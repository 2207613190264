/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-pagination.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../dialogs/src/lib/tooltips/tooltip.directive";
import * as i4 from "../../../dialogs/src/lib/tooltips/tooltips.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i7 from "@angular-react/fabric";
import * as i8 from "./simple-pagination.component";
import * as i9 from "../../../i18n/src/lib/services/i18n.service";
import * as i10 from "@angular/cdk/a11y";
var styles_SimplePaginationComponent = [i0.styles];
var RenderType_SimplePaginationComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SimplePaginationComponent, data: {} });
export { RenderType_SimplePaginationComponent as RenderType_SimplePaginationComponent };
function View_SimplePaginationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["data-test-id", "paging-total"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.simplePagination_of; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.total; _ck(_v, 6, 0, currVal_1); }); }
function View_SimplePaginationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "wcd-simple-pagination__position"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t\t", "-", "\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimplePaginationComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.total; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.firstIndex; var currVal_1 = _co.lastIndex; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_SimplePaginationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "wcd-simple-pagination__position"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), (_l()(), i1.ɵted(5, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.page; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.page; _ck(_v, 5, 0, currVal_1); }); }
function View_SimplePaginationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "wcd-simple-pagination wcd-margin-xxsmall-right"], ["data-track-id", "SimplePagination__Previous"], ["data-track-type", "Button"]], [[1, "aria-label", 0], [8, "disabled", 0]], [[null, "click"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.prev() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i3.TooltipDirective, [i1.ElementRef, i4.TooltipsService, i5.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fab-icon", [["contentClass", "wcd-simple-pagination-icon"]], null, null, null, i6.View_FabIconComponent_0, i6.RenderType_FabIconComponent)), i1.ɵdid(4, 5816320, null, 0, i7.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.i18nService.strings.simplePagination_previous_page; _ck(_v, 1, 0, currVal_2); var currVal_3 = "wcd-simple-pagination-icon"; var currVal_4 = "ChevronLeft"; _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calculatePagesNavigationAriaLabel("simplePagination_previous_page", _co.ariaPagePrevIndex); var currVal_1 = (_co.page === 1); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SimplePaginationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "wcd-simple-pagination"], ["data-track-id", "SimplePagination__Next"], ["data-track-type", "Button"]], [[1, "aria-label", 0], [8, "disabled", 0]], [[null, "click"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.next() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i3.TooltipDirective, [i1.ElementRef, i4.TooltipsService, i5.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fab-icon", [["contentClass", "wcd-simple-pagination-icon"]], null, null, null, i6.View_FabIconComponent_0, i6.RenderType_FabIconComponent)), i1.ɵdid(4, 5816320, null, 0, i7.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.i18nService.strings.simplePagination_next_page; _ck(_v, 1, 0, currVal_2); var currVal_3 = "wcd-simple-pagination-icon"; var currVal_4 = "ChevronRight"; _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calculatePagesNavigationAriaLabel("simplePagination_next_page", _co.ariaPageNextIndex); var currVal_1 = _co.isLastPage; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SimplePaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["role", "navigation"]], [[1, "aria-label", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimplePaginationComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(0, [["pageNumbers", 2]], null, 0, null, View_SimplePaginationComponent_4)), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimplePaginationComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimplePaginationComponent_6)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ("wcd-simple-pagination " + _co.className); _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.showCount && !_co.shouldShowPageNumbers); var currVal_3 = i1.ɵnov(_v, 7); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = !(_co.pageCount && (_co.pageCount <= 1)); _ck(_v, 10, 0, currVal_4); var currVal_5 = !(_co.pageCount && (_co.pageCount <= 1)); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.simplePagination_navigation; _ck(_v, 0, 0, currVal_0); }); }
export function View_SimplePaginationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimplePaginationComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldShowRange(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SimplePaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-simple-pagination", [], null, null, null, View_SimplePaginationComponent_0, RenderType_SimplePaginationComponent)), i1.ɵdid(1, 573440, null, 0, i8.SimplePaginationComponent, [i1.ChangeDetectorRef, i9.I18nService, i10.LiveAnnouncer], null, null)], null, null); }
var SimplePaginationComponentNgFactory = i1.ɵccf("wcd-simple-pagination", i8.SimplePaginationComponent, View_SimplePaginationComponent_Host_0, { page: "page", className: "className", pageSize: "pageSize", total: "total", currentPageCount: "currentPageCount", shouldShowPageNumbers: "shouldShowPageNumbers", showOnSinglePage: "showOnSinglePage" }, { pageChange: "pageChange" }, []);
export { SimplePaginationComponentNgFactory as SimplePaginationComponentNgFactory };
