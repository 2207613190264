import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { MachineGroup } from './machine-group.entity';

@ApiCall({
	name: 'Update machine group remediation level',
	endpoint: 'rbac/machine_groups/remediation_level',
	method: 'PATCH',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.rbacManagementApi,
	parseQuery: (machineGroups: Array<MachineGroup>) => ({
		data: machineGroups.map(machineGroup => ({
			MachineGroupId: machineGroup.id,
			AutoRemediationLevel: machineGroup.protectionLevel.id,
		})),
	}),
})
export class MachineGroupRemediationLevelApiCall extends ApiCallModel<void, Array<MachineGroup>> {}
