/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./datatable-field-value-custom-component.component";
var styles_DataTableFieldValueCustomComponent = [];
var RenderType_DataTableFieldValueCustomComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DataTableFieldValueCustomComponent, data: {} });
export { RenderType_DataTableFieldValueCustomComponent as RenderType_DataTableFieldValueCustomComponent };
export function View_DataTableFieldValueCustomComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { customComponent: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 16777216, [[1, 3], ["customComponent", 1]], null, 0, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, null); }
export function View_DataTableFieldValueCustomComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-datatable-field-value-custom-component", [], null, null, null, View_DataTableFieldValueCustomComponent_0, RenderType_DataTableFieldValueCustomComponent)), i0.ɵdid(1, 573440, null, 0, i1.DataTableFieldValueCustomComponent, [i0.ChangeDetectorRef, i0.ComponentFactoryResolver], null, null)], null, null); }
var DataTableFieldValueCustomComponentNgFactory = i0.ɵccf("wcd-datatable-field-value-custom-component", i1.DataTableFieldValueCustomComponent, View_DataTableFieldValueCustomComponent_Host_0, { field: "field", value: "value" }, {}, []);
export { DataTableFieldValueCustomComponentNgFactory as DataTableFieldValueCustomComponentNgFactory };
