import { Injectable } from '@angular/core';
import { IPreload } from '@wcd/shared';
import { HttpClient } from '@angular/common/http';
import { ApiBaseModel } from '@wcd/data';
import { switchMap, tap } from 'rxjs/operators';
import { isEqual, isNil } from 'lodash-es';
import { Observable, Subject, timer } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppContextService, Feature } from '@wcd/config';
import { IFeaturesService } from '@wcd/scc-common';

const CHECK_FEATURES_INTERVAL = 60 * 60 * 1000;

@Injectable()
export class FeaturesService implements IFeaturesService, IPreload {
	private features: Set<string>;
	private featuresMap = new Map<string, Feature>();
	private _defaultFeatures: Set<string>;
	private _featureChangedSubject: Subject<{ featureId: string; value: boolean }>;

	featureChanged$: Observable<{ featureId: string; value: boolean }>;

	/**
	 * Used by angularJs router in order to signal route change and showFeatureFlagToggle flag on given route.
	 * Can be removed when angularJs router is removed. TODO: remove when angularJs router is removed
	 * @type {Subject<string>}
	 */
	legacyShowFeatureToggle$: Subject<string> = new Subject<string>();

	constructor(private readonly http: HttpClient, private appContextService: AppContextService) {
		this._featureChangedSubject = new Subject<{ featureId: string; value: boolean }>();
		this.featureChanged$ = this._featureChangedSubject.asObservable();
		Object.keys(Feature).forEach((feature) =>
			this.featuresMap.set(feature.toLowerCase(), Feature[feature])
		);
	}

	private getFeaturesApi$(): Observable<{ [featureId: string]: boolean }> {
		const params = { asJson: true };

		// TODO: Remove adding the context once securityoperations portal will be deprecated
		if (this.appContextService.isMtp) {
			params['context'] = 'MtpContext';
		}

		return this.http.get<{ [featureId: string]: boolean }>(
			`/features`,
			ApiBaseModel.getUrlParams(params)
		);
	}

	init() {
		return this.getFeaturesApi$().pipe(
			tap((features) => {
				this.applyFeatures(features);
				if (this.isEnabled(Feature.RefreshToken)) {
					this.setFeaturesInterval();
				}
			})
		);
	}

	private setFeaturesInterval() {
		timer(CHECK_FEATURES_INTERVAL, CHECK_FEATURES_INTERVAL)
			.pipe(
				switchMap(() => this.getFeaturesApi$()),
				tap((newFeatures) => {
					const newFeatureSet = Object.entries(newFeatures).reduce((acc, [k, v]) => {
						if (v) {
							acc.add(this.normalizeFeatureId(k));
						}
						return acc;
					}, new Set());
					if (!isEqual(this._defaultFeatures, newFeatureSet)) {
						location.reload();
					}
				})
			)
			.toPromise();
	}

	private getLocalFeatureKey = (featureId: string): string => `feature.${featureId}`;

	private normalizeFeatureIds = (featureIds: string[]): Feature[] =>
		featureIds.map((x) => this.normalizeFeatureId(x));

	private normalizeFeatureId = (featureId: string): Feature =>
		this.featuresMap.get((featureId || '').toLowerCase()) || (featureId as Feature);

	applyFeatures(featuresData: { [featureId: string]: boolean }) {
		this.features = new Set();
		this._defaultFeatures = new Set();

		for (const featureId in featuresData) {
			if (featuresData[featureId]) {
				const feature = this.normalizeFeatureId(featureId);
				this.features.add(feature);
				this._defaultFeatures.add(feature);
			}
		}

		Object.freeze(this._defaultFeatures);

		const localStorageKeyRegExp = /^feature\.(.+)/;

		for (const storageKey in localStorage) {
			const featureIdMatch: RegExpMatchArray = storageKey.match(localStorageKeyRegExp);
			if (featureIdMatch) {
				const feature = this.normalizeFeatureId(featureIdMatch[1]);
				const enabled = localStorage.getItem(storageKey) !== 'false';
				if (enabled) this.features.add(feature);
				else if (this.features.has(feature)) this.features.delete(feature);
			}
		}
	}

	/**
	 * If the feature was set locally, returns the local value.
	 * @param {string} featureId
	 * @returns {boolean}
	 */
	getLocalFeatureValue(featureId: string): boolean {
		const localValue: string = localStorage.getItem(this.getLocalFeatureKey(featureId));
		return localValue ? localValue !== 'false' : null;
	}

	setLocalFeatureValue(featureId: string, value?: boolean): void {
		const featureLocalStorageKey: string = this.getLocalFeatureKey(featureId);
		let newValue: boolean;

		if (!isNil(value)) {
			newValue = value !== false;
			localStorage.setItem(featureLocalStorageKey, newValue ? 'true' : 'false');
		} else {
			localStorage.removeItem(featureLocalStorageKey);
			newValue = false;
		}
		const feature = this.normalizeFeatureId(featureId);
		if (newValue !== this.isEnabled(feature)) {
			if (newValue) this.features.add(feature);
			else this.features.delete(feature);

			this._featureChangedSubject.next({ featureId: feature, value: newValue });
		}
	}

	clearLocalFeatureValue(featureId: string): void {
		localStorage.removeItem(this.getLocalFeatureKey(featureId));
		const feature = this.normalizeFeatureId(featureId);
		if (this._defaultFeatures.has(feature)) this.features.add(feature);
		else this.features.delete(feature);

		this._featureChangedSubject.next({ featureId: feature, value: this.features.has(feature) });
	}

	isEnabled(featureId: string | Array<string>): boolean {
		const features = this.normalizeFeatureIds(Array.isArray(featureId) ? featureId : [featureId]);
		return this.features && features.every((x) => this.features.has(x));
	}

	isDefault = (featureId) => this._defaultFeatures.has(this.normalizeFeatureId(featureId));

	isAnyEnabled(featureIds: Array<string>): boolean {
		const features = this.normalizeFeatureIds(featureIds);
		return this.features && features.some((x) => this.features.has(x));
	}

	/**
	 * recursively search routes and route's parents data for 'featureFlagToggleFeatureId' param
	 * @param {ActivatedRouteSnapshot} route
	 * @returns {ActivatedRouteSnapshot}
	 */
	public getRouteWithFeatureFlagConfig(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
		let routeFeatureFlagConfig: ActivatedRouteSnapshot = route;
		while (routeFeatureFlagConfig) {
			if (!!routeFeatureFlagConfig.data['featureFlagToggleFeatureId']) break;
			routeFeatureFlagConfig = routeFeatureFlagConfig.firstChild;
		}

		return routeFeatureFlagConfig;
	}
}
