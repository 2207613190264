
		<span class="copy-to-clipboard" [ngClass]="settings.className">
			<button
				class="btn-link"
				[class.copied]="isCopied"
				[disabled]="!content"
				[attr.data-track-id]="'CopyToClipboard-' + ariaLabelFieldName"
				data-track-type="Button"
				[attr.aria-label]="'common.copyFieldToClipboardLabel' | i18n: { field: ariaLabelFieldName }"
				(click)="copyToClipboard()"
				[wcdTooltip]="tooltipText"
				#copyBtn
			>
				<wcd-shared-icon [iconName]="isCopied ? 'SkypeCircleCheck' : 'Copy'"> </wcd-shared-icon>
				{{ settings.copyText }}
			</button>
		</span>
	