import { Injectable } from '@angular/core';
import { HuntingMainLocalStorageService } from '../../shared/services/hunting-main-local-storage.service';

@Injectable()
export class ResultsTableLocalStorageService {
	readonly resultsPerPageKey = 'advanced-exploration-numResPerPage';

	constructor(private readonly localStorageService: HuntingMainLocalStorageService) {}

	savePageSize(pageSize: number) {
		this.localStorageService.setItemToStorage(this.resultsPerPageKey, pageSize);
	}

	getSavedPageSize(): number | null {
		return this.localStorageService.getItemFromStorage(this.resultsPerPageKey);
	}
}
