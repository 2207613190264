/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../global_entities/components/entity-details/recommendation-exception.entity-details.component.ngfactory";
import * as i2 from "../../../../../global_entities/components/entity-details/recommendation-exception.entity-details.component";
import * as i3 from "../../../../../global_entities/services/activated-entity.service";
import * as i4 from "../../../../../shared/pipes/calculate-remaining-days.pipe";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../tvm/services/tvm-texts.service";
import * as i7 from "../services/recommendation-exception.service";
import * as i8 from "./recommendation-exception.entity-panel.component";
var styles_RecommendationExceptionEntityPanelComponent = [];
var RenderType_RecommendationExceptionEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RecommendationExceptionEntityPanelComponent, data: {} });
export { RenderType_RecommendationExceptionEntityPanelComponent as RenderType_RecommendationExceptionEntityPanelComponent };
export function View_RecommendationExceptionEntityPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "recommendation-exception-entity-details", [], null, null, null, i1.View_RecommendationExceptionEntityDetailsComponent_0, i1.RenderType_RecommendationExceptionEntityDetailsComponent)), i0.ɵdid(3, 245760, null, 0, i2.RecommendationExceptionEntityDetailsComponent, [i3.ActivatedEntity, i4.CalculateRemainingDaysPipe, i5.Router, i0.Injector, i6.TvmTextsService, i7.RecommendationExceptionService], { recommendationException: [0, "recommendationException"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recommendationException; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_RecommendationExceptionEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "recommendation-exception-entity-panel", [], null, null, null, View_RecommendationExceptionEntityPanelComponent_0, RenderType_RecommendationExceptionEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i8.RecommendationExceptionEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecommendationExceptionEntityPanelComponentNgFactory = i0.ɵccf("recommendation-exception-entity-panel", i8.RecommendationExceptionEntityPanelComponent, View_RecommendationExceptionEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { RecommendationExceptionEntityPanelComponentNgFactory as RecommendationExceptionEntityPanelComponentNgFactory };
