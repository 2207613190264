import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { DeviceResource } from '@wcd/domain';
import { Router } from '@angular/router';

export interface MachineResourcesPanelInput {
	resources: Array<DeviceResource>;
}

@Component({
	selector: 'machine-itp-resources-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<machine-itp-resources [resources]="resources"></machine-itp-resources>
		</wcd-panel>
	`,
})
export class MachineResourcesPanelComponent extends PanelContainer {
	@Input() resources: Array<DeviceResource>;

	constructor(router: Router) {
		super(router);
	}
}
