import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('alertsFirstSeen', alertsFirstSeenFilter);

function alertsFirstSeenFilter() {
	return function(input) {
		if (!input) {
			return '';
		}

		var dt = new Date(input);
		var local = new Date();
		var oneDay = 86400000; // 24 * 60 * 60 * 1000;
		var days = Math.abs((dt.getTime() - local.getTime()) / oneDay);

		if (days < 1) {
			return 'Today';
		} else if (days < 2 && days > 1) {
			return 'Yesterday';
		}

		var roundedDays = Math.round(days);
		return roundedDays + 'd';
	};
}
