<ng-container *ngIf="!contextOptions || !contextOptions.isSingleAsset">
	<ng-container *ngTemplateOutlet="installationsSection; context: {assets: (installations$ | async)}">
	</ng-container>
</ng-container>

<ng-template #installationsSection let-assets="assets">
	<entity-panel-collapsible-section
		[loaded]="assets"
		[loadingText]="loadingText ? loadingText : ('tvm.securityRecommendation.loadingInstalledDevices' | i18n)"
		[title]="title ? title : ('tvm.common.installedDevices' | i18n)"
		[count]="assets?.count"
		(export)="export.emit()">
		<table class="wcd-table wcd-full-width table-grouped no-side-padding wcd-padding-bottom">
			<thead>
				<tr>
					<th>{{'tvm.common.assetNameTitle' | i18n}}</th>
					<th *ngIf="includeOperatingSystem">{{'os.operatingSystem' | i18n}}</th>
					<th *ngIf="includeLastSeenStatus">{{'tvm.networkGear.networkDeviceReachableTitle' | i18n}}</th>
				</tr>
			</thead>
			<tbody>
				<tr class="table-group-header" *ngFor="let asset of assets?.items">
					<td class="wcd-border-bottom table-link nowrap wcd-text-overflow-medium full-width-cell">
						<ng-container *ngIf="asset.assetName as displayName">
							<div tabindex="0" role="link" (keydown.enter)="onItemClick(asset.assetId)" (click)="onItemClick(asset.assetId)">
								<span *ngIf="isNetworkDevice"><network-device-icon></network-device-icon></span>
								<span *ngIf="!isNetworkDevice"><fab-icon contentClass='field-value-icon' iconName='System'></fab-icon></span>
								{{ displayName }}
							</div>
						</ng-container>
					</td>
					<td *ngIf="includeOperatingSystem" class="col-xs-3 wcd-border-bottom nowrap wcd-text-overflow-medium">
						{{ tvmTextsService.getOsPlatformLabel(asset.osName) }}
					</td>
					<td *ngIf="includeLastSeenStatus" class="col-xs-3 nowrap wcd-text-overflow-medium" title="Last seen: {{ getLastSeenDate(asset) }}">
						{{ (asset.lastSeen > oneDayAgo) ? 'Yes' : 'No' }}
					</td>
				</tr>
			</tbody>
		</table>

		<a *ngIf="assets?.count >= 3"
			tabindex="0"
			role="button"
			data-track-id="OpenAllInstalledMachines"
			data-track-type="Navigation"
			(keydown.enter)="openAllInstalledMachines()"
			(click)="openAllInstalledMachines()">{{'tvm.common.showMore' | i18n}}</a>
	</entity-panel-collapsible-section>
</ng-template>
