import { NgModule } from '@angular/core';
import { FolderExclusionsComponent } from './components/folder-exclusions.component';
import { FolderExclusionFormComponent } from './components/folder-exclusion-form.component';
import { FolderExclusionsBackendService } from './services/folder-exclusions.backend.service';
import { FolderExclusionsStore } from './services/folder-exclusions.store';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
	imports: [SharedModule],
	declarations: [FolderExclusionsComponent, FolderExclusionFormComponent],
	exports: [FolderExclusionsComponent, FolderExclusionFormComponent],
	entryComponents: [FolderExclusionsComponent, FolderExclusionFormComponent],
	providers: [FolderExclusionsBackendService, FolderExclusionsStore],
})
export class FolderExclusionsModule {}
