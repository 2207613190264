/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./scheduled-hunting.entity-details.component.ngfactory";
import * as i2 from "./scheduled-hunting.entity-details.component";
import * as i3 from "../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i4 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i5 from "@microsoft/paris/dist/lib/paris";
import * as i6 from "../scheduled-hunting.actions.fields";
import * as i7 from "../services/scheduled-hunting-action.entity-type.service";
import * as i8 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i9 from "@angular/router";
import * as i10 from "./scheduled-hunting.entity-panel.component";
import * as i11 from "../../../dialogs/services/dialogs.service";
var styles_ScheduledHuntingEntityPanelComponent = [];
var RenderType_ScheduledHuntingEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScheduledHuntingEntityPanelComponent, data: {} });
export { RenderType_ScheduledHuntingEntityPanelComponent as RenderType_ScheduledHuntingEntityPanelComponent };
export function View_ScheduledHuntingEntityPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "wcd-full-height"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "section", [["class", "wcd-padding-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "scheduled-hunting-entity-details", [], null, null, null, i1.View_ScheduledHuntingEntityDetailsComponent_0, i1.RenderType_ScheduledHuntingEntityDetailsComponent)), i0.ɵdid(6, 245760, null, 0, i2.ScheduledHuntingEntityDetailsComponent, [i0.Injector, i3.AppContextService, i0.ChangeDetectorRef, i4.FeaturesService, i5.Paris, i6.ScheduledHuntingActionsFields, i7.ScheduledHuntingActionEntityTypeService, i8.I18nService, i9.Router], { entity: [0, "entity"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rule; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_ScheduledHuntingEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "scheduled-hunting-entity-panel", [], null, null, null, View_ScheduledHuntingEntityPanelComponent_0, RenderType_ScheduledHuntingEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i10.ScheduledHuntingEntityPanelComponent, [i11.DialogsService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduledHuntingEntityPanelComponentNgFactory = i0.ɵccf("scheduled-hunting-entity-panel", i10.ScheduledHuntingEntityPanelComponent, View_ScheduledHuntingEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { ScheduledHuntingEntityPanelComponentNgFactory as ScheduledHuntingEntityPanelComponentNgFactory };
