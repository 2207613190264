var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { NotificationRuleCondition } from './notification-rule-condition.value-object';
import { MachineGroup } from '../rbac/machine-group.entity';
import { NotificationFormat } from './notification-rule-format.entity';
import { notificationFormatValues } from './notification-rule-format.values';
var ɵ0 = function (config) { return config.data.serviceUrls.alertsEmailNotifications; }, ɵ1 = ['Name'], ɵ2 = ['IsReadonly'], ɵ3 = function (items) {
    return items.map(function (item) { return item.id; });
}, ɵ4 = function (formatBitWise) {
    return notificationFormatValues.reduce(function (formats, notificationFormat) {
        return notificationFormat.id > 0 && formatBitWise & notificationFormat.id
            ? formats.concat([notificationFormat.id]) : formats;
    }, []);
}, ɵ5 = function (notificationFormats) {
    return notificationFormats.reduce(function (bitwiseValue, format) { return bitwiseValue | format.id; }, 0);
};
var NotificationRule = /** @class */ (function (_super) {
    __extends(NotificationRule, _super);
    function NotificationRule() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", Number)
    ], NotificationRule.prototype, "id", void 0);
    __decorate([
        EntityField({ data: ɵ1 }),
        __metadata("design:type", String)
    ], NotificationRule.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'Recipients', arrayOf: String }),
        __metadata("design:type", Array)
    ], NotificationRule.prototype, "recipients", void 0);
    __decorate([
        EntityField({ data: 'LastUpdated' }),
        __metadata("design:type", Date)
    ], NotificationRule.prototype, "lastUpdatedOn", void 0);
    __decorate([
        EntityField({ data: ɵ2 }),
        __metadata("design:type", Boolean)
    ], NotificationRule.prototype, "readonly", void 0);
    __decorate([
        EntityField({
            data: 'Rules',
            arrayOf: NotificationRuleCondition,
        }),
        __metadata("design:type", Array)
    ], NotificationRule.prototype, "conditions", void 0);
    __decorate([
        EntityField({
            data: 'MachineGroupIds',
            arrayOf: MachineGroup,
            serialize: ɵ3,
        }),
        __metadata("design:type", Array)
    ], NotificationRule.prototype, "machineGroups", void 0);
    __decorate([
        EntityField({
            data: 'FormatFlavor',
            arrayOf: NotificationFormat,
            parse: ɵ4,
            serialize: ɵ5,
        }),
        __metadata("design:type", Array)
    ], NotificationRule.prototype, "formatFlavor", void 0);
    NotificationRule = __decorate([
        Entity({
            singularName: 'Notification Rule',
            pluralName: 'Notification rules',
            endpoint: 'email_notifications',
            allItemsProperty: 'items',
            baseUrl: ɵ0,
        })
    ], NotificationRule);
    return NotificationRule;
}(EntityModelBase));
export { NotificationRule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
