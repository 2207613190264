import { Component, OnInit } from '@angular/core';
import { Ip } from '@wcd/domain';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { IpsService } from '../services/ips.service';
import { ReportModel } from '../../../reports/models/report.model';
import { ipReportModel } from './ip.overview.component';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';

@Component({
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<tabs
				class="wcd-flex-none wcd-margin-xxLarge-horizontal wcd-flex-spaceBetween-horizontal
					   wcd-flex-center-vertical wcd-margin-large-bottom wcd-padding-none-left-sm-screens"
				[tabsData]="tabs"
				[currentRouteIsActive]="true"
			></tabs>
			<div class="wcd-flex-1">
				<router-outlet></router-outlet>
			</div>
		</div>
	`,
})
export class IpEntityComponent extends EntityComponentBase<Ip> implements OnInit {
	report: ReportModel;
	tabs: ReadonlyArray<TabModel> = [];
	featuresChangeSubscription: Subscription;

	constructor(
		private readonly i18nService: I18nService,
		private readonly featuresService: FeaturesService,
		private readonly ipsService: IpsService,
		private readonly router: Router,
		private readonly route: ActivatedRoute
	) {
		super();

		this.featuresChangeSubscription = this.featuresService.featureChanged$
			.pipe(filter(({ featureId }) => featureId === Feature.UpgradeIpPage))
			.subscribe(() => {
				if (!featuresService.isEnabled(Feature.UpgradeIpPage)) {
					const params = this.route.snapshot.queryParams;
					this.router.navigateByUrl(
						this.ipsService.getLegacyIpLink(
							this.ip.id,
							new Date((params && params['to']) || Date.now())
						)
					);
				}
			});

		this.report = ipReportModel;
	}

	get ip(): Ip {
		return this.entity;
	}

	ngOnInit() {
		this.tabs = this.getTabs(this.ip);
	}

	ngOnDestroy() {
		this.featuresChangeSubscription && this.featuresChangeSubscription.unsubscribe();
	}

	private getTabs(ip: Ip): Array<TabModel> {
		const tabs = [
			{
				id: 'alerts',
				name: this.i18nService.get('ips.tabs.alerts'),
				routerLink: './alerts',
			},
			{
				id: 'observed',
				name: this.i18nService.get('ips.tabs.observed'),
				routerLink: './timeline',
			},
		];

		tabs.unshift({
			id: 'overview',
			name: this.i18nService.get('ips.tabs.overview'),
			routerLink: './overview',
		});

		return tabs.map(tab => new TabModel(tab));
	}
}
