var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { MachineGroup } from './machine-group.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = function (data) {
    if (data && data instanceof Array) {
        if (data instanceof Array) {
            return data.map(function (group) { return new MachineGroup({ id: group.id, name: group.label }); });
        }
        else {
            return [];
        }
    }
    else if (data && data.id && data.label) {
        // An object of type { id: number, label: string } - representing the machine group
        // This is needed for datatview being built in TVM. See this PR: I included screenshots.https://microsoft.visualstudio.com/DefaultCollection/WDATP/_git/WcdPortal/pullrequest/4525249
        return new MachineGroup({ id: data.id, name: data.label });
    }
};
var UserExposedRbacGroups = /** @class */ (function (_super) {
    __extends(UserExposedRbacGroups, _super);
    function UserExposedRbacGroups() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ1,
        }),
        __metadata("design:type", Array)
    ], UserExposedRbacGroups.prototype, "groups", void 0);
    UserExposedRbacGroups = __decorate([
        Entity({
            singularName: 'User Exposed RBAC Group',
            pluralName: 'User Exposed RBAC Groups',
            endpoint: 'UserExposedRbacGroups',
            allItemsEndpointTrailingSlash: false,
            readonly: true,
            baseUrl: ɵ0,
        })
    ], UserExposedRbacGroups);
    return UserExposedRbacGroups;
}(EntityModelBase));
export { UserExposedRbacGroups };
export { ɵ0, ɵ1 };
