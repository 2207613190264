var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ComponentRef } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { defer } from 'rxjs';
import { DataTableField } from '@wcd/datatable';
import { File, FileFileVerdictsRelationship, Alert } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { map, tap, mergeMap, take } from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { FileVerdictAlertService } from '../services/file-verdict-alert.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { MalwareAlertsComponent } from './malware-alerts.component';
import { PanelType } from '@wcd/panels';
import { catchHttpError } from '../../../utils/rxjs/rxjs-custom-operators';
import { Location } from '@angular/common';
var FileMalwareDetectedComponent = /** @class */ (function () {
    function FileMalwareDetectedComponent(i18nService, paris, dialogsService, globalEntityTypesService, fileVerdictAlertService, location) {
        var _this = this;
        this.i18nService = i18nService;
        this.paris = paris;
        this.dialogsService = dialogsService;
        this.fileVerdictAlertService = fileVerdictAlertService;
        this.location = location;
        this.showSummary = true;
        this.tableFields = DataTableField.fromList([
            {
                id: 'malware',
                name: this.i18nService.get('files.entityDetails.sections.detections.fields.malwareDetected.malware'),
                getDisplay: function (item) { return item.threatName; },
            },
            {
                id: 'source',
                name: this.i18nService.get('files.entityDetails.sections.detections.fields.malwareDetected.source'),
                getDisplay: function (item) {
                    return item.sources
                        .map(function (src) {
                        return src === 0
                            ? _this.i18nService.get('files.entityDetails.sections.detections.fields.malwareDetected.sources.windowsDefenderAv')
                            : _this.i18nService.get('files.entityDetails.sections.detections.fields.malwareDetected.sources.cloudService');
                    })
                        .join(', ');
                },
            },
            {
                id: 'seeAlertsLink',
                name: '',
                getDisplay: function (item) {
                    return _this.i18nService.get("files.entityDetails.sections.detections.fields.malwareDetected." + (item.relatedAlertsCount === 0
                        ? 'noAlert'
                        : item.relatedAlertsCount > 1
                            ? 'seeAlerts'
                            : 'seeAlert'));
                },
                onClick: function (item) { return __awaiter(_this, void 0, void 0, function () {
                    var alertLink;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (item.relatedAlertsCount === 0) {
                                    return [2 /*return*/];
                                }
                                if (!(item.relatedAlertsCount === 1)) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.fileVerdictAlertService
                                        .getFileVerdictAlerts(this.file.sha1, item)
                                        .pipe(map(function (alerts) { return _this._alertEntityTypeService.getEntitiesLink(alerts); }), take(1))
                                        .toPromise()];
                            case 1:
                                alertLink = _a.sent();
                                this.location.go(alertLink);
                                return [2 /*return*/, null];
                            case 2: return [2 /*return*/, this.showMalwareAlertsPanel(item)
                                    .pipe(take(1))
                                    .toPromise()];
                        }
                    });
                }); },
                getCssClass: function (item) { return (item.relatedAlertsCount > 0 ? 'btn-link' : ''); },
            },
        ]);
        this._alertEntityTypeService = globalEntityTypesService.getEntityType(Alert);
    }
    FileMalwareDetectedComponent.prototype.ngOnInit = function () {
        this.fileFileVerdictRepo = this.paris.getRelationshipRepository(FileFileVerdictsRelationship);
        this.fileFileVerdictRepo.sourceItem = this.file;
        this.tableItems$ = this.fileFileVerdictRepo.query(null, { allowCache: true }).pipe(catchHttpError(404, { items: [] }), map(function (result) { return result.items; }));
    };
    FileMalwareDetectedComponent.prototype.showMalwareAlertsPanel = function (fileVerdict) {
        var _this = this;
        return this.dialogsService
            .showPanel(MalwareAlertsComponent, {
            id: 'malware-alert',
            isModal: true,
            headerText: this.i18nService.get('files.entityDetails.sections.detections.fields.malwareDetected.malwareAlertPanel.title'),
            showOverlay: true,
            back: { onClick: function () { return _this._malwareAlertsPanel.destroy(); } },
            type: PanelType.large,
        }, {
            sha1: this.file.sha1,
            fileVerdict: fileVerdict,
        })
            .pipe(tap(function (panel) {
            _this._malwareAlertsPanel = panel;
            panel.onDestroy(function () {
                _this._malwareAlertsPanel = null;
            });
        }), mergeMap(function (panel) { return defer(function () { return new Promise(function (resolve) { return panel.onDestroy(resolve); }); }); }), map(function () { return null; }));
    };
    return FileMalwareDetectedComponent;
}());
export { FileMalwareDetectedComponent };
