import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
import { SerializedFilters } from '../../../models/serialized-filters.type';
import { isNil } from 'lodash-es';

@Component({
	template: `
		<wcd-toggle
			[trueLabel]="config?.trueLabel"
			[falseLabel]="config?.falseLabel"
			(change)="onChange($event)"
			[checked]="selectedValues"
			[helpText]="config?.helpText"
			[ariaLabel]="config?.ariaLabel"
		>
		</wcd-toggle>
	`,
	selector: 'wcd-filter-values-toggle',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterValuesToggleComponent extends FilterValuesComponent<
	void,
	boolean,
	boolean,
	FilterValuesToggleConfig
> {
	get value(): boolean {
		return this.selectedValues;
	}

	constructor(private changeDetectorRef: ChangeDetectorRef) {
		super();
	}

	setSelectedValues(selectedValues: boolean) {
		this.changeDetectorRef.detectChanges();
	}

	serialize(): SerializedFilters {
		if (isNil(this.selectedValues)) return null;

		return { [this.fieldId]: this.selectedValues.toString() };
	}

	deserialize(serializedValue: SerializedFilters): boolean {
		let strVal = serializedValue[this.fieldId];
		if (isNil(strVal)) {
			return null;
		}
		if (strVal instanceof Array) strVal = strVal[0];

		return strVal === 'true';
	}

	onChange(newValue: boolean) {
		this.selectedValues = newValue;
		this.filterValuesChange.emit(this.selectedValues);
	}
}

export interface FilterValuesToggleConfig {
	trueLabel?: string;
	falseLabel?: string;
	helpText?: string;
	ariaLabel?: string;
}
