import { SevilleModule } from '../../seville/seville.module';
import '../services/seville.threatintel.demoTenantResolver';

SevilleModule.directive('sidePaneUrl', sidePaneUrlIndicatorDirective);

sidePaneUrlIndicatorDirective.$inject = ['sidePaneService'];

function sidePaneUrlIndicatorDirective(sidePaneService) {
	return {
		restrict: 'E',
		bindToController: true,
		controllerAs: 'pi',
		scope: {
			url: '=',
			hideIndicator: '=',
			firstSeen: '=',
		},
		template: `
		<span class="side-pane-indicator" tabindex="0" role="button" aria-label="Open URL side pane" data-track-id="sidePaneButtonUrl" data-track-type="SidePaneToggleButton">
        <i class="icon icon-{{pi.icon}} pointer side-pane-indicator-icon" ng-click="toggle($event)" ng-if="!pi.hideIndicator"></i>
    </span>
		`,
		controller: [
			'$scope',
			'$http',
			'urlMapping',
			'$filter',
			'demoTenantResolverService',
			'$log',
			'sidePaneDataProviderService',
			function(
				$scope,
				$http,
				urlMapping,
				$filter,
				demoTenantResolverService,
				$log,
				sidePaneDataProviderService
			) {
				var vm = this;

				this.$onInit = () => {
					vm.key = vm.url;
				};

				this.togglePane = function() {
					var loadOperations = 1;
					vm.instance = sidePaneService.open({
						key: vm.url,
						title: vm.url,
						type: 'entity',
						icon: 'icon-Link',
						link: {
							text: 'Go to URL page',
							state: 'url',
							params: { id: vm.url },
						},
						sections: [
							{
								title: 'URL details',
								order: 1,
							},
							{
								title: 'Related alerts',
								order: 2,
							},
							{
								title: 'Observed in organization',
								subtitle: '(Last ' + demoTenantResolverService.getStatsLookback() + ' days)',
								order: 3,
							},
						],
					});

					function loadProfile() {
						const section = sidePaneDataProviderService.getUrlDetails(vm.url);
						vm.instance.setSection('URL details', section);
						vm.instance.setSectionLoading('URL details', false);
					}

					function loadAlerts() {
						sidePaneDataProviderService.loadAlertsForUrl(vm.url, vm.instance).then(
							function(response) {
								if (response.section) {
									vm.instance.setSection('Related alerts', response.section);
									vm.instance.setSectionLoading('Related alerts', false);
								} else {
									vm.instance.setSectionError(
										'Related alerts',
										response.error || 'Data could not be retrieved.'
									);
								}
							},
							function(response) {
								vm.instance.setSectionError(
									'Related alerts',
									response.error || 'Data could not be retrieved.'
								);
							}
						);
					}

					function loadStats() {
						sidePaneDataProviderService.loadUrlStats(vm.url, vm.instance).then(
							function(response) {
								if (response.section) {
									vm.instance.setSection('Observed in organization', response.section);
									vm.instance.setSectionLoading('Observed in organization', false);
								} else {
									vm.instance.setSectionError(
										'Observed in organization',
										response.error || 'Data could not be retrieved.'
									);
								}
							},
							function(response) {
								vm.instance.setSectionError(
									'Observed in organization',
									response.error || 'Data could not be retrieved.'
								);
							}
						);
					}

					loadProfile();
					loadAlerts();
					loadStats();
					vm.instance.setLoading(false);
				};
			},
		],
	};
}
