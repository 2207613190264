
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.firstSeen">
				<dt role="none">{{ i18nService.strings.airsEntities_oauthApplication_fields_firstSeen }}</dt>
				<dd role="none">{{ entity.firstSeen | date: 'short' }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.name">
				<dt role="none">{{ i18nService.strings.airsEntities_oauthApplication_fields_name }}</dt>
				<dd role="none">{{ entity.name }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.oAuthAppId">
				<dt role="none">{{ i18nService.strings.airsEntities_oauthApplication_fields_oauthAppId }}</dt>
				<dd role="none">{{ entity.oAuthAppId }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.oAuthObjectId">
				<dt role="none">
					{{ i18nService.strings.airsEntities_oauthApplication_fields_oauthObjectId }}
				</dt>
				<dd role="none">{{ entity.oAuthObjectId }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.risk">
				<dt role="none">{{ i18nService.strings.airsEntities_oauthApplication_fields_risk }}</dt>
				<dd role="none">{{ entity.risk }}</dd>
			</ng-container>
		</dl>
	