var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { InvestigatedMachine } from '../investigated-machine.entity';
import { RemediationActionType } from '../../remediation/remediation-action-type.entity';
import { InvestigationPendingType } from '../investigation-pending-type.entity';
import { InvestigationActionStatus } from './investigation-action-status.entity';
import { InvestigationActionCategory } from './investigation-category.entity';
import { InvestigationActionExtraData } from './investigation-action-extra-data.value-object';
import { AirsEntity } from '../../airs_entity/airs-entity.entity';
import { OfficeUtils } from '../../utils/office-utils';
import { InvestigationActionStatusId } from './investigation-action-status.values';
import { getConvertedEntityTypeName } from '../../mtp-investigation/mtp-investigation.entity';
import { AirsEntityType } from '../../airs_entity/airs-entity-type.entity';
import { get } from 'lodash-es';
import { mapAlertV3EntityToAirsEntity } from '../../airs_entity/alertV3/converters/airs-entity.converter.utils';
import { AlertV3ActionTypeMap } from '../../action-history/alertV3-action-type-types';
import { remediationActionTypeValues } from '../../remediation/remediation-action-type.values';
import { getConvertedOfficeActionType } from './office-action.utils';
import { sccHostService } from '@wcd/scc-interface';
export function convertOfficeActionToInvestigationAction(rawData) {
    var actionEntities = rawData &&
        rawData.Entities &&
        rawData.Entities.reduce(function (res, entity) {
            var convertedEntityType = getConvertedEntityTypeName(entity.Type);
            res.push(__assign({}, mapAlertV3EntityToAirsEntity(entity), { entity_type: AirsEntityType[convertedEntityType] }));
            return res;
        }, []);
    var startDate = rawData.StartTimeUtc.endsWith('Z') ? rawData.StartTimeUtc : rawData.StartTimeUtc + 'Z';
    //TODO: currently, for office investigation action, there's a bug that there's no EndTimeUtc in the response (https://microsoft.visualstudio.com/OS/_workitems/edit/24180557)
    var endDate = rawData.EndTimeUtc
        ? rawData.EndTimeUtc.endsWith('Z')
            ? rawData.EndTimeUtc
            : rawData.EndTimeUtc + 'Z'
        : null;
    var durationInSeconds = endDate && startDate ? (new Date(endDate).valueOf() - new Date(startDate).valueOf()) / 1000 : null;
    var convertedOfficeActionType = getConvertedOfficeActionType(rawData);
    var investigationActionTypeType = AlertV3ActionTypeMap[convertedOfficeActionType];
    var investigationActionType = remediationActionTypeValues.find(function (v) { return v.type === investigationActionTypeType; });
    return {
        id: rawData.ActionId,
        I18nNameKey: convertedOfficeActionType,
        remediation_action_type: investigationActionType ? investigationActionType.id : null,
        investigation: rawData.InvestigationId,
        action_status: InvestigationActionStatusId[rawData.ActionStatus],
        created: startDate,
        started: startDate,
        duration: durationInSeconds,
        entities: actionEntities,
        comment_count: rawData.ApproverComment ? 1 : 0,
        commentData: rawData.ApproverComment
            ? {
                approverComment: rawData.ApproverComment,
                actionApprovedTime: rawData.ActionApprovedTime &&
                    (rawData.ActionApprovedTime.endsWith('Z')
                        ? rawData.ActionApprovedTime
                        : rawData.ActionApprovedTime + 'Z'),
                approvedBy: rawData.ApprovedBy,
            }
            : null,
        category: 'Other',
        IsOfficeAction: true,
    };
}
var ɵ0 = function (config, query) {
    if (query && query.where && query.where['useOfficeApi']) {
        return "Find/MtpBatch?tenantid=" + query.where['tenantId'] + "&PageSize=200&Filter=ModelType eq 1 and ContainerUrn eq '" + query.where['investigation_urn'] + "'";
    }
    return 'investigationactions';
}, ɵ1 = function (itemId, entity, config, params) {
    if (params && params.useOfficeApi) {
        // There's bug in office that for PageSize=1 we don't get all the data, hence, PageSize=50
        return "Find/MtpBatch?tenantid=" + params.tenantId + "&PageSize=50&Filter=ModelType eq 1 and UrnProp eq '" + itemId + "'";
    }
    return "investigationactions/" + itemId;
}, ɵ2 = function (config, query) {
    if (query && query.where && query.where['useOfficeApi']) {
        return sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>';
    }
    return config.data.serviceUrls.automatedIr;
}, ɵ3 = function (rawData, config, query) {
    if (query && query.where && query.where['useOfficeApi']) {
        rawData = get(rawData, 'ResultData[0].InvestigationActionPayload');
        rawData = OfficeUtils.convertDataFromAlertV3(rawData);
        rawData =
            rawData &&
                (Array.isArray(rawData) ? rawData : [rawData]).map(function (a) {
                    return convertOfficeActionToInvestigationAction(a);
                });
        if (!query.where['isListView']) {
            return (rawData && rawData.length && rawData[0]) || null;
        }
        return {
            results: rawData,
            count: rawData ? rawData.length : 0,
        };
    }
    return rawData;
}, ɵ4 = function (name, itemData) { return name || itemData['I18nNameKey']; }, ɵ5 = ['I18nNameKey', 'name'], ɵ6 = function (actionType, rawData, query) {
    if (actionType) {
        return actionType;
    }
}, ɵ7 = function (errors) {
    return (errors || []).map(function (error) {
        if (!(error.details instanceof Array)) {
            var details = [];
            if (Object(error.details) === error.details) {
                for (var p in error.details) {
                    var value = error.details[p];
                    if (Object(value) === value)
                        value = JSON.stringify(value);
                    details.push(p + ": " + value);
                }
            }
            else
                details.push(error.details && error.details.toString());
            error.details = details;
        }
        return error;
    });
}, ɵ8 = function (isOfficeAction, _, query) {
    return !!(isOfficeAction || (query && query.where && query.where['useOfficeApi']));
};
var InvestigationAction = /** @class */ (function (_super) {
    __extends(InvestigationAction, _super);
    function InvestigationAction(data) {
        var _this = _super.call(this, data) || this;
        _this.shortActionId = OfficeUtils.getShortId(_this.id);
        return _this;
    }
    Object.defineProperty(InvestigationAction.prototype, "isActionUndone", {
        get: function () {
            return !!this.undoneActionId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InvestigationAction.prototype, "isActionUndoable", {
        get: function () {
            return !!this.undoActionId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InvestigationAction.prototype, "actionIds", {
        get: function () {
            return [this.id];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InvestigationAction.prototype, "isPending", {
        get: function () {
            return this.status.isPending || false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InvestigationAction.prototype, "isRunning", {
        get: function () {
            return this.status.isRunning || false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InvestigationAction.prototype, "ended", {
        get: function () {
            if (!this.isRunning && this.duration != null)
                return new Date(this.started.valueOf() + this.duration * 1000);
            return null;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({
            parse: ɵ4,
        }),
        __metadata("design:type", String)
    ], InvestigationAction.prototype, "name", void 0);
    __decorate([
        EntityField({
            data: ɵ5,
            parse: ɵ6,
        }),
        __metadata("design:type", String)
    ], InvestigationAction.prototype, "i18nNameKey", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], InvestigationAction.prototype, "details", void 0);
    __decorate([
        EntityField({ data: 'investigation' }),
        __metadata("design:type", Object)
    ], InvestigationAction.prototype, "investigationId", void 0);
    __decorate([
        EntityField({ data: 'is_live_response' }),
        __metadata("design:type", Boolean)
    ], InvestigationAction.prototype, "isLiveResponse", void 0);
    __decorate([
        EntityField({ data: 'action_status' }),
        __metadata("design:type", InvestigationActionStatus)
    ], InvestigationAction.prototype, "status", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], InvestigationAction.prototype, "created", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], InvestigationAction.prototype, "started", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], InvestigationAction.prototype, "duration", void 0);
    __decorate([
        EntityField({ data: 'pending_duration' }),
        __metadata("design:type", Number)
    ], InvestigationAction.prototype, "pendingDuration", void 0);
    __decorate([
        EntityField({ data: 'queued_duration' }),
        __metadata("design:type", Number)
    ], InvestigationAction.prototype, "queuedDuration", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", InvestigationActionCategory)
    ], InvestigationAction.prototype, "category", void 0);
    __decorate([
        EntityField({ data: 'host' }),
        __metadata("design:type", InvestigatedMachine)
    ], InvestigationAction.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'comment_count', defaultValue: 0 }),
        __metadata("design:type", Number)
    ], InvestigationAction.prototype, "commentCount", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Object)
    ], InvestigationAction.prototype, "commentData", void 0);
    __decorate([
        EntityField({ data: 'remediation_action_type' }),
        __metadata("design:type", RemediationActionType)
    ], InvestigationAction.prototype, "remediationActionType", void 0);
    __decorate([
        EntityField({ data: 'pending_type' }),
        __metadata("design:type", InvestigationPendingType)
    ], InvestigationAction.prototype, "pendingType", void 0);
    __decorate([
        EntityField({ data: 'is_remediation', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigationAction.prototype, "isRemediation", void 0);
    __decorate([
        EntityField({
            parse: ɵ7,
        }),
        __metadata("design:type", Array)
    ], InvestigationAction.prototype, "errors", void 0);
    __decorate([
        EntityField({ data: 'extra_data', defaultValue: {} }),
        __metadata("design:type", InvestigationActionExtraData)
    ], InvestigationAction.prototype, "extraData", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], InvestigationAction.prototype, "recommendation", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], InvestigationAction.prototype, "reason", void 0);
    __decorate([
        EntityField({ data: 'is_cleanup_applied', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigationAction.prototype, "dataRetentionApplied", void 0);
    __decorate([
        EntityField({ arrayOf: AirsEntity }),
        __metadata("design:type", Array)
    ], InvestigationAction.prototype, "entities", void 0);
    __decorate([
        EntityField({ data: 'undo_action_id' }),
        __metadata("design:type", Number)
    ], InvestigationAction.prototype, "undoActionId", void 0);
    __decorate([
        EntityField({ data: 'undone_action_id' }),
        __metadata("design:type", Number)
    ], InvestigationAction.prototype, "undoneActionId", void 0);
    __decorate([
        EntityField({
            data: 'IsOfficeAction',
            parse: ɵ8,
        }),
        __metadata("design:type", Boolean)
    ], InvestigationAction.prototype, "isOfficeAction", void 0);
    InvestigationAction = __decorate([
        Entity({
            singularName: 'Investigation Action',
            pluralName: 'Investigation Actions',
            endpoint: ɵ0,
            parseItemQuery: ɵ1,
            allItemsProperty: 'results',
            separateArrayParams: true,
            baseUrl: ɵ2,
            parseData: ɵ3,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
        }),
        __metadata("design:paramtypes", [Object])
    ], InvestigationAction);
    return InvestigationAction;
}(EntityModelBase));
export { InvestigationAction };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
