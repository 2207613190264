import { NgModule } from '@angular/core';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { CommandLineEntityTypeService} from './services/command-line.entity-type.service'
@NgModule({
	providers: [
		CommandLineEntityTypeService
	],
})
export class CommandLineModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(CommandLineEntityTypeService);
	}
}
