/* tslint:disable:template-click-events-have-key-events */
import { AssetFieldsService } from './../../../../@entities/@tvm/asset/services/asset.fields.service';
import { PrettyNumberService } from './../../../../../../../../projects/prettify/src/lib/services/pretty-number.service';
import { I18nService } from './../../../../../../../../projects/i18n/src/lib/services/i18n.service';
import { DataviewField } from './../../../../../../../../projects/dataview/src/lib/models/dataview-field.model';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Paris, DataQuerySortDirection } from '@microsoft/paris';
import { Asset } from '@wcd/domain';
import { TvmExposureScoreService } from '../../../services/tvm-exposure-score.service';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { FabricIconNames } from '@wcd/scc-common';
import { TextToken, TvmTextsService } from '../../../services/tvm-texts.service';
import { zip } from 'rxjs';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';

@Component({
	selector: 'top-exposed-machines-widget',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './top-exposed-machines.widget.html',
})
export class TvmTopExposedMachinesWidget extends ReportWidgetComponent<Array<Asset>> {
	private isMgSelected: boolean;
	fields: DataviewField<Asset>[];

	constructor(
		reportsService: ReportsService,
		private i18nService: I18nService,
		tvmExposureScoreService: TvmExposureScoreService,
		prettyNumberService: PrettyNumberService,
		assetFieldsService: AssetFieldsService,
		private tvmTextsService: TvmTextsService,
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		private router: Router,
		private paris: Paris
	) {
		super(reportsService);
		this.fields = assetFieldsService.fields.map(
			field => new DataviewField(Object.assign({}, field, { sort: { enabled: false } }))
		);
	}

	get widgetConfig() {
		return {
			id: 'topExposedMachinesWidget',
			name: this.i18nService.get('tvm.dashboard.topExposedDevices'),
			noDataMessage: () =>
				this.tvmTextsService.getText(TextToken.NoDataForWidget, {
					noDataKey: 'tvm.dashboard.noData.topExposedMachines',
					isGroupSelected: this.isMgSelected,
				}),
			noDataIcon: FabricIconNames.Trackers,
			NoIconLeftAlign: true,
			loadData: () => {
				const mg$ = this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1));
				const exposedMachines$ = this.paris
					.query(Asset, {
						page: 1,
						pageSize: 3,
						sortBy: [
							{
								field: 'exposureScore',
								direction: DataQuerySortDirection.descending,
							},
						],
					})
					.pipe(map(ds => ds.items.sort((a, b) => b.exposureScore - a.exposureScore).slice(0, 3)));
				return zip(mg$, exposedMachines$).pipe(
					map(([mg, exposedMachines]) => {
						this.isMgSelected = mg.isFiltering;
						return exposedMachines;
					})
				);
			},
			minHeight: '210px',
		};
	}

	openMachinesPage(): void {
		this.router.navigate(['/machines']);
	}

	openMachinePage(asset: Asset): void {
		this.router.navigate(['/machines', asset.id]);
	}
}
