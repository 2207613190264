import { Injectable } from '@angular/core';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { DownloadService } from '../../../shared/services/download.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';

const SETTINGS_API_ENDPOINT = '/admin';
const PHISHING_ENABLED_API_URL = 'phishing_enabled';

@Injectable()
export class AdvancedSettingsBackendService extends StoreBackendBase {
	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService
	) {
		super(http, downloadService);
	}

	get apiUrl() {
		return this.serviceUrlsService.automatedIr + SETTINGS_API_ENDPOINT;
	}

	getIsPhishingEnabled(): Observable<any> {
		return this.get(PHISHING_ENABLED_API_URL);
	}
}
