export const getAlertStatusIcon = alertStatusIconFilter();

function alertStatusIconFilter() {
	return function(input) {
		return getIcon(input);
	};
}

function getIcon(input: string): string {
	switch (input) {
		case 'New':
			return 'StatusCircleInner';
		case 'InProgress':
			return 'StatusCircleRing';
		case 'Resolved':
			return 'StatusCircleInner';
		default:
			return '';
	}
}
