var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, EntityModelBase, ValueObject } from '@microsoft/paris';
export var QueryProductContext;
(function (QueryProductContext) {
    QueryProductContext["Mdatp"] = "Mdatp";
    QueryProductContext["Itp"] = "Itp";
    QueryProductContext["Oatp"] = "Oatp";
})(QueryProductContext || (QueryProductContext = {}));
var HuntingDocEntity = /** @class */ (function (_super) {
    __extends(HuntingDocEntity, _super);
    function HuntingDocEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", String)
    ], HuntingDocEntity.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], HuntingDocEntity.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'Description' }),
        __metadata("design:type", String)
    ], HuntingDocEntity.prototype, "description", void 0);
    return HuntingDocEntity;
}(EntityModelBase));
export { HuntingDocEntity };
var HuntingDocQuery = /** @class */ (function (_super) {
    __extends(HuntingDocQuery, _super);
    function HuntingDocQuery() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'QueryText' }),
        __metadata("design:type", String)
    ], HuntingDocQuery.prototype, "queryText", void 0);
    __decorate([
        EntityField({ data: 'QueryProductContext' }),
        __metadata("design:type", String)
    ], HuntingDocQuery.prototype, "productContext", void 0);
    HuntingDocQuery = __decorate([
        ValueObject({
            singularName: 'hunting doc query reference',
            pluralName: 'hunting docs queries reference',
        })
    ], HuntingDocQuery);
    return HuntingDocQuery;
}(HuntingDocEntity));
export { HuntingDocQuery };
var HuntingDocRecord = /** @class */ (function (_super) {
    __extends(HuntingDocRecord, _super);
    function HuntingDocRecord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Queries', arrayOf: HuntingDocQuery }),
        __metadata("design:type", Array)
    ], HuntingDocRecord.prototype, "queries", void 0);
    HuntingDocRecord = __decorate([
        ValueObject({
            singularName: 'hunting doc record reference',
            pluralName: 'hunting doc records reference',
        })
    ], HuntingDocRecord);
    return HuntingDocRecord;
}(HuntingDocEntity));
export { HuntingDocRecord };
