import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceUrlsService } from '@wcd/app-config';
import { DateRangeModel, getUserTimeZone, TzDateService } from '@wcd/localization';
import { keyBy, merge } from 'lodash-es';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiBaseModel } from '@wcd/data';
import { DataError } from '../../shared/models/error.model';
import { DownloadService } from '../../shared/services/download.service';
import { ReportTimeRange } from '../../reports/models/report-time-range.interface';
import { ApiConfig, ReportWidgetModel } from '../../reports/models/report-widget.model';
import { ReportModel } from '../../reports/models/report.model';
import { WidgetType } from '../../reports/components/report-with-filters.component';
import { FiltersState, SerializedFilters } from '@wcd/ng-filters';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
import { Moment } from 'moment';
import { IBreadcrumbInfo, sccHostService } from '@wcd/scc-interface';

declare const moment: typeof import('moment');

export const reportWithFiltersFromDateProperty = 'fromDate';
export const reportWithFiltersToDateProperty = 'toDate';

@Injectable({
	providedIn: 'root',
})
export class ReportsService {
	private _currentTimeRange: ReportTimeRange;
	private _currentTimezone: number = getUserTimeZone();
	private _timeRangesIndex: { [value: string]: ReportTimeRange };
	reportTimeRanges: Array<ReportTimeRange>;

	reportState$: BehaviorSubject<ReportOptions>;

	get currentTimeRange(): ReportTimeRange {
		return this._currentTimeRange;
	}

	set currentTimeRange(timeRange: ReportTimeRange) {
		this._currentTimeRange = timeRange;
		this.updateOptions();
	}

	get timeRanges(): Array<ReportTimeRange> {
		return this.reportTimeRanges;
	}

	get currentTimezone(): number {
		return this._currentTimezone;
	}

	get defaultTimeRange(): ReportTimeRange {
		return this.reportTimeRanges.find((range) => range.value === '30');
	}

	set currentTimezone(value: number) {
		this._currentTimezone = value;
		this.updateOptions();
	}

	constructor(
		private http: HttpClient,
		private downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService,
		private route: ActivatedRoute,
		private authService: AuthService,
		private i18nService: I18nService,
		private tzDateService: TzDateService
	) {
		this.reportTimeRanges = [
			{ name: this.i18nService.get('report.time.range.last.day'), value: '1' },
			{ name: this.i18nService.get('report.time.range.last.week'), value: '7' },
			{ name: this.i18nService.get('report.time.range.last.30.days'), value: '30' },
			{ name: this.i18nService.get('report.time.range.last.3.months'), value: '90' },
			{ name: this.i18nService.get('report.time.range.last.6.months'), value: '180' },
			{
				name: this.i18nService.get('report.time.range.custom.range'),
				value: 'custom',
				allowCustomRange: true,
			},
		];
		this._currentTimeRange = this.defaultTimeRange;
		this.reportState$ = new BehaviorSubject(this.getCurrentOptions());
	}

	fixDataIfNeeded(filtersState: FiltersState, widgetType: WidgetType): SerializedFilters {
		if (!filtersState) filtersState = { selection: {}, serialized: {} };

		let urlDateRange: DateRangeModel;
		if (this.route.snapshot.queryParams['range']) {
			urlDateRange = DateRangeModel.fromString(
				this.tzDateService,
				this.route.snapshot.queryParams['range']
			);
		}

		let fromDate: Date =
			widgetType === WidgetType.Daily
				? new Date()
				: filtersState.selection[reportWithFiltersFromDateProperty];
		if (!fromDate) {
			// We don't have from date in case we just entered the page
			if (urlDateRange && urlDateRange.from) {
				fromDate = urlDateRange.from;
			} else {
				// The +1 is because date range starts from yesterday, and not from today
				const daysToSubtract = (+this.route.snapshot.queryParams['range'] || 30) + 1;
				fromDate = moment().subtract({ days: daysToSubtract }).toDate();
			}
		}

		const dateRange =
			widgetType === WidgetType.Daily ? 0 : +this.route.snapshot.queryParams['range'] || 30;
		const toDate =
			widgetType === WidgetType.Trend && filtersState.selection[reportWithFiltersToDateProperty]
				? filtersState.selection[reportWithFiltersToDateProperty]
				: widgetType === WidgetType.Trend && urlDateRange && urlDateRange.to
				? urlDateRange.to
				: new Date(new Date(fromDate.valueOf()).setDate(fromDate.getDate() + dateRange));

		const serializedFilters = filtersState.serialized;
		if (serializedFilters) {
			delete serializedFilters[reportWithFiltersFromDateProperty];
			delete serializedFilters[reportWithFiltersToDateProperty];
		}

		// Date only, time is not relevant and time causes Paris to send a few requests for the same data due to ms difference
		fromDate.setUTCHours(0, 0, 0, 0);
		toDate.setUTCHours(0, 0, 0, 0);
		return {
			...serializedFilters,
			[reportWithFiltersFromDateProperty]: fromDate.toISOString(),
			[reportWithFiltersToDateProperty]: toDate.toISOString(),
		};
	}

	calcCurrentDataDateRangeForTrend(serializedFilters: SerializedFilters): [Date, Date] {
		const to: Date =
			serializedFilters && reportWithFiltersToDateProperty in serializedFilters
				? new Date(<string>serializedFilters[reportWithFiltersToDateProperty])
				: this.getYesterday().toDate();

		const from: Date =
			serializedFilters && reportWithFiltersFromDateProperty in serializedFilters
				? new Date(<string>serializedFilters[reportWithFiltersFromDateProperty])
				: moment().startOf('day').subtract({ days: 31 }).toDate();

		return [from, to];
	}

	calcCurrentDataDateForDaily(serializedFilters: SerializedFilters, today: Date) {
		return serializedFilters && reportWithFiltersToDateProperty in serializedFilters
			? new Date(<string>serializedFilters.toDate)
			: today;
	}

	getDefaultDatesForDaily(): TimeBoundaries {
		const yesterday: string = this.getYesterday().toISOString();
		return {
			[reportWithFiltersFromDateProperty]: yesterday,
			[reportWithFiltersToDateProperty]: yesterday,
		};
	}

	getYesterday(): Moment {
		return moment.utc().startOf('day').subtract({ days: 1 });
	}

	getDefaultDatesForTrend(): TimeBoundaries {
		const yesterday: string = moment.utc().subtract({ days: 1 }).startOf('day').toISOString();
		const fromDate: string = moment.utc().subtract({ days: 31 }).startOf('day').toISOString();

		return {
			[reportWithFiltersFromDateProperty]: fromDate,
			[reportWithFiltersToDateProperty]: yesterday,
		};
	}

	exportReport(report: ReportModel): Promise<any> {
		const timeRange: string = this.currentTimeRange.allowCustomRange
			? this.currentTimeRange.dateRange.toString()
			: this.currentTimeRange.value;
		return this.downloadService.downloadFromUrl(
			`${this.serviceUrlsService.automatedIr}/reports/export?range=${timeRange}&report_type=${
				report.id
			}&timezone=${getUserTimeZone()}`
		);
	}

	userHasRequiredPermissions(widget: ReportWidgetModel): boolean {
		return (
			!widget.requiredPermissions ||
			this.authService.currentUser.hasMdeAllowedUserRoleAction(widget.requiredPermissions)
		);
	}

	loadWidgetData(widget: ReportWidgetModel, options?: ReportOptions): Observable<any> {
		if (widget.isDisabled || !this.userHasRequiredPermissions(widget)) {
			return of(null);
		}

		let data$: Observable<any>;
		if (!widget.api) {
			data$ = widget.loadData(options);
		} else {
			const apiList: Array<ApiConfig> = widget.api instanceof Array ? widget.api : [widget.api];
			const apiObservableList: Array<Observable<any>> = apiList.map((api: ApiConfig) => {
				const prefix: string = api.isExternal ? '' : `${this.serviceUrlsService.automatedIr}/`,
					url: string = prefix + (api.url instanceof Function ? api.url() : api.url);

				return this.http
					.get<{ data: any }>(
						url,
						ApiBaseModel.getUrlParams(merge({}, options, /*widget.state,*/ api.params))
					)
					.pipe(
						map((res) => {
							return res.data || res;
						})
					);
			});
			data$ = apiObservableList.length === 1 ? apiObservableList[0] : forkJoin(apiObservableList);
		}

		return widget.parseData
			? data$.pipe(
					map((data) => {
						try {
							return widget.parseData(data);
						} catch (error) {
							console.error(`Error parsing data for widget ${widget.name}:`, error);
							throw error;
						}
					}),
					catchError((error) => throwError(new DataError(error)))
			  )
			: data$;
	}

	getTimeRangeByValue(value: string): ReportTimeRange {
		if (!this._timeRangesIndex) this._timeRangesIndex = keyBy(this.reportTimeRanges, 'value');

		return this._timeRangesIndex[value];
	}

	setCustomTimeRange(dateRange: DateRangeModel, updateOptionsNeeded: Boolean = true): void {
		const customTimeRange: ReportTimeRange = this.getTimeRangeByValue('custom');
		customTimeRange.dateRange = dateRange;
		this._currentTimeRange = customTimeRange;
		if (updateOptionsNeeded) {
			this.updateOptions();
		}
	}

	/**
	 * For charts that display timeseries (axis x type = 'timeseries'), turns the label into a Date object, which is required by the charts.
	 * @param data
	 * @returns {*}
	 */
	static parseTimeSeriesData(data: any): Array<TimeSeriesDataItem> {
		const items: Array<TimeSeriesDataItem> = data instanceof Array ? data : data.values;
		return items.map((item: TimeSeriesDataItem) =>
			merge(item, { _label: item.label, label: new Date(item.from) })
		);
	}

	/**
	 * Makes sure all the dates in the range are present in the array of time series items. For example, if only today's date is present, but the range is the last 30 days, the returned array will be 30 items
	 * @param {Array<TimeSeriesDataItem>} items
	 * @param {Date} from
	 * @param {Date} to
	 * @returns {Array<TimeSeriesDataItem>}
	 */
	static padTimeSeriesDates(
		items: Array<TimeSeriesDataItem>,
		from: Date,
		to: Date,
		valueProperty: string = 'value'
	): Array<TimeSeriesDataItem> {
		const datesMap: Map<string, TimeSeriesDataItem> = new Map(),
			paddedItems: Array<TimeSeriesDataItem> = [];

		if (items) items.forEach((item: TimeSeriesDataItem) => datesMap.set(item.label.toDateString(), item));

		let currentDate: Date = new Date(from);

		do {
			const item: TimeSeriesDataItem = datesMap.get(currentDate.toDateString()) || {
				label: currentDate,
				_label: currentDate.toString(),
			};

			if (!item[valueProperty]) item[valueProperty] = 0;

			paddedItems.push(item);
			currentDate = new Date(currentDate);
			currentDate.setDate(currentDate.getDate() + 1);
		} while (currentDate < to);

		return paddedItems;
	}

	static getDateLabel(date: Date | number, range: string, timezone: number): string {
		const rangeParts: Array<number> =
				typeof range === 'string'
					? range.split(':').map((n) => parseInt(n, 10))
					: [parseInt(range, 10)],
			rangeDaysCount: number =
				rangeParts.length === 2
					? (rangeParts[1] - rangeParts[0]) / (1000 * 60 * 60 * 24)
					: rangeParts[0],
			m = timezone ? moment.utc(date).add(timezone, 'hours') : moment(date);

		if (rangeDaysCount > 0 && rangeDaysCount < 1.99) {
			const to = moment(m).add(2, 'hours');

			return [m.format('hh'), to.format('hh')].join('-') + to.format('A');
		}

		return m.format('MM/DD');
	}

	// The function returns y ticks instead of letting c3 to auto define them.
	// This is to get integers and 4 ticks always.
	// The ticks will have quite equal spaces based on maxTick.
	// The only case we have less than 4 ticks is if maxTick is less than 10.
	static getYTicks(maxTick: number): Array<number> {
		if (maxTick === 0) return [];
		const yTicksCount: number = maxTick < 6 ? 2 : maxTick < 10 ? 3 : 4;
		const yTickUnit = maxTick / yTicksCount;
		const yTicks: Array<number> = [];
		for (let i = 0; i <= yTicksCount; i++) {
			yTicks.push(Math.round(yTickUnit * i));
		}

		return yTicks;
	}

	private updateOptions() {
		this.reportState$.next(this.getCurrentOptions());
	}

	private getCurrentOptions() {
		return {
			timezone: this.currentTimezone,
			range: this.currentTimeRange.allowCustomRange
				? this.currentTimeRange.dateRange && this.currentTimeRange.dateRange.toString()
				: this.currentTimeRange.value,
		};
	}

	getTimeRangesValues(): ReadonlyArray<ReportTimeRange> {
		return this.reportTimeRanges.filter(
			(timeRange) =>
				/month/i.test(timeRange.name) || /days/i.test(timeRange.name) || timeRange.allowCustomRange
		);
	}

	setBreadcrumbs(reportName: string) {
		if (!sccHostService.isSCC || !reportName) {
			return;
		}
		const breadcrumbItems: IBreadcrumbInfo[] = [
			{
				text: this.i18nService.get('main.navigation.menu.item.reports'),
				state: 'securityreports',
			},
			{
				text: reportName,
				state: '',
			},
		];

		sccHostService.ui.setBreadcrumbs(breadcrumbItems);
	}
}

export interface ReportOptions {
	timezone?: number;
	range?: string;
}

export interface TimeSeriesDataItem {
	_label: string;
	label: Date;
	[index: string]: any;
}

interface TimeBoundaries {
	[reportWithFiltersFromDateProperty]: string;
	[reportWithFiltersToDateProperty]: string;
}

export const halfYearDate: Date = moment().subtract({ months: 6 }).toDate();
export const monthAgo: Date = moment().subtract({ days: 31 }).toDate();
export const fiveMonthDate: Date = moment().subtract({ months: 5 }).toDate();
export const yesterday: Date = moment().subtract({ days: 1 }).toDate();
