import { ModuleWithProviders } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';
import { CanDeactivateForm } from './guards/can-deactivate-form.guard';
import { MainNavService } from './main/services/main-nav.service';
import { DisplayTextPipe } from './pipes/display-text.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { BrowserDetectionService, CookiesService, A11yAnnouncerService } from '@wcd/shared';
import { ClipboardService } from './services/clipboard.service';
import { CsvService } from './services/csv.service';
import { DownloadService } from './services/download.service';
import { SccExportService } from './services/scc-export.service';
import { SeverityTypeColorService } from './services/severity-type-color.service';
import { EvidenceTypeColorService } from './services/evidence-type-color.service';
import { TimeRangesService } from './services/time-ranges.service';
import { TitleService } from './services/title.service';
import { CalculateRemainingDaysPipe } from './pipes/calculate-remaining-days.pipe';
import { CapitalizeStringPipe } from './pipes/capitalize.pipe';
import { QueryParamsCacheService } from './services/query-params-cache.service';
import { MitreTechniqueDisplayPipe, FilterInvalidMitreTechniques, MitreTechniqueLinkPipe, } from './pipes/mitre-technique-display.pipe';
import { AlertStatusIconPipe } from './pipes/alert-status-display.pipe';
import { DetectionTechnologyDisplayPipe } from './pipes/detection-technology-display.pipe';
import { DetectionStatusDisplayPipe, DetectionStatusTooltipPipe, DetectionStatusIconPipe, DetectionStatusI18nNamePipe, } from './pipes/detection-status-display.pipe';
import { TitlePipe } from './pipes/title.pipe';
import { ReportingService } from '../reporting/reporting.service';
var ɵ0 = { gfm: true, breaks: true };
var SharedModule = /** @class */ (function () {
    function SharedModule(markdownService) {
        markdownService.renderer.link = function (href, title, text) {
            return "<a href=\"" + href + "\" target=\"_blank\" " + (title ? 'title=' + title : '') + ">" + text + "</a>";
        };
    }
    SharedModule.forRoot = function () {
        return {
            ngModule: SharedModule,
            providers: [
                CanDeactivateForm,
                BrowserDetectionService,
                CookiesService,
                MainNavService,
                TimeAgoPipe,
                DisplayTextPipe,
                CalculateRemainingDaysPipe,
                CapitalizeStringPipe,
                TitlePipe,
                MitreTechniqueDisplayPipe,
                FilterInvalidMitreTechniques,
                MitreTechniqueLinkPipe,
                DetectionTechnologyDisplayPipe,
                AlertStatusIconPipe,
                DetectionStatusDisplayPipe,
                DetectionStatusTooltipPipe,
                DetectionStatusIconPipe,
                DetectionStatusI18nNamePipe,
                TruncatePipe,
                ClipboardService,
                TitleService,
                DownloadService,
                SccExportService,
                MarkdownService,
                CsvService,
                TimeRangesService,
                SeverityTypeColorService,
                EvidenceTypeColorService,
                QueryParamsCacheService,
                ReportingService,
                A11yAnnouncerService,
            ],
        };
    };
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
