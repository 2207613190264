/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../convergence-promotion/components/convergence-promotion-toolbar.component.ngfactory";
import * as i2 from "../../convergence-promotion/components/convergence-promotion-toolbar.component";
import * as i3 from "../../convergence-promotion/services/convergence-promotion.service";
import * as i4 from "../../reports/components/report.component.ngfactory";
import * as i5 from "../../reports/components/report.component";
import * as i6 from "../../shared-reports/services/reports.service";
import * as i7 from "../../insights/services/app-insights.service";
import * as i8 from "../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i11 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i12 from "../../../../../../projects/config/src/lib/services/polling.service";
import * as i13 from "../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i14 from "./security-operations.dashboard.component";
import * as i15 from "../../../../../../projects/config/src/lib/services/features.service";
var styles_SecurityOperationsDashboardComponent = [];
var RenderType_SecurityOperationsDashboardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SecurityOperationsDashboardComponent, data: {} });
export { RenderType_SecurityOperationsDashboardComponent as RenderType_SecurityOperationsDashboardComponent };
export function View_SecurityOperationsDashboardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "wcd-full-height"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "convergance-promotion-toolbar", [], null, null, null, i1.View_ConvergencePromotionToolbarComponent_0, i1.RenderType_ConvergencePromotionToolbarComponent)), i0.ɵdid(4, 49152, null, 0, i2.ConvergencePromotionToolbarComponent, [i3.ConvergencePromotionService], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "report", [["class", "wcd-full-height"]], null, null, null, i4.View_ReportComponent_0, i4.RenderType_ReportComponent)), i0.ɵdid(7, 4964352, null, 0, i5.ReportComponent, [i6.ReportsService, i7.AppInsightsService, i8.AppConfigService, i9.ActivatedRoute, i9.Router, i0.ComponentFactoryResolver, i10.LocaleConfigService, i11.I18nService, i12.PollingService, i13.TzDateService], { report: [0, "report"], showControls: [1, "showControls"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dashboardReport; var currVal_1 = false; _ck(_v, 7, 0, currVal_0, currVal_1); }, null); }
export function View_SecurityOperationsDashboardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dashboard", [], null, null, null, View_SecurityOperationsDashboardComponent_0, RenderType_SecurityOperationsDashboardComponent)), i0.ɵdid(1, 49152, null, 0, i14.SecurityOperationsDashboardComponent, [i15.FeaturesService, i8.AppConfigService], null, null)], null, null); }
var SecurityOperationsDashboardComponentNgFactory = i0.ɵccf("dashboard", i14.SecurityOperationsDashboardComponent, View_SecurityOperationsDashboardComponent_Host_0, {}, {}, []);
export { SecurityOperationsDashboardComponentNgFactory as SecurityOperationsDashboardComponentNgFactory };
