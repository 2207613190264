import { Entity, EntityModelBase } from '@microsoft/paris';

@Entity({
	singularName: 'mailMessageUAC',
	pluralName: 'Mailboxes',
	endpoint: 'mailboxes',
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})

export class MailboxUAC extends EntityModelBase {}