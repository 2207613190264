var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, EntityModelBase } from '@microsoft/paris';
import { ThreatInfo } from '../threat-info.value-object';
import { AssetsStatistics } from '../assets-statistics.value-object';
import { RemediationType } from '../remediation/remediation-task-type.enum';
import { RecommendationCategory } from './recommendation-category.enum';
import { EolState } from '../../software-version/eol-version-state.enum';
import { RecommendationStatus } from './recommendation-status.enum';
import { VulnerabilityType } from '../vulnerability/vulnerability-type.enum';
import { TvmSupportedOsPlatform } from '../tvm-supported-os-platform.enum';
import { TvmRecommendationOnboardingStatus } from './recommendation-onboarding-status.enum';
var ɵ0 = function (remediationType, rawData) {
    if (rawData.remediationType === '')
        return RemediationType.Update;
    return remediationType;
}, ɵ1 = function (data) { return data.remediationType === RemediationType.ConfigurationChange; }, ɵ2 = function (data) { return data.remediationType === RemediationType.ConfigurationChange; }, ɵ3 = function (data) { return data.remediationType === RemediationType.ConfigurationChange; }, ɵ4 = function (data) { return data.remediationType === RemediationType.ConfigurationChange; }, ɵ5 = function (data) { return data.remediationType === RemediationType.ConfigurationChange; }, ɵ6 = function (data) { return data.remediationType === RemediationType.ConfigurationChange; }, ɵ7 = function (data) { return data.remediationType === RemediationType.ConfigurationChange; }, ɵ8 = function (data) { return data.remediationType === RemediationType.ConfigurationChange; }, ɵ9 = function (data) { return data.remediationType === RemediationType.ConfigurationChange; }, ɵ10 = function (data) { return data.remediationType !== RemediationType.ConfigurationChange; }, ɵ11 = function (data) { return data.remediationType !== RemediationType.ConfigurationChange; }, ɵ12 = function (data) { return data.remediationType !== RemediationType.ConfigurationChange; };
var RecommendationBase = /** @class */ (function (_super) {
    __extends(RecommendationBase, _super);
    function RecommendationBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "title", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", Number)
    ], RecommendationBase.prototype, "weaknessesCount", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", ThreatInfo)
    ], RecommendationBase.prototype, "threatInfo", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", AssetsStatistics)
    ], RecommendationBase.prototype, "assetsStatistics", void 0);
    __decorate([
        EntityField({
            parse: ɵ0,
            required: true,
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "remediationType", void 0);
    __decorate([
        EntityField({
            required: true,
            data: 'recommendationCategory',
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "category", void 0);
    __decorate([
        EntityField({
            require: ɵ1,
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "subCategory", void 0);
    __decorate([
        EntityField({
            require: ɵ2,
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "description", void 0);
    __decorate([
        EntityField({
            require: ɵ3,
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "remediationDescription", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", Number)
    ], RecommendationBase.prototype, "exposureScoreImprovement", void 0);
    __decorate([
        EntityField({
            require: ɵ4,
        }),
        __metadata("design:type", Number)
    ], RecommendationBase.prototype, "secureScoreImprovement", void 0);
    __decorate([
        EntityField({
            require: ɵ5,
        }),
        __metadata("design:type", Array)
    ], RecommendationBase.prototype, "benchmarks", void 0);
    __decorate([
        EntityField({
            require: ɵ6,
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "potentialRisk", void 0);
    __decorate([
        EntityField({
            require: ɵ7,
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "scId", void 0);
    __decorate([
        EntityField({
            require: ɵ8,
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "cce", void 0);
    __decorate([
        EntityField({
            require: ɵ9,
        }),
        __metadata("design:type", Array)
    ], RecommendationBase.prototype, "relatedCVEs", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "productName", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], RecommendationBase.prototype, "productsNames", void 0);
    __decorate([
        EntityField({
            require: ɵ10,
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "vendor", void 0);
    __decorate([
        EntityField({
            require: ɵ11,
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "recommendedVersion", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "recommendedVendor", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "recommendedProgram", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], RecommendationBase.prototype, "requiredAttention", void 0);
    __decorate([
        EntityField({
            data: 'weaknessesCount',
            require: ɵ12,
        }),
        __metadata("design:type", Number)
    ], RecommendationBase.prototype, "vulnerabilitiesCount", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "productId", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], RecommendationBase.prototype, "oneAsset", void 0);
    __decorate([
        EntityField({ data: 'status' }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "status", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], RecommendationBase.prototype, "threats", void 0);
    __decorate([
        EntityField({
            data: 'remediationTasks',
        }),
        __metadata("design:type", Array)
    ], RecommendationBase.prototype, "remediationTasks", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], RecommendationBase.prototype, "isProductivityImpacted", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], RecommendationBase.prototype, "isEOL", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "eolSoftwareState", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], RecommendationBase.prototype, "eolSoftwareSinceDate", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], RecommendationBase.prototype, "hasEolVersions", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], RecommendationBase.prototype, "hasUpcomingEolVersions", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], RecommendationBase.prototype, "exposureScoreImprovementAfterApplyingExceptions", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], RecommendationBase.prototype, "secureScoreImprovementAfterApplyingExceptions", void 0);
    __decorate([
        EntityField({
            data: 'vulnerabilityWorkarounds',
        }),
        __metadata("design:type", Array)
    ], RecommendationBase.prototype, "workarounds", void 0);
    __decorate([
        EntityField({
            data: 'mostSevereVulnerabilityType',
        }),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "mostSevereVulnerabilityType", void 0);
    __decorate([
        EntityField({
            data: 'zeroDayAssetsAtRisk',
        }),
        __metadata("design:type", Number)
    ], RecommendationBase.prototype, "zeroDayAssetsAtRisk", void 0);
    __decorate([
        EntityField({
            data: 'patchReleaseDate',
        }),
        __metadata("design:type", Date)
    ], RecommendationBase.prototype, "patchReleaseDate", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], RecommendationBase.prototype, "tags", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "osPlatform", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RecommendationBase.prototype, "onboardingStatus", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], RecommendationBase.prototype, "hasIotDevice", void 0);
    return RecommendationBase;
}(EntityModelBase));
export { RecommendationBase };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
