import { SevilleModule } from '../../app/seville/seville.module';
import { AppInsightsService } from '../../../insights/services/app-insights.service';

SevilleModule.controller('seville.onboarding.preferences.create', onboardingCreateController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding.create', {
			url: '/create',
			views: {
				onboardingStepView: {
					template: `
						<div class="onboarding-create-modal">
                <div class="page-header-title">
                    Create your cloud instance
                </div>
                <br />
                <div>
                    <table>
                        <tr>
                            <td class="icon icon-lg icon-Error onboarding-create-warning-padding"></td>
                            <td>
                                You won't be able to change some of your preferences (such as the data storage location) after clicking 'Continue'.
                                <br />
                                If you want to check or make any changes, click 'Back to preferences' and review your preferences. Click 'Continue' if you want to set up your account.
                            </td>
                        </tr>
                    </table>
                </div>
                <br />
                <div class="col-xs-12">
                    <div class="col-xs-2 col-xs-offset-3">
                        <button class="onboarding-create-button onboarding-button onboarding-button-enabled btn-block"
                                ng-click="create()">
                                Continue
                        </button>
                    </div>
                    <div class="col-xs-2 col-xs-offset-3">
                        <button
                                class="onboarding-create-button onboarding-button"
                                ng-click="backToSettings()">
                                Back to preferences
                        </button>
                    </div>
                </div>
                <br />
                <br />
                <br />
            </div>
						`,
					controller: 'seville.onboarding.preferences.create',
					controllerAs: 'create',
				},
			},
		});
	},
]);

onboardingCreateController.$inject = ['$scope', '$state', 'appInsights', '$uibModalInstance'];

function onboardingCreateController($scope, $state, appInsights: AppInsightsService, $modalInstance) {
	$scope.create = function() {
		$scope.$parent.createTenant = true;
		$modalInstance.dismiss();
		$scope.$parent.stepPermissionsDone = true;
		$scope.$parent.stepWelcomeDone = true;
		$state.go('onboarding.processing');
	};

	$scope.backToSettings = function() {
		$scope.$parent.createTenant = false;
		$modalInstance.dismiss();
		appInsights.trackEvent('ButtonClick', {
			ButtonType: 'BackToSettings',
			Page: 'OnboardingCreate',
		});
	};
}
