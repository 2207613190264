import { SecurityAnalyticsControlSetting } from './security-analytics-settings.interface';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { SecurityAnalyticsSettings } from '@wcd/domain';

@Injectable()
export class SecurityAnalyticsService {
	constructor(
		private featuresService: FeaturesService,
		private i18nService: I18nService,
		private paris: Paris
	) {}

	private _securityAnalyticsControlSettings: Array<SecurityAnalyticsControlSetting>;
	private _isFresh: boolean = false;

	public getSecurityControlsSettings(): Observable<SecurityAnalyticsSettings> {
		return this.paris
			.getRepository<SecurityAnalyticsSettings>(SecurityAnalyticsSettings)
			.getItemById(1)
			.pipe(
				tap(controlSettings => {
					this.SecurityAnalyticsControlSettings.map(
						control => (control.exclude = controlSettings.excludedControls.includes(control.name))
					);
					this._isFresh = true;
				})
			);
	}

	public saveSecurityControlsSettings(): Observable<SecurityAnalyticsSettings> {
		if (!this._isFresh) {
			return throwError('Cannot save Security Analytics settings without getting them first');
		}

		return this.paris.getRepository<SecurityAnalyticsSettings>(SecurityAnalyticsSettings).save(
			new SecurityAnalyticsSettings({
				id: 1,
				excludedControls: this.SecurityAnalyticsControlSettings.filter(c => c.exclude).map(
					c => c.name
				),
			})
		);
	}

	public get SecurityAnalyticsControlSettings(): Array<SecurityAnalyticsControlSetting> {
		if (!this._securityAnalyticsControlSettings) {
			this._securityAnalyticsControlSettings = [
				{
					name: 'AntiVirus',
					exclude: false,
				},
				{
					name: 'ExploitGuard',
					exclude: false,
				},
				{
					name: 'AppGuard',
					exclude: false,
				},
				{
					name: 'SmartScreen',
					exclude: false,
				},
				{
					name: 'Firewall',
					exclude: false,
					feature: Feature.SecurityAnalyticsFirewallControl,
				},
				{
					name: 'CredentialGuard',
					exclude: false,
					feature: Feature.SecurityAnalyticsCredentialGuardControl,
				},
				{
					name: 'WindowsHello',
					exclude: false,
					feature: Feature.SecurityAnalyticsWinHelloControl,
				},
				{
					name: 'BitLocker',
					exclude: false,
					feature: Feature.SecurityAnalyticsBitLockerControl,
				},
				{
					name: 'UpgradeableWindowsVersion',
					exclude: false,
					feature: Feature.SecurityAnalyticsOsVersionUpgradeControl,
				},
			].filter(
				(control: SecurityAnalyticsControlSetting) =>
					!control.feature || this.featuresService.isEnabled(control.feature)
			);
		}

		return this._securityAnalyticsControlSettings;
	}
}
