import { AppConfigService } from '@wcd/app-config';
import { SevilleModule } from '../../../../../../app/seville/seville.module';
import { AppInsightsService } from '../../../../../../../insights/services/app-insights.service';

SevilleModule.directive('nonwindowsPartnerManagement', nonwindowsPartnerManagementDirective);

const NON_WINDOWS_PAGE_NAME = 'Preferences\\NonWindows';

function nonwindowsPartnerManagementDirective() {
	nonwindowsPartnerManagementController.$inject = [
		'$scope',
		'$http',
		'appConfig',
		'$interval',
		'$uibModal',
		'seville.settings.clientsideutils',
		'appInsights',
	];

	function nonwindowsPartnerManagementController(
		$scope,
		$http,
		appConfig: AppConfigService,
		$interval,
		$modal,
		clientSideUtils,
		appInsights: AppInsightsService
	) {
		var vm = this;

		vm.copyToClipboardGeneratedTokenCssClass = 'icon-Copy';

		vm.settingsChanging = false;
		vm.isGeneratingToken = false;
		vm.generatedToken = '';

		vm.generatedTokenRetryIntervalMs = 2000;
		vm.generatedTokenRetries = 0;
		vm.generatedTokenMaxRetries = 3;

		var onboardingpackageUrl = '/partners/' + vm.partner.partnerName.toLowerCase() + '/onboardingpackage';

		vm.uiMessages = {
			partnerChangeSettingsFailed: 'Unable to save preferences.',
			tokenGenerationFailed: 'Error in generating access token.',
		};

		vm.generateTokenButtonIsEnabled = function() {
			return (
				!vm.isreadonlyuser &&
				!vm.isSettingsLoading &&
				!vm.settingsChanging &&
				!vm.isGeneratingToken &&
				vm.partner.isEnabled &&
				vm.partner.isEnabledOrig
			);
		};

		vm.generateToken = function() {
			if (
				vm.isreadonlyuser ||
				vm.partner.isEnabled == false ||
				vm.settingsChanging ||
				vm.isSettingsLoading
			) {
				return;
			}

			vm.errorMessage = '';
			vm.isGeneratingToken = true;
			appInsights.trackEvent('ButtonClick', {
				Name: 'GeneratePartnerToken',
				Page: NON_WINDOWS_PAGE_NAME,
			});

			vm.generatedTokenRetries = 0;
			generateTokenInternal();
		};

		function generateTokenInternal() {
			vm.generatedTokenRetries++;

			$http
				.get(appConfig.serviceUrls.userRequests + onboardingpackageUrl, {
					timeout: 60000,
				})
				.then(
					function(response) {
						if (response.status === 200) {
							if (response.data === null) {
								handleGenerateTokenFailure(
									'response data is null',
									response.status,
									'null response'
								);
								return;
							}

							vm.generatedToken = response.data.toString();
							handleGenerateTokenFailure(false);
							vm.isGeneratingToken = false;
						} else {
							handleGenerateTokenFailure('status: ' + response.status, response.status, 'API');
						}
					},
					function(response) {
						handleGenerateTokenFailure('status: ' + response.status, response.status, 'Request');
					}
				)
				.then(
					function() {
						if (vm.generatedTokenRetries == vm.generatedTokenMaxRetries) {
							vm.isGeneratingToken = false;
						}
					},
					function() {
						if (vm.generatedTokenRetries == vm.generatedTokenMaxRetries) {
							vm.isGeneratingToken = false;
						}
					}
				);
		}

		function handleGenerateTokenFailure(msg?, responseStatus?, failureType?) {
			if (!msg && !responseStatus && !failureType) {
				vm.errorMessage = '';
			} else {
				console.log(msg);

				var eventData: any = {
					Name: 'GeneratePartnerToken',
					Page: NON_WINDOWS_PAGE_NAME,
					Retry: vm.generatedTokenRetries,
				};
				if (responseStatus) {
					eventData.ResponseStatus = responseStatus;
				}
				if (failureType) {
					eventData.FailureType = failureType;
				}
				appInsights.trackEvent('ButtonClick', eventData);

				// set error or retry
				if (vm.generatedTokenRetries >= vm.generatedTokenMaxRetries)
					vm.errorMessage = vm.uiMessages.tokenGenerationFailed;
				else {
					$interval(generateTokenInternal, vm.generatedTokenRetryIntervalMs, 1);
				}
			}
		}

		vm.copyToClipboard = function() {
			appInsights.trackEvent('ButtonClick', {
				Name: 'CopyGeneratedTokenButton',
				Page: NON_WINDOWS_PAGE_NAME,
			});
			clientSideUtils.copyToClipboard(
				'NonWindows',
				'settings.nonwindows.generatedTokenInputText.' + vm.partner.partnerName,
				setCopyToClipboardIcon
			);
		};

		function setCopyToClipboardIcon(id, icon) {
			vm.copyToClipboardGeneratedTokenCssClass = icon;
		}

		vm.saveStatus = function() {
			appInsights.trackEvent('ButtonClick', {
				Name: 'SavePartnerSettings-' + vm.partner.partnerName,
				Page: NON_WINDOWS_PAGE_NAME,
				NewStatus: vm.partner.isEnabled,
			});
			if (vm.partner.isEnabled == false) {
				var modalInstance = $modal.open({
					template: `
                    <div>
                      <div class="settings-endpoint-mgmt-tile-modal-title">
                        <span class="icon icon-Info"></span> Turn off {{partnerName}} Integration?
                      </div>
                      <div class="settings-endpoint-mgmt-tile-modal-section">
                          Turning integration off stops telemetry from devices with {{partnerName}}. To use this feature again, you will need to generate and apply a new access token.
                      </div>
                      <div class="text-right">
                          <button class="settings-button settings-customerTI-button settings-button-enabled" ng-click="ok()">
                            Yes, turn integration off
                          </button>
                          <span>
                             <button type="reset" class="settings-button settings-endpoint-mgmt-tile-modal-cancel-button" ng-click="cancel()">No</button>
                          </span>
                      </div>
                    </div>`,
					controller: 'seville.settings.nonwindows.confirmdisablemodal',
					backdrop: false,
					windowClass: 'settings-endpoint-mgmt-tile-modal',
					size: 'md',
					resolve: {
						partnerName: function() {
							return vm.partner.partnerName;
						},
					},
				});

				modalInstance.result.then(
					function() {
						saveEnabledStatus(vm.partner.isEnabled);
						appInsights.trackEvent('ButtonClick', {
							Name: 'ConfirmDisablePartnerModal-' + vm.partner.partnerName,
							Page: NON_WINDOWS_PAGE_NAME,
						});
					},
					function() {
						appInsights.trackEvent('ButtonClick', {
							Name: 'ConfirmDisablePartnerModal-' + vm.partner.partnerName,
							Page: NON_WINDOWS_PAGE_NAME,
						});
						vm.partner.isEnabled = vm.partner.isEnabledOrig;
					}
				);
			} else {
				saveEnabledStatus(vm.partner.isEnabled);
			}
		};

		function saveEnabledStatus(newStatus) {
			vm.settingsChanging = true;

			$http
				.post(
					appConfig.serviceUrls.management + '/SavePartnerSettings',
					{ PartnerName: vm.partner.partnerName, IsEnabled: newStatus },
					{
						timeout: 60000,
					}
				)
				.then(
					function(response) {
						if (response.status === 200) {
							if (response.data === null) {
								setSaveSettingsFailure(
									'response data is null',
									response.status,
									'null response'
								);
								return;
							}

							if (tryParseSettingsResponse(response.data)) {
								setSaveSettingsFailure(false);
								if (!newStatus) {
									vm.generatedToken = ''; // clear generated token if integration was turned off successfuly
								}
							} else {
								setSaveSettingsFailure(
									'failed to parse response data',
									response.status,
									'parse error'
								);
								return;
							}
						} else {
							setSaveSettingsFailure('status: ' + response.status, response.status, 'API');
						}
					},
					function(response) {
						setSaveSettingsFailure('status: ' + response.status, response.status, 'Request');
					}
				)
				.then(
					function() {
						// when finished the request update opt-in model and set isChanging=false
						vm.partner.isEnabled = vm.partner.isEnabledOrig;
						vm.settingsChanging = false;
					},
					function() {
						vm.partner.isEnabled = vm.partner.isEnabledOrig;
						vm.settingsChanging = false;
					}
				);
		}

		function tryParseSettingsResponse(responseData) {
			if (!responseData || !responseData.hasOwnProperty('IsEnabled')) {
				return false;
			}

			vm.partner.isEnabledOrig = responseData.IsEnabled ? true : false;

			return true;
		}

		function setSaveSettingsFailure(msg?, responseStatus?, failureType?) {
			if (!msg && !responseStatus && !failureType) {
				vm.errorMessage = '';
			} else {
				console.log(msg);

				var eventData: any = {
					Name: 'SavePartnerSettings-' + vm.partner.partnerName,
					Page: NON_WINDOWS_PAGE_NAME,
				};
				if (responseStatus) {
					eventData.ResponseStatus = responseStatus;
				}
				if (failureType) {
					eventData.FailureType = failureType;
				}
				appInsights.trackEvent('ButtonClick', eventData);

				vm.errorMessage = vm.uiMessages.partnerChangeSettingsFailed;
			}
		}
	}

	return {
		restrict: 'EA',
		scope: {
			partner: '=',
			isreadonlyuser: '=',
			isSettingsLoading: '=',
		},
		controllerAs: 'partnerManagement',
		bindToController: true,
		transclude: true,
		controller: nonwindowsPartnerManagementController,
		template: `
        <div class="settings-endpoint-mgmt-partner-toggle ms-Toggle">
          <input ng-class="{'disabled': partnerManagement.isreadonlyuser || partnerManagement.isSettingsLoading || partnerManagement.settingsChanging}" type="checkbox"
               id="partnerToggle.{{::partnerManagement.partner.partnerName}}" class="order ms-Toggle-input" ng-model="partnerManagement.partner.isEnabled"
               ng-click="partnerManagement.saveStatus()">
          <label ng-class="{'disabled':  partnerManagement.isreadonlyuser || partnerManagement.isSettingsLoading || partnerManagement.settingsChanging}" for="partnerToggle.{{::partnerManagement.partner.partnerName}}" class="order ms-Toggle-field ob-common-toggle">
          </label>
          <div class="ms-Toggle-description ob-common-toggle-description-title">
             <span class="ob-common-toggle-state" ng-class="{'settings-grey-text': partnerManagement.isreadonlyuser }">{{  partnerManagement.settingsChanging ? 'Pending' : (partnerManagement.partner.isEnabled ? 'On' : 'Off') }}</span>
               {{partnerManagement.partner.partnerName}}
               <div class="ob-common-toggle-description">
                 <ng-transclude>
                    Get telemetry from macOS and Linux devices with {{partnerManagement.partner.partnerName}}.
                 <ng-transclude>
               </div>
          </div>
          <span  ng-class="{'settings-visibility-hidden': ! partnerManagement.settingsChanging}" >
             <img class="settings-toggle-applying img-responsive" src="/assets/images/linear-loader.gif" />
          </span>
        </div>
        <div>
          <div class="settings-endpoint-mgmt-partner-appSettingsTitle">
             {{partnerManagement.partner.partnerName}} access token
             <span ng-if="partnerManagement.isGeneratingToken">
                 <img class="generating-token-circle-loading-gif" src="/assets/images/circle-loading.gif" /> Generating token...
             </span>
          </div>
          <div class="settings-endpoint-mgmt-token-section-padding">
            <button ng-click="partnerManagement.generateToken()"
                    class="settings-button settings-customerTI-button"
                    ng-disabled="partnerManagement.generateTokenButtonIsEnabled() == false"
                    ng-class="{ 'settings-button-disabled': partnerManagement.generateTokenButtonIsEnabled() == false, 'settings-button-enabled': partnerManagement.generateTokenButtonIsEnabled() == true }">
                Generate access token
            </button>
            <input id="settings.nonwindows.generatedTokenInputText.{{::partnerManagement.partner.partnerName}}" ng-model="partnerManagement.generatedToken" class="settings-customerTI-appPropertyDisabled settings-customerTI-generateTokensDisplaySpacing" />
            <span class="settings-clientsideutils-copyToClipboard-icon" ng-class="{'settings-clientsideutils-copy-enabled': partnerManagement.generatedToken, 'settings-clientsideutils-copy-disabled': !partnerManagement.generatedToken }"
                  ng-click="partnerManagement.copyToClipboard()">
                <i class="icon {{partnerManagement.copyToClipboardGeneratedTokenCssClass}}"></i> Copy
            </span>
            <div class="settings-error-message settings-customerTI-error-message-spacing">
                 {{partnerManagement.errorMessage}}
            </div>
          </div>
        </div>
    `,
	};
}
