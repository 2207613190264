import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { TotalSecureScore } from './total-secure-score.entity';
import { TvmAnalyticsApiCallSharedConfigurations } from '../analyticsEndPointUtils';

@ApiCall({
	...TvmAnalyticsApiCallSharedConfigurations,
	name: 'Read total secure score',
	method: 'GET',
	endpoint: 'configurations/securescore/total',
	cache: null,
	type: TotalSecureScore, //type is required when directly using ApiCall, in order to tell Paris how to parse the model correctly
})
export class TotalSecureScoreApiCall extends ApiCallModel<TotalSecureScore> {}
