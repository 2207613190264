import { Pipe, PipeTransform } from '@angular/core';
import { SlicePipe } from '@angular/common';

/**
 * Simple pipe to display `boolean` values.
 */
@Pipe({ name: 'safeSlice' })
export class SafeSlicePipe extends SlicePipe implements PipeTransform {
	constructor() {
		super();
	}
	/**
	 * Augment native angular slice pipe, if and of the indexes is not a number(ie undifined also), return immediatly, avoid doing array slice when any index is not defined
	 * @param value
	 * @param start
	 * @param end
	 */
	transform(value: any, start: any, end?: any): any {
		if (isNaN(start) || isNaN(end)) {
			return value;
		}

		return super.transform(value, start, end);
	}
}
