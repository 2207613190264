var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TvmColorService } from '../../../../../tvm/services/tvm-color.service';
import { EntityStatusComponentBase } from '../../../../../global_entities/components/entity-status/entity-status.component.base';
import { I18nService } from '@wcd/i18n';
var BaselineComplianceStatusComponent = /** @class */ (function (_super) {
    __extends(BaselineComplianceStatusComponent, _super);
    function BaselineComplianceStatusComponent(tvmColorService, i18nService) {
        var _this = _super.call(this) || this;
        _this.tvmColorService = tvmColorService;
        _this.i18nService = i18nService;
        return _this;
    }
    Object.defineProperty(BaselineComplianceStatusComponent.prototype, "configuration", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    BaselineComplianceStatusComponent.prototype.isSingleAsset = function (configuration) {
        return configuration.applicableDevices === 1;
    };
    BaselineComplianceStatusComponent.prototype.getIconClass = function (configuration) {
        return this.tvmColorService.getCompliantIcon(configuration.compliantDevices === 1) + " wcd-margin-xsmall-right";
    };
    BaselineComplianceStatusComponent.prototype.getComplianceMessage = function (configuration) {
        return configuration.compliantDevices === 1 ?
            this.i18nService.strings.tvm_baseline_compliant_enum_compliant :
            this.i18nService.strings.tvm_baseline_compliant_enum_notCompliant;
    };
    return BaselineComplianceStatusComponent;
}(EntityStatusComponentBase));
export { BaselineComplianceStatusComponent };
