/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/localization/src/lib/pipes/tz-date.pipe";
import * as i2 from "../../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i3 from "../../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i4 from "@angular/common";
import * as i5 from "./airs-active-directory-domain-details.component";
import * as i6 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_AirsActiveDirectoryDomainDetailsComponent = [];
var RenderType_AirsActiveDirectoryDomainDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AirsActiveDirectoryDomainDetailsComponent, data: {} });
export { RenderType_AirsActiveDirectoryDomainDetailsComponent as RenderType_AirsActiveDirectoryDomainDetailsComponent };
function View_AirsActiveDirectoryDomainDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "dt", [["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "dd", [["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i1.TzDatePipe, [i2.TzDateService, i3.LocaleConfigService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.airsEntities_activeDirectoryDomain_fields_firstSeen; _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.entity.firstSeen, "short")); _ck(_v, 6, 0, currVal_1); }); }
function View_AirsActiveDirectoryDomainDetailsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "dt", [["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "dd", [["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.airsEntities_activeDirectoryDomain_fields_name; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.entity.name; _ck(_v, 6, 0, currVal_1); }); }
function View_AirsActiveDirectoryDomainDetailsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "dt", [["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "dd", [["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.airsEntities_activeDirectoryDomain_fields_id; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.entity.id; _ck(_v, 6, 0, currVal_1); }); }
export function View_AirsActiveDirectoryDomainDetailsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 10, "dl", [["class", "key-values clearfix"], ["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AirsActiveDirectoryDomainDetailsComponent_1)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AirsActiveDirectoryDomainDetailsComponent_2)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AirsActiveDirectoryDomainDetailsComponent_3)), i0.ɵdid(10, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entity.firstSeen; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.entity.name; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.entity.id; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_AirsActiveDirectoryDomainDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "airs-active-directory-domain-details", [], null, null, null, View_AirsActiveDirectoryDomainDetailsComponent_0, RenderType_AirsActiveDirectoryDomainDetailsComponent)), i0.ɵdid(1, 49152, null, 0, i5.AirsActiveDirectoryDomainDetailsComponent, [i6.I18nService], null, null)], null, null); }
var AirsActiveDirectoryDomainDetailsComponentNgFactory = i0.ɵccf("airs-active-directory-domain-details", i5.AirsActiveDirectoryDomainDetailsComponent, View_AirsActiveDirectoryDomainDetailsComponent_Host_0, { entity: "entity" }, {}, []);
export { AirsActiveDirectoryDomainDetailsComponentNgFactory as AirsActiveDirectoryDomainDetailsComponentNgFactory };
