import { ApiCall, ApiCallModel, DataQuery } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { omit } from 'lodash-es';

export interface BilbaoRegistration {
	email: string;
	name: string;
	tenantId: string;
	useProvisioningStore: boolean;
}

export interface BilbaoRegistrationDataQuery extends DataQuery {
	where: BilbaoRegistration
}

@ApiCall({
	name: 'Submit registration request to managed threat hunting service',
	endpoint: (_config: WcdPortalParisConfig, query: BilbaoRegistrationDataQuery) => {
		if (query && query.where && query.where.useProvisioningStore) {
			return 'mte-classic-application';
		}
		return 'SubmitBilbaoRegistration';
	},
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig, query: BilbaoRegistrationDataQuery) => {
		if (query && query.where && query.where.useProvisioningStore) {
			return `${config.data.serviceUrls.defenderExpertsService}/mte/provisioning-store/v1.2/tenants/${query.where.tenantId}`;
		}
		return config.data.serviceUrls.management;
	},
	parseQuery: (params: BilbaoRegistration) => {
		return {
			params: omit(params, 'name', 'email'), // passed as request query params & also to endpoint & baseUrl keys
			data: omit(params, 'tenantId', 'useProvisioningStore'), // passed as request body
		}
	},
})
export class BilbaoRegistrationApiCall extends ApiCallModel<void, BilbaoRegistration> { }
