export enum AlertClassificationType {
	'Unknown' = 'Unknown',
	'NotSet' = 'NotSet',
	'FalsePositive' = 'FalsePositive',
	'TruePositive' = 'TruePositive',
	'BenignPositive' = 'BenignPositive',
}

export enum NewAlertClassificationType {
	'Unknown' = 'Unknown',
	'NotSet' = 'NotSet',
	'FalsePositive' = 'FalsePositive',
	'TruePositive' = 'TruePositive',
	'BenignPositive' = 'BenignPositive',
}

export const AlertClassificationId = {
	[AlertClassificationType.Unknown]: '0',
	[AlertClassificationType.FalsePositive]: '10',
	[AlertClassificationType.TruePositive]: '20',
	[NewAlertClassificationType.BenignPositive]: '30',
};

export const alertClassificationValues = [
	{
		id: AlertClassificationId.Unknown,
		name: 'Not set',
		nameI18nKey: 'alertsByClassification_unknown',
		type: AlertClassificationType.Unknown,
		priority: 3,
		isSelectable: true,
	},
	{
		id: AlertClassificationId.FalsePositive,
		name: 'False alert',
		nameI18nKey: 'alertsByClassification_falseAlert',
		type: AlertClassificationType.FalsePositive,
		priority: 2,
		isSelectable: true,
	},
	{
		id: AlertClassificationId.TruePositive,
		name: 'True alert',
		nameI18nKey: 'alertsByClassification_trueAlert',
		type: AlertClassificationType.TruePositive,
		priority: 1,
		allowDetermination: true,
		isSelectable: true,
	},
	{
		id: AlertClassificationId.BenignPositive,
		name: 'Benign Positive',
		nameI18nKey: 'alertsByClassification_BenignPositive',
		type: NewAlertClassificationType.BenignPositive,
		priority: 1,
		allowDetermination: true,
		isSelectable: true,
		combinedClassification: true
	},
];
