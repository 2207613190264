import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { AlertHistoryItem, Incident, IncidentHistoryItem } from '@wcd/domain';
import { CommentModel } from '../../../comments/models/comment.model';
import { DataSet, ModelBase, Paris } from '@microsoft/paris';
import { filter, map } from 'rxjs/operators';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { IncidentsService } from '../services/incidents.service';
import { AuthService, MtpPermission } from '@wcd/auth';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { Subscription } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AlertsService } from '../../alerts/services/alerts.service';
import { RbacControlSettings, RbacControlState } from '../../../rbac/models/rbac-control-settings.model';

@Component({
	selector: 'incident-comments',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<editable-comments-list
				[comments]="comments"
				[isSaving]="isSavingComment"
				[rbac]="rbacSettings"
				(save)="saveComment($event)"
				[readonly]="!incident.isFullyMachineRbacExposed"
				[isLoading]="isLoadingComments"
			></editable-comments-list>
		</wcd-panel>
	`,
})
export class IncidentCommentsComponent extends PanelContainer {
	@Input() incident: Incident;

	comments: Array<CommentModel> = [];
	isSavingComment: boolean = false;
	incidentSubscription: Subscription;
	rbacSettings: RbacControlSettings;
	isLoadingComments: boolean = false;

	constructor(
		router: Router,
		private paris: Paris,
		private activatedEntity: ActivatedEntity,
		private incidentsService: IncidentsService,
		private alertsService: AlertsService,
		private authService: AuthService,
		private changeDetectorRef: ChangeDetectorRef,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private liveAnnouncer: LiveAnnouncer
	) {
		super(router);
	}

	ngOnInit() {
		if (!this.incident) {
			this.incidentSubscription = this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof Incident))
				.subscribe((incident: Incident) => {
					this.incident = incident;
					this.setComments();
				});
		} else {
			this.setComments();
		}
	}

	ngOnDestroy() {
		this.incidentSubscription && this.incidentSubscription.unsubscribe();
	}

	setComments(): void {
		this.isLoadingComments = true;
		this.rbacSettings = {
			mtpPermissions: [MtpPermission.SecurityData_Manage],
			mtpWorkloads: this.incident.mtpWorkloads,
			requireAllPermissions: true,
			ignoreNonActiveWorkloads: true,
			state: RbacControlState.disabled,
		};

		this.paris
			.query(AlertHistoryItem, {
				where: { entityType: 'IncidentEntity', id: this.incident.id },
			})
			.pipe(map((dataSet: DataSet<AlertHistoryItem>) => dataSet.items))
			.subscribe(items => {
				this.comments = items.map((historyItem: AlertHistoryItem) => {
					return new CommentModel({
						id: historyItem.id,
						timestamp: historyItem.timestamp,
						user: historyItem.userName,
						message: this.alertsService.getAlertHistoryMessage(historyItem),
						icon: historyItem.type.icon,
					});
				});
				this.isLoadingComments = false;
				this.changeDetectorRef.markForCheck();
			});
	}

	saveComment(comment: string): void {
		this.isSavingComment = true;
		this.incidentsService.addComment([this.incident.id], comment).subscribe(
			(commentItem: IncidentHistoryItem) => {
				this.isSavingComment = false;
				this.comments = [
					new CommentModel({
						id: commentItem.id,
						user: (commentItem.user && commentItem.user.Upn) || this.authService.currentUser.name,
						message: commentItem.message || comment,
						timestamp: commentItem.timestamp || new Date(),
						icon: commentItem.icon,
					}),
					...(this.comments || []),
				];
				this.liveAnnouncer.announce(
					this.i18nService.get('comments.save.success', {
						date: commentItem.timestamp,
					})
				);
				this.changeDetectorRef.markForCheck();
			},
			(error: Error) => {
				this.isSavingComment = false;
				this.dialogsService.showError({
					title: error.name || '',
					message: this.i18nService.get('comments.save.error'),
					data: error.message,
				});
			}
		);
	}
}
