import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { WebContentFilteringCategoryEntityTypeService } from './services/web-content-filtering-category.entity-type.service';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../global_entities/global-entity-panels.module';
import { WebContentFilteringCategoryReportDetailsComponent } from './components/web-content-filtering-category-details.component';
import { WebContentFilteringAccessTrendOvertimeChart } from './widgets/web-content-filtering-access-trend-overtime-chart';
import { TimeSeriesChartModule } from '@wcd/charts';
import { DataTableModule } from '@wcd/datatable';
import { WebContentFilteringDomainEntityTypeService } from './services/web-content-filtering-domain.entity-type.service';
import { WebContentFilteringMachineListReportDetailsComponent } from './components/web-content-filtering-machine-list-details.component';
import { WebContentFilteringMachineListReportEntityTypeService } from './services/web-content-filtering-machine-list.entity-type.service';
import { WebThreatProtectionReportService } from '../../reporting/web-threat-protection/web-threat-protection-report.service';

@NgModule({
	imports: [
		SharedModule,
		GlobalEntitiesModule,
		GlobalEntityPanelsModule,
		TimeSeriesChartModule,
		DataTableModule,
	],
	declarations: [
		WebContentFilteringCategoryReportDetailsComponent,
		WebContentFilteringAccessTrendOvertimeChart,
		WebContentFilteringMachineListReportDetailsComponent,
	],
	providers: [
		WebContentFilteringCategoryEntityTypeService,
		WebContentFilteringDomainEntityTypeService,
		WebContentFilteringMachineListReportEntityTypeService,
		WebThreatProtectionReportService,
	],
	entryComponents: [
		WebContentFilteringCategoryReportDetailsComponent,
		WebContentFilteringMachineListReportDetailsComponent,
	],
})
export class WebContentFilteringReportEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(WebContentFilteringCategoryEntityTypeService);
		globalEntityTypesService.registerEntityType(WebContentFilteringDomainEntityTypeService);
		globalEntityTypesService.registerEntityType(WebContentFilteringMachineListReportEntityTypeService);
	}
}
