<ng-container>
    <wcd-expander [label]="'endpointManagement.linuxServer.offboarding.firstParty.title' | i18n"
        labelClass="wcd-font-size-l wcd-font-weight-regular wcd-padding-small-bottom"
        [isExpanded]="true"
        class="wcd-margin-large-bottom">
        <div class="wcd-margin-large-horizontal wcd-margin-medium-vertical">
            <manage-linux-first-party-offboarding [isServer]="isServer"></manage-linux-first-party-offboarding>
        </div>
    </wcd-expander>
    <ng-container *ngIf="isServer">
        <wcd-expander [label]="'endpointManagement.nonWindows.offboarding.thirdParty.title' | i18n"
            labelClass="wcd-font-size-l wcd-font-weight-regular wcd-padding-small-bottom"
            [isExpanded]="true"
            class="wcd-margin-large-bottom">
            <div class="wcd-margin-large-horizontal wcd-margin-medium-vertical">
                <manage-non-windows-third-party></manage-non-windows-third-party>
            </div>
        </wcd-expander>
    </ng-container>
</ng-container>
