import { ChangeDetectionStrategy, Component, Input, SimpleChanges } from '@angular/core';
import { Machine, OnboardingStatus } from '@wcd/domain';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
import { AppConfigService } from '@wcd/app-config';
import { MessageBarType } from 'office-ui-fabric-react';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { AuthService } from '@wcd/auth';
import { Router } from '@angular/router';
import { OnChanges } from '@angular/core';

@Component({
	selector: 'onboarding-status-message',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="wcd-margin-medium-bottom  message-bar" *ngIf="shouldDisplayOnboardingCta">
			<fab-message-bar [messageBarType]="MessageBarType.warning" [renderActions]="actions">
				<div class="wcd-font-size-s wcd-margin-none-bottom">
					<div>{{ 'DevicePaneOnboardingCtaMessage' | i18n }}</div>
				</div>
			</fab-message-bar>

			<ng-template #actions>
				<div class="wcd-margin-small-horizontal">
					<fab-messagebar-button
						[text]="'DevicePaneOnboardingCtaMessageButtonText' | i18n"
						(onClick)="goToOnboardingPage()"
						[disabled]="!isAdmin"
					></fab-messagebar-button>
				</div>
			</ng-template>
		</div>
	`,
})
export class MachineOnboardingStatusMessageComponent implements OnChanges {
	@Input()
	device: Machine;

	shouldDisplayAdIotUpsellMessage: boolean;
	shouldDisplayOnboardingCta: boolean;
	MessageBarType = MessageBarType;
	isAdmin = this.authService.currentUser.isMdeAdmin;

	constructor(
		private flavorService: FlavorService,
		private featuresService: FeaturesService,
		private router: Router,
		private appInsightsService: AppInsightsService,
		private authService: AuthService,
		private appConfigService: AppConfigService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.device) {
			return;
		}

		const ifOpenedToFlavor = this.flavorService.isEnabled(AppFlavorConfig.devices.onboardingStatus);
		const isDeviceDiscoveryEnabled = !this.appConfigService.magellanOptOut;
		const isOnboardingCtaEnabled = this.featuresService.isEnabled(
			Feature.MagellanDevicePageOnboardingCta
		);

		this.shouldDisplayOnboardingCta =
			ifOpenedToFlavor &&
			isDeviceDiscoveryEnabled &&
			isOnboardingCtaEnabled &&
			this.device.onboardingStatus === OnboardingStatus.NotOnboarded;
	}

	goToOnboardingPage() {
		this.appInsightsService.trackEvent('device-pane-clicked-on-onboarding-cta');
		this.router.navigate(['/preferences2/onboarding']);
	}
}
