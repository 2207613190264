var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { SystemExclusionListType } from './system-exclusion-list-type.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.automatedIr; }, ɵ1 = function (item, entity, config, options) {
    var suffix;
    if (!item.isNew)
        suffix = String(item.id);
    else if (options && options.params && options.params['type'] === 'certificate')
        suffix = 'certificate/upload';
    else
        suffix = 'all';
    return 'acl/' + suffix;
}, ɵ2 = function (item, modelData) {
    var apiObj = Object.assign(modelData, item.properties);
    delete apiObj.file_hash_type;
    for (var p in apiObj) {
        if (apiObj[p] === undefined || apiObj[p] === null)
            delete apiObj[p];
    }
    if (apiObj.ip) {
        delete apiObj.from_ip;
        delete apiObj.to_ip;
    }
    if (apiObj['file[]']) {
        var formData = new FormData();
        for (var p in apiObj)
            formData.append(p, apiObj[p]);
        return formData;
    }
    return apiObj;
}, ɵ3 = function (properties) {
    return properties && properties.from_ip
        ? Object.assign({
            ip: properties.to_ip !== properties.from_ip
                ? properties.from_ip + " - " + properties.to_ip
                : properties.from_ip,
        }, properties)
        : properties;
}, ɵ4 = ['file[]', 'file'];
var SystemExclusion = /** @class */ (function (_super) {
    __extends(SystemExclusion, _super);
    function SystemExclusion() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(SystemExclusion.prototype, "isReadonly", {
        get: function () {
            return (this.properties && this.properties.issuer) || false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemExclusion.prototype, "isNew", {
        get: function () {
            return this.id === undefined;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'creation_time' }),
        __metadata("design:type", Date)
    ], SystemExclusion.prototype, "creationTime", void 0);
    __decorate([
        EntityField({ data: 'creator_name' }),
        __metadata("design:type", String)
    ], SystemExclusion.prototype, "creatorName", void 0);
    __decorate([
        EntityField({ data: 'acl_type' }),
        __metadata("design:type", SystemExclusionListType)
    ], SystemExclusion.prototype, "listType", void 0);
    __decorate([
        EntityField({
            defaultValue: Object.freeze({}),
            parse: ɵ3,
        }),
        __metadata("design:type", Object)
    ], SystemExclusion.prototype, "properties", void 0);
    __decorate([
        EntityField({ data: 'entity_id' }),
        __metadata("design:type", Object)
    ], SystemExclusion.prototype, "entityId", void 0);
    __decorate([
        EntityField({ data: ɵ4 }),
        __metadata("design:type", File)
    ], SystemExclusion.prototype, "file", void 0);
    SystemExclusion = __decorate([
        Entity({
            singularName: 'Allowed/Blocked list rule',
            pluralName: 'Allowed/Blocked list rules',
            endpoint: 'acl',
            allItemsProperty: 'results',
            allItemsEndpoint: '/all',
            separateArrayParams: true,
            baseUrl: ɵ0,
            parseSaveQuery: ɵ1,
            serializeItem: ɵ2,
        })
    ], SystemExclusion);
    return SystemExclusion;
}(EntityModelBase));
export { SystemExclusion };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
