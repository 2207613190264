import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import {
	HuntingRuleCustomSupportedActionType,
	CustomActionMachineIsolationType,
	EmailActionType,
	EmailSubmissionCategory,
	EmailSubmissionReason,
	EmailSubmissionObjectType,
	EmailSubmissionType,
} from '../hunting-rule-custom-action.entity';
import { HuntingRuleEntityType } from '../hunting-rule-impacted-entity.value-object';

@ValueObject({
	singularName: 'hunting custom action entity',
	pluralName: 'Hunting custom actions entities',
})
export class HuntingEntityIdentifiers extends ModelBase {
	@EntityField({ data: 'EntityType' })
	entityType: HuntingRuleEntityType;

	@EntityField({ data: 'EntityIdField' })
	entityIdField: string;

	@EntityField({ data: 'EntityNameField' })
	entityNameField?: string;
}

@ValueObject({
	singularName: 'hunting custom action additional fields',
	pluralName: 'hunting custom action additional fields',
})
export class HuntingCustomActionAdditionalFields extends ModelBase {
	@EntityField({ data: 'RbacGroupIds' })
	rbacGroupIds?: Array<number>;

	@EntityField({ data: 'IsolationType' })
	isolationType?: CustomActionMachineIsolationType;

	@EntityField({ data: 'EmailActionType' })
	emailActionType?: EmailActionType;
}

@ValueObject({
	singularName: 'hunting custom action',
	pluralName: 'Hunting custom actions',
})
export class HuntingCustomAction extends ModelBase {
	@EntityField({ data: 'ActionType' })
	actionType: HuntingRuleCustomSupportedActionType;

	@EntityField({ data: 'Entities', arrayOf: HuntingEntityIdentifiers, defaultValue: [] })
	entities: Array<HuntingEntityIdentifiers>;

	@EntityField({
		data: 'AdditionalFields',
		serialize: (item: HuntingCustomActionAdditionalFields) =>
			item && {
				RbacGroupIds: item.rbacGroupIds,
				IsolationType: item.isolationType,
				EmailActionType: item.emailActionType,
			},
	})
	additionalFields?: HuntingCustomActionAdditionalFields;
}

@ValueObject({
	singularName: 'start new email report submission',
	pluralName: 'start new email report submissions',
})
export class StartNewEmailReportSubmission extends ModelBase {
	@EntityField({ data: 'Category' })
	Category: EmailSubmissionCategory;

	@EntityField({ data: 'EmailType' })
	EmailType: EmailSubmissionType;

	@EntityField({ data: 'ObjectId' })
	ObjectId: string;

	@EntityField({ data: 'Reason' })
	Reason: EmailSubmissionReason;

	@EntityField({ data: 'Recipients', arrayOf: String, defaultValue: [] })
	Recipients: Array<string>;

	@EntityField({ data: 'Type' })
	Type: EmailSubmissionObjectType;
}

@ValueObject({
	singularName: 'start trigger investigation',
	pluralName: 'start trigger investigations',
})
export class TriggerInvestigationSubmission extends ModelBase {
	@EntityField({ data: 'NetworkMessageId' })
	NetworkMessageId: string;

	@EntityField({ data: 'Recipients' })
	Recipients: Array<string>;

	@EntityField({ data: 'Recipient' })
	Recipient: string;
}
