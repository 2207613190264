import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogsModule } from '../dialogs/dialogs.module';
import { FeedbackComponent } from './components/feedback.component';
import { FeedbackService } from './services/feedback.service';
import { FabIconModule, FabButtonModule, FabSpinnerModule, FabMessageBarModule } from '@angular-react/fabric';
import { SharedModule } from '../shared/shared.module';
import { FeedbackModalComponent } from './components/feedback.modal.component';
import { AskThreatExpertModalComponent } from './components/ask-threat-expert.modal.component';
import { AskTheExpertPanelComponent } from './components/ask-threat-expert.panel.component';
import { AskThreatExpertService } from './services/ask-threat-expert.service';
import { WcdFormsModule } from '@wcd/forms';
import { SnackbarModule } from '@wcd/snackbar';

@NgModule({
	imports: [
		CommonModule,
		DialogsModule,
		FabIconModule,
		FabButtonModule,
		FabSpinnerModule,
		FabMessageBarModule,
		SharedModule,
		WcdFormsModule,
		SnackbarModule,
	],
	declarations: [
		FeedbackComponent,
		FeedbackModalComponent,
		AskThreatExpertModalComponent,
		AskTheExpertPanelComponent,
	],
	providers: [FeedbackService, AskThreatExpertService],
	entryComponents: [FeedbackModalComponent, AskThreatExpertModalComponent, AskTheExpertPanelComponent],
})
export class FeedbackModule {}
