import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../shared/shared.module';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { SecurityRecommendationFieldsService } from './services/security-recommendation.fields.service';
import { RecommendationContextFieldsService } from './services/recommendation-context.fields.service';
import { SingleAssetSecurityRecommendationFieldsService } from './services/single-asset-security-recommendation.fields.service';
import { SecurityRecommendationEntityTypeService } from './services/security-recommendation.entity-type.service';
import { SecurityRecommendationEntityPanelComponent } from './components/security-recommendation.entity-panel.component';
import { RecommendationContextComponent } from './components/recommendation-context.component';
import { SecurityRecommendationService } from './services/security-recommendation.service';
import { RemediationTaskCreationComponent } from './components/remediation-task-creation.component';
import { RecommendationContextPropertiesPanelComponent } from '../../../global_entities/components/entity-panels/recommendation-context-properties.panel.component';
import { I18nModule } from '@wcd/i18n';
import { FabDatePickerModule, FabMessageBarModule, FabSpinnerModule } from '@angular-react/fabric';
import { SingleAssetSecurityRecommendationEntityTypeService } from './services/single-asset-security-recommendation.entity-type.service';
import { RecommendationExceptionCreationComponent } from './components/recommendation-exception-creation.component';
import { SingleSoftwareSecurityRecommendationFieldsService } from './services/single-software-security-recommendation.fields.service';
import { PanelsModule } from '@wcd/panels';
import { WcdFormsModule } from '@wcd/forms';
import { TvmModule } from '../../../tvm/tvm.module';
import { RemediationItsmToolsService } from '../remediation/remediation-tasks/services/remediation-itsm-tools-service';
import { TagsModule } from '../../../tags/tags.module';

@NgModule({
	imports: [
		SharedModule,
		FabDatePickerModule,
		GlobalEntitiesModule,
		GlobalEntityPanelsModule,
		DataViewsModule,
		FabMessageBarModule,
		FabSpinnerModule,
		PanelsModule,
		WcdFormsModule,
		TvmModule,
		TagsModule,
	],
	declarations: [
		RemediationTaskCreationComponent,
		RecommendationExceptionCreationComponent,
		SecurityRecommendationEntityPanelComponent,
		RecommendationContextPropertiesPanelComponent,
		RecommendationContextComponent,
	],
	exports: [
		SecurityRecommendationEntityPanelComponent,
		RecommendationContextPropertiesPanelComponent,
		RecommendationContextComponent,
	],
	entryComponents: [
		RemediationTaskCreationComponent,
		RecommendationExceptionCreationComponent,
		SecurityRecommendationEntityPanelComponent,
		RecommendationContextPropertiesPanelComponent,
		RecommendationContextComponent,
	],
	providers: [
		I18nModule,
		SecurityRecommendationService,
		SecurityRecommendationFieldsService,
		RecommendationContextFieldsService,
		SingleAssetSecurityRecommendationFieldsService,
		SecurityRecommendationEntityTypeService,
		SingleSoftwareSecurityRecommendationFieldsService,
		SingleAssetSecurityRecommendationEntityTypeService,
		RemediationItsmToolsService,
	],
})
export class SecurityRecommendationEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(SecurityRecommendationEntityTypeService);
		globalEntityTypesService.registerEntityType(SingleAssetSecurityRecommendationEntityTypeService);
	}
}
