/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/ngx-markdown/ngx-markdown.ngfactory";
import * as i2 from "ngx-markdown";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../cms/services/cms.service";
import * as i7 from "@microsoft/paris/dist/lib/paris";
import * as i8 from "../../../shared/services/download.service";
import * as i9 from "./alerted-actor.component";
var styles_AlertedActorComponent = [];
var RenderType_AlertedActorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertedActorComponent, data: {} });
export { RenderType_AlertedActorComponent as RenderType_AlertedActorComponent };
function View_AlertedActorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [["class", "wcd-margin-none-all\n\t\t\t\t   wcd-padding-vertical\n\t\t\t\t   wcd-padding-right\n\t\t\t\t   wcd-font-weight-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "markdown", [["class", "wcd-block ck-content"]], null, null, null, i1.View_MarkdownComponent_0, i1.RenderType_MarkdownComponent)), i0.ɵdid(6, 4767744, null, 0, i2.MarkdownComponent, [i0.ElementRef, i2.MarkdownService], { data: [0, "data"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.content; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_0); }); }
function View_AlertedActorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadReport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), "alerts.alertActor.downloadReport")); _ck(_v, 3, 0, currVal_0); }); }
function View_AlertedActorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [["class", "wcd-margin-none-all\n\t\t\t   wcd-padding-vertical\n\t\t\t   wcd-padding-right\n\t\t\t   wcd-border-bottom\n\t\t\t   wcd-font-weight-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t", "\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertedActorComponent_2)), i0.ɵdid(6, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertedActorComponent_3)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.actor.sections; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.actor.linkToReport; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actor.name; _ck(_v, 3, 0, currVal_0); }); }
export function View_AlertedActorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.I18nPipe, [i5.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertedActorComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actor; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AlertedActorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "alerted-actor", [], null, null, null, View_AlertedActorComponent_0, RenderType_AlertedActorComponent)), i0.ɵprd(512, null, i6.CmsService, i6.CmsService, [i7.Paris, i8.DownloadService]), i0.ɵdid(2, 573440, null, 0, i9.AlertedActorComponent, [i7.Paris, i5.I18nService, i6.CmsService], null, null)], null, null); }
var AlertedActorComponentNgFactory = i0.ɵccf("alerted-actor", i9.AlertedActorComponent, View_AlertedActorComponent_Host_0, { actorName: "actorName" }, {}, []);
export { AlertedActorComponentNgFactory as AlertedActorComponentNgFactory };
