var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { GetEmailBodyApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { MessageBarType } from 'office-ui-fabric-react';
import { ErrorsDialogService } from '../../../dialogs/services/errors-dialog.service';
import { sccHostService } from '@wcd/scc-interface';
var DownloadEmailPanelComponent = /** @class */ (function (_super) {
    __extends(DownloadEmailPanelComponent, _super);
    function DownloadEmailPanelComponent(router, i18nService, paris, downloadService, http, errorsDialogService, changeDetectorRef) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.paris = paris;
        _this.downloadService = downloadService;
        _this.http = http;
        _this.errorsDialogService = errorsDialogService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.messageBarType = MessageBarType;
        _this.isDownloading = false;
        _this.agreedToTerms = false;
        return _this;
    }
    DownloadEmailPanelComponent.prototype.downloadEmail = function () {
        return __awaiter(this, void 0, void 0, function () {
            var recipient, EnableNewMailItemAPI, e_1, fileName, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isDownloading = true;
                        this.changeDetectorRef.detectChanges();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        recipient = this.emailMetadata &&
                            this.emailMetadata.recipients &&
                            this.emailMetadata.recipients.length &&
                            this.emailMetadata.recipients[0];
                        EnableNewMailItemAPI = false;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, sccHostService.auth.isInRole('setting:AntiSpamCHN$EnableNewMailItemAPI')];
                    case 3:
                        EnableNewMailItemAPI = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [4 /*yield*/, this.paris
                            .apiCall(GetEmailBodyApiCall, {
                            internetMessageId: this.emailMetadata.internetMessageId,
                            recipient: recipient,
                            EnableNewMailItemAPI: EnableNewMailItemAPI,
                        })
                            .toPromise()];
                    case 6:
                        _a.sent();
                        fileName = (this.emailMetadata.subject ||
                            this.i18nService.strings.airsEntities_emailMessage_downloadEmail_noSubject) + ".eml";
                        return [4 /*yield*/, this.downloadService.download(
                            // using Angular's http client because Paris doesn't pass the x-xsrf-token header
                            this.http.post('/api/Explorer/GetFullEmailMessage', {
                                FileName: encodeURIComponent(fileName),
                                InternetMessageId: this.emailMetadata.internetMessageId,
                                Recepient: recipient,
                            }, {
                                responseType: 'blob',
                            }), fileName)];
                    case 7:
                        _a.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        e_2 = _a.sent();
                        this.errorsDialogService.showError({
                            title: this.i18nService.strings.common_error,
                            data: this.i18nService.strings.airsEntities_emailMessage_downloadEmail_downloadError,
                        });
                        this.isDownloading = false;
                        this.changeDetectorRef.detectChanges();
                        return [2 /*return*/];
                    case 9:
                        this.destroy();
                        this.changeDetectorRef.detectChanges();
                        return [2 /*return*/];
                }
            });
        });
    };
    return DownloadEmailPanelComponent;
}(PanelContainer));
export { DownloadEmailPanelComponent };
