import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

const TRANSITION_END_EVENT = 'transitionend';

@Directive({
	selector: '[wcdCollapse]',
})
export class CollapseDirective implements OnChanges {
	@Input() wcdCollapse: boolean = false;
	@Input() duration: string = '0.3s';

	private animationFrames: Array<number> = [];
	private originalStyle: string;
	private originalHeight: number;

	constructor(private el: ElementRef) {}

	ngOnChanges(changes) {
		if (this.el && this.el.nativeElement) {
			if (this.originalHeight === undefined) {
				this.originalHeight = this.el.nativeElement.scrollHeight;
			}

			if (this.originalStyle === undefined) {
				this.originalStyle = this.el.nativeElement.style.cssText;
			}

			// ignore the first change, only trigger after init
			if (changes.wcdCollapse && !changes.wcdCollapse.firstChange) {
				if (changes.wcdCollapse.currentValue) {
					this.collapseElement();
				} else {
					this.expandElement();
				}
			} else {
				// first change
				if (this.wcdCollapse) {
					this.hideElement();
				}
			}
		}
	}

	private collapseElement() {
		this.cleanup();
		const displayedHeight = this.el.nativeElement.getBoundingClientRect().height;
		const newStyle: Partial<CSSStyleDeclaration> = {
			height: 'auto',
			overflow: 'hidden',
		};
		Object.assign(this.el.nativeElement.style, newStyle);
		this.animationFrames.push(
			requestAnimationFrame(() => {
				newStyle.height = displayedHeight + 'px';
				newStyle.transitionProperty = 'height';
				newStyle.transitionDuration = this.duration;
				newStyle.transitionTimingFunction = 'ease-out';
				Object.assign(this.el.nativeElement.style, newStyle);

				this.animationFrames.push(
					requestAnimationFrame(() => {
						this.el.nativeElement.style.height = '0px';
						this.el.nativeElement.addEventListener(TRANSITION_END_EVENT, this.onCollapseEnd);
					})
				);
			})
		);
	}

	private expandElement() {
		this.cleanup();
		Object.assign(this.el.nativeElement.style, <Partial<CSSStyleDeclaration>>{
			height: '0px',
			overflow: 'hidden',
			visibility: 'visible',
			transitionProperty: 'height',
			transitionDuration: this.duration,
			transitionTimingFunction: 'ease-out',
		});

		this.animationFrames.push(
			requestAnimationFrame(() => {
				const contentHeight = Math.max(this.el.nativeElement.scrollHeight, this.originalHeight);
				this.el.nativeElement.style.height = contentHeight + 'px';
				this.el.nativeElement.addEventListener(TRANSITION_END_EVENT, this.onExpandEnd);
			})
		);
	}

	private hideElement() {
		this.el.nativeElement.style.height = '0px';
		this.el.nativeElement.style.overflow = 'hidden';
		this.el.nativeElement.style.visibility = 'hidden';
	}

	onExpandEnd = () => {
		this.resetElement();
	};

	onCollapseEnd = () => {
		this.resetElement();
		this.hideElement();
	};

	private resetElement() {
		this.el.nativeElement.removeEventListener(TRANSITION_END_EVENT, this.onExpandEnd);
		this.el.nativeElement.style = this.originalStyle;
	}

	private cleanup() {
		this.animationFrames.forEach(f => cancelAnimationFrame(f));
		this.animationFrames = [];
		this.el.nativeElement.removeEventListener(TRANSITION_END_EVENT, this.onExpandEnd);
		this.el.nativeElement.removeEventListener(TRANSITION_END_EVENT, this.onCollapseEnd);
	}
}
