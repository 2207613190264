import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { PrettyNumberService } from '@wcd/prettify';
import { BaseType, select, Selection } from 'd3-selection';
import { arc } from 'd3-shape';
import { ChartComponent } from '../chart.component.base';
import { get } from 'lodash-es';

const labelSize: number = 25;
const LABEL_TEXT_CSS_CLASS: string = 'ms-pie-label-text';
const LABEL_LINE_CSS_CLASS: string = 'ms-pie-label-line';
const DYNAMIC_TITLE_CSS_CLASS: string = 'ms-pie-dynamic-title';
const NO_DATA_ARC_CSS_CLASS = 'no-data-arc';

let lastId = 0;

@Component({
	selector: 'wcd-pie-chart',
	template: `
		<div
			class="pie-chart-wrapper"
			[id]="'pie-chart-'+id"
			[class.wcd-full-height]="isFullHeight"
			[attr.aria-label]="ariaLabel || ('charts.pieChart' | i18n)"
			[class.wcd-pie-align-center]="alignCenter"
		>
			<div
				class="chart"
				[class.wcd-full-height]="isFullHeight"
				[attr.data-track-id]="settings.tracking?.id"
				[attr.data-track-type]="settings.tracking?.type"
			></div>
			<div class="pie-chart-content">
				<ng-content></ng-content>
			</div>
		</div>
	`,
	styles: [`
		.wcd-pie-align-center {
			text-align: center;
		}
	`],
})
export class PieChartComponent extends ChartComponent {
	protected chartSettings: any;
	@Input() upperTitle: string;
	@Input() labelsOutside: boolean = true;
	@Input() allowTitleClick: boolean = false;
	@Input() isFullHeight: boolean = false;
	@Input() ariaLabel: string = null;
	@Input() role: string = 'group';
	@Input() alignCenter: boolean = false;

	@Output() titleClick: EventEmitter<void> = new EventEmitter<void>();
	id = lastId++;

	constructor(elementRef: ElementRef, private prettyNumberService: PrettyNumberService) {
		super(elementRef);
		const self = this;

		this.chartSettings = {
			chartType: 'donut',
			columnName: 'name',
			columnValue: 'value',
			options: {
				color: {
					pattern: ['#d2d2d2', '#a40013', '#f68c1e', '#fbed1d'],
				},
				donut: {
					width: 19,
					label: {
						show: false,
					},
				},
				onrendered: function() {
					// use function instead of lambda so we can use c3's ChartInternal object
					self.onRendered(this);
				},
			},
		};
	}

	private onRendered(chart: { radius: number; innerRadius: number }) {
		let data = this.data;
		if (this.settings.dataPath) data = get(data, this.settings.dataPath);
		const noData = !(data && data.some(i => i.value !== 0));
		if (!(this.upperTitle || this.labelsOutside || noData)) return;
		const divElement = select(this.el);
		const svg = divElement.select('svg');
		svg.attr('role', this.role);
		svg.select('g')
			.attr('aria-hidden', 'false');
		svg.attr('aria-labelledby', `pie-chart-${this.id} pie-chart-id-${this.id}-text-count pie-chart-id-${this.id}-text-label`)
		// remove previously created labels and lines
		svg.selectAll('.' + LABEL_TEXT_CSS_CLASS).remove();
		svg.selectAll('.' + LABEL_LINE_CSS_CLASS).remove();
		svg.selectAll('.' + DYNAMIC_TITLE_CSS_CLASS).remove();
		svg.selectAll('.' + NO_DATA_ARC_CSS_CLASS).remove();

		if (noData) this.addNoDataArc(svg, chart);

		if (this.upperTitle) this.addDynamicTitle(svg);

		if (this.labelsOutside) this.addLabelsWithLines(svg, chart);
	}

	private addNoDataArc(
		svg: Selection<BaseType, any, HTMLElement, any>,
		chart: { radius: number; innerRadius: number }
	) {
		svg.attr('role', this.role);
		svg.select('g')
			.attr('aria-hidden', 'true');

		svg.select('.c3-chart-arcs')
			.append('svg:g')
			.attr('class', 'c3-chart-arc ' + NO_DATA_ARC_CSS_CLASS)
			.append('svg:g')
			.append('svg:path')
			.attr(
				'd',
				arc()
					.innerRadius(chart.innerRadius)
					.outerRadius(chart.radius)
					.startAngle(0)
					.endAngle(2 * Math.PI)
			)
			.attr('class', 'color-fill-neutral');
	}

	private addLabelsWithLines(
		svg: Selection<BaseType, any, HTMLElement, any>,
		chart: { radius: number; innerRadius: number }
	) {
		const originalHeight: number = Number(svg.attr('height') || 0);
		const originalWidth: number = Number(svg.attr('width') || 0);
		const originalSize: number = Math.min(originalHeight, originalWidth);

		// get the circle's radii and arc
		const innerRadius: number = chart.innerRadius;
		const outerRadius: number = chart.radius;

		const arcElements = svg.selectAll('.c3-chart-arc');
		const arcEL = arc<PieChartComponent, any>()
			.innerRadius(innerRadius)
			.outerRadius(outerRadius);
		const self = this;
		let index = 0;
		arcElements.each(function() {
			const currentArc = select<BaseType, any>(this);

			const arcData = currentArc.datum();

			if (arcData && arcData.value !== 0) {
				// calculate the arc's centroid
				const centroid: [number, number] = arcEL.centroid(arcData),
					centroidX: number = centroid[0],
					centroidY: number = centroid[1],
					centroidHeight: number = Math.sqrt(centroidX * centroidX + centroidY * centroidY);

				// add text elements
				const text = currentArc.append('svg:text');
				text.attr('class', LABEL_TEXT_CSS_CLASS + ' pointer')
					.attr(
						'transform',
						`translate(${(centroidX / centroidHeight) * (outerRadius + labelSize)},
																	${(centroidY / centroidHeight) * (outerRadius + labelSize)})`
					)
					.attr('id', `pie-chart-id-${self.id}-text-count-${index++}`)
					.attr(
						'text-anchor',
						(arcData.startAngle + arcData.endAngle) / 2 > Math.PI ? 'end' : 'start'
					)
					.attr('dx', (arcData.startAngle + arcData.endAngle) / 2 > Math.PI ? '.3em' : '-.3em')
					.attr('dy', '.3em')
					.text(self.prettyNumberService.prettyNumber(arcData.value))
					.on(
						'click',
						d =>
							self.settings.options &&
							self.settings.options.data &&
							self.settings.options.data.onclick &&
							self.settings.options.data.onclick(d.data, text)
					);
				// add lines
				currentArc
					.append('svg:line')
					.attr('class', LABEL_LINE_CSS_CLASS)
					.attr('x1', `${centroidX}`)
					.attr('y1', `${centroidY}`)
					.attr('x2', `${(centroidX / centroidHeight) * (outerRadius + labelSize - 15)}`)
					.attr('y2', `${(centroidY / centroidHeight) * (outerRadius + labelSize - 15)}`);
			}
		});

		// add a viewBox on the original svg so it resizes to fit the new elements
		svg.attr(
			'viewBox',
			`-${labelSize} -${labelSize} ${originalWidth + labelSize * 2} ${originalHeight + labelSize * 2}`
		);

		// expand the clipPath so everything fits
		this.expandClipPath(svg);

		// scale all text elements in the svg to original size
		this.scaleTextToOriginalSize(svg, originalSize);
	}

	private addDynamicTitle(svg: Selection<BaseType, any, HTMLElement, any>) {
		const arcContainer = svg.select('.c3-chart-arcs');
		const originalTitle = arcContainer.select('.c3-chart-arcs-title').attr('dy', '1em').attr('id', `pie-chart-id-${this.id}-text-label`);
		arcContainer
			.append('svg:text')
			.attr(
				'class',
				`c3-chart-arcs-title ${DYNAMIC_TITLE_CSS_CLASS}` + (this.allowTitleClick ? ' with-link' : '')
			)
			.attr('id', `pie-chart-id-${this.id}-text-count`)
			.attr('dy', '-.3rem')
			.on('click', () => this.titleClick.emit())
			.text(this.upperTitle);
		arcContainer.append(() => originalTitle.node());
	}

	private scaleTextToOriginalSize(svg: Selection<BaseType, any, HTMLElement, any>, originalSize: number) {
		// use function instead of arrow to use 'this' on each text element
		svg.selectAll('text').attr('transform', function() {
			let transformString: string = select(this).attr('transform') || '';
			// remove previous scale transformations
			transformString = transformString.replace(/scale\(.*\)/, '');
			return `${
				transformString ? transformString + ' ' : ''
			}scale(${(originalSize + labelSize * 2) / originalSize})`;
		});
	}

	private expandClipPath(svg: Selection<BaseType, any, HTMLElement, any>) {
		const padding: number = 10;
		svg.selectAll('clipPath[id^="c3-"][id$="clip"] rect')
			.attr('x', `-${labelSize + padding}`)
			.attr('y', `-${labelSize + padding}`)
			.attr('height', function() {
				const height = (<HTMLElement>this).getAttribute('height') || 0;
				return Number(height) + (labelSize + padding) * 2;
			})
			.attr('width', function() {
				const width = (<HTMLElement>this).getAttribute('width') || 0;
				return Number(width) + (labelSize + padding) * 2;
			});
	}
}
