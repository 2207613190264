import { mapValues } from 'lodash-es';
import { Lazy } from '@wcd/utils';
import { KnownColor, KnownColorName } from '../models/colors/known-colors.models';
import { KnownColorsService } from './known-colors.service';

export abstract class ColorsService<TKind extends string> {
	protected constructor(
		private readonly knownColorsService: KnownColorsService,
		private readonly colorMapping: Record<TKind, KnownColorName>
	) {}

	private readonly _colorsMap = new Lazy<ReadonlyMap<TKind, KnownColor>>(() => {
		return new Map<TKind, KnownColor>(Object.entries(
			mapValues(
				this.colorMapping,
				(colorName: KnownColorName) => this.knownColorsService.knownColorsMap[colorName]
			)
		) as [TKind, KnownColor][]);
	});

	private readonly _fontColorsClassMap = new Lazy<ReadonlyMap<TKind, string>>(
		() =>
			new Map(Object.entries(
				mapValues(
					this.colorMapping,
					(colorName: KnownColorName) => this.knownColorsService.fontColorsClassMap[colorName]
				)
			) as Array<[TKind, string]>)
	);

	private readonly _backgroundColorsClassMap = new Lazy<ReadonlyMap<TKind, string>>(
		() =>
			new Map(Object.entries(
				mapValues(
					this.colorMapping,
					(colorName: KnownColorName) => this.knownColorsService.backgroundColorsClassMap[colorName]
				)
			) as Array<[TKind, string]>)
	);

	get colorsMap(): ReadonlyMap<TKind, KnownColor> {
		return this._colorsMap.value;
	}

	get fontColorsClassMap(): ReadonlyMap<TKind, string> {
		return this._fontColorsClassMap.value;
	}

	get backgroundColorsClassMap(): ReadonlyMap<TKind, string> {
		return this._backgroundColorsClassMap.value;
	}
}
