import { DataEntityType, Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { OperatingSystemPlatform } from '../machine/operating-system-platform.entity';
import { DeviceTypeEntity } from '../machine/device-type.entity';
import { MachineGroupRuleProperty } from './machine-group-rule-property-enum';

@Entity({
	singularName: 'Device Group Simple Rule Property',
	pluralName: 'Device Group Simple Rule Properties',
	values: [
		{ id: 0, property: MachineGroupRuleProperty.Name, nameKey: 'machineGroup_rule_property_name' },
		{ id: 1, property: MachineGroupRuleProperty.Domain, nameKey: 'machineGroup_rule_property_domain' },
		{ id: 2, property: MachineGroupRuleProperty.Tags, nameKey: 'machineGroup_rule_property_tag' },
		{ id: 3, property: MachineGroupRuleProperty.Os, nameKey: 'machineGroup_rule_property_os', valuesEntity: OperatingSystemPlatform },
		{ id: 4, property: MachineGroupRuleProperty.DeviceType, nameKey: 'machineGroup_rule_property_deviceType', valuesEntity: DeviceTypeEntity },
	],
	readonly: true,
})
export class MachineGroupSimpleRuleProperty extends EntityModelBase {
	@EntityField() property: MachineGroupRuleProperty;

	@EntityField() nameKey: string;

	@EntityField() valuesEntity: DataEntityType;
}
