<ng-container *ngIf="currentValue!=null && !showHyphen;else noBar"> <!--If currentValue is null, it means no bar should be displayed but only the current status-->
	<wcd-stretched-donut-bars
		[data] = "strechedDonutChartItem"
		class = "wcd-full-width"
		barTitleCssClass = ''>
	</wcd-stretched-donut-bars>

	<div class="wcd-font-size-xs wcd-padding-bottom" *ngIf="showLegend">
		<fab-icon iconName="SquareShapeSolid" contentClass="ms-color-blue"></fab-icon>
		{{'tvm_activeRemediation_completed' | i18n}}
	</div>
</ng-container>
<ng-template #noBar>
	<ng-container *ngIf="!showHyphen; else showHyphenAsBar">
		<div class="wcd-flex-1 wcd-padding-small-top wcd-padding-small-bottom" *ngIf="showTitleIfNoBar">
			{{ title }}
		</div>
	</ng-container>

</ng-template>
<ng-template #showHyphenAsBar>
	<div [ngClass]="(inDataTable) ? 'wcd-flex-1 wcd-padding-small-top wcd-padding-small-bottom' : ''">
		{{'tvm_remediationTask_notTracked' | i18n}}
	</div>
</ng-template>
