import { Vulnerability } from './vulnerability.entity';
import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { Asset } from '../../asset/asset.entity';
import {
	ParseDataQuery,
	FilterByNameInsteadOfSearch,
	TvmAnalyticsSharedEntityRelationshipConfigurations,
} from '../../analyticsEndPointUtils';
import { EvidenceDistribution } from '../../evidence/evidence-distribution.entity';
import { Software } from '../../software/software.entity';
import { SecurityRecommendation } from '../../security-recommendation/security-recommendation.entity';
import { VulnerabilityAffectedProduct } from './vulnerability-affected-product.entity';

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Vulnerability,
	dataEntity: Asset,
	endpoint: (_, query) => {
		return `vulnerabilities/${query.where['id']}/assets`;
	},
	parseDataQuery: (dataQuery: DataQuery) => {
		// when searching for vulnerability assets we want to use OData filters
		dataQuery = FilterByNameInsteadOfSearch(dataQuery);

		return ParseDataQuery(dataQuery);
	},
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (vulnerability: Vulnerability) => {
		return { id: vulnerability.id };
	},
})
export class VulnerabilityExposedAssetsRelationship
	implements EntityRelationshipRepositoryType<Vulnerability, Asset> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Vulnerability,
	dataEntity: EvidenceDistribution,
	endpoint: (_, query) => `vulnerabilities/${query.where['id']}/evidenceDistribution`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (vulnerability: Vulnerability) => {
		return { id: vulnerability.id };
	},
})
export class VulnerabilityEvidenceDistributionRelationship
	implements EntityRelationshipRepositoryType<Vulnerability, EvidenceDistribution> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Vulnerability,
	dataEntity: Software,
	endpoint: (_, query) => `vulnerabilities/${query.where['id']}/products`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (vulnerability: Vulnerability) => {
		return { id: vulnerability.id };
	},
})
export class VulnerabilitySoftwareRelationship
	implements EntityRelationshipRepositoryType<Vulnerability, Software> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Vulnerability,
	dataEntity: SecurityRecommendation,
	endpoint: 'recommendations',
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (vulnerability: Vulnerability) => ({ search: vulnerability.id }),
})
export class VulnerabilitySecurityRecommendationsRelationship
	implements EntityRelationshipRepositoryType<Vulnerability, SecurityRecommendation> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Vulnerability,
	dataEntity: VulnerabilityAffectedProduct,
	endpoint: (_, query) => `vulnerabilities/${query.where['id']}/affectedProducts`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (vulnerability: Vulnerability) => ({
		id: vulnerability.id,
	}),
})
export class VulnerabilityAffectedProductsRelationship
	implements EntityRelationshipRepositoryType<Vulnerability, VulnerabilityAffectedProduct> {}
