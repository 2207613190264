var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DataviewField } from '@wcd/dataview';
import { Paris } from '@microsoft/paris';
import { BaselineProfileDetails, ConfigurationProfilesApiCall } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { filter } from 'rxjs/operators';
import { EntityPanelComponentBase } from '../../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { ActivatedEntity } from '../../../../../../global_entities/services/activated-entity.service';
import { TabModel } from '../../../../../../shared/components/tabs/tab.model';
import { Router } from '@angular/router';
var TabIds;
(function (TabIds) {
    TabIds["configurationDetails"] = "configurationDetails";
    TabIds["applicableProfiles"] = "applicableProfiles";
    TabIds["devices"] = "devices";
})(TabIds || (TabIds = {}));
var ProfileConfigurationEntityPanelComponent = /** @class */ (function (_super) {
    __extends(ProfileConfigurationEntityPanelComponent, _super);
    function ProfileConfigurationEntityPanelComponent(i18nService, activatedEntity, changeDetectorRef, paris, router) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.i18nService = i18nService;
        _this.activatedEntity = activatedEntity;
        _this.paris = paris;
        _this.router = router;
        _this.tabs = [];
        _this.tabIds = TabIds;
        return _this;
    }
    Object.defineProperty(ProfileConfigurationEntityPanelComponent.prototype, "configuration", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    ProfileConfigurationEntityPanelComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
        this._applicableProfilesSubscription && this._applicableProfilesSubscription.unsubscribe();
    };
    ProfileConfigurationEntityPanelComponent.prototype.initEntity = function (configuration, isExtendedData) {
        var _this = this;
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, configuration, isExtendedData);
        this.changeDetectorRef.markForCheck();
        this.tabs = this.getTabs();
        this.currentTab = this.tabs[0];
        if (this.configuration.isProfileConfiguration) {
            this._subscription = this.activatedEntity.currentEntity$
                .pipe(filter(function (entity) { return entity instanceof BaselineProfileDetails; }))
                .subscribe(function (profile) {
                _this.profileName = profile.name;
                _this.profileId = profile.id;
            });
        }
        this.singleAssetPanel = this.configuration.applicableDevices === 1;
        this._applicableProfilesSubscription = this.paris.apiCall(ConfigurationProfilesApiCall, {
            configurationId: this.configuration.id
        }).subscribe(function (profiles) { return _this.applicableProfiles = profiles; });
        this.applicableProfilesFields = DataviewField.fromList([
            {
                id: 'name',
                name: this.i18nService.strings.tvm_common_name,
                onClick: function (profile) {
                    _this.activatedEntity.setNextEntity(profile);
                    _this.router.navigate(["/baseline-compliance_t/profiles/" + encodeURIComponent(profile.id) + "/configurations"]);
                },
            }
        ]);
        this.isProfileConfiguration = this.configuration.isProfileConfiguration;
    };
    ProfileConfigurationEntityPanelComponent.prototype.setTab = function (tab) {
        this.currentTab = tab;
    };
    ProfileConfigurationEntityPanelComponent.prototype.getTabs = function () {
        return [
            {
                id: this.tabIds.configurationDetails,
                name: this.i18nService.strings.tvm_baseline_profile_entity_configurationTab_sidePanel_configurationDetails_title,
            },
            !this.configuration.isProfileConfiguration &&
                {
                    id: this.tabIds.applicableProfiles,
                    name: this.i18nService.strings.tvm_baseline_device_widget_applicable_profiles_column,
                },
            {
                id: this.tabIds.devices,
                name: this.i18nService.strings.machines,
            }
        ].filter(Boolean).map(function (tab) { return new TabModel(tab); });
    };
    return ProfileConfigurationEntityPanelComponent;
}(EntityPanelComponentBase));
export { ProfileConfigurationEntityPanelComponent };
