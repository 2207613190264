import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[wcdClickOutside]' })
export class ClickOutsideDirective {
	constructor(private readonly elementRef: ElementRef) {}

	@Output('wcdClickOutside') readonly clickOutside = new EventEmitter<void>();

	@HostListener('document:click', ['$event.target', '$event.button'])
	onDocumentClick(targetElement: HTMLElement, button: MouseEvent['button']) {
		// Only react to main (left) mouse button clicks
		if (button !== 0) {
			return;
		}

		const clickedInside = this.elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.clickOutside.emit();
		}
	}
}
