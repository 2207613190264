
		<ul class="wcd-machine-uac-flag-list">
			<li
				*ngFor="let flagName of supportedUacFlagsKeys"
				class="wcd-machine-uac-flag-item"
				[ngClass]="{ 'wcd-machine-uac-flag-item-enabled': !!uacFlags[flagName] }"
			>
				{{ 'machines.entityDetails.fields.uacFlags.flagsTexts.' + flagName | i18n }}
			</li>
		</ul>
	