var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { RelationshipRepository, ModelBase, Paris } from '@microsoft/paris';
import { TvmEndPoint, SoftwareInstallationAgg, SecurityRecommendation, RecommendationCategory, SoftwareInstallationNetworkDevice, RecommendationInstalledNetworkDevicesRelationship, } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { SecurityRecommendationFieldsService } from '../../../@entities/@tvm/security-recommendations/services/security-recommendation.fields.service';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
var InstalledAssetsPanelComponent = /** @class */ (function (_super) {
    __extends(InstalledAssetsPanelComponent, _super);
    function InstalledAssetsPanelComponent(i18nService, tvmDownloadService, fieldsService, globalEntityTypesService, paris, featuresService, router) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.fieldsService = fieldsService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.paris = paris;
        _this.featuresService = featuresService;
        _this.isExportEnabled = featuresService.isEnabled(Feature.TvmExportCsvFromSidePanel);
        return _this;
    }
    Object.defineProperty(InstalledAssetsPanelComponent.prototype, "sourceValue", {
        get: function () {
            return this._sourceValue;
        },
        set: function (value) {
            this._sourceValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InstalledAssetsPanelComponent.prototype, "installedAssetsTitle", {
        get: function () {
            return this._installedAssetsTitle;
        },
        set: function (value) {
            this._installedAssetsTitle = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InstalledAssetsPanelComponent.prototype, "repository", {
        set: function (value) {
            this._repository = this.paris.getRelationshipRepository(value);
        },
        enumerable: true,
        configurable: true
    });
    InstalledAssetsPanelComponent.prototype.ngOnInit = function () {
        if (this.sourceValue instanceof SecurityRecommendation) {
            this.isNetworkGear = this.sourceValue.category === RecommendationCategory.NetworkGear;
            this._fields = this.isNetworkGear
                ? this.fieldsService.installedDevicesFields
                : this.fieldsService.installedAssetsFields;
            var allFieldsIds = this._fields.map(function (field) { return field.id; });
            this.dataViewConfig = {
                id: "installedAssets(" + allFieldsIds.join() + ")",
            };
        }
        else {
            this._fields = this.fieldsService.installedAssetsFields;
            this.entityType = this.globalEntityTypesService.getEntityType(SoftwareInstallationAgg);
        }
        if (this.isNetworkGear) {
            this.networkDeviceRelationshipRepository = this.paris.getRelationshipRepository(RecommendationInstalledNetworkDevicesRelationship);
            if (this._sourceValue instanceof SecurityRecommendation) {
                this.networkDeviceRelationshipRepository.sourceItem = this._sourceValue;
            }
        }
    };
    InstalledAssetsPanelComponent.prototype.exportCveData = function () {
        if (this.isNetworkGear) {
            this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.networkDeviceRelationshipRepository, TvmEndPoint.Analytics, this.installedAssetsTitle + " - " + this.i18nService.strings.tvm_common_installedDevices, undefined);
        }
        else {
            this.tvmDownloadService.downloadCsvFromRelationshipRepository(this._repository, TvmEndPoint.Analytics, this.installedAssetsTitle + " - " + this.i18nService.strings.tvm_common_installedDevices, undefined);
        }
    };
    return InstalledAssetsPanelComponent;
}(PanelContainer));
export { InstalledAssetsPanelComponent };
