import { Injectable, Type } from '@angular/core';
import {
	HuntingCommunityQuery,
	HuntingSavedQuery,
	HuntingScheduledQuery,
	HuntingSharedQuery,
	HuntingUserQuery,
	QueryKind,
} from '@wcd/domain';
import { Subject } from 'rxjs';
import { SupportedQuery } from '../../shared/models/hunting.models';
import { QueriesListTreeComponent } from '../components/queries-list-tree/queries-list-tree.component';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';

@Injectable({ providedIn: 'root' })
export class HuntingQueryListService {
	update$: Subject<number> = new Subject<number>();

	constructor(private readonly i18nService: I18nService, private readonly featuresService: FeaturesService) {}
	refreshQueriesLists() {
		this.update$.next(new Date().valueOf());
	}

	get displayNames(): Record<QueryKind, string> {
		return {
			user: this.i18nService.strings.hunting_savedQueries_types_user,
			shared: this.i18nService.strings.hunting_savedQueries_types_shared,
			scheduled: this.i18nService.strings.hunting_savedQueries_types_scheduled,
			community: this.i18nService.strings.hunting_savedQueries_types_community,
		};
	}

	get config(): Record<QueryKind, QueriesListConfiguration<SupportedQuery>> {
		return {
			user: {
				title: this.displayNames.user,
				entityType: this.shouldMigrateToVNext ? HuntingSavedQuery : HuntingUserQuery,
				queryKind: 'user',
				// defaults for the tree are fine
			},
			shared: {
				title: this.displayNames.shared,
				entityType: this.shouldMigrateToVNext ? HuntingSavedQuery : HuntingSharedQuery,
				queryKind: 'shared',
				// defaults for the tree are fine
			},
			scheduled: {
				title: this.displayNames.scheduled,
				entityType: this.shouldMigrateToVNext ? HuntingSavedQuery : HuntingScheduledQuery,
				queryKind: 'scheduled',
				treeOptions: {
					allowContextMenu: false,
				},
			},
			community: {
				title: this.displayNames.community,
				entityType: HuntingCommunityQuery,
				queryKind: 'community',
				treeOptions: {
					allowContextMenu: false,
				},
			},
		};
	}

	get shouldMigrateToVNext() : boolean {
		return this.featuresService.isAnyEnabled([
			Feature.HuntingEndpointMigrationQueriesController,
			Feature.HuntingEndpointMigrationQueriesControllerMtp]);
	}
}

export interface QueriesListConfiguration<TQueryType extends SupportedQuery> {
	title: string;
	entityType: Type<TQueryType>;
	queryKind: QueryKind;
	treeOptions?: Pick<
		QueriesListTreeComponent<TQueryType>,
		'showQueries' | 'enableNewFolders' | 'toggleOnFolderNameClick' | 'allowContextMenu'
	>;
}
