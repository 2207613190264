/* tslint:disable:template-accessibility-alt-text */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'external-image',
	template: `
		<div class="external-image">
			<div *ngIf="isImageAvailable">
				<img class="screenshot" [src]="src | safeUrl" (error)="onImageError()" />
			</div>
			<div class="alert alert-danger" *ngIf="!isImageAvailable">
				<wcd-shared-icon [iconName]="'error'" class="large-icon pull-left"> </wcd-shared-icon>
				<div class="with-large-icon">
					<div>{{ 'help.externalLoadError' | i18n: { asset: imageAsset } }}</div>
					<span [title]="src">{{ src | truncate: 50 }}</span>
				</div>
			</div>
		</div>
	`,
})
export class ExternalImageComponent {
	@Input() src: string;
	@Output() error: EventEmitter<void> = new EventEmitter<void>();
	isImageAvailable: boolean = true;
	readonly imageAsset = this.i18nService.get('help_externalLoadError_image_asset');

	constructor(private readonly i18nService: I18nService) { }
	onImageError() {
		this.isImageAvailable = false;
		this.error.emit(null);
	}
}
