var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { map } from 'rxjs/operators';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { getUserTimeZone } from '@wcd/localization';
var SYSTEM_EXCLUSIONS_API_ENDPOINT = '/acl';
var SystemExclusionsBackendService = /** @class */ (function (_super) {
    __extends(SystemExclusionsBackendService, _super);
    function SystemExclusionsBackendService(http, downloadService, serviceUrlsService) {
        var _this = _super.call(this, http, downloadService) || this;
        _this.serviceUrlsService = serviceUrlsService;
        return _this;
    }
    Object.defineProperty(SystemExclusionsBackendService.prototype, "apiUrl", {
        get: function () {
            return this.serviceUrlsService.automatedIr + SYSTEM_EXCLUSIONS_API_ENDPOINT;
        },
        enumerable: true,
        configurable: true
    });
    SystemExclusionsBackendService.prototype.remove = function (id, options) {
        return this.http.delete(this.apiUrl + "/" + id + "?type=" + options.type);
    };
    SystemExclusionsBackendService.prototype.create = function (obj, options) {
        return this.http.post(this.getUpdateUrl(options), SystemExclusionsBackendService.getItemData(obj));
    };
    SystemExclusionsBackendService.prototype.update = function (id, data, options) {
        return this.http.put(this.getUpdateUrl(options, id), SystemExclusionsBackendService.getItemData(data));
    };
    SystemExclusionsBackendService.prototype.getSystemExclusionTypeCounts = function () {
        return this.http.get(this.apiUrl + "/count");
    };
    SystemExclusionsBackendService.prototype.createMemoryContentsAcl = function (memoryContentsId, aclType) {
        return this.post('memory_content', {
            memory_content: memoryContentsId,
            acl_type: aclType,
        }).pipe(map(this.extractData));
    };
    SystemExclusionsBackendService.prototype.removeMemoryContentsAcl = function (memoryContentsId) {
        return this.delete("memory_content/" + memoryContentsId);
    };
    SystemExclusionsBackendService.prototype.getUpdateUrl = function (options, id) {
        var urlId = id || 'all';
        var url = options.type === 'certificate'
            ? this.apiUrl + "/certificate/upload"
            : this.apiUrl + "/" + urlId + "?type=" + options.type;
        return "" + url + (/\?/.test(url) ? '&' : '?') + "time_offset=" + getUserTimeZone();
    };
    SystemExclusionsBackendService.getItemData = function (obj) {
        var postData = obj;
        if (obj.file) {
            postData = new FormData();
            postData.append('file[]', obj.file);
            for (var p in obj) {
                if (p !== 'file')
                    postData.append(p, obj[p]);
            }
        }
        return postData;
    };
    return SystemExclusionsBackendService;
}(StoreBackendBase));
export { SystemExclusionsBackendService };
