import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { IMachineVulnerabilitySeverityLevel, MachineVulnerabilitySeverityLevelType } from './machine-vulnerability-severity-level.models';
import { machineVulnerabilitySeverityLevelValues } from './machine-vulnerability-severity-level.values';

@Entity({
	singularName: 'Device Vulnerability Severity Level',
	pluralName: 'Device Vulnerability Severity Levels',
	values: machineVulnerabilitySeverityLevelValues,
})
export class MachineVulnerabilitySeverityLevel extends EntityModelBase<MachineVulnerabilitySeverityLevelType> implements IMachineVulnerabilitySeverityLevel {
	@EntityField() name: string;
	@EntityField() nameI18nKey: string;

	@EntityField() priority: number;

	get type(): MachineVulnerabilitySeverityLevelType {
		return this.id;
	}
}
