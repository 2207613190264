import { Entity, EntityField, ModelBase, DataQuery, DataQuerySortDirection } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, ParseDataQuery } from '../analyticsEndPointUtils';
import { VulnerabilityChangeEventType } from './vulnerability-change-event-type.enum';
import { AssetChangeEventType } from './asset-change-event-type.enum';
import { ConfigurationChangeEventType } from './configuration-change-event-type.enum';
import { RecommendationCategory } from '../common/recommendation/recommendation-category.enum';

export type ChangeEventTypes =
	| VulnerabilityChangeEventType
	| AssetChangeEventType
	| ConfigurationChangeEventType;

export class ChangeEventSummaryModel {
	eventType: ChangeEventTypes;
	cveCount: number;
	newScidCount: number;
}

export enum ChangeEventCategory {
	Vulnerability = 'Vulnerability',
	Configuration = 'Configuration',
}

export const ChangeEventTypesForMdatp = [
	VulnerabilityChangeEventType.NewCve,
	VulnerabilityChangeEventType.CveHasExploitInKit,
	VulnerabilityChangeEventType.CveHasPubliclyDisclosedExploit,
	VulnerabilityChangeEventType.CveHasVerifiedExploit,
	ConfigurationChangeEventType.NewScid,
];

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Event',
	pluralName: 'Events',
	endpoint: 'changeEvents',
	parseDataQuery: dataQuery => {
		if (dataQuery.sortBy && dataQuery.sortBy.some(item => item.field === 'score')) {
			return ParseDataQuery(dataQuery);
		}
		const newSortBy = (dataQuery.sortBy || []).concat({
			field: 'score',
			direction: DataQuerySortDirection.descending,
		});

		if (dataQuery.where && typeof dataQuery.where === 'object' && dataQuery.where['source'] === 'mdatp') {
			dataQuery.where['eventType'] = ChangeEventTypesForMdatp;
		}
		const dataQueryWithOrderByScore: DataQuery = { ...dataQuery, sortBy: newSortBy };
		return ParseDataQuery(dataQueryWithOrderByScore);
	},
	parseItemQuery: id => {
		return `changeEvents/${id}`;
	},
})
export class ChangeEvent extends ModelBase {
	// replace EntityModelBase with ModelBase to overcome paris bug of using entity inside a type of an api call
	@EntityField()
	readonly date: Date;

	@EntityField()
	readonly category: ChangeEventCategory;

	@EntityField({
		data: 'type',
	})
	readonly eventType: ChangeEventTypes;

	@EntityField({
		data: 'product',
	})
	readonly productName: string;

	@EntityField()
	readonly vendor: string;

	@EntityField()
	readonly assetsCount: number;

	@EntityField()
	readonly currentAssetsCount: number;

	@EntityField()
	readonly cvesList: string[];

	@EntityField()
	readonly score: number;

	@EntityField()
	readonly affectedAssetsPercent: number;

	@EntityField()
	readonly currentAffectedAssetsPercent: number;

	@EntityField()
	readonly scid: string;

	@EntityField()
	readonly configurationCategory: RecommendationCategory;

	@EntityField()
	readonly reductionPoints: number;

	@EntityField()
	readonly achievablePoints: number;

	@EntityField()
	readonly isActiveEvent: boolean;

	@EntityField()
	readonly relatedEventIds: string[];
}
