var ɵ0 = function (accountName, accountDomainName, isAllowedDisplayOfAccount) {
    if (!isAllowedDisplayOfAccount) {
        return false;
    }
    accountName = (accountName || '').toLowerCase();
    accountDomainName = (accountDomainName || '').toLowerCase();
    var isAllowedAccountName = true;
    var isAllowedAccountDomainname = true;
    var builtInAccountNames = [
        'SYSTEM',
        'LOCAL SERVICE',
        'NETWORK',
        'NETWORK SERVICE',
        'RDV GRAPHICS SERVICE',
        'tvsu_tmp',
        'root',
    ];
    if (builtInAccountNames.some(function (value) { return value.toLowerCase() === accountName; })) {
        isAllowedAccountName = false;
    }
    var builtInDomainAccountNames = [
        'NT AUTHORITY',
        'NT-AUTORITÄT',
        'AUTORITE NT',
        'AUTORIDADE NT',
        'NT SERVICE',
        'SERVICE RÉSEAU',
        'NT VIRTUAL MACHINE',
        'Window Manager',
        'Font Driver Host',
    ];
    if (builtInDomainAccountNames.some(function (value) { return value.toLowerCase() === accountDomainName; })) {
        isAllowedAccountDomainname = false;
    }
    var results = isAllowedAccountName && isAllowedAccountDomainname;
    return results;
}, ɵ1 = function (accountName, accountSid) {
    var buildInAccountSids = {
        'S-1-5-18': 'SYSTEM',
        'S-1-5-20': 'NETWORK SERVICE',
        'S-1-5-19': 'LOCAL SERVICE',
        'S-1-5-7': 'ANONYMOUS LOGON',
    };
    var returnedAccountName = buildInAccountSids[accountSid];
    if (returnedAccountName != null && returnedAccountName != accountName) {
        return returnedAccountName;
    }
    return accountName;
}, ɵ2 = function (accountName, accountDomainName) {
    if (accountName == null && accountDomainName == null) {
        return '';
    }
    if (accountDomainName == null) {
        return accountName;
    }
    return accountDomainName + '\\' + accountName;
};
export var userAccountService = {
    isAllowedAccount: ɵ0,
    convertAccountNameBySid: ɵ1,
    resolveUserName: ɵ2,
};
export { ɵ0, ɵ1, ɵ2 };
