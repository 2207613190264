import { NgModule } from '@angular/core';
import { GlobalEntityTypesService } from './services/global-entity-types.service';
import { EntityWithContextEntityTypeService } from './services/entity-with-context.entity-type.service';
import { GlobalEntitiesModule } from './global-entities.module';
import { EntityWithContextPanelComponent } from './components/entity-panels/entity-with-context.entity-panel.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	declarations: [EntityWithContextPanelComponent],
	imports: [SharedModule, GlobalEntitiesModule],
	providers: [EntityWithContextEntityTypeService],
	entryComponents: [EntityWithContextPanelComponent],
})
export class EntityWithContextEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(EntityWithContextEntityTypeService);
	}
}
