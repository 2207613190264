var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vulnerability } from './vulnerability.entity';
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { Asset } from '../../asset/asset.entity';
import { ParseDataQuery, FilterByNameInsteadOfSearch, TvmAnalyticsSharedEntityRelationshipConfigurations, } from '../../analyticsEndPointUtils';
import { EvidenceDistribution } from '../../evidence/evidence-distribution.entity';
import { Software } from '../../software/software.entity';
import { SecurityRecommendation } from '../../security-recommendation/security-recommendation.entity';
import { VulnerabilityAffectedProduct } from './vulnerability-affected-product.entity';
var ɵ0 = function (_, query) {
    return "vulnerabilities/" + query.where['id'] + "/assets";
}, ɵ1 = function (dataQuery) {
    // when searching for vulnerability assets we want to use OData filters
    dataQuery = FilterByNameInsteadOfSearch(dataQuery);
    return ParseDataQuery(dataQuery);
}, ɵ2 = function (vulnerability) {
    return { id: vulnerability.id };
};
var VulnerabilityExposedAssetsRelationship = /** @class */ (function () {
    function VulnerabilityExposedAssetsRelationship() {
    }
    VulnerabilityExposedAssetsRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Vulnerability, dataEntity: Asset, endpoint: ɵ0, parseDataQuery: ɵ1, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ2 }))
    ], VulnerabilityExposedAssetsRelationship);
    return VulnerabilityExposedAssetsRelationship;
}());
export { VulnerabilityExposedAssetsRelationship };
var ɵ3 = function (_, query) { return "vulnerabilities/" + query.where['id'] + "/evidenceDistribution"; }, ɵ4 = function (vulnerability) {
    return { id: vulnerability.id };
};
var VulnerabilityEvidenceDistributionRelationship = /** @class */ (function () {
    function VulnerabilityEvidenceDistributionRelationship() {
    }
    VulnerabilityEvidenceDistributionRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Vulnerability, dataEntity: EvidenceDistribution, endpoint: ɵ3, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ4 }))
    ], VulnerabilityEvidenceDistributionRelationship);
    return VulnerabilityEvidenceDistributionRelationship;
}());
export { VulnerabilityEvidenceDistributionRelationship };
var ɵ5 = function (_, query) { return "vulnerabilities/" + query.where['id'] + "/products"; }, ɵ6 = function (vulnerability) {
    return { id: vulnerability.id };
};
var VulnerabilitySoftwareRelationship = /** @class */ (function () {
    function VulnerabilitySoftwareRelationship() {
    }
    VulnerabilitySoftwareRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Vulnerability, dataEntity: Software, endpoint: ɵ5, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ6 }))
    ], VulnerabilitySoftwareRelationship);
    return VulnerabilitySoftwareRelationship;
}());
export { VulnerabilitySoftwareRelationship };
var ɵ7 = function (vulnerability) { return ({ search: vulnerability.id }); };
var VulnerabilitySecurityRecommendationsRelationship = /** @class */ (function () {
    function VulnerabilitySecurityRecommendationsRelationship() {
    }
    VulnerabilitySecurityRecommendationsRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Vulnerability, dataEntity: SecurityRecommendation, endpoint: 'recommendations', allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ7 }))
    ], VulnerabilitySecurityRecommendationsRelationship);
    return VulnerabilitySecurityRecommendationsRelationship;
}());
export { VulnerabilitySecurityRecommendationsRelationship };
var ɵ8 = function (_, query) { return "vulnerabilities/" + query.where['id'] + "/affectedProducts"; }, ɵ9 = function (vulnerability) { return ({
    id: vulnerability.id,
}); };
var VulnerabilityAffectedProductsRelationship = /** @class */ (function () {
    function VulnerabilityAffectedProductsRelationship() {
    }
    VulnerabilityAffectedProductsRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Vulnerability, dataEntity: VulnerabilityAffectedProduct, endpoint: ɵ8, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ9 }))
    ], VulnerabilityAffectedProductsRelationship);
    return VulnerabilityAffectedProductsRelationship;
}());
export { VulnerabilityAffectedProductsRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
