
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.username">
				<dt role="none">{{ i18nService.strings.airsEntities_user_fields_username }}</dt>
				<dd role="none">{{ entity.username }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.email">
				<dt role="none">{{ i18nService.strings.airsEntities_user_fields_emailAddress }}</dt>
				<dd role="none">{{ entity.email }}</dd>
			</ng-container>
		</dl>
	