import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../shared/shared.module';
import { SoftwareInstallationEntityTypeService } from './services/software-installation.entity-type.service';
import { SoftwareInstallationEntityPanelComponent } from './components/software-entity/software-installation.entity-panel.component';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { SoftwareInstallationFieldsService } from './services/software-installation.fields.service';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule],
	declarations: [SoftwareInstallationEntityPanelComponent],
	exports: [SoftwareInstallationEntityPanelComponent],
	entryComponents: [SoftwareInstallationEntityPanelComponent],
	providers: [SoftwareInstallationFieldsService, SoftwareInstallationEntityTypeService],
})
export class SoftwareInstallationEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(SoftwareInstallationEntityTypeService);
	}
}
