import { Injectable } from '@angular/core';
import { EntityType, EntityDataViewOptions } from '../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { of } from 'rxjs';
import { MissingKbEntityPanelComponent } from '../components/software-entity/missing-kb.entity-panel.component';
import { MissingKb } from '@wcd/domain';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';

@Injectable()
export class MissingKbEntityTypeService implements EntityTypeService<MissingKb> {
	constructor(private readonly tvmTagsService: TvmTagsService) {}

	readonly entityType: EntityType<MissingKb> = {
		id: 'missing-kb',
		entity: MissingKb,
		entityPluralNameKey: 'missingKb_entityType_pluralName',
		entitySingularNameKey: 'missingKb_entityType_singularName',
		getEntityName: (missingKb: MissingKb) => missingKb.vendorBulletin,
		getTags: (missingKb: MissingKb[]) => this.tvmTagsService.getMissingKbTags(missingKb[0]),
		singleEntityPanelComponentType: MissingKbEntityPanelComponent,
		loadFullEntityInPanel: false,
		isUserExposedToEntity: () => of(false), //this magic hides the action bar
		dataViewOptions: <EntityDataViewOptions<MissingKb, {}>>{
			defaultSortFieldId: 'productFixId',
		},
	};
}
