
		<modal (close)="destroy()" [settings]="settings">
			<confirm-modal-content
				class="wcd-flex-vertical wcd-flex-1 ie11Patch ie11Flex"
				[confirmButtonOptions]="{
					disabled: !reason || isSaving || loading
				}"
				[isSaving]="isSaving"
				(onCancel)="onCancelHandler()"
				(onConfirm)="submit()"
			>
				<p>{{ 'file.quarantine.description' | i18n }}</p>

				<ng-container *ngIf="loading; else stats">
					<fab-spinner [label]="'file.quarantine.details.loading' | i18n"></fab-spinner>
				</ng-container>
				<ng-template #stats>
					<table class="response-confirmation-table wcd-margin-bottom">
						<tr>
							<th
								class="response-confirmation-table-header-text response-confirmation-table-header-applies"
							>
								{{ 'file.quarantine.details.applies.title' | i18n }}
							</th>
							<th class="response-confirmation-table-header-text">
								{{ 'file.quarantine.details.fileDetails.title' | i18n }}
							</th>
						</tr>
						<tr class="response-confirmation-details-row">
							<td class="response-confirmation-impact-section">
								<div>
									<fab-icon
										iconName="System"
										contentClass="response-confirmation-DeviceLaptopNoPic"
									></fab-icon>
								</div>
								<div>
									{{
										'file.quarantine.details.applies.machines'
											| i18n: { amount: fileInstanceCount.machineCount | prettyNumber }
									}}
								</div>
							</td>
							<td>
								<div class="response-confirmation-details-sha1-section">
									<span class="response-confirmation-details-sha1-text">{{
										'file.quarantine.details.fileDetails.sha1' | i18n
									}}</span>
									{{ file.sha1 }}
								</div>
								<div class="response-confirmation-details-prevalence-section">
									<table class="response-confirmation-table">
										<tr>
											<td class="response-confirmation-details-prevalence-cell">
												<span
													class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-worldwide"
													>{{
														'file.quarantine.details.fileDetails.prevalanceWorldwide'
															| i18n
													}}</span
												>{{ fileStats.worldwidePrevalence | prettyNumber }}
											</td>
											<td class="response-confirmation-details-prevalence-cell">
												<span
													class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-filenames"
													>{{
														'file.quarantine.details.fileDetails.fileNames' | i18n
													}}</span
												>{{ fileStats.topFileNames?.length | prettyNumber }}
												<fab-icon
													iconName="Info"
													contentClass="ms-color-blue wcd-padding-xsmall-bottom"
													[wcdTooltip]="topFileNamesHtmlTooltip"
												></fab-icon>
											</td>
										</tr>
										<tr>
											<td class="response-confirmation-details-prevalence-cell">
												<span
													class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-organizations"
													>{{
														'file.quarantine.details.fileDetails.prevalenceOrganization'
															| i18n
													}}</span
												>{{ fileStats.organizationPrevalence | prettyNumber }}
											</td>
											<td class="response-confirmation-details-prevalence-cell">
												<span
													class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-file-instances"
													>{{
														'file.quarantine.details.fileDetails.fileInstances'
															| i18n
													}}</span
												>{{ fileInstanceCount.fileCount | prettyNumber }}
											</td>
										</tr>
									</table>
								</div>
							</td>
						</tr>
						<tr
							*ngIf="showHighOrgPrevalenceWarning"
							class="response-confirmation-warning-message"
						>
							<td colspan="2">
								<fab-icon
									iconName="warningSolid"
									contentClass="color-text-warning-dark wcd-margin-small-all"
								></fab-icon>
								{{ 'file.quarantine.details.fileRelativelyPrevalent' | i18n }}
							</td>
						</tr>
					</table>
				</ng-template>
				<label for="block-file-reason" class="wcd-required"
					>{{ 'file.quarantine.reason' | i18n }}:</label
				>
				<textarea
					required
					rows="4"
					id="block-file-reason"
					[placeholder]="'file.quarantine.reasonPlaceholder' | i18n"
					class="form-control dialog-textarea wcd-full-width wcd-no-resize"
					[(ngModel)]="reason"
				></textarea>

				<p class="wcd-margin-top">
					<fab-icon iconName="Clock"></fab-icon>
					{{ 'file.quarantine.applies' | i18n }}
					<span
						class="response-confirmation-export-to-csv-open-dialog-button no-outline pointer"
						(click)="downloadMachineListCsv()"
					>
						<fab-icon iconName="Download"></fab-icon>
						{{ 'file.quarantine.details.exportMachineList' | i18n }}
					</span>
				</p>
			</confirm-modal-content>
		</modal>
	