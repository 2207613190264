var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { DateRangeModel, getUserTimeZone, TzDateService } from '@wcd/localization';
import { keyBy, merge } from 'lodash-es';
import { BehaviorSubject, forkJoin, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiBaseModel } from '@wcd/data';
import { DataError } from '../../shared/models/error.model';
import { DownloadService } from '../../shared/services/download.service';
import { WidgetType } from '../../reports/components/report-with-filters.component';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
import { sccHostService } from '@wcd/scc-interface';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/services/download.service";
import * as i3 from "../../../../../../projects/app-config/src/lib/service-urls/services/service-urls.service";
import * as i4 from "@angular/router";
import * as i5 from "../../../../../../projects/auth/src/lib/services/auth.service";
import * as i6 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../../../../projects/localization/src/lib/services/tz-date.service";
export var reportWithFiltersFromDateProperty = 'fromDate';
export var reportWithFiltersToDateProperty = 'toDate';
var ReportsService = /** @class */ (function () {
    function ReportsService(http, downloadService, serviceUrlsService, route, authService, i18nService, tzDateService) {
        this.http = http;
        this.downloadService = downloadService;
        this.serviceUrlsService = serviceUrlsService;
        this.route = route;
        this.authService = authService;
        this.i18nService = i18nService;
        this.tzDateService = tzDateService;
        this._currentTimezone = getUserTimeZone();
        this.reportTimeRanges = [
            { name: this.i18nService.get('report.time.range.last.day'), value: '1' },
            { name: this.i18nService.get('report.time.range.last.week'), value: '7' },
            { name: this.i18nService.get('report.time.range.last.30.days'), value: '30' },
            { name: this.i18nService.get('report.time.range.last.3.months'), value: '90' },
            { name: this.i18nService.get('report.time.range.last.6.months'), value: '180' },
            {
                name: this.i18nService.get('report.time.range.custom.range'),
                value: 'custom',
                allowCustomRange: true,
            },
        ];
        this._currentTimeRange = this.defaultTimeRange;
        this.reportState$ = new BehaviorSubject(this.getCurrentOptions());
    }
    Object.defineProperty(ReportsService.prototype, "currentTimeRange", {
        get: function () {
            return this._currentTimeRange;
        },
        set: function (timeRange) {
            this._currentTimeRange = timeRange;
            this.updateOptions();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsService.prototype, "timeRanges", {
        get: function () {
            return this.reportTimeRanges;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsService.prototype, "currentTimezone", {
        get: function () {
            return this._currentTimezone;
        },
        set: function (value) {
            this._currentTimezone = value;
            this.updateOptions();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsService.prototype, "defaultTimeRange", {
        get: function () {
            return this.reportTimeRanges.find(function (range) { return range.value === '30'; });
        },
        enumerable: true,
        configurable: true
    });
    ReportsService.prototype.fixDataIfNeeded = function (filtersState, widgetType) {
        var _a;
        if (!filtersState)
            filtersState = { selection: {}, serialized: {} };
        var urlDateRange;
        if (this.route.snapshot.queryParams['range']) {
            urlDateRange = DateRangeModel.fromString(this.tzDateService, this.route.snapshot.queryParams['range']);
        }
        var fromDate = widgetType === WidgetType.Daily
            ? new Date()
            : filtersState.selection[reportWithFiltersFromDateProperty];
        if (!fromDate) {
            // We don't have from date in case we just entered the page
            if (urlDateRange && urlDateRange.from) {
                fromDate = urlDateRange.from;
            }
            else {
                // The +1 is because date range starts from yesterday, and not from today
                var daysToSubtract = (+this.route.snapshot.queryParams['range'] || 30) + 1;
                fromDate = moment().subtract({ days: daysToSubtract }).toDate();
            }
        }
        var dateRange = widgetType === WidgetType.Daily ? 0 : +this.route.snapshot.queryParams['range'] || 30;
        var toDate = widgetType === WidgetType.Trend && filtersState.selection[reportWithFiltersToDateProperty]
            ? filtersState.selection[reportWithFiltersToDateProperty]
            : widgetType === WidgetType.Trend && urlDateRange && urlDateRange.to
                ? urlDateRange.to
                : new Date(new Date(fromDate.valueOf()).setDate(fromDate.getDate() + dateRange));
        var serializedFilters = filtersState.serialized;
        if (serializedFilters) {
            delete serializedFilters[reportWithFiltersFromDateProperty];
            delete serializedFilters[reportWithFiltersToDateProperty];
        }
        // Date only, time is not relevant and time causes Paris to send a few requests for the same data due to ms difference
        fromDate.setUTCHours(0, 0, 0, 0);
        toDate.setUTCHours(0, 0, 0, 0);
        return __assign({}, serializedFilters, (_a = {}, _a[reportWithFiltersFromDateProperty] = fromDate.toISOString(), _a[reportWithFiltersToDateProperty] = toDate.toISOString(), _a));
    };
    ReportsService.prototype.calcCurrentDataDateRangeForTrend = function (serializedFilters) {
        var to = serializedFilters && reportWithFiltersToDateProperty in serializedFilters
            ? new Date(serializedFilters[reportWithFiltersToDateProperty])
            : this.getYesterday().toDate();
        var from = serializedFilters && reportWithFiltersFromDateProperty in serializedFilters
            ? new Date(serializedFilters[reportWithFiltersFromDateProperty])
            : moment().startOf('day').subtract({ days: 31 }).toDate();
        return [from, to];
    };
    ReportsService.prototype.calcCurrentDataDateForDaily = function (serializedFilters, today) {
        return serializedFilters && reportWithFiltersToDateProperty in serializedFilters
            ? new Date(serializedFilters.toDate)
            : today;
    };
    ReportsService.prototype.getDefaultDatesForDaily = function () {
        var _a;
        var yesterday = this.getYesterday().toISOString();
        return _a = {},
            _a[reportWithFiltersFromDateProperty] = yesterday,
            _a[reportWithFiltersToDateProperty] = yesterday,
            _a;
    };
    ReportsService.prototype.getYesterday = function () {
        return moment.utc().startOf('day').subtract({ days: 1 });
    };
    ReportsService.prototype.getDefaultDatesForTrend = function () {
        var _a;
        var yesterday = moment.utc().subtract({ days: 1 }).startOf('day').toISOString();
        var fromDate = moment.utc().subtract({ days: 31 }).startOf('day').toISOString();
        return _a = {},
            _a[reportWithFiltersFromDateProperty] = fromDate,
            _a[reportWithFiltersToDateProperty] = yesterday,
            _a;
    };
    ReportsService.prototype.exportReport = function (report) {
        var timeRange = this.currentTimeRange.allowCustomRange
            ? this.currentTimeRange.dateRange.toString()
            : this.currentTimeRange.value;
        return this.downloadService.downloadFromUrl(this.serviceUrlsService.automatedIr + "/reports/export?range=" + timeRange + "&report_type=" + report.id + "&timezone=" + getUserTimeZone());
    };
    ReportsService.prototype.userHasRequiredPermissions = function (widget) {
        return (!widget.requiredPermissions ||
            this.authService.currentUser.hasMdeAllowedUserRoleAction(widget.requiredPermissions));
    };
    ReportsService.prototype.loadWidgetData = function (widget, options) {
        var _this = this;
        if (widget.isDisabled || !this.userHasRequiredPermissions(widget)) {
            return of(null);
        }
        var data$;
        if (!widget.api) {
            data$ = widget.loadData(options);
        }
        else {
            var apiList = widget.api instanceof Array ? widget.api : [widget.api];
            var apiObservableList = apiList.map(function (api) {
                var prefix = api.isExternal ? '' : _this.serviceUrlsService.automatedIr + "/", url = prefix + (api.url instanceof Function ? api.url() : api.url);
                return _this.http
                    .get(url, ApiBaseModel.getUrlParams(merge({}, options, /*widget.state,*/ api.params)))
                    .pipe(map(function (res) {
                    return res.data || res;
                }));
            });
            data$ = apiObservableList.length === 1 ? apiObservableList[0] : forkJoin(apiObservableList);
        }
        return widget.parseData
            ? data$.pipe(map(function (data) {
                try {
                    return widget.parseData(data);
                }
                catch (error) {
                    console.error("Error parsing data for widget " + widget.name + ":", error);
                    throw error;
                }
            }), catchError(function (error) { return throwError(new DataError(error)); }))
            : data$;
    };
    ReportsService.prototype.getTimeRangeByValue = function (value) {
        if (!this._timeRangesIndex)
            this._timeRangesIndex = keyBy(this.reportTimeRanges, 'value');
        return this._timeRangesIndex[value];
    };
    ReportsService.prototype.setCustomTimeRange = function (dateRange, updateOptionsNeeded) {
        if (updateOptionsNeeded === void 0) { updateOptionsNeeded = true; }
        var customTimeRange = this.getTimeRangeByValue('custom');
        customTimeRange.dateRange = dateRange;
        this._currentTimeRange = customTimeRange;
        if (updateOptionsNeeded) {
            this.updateOptions();
        }
    };
    /**
     * For charts that display timeseries (axis x type = 'timeseries'), turns the label into a Date object, which is required by the charts.
     * @param data
     * @returns {*}
     */
    ReportsService.parseTimeSeriesData = function (data) {
        var items = data instanceof Array ? data : data.values;
        return items.map(function (item) {
            return merge(item, { _label: item.label, label: new Date(item.from) });
        });
    };
    /**
     * Makes sure all the dates in the range are present in the array of time series items. For example, if only today's date is present, but the range is the last 30 days, the returned array will be 30 items
     * @param {Array<TimeSeriesDataItem>} items
     * @param {Date} from
     * @param {Date} to
     * @returns {Array<TimeSeriesDataItem>}
     */
    ReportsService.padTimeSeriesDates = function (items, from, to, valueProperty) {
        if (valueProperty === void 0) { valueProperty = 'value'; }
        var datesMap = new Map(), paddedItems = [];
        if (items)
            items.forEach(function (item) { return datesMap.set(item.label.toDateString(), item); });
        var currentDate = new Date(from);
        do {
            var item = datesMap.get(currentDate.toDateString()) || {
                label: currentDate,
                _label: currentDate.toString(),
            };
            if (!item[valueProperty])
                item[valueProperty] = 0;
            paddedItems.push(item);
            currentDate = new Date(currentDate);
            currentDate.setDate(currentDate.getDate() + 1);
        } while (currentDate < to);
        return paddedItems;
    };
    ReportsService.getDateLabel = function (date, range, timezone) {
        var rangeParts = typeof range === 'string'
            ? range.split(':').map(function (n) { return parseInt(n, 10); })
            : [parseInt(range, 10)], rangeDaysCount = rangeParts.length === 2
            ? (rangeParts[1] - rangeParts[0]) / (1000 * 60 * 60 * 24)
            : rangeParts[0], m = timezone ? moment.utc(date).add(timezone, 'hours') : moment(date);
        if (rangeDaysCount > 0 && rangeDaysCount < 1.99) {
            var to = moment(m).add(2, 'hours');
            return [m.format('hh'), to.format('hh')].join('-') + to.format('A');
        }
        return m.format('MM/DD');
    };
    // The function returns y ticks instead of letting c3 to auto define them.
    // This is to get integers and 4 ticks always.
    // The ticks will have quite equal spaces based on maxTick.
    // The only case we have less than 4 ticks is if maxTick is less than 10.
    ReportsService.getYTicks = function (maxTick) {
        if (maxTick === 0)
            return [];
        var yTicksCount = maxTick < 6 ? 2 : maxTick < 10 ? 3 : 4;
        var yTickUnit = maxTick / yTicksCount;
        var yTicks = [];
        for (var i = 0; i <= yTicksCount; i++) {
            yTicks.push(Math.round(yTickUnit * i));
        }
        return yTicks;
    };
    ReportsService.prototype.updateOptions = function () {
        this.reportState$.next(this.getCurrentOptions());
    };
    ReportsService.prototype.getCurrentOptions = function () {
        return {
            timezone: this.currentTimezone,
            range: this.currentTimeRange.allowCustomRange
                ? this.currentTimeRange.dateRange && this.currentTimeRange.dateRange.toString()
                : this.currentTimeRange.value,
        };
    };
    ReportsService.prototype.getTimeRangesValues = function () {
        return this.reportTimeRanges.filter(function (timeRange) {
            return /month/i.test(timeRange.name) || /days/i.test(timeRange.name) || timeRange.allowCustomRange;
        });
    };
    ReportsService.prototype.setBreadcrumbs = function (reportName) {
        if (!sccHostService.isSCC || !reportName) {
            return;
        }
        var breadcrumbItems = [
            {
                text: this.i18nService.get('main.navigation.menu.item.reports'),
                state: 'securityreports',
            },
            {
                text: reportName,
                state: '',
            },
        ];
        sccHostService.ui.setBreadcrumbs(breadcrumbItems);
    };
    ReportsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportsService_Factory() { return new ReportsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DownloadService), i0.ɵɵinject(i3.ServiceUrlsService), i0.ɵɵinject(i4.ActivatedRoute), i0.ɵɵinject(i5.AuthService), i0.ɵɵinject(i6.I18nService), i0.ɵɵinject(i7.TzDateService)); }, token: ReportsService, providedIn: "root" });
    return ReportsService;
}());
export { ReportsService };
export var halfYearDate = moment().subtract({ months: 6 }).toDate();
export var monthAgo = moment().subtract({ days: 31 }).toDate();
export var fiveMonthDate = moment().subtract({ months: 5 }).toDate();
export var yesterday = moment().subtract({ days: 1 }).toDate();
