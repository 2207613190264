<h2 class="wcd-font-weight-bold">{{ i18n.strings.email_notification_wizard_rule_name_title }}</h2>
<div class="wcd-margin-large-top wcd-input-placeholder wcd-textarea-placeholder">
	<div class="wcd-margin-xLarge-top wcd-margin-mediumSmall-bottom">
		{{ ruleNameDescription }}
	</div>

	<label for="email-notification-rule-name" class="wcd-margin-mediumSmall-top required wcd-font-weight-semibold">{{
		i18n.strings.email_notification_name_label
	}}</label>
	<input
		id="email-notification-rule-name"
		type="text"
		name="name"
		class="form-control wcd-flex-1"
		[maxlength]="nameLength"
		[placeholder]="'email.notification.name.placeholder' | i18n"
		[(ngModel)]="data.name"
		[validateRegExp]="nameRegExp"
		required
		#name="ngModel"
	/>
	<div class="error-message" *ngIf="data.name && !name.valid">
		{{ 'notificationRules_ruleName_error' | i18n }}
	</div>

	<label for="email-notification-rule-description" class="wcd-margin-mediumSmall-top wcd-font-weight-semibold">{{
		i18n.strings.email_notification_description_label
	}}</label>
	<textarea
		id="email-notification-rule-description"
		class="form-control wcd-full-width"
		[placeholder]="'email.notification.description.placeholder' | i18n"
		name="description"
		[maxlength]="descriptionLength"
		rows="4"
		[(ngModel)]="data.description"
		[validateRegExp]="descriptionRegExp"
		#description="ngModel"
	></textarea>
	<div class="error-message" *ngIf="data.description && !description.valid">
		{{ 'notificationRules_ruleDescription_error' | i18n }}
	</div>

	<div class="form-group wcd-margin-large-top" *ngIf="includeStatus">
		<wcd-checkbox
			[(ngModel)]="data.isEnabled"
			[id]="'email-notification-enable'"
			[name]="'email-notification-enable'"
			[label]="i18n.strings.email_notification_enable"
			(change)="onModelChange()"
		></wcd-checkbox>
	</div>
</div>
