/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i2 from "../../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "./alert-artifact-timeline.component";
import * as i8 from "../../../../global_entities/services/activated-entity.service";
var styles_AlertArtifactTimelineComponent = [];
var RenderType_AlertArtifactTimelineComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertArtifactTimelineComponent, data: {} });
export { RenderType_AlertArtifactTimelineComponent as RenderType_AlertArtifactTimelineComponent };
function View_AlertArtifactTimelineComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-datatable", [], null, null, null, i1.View_DataTableComponent_0, i1.RenderType_DataTableComponent)), i0.ɵdid(1, 13352960, null, 0, i2.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.LiveAnnouncer, i4.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf; var currVal_1 = false; var currVal_2 = _co.tableFields; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AlertArtifactTimelineComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-flex-1 wcd-margin-small-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertArtifactTimelineComponent_2)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.context.ngIf.length; var currVal_1 = i0.ɵnov(_v.parent, 10); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_AlertArtifactTimelineComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "wcd-full-height loading-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "large-loader-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], null, null); }
function View_AlertArtifactTimelineComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "wcd-padding-all"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "icon icon-Error"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "alert.tabs.timeline.noData")); _ck(_v, 4, 0, currVal_0); }); }
export function View_AlertArtifactTimelineComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "wcd-full-height wcd-flex-1 wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AlertArtifactTimelineComponent_1)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(0, [["loader", 2]], null, 0, null, View_AlertArtifactTimelineComponent_3)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(0, [["noData", 2]], null, 0, null, View_AlertArtifactTimelineComponent_4)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.events$)); var currVal_1 = i0.ɵnov(_v, 8); _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_AlertArtifactTimelineComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alert-artifact-timeline", [], null, null, null, View_AlertArtifactTimelineComponent_0, RenderType_AlertArtifactTimelineComponent)), i0.ɵdid(1, 114688, null, 0, i7.AlertArtifactTimelineComponent, [i4.I18nService, i8.ActivatedEntity], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertArtifactTimelineComponentNgFactory = i0.ɵccf("alert-artifact-timeline", i7.AlertArtifactTimelineComponent, View_AlertArtifactTimelineComponent_Host_0, { alert: "alert" }, {}, []);
export { AlertArtifactTimelineComponentNgFactory as AlertArtifactTimelineComponentNgFactory };
