import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { get } from 'lodash-es';

@ApiCall({
	name: 'URL data',
	endpoint: 'autoir/officeui/interflow/domain',
	baseUrl: config => config.data.serviceUrls.k8s,
	method: 'POST',
	parseQuery: input => {
		return {
			data: {
				domain: input['address'],
			},
		};
	},
	parseData: data => {
		data = data && data.length && data[0];
		const registrant = get(data, 'parsed_whois.contacts.registrant');
		const mailingAddress = registrant
			? [
					get(registrant, 'street[0]'),
					registrant['city'],
					[registrant['state'], registrant['postal']].filter(Boolean).join(' '),
					registrant['country'],
			  ]
					.filter(Boolean)
					.join(', ')
			: null;
		const registrar = get(data, 'parsed_whois.registrar');
		let created = data['created'];
		created = created && (created.endsWith('Z') ? created : created + 'Z');
		let expires = data['expires'];
		expires = expires && (expires.endsWith('Z') ? expires : expires + 'Z');
		let updated = data['updated'];
		updated = updated && (updated.endsWith('Z') ? updated : updated + 'Z');
		return {
			registrant: {
				organization: get(registrant, 'org'),
				owner: get(registrant, 'name'),
				email: get(registrant, 'email'),
				phone: get(registrant, 'phone'),
				mailingAddress,
			},
			registrar: {
				name: registrar && registrar['name'],
			},
			created: created && new Date(created),
			expires: expires && new Date(expires),
			updated: updated && new Date(updated),
		};
	},
	cache: {
		time: 60 * 1000 * 60,
	},
})
export class InterflowUrlDataApiCall extends ApiCallModel<InterflowUrlData, { address: string }> {}

interface InterflowUrlData {
	registrant: {
		organization: string;
		owner: string;
		email: string;
		phone: string;
		mailingAddress: string;
	};
	registrar: {
		name: string;
	};
	created: Date;
	expires: Date;
	updated: Date;
}
