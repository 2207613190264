import { PrettyNumberService } from './../../../../../../../../projects/prettify/src/lib/services/pretty-number.service';
import { I18nService } from './../../../../../../../../projects/i18n/src/lib/services/i18n.service';
import { of } from 'rxjs';
import { VulnerabilityChangeEventType, ConfigurationChangeEventType, ChangeEventTypesForMdatp } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmColorService, TvmColor } from './../../../../tvm/services/tvm-color.service';
import { WcdIconNames } from '@wcd/icons-font';
var ChangeEventService = /** @class */ (function () {
    function ChangeEventService(tvmColorService, i18nService, prettyNumberService) {
        this.tvmColorService = tvmColorService;
        this.i18nService = i18nService;
        this.prettyNumberService = prettyNumberService;
        // Those functions are called from another context, bind them to the service instead
        this.getToolTip = this.getToolTip.bind(this);
        this.getScoreTrendIconClass = this.getScoreTrendIconClass.bind(this);
        this.getAssetsCountString = this.getAssetsCountString.bind(this);
    }
    ChangeEventService.prototype.getScoreTrendIconName = function (changeEvent) {
        if (changeEvent.score > 0) {
            return FabricIconNames.CaretSolidUp;
        }
        else if (changeEvent.score < 0) {
            return FabricIconNames.CaretSolidDown;
        }
        return null;
    };
    ChangeEventService.prototype.getScoreTrendIconClass = function (changeEvent) {
        if (changeEvent.score > 0) {
            return "color-text-" + this.tvmColorService.colorsMap.get(TvmColor.HighRisk).name;
        }
        else if (changeEvent.score < 0) {
            return "color-text-" + this.tvmColorService.colorsMap.get(TvmColor.Positive).name;
        }
        return null;
    };
    ChangeEventService.prototype.getTitleIconName = function (changeEvent) {
        switch (changeEvent.eventType) {
            case VulnerabilityChangeEventType.NewCve:
                return FabricIconNames.ReportWarning;
            case VulnerabilityChangeEventType.CveHasPubliclyDisclosedExploit:
                return FabricIconNames.Bug;
            case VulnerabilityChangeEventType.CveHasVerifiedExploit:
                return WcdIconNames.bug_warning;
            case VulnerabilityChangeEventType.CveHasExploitInKit:
                return WcdIconNames.bug_lightning_bolt;
            case ConfigurationChangeEventType.NewScid:
                return WcdIconNames.new_scid;
        }
        return null;
    };
    ChangeEventService.prototype.getToolTip = function (changeEvent) {
        var prefix = 'tvm.changeEvent.scoreTrendValues.';
        if (changeEvent.score > 0) {
            return this.i18nService.get(prefix + "negativeEffect");
        }
        else if (changeEvent.score < 0) {
            return this.i18nService.get(prefix + "positiveEffect");
        }
        return this.i18nService.get(prefix + "noEffect");
    };
    ChangeEventService.prototype.getScoreText = function (changeEvent) {
        return !changeEvent.score || changeEvent.score === 0 ? '–' : '';
    };
    ChangeEventService.prototype.getRelatedProductId = function (changeEvent) {
        return changeEvent.vendor + "-_-" + changeEvent.productName;
    };
    ChangeEventService.prototype.getAssetsCountString = function (changeEvent) {
        return changeEvent.assetsCount === null || changeEvent.assetsCount === undefined
            ? null
            : this.prettyNumberService.prettyNumber(changeEvent.assetsCount) + " " + this.roundAndDisplayPercent(changeEvent.affectedAssetsPercent);
    };
    ChangeEventService.prototype.getCurrentAssetsCountString = function (changeEvent) {
        return changeEvent.currentAssetsCount === null || changeEvent.currentAssetsCount === undefined
            ? null
            : this.prettyNumberService.prettyNumber(changeEvent.currentAssetsCount) + " " + this.roundAndDisplayPercent(changeEvent.currentAffectedAssetsPercent);
    };
    ChangeEventService.prototype.getChangeEventFilters = function () {
        var _this = this;
        if (!this._changeEventFilters) {
            this._changeEventFilters = {
                assetsCount: {
                    // This filter is defined in the fields service - special component
                    values: {},
                },
                eventType: {
                    values: this.getFilterValues(),
                },
            };
        }
        return of(Object.keys(this._changeEventFilters).reduce(function (res, key) {
            var _a;
            return Object.assign(res, (_a = {}, _a[key] = _this._changeEventFilters[key], _a));
        }, {}));
    };
    ChangeEventService.prototype.getFilterValues = function () {
        var _this = this;
        var vaFilters = ChangeEventTypesForMdatp.map(function (type) { return ({
            value: type,
            name: _this.i18nService.get("tvm.changeEvent.eventType." + VulnerabilityChangeEventType[type]),
        }); });
        var scaFilters = Object.keys(ConfigurationChangeEventType).map(function (type) { return ({
            value: type,
            name: _this.i18nService.get("tvm.changeEvent.eventType." + ConfigurationChangeEventType[type]),
        }); });
        return scaFilters.concat(vaFilters);
    };
    ChangeEventService.prototype.roundAndDisplayPercent = function (decimalPercent) {
        var rounded = Math.round(decimalPercent);
        return decimalPercent >= 0 && rounded <= 100 ? "(" + (rounded === 0 ? '<1' : rounded) + "%)" : '';
    };
    return ChangeEventService;
}());
export { ChangeEventService };
