import { keyBy } from 'lodash-es';
import { InternetFacingReason } from './internet-facing-reason.enum';
export const internetFacingReasons = [
	{
		id: InternetFacingReason.ExternalIpAddress,
		i18nNameKey: 'InternetFacingTag_ExternalIpAddress_tooltip',
		priority: 1,
	},
	{
		id: InternetFacingReason.ExternalNetworkConnection,
		i18nNameKey: 'InternetFacingTag_ExternalNetworkConnection_tooltip',
		priority: 2,
	},
	{
		id: InternetFacingReason.PublicScan,
		i18nNameKey: 'InternetFacingTag_PublicScan_tooltip',
		priority: 3,
	}
];

export const internetFacingReasonMap = keyBy(internetFacingReasons, o => o.id);
