var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityModelBase, EntityField, ValueObject, ModelBase, } from '@microsoft/paris';
import { StatusModel } from '../../status.model';
import { Machine } from '../../machine/machine.entity';
import { File } from '../../file/file.entity';
import { HuntingRuleCustomSupportedActionType } from '../hunting-rule-custom-action.entity';
import { HuntingRuleEntityType } from '../hunting-rule-impacted-entity.value-object';
import { AadUser } from '../../AAD/user/aad.user.entity';
import { MailMessage } from '../../mailbox/mail-message.value-object';
import { Mailbox } from '../../mailbox/mailbox.entity';
import { Url } from '../../url/url.entity';
export var HuntingCustomActionStatusCode;
(function (HuntingCustomActionStatusCode) {
    HuntingCustomActionStatusCode[HuntingCustomActionStatusCode["Submitted"] = 0] = "Submitted";
    HuntingCustomActionStatusCode[HuntingCustomActionStatusCode["Running"] = 1] = "Running";
    HuntingCustomActionStatusCode[HuntingCustomActionStatusCode["Completed"] = 2] = "Completed";
    HuntingCustomActionStatusCode[HuntingCustomActionStatusCode["Failed"] = 3] = "Failed";
    HuntingCustomActionStatusCode[HuntingCustomActionStatusCode["Skipped"] = 4] = "Skipped";
})(HuntingCustomActionStatusCode || (HuntingCustomActionStatusCode = {}));
var ɵ0 = HuntingCustomActionStatusCode.Submitted, ɵ1 = HuntingCustomActionStatusCode.Running, ɵ2 = HuntingCustomActionStatusCode.Completed, ɵ3 = HuntingCustomActionStatusCode.Failed, ɵ4 = HuntingCustomActionStatusCode.Skipped;
var CustomActionRunStatus = /** @class */ (function (_super) {
    __extends(CustomActionRunStatus, _super);
    function CustomActionRunStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomActionRunStatus = __decorate([
        Entity({
            singularName: 'Custom action status',
            pluralName: 'Custom action statuses',
            values: [
                {
                    id: ɵ0,
                    name: '',
                    type: 'submitted',
                    nameI18nKey: 'statuses_submitted',
                    iconName: 'CompletedSolid',
                    className: 'success',
                },
                {
                    id: ɵ1,
                    name: '',
                    type: 'running',
                    nameI18nKey: 'statuses_running',
                    image: '/assets/images/circle-loading.gif',
                    className: 'primary',
                },
                {
                    id: ɵ2,
                    name: '',
                    type: 'completed',
                    nameI18nKey: 'statuses_completed',
                    iconName: 'CompletedSolid',
                    className: 'success',
                },
                {
                    id: ɵ3,
                    name: '',
                    type: 'failure',
                    nameI18nKey: 'statuses_failed',
                    iconName: 'Error',
                    className: 'error',
                },
                {
                    id: ɵ4,
                    name: '',
                    type: 'skipped',
                    nameI18nKey: 'statuses_skipped',
                    iconName: 'Error',
                    className: 'primary',
                },
            ],
        })
    ], CustomActionRunStatus);
    return CustomActionRunStatus;
}(StatusModel));
export { CustomActionRunStatus };
var ɵ5 = HuntingRuleEntityType.Machine, ɵ6 = HuntingRuleEntityType.File, ɵ7 = HuntingRuleEntityType.User, ɵ8 = HuntingRuleEntityType.Mailbox, ɵ9 = HuntingRuleEntityType.MailMessage, ɵ10 = HuntingRuleEntityType.Url;
var CustomActionEntityType = /** @class */ (function (_super) {
    __extends(CustomActionEntityType, _super);
    function CustomActionEntityType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", Object)
    ], CustomActionEntityType.prototype, "type", void 0);
    CustomActionEntityType = __decorate([
        Entity({
            singularName: 'Custom action entity type',
            pluralName: 'Custom action entity types',
            values: [
                { id: ɵ5, type: Machine },
                { id: ɵ6, type: File },
                { id: ɵ7, type: AadUser },
                { id: ɵ8, type: Mailbox },
                { id: ɵ9, type: MailMessage },
                { id: ɵ10, type: Url },
            ],
        })
    ], CustomActionEntityType);
    return CustomActionEntityType;
}(EntityModelBase));
export { CustomActionEntityType };
export var HuntingCustomActionEntityRole;
(function (HuntingCustomActionEntityRole) {
    HuntingCustomActionEntityRole["ImpactedAsset"] = "ImpactedAsset";
    HuntingCustomActionEntityRole["TargetEntity"] = "TargetEntity";
})(HuntingCustomActionEntityRole || (HuntingCustomActionEntityRole = {}));
var HuntingCustomActionEntity = /** @class */ (function (_super) {
    __extends(HuntingCustomActionEntity, _super);
    function HuntingCustomActionEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(HuntingCustomActionEntity.prototype, "role", {
        get: function () {
            return this.entityType.id === HuntingRuleEntityType.File ||
                this.entityType.id === HuntingRuleEntityType.MailMessage
                ? HuntingCustomActionEntityRole.TargetEntity
                : HuntingCustomActionEntityRole.ImpactedAsset;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'EntityId' }),
        __metadata("design:type", String)
    ], HuntingCustomActionEntity.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'EntityName' }),
        __metadata("design:type", String)
    ], HuntingCustomActionEntity.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'EntityType' }),
        __metadata("design:type", CustomActionEntityType)
    ], HuntingCustomActionEntity.prototype, "entityType", void 0);
    HuntingCustomActionEntity = __decorate([
        ValueObject({
            singularName: 'Hunting custom action entity',
            pluralName: 'Hunting custom action entities',
        })
    ], HuntingCustomActionEntity);
    return HuntingCustomActionEntity;
}(ModelBase));
export { HuntingCustomActionEntity };
var ɵ11 = function (_a) {
    var Entities = _a.Entities, EntityId = _a.EntityId, EntityName = _a.EntityName, EntityType = _a.EntityType;
    return Entities && Entities.length ? Entities : [{ EntityId: EntityId, EntityName: EntityName, EntityType: EntityType }];
};
var HuntingCustomActionResult = /** @class */ (function (_super) {
    __extends(HuntingCustomActionResult, _super);
    function HuntingCustomActionResult() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(HuntingCustomActionResult.prototype, "entities", {
        get: function () {
            return this._entities;
        },
        set: function (value) {
            this._entities = value || [];
            this._impactedAsset = this._entities.find(function (e) { return e.role === HuntingCustomActionEntityRole.ImpactedAsset; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HuntingCustomActionResult.prototype, "impactedAsset", {
        get: function () {
            return this._impactedAsset;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'ActionType' }),
        __metadata("design:type", Number)
    ], HuntingCustomActionResult.prototype, "actionType", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ11,
            arrayOf: HuntingCustomActionEntity,
            defaultValue: [],
        }),
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [Array])
    ], HuntingCustomActionResult.prototype, "entities", null);
    __decorate([
        EntityField({ data: 'StartTime' }),
        __metadata("design:type", Date)
    ], HuntingCustomActionResult.prototype, "startTime", void 0);
    __decorate([
        EntityField({ data: 'Status' }),
        __metadata("design:type", CustomActionRunStatus)
    ], HuntingCustomActionResult.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'StatusDetails' }),
        __metadata("design:type", String)
    ], HuntingCustomActionResult.prototype, "failureReason", void 0);
    __decorate([
        EntityField({ data: 'AsyncActionId' }),
        __metadata("design:type", Number)
    ], HuntingCustomActionResult.prototype, "asyncActionId", void 0);
    HuntingCustomActionResult = __decorate([
        ValueObject({
            singularName: 'Hunting custom action result',
            pluralName: 'Hunting custom action results',
            readonly: true,
        })
    ], HuntingCustomActionResult);
    return HuntingCustomActionResult;
}(ModelBase));
export { HuntingCustomActionResult };
var CustomActionRun = /** @class */ (function (_super) {
    __extends(CustomActionRun, _super);
    function CustomActionRun() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'RuleId' }),
        __metadata("design:type", Number)
    ], CustomActionRun.prototype, "ruleId", void 0);
    __decorate([
        EntityField({ data: 'CustomActionRunId' }),
        __metadata("design:type", Number)
    ], CustomActionRun.prototype, "id", void 0);
    CustomActionRun = __decorate([
        ValueObject({
            singularName: 'Custom action run',
            pluralName: 'Custom action runs',
        })
    ], CustomActionRun);
    return CustomActionRun;
}(HuntingCustomActionResult));
export { CustomActionRun };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
