import { Component } from '@angular/core';
import { LegacyUser, SeverityType } from '@wcd/domain';
import { AppConfigService, ServiceUrlsService } from '@wcd/app-config';
import { TrackingEventType } from '../../../../../insights/models/tracking-event-type.enum';
import { SeverityTypesService } from '../../../../../shared/services/severity-types.service';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { DataTableField } from '@wcd/datatable';
import { UsersService } from '../../../../../@entities/users/services/users.service';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'users-at-risk-widget',
	template: `
		<wcd-datatable
			[items]="data$ | async"
			[columns]="tableColumns"
			[hidden]="error$ | async"
			[selectEnabled]="false"
			[showHeaders]="false"
			[label]="'dashboard_threatAnalytics_userAtRisk_widget_internal_grid_title'| i18n"
			class="table-with-legend"
		></wcd-datatable>
	`,
})
export class UsersAtRiskWidget extends ReportWidgetComponent<UserBackendData[], UsersAtRiskBackendData> {
	get widgetConfig(): ReportWidgetConfig<UserBackendData[], UsersAtRiskBackendData> {
		const rangeInDays: number = this.appConfigService.widgetLookback;
		return {
			id: 'usersAtRisk',
			name: this.i18nService.get('widget_title_usersAtRisk'),
			noDataMessage: this.i18nService.get('widget_noData_usersAtRisk'),
			noDataIcon: 'users.user',
			rangeInDays: rangeInDays,
			api: {
				url: () => `${this.serviceUrlsService.threatIntel}/Dashboard/GetTopAlertUsers`,
				isExternal: true,
				params: { lookBackInDays: rangeInDays, readFromCache: String(true) },
			},
			parseData: this.parseData.bind(this),
		};
	}

	tableColumns: Array<DataTableField<UserBackendData>> = DataTableField.fromList<UserBackendData>([
		{
			id: 'userName',
			name: 'widget_title_usersAtRisk_user_name',
			icon: {
				fabricIcon: this.globalEntityTypesService.getEntityTypeIcon(LegacyUser),
			},
			getDisplay: (item: UserBackendData) =>
				`${
					item.DomainName ? item.DomainName.toLowerCase() + '\\' : ''
				}${item.UserName.toLowerCase()}`,
			className: 'nowrap',
			getLink: (item: UserBackendData) =>
				this.usersService.getUserUrl({
					accountName: item.UserName,
					accountDomainName: item.DomainName,
					sid: item.UserSid,
				}),
			getTooltip: (item: UserBackendData) =>
				`${
					item.DomainName ? item.DomainName.toLowerCase() + '\\' : ''
				}${item.UserName.toLowerCase()}`,
			fluidWidth: 1,
			custom: {
				tracking: {
					id: 'userLink',
					type: TrackingEventType.Navigation,
				},
			},
		},
		{
			id: 'highSeverityAlerts',
			name: 'widget_title_usersAtRisk_high_severity_alerts',
			getDisplay: (item: UserBackendData) => item.HighSevAlerts,
			getFieldCssClass: () => {
				const severity = SeverityTypesService.getSeverityByType(SeverityType.High);
				return 'color-border-' + severity.className;
			},
			className: 'nowrap legend-item-cell',
			tooltip: this.i18nService.get('widget_title_high_severity_tooltip'),
		},
		{
			id: 'mediumSeverityAlerts',
			name: 'widget_title_usersAtRisk_medium_severity_alerts',
			getDisplay: (item: UserBackendData) => item.MediumSevAlerts,
			getFieldCssClass: () => {
				const severity = SeverityTypesService.getSeverityByType(SeverityType.Medium);
				return 'color-border-' + severity.className;
			},
			className: 'nowrap legend-item-cell',
			tooltip: this.i18nService.get('widget_title_medium_severity_tooltip'),
		},
		{
			id: 'lowSeverityAlerts',
			name: 'widget_title_usersAtRisk_low_severity_alerts',
			getDisplay: (item: UserBackendData) => item.LowSevAlerts,
			getFieldCssClass: () => {
				const severity = SeverityTypesService.getSeverityByType(SeverityType.Low);
				return 'color-border-' + severity.className;
			},
			className: 'nowrap legend-item-cell',
			tooltip: this.i18nService.get('widget_title_low_severity_tooltip'),
		},
		{
			id: 'infoSeverityAlerts',
			name: 'widget_title_usersAtRisk_information_severity_alerts',
			getDisplay: (item: UserBackendData) => item.InfoSevAlerts,
			getFieldCssClass: () => {
				const severity = SeverityTypesService.getSeverityByType(SeverityType.Informational);
				return 'color-border-' + severity.className;
			},
			className: 'nowrap legend-item-cell',
			tooltip: this.i18nService.get('widget_title_information_severity_tooltip'),
		},
	]);

	constructor(
		reportsService: ReportsService,
		private serviceUrlsService: ServiceUrlsService,
		private appConfigService: AppConfigService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private usersService: UsersService,
		private i18nService: I18nService
	) {
		super(reportsService);
	}

	parseData(data: UsersAtRiskBackendData): Array<UserBackendData> {
		return data.Users;
	}
}

interface UserBackendData {
	HighSevAlerts: number;
	MediumSevAlerts: number;
	LowSevAlerts: number;
	InfoSevAlerts: number;
	DomainName: string;
	UserName: string;
	UserSid: string;
}

interface UsersAtRiskBackendData {
	Users: Array<UserBackendData>;
}
