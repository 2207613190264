/* tslint:disable:template-click-events-have-key-events */
import { Component, Input } from '@angular/core';
import {
	TreeElementCyberEntityTypeEnum,
	LegacyUser,
	LegacyUserProfile,
	LegacyUserLegacyUserProfileRelationship,
} from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AlertPageService } from '../services/alert-page.service';
import { Paris } from '@microsoft/paris';
import { EntityCommandBarDisplayMode } from '../../../global_entities/models/entity-command-bar-display-mode.enum';

@Component({
	selector: 'alerted-user-details',
	templateUrl: './alerted-user-details.component.html',
	styleUrls: ['./alerted-machine-details.component.scss'],
})
export class AlertedUserDetailsComponent {
	@Input() user: LegacyUser;
	userProfile: LegacyUserProfile;

	userType: any;
	userIcon: string;
	userDetailsTabType: string;
	entityCommandBarDisplayMode = EntityCommandBarDisplayMode;

	constructor(
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly paris: Paris,
		readonly alertPageService: AlertPageService
	) {
		this.userIcon = this.globalEntityTypesService.getEntityTypeIcon(LegacyUser);
		this.userType = this.globalEntityTypesService.getEntityType(LegacyUser);
		this.userDetailsTabType = TreeElementCyberEntityTypeEnum.user;
	}

	ngOnInit() {
		this.paris
			.getRelatedItem<LegacyUser, LegacyUserProfile>(LegacyUserLegacyUserProfileRelationship, this.user)
			.subscribe(profile => (this.userProfile = profile));
	}
}
