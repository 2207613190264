import { PrettyNumberService } from '@wcd/prettify';
import { maxBy, mapValues, mapKeys, merge } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { ChartType } from './reporting.types';
import { ReportsService } from '../shared-reports/services/reports.service';
import { TzDateService } from '@wcd/localization';
var MAX_LABELS = 4;
var COMMON_C3_CONFIG = {
    grid: {
        y: {
            show: true,
        },
    },
    point: {
        show: false,
    },
    legend: { show: false },
    size: {
        height: 250,
    },
};
var ReportingService = /** @class */ (function () {
    function ReportingService(prettyNumberService, i18nService, tzDateService) {
        this.prettyNumberService = prettyNumberService;
        this.i18nService = i18nService;
        this.tzDateService = tzDateService;
    }
    ReportingService.prototype.getSettingsDaily = function (type, chartSeriesConfiguration, dimPeriodData, c3Options) {
        var _this = this;
        var dimensionData = dimPeriodData && dimPeriodData.data && dimPeriodData.data.length && dimPeriodData.data[0];
        var maxCount = dimensionData && dimensionData.values && maxBy(Object.values(dimensionData.values));
        var chartData = Object.entries(chartSeriesConfiguration).map(function (_a) {
            var key = _a[0], value = _a[1];
            return _this.getDimensionDataDaily(value.label, key, dimensionData);
        });
        var colorsPattern = Object.values(chartSeriesConfiguration).map(function (value) {
            var raw = value.knownColor ? value.knownColor.raw : value.knownColorsMap.daily.raw;
            return raw;
        });
        var defaultDailyConfig = {
            options: {
                color: {
                    pattern: colorsPattern,
                },
                data: {
                    columns: [[this.i18nService.get('common.all')].concat(chartData.map(function (data) { return data.value; }))],
                    groups: [
                        Object.values(chartSeriesConfiguration).map(function (value) { return value.label; }),
                    ],
                    type: 'bar',
                    labels: {
                        format: function (value) { return (maxCount > 0 ? _this.prettyNumberService.prettyNumber(value) : ''); },
                    },
                    color: function (inColor, data) {
                        if (data.index !== undefined) {
                            return colorsPattern[data.index];
                        }
                        return inColor;
                    },
                },
                tooltip: {
                    grouped: false,
                    contents: function (data) {
                        return "<table class=\"c3-tooltip\">\n\t\t\t\t\t\t\t<tbody>\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td class=\"name\">" + data[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") /* Add commas to numbers (e.g. 1,234,567)*/ + "</td>\n\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t</tbody>\n\t\t\t\t\t\t</table>";
                    },
                },
                bar: {
                    width: {
                        max: 8,
                        ratio: 0.2,
                    },
                },
                axis: {
                    rotated: type === ChartType.Horizontal,
                    x: {
                        type: 'category',
                        categories: chartData.map(function (data) { return data.label; }),
                        tick: {
                            multiline: type === ChartType.Horizontal,
                            culling: {
                                max: 1,
                            },
                            rotate: 75,
                        },
                        padding: { right: 0.5 },
                    },
                    y: {
                        show: true,
                        max: maxCount ? maxCount : 0,
                        min: 0,
                        tick: {
                            values: dimPeriodData ? ReportsService.getYTicks(maxCount) : [],
                            format: function (value) { return _this.prettyNumberService.prettyNumber(value); },
                        },
                        padding: {
                            bottom: 0,
                        },
                    },
                },
                padding: {
                    left: type === ChartType.Horizontal ? 100 : 50,
                },
            },
            disableCursor: true,
        };
        var settings = merge(defaultDailyConfig, { options: COMMON_C3_CONFIG }, { options: c3Options });
        return settings;
    };
    ReportingService.prototype.getSettingsOverTime = function (dim, chartSeriesConfiguration, dimPeriodData, c3Options) {
        var _this = this;
        var dimensionData = dimPeriodData.data && dimPeriodData.data.length && dimPeriodData.data;
        var maxMachineCount = dimensionData &&
            dimensionData.reduce(function (maxMachineCount, data) { return Math.max(maxBy(Object.values(data.values)), maxMachineCount); }, 0);
        var defaultOverTimeConfig = {
            options: {
                data: {
                    columns: this.getDimensionData(dim, dimensionData, chartSeriesConfiguration),
                    colors: mapValues(mapKeys(chartSeriesConfiguration, function (value) { return value.label; }), function (value) {
                        var raw = value.knownColor ? value.knownColor.raw : value.knownColorsMap.trend.raw;
                        return raw;
                    }),
                },
                axis: {
                    y: {
                        max: maxMachineCount ? maxMachineCount : 0,
                        min: 0,
                        tick: {
                            values: dimensionData ? ReportsService.getYTicks(maxMachineCount) : [],
                            format: function (value) { return _this.prettyNumberService.prettyNumber(value); },
                        },
                        padding: {
                            bottom: 0,
                        },
                    },
                    x: {
                        type: 'category',
                        categories: this.getDimensionDates(dimensionData),
                        tick: {
                            multiline: false,
                            culling: {
                                max: dimensionData
                                    ? Math.min(dimensionData.length + 1, MAX_LABELS)
                                    : MAX_LABELS,
                            },
                        },
                    },
                },
            },
            disableCursor: true,
        };
        var settings = merge(defaultOverTimeConfig, { options: COMMON_C3_CONFIG }, { options: c3Options });
        return settings;
    };
    ReportingService.prototype.getDimensionData = function (dim, dimDailyDataArr, chartSeriesConfiguration) {
        var _this = this;
        return Object.keys(dim)
            .filter(function (key) { return !!chartSeriesConfiguration[key]; })
            .map(function (key) {
            return _this.getSeriesData(chartSeriesConfiguration[key].label, key, dimDailyDataArr);
        });
    };
    ReportingService.prototype.getSeriesData = function (label, dimMember, dimDailyDataArr) {
        return [
            label
        ].concat((dimDailyDataArr &&
            dimDailyDataArr.map(function (data) { return (data.values[dimMember] ? data.values[dimMember] : 0); })));
    };
    ReportingService.prototype.getDimensionDates = function (dimensionData) {
        var _this = this;
        return dimensionData ? dimensionData.map(function (data) { return _this.tzDateService.format(data.date, 'MM/dd'); }) : [];
    };
    ReportingService.prototype.getDimensionDataDaily = function (label, dimMember, reportDailyData) {
        return {
            label: label,
            value: reportDailyData &&
                (reportDailyData.values[dimMember] ? reportDailyData.values[dimMember] : 0),
        };
    };
    return ReportingService;
}());
export { ReportingService };
