import { NgModule } from '@angular/core';
import { AngularExtensionsModule } from '@wcd/angular-extensions';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FabButtonModule, FabIconModule } from '@angular-react/fabric';
import { I18nModule } from '@wcd/i18n';
import { WcdWizardComponent } from './components/wizard.component';
import { VerticalStepsComponent } from './components/vertical-steps/vertical-steps.component';
import { TooltipsModule } from '@wcd/dialogs';
@NgModule({
	imports: [CommonModule, FormsModule, FabButtonModule, FabIconModule, I18nModule, AngularExtensionsModule, TooltipsModule],
	declarations: [WcdWizardComponent, VerticalStepsComponent],
	exports: [WcdWizardComponent],
})
export class WcdWizardModule {}
