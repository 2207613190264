import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { CustomTiIndicator } from './custom-ti-indicator.entity';
import { CustomTiIndicatorsType } from './custom-ti-indicators-type.entity';

@EntityRelationship({
	sourceEntity: CustomTiIndicatorsType,
	dataEntity: CustomTiIndicator,
	foreignKey: 'type',
	allowedTypes: [RelationshipType.OneToMany],
})
export class CustomTiIndicatorTypeExclusionsRelationship
	implements EntityRelationshipRepositoryType<CustomTiIndicatorsType, CustomTiIndicator> {}
