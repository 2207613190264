import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { OnChanges, TypedChanges } from '../types/angular/on-changes';

/**
 * This directive will use to replace aria-live on error message
 * when error message need to announce (turn visible) changing announceError to true
 * the Live Announcer will read the combination of the role in announceErrorRole (if exist) and
 * the message in announceErrorMessage
 */

@Directive({
	selector: '[announceError]',
})
export class AnnounceErrorDirective implements OnChanges<AnnounceErrorDirective>, AfterViewInit {
	@Input()
	announceError: boolean;

	@Input()
	announceErrorMessage: string;

	@Input()
	announceErrorRole?: string;

	@Input()
	announceErrorOnLoad: boolean = false;

	constructor(private liveAnnouncer: LiveAnnouncer) {}

	ngOnChanges(changes: TypedChanges<AnnounceErrorDirective>) {
		if (
			changes.announceError &&
			changes.announceError.currentValue &&
			!changes.announceError.isFirstChange()
		) {
			this.announceValidationError();
		}
	}

	ngAfterViewInit() {
		if (this.announceErrorOnLoad && this.announceError) {
			this.announceValidationError();
		}
	}

	announceValidationError() {
		const message =
			(this.announceErrorRole ? this.announceErrorRole + ': ' : '') + this.announceErrorMessage;
		this.liveAnnouncer.announce(message, 'assertive');
	}
}
