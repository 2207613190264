/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/charts/src/lib/bars/stacked-bars-chart.component.ngfactory";
import * as i2 from "../../../../../../../../../projects/charts/src/lib/bars/stacked-bars-chart.component";
import * as i3 from "@angular/common";
import * as i4 from "./alerts-by-detection-source.widget";
import * as i5 from "../../../../../shared-reports/services/reports.service";
import * as i6 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../../../insights/services/app-insights.service";
import * as i10 from "@microsoft/paris/dist/lib/paris";
var styles_AlertsByDetectionSourceWidget = [];
var RenderType_AlertsByDetectionSourceWidget = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertsByDetectionSourceWidget, data: {} });
export { RenderType_AlertsByDetectionSourceWidget as RenderType_AlertsByDetectionSourceWidget };
export function View_AlertsByDetectionSourceWidget_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "wcd-stacked-bars-chart", [], null, null, null, i1.View_StackedBarsChartComponent_0, i1.RenderType_StackedBarsChartComponent)), i0.ɵdid(2, 770048, null, 0, i2.StackedBarsChartComponent, [i0.ElementRef], { settings: [0, "settings"], data: [1, "data"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.chartSettings$)); var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 4).transform(_co.data$)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AlertsByDetectionSourceWidget_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AlertsByDetectionSourceWidget_0, RenderType_AlertsByDetectionSourceWidget)), i0.ɵdid(1, 245760, null, 0, i4.AlertsByDetectionSourceWidget, [i5.ReportsService, i6.I18nService, i7.PrettyNumberService, i8.Router, i9.AppInsightsService, i10.Paris], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertsByDetectionSourceWidgetNgFactory = i0.ɵccf("ng-component", i4.AlertsByDetectionSourceWidget, View_AlertsByDetectionSourceWidget_Host_0, {}, {}, []);
export { AlertsByDetectionSourceWidgetNgFactory as AlertsByDetectionSourceWidgetNgFactory };
