import { Paris } from '@microsoft/paris';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WebContentFilteringPolicy } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { Feature, FeaturesService } from '@wcd/config';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { MachinesService } from '../../machines/services/machines.service';
import { WebContentFilteringPolicyEditComponent } from '../components/web-content-filtering-policy-edit.component';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { WebThreatProtectionReportService } from '../../../reporting/web-threat-protection/web-threat-protection-report.service';
import _ from 'lodash';
var WebContentFilteringPolicyEntityTypeService = /** @class */ (function () {
    function WebContentFilteringPolicyEntityTypeService(paris, i18nService, dialogsService, authService, machineService, featureService, advancedFeaturesService, webThreatProtectionService) {
        var _this = this;
        this.paris = paris;
        this.i18nService = i18nService;
        this.dialogsService = dialogsService;
        this.authService = authService;
        this.machineService = machineService;
        this.featureService = featureService;
        this.advancedFeaturesService = advancedFeaturesService;
        this.webThreatProtectionService = webThreatProtectionService;
        this.entityType = {
            id: 'web-content-filtering-policy',
            entity: WebContentFilteringPolicy,
            loadFullEntityInPanel: false,
            getEntityName: function (policy) {
                return policy.policyName;
            },
            singleEntityPanelComponentType: WebContentFilteringPolicyEditComponent,
            getActions: function (policies) {
                return combineLatest(_this.rbacMachineGroupsEnabled && _this.machineService.getFullUserExposedMachineGroups(), _this.advancedFeaturesService.getAdvancedFeaturesSettings()).pipe(switchMap(function (_a) {
                    var machineGroups = _a[0], advancedFeatures = _a[1];
                    var actions = [];
                    /// can be not removable only if Rbac enabled and current user is not admin, otherwise delete option is available
                    var isNotRemovable = _this.rbacMachineGroupsEnabled &&
                        !!!_this.authService.currentUser.isMdeAdmin &&
                        policies.some(function (policy) {
                            // in case that current user not admin but selected one of the records that was added by admin, don't allow to delete
                            var createdByAdmin = !policy.rbacGroupIds || policy.rbacGroupIds.length === 0;
                            // or in case that one of the selected policies contains machine groups that user not exposed to
                            return (createdByAdmin ||
                                (policy.rbacGroupIds &&
                                    policy.rbacGroupIds.some(function (groupId) {
                                        return (machineGroups.filter(function (machineGroup) { return machineGroup.id === groupId; }).length === 0);
                                    })));
                        });
                    if (isNotRemovable) {
                        return of(actions.map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); }));
                    }
                    // Do not allow delete when web content filtering is off
                    actions = actions.slice();
                    if (advancedFeatures.webCategoriesEnabled) {
                        actions.push({
                            id: 'delete',
                            nameKey: 'delete',
                            icon: 'delete',
                            method: function (items) {
                                return new Promise(function (resolve, reject) {
                                    var itemName = items.length === 1
                                        ? items[0].policyName
                                        : items.length + " " + _this.i18nService
                                            .get('webContentFilteringPolicy.dataview.entity.names.pluralName')
                                            .toLowerCase();
                                    var repository = _this.paris.getRepository(WebContentFilteringPolicy);
                                    var deleteRequestData = items.map(function (item) {
                                        return {
                                            PolicyName: item.policyName,
                                            IndicatorValueIdMappings: item.indicatorValueIdMappings,
                                        };
                                    });
                                    _this.dialogsService
                                        .confirm({
                                        title: _this.i18nService.get('webContentFilteringPolicy.dataview.actions.delete.confirm.title', { itemName: itemName }),
                                        text: _this.i18nService.get('webContentFilteringPolicy.dataview.actions.delete.confirm.text', { itemName: itemName }),
                                        confirmText: _this.i18nService.get('webContentFilteringPolicy.dataview.actions.delete.confirm.confirmText'),
                                    })
                                        .then(function (e) {
                                        if (e.confirmed) {
                                            resolve(repository
                                                .remove(items, { data: deleteRequestData })
                                                .toPromise()
                                                .then(function (items) {
                                                _this.dialogsService.showSnackbar({
                                                    text: _this.i18nService.get('webContentFilteringPolicy.dataview.actions.delete.deleted', { itemName: itemName }),
                                                    icon: 'delete',
                                                    iconClassName: 'color-text-error',
                                                });
                                            }, function (error) {
                                                _this.dialogsService.showError({
                                                    title: _this.i18nService.get('webContentFilteringPolicy.dataview.actions.delete.errorMessage', { itemName: itemName }),
                                                    data: error,
                                                });
                                                reject(error);
                                            }));
                                        }
                                        else {
                                            resolve(e);
                                        }
                                    });
                                });
                            },
                            closeOnAction: function (data) {
                                if (!_.isNil(data) && !_.isNil(data.confirmed) && data.confirmed == false) {
                                    return false;
                                }
                                return true;
                            },
                            rbac: [RbacMdeAllowedActions.securitySettings],
                            rbacState: RbacControlState.hidden,
                            refreshOnResolve: true,
                        });
                    }
                    return of(actions.map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); }));
                }));
            },
        };
        this.rbacMachineGroupsEnabled = this.featureService.isEnabled(Feature.RbacMachineGroups);
    }
    return WebContentFilteringPolicyEntityTypeService;
}());
export { WebContentFilteringPolicyEntityTypeService };
