import { HttpClient } from '@angular/common/http';
import { ApiBaseModel } from '@wcd/data';
import { Injectable } from '@angular/core';
import { ServiceUrlsService } from '@wcd/app-config';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable()
export class OnboardingBackendService extends ApiBaseModel {
	constructor(http: HttpClient, private serviceUrlsService: ServiceUrlsService) {
		super(http);
	}

	dismissOnboardingWidget(): Observable<any> {
		return this.http
			.post(`${this.serviceUrlsService.management}/DismissOnboardingTile`, null)
			.pipe(timeout(60000));
	}
}
