import { IDomainCategory, DomainCategoryType } from './domain-category.models';

export const domainCategoryValues: Array<IDomainCategory> = [
	{
		id: 1,
		name: 'Phishing',
		type: DomainCategoryType.Phishing,
		priority: 1,
		isOpen: false,
	},
	{
		id: 2,
		name: 'Malicious',
		type: DomainCategoryType.Malicious,
		priority: 2,
		isOpen: false,
	},
	{
		id: 8,
		name: 'Custom Indicator',
		type: DomainCategoryType.CustomBlockList,
		priority: 4,
		isOpen: false,
	},
	{
		id: 32,
		name: 'Unknown',
		type: DomainCategoryType.Unknown,
		priority: 6,
		isOpen: false,
	},
];
