export enum MachineVulnerabilitySeverityLevelType {
	None = 'None',
	Low = 'Low',
	Medium = 'Medium',
	High = 'High',
	Critical = 'Critical',
}

export interface IMachineVulnerabilitySeverityLevel {
	readonly id: MachineVulnerabilitySeverityLevelType;
	name: string;
	nameI18nKey: string;
	priority: number;
}
