import {
	Component,
	forwardRef,
	ViewEncapsulation,
	ChangeDetectionStrategy,
	Input,
	OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DISABLEABLE_TOKEN } from '../../../../shared/interfaces/disableable.interface';
import { MdeUserRoleAction, MdeUserRoleActionEnum } from '@wcd/domain';

@Component({
	selector: 'user-role-permissions',
	templateUrl: './user-role-permissions.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UserRolePermissionsComponent),
			multi: true,
		},
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => UserRolePermissionsComponent),
		},
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRolePermissionsComponent implements ControlValueAccessor, OnInit {
	_allowedActionsModel: Array<MdeUserRoleAction>;

	partiallySelectedCategories: { [index: number]: boolean } = {};
	flattenedActions: Array<MdeUserRoleAction>;
	selectedValues: { [index: number]: boolean } = {};
	disabledPermissions: { [index: number]: boolean } = {};
	mandatoryPermissions: { [index: number]: boolean } = {};
	relevantActions: Array<MdeUserRoleAction> = [];
	liveResponseId: number = MdeUserRoleActionEnum.liveResponseBasic | MdeUserRoleActionEnum.liveResponseAdvanced;
	childToParentMap: { [index: number]: MdeUserRoleAction } = {};

	// Enables to use the static function 'getHelpKey' in the html file
	userRoleAction = MdeUserRoleAction;

	@Input() isDisabled?: boolean;

	@Input()
	set isLiveResponseSelected(isLiveResponseSelected: boolean) {
		this.selectedValues[this.liveResponseId] = isLiveResponseSelected;
		const liveResponseAction = this.relevantActions.find(action => action.id === this.liveResponseId);
		if (liveResponseAction) {
			this.onValueChanged(liveResponseAction);
		}
	}

	@Input('allowedActionsModel')
	set allowedActionsModel(values: Array<MdeUserRoleAction>) {
		this._allowedActionsModel = values;
		this.flattenedActions = MdeUserRoleAction.flatten(values);
	}

	get allowedActionsModel(): Array<MdeUserRoleAction> {
		return this._allowedActionsModel;
	}

	@Input('ngModel')
	get userAllowedActions() {
		return this.flattenedActions.filter(value => this.selectedValues[value.id]);
	}

	set userAllowedActions(userAllowedActions: Array<MdeUserRoleAction>) {
		if (!userAllowedActions) return;
		userAllowedActions.forEach(allowedAction => {
			const setKey =
				(allowedAction.id & this.liveResponseId) !== 0 ? this.liveResponseId : allowedAction.id;
			this.selectedValues[setKey] = true;
		});
	}

	ngOnInit(): void {
		this.relevantActions = [
			{
				id: MdeUserRoleActionEnum.liveResponseBasic | MdeUserRoleActionEnum.liveResponseAdvanced,
				additionalImplicitAllowedActions: MdeUserRoleActionEnum.viewData,
			} as MdeUserRoleAction,
		];
		this.relevantActions = this.relevantActions.concat(this.flattenedActions);
		this.relevantActions.forEach(permission => (this.disabledPermissions[permission.id] = false));
		this.disableMandatoryPermissions();
		this.updateChildrenStatuses();
		this.relevantActions
			.filter(action => this.selectedValues[action.id])
			.forEach(action => this.onValueChanged(action));
	}

	onChange = (_: Array<MdeUserRoleAction>) => {};
	onTouched = () => {};

	writeValue(values: Array<MdeUserRoleAction>): void {
		this.userAllowedActions = values;
	}
	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	onValueChanged(action: MdeUserRoleAction) {
		if (action.children) {
			action.children.forEach(child => {
				if (!this.disabledPermissions[child.id]) {
					this.selectedValues[child.id] = this.selectedValues[action.id];
				}
			});
		}
		this.updateActions(action);
		this.onChange(this.userAllowedActions);
	}

	isPermissionDisabled(permission: MdeUserRoleAction): boolean {
		return this.disabledPermissions[permission.id] || this.isDisabled;
	}

	private disableMandatoryPermissions() {
		this.allowedActionsModel.forEach(action => {
			if (action.mandatory) {
				this.mandatoryPermissions[action.id] = true;
				this.disabledPermissions[action.id] = true;
				if (action.children) {
					const someChildrenAreSelected = action.children.some(
						child => this.selectedValues[child.id]
					);
					if (!someChildrenAreSelected) {
						action.children.forEach(child => (this.selectedValues[child.id] = true));
					}
				} else {
					this.selectedValues[action.id] = true;
				}
			}

			action.children &&
				action.children.forEach(child => {
					if (child.mandatory) {
						this.mandatoryPermissions[child.id] = true;
						this.disabledPermissions[child.id] = true;
						this.selectedValues[child.id] = true;
					}
					this.childToParentMap[child.id] = action;
				});
		});
	}

	private updateActions(action: MdeUserRoleAction) {
		this.disableRequiredPermissions(action);
		if (action.children) {
			action.children.forEach(child => this.disableRequiredPermissions(child));
		}
		this.enableNotRequiredPermissions();
		this.disableLastCheckedChildForMandatoryParent(action);
		this.updateParentsStatuses();
	}

	// Verify that required permissions are disabled.
	private disableRequiredPermissions(action: MdeUserRoleAction) {
		if (this.selectedValues[action.id]) {
			const additionalRequiredPermissions = action.additionalImplicitAllowedActions;
			for (const i in MdeUserRoleActionEnum) {
				if (Number(i) === MdeUserRoleActionEnum.admin) {
					continue;
				}
				if ((additionalRequiredPermissions & Number(i)) !== 0) {
					this.selectedValues[Number(i)] = true;
					this.disabledPermissions[Number(i)] = true;
				}
			}
		}
	}

	// Verify that not required permissions are enabled.
	private enableNotRequiredPermissions() {
		this.relevantActions
			.filter(action => this.disabledPermissions[action.id])
			.forEach(disabledPermission => {
				const isRequired = this.relevantActions
					.filter(action => this.selectedValues[action.id])
					.some(selectedPermission => {
						const additionalRequiredPermissions =
							selectedPermission.additionalImplicitAllowedActions;
						return (disabledPermission.id & additionalRequiredPermissions) !== 0;
					});
				if (!isRequired && !this.mandatoryPermissions[disabledPermission.id]) {
					this.disabledPermissions[disabledPermission.id] = false;
				}
			});
	}

	// Verify that mandatory parent has at least one checked child.
	private disableLastCheckedChildForMandatoryParent(action: MdeUserRoleAction) {
		const parent = this.childToParentMap[action.id];
		if (parent && parent.mandatory) {
			const checkedChild = parent.children.find(child => this.selectedValues[child.id]);
			const onlyOneChecked = parent.children
				.filter(child => child.id !== checkedChild.id)
				.every(child => !this.selectedValues[child.id]);
			if (onlyOneChecked) {
				this.disabledPermissions[checkedChild.id] = true;
			}
		}
	}

	// Verify that if parent is enabled - all childs are enabled.
	private updateChildrenStatuses() {
		this.allowedActionsModel.forEach(action => {
			if (action.children && this.selectedValues[action.id]) {
				action.children.forEach(child => (this.selectedValues[child.id] = true));
			}
		});
	}

	// Verify that parent is disabled only if all childs are disabled.
	// Verify that parent is selected if all childs are selected.
	// Verify that parent is partiallySelected if at least one child is selected.
	private updateParentsStatuses() {
		this.allowedActionsModel
			.filter(action => action.children)
			.forEach(parent => {
				const allSelected = parent.children.every(child => this.selectedValues[child.id]);
				const noneSelected = parent.children.every(child => !this.selectedValues[child.id]);
				const allDisabled = parent.children.every(child => this.disabledPermissions[child.id]);

				this.disabledPermissions[parent.id] = allDisabled;

				if (noneSelected) {
					this.selectedValues[parent.id] = false;
					this.partiallySelectedCategories[parent.id] = false;
				}
				if (allSelected) {
					this.selectedValues[parent.id] = true;
					this.partiallySelectedCategories[parent.id] = false;

					if (this.mandatoryPermissions[parent.id]) {
						this.disabledPermissions[parent.id] = true;
					}
				}
				if (!allSelected && !noneSelected) {
					this.selectedValues[parent.id] = false;
					this.partiallySelectedCategories[parent.id] = true;

					if (!this.mandatoryPermissions[parent.id]) {
						this.disabledPermissions[parent.id] = false;
					}
				}
			});
	}
}
