var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Machine } from '../machine/machine.entity';
import { AadUser } from '../AAD/user/aad.user.entity';
import { Mailbox } from '../mailbox/mailbox.entity';
import { Application } from '../application/application-entity';
import { MachineGroup } from '../rbac/machine-group.entity';
var ImpactedEntities = /** @class */ (function (_super) {
    __extends(ImpactedEntities, _super);
    function ImpactedEntities() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ arrayOf: Machine, data: 'Machines' }),
        __metadata("design:type", Array)
    ], ImpactedEntities.prototype, "machines", void 0);
    __decorate([
        EntityField({ arrayOf: MachineGroup, data: 'MachineGroups' }),
        __metadata("design:type", Array)
    ], ImpactedEntities.prototype, "machineGroups", void 0);
    __decorate([
        EntityField({ arrayOf: AadUser, data: 'Users' }),
        __metadata("design:type", Array)
    ], ImpactedEntities.prototype, "users", void 0);
    __decorate([
        EntityField({ arrayOf: Mailbox, data: 'Mailboxes' }),
        __metadata("design:type", Array)
    ], ImpactedEntities.prototype, "mailboxes", void 0);
    __decorate([
        EntityField({ arrayOf: Application, data: 'Apps' }),
        __metadata("design:type", Array)
    ], ImpactedEntities.prototype, "apps", void 0);
    __decorate([
        EntityField({ data: 'MailboxCount', defaultValue: null }),
        __metadata("design:type", Number)
    ], ImpactedEntities.prototype, "mailboxCount", void 0);
    __decorate([
        EntityField({ data: 'MachineCount', defaultValue: null }),
        __metadata("design:type", Number)
    ], ImpactedEntities.prototype, "machineCount", void 0);
    __decorate([
        EntityField({ data: 'MachineGroupsCount', defaultValue: null }),
        __metadata("design:type", Number)
    ], ImpactedEntities.prototype, "machineGroupsCount", void 0);
    __decorate([
        EntityField({ data: 'UserCount', defaultValue: null }),
        __metadata("design:type", Number)
    ], ImpactedEntities.prototype, "userCount", void 0);
    __decorate([
        EntityField({ data: 'AppCount', defaultValue: null }),
        __metadata("design:type", Number)
    ], ImpactedEntities.prototype, "appCount", void 0);
    ImpactedEntities = __decorate([
        ValueObject({
            singularName: 'Impacted Entity',
            pluralName: 'Impacted Entities',
            readonly: false,
        })
    ], ImpactedEntities);
    return ImpactedEntities;
}(ModelBase));
export { ImpactedEntities };
