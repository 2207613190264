var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase, ModelBase, ValueObject } from '@microsoft/paris';
import { HuntingTableColumnSchema } from './hunting-table-column-schema.value-object';
var KustoInputParameter = /** @class */ (function (_super) {
    __extends(KustoInputParameter, _super);
    function KustoInputParameter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    KustoInputParameter_1 = KustoInputParameter;
    Object.defineProperty(KustoInputParameter.prototype, "isTabularParameter", {
        get: function () {
            return !this.type;
        },
        enumerable: true,
        configurable: true
    });
    var KustoInputParameter_1;
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], KustoInputParameter.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'CslType' }),
        __metadata("design:type", String)
    ], KustoInputParameter.prototype, "type", void 0);
    __decorate([
        EntityField({ data: 'DefaultValue' }),
        __metadata("design:type", String)
    ], KustoInputParameter.prototype, "defaultValue", void 0);
    __decorate([
        EntityField({ data: 'Columns', arrayOf: KustoInputParameter_1 }),
        __metadata("design:type", Array)
    ], KustoInputParameter.prototype, "columns", void 0);
    KustoInputParameter = KustoInputParameter_1 = __decorate([
        ValueObject({
            pluralName: 'Input parameters of kusto function',
            singularName: 'Input parameter of kusto function',
            readonly: true,
        })
    ], KustoInputParameter);
    return KustoInputParameter;
}(ModelBase));
export { KustoInputParameter };
var ɵ0 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ1 = function (_a) {
    var id = _a.id;
    return (id ? 'PATCH' : 'POST');
}, ɵ2 = function (_a) {
    var IsShared = _a.IsShared, CreatedBy = _a.CreatedBy;
    return (IsShared ? 'shared' : CreatedBy ? 'user' : 'builtIn');
};
var HuntingFunctionSchema = /** @class */ (function (_super) {
    __extends(HuntingFunctionSchema, _super);
    function HuntingFunctionSchema() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", Number)
    ], HuntingFunctionSchema.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], HuntingFunctionSchema.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'Path', defaultValue: '' }),
        __metadata("design:type", String)
    ], HuntingFunctionSchema.prototype, "path", void 0);
    __decorate([
        EntityField({ data: 'Description' }),
        __metadata("design:type", String)
    ], HuntingFunctionSchema.prototype, "description", void 0);
    __decorate([
        EntityField({ data: 'InputParameters', arrayOf: KustoInputParameter, defaultValue: [] }),
        __metadata("design:type", Array)
    ], HuntingFunctionSchema.prototype, "inputParameters", void 0);
    __decorate([
        EntityField({ data: 'OutputSchemaStatements', serialize: false }),
        __metadata("design:type", String)
    ], HuntingFunctionSchema.prototype, "outputSchemaStatements", void 0);
    __decorate([
        EntityField({
            data: 'OutputColumns',
            arrayOf: HuntingTableColumnSchema,
            serialize: false,
        }),
        __metadata("design:type", Array)
    ], HuntingFunctionSchema.prototype, "outputColumns", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ2,
            serialize: false,
        }),
        __metadata("design:type", String)
    ], HuntingFunctionSchema.prototype, "kind", void 0);
    __decorate([
        EntityField({ data: 'IsShared' }),
        __metadata("design:type", Boolean)
    ], HuntingFunctionSchema.prototype, "isShared", void 0);
    __decorate([
        EntityField({ data: 'Body' }),
        __metadata("design:type", String)
    ], HuntingFunctionSchema.prototype, "body", void 0);
    HuntingFunctionSchema = __decorate([
        Entity({
            pluralName: 'User defined kusto functions',
            singularName: 'User defined kusto function',
            endpoint: 'savedFunctions',
            baseUrl: ɵ0,
            saveMethod: ɵ1,
            readonly: true,
        })
    ], HuntingFunctionSchema);
    return HuntingFunctionSchema;
}(EntityModelBase));
export { HuntingFunctionSchema };
export { ɵ0, ɵ1, ɵ2 };
