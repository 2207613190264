var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { HuntingRuleCustomSupportedActionType, CustomActionMachineIsolationType, EmailActionType, EmailSubmissionCategory, EmailSubmissionReason, EmailSubmissionObjectType, EmailSubmissionType, } from '../hunting-rule-custom-action.entity';
import { HuntingRuleEntityType } from '../hunting-rule-impacted-entity.value-object';
var HuntingEntityIdentifiers = /** @class */ (function (_super) {
    __extends(HuntingEntityIdentifiers, _super);
    function HuntingEntityIdentifiers() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'EntityType' }),
        __metadata("design:type", String)
    ], HuntingEntityIdentifiers.prototype, "entityType", void 0);
    __decorate([
        EntityField({ data: 'EntityIdField' }),
        __metadata("design:type", String)
    ], HuntingEntityIdentifiers.prototype, "entityIdField", void 0);
    __decorate([
        EntityField({ data: 'EntityNameField' }),
        __metadata("design:type", String)
    ], HuntingEntityIdentifiers.prototype, "entityNameField", void 0);
    HuntingEntityIdentifiers = __decorate([
        ValueObject({
            singularName: 'hunting custom action entity',
            pluralName: 'Hunting custom actions entities',
        })
    ], HuntingEntityIdentifiers);
    return HuntingEntityIdentifiers;
}(ModelBase));
export { HuntingEntityIdentifiers };
var HuntingCustomActionAdditionalFields = /** @class */ (function (_super) {
    __extends(HuntingCustomActionAdditionalFields, _super);
    function HuntingCustomActionAdditionalFields() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'RbacGroupIds' }),
        __metadata("design:type", Array)
    ], HuntingCustomActionAdditionalFields.prototype, "rbacGroupIds", void 0);
    __decorate([
        EntityField({ data: 'IsolationType' }),
        __metadata("design:type", Number)
    ], HuntingCustomActionAdditionalFields.prototype, "isolationType", void 0);
    __decorate([
        EntityField({ data: 'EmailActionType' }),
        __metadata("design:type", Number)
    ], HuntingCustomActionAdditionalFields.prototype, "emailActionType", void 0);
    HuntingCustomActionAdditionalFields = __decorate([
        ValueObject({
            singularName: 'hunting custom action additional fields',
            pluralName: 'hunting custom action additional fields',
        })
    ], HuntingCustomActionAdditionalFields);
    return HuntingCustomActionAdditionalFields;
}(ModelBase));
export { HuntingCustomActionAdditionalFields };
var ɵ0 = function (item) {
    return item && {
        RbacGroupIds: item.rbacGroupIds,
        IsolationType: item.isolationType,
        EmailActionType: item.emailActionType,
    };
};
var HuntingCustomAction = /** @class */ (function (_super) {
    __extends(HuntingCustomAction, _super);
    function HuntingCustomAction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'ActionType' }),
        __metadata("design:type", Number)
    ], HuntingCustomAction.prototype, "actionType", void 0);
    __decorate([
        EntityField({ data: 'Entities', arrayOf: HuntingEntityIdentifiers, defaultValue: [] }),
        __metadata("design:type", Array)
    ], HuntingCustomAction.prototype, "entities", void 0);
    __decorate([
        EntityField({
            data: 'AdditionalFields',
            serialize: ɵ0,
        }),
        __metadata("design:type", HuntingCustomActionAdditionalFields)
    ], HuntingCustomAction.prototype, "additionalFields", void 0);
    HuntingCustomAction = __decorate([
        ValueObject({
            singularName: 'hunting custom action',
            pluralName: 'Hunting custom actions',
        })
    ], HuntingCustomAction);
    return HuntingCustomAction;
}(ModelBase));
export { HuntingCustomAction };
var StartNewEmailReportSubmission = /** @class */ (function (_super) {
    __extends(StartNewEmailReportSubmission, _super);
    function StartNewEmailReportSubmission() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Category' }),
        __metadata("design:type", Number)
    ], StartNewEmailReportSubmission.prototype, "Category", void 0);
    __decorate([
        EntityField({ data: 'EmailType' }),
        __metadata("design:type", Number)
    ], StartNewEmailReportSubmission.prototype, "EmailType", void 0);
    __decorate([
        EntityField({ data: 'ObjectId' }),
        __metadata("design:type", String)
    ], StartNewEmailReportSubmission.prototype, "ObjectId", void 0);
    __decorate([
        EntityField({ data: 'Reason' }),
        __metadata("design:type", Number)
    ], StartNewEmailReportSubmission.prototype, "Reason", void 0);
    __decorate([
        EntityField({ data: 'Recipients', arrayOf: String, defaultValue: [] }),
        __metadata("design:type", Array)
    ], StartNewEmailReportSubmission.prototype, "Recipients", void 0);
    __decorate([
        EntityField({ data: 'Type' }),
        __metadata("design:type", Number)
    ], StartNewEmailReportSubmission.prototype, "Type", void 0);
    StartNewEmailReportSubmission = __decorate([
        ValueObject({
            singularName: 'start new email report submission',
            pluralName: 'start new email report submissions',
        })
    ], StartNewEmailReportSubmission);
    return StartNewEmailReportSubmission;
}(ModelBase));
export { StartNewEmailReportSubmission };
var TriggerInvestigationSubmission = /** @class */ (function (_super) {
    __extends(TriggerInvestigationSubmission, _super);
    function TriggerInvestigationSubmission() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'NetworkMessageId' }),
        __metadata("design:type", String)
    ], TriggerInvestigationSubmission.prototype, "NetworkMessageId", void 0);
    __decorate([
        EntityField({ data: 'Recipients' }),
        __metadata("design:type", Array)
    ], TriggerInvestigationSubmission.prototype, "Recipients", void 0);
    __decorate([
        EntityField({ data: 'Recipient' }),
        __metadata("design:type", String)
    ], TriggerInvestigationSubmission.prototype, "Recipient", void 0);
    TriggerInvestigationSubmission = __decorate([
        ValueObject({
            singularName: 'start trigger investigation',
            pluralName: 'start trigger investigations',
        })
    ], TriggerInvestigationSubmission);
    return TriggerInvestigationSubmission;
}(ModelBase));
export { TriggerInvestigationSubmission };
export { ɵ0 };
