import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { AlertIncidentGraphElement } from './incident-graph-element';

/**
 * old alert incident graph element. please use "cyber-event" model.
 * @deprecated
 */
@ValueObject({
	singularName: 'Alert Incident Graph',
	pluralName: 'Alert Incident Graphs',
})
export class AlertIncidentGraph extends ModelBase {
	@EntityField({ data: 'AlertId' })
	alertId: string;

	@EntityField({ data: 'UpdatedOn' })
	updated: Date;

	@EntityField({ data: 'Elements', arrayOf: AlertIncidentGraphElement })
	elements: Array<AlertIncidentGraphElement>;

	@EntityField({ data: '__self' })
	raw: any;
}
