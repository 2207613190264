import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'entity-details-section-header',
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
	template: `
		<div class="wcd-margin-small-bottom">
			<h4 class="wcd-inline">{{ titleKey | i18n }}</h4>
			<span *ngIf="subtitleKey" class="wcd-margin-xsmall-left ms-fontColor-neutralPrimaryAlt"
				>({{ subtitleKey | i18n }})</span
			>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityDetailsSectionHeaderComponent {
	@Input() titleKey: string;
	@Input() subtitleKey?: string;
}
