var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DataQuerySortDirection } from '@microsoft/paris';
import { AddMachineGroupsFilterQueryParam, Cache, DefaultApiVersion, TvmEndPoint, AddODataFilterQueryParam, } from './commonEndPointUtils';
import { ODataFilterQueryBuilder } from './oDataFilterQueryBuilder';
import { EolState } from './software-version/eol-version-state.enum';
import { PermissionRisk, VulnerabilityType } from '..';
var rangeRegex = /^(\d+):(\d+)$/;
var dayInMilliseconds = 86400000;
export var OsBuildNumber;
(function (OsBuildNumber) {
    OsBuildNumber["Future"] = "Future";
    OsBuildNumber["H121"] = "21H1";
    OsBuildNumber["H220"] = "20H2";
    OsBuildNumber["H120"] = "2004";
    OsBuildNumber["H219"] = "1909";
    OsBuildNumber["RS6"] = "1903";
    OsBuildNumber["RS5"] = "1809";
    OsBuildNumber["RS4"] = "1803";
    OsBuildNumber["RS3"] = "1709";
    OsBuildNumber["RS2"] = "1703";
    OsBuildNumber["RS1"] = "1607";
})(OsBuildNumber || (OsBuildNumber = {}));
export var AgeFilterOptions;
(function (AgeFilterOptions) {
    AgeFilterOptions["ZeroToThirty"] = "ZeroToThirty";
    AgeFilterOptions["ThirtyToSixty"] = "ThirtyToSixty";
    AgeFilterOptions["SixtyToNinety"] = "SixtyToNinety";
    AgeFilterOptions["NinetyOrMore"] = "NinetyOrMore";
})(AgeFilterOptions || (AgeFilterOptions = {}));
var ValidDatesFiltersOptions;
(function (ValidDatesFiltersOptions) {
    ValidDatesFiltersOptions["Expired"] = "Expired";
    ValidDatesFiltersOptions["EarlyIssueDate"] = "EarlyIssueDate";
    ValidDatesFiltersOptions["ValidDate"] = "ValidDate";
    ValidDatesFiltersOptions["ExpiringIn30Days"] = "ExpiringIn30Days";
    ValidDatesFiltersOptions["ExpiringIn60Days"] = "ExpiringIn60Days";
    ValidDatesFiltersOptions["ExpiringIn90Days"] = "ExpiringIn90Days";
})(ValidDatesFiltersOptions || (ValidDatesFiltersOptions = {}));
export var FilterDiscoveredVulnerabilities;
(function (FilterDiscoveredVulnerabilities) {
    FilterDiscoveredVulnerabilities["hasWeaknesses"] = "HasWeaknesses";
    FilterDiscoveredVulnerabilities["noWeaknesses"] = "NoWeaknesses";
    FilterDiscoveredVulnerabilities["notAvailable"] = "NotAvailable";
})(FilterDiscoveredVulnerabilities || (FilterDiscoveredVulnerabilities = {}));
export var FilterWeaknesses;
(function (FilterWeaknesses) {
    FilterWeaknesses["hasWeaknesses"] = "HasWeaknesses";
    FilterWeaknesses["noWeaknesses"] = "NoWeaknesses";
    FilterWeaknesses["notAvailable"] = "NotAvailable";
})(FilterWeaknesses || (FilterWeaknesses = {}));
export var FilterImpactedAssets;
(function (FilterImpactedAssets) {
    FilterImpactedAssets["hasEffect"] = "HasEffect";
    FilterImpactedAssets["noEffect"] = "NoEffect";
    FilterImpactedAssets["notAvailable"] = "NotAvailable";
})(FilterImpactedAssets || (FilterImpactedAssets = {}));
export var FILTER_TAG;
(function (FILTER_TAG) {
    FILTER_TAG["none"] = "None";
    FILTER_TAG["alreadyEOL"] = "AlreadyEOL";
    // upcomingEOL = 'UpcomingEOL', TODO: When adding filter on UpcomingEolSoftware - uncomment this line
    FILTER_TAG["networkGear"] = "NetworkGear";
    FILTER_TAG["hasEolVersions"] = "HasEolVersions";
    FILTER_TAG["hasUpcomingEolVersions"] = "HasUpcomingEolVersions";
    FILTER_TAG["zeroDay"] = "ZeroDay";
})(FILTER_TAG || (FILTER_TAG = {}));
export var VA_RECOMMENDATION_FILTER_TAG;
(function (VA_RECOMMENDATION_FILTER_TAG) {
    VA_RECOMMENDATION_FILTER_TAG["alreadyEOL"] = "AlreadyEOL";
    // upcomingEOL = 'UpcomingEOL', TODO: When adding filter on UpcomingEolSoftware - uncomment this line
    VA_RECOMMENDATION_FILTER_TAG["hasEolVersions"] = "HasEolVersions";
    VA_RECOMMENDATION_FILTER_TAG["hasUpcomingEolVersions"] = "HasUpcomingEolVersions";
    VA_RECOMMENDATION_FILTER_TAG["zeroDay"] = "ZeroDay";
})(VA_RECOMMENDATION_FILTER_TAG || (VA_RECOMMENDATION_FILTER_TAG = {}));
export var SCA_RECOMMENDATION_FILTER_TAG;
(function (SCA_RECOMMENDATION_FILTER_TAG) {
    SCA_RECOMMENDATION_FILTER_TAG["covid19"] = "covid19";
    SCA_RECOMMENDATION_FILTER_TAG["userImpactAssessment"] = "userImpactAssessment";
    SCA_RECOMMENDATION_FILTER_TAG["humanOperatedRansomware"] = "humanOperatedRansomware";
})(SCA_RECOMMENDATION_FILTER_TAG || (SCA_RECOMMENDATION_FILTER_TAG = {}));
export var VERSION_DISTRIBUTION_FILTER_TAG;
(function (VERSION_DISTRIBUTION_FILTER_TAG) {
    VERSION_DISTRIBUTION_FILTER_TAG["none"] = "None";
    VERSION_DISTRIBUTION_FILTER_TAG["alreadyEOL"] = "AlreadyEOL";
    VERSION_DISTRIBUTION_FILTER_TAG["upcomingEOL"] = "UpcomingEOL";
})(VERSION_DISTRIBUTION_FILTER_TAG || (VERSION_DISTRIBUTION_FILTER_TAG = {}));
export var SOFTWARE_MISSING_KBS_FILTER_TAG;
(function (SOFTWARE_MISSING_KBS_FILTER_TAG) {
    SOFTWARE_MISSING_KBS_FILTER_TAG["none"] = "None";
    SOFTWARE_MISSING_KBS_FILTER_TAG["alreadyEOL"] = "AlreadyEOL";
    SOFTWARE_MISSING_KBS_FILTER_TAG["upcomingEOL"] = "UpcomingEOL";
})(SOFTWARE_MISSING_KBS_FILTER_TAG || (SOFTWARE_MISSING_KBS_FILTER_TAG = {}));
export var ANALYTICS_FIELDS;
(function (ANALYTICS_FIELDS) {
    ANALYTICS_FIELDS["alreadyEOL"] = "isEol";
    // upcomingEOL = 'isUpcomingEol', TODO: When adding filter on UpcomingEolSoftware - uncomment this line
    ANALYTICS_FIELDS["eolSoftwareState"] = "eolSoftwareState";
    ANALYTICS_FIELDS["hasEolVersions"] = "hasEolVersions";
    ANALYTICS_FIELDS["hasUpcomingEolVersions"] = "hasUpcomingEolVersions";
    ANALYTICS_FIELDS["category"] = "category";
    ANALYTICS_FIELDS["application"] = "Application";
    ANALYTICS_FIELDS["recommendationCategory"] = "recommendationCategory";
    ANALYTICS_FIELDS["eolVersionState"] = "eolVersionState";
    ANALYTICS_FIELDS["mostSevereVulnerabilityType"] = "mostSevereVulnerabilityType";
    ANALYTICS_FIELDS["patchReleaseDate"] = "patchReleaseDate";
    ANALYTICS_FIELDS["osVersion"] = "osVersion";
})(ANALYTICS_FIELDS || (ANALYTICS_FIELDS = {}));
var getExploitsRoute = function (filterValues) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    var predicatePerFilterValue = {
        '0': 'ThreatInfo/IsThreatActive',
        '1': 'ThreatInfo/HasExploit',
        '2': 'ThreatInfo/IsExploitVerified',
        '3': 'ThreatInfo/IsInExploitKit',
    };
    filterValues.forEach(function (filterValue) {
        if (predicatePerFilterValue[filterValue]) {
            return queryBuilder.equals(predicatePerFilterValue[filterValue], true);
        }
        else {
            throw new Error("Invalid filter value");
        }
    });
    return queryBuilder.toQuery();
};
var ɵ0 = getExploitsRoute;
var ɵ1 = function (filterValue) {
    if (!filterValue)
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        switch (filterVal) {
            case FILTER_TAG.none:
                queryBuilder.and(function (qb) {
                    return qb
                        .equals(ANALYTICS_FIELDS.eolSoftwareState, EolState.NotEOL)
                        .equals(ANALYTICS_FIELDS.category, ANALYTICS_FIELDS.application)
                        .equals(ANALYTICS_FIELDS.hasEolVersions, false)
                        .equals(ANALYTICS_FIELDS.hasUpcomingEolVersions, false);
                });
                break;
            case FILTER_TAG.alreadyEOL:
                // case FILTER_TAG.upcomingEOL:  TODO: When adding filter on UpcomingEolSoftware - uncomment this line
                queryBuilder.equals(ANALYTICS_FIELDS.eolSoftwareState, filterVal);
                break;
            case FILTER_TAG.hasEolVersions:
                queryBuilder.equals(ANALYTICS_FIELDS.hasEolVersions, true);
                break;
            case FILTER_TAG.hasUpcomingEolVersions:
                queryBuilder.equals(ANALYTICS_FIELDS.hasUpcomingEolVersions, true);
                break;
            case FILTER_TAG.networkGear:
                queryBuilder.equals(ANALYTICS_FIELDS.category, FILTER_TAG.networkGear);
                break;
            case FILTER_TAG.zeroDay:
                queryBuilder.and(function (qb) {
                    return qb
                        .equals(ANALYTICS_FIELDS.mostSevereVulnerabilityType, VulnerabilityType.ZeroDay)
                        .equals(ANALYTICS_FIELDS.patchReleaseDate, null);
                });
                break;
        }
    });
    return queryBuilder.toQuery();
}, ɵ2 = function (filterValue) {
    if (!filterValue)
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        switch (filterVal) {
            case VA_RECOMMENDATION_FILTER_TAG.alreadyEOL:
                //case VA_RECOMMENDATION_FILTER_TAG.upcomingEOL:  TODO: When adding filter on UpcomingEolSoftware - uncomment this line
                queryBuilder.equals(ANALYTICS_FIELDS.eolSoftwareState, filterVal);
                break;
            case VA_RECOMMENDATION_FILTER_TAG.hasEolVersions:
                queryBuilder.equals(ANALYTICS_FIELDS.hasEolVersions, true);
                break;
            case VA_RECOMMENDATION_FILTER_TAG.hasUpcomingEolVersions:
                queryBuilder.equals(ANALYTICS_FIELDS.hasUpcomingEolVersions, true);
                break;
            case VA_RECOMMENDATION_FILTER_TAG.zeroDay:
                queryBuilder.and(function (qb) {
                    return qb
                        .equals(ANALYTICS_FIELDS.mostSevereVulnerabilityType, VulnerabilityType.ZeroDay)
                        .equals(ANALYTICS_FIELDS.patchReleaseDate, null);
                });
                break;
        }
    });
    return queryBuilder.toQuery();
}, ɵ3 = function (filterValue) {
    if (!filterValue)
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        switch (filterVal) {
            case VERSION_DISTRIBUTION_FILTER_TAG.none:
                queryBuilder.equals(ANALYTICS_FIELDS.eolVersionState, EolState.NotEOL);
                break;
            case VERSION_DISTRIBUTION_FILTER_TAG.alreadyEOL:
            case VERSION_DISTRIBUTION_FILTER_TAG.upcomingEOL:
                queryBuilder.equals(ANALYTICS_FIELDS.eolVersionState, filterVal);
                break;
        }
    });
    return queryBuilder.toQuery();
}, ɵ4 = function (filterValue) {
    if (!filterValue)
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        switch (filterVal) {
            case SOFTWARE_MISSING_KBS_FILTER_TAG.none:
                queryBuilder.equals(ANALYTICS_FIELDS.eolVersionState, EolState.NotEOL);
                break;
            case SOFTWARE_MISSING_KBS_FILTER_TAG.alreadyEOL:
            case SOFTWARE_MISSING_KBS_FILTER_TAG.upcomingEOL:
                queryBuilder.equals(ANALYTICS_FIELDS.eolVersionState, filterVal);
                break;
        }
    });
    return queryBuilder.toQuery();
}, ɵ5 = function (filterValue) {
    if (!filterValue)
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        queryBuilder.equals(ANALYTICS_FIELDS.osVersion, filterVal);
    });
    return queryBuilder.toQuery();
}, ɵ6 = function (filterValue) {
    if (!filterValue)
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        switch (filterVal) {
            case FilterDiscoveredVulnerabilities.hasWeaknesses:
                queryBuilder.greaterThan('discoveredVulnerabilities', 0);
                break;
            case FilterDiscoveredVulnerabilities.noWeaknesses:
                queryBuilder.and(function (qb) {
                    return qb.equals('discoveredVulnerabilities', 0).equals('productNeverMatched', false);
                });
                break;
            case FilterDiscoveredVulnerabilities.notAvailable:
                queryBuilder.and(function (qb) {
                    return qb.equals('productNeverMatched', true).equals('assetsStatistics/assetsAtRiskCount', 0);
                });
                break;
        }
    });
    return queryBuilder.toQuery();
}, ɵ7 = function (filterValue) {
    if (!filterValue)
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('and');
    filterValue.forEach(function (filterVal) {
        switch (filterVal) {
            case FilterDiscoveredVulnerabilities.hasWeaknesses:
                queryBuilder.greaterThan('weaknesses', 0).equals('productNeverMatched', false);
                break;
            case FilterDiscoveredVulnerabilities.noWeaknesses:
                queryBuilder.and(function (qb) { return qb.equals('weaknesses', 0).equals('productNeverMatched', false); });
                break;
            case FilterDiscoveredVulnerabilities.notAvailable:
                queryBuilder.and(function (qb) { return qb.equals('productNeverMatched', true); });
                break;
        }
    });
    return queryBuilder.toQuery();
}, ɵ8 = function (filterValues) {
    //if MDATP
    if (filterValues[0].includes(':')) {
        return filterValues.map(function (field) { return field.split(':').join(' '); }).join(' or ');
    }
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValues.forEach(function (filterValue) {
        //check if nested
        if (filterValue.includes('-')) {
            var _a = filterValue.split('-'), category_1 = _a[0], subCategory_1 = _a[1];
            queryBuilder.and(function (qb) {
                return qb.equals('recommendationCategory', category_1).equals('subCategory', subCategory_1);
            });
        }
        else {
            queryBuilder.equals('recommendationCategory', filterValue);
        }
    });
    return queryBuilder.toQuery();
}, ɵ9 = function (filterValue) {
    if (!(filterValue && filterValue[0]))
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        switch (filterVal) {
            case FilterImpactedAssets.hasEffect:
                queryBuilder.greaterThan('numOfImpactedAssets', 0);
                break;
            case FilterImpactedAssets.noEffect:
                queryBuilder.equals('numOfImpactedAssets', 0);
                break;
            case FilterImpactedAssets.notAvailable:
                queryBuilder.equals('numOfImpactedAssets', null);
                break;
        }
    });
    return queryBuilder.toQuery();
}, ɵ10 = function (filterValue) {
    if (!filterValue)
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        return queryBuilder.equals('isNormalized', filterVal === 'Available');
    });
    return queryBuilder.toQuery();
}, ɵ11 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('and');
    var vendorProduct = filterValue[0].split('-_-');
    queryBuilder.equals('vendor', vendorProduct[0]);
    queryBuilder.equals('productName', vendorProduct[1]);
    return queryBuilder.toQuery();
}, ɵ12 = function (filterValues) {
    var fieldName = 'publishedOn';
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValues.forEach(function (filterValue) {
        switch (filterValue) {
            case AgeFilterOptions.ZeroToThirty:
                queryBuilder.greaterThan(fieldName, getCurrentDateWithDaysAgo(30));
                break;
            case AgeFilterOptions.ThirtyToSixty:
                queryBuilder.and(function (qb) {
                    return qb
                        .lessThanOrEquals(fieldName, getCurrentDateWithDaysAgo(30))
                        .greaterThan(fieldName, getCurrentDateWithDaysAgo(60));
                });
                break;
            case AgeFilterOptions.SixtyToNinety:
                queryBuilder.and(function (qb) {
                    return qb
                        .lessThanOrEquals(fieldName, getCurrentDateWithDaysAgo(60))
                        .greaterThan(fieldName, getCurrentDateWithDaysAgo(90));
                });
                break;
            case AgeFilterOptions.NinetyOrMore:
                queryBuilder.lessThanOrEquals(fieldName, getCurrentDateWithDaysAgo(90));
                break;
        }
    });
    return queryBuilder.toQuery();
}, ɵ13 = function (filterValue) {
    if (!(filterValue && filterValue[0]))
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('or');
    var matchPercentStr = 'matchPercent';
    filterValue.forEach(function (filterVal) {
        if (filterVal === '\u2265 90%') {
            // \u2265 is unicode for >=
            queryBuilder.greaterThanOrEquals(matchPercentStr, 90);
        }
        if (filterVal === '\u2265 50%') {
            queryBuilder.greaterThanOrEquals(matchPercentStr, 50);
        }
        if (filterVal === '\u2265 70%') {
            queryBuilder.greaterThanOrEquals(matchPercentStr, 70);
        }
        if (filterVal === '\u2264 50%') {
            // \u2264 is unicode for <=
            queryBuilder.lessThanOrEquals(matchPercentStr, 50);
        }
    });
    return queryBuilder.toQuery();
}, ɵ14 = function (filterValue) {
    if (!filterValue)
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) { return queryBuilder.equals('isFoundByTvm', filterVal === 'True'); });
    return queryBuilder.toQuery();
}, ɵ15 = function (filterValue) {
    if (!filterValue)
        return null;
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        return queryBuilder.equals('isFoundByOther', filterVal === 'True');
    });
    return queryBuilder.toQuery();
}, ɵ16 = function (filterValue) {
    if (!filterValue)
        return null;
    var fieldName = 'date';
    var queryBuilder = new ODataFilterQueryBuilder();
    switch (filterValue) {
        case "day" /* day */:
            queryBuilder.greaterThanOrEquals(fieldName, getCurrentDateWithDaysAgo(1));
            break;
        case "week" /* week */:
            queryBuilder.greaterThanOrEquals(fieldName, getCurrentDateWithDaysAgo(7));
            break;
        case "month" /* month */:
            queryBuilder.greaterThanOrEquals(fieldName, getCurrentDateWithDaysAgo(30));
            break;
        case "3months" /* '3months' */:
            queryBuilder.greaterThanOrEquals(fieldName, getCurrentDateWithDaysAgo(90));
            break;
        default:
            //SCC workaround - DatetimeRangeSection format isn't editable
            if (filterValue.length === 2) {
                //adding one day to fit SCC DatetimeRangeSection to the previous date format
                var startDate = Date.parse(filterValue[0]) + dayInMilliseconds;
                var endDate = Date.parse(filterValue[1]) + dayInMilliseconds;
                filterValue = startDate + ":" + endDate;
            }
            // if range is 'custom', this will match X:Y (where X and Y are unix timestamps in milliseconds)
            var customRangeMatch = filterValue.match(rangeRegex);
            if (customRangeMatch)
                queryBuilder
                    .greaterThanOrEquals(fieldName, getDateFromUnixTimestamp(Number(customRangeMatch[1]) / 1000))
                    .lessThanOrEquals(fieldName, getDateFromUnixTimestamp(Number(customRangeMatch[2]) / 1000));
            else
                return null;
            break;
    }
    return queryBuilder.toQuery();
}, ɵ17 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) { return queryBuilder.equals('type', filterVal); });
    return queryBuilder.toQuery();
}, ɵ18 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder();
    var rangeMatch = filterValue[0].match(rangeRegex);
    if (!rangeMatch) {
        rangeMatch = ['dummy', filterValue[0], '100'];
    }
    var from = Number(rangeMatch[1]);
    var to = Number(rangeMatch[2]);
    return queryBuilder
        .greaterThanOrEquals('affectedAssetsPercent', from)
        .lessThanOrEquals('affectedAssetsPercent', to)
        .toQuery();
}, ɵ19 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder();
    return queryBuilder.contains('name', filterValue).toQuery();
}, ɵ20 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    var osPlatformQbs = filterValue.map(function (filterVal) {
        var splitted = filterVal.split('-_-');
        var qb = new ODataFilterQueryBuilder('and');
        qb.equals('OperatingSystem', splitted[0]);
        qb.equals('OperatingSystemVersion', splitted[1]);
        return qb;
    });
    osPlatformQbs.forEach(function (qb) { return queryBuilder.join(qb); });
    return queryBuilder.toQuery();
}, ɵ21 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder();
    var rangeMatch = filterValue[0].match(rangeRegex);
    var from = Number(rangeMatch[1]);
    var to = Number(rangeMatch[2]);
    return queryBuilder
        .greaterThanOrEquals('compliantConfigurationsPercent', from)
        .lessThanOrEquals('compliantConfigurationsPercent', to)
        .toQuery();
}, ɵ22 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) { return queryBuilder.equals('status', filterVal == 'true'); });
    return queryBuilder.toQuery();
}, ɵ23 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    var complianceLevelsQbs = filterValue.map(function (filterVal) {
        var qb = new ODataFilterQueryBuilder();
        return qb.any('complianceLevels', filterVal);
    });
    complianceLevelsQbs.forEach(function (qb) { return queryBuilder.join(qb); });
    return queryBuilder.toQuery();
}, ɵ24 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        return queryBuilder.equals('compliantConfigurations', parseInt(filterVal));
    });
    return queryBuilder.toQuery();
}, ɵ25 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        return queryBuilder.equals('compliantDevices', parseInt(filterVal));
    });
    return queryBuilder.toQuery();
}, ɵ26 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        return queryBuilder.equals('compliantDevices', parseInt(filterVal));
    });
    return queryBuilder.toQuery();
}, ɵ27 = function (filterValues) {
    var notAfterFieldName = 'notAfter';
    var notBeforeFieldName = 'notBefore';
    var queryBuilder = new ODataFilterQueryBuilder('or');
    var currentDate = getCurrentDateInMoreDays(0);
    filterValues.forEach(function (filterValue) {
        switch (filterValue) {
            case ValidDatesFiltersOptions.Expired:
                queryBuilder.lessThanOrEquals(notAfterFieldName, currentDate);
                break;
            case ValidDatesFiltersOptions.ExpiringIn30Days:
                queryBuilder.and(function (qb) {
                    return qb
                        .greaterThan(notAfterFieldName, currentDate)
                        .lessThan(notAfterFieldName, getCurrentDateInMoreDays(31));
                });
                break;
            case ValidDatesFiltersOptions.ExpiringIn60Days:
                queryBuilder.and(function (qb) {
                    return qb
                        .greaterThan(notAfterFieldName, currentDate)
                        .lessThan(notAfterFieldName, getCurrentDateInMoreDays(61));
                });
                break;
            case ValidDatesFiltersOptions.ExpiringIn90Days:
                queryBuilder.and(function (qb) {
                    return qb
                        .greaterThan(notAfterFieldName, currentDate)
                        .lessThan(notAfterFieldName, getCurrentDateInMoreDays(91));
                });
                break;
            case ValidDatesFiltersOptions.ValidDate:
                queryBuilder.and(function (qb) {
                    return qb
                        .greaterThan(notAfterFieldName, currentDate)
                        .lessThanOrEquals(notBeforeFieldName, currentDate);
                });
                break;
            case ValidDatesFiltersOptions.EarlyIssueDate:
                queryBuilder.greaterThan(notBeforeFieldName, currentDate);
                break;
        }
    });
    return queryBuilder.toQuery();
}, ɵ28 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) { return queryBuilder.equals('selfSigned', filterVal === 'true'); });
    return queryBuilder.toQuery();
}, ɵ29 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) { return queryBuilder.equals('keySize', parseInt(filterVal)); });
    return queryBuilder.toQuery();
}, ɵ30 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) { return queryBuilder.equals('signatureAlgorithm', filterVal); });
    return queryBuilder.toQuery();
}, ɵ31 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) { return queryBuilder.equals('targetSoftware', filterVal); });
    return queryBuilder.toQuery();
}, ɵ32 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) { return queryBuilder.any('keyUsage', filterVal); });
    return queryBuilder.toQuery();
}, ɵ33 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) { return queryBuilder.equals('osName', filterVal); });
    return queryBuilder.toQuery();
}, ɵ34 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        return queryBuilder.equals('aggregatePermissionsRiskValue', Object.values(PermissionRisk).indexOf(filterVal));
    });
    return queryBuilder.toQuery();
}, ɵ35 = function (filterValue) {
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filterValue.forEach(function (filterVal) {
        return queryBuilder.equals('permissionsRiskValue', Object.values(PermissionRisk).indexOf(filterVal));
    });
    return queryBuilder.toQuery();
};
/*
    register FieldFilter in the map in order to enable filtering the field.
    available values:
        true: filtering is enabled, and will use the common oDataFilterFormatter (see below) to format the field's filtering query param using standard oData syntax
        false: filtering is disabled for the field
        CB: provide a CB that returns a custom filtering query param. if null is returned, the field will not be filtered.
*/
// TODO: some of those filters have boolean values, it makes no sense to send a query like: "x eq true or x eq false", may affect performance
export var fieldFiltersMap = {
    threats: [
        {
            predicate: getExploitsRoute,
        },
        {
            context: 'vulnerability',
            predicate: getExploitsRoute,
        },
    ],
    filterTags: ɵ1,
    recommendationTags: ɵ2,
    versionDistributionTags: ɵ3,
    softwareMissingKbsTags: ɵ4,
    softwareMissingKbsOsVersion: ɵ5,
    discoveredVulnerabilities: ɵ6,
    weaknesses: ɵ7,
    relatedComponent: ɵ8,
    remediationType: true,
    status: true,
    numOfImpactedAssets: ɵ9,
    severity: true,
    isNormalized: ɵ10,
    productIdFilter: ɵ11,
    age: ɵ12,
    matchPercent: ɵ13,
    isFoundByTvm: ɵ14,
    isFoundByOther: ɵ15,
    date: ɵ16,
    eventType: ɵ17,
    assetsCount: ɵ18,
    name: ɵ19,
    deviceType: true,
    vendor: true,
    onboardingStatus: true,
    os: true,
    osPlatform: true,
    osDistribution: true,
    exposureLevel: true,
    baselineOsPlatform: ɵ20,
    compliantConfigurations: ɵ21,
    profileStatus: ɵ22,
    category: true,
    complianceLevels: ɵ23,
    deviceCompliant: ɵ24,
    configurationCompliant: ɵ25,
    compliantDevices: ɵ26,
    validDates: ɵ27,
    selfSigned: ɵ28,
    keySize: ɵ29,
    signatureAlgorithm: ɵ30,
    targetSoftwareFilter: ɵ31,
    keyUsage: ɵ32,
    osName: ɵ33,
    aggregatePermissionsRiskValue: ɵ34,
    permissionsRiskValue: ɵ35,
};
var getDateFromUnixTimestamp = function (timestamp) {
    return moment.unix(timestamp).toDate();
};
var ɵ36 = getDateFromUnixTimestamp;
var getCurrentDateWithDaysAgo = function (daysAgo, useFullDays) {
    if (useFullDays === void 0) { useFullDays = true; }
    var _moment = moment()
        .utc(false)
        .add(-daysAgo, 'days');
    if (useFullDays) {
        _moment.startOf('day');
    }
    return _moment.toDate();
};
var ɵ37 = getCurrentDateWithDaysAgo;
var getCurrentDateInMoreDays = function (moreDays, useFullDays) {
    if (useFullDays === void 0) { useFullDays = true; }
    return getCurrentDateWithDaysAgo(-moreDays, useFullDays);
};
var ɵ38 = getCurrentDateInMoreDays;
var fieldIdOrderMap = {
    impactInfo: ['exposureScoreImprovement', 'secureScoreImprovement'],
    age: ['publishedOn'],
    eventType: ['type'],
    exposedDevicesAfterException: ['assetsStatistics/assetsAtRiskCountAfterApplyingExceptions'],
    profileStatus: ['status'],
    compliantDevices: ['compliancePct', 'name'],
    deviceCompliant: ['compliantConfigurations'],
    configurationCompliant: ['compliantDevices'],
    baselineOsPlatform: ['operatingSystem', 'operatingSystemVersion'],
};
export var TvmAnalyticsBaseUrl = function (config) { return config.data.serviceUrls.tvm + "/" + TvmEndPoint.Analytics; };
export var SharedConfig = {
    cache: Cache,
    baseUrl: TvmAnalyticsBaseUrl,
    customHeaders: DefaultApiVersion,
};
var ɵ39 = function (input) {
    var params = {};
    if (input.hasOwnProperty('product') && input.hasOwnProperty('vendor')) {
        params['$filter'] = new ODataFilterQueryBuilder()
            .equals('ProductName', input.product)
            .equals('Vendor', input.vendor)
            .toQuery();
    }
    if (input.hasOwnProperty('profileId')) {
        params['profileId'] = input.profileId;
    }
    AddMachineGroupsFilterQueryParam(params);
    return {
        params: params,
    };
};
export var TvmAnalyticsApiCallSharedConfigurations = __assign({}, SharedConfig, { parseQuery: ɵ39 });
export var AddODataSearchQueryParam = function (dataQuery, queryParams) {
    if (dataQuery.where && dataQuery.where.search) {
        Object.assign(queryParams, { search: dataQuery.where.search });
    }
};
export var AddODataSortingQueryParam = function (dataQuery, queryParams) {
    if (dataQuery.sortBy && dataQuery.sortBy.length) {
        queryParams['$orderby'] = dataQuery.sortBy
            .map(function (field) { return ({
            direction: field.direction === DataQuerySortDirection.descending ? 'desc' : 'asc',
            fields: fieldIdOrderMap[field.field] || [field.field],
        }); })
            .map(function (obj) { return obj.fields.map(function (field) { return field + " " + obj.direction; }).join(','); })
            .join(',');
    }
};
var SUPPORTED_SCA_TAGS = [
    SCA_RECOMMENDATION_FILTER_TAG.covid19,
    SCA_RECOMMENDATION_FILTER_TAG.userImpactAssessment,
    SCA_RECOMMENDATION_FILTER_TAG.humanOperatedRansomware,
];
var AddScaTagsQueryParam = function (dataQuery, queryParams) {
    var filterTagsDataQueryKey = 'recommendationTags';
    if (!dataQuery || !dataQuery.where || !dataQuery.where[filterTagsDataQueryKey]) {
        return;
    }
    var filterTags = dataQuery.where[filterTagsDataQueryKey];
    var recommendationTags = [];
    filterTags.forEach(function (t) {
        // Not very nice code - will be removed when BE support for tags will align (preserving current behavior)
        // Tag should be either SCA and in supported array or one of the 3 currently supported VA tags
        if (t in SCA_RECOMMENDATION_FILTER_TAG && SUPPORTED_SCA_TAGS.includes(t)) {
            recommendationTags.push(t);
        }
        else {
            // Handling current supported VA tags
            switch (t) {
                case VA_RECOMMENDATION_FILTER_TAG.zeroDay:
                    recommendationTags.push(VulnerabilityType.ZeroDay);
                    break;
                case VA_RECOMMENDATION_FILTER_TAG.alreadyEOL:
                    recommendationTags.push(ANALYTICS_FIELDS.alreadyEOL);
                    break;
                /*
                TODO: When adding filter on upcomingEolSoftware - uncomment this line
                case VA_RECOMMENDATION_FILTER_TAG.upcomingEOL:
                    recommendationTags.push(ANALYTICS_FIELDS.upcomingEOL);
                    break;
                */
                case VA_RECOMMENDATION_FILTER_TAG.hasEolVersions:
                    recommendationTags.push(ANALYTICS_FIELDS.hasEolVersions);
                    break;
                case VA_RECOMMENDATION_FILTER_TAG.hasUpcomingEolVersions:
                    recommendationTags.push(ANALYTICS_FIELDS.hasUpcomingEolVersions);
                    break;
            }
        }
    });
    // Removing the used recommendations tags to stop the next flow of OData $filter param construction
    delete dataQuery.where[filterTagsDataQueryKey];
    queryParams['tags'] = recommendationTags.join(',');
};
var ɵ40 = AddScaTagsQueryParam;
export var ParseDataQuery = function (dataQuery, context, nonPagedQuery) {
    if (nonPagedQuery === void 0) { nonPagedQuery = false; }
    var params = {};
    AddMachineGroupsFilterQueryParam(params);
    if (!dataQuery)
        return params;
    if (dataQuery.pageSize !== undefined && !nonPagedQuery) {
        params.pageIndex = dataQuery.page || 1;
        params.pageSize = dataQuery.pageSize;
    }
    // a temp solution the call must be made before the OData filter query params since SCA tags are using the same
    // tags mechanism and this call will remove SCA tags from data query to not damage the next flow
    AddScaTagsQueryParam(dataQuery, params);
    AddODataSearchQueryParam(dataQuery, params);
    AddODataFilterQueryParam(dataQuery, params, fieldFiltersMap, context);
    AddODataSortingQueryParam(dataQuery, params);
    AddIdParam(dataQuery, params);
    return params;
};
export var AddIdParam = function (dataQuery, queryParams) {
    var idDataQueryKeys = [
        'productId',
        'recommendationId',
        'installationId',
        'extensionId',
        'evidenceId',
        'internalPathId',
    ];
    if (!dataQuery || !dataQuery.where || !idDataQueryKeys.some(function (idKey) { return dataQuery.where[idKey]; })) {
        return;
    }
    idDataQueryKeys.forEach(function (idKey) {
        if (dataQuery.where[idKey]) {
            queryParams[idKey] = dataQuery.where[idKey];
        }
    });
};
export var FilterByNameInsteadOfSearch = function (dataQuery) {
    if (dataQuery.where.search) {
        dataQuery.where['name'] = dataQuery.where.search;
        delete dataQuery.where.search;
    }
    return dataQuery;
};
var ɵ41 = function (dataQuery) { return ParseDataQuery(dataQuery); }, ɵ42 = function (response) {
    return {
        count: response.numOfResults,
        items: response.results,
        meta: response.meta,
    };
};
export var TvmAnalyticsSharedEntityConfigurations = __assign({}, SharedConfig, { parseDataQuery: ɵ41, parseDataSet: ɵ42 });
var ɵ43 = function (dataQuery) { return ParseDataQuery(dataQuery); };
export var TvmAnalyticsSharedEntityRelationshipConfigurations = __assign({}, SharedConfig, { parseDataQuery: ɵ43 });
/**
 * we are stopping manipulating display texts in our domain. use tvm-texts.service.ts instead.
 * @deprecated
 */
export var parseEntityName = function (rawName) {
    return rawName
        .split('_')
        .map(function (s) { return s.charAt(0).toUpperCase() + s.substring(1); })
        .join(' ')
        .replace(' For Mac', ' for Mac')
        .replace(' For Linux', ' for Linux'); // TODO: clean this up once we have OS Kind column (Task: 26044745)
};
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42, ɵ43 };
