// TODO(khaw): Remove as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { WeaknessSeverity } from '@wcd/domain';
import { NetworkDevicesConnectionState } from './network-devices-connection-state.enum';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { NetworkDeviceBase } from './network-device.base.entity';
var NetworkDevicesSummaryModel = /** @class */ (function () {
    function NetworkDevicesSummaryModel() {
    }
    return NetworkDevicesSummaryModel;
}());
export { NetworkDevicesSummaryModel };
var ɵ0 = function (status) { return NetworkDevicesConnectionState[status]; };
var NetworkDevice = /** @class */ (function (_super) {
    __extends(NetworkDevice, _super);
    function NetworkDevice() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", String)
    ], NetworkDevice.prototype, "id", void 0);
    __decorate([
        EntityField({
            required: false,
        }),
        __metadata("design:type", String)
    ], NetworkDevice.prototype, "deviceName", void 0);
    __decorate([
        EntityField({
            required: true,
            parse: ɵ0,
        }),
        __metadata("design:type", Number)
    ], NetworkDevice.prototype, "status", void 0);
    __decorate([
        EntityField({
            required: false,
        }),
        __metadata("design:type", String)
    ], NetworkDevice.prototype, "deviceType", void 0);
    __decorate([
        EntityField({
            required: false,
        }),
        __metadata("design:type", String)
    ], NetworkDevice.prototype, "vendor", void 0);
    __decorate([
        EntityField({
            required: false,
        }),
        __metadata("design:type", String)
    ], NetworkDevice.prototype, "osVersion", void 0);
    __decorate([
        EntityField({
            required: false,
        }),
        __metadata("design:type", String)
    ], NetworkDevice.prototype, "exposureLevel", void 0);
    __decorate([
        EntityField({
            required: false,
        }),
        __metadata("design:type", Number)
    ], NetworkDevice.prototype, "cvesCount", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", Date)
    ], NetworkDevice.prototype, "lastScanned", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", Date)
    ], NetworkDevice.prototype, "firstSeen", void 0);
    NetworkDevice = __decorate([
        Entity(__assign({}, TvmAnalyticsSharedEntityConfigurations, { singularName: 'Network', pluralName: 'Networks', endpoint: 'network-devices', readonly: true }))
    ], NetworkDevice);
    return NetworkDevice;
}(NetworkDeviceBase));
export { NetworkDevice };
export { ɵ0 };
