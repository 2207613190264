import { Paris } from '@microsoft/paris';
import { InvestigationPendingType, InvestigationStatus, Machine, Service } from '@wcd/domain';
import { DataviewField } from '@wcd/dataview';
import { Prettify } from '../../../utils/services/prettify.service';
import { AppConfigService } from '@wcd/app-config';
import { FilterValuesChecklistComponent, FilterValuesChecklistWithSearchComponent, FilterValuesDatePickerComponent, } from '@wcd/ng-filters';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { map } from 'rxjs/operators';
import { IconsService, WcdIconNames } from '@wcd/icons';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { InvestigationEntityTypeService } from './investigation.entity-type.service';
import { I18nService } from '@wcd/i18n';
var InvestigationsFields = /** @class */ (function () {
    function InvestigationsFields(paris, appConfigService, globalEntityTypesService, iconsService, investigationEntityTypeService, i18nService) {
        this.paris = paris;
        this.appConfigService = appConfigService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.iconsService = iconsService;
        this.investigationEntityTypeService = investigationEntityTypeService;
        this.i18nService = i18nService;
    }
    Object.defineProperty(InvestigationsFields.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'names',
                        name: this.i18nService.strings.investigations_fields_triggeringAlert,
                        enabledByDefault: true,
                        sort: { enabled: false },
                        className: EntityNameComponent.entityNameDefaultCssClass,
                        component: {
                            type: EntityNameComponent,
                            getProps: function (investigationInput) {
                                return {
                                    entity: investigationInput,
                                    entityTypeId: _this.investigationEntityTypeService.entityType.id,
                                };
                            },
                        },
                        fluidWidth: 0.6,
                    },
                    {
                        id: 'id',
                        name: this.i18nService.strings.investigations_fields_investigationId,
                        className: 'small-cell',
                        enabledByDefault: !this.appConfigService.isDemoTenant,
                    },
                    {
                        id: 'status',
                        name: this.i18nService.strings.investigations_fields_investigationStatus,
                        enabledByDefault: true,
                        className: 'auto-size nowrap',
                        getDisplay: function (investigation) {
                            return investigation.isPending ? investigation.pendingType.name : investigation.status.name;
                        },
                        icon: {
                            wcdIcon: function (investigation) {
                                return investigation.status.iconName &&
                                    WcdIconNames[_this.iconsService.getIcon(investigation.status.iconName).name];
                            },
                            className: function (investigation) {
                                return "color-text-" + investigation.status.className;
                            },
                        },
                        getImage: function (investigation) { return investigation.status.image; },
                        sort: { enabled: false },
                        filter: {
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    mapFilterValue: function (statusId) {
                                        var statusIdInt = parseInt(statusId, 10);
                                        if (isNaN(statusIdInt))
                                            return null;
                                        var status = _this.paris.getValue(InvestigationStatus, statusIdInt);
                                        if (status) {
                                            return {
                                                icon: {
                                                    wcdIconName: _this.iconsService.getIcon(status.iconName).name,
                                                    className: "color-text-" + status.className,
                                                },
                                                image: status.image,
                                                name: status.name,
                                                id: status.id,
                                            };
                                        }
                                        return null;
                                    },
                                },
                            },
                        },
                        custom: {
                            allowFilterValueTracking: true,
                        },
                    },
                    {
                        id: 'detection_system',
                        name: this.i18nService.strings.investigations_fields_detectionSource,
                        enabledByDefault: true,
                        className: 'nowrap',
                        getDisplay: function (investigation) { return investigation.detectionSourceName; },
                        filter: {
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    mapFilterValue: function (value) {
                                        var detectionSourceId = parseInt(value, 10);
                                        if (isNaN(detectionSourceId)) {
                                            return {
                                                id: value,
                                                name: 'Unknown',
                                            };
                                        }
                                        return _this.paris.getItemById(Service, detectionSourceId).pipe(map(function (detectionSource) {
                                            return detectionSource
                                                ? {
                                                    name: detectionSource.name,
                                                    id: detectionSource.id,
                                                }
                                                : {
                                                    id: value,
                                                    name: 'Unknown',
                                                };
                                        }));
                                    },
                                },
                            },
                        },
                        custom: {
                            allowFilterValueTracking: true,
                        },
                    },
                    {
                        id: 'tags',
                        name: this.i18nService.strings.investigations_fields_tags,
                        enabledByDefault: true,
                        listValues: {
                            path: 'tags',
                            field: {
                                id: 'tag',
                                name: 'Tag',
                                getDisplay: function (listItem) { return listItem.name; },
                                className: 'tag',
                            },
                        },
                    },
                    {
                        id: 'hosts',
                        name: this.i18nService.strings.investigations_fields_entities,
                        enabledByDefault: true,
                        className: 'nowrap wcd-text-overflow-medium',
                        getDisplay: function (investigation) {
                            return investigation.machines.length
                                ? investigation.machines.length === 1
                                    ? investigation.machines[0].name.toLowerCase()
                                    : _this.i18nService.get('investigations_fields_deviceCount', {
                                        deviceCount: investigation.machines.length,
                                    })
                                : null;
                        },
                        icon: {
                            fabricIcon: function (investigation) {
                                return investigation.machines.length
                                    ? _this.globalEntityTypesService.getEntityTypeIcon(Machine)
                                    : null;
                            },
                        },
                        getLink: function (investigation) {
                            if (investigation.machines.length === 1) {
                                var id = investigation.machines[0].machineId;
                                _this.globalEntityTypesService.getEntityLink(Machine, {
                                    name: id,
                                    senseMachineId: id,
                                });
                            }
                            return null;
                        },
                        filter: {
                            component: {
                                type: FilterValuesChecklistWithSearchComponent,
                                config: {
                                    mapFilterValue: function (machineName) {
                                        return (machineName && {
                                            id: machineName,
                                            name: machineName.split('.')[0],
                                        });
                                    },
                                },
                            },
                        },
                    },
                    {
                        id: 'start_date',
                        name: this.i18nService.strings.investigations_fields_startDate,
                        enabledByDefault: true,
                        className: 'nowrap',
                        sort: {
                            sortDescendingByDefault: true,
                        },
                        filter: {
                            component: {
                                type: FilterValuesDatePickerComponent,
                            },
                        },
                        component: {
                            type: TzDateComponent,
                            getProps: function (investigation) { return ({ date: investigation.startDate }); },
                        },
                    },
                    {
                        id: 'duration',
                        name: this.i18nService.strings.investigations_fields_duration,
                        enabledByDefault: true,
                        getDisplay: function (investigation) { return Prettify.prettyTime(investigation.duration); },
                        className: 'auto-size',
                    },
                    {
                        id: 'error',
                        name: this.i18nService.strings.investigations_fields_errorDescription,
                        enabledByDefault: false,
                        className: 'color-text-error',
                        getDisplay: function (investigation) { return investigation.errorDescription; },
                        sort: { enabled: false },
                    },
                    {
                        id: 'reasons',
                        name: this.i18nService.strings.investigations_fields_statusDetails,
                        enabledByDefault: false,
                        className: 'full-width-cell',
                        listValues: {
                            path: 'reasons',
                            withBullets: true,
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'canceledBy',
                        name: this.i18nService.strings.investigations_fields_canceledBy,
                        enabledByDefault: false,
                        getDisplay: function (investigation) {
                            return investigation.canceledByUser ? investigation.canceledByUser.name : null;
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'lastAction',
                        name: this.i18nService.strings.investigations_fields_lastAction,
                        enabledByDefault: false,
                        fluidWidth: 0.4,
                        getDisplay: function (investigation) {
                            return investigation.lastAction ? investigation.lastAction.details : null;
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'host_groups',
                        name: this.i18nService.strings.investigations_fields_deviceGroups,
                        enabledByDefault: false,
                        className: 'auto-size',
                        getDisplay: function (investigation) { return investigation.hostGroups.join(', '); },
                        sort: { enabled: false },
                    },
                    {
                        id: 'remediated_actions',
                        name: this.i18nService.strings.investigations_fields_remediationActions,
                        enabledByDefault: false,
                        getDisplay: function (investigation) {
                            return investigation.actionsSummary.remediated || '';
                        },
                        className: 'no-wrap auto-size',
                        getTooltip: function (investigation) {
                            return _this.i18nService.get('investigations_fields_remediationActionsTooltip', {
                                remediationActionCount: investigation.actionsSummary.remediated,
                                totalActionCount: investigation.actionsSummary.total,
                            });
                        },
                        getCssClass: function (investigation) {
                            return investigation.actionsSummary.remediated ? 'bold color-text-success' : null;
                        },
                        sort: {
                            sortDescendingByDefault: true,
                        },
                    },
                    {
                        id: 'pending_type',
                        name: this.i18nService.strings.investigations_fields_pendingType,
                        enabledByDefault: false,
                        getDisplay: function (investigation) {
                            return investigation.pendingType && investigation.pendingType.name;
                        },
                        className: 'no-wrap',
                        filter: {
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    mapFilterValue: function (pendingTypeId) {
                                        var pendingType = _this.paris.getValue(InvestigationPendingType, parseInt(pendingTypeId, 10));
                                        if (pendingType) {
                                            return {
                                                name: pendingType.name,
                                                id: pendingType.id,
                                            };
                                        }
                                        return {
                                            id: pendingTypeId,
                                            name: 'Unknown',
                                        };
                                    },
                                },
                            },
                        },
                    },
                    {
                        id: 'comments',
                        name: this.i18nService.strings.investigations_fields_comments,
                        enabledByDefault: false,
                        getDisplay: function (investigation) { return investigation.commentCount; },
                        filter: {
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    mapFilterValue: function (hasComments) { return ({
                                        name: hasComments
                                            ? _this.i18nService.strings.investigations_fields_comments_yes
                                            : _this.i18nService.strings.investigations_fields_comments_no,
                                        id: String(hasComments),
                                    }); },
                                },
                            },
                        },
                        sort: {
                            sortDescendingByDefault: true,
                        },
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return InvestigationsFields;
}());
export { InvestigationsFields };
