import { Injectable } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MachineGroup, WebContentFilteringPolicy } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { Feature, FeaturesService } from '@wcd/config';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { I18nService } from '@wcd/i18n';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { MachinesService } from '../../machines/services/machines.service';
import { WebContentFilteringPolicyEditComponent } from '../components/web-content-filtering-policy-edit.component';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { WebThreatProtectionReportService } from '../../../reporting/web-threat-protection/web-threat-protection-report.service';
import _ from 'lodash';

@Injectable()
export class WebContentFilteringPolicyEntityTypeService
	implements EntityTypeService<WebContentFilteringPolicy> {
	private rbacMachineGroupsEnabled: boolean;

	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private authService: AuthService,
		private machineService: MachinesService,
		private featureService: FeaturesService,
		private advancedFeaturesService: AdvancedFeaturesService,
		private webThreatProtectionService: WebThreatProtectionReportService
	) {
		this.rbacMachineGroupsEnabled = this.featureService.isEnabled(Feature.RbacMachineGroups);
	}

	readonly entityType: EntityType<WebContentFilteringPolicy> = {
		id: 'web-content-filtering-policy',
		entity: WebContentFilteringPolicy,
		loadFullEntityInPanel: false,
		getEntityName: (policy: WebContentFilteringPolicy) => {
			return policy.policyName;
		},
		singleEntityPanelComponentType: WebContentFilteringPolicyEditComponent,
		getActions: (
			policies: Array<WebContentFilteringPolicy>
		): Observable<ReadonlyArray<ItemActionModel>> => {
			return combineLatest(
				this.rbacMachineGroupsEnabled && this.machineService.getFullUserExposedMachineGroups(),
				this.advancedFeaturesService.getAdvancedFeaturesSettings()
			).pipe(
				switchMap(([machineGroups, advancedFeatures]) => {
					let actions: Array<ItemActionModelConfig> = [];

					/// can be not removable only if Rbac enabled and current user is not admin, otherwise delete option is available
					const isNotRemovable =
						this.rbacMachineGroupsEnabled &&
						!!!this.authService.currentUser.isMdeAdmin &&
						policies.some((policy: WebContentFilteringPolicy) => {
							// in case that current user not admin but selected one of the records that was added by admin, don't allow to delete
							const createdByAdmin = !policy.rbacGroupIds || policy.rbacGroupIds.length === 0;

							// or in case that one of the selected policies contains machine groups that user not exposed to
							return (
								createdByAdmin ||
								(policy.rbacGroupIds &&
									policy.rbacGroupIds.some((groupId: number) => {
										return (
											machineGroups.filter(
												(machineGroup: MachineGroup) => machineGroup.id === groupId
											).length === 0
										);
									}))
							);
						});

					if (isNotRemovable) {
						return of(actions.map(itemActionConfig => new ItemActionModel(itemActionConfig)));
					}

					// Do not allow delete when web content filtering is off
					actions = [...actions];
					if (advancedFeatures.webCategoriesEnabled) {
						actions.push({
							id: 'delete',
							nameKey: 'delete',
							icon: 'delete',
							method: (items: Array<WebContentFilteringPolicy>) => {
								return new Promise((resolve, reject) => {
									const itemName: string =
										items.length === 1
											? items[0].policyName
											: `${items.length} ${this.i18nService
													.get(
														'webContentFilteringPolicy.dataview.entity.names.pluralName'
													)
													.toLowerCase()}`;

									const repository: Repository<
										WebContentFilteringPolicy
									> = this.paris.getRepository(WebContentFilteringPolicy);

									const deleteRequestData = items.map((item: WebContentFilteringPolicy) => {
										return {
											PolicyName: item.policyName,
											IndicatorValueIdMappings: item.indicatorValueIdMappings,
										};
									});

									this.dialogsService
										.confirm({
											title: this.i18nService.get(
												'webContentFilteringPolicy.dataview.actions.delete.confirm.title',
												{ itemName: itemName }
											),
											text: this.i18nService.get(
												'webContentFilteringPolicy.dataview.actions.delete.confirm.text',
												{ itemName: itemName }
											),
											confirmText: this.i18nService.get(
												'webContentFilteringPolicy.dataview.actions.delete.confirm.confirmText'
											),
										})
										.then((e: ConfirmEvent) => {
											if (e.confirmed)
											{
												resolve(
													repository
														.remove(items, { data: deleteRequestData })
														.toPromise()
														.then(
															items => {
																this.dialogsService.showSnackbar({
																	text: this.i18nService.get(
																		'webContentFilteringPolicy.dataview.actions.delete.deleted',
																		{ itemName: itemName }
																	),
																	icon: 'delete',
																	iconClassName: 'color-text-error',
																});
															},
															error => {
																this.dialogsService.showError({
																	title: this.i18nService.get(
																		'webContentFilteringPolicy.dataview.actions.delete.errorMessage',
																		{ itemName: itemName }
																	),
																	data: error,
																});

																reject(error);
															}
														)
												);
											}
											else {
												resolve(e);
											}
										});
								});
							},
							closeOnAction: (data: any): boolean => {
								if (!_.isNil(data) && !_.isNil(data.confirmed) && data.confirmed == false) {
									return false;
								}
								return true;
							},
							rbac: [RbacMdeAllowedActions.securitySettings],
							rbacState: RbacControlState.hidden,
							refreshOnResolve: true,
						});
					}

					return of(actions.map(itemActionConfig => new ItemActionModel(itemActionConfig)));
				})
			);
		},
	};
}
