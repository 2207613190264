var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ValueObject, ModelBase } from '@microsoft/paris';
import { AirsEntityStatus } from './airs-entity-status.entity';
var reputationStatusMapping = {
    verified: 3,
    clean: 4,
    infected: 1,
    suspicious: 2,
    not_found: 5,
};
var ɵ0 = function (statusType) { return reputationStatusMapping[statusType]; }, ɵ1 = function (scanTimeInSeconds) { return scanTimeInSeconds && scanTimeInSeconds * 1000; };
var AirsEntityReportProvider = /** @class */ (function (_super) {
    __extends(AirsEntityReportProvider, _super);
    function AirsEntityReportProvider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AirsEntityReportProvider.prototype, "hasDetails", {
        get: function () {
            return this.link || this.detectedBy || this.categories || this.summary;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({
            data: 'status',
            parse: ɵ0,
        }),
        __metadata("design:type", AirsEntityStatus)
    ], AirsEntityReportProvider.prototype, "reputation", void 0);
    __decorate([
        EntityField({ data: 'source' }),
        __metadata("design:type", String)
    ], AirsEntityReportProvider.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AirsEntityReportProvider.prototype, "link", void 0);
    __decorate([
        EntityField({ data: 'private_id', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AirsEntityReportProvider.prototype, "isInternal", void 0);
    __decorate([
        EntityField({ data: 'detected_by' }),
        __metadata("design:type", String)
    ], AirsEntityReportProvider.prototype, "detectedBy", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AirsEntityReportProvider.prototype, "categories", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], AirsEntityReportProvider.prototype, "summary", void 0);
    __decorate([
        EntityField({
            data: 'scan_time',
            parse: ɵ1,
        }),
        __metadata("design:type", Date)
    ], AirsEntityReportProvider.prototype, "scanTime", void 0);
    AirsEntityReportProvider = __decorate([
        ValueObject({
            singularName: 'Entity Report Provider',
            pluralName: 'Entity Report Providers',
            readonly: true,
        })
    ], AirsEntityReportProvider);
    return AirsEntityReportProvider;
}(ModelBase));
export { AirsEntityReportProvider };
export { ɵ0, ɵ1 };
