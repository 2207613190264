var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-accessibility-label-for */
import { OnInit } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { AssessmentJobCompletedComponent } from './assessment-job-completed.component';
import { AssessmentJobService } from '../../services/assessment-job.service';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { of } from 'rxjs';
var AssessmentJobReviewStepComponent = /** @class */ (function (_super) {
    __extends(AssessmentJobReviewStepComponent, _super);
    function AssessmentJobReviewStepComponent(i18n, assessmentJobService, dialogsService) {
        var _this = _super.call(this) || this;
        _this.i18n = i18n;
        _this.assessmentJobService = assessmentJobService;
        _this.dialogsService = dialogsService;
        _this.MessageBarType = MessageBarType;
        _this.onNext = function () {
            var next = function () {
                _this.markWizardCompleted(AssessmentJobCompletedComponent);
            };
            var errorHandling = function (error) {
                _this.dialogsService.showError({
                    title: _this.i18n.get('tvm.authenticatedScan.creationPanel.creationErrorTitle'),
                    data: error,
                });
            };
            _this.assessmentJobService.saveItem(_this.data.assessmentJob, next, errorHandling);
            return of(true);
        };
        return _this;
    }
    AssessmentJobReviewStepComponent.prototype.ngOnInit = function () {
        this.setStepValidation(!this.data.readOnly);
        this.setOnNext(this.onNext);
    };
    AssessmentJobReviewStepComponent.prototype.navigateToJobStep = function () {
        if (this.assessmentJobService.isWindowsScanFeatureEnabled()) {
            this.goToStep(1);
        }
        else {
            this.goToStep(0);
        }
    };
    AssessmentJobReviewStepComponent.prototype.navigateToScanStep = function () {
        this.previousStep();
    };
    AssessmentJobReviewStepComponent.prototype.isAllSelected = function () {
        if (this.data.assessmentJob.target === this.data.assessmentJob.originalTargetRanges) {
            return true;
        }
        return (this.assessmentJobService.countIpAddresses(this.data.assessmentJob.originalTargetRanges) ===
            this.data.selectedIps.length);
    };
    return AssessmentJobReviewStepComponent;
}(WizardBaseStep));
export { AssessmentJobReviewStepComponent };
