/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "@angular/common";
import * as i4 from "./entity-details.section-header.component";
var styles_EntityDetailsSectionHeaderComponent = ["[_nghost-%COMP%] {\n\t\t\t\tdisplay: block;\n\t\t\t}"];
var RenderType_EntityDetailsSectionHeaderComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_EntityDetailsSectionHeaderComponent, data: {} });
export { RenderType_EntityDetailsSectionHeaderComponent as RenderType_EntityDetailsSectionHeaderComponent };
function View_EntityDetailsSectionHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "wcd-margin-xsmall-left ms-fontColor-neutralPrimaryAlt"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["(", ")"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.subtitleKey)); _ck(_v, 1, 0, currVal_0); }); }
export function View_EntityDetailsSectionHeaderComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "wcd-margin-small-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "h4", [["class", "wcd-inline"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityDetailsSectionHeaderComponent_1)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.subtitleKey; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), _co.titleKey)); _ck(_v, 5, 0, currVal_0); }); }
export function View_EntityDetailsSectionHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "entity-details-section-header", [], null, null, null, View_EntityDetailsSectionHeaderComponent_0, RenderType_EntityDetailsSectionHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i4.EntityDetailsSectionHeaderComponent, [], null, null)], null, null); }
var EntityDetailsSectionHeaderComponentNgFactory = i0.ɵccf("entity-details-section-header", i4.EntityDetailsSectionHeaderComponent, View_EntityDetailsSectionHeaderComponent_Host_0, { titleKey: "titleKey", subtitleKey: "subtitleKey" }, {}, []);
export { EntityDetailsSectionHeaderComponentNgFactory as EntityDetailsSectionHeaderComponentNgFactory };
