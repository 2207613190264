/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "./download-file.component";
import * as i4 from "../services/download.service";
var styles_DownloadFileComponent = [];
var RenderType_DownloadFileComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DownloadFileComponent, data: {} });
export { RenderType_DownloadFileComponent as RenderType_DownloadFileComponent };
export function View_DownloadFileComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "fab-action-button", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.downloadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabActionButtonComponent_0, i1.RenderType_FabActionButtonComponent)), i0.ɵdid(2, 6012928, null, 1, i2.FabActionButtonComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { disabled: [0, "disabled"], iconProps: [1, "iconProps"] }, { onClick: "onClick" }), i0.ɵqud(603979776, 1, { menuItemsDirectives: 1 }), i0.ɵpod(4, { iconName: 0 }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _ck(_v, 4, 0, "Download"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DownloadFileComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "download-file", [], null, null, null, View_DownloadFileComponent_0, RenderType_DownloadFileComponent)), i0.ɵdid(1, 49152, null, 0, i3.DownloadFileComponent, [i4.DownloadService], null, null)], null, null); }
var DownloadFileComponentNgFactory = i0.ɵccf("download-file", i3.DownloadFileComponent, View_DownloadFileComponent_Host_0, { filename: "filename", fileContent: "fileContent", downloadSettings: "downloadSettings", disabled: "disabled" }, { onDownload: "onDownload" }, []);
export { DownloadFileComponentNgFactory as DownloadFileComponentNgFactory };
