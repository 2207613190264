import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { IncidentGraphRelationType } from './incident-graph-relation-type.entity';

@ValueObject({
	singularName: 'Incident graph relation',
	pluralName: 'Incident graph relations',
	readonly: true,
})
export class IncidentGraphRelation extends ModelBase {
	@EntityField({ data: 'SourceNodeId' })
	sourceNodeId: string;

	@EntityField({ data: 'TargetNodeId' })
	targetNodeId: string;

	@EntityField({ data: 'RelationType' })
	relationType: IncidentGraphRelationType;
}
