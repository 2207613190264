import { AirsEntityType, } from '@wcd/domain';
export var DEVICE_ENTITY_TYPE_GO_HUNT_ID = -19;
export var HUNTING_ROUTE = 'hunting';
var GoHuntQueryBuilder = /** @class */ (function () {
    function GoHuntQueryBuilder() {
        var _a;
        this.convertMailboxEvidence = function (baseHuntingQueryRequest, mailbox, options) {
            if (!baseHuntingQueryRequest.incidentId) {
                // Mailbox go hunt is available only in context of incident
                return null;
            }
            return Object.assign(baseHuntingQueryRequest, {
                upn: mailbox.upn,
                mailboxPrimaryAddress: mailbox.mailboxPrimaryAddress,
            });
        };
        this.convertMachineEvidence = function (baseHuntingQueryRequest, machine, options) {
            return Object.assign(baseHuntingQueryRequest, {
                deviceId: machine.senseMachineId,
                deviceName: machine.name,
                evidenceTypeId: DEVICE_ENTITY_TYPE_GO_HUNT_ID,
            });
        };
        this.entityTypeIdToConversionMethod = (_a = {},
            _a[AirsEntityType.File] = this.convertFileEvidence,
            _a[AirsEntityType.MailMessage] = this.convertEmailEvidence,
            _a[AirsEntityType.SubmissionMail] = this.convertEmailEvidence,
            _a[AirsEntityType.Mailbox] = this.convertMailboxEvidence,
            _a[AirsEntityType.MailCluster] = this.convertEmailClusterEvidence,
            _a[DEVICE_ENTITY_TYPE_GO_HUNT_ID] = this.convertMachineEvidence,
            _a[AirsEntityType.User] = this.convertUserEvidence,
            _a[AirsEntityType.URL] = this.convertUrlEvidence,
            _a[AirsEntityType.IP] = this.convertIpEvidence,
            _a[AirsEntityType.Process] = this.convertProcessEvidence,
            _a);
    }
    GoHuntQueryBuilder.prototype.getAirsEntityHuntingRequest = function (entity, evidenceTypeId, options) {
        var conversionMethod = this.entityTypeIdToConversionMethod[evidenceTypeId.toString()];
        if (!conversionMethod) {
            // entity id is not supported
            return null;
        }
        var baseHuntingQueryRequest = {
            evidenceId: entity.id,
            evidenceTypeId: evidenceTypeId,
            actionTime: entity.firstSeen,
            incidentId: options && options.incident && options.incident.id,
            context: options && options.huntingContext,
        };
        return conversionMethod(baseHuntingQueryRequest, entity, options);
    };
    GoHuntQueryBuilder.prototype.convertFileEvidence = function (baseHuntingQueryRequest, entity, options) {
        return Object.assign(baseHuntingQueryRequest, {
            sha1: entity.sha1,
            sha256: entity.sha256,
            fileName: entity.name,
            machineId: entity.machine && entity.machine.id,
            actionTime: entity.createdTime,
        });
    };
    GoHuntQueryBuilder.prototype.convertEmailEvidence = function (baseHuntingQueryRequest, entity, options) {
        var receivedDate = entity.receivedDate;
        return Object.assign(baseHuntingQueryRequest, {
            senderEmailAddress: entity.sender,
            recipientEmailAddress: entity.recipient,
            networkMessageIds: [entity.networkMessageId],
            actionTime: receivedDate && receivedDate.toISOString(),
            emailSubject: entity.subject,
        });
    };
    GoHuntQueryBuilder.prototype.convertEmailClusterEvidence = function (baseHuntingQueryRequest, entity, options) {
        return Object.assign(baseHuntingQueryRequest, {
            networkMessageIds: entity.networkMessageIds,
        });
    };
    GoHuntQueryBuilder.prototype.convertUserEvidence = function (baseHuntingQueryRequest, entity, options) {
        if (!baseHuntingQueryRequest.incidentId) {
            // User go hunt is available only in context of incident
            return null;
        }
        return Object.assign(baseHuntingQueryRequest, {
            aadUserId: entity.aadUserId,
            accountSid: entity.sid,
            accountName: entity.accountName,
        });
    };
    GoHuntQueryBuilder.prototype.convertUrlEvidence = function (baseHuntingQueryRequest, entity, options) {
        return Object.assign(baseHuntingQueryRequest, {
            url: entity.address,
        });
    };
    GoHuntQueryBuilder.prototype.convertIpEvidence = function (baseHuntingQueryRequest, entity, options) {
        return Object.assign(baseHuntingQueryRequest, {
            ip: entity.address || entity.id,
        });
    };
    GoHuntQueryBuilder.prototype.convertProcessEvidence = function (baseHuntingQueryRequest, entity, options) {
        return Object.assign(baseHuntingQueryRequest, {
            actionTime: entity.created,
            processId: entity.processId,
            deviceId: entity.machine && entity.machine.machineId,
            deviceName: entity.machine && entity.machine.name,
            fileName: entity.name,
        });
    };
    return GoHuntQueryBuilder;
}());
export { GoHuntQueryBuilder };
