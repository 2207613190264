var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { Incident } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { WicdSanitizerService } from '@wcd/shared';
var MteAdvancedQueriesPanelComponent = /** @class */ (function (_super) {
    __extends(MteAdvancedQueriesPanelComponent, _super);
    function MteAdvancedQueriesPanelComponent(router, domSanitizer) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.domSanitizer = domSanitizer;
        _this.sanitize = function (text) { return _this.domSanitizer.sanitize(SecurityContext.HTML, text); };
        return _this;
    }
    return MteAdvancedQueriesPanelComponent;
}(PanelContainer));
export { MteAdvancedQueriesPanelComponent };
