var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { CapacityState } from './custom-ti-indicator-capacity-state.enum';
var ɵ0 = ['limit'], ɵ1 = ['total'];
var TiIndicatorsCapacity = /** @class */ (function (_super) {
    __extends(TiIndicatorsCapacity, _super);
    function TiIndicatorsCapacity(data) {
        var _this = _super.call(this, data) || this;
        _this.capacityAvailable = !!((_this.limit || _this.limit === 0) && (_this.total || _this.total === 0));
        if (!_this.capacityAvailable) {
            _this.capacityState = CapacityState.Unavailable;
            return _this;
        }
        // check if capacity was not exceeded
        _this.capacityAvailable = _this.total < _this.limit;
        _this.capacityState = _this.capacityAvailable ? CapacityState.Available : CapacityState.Exceeded;
        return _this;
    }
    __decorate([
        EntityField({ data: ɵ0 }),
        __metadata("design:type", Number)
    ], TiIndicatorsCapacity.prototype, "limit", void 0);
    __decorate([
        EntityField({ data: ɵ1 }),
        __metadata("design:type", Number)
    ], TiIndicatorsCapacity.prototype, "total", void 0);
    TiIndicatorsCapacity = __decorate([
        ValueObject({
            singularName: 'TI Indicators capacity',
            pluralName: 'TI Indicators capacities',
            readonly: true,
        }),
        __metadata("design:paramtypes", [Object])
    ], TiIndicatorsCapacity);
    return TiIndicatorsCapacity;
}(ModelBase));
export { TiIndicatorsCapacity };
export { ɵ0, ɵ1 };
