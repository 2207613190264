import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { LegacyUser } from '../legacy-user.entity';
import { AlertsSeveritySummary } from '../../../alert/alerts-severity-summary.value-object';
import { WcdPortalParisConfig } from '../../../paris-config.interface';

@EntityRelationship({
	sourceEntity: LegacyUser,
	dataEntity: AlertsSeveritySummary,
	allowedTypes: [RelationshipType.OneToOne],
	getRelationshipData: (user: LegacyUser) => ({
		AccountDomainName: user.accountDomainName,
		AccountName: user.accountName,
		AccountSid: user.sid,
	}),
	fixedData: {
		includeEventReferences: true,
		lookingBackInDays: 180,
	},
	endpoint: 'activeAlertsSummary',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.activeAlertsSummary,
})
export class LegacyUserAlertsSeveritySummaryRelationship
	implements EntityRelationshipRepositoryType<LegacyUser, AlertsSeveritySummary> {}
