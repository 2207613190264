var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { UserRoleEditComponent } from './user-role-edit.component';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { UserRole } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
var UserRoleEditPanelComponent = /** @class */ (function (_super) {
    __extends(UserRoleEditPanelComponent, _super);
    function UserRoleEditPanelComponent(router, dialogsService, i18nService, paris) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.dialogsService = dialogsService;
        _this.i18nService = i18nService;
        _this.paris = paris;
        _this.boundCanDeactivate = _this.canDeactivate.bind(_this);
        _this.userRolesRepo = _this.paris.getRepository(UserRole);
        return _this;
    }
    UserRoleEditPanelComponent.prototype.save = function ($event) {
        this.onDone($event);
        this.startClose();
    };
    UserRoleEditPanelComponent.prototype.canDeactivate = function () {
        if (this.userRoleEdit.isDirty) {
            return from(this.dialogsService.confirm({
                title: this.i18nService.get('discardChanges'),
                text: this.i18nService.get('discardMessage.singular', {
                    itemName: this.userRolesRepo.entity.singularName.toLowerCase(),
                }),
                confirmText: this.i18nService.get('buttons.discard'),
            })).pipe(map(function (e) { return e.confirmed; }));
        }
        else {
            return of(true);
        }
    };
    UserRoleEditPanelComponent.prototype.cancel = function () {
        var _this = this;
        this.canDeactivate().subscribe(function (_canDeactivate) {
            if (_canDeactivate)
                _this.closePanel();
        });
    };
    UserRoleEditPanelComponent.prototype.closePanel = function () {
        var _this = this;
        this.startClose();
        setTimeout(function () { return _this.destroy(); }, 200);
    };
    return UserRoleEditPanelComponent;
}(PanelContainer));
export { UserRoleEditPanelComponent };
