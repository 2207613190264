/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../global_entities/components/entity-details/software.entity-details.component.ngfactory";
import * as i2 from "../../../../global_entities/components/entity-details/software.entity-details.component";
import * as i3 from "../../../../global_entities/services/activated-entity.service";
import * as i4 from "../../../../tvm/services/tvm-texts.service";
import * as i5 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../../../../../../projects/config/src/lib/services/features.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "./software-inventory.entity-panel.component";
import * as i10 from "@microsoft/paris/dist/lib/paris";
var styles_SoftwareInventoryEntityPanelComponent = [];
var RenderType_SoftwareInventoryEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SoftwareInventoryEntityPanelComponent, data: {} });
export { RenderType_SoftwareInventoryEntityPanelComponent as RenderType_SoftwareInventoryEntityPanelComponent };
function View_SoftwareInventoryEntityPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "software-entity-details", [], null, null, null, i1.View_SoftwareEntityDetailsComponent_0, i1.RenderType_SoftwareEntityDetailsComponent)), i0.ɵdid(3, 245760, null, 0, i2.SoftwareEntityDetailsComponent, [i0.ChangeDetectorRef, i3.ActivatedEntity, i4.TvmTextsService, i5.I18nService, i6.FeaturesService, i7.Router, i0.Injector], { software: [0, "software"], installation: [1, "installation"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.software; var currVal_1 = _co.softwareInstallation; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_SoftwareInventoryEntityPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SoftwareInventoryEntityPanelComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.software; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SoftwareInventoryEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "software-inventory-entity-panel", [], null, null, null, View_SoftwareInventoryEntityPanelComponent_0, RenderType_SoftwareInventoryEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i9.SoftwareInventoryEntityPanelComponent, [i0.ChangeDetectorRef, i10.Paris], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SoftwareInventoryEntityPanelComponentNgFactory = i0.ɵccf("software-inventory-entity-panel", i9.SoftwareInventoryEntityPanelComponent, View_SoftwareInventoryEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { SoftwareInventoryEntityPanelComponentNgFactory as SoftwareInventoryEntityPanelComponentNgFactory };
