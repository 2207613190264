export enum HealthStatus {
	'Active' = 'Active',
	'Inactive' = 'Inactive',
	'ImpairedCommunication' = 'ImpairedCommunication',
	'NoSensorData' = 'NoSensorData',
}
export enum AvStatus {
	'Unknown' = 'Unknown',
	'Disabled' = 'Disabled',
	'NotUpdated' = 'NotUpdated',
	'Updated' = 'Updated',
}
export enum OsVersion {
	'RS1' = 'RS1',
	'RS2' = 'RS2',
	'RS3' = 'RS3',
	'RS4' = 'RS4',
	'RS5' = 'RS5',
	'RS6' = 'RS6',
	'RS7' = 'RS7',
	'RS8' = 'RS8',
	'RS9' = 'RS9',
	'RS10' = 'RS10',
	'RS11' = 'RS11',
	'Future' = 'Future',
}
export enum OsPlatform {
	'Windows11' = 'Windows11',
	'Windows10' = 'Windows10',
	'Windows8Blue' = 'Windows8Blue',
	'Windows8' = 'Windows8',
	'Windows7' = 'Windows7',
	'WindowsServer2022' = 'WindowsServer2022',
	'WindowsServer2019' = 'WindowsServer2019',
	'WindowsServer2016' = 'WindowsServer2016',
	'WindowsServer2012R2' = 'WindowsServer2012R2',
	'WindowsServer2008R2' = 'WindowsServer2008R2',
	'Windows10WVD' = 'Windows10WVD',
	'Linux' = 'Linux',
	'macOS' = 'macOS',
	'iOS' = 'iOS',
	'Android' = 'Android',
	'Other' = 'Other',
}
export type HealthStatusKeyType = keyof typeof HealthStatus;
export type AvStatusKeyType = keyof typeof AvStatus;
export type OsVersionKeyType = keyof typeof OsVersion;
export type OsPlatformKeyType = keyof typeof OsPlatform;
export type MachineReportDimensionKeyType =
	| HealthStatusKeyType
	| AvStatusKeyType
	| OsVersionKeyType
	| OsPlatformKeyType;
