/* tslint:disable:template-accessibility-label-for */
import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Output,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PositionModel } from '../../dialogs/models/position.model';
import { PositionService } from '../../dialogs/services/position.service';
import { Subscription } from 'rxjs';
import { getClientTimeZoneString, LocaleConfigService, TzDateService } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
declare const moment: typeof import('moment');

const DATEPICKER_CUSTOM_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DatePickerComponent),
	multi: true,
};

const ARIA_DATE_FORMAT_OPTIONS = {
	month: 'long',
	day: 'numeric',
	year: 'numeric',
};

const DATE_TIME_FORMAT_OPTIONS = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	second: 'numeric'
}

let lastId = 0;

@Component({
	selector: 'datepicker',
	templateUrl: './datepicker.component.html',
	providers: [DATEPICKER_CUSTOM_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./datepicker.component.scss'],
})
export class DatePickerComponent implements OnInit, ControlValueAccessor, OnDestroy {
	_value: Date = new Date();
	dialogPosition: PositionModel;
	timezoneLabel: string;
	timezoneChangeSubscription: Subscription;
	userChangedHoursValue: boolean;
	userChangedMinutesValue: boolean;
	private _dialogEl;

	get isUTCTimeZone(): boolean {
		return !this.localeConfigService.isLocalTimeZone;
	}

	setTime(hours?, minutes?, seconds?) {
		if (hours !== null && hours >= 0 && hours < 24) {
			this.selectedDate.setHours(hours);
			this.userChangedHoursValue = true;
		}
		if (minutes !== null && minutes >= 0 && minutes < 60) {
			this.selectedDate.setMinutes(minutes);
			this.userChangedMinutesValue = true;
		}
		if (seconds !== null && seconds >= 0 && seconds < 60) this.selectedDate.setSeconds(seconds);
	}

	isTimeValid(hours, minutes, seconds) {
		hours = parseInt(hours);
		minutes = parseInt(minutes);
		seconds = parseInt(seconds);
		return (

			(!isNaN(hours) &&
				hours < 24 &&
				hours >= 0 &&
				!isNaN(minutes) &&
				minutes < 60 &&
				minutes >= 0 &&
				!isNaN(seconds) &&
				seconds < 60 &&
				seconds >= 0)
		);
	}

	@Input() allowFutureDates: boolean = false;
	@Input() allowExactTimeSelection: boolean = false;
	@Input() earliestDateAllowed: Date = null;
	@Input() latestDateAllowed: Date = null;
	@Input() ariaLabel: string = null;
	@Input() rawDate: boolean;
	@Input() showInPanel: boolean = false;
	@Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>(false);

	get value(): Date {
		return this._value;
	}

	set value(v: Date) {
		this._value = v;
	}

	days: Array<string>;
	displayDate: Date;
	displayValue: string;
	dateAriaLabel: string;
	showDialog: boolean = false;
	selectedDate: Date;
	currentMonth: DatePickerMonth;
	datePickerId: string = `datepicker-${lastId++}`;

	constructor(
		private readonly el: ElementRef,
		private readonly ngZone: NgZone,
		private readonly tzDateService: TzDateService,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly localeConfigService: LocaleConfigService,
		private readonly i18nService: I18nService,
		private readonly liveAnnouncer: LiveAnnouncer,
	) {
		this.displayDate = new Date();
		this.setCurrentTimezoneLabel();
		this.timezoneChangeSubscription = this.localeConfigService.config$.subscribe(() => {
			this.setCurrentTimezoneLabel();
			this.changeDetector.markForCheck();
		});
	}

	ngOnInit() {
		const dialogWrapper = this.el.nativeElement.querySelector('.datepicker-wrapper');
		this._dialogEl = dialogWrapper.querySelector('.datepicker-content');
	}

	ngOnDestroy() {
		this.timezoneChangeSubscription && this.timezoneChangeSubscription.unsubscribe();
	}

	onChange = (_: any) => {};
	onTouched = () => {};

	writeValue(value: Date): void {
		this.ngZone.run(() => {
			// If UTC timezone is selected, we need to convert the date to UTC (and bypass the browser's 'new Date()' method that always creates dates in local timezone)
			const correctTimeZoneDate =
				value && this.isUTCTimeZone && !this.rawDate ? this.convertLocalToUTCDate(value) : value;
			this.setValue(correctTimeZoneDate);
		});
	}

	private convertLocalToUTCDate(date: Date): Date {
		return moment([
			date.getUTCFullYear(),
			date.getUTCMonth(),
			date.getUTCDate(),
			date.getHours(),
			date.getMinutes(),
			date.getSeconds(),
		]).toDate();
	}

	private convertUTCDateToLocal(date: Date): Date {
		return moment([
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			this.userChangedHoursValue ? date.getHours() : date.getUTCHours(),
			this.userChangedMinutesValue ? date.getMinutes() : date.getUTCMinutes(),
			date.getSeconds(),
		])
			.utcOffset(0, true)
			.toDate();
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setValue(value: Date) {
		this.value = value;
		if (value) {
			this.selectedDate = copyDate(value);
			if (this.rawDate) {
				const utcDateAsLocal = moment([
					value.getUTCFullYear(),
					value.getUTCMonth(),
					value.getUTCDate(),
					value.getUTCHours(),
					value.getUTCMinutes(),
					value.getUTCSeconds(),
				])
					.utcOffset(new Date().getTimezoneOffset() / 60, true)
					.toDate();
				this.updateDisplayValue(utcDateAsLocal);
				this.dateAriaLabel = new Intl.DateTimeFormat(
					this.localeConfigService.selectedLocale,
					ARIA_DATE_FORMAT_OPTIONS
				).format(utcDateAsLocal);
			} else {
				this.updateDisplayValue(this.selectedDate);
				this.dateAriaLabel = new Intl.DateTimeFormat(
					this.localeConfigService.selectedLocale,
					ARIA_DATE_FORMAT_OPTIONS
				).format(this.selectedDate);
			}
		} else {
			this.selectedDate = null;
			this.displayValue = '';
		}
		this.changeDetector.detectChanges();
	}

	private updateDisplayValue(date) {
		if (this.allowExactTimeSelection) {
			this.displayValue = new Intl.DateTimeFormat(this.localeConfigService.selectedLocale, DATE_TIME_FORMAT_OPTIONS).format(date);
		} else {
			this.displayValue = new Intl.DateTimeFormat(this.localeConfigService.selectedLocale).format(date);
		}
	}

	revert() {
		this.setDisplayDate(this.value || new Date());
		this.showDialog = false;
		this.selectedDate = copyDate(this.value);
		this.userChangedHoursValue = false;
		this.userChangedMinutesValue = false;

		this.changeDetector.detectChanges();
	}

	onWrapperClick($event) {
		if ($event.target.className === 'datepicker-wrapper') {
			this.revert();
			this.changeDetector.detectChanges();
		}
	}

	save() {
		this.showDialog = false;

		setTimeout(() => {
			if (!datesAreEqual(this.value, this.selectedDate)) {
				this.setValue(this.selectedDate);

				// If UTC timezone is selected, we need to convert the date to UTC (and bypass the browser's 'new Date()' method that always creates dates in local timezone)
				// It gets tricky if the user only changes one of the inputs, because we need to set the UTC time of the untouched input too
				const correctLocalTimezoneDate =
					this.selectedDate && this.isUTCTimeZone && !this.rawDate
						? this.convertUTCDateToLocal(this.selectedDate)
						: this.selectedDate;

				this.valueChange.emit(correctLocalTimezoneDate);
				this.onChange(correctLocalTimezoneDate);
				this.userChangedHoursValue = false;
				this.userChangedMinutesValue = false;

				this.changeDetector.detectChanges();
			}
		});
	}

	toggleDialog() {
		if (!this.showDialog) {
			this.ngZone.run(() => {
				this.setDisplayDate(this.value || new Date());

				setTimeout(() => {
					this.showDialog = true;
					if (this.showInPanel) {
						this.setDialogInPanelPosition();
					} else {
						this.setDialogPosition();
					}
					this._dialogEl.querySelector('h4').focus();
					this.changeDetector.detectChanges();
				}, 60);
			});
		}
		this.showDialog = false;
	}

	hideDialogEsc(event: KeyboardEvent){
		event.preventDefault();
		event.stopImmediatePropagation();
		event.stopPropagation();
		this.showDialog = false;
	}

	selectDate(date: Date, event: MouseEvent) {
		event && event.preventDefault();
		if (!this.isDayDisabled(date)) {
			const dateCopy = copyDate(date);
			dateCopy.setHours(this.selectedDate.getHours());
			dateCopy.setMinutes(this.selectedDate.getMinutes());
			dateCopy.setSeconds(this.selectedDate.getSeconds());
			this.selectedDate = dateCopy;

			this.changeDetector.detectChanges();
		}
	}

	setDisplayDate(date: Date) {
		this.displayDate = copyDate(date);
		this.currentMonth = this.getMonthData(date);
		this.days = this.getDays();
		this.liveAnnouncer.announce(this.currentMonth.name +' '+this.currentMonth.year,'assertive',300);
		this.changeDetector.detectChanges();
	}

	// If UTC timezone is selected, we're showing the user what he expects, while keeping the real date in local timezone, and parse it again before save
	// If the user manually changes value, we're showing him the value he inserted and we're setting it to the selectedDate object
	getDisplayHours(): number {
		return this.localeConfigService.isLocalTimeZone || this.userChangedHoursValue
			? this.selectedDate.getHours()
			: this.selectedDate.getUTCHours();
	}

	getDisplayMinutes(): number {
		return this.localeConfigService.isLocalTimeZone || this.userChangedMinutesValue
			? this.selectedDate.getMinutes()
			: this.selectedDate.getUTCMinutes();
	}

	setDialogInPanelPosition() {
		this.dialogPosition = new PositionModel(
			this.el.nativeElement.offsetTop + 40, // 32px is the input height inside panels, and 8px is the desired padding
			this.el.nativeElement.offsetLeft
		);
	}

	setDialogPosition() {
		const inputClientRect = this.el.nativeElement.getBoundingClientRect(),
			dialogClientRect = this._dialogEl.getBoundingClientRect();

		const inputPosition = new PositionModel(inputClientRect.bottom, inputClientRect.left);

		const positionFits = PositionService.fitsPosition(inputPosition, dialogClientRect, 16);
		this.dialogPosition = new PositionModel(
			positionFits.bottom ? inputPosition.top : inputClientRect.top - dialogClientRect.height,
			positionFits.right ? inputPosition.left : inputClientRect.right - dialogClientRect.width
		);
	}

	prevMonth() {
		this.displayDate.setMonth(this.displayDate.getMonth() - 1);
		this.setDisplayDate(this.displayDate);
	}

	nextMonth() {
		this.displayDate.setMonth(this.displayDate.getMonth() + 1);
		this.setDisplayDate(this.displayDate);
	}

	getMonthData(date: Date): DatePickerMonth {
		return new DatePickerMonth(date.getFullYear(), date.getMonth(), this.localeConfigService);
	}

	isDayDisabled(date: Date): boolean {
		if (this.earliestDateAllowed && date < this.earliestDateAllowed) return true;
		if (this.latestDateAllowed && date > this.latestDateAllowed) return true;
		if (this.allowFutureDates) return false;

		const tomorrow: Date = new Date();

		tomorrow.setDate(tomorrow.getDate() + 1);
		tomorrow.setHours(0);
		tomorrow.setMinutes(0);
		tomorrow.setSeconds(0);
		tomorrow.setMilliseconds(-1);

		return date > tomorrow;
	}

	private getDays() {
		// just the first sunday
		const sunday = new Date('1970-01-04T12:00:00.000Z');
		const days = [];
		for (let day = 0; day < 7; day++) {
			days.push(this.tzDateService.format(sunday, 'E', '+0000'));
			sunday.setDate(sunday.getDate() + 1);
		}
		return days;
	}

	private setCurrentTimezoneLabel() {
		this.timezoneLabel = this.localeConfigService.isLocalTimeZone
			? this.i18nService.get('datepicker_timezoneLocalUTC', {
					clientTimeZone: getClientTimeZoneString(),
			  })
			: this.i18nService.get('datepicker_timezoneUTC');
	}

	setDaysFocus(event: KeyboardEvent) {
		if (!this.currentMonth) {
			return;
		}
		this.currentMonth.weeks[0].days[0].isFocused = true;
	}

	setDayFocus(dayIndex: number, weekIndex: number, event: KeyboardEvent) {
		const currentWeek = this.currentMonth.weeks[weekIndex];
		const nextWeek = this.currentMonth.weeks[weekIndex + 1];
		const prevWeek = this.currentMonth.weeks[weekIndex - 1];
		let nextDay = currentWeek.days[dayIndex];
		let dayOffset = 0;
		currentWeek.days[dayIndex].isFocused = false;
		switch (event.key) {
			case 'Down': // IE/Edge specific value
			case 'ArrowDown':
				if(nextWeek && nextWeek.days[dayIndex]){
					dayOffset = Math.max(nextWeek.days.length - currentWeek.days.length, 0);
					nextDay = nextWeek.days[dayIndex + dayOffset];
				}
				else if(nextWeek)
					nextDay = nextWeek.days[nextWeek.days.length - 1];
				break;
			case 'Up': // IE/Edge specific value
			case 'ArrowUp':
				if(prevWeek){
					dayOffset = Math.max(currentWeek.days.length - prevWeek.days.length, 0);
					const nextDayIndex = dayIndex < dayOffset ? 0 : dayIndex - dayOffset;
					nextDay = prevWeek.days[nextDayIndex];
				}
				break;
			case 'Left': // IE/Edge specific value
			case 'ArrowLeft':
				if(currentWeek.days[dayIndex - 1])
					nextDay = currentWeek.days[dayIndex - 1];
				break;
			case 'Right': // IE/Edge specific value
			case 'ArrowRight':
				if(currentWeek.days[dayIndex + 1])
					nextDay = currentWeek.days[dayIndex + 1];
				break;
			case 'Esc': // IE/Edge specific value
			case 'Escape':
				this.showDialog = false;
				break;
		}
		nextDay.isFocused = true;
	}
}

class DatePickerMonth {
	weeks: Array<DatePickerWeek> = [];
	name: string;

	constructor(public year: number, public month: number, private localeConfigService: LocaleConfigService) {
		this.setWeeks();
		this.name = this.getMonthName();
	}

	private setWeeks(): void {
		const daysCount = this.getDaysCount();
		const firstDay = this.getFirstDay();
		const weeksCount = this.getWeeksCount();
		let firstWeekDay: number;
		let firstWeekDate: Date;

		for (let week = 0; week < weeksCount; week++) {
			firstWeekDay = week ? 0 : firstDay;
			firstWeekDate = new Date(this.year, this.month, week ? 7 - firstDay + 1 + (week - 1) * 7 : 1);

			this.weeks.push(new DatePickerWeek(firstWeekDate, firstWeekDay, daysCount));
		}
	}

	// http://stackoverflow.com/questions/1184334/get-number-days-in-a-specified-month-using-javascript
	private getDaysCount(): number {
		return new Date(this.year, this.month + 1, 0).getDate();
	}

	/**
	 * Returns the zero-based number of the day of the week on which the month begins. 0=Sunday, 1=Monday, etc.
	 */
	private getFirstDay(): number {
		return new Date(this.year, this.month, 1).getDay();
	}

	private getMonthName(): string {
		const date = new Date(this.year, this.month, 1);
		return date.toLocaleString(this.localeConfigService.selectedLocale, { month: 'long' });
	}

	private getWeeksCount() {
		const daysCount = this.getDaysCount(),
			firstDay = this.getFirstDay();

		return 1 + Math.ceil((daysCount - 7 + firstDay) / 7);
	}
}

class DatePickerWeek {
	days: Array<DatePickerDay> = [];
	padLeft: number = 0;
	padRight: number = 0;

	constructor(firstDate: Date, firstDay: number, lastDate: number) {
		const month = firstDate.getMonth();
		const year = firstDate.getFullYear();
		const lastDateDate = new Date(year, month, lastDate);
		const now = new Date();
		let foundToday =
			now.getFullYear() !== year ||
			now.getMonth() !== month ||
			now.getDate() < firstDate.getDate() ||
			now.getDate() > lastDate;

		this.padLeft = firstDay;
		this.padRight = 6 - lastDateDate.getDay();

		for (
			let day = firstDay, date = firstDate.getDate(), isToday: boolean, dayDate: Date;
			day < 7 && date <= lastDate;
			day++, date++
		) {
			dayDate = new Date(year, month, date);

			if (!foundToday) {
				if ((isToday = datesAreEqual(now, dayDate))) foundToday = true;
			} else isToday = false;

			this.days.push(new DatePickerDay(dayDate, isToday));
		}
	}
}

class DatePickerDay {
	date: Date;
	displayDate: number;
	isToday: boolean;
	isFocused: boolean = false;
	constructor(date: Date, isToday: boolean) {
		this.date = date;
		this.displayDate = date.getDate();
		this.isToday = isToday;
	}

	equals(otherDate: Date): boolean {
		if (!otherDate) return false;
		const compareTo = copyDate(otherDate);
		compareTo.setHours(0);
		compareTo.setMinutes(0);
		compareTo.setSeconds(0);

		return datesAreEqual(this.date, compareTo);
	}
}

function datesAreEqual(date1: Date, date2: Date): boolean {
	if (!date1 || !date2) return false;

	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate() &&
		date1.getHours() === date2.getHours() &&
		date1.getMinutes() === date2.getMinutes() &&
		date1.getSeconds() === date2.getSeconds()
	);
}

function copyDate(date: Date): Date {
	if (!date) return date;

	return new Date(date.valueOf());
}
