/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/pagination/src/lib/simple-pagination.component.ngfactory";
import * as i2 from "../../../../../../../projects/pagination/src/lib/simple-pagination.component";
import * as i3 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/common";
import * as i6 from "./dataview-action-pagination.component";
var styles_DataviewActionPaginationComponent = [];
var RenderType_DataviewActionPaginationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DataviewActionPaginationComponent, data: {} });
export { RenderType_DataviewActionPaginationComponent as RenderType_DataviewActionPaginationComponent };
function View_DataviewActionPaginationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "wcd-simple-pagination", [], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = (_co.onPageNumberChange($event.page) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SimplePaginationComponent_0, i1.RenderType_SimplePaginationComponent)), i0.ɵdid(1, 573440, null, 0, i2.SimplePaginationComponent, [i0.ChangeDetectorRef, i3.I18nService, i4.LiveAnnouncer], { page: [0, "page"], className: [1, "className"], pageSize: [2, "pageSize"], total: [3, "total"], currentPageCount: [4, "currentPageCount"], shouldShowPageNumbers: [5, "shouldShowPageNumbers"], showOnSinglePage: [6, "showOnSinglePage"] }, { pageChange: "pageChange" }), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.dataViewModel.page$)); var currVal_1 = "command-bar-item-pagination"; var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 3).transform(_co.dataViewModel.pageSize$)); var tmp_3_0 = null; var currVal_3 = (((tmp_3_0 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 4).transform(_co.dataViewModel.dataSet$))) == null) ? null : tmp_3_0.count); var tmp_4_0 = null; var currVal_4 = (((tmp_4_0 = i0.ɵunv(_v, 1, 4, i0.ɵnov(_v, 5).transform(_co.dataViewModel.dataSet$))) == null) ? null : ((tmp_4_0.items == null) ? null : tmp_4_0.items.length)); var currVal_5 = _co.shouldShowPageNumbers; var currVal_6 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_DataviewActionPaginationComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_DataviewActionPaginationComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.dataViewModel.dataSet$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DataviewActionPaginationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dataview-action-pagination", [], null, null, null, View_DataviewActionPaginationComponent_0, RenderType_DataviewActionPaginationComponent)), i0.ɵdid(1, 114688, null, 0, i6.DataviewActionPaginationComponent, [i3.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataviewActionPaginationComponentNgFactory = i0.ɵccf("dataview-action-pagination", i6.DataviewActionPaginationComponent, View_DataviewActionPaginationComponent_Host_0, { paginationActionConfig: "paginationActionConfig", dataViewModel: "dataViewModel", shouldShowPageNumbers: "shouldShowPageNumbers" }, {}, []);
export { DataviewActionPaginationComponentNgFactory as DataviewActionPaginationComponentNgFactory };
