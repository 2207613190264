import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { CustomTiIndicatorsTypeField } from './custom-ti-indicators-type-field.interface';
import { CustomTiIndicatorsTypes, customTiIndicatorsTypeValues } from './custom-ti-indicators-type.values';

@Entity({
	singularName: 'Custom TI indicators Type',
	pluralName: 'Custom TI indicators Types',
	values: customTiIndicatorsTypeValues,
})
export class CustomTiIndicatorsType extends EntityModelBase<CustomTiIndicatorsTypes> {
	@EntityField() name: string;

	@EntityField() singularName: string;

	@EntityField() displayName: string;

	@EntityField() helpKey: string;

	@EntityField()
	/**
	 * Represents the needed fields for a specific rule type
	 *
	 */
	properties: Array<CustomTiIndicatorsTypeField>;

	@EntityField() readonly: boolean;
}
