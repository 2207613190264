/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./machine-score.panel.component.ngfactory";
import * as i5 from "./machine-score.panel.component";
import * as i6 from "../../../dialogs/services/dialogs.service";
import * as i7 from "../services/machines.service";
import * as i8 from "@angular/router";
import * as i9 from "./machine-score.component";
var styles_MachineSecurityStateComponent = [];
var RenderType_MachineSecurityStateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MachineSecurityStateComponent, data: {} });
export { RenderType_MachineSecurityStateComponent as RenderType_MachineSecurityStateComponent };
export function View_MachineSecurityStateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 2, "machine-security-state-panel", [["class", "wcd-flex-1 wcd-flex-vertical wcd-full-height"]], null, null, null, i4.View_MachineSecurityStatePanelComponent_0, i4.RenderType_MachineSecurityStatePanelComponent)), i0.ɵdid(6, 245760, null, 0, i5.MachineSecurityStatePanelComponent, [i6.DialogsService, i7.MachinesService, i8.Router], { machine: [0, "machine"], machineSecurityAnalytics: [1, "machineSecurityAnalytics"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.machine; var currVal_2 = _co.machineSecurityAnalytics; _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_MachineSecurityStateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-machine-score", [], null, null, null, View_MachineSecurityStateComponent_0, RenderType_MachineSecurityStateComponent)), i0.ɵdid(1, 245760, null, 0, i9.MachineSecurityStateComponent, [i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MachineSecurityStateComponentNgFactory = i0.ɵccf("app-machine-score", i9.MachineSecurityStateComponent, View_MachineSecurityStateComponent_Host_0, { settings: "settings", machine: "machine", machineSecurityAnalytics: "machineSecurityAnalytics" }, {}, []);
export { MachineSecurityStateComponentNgFactory as MachineSecurityStateComponentNgFactory };
