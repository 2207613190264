var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
var ɵ0 = function (config, query) {
    return "machines/" + query.where['machineId'] + "/event/huntingQuery";
}, ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ2 = function (query) { return ({
    params: {
        machineId: query.machineId,
        actionTime: query.actionTimeIsoString,
        actionType: query.actionType,
    },
}); }, ɵ3 = function (data) { return ({
    allActivity: data['AllActivity'],
    encodedHuntingQuery: data.encodedHuntingQuery,
}); };
var CyberEventGoHuntQueryApiCall = /** @class */ (function (_super) {
    __extends(CyberEventGoHuntQueryApiCall, _super);
    function CyberEventGoHuntQueryApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CyberEventGoHuntQueryApiCall = __decorate([
        ApiCall({
            name: 'Get cyber event go hunt encoded query',
            endpoint: ɵ0,
            baseUrl: ɵ1,
            parseQuery: ɵ2,
            parseData: ɵ3,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
        })
    ], CyberEventGoHuntQueryApiCall);
    return CyberEventGoHuntQueryApiCall;
}(ApiCallModel));
export { CyberEventGoHuntQueryApiCall };
var ɵ4 = function (config, query) { return "goHunt/Timeline"; }, ɵ5 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ6 = function (query) { return ({
    data: __assign({}, query, { actionType: "", actionTime: query.actionTimeIsoString }),
}); }, ɵ7 = function (data) { return ({
    allActivity: data['AllActivity'],
    encodedHuntingQuery: data.encodedHuntingQuery,
}); };
var CyberEventGoHuntQueryApiCallVNext = /** @class */ (function (_super) {
    __extends(CyberEventGoHuntQueryApiCallVNext, _super);
    function CyberEventGoHuntQueryApiCallVNext() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CyberEventGoHuntQueryApiCallVNext = __decorate([
        ApiCall({
            name: 'Get cyber event go hunt encoded query',
            method: 'POST',
            endpoint: ɵ4,
            baseUrl: ɵ5,
            parseQuery: ɵ6,
            parseData: ɵ7,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
        })
    ], CyberEventGoHuntQueryApiCallVNext);
    return CyberEventGoHuntQueryApiCallVNext;
}(ApiCallModel));
export { CyberEventGoHuntQueryApiCallVNext };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
