var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DownloadService } from '../../shared/services/download.service';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CmsLinkedReportDownloadUrlApiCall, CmsLinkedReportUploadApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
export function getCmsLegacyUrlFromNewUrlRoute(route) {
    var entity = route.url[0].path;
    var id = route.url[1] && route.url[1].path;
    return ['cms', entity === 'actors' ? 'threatintel' : 'outbreak'].concat((id ? ['edit', id] : []));
}
export function getCmsNewUrlFromLegacyUrlState($state) {
    var isOutbreak = /outbreak/.test($state.current.name);
    return [
        'cms2',
        isOutbreak ? 'outbreaks' : 'actors'
    ].concat(($state.params && $state.params.id ? [$state.params.id] : []));
}
var CmsService = /** @class */ (function () {
    function CmsService(paris, downloadService) {
        this.paris = paris;
        this.downloadService = downloadService;
    }
    CmsService.prototype.createEntity = function (form) {
        return __assign({}, form.value, { updatedAt: undefined, sections: form.value.sections.map(function (section, index) { return (__assign({}, section, { content: section.content, order: index + 1, $parent: undefined })); }) });
    };
    CmsService.prototype.exportData = function (form) {
        var value = this.createEntity(form);
        var fileName = value.name + "_Overview.json";
        var blob = new Blob([JSON.stringify(value, null, '\t')], { type: 'application/json' });
        this.downloadService.download(of(blob), fileName);
    };
    // gets a link to private blob containing actor report and an error message to log if not succeeded.
    // address server to get link with access to the private blob and start download.
    CmsService.prototype.downloadReportUsingSasToken = function (linkToReport) {
        return this.paris.apiCall(CmsLinkedReportDownloadUrlApiCall, linkToReport).pipe(tap(function (link) {
            // start the download of the actor report.
            // will start the download and not open the file since the content type of this blobs is set to application/octet-stream.
            window.location.href = link;
        }));
    };
    CmsService.prototype.uploadReport = function (actorName, file) {
        return this.paris.apiCall(CmsLinkedReportUploadApiCall, { file: file, actorName: actorName });
    };
    return CmsService;
}());
export { CmsService };
