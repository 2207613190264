/* tslint:disable:template-accessibility-label-for */
import { EventEmitter } from '@angular/core';
import { SpinnerSize } from 'office-ui-fabric-react';
var KEY_CODES = {
    ENTER: 13,
    SPACE: 32,
};
var EntityPanelCollapsibleComponent = /** @class */ (function () {
    function EntityPanelCollapsibleComponent() {
        this.SpinnerSize = SpinnerSize;
        this.loaded = true;
        this.enableExport = true;
        this.export = new EventEmitter();
        this.onCollapseChange = new EventEmitter();
    }
    Object.defineProperty(EntityPanelCollapsibleComponent.prototype, "collapsed", {
        get: function () {
            return this._collapsed === undefined ? true : this._collapsed;
        },
        set: function (collapsed) {
            // Allow input to set only the initial state
            if (this._collapsed === undefined) {
                this._collapsed = collapsed;
            }
        },
        enumerable: true,
        configurable: true
    });
    EntityPanelCollapsibleComponent.prototype.toggleCollapsed = function (e) {
        if (!e || (e && (e.charCode === KEY_CODES.ENTER || e.charCode === KEY_CODES.SPACE))) {
            this._collapsed = !this.collapsed;
            this.onCollapseChange.emit(this._collapsed);
        }
    };
    return EntityPanelCollapsibleComponent;
}());
export { EntityPanelCollapsibleComponent };
