import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { CustomActionRun } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ScheduledHuntingActionEntityPanelComponent } from '../components/scheduled-hunting-action.entity-panel.component';

@Injectable()
export class ScheduledHuntingActionEntityTypeService implements EntityTypeService<CustomActionRun> {
	constructor(private i18nService: I18nService) {}

	readonly entityType: EntityType<CustomActionRun> = {
		id: 'custom-action-run',
		entity: CustomActionRun,
		singleEntityPanelComponentType: ScheduledHuntingActionEntityPanelComponent,
		getEntityName: (action: CustomActionRun) =>
			this.i18nService.get(`hunting.customDetections.actions.${action.actionType}`),
		getEntitiesLink: (actions: Array<CustomActionRun>) => actions.length > 0 ? `/custom_detection/${actions[0].ruleId}/actions` : null,
	};
}
