<div
	class="wcd-flex-horizontal
			element--border
			element--centered
			element
			selectable--inner
			clickable
			associated-alert--box"
	(click)="alertPageService.updateDetailsTab(TreeElementCyberEntityTypeEnum.alert, alertContext.cyberEntity, alertContext.alertId)"
	(keydown.enter)="alertPageService.updateDetailsTab(TreeElementCyberEntityTypeEnum.alert, alertContext.cyberEntity, alertContext.alertId)"
	[class.clickable]="alertContext.cyberEntity && alertContext.alertId"
	[class.selected]="alertPageService.selectedTreeElements[alertContext.alertId]"
	data-track-type="Button"
	data-track-id="alert-tree-element-tile"
	[attr.data-track-value]="alertContext.alertId"
	tabindex="0"
	role="listitem"
	[attr.aria-label]="('alert.accessibility.associated.alert.label' | i18n) + alertContext.title"
	[attr.aria-current]="alertPageService.selectedTreeElements[alertContext.alertId]"
	[attr.aria-description]="alertPageService.selectedTreeElements[alertContext.alertId] ? ('alert_accessibility_selected_element_description' | i18n) : null">
	<span class="wcd-flex-horizontal wcd-flex-1 wcd-padding-small-vertical">
		<fab-icon [iconName]="icon"></fab-icon>
		<span class="wcd-flex-1 associated-alert--box--title wcd-text-overflow-ellipsis-nowrap wcd-text-overflow-ellipsis"
				[wcdTooltip]="alertContext.title">
			{{ alertContext.title }}
		</span>
		<ng-container *ngIf="alertContext.cyberEntity != null">
			<ng-container *ngLet="toAlertEntity(alertContext.cyberEntity) as alertEntity">
				<span *ngIf="alertEntity.severity && alertEntity.severity.type"
						class="wcd-severity wcd-padding-mediumSmall-horizontal associated-alert--align-center"
						[ngClass]="'wcd-severity-' + alertEntity.severity.type">
					{{ alertEntity.severity.nameI18nKey | i18n }}
				</span>
				<span class="wcd-padding-mediumSmall-right" *ngIf="alertEntity.status != null">
					<wcd-shared-icon
						[iconName]="alertEntity.status.type | alertStatusIcon"
						[ngClass]="'status-icon--alert--' + alertEntity.status.type"
						class="status-icon"></wcd-shared-icon>
					{{ alertEntity.status.nameI18nKey | i18n }}
					<span *ngIf="alertEntity.classification != null">
						({{ alertEntity.classification.nameI18nKey | i18n }})
					</span>
				</span>
				<span class="wcd-padding-mediumSmall-right" *ngIf="(alertEntity.detectionStatus | detectionStatusDisplay) != null">
					<wcd-shared-icon
						[iconName]="alertEntity.detectionStatus | detectionStatusIcon"
						[ngClass]="'status-icon--detection--' + (alertEntity.detectionStatus | detectionStatusDisplay)"
						class="status-icon"></wcd-shared-icon>
					{{ alertEntity.detectionStatus | detectionStatusI18nName | i18n }}
				</span>
			</ng-container>
		</ng-container>
	</span>

	<!-- Alert actions -->
	<entity-command-bar
	*ngIf="alertContext.cyberEntity"
	[entityType]="alertType"
	[entity]="alertContext.cyberEntity"
	[entities]="[alertContext.cyberEntity]"
	[overFlowActionsView]="true"
	[options]="{ hideOpenLink: alertContext.cyberEntity.id == (mainAlertPageId$ | async) }">
	</entity-command-bar>
</div>
