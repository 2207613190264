import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { TiIndicatorsCapacity } from './custom-ti-indicators-capacity-result.value.object';

@ApiCall({
	name: 'Custom TI indicator capacity',
	endpoint: 'ti/count',
	method: 'GET',
	type: TiIndicatorsCapacity,
	baseUrl: config => config.data.serviceUrls.userRequests,
})
export class CustomTiIndicatorsCapacityApiCall extends ApiCallModel<TiIndicatorsCapacity> {}
