var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Entity } from '@microsoft/paris';
import { airsEntityStatusValues } from './airs-entity-status.entity';
import { AirsEntityStatus } from './airs-entity-status.entity';
import { RemediationStatusId, RemediationStatusType } from './airs-entity-status-types.enum';
var ɵ7 = function (s) { return !s.isVerdict; }, ɵ0 = RemediationStatusId.Failed, ɵ1 = RemediationStatusId.Prevented, ɵ2 = RemediationStatusId.PendingApproval, ɵ3 = RemediationStatusId.Declined, ɵ4 = RemediationStatusId.Running, ɵ5 = RemediationStatusId.Active, ɵ6 = RemediationStatusId.Blocked;
export var airsEntityRemediationStatuses = airsEntityStatusValues
    .filter(ɵ7)
    .concat([
    {
        id: ɵ0,
        name: 'Failed',
        helpKey: null,
        type: RemediationStatusType.Failed,
        className: 'error',
        paletteColor: 'redDark',
        priority: 1,
        isImportant: true,
        iconName: 'malware',
        isMalicious: true,
        isVerdict: false,
    },
    {
        id: ɵ1,
        name: 'Prevented',
        helpKey: null,
        type: RemediationStatusType.Prevented,
        className: 'success',
        paletteColor: 'green',
        priority: 1,
        isImportant: true,
        isVerdict: false,
    },
    {
        id: ɵ2,
        name: 'Pending approval',
        helpKey: null,
        type: RemediationStatusType.PendingApproval,
        className: 'warning',
        paletteColor: 'yellow',
        priority: 0,
        isImportant: true,
        isVerdict: false,
    },
    {
        id: ɵ3,
        name: 'Declined',
        helpKey: null,
        type: RemediationStatusType.Declined,
        className: 'warning-dark',
        paletteColor: 'orangeLighter',
        priority: 1,
        isImportant: true,
        isVerdict: false,
    },
    {
        id: ɵ4,
        name: 'Running',
        helpKey: null,
        type: RemediationStatusType.Running,
        className: 'primary',
        paletteColor: 'themePrimary',
        priority: 1,
        isImportant: true,
        isVerdict: false,
    },
    {
        id: ɵ5,
        name: 'Active',
        helpKey: null,
        type: RemediationStatusType.Active,
        className: 'error',
        paletteColor: 'redDark',
        priority: 1,
        isImportant: true,
        iconName: 'malware',
        isMalicious: true,
        isVerdict: false,
    },
    {
        id: ɵ6,
        name: 'Blocked',
        helpKey: null,
        type: RemediationStatusType.Blocked,
        className: 'success',
        paletteColor: 'green',
        priority: 1,
        isImportant: true,
        isVerdict: false,
    },
]);
var AirsEntityRemediationStatus = /** @class */ (function (_super) {
    __extends(AirsEntityRemediationStatus, _super);
    function AirsEntityRemediationStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AirsEntityRemediationStatus = __decorate([
        Entity({
            singularName: 'Entity Remediation Status',
            pluralName: 'Entity Remediation Statuses',
            values: airsEntityRemediationStatuses,
        })
    ], AirsEntityRemediationStatus);
    return AirsEntityRemediationStatus;
}(AirsEntityStatus));
export { AirsEntityRemediationStatus };
export { ɵ7, ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
