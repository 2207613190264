var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AppContextService } from '@wcd/config';
import { StoreStorageService } from './store-storage.abstract.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../config/src/lib/services/app-context.service";
/*
    Facade for sessionstorage
 */
var SessionStorageService = /** @class */ (function (_super) {
    __extends(SessionStorageService, _super);
    function SessionStorageService(appContext) {
        return _super.call(this, appContext, sessionStorage) || this;
    }
    SessionStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionStorageService_Factory() { return new SessionStorageService(i0.ɵɵinject(i1.AppContextService)); }, token: SessionStorageService, providedIn: "root" });
    return SessionStorageService;
}(StoreStorageService));
export { SessionStorageService };
