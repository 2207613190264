var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityReport } from './airs-entity-report.value-object';
import { SystemExclusionListType } from '../system_exclusions/system-exclusion-list-type.entity';
var MemoryContents = /** @class */ (function (_super) {
    __extends(MemoryContents, _super);
    function MemoryContents() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MemoryContents.prototype, "status", {
        get: function () {
            return this.report && this.report.status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MemoryContents.prototype, "addressHex", {
        get: function () {
            return this.address ? '0x' + this.address.toString(16) : '';
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], MemoryContents.prototype, "id", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], MemoryContents.prototype, "address", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], MemoryContents.prototype, "size", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], MemoryContents.prototype, "detector", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", AirsEntityReport)
    ], MemoryContents.prototype, "report", void 0);
    __decorate([
        EntityField({ data: 'acl_type' }),
        __metadata("design:type", SystemExclusionListType)
    ], MemoryContents.prototype, "systemExclusionRuleListType", void 0);
    __decorate([
        EntityField({ data: 'allow_create_acl', defaultValue: true }),
        __metadata("design:type", Boolean)
    ], MemoryContents.prototype, "allowCreateSystemExclusion", void 0);
    MemoryContents = __decorate([
        ValueObject({
            singularName: 'Memory Contents',
            pluralName: 'Memory Contents',
            readonly: true,
        })
    ], MemoryContents);
    return MemoryContents;
}(ModelBase));
export { MemoryContents };
