import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../../shared/shared.module';
import { RemediationTaskEntityTypeService } from './services/remediation-task.entity-type.service';
import { RemediationTaskEntityPanelComponent } from './components/remediation-task.entity-panel.component';
import { DataViewsModule } from '../../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import { RemediationTasksFieldsService } from './services/remediation-tasks.fields.service';
import { TvmModule } from '../../../../tvm/tvm.module';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule, TvmModule],
	declarations: [RemediationTaskEntityPanelComponent],
	exports: [RemediationTaskEntityPanelComponent],
	entryComponents: [RemediationTaskEntityPanelComponent],
	providers: [RemediationTasksFieldsService, RemediationTaskEntityTypeService],
})
export class RemediationTaskEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(RemediationTaskEntityTypeService);
	}
}
