/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../global_entities/components/entity-details/network-device.entity-details.component.ngfactory";
import * as i2 from "../../../../global_entities/components/entity-details/network-device.entity-details.component";
import * as i3 from "@microsoft/paris/dist/lib/paris";
import * as i4 from "@angular/router";
import * as i5 from "../../../../tvm/services/tvm-download.service";
import * as i6 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../common/side-panel.service";
import * as i8 from "../../security-recommendations/services/security-recommendation.fields.service";
import * as i9 from "../../../../tvm/services/tvm-texts.service";
import * as i10 from "./network-device.entity-panel.components";
var styles_NetworkDeviceEntityPanelComponent = [];
var RenderType_NetworkDeviceEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NetworkDeviceEntityPanelComponent, data: {} });
export { RenderType_NetworkDeviceEntityPanelComponent as RenderType_NetworkDeviceEntityPanelComponent };
export function View_NetworkDeviceEntityPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(1, 0, null, null, 5, "section", [["class", "wcd-full-height wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "network-device-entity-details", [], null, null, null, i1.View_NetworkDeviceEntityDetailsComponent_0, i1.RenderType_NetworkDeviceEntityDetailsComponent)), i0.ɵdid(4, 114688, null, 0, i2.NetworkDeviceEntityDetailsComponent, [i0.Injector, i3.Paris, i4.Router, i5.TvmDownloadService, i6.I18nService, i7.SidePanelService, i8.SecurityRecommendationFieldsService, i9.TvmTextsService], { device: [0, "device"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.networkDevice; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_NetworkDeviceEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "network-device-entity-panel", [], null, null, null, View_NetworkDeviceEntityPanelComponent_0, RenderType_NetworkDeviceEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i10.NetworkDeviceEntityPanelComponent, [i0.ChangeDetectorRef, i3.Paris], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NetworkDeviceEntityPanelComponentNgFactory = i0.ɵccf("network-device-entity-panel", i10.NetworkDeviceEntityPanelComponent, View_NetworkDeviceEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { NetworkDeviceEntityPanelComponentNgFactory as NetworkDeviceEntityPanelComponentNgFactory };
