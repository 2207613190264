var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { Tag } from './tag.entity';
import { TagType } from './tag-type.enum';
var ɵ0 = function (tags) {
    return tags.map(function (tag) {
        return new Tag({
            id: tag,
            name: tag,
            type: TagType.system,
            isEditable: false,
        });
    });
}, ɵ1 = function (tags) {
    return tags.map(function (tag) { return new Tag({ id: tag, name: tag, type: TagType.user }); });
};
var TagsCollection = /** @class */ (function (_super) {
    __extends(TagsCollection, _super);
    function TagsCollection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(TagsCollection.prototype, "allTags", {
        get: function () {
            return this.builtInTags.concat(this.userDefinedTags);
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({
            data: 'BuiltInTags',
            arrayOf: Tag,
            parse: ɵ0,
        }),
        __metadata("design:type", Array)
    ], TagsCollection.prototype, "builtInTags", void 0);
    __decorate([
        EntityField({
            data: 'UserDefinedTags',
            arrayOf: Tag,
            parse: ɵ1,
        }),
        __metadata("design:type", Array)
    ], TagsCollection.prototype, "userDefinedTags", void 0);
    TagsCollection = __decorate([
        ValueObject({
            singularName: 'Tags Collection',
            pluralName: 'Tags Collections',
        })
    ], TagsCollection);
    return TagsCollection;
}(ModelBase));
export { TagsCollection };
export { ɵ0, ɵ1 };
