import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActiveIncidents, ActiveTrendItem, Alert, Incident } from '@wcd/domain';
import { ChartSettings, LegendItem, ValueFormatter } from '@wcd/charts';
import { TzDateService } from '@wcd/localization';
import { config, KnownColorsService } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { EntityType } from '../../../../../global_entities/models/entity-type.interface';
import { MainAppState, MainAppStateService } from '../../../../../shared/main/services/main-app-state.service';
import { filter } from 'rxjs/operators';




@Component({
	selector: 'active-incidents-trends',
	template: `
		<div class="wcd-flex-1 wcd-flex-horizontal wcd-flex-vertical incident-trends-custom-tooltip" *ngIf="data">
			<div class="wcd-font-size-s wcd-font-weight-bold wcd-margin-medium-bottom" *ngIf="!hideTitle">
				{{'incident_alert_and_incident_trend' | i18n }}
			</div>
			<div>
				<wcd-time-series-chart
					[data]="data"
					[settings]="trendChartSettings"
					[enableRenderOnSettingsChanges]="true"
				>
				</wcd-time-series-chart>
			</div>
			<wcd-chart-legend
				class="wcd-margin-medium-top wcd-margin-small-bottom"
				orientation="horizontal"
				[wrap]="true"
				[showValues]="false"
				[items]="incidentAlertTrendLegend"
			></wcd-chart-legend>
		</div>
	`,
	styles: [`
		.incident-trends-custom-tooltip .custom-tooltip {
			background: white;
			width: 149px;
			height: 153px;
			background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), rgba(255, 255, 255, 0.25));
			background-blend-mode: normal, luminosity;
			backdrop-filter: blur(60px);
			border: 1px;
			box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15)
		}

		.incident-trends-custom-tooltip .custom-tooltip .tooltip-title {
			margin-bottom: 10px;
			margin-top: 10px;
			text-align: center;
		}

		.incident-trends-custom-tooltip .custom-tooltip .number-section {
			margin-left: 16px;
			margin-bottom: 10px;
		}

		.incident-trends-custom-tooltip .custom-tooltip .number-value {
			margin-left: 8px;
		}
	`],
	encapsulation: ViewEncapsulation.None,
})
export class ActiveIncidentsTrendsComponent implements OnInit {
	@Input()
	data: ActiveIncidents;

	@Input()
	hideTitle = false;

	incidentAlertTrendLegend: ReadonlyArray<LegendItem>;
	incidentsTranslation: string;
	alertTranslation: string;
	trendChartSettings: ChartSettings;
	incidentEntityType: EntityType<Incident>;
	alertEntityType: EntityType<Alert>;
	isNarrowLayout: boolean = false;

	readonly chartValueFormatter: ValueFormatter = value => `(${value})`;

	constructor(
		private tzDateService: TzDateService,
		private knownColorsService: KnownColorsService,
		private i18nService: I18nService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private mainAppStateService: MainAppStateService,
	) {}

	ngOnInit() {
		this.incidentEntityType = this.globalEntityTypesService.getEntityType(Incident);
		this.alertEntityType = this.globalEntityTypesService.getEntityType(Alert);
		this.incidentsTranslation = this.i18nService.get(this.incidentEntityType.entityPluralNameKey);
		this.alertTranslation = this.i18nService.get(this.alertEntityType.entityPluralNameKey);

		this.incidentAlertTrendLegend = [
			{
				name: this.incidentsTranslation,
				iconClassName: 'ms-color-themePrimary'
			},
			{
				name: this.alertTranslation,
				iconClassName: 'ms-color-blueMid'
			}
		];

		this.mainAppStateService.state$
			.pipe(
				filter((state: MainAppState) => {
					return (
						this.isNarrowLayout !==
						config.widthBreakpointSmallerThan(
							state.screenMaxWidthBreakpoint,
							config.msScreenSizeBreakpoints.xl
						)
					);
				})
			)
			.subscribe((state: MainAppState) => {
				const currentIsNarrowLayout = this.isNarrowLayout;
				this.isNarrowLayout = config.widthBreakpointSmallerThan(
					state.screenMaxWidthBreakpoint,
					config.msScreenSizeBreakpoints.xl
				);
				if(currentIsNarrowLayout !== this.isNarrowLayout){
					this.getSettings(this.data);
				}
			});

		this.getSettings(this.data);

	}

	getSettings = (data: ActiveIncidents) => {
		const max = [...data.incidentsTrend, ...data.alertsTrend].reduce((r, item) => r > item.count ? r : item.count, 0)
		const maxY = Math.ceil(max / 100) * 100
		const blueMid = this.knownColorsService.knownColorsMap['blueMid'].raw
		const themePrimary = this.knownColorsService.knownColorsMap['themePrimary'].raw

		this.trendChartSettings = {
			options: {
				data: {
					columns: [
						[this.incidentsTranslation, ...data.incidentsTrend.map((trend: ActiveTrendItem) => trend.count)],
						[this.alertTranslation, ...data.alertsTrend.map((trend: ActiveTrendItem) => trend.count)],
					],
					colors: {
						[this.incidentsTranslation]: this.knownColorsService.knownColorsMap['themePrimary'].raw,
						[this.alertTranslation]: this.knownColorsService.knownColorsMap['blueMid'].raw,
					},
				},
				point: { show: false },
				axis: {
					// max: maxY,
					y: {
						tick: {
							count: 3
						},
					},
					x: {
						type: 'categories',
						categories: data.alertsTrend.map(item => this.tzDateService.format(item.timeUtc, 'hh:mm:ss aaa')),
						tick: {
							width: 80,
							culling: {
								max: 4
							},
							rotate: !this.isNarrowLayout ? 0 : 45,
						},
						padding: {
							left: 10,
							right: 10,
						},
					},
				},
				grid: {
					y: {
						show: true,
					},
				},
				legend: {
					show: false,
				},
				size: {
					height: 174,
				},
				tooltip: {
					contents: (point: any): string => {
						const date = this.tzDateService.format(data.incidentsTrend[point[0].index].timeUtc, 'MM/dd/yy')
						const time = this.tzDateService.format(data.incidentsTrend[point[0].index].timeUtc, 'h:mm a').toLocaleLowerCase()
						return `<div class="wcd-flex wcd-flex-vertical custom-tooltip">
								<div class="wcd-font-size-s wcd-flex-horizontal wcd-flex-horizontal-spaced tooltip-title" style="margin-bottom: 10px;margin-top:10px;text-align: center;">
									<div>${date}</div>
									<div>${time}</div>
								</div>
								<div style="border-left: 4px solid ${themePrimary};" class="number-section" >
									<div class="wcd-font-size-s" style="margin-left: 8px;">${point[0].name}</div>
									<div class="wcd-font-size-xl ms-color-themePrimary wcd-font-weight-bold number-value" style="margin-left: 8px;">${point[0].value}</div>
								</div>


								<div style="border-left: 4px solid ${blueMid};" class="number-section">
									<div class="wcd-font-size-s" style="margin-left: 8px;">${point[1].name}</div>
									<div class="wcd-font-size-xl ms-color-blueMid wcd-font-weight-bold number-value" style="margin-left: 8px;">${point[1].value}</div>
								</div>
							</div>`
					}
				},
			},
			disableCursor: true,
		};

	}
}
