/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../../../../../global_entities/components/entity-details/machine.entity-details.component.ngfactory";
import * as i4 from "../../../../../global_entities/components/entity-details/machine.entity-details.component";
import * as i5 from "../../../../../global_entities/services/activated-entity.service";
import * as i6 from "../../../../../global_entities/services/global-entity-types.service";
import * as i7 from "../../../../../rbac/services/rbac.service";
import * as i8 from "../../../../machines/services/machines.service";
import * as i9 from "../../../../../../../../../projects/config/src/lib/services/app-flavor.service";
import * as i10 from "../../../../../../../../../projects/config/src/lib/services/features.service";
import * as i11 from "../../../../../admin/integration-settings/advanced-features.service";
import * as i12 from "@microsoft/paris/dist/lib/paris";
import * as i13 from "../../../../../dialogs/services/dialogs.service";
import * as i14 from "../../../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i15 from "../../../../../../../../../projects/config/src/lib/services/tvm-licenses-angular.service";
import * as i16 from "../../../../../../../../../projects/app-config/src/lib/service-urls/services/service-urls.service";
import * as i17 from "../../../../alerts/services/alerts.fields";
import * as i18 from "../../../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i19 from "./software-installation.entity-panel.component";
var styles_SoftwareInstallationEntityPanelComponent = [];
var RenderType_SoftwareInstallationEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SoftwareInstallationEntityPanelComponent, data: {} });
export { RenderType_SoftwareInstallationEntityPanelComponent as RenderType_SoftwareInstallationEntityPanelComponent };
export function View_SoftwareInstallationEntityPanelComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 8, "section", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "h4", [["class", "definition-list-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["\n\t\t\t\t", "\n\t\t\t"])), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "machine-entity-details", [], null, [[null, "loggedOnUsersClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loggedOnUsersClick" === en)) {
        var pd_0 = (_co.showLoggedOnUsers() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MachineEntityDetailsComponent_0, i3.RenderType_MachineEntityDetailsComponent)), i0.ɵdid(9, 114688, null, 0, i4.MachineEntityDetailsComponent, [i0.Injector, i5.ActivatedEntity, i6.GlobalEntityTypesService, i7.RbacService, i8.MachinesService, i9.FlavorService, i2.I18nService, i10.FeaturesService, i11.AdvancedFeaturesService, i12.Paris, i0.ChangeDetectorRef, i13.DialogsService, i8.MachinesService, i14.AppConfigService, i15.TvmLicensesAngularService, i16.ServiceUrlsService, i17.AlertsFields, i18.AppContextService], { machine: [0, "machine"], collapsibleSections: [1, "collapsibleSections"], includeMachineName: [2, "includeMachineName"] }, { loggedOnUsersClick: "loggedOnUsersClick" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.machine; var currVal_2 = false; var currVal_3 = true; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), "machines_entityDetails_sections_details")); _ck(_v, 5, 0, currVal_0); }); }
export function View_SoftwareInstallationEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "software-installation-entity-panel", [], null, null, null, View_SoftwareInstallationEntityPanelComponent_0, RenderType_SoftwareInstallationEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i19.SoftwareInstallationEntityPanelComponent, [i12.Paris, i8.MachinesService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SoftwareInstallationEntityPanelComponentNgFactory = i0.ɵccf("software-installation-entity-panel", i19.SoftwareInstallationEntityPanelComponent, View_SoftwareInstallationEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { SoftwareInstallationEntityPanelComponentNgFactory as SoftwareInstallationEntityPanelComponentNgFactory };
