<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="recommendation" class="tvm wcd-margin-medium-top wcd-scroll-vertical" data-track-component="recommendation">
	<wcd-dl class="wcd-margin-bottom" [display]="dlDisplayMode">
		<div class="span-2 wcd-margin-small-bottom" *ngIf="isZeroDayRecommendation">
			<fab-message-bar  [messageBarType]="MessageBarType.severeWarning">
				{{'tvm_securityRecommendation_zeroDay_notification' | i18n}}
			</fab-message-bar>
		</div>
		<div class="span-2 wcd-margin-small-bottom" *ngIf="newPatchMessage">
			<fab-message-bar  [messageBarType]="MessageBarType.info">
				{{newPatchMessage}}
			</fab-message-bar>
		</div>
		<div *ngIf="recommendation.requiredAttention && isRequiredAttentionEnabled">
			<fab-message-bar
				[messageBarType]="MessageBarType.warning" [styles]="messageBarStyles">
				<div class="spaced">{{explanationText}}</div>
			</fab-message-bar>
		</div>

		<div *ngIf="showEolMessage" class="span-2 wcd-padding-bottom">
			<fab-message-bar
				[messageBarType]="messageBarType"
				[isMultiline]="false"
				[truncated]="shouldShowLegalNote"
				[overflowButtonAriaLabel]="i18nService.strings.entityCommon_panel_impactedEntities_showMore"
				[styles]="getStyles.bind(this)"
				>
				<div class="spaced">{{eolText}}<ng-container *ngIf="recommendation.hasEolVersions || recommendation.hasUpcomingEolVersions"><a (click)="openSoftwareVersions()">tvm_securityRecommendation_remediationTaskCreation_openSoftwareVersions </a>{{softwareVersionsLinkText}}</ng-container></div>
				<div class="wcd-margin-top" *ngIf="messageBarState.showBody" >{{legalNote}}<ng-container *ngIf="showLegalNoteLink"><a [href]="legalNoteLink">{{legalNoteLinkText}}</a></ng-container></div>
			</fab-message-bar>
		</div>

		<ng-container *ngIf="recommendationDescription">
			<ng-container *ngIf="recommendation.status !== 'Active'">
				<dl class="span-2">
					<dt>{{'tvm.common.status' | i18n}}</dt>
					<dd>
						<div>{{recommendationStatus}}. <a [href]="exceptionLink">{{'tvm.securityRecommendation.remediationTaskCreation.viewException' | i18n}}</a></div>
					</dd>
				</dl>
			</ng-container>
			<dl class="span-2">
				<dt>{{'tvm.common.description' | i18n}}</dt>
				<dd>
					<span [innerHTML]="recommendationDescription"></span>
					<a *ngIf="showUpgradeLink" href="{{upgradeLink}}">{{upgradeLinkText}}</a>
				</dd>
			</dl>
		</ng-container>

		<ng-container *ngIf="recommendation.remediationType === remediationType.ConfigurationChange ; then scaRecommendation; else vaRecommendation">
		</ng-container>

		<ng-container *ngIf="!isSingleAssetRecommendation; else remediationOptions">
			<dl>
				<dt>{{ exposedAssetsTitle }}</dt>
				<dd>{{recommendation.assetsStatistics.assetsAtRiskCount | prettyNumber}} / {{recommendation.assetsStatistics.totalAssetCount | prettyNumber}}</dd>
			</dl>

			<dl>
				<dt>{{'tvm.common.impact' | i18n}}</dt>
				<dd><tvm-impact-score [scoreData]="{exposureScore: recommendation.exposureScoreImprovement, secureScore: recommendation.secureScoreImprovement, exposureScoreTooltip: exposureTooltip, secureScoreTooltip: configurationTooltip}"></tvm-impact-score></dd>
			</dl>

			<dl *ngIf="isExposedOperatingSystem && hasWeaknesses">
				<dt>{{'tvm.securityRecommendation.exposedOperatingSystems.title' | i18n}}</dt>
				<dd>
					<div *ngIf="(exposedOperatingSystemsStatistics$ | async) as operatingSystemsDataSet ; else loadingOSs">
						<div
							wcdTooltip
							[tooltipInnerComponent]="ExposedOperatingSystemsComponent"
							[innerComponentInput]="{exposedOperatingSystemsStatistics: operatingSystemsDataSet.items}">
							{{ generateExposedOperatingSystems(operatingSystemsDataSet.items) }}
						</div>
					</div>
				</dd
				>
			</dl>
		</ng-container>

		<ng-template #remediationOptions>
			<ng-container *ngIf="recommendation.remediationType === 'ConfigurationChange'">
				<dl class="span-2">
					<dt>{{('tvm.securityRecommendation.remediationTaskCreation.remediationOptions' | i18n)}}</dt>
					<dd [innerHTML]="recommendation.remediationDescription"></dd>
				</dl>
			</ng-container>
		</ng-template>
	</wcd-dl>

	<ng-container *ngIf="!isSingleAssetRecommendation && recommendation.assetsStatistics.assetsAtRiskCount > 0">
		<exposed-machines-details [includeProductivityAssessment]="showProductivityImpactDescription"
			[assets$]="assets$"
			[title]="exposedAssetsTitle"
			[loadingText]="exposedAssetsLoadingText"
			[isNetworkDevice]="isNetworkDevice"
			[includeLastSeenStatus]="includeLastSeenStatus"
			[includeOperatingSystem]="!isNetworkDevice && isExposedOperatingSystem"
			(showMore)=openAllExposedMachines()
			(export)="exportExposedMachines()">
		</exposed-machines-details>
	</ng-container>

	<ng-container *ngIf="!isSingleAssetRecommendation && recommendation.remediationType !== 'ConfigurationChange'">
		<installed-machines-details
			[installations$]="installations$"
		 	[title]="installedAssetsTitle"
		  	[loadingText]="installedAssetsLoadingText"
			[isNetworkDevice]="isNetworkDevice"
			[includeLastSeenStatus]="includeLastSeenStatus"
			[includeOperatingSystem]="!isNetworkDevice && isExposedOperatingSystem"
			(showMore)=openAllInstalledMachines()
			(export)="exportInstalledMachines()">
		</installed-machines-details>
	</ng-container>

	<related-cves-details *ngIf="!isConfigurationChange && hasWeaknesses"
		[repository]="vulnerabilitiesRelationshipRepository"
		[title]="'tvm.missingKb.cvesAddressed.title' | i18n"
		[maxItems]="MaxItemsInCollapseExpandTable"
		[contextOptions]="contextOptions"
		[isNetworkGearCve]="isNetworkGearCve"
		(export)="exportCveData()"
		(showMore)=openAllRelatedCves()>
	</related-cves-details>

	<ng-container *ngIf="!isSingleAssetRecommendation && recommendation.assetsStatistics.assetsAtRiskCount > 0 && recommendationContextType !== unknownRecommendationContextType">
		<recommendation-context-details
			[isSingleAssetRecommendation]="isSingleAssetRecommendation"
			[securityRecommendation]="recommendation"
			[recommendationContextType]="recommendationContextType"
			[repository]="recommendationContextRelationshipRepository"
			[title]="recommendationContextExposedMachinesTitle"
			[maxItems]="MaxItemsInCollapseExpandTable"
			[contextOptions]="contextOptions"
			(export)="exportRecommendationContextData()"
			(showMore)=openAllRecommendationContextKeyAndExposedMachinesCountPanel()>
		</recommendation-context-details>
	</ng-container>

	<ng-container *ngIf="isSingleAssetRecommendation && recommendationContextType !== unknownRecommendationContextType">
		<recommendation-context-details
			[isSingleAssetRecommendation]="isSingleAssetRecommendation"
			[securityRecommendation]="recommendation"
			[recommendationContextType]="recommendationContextType"
			[repository]="assetRecommendationContextRelationshipRepository"
			[title]="recommendationContextExposedMachinesTitle"
			[maxItems]="3"
			[contextOptions]="contextOptions"
			(export)="exportRecommendationContextData()"
			(showMore)=openAllRecommendationContextKeyAndPropertiesPanel()>
		</recommendation-context-details>
	</ng-container>

	<related-tasks-details [remediationTasks]="recommendation.remediationTasks"></related-tasks-details>

	<ng-container *ngIf="mitigatedVulnerabilities$">
		<ng-container *ngIf="(mitigatedVulnerabilities$ | async) as vulnerabilities ; else loadingCves">
			<ng-container *ngTemplateOutlet="loadMitigatedVulnerabilities; context: {vulnerabilities: vulnerabilities}">
			</ng-container>
		</ng-container>
	</ng-container>
</div>

<ng-template #loadMitigatedVulnerabilities let-vulnerabilities="vulnerabilities">
	<entity-panel-collapsible-section
		*ngIf="!(vulnerabilities?.count == 0)"
		[title]="vulnerabilitiesTitle"
		[count]="vulnerabilities?.count">
		<table class="wcd-table wcd-full-width table-grouped no-side-padding" [attr.aria-label]="'tvm.common.relatedCVE.title' | i18n">
			<thead>
				<tr>
					<th>{{'tvm.securityRecommendation.mitigatedCVE.cveId' | i18n}}</th>
					<th *ngIf="!isSingleAssetRecommendation">{{'tvm.securityRecommendation.mitigatedCVE.canBeMitigated' | i18n}}</th>
					<th *ngIf="!isSingleAssetRecommendation">{{'tvm.securityRecommendation.mitigatedCVE.mitigatedMachines' | i18n}}</th>
					<th>{{'tvm.securityRecommendation.mitigatedCVE.mitigationLevel' | i18n}}</th>
				</tr>
			</thead>
			<tbody>
				<tr class="table-row table-group-header" *ngFor="let vulnerability of vulnerabilities.items" (click)="openVulnerabilityPanel(vulnerability.name)">
					<td class="table-link nowrap wcd-text-overflow-medium">
						{{ vulnerability.name }}
					</td>
					<td *ngIf="!isSingleAssetRecommendation" class="nowrap wcd-text-overflow-medium">
						{{ vulnerability.countOfNonMitigatedAssets }}
					</td>
					<td *ngIf="!isSingleAssetRecommendation" class="nowrap wcd-text-overflow-medium">
						{{ vulnerability.countOfMitigatedAssets }}
					</td>
					<td class="nowrap wcd-text-overflow-medium">
						{{ vulnerability.mitigationLevel }}
					</td>
				</tr>
			</tbody>
		</table>
	</entity-panel-collapsible-section>
</ng-template>

<ng-template #showNoDataAvailable>
	{{'common.noDataAvailable' | i18n}}
</ng-template>

<ng-template #scaRecommendation>
	<dl class="span-2">
		<dt>{{'tvm.securityRecommendation.configurationChange.potentialRisk' | i18n}}</dt>
		<dd [innerHTML]="recommendation.potentialRisk"></dd>
	</dl>

	<div *ngIf="showProductivityImpactDescription" class="span-2 security-insights wcd-padding-all wcd-flex-vertical">
		<div class="header wcd-inline-block wcd-margin-small-bottom">
			<fab-icon iconName="UserGauge" contentClass="wcd-vertical-align-bottom inheriting-icon"></fab-icon>
			{{'tvm.securityRecommendation.configurationChange.userImpact' | i18n}}
			<wcd-help [text]="'tvm.securityRecommendation.userImpact.infoIconToolTip' | i18n"></wcd-help>
		</div>
		<div [innerHTML]="userImpactDescription"></div>
		<button
			data-track-id="OpenRemediationOnSafeAssets"
			data-track-type="Button"
			[attr.data-track-value]="recommendation.title"
			*ngIf="!isSingleAssetRecommendation"
			class="remediate-safe-assets-btn"
			(click)="openSafeAssetsRemediation()">
			{{'tvm.securityRecommendation.userImpact.remediateSafeAssets' | i18n}}
		</button>
	</div>

	<ng-container *ngIf="!isSingleAssetRecommendation">
		<div class="span-2 security-insights wcd-padding-all wcd-margin-vertical">
			<div class="header wcd-margin-small-bottom">
				<fab-icon iconName="lightbulb" contentClass="wcd-vertical-align-bottom inheriting-icon"></fab-icon>
				<span>{{'tvm.securityRecommendation.configurationChange.insights' | i18n}}</span>
			</div>
			<ul>
				<li [innerHTML]="properlyConfiguredInsight"></li>
				<li *ngIf="supportedBenchmarksInsight" [innerHTML]="supportedBenchmarksInsight"></li>
			</ul>
		</div>
	</ng-container>

	<dl>
		<dt>{{'tvm.securityRecommendation.configurationChange.configurationID' | i18n}}</dt>
		<dd>{{recommendation.scId || ('notAvailable.short' | i18n)}}</dd>
	</dl>
	<dl>
		<dt>{{'tvm.securityRecommendation.configurationChange.cce' | i18n}}</dt>
		<dd>{{recommendation.cce || ('notAvailable.short' | i18n)}}</dd>
	</dl>
	<dl>
		<dt>{{'tvm.securityRecommendation.configurationChange.category' | i18n}}</dt>
		<dd>{{recommendationRelatedComponent}}</dd>
	</dl>
</ng-template>

<ng-template #vaRecommendation>
	<ng-container *ngIf="isZeroDayRecommendation">
		<div class="span-2 security-insights wcd-padding-all wcd-flex-vertical wcd-margin-medium-bottom">
			<div class="header wcd-inline-block wcd-margin-small-bottom">
				<fab-icon iconName="EventDateMissed12" contentClass="wcd-vertical-align-bottom inheriting-icon">
				</fab-icon>
				{{'tvm_securityRecommendation_va_zeroDay_card_title' | i18n}}
			</div>
			<dl *ngIf="!isSingleAssetRecommendation" class="wcd-margin-small-top">
				<dt>
					{{ exposedAssetsTitle }}
				</dt>
				<dd>
					{{recommendation.zeroDayAssetsAtRisk | prettyNumber}}
				</dd>
			</dl>
			<div>
				<span *ngIf="shouldShowWorkaround">
					{{'tvm_securityRecommendation_va_zeroDay_card_text_with_workaround_preLink' | i18n}}
					<a href="#" (click)="openWorkaroundPanel()">
						{{'tvm_securityRecommendation_va_zeroDay_card_workaround_link_text' | i18n}}
					</a>
				</span>
				{{ workaroundsMainText }}
			</div>
			<button
				*ngIf="!isSingleAssetRecommendation"
				data-track-id="OpenRemediationFromZeroDayCard"
				data-track-type="Button"
				[attr.data-track-value]="recommendation.title"
				class="remediate-safe-assets-btn wcd-margin-medium-top"
				(click)="openZeroDayRemediation()">
				{{'tvm_securityRecommendation_va_zeroDay_card_button' | i18n}}
			</button>
		</div>

		<div *ngIf="!isSingleVulnerability && !isSingleAssetRecommendation" class="span-2 security-insights wcd-padding-all wcd-flex-vertical wcd-margin-large-bottom">
			<div class="header wcd-inline-block wcd-margin-small-bottom">
				<fab-icon iconName="PageList" contentClass="wcd-vertical-align-bottom inheriting-icon">
				</fab-icon>
				{{'tvm_securityRecommendation_va_additional_vulnerabilities_card_title' | i18n}}
			</div>
			<span>
				{{'tvm_securityRecommendation_va_additional_vulnerabilities_card_text' | i18n:{software: recommendationRelatedComponent, vulnerabilities: (recommendation.weaknessesCount - 1)} }}
			</span>
			<button
				*ngIf="!isSingleAssetRecommendation"
				data-track-id="OpenRemediationFromAdditionalVulnerabilitiesCard"
				data-track-type="Button"
				[attr.data-track-value]="recommendation.title"
				class="remediate-safe-assets-btn wcd-margin-medium-top"
				(click)="openRemediationOption()">
				{{'tvm_securityRecommendation_va_additional_vulnerabilities_card_button' | i18n}}
			</button>
		</div>
	</ng-container>
	<div class="title">{{'tvm.securityRecommendation.software.vulnerabilityDetails' | i18n}}</div>

	<dl>
		<dt>{{'tvm.securityRecommendation.software.numberOfVulnerabilities' | i18n}}
			<wcd-help *ngIf="isEol" [text]="widgetEosTooltip"></wcd-help>
		</dt>
		<dd>{{recommendation.vulnerabilitiesCount}}</dd>
	</dl>
	<dl>
		<dt>{{'tvm.securityRecommendation.software.exploitAvailable' | i18n}}</dt>
		<dd>{{recommendation.threatInfo.hasExploit | boolean:['Yes', 'No']}}</dd>
	</dl>
</ng-template>

<ng-template #loadingCves>
	<div class="wcd-padding-top">
		<fab-spinner [size]="SpinnerSize.large" [label]="'tvm.securityRecommendation.loadingRelatedCves' | i18n"></fab-spinner>
	</div>
</ng-template>

<ng-template #loadingOSs>
	<div class="wcd-padding-top">
		<fab-spinner [size]="SpinnerSize.small" [label]="'tvm.securityRecommendation.loadingExposedOperatingSystems' | i18n" className="wcd-padding-left"></fab-spinner>
	</div>
</ng-template>
