var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CloseSessionApiCall, LiveResponseScript, LiveResponseSession, Machine, OperatingSystemPlatformCategories, } from '@wcd/domain';
import { LiveResponseSessionEntityComponent } from '../components/live-response-session.entity.component';
import { LiveResponseSessionEntityDetailsComponent } from '../../../global_entities/components/entity-details/live-response-session.entity-details.component';
import { FabricIconNames } from '@wcd/scc-common';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { DimensionsModel } from '../../../dialogs/models/dimensions.model';
import { LiveResponseScriptUploadComponent } from '../components/live-response-script-upload.component';
import { LiveResponseService } from './live-response.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { LiveResponseSessionHeaderBottomComponent } from '../../../global_entities/components/entity-header-bottom/live-response-session.header-bottom.component';
import { WcdIconNames } from '@wcd/icons-font';
var LiveResponseSessionEntityTypeService = /** @class */ (function () {
    function LiveResponseSessionEntityTypeService(paris, i18nService, dialogsService, liveResponseService, globalEntityTypesService) {
        var _this = this;
        this.paris = paris;
        this.i18nService = i18nService;
        this.dialogsService = dialogsService;
        this.liveResponseService = liveResponseService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.entityType = {
            id: 'liveResponseSession',
            entity: LiveResponseSession,
            icon: FabricIconNames.Play,
            getIcon: function (sessions) {
                var icon = GlobalEntityTypesService.getCommonValue(sessions, function (session) {
                    if (session.machine && session.machine.os) {
                        if (session.machine.os.osPlatform.category === OperatingSystemPlatformCategories.macOS) {
                            return WcdIconNames.mac;
                        }
                        if (session.machine.os.osPlatform.category === OperatingSystemPlatformCategories.Linux) {
                            return WcdIconNames.linux;
                        }
                    }
                    return FabricIconNames.WindowsLogo;
                });
                return icon || FabricIconNames.Play;
            },
            getIconCssClass: function (sessions) { return 'live-response-entity-icon'; },
            getImage: function () { return '/assets/images/icons/entities/live_response.svg'; },
            entityPageViewMode: EntityPageViewMode.Asset,
            entityContentsComponentType: LiveResponseSessionEntityComponent,
            entityDetailsComponentType: LiveResponseSessionEntityDetailsComponent,
            entityHeaderBottomComponentType: LiveResponseSessionHeaderBottomComponent,
            getEntityName: function (session) {
                return _this.i18nService.get('liveResponse_session_name', {
                    machineId: _this.globalEntityTypesService.getEntityName(Machine, session.machine) ||
                        session.machine.id,
                });
            },
            getActions: function (sessions) {
                var actions = [];
                if (sessions.length === 1) {
                    var currentSession = sessions[0];
                    return _this.liveResponseService.getSessionUntilDone(currentSession).pipe(map(function (session) {
                        actions = [];
                        if (session.status.isRunning) {
                            actions.push(new ItemActionModel({
                                id: 'closeSession',
                                nameKey: 'liveResponse_actions_closeSession_title',
                                rbac: [
                                    RbacMdeAllowedActions.liveResponseBasic,
                                    RbacMdeAllowedActions.liveResponseAdvanced,
                                ],
                                rbacState: RbacControlState.disabled,
                                icon: FabricIconNames.Stop,
                                tooltip: _this.i18nService.strings.liveResponse_actions_closeSession_tooltip,
                                method: function (_sessions) { return __awaiter(_this, void 0, void 0, function () {
                                    var confirmed;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this.dialogsService.confirm({
                                                    title: this.i18nService.strings.liveResponse_actions_closeSession_title,
                                                    text: this.i18nService.strings.liveResponse_actions_closeSession_approve,
                                                })];
                                            case 1:
                                                confirmed = (_a.sent()).confirmed;
                                                if (!confirmed) return [3 /*break*/, 3];
                                                return [4 /*yield*/, this.paris.apiCall(CloseSessionApiCall, _sessions[0]).toPromise()];
                                            case 2:
                                                _a.sent();
                                                return [2 /*return*/, this.liveResponseService.resetSession(_sessions[0])];
                                            case 3: return [2 /*return*/, Promise.reject()];
                                        }
                                    });
                                }); },
                            }));
                            actions.push(new ItemActionModel({
                                id: 'uploadScript',
                                nameKey: 'liveResponse_actions_uploadScript_title',
                                rbac: [RbacMdeAllowedActions.securitySettings],
                                rbacState: RbacControlState.disabled,
                                icon: FabricIconNames.Upload,
                                tooltip: _this.i18nService.strings.liveResponse_actions_uploadScript_tooltip,
                                refreshOnResolve: false,
                                method: function (_sessions) {
                                    return _this.dialogsService
                                        .showModal(LiveResponseScriptUploadComponent, {
                                        id: 'live-response-script-upload',
                                        title: _this.i18nService.strings
                                            .liveResponse_actions_uploadScript_title,
                                        dimensions: new DimensionsModel(600, 370),
                                    }, {
                                        script: new LiveResponseScript(null),
                                    }).toPromise();
                                },
                            }));
                        }
                        return actions;
                    }));
                }
                return actions;
            },
            isUserExposedToEntityById: function (sessionId) {
                return _this.paris.getItemById(LiveResponseSession, sessionId, null, { useV3Api: sessionId.startsWith('CLR') }).pipe(map(function (liveResponseSession) {
                    return true;
                }), catchError(function (error) {
                    return of({ isExposed: error.status !== 403, reason: error.message });
                }));
            },
            getItemParams: function (entity, options) { return ({
                useV3Api: (options && options["routeId"] && options["routeId"].startsWith('CLR')) || entity && entity.useV3Api
            }); },
        };
    }
    return LiveResponseSessionEntityTypeService;
}());
export { LiveResponseSessionEntityTypeService };
