
		<div
			class="wcd-border-all-light wcd-margin-bottom wcd-padding-all"
			data-track-component="AirsMemoryContentDetails"
		>
			<dl class="key-values wcd-margin-bottom" role="none">
				<dt>{{ i18nService.strings.airsEntities_memoryContents_address }}</dt>
				<dd>{{ memoryContents.addressHex }}</dd>
				<dt>{{ i18nService.strings.airsEntities_memoryContents_size }}</dt>
				<dd>{{ memoryContents.size | prettyBytes }}</dd>
				<ng-container *ngIf="memoryContents.detector">
					<dt>{{ i18nService.strings.airsEntities_memoryContents_reason }}</dt>
					<dd>
						{{
							i18nService.strings[
								'entities_memoryContents_detector_' +
									memoryContents.detector.toLowerCase() +
									'_reason'
							]
						}}
					</dd>
				</ng-container>
			</dl>
			<entity-report
				*ngIf="memoryContents.report"
				[entityType]="entityType"
				[report]="memoryContents.report"
				[showDescription]="showDescription"
				[viewProvidersByDefault]="true"
			></entity-report>
			<div
				class="wcd-margin-top"
				*ngIf="appConfigService.isExposedToAllMachineGroups"
				[rbac]="{ permissions: ['securitySettings'], state: 'hidden' }"
			>
				<ng-container
					*ngIf="
						!memoryContents.systemExclusionRuleListType &&
						memoryContents.allowCreateSystemExclusion
					"
				>
					<button
						type="button"
						*ngFor="let systemExclusionListType of systemExclusionListTypes"
						class="btn btn-secondary-primary wcd-margin-small-right"
						data-track-id="AddSystemExclusion"
						data-track-type="Button"
						(click)="setMemoryContentsRuleType(systemExclusionListType)"
						[disabled]="isSettingExclusionRule"
						[wcdTooltip]="systemExclusionListType.helpKey | i18n"
					>
						<wcd-shared-icon
							[iconName]="systemExclusionListType.icon"
							class="add-system-exclusion-icon"
						>
						</wcd-shared-icon>
						{{
							'systemExclusions_addRuleType'
								| i18n: { systemExclusionListType: systemExclusionListType.name }
						}}
					</button>
				</ng-container>
				<!--<ng-template #removeSystemExclusion>-->
				<!--<button type="button"-->
				<!--class="btn btn-secondary-primary wcd-margin-small-right"-->
				<!--data-track-id="RemoveRule" -->
				<!--data-track-type="Button" -->
				<!--(click)="removeMemoryContentsRule()"-->
				<!--[disabled]="isSettingExclusionRule"-->
				<!--[wcdTooltip]="'systemExclusions.generalDescription' | i18n:{ type: 'Memory contents'}">-->
				<!-- <wcd-shared-icon [iconName]="'remove'" > </wcd-shared-icon >-->
				<!--{{"systemExclusions.removeRule" | i18n:{ systemExclusionType: 'memory content', systemExclusionListType: memoryContents.systemExclusionRuleListType.name } }}-->
				<!--</button>-->
				<!--</ng-template>-->
			</div>
		</div>
	