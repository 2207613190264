/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectionStrategy, Component, Injector, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ModelBase } from '@microsoft/paris';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { RecommendationExceptionAggregated } from '@wcd/domain';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { Router } from '@angular/router';
import { RecommendationExceptionService } from '../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exception.service';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'recommendation-exception-aggregated-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './recommendation-exception-aggregated.entity-details.component.html',
})
export class RecommendationExceptionAggregatedEntityDetailsComponent
	extends EntityDetailsComponentBase<RecommendationExceptionAggregated>
	implements OnInit, OnDestroy {
	private _subscription: Subscription;

	@Input() recommendationExceptionAggregated?: RecommendationExceptionAggregated;

	exceptionScopeText: string;
	exceptionTypeText: string;
	relatedComponent: string;
	exceptionStatusTextList: string[];

	constructor(
		private activatedEntity: ActivatedEntity,
		private tvmTextsService: TvmTextsService,
		private router: Router,
		private recommendationExceptionService: RecommendationExceptionService,
		private i18nService: I18nService,
		injector: Injector
	) {
		super(injector);
	}

	ngOnInit() {
		if (!this.recommendationExceptionAggregated) {
			this._subscription = this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof RecommendationExceptionAggregated))
				.pipe(take(1))
				.subscribe((recommendationExceptionAggregated: RecommendationExceptionAggregated) => {
					this.recommendationExceptionAggregated = recommendationExceptionAggregated;
					this.setProperties();
				});
		}

		this.setProperties();
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	navigateToRelatedRecommendation() {
		this.router.navigate(
			[
				'/security-recommendations',
				this.recommendationExceptionService.getRelatedRecommendationId(
					this.recommendationExceptionAggregated
				),
			],
			{
				queryParams: {
					search: `${this.recommendationExceptionService.getRelatedRecommendationId(
						this.recommendationExceptionAggregated,
						false
					)}`,
				},
			}
		);
	}

	navigateToRelatedComponent() {
		this.router.navigate([
			'/software-inventory',
			this.recommendationExceptionAggregated.exceptionArgs.vaRecommendationArgs.productId,
		]);
	}

	private setProperties() {
		this.exceptionScopeText = this.tvmTextsService.getText(
			TextToken.AggregatedExceptionScope,
			this.recommendationExceptionAggregated
		);
		this.exceptionTypeText = this.tvmTextsService.getText(
			TextToken.ExceptionRemediationType,
			this.recommendationExceptionAggregated
		);
		this.relatedComponent = this.tvmTextsService.getText(
			TextToken.RecommendationExceptionRelatedComponent,
			this.recommendationExceptionAggregated
		);

		const totalAmountOfMachineGroups = this.recommendationExceptionAggregated.rbacGroupIdsCount;
		this.exceptionStatusTextList = this.recommendationExceptionAggregated.distinctStatusWithCount.map(
			statusWithCount =>
				`${this.i18nService.get(
					`tvm.recommendationException.status.${statusWithCount.status}`
				)} (${this.i18nService.get(`tvm.recommendationException.machineGroupsScopeOutOfTotalText`, {
					scope: statusWithCount.count,
					total: totalAmountOfMachineGroups,
				})})`
		);
	}
}
