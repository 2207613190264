<!-- tslint:disable:template-accessibility-alt-text -->
<section class="wcd-margin-large-bottom" data-track-component-type="Settings" data-track-component="NonWindows Android FirstParty Onboarding">
	<ng-container *ngIf="isAndroidEnabledGA; else publicPreview">
		<h3 class="wcd-padding-bottom">{{"endpointManagement.nonWindows.android.onboarding.firstParty.step1.title" | i18n}}</h3>
		<div class="wcd-margin-medium-top wcd-padding-vertical wcd-padding-horizontal wcd-flex-vertical wcd-flex-vertical color-box-primary-75">
			<div class="wcd-flex-horizontal">
				<div>
					<a href="https://aka.ms/googlePlayOnBoardingApp" target="_blank"
					   data-track-id="AndroidOnboardingGooglePlayLink"
					   data-track-type="Button">
						<img [src]="'/assets/images/onboarding/google-play-store.svg' | basePath" style="height:50px;" />
					</a>
				</div>
				<div class="wcd-padding-medium-left wcd-font-size-l">
					<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step1.googlePlayAvailability' | i18n"></markdown>
				</div>
			</div>
			<div class="wcd-margin-top">
				<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step1.GA_Note_header' | i18n"></markdown>
			</div>
			<div>
				<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step1.GA_Note' | i18n"></markdown>
			</div>
			<div class="wcd-flex-horizontal-justify-items-end wcd-margin-top">
				<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step1.seeDeploymentInstructions' | i18n"></markdown>
			</div>
		</div>
	</ng-container>
	<ng-template #publicPreview>
		<h3 class="wcd-padding-bottom">{{"endpointManagement.nonWindows.android.onboarding.firstParty.step1.title.preview" | i18n}}</h3>
		<div class="wcd-margin-small-bottom wcd-font-size-l">
			<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step1.device.administrator' | i18n"></markdown>
		</div>
		<div class="wcd-padding-bottom">
			<ol type="a">
				<li class="wcd-margin-small-top">{{'endpointManagement.nonWindows.android.onboarding.firstParty.step1.description.a' | i18n}}</li>
				<li class="wcd-margin-small-top">{{'endpointManagement.nonWindows.android.onboarding.firstParty.step1.description.b' | i18n}}</li>
				<li class="wcd-margin-small-top">{{'endpointManagement.nonWindows.android.onboarding.firstParty.step1.description.c' | i18n}}</li>
			</ol>
			<div class="wcd-padding-medium-left">
				<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step1.footer' | i18n"></markdown>
			</div>
		</div>
		<div class="wcd-padding-medium-left">
			<button class="btn btn-primary"
					[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
					data-track-id="DownloadAndroidOnboarding"
					data-track-type="Button"
					(click)="downloadApk()">
				<wcd-shared-icon iconName="download"> </wcd-shared-icon >
				{{"endpointManagement.nonWindows.android.onboarding.firstParty.step1.downloadOnboardingPackage" | i18n}}
			</button>
		</div>
		<div class="wcd-margin-medium-top wcd-margin-small-bottom wcd-font-size-l">
			<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step1.enterprise' | i18n"></markdown>
		</div>
		<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step1.enterprise.description' | i18n"></markdown>
		<div class="wcd-margin-bottom wcd-margin-top wcd-padding-small-all color-box-gray-100">
			<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step1.note' | i18n"></markdown>
		</div>
	</ng-template>
</section>
<section class="wcd-margin-large-bottom">
	<h3 class="wcd-padding-bottom">{{"endpointManagement.nonWindows.onboarding.firstParty.step2.title" | i18n}}</h3>
	<div>
		<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step2.detection_test' | i18n"></markdown>
	</div>
	<div>
		<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step2.detection_test_b' | i18n"></markdown>
	</div>
	<div class="wcd-margin-top">
		<markdown [data]="'endpointManagement.nonWindows.android.onboarding.firstParty.step2.description.after' | i18n"></markdown>
	</div>
</section>
