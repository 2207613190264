import { DetectionSourceType } from './detection-source.enum';

export const AUTOMATION_DETECTION_SOURCE_TYPE = 'AutomatedInvestigation';
export const MANUAL_DETECTION_SOURCE_TYPE = 'Manual';

export const detectionSourceValues: Array<any> = [
	{
		id: DetectionSourceType.WDATP,
		name: 'EDR',
		nameI18nKey: 'detectionSource_WDATP',
		type: 'WindowsDefenderAtp',
		logoUrl: '/assets/images/detection_systems/defender.png',
		priority: 1,
		isExtended: true,
		isMdeDetectionSource: true,
	},
	{
		id: DetectionSourceType.AV,
		name: 'Antivirus',
		nameI18nKey: 'detectionSource_AV',
		type: 'WindowsDefenderAv',
		priority: 2,
		isMdeDetectionSource: true,
	},
	{
		id: DetectionSourceType.SmartScreen,
		name: 'SmartScreen',
		nameI18nKey: 'detectionSource_SmartScreen',
		type: 'WindowsDefenderSmartScreen',
		priority: 3,
		isMdeDetectionSource: true,
	},
	{
		id: DetectionSourceType.ThirdPartySensors,
		name: '3rd party',
		nameI18nKey: 'detectionSource_3rd_party',
		type: 'Bitdefender,Ziften,SentinelOne,Lookout',
		isGroupedThirdParty: true,
		priority: 4,
		isMdeDetectionSource: true,
	},
	{
		id: DetectionSourceType.Bitdefender,
		name: 'Bitdefender',
		nameI18nKey: 'detectionSource_Bitdefender',
		type: 'Bitdefender',
		isThirdParty: true,
		priority: 6,
	},
	{
		id: DetectionSourceType.CustomerTI,
		name: 'Custom TI',
		nameI18nKey: 'detectionSource_CustomerTI',
		type: 'CustomerTI',
		isExternal: true,
		priority: 5,
		isMdeDetectionSource: true,
	},
	{
		id: DetectionSourceType.Ziften,
		name: 'Ziften',
		nameI18nKey: 'detectionSource_Ziften',
		type: 'Ziften',
		isThirdParty: true,
		priority: 6,
	},
	{
		id: DetectionSourceType.Lookout,
		name: 'Lookout',
		nameI18nKey: 'detectionSource_Lookout',
		type: 'Lookout',
		isThirdParty: true,
		priority: 6,
	},
	{
		id: DetectionSourceType.SentinelOne,
		name: 'Sentinel One',
		nameI18nKey: 'detectionSource_SentinelOne',
		type: 'SentinelOne',
		isThirdParty: true,
		priority: 6,
	},
	{
		id: DetectionSourceType.OATP,
		name: 'MDO',
		nameI18nKey: 'detectionSource_OATP',
		type: 'OfficeATP',
		priority: 6,
		isExtended: true,
		isSmbDetectionSource: true,
	},
	{
		id: DetectionSourceType.AutomatedInvestigation,
		name: 'Automated investigation',
		nameI18nKey: 'detectionSource_AutomatedInvestigation',
		type: AUTOMATION_DETECTION_SOURCE_TYPE,
		priority: 6,
		isExtended: true,
		isMdeDetectionSource: true,
	},
	{
		id: DetectionSourceType.ThreatExperts,
		name: 'Microsoft Threat Experts',
		nameI18nKey: 'detectionSource_ThreatExperts',
		type: 'ThreatExperts',
		priority: 6,
		isExtended: true,
		isMdeDetectionSource: true,
	},
	{
		id: DetectionSourceType.CustomDetection,
		name: 'Custom detection',
		nameI18nKey: 'detectionSource_CustomDetection',
		type: 'CustomDetection',
		priority: 6,
		isExtended: true,
		isMdeDetectionSource: true,
	},
	{
		id: DetectionSourceType.AATP,
		name: 'MDI',
		nameI18nKey: 'detectionSource_AATP',
		type: 'AzureATP',
		priority: 6,
	},
	{
		id: DetectionSourceType.MCAS,
		name: 'Microsoft Cloud App Security',
		nameI18nKey: 'detectionSource_MCAS',
		type: 'MCAS',
		priority: 6,
	},
	{
		id: DetectionSourceType.MTP,
		name: 'Microsoft 365 Defender',
		nameI18nKey: 'detectionSource_MTP',
		type: 'MTP',
		priority: 6,
		isExtended: true,
		isMdeDetectionSource: true,
	},
	{
		id: DetectionSourceType.MAPG,
		name: 'Microsoft Application Protection and Governance',
		nameI18nKey: 'serviceSourceType_Mapg',
		type: 'MAPG',
		priority: 6,
	},
	{
		id: DetectionSourceType.AAD,
		name: 'AAD Identity Protection',
		nameI18nKey: 'serviceSourceType_Aad',
		type: 'AAD',
		priority: 6,
	},
	{
		id: DetectionSourceType.Manual,
		name: 'Manual',
		nameI18nKey: 'detectionSource_Manual',
		type: MANUAL_DETECTION_SOURCE_TYPE,
		priority: 6,
	},
	{
		id: DetectionSourceType.DLP,
		name: 'Data Loss Prevention',
		nameI18nKey: 'serviceSourceType_Dlp',
		type: 'DLP',
		priority: 6,
	},
	{
		id: DetectionSourceType.AppGPolicy,
		name: 'App governance Policy',
		nameI18nKey: 'detectionSource_AppGPolicy',
		type: 'AppGPolicy',
		priority: 6,
	},
	{
		id: DetectionSourceType.AppGDetection,
		name: 'App governance Detection',
		nameI18nKey: 'detectionSource_AppGDetection',
		type: 'AppGDetection',
		priority: 6,
	},
].reduce((idAndTypeArray, detectionSource: any) => {
	const detectionSourceByType = Object.assign({}, detectionSource);
	detectionSourceByType.id = detectionSource.type;
	detectionSource.isSelectable = false;
	detectionSourceByType.isSelectable = true;

	return idAndTypeArray.concat([detectionSource, detectionSourceByType]);
}, []);
