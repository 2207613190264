import { EntityType } from '../../models/entity-type.interface';
import { DataviewField } from '@wcd/dataview';
import { ModelBase, RelationshipRepository } from '@microsoft/paris';
import { PanelContainer } from '@wcd/panels';
import { Input, ChangeDetectionStrategy, Component } from '@angular/core';
import { Vulnerability, TvmEndPoint } from '@wcd/domain';
import { Router } from '@angular/router';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { VulnerabilityFieldsService } from '../../../@entities/@tvm/vulnerabilities/services/vulnerabilities.fields.service';
import { VulnerabilityService } from '../../../@entities/@tvm/vulnerabilities/services/vulnerability.service';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { Feature, FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'related-cves-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './related-cves.panel.html',
})
export class RelatedCvesPanelComponent<T extends ModelBase> extends PanelContainer {
	entityType: EntityType<Vulnerability>;
	isExportEnabled: boolean;
	_repository: RelationshipRepository<T, Vulnerability>;
	_sourceValue: T;
	_relatedCvesTitle: string;
	fields: Array<DataviewField<Vulnerability>>;

	@Input()
	set sourceValue(value: T) {
		this._sourceValue = value;
	}
	get sourceValue(): T {
		return this._sourceValue;
	}

	@Input()
	set relatedCvesTitle(value: string) {
		this._relatedCvesTitle = value;
	}
	get relatedCvesTitle(): string {
		return this._relatedCvesTitle;
	}

	@Input()
	set repository(value: RelationshipRepository<T, Vulnerability>) {
		this._repository = value;
	}

	@Input() includeExposedMachinesComponent: boolean;

	@Input() isNetworkGearCve: boolean;

	constructor(
		router: Router,
		globalEntityTypesService: GlobalEntityTypesService,
		featuresService: FeaturesService,
		public readonly vulnerabilityFieldsService: VulnerabilityFieldsService,
		private tvmDownloadService: TvmDownloadService,
		private vulnerabilityService: VulnerabilityService,
		private i18nService: I18nService
	) {
		super(router);
		this.isExportEnabled = featuresService.isEnabled(Feature.TvmExportCsvFromSidePanel);
		this.fields = vulnerabilityFieldsService.fields.filter(field =>
			['name', 'severity', 'numOfImpactedAssets'].includes(field.id)
		);

		this.entityType = globalEntityTypesService.getEntityType(Vulnerability);
	}

	exportCveData() {
		this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this._repository,
			TvmEndPoint.Analytics,
			`${this.relatedCvesTitle} - ${this.i18nService.get('tvm.common.relatedCVE.title')}`
		);
	}

	openVulnerabilityPanel(vulnerability: Vulnerability) {
		this.vulnerabilityService.showVulnerabilityPanel(
			vulnerability,
			this.includeExposedMachinesComponent,
			this.isNetworkGearCve
		);
	}
}
