import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'machine-itp-spns',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./machine-itp-spns.component.scss'],
	template: `
		<ul *ngIf="spns?.length; else noneFound" class="wcd-padding-none-all wcd-machine-spns-list">
			<li *ngFor="let spn of spns">
				{{ spn }}
			</li>
		</ul>
		<ng-template #noneFound>
			{{ 'machines.entityDetails.fields.spns.noneFound' | i18n }}
		</ng-template>
	`,
})
export class MachineSpnsComponent {
	@Input() spns: Array<string>;
}
