import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Product evidence',
	pluralName: 'Product evidences',
	endpoint: 'productEvidence',
	parseItemQuery: (itemId: string, entity, config, params: { [index: string]: any }) => {
		return `productEvidence?assetId=${encodeURIComponent(params.assetId)}&productId=${encodeURIComponent(
			itemId
		)}&productVersion=${encodeURIComponent(params.productVersion)}&nonCpeProgram=${params.nonCpeProgram}`;
	},
	readonly: true,
})
export class ProductEvidence extends EntityModelBase<string> {
	@EntityField({})
	readonly diskPaths: Array<string>;

	@EntityField({})
	readonly registryPaths: Array<string>;

	@EntityField({
		// Time looks like 2019-01-25 00:03
		// As it doesn't include +00 or GMT its considered as local time, we add UTC
		parse: value => {
			const valueInUtc = value ? value + ' UTC' : undefined;
			const dateParsed = Date.parse(valueInUtc);
			if (isNaN(dateParsed) === false) {
				return new Date(dateParsed);
			}
			return undefined;
		},
	})
	readonly lastSeenTimestampUtc: Date;
}
