import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
	IConfirmationDialogContentComponent,
	IConfirmationDialogHandlers,
} from '../models/confirmation-dialog-options.model';

@Component({
	selector: 'wcd-confirmation-dialog-text-input-content',
	templateUrl: './confirmation-dialog-text-input-content.component.html',
	styleUrls: ['./confirmation-dialog-text-input-content.component.scss'],
})
export class ConfirmationDialogTextInputContentComponent
	implements
		OnInit,
		IConfirmationDialogContentComponent,
		IConfirmationDialogHandlers<ConfirmationDialogTextInputContentComponent, string> {
	@Input() text?: string = '';
	@Input() placeholder?: string = '';
	@Input() label?: string;
	@Input() required?: boolean;

	readonly isValid$ = new Subject<boolean>();

	ngOnInit() {
		this.isValid$.next(this.required ? this.text !== '' : true);
	}

	onValueChange(newValue: string) {
		if (this.required) {
			this.isValid$.next(newValue !== '');
		}
	}

	onSubmit(): Promise<string> | Observable<string> | string {
		return this.text;
	}
}
