import { BaselineBaseBenchmark } from './baseline-base-benchmark.entity';

export enum BaselineBaseBenchmarks {
	CIS = 'CIS',
	STIG = 'STIG'
}

export const baselineBaseBenchmarkValues: Array<BaselineBaseBenchmark> = [
	{
		id: BaselineBaseBenchmarks.CIS,
		name: 'Center for Internet Security (CIS)',
	},
	{
		id: BaselineBaseBenchmarks.STIG,
		name: 'Security Technical Implementation Guides (STIG)',
	},
];
