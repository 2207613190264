import { AggregatedIncidentLinkedBy, Alert } from '@wcd/domain';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AlertIncidentService } from '../services/alert-incident.service';
import { I18nService } from '@wcd/i18n';
import { AlertLinkedByDetailsComponent } from './alert-linked-by-details.component';

@Component({
	selector: 'alert-reasons-field',
	template: `
		<span
			wcdTooltip
			[tooltipInnerComponent]="alertLinkedByDetailsComponent"
			[innerComponentInput]="{
				alertId: alert?.id,
				linkedBy: alert?.aggregatedIncidentLinkedBy,
				aggregatedLinkedBy: aggregatedLinkedBy,
				showTooltip: false
			}"
			>{{ reasonsText }}</span
		>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertReasonsFieldComponent implements OnInit {
	@Input() alert: Alert;
	reasonsText: string;
	alertLinkedByDetailsComponent = AlertLinkedByDetailsComponent;
	aggregatedLinkedBy: Array<AggregatedIncidentLinkedBy>;

	constructor(private i18nService: I18nService, private alertIncidentService: AlertIncidentService) {}

	ngOnInit() {
		this.reasonsText = this.getReasonsText();
	}

	getReasonsText(): string {
		if (!this.alert || this.alert.aggregatedIncidentLinkedBy.length === 0) {
			return '';
		}

		this.aggregatedLinkedBy = this.alert.aggregatedIncidentLinkedBy;

		if (this.aggregatedLinkedBy.length > 1) {
			return this.i18nService.get('alerts.fields.linkedBy.values.multipleValues', {
				count: this.aggregatedLinkedBy.length,
			});
		}

		return this.alertIncidentService.getCategoryDisplayText(this.aggregatedLinkedBy[0].category);
	}
}
