/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "@angular/common";
import * as i4 from "./airs-entity.entity-panel-multi.component";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../global_entities/services/global-entity-types.service";
var styles_AirsEntityEntityPanelMultiComponent = [];
var RenderType_AirsEntityEntityPanelMultiComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AirsEntityEntityPanelMultiComponent, data: {} });
export { RenderType_AirsEntityEntityPanelMultiComponent as RenderType_AirsEntityEntityPanelMultiComponent };
function View_AirsEntityEntityPanelMultiComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "wcd-margin-large-horizontal wcd-flex"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "fab-icon", [["contentClass", "color-text-error"], ["iconName", "Warning"]], null, null, null, i1.View_FabIconComponent_0, i1.RenderType_FabIconComponent)), i0.ɵdid(3, 5816320, null, 0, i2.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "wcd-padding-small-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var currVal_0 = "color-text-error"; var currVal_1 = "Warning"; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.i18nService.strings.airsEntities_panel_multipleStatus_warning; _ck(_v, 6, 0, currVal_2); }); }
export function View_AirsEntityEntityPanelMultiComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AirsEntityEntityPanelMultiComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.showWarning$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AirsEntityEntityPanelMultiComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "airs-entity-multi-entity-panel", [], null, null, null, View_AirsEntityEntityPanelMultiComponent_0, RenderType_AirsEntityEntityPanelMultiComponent)), i0.ɵdid(1, 245760, null, 0, i4.AirsEntityEntityPanelMultiComponent, [i5.I18nService, i6.GlobalEntityTypesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AirsEntityEntityPanelMultiComponentNgFactory = i0.ɵccf("airs-entity-multi-entity-panel", i4.AirsEntityEntityPanelMultiComponent, View_AirsEntityEntityPanelMultiComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { AirsEntityEntityPanelMultiComponentNgFactory as AirsEntityEntityPanelMultiComponentNgFactory };
