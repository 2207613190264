/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../reports/components/alerts-severity-incident-summary.component.ngfactory";
import * as i2 from "../../../reports/components/alerts-severity-incident-summary.component";
import * as i3 from "../../alerts/components/alerts.dataview.ngfactory";
import * as i4 from "../../alerts/components/alerts.dataview";
import * as i5 from "@microsoft/paris/dist/lib/paris";
import * as i6 from "../../alerts/services/alerts.fields";
import * as i7 from "../../alerts/services/alerts.service";
import * as i8 from "../../../shared/services/title.service";
import * as i9 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i12 from "../../../global_entities/services/global-entity-types.service";
import * as i13 from "../../../shared/services/time-ranges.service";
import * as i14 from "../../../rbac/services/rbac.service";
import * as i15 from "../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i16 from "../../../dialogs/services/dialogs.service";
import * as i17 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i18 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i19 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i20 from "@angular-react/fabric";
import * as i21 from "@angular/common";
import * as i22 from "./mailbox-alerts.component";
var styles_MailboxAlertsComponent = [];
var RenderType_MailboxAlertsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MailboxAlertsComponent, data: {} });
export { RenderType_MailboxAlertsComponent as RenderType_MailboxAlertsComponent };
function View_MailboxAlertsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "wcd-padding-large-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "alerts-severity-incident-summary", [], null, null, null, i1.View_AlertsSeverityIncidentSummaryComponent_0, i1.RenderType_AlertsSeverityIncidentSummaryComponent)), i0.ɵdid(5, 49152, null, 0, i2.AlertsSeverityIncidentSummaryComponent, [], { alertsSeveritySummary: [0, "alertsSeveritySummary"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.alertsSeveritySummary; _ck(_v, 5, 0, currVal_0); }, null); }
function View_MailboxAlertsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "alerts-dataview", [], null, null, null, i3.View_AlertsDataviewComponent_0, i3.RenderType_AlertsDataviewComponent)), i0.ɵdid(1, 638976, null, 0, i4.AlertsDataviewComponent, [i5.Paris, i6.AlertsFields, i7.AlertsService, i8.TitleService, i9.AuthService, i10.Router, i11.PreferencesService, i10.ActivatedRoute, i12.GlobalEntityTypesService, i13.TimeRangesService, i14.RbacService, i15.AppConfigService, i16.DialogsService, i0.ChangeDetectorRef, i17.FeaturesService, i18.I18nService], { dataViewId: [0, "dataViewId"], onlyFields: [1, "onlyFields"], sortDisabledFields: [2, "sortDisabledFields"], allowPaging: [3, "allowPaging"], allowFilters: [4, "allowFilters"], allowGrouping: [5, "allowGrouping"], allowTimeRangeSelect: [6, "allowTimeRangeSelect"], hideControls: [7, "hideControls"], disableSelection: [8, "disableSelection"], showHeader: [9, "showHeader"], maxItems: [10, "maxItems"], removePadding: [11, "removePadding"], dataViewConfig: [12, "dataViewConfig"] }, null), i0.ɵpad(2, 2), i0.ɵpad(3, 2), i0.ɵpod(4, { data: 0, id: 1 })], function (_ck, _v) { var currVal_0 = "mailbox-entity-alert-dataview"; var currVal_1 = _ck(_v, 2, 0, "title", "severity"); var currVal_2 = _ck(_v, 3, 0, "title", "severity"); var currVal_3 = false; var currVal_4 = false; var currVal_5 = false; var currVal_6 = false; var currVal_7 = true; var currVal_8 = true; var currVal_9 = false; var currVal_10 = 5; var currVal_11 = false; var currVal_12 = _ck(_v, 4, 0, _v.parent.context.ngIf.alerts, "mailbox-entity-alert-dataview"); _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
function View_MailboxAlertsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 8, "fab-shimmer", [], null, null, null, i19.View_FabShimmerComponent_0, i19.RenderType_FabShimmerComponent)), i0.ɵdid(3, 5816320, null, 0, i20.FabShimmerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { isDataLoaded: [0, "isDataLoaded"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_MailboxAlertsComponent_2)), i0.ɵdid(6, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_MailboxAlertsComponent_3)), i0.ɵdid(9, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.ngIf !== _co.loadingSymbol); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_v.context.ngIf == null) ? null : ((_v.context.ngIf.alertsSeveritySummary == null) ? null : _v.context.ngIf.alertsSeveritySummary.hasAlerts)); _ck(_v, 6, 0, currVal_1); var currVal_2 = ((_v.context.ngIf.alerts == null) ? null : _v.context.ngIf.alerts.length); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_MailboxAlertsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MailboxAlertsComponent_1)), i0.ɵdid(2, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i21.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.alertData$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MailboxAlertsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mailbox-alerts", [], null, null, null, View_MailboxAlertsComponent_0, RenderType_MailboxAlertsComponent)), i0.ɵdid(1, 49152, null, 0, i22.MailboxAlertsComponent, [i5.Paris, i17.FeaturesService], null, null)], null, null); }
var MailboxAlertsComponentNgFactory = i0.ɵccf("mailbox-alerts", i22.MailboxAlertsComponent, View_MailboxAlertsComponent_Host_0, { mailbox: "mailbox" }, {}, []);
export { MailboxAlertsComponentNgFactory as MailboxAlertsComponentNgFactory };
