import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { keyBy, mapKeys, mapValues, uniq, values } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { InvestigationPendingType, InvestigationStatus, InvestigationStatusEnum } from '@wcd/domain';
import { ChartSettings, ChartSettingsSeriesItem, LegendItem } from '@wcd/charts';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService, Feature } from '@wcd/config';
import { ServiceUrlsService } from '@wcd/app-config';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { getUserTimeZone } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';

const WIDGET_API_URL = 'dashboard/active_automated_investigations';
const STATUS_MAPPING = {
	running: {
		className: 'primary',
		color: '#0078d4',
		name: 'Running',
		name18nKey: 'activeAutomatedInvestigationsWidget_running',
		status: InvestigationStatusEnum.running,
		legacyStatus: InvestigationStatusEnum.running,
	},
	pending_approval: {
		className: 'warning-dark',
		color: '#F2610C',
		name: 'Pending action',
		name18nKey: 'activeAutomatedInvestigationsWidget_pending_action',
		status: InvestigationStatusEnum.pendingUser,
		legacyStatus: InvestigationStatusEnum.pending,
	},
	pending_resource: {
		className: 'gray-200',
		color: '#D9D9D9',
		name: 'Waiting for device',
		name18nKey: 'activeAutomatedInvestigationsWidget_waiting_for_device',
		status: InvestigationStatusEnum.pendingResource,
		legacyStatus: InvestigationStatusEnum.pending,
	},
};

@Component({
	selector: 'active-automated-investigations-widget',
	template: `
		<div
			class="wcd-flex-horizontal-spaced"
			*ngIf="appConfigService.isAutomatedIrEnabled; else enableAutoIr"
		>
			<wcd-pie-chart
				[data]="data$ | async"
				[hidden]="error$ | async"
				[settings]="activeAutomatedInvestigationPieSettings"
				[allowTitleClick]="true"
				(titleClick)="goToInvestigationsPage()"
				[upperTitle]="pieChartTitle$ | async"
			>
			</wcd-pie-chart>
			<wcd-chart-legend
				[items]="legendItems$ | async"
				[showValues]="true"
				[hidden]="error$ | async"
			></wcd-chart-legend>
		</div>
		<ng-template #enableAutoIr>
			<enable-auto-ir></enable-auto-ir>
		</ng-template>
	`,
})
export class ActiveAutomatedInvestigationsWidget extends ReportWidgetComponent<
	Array<AutomatedInvestigationSeriesItem>
> {
	pieChartTitle$: Observable<string> = this.data$.pipe(
		map((data: Array<AutomatedInvestigationSeriesItem>) => {
			return this.prettyNumberService.prettyNumber(
				data
					? data
							.map((a: AutomatedInvestigationSeriesItem) => <number>a.value)
							.reduce((a, b) => a + b, 0)
					: 0
			);
		})
	);

	legendItems$: Observable<Array<LegendItem>> = this.data$.pipe(
		filter(Boolean),
		map((data: AutomatedInvestigationSeriesItem[]) =>
			data.map((item: AutomatedInvestigationSeriesItem) => ({
				name: item.name,
				value: Number(item.value),
				iconClassName: `color-text-${STATUS_MAPPING[item.id].className}`,
			}))
		)
	);

	get widgetConfig(): ReportWidgetConfig<Array<AutomatedInvestigationSeriesItem>> {
		const rangeInDays: number = this.appConfigService.widgetLookback;
		return Object.assign(
			{
				id: 'activeAutomatedInvestigations',
				name: this.i18nService.get('widget_title_automatedInvestgations'),
				rangeInDays: rangeInDays,
				minHeight: '200px',
			},
			this.appConfigService.isAutomatedIrEnabled
				? {
						parseData: this.parseData.bind(this),
						api: this.featuresService.isEnabled(Feature.AirsApiOffloading)
							? {
									url: () => `${this.serviceUrlsService.threatIntel}/${WIDGET_API_URL}`,
									isExternal: true,
							  }
							: {
									url: WIDGET_API_URL,
									params: {
										range: rangeInDays,
										timezone: getUserTimeZone(),
									},
							  },
				  }
				: {
						loadData: () => of([null]),
				  }
		);
	}

	activeAutomatedInvestigationPieSettings: ChartSettings = {
		columnName: 'name',
		columnValue: 'value',
		options: {
			data: {
				colors: mapValues(
					mapKeys(STATUS_MAPPING, value => value.name18nKey),
					value => value && value.color
				),
				onclick: value => {
					this.goToInvestigationsPage(value);
				},
			},
			tooltip: {
				format: {
					value: (value: string, ratio: number) => value,
				},
			},
			donut: {
				title: this.i18nService.get('activeAutomatedInvestigationsWidget_active'),
			},
			legend: {
				show: false,
			},
			size: {
				width: 250,
				height: 200,
			},
		},
	};

	constructor(
		reportsService: ReportsService,
		private router: Router,
		private paris: Paris,
		private featuresService: FeaturesService,
		private serviceUrlsService: ServiceUrlsService,
		public appConfigService: AppConfigService,
		private prettyNumberService: PrettyNumberService,
		private i18nService: I18nService
	) {
		super(reportsService);
	}

	parseData(data: ActiveAutomatedInvestigationsBackendData): Array<AutomatedInvestigationSeriesItem> {
		return Object.keys(data).map((key: string) => {
			return { id: key, name: this.i18nService.get(STATUS_MAPPING[key].name18nKey), value: data[key] };
		});
	}

	goToInvestigationsPage(status?: AutomatedInvestigationSeriesItem) {
		let investigationStatusStr: string, pendingTypeFilter: string;

		if (status) {
			const statusObj = keyBy(STATUS_MAPPING, 'name')[status.id];
			const investigationStatus: InvestigationStatus = this.paris.getValue(
				InvestigationStatus,
				statusObj.status
			);

			if (this.featuresService.isEnabled(Feature.AirsApiOffloading)) {
				investigationStatusStr = String(investigationStatus.sevilleStatusId);
			} else {
				investigationStatusStr = String(statusObj.legacyStatus);

				if (investigationStatus.isPending) {
					const pendingTypes: Array<InvestigationPendingType> = this.paris
						.getRepository(InvestigationPendingType)
						.entity.values.filter((pendingType: InvestigationPendingType) =>
							investigationStatus.isPendingUser
								? pendingType.isUserPending
								: !pendingType.isUserPending
						);

					pendingTypeFilter = `,pending_type=${pendingTypes
						.map(pendingType => pendingType.id)
						.join('|')}`;
				}
			}
		} else {
			investigationStatusStr = uniq(
				values(STATUS_MAPPING).map(value => {
					const investigationStatus: InvestigationStatus = this.paris.getValue(
						InvestigationStatus,
						value.status
					);
					return this.featuresService.isEnabled(Feature.AirsApiOffloading)
						? investigationStatus.sevilleStatusId
						: value.legacyStatus;
				})
			).join('|');
		}

		this.router.navigate(['/investigations'], {
			queryParams: Object.assign(
				{},
				investigationStatusStr && {
					filters: `status=${investigationStatusStr}${pendingTypeFilter || ''}`,
				},
				{ range: this.widgetConfig.rangeInDays }
			),
		});
	}
}

interface ActiveAutomatedInvestigationsBackendData {
	running: number;
	pending_approval: number;
	pending_resource: number;
}

interface AutomatedInvestigationSeriesItem extends ChartSettingsSeriesItem {
	id: string;
	legacyStatus?: number;
}
