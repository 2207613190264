var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, EntityModelBase, Entity } from '@microsoft/paris';
import { EvaluationSimulationStatus } from './evaluation-simulation-status.entity';
import { EvaluationSimulatorAgent } from './evaluation-model';
var ɵ0 = function (value) { return 'evaluation/simulations'; }, ɵ1 = function (config) { return config.data.serviceUrls.k8s; };
var EvaluationSimulation = /** @class */ (function (_super) {
    __extends(EvaluationSimulation, _super);
    function EvaluationSimulation() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(EvaluationSimulation.prototype, "uniqueKey", {
        get: function () {
            return this.simulationId + "#" + (this.endTime ? this.endTime.toISOString() : '') + this.machineIndex;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'simulationName' }),
        __metadata("design:type", String)
    ], EvaluationSimulation.prototype, "simulationName", void 0);
    __decorate([
        EntityField({ data: 'simulationId' }),
        __metadata("design:type", Number)
    ], EvaluationSimulation.prototype, "simulationId", void 0);
    __decorate([
        EntityField({ data: 'simulator' }),
        __metadata("design:type", String)
    ], EvaluationSimulation.prototype, "simulator", void 0);
    __decorate([
        EntityField({ data: 'machineName' }),
        __metadata("design:type", String)
    ], EvaluationSimulation.prototype, "machineName", void 0);
    __decorate([
        EntityField({ data: 'machineIndex' }),
        __metadata("design:type", Number)
    ], EvaluationSimulation.prototype, "machineIndex", void 0);
    __decorate([
        EntityField({ data: 'status' }),
        __metadata("design:type", EvaluationSimulationStatus)
    ], EvaluationSimulation.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'startTime' }),
        __metadata("design:type", Date)
    ], EvaluationSimulation.prototype, "startTime", void 0);
    __decorate([
        EntityField({ data: 'endTime' }),
        __metadata("design:type", Date)
    ], EvaluationSimulation.prototype, "endTime", void 0);
    __decorate([
        EntityField({ data: 'userName' }),
        __metadata("design:type", String)
    ], EvaluationSimulation.prototype, "userName", void 0);
    EvaluationSimulation = __decorate([
        Entity({
            singularName: 'Evaluation simulation',
            pluralName: 'Evaluation simulations',
            endpoint: 'evaluation/simulations',
            allItemsProperty: 'simulations',
            loadAll: false,
            parseSaveQuery: ɵ0,
            saveMethod: 'POST',
            baseUrl: ɵ1,
        })
    ], EvaluationSimulation);
    return EvaluationSimulation;
}(EntityModelBase));
export { EvaluationSimulation };
export { ɵ0, ɵ1 };
