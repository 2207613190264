import { OnboardingBackendService } from './onboarding.backend.service';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { AppConfigService } from '@wcd/app-config';
import { tap } from 'rxjs/operators';
import { SessionStorageService } from '@wcd/shared';
var DISMISS_WIDGET_STORAGE_KEY = 'onboarding-widget-dismissed';
var OnboardingService = /** @class */ (function () {
    function OnboardingService(onboardingBackendService, appConfigService, appInsights, sessionStorageService) {
        this.onboardingBackendService = onboardingBackendService;
        this.appInsights = appInsights;
        this.sessionStorageService = sessionStorageService;
        this._onboardingWidgetDismissed =
            appConfigService.onboardingTileDismissed ||
                !!this.sessionStorageService.getItem(DISMISS_WIDGET_STORAGE_KEY);
    }
    Object.defineProperty(OnboardingService.prototype, "onboardingWidgetDismissed", {
        get: function () {
            return this._onboardingWidgetDismissed;
        },
        enumerable: true,
        configurable: true
    });
    OnboardingService.prototype.dismissOnboardingWidget = function (endpointOnboardingStatus, trackEvent) {
        var _this = this;
        if (trackEvent === void 0) { trackEvent = true; }
        if (trackEvent) {
            this.trackOnboardingWidgetEvent(true, endpointOnboardingStatus);
        }
        this._onboardingWidgetDismissed = true;
        this.sessionStorageService.setItem(DISMISS_WIDGET_STORAGE_KEY, '1');
        return this.onboardingBackendService
            .dismissOnboardingWidget()
            .pipe(tap(null, function (err) { return (_this._onboardingWidgetDismissed = false); }));
    };
    OnboardingService.prototype.trackOnboardingWidgetEvent = function (isDecline, endpointOnboardingStatus) {
        this.appInsights.trackEvent("EndpointOnboarding-DashboardTile" + (isDecline ? 'Dismiss' : 'Button'), {
            Page: 'DashboardTile',
            RanTestAlert: endpointOnboardingStatus.RanTestAlert,
            MachineOnboarded: endpointOnboardingStatus.MachineOnboarded,
        });
    };
    return OnboardingService;
}());
export { OnboardingService };
