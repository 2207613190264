import { AirsNotificationRuleTypeModel } from './airs-notification-rule-type.model';
import { AirsNotificationRuleTypesService } from '../services/airs-notification-rule-types.service';
import {
	AirsNotificationRuleConditionConfig,
	AirsNotificationRuleConditionModel,
} from './airs-notification-rule-condition.model';
import { AirsNotificationRuleConditionGroupsStore } from '../services/airs-notification-rule-condition-groups.store';
import { AirsNotificationRuleConditionGroupModel } from './airs-notification-rule-condition-group.model';
import { AirsNotificationRuleReportSchedule } from './airs-notification-rule-report-schedule';
import { Identifiable } from '../../../data/models/identifiable.model';
import { merge } from 'lodash-es';

export class AirsNotificationRuleModel extends Identifiable<number> {
	emails: Array<string>;
	email: string;
	body: string;
	private _type: AirsNotificationRuleTypeModel;
	conditions: Array<AirsNotificationRuleConditionModel>;
	reportSchedule: AirsNotificationRuleReportSchedule;

	get ruleId() {
		return `${this.type.requireSchedule ? 'report' : 'rule'}_${this.id}`;
	}

	get type(): AirsNotificationRuleTypeModel {
		return this._type;
	}

	set type(value: AirsNotificationRuleTypeModel) {
		this._type = value;
		this.conditions = [];

		if (value.allowConditions) {
			if (value.allowConditions) this.addCondition();
		}
	}

	get isValid(): boolean {
		if (
			!this.name ||
			(!this.emails.length && !this.email) ||
			(this.type.allowConditions && (!this.conditions || !this.conditions.length))
		)
			return false;

		if (this.type.allowConditions) {
			const conditionsAreValid = this.conditions.every(condition => condition.isValid);

			if (!conditionsAreValid) return false;

			if (!this.conditions.some(condition => !condition.isEmpty)) return false;
		}

		return !(this.type.requireSchedule && !this.reportSchedule.isValid);
	}

	constructor(
		private notificationRuleConditionGroupsStore: AirsNotificationRuleConditionGroupsStore,
		data?: AirsNotificationRuleConfig
	) {
		super(data, [notificationRuleConditionGroupsStore]);

		if (!data) {
			this.type = AirsNotificationRuleTypesService.default;
			this.emails = [];

			this.reportSchedule = new AirsNotificationRuleReportSchedule();
		}
	}

	setData(
		data: AirsNotificationRuleConfig,
		notificationRuleConditionGroupsStore: AirsNotificationRuleConditionGroupsStore
	) {
		super.setData(data);

		this.emails = data.emails;
		this.body = data.body;
		this._type =
			data.notification_type === undefined
				? AirsNotificationRuleTypesService.reportsNotificationRuleType
				: AirsNotificationRuleTypesService.getNotificationRuleTypeById(data.notification_type);

		if (data.rule)
			this.conditions = data.rule.map(
				conditionData =>
					new AirsNotificationRuleConditionModel(
						notificationRuleConditionGroupsStore,
						conditionData
					)
			);

		this.reportSchedule = new AirsNotificationRuleReportSchedule(this.type.requireSchedule ? data : null);
	}

	getBackendData(): any {
		const conditions: { [index: string]: Array<any> } = {};
		if (this.type.allowConditions) {
			this.conditions
				.filter(function(condition) {
					return !condition.isEmpty;
				})
				.forEach(function(condition) {
					conditions[condition.group.id] = condition.values;
				});
		}

		const backendData = {
			name: this.name,
			emails: this.emails,
		};

		if (this.email && !~backendData.emails.indexOf(this.email))
			backendData.emails.splice(0, 0, this.email);

		if (this.type.requireSchedule) merge(backendData, this.reportSchedule.getBackendData());
		else {
			merge(backendData, {
				notification_type: this.type.id,
				rule: conditions,
			});
		}

		return backendData;
	}

	addCondition(): void {
		this.conditions.push(
			new AirsNotificationRuleConditionModel(this.notificationRuleConditionGroupsStore)
		);
	}

	removeCondition(condition: AirsNotificationRuleConditionModel): void {
		const conditionIndex = this.conditions.indexOf(condition);
		if (~conditionIndex) this.conditions.splice(conditionIndex, 1);
	}

	containsGroup(group: AirsNotificationRuleConditionGroupModel): boolean {
		if (!group || !this.type.allowConditions) return false;

		return this.conditions.some(condition => condition.group && condition.group.id === group.id);
	}

	updateEmail(): boolean {
		if (this.email) {
			const emailExists = ~this.emails.indexOf(this.email);

			if (!emailExists) this.emails.splice(0, 0, this.email);

			this.email = '';

			return !emailExists;
		}

		return false;
	}

	removeEmail(email: string) {
		const emailIndex = this.emails.indexOf(email);

		if (~emailIndex) this.emails.splice(emailIndex, 1);
	}
}

export interface AirsNotificationRuleConfig {
	id: number;
	name: string;
	emails: Array<string>;
	body?: string;
	notification_type?: number;
	rule?: Array<AirsNotificationRuleConditionConfig>;
	report_type?: Array<number>;
	report_period?: number;
	repeats?: number;
	repeat_period?: number;
	repeat_day?: Array<number>;
	time?: string;
}

interface AirsNotificationRuleBackendData {
	name: string;
	emails: Array<string>;
	notification_type: number;
	rule: { [index: string]: Array<any> };
}
