import { SystemExclusionTypeEnum } from './system-exclusion-type.enum';

export const systemExclusionTypeValues = [
	{
		id: SystemExclusionTypeEnum.files,
		name: 'File Hash',
		icon: 'file',
		hideIfPhase1OffloadingEnabled: true,
		properties: [
			{
				id: 'filename',
				name: 'File Name',
				isRequired: true,
				editable: true,
			},
			{
				id: 'file_hash_type',
				name: 'Hash Type',
				editable: false,
			},
			{
				id: 'file_hash',
				name: 'File Hash',
				className: 'break',
				isRequired: true,
				pattern: 'fileHash',
				patternError:
					'Hash must be exactly 32, 40 or 64 characters long and contain only the letters A-F or numbers',
				custom: {
					testProperty: true,
				},
				editable: true,
			},
			{ id: 'product_vendor', name: 'Vendor', editable: true },
			{ id: 'product_name', name: 'Application', editable: true },
			{ id: 'product_version', name: 'Version', editable: true },
		],
	},
	{
		id: SystemExclusionTypeEnum.certificate,
		name: 'Certificate',
		icon: 'entities.certificate',
		hideIfPhase2OffloadingEnabled: true,
		properties: [
			{ id: 'subject', name: 'Subject', editable: false },
			{ id: 'issuer', name: 'Issuer', editable: false },
			{
				id: 'cert_sha256',
				name: 'SHA256',
				editable: false,
				custom: { testProperty: true },
			},
			{ id: 'serial', name: 'Serial', isRequired: true, editable: false },
		],
		file: {
			id: 'certificate',
			name: 'Certificate File',
			placeholder: 'Please select a PEM certificate file:',
			type: 'file',
		},
	},
	{
		id: SystemExclusionTypeEnum.ip,
		icon: 'entities.ipaddress',
		name: 'IP Address',
		hideIfPhase1OffloadingEnabled: true,
		properties: [
			{
				id: 'ip',
				name: 'IP Address',
				isRequired: true,
				pattern: 'ipOrSegment',
				placeholder: 'IP Address or segment',
				patternError: 'Invalid IP address or segment',
				descriptionKey: 'exclusion.ip.segment.description',
				custom: { testProperty: true },
				editable: true,
			},
		],
	},
	// {
	// 	id: "dns",
	// 	icon: "domain",
	// 	name: "DNS",
	// 	properties: [
	// 		{
	// 			id: "dns",
	// 			name: "DNS",
	// 			isRequired: true,
	// 			editable: true,
	// 			custom: { testProperty: true }
	// 		}
	// 	]
	// },
	// {
	// 	id: "email",
	// 	icon: "email",
	// 	name: "Email",
	// 	properties: [
	// 		{
	// 			id: "address",
	// 			name: "Email Address",
	// 			isRequired: true,
	// 			pattern: "email",
	// 			patternError: "Invalid Email address",
	// 			custom: { testProperty: true },
	// 			editable: true
	// 		}
	// 	]
	// },
	{
		id: SystemExclusionTypeEnum.memory_content,
		icon: 'memory',
		name: 'Process Memory',
		readonly: true,
		hideIfEmpty: true,
		properties: [
			{
				id: 'hash_value',
				name: 'Hash',
				className: 'truncate',
				isRequired: true,
				custom: {
					testProperty: true,
				},
				editable: true,
			},
			{
				id: 'actual_size',
				name: 'Size',
				isRequired: true,
				editable: true,
			},
			{
				id: 'process_name',
				name: 'Process Name',
				editable: true,
			},
		],
	},
];
