import { Injectable } from '@angular/core';
import { DataviewField } from '@wcd/dataview';
import { EvidenceEntity, RemediationStatusType } from '@wcd/domain';
import { DataSet, Paris } from '@microsoft/paris';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { iconLibraries, IconsService, WcdIconNames } from '@wcd/icons';
import { EvidenceDetectionContextMultiComponent } from '../components/evidence-detection-context-multi.component';
import { FilterValuesChecklistWithSearchComponent } from '@wcd/ng-filters';
import { EVIDENCE_FIELD_IDS, EvidenceService } from './evidence.service';
import { map } from 'rxjs/operators';
import { ColoredDotIndicationFieldComponent } from '../../../shared/components/colored-dot-indication-field.component';
import { I18nService } from '@wcd/i18n';
import { EvidenceStatusDetailsComponent } from '../components/evidence-status-details.component';
import { Observable } from 'rxjs';
import { flatMap } from 'lodash-es';
import { ThreatsDisplayNameService } from '../../mtp_investigations/services/threats-display-name-field.service';

@Injectable()
export class EvidenceFieldsService {
	constructor(
		private paris: Paris,
		private iconsService: IconsService,
		private evidenceService: EvidenceService,
		private i18nService: I18nService,
		private threatsDisplayNameService: ThreatsDisplayNameService
	) {}

	getFields(evidence$: Observable<DataSet<EvidenceEntity>>) {
		return DataviewField.fromList<EvidenceEntity>([
			{
				id: EVIDENCE_FIELD_IDS.firstSeen,
				name: this.i18nService.strings.evidence_fields_firstSeen,
				component: {
					type: TzDateComponent,
					getProps: (evidence) => ({ date: evidence.firstSeen }),
				},
				className: 'nowrap wcd-text-overflow-small',
			},
			{
				id: EVIDENCE_FIELD_IDS.name,
				name: this.i18nService.strings.evidence_fields_name,
				getDisplay: (evidence) => evidence.name,
				icon: {
					wcdIcon: (evidence) => {
						if (!evidence.entityType) {
							return;
						}
						const icon = this.iconsService.getIcon(evidence.entityType.icon);
						return icon.library === iconLibraries.wcd ? WcdIconNames[icon.name] : null;
					},
					fabricIcon: (evidence) => {
						if (!evidence.entityType) {
							return;
						}
						const icon = this.iconsService.getIcon(evidence.entityType.icon);
						return icon.library === iconLibraries.fabric ? icon.name : null;
					},
				},
				filter: {
					component: {
						type: FilterValuesChecklistWithSearchComponent,
						config: {
							search: {
								method: (term: string) =>
									evidence$.pipe(
										map((evidence) =>
											Array.from(
												new Set(
													evidence.items
														.map((ev) => ev.name)
														.filter(
															(name) =>
																name &&
																name
																	.toLowerCase()
																	.includes(term.toLowerCase())
														)
												)
											).map((name) => ({ value: name }))
										)
									),
							},
						},
					},
				},
				className: 'nowrap wcd-text-overflow-medium',
				getTooltip: (evidence) => evidence.name,
			},
			{
				id: EVIDENCE_FIELD_IDS.verdict,
				name: this.i18nService.strings.evidence_fields_verdict,
				getDisplay: (evidence) => evidence.verdict && evidence.verdict.name,
				className: 'nowrap wcd-text-overflow-small',
			},
			{
				id: EVIDENCE_FIELD_IDS.state,
				name: this.i18nService.strings.evidence_fields_state,
				component: {
					type: ColoredDotIndicationFieldComponent,
					getProps: (evidence) => ({
						// status should be empty in case of 'Active' remediation status (see bug: https://microsoft.visualstudio.com/OS/_workitems/edit/31830546/)
						fieldData: {
							name:
								evidence.state && evidence.state.type !== RemediationStatusType.Active
									? evidence.state.name
									: '',
							color:
								evidence.state && evidence.state.type !== RemediationStatusType.Active
									? evidence.state.className
									: '',
						},
					}),
				},
				className: 'nowrap wcd-text-overflow-small',
			},
			{
				id: EVIDENCE_FIELD_IDS.statusDetails,
				name: this.i18nService.strings.evidence_fields_statusDetails,
				component: {
					type: EvidenceStatusDetailsComponent,
					getProps: (evidence) => ({ items: evidence.statusDetails }),
				},
				enabledByDefault: false,
			},
			{
				id: EVIDENCE_FIELD_IDS.assets,
				name: this.i18nService.strings.evidence_fields_assets,
				className: 'nowrap wcd-text-overflow-small',
				getDisplay: (evidence) => evidence.assetsFieldName,
				getTooltip: (evidence) => evidence.assetsFieldName,
				icon: {
					fabricIcon: (evidence) => {
						const firstAssetType =
							evidence.assets &&
							evidence.assets.length &&
							evidence.assets[0] &&
							evidence.assets[0].assetType;
						if ((evidence.assets || []).some((a) => a.assetType !== firstAssetType)) {
							return null;
						}
						return firstAssetType ? firstAssetType.icon : null;
					},
				},
				filter: {
					component: {
						type: FilterValuesChecklistWithSearchComponent,
						config: {
							search: {
								method: (term: string) =>
									evidence$.pipe(
										map((evidence) => {
											const lowerTerm = term.toLowerCase();
											return Array.from(
												new Set(
													flatMap(
														evidence.items
															.map(
																(ev) =>
																	ev.assets &&
																	ev.assets.map(
																		(a) =>
																			a.displayName &&
																			a.displayName.toLowerCase()
																	)
															)
															.filter((names) => names && names.length)
													)
												)
											)
												.filter((name) => name.includes(lowerTerm))
												.map((name) => ({ value: name }));
										})
									),
							},
						},
					},
				},
			},
			{
				id: EVIDENCE_FIELD_IDS.detectionContexts,
				name: this.i18nService.strings.evidence_fields_detectionContext,
				className: 'nowrap wcd-text-overflow-medium',
				component: {
					type: EvidenceDetectionContextMultiComponent,
					getProps: (evidence) => ({
						detectionContexts: evidence.detectionContexts,
					}),
				},
				filter: {
					component: {
						type: FilterValuesChecklistWithSearchComponent,
						config: {
							search: {
								method: (term: string) =>
									evidence$.pipe(
										map((evidence) =>
											Array.from(
												new Set(
													flatMap(
														evidence.items.map((ev) => {
															const names = (
																ev.detectionContexts || []
															).map((c) =>
																this.evidenceService.getEvidenceDetectionContextDisplayName(
																	c
																)
															);
															return names.filter((name) =>
																name
																	.toLowerCase()
																	.includes(term.toLowerCase())
															);
														})
													)
												)
											).map((name) => ({ value: name }))
										)
									),
							},
						},
					},
				},
			},
			{
				id: EVIDENCE_FIELD_IDS.threatsDisplayName,
				name: this.i18nService.strings.evidence_fields_threats,
				getDisplay: (evidence) => {
					return (
						this.threatsDisplayNameService.getThreatsDisplayName(evidence.threatsI18nKeys) ||
						evidence.threatsDisplayName
					);
				},
				enabledByDefault: false,
			},
		]);
	}
}
