import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { MachineGroup } from './machine-group.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'User Exposed RBAC Group',
	pluralName: 'User Exposed RBAC Groups',
	endpoint: 'UserExposedRbacGroups',
	allItemsEndpointTrailingSlash: false,
	readonly: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class UserExposedRbacGroups extends EntityModelBase {
	@EntityField({
		data: '__self',
		parse: data => {
			if (data && data instanceof Array) {
				if (data instanceof Array) {
					return data.map(group => new MachineGroup({ id: group.id, name: group.label }));
				} else {
					return [];
				}
			} else if (data && data.id && data.label) {
				// An object of type { id: number, label: string } - representing the machine group
				// This is needed for datatview being built in TVM. See this PR: I included screenshots.https://microsoft.visualstudio.com/DefaultCollection/WDATP/_git/WcdPortal/pullrequest/4525249
				return new MachineGroup({ id: data.id, name: data.label });
			}
		},
	})
	groups: Array<MachineGroup>;
}
