var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityHeaderBottomComponentBase } from './entity-header-bottom.component.base';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { TvmColorService, TvmColor } from '../../../tvm/services/tvm-color.service';
var BaselineProfileHeaderBottomComponent = /** @class */ (function (_super) {
    __extends(BaselineProfileHeaderBottomComponent, _super);
    function BaselineProfileHeaderBottomComponent(globalEntityTypesService, i18nService, tvmColorService) {
        var _this = _super.call(this, globalEntityTypesService, i18nService) || this;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.i18nService = i18nService;
        _this.tvmColorService = tvmColorService;
        return _this;
    }
    BaselineProfileHeaderBottomComponent.prototype.setEntity = function (entity) {
        _super.prototype.setEntity.call(this, entity);
        this.status = this.entity.status;
        this.display = this.status
            ? this.i18nService.strings.tvm_baseline_profiles_enabled
            : this.i18nService.strings.tvm_baseline_profiles_disabled;
        var iconColorName = this.tvmColorService.colorsMap.get(this.status ? TvmColor.Green : TvmColor.NeutralTertiary).name;
        this.iconClass = "ms-fontColor-" + iconColorName;
    };
    return BaselineProfileHeaderBottomComponent;
}(EntityHeaderBottomComponentBase));
export { BaselineProfileHeaderBottomComponent };
