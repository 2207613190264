import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	ViewChild,
	ContentChild,
	ViewChildren,
	QueryList,
	OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { PanelMenuAction } from '../models/panel-settings.model';
import { PanelType } from '../models/panel-type.enum';
import { Panel } from '../models/panel.model';
import { PanelService } from '../services/panels.service';

let lastId = 0;
const wcdPanelManger: PanelComponent[] = [];

@Component({
	selector: 'wcd-panel',
	templateUrl: './panel.component.html',
	styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
	@Output() close: EventEmitter<any> = new EventEmitter();
	@Output() startClose: EventEmitter<void> = new EventEmitter<void>();
	@Output() onMenuActionClick: EventEmitter<PanelMenuAction> = new EventEmitter<PanelMenuAction>();
	@Output() previous: EventEmitter<void> = new EventEmitter<void>();
	@Output() next: EventEmitter<void> = new EventEmitter<void>();
	@Output() isNotFocused: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() canDeactivate: () => Observable<boolean>;
	@Input() isOpen: boolean = true;
	@Input() disableTrapFocus: boolean = false;
	@Input() disableAutoFocus: boolean = false;
	@Input() filterData?: Observable<any>;
	@Input() disableHeaderRole: boolean = false;
	@Input() panelBodyClassName: string;
	@Input()
	set settings(settings: Panel) {
		this.setSettings(settings);
	}

	private tryKeepFocusOnCurrent = false;
	get settings(): Panel {
		return this._settings;
	}

	get panelTypeName(): string {
		return this.settings && PanelType[this.settings.type];
	}

	get selectableActions(): Array<PanelMenuAction> {
		return (
			this.settings &&
			this.settings.menuActions &&
			this.settings.menuActions.filter(action => action.isSelectable)
		);
	}

	isInit: boolean = false;
	isFocused: boolean = false;
	private _settings: Panel;

	binnedFocusOnFirstfocusable = this.focusOnFirstfocusable.bind(this);

	@HostListener('window:keydown', ['$event'])
	onKeydown($event: KeyboardEvent): boolean {
		if ($event.keyCode === 27) {
			// ESCAPE
			$event.preventDefault();
			$event.stopPropagation();
			$event.stopImmediatePropagation();
			this.panelService.panelRef[this.panelService.activeInstances - 1].instance.closePanel();
			return false;
		}

		return true;
	}

	readonly TITLE_ELEMENT_ID = `side-panel-header-container-${lastId++}`;
	@ViewChild('overlay', { static: true }) readonly panelOverlay: ElementRef<HTMLElement>;
	@ContentChild('panelHeader', { static: false }) panelHeader: ElementRef<HTMLElement>;
	@ViewChild('panelHiddenHeader', { static: true }) panelHiddenHeader: ElementRef<HTMLElement>;
	@ViewChildren('focusable') focusable: QueryList<ElementRef>;

	titleId: string = '';
	isFloatingFeedbackWidget: boolean;

	headerRole =  "heading";
	headerAriaLevel = 1;
	constructor(
		protected elementRef: ElementRef<HTMLElement>,
		private changeDetectorRef: ChangeDetectorRef,
		private panelService: PanelService,
	) {}

	ngOnInit() {
		if (this.filterData) {
			this.isNotFocused.emit(true);
		}
		if (this.disableHeaderRole) {
			this.headerRole = null;
			this.headerAriaLevel = null;
		}
	}

	setSettings(settings: Panel): void {
		this._settings = settings;
		this.isFocused = false;
		setTimeout(() => {
			this.changeDetectorRef.detectChanges();

			setTimeout(() => {
				this.isInit = true;
				let headerElementId: string = '';
				if (this.panelHeader && this.panelHeader.nativeElement) {
					const headerElement = this.panelHeader.nativeElement;
					if (!headerElement.id) {
						headerElementId = 'panelHeader_' + Date.now();
						headerElement.id = headerElementId;
					} else {
						headerElementId = headerElement.id;
					}
				} else {
					// The id option is a fallback, since not all element can be found using the ContentChildren (for some reason...)
					if (settings.headerElementId) {
						const panelHeaderById = this.elementRef.nativeElement.querySelector(
							`#${settings.headerElementId}`
						);
						if (panelHeaderById) {
							headerElementId = panelHeaderById.id;
						} else {
							headerElementId = this.panelHiddenHeader.nativeElement.id;
						}
					} else if (this.settings.headerText) {
						headerElementId = this.TITLE_ELEMENT_ID;
					} else {
						headerElementId = this.panelHiddenHeader.nativeElement.id;
					}
				}
				this.titleId = headerElementId;
				this.changeDetectorRef.detectChanges();

				if (!this.disableAutoFocus) {
					this.focusOnFirstfocusable();
				}
			}, 40);
		});
	}

	public closePanel(fromOverlay: boolean = false) {
		if (fromOverlay && this.settings.disableOverlayClick) return false;

		if (this.canDeactivate) {
			return this.canDeactivate().subscribe(
				(_canDeactivate: boolean) => {
					if (_canDeactivate) this.doClosePanel();
					else return false;
				},
				error => {
					return false;
				}
			);
		}

		this.doClosePanel();
	}

	private doClosePanel() {
		this.isOpen = false;
		this.startClose.emit();
		this.changeDetectorRef.detectChanges();
		setTimeout(() => {
			this.close.emit();
		}, 200);
	}

	previousClick() {
		this.tryKeepFocusOnCurrent = true;
		if (!this.settings.previous.isDisabled) this.settings.previous.onClick();
	}

	nextClick() {
		this.tryKeepFocusOnCurrent = true;
		if (!this.settings.next.isDisabled) this.settings.next.onClick();
	}

	back() {
		this.tryKeepFocusOnCurrent = true;
		if (this.settings.back.onClick) this.settings.back.onClick();
		else window.history.back();
	}

	overlayClicked(event: Event) {
		if (event.target === this.panelOverlay.nativeElement) {
			this.closePanel(true);
		}
	}

	private focusOnFirstfocusable() {
		const focusable = this.focusable.filter(element => (!element.nativeElement.disabled))
		let focused = focusable.find(elm=>elm.nativeElement == document.activeElement)
		if (this.tryKeepFocusOnCurrent && focused) {
			focused.nativeElement.blur();
		}
		else {
			focused = this.focusable.filter(element => !element.nativeElement.disabled)[0];
			this.isFocused = true;
		}
		if (!focused) return;
		this.tryKeepFocusOnCurrent = false;
		if (this.filterData) {
			focused.nativeElement.focus();
			setTimeout(() => {
				this.isNotFocused.emit(false);
			}, 150)
		} else {
			setTimeout(()=>{
				focused.nativeElement.focus();
			},150)
		}
	}
}
