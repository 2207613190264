
		<wcd-buttons-bar-widget
			*ngIf="appConfigService.isAutomatedIrEnabled; else enableAutoIr"
			[buttons]="data$ | async"
			[attr.data-track-component]="widgetConfig.id"
			[hidden]="error$ | async"
		></wcd-buttons-bar-widget>
		<ng-template #enableAutoIr>
			<enable-auto-ir></enable-auto-ir>
		</ng-template>
	