import { Injectable } from '@angular/core';
import { ServiceUrlsService } from '@wcd/app-config';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Paris } from '@microsoft/paris';
import {
	AgentEndpointsManagementState,
	AgentOnboardingStatus,
	SenseEndpointsManagementState,
} from '@wcd/domain';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AppConfigService } from '@wcd/app-config';
import { find } from 'lodash-es';
import { PollingService } from '@wcd/config';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { AndroidEndpointManagementApiCall } from '../../../../../../packages/@wcd/domain/src/endpoint_management/android-endpoint-management.api-call';
import { PackageFile } from '../../../../../../packages/@wcd/domain/src/endpoint_management/package-file.value-object';
import { AppInsightsService } from '../../insights/services/app-insights.service';

const SENSE_ONBOARDING_STATUS_REFRESH_RATE = 5 * 60000;
const AGENT_ONBOARDING_STATUS_REFRESH_RATE = 1500;

@Injectable()
export class EndpointManagementService {
	agentOnboardingStatuses: Array<AgentOnboardingStatus>;

	constructor(
		private paris: Paris,
		private httpClient: HttpClient,
		private serviceUrlsService: ServiceUrlsService,
		private appConfigService: AppConfigService,
		private pollingService: PollingService,
		private dialogsService: DialogsService,
		private i18n: I18nService,
		private appInsights: AppInsightsService
	) {
		this.agentOnboardingStatuses = this.paris.getRepository(AgentOnboardingStatus).entity.values;
	}

	downloadSensePackage(onboarding: boolean, packageId: number): Observable<string> {
		return this.httpClient.get<string>(
			`${this.serviceUrlsService.packages}/Download${onboarding ? 'On' : 'Off'}boardingPackage`,
			{ params: { mgmtTool: String(packageId) } }
		);
	}

	getSenseOnboardingState(): Observable<SenseEndpointsManagementState> {
		return this.pollingService
			.poll(0, SENSE_ONBOARDING_STATUS_REFRESH_RATE)
			.pipe(mergeMap(() => this.paris.getItemById(SenseEndpointsManagementState, null)));
	}

	getAgentOnboardingStatusByType(typeName: string): AgentOnboardingStatus {
		return find(
			this.agentOnboardingStatuses,
			(status: AgentOnboardingStatus) => status.type === typeName
		);
	}

	getAgentOnboardingState(): Observable<AgentEndpointsManagementState> {
		return this.pollingService
			.poll(0, AGENT_ONBOARDING_STATUS_REFRESH_RATE)
			.pipe(mergeMap(() => this.paris.getItemById(AgentEndpointsManagementState, null)));
	}

	turnOnServerMonitoring(): Observable<any> {
		return this.httpClient.post<any>(
			`${this.serviceUrlsService.serveronboarding}/StartOmsProvisioning`,
			undefined
		);
	}

	downloadApk(fileName: string) {
		this.getApkUrl(fileName).subscribe(
			(packageFiles: PackageFile[]) => {
				if (packageFiles.length > 0) {
					window.open(packageFiles[0].url, '_blank');
					this.appInsights.trackEvent('AndroidEndpointOnboarding-DownloadPackage',{
						ResponseStatus:200,
					});
				} else {
					this.dialogsService.showError({
						title: this.i18n.get('endpointManagement.download.package.failed'),
						data: this.i18n.get('endpointManagement.download.package.from.server.failed'),
					});
				}
			},
			(error: HttpErrorResponse) => {
				this.appInsights.trackEvent('AndroidEndpointOnboarding-DownloadPackage',{
					ResponseStatus:error.status,
					FailureType:'API',
				});

				this.dialogsService.showError({
					title: this.i18n.get('endpointManagement.download.package.failed'),
					data: error,
				});
			}
		);
	}

	getApkUrl(fileName: string): Observable<PackageFile[]> {
		return this.paris.apiCall(AndroidEndpointManagementApiCall, fileName);
	}
}
