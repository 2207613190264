<!-- tslint:disable:template-accessibility-alt-text -->
<!-- tslint:disable:template-accessibility-label-for -->
<wcd-panel (close)="destroy()" [disableAutoFocus]="true" [disableHeaderRole]="true" [settings]="settings" class="ask-the-expert"
		   panelBodyClassName="wcd-min-height-large">
	<div class="panel-header" sidePanel-top-content
		 *ngIf="bilbaoLicenseState == ContentStates.Complete && showBanner">
		<wcd-expander
			*ngIf="isNarrowLayout"
			[isExpanded]="true"
			arrowPosition="right">
			<!-- make the banner collapsible on narrow screens -->
			<ng-container *ngTemplateOutlet="banner"></ng-container>
		</wcd-expander>
		<ng-container *ngIf="!isNarrowLayout">
			<ng-container *ngTemplateOutlet="banner"></ng-container>
		</ng-container>
	</div>

	<wcd-contents-state [contentState]="bilbaoLicenseState" class="wcd-full-height content-stage">
		<ng-container loading *ngTemplateOutlet="loading"></ng-container>
		<ng-container error *ngTemplateOutlet="errorOrEmpty"></ng-container>
		<ng-container complete *ngTemplateOutlet="expertPanel"></ng-container>
		<ng-container empty *ngTemplateOutlet="errorOrEmpty"></ng-container>
	</wcd-contents-state>
</wcd-panel>

<ng-template #loading>
	<div class="wcd-full-height wcd-flex-center-all">
		<fab-spinner></fab-spinner>
	</div>
</ng-template>

<ng-template #errorOrEmpty>
	<fab-message-bar
		[messageBarType]="messageBarType">
		<div class="wcd-font-weight-bold">{{'threatExpert.error.title' | i18n}}</div>
		<div class="wcd-margin-small-top">{{'threatExpert.error.message' | i18n}}</div>
	</fab-message-bar>
</ng-template>

<ng-template #expertPanel>
	<div class="wcd-padding-horizontal wcd-full-height">
		<form class="wcd-auto-height-responsive dialog-contents wcd-flex-vertical"
			  #threatExpertForm="ngForm"
			  (submit)="submitRequest()" data-track-component="AskTheExpertComponent">
			<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-medium-right">
				<!--The label tag was added in order to solve this bug: https://dev.azure.com/microsoft/OS/_workitems/edit/28137526/-->
				<!--Heading should be next to some meaningful content in order to be parsed correctly and recognized by screen readers.-->
				<label for="threat-expert-header"></label>
				<h2 id="threat-expert-header" class="wcd-font-size-xxl wcd-font-weight-bold">
					{{ 'threatExpert.body.title' | i18n }}
				</h2>
				<div *ngIf="isSaving || hasSubmitted" class="wcd-margin-largeMedium-top">
					<fab-message-bar [messageBarType]="messageBarType">
						{{ messageBarText }}
					</fab-message-bar>
				</div>
				<div class="wcd-padding-largeMedium-top wcd-padding-small-bottom">
					<markdown>
						{{ 'threatExpert.body.description' | i18n }}
					</markdown>
				</div>
				<div class="wcd-full-width wcd-padding-vertical"
					 [rbac]="{ permissions: ['securitySettings'], state: 'disabled' }">
					<label id="topic" class="required wcd-font-weight-bold">
						{{ 'thereatExpert.form.topic' | i18n}}
					</label>
					<input type="text" id="mte-cause"
						   required name="cause" [(ngModel)]="cause"
						   class="form-control wcd-full-width wcd-margin-small-top"
						   [disabled]="isFormDisabled"
						   [rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
						   aria-labelledby="topic"/>
				</div>
				<div class="wcd-flex-1">
					<textarea class="form-control wcd-no-resize" rows="8" required
							  autofocus name="mteMessage" [(ngModel)]="message"
							  [placeholder]="formBodyPlaceholder"
							  [rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
							  [disabled]="isFormDisabled"></textarea>
				</div>
				<label for="mte-email" class="required wcd-padding-large-top wcd-font-weight-bold">
					{{ 'email' | i18n }}
				</label>
				<div>{{ 'threatExpert.emailLabel' | i18n }}</div>
				<input type="email" id="mte-email" validateRegExp="emailWithSpecialChars" #emailControl="ngModel" name="mteEmail"
					   class="form-control wcd-full-width wcd-margin-small-top"
					   [placeholder]="'researcherAssistance.emailPlaceholder' | i18n" [(ngModel)]="email"
					   [required]="true"
					   [disabled]="isFormDisabled" [rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"/>
				<div class="error-message" [class.invisible]="emailControl.pristine || !email || emailControl.valid">
					{{ 'feedback.invalidEmail' | i18n }}
				</div>
			</div>
			<div class="wcd-flex-none">
				<footer
					class="wcd-flex-horizontal wcd-full-width wcd-margin-large-top wcd-margin-bottom">
					<div class="wcd-flex-1" [rbac]="{ permissions: ['securitySettings'], state: 'disabled' }">
						<fab-primary-button
							data-track-id="Submit"
							data-track-type="Button"
							[contentClass]="isSaving ? 'wcd-width-small' : ''"
							[text]="isSaving ? '' : ('buttons.submit' | i18n)"
							(onClick)="submitRequest()"
							[disabled]="(!threatExpertForm.form.valid || isFormDisabled) && !isSaving">
							<fab-spinner *ngIf="isSaving" [size]="SpinnerSize.xSmall"></fab-spinner>
						</fab-primary-button>
					</div>
					<div class="wcd-flex-none">
						<markdown [data]="'threatExpert.form.privacyStatement' | i18n"></markdown>
					</div>
				</footer>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #banner>
	<div class="wcd-flex-horizontal wcd-flex-center-vertical"
		 [focusOnFirst]="true" >
		<div
			class="banner-container wcd-flex-2 wcd-font-size-s "
			[ngClass]="{
				'wcd-padding-large-vertical wcd-padding-large-left wcd-padding-mediumSmall-right' : !isNarrowLayout,
				'wcd-padding-largeMedium-bottom wcd-padding-largeMedium-horizontal' : isNarrowLayout
			}"
		>
			<!--The label tag was added in order to solve this bug: https://dev.azure.com/microsoft/OS/_workitems/edit/28137526/-->
			<!--Heading should be next to some meaningful content in order to be parsed correctly and recognized by screen readers.-->
			<label for="banner-header"></label>
			<h1 id="banner-header" class="wcd-font-weight-bold panel-title banner-title">
				{{ bannerTitle }}
			</h1>
			<div class="wcd-padding-top">
				<markdown>{{ bannerDescription }}</markdown>
			</div>
		</div>
		<div *ngIf="!isNarrowLayout" class="wcd-flex-1 wcd-flex-center-horizontal wcd-padding-small-right">
			<img [src]="'/assets/images/mte/signup.svg' | basePath" class="mte-icon" [alt]="'threatExpert_banner_image_description' | i18n"/>
		</div>
	</div>
</ng-template>
