import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Security Alert Summary',
	pluralName: 'Security Alert Summaries',
	readonly: true,
})
export class SecurityAlertSummary extends ModelBase {
	@EntityField({
		data: 'HighCount',
		defaultValue: 0,
	})
	readonly high: number;

	@EntityField({
		data: 'MediumCount',
		defaultValue: 0,
	})
	readonly medium: number;

	@EntityField({
		data: 'LowCount',
		defaultValue: 0,
	})
	readonly low: number;
}
