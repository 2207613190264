import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartSettings } from '@wcd/charts';
import { I18nService } from '@wcd/i18n';
import { ReportWidgetComponent } from '../reports/components/report-widget.component.base';
import { ReportWidgetConfig } from '../reports/models/report-widget.model';
import { WidgetType } from '../reports/components/report-with-filters.component';
import { ReportsService } from '../shared-reports/services/reports.service';
import { FiltersState, SerializedFilters } from '@wcd/ng-filters';

export abstract class BaseReportWidgetComponent<TData = any> extends ReportWidgetComponent<TData> {
	abstract readonly widgetType: WidgetType;
	protected readonly dimensionType?: string;
	protected readonly height: number = 300;

	protected currentFilters: SerializedFilters;
	private currentDataDateRange: [Date, Date];
	private currentDataDate: Date;
	private readonly today = new Date();

	private widgetId: string;
	private titleKey: string;

	constructor(
		widgetId: string,
		titleKey: string,
		reportsService: ReportsService,
		protected i18nService: I18nService,
		private customWidgetConfig: Partial<ReportWidgetConfig> = {},
		private help: string = undefined,
		private range: number = undefined
	) {
		super(reportsService);
		this.widgetId = widgetId;
		this.titleKey = titleKey;
	}

	get widgetConfig(): ReportWidgetConfig<TData> {
		return {
			...this.customWidgetConfig,
			id: this.widgetId,
			name: this.i18nService.get(this.titleKey),
			help: this.help == undefined ? undefined : this.i18nService.get(this.help),
			getDateOfWidget: () => {
				return this.widgetType === WidgetType.Daily && this.currentDataDate;
			},
			getDateRangeOfWidget: this.range
				? undefined
				: () => {
						return this.widgetType === WidgetType.Trend && this.currentDataDateRange;
				  },
			rangeInDays: this.range,
			height: this.getHeight(),
			loadData: (filtersState: FiltersState) => {
				const serializedFilters = this.reportsService.fixDataIfNeeded(filtersState, this.widgetType);
				this.currentFilters = serializedFilters;

				this.widgetType === WidgetType.Daily
					? (this.currentDataDate = this.reportsService.calcCurrentDataDateForDaily(
							serializedFilters,
							this.today
					  ))
					: (this.currentDataDateRange = this.reportsService.calcCurrentDataDateRangeForTrend(
							serializedFilters
					  ));

				return this.dataFunc(serializedFilters, this.widgetType, this.dimensionType).pipe(
					map((res) => {
						return this.widgetType === WidgetType.Daily && Array.isArray(res) ? res[0] : res;
					})
				);
			},
		};
	}

	getHeight(): string {
		return `${this.height}px`;
	}

	chartSettings$: Observable<ChartSettings> = this.data$.pipe(
		map((data) => data && this.getSettings(data))
	);

	abstract getSettings(data?: TData): ChartSettings;

	abstract get dataFunc(): (
		serializedFilters: SerializedFilters,
		widgetType: WidgetType,
		dimension?: string
	) => Observable<Readonly<TData>>;
}
