var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { RecommendationContextType } from '../common/recommendation/recommendation-context-type.enum';
import { RecommendationContextBase } from '../recommendation-context/recommendation-context-base.entity';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
var ɵ0 = function (o, rawData) {
    if (rawData.ServiceName) {
        if (rawData.Path) {
            return RecommendationContextType.ContextWithServiceNameAndServicePath;
        }
        else {
            return RecommendationContextType.ContextWithServiceName;
        }
    }
    else if (rawData.UserName) {
        return RecommendationContextType.ContextWithUserName;
    }
    else if (rawData.ShareName) {
        return RecommendationContextType.ContextWithShareNameAndSharePath;
    }
    return RecommendationContextType.Unknown;
};
var AssetRecommendationContext = /** @class */ (function (_super) {
    __extends(AssetRecommendationContext, _super);
    function AssetRecommendationContext() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Path' }),
        __metadata("design:type", String)
    ], AssetRecommendationContext.prototype, "path", void 0);
    __decorate([
        EntityField({ data: 'SharePath' }),
        __metadata("design:type", String)
    ], AssetRecommendationContext.prototype, "sharePath", void 0);
    __decorate([
        EntityField({
            data: 'contextType',
            parse: ɵ0,
            required: true,
        }),
        __metadata("design:type", String)
    ], AssetRecommendationContext.prototype, "contextType", void 0);
    AssetRecommendationContext = __decorate([
        Entity(__assign({}, TvmAnalyticsSharedEntityConfigurations, { singularName: 'Asset Recommendation Context', pluralName: 'Asset Recommendation Contexts', endpoint: 'configurations', readonly: true }))
    ], AssetRecommendationContext);
    return AssetRecommendationContext;
}(RecommendationContextBase));
export { AssetRecommendationContext };
export { ɵ0 };
