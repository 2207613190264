var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, EntityModelBase, Entity, ModelBase, ValueObject } from '@microsoft/paris';
import { EvaluationMachineStatus } from './evaluation-machine-status.entity';
import { EvaluationSimulatorStatus } from './evaluation-simulator-status.entity';
var EvaluationSimulatorsStatus = /** @class */ (function (_super) {
    __extends(EvaluationSimulatorsStatus, _super);
    function EvaluationSimulatorsStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'AttackIQ' }),
        __metadata("design:type", EvaluationSimulatorStatus)
    ], EvaluationSimulatorsStatus.prototype, "attackIQ", void 0);
    __decorate([
        EntityField({ data: 'SafeBreach' }),
        __metadata("design:type", EvaluationSimulatorStatus)
    ], EvaluationSimulatorsStatus.prototype, "safeBreach", void 0);
    EvaluationSimulatorsStatus = __decorate([
        ValueObject({
            singularName: 'Evaluation simulator status',
            pluralName: 'Evaluation simulators status',
            readonly: true,
        })
    ], EvaluationSimulatorsStatus);
    return EvaluationSimulatorsStatus;
}(ModelBase));
export { EvaluationSimulatorsStatus };
var ɵ0 = function (value) { return 'evaluation/machines'; }, ɵ1 = function (config) { return config.data.serviceUrls.k8s; }, ɵ2 = ['Name', 'name'], ɵ3 = function (machineName) { return machineName.toLowerCase(); }, ɵ4 = ['Password', 'password'];
var EvaluationMachine = /** @class */ (function (_super) {
    __extends(EvaluationMachine, _super);
    function EvaluationMachine() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Index' }),
        __metadata("design:type", Number)
    ], EvaluationMachine.prototype, "index", void 0);
    __decorate([
        EntityField({ data: ɵ2, parse: ɵ3 }),
        __metadata("design:type", String)
    ], EvaluationMachine.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'MachineFqdn' }),
        __metadata("design:type", String)
    ], EvaluationMachine.prototype, "machineFqdn", void 0);
    __decorate([
        EntityField({ data: 'CreationTime' }),
        __metadata("design:type", Date)
    ], EvaluationMachine.prototype, "creationTime", void 0);
    __decorate([
        EntityField({ data: 'ProvisioningTime' }),
        __metadata("design:type", Date)
    ], EvaluationMachine.prototype, "provisioningTime", void 0);
    __decorate([
        EntityField({ data: 'PublicIp' }),
        __metadata("design:type", String)
    ], EvaluationMachine.prototype, "publicIp", void 0);
    __decorate([
        EntityField({ data: 'Status' }),
        __metadata("design:type", EvaluationMachineStatus)
    ], EvaluationMachine.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'SimulatorsStatus' }),
        __metadata("design:type", EvaluationSimulatorsStatus)
    ], EvaluationMachine.prototype, "simulatorsStatus", void 0);
    __decorate([
        EntityField({ data: ɵ4 }),
        __metadata("design:type", String)
    ], EvaluationMachine.prototype, "password", void 0);
    __decorate([
        EntityField({ data: 'SenseMachineId' }),
        __metadata("design:type", String)
    ], EvaluationMachine.prototype, "senseMachineId", void 0);
    __decorate([
        EntityField({
            data: 'MachineOS',
        }),
        __metadata("design:type", String)
    ], EvaluationMachine.prototype, "machineOs", void 0);
    __decorate([
        EntityField({
            data: 'ToolsToExclude',
        }),
        __metadata("design:type", Array)
    ], EvaluationMachine.prototype, "toolsToExclude", void 0);
    __decorate([
        EntityField({
            data: 'DomainStatus',
        }),
        __metadata("design:type", String)
    ], EvaluationMachine.prototype, "domainStatus", void 0);
    __decorate([
        EntityField({
            data: 'DomainName',
        }),
        __metadata("design:type", String)
    ], EvaluationMachine.prototype, "domainName", void 0);
    EvaluationMachine = __decorate([
        Entity({
            singularName: 'Evaluation device',
            pluralName: 'Evaluation devices',
            endpoint: 'evaluation/machines',
            allItemsProperty: 'machines',
            loadAll: false,
            parseSaveQuery: ɵ0,
            saveMethod: 'POST',
            baseUrl: ɵ1,
        })
    ], EvaluationMachine);
    return EvaluationMachine;
}(EntityModelBase));
export { EvaluationMachine };
export var EvaluationMachineOperatingSystem;
(function (EvaluationMachineOperatingSystem) {
    EvaluationMachineOperatingSystem["Windows10"] = "windows10";
    EvaluationMachineOperatingSystem["Windows11"] = "windows11";
    EvaluationMachineOperatingSystem["WindowsServer2019"] = "windowsServer2019";
    EvaluationMachineOperatingSystem["LinuxUbuntuServer"] = "LinuxUbuntuServer";
    EvaluationMachineOperatingSystem["WindowsServer2016"] = "WindowsServer2016";
})(EvaluationMachineOperatingSystem || (EvaluationMachineOperatingSystem = {}));
export var DomainStatus;
(function (DomainStatus) {
    DomainStatus["Workgroup"] = "Workgroup";
    DomainStatus["DomainJoined"] = "DomainJoined";
    DomainStatus["DomainController"] = "DomainController";
})(DomainStatus || (DomainStatus = {}));
export var EvaluationMachineTool;
(function (EvaluationMachineTool) {
    EvaluationMachineTool["Office"] = "Office";
    EvaluationMachineTool["Jre"] = "Jre";
    EvaluationMachineTool["Python"] = "Python";
    EvaluationMachineTool["SysInternals"] = "SysInternals";
})(EvaluationMachineTool || (EvaluationMachineTool = {}));
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
