import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'SecurityConfiguration recommendation arguments',
	pluralName: 'SecurityConfiguration recommendations arguments',
})
export class ScaRecommendationArgs extends ModelBase {
	@EntityField({
		required: true,
		data: 'scid',
	})
	readonly scid: string;
}
