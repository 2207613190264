import { ModelBase, EntityField, ValueObject } from '@microsoft/paris';
import { RecommendationExceptionStateValue } from './recommendation-exception-state-value.enum';

@ValueObject({
	singularName: 'Exception status with count',
	pluralName: 'Exceptions status with count',
})
export class RecommendationExceptionStatusWithCount extends ModelBase {
	@EntityField({
		data: 'status',
	})
	readonly status: RecommendationExceptionStateValue;

	@EntityField({
		data: 'count',
	})
	readonly count: number;
}
