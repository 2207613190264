import { Injectable } from '@angular/core';
import { DetectionSource, ServiceSourceType, ServiceSourceValue, serviceSourceValues } from '@wcd/domain';
import { AppContextService, FlavorService, FeaturesService, Feature } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';
import { DataEntityType } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { getDetectionSourceFilterValueByServiceSource, mdatpDetectionSources } from '@wcd/scc-common';
import { AppFlavorConfig } from '@wcd/scc-common';

@Injectable({
	providedIn: 'root',
})
export class SourceFilterService {
	detectionSourceFiltersExtended: boolean;
	isMtpFilterHidden: boolean;
	isThreatExpertEnabledForMdatp: boolean;
	isThreatExpertEnabledForMtp: boolean;
	isSmbDetectionSourcesEnabled: boolean;
	isManualAlertsEnabled: boolean;

	constructor(
		private appConfigService: AppConfigService,
		private appContextService: AppContextService,
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		flavorService: FlavorService
	) {
		this.detectionSourceFiltersExtended = flavorService.isEnabled(
			AppFlavorConfig.alerts.detectionSourceExtended
		);
		this.isMtpFilterHidden = !flavorService.isEnabled(
			AppFlavorConfig.alertsQueue.extendedFilterAndSubFilterOptions
		);
		this.isThreatExpertEnabledForMdatp =
			flavorService.isEnabled(AppFlavorConfig.alerts.threatExpertSource) &&
			this.appConfigService.isBilbaoEnabled;
		this.isThreatExpertEnabledForMtp = this.appConfigService.isBilbaoEnabled;
		this.isSmbDetectionSourcesEnabled = flavorService.isEnabled(
			AppFlavorConfig.incidents.detectionSources
		);
		this.isManualAlertsEnabled = featuresService.isEnabled(Feature.ShowNewHuntingPageToggle);
	}

	getDetectionSourcesFilterValues(): Record<string, any> {
		return [
			...mdatpDetectionSources(
				this.appContextService.isMtp,
				this.isThreatExpertEnabledForMdatp,
				true,
				this.detectionSourceFiltersExtended,
				this.isSmbDetectionSourcesEnabled
			),
			...this.getThirdPartySourceValue(),
		].map((detectionSource: DetectionSource) => ({
			value: detectionSource.id,
			name: this.i18nService.get(detectionSource.nameI18nKey),
			count: null,
		}));
	}

	getServiceSourceTreeFilterValues(): Record<string, any> {
		const filterValues = getDetectionSourceFilterValueByServiceSource(
			this.isThreatExpertEnabledForMtp,
			this.isThreatExpertEnabledForMdatp,
			this.appContextService.isMtp,
			!this.appConfigService.isOatpActive,
			!this.appConfigService.isItpActive,
			this.isMtpFilterHidden,
			this.detectionSourceFiltersExtended,
			this.featuresService.isEnabled(Feature.MapgAlerts) && this.appConfigService.isMapgActive,
			this.featuresService.isEnabled(Feature.AadIp2Alerts) && this.appConfigService.isAadIpActive,
			this.isSmbDetectionSourcesEnabled,
			this.isManualAlertsEnabled,
			this.featuresService.isEnabled(Feature.SupportDlpServiceSource) &&
				this.appConfigService.isOatpActive //TODO: switch to isDlpActive once BE is ready - VSO: 34891225)
		);
		//translate names
		return filterValues.map((serviceSource) => ({
			value: serviceSource.value,
			name: this.i18nService.get(serviceSource.nameI18nKey),
			children: serviceSource.children
				? serviceSource.children.map((detectionSource) => ({
						name: this.i18nService.get(detectionSource.nameI18nKey),
						value: detectionSource.value,
						priority: detectionSource.priority,
				  }))
				: null,
		}));
	}

	private getThirdPartySourceValue() {
		return (<DataEntityType>DetectionSource).entityConfig.values.filter(
			(source) => source.isSelectable && source.isGroupedThirdParty === !this.appContextService.isMtp
		);
	}
}
