import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	ViewEncapsulation,
	ViewChildren,
	QueryList,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
import { ListFilterValue } from '../list-filter-value.model';
import { flatMap, isEqual, isEqualWith, uniq, uniqWith } from 'lodash-es';
import { combineLatest, Observable, of, Subscription, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterValuesCheckListConfig } from './filter-values.checklist.config';
import { SerializedFilters } from '../../../models/serialized-filters.type';
import { ListFilterValueCategory } from '../list-filter-value-category.model';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';

const SERIALIZED_VALUE_SEPARATOR = '|';

/**
 * Just a very large number, no special meaning to the specific number.
 */
const LARGEST_FILTER_VALUE_PRIORITY = Infinity;

@Component({
	selector: 'wcd-filter-values-checklist',
	templateUrl: './filter-values.checklist.component.html',
	styleUrls: ['./filter-values.checklist.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterValuesChecklistComponent<TValue extends string | number | boolean>
	extends FilterValuesComponent<
		FilterValuesChecklistData<TValue>,
		FilterValuesChecklistSelection<TValue>,
		ReadonlyArray<ListFilterValue<TValue>>,
		FilterValuesCheckListConfig<TValue>
	>
	implements OnDestroy, OnInit {
	private _rawData: FilterValuesChecklistData;

	@Input()
	set data(data: FilterValuesChecklistData<TValue>) {
		this._rawData = data;
		this.setValues();
	}

	get value(): ReadonlyArray<ListFilterValue<TValue>> {
		return this.values;
	}

	@Input() setKeyboardContainer: boolean = true;

	private _rawValues: Array<FilterValuesChecklistValueData<TValue>>;
	private getValuesSubscription: Subscription;

	private _config: FilterValuesCheckListConfig<TValue>;
	@Input()
	set config(config: FilterValuesCheckListConfig<TValue>) {
		this._config = config;
		if (config) {
			const { fixedValues, values } = config;
			this.fixedValues = new Set(fixedValues && fixedValues.map(fixedValue => String(fixedValue)));

			if (values) {
				if (values instanceof Observable) {
					this.getValuesSubscription = from(values).subscribe(_values => {
						this._rawValues = _values;
						this.setValues();
					});
				} else {
					this._rawValues = values;
					this.setValues();
				}
			}
		} else this.fixedValues = new Set();
	}

	get config(): FilterValuesCheckListConfig<TValue> {
		return this._config;
	}

	get allowSingleValueDeselection(): boolean {
		return this.config && this.config.allowSingleValueDeselection;
	}

	/**
	 * Set to `true` to not have an 'Select All' option that selects all values.
	 */
	@Input() disableSelectAll = false;

	/**
	 * If the checklist is nested in another, `parentValue` should be the filter value that holds this checklist.
	 */
	@Input() parentValue: ListFilterValue<TValue>;

	@Input() elementVisible: boolean = true;

	@ViewChildren(FilterValuesChecklistComponent)
	private childChecklists: QueryList<FilterValuesChecklistComponent<TValue>>;

	selectedValuesSet: Set<string> = new Set();
	parentSelectedValuesSet: Set<string> = new Set();
	partialValues: Set<string> = new Set();
	values: ReadonlyArray<ListFilterValue<TValue>> = [];
	fixedValues: Set<string>;
	expandedValues: Set<string> = new Set();

	childrenTabIndex: Array<number> = [];

	showSelectAllCheckbox: boolean;
	selectAllText: string;

	private _getFilterValuesSubscription: Subscription;
	private _categoryValues: Map<
		ListFilterValueCategory,
		{ values: Array<ListFilterValue<TValue>> }
	> = new Map();

	get isAll(): boolean {
		return this.selectedValuesSet && this.selectedValuesSet.size === this.values.length;
	}

	get selectionMode(): FilterValuesChecklistSelectionMode {
		return this.selectedValuesSet.size === this.values.length
			? FilterValuesChecklistSelectionMode.All
			: !this.selectedValuesSet.size
			? FilterValuesChecklistSelectionMode.None
			: FilterValuesChecklistSelectionMode.Some;
	}

	@Input() allFirstTabIndex: boolean = false;
	firstTabIndex: boolean = true;

	constructor(private changeDetectorRef: ChangeDetectorRef, public i18nService: I18nService, private liveAnnouncer: LiveAnnouncer) {
		super();
		this.selectAllText = this.i18nService.strings.filters_values_checklist_select_all;
	}

	ngOnInit(): void {
		this.setShowSelectAllCheckbox();
		this.changeDetectorRef.markForCheck();
	}

	ngOnDestroy() {
		if (this._getFilterValuesSubscription) this._getFilterValuesSubscription.unsubscribe();
		if (this.getValuesSubscription) this.getValuesSubscription.unsubscribe();
	}

	setValues() {
		if (this._getFilterValuesSubscription) this._getFilterValuesSubscription.unsubscribe();

		if (this._rawData || this._rawValues || this.selectedValuesSet.size) {
			const extraValues: Array<TValue> =
				(!this.parentValue &&
					this.config &&
					this.config.allowUnknownValues &&
					this.getExtraValues(Array.from(this.selectedValuesSet))) ||
				[];
			const rawValues: Array<any> = [
				...((this._rawData && this._rawData.values) || this._rawValues || []),
				...extraValues,
			];
			this._getFilterValuesSubscription = this.getFilterValues(rawValues).subscribe(values => {
				if (!isEqual(this.values, values)) {
					this.values = values;
					this.setShowSelectAllCheckbox();
					this.changeDetectorRef.markForCheck();
				}
			});
		} else this.values = [];

		this.changeDetectorRef.detectChanges();
	}

	isValueSelected(filterValue: ListFilterValue<TValue>): boolean {
		const stringId = String(filterValue.id);

		return (
			this.selectedValuesSet.has(stringId) ||
			this.parentSelectedValuesSet.has(stringId) ||
			(this.fixedValues && this.fixedValues.has(stringId)) ||
			(!this.allowSingleValueDeselection && this.values.length === 1)
		);
	}

	isValueDisabled(filterValue: ListFilterValue<TValue>): boolean {
		return (
			(this.fixedValues && this.fixedValues.has(String(filterValue.id))) ||
			(!this.allowSingleValueDeselection && this.values.length === 1)
		);
	}

	isPartiallySelected(filterValue: ListFilterValue<TValue>): boolean {
		return this.partialValues.has(String(filterValue.id));
	}

	getCategoryValues(category: ListFilterValueCategory<TValue>): { values: Array<ListFilterValue<TValue>> } {
		let existingCategoryValues: { values: Array<ListFilterValue<TValue>> } = this._categoryValues.get(
			category
		);
		if (!existingCategoryValues) {
			existingCategoryValues = { values: category.values };
			this._categoryValues.set(category, existingCategoryValues);
		}

		return existingCategoryValues;
	}

	toggleAll() {
		if (this.isAll) {
			this.setSelectedValues(null);
		} else {
			this.selectAllValues();
		}
		this.triggerChange();
	}

	get isPartialSelected() {
		return this.selectedValuesSet.size > 0 && this.selectedValuesSet.size < this.values.length;
	}

	setSelectedValues(selectedValues: FilterValuesChecklistSelection) {
		if (this.childChecklists) {
			if (selectedValues && selectedValues.length) {
				const childValues: Set<string> = new Set(
					flatMap(
						this.childChecklists.map(childChecklist =>
							childChecklist.values.map(value => String(value.id))
						)
					)
				);

				const nonChildSelectedValues: FilterValuesChecklistSelection = selectedValues.filter(
					value => !childValues.has(String(value))
				);
				let relevantSelectedValues: FilterValuesChecklistSelection = nonChildSelectedValues.map(
					value => String(value)
				);
				if (this.parentValue)
					relevantSelectedValues = this.removeUnknownValues(relevantSelectedValues);

				this.selectedValuesSet = new Set(relevantSelectedValues);
			} else this.selectedValuesSet = new Set([]);

			this.childChecklists.forEach(child => child.setSelectedValues(selectedValues));
		} else
			this.selectedValuesSet = new Set(
				selectedValues ? selectedValues.map(value => String(value)) : []
			);

		this.setValues();
		this.setParentValuesSelection();
	}

	private removeUnknownValues(values: FilterValuesChecklistSelection): FilterValuesChecklistSelection {
		const knownValuesSet: Set<string> = new Set(this.values.map(value => String(value.id)));
		return values.filter(value => knownValuesSet.has(String(value)));
	}

	selectAllValues() {
		const allValues: Array<TValue> = this.values.reduce(
			(allSelectedValues: Array<TValue>, value: ListFilterValue<TValue>) => {
				const allChildValues: Array<ListFilterValue<TValue>> = value.childCategories
					? flatMap(value.childCategories, category => category.values)
					: [];
				return [...allSelectedValues, ...allChildValues.map(listValue => listValue.id), value.id];
			},
			[]
		);

		this.setSelectedValues(allValues);
	}

	serialize(): SerializedFilters {
		const mainSelectedValues: Array<string> = this.getSerializedSelectedValues();
		const childSelectedValues: Array<string> = flatMap(
			this.childChecklists.map(childChecklist => childChecklist.getSerializedSelectedValues())
		);

		const allSelectedValues: Array<string> = mainSelectedValues.concat(childSelectedValues);

		return allSelectedValues.length
			? {
					[this.fieldId]: allSelectedValues,
			  }
			: null;
	}

	getSerializedSelectedValues(): Array<string> {
		return this.selectedValuesSet && this.selectedValuesSet.size
			? Array.from(this.selectedValuesSet)
			: [];
	}

	deserialize(serializedSelectedValues: SerializedFilters): FilterValuesChecklistSelection {
		const checklistSerializedValues = serializedSelectedValues && serializedSelectedValues[this.fieldId];
		if (checklistSerializedValues) {
			return checklistSerializedValues instanceof Array
				? checklistSerializedValues
				: checklistSerializedValues.split(SERIALIZED_VALUE_SEPARATOR);
		}

		return [];
	}

	deselectAllValues(): void {
		this.setSelectedValues(null);
		this.partialValues.clear();
		this.triggerChange();
	}

	/**
	 * Sets values that are in this.selectedValuesSet but aren't in this.values
	 */
	getExtraValues(selectedValues: FilterValuesChecklistSelection): Array<TValue> {
		if (!this._rawData) return selectedValues || [];

		return selectedValues.filter(selectedValue => {
			const strValue = String(selectedValue);
			return (
				!this._rawData.values.find(value => String(value.value) === strValue) &&
				!this.valuesContainValue(this.values, strValue)
			);
		});
	}

	private valuesContainValue(values: ReadonlyArray<ListFilterValue<TValue>>, value: string): boolean {
		return values.some(_value => {
			if (String(_value.rawValue) === value || String(_value.id) === value) return true;

			if (_value.hasChildren) {
				const isChildValue = this.valuesContainValue(
					flatMap(_value.childCategories, category => category.values),
					value
				);
				if (isChildValue) return true;
			}

			return false;
		});
	}

	onToggleFieldValue(filterValue: ListFilterValue<TValue>): void {
		const filterValueId = String(filterValue.id);

		if (filterValue.hasChildren) {
			const parentNewValue =
				!this.parentSelectedValuesSet.has(filterValueId) && !this.partialValues.has(filterValueId);
			if (parentNewValue) this.parentSelectedValuesSet.add(filterValueId);
			else this.parentSelectedValuesSet.delete(filterValueId);

			this.partialValues.delete(filterValueId);

			this.setChildrenSelectedValues(filterValue, parentNewValue);
		} else {
			const newValue = !this.selectedValuesSet.has(filterValueId);
			const changedValues = new Map<ListFilterValue<TValue>, boolean>();

			changedValues.set(filterValue, newValue || null);

			if (newValue) this.selectedValuesSet.add(filterValueId);
			else this.selectedValuesSet.delete(filterValueId);
		}

		this.triggerChange();
	}

	setChildrenSelectedValues(filterValue: ListFilterValue<TValue>, isSelected: boolean) {
		if (!filterValue.hasChildren) return;

		this.partialValues.delete(String(filterValue.id));
		const childChecklists = this.getFilterValueChildChecklists(filterValue);
		childChecklists.forEach(checklist => {
			if (isSelected) checklist.selectAllValues();
			else checklist.setSelectedValues([]);
		});
	}

	private getFilterValueChildChecklists(
		filterValue: ListFilterValue<TValue>
	): Array<FilterValuesChecklistComponent<TValue>> {
		return this.childChecklists
			? this.childChecklists.filter(childChecklist => childChecklist.parentValue === filterValue)
			: [];
	}

	onChildChecklistChange(filterValue: ListFilterValue<TValue>) {
		this.triggerChange();
		this.setParentFilterValueSelectionMode(filterValue);
	}

	/**
	 * Sets the selection state of values that have children - selected, partially selected or not selected.
	 */
	private setParentValuesSelection() {
		if (!this.childChecklists) return;

		this.values.forEach(filterValue => this.setParentFilterValueSelectionMode(filterValue));
	}

	private setParentFilterValueSelectionMode(filterValue: ListFilterValue<TValue>) {
		if (filterValue.hasChildren) {
			const selectionMode: FilterValuesChecklistSelectionMode = this.getParentFilterValueSelectionMode(
				filterValue
			);

			const filterValueId = String(filterValue.id);

			this.partialValues.delete(filterValueId);
			switch (selectionMode) {
				case FilterValuesChecklistSelectionMode.None:
					this.parentSelectedValuesSet.delete(filterValueId);
					break;
				case FilterValuesChecklistSelectionMode.Some:
					this.partialValues.add(filterValueId);
				default:
					this.parentSelectedValuesSet.add(filterValueId);
			}
		}
	}

	private getParentFilterValueSelectionMode(
		filterValue: ListFilterValue<TValue>
	): FilterValuesChecklistSelectionMode {
		const allChildrenValues = this.getFilterValueChildChecklists(filterValue);

		if (!allChildrenValues.length) return null;

		return allChildrenValues.reduce((filterValueSelectionMode, checklist) => {
			return checklist.selectionMode === filterValueSelectionMode
				? filterValueSelectionMode
				: FilterValuesChecklistSelectionMode.Some;
		}, allChildrenValues[0].selectionMode);
	}

	private triggerChange() {
		this.filterValuesChange.emit((this.selectedValues = this.getSelectedValues()));
	}

	/**
	 * Returns the IDs of all selected values (including child lists) that don't have children.
	 */
	getSelectedValues(): Array<TValue> {
		const selectedValues: Array<TValue> = Array.from(this.selectedValuesSet)
			.map((selectedValue: string) => this.values.find(value => selectedValue === String(value.id)))
			.filter(
				(value: ListFilterValue<TValue>) =>
					value && (!value.childCategories || !value.childCategories.length)
			)
			.map((value: ListFilterValue<TValue>) => value.id);

		const childSelectedValues = this.childChecklists
			? flatMap(this.childChecklists.map(childChecklist => childChecklist.getSelectedValues()))
			: [];
		return uniq(selectedValues.concat(childSelectedValues));
	}

	/**
	 * Formats the raw values into values used by the component to render the checkboxes.
	 */
	getFilterValues(
		rawFilterValues: Array<FilterValuesChecklistValueData>
	): Observable<Array<ListFilterValue<TValue>>> {
		if (!rawFilterValues || !rawFilterValues.length) return of([]);

		const filterValues$: Array<Observable<ListFilterValue<TValue>>> = rawFilterValues.map(
			(value: FilterValuesChecklistValueData<TValue>) =>
				ListFilterValue.fromValue<TValue>(value, this.config)
		);

		return combineLatest(filterValues$).pipe(
			map((filterValues: Array<ListFilterValue<TValue>>) => {
				const uniqueValues: Array<ListFilterValue<TValue>> = uniqWith(
					filterValues,
					(filterValueA: ListFilterValue<TValue>, filterValueB: ListFilterValue<TValue>) =>
						isEqualWith(filterValueA, filterValueB, () => filterValueA.id === filterValueB.id)
				);

				return uniqueValues.sort((a: ListFilterValue<TValue>, b: ListFilterValue<TValue>) => {
					if (!isNaN(a.priority) || !isNaN(b.priority)) {
						const aPriority: number = a.priority || LARGEST_FILTER_VALUE_PRIORITY,
							bPriority: number = b.priority || LARGEST_FILTER_VALUE_PRIORITY;

						return aPriority === bPriority ? 0 : aPriority > bPriority ? 1 : -1;
					}

					if (a.count === b.count) return a.displayName > b.displayName ? 1 : -1;

					return b.count - a.count;
				});
			})
		);
	}

	private setShowSelectAllCheckbox() {
		this.showSelectAllCheckbox =
			this.values.length > 1 &&
			(!this.config || (this.config && !this.config.disableSelectAll)) &&
			!this.disableSelectAll;
	}

	// Aria label type of "2 of 5 <filter value>"
	getCheckboxAriaLabel(i: number, fieldValue: string, hasChildren: boolean): string {
		const selectAllAddition = this.showSelectAllCheckbox ? 1 : 0;
		const filterValuesCount = this.value.length + selectAllAddition;
		let ariaLabel = this.i18nService.get('common_filter_checkbox_aria_text', {
			index: i + 1 + selectAllAddition,
			total: filterValuesCount,
			filterValue: fieldValue,
		});
		if(hasChildren){
			ariaLabel += ' ' + this.i18nService.get('common_filter_checkbox_children_available');
		}
		return ariaLabel;
	}

	toggleValue(event: Event, value: ListFilterValue<TValue>) {
		const strId = String(value.id);
		const expended = this.expandedValues.has(strId);
		this.liveAnnouncer.announce(
			this.i18nService.get( !expended ? "common.button.expanded" : "common.button.collapsed"),
			'assertive',
			1000
		).then(()=>{
			setTimeout(()=>{
				if (expended) this.expandedValues.delete(strId);
				else this.expandedValues.add(strId);
				event.stopPropagation();
				event.preventDefault();
				this.changeDetectorRef.markForCheck();
			}, 200);
		});
	}

	isExpanded(value: ListFilterValue<TValue>): boolean {
		return this.expandedValues.has(String(value.id));
	}

	setChildrenTabIndex($event, valueId, hasChildren) {
		if (hasChildren) {
			this.childrenTabIndex[valueId] = $event;
		}
	}

	getFirstTabIndex(){
		const isFirstTabIndex = this.firstTabIndex;
		this.firstTabIndex = false;
		return isFirstTabIndex;
	}
}

export interface FilterValuesChecklistData<TValue = any> {
	count?: number;
	values: Array<FilterValuesChecklistValueData<TValue>>;
}

export interface FilterValuesChecklistValueData<TValue = any, TCustomData = any> {
	count?: number;
	value: TValue;
	name?: string;
	priority?: number;
	children?: Array<FilterValuesChecklistValueData<TValue>>;
	category?: string;
	custom?: TCustomData;
}

export type FilterValuesChecklistSelection<TValue = any> = Array<TValue>;

enum FilterValuesChecklistSelectionMode {
	All,
	Some,
	None,
}
