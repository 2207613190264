var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { FilesService } from '../../files/services/files.service';
var EmailAttachmentsDetailsPanelComponent = /** @class */ (function (_super) {
    __extends(EmailAttachmentsDetailsPanelComponent, _super);
    function EmailAttachmentsDetailsPanelComponent(router, i18nService, filesService) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.filesService = filesService;
        _this.tableFields = DataTableField.fromList([
            {
                id: 'fileName',
                name: _this.i18nService.strings.airsEntities_emailMessage_fields_attachments_fileName,
                getTooltip: function (emailAttachment) { return emailAttachment.fileName; },
            },
            {
                id: 'SHA256',
                name: _this.i18nService.strings.airsEntities_emailMessage_fields_attachments_sha256,
                getTooltip: function (emailAttachment) { return emailAttachment.SHA256; },
                getLink: function (emailAttachment) {
                    return _this.filesService.getFileLink(emailAttachment.SHA256);
                },
            },
            {
                id: 'malwareFamily',
                name: _this.i18nService.strings.airsEntities_emailMessage_fields_attachments_malwareFamily,
                getTooltip: function (emailAttachment) { return emailAttachment.malwareFamily; },
            },
        ]);
        return _this;
    }
    return EmailAttachmentsDetailsPanelComponent;
}(PanelContainer));
export { EmailAttachmentsDetailsPanelComponent };
