
		<ul
			class="field-value-list inline-list with-bullets"
			[wcdTooltip]="tooltip"
			[wcdTooltipAllowHtmlRendering]="true"
			[ngClass]="{ unstyled: (action.relatedEntities?.length || 0) <= 1 }"
		>
			<ng-container [ngSwitch]="action.actionType?.type">
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.start_multiple_investigations">
					<li>
						<span>
							<ng-container *ngIf="action.expandedHostsCount === 1; else multipleHosts">
								{{
									'actionHistory_details_fields_expandedHosts_singular'
										| i18n
											: {
													source: action.expandSource
											  }
								}}
							</ng-container>
							<ng-template #multipleHosts
								>{{
									'actionHistory_details_fields_expandedHosts_plural'
										| i18n
											: {
													hostCount: action.expandedHostsCount,
													source: action.expandSource
											  }
								}}
							</ng-template>
						</span>
					</li>
				</ng-container>

				<ng-container *ngSwitchCase="RemediationActionTypeTypes.live_response_command">
					<li>{{ action.rawCommand }}</li>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.email_remediation">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.move_to_junk">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.move_to_inbox">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.hard_email_remediation">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.move_to_deleted_items">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.delete_attachment">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.submit_for_detonation">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.block_sender">
					<ng-container *ngFor="let item of action.relatedEntities">
						<li>
							<mailbox-details-field
								[senderEmailAddress]="
									item?.senderFromMailAddress || item?.p1SenderFromMailAddress
								"
								[displayQuery]="item?.displayQuery"
							></mailbox-details-field>
						</li>
					</ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.confirm_compromised_user">
					<li>{{ action.investigatedUser.upn }}</li>
				</ng-container>

				<ng-container *ngSwitchCase="RemediationActionTypeTypes.kill_process">
					<ng-container *ngTemplateOutlet="processDisplay"></ng-container>
				</ng-container>

				<ng-container *ngSwitchCase="RemediationActionTypeTypes.kill_processes">
					<ng-container *ngTemplateOutlet="processDisplay"></ng-container>
				</ng-container>

				<ng-container *ngSwitchDefault>
					<ng-container *ngIf="getDisplayByActionType[(action.actionType?.type)] as displayMethod">
						<li *ngFor="let item of action.relatedEntities">
							<span>{{ displayMethod(item) }}</span>
						</li>
					</ng-container>
				</ng-container>
				<ng-template #emailRemediationAction>
					<li *ngFor="let item of action.relatedEntities">
						<mailbox-details-field
							[senderEmailAddress]="item?.senderFromMailAddress"
							[recipientEmailAddress]="item?.recipientEmailAddress"
							[displayQuery]="item?.displayQuery"
						></mailbox-details-field>
					</li>
				</ng-template>
			</ng-container>
		</ul>
		<ng-template #processDisplay>
			<li *ngFor="let item of action.relatedEntities">
				<span>
					<ng-container *ngIf="item.processCreationTime; else noCreationTime"
						>{{
							'actionHistory_details_fields_processWithCreationTime'
								| i18n
									: {
											fileName: item.fileName,
											processId: item.processId,
											creationTime: item.processCreationTime | date: 'short'
									  }
						}}
					</ng-container>
					<ng-template #noCreationTime
						>{{
							'actionHistory_details_fields_process'
								| i18n
									: {
											fileName: item.fileName,
											processId: item.processId
									  }
						}}
					</ng-template>
				</span>
			</li>
		</ng-template>
	