import {
	DataQuery,
	EntityRelationship,
	EntityRelationshipRepositoryType,
	queryToHttpOptions,
	RelationshipType,
} from '@microsoft/paris';
import { RemediationAction } from './remediation-action.entity';
import { RemediationActionType } from './remediation-action-type.entity';

@EntityRelationship({
	sourceEntity: RemediationActionType,
	dataEntity: RemediationAction,
	baseUrl: (config, query) => {
		if (query.where['useSevilleApi']) {
			return config.data.serviceUrls.threatIntel;
		}
		return config.data.serviceUrls.automatedIr;
	},
	endpoint: (config, query: DataQuery) => {
		if (query.where['useSevilleApi']) {
			return 'investigations/pending_actions';
		}
		return query.where['api'];
	},
	getRelationshipData: (item: RemediationActionType) => {
		return { action_type: item.id, api: item.api || `remediation_action/${item.id}/actions` };
	},
	parseDataQuery: (query: DataQuery) => {
		if (query.where['useSevilleApi']) {
			const params: { [index: string]: any } = {};
			if (query.pageSize) {
				params.pageSize = query.pageSize;
			}
			if (query.page) {
				params.pageIndex = query.page;
			}
			if (query.where['investigation']) {
				params.investigationId = query.where['investigation'];
			}
			params.attentionRequiredActionType = query.where['action_type'];
			return params;
		}
		return queryToHttpOptions(query).params;
	},
	parseData: (rawData, _, query: DataQuery) => {
		if (query && query.where && query.where['useSevilleApi']) {
			return {
				count: rawData.count,
				results:
					rawData.Actions &&
					rawData.Actions.map(action => Object.assign({}, action, action.entity_data)),
			};
		}
		return rawData;
	},
	allowedTypes: [RelationshipType.OneToMany],
})
export class RemediationActionTypeActionsRelationship
	implements EntityRelationshipRepositoryType<RemediationActionType, RemediationAction> {}
