/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./associated-alerts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./associated-alert.component.ngfactory";
import * as i3 from "./associated-alert.component";
import * as i4 from "../../../../global_entities/services/global-entity-types.service";
import * as i5 from "../../services/alert-page.service";
import * as i6 from "@microsoft/paris/dist/lib/paris";
import * as i7 from "@angular/common";
import * as i8 from "./associated-alerts.component";
var styles_AssociatedAlertsComponent = [i0.styles];
var RenderType_AssociatedAlertsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssociatedAlertsComponent, data: {} });
export { RenderType_AssociatedAlertsComponent as RenderType_AssociatedAlertsComponent };
function View_AssociatedAlertsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "selectable--outer wcd-margin-xsmall-top"], ["role", "presentation"]], [[2, "selected", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "associated-alert", [["role", "presentation"]], null, null, null, i2.View_AssociatedAlertComponent_0, i2.RenderType_AssociatedAlertComponent)), i1.ɵdid(3, 114688, null, 0, i3.AssociatedAlertComponent, [i4.GlobalEntityTypesService, i5.AlertPageService, i6.Paris], { alertContext: [0, "alertContext"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertPageService.selectedTreeElements[_v.context.$implicit.alertId]; _ck(_v, 0, 0, currVal_0); }); }
function View_AssociatedAlertsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [["class", "associated-alerts--alerts-list"], ["role", "list"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssociatedAlertsComponent_2)), i1.ɵdid(3, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.associatedAlerts; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AssociatedAlertsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssociatedAlertsComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.associatedAlerts.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AssociatedAlertsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "associated-alerts", [], null, null, null, View_AssociatedAlertsComponent_0, RenderType_AssociatedAlertsComponent)), i1.ɵdid(1, 49152, null, 0, i8.AssociatedAlertsComponent, [i5.AlertPageService], null, null)], null, null); }
var AssociatedAlertsComponentNgFactory = i1.ɵccf("associated-alerts", i8.AssociatedAlertsComponent, View_AssociatedAlertsComponent_Host_0, { associatedAlerts: "associatedAlerts" }, {}, []);
export { AssociatedAlertsComponentNgFactory as AssociatedAlertsComponentNgFactory };
