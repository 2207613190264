import {
	Paris,
	DataEntityType,
	EntityRelationshipRepositoryType,
	DataQuery,
	ModelBase,
	DataSet,
} from '@microsoft/paris';
import { ReportWidgetComponent } from '../../../reports/components/report-widget.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { ReportsService } from '../../../shared-reports/services/reports.service';
import { ReportWidgetConfig } from '../../../reports/models/report-widget.model';
import { Type } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, mergeMap, map } from 'rxjs/operators';

/**
 * A base class for widgets that display data that is related to an entity
 * Extends the regular widget with the ability to query paris for related data
 */
export abstract class EntityRelatedWidgetBase<
	TEntity extends ModelBase,
	TData = any,
	TRawData = TData
> extends ReportWidgetComponent<TData, EntityRelatedData<TEntity, TRawData>> {
	constructor(
		protected readonly paris: Paris,
		protected readonly activatedEntity: ActivatedEntity,
		reportsService: ReportsService
	) {
		super(reportsService);
	}

	abstract get entityRelatedWidgetConfig(): EntityRelatedWidgetConfig<TEntity, TData, TRawData>;

	get widgetConfig() {
		const entityRelatedConfig = this.entityRelatedWidgetConfig;

		return {
			...entityRelatedConfig,
			loadData: () => {
				return this.getEntity(entityRelatedConfig).pipe(
					mergeMap((entity: TEntity) =>
						entityRelatedConfig.getRelatedData
							? entityRelatedConfig.getRelatedData(entity)
							: this.paris
									.getRelatedItem<TEntity, TRawData>(
										entityRelatedConfig.entityRelationshipType,
										entity,
										entityRelatedConfig.getDataQuery &&
											entityRelatedConfig.getDataQuery(entity)
									)
									.pipe(
										map((relatedData: TRawData) => ({
											entity,
											relatedData,
										}))
									)
					)
				);
			},
		};
	}

	private getEntity(
		entityRelatedConfig: EntityRelatedWidgetConfig<TEntity, TData, TRawData>
	): Observable<TEntity> {
		const activatedEntity$ = <Observable<TEntity>>(
			this.activatedEntity.currentEntity$.pipe(
				filter(currentEntity => currentEntity instanceof entityRelatedConfig.entityType)
			)
		);

		return (entityRelatedConfig.getEntity && entityRelatedConfig.getEntity()) || activatedEntity$;
	}
}

export interface EntityRelatedWidgetConfig<TEntity extends ModelBase, TData = any, TRawData = TData>
	extends ReportWidgetConfig<TData, EntityRelatedData<TEntity, TRawData>> {
	entityType: DataEntityType<TEntity>;

	entityRelationshipType: Type<EntityRelationshipRepositoryType<TEntity, TRawData>>;

	getEntity?: () => Observable<TEntity>;

	getDataQuery?: (entity: TEntity) => DataQuery;

	getRelatedData?: (entity: TEntity) => Observable<EntityRelatedData<TEntity, TRawData>>;
}

export interface EntityRelatedData<TEntity, TData> {
	entity: TEntity;
	relatedData: TData;
}
