export enum MachineIdType {
	Id = 'Id',
	SenseMachineId = 'SenseMachineId',
	Guid = 'Guid',
	Name = 'Name',
}

export const machineIdTypeRegexMapping: { [index: string]: RegExp } = {
	[MachineIdType.Guid]: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
	[MachineIdType.SenseMachineId]: /^[A-Fa-f0-9]{40}$/,
};

export function getMachineIdType(machineId: string): string {
	for (const machineIdType in machineIdTypeRegexMapping) {
		if (machineIdTypeRegexMapping[machineIdType].test(machineId)) return machineIdType;
	}
	return MachineIdType.Name;
}
