import { Component, Input, ComponentRef } from '@angular/core';
import { Incident, Alert, IncidentAlertsRelationship } from '@wcd/domain';
import { RelationshipRepository, Paris, DataQuerySortDirection } from '@microsoft/paris';
import { tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DataTableClickEvent } from '@wcd/datatable';
import { EntityPanelsService } from '../../services/entity-panels.service';
import { DataviewField } from '@wcd/dataview';
import { AlertsFields } from '../../../@entities/alerts/services/alerts.fields';
import { AlertsDataviewPanelComponent } from '../../../@entities/alerts/components/alerts.dataview-panel';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';

const ALERTS_FIELDS = ['title', 'severity', 'status', 'lasteventtime'];
const ALERTS_SORT_BY_FIELD = 'severity';

@Component({
	selector: 'alert-incident-details',
	templateUrl: './alert-incident-details.component.html',
})
export class AlertIncidentDetailsComponent {
	@Input() incident: Incident;

	alerts$: Observable<Array<Alert>>;
	allAlerts: Array<Alert>;
	alertsSidePanel: ComponentRef<AlertsDataviewPanelComponent>;
	alertsColumns: DataviewField<Alert>[];
	isLoadingAlerts: boolean = true;
	MAX_ALERTS = 5;

	constructor(
		private paris: Paris,
		private entityPanelsService: EntityPanelsService,
		private alertsFields: AlertsFields,
		private readonly dialogsService: DialogsService
	) {
		const queryOptions = {
			sortBy: [
				{
					field: ALERTS_SORT_BY_FIELD,
					direction: DataQuerySortDirection.descending,
				},
			],
		};

		this.alertsColumns = alertsFields.allFields
			.filter(field => ALERTS_FIELDS.includes(field.id))
			.map(field => field.clone({ maxWidth: field.maxWidth || 200, sort: { enabled: false } }));
	}

	ngOnInit() {
		const incidentAlertsRepository: RelationshipRepository<
			Incident,
			Alert
		> = this.paris.getRelationshipRepository(IncidentAlertsRelationship);
		incidentAlertsRepository.sourceItem = this.incident;

		this.alerts$ = incidentAlertsRepository.query().pipe(
			tap(result => (this.allAlerts = result.items)),
			map(result => {
				this.isLoadingAlerts = false;
				return result.items.slice(0, this.MAX_ALERTS);
			})
		);
	}

	viewAlert($event: DataTableClickEvent) {
		this.entityPanelsService.showEntity(Alert, $event.item);
	}

	onShowMoreAlerts() {
		this.dialogsService
			.showPanel(
				AlertsDataviewPanelComponent,
				{
					id: 'alerts-dataview-panel-dataview',
					type: PanelType.large,
					isModal: true,
					noBodyPadding: true,
					scrollBody: false,
					noShadow: true,
					back: {
						onClick: () => this.alertsSidePanel.destroy(),
					},
				},
				{
					alerts: this.allAlerts,
					defaultSortBy: ALERTS_SORT_BY_FIELD,
					onlyFields: ALERTS_FIELDS,
				}
			)
			.subscribe((panel: ComponentRef<AlertsDataviewPanelComponent>) => {
				this.alertsSidePanel = panel;

				panel.onDestroy(() => {
					this.alertsSidePanel = null;
				});
			});
	}
}
