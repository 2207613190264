var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Machine } from '../../machine/machine.entity';
import { LegacyUser } from './legacy-user.entity';
var ɵ0 = ['SenseMachineId', 'ComputerDnsName'], ɵ1 = function (data) { return ({
    SenseMachineId: data.SenseMachineId,
    ComputerDnsName: data.ComputerDnsName,
}); }, ɵ2 = function (data) { return ({
    AccountDomainName: data.MostFrequentAccountDomainName,
    AccountName: data.MostFrequentAccountName,
}); }, ɵ3 = function (data) { return ({
    AccountDomainName: data.LeastFrequentAccountDomainName,
    AccountName: data.LeastFrequentAccountName,
}); };
var LegacyUserObservedMachine = /** @class */ (function (_super) {
    __extends(LegacyUserObservedMachine, _super);
    function LegacyUserObservedMachine() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: ɵ0,
        }),
        __metadata("design:type", String)
    ], LegacyUserObservedMachine.prototype, "id", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ1,
        }),
        __metadata("design:type", Machine)
    ], LegacyUserObservedMachine.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'IsLocalAdminLeastPrevalent' }),
        __metadata("design:type", Boolean)
    ], LegacyUserObservedMachine.prototype, "isLocalAdminLeastPrevalent", void 0);
    __decorate([
        EntityField({ data: 'IsLocalAdminMostPrevalent' }),
        __metadata("design:type", Boolean)
    ], LegacyUserObservedMachine.prototype, "isLocalAdminMostPrevalent", void 0);
    __decorate([
        EntityField({ data: 'LastSeenOnMachine' }),
        __metadata("design:type", Date)
    ], LegacyUserObservedMachine.prototype, "lastSeenOnMachine", void 0);
    __decorate([
        EntityField({ data: 'TotalObserevedUsers' }),
        __metadata("design:type", Number)
    ], LegacyUserObservedMachine.prototype, "totalObserevedUsers", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ2,
        }),
        __metadata("design:type", LegacyUser)
    ], LegacyUserObservedMachine.prototype, "mostFrequentAccount", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ3,
        }),
        __metadata("design:type", LegacyUser)
    ], LegacyUserObservedMachine.prototype, "leastFrequentAccount", void 0);
    LegacyUserObservedMachine = __decorate([
        ValueObject({
            singularName: 'User Observed Device',
            pluralName: 'User Observed Devices',
            readonly: true,
        })
    ], LegacyUserObservedMachine);
    return LegacyUserObservedMachine;
}(ModelBase));
export { LegacyUserObservedMachine };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
