import { AppInsightsService } from './../../../../insights/services/app-insights.service';
import { SevilleModule } from '../../seville/seville.module';
import { ERROR_POPUP_TEMPLATE } from '../../../../error/components/error-page.component';
import { MACHINE_TEMPLATE } from '../actioncenter/seville.actioncenter.machine';

SevilleModule.controller('seville.threatintel.machine.forensic', forensicController);

forensicController.$inject = [
	'$log',
	'$scope',
	'$state',
	'$http',
	'$uibModalInstance',
	'appConfig',
	'machine',
	'$uibModal',
	'hoverPaneService',
	'appInsights',
];

function forensicController(
	$log,
	$scope,
	$state,
	$http,
	$uibModalInstance,
	appConfig,
	machine,
	$modal,
	hoverPaneService,
	appInsights: AppInsightsService
) {
	$scope.machine = machine;
	var vm = $scope;

	vm.collect = function() {
		if (vm.posting) return;

		if (!vm.machine.commentText || vm.machine.commentText == '') return;

		vm.posting = true;
		appInsights.trackEvent('UsageTrack', {
			ButtonType: 'ActionsButton',
			Page: 'Device',
			Component: 'CollectForensic',
		});

		$http
			.post(appConfig.serviceUrls.userRequests + '/requests/create', {
				MachineId: machine.SenseMachineId,
				RequestorComment: vm.machine.commentText,
				OsPlatform: vm.machine.OsPlatform,
				SenseClientVersion: vm.machine.SenseClientVersion,
				Type: 'ForensicsRequest',
			})
			.then(function(response) {
				if (response.status == 200) {
					machine.ResponseActionExists = true;
					machine.ForensicStatus = 'RequestPending';
					openActionCenter();
				} else {
					$modal.open({
						animation: $scope.animationsEnabled,
						template: ERROR_POPUP_TEMPLATE,
						size: 'md',
						windowClass: 'machine-actions-modal',
						backdrop: false,
					});
				}

				vm.machine.commentText = '';
				vm.posting = false;
				$uibModalInstance.dismiss();
			});
	};

	vm.close = function() {
		vm.machine.commentText = '';
		$uibModalInstance.dismiss();
	};

	function openActionCenter() {
		hoverPaneService.open({
			template: MACHINE_TEMPLATE,
			controller: 'seville.actioncenter.machine',
			controllerAs: 'actioncentermachine',
			title: 'Action center',
			icon: 'F12DevTools',
			size: 'xl',
			resolve: {
				entity: function() {
					return machine;
				},
			},
		});
	}
}
