import { SoftwareVersion } from './software-version.entity';
import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { SoftwareInstallationAgg } from '../software-installation/software-installation-agg-versions.entity';
import {
	TvmAnalyticsSharedEntityRelationshipConfigurations,
	ParseDataQuery,
} from '../analyticsEndPointUtils';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: SoftwareVersion,
	dataEntity: SoftwareInstallationAgg,
	endpoint: (_, query) =>
		`products/product/productversions/${encodeURIComponent(
			query.where['productVersions']
		)}/installations`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (softwareVersion: SoftwareVersion) => ({
		productId: softwareVersion.productId,
		productVersions: softwareVersion.version,
	}),
})
export class SoftwareVersionInstalledAssetsRelationship
	implements EntityRelationshipRepositoryType<SoftwareVersion, SoftwareInstallationAgg> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: SoftwareVersion,
	dataEntity: Vulnerability,
	endpoint: (_, query) =>
		`products/product/productversions/${encodeURIComponent(
			query.where['productVersions']
		)}/vulnerabilities`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (softwareVersion: SoftwareVersion) => ({
		productId: softwareVersion.productId,
		productVersions: softwareVersion.version,
	}),
})
export class SoftwareVersionVulnerabilitiesRelationship
	implements EntityRelationshipRepositoryType<SoftwareVersion, Vulnerability> {}
