import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { MachineSecurityKbToCve } from './machine-security-cve.value-object';
import { MachineSecurityControl } from './machine-security-control.value-object';

@ValueObject({
	singularName: 'Security control',
	pluralName: 'Security controls',
	readonly: true,
})
export class MachineSecurityAnalytics extends ModelBase {
	@EntityField({ data: 'SecurityScore' })
	securityScore: number;

	@EntityField({ data: 'MaxPotentialSecurityScore' })
	maxPotentialSecurityScore: number;

	@EntityField({ data: 'OverallStatus' })
	overallStatus: string;

	@EntityField({ data: 'SecurityControls', arrayOf: MachineSecurityControl })
	securityControls: Array<MachineSecurityControl>;

	@EntityField({ data: 'MissingKbs', arrayOf: MachineSecurityKbToCve })
	machineSecurityMissingKbs: Array<MachineSecurityKbToCve>;

	@EntityField({ data: 'AvSigVersion' })
	machineSecurityAvSigVersion: string;
}
