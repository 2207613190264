import { EventEmitter, OnChanges } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var MultiSelectComponent = /** @class */ (function () {
    function MultiSelectComponent(i18nService, liveAnnouncer) {
        this.i18nService = i18nService;
        this.liveAnnouncer = liveAnnouncer;
        this.filter = '';
        this.appliedFilter = '';
        this.commandButtonStyles = {
            root: {
                width: '100%'
            }
        };
        this.isDisabled = false;
        this.isLoadingAvailableItems = false;
        this.hasError = false;
        this.virtualScrollAvailableItems = false;
        this.onRemoveChosen = new EventEmitter();
        this.onAddChosen = new EventEmitter();
        this.onSetAvailableItems = new EventEmitter();
        this.chosenFromSelectedChange = new EventEmitter();
        this.chosenFromAvailableChange = new EventEmitter();
    }
    MultiSelectComponent.prototype.setChosenFromSelected = function (chosen) {
        this.chosenFromSelected = chosen;
        this.chosenFromSelectedChange.emit(chosen);
    };
    MultiSelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selected && this.selected) {
            this.announceListState('common.list.updated');
        }
        if (changes.isLoadingAvailableItems && changes.isLoadingAvailableItems.currentValue) {
            this.announceListState('common_loading');
        }
    };
    MultiSelectComponent.prototype.setChosenFromAvailable = function (chosen) {
        this.chosenFromAvailable = chosen;
        this.chosenFromAvailableChange.emit(chosen);
    };
    Object.defineProperty(MultiSelectComponent.prototype, "shouldApplyFilter", {
        get: function () {
            return !this.appliedFilter || this.appliedFilter !== this.filter;
        },
        enumerable: true,
        configurable: true
    });
    MultiSelectComponent.prototype.clearFilter = function () {
        this.filter = '';
        this.applyFilter();
    };
    MultiSelectComponent.prototype.applyFilter = function () {
        this.appliedFilter = this.filter;
        this.onSetAvailableItems.emit(this.filter);
    };
    MultiSelectComponent.prototype.announceListState = function (key) {
        this.liveAnnouncer.announce(this.i18nService.get(key), 'assertive', 300);
    };
    return MultiSelectComponent;
}());
export { MultiSelectComponent };
