/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i3 from "@angular-react/fabric";
import * as i4 from "../../../../../projects/charts/src/lib/time-series/time-series-chart.component.ngfactory";
import * as i5 from "../../../../../projects/charts/src/lib/time-series/time-series-chart.component";
import * as i6 from "../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i8 from "./dim-overtime-chart";
var styles_DimOvertimeChart = [];
var RenderType_DimOvertimeChart = i0.ɵcrt({ encapsulation: 2, styles: styles_DimOvertimeChart, data: {} });
export { RenderType_DimOvertimeChart as RenderType_DimOvertimeChart };
function View_DimOvertimeChart_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, [" "]))], null, null); }
function View_DimOvertimeChart_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DimOvertimeChart_2)), i0.ɵdid(1, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 13); _ck(_v, 1, 0, currVal_0); }, null); }
function View_DimOvertimeChart_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, [" "]))], null, null); }
function View_DimOvertimeChart_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DimOvertimeChart_4)), i0.ɵdid(1, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 13); _ck(_v, 1, 0, currVal_0); }, null); }
function View_DimOvertimeChart_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "wcd-padding-small-left wcd-inline-block"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "fab-icon", [["iconName", "SquareShapeSolid"]], null, null, null, i2.View_FabIconComponent_0, i2.RenderType_FabIconComponent)), i0.ɵdid(3, 5816320, null, 0, i3.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i0.ɵted(4, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.colorClass; var currVal_1 = "SquareShapeSolid"; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_2); }); }
function View_DimOvertimeChart_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "wcd-font-size-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DimOvertimeChart_6)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.legendItems; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_DimOvertimeChart_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DimOvertimeChart_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 4, "wcd-time-series-chart", [], null, null, null, i4.View_TimeSeriesChartComponent_0, i4.RenderType_TimeSeriesChartComponent)), i0.ɵdid(5, 770048, null, 0, i5.TimeSeriesChartComponent, [i0.ElementRef, i6.I18nService, i7.TzDateService], { settings: [0, "settings"], data: [1, "data"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DimOvertimeChart_3)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i0.ɵand(0, [["mapping", 2]], null, 0, null, View_DimOvertimeChart_5)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colorMappingOnTop; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.chartSettings$)); var tmp_2_0 = null; var currVal_2 = (((tmp_2_0 = i0.ɵunv(_v, 5, 1, i0.ɵnov(_v, 7).transform(_co.data$))) == null) ? null : tmp_2_0.data); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = !_co.colorMappingOnTop; _ck(_v, 11, 0, currVal_3); }, null); }
export function View_DimOvertimeChart_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dim-overtime-chart", [], null, null, null, View_DimOvertimeChart_0, RenderType_DimOvertimeChart)), i0.ɵdid(1, 49152, null, 0, i8.DimOvertimeChart, [], null, null)], null, null); }
var DimOvertimeChartNgFactory = i0.ɵccf("dim-overtime-chart", i8.DimOvertimeChart, View_DimOvertimeChart_Host_0, { chartSeriesConfig: "chartSeriesConfig", data$: "data$", chartSettings$: "chartSettings$", colorMappingOnTop: "colorMappingOnTop" }, {}, []);
export { DimOvertimeChartNgFactory as DimOvertimeChartNgFactory };
