import { Pipe, PipeTransform } from '@angular/core';
import { RemediationTask } from '@wcd/domain';

@Pipe({ name: 'remediationTaskFixedAssets' })
export class RemediationTaskFixedAssetsPipe implements PipeTransform {
	transform(remediationTask: RemediationTask, enforce = false) {
		// if enforce is true, if fixedAssets is not available, 0 will be returned (we use it in the widget where we must display a bar, in contrast to remediationTasks page in which we just display the status in case the fixedAssets number is not available)
		const fixedAssets =
			remediationTask.fixedAssets != null ? remediationTask.fixedAssets : enforce ? 0 : null;

		// TODO: VSTS task #19850674 : Creating a difference in status bar between a real completed task vs only mark as completed task
		return remediationTask.status.value === 'Completed' ? remediationTask.targetAssets : fixedAssets;
	}
}
