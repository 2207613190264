import { ValueObject, EntityModelBase, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Registry value',
	pluralName: 'Registry value items',
	readonly: true,
})
export class RegistryValue extends EntityModelBase<string> {

	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'EvidenceId', required: false })
	evidenceId?: string;

}
