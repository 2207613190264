<div
	[attr.aria-label]="cardData.title"
	[attr.aria-describedby]="'recommendationDetails' + cardData.recommendationId"
	role="button"
	class="security-recommendations-card pointer wcd-flex-vertical wcd-margin-horizontal wcd-padding-vertical wcd-padding-left wcd-padding-small-right"
 	(click)="navigateToRecommendations(cardData.recommendationId)"
 	(keydown.enter) = "navigateToRecommendations(cardData.recommendationId)"
	tabindex="0"
	data-track-id="OpenRecommendation"
	data-track-type="Navigation"
	>
	<div class="wcd-margin-small-bottom wcd-font-size-base wcd-font-weight-bold nowrap wcd-text-overflow-ellipsis" [wcdTooltip]="cardData.title">{{cardData.title}}</div>
	<div [attr.id]="'recommendationDetails' + cardData.recommendationId">
		<div class="card-subtitle wcd-margin-small-bottom wcd-font-weight-semibold wcd-font-size-s" [wcdTooltip]="cardData?.exposedMachinesCount?.toLocaleString()">{{cardData.exposedMachinesCount | prettyNumber}} {{('tvm.securityRecommendation.exposedMachines'| i18n)}}</div>

		<div class="wcd-flex-spaceBetween-horizontal wcd-flex-center-vertical wcd-flex-wrap">
			<div class="wcd-flex wcd-flex-wrap">
				<tvm-impact-score class="wcd-margin-small-right" [scoreData]="{exposureScore: cardData.scoreData?.exposureScore, secureScore: cardData.scoreData?.secureScore, exposureScoreTooltip: exposureTooltip, secureScoreTooltip: configurationTooltip}"></tvm-impact-score>
				<tags-list *ngIf="cardData.tags?.length" [tags]="cardData.tags"></tags-list>
			</div>
			<tvm-threat-icons [config]="threatIconsComponentConfig"></tvm-threat-icons>
		</div>
	</div>
</div>
