var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { VulnerabilityChangeEventType } from '@wcd/domain';
import { EmailNotificationFormData } from '../../../../email-notifications/components/email-notification-model';
var VulnerabilityNotificationRuleFormData = /** @class */ (function (_super) {
    __extends(VulnerabilityNotificationRuleFormData, _super);
    function VulnerabilityNotificationRuleFormData(vulnerabilityNotificationRuleFormData) {
        if (vulnerabilityNotificationRuleFormData === void 0) { vulnerabilityNotificationRuleFormData = {
            id: undefined,
            name: '',
            description: '',
            recipients: [],
            severityLevelForNewCve: undefined,
            eventTypes: undefined,
            machineGroups: [],
            allRbacGroups: false,
            isEnabled: true,
            includeOrgName: true,
            eTag: '',
        }; }
        var _this = _super.call(this, vulnerabilityNotificationRuleFormData) || this;
        _this.severityLevelForNewCve = vulnerabilityNotificationRuleFormData.severityLevelForNewCve;
        var eventTypesInitialState = new Map();
        Object.keys(VulnerabilityChangeEventType).forEach(function (e) { return eventTypesInitialState[e] = false; });
        _this.eventTypes = vulnerabilityNotificationRuleFormData.eventTypes ? vulnerabilityNotificationRuleFormData.eventTypes : eventTypesInitialState;
        _this.isEnabled = vulnerabilityNotificationRuleFormData.isEnabled;
        _this.eTag = vulnerabilityNotificationRuleFormData.eTag;
        return _this;
    }
    return VulnerabilityNotificationRuleFormData;
}(EmailNotificationFormData));
export { VulnerabilityNotificationRuleFormData };
