import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ElementRef, Injector, OnDestroy, ViewContainerRef, } from '@angular/core';
import { fitPosition } from '../shared/position.service';
import { generateChanges } from '@wcd/angular-extensions';
import { LiveAnnouncer } from "@angular/cdk/a11y";
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/a11y";
var TOOLTIP_CLASS_NAME = 'wcd-tooltip';
var TOOLTIP_ID = 'wcd-tooltip';
var TOOLTIP_VISIBLE_CLASS_NAME = 'wcd-tooltip__visible';
var TOOLTIP_ACTIONABLE_CLASS_NAME = 'wcd-tooltip__actionable';
var TooltipsService = /** @class */ (function () {
    function TooltipsService(injector, resolver, liveAnnouncer) {
        this.injector = injector;
        this.resolver = resolver;
        this.liveAnnouncer = liveAnnouncer;
        this._isMouseOver = false;
        this.mouseenterCallback = this.onmouseenter.bind(this);
        this.mouseleaveCallback = this.onmouseleave.bind(this);
        this.keypressCallback = this.onkeypress.bind(this);
        this.keydownCallback = this.onkeydown.bind(this);
        this.keyupCallback = this.onkeyup.bind(this);
    }
    TooltipsService.prototype.createTextTooltipElementContainer = function () {
        this.tooltipTextElement = document.createElement('div');
        this.tooltipTextElement.className = TOOLTIP_CLASS_NAME;
        this.tooltipTextElement.id = TOOLTIP_ID;
        document.body.appendChild(this.tooltipTextElement);
    };
    TooltipsService.prototype.registerEventListenersToTootip = function () {
        this.tooltipElement && this.tooltipElement.addEventListener('mouseenter', this.mouseenterCallback);
        this.tooltipElement && this.tooltipElement.addEventListener('mouseleave', this.mouseleaveCallback);
    };
    TooltipsService.prototype.unregisterEventListenersToTootip = function () {
        this.tooltipElement && this.tooltipElement.removeEventListener('mouseenter', this.mouseenterCallback);
        this.tooltipElement && this.tooltipElement.removeEventListener('mouseleave', this.mouseleaveCallback);
    };
    TooltipsService.prototype.registerEventListenersToWindow = function () {
        window.addEventListener('keypress', this.keypressCallback);
        window.addEventListener('keydown', this.keydownCallback);
        window.addEventListener('keyup', this.keyupCallback);
    };
    TooltipsService.prototype.unregisterEventListenersToWindow = function () {
        window.removeEventListener('keypress', this.keypressCallback);
        window.removeEventListener('keydown', this.keydownCallback);
        window.removeEventListener('keyup', this.keyupCallback);
    };
    TooltipsService.prototype.registerViewContainerRef = function (vcRef) {
        this.innerComponentViewRef = vcRef;
    };
    TooltipsService.prototype.registerTooltipContainerElement = function (vcRef) {
        this.innerComponentContainerElement = vcRef;
        this.innerComponentContainerElement.nativeElement.addEventListener('mouseenter', this.onmouseenter.bind(this));
        this.innerComponentContainerElement.nativeElement.addEventListener('mouseleave', this.onmouseleave.bind(this));
    };
    TooltipsService.prototype.unregisterViewContainerRef = function () {
        this.innerComponentViewRef.clear();
        this.innerComponentViewRef = null;
    };
    TooltipsService.prototype.unregisterTooltipContainerElement = function () {
        this.innerComponentContainerElement = null;
    };
    TooltipsService.prototype.ngOnDestroy = function () {
        this.clearLastTooltip();
        this.tooltipTextElement && this.tooltipTextElement.parentNode.removeChild(this.tooltipTextElement);
    };
    TooltipsService.prototype.setCustomComponentTooltip = function (componentType, tooltipRemovedCallback, componentInputs) {
        if (this.tooltipElement) {
            // We call this in case a new tooltip is added before last one is cleared due to race condition
            this.innerComponentViewRef.clear();
            this.clearLastTooltip();
        }
        this.tooltipRemovedCallback = tooltipRemovedCallback;
        var factory = this.resolver.resolveComponentFactory(componentType);
        this.detailsComponentRef = factory ? factory.create(this.injector) : null;
        if (this.detailsComponentRef) {
            Object.assign(this.detailsComponentRef.instance, {
                options: {},
            }, componentInputs);
            var changes = generateChanges(this.detailsComponentRef.instance, componentInputs);
            this.detailsComponentRef.instance.ngOnChanges &&
                this.detailsComponentRef.instance.ngOnChanges(changes);
            this.innerComponentViewRef.insert(this.detailsComponentRef.hostView, 0);
            this.tooltipElement = this.innerComponentContainerElement.nativeElement;
            this.registerEventListenersToTootip();
            this.registerEventListenersToWindow();
        }
    };
    TooltipsService.prototype.setTextTooltip = function (tooltip, tooltipRemovedCallback, renderAsHtml) {
        if (!this.tooltipTextElement) {
            this.createTextTooltipElementContainer();
        }
        if (this.tooltipElement) {
            // We call this in case a new tooltip is added before last one is cleared due to race condition
            this.clearLastTooltip();
        }
        this.tooltipRemovedCallback = tooltipRemovedCallback;
        if (renderAsHtml) {
            this.tooltipTextElement.innerHTML = tooltip;
        }
        else {
            this.tooltipTextElement.innerText = tooltip;
        }
        this.tooltipElement = this.tooltipTextElement;
        this.registerEventListenersToTootip();
        this.registerEventListenersToWindow();
    };
    TooltipsService.prototype.clearLastTooltip = function () {
        if (this.tooltipRemovedCallback) {
            this.tooltipRemovedCallback();
        }
        this.toggleVisibility(false);
        this.unregisterTooltipEvents();
    };
    TooltipsService.prototype.unregisterTooltipEvents = function () {
        this.unregisterEventListenersToTootip();
        this.unregisterEventListenersToWindow();
        this.innerComponentViewRef && this.innerComponentViewRef.clear();
        this.tooltipRemovedCallback = null;
        this.tooltipElement = null;
    };
    TooltipsService.prototype.setTooltipClassName = function (className) {
        this.tooltipElement.className = TOOLTIP_CLASS_NAME + " " + className;
    };
    TooltipsService.prototype.toggleVisibility = function (isTooltipVisible) {
        if (!this.tooltipElement) {
            return;
        }
        // The actionable tooltip can't stay when the tooltip is visible because the tooltip has display:block style even when it's not displayed
        // It's visibility is controlled by opacity. Therefore, when the the tooltip is hidden,
        // it's actually rendered and transparent and blocks mouse events on underlying elements
        // To prevent this blocking, pointer-events: none style has been added, which we remove for actionable tooltips
        // We need to remove pointer-events: none only while the tooltip is displayed,
        // I tried to change to display:none when tooltip isn't visible, but the it's rendered outside of page bounds when the tooltip is near the edge
        if (isTooltipVisible) {
            this.tooltipElement.classList.add(TOOLTIP_VISIBLE_CLASS_NAME);
            this.tooltipElement.classList.add(TOOLTIP_ACTIONABLE_CLASS_NAME);
        }
        else {
            this.tooltipElement.classList.remove(TOOLTIP_VISIBLE_CLASS_NAME, TOOLTIP_ACTIONABLE_CLASS_NAME);
        }
    };
    TooltipsService.prototype.setTooltipPosition = function (position, dimensions) {
        if (!this.tooltipElement) {
            return null;
        }
        var tooltipPosition = fitPosition(position, dimensions, 10, 10);
        this.tooltipElement.style.top = tooltipPosition.top + 'px';
        this.tooltipElement.style.left = tooltipPosition.left + 'px';
    };
    TooltipsService.prototype.getTooltipDimensions = function () {
        if (!this.tooltipElement) {
            return null;
        }
        this.tooltipElement.style.removeProperty('width');
        this.tooltipElement.style.removeProperty('height');
        return {
            width: this.tooltipElement.clientWidth,
            height: this.tooltipElement.clientHeight,
        };
    };
    TooltipsService.prototype.onmouseenter = function () {
        this._isMouseOver = true;
    };
    TooltipsService.prototype.onkeypress = function () {
        this.onmouseleave();
    };
    TooltipsService.prototype.onkeydown = function () {
        this.onmouseleave();
    };
    TooltipsService.prototype.onkeyup = function () {
        this.onmouseleave();
    };
    TooltipsService.prototype.onmouseleave = function () {
        this._isMouseOver = false;
        this.toggleVisibility(false);
    };
    Object.defineProperty(TooltipsService.prototype, "isMouseOver", {
        // This property only works correctly when isActionable is true because otherwise, the mouse events are disabled and we don't know whether the mouse is over the tooltip or not
        // When isActionable is false, this will always return false
        get: function () {
            return this._isMouseOver && this.isVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TooltipsService.prototype, "isVisible", {
        get: function () {
            return this.tooltipElement.classList.contains(TOOLTIP_VISIBLE_CLASS_NAME);
        },
        enumerable: true,
        configurable: true
    });
    TooltipsService.prototype.announce = function (tooltipText) {
        this.liveAnnouncer.announce(tooltipText);
    };
    TooltipsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TooltipsService_Factory() { return new TooltipsService(i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i1.LiveAnnouncer)); }, token: TooltipsService, providedIn: "root" });
    return TooltipsService;
}());
export { TooltipsService };
