import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { ExtendedOutbreak, OutbreakCalculationStatusType } from '@wcd/domain';

@ValueObject({
	singularName: 'Extended outbreaks calculation',
	pluralName: 'Extended outbreaks calculation',
	readonly: true,
})
export class ExtendedOutbreaksCalculation extends ModelBase {
	@EntityField({ data: ['Items', 'items'], arrayOf: ExtendedOutbreak, defaultValue: [] })
	items: Array<ExtendedOutbreak>;

	// This field will not be optional once ThreatAnalyticsMTP is in GA
	@EntityField({ data: 'DevicesCalculationStatus' })
	devicesCalculationStatus?: OutbreakCalculationStatusType;

	// This field will not be optional once ThreatAnalyticsMTP is in GA
	@EntityField({ data: 'MailboxesCalculationStatus' })
	mailboxesCalculationStatus?: OutbreakCalculationStatusType;

	// This field will not be optional once ThreatAnalyticsMTP is in GA
	@EntityField({ data: 'ThreatExposureCalculationStatus' })
	threatExposureCalculationStatus?: OutbreakCalculationStatusType;
}
