/* tslint:disable:template-click-events-have-key-events */
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { AssessmentType } from '@wcd/domain';
import { AssessmentJobModel } from '../../models/assessment-job.model';
import { ChecklistValue } from '@wcd/forms';
import { I18nService } from '@wcd/i18n';
import { AssessmentJobService } from '../../services/assessment-job.service';

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './assessment-job-job-type-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentJobJobTypeStepComponent extends WizardBaseStep<AssessmentJobModel>
	implements OnInit {
	readonly assessmentTypeValues: Array<ChecklistValue>;

	selectedAssessmentType: ChecklistValue | null;

	constructor(private readonly i18nService: I18nService, public readonly assessmentJobService: AssessmentJobService) {
		super();

		this.assessmentTypeValues = [
			{
				id: AssessmentType.NetworkAssessment,
				name: this.i18nService.strings.tvm_authenticatedScan_wizard_networkScanType_title,
				description: this.i18nService.strings.tvm_authenticatedScan_wizard_networkScanType_description,
			},
			{
				id: AssessmentType.WindowsAssessment,
				name: this.i18nService.strings.tvm_authenticatedScan_wizard_windowsScanType_title,
				description: this.assessmentJobService.isWindowsScanLicenseEnabled() ? this.i18nService.strings.tvm_authenticatedScan_wizard_windowsScanType_description_withLicense : this.i18nService.strings.tvm_authenticatedScan_wizard_windowsScanType_description_noLicense,
				disabled: !this.assessmentJobService.isWindowsScanLicenseEnabled(),
			},
		];
	}

	ngOnInit(): void {
		if (this.data.assessmentJob.scanType) {
			this.selectedAssessmentType = this.assessmentTypeValues.find(value => value.id === this.data.assessmentJob.scanType);
		}

		this.setStepValidation(!!this.data.assessmentJob.scanType);
	}

	onAssessmentTypeChange(): void {
		this.data.assessmentJob.scanType = this.selectedAssessmentType.id;

		this.setStepValidation(!!this.data.assessmentJob.scanType);
	}
}
