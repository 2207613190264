import { FabricIconNames } from '@wcd/scc-common';

import { EntitiesDisplayMap } from '../entity-display.model';

export const defaultEntitiesDisplayMap: Required<EntitiesDisplayMap> = {
	Alert: {
		icon: FabricIconNames.LightningBolt,
	},
	Url: {
		icon: FabricIconNames.Website,
	},
	Ip: {
		icon: FabricIconNames.Streaming,
	},
	User: {
		icon: FabricIconNames.Contact,
	},
	File: {
		icon: FabricIconNames.Page,
	},
	Machine: {
		icon: FabricIconNames.System,
	},
	UserObjectId: {
		icon: FabricIconNames.Contact,
	},
	UserSid: {
		icon: FabricIconNames.Contact,
	},
};
