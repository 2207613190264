var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { HuntingQueryBase } from './hunting-query.entity.base';
import { Entity } from '@microsoft/paris';
var ɵ0 = function (item) { return (item.id ? 'PATCH' : 'POST'); }, ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; };
/**
 * @deprecated use HuntingSavedQuery instead
 */
var HuntingUserQuery = /** @class */ (function (_super) {
    __extends(HuntingUserQuery, _super);
    function HuntingUserQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.kind = 'user';
        _this.shared = false;
        _this.isScheduled = false;
        return _this;
    }
    HuntingUserQuery = __decorate([
        Entity({
            singularName: 'User query',
            pluralName: 'User queries',
            endpoint: 'hunting/queries',
            allItemsEndpoint: '/user',
            saveMethod: ɵ0,
            baseUrl: ɵ1,
        })
    ], HuntingUserQuery);
    return HuntingUserQuery;
}(HuntingQueryBase));
export { HuntingUserQuery };
export { ɵ0, ɵ1 };
