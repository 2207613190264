import { ComponentRef, Injector } from '@angular/core';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { InvestigationCommentsPanelComponent } from '../../investigations_common/components/investigation-comments.panel.component';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { AuthService } from '@wcd/auth';
import { InvestigationStatus, MtpInvestigation } from '@wcd/domain';
import { InvestigationsBackendService } from '../../investigations/services/investigations.backend.service';
import { CancelInvestigationModalComponent } from '../../investigations_common/components/cancel-investigation.modal';
import { DimensionsModel } from '../../../dialogs/models/dimensions.model';
import { never, of } from 'rxjs';
import { Paris } from '@microsoft/paris';
import { TagsStore } from '../../../tags/services/tags.store';
import { RbacService } from '../../../rbac/services/rbac.service';
import { Router } from '@angular/router';
import { catchError, mergeMap } from 'rxjs/operators';
import { Feature, FeaturesService, PollingService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { compact, keyBy } from 'lodash-es';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { sccHostService } from '@wcd/scc-interface';
export var INVESTIGATION_REFRESH_RATE = 5000;
var SEVILLE_STATUS_TO_STATUS = keyBy(InvestigationStatus.entityConfig.values, 'sevilleStatusId');
var MtpInvestigationsService = /** @class */ (function () {
    function MtpInvestigationsService(dialogsService, authService, tagsStore, paris, injector, router, featuresService, i18nService, backendService, pollingService) {
        this.dialogsService = dialogsService;
        this.authService = authService;
        this.tagsStore = tagsStore;
        this.paris = paris;
        this.injector = injector;
        this.router = router;
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.backendService = backendService;
        this.pollingService = pollingService;
    }
    MtpInvestigationsService.prototype.showCurrentInvestigationCommentsPanel = function (investigation, investigationGraphData) {
        var _this = this;
        var panelSettings = {
            id: 'investigation-comments-panel',
            type: PanelType.medium,
            isModal: true,
            showOverlay: false,
            isBlocking: true,
            noBodyPadding: true,
            scrollBody: false,
        };
        var panelInputs = {
            investigation: investigation,
            investigationGraphData: investigationGraphData,
        };
        this.dialogsService
            .showPanel(InvestigationCommentsPanelComponent, panelSettings, panelInputs)
            .subscribe(function (panel) {
            _this._commentsPanel = panel;
            panel.onDestroy(function () {
                _this._commentsPanel = null;
            });
        });
    };
    MtpInvestigationsService.prototype.getInvestigationPolling = function (investigationId) {
        var _this = this;
        var poll$ = this.pollingService
            .poll(0, INVESTIGATION_REFRESH_RATE)
            .pipe(mergeMap(function () {
            return _this.paris
                .getItemById(MtpInvestigation, investigationId, null, {
                tenantId: sccHostService.loginUser.tenantId,
            })
                .pipe(catchError(function (error) {
                if (error.status === 403 &&
                    _this.featuresService.isEnabled(Feature.RbacMachineGroups)) {
                    var rbacService = _this.injector.get(RbacService);
                    rbacService.addToExposureCache(MtpInvestigation, String(investigationId), { isExposed: false });
                    rbacService.showNoAccessModal(_this.i18nService.strings.rbac_accessDenied_description_investigation);
                    _this.router.navigate(['/investigations']);
                    return never();
                }
                else {
                    console.warn("Investigation polling failed. trying again in " + INVESTIGATION_REFRESH_RATE /
                        1000 + " seconds.");
                    return of(error);
                }
            }));
        }));
        return poll$;
    };
    MtpInvestigationsService.prototype.getInvestigationActions = function (investigation) {
        return this.getInvestigationsActions([investigation]);
    };
    MtpInvestigationsService.prototype.getInvestigationsActions = function (investigations) {
        var actions = [
        // new ItemActionModel({
        // 	id: 'investigationTags',
        // 	rbac: ['alertsInvestigation'],
        // 	nameKey: 'tags.manage',
        // 	type: ItemActionType.Tags,
        // 	icon: 'tag',
        // 	refreshOnResolve: true,
        // 	options: {
        // 		tags: this.getInvestigationTagsSettings(investigations),
        // 	},
        // }),
        ];
        if (investigations.every(function (investigation) { return investigation.isRunning && !investigation.isOfficeInvestigation; }))
            actions.push(this._investigationDataViewActions.cancel);
        return compact(actions);
    };
    Object.defineProperty(MtpInvestigationsService.prototype, "_investigationDataViewActions", {
        get: function () {
            if (!this._investigationDataViewActionsIndex) {
                var investigationActions = compact([
                    !this.authService.currentUser.isReadonly
                        ? {
                            id: 'cancel',
                            nameKey: 'cancel',
                            icon: 'stopInvestigation',
                            method: this.cancelInvestigations.bind(this),
                            tooltip: this.i18nService.strings.investigations_actions_cancel_tooltip,
                            refreshOnResolve: true,
                            rbac: [RbacMdeAllowedActions.alertsInvestigation],
                        }
                        : null,
                ]).map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); });
                this._investigationDataViewActionsIndex = keyBy(investigationActions, 'id');
            }
            return this._investigationDataViewActionsIndex;
        },
        enumerable: true,
        configurable: true
    });
    MtpInvestigationsService.prototype.cancelInvestigations = function (investigations, options) {
        var _this = this;
        var runningInvestigations = investigations &&
            investigations.filter(function (investigation) { return investigation.isRunning && !investigation.isOfficeInvestigation; });
        return new Promise(function (resolve, reject) {
            _this.getCancelInvestigationComment('investigation', runningInvestigations ? runningInvestigations.length : null).then(function (cancelData) {
                _this.backendService
                    .cancelInvestigations(runningInvestigations
                    ? runningInvestigations.map(function (investigation) { return investigation.id; })
                    : null, cancelData, options)
                    .subscribe(function (result) {
                    var count = result && result.count !== undefined
                        ? result.count
                        : (investigations && investigations.length) || 0, notificationText = count
                        ? count > 1
                            ? _this.i18nService.get('investigations_actions_cancel_successMessage_plural', {
                                investigationCount: count,
                            })
                            : _this.i18nService.strings
                                .investigations_actions_cancel_successMessage_singular
                        : _this.i18nService.strings
                            .investigations_actions_cancel_successMessage_general;
                    _this.dialogsService.showSnackbar({
                        text: notificationText,
                        icon: 'stopInvestigation',
                    });
                    resolve(result);
                }, function (error) {
                    var title = _this.i18nService.strings
                        .investigations_actions_cancel_errorMessage;
                    _this.dialogsService.showError({
                        title: title,
                        data: error,
                    });
                    reject(title);
                });
            }, function (error) { return reject(error); });
        });
    };
    MtpInvestigationsService.prototype.getCancelInvestigationComment = function (cancelReasonsType, investigationCount, hostName) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var modal;
            _this.dialogsService
                .showModal(CancelInvestigationModalComponent, {
                id: 'cancel-investigation-modal',
                dimensions: new DimensionsModel(480, 350),
                title: cancelReasonsType === 'host'
                    ? investigationCount > 1
                        ? _this.i18nService.strings
                            .investigations_actions_cancel_approvalMessage_plural_host
                        : _this.i18nService.strings
                            .investigations_actions_cancel_approvalMessage_singular_host
                    : investigationCount > 1
                        ? _this.i18nService.strings
                            .investigations_actions_cancel_approvalMessage_plural
                        : _this.i18nService.strings
                            .investigations_actions_cancel_approvalMessage_singular,
            }, {
                investigationCount: investigationCount,
                reasonsType: cancelReasonsType,
                confirm: function (cancelData) {
                    resolve(cancelData);
                    modal.destroy();
                },
                cancel: function () { return modal.destroy(); },
                hostName: hostName,
            })
                .subscribe(function (_modal) {
                modal = _modal;
                _modal.onDestroy(function () { return reject(); });
            });
        });
    };
    /**
     * This is only for AngularJS, which can't access Paris directly.
     * @param {string} investigationStatusTypeName
     * @returns {InvestigationStatus}
     */
    MtpInvestigationsService.prototype.getInvestigationStatusByTypeName = function (investigationStatusTypeName) {
        return this.paris.getValue(InvestigationStatus, function (investigationStatus) {
            return investigationStatus.type.toLowerCase() === investigationStatusTypeName.toLowerCase();
        });
    };
    MtpInvestigationsService.getInvestigationStatusBySevilleStatus = function (sevilleStatus) {
        var statusIdInt = parseInt(sevilleStatus, 10);
        if (isNaN(statusIdInt)) {
            return null;
        }
        return SEVILLE_STATUS_TO_STATUS[statusIdInt];
    };
    MtpInvestigationsService.getInvestigationLink = function (investigation) {
        var url = investigation && investigation.isOfficeInvestigation ? 'mtp-investigation' : 'investigation';
        return investigation
            ? (investigation.isLiveResponse && "/live-response/" + investigation.id) ||
                "/" + url + "/" + investigation.id
            : null;
    };
    return MtpInvestigationsService;
}());
export { MtpInvestigationsService };
