/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-name-field.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/shared/src/lib/components/route-link.component.ngfactory";
import * as i3 from "../../../../../../../projects/shared/src/lib/components/route-link.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../../../../projects/shared/src/lib/services/routes.service";
import * as i6 from "@angular/common";
import * as i7 from "../../../tags/components/tag/tag.component.ngfactory";
import * as i8 from "../../../tags/components/tag/tag.component";
import * as i9 from "../../../tags/services/tags.service";
import * as i10 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i11 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i12 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i15 from "./alert-name-field.component";
import * as i16 from "../services/alert.entity-type.service";
import * as i17 from "../services/alerts.service";
var styles_AlertNameFieldComponent = [i0.styles];
var RenderType_AlertNameFieldComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AlertNameFieldComponent, data: {} });
export { RenderType_AlertNameFieldComponent as RenderType_AlertNameFieldComponent };
function View_AlertNameFieldComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_AlertNameFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "route-link", [["className", "field-value-link wcd-flex wcd-vertical-align-center"], ["data-track-id", "AlertNameLinkToAlertPage"], ["data-track-type", "Navigation"]], null, null, null, i2.View_RouteLinkComponent_0, i2.RenderType_RouteLinkComponent)), i1.ɵdid(1, 573440, null, 0, i3.RouteLinkComponent, [i4.UrlHandlingStrategy, i4.Router, i5.RoutesService], { routeLink: [0, "routeLink"], className: [1, "className"], linkTabIndex: [2, "linkTabIndex"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AlertNameFieldComponent_2)), i1.ɵdid(4, 540672, null, 0, i6.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; var currVal_1 = "field-value-link wcd-flex wcd-vertical-align-center"; var currVal_2 = _co.linkTabIndex; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v.parent, 5); _ck(_v, 4, 0, currVal_3); }, null); }
function View_AlertNameFieldComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "tag", [["className", "wcd-margin-xsmall-right tag-color-box-threatExpert wcd-flex-none"]], null, null, null, i7.View_TagComponent_0, i7.RenderType_TagComponent)), i1.ɵdid(1, 573440, null, 0, i8.TagComponent, [i9.TagsService, i10.I18nService], { className: [0, "className"], tag: [1, "tag"] }, null), i1.ɵppd(2, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-margin-xsmall-right tag-color-box-threatExpert wcd-flex-none"; var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), _co.alert.detectionSource.nameI18nKey)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AlertNameFieldComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tag", [["className", "wcd-margin-xsmall-right wcd-flex-none tag-color-box-actor"]], null, null, null, i7.View_TagComponent_0, i7.RenderType_TagComponent)), i1.ɵdid(1, 573440, null, 0, i8.TagComponent, [i9.TagsService, i10.I18nService], { className: [0, "className"], tag: [1, "tag"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-margin-xsmall-right wcd-flex-none tag-color-box-actor"; var currVal_1 = _co.alert.actor; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AlertNameFieldComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertNameFieldComponent_5)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertNameFieldComponent_6)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert.isThreatExpertOriginated; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.alert.actor; _ck(_v, 6, 0, currVal_1); }, null); }
function View_AlertNameFieldComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "wcd-flex-items-center router-link-text wcd-text-overflow-ellipsis"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertNameFieldComponent_4)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "nowrap wcd-text-overflow-ellipsis wcd-field-alert-name"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 147456, null, 0, i11.TooltipDirective, [i1.ElementRef, i12.TooltipsService, i13.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i1.ɵted(8, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTags; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.alert.name; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.alert.isGroup ? ((_co.alert.groupedAlertsCount + " alerts: ") + _co.alert.name) : _co.alert.name); _ck(_v, 8, 0, currVal_2); }); }
export function View_AlertNameFieldComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i14.I18nPipe, [i10.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertNameFieldComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(0, [["contents", 2]], null, 0, null, View_AlertNameFieldComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertEntityTypeService.entityType.getNavigationModel(_co.alert); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_AlertNameFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alert-name-field", [], null, null, null, View_AlertNameFieldComponent_0, RenderType_AlertNameFieldComponent)), i1.ɵdid(1, 49152, null, 0, i15.AlertNameFieldComponent, [i16.AlertEntityTypeService, i17.AlertsService], null, null)], null, null); }
var AlertNameFieldComponentNgFactory = i1.ɵccf("alert-name-field", i15.AlertNameFieldComponent, View_AlertNameFieldComponent_Host_0, { alert: "alert", showTags: "showTags", linkTabIndex: "linkTabIndex" }, {}, []);
export { AlertNameFieldComponentNgFactory as AlertNameFieldComponentNgFactory };
