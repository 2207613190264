import { SevilleModule } from '../seville.module';

SevilleModule.run([
	'$location',
	'$rootScope',
	'$http',
	function($location, $rootScope, $http) {
		$rootScope.$on('$stateChangeStart', function() {
			$http.pendingRequests.forEach(request => $http.abort(request));
		});
	},
]);

SevilleModule.config([
	'$provide',
	function($provide) {
		$provide.decorator('$http', [
			'$delegate',
			'$q',
			'$log',
			'$httpParamSerializer',
			function($delegate, $q, $log, $httpParamSerializer) {
				var getFn = $delegate.get;
				var postFn = $delegate.post;
				var cancelerMap = {};

				function getCancelerKey(method, url, params) {
					var formattedMethod = method.toLowerCase();
					var formattedUrl = encodeURI(url)
						.toLowerCase()
						.trim();
					if (params) {
						formattedUrl += '?' + $httpParamSerializer(params);
					}

					return formattedMethod + '~' + formattedUrl;
				}

				function mapCanceler(key, canceler) {
					if (!cancelerMap[key]) {
						cancelerMap[key] = [];
					}

					cancelerMap[key].push(canceler);
				}

				$delegate.post = function() {
					var cancelerKey, canceler, method;
					var args = [].slice.call(arguments);
					var url = args[0];
					var params = null; //arguments[1] ? Object.keys(arguments[1]) : null;
					var config = args.length == 3 ? args[2] : {};
					if (config.timeout == null) {
						method = 'POST';
						cancelerKey = getCancelerKey(method, url, params);
						canceler = $q.defer();
						mapCanceler(cancelerKey, canceler);
						config.timeout = canceler.promise;
						args[2] = config;
					}

					return postFn.apply(null, args);
				};

				$delegate.get = function() {
					var cancelerKey, canceler, method;
					var args = [].slice.call(arguments);
					var url = args[0];
					var params = arguments[1] && arguments[1].params ? arguments[1].params : null;
					var config = args[1] || {};
					if (config.timeout == null) {
						method = 'GET';
						cancelerKey = getCancelerKey(method, url, params);
						canceler = $q.defer();
						mapCanceler(cancelerKey, canceler);
						config.timeout = canceler.promise;
						args[1] = config;
					}
					return getFn.apply(null, args);
				};

				$delegate.abort = function(request) {
					var cancelerKey, canceler;
					cancelerKey = getCancelerKey(request.method, request.url, request.params);

					if (cancelerMap[cancelerKey]) {
						for (var i = 0; i < cancelerMap[cancelerKey].length; i++) {
							canceler = cancelerMap[cancelerKey][i];
							if (canceler != null) {
								$log.debug('aborting', cancelerKey);

								if (request.timeout != null && typeof request.timeout !== 'number') {
									canceler.resolve();
								}
							}
						}

						delete cancelerMap[cancelerKey];
					}
				};

				return $delegate;
			},
		]);
	},
]);
