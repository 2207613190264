import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { AadUserEntityTypeService } from './aad-user.entity-type.service';
import { AadUserEntityPanelComponent } from './components/aad-user-entity-panel.component';
import { AadUserStatusComponent } from './components/aad-user-entity-status.component';
import { AadUserStatusDisplayNameComponent } from './components/aad-user-status-display-name.component';
import { DataTableModule } from '@wcd/datatable';
import { FabShimmerModule, FabSpinnerModule } from '@angular-react/fabric';
import { ReportsModule } from '../../../reports/reports.module';
import { AlertsModule } from '../../alerts/alerts.module';
import { GoHuntModule } from '../../../hunting-go-hunt/hunting-go-hunt.module';

const entityComponents = [
	AadUserEntityPanelComponent,
	AadUserStatusComponent,
	AadUserStatusDisplayNameComponent,
];

@NgModule({
	imports: [
		SharedModule,
		DataViewsModule,
		DataTableModule,
		GlobalEntitiesModule,
		FabSpinnerModule,
		FabShimmerModule,
		ReportsModule,
		AlertsModule,
		GoHuntModule,
	],
	providers: [AadUserEntityTypeService],
	declarations: entityComponents,
	entryComponents: entityComponents,
	exports: [AadUserStatusDisplayNameComponent],
})
export class AadUserEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(AadUserEntityTypeService);
	}
}
