<section class="wcd-margin-vertical wcd-margin-large-horizontal">
	<section>
		<entity-details-section-header titleKey="files.entityDetails.sections.details.title"></entity-details-section-header>
		<file-entity-details [entity]="file"></file-entity-details>
	</section>

	<ng-container *ngIf="file.id">
	<section class="wcd-margin-large-top">
		<entity-details-section-header titleKey="files.entityDetails.sections.detections.title"></entity-details-section-header>
		<dl class="key-values" role="none">
			<dt>{{ 'files.entityDetails.sections.detections.fields.alerts.title' | i18n }}</dt>
			<dd>
				<wcd-contents-state [contentState]="alertsSeveritySummaryContentState">
					<ng-container loading *ngTemplateOutlet="loading"></ng-container>
					<ng-container error *ngTemplateOutlet="error"></ng-container>
					<ng-container complete *ngTemplateOutlet="completeOrEmpty"></ng-container>
					<ng-container empty *ngTemplateOutlet="completeOrEmpty"></ng-container>

					<ng-template #completeOrEmpty>
						<severity-summary [severitiesCountMap]="alertsSeveritiesCountMap" size="compact"></severity-summary>
					</ng-template>
				</wcd-contents-state>
			</dd>

			<dt>{{ 'files.entityDetails.sections.detections.fields.virusTotal.title' | i18n }}</dt>
			<dd>
				<wcd-contents-state [contentState]="virusTotalContentState">
					<ng-container loading *ngTemplateOutlet="loading"></ng-container>
					<ng-container error *ngTemplateOutlet="error"></ng-container>
					<ng-container complete><value-with-external-link [context]="virusTotalContext"></value-with-external-link></ng-container>
					<ng-container empty>{{ 'files.entityDetails.sections.detections.fields.virusTotal.noneValue' | i18n }}</ng-container>
				</wcd-contents-state>
			</dd>

			<ng-container *ngIf="!isFileVerdictEnabled">
				<dt>{{ 'files.entityDetails.sections.detections.fields.windowsDefenderAv.title' | i18n }}</dt>
				<dd>
					<ng-container *ngIf="windowsDefenderAvContext; else empty">
						<ng-container><value-with-external-link [context]="windowsDefenderAvContext"></value-with-external-link></ng-container>
					</ng-container>

					<ng-template #empty>
						{{ 'files.entityDetails.sections.detections.fields.windowsDefenderAv.noneValue' | i18n }}
					</ng-template>
				</dd>
			</ng-container>
		</dl>
		<section *ngIf="isFileVerdictEnabled" class="wcd-padding-vertical">
			<h3 class="wcd-font-size-m">
				{{ 'files.entityDetails.sections.detections.fields.malwareDetected.title' | i18n }}
			</h3>
			<file-malware-detected [file]="entity"></file-malware-detected>
		</section>
	</section>

	<section class="wcd-margin-large-top">
		<entity-details-section-header titleKey="files.entityDetails.sections.observedWorldwide.title"></entity-details-section-header>
		<wcd-contents-state [contentState]="fileWorldwideStatsContentState">
			<ng-container loading *ngTemplateOutlet="progressBar;context:{ time: 120000 }"></ng-container>
			<ng-container error *ngTemplateOutlet="error"></ng-container>
			<ng-container empty>{{ 'files.entityDetails.sections.observedWorldwide.noneValue' | i18n }}</ng-container>
			<dl complete class="key-values" role="none">
				<ng-container *ngTemplateOutlet="filePrevalence; context: {
					statsData: worldwideFileStats,
					fieldTranslationKey: 'observedWorldwide'
				}"></ng-container>
			</dl>
		</wcd-contents-state>
	</section>

	<section class="wcd-margin-large-top">
		<entity-details-section-header
			titleKey="files.entityDetails.sections.observedOrganization.title"
			subtitleKey="files.entityDetails.sections.observedOrganization.subtitle">
		</entity-details-section-header>
		<wcd-contents-state [contentState]="fileOrganizationStatsContentState">
			<ng-container loading *ngTemplateOutlet="progressBar;context:{ time: 120000 }"></ng-container>
			<ng-container error *ngTemplateOutlet="error"></ng-container>
			<ng-container empty>{{ 'files.entityDetails.sections.observedOrganization.noneValue' | i18n }}</ng-container>
			<dl complete class="key-values" role="none">
				<ng-container *ngTemplateOutlet="filePrevalence; context: {
					statsData: organizationFileStats,
					fieldTranslationKey: 'observedOrganization'
				}"></ng-container>
			</dl>
		</wcd-contents-state>
	</section>
	</ng-container>
</section>

<ng-template #filePrevalence let-statsData="statsData" let-fieldTranslationKey="fieldTranslationKey">
	<ng-container *ngIf="statsData">
		<ng-container *ngIf="statsData.count">
			<dt>{{ 'files.entityDetails.sections.' + fieldTranslationKey + '.fields.count.title' | i18n }}</dt>
			<dd>{{ statsData.count | prettyNumber }}</dd>
		</ng-container>

		<ng-container *ngIf="statsData.firstSeen">
			<dt>{{ 'files.entityDetails.sections.' + fieldTranslationKey + '.fields.firstSeen.title' | i18n }}</dt>
			<dd>{{ statsData.firstSeen | timeAgo }}</dd>
		</ng-container>

		<ng-container *ngIf="statsData.lastSeen">
			<dt>{{ 'files.entityDetails.sections.' + fieldTranslationKey + '.fields.lastSeen.title' | i18n }}</dt>
			<dd>{{ statsData.lastSeen | timeAgo }}</dd>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #error>
	<span>{{ 'help.externalLoadError' | i18n:{ asset: dataAsset } }}</span>
</ng-template>

<ng-template #loading>
	<fab-spinner contentClass="wcd-inline-block wcd-margin-small-left"></fab-spinner>
</ng-template>

<ng-template #progressBar let-time="time">
	<progress-indicator [time]="time" [label]="'loading' | i18n"></progress-indicator>
</ng-template>
