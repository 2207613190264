var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Paris } from '@microsoft/paris';
import { DataviewField } from '@wcd/dataview';
import { FilterValuesChecklistComponent, FilterValuesChecklistWithSearchComponent, FilterValuesDatePickerComponent, } from '@wcd/ng-filters';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomTiIndicatorActionsType, CustomTiIndicatorActionsTypes, CustomTiIndicatorsProperties, CustomTiIndicatorIdentifierTypes, } from '@wcd/domain';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { MachinesService } from '../../machines/services/machines.service';
import { CustomTiIndicatorsService } from './custom-ti-indicators.service';
import { FabricIconNames } from '@wcd/scc-common';
import { AppFlavorConfig } from '@wcd/scc-common';
var CustomTiIndicatorsFields = /** @class */ (function () {
    function CustomTiIndicatorsFields(i18nService, featureService, flavorService, machineService, paris, customTiService) {
        this.i18nService = i18nService;
        this.featureService = featureService;
        this.flavorService = flavorService;
        this.machineService = machineService;
        this.paris = paris;
        this.customTiService = customTiService;
        this._rbacMachineGroupsEnabled = featureService.isEnabled(Feature.RbacMachineGroups);
        this._tvmApplicationBlockEnabled = featureService.isEnabled(Feature.TVMApplicationBlock);
    }
    CustomTiIndicatorsFields.prototype.setUserExposedMachineGroups = function () {
        var _this = this;
        if (this._rbacMachineGroupsEnabled && !this._machineRbacGroups) {
            return this.machineService.getFullUserExposedMachineGroups().pipe(tap(function (machineGroup) {
                _this._machineRbacGroups = machineGroup;
            }));
        }
        return of(this._machineRbacGroups || []);
    };
    CustomTiIndicatorsFields.prototype.getFields = function (indicatorType) {
        var _this = this;
        return this.setUserExposedMachineGroups().pipe(map(function (machineGroup) {
            var now = new Date();
            _this._fields = DataviewField.fromList([
                {
                    id: CustomTiIndicatorsProperties.Action,
                    name: _this.getEntityFieldsText('action.title'),
                    getDisplay: function (tiIndicator) {
                        var actionName = tiIndicator.action.name.toLowerCase();
                        if (tiIndicator.action.id === CustomTiIndicatorActionsTypes.Block) {
                            actionName =
                                tiIndicator.tiIndicatorType.id ===
                                    CustomTiIndicatorIdentifierTypes.CertificateThumbprint
                                    ? 'blockandremediate'
                                    : 'blockexecution';
                        }
                        return _this.getEntityFieldsText("action.values." + actionName + ".title");
                    },
                    className: 'nowrap',
                    filter: {
                        component: {
                            type: FilterValuesChecklistComponent,
                            config: {
                                mapFilterValue: function (actionTypeId) {
                                    var actionType = _this.paris.getValue(CustomTiIndicatorActionsType, actionTypeId);
                                    if (actionType) {
                                        return {
                                            name: _this.getEntityFieldsText("action.values." + actionType.name.toLowerCase() + ".title"),
                                            id: actionType.id,
                                        };
                                    }
                                    return null;
                                },
                            },
                        },
                    },
                    sort: {
                        enabled: true,
                    },
                },
                {
                    id: CustomTiIndicatorsProperties.Severity,
                    name: _this.getEntityFieldsText('alertSeverity.title'),
                    getDisplay: function (tiIndicator) {
                        if (tiIndicator.action.id === CustomTiIndicatorActionsTypes.Allow ||
                            (_this._tvmApplicationBlockEnabled && !tiIndicator.generateAlert)) {
                            return _this.i18nService.get("customTiIndicator_dataview_entity_fields_alertSeverity_values_na_title");
                        }
                        return _this.i18nService.get("customTiIndicator_dataview_entity_fields_alertSeverity_values_" + tiIndicator.severity.type + "_title");
                    },
                    getCssClass: function (tiIndicator) {
                        if (tiIndicator.action.id === CustomTiIndicatorActionsTypes.Allow ||
                            (_this._tvmApplicationBlockEnabled && !tiIndicator.generateAlert)) {
                            return null;
                        }
                        return "wcd-severity wcd-severity-" + tiIndicator.severity.type;
                    },
                    className: 'nowrap',
                    sort: {
                        enabled: true,
                    },
                }
            ].concat((_this.flavorService.isEnabled(AppFlavorConfig.settings.indicators)
                ? [
                    {
                        id: CustomTiIndicatorsProperties.RbacGroupIds,
                        name: _this.getEntityFieldsText('scope'),
                        className: 'nowrap',
                        getDisplay: function (tiIndicator) {
                            if (!_this._rbacMachineGroupsEnabled ||
                                !tiIndicator.rbacGroupIds ||
                                tiIndicator.rbacGroupIds.length === 0) {
                                return _this.i18nService.get('customTiIndicator.detailsSidePane.sections.organizationalscope.all');
                            }
                            if (tiIndicator.rbacGroupIds.length === 1) {
                                var currentGroup = _this._machineRbacGroups.find(function (group) {
                                    return group.id === tiIndicator.rbacGroupIds[0];
                                });
                                return currentGroup
                                    ? currentGroup.name
                                    : _this.i18nService.get('common_error').toUpperCase();
                            }
                            return tiIndicator.rbacGroupIds.length + " " + _this.i18nService.get('customTiIndicator.detailsSidePane.sections.organizationalscope.groups');
                        },
                        icon: {
                            fabricIcon: function (tiIndicator) {
                                return !_this.allGroupsValid(tiIndicator) &&
                                    FabricIconNames.IncidentTriangle;
                            },
                            className: 'color-text-warning-dark',
                        },
                        getTooltip: function (tiIndicator) {
                            if (!_this.allGroupsValid(tiIndicator)) {
                                return _this.getEntityFieldsText('invalid.device.group.tooltip');
                            }
                            return null;
                        },
                        enabledByDefault: _this._rbacMachineGroupsEnabled,
                    },
                ]
                : []), [
                {
                    id: CustomTiIndicatorsProperties.ExpirationTime,
                    name: _this.getEntityFieldsText('expiresOn.title'),
                    icon: {
                        fabricIcon: function (tiIndicator) {
                            return tiIndicator.expirationTime &&
                                tiIndicator.expirationTime < now &&
                                FabricIconNames.IncidentTriangle;
                        },
                        className: 'color-text-warning-dark',
                    },
                    component: {
                        type: TzDateComponent,
                        getProps: function (tiIndicator) {
                            var utcDateAsLocal = _this.customTiService.reinterpretUtcAsLocalTime(tiIndicator.expirationTime);
                            return {
                                date: utcDateAsLocal,
                                dateFormat: 'mediumDate',
                            };
                        },
                    },
                    filter: {
                        component: {
                            type: FilterValuesDatePickerComponent,
                        },
                    },
                    sort: {
                        enabled: true,
                    },
                    className: 'nowrap',
                    getTooltip: function (tiIndicator) {
                        if (!tiIndicator.expirationTime) {
                            return _this.getEntityFieldsText('expiresOn.values.never');
                        }
                        if (tiIndicator.expirationTime < now) {
                            return _this.getEntityFieldsText('expiresOn.values.expired');
                        }
                        return null;
                    },
                },
                {
                    id: CustomTiIndicatorsProperties.Title,
                    name: _this.getEntityFieldsText('tiTitle.title'),
                    className: 'nowrap',
                    getTooltip: function (tiIndicator) { return tiIndicator.title; },
                    sort: {
                        enabled: true,
                    },
                },
                {
                    id: CustomTiIndicatorsProperties.CreatedByDisplayName,
                    name: _this.getEntityFieldsText('createdBy'),
                    className: 'nowrap',
                    filter: {
                        component: {
                            type: FilterValuesChecklistWithSearchComponent,
                            config: _this.customTiService.getFilterValuesConfig(CustomTiIndicatorsProperties.CreatedByDisplayName, indicatorType),
                        },
                    },
                    sort: {
                        enabled: true,
                    },
                },
                {
                    id: CustomTiIndicatorsProperties.CreationTime,
                    name: _this.getEntityFieldsText('createdOn'),
                    className: 'nowrap',
                    component: {
                        type: TzDateComponent,
                        getProps: function (tiIndicator) {
                            var utcDateAsLocal = _this.customTiService.reinterpretUtcAsLocalTime(tiIndicator.creationTime);
                            return {
                                date: utcDateAsLocal,
                                dateFormat: 'medium',
                            };
                        },
                    },
                    filter: {
                        component: {
                            type: FilterValuesDatePickerComponent,
                        },
                    },
                    sort: {
                        enabled: true,
                    },
                },
                {
                    id: CustomTiIndicatorsProperties.GenerateAlert,
                    name: _this.getEntityFieldsText('generateAlert.title'),
                    filterOnly: true,
                    className: 'nowrap',
                    filter: {
                        component: {
                            type: FilterValuesChecklistComponent,
                            config: {
                                mapFilterValue: function (filterValue) {
                                    return {
                                        id: filterValue,
                                        name: _this.i18nService.get("common." + (filterValue === true ? 'yes' : 'no')),
                                    };
                                },
                            },
                        },
                    },
                    sort: {
                        enabled: true,
                    },
                },
            ]).map(function (field) {
                return !!field.sort && field.sort.enabled === true
                    ? field
                    : __assign({}, field, { sort: { enabled: false } });
            }));
            return _this._fields;
        }));
    };
    CustomTiIndicatorsFields.prototype.getEntityFieldsText = function (suffix) {
        return this.i18nService.get("customTiIndicator.dataview.entity.fields." + suffix);
    };
    CustomTiIndicatorsFields.prototype.allGroupsValid = function (tiIndicator) {
        var _this = this;
        if (tiIndicator.rbacGroupIds && tiIndicator.rbacGroupIds.length > 0) {
            var isValidGroups = tiIndicator.rbacGroupIds.every(function (id) {
                var foundMachineGroup = _this._machineRbacGroups.find(function (group) { return group.id === id; });
                return !!foundMachineGroup;
            });
            return isValidGroups;
        }
        return true;
    };
    return CustomTiIndicatorsFields;
}());
export { CustomTiIndicatorsFields };
