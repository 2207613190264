import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MachineBase } from '../../@entities/machines/models/machine-base.interface';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { Machine } from '@wcd/domain';
import { NavItemModel } from '@wcd/shared';

@Component({
	selector: 'machine-link',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<route-link *ngIf="machine.machineId; else labelOnly" [routeLink]="machineRouteLinkModel">
			<ng-container *ngTemplateOutlet="linkContent"></ng-container>
		</route-link>
		<ng-template #labelOnly>
			<ng-container *ngTemplateOutlet="linkContent"></ng-container>
		</ng-template>
		<ng-template #linkContent>
			<wcd-shared-icon *ngIf="showIcon" iconName="System" class="small-icon color-text-gray-900">
			</wcd-shared-icon>
			<span *ngIf="machine.name; else machineIdLabel">
				<machine-name [machine]="machine.name"></machine-name>
			</span>
			<ng-template #machineIdLabel>{{ machine.machineId }}</ng-template>
		</ng-template>
	`,
})
export class HostLinkComponent implements OnInit {
	constructor(private globalEntityTypesService: GlobalEntityTypesService) {}
	@Input() machine: MachineBase;
	@Input() showIcon: boolean = true;

	machineRouteLinkModel: Partial<NavItemModel>;

	ngOnInit() {
		const machineId = this.machine.machineId;
		this.machineRouteLinkModel = this.globalEntityTypesService.getRouteLinkModel(Machine, <
			Partial<Machine>
		>{ senseMachineId: machineId, name: machineId });
	}
}
