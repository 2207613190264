<!-- tslint:disable:template-accessibility-alt-text -->
<ul
	#primaryUl
	class="tabs"
	[class.wcd-flex-horizontal]="justifyTabs"
	[class.larger-tabs]="tabsKind === 'large'"
	[ngClass]="tabsClass"
	role="tablist"
	[attr.id]="tabPanelId"
	(window:resize)="onResize()"
>
	<ng-container
		*ngFor="let tab of (tabsData | visibleTabs); let i = index">
		<ng-container *ngLet="selectedTabIndex === i as selected">
			<li
				#primaryItem
				*ngIf="!isTabHidden(i)"
				class="tab"
				[class.tab-row]="row"
				[routerLinkActive]="!tab.shouldForceActive && currentRouteIsActive ? 'active' : ''"
				[ngClass]="{ active: selected }"
				[attr.data-test-id]="tab.id"
				[wcdTooltip]="tab.tooltip"
				(keydown.arrowleft)="row && prevTab()"
				(keydown.arrowright)="row && nextTab()"
				(keydown.arrowUp)="!row && prevTab()"
				(keydown.arrowDown)="!row && nextTab()"
				role="presentation"
			>
				<ng-container *ngTemplateOutlet="liContent; context:{tab: tab, index: i, selected: selected, role: 'tab'}"></ng-container>
			</li>
		</ng-container>
	</ng-container>

	<li class="overflow-section tab"
		[class.tab-row]="row"
		[hidden]="!hasOverflowTabs"
		role="presentation">
		<div class="icon-container" role="presentation">
			<button #showMoreBtn
					role="tab"
					[attr.id]="tabPanelId + '_button'"
					class="more-button"
					(click)="onShowMore($event)"
					(keydown.arrowleft)="prevTab()"
					aria-haspopup="true"
					[attr.aria-controls]="tabPanelId + '_menu'"
					[attr.aria-expanded]="isOverflowMenuOpen"
					[attr.aria-selected]="false"
					[focusOn]="focusedTabIndex === tabsData.length"
					[attr.tabindex]="getTabIndexAttribute(false, tabsData.length)"
					[attr.aria-label]="'entityCommon_commandBar_moreOptions' | i18n"
					>
				<wcd-shared-icon iconName="More" [styles]="moreIconStyles"
								 wcdTooltip="{{ 'tabs.show.more.tooltip' | i18n }}"></wcd-shared-icon>
			</button>
		</div>
		<ul [id]="tabPanelId + '_menu'" class="overflow-ul dropdown-menu-shadow overflowMenu" (keydown.esc)="onShowMore($event)"
			[hidden]="!isOverflowMenuOpen" role="menu"
			aria-labelledby="tabPanelId + '_button'"
			#menu>
			<ng-container *ngFor="let tab of tabsData; let i = index;">
				<li *ngIf="isTabHidden(i)" (keydown.tab)="showMoreNextTab($event)" (keydown.shift.tab)="showMorePrevTab($event)"
				(keydown.arrowup)="showMorePrevTab($event, false)"
				(keydown.arrowdown)="showMoreNextTab($event, false)"
				role="none"
				>
					<ng-container *ngTemplateOutlet="liContent; context:{tab: tab, index: i, role: 'menuitem'}"></ng-container>
				</li>
			</ng-container>
		</ul>
	</li>
</ul>

<ng-template #liContent let-tab="tab" let-i="index" let-selected="selected" let-role="role">
	<a
		*ngIf="tab.routerLink; else noLink"
		[attr.data-track-id]="'TabClick_' + tab.id"
		data-track-type="Navigation"
		[routerLink]="tab.routerLink"
		[queryParams]="tab.routerLinkQueryParams"
		[queryParamsHandling]="tab.routerQueryParamsHandling"
		[ngClass]="getTabClassName(tab)"
		[attr.aria-controls]="tabPanelId"
		[attr.aria-selected]="selected"
		[attr.role]="role"
		[attr.aria-disabled]="tab.disabled"
		[focusOn]="focusedTabIndex === i"
		[attr.tabindex]="getTabIndexAttribute(selected, i)"
		(click)="!tab.disabled && selectTab(tab, $event)"
		(keydown.enter)="!tab.disabled && selectTab(tab, $event)"
	>
		<ng-container *ngTemplateOutlet="tabContents; context: { tab: tab }"></ng-container>
	</a>
	<ng-template #noLink>
		<a
			[attr.data-track-id]="'TabClick_' + tab.id"
			[attr.aria-selected]="selected"
			data-track-type="Navigation"
			[ngClass]="getTabClassName(tab)"
			[attr.aria-controls]="tabPanelId"
			[attr.role]="role"
			[attr.aria-disabled]="tab.disabled"
			[focusOn]="focusedTabIndex === i"
			[attr.tabindex]="getTabIndexAttribute(tab, i)"
			(keydown.enter)="!tab.disabled && selectTab(tab, $event)"
			(click)="!tab.disabled && selectTab(tab, $event)"
		>
			<ng-container *ngTemplateOutlet="tabContents; context: { tab: tab }"></ng-container>
		</a>
	</ng-template>
	<ng-template #tabContents let-tab="tab">
		<ng-container *ngIf="tab.templateRef; else defaultTabContent">
			<ng-container [ngTemplateOutlet]="tab.templateRef"></ng-container>
		</ng-container>
		<ng-template #defaultTabContent>
			<img *ngIf="tab.iconUrl" [src]="tab.iconUrl" />
			<wcd-shared-icon *ngIf="tab.icon" [iconName]="tab.icon" [ngClass]="tab.iconClassName">
			</wcd-shared-icon>
			<span>{{ tab.text || tab.name }}</span>
			<span
				*ngIf="tab.value !== undefined && tab.value !== null"
				[ngClass]="tab.valueClassName || 'tab-value'"
			>{{ tab.value | prettyNumber }}</span
			>
			<span *ngIf="tab.badge" [ngClass]="tab.badgeClassName">{{ tab.badge }}</span>
		</ng-template>
	</ng-template>
</ng-template>

