export enum UserStatusTypes {
	ACTIVE = 'ACTIVE',
	SUSPENDED = 'SUSPENDED',
	DELETED = 'DELETED',
}

export const AadUserStatus = {
	[UserStatusTypes.ACTIVE]: {
		nameI18nKey: UserStatusTypes.ACTIVE,
		iconName: 'Completed',
		iconColor: 'green',
	},
	[UserStatusTypes.SUSPENDED]: {
		nameI18nKey: UserStatusTypes.SUSPENDED,
		iconName: 'Blocked',
		iconColor: 'red',
	},
	[UserStatusTypes.DELETED]: {
		nameI18nKey: UserStatusTypes.DELETED,
		iconName: 'Cancel',
		iconColor: 'redDark',
	},
};
