import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injector, OnDestroy, ComponentRef, } from '@angular/core';
import { SnackbarComponent } from './snackbar.component';
import { AppInsightsService } from '../../../../apps/portal/src/app/insights/services/app-insights.service';
// @dynamic
var SnackbarMountingService = /** @class */ (function () {
    function SnackbarMountingService(applicationRef, injector, document, appInsights, componentFactoryResolver) {
        this.applicationRef = applicationRef;
        this.injector = injector;
        this.document = document;
        this.appInsights = appInsights;
        this.componentFactoryResolver = componentFactoryResolver;
    }
    Object.defineProperty(SnackbarMountingService.prototype, "isMounted", {
        get: function () {
            return this.document.body.querySelectorAll('wcd-snackbar').length > 0;
        },
        enumerable: true,
        configurable: true
    });
    SnackbarMountingService.prototype.mount = function () {
        if (this.isMounted) {
            console.warn('wcd-snackbar already mounted.');
            return;
        }
        this.snackBarRef = this.componentFactoryResolver
            .resolveComponentFactory(SnackbarComponent)
            .create(this.injector);
        this.applicationRef.attachView(this.snackBarRef.hostView);
        this.document.body.appendChild(this.snackBarRef.hostView.rootNodes[0]);
    };
    SnackbarMountingService.prototype.ngOnDestroy = function () {
        try {
            this.snackBarRef && this.snackBarRef.destroy();
            this.document.body.querySelectorAll('wcd-snackbar').forEach(function (elem) {
                elem.parentNode.removeChild(elem);
            });
        }
        catch (e) {
            console.error("Can't clear snackbar");
            this.appInsights.trackException(e);
        }
    };
    return SnackbarMountingService;
}());
export { SnackbarMountingService };
