import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Alert, MdeUserRoleActionEnum } from '@wcd/domain';
import { MdeAllowedActionsGuard, AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard } from '@wcd/auth';
import { CommentsModule } from '../../comments/comments.module';
import { AppContextService, ConfigModule, Feature } from '@wcd/config';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../global_entities/global-entity-panels.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { MachineEntityTypeModule } from '../machines/machine.entity-type.module';
import { AlertsCommentsComponent } from './components/alert-comments.component';
import { AlertLinkToIncidentPanelComponent } from './components/alert-link-to-incident-panel.component';
import { AlertLinkedByDetailsComponent } from './components/alert-linked-by-details.component';
import { AlertEntityPanelComponent } from './components/alert.entity-panel.component';
import { AlertsDataviewComponent } from './components/alerts.dataview';
import { AlertsEntityPanelComponent } from './components/alerts.entity-panel.component';
import { AlertIncidentService } from './services/alert-incident.service';
import { AlertEntityTypeService } from './services/alert.entity-type.service';
import { AlertLinkedEntityResolverService } from './services/alert.linked-entity-resolver.service';
import { AlertsFields } from './services/alerts.fields';
import { AlertsService, routeToLegacyAlertRoute } from './services/alerts.service';
import { DataTableModule } from '@wcd/datatable';
import { AlertNameFieldComponent } from './components/alert-name-field.component';
import { EntityWithContextEntityTypeModule } from '../../global_entities/entity-with-context.entity-type.module';
import { ImpactedEntitiesModule } from '../../impacted-entities/impacted-entities.module';
import { PanelsModule } from '@wcd/panels';
import { TagsModule } from '../../tags/tags.module';
import { AlertEntityComponent } from './components/alert-entity.component';
import { ReportsModule } from '../../reports/reports.module';
import { BreadcrumbsResolver } from '../../breadcrumbs/services/breadcrumbs-resolver.service';
import { AlertEntityDetailsComponent } from '../../global_entities/components/entity-details/alert.entity-details.component';
import { AlertProcessTreeComponent } from './components/tabs/alert-process-tree.component';
import { AlertContextWidgetComponent } from './components/widgets/alert-context-widget.component';
import { AlertStatusWidgetComponent } from './components/widgets/alert-status-widget.component';
import { AlertArtifactTimelineComponent } from './components/tabs/alert-artifact-timeline.component';
import { AlertIncidentGraphComponent } from './components/tabs/alert-incident-graph.component';
import { AlertDetailsSummaryComponent } from './components/alert-details-summary.component';
import { AlertsSummaryTimelineComponent } from './components/alerts-summary-timeline.component';
import { TooltipsModule } from '@wcd/dialogs';
import { AlertDescriptionComponent } from './components/tabs/alert-description.component';
import { AlertsEtwService } from './services/alert-etw.service';
import { AlertReasonsFieldComponent } from './components/alert-reasons-field.component';
import { AlertStatusComponent } from './components/alert-status.component';
import { AlertsDataviewPanelComponent } from './components/alerts.dataview-panel';
import { AlertPageComponent } from './components/alert-page.component';
import { SplitterModule } from '@progress/kendo-angular-layout';
import { DetailsTabComponent } from './components/details-tab.component';
import { EntityResolver } from '../../global_entities/services/entity.resolver';
import { A11yModule } from '@angular/cdk/a11y';
import { AlertPageService } from './services/alert-page.service';
import { MultipleAlertsProcessTreeComponent } from './components/processtree/multiple-alerts-process-tree.component';
import { AssociatedAlertsComponent } from './components/processtree/associated-alerts.component';
import { AlertLinkedAlertsPanelComponent } from './components/linked-alerts/alert-linked-alerts-panel.component';
import { AlertedMachineDetailsComponent } from './components/alerted-machine-details.component';
import { AlertedUserDetailsComponent } from './components/alerted-user-details.component';
import { FabSpinnerModule, FabMessageBarModule } from '@angular-react/fabric';
import { AlertImpactedAssetsComponent } from './components/alert-impacted-assets.component';
import { ExpanderModule } from '@wcd/expander';
import { AssociatedAlertComponent } from './components/processtree/associated-alert.component';
import { ImportAlertComponent } from './components/import-alert.component';
import { HistorySafeRedirectComponent } from '../../shared/components/history-safe-redirect.component';
import { AlertLinkedFields } from './services/alert.linked.fields';
import { ConvergencePromotionModule } from '../../convergence-promotion/convergence-promotion.module';
import { StandardDiscoveryMessageModule } from '../machines/components/device-discovery/standard-discovery.module';
import { IncidentNameComponent } from '../incidents/components/incident.name.component';

export const alertsRoutes: Routes = [
	{
		path: 'alertsQueue',
		component: AlertsDataviewComponent,
		canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
		data: {
			entity: Alert,
			breadcrumbsConfig: {
				resetListOnEnter: true,
				show: false,
			},
			pageTitle: 'Alerts queue',
			pageTitleKey: 'main_navigation_menu_item_alerts_queue',
			pageTitleIcon: 'alert',
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData,
			},
		},
	},
	{
		path: 'alerts/:id',
		component: AlertPageComponent,
		canActivate: [AuthenticatedGuard, OnboardGuard, FeatureEnabledGuard],
		resolve: {
			entity: EntityResolver,
			breadcrumbs: BreadcrumbsResolver,
		},
		data: {
			entity: Alert,
			breadcrumbsConfig: {
				entity: EntityResolver,
				addParentWhenEmpty: true,
			},
			pageTitle: 'Alert',
			pageTitleIcon: 'alert',
		},
		children: [
			{
				path: 'details',
				component: DetailsTabComponent,
				data: {
					pageTitle: 'Details',
				},
			},
			{
				path: 'importAlert',
				component: ImportAlertComponent,
				canActivate: [FeatureEnabledGuard],
				data: {
					pageTitle: 'Import alert',
					features: [Feature.ImportAlertProcessTree],
				},
			},
			{
				path: '',
				component: HistorySafeRedirectComponent,
				data: {
					redirectTo: 'details',
				},
				pathMatch: 'full',
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forChild(alertsRoutes),
		SharedModule,
		DataViewsModule,
		CommentsModule,
		MachineEntityTypeModule,
		GlobalEntitiesModule,
		GlobalEntityPanelsModule,
		ConfigModule,
		DataTableModule,
		EntityWithContextEntityTypeModule,
		ImpactedEntitiesModule,
		PanelsModule,
		TagsModule,
		ReportsModule,
		TooltipsModule,
		SplitterModule,
		A11yModule,
		FabSpinnerModule,
		FabMessageBarModule,
		ExpanderModule,
		ConvergencePromotionModule,
		StandardDiscoveryMessageModule,
	],
	declarations: [
		AlertsDataviewComponent,
		AlertEntityPanelComponent,
		AlertLinkedByDetailsComponent,
		AlertsEntityPanelComponent,
		AlertsCommentsComponent,
		AlertLinkToIncidentPanelComponent,
		AlertNameFieldComponent,
		AlertEntityComponent,
		AlertPageComponent,
		AlertProcessTreeComponent,
		AlertContextWidgetComponent,
		AlertStatusWidgetComponent,
		AlertArtifactTimelineComponent,
		AlertIncidentGraphComponent,
		AlertDetailsSummaryComponent,
		AlertsSummaryTimelineComponent,
		AlertDescriptionComponent,
		AlertReasonsFieldComponent,
		AlertStatusComponent,
		AlertsDataviewPanelComponent,
		DetailsTabComponent,
		ImportAlertComponent,
		MultipleAlertsProcessTreeComponent,
		AssociatedAlertsComponent,
		AssociatedAlertComponent,
		AlertLinkedAlertsPanelComponent,
		AlertedMachineDetailsComponent,
		AlertedUserDetailsComponent,
		AlertImpactedAssetsComponent,
		IncidentNameComponent
	],
	exports: [
		AlertsDataviewComponent,
		AlertEntityComponent,
		RouterModule,
		AlertLinkToIncidentPanelComponent,
		AlertDetailsSummaryComponent,
		AlertsSummaryTimelineComponent,
	],
	providers: [
		AlertsFields,
		AlertsService,
		AlertEntityTypeService,
		AlertLinkedEntityResolverService,
		AlertIncidentService,
		AppContextService,
		AlertsEtwService,
		AlertPageService,
		AlertLinkedFields,
	],
	entryComponents: [
		AlertEntityPanelComponent,
		AlertsEntityPanelComponent,
		AlertLinkToIncidentPanelComponent,
		AlertNameFieldComponent,
		AlertEntityDetailsComponent,
		AlertEntityComponent,
		AlertProcessTreeComponent,
		AlertContextWidgetComponent,
		AlertStatusWidgetComponent,
		AlertArtifactTimelineComponent,
		AlertIncidentGraphComponent,
		AlertDescriptionComponent,
		AlertReasonsFieldComponent,
		AlertLinkedByDetailsComponent,
		AlertStatusComponent,
		AlertsDataviewPanelComponent,
		AlertLinkedAlertsPanelComponent,
		IncidentNameComponent
	],
})
export class AlertsModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(AlertEntityTypeService);
	}
}
