import {Directive, ElementRef, Input, OnDestroy, OnInit,} from '@angular/core';
import {Observable, Subscription} from "rxjs";

@Directive({
	selector: '[focus-first-focusable-element]'
})

export class OnFocusFirstFocusableElementDirective implements OnDestroy, OnInit {

	private subscribe: Subscription;

	@Input() elementReady$: Observable<boolean>;

	constructor(private viewContainer: ElementRef) {
	}

	ngOnInit(): void {
		this.subscribe = this.elementReady$.subscribe(val => {
			if (val) {
				setTimeout(() => {
					const fundedElement = this.viewContainer.nativeElement.querySelectorAll('button, [href], input, select, textarea, [role="button"]');
					if (fundedElement.length && document.body.classList.contains('ms-Fabric--isFocusVisible')) {
						fundedElement[0].focus();
					}
				})
			}
		})
	}

	ngOnDestroy() {
		this.subscribe.unsubscribe();
	}
}
