import {
	ComponentFactoryResolver,
	ComponentRef,
	Inject,
	Injectable,
	Injector,
	Type,
	ViewContainerRef,
	OnDestroy,
	ChangeDetectorRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { merge } from 'lodash-es';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { PopupSettings } from '../../models/popup-settings';

const MODAL_OPEN_CLASS = 'modal-open';

// http://blog.brecht.io/Modals-in-angular2/

/** @dynamic */
@Injectable()
export class ModalService implements OnDestroy {
	// here we hold our placeholder
	private vcRef: ViewContainerRef;
	// here we hold our injector
	private injector: Injector;
	// we can use this to determine z-index of multiple modals
	public activeInstances = 0;
	activeInstances$: Subject<number> = new Subject();

	modalRef: ComponentRef<any>[] = [];
	private resolver: ComponentFactoryResolver;
	private _queuedModal: { component: Type<any>; settings?: PopupSettings; parameters?: Object };
	private _lastComponentRef$: ReplaySubject<any>;

	constructor(@Inject(DOCUMENT) private readonly document: Document) {}

	registerResolver(resolver: ComponentFactoryResolver) {
		this.resolver = resolver;

		if (this.injector && this.vcRef) this.createQueuedModal();
	}

	registerViewContainerRef(vcRef: ViewContainerRef): void {
		this.vcRef = vcRef;

		if (this.injector && this.resolver) this.createQueuedModal();
	}

	registerInjector(injector: Injector): void {
		this.injector = injector;

		if (this.vcRef && this.resolver) this.createQueuedModal();
	}

	private createQueuedModal() {
		if (!this._queuedModal) return;

		this.create(this._queuedModal.component, this._queuedModal.settings, this._queuedModal.parameters);
		this._queuedModal = null;
		this._lastComponentRef$ = null;
	}

	/**
	 * Create a Modal from a Component, that will be used as the content of the modal.
	 *
	 * @template TComponent The component's type
	 * @template TInputs The inputs of the component - **only use `@Input` of `TComponent`**.
	 * @param component The type of the component
	 * @param settings Settings to pass to the modal's wrapper
	 * @param inputs _Optional_. inputs to pass to the component - **only use `@Input` of `TComponent`**.
	 * @param componentFactoryResolver _Optional_. factory to create `TComponent`.
	 * @returns An `Observable` with the created component.
	 */
	create<TComponent, TInputs extends Partial<TComponent> = Partial<TComponent>>(
		component: Type<TComponent>,
		settings?: PopupSettings,
		inputs?: TInputs,
		componentFactoryResolver?: ComponentFactoryResolver
	): Observable<ComponentRef<TComponent>> {
		const componentRef$ = this._lastComponentRef$ || new ReplaySubject();

		if (this.vcRef) {
			const currentFocusedElement = this.document.activeElement;
			const injector = Injector.create({ providers: [], parent: this.vcRef.parentInjector });
			const factory =
				(componentFactoryResolver && componentFactoryResolver.resolveComponentFactory(component)) ||
				this.resolver.resolveComponentFactory(component);
			const componentRef = factory.create(injector);

			this.vcRef.insert(componentRef.hostView);

			Object.assign(componentRef.instance, merge({}, inputs, { settings: settings })); // pass the @Input parameters to the instance

			this.activeInstances++;
			this.toggleModalOpenFlag();
			this.activeInstances$.next(this.activeInstances);

			componentRef.instance['componentIndex'] = this.activeInstances;
			componentRef.instance['destroy'] = () => {
				this.activeInstances--;
				this.toggleModalOpenFlag();
				this.activeInstances = Math.max(this.activeInstances, 0);

				// remove modal instance from active instances array
				const idx = this.modalRef.indexOf(componentRef);
				if (idx > -1) {
					this.modalRef.splice(idx, 1);
				}

				this.activeInstances$.next(this.activeInstances);
				componentRef.destroy();

				if (currentFocusedElement && currentFocusedElement['focus']) {
					(<HTMLOrSVGElement>(<unknown>currentFocusedElement)).focus();
				}
			};

			this.modalRef.push(componentRef);

			componentRef$.next(componentRef);
			componentRef$.complete();
			this.vcRef.injector.get(ChangeDetectorRef).detectChanges();
		} else {
			this._queuedModal = { component: component, settings: settings, parameters: inputs };
			this._lastComponentRef$ = componentRef$;
		}

		return <Observable<ComponentRef<TComponent>>>componentRef$.asObservable();
	}

	closeModal(modal: ComponentRef<any>) {
		modal.instance.destroy();
	}

	closeAllModals() {
		this.modalRef.forEach(modal => modal.instance.destroy());
	}

	private toggleModalOpenFlag() {
		const bodyEl = this.document.getElementsByTagName('body')[0];

		if (this.activeInstances && bodyEl.classList.contains(MODAL_OPEN_CLASS))
			bodyEl.classList.add(MODAL_OPEN_CLASS);
		else bodyEl.classList.remove(MODAL_OPEN_CLASS);
	}

	ngOnDestroy() {
		this.closeAllModals();
		this.activeInstances$.complete();
		this._lastComponentRef$ && this._lastComponentRef$.complete();
	}
}
