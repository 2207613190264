import { ComponentRef, Type } from '@angular/core';
import { RemediationTaskCreationComponent } from '../components/remediation-task-creation.component';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { RemediationType, RecommendationCategory, VA_RECOMMENDATION_FILTER_TAG, SCA_RECOMMENDATION_FILTER_TAG, RecommendationStatus, TvmSupportedOsPlatform, } from '@wcd/domain';
import { PanelType } from '@wcd/panels';
import { RecommendationExceptionCreationComponent } from '../components/recommendation-exception-creation.component';
import { RemediationTaskService } from '../../remediation/remediation-tasks/services/remediation-task.service';
import { of } from 'rxjs';
import { RelatedComponentEnum } from '../components/filter/related-component.enum';
import { FilterTypesEnum } from '../components/filter/filter-types.enum';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { AppConfigService } from '@wcd/app-config';
var SecurityRecommendationService = /** @class */ (function () {
    function SecurityRecommendationService(dialogsService, remediationTaskService, featuresService, i18nService, tvmTagsService, appConfigService) {
        this.dialogsService = dialogsService;
        this.remediationTaskService = remediationTaskService;
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.tvmTagsService = tvmTagsService;
        this._isExceptionsPerRbacFeatureEnabled = false;
        this.filterTypesMap = {
            remediationType: FilterTypesEnum.RemediationType,
            relatedComponent: FilterTypesEnum.RelatedComponent,
            status: FilterTypesEnum.RecommendationStatus,
            recommendationTags: FilterTypesEnum.RecommendationTags,
            osPlatform: FilterTypesEnum.OsPlatform,
        };
        this.remediationTypesToIgnoreInFilter = [RemediationType.AttentionRequired];
        this._isExceptionsPerRbacFeatureEnabled =
            appConfigService.hasMachineGroups && featuresService.isEnabled(Feature.TvmExceptionsPerRbac);
        this.getRecommendationStatus = this.getRecommendationStatus.bind(this);
        this.mockDataRandomValue = Math.random();
        if (this.mockDataRandomValue < 0.2) {
            this.mockDataRandomValue += 0.2;
        }
    }
    SecurityRecommendationService.prototype.getMockDataRandomValue = function () {
        return this.mockDataRandomValue;
    };
    SecurityRecommendationService.prototype.showRemediationTaskCreationPanel = function (recommendation, remediationType, defaultRemediationActionId) {
        var _this = this;
        return this.showCreationPanel({
            recommendation: recommendation,
            productivityImpactRemediation: remediationType,
            defaultSelectedRemediationActionId: defaultRemediationActionId,
        }, 'remediation-task-creation', RemediationTaskCreationComponent, function (remediationTask, panel) {
            if (!remediationTask) {
                panel.destroy();
            }
            else {
                _this.remediationTaskService
                    .downloadRemediationTaskCsv(remediationTask)
                    .then(function () { return panel.destroy(); });
            }
        });
    };
    SecurityRecommendationService.prototype.showRecommendationExceptionCreationPanel = function (recommendation) {
        var _this = this;
        return this.showCreationPanel({ securityRecommendation: recommendation }, 'recommendation-exception-creation', RecommendationExceptionCreationComponent, function (model, panel) {
            if (!model) {
                _this._done = false;
            }
            panel.destroy();
        });
    };
    SecurityRecommendationService.prototype.showCreationPanel = function (creationParams, componentId, creationComponent, doneCallBack) {
        var _this = this;
        // returns a promise with true when the panel closes after done is omitted and false otherwise
        return new Promise(function (resolve, reject) {
            _this._done = false;
            _this.dialogsService
                .showPanel(creationComponent, {
                id: componentId,
                isModal: true,
                showOverlay: false,
                hasCloseButton: true,
                type: PanelType.large,
                back: { onClick: function () { return _this._remediationCreationPanel.instance.closePanel(); } },
                noBodyPadding: true,
            }, creationParams)
                .subscribe(function (panel) {
                _this._remediationCreationPanel = panel;
                panel.onDestroy(function () {
                    // this callback is called when the panel is destroyed
                    resolve(_this._done);
                    _this._remediationCreationPanel = null;
                });
                panel.instance.done.subscribe(function (model) {
                    _this._done = true;
                    doneCallBack(model, panel);
                });
            });
        });
    };
    SecurityRecommendationService.prototype.getRecommendationStatus = function (status, addDetails) {
        if (addDetails === void 0) { addDetails = false; }
        var key;
        if (this._isExceptionsPerRbacFeatureEnabled) {
            if (status === RecommendationStatus.Exception) {
                key = 'fullException';
            }
            if (addDetails && status === RecommendationStatus.PartialException) {
                key = 'partialExceptionWithExplanation';
            }
        }
        if (!key) {
            switch (status) {
                case RecommendationStatus.Active:
                    key = 'active';
                    break;
                case RecommendationStatus.Exception:
                    key = 'exception';
                    break;
                case RecommendationStatus.PartialException:
                    key = 'partialException';
                    break;
                default:
                    break;
            }
        }
        return this.i18nService.get("tvm.securityRecommendation.status." + key);
    };
    SecurityRecommendationService.prototype.getFiltersByTypes = function (types) {
        var _this = this;
        if (!this._recommendationFilters) {
            this._recommendationFilters = {
                remediationType: {
                    count: null,
                    values: Object.keys(RemediationType)
                        .filter(function (type) { return _this.remediationTypesToIgnoreInFilter.indexOf(RemediationType[type]) < 0; })
                        .map(function (type) {
                        return {
                            value: type,
                            name: _this.i18nService.get("tvm.remediationTask.type." + RemediationType[type]),
                            count: null,
                        };
                    }),
                },
                relatedComponent: {
                    count: null,
                    values: Object.keys(RelatedComponentEnum)
                        //TODO: Requested to remove the 'Other' filter. leaving rest of logic as is for now, for the chance of bringing it back soon.
                        .filter(function (type) { return type !== 'Other'; })
                        .filter(function (type) {
                        return type !== RecommendationCategory.NetworkGear ||
                            _this.featuresService.isEnabled(Feature.TvmNetworkScan);
                    })
                        .map(function (type) {
                        return {
                            value: type,
                            name: RelatedComponentEnum[type],
                            count: null,
                        };
                    }),
                },
                status: {
                    count: null,
                    values: Object.keys(RecommendationStatus)
                        .filter(function (status) {
                        return status !== RecommendationStatus.PartialException ||
                            _this._isExceptionsPerRbacFeatureEnabled;
                    })
                        .map(function (status) {
                        return {
                            value: status,
                            name: _this.getRecommendationStatus(status),
                        };
                    }),
                },
                recommendationTags: {
                    count: null,
                    values: this.tvmTagsService // Creating the tags from VA and SCA tags separately and then concatenating them
                        .getVaRecommendationsTagsForFiltering()
                        .map(function (tag) {
                        return {
                            value: VA_RECOMMENDATION_FILTER_TAG[tag],
                            name: _this.i18nService.get("tvm.softwarePage.filters.tags." + tag),
                            count: null,
                        };
                    })
                        .concat(this.tvmTagsService.scaRecommendationsTagsForFiltering.map(function (tag) { return ({
                        value: SCA_RECOMMENDATION_FILTER_TAG[tag],
                        name: _this.i18nService.get("tvm.securityRecommendation.tag." + tag),
                        count: null,
                    }); })),
                },
                osPlatform: {
                    values: Object.keys(TvmSupportedOsPlatform).map(function (plat) {
                        return {
                            value: plat,
                            name: _this.i18nService.get("tvm_common_osPlatform_" + TvmSupportedOsPlatform[plat]),
                        };
                    }),
                },
            };
        }
        return of(Object.keys(this._recommendationFilters)
            .filter(function (filterName) { return types.includes(_this.filterTypesMap[filterName]); })
            .reduce(function (res, key) {
            var _a;
            return Object.assign(res, (_a = {}, _a[key] = _this._recommendationFilters[key], _a));
        }, {}));
    };
    return SecurityRecommendationService;
}());
export { SecurityRecommendationService };
