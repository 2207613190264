/* tslint:disable:template-accessibility-alt-text */
/* tslint:disable:template-click-events-have-key-events */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Params } from '@angular/router';
import { AuthService, AuthUser } from '@wcd/auth';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { SafeHtml } from '@angular/platform-browser';
import { ErrorConfig, ErrorTypesService } from '../services/error-types.service';
import { ErrorsService } from '../../shared/services/errors.service';
import { Location } from '@angular/common';
import { zip } from 'rxjs';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
	showLinearLoader: boolean;
	errorSubtitleHtml: SafeHtml;
	showHelp: boolean;
	errorTitle: string;
	errorSubtitle: string;
	message: string;
	iconName: string;
	isLoginError: boolean;

	constructor(
		private route: ActivatedRoute,
		private appInsights: AppInsightsService,
		public authService: AuthService,
		private errorTypesService: ErrorTypesService,
		private location: Location,
		private errorsService: ErrorsService,
		public i18nService: I18nService
	) {}

	ngOnInit() {
		this.errorsService.errorPage$.next();
		// todo clear current history slot
		zip(this.route.data, this.route.params, this.route.queryParams).subscribe(
			(joinedData: [Data, Params, Params]) => {
				const [data, params, queryParams] = joinedData;

				this.isLoginError = data.isLoginError;

				const config: ErrorConfig = this.errorTypesService.getErrorTypeConfig(params.type);

				this.errorTitle = config.getErrorTitle();
				this.message = config.message;
				this.iconName = config.iconName;
				this.errorSubtitle = config.getErrorSubtitle && config.getErrorSubtitle(queryParams.itemId);
				this.errorSubtitleHtml = config.errorSubtitleHtml;
				this.showHelp = config.showHelp;

				if (this.isLoginError) {
					if (config.appInsightsCategory)
						this.appInsights.trackEvent(
							config.appInsightsCategory,
							(config.getAppInsightsProperties &&
								config.getAppInsightsProperties(queryParams)) || {
								Type: config.type,
							}
						);

					if (config.redirectToRoot) {
						this.showLinearLoader = true;
						setTimeout(() => {
							window.location.href = '/';
						}, 20000);
					}
				}

				if (queryParams.id && !this.authService.currentUser)
					this.authService.currentUser = new AuthUser({
						UserName: this.i18nService.strings.error_LoginFailure_Sign_in_title,
					});
			}
		);
	}

	goBack() {
		this.location.back();
	}
}

// deprecated (used only in Seville). Can be removed once angularJs is removed.
export const ERROR_POPUP_TEMPLATE: string = `
    <div class="row nomargin">
        <div class="col-md-12 nopadding">
            <i class="icon icon-Cancel pointer hover-pane-dismiss-btn" ng-click="$close()" tabindex="0"></i>
            <div class="popup-error-title">This action is currently unavailable</div>
        </div>
    </div>
    <div class="row nomargin">
        <div class="col-md-12 nopadding popup-error-text">
            This functionality is currently unavailable.<br />
            Please try again later. If this issue is not resolved contact Microsoft support.
        </div>
    </div>
    <div class="row nomargin">
        <div class="col-sm-12 text-right nopadding">
            <button class="btn btn-primary primary-button" ng-click="$close()">Close</button>
        </div>
    </div>
`;
