/**
 * Manages SCC auth services:
 * Auth tokens
 * Roles
 */
export class SccAuthService {
	constructor(private sccAuth) {}

	isInRole(role: string, noCache: boolean = false): Promise<boolean> {
		return this.sccAuth.isInRole(role, noCache);
	}

	isInRoles(roles: string[]): Promise<boolean[]> {
		return this.sccAuth.isInRoles(roles);
	}

	getToken(app: string = 'MATP'): Promise<string> {
		return this.sccAuth.getToken(app);
	}
}
