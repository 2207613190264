import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { FeaturesService } from '../services/features.service';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class SccRedirectGuard implements CanActivate {
	constructor(private featureService: FeaturesService) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		const config: SccRedirectGuardConfig = route.routeConfig.data && route.routeConfig.data.sccRedirect;

		// redirect to another page by feature or session storage setting
		if (config && config.redirectTo && this.shouldRedirect(config)) {
			sccHostService.state.go(config.redirectTo, route.queryParams);
			return false;
		}
		return true;
	}

	private shouldRedirect(config: SccRedirectGuardConfig): boolean {
		/**
		 * Order of precedence:
		 * sessionStorageKeyToStay
		 * sessionStorageKeyToRedirect
		 * featureNameToStay
		 * featureNameToRedirect
		 */

		if (
			config.sessionStorageKeyToStay &&
			sessionStorage.getItem(config.sessionStorageKeyToStay) === 'true'
		) {
			return false;
		}

		if (
			config.sessionStorageKeyToRedirect &&
			sessionStorage.getItem(config.sessionStorageKeyToRedirect) === 'true'
		) {
			return true;
		}

		if (config.featureNameToStay && this.areEnabled(config.featureNameToStay)) {
			return false;
		}

		if (config.featureNameToRedirect && this.areEnabled(config.featureNameToRedirect)) {
			return true;
		}

		return false;
	}

	private areEnabled(features: string | string[]): boolean{
		const featuresArray = typeof features === 'string' ? [features] : features;
		return featuresArray.every(featureName => this.featureService.isEnabled(featureName));
	}
}

interface SccRedirectGuardConfig {
	/**
	 * SCC state to redirect to
	 */
	redirectTo: string;

	/**
	 * Names of features that enables redirection, if all of them are enabled.
	 */
	featureNameToRedirect?: string | string[];

	/**
	 * Name of feature that disables redirection. Takes precedence over featureNameToRedirect.
	 */
	featureNameToStay?: string | string[];

	/**
	 * Key of a session storage value, which will enable redirection if is true. Takes precedence over features.
	 */
	sessionStorageKeyToRedirect?: string;

	/**
	 * Key of a session storage value, which will disable redirection if is true. Takes precedence over sessionStorageKeyToRedirect
	 */
	sessionStorageKeyToStay?: string;
}
