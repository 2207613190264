import { NgModule } from '@angular/core';
import { DistributionComponent } from './distribution.component';
import { DistributionElementComponent } from './distribution-element/distribution-element.component';
import { CommonModule } from '@angular/common';
import { FabSpinnerModule } from '@angular-react/fabric';
import { TooltipsModule } from '@wcd/dialogs';

@NgModule({
	imports: [CommonModule, FabSpinnerModule, TooltipsModule],
	declarations: [DistributionComponent, DistributionElementComponent],
	exports: [DistributionComponent, DistributionElementComponent],
	entryComponents: [DistributionComponent, DistributionElementComponent],
})
export class DistributionModule {}
