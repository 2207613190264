import { Observable } from 'rxjs';
var DimOvertimeChart = /** @class */ (function () {
    function DimOvertimeChart() {
        this.colorMappingOnTop = true;
    }
    Object.defineProperty(DimOvertimeChart.prototype, "chartSeriesConfig", {
        get: function () {
            return this._chartSeriesConfig;
        },
        set: function (newChartSeriesConfig) {
            this._chartSeriesConfig = newChartSeriesConfig;
            this.setLegendItems();
        },
        enumerable: true,
        configurable: true
    });
    DimOvertimeChart.prototype.setLegendItems = function () {
        if (!this.chartSeriesConfig) {
            return;
        }
        this.legendItems = Object.entries(this.chartSeriesConfig)
            .sort(function (_a, _b) {
            var value1 = _a[1];
            var value2 = _b[1];
            return !value1.priority || !value2.priority ? 0 : value1.priority > value2.priority ? 1 : -1;
        })
            .map(function (_a) {
            var value = _a[1];
            var font = value.knownColor
                ? value.knownColor.classes.font
                : value.knownColorsMap.trend.classes.font;
            return { colorClass: font, label: value.label };
        });
    };
    return DimOvertimeChart;
}());
export { DimOvertimeChart };
