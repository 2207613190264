import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';

@Component({
	selector: 'incident-update-status-dialog',
	template: `
		<modal (close)="destroy()" [settings]="settings">
			<form
				#resolveIncident="ngForm"
				(submit)="submitResolve()"
				class="wcd-full-height wcd-flex-vertical"
				data-track-component="IncidentUpdateStatusDialog"
			>
				<p class="dialog-contents wcd-flex-1">
					{{
						(isUpdateToResolve
							? 'incident.resolve.resolveWarning'
							: 'incident.reactivate.reactivateWarning') | i18n
					}}
				</p>
				<footer
					class="dialog-footer wcd-flex-none wcd-flex-horizontal wcd-padding-medium-left wcd-padding-medium-right wcd-padding-small-top"
				>
					<button
						type="submit"
						[attr.data-track-id]="isUpdateToResolve ? 'ResolveIncident' : 'ReactivateIncident'"
						data-track-type="Button"
						class="btn btn-primary wcd-flex-1 wcd-margin-right"
					>
						<span *ngIf="resolving; else resolveIncident">
							<i class="loader-icon"></i>
							{{
								(isUpdateToResolve
									? 'incident.resolve.resolvingIncident'
									: 'incident.reactivate.reactivatingIncident') | i18n
							}}
						</span>
						<ng-template #resolveIncident>
							{{
								(isUpdateToResolve
									? 'incident.resolve.resolveIncident'
									: 'incident.reactivate.reactivateIncident') | i18n
							}}
						</ng-template>
					</button>
					<button
						type="button"
						data-track-id="Cancel"
						data-track-type="Button"
						class="btn wcd-flex-none"
						(click)="destroy()"
					>
						{{ 'incident.setClassification.cancel' | i18n }}
					</button>
				</footer>
			</form>
		</modal>
	`,
})
export class IncidentUpdateStatusDialogComponent extends ModalContainer {
	@Output() resolveIncident: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Input() isUpdateToResolve: boolean;
	resolving: boolean = false;

	constructor(router: Router) {
		super(router);
	}

	submitResolve(): void {
		this.resolving = true;
		this.resolveIncident.emit(true);
	}
}
