import { ApiCall, ApiCallModel, HttpOptions } from '@microsoft/paris';
import * as Requests from '../machine-requests.models';
import { MachineRestrictAppExecutionRequest } from './machine-restrict-app-execution-request.value-object';

export type CancelRestrictAppExecutionRawApiRequestBody = Requests.CancelApiRequestBody<
	'RestrictExecutionRequest'
>;

export type CancelRestrictAppExecutionParameters = Requests.CancelParameters;

@ApiCall({
	name: 'Cancel restrict app execution',
	endpoint: 'requests/cancelbyid',
	method: 'POST',
	type: MachineRestrictAppExecutionRequest,
	baseUrl: config => config.data.serviceUrls.userRequests,
	parseQuery: ({
		requestGuid,
		comment,
	}: CancelRestrictAppExecutionParameters): HttpOptions<CancelRestrictAppExecutionRawApiRequestBody> => ({
		params: {
			requestGuid: requestGuid,
			comment: comment,
		},
	}),
})
export class MachineCancelRestrictAppExecutionApiCall extends ApiCallModel<
	MachineRestrictAppExecutionRequest,
	CancelRestrictAppExecutionParameters
> {}
