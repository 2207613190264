/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/localization/src/lib/pipes/tz-date.pipe";
import * as i2 from "../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i3 from "../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i4 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "@angular/common";
import * as i7 from "./email.entity-details.component";
var styles_EmailEntityDetailsComponent = [];
var RenderType_EmailEntityDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmailEntityDetailsComponent, data: {} });
export { RenderType_EmailEntityDetailsComponent as RenderType_EmailEntityDetailsComponent };
function View_EmailEntityDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "events.actionTypes.fileCreated.outlookSavedAttachment.emailFields.sender")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.email.sender; _ck(_v, 7, 0, currVal_1); }); }
function View_EmailEntityDetailsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "events.actionTypes.fileCreated.outlookSavedAttachment.emailFields.subject")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.email.subject; _ck(_v, 7, 0, currVal_1); }); }
function View_EmailEntityDetailsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "events.actionTypes.fileCreated.outlookSavedAttachment.emailFields.recipients")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.email.recipients.join("; "); _ck(_v, 7, 0, currVal_1); }); }
function View_EmailEntityDetailsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["\n\t\t\t", "\n\t\t"])), i0.ɵpid(131072, i1.TzDatePipe, [i2.TzDateService, i3.LocaleConfigService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "events.actionTypes.fileCreated.outlookSavedAttachment.emailFields.received")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.email.receivedDate, "medium")); _ck(_v, 7, 0, currVal_1); }); }
export function View_EmailEntityDetailsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.I18nPipe, [i5.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 13, "dl", [["role", "none"]], [[2, "key-values", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailEntityDetailsComponent_1)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailEntityDetailsComponent_2)), i0.ɵdid(7, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailEntityDetailsComponent_3)), i0.ɵdid(10, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailEntityDetailsComponent_4)), i0.ɵdid(13, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.email.sender; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.email.subject; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.email.recipients; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.email.receivedDate; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.asKeyValueList; _ck(_v, 1, 0, currVal_0); }); }
export function View_EmailEntityDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "email-entity-details", [], null, null, null, View_EmailEntityDetailsComponent_0, RenderType_EmailEntityDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i7.EmailEntityDetailsComponent, [i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailEntityDetailsComponentNgFactory = i0.ɵccf("email-entity-details", i7.EmailEntityDetailsComponent, View_EmailEntityDetailsComponent_Host_0, { entity: "entity", dataEnriched: "dataEnriched", asKeyValueList: "asKeyValueList", showEntityPanelLink: "showEntityPanelLink", mode: "mode", entityPageViewMode: "entityPageViewMode" }, {}, []);
export { EmailEntityDetailsComponentNgFactory as EmailEntityDetailsComponentNgFactory };
