/* tslint:disable:template-accessibility-alt-text */
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ContentState } from '@wcd/contents-state';
import { isEqual, map, pick } from 'lodash-es';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TenantSettings } from '@wcd/domain';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { ChecklistValue } from '@wcd/forms';
import { I18nService } from '@wcd/i18n';
import { GeneralSettingsService, RETENTION_PERIODS } from './general-settings.service';
import { AppConfigService } from '@wcd/app-config';
import { FabricIconNames, TvmLicenseType } from '@wcd/scc-common';
import { Feature, FeaturesService, FlavorService, TvmLicensesAngularService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../insights/services/performance.scc.service';

const SETTINGS_CHANGED_FIELDS = ['geoRegion', 'retentionPolicy'];


@Component({
	templateUrl: './general-settings.component.html',
	providers: [GeneralSettingsService],
})
export class GeneralSettingsComponent implements OnDestroy {
	loadingState: ContentState = ContentState.Loading;
	isSaving: boolean = false;
	dataRetention: boolean;

	originalPreferences: TenantSettings;
	tenantPreferences: TenantSettings;

	availableGeos: Array<any>;
	retentionPeriods: ReadonlyArray<number>;
	isScc = sccHostService.isSCC;
	private getSettingsSubscription: Subscription;

	constructor(
		private generalSettingsService: GeneralSettingsService,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		public appConfigService: AppConfigService,
		private changeDetectorRef: ChangeDetectorRef,
		flavorService: FlavorService,
		tvmLicensesService: TvmLicensesAngularService,
		private performanceSccService: PerformanceSccService,
		featuresService: FeaturesService
	) {
		this.dataRetention = flavorService.isEnabled(AppFlavorConfig.settings.dataRetention);
		this.retentionPeriods = RETENTION_PERIODS;
		this.availableGeos = map(
			this.appConfigService.supportedGeoRegions,
			geo => ({
					key: geo,
					text: this.i18nService.get('endpointManagement.supportedGeoRegions.' + geo),
					disabled: true
				})
		);

		this.getRetentionLabel = this.getRetentionLabel.bind(this); // this function is called from a child component and requires this component's context

		this.getSettingsSubscription = this.generalSettingsService.getTenantSettings().subscribe(
			settings => {
				this.originalPreferences = Object.assign({}, settings);
				this.tenantPreferences = settings;

				this.loadingState = ContentState.Complete;
			},
			error => {
				this.loadingState = ContentState.Error;
			},
			() => {
				this.performanceSccService.endNgPageLoadPerfSession('settings-data-retention');
			}
		);
	}

	getRetentionLabel(days: number): string {
		return this.i18nService.get('settings.general.dataRetentionLabel', { days: days });
	}

	setRetention(days: number): void {
		this.tenantPreferences.retentionPolicy = days;
	}

	isSettingsChanged(): boolean {
		return !isEqual(
			pick(this.tenantPreferences, SETTINGS_CHANGED_FIELDS),
			pick(this.originalPreferences, SETTINGS_CHANGED_FIELDS)
		);
	}

	saveSettings(): void {
		this.isSaving = true;
		this.generalSettingsService
			.saveTenantSettings(this.tenantPreferences)
			.pipe(
				finalize(() => {
					this.isSaving = false;
					this.changeDetectorRef.detectChanges();
				}))
			.subscribe(
				() => {
					this.originalPreferences = Object.assign({}, this.tenantPreferences);
					this.dialogsService.showSuccessSnackbar({
						icon: FabricIconNames.Completed,
						text: this.i18nService.get('settings.general.saveSuccessMessage'),
					});
				},
				error => {
					this.dialogsService.showError({
						title: this.i18nService.get('settings.general.saveFailedMessage'),
						data: error,
					});
				}
			);
		this.changeDetectorRef.detectChanges();
	}

	ngOnDestroy() {
		this.getSettingsSubscription && this.getSettingsSubscription.unsubscribe();
	}
}
