import { SevilleModule } from '../../../../../app/seville/seville.module';
import { RbacMdeAllowedActions } from '../../../../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.controller('seville.settings.nonwindows.confirmdisablemodal', nonWindowsConfirmDisableModal);

nonWindowsConfirmDisableModal.$inject = [
	'$scope',
	'$uibModalInstance',
	'authenticationService',
	'partnerName',
];

function nonWindowsConfirmDisableModal($scope, $modalInstance, authenticationService, partnerName) {
	$scope.isreadonlyuser = !authenticationService.isUserActionAllowed([RbacMdeAllowedActions.securitySettings]);
	$scope.partnerName = partnerName;

	$scope.ok = function() {
		$modalInstance.close();
	};

	$scope.cancel = function() {
		$modalInstance.dismiss();
	};
}
