import { SevilleModule } from '../../seville/seville.module';

SevilleModule.controller('seville.threatintel.file.blacklist', blacklistController);

blacklistController.$inject = [
	'$log',
	'$scope',
	'$state',
	'$http',
	'$uibModalInstance',
	'appConfig',
	'file',
	'$uibModal',
	'hoverPaneService',
	'blackListService',
];

function blacklistController(
	$log,
	$scope,
	$state,
	$http,
	$uibModalInstance,
	appConfig,
	file,
	$modal,
	hoverPaneService,
	blackListService
) {
	$scope.file = file;
	var vm = $scope;

	vm.blacklist = function() {
		if (vm.posting) return;

		if (!vm.file.commentText || vm.file.commentText == '') return;

		vm.posting = true;

		// Determine the blacklist action
		var isDeleted;
		var newStatus;
		if (file.BlacklistStatus == 'Blocked') {
			isDeleted = true;
			newStatus = 'Unblocked';
		} else {
			isDeleted = false;
			newStatus = 'Blocked';
		}

		blackListService.blackList(
			file,
			vm.file.commentText,
			isDeleted,
			newStatus,
			true,
			file.machineGroupsIds
		);

		vm.file.commentText = '';
		vm.posting = false;
		$uibModalInstance.dismiss();
	};

	vm.close = function() {
		vm.file.commentText = '';
		$uibModalInstance.dismiss();
	};
}
