import {ApiCall, ApiCallModel, DataQuery} from '@microsoft/paris';
import {IncidentHistoryItem} from './incident-comments-item.entity';
import {WcdPortalParisConfig} from '../paris-config.interface';

@ApiCall({
	name: 'Add comment to incidents',
	endpoint: (config: WcdPortalParisConfig, query: DataQuery) => {
		return query && query.where && query.where['newCommentsApi'] ? 'addComment' : 'incidents/addComment';
	},
	method: 'POST',
	type: IncidentHistoryItem,
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) => {
		return query && query.where && query.where['newCommentsApi'] ? config.data.serviceUrls.incidents : config.data.serviceUrls.threatIntel;
	},
	separateArrayParams: true,
	parseQuery: (incidentAddCommentOptions: IncidentsAddCommentApiParameters) => {
		return ({
			data: incidentAddCommentOptions,
			params: {
				newCommentsApi: incidentAddCommentOptions.newCommentsApi,
			}
		});
	},
	parseData: (data, _, query) => {
		if (query && query.where && query.where['newCommentsApi']) {
			return {
				NewValue : data[0].Comment,
				...data[0]
			};
		}
		return data;
	},
})
export class IncidentsAddCommentApiCall extends ApiCallModel<
	IncidentHistoryItem,
	IncidentsAddCommentApiParameters
> {}

export interface IncidentsAddCommentApiParameters {
	Ids: Array<string>;
	Comment: string;
	newCommentsApi: boolean;
}
