import { Component, ComponentRef, OnDestroy, OnInit } from '@angular/core';
import { TitleService } from '../../../../shared/services/title.service';
import { MachineGroup, MdeUserRoleActionEnum, MtpWorkload, UserRoleAssignment } from '@wcd/domain';
import { DataSet, Paris, Repository } from '@microsoft/paris';
import { MachineGroupFields } from '../../services/machine-group.fields';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { PanelType } from '@wcd/panels';
import { MachineGroupEditEnhancedPanelComponent } from './machine-group-edit-enhanced.panel.component';
import { ItemActionModel } from '../../../../dataviews/models/item-action.model';
import { I18nService } from '@wcd/i18n';
import { ConfirmEvent } from '../../../../dialogs/confirm/confirm.event';
import { AuthService, UnifiedRbacPermission, UserAuthEnforcementMode } from '@wcd/auth';
import { DatasetBackendOptions, DataViewConfig } from '@wcd/dataview';
import { Observable, of, Subscription, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DataChangedPayload } from '../../../../dataviews/components/dataview.component';
import { ServiceUrlsService } from '@wcd/app-config';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, distinctUntilChanged, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { cloneDeep, filter, maxBy, minBy, remove, some, sortBy } from 'lodash-es';
import { RbacService } from '../../../../rbac/services/rbac.service';
import { MessageBarAction, MessageBarActionExecutionDetails } from '@wcd/shared';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Feature, FeaturesService, PollingService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../../insights/services/performance.scc.service';
import { AppConfigService } from '@wcd/app-config';

const IS_APPLYING_CHANGES_INTERVAL: number = 1000 * 20;
const IS_APPLYING_MACHINE_GROUPS_CHANGES_PATH: string = 'IsRbacReevaluationInProgress';

@Component({
	selector: 'machine-groups-dataview',
	providers: [MachineGroupFields],
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<message-bar
				*ngIf="applyingChangesInProgress || (unappliedChangesExists && allowEdit)"
				class="wcd-flex-none"
				[messageKey]="getLocalChangesMessageKey()"
				[className]="'color-box-' + (unappliedChangesExists ? 'warning-light' : 'successBackground')"
				[iconName]="unappliedChangesExists ? 'warning' : 'checkCircle'"
				[actions]="
					unappliedChangesExists
						? applyingChangesInProgress
							? [discardChangesAction]
							: localChangesActions
						: null
				"
				(actionFailed)="onActionFailed($event)"
				[messageRole]="'alert'"
			></message-bar>
			<dataview
				class="wcd-flex-1"
				id="machine-groups"
				[dataViewConfig]="dataViewConfig"
				[defaultSortFieldId]="'priority'"
				[refreshOn]="lastUpdate"
				[allowFilters]="false"
				[allowPaging]="false"
				[allowAdd]="allowEdit"
				[disableSelection]="!allowEdit"
				[ignoreQueryParams]="true"
				(onData)="onDataFetched($event)"
				[setItemActions]="boundSetItemActions"
				(onItemClick)="openEditPanel($event.item)"
				(onNewItem)="openEditPanel()"
				[fields]="machineGroupFields.fields"
				[itemName]="repository.entity.singularName"
				[label]="
					'customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.title'
						| i18n
				"
				[queueHeader]="true"
				[padLeft]="false"
				commandBarNoPadding="true"
				responsiveActionBar="true"
				responsiveLayout="true"
				[removePaddingRight]="isScc"
				(onTableRenderComplete)="onMachineGroupsDataViewComplete()"
			>
				<div class="wcd-padding-top" dataview-header>
					{{ 'machineGroup.description' | i18n }}
				</div>
			</dataview>
		</div>
	`,
})
export class MachineGroupsDataviewComponent implements OnInit, OnDestroy {
	repository: Repository<MachineGroup>;
	editPanel: ComponentRef<MachineGroupEditEnhancedPanelComponent>;
	itemActions: Array<ItemActionModel>;
	boundSetItemActions: (machineGroups: Array<MachineGroup>) => Array<ItemActionModel>;
	lastUpdate: Date;
	itemsFromBackend: Array<MachineGroup>;
	unappliedItems: Array<MachineGroup>;
	discardChangesAction: MessageBarAction;
	localChangesActions: Array<MessageBarAction>;
	applyingChangesInProgress: boolean;
	applyingChangesSubscription: Subscription;
	dataViewConfig: DataViewConfig<MachineGroup>;
	isScc = sccHostService.isSCC;
	isApplyingMachineGroupsBaseUrl: string;

	get allowEdit(): boolean {
		if (this.appConfigService.userAuthEnforcementMode === UserAuthEnforcementMode.UnifiedRbac) {
			//This is granularity change if the Tenant moved to UnifiedRbac
			return this.authService.currentUser.hasRequiredMtpPermissionInWorkload(
				UnifiedRbacPermission.ConfigurationAuthorizationManage,
				MtpWorkload.Mdatp);
		}
		return this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.systemSettings);
	}

	get unappliedChangesExists(): boolean {
		return !!(this.unappliedItems && this.unappliedItems.length);
	}

	constructor(
		private http: HttpClient,
		public paris: Paris,
		public machineGroupFields: MachineGroupFields,
		public titleService: TitleService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		public authService: AuthService,
		private serviceUrlsService: ServiceUrlsService,
		private rbacService: RbacService,
		private pollingService: PollingService,
		private liveAnnouncer: LiveAnnouncer,
		private featureService: FeaturesService,
		private performanceSccService: PerformanceSccService,
		private appConfigService: AppConfigService
	) {
		this.repository = paris.getRepository(MachineGroup);
		this.isApplyingMachineGroupsBaseUrl = featureService.isEnabled(Feature.RbacGroupsProgressApiMigration)
			? `${serviceUrlsService.k8s}/machines`
			: serviceUrlsService.threatIntel
		this.dataViewConfig = {
			id: `unappliedMachineGroups`,
			loadResults: options => this.getItems(options),
		};

		this.itemActions = [
			{
				id: 'priorityIncrease',
				name: this.i18nService.get('machineGroup.promoteRank'),
				icon: 'arrow.sort.up',
				tooltip: this.i18nService.get('machineGroup.promoteRankTooltip'),
				method: this.changePriority.bind(this, false),
				refreshOnResolve: true,
			},
			{
				id: 'priorityDecrease',
				name: this.i18nService.get('machineGroup.demoteRank'),
				icon: 'arrow.sort.down',
				tooltip: this.i18nService.get('machineGroup.demoteRankTooltip'),
				method: this.changePriority.bind(this, true),
				refreshOnResolve: true,
			},
			{
				id: 'delete',
				name: this.i18nService.get('delete'),
				icon: 'delete',
				tooltip: this.i18nService.get('deleteSelectedItems', {
					itemsName: this.repository.entity.pluralName,
				}),
				method: (items: Array<MachineGroup>) => this.deleteItems(items),
				refreshOnResolve: true,
			},
		].map(itemActionConfig => new ItemActionModel(itemActionConfig));

		this.discardChangesAction = {
			id: 'discardChanges',
			name: this.i18nService.get('discardChanges'),
			tooltip: 'Discard the current changes to device groups',
			method: this.removeUnappliedChanges.bind(this),
			buttonClass: 'btn-cancel',
		};

		this.localChangesActions = [
			{
				id: 'applyChanges',
				name: this.i18nService.get('buttons.applyChanges'),
				tooltip: 'Apply the current changes to device groups',
				method: this.applyChanges.bind(this),
			},
			this.discardChangesAction,
		];

		this.boundSetItemActions = this.setItemActions.bind(this);
	}

	ngOnInit() {
		this.setApplyingInProgressState();
		this.unappliedItems = this.getUnappliedChanges();
	}

	ngOnDestroy() {
		this.applyingChangesSubscription && this.applyingChangesSubscription.unsubscribe();
	}

	onDataFetched(event: DataChangedPayload) {
		if ((!this.unappliedItems || !this.unappliedItems.length) && event.data && event.data.items)
			this.itemsFromBackend = event.data.items;
	}

	setItemActions(machineGroups: Array<MachineGroup>): Array<ItemActionModel> {
		const unassignedGroupSelected: boolean = machineGroups.some(
			(machineGroup: MachineGroup) => machineGroup.isUnassignedMachineGroup
		);
		return unassignedGroupSelected ? [] : this.itemActions;
	}

	openEditPanel(machineGroup?: MachineGroup): void {
		this.dialogsService
			.showPanel(
				MachineGroupEditEnhancedPanelComponent,
				{
					id: 'machine-group-edit-panel',
					type: PanelType.extraLarge,
					isModal: true,
					showOverlay: true,
					disableOverlayClick: true,
					isBlocking: true,
					headerIcon: 'System',
					headerText: this.i18nService.get(
						machineGroup ? 'machineGroups.edit' : 'machineGroups.add'
					),
					noBodyPadding: true,
				},
				{
					machineGroup: machineGroup,
					onCancel: () => this.editPanel.destroy(),
					onDone: () => {
						this.editPanel.destroy();
						this.lastUpdate = new Date();
					},
				}
			)
			.subscribe((panel: ComponentRef<MachineGroupEditEnhancedPanelComponent>) => {
				this.editPanel = panel;
				panel.instance.onSaveChanges.subscribe((item: MachineGroup) => {
					this.saveChangesLocally(item).subscribe(() => {
						this.editPanel.instance.closePanel();
						this.lastUpdate = new Date();
					});
				});
				panel.onDestroy(() => {
					this.editPanel = null;
				});
			});
	}

	getItems(options: DatasetBackendOptions): Observable<DataSet<MachineGroup>> {
		if (!this.unappliedChangesExists)
			return this.repository.query({
				...options,
				where: {
					addAadGroupNames: 'true',
					addMachineGroupCount: 'true',
				},
			});
		else
			return of({
				count: this.unappliedItems && this.unappliedItems.length,
				items: this.unappliedItems || [],
			});
	}

	deleteItems(items: Array<MachineGroup>): Promise<Array<MachineGroup>> {
		const itemName: string = (items.length === 1
			? this.repository.entity.singularName
			: this.repository.entity.pluralName
		).toLowerCase();
		return this.dialogsService
			.confirm({
				title: this.i18nService.get('deleteItem', { itemName: itemName }),
				text: this.i18nService.get('machineGroup.delete.confirmText', {
					itemPluralName: itemName,
				}),
				confirmText: this.i18nService.get('delete'),
			})
			.then((e: ConfirmEvent) => {
				if (e.confirmed) {
					const itemsAfterDelete: Array<MachineGroup> = filter(
						this.unappliedChangesExists ? this.unappliedItems : this.itemsFromBackend,
						(originalItem: MachineGroup) =>
							!some(items, itemToDelete => itemToDelete.id === originalItem.id)
					);

					itemsAfterDelete
						.sort((a: MachineGroup, b: MachineGroup) => {
							return a.priority > b.priority ? 1 : -1;
						})
						.forEach((machineGroup: MachineGroup, index: number) => {
							if (!machineGroup.isUnassignedMachineGroup) machineGroup.priority = index + 1;
						});

					this.setUnappliedItems(itemsAfterDelete);
				}
				return Promise.resolve(
					this.unappliedChangesExists ? this.unappliedItems : this.itemsFromBackend
				);
			});
	}

	changePriority(
		increase: boolean,
		machineGroups: Array<MachineGroup>,
		options,
		dataSet: DataSet<MachineGroup>
	): Promise<any> {
		return this.dialogsService
			.confirm({
				title: this.i18nService.get(
					increase ? 'machineGroup.decreaseRankBy' : 'machineGroup.increaseRankBy'
				),
				requireMessage: {
					placeholder: '1',
					type: 'number',
					min: 1,
					property: 'newPriority',
					defaultValue: '1',
				},
			})
			.then((e: ConfirmEvent) => {
				if (!e.confirmed) return false;
				const unassignedMachinesGroup: MachineGroup = remove(
					dataSet.items,
					(machineGroup: MachineGroup) => machineGroup.isUnassignedMachineGroup
				)[0],
					priorityChange: number = e.data.newPriority ? parseInt(e.data.newPriority) : 1,
					selectedItemIds: Set<string> = new Set(
						machineGroups.map(machineGroup => String(machineGroup.id))
					);

				const editedItems: Array<MachineGroup> = sortBy(dataSet.items.map(
					(machineGroup: MachineGroup) => new MachineGroup(machineGroup)
				), (machineGroup: MachineGroup) => machineGroup.priority);

				if (increase) {
					const highest: MachineGroup = maxBy<MachineGroup>(machineGroups, 'priority');
					const normalizedPriorityChange = Math.min(
						priorityChange,
						editedItems.length - highest.priority
					);
					for (let i = editedItems.length - 1; i >= 0; i--) {
						if (selectedItemIds.has(String(editedItems[i].id))) {
							const editedMachineGroup = editedItems[i];

							// Change group priority
							editedMachineGroup.priority += normalizedPriorityChange;

							// Push effected groups and update their priority
							for (let j = i; j < editedMachineGroup.priority - 1; j++) {
								editedItems[j + 1].priority--;
								editedItems[j] = editedItems[j + 1];
							}

							// Move group to new position
							editedItems[editedMachineGroup.priority - 1] = editedMachineGroup;
						}
					}
				} else {
					const lowest: MachineGroup = minBy<MachineGroup>(machineGroups, 'priority');
					const normalizedPriorityChange = Math.min(priorityChange, lowest.priority - 1);
					for (let i = 0; i < editedItems.length; i++) {
						if (selectedItemIds.has(String(editedItems[i].id))) {
							const editedMachineGroup = editedItems[i];

							// Change group priority
							editedMachineGroup.priority -= normalizedPriorityChange;

							// Push effected groups and update their priority
							for (let j = i; j >= editedMachineGroup.priority; j--) {
								editedItems[j - 1].priority++;
								editedItems[j] = editedItems[j - 1];
							}

							// Move group to new position
							editedItems[editedMachineGroup.priority - 1] = editedMachineGroup;
						}
					}
				}

				editedItems.push(unassignedMachinesGroup);
				this.setUnappliedItems(editedItems);
				return editedItems;
			});
	}

	saveChangesLocally(editedMachineGroup: MachineGroup): Observable<MachineGroup> {
		const items: Array<MachineGroup> = cloneDeep(
			this.unappliedChangesExists ? this.unappliedItems : this.itemsFromBackend
		);
		if (editedMachineGroup.id) {
			// edited item
			const originalItem = items.find(machineGroup => machineGroup.id === editedMachineGroup.id);
			items[items.indexOf(originalItem)] = editedMachineGroup;
		} else {
			// new item
			const minIdItem: MachineGroup = minBy(items, item => item.id);
			editedMachineGroup.id = !minIdItem || minIdItem.id > 0 ? -1 : minIdItem.id - 1;

			const maxRankItem: MachineGroup = maxBy(items, item =>
				!item.isUnassignedMachineGroup ? item.priority : 0
			);
			editedMachineGroup.priority =
				!maxRankItem || maxRankItem.isUnassignedMachineGroup ? 1 : maxRankItem.priority + 1;

			editedMachineGroup.lastUpdatedDate = new Date();
			items.splice(items.length - 1, 0, editedMachineGroup);
		}

		this.setUnappliedItems(items);
		return of(editedMachineGroup);
	}

	applyChanges(): Promise<any> {
		const itemsForSave: Array<any> = this.unappliedItems.map((machineGroup: MachineGroup) => {
			if (machineGroup.assignments && machineGroup.assignments.length)
				machineGroup.assignments.forEach(
					(rule: UserRoleAssignment) =>
						(rule.lastUpdated = new Date(rule.lastUpdated || new Date()))
				);
			machineGroup.lastUpdatedDate = new Date(machineGroup.lastUpdatedDate);
			return machineGroup;
		});

		this.liveAnnouncer.announce(this.i18nService.get('machineGroup.actions.applyingChanges'), 'assertive', 300);

		return this.repository
			.saveItems(itemsForSave)
			.pipe(
				tap(() => {
					this.repository.clearAllValues();

					// currently backend has cache up to 90secs of the rbac exposed groups, so we poll it to make sure we get updated results once cache is flushed.
					// can be removed when backend cahce settigs changes
					this.pollingService
						.poll(0, 10 * 1000)
						.pipe(takeUntil(timer(100 * 1000)))
						.subscribe(() => {
							this.rbacService.refreshUserExposedRbacGroups$.next(null);
						});
					this.setApplyingInProgressState();
					return this.removeUnappliedChanges();
				}),
				catchError((err: any) => {
					return this.dialogsService.showError({
						title: this.i18nService.get('machineGroup.actions.applyErrorTitle'),
						data:
							err && (<HttpErrorResponse>err).status === 409
								? this.i18nService.get('machineGroup.actions.applyConflictErrorDescription')
								: err.message || err.error,
					});
				})
			)
			.toPromise();
	}

	removeUnappliedChanges(): Promise<any> {
		this.unappliedItems = undefined;
		localStorage.removeItem(this.getStorageKey());
		this.lastUpdate = new Date();
		return Promise.resolve(null);
	}

	setApplyingInProgressState() {
		if (this.applyingChangesSubscription) {
			this.applyingChangesSubscription.unsubscribe();
		}

		this.applyingChangesSubscription = this.pollingService
			.poll(0, IS_APPLYING_CHANGES_INTERVAL)
			.pipe(
				mergeMap(() => {
					return this.http.get<boolean>(
						`${this.isApplyingMachineGroupsBaseUrl}/${IS_APPLYING_MACHINE_GROUPS_CHANGES_PATH}`
					);
				}),
				distinctUntilChanged()
			)
			.subscribe(applyingInProgress => {
				if (this.applyingChangesInProgress) {
					this.lastUpdate = new Date();
				}
				this.applyingChangesInProgress = applyingInProgress;
			});
	}

	getLocalChangesMessageKey() {
		return `machineGroup.actions.${this.applyingChangesInProgress
			? this.unappliedChangesExists
				? 'discardOnly'
				: 'applyingInProgress'
			: 'unappliedChanges'
			}`;
	}

	onActionFailed(details: MessageBarActionExecutionDetails) {
		this.dialogsService.showError({
			title: this.i18nService.get('errors.action.failureTitle'),
			errorTitle: this.i18nService.get('errors.action.failureSubTitle', {
				actionName: details.action.name,
			}),
			data: details.error,
		});
	}

	private setUnappliedItems(unappliedItems: Array<MachineGroup>): void {
		this.unappliedItems = unappliedItems;

		// to make sure that the DataEntityType field is saved when stringify
		// (packages/@wcd/domain/src/rbac/machine-group-simple-rule-property.entity.ts)
		const fixedUnappliedItems = this.unappliedItems.map(item => ({
			...item,
			rules: item.rules.map(rule => ({
				...rule,
				property: {
					...rule.property,
					valuesEntity: rule.property.valuesEntity
						? rule.property.valuesEntity.name
						: rule.property.valuesEntity,
				},
			})),
		}));

		localStorage.setItem(
			this.getStorageKey(),
			JSON.stringify(fixedUnappliedItems, (key, value) => {
				if (key == '$parent') {
					return value.id;
				} else return value;
			})
		);
	}

	private getUnappliedChanges(): Array<MachineGroup> {
		const data = localStorage.getItem(this.getStorageKey());
		return data ? JSON.parse(data) : [];
	}

	private getStorageKey(): string {
		const currentUserName = this.authService.currentUser
			? this.authService.currentUser.name
			: 'defaultUser';
		return `${currentUserName}_rbac.unappliedMachineGroups`;
	}

	onMachineGroupsDataViewComplete() {
		this.performanceSccService.endNgPageLoadPerfSession('settings-machines-groups');
	}
}
