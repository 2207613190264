import { Component } from '@angular/core';
import { Alert } from '@wcd/domain';
import { CommentModel } from '../../../comments/models/comment.model';
import { EntitiesPanelComponentBase } from '../../../global_entities/components/entity-panels/entities-panel.component.base';

enum CollapsibleID {
	AlertsManage = 'alerts-entity-panel-manage',
	AlertComments = 'alerts-entity-panel-comments',
}

@Component({
	selector: 'alerts-entity-panel',
	template: `
		<wcd-collapsible-section
			[sectionId]="collapsibleID.AlertsManage"
			[label]="'alerts.panel.manageAlerts' | i18n"
		>
			<div
				*ngIf="hasReadOnlyAlert; else alertsManage"
				[wcdTooltip]="'alerts.panel.cantManageAllAlerts' | i18n"
				class="disabled"
			>
				<ng-container *ngTemplateOutlet="alertsManage"></ng-container>
			</div>

			<ng-template #alertsManage>
				<alerts-manage
					[alerts]="entities"
					[readonly]="hasReadOnlyAlert || (isUserExposed$ | async) !== true"
					(statusChanged)="onStatusChanged()"
					(classificationChanged)="onClassificationChanged()"
					(determinationChanged)="onDeterminationChanged()"
					(assigneeChanged)="onAssigneeChanged()"
					(restored)="refreshEntities()"
				></alerts-manage>
			</ng-template>
		</wcd-collapsible-section>

		<wcd-collapsible-section
			[label]="'entityCommon.panel.comments.header' | i18n"
			[sectionId]="collapsibleID.AlertComments"
			*ngIf="(isUserExposed$ | async)"
		>
			<alerts-comments [comments]="comments" [alerts]="entities"></alerts-comments>
		</wcd-collapsible-section>
	`,
})
export class AlertsEntityPanelComponent extends EntitiesPanelComponentBase<Alert> {
	comments: Array<CommentModel>;

	collapsibleID = CollapsibleID;

	get hasReadOnlyAlert(): boolean {
		return this.entities.some(alert => alert.isReadOnly);
	}

	onStatusChanged() {
		this.runEntityAction('alertAssignToMe');
		this.refreshOnClose(true);
	}

	onClassificationChanged() {
		this.refreshOnClose(true);
	}

	onAssigneeChanged() {
		this.refreshOnClose(true);
	}

	onDeterminationChanged() {
		this.refreshOnClose(true);
	}
}
