
		<ng-container *ngIf="session">
			<div [ngClass]="{ 'wcd-full-height wcd-flex-vertical': mode === 'EntityPage' }">
				<wcd-collapsible-section [label]="i18nService.strings.liveResponse_session_deviceDetails">
					<a (click)="openMachinePanel()" class="wcd-padding-bottom" href="javascript:void(0);">
						{{ i18nService.strings.liveResponse_session_deviceDetailsLink }}
					</a>
				</wcd-collapsible-section>
				<wcd-collapsible-section [label]="i18nService.strings.liveResponse_session_generalInfo">
					<dl [class.key-values]="asKeyValueList" role="none">
						<dt>{{ i18nService.strings.liveResponse_session_id }}</dt>
						<dd>
							{{ session.id }}
						</dd>
						<dt>{{ i18nService.strings.liveResponse_session_createdBy }}</dt>
						<dd>
							{{ session.initiatingUser }}
						</dd>

						<dt>{{ i18nService.strings.liveResponse_session_started }}</dt>
						<dd>
							{{ session.startDate | date: 'short' }}
						</dd>

						<dt>{{ i18nService.strings.liveResponse_session_ended }}</dt>
						<dd>
							<ng-container *ngIf="session.endDate; else notAvailable">
								{{ session.endDate | date: 'short' }}
							</ng-container>
							<ng-template #notAvailable>
								{{ i18nService.strings.notAvailable_short }}
							</ng-template>
						</dd>

						<dt>{{ i18nService.strings.liveResponse_session_duration }}</dt>
						<dd>
							<stopwatch [start]="session.startDate" [end]="session.endDate"></stopwatch>
						</dd>
					</dl>
				</wcd-collapsible-section>
				<wcd-collapsible-section
					*ngIf="session.machine"
					[label]="i18nService.strings.liveResponse_session_deviceInfo"
				>
					<device-details [machine]="session.machine" [includeMachineName]="false"></device-details>
				</wcd-collapsible-section>
			</div>
		</ng-container>
	