import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
	CustomActionEntityType,
	HuntingCustomAction,
	HuntingCustomActionEntity,
	HuntingCustomActionRequest,
	HuntingCustomActionResult,
	HuntingCustomActionsRequest,
	HuntingCustomActionsResponse,
	HuntingCustomActionStatusCode,
	HuntingQueryResultRecord,
	HuntingQuerySupportedAction,
	HuntingSupportedBulkActionsApiCall,
	RunHuntingBulkActionApiCall,
} from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { concat, forkJoin, Observable } from 'rxjs';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { HuntingGridColumn } from '@wcd/hunting';
import { WizardStepModel } from '../../shared/components/wizard/wizard-step.model';
import { DataTableField } from '@wcd/datatable';
import { findLastIndex, flatMap, flatten, groupBy, pick, uniq, uniqBy } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { ScheduledHuntingActionsFields } from '../../@entities/scheduled_hunting/scheduled-hunting.actions.fields';
import { catchError, map, shareReplay, take, tap } from 'rxjs/operators';
import { HuntingEntityNamesService } from '../services/hunting-entity-names.service';
import { ContentState } from '@wcd/contents-state';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { Feature, FeaturesService } from '@wcd/config';

enum WizardStepId {
	SelectAction = 1,
	SelectEntities,
	Confirm,
	ActionStatus,
}

class CustomActionRecord extends HuntingCustomActionResult {
	record: HuntingQueryResultRecord;
}

@Component({
	selector: 'hunting-bulk-actions',
	templateUrl: './hunting-bulk-actions.component.html',
	styleUrls: ['hunting-bulk-actions.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class HuntingBulkActionsComponent extends PanelContainer implements OnInit {
	@Input() records: Array<HuntingQueryResultRecord>;
	@Input() columnsInfo: Array<HuntingGridColumn>;

	WizardStepId = WizardStepId;
	stepsData: Array<WizardStepModel>;
	currentStep: WizardStepModel;

	supportedActions$: Observable<Array<HuntingQuerySupportedAction>>;
	selectedActions: Array<HuntingCustomAction>;
	supportedActionsState: ContentState;

	entities$: Observable<Array<CustomActionRecord>>;
	selectedEntities: Array<CustomActionRecord>;
	entityFields: Array<DataTableField>;

	bulkName: string;
	isEditingBulkName: boolean = false;
	bulkComment: string;
	isEditingBulkComment: boolean = false;
	didSave: boolean = false;

	actionsToConfirm$: Observable<Array<HuntingCustomActionResult>>;
	confirmationFields: Array<DataTableField>;

	actionsStatus$: Observable<Array<HuntingCustomActionResult>>;
	actionsFields: Array<DataTableField>;
	isActionCenterLinkEnabled: boolean;
	actionCenterBulkId: string;

	constructor(
		router: Router,
		featuresService: FeaturesService,
		private paris: Paris,
		private i18nService: I18nService,
		private huntingActionsFields: ScheduledHuntingActionsFields,
		private huntingEntityNamesService: HuntingEntityNamesService,
		private appInsightsService: AppInsightsService,
		private changeDetector: ChangeDetectorRef
	) {
		super(router);
		this.isActionCenterLinkEnabled = featuresService.isEnabled(Feature.ActionCenterBulkIdColumn);
	}

	ngOnInit() {
		this.setWizardSteps();

		this.supportedActionsState = ContentState.Loading;
		this.supportedActions$ = this.paris
			.apiCall<Array<HuntingQuerySupportedAction>>(HuntingSupportedBulkActionsApiCall, this.records[0])
			.pipe(
				take(1),
				tap(
					() => (this.supportedActionsState = ContentState.Complete),
					err => (this.supportedActionsState = ContentState.Error)
				),
				shareReplay()
			);
	}

	setWizardSteps() {
		this.stepsData = [
			new WizardStepModel({
				id: WizardStepId.SelectAction,
				subText: this.i18nService.get('hunting.bulkActions.chooseActions.title'),
				show: true,
				validation: () => this.selectedActions && this.selectedActions.length > 0,
			}),
			new WizardStepModel({
				id: WizardStepId.SelectEntities,
				subText: this.i18nService.get('hunting.bulkActions.chooseEntities.title'),
				show: true,
				validation: () => Boolean(this.selectedEntities && this.selectedEntities.length),
			}),
			new WizardStepModel({
				id: WizardStepId.Confirm,
				subText: this.i18nService.get('hunting.bulkActions.confirm.title'),
				actionButtonSettings: {
					label: this.i18nService.get('hunting.bulkActions.submitActions'),
					onActionButtonClick: () => {
						this.didSave = true;
						this.currentStep = this.stepsData[this.currentStep.id];
						this.changeDetector.detectChanges();
						return this.submitActions();
					},
				},
				show: true,
			}),
			new WizardStepModel({
				id: WizardStepId.ActionStatus,
				subText: this.i18nService.get('hunting.bulkActions.actionStatus.title'),
				show: false,
			}),
		];

		this.currentStep = this.stepsData[0];
	}

	previousStep() {
		const currentStepIndex = this.currentStep.id - 1;
		const nextStepIndex = findLastIndex(this.stepsData, step => step.show, currentStepIndex - 1);
		this.currentStep = this.stepsData[nextStepIndex];
	}

	nextStep() {
		switch (this.currentStep.id) {
			case WizardStepId.SelectAction:
				this.setEntities();
				break;
			case WizardStepId.SelectEntities:
				this.confirm();
				break;
		}
		this.currentStep = this.stepsData[this.currentStep.id];
	}

	onClosePanel(e) {
		event.preventDefault();
		event.stopPropagation();
		this.closePanel();
	}

	get selectEntitiesStepSubtitle(): string{
				const actionNames = (this.selectedActions || []).map(action =>
					this.i18nService.get(`hunting_customDetections_actions.${action.actionType}`)
				);
				return this.i18nService.get('hunting_bulkActions_chooseEntities_subtitle', {
					actionName: actionNames.join(', '),
				});
	}

	get showActionCenterLink(): boolean {
		return this.isActionCenterLinkEnabled && this.didSave && Boolean(this.actionCenterBulkId);
	}

	get actionCenterBulkIdDisplay(): string{
		// Action center displays only the last 6 characters of the bulk ID
		return this.actionCenterBulkId && this.actionCenterBulkId.substring(this.actionCenterBulkId.length - 6);
	}

	onRecordSelectionChange({ items }: { items: Array<CustomActionRecord> }) {
		this.selectedEntities = items;
	}

	setEntities() {
		if (!this.selectedActions || !this.selectedActions.length) {
			return;
		}

		const entities = flatten(
			this.selectedActions.map(action => this.getDistinctEntities(action, this.records))
		);

		this.entities$ = this.getEntitiesWithPrettyNames(entities);

		this.entityFields = this.huntingActionsFields.bulkActionsFields.filter(field =>
			['impactedAsset', 'actionType', 'targetEntities'].includes(field.id)
		);
		this.onRecordSelectionChange({ items: entities });
	}

	private getDistinctEntities(
		action: HuntingCustomAction,
		records: Array<HuntingQueryResultRecord>
	): Array<CustomActionRecord> {
		const entityIdColumns = action.entities.map(e => e.entityIdField);
		const entityColumns = uniq([
			...entityIdColumns,
			...action.entities.map(e => e.entityNameField),
		]).filter(Boolean);

		records = records
			.filter(record => entityIdColumns.every(idCol => record.dataItem[idCol]))
			.map(
				record =>
					new HuntingQueryResultRecord({
						id: entityIdColumns.map(c => record.dataItem[c]).join('_'),
						dataItem: pick(record.dataItem, entityColumns),
					})
			);

		const distinctEntities = uniqBy(records, 'id').map(
			record =>
				new CustomActionRecord({
					record,
					actionType: action.actionType,
					entities: action.entities.map(
						e =>
							new HuntingCustomActionEntity({
								id: record.dataItem[e.entityIdField],
								name: record.dataItem[e.entityNameField],
								entityType: this.paris.getValue(CustomActionEntityType, e.entityType),
							})
					),
				})
		);

		return distinctEntities;
	}

	private getEntitiesWithPrettyNames(
		customActions: Array<CustomActionRecord>
	): Observable<Array<CustomActionRecord>> {
		// We need to show pretty names of the action's impacted entities, e.g. machine name instead of machine ID
		// Call BE to obtain the names by ID
		const impactedAssets = customActions.map(action => action.impactedAsset).filter(Boolean);

		return this.huntingEntityNamesService.getEntitiesWithNames(impactedAssets).pipe(
			map((entitiesWithNames: Array<HuntingCustomActionEntity>) => {
				this.copyEntityNames(entitiesWithNames, impactedAssets);

				// Return a copy of the modified array to trigger change detection
				return [...customActions];
			})
		);
	}

	private copyEntityNames(
		entitiesWithNames: Array<HuntingCustomActionEntity>,
		entities: Array<HuntingCustomActionEntity>
	) {
		for (const entityWithName of entitiesWithNames) {
			// find matching entities (by ID and entity type) and assign their name
			for (const entity of entities) {
				if (
					entity.id === entityWithName.id &&
					entity.entityType.type === entityWithName.entityType.type
				) {
					entity.name = entityWithName.name;
				}
			}
		}
	}

	confirm() {
		this.confirmationFields = this.huntingActionsFields.bulkActionsFields
			.filter(field => ['impactedAsset', 'actionType', 'targetEntities'].includes(field.id))
			.concat([
				new DataTableField({
					id: 'status',
					name: this.i18nService.get('hunting.customDetections.actionRuns.fields.status'),
					getDisplay: _ => this.i18nService.get('statuses.notSubmitted'),
					className: 'color-text-neutralTertiary',
				}),
			]);
	}

	async submitActions() {
		this.actionsFields = this.huntingActionsFields.bulkActionsFields;

		const recordsByAction = groupBy(this.selectedEntities, record => record.actionType);

		const actions: Array<HuntingCustomActionRequest> = Object.keys(recordsByAction).map(actionType => {
			const action = this.selectedActions.find(a => a.actionType === +actionType);
			const actionRecords = recordsByAction[actionType];

			return new HuntingCustomActionRequest({
				...action,
				records: actionRecords.map(actionRecord => actionRecord.record.dataItem),
			});
		});

		const request: HuntingCustomActionsRequest = new HuntingCustomActionsRequest({
			actions,
			remediationName: this.bulkName,
			remediationComment: this.bulkComment,
		});

		// serialize request into a format that is readable by the BE
		const rawRequest = this.paris.modeler.serializeModel(
			request,
			this.paris.getModelBaseConfig(HuntingCustomActionsRequest)
		);

		// send the actions to execute
		const actionResults$ = this.paris.apiCall(RunHuntingBulkActionApiCall, rawRequest).pipe(
			catchError(err => this.createTemporaryResults(actions, HuntingCustomActionStatusCode.Failed)),
			take(1),
			tap(actionResults => {
				this.trackActionsStatus(actionResults.results);
				this.actionCenterBulkId = actionResults.bulkId;
				this.changeDetector.markForCheck();
			})
		);

		// until the actions complete, show their status as "running"
		const tempStatus$ = this.createTemporaryResults(actions, HuntingCustomActionStatusCode.Running);

		this.actionsStatus$ = concat(tempStatus$, actionResults$).pipe(
			map(actionsResponse => actionsResponse && actionsResponse.results),
			tap(actionResults =>
				this.copyEntityNames(
					this.selectedEntities.map(r => r.impactedAsset),
					actionResults.map(a => a.impactedAsset)
				)
			)
		);
	}

	private createTemporaryResults(
		actionRequests: Array<HuntingCustomActionRequest>,
		status: HuntingCustomActionStatusCode
	): Observable<HuntingCustomActionsResponse> {
		// create temporary actions with a status to display until a real result is returned from the backend
		const actionResults: Array<Observable<HuntingCustomActionResult>> = flatMap(actionRequests, action =>
			action.records.map(actionRecord =>
				this.paris.createItem(HuntingCustomActionResult, {
					ActionType: action.actionType,
					Entities: action.entities.map(e => ({
						EntityType: e.entityType,
						EntityId: actionRecord[e.entityIdField],
						EntityName: e.entityNameField && actionRecord[e.entityNameField],
					})),
					Status: status,
				})
			)
		);

		return forkJoin(actionResults).pipe(map(results => new HuntingCustomActionsResponse({ results })));
	}

	private trackActionsStatus(actionResults: HuntingCustomActionResult[]) {
		this.appInsightsService.trackEvent('Hunting bulk actions', {
			actionResults:
				actionResults &&
				actionResults.map(result => ({
					actionType: result.actionType,
					status: result.status,
					failureReason: result.failureReason,
				})),
		});
	}
}
