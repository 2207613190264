import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { VulnerabilitySourceInfo, VulnerabilityInstalledAffectedProduct } from '@wcd/domain';
import { SpinnerSize } from 'office-ui-fabric-react';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'detection-logic',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './detection-logic.details.component.html',
})
export class DetectionLogicDetailsComponent implements OnInit {
	SpinnerSize = SpinnerSize;
	versionItems: Array<string>;
	kbItems: Array<string>;
	cveSourcesHtml: string;
	installedProducts: Array<string>;
	osFeatures?: Array<string>;
	@Input() cveSources: Array<VulnerabilitySourceInfo>;
	@Input() installedAffectedProducts: Array<VulnerabilityInstalledAffectedProduct>;

	constructor(private readonly i18nService: I18nService, private tvmTextService: TvmTextsService) {}

	ngOnInit() {
		this.osFeatures = this.flatten(
			this.installedAffectedProducts.map(installed => installed.vulnerabilityAffectedProduct.osFeatures)
		);
		this.kbItems = this.flatten(
			this.installedAffectedProducts.map(
				installed => installed.vulnerabilityAffectedProduct.productFixes
			)
		).map(fix => `<a href=\"https://support.microsoft.com/help/${fix.id}\">${fix.id}</a>`);

		this.versionItems = Array.from(
			new Set(
				this.installedAffectedProducts
					.map(installed =>
						this.tvmTextService.getText(
							TextToken.AffectedProduct,
							installed.vulnerabilityAffectedProduct
						)
					)
					.filter(s => s != '')
			)
		).sort();
		if (this.versionItems.length > 1 && this.installedAffectedProducts.length === 1) {
			this.versionItems = [this.versionItems[0]];
		}
		this.initCveSources();
		this.initInstalledProducts();
	}

	private initCveSources() {
		const title =
			this.cveSources && this.cveSources.length === 1
				? this.i18nService.get('tvm.detectionLogic.source')
				: this.i18nService.get('tvm.detectionLogic.sources');
		this.cveSourcesHtml = `${title}: ${this.cveSources
			.map(s => `<a href=\"${s.url}\">${s.source}</a>`)
			.join(', ')}`;
	}

	private flatten<T>(arr: T[][]): T[] {
		return [].concat(...arr);
	}

	private initInstalledProducts() {
		this.installedProducts = this.installedAffectedProducts.map(p =>
			this.tvmTextService.getText(TextToken.VulnerabilityInstalledAffectedProduct, p)
		);
	}
}
