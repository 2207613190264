export enum TagType {
	alertCategory = 'alertCategory',
	behavior = 'behavior',
	failure = 'failure',
	machineGroupName = 'groupName',
	success = 'success',
	system = 'system',
	user = 'user',
	classifiedData = 'classifiedData',
	sensitiveData = 'sensitiveData',
	default = 'default',
	alertToEvent = 'alertToEvent',
	threatExpert = 'threatExpert',
	highValueAsset = 'highValueAsset',
	internetFacing = 'internetFacing',
	mitre = 'mitre',
	actor = 'actor',
	ransomware = 'ransomware',
	telemetrySpoofing = 'spoofing',
}
