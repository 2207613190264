import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('isoString', isoStringFilter);

function isoStringFilter() {
	return function(input) {
		if (!input) {
			return null;
		}

		var date = new Date(input);
		var newIsoString = date.toISOString();
		return newIsoString;
	};
}
