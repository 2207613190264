var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ComponentRef, OnDestroy, OnInit } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { IncidentAlertsRelationship } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { RbacService } from '../../../rbac/services/rbac.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AlertsService } from '../../alerts/services/alerts.service';
import { AppContextService } from '@wcd/config';
import { AlertsFields } from '../../alerts/services/alerts.fields';
import { DataviewField } from '@wcd/dataview';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { IncidentAlertsPanelComponent } from './incident.alerts.panel.component';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["ImpactedEntities"] = "incident-entity-impacted-entities";
    CollapsibleID["Alerts"] = "incident-entity-alerts";
})(CollapsibleID || (CollapsibleID = {}));
var IncidentEntityPanelComponent = /** @class */ (function (_super) {
    __extends(IncidentEntityPanelComponent, _super);
    function IncidentEntityPanelComponent(alertsService, paris, rbacService, globalEntityTypesService, appContextService, changeDetectorRef, alertsFields, dialogService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.alertsService = alertsService;
        _this.paris = paris;
        _this.rbacService = rbacService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.appContextService = appContextService;
        _this.alertsFields = alertsFields;
        _this.dialogService = dialogService;
        _this.collapsibleID = CollapsibleID;
        _this.isItemSelectable = function () { return false; };
        _this.maxAlerts = 5;
        _this.isMtpEnabled = _this.appContextService.isMtp;
        var fieldNames = ['title', 'severity', 'status'];
        _this.fields = (_this.appContextService.isMtp ? alertsFields.mtpFields : alertsFields.allFields)
            .filter(function (field) { return fieldNames.includes(field.id); })
            .map(function (field) { return new DataviewField(__assign({}, field, { sort: { enabled: false } })); });
        return _this;
    }
    Object.defineProperty(IncidentEntityPanelComponent.prototype, "incident", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    IncidentEntityPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this._onActionSubscription = this.action$.subscribe(function ($event) { return _this.onAction($event.action, $event.data); });
        this.setAlertsRepository(this.entity);
    };
    IncidentEntityPanelComponent.prototype.ngOnDestroy = function () {
        this._onActionSubscription && this._onActionSubscription.unsubscribe();
        if (this.alertsComponentPanel) {
            this.alertsComponentPanel.destroy();
        }
    };
    IncidentEntityPanelComponent.prototype.onAction = function (action, data) {
        this.requestEntitiesRefresh.emit();
        this.changeDetectorRef.markForCheck();
    };
    IncidentEntityPanelComponent.prototype.initEntity = function (entity, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, entity);
        this.changeDetectorRef.markForCheck();
    };
    IncidentEntityPanelComponent.prototype.setAlertsRepository = function (incident) {
        var _this = this;
        if (incident.alertCount) {
            this.incidentAlertsRepository = this.paris.getRelationshipRepository(IncidentAlertsRelationship);
            this.incidentAlertsRepository.sourceItem = incident;
            this.incidentAlertsRepository
                .query()
                .subscribe(function (response) {
                _this.relatedAlerts = response.items;
                _this.partialRelatedAlerts = _this.relatedAlerts.splice(0, _this.maxAlerts);
                _this.changeDetectorRef.markForCheck();
            });
        }
    };
    IncidentEntityPanelComponent.prototype.openAlerts = function () {
        var _this = this;
        var panelSettings = Object.assign({
            id: 'incident-alert-panel',
            type: PanelType.large,
            showOverlay: true,
            isBlocking: false,
            scrollBody: true,
            back: {
                onClick: function () {
                    _this.alertsComponentPanel.destroy();
                }
            },
        });
        this.dialogService.showPanel(IncidentAlertsPanelComponent, panelSettings, { incident: this.entity, alerts: this.relatedAlerts })
            .subscribe(function (panel) {
            _this.alertsComponentPanel = panel;
            panel.onDestroy(function () {
                _this.alertsComponentPanel = null;
            });
        });
    };
    return IncidentEntityPanelComponent;
}(EntityPanelComponentBase));
export { IncidentEntityPanelComponent };
