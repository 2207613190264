import { ApiCall, ApiCallModel, HttpOptions } from '@microsoft/paris';

@ApiCall({
	name: 'Custom TI indicator delete',
	endpoint: 'ti/indicators',
	method: 'DELETE',
	baseUrl: config => config.data.serviceUrls.userRequests,
	parseQuery: (ids: Array<Number>) => {
		return {
			data:  ids,
		};
	},
})
export class DeleteTiIndicatorsApiCall extends ApiCallModel<void, Array<Number>	
> {}
