import { ComponentRef, Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityDataViewOptions, EntityType } from '../../../global_entities/models/entity-type.interface';
import { HuntingRule, ScheduledHuntingRunApiCall, ScheduledHuntingUpdateStatusApiCall } from '@wcd/domain';
import {
	HuntingRuleEntityPanelOptions,
	ScheduledHuntingEntityPanelComponent,
} from '../components/scheduled-hunting.entity-panel.component';
import { ScheduledHuntingFieldsService } from './scheduled-hunting.fields';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { Paris } from '@microsoft/paris';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { ScheduledHuntingRuleEditComponent } from '../components/scheduled-hunting-rule-edit.component';
import { PanelSettings, PanelType } from '@wcd/panels';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { ScheduledHuntingEntityDetailsComponent } from '../components/scheduled-hunting.entity-details.component';
import { ScheduledHuntingEntityComponent } from '../components/scheduled-hunting.entity.component';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { HuntingRbacService } from './hunting-rbac-service';
import { map } from 'rxjs/operators';
import { FabricIconNames } from '@wcd/scc-common';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { HUNTING_ROUTE } from '@wcd/shared';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { HuntingQueryListService } from '@wcd/hunting';
import { getTenantIdUrlParam } from './scheduled-hunting-utils';

@Injectable()
export class ScheduledHuntingEntityTypeService implements EntityTypeService<HuntingRule> {
	constructor(
		private paris: Paris,
		private router: Router,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private huntingRbacService: HuntingRbacService,
		private readonly appContextService: AppContextService,
		private readonly huntingQueryListService: HuntingQueryListService,
		private readonly featureService: FeaturesService
	) {}

	entityType: EntityType<HuntingRule> = {
		id: 'scheduled-hunting',
		entity: HuntingRule,
		icon: FabricIconNames.DateTime,
		entityDetailsComponentType: ScheduledHuntingEntityDetailsComponent,
		entityContentsComponentType: ScheduledHuntingEntityComponent,
		singleEntityPanelComponentType: ScheduledHuntingEntityPanelComponent,
		getItemParams: (rule: HuntingRule) => {
			return { includeLastRun: true };
		},
		getEntityName: (rule: HuntingRule) => rule.name,
		entityPluralNameKey: 'scheduledHunting_entityType_pluralName',
		entitySingularNameKey: 'scheduledHunting_entityType_singularName',
		getEntityDataviewLink: () => this.scheduledHuntingDataviewLink,
		getEntitiesLink: (rules: Array<HuntingRule>) =>
			rules.length === 1 ? `/custom_detection/${rules[0].id}` : null,
		getEntitiesLinkText: (rules: Array<HuntingRule>) => {
			return this.i18nService.get('scheduledHunting.actions.openMonitorPage');
		},
		getNavigationModel: (huntingRule: HuntingRule) => {
			const link = this.entityType.getEntitiesLink([huntingRule]);
			return link ? { routerLink: [link], queryParams: getTenantIdUrlParam() } : null;
		},
		entityPageViewMode: EntityPageViewMode.Asset,
		getActions: (
			rules: Array<HuntingRule>,
			options: HuntingRuleEntityPanelOptions,
			entityViewType: EntityViewType
		) => {
			const isSingleRule = rules.length === 1;
			const areRulesEnabled = rules.every((rule) => rule.isEnabled);
			const isUserAllowedToEdit = rules.every((rule) =>
				this.huntingRbacService.isUserAllowedToEdit(rule)
			);

			if (!isUserAllowedToEdit) {
				return [];
			}

			return this.huntingRbacService.isUserExposedToHuntingRulesScope(rules).pipe(
				map((isUserExposedToHuntingRulesScope) => {
					let actions: Array<ItemActionModelConfig> = [];
					if (areRulesEnabled && isUserExposedToHuntingRulesScope) {
						actions = [
							...actions,
							{
								id: 'runRule',
								nameKey: 'scheduledHunting.actions.runRule',
								icon: FabricIconNames.Play,
								method: (rules: Array<HuntingRule>) => {
									const runRulesCall = this.paris.apiCall(ScheduledHuntingRunApiCall, {
										RuleIds: rules.map((rule) => rule.id),
									});
									return runRulesCall.toPromise().then(
										() => {
											const rulesRepo = this.paris.getRepository(HuntingRule);
											rulesRepo.clearCache();
											this.dialogsService.showSuccessSnackbar({
												text: this.i18nService.get(
													'scheduledHunting_actions_runRule_Scheduled',
													{
														text: (rules.length === 1
															? rulesRepo.entity.singularName
															: rules.length + ' ' + rulesRepo.entity.pluralName
														).toLowerCase(),
													}
												),
											});
										},
										(error) => {
											this.dialogsService.showError({
												title: this.i18nService.get(
													isSingleRule
														? 'hunting.scheduledMonitorSidePane.errors.failedRuns.single'
														: 'hunting.scheduledMonitorSidePane.errors.failedRuns'
												),
												data: error,
											});
										}
									);
								},
								closeOnAction: true,
							},
						];
					}
					if (isSingleRule && isUserExposedToHuntingRulesScope) {
						const isMdatpRuleUnderMtpContext =
							this.appContextService.isMtp &&
							rules[0].isMdatp &&
							!(
								this.featureService.isEnabled(Feature.PortedSccPages) &&
								this.featureService.isEnabled(Feature.ShowHuntingNotificationBar)
							);
						const mdatpRuleEditDisabledMessage = this.i18nService.get(
							'scheduledHunting_actions_mdatp_rule_edit_not_allowed_in_mtp'
						);
						actions = [
							...actions,
							{
								id: 'editRule',
								nameKey: 'scheduledHunting.actions.editRule',
								icon: 'edit',
								disabled: isMdatpRuleUnderMtpContext,
								tooltip: isMdatpRuleUnderMtpContext ? mdatpRuleEditDisabledMessage : null,
								method: (rules?: Array<HuntingRule>) => {
									const rulesRepo = this.paris.getRepository(HuntingRule);
									rulesRepo.clearCache();
									const panelSettings: PanelSettings = {
										id: 'scheduled-hunting-edit-panel',
										type: PanelType.wizard,
										noBodyPadding: true,
										disableOverlayClick: true,
									};
									//TODO explicit construction
									return new Promise<void>((resolve, reject) => {
										this.dialogsService
											.showPanel(ScheduledHuntingRuleEditComponent, panelSettings, {
												huntingRule: rules[0],
												queryText: (options && options.editedQueryText) || null,
											})
											.subscribe(
												(panel: ComponentRef<ScheduledHuntingRuleEditComponent>) => {
													const ruleSavedSub = panel.instance.itemSaved.subscribe(
														(rule: HuntingRule) => {
															options.huntingRuleSaved &&
																options.huntingRuleSaved(rule);
															this.huntingQueryListService.refreshQueriesLists();
														}
													);

													panel.onDestroy(() => {
														ruleSavedSub && ruleSavedSub.unsubscribe();
														resolve();
													});
												}
											);
									});
								},
								closeOnAction: true,
							},
							{
								id: 'editQuery',
								nameKey: 'scheduledHunting.actions.editQuery',
								icon: 'hunting',
								disabled: isMdatpRuleUnderMtpContext,
								tooltip: isMdatpRuleUnderMtpContext ? mdatpRuleEditDisabledMessage : null,
								method: ([rule]: Array<HuntingRule>) => {
									return this.router.navigate([`/${HUNTING_ROUTE}`], {
										queryParams: { queryId: rule.queryId },
									});
								},
								refreshOnResolve: false,
								closeOnAction: true,
							},
						];
					}

					const isFirstRuleEnabled = rules[0].isEnabled;
					if (
						isUserExposedToHuntingRulesScope &&
						(isSingleRule || rules.every((rule) => rule.isEnabled === isFirstRuleEnabled))
					) {
						actions = [
							...actions,
							{
								id: 'changeStatus',
								name: this.i18nService.get('scheduledHunting.actions.changeStatus', {
									status: isFirstRuleEnabled
										? this.i18nService.get(
												'scheduledHunting_actions_changeStatus_result_off'
										  )
										: this.i18nService.get(
												'scheduledHunting_actions_changeStatus_result_on'
										  ),
								}),
								icon: isFirstRuleEnabled ? 'clear' : 'check',
								method: (rules?: Array<HuntingRule>) => {
									const updateStatusApi = this.paris.apiCall(
										ScheduledHuntingUpdateStatusApiCall,
										{
											RuleIds: rules.map((rule) => rule.id),
											IsEnabled: !isFirstRuleEnabled,
										}
									);
									return updateStatusApi.toPromise().then(
										() => {
											const rulesRepo = this.paris.getRepository(HuntingRule);
											rulesRepo.clearCache();
											this.dialogsService.showSuccessSnackbar({
												text: this.i18nService.get(
													'scheduledHunting_actions_changeStatus_result',
													{
														newStatus: isFirstRuleEnabled
															? this.i18nService.get(
																	'scheduledHunting_actions_changeStatus_result_off'
															  )
															: this.i18nService.get(
																	'scheduledHunting_actions_changeStatus_result_on'
															  ),
														entity:
															rules.length === 1
																? rulesRepo.entity.singularName
																: rules.length +
																  ' ' +
																  rulesRepo.entity.pluralName,
													}
												),
												icon: isFirstRuleEnabled ? 'clear' : 'check',
											});
										},
										(error) => {
											this.dialogsService.showError({
												title: this.i18nService.get(
													'scheduledHunting_actions_changeStatus_result_error',
													{
														status: isFirstRuleEnabled
															? this.i18nService.get(
																	'scheduledHunting_actions_changeStatus_result_off'
															  )
															: this.i18nService.get(
																	'scheduledHunting_actions_changeStatus_result_on'
															  ),
													}
												),
												data: error,
											});
										}
									);
								},
								closeOnAction: true,
							},
						];
					}

					if (isUserExposedToHuntingRulesScope) {
						actions = [
							...actions,
							{
								id: 'delete',
								nameKey: 'delete',
								icon: 'delete',
								method: (rules?: Array<HuntingRule>) => {
									return new Promise((resolve, reject) => {
										const rulesRepo = this.paris.getRepository(HuntingRule);
										this.dialogsService
											.confirm({
												title:
													rules.length === 1
														? this.i18nService.get('deleteItem', {
																itemName: rulesRepo.entity.singularName.toLowerCase(),
														  })
														: this.i18nService.get('deleteSelectedItems', {
																itemsName: rulesRepo.entity.pluralName.toLowerCase(),
														  }),
												text:
													rules.length === 1
														? this.i18nService.get('deleteConfirmSingular', {
																itemSingularName: rulesRepo.entity.singularName.toLowerCase(),
														  })
														: this.i18nService.get('deleteConfirm', {
																itemPluralName: rulesRepo.entity.pluralName.toLowerCase(),
														  }),
												confirmText: this.i18nService.get('delete'),
											})
											.then((e: ConfirmEvent) => {
												const ids = rules.map((rule) => rule.id);
												if (e.confirmed)
													resolve(
														rulesRepo
															.remove(rules, { data: { ids, RuleIds: ids } })
															.toPromise()
															.then(
																(items) => {
																	this.dialogsService.showSnackbar({
																		text: `Deleted ${(items.length === 1
																			? rulesRepo.entity.singularName
																			: items.length +
																			  ' ' +
																			  rulesRepo.entity.pluralName
																		).toLowerCase()}`,
																		icon: 'delete',
																		iconClassName: 'color-text-error',
																	});

																	if (
																		entityViewType ===
																		EntityViewType.EntityPage
																	)
																		this.router.navigate([
																			this.scheduledHuntingDataviewLink,
																		]);

																	this.huntingQueryListService.refreshQueriesLists();
																},
																(error) => {
																	this.dialogsService.showError({
																		title: this.i18nService.strings
																			.scheduled_hunting_error_delete,
																		data: error,
																	});
																}
															)
													);
												else reject(true);
											});
									});
								},
								closeOnAction: true,
							},
						];
					}
					return actions.map((itemActionConfig) => new ItemActionModel(itemActionConfig));
				})
			);
		},
		dataViewOptions: <EntityDataViewOptions<HuntingRule, {}>>{
			fields: ScheduledHuntingFieldsService,
			defaultSortFieldId: 'LastUpdatedTime',
			dataViewConfig: {
				allowFilters: false,
			},
		},
	};

	private get scheduledHuntingDataviewLink() {
		// In MDATP, the scheduled hunting rules dataview is under settings page
		return this.appContextService.isSCC ? '/custom_detection' : '/preferences2/scheduled_hunting';
	}
}
