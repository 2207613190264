/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "./extended-fab-date-picker.component";
import * as i4 from "../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i5 from "../../../../../../../projects/localization/src/lib/services/tz-date.service";
var styles_ExtendedFabDatePickerComponent = [];
var RenderType_ExtendedFabDatePickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExtendedFabDatePickerComponent, data: {} });
export { RenderType_ExtendedFabDatePickerComponent as RenderType_ExtendedFabDatePickerComponent };
export function View_ExtendedFabDatePickerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "fab-date-picker", [], null, [[null, "onSelectDate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelectDate" === en)) {
        var pd_0 = (_co.emitDate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabDatePickerComponent_0, i1.RenderType_FabDatePickerComponent)), i0.ɵdid(1, 5816320, null, 0, i2.FabDatePickerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { disabled: [0, "disabled"], ariaLabel: [1, "ariaLabel"], pickerAriaLabel: [2, "pickerAriaLabel"], isMonthPickerVisible: [3, "isMonthPickerVisible"], disableAutoFocus: [4, "disableAutoFocus"], placeholder: [5, "placeholder"], formatDate: [6, "formatDate"], minDate: [7, "minDate"], maxDate: [8, "maxDate"] }, { onSelectDate: "onSelectDate" }), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.ariaLabel; var currVal_2 = _co.ariaLabel; var currVal_3 = _co.isMonthPickerVisible; var currVal_4 = _co.disableAutoFocus; var currVal_5 = _co.placeHolder; var currVal_6 = _co.formatDate; var currVal_7 = _co.minDate; var currVal_8 = _co.maxDate; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_ExtendedFabDatePickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "extended-fab-date-picker", [], null, null, null, View_ExtendedFabDatePickerComponent_0, RenderType_ExtendedFabDatePickerComponent)), i0.ɵdid(1, 49152, null, 0, i3.ExtendedFabDatePickerComponent, [i4.LocaleConfigService, i5.TzDateService], null, null)], null, null); }
var ExtendedFabDatePickerComponentNgFactory = i0.ɵccf("extended-fab-date-picker", i3.ExtendedFabDatePickerComponent, View_ExtendedFabDatePickerComponent_Host_0, { ariaLabel: "ariaLabel", disabled: "disabled", minDate: "minDate", maxDate: "maxDate", placeHolder: "placeHolder", isMonthPickerVisible: "isMonthPickerVisible", format: "format", disableAutoFocus: "disableAutoFocus" }, { onSelectDate: "onSelectDate" }, []);
export { ExtendedFabDatePickerComponentNgFactory as ExtendedFabDatePickerComponentNgFactory };
