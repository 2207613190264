<!-- tslint:disable:template-accessibility-alt-text -->
<ng-container *ngIf="this.osTypeKey == OsType.server && ('AscOnboarding' | featureEnabled); else onboardWithWDATP"
	data-track-component="AgentOnboarding">
	<div class="wcd-margin-large-bottom">
		<ng-container *ngTemplateOutlet="onboardWithWDATP"></ng-container>
	</div>
	<wcd-expander [label]="'endpointManagement.agent.onboarding.server.onboardWithAzure' | i18n"
				  labelClass="wcd-font-size-l wcd-font-weight-regular wcd-padding-small-bottom"
				  [isExpanded]="true"
				  class="wcd-margin-large-bottom"
	>
		<asc-onboarding-link class="wcd-margin-large-all"></asc-onboarding-link>
	</wcd-expander>
</ng-container>
<ng-template #onboardWithWDATP>
	<section class="wcd-margin-large-bottom" data-track-component-type="Settings" data-track-component="Sense Onboarding">
		<h3 class="wcd-padding-bottom">{{"endpointManagement.agent.onboarding.step1." + this.osTypeKey + ".title" | i18n}}</h3>
		<div class="wcd-padding-bottom">
			<markdown [data]="'endpointManagement.agent.onboarding.step1.' + this.osTypeKey + '.description' | i18n"></markdown>
		</div>
		<div class="wcd-margin-large-bottom">
			<div *ngIf="error; else monitoringStatus" class="error-message">{{"endpointManagement.agent.onboarding.error" | i18n}}
			</div>
			<ng-template #monitoringStatus>
				<span *ngIf="!loading; else loadingStatus">
					<ng-container [ngSwitch]="onboardingState.status?.type">
						<div *ngSwitchCase="'notStarted'">
							<ng-container *ngTemplateOutlet="turnOnButton"></ng-container>
						</div>
						<div *ngSwitchCase="'failure'">
							<ng-container *ngTemplateOutlet="turnOnButton"></ng-container>
							<div class="error-message">
								{{"endpointManagement.agent.onboarding.step1." + this.osTypeKey + ".status." + onboardingState.status.type | i18n}}
							</div>
						</div>
						<div *ngSwitchCase="'success'">
							{{"endpointManagement.agent.onboarding.step1." + this.osTypeKey + ".status." + onboardingState.status.type | i18n}}
							{{"onboardingWizard_preferences_content_previewFeatures_onLabel" | i18n}}
							<wcd-shared-icon iconName="success" class="color-text-success-light ms-icon-top-2-fix"> </wcd-shared-icon >
						</div>
						<div *ngSwitchCase="'inProgress'">
							<h5 class="color-text-primary">{{"endpointManagement.agent.onboarding.step1." + this.osTypeKey + ".status." + onboardingState.status.type | i18n}}</h5>
							<img src="/assets/images/linear-loader.gif"/>
						</div>
						<div *ngSwitchDefault>
							<img src="/assets/images/linear-loader.gif"/>
						</div>
						<ng-template #turnOnButton>
							<button class="btn btn-primary"
									data-track-id="ConfirmAndTurnOnServerMonitoring"
									data-track-type="Button"
									(click)="confirmAndTurnOnServerMonitoring()">
									{{"endpointManagement.agent.onboarding.step1." + this.osTypeKey + ".turnOn" | i18n}}
							</button>
						</ng-template>
					</ng-container>
				</span>
				<ng-template #loadingStatus><i class="loader-icon"></i></ng-template>
			</ng-template>
		</div>
	</section>
	<section class="wcd-margin-large-bottom">
		<h3 class="wcd-padding-bottom">{{"endpointManagement.agent.onboarding.step2." + this.osTypeKey + ".title" | i18n}}</h3>
		<div>
			<markdown [data]="'endpointManagement.agent.onboarding.step2.' + this.osTypeKey + '.description' | i18n"></markdown>
		</div>
	</section>
	<section class="wcd-margin-large-bottom large-form">
		<h3 class="wcd-padding-bottom">{{"endpointManagement.agent.onboarding.step3.title" | i18n}}</h3>
		<div class="wcd-padding-bottom">
			{{"endpointManagement.agent.onboarding.step3.description" | i18n}}
		</div>
		<div class="form-group">
			<label for="workspace-id">{{"endpointManagement.agent.onboarding.step3.workspaceId" | i18n}}</label>
			<div>
				<input type="text"
					id="workspace-id"
					name="workspace-id"
					readonly
					[(ngModel)]="onboardingState.workspaceId"
					style="width:300px;"
					class="form-control with-action-after"/>
				<copy-to-clipboard [ariaLabelFieldName]="'endpointManagement.agent.onboarding.step3.workspaceId' | i18n" [content]="onboardingState.workspaceId" [originId]="'workspace-id'"></copy-to-clipboard>
			</div>
		</div>
		<div class="form-group">
			<label for="workspace-key">{{"endpointManagement.agent.onboarding.step3.workspaceKey" | i18n}}</label>
			<div>
				<input type="text"
					id="workspace-key"
					name="workspace-key"
					readonly
					[(ngModel)]="onboardingState.workspaceKey"
					style="width:300px;"
					class="form-control with-action-after"/>
				<copy-to-clipboard [ariaLabelFieldName]="'endpointManagement.agent.onboarding.step3.workspaceKey' | i18n" [content]="onboardingState.workspaceKey"
								[originId]="'workspace-key'"></copy-to-clipboard>
			</div>
		</div>
		<div>
			 <wcd-shared-icon iconName="Info" class="color-text-primary ms-icon-top-2-fix"> </wcd-shared-icon >
			{{"endpointManagement.agent.onboarding.step3.newEndpoints" | i18n}}
		</div>
	</section>
	<section class="wcd-margin-large-bottom">
		<h3 class="wcd-padding-bottom">{{"endpointManagement.agent.onboarding.step4.title" | i18n}}</h3>
		<detection-test [settings]="detectionTestSettings"></detection-test>
	</section>
</ng-template>
