var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
var EmailNotificationCompletedComponent = /** @class */ (function (_super) {
    __extends(EmailNotificationCompletedComponent, _super);
    function EmailNotificationCompletedComponent(i18nService) {
        var _this = _super.call(this) || this;
        _this.i18nService = i18nService;
        return _this;
    }
    EmailNotificationCompletedComponent.prototype.ngOnInit = function () {
        this.title = this.data.isUpdateFlow
            ? this.i18nService.strings.email_notification_wizard_ruleUpdated_title
            : this.i18nService.strings.email_notification_wizard_ruleCreated_title;
    };
    return EmailNotificationCompletedComponent;
}(WizardBaseStep));
export { EmailNotificationCompletedComponent };
