import { Injectable } from '@angular/core';
import { BaselineConfiguration, BaselineConfigurationDevice } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class BaselineDetectedValuesService {
	constructor(private i18nService: I18nService) { }

	isConfigurationSupported = (configuration: BaselineConfiguration | BaselineConfigurationDevice) =>
		(configuration.sourceValues && configuration.sourceValues.length && configuration.sourceValues[0] !== "Unsupported");

	getConfigurationDetectedValuesString(configuration: BaselineConfiguration | BaselineConfigurationDevice): string {
		if (!this.isConfigurationSupported(configuration)) {
			return null;
		}
		if (!configuration.detectedValues || configuration.detectedValues.length === 0) {
			return this.i18nService.strings.notAvailable_long;
		}

		if (configuration.detectedValues.length === 1 || !configuration.sourceValues || configuration.sourceValues.length === 0) {
			return configuration.detectedValues[0];
		}

		const firstSource = configuration.sourceValues[0];
		const allTheSame = configuration.sourceValues.every(s => s === firstSource);

		return allTheSame ? configuration.detectedValues[0] : configuration.detectedValues.join(" | ");
	}

	getConfigurationUniqueSources(configuration: BaselineConfiguration | BaselineConfigurationDevice): string[] {
		if (!this.isConfigurationSupported(configuration)) {
			return null;
		}
		if (!configuration.sourceValues || configuration.sourceValues.length === 0) {
			return [this.i18nService.strings.notAvailable_long];
		}
		if (configuration.sourceValues.length === 1) {
			return [configuration.sourceValues[0]];
		}
		const set = new Set();
		const res = [];

		configuration.sourceValues.forEach(s => !set.has(s) && set.add(s) && res.push(s));
		return res
	}
}
