
		<alerts-dataview
			*ngIf="this.associatedAlertsRepo?.sourceItem"
			[allowFilters]="false"
			[repository]="associatedAlertsRepo"
			[disabledFields]="['rbacGroup']"
			[sortDisabledFields]="sortDisabledAlertFields"
			[allowGrouping]="false"
			className="wcd-padding-large-horizontal"
			[showHeader]="false"
			[allowTimeRangeSelect]="false"
			dataViewId="suppression-rule-alerts"
			[responsiveActionBar]="true"
			[removePadding]="true"
			[fixedOptions]="fixedOptions"
			(onTableRenderComplete)="onTableRenderComplete()"
			[removePaddingRight]="isScc">
		</alerts-dataview>
	