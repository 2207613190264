/* tslint:disable:template-accessibility-label-for */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageBarType } from 'office-ui-fabric-react';
import { PanelContainer } from '@wcd/panels';
import { EvaluationService, ConsentType } from '../../services/evaluation.service';

@Component({
	selector: 'simulator-agent-consent-form',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<div
				class="wcd-full-height wcd-flex-justify-content-space-between wcd-flex-vertical wcd-padding-large-horizontal wcd-scroll-vertical"
			>
				<div>
					<label class="wcd-font-weight-semibold wcd-font-size-xxl wcd-margin-bottom">
						{{ 'evaluation.lab.setupPanel.simulatorAgentStep.' + this.consentTitle | i18n }}
					</label>
					<fab-message-bar [messageBarType]="MessageBarType.warning">
						<markdown
							[data]="
								'evaluation.lab.setupPanel.simulatorAgentStep.consentForm.notification' | i18n
							"
						>
						</markdown>
					</fab-message-bar>
					<div class="wcd-margin-largeMedium-vertical">
						<markdown
							[data]="
								'evaluation.lab.setupPanel.simulatorAgentStep.' + this.consentContent | i18n
							"
						>
						</markdown>
					</div>
				</div>
				<div class="wcd-padding-top wcd-flex-horizontal">
					<fab-primary-button
						contentClass="wcd-margin-small-horizontal wcd-margin-none-all"
						[text]="
							'evaluation_lab_setupPanel_simulatorAgentStep_consentForm_accept_button' | i18n
						"
						(onClick)="onConsentAccepted()"
					></fab-primary-button>
				</div>
			</div>
		</wcd-panel>
	`,
})
export class SimulatorAgentsConsentFormComponent extends PanelContainer implements OnInit {
	@Input() consentType: ConsentType;
	@Output() onAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();

	consentTitle: string;
	consentContent: string;
	MessageBarType = MessageBarType;

	constructor(router: Router, private evaluationService: EvaluationService) {
		super(router);
	}

	ngOnInit() {
		switch (this.consentType) {
			case ConsentType.MsInformation: {
				this.consentTitle = 'msConsentFirstDeclaration';
				this.consentContent = 'msFirstConsentContent';
				break;
			}
			case ConsentType.MsInformationSharing: {
				this.consentTitle = 'msConsentSecondDeclaration';
				this.consentContent = 'msSecondConsentContent';
				break;
			}
			case ConsentType.AttackIQ: {
				this.consentTitle = 'attackIqConsentTitle';
				this.consentContent = 'attackIqConsentContent';
				break;
			}
		}
	}

	onConsentAccepted() {
		this.closePanel();
		this.onAccepted.emit(true);
	}
}
