import { Component, Input, ChangeDetectionStrategy, Output } from '@angular/core';
import { DownloadService, DownloadOptions } from '../services/download.service';
import { of, Observable } from 'rxjs';
import { toObservable } from '../../utils/rxjs/utils';
import { map } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';

@Component({
	selector: 'download-file',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<fab-action-button
			[iconProps]="{ iconName: 'Download' }"
			[disabled]="disabled"
			(onClick)="downloadFile()"
		>
		</fab-action-button>
	`,
})
export class DownloadFileComponent {
	/***
	 * The saved file name
	 */
	@Input() filename?: string;

	/***
	 * The file content to save. Can be empty if downloadSettings is set.
	 */
	@Input() fileContent?: string | Observable<string> | Promise<string>;

	/***
	 * Configures the file download from backend. Can be empty if fileContent is set.
	 */
	@Input() downloadSettings: DownloadFileSettings;

	@Input() disabled = false;

	@Output() onDownload: EventEmitter<void> = new EventEmitter<void>();

	constructor(private downloadService: DownloadService) {}

	downloadFile() {
		this.onDownload.emit(null);

		if (this.fileContent) {
			const blob$ = toObservable(this.fileContent).pipe(
				map(content => new Blob([content], { type: 'text/plain' }))
			);
			return this.downloadService.download(blob$, this.filename);
		} else if (this.downloadSettings && this.downloadSettings.url) {
			const options: DownloadOptions = {
				...this.downloadSettings,
				downloadedFileName: this.filename,
			};

			return this.downloadService.downloadFromUrl(this.downloadSettings.url, options);
		}
	}
}

export interface DownloadFileSettings extends DownloadOptions {
	url: string;
}
