import {
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Type,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { CurrentWizardStepSettings, StepStatus, WizardStepConfig } from '../models/wizard.model';
import { VerticalStepModel } from './vertical-steps/vertical-step.model';
import { I18nService } from '@wcd/i18n';
import { WizardBaseStep } from './base-wizard-step.component';
import { AppInsightsService } from '../../../../../apps/portal/src/app/insights/services/app-insights.service';
import { TrackingEventType } from '@wcd/telemetry';
import { NextButtonModeEnum } from './next-button-mode.enum';

const COMPLETE_STEP_ICON_NAME = 'CompletedSolid';

@Component({
	selector: 'wcd-wizard',
	templateUrl: './wizard.component.html',
	styleUrls: ['./wizard.component.scss'],
})
export class WcdWizardComponent<T> implements OnDestroy, OnInit {
	@ViewChild('stepTarget', { read: ViewContainerRef, static: true }) stepContainerRef: ViewContainerRef;
	@Input() steps: WizardStepConfig<T>[];
	@Input() data: T;
	@Input() wizardTitlePrefix: String;
	@Input() wizardTitle: String;
	@Input() wizardTrackingName: String;
	@Output() onDone = new EventEmitter<void>();
	@Output() onClose = new EventEmitter<void>();

	NextButtonModeEnum = NextButtonModeEnum;
	verticalSteps$ = new ReplaySubject<VerticalStepModel[]>(1);
	currentStep$ = new ReplaySubject<CurrentWizardStepSettings>(1);
	private currentStepIndex = 0;
	private stepsValidity: { [index: number]: boolean } = {};
	private stepsTrackingProperties: Map<string, string> = new Map<string, string>();
	private backButtonEnabled: { [index: number]: boolean } = {};
	private nextButtonMode: { [index: number]: NextButtonModeEnum } = {};
	private nextSkipButtonText: { [index: number]: string } = {};
	private onNext$: Subscription;
	private stepsOnNext: { [index: number]: () => Observable<boolean> } = {};

	constructor(
		private i18nService: I18nService,
		private changeDetectorRef: ChangeDetectorRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private appInsightsService: AppInsightsService
	) {}

	ngOnInit() {
		this.steps.map((step, index) => (this.stepsValidity[index] = false));
		this.steps.map((step, index) => (this.backButtonEnabled[index] = true));
		this.steps.map((step, index) => (this.nextButtonMode[index] = NextButtonModeEnum.Normal));
		this.steps.map((step, index) => (this.nextSkipButtonText[index] = ""));
		this.publishCurrentStep();
	}

	ngOnDestroy(): void {
		this.verticalSteps$.complete();
		this.currentStep$.complete();
		this.onNext$ && this.onNext$.unsubscribe();
	}

	nextStep = () => {
		if (!this.steps[this.currentStepIndex].onNext) {
			this.doNext();
			return;
		}

		this.onNext$ = this.stepsOnNext[this.currentStepIndex]().subscribe(res => {
			if (res) {
				this.doNext();
			}
		});
	};

	doneStep = () => {
		this.trackWizardFlow('DoneStep', TrackingEventType.Button);
		this.onDone.emit();
	};

	setBackButtonEnabled = (enabled: boolean) => {
		this.backButtonEnabled[this.currentStepIndex] = enabled;
		this.changeDetectorRef.detectChanges();
	};

	setNextButtonMode = (mode: NextButtonModeEnum, skipButtonText?: string) => {
		this.nextButtonMode[this.currentStepIndex] = mode;
		this.nextSkipButtonText[this.currentStepIndex] = skipButtonText;
		this.changeDetectorRef.detectChanges();
	};

	previousStep = () => {
		this.trackWizardFlow('PreviousStep', TrackingEventType.Button);

		if (this.isFirstStep()) {
			return;
		}

		this.currentStepIndex--;
		this.publishCurrentStep();
	};

	goToStep = (step: number) => {
		this.trackWizardFlow('GoToStep', TrackingEventType.Button);

		this.currentStepIndex = step;
		this.publishCurrentStep();
	};

	jumpToStepId(stepId: number) {
		const stepIndex = this.steps.findIndex(s => s.id === stepId);
		if (stepIndex > -1) {
			this.currentStepIndex = stepIndex;
			this.publishCurrentStep();
		}
	}

	isValidStep(): boolean {
		return this.stepsValidity[this.currentStepIndex];
	}

	private trackWizardFlow = (id: string, type: TrackingEventType) => {
		this.appInsightsService.trackEvent('WizardTrack', {
			id: id,
			type: type,
			wizardTrackingName: this.wizardTrackingName,
			component: this.steps[this.currentStepIndex].name,
			additionalTrackingProperties: this.stepsTrackingProperties,
		});
	};

	private doNext() {
		if (!this.isLastStep()) {
			this.currentStepIndex++;
			this.publishCurrentStep();
		}
		this.trackWizardFlow('NextStep', TrackingEventType.Button);
	}

	private loadComponent = (
		component: Type<WizardBaseStep<T>>,
		componentInputs: Map<string, any> = new Map(),
		componentOutputs: Map<string, Function> = new Map()
	) => {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
		this.stepContainerRef.clear();
		const stepComponentRef = this.stepContainerRef.createComponent(componentFactory);
		// Set component specific inputs
		Array.from(componentInputs.entries()).map(([inputName, value]) => {
			stepComponentRef.instance[inputName] = value;
		});
		// Set component specific outputs
		Array.from(componentOutputs.entries()).map(([outputName, func]) => {
			stepComponentRef.instance[outputName].subscribe(func);
		});
		// Set base step methods and inputs
		stepComponentRef.instance.data = this.data;
		stepComponentRef.instance.setStepTrackingProperties = this.setStepTrackingProperties;
		stepComponentRef.instance.setStepValidation = this.setStepValidation;
		stepComponentRef.instance.nextStep = this.nextStep;
		stepComponentRef.instance.previousStep = this.previousStep;
		stepComponentRef.instance.goToStep = this.goToStep;
		stepComponentRef.instance.setOnNext = this.setOnNext;
		stepComponentRef.instance.markWizardCompleted = this.markWizardCompleted;
		stepComponentRef.instance.setBackButtonEnabled = this.setBackButtonEnabled;
		stepComponentRef.instance.setNextButtonMode = this.setNextButtonMode;
		this.changeDetectorRef.detectChanges();
		this.trackWizardFlow('EnterStep', TrackingEventType.Navigation);
	};

	private setStepValidation = (isValid: boolean) => {
		this.stepsValidity[this.currentStepIndex] = isValid;
		this.changeDetectorRef.detectChanges();
	};

	private setStepTrackingProperties = (trackingProperties: Map<string, string>) => {
		this.stepsTrackingProperties = trackingProperties;
	};

	private setOnNext = (onNext: () => Observable<boolean>) => {
		this.stepsOnNext[this.currentStepIndex] = onNext;
	};

	private isLastStep(): boolean {
		return this.currentStepIndex === this.steps.length - 1;
	}

	private isFirstStep(): boolean {
		return this.currentStepIndex === 0;
	}

	private publishCurrentStep() {
		this.verticalSteps$.next(
			this.steps.map(
				(config, index): VerticalStepModel => ({
					text: this.i18nService.get(config.verticalStepKeyName),
					status: this.getStepStatus(index),
					isBold: index === this.currentStepIndex,
				})
			)
		);
		this.currentStep$.next({
			withDoneButton: false,
			withBackButton: !this.isFirstStep(),
			withNextButton: true,
			withCloseButton: true,
			nextButtonText: this.steps[this.currentStepIndex].nextButtonText || 'buttons.next',
			wideStep: this.steps[this.currentStepIndex].wideStep,
		});
		this.loadComponent(
			this.steps[this.currentStepIndex].component,
			this.steps[this.currentStepIndex].componentInputs,
			this.steps[this.currentStepIndex].componentOutputs
		);
	}

	markWizardCompleted = (completeComponent?: Type<WizardBaseStep<T>>) => {
		this.verticalSteps$.next(
			this.steps.map(
				(config, index): VerticalStepModel => ({
					text: this.i18nService.get(config.verticalStepKeyName),
					status: this.getStepStatus(index),
					isBold: false,
					iconName: COMPLETE_STEP_ICON_NAME,
					contentClass: 'ms-color-greenLight',
				})
			)
		);
		this.currentStep$.next({
			withCloseButton: true,
			withNextButton: false,
			withBackButton: false,
			withDoneButton: true,
		});

		if (completeComponent) {
			this.loadComponent(completeComponent);
		}
	};

	private getStepStatus = (index: number): string => {
		if (index > this.currentStepIndex) {
			return StepStatus.next;
		} else if (index === this.currentStepIndex){
			return StepStatus.current;
		}
		return StepStatus.completed;
	};
}
