import { SevilleModule } from '../../seville/seville.module';
import '../services/seville.threatintel.progressivetimelineloader';
import { FeaturesService, Feature } from '@wcd/config';
import { RegExpService } from '@wcd/shared';
import { CANCEL_CONFIRMATION_TEMPLATE, FILE_TEMPLATE } from '../actioncenter/seville.actioncenter.file';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { RbacMdeAllowedActions } from '../../../../rbac/enums/mde-allowed-actions.enum';
import { OfficeIntegrationService } from '../../../../admin/services/office-integration.service';
import { MachineGroup, OfficeIntegrationSettings, FileId, FileProtectionInfo } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { MachinesService } from '../../../../@entities/machines/services/machines.service';
import { filter } from 'rxjs/operators';
import { breadcrumbsStateService } from '@wcd/shared';
import { FilesBackendService } from '../../../../@entities/files/services/files.backend.service';
import { TagsService } from '../../../../tags/services/tags.service';
import { FilesService } from '../../../../@entities/files/services/files.service';
import { TrackingEventType } from '../../../../insights/models/tracking-event-type.enum';
import { AskThreatExpertService } from '../../../../feedback/services/ask-threat-expert.service';

SevilleModule.controller('seville.threatintel.file', fileController);

SevilleModule.config([
	'$stateProvider',
	'searchProvider',
	function($stateProvider, searchProvider) {
		var md5Regex = /(^|\s)([0-9A-Fa-f]{32})(?=\s|$)/;
		var sha1Regex = /(^|\s)([a-fA-F0-9]{40})(?=\s|$)/;
		var sha256Regex = /(^|\s)([0-9A-Fa-f]{64})(?=\s|$)/;

		$stateProvider.state('file', {
			url: '/file/:id/:time',
			params: {
				time: {
					value: null,
					squash: true,
				},
			},
			featureFlagToggleFeatureId: Feature.UpgradeFilePage,
			showToggleFeatureId: Feature.ShowUpgradeFilePageToggle,
			title: 'File',
			showBreadcrumbs: true,
			data: { entity: null },
			views: {
				main: {
					template: `
				<div ng-if="file.main" data-track-component="FilePage">
					<div ng-if="file.showDeprecatedMessage"
						class="wcd-padding-horizontal wcd-flex-center-vertical wcd-flex-horizontal ms-background-color-themeLight"
						style="height:50px; margin: 0 -12px;">
						<i class="icon icon-Info wcd-margin-small-right"></i>
						<div class="message-bar-text wcd-flex-1">
							<span>{{file.deprecatedMessageText}}</span>
							<a ng-click="file.turnOnNewFilePage()">Take me to the new file page!</a>
						</div>
					</div>
              <div class="row entity-title">
                File worldwide
              </div>
              <div class="row entity">
                <div class="col-sm-12 col-md-12 col-lg-6 entity-cube">
                  <div class="inner-box border-default">
                    <div class="file-profile-icon-container">
                      <i class="icon icon-md icon-Page file-main-icon-page"></i>
					  <span class="entity-meta-main-title wcd-margin-medium-right">File</span>
					  <ul class="tags-list wcd-inline-block" ng-if="::file.fileSensitivityEnabled">
						<li ng-if="::file.sensitivityTag" class="{{:: file.sensitivityTag.className}}" tooltips tooltip-title="Data sensitivity label applied to the file">
							<span class="tag-text">{{::file.sensitivityTag.name}}</span>
						</li>
					  </ul>
                    </div>
                    <div class="machine-actions"
                       ng-if="(file.ResponseFeatureEnabled && !file.disabledResponseActionReasonDescription) || file.downloadSampleEnabled">
                      <div class="btn-group" uib-dropdown>
                        <button id="single-button" type="button" class="btn btn-primary secondary-button"
                            uib-dropdown-toggle ng-disabled="disabled">
                          Actions <span class="icon icon-ChevronDown machine-actions-icon"></span>
                        </button>
                        <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button" data-track-component="fileActions" data-track-component-type="">
                          <li role="menuitem" ng-if="file.ResponseFeatureEnabled" ng-switch="file.main.QuarantineStatus" rbacjs state="'disabled'" permissions="['remediationActions']">
                            <a ng-switch-when="RequestPending" uib-popover="Action is pending completion..."
                            	data-track-id="FileStopAndQuarantineAction" data-track-type="Action"
                               popover-trigger="'mouseenter'"
                               popover-class="threatintel-popover response-action-pending-action-popover-content"
                               popover-placement="right" href="#">
                              <span class="response-pending-dropdown-menu-item">Stop and Quarantine File</span>
                            </a>
                            <a ng-switch-when="NotAvailable"
                               uib-popover="Action unavailable. Upgrade the device to Windows 10 Creators Update or newer."
                               data-track-id="FileStopAndQuarantineAction" data-track-type="Action"
                               popover-trigger="'mouseenter'"
                               popover-class="threatintel-popover file-quarantine-action-popover-content"
                               popover-placement="right" href="#">
                              <span class="response-pending-dropdown-menu-item">Stop and Quarantine File</span>
                            </a>
                            <a ng-switch-when="Readonly" uib-popover="Option unavailable to read-only accounts"
                            	data-track-id="FileStopAndQuarantineAction" data-track-type="Action"
                               popover-trigger="'mouseenter'"
                               popover-class="threatintel-popover response-action-readonly-mode-action-popover-content"
                               popover-placement="right" href="#">
                              <span class="response-pending-dropdown-menu-item">Stop and Quarantine File</span>
                            </a>
                            <a ng-switch-when="Loading" uib-popover="Action unavailable. Loading data..."
                            	data-track-id="FileStopAndQuarantineAction" data-track-type="Action"
                                popover-trigger="'mouseenter'" popover-class="threatintel-popover response-action-loading-action-popover-content"
                                popover-placement="right" href="#">
                            <span class="response-pending-dropdown-menu-item">Stop and Quarantine File</span>
							</a>
							<a ng-switch-when="Disabled" uib-popover-html="file.disabledResponseActionReasonDescription"
								data-track-id="FileStopAndQuarantineAction" data-track-type="Action"
								popover-trigger="'mouseenter'" popover-class="threatintel-popover nowrap"
								popover-placement="right" href="#">
							<span class="response-pending-dropdown-menu-item">Stop and Quarantine File</span>
							</a>
                            <a ng-switch-default ng-click="file.quarantine()"
                               uib-popover="Submit a request to apply an action to this file"
                               data-track-id="FileStopAndQuarantineAction" data-track-type="Action"
                               popover-trigger="'mouseenter'"
                               popover-class="threatintel-popover file-submit-action-popover-content"
                               popover-placement="right">Stop and Quarantine File</a>
                          </li>
                          <li role="menuitem" ng-if="file.ResponseFeatureEnabled" ng-switch="file.main.BlacklistStatus" rbacjs state="'disabled'" permissions="['remediationActions']">
                            <a ng-switch-when="Blocked" ng-click="file.blacklist()"
                               uib-popover="Submit a request to apply an action to this file"
                               data-track-id="FileRemoveFromBlockedListAction" data-track-type="Action"
                               popover-trigger="'mouseenter'"
                               popover-class="threatintel-popover file-submit-action-popover-content"
                               popover-placement="right" href="#">Remove from blocked list</a>
                            <a ng-switch-when="Unblocked" ng-click="file.blacklist()"
                               uib-popover="Submit a request to apply an action to this file"
                               data-track-id="FileBlockAction" data-track-type="Action"
                               popover-trigger="'mouseenter'"
                               popover-class="threatintel-popover file-submit-action-popover-content"
                               popover-placement="right" href="#">Block file</a>
                            <a ng-switch-when="NotAvailable" href="preferences2/integration" data-track-id="FileBlockAction" data-track-type="Action">
                                            <span
                              class="response-block-pending-dropdown-menu-item response-pending-dropdown-menu-item">
                                                Block file
                                            </span>
                              <i class="icon file-block-repair-icon icon-Repair"
                                 uib-popover="Open settings page to enable this feature"
                                 popover-trigger="'mouseenter'"
                                 popover-class="threatintel-popover file-block-action-popover-content"
                                 popover-placement="right" href="#"></i>
                            </a>
                            <a ng-switch-when="Readonly" uib-popover="Option unavailable to read-only accounts"
                            	data-track-id="FileBlockAction" data-track-type="Action"
                               popover-trigger="'mouseenter'"
                               popover-class="threatintel-popover response-action-readonly-mode-action-popover-content"
                               popover-placement="right" href="#">
                              <span class="response-pending-dropdown-menu-item">Block file</span>
							</a>
							<a ng-switch-when="BlockedByAdmin" uib-popover="Option unavailable. Blocked by a global admin"
                            	data-track-id="FileBlockAction" data-track-type="Action"
                               popover-trigger="'mouseenter'"
                               popover-class="threatintel-popover file-block-action-blockedbyadmin-popover-content"
                               popover-placement="right" href="#">
                              <span class="response-pending-dropdown-menu-item">Block file</span>
                            </a>
                            <a ng-switch-when="NonPeFile"
                            	data-track-id="FileBlockAction" data-track-type="Action"
                               uib-popover="Block action is available only for Windows executable files"
                               popover-trigger="'mouseenter'"
                               popover-class="threatintel-popover file-nonpefile-action-popover-content"
                               popover-placement="right" href="#">
                              <span class="response-pending-dropdown-menu-item">Block file</span>
							</a>
							<a ng-switch-when="Disabled"
                            	data-track-id="FileBlockAction" data-track-type="Action"
								uib-popover-html="file.disabledResponseActionReasonDescription"
                               	popover-trigger="'mouseenter'"
                               	popover-class="threatintel-popover nowrap"
                               	popover-placement="right" href="#">
                              <span class="response-pending-dropdown-menu-item">Block file</span>
                            </a>
						  </li>

						  <li  rbacjs state="'disabled'" permissions="['alertsInvestigation']" ng-if="file.huntingEnabled" role="menuitem">
                              <a router-link="['/hunting']"
                              	data-track-id="GoToHunting"
								data-track-type="Navigation"
                              	uib-popover="Query raw event data using Advanced hunting"
                              	popover-trigger="'mouseenter'"
                              	popover-class="threatintel-popover action-center-hunting"
                              	popover-placement="right">
                                <span>Go to Advanced hunting</span>
                              </a>
                          </li>
						  <!-- ./Hunting -->
                          <li role="menuitem" ng-if="file.showConsultThreatExpertMenuItem">
                            <a ng-click="file.showExpertPanel()"
							   data-track-id="consultThreatExpertMenuItem" data-track-type="Action">
									<span>Consult a threat expert</span>
                            </a>
                          </li>
                          <li class="divider"></li>
                          <li role="menuitem" rbacjs state="'disabled'" permissions="['remediationActions', 'alertsInvestigation']">
                            <a ng-click="file.openHover()" uib-popover="View the status of submitted actions"
							   data-track-id="FileActionCenterAction" data-track-type="Action"
                               popover-trigger="'mouseenter'"
                               popover-class="threatintel-popover response-action-action-center-action-popover-content"
                               popover-placement="right" href="#">
                                            <span>
                                                Action center
                                                <span ng-if="file.main.ResponseActionExists"
                                  class="icon action-center-response-action-circle"></span>
                                                <span ng-if="file.main.ResponseActionExists"
                                  class="action-center-response-action-exclamation-mark"></span>
                                            </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <span ng-if="file.main.ResponseActionExists" class="icon response-action-circle"></span>
                      <span ng-if="file.main.ResponseActionExists" class="response-action-exclamation-mark"></span>
                    </div>
                    <div class="machine-actions"
                       ng-if="file.ResponseFeatureEnabled && file.disabledResponseActionReasonDescription && !file.downloadSampleEnabled">
                            <span class="tool-tip-disabled-button"
                        uib-popover-html="file.disabledResponseActionReasonDescription"
                        popover-trigger="'mouseenter'"
                        popover-class="threatintel-popover file-disable-action-menu-popover-content"
                        popover-placement="right">
                                <button type="button" class="btn.disabled secondary-button-disabled" ng-disabled="true">
                                    Actions <span class="icon icon-ChevronDown machine-actions-icon"></span>
                                </button>
                            </span>
                    </div>
                    <div>
                      <div ng-if="::file.main.Sha1">SHA1: {{::file.main.Sha1}}</div>
                      <div ng-if="file.main.Sha256">SHA256: {{file.main.Sha256}}</div>
                      <div ng-if="file.main.Md5">MD5: {{file.main.Md5}}</div>
                      <div ng-if="file.main.Size">Size: {{file.main.Size | bytes}}</div>
                      <div>
                        Signer:
                        <span ng-if="file.main.IsValidCertificate && file.main.Signer"> {{file.main.Signer}}</span>
                        <span ng-if="!file.main.IsValidCertificate && file.main.Signer && file.main.Signer.indexOf('INVALID') == 0"> {{file.main.Signer}}</span>
                        <span ng-if="!file.main.IsValidCertificate && file.main.Signer && file.main.Signer.indexOf('INVALID') != 0"> INVALID: {{file.main.Signer}}</span>
                        <span ng-if="!file.main.Signer"> unsigned </span>
                      </div>
                      <div>
                        Issuer:
                        <span ng-if="file.main.IsValidCertificate && file.main.Issuer"> {{file.main.Issuer}}</span>
                        <span ng-if="!file.main.IsValidCertificate && file.main.Issuer && file.main.Issuer.indexOf('INVALID') == 0"> {{file.main.Issuer}}</span>
                        <span ng-if="!file.main.IsValidCertificate && file.main.Issuer && file.main.Issuer.indexOf('INVALID') != 0"> INVALID: {{file.main.Issuer}}</span>
                        <span ng-if="!file.main.Issuer"> unsigned </span>
					  </div>
					  <div ng-if="file.isAzureInfoProtectionApplied != null">
						  AIP protected: {{::file.isAzureInfoProtectionApplied ? 'Yes' : 'No' }}
					  </div>
                    </div>
                  </div>
                </div>

                <!-- Virus Total -->
                <div class="col-sm-6 col-md-6 col-lg-3 entity-cube">
                  <div class="inner-box border-default">
                    <div class="file-malware-detection-title">
                                <span class="entity-meta-main-title">
                                    <span> Malware detection </span>
                                </span>
                    </div>
                    <div class="file-malware-detection-box">
                      <span ng-if="file.vtDataExists">VirusTotal detection ratio:</span>
                      <span ng-if="!file.vtDataExists">File not found in VirusTotal</span>
                    </div>
                    <div ng-if="file.vtDataExists">
                      <span class="file-malware-detection-counter">{{file.positiveScanners}}/{{file.totalScanners}}</span>
                      <a href="{{file.permalink}}" target="_blank"
                      	data-track-id="VirusTotalLink"
						data-track-type="ExternalLink"
                      	class="file-virus-total-link">VirusTotal</a>
                    </div>
                    <div ng-if="!file.vtDataExists" class="file-malware-detection-counter-empty">
                    </div>

                    <div class="file-malware-detection-counter-child bolder">
                      <i class="icon file-windows-defender-icon icon-Castle"></i>Windows Defender AV:
                    </div>
                    <div>
						<span ng-if="file.main.Threat">
							{{file.main.Threat}}
							<a href="http://go.microsoft.com/fwlink/?LinkID=142185&Name={{file.main.Threat}}"
							   	data-track-id="MicrosoftEncyclopediaReadMore"
								data-track-type="ExternalLink"
							   target=" _blank" class="file-ms-encyclopedia-link">
								<i class="icon icon-ReadingMode file-virus-total-report-link-icon"
								 uib-popover="Read more on Microsoft Encyclopedia" popover-trigger="'mouseenter'"
								 popover-class="threatintel-popover file-encyclopedia-popover"
								 popover-placement="right"></i>
							</a>
						</span>
                      <span ng-if="!file.main.Threat">No detections found</span>
                    </div>
                  </div>
                </div>

                <!-- Prevalence -->
                <div class="col-sm-6 col-md-6 col-lg-3 entity-cube">
                  <prevalence-cube loaded="file.prevalenceLoaded" org="false" prevalence="file.worldPrevalence"
                           first-seen="file.main.FirstObserved" last-seen="file.main.LastObserved"></prevalence-cube>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">

                  <!--Deep analysis-->
                  <div class="row entity-section">
                    <div class="col-xs-12">
                      <deep-analysis-request-id sha1="file.main.Sha1"
                                    is-pe-file="file.main.IsPeFile"></deep-analysis-request-id>
                      <deep-analysis-summary-id sha1="file.main.Sha1"></deep-analysis-summary-id>
                    </div>
                  </div>

                  <!--Associated Alerts-->
                  <associated-ioas entity="'file'" sha1="file.main.Sha1" sha256="file.main.Sha256"
                           refresh-investigation-states="true"></associated-ioas>

                  <div class="row entity-title entity-section">
                    File in organization
                  </div>

                  <div class="row">
                    <div>
                      Filter by:
                      <div class="dropdown inline" uib-dropdown>
                        <button class="btn btn-link dropdown-toggle alerts-btn" uib-dropdown-toggle type="button"
                            data-toggle="dropdown" aria-expanded="true">
                                        <span>
                                            <span ng-if="file.telemetryLookback == 180">6 months</span>
                                            <span ng-if="file.telemetryLookback == 1">1 day</span>
                                            <span ng-if="file.telemetryLookback != 180 && file.telemetryLookback > 1">
                                                {{file.telemetryLookback}} days
                                            </span>
                                        </span>
                          <span class="icon icon-sm icon-ChevronDown"></span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-shadow dropdown-margin">
                          <li ng-class="{'dropdown-selected' : file.telemetryLookback == '1'}"><a href="#"
                                                              	data-track-id="TelemetryLookback1d"
																data-track-type="Selection"
                                                              ng-model="file.telemetryLookback"
                                                              uib-btn-radio="'1'">1
                            day</a></li>
                          <li ng-class="{'dropdown-selected' : file.telemetryLookback == '3'}"><a href="#"
                          										data-track-id="TelemetryLookback3d"
																data-track-type="Selection"
                                                              ng-model="file.telemetryLookback"
                                                              uib-btn-radio="'3'">3
                            days</a></li>
                          <li ng-class="{'dropdown-selected' : file.telemetryLookback == '7'}"><a href="#"
                          										data-track-id="TelemetryLookback7d"
																data-track-type="Selection"
                                                              ng-model="file.telemetryLookback"
                                                              uib-btn-radio="'7'">7
                            days</a></li>
                          <li ng-class="{'dropdown-selected' : file.telemetryLookback == '30'}"><a href="#"
                          										data-track-id="TelemetryLookback30d"
																data-track-type="Selection"
                                                               ng-model="file.telemetryLookback"
                                                               uib-btn-radio="'30'">30
                            days</a></li>
                          <li ng-class="{'dropdown-selected' : file.telemetryLookback == '180'}"><a href="#"
                          										data-track-id="TelemetryLookback180d"
																data-track-type="Selection"
                                                                ng-model="file.telemetryLookback"
                                                                uib-btn-radio="'180'">6
                            months</a></li>
                        </ul>
                      </div>
                    </div>

                    <!--Prevalence-->
                    <prevalence-cube loaded="file.prevalenceLoaded"
                             ng-class="{'col-sm-12 col-md-4 col-lg-3 entity-cube': !file.o365IntegrationEnabled , 'col-sm-12 col-md-6 col-lg-3 entity-cube' : file.o365IntegrationEnabled }"
                             org="true"
                             prevalence="file.orgPrevalence"
                             first-seen="file.orgFirstseen"
                             lookback="file.telemetryLookback"
                             last-seen="file.orgLastseen">
                    </prevalence-cube>

                    <prevalence-mail-cube loaded="file.mailPrevalenceLoaded" ng-if="file.o365IntegrationEnabled "
                                class="col-sm-12 col-md-6 col-lg-3 entity-cube"
                                prevalence="file.mailPrevalence"
                                first-seen="file.mailFirstSeen"
                                last-seen="file.mailLastSeen"
                                lookback="file.telemetryLookback"
                                sender="file.mailSender"
                                number-of-senders="file.numberOfSenders"
                                deep-link-to-office="file.deepLinkToO365Portal"
                                is-demo-tenant="file.isDemoTenant"
                                sha256="file.main.Sha256">
                    </prevalence-mail-cube>

                    <!-- Top file names -->
                    <div
                      ng-class="{'col-md-8 col-lg-9 entity-cube': !file.o365IntegrationEnabled && !file.lsHashSimilarityEnabled, 'col-md-12 col-lg-6 entity-cube' : file.o365IntegrationEnabled && !file.lsHashSimilarityEnabled, 'col-md-6 col-lg-3 entity-cube': file.lsHashSimilarityEnabled}">
                      <div class="inner-box-scroll border-default">
                        <table class="entity-ip-reverseip-title-container">
                          <tr>
                            <td>
                                                <span class="entity-meta-main-title">
                                                    {{file.TopFileNames ? (file.TopFileNames.length == 1 ? '1 file name observed' : file.TopFileNames.length + ' file names observed') : 'File names observed'}}
                                                </span>
                            </td>
                          </tr>
                        </table>

                        <div class="entity-meta-main-content entity-meta-main-content-scroll file-names overflow-all">
                          <div ng-repeat="name in file.TopFileNames track by $index">
                            {{ name }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Similar files -->
                    <div ng-if="::file.lsHashSimilarityEnabled"
                       ng-class="{'col-md-8 col-lg-6 entity-cube': !file.o365IntegrationEnabled , 'col-md-6 col-lg-3 entity-cube' : file.o365IntegrationEnabled }">
                      <div class="inner-box-scroll border-default">
                        <table class="entity-ip-reverseip-title-container">
                          <tr>
                            <td>
                                                <span class="entity-meta-main-title">
                                                    Similar files observed
                                                </span>
                            </td>
                          </tr>
                        </table>

                        <div class="entity-meta-main-content entity-meta-main-content-scroll file-names overflow-all">
                          <div ng-if="file.topSimilarFiles.length > 0"
                             ng-repeat="similarFile in file.topSimilarFiles">
                            <a ng-href="/file/{{similarFile.Sha1}}" tooltips
                            	data-track-id="GoToFile"
								data-track-type="Navigation"
                               tooltip-title="Similarity: {{similarFile.Similarity*100}}%">{{ similarFile.Sha1
                              }}</a>
                          </div>
                          <div ng-if="!file.topSimilarFiles || file.topSimilarFiles.length == 0">No similar files
                            observed
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
			  </div>

              <!-- Date picker & time bar-->
			  <div ng-class=":: {'entity-toolbox': file.exportTimelineToCsvEnabled, 'row': !file.exportTimelineToCsvEnabled}" class="wcd-margin-large-top">
				  <timeline-datepicker from-date="file.fromDate" to-date="file.toDate" load-timeline="file.loadTelemetryEvents()" start-date="file.telemetryFromDate" end-date="file.telemetryToDate" mask-results="file.maskResults" in-cold-storage="file.inColdStorage" picker-title="'Most recent observed devices with file from:'" cold-storage-enabled="true"></timeline-datepicker>
				  <timeline-timebar from-date="file.fromDate" to-date="file.toDate"></timeline-timebar>
			  </div>
			  <div ng-if="::file.exportTimelineToCsvEnabled" class="entity-toolbox entity-toolbar">
				  <button ng-click="file.openExportToCsvDialog()" class="entity-toolbox-button entity-toolbar-button pull-right">
					  <i class="icon icon-ExcelLogo export-to-csv-excel-logo"></i> Export
				  </button>
			  </div>

			  <div class="row table-header event-timeline-columnsheader">
                <div class="col-md-5 col-md-offset-1 user-timeline-title-header">Machine</div>
                <div class="col-md-5 col-md-offset-1 user-timeline-title-header user-timeline-title-header-description">
                  Description
                </div>
              </div>
              <!-- File grid -->
              <timeline-grid loading="file.loading" partial-results-loaded="file.partialResultsLoaded" events="file.events"
                       records-per-page="file.telemetryPageSize" mask-results="file.maskResults"
                       in-cold-storage="file.inColdStorage" load-timeline="file.loadTelemetryEvents()"></timeline-grid>
            </div>
						`,
					controller: 'seville.threatintel.file',
					controllerAs: 'file',
				},
			},
		});

		searchProvider.registerEntitySearchProvider(
			'File',
			function(searchTerm) {
				return (
					md5Regex.test(searchTerm) ||
					sha1Regex.test(searchTerm) ||
					sha256Regex.test(searchTerm) ||
					searchTerm.indexOf('.exe') > -1 ||
					searchTerm.indexOf('.bat') > -1 ||
					searchTerm.indexOf('.dll') > -1
				);
			},
			function(searchTerm, $state) {
				if (md5Regex.test(searchTerm) || sha1Regex.test(searchTerm) || sha256Regex.test(searchTerm)) {
					$state.go('file', { id: searchTerm }, { reload: true });

					return;
				}

				// else go to file resutls page
				$state.go('search.files', { id: searchTerm }, { reload: true });
			}
		);
	},
]);

fileController.$inject = [
	'$log',
	'$scope',
	'$filter',
	'$http',
	'$state',
	'$stateParams',
	'$q',
	'$uibModal',
	'appConfig',
	'urlMapping',
	'investigationService',
	'demoTenantResolverService',
	'hoverPaneService',
	'coldStorage',
	'coldStorageConsts',
	'authenticationService',
	'featuresService',
	'progressiveTimelineLoader',
	'exportToCsvService',
	'appInsights',
	'ng2router',
	'officeIntegrationService',
	'authService',
	'machinesService',
	'blackListService',
	'filesBackendService',
	'tagsService',
	'filesService',
	'askTheExpertService',
];

function fileController(
	$log,
	$scope,
	$filter,
	$http,
	$state,
	$stateParams,
	$q,
	$modal,
	appConfig,
	urlMapping,
	investigation,
	demoTenantResolverService,
	hoverPaneService,
	coldStorage,
	coldStorageConsts,
	authenticationService,
	featuresService: FeaturesService,
	progressiveTimelineLoader,
	exportToCsvService,
	appInsights: AppInsightsService,
	ng2router,
	officeIntegrationService: OfficeIntegrationService,
	authService: AuthService,
	machinesService: MachinesService,
	blackListService,
	filesBackendService: FilesBackendService,
	tagsService: TagsService,
	filesService: FilesService,
	askTheExpertService: AskThreatExpertService
) {
	var vm = this;
	if (!$stateParams.id) {
		ng2router.navigate(['/errors']);
		return;
	}

	// handle old links: if url is of old file page but "upgrade file page" flag is on, redirect to new file page
	if (featuresService.isEnabled(Feature.UpgradeFilePage)) {
		goToNewFilePage();
		return;
	} else {
		if (
			featuresService.isDefault(Feature.UpgradeFilePage) &&
			!featuresService.isDefault(Feature.ShowUpgradeFilePageToggle) &&
			!featuresService.getLocalFeatureValue(Feature.UpgradeFilePage)
		) {
			featuresService.clearLocalFeatureValue(Feature.ShowUpgradeFilePageToggle);
			featuresService.clearLocalFeatureValue(Feature.UpgradeFilePage);
			goToNewFilePage();
			return;
		}
	}

	vm.loading = 0;
	vm.partialResultsLoaded = false;
	vm.showLoadingMessage = 0;
	vm.telemetryLookback = '30'; // 30 days by default
	vm.signer = 'All';
	vm.events = [];
	vm.telemetryPageSize = 100;
	vm.telemetryToDate = $stateParams.time ? new Date($stateParams.time) : new Date();
	vm.isDemoTenant = demoTenantResolverService.isDemoTenant();
	vm.alertsInvestigationAllowed = !authenticationService.isUserActionAllowed([
		RbacMdeAllowedActions.alertsInvestigation,
	]);
	vm.activeRemediationAllowed = !authenticationService.isUserActionAllowed([
		RbacMdeAllowedActions.remediationActions,
	]);

	vm.isAdminUser = authService.currentUser.isMdeAdmin;

	var lookback = demoTenantResolverService.isDemoTenant()
		? demoTenantResolverService.demoTenantLookback
		: coldStorageConsts.FILE_TIMELINE_LOOKBACK;
	vm.telemetryFromDate = coldStorage.overrideStartDateIfBeyondHotData(vm.telemetryToDate, lookback);
	vm.events = [];
	vm.endDate = new Date();
	vm.maskResults = false;
	vm.inColdStorage = false;
	vm.minOrgPrevalenceToDisplayWarning = 20;
	vm.lsHashSimilarityEnabled = featuresService.isEnabled('LsHashSimilarity');
	vm.exportTimelineToCsvEnabled = featuresService.isEnabled('ExportEntityTimelinesToCsv');
	vm.huntingEnabled =
		featuresService.isEnabled('Hunting') && appConfig.isExposedToAllMachineGroups && false; // disabled for now;
	vm.rbacMachineGroupsEnabled = featuresService.isEnabled(Feature.RbacMachineGroups);
	vm.fileSensitivityEnabled = featuresService.isEnabled(Feature.FileSensitivity);
	vm.downloadSampleEnabled = featuresService.isEnabled(Feature.DownloadFileSample);
	vm.showDeprecatedMessage = featuresService.isEnabled(Feature.ShowDeprecatedFilePageMessage);
	vm.showConsultThreatExpertMenuItem = askTheExpertService.shouldShowThreatExpertPanel();

	const privatePreviewDisableDate = new Date(Date.UTC(2019, 7, 25, 23, 59, 59)); // 25.8.2019 23:59:59
	const disableDate = new Date() < privatePreviewDisableDate ? 'August 25' : 'September 1';
	vm.deprecatedMessageText = `Please note that this view will be unavailable starting ${disableDate}, 2019.`;

	var isoStringFilter = $filter('isoString');

	vm.showExpertPanel = function() {
		askTheExpertService.showResearcherAssistanceDialog();
	};

	vm.turnOnNewFilePage = function() {
		appInsights.trackEvent('UI', {
			type: TrackingEventType.Action,
			id: 'turnOnNewFilePageFromDeprecatedPageMessage',
			component: 'OldUFilePage',
		});
		featuresService.setLocalFeatureValue(Feature.UpgradeFilePage, true);
	};

	vm.quarantine = function() {
		$modal.open({
			animation: $scope.animationsEnabled,
			template: `
			<div class="modal-header response-confirmation-title">
          <span class="response-confirmation-title-text">
              Stop and Quarantine file?
              <i class="icon icon-Cancel pointer response-confirmation-dismiss-btn" ng-click="close()" tabindex="0"></i>
          </span>
      </div>
      <div class="container">
          <div class="row">
              <div class="col-md-12 response-confirmation-text">
                  This action will attempt to stop running instances of the file, quarantine the <br />
                  file and remove persistence mechanisms.
              </div>
          </div>
          <div class="row">
              <div class="col-md-12 response-confirmation-details response-confirmation-quarantine-width">
                  <table class="response-confirmation-table">
                      <tr>
                          <th class="col-sm-4 response-confirmation-table-header-text response-confirmation-table-header-applies">Applies to</th>
                          <th class="col-sm-8 response-confirmation-table-header-text">File details</th>
                      </tr>
                      <tr class="response-confirmation-details-row">
                          <td class="response-confirmation-impact-section">
                              <div>
                                  <i class="icon response-confirmation-DeviceLaptopNoPic icon-DeviceLaptopNoPic"></i>
                              </div>
                              <div>
                                  {{::file.OrgPrevalenceResponseSupportedMachines | megaNumber}} devices
                              </div>
                          </td>
                          <td>
                              <div class="response-confirmation-details-sha1-section">
                                  <span class="response-confirmation-details-sha1-text">Sha1: </span> {{::file.Sha1}}
                              </div>
                              <div class="response-confirmation-details-prevalence-section">
                                  <table class="response-confirmation-table">
                                      <tr>
                                          <td class="response-confirmation-details-prevalence-cell">
                                              <span class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-worldwide">Prevalance worldwide:</span>{{::file.worldPrevalence | megaNumber}}
                                          </td>
                                          <td class="response-confirmation-details-prevalence-cell">
                                              <span class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-filenames">File names:</span>{{::file.TopFileNamesCount | megaNumber}}
                                              <i class="icon icon-Info response-confirmation-info-icon hero-button" tooltips tooltip-side="bottom" tooltip-html={{file.TopFileNamesHtmlTooltip}}></i>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="response-confirmation-details-prevalence-cell">
                                              <span class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-organizations">Prevalence in organization:</span>{{::file.orgPrevalence | megaNumber}}
                                          </td>
                                          <td class="response-confirmation-details-prevalence-cell">
                                              <span class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-file-instances">File instances:</span>{{::file.fileInstancesCount | megaNumber}}
                                          </td>
                                      </tr>
                                  </table>
                              </div>
                          </td>
                      </tr>
                      <tr ng-if="::file.isHighOrgPrevalenceResponseSupportedMachines" class="response-confirmation-warning-message">
                          <td colspan="2">
                              <i class="icon response-confirmation-warning-icon icon-IncidentTriangle"></i>
                              This file is relatively prevalent in your organization.
                          </td>
                      </tr>
                  </table>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12">
                  <span class="response-confirmation-comments-text">Reason: <span class="response-confirmation-asterisk"> *</span></span>
                  <textarea placeholder="Type your reason for this action" class="form-control response-confirmation-comments-box response-confirmation-comments-box-remediate" maxlength="1000" autofocus ng-model="file.commentText"></textarea>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12 response-confirmation-info">
                  <i class="icon action-center-pre-comment-icon icon-Clock"></i>
                  This action applies only to files seen in the last 30 days on devices with Windows 10<br />Creators Update and newer.
                  <span class="response-confirmation-export-to-csv-open-dialog-button no-outline pointer" ng-click="downloadMachineListCsv()">
                      <i class="icon icon-ExcelLogo export-to-csv-excel-logo"></i> Export full list of devices.
                  </span>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12 pull-right response-confirmation-buttons-quarantine">
                  <button class="btn btn-primary response-confirmation-quarantine-btn" ng-disabled="file.commentText=='' || file.commentText==undefined" ng-click="quarantine()">Yes, stop and quarantine</button>
                  <button class="btn btn-primary normal-button normal-primary-buttons-margin" ng-click="close()">No</button>
              </div>
          </div>
      </div>
			`,
			windowClass: 'response-confirmation-modal-quarantine',
			controller: 'seville.threatintel.file.quarantine',
			backdrop: false,
			resolve: {
				file: function() {
					return vm.main;
				},
			},
		});
	};

	vm.blacklist = function() {
		if (vm.main.BlacklistStatus == 'Unblocked') {
			$modal.open({
				animation: $scope.animationsEnabled,
				template: `
				<div class="modal-header response-confirmation-title">
            <span class="response-confirmation-title-text">
                <span ng-if="file.BlacklistStatus == 'Unblocked'">Block this file?</span>
                <span ng-if="file.BlacklistStatus == 'Blocked'">Remove this file from block list?</span>
                <i class="icon icon-Cancel pointer response-confirmation-dismiss-btn" ng-click="close()" tabindex="0"></i>
            </span>
        </div>
        <div class="container" data-track-component="Block File Popup" data-track-component-type="Dialog">
            <div class="row">
                <div ng-if="file.BlacklistStatus == 'Unblocked'" class="col-md-12 response-confirmation-text response-confirmation-block-width">
                    This action will prevent this file from running on devices in your organization.
                </div>
                <div ng-if="file.BlacklistStatus == 'Blocked'" class="col-md-12 response-confirmation-text response-confirmation-block-width">
                    Removing the file from the blocked list will allow the file to run on devices in your organization.
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 response-confirmation-details response-confirmation-block-width">
                    <table class="response-confirmation-table">
                        <tr>
                            <th class="col-sm-8 response-confirmation-table-header-text">File details</th>
                        </tr>
                        <tr class="response-confirmation-details-row">
                            <td colspan="2">
                                <div class="response-confirmation-details-sha1-section">
                                    <span class="response-confirmation-details-sha1-text">Sha1: </span> {{::file.Sha1}}
                                </div>
                                <div class="response-confirmation-details-prevalence-section">
                                    <table class="response-confirmation-table">
                                        <tr>
                                            <td class="response-confirmation-details-prevalence-cell">
                                                <span class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-worldwide">Prevalance worldwide:</span>{{::file.worldPrevalence | megaNumber}}
                                            </td>
                                            <td class="response-confirmation-details-prevalence-cell">
                                                <span class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-filenames">File names:</span>{{::file.TopFileNamesCount | megaNumber}}
                                                <i class="icon icon-Info response-confirmation-info-icon hero-button" tooltips tooltip-side="bottom" tooltip-html={{file.TopFileNamesHtmlTooltip}}></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="response-confirmation-details-prevalence-cell">
                                                <span class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-organizations">Prevalence in organization:</span>{{::file.orgPrevalence | megaNumber}}
                                            </td>
                                            <td class="response-confirmation-details-prevalence-cell">
                                                <span class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-file-instances">File instances:</span>{{::file.fileInstancesCount | megaNumber}}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr ng-if="::file.isHightOrgPrevalence" class="response-confirmation-warning-message">
                            <td colspan="2">
                                <i class="icon response-confirmation-warning-icon icon-IncidentTriangle"></i>
                                This file is relatively prevalent in your organization.
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span class="response-confirmation-comments-text">Reason: <span class="response-confirmation-asterisk"> *</span></span>
                    <textarea placeholder="Type your reason for this action" class="form-control response-confirmation-comments-box response-confirmation-comments-box-block" maxlength="1000" autofocus ng-model="file.commentText"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 response-confirmation-info">
                    <i class="icon action-center-pre-comment-icon icon-Clock"></i>
                    This action applies only to files seen in the last 30 days on<br />devices with Windows 10 Creators Update and newer.
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 pull-right response-confirmation-buttons-block">
                    <button ng-if="file.BlacklistStatus == 'Unblocked'" ng-disabled="file.commentText=='' || file.commentText==undefined" class="btn btn-primary primary-button response-confirmation-block-btn" ng-click="blacklist()" data-track-id="blockFileConfirm" data-track-type="Button">Yes, block file</button>
                    <button ng-if="file.BlacklistStatus == 'Blocked'" class="btn btn-primary primary-button response-confirmation-block-btn" ng-click="blacklist()">Yes, remove</button>
                    <button class="btn btn-primary normal-button normal-primary-buttons-margin" ng-click="close()">No</button>
                </div>
            </div>
        </div>
				`,
				windowClass: 'response-confirmation-modal-block',
				controller: 'seville.threatintel.file.blacklist',
				backdrop: false,
				resolve: {
					file: function() {
						return vm.main;
					},
				},
			});
		} else {
			$modal.open({
				animation: $scope.animationsEnabled,
				template: CANCEL_CONFIRMATION_TEMPLATE,
				windowClass: 'machine-actions-modal',
				controller: 'seville.actioncenter.response.cancel.confirmation',
				backdrop: false,
				resolve: {
					entity: function() {
						return vm.main;
					},
					action: function() {
						return 'Unblock';
					},
					openActionCenterPanel: function() {
						return true;
					},
				},
			});
		}
	};

	vm.openExportToCsvDialog = function() {
		const sha = vm.main.Sha1 || vm.main.Sha256;
		if (sha) {
			appInsights.trackEvent('UsageTrack', {
				ButtonType: 'ExportToCsv',
				Page: 'File',
				Component: 'FileExportToCsv',
			});
			exportToCsvService.openDialog({
				title: 'Export file organizational footprint',
				maxDays: 30,
				maxDaysInColdStorage: 7,
				timelineDate: vm.telemetryToDate,
				initialStartDate: vm.telemetryFromDate,
				initialEndDate: vm.telemetryToDate,
				httpRequestParams: { fileId: sha },
				checkResultsUrl: '/LookForFileEventsToExport',
				getAuthTokenUrl: '/GetAuthToken?exportUrl=DownloadFileTimelineToCsv',
				downloadUrl: '/DownloadFileTimelineToCsv',
			});
		}
	};

	showActionCenter();
	loadVirusTotalFileReport();

	$scope.$watch(
		function() {
			return vm.telemetryLookback;
		},
		function() {
			loadFileStats();
		}
	);

	vm.featuresChangeSubscription = featuresService.featureChanged$
		.pipe(
			filter(({ featureId }) => featureId === Feature.UpgradeFilePage),
			filter(_ => featuresService.isEnabled(Feature.UpgradeFilePage))
		)
		.subscribe(_ => goToNewFilePage());

	$scope.$on('$destroy', () => {
		vm.featuresChangeSubscription && vm.featuresChangeSubscription.unsubscribe();
	});

	function loadEntity() {
		return $q(function(resolve, reject) {
			// resolve searchType by regex
			var searchByParam = 'Sha1';
			if (RegExpService.md5.test($stateParams.id)) {
				searchByParam = 'Md5';
			} else if (RegExpService.sha1.test($stateParams.id)) {
				searchByParam = 'Sha1';
			} else if (RegExpService.sha256.test($stateParams.id)) {
				searchByParam = 'Sha256';
			} else {
				ng2router.navigate(['/errors']);
				return reject('entity loading failed');
			}

			if ($state.current.data && $state.current.data.entity) {
				$log.debug('Entity is already loaded in memory');
				var data = $state.current.data.entity;
				updateInvestigationFlow(data);
				vm.main = data;
				delete $state.current.data.entity;
				vm.loadTelemetryEvents();
				return resolve('ok');
			} else {
				$log.debug('loading file with id ' + $stateParams.id);
				var url = urlMapping.getThreatIntelUrl() + '/files';
				$http
					.get(url, {
						params: {
							searchTerm: $stateParams.id,
							searchBy: searchByParam,
							lookingBackIndays: 180,
						},
					})
					.then(
						function(response) {
							if (response.status == 404) {
								ng2router.navigate(['/errors/FileNotFound'], {
									queryParams: { itemId: $stateParams.id },
								});
								return reject('entity loading failed');
							} else if (response.status == 200) {
								$log.debug('entity loaded successfully');
								vm.main = response.data;
								handleResonseFlow();
								updateInvestigationFlow(response.data);
								vm.loadTelemetryEvents();
								return resolve('ok');
							} else if (response.status != -1) {
								ng2router.navigate(['/errors']);

								return reject('entity loading failed');
							}
						},
						function(response) {
							delete vm.main;
							$log.debug('entity loading failed');

							return reject('entity loading failed');
						}
					);
			}
		});
	}

	function loadAssociatedEntities() {
		if (!vm.main) {
			return;
		}

		loadFileStats();
		loadFileSimilarity();
		loadFileSensitivityLabel();
		checkIfDownloadIsAvailable();

		officeIntegrationService.officeIntegrationSettings$
			.pipe(filter(value => !!value))
			.subscribe(setOfficeIntegrationSettings);
	}

	loadEntity().then(loadAssociatedEntities);

	function setOfficeIntegrationSettings(officeIntegrationSettings: OfficeIntegrationSettings) {
		if (!officeIntegrationSettings) return;

		officeIntegrationService.getOfficeTenantUrlPrefix().subscribe((officeTenantPrefix: string) => {
			vm.officeTenantPrefix = officeTenantPrefix;
			vm.o365IntegrationEnabled = officeIntegrationSettings.isE2EIntegrationEnabled || vm.isDemoTenant;
			loadMailStats();
		});
	}

	function loadMailStats() {
		if (!vm.main || !vm.o365IntegrationEnabled || vm.mailPrevalenceLoaded) return;

		vm.mailPrevalenceLoaded = false;
		$log.debug('loading associated o365 mail stats for file with sha256=' + vm.main.Sha256);
		$http
			.get(urlMapping.getThreatIntelUrl() + '/MailStats', {
				params: {
					sha1: vm.main.Sha1,
					sha256: vm.main.Sha256,
					lookingBackIndays: vm.telemetryLookback,
					officeTenantPrefix: vm.officeTenantPrefix,
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						$log.debug('associated o365 mail stats loaded successfully');
						vm.mailStatsLoaded = true;
						if (response.data) {
							vm.mailPrevalence = response.data.NumberOfInboxes;
							vm.mailFirstSeen = response.data.FirstSeen;
							vm.mailLastSeen = response.data.LastSeen;
							vm.mailSender = response.data.Sender;
							vm.numberOfSenders = response.data.NumberOfSenders;
							vm.deepLinkToO365Portal = response.data.DeepLinkToO365Portal;
						}
					} else {
						$log.debug('No o365 mail stats found for Sha256=' + vm.main.Sha256);
						vm.mailPrevalence = -1;
					}
					vm.mailPrevalenceLoaded = true;
				},
				function(response) {
					$log.error('Error occur while loading o365 mail stats');
				}
			);
	}

	function loadVirusTotalFileReport() {
		$log.debug('loading virus total file report information, for file with id=' + $stateParams.id);

		$http
			.get(urlMapping.getThreatIntelUrl() + '/VirusTotalFileReport', {
				params: {
					fileIdentifier: $stateParams.id,
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						$log.debug(
							'virus total file report information for file with id=' +
								$stateParams.id +
								' loaded successfully'
						);
						var data = response.data;
						if (data) {
							vm.totalScanners = data.total;
							vm.positiveScanners = data.positives;
							vm.permalink = data.permalink;
							vm.vtDataExists = data.total && data.permalink;
						}
					}
				},
				function(response) {
					$log.error('Error occur while loading virus total file report information');
				}
			);
	}

	function loadFileSimilarity() {
		if (!vm.main) return;

		if (!vm.lsHashSimilarityEnabled) {
			$log.debug('LsHashSimilarity feature is not enabled for the current organization');
			return;
		}

		$log.debug('loading file similarity, for file with id=' + vm.main.Sha1);

		$http
			.get(appConfig.serviceUrls.userRequests + '/similarity/file', {
				params: {
					sha1: vm.main.Sha1,
					minSimilarity: 0.95,
					maxResults: 5,
				},
			})
			.then(function(response) {
				if (response.status == 200) {
					vm.topSimilarFiles = response.data.Files;
				} else {
					$log.debug('Error occur while loading file similarity');
				}
			});
	}

	function loadFileStats() {
		if (!vm.main) return;
		$log.debug('loading first & last seen, for file with id=' + vm.main.Sha1);
		vm.prevalenceLoaded = false;
		vm.orgFirstseen = null;
		vm.orgLastseen = null;
		vm.orgPrevalence = null;

		$http
			.get(urlMapping.getThreatIntelUrl() + '/FileStats', {
				params: {
					sha1: vm.main.Sha1,
					sha256: vm.main.Sha256,
					lookingBackIndays: getFileStatsLookback(),
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						$log.debug(
							'loading file stats for file with id=' + vm.main.Sha1 + ' loaded successfully'
						);
						var data = response.data;
						if (data) {
							vm.orgFirstseen = data.OrgFirstSeen;
							vm.orgLastseen = data.OrgLastSeen;
							vm.orgPrevalence = data.OrgPrevalence ? parseInt(data.OrgPrevalence) : 0;
							vm.TopFileNames = data.TopFileNames;
							vm.main.TopFileNamesCount = vm.TopFileNames.length;

							createTopFileNamesHtmlForTooltip();

							vm.worldPrevalence = vm.main.TelemetryPrevalence
								? parseInt(vm.main.TelemetryPrevalence)
								: 0;
							vm.main.isHightOrgPrevalence =
								vm.orgPrevalence > vm.minOrgPrevalenceToDisplayWarning;
							vm.main.orgPrevalence = vm.orgPrevalence;

							if (vm.worldPrevalence < vm.orgPrevalence) {
								vm.worldPrevalence = vm.orgPrevalence;
							}

							vm.main.worldPrevalence = vm.worldPrevalence;

							if (
								(!vm.main.FirstObserved && vm.orgFirstseen) ||
								vm.main.FirstObserved > vm.orgFirstseen
							) {
								vm.main.FirstObserved = vm.orgFirstseen;
							}

							if (
								(!vm.main.LastObserved && vm.orgLastseen) ||
								vm.main.LastObserved < vm.orgLastseen
							) {
								vm.main.LastObserved = vm.orgLastseen;
							}

							vm.prevalenceLoaded = true;
						}
					}
				},
				function(response) {
					$log.error('Error occur while loading first & last seen information');
				}
			);
	}

	function loadFileSensitivityLabel() {
		if (!vm.fileSensitivityEnabled) return;

		const fileId: FileId = vm.main.Sha1 || vm.main.Sha256;
		filesBackendService
			.getFileProtectionInfo(fileId)
			.then((protectionInfo: FileProtectionInfo) => {
				vm.sensitivityTag =
					protectionInfo && protectionInfo.sensitivity
						? tagsService.getDataSensitivityTag(protectionInfo.sensitivity, null, false)
						: undefined;
				vm.isAzureInfoProtectionApplied = protectionInfo.isAzureInfoProtectionApplied;
			})
			.catch((error: any) =>
				appInsights.trackException(
					new Error(
						`Error occurred while fetching file sensitivity label for file '${fileId}'\n${error}`
					)
				)
			);
	}

	function checkIfDownloadIsAvailable() {
		vm.canDownload = null;

		filesBackendService
			.canDownloadFile(vm.main.Sha1)
			.subscribe((value: boolean) => (vm.canDownload = value));
	}

	function createTopFileNamesHtmlForTooltip() {
		var fileNamesToDisplay = 4;
		vm.main.TopFileNamesHtmlTooltip = '';

		var minIndex = Math.min(fileNamesToDisplay, vm.TopFileNames.length);

		for (var i = 0; i < minIndex; i++) {
			vm.main.TopFileNamesHtmlTooltip += vm.TopFileNames[i] + '<br>';
		}

		if (minIndex < vm.TopFileNames.length) {
			vm.main.TopFileNamesHtmlTooltip +=
				'and ' + (vm.TopFileNames.length - fileNamesToDisplay) + ' more';
		}
	}

	vm.loadTelemetryEvents = function() {
		if (!vm.main || !vm.telemetryToDate) {
			return;
		}

		progressiveTimelineLoader.loadTimelineProgressively(
			loadTimelineEvents,
			vm.telemetryFromDate,
			vm.telemetryToDate,
			vm.telemetryPageSize,
			function(newEvents, isPartialResultsLoaded) {
				vm.events = newEvents;
				vm.partialResultsLoaded = isPartialResultsLoaded;
			},
			function(record) {
				return record.machineId || record.MachineId;
			}
		);
	};

	function loadTimelineEvents(fromDate, toDate) {
		return $q(function(resolve, reject) {
			if (!vm.main || !vm.telemetryToDate) {
				return reject();
			}

			vm.loading++;

			$log.debug('loading file timeline for file ' + vm.main.Sha1);
			$http
				.get(urlMapping.getThreatIntelUrl() + '/FileTimeline', {
					params: {
						Sha1: vm.main.Sha1,
						Sha256: vm.main.Sha256,
						fromDate: fromDate,
						toDate: toDate,
						pageSize: vm.telemetryPageSize,
						pageIndex: 1,
					},
				})
				.then(
					function(response) {
						if (response.status == 200) {
							$log.debug('file timeline events loaded successfully');
							var data = response.data;
							if (!data || !data.Items) {
								$log.error('Error occur while loading events timeline');
								vm.loading--;

								return;
							}

							var newEvents = [];
							for (var i = 0; i < data.Items.length; i++) {
								var record = data.Items[i];
								var url = record.Url;
								var domain;
								var files = {
									TotalResults: 1,
									Items: [],
								};

								//find & remove protocol (http, ftp, etc.) and get domain
								if (url.indexOf('://') > -1) {
									domain = url.split('/')[2];
								} else {
									domain = url.split('/')[0];
								}

								files.Items.push({
									Path: record.RealPath,
									Name: record.FileName,
									Valid: true,
								});

								var item = {
									time: record.Timestamp,
									sort: $filter('date')(record.Timestamp, 'yyyy-MM-dd-HH-mm-sss', 'UTC'),
									wcdMachineId: record.WcdMachineId,
									machineId: record.MachineId,
									filename: record.FileName,
									ipsCount: record.IpsCount,
									ips: record.TopIps,
									url: record.Url,
									urldomain: domain,
									signer: record.Signer,
									isValidCertificate: record.IsValidCertificate,
									computerDnsName: record.ComputerDnsName,
									desc: record.Description,
									type: 'Machine',
									files: files,
									json: record,
									isoTime: isoStringFilter(record.Timestamp),
									containerId: record.ContainerId,
								};

								newEvents.push(item);
							}

							resolve(newEvents);
						}

						vm.loading--;
					},
					function(response) {
						vm.loading--;
						delete vm.main;
						$log.error('Error occur while loading file timeline');
						return reject(response);
					}
				);
		});
	}

	function getFileStatsLookback() {
		if (vm.isDemoTenant) return demoTenantResolverService.demoTenantLookback;

		return vm.telemetryLookback;
	}

	function updateInvestigationFlow(data) {
		if (!data || data.length <= 1) {
			data = {};
		}

		data.icon = 'icon-Page';
		data.displaystate = 'File';
		data.state = 'file';
		investigation.setActive(data);
		breadcrumbsStateService.add({
			id: `file_${data.Id}`,
			label: 'File',
			url: $state.href($state.current),
			queryParams: $state.params,
		});
	}

	function handleResonseFlow() {
		vm.ResponseFeatureEnabled = false;

		// Check if responseAction feature is enabled
		// Currently we assume that if Response-Remediation feature is not flighted, Response-Blacklist is not flighted as well
		if (!featuresService.isEnabled('Response-Remediation')) {
			$log.debug('Response feature is not enabled since Response-Remediation feature is not enabled');
			return;
		}

		$log.debug('Response feature is enabled');

		// Check file permissions
		$log.debug('Check file permissions for response actions');
		$http
			.get(appConfig.serviceUrls.userRequests + '/remediation/permission', {
				params: {
					FileIdentifierType: vm.main.Sha1 ? 'Sha1' : 'Sha256',
					FileIdentifier: vm.main.Sha1 || vm.main.Sha256,
				},
			})
			.then(function(response) {
				if (response.status === 200) {
					checkFileResponseActionPermission(response.data);
				} else {
					$log.debug('Error occur while checking file response action permissions');
				}
			});
	}

	function checkFileResponseActionPermission(data) {
		//Check if permission disallowed
		if (data && getResponsePermissionsResult(data.Result) === 'Disallowed') {
			var responsePermissionsReason = getResponsePermissionsReason(data.Reason);
			$log.debug('File has no permissions for response actions. Reason' + responsePermissionsReason);

			const disabledStatus = 'Disabled';
			switch (responsePermissionsReason) {
				case 'FileCertificateMicrosoftRoot':
				case 'FileCertificateNonMsTrustedSigner':
					// Check whether file is signed by trusted signer
					var trustedSignerDescription = getTrustedSignerDescription(responsePermissionsReason);
					vm.disabledResponseActionReasonDescription =
						'This file is signed by ' +
						trustedSignerDescription +
						'.<br>Actions have been disabled to prevent negative impact on the devices.';
					vm.main.QuarantineStatus = disabledStatus;
					vm.main.BlacklistStatus = disabledStatus;
					break;
				case 'NotSupported':
					vm.disabledResponseActionReasonDescription = 'Action unavailable for this file.';
					vm.main.QuarantineStatus = disabledStatus;
					vm.main.BlacklistStatus = disabledStatus;
					break;
				default:
					// If we reached this code, so this is some kind of bug - enable feature
					// Disallowed, however with unsuported reason, so enable the response feature
					$log.debug(
						'Disallowed, however the reason unknown, ResponsePermissionsReason: ' +
							responsePermissionsReason
					);
					enableResponseFeature();
			}
		} else {
			// Enable response features
			$log.debug('File has permissions for response actions');
			enableResponseFeature();
		}

		// Response feature can consider enabled only after checking the file permissions
		vm.ResponseFeatureEnabled = true;
	}

	function enableResponseFeature() {
		// Check if any response action was ever preformed on the file
		validateResponseActionExists();

		if (vm.activeRemediationAllowed) {
			$log.debug('Response actions are not enabled since user is readonly user');

			vm.main.BlacklistStatus = 'Readonly';
			vm.main.QuarantineStatus = 'Readonly';

			return;
		}

		if (featuresService.isEnabled('Response-Blacklist')) {
			if (vm.main.IsPeFile) {
				// Check if blacklist feature is on or off
				$http
					.get(urlMapping.getThreatIntelUrl() + '/BlacklistFeatureStatus', {
						params: {},
					})
					.then(function(response) {
						if (response.status == 200) {
							if (response.data) {
								$log.debug(
									'Tenant is flighting blacklist feature and blacklist feature setting is on'
								);

								// Check file blacklist status - is file blacklisted or not
								$log.debug('Check file blacklist status');
								vm.main.BlacklistStatus = 'Unblocked';
								$http
									.post(appConfig.serviceUrls.userRequests + '/bannedfiles/rbac/latest', {
										FileIdentifierType: 'Sha1',
										FileIdentifier: vm.main.Sha1,
									})
									.then(function(response) {
										if (response.status == 200) {
											machinesService
												.getFullUserExposedMachineGroups()
												.subscribe((machineGroups: Array<MachineGroup>) => {
													vm.main.machineGroupsIds =
														vm.isAdminUser ||
														!vm.rbacMachineGroupsEnabled ||
														machineGroups.length === 0
															? [0]
															: machineGroups.map(group => group.id);
												});

											if (response.data && response.data.length > 0) {
												const blackList = response.data;

												vm.main.BlacklistStatus = blackListService.resolveBannedFilesStatus(
													blackList,
													vm.isAdminUser,
													vm.rbacMachineGroupsEnabled
												);
											}
										}
									});
							} else {
								$log.debug(
									'Tenant is flighting blacklist feature and blacklist feature setting is off'
								);
								vm.main.BlacklistStatus = 'NotAvailable';
							}
						}
					});
			} else {
				// end of IsPeFile
				$log.debug('Black list actions is not enabled for Non PE file');
				vm.main.BlacklistStatus = 'NonPeFile';
			}
		} else {
			$log.debug('Tenant is not flighting blacklist feature');
		}

		vm.main.QuarantineStatus = 'Loading';
		// Check if quarantine feature is enabled - the feature is enabled only if file was observed on atleast one RS2 machine
		var url = urlMapping.getThreatIntelUrl() + '/FileObservedMachines';
		$http
			.get(url, {
				params: {
					sha1: vm.main.Sha1,
					resultsLimit: 1000,
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						if (response.data && response.data.length > 0) {
							vm.main.OrgPrevalenceResponseSupportedMachines = response.data.length;
							vm.main.isHighOrgPrevalenceResponseSupportedMachines =
								response.data.length > vm.minOrgPrevalenceToDisplayWarning;
							vm.main.fileInstancesCount = 0;

							for (var i = 0; i < response.data.length; i++) {
								vm.main.fileInstancesCount += response.data[i].Files.length;
							}
							vm.main.QuarantineStatus = 'Available';

							$log.debug(
								'Quarantine action is enabled on the device since file was observed on a RS2 device'
							);
						} else {
							$log.debug(
								'Quarantine action is disabled on the device since file was not observed on a RS2 device'
							);
							vm.main.QuarantineStatus = 'NotAvailable';
						}
					} else {
						vm.main.QuarantineStatus = 'NotAvailable';
					}
				},
				function(response) {
					vm.main.QuarantineStatus = 'NotAvailable';
				}
			);
	}

	function validateResponseActionExists() {
		// Check if any reponse action was ever preformed on the file
		$http
			.get(appConfig.serviceUrls.userRequests + '/requests/file/any', {
				params: {
					identifierType: 'Sha1',
					fileIdentifier: vm.main.Sha1,
				},
			})
			.then(function(response) {
				if (response.status == 200) {
					vm.main.ResponseActionExists = response.data;
				}
			});
	}

	function showActionCenter() {
		if (vm.showActionCenter) {
			delete vm.showActionCenter;
		}

		$log.debug('checking if action center is enabled');

		// Currently we assume that if Response-Remediation feature is not flighted, Response-Blacklist is not flighted as well
		vm.showActionCenter = featuresService.isEnabled('Response-Remediation');
		$log.debug('action center feature enabling status is ' + vm.showActionCenter);
	}

	function getResponsePermissionsReason(input) {
		switch (input) {
			case 0:
				return 'NoReason';
			case 0x1:
				return 'FileHighPrevalence';
			case 0x2:
				return 'FileCertificateMicrosoftRoot';
			case 0x4:
				return 'FileCertificateNonMsTrustedSigner';
			case 0x8:
				return 'NonPeFile';
			case 0x10:
				return 'NotSupported';
			default:
				return input;
		}
	}

	function getResponsePermissionsResult(input) {
		switch (input) {
			case -1:
				return 'Disallowed';
			case 0:
				return 'Allowed';
			case 1:
				return 'AllowedWithWarning';
			default:
				return input;
		}
	}

	function getTrustedSignerDescription(input) {
		switch (input) {
			case 'FileCertificateMicrosoftRoot':
				return 'a Microsoft trusted publisher';
			case 'FileCertificateNonMsTrustedSigner':
				return 'a trusted publisher';
			default:
				return input;
		}
	}

	function goToNewFilePage() {
		const alertTime = $state.params.time && new Date($state.params.time);
		ng2router.navigate([
			filesService.getFileLink(
				(vm.main && (vm.main.Sha1 || vm.main.Sha256)) || $state.params.id,
				alertTime
			),
		]);
	}

	vm.openHover = function() {
		hoverPaneService.open({
			template: FILE_TEMPLATE,
			controller: 'seville.actioncenter.file',
			controllerAs: 'actioncenterfile',
			title: 'Action center',
			icon: 'F12DevTools',
			size: 'xl',
			resolve: {
				entity: function() {
					return vm.main;
				},
			},
		});
	};

	vm.downloadFile = function() {
		if (vm.canDownload) {
			filesBackendService.downloadFile(vm.main.Sha1);
		}
	};

	vm.getDownloadTooltip = function() {
		if (vm.canDownload != null) {
			return vm.canDownload
				? ''
				: vm.main.IsPeFile && vm.orgPrevalence > 0
				? 'File unavailable for download. Submit it for deep analysis to initiate file collection.'
				: 'File unavailable for download.';
		}

		return null;
	};
}
