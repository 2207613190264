export enum OutbreakMitigationType {
	'AvSigVersion' = 1,
	'AvCloudProtection' = 2,
	'Kb' = 3,
	'Cve' = 4,
	'OsVersion' = 5,
	'PoncherelloFirmware' = 6,
	'AvPUA' = 7,
	'AvRTP' = 8,
	'Protected' = 32767,
}
