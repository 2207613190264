var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { PrettyNumberService } from '@wcd/prettify';
import { find, merge, values } from 'lodash-es';
import { map } from 'rxjs/operators';
import { Alert, Severity } from '@wcd/domain';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService } from '@wcd/config';
import { EntityPanelsService } from '../../../../../global_entities/services/entity-panels.service';
import { AppInsightsService } from '../../../../../insights/services/app-insights.service';
import { config } from '@wcd/shared';
import { ServiceUrlsService } from '@wcd/app-config';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { TzDateComponent } from '../../../../../shared/components/tz-date.component';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { DataTableField } from '@wcd/datatable';
import { AlertsService } from '../../../../../@entities/alerts/services/alerts.service';
import { I18nService } from '@wcd/i18n';
import { AlertEntityTypeService } from '../../../../../@entities/alerts/services/alert.entity-type.service';
var MAX_ALERTS_WITH_AUTOMATION = 5;
var MAX_ALERTS_WITHOUT_AUTOMATION = 9;
var ActiveAlertsWidget = /** @class */ (function (_super) {
    __extends(ActiveAlertsWidget, _super);
    function ActiveAlertsWidget(reportsService, featuresService, router, paris, entityPanelsService, appInsightsService, appConfigService, serviceUrlsService, prettyNumberService, globalEntityTypesService, alertsService, alertEntityTypeService, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.featuresService = featuresService;
        _this.router = router;
        _this.paris = paris;
        _this.entityPanelsService = entityPanelsService;
        _this.appInsightsService = appInsightsService;
        _this.appConfigService = appConfigService;
        _this.serviceUrlsService = serviceUrlsService;
        _this.prettyNumberService = prettyNumberService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.alertsService = alertsService;
        _this.alertEntityTypeService = alertEntityTypeService;
        _this.i18nService = i18nService;
        _this.newAlertsData$ = _this.data$.pipe(map(function (data) {
            return (data &&
                data.newAlertsData.filter(function (item) { return item.severity.type !== 'informational'; }));
        }));
        _this.newAlertsTitle$ = _this.newAlertsData$.pipe(map(function (data) {
            return _this.prettyNumberService.prettyNumber(data
                ? data.map(function (item) { return item.value; }).reduce(function (a, b) { return a + b; }, 0)
                : 0);
        }));
        _this.inProgressAlertsData$ = _this.data$.pipe(map(function (data) {
            return (data &&
                data.inProgressAlertsData.filter(function (item) { return item.severity.type !== 'informational'; }));
        }));
        _this.inProgressAlertsTitle$ = _this.inProgressAlertsData$.pipe(map(function (data) {
            return _this.prettyNumberService.prettyNumber(data
                ? data.map(function (item) { return item.value; }).reduce(function (a, b) { return a + b; }, 0)
                : 0);
        }));
        _this.legendData$ = _this.data$.pipe(map(function (data) {
            return data && data.legendData;
        }));
        _this.latestAlertData$ = _this.data$.pipe(map(function (data) {
            return data && data.latestAlertData;
        }));
        _this.tableColumns = DataTableField.fromList([
            {
                id: 'title',
                name: _this.i18nService.get('activeAlertsWidget_Title'),
                getDisplay: function (item) { return item.Title; },
                icon: {
                    fabricIcon: _this.globalEntityTypesService.getEntityTypeIcon(Alert),
                },
                getLink: function (item) { return "" + _this.alertEntityTypeService.entityType.getEntitiesLink([new Alert({ id: item.id || item.AlertId })]); },
                fluidWidth: 1,
            },
            {
                id: 'severity',
                name: _this.i18nService.get('activeAlertsWidget_Severity'),
                getDisplay: function (item) { return _this.i18nService.get(item.severity.nameI18nKey); },
                getCssClass: function (item) {
                    return "wcd-severity wcd-severity-" + item.severity.type;
                },
                className: 'nowrap wcd-width-xxs-medium',
            },
            {
                id: 'date',
                name: _this.i18nService.get('activeAlertsWidget_Date'),
                component: {
                    type: TzDateComponent,
                    getProps: function (data) { return ({ date: data.LastSeen }); },
                },
                className: 'nowrap wcd-width-xxs-medium text-right',
            },
        ]);
        _this.alertsPieSettings = {
            options: {
                data: {
                    colors: {
                        High: config.color.highSeverity,
                        Medium: config.color.mediumSeverity,
                        Low: config.color.lowSeverity,
                        Informational: config.color.neutralLight,
                    },
                    order: null,
                },
                legend: { show: false },
                tooltip: {
                    format: {
                        value: function (value, ratio) { return value; },
                    },
                },
                size: {
                    width: 250,
                    height: 200,
                },
            },
        };
        _this.onNewAlertsTitleClick = function () {
            _this.goToAlerts({ status: 'New' });
        };
        _this.newAlertsPieSettings = merge({
            options: {
                donut: { title: _this.i18nService.get('activeAlertsWidget_New') },
                data: {
                    onclick: function (severity) {
                        _this.goToAlerts({
                            status: 'New',
                            severity: severity.id,
                        });
                    },
                },
            },
            tracking: {
                id: _this.widgetConfig.id + "_NewAlerts",
                type: 'Navigation',
            },
        }, _this.alertsPieSettings);
        _this.onInProgressAlertsTitleClick = function () {
            _this.goToAlerts({ status: 'InProgress' });
        };
        _this.inProgressAlertsPieSettings = merge({
            options: {
                donut: { title: _this.i18nService.get('activeAlertsWidget_InProgress') },
                data: {
                    onclick: function (severity) {
                        _this.goToAlerts({
                            status: 'InProgress',
                            severity: severity.id,
                        });
                    },
                },
            },
            tracking: {
                id: _this.widgetConfig.id + "_NewAlerts",
                type: 'Navigation',
            },
        }, _this.alertsPieSettings);
        _this.maxAlertsInTable = MAX_ALERTS_WITH_AUTOMATION;
        return _this;
    }
    Object.defineProperty(ActiveAlertsWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            var rangeInDays = this.appConfigService.widgetLookback;
            return {
                id: 'activeAlerts',
                name: this.i18nService.get('widget_title_activeAlerts'),
                rangeInDays: rangeInDays,
                api: {
                    url: function () { return _this.serviceUrlsService.threatIntel + "/Dashboard/GetActiveAlertsDashboard"; },
                    isExternal: true,
                    params: {
                        latestAlertCount: this.maxAlertsInTable,
                        lookbackInDays: rangeInDays,
                        readFromCache: true,
                    },
                },
                parseData: this.parseData.bind(this),
            };
        },
        enumerable: true,
        configurable: true
    });
    ActiveAlertsWidget.prototype.goToAlerts = function (filters) {
        if (filters.severity)
            filters.severity = this.paris.getValue(Severity, function (severity) { return severity.type === filters.severity.toLowerCase(); }).name;
        var filterValues = filters
            ? Object.keys(filters)
                .map(function (field) { return field + "=" + filters[field]; })
                .join(',')
            : null;
        this.router.navigate([this.alertEntityTypeService.entityType.getEntityDataviewLink()], {
            queryParams: {
                filters: filterValues,
                range: this.widgetConfig.rangeInDays,
            },
        });
    };
    ActiveAlertsWidget.prototype.showAlertSidePanel = function (rawAlert) {
        var _this = this;
        this.paris
            .getRepository(Alert)
            .createItem(rawAlert)
            .subscribe(function (alert) {
            _this.entityPanelsService.showEntities(Alert, [alert]);
        });
    };
    ActiveAlertsWidget.prototype.parseData = function (data) {
        var _this = this;
        var newAlerts = find(data.AlertSummary, function (item) { return item.StatusName === 'New'; });
        var inProgressAlerts = find(data.AlertSummary, function (item) { return item.StatusName === 'InProgress'; });
        var alertTypes = {
            newAlertsData: newAlerts &&
                newAlerts.ActiveAlerts.map(function (item) {
                    return {
                        name: _this.i18nService.get("incident.severity.values." + item.SeverityName),
                        value: item.AlertCount,
                        severity: _this.paris.getValue(Severity, item.Severity),
                    };
                }).sort(function (a, b) {
                    return a.severity.priority - b.severity.priority;
                }),
            inProgressAlertsData: inProgressAlerts &&
                inProgressAlerts.ActiveAlerts.map(function (item) { return ({
                    name: _this.i18nService.get("incident.severity.values." + item.SeverityName),
                    value: item.AlertCount,
                    severity: _this.paris.getValue(Severity, item.Severity),
                }); }).sort(function (a, b) { return a.severity.id - b.severity.id; }),
        };
        return __assign({}, alertTypes, { latestAlertData: (data.LatestAlerts || []).slice(0, this.maxAlertsInTable).map(function (alertData) { return (__assign({}, alertData, { severity: _this.paris.getValue(Severity, alertData.Severity) })); }), legendData: ((alertTypes.newAlertsData || alertTypes.inProgressAlertsData) &&
                this.parseLegendData([alertTypes.newAlertsData, alertTypes.inProgressAlertsData])) ||
                [] });
    };
    ActiveAlertsWidget.prototype.parseLegendData = function (data) {
        var _this = this;
        return values(data.reduce(function (severityCountByType, alertSeriesItems) {
            alertSeriesItems.forEach(function (alertSeriesItem) {
                // get existing item. this can happen because there are types that have multiple ids
                var legendItem = severityCountByType[alertSeriesItem.severity.type];
                var count = ((legendItem && legendItem.value) || 0) + Number(alertSeriesItem.value);
                severityCountByType[alertSeriesItem.severity.type] = {
                    name: _this.i18nService.get(alertSeriesItem.severity.nameI18nKey),
                    value: count,
                    iconClassName: "color-text-" + alertSeriesItem.severity.className,
                };
            });
            return severityCountByType;
        }, {}));
    };
    return ActiveAlertsWidget;
}(ReportWidgetComponent));
export { ActiveAlertsWidget };
