import { ComponentRef, Injectable } from '@angular/core';
import { SystemExclusionsBackendService } from './system-exclusions.backend.service';
import { Observable } from 'rxjs';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { SystemExclusionEditModalComponent } from '../components/system-exclusion-edit.modal.component';
import { PanelType } from '@wcd/panels';
import { AuthService } from '@wcd/auth';
import {
	MemoryContents,
	SystemExclusion,
	SystemExclusionListType,
	SystemExclusionType,
	MdeUserRoleActionEnum,
} from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { DataviewRepositoryService } from '../../../dataviews/services/dataview-repository.service';
import { tap } from 'rxjs/operators';
import { compact, isNil } from 'lodash-es';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class SystemExclusionsService {
	constructor(
		protected backendService: SystemExclusionsBackendService,
		private authService: AuthService,
		private dialogsService: DialogsService,
		private dataviewRepositoryService: DataviewRepositoryService,
		private paris: Paris,
		private i18nService: I18nService
	) {}

	getSystemExclusionsActions(systemExclusionType: SystemExclusionType): Array<ItemActionModel> {
		return compact([
			this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings)
				? this.dataviewRepositoryService.getRemoveItemAction(
						this.paris.getRepository(SystemExclusion),
						{ type: systemExclusionType.id },
						true
				  )
				: null,
		]).map(itemActionConfig => new ItemActionModel(itemActionConfig));
	}

	getSystemExclusionTypeCounts(): Observable<{ [index: string]: number }> {
		return this.backendService.getSystemExclusionTypeCounts();
	}

	showEditSystemExclusionDialog(
		systemExclusionType: SystemExclusionType,
		systemExclusion?: SystemExclusion,
		formIsDirtyOnInit: boolean = false
	): Promise<SystemExclusion> {
		return new Promise<SystemExclusion>((resolve, reject) => {
			const editedSystemExclusion: SystemExclusion = systemExclusion
				? new SystemExclusion(systemExclusion)
				: this.paris.getRepository<SystemExclusion>(SystemExclusion).createNewItem();
			let modal: ComponentRef<any>;
			const isNewSystemExclusion: boolean = isNil(editedSystemExclusion.id);

			if (isNewSystemExclusion)
				editedSystemExclusion.listType = this.paris.getValue(SystemExclusionListType, listType =>
					/Allow/i.test(listType.name)
				);

			this.dialogsService
				.showPanel(
					SystemExclusionEditModalComponent,
					{
						id: 'edit-system-exclusion-modal',
						type: PanelType.medium,
						headerText: this.i18nService.get(
							isNewSystemExclusion
								? 'systemExclusions_createRule'
								: 'systemExclusions_editRule',
							{ exclusionName: systemExclusionType.name.toLowerCase() }
						),
					},
					{
						onDone: (systemExclusion: SystemExclusion) => {
							resolve(systemExclusion);
							modal.destroy();
						},
						onCancel: () => {
							reject();
							modal.destroy();
						},
						systemExclusion: editedSystemExclusion,
						systemExclusionType: systemExclusionType,
						systemExclusionListTypes: this.paris.getRepository(SystemExclusionListType).entity
							.values,
						editableProperties: systemExclusionType.properties.filter(
							property => property.editable
						),
						formIsDirtyOnInit: formIsDirtyOnInit,
					}
				)
				.subscribe(_modal => (modal = _modal));
		});
	}

	createMemoryContentsAcl(
		memoryContents: MemoryContents,
		systemExclusionListType: SystemExclusionListType
	): Observable<Array<SystemExclusion>> {
		return this.backendService
			.createMemoryContentsAcl(memoryContents.id, systemExclusionListType.id)
			.pipe(
				tap(null, error => {
					this.dialogsService.showError({
						title: this.i18nService.get('systemExclusions_error_FailedAddMemoryContents', {systemExclusionListType: systemExclusionListType.name}),
						data: error,
					});
				})
			);
	}

	removeMemoryContentsAcl(memoryContents: MemoryContents): Observable<any> {
		return this.backendService.removeMemoryContentsAcl(memoryContents.id);
	}
}
