import { Injectable } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from '@angular/router';

import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { LogicalOperator } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';
import { I18nService } from '@wcd/i18n';

export interface FeaturesData {
	enableFeatures: Feature[];
	/**
	 * Set this value to and/or between the 'enableFeatures' array.
	 */
	logicalOperator: LogicalOperator;
	disableFeatures: Feature[];
	featureRedirect: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => any[] | any[];
}

@Injectable()
export class FeatureEnabledGuard implements CanActivate {
	constructor(
		private featuresService: FeaturesService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private appContextService: AppContextService,
		private i18n: I18nService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let shouldRedirect = false;
		const routeData = this.parseRouteData(route);
		if (routeData) {
			if (routeData.enableFeatures) {
				const enableFeatures = routeData.enableFeatures;
				shouldRedirect = !(routeData.logicalOperator === LogicalOperator.And
					? this.featuresService.isEnabled(enableFeatures)
					: this.featuresService.isAnyEnabled(enableFeatures));
			} else if (routeData.disableFeatures) {
				shouldRedirect = this.featuresService.isAnyEnabled(routeData.disableFeatures);
			}

			if (shouldRedirect && routeData.featureRedirect && !this.appContextService.isSCC) {
				const featureRedirect = routeData.featureRedirect;
				const redirectRoute =
					featureRedirect instanceof Function ? featureRedirect(route, state) : featureRedirect;
				// NOTE: Returning a UrlTree and not navigating since it caused a race condition
				return this.router.createUrlTree(redirectRoute, {
					relativeTo: this.activatedRoute,
				});
			}
		}
		if (this.appContextService.isSCC && shouldRedirect) {
			sccHostService.ui
				.showError(this.i18n.get('common_permissions_noPermissionTooltip'))
				.then(err => sccHostService.state.go('homepage'));
			return false;
		}

		return !shouldRedirect;
	}

	private parseRouteData(route: ActivatedRouteSnapshot): FeaturesData {
		const data = route.routeConfig.data;
		if (data && data.features) {
			if (Array.isArray(data.features)) {
				return {
					enableFeatures: data.features,
					disableFeatures: data.disableFeatures,
					logicalOperator: data.logicalOperator,
					featureRedirect: data.featureRedirect,
				};
			} else {
				return {
					enableFeatures: data.features.enableFeatures,
					disableFeatures: data.features.disableFeatures,
					logicalOperator: data.features.logicalOperator,
					featureRedirect: data.features.featureRedirect,
				};
			}
		}

		return undefined;
	}
}
