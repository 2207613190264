var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { LegacyUser } from '../legacy-user.entity';
import { AlertsSeveritySummary } from '../../../alert/alerts-severity-summary.value-object';
var ɵ0 = function (user) { return ({
    AccountDomainName: user.accountDomainName,
    AccountName: user.accountName,
    AccountSid: user.sid,
}); }, ɵ1 = function (config) { return config.data.serviceUrls.activeAlertsSummary; };
var LegacyUserAlertsSeveritySummaryRelationship = /** @class */ (function () {
    function LegacyUserAlertsSeveritySummaryRelationship() {
    }
    LegacyUserAlertsSeveritySummaryRelationship = __decorate([
        EntityRelationship({
            sourceEntity: LegacyUser,
            dataEntity: AlertsSeveritySummary,
            allowedTypes: [RelationshipType.OneToOne],
            getRelationshipData: ɵ0,
            fixedData: {
                includeEventReferences: true,
                lookingBackInDays: 180,
            },
            endpoint: 'activeAlertsSummary',
            baseUrl: ɵ1,
        })
    ], LegacyUserAlertsSeveritySummaryRelationship);
    return LegacyUserAlertsSeveritySummaryRelationship;
}());
export { LegacyUserAlertsSeveritySummaryRelationship };
export { ɵ0, ɵ1 };
