import { Component, Input, OnChanges } from '@angular/core';
import { Icon } from '@wcd/icons';

export const enum IndicationIconState {
	'On',
	'Off',
	'Neutral',
}

export interface IndicationIcon {
	show?: boolean;
	icon: Icon;
	state: IndicationIconState;
	tooltip?: string;
}

@Component({
	selector: 'wcd-indication-icons',
	templateUrl: './indication-icons.component.html',
	styleUrls: ['./indication-icons.component.scss'],
})
export class WcdIndicationIconsComponent implements OnChanges {
	@Input() indicationIcons: Array<IndicationIcon> = [];
	@Input() allowTooltipHTMLRender: boolean;

	_indicationIcons: Array<IndicationIcon | { contentClass: string }>;

	ngOnChanges() {
		if (!this.indicationIcons || !this.indicationIcons.length) return;
		this._indicationIcons = this.indicationIcons.map(indicationIcon => {
			let contentClass: string;
			switch (indicationIcon.state) {
				case IndicationIconState.Neutral:
					contentClass = '';
					break;
				case IndicationIconState.Off:
					contentClass = 'color-text-indicationOff';
					break;
				case IndicationIconState.On:
					contentClass = 'color-text-red';
					break;
			}
			return Object.assign({}, indicationIcon, { contentClass: contentClass });
		});
	}
}
