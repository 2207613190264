import { VulnerabilityFieldsService } from './../../../../@entities/@tvm/vulnerabilities/services/vulnerabilities.fields.service';
import { EventEmitter, OnInit } from '@angular/core';
import { VulnerabilityService } from '../../../../@entities/@tvm/vulnerabilities/services/vulnerability.service';
import { RelationshipRepository } from '@microsoft/paris';
import { DataviewField } from '@wcd/dataview';
var RelatedCvesDetailsComponent = /** @class */ (function () {
    function RelatedCvesDetailsComponent(vulnerabilityService, vulnerabilityFieldsService) {
        this.vulnerabilityService = vulnerabilityService;
        this.vulnerabilityFieldsService = vulnerabilityFieldsService;
        this.includeExposedMachinesComponent = true;
        this.disableSort = true;
        this.initiallyCollapsed = true;
        this.showMore = new EventEmitter();
        this.export = new EventEmitter();
        this._defaultFields = this.vulnerabilityFieldsService.defaultSidePanelFieldsConfig;
    }
    Object.defineProperty(RelatedCvesDetailsComponent.prototype, "repository", {
        set: function (value) {
            this._repository = value;
        },
        enumerable: true,
        configurable: true
    });
    RelatedCvesDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Enabling the onclick for adding the tab index to the name column
        this.fields = DataviewField.fromList((this.customFields ? this.customFields : this._defaultFields).map(function (field) {
            return field.id !== 'name'
                ? field
                : _this.vulnerabilityFieldsService.createClickableNameField(function (vulnerability) { return _this.openVulnerabilityPanel(vulnerability); });
        }));
        if (this.contextOptions && this.contextOptions.isSingleAsset) {
            this.fields = this.fields.filter(function (field) { return field.id !== 'numOfImpactedAssets'; });
        }
    };
    RelatedCvesDetailsComponent.prototype.openVulnerabilityPanel = function (vulnerability) {
        this.vulnerabilityService.showVulnerabilityPanel(vulnerability, this.includeExposedMachinesComponent, this.isNetworkGearCve);
    };
    RelatedCvesDetailsComponent.prototype.openAllVulnerabilities = function () {
        this.showMore.emit();
    };
    return RelatedCvesDetailsComponent;
}());
export { RelatedCvesDetailsComponent };
