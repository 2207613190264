import { EntityModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Email',
	pluralName: 'Emails',
	readonly: true,
})
export class Email extends EntityModelBase<string> {
	@EntityField({ data: 'MessageId' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'Sender' })
	sender: string;

	@EntityField({ data: 'SenderIp' })
	senderIp: string;

	@EntityField({
		data: 'Recipients',
		parse: recipients => recipients && recipients.split(','),
	})
	recipients: Array<string>;

	@EntityField({ data: 'Subject' })
	subject: string;

	@EntityField({ data: 'ReceivedDate' })
	receivedDate: Date;

	@EntityField({ data: 'DeepLinkToO365Portal' })
	linkToO365Portal: string;
}
