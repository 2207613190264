var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector } from '@angular/core';
import { LegacyUser, Machine } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { CyberEventsActionTypesService } from '../../../@entities/cyber_events/services/cyber-events-action-types.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { isAccountLinkable } from '@wcd/scc-interface';
var CyberEventEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(CyberEventEntityDetailsComponent, _super);
    function CyberEventEntityDetailsComponent(changeDetectorRef, cyberEventsActionTypesService, globalEntityTypesService, injector) {
        var _this = _super.call(this, injector) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.cyberEventsActionTypesService = cyberEventsActionTypesService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.userType = LegacyUser;
        _this.machineType = Machine;
        _this.showMachineLink = true;
        _this.showEventDate = true;
        _this.showActionType = true;
        return _this;
    }
    CyberEventEntityDetailsComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(CyberEventEntityDetailsComponent.prototype, "actionType", {
        get: function () {
            if (!this._actionType)
                this.setActionType();
            return this._actionType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CyberEventEntityDetailsComponent.prototype, "event", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CyberEventEntityDetailsComponent.prototype, "description", {
        get: function () {
            if (!this._description)
                this.setEventDescription();
            return this._description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CyberEventEntityDetailsComponent.prototype, "tags", {
        get: function () {
            if (!this._tags)
                this.setEventTags();
            return this._tags;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CyberEventEntityDetailsComponent.prototype, "isUserLinkAllowed", {
        get: function () {
            if (this._isUserLinkAllowed === undefined)
                this.setUserDetails();
            return this._isUserLinkAllowed;
        },
        enumerable: true,
        configurable: true
    });
    CyberEventEntityDetailsComponent.prototype.setEntity = function (entity) {
        _super.prototype.setEntity.call(this, entity);
        this.showActionType = entity.isUnknownOrNotAlert;
        this.setEventDescription();
        this.setUserDetails();
        this.setEventTags();
        this.changeDetectorRef.markForCheck();
    };
    CyberEventEntityDetailsComponent.prototype.setActionType = function () {
        this._actionType = this.cyberEventsActionTypesService.getActionType(this.event);
    };
    CyberEventEntityDetailsComponent.prototype.setEventDescription = function () {
        this._description = this.cyberEventsActionTypesService.getEventDescription(this.event);
    };
    CyberEventEntityDetailsComponent.prototype.setEventTags = function () {
        this._tags = this.cyberEventsActionTypesService.getEventTags(this.event);
    };
    CyberEventEntityDetailsComponent.prototype.setUserDetails = function () {
        this._isUserLinkAllowed = this.event.initiatingUser && isAccountLinkable(this.event.initiatingUser);
    };
    return CyberEventEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { CyberEventEntityDetailsComponent };
