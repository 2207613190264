
		<div class="wcd-impacted-entities-counter">
			<ul
				class="wcd-flex wcd-padding-none-all wcd-margin-none-all"
				[class.wcd-flex-horizontal]="layout === 'row'"
			>
				<li
					[wcdTooltip]="entity.tooltip"
					[wcdTooltipAllowHtmlRendering]="true"
					[wcdTooltipSanitizeHtml]="false"
					class="wcd-padding-small-right wcd-text-overflow-medium"
					*ngFor="let entity of entitiesList"
				>
					<fab-icon
						*ngIf="entity.icon"
						contentClass="field-value-icon"
						[iconName]="entity.icon"
					></fab-icon>
					<span>{{ entity.display }}</span>
				</li>
			</ul>
		</div>
	