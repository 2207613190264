import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { IpEntityTypeService } from './services/ip.entity-type.service';
import { IpEntityPanelComponent } from './components/ip.entity-panel.component';
import { IpEntityComponent } from './components/ip.entity.component';
import { IpsService } from './services/ips.service';
import { IpAlertsSummaryWidgetComponent } from './components/widgets/ip.alerts-summary.widget';
import { ReportsModule } from '../../reports/reports.module';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, ReportsModule, A11yModule],
	providers: [IpEntityTypeService, IpsService],
	declarations: [IpEntityPanelComponent, IpEntityComponent, IpAlertsSummaryWidgetComponent],
	entryComponents: [IpEntityPanelComponent, IpEntityComponent, IpAlertsSummaryWidgetComponent],
})
export class IpEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(IpEntityTypeService);
	}
}
