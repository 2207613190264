import { ChangeEventService } from './services/change-event.service';
import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../shared/shared.module';
import { ChangeEventEntityTypeService } from './services/change-event.entity-type.service';
import { ChangeEventEntityPanelComponent } from './components/change-event.entity-panel.component';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { ChangeEventFieldsService } from './services/change-event.fields.service';
import { TvmModule } from '../../../tvm/tvm.module';
import { I18nModule } from '@wcd/i18n';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule, TvmModule],
	declarations: [ChangeEventEntityPanelComponent],
	exports: [ChangeEventEntityPanelComponent],
	entryComponents: [ChangeEventEntityPanelComponent],
	providers: [I18nModule, ChangeEventFieldsService, ChangeEventEntityTypeService, ChangeEventService],
})
export class ChangeEventEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(ChangeEventEntityTypeService);
	}
}
