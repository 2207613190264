import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	LegacyUser,
	Machine,
	MachineBackendOptions,
	MachineUserDetails,
	MachineUserDetailsRelationship,
} from '@wcd/domain';
import { Feature, FeaturesService } from '@wcd/config';

export interface MachineUserLogonData {
	readonly mostPrevalentAccount: LegacyUser;
	readonly leastPrevalentAccount: LegacyUser;
	readonly loggedOnUserCount: number;
	readonly machine: Machine;
}

@Injectable()
export class MachineLoggedOnUsersDataService {
	readonly backendOptions: MachineBackendOptions;

	constructor(private readonly paris: Paris, private readonly featuresService: FeaturesService) {
		this.backendOptions = {
			isUnifiedDevicePage: featuresService.isEnabled(Feature.NewDevicePage),
		};
	}

	fetchLoggedOnUsersData(machine: Machine): Observable<MachineUserLogonData> {
		return this.paris
			.getRelatedItem<Machine, MachineUserDetails>(MachineUserDetailsRelationship, machine, {
				where: this.backendOptions,
			})
			.pipe(
				map(machineUserDetails => {
					return {
						mostPrevalentAccount: machineUserDetails.mostPrevalentUser,
						leastPrevalentAccount: machineUserDetails.leastPrevalentUser,
						loggedOnUserCount: machineUserDetails.loggedOnUsersCount,
						machine,
					};
				})
			);
	}
}
