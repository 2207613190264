var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { forwardRef, OnChanges, OnInit, SimpleChanges, ChangeDetectorRef, EventEmitter, ElementRef, } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChecklistComponent } from './checklist.component';
import { DropdownComponent } from './dropdown.component';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Positions } from '@wcd/forms';
export var CHECKLIST_DROPDOWN_CUSTOM_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return ChecklistDropdownComponent; }),
    multi: true,
};
var ChecklistDropdownComponent = /** @class */ (function (_super) {
    __extends(ChecklistDropdownComponent, _super);
    function ChecklistDropdownComponent(changeDetectorRef, i18nService, liveAnnouncer) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.i18nService = i18nService;
        _this.liveAnnouncer = liveAnnouncer;
        _this.showIconDropdown = true;
        _this.openMenuPosition = Positions.Default;
        _this.isBordered = true;
        _this.isFullWidth = false;
        _this.navigateUsingArrowKeysOnly = false;
        _this.ariaRole = "button";
        _this.disableSecondButton = false;
        _this.wrapItemsLabel = false;
        _this.secondButtonClick = new EventEmitter();
        _this.onChecklistChangeEmit = new EventEmitter();
        _this.toggleDropdownState = new EventEmitter();
        _this.visible = false;
        _this.setFocus = function () {
            if (_this.dropdown) {
                _this.dropdown.setFocus();
            }
        };
        return _this;
    }
    ChecklistDropdownComponent.prototype.closeDropdown = function () {
        this.dropdown.toggle();
    };
    ChecklistDropdownComponent.prototype.isVisible = function () {
        return this.dropdown.isVisible;
    };
    ChecklistDropdownComponent.prototype.setVisible = function (visible) {
        this.visible = visible;
        this.toggleDropdownState.emit(visible);
    };
    ChecklistDropdownComponent.prototype.ngOnChanges = function (changes) {
        if (changes.values) {
            this.allValuesAreDisabled = !changes.values.currentValue.some(function (value) { return !value.disabled; });
        }
    };
    ChecklistDropdownComponent.prototype.onChecklistChange = function (event) {
        this.onChange(event);
        if (this.disableSecondButton) {
            this.onChecklistChangeEmit.emit();
        }
    };
    ChecklistDropdownComponent.prototype.selectAllOrNone = function () {
        var _this = this;
        var selectedCount = this.values.filter(function (value) { return _this.selectedValues[value.id]; }).length;
        if (selectedCount === this.values.length) {
            this.selectNone();
            this.liveAnnouncer.announce(this.i18nService.get('common.uncheckedAllColumns', {
                column: this.values[0].name,
            }), 'assertive', 300);
        }
        else {
            this.liveAnnouncer.announce(this.i18nService.get('common.checkedAllColumns'), 'assertive', 300);
            this.selectAll();
        }
    };
    ChecklistDropdownComponent.prototype.setFocusOnAllOrNone = function () {
        if (this.selectAllOrNoneButton && this.selectAllOrNoneButton.nativeElement)
            this.selectAllOrNoneButton.nativeElement.focus();
    };
    return ChecklistDropdownComponent;
}(ChecklistComponent));
export { ChecklistDropdownComponent };
