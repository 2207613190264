import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { machineManagedByStatusValues } from './machine-managed-by-status.values';
import { IMachineManagedByStatus, MachineManagedByStatusType } from './machine-managed-by-status.models';

@Entity({
	singularName: 'Device Managed By Status',
	pluralName: 'Device Managed By Statuses',
	values: machineManagedByStatusValues,
})
export class MachineManagedByStatus extends EntityModelBase<MachineManagedByStatusType> implements IMachineManagedByStatus {
	@EntityField() name: string;
	@EntityField() nameI18nKey: string;

	@EntityField() priority: number;

	get type(): MachineManagedByStatusType {
		return this.id;
	}
}
