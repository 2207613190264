import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
	AirsEmail,
	AirsEmailCluster,
	AirsEmailSubmission,
	AirsEntity,
	AirsEntityType,
	AirsFile,
	AirsIp,
	AirsUrl,
	EvidenceAlertsSummary,
	File,
	FileIncidentAlertsRelationship,
	HuntingContext,
	MailClusterUtils,
} from '@wcd/domain';
import { AirsEntitiesService } from '../services/airs-entities.service';
import { GeoCoordinates } from '../../../maps/models/geo-coordinates.model';
import { TabModel, TabModelConfig } from '../../../shared/components/tabs/tab.model';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { AuthService } from '@wcd/auth';
import { filter, shareReplay } from 'rxjs/operators';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { GoHuntAirsEntityService } from '../services/go-hunt-airs-entity.service';
import { Observable } from 'rxjs';
import { omit } from 'lodash-es';
import { AppContextService, FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';

enum TabIds {
	relations = 'relations',
	behavior = 'behavior',
	summary = 'summary',
	evidenceView = 'evidenceView',
}

enum CollapsibleID {
	Details = 'airs-entity-entity-panel-details',
}

@Component({
	selector: 'airs-entity-entity-panel',
	templateUrl: './airs-entity.entity-panel.component.html',
})
export class AirsEntityEntityPanelComponent extends EntityPanelComponentBase<
	AirsEntity,
	{
		type_id?: number;
		investigation_id?: number;
		summaryView?: boolean;
		allowStatusSplit?: boolean;
		huntingContext?: HuntingContext;
	}
> {
	AirsEntityType = AirsEntityType;

	@Input() showApproveReject: boolean = true;
	@Input() flatView: boolean = false;
	@Input() showGoHuntInDetails: boolean = false;

	tabs: Array<TabModel>;
	currentTab: TabModel;
	booleanProperties: Array<{ name: string; value: boolean }>;
	mapCoordinates: GeoCoordinates;
	isMapAvailable: boolean = true;
	isScreenshotAvailable: boolean = true;
	isScreenshotError: boolean = false;
	isLoadingDataFromCloud: boolean = false;
	isSettingExclusionRule: boolean = false;
	tabIds = TabIds;

	collapsibleID = CollapsibleID;

	protected readonly defaultTabsInitialConfig: ReadonlyArray<TabModelConfig> = [
		{
			id: this.tabIds.summary,
			name: this.i18nService.strings.airsEntities_panel_tab_summary,
			value: null,
		},
	];

	private fileAlertRepo: RelationshipRepository<File, EvidenceAlertsSummary>;
	file: File;
	goHuntActionConfig$: Observable<ItemActionModelConfig<any>>;
	threatExplorerLink: string;

	get summaryView(): boolean {
		return this.options && this.options.summaryView;
	}

	private get huntingContext(): HuntingContext {
		return this.options && this.options.huntingContext;
	}

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		private airsEntitiesService: AirsEntitiesService,
		public authService: AuthService,
		private paris: Paris,
		public i18nService: I18nService,
		private goHuntAirsEntityService: GoHuntAirsEntityService,
		private appContextService: AppContextService,
		private flavorService: FlavorService
	) {
		super(changeDetectorRef);
		this.fileAlertRepo = this.paris.getRelationshipRepository(FileIncidentAlertsRelationship);
	}

	ngOnInit() {
		super.ngOnInit();
		if (this.action$) {
			this.action$
				.pipe(
					filter(
						(action: { action: ItemActionModel; data: any }) =>
							action.action.id === 'createEntityExclusionRule'
					)
				)
				.subscribe(() => {
					if (this.entity.remediationActions) {
						this.entity.remediationActions.length = 0;
					}
				});
		}
	}

	setEntity(entity: AirsEntity) {
		super.setEntity(entity);

		if (!entity) return;

		this.setTabs();
		this.booleanProperties = this.getBooleanProperties();
		this.setLocationData();
		this.setFile();
		if (this.flavorService.isEnabled(AppFlavorConfig.routes.hunting)) {
			this.setGoHunt();
		}
		this.threatExplorerLink = this.getThreatExplorerLink();
	}

	setTabs() {
		let tabsConfig: Array<TabModelConfig> = [...this.defaultTabsInitialConfig];

		if (this.summaryView) {
			// only one value
			tabsConfig = [{ id: TabIds.evidenceView }];
		} else {
			if (this.entity.relations && this.entity.relations.length) {
				tabsConfig.push({
					id: TabIds.relations,
					name:
						this.entity.relationCount === 1
							? this.i18nService.strings.airsEntities_panel_tab_relations_singular
							: this.i18nService.strings.airsEntities_panel_tab_relations_plural,
					value: this.entity.relationCount,
				});
			}

			if (this.entity.behavior && this.entity.behavior.length) {
				tabsConfig.push({
					id: TabIds.behavior,
					name: this.i18nService.strings.airsEntities_panel_tab_behavior,
					value: null,
				});
			}
		}

		this.tabs = tabsConfig.map((tab) => new TabModel(tab));

		this.currentTab = this.tabs[0];
	}

	setLocationData() {
		if ((<AirsIp>this.entity).location) {
			this.mapCoordinates = new GeoCoordinates((<AirsIp>this.entity).location);
		}
	}

	setTab(tab: TabModel) {
		this.currentTab = tab;
	}

	onScreenshotError() {
		this.isScreenshotAvailable = false;
		this.isScreenshotError = true;
	}

	onMapError() {
		this.isMapAvailable = false;
	}

	/**
	 * Returns an array of all the Entity type's possible boolean fields, each with the Entity's value.
	 * @returns {{field:any, value:boolean}[]}
	 */
	getBooleanProperties(): Array<{ name: string; value: boolean }> {
		const booleanProperties = this.airsEntitiesService.getEntityBooleanFields(this.entity);

		return (
			booleanProperties &&
			booleanProperties.sort((a, b) => {
				if ((a.value && b.value) || (!a.value && !b.value)) {
					return 0;
				}

				return a.value ? -1 : 1;
			})
		);
	}

	private setFile() {
		this.file = (<AirsFile>this.entity).sha1
			? new File({
					id: (<AirsFile>this.entity).sha1,
					sha1: (<AirsFile>this.entity).sha1,
			  })
			: null;
		this.changeDetectorRef.markForCheck();
	}

	private setGoHunt() {
		this.goHuntActionConfig$ =
			this.showGoHuntInDetails || this.flavorService.isEnabled(AppFlavorConfig.routes.hunting)
				? this.goHuntAirsEntityService
						.getGoHuntAirsEntityObservable(this.entity, { huntingContext: this.huntingContext })
						.pipe(shareReplay({ bufferSize: 1, refCount: true }))
				: null;
	}

	private getThreatExplorerLink(): string {
		if (!this.appContextService.isSCC) {
			return null;
		}

		const endDate = new Date().toISOString();
		const currentDate = new Date();
		const monthAgo = new Date(currentDate.setDate(currentDate.getDate() - 30)).toISOString();
		const dateRangeStr = `startTime=${monthAgo}&endTime=${endDate}`;

		if (
			this.entity.type &&
			(this.entity.type.id == AirsEntityType.MailMessage ||
				this.entity.type.id == AirsEntityType.SubmissionMail)
		) {
			const param = (<AirsEmail | AirsEmailSubmission>this.entity).networkMessageId;
			return (
				(param &&
					`/threatexplorer?dltarget=Explorer&dlstorage=Url&viewid=allemail&query-NetworkMessageId=${param}&${dateRangeStr}`) ||
				null
			);
		}
		if (
			this.flavorService.isEnabled(AppFlavorConfig.incidents.urlExplorerLink) &&
			this.entity.type &&
			this.entity.type.id == AirsEntityType.URL
		) {
			const param = (<AirsUrl>this.entity).address;
			return (
				(param &&
					`/threatexplorer?dltarget=Explorer&dlstorage=Url&viewid=allemail&query-CanonicalizedUrl=${encodeURIComponent(
						param
					)}&${dateRangeStr}`) ||
				null
			);
		}
		if (this.entity.type && this.entity.type.id == AirsEntityType.MailCluster) {
			const clusteredBy = (<AirsEmailCluster>this.entity).clusteredBy;
			const clusteredByValue = (<AirsEmailCluster>this.entity).clusterByValue;
			let queryObj = MailClusterUtils.getQuery(clusteredBy, clusteredByValue);
			queryObj = omit(queryObj, 'ContentType');
			const param = Object.entries(queryObj)
				.map(([k, v]) => `query-${k}=${encodeURIComponent(v.join(','))}`)
				.join('&');
			return (
				(param &&
					`/threatexplorer?dltarget=Explorer&dlstorage=Url&viewid=allemail&${param}&${dateRangeStr}`) ||
				null
			);
		}
	}
}
