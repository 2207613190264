import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { I18nService, Strings } from '@wcd/i18n';
import { config } from '@wcd/shared';
import { filter } from 'rxjs/operators';
import { MainAppState, MainAppStateService } from '../../main/services/main-app-state.service';

@Component({
	selector: 'wcd-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class BannerComponent implements AfterViewInit {
	@Input() text: string;
	@Input() trackId: string;
	@Input() showCloseButton = true;
	@Input() linkOptions: {
		id: string;
		buttonTextKey: keyof Strings;
		navigateTo: string;
	};
	@Output() onClose = new EventEmitter<void>();

	navigateTo: string;
	hide: boolean = false;
	isNarrowLayout: boolean = false;

	constructor(
		public i18nService: I18nService,
		private mainAppStateService: MainAppStateService,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	ngAfterViewInit() {
		this.mainAppStateService.state$
			.pipe(
				filter((state: MainAppState) => {
					return (
						this.isNarrowLayout !==
						config.widthBreakpointSmallerThan(
							state.screenMaxWidthBreakpoint,
							config.msScreenSizeBreakpoints.xl
						)
					);
				})
			)
			.subscribe((state: MainAppState) => {
				this.isNarrowLayout = config.widthBreakpointSmallerThan(
					state.screenMaxWidthBreakpoint,
					config.msScreenSizeBreakpoints.xl
				);
				this.changeDetectorRef.markForCheck();
			});
	}

	readonly buttonStyle = {
		root: {
			backgroundColor: 'white',
			color: '#0078d4',
			borderRadius: 2,
			paddingLeft: 19,
			paddingRight: 19,
		},
	};

	close() {
		this.onClose.emit();
		this.hide = true;
	}
}
