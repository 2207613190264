var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { MailClusterUtils, getMailClusterMailboxCount, getMailClusterNotInMailboxCount, getMailClusterOnPremOrExternalCount, getMailClusterInternalIdFromUrns, } from '../../mailbox/mail-cluster.utils';
import { OfficeProtectionStatus, OfficeThreatTypes, } from '../../investigation/actions/office-action.utils';
import { dateStrToUtc } from '../../utils';
var ɵ0 = function (threatTypes) {
    if (typeof threatTypes === 'string') {
        // Note: since the keys and values are known we can use this hack
        var convertedThreatTypes = threatTypes && threatTypes.replace(/'/g, '"');
        return convertedThreatTypes ? JSON.parse(convertedThreatTypes) : null;
    }
    return threatTypes;
}, ɵ1 = function (deliveryLocations) {
    if (typeof deliveryLocations === 'string') {
        // Note: since the keys and values are known we can use this hack
        var convertedDeliveryLocations = deliveryLocations && deliveryLocations.replace(/'/g, '"');
        return convertedDeliveryLocations ? JSON.parse(convertedDeliveryLocations) : null;
    }
    return deliveryLocations;
}, ɵ2 = function (protectionStatuses) {
    if (typeof protectionStatuses === 'string') {
        // Note: since the keys and values are known we can use this hack
        var convertedProtectionStatuses = protectionStatuses && protectionStatuses.replace(/'/g, '"');
        return convertedProtectionStatuses ? JSON.parse(convertedProtectionStatuses) : null;
    }
    return protectionStatuses;
}, ɵ3 = function (dateStr) { return dateStrToUtc(dateStr); };
var AirsEmailCluster = /** @class */ (function (_super) {
    __extends(AirsEmailCluster, _super);
    function AirsEmailCluster(data) {
        var _this = _super.call(this, data) || this;
        _this.showClusterAdditionalData = false;
        var isClusterThreatsExists = false;
        var isClusterDeliveryLocationsExists = false;
        var isClusterOriginalDeliveryLocationsExists = false;
        if (_this.countByThreatType) {
            _this.emailClusterThreats = {
                threats: _this.threats,
                malwareCount: _this.countByThreatType[OfficeThreatTypes.Malware],
                phishCount: _this.countByThreatType[OfficeThreatTypes.Phish],
                highConfidencePhishCount: _this.countByThreatType[OfficeThreatTypes.HighConfidencePhish],
                spamCount: _this.countByThreatType[OfficeThreatTypes.Spam],
            };
            isClusterThreatsExists = Object.values(_this.emailClusterThreats).some(function (data) { return Boolean(data); });
        }
        if (_this.countByDeliveryLocation) {
            _this.emailClusterDeliveryLocations = {
                mailboxCount: getMailClusterMailboxCount(_this.countByDeliveryLocation),
                notInMailboxCount: getMailClusterNotInMailboxCount(_this.countByDeliveryLocation),
                onPremOrExternalCount: getMailClusterOnPremOrExternalCount(_this.countByDeliveryLocation),
            };
            isClusterDeliveryLocationsExists = Object.values(_this.emailClusterDeliveryLocations).some(function (data) {
                return Boolean(data);
            });
        }
        if (_this.countByProtectionStatus) {
            _this.emailClusterOriginalDeliveryLocations = {
                deliveredCount: _this.countByProtectionStatus[OfficeProtectionStatus.Delivered],
                junkedCount: _this.countByProtectionStatus[OfficeProtectionStatus.DeliveredAsSpam],
                replacedCount: _this.countByProtectionStatus[OfficeProtectionStatus.Replaced],
                blockedCount: _this.countByProtectionStatus[OfficeProtectionStatus.Blocked],
            };
            isClusterOriginalDeliveryLocationsExists = Object.values(_this.emailClusterOriginalDeliveryLocations).some(function (data) { return Boolean(data); });
        }
        _this.showClusterAdditionalData =
            isClusterThreatsExists ||
                isClusterDeliveryLocationsExists ||
                isClusterOriginalDeliveryLocationsExists;
        _this.entityName = MailClusterUtils.getDisplayQuery(_this.clusteredBy, _this.clusterByValue);
        var investigationId = _this.investigation && _this.investigation.id;
        /*
            'mdoInternalId' is NOT empty in case of Admin playbook.
            Once the 'Admin playbook' is open to all customer this calculation can be removed
            because this field will not be empty.
         */
        _this.mdoInternalId =
            _this.mdoInternalId ||
                (typeof investigationId === 'string'
                    ? getMailClusterInternalIdFromUrns(investigationId)
                    : null);
        return _this;
    }
    __decorate([
        EntityField({ data: 'network_message_ids' }),
        __metadata("design:type", Array)
    ], AirsEmailCluster.prototype, "networkMessageIds", void 0);
    __decorate([
        EntityField({
            data: 'count_by_threat_type',
            parse: ɵ0,
        }),
        __metadata("design:type", Object)
    ], AirsEmailCluster.prototype, "countByThreatType", void 0);
    __decorate([
        EntityField({
            data: 'count_by_delivery_location',
            parse: ɵ1,
        }),
        __metadata("design:type", Object)
    ], AirsEmailCluster.prototype, "countByDeliveryLocation", void 0);
    __decorate([
        EntityField({
            data: 'count_by_protection_status',
            parse: ɵ2,
        }),
        __metadata("design:type", Object)
    ], AirsEmailCluster.prototype, "countByProtectionStatus", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AirsEmailCluster.prototype, "threats", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AirsEmailCluster.prototype, "query", void 0);
    __decorate([
        EntityField({ data: 'query_time', parse: ɵ3 }),
        __metadata("design:type", Date)
    ], AirsEmailCluster.prototype, "queryTime", void 0);
    __decorate([
        EntityField({ data: 'mail_count' }),
        __metadata("design:type", Number)
    ], AirsEmailCluster.prototype, "mailCount", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AirsEmailCluster.prototype, "source", void 0);
    __decorate([
        EntityField({ data: 'volume_anomaly' }),
        __metadata("design:type", Boolean)
    ], AirsEmailCluster.prototype, "volumeAnomaly", void 0);
    __decorate([
        EntityField({ data: 'clustered_by' }),
        __metadata("design:type", String)
    ], AirsEmailCluster.prototype, "clusteredBy", void 0);
    __decorate([
        EntityField({ data: 'cluster_by_value' }),
        __metadata("design:type", String)
    ], AirsEmailCluster.prototype, "clusterByValue", void 0);
    __decorate([
        EntityField({
            data: 'mdo_internal_id',
        }),
        __metadata("design:type", String)
    ], AirsEmailCluster.prototype, "mdoInternalId", void 0);
    __decorate([
        EntityField({
            data: 'mdo_remediation_id',
        }),
        __metadata("design:type", String)
    ], AirsEmailCluster.prototype, "mdoRemediationId", void 0);
    __decorate([
        EntityField({
            data: 'mdo_batch_id',
        }),
        __metadata("design:type", String)
    ], AirsEmailCluster.prototype, "mdoBatchId", void 0);
    AirsEmailCluster = __decorate([
        Entity(__assign({}, airsEntityConfig, { singularName: 'Email Cluster', pluralName: 'Email Clusters', modelWith: null, forwardRefName: AirsEntityRefNames.AirsEmailCluster })),
        __metadata("design:paramtypes", [Object])
    ], AirsEmailCluster);
    return AirsEmailCluster;
}(AirsEntity));
export { AirsEmailCluster };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
