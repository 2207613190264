import { EntityConfig } from '@microsoft/paris';
import { Cache, DefaultApiVersion, TvmEndPoint } from './commonEndPointUtils';

const BaseUrl = config => `${config.data.serviceUrls.tvm}/${TvmEndPoint.Manualcontentmanagement}`;

export const TvmMcmSharedEntityConfigurations: Partial<EntityConfig<any>> = {
	cache: Cache,
	readonly: true,
	customHeaders: DefaultApiVersion,
	baseUrl: BaseUrl,
};
