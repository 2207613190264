import { FlavorConfig, ProductFlavor, IAppFlavorService } from './models';
import { sccHostService } from '@wcd/scc-interface';

export class AppFlavorService implements IAppFlavorService {
	constructor(
		private mdeFlavor: ProductFlavor,
		private isMdoActive: boolean,
		private isMdiActive: boolean,
	) {}

	isEnabled(config: FlavorConfig): boolean {
		if (!config) {
			return false;
		}

		// TODO: after BE implementation completed (MdeFlavor is returned both in TenantContext and in Login) return false here
		if (!this.mdeFlavor && !sccHostService.isSCC) {
			return true;
		}

		const isEnabledForNonMdeWorkloads = config.enableForNonMdeWorkloads && (this.isMdoActive || this.isMdiActive);
		const isEnabledForMde = (config.mdeFlavors || []).includes(this.mdeFlavor);

		return isEnabledForNonMdeWorkloads || isEnabledForMde;
	}
}
