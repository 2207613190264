var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subscriber } from 'rxjs';
/**
 * Returns an Observable that mirrors the source Observable with the exception of an `error`. If the source Observable
 * calls `error`, this method will resubscribe to the source Observable for a maximum of `count` resubscriptions (given
 * as a number parameter) rather than propagating the `error` call.
 *
 * ![](retry.png)
 *
 * Any and all items emitted by the source Observable will be emitted by the resulting Observable, even those emitted
 * during failed subscriptions. For example, if an Observable fails at first but emits [1, 2] then succeeds the second
 * time and emits: [1, 2, 3, 4, 5] then the complete stream of emissions and notifications
 * would be: [1, 2, 1, 2, 3, 4, 5, `complete`].
 * @param {number} count - Number of retry attempts before failing.
 * @param {boolean} resetOnSuccess - If true, every successful emission will reset the error count
 * @return {Observable} The source Observable modified with the retry logic.
 * @method retry
 * @owner Observable
 */
export function retry(count, resetOnSuccess) {
    if (count === void 0) { count = -1; }
    return function (source) { return source.lift(new RetryOperator(count, source, resetOnSuccess)); };
}
var RetryOperator = /** @class */ (function () {
    function RetryOperator(count, source, resetOnSuccess) {
        this.count = count;
        this.source = source;
        this.resetOnSuccess = resetOnSuccess;
    }
    RetryOperator.prototype.call = function (subscriber, source) {
        return source.subscribe(new RetrySubscriber(subscriber, this.count, this.source, this.resetOnSuccess));
    };
    return RetryOperator;
}());
/**
 * We need this JSDoc comment for affecting ESDoc.
 * @ignore
 * @extends {Ignored}
 */
var RetrySubscriber = /** @class */ (function (_super) {
    __extends(RetrySubscriber, _super);
    function RetrySubscriber(destination, count, source, resetOnSuccess) {
        var _this = _super.call(this, destination) || this;
        _this.count = count;
        _this.source = source;
        _this.resetOnSuccess = resetOnSuccess;
        _this.initialCount = _this.count;
        return _this;
    }
    RetrySubscriber.prototype.next = function (value) {
        _super.prototype.next.call(this, value);
        if (this.resetOnSuccess) {
            this.count = this.initialCount;
        }
    };
    RetrySubscriber.prototype.error = function (err) {
        if (!this.isStopped) {
            var _a = this, source = _a.source, count = _a.count;
            if (count === 0) {
                return _super.prototype.error.call(this, err);
            }
            else if (count > -1) {
                this.count = count - 1;
            }
            source.subscribe(this._unsubscribeAndRecycle());
        }
    };
    return RetrySubscriber;
}(Subscriber));
