import { Component, Input, OnInit } from '@angular/core';
import { Panel } from '@wcd/panels';
import { WizardStepModel } from '../wizard-step.model';

@Component({
	selector: 'wizard-panel',
	templateUrl: './wizard-panel.component.html',
	styleUrls: ['./wizard-panel.component.scss'],
})
export class WizardPanelComponent implements OnInit{

	@Input() wizardTitle: string;
	@Input() panelSettings?: Panel;

	/**
	 * The panel expects the steps IDs to be in ascending order, but not necessarily consecutive.
	 */
	@Input() stepsData: Array<WizardStepModel>;

	/**
	 * Must be an item of stepsData.
	 */
	@Input() currentStep: WizardStepModel;

	/**
	 * Called when the user presses the panel's "Back" button.
	 * The "Back" button will not be visible if currentStep is the first item in stepsData, or if isCompleted is true.
	 */
	@Input() onBackPressed: () => void;

	/**
	 * Called when the user presses the panel's "Next" button.
	 * The "Next" button will be disabled if isNextEnabled is false, or if isLoading is true.
	 * The "Next" button will be hidden if currentStep is the last item in stepsData, or if the current step
	 * has action button settings.
	 */
	@Input() onNextPressed: () => void;

	/**
	 * Called when the user presses the panel's "Close" / "Cancel" button.
	 */
	@Input() onClosePressed: (event?) => void;

	/**
	 * Controls whether the current step is completed / valid, and if the user may proceed to the next step.
	 */
	@Input() isNextEnabled: boolean;

	/**
	 * When true, the step controls will be disabled.
	 */
	@Input() isLoading: boolean;

	/**
	 * Used to mark the wizard was submitted, for cases when the panel should remain open for review.
	 * If true, the step controls will be hidden, and the "Cancel" button label will be replaced with "Close".
	 */
	@Input() isCompleted: boolean;

	isFloatingFeedbackWidget: boolean;

	ngOnInit() {
		this.panelSettings.className = 'panel-wizard';
	}

}
