<div role="group" [attr.aria-label]="ariaLabel" class="wcd-full-height">
	<cdk-virtual-scroll-viewport [itemSize]="itemHeight" *ngIf="withVirtualScroll; else normalScroll" class="wcd-full-height inline">
		<ul class="wcd-checklist wcd-full-height"
			[attr.data-track-component]="trackComponent" [attr.role]="listRole" [keyboard-navigable-container]>
			<li *cdkVirtualFor="let value of values; let valueIndex = index" [attr.role]="itemListRole" [ngStyle]="{'height.px': itemHeight}">
				<ng-container [ngTemplateOutlet]="checkbox"
							  [ngTemplateOutletContext]="{value: value, valueIndex: valueIndex}"></ng-container>
			</li>
		</ul>
	</cdk-virtual-scroll-viewport>

</div>

<ng-template #normalScroll>
	<ul class="wcd-checklist" [attr.role]="listRole" [attr.data-track-component]="trackComponent" [keyboard-navigable-container]>
		<li *ngFor="let value of values; let valueIndex = index" [attr.role]="itemListRole">
			<ng-container [ngTemplateOutlet]="checkbox" [ngTemplateOutletContext]="{value: value, valueIndex: valueIndex}"></ng-container>
		</li>
	</ul>
</ng-template>

<ng-template #checkbox let-value="value" let-valueIndex="valueIndex">
	<wcd-checkbox
		[(ngModel)]="selectedValues[value.id]"
		(ngModelChange)="setValue()"
		[isDisabled]="isDisabled || selectedValues[value.id] && isLastSelectedValue && disableEmptySelection || value.disabled"
		[wcdTooltip]="isDisabled && disableReason"
		[isInvalid]="value.invalid"
		[name]="name + '-checkbox-' + valueIndex"
		[tooltipText]="value.name"
		[tooltipOnOverFlowOnly]="true"
		setKeyboardSupport="navigateUsingArrowKeysOnly"
		[ariaRole]="'menuitemcheckbox'"
		[wrapLabel]="wrapLabel"
	>
		<span [ngStyle]="value.style">
			{{getItemLabel(value)}}
		</span>
		<wcd-help *ngIf="value.helpText" [text]="value.helpText"></wcd-help>
	</wcd-checkbox>
</ng-template>
