import { EndpointManagementSensePackagesService } from '../../services/endpoint-management-sense-packages.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { EndpointManagementService } from '../../services/endpoint-management.service';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { MANAGEMENT_FLOWS, EndpointManagementProcessesService, } from '../../services/endpoint-management-processes.service';
import { EndpointManagementProcess, } from '../../models/endpoint-management-process.model';
import { TzDateService } from '@wcd/localization';
var OFFBOARDING_PACKAGE_EXPIRATION_IN_DAYS = 30;
var SenseOffboardingComponent = /** @class */ (function () {
    function SenseOffboardingComponent(endpointManagementService, endpointManagementProcessesService, packagesService, appInsights, dialogsService, i18nService, tzDateService) {
        this.endpointManagementService = endpointManagementService;
        this.endpointManagementProcessesService = endpointManagementProcessesService;
        this.packagesService = packagesService;
        this.appInsights = appInsights;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.tzDateService = tzDateService;
        this.packages = this.packagesService.getEnabledPackages(MANAGEMENT_FLOWS.onboarding, this.endpointManagementProcessesService.default);
        this.currentMethod = this.packages && this.packages[0];
        this.getDeploymentMethodLabel = this.getDeploymentMethodLabel.bind(this);
    }
    Object.defineProperty(SenseOffboardingComponent.prototype, "osType", {
        set: function (process) {
            this.packages = this.packagesService.getEnabledPackages(MANAGEMENT_FLOWS.offboarding, process);
            this.currentMethod = this.packages[0];
        },
        enumerable: true,
        configurable: true
    });
    SenseOffboardingComponent.prototype.getDeploymentMethodLabel = function (managementPackage) {
        return this.i18nService.get('endpointManagement.deploymentMethods.' + managementPackage.name + '.title');
    };
    SenseOffboardingComponent.prototype.confirmAndDownloadPackage = function () {
        var _this = this;
        var now = new Date();
        now.setDate(now.getDate() + OFFBOARDING_PACKAGE_EXPIRATION_IN_DAYS);
        this.dialogsService
            .confirm({
            title: this.i18nService.get('endpointManagement.agent.offboarding.confirm.title'),
            text: this.i18nService.get('endpointManagement.agent.offboarding.confirm.description', {
                date: this.tzDateService.format(now, 'shortDate'),
            }),
            confirmText: this.i18nService.get('endpointManagement.agent.offboarding.confirm.yes'),
        })
            .then(function (e) { return e.confirmed && _this.doDownload(); });
    };
    SenseOffboardingComponent.prototype.doDownload = function () {
        var _this = this;
        this.endpointManagementService.downloadSensePackage(false, this.currentMethod.id).subscribe(function (res) {
            _this.appInsights.trackEvent('EndpointOffboarding-DownloadPackage', {
                ResponseStatus: String(200),
            });
            window.location.href = res;
        }, function (error) {
            _this.dialogsService.showError({
                title: _this.i18nService.get('endpointManagement.download.package.failed'),
                data: error,
            });
            _this.appInsights.trackEvent('EndpointOffboarding-DownloadPackage', {
                ResponseStatus: String(error.status),
                FailureType: 'API',
            });
        });
    };
    return SenseOffboardingComponent;
}());
export { SenseOffboardingComponent };
