import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Machine, SoftwareInstallationAgg } from '@wcd/domain';
import { Paris, Repository } from '@microsoft/paris';
import { MachinesService } from '../../../../machines/services/machines.service';

@Component({
	selector: 'software-installation-agg-entity-panel',
	template: `
		<section class="wcd-padding-vertical wcd-padding-large-horizontal">
			<h4 class="definition-list-title">
				{{ 'machines_entityDetails_sections_details' | i18n }}
			</h4>
			<machine-entity-details
				[machine]="machine"
				(loggedOnUsersClick)="showLoggedOnUsers()"
				[includeMachineName]="true"
				[collapsibleSections]="false"
			></machine-entity-details>
		</section>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftwareInstallationAggEntityPanelComponent extends EntityPanelComponentBase<
	SoftwareInstallationAgg
> {
	public machine: Machine;
	machineRepository: Repository<Machine>;

	constructor(
		paris: Paris,
		private machinesService: MachinesService,
		changeDetectorRef: ChangeDetectorRef
	) {
		super(changeDetectorRef);
		this.machineRepository = paris.getRepository(Machine);
	}

	initEntity(installation: SoftwareInstallationAgg, isExtendedData: boolean = false) {
		super.initEntity(installation, isExtendedData);
		this.machineRepository.getItemById(installation.assetId).subscribe(m => {
			this.machine = m;
			this.changeDetectorRef.markForCheck();
		});
	}

	showLoggedOnUsers() {
		this.machinesService.showMachineLoggedOnUsers(this.machine, true, {
			noShadow: true,
			hasCloseButton: false,
		});
	}
}
