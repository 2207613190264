import { Entity, EntityField, EntityModelBase, ParisConfig, DataSet, HttpOptions } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { EventHubProperties } from './event-hub-properties.value-object';
import { LogProperties } from './log-properties.value-object';
import { StorageAccountProperties } from './storage-account-properties.value-object';

@Entity({
	singularName: 'Data export settings',
	pluralName: 'Data export settings',
	endpoint: 'dataexportsettings',
	saveMethod: (item: any, config?: ParisConfig) => 'POST',
	parseSaveQuery: (item: DataExportSettingsEntity) => 'dataexportsettings',
	readonly: true,
	separateArrayParams: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.wdatpApi,
	allItemsEndpointTrailingSlash: false,
	parseDataSet: (response: any): DataSet<any> => {
		return {
			count: response.value.length,
			items: response.value,
		};
	},
})
export class DataExportSettingsEntity extends EntityModelBase<string> {
	@EntityField({ data: 'id' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'designatedTenantId' })
	designatedTenantId: string;

	@EntityField({ data: 'eventHubProperties' })
	eventHubProperties: EventHubProperties;

	@EntityField({ data: 'storageAccountProperties' })
	storageAccountProperties: StorageAccountProperties;

	@EntityField({ data: 'workspaceProperties' })
	workspaceProperties: StorageAccountProperties;

	@EntityField({ data: 'logs', arrayOf: LogProperties })
	logs: Array<LogProperties>;
}
