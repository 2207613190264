import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { PrettyNumberService } from '@wcd/prettify';
import { find, merge, values } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Alert, Severity, SeverityId } from '@wcd/domain';
import { ChartSettings, ChartSettingsSeriesItem, LegendItem } from '@wcd/charts';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService, Feature } from '@wcd/config';
import { EntityPanelsService } from '../../../../../global_entities/services/entity-panels.service';
import { AppInsightsService } from '../../../../../insights/services/app-insights.service';
import { config } from '@wcd/shared';
import { ServiceUrlsService } from '@wcd/app-config';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { TzDateComponent } from '../../../../../shared/components/tz-date.component';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { DataTableField } from '@wcd/datatable';
import { AlertsService } from '../../../../../@entities/alerts/services/alerts.service';
import { I18nService } from '@wcd/i18n';
import { AlertEntityTypeService } from '../../../../../@entities/alerts/services/alert.entity-type.service';

const MAX_ALERTS_WITH_AUTOMATION: number = 5;
const MAX_ALERTS_WITHOUT_AUTOMATION: number = 9;

@Component({
	selector: 'active-alerts-widget',
	template: `
		<ng-container *ngIf="(latestAlertData$ | async)?.length; else noAlerts">
			<div class="wcd-flex-spaceBetween-horizontal wcd-flex-wrap-horizontal wcd-padding-bottom">
				<wcd-pie-chart
					[data]="newAlertsData$ | async"
					*ngIf="(newAlertsData$ | async)"
					[hidden]="error$ | async"
					[settings]="newAlertsPieSettings"
					[allowTitleClick]="true"
					[upperTitle]="newAlertsTitle$ | async"
					(titleClick)="onNewAlertsTitleClick()"
					[ariaLabel]="'alerts_active' | i18n"
				>
				</wcd-pie-chart>
				<wcd-pie-chart
					[data]="inProgressAlertsData$ | async"
					*ngIf="(inProgressAlertsData$ | async)"
					[hidden]="error$ | async"
					[settings]="inProgressAlertsPieSettings"
					[allowTitleClick]="true"
					[upperTitle]="inProgressAlertsTitle$ | async"
					(titleClick)="onInProgressAlertsTitleClick()"
					[ariaLabel]="'alerts_active' | i18n"
				>
				</wcd-pie-chart>
				<wcd-chart-legend [items]="legendData$ | async" [showValues]="true"></wcd-chart-legend>
			</div>
			<wcd-datatable
				[items]="latestAlertData$ | async"
				[columns]="tableColumns"
				[hidden]="error$ | async"
				[selectEnabled]="false"
				[showHeaders]="false"
				(itemClick)="showAlertSidePanel($event.item)"
				[label]="'machines.entityDetails.fields.activeAlerts.title' | i18n"
			></wcd-datatable>
		</ng-container>
		<ng-template #noAlerts>
			<div class="widget-nodata wcd-full-height wcd-flex-center-vertical">
				<div class="widget-nodata-message">
					<div class="widget-nodata-message-icon">
						<wcd-shared-icon [iconName]="'hide'"> </wcd-shared-icon>
					</div>
					<div>
						Wondering where to start?
						<a [routerLink]="'/preferences2/onboarding'">{{
							'onboarding.runDetectionTest.title' | i18n
						}}</a
						>.
					</div>
				</div>
			</div>
		</ng-template>
	`,
	styles: [
		`
			@media (min-width: 1024px) and (max-width: 1350px), (max-width: 700px) {
				chart-legend {
					display: none;
				}
			}
		`,
	],
})
export class ActiveAlertsWidget extends ReportWidgetComponent<ActiveAlertsData, ActiveAlertsBackendData> {
	private maxAlertsInTable: number;

	constructor(
		reportsService: ReportsService,
		private featuresService: FeaturesService,
		private router: Router,
		private paris: Paris,
		private entityPanelsService: EntityPanelsService,
		private appInsightsService: AppInsightsService,
		private appConfigService: AppConfigService,
		private serviceUrlsService: ServiceUrlsService,
		private prettyNumberService: PrettyNumberService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private alertsService: AlertsService,
		private alertEntityTypeService: AlertEntityTypeService,
		private i18nService: I18nService
	) {
		super(reportsService);
		this.maxAlertsInTable = MAX_ALERTS_WITH_AUTOMATION;
	}

	newAlertsData$: Observable<Array<ChartSettingsSeriesItem>> = this.data$.pipe(
		map((data: ActiveAlertsData) => {
			return (
				data &&
				data.newAlertsData.filter(
					(item: ActiveAlertSeriesItem) => item.severity.type !== 'informational'
				)
			);
		})
	);
	newAlertsTitle$: Observable<string> = this.newAlertsData$.pipe(
		map((data: Array<ChartSettingsSeriesItem>) => {
			return this.prettyNumberService.prettyNumber(
				data
					? data.map((item: ActiveAlertSeriesItem) => <number>item.value).reduce((a, b) => a + b, 0)
					: 0
			);
		})
	);
	inProgressAlertsData$: Observable<Array<ChartSettingsSeriesItem>> = this.data$.pipe(
		map((data: ActiveAlertsData) => {
			return (
				data &&
				data.inProgressAlertsData.filter(
					(item: ActiveAlertSeriesItem) => item.severity.type !== 'informational'
				)
			);
		})
	);
	inProgressAlertsTitle$: Observable<string> = this.inProgressAlertsData$.pipe(
		map((data: Array<ChartSettingsSeriesItem>) => {
			return this.prettyNumberService.prettyNumber(
				data
					? data.map((item: ActiveAlertSeriesItem) => <number>item.value).reduce((a, b) => a + b, 0)
					: 0
			);
		})
	);
	legendData$: Observable<Array<LegendItem>> = this.data$.pipe(
		map((data: ActiveAlertsData) => {
			return data && data.legendData;
		})
	);
	latestAlertData$: Observable<Array<any>> = this.data$.pipe(
		map((data: ActiveAlertsData) => {
			return data && data.latestAlertData;
		})
	);

	tableColumns: Array<DataTableField> = DataTableField.fromList([
		{
			id: 'title',
			name: this.i18nService.get('activeAlertsWidget_Title'),
			getDisplay: (item: any) => item.Title,
			icon: {
				fabricIcon: this.globalEntityTypesService.getEntityTypeIcon(Alert),
			},
			getLink: (item: any) => `${this.alertEntityTypeService.entityType.getEntitiesLink([new Alert({id: item.id || item.AlertId})])}`,
			fluidWidth: 1,
		},
		{
			id: 'severity',
			name: this.i18nService.get('activeAlertsWidget_Severity'),
			getDisplay: (item: any) => this.i18nService.get(item.severity.nameI18nKey),
			getCssClass: (item: any) => {
				return `wcd-severity wcd-severity-${item.severity.type}`;
			},
			className: 'nowrap wcd-width-xxs-medium',
		},
		{
			id: 'date',
			name: this.i18nService.get('activeAlertsWidget_Date'),
			component: {
				type: TzDateComponent,
				getProps: (data: { LastSeen: Date }) => ({ date: data.LastSeen }),
			},
			className: 'nowrap wcd-width-xxs-medium text-right',
		},
	]);

	get widgetConfig(): ReportWidgetConfig<ActiveAlertsData, ActiveAlertsBackendData> {
		const rangeInDays: number = this.appConfigService.widgetLookback;
		return {
			id: 'activeAlerts',
			name: this.i18nService.get('widget_title_activeAlerts'),
			rangeInDays: rangeInDays,
			api: {
				url: () => `${this.serviceUrlsService.threatIntel}/Dashboard/GetActiveAlertsDashboard`,
				isExternal: true,
				params: {
					latestAlertCount: this.maxAlertsInTable,
					lookbackInDays: rangeInDays,
					readFromCache: true,
				},
			},
			parseData: this.parseData.bind(this),
		};
	}

	alertsPieSettings: ChartSettings = {
		options: {
			data: {
				colors: {
					High: config.color.highSeverity,
					Medium: config.color.mediumSeverity,
					Low: config.color.lowSeverity,
					Informational: config.color.neutralLight,
				},
				order: null,
			},
			legend: { show: false },
			tooltip: {
				format: {
					value: (value: any, ratio: number) => value,
				},
			},
			size: {
				width: 250,
				height: 200,
			},
		},
	};

	onNewAlertsTitleClick = () => {
		this.goToAlerts({ status: 'New' });
	};

	goToAlerts(filters: { severity?: string; status?: string }) {
		if (filters.severity)
			filters.severity = this.paris.getValue(
				Severity,
				(severity: Severity) => severity.type === filters.severity.toLowerCase()
			).name;

		const filterValues: string = filters
			? Object.keys(filters)
					.map(field => `${field}=${filters[field]}`)
					.join(',')
			: null;

		this.router.navigate([this.alertEntityTypeService.entityType.getEntityDataviewLink()], {
			queryParams: {
				filters: filterValues,
				range: this.widgetConfig.rangeInDays,
			},
		});
	}

	newAlertsPieSettings = merge(
		{
			options: {
				donut: { title: this.i18nService.get('activeAlertsWidget_New') },
				data: {
					onclick: severity => {
						this.goToAlerts({
							status: 'New',
							severity: severity.id,
						});
					},
				},
			},
			tracking: {
				id: `${this.widgetConfig.id}_NewAlerts`,
				type: 'Navigation',
			},
		},
		this.alertsPieSettings
	);

	onInProgressAlertsTitleClick = () => {
		this.goToAlerts({ status: 'InProgress' });
	};

	inProgressAlertsPieSettings = merge(
		{
			options: {
				donut: { title: this.i18nService.get('activeAlertsWidget_InProgress') },
				data: {
					onclick: severity => {
						this.goToAlerts({
							status: 'InProgress',
							severity: severity.id,
						});
					},
				},
			},
			tracking: {
				id: `${this.widgetConfig.id}_NewAlerts`,
				type: 'Navigation',
			},
		},
		this.alertsPieSettings
	);

	showAlertSidePanel(rawAlert) {
		this.paris
			.getRepository(Alert)
			.createItem(rawAlert)
			.subscribe((alert: Alert) => {
				this.entityPanelsService.showEntities(Alert, [alert]);
			});
	}

	parseData(data: ActiveAlertsBackendData): ActiveAlertsData {
		const newAlerts: AlertSummaryBackendItem = find(
			data.AlertSummary,
			(item: AlertSummaryBackendItem) => item.StatusName === 'New'
		);
		const inProgressAlerts: AlertSummaryBackendItem = find(
			data.AlertSummary,
			(item: AlertSummaryBackendItem) => item.StatusName === 'InProgress'
		);

		const alertTypes = {
			newAlertsData:
				newAlerts &&
				newAlerts.ActiveAlerts.map((item: AlertSeverityCount) => {
					return {
						name: this.i18nService.get(`incident.severity.values.${item.SeverityName}`),
						value: item.AlertCount,
						severity: this.paris.getValue(Severity, item.Severity),
					};
				}).sort(
					(a: ActiveAlertSeriesItem, b: ActiveAlertSeriesItem) =>
						a.severity.priority - b.severity.priority
				),
			inProgressAlertsData:
				inProgressAlerts &&
				inProgressAlerts.ActiveAlerts.map((item: AlertSeverityCount) => ({
					name: this.i18nService.get(`incident.severity.values.${item.SeverityName}`),
					value: item.AlertCount,
					severity: this.paris.getValue(Severity, item.Severity),
				})).sort(
					(a: ActiveAlertSeriesItem, b: ActiveAlertSeriesItem) => a.severity.id - b.severity.id
				),
		};

		return {
			...alertTypes,
			latestAlertData: (data.LatestAlerts || []).slice(0, this.maxAlertsInTable).map(alertData => ({
				...alertData,
				severity: this.paris.getValue(Severity, alertData.Severity),
			})),
			legendData:
				((alertTypes.newAlertsData || alertTypes.inProgressAlertsData) &&
					this.parseLegendData([alertTypes.newAlertsData, alertTypes.inProgressAlertsData])) ||
				[],
		};
	}

	private parseLegendData(data: Array<Array<ActiveAlertSeriesItem>>): Array<LegendItem> {
		return values(
			data.reduce((severityCountByType, alertSeriesItems: Array<ActiveAlertSeriesItem>) => {
				alertSeriesItems.forEach((alertSeriesItem: ActiveAlertSeriesItem) => {
					// get existing item. this can happen because there are types that have multiple ids
					const legendItem: LegendItem = severityCountByType[alertSeriesItem.severity.type];
					const count: number =
						((legendItem && legendItem.value) || 0) + Number(alertSeriesItem.value);
					severityCountByType[alertSeriesItem.severity.type] = {
						name: this.i18nService.get(alertSeriesItem.severity.nameI18nKey),
						value: count,
						iconClassName: `color-text-${alertSeriesItem.severity.className}`,
					};
				});

				return severityCountByType;
			}, {})
		);
	}
}

interface AlertSeverityCount {
	Severity: SeverityId;
	SeverityName: string;
	AlertCount: number;
}

interface AlertSummaryBackendItem {
	AlertStatus: number;
	StatusName: string;
	ActiveAlerts: Array<AlertSeverityCount>;
}

interface ActiveAlertsBackendData {
	AlertSummary: Array<AlertSummaryBackendItem>;
	LatestAlerts: Array<any>;
}

interface ActiveAlertSeriesItem extends ChartSettingsSeriesItem {
	severity: Severity;
}

interface ActiveAlertsData {
	latestAlertData: Array<any>;
	legendData: Array<LegendItem>;
	newAlertsData: Array<ActiveAlertSeriesItem>;
	inProgressAlertsData: Array<ActiveAlertSeriesItem>;
}
