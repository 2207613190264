import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentsStateComponent } from './contents-state.component';

@NgModule({
	imports: [CommonModule],
	declarations: [ContentsStateComponent],
	exports: [ContentsStateComponent],
})
export class ContentsStateModule {}
