import { OnDestroy } from '@angular/core';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Subject } from 'rxjs';
var RECENT_TIME_FRAME = 400; // milliseconds
var ActivatedEntity = /** @class */ (function () {
    function ActivatedEntity() {
        this.currentEntity$ = new BehaviorSubject(null);
        this.fullScreenEntity$ = new Subject();
    }
    Object.defineProperty(ActivatedEntity.prototype, "recentEntity", {
        get: function () {
            return this._recentEntity;
        },
        enumerable: true,
        configurable: true
    });
    ActivatedEntity.prototype.setCurrentEntity = function (entity) {
        var currentEntity = this.currentEntity$.getValue();
        if (entity !== currentEntity && !isEqual(entity, currentEntity))
            this.currentEntity$.next(entity);
    };
    ActivatedEntity.prototype.setNextEntity = function (entity) {
        var _this = this;
        this._recentEntity = entity;
        clearTimeout(this._removeRecentEntityTimeout);
        this._removeRecentEntityTimeout = setTimeout(function () { return (_this._recentEntity = null); }, RECENT_TIME_FRAME);
    };
    ActivatedEntity.prototype.ngOnDestroy = function () {
        clearTimeout(this._removeRecentEntityTimeout);
        this.currentEntity$.complete();
        this.fullScreenEntity$.complete();
    };
    return ActivatedEntity;
}());
export { ActivatedEntity };
