export enum HuntingRuleCustomSupportedActionType {
	IsolateMachine = 0,
	CollectInvestigationPackage = 1,
	RunAntivirusScan = 2,
	InitiateInvestigation = 3,
	QuarantineFile = 4,
	AllowFile = 5,
	BlockFile = 6,
	RestrictExecution = 7,
	MarkUserAsCompromised = 8,
	MoveEmailToFolder = 9,
	DeleteEmail = 10,
	BlockEmailSender = 11,
	BlockEmailUrl = 12,
	DisableUser = 13,
	ForceUserPasswordReset = 14,
	StartNewSubmission = 15,
	TriggerInvestigation = 16,
}

export enum CustomActionMachineIsolationType {
	Full = 0,
	Selective = 1,
}

export enum EmailActionType {
	MoveToJunk = 1,
	MoveToInbox = 2,
	DeleteAttachment = 3,
	SubmitForDetonation = 4,
	Delete = 5,
	SoftDelete = 6,
	MoveToDeletedItems = 7,
	ReportClean = 8,
	ReportPhishing = 9,
	ReportMalware = 10,
	ReportSpam = 11,
	InvestigateEmail = 12,
	InvestigateSender = 13,
	InvestigateRecipient = 14,
}

/// <summary>
/// Enum for Spam Type
/// </summary>
export enum EmailSubmissionCategory {
	NotJunk = 0,
	Spam = 1,
	Phishing = 2,
	Malware = 3,
}

/// <summary>
/// Enum for submission reason
/// </summary>
export enum EmailSubmissionReason {
	FN = 1,
	FP = 2,
}

/// <summary>
/// Enum for Email submission object
/// </summary>
export enum EmailSubmissionType {
	Undefined = 0,
	NetworkMessageId = 1,
	MailFile = 2,
	MessageId = 3,
}

/// <summary>
/// Enum for submission object
/// </summary>
export enum EmailSubmissionObjectType {
	Mail = 1,
	URL = 2,
	Attachment = 3,
}

export enum CustomActionAssetType {
	Machine = 'hunting.customDetections.actionCategories.machine',
	File = 'hunting.customDetections.actionCategories.file',
	User = 'hunting.customDetections.actionCategories.user',
	Email = 'hunting.customDetections.actionCategories.email',
	Url = 'hunting.customDetections.actionCategories.url',
}

export const huntingRuleCustomActionTypeValues: Array<HuntingRuleCustomActionType> = [
	{
		id: HuntingRuleCustomSupportedActionType.IsolateMachine,
		category: CustomActionAssetType.Machine,
		displayName: 'Isolate Device',
	},
	{
		id: HuntingRuleCustomSupportedActionType.QuarantineFile,
		category: CustomActionAssetType.File,
		displayName: 'Quarantine File',
	},
	{
		id: HuntingRuleCustomSupportedActionType.InitiateInvestigation,
		category: CustomActionAssetType.Machine,
		displayName: 'Initiate Investigation',
	},
	{
		id: HuntingRuleCustomSupportedActionType.CollectInvestigationPackage,
		category: CustomActionAssetType.Machine,
		displayName: 'Collect Investigation Package',
	},
	{
		id: HuntingRuleCustomSupportedActionType.RunAntivirusScan,
		category: CustomActionAssetType.Machine,
		displayName: 'Run Antivirus Scan',
	},
	{
		id: HuntingRuleCustomSupportedActionType.AllowFile,
		category: CustomActionAssetType.File,
		displayName: 'Allow File',
	},
	{
		id: HuntingRuleCustomSupportedActionType.BlockFile,
		category: CustomActionAssetType.File,
		displayName: 'Block File',
	},
	{
		id: HuntingRuleCustomSupportedActionType.RestrictExecution,
		category: CustomActionAssetType.Machine,
		displayName: 'Restrict App Execution',
	},
	{
		id: HuntingRuleCustomSupportedActionType.MarkUserAsCompromised,
		category: CustomActionAssetType.User,
		displayName: 'Mark User as Compromised',
	},
];

export interface HuntingRuleCustomActionType {
	id: HuntingRuleCustomSupportedActionType;
	category: CustomActionAssetType;
	displayName: string;
}

export interface HuntingRuleCustomActionContract {
	MachineColumnName: string;
	FileColumnName: string;
	RbacGroupIds: number[];
	ActionType: HuntingRuleCustomSupportedActionType;
	MachineIsolationType: CustomActionMachineIsolationType;
}

export interface HuntingRuleCustomAction {
	actionType: HuntingRuleCustomSupportedActionType;
	active: boolean;
	rbacGroupIds?: number[];
	fileColumnName?: string;
	machineColumnName?: string;
	displayName: string;
	machineIsolationType?: CustomActionMachineIsolationType;
	category?: CustomActionAssetType;
}

export interface HuntingRuleCustomActions {
	[actionType: string]: HuntingRuleCustomAction;
}

function customActionsAsMap(
	customActions: HuntingRuleCustomActionContract[]
): { [key: string]: HuntingRuleCustomActionContract } {
	return customActions
		? customActions.reduce((acc, item) => {
				acc[item.ActionType] = item;
				return acc;
		  }, {})
		: {};
}

export function parseCustomActions(
	customActions: HuntingRuleCustomActionContract[]
): HuntingRuleCustomActions {
	const actions = customActionsAsMap(customActions);
	return huntingRuleCustomActionTypeValues.reduce((acc, actionType) => {
		const action: HuntingRuleCustomActionContract = actions[actionType.id];
		acc[actionType.id] = {
			displayName: actionType.displayName,
			actionType: actionType.id,
			category: actionType.category,
			rbacGroupIds: action ? action.RbacGroupIds : null,
			fileColumnName: action ? action.FileColumnName : null,
			machineColumnName: action ? action.MachineColumnName : null,
			machineIsolationType: action ? action.MachineIsolationType : null,
			active: typeof action === 'object',
		};
		return acc;
	}, <HuntingRuleCustomActions>{});
}

export function serializeCustomActions(
	customActions: HuntingRuleCustomActions
): HuntingRuleCustomActionContract[] {
	return Object.values(customActions)
		.filter((action) => action.active)
		.map(({ actionType, rbacGroupIds, fileColumnName, machineColumnName, machineIsolationType }) => ({
			ActionType: actionType,
			RbacGroupIds: rbacGroupIds,
			FileColumnName: fileColumnName,
			MachineColumnName: machineColumnName,
			MachineIsolationType: machineIsolationType,
		}));
}
