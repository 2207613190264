import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';
import { Router } from '@angular/router';
import { CustomConfirmComponent } from '../../../dialogs/models/custom-confirm-options';
import { RemediationRejectData } from '../services/remediation-reject-reason.interface';

@Component({
	selector: 'remediation-decline-modal',
	template: `
		<modal (close)="onClose()" [settings]="settings">
			<confirm-modal-content
				class="wcd-flex-1 ie11Patch ie11Flex"
				[confirmButtonOptions]="{
					disabled: isSaving || !declineReason
				}"
				[isSaving]="isSaving"
				(onCancel)="onCancelHandler()"
				(onConfirm)="onConfirmHandler()"
			>
				<div style="min-width: 400px">
					<remediation-decline-reason [(ngModel)]="declineReason"> </remediation-decline-reason>
				</div>
			</confirm-modal-content>
		</modal>
	`,
})
export class RemediationDeclineModalComponent extends ModalContainer
	implements CustomConfirmComponent<RemediationRejectData>, OnInit {
	@Input() title: string;

	@Output() readonly onConfirm: EventEmitter<RemediationRejectData> = new EventEmitter();
	@Output() readonly onCancel: EventEmitter<void> = new EventEmitter();

	isSaving: boolean = false;
	declineReason: RemediationRejectData;

	constructor(protected router: Router) {
		super(router);
	}

	ngOnInit() {
		this.settings = {
			...this.settings,
			className: 'wcd-flex-vertical',
			title: this.title,
		};
	}

	onCancelHandler() {
		this.onCancel.emit();
		this.onCancel.complete();
	}

	onConfirmHandler() {
		this.onConfirm.emit(this.declineReason);
		this.onConfirm.complete();
	}

	onClose() {
		this.onCancelHandler();
		this.destroy();
	}
}
