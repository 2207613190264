/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i3 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i5 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./mailbox-details-field.component";
var styles_MailboxDetailsFieldComponent = [];
var RenderType_MailboxDetailsFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MailboxDetailsFieldComponent, data: {} });
export { RenderType_MailboxDetailsFieldComponent as RenderType_MailboxDetailsFieldComponent };
function View_MailboxDetailsFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t", "\n\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayQuery; _ck(_v, 1, 0, currVal_0); }); }
function View_MailboxDetailsFieldComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(5, null, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent.parent, 0), "actionCenter.details.email_remediation.from")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.senderEmailAddress; _ck(_v, 5, 0, currVal_1); }); }
function View_MailboxDetailsFieldComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(5, null, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent.parent, 0), "actionCenter.details.email_remediation.to")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.recipientEmailAddress; _ck(_v, 5, 0, currVal_1); }); }
function View_MailboxDetailsFieldComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 9, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MailboxDetailsFieldComponent_4)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MailboxDetailsFieldComponent_5)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.senderEmailAddress; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.recipientEmailAddress; _ck(_v, 10, 0, currVal_1); }, null); }
function View_MailboxDetailsFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MailboxDetailsFieldComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.senderEmailAddress || _co.recipientEmailAddress); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MailboxDetailsFieldComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.I18nPipe, [i3.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 7, "span", [], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 147456, null, 0, i4.TooltipDirective, [i0.ElementRef, i5.TooltipsService, i6.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], wcdTooltipAllowHtmlRendering: [1, "wcdTooltipAllowHtmlRendering"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MailboxDetailsFieldComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(0, [["emailMessage", 2]], null, 0, null, View_MailboxDetailsFieldComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showTooltip ? _co.tooltip : null); var currVal_1 = (_co.showTooltip ? true : null); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.displayQuery; var currVal_3 = i0.ɵnov(_v, 8); _ck(_v, 6, 0, currVal_2, currVal_3); }, null); }
export function View_MailboxDetailsFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mailbox-details-field", [], null, null, null, View_MailboxDetailsFieldComponent_0, RenderType_MailboxDetailsFieldComponent)), i0.ɵdid(1, 573440, null, 0, i7.MailboxDetailsFieldComponent, [i0.ElementRef, i0.ChangeDetectorRef], null, null)], null, null); }
var MailboxDetailsFieldComponentNgFactory = i0.ɵccf("mailbox-details-field", i7.MailboxDetailsFieldComponent, View_MailboxDetailsFieldComponent_Host_0, { displayQuery: "displayQuery", senderEmailAddress: "senderEmailAddress", recipientEmailAddress: "recipientEmailAddress", showTooltip: "showTooltip" }, {}, []);
export { MailboxDetailsFieldComponentNgFactory as MailboxDetailsFieldComponentNgFactory };
