/* tslint:disable:template-accessibility-label-for */
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { ChecklistValue } from '@wcd/forms';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import {
	HuntingCustomAction,
	HuntingQueryMtpWorkloadsApiCall,
	HuntingQueryMtpWorkloadsApiCallVNext,
	HuntingQuerySupportedAction,
	HuntingQuerySupportedActionsApiCall,
	HuntingQuerySupportedImpactedEntitiesApiCall,
	HuntingRule,
	HuntingRuleAlertCategory,
	HuntingRuleEntityType,
	HuntingRuleImpactedEntity,
	HuntingRuleQueryIsMdatpApiCall,
	HuntingRuleQueryIsMdatpVNextApiCall,
	MachineGroup,
	MtpWorkload,
	Outbreak,
	Severity,
} from '@wcd/domain';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { AuthService, MtpPermission, tenantContextCache } from '@wcd/auth';
import { ScheduledHuntingErrorInvalidSchema, ScheduledHuntingErrorParsingService } from '@wcd/hunting';
import { groupBy, isNil, lowerFirst, orderBy, sortBy } from 'lodash-es';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { HuntingRuleFrequency } from '../models/hunting-rule-frequency.model';
import { WizardStepModel, WizardStepModelConfig } from '../../../shared/components/wizard/wizard-step.model';
import { DataTableField } from '@wcd/datatable';
import { MitreTechniqueDisplayPipe } from '../../../shared/pipes/mitre-technique-display.pipe';
import { HuntingRuleScope } from '../models/hunting-rule-scope.model';
import { RbacService } from '../../../rbac/services/rbac.service';
import { ConfirmationDialogService } from '@wcd/dialogs';
import { CATEGORY_TO_MITRE_TECHNIQUES_MAPPING } from '@wcd/scc-common';
import { AppConfigData } from '@wcd/app-config';
import { AppFlavorConfig } from '@wcd/scc-common';

const HUNTING_RULE_FREQUENCIES = [24, 12, 3, 1];

class WizardStep extends WizardStepModel {
	constructor(config: WizardStepConfig) {
		super(config);
		this.validation = config.validation;
	}

	validation?: () => boolean;
	get valid(): boolean {
		return this.validation ? this.validation() : true;
	}
}

interface WizardStepConfig extends WizardStepModelConfig {
	validation?: () => boolean;
}

enum WizardStepId {
	AlertDetails = 1,
	ImpactedEntities = 2,
	CustomActions = 3,
	Scope = 4,
	Summary = 5,
}

interface ImpactedEntitySelection {
	entityType: HuntingRuleEntityType;
	entities: Array<HuntingRuleImpactedEntity>;
	selectedEntity: HuntingRuleImpactedEntity;
	checked: boolean;
	name: string;
}

@Component({
	selector: 'scheduled-hunting-rule-edit',
	templateUrl: './scheduled-hunting-rule-edit.component.html',
})
export class ScheduledHuntingRuleEditComponent
	extends PanelContainer
	implements OnInit, OnChanges, OnDestroy {
	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private errorParsingService: ScheduledHuntingErrorParsingService,
		private featuresService: FeaturesService,
		private rbacService: RbacService,
		private authService: AuthService,
		private appInsightsService: AppInsightsService,
		private appContextService: AppContextService,
		private changeDetector: ChangeDetectorRef,
		private mitreTechniqueDisplayPipe: MitreTechniqueDisplayPipe,
		private readonly confirmationDialogService: ConfirmationDialogService,
		router: Router
	) {
		super(router);
		const tenantContext: AppConfigData = tenantContextCache.appConfig;

		this.isCustomActionsFeatureEnabled = this.featuresService.isEnabled(Feature.HuntingCustomActions);
		this.huntingRuleFrequencyEnabled = this.featuresService.isEnabled('HuntingRuleFrequency');
		this.huntingCustomDetectionMitreDropDownEnabled = this.featuresService.isEnabled(
			Feature.HuntingCustomDetectionMitreDropdown
		);
		this.showImpactedEntities =
			appContextService.isSCC && featuresService.isEnabled(Feature.HuntingCustomDetectionUsingEvidence);

		this.threatAnalyticsCustomDetectionFeatureEnabled =
			featuresService.isEnabled(Feature.ThreatAnalyticsCustomDetection) &&
			((tenantContext.IsMdatpActive &&
				(AppFlavorConfig.threatAnalytics.mde.mdeFlavors || []).includes(tenantContext.MdeFlavor)) ||
				tenantContext.IsOatpActive);

		this.shouldDropdownBeActive =
			(tenantContext.MdatpMtpPermissions || []).includes(MtpPermission.SecurityData_Read) ||
			(tenantContext.OatpMtpPermissions || []).includes(MtpPermission.SecurityData_Read);

		this.huntingRuleRepo = this.paris.getRepository(HuntingRule);
	}

	@Input() huntingRule: HuntingRule;
	@Input() queryText: string;

	@Output() readonly itemSaved = new EventEmitter<HuntingRule>();

	@ViewChild('alertDetailsStepForm', { static: false }) readonly alertDetailsStepForm: NgForm;
	@ViewChild('entitiesStepForm', { static: false }) readonly entitiesStepForm: NgForm;
	@ViewChild('actionsStepForm', { static: false }) readonly actionsStepForm: NgForm;
	@ViewChild('scopeStepForm', { static: false }) readonly scopeStepForm: NgForm;

	huntingRuleRepo: Repository<HuntingRule>;
	availableAlertSeverities: ReadonlyArray<Severity>;
	huntingRuleFrequencies: HuntingRuleFrequency[];
	huntingRuleFrequencyEnabled: boolean;
	huntingCustomDetectionMitreDropDownEnabled: boolean;
	ALL_SCOPES = HuntingRuleScope.all.valueOf();

	private _selectedFrequency: HuntingRuleFrequency;

	supportedAlertCategories: ReadonlyArray<HuntingRuleAlertCategory>;
	isSaving = false;
	createMonitorMode = true;
	alertCategory: HuntingRuleAlertCategory;
	selectableMitreTechniques: Array<DataTableField>;
	threat: Outbreak;

	error: string;
	queryFieldsNames: ReadonlyArray<string>;
	queryFields: Record<string, boolean>;
	fieldsAreMissing: boolean;
	destroyed$: ReplaySubject<any> = new ReplaySubject();

	// Machine groups support
	// tenant/user state
	isRbacFilteringNecessary$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	machineGroups$: BehaviorSubject<Array<MachineGroup>> = new BehaviorSubject([]);
	machineGroupsChecklistValues$: Observable<Array<ChecklistValue>>;
	// groups data

	readonly allSelectableScopes: Array<ChecklistValue> = [
		{
			id: HuntingRuleScope.all,
			name: this.i18nService.get('hunting.scheduledMonitorSidePane.fields.machineGroups.all'),
		},
		{
			id: HuntingRuleScope.specific,
			name: this.i18nService.get('hunting.scheduledMonitorSidePane.fields.machineGroups.specific'),
		},
	];
	allMachineGroups: Array<MachineGroup>;
	selectableScopes = this.allSelectableScopes;
	// UX state
	stepsData: WizardStep[];
	currentStep: WizardStepModel;
	WizardStepId = WizardStepId;
	isLoadingMachinesScopes = true;
	huntingRuleScope: ChecklistValue;
	currentSelectedMachineGroupsIds: Array<number> = [];
	disableGroupsDropdown = true;

	// Custom Actions
	isCustomActionsFeatureEnabled: boolean;
	summarizedActionsColumns: Array<DataTableField<HuntingCustomAction>>;
	supportedCustomActions: Array<HuntingQuerySupportedAction>;

	// Threat Analytic Report
	threats: Array<Outbreak>;
	threatAnalyticsCustomDetectionFeatureEnabled: boolean;
	shouldDropdownBeActive: boolean;

	// Impacted entities
	impactedEntityCategories: Array<ImpactedEntitySelection>;
	readonly showImpactedEntities: boolean;
	impactedEntitiesColumns: Array<DataTableField<HuntingRuleImpactedEntity>>;
	summarizedActions: HuntingCustomAction[];
	private editorMediaWidthQueryList: MediaQueryList;

	ngOnInit() {
		this.createMonitorMode = !this.huntingRule;

		this.availableAlertSeverities = this.getAvailableAlertSeverities();

		if (this.huntingRuleFrequencyEnabled) {
			this.huntingRuleFrequencies = this.getRuleFrequencies();
			this.selectedFrequency =
				this.huntingRule &&
				this.huntingRuleFrequencies.find((freq) => freq.id === this.huntingRule.intervalHours);
		}

		this.supportedAlertCategories = this.getSupportedAlertCategories();
		this.getAlertCategoryLabel = this.getAlertCategoryLabel.bind(this);

		// edit mode - load rule from BE based on query id
		if (this.createMonitorMode) {
			this.huntingRule = this.createNewHuntingRule();
		} else {
			this.huntingRule = { ...this.huntingRule }; // create a copy, so the wizard won't change the real object

			this.alertCategory = this.huntingRule.alertCategory;
			//we have to remove undefined machine groups because of a server bug that
			//returns deleted machine group ids in the hunting rule
			this.currentSelectedMachineGroupsIds = this.huntingRule.machineGroups
				.filter(Boolean)
				.map((a) => a.id);

			this.selectableMitreTechniques = this.getSelectableMitreTechniques(this.alertCategory);
		}

		this.setupWizard();
		this.loadMachineGroups();
		this.setMachineGroupScope();
		this.initializeCustomActions();
		this.initializeImpactedEntities();
		this.setSummarizedActions();

		if (this.threatAnalyticsCustomDetectionFeatureEnabled) {
			this.loadThreats();
			this.loadRuleThreat();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.huntingRule) {
			this.setSummarizedActions();
		}
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	private setSummarizedActions(): void {
		this.summarizedActions = this.huntingRule.actions || [];
	}

	private getSupportedAlertCategories() {
		// list enum values
		return Object.keys(HuntingRuleAlertCategory)
			.map((k) => HuntingRuleAlertCategory[k])
			.sort();
	}

	private getAvailableAlertSeverities() {
		return this.paris
			.getRepository<Severity>(Severity)
			.entity.values.filter((alertSeverity: Severity) => alertSeverity.isSelectable)
			.sort((a: Severity, b: Severity) => a.priority - b.priority);
	}

	fixDataClassListener = (e) => {
		e.matches ? this.fixDataClass('wcd-padding-small-top') : this.fixDataClass('wcd-padding-large-top');
	};

	fixDataClass(className) {
		this.stepsData = this.stepsData.map((item) => {
			item.className = className;
			return item;
		});
	}

	private setupWizard() {
		const showCustomActions =
			this.featuresService.isEnabled(Feature.HuntingCustomActions) &&
			(!this.appContextService.isSCC ||
				this.featuresService.isEnabled(Feature.HuntingCustomActionsMTP));

		this.stepsData = [
			new WizardStep({
				id: WizardStepId.AlertDetails,
				subText: this.i18nService.get(
					'hunting.scheduledMonitorSidePane.wizardSections.alertDetails.title'
				),
				show: true,
				validation: () => this.alertDetailsStepForm && this.alertDetailsStepForm.form.valid,
			}),
			this.showImpactedEntities
				? new WizardStep({
						id: WizardStepId.ImpactedEntities,
						subText: this.i18nService.get(
							'hunting.scheduledMonitorSidePane.wizardSections.entities.title'
						),
						show: true,
						validation: () =>
							this.entitiesStepForm &&
							this.entitiesStepForm.form.valid &&
							this.huntingRule.impactedEntities.length > 0,
				  })
				: null,
			showCustomActions
				? new WizardStep({
						id: WizardStepId.CustomActions,
						subText: this.i18nService.get(
							'hunting.scheduledMonitorSidePane.wizardSections.actions.title'
						),
						show: false, // actions are initially hidden
						validation: () => this.actionsStepForm && this.actionsStepForm.form.valid,
				  })
				: null,
			new WizardStep({
				id: WizardStepId.Scope,
				subText: this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.scope.title'),
				show: false, // scope is initially hidden
				validation: () => this.scopeStepForm && this.scopeStepForm.form.valid,
			}),
			new WizardStep({
				id: WizardStepId.Summary,
				subText: this.i18nService.get(
					'hunting.scheduledMonitorSidePane.wizardSections.summary.title'
				),
				actionButtonSettings: {
					label: this.i18nService.get(
						'hunting.scheduledMonitorSidePane.buttons.' +
							(this.createMonitorMode ? 'create' : 'save')
					),
					onActionButtonClick: () => this.saveScheduledQuery().then(() => this.closePanel()),
				},
				show: true,
			}),
		].filter(Boolean);

		this.currentStep = this.stepsData.find((step) => step.show);

		this.summarizedActionsColumns = DataTableField.fromList<HuntingCustomAction>([
			{
				id: 'action',
				name: this.i18nService.get(
					'hunting.scheduledMonitorSidePane.wizardSections.summary.actionsTable.action'
				),
				getDisplay: (item) => {
					return this.i18nService.get(`hunting.customDetections.actions.${item.actionType}`);
				},
				fluidWidth: 0.5,
				sort: { enabled: false },
			},
			{
				id: 'asset',
				name: this.i18nService.get(
					'hunting.scheduledMonitorSidePane.wizardSections.summary.actionsTable.asset'
				),
				getDisplay: (item) =>
					item.entities &&
					item.entities
						.map((entity) =>
							this.i18nService.get(
								'hunting.scheduledMonitorSidePane.wizardSections.entities.' +
									lowerFirst(entity.entityType)
							)
						)
						.join(', '),
				fluidWidth: 0.5,
				sort: { enabled: false },
			},
		]);
	}

	private showOrHideSteps(changes: { [index: number]: boolean }) {
		if (changes) {
			for (const step of this.stepsData) {
				if (changes[step.id] != null) {
					step.show = changes[step.id];
				}
			}

			this.stepsData = [...this.stepsData];
		}
	}

	onClosePanel(e?) {
		event.preventDefault();
		event.stopPropagation();
		this.closePanel();
	}

	createNewHuntingRule(): HuntingRule {
		const huntingRule = this.huntingRuleRepo.createNewItem();
		huntingRule.isEnabled = true;
		return huntingRule;
	}

	onCategoryChanged($event: HuntingRuleAlertCategory) {
		if (this.huntingRule.alertCategory == $event) {
			return;
		}

		this.huntingRule.alertCategory = $event;

		this.huntingRule.mitreTechniques = null; // on category change, reset the MITRE techniques selection
		this.selectableMitreTechniques = this.getSelectableMitreTechniques($event);
	}

	onThreatChanged($event: Outbreak) {
		this.huntingRule.threatId = $event.id;
		this.threat = $event;
	}

	getSelectableMitreTechniques(alertCategory: HuntingRuleAlertCategory) {
		return (
			alertCategory &&
			CATEGORY_TO_MITRE_TECHNIQUES_MAPPING[alertCategory] &&
			sortBy(
				CATEGORY_TO_MITRE_TECHNIQUES_MAPPING[alertCategory].map((technique) => ({
					id: technique,
					name: this.mitreTechniqueDisplayPipe.transform(technique, true),
				})),
				(techniqueField) => this.mitreTechniqueDisplayPipe.transform(techniqueField.id, false)
			)
		);
	}

	getMitreTechniqueDropDownPlaceHolder(): string {
		if (!this.huntingRule.mitreTechniques || !this.huntingRule.mitreTechniques.length) {
			return this.i18nService.get(
				'hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholder'
			);
		}

		return this.huntingRule.mitreTechniques.length > 1
			? this.i18nService.get(
					'hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholderWithSelection.plural',
					{ count: this.huntingRule.mitreTechniques.length }
			  )
			: this.i18nService.get(
					'hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholderWithSelection.singular'
			  );
	}

	async saveScheduledQuery() {
		this.isSaving = true;
		this.fieldsAreMissing = false;

		let machineGroups: Array<MachineGroup>;
		if (!this.isRbacFilteringNecessary$.value || this.huntingRuleScope.id === HuntingRuleScope.all) {
			machineGroups = []; // no groups specified == all machine groups
		} else if (this.currentSelectedMachineGroupsIds.length) {
			machineGroups = this.machineGroups$.value.filter((group) =>
				this.currentSelectedMachineGroupsIds.includes(group.id)
			);
		} else {
			this.appInsightsService.trackException(
				new Error(
					'No device groups were selected for Hunting rule scope while user is expected to select specific groups.'
				)
			);
		}

		this.huntingRule.machineGroups = machineGroups;
		this.huntingRule.intervalHours = !isNil(this.huntingRule.intervalHours) ? this.huntingRule.intervalHours : 24;

		try {
			if (await this.shouldMigrateRuleToMtp()) {
				const doesUserApproveChange = await this.showMtpEditRuleDialog(this.huntingRule);

				if (!doesUserApproveChange) {
					this.closePanel();
					return;
				}
			}
		} catch (err) {
			this.parseError(err);
			return;
		}

		await this.huntingRuleRepo
			.save(this.huntingRule, null, { queryText: this.queryText })
			.pipe(
				catchError((err: HttpErrorResponse) => {
					this.parseError(err);

					return throwError(err);
				})
			)
			.toPromise()
			.then((savedRule) => {
				this.itemSaved.emit(savedRule);
				this.itemSaved.complete();
			})
			.catch((error: HttpErrorResponse) => {});
		this.huntingRuleRepo.clearCache();
	}

	private loadMachineGroups(): any {
		const apiCall = this.shouldMigrateQueriesApiToVNext
			? HuntingQueryMtpWorkloadsApiCallVNext
			: HuntingQueryMtpWorkloadsApiCall;
		const mtpWorkloads$ = this.queryText
			? this.paris.apiCall(apiCall, this.queryText).pipe(take(1))
			: of(this.huntingRule.mtpWorkloads);

		mtpWorkloads$
			.pipe(
				switchMap((mtpWorkloads: Array<MtpWorkload>) => {
					return mtpWorkloads.includes(MtpWorkload.Mdatp)
						? this.rbacService.isFilteringNecessary$
						: of(false); // machines group filtering isn't relevant if the rule's query doesn't include any device table
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe(this.isRbacFilteringNecessary$);

		this.rbacService.userExposedRbacGroups$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(this.machineGroups$);

		this.machineGroupsChecklistValues$ = this.machineGroups$.pipe(
			map((machineGroups: Array<MachineGroup>) => {
				const checklistValues = machineGroups.map((group) => ({
					id: group.id,
					name: group.isUnassignedMachineGroup
						? this.i18nService.get('machineGroup.unassignedGroup.name')
						: group.name,
				}));
				return orderBy(checklistValues, [(group) => group.name.toLowerCase()]);
			})
		);

		this.isRbacFilteringNecessary$.subscribe((isRbacFilteringNecessary: boolean) =>
			this.showOrHideSteps({ [WizardStepId.Scope]: isRbacFilteringNecessary })
		);

		this.machineGroups$.subscribe(
			() => {
				this.setSelectableScopes();
				this.isLoadingMachinesScopes = false;
			},
			(error) => {
				this.isLoadingMachinesScopes = false;
			}
		);
	}

	private loadThreats() {
		this.paris.getRepository(Outbreak).allItems$.subscribe((outbreaks) => {
			const nullableOutbreak = new Outbreak({ id: null });
			nullableOutbreak.displayName = 'None';

			const currentThreats = [nullableOutbreak, ...outbreaks];
			this.threats = currentThreats.sort((a, b) => a.displayName.localeCompare(b.displayName));
		});
	}

	private loadRuleThreat() {
		if (this.huntingRule.threatId) {
			this.paris
				.getItemById(Outbreak, this.huntingRule.threatId)
				.subscribe((outbreak: Outbreak) => (this.threat = outbreak));
		}
	}

	getRuleFrequencies() {
		const frequencies = HUNTING_RULE_FREQUENCIES.map((freq) => ({
			name: this.i18nService.get(
				`hunting.scheduledMonitorSidePane.fields.huntingRuleFrequency.everyFrequencyHours.${
					+freq > 1 ? 'plural' : 'singular'
				}`,
				{ freq }
			),
			id: freq,
		}));

		if (this.featuresService.isEnabled(Feature.HuntingStreamingDetection)) {
			frequencies.push({ name: this.i18nService.get('hunting.scheduledMonitorSidePane.fields.huntingRuleFrequency.continuous'), id: 0});
		}

		return frequencies;
	}

	private parseError(err) {
		this.error = this.i18nService.get('hunting.scheduledMonitorSidePane.errors.generic');
		if (err.status === 409) {
			this.error = this.i18nService.get('hunting.scheduledMonitorSidePane.errors.duplicateMonitor');
		} else if (err.status === 400 && err.message && err.message.Type) {
			try {
				const scheduledHuntingError = this.errorParsingService.parseError(err);
				this.error = scheduledHuntingError.errorMessage;

				if (scheduledHuntingError.type === 'InvalidSchema') {
					const invalidSchemaError = scheduledHuntingError as ScheduledHuntingErrorInvalidSchema;
					this.queryFields = invalidSchemaError.requiredQueryFields;
					this.queryFieldsNames = invalidSchemaError.requiredQueryFieldNames;
					this.fieldsAreMissing = invalidSchemaError.areFieldsMissing;
				}
			} catch (e) {
				this.error = this.error || err.error;
			}
		} else {
			this.error = this.error || err.error;
		}
	}

	setSelectableScopes(): void {
		if (!this.authService.currentUser.isMdeAdmin) {
			this.selectableScopes = this.allSelectableScopes.filter(
				(item) => item.id === HuntingRuleScope.specific
			);
		}
		this.setMachineGroupScope();
	}

	onRuleScopeChange(selectedScope: ChecklistValue) {
		this.disableGroupsDropdown = selectedScope.id === HuntingRuleScope.all;
		this.changeDetector.detectChanges();
	}

	setMachineGroupScope() {
		const scopeId = this.huntingRule.machineGroups && this.huntingRule.machineGroups.length > 0;
		this.huntingRuleScope =
			this.selectableScopes.length === 1 ? this.selectableScopes[0] : this.selectableScopes[+scopeId];
		this.onRuleScopeChange(this.huntingRuleScope);
	}

	onMachineGroupsChange(selectedGroupIds: number[]) {
		this.currentSelectedMachineGroupsIds = [...selectedGroupIds];

		this.changeDetector.detectChanges();
		setTimeout(() => {
			this.changeDetector.detectChanges();
		});
	}

	getGroupsDropdownPlaceholder(selectedGroupIds: number[]): string {
		const numSelectedGroups = selectedGroupIds ? selectedGroupIds.length : 0;
		return numSelectedGroups > 0
			? this.i18nService.get(
					`hunting.scheduledMonitorSidePane.fields.machineGroups.dropdown.placeholderWithInfo.${
						numSelectedGroups === 1 ? 'singular' : 'plural'
					}`,
					{
						numGroups: numSelectedGroups,
					}
			  )
			: this.i18nService.get(
					'hunting.scheduledMonitorSidePane.fields.machineGroups.dropdown.placeholder'
			  );
	}

	getAlertCategoryLabel(alertCategory: HuntingRuleAlertCategory): string {
		return this.i18nService.get(`reporting.alertsByCategory.${lowerFirst(alertCategory)}`);
	}

	getThreatAnalyticsLabel(threat: Outbreak): string {
		return threat.displayName;
	}

	private initializeCustomActions() {
		this.paris
			.apiCall<HuntingQuerySupportedAction[]>(HuntingQuerySupportedActionsApiCall, {
				Id: this.huntingRule.queryId,
				QueryText: this.queryText,
			})
			.pipe(take(1))
			.subscribe((supportedCustomActions) => {
				this.supportedCustomActions = supportedCustomActions;

				const showActionsStep =
					supportedCustomActions &&
					supportedCustomActions.some((action) => action.isEnabled) &&
					this.authService.currentUser.hasRequiredMtpPermission(
						MtpPermission.SecurityData_Remediate
					);
				this.showOrHideSteps({ [WizardStepId.CustomActions]: showActionsStep });
			});
	}

	onCustomActionsChange(actions: Array<HuntingCustomAction>) {
		this.setSummarizedActions();
		this.changeDetector.detectChanges();
	}

	private initializeImpactedEntities() {
		// get possible selections of impacted entities based on query text
		const supportedImpactedEntitiesCall = this.paris
			.apiCall(HuntingQuerySupportedImpactedEntitiesApiCall, {
				Id: this.huntingRule.queryId,
				QueryText: this.queryText,
			})
			.pipe(
				map((possibleImpactedEntities) => {
					const huntingRuleImpactedEntity: Array<HuntingRuleImpactedEntity> = [];
					Object.keys(possibleImpactedEntities).forEach((entityType) => {
						possibleImpactedEntities[entityType].forEach((possibleValue) => {
							huntingRuleImpactedEntity.push({
								entityType: HuntingRuleEntityType[entityType],
								entityIdFields: [possibleValue],
							});
						});
					});
					return huntingRuleImpactedEntity;
				})
			);
		supportedImpactedEntitiesCall
			.pipe(
				take(1),
				tap((possibleImpactedEntities) => {
					// restrict selected impacted entities to a subset of possible entities
					this.huntingRule.impactedEntities = (
						this.huntingRule.impactedEntities || []
					).filter((entity) => possibleImpactedEntities.some((pe) => this.areEntitiesEqual(entity, pe)));

					// set up the impacted entities selection options
					const possibleEntitiesByType = groupBy(possibleImpactedEntities, 'entityType');
					const possibleEntityTypes = [
						HuntingRuleEntityType.Machine,
						HuntingRuleEntityType.Mailbox,
						HuntingRuleEntityType.User,
					];
					this.impactedEntityCategories = possibleEntityTypes.map((entityType) => {
						const selectedEntity: HuntingRuleImpactedEntity = this.huntingRule.impactedEntities.find(
							(entity) => entity.entityType === entityType
						);
						return {
							entityType: <HuntingRuleEntityType>entityType,
							entities: sortBy(
								possibleEntitiesByType[entityType] || [],
								(entity) => entity.entityIdFields[0]
							),
							checked: !!selectedEntity,
							selectedEntity:
								selectedEntity &&
								possibleEntitiesByType[entityType].find((entity) =>
									this.areEntitiesEqual(entity, selectedEntity)
								),
							name: this.i18nService.get(
								'hunting.scheduledMonitorSidePane.wizardSections.entities.' +
									lowerFirst(entityType)
							),
						};
					});
				})
			)
			.subscribe();

		this.impactedEntitiesColumns = DataTableField.fromList<HuntingRuleImpactedEntity>([
			{
				id: 'entityType',
				name: this.i18nService.get(
					'hunting.scheduledMonitorSidePane.wizardSections.summary.impactedEntitiesTable.entityType'
				),
				getDisplay: (entity) =>
					this.i18nService.get(
						'hunting.scheduledMonitorSidePane.wizardSections.entities.' +
							lowerFirst(entity.entityType)
					),
				fluidWidth: 0.5,
				sort: { enabled: false },
			},
			{
				id: 'entityIdFields',
				name: this.i18nService.get(
					'hunting.scheduledMonitorSidePane.wizardSections.summary.impactedEntitiesTable.entityIdFields'
				),
				getDisplay: this.getImpactedEntitiesLabel,
				fluidWidth: 0.5,
				sort: { enabled: false },
			},
		]);
	}

	onImpactedEntitiesSelectionChange($event: any) {
		// apply impacted entities selection
		this.huntingRule.impactedEntities = Object.values(this.impactedEntityCategories)
			.filter((category) => category.checked && category.selectedEntity)
			.map((category) => ({ ...category.selectedEntity }));
		this.changeDetector.detectChanges();
	}

	getImpactedEntitiesLabel(entity: HuntingRuleImpactedEntity): string {
		return entity.entityIdFields.join(', ');
	}

	previousStep() {
		const previousSteps = this.stepsData.filter((s) => s.show && s.id < this.currentStep.id);
		if (previousSteps.length) {
			this.currentStep = previousSteps[previousSteps.length - 1];

			this.changeDetector.detectChanges();
			// re-trigger change detection to update ngModels inside the current step form
			setTimeout(() => {
				this.changeDetector.detectChanges();
			});
		}
	}

	nextStep() {
		const nextStep = this.stepsData.find((s) => s.show && s.id > this.currentStep.id);
		if (nextStep) {
			this.currentStep = nextStep;

			this.changeDetector.detectChanges();
			setTimeout(() => {
				this.changeDetector.detectChanges();
			});
		}
	}

	machineGroupsSummaryDisplay(groupIds?: number[], isAllMachinesToggleSelected: boolean = false) {
		if (isAllMachinesToggleSelected || !groupIds || !groupIds.length) {
			return this.i18nService.get('hunting.scheduledMonitorSidePane.fields.machineGroups.all');
		}

		if (groupIds.length === 1) {
			const group = this.machineGroups$.value.find((group) => group.id === groupIds[0]);
			return group && group.name;
		} else {
			return this.i18nService.get(
				'hunting.scheduledMonitorSidePane.fields.machineGroups.dropdown.placeholderWithInfo.plural',
				{
					numGroups: groupIds.length,
				}
			);
		}
	}

	get isNextButtonEnabled(): boolean {
		return this.stepsData.find((step) => step.id === this.currentStep.id).valid;
	}

	set selectedFrequency(frequency: HuntingRuleFrequency) {
		this._selectedFrequency = frequency;
		if (this.huntingRule) {
			this.huntingRule.intervalHours = frequency && frequency.id;
		}
	}

	get selectedFrequency(): HuntingRuleFrequency {
		return this._selectedFrequency;
	}

	private async shouldMigrateRuleToMtp() {
		if (this.queryText && this.huntingRule.isMdatp && this.huntingRule.id) {
			const apiCall = this.shouldMigrateQueriesApiToVNext
				? HuntingRuleQueryIsMdatpVNextApiCall
				: HuntingRuleQueryIsMdatpApiCall;
			const isMdatpQuery = await this.paris.apiCall(apiCall, this.queryText).pipe(take(1)).toPromise();
			return !isMdatpQuery;
		}
		return false;
	}

	private async showMtpEditRuleDialog(huntingRule: HuntingRule): Promise<boolean> {
		const { confirmed } = await this.confirmationDialogService.showDialog({
			title: this.i18nService.get('scheduledHunting.rules.editMdatpInMtp.dialogTitle'),
			body: this.i18nService.get('scheduledHunting_rules_editMdatpInMtp_dialogBody'),
			confirmButton: {
				text: this.i18nService.get('scheduledHunting_rules_editMdatpInMtp_dialogConfirmButton'),
			},
		});

		this.appInsightsService.trackEvent('EditMdatpRuleInMtp', {
			ruleId: huntingRule.id,
			confirmed: confirmed,
		});

		return confirmed;
	}

	onAnyExpandedChange(state: boolean): void {
		this.changeDetector.detectChanges();
	}

	private get shouldMigrateQueriesApiToVNext() {
		return this.featuresService.isAnyEnabled([
			Feature.HuntingEndpointMigrationQueriesController,
			Feature.HuntingEndpointMigrationQueriesControllerMtp,
		]);
	}

	private areEntitiesEqual(a: HuntingRuleImpactedEntity, b: HuntingRuleImpactedEntity){
		return a.entityType === b.entityType
			&& a.entityIdFields.length === b.entityIdFields.length
			&& a.entityIdFields.every(idField => b.entityIdFields.includes(idField));
	}
}
