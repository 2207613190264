/* tslint:disable:template-click-events-have-key-events */
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	OnInit,
} from '@angular/core';
import { RecommendationContextBase, RecommendationContextType, SecurityRecommendation } from '@wcd/domain';
import { SpinnerSize } from 'office-ui-fabric-react';
import { RecommendationContextFieldsService } from '../../../../@entities/@tvm/security-recommendations/services/recommendation-context.fields.service';
import { TvmContextOptions } from '../../../../@entities/@tvm/vulnerabilities/components/vulnerability.entity-panel.component';
import { DataSet, RelationshipRepository, ModelBase } from '@microsoft/paris';
import { DataviewField, DataViewConfig } from '@wcd/dataview';
import { SidePanelService } from '../../../../@entities/@tvm/common/side-panel.service';

@Component({
	selector: 'recommendation-context-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './recommendation-context.details.component.html',
})
export class RecommendationContextDetailsComponent<T extends ModelBase> implements OnInit {
	SpinnerSize = SpinnerSize;
	loaded = false;
	itemsCount: number;
	fields: Array<DataviewField<any>>;
	hideTable: boolean;
	dataViewConfig: DataViewConfig;

	@Input() isSingleAssetRecommendation: Boolean;
	@Input() repository: RelationshipRepository<T, any>;
	@Input() contextOptions: TvmContextOptions;
	@Input() title: string;
	@Input() maxItems: number;
	@Input() initiallyCollapsed = true;
	@Input() securityRecommendation: SecurityRecommendation;
	@Input() recommendationContextType: RecommendationContextType;

	@Output() showMore = new EventEmitter<void>();
	@Output() export = new EventEmitter<void>();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private sidePanelService: SidePanelService,
		private recommendationContextFieldsService: RecommendationContextFieldsService
	) {}

	ngOnInit() {
		if (this.isSingleAssetRecommendation) {
			this.fields = this.recommendationContextFieldsService.getAssetRecommendationContextFields(
				this.recommendationContextType
			);
		} else {
			this.fields = this.recommendationContextFieldsService.getExposedMachinesCountPerRecommendationContextKeyFields(
				this.recommendationContextType
			);
		}
		const allFieldsIds = this.fields.map(field => field.id);
		this.dataViewConfig = {
			//must supply unique id for correct dataview caching purposes - unique id is based on the fields
			id: `recommendationContextDetails(${allFieldsIds.join()})`,
		};
		this.hideTable = this.initiallyCollapsed;
	}

	openRecommendationContextItemPanel(contextKey: RecommendationContextBase) {
		this.sidePanelService.showRecommendationContextPropertiesPanel(
			this.securityRecommendation,
			contextKey.contextKey,
			this.recommendationContextType
		);
	}

	onData(dataSet: DataSet<any>) {
		this.itemsCount = dataSet.count;
		this.loaded = true;
		this.changeDetectorRef.markForCheck();
	}

	onCollapseChange(isCollapsed: boolean) {
		if (this.hideTable && !isCollapsed) {
			this.hideTable = false;
			this.changeDetectorRef.markForCheck();
		}
	}
}
