import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('smallContainer', smallContainerDirective);

function smallContainerDirective() {
	return {
		restrict: 'A',
		scope: {
			smallContainerWidth: '=smallContainer',
		},
		link: function(scope, element, attrs) {
			var destroyed = false;

			element.on('$destroy', function() {
				destroyed = true;
				$(window).off('resize');
				element.removeClass('small-container-active');
			});

			$(window).resize(checkWidth);

			function checkWidth() {
				if (!destroyed) {
					var width = element.width();
					if (width < scope.smallContainerWidth) {
						element.addClass('small-container-active');
					} else {
						element.removeClass('small-container-active');
					}
				}
			}

			checkWidth();
		},
	};
}
