import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTiIndicatorsType } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'custom-ti-indicator-new-modal',
	template: `
		<wcd-panel [settings]="settings" [canDeactivate]="boundCanDeactivate">
			<custom-ti-indicator-new
				[customTiIndicatorType]="customTiIndicatorType"
				[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
				(save)="onSave()"
				(cancel)="closePanel()"
				[indicatorValue]="indicatorValue"
			>
			</custom-ti-indicator-new>
		</wcd-panel>
	`,
})
export class CustomTiIndicatorNewModalComponent extends PanelContainer {
	@Input() customTiIndicatorType: CustomTiIndicatorsType;
	@Input() indicatorValue?: string;

	@Output() save: EventEmitter<void> = new EventEmitter<void>();
	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();

	boundCanDeactivate: () => Observable<boolean>;

	constructor(protected router: Router) {
		super(router);

		this.boundCanDeactivate = this.canDeactivate.bind(this);
	}

	canDeactivate(): Observable<boolean> {
		return of(true);
	}

	onSave() {
		this.save.emit();
		this.closePanel();
	}

	closePanel() {
		this.cancel.emit();
		super.closePanel();
	}
}
