
		<div class="wcd-flex-vertical wcd-full-height">
			<ng-container *ngIf="(data$ | async) as data">
				<div class="wcd-flex-1 ie11Patch ie11Flex">
					<h4 class="wcd-margin-bottom">{{ 'alert.report.widgets.alertContext.title' | i18n }}</h4>
					<!--[ First machine ]-->
					<div *ngIf="data.machine" class="wcd-flex-horizontal wcd-margin-small-bottom">
						<wcd-shared-icon
							[iconName]="
								(machineEntityTypeService.getIcon &&
									machineEntityTypeService.getIcon([data.machine])) ||
								machineEntityTypeService.icon
							"
						></wcd-shared-icon>
						&nbsp;
						<route-link
							[routeLink]="{
								routerLink: [machineEntityTypeService.getEntitiesLink([data.machine])]
							}"
							class="wcd-flex-1 wcd-text-overflow-ellipsis wcd-text-overflow-ellipsis-nowrap wcd-margin-small-right"
						>
							<span [wcdTooltip]="data.machine.name">{{ data.machine.name }}</span>
						</route-link>
					</div>

					<div
						class="wcd-flex-horizontal wcd-margin-small-bottom"
						*ngIf="getUserLink(data) as userLink"
					>
						<wcd-shared-icon iconName="Contact"></wcd-shared-icon>
						&nbsp;
						<route-link
							[routeLink]="{
								routerLink: userLink.commands,
								queryParams: userLink.queryParams
							}"
							class="wcd-flex-1 wcd-text-overflow-ellipsis wcd-text-overflow-ellipsis-nowrap wcd-margin-small-right"
						>
							<span [wcdTooltip]="data.user.fullName">{{ data.user.fullName }}</span>
						</route-link>
					</div>

					<div class="wcd-flex-horizontal wcd-margin-small-bottom">
						<span class="wcd-font-weight-bold wcd-flex-0 wcd-margin-small-right">
							{{ 'alerts.activity.first' | i18n }}:
						</span>
						{{ data.firstEventTime | date: 'medium' }}
					</div>

					<div class="wcd-flex-horizontal wcd-margin-small-bottom">
						<span class="wcd-font-weight-bold wcd-flex-0 wcd-margin-small-right">
							{{ 'alerts.activity.last' | i18n }}:
						</span>
						{{ data.lastEventTime | date: 'medium' }}
					</div>
				</div>
			</ng-container>
		</div>
	