
		<div *ngIf="loaded" class="wcd-border-top wcd-padding-top wcd-padding-bottom">
			<div
				class="wcd-flex-spaceBetween-horizontal"
				role="button"
				[attr.aria-expanded]="!collapsed"
				tabindex="0"
				(click)="toggleCollapsed()"
				(keypress)="toggleCollapsed($event)"
			>
				<label class="pointer header color-text-gray-700 wcd-font-weight-bold wcd-flex-1">
					{{ title }} {{ count === undefined ? '' : '(' + (count | prettyNumber) + ')' }}
				</label>
				<button
					data-track-id="tvm-export-to-csv"
					data-track-type="Button"
					[attr.data-track-value]="'Export to csv of ' + title"
					class="section-export-button wcd-flex-center-vertical"
					*ngIf="enableExport && export?.observers?.length > 0"
					(click)="export.emit(); $event.stopPropagation()"
					[wcdTooltip]="'tvm.common.exportToCSV' | i18n"
				>
					<wcd-shared-icon iconName="Download"> </wcd-shared-icon>
					<span>{{ 'export' | i18n }}</span>
				</button>
				<fab-icon contentClass="pointer" [iconName]="collapsed ? 'ChevronDown' : 'ChevronUp'">
				</fab-icon>
			</div>
			<div [hidden]="collapsed">
				<ng-content></ng-content>
			</div>
		</div>

		<div *ngIf="!loaded" class="wcd-padding-top wcd-padding-bottom">
			<fab-spinner [size]="SpinnerSize.large" [label]="loadingText"></fab-spinner>
		</div>
	