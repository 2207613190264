/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./evidence-status-details.component";
var styles_EvidenceStatusDetailsComponent = [];
var RenderType_EvidenceStatusDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EvidenceStatusDetailsComponent, data: {} });
export { RenderType_EvidenceStatusDetailsComponent as RenderType_EvidenceStatusDetailsComponent };
function View_EvidenceStatusDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t", "\n\t\t\t"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_EvidenceStatusDetailsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "ul", [["class", "field-value-list inline-list with-bullets"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { unstyled: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceStatusDetailsComponent_1)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "field-value-list inline-list with-bullets"; var currVal_1 = _ck(_v, 4, 0, ((((_co.items == null) ? null : _co.items.length) || 0) <= 1)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.items; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_EvidenceStatusDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "evidence-status-details", [], null, null, null, View_EvidenceStatusDetailsComponent_0, RenderType_EvidenceStatusDetailsComponent)), i0.ɵdid(1, 49152, null, 0, i2.EvidenceStatusDetailsComponent, [], null, null)], null, null); }
var EvidenceStatusDetailsComponentNgFactory = i0.ɵccf("evidence-status-details", i2.EvidenceStatusDetailsComponent, View_EvidenceStatusDetailsComponent_Host_0, { items: "items" }, {}, []);
export { EvidenceStatusDetailsComponentNgFactory as EvidenceStatusDetailsComponentNgFactory };
