import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { GlobalEntitiesModule } from '../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../global_entities/services/global-entity-types.service';
import { EvaluationEntityTypeService } from './services/evaluation.entity-type.service';
import { GlobalEntityPanelsModule } from '../global_entities/global-entity-panels.module';
import { EvaluationAddMachinePanelComponent } from './components/evaluation-add-machine-panel.component';
import {
	FabMessageBarModule,
	FabSpinnerModule,
	FabCheckboxModule,
	FabTextFieldModule,
} from '@angular-react/fabric';
import { EvaluationLabSetupPanelComponent } from './components/evaluation-lab-setup-panel.component';
import { EvaluationSetupPanelComponent } from './components/setup-wizard/evaluation-setup-panel.component';
import { LabConfigurationStepComponent } from './components/setup-wizard/lab-configuration-step.component';
import { SimulatorAgentStepComponent } from './components/setup-wizard/simulator-agent-step.component';
import { SummaryStepComponent } from './components/setup-wizard/summary-step.component';
import { LabConfigurationComponent } from './components/lab-configuration.component';
import { WcdWizardModule } from '@wcd/wizard';
import { EvaluationCreateSimulationPanelComponent } from './components/evaluation-create-simulation-panel.component';
import { SimulatorAgentFormContentComponent } from './components/setup-wizard/simulator-agent-form-content.component';
import { EditSimulatorAgentsPanelComponent } from './components/setup-wizard/edit-simulator-agents-panel.component';
import { SimulatorAgentsConsentFormComponent } from './components/setup-wizard/simulator-agents-consent-form.component';
import { SimulationDescriptionPanelComponent } from './components/simulation-description-panel.component';
import { ContentsStateModule } from '@wcd/contents-state';

@NgModule({
	imports: [
		SharedModule,
		GlobalEntitiesModule,
		GlobalEntityPanelsModule,
		FabMessageBarModule,
		FabTextFieldModule,
		FabSpinnerModule,
		WcdWizardModule,
		FabCheckboxModule,
		ContentsStateModule,
	],
	declarations: [
		EvaluationAddMachinePanelComponent,
		EvaluationCreateSimulationPanelComponent,
		EvaluationLabSetupPanelComponent,
		LabConfigurationComponent,
		EvaluationSetupPanelComponent,
		LabConfigurationStepComponent,
		SummaryStepComponent,
		SimulatorAgentStepComponent,
		SimulatorAgentFormContentComponent,
		EditSimulatorAgentsPanelComponent,
		SimulatorAgentsConsentFormComponent,
		SimulationDescriptionPanelComponent,
	],
	entryComponents: [
		EvaluationAddMachinePanelComponent,
		EvaluationCreateSimulationPanelComponent,
		EvaluationLabSetupPanelComponent,
		EvaluationSetupPanelComponent,
		LabConfigurationStepComponent,
		SummaryStepComponent,
		SimulatorAgentStepComponent,
		EditSimulatorAgentsPanelComponent,
		SimulatorAgentsConsentFormComponent,
		SimulationDescriptionPanelComponent,
	],
	providers: [EvaluationEntityTypeService],
})
export class EvaluationEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(EvaluationEntityTypeService);
	}
}
