import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { SuppressionRule, SuppressionRuleType } from '@wcd/domain';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { SuppressionRuleEntityPanelComponent } from '../components/suppression-rule.entity-panel.component';
import { SuppressionRuleEntityComponent } from '../components/suppression-rule.entity.component';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import {
	SESSION_NEW_SUPPRESSION,
	SuppressionRulePanelMode,
	SuppressionRulesService,
} from './suppression-rules.service';
import { Router } from '@angular/router';
import { FabricIconNames } from '@wcd/scc-common';
import { SuppressionRuleEntityDetailsComponent } from '../components/suppression-rule.entity-details.component';
import { AppContextService } from '@wcd/config';

@Injectable()
export class SuppressionRulesEntityTypeService implements EntityTypeService<SuppressionRule> {
	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private router: Router,
		public suppressionRulesService: SuppressionRulesService,
		private appContextService: AppContextService
	) {}

	readonly entityType: Readonly<EntityType<SuppressionRule>> = {
		entity: SuppressionRule,
		id: 'suppressionRules',
		icon: 'suppressionRule',
		entityDetailsComponentType: SuppressionRuleEntityDetailsComponent,
		entityContentsComponentType: SuppressionRuleEntityComponent,
		singleEntityPanelComponentType: SuppressionRuleEntityPanelComponent,
		loadFullEntityInPanel: false,
		getEntityName: (rule: SuppressionRule) => rule.name,
		getNavigationModel: () => {
			return {
				routerLink: null,
			};
		},
		entityPageViewMode: EntityPageViewMode.Default,
		entityPluralNameKey: 'suppressionRules_entityType_pluralName',
		entitySingularNameKey: 'suppressionRules_entityType_singularName',
		getActions: (rules: Array<SuppressionRule>, options, entityViewType: EntityViewType) => {
			const isFirstRuleEnabled = rules[0].isEnabled;
			if (rules.length !== 1) {
				return [];
			}

			let actions: Array<ItemActionModelConfig> = [];

			if (entityViewType !== EntityViewType.EntityPage) {
				actions = [
					...actions,
					{
						id: 'comments',
						nameKey: 'suppressionRules.edit.open.suppression.page',
						icon: FabricIconNames.PageRight,
						method: (rules?: Array<SuppressionRule>) =>
							this.router.navigate([`/preferences2/suppressionRule/${rules[0].id}`]),
					},
				];
			}

			actions = [
				...actions,
				{
					id: 'editRule',
					nameKey: 'suppressionRules.edit.confirmButton',
					icon: FabricIconNames.EditSolid12,
					closeOnAction: true,
					disabled:
						rules[0].isReadOnly ||
						(this.appContextService.isSCC &&
							localStorage.getItem(SESSION_NEW_SUPPRESSION) === 'false' &&
							rules &&
							rules[0].ruleType === SuppressionRuleType.MDE) ||
						(!this.appContextService.isSCC &&
							entityViewType !== EntityViewType.EntityPage &&
							rules &&
							rules[0].ruleType === SuppressionRuleType.MDE),
					method: (rules?: Array<SuppressionRule>) =>
						this.suppressionRulesService
							.showRulePanel(SuppressionRulePanelMode.edit, rules[0].id, null)
							.catch((error) => {}), // We swallow the error If a user cancels the edit
				},
				{
					id: 'changeStatus',
					disabled: rules[0].isReadOnly,
					name: this.i18nService.get('suppressionRules.actions.changeStatus', {
						status: isFirstRuleEnabled
							? this.i18nService.get('suppressionRules_actions_changeStatus_result_off')
							: this.i18nService.get('suppressionRules_actions_changeStatus_result_on'),
					}),
					icon: isFirstRuleEnabled ? FabricIconNames.Cancel : FabricIconNames.Accept,
					method: (rules?: Array<SuppressionRule>) =>
						this.suppressionRulesService.changeRuleStatus(rules[0]),
					closeOnAction: true,
				},
				{
					id: 'delete',
					nameKey: 'delete',
					disabled: rules[0].isReadOnly,
					icon: FabricIconNames.Delete,
					method: (rules?: Array<SuppressionRule>) =>
						this.suppressionRulesService.deleteRule(rules[0]).then((data) => {
							if (data && data.isConfirm && entityViewType === EntityViewType.EntityPage) {
								this.router.navigate(['/preferences2/alert_suppression']);
							}
						}),
					closeOnAction: true,
				},
			];

			if (entityViewType === EntityViewType.EntityPage) {
				actions = [
					...actions,
					{
						id: 'comments',
						nameKey: 'suppressionRules.edit.rule.comment.and.history.field',
						icon: 'comment',
						method: (rules?: Array<SuppressionRule>) =>
							this.suppressionRulesService.showRuleCommentsPanel(rules[0]),
					},
				];
			}

			return actions.map((itemActionConfig) => new ItemActionModel(itemActionConfig));
		},
	};
}
