import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { MachinesMigrationDataQuery } from './machines-migration-dataQuery';
import { MachineTags } from './machine-tags.value-object';

@ApiCall({
	name: 'Get all the available device tags',
	endpoint: (config: WcdPortalParisConfig, query: MachinesMigrationDataQuery) => {
		if (query && query.where && query.where.migrateToVNext) {
			return 'machines/allMachinesTags';
		}
		return 'AllMachinesTags';
	},
	baseUrl: (config: WcdPortalParisConfig, query: MachinesMigrationDataQuery) => {
		if (query && query.where && query.where.migrateToVNext) {
			return config.data.serviceUrls.k8s;
		}
		return config.data.serviceUrls.threatIntel;
	},
	type: MachineTags,
	cache: {
		time: 1000 * 60,
	},
	method: 'GET',
})
export class MachineTagsApiCall extends ApiCallModel<MachineTags> {}