import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';

@Entity({
	singularName: 'Investigation Action Category',
	pluralName: 'Investigation Action Categories',
	values: [
		{ iconName: 'investigationOutline', name: 'Analysis', id: 'Analysis' },
		{ iconName: 'users.user', name: 'User activity', id: 'UserActivity' },
		{ iconName: 'storage', name: 'Drive', id: 'Drive' },
		{ iconName: 'entities.driver', name: 'Driver', id: 'Driver' },
		{ iconName: 'entities.file', name: 'File', id: 'File' },
		{ iconName: 'entities.connection', name: 'Network', id: 'Network' },
		{ iconName: 'entities.process', name: 'Process', id: 'Process' },
		{ iconName: 'entities.persistence_method', name: 'Persistence', id: 'Persistence' },
		{ iconName: 'registry', name: 'Registry', id: 'Registry' },
		{ iconName: 'entities.service', name: 'Service', id: 'Service' },
		{ iconName: 'investigationOutline', name: 'System', id: 'System' },
		{ iconName: 'users.user', name: 'User', id: 'User' },
		{ iconName: 'actions', name: 'unknown', id: 'unknown' },
		{ iconName: 'actions', name: 'Other', id: 'Other' },
	],
})
export class InvestigationActionCategory extends EntityModelBase {
	@EntityField() name: string;
	@EntityField() iconName: string;
}
