
		<div class="wcd-flex-horizontal">
			<wcd-pie-chart
				[data]="data"
				[settings]="settings"
				[allowTitleClick]="false"
				[labelsOutside]="false"
			>
				<div class="wcd-full-height wcd-full-width wcd-flex-center-all">
					<div>
						<div class="wcd-full-height wcd-full-width wcd-flex-center-all">
							<div>
								<wcd-shared-icon
									class="small-icon color-text-primary"
									[iconName]="'os.windows'"
								>
								</wcd-shared-icon>
							</div>
						</div>
						<div class="wcd-full-height wcd-full-width wcd-flex-center-all">
							<div>Windows</div>
						</div>
						<div
							class="wcd-full-height wcd-full-width wcd-flex-center-all pie-chart-content-title"
						>
							<div>{{ machineSecurityLatestState.securityScore }}</div>
						</div>
						<div class="wcd-full-height wcd-full-width wcd-flex-center-all">
							<div>of {{ machineSecurityLatestState.maxPotentialSecurityScore }}</div>
						</div>
					</div>
				</div>
			</wcd-pie-chart>
		</div>
	