
		<div
			#parentElement
			class="support-central-parent"
			style="position: fixed; visibility: hidden;"
			[ngStyle]="{ display: widgetParentDisplay }"
		></div>
		<div
			*ngIf="withCollapseButton"
			class="support-central-collapse-button"
			[ngStyle]="{ 'right.px': collapseButtonRightPositionPx, 'width.px': collapseButtonWidthPx }"
		>
			<fab-icon-button
				[styles]="{ root: { height: 40 } }"
				[iconProps]="{ iconName: collapseButtonIcon }"
				(click)="toggleCollapse()"
			></fab-icon-button>
		</div>
	