import { Injectable } from '@angular/core';
import {
	EntityDataViewOptions,
	EntityType,
} from '../../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../../global_entities/models/entity-type-service.interface';
import { BaselineProfileDetails } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { ItemActionModel, ItemActionModelConfig, ItemActionType } from '../../../../../dataviews/models/item-action.model';
import { ProfileEntityPanelComponent } from '../components/profile.entity-panel.component';
import { ProfilesFieldsService } from './profiles.fields.service';
import { BaselineProfileEntityDetailsComponent } from '../components/baseline-profile.entity-details.component';
import { ProfileEntityComponent } from '../components/profile-entity/profile.entity.component';
import { EntityViewType } from '../../../../../global_entities/models/entity-view-type.enum';
import { EntityPageViewMode } from '../../../../../global_entities/models/entity-page-view-mode.enum';
import { BaselineProfileHeaderBottomComponent } from '../../../../../global_entities/components/entity-header-bottom/baseline-profile.header-bottom.component';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { ConfirmEvent } from '../../../../../dialogs/confirm/confirm.event';
import { ProfilesService } from './profiles.service';
import { Router } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { MdeUserRoleActionEnum } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
import { RbacService } from '../../../../../rbac/services/rbac.service';
import { map } from 'rxjs/operators';

export interface ProfileDataViewFixedOptions {}

export enum ExternalProfileActions {
	UPDATE_STATUS_ACTION = 'updateStatus',
}

@Injectable()
export class ProfileEntityTypeService implements EntityTypeService<BaselineProfileDetails> {
	itemSingularName: string;
	userExposedMachineGroupsIdsSet: Set<number> = new Set();
	rbacService: RbacService;

	constructor(
		private profilesService: ProfilesService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private router: Router,
		public authService: AuthService,
		private featuresService: FeaturesService,
		rbacService: RbacService,
	) {
		this.itemSingularName = this.i18nService.get('tvm_baseline_profile_entity_singular_name');
		this.rbacService = rbacService;
		this.rbacService.userExposedRbacGroups$.pipe(
			map(rbacGroups => rbacGroups.map(g => g.id))
		).subscribe((rbacGroupIds) => {
			this.userExposedMachineGroupsIdsSet = new Set(rbacGroupIds);
			// TODO: Update once it's clear why there is a mismatch between the profile and user's rbac group ids regarding value 0
			this.userExposedMachineGroupsIdsSet.add(0);
		});
	}

	readonly entityType: EntityType<BaselineProfileDetails> = {
		id: 'profile',
		entity: BaselineProfileDetails,
		icon: FabricIconNames.PageList,
		entityPageViewMode: EntityPageViewMode.Asset,
		entityContentsComponentType: ProfileEntityComponent,
		entityDetailsComponentType: BaselineProfileEntityDetailsComponent,
		singleEntityPanelComponentType: ProfileEntityPanelComponent,
		entityHeaderBottomComponentType: BaselineProfileHeaderBottomComponent,
		getEntityName: profile => profile.name,
		entityPluralNameKey: 'profile_entityType_pluralName',
		entitySingularNameKey: 'profile_entityType_singularName',
		getEntityDataviewLink: () => '/baseline-compliance/profiles',
		getActions: (profiles: Array<BaselineProfileDetails>, options, entityViewType: EntityViewType) => {
			if (profiles.length !== 1) {
				return [];
			}

			const IsUserAllowedManagingBaselineProfiles = () => {
				if(!this.featuresService.isEnabled(Feature.TvmBaselineAssessProfiles)){
					return true;
				}

				const isAdmin = this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.admin);
				const isUserAllowedToManageBaselineProfiles = this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.tvmBaselineAssessProfiles);

				return isAdmin || isUserAllowedToManageBaselineProfiles;
			}

			const IsUserAllowedToUpdatedProfile = (baselineProfileDetails: BaselineProfileDetails) => {
				const profileGroupIds = baselineProfileDetails.rbacGroupIds;
				return profileGroupIds.every((gid: number) => this.userExposedMachineGroupsIdsSet.has(gid));
			};

			const isDisabled = !(
				IsUserAllowedManagingBaselineProfiles() && IsUserAllowedToUpdatedProfile(profiles[0])
			);

			let tooltip: string;
			if (!IsUserAllowedManagingBaselineProfiles()) {
				tooltip = this.i18nService.strings.baselineProfiles_warning_NotAllowed;
			} else if (!IsUserAllowedToUpdatedProfile(profiles[0])) {
				tooltip = this.i18nService.strings.baselineProfiles_warning_NotAllowedRbacGroups;
			}

			const actions: Array<ItemActionModelConfig> = [
				entityViewType === EntityViewType.EntityPage ? null :
				{
					id: 'gotoProfile',
					nameKey: 'tvm_baseline_profiles_sidepane_action_open',
					icon: FabricIconNames.PageRight,
					closeOnAction: methodResolution => methodResolution,
					method: (profiles: Array<BaselineProfileDetails>) => {
						return this.profilesService.navigateToProfilePage(profiles[0]);
					},
				},
				{
					id: 'deleteProfile',
					nameKey: 'tvm_baseline_profiles_sidepane_action_delete',
					icon: FabricIconNames.Delete,
					closeOnAction: methodResolution => methodResolution,
					disabled: isDisabled,
					tooltip: tooltip,
					method: (profiles: Array<BaselineProfileDetails>) => {
						const title = profiles[0].name;
						return this.dialogsService
							.confirm({
								title: this.i18nService.get('deleteItem', { itemName: title }),
								text: this.i18nService.get('deleteConfirmSingular', {
									itemSingularName: this.itemSingularName,
								}),
								confirmText: this.i18nService.get('delete'),
							})
							.then((e: ConfirmEvent) => {
								if (e.confirmed)
									return this.profilesService
										.deleteProfile(profiles[0])
										.then(_ =>  {
											if (entityViewType === EntityViewType.EntityPage)
												this.router.navigate(['baseline-compliance/profiles']);
											return Promise.resolve(true);
										})
										.catch(error => {
											this.dialogsService.showError({
												title: this.i18nService.get(
													'common_error_failedDeleteItem',
													{
														itemName: title,
													}
												),
												data: error,
											});
										});
							});
					},
				},
				{
					id: ExternalProfileActions.UPDATE_STATUS_ACTION,
					closeOnAction: true,
					disabled: true,
					method: (profiles: Array<BaselineProfileDetails>) => {
						const profileToUpdate = {
							...profiles[0],
							status: !profiles[0].status
						}
						return this.profilesService.updateBaselineProfile(profileToUpdate)
					},
					type: ItemActionType.Hidden
				},
			];

			return actions.filter(Boolean).map(itemActionConfig => new ItemActionModel(itemActionConfig));
		},
		loadFullEntityInPanel: false,
		dataViewOptions: <EntityDataViewOptions<BaselineProfileDetails, ProfileDataViewFixedOptions>>{
			fields: ProfilesFieldsService,
		},
	};
}
