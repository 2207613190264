import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { RegistryValueTypes } from '../../entity/registry-value-type.enum';

@Entity({
	...airsEntityConfig,
	singularName: 'Registry Value',
	pluralName: 'Registry Values',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsRegistryValue,
})
export class AirsRegistryValue extends AirsEntity {
	@EntityField({ data: 'value_type' })
	valueType: RegistryValueTypes;

	@EntityField({ data: 'value_data' })
	valueData: string;

	@EntityField({ data: 'key' })
	keyData: string;

	@EntityField()
	hive: string;


	constructor(data) {
		super(data);
		this.entityName = this.valueData ? `${this.name}: ${this.valueData}` : this.name;
	}
}
