import { Injectable } from '@angular/core';
import { Paris, DataEntityType } from '@microsoft/paris';
import { Type } from '@angular/compiler';

@Injectable()
export class SevilleDowngradeUtilsService {
	constructor(private readonly paris: Paris) {}

	createItem(type: DataEntityType, rawData: any) {
		return this.paris.createItem(type, rawData);
	}
}
