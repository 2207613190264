
		<div [class]="contentClass" *ngIf="showReadOnlyMessage">
			<fab-message-bar [messageBarType]="MessageBarType.info"
							 (onDismiss)="closeBar('showReadOnlyMessage')"
							 [dismissable]="true">
				{{'incident_rbac_permission_message_read_only' | i18n}}
			</fab-message-bar>
		</div>
		<div [class]="contentClass"
			*ngIf="showIncidentRbacMessage" >
			<fab-message-bar [messageBarType]="MessageBarType.info"
							 (onDismiss)="closeBar('showIncidentRbacMessage')"
							 [dismissable]="true">
				<div [innerHTML]="rbacMessage"></div>
			</fab-message-bar>
		</div>
		<div [class]="contentClass" *ngIf="showMTPAlertMessageBar">
			<fab-message-bar [messageBarType]="MessageBarType.info"
							 (onDismiss)="closeBar('showMTPAlertMessageBar')"
							 [dismissable]="true">
				<div [innerHTML]="i18nService.get('incident_MtpAlertsExistMessage',{ url: incidentUrl } )"></div>
			</fab-message-bar>
		</div>
	