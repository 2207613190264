import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Log properties',
	pluralName: '',
	readonly: true,
})
export class LogProperties extends ModelBase {
	@EntityField({ data: 'category' })
	category: string;

	@EntityField({ data: 'enabled' })
	enabled: boolean;
}
