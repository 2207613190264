
		<ng-container *ngIf="(data$ | async) as data">
			<p class="wcd-font-size-xxl wcd-margin-mediumSmall-bottom">
				{{ 'incident.activeIncidentsCount' | i18n: {count: data.activeIncidentCount | number} }}
				<span class="wcd-font-size-mi">{{ 'incident_relevant_for_30_days' | i18n }}</span>
			</p>
			<active-incidents-trends [data]="data"></active-incidents-trends>
			<dataview
				[id]="'active-incidents-dataview'"
				[disableSelection]="true"
				[entityType]="incidentEntityType"
				[fields]="fields"
				[hideControls]="true"
				[allowPaging]="false"
				[allowFilters]="false"
				[padLeft]="false"
				[dataViewConfig]="dataViewConfig"
				[label]="'incident.activeIncidents' | i18n"
				[fixedTable]="true"
				[ignoreQueryParams]="true"
				className="no-horizontal-padding"
			>
			</dataview>
			<fab-default-button contentClass="btn-secondary" (click)="viewAllIncidentClicked()">
				{{ 'incident.viewAll' | i18n }}
			</fab-default-button>
		</ng-container>
	