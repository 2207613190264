import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { RemediationActionType } from './remediation-action-type.entity';
import { InvestigatedMachine } from '../investigation/investigated-machine.entity';

@ValueObject({
	singularName: 'Remediated Threat',
	pluralName: 'Remediated Threats',
	readonly: true,
})
export class RemediatedThreat extends ModelBase {
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() id: number;

	@EntityField() name: string;

	@EntityField({ data: 'action_type' })
	remediationActionType: RemediationActionType;

	@EntityField({ data: 'threat_type' })
	threatType: string;

	@EntityField() errors: Array<string>;

	@EntityField({ data: 'host' })
	machine: InvestigatedMachine;
}
