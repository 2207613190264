import { RecommendationContextType } from '@wcd/domain';
var CSV_SUPPORTED_RECOMMENDATION_CONTEXT_TYPES = [
    RecommendationContextType.ContextWithServiceName,
    RecommendationContextType.ContextWithServiceNameAndServicePath,
    RecommendationContextType.ContextWithUserName,
    RecommendationContextType.ContextWithShareNameAndSharePath
];
var UNQUOTED_SERVICE_PATH_SECURITY_RECOMMENDATION_ID = 'scid-3001';
var PROTECTED_LOCATION_SERVICE_PATH_SECURITY_RECOMMENDATION_ID = 'scid-3002';
var CACHED_PASSWORD_SERVICE_SECURITY_RECOMMENDATION_ID = 'scid-3003';
var BUILT_IN_ADMIN_ACCOUNT_SECURITY_RECOMMENDATION_ID = 'scid-3010';
var BUILT_IN_GUEST_ACCOUNT_SECURITY_RECOMMENDATION_ID = 'scid-3011';
var OFFLINE_ACCESS_SHARE_SECURITY_RECOMMENDATION_ID = 'scid-4000';
var EVERYONE_SHARE_SECURITY_RECOMMENDATION_ID = 'scid-4001';
var ROOT_FOLDER_SHARE_SECURITY_RECOMMENDATION_ID = 'scid-4002';
var TvmRecommendationContextService = /** @class */ (function () {
    function TvmRecommendationContextService() {
        var _a;
        this._recommendationContextToContextKeyIconName = (_a = {},
            _a[RecommendationContextType.ContextWithServiceName] = 'wcd-icons--service',
            _a[RecommendationContextType.ContextWithServiceNameAndServicePath] = 'wcd-icons--service',
            _a[RecommendationContextType.ContextWithUserName] = 'wcd-icons--user',
            _a[RecommendationContextType.ContextWithShareNameAndSharePath] = 'wcd-icons--service',
            _a[RecommendationContextType.Unknown] = '',
            _a);
    }
    TvmRecommendationContextService.prototype.isRecommendationContextNeededInCSV = function (recommendationContextType) {
        return CSV_SUPPORTED_RECOMMENDATION_CONTEXT_TYPES.includes(recommendationContextType);
    };
    TvmRecommendationContextService.prototype.getRecommendationContextTypeByAsset = function (asset) {
        var isUnknown = asset.recommendationContext === null || asset.recommendationContext.length === 0;
        return isUnknown ? RecommendationContextType.Unknown : asset.recommendationContext[0].contextType;
    };
    TvmRecommendationContextService.prototype.getRecommendationContextTypeByAssets = function (assets) {
        return assets.items.length === 0
            ? RecommendationContextType.Unknown
            : this.getRecommendationContextTypeByAsset(assets.items[0]);
    };
    // This is temporarily till we add a field in the recommendations pre-agg table that indicates if there is a context defined or not
    TvmRecommendationContextService.prototype.getRecommendationContextTypeByScId = function (scId) {
        switch (scId) {
            case UNQUOTED_SERVICE_PATH_SECURITY_RECOMMENDATION_ID:
            case PROTECTED_LOCATION_SERVICE_PATH_SECURITY_RECOMMENDATION_ID: {
                return RecommendationContextType.ContextWithServiceNameAndServicePath;
            }
            case CACHED_PASSWORD_SERVICE_SECURITY_RECOMMENDATION_ID: {
                return RecommendationContextType.ContextWithServiceName;
            }
            case BUILT_IN_ADMIN_ACCOUNT_SECURITY_RECOMMENDATION_ID: {
                return RecommendationContextType.ContextWithUserName;
            }
            case BUILT_IN_GUEST_ACCOUNT_SECURITY_RECOMMENDATION_ID: {
                return RecommendationContextType.ContextWithUserName;
            }
            case OFFLINE_ACCESS_SHARE_SECURITY_RECOMMENDATION_ID: {
                return RecommendationContextType.ContextWithShareNameAndSharePath;
            }
            case EVERYONE_SHARE_SECURITY_RECOMMENDATION_ID: {
                return RecommendationContextType.ContextWithShareNameAndSharePath;
            }
            case ROOT_FOLDER_SHARE_SECURITY_RECOMMENDATION_ID: {
                return RecommendationContextType.ContextWithShareNameAndSharePath;
            }
            default: {
                return RecommendationContextType.Unknown;
            }
        }
    };
    // This function returns the headers names of the tables that show recommendation context, regular html tables and not dataview tables
    TvmRecommendationContextService.prototype.getRecommendationContextTableHeadersByType = function (recommendationContextType) {
        var SERVICE_NAME_PROPERTY = 'serviceName';
        var SERVICE_PATH_PROPERTY = 'path';
        var USER_NAME_PROPERTY = 'userName';
        var SHARE_NAME_PROPERTY = 'shareName';
        var SHARE_PATH_PROPERTY = 'sharePath';
        switch (recommendationContextType) {
            case RecommendationContextType.ContextWithServiceNameAndServicePath:
                return [SERVICE_NAME_PROPERTY, SERVICE_PATH_PROPERTY];
            case RecommendationContextType.ContextWithServiceName:
                return [SERVICE_NAME_PROPERTY];
            case RecommendationContextType.ContextWithUserName:
                return [USER_NAME_PROPERTY];
            case RecommendationContextType.ContextWithShareNameAndSharePath:
                return [SHARE_NAME_PROPERTY, SHARE_PATH_PROPERTY];
            default:
                return [];
        }
    };
    TvmRecommendationContextService.prototype.getRecommendationContextKeyIconNameByType = function (recommendationContextType) {
        return this._recommendationContextToContextKeyIconName[recommendationContextType];
    };
    return TvmRecommendationContextService;
}());
export { TvmRecommendationContextService };
