/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./machine-link.component.ngfactory";
import * as i2 from "./machine-link.component";
import * as i3 from "../../global_entities/services/global-entity-types.service";
import * as i4 from "@angular/common";
import * as i5 from "./machines-list.component";
var styles_InvestigatedMachinesListComponent = [];
var RenderType_InvestigatedMachinesListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InvestigatedMachinesListComponent, data: {} });
export { RenderType_InvestigatedMachinesListComponent as RenderType_InvestigatedMachinesListComponent };
function View_InvestigatedMachinesListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " devices "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.machines == null) ? null : _co.machines.length); _ck(_v, 1, 0, currVal_0); }); }
function View_InvestigatedMachinesListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "machine-link", [], null, null, null, i1.View_HostLinkComponent_0, i1.RenderType_HostLinkComponent)), i0.ɵdid(3, 114688, null, 0, i2.HostLinkComponent, [i3.GlobalEntityTypesService], { machine: [0, "machine"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0); }, null); }
function View_InvestigatedMachinesListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "ul", [["class", "investigated-machines-list"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_InvestigatedMachinesListComponent_4)), i0.ɵdid(3, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i4.SlicePipe, []), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.machines, 0, _co.currentLimit)); _ck(_v, 3, 0, currVal_0); }, null); }
function View_InvestigatedMachinesListComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["data-track-id", "MachinesFullListToggle"], ["data-track-type", "Toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowMoreClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t", "\n\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isFullListShown ? "View less" : "View all"); _ck(_v, 1, 0, currVal_0); }); }
function View_InvestigatedMachinesListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InvestigatedMachinesListComponent_3)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InvestigatedMachinesListComponent_5)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.machines; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isMoreButtonShown; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_InvestigatedMachinesListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InvestigatedMachinesListComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["machinesList", 2]], null, 0, null, View_InvestigatedMachinesListComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.countOnly; var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_InvestigatedMachinesListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "investigated-machines-list", [], null, null, null, View_InvestigatedMachinesListComponent_0, RenderType_InvestigatedMachinesListComponent)), i0.ɵdid(1, 573440, null, 0, i5.InvestigatedMachinesListComponent, [], null, null)], null, null); }
var InvestigatedMachinesListComponentNgFactory = i0.ɵccf("investigated-machines-list", i5.InvestigatedMachinesListComponent, View_InvestigatedMachinesListComponent_Host_0, { machines: "machines", limit: "limit", countOnly: "countOnly" }, {}, []);
export { InvestigatedMachinesListComponentNgFactory as InvestigatedMachinesListComponentNgFactory };
