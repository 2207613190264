import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import {
	LegacyUser,
	LegacyUserLegacyUserProfileRelationship,
	LegacyUserProfile,
	ServiceSourceType,
	MdeUserRoleActionEnum,
} from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityDataViewOptions, EntityType } from '../../../global_entities/models/entity-type.interface';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { Lazy } from '@wcd/utils';
import { UserEntityPanelComponent } from '../components/user-entity-panel.component';
import { LegacyUsersFieldsService } from './legacy-users.fields.service';
import { UsersService } from './users.service';
import { UserEntityComponent } from '../components/user-entity.component';
import { UserEntityDetailsComponent } from '../../../global_entities/components/entity-details/user-entity-details.component';
import { Router, Params } from '@angular/router';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { UserMinimizedDetailsComponent } from '../../../global_entities/components/entity-minimized/user.minimized.details.component';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService, AppContextService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';

export interface LegacyUserDataViewFixedOptions {
	lookingBackIndays: string;
}

@Injectable()
export class UserEntityTypeService implements EntityTypeService<LegacyUser> {
	private readonly timeRanges = this.timeRangesService.standard;

	private readonly _entityType = new Lazy<Readonly<EntityType<LegacyUser>>>(() => ({
		id: 'user',
		entity: LegacyUser,
		icon: FabricIconNames.Contact,
		getImage: users => {
			if (users.length !== 1) {
				return null;
			}

			return this.paris
				.getRelatedItem<LegacyUser, LegacyUserProfile>(
					LegacyUserLegacyUserProfileRelationship,
					users[0]
				)
				.pipe(
					map(userProfile => userProfile.thumbnailPhotoSrc),
					filter(url => !!url)
				);
		},
		getIcon: () => FabricIconNames.Contact,
		getIconCssClass: () => `wcd-circle`,
		singleEntityPanelComponentType: UserEntityPanelComponent,
		entityContentsComponentType: UserEntityComponent,
		entityDetailsComponentType: UserEntityDetailsComponent,
		entityMinimizedComponentType: UserMinimizedDetailsComponent,
		getUseExternalRouting: users => false,
		getEntityName: user => user.fullName,
		entityPluralNameKey: 'user_entityType_pluralName',
		entitySingularNameKey: 'user_entityType_singularName',
		getEntitiesLink: users => {
			if (!users || users.length !== 1) {
				return null;
			}

			const [user] = users;

			// we currently support user link only if we have either sid or accountName + accountDomainName.
			if (!user.sid && !(user.accountName && user.accountDomainName)) {
				return null;
			}

			const { commands, queryParams } = this.usersService.getUserLink({
				accountName: user.accountName,
				accountDomainName: user.accountDomainName,
				sid: user.sid,
			});

			return this.router.serializeUrl(this.router.createUrlTree(commands, { queryParams }));
		},
		getNavigationModel: (user: LegacyUser, serviceSource?: ServiceSourceType) => {
			if (
				!this.featuresService.isEnabled(Feature.PortedSccPages) ||
				serviceSource !== ServiceSourceType.Wdatp
			) {
				return null;
			}

			const url = this.entityType.getEntitiesLink([user]);
			if (!url) {
				return null;
			}
			const [routerLink, queryParamsPart] = url.split('?');
			const queryParams: Params = {};
			// IE and older Edge dont support URLSearchParams
			window.URLSearchParams &&
				new URLSearchParams(queryParamsPart).forEach((value, key) => (queryParams[key] = value));
			return {
				routerLink: [routerLink],
				queryParams: queryParams,
			};
		},
		dataViewOptions: <EntityDataViewOptions<LegacyUser, LegacyUserDataViewFixedOptions>>{
			fields: LegacyUsersFieldsService,
			dateRangeOptions: {
				defaultTimeRangeId: 'month',
				supportedRanges: this.timeRanges,
			},
			fixedOptions: (dateRange, searchTerm?) => ({
				lookingBackIndays: dateRange.value.toString(),
				searchTerm: searchTerm,
			}),
			exportOptions: {
				showModalOnExport: false,
				exportResults: (options, fixedOptions, searchTerm?) => {
					return this.usersService.downloadCsv({
						lookingBackIndays: fixedOptions.lookingBackIndays,
						userAccount: searchTerm,
						userAccountDomain: null,
					});
				},
			},
			dataViewConfig: {
				allowPaging: false,
				allowFilters: false,
			},
		},
		searchOptions: {
			displayName: this.i18nService.strings.entity_type_display_name_user,
			resolveTypeFromSearchTerm: (searchTerm: string) => {
				if (searchTerm) {
					const splitValues = searchTerm.split('\\');
					if (splitValues.length === 2 && splitValues[0].length > 0 && splitValues[1].length > 0) {
						return true;
					}
				}

				return false;
			},
			getSearchParams: (searchTerm: string) => {
				const resultsPageName =
					this.appContextService.isSCC || this.featuresService.isEnabled(Feature.UpgradeSearchUsers)
						? 'searchResults'
						: 'search';
				return { url: `/${resultsPageName}/users/${searchTerm}` };
			},
			searchDropdownOrder: 0,
			requiredAllowedActions: MdeUserRoleActionEnum.viewData,
			flavorConfig: AppFlavorConfig.search.user,
		},
		entityPageViewMode: EntityPageViewMode.Asset,
	}));

	constructor(
		private readonly paris: Paris,
		private readonly usersService: UsersService,
		private readonly timeRangesService: TimeRangesService,
		private readonly router: Router,
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		private appContextService: AppContextService
	) {}

	get entityType(): Readonly<EntityType<LegacyUser>> {
		return this._entityType.value;
	}
}
