import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Observable, of, forkJoin } from 'rxjs';
import {
	HuntingCustomActionEntity,
	HuntingRuleEntityType,
	HuntingCustomActionDeviceNames,
	HuntingCustomActionAadUserNames,
} from '@wcd/domain';
import { groupBy, uniq, flatten } from 'lodash-es';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { catchError, take, map } from 'rxjs/operators';

@Injectable()
export class HuntingEntityNamesService {
	constructor(private paris: Paris, private appInsights: AppInsightsService) {}

	getEntitiesWithNames(
		entities: Array<HuntingCustomActionEntity>
	): Observable<Array<HuntingCustomActionEntity>> {
		const entitiesByType = groupBy(entities, e => e.entityType.id);
		const entitiesWithNamesByType = Object.keys(entitiesByType).map(type =>
			this.getEntitiesOfType(entitiesByType[type], <HuntingRuleEntityType>type)
		);
		return forkJoin(entitiesWithNamesByType).pipe(map(results => flatten(results)));
	}

	private getEntitiesOfType(
		entities: Array<HuntingCustomActionEntity>,
		entityType: HuntingRuleEntityType
	): Observable<Array<HuntingCustomActionEntity>> {
		const entityIds = uniq(entities.map(e => e.id));
		let entitiesWithNames$: Observable<HuntingCustomActionEntity[]>;

		switch (entityType) {
			case HuntingRuleEntityType.Machine:
				entitiesWithNames$ = this.paris.apiCall(HuntingCustomActionDeviceNames, entityIds);
				break;
			case HuntingRuleEntityType.User:
				entitiesWithNames$ = this.paris.apiCall(HuntingCustomActionAadUserNames, entityIds);
				break;
			default:
				this.appInsights.trackException(new Error(`Unsupported hunting entity type: ${entityType}`));
				entitiesWithNames$ = of([]);
		}

		return entitiesWithNames$.pipe(
			catchError(error => {
				this.appInsights.trackException(
					new Error(
						`error while fetching pretty names of hunting entities. Entity type: ${entityType}, error: ${error}`
					)
				);
				return of([]);
			}),
			take(1)
		);
	}
}
