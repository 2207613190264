
		<events-summary-bars
			class="wcd-padding-small-bottom"
			[events]="severities"
			[kindColorMap]="severityColorMap"
			[displayTextFormatter]="barTextFormatter"
			size="large"
		>
		</events-summary-bars>
		<wcd-chart-legend
			class="wcd-full-width wcd-margin-small-top"
			orientation="horizontal"
			[wrap]="true"
			[showValues]="true"
			[valueFormatter]="legendTextFormatter"
			[items]="severities"
		></wcd-chart-legend>
	