import { DataviewField } from '@wcd/dataview';
import { SoftwareInstallation } from '@wcd/domain';
import { TzDateService } from '@wcd/localization';
import { InaccuracyContext } from '../../../../tvm/services/tvm-texts.service';
import { TvmThreatIconsComponent } from '../../../../tvm/components/tvm-threat-icons/tvm-threat-icons.component';
import { TvmIconBuilderService } from '../../../../tvm/services/tvm-icon-builder.service';
import { ReportableBaseFieldComponent } from '../../../../tvm/components/report-inaccuracy/tvm-reportable-base-field.component';
import { FeaturesService, Feature } from '@wcd/config';
import { SoftwareInstallationNormalizedDisplayPipe } from '../../../../../app/tvm/pipes/software-installation-normalized-display.pipe';
import { I18nService } from '@wcd/i18n';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
var SoftwareInstallationFieldsService = /** @class */ (function () {
    function SoftwareInstallationFieldsService(tzDateService, tvmIconBuilderService, featuresService, softwareInstallationNormalizedDisplayPipe, i18nService, tvmTagsService) {
        this.tzDateService = tzDateService;
        this.tvmIconBuilderService = tvmIconBuilderService;
        this.featuresService = featuresService;
        this.softwareInstallationNormalizedDisplayPipe = softwareInstallationNormalizedDisplayPipe;
        this.i18nService = i18nService;
        this.tvmTagsService = tvmTagsService;
    }
    Object.defineProperty(SoftwareInstallationFieldsService.prototype, "assetInstallationsFields", {
        get: function () {
            var _this = this;
            var naString = this.i18nService.get('notAvailable.short');
            if (!this._assetInstallationFields) {
                var installationsFieldsArr = [
                    {
                        id: 'productName',
                        name: this.i18nService.get('tvm.common.name'),
                        component: {
                            type: ReportableBaseFieldComponent,
                            getProps: function (installation) { return ({
                                inaccuracyContext: InaccuracyContext.MachineInventory,
                                contextObject: installation,
                                title: installation.productName,
                            }); },
                        },
                        getLink: function (installation) {
                            return "/software-inventory/" + encodeURIComponent(installation.getProductId());
                        },
                        getDisplay: function (softwareInstallation) {
                            return softwareInstallation.productName == '' ? naString : softwareInstallation.productName;
                        },
                    },
                    {
                        id: 'vendor',
                        name: this.i18nService.get('tvm.common.vendor'),
                        getDisplay: function (softwareInstallation) {
                            return softwareInstallation.vendor == '' ? naString : softwareInstallation.vendor;
                        },
                    },
                    {
                        id: 'installedVersion',
                        name: this.i18nService.get('tvm.common.installedVersions'),
                        getDisplay: function (softwareInstallation) {
                            return softwareInstallation.installedVersion == ''
                                ? naString
                                : softwareInstallation.installedVersion;
                        },
                    },
                    {
                        id: 'weaknesses',
                        name: this.i18nService.get('tvm.common.weaknesses'),
                        getDisplay: function (softwareInstallation) {
                            return softwareInstallation.productNeverMatched
                                ? _this.i18nService.get('notAvailable.long')
                                : softwareInstallation.weaknesses;
                        },
                    },
                    {
                        id: 'threats',
                        name: this.i18nService.get('tvm.common.threats'),
                        sort: {
                            enabled: false,
                        },
                        component: {
                            type: TvmThreatIconsComponent,
                            getProps: function (installation) {
                                return _this.tvmIconBuilderService.configureIcons(installation.threatInfo, null, 'software', false, null, true);
                            },
                        },
                    },
                ];
                if (this.featuresService.isEnabled(Feature.TvmNonCpePrograms)) {
                    installationsFieldsArr.push({
                        id: 'isNormalized',
                        name: this.i18nService.get('tvm.software.productCode'),
                        getDisplay: function (softwareInstallation) {
                            return _this.softwareInstallationNormalizedDisplayPipe.transform(softwareInstallation);
                        },
                    });
                }
                installationsFieldsArr.push({
                    id: 'tags',
                    name: this.i18nService.get('common.tags'),
                    component: {
                        type: TagsFieldComponent,
                        getProps: function (installation) { return ({
                            tags: _this.tvmTagsService.getSoftwareInstallationTags(installation),
                        }); },
                    },
                    sort: { enabled: false },
                });
                this._assetInstallationFields = DataviewField.fromList(installationsFieldsArr);
            }
            return this._assetInstallationFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SoftwareInstallationFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'assetName',
                        name: this.i18nService.get('tvm.common.machine'),
                    },
                    {
                        id: 'installedVersion',
                        name: this.i18nService.get('tvm.common.installedVersion'),
                    },
                    {
                        id: 'lastSeen',
                        name: this.i18nService.get('tvm.software.lastSeen'),
                        getDisplay: function (softwareInstallation) {
                            return _this.tzDateService.format(softwareInstallation.lastSeen, 'short');
                        },
                    },
                    {
                        id: 'highSevAlert',
                        name: this.i18nService.get('tvm.software.highAlerts'),
                        getDisplay: function (softwareInstallation) {
                            return softwareInstallation.highSevAlert
                                ? _this.i18nService.get('common.yes')
                                : _this.i18nService.get('common.no');
                        },
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return SoftwareInstallationFieldsService;
}());
export { SoftwareInstallationFieldsService };
