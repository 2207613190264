import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityTypeConfig } from '../airs_entity/airs-entity-type-config.entity';

@ValueObject({
	singularName: 'Remediation Action Related Entity',
	pluralName: 'Remediation Action Related Entities',
	readonly: true,
})
export class RemediationActionRelatedEntity extends ModelBase {
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() readonly id: number;

	@EntityField() readonly name: string;

	@EntityField({ data: 'av_key' })
	readonly avKey: string;

	@EntityField({ data: 'av_scheme' })
	readonly avScheme: string;

	@EntityField({ data: 'entity_type' })
	readonly type: AirsEntityTypeConfig;
}
