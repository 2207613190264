import { ICommandBarItemOptionsRenderIconContext } from '@angular-react/fabric';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { getTheme } from '@uifabric/styling';
import { IContextualMenuItem } from 'office-ui-fabric-react';
import { IconComponent } from '@wcd/icons';

@Component({
	selector: 'command-bar-custom-icon',
	/* No encapsulation to be able to override the icon. BEM is used to ensure this does not override anything else */
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-shared-icon [iconName]="iconName" [style.color]="iconColor" class="CommandBarCustomIcon" [class.command-bar-custom-icon-disabled]="disabled"
			[ariaLabel]="''">
		</wcd-shared-icon>
	`,
	styleUrls: ['./command-bar-custom-icon.component.scss'],
})
export class CommandBarCustomIconComponent implements OnInit, ICommandBarItemOptionsRenderIconContext {
	private _contextualMenuItem: IContextualMenuItem;
	disabled: boolean;

	get contextualMenuItem(): IContextualMenuItem {
		return this._contextualMenuItem;
	}

	@Input()
	set contextualMenuItem(value: IContextualMenuItem) {
		this.disabled = !!value.disabled;
		this._contextualMenuItem = value;

		this.iconName =
			this._contextualMenuItem &&
			this._contextualMenuItem.iconProps &&
			this._contextualMenuItem.iconProps.iconName;
	}

	iconColor: string;
	iconName: IconComponent['iconName'];

	ngOnInit() {
		const { semanticColors } = getTheme();

		this.iconColor = semanticColors.menuIcon;
	}
}
