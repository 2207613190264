import { ComponentRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { pickBy } from 'lodash-es';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Feature, FeaturesService } from '@wcd/config';
import { DialogsService } from '../services/dialogs.service';
import { ShortcutComponent } from './shortcut.component';

const TEXT_ANGULAR_ELEMENT_ID_PREFIX = 'taTextElement';

export enum ShortcutEvent {
	search,
	flags,
}

const SHORTCUT_KEYS: { [index: string]: IShortcut } = {
	// "a": {
	// 	name: "alert",
	// 	iconName: "alert",
	// 	placeholder: "Go to Alert with ID",
	// 	url: "/alert",
	// 	allowForFeatures: [Feature.AutoInvestigationEnabled]
	// },
	// "i": {
	// 	name: "investigation",
	// 	iconName: "investigation",
	// 	placeholder: "Go to investigation with ID",
	// 	url: "/investigation",
	// 	allowForFeatures: [Feature.AutoInvestigationEnabled]
	// },
	// "m": {
	// 	name: "endpoint",
	// 	iconName: "System",
	// 	placeholder: "Search for machines",
	// 	url: "/search/machines",
	// 	allowForFeatures: [Feature.AutoInvestigationEnabled]
	// },
	// "f": {
	// 	name: "file",
	// 	iconName: "entities.file",
	// 	placeholder: "Go to file with SHA1",
	// 	url: "/file",
	// 	allowForFeatures: [Feature.AutoInvestigationEnabled]
	// },
	F: {
		name: 'flags',
		allowForFeatures: [Feature.Flags],
		event: ShortcutEvent.flags,
		allowInput: false,
	},
	s: {
		name: 'search',
		event: ShortcutEvent.search,
		allowInput: false,
	},
};

@Injectable()
export class ShortcutsService {
	private allowedShortcuts: { [index: string]: IShortcut };

	private _events$: Subject<ShortcutEvent> = new Subject();
	private keySub$: Subscription;
	events$: Observable<ShortcutEvent> = this._events$.asObservable();

	constructor(
		private dialogsService: DialogsService,
		private router: Router,
		private featuresService: FeaturesService
	) {}

	init() {
		this.allowedShortcuts = pickBy(SHORTCUT_KEYS, (shortcutConfig: IShortcut, key: string) => {
			if (!shortcutConfig.allowForFeatures) return true;

			const features: Array<Feature> = shortcutConfig.allowForFeatures || [];
			return features.every((feature: Feature) => this.featuresService.isEnabled(feature));
		});

		this.keySub$ = fromEvent(document, 'keyup')
			.pipe(
				filter((e: KeyboardEvent) => {
					const el: Element = <Element>e.target;
					return !(
						el.nodeName === 'INPUT' ||
						el.nodeName === 'TEXTAREA' ||
						el.nodeName === 'SELECT' ||
						el.id.startsWith(TEXT_ANGULAR_ELEMENT_ID_PREFIX) ||
						el.getAttribute('contentEditable') === 'true'
					);
				})
			)
			.subscribe((e: KeyboardEvent) => this.onKeyDown(e));
	}

	onKeyDown(e: KeyboardEvent) {
		const shortcut = this.allowedShortcuts[e.key];

		if (!shortcut || e.ctrlKey) return true;

		if (shortcut.event !== undefined) this._events$.next(shortcut.event);

		if (shortcut.allowInput === false) return true;

		const onEnter: (value: string) => void = this.onEnterShortcut.bind(this, shortcut);

		let modal: ComponentRef<any>;
		this.dialogsService
			.showModal(
				ShortcutComponent,
				{
					id: 'shortcut-modal',
				},
				{
					shortcut: shortcut,
					onEnterShortcut: (value: string) => {
						modal.destroy();
						onEnter(value);
					},
				}
			)
			.subscribe(_modal => (modal = _modal));

		return true;
	}

	onEnterShortcut(shortcut: IShortcut, value: string) {
		const path: string = `${shortcut.url}/${value}`;
		this.router.navigate([path]);
	}

	ngOnDestroy() {
		this.keySub$ && this.keySub$.unsubscribe();
	}
}

export interface IShortcut {
	name: string;
	placeholder?: string;
	iconName?: string;
	url?: string;
	allowForFeatures?: Array<Feature>;
	event?: ShortcutEvent;
	allowInput?: boolean;
}
