var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, ValueObject, ModelBase } from '@microsoft/paris';
import { Machine } from '../machine/machine.entity';
import { File, parseFileItemQuery } from './file.entity';
var FileCreatedBy = /** @class */ (function (_super) {
    __extends(FileCreatedBy, _super);
    function FileCreatedBy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'ProcessId' }),
        __metadata("design:type", String)
    ], FileCreatedBy.prototype, "processId", void 0);
    __decorate([
        EntityField({ data: 'ProcessName' }),
        __metadata("design:type", String)
    ], FileCreatedBy.prototype, "processName", void 0);
    FileCreatedBy = __decorate([
        ValueObject({
            singularName: 'File Created By',
            pluralName: 'File Created By'
        })
    ], FileCreatedBy);
    return FileCreatedBy;
}(ModelBase));
export { FileCreatedBy };
var MarkOfWeb = /** @class */ (function (_super) {
    __extends(MarkOfWeb, _super);
    function MarkOfWeb() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Zone' }),
        __metadata("design:type", String)
    ], MarkOfWeb.prototype, "zone", void 0);
    __decorate([
        EntityField({ data: 'HostUrl' }),
        __metadata("design:type", String)
    ], MarkOfWeb.prototype, "hostUrl", void 0);
    __decorate([
        EntityField({ data: 'ReferrerUrl' }),
        __metadata("design:type", String)
    ], MarkOfWeb.prototype, "referrerUrl", void 0);
    __decorate([
        EntityField({ data: 'HostIpAddress' }),
        __metadata("design:type", String)
    ], MarkOfWeb.prototype, "hostIpAddress", void 0);
    MarkOfWeb = __decorate([
        ValueObject({
            singularName: 'Mark of web',
            pluralName: 'Marks of web'
        })
    ], MarkOfWeb);
    return MarkOfWeb;
}(ModelBase));
export { MarkOfWeb };
var ɵ0 = function (config, query) {
    return query.where && query.where['newFilesApi']
        ? config.data.serviceUrls.cyberProfileService
        : config.data.serviceUrls.threatIntel;
}, ɵ1 = ['Sha1', 'Sha256', 'id'], ɵ2 = ['FolderPath'], ɵ3 = ['FullPath'], ɵ4 = ['MachineId'];
var FileInstance = /** @class */ (function (_super) {
    __extends(FileInstance, _super);
    function FileInstance() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: ɵ1 }),
        __metadata("design:type", String)
    ], FileInstance.prototype, "id", void 0);
    __decorate([
        EntityField({ data: ɵ2 }),
        __metadata("design:type", String)
    ], FileInstance.prototype, "folderPath", void 0);
    __decorate([
        EntityField({ data: ɵ3 }),
        __metadata("design:type", String)
    ], FileInstance.prototype, "fullPath", void 0);
    __decorate([
        EntityField({ data: ɵ4 }),
        __metadata("design:type", Machine)
    ], FileInstance.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'IsAzureInfoProtectionApplied' }),
        __metadata("design:type", Boolean)
    ], FileInstance.prototype, "isAzureInfoProtectionApplied", void 0);
    __decorate([
        EntityField({ data: 'CreationTime' }),
        __metadata("design:type", Date)
    ], FileInstance.prototype, "creationTime", void 0);
    __decorate([
        EntityField({ data: 'deviceName' }),
        __metadata("design:type", String)
    ], FileInstance.prototype, "deviceName", void 0);
    __decorate([
        EntityField({ data: 'deviceId' }),
        __metadata("design:type", String)
    ], FileInstance.prototype, "deviceId", void 0);
    __decorate([
        EntityField({ data: 'MarkOfWeb' }),
        __metadata("design:type", MarkOfWeb)
    ], FileInstance.prototype, "markOfWeb", void 0);
    __decorate([
        EntityField({ parse: function (_, entity) { return { ProcessId: entity.CreatingProcessId, ProcessName: entity.CreatingProcessName }; } }),
        __metadata("design:type", FileCreatedBy)
    ], FileInstance.prototype, "createdBy", void 0);
    __decorate([
        EntityField({ data: 'LastFileEvent' }),
        __metadata("design:type", Date)
    ], FileInstance.prototype, "lastFileEvent", void 0);
    __decorate([
        EntityField({ data: 'MergeByKey' }),
        __metadata("design:type", String)
    ], FileInstance.prototype, "uniqueEntityId", void 0);
    FileInstance = __decorate([
        Entity({
            singularName: 'File',
            pluralName: 'Files',
            cache: {
                time: 1000 * 60,
                max: 10,
            },
            readonly: true,
            endpoint: "files",
            parseItemQuery: parseFileItemQuery,
            baseUrl: ɵ0,
        })
    ], FileInstance);
    return FileInstance;
}(File));
export { FileInstance };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
