import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Machine } from '../machine.entity';
import * as AntiVirusTypes from './antivirus-scan/antivirus-scan.models';
import * as RawIsolationTypes from './isolation/isolation-requests.models';
import { MachineResponseType } from './machine-responses.models';
import * as RestrictPolicyTypes from './restrict-app-execution/restrict-app-execution.models';
import { MachineRequestErrorCode } from './machine-request-error-code.value-object';
type AntivirusScanType = AntiVirusTypes.AntivirusScanType;
type IsolationAction = RawIsolationTypes.IsolationAction;
type IsolationType = RawIsolationTypes.IsolationType;
type RestrictPolicyType = RestrictPolicyTypes.RestrictPolicyType;
export type RequestStatus = 'None' | 'Cancelled' | 'Failed' | 'Submitted' | 'InProgress' | 'Succeeded';

@ValueObject({
	readonly: true,
	singularName: 'Device request',
	pluralName: 'Device requests',
	modelWith: ({ Type: type }: { Type: MachineResponseType }) => {
		switch (type) {
			case 'ForensicsResponse':
				return MachineCollectInvesigationPackageRequest;
			case 'IsolationResponse':
				return MachineIsolationRequest;
			case 'RestrictExecutionResponse':
				return MachineRestrictAppExecutionRequest;
			case 'ScanResponse':
				return MachineRunAntivirusScanRequest;
			case 'TroubleshootResponse':
				return TroubleshootModeRequest;
			case 'LogsCollectionResponse':
				return MachineLogsCollectionRequest;
			default:
				return null;
		}
	},
})
export class MachineRequestBase<TType extends MachineResponseType = MachineResponseType> extends ModelBase {
	@EntityField({ data: 'MachineId' })
	readonly machineId: Machine['id'];

	@EntityField({ data: 'CreationDateTimeUtc' })
	readonly creationDateTimeUtc: Date;

	@EntityField({ data: 'LastUpdateTimeUtc' })
	readonly lastUpdateTimeUtc: Date;

	@EntityField({ data: 'Requestor' })
	readonly requestor: string;

	@EntityField({ data: 'RequestorComment' })
	readonly requestorComment: string;

	@EntityField({ data: 'CancellationRequestor' })
	readonly cancellationRequestor: string;

	@EntityField({ data: 'CancellationComment' })
	readonly cancellationComment: string;

	@EntityField({ data: 'CancellationDateTimeUtc' })
	readonly cancellationDateTimeUtc?: Date;

	@EntityField({ data: 'RequestGuid' })
	readonly requestGuid: string;

	@EntityField({ data: 'RequestStatus' })
	readonly requestStatus: RequestStatus;

	@EntityField({ data: 'Type' })
	readonly type: TType;

	@EntityField({ data: 'ErrorHResult' })
	readonly errorCode?: MachineRequestErrorCode;

	@EntityField({ data: 'PackageIdentity' })
	packageIdentity?: string;
}

/**
 * All of these subtypes are defined here to avoid circular dependency.
 * `MachineRequestBase` references them for the `modelWith` function, and all the sub-types `extend MachineRequestBase`.
 */

@ValueObject({
	readonly: true,
	singularName: 'Device forensics request',
	pluralName: '',
})
export class MachineCollectInvesigationPackageRequest extends MachineRequestBase<'ForensicsResponse'> {
	@EntityField({ data: 'Sha256' })
	readonly sha256: string | null;
}

@ValueObject({
	readonly: true,
	singularName: 'Device scan request',
	pluralName: '',
})
export class MachineRunAntivirusScanRequest extends MachineRequestBase<'ScanResponse'> {
	@EntityField({
		data: 'ScanType',
		parse: (rawValue: AntiVirusTypes.QueryAntivirusScanTypeRaw) =>
			AntiVirusTypes.QueryAntivirusScanTypeRaw[rawValue],
	})
	readonly scanType: AntivirusScanType;
}

@ValueObject({
	readonly: true,
	singularName: 'Device isolation request',
	pluralName: '',
})
export class MachineIsolationRequest extends MachineRequestBase<'IsolationResponse'> {
	@EntityField({
		data: 'Action',
		parse: (rawValue: RawIsolationTypes.RawIsolationAction) =>
			RawIsolationTypes.RawIsolationAction[rawValue],
	})
	readonly action: IsolationAction;

	@EntityField({
		data: 'IsolationType',
		parse: (rawValue: RawIsolationTypes.RawIsolationType) => RawIsolationTypes.RawIsolationType[rawValue],
	})
	readonly isolationType: IsolationType;
}

@ValueObject({
	readonly: true,
	singularName: 'Device restrict app execution request',
	pluralName: '',
})
export class MachineRestrictAppExecutionRequest extends MachineRequestBase<'RestrictExecutionResponse'> {
	@EntityField({
		data: 'PolicyType',
		parse: (rawValue: RestrictPolicyTypes.QueryExecutionPolicyTypeRaw) =>
			RestrictPolicyTypes.QueryExecutionPolicyTypeRaw[rawValue],
	})
	readonly policyType: RestrictPolicyType;
}

@ValueObject({
	readonly: true,
	singularName: 'Troubleshoot request',
	pluralName: '',
})
export class TroubleshootModeRequest extends MachineRequestBase<'TroubleshootResponse'> {
	@EntityField({
		data: 'TroubleshootExpirationDateTimeUtc',
	})
	readonly troubleshootExpirationDateTimeUtc: Date;

	@EntityField({
		data: 'TroubleshootStartDateTimeUtc',
	})
	readonly troubleshootStartDateTimeUtc: Date;

	@EntityField({
		data: 'RequestSource'
	})
	requestSource?: string;

	@EntityField({
		data: 'TroubleshootState'
	})
	readonly troubleshootState: Number;
}

@ValueObject({
	readonly: true,
	singularName: 'Collect support logs request',
	pluralName: '',
})
export class MachineLogsCollectionRequest extends MachineRequestBase<'LogsCollectionResponse'> {
	@EntityField({ data: 'Sha256' })
	readonly sha256: string | null;
}

/**
 * Describes any `*MachineRequest`.
 * 1. When used without a generic argument (default value) - describes all possible `*MachineRequest` as a union type.
 * 2. When given a `MachineRequestType` as a generic argument, returns the corresponding type that extends MachineRequest.
 *
 * Although this seems complicated - it's pretty straightforward:
 * Each conditional check checks if the `TRequestType` is a specific one (via a "reverse" `extends`),
 * if not it falls back to a union of all possible types.
 */
export type MachineRequest<
	TResponseType extends MachineResponseType = MachineResponseType
> = TResponseType extends 'TroubleshootResponse'
? TroubleshootModeRequest
	: TResponseType extends 'RestrictExecutionResponse'
	? MachineRestrictAppExecutionRequest
	: TResponseType extends 'ScanResponse'
	? MachineRunAntivirusScanRequest
	: TResponseType extends 'ForensicsResponse'
	? MachineCollectInvesigationPackageRequest
	: TResponseType extends 'IsolationResponse'
	? MachineIsolationRequest
	: TResponseType extends 'LogsCollectionRequest'
	? MachineLogsCollectionRequest
    :
			| MachineIsolationRequest
			| MachineRestrictAppExecutionRequest
			| MachineRunAntivirusScanRequest
			| MachineCollectInvesigationPackageRequest
			| MachineLogsCollectionRequest
