
		<ng-container *ngLet="{ metadata: metadata$ | async, stateData: emailData$ | async } as data">
			<dl class="key-values clearfix" role="none">
				<ng-container *ngIf="entity.subject">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_subject }}</dt>
					<dd role="none">{{ entity.subject }}</dd>
				</ng-container>
				<ng-container *ngIf="!isEmailSubmission">
					<ng-container *ngIf="entity.receivedDate">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailMessage_fields_emailReceivedDate }}
						</dt>
						<dd role="none">{{ entity.receivedDate | date: 'short' }}</dd>
					</ng-container>
					<ng-container *ngIf="entity.deliveryStatus">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailMessage_fields_deliveryStatus }}
						</dt>
						<dd role="none">{{ entity.deliveryStatus }}</dd>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="entity.sender">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_sender }}</dt>
					<dd role="none">{{ entity.sender }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.recipient">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_recipient }}</dt>
					<dd role="none">{{ entity.recipient }}</dd>
				</ng-container>
				<ng-container *ngIf="isEmailSubmission">
					<ng-container *ngIf="emailSubmission.submitter">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailSubmission_fields_reportedBy }}
						</dt>
						<dd role="none">{{ emailSubmission.submitter }}</dd>
					</ng-container>
					<ng-container *ngIf="emailSubmission.timestamp">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailSubmission_fields_deliveredOn }}
						</dt>
						<dd role="none">{{ emailSubmission.timestamp | date: 'short' }}</dd>
					</ng-container>
					<ng-container *ngIf="emailSubmission.reportedOn">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailSubmission_fields_reportedOn }}
						</dt>
						<dd role="none">{{ emailSubmission.reportedOn | date: 'short' }}</dd>
					</ng-container>
					<ng-container *ngIf="emailSubmission.reportTypeI18nKey">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailSubmission_fields_reportType }}
						</dt>
						<dd role="none">
							{{
								i18nService.get(
									'airsEntities_emailSubmission_fields_reportType_' +
										emailSubmission.reportTypeI18nKey,
									null,
									true
								) || emailSubmission.reportTypeI18nKey
							}}
						</dd>
					</ng-container>
					<ng-container *ngIf="emailSubmission.submissionId">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailSubmission_fields_submissionId }}
						</dt>
						<dd role="none">{{ emailSubmission.submissionId }}</dd>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="entity.senderIp">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_senderIp }}</dt>
					<dd role="none">{{ entity.senderIp }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.internetMessageId">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_internetMessageId }}
					</dt>
					<dd role="none">{{ entity.internetMessageId }}</dd>
				</ng-container>
				<dt role="none">
					{{ i18nService.strings.airsEntities_emailMessage_fields_attachments }}
				</dt>
				<dd role="none">
					<email-entity-attachments-field
						[attachments]="entity.attachments"
					></email-entity-attachments-field>
				</dd>
				<ng-container *ngIf="data?.metadata?.potentiallyHarmfulUrls">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_urls_and_threats }}
					</dt>
					<dd role="none">
						<email-entity-urls-field
							[urls]="entity.urls"
							[potentiallyHarmfulUrls]="data.metadata.potentiallyHarmfulUrls"
						></email-entity-urls-field>
					</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.originalDeliveryLocation">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_deliveryLocation }}
					</dt>
					<dd role="none">
						{{
							'airsEntities_emailMessage_deliveryLocation_' +
								data.metadata.originalDeliveryLocation | i18n
						}}
					</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.latestDeliveryLocation">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_latestDeliveryLocation }}
					</dt>
					<dd role="none">
						{{
							'airsEntities_emailMessage_deliveryLocation_' +
								data.metadata.latestDeliveryLocation | i18n
						}}
					</dd>
				</ng-container>
				<ng-container
					*ngIf="threatsDisplayNameService.getThreatsDisplayName(entity.threats) as threatsDisplay"
				>
					<dt role="none">{{ i18nService.strings.airsEntities_general_fields_threats }}</dt>
					<dd role="none">{{ threatsDisplay }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.attachmentFileNames">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_attachmentFileNames }}
					</dt>
					<dd role="none">{{ entity.attachmentFileNames }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.attachmentFileHashes">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_attachmentSha256 }}
					</dt>
					<dd role="none">{{ entity.attachmentFileHashes }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.emailHeaders">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_emailHeaders }}
					</dt>
					<dd role="none">{{ entity.emailHeaders }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.emailDownload">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_emailDownload }}
					</dt>
					<dd role="none">{{ entity.emailDownload }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.networkMessageId">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_networkMessageId }}
					</dt>
					<dd role="none">{{ entity.networkMessageId }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.deliveryAction">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_deliveryAction }}
					</dt>
					<dd role="none">{{ entity.deliveryAction }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.clusterId">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_clusterId }}</dt>
					<dd role="none">{{ entity.clusterId }}</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.senderDisplayName">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_senderDisplayName }}
					</dt>
					<dd role="none">{{ data.metadata.senderDisplayName }}</dd>
				</ng-container>
				<ng-container
					*ngIf="
						data?.metadata &&
						emailDetectionTechnologiesDisplayNameService.getEmailThreatDetectionMethodsDisplayName(
							data.metadata
						) as detectionMethodsDisplay
					"
				>
					<dt role="none">
						{{
							i18nService.strings.airsEntities_emailMessage_fields_threatsDetectionTechnologies
						}}
					</dt>
					<dd role="none">{{ detectionMethodsDisplay }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.attachmentMalwareFamily">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_attachmentMalwareFamily }}
					</dt>
					<dd role="none">{{ entity.attachmentMalwareFamily }}</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.language">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_language }}</dt>
					<dd role="none">{{ data.metadata.language }}</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.returnPath">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_returnPath }}
					</dt>
					<dd role="none">{{ data.metadata.returnPath }}</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.directionalityI18nKey">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_directionality }}
					</dt>
					<dd role="none">
						{{
							'airsEntities_emailMessage_antispamDirection_' +
								data.metadata.directionalityI18nKey | i18n
						}}
					</dd>
				</ng-container>
			</dl>
			<div *ngIf="emailDetailsLink" class="wcd-padding-vertical">
				<a [href]="emailDetailsLink" target="_blank">
					{{ i18nService.strings.airsEntities_emailMessage_emailDetailsLink }}
					<fab-icon iconName="OpenInNewWindow" className="small-icon"></fab-icon>
				</a>
			</div>
			<ng-container
				*ngIf="data?.metadata === LOADING_SYMBOL || data?.stateData === LOADING_SYMBOL"
				[ngTemplateOutlet]="loader"
			>
			</ng-container>
			<ng-container *ngIf="data?.stateData as stateData">
				<ng-container *ngIf="stateData?.quarantineMessage; else noQuarantineView">
					<div>
						<fab-primary-button
							(onClick)="openQuarantineEmailHeadersPanel(stateData.quarantineMessage)"
							[text]="i18nService.strings.airsEntities_email_headers_panel_button"
							[iconProps]="{ iconName: 'Header' }"
						>
						</fab-primary-button>
						<fab-primary-button
							*ngIf="stateData?.isQuarantineViewAllowed && data?.metadata?.internetMessageId"
							(onClick)="goToQuarantinePage(data?.metadata?.internetMessageId)"
							[iconProps]="{ iconName: 'OpenInNewWindow' }"
							[text]="i18nService.strings.airsEntities_emailMessage_viewQuarantinedEmail_text"
						>
						</fab-primary-button>
					</div>
				</ng-container>
				<ng-template #noQuarantineView>
					<div *ngIf="data.metadata?.deliveredToMailbox">
						<fab-primary-button
							(onClick)="openEmailHeadersPanel(data.metadata)"
							[text]="i18nService.strings.airsEntities_email_headers_panel_button"
							[iconProps]="{ iconName: 'Header' }"
						>
						</fab-primary-button>
						<fab-primary-button
							*ngIf="stateData?.isInPreview"
							(onClick)="openDownloadEmailPanel(data.metadata)"
							[iconProps]="{
								iconName: 'Download'
							}"
							[text]="i18nService.strings.airsEntities_emailMessage_downloadEmail_text"
						>
						</fab-primary-button>
					</div>
				</ng-template>
			</ng-container>
		</ng-container>
		<ng-template #loader>
			<div class="wcd-flex-center-all wcd-padding-all">
				<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
			</div>
		</ng-template>
	