export enum InvestigationStatusEnum {
	running = 0,
	benign = 1,
	pending = 2,
	terminatedBySystem = 3,
	terminatedByUser = 4,
	failed = 5,
	fullyRemediated = 6,
	partiallyRemediated = 7,
	partiallyInvestigated = 8,
	pendingResource = 20,
	pendingUser = 21,

	queued = -1,
	disabled = -2,
	innerFailure = -3,
	unsupportedAlertType = -4,
	unsupportedOs = -5,
	preexistingAlert = -6,
	suppressedAlert = -7,
}

export enum InvestigationStatusNameEnum {
	Running = 'Running',
	Benign = 'Benign',
	Pending = 'Pending',
	TerminatedBySystem = 'TerminatedBySystem',
	TerminatedByUser = 'TerminatedByUser',
	Failed = 'Failed',
	FullyRemediated = 'FullyRemediated',
	PartiallyRemediated = 'PartiallyRemediated',
	PartiallyInvestigated = 'PartiallyInvestigated',
	PendingResource = 'PendingResource',
	PendingUser = 'PendingUser',

	Queued = 'Queued',
	Disabled = 'Disabled',
	InnerFailure = 'InnerFailure',
	UnsupportedAlertType = 'UnsupportedAlertType',
	UnsupportedOs = 'UnsupportedOs',
	PreexistingAlert = 'PreexistingAlert',
	SuppressedAlert = 'SuppressedAlert',
}
