<div class="wcd-flex-horizontal" *ngIf="!flatView" data-track-component="AirsEntityRelations">
	<div class="wcd-flex-none wcd-margin-large-right">
		<ul class="nav-tree">
			<li *ngFor="let relation of entity.relations">
				<h5>
					<wcd-shared-icon [iconName]="relation.entityType.icon"> </wcd-shared-icon>
					{{ relation.count }}
					{{ relation.count === 1 ? relation.entityType.singularName : relation.entityType.name }}
				</h5>
				<ul class="nav-tree">
					<li *ngFor="let relationType of relation.relationTypes">
						<a
							(click)="setCurrentRelationType(relationType, relation)"
							(keydown.enter)="setCurrentRelationType(relationType, relation)"
							data-track-id="SetCurrentRelationType"
							data-track-type="Action"
							tabindex="0"
							role="tab"
							[ngClass]="{ active: relationType === currentRelationType }"
						>
							{{ relationType.relationName }} ({{ relationType.entities.length }})
							<ul
								*ngIf="relationTypeCounts.get(relationType)"
								class="inline-list unstyled wcd-margin-bottom wcd-margin-left"
							>
								<li *ngFor="let statusCount of relationTypeCounts.get(relationType)">
									<span [ngClass]="'color-text-' + statusCount.status.className">
										<wcd-shared-icon
											[iconName]="statusCount.status.iconName"
											class="small-icon"
										>
										</wcd-shared-icon>
										{{ statusCount.status.name }} ({{ statusCount.count }})
									</span>
								</li>
							</ul>
						</a>
					</li>
				</ul>
			</li>
		</ul>
	</div>
	<div class="wcd-flex-1">
		<h4 class="no-margin-top" style="position: relative; top: -3px">
			{{ currentRelation.entityType.name }}
			<wcd-shared-icon [iconName]="'carets.right'" class="small-icon"> </wcd-shared-icon>
			{{ currentRelationType.relationName }}
		</h4>
		<ul class="unstyled">
			<li *ngFor="let relationEntity of currentRelationType.entities">
				<a
					data-track-id="AddRelation"
					data-track-type="Action"
					tabindex="0"
					(click)="addRelation(currentRelation.entityType, relationEntity)"
					(keydown.enter)="addRelation(currentRelation.entityType, relationEntity)"
				>
					<wcd-shared-icon
						[iconName]="currentRelation.entityType.icon"
						class="small-icon color-text-gray-700"
					>
					</wcd-shared-icon>
					{{ relationEntity.name }}
					<span
						class="small-status-label color-box-{{ relationEntity.status.className }}"
						*ngIf="relationEntity.status.isImportant"
						>{{ relationEntity.status.name }}</span
					>
					<wcd-shared-icon
						[iconName]="'warning'"
						wcdTooltip="Has remediation actions"
						*ngIf="relationEntity.remediationActions?.length"
						class="color-text-partial"
					>
					</wcd-shared-icon>
				</a>
			</li>
		</ul>
	</div>
</div>
<div *ngIf="flatView" class="entity-relations-tree" data-track-component="AirsEntityRelationsFlat">
	<section *ngFor="let relation of entity.relations" class="entity-relations-tree-section">
		<h4>
			<wcd-shared-icon [iconName]="relation.entityType.icon"> </wcd-shared-icon>
			{{ relation.count }}
			{{ relation.count === 1 ? relation.entityType.singularName : relation.entityType.name }}
		</h4>
		<div class="with-icon">
			<div *ngFor="let relationType of relation.relationTypes" class="entity-relations-tree-section">
				<h5>{{ relationType.relationName }} ({{ relationType.entities.length }})</h5>
				<ul class="unstyled">
					<li *ngFor="let relationEntity of relationType.entities">
						<wcd-shared-icon [iconName]="currentRelation.entityType.icon" class="small-icon">
						</wcd-shared-icon>
						{{ relationEntity.name }}
						<span
							class="small-status-label color-box-{{ relationEntity.status.className }}"
							*ngIf="relationEntity.status.isImportant"
							>{{ relationEntity.status.name }}</span
						>
						<wcd-shared-icon
							[iconName]="'warning'"
							*ngIf="relationEntity.remediationActions"
							class="color-text-partial"
						>
						</wcd-shared-icon>
					</li>
				</ul>
			</div>
		</div>
	</section>
</div>
