import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params } from '@angular/router';

@Component({
	selector: 'wcd-buttons-bar-widget',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="buttons-bar-widget">
			<div class="buttons-bar-button" *ngFor="let button of buttons">
				<div class="buttons-bar-icon" *ngIf="button.icon || button.image">
					<img *ngIf="button.image" [src]="button.image" />
					<wcd-shared-icon
						*ngIf="button.icon"
						[ngClass]="button.iconClassName"
						[iconName]="button.icon"
					></wcd-shared-icon>
				</div>
				<div class="buttons-bar-content">
					<div class="buttons-bar-value nowrap" *ngIf="button.value">
						{{ button.value }}
						<wcd-shared-icon
							*ngIf="button.trend"
							[iconName]="'arrow.' + button.trend"
							[ngClass]="button.trendCssClass || 'trend-' + button.trend"
						></wcd-shared-icon>
					</div>
					<div class="buttons-bar-text">
						<a
							*ngIf="button.url; else noLink"
							[routerLink]="button.url"
							data-track-type="Navigation"
							[attr.data-track-id]="button.id"
							[queryParams]="button.queryParams"
							[wcdTooltip]="button.tooltip"
						>
							{{ button.text }}
						</a>
						<ng-template #noLink>
							{{ button.text }}
						</ng-template>
						<wcd-help [text]="button.helpKey | i18n" *ngIf="button.helpKey"></wcd-help>
					</div>
				</div>
			</div>
		</div>
	`,
})
export class ButtonsBarWidgetComponent {
	@Input() buttons: Array<ButtonsBarButton>;
	@Input() itemsPerRow: number = 3;
}

export interface ButtonsBarButton {
	id: string;
	helpKey?: string;
	icon?: string;
	iconClassName?: string;
	image?: string;
	text?: string;
	tooltip?: string;
	url?: string;
	queryParams?: Params;
	value?: string;
	trend?: 'up' | 'down';
	trendCssClass?: string;
}
