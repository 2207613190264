import { Injectable } from '@angular/core';
import {
	AirsAppendedFile,
	AirsDriver,
	AirsEmail,
	AirsEmailCluster,
	AirsEmailLink,
	AirsEmailSubmission,
	AirsEntity,
	AirsEntityStatus,
	AirsEntityType,
	AirsEntityTypeConfig,
	AirsEntityTypeValue,
	AirsFile,
	AirsIp,
	AirsMailbox,
	AirsMailboxConfiguration,
	AirsPersistenceMethod,
	AirsProcess,
	AirsSecurityGroup,
	AirsRegistryKey,
	AirsRegistryValue,
	AirsCloudApplication,
	AirsCloudLogonSession,
	AirsOauthApplication,
	AirsActiveDirectoryDomain,
	AirsService,
	AirsUrl,
	AirsUser,
	AirsUserActivity,
	MappableAirsEntityTypes,
} from '@wcd/domain';
import { MachineNameComponent } from '../../../shared/components/machine-name.component';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { DataviewField } from '@wcd/dataview';
import { Paris } from '@microsoft/paris';
import { Feature, FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { EvidenceDetectionContextComponent } from '../../evidence/components/evidence-detection-context.component';
import { WcdIconNames } from '@wcd/icons-font';
import { ThreatsDisplayNameService } from '../../mtp_investigations/services/threats-display-name-field.service';

@Injectable()
export class AirsEntitiesFieldsService {
	constructor(
		private paris: Paris,
		private featuresService: FeaturesService,
		private i18nService: I18nService,
		private threatsDisplayNameService: ThreatsDisplayNameService
	) {}

	/**
	 * @deprecated
	 */
	private readonly entityTypeCommonDisplayFieldsOld = DataviewField.fromList<AirsEntity>([
		{
			id: 'entity_status',
			name: this.i18nService.strings.airsEntities_fields_verdict,
			getDisplay: (item) => (item.status ? item.status.name : ''),
			getCssClass: (item) =>
				`wcd-padding-small-left color-border-${
					(item.status && item.status.className) || 'neutralQuaternaryAlt'
				}`,
			filter: {
				component: {
					type: FilterValuesChecklistComponent,
					config: {
						mapFilterValue: (entityStatusId: string) => {
							const entityStatus: AirsEntityStatus = this.paris.getValue(
								AirsEntityStatus,
								parseInt(entityStatusId, 10)
							);

							return {
								id: entityStatusId,
								name: entityStatus.name,
								nameClass: `wcd-padding-small-left color-border-${
									entityStatus.className || 'gray-200'
								}`,
							};
						},
					},
				},
			},
			custom: {
				allowFilterValueTracking: true,
			},
			sort: {
				enabled: true,
				sortDescendingByDefault: false,
			},
		},
	]);

	private readonly entityTypeCommonDisplayFieldsNew: Array<DataviewField> = DataviewField.fromList<
		AirsEntity
	>([
		{
			id: 'verdict',
			name: this.i18nService.strings.airsEntities_fields_verdict,
			getDisplay: (item) => item.verdict && item.verdict.name,
			getCssClass: (item) =>
				item.verdict
					? `wcd-padding-small-left color-border-${
							item.verdict.className || 'neutralQuaternaryAlt'
					  }`
					: null,
			sort: {
				enabled: false,
			},
		},
		{
			id: 'remediation_status',
			name: this.i18nService.strings.airsEntities_fields_remediationStatus,
			getDisplay: (item) => item.remediationStatus && item.remediationStatus.name,
			getCssClass: (item) =>
				item.remediationStatus
					? `wcd-padding-small-left color-border-${
							item.remediationStatus.className || 'neutralQuaternaryAlt'
					  }`
					: null,
			sort: {
				enabled: false,
			},
		},
	]);

	private readonly entityFirstSeen = new DataviewField<AirsEntity>({
		id: 'first_seen',
		name: this.i18nService.strings.airsEntities_fields_firstSeen,
		enabledByDefault: false,
		component: {
			type: TzDateComponent,
			getProps: (item) => ({ date: item.firstSeen }),
		},
		sort: { enabled: true },
	});

	private readonly entityDetectionContext = new DataviewField<AirsEntity>({
		id: 'detection_context',
		name: this.i18nService.strings.airsEntities_fields_detectionContext,
		enabledByDefault: false,
		component: {
			type: EvidenceDetectionContextComponent,
			getProps: (item) => ({
				detectionContext: item.detectionContext,
			}),
		},
		sort: { enabled: true },
	});

	private readonly investigationField = new DataviewField<AirsEntity>({
		id: 'investigation',
		name: this.i18nService.strings.airsEntities_fields_investigation,
		getDisplay: (item) =>
			item.investigation ? `${item.investigation.title} (${item.investigation.id})` : '',
		getLink: (item) => ['/investigation', String(item.investigation.id)],
		icon: {
			wcdIcon: WcdIconNames.investigation,
		},
		sort: { enabled: true },
	});

	private readonly addressField = new DataviewField<{ address: string }>({
		id: 'address',
		name: this.i18nService.strings.airsEntities_url_fields_address,
		sort: { enabled: true },
		enabledByDefault: true,
		getDisplay: (entity) => entity.address,
	});

	private readonly hostField = new DataviewField<AirsEntity>({
		id: 'host',
		name: this.i18nService.strings.machine,
		enabledByDefault: true,
		component: {
			type: MachineNameComponent,
			getProps: (entity: AirsEntity) => ({
				machine: entity.machine,
				showIcon: true,
				showLink: true,
				iconCssClass: 'small-icon',
			}),
		},
		sort: { enabled: true },
	});
	private readonly statusDetailsField = new DataviewField<AirsEntity>({
		id: 'status_details',
		name: this.i18nService.strings.evidence_fields_statusDetails,
		sort: { enabled: false },
		enabledByDefault: false,
		getDisplay: (entity) => entity.statusDetails,
	});

	private fieldsByType = Object.entries({
		[AirsEntityTypeValue.File]: [this.statusDetailsField as DataviewField<AirsFile>]
			.concat(
				DataviewField.fromList<AirsFile>([
					{
						id: 'path',
						name: this.i18nService.strings.airsEntities_file_fields_filePath,
						sort: { enabled: true },
						enabledByDefault: true,
						getDisplay: (entity) => entity.path,
					},
					{
						id: 'name',
						name: this.i18nService.strings.airsEntities_file_fields_fileName,
						sort: { enabled: true },
						enabledByDefault: true,
						getDisplay: (entity) => entity.entityName,
					},
				])
			)
			.concat([this.hostField]),
		[AirsEntityTypeValue.Process]: [this.statusDetailsField as DataviewField<AirsProcess>]
			.concat(
				DataviewField.fromList<AirsProcess>([
					{
						id: 'name',
						name: this.i18nService.strings.airsEntities_process_fields_processName,
						sort: { enabled: true },
						enabledByDefault: true,
						getDisplay: (entity) => entity.entityName,
					},
					{
						id: 'process_id',
						name: this.i18nService.strings.airsEntities_process_fields_processId,
						sort: { enabled: true },
						enabledByDefault: true,
						getDisplay: (entity) => entity.processId,
					},
				])
			)
			.concat([this.hostField])
			.concat(
				DataviewField.fromList<AirsProcess>([
					{
						id: 'created',
						name: this.i18nService.strings.airsEntities_process_fields_startTime,
						sort: { enabled: true },
						enabledByDefault: false,
						component: {
							type: TzDateComponent,
							getProps: (entity) => ({ date: entity.created }),
						},
					},
				])
			),
		[AirsEntityTypeValue.Driver]: DataviewField.fromList<AirsDriver>([
			{
				id: 'name',
				name: this.i18nService.strings.airsEntities_general_fields_name,
				sort: { enabled: true },
				enabledByDefault: true,
				getDisplay: (entity) => entity.entityName,
			},
			{
				id: 'display_name',
				name: this.i18nService.strings.airsEntities_driver_fields_displayName,
				sort: { enabled: true },
				enabledByDefault: true,
				getDisplay: (entity) => entity.displayName,
			},
			{
				id: 'bin_path',
				name: this.i18nService.strings.airsEntities_driver_fields_driverPath,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.binPath,
			},
		]).concat([this.hostField]),
		[AirsEntityTypeValue.Service]: DataviewField.fromList<AirsService>([
			{
				id: 'name',
				name: this.i18nService.strings.airsEntities_service_fields_serviceName,
				sort: { enabled: true },
				enabledByDefault: true,
				getDisplay: (entity) => entity.entityName,
			},
			{
				id: 'bin_path',
				name: this.i18nService.strings.airsEntities_service_fields_servicePath,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.binPath,
			},
		]).concat([this.hostField]),
		[AirsEntityTypeValue.URL]: [this.addressField as DataviewField<AirsUrl>],
		[AirsEntityTypeValue.IP]: DataviewField.fromList<AirsIp>([
			{
				id: 'address',
				name: this.i18nService.strings.airsEntities_ip_fields_address,
				sort: { enabled: true },
				enabledByDefault: true,
				getDisplay: (entity) => entity.address,
			},
		]),
		[AirsEntityTypeValue.AppendedFile]: DataviewField.fromList<AirsAppendedFile>([
			{
				id: 'name',
				name: this.i18nService.strings.airsEntities_file_fields_fileName,
				sort: { enabled: true },
				enabledByDefault: true,
				getDisplay: (entity) => entity.entityName,
			},
			{
				id: 'sha1',
				name: this.i18nService.strings.airsEntities_file_fields_hashes_sha1,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.sha1,
			},
		]),
		[AirsEntityTypeValue.EmailUrl]: [this.addressField as DataviewField<AirsEmailLink>].concat(
			DataviewField.fromList<AirsEmailLink>([
				{
					id: 'subject',
					name: this.i18nService.strings.airsEntities_url_fields_emailSubject,
					sort: { enabled: true },
					enabledByDefault: true,
					getDisplay: (entity) => entity.subject,
				},
			])
		),
		[AirsEntityTypeValue.User]: DataviewField.fromList<AirsUser>([
			{
				id: 'username',
				name: this.i18nService.strings.airsEntities_user_fields_username,
				sort: { enabled: true },
				enabledByDefault: true,
				getDisplay: (entity) => entity.username,
			},
			{
				id: 'email',
				name: this.i18nService.strings.airsEntities_user_fields_emailAddress,
				sort: { enabled: true },
				enabledByDefault: true,
				getDisplay: (entity) => entity.email,
			},
		]).concat([this.statusDetailsField]),
		[AirsEntityTypeValue.WebPage]: [this.addressField as DataviewField<AirsUrl>],
		[AirsEntityTypeValue.PersistenceMethod]: DataviewField.fromList<AirsPersistenceMethod>([
			{
				id: 'name',
				name: this.i18nService.strings.airsEntities_general_fields_name,
				sort: { enabled: true },
				enabledByDefault: true,
				getDisplay: (entity) => entity.entityName,
			},
			{
				id: 'method_type',
				name: this.i18nService.strings.airsEntities_persistenceMethod_fields_methodType,
				sort: { enabled: true },
				enabledByDefault: true,
				getDisplay: (entity) => entity.methodType,
			},
			{
				id: 'category',
				name: this.i18nService.strings.airsEntities_persistenceMethod_fields_category,
				sort: { enabled: true },
				enabledByDefault: true,
				getDisplay: (entity) => entity.category,
			},
			{
				id: 'command_line',
				name: this.i18nService.strings.airsEntities_persistenceMethod_fields_commandLine,
				sort: { enabled: true },
				enabledByDefault: true,
				getDisplay: (entity) => entity.commandLine,
			},
		]).concat([this.hostField]),
		[AirsEntityTypeValue.UserActivity]: DataviewField.fromList<AirsUserActivity>([
			{
				id: 'upn',
				name: this.i18nService.strings.airsEntities_userActivity_fields_username,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.upn,
			},
			{
				id: 'full_name',
				name: this.i18nService.strings.airsEntities_general_fields_name,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.fullName,
			},
			{
				id: 'user_object_id',
				name: this.i18nService.strings.airsEntities_userActivity_fields_aadId,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.userObjectId,
			},
		]),
		[AirsEntityTypeValue.MailCluster]: DataviewField.fromList<AirsEmailCluster>([
			{
				id: 'name',
				name: this.i18nService.strings.airsEntities_emailCluster_fields_name,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.entityName,
				className: 'nowrap wcd-text-overflow-medium',
				getTooltip: (entity) => entity.entityName,
			},
			{
				id: 'threats',
				name: this.i18nService.strings.airsEntities_general_fields_threats,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => this.threatsDisplayNameService.getThreatsDisplayName(entity.threats),
			},
			{
				id: 'mail_count',
				name: this.i18nService.strings.airsEntities_emailCluster_fields_emailCount,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.mailCount,
			},
			{
				id: 'malware_count',
				name: this.i18nService.strings.remediation_fields_malware,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					(entity.emailClusterThreats && entity.emailClusterThreats.malwareCount) || 0,
			},
			{
				id: 'phish_count',
				name: this.i18nService.strings.remediation_fields_phish,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					(entity.emailClusterThreats && entity.emailClusterThreats.phishCount) || 0,
			},
			{
				id: 'high_confidence_phish_count',
				name: this.i18nService.strings.remediation_fields_highConfidencePhish,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					(entity.emailClusterThreats && entity.emailClusterThreats.highConfidencePhishCount) || 0,
			},
			{
				id: 'spam_count',
				name: this.i18nService.strings.remediation_fields_spam,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					(entity.emailClusterThreats && entity.emailClusterThreats.spamCount) || 0,
			},
			{
				id: 'delivered_count',
				name: this.i18nService.strings.remediation_fields_delivered,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					(entity.emailClusterOriginalDeliveryLocations &&
						entity.emailClusterOriginalDeliveryLocations.deliveredCount) ||
					0,
			},
			{
				id: 'junked_count',
				name: this.i18nService.strings.remediation_fields_junked,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					(entity.emailClusterOriginalDeliveryLocations &&
						entity.emailClusterOriginalDeliveryLocations.junkedCount) ||
					0,
			},
			{
				id: 'replaced_count',
				name: this.i18nService.strings.remediation_fields_replaced,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					(entity.emailClusterOriginalDeliveryLocations &&
						entity.emailClusterOriginalDeliveryLocations.replacedCount) ||
					0,
			},
			{
				id: 'blocked_count',
				name: this.i18nService.strings.remediation_fields_blocked,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					(entity.emailClusterOriginalDeliveryLocations &&
						entity.emailClusterOriginalDeliveryLocations.blockedCount) ||
					0,
			},
			{
				id: 'mailbox_count',
				name: this.i18nService.strings.remediation_fields_mailbox,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					(entity.emailClusterDeliveryLocations &&
						entity.emailClusterDeliveryLocations.mailboxCount) ||
					0,
			},
			{
				id: 'not_in_mailbox_count',
				name: this.i18nService.strings.remediation_fields_notInMailbox,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					(entity.emailClusterDeliveryLocations &&
						entity.emailClusterDeliveryLocations.notInMailboxCount) ||
					0,
			},
			{
				id: 'on_prem_or_external_count',
				name: this.i18nService.strings.remediation_fields_onPremOrExternal,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					(entity.emailClusterDeliveryLocations &&
						entity.emailClusterDeliveryLocations.onPremOrExternalCount) ||
					0,
			},
			{
				id: 'volume_anomaly',
				name: this.i18nService.strings.airsEntities_emailCluster_fields_volumeAnomaly,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.volumeAnomaly,
			},
		]),
		[AirsEntityTypeValue.Mailbox]: DataviewField.fromList<AirsMailbox>([
			{
				id: 'display_name',
				name: this.i18nService.strings.airsEntities_mailbox_fields_displayName,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.displayName,
			},
			{
				id: 'primary_mail_address',
				name: this.i18nService.strings.airsEntities_mailbox_fields_primaryEmailAddress,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.primaryMailAddress,
			},
			{
				id: 'upn',
				name: this.i18nService.strings.airsEntities_mailbox_fields_upn,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.upn,
			},
			{
				id: 'fw_rule_name',
				name: this.i18nService.strings.airsEntities_mailbox_fields_forwardingRuleName,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.fwRuleName,
			},
			{
				id: 'fw_rule_creation_time',
				name: this.i18nService.strings.airsEntities_mailbox_fields_forwardingRuleCreationTime,
				sort: { enabled: false },
				enabledByDefault: true,
				component: {
					type: TzDateComponent,
					getProps: (entity) => ({ date: entity.fwRuleCreationTime }),
				},
			},
			{
				id: 'fw_rule_creator_alias',
				name: this.i18nService.strings.airsEntities_mailbox_fields_forwardingRuleCreatorAlias,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.fwRuleCreatorAlias,
			},
			{
				id: 'fw_rule_forwarding_SMTP_address',
				name: this.i18nService.strings.airsEntities_mailbox_fields_forwardingRuleSmtpAddress,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.fwRuleForwardingSMTPAddress,
			},
		]),
		[AirsEntityTypeValue.MailMessage]: DataviewField.fromList<AirsEmail>([
			{
				id: 'received_date',
				name: this.i18nService.strings.airsEntities_emailMessage_fields_emailReceivedDate,
				sort: { enabled: false },
				enabledByDefault: true,
				component: { type: TzDateComponent, getProps: (entity) => ({ date: entity.receivedDate }) },
			},
			{
				id: 'delivery_status',
				name: this.i18nService.strings.airsEntities_emailMessage_fields_deliveryStatus,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.deliveryStatus,
			},
			{
				id: 'subject',
				name: this.i18nService.strings.airsEntities_emailMessage_fields_subject,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.subject,
				className: 'nowrap wcd-text-overflow-medium',
				getTooltip: (entity) => entity.subject,
			},
			{
				id: 'sender',
				name: this.i18nService.strings.airsEntities_emailMessage_fields_sender,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.sender,
			},
			{
				id: 'recipient',
				name: this.i18nService.strings.airsEntities_emailMessage_fields_recipient,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.recipient,
			},
		]),
		[AirsEntityTypeValue.SubmissionMail]: DataviewField.fromList<AirsEmailSubmission>([
			{
				id: 'subject',
				name: this.i18nService.strings.airsEntities_emailMessage_fields_subject,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.subject,
			},
			{
				id: 'sender',
				name: this.i18nService.strings.airsEntities_emailMessage_fields_sender,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.sender,
			},
			{
				id: 'recipient',
				name: this.i18nService.strings.airsEntities_emailMessage_fields_recipient,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.recipient,
			},
			{
				id: 'submitter',
				name: this.i18nService.strings.airsEntities_emailSubmission_fields_reportedBy,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.submitter,
			},
			{
				id: 'reportType',
				name: this.i18nService.strings.airsEntities_emailSubmission_fields_reportType,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					this.i18nService.get(
						`airsEntities_emailSubmission_fields_reportType_${entity.reportTypeI18nKey}`,
						null,
						true
					) || entity.reportTypeI18nKey,
			},
		]),
		[AirsEntityTypeValue.MailboxConfiguration]: DataviewField.fromList<AirsMailboxConfiguration>([
			{
				id: 'config_type',
				name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_configurationAction,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) =>
					this.i18nService.get(
						`airsEntities_mailConfiguration_confType_${entity.configType}`,
						null,
						true
					) || entity.configType,
			},
			{
				id: 'performed_by',
				name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_performedBy,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.performedBy,
			},
			{
				id: 'mailbox_primary_address',
				name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_mailbox,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.mailboxPrimaryAddress,
			},
			{
				id: 'upn',
				name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_upn,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.upn,
			},
			{
				id: 'forwardingSmtpAddress',
				name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_forwardingSmtpAddress,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.forwardingSmtpAddress,
			},
			{
				id: 'actionTime',
				name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_actionTime,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.timestamp,
			},
		]),
		[AirsEntityTypeValue.SecurityGroup]: DataviewField.fromList<AirsSecurityGroup>([
			{
				id: 'name',
				name: this.i18nService.strings.airsEntities_securityGroup_fields_displayName,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.name,
			},
		]),
		[AirsEntityTypeValue.RegistryKey]: DataviewField.fromList<AirsRegistryKey>([
			{
				id: 'key',
				name: this.i18nService.strings.airsEntities_registryKey_fields_key,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.keyData,
			},
			{
				id: 'hive',
				name: this.i18nService.strings.airsEntities_registryKey_fields_hive,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.hive,
			},
		]),
		[AirsEntityTypeValue.RegistryValue]: DataviewField.fromList<AirsRegistryValue>([
			{
				id: 'name',
				name: this.i18nService.strings.airsEntities_registryValue_fields_name,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.name,
			},
			{
				id: 'value_type',
				name: this.i18nService.strings.airsEntities_registryValue_fields_type,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.valueType,
			},
			{
				id: 'value_data',
				name: this.i18nService.strings.airsEntities_registryValue_fields_data,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.valueData,
			},
			{
				id: 'key',
				name: this.i18nService.strings.airsEntities_registryKey_fields_key,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.keyData,
			},
			{
				id: 'hive',
				name: this.i18nService.strings.airsEntities_registryKey_fields_hive,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.hive,
			},
		]),
		[AirsEntityTypeValue.CloudApplication]: DataviewField.fromList<AirsCloudApplication>([
			{
				id: 'name',
				name: this.i18nService.strings.airsEntities_cloudApplication_fields_name,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.name,
			},
			{
				id: 'app_id',
				name: this.i18nService.strings.airsEntities_cloudApplication_fields_appId,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.appId,
			},
			{
				id: 'risk',
				name: this.i18nService.strings.airsEntities_cloudApplication_fields_risk,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.risk,
			},
		]),
		[AirsEntityTypeValue.CloudLogonSession]: DataviewField.fromList<AirsCloudLogonSession>([
			{
				id: 'username',
				name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_username,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.account && entity.account.username,
			},
			{
				id: 'nt_domain',
				name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_domain,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.account && entity.account.ntDomain,
			},
			{
				id: 'session_id',
				name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_sessionId,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.sessionId,
			},
			{
				id: 'sid',
				name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_userSid,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.account && entity.account.sid,
			},
			{
				id: 'display_name',
				name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_displayName,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.account && entity.account.userDisplayName,
			},
			{
				id: 'user_agent',
				name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_userAgent,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.userAgent,
			},
		]),
		[AirsEntityTypeValue.OauthApplication]: DataviewField.fromList<AirsOauthApplication>([
			{
				id: 'name',
				name: this.i18nService.strings.airsEntities_oauthApplication_fields_name,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.name,
			},
			{
				id: 'oauth_app_id',
				name: this.i18nService.strings.airsEntities_oauthApplication_fields_oauthAppId,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.oAuthAppId,
			},
			{
				id: 'oauth_object_id',
				name: this.i18nService.strings.airsEntities_oauthApplication_fields_oauthObjectId,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.oAuthObjectId,
			},
			{
				id: 'risk',
				name: this.i18nService.strings.airsEntities_oauthApplication_fields_risk,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.risk,
			},
		]),
		[AirsEntityTypeValue.ActiveDirectoryDomain]: DataviewField.fromList<AirsActiveDirectoryDomain>([
			{
				id: 'name',
				name: this.i18nService.strings.airsEntities_activeDirectoryDomain_fields_name,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.name,
			},
			{
				id: 'id',
				name: this.i18nService.strings.airsEntities_activeDirectoryDomain_fields_id,
				sort: { enabled: false },
				enabledByDefault: true,
				getDisplay: (entity) => entity.id,
			},
		]),
	} as Record<MappableAirsEntityTypes, Array<DataviewField<AirsEntity>>>).reduce((acc, [k, v]) => {
		acc[AirsEntityType[k]] = v;
		return acc;
	}, {});

	getEntityTypeDisplayFields(
		entityType: AirsEntityTypeConfig,
		allowInvestigationField: boolean = true,
		useNewStatus: boolean = false
	): Array<DataviewField> {
		const fieldsByTypeElement = this.fieldsByType[entityType.id];
		return [].concat(
			this.featuresService.isEnabled(Feature.EntityDetectionContext)
				? [this.entityFirstSeen as DataviewField]
				: [],
			useNewStatus ? this.entityTypeCommonDisplayFieldsNew : this.entityTypeCommonDisplayFieldsOld,
			fieldsByTypeElement,
			allowInvestigationField ? [this.investigationField] : [],
			this.featuresService.isEnabled(Feature.EntityDetectionContext)
				? [this.entityDetectionContext]
				: []
		);
	}
}
