var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of, Subject, timer } from 'rxjs';
import { MessageBarType } from 'office-ui-fabric-react';
import { PanelContainer } from '@wcd/panels';
import { EvaluationService } from '../../services/evaluation.service';
import { catchError, delayWhen, retryWhen, scan, tap } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
var EditSimulatorAgentsPanelComponent = /** @class */ (function (_super) {
    __extends(EditSimulatorAgentsPanelComponent, _super);
    function EditSimulatorAgentsPanelComponent(router, evaluationService, authService) {
        var _this = _super.call(this, router) || this;
        _this.evaluationService = evaluationService;
        _this.authService = authService;
        _this.saving = false;
        _this.error$ = new Subject();
        _this.canUpdate = false;
        _this.MessageBarType = MessageBarType;
        return _this;
    }
    EditSimulatorAgentsPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.evaluationService
            .getEvaluationLab()
            .pipe(tap(function (lab) {
            _this.agentsConfig = _this.getInitialAgentsConfig(lab);
        }))
            .subscribe();
    };
    EditSimulatorAgentsPanelComponent.prototype.getInitialAgentsConfig = function (lab) {
        var initialConfig = {
            withSafeBreach: false,
            withAiq: false,
            email: this.authService.currentUser.name,
        };
        // if any of the simulators were enabled, it means MS consent (both terms) was read & accepted
        if (lab.simulatorsSettings.AttackIQ.isEnabled || lab.simulatorsSettings.SafeBreach.isEnabled) {
            initialConfig.msTermsConsentRead = true;
            initialConfig.msTermsConsentAccepted = true;
            initialConfig.msTermsConsentDisabled = true;
            initialConfig.msInfoSharingConsentRead = true;
            initialConfig.msInfoSharingConsentAccepted = true;
            initialConfig.msInfoSharingConsentDisabled = true;
        }
        if (lab.simulatorsSettings.AttackIQ.isEnabled) {
            initialConfig.attackIQConsentAccepted = true;
            initialConfig.withAiq = true;
            initialConfig.aiqDisabled = true;
            initialConfig.firstName = lab.simulatorsSettings.AttackIQ.additionalInfo.FirstName;
            initialConfig.lastName = lab.simulatorsSettings.AttackIQ.additionalInfo.LastName;
            initialConfig.email = lab.simulatorsSettings.AttackIQ.additionalInfo.EmailAddress;
        }
        if (lab.simulatorsSettings.SafeBreach.isEnabled) {
            initialConfig.withSafeBreach = true;
            initialConfig.safeBreachDisabled = true;
            initialConfig.firstName = lab.simulatorsSettings.SafeBreach.additionalInfo.FirstName;
            initialConfig.lastName = lab.simulatorsSettings.SafeBreach.additionalInfo.LastName;
            initialConfig.email = lab.simulatorsSettings.SafeBreach.additionalInfo.EmailAddress;
        }
        return initialConfig;
    };
    EditSimulatorAgentsPanelComponent.prototype.onUpdate = function () {
        var _this = this;
        this.saving = true;
        var agentAdditionalSettings = {
            FirstName: this.agentsConfig.firstName,
            LastName: this.agentsConfig.lastName,
            EmailAddress: this.agentsConfig.email,
        };
        this.evaluationService
            .setSimulatorsConfigurations(agentAdditionalSettings, this.agentsConfig.withSafeBreach, this.agentsConfig.withAiq)
            .pipe(tap(function () {
            _this.closePanel();
        }), retryWhen(function (errors) {
            return errors.pipe(scan(function (errorCount, err) {
                if (errorCount > 2)
                    throw err;
                return errorCount + 1;
            }, 0), delayWhen(function () { return timer(1000); }));
        }), catchError(function (error) {
            _this.error$.next(true);
            _this.saving = false;
            return of();
        }))
            .subscribe();
    };
    EditSimulatorAgentsPanelComponent.prototype.onProviderToggled = function () {
        this.canUpdate =
            (!this.agentsConfig.aiqDisabled && this.agentsConfig.withAiq) ||
                (!this.agentsConfig.safeBreachDisabled && this.agentsConfig.withSafeBreach);
    };
    return EditSimulatorAgentsPanelComponent;
}(PanelContainer));
export { EditSimulatorAgentsPanelComponent };
