import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertSeverityCount, AlertsSeveritySummary, SeverityType } from '@wcd/domain';
import { DistributionElementSize } from '../../modules/distribution/distribution-element/distribution-element.component';

const loadingSeverities: LoadingSeveritiesCountMap = Object.freeze({
	high: null,
	medium: null,
	low: null,
	informational: null,
});

const defaultSeverities: SeveritiesCountMap = Object.freeze({
	high: 0,
	medium: 0,
	low: 0,
	informational: 0,
});

/**
 * An internal severities count map for while the data hasn't been populated yet.
 */
type LoadingSeveritiesCountMap = Record<SeverityType, null>;

/**
 * A map of the severities to their respective count.
 */
export type SeveritiesCountMap = Record<SeverityType, number>;

export function buildSeveritiesMap(alertsSeveritySummary: AlertsSeveritySummary): SeveritiesCountMap {
	const extractCount = (countWrapper: AlertSeverityCount) => countWrapper.count;

	return {
		high: extractCount(alertsSeveritySummary.high),
		medium: extractCount(alertsSeveritySummary.medium),
		low: extractCount(alertsSeveritySummary.low),
		informational: extractCount(alertsSeveritySummary.info),
	};
}

@Component({
	selector: 'severity-summary',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./severity-summary.component.scss'],
	template: `
		<div
			*ngIf="severitiesCountArray; else noAlerts"
			class="wcd-flex-horizontal"
			[class.wcd-flex-wrap-horizontal]="wrap !== false"
		>
			<severity-bar
				class="SeveritySummary__bar"
				*ngFor="let severity of severitiesCountArray"
				[severity]="severity.type"
				[count]="severity.count"
				[size]="size"
			>
			</severity-bar>
		</div>

		<ng-template #noAlerts>
			<div class="subtle">No alerts</div>
		</ng-template>
	`,
})
export class SeveritySummaryComponent {
	severitiesCountArray: ReadonlyArray<Readonly<{ type: SeverityType; count?: number }>>;

	@Input() size?: DistributionElementSize;
	@Input() wrap?: boolean;
	@Input()
	set severitiesCountMap(value: Partial<SeveritiesCountMap>) {
		const fullSeverities: LoadingSeveritiesCountMap | SeveritiesCountMap = !value
			? loadingSeverities
			: {
					...defaultSeverities,
					...value,
			  };

		this.severitiesCountArray = Object.entries(fullSeverities).map(
			([type, count]: [SeverityType, number]) => ({
				type,
				count,
			})
		);
	}
}
