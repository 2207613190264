import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewChild,
	ElementRef,
	Output,
	EventEmitter,
	AfterViewInit,
	ChangeDetectorRef,
} from '@angular/core';
import { clamp } from 'lodash-es';
import { Point } from '@angular/cdk/drag-drop/typings/drag-ref';

export class RangeValue {
	from: number;
	to: number;
}

type ThumbType = 'minElem' | 'maxElem';

@Component({
	selector: 'wcd-range-slider',
	templateUrl: './range-slider.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./range-slider.component.scss'],
})
export class RangeSliderComponent implements OnInit, AfterViewInit {
	@Input() ariaLabel?: string;
	@Input() max: number = 100;
	@Input() min: number = 0;
	@Input() showValue?: boolean = true;
	@Input() defaultValue?: RangeValue;
	@Input() valueFormat?: (value: number) => string;
	@Input()
	set value(value: RangeValue) {
		this._value = value;
		if (this._isViewInitialized) {
			this.setThumbsPositions();
		}
	}

	get value(): RangeValue {
		return this._value;
	}

	@Output() onChange: EventEmitter<RangeValue> = new EventEmitter();

	private _railElement: HTMLElement;
	private _minThumbElement: HTMLElement;
	private _maxThumbElement: HTMLElement;
	private _value: RangeValue;
	private _isViewInitialized = false;

	minThumbFreeDragPosition: Point;
	maxThumbFreeDragPosition: Point;

	@ViewChild('rail', { static: false }) railRef: ElementRef;
	@ViewChild('minElem', { static: false }) minThumbRef: ElementRef;
	@ViewChild('maxElem', { static: false }) maxThumbRef: ElementRef;

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit() {
		if (!this.value) {
			this.value = this.defaultValue || { from: this.max, to: this.min };
		}
	}

	ngAfterViewInit() {
		this._railElement = this.railRef.nativeElement;
		this._minThumbElement = this.minThumbRef.nativeElement;
		this._maxThumbElement = this.maxThumbRef.nativeElement;
		this._isViewInitialized = true;
		setTimeout(() => this.changeDetectorRef.detectChanges());
	}

	/**
	 * This method calculates the 'value' from the position of the thumbs as they move
	 */
	onDragMoved(thumbType: ThumbType) {
		const railBoundingRect = this._railElement.getBoundingClientRect();
		const parentWidth = railBoundingRect.width;

		if (thumbType === 'maxElem') {
			const maxThumbBoundingRect = this._maxThumbElement.getBoundingClientRect();
			const xRelativeToParent = this.getRectCenterX(maxThumbBoundingRect) - railBoundingRect.left;
			this.value.to = Math.min(this.max, Math.round((xRelativeToParent / parentWidth) * 100));
		} else {
			const minThumbBoundingRect = this._minThumbElement.getBoundingClientRect();
			const xRelativeToParent = this.getRectCenterX(minThumbBoundingRect) - railBoundingRect.left;
			this.value.from = Math.max(this.min, Math.round((xRelativeToParent / parentWidth) * 100));
		}
	}

	onDragReleased() {
		this.onChange.emit(this.value);
	}

	getMaxBoundaryAbsoluteWidth(): number {
		if (!this._isViewInitialized) return 0;

		const railBoundingRect = this._railElement.getBoundingClientRect();
		const minThumbBoundingRect = this._minThumbElement.getBoundingClientRect();

		const rightEdge = railBoundingRect.right + minThumbBoundingRect.width / 2;
		const leftEdge = minThumbBoundingRect.left;
		return rightEdge - leftEdge;
	}

	getMinBoundaryAbsoluteWidth(): number {
		if (!this._isViewInitialized) return 0;

		const railBoundingRect = this._railElement.getBoundingClientRect();
		const maxThumbBoundingRect = this._maxThumbElement.getBoundingClientRect();

		const leftEdge = railBoundingRect.left - maxThumbBoundingRect.width / 2;
		const rightEdge = maxThumbBoundingRect.right;
		return rightEdge - leftEdge;
	}

	// relative to the whole width of the rail
	getInactiveRailRelativeWidth(thumbType: ThumbType): number {
		switch (thumbType) {
			case 'minElem':
				return (this.value.from / (this.max - this.min)) * 100;
			case 'maxElem':
				return ((this.max - this.value.to) / (this.max - this.min)) * 100;
		}
	}

	keyPressed(thumbType: ThumbType, event: KeyboardEvent) {
		const jumpValue = Math.floor((this.max - this.min) / 10); // 10% of the slider
		switch (event.key) {
			case 'ArrowUp':
			case 'ArrowRight':
				thumbType === 'maxElem' ? this.increaseTo(1) : this.increaseFrom(1);
				break;
			case 'ArrowDown':
			case 'ArrowLeft':
				thumbType === 'maxElem' ? this.increaseTo(-1) : this.increaseFrom(-1);
				break;
			case 'PageUp':
				thumbType === 'maxElem' ? this.increaseTo(jumpValue) : this.increaseFrom(jumpValue);
				break;
			case 'PageDown':
				thumbType === 'maxElem' ? this.increaseTo(-jumpValue) : this.increaseFrom(-jumpValue);
				break;
			case 'End':
				// set to maximum value
				thumbType === 'maxElem' ? this.increaseTo(Infinity) : this.increaseFrom(Infinity);
				break;
			case 'Home':
				// set to minimum value
				thumbType === 'maxElem' ? this.increaseTo(-Infinity) : this.increaseFrom(-Infinity);
				break;
		}
	}

	private getRectCenterX(rect: DOMRect | ClientRect) {
		return rect.left + rect.width / 2;
	}

	/**
	 *  Increase the 'to' value of the range (aka the upper bound)
	 */
	private increaseTo(value: number) {
		this.value = { ...this.value, to: clamp(this.value.to + value, this.value.from + 1, this.max) };
		this.onChange.emit(this.value);
	}

	/**
	 *  Increase the 'from' value of the range (aka the lower bound)
	 */
	private increaseFrom(value: number) {
		this.value = { ...this.value, from: clamp(this.value.from + value, this.min, this.value.to - 1) };
		this.onChange.emit(this.value);
	}

	/**
	 * Calculate the thumb positions from the current 'value',
	 * use freeDragPosition to set the position (as offset from the start and end of the rail)
	 */
	private setThumbsPositions() {
		const railBoundingRect = this._railElement.getBoundingClientRect();
		const maxThumbBoundingRectHalfWidth = this._maxThumbElement.getBoundingClientRect().width / 2;
		const minThumbBoundingRectHalfWidth = this._minThumbElement.getBoundingClientRect().width / 2;

		this.maxThumbFreeDragPosition = {
			x: -(
				(this.getInactiveRailRelativeWidth('maxElem') / 100) * railBoundingRect.width -
				maxThumbBoundingRectHalfWidth
			),
			y: 0,
		};
		this.minThumbFreeDragPosition = {
			x:
				(this.getInactiveRailRelativeWidth('minElem') / 100) * railBoundingRect.width -
				minThumbBoundingRectHalfWidth,
			y: 0,
		};
	}
}
