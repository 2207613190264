
		<wcd-panel #panel (close)="destroy()" [settings]="settings">
			<wcd-wizard
				[steps]="steps"
				[data]="data"
				wizardTitle="evaluation.lab.setupPanel.title"
				wizardTrackingName="EvaluationWizard"
				(onClose)="panel.closePanel()"
			></wcd-wizard>
		</wcd-panel>
	