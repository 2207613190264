import { HasSecureScore } from '../common/secure-score/has-secure-score.interface';
import { ModelBase, EntityField, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Total secure score',
	pluralName: 'Total secure scores',
	readonly: true,
})
export class TotalSecureScore extends ModelBase implements HasSecureScore {
	@EntityField({
		required: true,
		data: 'currentScore',
	})
	readonly secureScore: number;

	@EntityField({
		required: true,
		data: 'maxScore',
	})
	readonly maxSecureScore: number;

	@EntityField({
		required: true,
		data: 'currentScoreHistory',
	})
	readonly secureScoreHistory: number[];

	@EntityField({
		required: true,
		data: 'maxScoreHistory',
	})
	readonly maxSecureScoreHistory: number[];

	@EntityField({
		required: true,
		data: 'secureScoreHistory',
	})
	readonly secureScoreHistoryPercentage: number[];
}
