import { AppContextService } from '@wcd/config';

/*
    Abstract facade for store service
 */
export abstract class StoreStorageService {
	protected readonly keyPrefix: string = '';
	protected constructor(private appContext: AppContextService, private storeService: Storage) {
		this.keyPrefix = this.appContext.getCurrentAppPrefix();
	}

	setItem(key: string, value: string) {
		return this.storeService.setItem(this.normalizeKey(key), value);
	}

	getItem(key: string): string {
		return this.storeService.getItem(this.normalizeKey(key));
	}

	removeItem(key: string) {
		return this.storeService.removeItem(this.normalizeKey(key));
	}

	clear() {
		return this.storeService.clear();
	}

	private normalizeKey(key: string): string {
		return this.keyPrefix + key;
	}
}
