
		<modal (close)="destroy()" [settings]="settings" data-track-component="AirsExternalSourceResults">
			<div class="wcd-flex-1 dialog-contents wcd-scroll-vertical ie11Patch ie11Flex">
				<pre class="code block">{{ result }}</pre>
				<a
					*ngIf="isPartial"
					data-track-id="ViewFullResults"
					data-track-type="Navigation"
					[attr.href]="ApiBaseUrl + '/services/result?result_id=' + resultId | urlWithToken"
					target="_blank"
				>
					<wcd-shared-icon [iconName]="'popOut'" class="small-icon"> </wcd-shared-icon>
					View Full Results
				</a>
			</div>
		</modal>
	