<div *ngIf="(data$ | async) as data" class="wcd-full-height wcd-flex-vertical" data-track-component="TvmDevicesExposureDistributionWidget">

	<h2 class="wcd-margin-xsmall-top wcd-margin-bottom wcd-font-weight-bold">{{ 'tvm.dashboard.devicesExposureDistributionWidget.header' | i18n }}</h2>

	<p class="wcd-margin-bottom">
		{{ 'tvm.dashboard.devicesExposureDistributionWidget.description' | i18n }}
	</p>

	<div class="pie-container wcd-margin-vertical">
		<div a11y-invisible>
			<h5>A pie chart displaying the count of machines per exposure score categories: low, medium and high.</h5>
			<div *ngIf="accessibleChartData">
				Total machine count is {{totalDevicesCount}}.
				<ng-container *ngIf="showOsNotSupportedLegend"><a href="javascript:void(0)" tabindex="-1" (click)="onAccessibilityLink('OsNonSupported')">{{ 'tvm.dashboard.devicesExposureDistributionWidget.categories.OS_NOT_SUPPORTED' | i18n }}:</a> {{accessibleChartData.OsNotSupported.count}} machines ({{accessibleChartData.OsNotSupported.percent}} percent).</ng-container>
				<ng-container *ngIf="showOtherNotSupportedLegend"><a href="javascript:void(0)" tabindex="-1" (click)="onAccessibilityLink('OTHER')">{{ 'common.noDataAvailable' | i18n }}:</a> {{accessibleChartData.Other.count}} machines ({{accessibleChartData.Other.percent}} percent).</ng-container>
				<a href="javascript:void(0)" tabindex="-1" (click)="onAccessibilityLink('High')">High exposure score:</a> {{accessibleChartData.High.count}} machines ({{accessibleChartData.High.percent}} percent).
				<a href="javascript:void(0)" tabindex="-1" (click)="onAccessibilityLink('Medium')">Medium exposure score:</a> {{accessibleChartData.Medium.count}} machines ({{accessibleChartData.Medium.percent}} percent).
				<a href="javascript:void(0)" tabindex="-1" (click)="onAccessibilityLink('Low')">Low exposure score:</a> {{accessibleChartData.Low.count}} machines ({{accessibleChartData.Low.percent}} percent).
			</div>
		</div>
		<wcd-pie-chart
			[data]="data_ | async"
			[hidden]="error$ | async"
			[settings]="pieSettings"
			[allowTitleClick]="false"
			[labelsOutside]="false"
			[upperTitle]="upperTitle$ | async"
			[ariaLabel]="'tvm.dashboard.devicesExposureDistributionWidget.header' | i18n"
			(titleClick)="onTitleClick($event)"
			>
		</wcd-pie-chart>
	</div>

	<wcd-chart-legend
		class="wcd-margin-xsmall-bottom"
		orientation="horizontal"
		[wrap]="true"
		[items]="legendItems">
	</wcd-chart-legend>

	<wcd-chart-legend
		*ngIf="showOtherNotSupportedLegend || showOsNotSupportedLegend"
		orientation="horizontal"
		[wrap]="true"
		[items]="secondaryLegendItems">
	</wcd-chart-legend>

</div>
