var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { TagType } from './tag-type.enum';
var ɵ0 = function (config) { return config.data.serviceUrls.automatedIr; }, ɵ1 = function (id) { return String(id); };
var Tag = /** @class */ (function (_super) {
    __extends(Tag, _super);
    function Tag() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ parse: ɵ1 }),
        __metadata("design:type", String)
    ], Tag.prototype, "id", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Tag.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Tag.prototype, "nameKey", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Tag.prototype, "type", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Tag.prototype, "className", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Tag.prototype, "tooltip", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Tag.prototype, "ariaLabel", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], Tag.prototype, "isPartial", void 0);
    __decorate([
        EntityField({ defaultValue: true }),
        __metadata("design:type", Boolean)
    ], Tag.prototype, "isEditable", void 0);
    Tag = __decorate([
        Entity({
            singularName: 'Tag',
            pluralName: 'Tags',
            endpoint: 'tags',
            allItemsProperty: 'results',
            baseUrl: ɵ0,
            supportedEntityGetMethods: [EntityGetMethod.query],
        })
    ], Tag);
    return Tag;
}(EntityModelBase));
export { Tag };
export { ɵ0, ɵ1 };
