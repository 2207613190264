import { Injectable } from '@angular/core';
import { Feature, PreferencesService , FeaturesService} from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import {I18nService} from "@wcd/i18n";

const BANNER_DISMISSED = "promotionBannerDismissedTs";
const TWO_WEEKS = 1000*60*24*14; // seconds*minutes*hours*days

@Injectable()
export class ConvergencePromotionService {
	/**
	 * These are based on feature flag.
	 */
	public readMoreUrl: string;
	public title: string;
	public desc: string;
	public shortDesc:string;
	public buttonTitle: string;
	public learnMoreTitle : string;
	public isL2Enabled: boolean;
	public isL3Enabled: boolean;
	public DapDowngradeToPromotionL2Enabled: boolean;

	constructor(
		private readonly preferencesService: PreferencesService,
		private readonly featuresService: FeaturesService,
		private readonly i18nService: I18nService
	) {
		// We want to show promotion L2 text if Feature.ConvergencePromotionL2 or if ConvergencePromotionL3DisableForDap enabled
		// Otherwise we show L3 promotion text
		this.isL2Enabled = this.featuresService.isEnabled(Feature.ConvergencePromotionL2);
		this.isL3Enabled = this.featuresService.isEnabled(Feature.ConvergencePromotionL3);
		this.DapDowngradeToPromotionL2Enabled = this.featuresService.isAnyEnabled([Feature.DapDowngradeToPromotionL2Part1, Feature.DapDowngradeToPromotionL2Part2]);

		this.title = this.isL2Enabled ? this.i18nService.strings.promotion_l2_title : this.i18nService.strings.promotion_title;
		this.desc = this.isL3Enabled && !this.DapDowngradeToPromotionL2Enabled
			? this.i18nService.get('promotion_l3_description', { learnMoreURL:'https://aka.ms/RedirectionInOptOutModeBlog', newPortalURL: 'https://security.microsoft.com', oldPortalURL: 'securitycenter.windows.com'})
			: (this.isL2Enabled || this.DapDowngradeToPromotionL2Enabled)
				? this.i18nService.get('promotion_l2_description', { learnMoreURL:'https://aka.ms/RedirectionInOptOutModeBlog', newPortalURL: 'https://security.microsoft.com', oldPortalURL: 'securitycenter.windows.com'})
				: this.i18nService.get('promotion_description',{ learnMoreURL:'https://aka.ms/AAaqpu9', newPortalURL: 'https://security.microsoft.com' });
		this.shortDesc = this.isL3Enabled && !this.DapDowngradeToPromotionL2Enabled
			? this.i18nService.get('promotion_l3_description_short',{ learnMoreURL:'https://aka.ms/RedirectionInOptOutModeBlog', newPortalURL: 'https://security.microsoft.com'})
			: (this.isL2Enabled || this.DapDowngradeToPromotionL2Enabled)
				? this.i18nService.get('promotion_l2_description_short',{ learnMoreURL:'https://aka.ms/RedirectionInOptOutModeBlog', newPortalURL: 'https://security.microsoft.com'})
				: this.i18nService.get('promotion_description_short', { learnMoreURL:'https://aka.ms/AAaqpu9', newPortalURL: 'https://security.microsoft.com'});
		this.buttonTitle = this.isL2Enabled ? this.i18nService.strings.promotion_l2_action_title : this.i18nService.strings.promotion_action_title;
	}

	getDismissedTs() {
		return this.preferencesService.getPreference(BANNER_DISMISSED);
	}

	dismiss() {
		this.preferencesService.setPreference(BANNER_DISMISSED, (new Date()).getTime() + TWO_WEEKS);
	}

	promotionConvergenceEnabled () {
		return (
			!sccHostService.isSCC && // Dont show if we are already in SCC
			this.featuresService.isEnabled(Feature.PortedSccPages) &&
			this.featuresService.isEnabled(Feature.ConvergencePromotion)
		);
	}

	shouldShowPromotionBanner() {
		const dismissTs = this.getDismissedTs();
		return (
			this.promotionConvergenceEnabled() && // Show if feature is enabled
			(
				this.isL2Enabled || // If we are in L2 always show regardless of dismiss time
				(!dismissTs || dismissTs < (new Date()).getTime()) // Was not dismissed or dismiss time has passed
			));
	}
}
