var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, Injector } from '@angular/core';
import { AirsEntity, AirsEntityTypeConfig, Alert, Investigation, MtpInvestigation, AirsEntityType, } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { CommentsStore } from '../../../comments/services/comments.store';
import { Paris } from '@microsoft/paris';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { combineLatest, of } from 'rxjs';
import { flatMap } from 'lodash-es';
import { finalize, switchMap } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Feature, FeaturesService } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';
import { sccHostService } from '@wcd/scc-interface';
export var PIVOT_MULTIPLE_MACHINES_ACTION_ID = 12;
var RemediationActionEntityPanelComponent = /** @class */ (function (_super) {
    __extends(RemediationActionEntityPanelComponent, _super);
    function RemediationActionEntityPanelComponent(paris, commentsStore, dialogsService, injector, i18nService, liveAnnouncer, featuresService, appConfigService, changeDetectorRef) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.paris = paris;
        _this.commentsStore = commentsStore;
        _this.dialogsService = dialogsService;
        _this.injector = injector;
        _this.i18nService = i18nService;
        _this.liveAnnouncer = liveAnnouncer;
        _this.featuresService = featuresService;
        _this.appConfigService = appConfigService;
        _this.isSavingComment = false;
        _this.isLoadingAirsEntities = false;
        _this.isLoadingAlert = false;
        _this.isLoadingInvestigation = false;
        _this.allowEditComments = true;
        _this.summarizeEntities = false;
        return _this;
    }
    Object.defineProperty(RemediationActionEntityPanelComponent.prototype, "action", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RemediationActionEntityPanelComponent.prototype, "type", {
        get: function () {
            return (this.action && this.action.remediationActionType) || this.options.type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RemediationActionEntityPanelComponent.prototype, "pivotActionId", {
        get: function () {
            return PIVOT_MULTIPLE_MACHINES_ACTION_ID;
        },
        enumerable: true,
        configurable: true
    });
    RemediationActionEntityPanelComponent.prototype.setEntity = function (entity) {
        var isSameInvestigation = (entity && entity.investigationId) === (this.action && this.action.investigationId);
        var isSameAlert = this.alertId === (this.alert && this.alert.id);
        var isDifferentEntity = !this.entity || entity.id !== this.entity.id;
        _super.prototype.setEntity.call(this, entity);
        if (!entity)
            return;
        if (!this.commentsOptions || this.commentsOptions.investigation_action !== +this.action.id) {
            var actionId = parseInt(this.action.id);
            this.commentsOptions = !isNaN(actionId) ? { investigation_action: +this.action.id } : null;
        }
        if (this.isInit) {
            if (isDifferentEntity || (!this.airsEntities && !this.isLoadingAirsEntities)) {
                this.setAirsEntities();
            }
            if ((!this.options || this.options.loadInvestigation) &&
                ((this.options && this.options.showInvestigation) || !this.alertId) &&
                (!isSameInvestigation || (!this.investigation && !this.isLoadingInvestigation))) {
                this.setInvestigation();
            }
            if (!isSameAlert || (this.alertId && !this.alert && !this.isLoadingAlert)) {
                this.setAlert();
            }
        }
    };
    RemediationActionEntityPanelComponent.prototype.setAirsEntities = function () {
        return __awaiter(this, void 0, void 0, function () {
            var entityGetters;
            var _this = this;
            return __generator(this, function (_a) {
                this.airsEntities = null;
                if (this.fullEntities) {
                    this.airsEntities = this.fullEntities;
                    this.changeDetectorRef.markForCheck();
                }
                else if (!this.options.ignoreEntityData &&
                    ((this.action && this.type && this.type.mainEntities && this.type.mainEntities.length) ||
                        (this.partialEntities && this.partialEntities.length))) {
                    this.isLoadingAirsEntities = true;
                    entityGetters = void 0;
                    if (this.type && this.type.mainEntities && this.type.mainEntities.length) {
                        entityGetters = this.type.mainEntities.map(function (entityConfig) {
                            return _this.paris.getItemById(AirsEntityTypeConfig, entityConfig.entityTypeId).pipe(switchMap(function (entityType) {
                                if (entityType) {
                                    var entities = _this.action[entityConfig.fieldName];
                                    var entitiesBaseData = _this.getEntitiesBaseData(entities);
                                    if (entitiesBaseData && entitiesBaseData.length) {
                                        return combineLatest(entitiesBaseData.map(function (entityBaseData) {
                                            return _this.paris.getItemById(AirsEntity, entityBaseData.entityId, undefined, {
                                                type_id: entityType.id,
                                                investigation_id: _this.action.investigationId,
                                                useOfficeEntityApi: _this.action.isOfficeInvestigation,
                                                useMdiEntityApi: entityBaseData.entityTypeId ===
                                                    AirsEntityType.Account,
                                                tenantId: sccHostService.isSCC
                                                    ? sccHostService.loginUser.tenantId
                                                    : null,
                                                sid: entityBaseData.sid,
                                                aadUserId: entityBaseData.aadUserId,
                                            });
                                        }));
                                    }
                                }
                                return of([]);
                            }));
                        });
                    }
                    else if (this.partialEntities && this.partialEntities.length) {
                        entityGetters = [
                            combineLatest(this.partialEntities
                                .filter(function (e) { return e.id; })
                                .map(function (entity) {
                                return _this.paris.getItemById(AirsEntity, entity.id, null, {
                                    type_id: entity.type.id,
                                    investigation_id: _this.action.investigationId,
                                });
                            })),
                        ];
                    }
                    this.addExtraDataSubscription(combineLatest(entityGetters)
                        .pipe(finalize(function () {
                        _this.isLoadingAirsEntities = false;
                        _this.changeDetectorRef.markForCheck();
                    }))
                        .subscribe(function (entities) {
                        _this.airsEntities = flatMap(entities.filter(function (entityArr) { return entityArr && entityArr.length; }));
                        _this.isLoadingAirsEntities = false;
                        _this.changeDetectorRef.markForCheck();
                    }, function (error) {
                        console.error(error);
                    }));
                }
                return [2 /*return*/];
            });
        });
    };
    RemediationActionEntityPanelComponent.prototype.getEntitiesBaseData = function (entities) {
        var _this = this;
        if (!entities) {
            return null;
        }
        if (entities instanceof Array) {
            return entities.map(function (entity) { return _this.getEntityBaseData(entity); }).filter(Boolean);
        }
        else {
            var entityBaseData = this.getEntityBaseData(entities);
            return entityBaseData ? [entityBaseData] : null;
        }
        return null;
    };
    RemediationActionEntityPanelComponent.prototype.getEntityBaseData = function (remediationActionEntity) {
        if (!remediationActionEntity) {
            return null;
        }
        var entityId = remediationActionEntity.id;
        var entityTypeId = remediationActionEntity.entityTypeId;
        if (entityId) {
            var entityBaseData = { entityId: entityId, entityTypeId: entityTypeId };
            if (entityTypeId === AirsEntityType.Account) {
                if (!remediationActionEntity.sid) {
                    return null;
                }
                return Object.assign(entityBaseData, {
                    sid: remediationActionEntity.sid,
                    aadUserId: remediationActionEntity.aadUserId,
                });
            }
            return entityBaseData;
        }
        return null;
    };
    RemediationActionEntityPanelComponent.prototype.ngOnChanges = function (changes) {
        if (changes.partialEntities || changes.fullEntities) {
            this.setAirsEntities();
        }
    };
    RemediationActionEntityPanelComponent.prototype.setInvestigation = function () {
        var _this = this;
        this.investigation = null;
        this.isLoadingInvestigation = true;
        if (!this.alertId) {
            this.alert = null;
            this.isLoadingAlert = true;
        }
        if (this.action.investigationId) {
            /*
                TODO: LIRAN:
                it may broke MDATP investigation loading in case PortedSccPages flag is enabled.
                consider adding indication in backend if it's an action from office investigation.
             */
            var repo = this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
                this.action.isOfficeInvestigation
                ? this.paris.getRepository(MtpInvestigation)
                : this.paris.getRepository(Investigation);
            this.addExtraDataSubscription(repo
                .getItemById(this.action.investigationId, undefined, {
                tenantId: this.appConfigService.tenantId,
            })
                .pipe(finalize(function () {
                _this.isLoadingInvestigation = false;
                _this.changeDetectorRef.markForCheck();
            }))
                .subscribe(function (investigation) {
                _this.investigation = investigation;
                _this.isLoadingInvestigation = false;
                if (!_this.alertId) {
                    _this.setAlert();
                }
                _this.changeDetectorRef.markForCheck();
            }));
        }
        else {
            this.isLoadingInvestigation = false;
            if (!this.alertId) {
                this.isLoadingAlert = false;
            }
        }
        this.changeDetectorRef.markForCheck();
    };
    RemediationActionEntityPanelComponent.prototype.setAlert = function () {
        var _this = this;
        this.alert = null;
        this.isLoadingAlert = true;
        var alertId = this.alertId || (this.investigation && this.investigation.alert && this.investigation.alert.id);
        if (alertId) {
            this.addExtraDataSubscription(this.paris
                .getRepository(Alert)
                .getItemById(alertId)
                .pipe(finalize(function () {
                _this.isLoadingAlert = false;
                _this.changeDetectorRef.markForCheck();
            }))
                .subscribe(function (alert) {
                _this.alert = alert;
                _this.isLoadingAlert = false;
                _this.changeDetectorRef.markForCheck();
            }));
        }
        else {
            this.isLoadingAlert = false;
        }
        this.changeDetectorRef.markForCheck();
    };
    RemediationActionEntityPanelComponent.prototype.showRelatedEntity = function (entity) {
        var _this = this;
        this.injector.get(EntityPanelsService).showEntity(AirsEntity, entity, { investigation_id: this.action.investigationId }, {
            back: {
                onClick: function () { return _this.injector.get(EntityPanelsService).closeEntityPanel(AirsEntity); },
            },
        });
    };
    RemediationActionEntityPanelComponent.prototype.saveComment = function (commentText) {
        var _this = this;
        this.isSavingComment = true;
        var comment = this.commentsStore.createComment(commentText, {
            investigation: (this.investigation && this.investigation.id) || this.action.investigationId,
            investigation_action: this.action.id,
        });
        this.commentsStore
            .save(comment, null, true, '#Action-entity-panel editable-comments-list textarea')
            .then(function () {
            _this.isSavingComment = false;
            _this.refreshOn = new Date();
            _this.liveAnnouncer.announce(_this.i18nService.get('comments_save_success', {
                date: comment.timestamp,
            }));
            _this.changeDetectorRef.detectChanges();
        }, function (error) {
            _this.isSavingComment = false;
            _this.dialogsService.showError({
                title: error.message || error,
                message: _this.i18nService.strings.comments_save_error,
                data: error.data,
            });
        });
    };
    return RemediationActionEntityPanelComponent;
}(EntityPanelComponentBase));
export { RemediationActionEntityPanelComponent };
