import { NgModule, Type } from '@angular/core';
import { Resolve, RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard, OnboardGuard, FeatureEnabledGuard } from '@wcd/auth';
import { Feature } from '@wcd/config';
import { EntityType } from '../global_entities/models/entity-type.interface';
import { SearchPageComponent } from './components/search-page.component';
import { SearchResultsComponent } from './components/search-results.component';
import { SearchEntityTypeResolver } from './services/search-entity-type.resolver';
import { BreadcrumbsResolver } from '../breadcrumbs/services/breadcrumbs-resolver.service';

export interface SearchResultsRouteData {
	readonly entityType: EntityType;
}

type SearchResultResolve = { [K in keyof SearchResultsRouteData]: Type<Resolve<SearchResultsRouteData[K]>> };

export const searchRoutes: Routes = [
	{
		path: '',
		canActivate: [AuthenticatedGuard, OnboardGuard],
		children: [
			{
				path: 'input',
				component: SearchPageComponent,
				data: {
					breadcrumbsConfig: {
						addParentWhenEmpty: false,
						resetListOnEnter: true,
						show: false,
						itemConfig: {
							labelKey: 'main_search_title',
							url: '/endpoints/search',
						},
					},
				},
				children: [
					{
						path: ':searchEntityTypeId/:term',
						component: SearchResultsComponent,
						resolve: <SearchResultResolve>{
							entityType: SearchEntityTypeResolver,
							breadcrumbs: BreadcrumbsResolver,
						},
						canActivate: [AuthenticatedGuard, OnboardGuard],
					},
				],
			},
		],
	},

	{
		path: 'searchResults/:searchEntityTypeId/:term',
		pathMatch: 'full',
		component: SearchResultsComponent,
		resolve: <SearchResultResolve>{
			entityType: SearchEntityTypeResolver,
			breadcrumbs: BreadcrumbsResolver,
		},
		canActivate: [AuthenticatedGuard, OnboardGuard],
		data: {
			breadcrumbsConfig: {
				addParentWhenEmpty: false,
				resetListOnEnter: true,
				itemConfig: {
					labelKey: 'main_searchResults_title',
					url: 'searchResults/:searchEntityTypeId/:term',
				},
			},
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(searchRoutes)],
})
export class SearchRoutingModule {}
