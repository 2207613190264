/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../icons/src/lib/components/icon.component";
import * as i3 from "../../../../icons/src/lib/services/icons.service";
import * as i4 from "./info-box.component";
var styles_InfoBoxComponent = [];
var RenderType_InfoBoxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InfoBoxComponent, data: {} });
export { RenderType_InfoBoxComponent as RenderType_InfoBoxComponent };
export function View_InfoBoxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "color-box-gray-25 wcd-flex-none wcd-padding-small-left wcd-padding-top wcd-padding-right wcd-padding-bottom wcd-flex-horizontal wcd-font-size-s"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "wcd-flex-none wcd-padding-small-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "wcd-shared-icon", [["class", "small-icon"], ["iconName", "Info"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(6, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), i0.ɵncd(null, 0), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = "Info"; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_InfoBoxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-info-box", [], null, null, null, View_InfoBoxComponent_0, RenderType_InfoBoxComponent)), i0.ɵdid(1, 49152, null, 0, i4.InfoBoxComponent, [], null, null)], null, null); }
var InfoBoxComponentNgFactory = i0.ɵccf("wcd-info-box", i4.InfoBoxComponent, View_InfoBoxComponent_Host_0, {}, {}, ["*"]);
export { InfoBoxComponentNgFactory as InfoBoxComponentNgFactory };
