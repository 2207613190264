
		<div class="wcd-flex-1 wcd-flex-horizontal wcd-flex-vertical incident-trends-custom-tooltip" *ngIf="data">
			<div class="wcd-font-size-s wcd-font-weight-bold wcd-margin-medium-bottom" *ngIf="!hideTitle">
				{{'incident_alert_and_incident_trend' | i18n }}
			</div>
			<div>
				<wcd-time-series-chart
					[data]="data"
					[settings]="trendChartSettings"
					[enableRenderOnSettingsChanges]="true"
				>
				</wcd-time-series-chart>
			</div>
			<wcd-chart-legend
				class="wcd-margin-medium-top wcd-margin-small-bottom"
				orientation="horizontal"
				[wrap]="true"
				[showValues]="false"
				[items]="incidentAlertTrendLegend"
			></wcd-chart-legend>
		</div>
	