var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { IncidentSeverityCount } from './incident-severity-count.value-object';
import { Incident } from './incident.entity';
import { ActiveTrendItem } from './active-trend-item.value-object';
var ɵ0 = ['activeIncidentCount', 'ActiveIncidentCount'], ɵ1 = ['topIncidents', 'TopIncidents'], ɵ2 = ['incidentsTrend', 'IncidentsTrend'], ɵ3 = ['alertsTrend', 'AlertsTrend'];
var ActiveIncidents = /** @class */ (function (_super) {
    __extends(ActiveIncidents, _super);
    function ActiveIncidents() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: ɵ0 }),
        __metadata("design:type", Number)
    ], ActiveIncidents.prototype, "activeIncidentCount", void 0);
    __decorate([
        EntityField({ arrayOf: IncidentSeverityCount }),
        __metadata("design:type", Array)
    ], ActiveIncidents.prototype, "incidentsBySeverity", void 0);
    __decorate([
        EntityField({ arrayOf: Incident, data: ɵ1 }),
        __metadata("design:type", Array)
    ], ActiveIncidents.prototype, "topIncidents", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], ActiveIncidents.prototype, "unassignedIncidentCount", void 0);
    __decorate([
        EntityField({ arrayOf: ActiveTrendItem, data: ɵ2 }),
        __metadata("design:type", Array)
    ], ActiveIncidents.prototype, "incidentsTrend", void 0);
    __decorate([
        EntityField({ arrayOf: ActiveTrendItem, data: ɵ3 }),
        __metadata("design:type", Array)
    ], ActiveIncidents.prototype, "alertsTrend", void 0);
    ActiveIncidents = __decorate([
        ValueObject({
            singularName: 'Active incidents',
            pluralName: '',
            readonly: true,
        })
    ], ActiveIncidents);
    return ActiveIncidents;
}(ModelBase));
export { ActiveIncidents };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
