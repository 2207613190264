import { Entity, EntityModelBase, EntityField, DataSet } from '@microsoft/paris';
import { ITvmAnalyticsResponse } from '../../analyticsEndPointUtils';

@Entity({
	singularName: 'Baseline configuration device',
	pluralName: 'Baseline configuration devices',
	readonly: true,
	endpoint: "baseline/devices",
	parseDataSet: (response: ITvmAnalyticsResponse<any>): DataSet<any> => ({
		count: response.numOfResults,
		items: response.results,
		meta: response.meta,
	})
})
export class BaselineConfigurationDevice extends EntityModelBase<string> {
	@EntityField() readonly name: string;

	@EntityField() readonly domain: string;

	@EntityField() readonly isCompliant: boolean;

	@EntityField() readonly operatingSystem: string;

	@EntityField() readonly operatingSystemVersion: string;

	@EntityField() readonly lastSeen: Date;

	@EntityField() readonly compliantConfigurations: number;

	@EntityField() readonly detectedValues: string[];

	@EntityField() readonly expectedValues: string[];

	@EntityField() readonly sourceValues: string[];
}
