import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Sample download response',
	pluralName: '',
	readonly: true,
})
export class SampleDownloadResponse extends ModelBase {
	@EntityField({ data: 'DownloadSasUri' })
	readonly sasUri: string;
}
