import { mapFileEntityToAirsEntity } from './airs-entity.file.converter';
export function mapProcessEntityToAirsEntity(entityData) {
    var res = Object.entries(entityData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case "ImageFile" /* ImageFile */:
                    acc['cwd'] = val['Directory'];
                    acc['name'] = val['Name'];
                    acc['main_module'] = mapFileEntityToAirsEntity(val);
                    acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
                    break;
                case "ProcessId" /* ProcessId */:
                    acc['process_id'] = val;
                    acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
                    break;
                case "CreatedTimeUtc" /* CreatedTimeUtc */:
                    acc['created'] = val;
                    break;
                case "Account" /* Account */:
                    var ntDomain = val['NTDomain'];
                    var name_1 = val['Name'];
                    acc['username'] =
                        ntDomain && name_1 ? ntDomain + "\\" + name_1 : ntDomain ? ntDomain : name_1 ? name_1 : null;
                    break;
                case "CommandLine" /* CommandLine */:
                    acc['cmd'] = val;
                    break;
                // case ProcessEntityAlertV3Fields.ParentProcess:
                // 	acc['parent_name'] = val['ProcessId']; TODO: missing parent_name data in the response - need to add to the BE (https://microsoft.visualstudio.com/OS/_workitems/edit/37518268)
                //	break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
    return Object.assign(res, { additional_data: { name: res['name'], pid: res['process_id'] } });
}
