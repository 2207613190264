import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GEO_SPLITTERS, GeoCoordinates } from '../models/geo-coordinates.model';
import { I18nService } from '@wcd/i18n';

const MAP_OPTIONS: any = { zoom: 4, width: 450, height: 250, apiVersion: 3 },
	MAP_URL_PREFIX: string = 'https://www.bing.com/maps/embed/viewer.aspx?',
	EXTERNAL_MAP_URL_PREFIX: string = 'https://www.bing.com/maps?cp=',
	MAP_LOAD_TIMEOUT: number = 10000;

// embedded map provider: Bing Maps.
// docs: https://msdn.microsoft.com/en-us/library/ff428643.aspx
// embed customize helper (right click for marker): https://www.bing.com/maps/embed/Customize.aspx
// url example: https://www.bing.com/maps/embed/viewer.aspx?v=3&cp=46.4655~-1.6184&lvl=4&w=450&h=250&pp=~~46.4655~-1.6184
// external url example: https://www.bing.com/maps?cp=47.677797~-122.122013

@Component({
	selector: 'external-map',
	template: `
		<div class="external-map">
			<div class="map" *ngIf="isMapAvailable">
				<iframe [src]="mapUrl | safeUrl" (load)="onIframeLoad()"></iframe>
			</div>
			<div class="alert alert-danger" *ngIf="!isMapAvailable">
				<wcd-shared-icon [iconName]="'error'" class="large-icon pull-left"> </wcd-shared-icon>
				<div class="with-large-icon">
					<div>{{ 'help.externalLoadError' | i18n: { asset: mapAsset } }}</div>
					<a target="_blank" [href]="externalMapUrl | safeUrl">location link</a>
				</div>
			</div>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit {
	@Input() location: GeoCoordinates;
	@Output() error: EventEmitter<void> = new EventEmitter<void>();
	isMapAvailable: boolean = true;
	externalMapUrl: string;
	mapUrl: string;
	private _loadErrorTimeout;
	readonly mapAsset = this.i18nService.get('help_externalLoadError_map_asset');

	constructor(private readonly i18nService: I18nService) { }

	ngOnInit() {
		if (this.location) {
			this.externalMapUrl = `${EXTERNAL_MAP_URL_PREFIX + this.location.toString(GEO_SPLITTERS.bing)}`;

			const center = this.location.toString(GEO_SPLITTERS.bing);
			this.mapUrl = `${MAP_URL_PREFIX}
				v=${MAP_OPTIONS.apiVersion}
				&cp=${center}
				&lvl=${MAP_OPTIONS.zoom}
				&w=${MAP_OPTIONS.width}
				&h=${MAP_OPTIONS.height}
				&pp=~~${center}`;

			this._loadErrorTimeout = setTimeout(() => {
				this.onLoadError();
			}, MAP_LOAD_TIMEOUT);
		} else this.onLoadError();
	}

	onIframeLoad() {
		clearTimeout(this._loadErrorTimeout);
	}

	onLoadError() {
		this.isMapAvailable = false;
		this.error.emit(null);
	}
}
