import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgClassInput } from "@wcd/angular-extensions";

@Component({
	selector: 'editable-text',
	providers: [
		// Register the component as a value accessor for use by ngModel
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EditableTextComponent),
			multi: true,
		},
	],
	template: `
		<label *ngIf="label" for="{{ id }}" [ngClass]="labelClass">{{ label }}</label>
		<input *ngIf="allowEdit; else textDisplay"
			type="text"
			[ngClass]="inputClass"
			id="{{ id }}"
			name="{{ id }}"
			[(ngModel)]="text"
			(ngModelChange)="onTextChange($event)"/>

		<ng-template #textDisplay>
			<p id="{{ id }}" [ngClass]="textClass">{{ text }}</p>
		</ng-template>`
})
export class EditableTextComponent implements ControlValueAccessor{
	@Input() label?: string;

	@Input() labelClass?: NgClassInput;

	@Input() allowEdit?: boolean = true;

	@Input() inputClass?: NgClassInput;

	@Input() textClass?: NgClassInput;

	static nextId: number = 0;

	readonly id: string;

	text: string;

	onTextChange:(value: string) => void;

	constructor() {
		this.id = `editable-text-${++EditableTextComponent.nextId}`;
	}

	//#region Angular forms integration: ngModel
	writeValue(text: string): void {
		this.text = text;
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onTextChange = fn;
	}

	registerOnTouched(fn: any): void { }
	//#endregion
}
