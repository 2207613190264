import { Component, Input, ViewChild } from '@angular/core';
import { PanelContainer, PanelComponent } from '@wcd/panels';
import { Router } from '@angular/router';
import { Alert, SuppressionRule } from '@wcd/domain';
import { SuppressionRuleEditComponent } from './suppression-rule-edit.component';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'suppression-rule-panel',
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings" [canDeactivate]="boundCanDeactivate">
			<suppression-rule-edit
				class="wcd-flex-1 wcd-flex-vertical wcd-full-height"
				[rule]="rule"
				[alert]="alert"
				[refreshOn$]="refreshOn$"
				[changingRuleStatus$]="changingRuleStatus$"
				[isEditRuleMode]="isEditRuleMode"
				(save)="onDone($event)"
				(changeStatus)="onChangeStatus($event)"
				(closePanel)="onClosePanel()"
			>
			</suppression-rule-edit>
		</wcd-panel>
	`,
})
export class SuppressionRulePanelComponent extends PanelContainer {
	@Input() rule: SuppressionRule;
	@Input() alert: Alert;
	@Input() refreshOn$: BehaviorSubject<number>;
	@Input() changingRuleStatus$: BehaviorSubject<boolean>;
	@Input() onDone: Function;
	@Input() onChangeStatus: Function;
	@Input() onCancel: Function;
	@Input() isEditRuleMode: boolean;

	@ViewChild(SuppressionRuleEditComponent, { static: false })
	ruleEditComponent: SuppressionRuleEditComponent;
	@ViewChild(PanelComponent, { static: false }) panel: PanelComponent;

	boundCanDeactivate: () => Observable<boolean>;

	constructor(
		router: Router,
		private i18nService: I18nService,
		private dialogsService: DialogsService
	) {
		super(router);

		this.boundCanDeactivate = this.canDeactivate.bind(this);
	}

	canDeactivate(): Observable<boolean> {
		if (this.ruleEditComponent.isDirty) {
			return from(
				this.dialogsService.confirm({
					title: this.i18nService.get('discardChanges'),
					text: this.i18nService.get('discardMessage.singular', {
						itemName: 'suppression rule',
					}),
					confirmText: this.i18nService.get('buttons_discard'),
				})
			).pipe(map((e: ConfirmEvent) => e.confirmed));
		} else {
			return of(true);
		}
	}

	onClosePanel() {
		this.canDeactivate().subscribe((_canDeactivate: boolean) => {
			if (_canDeactivate) this.closePanel();
		});
	}

	closePanel() {
		this.startClose();
		setTimeout(() => this.destroy(), 200);
	}
}
