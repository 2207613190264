import { sccHostService } from '@wcd/scc-interface';
var STARTS_WITH_BRACKETS_REGEXP = /^(<(\w+)>)[\/]+/i;
var SccProxyUtils = /** @class */ (function () {
    function SccProxyUtils() {
    }
    SccProxyUtils.shouldUseSccProxy = function (features) {
        if (!sccHostService.isSCC) {
            return false;
        }
        var localFeatureStringValue = localStorage.getItem("feature.UseSccProxy");
        return localFeatureStringValue
            ? localFeatureStringValue === String(true)
            : features && features.UseSccProxy;
    };
    SccProxyUtils.urlMatchSccProxyPattern = function (url) {
        return Boolean(url.match(STARTS_WITH_BRACKETS_REGEXP));
    };
    SccProxyUtils.convertToSccProxyPattern = function (key) {
        return "<mtp>/" + key;
    };
    SccProxyUtils.getServiceUrls = function (originalServiceUrls, features) {
        var useSccProxy = SccProxyUtils.shouldUseSccProxy(features);
        var useMockServer = sccHostService.mock.isMockMode;
        return Object.entries(originalServiceUrls || {})
            .filter(function (_a) {
            var key = _a[0], value = _a[1];
            return value;
        })
            .reduce(function (acc, _a) {
            var key = _a[0], value = _a[1];
            acc[key] =
                SccProxyUtils.urlMatchSccProxyPattern(value) || !useSccProxy || useMockServer
                    ? value
                    : SccProxyUtils.convertToSccProxyPattern(key);
            return acc;
        }, {});
    };
    return SccProxyUtils;
}());
export { SccProxyUtils };
