import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceComplianceStatisticsApiCall, ProfilesStatistics, ResolveEntityURL, TvmEndPoint } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { Subscription } from 'rxjs';
import { I18nService } from '@wcd/i18n';

@Component({
  selector: 'app-compliant-devices-dataview-cell',
  templateUrl: './compliant-devices-dataview-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompliantDevicesDataviewCellComponent implements OnDestroy, OnChanges {
	private _subscription: Subscription;
	private _assetsCountHistory: number[];

	isImprovementNegative: boolean = false;
	isLoading: boolean;

	@Input() profileId: string;
	@Input() totalAssetCount: number;
	@Input() assetsCount: number;
	@Input()
	set assetsCountHistory(assetsCountHistory: number[]) {
		this._assetsCountHistory = assetsCountHistory && assetsCountHistory.length === 1 ?
			[...assetsCountHistory, ...assetsCountHistory] :
			assetsCountHistory;
	}
	get assetsCountHistory(): number[] {
		return this._assetsCountHistory;
	}

	constructor(
		private paris: Paris,
		private changeDetectionRef: ChangeDetectorRef,
		public i18nService: I18nService) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (this.assetsCount === -1) {
			this.isLoading = true;
			const statistics$ = this.paris.apiCall<ProfilesStatistics>(DeviceComplianceStatisticsApiCall, {profileId: this.profileId});
			this._subscription && this._subscription.unsubscribe();
			this._subscription = statistics$.subscribe((statistics: ProfilesStatistics) => {
				this.assetsCount = statistics.passedDevices;
				this.totalAssetCount = statistics.totalDevices;
				this.assetsCountHistory = [this.assetsCount, this.assetsCount];
				this.isLoading = false;
				this.changeDetectionRef.detectChanges();
			});
		}
		this.changeDetectionRef.detectChanges();
	}

	ngOnDestroy(): void {
		this._subscription && this._subscription.unsubscribe();
	}

}
