import { Component } from '@angular/core';
import { MachineGroup } from '@wcd/domain';
import { Paris, Repository } from '@microsoft/paris';
import { MachineGroupFields } from '../../services/machine-group.fields';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { PanelType } from '@wcd/panels';
import { DataViewConfig } from '@wcd/dataview';
import { MachineGroupRemediationLevelEditPanelComponent } from './machine-group-remediation-level-edit.panel.component';
import { map, take } from 'rxjs/operators';
import { MachinesService } from '../../../machines/services/machines.service';
import { FabricIconNames } from '@wcd/scc-common';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../../insights/services/performance.scc.service';

@Component({
	selector: 'machine-groups-remediation-level-dataview',
	providers: [MachineGroupFields],
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<dataview
				class="wcd-flex-1"
				id="machine-groups-remediation-level"
				[allowMultipleSelection]="false"
				[dataViewConfig]="dataViewConfig"
				[repository]="repository"
				[defaultSortFieldId]="'priority'"
				[refreshOn]="lastUpdate"
				[allowFilters]="false"
				[allowPaging]="false"
				[allowAdd]="false"
				[ignoreQueryParams]="true"
				(onItemClick)="openEditPanel($event.item)"
				[fields]="machineGroupFields.fields"
				[itemName]="itemName"
				[label]="
					'customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.title'
						| i18n
				"
				commandBarNoPadding="true"
				responsiveActionBar="true"
				responsiveLayout="true"
				[removePaddingRight]="isScc"
				(onTableRenderComplete)="onTableRenderComplete()"
			>
				<div class="wcd-padding-top" dataview-header>
					{{ 'machineGroup.remediationLevelDescription' | i18n }}
				</div>
			</dataview>
		</div>
	`,
})
export class MachineGroupsRemediationLevelDataviewComponent {
	repository: Repository<MachineGroup>;
	lastUpdate: Date;
	dataViewConfig: DataViewConfig<MachineGroup>;
	isScc = sccHostService.isSCC;
	itemName: string;

	constructor(
		public paris: Paris,
		public machineGroupFields: MachineGroupFields,
		private dialogsService: DialogsService,
		private machinesService: MachinesService,
		private performanceSccService: PerformanceSccService
	) {
		const repository = paris.getRepository(MachineGroup);
		this.itemName = repository.entity.singularName;
		this.dataViewConfig = {
			id: `unappliedMachineGroups`,
			fixedOptions: {
				addAadGroupNames: 'true',
				addMachineGroupCount: 'true',
			},
			loadResults: options =>
				this.machinesService
					.getFullUserExposedMachineGroups({
						...options,
						where: {
							addAadGroupNames: 'true',
							addMachineGroupCount: 'true',
						},
					})
					.pipe(map(groups => ({ items: groups, count: groups.length }))),
		};
	}

	openEditPanel(machineGroup: MachineGroup): void {
		this.dialogsService
			.showPanel(
				MachineGroupRemediationLevelEditPanelComponent,
				{
					id: 'machine-group-edit-panel',
					type: PanelType.large,
					isModal: true,
					showOverlay: true,
					isBlocking: true,
					headerIcon: FabricIconNames.System,
					noBodyPadding: true,
					hasCloseButton: false,
				},
				{
					machineGroup: machineGroup,
					onDone: () => {
						this.lastUpdate = new Date();
					},
				}
			)
			.pipe(take(1))
			.subscribe();
	}

	onTableRenderComplete(){
		this.performanceSccService.endNgPageLoadPerfSession('endpoints-auto-remediation-levels');
	}
}
