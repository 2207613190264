import { NgZone } from '@angular/core';
import { StoreBackendBase } from './store.backend.base';
import { ReadOnlyIdentifiable } from './readonly-identifiable.model';
import { IPreload, PreloadError } from '@wcd/shared';
import { DataSet } from '@microsoft/paris';
import { DataviewField } from '@wcd/dataview';
import { ItemActionModel } from '../../dataviews/models/item-action.model';
import { IStore } from './store.interface';
import { combineLatest, merge, Observable, of, Subscriber, throwError } from 'rxjs';
import { Dictionary } from '@wcd/config';
import { map, mergeMap, share, tap } from 'rxjs/operators';
import { intersection, isEqual } from 'lodash-es';

export class ReadOnlyStore<T extends ReadOnlyIdentifiable<U>, U extends string | number> implements IStore {
	protected _items: Array<T>;
	items$: Observable<Array<T>>;

	private _itemsDictionary: Dictionary<U, T>;
	protected _itemsObserver: Subscriber<Array<T>>;

	private _isLoading: boolean = false;
	private _isInit: boolean = false;

	private _itemActions: { [index: string]: ItemActionModel } = {};

	get isLoading(): boolean {
		return this._isLoading;
	}

	get cachedItems(): Array<T> {
		return this._items;
	}

	get isEmpty(): boolean {
		return !this._items || !this._items.length;
	}

	get isInit(): boolean {
		return this._isInit;
	}

	get itemsDictionary(): Dictionary<U, T> {
		if (this._items && !this._itemsDictionary) this.setDictionary();

		return this._itemsDictionary;
	}

	constructor(
		protected backendService: StoreBackendBase,
		protected ngZone: NgZone,
		protected itemConstructor: new (data: any, ...args: Array<any>) => T,
		public options: ReadOnlyStoreOptions
	) {
		this._isInit = options.requiresInit === false;
		if (!this.options.requiresInit) this.setItemsObservable();
	}

	/**
	 * Modifies an item's data before it's passed to the T constructor
	 * @param itemData
	 * @returns {any}
	 */
	protected parseData(itemData: any): any {
		return itemData;
	}

	createItem(itemData?: any): T {
		const parsedItemData: any = this.parseData(itemData) || itemData;

		return new (Function.prototype.bind.apply(
			this.itemConstructor,
			[null].concat(this.options.itemParams || [])
		))(parsedItemData);
	}

	updateItem(item: T, itemData: any): boolean {
		if (isEqual(item.__data, itemData)) return false;

		item.setData.apply(item, [itemData].concat(this.options.itemParams));
		return true;
	}

	cloneItem(item: T): T {
		return this.createItem(item.__data);
	}

	getCachedItem(itemId: U): T {
		return this._itemsDictionary ? this._itemsDictionary.get(itemId) : null;
	}

	trackItemId(index: number, item: ReadOnlyIdentifiable<U>): string {
		return String(item.id);
	}

	protected parseItems(items: Array<T>): Array<T> {
		return items;
	}

	getItemById(
		itemId: U,
		options?: { [index: string]: any },
		fromCache?: boolean,
		refreshItems: boolean = true
	): Observable<T> {
		if ((this.options.cacheItems && fromCache !== false) || (!this.options.cacheItems && fromCache)) {
			return new Observable<T>(observer => {
				if (this.itemsDictionary) {
					const cacheItem = this.itemsDictionary.get(itemId);
					if (cacheItem) {
						observer.next(cacheItem);
						return;
					}
				}

				if (refreshItems !== false) {
					this.items$.subscribe(() => {
						if (!this._itemsDictionary) this.setDictionary();

						observer.next(this._itemsDictionary.get(itemId));
					});
				} else observer.next(null);
			});
		} else
			return this.backendService.getItem(itemId, options).pipe(
				mergeMap(itemData => {
					try {
						return of(this.createItem(itemData));
					} catch (e) {
						return throwError(e);
					}
				})
			);
	}

	getItemsByIds(itemIds: Array<U>): Observable<Array<T>> {
		return new Observable<Array<T>>(observer => {
			if (this.itemsDictionary) {
				observer.next(this.itemsDictionary.getList(itemIds));
				return observer.complete();
			}

			this.items$.subscribe(() => {
				if (!this._itemsDictionary) this.setDictionary();

				observer.next(this._itemsDictionary.getList(itemIds));
			});
		});
	}

	protected initDependencies(forceRefresh: boolean = false): Observable<any> {
		return new Observable(observer => {
			if (!this.options.dependencies || !this.options.dependencies.length) {
				observer.next();
				observer.complete();
			} else {
				combineLatest(this.options.dependencies.map(service => service.init(forceRefresh))).subscribe(
					values => {
						observer.next(values);
						observer.complete();
						return values;
					},
					error => observer.error(error)
				);
			}
		});
	}

	private setItemsObservable() {
		const itemsObservable = new Observable<Array<T>>(observer => {
			this._itemsObserver = observer;
		}).pipe(share());

		const loaderObservable: Observable<Array<T>> = new Observable<Array<T>>(observer =>
			observer.next([])
		).pipe(
			tap(() => {
				this._isLoading = true;
			})
		);

		const mergedObservable = merge(
			itemsObservable,
			this.backendService.getAllResults().pipe(
				map((itemsDataSet: any) => {
					if (!itemsDataSet.items)
						throw `${this.options.itemNameSingular} loaded with no 'items' property.`;

					this._items = itemsDataSet.items.map(itemData => this.createItem(itemData));
					this._items = this.parseItems(this._items);

					if (this._itemsDictionary || this.options.cacheItems) this.setDictionary();

					this.ngZone.run(() => {
						this._isLoading = false;
					});

					return this._items;
				}),
				share()
			)
		);

		this.items$ = loaderObservable.pipe(mergeMap(() => mergedObservable));
	}

	init(forceRefresh: boolean = false): Observable<Array<T>> {
		if (this.options.requiresInit) this.setItemsObservable();

		const initObservable = new Observable<Array<T>>(observer => {
			if (this.isInit && !forceRefresh) {
				observer.next(this._items);
				observer.complete();
			} else {
				this.items$.subscribe(
					(items: Array<T>) => {
						this._isInit = true;
						observer.next(items);
						observer.complete();
					},
					(error: any) => {
						const errorMessage = `Error preloading ${this.options.itemNamePlural}.`;
						console.error(errorMessage, error);
						const err: PreloadError = {
							message: errorMessage,
							error: error,
						};
						observer.error(err);
					},
					() => observer.complete()
				);
			}
		});

		return this.initDependencies(forceRefresh).pipe(mergeMap(() => initObservable));
	}

	getItemsDataSet(options?: { [index: string]: any }): Observable<DataSet<T>> {
		const getResultsObservable = this.backendService.getAllResults(options).pipe(
			map((rawDataSet: any) => {
				return {
					count: rawDataSet.count,
					items: rawDataSet.items.map(itemData => this.createItem(itemData)),
					options: options,
				};
			})
		);

		return getResultsObservable;
	}

	private setDictionary() {
		this._itemsDictionary = Dictionary.fromList<U, T>(this._items, 'id');
	}

	protected getExportItemAction(): ItemActionModel {
		return new ItemActionModel({
			id: 'export',
			name: 'Export',
			icon: 'export',
			method: (items: Array<T>) => this.exportItems(items),
			tooltip: 'Export the selected ' + this.options.itemNamePlural,
			refreshOnResolve: true,
		});
	}

	/**
	 * Returns all the actions the are common to the given items
	 * @param items
	 */
	getItemsActions(items: Array<T>): Array<ItemActionModel> {
		return intersection.apply(this, items.map(item => this.getItemActions(item)));
	}

	/**
	 * Returns the actions that are supported by the given item
	 * @param item
	 */
	protected getItemActions(item: T): Array<ItemActionModel> {
		return [];
	}

	exportAllResults(options?: { [index: string]: any }, format: string = 'csv'): Promise<any> {
		return this.backendService.exportAllResults(options, format);
	}

	protected exportItems(items: Array<T>, apiOptions?: any): Promise<any> {
		return this.backendService.export(items.map(item => item.id), apiOptions);
	}

	protected getAvailableAction(actionId: string, getNewAction: () => ItemActionModel): ItemActionModel {
		let action = this._itemActions[actionId];
		if (!action) action = this._itemActions[actionId] = getNewAction.bind(this)();

		return action;
	}
}

export interface ReadOnlyStoreOptions {
	itemParams?: Array<any>;
	itemNameSingular: string;
	itemNamePlural: string;
	iconName?: string;
	cacheItems?: boolean;
	showVendors?: boolean;
	productLabel?: string;
	editDialogHeight?: number;
	requiresInit?: boolean;
	dependencies?: Array<IPreload>;
	getDataviewItemActions?: () => Array<ItemActionModel>;
	dataViewOptions?: {
		isItemClickable?: (item) => boolean;
		isItemSelectable?: (item) => boolean;
		fields?: Array<DataviewField>;
		searchEnabled?: boolean;
		exportEnabled?: boolean;
		sortField?: string;
		visibleFields?: Array<string>;
	};
	showSnackbarOnUpdate?: boolean;
	templateObjectType?: string;
	selectTemplate?: boolean;
	allowDeleteUndo?: boolean;
}
