import { SevilleAlertsModule } from '../../alerts/seville.alerts.module';

alertsContextMenuController.$inject = ['$scope', 'alertFilterService', '$rootScope'];

SevilleAlertsModule.controller('alertsContextMenuController', alertsContextMenuController);

function alertsContextMenuController($scope, alertFilterService, $rootScope) {
	var vm = this;

	vm.toggleAlertMode = function(ioa) {
		// We're using stop-propagation, make sure we close everything
		$rootScope.$broadcast('context-menu/close');

		if ($scope.loading) return;

		var alertId = vm.getAlertId(ioa);

		if (!alertId) {
			return;
		}

		$rootScope.$broadcast('alertContextMenu:selectAlert', { alert: ioa, alertId: alertId });
	};

	vm.manageAlert = function(ioa) {
		// We're using stop-propagation, make sure we close everything
		$rootScope.$broadcast('context-menu/close');

		var alertId = vm.getAlertId(ioa);

		if (!alertId) {
			return;
		}

		$rootScope.$broadcast('alertContextMenu:manageAlert', { alert: ioa, alertId: alertId });
	};

	vm.ishighlightActive = function(ioa) {
		if (!ioa) {
			return false;
		}

		var alertId = vm.getAlertId(ioa);
		if (!alertId) {
			return false;
		}

		return alertFilterService.ishighlightActive(alertId);
	};

	vm.clearFilters = function() {
		// We're using stop-propagation, make sure we close everything
		$rootScope.$broadcast('context-menu/close');

		alertFilterService.clearFilters();
	};

	vm.showAlertContextMenu = function(item) {
		if ($scope.loading) {
			// Close so the context menu won't appear and surprise the use when we stop loading
			$rootScope.$broadcast('context-menu/close');

			return false;
		}

		return vm.getAlertId(item);
	};

	/* Support events and alerts from associated ioas directive and events directive */
	vm.getAlertId = function(item) {
		if (!item) {
			return '';
		}
		var alertId = item.AlertId;
		if (alertId) return alertId;

		return item.type == 'Alert' ? item.id : '';
	};

	vm.getAlertSeverity = function(item) {
		return item.Severity || item.severity;
	};

	vm.getAlertTime = function(item) {
		return item.LastEventTime || item.LastSeen || item.time;
	};

	vm.getText = function(item) {
		if (vm.ishighlightActive(item)) {
			return 'Deselect alert and unmark events';
		} else {
			return 'Select alert and mark events';
		}
	};
}
