import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TvmColorService, TvmColor } from '../../services/tvm-color.service';
import { PrettyNumberService } from '@wcd/prettify';

const MAX_DAYS_OF_HISTORY = 30;

export interface TvmScoreTrendOptions {
	scoreHistory: number[];
	title: string;
	// If graph color isn't set - coloring will be done according to improvement
	iconColor?: TvmColor;
	// Set to true if lower score means better score
	lowerScoreBetter?: boolean;
}

@Component({
	selector: 'tvm-score-trend',
	templateUrl: './tvm-score-trend.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvmScoreTrendComponent {
	advancementScore: string;
	iconName: string;
	iconColorClass: string;
	title: string;
	historyDays: number;

	@Input()
	set options(trendOptions: TvmScoreTrendOptions) {
		// Include only up to 30 days of history data
		const scoreHistory = trendOptions.scoreHistory.slice(0, MAX_DAYS_OF_HISTORY);

		this.historyDays = scoreHistory.length;
		let advancement =
			this.historyDays > 0 ? Math.round(scoreHistory[0] - scoreHistory[this.historyDays - 1]) : 0;

		const negativeTrend =
			(advancement < 0 && !trendOptions.lowerScoreBetter) ||
			(advancement > 0 && trendOptions.lowerScoreBetter);
		const defaultColoring = negativeTrend ? TvmColor.HighRisk : TvmColor.Positive;

		this.iconName = advancement < 0 ? 'StockDown' : 'StockUp';

		if (advancement < 0) {
			advancement = advancement * -1;
		}
		this.advancementScore = this.prettyNumberService.prettyNumber(advancement);
		this.iconColorClass = this.tvmColorService.fontColorsClassMap.get(
			trendOptions.iconColor ? trendOptions.iconColor : defaultColoring
		);
		this.title = trendOptions.title;
	}

	constructor(private tvmColorService: TvmColorService, private prettyNumberService: PrettyNumberService) {}
}
