var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { from } from 'rxjs';
import { BaselineConfigurationByCategory } from '@wcd/domain';
import { DataviewField } from '@wcd/dataview';
import { ConfigurationsByCategoryFieldsService } from '../../profiles-tab/services/configurations-by-category.fields.service';
import { Paris } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { DataViewComponent } from '../../../../../dataviews/components/dataview.component';
var ProfileConfigurationsAssignemntStepComponent = /** @class */ (function (_super) {
    __extends(ProfileConfigurationsAssignemntStepComponent, _super);
    function ProfileConfigurationsAssignemntStepComponent(globalEntityTypesService, paris, i18n, configurationsByCategoryFieldsService, changeDetectionRef) {
        var _this = _super.call(this) || this;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.paris = paris;
        _this.i18n = i18n;
        _this.configurationsByCategoryFieldsService = configurationsByCategoryFieldsService;
        _this.changeDetectionRef = changeDetectionRef;
        _this.MessageBarType = MessageBarType;
        _this.localRefreshOn = 0;
        _this.categoryIdPrefix = "category-";
        _this.entityType = _this.globalEntityTypesService.getEntityType(BaselineConfigurationByCategory);
        return _this;
    }
    ProfileConfigurationsAssignemntStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setStepValidation(false);
        this.boundGetContainedConfigurations = this.getContainedConfigurations.bind(this);
        this.boundGetNameColumnDisplay = this.GetNameColumnDisplay.bind(this);
        this.boundIsGroupExpanded = this.isGroupExpanded.bind(this);
        this.boundIsSettingSelected = this.isSettingSelected.bind(this);
        this.boundIsGroup = this.isGroup.bind(this);
        this.fields = DataviewField.fromList(this.configurationsByCategoryFieldsService.fields.map(function (field) { return (field.id === "configurationByCategoryName" ? (__assign({}, field, { getDisplay: _this.boundGetNameColumnDisplay })) : field); }));
        this.repository = this.paris.getRepository(BaselineConfigurationByCategory);
        this.dataViewConfig = {
            id: 'profileConfigurationsWizardConfig',
            fixedOptions: {
                benchmarkName: this.data.benchmark,
                benchmarkVersion: this.data.benchmarkVersion,
                complianceLevel: this.data.complianceLevel
            },
        };
        this.previouslySelectedSettings = this.data.selectedSettings;
        this.currentlySelectedSettings = [];
        this.onInit = true;
        this.setStepValidation(this.data.selectedSettings.length !== 0);
        this.changeDetectionRef.markForCheck();
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.unselectAll = function () {
        this.data.selectedSettings = [];
        this.currentlySelectedSettings = [];
        this.previouslySelectedSettings = [];
        this.dataViewComponent.unSelectAllItems();
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.getHowManySelectedText = function () {
        return this.i18n.get('baseline_profile_wizard_profile_configurations_assignment_selected', { selectedCount: this.data.selectedSettings.length });
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.selectSetting = function (selectedSettings) {
        var _this = this;
        this.onInit = false;
        var flattenedSelectedSettings = selectedSettings.reduce(function (settings, currentSetting) {
            if (currentSetting.id.startsWith(_this.categoryIdPrefix))
                return settings.concat(_this.getGroupSettings(currentSetting));
            return settings.concat([currentSetting]);
        }, []);
        var distinctNewSelected = this.distinctSelectedSettings(flattenedSelectedSettings);
        this.removeSharedSettingsFromPreviousSelected(distinctNewSelected);
        this.currentlySelectedSettings = distinctNewSelected;
        this.data.selectedSettings = this.distinctSelectedSettings(this.currentlySelectedSettings.concat(this.previouslySelectedSettings));
        this.setStepValidation(this.data.selectedSettings.length !== 0);
        this.localRefreshOn++;
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.onSearch = function (term) {
        this.previouslySelectedSettings = this.data.selectedSettings;
        this.currentlySelectedSettings = [];
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.isGroup = function (configuration) {
        return configuration && configuration.id.startsWith(this.categoryIdPrefix);
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.removeSharedSettingsFromPreviousSelected = function (distinctNewSelected) {
        var ids = distinctNewSelected.map(function (item) { return item.id; });
        this.previouslySelectedSettings = this.previouslySelectedSettings.filter(function (item) { return !ids.includes(item.id); });
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.distinctSelectedSettings = function (selectedSettings) {
        var distinctIds = Array.from(new Set(selectedSettings.map(function (item) { return item.id; })));
        return distinctIds.map(function (id) { return selectedSettings.filter(function (item) { return item.id === id; })[0]; });
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.isSettingSelected = function (setting) {
        var _this = this;
        var selectedIds = setting.id.startsWith(this.categoryIdPrefix) ? setting.configurations.map(function (conf) { return conf.id; }) : [setting.id];
        return selectedIds.filter(function (id) { return _this.data.selectedSettings.every(function (item) { return item.id !== id; }); }).length === 0;
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.getContainedConfigurations = function (conf) {
        var res = !conf.complianceLevels ? from(new Array()) : from(new Array(this.getGroupSettings(conf)));
        return res;
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.getGroupSettings = function (conf) {
        return conf.configurations.map(function (configuration) {
            return new BaselineConfigurationByCategory({
                id: configuration.id,
                title: configuration.title,
                complianceLevels: configuration.complianceLevels,
                category: conf.category
            });
        });
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.isGroupExpanded = function (conf) {
        var _this = this;
        if (this.onInit && this.data.checks && this.data.checks.length !== 0)
            this.addChecksToSelectedSettings(conf);
        var selectedChildrenCount = conf.configurations.filter(function (item) { return _this.data.selectedSettings.some(function (setting) { return setting.id === item.id; }); }).length;
        return selectedChildrenCount !== conf.count && selectedChildrenCount !== 0;
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.addChecksToSelectedSettings = function (setting) {
        var _this = this;
        var settings = setting.id.startsWith(this.categoryIdPrefix) ? this.getGroupSettings(setting) : [setting];
        settings.forEach(function (item) {
            if (_this.data.checks.includes(item.id)) {
                _this.previouslySelectedSettings.push(item);
                _this.data.selectedSettings.push(item);
            }
        });
    };
    ProfileConfigurationsAssignemntStepComponent.prototype.GetNameColumnDisplay = function (conf) {
        var _this = this;
        var selectedCount = this.currentlySelectedSettings.reduce(function (count, selectedSetting) {
            if (selectedSetting.category !== conf.category)
                return count;
            return count + (selectedSetting.id.startsWith(_this.categoryIdPrefix) ? selectedSetting.configurations.length : 1);
        }, 0);
        return conf.id.startsWith(this.categoryIdPrefix) ? conf.category + " (" + selectedCount + "/" + conf.count + ")" : conf.title;
    };
    return ProfileConfigurationsAssignemntStepComponent;
}(WizardBaseStep));
export { ProfileConfigurationsAssignemntStepComponent };
