var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { mapValues } from 'lodash-es';
import { getTheme } from 'office-ui-fabric-react';
import { Lazy } from '@wcd/utils';
import { m365ExtendedColors } from '../models/colors/m365-colors-palette.model';
import * as i0 from "@angular/core";
function createMap(palette) {
    return mapValues(palette, function (value, key) { return ({
        name: key,
        raw: value,
        classes: {
            font: "ms-fontColor-" + key,
            background: "ms-bgColor-" + key,
            border: "ms-borderColor-" + key,
        },
    }); });
}
/**
 * Service for exposing colors (in various forms) to components and other consumers in an easy way.
 */
var KnownColorsService = /** @class */ (function () {
    function KnownColorsService() {
        var _this = this;
        this._theme = getTheme();
        this._colorsMap = new Lazy(function () {
            return __assign({}, createMap(_this._theme.palette), createMap(m365ExtendedColors));
        });
        this._fontColorsClassMap = new Lazy(function () {
            return _this._calculateClassMap('font');
        });
        this._backgroundColorsClassMap = new Lazy(function () {
            return _this._calculateClassMap('background');
        });
        this._borderColorsClassMap = new Lazy(function () {
            return _this._calculateClassMap('border');
        });
    }
    Object.defineProperty(KnownColorsService.prototype, "knownColorsMap", {
        get: function () {
            return this._colorsMap.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KnownColorsService.prototype, "fontColorsClassMap", {
        get: function () {
            return this._fontColorsClassMap.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KnownColorsService.prototype, "backgroundColorsClassMap", {
        get: function () {
            return this._backgroundColorsClassMap.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KnownColorsService.prototype, "borderColorsClassMap", {
        get: function () {
            return this._borderColorsClassMap.value;
        },
        enumerable: true,
        configurable: true
    });
    KnownColorsService.prototype._calculateClassMap = function (type) {
        return mapValues(this.knownColorsMap, function (value) { return value.classes[type]; });
    };
    KnownColorsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KnownColorsService_Factory() { return new KnownColorsService(); }, token: KnownColorsService, providedIn: "root" });
    return KnownColorsService;
}());
export { KnownColorsService };
