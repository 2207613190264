// tslint:disable:template-accessibility-label-for
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	AfterViewInit,
	ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { IChoiceGroupOption } from 'office-ui-fabric-react';
import { AntivirusScanType } from '@wcd/domain';
import { ModalContainer } from '../../../../dialogs/modals/models/modal-container.model';
import { CustomConfirmComponent } from '../../../../dialogs/models/custom-confirm-options';
import { I18nService } from '@wcd/i18n';

export interface RunAntivirusScanModalPayload {
	readonly scanType: AntivirusScanType;
	readonly comment: string;
}

/**
 * Local type for a more type-safe `IChoiceGroupOption`
 */
type ChoiceGroupOption = IChoiceGroupOption & { key: AntivirusScanType };

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<modal (close)="destroy()" [settings]="settings">
			<confirm-modal-content
				class="wcd-flex-1 ie11Patch ie11Flex"
				[confirmButtonOptions]="{
					disabled: isSaving || !selectedScanType || !commentText
				}"
				[isSaving]="isSaving"
				(onCancel)="onCancelHandler()"
				(onConfirm)="onConfirmHandler()"
			>
				<p>{{ 'machines.entityDetails.actions.runAntivirusScan.dialog.content' | i18n }}</p>

				<fab-choice-group
					[label]="
						('machines.entityDetails.actions.runAntivirusScan.dialog.form.scanType.label'
							| i18n) + ':'
					"
					[required]="true"
					[options]="scanTypesOptions"
					(onChange)="onScanTypeChanged($event)"
				>
				</fab-choice-group>

				<label class="wcd-required"
					>{{ 'machines.entityDetails.actions.$general.dialog.form.comment.label' | i18n }}:</label
				>
				<textarea
					[attr.aria-label]="
						'machines.entityDetails.actions.$general.dialog.form.comment.label' | i18n
					"
					required
					rows="4"
					[placeholder]="
						'machines.entityDetails.actions.$general.dialog.form.comment.placeholder' | i18n
					"
					class="form-control dialog-textarea wcd-full-width wcd-no-resize-horizontal"
					[(ngModel)]="commentText"
				></textarea>
			</confirm-modal-content>
		</modal>
	`,
})
export class RunAntivirusScanModalComponent extends ModalContainer
	implements CustomConfirmComponent<RunAntivirusScanModalPayload>, OnInit {
	@Input() machineName: string;
	@Input() isSaving: boolean = false;

	@Output() readonly onConfirm = new EventEmitter<RunAntivirusScanModalPayload>();
	@Output() readonly onCancel = new EventEmitter<void>();

	selectedScanType: AntivirusScanType;
	commentText: string;

	readonly scanTypesOptions: ReadonlyArray<ChoiceGroupOption>;

	constructor(router: Router, private readonly i18nService: I18nService, protected elementRef: ElementRef) {
		super(router);

		this.scanTypesOptions = [
			{
				key: 'Quick',
				text: i18nService.get(
					'machines.entityDetails.actions.runAntivirusScan.dialog.form.scanType.options.quick'
				),
			},
			{
				key: 'Full',
				text: i18nService.get(
					'machines.entityDetails.actions.runAntivirusScan.dialog.form.scanType.options.full'
				),
			},
		];
	}

	ngOnInit() {
		this.settings = {
			...this.settings,
			className: 'wcd-flex-vertical',
			title: this.i18nService.get('machines.entityDetails.actions.runAntivirusScan.dialog.title', {
				machineName: this.machineName,
			}),
		};
	}

	onConfirmHandler() {
		this.onConfirm.emit({
			comment: this.commentText,
			scanType: this.selectedScanType,
		});
		this.onConfirm.complete();
	}

	onCancelHandler() {
		this.onCancel.emit();
		this.onCancel.complete();
	}

	onScanTypeChanged({ option }: { option: ChoiceGroupOption }) {
		this.selectedScanType = option.key;
	}
}
