var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentFactoryResolver } from "@angular/core";
import { Paris } from '@microsoft/paris';
import { BaselineProfileForSaving, ComplianceOverTimeApiCall, DeviceComplianceStatisticsApiCall, ProfileFiltersFieldsApiCall } from "@wcd/domain";
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { ProfileWizardComponent } from "../../components/profile-wizard/profile-wizard.component";
import { ProfileForCreationFormData } from "../models/profile-for-creation.model";
import { PanelType } from '@wcd/panels';
import { I18nService } from '@wcd/i18n';
import { map } from "rxjs/operators";
import { ActivatedEntity } from "../../../../../global_entities/services/activated-entity.service";
import { Router } from "@angular/router";
var ProfilesService = /** @class */ (function () {
    function ProfilesService(dialogsService, componentFactoryResolver, i18nService, activatedEntity, router, paris) {
        this.dialogsService = dialogsService;
        this.componentFactoryResolver = componentFactoryResolver;
        this.i18nService = i18nService;
        this.activatedEntity = activatedEntity;
        this.router = router;
        this.paris = paris;
        this.saveBaselineProfile = this.saveBaselineProfile.bind(this);
        this.repo = paris.getRepository(BaselineProfileForSaving);
    }
    ProfilesService.prototype.showWizardPanel = function (baselineProfileDetails) {
        var _this = this;
        var profileForCreationFormData;
        var isUpdateFlow = !!baselineProfileDetails;
        profileForCreationFormData = isUpdateFlow
            ? this.convertBaselineProfileDetailsToFormData(baselineProfileDetails, profileForCreationFormData)
            : new ProfileForCreationFormData();
        return new Promise(function (resolve, reject) {
            _this.dialogsService.showPanel(ProfileWizardComponent, {
                id: 'profile-wizard',
                type: PanelType.wizard,
                hasCloseButton: false,
                noBodyPadding: true,
            }, {
                onDone: resolve,
                onClose: resolve,
                data: profileForCreationFormData,
                isUpdateFlow: isUpdateFlow,
            }, _this.componentFactoryResolver);
        });
    };
    ProfilesService.prototype.convertBaselineProfileDetailsToFormData = function (baselineProfileDetails, profileForCreationFormData) {
        profileForCreationFormData = new ProfileForCreationFormData({
            id: baselineProfileDetails.id,
            name: baselineProfileDetails.name,
            description: baselineProfileDetails.description,
            isEnabled: baselineProfileDetails.status,
            operatingSystem: baselineProfileDetails.operatingSystem,
            operatingSystemVersion: baselineProfileDetails.operatingSystemVersion,
            benchmark: baselineProfileDetails.benchmark,
            benchmarkVersion: baselineProfileDetails.version,
            checks: baselineProfileDetails.checks,
            complianceLevel: baselineProfileDetails.complianceLevel,
            eTag: baselineProfileDetails.eTag,
            isUpdateFlow: true,
            selectedSettings: []
        });
        return profileForCreationFormData;
    };
    ProfilesService.prototype.convertBaselineProfileDetailsToSaveModel = function (baselineProfileDetails) {
        return new BaselineProfileForSaving({
            id: baselineProfileDetails.id,
            name: baselineProfileDetails.name,
            description: baselineProfileDetails.description,
            operatingSystem: baselineProfileDetails.operatingSystem,
            operatingSystemVersion: baselineProfileDetails.operatingSystemVersion,
            benchmark: baselineProfileDetails.benchmark,
            benchmarkVersion: baselineProfileDetails.version,
            complianceLevel: baselineProfileDetails.complianceLevel,
            isEnabled: baselineProfileDetails.status,
            checks: baselineProfileDetails.checks,
            eTag: baselineProfileDetails.eTag
        });
    };
    ProfilesService.prototype.saveBaselineProfile = function (baselineProfileData) {
        var itemToSave = {
            name: baselineProfileData.name,
            description: baselineProfileData.description,
            isEnabled: baselineProfileData.isEnabled,
            operatingSystem: baselineProfileData.operatingSystem,
            operatingSystemVersion: baselineProfileData.operatingSystemVersion,
            benchmark: baselineProfileData.benchmark,
            benchmarkVersion: baselineProfileData.benchmarkVersion,
            complianceLevel: baselineProfileData.complianceLevel,
            checks: baselineProfileData.checks,
            eTag: baselineProfileData.eTag,
        };
        itemToSave.id = baselineProfileData.id;
        var saveObservable$ = this.repo.save(itemToSave);
        this.paris.clearApiCallCache([
            ComplianceOverTimeApiCall,
            DeviceComplianceStatisticsApiCall
        ]);
        return saveObservable$;
    };
    ProfilesService.prototype.deleteProfile = function (profile) {
        var profileToDelete = this.convertBaselineProfileDetailsToSaveModel(profile);
        var deleteObservable$ = this.repo.removeItem(profileToDelete, {
            data: { eTag: profile.eTag },
        });
        this.paris.clearApiCallCache([
            ComplianceOverTimeApiCall,
            DeviceComplianceStatisticsApiCall
        ]);
        return deleteObservable$.toPromise();
    };
    ProfilesService.prototype.updateBaselineProfile = function (profile) {
        var profileToUpdate = this.convertBaselineProfileDetailsToSaveModel(profile);
        var saveObservable$ = this.repo.save(profileToUpdate);
        this.paris.clearApiCallCache([
            ComplianceOverTimeApiCall,
            DeviceComplianceStatisticsApiCall
        ]);
        return saveObservable$.toPromise();
    };
    ProfilesService.prototype.getFilters = function () {
        var _this = this;
        if (!this._profileFilters) {
            this._profileFilters = {
                profileStatus: {
                    count: null,
                    values: [
                        {
                            value: true,
                            name: this.i18nService.strings.tvm_baseline_profiles_enabled,
                            priority: 1
                        },
                        {
                            value: false,
                            name: this.i18nService.strings.tvm_baseline_profiles_disabled,
                            priority: 2
                        }
                    ]
                }
            };
        }
        return this.paris
            .apiCall(ProfileFiltersFieldsApiCall)
            .pipe(map(function (filtersFields) {
            var dynamicFilters = {};
            filtersFields.forEach(function (filterField) {
                dynamicFilters[filterField.name] = {
                    values: Object.values(filterField.filters).map(function (filter) { return new Object({
                        value: filter,
                        name: filter.split("-_-").join(" ")
                    }); }),
                };
            });
            return __assign({}, dynamicFilters, _this._profileFilters);
        }));
    };
    ProfilesService.prototype.navigateToProfilePage = function (profile) {
        this.activatedEntity.setNextEntity(profile);
        return this.router.navigate(["/baseline-compliance_t/profiles/" + encodeURIComponent(profile.id)]);
    };
    return ProfilesService;
}());
export { ProfilesService };
