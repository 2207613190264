var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ApiCall, ApiCallModel, ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { ImpactedEntities } from '../impacted-entities/incident.impacted.entities';
var HuntingRecordEntitiesResponse = /** @class */ (function (_super) {
    __extends(HuntingRecordEntitiesResponse, _super);
    function HuntingRecordEntitiesResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'ImpactedEntities' }),
        __metadata("design:type", ImpactedEntities)
    ], HuntingRecordEntitiesResponse.prototype, "impactedEntities", void 0);
    HuntingRecordEntitiesResponse = __decorate([
        ValueObject({
            singularName: 'Hunting record main entities response',
            pluralName: '',
        })
    ], HuntingRecordEntitiesResponse);
    return HuntingRecordEntitiesResponse;
}(ModelBase));
export { HuntingRecordEntitiesResponse };
var ɵ0 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ1 = function (data) { return ({ ImpactedEntities: data }); }, ɵ2 = function (params) { return ({ data: { row: params.dataItem } }); };
var HuntingRecordEntitiesApiCall = /** @class */ (function (_super) {
    __extends(HuntingRecordEntitiesApiCall, _super);
    function HuntingRecordEntitiesApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingRecordEntitiesApiCall = __decorate([
        ApiCall({
            name: 'Get main entities of hunting query result record',
            endpoint: 'record/entities',
            method: 'POST',
            type: HuntingRecordEntitiesResponse,
            baseUrl: ɵ0,
            parseData: ɵ1,
            parseQuery: ɵ2,
        })
    ], HuntingRecordEntitiesApiCall);
    return HuntingRecordEntitiesApiCall;
}(ApiCallModel));
export { HuntingRecordEntitiesApiCall };
export { ɵ0, ɵ1, ɵ2 };
