import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ColorClassNames } from '@uifabric/styling';
import { compact } from 'lodash-es';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { Icon } from '../models/icon.model';
import { FabricIconName, isFabricIconName } from '@wcd/scc-common';
import { IconsService } from '../services/icons.service';
import { iconLibraries } from '../services/icon_libraries';
import { isWcdIconName, WcdIconName } from '@wcd/icons-font';
import { IIconStyles } from 'office-ui-fabric-react';

export type VerticalAlign = 'top' | 'middle' | 'bottom' | 'baseline';

type VerticalAlignClass =
	| 'wcd-vertical-align-top'
	| 'wcd-vertical-align-middle'
	| 'wcd-vertical-align-bottom'
	| 'wcd-vertical-align-baseline';

const verticalAlignMap: Record<VerticalAlign, VerticalAlignClass> = {
	top: 'wcd-vertical-align-top',
	middle: 'wcd-vertical-align-middle',
	bottom: 'wcd-vertical-align-bottom',
	baseline: 'wcd-vertical-align-baseline',
};

@Component({
	selector: 'wcd-shared-icon',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<icon-switch
			*ngIf="isFabricReactOrWcdIcon; else fallback"
			[iconName]="parsedIconName"
			[contentClass]="iconDynamicClass"
			[ariaLabel]="ariaLabel"
			[styles]="styles"
		>
		</icon-switch>
		<ng-template #fallback>
			<i [className]="iconTypeClass" [innerText]="text" [ngClass]="iconDynamicClass"></i>
		</ng-template>
	`,
})
export class IconComponent implements OnChanges<IconComponent> {
	@Input() iconName: string | FabricIconName;
	@Input() verticalAlign?: VerticalAlign = null;
	@Input() iconColor?: keyof typeof ColorClassNames;
	@Input() iconClass?: string;
	@Input() styles: IIconStyles;
	@Input() ariaLabel?: string;

	iconTypeClass: string;
	iconDynamicClass: string;
	icon: Icon;

	isFabricReactOrWcdIcon = false;
	parsedIconName: string | FabricIconName | WcdIconName;

	constructor(private iconsService: IconsService, private _changeDetectionRef: ChangeDetectorRef) {}

	get text() {
		return (this.icon && !this.icon.library.useClass && this.icon.name) || '';
	}

	ngOnChanges(changes: TypedChanges<IconComponent>) {
		if (changes.iconName && changes.iconName.currentValue) {
			this.setIcon(this.iconName);
		}

		const verticalAlignClass =
			changes.verticalAlign &&
			changes.verticalAlign.currentValue &&
			this.calculateVerticalAlignClass(changes.verticalAlign.currentValue);

		const colorClass =
			changes.iconColor &&
			changes.iconColor.currentValue &&
			this.calculateIconColorClass(changes.iconColor.currentValue);

		this.iconDynamicClass = this.combineClasses(verticalAlignClass, colorClass, this.iconClass);
		this.iconTypeClass = this.iconsService.getIconClassName(this.icon);

		this._changeDetectionRef.markForCheck();
	}

	private calculateVerticalAlignClass(verticalAlign: VerticalAlign): VerticalAlignClass {
		return verticalAlignMap[verticalAlign];
	}

	private combineClasses(...classNames: string[]): string {
		return compact(classNames).join(' ');
	}

	private calculateIconColorClass(iconColor: keyof typeof ColorClassNames) {
		return ColorClassNames[iconColor];
	}

	private setIcon(iconName: this['iconName']) {
		if (!iconName) {
			return;
		}

		this.parsedIconName = this.getFabricOrWcdIconName(iconName);
		this.isFabricReactOrWcdIcon = !!this.parsedIconName;

		if (!this.isFabricReactOrWcdIcon) {
			this.icon = this.iconsService.getIcon(iconName) || null;
			if (!this.icon) {
				console.warn(`Unknown icon: '${iconName}'.`);
			}
		}
	}

	private getFabricOrWcdIconName(iconName: this['iconName']): FabricIconName | WcdIconName | null {
		if (isFabricIconName(iconName) || isWcdIconName(iconName)) {
			return iconName;
		}

		const icon = this.iconsService.getIcon(iconName);
		if (icon && icon.library === iconLibraries.fabric) {
			return icon.name as FabricIconName;
		} else if (icon && icon.library === iconLibraries.wcd) {
			return icon.name as WcdIconName;
		}

		return null;
	}
}
