
		<section class="wcd-padding-vertical wcd-margin-large-horizontal wcd-full-height wcd-flex-vertical">
			<div
				*ngIf="(isLoadingEntity$ | async); else finishedLoading"
				class="loading-overlay wcd-full-height"
			>
				<i class="large-loader-icon"></i>
			</div>

			<ng-template #finishedLoading>
				<div class="wcd-flex-justify-end-horizontal wcd-flex-center-vertical">
					<copy-to-clipboard
						[ariaLabelFieldName]="'amsiScripts.entityDetails.title' | i18n"
						[content]="script?.content"
						[settings]="{ copyText: '' }"
					></copy-to-clipboard>
					<download-file
						*ngIf="saveSettings?.allowSave"
						[filename]="saveSettings?.fileName"
						[fileContent]="script?.content"
						[disabled]="!script?.content"
					></download-file>
				</div>
				<div class="wcd-flex-vertical wcd-flex-1">
					<pre class="inline-pre color-box-gray-100" style="align-self: stretch">{{
						script?.content
					}}</pre>
				</div>
			</ng-template>
		</section>
	