var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentRef } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { DataviewField } from '@wcd/dataview';
import { DataExportSettingsEntity } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { DataExportNewModalComponent } from './data-export-new.component';
import { AuthService } from '@wcd/auth';
import { sccHostService } from '@wcd/scc-interface';
var MAX_ITEMS = 5;
var NO_DATA = '';
var DataExportDataviewComponent = /** @class */ (function () {
    function DataExportDataviewComponent(paris, globalEntityTypesService, dialogsService, i18nService, authService) {
        var _this = this;
        this.paris = paris;
        this.globalEntityTypesService = globalEntityTypesService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.authService = authService;
        this.globalEntityType = this.globalEntityTypesService.getEntityType(DataExportSettingsEntity);
        this.isScc = sccHostService.isSCC;
        this.entityType = this.globalEntityTypesService.getEntityType(DataExportSettingsEntity);
        this.dataExportRepository = this.paris.getRepository(DataExportSettingsEntity);
        this.dataExportRepository.allItems$.subscribe(function (items) {
            if (!_this.authService.isGlobalAdmin) {
                _this.disableAddButton = true;
                _this.disabledAddTooltip = _this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.dataView.noPermissionsTooltip');
            }
            else if (items.length >= MAX_ITEMS) {
                _this.disableAddButton = true;
                _this.disabledAddTooltip = _this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.dataView.itemsLimit');
            }
            _this.capacityString = MAX_ITEMS - items.length + "/" + MAX_ITEMS;
        });
        this.fields = this.createFields();
    }
    DataExportDataviewComponent.prototype.showDataExportNewDialog = function () {
        var _this = this;
        this.dialogsService
            .showPanel(DataExportNewModalComponent, {
            id: 'new-data-export',
            type: PanelType.large,
            noBodyPadding: true,
            headerText: this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.addNew.title')
        })
            .subscribe(function (panel) {
            panel.instance.save.subscribe(function () {
                _this.lastUpdate = new Date();
            });
        });
    };
    DataExportDataviewComponent.prototype.createFields = function () {
        var _this = this;
        var columnNameI18Prefix = 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.';
        return [
            {
                id: 'id',
                getDisplay: function (item) { return item.id; },
            },
            {
                id: 'eventHubResourceGroup',
                getDisplay: function (item) {
                    return item.eventHubProperties == null ? NO_DATA : item.eventHubProperties.resourceGroup;
                },
            },
            {
                id: 'eventhub',
                getDisplay: function (item) {
                    return item.eventHubProperties == null
                        ? NO_DATA
                        : item.eventHubProperties.namespace + "/" + item.eventHubProperties.name;
                },
            },
            {
                id: 'storageResourceGroup',
                getDisplay: function (item) {
                    return item.storageAccountProperties == null
                        ? NO_DATA
                        : item.storageAccountProperties.resourceGroup;
                },
            },
            {
                id: 'storageAccount',
                getDisplay: function (item) {
                    return item.storageAccountProperties == null ? NO_DATA : item.storageAccountProperties.name;
                },
            },
            {
                id: 'eventTypes',
                maxWidth: 100,
                getDisplay: function (item) { return _this.getLogCategoriesString(item.logs); },
            },
        ].map(function (props) {
            return new DataviewField(__assign({}, props, { name: _this.i18nService.get(columnNameI18Prefix + props.id) }));
        });
    };
    DataExportDataviewComponent.prototype.getLogCategoriesString = function (logs) {
        if (logs.length === 1) {
            return this.i18nService.get("endpointManagement.nonWindows.applications.dataExport.logTypes." + logs[0].category);
        }
        var eventTypes = this.i18nService
            .get('endpointManagement.nonWindows.applications.dataExport.dataView.columns.eventTypes')
            .toLowerCase();
        return logs.filter(function (log) { return !!log.enabled; }).length + " " + eventTypes;
    };
    return DataExportDataviewComponent;
}());
export { DataExportDataviewComponent };
