import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityStatus } from '../airs_entity/airs-entity-status.entity';

@ValueObject({
	singularName: 'User',
	pluralName: 'Users',
	readonly: true,
})
export class InvestigatedUser extends ModelBase {
	@EntityField()
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal

	id: number;

	@EntityField({ data: 'user_object_id' })
	aadId: string;

	@EntityField({ data: 'full_name' })
	fullName: string;

	@EntityField()
	sid: string;

	@EntityField({ data: ['upn', 'Upn'] })
	upn: string;

	@EntityField({ data: 'entity_status' })
	status: AirsEntityStatus;
}
