import { Pipe, PipeTransform } from '@angular/core';
import { memoize } from 'lodash-es';
import { I18nService, Strings } from '@wcd/i18n';

@Pipe({
	name: 'displayTextPipe',
})
export class DisplayTextPipe implements PipeTransform {
	constructor(private readonly i18nService: I18nService) {
		this.transform = memoize(this.transform);
	}

	transform<T extends string | number>(value: T, displayKeysMap: Record<T, keyof Strings>): string | null {
		if (!(value in displayKeysMap)) {
			return null;
		}

		return this.i18nService.get(displayKeysMap[value]);
	}
}
