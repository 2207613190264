import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { IncidentLinkedByCategory } from './incident-linked-by-category.model';
import { IncidentLinkedByEntity } from './incident-linked-by-entity.value-object';

@ValueObject({
	singularName: 'Aggregated incident link reason',
	pluralName: 'Aggregated incident link reasons',
	readonly: true,
})
export class AggregatedIncidentLinkedBy extends ModelBase {
	@EntityField({ data: ['Category', 'category'], required: true })
	readonly category: IncidentLinkedByCategory;

	@EntityField({ data: ['SourceEntity', 'sourceEntity'] })
	readonly sourceEntity?: IncidentLinkedByEntity;

	@EntityField({ data: ['TargetEntities', 'targetEntities'], arrayOf: IncidentLinkedByEntity })
	readonly targetEntities?: Array<IncidentLinkedByEntity>;

	@EntityField({ data: ['LinkedAlertsCount', 'linkedAlertsCount'] })
	readonly linkedAlertsCount: number;
}
