import { SevilleDowngradeModule } from '../../../seville.downgrade';
import { SevilleUrlMappingModule } from '../../seville/services/seville.urlMapping';

declare var angular: angular.IAngularStatic;

export let SevilleAlertsModule = angular.module('seville.alerts', [
	SevilleDowngradeModule.name,
	'ui.router',
	'ngStorage',
	SevilleUrlMappingModule.name,
]);
