import { ValueObject, EntityField, EntityModelBase } from '@microsoft/paris';
import { TreeElementTypeEnum } from './tree-element-relation-type-enum';
import { TreeElementAlertContext } from './tree-element-alert-context';
import { GenericField } from '../../generic-field/generic-field.value-object';

@ValueObject({
	singularName: 'Tree element',
	pluralName: 'Tree elements',
})
export class TreeElement extends EntityModelBase {
	@EntityField()
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField()
	cyberEntityId: string;

	@EntityField()
	treeElementRelationType?: TreeElementTypeEnum;

	@EntityField()
	cyberEntityType?: string;

	@EntityField({ defaultValue: false })
	isMinimized: boolean;

	@EntityField({ defaultValue: false })
	isSubTreeMinimized: boolean;

	isRoot: boolean = false;

	@EntityField()
	depth?: number;

	@EntityField()
	hight?: number;

	@EntityField({ data: 'size' })
	subTreeSize?: number;

	@EntityField()
	icon: string;

	@EntityField()
	nonBoldTitlePrefix: string;

	@EntityField()
	boldTitle: string;

	@EntityField()
	nonBoldTitleSuffix: string;

	@EntityField()
	relationToPrecedingElement: string;

	@EntityField()
	time: Date;

	@EntityField({ data: 'typedDetails' })
	genericFields?: Array<GenericField>;

	/* generic fields created by the frontend */
	additionalGenericFields: Array<GenericField<any>> = new Array<GenericField<any>>();

	get allGenericFields(): Array<GenericField<any>> {
		return this.additionalGenericFields.length > 0
			? this.genericFields.concat(this.additionalGenericFields).filter((d) => !d.isHidden)
			: this.genericFields.filter((d) => !d.isHidden);
	}

	@EntityField()
	hiddenDetails: { [key: string]: string };

	@EntityField({ arrayOf: TreeElement })
	children: Array<TreeElement>;

	@EntityField()
	lastChild: TreeElement;

	@EntityField({ arrayOf: TreeElementAlertContext })
	associatedAlerts: Array<TreeElementAlertContext>;

	@EntityField({ arrayOf: TreeElement })
	associatedItems: Array<TreeElement>;

	@EntityField({ defaultValue: false })
	isAssociatedItem: boolean;

	cyberEntity: EntityModelBase<string | number>;

	// Ensure the sub trees components will be loaded only one time when first expanded.
	subTreeNeverLoaded: boolean = true;

	// Ensure the details and associated items components will be loaded only one time when first expanded.
	detailsNeverLoaded: boolean = true;
}
