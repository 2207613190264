import { HuntingQueryBase } from './hunting-query.entity.base';
import { Entity } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { QueryKind } from './hunting-query.type';

@Entity({
	singularName: 'Community query',
	pluralName: 'Community queries',
	endpoint: 'communityQueries',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
})
export class HuntingCommunityQuery extends HuntingQueryBase {
	readonly kind: QueryKind = 'community';
	readonly readonly = true;
	readonly shared = false;
	readonly isScheduled = false;
}
