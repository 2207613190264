import { Injectable } from '@angular/core';
import { EntityType, EntityDataViewOptions } from '../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import {
	Software,
	MdeUserRoleActionEnum,
	SoftwareInstallation,
	ResolveEntityURL,
	TvmEndPoint,
	FilterDiscoveredVulnerabilities,
	TvmSupportedOsPlatform,
} from '@wcd/domain';
import { SoftwareInventoryEntityPanelComponent } from '../components/software-inventory.entity-panel.component';
import { SoftwareEntityComponent } from '../components/software-entity/software.entity.component';
import { SoftwareEntityDetailsComponent } from '../../../../global_entities/components/entity-details/software.entity-details.component';
import { ItemActionModelConfig, ItemActionModel } from '../../../../dataviews/models/item-action.model';
import { FabricIconNames } from '@wcd/scc-common';
import { SidePanelService } from '../../common/side-panel.service';
import { Router } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { of } from 'rxjs';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { TvmReportInaccuracyService } from '../../../../tvm/services/tvm-report-inaccuracy.service';
import { InaccuracyContext } from '../../../../tvm/services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { SoftwareFieldsService } from './software.fields.service';
import { TvmDownloadService } from '../../../../tvm/services/tvm-download.service';
import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { EntityPageViewMode } from '../../../../global_entities/models/entity-page-view-mode.enum';
import { AppFlavorConfig } from '@wcd/scc-common';

export interface SoftwareDataViewFixedOptions {}

@Injectable()
export class SoftwareInventoryEntityTypeService implements EntityTypeService<Software> {
	constructor(
		private router: Router,
		private sidePanelService: SidePanelService,
		private authService: AuthService,
		private tvmTagsService: TvmTagsService,
		private reportInaccuracyService: TvmReportInaccuracyService,
		private i18nService: I18nService,
		private softwareFieldsService: SoftwareFieldsService,
		private tvmDownloadService: TvmDownloadService,
		private featuresService: FeaturesService,
		private appContextService: AppContextService
	) {}

	readonly entityType: EntityType<Software> = {
		id: 'software',
		entity: Software,
		entityPluralNameKey: 'software_entityType_pluralName',
		entitySingularNameKey: 'software_entityType_singularName',
		entityContentsComponentType: SoftwareEntityComponent,
		entityDetailsComponentType: SoftwareEntityDetailsComponent,
		searchOptions: {
			displayName: this.i18nService.strings.entity_type_display_name_software,
			getSearchParams: (searchTerm: string) => {
				const resultsUrl = this.appContextService.isSCC
					? `/searchResults/softwareInventory/${searchTerm}`
					: `/software-inventory`;
				return { url: resultsUrl, filter: searchTerm };
			},
			searchDropdownOrder: 1,
			requiredAllowedActions: MdeUserRoleActionEnum.tvmViewData,
			flavorConfig: AppFlavorConfig.search.tvmEntities,
		},
		singleEntityPanelComponentType: SoftwareInventoryEntityPanelComponent,
		entityPageViewMode: EntityPageViewMode.Asset,
		hideTagsInEntityComponent: true,
		getEntityName: (software: Software) => software.name,
		getEntityDataviewLink: () => '/software-inventory',
		loadFullEntityInPanel: false,
		dataViewOptions: <EntityDataViewOptions<Software, SoftwareDataViewFixedOptions>>{
			defaultSortFieldId: 'riskScore',
			fields: this.softwareFieldsService.fields,
			exportOptions: {
				showModalOnExport: false,
				exportResults: (options, format, searchTerm, dataQuery) => {
					const url = ResolveEntityURL({
						endPoint: TvmEndPoint.Analytics,
						entityModelBaseOrRelationship: Software,
					});

					return this.tvmDownloadService.downloadCsv({
						url: url,
						fileName: 'export-tvm-software-inventory',
						dataQuery: dataQuery,
					});
				},
			},
			dataViewConfig: {
				getFiltersData: () => {
					const filterMappings = {
						discoveredVulnerabilities: {
							values: Object.keys(FilterDiscoveredVulnerabilities),
						},
						threats: {
							values: this.featuresService.isEnabled(Feature.TvmExploits)
								? [1, 2, 3]
								: [true, false],
						},
						osPlatform: {
							values: Object.keys(TvmSupportedOsPlatform).map(plat => {
								return {
									value: plat,
									name: this.i18nService.get(
										`tvm_common_osPlatform_${TvmSupportedOsPlatform[plat]}`
									),
								};
							}),
						},
						filterTags: {
							values: this.tvmTagsService.getSoftwareTagsForFiltering(),
						},
					};

					return of(filterMappings);
				},
			},
		},
		getActions: (software: Array<Software>) => {
			if (
				(software[0].id && !software[0].isNormalized) ||
				this.router.url.includes('-_-') ||
				!this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.tvmViewData)
			)
				return [];
			if ((software[0].id && software[0].name) || software[0] instanceof SoftwareInstallation) {
				const actions: Array<ItemActionModelConfig> = [
					{
						id: 'navigateToSoftware',
						nameKey: 'tvm.securityRecommendation.openSoftwarePage',
						icon: FabricIconNames.AppIconDefault,
						closeOnAction: methodResolution => methodResolution,
						method: (software: Array<SoftwareInstallation>) => {
							if (software[0] instanceof SoftwareInstallation) {
								const softwareId = software[0].getProductId();
								const url = `/software-inventory/${encodeURIComponent(softwareId)}/overview`;
								return this.router.navigate([url]);
							} else {
								const url = `/software-inventory/${encodeURIComponent(
									software[0].id
								)}/overview`;
								return this.router.navigate([url]);
							}
						},
					},
					{
						id: 'openReportInaccuracy',
						nameKey: 'tvm.reportInaccuracy.reportButton',
						icon: FabricIconNames.Feedback,
						closeOnAction: methodResolution => methodResolution,
						method: (installations: Array<SoftwareInstallation>) => {
							// For org level inventory the Software is used (for machine's inventory - SoftwareInstallation)
							const isOrgLevel = installations[0] instanceof Software;
							return this.reportInaccuracyService.openReportInaccuracyPanel(
								isOrgLevel
									? InaccuracyContext.OrgInventory
									: InaccuracyContext.MachineInventory,
								installations[0]
							);
						},
					},
				];

				return actions.map(itemActionConfig => new ItemActionModel(itemActionConfig));
			} else {
				return [];
			}
		},
		getTags: (software: Software[]) =>
			software[0] instanceof SoftwareInstallation
				? this.tvmTagsService.getSoftwareInstallationTags(
						(software[0] as unknown) as SoftwareInstallation
				  )
				: this.tvmTagsService.getSoftwareTags(software[0]),
	};
}
