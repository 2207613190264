import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { I18nService } from '@wcd/i18n';
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="wcd-flex-vertical wcd-full-height">
			<ng-container *ngIf="(data$ | async) as data">
				<div class="wcd-flex-1 ie11Patch ie11Flex">
					<h4 class="wcd-margin-bottom">{{ 'alert.report.widgets.alertStatus.title' | i18n }}</h4>
					<div class="wcd-flex-horizontal wcd-margin-small-bottom">
						<span class="wcd-font-weight-bold wcd-flex-0 wcd-margin-small-right">
							{{ 'alert.report.widgets.alertStatus.stateTitle' | i18n }}:
						</span>
						{{ data?.status?.name }}
					</div>
					<div class="wcd-flex-horizontal wcd-margin-small-bottom">
						<span class="wcd-font-weight-bold wcd-flex-0 wcd-margin-small-right">
							{{ 'alert.report.widgets.alertStatus.ClassificationTitle' | i18n }}:
						</span>
						{{ data?.classification?.name || ('alert.report.widgets.alertStatus.notSet' | i18n) }}
					</div>
					<div class="wcd-flex-horizontal wcd-margin-small-bottom">
						<span class="wcd-font-weight-bold wcd-flex-0 wcd-margin-small-right">
							{{ 'alert.report.widgets.alertStatus.assignToTitle' | i18n }}:
						</span>
						{{ data?.assignedTo || ('alert.report.widgets.alertStatus.notAssigned' | i18n) }}
					</div>
				</div>
			</ng-container>
		</div>
	`,
})
export class AlertStatusWidgetComponent extends ReportWidgetComponent<any> {
	constructor(
		private readonly activatedEntity: ActivatedEntity,
		private readonly i18nService: I18nService,
		reportsService: ReportsService
	) {
		super(reportsService);
	}
	get widgetConfig(): any {
		return {
			id: 'alert-status-widget',
			name: this.i18nService.get('alert.report.widgets.alertStatus.title'),
			loadData: () => {
				return this.activatedEntity.currentEntity$;
			},
		};
	}
}
