var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RecommendationFiltersApiCall, RemediationType, AssetRecommendation, RecommendationCategory, MdeUserRoleActionEnum, } from '@wcd/domain';
import { DataviewField } from '@wcd/dataview';
import { TvmImpactScoreComponent } from '../../../../tvm/components/tvm-impact-score/tvm-impact-score.component';
import { TvmThreatIconsComponent } from '../../../../tvm/components/tvm-threat-icons/tvm-threat-icons.component';
import { TextToken } from '../../../../tvm/services/tvm-texts.service';
import { FabricIconNames } from '@wcd/scc-common';
import { Feature } from '@wcd/config';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { RelatedComponentEnum } from '../components/filter/related-component.enum';
import { map } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { TvmAssetsStatisticsComponent } from '../../../../tvm/components/tvm-assets-statistics/tvm-assets-statistics.component';
import { TaggedFieldComponent } from '../../../../tvm/components/tagged-field/tagged-field.component';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { NetworkDeviceFieldComponent } from '../../../../global_entities/components/entity-details/network-device-field/network-device-field.component';
import { TvmProductivityImpactComponent } from '../../../../global_entities/components/entity-details/productivity-impact.component';
var STATUS_FILTER_PRIORITY = 1;
var REMEDIATION_FILTER_PRIORITY = 2;
var RELATED_COMPONENT_FILTER_PRIORITY = 3;
var TAGS_FILTER_PRIORITY = 4;
var SecurityRecommendationFieldsServiceBase = /** @class */ (function () {
    function SecurityRecommendationFieldsServiceBase(tvmTaggedFieldService, tzDateService, i18nService, tvmTextsService, featuresService, paris, tvmIconBuilderService, reportInaccuracyService, tvmTagsService, authService, productivityImpactService, prettyNumberService, securityRecommendationService, appConfigService) {
        var _this = this;
        this.tvmTaggedFieldService = tvmTaggedFieldService;
        this.tzDateService = tzDateService;
        this.i18nService = i18nService;
        this.tvmTextsService = tvmTextsService;
        this.featuresService = featuresService;
        this.tvmIconBuilderService = tvmIconBuilderService;
        this.reportInaccuracyService = reportInaccuracyService;
        this.tvmTagsService = tvmTagsService;
        this.authService = authService;
        this.productivityImpactService = productivityImpactService;
        this.prettyNumberService = prettyNumberService;
        this.securityRecommendationService = securityRecommendationService;
        this._singleSoftwareRecommendation = false;
        this._singleAssetRecommendation = false;
        this._isExceptionsPerRbacFeatureEnabled = false;
        this._orgHasMachineGroups = false;
        this._relatedComponentFilter$ = new ReplaySubject();
        this.exposedAssetsFieldsConfig = [
            {
                id: 'assetName',
                name: this.i18nService.get('tvm.common.assetNameTitle'),
                sort: { enabled: false },
                getDisplay: function (asset) { return asset.name; },
                getLink: function (asset) {
                    return _this.featuresService.isEnabled(Feature.UpgradeMachinePage)
                        ? "/machines/" + asset.id
                        : "/machine/" + asset.id;
                },
                icon: {
                    fabricIcon: FabricIconNames.System,
                },
                className: 'nowrap wcd-text-overflow-medium',
            },
            this.featuresService.isEnabled(Feature.TvmExposedOperatingSystems)
                ? {
                    id: 'osName',
                    name: this.i18nService.get('os.operatingSystem'),
                    sort: { enabled: false },
                    getDisplay: function (asset) { return _this.tvmTextsService.getOsPlatformLabel(asset.osName); },
                    className: 'nowrap wcd-text-overflow-medium',
                }
                : null,
        ].filter(Boolean);
        this._isExceptionsPerRbacFeatureEnabled = featuresService.isEnabled(Feature.TvmExceptionsPerRbac);
        this._orgHasMachineGroups = appConfigService.hasMachineGroups;
        paris
            .apiCall(RecommendationFiltersApiCall, {}) //TODO: bad practice ahead - the empty params is due to the fact that Paris fires the "parseQuery" CB only if arg is provided
            .pipe(map(function (relatedComponents) {
            return Object.keys(RelatedComponentEnum)
                .filter(function (k) { return k !== 'Other'; }) //TODO: Requested to remove the 'Other' filter. leaving rest of logic as is for now, for the chance of bringing it back soon.
                .map(function (k) {
                var filteredByCategory = relatedComponents.filter(function (x) { return x.category === RelatedComponentEnum[k]; });
                var children = filteredByCategory.length == 0 ? [] : filteredByCategory[0].subCategories;
                return {
                    key: k,
                    value: children,
                };
            })
                .reduce(function (res, obj) {
                res[obj.key] = obj.value;
                return res;
            }, {});
        }))
            .subscribe(function (filters) { return _this._relatedComponentFilter$.next(filters); });
    }
    Object.defineProperty(SecurityRecommendationFieldsServiceBase.prototype, "singleAssetRecommendation", {
        set: function (val) {
            this._singleAssetRecommendation = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationFieldsServiceBase.prototype, "singleSoftwareRecommendation", {
        set: function (val) {
            this._singleSoftwareRecommendation = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationFieldsServiceBase.prototype, "fields", {
        get: function () {
            var _this = this;
            /*
            //TODO: consider:
            Ben Grynhaus
                FYI there's a Lazy<T> class I wrote, partially due to this, which also disallows the use of readonly on the _fields (even though it's only written once).
                Not everyone likes it, but at least I feel it makes the code more readable (& safe), so no obligation to use it, but up to you.
                It does exactly what the C# equivalent does.
            */
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'title',
                        name: this.i18nService.get('tvm.common.securityRecommendation'),
                        sort: { enabled: false },
                        component: {
                            type: TaggedFieldComponent,
                            getProps: function (recommendation) {
                                return _this.getTitleFieldsParams(recommendation);
                            },
                        },
                    },
                    !this._singleAssetRecommendation
                        ? {
                            id: 'osPlatform',
                            name: this.i18nService.get('tvm_common_osPlatform'),
                            sort: { enabled: false },
                            getDisplay: function (securityRecommendation) {
                                return securityRecommendation.osPlatform
                                    ? _this.i18nService.get("tvm_common_osPlatform_" + securityRecommendation.osPlatform)
                                    : _this.i18nService.strings.notAvailable_short;
                            },
                        }
                        : null,
                    {
                        id: 'weaknessesCount',
                        name: this.i18nService.get('tvm.common.weaknesses'),
                    },
                    {
                        id: 'relatedComponent',
                        name: this.i18nService.get('tvm.common.relatedComponent'),
                        filterOnly: this._singleSoftwareRecommendation,
                        sort: { enabled: false },
                        getDisplay: function (securityRecommendation) {
                            return _this.tvmTextsService.getText(TextToken.SecurityRecommendationRelatedComponent, securityRecommendation);
                        },
                        filterName: this.i18nService.strings.tvm_common_relatedComponent_filterTitle,
                        filter: {
                            priority: RELATED_COMPONENT_FILTER_PRIORITY,
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    allowSingleValueDeselection: true,
                                    mapFilterValue: function (component) {
                                        if (component == RecommendationCategory.NetworkGear ||
                                            RelatedComponentEnum[component]) {
                                            return _this.getComponentMapFilterValues(component);
                                        }
                                    },
                                },
                            },
                        },
                    },
                    {
                        id: 'threats',
                        name: this.i18nService.get('tvm.common.threats'),
                        sort: { enabled: false },
                        component: {
                            type: TvmThreatIconsComponent,
                            getProps: function (securityRecommendation) {
                                var insightTooltip = '';
                                if (securityRecommendation.remediationType === RemediationType.ConfigurationChange) {
                                    var properlyConfiguredInsight = _this.tvmTextsService.getText(TextToken.SecurityRecommendationSCAProperlyConfiguredInsight, securityRecommendation);
                                    var benchmarksInsight = _this.tvmTextsService.getText(TextToken.SecurityRecommendationSCARecommendedBenchmarksInsight, securityRecommendation);
                                    insightTooltip =
                                        securityRecommendation instanceof AssetRecommendation
                                            ? _this.createInsights(null, benchmarksInsight)
                                            : _this.createInsights(properlyConfiguredInsight, benchmarksInsight);
                                }
                                return _this.tvmIconBuilderService.configureIcons(securityRecommendation.threatInfo, securityRecommendation.threats, 'securityRecommendation', true, insightTooltip, true);
                            },
                        },
                    },
                    {
                        id: 'exposedDevicesWithTrends',
                        filterOnly: this._singleAssetRecommendation,
                        name: this.i18nService.get('tvm.common.exposedDevices'),
                        sort: { enabled: false },
                        component: {
                            type: TvmAssetsStatisticsComponent,
                            getProps: function (securityRecommendation) { return ({
                                totalAssetCount: securityRecommendation.assetsStatistics.totalAssetCount,
                                assetsCount: securityRecommendation.assetsStatistics.assetsAtRiskCount,
                                assetsCountHistory: securityRecommendation.assetsStatistics.assetsAtRiskHistory,
                            }); },
                        },
                        allowResize: false,
                        minWidth: 200,
                    },
                    {
                        id: 'status',
                        name: this.i18nService.get('status'),
                        filter: { priority: STATUS_FILTER_PRIORITY },
                        enabledByDefault: true,
                        getDisplay: function (recommendation) {
                            return _this.securityRecommendationService.getRecommendationStatus(recommendation.status);
                        },
                    },
                    {
                        id: 'remediationType',
                        name: this.i18nService.get('tvm.common.remediationType'),
                        filterOnly: this._singleSoftwareRecommendation,
                        filter: { priority: REMEDIATION_FILTER_PRIORITY },
                        getDisplay: function (securityRecommendation) {
                            return _this.i18nService.get("tvm.remediationTask.type." + securityRecommendation.remediationType);
                        },
                    },
                    {
                        id: 'remediationTasks',
                        name: this.i18nService.get('tvm.common.remediationActivities'),
                        sort: { enabled: false },
                        getDisplay: function (securityRecommendation) {
                            if (!securityRecommendation.remediationTasks) {
                                return _this.i18nService.get('notAvailable.short');
                            }
                            var count = securityRecommendation.remediationTasks.length;
                            return count;
                        },
                        getTooltip: function (securityRecommendation) {
                            var statuses = securityRecommendation.remediationTasks;
                            if (statuses.length != 0) {
                                var list = '<ul>';
                                list += statuses.reduce(function (acc, curr) {
                                    return acc + "<li>" + _this.tvmTextsService.getText(TextToken.RemediationTicketStatus, curr) + ", " + _this.i18nService
                                        .get('tvm.securityRecommendation.relatedRemediation.createdOn')
                                        .toLocaleLowerCase() + ": " + _this.tzDateService.format(curr.createdOn, 'shortDate') + "</li>";
                                }, '');
                                list += '</ul>';
                                return list;
                            }
                            return '';
                        },
                        valueTooltipAllowHtmlRendering: true,
                    },
                    {
                        id: 'impactInfo',
                        filterOnly: this._singleAssetRecommendation,
                        name: this.i18nService.get('tvm.common.impact'),
                        description: this.tvmTextsService.getText(TextToken.ScoreImpactInfo),
                        component: {
                            type: TvmImpactScoreComponent,
                            getProps: function (securityRecommendation) { return ({
                                scoreData: {
                                    exposureScore: securityRecommendation.exposureScoreImprovement,
                                    secureScore: securityRecommendation.secureScoreImprovement,
                                    exposureScoreTooltip: _this.tvmTextsService.getText(TextToken.ExposureScoreTooltip),
                                    secureScoreTooltip: _this.tvmTextsService.getText(TextToken.ConfigurationScoreTooltip),
                                },
                            }); },
                        },
                        descriptionTooltipAllowHtmlRendering: true,
                    },
                    this._isExceptionsPerRbacFeatureEnabled && this._orgHasMachineGroups
                        ? {
                            id: 'exposedDevicesAfterException',
                            name: this.i18nService.strings
                                .tvm_securityRecommendation_exposedDevicesAfterException,
                            description: this.i18nService.strings
                                .tvm_securityRecommendation_exposedDevicesAfterException_tooltip,
                            enabledByDefault: false,
                            getDisplay: function (securityRecommendation) {
                                return _this.prettyNumberService.prettyNumber(securityRecommendation.assetsStatistics
                                    .assetsAtRiskCountAfterApplyingExceptions);
                            },
                        }
                        : null,
                    this._isExceptionsPerRbacFeatureEnabled
                        ? {
                            id: 'impactInfoAfterException',
                            name: this.i18nService.strings
                                .tvm_securityRecommendation_impactInfoAfterException,
                            description: this.i18nService.strings
                                .tvm_securityRecommendation_impactInfoAfterException_tooltip,
                            sort: { enabled: false },
                            enabledByDefault: false,
                            component: {
                                type: TvmImpactScoreComponent,
                                getProps: function (securityRecommendation) { return ({
                                    scoreData: {
                                        exposureScore: securityRecommendation.exposureScoreImprovementAfterApplyingExceptions,
                                        secureScore: securityRecommendation.secureScoreImprovementAfterApplyingExceptions,
                                        exposureScoreTooltip: _this.tvmTextsService.getText(TextToken.ExposureScoreTooltip),
                                        secureScoreTooltip: _this.tvmTextsService.getText(TextToken.ConfigurationScoreTooltip),
                                    },
                                }); },
                            },
                        }
                        : null,
                    {
                        id: 'recommendationTags',
                        name: this.i18nService.get('common.tags'),
                        component: {
                            type: TagsFieldComponent,
                            getProps: function (securityRecommendation) { return ({
                                tags: _this.tvmTagsService.getRecommendationTags(securityRecommendation),
                            }); },
                        },
                        sort: { enabled: false },
                        filter: {
                            priority: TAGS_FILTER_PRIORITY,
                        },
                    },
                ]).filter(Boolean);
                // recommendations for one asset presented for all user
                if (!this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.tvmViewData)) {
                    // remove the remediation activities column
                    this._fields.splice(7, 1);
                }
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationFieldsServiceBase.prototype, "exposedAssetsFields", {
        get: function () {
            if (this._exposedAssetsFields) {
                return this._exposedAssetsFields;
            }
            this._exposedAssetsFields = DataviewField.fromList(this.exposedAssetsFieldsConfig);
            return this._exposedAssetsFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationFieldsServiceBase.prototype, "exposedAssetsUserImpactFields", {
        get: function () {
            if (this._exposedAssetsUserImpactFields) {
                return this._exposedAssetsUserImpactFields;
            }
            this._exposedAssetsUserImpactFields = DataviewField.fromList(this.exposedAssetsFieldsConfig.concat([
                {
                    id: 'productivityImpactField',
                    name: this.i18nService.get('tvm.securityRecommendation.userImpact.productivityImpactFieldName'),
                    sort: { enabled: false },
                    component: {
                        type: TvmProductivityImpactComponent,
                        getProps: function (asset) { return ({
                            isUserProductivityImpacted: asset.isUserProductivityImpacted,
                        }); },
                    },
                },
            ]));
            return this._exposedAssetsUserImpactFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationFieldsServiceBase.prototype, "exposedDevicesFields", {
        get: function () {
            var _this = this;
            if (this._exposedDevicesFields) {
                return this._exposedDevicesFields;
            }
            this._exposedDevicesFields = DataviewField.fromList([
                {
                    id: 'assetName',
                    name: this.i18nService.get('tvm.common.assetNameTitle'),
                    sort: { enabled: false },
                    //Temp solution until INE fix the bug in fabric font
                    component: {
                        type: NetworkDeviceFieldComponent,
                        getProps: function (asset) {
                            return { title: asset.name };
                        },
                    },
                },
                {
                    id: 'lastSeen',
                    name: this.i18nService.get('tvm.networkGear.networkDeviceReachableTitle'),
                    sort: { enabled: false },
                    getDisplay: function (asset) {
                        return new Date(asset.lastSeen).getTime() > new Date().getTime() - 60 * 60 * 24 * 1000
                            ? _this.i18nService.get('common.yes')
                            : _this.i18nService.get('common.no');
                    },
                    className: 'nowrap wcd-text-overflow-medium',
                },
            ]);
            return this._exposedDevicesFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationFieldsServiceBase.prototype, "installedAssetsFields", {
        get: function () {
            var _this = this;
            if (this._installedAssetsFields) {
                return this._installedAssetsFields;
            }
            this._installedAssetsFields = DataviewField.fromList([
                {
                    id: 'assetName',
                    name: this.i18nService.get('tvm.common.assetNameTitle'),
                    sort: { enabled: false },
                    getDisplay: function (softwareInstallation) {
                        return softwareInstallation.assetName;
                    },
                    getLink: function (softwareInstallation) {
                        return _this.featuresService.isEnabled(Feature.UpgradeMachinePage)
                            ? "/machines/" + softwareInstallation.assetId
                            : "/machine/" + softwareInstallation.assetId;
                    },
                    icon: {
                        fabricIcon: FabricIconNames.System,
                    },
                    className: 'nowrap wcd-text-overflow-medium',
                },
                this.featuresService.isEnabled(Feature.TvmExposedOperatingSystems)
                    ? {
                        id: 'osName',
                        name: this.i18nService.get('os.operatingSystem'),
                        sort: { enabled: false },
                        getDisplay: function (softwareInstallation) {
                            return _this.tvmTextsService.getOsPlatformLabel(softwareInstallation.osName);
                        },
                        className: 'nowrap wcd-text-overflow-medium',
                    }
                    : null,
            ].filter(Boolean));
            return this._installedAssetsFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationFieldsServiceBase.prototype, "installedDevicesFields", {
        get: function () {
            if (this._installedDevicesFields) {
                return this._installedDevicesFields;
            }
            this._installedDevicesFields = DataviewField.fromList([
                {
                    id: 'assetName',
                    name: this.i18nService.get('tvm.common.assetNameTitle'),
                    sort: { enabled: false },
                    //Temp solution until INE fix the bug in fabric font
                    component: {
                        type: NetworkDeviceFieldComponent,
                        getProps: function (softwareInstallation) {
                            return { title: softwareInstallation.assetName };
                        },
                    },
                },
            ]);
            return this._installedDevicesFields;
        },
        enumerable: true,
        configurable: true
    });
    SecurityRecommendationFieldsServiceBase.prototype.createInsights = function (properlyConfigured, supportedBenchmarks) {
        if (!properlyConfigured && !supportedBenchmarks) {
            return '';
        }
        var insights = "<h3 class=\"wcd-margin-vertical\">" + this.i18nService.get('tvm.common.recommendationsInsights') + "</h3>\n\t\t\t\t\t\t<ul>";
        if (properlyConfigured) {
            insights += "<li>" + properlyConfigured + "</li>";
        }
        if (supportedBenchmarks) {
            insights += "<li>" + supportedBenchmarks + "</li>";
        }
        insights += '</ul>';
        return insights;
    };
    SecurityRecommendationFieldsServiceBase.prototype.getComponentMapFilterValues = function (component) {
        var _this = this;
        return this._relatedComponentFilter$.pipe(map(function (relatedComponentsChildren) { return ({
            id: component === 'Other'
                ? "productName:eq:'" + component + "'"
                : "recommendationCategory:eq:'" + (component == RecommendationCategory.NetworkGear
                    ? component
                    : RelatedComponentEnum[component]) + "'",
            name: component === 'OS'
                ? _this.i18nService.get('tvm.common.operatingSystem')
                : RelatedComponentEnum[component],
            priority: component == 'Other' ? 0 : 1,
            children: component === 'OS'
                ? _this.getOsFilterChildren(relatedComponentsChildren[component])
                : relatedComponentsChildren[component] &&
                    relatedComponentsChildren[component].map(function (child) { return ({
                        id: component === 'Other'
                            ? "productName:eq:'" + child + "'"
                            : "(recommendationCategory:eq:'" + RelatedComponentEnum[component] + "':and:subCategory:eq:'" + child + "')",
                        name: _this.tvmTextsService.getText(TextToken.EntityName, child),
                    }); }),
        }); }));
    };
    // An ad hoc fix for now for inability to filter on general OS recommendation
    // TODO: replace with actual filtering in the BE, VSTS bug - 24347723
    SecurityRecommendationFieldsServiceBase.prototype.getOsFilterChildren = function (children) {
        var _this = this;
        var osChildren = [{ id: "recommendationCategory:eq:'OS':or:subCategory:eq:'any'", name: 'Any' }];
        if (Array.isArray(children)) {
            children.forEach(function (child) {
                return osChildren.push({
                    id: "(recommendationCategory:eq:'OS':and:subCategory:eq:'" + child + "')",
                    name: _this.tvmTextsService.getText(TextToken.EntityName, child),
                });
            });
        }
        return osChildren;
    };
    SecurityRecommendationFieldsServiceBase.prototype.getTitleFieldsParams = function (recommendation) {
        var baseProps = recommendation.remediationType === RemediationType.ConfigurationChange
            ? this.getBasePropsProductivityAssessment(recommendation)
            : this.getZeroDayBaseProps(recommendation);
        // To show report inaccuracy only for a single asset security recommendations
        if (this._singleAssetRecommendation) {
            var inaccuracyContext = {
                inaccuracyContext: this.reportInaccuracyService.evaluateContext(true, recommendation),
                contextObject: recommendation,
            };
            baseProps = __assign({}, baseProps, inaccuracyContext);
        }
        return baseProps;
    };
    SecurityRecommendationFieldsServiceBase.prototype.getBasePropsProductivityAssessment = function (recommendation) {
        var statistics = recommendation.assetsStatistics;
        var assetsAtRisk = statistics.assetsAtRiskCount;
        var nonImpactedAssets = statistics.nonProductivityImpactedAssets;
        var isSingleAsset = statistics.totalAssetCount === 0 && assetsAtRisk === 1; // A check for single asset recommendation
        var includeImpactTitleInsights = this.productivityImpactService.isDataviewTitleInsightsEnabled &&
            (isSingleAsset
                ? recommendation.isProductivityImpacted === false // For now only showing the green icon for safe assets
                : this.productivityImpactService.isRecommendationContainsImpactAssessment(recommendation));
        var percentage = assetsAtRisk > 0 && nonImpactedAssets <= assetsAtRisk
            ? (nonImpactedAssets / assetsAtRisk) * 100
            : 0; // setting to 0 - to better show red color (if shown) instead of crushing
        var iconColoring = isSingleAsset
            ? this.productivityImpactService.getAssetSafeRecommendationColoring()
            : this.productivityImpactService.getOrgSafeRecommendationColoring(percentage);
        var productivityAssessmentTooltip = includeImpactTitleInsights
            ? isSingleAsset
                ? this.tvmTextsService.getText(TextToken.AssetRecommendationProductivityImpactTooltip)
                : this.tvmTextsService.getText(TextToken.OrgRecommendationProductivityImpactTooltip, statistics)
            : undefined;
        return {
            title: this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, recommendation),
            showTag: false,
            tagAfterText: false,
            productivityAssessmentInfo: {
                isProductivityAssessmentIncluded: includeImpactTitleInsights,
                isSingleAsset: isSingleAsset,
                productivityAssessmentTooltip: productivityAssessmentTooltip,
                iconColoring: iconColoring,
            },
        };
    };
    SecurityRecommendationFieldsServiceBase.prototype.getZeroDayBaseProps = function (recommendation) {
        return this.tvmTaggedFieldService.getZeroDayBaseProps(recommendation.mostSevereVulnerabilityType, recommendation.patchReleaseDate, this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, recommendation), true);
    };
    return SecurityRecommendationFieldsServiceBase;
}());
export { SecurityRecommendationFieldsServiceBase };
