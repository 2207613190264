import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { FlavorService, TvmLicensesAngularService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';

@Injectable()
export class TvmLicenseNoMdeGuard implements CanActivate {
	constructor(private flavorService: FlavorService, private tvmLicensesService: TvmLicensesAngularService) {}

	canActivate(route: ActivatedRouteSnapshot) {
		const data = route.routeConfig.data;
		return data && this.tvmLicensesService.isEnabled(data.tvmLicenseType) && !this.flavorService.isEnabled(AppFlavorConfig.settings.mdeWithoutWorkloads);
	}
}
