var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
var ɵ0 = function (config, query) {
    return query && query.where && query.where['EnableNewMailItemAPI']
        ? 'MailItem/GetMessageHeaders'
        : 'MailItem/GetMailItemHeaders';
}, ɵ1 = function (params) {
    return {
        data: {
            internetMessageId: params.internetMessageId,
            mailboxId: params.mailboxId,
        },
        params: {
            EnableNewMailItemAPI: params.EnableNewMailItemAPI,
        },
    };
}, ɵ2 = function (data) {
    return (data &&
        data.InternetMessageHeaders &&
        data.InternetMessageHeaders.map(function (h) { return h.Name + ": " + h.Value; }));
};
var EmailHeadersApiCall = /** @class */ (function (_super) {
    __extends(EmailHeadersApiCall, _super);
    function EmailHeadersApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EmailHeadersApiCall = __decorate([
        ApiCall({
            name: 'Email headers',
            endpoint: ɵ0,
            baseUrl: '<dgs>',
            method: 'POST',
            parseQuery: ɵ1,
            parse: ɵ2,
            cache: {
                time: 1000 * 60 * 10,
            },
        })
    ], EmailHeadersApiCall);
    return EmailHeadersApiCall;
}(ApiCallModel));
export { EmailHeadersApiCall };
export { ɵ0, ɵ1, ɵ2 };
