import { ComponentRef, Injectable } from '@angular/core';
import { DataEntityType } from '@microsoft/paris';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { AlertStatus, DomainCategory, Severity } from '@wcd/domain';
import { Observable, of } from 'rxjs';
import { DomainExposedMachinesFullComponent } from '../components/domain-exposed-machines-full.dataview.component';
import { PanelType } from '@wcd/panels';
import { DomainUrlsFullComponent } from '../components/domain-urls-full.dataview.component';
import { HttpClient } from '@angular/common/http';
import { CsvService } from '../../../shared/services/csv.service';
import { DownloadService } from '../../../shared/services/download.service';
import { ServiceUrlsService } from '@wcd/app-config';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { DatasetBackendOptions } from '@wcd/dataview';

const EXPORT_TO_CSV_TOKEN_URL = 'domains/ExportDomainsList';
const EXPORT_TO_CSV_URL = 'domains/DownloadDomainsList';

@Injectable()
export class DomainsService extends StoreBackendBase {
	get apiUrl(): string {
		return this.serviceUrlsService.webThreatProtection;
	}

	constructor(
		private dialogsService: DialogsService,
		http: HttpClient,
		downloadService: DownloadService,
		private readonly csvService: CsvService,
		private serviceUrlsService: ServiceUrlsService,
		private i18nService: I18nService
	) {
		super(http, downloadService);
	}

	getDomainsFilters(): Observable<Record<string, any>> {
		const filters$: Observable<Record<string, any>> = of({
			severity: {
				count: null,
				values: (<DataEntityType>Severity).entityConfig.values
					.filter((severity: Severity) => severity.isSelectable)
					.map((severity: Severity) => {
						return {
							value: severity.id,
							name: this.i18nService.get(severity.nameI18nKey),
							count: null,
							priority: severity.priority,
						};
					}),
			},
			webThreatCategory: {
				count: null,
				values: (<DataEntityType>DomainCategory).entityConfig.values.map(
					(category: DomainCategory) => {
						return {
							value: category.type,
							name: category.name,
							count: null,
							priority: category.priority,
						};
					}
				),
			},
			status: {
				count: null,
				values: (<DataEntityType>AlertStatus).entityConfig.values
					.filter((status: AlertStatus) => status.isSelectable)
					.map((status: AlertStatus) => {
						return {
							value: status.id,
							name: this.i18nService.get(status.nameI18nKey),
							count: null,
							priority: status.priority,
						};
					}),
			},
		});

		return filters$;
	}

	showAllExposedMachines(
		domainIdSelected: string
	): Observable<ComponentRef<DomainExposedMachinesFullComponent>> {
		return this.dialogsService.showPanel(
			DomainExposedMachinesFullComponent,
			{
				id: 'exposed-machines-panel',
				headerText: this.i18nService.get('reporting.webThreatReport.domainList.exposedMachinesTitle'),
				type: PanelType.largeFixed,
				isModal: true,
				scrollBody: true,
				noShadow: true,
				width: 500,
				persistOnNavigate: false,
				noBodyPadding: true,
			},
			{
				domainIdSelected: domainIdSelected,
			}
		);
	}

	showAllDomains(domainIdSelected: string): Observable<ComponentRef<DomainUrlsFullComponent>> {
		return this.dialogsService.showPanel(
			DomainUrlsFullComponent,
			{
				id: 'domain-urls-panel',
				headerText: this.i18nService.get('reporting.webThreatReport.domainList.urlTitle'),
				type: PanelType.largeFixed,
				isModal: true,
				scrollBody: true,
				noShadow: true,
				width: 500,
				persistOnNavigate: false,
				noBodyPadding: true,
			},
			{
				domainIdSelected: domainIdSelected,
			}
		);
	}

	downloadCsv(options?: DatasetBackendOptions) {
		return this.csvService.downloadCsv({
			tokenUrl: `${this.apiUrl}/${EXPORT_TO_CSV_TOKEN_URL}`,
			apiUrl: `${this.apiUrl}/${EXPORT_TO_CSV_URL}`,
			params: options,
		});
	}
}
