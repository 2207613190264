var _a;
import { AfterViewInit, ChangeDetectorRef, OnChanges, SimpleChanges, } from '@angular/core';
import { StepStatus } from '../../models/wizard.model';
import { debounce } from 'lodash-es';
import { config } from '@wcd/shared';
var VerticalStepsComponent = /** @class */ (function () {
    function VerticalStepsComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.isPanel = false;
        this.StepStatus = StepStatus;
        this.isNarrowLayout = false;
        this.stepsDropdownOpen = false;
        this._onResizeDebounced = debounce(this.onResize, 1000);
    }
    VerticalStepsComponent.prototype.windowResize = function () {
        this._onResizeDebounced();
    };
    VerticalStepsComponent.prototype.onResize = function () {
        var screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        var isNarrowLayoutAfterResize = config.msScreenSizeBreakpoints.medium >= screenWidth;
        if (isNarrowLayoutAfterResize !== this.isNarrowLayout) {
            this.isNarrowLayout = isNarrowLayoutAfterResize;
            this.changeDetectorRef.detectChanges();
        }
    };
    ;
    VerticalStepsComponent.prototype.ngAfterViewInit = function () {
        this.onResize();
    };
    VerticalStepsComponent.prototype.ngOnChanges = function (changes) {
        this.currentStep = this.steps.find(function (step) { return step.status === StepStatus.current; });
        this.changeDetectorRef.detectChanges();
    };
    VerticalStepsComponent.prototype.trackByFunction = function (index) {
        return index;
    };
    VerticalStepsComponent.prototype.toggleStepsDropdown = function () {
        this.stepsDropdownOpen = !this.stepsDropdownOpen;
    };
    VerticalStepsComponent.prototype.getStepAriaLabelKey = function (stepStatus) {
        return 'wizard_stepStatusAria_' + stepStatus;
    };
    VerticalStepsComponent.prototype.getIconForStatus = function (status) {
        return statusToIcon[status];
    };
    return VerticalStepsComponent;
}());
export { VerticalStepsComponent };
var statusToIcon = (_a = {},
    _a[StepStatus.completed] = 'CompletedSolid',
    _a[StepStatus.current] = 'CircleFill',
    _a[StepStatus.next] = 'CircleRing',
    _a);
