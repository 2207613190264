import { LegacyUser } from '@wcd/domain';
import { DataviewField } from '@wcd/dataview';
import { Lazy } from '@wcd/utils';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { PrettyNumberService } from '@wcd/prettify';
import { I18nService } from '@wcd/i18n';
var LegacyUsersFieldsService = /** @class */ (function () {
    function LegacyUsersFieldsService(prettyNumberService, globalEntityTypesService, i18nService) {
        var _this = this;
        this.prettyNumberService = prettyNumberService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.i18nService = i18nService;
        this._fields = new Lazy(function () {
            var fields = [
                {
                    id: 'user',
                    name: _this.i18nService.get('users_fields_user'),
                    className: 'nowrap wcd-text-overflow-large',
                    getDisplay: function (user) { return user.fullName; },
                    icon: {
                        fabricIcon: _this.globalEntityTypesService.getEntityTypeIcon(LegacyUser),
                    },
                    sort: { enabled: false },
                    getLink: function (user) {
                        return _this.globalEntityTypesService.getEntityType(LegacyUser).getEntitiesLink([user]);
                    },
                },
                {
                    id: 'lastSeen',
                    name: _this.i18nService.get('users_fields_lastSeen'),
                    className: 'nowrap wcd-text-overflow-large',
                    component: {
                        type: TzDateComponent,
                        getProps: function (user) { return ({ date: user.lastSeen }); },
                    },
                    sort: { enabled: false },
                },
                {
                    id: 'loggedOnMachinesCount',
                    name: _this.i18nService.get('users_fields_TotalDevices'),
                    getDisplay: function (user) {
                        return _this.prettyNumberService.prettyNumber(user.loggedOnMachinesCount);
                    },
                    sort: { enabled: false },
                    enabledByDefault: true,
                },
                {
                    id: 'sid',
                    name: _this.i18nService.get('users_fields_SID'),
                    getDisplay: function (user) { return user.sid; },
                    sort: { enabled: false },
                    enabledByDefault: false,
                },
            ];
            return DataviewField.fromList(fields);
        });
    }
    Object.defineProperty(LegacyUsersFieldsService.prototype, "fields", {
        get: function () {
            return this._fields.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LegacyUsersFieldsService.prototype, "fieldsWithIncident", {
        get: function () {
            return this._fields.value;
        },
        enumerable: true,
        configurable: true
    });
    return LegacyUsersFieldsService;
}());
export { LegacyUsersFieldsService };
