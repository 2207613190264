import { Component, Input, OnInit } from '@angular/core';
import { ModelBase } from '@microsoft/paris';
import { EntityType } from '../../models/entity-type.interface';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';

@Component({
	template: '',
})
export class EntityHeaderBottomComponentBase<T extends ModelBase> implements OnInit {
	/**
	 * entity displayed
	 */
	@Input() entity: T;

	@Input()
	entityType: EntityType<T>;

	iconName: string;

	constructor(
		protected readonly globalEntityTypesService: GlobalEntityTypesService,
		protected readonly i18nService: I18nService
	) {}

	ngOnInit() {
		if (this.entity) {
			this.setEntity(this.entity);
		}
	}

	setEntity(entity) {
		this.entity = entity;
		this.entityType = this.globalEntityTypesService.getEntityType(entity.constructor);
		this.iconName = this.entityType.getIcon
			? this.entityType.getIcon([this.entity])
			: this.entityType.icon;
	}
}
