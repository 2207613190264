import { Entity, EntityField } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { convertOfficeActionToInvestigationAction, InvestigationAction } from './investigation-action.entity';
import { OfficeUtils } from '../../utils/office-utils';
import { get } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';

const OFFICE_ALERT_PREFIX = `fa`;

@Entity({
	singularName: 'Investigation Action',
	pluralName: 'Investigation Actions',
	endpoint: 'autoir/officeui/action',
	allItemsProperty: 'results',
	separateArrayParams: true,
	parseItemQuery: (itemId, entity, config, params: { [index: string]: any }) =>
		// There's bug in office that for PageSize=1 we don't get all the data, hence, PageSize=50
		`Find/MtpBatch?tenantid=${
			params.tenantId
		}&PageSize=50&Filter=ModelType eq 1 and UrnProp eq '${itemId}'`,

	baseUrl: (config: WcdPortalParisConfig) => {
		return sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>';
	},
	parseData: data => {
		const rawData = get(data, 'ResultData[0].InvestigationActionPayload[0]');
		const convertedRawData = OfficeUtils.convertDataFromAlertV3(rawData);
		const actionData = convertOfficeActionToInvestigationAction(convertedRawData);
		return { ...convertedRawData, ...actionData };
	},
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class OfficeInvestigationAction extends InvestigationAction<string> {
	@EntityField({ data: 'Description' })
	description: string;

	@EntityField({
		data: 'RelatedAlertIds',
		parse: relatedAlerts => relatedAlerts && relatedAlerts.map(a => OFFICE_ALERT_PREFIX + a),
	})
	relatedAlerts: Array<string>;
}
