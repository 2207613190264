var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { ServiceTemplate } from './service-template.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.automatedIr; };
var Service = /** @class */ (function (_super) {
    __extends(Service, _super);
    function Service() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Service.prototype, "vendorLogo", {
        get: function () {
            return this.template.logoUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Service.prototype, "iconName", {
        get: function () {
            return "services." + this.name.toLowerCase().replace(/\s/g, '_');
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Service.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Service.prototype, "product", void 0);
    __decorate([
        EntityField({ data: 'template_id' }),
        __metadata("design:type", ServiceTemplate)
    ], Service.prototype, "template", void 0);
    Service = __decorate([
        Entity({
            singularName: 'Service',
            pluralName: 'Services',
            endpoint: 'services',
            loadAll: true,
            allItemsProperty: 'results',
            readonly: true,
            baseUrl: ɵ0,
        })
    ], Service);
    return Service;
}(EntityModelBase));
export { Service };
export { ɵ0 };
