export function mapSecurityGroupEntityToAirsEntity(entityData) {
    return Object.entries(entityData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case "FriendlyName" /* FriendlyName */:
                    acc['name'] = val;
                    acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
                    break;
                case "Id" /* Id */:
                    acc['id'] = val;
                    break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
}
