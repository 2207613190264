var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { HuntingQueryResult } from '@wcd/domain';
var ɵ0 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ1 = function (query) { return ({
    data: {
        QueryText: query.queryText,
        StartTime: query.startTime || null,
        EndTime: query.endTime || null,
        MaxRecordCount: query.resultsLimit || null,
    },
}); };
/**
 * Run a Hunting query in hunting service.
 */
var HuntingQueryApiCall = /** @class */ (function (_super) {
    __extends(HuntingQueryApiCall, _super);
    function HuntingQueryApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingQueryApiCall = __decorate([
        ApiCall({
            name: 'Hunting query',
            endpoint: 'queryExecutor',
            baseUrl: ɵ0,
            method: 'POST',
            cache: false,
            timeout: 1000 * 60 * 10,
            type: HuntingQueryResult,
            parseQuery: ɵ1,
        })
    ], HuntingQueryApiCall);
    return HuntingQueryApiCall;
}(ApiCallModel));
export { HuntingQueryApiCall };
export { ɵ0, ɵ1 };
