import { I18nService } from '@wcd/i18n';
import { Lazy } from '@wcd/utils';
var TimeRangesService = /** @class */ (function () {
    function TimeRangesService(i18nService) {
        var _this = this;
        this.i18nService = i18nService;
        this._allTimeRangeValues = new Lazy(function () { return [
            {
                id: "day" /* day */,
                value: 1,
                name: _this.i18nService.get('datetime.day.singular.template', { amount: 1 }),
            },
            {
                id: "3days" /* '3days' */,
                value: 3,
                name: _this.i18nService.get('datetime.day.plural.template', { amount: 3 }),
            },
            {
                id: "week" /* week */,
                value: 7,
                name: _this.i18nService.get('datetime.week.singular.template', { amount: 1 }),
            },
            {
                id: "month" /* month */,
                value: 30,
                name: _this.i18nService.get('datetime.day.plural.template', { amount: 30 }),
            },
            {
                id: "6months" /* '6months' */,
                value: 180,
                name: _this.i18nService.get('datetime.month.plural.template', { amount: 6 }),
            },
            {
                id: "custom" /* custom */,
                name: _this.i18nService.strings.datetime_dataRange_customRange_label,
                isCustom: true,
                customRange: null,
            },
        ]; });
    }
    Object.defineProperty(TimeRangesService.prototype, "all", {
        get: function () {
            return this._allTimeRangeValues.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeRangesService.prototype, "standard", {
        get: function () {
            return this.all.filter(function (rangeValue) { return rangeValue.id !== "custom" /* custom */; });
        },
        enumerable: true,
        configurable: true
    });
    TimeRangesService.prototype.pick = function (ranges) {
        return this.all.filter(function (rangeValue) { return ranges.includes(rangeValue.id); });
    };
    return TimeRangesService;
}());
export { TimeRangesService };
