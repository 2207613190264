var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { I18nService } from '@wcd/i18n';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { filter, map } from 'rxjs/operators';
import { LegacyUser, Machine, USER_LOOKBACK_IN_DAYS } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
var UserDeviceListWidgetComponent = /** @class */ (function (_super) {
    __extends(UserDeviceListWidgetComponent, _super);
    function UserDeviceListWidgetComponent(reportsService, globalEntityTypesService, activatedEntity, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.activatedEntity = activatedEntity;
        _this.i18nService = i18nService;
        _this.machineType = Machine;
        _this.machineEntityTypeService = globalEntityTypesService.getEntityType(Machine);
        return _this;
    }
    Object.defineProperty(UserDeviceListWidgetComponent.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'user-device-list-widget',
                name: this.i18nService.get('users.entityDetails.report.widgets.deviceList.title'),
                rangeInDays: USER_LOOKBACK_IN_DAYS,
                loadData: function () {
                    return _this.activatedEntity.currentEntity$.pipe(filter(function (e) { return e instanceof LegacyUser; }), map(function (user) { return user; }));
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    return UserDeviceListWidgetComponent;
}(ReportWidgetComponent));
export { UserDeviceListWidgetComponent };
