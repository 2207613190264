import { ChangeDetectorRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, ViewContainerRef, } from '@angular/core';
import { DataTableField } from '../models/datatable-field.model';
import { generateChanges } from '@wcd/angular-extensions';
import { isNgChanges } from '@wcd/angular-extensions';
var DataTableFieldValueCustomComponent = /** @class */ (function () {
    function DataTableFieldValueCustomComponent(changeDetectorRef, resolver) {
        this.changeDetectorRef = changeDetectorRef;
        this.resolver = resolver;
        this.customComponentEmpty = false;
    }
    Object.defineProperty(DataTableFieldValueCustomComponent.prototype, "customComponent", {
        set: function (customComponentPlaceholder) {
            if (customComponentPlaceholder)
                this.setCustomComponent(customComponentPlaceholder);
        },
        enumerable: true,
        configurable: true
    });
    DataTableFieldValueCustomComponent.prototype.ngOnChanges = function (changes) {
        if ((changes.field || changes.value) && this.field && this.value) {
            this.updateValue();
        }
    };
    DataTableFieldValueCustomComponent.prototype.updateValue = function () {
        var fieldCustomComponent = this.getCustomComponent(this.value);
        if (this.fieldCustomComponentRef && fieldCustomComponent.getProps) {
            var props = fieldCustomComponent.getProps(this.value);
            var changes = generateChanges(this.fieldCustomComponentRef.instance, props);
            Object.assign(this.fieldCustomComponentRef.instance, props);
            if (isNgChanges(this.fieldCustomComponentRef.instance))
                this.fieldCustomComponentRef.instance.ngOnChanges(changes);
            this.fieldCustomComponentRef.changeDetectorRef.markForCheck();
        }
    };
    DataTableFieldValueCustomComponent.prototype.setCustomComponent = function (customComponentPlaceholder) {
        customComponentPlaceholder.clear();
        var injector = Injector.create({
            providers: [],
            parent: customComponentPlaceholder.parentInjector,
        });
        var component = this.getCustomComponent(this.value);
        var factory = this.resolver.resolveComponentFactory(component.type);
        var fieldCustomComponentRef = (this.fieldCustomComponentRef = factory.create(injector));
        var props = component.getProps && component.getProps(this.value);
        if (component.getProps)
            Object.assign(fieldCustomComponentRef.instance, props);
        if (isNgChanges(this.fieldCustomComponentRef.instance))
            fieldCustomComponentRef.instance.ngOnChanges(generateChanges(props));
        customComponentPlaceholder.insert(fieldCustomComponentRef.hostView);
        this.changeDetectorRef.detectChanges();
    };
    DataTableFieldValueCustomComponent.prototype.getCustomComponent = function (item) {
        return ((this.field.useDefaultEmptyFieldComponent &&
            this.field.getIsEmptyField(item) &&
            this.field.defaultEmptyComponent) ||
            (this.field.getDynamicComponent && this.field.getDynamicComponent(item)) ||
            this.field.component);
    };
    return DataTableFieldValueCustomComponent;
}());
export { DataTableFieldValueCustomComponent };
