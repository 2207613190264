import { SevilleModule } from '../../seville/seville.module';
import { userAccountService } from '../../seville/services/seville.userAccounts';

SevilleModule.directive('sidePaneUser', sidePaneUserIndicatorDirective);

sidePaneUserIndicatorDirective.$inject = ['sidePaneService'];

function sidePaneUserIndicatorDirective(sidePaneService) {
	return {
		restrict: 'E',
		bindToController: true,
		controllerAs: 'pi',
		scope: {
			accountname: '=',
			accountdomainname: '=?',
			sid: '=?',
			hideIndicator: '=',
		},
		template: `
		<span class="side-pane-indicator" tabindex="0" role="button" aria-label="Open user side pane" data-track-id="sidePaneButtonUser" data-track-type="SidePaneToggleButton">
        <i class="icon icon-{{pi.icon}} pointer side-pane-indicator-icon" ng-click="toggle($event)" ng-if="!pi.hideIndicator"></i>
    </span>
		`,
		controller: [
			'$scope',
			'$http',
			'urlMapping',
			'$filter',
			'demoTenantResolverService',
			'$log',
			'sidePaneDataProviderService',
			function(
				$scope,
				$http,
				urlMapping,
				$filter,
				demoTenantResolverService,
				$log,
				sidePaneDataProviderService
			) {
				var vm = this;

				this.$onInit = () => {
					vm.key = userAccountService.resolveUserName(vm.accountname, vm.accountdomainname);
				}

				this.togglePane = function() {
					var loadOperations = 3;
					const href = vm.sid
						? `/users/${vm.sid}`
						: `/users/nosid?accountName=${vm.accountname}${
								vm.accountdomainname ? `&accountDomainName=${vm.accountdomainname}` : ''
						  }`;
					vm.instance = sidePaneService.open({
						key: vm.key,
						title: vm.key,
						type: 'entity',
						icon: 'icon-Contact',
						link: {
							text: 'Go to User page',
							state: 'user',
							params: {
								userdomain: vm.accountdomainname,
								id: vm.accountname,
								sid: vm.sid,
							},
							href,
						},
						sections: [
							{
								title: 'Activity summary',
								order: 1,
							},
						],
					});

					function loadProfile() {
						sidePaneDataProviderService
							.loadUserProfile(vm.accountname, vm.accountdomainname, vm.sid, vm.instance)
							.then(
								function(response) {
									if (response.section) {
										// if an empty profile was returned - the section of logged on machines will have a value of 0
										if (response.section[2].value == 0) {
											vm.instance.setError('Data could not be retrieved.');
										} else {
											vm.instance.setSection('Activity summary', response.section);
											loadOperations--;
											if (loadOperations == 0) {
												vm.instance.setLoading(false);
											}

											vm.instance.setSectionLoading('Activity summary', false);
										}
									} else {
										if (response.error) {
											vm.instance.setError(response.error);
										} else {
											vm.instance.setError('Data could not be retrieved.');
										}
									}
								},
								function(response) {
									if (response.error) {
										vm.instance.setError(response.error);
									} else {
										vm.instance.setError('Data could not be retrieved.');
									}
								}
							);
					}

					function loadAlerts() {
						sidePaneDataProviderService
							.loadAlertsForUser(vm.accountname, vm.accountdomainname, vm.sid, vm.instance)
							.then(
								function(response) {
									if (response.section) {
										vm.instance.setProps(response.section);

										loadOperations--;
										if (loadOperations == 0) {
											vm.instance.setLoading(false);
										}
									} else {
										if (response.error) {
											vm.instance.setError(response.error);
										} else {
											vm.instance.setError('Data could not be retrieved.');
										}
									}
								},
								function(response) {
									if (response.error) {
										vm.instance.setError(response.error);
									} else {
										vm.instance.setError('Data could not be retrieved.');
									}
								}
							);
					}

					function loadUserSidePaneDetails() {
						sidePaneDataProviderService
							.loadUserSidePaneDetails(vm.accountname, vm.accountdomainname, vm.instance)
							.then(
								function(response) {
									if (response.section) {
										vm.instance.setProps(response.section);

										loadOperations--;
										if (loadOperations == 0) {
											vm.instance.setLoading(false);
										}
									} else {
										if (response.error) {
											vm.instance.setError(response.error);
										} else {
											vm.instance.setError('Data could not be retrieved.');
										}
									}
								},
								function(response) {
									if (response.error) {
										vm.instance.setError(response.error);
									} else {
										vm.instance.setError('Data could not be retrieved.');
									}
								}
							);
					}

					loadProfile();
					loadUserSidePaneDetails();
					loadAlerts();
				};
			},
		],
	};
}
