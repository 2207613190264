import { DataviewField } from '@wcd/dataview';
import { SystemExclusion, SystemExclusionListType } from '@wcd/domain';
import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { FilterValuesDatePickerComponent, FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class SystemExclusionBaseFields {
	constructor(private paris: Paris, private i18nService: I18nService) {}

	private _fields: Array<DataviewField>;

	get fields(): Array<DataviewField<SystemExclusion>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<SystemExclusion>([
				{
					id: 'acl_type',
					name: this.i18nService.strings.systemExclusions_fields_listType,
					filter: {
						component: {
							type: FilterValuesChecklistComponent,
							config: {
								mapFilterValue: (listTypeId) => {
									const listType: SystemExclusionListType = this.paris.getValue(
										SystemExclusionListType,
										parseInt(listTypeId, 10)
									);

									if (listType) {
										return {
											name: listType.name,
											id: listType.id,
										};
									}

									return null;
								},
							},
						},
					},
					getDisplay: (item: SystemExclusion) => item.listType.name,
					className: 'nowrap',
				},
				{
					id: 'creation_time',
					name: this.i18nService.strings.systemExclusions_fields_creationTime,
					sort: { sortDescendingByDefault: true },
					filter: {
						component: {
							type: FilterValuesDatePickerComponent,
						},
					},
					component: {
						type: TzDateComponent,
						getProps: (systemExclusion: SystemExclusion) => ({
							date: systemExclusion.creationTime,
						}),
					},
				},
				{
					id: 'creator_name',
					name: this.i18nService.strings.systemExclusions_fields_creatorName,
					getDisplay: (item: SystemExclusion) => item.creatorName,
				},
			]);
		}

		return this._fields;
	}
}
