import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Disableable, DISABLEABLE_TOKEN } from '../models/disableable.interface';

let lastId = 0;

@Component({
	selector: 'wcd-checkbox',
	template: `
		<div class="wcd-checkbox" [class.disabled]="isDisabled">
			<input
				type="checkbox"
				[checked]="checked"
				(change)="_onInteractionEvent($event)"
				[id]="id"
				[name]="name"
				[disabled]="isDisabled"
			/>

			<ng-container *ngIf="setKeyboardSupport; else noKeyboardSupport">
				<label
					[attr.role]="ariaRole"
					[attr.aria-checked]="checked"
					(keydown.space)="_onInteractionEvent($event)"
					[attr.for]="id"
					[wcdTooltip]="tooltipText || (isDisabled && disableReason)"
					[wcdTooltipShowOnOverflowOnly]="tooltipOnOverFlowOnly"
					class="wcd-flex-horizontal"
					[class.invalid]="isInvalid"
					keyboard-navigable-element
					[elementVisible]="elementVisible"
					(onTabIndexChange)="focusChange.emit($event)"
					[attr.aria-disabled]="isDisabled"
					[class.force-wrap-normal]="wrapLabel"
				>
					<ng-container *ngTemplateOutlet="checkboxContent"></ng-container>
				</label>
			</ng-container>
		</div>

		<ng-template #noKeyboardSupport>
			<label
				[attr.role]="ariaRole"
				tabindex="0"
				[attr.aria-checked]="!isPartiallyChecked ? checked : 'mixed'"
				(keydown.space)="_onInteractionEvent($event)"
				[attr.for]="id"
				[wcdTooltip]="tooltipText || (isDisabled && disableReason)"
				[wcdTooltipShowOnOverflowOnly]="tooltipOnOverFlowOnly"
				class="wcd-flex-horizontal"
				[class.invalid]="isInvalid"
				[attr.aria-disabled]="isDisabled"
				[class.force-wrap-normal]="wrapLabel"
			>
				<ng-container *ngTemplateOutlet="checkboxContent"></ng-container>
			</label>
		</ng-template>

		<ng-template #checkboxContent>
			<div class="wcd-flex-none">
				<i *ngIf="isPartiallyChecked; else checkboxIcon" class="wcd-checkbox--partial"></i>
				<ng-template #checkboxIcon>
					<fab-icon
						[iconName]="checked ? 'CheckboxCompositeReversed' : 'Checkbox'"
						[contentClass]="checked ? 'checked' : ''"
					></fab-icon>
				</ng-template>
			</div>
			<div class="wcd-flex-1 wcd-checkbox-label-contents">
				{{ label }}
				<ng-content></ng-content>
			</div>
		</ng-template>
	`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true,
		},
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => CheckboxComponent),
		},
	],
	styleUrls: ['./checkbox.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor, Disableable {
	private _checked: boolean;
	disableReason: string;

	@Input() id = `checkbox-${lastId++}`;
	@Input() label: string;
	@Input() isDisabled: boolean;
	@Input() name: string;
	@Input() tooltipText: string;
	@Input() tooltipOnOverFlowOnly = true;
	@Input() isPartiallyChecked = false;
	@Input() isInvalid = false;
	@Input() setKeyboardSupport: boolean = false;
	@Input() ariaRole="checkbox"
	@Input() wrapLabel:  boolean = false;
	@Input() elementVisible:  boolean = true;

	@Input()
	set checked(checked: boolean) {
		if (checked != this.checked) {
			this._checked = checked;
			this.changeDetectorRef.markForCheck();
		}
	}

	get checked() {
		return this._checked;
	}

	@Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() focusChange: EventEmitter<number> = new EventEmitter<number>();

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	onChange: (value: any) => void = value => {};
	onTouched = () => {};

	writeValue(checked: any): void {
		this.checked = !!checked;
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	/**
	 * Toggles the `checked` value between true and false
	 */
	toggle() {
		this.checked = !this.checked;
	}

	/**
	 * Event handler for checkbox input element.
	 * Toggles checked state if element is not disabled.
	 * @param event
	 */
	_onInteractionEvent(event: Event) {
		// We always have to stop propagation on the change event.
		// Otherwise the change event, from the input element, will bubble up and
		// emit its event object to the `change` output.
		event.stopPropagation();
		event.preventDefault();

		if (!this.isDisabled) {
			this.toggle();

			this.onChange(this.checked);
			this.change.emit(this.checked);
		}
	}
}
