var UserIconComponent = /** @class */ (function () {
    function UserIconComponent() {
    }
    Object.defineProperty(UserIconComponent.prototype, "initials", {
        get: function () {
            var initialsMatch = this.userName && this.userName.match(/\b(\w)/g);
            return initialsMatch ? initialsMatch.slice(0, 2).join('') : '';
        },
        enumerable: true,
        configurable: true
    });
    return UserIconComponent;
}());
export { UserIconComponent };
