import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { Registry } from '@wcd/domain';
import { RegistryEntityPanelComponent } from '../components/registry.entity-panel.component';

@Injectable()
export class RegistryEntityTypeService implements EntityTypeService<Registry> {
	readonly entityType: Readonly<EntityType<Registry>> = {
		entity: Registry,
		id: 'registry',
		icon: 'entities.registry',
		singleEntityPanelComponentType: RegistryEntityPanelComponent,
		loadFullEntityInPanel: false,
		getEntityName: registry => registry.name,
	};
}
