import { Injectable } from '@angular/core';
import { Feature, FeaturesService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { DialogsService } from '../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { AppInsightsService } from '../insights/services/app-insights.service';

const TOGGLE_SUPPORT_CENTRAL_SUCCESS = 'ToggleSupportCentralSuccess';
const TOGGLE_SUPPORT_CENTRAL_FAIL = 'ToggleSupportCentralFail';

@Injectable({
	providedIn: 'root',
})
export class SupportCentralService {
	constructor(
		private featuresService: FeaturesService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private appInsightsService: AppInsightsService,
	) {}

	get withSupportCentral(): boolean {
		return !sccHostService.isSCC && this.featuresService.isEnabled(Feature.SupportCentral);
	}

	toggleWidgetIfPossible(): void {
		if (window['SupportCentral'] && window['SupportCentral'].ToggleWidget) {
			window['SupportCentral'].ToggleWidget();
			this.appInsightsService.trackEvent(TOGGLE_SUPPORT_CENTRAL_SUCCESS)
		} else {
			this.dialogsService.confirm({
				title: this.i18nService.get('support_notAvailableTitle'),
				text: this.i18nService.get('support_notAvailableText'),
			});
			this.appInsightsService.trackEvent(TOGGLE_SUPPORT_CENTRAL_FAIL)
		}
	}
}
