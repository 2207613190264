/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "@angular/common";
import * as i5 from "./baseline-device-compliance-status.component";
import * as i6 from "../../../../../tvm/services/tvm-color.service";
import * as i7 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_BaselineComplianceStatusComponent = [];
var RenderType_BaselineComplianceStatusComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BaselineComplianceStatusComponent, data: {} });
export { RenderType_BaselineComplianceStatusComponent as RenderType_BaselineComplianceStatusComponent };
function View_BaselineComplianceStatusComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "wcd-flex-horizontal-align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "wcd-shared-icon", [["iconName", "StatusCircleInner"]], [[8, "className", 0]], null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(3, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["\n\t\t\t\t", "\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var currVal_1 = "StatusCircleInner"; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getIconClass(_co.configuration); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.getComplianceMessage(_co.configuration); _ck(_v, 7, 0, currVal_2); }); }
export function View_BaselineComplianceStatusComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BaselineComplianceStatusComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSingleAsset(_co.configuration); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BaselineComplianceStatusComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_BaselineComplianceStatusComponent_0, RenderType_BaselineComplianceStatusComponent)), i0.ɵdid(1, 49152, null, 0, i5.BaselineComplianceStatusComponent, [i6.TvmColorService, i7.I18nService], null, null)], null, null); }
var BaselineComplianceStatusComponentNgFactory = i0.ɵccf("ng-component", i5.BaselineComplianceStatusComponent, View_BaselineComplianceStatusComponent_Host_0, { entity: "entity" }, {}, []);
export { BaselineComplianceStatusComponentNgFactory as BaselineComplianceStatusComponentNgFactory };
