var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, DoCheck, ElementRef, OnInit } from '@angular/core';
import { I18nService } from '@wcd/i18n';
var lastId = 0;
var DataviewActionButtonComponent = /** @class */ (function () {
    function DataviewActionButtonComponent(i18nService, cd) {
        this.i18nService = i18nService;
        this.cd = cd;
        this.smallScreenView = false;
        this.isInMoreMenu = false;
        this.isBusy = false;
        this.isBusyFn = function () { return false; };
        this.disabled = false;
        this.shouldRender = true;
        this.elementId = 'command-bar-item-' + ++lastId;
    }
    DataviewActionButtonComponent.prototype.buttonElementPicker = function () {
        return this.buttonEl.nativeElement;
    };
    DataviewActionButtonComponent.prototype.ngOnInit = function () {
        Object.assign(this, __assign({}, this.buttonActionConfig));
    };
    DataviewActionButtonComponent.prototype.ngDoCheck = function () {
        var hasChanges = false;
        if (this.isBusyFn && this.isBusy !== this.isBusyFn()) {
            this.isBusy = this.isBusyFn();
            hasChanges = true;
        }
        if (this.localizedLabelFn && this.localizedLabel !== this.localizedLabelFn()) {
            this.localizedLabel = this.localizedLabelFn();
            hasChanges = true;
        }
        if (this.iconFn && this.icon !== this.iconFn()) {
            this.icon = this.iconFn();
            hasChanges = true;
        }
        if (this.localizedTooltipFn && this.localizedTooltip !== this.localizedTooltipFn()) {
            this.localizedTooltip = this.localizedTooltipFn();
            hasChanges = true;
        }
        if (hasChanges) {
            this.cd.detectChanges();
        }
    };
    return DataviewActionButtonComponent;
}());
export { DataviewActionButtonComponent };
