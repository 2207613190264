
		<div *ngIf="!shouldHideToolbar" class='wcd-flex-justify-end ms-bgColor-gray10'>
			<div class="wcd-margin-small-horizontal wcd-flex-none wcd-padding-small-vertical semi-bold">
				<fab-command-bar-button
					data-track-id="PromotionBannerExploreFromBarClick"
					data-track-type="Button"
					className='ms-bgColor-gray10'
					[href]='navigateToUrl'
					target='_blank'>
					<wcd-shared-icon iconName="OpenInNewWindow" class='wcd-padding-small-right'></wcd-shared-icon>
					{{ 'promotion.action.navbar' | i18n }}
				</fab-command-bar-button>
			</div>
		</div>
	