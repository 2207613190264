import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Get the Office tenant URL prefix',
	endpoint: 'QueryTenantUrlPrefix',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.officeOptIn,
	parse: (data: { TenantUrlPrefix: string }) => data.TenantUrlPrefix,
	cache: true,
})
export class OfficeTenantUrlPrefixApiCall extends ApiCallModel<string> {}
