var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { ActivatedRoute } from '@angular/router';
import { I18nService } from '@wcd/i18n';
var LiveResponseSessionEntityComponent = /** @class */ (function (_super) {
    __extends(LiveResponseSessionEntityComponent, _super);
    function LiveResponseSessionEntityComponent(route, i18nService) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.i18nService = i18nService;
        _this.tabs = [
            {
                id: 'console',
                name: _this.i18nService.strings.liveResponse_tabs_console,
                routerLink: './',
            },
            {
                id: 'log',
                name: _this.i18nService.strings.liveResponse_tabs_log,
                routerLink: './log',
            },
        ].map(function (t) { return new TabModel(t); });
        var childComponentUrl = route.snapshot.firstChild && route.snapshot.firstChild.url.toString();
        _this.currentTab =
            _this.tabs.find(function (tab) { return new RegExp("./" + childComponentUrl + "$").test(tab.routerLink); }) ||
                _this.tabs[0];
        _this.isInitTerminal = _this.currentTab.id === 'console';
        return _this;
    }
    Object.defineProperty(LiveResponseSessionEntityComponent.prototype, "currentTabId", {
        get: function () {
            return this.currentTab.id;
        },
        enumerable: true,
        configurable: true
    });
    LiveResponseSessionEntityComponent.prototype.onTabChange = function (event) {
        this.currentTab = event.tab;
        if (!this.isInitTerminal) {
            this.isInitTerminal = this.currentTab.id === 'console';
        }
    };
    Object.defineProperty(LiveResponseSessionEntityComponent.prototype, "session", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    return LiveResponseSessionEntityComponent;
}(EntityComponentBase));
export { LiveResponseSessionEntityComponent };
