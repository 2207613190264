import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityRelationTypeEntity } from './airs-related-entity-type-entity.value-object';

@ValueObject({
	singularName: 'Related Entity Type',
	pluralName: 'Related Entity Types',
	readonly: true,
})
export class AirsEntityRelationType extends ModelBase {
	@EntityField() count: number;

	@EntityField({ data: 'relation_name' })
	relationName: string;

	@EntityField({ arrayOf: AirsEntityRelationTypeEntity })
	entities: Array<AirsEntityRelationTypeEntity>;
}
