/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./report-row.component.ngfactory";
import * as i2 from "./report-row.component";
import * as i3 from "@angular/common";
import * as i4 from "./report-widget.component.ngfactory";
import * as i5 from "./report-widget.component";
import * as i6 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "./report-column.component";
var styles_ReportColumnComponent = [];
var RenderType_ReportColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ReportColumnComponent, data: {} });
export { RenderType_ReportColumnComponent as RenderType_ReportColumnComponent };
function View_ReportColumnComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "report-row", [], [[8, "className", 0]], null, null, i1.View_ReportRowComponent_0, i1.RenderType_ReportRowComponent)), i0.ɵdid(1, 49152, [[2, 4], ["row", 4]], 0, i2.ReportRowComponent, [], { reportRow: [0, "reportRow"], params: [1, "params"], dataOptions: [2, "dataOptions"], headerLevel: [3, "headerLevel"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.params; var currVal_3 = _co.dataOptions; var currVal_4 = ((_co.headerLevel - 0) - 0); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "dashboard-row ", _v.context.$implicit.className, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ReportColumnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReportColumnComponent_2)), i0.ɵdid(3, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reportColumn.rows; var currVal_1 = _co.trackById; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_ReportColumnComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "report-widget", [], null, null, null, i4.View_ReportWidgetDynamicComponent_0, i4.RenderType_ReportWidgetDynamicComponent)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(3, 245760, [[1, 4]], 0, i5.ReportWidgetDynamicComponent, [i6.I18nService, i0.ComponentFactoryResolver, i0.ChangeDetectorRef, i0.ElementRef], { widgetComponentType: [0, "widgetComponentType"], params: [1, "params"], dataOptions: [2, "dataOptions"], headerLevel: [3, "headerLevel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ("wcd-flex-" + _v.context.$implicit.weight); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.type; var currVal_2 = _co.params; var currVal_3 = _co.dataOptions; var currVal_4 = _co.headerLevel; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ReportColumnComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { widgets: 1 }), i0.ɵqud(671088640, 2, { rows: 1 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReportColumnComponent_1)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReportColumnComponent_3)), i0.ɵdid(7, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reportColumn.rows; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.reportColumn.widgetComponents; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_ReportColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "report-column", [], null, null, null, View_ReportColumnComponent_0, RenderType_ReportColumnComponent)), i0.ɵdid(1, 49152, null, 0, i7.ReportColumnComponent, [], null, null)], null, null); }
var ReportColumnComponentNgFactory = i0.ɵccf("report-column", i7.ReportColumnComponent, View_ReportColumnComponent_Host_0, { reportColumn: "reportColumn", params: "params", dataOptions: "dataOptions", headerLevel: "headerLevel" }, {}, []);
export { ReportColumnComponentNgFactory as ReportColumnComponentNgFactory };
