import {ApiCall, ApiCallModel} from '@microsoft/paris';
import {WcdPortalParisConfig} from '../paris-config.interface';

@ApiCall({
	name: 'Get cyber event go hunt encoded query',
	endpoint: (config, query) => {
		return `machines/${query.where['machineId']}/event/huntingQuery`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (query: CyberEventGoHuntQueryRequest) => ({
		params: {
			machineId: query.machineId,
			actionTime: query.actionTimeIsoString,
			actionType: query.actionType,
		},
	}),
	parseData: (data: GoHuntQueryResponse) : GoHuntQueryResponse => ({
		allActivity: data['AllActivity'],
		encodedHuntingQuery: data.encodedHuntingQuery,
	}),
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class CyberEventGoHuntQueryApiCall extends ApiCallModel<GoHuntQueryResponse> {}

@ApiCall({
	name: 'Get cyber event go hunt encoded query',
	method: 'POST',
	endpoint: (config, query) => `goHunt/Timeline`,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	parseQuery: (query: CyberEventGoHuntQueryRequest) => ({
		data: {
			...query,
			actionType: "",
			actionTime: query.actionTimeIsoString,
		},
	}),
	parseData: (data: GoHuntQueryResponse) : GoHuntQueryResponse => ({
		allActivity: data['AllActivity'],
		encodedHuntingQuery: data.encodedHuntingQuery,
	}),
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class CyberEventGoHuntQueryApiCallVNext extends ApiCallModel<GoHuntQueryResponse> {
}

export interface CyberEventGoHuntQueryRequest {
	machineId: string;
	actionType: string;
	actionTimeIsoString: string;
	actionTime?: string;
}

export interface GoHuntQueryResponse {
	encodedHuntingQuery: string;
	allActivity: string;
}
