import { ComponentRef, Injectable, Injector } from '@angular/core';
import { PanelSettings, PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { InvestigationCommentsPanelComponent } from '../../investigations_common/components/investigation-comments.panel.component';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { AuthService } from '@wcd/auth';
import { Investigation, InvestigationStatus, Tag } from '@wcd/domain';
import { InvestigationsBackendService } from './investigations.backend.service';
import { CancelInvestigationModalComponent } from '../../investigations_common/components/cancel-investigation.modal';
import { DimensionsModel } from '../../../dialogs/models/dimensions.model';
import { from, never, Observable, of } from 'rxjs';
import { DataEntityType, DataQuerySortDirection, DataSet, Paris } from '@microsoft/paris';
import { TagsStore } from '../../../tags/services/tags.store';
import { TagModel } from '../../../tags/models/tags.model';
import { TagsService } from '../../../tags/services/tags.service';
import { TagsEditSettings } from '../../../tags/components/tags-edit/tags-edit.component';
import { RbacService } from '../../../rbac/services/rbac.service';
import { Router } from '@angular/router';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Feature, FeaturesService, PollingService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { InvestigationGraphDataModel } from '../../../graph/airs-investigation/models/investigation-graph-data.model';

import { compact, keyBy } from 'lodash-es';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';

const INVESTIGATION_REFRESH_RATE = 5000;
const SEVILLE_STATUS_TO_STATUS = keyBy(
	(<DataEntityType>InvestigationStatus).entityConfig.values,
	'sevilleStatusId'
);

@Injectable()
export class InvestigationsService {
	private _commentsPanel: ComponentRef<InvestigationCommentsPanelComponent>;
	constructor(
		private dialogsService: DialogsService,
		private authService: AuthService,
		private tagsStore: TagsStore,
		private paris: Paris,
		private injector: Injector,
		private router: Router,
		private featuresService: FeaturesService,
		private i18nService: I18nService,
		private backendService: InvestigationsBackendService,
		private pollingService: PollingService
	) {}

	showCurrentInvestigationCommentsPanel(
		investigation: Investigation,
		investigationGraphData: InvestigationGraphDataModel
	) {
		const panelSettings: PanelSettings = {
			id: 'investigation-comments-panel',
			type: PanelType.medium,
			isModal: true,
			showOverlay: false,
			isBlocking: true,
			noBodyPadding: true,
			scrollBody: false,
		};

		const panelInputs: { [index: string]: any } = {
			investigation: investigation,
			investigationGraphData: investigationGraphData,
		};

		this.dialogsService
			.showPanel(InvestigationCommentsPanelComponent, panelSettings, panelInputs)
			.subscribe((panel: ComponentRef<InvestigationCommentsPanelComponent>) => {
				this._commentsPanel = panel;

				panel.onDestroy(() => {
					this._commentsPanel = null;
				});
			});
	}

	getInvestigationPolling(investigationId: number | string): Observable<Investigation | Error> {
		const poll$: Observable<Investigation | Error> = this.pollingService
			.poll(0, INVESTIGATION_REFRESH_RATE)
			.pipe(
				mergeMap(() =>
					this.paris.getItemById(Investigation, investigationId).pipe(
						catchError(error => {
							if (
								error.status === 403 &&
								this.featuresService.isEnabled(Feature.RbacMachineGroups)
							) {
								const rbacService = this.injector.get(RbacService);
								rbacService.addToExposureCache(
									<DataEntityType>Investigation,
									String(investigationId),
									{ isExposed: false }
								);
								rbacService.showNoAccessModal(
									this.i18nService.strings.rbac_accessDenied_description_investigation
								);
								this.router.navigate(['/investigations']);
								return never();
							} else {
								console.warn(
									`Investigation polling failed. trying again in ${INVESTIGATION_REFRESH_RATE /
										1000} seconds.`
								);
								return of(error);
							}
						})
					)
				)
			);

		return poll$;
	}

	getInvestigationActions(investigation: Investigation): Array<ItemActionModel> {
		return this.getInvestigationsActions([investigation]);
	}

	getInvestigationsActions(investigations: Array<Investigation>): Array<ItemActionModel> {
		const actions: Array<ItemActionModel> = [
			// new ItemActionModel({
			// 	id: 'investigationTags',
			// 	rbac: ['alertsInvestigation'],
			// 	nameKey: 'tags.manage',
			// 	type: ItemActionType.Tags,
			// 	icon: 'tag',
			// 	refreshOnResolve: true,
			// 	options: {
			// 		tags: this.getInvestigationTagsSettings(investigations),
			// 	},
			// }),
		];

		if (
			investigations.every(
				investigation => investigation.isRunning && !investigation.isOfficeInvestigation
			)
		)
			actions.push(this._investigationDataViewActions.cancel);

		return compact(actions);
	}

	getInvestigationTagsSettings(investigations: Array<Investigation>): TagsEditSettings<Investigation> {
		return {
			getTags: (investigations: Array<Investigation>) =>
				of(TagsService.getTagsArray(investigations, investigation => investigation.tags)),
			setTags: (tags: Array<Tag>, addedTags: Array<Tag>, removedTags: Array<Tag>) => {
				const investigationIds = investigations.map(investigation => investigation.id);

				if (addedTags && addedTags.length)
					return this.tagsStore.addTagsToObjects(
						new TagModel(addedTags[0]),
						'investigation',
						investigationIds
					);
				else if (removedTags && removedTags.length)
					return this.tagsStore.removeTagsFromObjects(
						new TagModel(removedTags[0]),
						'investigation',
						investigationIds
					);

				return of(null);
			},
			searchFunction: (term: string) =>
				this.paris
					.query(Tag, {
						pageSize: 6,
						where: { search: term },
						sortBy: [
							{
								field: 'popularity',
								direction: DataQuerySortDirection.descending,
							},
						],
					})
					.pipe(
						map((dataSet: DataSet<Tag>) =>
							dataSet.items.map(tag => {
								return {
									label: tag.name,
									value: tag.id,
									item: tag,
								};
							})
						)
					),
			createNewTag: (tag: Tag) => from(this.tagsStore.save(new TagModel(tag))),
			searchSettings: {},
		};
	}

	private _investigationDataViewActionsIndex: { [actionId: string]: ItemActionModel };

	private get _investigationDataViewActions(): { [actionId: string]: ItemActionModel } {
		if (!this._investigationDataViewActionsIndex) {
			const investigationActions = compact([
				!this.authService.currentUser.isReadonly
					? {
							id: 'cancel',
							nameKey: 'cancel',
							icon: 'stopInvestigation',
							method: this.cancelInvestigations.bind(this),
							tooltip: this.i18nService.strings.investigations_actions_cancel_tooltip,
							refreshOnResolve: true,
							rbac: [RbacMdeAllowedActions.alertsInvestigation],
					  }
					: null,
			]).map(itemActionConfig => new ItemActionModel(itemActionConfig));

			this._investigationDataViewActionsIndex = keyBy(investigationActions, 'id');
		}

		return this._investigationDataViewActionsIndex;
	}

	cancelInvestigations(investigations: Array<Investigation>, options?: Object): Promise<any> {
		const runningInvestigations: Array<Investigation> =
			investigations &&
			investigations.filter(
				investigation => investigation.isRunning && !investigation.isOfficeInvestigation
			);

		return new Promise((resolve, reject) => {
			this.getCancelInvestigationComment(
				'investigation',
				runningInvestigations ? runningInvestigations.length : null
			).then(
				(cancelData: { reason: number; comment: string }) => {
					this.backendService
						.cancelInvestigations(
							runningInvestigations
								? runningInvestigations.map(investigation => investigation.id)
								: null,
							cancelData,
							options
						)
						.subscribe(
							(result: { count: number }) => {
								const count: number =
										result && result.count !== undefined
											? result.count
											: (investigations && investigations.length) || 0,
									notificationText: string = count
										? count > 1
											? this.i18nService.get(
													'investigations_actions_cancel_successMessage_plural',
													{
														investigationCount: count,
													}
											  )
											: this.i18nService.strings
													.investigations_actions_cancel_successMessage_singular
										: this.i18nService.strings
												.investigations_actions_cancel_successMessage_general;

								this.dialogsService.showSnackbar({
									text: notificationText,
									icon: 'stopInvestigation',
								});

								resolve(result);
							},
							error => {
								const title = this.i18nService.strings
									.investigations_actions_cancel_errorMessage;

								this.dialogsService.showError({
									title: title,
									data: error,
								});

								reject(title);
							}
						);
				},
				error => reject(error)
			);
		});
	}

	private getCancelInvestigationComment(
		cancelReasonsType: string,
		investigationCount: number,
		hostName?: string
	): Promise<{ reason?: number; comment: string }> {
		return new Promise((resolve, reject) => {
			let modal: ComponentRef<any>;

			this.dialogsService
				.showModal(
					CancelInvestigationModalComponent,
					{
						id: 'cancel-investigation-modal',
						dimensions: new DimensionsModel(480, 350),
						title:
							cancelReasonsType === 'host'
								? investigationCount > 1
									? this.i18nService.strings
											.investigations_actions_cancel_approvalMessage_plural_host
									: this.i18nService.strings
											.investigations_actions_cancel_approvalMessage_singular_host
								: investigationCount > 1
								? this.i18nService.strings
										.investigations_actions_cancel_approvalMessage_plural
								: this.i18nService.strings
										.investigations_actions_cancel_approvalMessage_singular,
					},
					{
						investigationCount: investigationCount,
						reasonsType: cancelReasonsType,
						confirm: (cancelData: { reason?: number; comment: string }) => {
							resolve(cancelData);
							modal.destroy();
						},
						cancel: () => modal.destroy(),
						hostName: hostName,
					}
				)
				.subscribe(_modal => {
					modal = _modal;
					_modal.onDestroy(() => reject());
				});
		});
	}

	/**
	 * This is only for AngularJS, which can't access Paris directly.
	 * @param {string} investigationStatusTypeName
	 * @returns {InvestigationStatus}
	 */
	getInvestigationStatusByTypeName(investigationStatusTypeName: string): InvestigationStatus {
		return this.paris.getValue(
			InvestigationStatus,
			(investigationStatus: InvestigationStatus) =>
				investigationStatus.type.toLowerCase() === investigationStatusTypeName.toLowerCase()
		);
	}

	static getInvestigationStatusBySevilleStatus(sevilleStatus: string): InvestigationStatus {
		const statusIdInt: number = parseInt(sevilleStatus, 10);
		if (isNaN(statusIdInt)) {
			return null;
		}

		return SEVILLE_STATUS_TO_STATUS[statusIdInt];
	}

	static getInvestigationLink(investigation: {
		externalInvestigationPageUrl?: string;
		isLiveResponse?: boolean;
		id: number | string;
	}) {
		return investigation
			? investigation.externalInvestigationPageUrl ||
					(investigation.isLiveResponse && `/live-response/${investigation.id}`) ||
					`/investigation/${investigation.id}`
			: null;
	}
}
