import { Component, OnDestroy, OnInit } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { IncidentGraphNode } from '@wcd/domain';
import { ChangeDetectorRef } from '@angular/core';
import { EntityPanelsService } from '../../../../global_entities/services/entity-panels.service';
import { DataEntityType } from '@microsoft/paris';

@Component({
	selector: 'incident-graph-node-entity-panel',
	templateUrl: './incident-graph-node.entity-panel.component.html',
})
export class IncidentGraphNodeEntityPanelComponent extends EntityPanelComponentBase<IncidentGraphNode>
	implements OnDestroy, OnInit {
	constructor(changeDetectorRef: ChangeDetectorRef, private entityPanelsService: EntityPanelsService) {
		super(changeDetectorRef);
	}

	get incidentGraphNode(): IncidentGraphNode {
		return this.entity;
	}

	showChildNode(childNode: IncidentGraphNode) {
		this.entityPanelsService.showEntity(<DataEntityType>childNode.entity.constructor, childNode.entity);
	}
}
