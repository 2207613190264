import { DataviewField } from '@wcd/dataview';
import { PrettyNumberService } from '@wcd/prettify';
import { FabricIconNames } from '@wcd/scc-common';
import { FeaturesService, Feature } from '@wcd/config';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { I18nService } from '@wcd/i18n';
import { TvmTextsService } from '../../../..//tvm/services/tvm-texts.service';
var MissingKbFieldsService = /** @class */ (function () {
    function MissingKbFieldsService(prettyNumberService, featuresService, tvmTagsService, i18nService, tvmTextsService) {
        this.prettyNumberService = prettyNumberService;
        this.featuresService = featuresService;
        this.tvmTagsService = tvmTagsService;
        this.i18nService = i18nService;
        this.tvmTextsService = tvmTextsService;
    }
    Object.defineProperty(MissingKbFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._softwareMissingKbsFields) {
                var softwareMissingKbsFieldsArr = [
                    {
                        id: 'vendorBulletin',
                        name: this.i18nService.get('tvm.missingKb.bulletin'),
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'osVersion',
                        name: this.i18nService.get('tvm.missingKb.osVersion'),
                    },
                    {
                        id: 'missingMachines',
                        name: this.i18nService.get('tvm.missingKb.machineMissedOn'),
                        sort: {
                            enabled: false,
                        },
                        getDisplay: function (missingKbs) {
                            return _this.prettyNumberService.prettyNumber(missingKbs.missingMachines);
                        },
                    },
                    {
                        id: 'cvesAddressed',
                        name: this.i18nService.get('tvm.missingKb.cvesAddressed.title'),
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'productFixId',
                        name: this.i18nService.get('tvm.missingKb.name'),
                        sort: {
                            sortDescendingByDefault: true,
                        },
                        onClick: function (missingKbs) { return window.open(missingKbs.productFixUrl); },
                        getCssClass: function () { return 'wcd-font-weight-bold'; },
                    },
                    {
                        id: 'tags',
                        name: this.i18nService.get('common.tags'),
                        component: {
                            type: TagsFieldComponent,
                            getProps: function (missingKb) { return ({
                                tags: _this.tvmTagsService.getMissingKbTags(missingKb),
                            }); },
                        },
                        sort: { enabled: false },
                    },
                ];
                this._softwareMissingKbsFields = DataviewField.fromList(this.linuxSoftwareMissingProductFixesFields());
            }
            return this._softwareMissingKbsFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MissingKbFieldsService.prototype, "exposedAssetsFields", {
        get: function () {
            var _this = this;
            if (this._exposedAssetsFields) {
                return this._exposedAssetsFields;
            }
            this._exposedAssetsFields = DataviewField.fromList([
                {
                    id: 'assetName',
                    name: this.i18nService.get('tvm.common.assetNameTitle'),
                    sort: { enabled: false },
                    getDisplay: function (asset) { return asset.name; },
                    getLink: function (asset) {
                        return _this.featuresService.isEnabled(Feature.UpgradeMachinePage)
                            ? "/machines/" + asset.id
                            : "/machine/" + asset.id;
                    },
                    icon: {
                        fabricIcon: FabricIconNames.System,
                    },
                    className: 'nowrap wcd-text-overflow-medium',
                },
            ]);
            return this._exposedAssetsFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MissingKbFieldsService.prototype, "machineMissingKbsFields", {
        get: function () {
            var _this = this;
            if (!this._machineMissingKbsFields) {
                var missingKbsFieldsArr = [
                    {
                        id: 'vendorBulletin',
                        name: this.i18nService.get('tvm.missingKb.bulletin'),
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'osVersion',
                        name: this.i18nService.get('tvm.missingKb.osVersion'),
                    },
                    {
                        id: 'productsNames',
                        name: this.i18nService.strings.tvm_missingKb_products,
                        getDisplay: function (missingKb) {
                            return _this.tvmTextsService.getFormattedRelatedSoftware(missingKb.productsNames.map(function (name) { return "vendor-_-" + name; }));
                        },
                        getTooltip: function (missingKb) {
                            return _this.tvmTextsService.getFormattedRelatedSoftwareTooltip(missingKb.productsNames.map(function (name) { return "vendor-_-" + name; }));
                        },
                        valueTooltipAllowHtmlRendering: true,
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'cvesAddressed',
                        name: this.i18nService.get('tvm.missingKb.cvesAddressed.title'),
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'productFixId',
                        name: this.i18nService.get('tvm.missingKb.name'),
                        sort: {
                            sortDescendingByDefault: true,
                        },
                        onClick: function (missingKbs) { return window.open(missingKbs.productFixUrl); },
                        getCssClass: function () { return 'wcd-font-weight-bold'; },
                    },
                    {
                        id: 'tags',
                        name: this.i18nService.get('common.tags'),
                        component: {
                            type: TagsFieldComponent,
                            getProps: function (missingKb) { return ({
                                tags: _this.tvmTagsService.getMissingKbTags(missingKb),
                            }); },
                        },
                        sort: { enabled: false },
                    },
                ];
                this._machineMissingKbsFields = DataviewField.fromList(this.linuxMachineMissingProductFixesFields());
            }
            return this._machineMissingKbsFields;
        },
        enumerable: true,
        configurable: true
    });
    MissingKbFieldsService.prototype.linuxMachineMissingProductFixesFields = function () {
        var _this = this;
        return [
            {
                id: 'vendorBulletin',
                name: this.i18nService.strings.tvm_missingKb_bulletin,
                sort: {
                    enabled: false,
                },
            },
            {
                id: 'osVersion',
                name: this.i18nService.strings.tvm_missingKb_osVersion,
            },
            {
                id: 'productFixId',
                name: this.i18nService.strings.tvm_missingKb_id,
                sort: {
                    sortDescendingByDefault: true,
                },
                onClick: function (missingKbs) { return window.open(missingKbs.productFixUrl); },
                getCssClass: function () { return 'wcd-font-weight-bold'; },
            },
            {
                id: 'productsNames',
                name: this.i18nService.strings.tvm_missingKb_products,
                getDisplay: function (missingKb) {
                    return _this.tvmTextsService.getFormattedRelatedSoftware(missingKb.productsNames.map(function (name) { return "vendor-_-" + name; }));
                },
                getTooltip: function (missingKb) {
                    return _this.tvmTextsService.getFormattedRelatedSoftwareTooltip(missingKb.productsNames.map(function (name) { return "vendor-_-" + name; }));
                },
                valueTooltipAllowHtmlRendering: true,
                sort: {
                    enabled: false,
                },
            },
            {
                id: 'cvesAddressed',
                name: this.i18nService.strings.tvm_missingKb_cvesAddressed_title,
                sort: {
                    enabled: false,
                },
            },
            {
                id: 'tags',
                name: this.i18nService.strings.common_tags,
                component: {
                    type: TagsFieldComponent,
                    getProps: function (missingKb) { return ({
                        tags: _this.tvmTagsService.getMissingKbTags(missingKb),
                    }); },
                },
                sort: { enabled: false },
            },
        ];
    };
    MissingKbFieldsService.prototype.linuxSoftwareMissingProductFixesFields = function () {
        var _this = this;
        return [
            {
                id: 'vendorBulletin',
                name: this.i18nService.strings.tvm_missingKb_bulletin,
                sort: {
                    enabled: false,
                },
            },
            {
                id: 'osVersion',
                name: this.i18nService.strings.tvm_missingKb_osVersion,
            },
            {
                id: 'productFixId',
                name: this.i18nService.strings.tvm_missingKb_id,
                sort: {
                    sortDescendingByDefault: true,
                },
                onClick: function (missingKbs) { return window.open(missingKbs.productFixUrl); },
                getCssClass: function () { return 'wcd-font-weight-bold'; },
            },
            {
                id: 'missingMachines',
                name: this.i18nService.strings.tvm_missingKb_machineMissedOn,
                sort: {
                    enabled: false,
                },
                getDisplay: function (missingKbs) {
                    return _this.prettyNumberService.prettyNumber(missingKbs.missingMachines);
                },
            },
            {
                id: 'cvesAddressed',
                name: this.i18nService.strings.tvm_missingKb_cvesAddressed_title,
                sort: {
                    enabled: false,
                },
            },
            {
                id: 'tags',
                name: this.i18nService.strings.common_tags,
                component: {
                    type: TagsFieldComponent,
                    getProps: function (missingKb) { return ({
                        tags: _this.tvmTagsService.getMissingKbTags(missingKb),
                    }); },
                },
                sort: { enabled: false },
            },
        ];
    };
    return MissingKbFieldsService;
}());
export { MissingKbFieldsService };
