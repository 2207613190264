/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../tags/components/tags-field/tags-field.component.ngfactory";
import * as i2 from "../../../../tags/components/tags-field/tags-field.component";
import * as i3 from "@angular/common";
import * as i4 from "./machines-dataview-tags.component";
import * as i5 from "../../services/machine.entity-type.service";
var styles_MachinesDataviewTagsComponent = [];
var RenderType_MachinesDataviewTagsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MachinesDataviewTagsComponent, data: {} });
export { RenderType_MachinesDataviewTagsComponent as RenderType_MachinesDataviewTagsComponent };
function View_MachinesDataviewTagsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "tags-field", [], null, null, null, i1.View_TagsFieldComponent_0, i1.RenderType_TagsFieldComponent)), i0.ɵdid(3, 49152, null, 0, i2.TagsFieldComponent, [], { tags: [0, "tags"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MachinesDataviewTagsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_MachinesDataviewTagsComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.tags$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MachinesDataviewTagsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "machines-dataview-tags", [], null, null, null, View_MachinesDataviewTagsComponent_0, RenderType_MachinesDataviewTagsComponent)), i0.ɵdid(1, 49152, null, 0, i4.MachinesDataviewTagsComponent, [i5.MachineEntityTypeService, i0.ChangeDetectorRef], null, null)], null, null); }
var MachinesDataviewTagsComponentNgFactory = i0.ɵccf("machines-dataview-tags", i4.MachinesDataviewTagsComponent, View_MachinesDataviewTagsComponent_Host_0, { machine: "machine" }, {}, []);
export { MachinesDataviewTagsComponentNgFactory as MachinesDataviewTagsComponentNgFactory };
