import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@wcd/i18n';

/**
 * Simple pipe to display `boolean` values.
 */
@Pipe({ name: 'boolean' })
export class BooleanPipe implements PipeTransform {
	constructor(private readonly i18nService: I18nService) {}

	/**
	 * Transform a boolean value to a user-friendly string.
	 *
	 * @param value the value.
	 * @param alternativeDisplays _optional_ values to display instead of the default ones.
	 * @returns {string}
	 */
	transform(value: boolean, alternativeDisplays?: [string, string]): string {
		if (alternativeDisplays) {
			return value ? alternativeDisplays[0] : alternativeDisplays[1];
		}

		return this.i18nService.get(this._getKey(value));
	}

	private _getKey(value: boolean): string {
		return String(`boolean_${value}`);
	}
}
