
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.name">
				<dt role="none">{{ i18nService.strings.airsEntities_process_fields_processName }}</dt>
				<dd role="none">{{ entity.name }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.processId">
				<dt role="none">{{ i18nService.strings.airsEntities_process_fields_processId }}</dt>
				<dd role="none">{{ entity.processId }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.created">
				<dt role="none">{{ i18nService.strings.airsEntities_process_fields_startTime }}</dt>
				<dd role="none">{{ entity.created | date: 'short' }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.username">
				<dt role="none">{{ i18nService.strings.airsEntities_process_fields_runningAsUser }}</dt>
				<dd role="none">{{ entity.username }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.cwd">
				<dt role="none">{{ i18nService.strings.airsEntities_process_fields_workingDirectory }}</dt>
				<dd role="none">{{ entity.cwd }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.cmd">
				<dt role="none">
					{{ i18nService.strings.airsEntities_process_fields_commandLine }}
				</dt>
				<dd role="none">{{ entity.cmd }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.commandArgs">
				<dt role="none">
					{{ i18nService.strings.airsEntities_process_fields_commandLineArguments }}
				</dt>
				<dd role="none">{{ entity.commandArgs }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.parentName">
				<dt role="none">{{ i18nService.strings.airsEntities_process_fields_parentName }}</dt>
				<dd role="none">{{ entity.parentName }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.fileData as fileData">
				<ng-container *ngIf="fileData.name">
					<dt role="none">
						{{ i18nService.strings.airsEntities_process_fields_imageFileName }}
					</dt>
					<dd role="none">{{ fileData.name }}</dd>
				</ng-container>
				<ng-container *ngIf="fileData.path">
					<dt role="none">{{ i18nService.strings.airsEntities_process_fields_imageFilePath }}</dt>
					<dd role="none">
						<entity-name
							[entity]="entity.fileData"
							[entityName]="fileData.path"
							[entityTypeId]="'airs-entity'"
						></entity-name>
					</dd>
				</ng-container>
				<ng-container *ngIf="fileData.sha1">
					<dt role="none">
						{{ i18nService.strings.airsEntities_process_fields_imageFileSha1 }}
					</dt>
					<dd role="none">{{ fileData.sha1 }}</dd>
				</ng-container>
				<ng-container *ngIf="fileData.sha256">
					<dt role="none">
						{{ i18nService.strings.airsEntities_process_fields_imageFileSha256 }}
					</dt>
					<dd role="none">{{ fileData.sha256 }}</dd>
				</ng-container>
			</ng-container>
		</dl>
	