/*
 * Public API Surface of panels
 */
export * from './lib/components/panel.component';
export * from './lib/services/panels.service';
export * from './lib/panels.module';
export { PanelContainer } from './lib/models/panel-container.model';
export { PanelSettings, PanelMenuAction, PanelAction } from './lib/models/panel-settings.model';
export { PanelType } from './lib/models/panel-type.enum';
export { Panel } from './lib/models/panel.model';
