import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('wcdMachineId', wcdMachineIdFilter);

function wcdMachineIdFilter() {
	return function(input) {
		if (!input || input.length <= 36) {
			return input;
		}

		var guid = input.substring(0, 36);
		var dns = input.substring(36, input.length);
		var parts = dns.split('.');

		return parts[0];
	};
}
