import { ServiceSourceType } from './service-source.enum';
import { detectionSourceValues } from './detection-source.entity.values';
import { DetectionSource } from './detection-source.entity';

export interface ServiceSource {
	name: string;
	nameI18nKey: string;
	isSelectable: boolean;
}

export interface ServiceSourceValue extends ServiceSource {
	id: number;
	children?: DetectionSource[];
}

const AZURE_ATP_NAME = 'MDI';
const MCAS_NAME = 'Microsoft Cloud App Security';
const OATP_NAME = 'MDO';
const MTP_NAME = 'Microsoft 365 Defender';
const CUSTOM_DETECTION_DETECTION_SOURCE = 'Custom detection';
const WDATP_NAME = 'Microsoft Defender for Endpoint';
const MAPG_NAME = 'Microsoft Application Protection and Governance';
const THREAT_EXPERTS = 'Microsoft Threat Experts';
const MANUAL = 'Manual';
const AAD_NAME = 'AAD Identity Protection';
const DLP_NAME = 'Data Loss Prevention'; //TODO: Verify name with Wissan
const APPG_POLICY_NAME = 'App governance Policy';
const APPG_DETECTION_NAME = 'App governance Detection';

export const serviceSourceValues: ServiceSourceValue[] = [
	{
		id: ServiceSourceType.Aatp,
		name: AZURE_ATP_NAME,
		nameI18nKey: 'serviceSourceType_Aatp',
		isSelectable: true,
		children: [detectionSourceValues.find((detectionSource) => detectionSource.name === AZURE_ATP_NAME)],
	},
	{
		id: ServiceSourceType.Mcas,
		name: MCAS_NAME,
		nameI18nKey: 'serviceSourceType_Mcas',
		isSelectable: true,
		children: [detectionSourceValues.find((detectionSource) => detectionSource.name === MCAS_NAME)],
	},
	{
		id: ServiceSourceType.Wdatp,
		name: WDATP_NAME,
		nameI18nKey: 'serviceSourceType_Wdatp',
		isSelectable: true,
		// MDATP children are selected with some logic that involves appConfigService and featuresService
		// hence, actually children assignment is done in source-filter.service
	},
	{
		id: ServiceSourceType.Mtp,
		name: MTP_NAME,
		nameI18nKey: 'serviceSourceType_Mtp',
		isSelectable: true,
		children: detectionSourceValues.filter(
			(detectionSource) =>
				detectionSource.isSelectable &&
				[CUSTOM_DETECTION_DETECTION_SOURCE, MTP_NAME, THREAT_EXPERTS, MANUAL].includes(
					detectionSource.name
				)
		),
	},
	{
		id: ServiceSourceType.Oatp,
		name: OATP_NAME,
		nameI18nKey: 'serviceSourceType_Oatp',
		isSelectable: true,
		children: [detectionSourceValues.find((detectionSource) => detectionSource.name === OATP_NAME)],
	},
	{
		id: ServiceSourceType.Mapg,
		name: MAPG_NAME,
		nameI18nKey: 'serviceSourceType_Mapg',
		isSelectable: true,
		children: detectionSourceValues.filter(
			(detectionSource) =>
				detectionSource.isSelectable &&
				[APPG_POLICY_NAME, APPG_DETECTION_NAME].includes(detectionSource.name)
		),
	},
	{
		id: ServiceSourceType.Aad,
		name: AAD_NAME,
		nameI18nKey: 'serviceSourceType_Aad',
		isSelectable: true,
		children: [detectionSourceValues.find((detectionSource) => detectionSource.name === AAD_NAME)],
	},
	{
		id: ServiceSourceType.Dlp,
		name: DLP_NAME,
		nameI18nKey: 'serviceSourceType_Dlp',
		isSelectable: true,
		children: [detectionSourceValues.find((detectionSource) => detectionSource.name === DLP_NAME)],
	},
];
