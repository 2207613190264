import { SevilleModule } from '../../seville/seville.module';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { reduce } from 'lodash-es';

declare let angular: angular.IAngularStatic;

SevilleModule.directive('machineTags', machineTagsDirective);

function machineTagsDirective() {
	/**
	 * tags - a list of user defined tags to display.
	 * readOnlyTags - a list of custom tags to display as read only.
	 * canremovetags - boolean, determine if tags (not readonly) may be removed or not.
	 * onTagRemoved - callback function to be called when tag removal is requested.
	 */
	return {
		restrict: 'EA',
		scope: {
			tags: '=?',
			readOnlyTags: '=?',
			canRemoveTags: '=?',
			onTagRemoved: '&?',
		},
		controllerAs: 'machinetags',
		bindToController: true,
		template: `
		<div class="tags-cloud">
		<div ng-if="machinetags.readOnlyTags" class="tags-cloud-tag-container machine-tag-container" ng-repeat="tag in machinetags.readOnlyTags">
		<div class="tags-cloud-tag machine-tag-display machine-readonly-tag">
                <span class="machine-tag-text-view tags-cloud-tag-value">{{tag}}</span>
            </div>
		</div>
		<div
		ng-if="machinetags.totalTags && machinetags.totalTags.length > 0"
		class="tags-cloud-tag-container machine-tag-container" ng-repeat="tag in machinetags.totalTags track by $index">
			<div ng-if="tag.type == 'userdefined'" class="tags-cloud-tag machine-tag-display">
                <span class="tags-cloud-tag-value" ng-class="{'machine-tag-text-view' : !machinetags.canRemoveTags, 'machine-tags-edit': machinetags.canRemoveTags}">{{tag.value}}</span>
                <i ng-if="machinetags.canRemoveTags"
					rbacjs state="'disabled'" permissions="['alertsInvestigation']"
					class="icon icon-Cancel"
                	data-track-id="removeTag"
                	data-track-type="Button"
                	ng-click="machinetags.removeTag(tag)"></i>
            </div>
            <div ng-if="tag.type == 'builtin'" class="tags-cloud-tag machine-tag-display built-in-machine-tag machine-tag-display">
                <i class="icon icon-Lock"></i>
                <span class="tags-cloud-tag-value built-in-machine-tag">{{tag.value}}</span>
            </div>
        </div>
    </div>
		`,
		controller: machineTagsController,
	};
}

machineTagsController.$inject = ['$scope', 'appInsights'];

function machineTagsController($scope, appInsights: AppInsightsService) {
	var vm = this;
	appInsights.trackEvent('UsageTrack', {
		ButtonType: 'DeviceTagsHoverPane',
		Page: 'Device',
		Component: 'DeviceTagsHoverPane',
	});

	angular.extend(vm, {
		$onInit: activate,
		removeTag: removeTag,
	});

	function activate() {
		arrangeTags();

		$scope.$watch(
			function() {
				return vm.tags + vm.builtInTags;
			},
			function(newVal, oldVal) {
				if (newVal !== oldVal) {
					arrangeTags();
				}
			}
		);
	}

	function arrangeTags() {
		appInsights.trackEvent('UsageTrack', {
			ButtonType: 'MachineTagsHoverPane',
			Page: 'Device',
			Component: 'MachineTagsAddTag',
		});
		vm.totalTags = reduce(
			vm.tags,
			function(resultObj, value, key) {
				resultObj.unshift({ value: value, type: 'userdefined' });
				return resultObj;
			},
			[]
		);
	}

	function removeTag(tag) {
		if (vm.onTagRemoved) {
			vm.onTagRemoved()(tag);
			appInsights.trackEvent('UsageTrack', {
				ButtonType: 'DeviceTagsHoverPane',
				Page: 'Device',
				Component: 'DeviceTagsRemoveTag',
			});
		}
	}
}
