import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	TrackByFunction,
	ViewEncapsulation,
} from '@angular/core';
import { IContextualMenuItem } from 'office-ui-fabric-react';
import { isSameDay, subDays, subMonths, subWeeks } from 'date-fns';

import { DateRangeModel, TzDateService } from '@wcd/localization';

import {
	CustomTimeRangeValue,
	isCustomTimeRangeValue,
	TimeRangeId,
	TimeRangeValue,
} from './time-range-value.model';

@Component({
	selector: 'wcd-command-bar-date-picker',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./command-bar-date-picker.component.scss'],
	template: `
		<fab-command-bar-button
			data-track-id="DatePickerSelectTimeRange"
			data-track-type="Button"
			[text]="currentTimeRange.name"
			[disabled]="disabled"
			[iconProps]="{ iconName: 'Calendar' }"
			[styles]="{ root: { height: '100%' } }"
			[ariaLabel]="ariaDescription + currentTimeRange.name"
			[role]="'menuitem'"
		>
			<ng-container *ngIf="timeRanges">
				<ng-container *ngFor="let timeRange of timeRanges; trackBy: customTimeRangesTrackBy">
					<contextual-menu-item
						[key]="timeRange.id"
						[text]="timeRange.name"
						[data]="timeRange"
						[secondaryText]="calculateSecondaryText(timeRange)"
						(click)="onDateRangeChange(timeRange, !isCustomTimeRangeValue(timeRange))"
					></contextual-menu-item>
				</ng-container>
			</ng-container>
		</fab-command-bar-button>

		<wcd-date-range-picker
			*ngIf="customTimeRange"
			class="wcd-padding-small-left"
			[(dateRange)]="customTimeRange.customRange"
			[allowTimeSelection]="allowTimeSelection"
			[minDate]="minDate"
			[maxDate]="maxDate"
			(dateRangeChange)="onTimeRangeChange($event)"
		>
		</wcd-date-range-picker>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandBarDatePickerComponent implements OnInit {
	readonly isCustomTimeRangeValue = isCustomTimeRangeValue;

	@Input() timeRanges: ReadonlyArray<TimeRangeValue>;
	@Input() minDate?: Date;
	@Input() maxDate?: Date;
	@Input() disabled?: boolean;
	@Input() ariaDescription?: string;
	/**
	 * _Optional_ Time range to be set.
	 * Also allows two-way binding together with `currentTimeRangeChange`.
	 */
	@Input() currentTimeRange: TimeRangeValue;
	@Input() allowTimeSelection: boolean = true;
	@Input() useExactDays: boolean;
	@Input() sinceDateTimezone: string;

	@Output() readonly currentTimeRangeChange = new EventEmitter<TimeRangeValue>();
	@Output() readonly currentSelectionChange = new EventEmitter<TimeRangeValue>();

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly tzDateService: TzDateService
	) {}

	get customTimeRange(): CustomTimeRangeValue | null {
		if (this.currentTimeRange && isCustomTimeRangeValue(this.currentTimeRange)) {
			return this.currentTimeRange;
		}

		return null;
	}

	ngOnInit() {
		if (!this.currentTimeRange) {
			this.currentTimeRange = this.timeRanges[0];
		}
	}

	readonly customTimeRangesTrackBy: TrackByFunction<IContextualMenuItem> = (index, item) => item.key;

	onDateRangeChange(range: TimeRangeValue, externalNotify: boolean) {
		this.currentSelectionChange.emit(range);
		this.currentTimeRange = range;
		this.changeDetectorRef.detectChanges();

		if (externalNotify) {
			this.currentTimeRangeChange.emit(this.currentTimeRange);
		}
	}

	onTimeRangeChange(range: DateRangeModel) {
		this.currentTimeRange = {
			// Custom is already set from onDateRangeChange being called when the custom menu item itself was clicked
			...this.currentTimeRange,
			id: TimeRangeId.custom,
		} as CustomTimeRangeValue;

		this.changeDetectorRef.markForCheck();
		this.currentTimeRangeChange.emit(this.currentTimeRange);
	}

	calculateSecondaryText(range: TimeRangeValue): string {
		const formatDate = (date: Date) =>
			this.tzDateService.format(date, 'mediumDate', this.sinceDateTimezone);
		const getSinceText = (date: Date) => `since ${formatDate(date)}`;

		const now = new Date();

		if (isCustomTimeRangeValue(range)) {
			if (range.customRange.from && range.customRange.to) {
				if (isSameDay(now, range.customRange.to)) {
					return getSinceText(range.customRange.from);
				}

				return `${formatDate(range.customRange.from)} - ${formatDate(range.customRange.to)}`;
			}

			if (range.customRange.from) {
				return getSinceText(range.customRange.from);
			}
			if (range.customRange.to) {
				return getSinceText(range.customRange.to);
			}

			return '';
		}

		const rangeIdMap = {
			day: subDays(now, 1),
			'3days': subDays(now, 3),
			week: subWeeks(now, 1),
			month: subMonths(now, 1),
			'6months': subMonths(now, 6),
		};

		const sinceDate = this.useExactDays ? subDays(now, range.value) : rangeIdMap[range.id];
		return getSinceText(sinceDate);
	}
}
