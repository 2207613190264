import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { File } from '../file.entity';
import { EvidenceAlertsSummary } from '../../incident/evidence-alerts-summary.value-object';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: EvidenceAlertsSummary,
	allowedTypes: [RelationshipType.OneToOne],
	baseUrl: config => config.data.serviceUrls.threatIntel,
	getRelationshipData: (file: File) => ({ id: file.id }),
	endpoint: (_, query) => `evidence/file/${query.where['id']}/alerts`,
})
export class FileIncidentAlertsRelationship
	implements EntityRelationshipRepositoryType<File, EvidenceAlertsSummary> {}
