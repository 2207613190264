export enum MachineStatusType {
	'infected' = 0,
	'clean' = 1,
	'remediated' = 2,
	'unknown' = 3,
	'analyzing' = 4,
}

export const machineStatusValues = [
	{
		id: MachineStatusType.infected,
		type: 'infected',
		name: 'Infected',
		className: 'error',
		iconName: 'hosts.status.infected',
		priority: 0,
		isRunning: true,
		allowCancelInvestigation: true,
	},
	{
		id: MachineStatusType.clean,
		type: 'clean',
		name: 'Clean',
		className: 'success',
		iconName: 'hosts.status.clean',
		priority: 2,
		isRunning: false,
		allowCancelInvestigation: false,
	},
	{
		id: MachineStatusType.remediated,
		type: 'remediated',
		name: 'Remediated',
		className: 'success',
		iconName: 'hosts.status.remediated',
		priority: 1,
		isRunning: false,
		allowCancelInvestigation: false,
	},
	{
		id: MachineStatusType.unknown,
		type: 'unknown',
		name: 'Incomplete Analysis',
		className: 'gray-700',
		iconName: 'hosts.status.unavailable',
		priority: 3,
		isRunning: false,
		allowProtectionLevelChange: false,
		allowCancelInvestigation: true,
	},
	{
		id: MachineStatusType.analyzing,
		type: 'analyzing',
		name: 'Analyzing',
		className: 'primary',
		image: '/assets/images/icons/host-analyzing.svg',
		priority: 5,
		isRunning: true,
		allowCancelInvestigation: true,
	},
];
