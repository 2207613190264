
		<fab-command-bar
			data-track-component-type="Command Bar"
			[attr.data-track-component]="id + '_CommandBar'"
			[items]="getCommandBarItems()"
			[overflowItems]="isNarrowLayout ? _visibleIconOnlyOverflowItems : _visibleOverflowItems"
			[farItems]="getCommandBarFarItems()"
			[styles]="customStyles"
			[ariaLabel]="isAllOverflowItems() ? ('entityCommon.commandBar.instructions' | i18n) : ''"
			[overflowButtonProps]="{
				onMenuClick: setCurrentMenuButton,
				role: 'menuitem',
				ariaLabel: overflowButtonAriaLabel | i18n
			}"
		></fab-command-bar>
	