import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import {
	LegacyUser,
	LegacyUserLegacyUserProfileRelationship,
	LegacyUserProfile,
	AatpProfile,
	LegacyUserAatpProfileRelationship,
} from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { take, mergeMap, map } from 'rxjs/operators';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { ContentState } from '@wcd/contents-state';
import { Observable, of } from 'rxjs';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { isAccountLinkable } from '@wcd/scc-interface';

interface AatpDisplayData {
	count: number;
	url: string;
}

@Component({
	selector: 'user-entity-details',
	templateUrl: './user-entity-details.component.html',
})
export class UserEntityDetailsComponent extends EntityDetailsComponentBase<LegacyUser> implements OnInit {
	readonly EntityDetailsMode = EntityDetailsMode;
	readonly userType = LegacyUser;

	@Input() asKeyValueList = false;

	userProfile: LegacyUserProfile;
	userProfileContentState: ContentState = ContentState.Loading;
	aatpDisplayData: AatpDisplayData;
	aatpDisplayDataContentState: ContentState = ContentState.Loading;

	get isClickable(): boolean {
		return isAccountLinkable(this.user);
	}

	get user(): LegacyUser {
		return this.entity;
	}

	get showSecurityInfo(): boolean {
		return this.isPageOrSidePane;
	}

	get showNetworkActivity(): boolean {
		return this.isPageOrSidePane;
	}

	get showContactDetails(): boolean {
		return this.isPageOrSidePane;
	}

	get isPageOrSidePane(): boolean {
		return this.mode === EntityDetailsMode.EntityPage || this.mode === EntityDetailsMode.SidePane;
	}

	constructor(
		injector: Injector,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly advancedFeaturesService: AdvancedFeaturesService,
		private readonly paris: Paris
	) {
		super(injector);
	}

	ngOnInit() {
		this.userProfileContentState = ContentState.Loading;
		this.getUserProfile(this.user).subscribe(
			profile => {
				this.userProfileContentState = profile ? ContentState.Complete : ContentState.Empty;
				this.userProfile = profile;
				this.changeDetectorRef.markForCheck();
			},
			error => {
				this.userProfileContentState = ContentState.Error;
				this.changeDetectorRef.markForCheck();
			}
		);

		this.aatpDisplayDataContentState = ContentState.Loading;
		this.getAatpProfile(this.user).subscribe(
			data => {
				this.aatpDisplayDataContentState = data ? ContentState.Complete : ContentState.Empty;
				this.aatpDisplayData = data;
				this.changeDetectorRef.markForCheck();
			},
			error => {
				this.aatpDisplayDataContentState = ContentState.Error;
				this.changeDetectorRef.markForCheck();
			}
		);
	}

	private getUserProfile(user: LegacyUser): Observable<LegacyUserProfile> {
		return this.paris
			.getRelatedItem<LegacyUser, LegacyUserProfile>(LegacyUserLegacyUserProfileRelationship, user)
			.pipe(take(1));
	}

	private getAatpProfile(user: LegacyUser): Observable<AatpDisplayData> {
		return this.advancedFeaturesService.getAdvancedFeaturesSettings().pipe(
			mergeMap(advancedFeatures => {
				if (!advancedFeatures.aatpIntegrationEnabled || !advancedFeatures.aatpWorkspaceExists) {
					return of(null);
				}
				return this.paris
					.getRelatedItem<LegacyUser, AatpProfile>(LegacyUserAatpProfileRelationship, user)
					.pipe(
						map<AatpProfile, AatpDisplayData>(({ securityAlertSummary, url }) => ({
							count:
								securityAlertSummary.high +
								securityAlertSummary.medium +
								securityAlertSummary.low,
							url,
						}))
					);
			}),
			take(1)
		);
	}
}
