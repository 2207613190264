import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { TvmLicensesAngularService } from '@wcd/config';

@Injectable()
export class TvmLicensesGuard implements CanActivate {
	constructor(private tvmLicensesService: TvmLicensesAngularService) {}

	canActivate(route: ActivatedRouteSnapshot) {
		const data = route.routeConfig.data;
		return data && this.tvmLicensesService.isEnabled(data.tvmLicenseType);
	}
}
