/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/charts/src/lib/buttons-bar/buttons-bar.component.ngfactory";
import * as i2 from "../../../../../../../../../projects/charts/src/lib/buttons-bar/buttons-bar.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../../../projects/config/src/lib/components/enable-automated-ir.component.ngfactory";
import * as i5 from "../../../../../../../../../projects/config/src/lib/components/enable-automated-ir.component";
import * as i6 from "@angular/router";
import * as i7 from "./automated-investigations-statistics.widget";
import * as i8 from "../../../../../shared-reports/services/reports.service";
import * as i9 from "../../../../../../../../../projects/config/src/lib/services/features.service";
import * as i10 from "../../../../../../../../../projects/app-config/src/lib/service-urls/services/service-urls.service";
import * as i11 from "@microsoft/paris/dist/lib/paris";
import * as i12 from "../../../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i13 from "../../../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i14 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i15 from "../../../../../global_entities/services/global-entity-types.service";
var styles_AutomatedInvestigationsStatisticsWidget = [];
var RenderType_AutomatedInvestigationsStatisticsWidget = i0.ɵcrt({ encapsulation: 2, styles: styles_AutomatedInvestigationsStatisticsWidget, data: {} });
export { RenderType_AutomatedInvestigationsStatisticsWidget as RenderType_AutomatedInvestigationsStatisticsWidget };
function View_AutomatedInvestigationsStatisticsWidget_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "wcd-buttons-bar-widget", [], [[1, "data-track-component", 0], [8, "hidden", 0]], null, null, i1.View_ButtonsBarWidgetComponent_0, i1.RenderType_ButtonsBarWidgetComponent)), i0.ɵdid(1, 49152, null, 0, i2.ButtonsBarWidgetComponent, [], { buttons: [0, "buttons"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.data$)); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.widgetConfig.id; var currVal_1 = i0.ɵunv(_v, 0, 1, i0.ɵnov(_v, 3).transform(_co.error$)); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_AutomatedInvestigationsStatisticsWidget_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "enable-auto-ir", [], null, null, null, i4.View_EnableAutomatedIrComponent_0, i4.RenderType_EnableAutomatedIrComponent)), i0.ɵdid(2, 180224, null, 0, i5.EnableAutomatedIrComponent, [i6.ActivatedRoute], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, null); }
export function View_AutomatedInvestigationsStatisticsWidget_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutomatedInvestigationsStatisticsWidget_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["enableAutoIr", 2]], null, 0, null, View_AutomatedInvestigationsStatisticsWidget_2)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appConfigService.isAutomatedIrEnabled; var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AutomatedInvestigationsStatisticsWidget_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "automated-investigations-statistics-widget", [], null, null, null, View_AutomatedInvestigationsStatisticsWidget_0, RenderType_AutomatedInvestigationsStatisticsWidget)), i0.ɵdid(1, 245760, null, 0, i7.AutomatedInvestigationsStatisticsWidget, [i8.ReportsService, i9.FeaturesService, i10.ServiceUrlsService, i11.Paris, i12.AppConfigService, i13.PrettyNumberService, i14.I18nService, i15.GlobalEntityTypesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AutomatedInvestigationsStatisticsWidgetNgFactory = i0.ɵccf("automated-investigations-statistics-widget", i7.AutomatedInvestigationsStatisticsWidget, View_AutomatedInvestigationsStatisticsWidget_Host_0, {}, {}, []);
export { AutomatedInvestigationsStatisticsWidgetNgFactory as AutomatedInvestigationsStatisticsWidgetNgFactory };
