import { Observable, of, isObservable, from } from 'rxjs';
import { groupBy, isNil, uniq } from 'lodash-es';
import { map } from 'rxjs/operators';
import { FilterValuesChecklistValueData } from './checklist/filter-values.checklist.component';
import { FilterValuesListConfig } from './list-filter-values-component-config.interface';
import { ListFilterValueConfig, ListFilterValueIcon } from './list-filter-value-config.interface';
import { ListFilterValueCategory } from './list-filter-value-category.model';
import { I18nService } from '@wcd/i18n';

const EMPTY_FILTER_VALUE_KEY = 'list_filter_empty_value';

export class ListFilterValue<TValue extends boolean | string | number, TData = any>
	implements ListFilterValueConfig<TValue> {
	count = 0;
	rawValue: TValue;
	id: TValue;
	name: string;
	nameClass: string;
	icon: ListFilterValueIcon;
	image: string;
	className: string;
	priority: number;
	category?: string;
	childCategories: Array<ListFilterValueCategory>;
	data: TData;

	get hasChildren(): boolean {
		return this.childCategories && !!this.childCategories.length;
	}

	private i18nService: I18nService;

	constructor(data?: ListFilterValueConfig<TValue>) {
		this.i18nService = new I18nService();
		if (data) {
			Object.assign(this, data);
			if (this.id === undefined) {
				if (this.rawValue !== undefined) this.id = this.rawValue;
				else throw new Error("Can't create ListFilterValue, no id or rawValue provided");
			}

			if (typeof data.icon === 'string') this.icon = { iconName: data.icon };
			if (data.children) {
				const children = data.children.map(child => new ListFilterValue(child));
				this.setChildrenCategories(children);
			}
		}
	}

	get displayName(): string {
		if (!isNil(this.name)) return this.name || this.i18nService.get(EMPTY_FILTER_VALUE_KEY);

		if (!isNil(this.id)) return this.id.toString() || this.i18nService.get(EMPTY_FILTER_VALUE_KEY);

		return this.i18nService.get(EMPTY_FILTER_VALUE_KEY);
	}

	get paramValue(): string {
		return String(this.id);
	}

	static fromValue<TValue extends string | number | boolean = any>(
		value: FilterValuesChecklistValueData<TValue> | TValue | ListFilterValue<TValue>,
		config: FilterValuesListConfig<TValue> = {}
	): Observable<ListFilterValue<TValue>> {
		if (value instanceof ListFilterValue) return of(value);

		const { mapFilterValue } = config;

		const valueObj: FilterValuesChecklistValueData<TValue> =
			typeof value === 'object' ? value : { value: value };

		const filterConfig$ =
			(mapFilterValue && mapFilterValue(valueObj.value, valueObj)) ||
			ListFilterValue.valueToListValueConfig(valueObj);

		const filterConfigObservable$: Observable<ListFilterValueConfig<TValue>> = isObservable(filterConfig$)
			? filterConfig$
			: of(filterConfig$);

		return filterConfigObservable$.pipe(
			map((filterConfig: ListFilterValueConfig<TValue>) => {
				const filterValue: ListFilterValue<TValue> = new ListFilterValue<TValue>(filterConfig);

				filterValue.count = valueObj.count;

				return filterValue;
			})
		);
	}

	private static valueToListValueConfig<TValue extends string | number | boolean = any>(
		rawValue: FilterValuesChecklistValueData<TValue>
	): ListFilterValueConfig<TValue> {
		return {
			id: rawValue.value,
			rawValue: rawValue.value,
			priority: rawValue.priority,
			name: rawValue.name,
			category: rawValue.category,
			children: rawValue.children && rawValue.children.map(child => this.valueToListValueConfig(child)),
			data: rawValue.custom,
		};
	}

	setChildrenCategories(children: Array<ListFilterValue<TValue>>) {
		const categories: Record<string, Array<ListFilterValue<TValue>>> = groupBy(
			children,
			child => child.category || ''
		);
		const orderedCategoryNames: Array<string> = uniq(children.map(child => child.category || ''));

		this.childCategories = orderedCategoryNames.map(name => ({ name, values: categories[name] }));
	}
}
