import { EntityRelationship, RelationshipType, EntityRelationshipRepositoryType } from '@microsoft/paris';
import { RecommendationExceptionAggregated } from './recommendation-exception-aggregated.entity';
import { RecommendationException } from './recommendation-exception.entity';
import { TvmRemediationSharedEntityConfigurations } from '../remediationEndPointUtils';

@EntityRelationship({
	...TvmRemediationSharedEntityConfigurations,
	sourceEntity: RecommendationExceptionAggregated,
	dataEntity: RecommendationException,
	endpoint: (_, query) => `aggregatedExceptions/${encodeURIComponent(query.where['id'])}/exceptions`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (recommendationExceptionAggregated: RecommendationExceptionAggregated) => {
		return { id: recommendationExceptionAggregated.id };
	},
})
export class RecommendationExceptionAggregatedRelationship
	implements EntityRelationshipRepositoryType<RecommendationExceptionAggregated, RecommendationException> {}
