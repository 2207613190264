
		<div [ngClass]="nodeClass" [ngStyle]="nodeStyle" [attr.role]="ariaRole">
			<wcd-expander-button
				[label]="label"
				[labelClass]="labelClass"
				[(isExpanded)]="isExpanded"
				[toggleOnTitleClick]="toggleOnTitleClick"
				[arrowPosition]="arrowPosition"
				(labelClicked)="labelClicked.emit($event)"
				(isExpandedChange)="onExpandedChange($event)"
				[ariaRole]="buttonRole"
				[ariaLabel]="ariaLabel"
				[attr.role]="'presentation'"
				[iconName]="iconName"
				[regionId]="regionId"
				[buttonId]="buttonId"
			>
				<ng-content select="[wcd-expander-title]" ngProjectAs="[wcd-expander-title]"></ng-content>
			</wcd-expander-button>
		</div>
		<div
			class="wcd-expander-content"
			[ngClass]="collapsibleClass"
			[wcdCollapse]="!isExpanded"
			role="region"
			[attr.id]="regionId"
			[attr.aria-labelledby]="buttonId"
		>
			<div>
				<!--[Added "div" to "ng-content", otherwise the expander sometimes will not get his size properly]-->
				<ng-content></ng-content>
			</div>
		</div>
	