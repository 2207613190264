import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { TreeElement } from './tree-element';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { TreeElementAlertContext } from './tree-element-alert-context';
import { AlertUser } from '../alert-user.value-object';

@Entity({
	singularName: 'Process Tree',
	pluralName: 'Process Trees',
	endpoint: 'AlertPageData',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.cyberData,
	parseItemQuery: (itemId: string, entity: any, config, params: {[index: string]: any;}) => {
		return `AlertPageData?
					alertId=${itemId}
					${params['correlationId'] ? '&correlationId=' + params['correlationId'] : ''}
					${params['officeTenantPrefix'] ? '&officeTenantPrefix=' + params['officeTenantPrefix'] : ''}`;
	},
})
export class ProcessTree extends EntityModelBase {
	@EntityField({ arrayOf: TreeElement })
	treeElements: Array<TreeElement>;

	@EntityField()
	totalSize: number;

	@EntityField()
	maxDepth: number;

	@EntityField({ arrayOf: TreeElementAlertContext })
	associatedAlerts: Array<TreeElementAlertContext>;

	@EntityField()
	mainUser?: AlertUser;

	@EntityField()
	mainAlertId?: string;
}
