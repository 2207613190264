
		<div class="wcd-full-height wcd-flex-vertical">
			<div *ngIf="error && !loading" class="wcd-padding-left">
				<wcd-shared-icon iconName="error"></wcd-shared-icon>
				{{ 'alert.tabs.tree.error' | i18n }}
			</div>
			<div class="wcd-padding-all" *ngIf="!loading && missingEventInfo">
				<div>
					{{
						'alert.tabs.tree.missingEvents.relatedEvents'
							| i18n: { events: missingEventInfo.events }
					}}
				</div>
				<div>
					{{
						'alert.tabs.tree.missingEvents.lastEventTime'
							| i18n: { time: missingEventInfo.spanningTo }
					}}
				</div>
				<div>
					{{ 'alert.tabs.tree.missingEvents.clickForTimelinePreLink' | i18n }}
					<a [href]="missingEventInfo.machineUrl">{{
						'alert.tabs.tree.missingEvents.clickForTimelineLink' | i18n
					}}</a>
					{{ 'alert.tabs.tree.missingEvents.clickForTimelinePostLink' | i18n }}
				</div>
			</div>
			<div #treeContainer id="processTree-container" class="wcd-flex-1 wcd-scroll-vertical">
				<div *ngIf="loading" class="wcd-full-height loading-overlay">
					<i class="large-loader-icon"></i>
				</div>
			</div>
		</div>
	