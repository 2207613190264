import { AuthService, MtpPermission } from '@wcd/auth';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommentModel } from '../../../comments/models/comment.model';
import { finalize } from 'rxjs/operators';
import { AlertsService } from '../services/alerts.service';
import { Alert } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SnackBarsService } from '../../../dialogs/snackbars/services/snackbars.service';
import { BehaviorSubject } from 'rxjs';
import { RbacControlSettings, RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { flatMap, uniq } from 'lodash-es';

@Component({
	selector: 'alerts-comments',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<editable-comments-list
			[comments]="comments"
			[isSaving]="isSavingComment"
			[readonly]="readonly"
			[rbac]="rbacSettings"
			[isVisibleSnackBar$]="isVisibleSnackBar$"
			(save)="saveComment($event)"
		></editable-comments-list>
	`,
})
export class AlertsCommentsComponent implements OnInit, OnChanges {
	@Input() comments: Array<CommentModel>;
	@Input() alerts: Array<Alert>;
	@Input() readonly: boolean;

	isSavingComment: boolean = false;
	isVisibleSnackBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	rbacSettings: RbacControlSettings;

	constructor(
		private alertsService: AlertsService,
		private changeDetectorRef: ChangeDetectorRef,
		private authService: AuthService,
		private i18nService: I18nService,
		private liveAnnouncer: LiveAnnouncer,
		private readonly snackbarsService: SnackBarsService
	) {
		this.isVisibleSnackBar$ = snackbarsService.isVisibleSnackBar$;

	}

	ngOnInit() {
		this.setRbacModel();
	}

	ngOnChanges(changes) {
		if (changes.alerts) this.setRbacModel();
	}

	setRbacModel() {
		if (this.alerts && this.alerts.length) {
			const workloads = uniq(flatMap(this.alerts.map(alert => alert.mtpWorkloads)));

			this.rbacSettings = {
				mtpPermissions: [MtpPermission.SecurityData_Manage],
				mtpWorkloads: workloads,
				requireAllPermissions: true,
				state: RbacControlState.disabled,
			};
		}
	}

	saveComment(comment: string): void {
		this.isSavingComment = true;
		this.alertsService
			.addCommentToAlerts(this.alerts, comment)
			.pipe(finalize(() => (this.isSavingComment = false)))
			.subscribe(() => {
				const newComment = new CommentModel({
					id: new Date().valueOf(),
					user: this.authService.currentUser.name,
					message: comment,
					timestamp: new Date(),
				});
				this.comments = [newComment, ...(this.comments || [])];
				this.liveAnnouncer.announce(
					this.i18nService.get('comments.save.success', {
						date: newComment.timestamp,
					})
				);
				this.changeDetectorRef.markForCheck();
			});
	}
}
