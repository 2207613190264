var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
export var CustomTiIndicatorIdentifierTypes;
(function (CustomTiIndicatorIdentifierTypes) {
    CustomTiIndicatorIdentifierTypes[CustomTiIndicatorIdentifierTypes["FileSha1"] = 0] = "FileSha1";
    CustomTiIndicatorIdentifierTypes[CustomTiIndicatorIdentifierTypes["FileSha256"] = 1] = "FileSha256";
    CustomTiIndicatorIdentifierTypes[CustomTiIndicatorIdentifierTypes["FileMd5"] = 2] = "FileMd5";
    CustomTiIndicatorIdentifierTypes[CustomTiIndicatorIdentifierTypes["IpAddress"] = 3] = "IpAddress";
    CustomTiIndicatorIdentifierTypes[CustomTiIndicatorIdentifierTypes["DomainName"] = 4] = "DomainName";
    CustomTiIndicatorIdentifierTypes[CustomTiIndicatorIdentifierTypes["Url"] = 5] = "Url";
    CustomTiIndicatorIdentifierTypes[CustomTiIndicatorIdentifierTypes["CertificateThumbprint"] = 6] = "CertificateThumbprint";
    CustomTiIndicatorIdentifierTypes[CustomTiIndicatorIdentifierTypes["ProcessMemorySimilarityHash"] = 7] = "ProcessMemorySimilarityHash";
})(CustomTiIndicatorIdentifierTypes || (CustomTiIndicatorIdentifierTypes = {}));
var ɵ0 = CustomTiIndicatorIdentifierTypes.FileSha1, ɵ1 = CustomTiIndicatorIdentifierTypes.FileSha256, ɵ2 = CustomTiIndicatorIdentifierTypes.FileMd5, ɵ3 = CustomTiIndicatorIdentifierTypes.IpAddress, ɵ4 = CustomTiIndicatorIdentifierTypes.Url, ɵ5 = CustomTiIndicatorIdentifierTypes.DomainName, ɵ6 = CustomTiIndicatorIdentifierTypes.CertificateThumbprint, ɵ7 = CustomTiIndicatorIdentifierTypes.ProcessMemorySimilarityHash;
var CustomTiIndicatorIdentifierType = /** @class */ (function (_super) {
    __extends(CustomTiIndicatorIdentifierType, _super);
    function CustomTiIndicatorIdentifierType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], CustomTiIndicatorIdentifierType.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], CustomTiIndicatorIdentifierType.prototype, "type", void 0);
    CustomTiIndicatorIdentifierType = __decorate([
        Entity({
            singularName: 'Custom Ti Indicator Identifier Type',
            pluralName: 'Custom Ti Indicator Identifier Types',
            values: [
                {
                    id: ɵ0,
                    name: 'SHA1',
                    type: 'files',
                },
                {
                    id: ɵ1,
                    name: 'SHA256',
                    type: 'files',
                },
                {
                    id: ɵ2,
                    name: 'MD5',
                    type: 'files',
                },
                {
                    id: ɵ3,
                    name: 'IP',
                    type: 'ip',
                },
                {
                    id: ɵ4,
                    name: 'Url',
                    type: 'url',
                },
                {
                    id: ɵ5,
                    name: 'Domain',
                    type: 'url',
                },
                {
                    id: ɵ6,
                    name: 'Certificate',
                    type: 'certificate',
                },
                {
                    id: ɵ7,
                    name: 'ProcessMemory',
                    type: 'memory_content',
                },
            ],
        })
    ], CustomTiIndicatorIdentifierType);
    return CustomTiIndicatorIdentifierType;
}(EntityModelBase));
export { CustomTiIndicatorIdentifierType };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
