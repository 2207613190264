import { Injectable } from '@angular/core';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { InvestigationStatus, LiveResponseSession, MtpInvestigation } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { MtpInvestigationGraphService } from '../../../graph/mtp-investigation/services/mtp-investigation-graph.service';
import { distinctUntilChanged, switchMap, takeWhile } from 'rxjs/operators';
import { MtpInvestigationsService } from './mtp-investigations.service';
import { WcdIconNames } from '@wcd/icons';
import { AppContextService, Feature, FeaturesService, PollingService } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class MtpInvestigationEntityTypeService implements EntityTypeService<MtpInvestigation> {
	constructor(
		private paris: Paris,
		private mtpInvestigationsService: MtpInvestigationsService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private appContextService: AppContextService,
		private appConfigService: AppConfigService,
		private pollingService: PollingService,
		private featuresService: FeaturesService
	) {}

	readonly entityType: EntityType<MtpInvestigation> = {
		id: 'mtp-investigation',
		icon: WcdIconNames.investigationoutline,
		entity: MtpInvestigation,
		getEntityPolling: entityGetter$ =>
			this.pollingService.poll(5000, 5000).pipe(
				switchMap(() => entityGetter$),
				distinctUntilChanged(
					(prev, curr) =>
						!Object.keys(MtpInvestigationGraphService.getInvestigationDifference(prev, curr))
							.length
				),
				takeWhile(i => i.isRunning, true)
			),
		getItemParams: (entity, options) => ({ tenantId: sccHostService.loginUser.tenantId }),
		getImage: (investigations: Array<MtpInvestigation>) => {
			return GlobalEntityTypesService.getCommonValue(
				investigations,
				investigation => investigation.status.image
			);
		},
		getIcon: (investigations: Array<MtpInvestigation>) => {
			return (
				GlobalEntityTypesService.getCommonValue(investigations, investigation => {
					if (investigation.isLiveResponse) {
						return this.globalEntityTypesService.getEntityTypeIcon(LiveResponseSession);
					}
					return investigation.status && investigation.status.iconName;
				}) || WcdIconNames.investigationoutline
			);
		},
		getIconCssClass: (investigations: Array<MtpInvestigation>) => {
			const commonStatus: InvestigationStatus = GlobalEntityTypesService.getCommonValue(
				investigations,
				investigation => investigation.status
			);
			if (commonStatus) return `color-text-${commonStatus.className}`;
		},
		getEntityClassName: (investigation: MtpInvestigation) => investigation.status.className,
		getEntityName: (investigation: MtpInvestigation) => investigation.title,
		getEntitiesLink: (investigations: Array<MtpInvestigation>) =>
			investigations && investigations.length === 1
				? MtpInvestigationsService.getInvestigationLink(investigations[0])
				: null,
		getNavigationModel: (investigation: MtpInvestigation) => {
			if (this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence)) {
				return (
					investigation.id && {
						routerLink: [this.entityType.getEntitiesLink([investigation])],
					}
				);
			} else {
				return (
					(investigation.externalInvestigationPageUrl || investigation.id) && {
						externalLink: investigation.externalInvestigationPageUrl,
						routerLink: !investigation.externalInvestigationPageUrl
							? [this.entityType.getEntitiesLink([investigation])]
							: null,
						openInNewTab:
							this.appContextService.isMtp &&
							Boolean(investigation.externalInvestigationPageUrl),
					}
				);
			}
		},
		getActions: (investigations: Array<MtpInvestigation>) =>
			this.mtpInvestigationsService.getInvestigationsActions(investigations),
	};
}
