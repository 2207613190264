export const antiVirusStatuses = [
	{
		id: 'AntiVirusNotReporting',
		name: 'Not reporting',
		priority: 1,
	},
	{
		id: 'AntiVirusEnabled',
		name: 'Disabled',
		priority: 2,
	},
	{
		id: 'AntiVirusSignatureVersion',
		name: 'Not updated',
		priority: 3,
	},
];
