
		<section
			*ngIf="undoneActionId || undoActionId"
			data-track-component="ActionHistoryEntityPanel"
			class="wcd-padding-vertical wcd-padding-large-horizontal"
		>
			<span *ngIf="undoneActionId">
				{{ i18nService.strings.actionsHistory_undo_undone_undoAction }}
				<a data-track-id="OpenOriginalAction" data-track-type="Action" (click)="openOriginalAction()">
					{{
						'actionHistory_panel_actionId'
							| i18n
								: {
										actionId: undoneActionId
								  }
					}}
				</a>
			</span>
			<span *ngIf="undoActionId">
				{{ i18nService.strings.actionsHistory_undo_undone_originalAction }}
				<a data-track-id="OpenUndoAction" data-track-type="Action" (click)="openUndoAction()">
					{{
						'actionHistory_panel_actionId'
							| i18n
								: {
										actionId: undoActionId
								  }
					}}
				</a>
			</span>
		</section>

		<div *ngIf="actionHistory.actionFailureI18nKey" class="wcd-padding-large-horizontal">
			<ng-container *ngIf="showLinkInFailureBanner; else simpleFailureText">
				<fab-message-bar [messageBarType]="messageBarType.warning">
					<markdown>
						{{
							i18nService.get(
								'actionHistory_panel_failureBanner_' + actionHistory.actionFailureI18nKey,
								{
									url: manageActionAccountUrl
								},
								true
							) || actionHistory.actionFailureI18nKey
						}}
					</markdown>
				</fab-message-bar>
			</ng-container>
			<ng-template #simpleFailureText>
				<fab-message-bar [messageBarType]="messageBarType.warning">
					{{
						i18nService.get(
							'actionHistory_panel_failureBanner_' + actionHistory.actionFailureI18nKey,
							null,
							true
						) || actionHistory.actionFailureI18nKey
					}}
				</fab-message-bar>
			</ng-template>
		</div>

		<action-history-base-panel
			[entity]="entity"
			[options]="options"
			[isInit]="isInit"
			[moreActionsFilter]="moreActionsFilter"
			[moreActionsEnabled]="moreActionsEnabled"
			(reset)="onResetData()"
			(investigationActionLoad)="onInvestigationActionLoad($event)"
			(requestMetadataRefresh)="requestMetadataRefresh.emit()"
		>
			<ng-container action-post-description>
				<wcd-collapsible-section
					[label]="i18nService.strings.actionHistory_panel_actionDetails"
					[sectionId]="'action-history-panel-action-Details'"
				>
					<action-history-details [actionHistory]="actionHistory"></action-history-details>
				</wcd-collapsible-section>
			</ng-container>
		</action-history-base-panel>
	