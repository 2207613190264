import { ChangeDetectorRef, Component } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { SuppressionRule } from '@wcd/domain';

@Component({
	selector: 'suppression-rule-entity-panel',
	template: `
		<div class="wcd-full-height">
			<section class="wcd-padding-vertical">
				<suppression-rule-entity-details [entity]="rule" [isSuppressionRuleEntityPanel]="true"></suppression-rule-entity-details>
			</section>
		</div>
	`,
})
export class SuppressionRuleEntityPanelComponent extends EntityPanelComponentBase<SuppressionRule> {
	get rule(): SuppressionRule {
		return this.entity;
	}

	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}
}
