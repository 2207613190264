import { NgModule } from '@angular/core';
import { FabCommandBarModule } from '@angular-react/fabric';
import { SharedModule } from '../shared/shared.module';
import { PromotionBannerComponent } from './components/promotion-banner.component';

@NgModule({
	imports: [SharedModule, FabCommandBarModule],
	declarations: [PromotionBannerComponent],
	exports: [PromotionBannerComponent],
})
export class MtpPromotionModule {}
