<wcd-panel (close)="destroy()" [settings]="settings">
	<div class="wcd-padding-horizontal wcd-full-height">
		<div class="wcd-flex-1 wcd-flex-vertical wcd-padding-medium-right">
			<label for="panel-header"></label>
			<h2 id="panel-header" class="wcd-font-size-xxl wcd-font-weight-bold">
					{{ 'urls_fields_domain_disputeCategory_panel_header' | i18n }}
			</h2>
			<div class="wcd-margin-largeMedium-top wcd-margin-small-bottom">
				<dl role="none">
					<dt>{{ 'urls_fields_domain_disputeCategory_panel_description_header' | i18n }}</dt>
					<dd>{{ 'urls_fields_domain_disputeCategory_panel_description' | i18n }}</dd>
				</dl>
				<dl role="none">
					<dt>{{ 'urls_fields_domain_disputeCategory_panel_description_domain_header' | i18n  }}</dt>
					<dd>{{ domain }}</dd>
				</dl>
				<dl class="asKeyValueList" role="none">
					<dt>{{ 'urls_fields_domain_disputeCategory_panel_priority_header' | i18n}}</dt>
					<dd role="none">
						<fancy-select
							class="command-bar-item-dropdown fullWidthDropdown"
							[(ngModel)]="currentPrioritySelected"
							(ngModelChange)="updatePrioritySelected($event)"
							[isBordered]="true"
							[values]="priorityValues"
							[isFullWidth]="true"
							[ariaLabel]="('machines_entityDetails_actions_machineValue_title' | i18n)"
							[dropdownAreaRole]="'combobox'"
							>
						</fancy-select>
					</dd>
				</dl>
				<dl role="none">
					<dt>{{ 'urls_fields_domain_disputeCategory_panel_tellUsMore_header' | i18n}}</dt>
					<dd>
						<textarea
							class="form-control wcd-padding-small-all wcd-margin-small-bottom wcd-full-width"
							[(ngModel)]="comments"
							maxlength="300"
							rows="3"
						></textarea>
					</dd>
				</dl>
			</div>
		</div>
		<div class="wcd-flex-none">
			<footer class="wcd-flex-horizontal wcd-margin-large-top">
				<fab-primary-button
					[contentClass]="isSending ? 'wcd-width-small' : ''"
					[text]="isSending ? '' : ('buttons.submit' | i18n)"
					(onClick)="submitDispute()"
					[disabled]="isSending">
					<fab-spinner *ngIf="isSending" [size]="SpinnerSize.xSmall"></fab-spinner>
				</fab-primary-button>
			</footer>
		</div>
	</div>
</wcd-panel>
