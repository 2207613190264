import { combineLatest, defer, of } from 'rxjs';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { BreadcrumbsService } from './breadcrumbs.service';
import { compact } from 'lodash-es';
import { ActivatedEntity } from '../../global_entities/services/activated-entity.service';
import { EntityIdRouteService } from '../../global_entities/services/entity-id-route.service';
import { AppContextService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { RoutesService } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';
import { toObservable } from '../../utils/rxjs/utils';
import * as i0 from "@angular/core";
import * as i1 from "@microsoft/paris/dist/lib/paris";
import * as i2 from "../../global_entities/services/global-entity-types.service";
import * as i3 from "./breadcrumbs.service";
import * as i4 from "../../global_entities/services/activated-entity.service";
import * as i5 from "../../global_entities/services/entity-id-route.service";
import * as i6 from "../../../../../../projects/config/src/lib/services/app-context.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../../../../projects/shared/src/lib/services/routes.service";
import * as i9 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
/**
 * Breadcrumb resolver: creates breadcrumb using entity type service and Paris getItemById if needed.
 * Can be used for entities with\without global entityType service.
 * Fallbacks to hard-coded route data config.
 */
var BreadcrumbsResolver = /** @class */ (function () {
    function BreadcrumbsResolver(paris, globalEntityTypesService, breadcrumbsService, activatedEntity, entityIdRouteService, appContext, router, routesService, i18nService) {
        this.paris = paris;
        this.globalEntityTypesService = globalEntityTypesService;
        this.breadcrumbsService = breadcrumbsService;
        this.activatedEntity = activatedEntity;
        this.entityIdRouteService = entityIdRouteService;
        this.appContext = appContext;
        this.router = router;
        this.routesService = routesService;
        this.i18nService = i18nService;
    }
    BreadcrumbsResolver.prototype.resolve = function (route, state) {
        var routeWithBreadcrumbsConfig = this.breadcrumbsService.getRouteWithBreadcrumbsConfig(route);
        if (!routeWithBreadcrumbsConfig)
            return of(null);
        return combineLatest(routeWithBreadcrumbsConfig.data['breadcrumbsConfig'] &&
            routeWithBreadcrumbsConfig.data['breadcrumbsConfig']['addParentWhenEmpty']
            ? this.createBreadcrumb(routeWithBreadcrumbsConfig, state, true)
            : of(null), this.createBreadcrumb(routeWithBreadcrumbsConfig, state)).pipe(map(compact));
    };
    /**
     * creates a breadcrumb item out of route data
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @param {boolean} isForParent: if set to 'true', returns a breadcrumb item of the route's 'parent' item. for example, "all alerts" for route "alert item".
     * @returns {Observable<Breadcrumb>}
     * @private
     */
    BreadcrumbsResolver.prototype.createBreadcrumb = function (route, state, isForParent) {
        var _this = this;
        var breadcrumbsRouteConfig = route.data['breadcrumbsConfig'];
        if (!breadcrumbsRouteConfig || breadcrumbsRouteConfig.addItemOnEnter === false)
            return of(null);
        var entityType = this.globalEntityTypesService.getEntityType(route.data.entity);
        var breadcrumbItemConfig = (isForParent && breadcrumbsRouteConfig && breadcrumbsRouteConfig.parentItemConfig) ||
            breadcrumbsRouteConfig.itemConfig;
        var entity = (entityType && entityType.entity) || (route.data && route.data.entity);
        if (!entity && !breadcrumbItemConfig)
            throw new Error('Missing details for breadcrumbs.');
        var routeId = this.entityIdRouteService.getRouteId({
            route: route,
            itemIdParamName: breadcrumbItemConfig && breadcrumbItemConfig.idFieldName,
        });
        if (routeId && !isForParent) {
            // create breadcrumbs for entity
            var isRecentEntityPopulated = this.activatedEntity.recentEntity &&
                this.activatedEntity.recentEntity.constructor === route.data.entity &&
                this.activatedEntity.recentEntity.id === routeId;
            var params$ = defer(function () {
                return toObservable(entityType && entityType.getItemParams
                    ? entityType.getItemParams(route.data.entity, { routeId: routeId })
                    : null);
            });
            var entity$ = isRecentEntityPopulated
                ? of(this.activatedEntity.recentEntity)
                : params$.pipe(switchMap(function (params) { return _this.getEntity(routeId, route, state, params); }));
            return entity$.pipe(first(), map(function (item) {
                var url = (entityType && entityType.getEntitiesLink && entityType.getEntitiesLink([item])) ||
                    (breadcrumbItemConfig && breadcrumbItemConfig.url) ||
                    state.url.split('?')[0];
                return {
                    id: (entity && entity.singularName.toLowerCase() + "_" + item.id) ||
                        (breadcrumbItemConfig && breadcrumbItemConfig.id) ||
                        item.id,
                    label: _this.getItemLabel(item, entityType, breadcrumbItemConfig),
                    url: url,
                    queryParams: route.queryParams,
                    sccBCConfig: _this.getSCCConfig(url),
                };
            }));
        }
        else {
            // create breadcrumbs for dataview
            var url = (entityType && entityType.getEntityDataviewLink && entityType.getEntityDataviewLink()) ||
                (breadcrumbItemConfig && breadcrumbItemConfig.url) ||
                state.url.split('?')[0];
            return of({
                id: (entity && entity.pluralName.toLowerCase()) ||
                    (breadcrumbItemConfig && breadcrumbItemConfig.id),
                label: this.getParentLabel(entity, entityType, breadcrumbItemConfig),
                url: url,
                queryParams: isForParent ? {} : route.queryParams,
                sccBCConfig: this.getSCCConfig(url),
            });
        }
    };
    BreadcrumbsResolver.prototype.getSCCConfig = function (link) {
        if (!this.appContext.isSCC)
            return null;
        var inSccLink = link;
        try {
            inSccLink = this.routesService.getMdatpFromSccLinkConfig(link).url;
        }
        catch (e) {
            console.warn('getMdatpFromSccLinkConfig returned null inside SCC');
        }
        inSccLink = inSccLink[0] === '/' ? inSccLink.substr(1) : inSccLink;
        //Split on first /
        var _a = inSccLink.split(/\/(.+)?/), base = _a[0], path = _a[1];
        if (!path) {
            return { state: base };
        }
        // We have do handle paths where the page part contains two sections: endpoints/<specific page name>. This is the case for
        // some of MDATP pages inside SCC portal
        if (base === 'endpoints') {
            var _b = path.split(/\/(.+)?/), endpointsPage = _b[0], endpointsPagePath = _b[1];
            if (!endpointsPagePath) {
                return {
                    state: base + "/" + endpointsPage,
                };
            }
            return {
                state: base + "/" + endpointsPage + ".child",
                params: {
                    path: endpointsPagePath,
                },
            };
        }
        return {
            state: base + ".child",
            params: {
                path: path,
            },
        };
    };
    BreadcrumbsResolver.prototype.getEntity = function (entityId, route, state, params) {
        var _this = this;
        var entityType = this.globalEntityTypesService.getEntityType(route.data.entity);
        var encodedEntityId = entityType && entityType.getEncodedEntityId ? entityType.getEncodedEntityId(entityId) : entityId;
        // Since the root route also uses this breadcrumb resolver, we add an option to ignore getting the entity
        // on the root router. It will read this from the route config (the specific route config for the entity) and
        // returns an empty entity. This is used by the Url entity for example , where in the UrlBreadcrumb resolver
        // we have a different way to load the entity.
        var breadcrumbsRouteConfig = route.data['breadcrumbsConfig'];
        if (breadcrumbsRouteConfig.ignoreRootResolver) {
            return of({ id: encodedEntityId });
        }
        return this.paris.getItemById(route.data.entity, encodedEntityId, null, params).pipe(catchError(function (error) {
            _this.appContext.isSCC
                ? setTimeout(function () { return sccHostService.state.go('Error.child', { path: '404' }); })
                : _this.router.navigateByUrl('/Error/404');
            return of(null);
        }));
    };
    BreadcrumbsResolver.prototype.getItemLabel = function (item, entityType, breadcrumbItemConfig) {
        return ((entityType &&
            ((entityType.getEntityName && entityType.getEntityName(item)) ||
                (entityType.entitySingularNameKey &&
                    this.i18nService.get(entityType.entitySingularNameKey)))) ||
            (breadcrumbItemConfig &&
                ((breadcrumbItemConfig.labelKey && this.i18nService.get(breadcrumbItemConfig.labelKey)) ||
                    breadcrumbItemConfig.label)) ||
            item['name'] ||
            item.id);
    };
    BreadcrumbsResolver.prototype.getParentLabel = function (entity, entityType, breadcrumbItemConfig) {
        return ((entity &&
            entityType &&
            entityType.entityPluralNameKey &&
            this.i18nService.get(entityType.entityPluralNameKey)) ||
            (entity && entity.pluralName) ||
            (breadcrumbItemConfig &&
                ((breadcrumbItemConfig.labelKey && this.i18nService.get(breadcrumbItemConfig.labelKey)) ||
                    breadcrumbItemConfig.label)));
    };
    BreadcrumbsResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BreadcrumbsResolver_Factory() { return new BreadcrumbsResolver(i0.ɵɵinject(i1.Paris), i0.ɵɵinject(i2.GlobalEntityTypesService), i0.ɵɵinject(i3.BreadcrumbsService), i0.ɵɵinject(i4.ActivatedEntity), i0.ɵɵinject(i5.EntityIdRouteService), i0.ɵɵinject(i6.AppContextService), i0.ɵɵinject(i7.Router), i0.ɵɵinject(i8.RoutesService), i0.ɵɵinject(i9.I18nService)); }, token: BreadcrumbsResolver, providedIn: "root" });
    return BreadcrumbsResolver;
}());
export { BreadcrumbsResolver };
