import { OnInit } from '@angular/core';
import { CyberEvent } from '@wcd/domain';
import { CyberEventsUtilsService } from '../../cyber_events/services/cyber-events-utils.service';
import { ChangeDetectorRef } from '@angular/core';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
var ɵ0 = function (actionType) { return actionType.toString(); };
var FLAGGING_UNSUPPORTED_ACTIONTYPES = [
// Add action types that should not be allowed to be flagged here.
].map(ɵ0);
var MARK_EVENT_APP_INSIGHT_ID = 'MarkTimelineEvent';
var MachineEventMarkComponent = /** @class */ (function () {
    function MachineEventMarkComponent(cyberEventsUtilsService, ref, dialogService, i18nService, appInsightsService) {
        var _this = this;
        this.cyberEventsUtilsService = cyberEventsUtilsService;
        this.ref = ref;
        this.dialogService = dialogService;
        this.i18nService = i18nService;
        this.appInsightsService = appInsightsService;
        this.getFlagButtonStyle = function () {
            var hoverOrFocusOpacity = _this.isMarked ? 1 : 0.6;
            return {
                root: {
                    opacity: _this.isMarked ? 1 : 0.3,
                },
                rootHovered: {
                    opacity: 1,
                },
                rootFocused: {
                    opacity: hoverOrFocusOpacity,
                },
                menuIcon: {
                    paddingRight: 19,
                    textShadow: _this.isMarked ? '0 0 2px #b3eafa, 0 0 3px #4178d4' : 'none',
                },
            };
        };
        this.onFlagClick = function () {
            var newEventMarkStatus = !_this.isMarked;
            _this.isMarked = newEventMarkStatus;
            _this.appInsightsService.trackEvent(MARK_EVENT_APP_INSIGHT_ID, {
                SenseMachineId: _this.cyberEvent.machine.id,
                IsFlagged: _this.isMarked,
                ReportId: _this.cyberEvent.reportId,
                ActionType: _this.cyberEvent.rawActionType,
                isCyberData: _this.cyberEvent.isCyberData,
                dataType: _this.cyberEvent.cyberActionType && _this.cyberEvent.cyberActionType.dataType,
                relatedObservationName: _this.cyberEvent.relatedObservationName
            });
            _this.cyberEventsUtilsService.markEvent(_this.cyberEvent, _this.isMarked).subscribe(function (_) {
                // nothing to do on success, flag already marked.
            }, function (err) {
                // add event marked failed, revert the flag
                _this.isMarked = !newEventMarkStatus;
                _this.ref.detectChanges();
                _this.dialogService.showSnackbar({
                    text: _this.i18nService.get('events.markedEvents.markEventErrorMessage'),
                    data: err,
                });
                _this.appInsightsService.trackException(err, MARK_EVENT_APP_INSIGHT_ID);
            });
        };
    }
    MachineEventMarkComponent.prototype.ngOnInit = function () {
        this.isMarked = this.cyberEvent.isMarked;
        this.isSupportedActionType = !FLAGGING_UNSUPPORTED_ACTIONTYPES.includes(this.cyberEvent.rawActionType);
    };
    return MachineEventMarkComponent;
}());
export { MachineEventMarkComponent };
export { ɵ0 };
