var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityTypeConfig } from './airs-entity-type-config.entity';
import { AirsEntityStatusCount } from './airs-entity-status-count.value-object';
import { RemediationStatusType } from './airs-entity-status-types.enum';
var TOTAL = 'total';
var AirsEntityTypeResults = /** @class */ (function (_super) {
    __extends(AirsEntityTypeResults, _super);
    function AirsEntityTypeResults() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._entityCounts = {};
        _this._maliciousCount = {};
        return _this;
    }
    Object.defineProperty(AirsEntityTypeResults.prototype, "totalEntityCount", {
        get: function () {
            return this.getEntityCountByStatus(TOTAL);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsEntityTypeResults.prototype, "remediatedEntitiesCount", {
        get: function () {
            return this.getEntityCountByStatus(RemediationStatusType.Remediated);
        },
        enumerable: true,
        configurable: true
    });
    AirsEntityTypeResults.prototype.getEntityCountByStatus = function (status) {
        if (this._entityCounts[status] === undefined) {
            this._entityCounts[status] = this.results.reduce(function (total, currentStatus) {
                return status === TOTAL || (currentStatus.status && currentStatus.status.type === status)
                    ? total + currentStatus.count
                    : total;
            }, 0);
        }
        return this._entityCounts[status];
    };
    Object.defineProperty(AirsEntityTypeResults.prototype, "maliciousEntitiesCount", {
        get: function () {
            if (this._maliciousCount.count === undefined)
                this._maliciousCount.count = this.results.reduce(function (total, currentStatus) {
                    return currentStatus.status && currentStatus.status.isMalicious
                        ? total + currentStatus.count
                        : total;
                }, 0);
            return this._maliciousCount.count;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsEntityTypeResults.prototype, "entityTypeId", {
        get: function () {
            return this.entityType.id;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'type_id' }),
        __metadata("design:type", AirsEntityTypeConfig)
    ], AirsEntityTypeResults.prototype, "entityType", void 0);
    __decorate([
        EntityField({ data: 'data', arrayOf: AirsEntityStatusCount }),
        __metadata("design:type", Array)
    ], AirsEntityTypeResults.prototype, "results", void 0);
    AirsEntityTypeResults = __decorate([
        ValueObject({
            singularName: 'Entity Type Results',
            pluralName: 'Entity Type Results',
            readonly: true,
        })
    ], AirsEntityTypeResults);
    return AirsEntityTypeResults;
}(ModelBase));
export { AirsEntityTypeResults };
