import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { MachineUserDetailsAccount } from '../../../machine/machine-user-details-account.value-object';
import { WcdPortalParisConfig } from '../../../paris-config.interface';
import { LegacyUser } from '../legacy-user.entity';
import { LegacyUserProfile } from '../legacy-user-profile.entity';
import { omitBy, isNil } from 'lodash-es';

@EntityRelationship({
	sourceEntity: LegacyUser,
	dataEntity: LegacyUserProfile,
	getRelationshipData: (user: LegacyUser) => {
		let userAccount = user.accountName;
		let userAccountDomain = user.accountDomainName;
		if (user.$parent && user.$parent.constructor === MachineUserDetailsAccount) {
			const account = <MachineUserDetailsAccount>user.$parent;
			userAccount = account.name;
			userAccountDomain = account.domain;
		}

		return omitBy({ sid: user.sid, userAccount, userAccountDomain }, isNil);
	},
	endpoint: 'UserCommunicationProfile',
	allowedTypes: [RelationshipType.OneToOne],
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class LegacyUserLegacyUserProfileRelationship
	implements EntityRelationshipRepositoryType<LegacyUser, LegacyUserProfile> {}
