var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { some } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
/**
 * Map message keys sent from the backend to i18n keys
 */
var HuntingMessageKeys;
(function (HuntingMessageKeys) {
    HuntingMessageKeys["HUNTING_QUERY_SYNTAX_ERROR"] = "hunting_validateScheduledQuerySchema_errors_syntaxError";
    HuntingMessageKeys["HUNTING_EMPTY_QUERY_NAME"] = "hunting_saveQueryDialog_errors_emptyName";
    HuntingMessageKeys["HUNTING_QUERY_NAME_TOO_LONG"] = "hunting_saveQueryDialog_errors_nameTooLong";
    HuntingMessageKeys["HUNTING_QUERY_PATH_TOO_LONG"] = "hunting_saveQueryDialog_errors_pathTooLong";
    HuntingMessageKeys["HUNTING_QUERY_PATH_TOO_MANY_LEVELS"] = "hunting_saveQueryDialog_errors_tooManyPathLevels";
    HuntingMessageKeys["HUNTING_EMPTY_QUERY_TEXT"] = "hunting_saveQueryDialog_errors_emptyQueryText";
    HuntingMessageKeys["HUNTING_QUERY_MISSING_COLUMNS"] = "hunting_validateScheduledQuerySchema_errors_missingFields";
    HuntingMessageKeys["HUNTING_QUERY_NO_IMPACTED_ENTITIES"] = "hunting_validateScheduledQuerySchema_errors_noImpactedEntities";
    HuntingMessageKeys["HUNTING_QUERY_MISSING_RULE_ENTITY_OR_ACTION"] = "hunting_validateScheduledQuerySchema_errors_missingRuleEntityOrAction";
    HuntingMessageKeys["HUNTING_RULE_MISSING_PERMISSION"] = "common_permissions_noPermissionForActionDescription";
})(HuntingMessageKeys || (HuntingMessageKeys = {}));
var ScheduledHuntingErrorParsingService = /** @class */ (function () {
    function ScheduledHuntingErrorParsingService(i18nService) {
        this.i18nService = i18nService;
    }
    ScheduledHuntingErrorParsingService.prototype.parseError = function (error, showExtendedErrorMessage) {
        if (showExtendedErrorMessage === void 0) { showExtendedErrorMessage = false; }
        if (error.status === 409) {
            return new ScheduledHuntingError(error, this.i18nService.get('hunting.saveQueryDialog.errors.conflict'));
        }
        if (error.status === 401 || error.status === 403) {
            return new ScheduledHuntingError(error, this.i18nService.get('common_permissions_noPermissionForActionDescription'));
        }
        switch (error.message.Type) {
            case 'InvalidSchema':
                return new ScheduledHuntingErrorInvalidSchema(error, this.getLocalizedMessage(error.message.LocalizableMessage) || this.i18nService.get('hunting.validateScheduledQuerySchema.errors.missingFields'), showExtendedErrorMessage);
            case 'SyntaxError':
                return new ScheduledHuntingError(error, this.i18nService.get('hunting.validateScheduledQuerySchema.errors.syntaxError'));
            case 'InvalidParameter':
                return new ScheduledHuntingError(error, this.getLocalizedMessage(error.message.LocalizableMessage) || error.message.ErrorMessage);
            case 'Unauthorized':
                return new ScheduledHuntingError(error, this.i18nService.get('common_permissions_noPermissionForActionDescription'));
            default:
                return new ScheduledHuntingError(error, this.getLocalizedMessage(error.message.LocalizableMessage));
        }
    };
    ScheduledHuntingErrorParsingService.prototype.getLocalizedMessage = function (message) {
        var localizedMessage = message &&
            message.MessageKey &&
            HuntingMessageKeys[message.MessageKey] &&
            this.i18nService.get(HuntingMessageKeys[message.MessageKey], message.Parameters);
        return localizedMessage || (message && message.AltText);
    };
    return ScheduledHuntingErrorParsingService;
}());
export { ScheduledHuntingErrorParsingService };
export function getScheduledQueryError(error) {
    if (isScheduledQueryApiError(error)) {
        return error;
    }
    // the proxy server changes the structure of the response we are getting, from ajaxError to axiosResponse.
    if (error.response && error.response.status != undefined && error.response.data && error.response.data.Type) {
        return { status: error.response.status, message: error.response.data };
    }
    return null;
}
function isScheduledQueryApiError(error) {
    return error && typeof error === 'object' && error.status === 400 && error.message && error.message.Type;
}
var ScheduledHuntingError = /** @class */ (function () {
    function ScheduledHuntingError(error, errorMessage) {
        this.errorMessage = errorMessage;
        this.type = error.message.Type;
    }
    return ScheduledHuntingError;
}());
export { ScheduledHuntingError };
var ScheduledHuntingErrorInvalidSchema = /** @class */ (function (_super) {
    __extends(ScheduledHuntingErrorInvalidSchema, _super);
    function ScheduledHuntingErrorInvalidSchema(error, errorMessage, showExtendedErrorMessage) {
        if (showExtendedErrorMessage === void 0) { showExtendedErrorMessage = false; }
        var _this = _super.call(this, error, errorMessage) || this;
        _this.requiredQueryFields = error.message.AdditionalInfo || {};
        if (showExtendedErrorMessage) {
            _this.errorMessage = errorMessage + " " + _this.missingFieldNames.join(', ');
        }
        return _this;
    }
    Object.defineProperty(ScheduledHuntingErrorInvalidSchema.prototype, "areFieldsMissing", {
        get: function () {
            return some(this.requiredQueryFields, function (f) { return !f; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledHuntingErrorInvalidSchema.prototype, "requiredQueryFieldNames", {
        get: function () {
            return Object.keys(this.requiredQueryFields);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledHuntingErrorInvalidSchema.prototype, "missingFieldNames", {
        get: function () {
            var _this = this;
            return Object.keys(this.requiredQueryFields).filter(function (k) { return !_this.requiredQueryFields[k]; });
        },
        enumerable: true,
        configurable: true
    });
    return ScheduledHuntingErrorInvalidSchema;
}(ScheduledHuntingError));
export { ScheduledHuntingErrorInvalidSchema };
