import { EntityField, ValueObject, ModelBase } from '@microsoft/paris';
import { MachineGroupMachine } from './machine-group-machine.value-object';

@ValueObject({
	singularName: 'Device Group Results Preview',
	pluralName: 'Device Group Results Previews',
})
export class MachineGroupResultsPreview extends ModelBase {
	@EntityField({ data: 'MachineGroupId' })
	machineGroupId: number;

	@EntityField({ data: 'MachineCount' })
	machineCount: number;

	@EntityField({ data: 'SampleOfMachines', arrayOf: MachineGroupMachine })
	machines: Array<MachineGroupMachine>;
}
