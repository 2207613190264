import { ApiCallModel, ApiCall } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { ThreatProtection } from './threat-protection.value-object';
import { ThreatProtectionReportOptions } from './threat-protection-options.interface';

@ApiCall({
	name: 'Threat report',
	endpoint: 'reports/threatProtection',
	method: 'GET',
	type: ThreatProtection,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
	parseQuery: (options: ThreatProtectionReportOptions) => {
		return { params: options };
	},
})
export class ThreatReportApiCall extends ApiCallModel<
	Array<ThreatProtection>,
	ThreatProtectionReportOptions
> {}
