import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../../shared/shared.module';
import { DataViewsModule } from '../../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import { TvmModule } from '../../../../tvm/tvm.module';
import { ProfileEntityPanelComponent } from './components/profile.entity-panel.component';
import { ProfilesFieldsService } from './services/profiles.fields.service';
import { ProfileEntityTypeService } from './services/profile.entity-type.service';
import { ProfileWizardComponent } from '../components/profile-wizard/profile-wizard.component';
import { WcdWizardModule } from '@wcd/wizard';
import { ProfileNameStepComponent } from '../components/profile-wizard/profile-name-step.component';
import { ProfileSettingsStepComponent } from '../components/profile-wizard/profile-settings-step.component';
import { ProfileConfigurationsAssignemntStepComponent } from '../components/profile-wizard/profile-configurations-assignment-step.component';
import { ProfileReviewStepComponent } from '../components/profile-wizard/profile-review-step.component';
import { ConfigurationsByCategoryFieldsService } from './services/configurations-by-category.fields.service';
import { ConfigurationByCategoryEntityTypeService } from './services/configuration-by-category.entity-type.service';
import { BaselineProfileCompletedComponent } from '../components/profile-wizard/baseline-profile-completed.component';
import { ProfilesService } from './services/profiles.service';
import { BaselineProfileEntityDetailsComponent } from './components/baseline-profile.entity-details.component';
import { FabSpinnerModule } from '@angular-react/fabric';
import { CompliantDevicesDataviewCellComponent } from './components/compliant-devices-dataview-cell/compliant-devices-dataview-cell.component';

@NgModule({
	imports: [
		SharedModule,
		GlobalEntitiesModule,
		GlobalEntityPanelsModule,
		DataViewsModule,
		TvmModule,
		FabSpinnerModule,
		WcdWizardModule],
	declarations: [
		ProfileEntityPanelComponent,
		BaselineProfileEntityDetailsComponent,
		ProfileWizardComponent,
		ProfileNameStepComponent,
		ProfileSettingsStepComponent,
		ProfileConfigurationsAssignemntStepComponent,
		ProfileReviewStepComponent,
		BaselineProfileCompletedComponent,
		CompliantDevicesDataviewCellComponent],
	exports: [
		ProfileEntityPanelComponent,
		BaselineProfileEntityDetailsComponent],
	entryComponents: [ProfileEntityPanelComponent,
		ProfileWizardComponent,
		ProfileNameStepComponent,
		ProfileSettingsStepComponent,
		ProfileConfigurationsAssignemntStepComponent,
		ProfileReviewStepComponent,
		BaselineProfileCompletedComponent,
		BaselineProfileEntityDetailsComponent,
		CompliantDevicesDataviewCellComponent],
	providers: [
		ProfilesFieldsService,
		ProfilesService,
		ProfileEntityTypeService,
		ConfigurationsByCategoryFieldsService,
		ConfigurationByCategoryEntityTypeService],
})
export class ProfileEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(ProfileEntityTypeService);
		globalEntityTypesService.registerEntityType(ConfigurationByCategoryEntityTypeService);
	}
}
