


export class AutoFocusService {

	focusOnFirstElement(element?: HTMLElement){
		if(document.body.classList.contains('ms-Fabric--isFocusHidden')){
			return;
		}
		const countLimit = 10;
		let count = 0;
		const targetElement = element || document.querySelector("main");
		if(!targetElement){
			return;
		}
		const intervalHandler = setInterval(()=>{
			const focusable = targetElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');

			if(focusable.length > 0){
				const firstFocusable = focusable[0] as HTMLElement;
				firstFocusable.focus();
				clearInterval(intervalHandler);
				return;
			}

			if(count > countLimit){
				clearInterval(intervalHandler);
				return;
			}
			count++;
		},400);
	}
}
