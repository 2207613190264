var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone } from '@angular/core';
import { Store } from '../../data/models/store.base';
import { CommentModel } from '../models/comment.model';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { CommentsBackendService } from './comments.backend.service';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
var CommentsStore = /** @class */ (function (_super) {
    __extends(CommentsStore, _super);
    function CommentsStore(backendService, ngZone, dialogsService, authService, i18nService) {
        var _this = _super.call(this, backendService, ngZone, CommentModel, dialogsService, i18nService, {
            itemNameSingular: 'Comment',
            itemNamePlural: 'Comments',
            iconName: 'comment',
        }) || this;
        _this.authService = authService;
        return _this;
    }
    CommentsStore.prototype.parseData = function (itemData) {
        if (!itemData)
            itemData.user = this.authService.currentUser.name;
        return itemData;
    };
    CommentsStore.prototype.getItemsDataSet = function (options) {
        if (!options)
            options = {};
        if (!options.ordering)
            options.ordering = '-timestamp';
        return _super.prototype.getItemsDataSet.call(this, options);
    };
    CommentsStore.prototype.createComment = function (message, linkedObjects) {
        var comment = new CommentModel();
        comment.message = message;
        comment.linkedObjects = linkedObjects;
        return comment;
    };
    return CommentsStore;
}(Store));
export { CommentsStore };
