var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
var UsbEventEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(UsbEventEntityDetailsComponent, _super);
    function UsbEventEntityDetailsComponent(injector) {
        return _super.call(this, injector) || this;
    }
    Object.defineProperty(UsbEventEntityDetailsComponent.prototype, "event", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    return UsbEventEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { UsbEventEntityDetailsComponent };
