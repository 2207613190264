import { ApiCall, ApiCallModel, ParisConfig } from '@microsoft/paris';
import { Machine } from '../machine.entity';
import { MachineCreateRequestType } from './machine-requests.models';

export interface MachinePermissionsRequestBody {
	readonly machineId: string;
	readonly osPlatform: string;
	readonly senseClientVersion: string;
}

export enum RawMachinePermissions {
	Unknown = -2,
	Disallowed = -1,
	Allowed = 0,
	AllowedWithWarning = 1,
}

interface RawMachinePermissionsItem {
	readonly Reason: number;
	readonly Result: RawMachinePermissions;
	readonly Source: number;
	readonly Type: MachineCreateRequestType;
}

interface MachinePermissionsRawApiResponse {
	readonly Permissions: ReadonlyArray<RawMachinePermissionsItem>;
}

export type MachinePermission = keyof typeof RawMachinePermissions;

export type MachinePermissions = Record<MachineCreateRequestType, MachinePermission>;

@ApiCall({
	name: 'Machine permissions',
	endpoint: 'requests/permissions',
	method: 'GET',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: (machine: Machine) => ({
		params: {
			machineId: machine.machineId,
		} as MachinePermissionsRequestBody,
	}),
	parseData: ({ Permissions }: MachinePermissionsRawApiResponse): MachinePermissions => {
		const typesMap = Permissions.reduce(
			(map, currentItem) =>
				Object.assign(map, {
					[currentItem.Type]: RawMachinePermissions[currentItem.Result],
				}),
			{} as any
		);

		return typesMap;
	},
})
export class MachinePermissionsApiCall extends ApiCallModel<MachinePermissions, Machine> {}
