import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@ApiCall({
	name: 'API for tracking purpose - User cancel create live response session',
	endpoint: (config, query) => {
		if(query && query.where && query.where['useV3Api']) {
			return 'cloud/live_response/create_session_canceled'
		}

		return query && query.where && query.where['useV2Api']
			? 'v2/live_response/create_session_canceled'
			: 'live_response/create_session_canceled'
	},
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig, query) => query && query.where && query.where['useV3Api'] ? config.data.serviceUrls.k8s : config.data.serviceUrls.automatedIr,
	parseQuery: (requestParams: CancelCreateSessionApiRequestParams) => {
		return {
			params: {
				machine_id: requestParams.machineId,
				machine_last_seen: requestParams.machineLastSeen,
				useV2Api: requestParams.useV2Api,
				useV3Api: requestParams.useV3Api,
			},
		};
	},
})
export class CancelCreateLiveResponseSessionApiCall extends ApiCallModel<
	void,
	CancelCreateSessionApiRequestParams
> {}

interface CancelCreateSessionApiRequestParams {
	readonly machineId: string;
	readonly machineLastSeen: string;
	readonly useV2Api: boolean;
	readonly useV3Api: boolean;
}
