import { Component, Input } from '@angular/core';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';
import { Router } from '@angular/router';
import { LiveResponseScript } from '@wcd/domain';
import { take } from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { LiveResponseScriptService } from '../services/live-response-script.service';

@Component({
	selector: 'live-response-script-upload',
	template: `
		<modal (close)="destroy()" [settings]="settings">
			<form class="dialog-contents" (submit)="submit()" data-track-component="LiveResponseScriptUpload">
				<div class="wcd-flex-vertical inputs-container">
					<input
						type="file"
						id="scriptContent"
						name="scriptContent"
						class="wcd-margin-small-all wcd-flex-1 ie11Patch ie11Flex"
						[attr.aria-label]="
							filename || i18nService.strings.liveResponse_actions_uploadScript_scriptContent
						"
						(change)="setFile($event)"
					/>
					<input
						type="text"
						id="description"
						name="scriptDescription"
						[attr.aria-label]="
							i18nService.strings.liveResponse_actions_uploadScript_scriptDescription
						"
						class="form-control wcd-margin-small-all wcd-flex-1 ie11Patch ie11Flex"
						[placeholder]="
							i18nService.strings.liveResponse_actions_uploadScript_scriptDescription
						"
						[(ngModel)]="script.description"
					/>
					<wcd-checkbox
						[id]="'overwriteExistingFile'"
						name="overwriteExistingFile"
						class="wcd-margin-small-all wcd-flex-1"
						[label]="i18nService.strings.liveResponse_actions_uploadScript_overwriteFile"
						[(ngModel)]="overwriteExistingFile"
					>
						<wcd-help
							[text]="i18nService.strings.liveResponse_actions_uploadScript_fileAlreadyExists"
						>
						</wcd-help>
					</wcd-checkbox>
					<wcd-checkbox
						[id]="'scriptHasParams'"
						name="scriptHasParams"
						class="wcd-margin-small-all wcd-flex-1 ie11Patch ie11Flex"
						[label]="i18nService.strings.liveResponse_actions_uploadScript_scriptParameters"
						[(ngModel)]="script.hasParams"
					>
					</wcd-checkbox>
					<input
						*ngIf="script.hasParams"
						type="text"
						id="paramsDescription"
						name="scriptParamDecription"
						class="form-control wcd-margin-small-all wcd-flex-1 ie11Patch ie11Flex"
						[attr.placeholder]="
							i18nService.strings.liveResponse_actions_uploadScript_parametersDescription
						"
						[(ngModel)]="script.paramsDescription"
					/>
				</div>
				<footer class="wcd-flex-horizontal wcd-margin-top">
					<div class="wcd-margin-small-all wcd-flex-1 ie11Patch ie11Flex">
						<button
							type="submit"
							data-track-id="Submit"
							data-track-type="Button"
							class="btn btn-primary wcd-margin-small-right"
							[disabled]="!script?.file || isSaving"
						>
							<i *ngIf="isSaving" class="loader-icon"></i>
							{{ i18nService.strings.buttons_confirm }}
						</button>
					</div>
				</footer>
			</form>
		</modal>
	`,
})
export class LiveResponseScriptUploadComponent extends ModalContainer {
	isSaving: boolean;
	overwriteExistingFile: boolean;
	filename: string;

	@Input() script: LiveResponseScript;

	constructor(
		router: Router,
		private dialogsService: DialogsService,
		public i18nService: I18nService,
		private liveResponseScriptService: LiveResponseScriptService
	) {
		super(router);
	}

	setFile(event): void {
		this.script.file = event.target.files && event.target.files[0];
		try {
			this.filename = event.target.value.split('\\').pop();
			event.target.focus();
		} catch (e) {}
	}

	async submit(): Promise<void> {
		this.isSaving = true;
		try {
			await this.liveResponseScriptService
				.save(this.script, {
					overwriteExisting: this.overwriteExistingFile,
				})
				.pipe(take(1))
				.toPromise();
			this.closeModal();
			this.dialogsService.showSuccessSnackbar({
				text: this.i18nService.strings.liveResponse_actions_uploadScript_success,
				icon: FabricIconNames.Upload,
			});
		} catch (e) {
			this.dialogsService.showError({
				title: this.i18nService.strings.liveResponse_actions_uploadScript_failure,
				text: this.i18nService.strings.liveResponse_actions_uploadScript_failure,
				data: e,
			});
		} finally {
			this.isSaving = false;
		}
	}
}
