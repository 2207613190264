import { EntityField, EntityModelBase, ValueObject } from '@microsoft/paris';

export enum QueryProductContext {
	Mdatp = 'Mdatp',
	Itp = 'Itp',
	Oatp = 'Oatp'
}



export class HuntingDocEntity extends EntityModelBase {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'Description' })
	description: string;
}

@ValueObject({
	singularName: 'hunting doc query reference',
	pluralName: 'hunting docs queries reference',
})
export class HuntingDocQuery extends HuntingDocEntity {
	@EntityField({ data: 'QueryText' })
	queryText: string;

	// in getting started context this is used to identify to which product the query belong.
	// we need this identification to match the query with the correct description since we store the description in enLockstring
	@EntityField({ data: 'QueryProductContext' })
	productContext?: QueryProductContext;
}

@ValueObject({
	singularName: 'hunting doc record reference',
	pluralName: 'hunting doc records reference',
})
export class HuntingDocRecord extends HuntingDocEntity {
	@EntityField({ data: 'Queries', arrayOf: HuntingDocQuery })
	queries: Array<HuntingDocQuery>;
}
