import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalContainer } from '../../../../dialogs/modals/models/modal-container.model';
import { CustomConfirmComponent } from '../../../../dialogs/models/custom-confirm-options';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import {
	File,
	FileCurrentInstancesCount,
	FileCurrentInstancesRelationship,
	MachineGroup,
	FileBlockApiCall,
} from '@wcd/domain';
import { Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { DimensionsModel } from '../../../../dialogs/models/dimensions.model';
import { MachinesService } from '../../../machines/services/machines.service';
import { AuthService } from '@wcd/auth';
import { FilesService } from '../../services/files.service';
import { FeaturesService } from '@wcd/config';
import { SpecificTimeRangeValue } from '@wcd/date-time-picker';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<modal (close)="destroy()" [settings]="settings">
			<confirm-modal-content
				class="wcd-flex-1 ie11Patch ie11Flex"
				[confirmButtonOptions]="{
					disabled: !reason || isSaving
				}"
				[isSaving]="isSaving"
				(onCancel)="onCancelHandler()"
				(onConfirm)="submit()"
			>
				<p>{{ 'file.' + action + '.description' | i18n }}</p>
				<label for="block-file-reason">{{ 'file.block.reason' | i18n }}:</label>
				<textarea
					required
					rows="4"
					id="block-file-reason"
					[placeholder]="'file.quarantine.reasonPlaceholder' | i18n"
					class="form-control dialog-textarea wcd-full-width wcd-no-resize-horizontal"
					[(ngModel)]="reason"
				></textarea>
				<ng-container *ngIf="action === 'block'">
					<div
						*ngIf="(currentInstancesCount$ | async) as currentInstancesCount"
						class="wcd-margin-vertical"
					>
						<markdown
							[data]="'file.block.instances' | i18n: { count: currentInstancesCount.fileCount }"
						></markdown>
						<div class="wcd-margin-top">
							<small>
								{{ 'file.quarantine.applies' | i18n }}
							</small>
						</div>
					</div>
				</ng-container>
			</confirm-modal-content>
		</modal>
	`,
})
export class BlockFileModalComponent extends ModalContainer implements CustomConfirmComponent<{}>, OnInit {
	@Input() file: File;
	@Input() action: 'block' | 'unblock';
	@Input() isSaving = false;
	@Input() openActionCenterOnSubmit = false;

	@Output() readonly onConfirm = new EventEmitter<{}>();
	@Output() readonly onCancel = new EventEmitter<void>();

	reason: string;

	currentInstancesCount$: Observable<FileCurrentInstancesCount>;

	constructor(
		router: Router,
		private paris: Paris,
		private readonly machinesService: MachinesService,
		private readonly authService: AuthService,
		private readonly i18nService: I18nService,
		private readonly filesService: FilesService,
		private readonly featuresService: FeaturesService,
	) {
		super(router);
	}

	ngOnInit() {
		this.settings = {
			...this.settings,
			className: 'wcd-flex-vertical',
			title: this.i18nService.get(`file.${this.action}.title`),
			dimensions: new DimensionsModel(500, this.action === 'block' ? 420 : 340),
		};

		this.currentInstancesCount$ = this.paris.getRelatedItem<File, FileCurrentInstancesCount>(
			FileCurrentInstancesRelationship,
			this.file,
			{ where: { filesPrefix: this.featuresService.isEnabled('K8SMigration-EPSFilePrevalence-kw') } }
		);
	}

	onConfirmHandler() {
		this.onConfirm.emit({});
		this.onConfirm.complete();
	}

	onCancelHandler() {
		this.onCancel.emit();
		this.onCancel.complete();
	}

	async submit() {
		this.isSaving = true;

		const machineGroupIds: ReadonlyArray<MachineGroup['id']> = this.authService.currentUser.isMdeAdmin
			? [0]
			: await this.machinesService
					.getFullUserExposedMachineGroups()
					.pipe(map(machineGroups => machineGroups.map(machineGroup => machineGroup.id)))
					.toPromise();

		this.paris
			.apiCall(FileBlockApiCall, {
				file: this.file,
				reason: this.reason,
				action: this.action,
				rbacGroups: machineGroupIds,
			})
			.pipe(
				finalize(() => {
					this.isSaving = false;
					this.onConfirmHandler();
				})
			)
			.subscribe(() => {
				if (this.openActionCenterOnSubmit) this.filesService.showFileActionCenter(this.file);
			});
	}
}
