import { NgModule } from '@angular/core';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { SharedModule } from '../../shared/shared.module';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { ScheduledHuntingDataviewComponent } from './components/scheduled-hunting.dataview';
import { ScheduledHuntingEntityPanelComponent } from './components/scheduled-hunting.entity-panel.component';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { ScheduledHuntingEntityTypeService } from './services/scheduled-hunting.entity-type.service';
import { ScheduledHuntingFieldsService } from './services/scheduled-hunting.fields';
import { ScheduledHuntingRuleEditComponent } from './components/scheduled-hunting-rule-edit.component';
import { ScheduledHuntingEntityDetailsComponent } from './components/scheduled-hunting.entity-details.component';
import { ScheduledHuntingAlertsComponent } from './components/scheduled-hunting.alerts.component';
import { AlertsModule } from '../alerts/alerts.module';
import { ScheduledHuntingEntityComponent } from './components/scheduled-hunting.entity.component';
import { HuntingRbacService } from './services/hunting-rbac-service';
import { PanelsModule } from '@wcd/panels';
import { WcdFormsModule } from '@wcd/forms';
import { ScheduledHuntingActionsComponent } from './components/scheduled-hunting.actions.component';
import { ScheduledHuntingActionEntityTypeService } from './services/scheduled-hunting-action.entity-type.service';
import { ScheduledHuntingActionEntityPanelComponent } from './components/scheduled-hunting-action.entity-panel.component';
import { DataTableModule } from '@wcd/datatable';
import { ScheduledHuntingActionsFields } from './scheduled-hunting.actions.fields';
import { WcdSharedModule } from '@wcd/shared';
import { A11yModule } from '@angular/cdk/a11y';
import { HuntingCustomActionsModule } from '../../hunting-custom-actions/hunting-custom-actions.module';
import { HuntingQueriesModule } from '@wcd/hunting';
import { ScheduledHuntingLastRunStatusComponent } from './components/scheduled-hunting-last-run-status.component';
import { ScheduledHuntingRunTimeComponent } from './components/scheduled-hunting-run-time.component';

@NgModule({
	imports: [
		SharedModule,
		DataViewsModule,
		DataTableModule,
		AlertsModule,
		GlobalEntitiesModule,
		PanelsModule,
		WcdFormsModule,
		WcdSharedModule,
		A11yModule,
		HuntingCustomActionsModule,
		HuntingQueriesModule,
	],
	declarations: [
		ScheduledHuntingEntityPanelComponent,
		ScheduledHuntingActionEntityPanelComponent,
		ScheduledHuntingEntityDetailsComponent,
		ScheduledHuntingDataviewComponent,
		ScheduledHuntingRuleEditComponent,
		ScheduledHuntingEntityComponent,
		ScheduledHuntingAlertsComponent,
		ScheduledHuntingActionsComponent,
		ScheduledHuntingLastRunStatusComponent,
		ScheduledHuntingRunTimeComponent,
	],
	exports: [ScheduledHuntingDataviewComponent, ScheduledHuntingRuleEditComponent],
	providers: [
		ScheduledHuntingEntityTypeService,
		ScheduledHuntingActionEntityTypeService,
		ScheduledHuntingFieldsService,
		HuntingRbacService,
		ScheduledHuntingActionsFields,
	],
	entryComponents: [
		ScheduledHuntingEntityPanelComponent,
		ScheduledHuntingActionEntityPanelComponent,
		ScheduledHuntingEntityDetailsComponent,
		ScheduledHuntingRuleEditComponent,
		ScheduledHuntingEntityComponent,
		ScheduledHuntingLastRunStatusComponent,
		ScheduledHuntingRunTimeComponent,
	],
})
export class ScheduledHuntingModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(ScheduledHuntingEntityTypeService);
		globalEntityTypesService.registerEntityType(ScheduledHuntingActionEntityTypeService);
	}
}
