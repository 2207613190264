/* tslint:disable:template-click-events-have-key-events */
import {
	Component,
	ChangeDetectionStrategy,
	Input,
	ElementRef,
	AfterViewChecked,
	ViewChild,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	OnInit,
} from '@angular/core';
import { TzDateService } from '@wcd/localization';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { LineChartTooltip, DateValuePoint, DatePercentagePoint } from '@wcd/charts';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { I18nService } from '@wcd/i18n';

const DATA_POINT_HOVER_EVENT = 'TVM hover data point';
const MIN_WIDTH_WITH_DATA = 270;
const MIN_WIDTH_WITHOUT_DATA = 110;
export const SECURE_SCORE_TOOLTIP_ID = 'secureScoreTooltip';
export const EXPOSURE_SCORE_TOOLTIP_ID = 'exposureScoreTooltip';
export const ASSETS_STATISTICS_EXPANDED_TOOLTIP_ID = 'assetsStatisticsExpandedTooltip';

@Component({
	selector: 'tvm-chart-tooltip',
	templateUrl: './tvm-chart-tooltip.component.html',
	styleUrls: ['./tvm-chart-tooltip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvmChartTooltipComponent implements AfterViewChecked, LineChartTooltip, OnInit {
	@ViewChild('eventDiv', { read: ElementRef, static: true })
	eventDiv: ElementRef;

	@Input() legend: string;
	@Input() percentageDisplay = false;

	formattedDate: string;
	date: Date;
	score: number;
	eventLines: string[];
	percentNominator: number;
	percentDenominator: number;
	isDisplayingData: boolean;
	minWidth: number;
	linkText: string;

	@Input() left: number;
	@Input() bottom: number;
	@Input() opacity: number;
	@Input() id: string;

	@Input() newDataPoint$: Observable<DateValuePoint>;

	@Output() onNewWidth = new EventEmitter<number>();
	@Output() onEnterTooltip = new EventEmitter<void>();
	@Output() onLeaveTooltip = new EventEmitter<void>();

	private _width: number;

	constructor(
		private route: ActivatedRoute,
		private changeDetectorRef: ChangeDetectorRef,
		private appInsights: AppInsightsService,
		private router: Router,
		private tzDateService: TzDateService,
		private i18nService: I18nService
	) {}

	ngOnInit() {
		if (this.newDataPoint$) {
			this.newDataPoint$.subscribe((dataPoint: DateValuePoint) => {
				this.score = dataPoint.value;
				this.date = dataPoint.date;
				this.formattedDate = this.tzDateService.format(dataPoint.date, 'MM/dd');
				this.eventLines = dataPoint.events;
				this.isDisplayingData = this.eventLines && this.eventLines.length > 0;
				this.minWidth = this.isDisplayingData ? MIN_WIDTH_WITH_DATA : MIN_WIDTH_WITHOUT_DATA;
				this.linkText =
					this.id === ASSETS_STATISTICS_EXPANDED_TOOLTIP_ID
						? this.i18nService.strings.tvm_changeEvent_showAllEvents
						: this.i18nService.strings.tvm_changeEvent_showAllEventsOnDay;

				if (this.percentageDisplay && 'nominator' in dataPoint) {
					const percentPoint = dataPoint as DatePercentagePoint;
					this.percentNominator = percentPoint.nominator;
					this.percentDenominator = percentPoint.denominator;
				}
				this.appInsights.trackEvent(DATA_POINT_HOVER_EVENT, {
					chartLegend: this.legend, // Adding the chart legend to distinguish between the events fro multiple charts
					...dataPoint,
				});
			});
		}
	}

	openEventsPageOnDate() {
		let params = null;
		let route = 'tvm-event-insights';
		if (this.id !== ASSETS_STATISTICS_EXPANDED_TOOLTIP_ID) {
			const timeRange = this.date.getTime();
			params = { range: `${timeRange}:${timeRange}` };
			if (this.id === SECURE_SCORE_TOOLTIP_ID) {
				params['filters'] = 'eventType=NewScid';
			}
		} else {
			route = 'event-insights';
		}

		this.router.navigate([route], {
			queryParams: params,
			relativeTo: this.route.parent,
		});
	}

	ngAfterViewChecked() {
		// For the client width to be updated
		this.changeDetectorRef.markForCheck();
		setTimeout(() => {
			const newWidth = this.eventDiv.nativeElement.clientWidth;
			if (this._width !== newWidth) {
				this.onNewWidth.emit(newWidth);
				this._width = newWidth;
			}
		}, 0);
	}
}
