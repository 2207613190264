import { AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
var FabButtonFocusOnDirective = /** @class */ (function () {
    function FabButtonFocusOnDirective(elemRef) {
        var _this = this;
        this.elemRef = elemRef;
        this.focusingDelay = 0;
        this.focused = false;
        this.blurCallbackInit = false;
        this.onBlur = function () {
            _this.focused = false;
            _this.fabButtonBlurCallback();
        };
    }
    Object.defineProperty(FabButtonFocusOnDirective.prototype, "button", {
        get: function () {
            if (!this._button) {
                this._button = this.elemRef.nativeElement.querySelector('button');
            }
            return this._button;
        },
        enumerable: true,
        configurable: true
    });
    FabButtonFocusOnDirective.prototype.ngOnChanges = function (changes) {
        this.focused = false;
        if (changes.fabButtonFocusOn && changes.fabButtonFocusOn.currentValue) {
            this.focusOnButton(this.focusingDelay);
        }
    };
    FabButtonFocusOnDirective.prototype.ngAfterViewInit = function () {
        if (this.fabButtonFocusOn) {
            this.focusOnButton(this.focusingDelay);
        }
    };
    FabButtonFocusOnDirective.prototype.ngOnDestroy = function () {
        this.fabButtonBlurCallback && !this.blurCallbackInit && this.button.removeEventListener('blur', this.onBlur);
    };
    FabButtonFocusOnDirective.prototype.focusOnButton = function (delay) {
        var _this = this;
        if (delay === void 0) { delay = 0; }
        setTimeout(function () {
            if (_this.elemRef && _this.elemRef.nativeElement) {
                if (_this.button && !_this.focused) {
                    _this.focused = true;
                    _this.button.focus();
                    if (_this.fabButtonBlurCallback && !_this.blurCallbackInit) {
                        _this.blurCallbackInit = true;
                        _this.button.addEventListener('blur', _this.onBlur);
                    }
                }
            }
        }, delay);
    };
    return FabButtonFocusOnDirective;
}());
export { FabButtonFocusOnDirective };
