import { Paris } from '@microsoft/paris';
import { MagellanFeatures } from './magellan-features.entity';
import { MagellanFeaturesEnablingDates } from './magellan-features-enabling-dates';
import { GetMagellanSettingsApiCall } from './magellan-features.apicall';

export interface MagellanFeaturesWithDates {
	isProactiveDiscoveryEnabled: boolean;
	isProactiveDiscoveryEmpty: boolean;
	proactiveDiscoveryEnablingDate: string;
	isProactiveDiscoveryEnablingDatePassed: boolean;
	isLog4jScanningEnabled: boolean;
}
export class MagellanFeaturesProvider {
	constructor(private paris: Paris) {}

	get(): Promise<MagellanFeatures> {
		return this.paris.apiCall<MagellanFeatures>(GetMagellanSettingsApiCall).toPromise<MagellanFeatures>();
	}

	put(features: Partial<MagellanFeatures>): Promise<MagellanFeatures> {
		return this.paris
			.getRepository(MagellanFeatures)
			.save(features)
			.toPromise();
	}

	async getFeaturesWithDates(): Promise<MagellanFeaturesWithDates> {
		const [
			{ isProactiveDiscoveryEnabled, isLog4jScanningEnabled, isProactiveDiscoveryEmpty },
			{ proactiveDiscoveryEnablingDate },
		] = await Promise.all([this.get(), this.getEnabledDates()]);

		return {
			isProactiveDiscoveryEnabled,
			isLog4jScanningEnabled,
			isProactiveDiscoveryEmpty,
			proactiveDiscoveryEnablingDate,
			isProactiveDiscoveryEnablingDatePassed: new Date() > new Date(proactiveDiscoveryEnablingDate),
		};
	}

	getEnabledDates(): Promise<MagellanFeaturesEnablingDates> {
		return this.paris.getItemById(MagellanFeaturesEnablingDates, 1).toPromise();
	}
}
