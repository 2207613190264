import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { iotDeviceTypes } from './machine-device-type.values';

@Entity({
	singularName: 'Device Type',
	pluralName: 'Device Types',
	values: iotDeviceTypes,
})
export class DeviceTypeEntity extends EntityModelBase {
	@EntityField() i18nNameKey: string;

	@EntityField() priority?: number;

	name: string;
}
