import { IStatus } from '../../status.interface';

export enum AlertStatusType {
	Unspecified = 'Unspecified',
	New = 'New',
	InProgress = 'InProgress',
	Resolved = 'Resolved',
	Hidden = 'Hidden',
	ResolvedValid = 'Resolved [Valid]',
	ResolvedValidAllowed = 'Resolved [Valid - Allowed]',
	ResolvedFalseAlarm = 'Resolved [False Alarm]',
}

export enum AlertV3StatusType {
	Unknown = 'Unknown',
	New = 'New',
	Resolved = 'Resolved',
	Dismissed = 'Dismissed',
	InProgress = 'InProgress',
}

export const AlertStatusTypeId = {
	Unspecified: 1,
	New: 2,
	InProgress: 4,
	Resolved: 8,
	Hidden: 16,
	ResolvedValid: 32,
	ResolvedValidAllowed: 64,
	ResolvedFalseAlarm: 128,
} as const;

/*
	TODO:
	'Dismissed' status in AlertV3 is not part of the existing Alert statuses.
	For now it will be mapped to 'Resolved' but it's still an open issue on PM side.
*/
export const AlertV3StatusTypeMapping = {
	[AlertV3StatusType.Unknown]: AlertStatusType.Unspecified,
	[AlertV3StatusType.New]: AlertStatusType.New,
	[AlertV3StatusType.Resolved]: AlertStatusType.Resolved,
	[AlertV3StatusType.Dismissed]: AlertStatusType.Resolved,
	[AlertV3StatusType.InProgress]: AlertStatusType.InProgress,
};

export type AlertStatusId = 0 | typeof AlertStatusTypeId[keyof typeof AlertStatusTypeId];
export const ActiveAlertStatuses = AlertStatusTypeId.New | AlertStatusTypeId.InProgress;

export interface IAlertStatus extends IStatus<AlertStatusId, AlertStatusType> {
	title?: string;
	isSelectable?: boolean;
	allowClassification?: boolean;
	isHidden?: boolean;
}
