import { Component, EventEmitter, Output } from '@angular/core';

import { NotificationsService } from '../services/notifications.service';

@Component({
	selector: 'floating-notifications',
	templateUrl: './floating-notifications.component.html',
})
export class FloatingNotificationsComponent {
	@Output() close: EventEmitter<void> = new EventEmitter<void>();

	constructor(public notificationsService: NotificationsService) {}
}
