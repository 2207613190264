import { Component } from '@angular/core';
import { Feature, FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'manage-android-onboarding',
	templateUrl: './manage-android-onboarding.component.html',
})
export class ManageAndroidOnboardingComponent {
	androidOnBoardingEnabled: boolean;
	isAndroidPreview: boolean;

	constructor(private featuresService: FeaturesService, private i18nService: I18nService) {
		this.isAndroidPreview = featuresService.isEnabled(Feature.AndroidEndPointManagementPreview);
		this.androidOnBoardingEnabled =
			this.isAndroidPreview || featuresService.isEnabled(Feature.AndroidEndPointManagement);
	}

	getTitle() {
		return this.isAndroidPreview
			? this.i18nService.get(
					'endpointManagement.nonWindows.android.onboarding.firstParty.title.preview'
			  )
			: this.i18nService.get('endpointManagement.nonWindows.android.onboarding.firstParty.title');
	}
}
