import { EntityField, EntityModelBase } from '@microsoft/paris';
import { ThreatInfo } from '../threat-info.value-object';
import { AssetsStatistics } from '../assets-statistics.value-object';
import { RemediationType } from '../remediation/remediation-task-type.enum';
import { RecommendationCategory } from './recommendation-category.enum';
import { ThreatDescriptor } from '../threat-descriptor.value-object';
import { RemediationDescriptor } from '../remediation/remediation-descriptor.value-object';
import { EolState } from '../../software-version/eol-version-state.enum';
import { RecommendationStatus } from './recommendation-status.enum';
import { VulnerabilityWorkaround } from '../vulnerability/vulnerability-workaround.value-object';
import { VulnerabilityType } from '../vulnerability/vulnerability-type.enum';
import { TvmSupportedOsPlatform } from '../tvm-supported-os-platform.enum';
import { TvmRecommendationOnboardingStatus } from './recommendation-onboarding-status.enum';

export abstract class RecommendationBase extends EntityModelBase {
	@EntityField({
		required: true,
	})
	readonly title: string;

	@EntityField({
		required: true,
	})
	readonly weaknessesCount: number;

	@EntityField({
		required: true,
	})
	readonly threatInfo: ThreatInfo;

	@EntityField({
		required: true,
	})
	readonly assetsStatistics: AssetsStatistics;

	@EntityField({
		parse: (remediationType: RemediationType, rawData) => {
			if (rawData.remediationType === '') return RemediationType.Update;
			return remediationType;
		},
		required: true,
	})
	readonly remediationType: RemediationType;

	@EntityField({
		required: true,
		data: 'recommendationCategory',
	})
	readonly category: RecommendationCategory;

	@EntityField({
		require: data => data.remediationType === RemediationType.ConfigurationChange,
	})
	readonly subCategory?: string;

	@EntityField({
		require: data => data.remediationType === RemediationType.ConfigurationChange,
	})
	readonly description: string;

	@EntityField({
		require: data => data.remediationType === RemediationType.ConfigurationChange,
	})
	readonly remediationDescription: string;

	@EntityField({
		required: true,
	})
	readonly exposureScoreImprovement: number;

	@EntityField({
		require: data => data.remediationType === RemediationType.ConfigurationChange,
	})
	readonly secureScoreImprovement: number;

	@EntityField({
		require: data => data.remediationType === RemediationType.ConfigurationChange,
	})
	readonly benchmarks: string[];

	@EntityField({
		require: data => data.remediationType === RemediationType.ConfigurationChange,
	})
	readonly potentialRisk?: string;

	@EntityField({
		require: data => data.remediationType === RemediationType.ConfigurationChange,
	})
	readonly scId?: string;

	@EntityField({
		require: data => data.remediationType === RemediationType.ConfigurationChange,
	})
	readonly cce?: string;

	@EntityField({
		require: data => data.remediationType === RemediationType.ConfigurationChange,
	})
	readonly relatedCVEs?: string[];

	@EntityField() readonly productName?: string;

	@EntityField() readonly productsNames?: Array<string>;

	@EntityField({
		require: data => data.remediationType !== RemediationType.ConfigurationChange,
	})
	readonly vendor?: string;

	@EntityField({
		require: data => data.remediationType !== RemediationType.ConfigurationChange,
	})
	readonly recommendedVersion?: string;

	@EntityField() readonly recommendedVendor?: string;

	@EntityField() readonly recommendedProgram?: string;

	@EntityField() readonly requiredAttention?: boolean;

	@EntityField({
		data: 'weaknessesCount',
		require: data => data.remediationType !== RemediationType.ConfigurationChange,
	})
	readonly vulnerabilitiesCount?: number;

	@EntityField() readonly productId?: string;

	// TODO: evhvoste - why the values is here and not used in the portal
	@EntityField() readonly oneAsset: boolean;

	@EntityField({ data: 'status' })
	readonly status?: RecommendationStatus;

	@EntityField()
	readonly threats: ThreatDescriptor[];

	@EntityField({
		data: 'remediationTasks',
	})
	readonly remediationTasks?: RemediationDescriptor[];

	@EntityField() readonly isProductivityImpacted?: boolean;

	// TODO[gibarila 25864371]: Remove isEOL and its references when Analytics in production uses eolSoftwareState instead of isEOL
	@EntityField() readonly isEOL?: boolean;

	@EntityField()
	readonly eolSoftwareState: EolState;

	@EntityField()
	readonly eolSoftwareSinceDate: Date;

	@EntityField()
	readonly hasEolVersions: boolean;

	@EntityField()
	readonly hasUpcomingEolVersions: boolean;

	@EntityField()
	readonly exposureScoreImprovementAfterApplyingExceptions: number;

	@EntityField()
	readonly secureScoreImprovementAfterApplyingExceptions: number;

	@EntityField({
		data: 'vulnerabilityWorkarounds',
	})
	readonly workarounds?: Array<VulnerabilityWorkaround>;

	@EntityField({
		data: 'mostSevereVulnerabilityType',
	})
	readonly mostSevereVulnerabilityType?: VulnerabilityType;

	@EntityField({
		data: 'zeroDayAssetsAtRisk',
	})
	readonly zeroDayAssetsAtRisk?: number;

	@EntityField({
		data: 'patchReleaseDate',
	})
	readonly patchReleaseDate?: Date;

	@EntityField()
	readonly tags: string[];

	@EntityField()
	readonly osPlatform: TvmSupportedOsPlatform;

	@EntityField()
	readonly onboardingStatus: TvmRecommendationOnboardingStatus;

	@EntityField()
	readonly hasIotDevice?: boolean;
}
