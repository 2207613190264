import { EntityField, EntityModelBase } from '@microsoft/paris';

export abstract class BaselineDeviceBase extends EntityModelBase {
	@EntityField() readonly name: string;

	@EntityField() readonly domain: string;

	@EntityField() readonly compliantConfigurations: number;

	@EntityField() readonly compliantConfigurationsPercent: number;

	@EntityField() readonly applicableConfigurations: number;

	@EntityField() readonly operatingSystem: string;

	@EntityField() readonly operatingSystemVersion: string;

	@EntityField() readonly applicableProfiles: number;

	@EntityField() readonly lastSeen: Date;

	@EntityField() readonly detectedValues: string[];

	@EntityField() readonly rbacGroupId: number;
}
