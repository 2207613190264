var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { Alert } from '../../alert/alert.entity';
import { HuntingRule } from '../hunting-rule.entity';
import { CustomActionRun } from '../actions/hunting-custom-action-result.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = function (rule) {
    return {
        IoaDefinitionIds: rule.ioaDefinitionId,
    };
};
var ScheduledHuntingRuleAlertRelationship = /** @class */ (function () {
    function ScheduledHuntingRuleAlertRelationship() {
    }
    ScheduledHuntingRuleAlertRelationship = __decorate([
        EntityRelationship({
            sourceEntity: HuntingRule,
            dataEntity: Alert,
            endpoint: "AssociatedAlerts",
            allItemsEndpointTrailingSlash: false,
            allItemsProperty: 'Items',
            allowedTypes: [RelationshipType.OneToMany],
            baseUrl: ɵ0,
            getRelationshipData: ɵ1,
        })
    ], ScheduledHuntingRuleAlertRelationship);
    return ScheduledHuntingRuleAlertRelationship;
}());
export { ScheduledHuntingRuleAlertRelationship };
var MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
function getStartTimeOfRange(rangeInDays) {
    //add 10 seconds to the start time so that we don't accidentally go over the server's
    //date range limit and get an error
    return new Date(new Date().getTime() - rangeInDays * MILLISECONDS_PER_DAY + 10000);
}
var ɵ2 = function (query) {
    var dataQuery = {
        startTime: getStartTimeOfRange(query.where.range).toISOString(),
        endTime: new Date().toISOString(),
        sortOrder: query.sortBy && query.sortBy[0].direction === 1 ? 'Descending' : 'Ascending',
        pageIndex: query.page || 1,
        pageSize: query.pageSize,
        lastRunOnly: !!query.where.lastRunOnly,
    };
    if (query.sortBy && query.sortBy[0].field) {
        dataQuery.sortByField = query.sortBy[0].field;
    }
    return dataQuery;
}, ɵ3 = function (config, query) {
    return "rules/history/" + query.where.ruleId + "/actions";
}, ɵ4 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ5 = function (rule) {
    return {
        ruleId: rule.id,
    };
};
var ScheduledHuntingRuleActionRelationship = /** @class */ (function () {
    function ScheduledHuntingRuleActionRelationship() {
    }
    ScheduledHuntingRuleActionRelationship = __decorate([
        EntityRelationship({
            sourceEntity: HuntingRule,
            dataEntity: CustomActionRun,
            parseDataQuery: ɵ2,
            endpoint: ɵ3,
            allItemsEndpointTrailingSlash: false,
            allowedTypes: [RelationshipType.OneToMany],
            baseUrl: ɵ4,
            getRelationshipData: ɵ5,
        })
    ], ScheduledHuntingRuleActionRelationship);
    return ScheduledHuntingRuleActionRelationship;
}());
export { ScheduledHuntingRuleActionRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
