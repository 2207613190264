import { FeaturesService, Feature } from '@wcd/config';
import 'd3-transition'; // loaded here explicitly in order to extend d3-select to allow transition chaining (for alert-process-tree and scope-of-breach)
import { debounce, find, findIndex, includes, keys } from 'lodash-es';
import { AuthService } from '@wcd/auth';
import { BreadcrumbsService } from '../../../breadcrumbs/services/breadcrumbs.service';
import { AppConfigService } from '@wcd/app-config';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { MainAppStateService } from '../../../shared/main/services/main-app-state.service';
import { TitleService } from '../../../shared/services/title.service';
import { SevilleDowngradeModule } from '../../seville.downgrade';
import { SevilleAlertsModule } from '../threatintel/alerts/seville.alerts.module';
import { SevilleAppStateModule } from './services/seville.appstate';
import { SevilleAuthenticationModule } from './services/Seville.authentication';
import { SevilleLoadingBarModule } from './services/seville.loading';
import { SevilleUrlMappingModule } from './services/seville.urlMapping';
import { PanelService } from '@wcd/panels';

const createManualLodash = () =>
	({
		includes,
		keys,
		find,
		findIndex,
		debounce,
	} as Partial<_.LoDashStatic>);

declare let angular: angular.IAngularStatic;

export let SevilleModule = angular.module('seville', [
	'sevilleTemplates',
	SevilleAppStateModule.name,
	SevilleUrlMappingModule.name,
	SevilleAuthenticationModule.name,
	SevilleAlertsModule.name,
	SevilleDowngradeModule.name,
	'ui.router',
	'ngResource',
	'ngSanitize',
	'ngAnimate',
	'ui.bootstrap',
	SevilleLoadingBarModule.name,
	'ngAria',
	'ngStorage',
	'angularjs-dropdown-multiselect',
	'ngFileUpload',
	'720kb.tooltips',
	'infinite-scroll',
	'cfp.hotkeys',
	'rzModule',
	'ngContextMenu'
]);

SevilleModule.config([
	'authServiceProvider',
	'$stateProvider',
	'$urlRouterProvider',
	'$locationProvider',
	'$ariaProvider',
	'$httpProvider',
	'hotkeysProvider',
	/*'insightsProvider',*/ '$compileProvider',
	'$provide',
	function(
		authService: AuthService,
		$stateProvider,
		$urlRouterProvider,
		$locationProvider,
		$ariaProvider,
		$httpProvider,
		hotkeysProvider,
		/*insightsProvider,*/ $compileProvider,
		$provide
	) {
		// Adding global lodash-like object with the functions that `angularjs-dropdown-multiselect` and `ng-contextmenu` need since they are implicitly dependant on lodash.
		// @ts-ignore TODO
		window['_'] = createManualLodash();

		$httpProvider.defaults.withCredentials = true;

		// Configure the app to use the HTML 5 url replacement feature when possible
		$locationProvider.html5Mode(true);

		// For any unmatched url, redirect to root state
		$stateProvider.state('otherwise', {
			template: '',
		});

		$urlRouterProvider.otherwise(function($injector, $location) {
			var $state = $injector.get('$state');

			$state.go('otherwise');
		});

		$ariaProvider.config({
			bindRoleForClick: false,
		});

		hotkeysProvider.includeCheatSheet = false;

		$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|sip|chrome-extension):/);
	},
]);

SevilleModule.run([
	'$rootScope',
	'$state',
	'$location',
	'titleService',
	'authService',
	'appStateService',
	'mainAppStateService',
	'breadcrumbsService',
	'appInsights',
	'appConfig',
	'panelService',
	'featuresService',
	function(
		$rootScope,
		$state,
		$location,
		titleService: TitleService,
		authService: AuthService,
		appStateService,
		mainAppStateService: MainAppStateService,
		breadcrumbsService: BreadcrumbsService,
		appInsights: AppInsightsService,
		appConfig: AppConfigService,
		panelService: PanelService,
		featuresService: FeaturesService
	) {
		$rootScope.$on('$stateChangeStart', function(
			event,
			toState,
			toParams,
			fromState,
			fromParams,
			options
		) {
			if (authService.isLoggedIn) {
				if (
					!appConfig.isDeleted &&
					!appConfig.isSuspended &&
					!appConfig.isOnboardingComplete &&
					!/.*onboarding/.test(toState.name) &&
					!featuresService.isEnabled(Feature.OnboardingWizardUpgrade)
				) {
					// user tries to get to app pages, but he is not onboarded
					event.preventDefault();
					$state.go('onboarding.permissions');
				} else if (appConfig.isOnboardingComplete && /.*onboarding/.test(toState.name)) {
					// user tries to get to Onboarding pages, but he is already onboarded
					$location.path('');
				}
			}

			if (toState.redirectTo) {
				event.preventDefault();
				$state.go(toState.redirectTo, toParams, { location: 'replace' });
			}
		});

		$rootScope.$on('$stateChangeSuccess', function(event, toState) {
			const isSeville: boolean = toState.name !== 'otherwise';

			appStateService.setIsSeville(isSeville);

			if (isSeville) {
				breadcrumbsService.legacyShow$.next(!!toState.showBreadcrumbs);
				featuresService.legacyShowFeatureToggle$.next(
					toState.showToggleFeatureId && featuresService.isEnabled(toState.showToggleFeatureId)
						? toState.featureFlagToggleFeatureId
						: undefined
				);
				authService.redirectUrl$.next($state.href($state.current.name, $state.params, {}));
				titleService.setState({ pageTitle: toState.title });
				mainAppStateService.notifyNavChange(toState.name);
				appInsights.trackPageView(
					toState.name,
					window.location.href.replace(window.location.origin, '')
				);
				panelService.closeAllPanels();
			}
		});

		appStateService.init();
	},
]);
