import { Component, OnInit } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { ProfileForCreationFormData } from '../../profiles-tab/models/profile-for-creation.model';

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './profile-name-step.component.html',
})
export class ProfileNameStepComponent extends WizardBaseStep<ProfileForCreationFormData>
	implements OnInit {
	MessageBarType = MessageBarType;

	profileNameDescription: string;
	nameLength = 50;
	descriptionLength = 300;
	nameRegExp = 'notEmpty';
	constructor(public i18n: I18nService) {
		super();
	}

	ngOnInit(): void {
		this.setStepValidation(true);
	}
}
