import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('processor', processorFilter);
processorFilter.$inject = ['$log'];

function processorFilter($log) {
	return function(input) {
		if (!input) {
			return '';
		}

		switch (input) {
			case 'x64':
				return '64-bit';
			case 'x86':
			case 'x32':
				return '32-bit';
			case 'Unknown':
				return '';
			default:
				$log.error('processor filter - unknown value - ' + input);
				return '';
		}
	};
}
