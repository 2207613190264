import {
	Component,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild,
	ViewContainerRef,
	ComponentRef,
	Injector,
	ComponentFactoryResolver,
	Type,
	ComponentFactory,
	OnDestroy,
	AfterViewInit,
} from '@angular/core';
import { EntityWithContext, EntityWithType, EntityContext } from '@wcd/domain';
import { EntityPanelComponentBase } from './entity-panel.component.base';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { EntityModelBase, ModelBase } from '@microsoft/paris';
import { Subject, combineLatest, Subscription } from 'rxjs';

@Component({
	selector: 'entity-with-context-panel',
	template: `
		<!-- entity context details -->
		<section *ngIf="entityContext" class="wcd-margin-vertical wcd-margin-large-horizontal">
			<entity-details-section-header
				*ngIf="entityContext.nameKey"
				[titleKey]="entityContext.nameKey"
			></entity-details-section-header>
			<global-entity-details
				[entity]="entityContext.item"
				[entityType]="entityContext.type"
				[showEntityPanelLink]="false"
			></global-entity-details>
		</section>

		<!-- main entity details -->
		<ng-container #mainEntityDetailsPlaceholder></ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityWithContextPanelComponent<
	TEntity extends EntityModelBase<string | number>,
	TContext extends ModelBase,
	TOptions
> extends EntityPanelComponentBase<EntityWithContext<TEntity, TContext>, TOptions>
	implements AfterViewInit, OnDestroy {
	@ViewChild('mainEntityDetailsPlaceholder', { read: ViewContainerRef, static: false })
	mainEntityDetailsPlaceholder: ViewContainerRef;

	mainEntityDetailsComponent: ComponentRef<EntityPanelComponentBase>;

	get entityContext(): EntityContext<TContext> {
		return this.entity && this.entity.entityContext;
	}

	private setEntitySubscription: Subscription;
	private afterViewInit$: Subject<boolean> = new Subject<boolean>();
	private setEntity$: Subject<boolean> = new Subject<boolean>();

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		private globalEntityTypesService: GlobalEntityTypesService,
		private resolver: ComponentFactoryResolver,
		private injector: Injector
	) {
		super(changeDetectorRef);

		this.setEntitySubscription = combineLatest(this.afterViewInit$, this.setEntity$).subscribe(
			([afterViewInit, isExtendedData]: [boolean, boolean]) => this.setEntityInternal(isExtendedData)
		);
	}

	ngAfterViewInit() {
		this.afterViewInit$.next(true);
	}

	ngOnDestroy() {
		this.setEntitySubscription && this.setEntitySubscription.unsubscribe();
		this.destroyMainEntityDetailsComponent();
	}

	setEntity(entityWithContext: EntityWithContext<TEntity, TContext>, isExtendedData: boolean = false) {
		super.setEntity(entityWithContext, isExtendedData);

		// postpone entity rendering until AfterViewInit by queueing a message in setEntity$
		this.setEntity$.next(isExtendedData);
	}

	private setEntityInternal(isExtendedData: boolean) {
		this.createMainEntityDetailsComponent(this.entity.mainEntity, isExtendedData);
		this.changeDetectorRef.markForCheck();
	}

	private createMainEntityDetailsComponent(mainEntity: EntityWithType<TEntity>, isExtendedData: boolean) {
		if (!this.mainEntityDetailsPlaceholder) return;

		this.destroyMainEntityDetailsComponent();

		const entityType =
				mainEntity && mainEntity.type
					? this.globalEntityTypesService.getEntityType(mainEntity.type)
					: null,
			entityPanelType: Type<EntityPanelComponentBase> = entityType
				? entityType.singleEntityPanelComponentType
				: null,
			factory: ComponentFactory<EntityPanelComponentBase> = entityPanelType
				? this.resolver.resolveComponentFactory<EntityPanelComponentBase>(entityPanelType)
				: null,
			entityPanelRef: ComponentRef<EntityPanelComponentBase> = factory
				? factory.create(this.injector)
				: null;

		this.mainEntityDetailsComponent = entityPanelRef;
		if (!entityPanelRef) return;

		this.mainEntityDetailsPlaceholder.insert(this.mainEntityDetailsComponent.hostView);
		this.mainEntityDetailsComponent.instance.setEntity(mainEntity.item, isExtendedData);
	}

	private destroyMainEntityDetailsComponent() {
		this.mainEntityDetailsComponent && this.mainEntityDetailsComponent.destroy();
		this.mainEntityDetailsPlaceholder && this.mainEntityDetailsPlaceholder.clear();
	}
}
