export function mapMailMessageEntityToAirsEntity(entityData) {
    return Object.entries(entityData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case "Sender" /* Sender */:
                    acc['sender'] = val;
                    break;
                case "SenderIP" /* SenderIP */:
                    acc['sender_ip'] = val;
                    break;
                case "Recipient" /* Recipient */:
                    acc['recipient'] = val;
                    break;
                case "InternetMessageId" /* InternetMessageId */:
                    acc['internet_message_id'] = val;
                    break;
                case "NetworkMessageId" /* NetworkMessageId */:
                    acc['network_message_id'] = val;
                    break;
                case "DeliveryAction" /* DeliveryAction */:
                    acc['delivery_status'] = val;
                    break;
                case "DeliveryLocation" /* DeliveryLocation */:
                    acc['delivery_location'] = val;
                    break;
                case "Files" /* Files */:
                    var attachmentsData = val &&
                        val.reduce(function (res, file) {
                            if (file.Type === 'file') {
                                var sha256 = file.FileHashes &&
                                    file.FileHashes.find(function (hash) { return hash.Algorithm === 'SHA256'; });
                                res.push({
                                    fileName: file.Name,
                                    SHA256: sha256 && sha256.Value,
                                    malwareFamily: file.MalwareFamily,
                                });
                            }
                            return res;
                        }, []);
                    acc['attachments'] = attachmentsData || null;
                    break;
                case "Urls" /* Urls */:
                    acc['urls'] = val;
                    break;
                case "Subject" /* Subject */:
                    acc['subject'] = val;
                    acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
                    break;
                case "ReceivedDate" /* ReceivedDate */:
                    acc['received_date'] = val;
                    break;
                case "P1SenderDisplayName" /* P1SenderDisplayName */:
                    acc['sender_display_name'] = val;
                    break;
                case "Language" /* Language */:
                    acc['language'] = val;
                    break;
                case "Threats" /* Threats */:
                    acc['threats'] = val;
                    break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
}
