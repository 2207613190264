import { ComponentRef, Injectable } from '@angular/core';
import { DataCache, DataQuery, Paris, RelationshipRepository, Repository } from '@microsoft/paris';
import { values, omit, countBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of, merge } from 'rxjs';
import { map, mergeMap, tap, switchMap } from 'rxjs/operators';
import {
	Machine,
	MachineGroup,
	MachineSecurityAnalytics,
	MachineSecurityAnalyticsRelationship,
	MachineSecurityKbToCve,
	MachineTagsCollectionRelationship,
	Tag,
	TagsCollection,
	TagType,
	MachineValue,
	OperatingSystemPlatformCategories,
} from '@wcd/domain';
import { Router } from '@angular/router';
import { PanelSettings, PanelType, PanelService } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { MachineIpsPanelComponent } from '../components/machine-ips.panel.component';
import { MachineLoggedOnUsersPanelComponent } from '../components/machine-logged-on-users.panel.component';
import { MachineSecurityStateComponent } from '../components/machine-score.component';
import { MachineCveComponent } from '../components/machine-security-cve.component';
import { MachineModel, DeviceCategory } from '../models/machine.model';
import { MachinesBackendService, MachineTagsBackendData } from './machines.backend.service';
import { MachinesFiltersService } from './machines.filters.service';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { SerializedFilters } from '@wcd/ng-filters';
import { TvmOsSupportService } from '../../../tvm/services/tvm-os-support.service';
import {
	MachineResourcesPanelComponent,
	MachineResourcesPanelInput,
} from '../components/machine-itp-resources.panel.component';
import {
	MachineDirectoryDataPanelComponent,
	MachineDirectoryDataPanelInput,
} from '../components/machine-itp-directory-data.panel.component';
import { I18nService } from '@wcd/i18n';
import { MachineValuePanelComponent } from '../components/panel/machine-value/machine-value-panel.component';
import { EntityDataViewOptions } from '../../../global_entities/models/entity-type.interface';
import { MachineDataViewFixedOptions } from './machine.entity-type.service';
import { IotDevicesFieldsService } from './iot-fields-service';
import { DatasetBackendOptions, DataviewField } from '@wcd/dataview';
import { RegExpService } from '@wcd/shared';
import { AppFlavorConfig } from '@wcd/scc-common';

const fieldIdOrderMap = {
	riskScores: 'riskscore',
	exposureScores: 'exposurescore',
	healthStatuses: 'healthstatus',
	deviceTypes: 'deviceType',
};

export interface MachineValueConfig {
	id: MachineValue;
	displayName: string;
}

export interface MachineExportResponse {
	sasUri: string;
	isPartial: boolean;
	minIncludedEventTime: string;
}

// Check if we're using the new response for export timeline (older response was just the sasUri as string and not an object)
export const isMachineExportResponse = (obj: any): obj is MachineExportResponse => !!(obj && obj.sasUri);

export interface TabParams {
	onlyManagedDevices?: boolean;
	deviceCategories: DeviceCategory[];
	mainCategory: DeviceCategory;
}

@Injectable()
export class MachinesService {
	private _machineCveComponent: ComponentRef<MachineCveComponent>;
	private _loggedOnUsersPanel: ComponentRef<MachineLoggedOnUsersPanelComponent>;
	private _resourcesPanel: ComponentRef<MachineResourcesPanelComponent>;
	private _directoryDataPanel: ComponentRef<MachineDirectoryDataPanelComponent>;
	private machineTagsRepo: RelationshipRepository<Machine, TagsCollection>;
	private _allMachineTagsCache: DataCache<Array<Tag>> = new DataCache({
		time: 1000 * 60 * 5,
		max: 1,
		getter: () =>
			this.backendService.getMachineGroups().pipe(
				tap(() => this.machinesFiltersService.clearMachinesFilters()),
				map((data: MachineTagsBackendData) =>
					values(data.UserDefinedTags).map(
						(tag: string) => new Tag({ id: tag, name: tag, type: TagType.user })
					)
				)
			),
	});
	private machineGroupsRepo: Repository<MachineGroup>;

	private machineValuePseudoTags = [
		this.doGetMachineValuePseudoTag([MachineValue.High]),
		this.doGetMachineValuePseudoTag([MachineValue.Normal]),
		this.doGetMachineValuePseudoTag([MachineValue.Low]),
	];

	public machineValues: MachineValueConfig[];

	displayedMachines$: BehaviorSubject<Array<Machine>> = new BehaviorSubject<Array<Machine>>([]);

	constructor(
		private dialogsService: DialogsService,
		private machinesFiltersService: MachinesFiltersService,
		private backendService: MachinesBackendService,
		private rbacService: RbacService,
		private paris: Paris,
		private featuresService: FeaturesService,
		private osSupportService: TvmOsSupportService,
		private router: Router,
		private readonly i18nService: I18nService,
		private readonly iotDevicesFieldsService: IotDevicesFieldsService,
		private flavorService: FlavorService,
		private readonly panelService: PanelService
	) {
		this.machineTagsRepo = this.paris.getRelationshipRepository(MachineTagsCollectionRelationship);
		this.machineGroupsRepo = this.paris.getRepository(MachineGroup);
		this.machineValues = [
			{
				id: MachineValue.Low,
				displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_low'),
			},
			{
				id: MachineValue.Normal,
				displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_normal'),
			},
			{
				id: MachineValue.High,
				displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_high'),
			},
		];
	}

	getMachinesFilters(options: SerializedFilters): Observable<Record<string, any>> {
		if (this.featuresService.isEnabled(Feature.TvmMachineValue)) {
			return this.machinesFiltersService.getMachinesFiltersWithPseudoTags(
				options,
				this.machineValuePseudoTags
			);
		} else {
			return this.machinesFiltersService.getMachinesFilters(options);
		}
	}

	clearMachinesFilters() {
		this.machinesFiltersService.clearMachinesFilters();
	}

	downloadCsv(options?: Record<string, any>): Promise<void> {
		const sortByField = options.ordering ? options.ordering.replace(/^-/, '') : 'alerts';
		const sortDirection =
			options.ordering && options.ordering.startsWith('-') ? 'Descending' : 'Ascending';

		let parsedOptions: Record<string, string> = Object.assign(
			{},
			omit(options, ['page', 'page_size', 'ordering']),
			{
				sortByField: 'alerts',
			},
			options.ordering
				? {
						sortByField: fieldIdOrderMap[sortByField] || sortByField,
						sortOrder: sortDirection,
				  }
				: undefined
		);

		const hasOutbreakId = !!options['outbreakId'];
		const hasMitigationTypes = !!options['mitigationTypes'];

		if (hasOutbreakId !== hasMitigationTypes) {
			parsedOptions = omit(parsedOptions, ['outbreakId', 'mitigationTypes']);
		}

		return this.backendService.downloadCsv(parsedOptions);
	}

	getMachineLatestSecurityState(senseMachineId: string): Observable<MachineSecurityAnalytics> {
		return this.paris
			.getItemById<Machine, Machine['id'], Machine['id']>(Machine, senseMachineId)
			.pipe(
				mergeMap((machine) =>
					this.paris.getRelatedItem<Machine, MachineSecurityAnalytics>(
						MachineSecurityAnalyticsRelationship,
						machine
					)
				)
			);
	}

	getAllUserDefinedMachineTags(): Observable<Array<Tag>> {
		return this._allMachineTagsCache.get(true);
	}

	clearAllUserDefinedMachineTags(): void {
		this._allMachineTagsCache.remove(true);
	}

	showMachineScores(
		machineSecurityAnalytics: MachineSecurityAnalytics
	): Observable<ComponentRef<MachineSecurityStateComponent>> {
		return this.dialogsService.showPanel(
			MachineSecurityStateComponent,
			{
				id: 'machine-scores',
				type: PanelType.large,
				noBodyPadding: true,
				persistOnNavigate: false,
			},
			{
				machineSecurityAnalytics: machineSecurityAnalytics,
			}
		);
	}

	getMachineWithAlertParams(alert: {
		lastEventTime?: Date;
		lastSeen?: Date;
		id?: string;
	}): { from: string; to: string } | { alert: string; time: string } {
		if (this.featuresService.isEnabled(Feature.UpgradeMachinePage)) {
			const alertTime: Date = alert && (alert.lastEventTime || alert.lastSeen);

			if (alertTime) {
				// adding extra millisec to the to time to guarantee that tha alert event won't be sliced in the BE.
				alertTime.setMilliseconds(alertTime.getMilliseconds() + 1);
				const alertFrom: Date = new Date(alertTime);
				alertFrom.setDate(alertTime.getDate() - 7);

				return {
					from: alertFrom.toISOString(),
					to: alertTime.toISOString(),
					alert: alert.id,
				};
			}

			return null;
		} else {
			return {
				alert: alert.id,
				time: alert && (alert.lastEventTime || alert.lastSeen).toISOString(),
			};
		}
	}

	getMachineLink(
		machineId: string,
		includeQueryParams: boolean = true,
		alertLastEventTime?: Date,
		alertId?: string
	): string {
		try {
			/*
			Cases of IP machine page - without a supported machine page - MCAS/AATP
			*/
			if (RegExpService.ip.test(machineId)) {
				return null;
			}

			if (this.featuresService.isEnabled(Feature.UpgradeMachinePage)) {
				const alertParams =
					includeQueryParams &&
					this.getMachineWithAlertParams({
						lastEventTime: alertLastEventTime,
						id: alertId,
					});

				const route = ['machines', machineId];
				if (alertLastEventTime) route.push('timeline');

				return this.router.serializeUrl(
					this.router.createUrlTree(
						route,
						includeQueryParams
							? {
									queryParams: alertParams,
							  }
							: {}
					)
				);
			}

			return this.getLegacyMachineLink(machineId, alertLastEventTime);
		} catch (e) {
			return '';
		}
	}

	getLegacyMachineLink(machineId: string, alertLastEventTime: Date) {
		return `/_machine/${machineId}${alertLastEventTime ? '/' + alertLastEventTime.toISOString() : ''}`;
	}

	showMachineCvesPanel(machineSecurityCves: Array<MachineSecurityKbToCve>): void {
		const panelSettings: PanelSettings = Object.assign({
			id: 'machine-security-cves',
			type: PanelType.large,
			persistOnNavigate: false,
			scrollBody: true,
			back: { onClick: () => this._machineCveComponent.destroy() },
		});

		const machinePanelInputs: { [index: string]: any } = {
			machineSecurityCves: machineSecurityCves,
		};

		this.dialogsService
			.showPanel(MachineCveComponent, panelSettings, machinePanelInputs)
			.subscribe((panel: ComponentRef<MachineCveComponent>) => {
				this._machineCveComponent = panel;

				panel.onDestroy(() => {
					this._machineCveComponent = null;
				});
			});
	}

	showMachineLoggedOnUsers(machine: Machine, allowBack: boolean = false, settings?: PanelSettings): void {
		if (
			!(
				machine &&
				machine.os &&
				machine.os.platform &&
				machine.os.platform.category === OperatingSystemPlatformCategories.Linux
			)
		) {
			const panelSettings: PanelSettings = Object.assign(
				{
					id: 'machine-logged-on-users-panel',
					type: PanelType.large,
					isModal: true,
					showOverlay: false,
					isBlocking: false,
					noBodyPadding: true,
					scrollBody: false,
					headerElementId: 'machine-logged-on-users-panel-header',
					back: allowBack
						? {
								onClick: () => this._loggedOnUsersPanel.destroy(),
						  }
						: null,
				},
				settings
			);

			const panelInputs: { [index: string]: any } = {
				machine: machine,
			};

			this.dialogsService
				.showPanel(MachineLoggedOnUsersPanelComponent, panelSettings, panelInputs)
				.subscribe((panel: ComponentRef<MachineLoggedOnUsersPanelComponent>) => {
					this._loggedOnUsersPanel = panel;

					panel.onDestroy(() => {
						this._loggedOnUsersPanel = null;
					});
				});
		}
	}

	showMachineResources(
		machine: Machine,
		allowBack: boolean = false,
		settings?: PanelSettings
	): Observable<ComponentRef<MachineResourcesPanelComponent>> {
		const panelSettings: PanelSettings = Object.assign(
			{
				id: 'machine-resources-panel',
				type: PanelType.large,
				isModal: true,
				showOverlay: false,
				isBlocking: true,
				noBodyPadding: true,
				scrollBody: true,
				headerElementId: 'machine-resources-panel-header',
				back: allowBack
					? {
							onClick: () => this._resourcesPanel.destroy(),
					  }
					: null,
			},
			settings
		);

		const panelInputs: MachineResourcesPanelInput = {
			resources: machine.resources,
		};

		return this.dialogsService.showPanel(MachineResourcesPanelComponent, panelSettings, panelInputs).pipe(
			tap((panel: ComponentRef<MachineResourcesPanelComponent>) => {
				this._resourcesPanel = panel;
				panel.onDestroy(() => {
					this._resourcesPanel = null;
				});
			})
		);
	}

	showMachineDirectoryData(
		machine: Machine,
		allowBack: boolean = false,
		settings?: PanelSettings
	): Observable<ComponentRef<MachineDirectoryDataPanelComponent>> {
		const panelSettings: PanelSettings = Object.assign(
			{
				id: 'machine-directory-data-panel',
				type: PanelType.large,
				isModal: true,
				showOverlay: false,
				isBlocking: true,
				noBodyPadding: true,
				scrollBody: true,
				headerElementId: 'machine-directory-data-panel-header',
				back: allowBack
					? {
							onClick: () => this._directoryDataPanel.destroy(),
					  }
					: null,
			},
			settings
		);

		const panelInputs: MachineDirectoryDataPanelInput = {
			machine: machine,
		};

		return this.dialogsService
			.showPanel(MachineDirectoryDataPanelComponent, panelSettings, panelInputs)
			.pipe(
				tap((panel: ComponentRef<MachineDirectoryDataPanelComponent>) => {
					this._directoryDataPanel = panel;
					panel.onDestroy(() => {
						this._directoryDataPanel = null;
					});
				})
			);
	}

	showMachineIpsPanel(machine: Machine): Observable<ComponentRef<MachineIpsPanelComponent>>;

	/**
	 * Show machine IPs panel. This is a backward compatible version, and should be deleted when the old machine page/side pane are retired.
	 * @deprecated
	 */
	showMachineIpsPanel(machine: MachineModel): Observable<ComponentRef<MachineIpsPanelComponent>>;

	showMachineIpsPanel(machine: Machine | MachineModel): Observable<ComponentRef<MachineIpsPanelComponent>> {
		return this.dialogsService.showPanel(
			MachineIpsPanelComponent,
			{
				id: 'machine-ips-panel',
				type: PanelType.largeFixed,
				width: 500,
				persistOnNavigate: false,
				noBodyPadding: true,
			},
			{
				machine: machine,
			}
		);
	}

	updateMachinesTags(machines: ReadonlyArray<Machine>, tags: ReadonlyArray<Tag>): Observable<any> {
		this.machinesFiltersService.clearMachineTagsApiCall();
		this._allMachineTagsCache.remove(true);
		return this.backendService.updateMachinesTags(
			machines.map((machine) => machine.internalMachineId),
			tags
		);
	}

	clearCachedMachines(): void {
		this.paris.getRepository(Machine).clearCache();
	}

	// TODO: move to machine-groups.service/rbac.service
	getFullUserExposedMachineGroups(options?: DataQuery): Observable<Array<MachineGroup>> {
		const allMachineGroups$ = options
			? this.machineGroupsRepo.query(options).pipe(map((dataSet) => dataSet.items))
			: this.machineGroupsRepo.allItems$;
		return combineLatest([this.rbacService.userExposedRbacGroups$, allMachineGroups$]).pipe(
			map(([userExposedMachineGroups, allMachineGroups]) => {
				return (
					(userExposedMachineGroups &&
						userExposedMachineGroups.length &&
						allMachineGroups.filter((machineGroup) =>
							userExposedMachineGroups.find(
								(userExposedMachineGroup) => userExposedMachineGroup.id === machineGroup.id
							)
						)) ||
					[]
				);
			})
		);
	}

	supportTvmTabs(machine: Machine) {
		return this.osSupportService.isMachineSupported(machine);
	}

	setMachinesValue(machines: ReadonlyArray<Machine>): Promise<void> {
		let machineValue: MachineValue;
		let panel: ComponentRef<MachineValuePanelComponent>;
		return new Promise((resolve, reject) => {
			this.panelService
				.create(
					MachineValuePanelComponent,
					{ id: 'machine-value-panel', type: PanelType.large, showOverlay: true },
					{ machines: machines }
				)
				.pipe(
					switchMap((panel_: ComponentRef<MachineValuePanelComponent>) => {
						panel = panel_;
						return panel.instance.onMachineValueChanged;
					}),
					switchMap((machineValue_: MachineValue) => {
						machineValue = machineValue_;
						return this.updateMachinesValues(machines, machineValue);
					}),
					switchMap((_) => {
						machines.forEach((machine) => (machine.assetValue = machineValue));
						return of(null);
					})
				)
				.subscribe(
					(_) => {
						this.clearCachedMachines();
						panel.instance.requestSuccess();
						resolve();
					},
					(e) => {
						panel.instance.requestFail(e);
						reject(e);
					}
				);
		});
	}

	getMachineValuePseudoTag(machines: ReadonlyArray<Machine>): Observable<ReadonlyArray<Tag>> {
		const pseudoTags: Tag[] = [];
		if (this.featuresService.isEnabled(Feature.TvmMachineValue)) {
			const machinesValues = Object.keys(countBy(machines, (machine) => machine.assetValue)) as Array<
				MachineValue
			>;
			if (Object.keys(machinesValues).length > 1 || machines[0].assetValue !== MachineValue.Normal) {
				pseudoTags.push(this.doGetMachineValuePseudoTag(machinesValues));
			}
		}
		return of(pseudoTags.filter(Boolean) as ReadonlyArray<Tag>);
	}

	private doGetMachineValuePseudoTag(machineValues: Array<MachineValue>): Tag {
		const name = `${this.i18nService.get(
			'machines_entityDetails_actions_machineValue_title'
		)}: ${machineValues.join()}`;
		const isPartial = machineValues.length > 1;
		return new Tag({
			id: isPartial ? 'mixed' : machineValues[0],
			name: name,
			isPartial: isPartial,
			className:
				isPartial || machineValues[0] !== MachineValue.High
					? 'tag-color-box-user'
					: 'tag-color-box-highValueAsset',
			type: TagType.user, //the machine value pseudo tag IS A user tag (controlled by the user)
			tooltip: `${name} - ${this.i18nService.get(
				'machines_entityDetails_actions_machineValue_values_tag_tooltip'
			)}`,
		});
	}

	private updateMachinesValues(machines: ReadonlyArray<Machine>, value: MachineValue): Observable<any> {
		return this.backendService.updateMachinesValues(
			machines.map((machine) => machine.senseMachineId),
			value
		);
	}

	public getSearchViewConfig(
		dataViewOptions: EntityDataViewOptions<Machine, object>,
		magellanOptOut: boolean
	) {
		const displayOnlyMangedDevices =
			magellanOptOut || !this.flavorService.isEnabled(AppFlavorConfig.devices.iotDevices);
		const originalFields = dataViewOptions.fields as readonly DataviewField<Machine, any>[];

		const additionalFilters = displayOnlyMangedDevices ? { isManagedByMdatp: true } : {};
		const fields = displayOnlyMangedDevices
			? originalFields.filter((field) => field.id !== 'onBoardingStatuses')
			: originalFields;

		const defaultDataViewConfig = dataViewOptions.dataViewConfig;
		const defaultFixedOptions = dataViewOptions.dataViewConfig['fixedOptions'];
		const getFiltersData = function (options?: SerializedFilters) {
			return dataViewOptions.dataViewConfig.getFiltersData({
				...options,
			} as SerializedFilters);
		};

		const fixedOptions = { ...defaultFixedOptions, ...additionalFilters } as MachineDataViewFixedOptions;
		const dataViewConfig = { ...defaultDataViewConfig, fixedOptions, getFiltersData };

		return { ...dataViewOptions, dataViewConfig, fields };
	}

	public getTabViewConfig(
		dataViewOptions: EntityDataViewOptions<Machine, object>,
		{ onlyManagedDevices, deviceCategories, mainCategory }: TabParams
	): EntityDataViewOptions<Machine, object> {
		const tabParams: { isManagedByMdatp?: boolean; deviceCategories?: string } = {};

		if (typeof onlyManagedDevices !== 'undefined') {
			tabParams.isManagedByMdatp = onlyManagedDevices;
		}

		if (deviceCategories.length > 0) {
			tabParams.deviceCategories = deviceCategories.join(',');
		}

		const fields =
			mainCategory !== DeviceCategory.Endpoint
				? this.iotDevicesFieldsService.getFields(
						dataViewOptions.fields as Array<DataviewField<Machine>>,
						mainCategory === DeviceCategory.NetworkDevice
				  )
				: dataViewOptions.fields;

		const defaultDataViewConfig = dataViewOptions.dataViewConfig;
		const defaultFixedOptions = dataViewOptions.dataViewConfig['fixedOptions'];
		const getFiltersData = function (options?: SerializedFilters) {
			return dataViewOptions.dataViewConfig.getFiltersData({
				...options,
				mainCategory,
			} as SerializedFilters);
		};

		const fixedOptions = { ...defaultFixedOptions, ...tabParams } as MachineDataViewFixedOptions;
		const dataViewConfig = { ...defaultDataViewConfig, fixedOptions, getFiltersData };

		return { ...dataViewOptions, dataViewConfig, fields };
	}

	isIotOrNetworkDevices(deviceCategories: DeviceCategory[]): boolean {
		return (
			deviceCategories.includes(DeviceCategory.IoT) ||
			deviceCategories.includes(DeviceCategory.NetworkDevice)
		);
	}
}
