import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'intercepting machine',
	pluralName: 'intercepting machines',
})
export class InterceptingMachine extends ModelBase {
	@EntityField({ data: 'DeviceId' })
	deviceId: string;

	@EntityField({ data: 'ActivityTime' })
	lastSeen?: Date;

	@EntityField({ data: 'DeviceName' })
	deviceName?: boolean;
}

@ValueObject({
	singularName: 'device intercepting machines',
	pluralName: 'devices intercepting machines',
})
export class SeenByMachines extends ModelBase {
	@EntityField({ data: 'DeviceId' })
	deviceId: string;

	@EntityField({
		data: 'InterceptingDevices',
		parse: interceptingDevices => interceptingDevices && interceptingDevices[0],
	})
	lastSeenDevice?: InterceptingMachine;

	@EntityField({ data: 'ContainsUnauthorizedDevice' })
	containsUnauthorizedDevice: boolean;

	get isAuthorizedToSeeSeenByData(): boolean {
		return !!this.lastSeenDevice || !this.containsUnauthorizedDevice;
	}
}
