<wcd-panel (close)="destroy()" [settings]="settings">
	<div class="wcd-padding-horizontal wcd-full-height">
		<form class="wcd-full-height wcd-flex-vertical" data-track-component="SetupEvaluationLabComponent">
			<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-medium-right">
				<h3 #panelHeader class="wcd-font-size-xxl wcd-font-weight-bold">
					{{'evaluation.lab.configPanel.title' | i18n}}
				</h3>
				<div class="wcd-margin-vertical">
					<fab-message-bar [messageBarType]="MessageBarType.warning">
						{{ 'evaluation.lab.configPanel.warningMessage' | i18n}}
					</fab-message-bar>
				</div>
				<div class="wcd-flex-1 wcd-scroll-vertical">
					<div class="wcd-margin-bottom wcd-font-weight-bold">
						{{ 'evaluation.lab.configPanel.selectConfigTitle'| i18n}}
					</div>
					<div class="wcd-padding-bottom">
						{{ 'evaluation.lab.configPanel.selectConfigDescription' | i18n}}
					</div>
					<hr class="ms-border-color-neutralQuaternaryAlt wcd-margin-small-vertical"/>
					<radio-list [values]="LAB_CONFIGURATION" name="evaluation-lab-config-selection" [defaultValue]="selectedLabConfiguration" [(ngModel)]="selectedLabConfiguration" [isBordered]="true">
						<ng-template #radioContent let-item>
							<lab-configuration [configuration]="item"></lab-configuration>
						</ng-template>
					</radio-list>
				</div>
			</div>
			<div class="wcd-flex-none">
				<footer>
					<div
						class="wcd-flex-1 wcd-flex-horizontal wcd-margin-top"
						[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
					>
						<fab-primary-button
							data-track-id="CreateLab"
							data-track-type="Button"
							data-track-value="selectedLabConfiguration"
							[text]="'evaluation.lab.configPanel.createButton' | i18n"
							(onClick)="createLab()">
						</fab-primary-button>
						<fab-default-button
							data-track-id="CancelLab"
							data-track-type="Button"
							className="wcd-margin-small-left"
							[text]="'buttons.cancel' | i18n"
							(onClick)="destroy()">
						</fab-default-button>
					</div>
				</footer>
			</div>
		</form>
	</div>
</wcd-panel>
