import { Injector, Input, OnInit } from '@angular/core';
import { DataEntityType, EntityModelBase, ModelBase } from '@microsoft/paris';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { EntityPanelsService } from '../../services/entity-panels.service';
import { EntityPageViewMode } from '../../models/entity-page-view-mode.enum';
import { PanelSettings } from '@wcd/panels';
import { DISPLAY_MODE as DETAILS_DISPLAY_MODE } from '../../../shared/components/details-list/details-list.component';

export class EntityDetailsComponentBase<T extends ModelBase> implements OnInit {
	/**
	 * entity displayed
	 */
	@Input() entity: T;

	/**
	 * determines if entity data was enriched
	 */
	 @Input() dataEnriched: boolean;

	/**
	 * determines weather details are displayed as an inline key-value list ("label: value") or as a block list ("label \n value")
	 */
	@Input() asKeyValueList: boolean = true;

	/**
	 * determines if the component can show "openEntityPanel" links
	 */
	@Input() showEntityPanelLink: boolean = false;

	/**
	 * determines if the component is displayed inside a side panel or an entity page details section
	 */
	@Input() mode: EntityDetailsMode;

	@Input() entityPageViewMode: EntityPageViewMode;

	entityPanelsService: EntityPanelsService;

	private setEntityWasCalled = false;

	dlDisplayMode: DETAILS_DISPLAY_MODE;

	constructor(protected readonly injector: Injector) {
		this.entityPanelsService = this.injector.get(EntityPanelsService);
	}

	/**
	 * Ensures that setEntity is called upon (or was called before) initialization, so derived classes need not implement OnInit
	 */
	ngOnInit() {
		this.dlDisplayMode =
			this.mode === EntityDetailsMode.EntityPage
				? DETAILS_DISPLAY_MODE.COLUMN
				: DETAILS_DISPLAY_MODE.GRID;
		if (this.entity && !this.setEntityWasCalled) {
			this.setEntity(this.entity);
		}
	}

	setEntity(entity: T) {
		this.setEntityWasCalled = true;
		this.entity = entity;
	}

	openEntityPanel(
		entityConstructor: DataEntityType<EntityModelBase<string>>,
		entityId: string
	);
	openEntityPanel(
		entityConstructor: DataEntityType<EntityModelBase<string>>,
		entityOrId: string | EntityModelBase<string>
	) {
		const panelOptions = {};
		const panelSettings: PanelSettings = {
			back: {
				onClick: () => this.entityPanelsService.closeEntityPanel(entityConstructor),
			},
		};

		if (entityOrId instanceof EntityModelBase) {
			const entity = entityOrId;
			if (!(entity instanceof EntityModelBase)) {
				throw new Error('Component is allowed to open panel link, but is not given a valid entity. ');
			}

			this.entityPanelsService.showEntity(entityConstructor, entity, panelOptions, panelSettings);
		} else {
			const entityId = entityOrId;
			this.entityPanelsService.showEntityById(entityConstructor, entityId, panelOptions, panelSettings);
		}
	}
}
