import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { ServiceSourceType } from '../sources/service-source.enum';

export type IncidentLinkedByEntityType =
	| 'Machine'
	| 'File'
	| 'User'
	| 'Url'
	| 'Ip'
	| 'Mailbox'
	| 'MailMessage'
	| 'MailCluster'
	| 'RegistryKey'
	| 'RegistryValue'
	| 'CommandLine';

@ValueObject({
	singularName: 'Entity linked to the alert',
	pluralName: 'Entities linked to the alert',
	readonly: true,
})
export class IncidentLinkedByEntity extends ModelBase {
	/**
	 * TODO: required should be `true`, but currently we manually construct this object in some cases, so it has no id.
	 * Should be made required when deprecation is complete.
	 */
	@EntityField({ data: ['Id', 'id'], required: false })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField({ data: ['Name', 'name'], required: true })
	readonly name: string;

	@EntityField({ data: ['EntityPageUrl', 'entityPageUrl'], required: false })
	readonly entityPageUrl?: string;

	@EntityField({ data: ['AlertProduct', 'alertProduct'], required: false })
	readonly entitySource?: ServiceSourceType;

	@EntityField({ data: ['EvidenceId', 'evidenceId'], required: false })
	readonly evidenceId?: ServiceSourceType;
	/**
	 * Automation should be removed once deprecated types are removed - more specific types instead.
	 */
	@EntityField({ data: ['Type', 'type'], required: true })
	readonly type: IncidentLinkedByEntityType;
}

export const IncidentLinkedByEntityTypeMapper = {
	Machine: 'machine',
	File: 'file',
	User: 'user',
	Url: 'url',
	Ip: 'ip',
	Mailbox: 'mailbox',
	MailMessage: 'mailMessage',
	MailCluster: 'mailCluster',
	CommandLine: 'commandLine'
};
