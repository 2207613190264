import { Injectable } from '@angular/core';
import { AppConfigService } from '@wcd/app-config';
import { AppFlavorService, FlavorConfig } from '@wcd/scc-common';

@Injectable()
export class FlavorService {
	private appFlavorService: AppFlavorService;

	constructor(appConfigService: AppConfigService) {
		this.appFlavorService = new AppFlavorService(
			appConfigService.mdeFlavor,
			appConfigService.isOatpActive,
			appConfigService.isItpActive
		);
	}

	isEnabled(config: FlavorConfig): boolean {
		return this.appFlavorService.isEnabled(config);
	}
}
