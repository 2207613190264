export enum LogType {
	DeviceAlertEvents = 'AdvancedHunting-DeviceAlertEvents',
	DeviceInfo = 'AdvancedHunting-DeviceInfo',
	DeviceNetworkInfo = 'AdvancedHunting-DeviceNetworkInfo',
	DeviceProcessEvents = 'AdvancedHunting-DeviceProcessEvents',
	DeviceNetworkEvents = 'AdvancedHunting-DeviceNetworkEvents',
	DeviceFileEvents = 'AdvancedHunting-DeviceFileEvents',
	DeviceRegistryEvents = 'AdvancedHunting-DeviceRegistryEvents',
	DeviceLogonEvents = 'AdvancedHunting-DeviceLogonEvents',
	DeviceImageLoadEvents = 'AdvancedHunting-DeviceImageLoadEvents',
	DeviceEvents = 'AdvancedHunting-DeviceEvents',
	DeviceFileCertificateInfo = 'AdvancedHunting-DeviceFileCertificateInfo',
	DynamicEventCollection = 'AdvancedHunting-DynamicEventCollection',
}
