import { NgModule } from '@angular/core';
import { CancelInvestigationModalComponent } from './components/cancel-investigation.modal';
import { SharedModule } from '../../shared/shared.module';
import { InvestigationCommentsPanelComponent } from './components/investigation-comments.panel.component';
import { InvestigationCommentsComponent } from './components/investigation-comments.component';
import { CommentsModule } from '../../comments/comments.module';

@NgModule({
	imports: [SharedModule, CommentsModule],
	declarations: [
		CancelInvestigationModalComponent,
		InvestigationCommentsComponent,
		InvestigationCommentsPanelComponent,
	],
	exports: [CancelInvestigationModalComponent, InvestigationCommentsPanelComponent],
	entryComponents: [CancelInvestigationModalComponent, InvestigationCommentsPanelComponent],
})
export class InvestigationsCommonModule {}
