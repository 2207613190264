var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { HuntingDocEntity, HuntingDocQuery, HuntingDocRecord } from './hunting-documentation.entity.base';
var DOCUMENTATION_EXTERNAL_LINK = 'https://docs.microsoft.com/microsoft-365/security/mtp/advanced-hunting-';
var ɵ0 = function (config, query) {
    if (query && query.where && query.where.migrateToVNext) {
        return 'documentation/TableDocumentation';
    }
    return 'hunting/documentation/tableReference';
}, ɵ1 = function (config, query) {
    if (query && query.where && query.where.migrateToVNext) {
        return config.data.serviceUrls.huntingService;
    }
    return config.data.serviceUrls.threatIntel;
};
var HuntingDocTable = /** @class */ (function (_super) {
    __extends(HuntingDocTable, _super);
    function HuntingDocTable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingDocTable.prototype.getExternalLink = function () {
        if (this.HasExternalLink) {
            return "" + DOCUMENTATION_EXTERNAL_LINK + this.name + "-table";
        }
        return null;
    };
    __decorate([
        EntityField({ data: 'Fields', arrayOf: HuntingDocRecord }),
        __metadata("design:type", Array)
    ], HuntingDocTable.prototype, "fields", void 0);
    __decorate([
        EntityField({ data: 'ActionTypes', arrayOf: HuntingDocRecord }),
        __metadata("design:type", Array)
    ], HuntingDocTable.prototype, "actionTypes", void 0);
    __decorate([
        EntityField({ data: 'Queries', arrayOf: HuntingDocQuery }),
        __metadata("design:type", Array)
    ], HuntingDocTable.prototype, "queries", void 0);
    __decorate([
        EntityField({ data: 'HasExternalLink' }),
        __metadata("design:type", Boolean)
    ], HuntingDocTable.prototype, "HasExternalLink", void 0);
    HuntingDocTable = __decorate([
        Entity({
            singularName: 'Hunting table Reference',
            pluralName: 'Hunting tables References',
            endpoint: ɵ0,
            baseUrl: ɵ1
        })
    ], HuntingDocTable);
    return HuntingDocTable;
}(HuntingDocEntity));
export { HuntingDocTable };
export { ɵ0, ɵ1 };
