import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@Entity({
	singularName: 'Outbreak impact',
	pluralName: 'Outbreak impact',
	readonly: true,
	endpoint: 'outbreaks/impact',
	loadAll: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.securityAnalytics,
})
export class OutbreakDailyImpactedDevices extends EntityModelBase<string> {
	@EntityField({ data: 'OutbreakId' })
	outbreakId: string;

	@EntityField({ data: 'CompromisedMachines' })
	compromisedMachines: number;

	@EntityField({ data: 'BlockedMachines' })
	blockedMachines: number;

	@EntityField({ data: 'UpdatedAt' })
	updatedAt: Date;
}
