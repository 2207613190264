/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tvm-active-remediation-tasks.widget.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i3 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../../../../../../../../projects/charts/src/lib/bars/stretched-donut-bars.component.ngfactory";
import * as i5 from "../../../../../../../../projects/charts/src/lib/bars/stretched-donut-bars.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i8 from "@angular-react/fabric";
import * as i9 from "./tvm-active-remediation-tasks.widget";
import * as i10 from "../../../../shared-reports/services/reports.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../services/tvm-machine-groups-filter.service";
import * as i13 from "../../../services/tvm-texts.service";
import * as i14 from "@microsoft/paris/dist/lib/paris";
import * as i15 from "../../../services/tvm-color.service";
import * as i16 from "../../../../shared/pipes/calculate-remaining-days.pipe";
import * as i17 from "../../../../shared/pipes/truncate.pipe";
import * as i18 from "../../../pipes/remediation-task-display-remaining-days.pipe";
import * as i19 from "../../../pipes/remediation-task-fixed-assets.pipe";
var styles_TvmActiveRemediationTasksWidget = [i0.styles];
var RenderType_TvmActiveRemediationTasksWidget = i1.ɵcrt({ encapsulation: 0, styles: styles_TvmActiveRemediationTasksWidget, data: {} });
export { RenderType_TvmActiveRemediationTasksWidget as RenderType_TvmActiveRemediationTasksWidget };
export function View_TvmActiveRemediationTasksWidget_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.I18nPipe, [i3.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 32, "div", [["class", "wcd-full-height wcd-flex-vertical"], ["data-track-component", "TopActiveRemediationTasksWidget"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 24, "div", [["class", "wcd-flex-vertical wcd-flex-1 ie11Patch ie11Flex"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h2", [["class", "wcd-margin-small-top wcd-margin-bottom wcd-font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 1), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "p", [["class", "wcd-margin-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["\n\t\t\t", "\n\t\t"])), i1.ɵppd(12, 1), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "wcd-stretched-donut-bars", [["barCssClass", "wcd-margin-small-bottom"], ["class", "wcd-margin-bottom"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.openRemediationTask($event.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_StretchedDonutBarsComponent_0, i4.RenderType_StretchedDonutBarsComponent)), i1.ɵdid(15, 49152, null, 0, i5.StretchedDonutBarsComponent, [], { data: [0, "data"], barCssClass: [1, "barCssClass"] }, { onClick: "onClick" }), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i1.ɵeld(19, 0, null, null, 8, "div", [["class", "wcd-font-size-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(21, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "fab-icon", [["contentClass", "ms-color-blue"], ["iconName", "SquareShapeSolid"]], null, null, null, i7.View_FabIconComponent_0, i7.RenderType_FabIconComponent)), i1.ɵdid(24, 5816320, null, 0, i8.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(25, null, ["\n\t\t\t\t", "\n\t\t\t"])), i1.ɵppd(26, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(30, 0, null, null, 3, "a", [["class", "wcd-margin-top"], ["data-track-id", "OpenRemediationTasksScreen"], ["data-track-type", "Navigation"], ["tabindex", "0"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openRemediationTasksScreen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(31, 1), (_l()(), i1.ɵted(32, null, ["\n\t\t", "\n\t"])), i1.ɵppd(33, 1), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform(_co.data$)); var currVal_3 = "wcd-margin-small-bottom"; _ck(_v, 15, 0, currVal_2, currVal_3); var currVal_4 = "ms-color-blue"; var currVal_5 = "SquareShapeSolid"; _ck(_v, 24, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), "tvm.activeRemediation.title")); _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), "tvm.activeRemediation.description")); _ck(_v, 11, 0, currVal_1); var currVal_6 = i1.ɵunv(_v, 25, 0, _ck(_v, 26, 0, i1.ɵnov(_v, 0), "tvm.activeRemediation.completed")); _ck(_v, 25, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 30, 0, _ck(_v, 31, 0, i1.ɵnov(_v, 0), "tvm.dashboard.showMore.activeRemediation")); _ck(_v, 30, 0, currVal_7); var currVal_8 = i1.ɵunv(_v, 32, 0, _ck(_v, 33, 0, i1.ɵnov(_v, 0), "tvm.activeRemediation.showMore")); _ck(_v, 32, 0, currVal_8); }); }
export function View_TvmActiveRemediationTasksWidget_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tvm-active-remediation-tasks-widget", [], null, null, null, View_TvmActiveRemediationTasksWidget_0, RenderType_TvmActiveRemediationTasksWidget)), i1.ɵdid(1, 245760, null, 0, i9.TvmActiveRemediationTasksWidget, [i10.ReportsService, i11.Router, i12.TvmMachineGroupsFilterService, i13.TvmTextsService, i14.Paris, i3.I18nService, i15.TvmColorService, i16.CalculateRemainingDaysPipe, i17.TruncatePipe, i18.RemediationTaskDisplayRemainingDaysPipe, i19.RemediationTaskFixedAssetsPipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TvmActiveRemediationTasksWidgetNgFactory = i1.ɵccf("tvm-active-remediation-tasks-widget", i9.TvmActiveRemediationTasksWidget, View_TvmActiveRemediationTasksWidget_Host_0, {}, {}, []);
export { TvmActiveRemediationTasksWidgetNgFactory as TvmActiveRemediationTasksWidgetNgFactory };
