import { NgModule } from '@angular/core';
import { TelemetryDelegateDirective } from './telemetry-delegate.directive';

const exportedDeclarations = [TelemetryDelegateDirective];

@NgModule({
	declarations: [...exportedDeclarations],
	exports: [...exportedDeclarations],
})
export class TelemetryModule {}
