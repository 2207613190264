var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReadOnlyIdentifiable, } from '../../data/models/readonly-identifiable.model';
import { ReportWidgetsLayoutColumnModel, } from './report-widgets-layout.column.model';
import { ReportWidgetsLayoutRowModel } from './report-widgets-layout.row.model';
var DEFAULT_REFRESH_RATE = 60 * 1000;
var MIN_REFRESH_RATE = 5 * 1000;
export var M365_DASHBOARD_WIDGET_CLASS = 'm365-dashboard-report';
var ReportModel = /** @class */ (function (_super) {
    __extends(ReportModel, _super);
    function ReportModel(config) {
        var _this = _super.call(this, config) || this;
        _this.columns = config.columns
            ? config.columns.map(function (columnConfig) {
                return new ReportWidgetsLayoutColumnModel(columnConfig);
            })
            : [];
        _this.rows = config.rows
            ? config.rows.map(function (rowConfig) { return new ReportWidgetsLayoutRowModel(rowConfig); })
            : [];
        _this.allowExport = config.allowExport !== false;
        _this.allowRangeSelect = config.allowRangeSelect !== false;
        _this.rangeConfig = config.rangeConfig;
        _this.className = config.className;
        _this.titleIcon = config.titleIcon;
        _this.params = config.params;
        _this.titleNameKey = config.titleNameKey;
        _this.title = config.title;
        _this.descriptionKey = config.descriptionKey;
        _this.transparent = !!config.transparent;
        _this.autoRefresh = config.autoRefresh !== false;
        if (config.maxWidth)
            _this.maxWidth = Math.max(100, config.maxWidth);
        _this.refreshRate = Math.max(MIN_REFRESH_RATE, config.refreshRate || DEFAULT_REFRESH_RATE);
        _this.fitToHeight = config.fitToHeight === true;
        _this.emptyDashboardComponent = config.emptyDashboardComponent;
        _this.enablePadding = config.enablePadding !== false;
        _this.enableHorizontalPadding = config.enableHorizontalPadding === true;
        _this.overflow = config.overflow || 'all';
        _this.refreshOnQueryParamsChange = config.refreshOnQueryParamsChange !== false;
        _this.showLastLoadTime = config.showLastLoadTime !== false;
        return _this;
    }
    return ReportModel;
}(ReadOnlyIdentifiable));
export { ReportModel };
