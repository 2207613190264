import { Entity, EntityField, EntityModelBase } from "@microsoft/paris";

@Entity({
	singularName: 'Configuration',
	pluralName: 'Configurations',
	endpoint: 'baselineProfileConfigurationIds',
})
export class BaselineProfileConfigurationIds extends EntityModelBase {
	@EntityField() readonly configurationId: string;

	@EntityField() readonly profileId: string;
}
