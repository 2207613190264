import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Event hub properties',
	pluralName: '',
	readonly: true,
})
export class EventHubProperties extends ModelBase {
	@EntityField({ data: 'eventHubResourceId' })
	eventHubResourceId: string;

	@EntityField({ data: 'subscriptionId' })
	subscriptionId: string;

	@EntityField({ data: 'resourceGroup' })
	resourceGroup: string;

	@EntityField({ data: 'namespace' })
	namespace: string;

	@EntityField({ data: 'name' })
	name: string;
}
