import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Undo remediation',
	endpoint: 'investigationactions/launch/undo',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
	parseQuery: (actionIds: Array<number | string>) => {
		return {
			data: Object.assign(
				{
					action_ids: actionIds,
				},
				actionIds && actionIds.length === 1
					? {
							action_id: actionIds[0],
					  }
					: null
			),
		};
	},
})
export class UndoRemediationApiCall extends ApiCallModel<void, Array<number | string>> {}
