
		<button
			[attr.id]="dataviewId + (isInMoreMenu ? '-more' : '')"
			class="hidden-action btn btn-link command-bar-item-button icon-only command-bar-filter"
			[class.checked]="filtersOpened"
			(click)="toggleFilters()"
			data-track-id="toggleFilters"
			data-track-type="Button"
			*ngIf="dataViewModel.allowFilters"
			[attr.aria-label]="'grid.commands.filters.text' | i18n"
			[wcdTooltip]="getLocalizedTooltip()"
			#filterButton
		>
			<fab-icon [iconName]="icon"></fab-icon>
			<span *ngIf="showText && !smallScreenView">{{ 'grid.commands.filters.text' | i18n }}</span>
		</button>

		<wcd-panel
			#filterPanel
			(close)="toggleFilters(false)"
			(open)="toggleFilters(true)"
			class="responsive-panel"
			*ngIf="dataViewModel?.allowFilters && filtersOpened"
			[disableAutoFocus]="disableFilterPanelAutoFocus"
			[class.wcd-padding-large-top]="datatablePadTop"
			[settings]="filtersPanelSettings"
			(keydown.arrowUp)="onKeydown($event)"
			(keydown.arrowDown)="onKeydown($event)"
		>
			<div class="wcd-full-height wcd-flex-center-all" *ngIf="dataViewModel.filtersError; else filters">
				<span class="error-message">
					{{ i18nService.strings.dataview_error_loading_filters }}
				</span>
			</div>
			<ng-template #filters>
				<ng-container *ngIf="dataViewModel.filtersData$ | async as filtersData; else loadingFilters">
					<wcd-filters
						[data]="filtersData"
						[fields]="dataViewModel.filterFields"
						[fixedSelectedValues]="dataViewModel.fixedFilterValues"
						[serializedFilters]="dataViewModel.filtersState?.serialized"
						class="wcd-full-height"
						(filtersChange)="onFiltersChanged($event)"
						(filtersCancel)="onFiltersCancelCallback ? onFiltersCancelCallback() : null"
						[parentFocusable]="filterPanel.binnedFocusOnFirstfocusable"
					>
					</wcd-filters>
				</ng-container>
				<ng-template #loadingFilters>
					<div class="wcd-full-height wcd-flex-center-all">
						<fab-spinner [label]="'filters.loading' | i18n"></fab-spinner>
					</div>
				</ng-template>
			</ng-template>
		</wcd-panel>
	