/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "../../../../../../../projects/shared/src/lib/components/route-link.component.ngfactory";
import * as i5 from "../../../../../../../projects/shared/src/lib/components/route-link.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../../../../../projects/shared/src/lib/services/routes.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i10 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i11 from "@angular/platform-browser";
import * as i12 from "./entity-name.component";
import * as i13 from "../../services/global-entity-types.service";
import * as i14 from "../../services/entity-panels.service";
var styles_EntityNameComponent = [];
var RenderType_EntityNameComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EntityNameComponent, data: {} });
export { RenderType_EntityNameComponent as RenderType_EntityNameComponent };
function View_EntityNameComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "wcd-shared-icon", [], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(1, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"], iconClass: [1, "iconClass"], ariaLabel: [2, "ariaLabel"] }, null), i0.ɵpad(2, 1), i0.ɵpad(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.iconName || (_co.entityType && (_co.entityType.getIcon ? _co.entityType.getIcon(_ck(_v, 2, 0, _co.entity)) : _co.entityType.icon))); var currVal_1 = _co.iconClass; var currVal_2 = (_co.displayAriaLabelOnIcon ? (_co.iconName || (_co.entityType && (_co.entityType.getIcon ? _co.entityType.getIcon(_ck(_v, 3, 0, _co.entity)) : _co.entityType.icon))) : null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_EntityNameComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_EntityNameComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "route-link", [], null, null, null, i4.View_RouteLinkComponent_0, i4.RenderType_RouteLinkComponent)), i0.ɵdid(1, 573440, null, 0, i5.RouteLinkComponent, [i6.UrlHandlingStrategy, i6.Router, i7.RoutesService], { routeLink: [0, "routeLink"], className: [1, "className"], trackId: [2, "trackId"], linkTabIndex: [3, "linkTabIndex"], linkAriaLabel: [4, "linkAriaLabel"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_EntityNameComponent_5)), i0.ɵdid(4, 540672, null, 0, i8.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entityRoute; var currVal_1 = _co.linkClass; var currVal_2 = _co.dataTrackId; var currVal_3 = _co.linkTabIndex; var currVal_4 = _co.linkAriaLabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = i0.ɵnov(_v.parent.parent.parent, 4); _ck(_v, 4, 0, currVal_5); }, null); }
function View_EntityNameComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityNameComponent_4)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entityRoute; var currVal_1 = i0.ɵnov(_v.parent.parent, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_EntityNameComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityNameComponent_2)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityNameComponent_3)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideIcon; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.openEntityPanel; var currVal_2 = i0.ɵnov(_v.parent, 6); _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
function View_EntityNameComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["class", "wcd-text-overflow-ellipsis"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 147456, null, 0, i9.TooltipDirective, [i0.ElementRef, i10.TooltipsService, i11.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipDisplay; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.entityDisplayName; _ck(_v, 3, 0, currVal_1); }); }
function View_EntityNameComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 5, "a", [], [[1, "aria-labelledby", 0], [1, "tabindex", 0]], [[null, "click"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenEntityPanel() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (_co.onOpenEntityPanel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "wcd-text-overflow-ellipsis"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 4).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 4).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 147456, null, 0, i9.TooltipDirective, [i0.ElementRef, i10.TooltipsService, i11.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.tooltipDisplay; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkAriaLabel; var currVal_1 = _co.linkTabIndex; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.entityDisplayName; _ck(_v, 5, 0, currVal_3); }); }
export function View_EntityNameComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityNameComponent_1)), i0.ɵdid(2, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["entityName", 2]], null, 0, null, View_EntityNameComponent_6)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["entityPanel", 2]], null, 0, null, View_EntityNameComponent_7)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entityType; var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_EntityNameComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "entity-name", [], null, null, null, View_EntityNameComponent_0, RenderType_EntityNameComponent)), i0.ɵdid(1, 573440, null, 0, i12.EntityNameComponent, [i13.GlobalEntityTypesService, i14.EntityPanelsService], null, null)], null, null); }
var EntityNameComponentNgFactory = i0.ɵccf("entity-name", i12.EntityNameComponent, View_EntityNameComponent_Host_0, { entity: "entity", entityTypeId: "entityTypeId", entityName: "entityName", entitySource: "entitySource", showTooltip: "showTooltip", tooltip: "tooltip", hideIcon: "hideIcon", iconName: "iconName", iconClass: "iconClass", linkClass: "linkClass", trackId: "trackId", openEntityPanel: "openEntityPanel", displayAriaLabelOnIcon: "displayAriaLabelOnIcon", linkAriaLabel: "linkAriaLabel", linkTabIndex: "linkTabIndex" }, {}, []);
export { EntityNameComponentNgFactory as EntityNameComponentNgFactory };
