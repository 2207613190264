import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig, Machine, AadUser } from '..';
import { Incident } from './incident.entity';
import { IncidentTimelineResponse } from './incident.timeline-response.value-object';

@ApiCall({
	name: 'Incident timeline',
	endpoint: (_, query) => `incidents/${query.where['id']}/timeline`,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	method: 'GET',
	type: IncidentTimelineResponse,
	cache: true,
})
export class IncidentTimelineApiCall extends ApiCallModel<IncidentTimelineResponse, Incident> {}
