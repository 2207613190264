/* tslint:disable:template-accessibility-alt-text */
import { Component } from '@angular/core';
import { FeaturesService, Feature, PreferencesService, Dictionary } from '@wcd/config';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { ConvergencePromotionService } from "../../../../../convergence-promotion/services/convergence-promotion.service";
import { I18nService } from '@wcd/i18n';

const BANNER_DISMISSED = "promotionBannerDismissedTs";

@Component({
	selector: 'promotion-banner-widget',
	template: `
		<convergence-promotion-widget (onDismiss)='dismiss()'></convergence-promotion-widget>
	`,
})
export class PromotionBannerWidget extends ReportWidgetComponent<PromotionBannerData,Dictionary<string, any>> {
	private hide: boolean = false;

	constructor(
		reportsService: ReportsService,
		private featuresService: FeaturesService,
		private preferencesService: PreferencesService,
		private convergencePromotionService: ConvergencePromotionService,
		private i18nService: I18nService
	) {
		super(reportsService);
	}

	dismiss() {
		this.convergencePromotionService.dismiss();
		this.hide = true;
		this.widgetConfig$.next(this.widgetConfig);
	}

	get widgetConfig(): ReportWidgetConfig<PromotionBannerData,Dictionary<string, any>> {
		return {
			id: 'promotion-banner',
			bodyClassName: "no-padding",
			isDisabled: this.hide || !this.convergencePromotionService.shouldShowPromotionBanner(),
			showHeader: false,
			hideOnNoData: true,
			name: this.i18nService.get('promotion_name'),
			loadData: ()=>this.preferencesService.preferences$,
			parseData: (data: Dictionary<string, any>) : PromotionBannerData => {
				return { dismissTs: data.get(BANNER_DISMISSED)};
			}
		};
	}
}

interface PromotionBannerData {
	dismissTs: number;
}
