/* tslint:disable:template-click-events-have-key-events */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Feature, FeaturesService } from '@wcd/config';
import { NEVER, of, Subscription, EMPTY, throwError } from 'rxjs';
import { routeToLegacyAlertRoute } from '../services/alerts.service';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { I18nService } from '@wcd/i18n';
import {
	Alert,
	AlertUser,
	AntivirusBlockDetails,
	AntivirusRemediationAction,
	EntityContext,
	EntityWithContext,
	File,
	FileActionDetails,
	FileInstance,
	FileVirusTotalFileReportRelationship,
	LegacyUser,
	LegacyUserLegacyUserProfileRelationship,
	LegacyUserProfile,
	Machine,
	Process,
	ProcessTree,
	Script,
	TreeElement,
	TreeElementAlertContext,
	TreeElementCyberEntityTypeEnum,
	Url,
	VirusTotalFileReport,
	AUTOMATION_DETECTION_SOURCE_TYPE,
	GenericFieldType,
} from '@wcd/domain';
import { ModelBase, Paris } from '@microsoft/paris';
import {
	AlertInsightErrorType,
	AlertInsightPerformanceType,
	AlertPageService,
	detailsTabSupportedEntityTypes,
} from '../services/alert-page.service';
import { SpinnerSize } from 'office-ui-fabric-react';
import { VirusTotalFileReportService } from '../../files/services/virus-total-file-report.service';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { MachinesService } from '../../machines/services/machines.service';
import { OfficeIntegrationService } from '../../../admin/services/office-integration.service';
import { v4 as uuid4 } from 'uuid';
import { GetAlertsByIdsApiCall, Alerts } from '@wcd/domain';

export const ProcessTreeErrorType = {
	unavailableAlertStory: 'unavailableAlertStory',
	unSupportedAlertStory: 'unSupportedAlertStory',
	unSupportedJson: 'unSupportedJson',
};

@Component({
	templateUrl: './alert-page.component.html',
	styleUrls: ['./alert-page.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AlertPageComponent implements OnInit, OnDestroy {
	subscriptions: Subscription = new Subscription();
	tabs: Array<TabModel> = [];

	treeElements: Array<TreeElement>;
	alertsIds: Set<string> = new Set();
	alertsEntities: Map<string, Alert> = new Map<string, Alert>();
	processTree: ProcessTree;
	officeTenantPrefix: string;
	machine: Machine;
	user: LegacyUser;
	alert: Alert;
	defaultAlertContext: TreeElementAlertContext;

	startLoadTime: number;
	processTreeLoading: boolean = true;
	processTreeError: boolean = false;
	processTreeErrorType: string;
	seeInTimelineLink: string;
	processTreeJsonError: boolean = false;
	allCollapsed: boolean = false;

	readonly correlationId = uuid4();
	readonly SpinnerSize = SpinnerSize;
	readonly genericFieldType = GenericFieldType;
	readonly ProcessTreeErrorType = ProcessTreeErrorType;
	readonly enableImportAlert: boolean = false;

	rootsToRender: number = 0;

	constructor(
		private readonly featuresService: FeaturesService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly i18nService: I18nService,
		private readonly paris: Paris,
		private readonly alertPageService: AlertPageService,
		private readonly machinesService: MachinesService,
		private readonly virusTotalFileReportService: VirusTotalFileReportService,
		private readonly officeIntegrationService: OfficeIntegrationService
	) {
		this.enableImportAlert = this.featuresService.isEnabled(Feature.ImportAlertProcessTree);

		this.subscriptions.add(
			this.featuresService.featureChanged$.subscribe(({ featureId, value: isEnabled }) => {
				if (featureId === Feature.UpgradeAlertPage && !isEnabled) {
					const urlTree = routeToLegacyAlertRoute(this.route.snapshot) as any[];
					this.router.navigate(urlTree, { replaceUrl: true });
				}
			})
		);

		this.setOfficeTenantPrefix();

		this.subscriptions.add(
			router.events.subscribe((val) => {
				if (val instanceof NavigationEnd) {
					this.init();
				}
			})
		);
	}

	ngOnInit() {
		this.startLoadTime = Date.now();
		this.tabs = this.getTabs();

		if (this.enableImportAlert) {
			this.subscribeProcessTreeJson();
		}

		this.init();
	}

	init() {
		performance.mark(AlertInsightPerformanceType.InitiatingAlert);
		// Use the EntityResolver of the parent component to get Alert with the url alert id.
		this.subscriptions.add(
			this.route.data.subscribe(({ entity }) => {
				this.setAlert(entity);
				this.alertPageService.trackPerformance(
					this.alert.id,
					this.correlationId,
					AlertInsightPerformanceType.InitiatingAlert,
					true
				);

				this.setMachine();
				this.setUser(this.alert.user);
				this.setProcessTree();
			})
		);
	}

	private setOfficeTenantPrefix() {
		performance.mark(AlertInsightPerformanceType.InitiatingOfficeTenantPrefix);
		this.subscriptions.add(
			this.officeIntegrationService.isIntegrationEnabled$
				.pipe(
					switchMap((isEnabled: boolean) =>
						isEnabled ? this.officeIntegrationService.getOfficeTenantUrlPrefix() : of('')
					),
					catchError((error) => {
						this.alertPageService.trackErrorInsight(
							this.route.snapshot.params.id,
							this.correlationId,
							AlertInsightErrorType.OfficeIntegrationError
						);
						this.alertPageService.trackPerformance(
							this.alert.id,
							this.correlationId,
							AlertInsightPerformanceType.InitiatingOfficeTenantPrefix,
							false
						);

						return EMPTY;
					})
				)
				.subscribe((officeTenantPrefix: string) => {
					this.officeTenantPrefix = officeTenantPrefix ? officeTenantPrefix : '';

					this.alertPageService.trackPerformance(
						this.route.snapshot.params.id,
						this.correlationId,
						AlertInsightPerformanceType.InitiatingOfficeTenantPrefix,
						true,
						{
							officeTenantPrefix: this.officeTenantPrefix ? 1 : 0,
						}
					);
				})
		);
	}

	private subscribeProcessTreeJson() {
		this.subscriptions.add(
			this.alertPageService.processTreeJson$
				.pipe(
					filter((processTreeJsonString: string) => processTreeJsonString !== ''),
					tap(() => this.alertPageService.updateTrackingStatus(false)),
					switchMap((processTreeJsonString: string) => {
						var processTreeJson: any;
						try {
							processTreeJson = JSON.parse(processTreeJsonString);
						} catch (error) {
							this.onProcessTreeJsonError();
						}

						return processTreeJson ? of(processTreeJson) : NEVER;
					}),
					switchMap((processTreeJson: any) =>
						this.paris.createItem(ProcessTree, processTreeJson).pipe(
							catchError((error: Error) => {
								this.onProcessTreeJsonError();
								return NEVER;
							})
						)
					),
					tap((processTree: ProcessTree) => {
						this.processTree = processTree;
					}),
					switchMap((processTree: ProcessTree) =>
						this.paris.getItemById(Alert, this.processTree.mainAlertId).pipe(
							catchError((error: Error) => {
								this.onProcessTreeJsonError();
								return NEVER;
							})
						)
					)
				)
				.subscribe((alert: Alert) => {
					this.onAlertChangeThroughJSON(alert);
					this.defaultAlertContext = null;
					this.processTreeError = alert == null;
					this.processTreeJsonError = alert == null;
				})
		);
	}

	onProcessTreeJsonError() {
		this.processTreeError = true;
		this.processTreeErrorType = ProcessTreeErrorType.unSupportedJson;
	}

	onAlertChangeThroughJSON(alert: Alert) {
		this.setAlert(alert);
		this.setMachine();
		this.setUser(this.alert.user);
		this.setTreeElements();
	}

	private setAlert(alert: Alert) {
		this.alert = alert;
		this.alertPageService.updateMainAlertPageId(this.alert.id);
		// By default the first entity to be represent in the details tab is the primary alert entity.
		this.alertPageService.updateDetailsTab(
			TreeElementCyberEntityTypeEnum.alert,
			this.alert,
			this.alert.id
		);
	}

	private setProcessTree() {
		performance.mark(AlertInsightPerformanceType.ProcessTree);
		const getStory =
			this.alert.detectionSource.type === AUTOMATION_DETECTION_SOURCE_TYPE
				? throwError('Ignore autoIR alerts as story is not supported')
				: this.paris.getItemById(ProcessTree, this.alert.id, null, {
						correlationId: this.correlationId,
						officeTenantPrefix: this.officeTenantPrefix,
				  });
		this.subscriptions.add(
			getStory
				.pipe(
					catchError((error) => {
						this.setDefaultProcessTree();
						this.alertPageService.trackPerformance(
							this.alert.id,
							this.correlationId,
							AlertInsightPerformanceType.ProcessTree,
							false
						);

						if (this.alert.detectionSource.type === AUTOMATION_DETECTION_SOURCE_TYPE) {
							this.processTreeErrorType = ProcessTreeErrorType.unSupportedAlertStory;
						} else if (error.status === 404 || error.status === 500) {
							this.processTreeErrorType = ProcessTreeErrorType.unavailableAlertStory;
						}

						this.seeInTimelineLink = this.machinesService.getMachineLink(
							this.alert.machine.machineId,
							true,
							this.alert.lastEventTime || this.alert.lastSeen,
							this.alert.id
						);

						this.processTreeLoading = false;
						this.processTreeError = true;

						return NEVER;
					})
				)
				.subscribe((processTree: ProcessTree) => {
					this.processTree = processTree;
					performance.mark(AlertInsightPerformanceType.ProcessTreeRendering);
					this.rootsToRender = processTree.treeElements.length ? 1 : 0;
					if (!this.rootsToRender) {
						this.OnDoneRendering();
					}
					this.setTreeElements();
				})
		);
	}

	private setTreeElements() {
		this.treeElements = null;
		this.processTreeLoading = false;

		if (this.processTree.totalSize > 0) {
			this.treeElements = this.processTree.treeElements;
			this.alertPageService.trackProcessTreeInsight(this.alert.id, this.correlationId, true, {
				numberOfTreeElements: this.processTree.totalSize,
				treeDepth: this.processTree.maxDepth,
			});
			this.alertPageService.trackPerformance(
				this.alert.id,
				this.correlationId,
				AlertInsightPerformanceType.ProcessTree,
				true,
				{
					numberOfTreeElements: this.processTree.totalSize,
					treeDepth: this.processTree.maxDepth,
				}
			);

			if (
				!this.isActualLogonUser(this.alert.user) &&
				this.isActualLogonUser(this.processTree.mainUser)
			) {
				this.setUser(this.processTree.mainUser, true);
			}

			this.collectAlertsIds(this.processTree);
			this.getAlertsEntities();
			this.treeElements.forEach((element) => this.setTreeElementCyberEntity(element, true));
		} else {
			this.setDefaultProcessTree();
			this.alertPageService.trackPerformance(
				this.alert.id,
				this.correlationId,
				AlertInsightPerformanceType.ProcessTree,
				false
			);
		}
	}

	private collectAlertsIds(processTree: ProcessTree) {
		if (processTree.associatedAlerts) {
			processTree.associatedAlerts.forEach((associatedAlert) => {
				this.alertsIds.add(associatedAlert.alertId);
			});
		}
	}

	private getAlertsEntities() {
		this.subscriptions.add(
			this.paris.apiCall(GetAlertsByIdsApiCall, Array.from(this.alertsIds)).subscribe(
				(alertsList: Alerts) => {
					alertsList.alerts.forEach((alert: Alert) => {
						this.alertsEntities.set(alert.id, alert);
					});

					this.treeElements.forEach((element) => this.setAlertsEntities(element));
				},
				() => {}
			)
		);
	}

	private setAlertsEntities(element: TreeElement) {
		if (element.associatedAlerts) {
			element.associatedAlerts.forEach((associatedAlert) => {
				if (this.alertsEntities.has(associatedAlert.alertId)) {
					associatedAlert.cyberEntity = this.alertsEntities.get(associatedAlert.alertId);
				}
			});
		}

		if (element.children) {
			element.children.forEach((childElement) => this.setAlertsEntities(childElement));
		}

		if (element.lastChild) {
			this.setAlertsEntities(element.lastChild);
		}

		if (element.associatedItems) {
			element.associatedItems.forEach((associatedItem) => {
				this.setAlertsEntities(associatedItem);
			});
		}
	}

	private setMachine() {
		this.machine = null;
		performance.mark(AlertInsightPerformanceType.RelatedMachine);
		this.subscriptions.add(
			this.paris.getItemById(Machine, this.alert.machine.machineId).subscribe(
				(machine: Machine) => {
					this.machine = machine;
					this.alertPageService.trackPerformance(
						this.alert.id,
						this.correlationId,
						AlertInsightPerformanceType.RelatedMachine,
						true
					);
				},
				(error: Error) => {
					this.alertPageService.trackErrorInsight(
						this.alert.id,
						this.correlationId,
						AlertInsightErrorType.CyberEntityNotFound
					);
					this.alertPageService.trackPerformance(
						this.alert.id,
						this.correlationId,
						AlertInsightPerformanceType.RelatedMachine,
						false
					);
				}
			)
		);
	}

	private setUser(alertUser: AlertUser, fromProcessTree: boolean = false) {
		this.user = null;
		const performanceType = fromProcessTree
			? AlertInsightPerformanceType.RelatedUserFromProcessTree
			: AlertInsightPerformanceType.RelatedUserFromAlert;
		performance.mark(performanceType);
		if (alertUser) {
			if (this.isActualLogonUser(alertUser)) {
				this.subscriptions.add(
					this.paris.getItemById(LegacyUser, this.getUserQueryString(alertUser)).subscribe(
						(legacyUser: LegacyUser) => {
							this.user = legacyUser;
							this.alertPageService.trackPerformance(
								this.alert.id,
								this.correlationId,
								performanceType,
								true,
								{ userFromParis: 1 }
							);
						},
						(error: Error) => {
							this.alertPageService.trackErrorInsight(
								this.alert.id,
								this.correlationId,
								AlertInsightErrorType.CyberEntityNotFound
							);
							this.alertPageService.trackPerformance(
								this.alert.id,
								this.correlationId,
								performanceType,
								true,
								{ userFromParis: 0 }
							);
							this.user = new LegacyUser({
								id: alertUser.sid,
								sid: alertUser.sid,
								accountDomainName: alertUser.accountDomainName,
								accountName: alertUser.accountName,
							});
						}
					)
				);
			} else {
				this.alertPageService.trackPerformance(
					this.alert.id,
					this.correlationId,
					performanceType,
					true,
					{ userFromParis: 0 }
				);
				this.user = new LegacyUser({
					id: alertUser.sid,
					sid: alertUser.sid,
					accountDomainName: alertUser.accountDomainName,
					accountName: alertUser.accountName,
				});
			}
		} else {
			this.alertPageService.trackPerformance(this.alert.id, this.correlationId, performanceType, false);
		}
	}

	private setDefaultProcessTree() {
		if (!this.alert || !this.alert.id || !this.alert.name) {
			this.alertPageService.trackErrorInsight(
				this.alert ? this.alert.id : '',
				this.correlationId,
				AlertInsightErrorType.NoRelatedAlert
			);
			this.alertPageService.trackProcessTreeInsight('', this.correlationId, false, {
				loadTime: Date.now() - this.startLoadTime,
			});
		} else {
			this.defaultAlertContext = new TreeElementAlertContext(this.alert);
			this.defaultAlertContext.alertId = this.alert.id;
			this.defaultAlertContext.severity = this.alert.severity;
			this.defaultAlertContext.title = this.alert.name;
			this.defaultAlertContext.cyberEntity = this.alert;
			this.alertPageService.trackErrorInsight(
				this.alert.id,
				this.correlationId,
				AlertInsightErrorType.NoProcessTreeCreate
			);
			this.alertPageService.trackProcessTreeInsight(this.alert.id, this.correlationId, false, {
				loadTime: Date.now() - this.startLoadTime,
			});
		}
	}

	private setTreeElementCyberEntity(element: TreeElement, isRoot: boolean = false) {
		element.isRoot = isRoot;

		if (element.cyberEntityType && detailsTabSupportedEntityTypes[element.cyberEntityType]) {
			switch (element.cyberEntityType) {
				case TreeElementCyberEntityTypeEnum.script:
					this.setScriptCyberEntity(element);
					break;

				case TreeElementCyberEntityTypeEnum.process:
					this.setProcessCyberEntity(element);
					break;

				case TreeElementCyberEntityTypeEnum.file:
					this.setFileCyberEntity(element);
					break;

				case TreeElementCyberEntityTypeEnum.url:
					this.setUrlCyberEntity(element);
					break;

				case TreeElementCyberEntityTypeEnum.user:
					this.setUserCyberEntity(element);
					break;

				case TreeElementCyberEntityTypeEnum.defender:
					this.setDefenderCyberEntity(element);
					break;

				default:
					this.setCyberEntityFromParis(element);
					break;
			}
		}

		if (element.children) {
			element.children.forEach((childElement) => this.setTreeElementCyberEntity(childElement));
		}

		if (element.lastChild) {
			this.setTreeElementCyberEntity(element.lastChild);
		}

		if (element.associatedItems) {
			element.associatedItems.forEach((associatedItem) => {
				this.setTreeElementCyberEntity(associatedItem, true);
			});
		}
	}

	// Special case for Script type - do not get it from paris but build it from the hidden details provided by the BE
	private setScriptCyberEntity(element: TreeElement) {
		if (!element.hiddenDetails) {
			return;
		}
		element.cyberEntity = new Script({
			sha1: element.hiddenDetails['SHA1'],
			sha256: element.hiddenDetails['SHA256'],
			id: null,
			content: element.hiddenDetails['Content'],
		});
	}

	private setProcessCyberEntity(element: TreeElement) {
		if (!element.cyberEntityId) {
			return;
		}
		this.subscriptions.add(
			this.paris
				.getItemById(File, element.cyberEntityId, undefined, {
					['newFilesApi']: this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW),
				})
				.subscribe((file: File) => {
					const pid = Number(element.hiddenDetails['ProcessId']);
					const process = new Process({ id: pid });
					process.creationTime = new Date(element.hiddenDetails['CreationTime']);
					if (element.hiddenDetails['IntegrityLevel']) {
						process.integrityLevel = element.hiddenDetails['IntegrityLevel'];
					}
					if (element.hiddenDetails['TokenElevation']) {
						process.tokenElevation = element.hiddenDetails['TokenElevation'];
					}
					if (element.hiddenDetails['DecodedCommandLine']) {
						process.decodedCommandLine = element.hiddenDetails['DecodedCommandLine'];
					}
					process.commandLine = element.hiddenDetails['CommandLine'];
					process.account = element.boldTitle;

					const mainEntity: any = {
						item: new FileInstance({
							...file,
							fileName: element.hiddenDetails['FileLocalName'],
							fullPath: element.hiddenDetails['ImageFilePath'],
						}),
						type: FileInstance,
					};

					const entityContext: EntityContext<ModelBase> = {
						item: process,
						type: Process,
						nameKey: 'alerts.events.details.executionDetails',
					};
					this.setFileCyberEntityAdditionalTypedDetails(element, file);

					element.cyberEntity = new EntityWithContext({
						id: undefined,
						mainEntity: mainEntity,
						entityContext: entityContext,
					});
				})
		);
	}

	private setUrlCyberEntity(element: TreeElement) {
		if (!element.cyberEntityId) {
			return;
		}
		const decodeUrl = decodeURI(decodeURI(element.cyberEntityId));
		element.cyberEntity = new Url({ id: decodeUrl, name: decodeUrl });
	}

	private setFileCyberEntity(element: TreeElement) {
		if (!element.cyberEntityId) {
			return;
		}
		this.subscriptions.add(
			this.paris
				.getItemById(File, element.cyberEntityId, undefined, {
					['newFilesApi']: this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW),
				})
				.subscribe((file: File) => {
					const fileEntity = new FileInstance({
						...file,
						fileName: element.hiddenDetails['FileLocalName'],
						fullPath: element.hiddenDetails['Path'],
					});
					this.setFileCyberEntityAdditionalTypedDetails(element, fileEntity);
					element.cyberEntity = fileEntity;
				})
		);
	}

	private setFileCyberEntityAdditionalTypedDetails(treeElement: TreeElement, fileEntity: File) {
		treeElement.additionalGenericFields.push({
			key: this.i18nService.get('files.entityDetails.sections.details.fields.signer.title'),
			value: fileEntity,
			valueType: this.genericFieldType.Signer,
		});
		this.subscriptions.add(
			this.paris
				.getRelatedItem<File, VirusTotalFileReport>(FileVirusTotalFileReportRelationship, fileEntity)
				.subscribe((virusTotalFileReport) => {
					if (this.hasVirusTotalFileReport(virusTotalFileReport)) {
						treeElement.additionalGenericFields.push({
							key: this.i18nService.get(
								'files.entityDetails.sections.detections.fields.virusTotal.title'
							),
							value: this.virusTotalContext(virusTotalFileReport),
							valueType: this.genericFieldType.VirusTotalFileReport,
						});
					}
				})
		);
	}

	private hasVirusTotalFileReport(virusTotalFileReport: VirusTotalFileReport): boolean {
		return this.virusTotalFileReportService.hasVirusTotalData(virusTotalFileReport);
	}

	private virusTotalContext(virusTotalFileReport: VirusTotalFileReport) {
		return this.virusTotalFileReportService.toValueWithExternalLinkContext(virusTotalFileReport);
	}

	private setUserCyberEntity(element: TreeElement) {
		if (
			!element.cyberEntityId ||
			this.isSystemSid(element.cyberEntityId) ||
			this.isSystemUserName(element.cyberEntityId)
		) {
			return;
		}
		this.subscriptions.add(
			this.paris
				.getItemById(LegacyUser, element.cyberEntityId)
				.pipe(
					switchMap((legacyUser: LegacyUser) => {
						element.cyberEntity = legacyUser;
						return this.paris.getRelatedItem<LegacyUser, LegacyUserProfile>(
							LegacyUserLegacyUserProfileRelationship,
							legacyUser
						);
					})
				)
				.subscribe((profile) => {
					this.setUserCyberEntityAdditionalTypedDetails(element, profile);
				})
		);
	}

	private setUserCyberEntityAdditionalTypedDetails(treeElement: TreeElement, profile: LegacyUserProfile) {
		if (!profile) {
			return;
		}
		if (profile.department) {
			treeElement.additionalGenericFields.push({
				key: this.i18nService.get('users.entityDetails.sections.details.department.title'),
				value: profile.department,
				valueType: this.genericFieldType.String,
			});
		}
		if (profile.title) {
			treeElement.additionalGenericFields.push({
				key: this.i18nService.get('users.entityDetails.sections.details.jobTitleUserProfile.title'),
				value: profile.title,
				valueType: this.genericFieldType.String,
			});
		}
		if (profile.name) {
			treeElement.additionalGenericFields.push({
				key: this.i18nService.get('users.entityDetails.name'),
				value: profile.name,
				valueType: this.genericFieldType.String,
			});
		}
		treeElement.additionalGenericFields.push({
			key: this.i18nService.get('users.entityDetails.sections.contactDetails.title'),
			value: treeElement.cyberEntity,
			valueType: this.genericFieldType.ContactDetails,
		});
	}

	private setDefenderCyberEntity(element: TreeElement) {
		if (!element.cyberEntityId) {
			return;
		}
		this.subscriptions.add(
			this.paris
				.getItemById(File, element.cyberEntityId, undefined, {
					['newFilesApi']: this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW),
				})
				.subscribe((file: File) => {
					const blockDetails = new AntivirusBlockDetails({ id: element.cyberEntityId });
					if (element.hiddenDetails['ThreatName']) {
						blockDetails.threatName = element.hiddenDetails['ThreatName'];
					}
					if (element.hiddenDetails['WasRemediated']) {
						blockDetails.wasRemediated = element.hiddenDetails['WasRemediated'] === 'True';
					} else {
						blockDetails.wasRemediated = null;
					}
					if (element.hiddenDetails['wasExecutingWhileDetected']) {
						blockDetails.wasExecutingWhileDetected =
							element.hiddenDetails['wasExecutingWhileDetected'] === 'True';
					} else {
						blockDetails.wasExecutingWhileDetected = null;
					}
					if (element.hiddenDetails['Container']) {
						blockDetails.container = element.hiddenDetails['Container'];
					}
					if (element.hiddenDetails['RemediationAction']) {
						switch (element.hiddenDetails['RemediationAction']) {
							case 'clean':
								blockDetails.remediationAction = AntivirusRemediationAction.Clean;
								break;
							case 'quarantine':
								blockDetails.remediationAction = AntivirusRemediationAction.Quarantine;
								break;
							case 'remove':
								blockDetails.remediationAction = AntivirusRemediationAction.Remove;
								break;
							case 'block':
								blockDetails.remediationAction = AntivirusRemediationAction.Block;
								break;
						}
					}

					const fileInstance: any = {
						item: new FileInstance({
							...file,
							fileName: element.hiddenDetails['FileLocalName'],
							fullPath: element.hiddenDetails['FilePath'],
						}),
						type: FileInstance,
					};

					const fileAction: FileActionDetails = new FileActionDetails({
						id: element.cyberEntityId,
						actionTime: element.time,
						actionType: 'WDAVDetection',
						file: fileInstance.item,
						blockDetails: blockDetails,
					});

					const entityContext: EntityContext<ModelBase> = {
						item: fileAction,
						type: FileActionDetails,
						nameKey: 'alerts.events.details.blockDetails',
					};

					element.cyberEntity = new EntityWithContext({
						id: undefined,
						mainEntity: fileInstance,
						entityContext: entityContext,
					});
				})
		);
	}

	private setCyberEntityFromParis(element: TreeElement) {
		if (!element.cyberEntityId) {
			return;
		}
		this.subscriptions.add(
			this.paris
				.getItemById(detailsTabSupportedEntityTypes[element.cyberEntityType], element.cyberEntityId)
				.subscribe(
					(entity) => {
						element.cyberEntity = entity;
					},
					(error: Error) => {
						this.alertPageService.trackErrorInsight(
							this.alert.id,
							this.correlationId,
							AlertInsightErrorType.CyberEntityNotFound
						);
					}
				)
		);
	}

	private getTabs(): Array<TabModel> {
		const tabs: Array<{ id: string }> = [
			{
				id: 'details',
			},
		];

		if (this.enableImportAlert) {
			tabs.push({
				id: 'importAlert',
			});
		}

		return tabs.map(
			(tab) =>
				new TabModel({
					name: this.i18nService.get(`alert.tabs.${tab.id}.title`),
					routerLink: `./${tab.id}`,
					...tab,
				})
		);
	}

	setTreeMinimized(isMinimized: boolean) {
		this.allCollapsed = !this.allCollapsed;
		this.treeElements.forEach((element) => this.setTreeMinimizedRecursive(element, isMinimized));
	}

	private setTreeMinimizedRecursive(element: TreeElement, isMinimized: boolean) {
		element.isMinimized = isMinimized;
		element.children.forEach((child) => this.setTreeMinimizedRecursive(child, isMinimized));
		if (element.lastChild) {
			this.setTreeMinimizedRecursive(element.lastChild, isMinimized);
		}
	}

	getUserQueryString(user: AlertUser): string {
		if (user.accountDomainName && user.accountName) {
			return user.accountName + '/' + user.accountDomainName + '/' + user.sid;
		} else {
			return user.sid;
		}
	}

	isActualLogonUser(alertUser: AlertUser): boolean {
		if (!alertUser || (!alertUser.sid && (!alertUser.name || !alertUser.domain))) {
			return false;
		}
		return !this.isSystemUser(alertUser);
	}

	isSystemUser(alertUser: AlertUser): boolean {
		if (!alertUser) {
			return false;
		}
		if (alertUser.sid && alertUser.sid != '') {
			return this.isSystemSid(alertUser.sid);
		}
		if (alertUser.fullName && alertUser.fullName != '') {
			return this.isSystemUserName(alertUser.fullName);
		}
		return false;
	}

	isSystemSid(sid: String): boolean {
		if (!sid) {
			return false;
		}
		const minNumOfDashesInRealUserSid = 5; // actual users (not local system) sid has at least '-' in it
		return sid.match(/-/gi) == null || sid.match(/-/gi).length < minNumOfDashesInRealUserSid;
	}

	isSystemUserName(userName: string) {
		const trivialUserNames = [
			'NT AUTHORITY\\NETWORK SERVICE',
			'NT AUTHORITY\\',
			'NETWORK SERVICE',
			'NT AUTHORITY\\SYSTEM',
			'SYSTEM',
			'NT AUTHORITY\\LOCAL SERVICE',
			'LOCAL SERVICE',
			'',
		];
		return trivialUserNames.includes(userName.toUpperCase());
	}

	OnDoneRendering() {
		setTimeout(() => {
			this.alertPageService.trackPerformance(
				this.alert.id,
				this.correlationId,
				AlertInsightPerformanceType.ProcessTreeRendering,
				true
			);
		}, 0);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
