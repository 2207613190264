import { Injectable } from '@angular/core';
import {
	EntityDataViewOptions,
	EntityType,
} from '../../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../../global_entities/models/entity-type-service.interface';
import {
	RemediationTask,
	RemediationTaskStateValue,
	MdeUserRoleActionEnum,
	Tag,
	RemediationCategory,
} from '@wcd/domain';
import { RemediationTaskEntityPanelComponent } from '../components/remediation-task.entity-panel.component';
import { RemediationTaskEntityDetailsComponent } from '../../../../../global_entities/components/entity-details/remediation-task.entity-details.component';
import { Paris } from '@microsoft/paris';
import { RemediationTasksFieldsService } from './remediation-tasks.fields.service';
import { FabricIconNames } from '@wcd/scc-common';
import { ItemActionModel, ItemActionModelConfig } from '../../../../../dataviews/models/item-action.model';
import { RemediationTaskService } from './remediation-task.service';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
import { TvmMachineGroupsFilterService } from '../../../../../tvm/services/tvm-machine-groups-filter.service';
import { take, switchMap, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { TvmTagsService } from '../../../../../tvm/services/tvm-tags.service';

export interface RemediationTaskDataViewFixedOptions {}

export const FILTER_ONLY_REMEDIATION_REQUEST_FIELDS = [];

@Injectable()
export class RemediationTaskEntityTypeService implements EntityTypeService<RemediationTask> {
	constructor(
		private readonly paris: Paris,
		private authService: AuthService,
		private i18nService: I18nService,
		private remediationTaskService: RemediationTaskService,
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		private dialogsService: DialogsService,
		private tvmTagsService: TvmTagsService
	) {}

	readonly entityType: EntityType<RemediationTask> = {
		id: 'RemediationTask',
		entity: RemediationTask,
		entityDetailsComponentType: RemediationTaskEntityDetailsComponent,
		singleEntityPanelComponentType: RemediationTaskEntityPanelComponent,
		getEntityName: remediationTask => remediationTask.name,
		getEntityDataviewLink: () => '/remediation-tasks',
		getActions: remediationTasks => {
			const actions: Array<ItemActionModelConfig> = [];
			if (remediationTasks.length !== 1) {
				return of([]);
			}

			const userAllowedToEditRemediation = this.authService.currentUser.hasMdeAllowedUserRoleAction(
				MdeUserRoleActionEnum.tvmRemediationHandling
			);

			return this.machineGroupsFilterService.machineGroupsFilter$.pipe(
				take(1),
				switchMap(gr => {
					if (remediationTasks[0].status.value !== 'Completed') {
						actions.push({
							id: 'markAsCompleted',
							nameKey: 'tvm.remediationTasksActions.markAsCompleted',
							icon: FabricIconNames.Completed,
							closeOnAction: true,
							// mark task as completed is disabled in filter mode, if the user is not allowed to edit remediation, or if the user is not a global admin and not authorized to the RBAC groups of the task
							disabled: !(
								!gr.isFiltering &&
								userAllowedToEditRemediation &&
								(this.authorizedToAllTaskRbacGroups(remediationTasks[0]) ||
									this.authService.isGlobalAdmin)
							),
							tooltip: gr.isFiltering
								? this.i18nService.get(`tvm.remediationTasksActions.inFilterModeToolTip`)
								: !userAllowedToEditRemediation
								? this.i18nService.get(
										`tvm.remediationTasksActions.RolesBasedDisabledMarkAsCompletedToolTip`
								  )
								: !this.authorizedToAllTaskRbacGroups(remediationTasks[0])
								? this.i18nService.get(
										`tvm.remediationTasksActions.unauthorizedRbacDisabledMarkAsCompletedToolTip`
								  )
								: undefined,
							method: (remediationTasks: Array<RemediationTask>) => {
								const updatedRemediationTask: Partial<RemediationTask> = {
									id: remediationTasks[0].id,
									status: {
										value: RemediationTaskStateValue.Completed,
									},
								};
								const repo = this.paris.getRepository(RemediationTask);
								return repo
									.save(updatedRemediationTask)
									.pipe(
										catchError((error, caught) => {
											this.dialogsService.showError({
												title: this.i18nService.get(
													'tvm.remediationTasksActions.markAsCompletedFailTitle'
												),
												data: this.i18nService.get(
													'tvm.remediationTasksActions.markAsCompletedFailMessage'
												),
											});
											return throwError(error);
										})
									)
									.toPromise();
							},
						});
					}

					actions.push({
						id: 'exportToCSV',
						nameKey: 'tvm.remediationTasksActions.exportToCSV',
						icon: FabricIconNames.ExcelLogo,
						closeOnAction: true,
						method: (remediationTasks: Array<RemediationTask>) => {
							return this.remediationTaskService.downloadRemediationTaskCsv(
								remediationTasks[0]
							);
						},
					});

					return of(actions.map(itemActionConfig => new ItemActionModel(itemActionConfig)));
				})
			);
		},
		getTags: (remediationTasks: RemediationTask[]) =>
			this.tvmTagsService.getRemediationTags(remediationTasks[0]),
		loadFullEntityInPanel: false,
		dataViewOptions: <EntityDataViewOptions<RemediationTask, RemediationTaskDataViewFixedOptions>>{
			fields: RemediationTasksFieldsService,
		},
	};

	// Verify that the user is exposed to all RBAC groups of the task. We still want to support old tasks with no information on RBAC groups.
	authorizedToAllTaskRbacGroups(remediationTask: RemediationTask) {
		return (
			(!remediationTask.originalRbacGroupIds && !remediationTask.displayedRbacGroupIds) ||
			remediationTask.originalRbacGroupIds.value.every(
				x => remediationTask.displayedRbacGroupIds.value.indexOf(x) !== -1
			)
		);
	}
}
