import { OnChanges, SimpleChanges } from '@angular/core';
import { File, FileFileStatsRelationship, FileVirusTotalFileReportRelationship, AirsFileBase, } from '@wcd/domain';
import { EMPTY, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { FeaturesService } from '@wcd/config';
var AirsFileDetailsComponent = /** @class */ (function () {
    function AirsFileDetailsComponent(paris, i18nService, featuresService) {
        var _this = this;
        this.paris = paris;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.fileRepo = this.paris.getRepository(File);
        this.sha1$ = new Subject();
        this.file$ = this.sha1$.pipe(distinctUntilChanged(), switchMap(function (sha1) { return (sha1 ? _this.fileRepo.createItem({ Sha1: sha1 }) : EMPTY); }));
        this.vtData$ = this.file$.pipe(switchMap(function (f) {
            return _this.paris.getRelatedItem(FileVirusTotalFileReportRelationship, f);
        }));
        this.fileStats$ = this.file$.pipe(switchMap(function (f) { return _this.paris.getRelatedItem(FileFileStatsRelationship, f, { where: { filesPrefix: _this.featuresService.isEnabled('K8SMigration-EPSFilePrevalence-kw') } }); }));
    }
    Object.defineProperty(AirsFileDetailsComponent.prototype, "file", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsFileDetailsComponent.prototype, "appendedFile", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    AirsFileDetailsComponent.prototype.ngOnChanges = function (changes) {
        this.sha1$.next(this.entity.sha1);
    };
    AirsFileDetailsComponent.prototype.openInNewTab = function (url) {
        window.open(url, '_blank');
    };
    return AirsFileDetailsComponent;
}());
export { AirsFileDetailsComponent };
