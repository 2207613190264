<dataview
	[repository]="repository"
	[navigateOnChange]="false"
	[ignoreQueryParams]="true"
	[disableSelection]="true"
	[allowColumnCustomization]="false"
	[allowFilters]="false"
	[searchEnabled]="false"
	[fields]="fields"
	[allowPaging]="true"
	[padLeft]="true"
	[loadingMessageText]="loadingMessageText"
	[dataViewConfig]="dataViewConfig"
	(onItemClick)="openMachinePage($event.item)">
</dataview>
