import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ThreatProtectionDetectionSourceWidget } from './threat-protection-detection-source.widget';

@Component({
	template: `
		<wcd-stacked-bars-chart [data]="data$ | async" [settings]="chartSettings$ | async">
		</wcd-stacked-bars-chart>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreatProtectionDetectionSourceDashboardWidget extends ThreatProtectionDetectionSourceWidget {
	// this override const height of 300px of parent BaseReportWidgetComponent widget config
	getHeight(): string {
		return undefined;
	}
}
