import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isUndefined, omitBy } from 'lodash-es';
import { combineLatest, Observable, Subject, Subscription, merge, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { EntityDataViewOptions, EntityType } from '../../global_entities/models/entity-type.interface';
import { I18nService } from '@wcd/i18n';
import { TextHighlightDirective } from '@wcd/angular-extensions';
import { TitleService } from '../../shared/services/title.service';
import { SearchResultsRouteData } from '../search-routing.module';
import { DataChangedPayload } from '../../dataviews/components/dataview.component';
import { DataSet, ModelBase } from '@microsoft/paris';
import { breadcrumbsStateService } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';
import { RoutesService } from '@wcd/shared';
import { Machine, ServiceSourceType } from '@wcd/domain';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AdvancedFeaturesService } from '../../admin/integration-settings/advanced-features.service';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { MachinesService } from '../../@entities/machines/services/machines.service';
import { AppConfigService } from '@wcd/app-config';

const Range30Days = 30;
const Range180Days = 180;

@Component({
	styleUrls: ['./search-results.component.scss'],
	template: `
		<div class="wcd-full-height wcd-flex-1 search-results">
			<entity-dataview
				[textHighlight]="searchTerm$ | async"
				tags="wcd-datatable-field-value"
				[entityType]="(entityType$ | async).entity"
				[options]="dataViewOptions$ | async"
				[disableFilterPanelAutoFocus]="true"
				(fixedOptionsChanged)="onFixedOptionsChanged($event)"
				(dataChanged)="onDataChanged($event)"
				(newItemAdded)="updateTextHighlight()"
				(groupExpanded)="updateTextHighlight()"
				[queueHeader]="true"
				[padLeft]="dataviewLeftPadding"
				commandBarNoPadding="true"
				[removePadding]="isScc"
				[removePaddingRight]="isScc"
				[responsiveActionBar]="true"
			>
				<div dataview-header class="results-header">
					<h3>{{ header$ | async }}</h3>
				</div>
			</entity-dataview>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent<TEntity extends ModelBase, TFixedOptions extends object = {}>
	implements OnDestroy {
	readonly header$: Observable<string>;
	readonly entityType$: Observable<EntityType<TEntity>>;
	readonly dataViewOptions$: Observable<EntityDataViewOptions<TEntity, TFixedOptions>>;
	readonly searchTerm$: Observable<string>;
	isFocused = false;
	entityTypeId: any;
	range?: number;
	dataviewLeftPadding: boolean;
	isScc = sccHostService.isSCC;

	private currentSearchTerm: string;
	private fixedOptions: TFixedOptions;
	private readonly subscriptions: Subscription[] = [];
	private resultsCount$ = new Subject<number | undefined>();
	private currentResultData: DataSet<any>;
	private getEntitiesLink: Function;
	private getNavigationModel: Function;

	@ViewChild(TextHighlightDirective, { static: false })
	private readonly textHighlightDirective: TextHighlightDirective;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private i18nService: I18nService,
		titleService: TitleService,
		private changeDetectorRef: ChangeDetectorRef,
		private routesService: RoutesService,
		private advancedFeaturesService: AdvancedFeaturesService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private machinesService: MachinesService,
		private appConfigService: AppConfigService,
		private liveAnnouncer: LiveAnnouncer
	) {
		this.subscriptions.push(
			this.route.queryParamMap
				.pipe(
					map(queryParams => queryParams.get('range')),
					filter(Boolean),
					map(Number)
				)
				.subscribe(range => (this.range = range))
		);

		this.searchTerm$ = route.paramMap.pipe(map(paramMap => paramMap.get('term')));
		this.entityType$ = route.data.pipe(
			map((data: SearchResultsRouteData) => data.entityType as any as EntityType<TEntity>)
		);

		this.subscriptions.push(this.searchTerm$.subscribe(term => (this.currentSearchTerm = term)));
		this.header$ = merge(this.searchTerm$, this.resultsCount$).pipe(
			map((resultsCount: any) => {
				if (typeof resultsCount === 'string') {
					return '';
				}

				if (resultsCount === 1) {
					if (this.entityTypeId === 'user') {
						const link = this.currentResultData.items[0];
						const { routerLink, queryParams } = this.getNavigationModel(link, ServiceSourceType.Wdatp);
						this.router.navigate(routerLink, { queryParams })
					} else {
						this.router.navigate([this.routesService.getMdatpFromSccUrl(this.getEntitiesLink(this.currentResultData.items))]);
					}
					return '';
				}

				if (
					(!this.range || this.range === Range30Days) &&
					resultsCount === 0 &&
					this.entityTypeId === 'machine'
				) {
					this.router.navigate(['.'], {
						relativeTo: this.route,
						queryParams: Object.assign({ range: Range180Days }, { pageIndex: null }),
						queryParamsHandling: 'merge',
					});
				}
				const key =
					resultsCount === 0
						? 'main.searchResults.header.empty'
						: resultsCount > 100
						? 'main.searchResults.header.capped'
						: 'main.searchResults.header.full';
				const params = {
					term: this.currentSearchTerm,
					count: resultsCount > 100 ? 100 : resultsCount,
				};
				return i18nService.get(key, params);
			})
		);

		this.subscriptions.push(this.header$.subscribe((message) => {
				setTimeout(() => {
					if (message) {
					liveAnnouncer.announce(message, 'assertive')
					}
			})
		}))
		this.subscriptions.push(
			this.entityType$.subscribe(entityType => {
				this.getEntitiesLink = entityType.getEntitiesLink;
				this.getNavigationModel = entityType.getNavigationModel;
				this.entityTypeId = entityType.id;
				titleService.setState({
					pageTitle: i18nService.get('main.searchResults.pageTitle', {
						entityTypeName: entityType.entity.pluralName,
					}),
				});
			})
		);

		this.dataViewOptions$ = combineLatest(this.entityType$, this.searchTerm$).pipe(
			map(([entityType, searchTerm]: [EntityType<any>, string]) => {
				const originalDataViewOptions = entityType.dataViewOptions;
				const { exportOptions, fixedOptions } = originalDataViewOptions;
				let dataViewOptions = originalDataViewOptions;

				if (entityType.id === this.globalEntityTypesService.getEntityType<Machine>(Machine).id) {
					dataViewOptions = this.machinesService.getSearchViewConfig(
						originalDataViewOptions,
						this.appConfigService.magellanOptOut
					);
				}

				return omitUndefinedProperties({
					...dataViewOptions,
					fixedOptions:
						fixedOptions &&
							(currentDateRange =>
							originalDataViewOptions.fixedOptions(currentDateRange, searchTerm)),
					exportOptions: exportOptions && {
						showModalOnExport: exportOptions.showModalOnExport,
						exportResults:
							exportOptions.exportResults &&
								(options =>
								exportOptions.exportResults(options, this.fixedOptions, searchTerm)),
					},
				}) as EntityDataViewOptions<TEntity, TFixedOptions>;
				}
			)
		);

		this.dataviewLeftPadding = !sccHostService.isSCC;
	}

	ngOnDestroy() {
		if (this.subscriptions) {
			this.subscriptions.forEach(subscription => subscription.unsubscribe());
		}
	}

	onFixedOptionsChanged(fixedOptions: TFixedOptions) {
		this.fixedOptions = fixedOptions;

		this.updateTextHighlight();
	}

	onDataChanged({ data }: DataChangedPayload) {
		this.currentResultData = data;
		this.resultsCount$.next(data.count || data.items.length);
		this.isFocused = false;
		// Focus the search result header, so after a search the narrator would announce the search result summary
		setTimeout(() => {
			this.isFocused = true;
			this.changeDetectorRef.markForCheck();
		}, 50);
		this.updateTextHighlight();
		this.setBreadcrumbs();
	}

	updateTextHighlight() {
		this.textHighlightDirective && this.textHighlightDirective.updateHighlights();
	}

	private setBreadcrumbs() {
		if (sccHostService.isSCC) {
			return;
		}
		// Since there are several components that handle the search results and breadcrumbs themselves
		// (e.g. - users search results, software, etc.), the breadcrumbs for search results can't be
		// set in the module router using the breadcrumb config.
		// In addition, setting the breadcrumb here, only works for times where it is not handled elsewhere.
		const snapshot = this.route.snapshot;

		if (snapshot && snapshot.params && snapshot.params['term']) {
			breadcrumbsStateService.set({
				id: 'searchResults',
				label: this.i18nService.get('main.searchResults.title'),
				url: snapshot.url.map(urlSegment => `/${urlSegment.path}`).join(''),
				queryParams: this.route.snapshot.queryParams,
			});
		}
	}
}

const omitUndefinedProperties = <T extends {}>(obj: T) => omitBy(obj, isUndefined);
