import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { MachineSecurityKbToCve } from '@wcd/domain';

@Component({
	selector: 'app-machine-cves',
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<machine-security-cve-panel
				class="wcd-flex-1 wcd-flex-vertical wcd-full-height"
				[machineSecurityCves]="machineSecurityCves"
			>
			</machine-security-cve-panel>
		</wcd-panel>
	`,
})
export class MachineCveComponent extends PanelContainer {
	@Input() machineSecurityCves: Array<MachineSecurityKbToCve>;
	constructor(router: Router) {
		super(router);
	}
}
