import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { GetAuthenticatedScanPublicKey } from '@wcd/domain';

@Injectable()
export class AssessmentJobEncryptionService {
	constructor(private paris: Paris) {}

	async encrypt(plainText: string): Promise<string> {
		const encodedPlainText = new TextEncoder().encode(plainText);
		const publicKey = await this.getPublicKey(); // gets the public key from NetworkScanService, to be used for encrypting
		const encrypted = await this.encryptRSA(publicKey, encodedPlainText);
		return window.btoa(this.ab2str(encrypted)); // converts the result array buffer to string, then to base64
	}

	getPublicKey(): Promise<CryptoKey> {
		return this.paris
			.apiCall(GetAuthenticatedScanPublicKey)
			.toPromise()
			.then(this.importRsaKey.bind(this));
	}

	importRsaKey(pem: string) {
		const binaryDerString = window.atob(pem);
		// convert from a binary string to an ArrayBuffer
		const binaryDer = this.str2ab(binaryDerString);

		return window.crypto.subtle.importKey(
			'spki', // the key is is exported by the service in SPKI format
			binaryDer,
			{
				name: 'RSA-OAEP',
				hash: 'SHA-256',
			},
			true,
			['encrypt']
		);
	}

	encryptRSA(key: CryptoKey, plaintext: Uint8Array) {
		const encrypted = window.crypto.subtle.encrypt(
			{
				name: 'RSA-OAEP', // the service uses RSA-OAEP to decrypt
			},
			key,
			plaintext
		);
		return encrypted;
	}

	str2ab(str: string): ArrayBuffer {
		const buf = new ArrayBuffer(str.length);
		const bufView = new Uint8Array(buf);
		for (let i = 0, strLen = str.length; i < strLen; i++) {
			bufView[i] = str.charCodeAt(i);
		}
		return buf;
	}

	ab2str(buf: ArrayBuffer): string {
		return String.fromCharCode.apply(null, new Uint8Array(buf));
	}
}
