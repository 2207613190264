/* tslint:disable:template-click-events-have-key-events */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	OnDestroy,
	Injector,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
	Software,
	SoftwareInstallation,
	ShouldShowLegalNoteFor,
	LegalNoteLinks,
	EolState,
	VulnerabilityType,
} from '@wcd/domain';
import { ModelBase } from '@microsoft/paris';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { FeaturesService, Feature } from '@wcd/config';
import { MessageBarStyles } from '../../../@entities/@tvm/common/styles';
import { SOFTWARE_CATEGORY } from '../../../@entities/@tvm/software-inventory/components/software-entity/software.entity.component';
import { MessageBarType, IMessageBarStyleProps } from 'office-ui-fabric-react';
import { Router } from '@angular/router';

@Component({
	selector: 'software-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './software.entity-details.component.html',
})
export class SoftwareEntityDetailsComponent extends EntityDetailsComponentBase<Software>
	implements OnInit, OnDestroy {
	private _subscription: Subscription;
	readonly MessageBarType = MessageBarType;
	tooltip: string;
	eolText: string;
	legalNote: string;
	isProductEvidenceEnabled: boolean;
	messageBarStyles = MessageBarStyles;
	exposedDevicesTitle: string;
	shouldShowLegalNote: boolean;
	zeroDayFeatureEnabled: boolean;
	showZeroDayMessage: boolean;
	osPlatformText: string;

	@Input() software?: Software;
	@Input() installation?: SoftwareInstallation;
	messageBarState = {
		showBody: false,
	};
	_messageBarStyles = MessageBarStyles;
	showEolMessage: boolean;
	softwareVersionsLinkText: string;
	legalNoteLinkText: string;
	legalNoteLink: string;
	showLegalNoteLink: boolean;
	messageBarType: MessageBarType;
	isEolSoftware: boolean;
	isUpcomingEolSoftware: boolean;
	showEolDate: boolean;
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private activatedEntity: ActivatedEntity,
		private tvmTextsService: TvmTextsService,
		public i18nService: I18nService,
		private featuresService: FeaturesService,
		private router: Router,
		injector: Injector
	) {
		super(injector);
		this.isProductEvidenceEnabled = this.featuresService.isEnabled(Feature.TvmProductEvidence);
		this.zeroDayFeatureEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
	}

	private generateTooltip(software: Software) {
		this.tooltip = this.tvmTextsService.getText(TextToken.ExposureScoreForSoftware, software.name);
	}

	ngOnInit() {
		super.ngOnInit();
		if (!this.software) {
			this._subscription = this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof Software))
				.subscribe((software: Software) => {
					this.software = software;
					this.initializeSoftwareFields();
				});
		} else {
			this.initializeSoftwareFields();
		}
	}

	private getExposedDevicesTitle(software: Software) {
		return this.featuresService.isEnabled(Feature.TvmNetworkScan) &&
			software.category === SOFTWARE_CATEGORY.NetworkGear
			? this.i18nService.get('tvm.common.exposedNetworkDevices')
			: this.i18nService.get('tvm.common.exposedDevices');
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	getStyles(messageBarProps: IMessageBarStyleProps) {
		if (this.messageBarState.showBody !== messageBarProps.expandSingleLine) {
			this.messageBarState.showBody = messageBarProps.expandSingleLine;
			this.changeDetectorRef.detectChanges();
		}
		return this._messageBarStyles;
	}

	shouldShowEolMessage() {
		return (
			this.isEolSoftware ||
			this.isUpcomingEolSoftware ||
			this.software.hasEolVersions ||
			this.software.hasUpcomingEolVersions
		);
	}

	initializeSoftwareFields() {
		this.generateTooltip(this.software);
		this.isEolSoftware = this.software.eolSoftwareState === EolState.AlreadyEOL;
		this.isUpcomingEolSoftware = this.software.eolSoftwareState === EolState.UpcomingEOL;
		this.exposedDevicesTitle = this.getExposedDevicesTitle(this.software);
		this.shouldShowLegalNote = ShouldShowLegalNoteFor.includes(this.software.id);
		this.showEolMessage = this.shouldShowEolMessage();
		this.showEolDate = this.shouldShowEolDate();
		this.eolText = this.getEolText();
		this.legalNote = this.isEolSoftware
			? this.tvmTextsService.getText(TextToken.SoftwareEolOsLegalNote, this.software.id)
			: this.tvmTextsService.getText(TextToken.HasEolVersionsMicrosoftLegalNote, this.software.id);
		this.showLegalNoteLink = Object.keys(LegalNoteLinks).includes(this.software.id);
		this.legalNoteLink = LegalNoteLinks[this.software.id];
		this.legalNoteLinkText = this.tvmTextsService.getText(TextToken.LegalNoteLinkText, this.software.id);
		this.softwareVersionsLinkText = this.getSoftwareVersionsLinkText();
		this.messageBarType =
			this.software.hasEolVersions || this.isEolSoftware ? MessageBarType.warning : MessageBarType.info;
		this.showZeroDayMessage = this.shouldShowZeroDayMessage(this.software);
		this.osPlatformText = this.software.osPlatform
			? this.i18nService.get(`tvm_common_osPlatform_${this.software.osPlatform}`)
			: this.i18nService.strings.notAvailable_short;
	}

	getEolText() {
		if (this.isEolSoftware) {
			return this.tvmTextsService.getText(
				TextToken.EolSoftwareText,
				this.software.eolSoftwareSinceDate
			);
		}
		if (this.isUpcomingEolSoftware) {
			return this.tvmTextsService.getText(
				TextToken.UpcomingEolSoftwareText,
				this.software.eolSoftwareSinceDate
			);
		}
		if (this.software.hasEolVersions && this.software.hasUpcomingEolVersions) {
			return this.i18nService.get('tvm.securityRecommendation.combinedEolVersionsText', {
				product: this.software.name,
			});
		}
		if (this.software.hasEolVersions) {
			return this.i18nService.get('tvm.securityRecommendation.hasEolVersionsText', {
				product: this.software.name,
			});
		}
		if (this.software.hasUpcomingEolVersions) {
			return this.i18nService.get('tvm.securityRecommendation.hasUpcomingEolVersionsText', {
				product: this.software.name,
			});
		}
	}

	openSoftwareVersions() {
		const alreadyEOL = this.software.hasEolVersions ? 'AlreadyEOL' : '';
		const upcomingEOL = this.software.hasUpcomingEolVersions ? 'UpcomingEOL' : '';
		this.router.navigate([`/software-inventory/${encodeURIComponent(this.software.id)}/versions`], {
			queryParams: {
				filters: `versionDistributionTags=${[alreadyEOL, upcomingEOL]
					.filter(s => s.length > 0)
					.join('|')}`,
			},
		});
		this.changeDetectorRef.detectChanges();
	}

	getSoftwareVersionsLinkText() {
		return this.i18nService.get(
			'tvm.securityRecommendation.remediationTaskCreation.openSoftwareVersions',
			{ software: this.software.name }
		);
	}

	shouldShowEolDate() {
		return this.software.eolSoftwareSinceDate && (this.isEolSoftware || this.isUpcomingEolSoftware);
	}

	private shouldShowZeroDayMessage(software: Software) {
		return (
			this.zeroDayFeatureEnabled &&
			software.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
			!software.patchReleaseDate
		);
	}
}
