export const enum OauthApplicationEntityAlertV3Fields {
	OAuthAppId = 'OAuthAppId',
	OAuthObjectId = 'OAuthObjectId',
	Name = 'Name',
	Risk = 'Risk',
}

export function mapOauthApplicationEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case OauthApplicationEntityAlertV3Fields.OAuthAppId:
					acc['oauth_app_id'] = val;
					break;
				case OauthApplicationEntityAlertV3Fields.OAuthObjectId:
					acc['oauth_object_id'] = val;
					break;
				case OauthApplicationEntityAlertV3Fields.Name:
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					acc['name'] = val;
					break;
				case OauthApplicationEntityAlertV3Fields.Risk:
					acc['risk'] = val;
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
