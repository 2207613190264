import { Component } from '@angular/core';
import { EntityMinimizedDetailsComponentBase } from './entity-minimized-details.component.base';
import { Machine, machineHealthStatuses } from '@wcd/domain';

@Component({
	template: `
		<div class="relative wcd-flex-vertical" [attr.aria-label]="description">
			<wcd-shared-icon
				[iconName]="iconName"
				[ngClass]="minimizedData"
				class="wcd-entity-panel-close-icon"
			></wcd-shared-icon>
			<div
				class="wcd-machine-minimized-indicators wcd-flex-horizontal wcd-flex-justify-content-space-between"
			>
				<div
					*ngIf="machineRiskClass"
					[ngClass]="machineRiskClass"
					class="machine-risk-indicator"
					[attr.aria-label]="riskDescription"
					[wcdTooltip]="riskDescription"
					tabindex="0"
				></div>
				<div
					class="machine-status-active"
					*ngIf="isActive"
					[attr.aria-label]="statusDescription"
					[wcdTooltip]="statusDescription"
					tabindex="0"
				></div>
			</div>
		</div>
	`,
	styleUrls: ['./machine.minimized.component.scss'],
})
export class MachineMinimizedDetailsComponent extends EntityMinimizedDetailsComponentBase<Machine> {
	minimizedData: string;
	machineRiskClass: string;
	isActive: boolean;
	description: string;
	riskDescription: string;
	statusDescription: string;

	setEntity(entity) {
		super.setEntity(entity);
		const riskScore = this.entity.riskScore;
		this.machineRiskClass =
			riskScore && riskScore.id && `machine-risk-${riskScore.id.toString().toLowerCase()}`;

		const status = this.entity.status;
		this.isActive = status && status.id && status.id === machineHealthStatuses.Active.id;

		this.description =
			status &&
			riskScore &&
			this.i18nService.get('machines.entityDetails.minimized.tooltip', {
				risk: this.i18nService.get(this.entity.riskScore.nameI18nKey),
				status: this.entity.status.name,
			});
		this.riskDescription =
			riskScore &&
			this.i18nService.get('machines.entityDetails.minimized.risk', {
				risk: this.i18nService.get(this.entity.riskScore.nameI18nKey),
			});
		this.statusDescription =
			status &&
			this.i18nService.get('machines.entityDetails.minimized.status', {
				status: this.entity.status.name,
			});
	}
}
