import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Mail Message',
	pluralName: 'Mail Messages',
})
export class MailMessage extends ModelBase {
	@EntityField({ data: 'Id' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'Name', required: false })
	name?: string;

	@EntityField({ data: 'MailMessagePageUrl', required: false })
	mailMessagePageUrl?: string;
}
