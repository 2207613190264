import { DataQuery, Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { RemediationActionType } from './remediation-action-type.entity';
import { InvestigatedMachine } from '../investigation/investigated-machine.entity';
import { RemediationActionEntity } from './remediation-action-entity.value-object';
import { RemediationActionRelatedEntity } from './remediation-action-related-entity.value-object';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { InvestigatedUser } from '../investigation/investigated-identity.value-object';

@Entity({
	singularName: 'Remediation Action',
	pluralName: 'Remediation Actions',
	endpoint: 'remediation_actions',
	allItemsProperty: 'results',
	separateArrayParams: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class RemediationAction extends EntityModelBase {
	@EntityField({
		data: 'action_ids',
		parse: (actionIds, rawData, query: DataQuery) => {
			if (actionIds) {
				return actionIds && Array.isArray(actionIds) ? actionIds.length && actionIds[0] : actionIds;
			}

			if (query && query.where && query.where['useSevilleApi']) {
				return rawData.action_id;
			}
		},
	})
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({
		data: ['action_type', 'type'],
		parse: (type, rawData, query: DataQuery) =>
			type !== undefined && type !== null ? type : query.where['action_type'],
	})
	remediationActionType: RemediationActionType;

	@EntityField()
	description?: string;

	@EntityField()
	commentData: {
		actionApprovedTime: string;
		approvedBy: string;
		approverComment: string;
	};

	@EntityField({
		data: 'action_ids',
		parse: (actionIds, rawData, query: DataQuery) => {
			if (actionIds) {
				return actionIds;
			}

			if (query && query.where && query.where['useSevilleApi']) {
				return [rawData.action_id];
			}
		},
	})
	actionIds: Array<number>;

	@EntityField({ data: 'investigation_id' })
	investigationId: number | string;

	@EntityField({
		data: 'host',
		parse: (host, rawData, query: DataQuery) => {
			if (host) {
				return host;
			}

			if (query && query.where && query.where['useSevilleApi'] && rawData.computer_dns_name) {
				return {
					id: rawData.sense_machine_id,
					machine_id: rawData.sense_machine_id,
					name: rawData.computer_dns_name,
				};
			}
		},
	})
	machine: InvestigatedMachine;

	@EntityField({ data: 'hosts', arrayOf: InvestigatedMachine })
	private _machines: Array<InvestigatedMachine>;

	@EntityField({ data: 'action' })
	details: { reason?: string; recommendation?: string };

	@EntityField() entity: RemediationActionEntity;

	@EntityField() reason: string;

	@EntityField({ data: 'related_entities', arrayOf: RemediationActionRelatedEntity })
	relatedEntities: Array<RemediationActionRelatedEntity>;

	@EntityField() file: RemediationActionEntity;

	@EntityField({ data: 'appended_file' })
	appendedFile: RemediationActionEntity;

	@EntityField() process: RemediationActionEntity;

	@EntityField({ arrayOf: RemediationActionEntity })
	processes: Array<RemediationActionEntity>;

	@EntityField() address: RemediationActionEntity;

	@EntityField() service: RemediationActionEntity;

	@EntityField() driver: RemediationActionEntity;

	@EntityField() user: RemediationActionEntity;

	@EntityField() mailCluster: RemediationActionEntity;

	@EntityField() mailMessage: RemediationActionEntity;

	@EntityField() mailbox: RemediationActionEntity;

	@EntityField() mailboxConfiguration: RemediationActionEntity;

	@EntityField({ data: 'persistence_method' })
	persistenceMethod: RemediationActionEntity;

	@EntityField({ data: 'user_activity' })
	investigatedUser: InvestigatedUser;

	@EntityField({ data: 'IsOfficeInvestigation' })
	isOfficeInvestigation: boolean;

	state: { isApproving: boolean; isDeclining: boolean };

	get isProcessing(): boolean {
		return this.state.isApproving || this.state.isDeclining;
	}

	get machines(): Array<InvestigatedMachine> {
		return this._machines && this._machines.length ? this._machines : [this.machine];
	}
}
