import { Component, Input, OnInit } from '@angular/core';
import { CyberEvent } from '@wcd/domain';
import { CyberEventsUtilsService } from '../../cyber_events/services/cyber-events-utils.service';
import { ChangeDetectorRef } from '@angular/core';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { AppInsightsService } from '../../../insights/services/app-insights.service';

const FLAGGING_UNSUPPORTED_ACTIONTYPES = [
	// Add action types that should not be allowed to be flagged here.
].map(actionType => actionType.toString());

const MARK_EVENT_APP_INSIGHT_ID = 'MarkTimelineEvent';

@Component({
	selector: 'event-mark',
	template: `
		<fab-icon-button
			*ngIf="isSupportedActionType"
			[menuIconProps]="{ iconName: 'Flag' }"
			(click)="onFlagClick()"
			[styles]="getFlagButtonStyle()"
			[ariaLabel]="'events.markedEvents.filterText' | i18n"
			[tabIndex]="-1"
		>
		</fab-icon-button>
	`,
})
export class MachineEventMarkComponent implements OnInit {
	@Input()
	cyberEvent: CyberEvent;

	isMarked: boolean;
	isSupportedActionType: boolean;

	constructor(
		private cyberEventsUtilsService: CyberEventsUtilsService,
		private ref: ChangeDetectorRef,
		private dialogService: DialogsService,
		private i18nService: I18nService,
		private appInsightsService: AppInsightsService
	) {}

	getFlagButtonStyle = () => {
		const hoverOrFocusOpacity = this.isMarked ? 1 : 0.6;
		return {
			root: {
				opacity: this.isMarked ? 1 : 0.3,
			},
			rootHovered: {
				opacity: 1,
			},
			rootFocused: {
				opacity: hoverOrFocusOpacity,
			},
			menuIcon: {
				paddingRight: 19,
				textShadow: this.isMarked ? '0 0 2px #b3eafa, 0 0 3px #4178d4' : 'none',
			},
		};
	};

	ngOnInit() {
		this.isMarked = this.cyberEvent.isMarked;
		this.isSupportedActionType = !FLAGGING_UNSUPPORTED_ACTIONTYPES.includes(this.cyberEvent.rawActionType);
	}

	onFlagClick = () => {
		const newEventMarkStatus = !this.isMarked;
		this.isMarked = newEventMarkStatus;

		this.appInsightsService.trackEvent(MARK_EVENT_APP_INSIGHT_ID, {
			SenseMachineId: this.cyberEvent.machine.id,
			IsFlagged: this.isMarked,
			ReportId: this.cyberEvent.reportId,
			ActionType: this.cyberEvent.rawActionType,
			isCyberData: this.cyberEvent.isCyberData,
			dataType: this.cyberEvent.cyberActionType && this.cyberEvent.cyberActionType.dataType,
			relatedObservationName: this.cyberEvent.relatedObservationName
		});

		this.cyberEventsUtilsService.markEvent(this.cyberEvent, this.isMarked).subscribe(
			_ => {
				// nothing to do on success, flag already marked.
			},
			err => {
				// add event marked failed, revert the flag
				this.isMarked = !newEventMarkStatus;
				this.ref.detectChanges();
				this.dialogService.showSnackbar({
					text: this.i18nService.get('events.markedEvents.markEventErrorMessage'),
					data: err,
				});

				this.appInsightsService.trackException(err, MARK_EVENT_APP_INSIGHT_ID);
			}
		);
	};
}
