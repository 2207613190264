<div class="wcd-full-height large-form">
	<form #customTiIndicatorImportForm="ngForm" (submit)="onImport()" class="wcd-full-height wcd-flex-vertical" data-track-component="CustomTiIndicatorImportForm">
		<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
			<div class="form-group">
				<label for="import-csv_file" class="dialog-control-description subtle">{{ 'customTiIndicator.dataview.actions.import.selectCsvFile'
					| i18n}}</label>
				<input #importCsvFileInput type="file" id="import-csv_file" name="import-csv_file" (change)="onFileSelect($event)"	/>
				<div *ngIf="showInvalidCsvFileSizeMessage" class="color-box-gray-100 wcd-flex-horizontal wcd-padding-small-all wcd-margin-large-bottom">
						<fab-icon iconName="Warning" contentClass="color-text-error"></fab-icon>
						<span class="wcd-margin-xsmall-left">{{ 'customTiIndicator.dataview.actions.import.errorMessages.csvFileTooLarge' | i18n }}</span>
				</div>
			</div>
			<div [hidden]="!isSaving">
				<i class="large-loader-icon"></i>
			</div>
			<section *ngIf="totalCustomTiParsed && !isSaving" class="wcd-padding-bottom wcd-scroll-vertical">
				<h4 class="definition-list-title">
					{{ 'customTiIndicator.dataview.actions.import.importReport.title' | i18n}}
				</h4>
				<dl class="key-values" role="none">
					<dt>{{ 'customTiIndicator.dataview.actions.import.importReport.fields.total' | i18n}}</dt>
					<dd>
						{{ totalCustomTiParsed }}
					</dd>
					<dt>{{ 'customTiIndicator.dataview.actions.import.importReport.fields.imported' | i18n}}</dt>
					<dd>
						{{ successfullyImported }}
					</dd>
				</dl>
				<div *ngIf="importFailures && importFailures.length > 0" class="dialog-control-description subtle wcd-margin-medium-top">
					{{ 'customTiIndicator.dataview.actions.import.importReport.failureDetails' | i18n}}
				</div>
				<ng-container *ngFor="let result of importFailures">
					<div class="wcd-flex-horizontal">
					  <div class="nowrap wcd-text-overflow-large" [wcdTooltip]="result.indicator.tiIndicator">{{ result.indicator.tiIndicator}}</div>
					  <fab-icon *ngIf="result.failureReason" iconName="Comment" [wcdTooltip]="result.failureReason" contentClass="small-icon color-text-warning"></fab-icon>
				    </div>
				</ng-container>
			</section>
		</div>

		<div class="wcd-flex-none">
			<button class="btn btn-link wcd-padding-medium-left" data-track-id="DownloadImportCsvSample" data-track-type="Button" type="button"
			 (click)="downloadCsvSample()" [wcdTooltip]="'customTiIndicator.dataview.actions.import.downloadSampleCsv' | i18n">
				<fab-icon iconName="DownloadDocument" iconColor="blue"></fab-icon>
				{{ 'customTiIndicator.dataview.actions.import.downloadSampleCsv' | i18n}}
			</button>
		</div>
		<div class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
			<div class="wcd-flex-1 wcd-margin-right">
				<button class="btn btn-secondary-primary" data-track-id="ImportCustomTiIndicators" data-track-type="Button"
				 type="submit" [disabled]="!isValid">
					<i class="loader-icon" [hidden]="!isSaving"></i>
					{{ 'buttons.import' | i18n }}
				</button>
			</div>
			<div class="wcd-flex-none">
				<button class="btn btn-cancel"
						data-track-id="Cancel"
						data-track-type="Button"
						type="button"
						(click)="done.emit()">{{'buttons.done' | i18n}}</button>
			</div>
		</div>
	</form>
</div>
