import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit, } from '@angular/core';
import { isNil, isObject } from 'lodash-es';
import { from } from 'rxjs';
import { SpinnerSize } from 'office-ui-fabric-react';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';
var MIN_SEARCH_LENGTH = 2;
var KEY_CODES = {
    ENTER: 13,
    ESCAPE: 27,
    UP: 38,
    DOWN: 40,
    TAB: 9,
};
var MARGIN = 20;
var TOP_CONTAINER_HEIGHT = 50;
var lastId = 0;
var SearchComponent = /** @class */ (function () {
    function SearchComponent(changeDetectionRef, liveAnnouncer, i18nService) {
        var _this = this;
        this.changeDetectionRef = changeDetectionRef;
        this.liveAnnouncer = liveAnnouncer;
        this.i18nService = i18nService;
        this.fullWidth = false;
        this.ariaLabel = null;
        this.forceAlphanumeric = false;
        this.setFocus = false;
        this.enableTopPosition = true;
        this.role = "combobox";
        this.select = new EventEmitter();
        /**
         * 	triggered when the user presses Esc or deletes the search term from the input box
         */
        this.searchTermCleared = new EventEmitter();
        /**
         * 	triggered when the user presses Esc when search term is cleared and results are closed
         */
        this.escOnClosedResult = new EventEmitter();
        this.searchValue = '';
        this.results = [];
        this.topPositionResults = false;
        this.isLoading = false;
        this.minSearchSize = MIN_SEARCH_LENGTH;
        this.showResults = false;
        this.resultVisible = false;
        this.searchboxId = 'wcd-searchbox-' + lastId++;
        this.SpinnerSize = SpinnerSize;
        this.onMouseWheel = function (e) {
            if (!e.target.closest('.wcd-searchbox--results'))
                _this.closeResults();
        };
    }
    Object.defineProperty(SearchComponent.prototype, "resultsElement", {
        set: function (resultsElement) {
            this.menuEl = resultsElement && resultsElement.nativeElement;
            if (this.menuEl) {
                /**
                 * Taking the menu out of the component, and into the body, for positioning.
                 */
                document.body.appendChild(this.menuEl);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchComponent.prototype, "noResultsElement", {
        set: function (resultsElement) {
            this.noResultMenu = resultsElement && resultsElement.nativeElement;
            if (this.noResultMenu) {
                /**
                 * Taking the menu out of the component, and into the body, for positioning.
                 */
                document.body.appendChild(this.noResultMenu);
            }
        },
        enumerable: true,
        configurable: true
    });
    SearchComponent.prototype.ngOnInit = function () {
        if (this.settings && !isNaN(this.settings.minSearchSize))
            this.minSearchSize = this.settings.minSearchSize;
    };
    SearchComponent.prototype.onKeyDown = function (e) {
        clearTimeout(this._keyUpTimeout);
        if (e.keyCode === KEY_CODES.TAB) {
            this.closeResults();
        }
        else if (e.keyCode === KEY_CODES.ESCAPE) {
            if (this.searchValue !== '') {
                this.searchValue = '';
                if (this.closeResultsWhenSearchCleared) {
                    this.closeResults();
                    this.searchTermCleared.emit(null);
                }
            }
            else {
                if (this.showResults) {
                    this.closeResults();
                    this.searchTermCleared.emit(null);
                }
                else {
                    this.escOnClosedResult.emit(null);
                }
            }
            e.stopPropagation();
        }
        else if (e.keyCode === KEY_CODES.ENTER) {
            this.onSelect();
        }
        else if (e.keyCode === KEY_CODES.UP || e.keyCode === KEY_CODES.DOWN) {
            if (e.keyCode === KEY_CODES.UP && this.highlightedResult >= 0)
                this.highlightedResult--;
            else if (e.keyCode === KEY_CODES.DOWN) {
                if (this.results && this.results.length && !this.showResults) {
                    this.showResults = true;
                    this.highlightedResult = 0;
                }
                else
                    this.highlightedResult++;
            }
            this.highlightedResult = this.highlightedResult || 0;
            if (this.highlightedResult < 0)
                this.highlightedResult = this.results.length - 1;
            if (this.highlightedResult > this.results.length - 1)
                this.highlightedResult = 0;
            e.preventDefault();
            setTimeout(function () {
                var selectedElement = document.querySelector(".wcd-searchbox--result__highlighted");
                selectedElement && selectedElement.scrollIntoView();
            });
        }
        else if (/[!@\-+]/.test(e.key) && this.searchValue.length === 0 && this.forceAlphanumeric) {
            return false;
        }
        else {
            this._keyUpTimeout = setTimeout(this.loadSearchResults.bind(this), 400);
        }
        this.changeDetectionRef.detectChanges();
    };
    SearchComponent.prototype.onBlur = function () {
        this.closeResults();
    };
    SearchComponent.prototype.onFocus = function () {
        if (this.settings && this.settings.showSuggestions) {
            clearTimeout(this._keyUpTimeout);
            this._keyUpTimeout = setTimeout(this.loadSearchResults.bind(this), 400);
        }
    };
    SearchComponent.prototype.closeResults = function (clearSearchValue) {
        if (clearSearchValue === void 0) { clearSearchValue = true; }
        this.topPositionResults = false;
        this.isLoading = false;
        this.showResults = false;
        this.resultVisible = false;
        if (clearSearchValue && (!this.settings || !this.settings.showValueOnSelect))
            this.searchValue = '';
        window.removeEventListener('mousewheel', this.onMouseWheel);
        this.changeDetectionRef.detectChanges();
    };
    SearchComponent.prototype.selectItem = function (itemIndex) {
        this.highlightedResult = itemIndex;
        this.selectedResult = this.results[itemIndex];
        if (this.settings && this.settings.showValueOnSelect) {
            this.searchValue = this.selectedResult.label || this.selectedResult.value;
        }
        this.emitSelect(this.selectedResult);
        this.closeResults();
    };
    SearchComponent.prototype.onSelect = function () {
        var _this = this;
        this.selectedResult = this.results ? this.results[this.highlightedResult || 0] : null;
        if (this.settings && this.settings.showValueOnSelect)
            this.searchValue = this.selectedResult.label || this.selectedResult.value;
        clearTimeout(this._selectTimeout);
        this._selectTimeout = setTimeout(function () {
            _this.emitSelect(_this.selectedResult);
        }, 40);
        this.closeResults();
        return false;
    };
    SearchComponent.prototype.emitSelect = function (selectedResult) {
        this.select.emit(this.rawResults[this.results.indexOf(selectedResult)]);
    };
    SearchComponent.prototype.loadSearchResults = function () {
        var _this = this;
        var searchTerm = this.searchValue;
        if (!searchTerm) {
            this.searchTermCleared.emit(null);
        }
        if (!this.settings || !this.settings.showSuggestions) {
            if (!searchTerm || searchTerm.length < this.minSearchSize)
                return this.closeResults(false);
        }
        this.isLoading = true;
        this.changeDetectionRef.detectChanges();
        from(this.searchFunction(searchTerm)).subscribe(function (results) {
            _this.results = _this.parseResults(results);
            _this.rawResults = results;
            _this.showResults = true;
            setTimeout(_this.setResultsAreBelowFold.bind(_this), 10);
            _this.isLoading = false;
            _this.liveAnnouncer.announce(_this.i18nService.get(_this.results.length ? 'filters_search_suggestions_available' : 'filters_search_result_not_found'));
            window.addEventListener('mousewheel', _this.onMouseWheel);
            _this.changeDetectionRef.detectChanges();
        }, function () { return _this.closeResults(); });
    };
    SearchComponent.prototype.selectionChanged = function (e) {
        // otherwise, it's bubbles up as the search.component's (select) event
        e.stopPropagation();
    };
    SearchComponent.prototype.parseResults = function (results) {
        var _this = this;
        if (!results)
            return [];
        if (this.settings && this.settings.parseResult)
            return results.map(function (result) { return _this.settings.parseResult(result); });
        if (isSearchResults(results))
            return results;
        throw new Error('Failed to parse search results.');
    };
    SearchComponent.prototype.setResultsAreBelowFold = function () {
        var resultsBoundingRect = (this.menuEl && this.menuEl.getBoundingClientRect()) ||
            (this.noResultMenu && this.noResultMenu.getBoundingClientRect()), windowHeight = document.documentElement.clientHeight;
        this.topPositionResults =
            this.inputElement.nativeElement.getBoundingClientRect().bottom + resultsBoundingRect.height >
                windowHeight;
        this.changeDetectionRef.detectChanges();
        this.setMenuPositionAndShow();
    };
    SearchComponent.prototype.setMenuPositionAndShow = function () {
        var inputRect = this.inputElement.nativeElement.getBoundingClientRect();
        var documentHeight = document.documentElement.clientHeight, aboveHight = inputRect.top - TOP_CONTAINER_HEIGHT - MARGIN, menuEl = this.results.length ? this.menuEl : this.noResultMenu, belowHight = documentHeight - inputRect.bottom - MARGIN;
        if (menuEl) {
            if (belowHight >= aboveHight) {
                menuEl.style.top = inputRect.bottom + 'px';
                menuEl.style.left = inputRect.left + 1 + 'px';
                menuEl.style.minWidth = inputRect.width - 1 + 'px';
                menuEl.style.maxWidth = inputRect.width + 1 + 'px';
                menuEl.style.maxHeight = belowHight + 'px';
            }
            else {
                var maxHeight = inputRect.top - TOP_CONTAINER_HEIGHT - MARGIN, menuRec = menuEl.getBoundingClientRect(), top_1 = inputRect.top < menuRec.height ? maxHeight : inputRect.top - menuRec.height;
                menuEl.style.maxHeight = maxHeight + 'px';
                menuEl.style.left = inputRect.left + 1 + 'px';
                menuEl.style.minWidth = inputRect.width - 1 + 'px';
                menuEl.style.maxWidth = inputRect.width + 1 + 'px';
                menuEl.style.top = top_1 + 'px';
            }
            this.resultVisible = true;
            this.changeDetectionRef.detectChanges();
        }
    };
    return SearchComponent;
}());
export { SearchComponent };
function isSearchResults(searchResults) {
    return (Array.isArray(searchResults) &&
        searchResults.every(function (searchResult) {
            if (!isObject(searchResult))
                return false;
            var maybeSearchResult = searchResult;
            if (isNil(maybeSearchResult.value))
                return false;
            return isNil(maybeSearchResult.label) || typeof maybeSearchResult.label === 'string';
        }));
}
