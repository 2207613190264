import { SevilleModule } from '../../seville/seville.module';
import { config } from '@wcd/shared';
import { FilesService } from '../../../../@entities/files/services/files.service';

SevilleModule.directive('fileSearchResults', fileSearchResultsDirective);

fileSearchResultsDirective.$inject = ['filesService'];

function fileSearchResultsDirective(filesService: FilesService) {
	return {
		restrict: 'EA',
		scope: {
			results: '=',
			matchTitle: '=',
			loading: '=',
		},
		template: `
		<div ng-class="{'loading-opacity': fileSearchResults.loading }" data-track-component="FileSearchResults">
        <div ng-if="fileSearchResults.results">
            <div class="row hover-default file-search-matches-title">
                <div class="col-md-height col-middle overflow-all">
                    <b>{{::fileSearchResults.matchTitle}}</b>
                </div>
            </div>
            <div ng-if="fileSearchResults.results.length == 0" class="event-timeline-event row hover-default">
                <div class="search-results-desc">
                    No results
                </div>
            </div>
        </div>

        <div class="event-timeline-event row hover-default side-pane-container"
        	ng-repeat="instance in fileSearchResults.results  | orderBy:'MaxTimestamp':true | limitTo: 100"  ng-disabled="fileSearchResults.loading">
            <div class="row-same-height event-timeline-event-main search-results-lighter nopadding">
                <div class="col-md-3 col-md-height col-middle overflow-all search-results-desc">
                    <i class="icon machine-timeline-icon icon-Page"></i>
                    <side-pane-file sha1="::instance.Sha1 || instance.Sha256" filename="::instance.FileName" />
					<a href="{{::fileSearchResults.getFileLink(instance.Sha1 || instance.Sha256, instance.FileName)}}"
                    	data-track-id="GoToFile"
						data-track-type="Navigation"
						stop-propagation>
                        {{::instance.FileName}}
                    </a>
                </div>

                <div class="col-md-3 col-md-height col-middle overflow-all search-results-desc">
                    <date src="instance.MaxTimestamp" type="date"></date>
                    <date src="instance.MaxTimestamp" type="time"></date>
                </div>

                <div class="col-md-3 col-md-height col-middle overflow-all search-results-desc">
                    <date src="instance.MinTimestamp" type="date"></date>
                    <date src="instance.MinTimestamp" type="time"></date>
                </div>

          <div class="col-md-3 col-md-height col-middle overflow-all search-results-desc">
            <div ng-if="instance.Sha1">
              <img class="sha-svg" ng-src="{{fileSearchResults.sha1IconAddress}}" />
              {{::instance.Sha1}}
            </div>
            <div ng-if="!instance.Sha1 && instance.Sha256">
              <img class="sha-svg" ng-src="{{fileSearchResults.sha256IconAddress}}" />
              {{::instance.Sha256}}
            </div>
          </div>
            </div>
        </div>
    </div>
		`,
		controllerAs: 'fileSearchResults',
		bindToController: true,
		controller: [
			function() {
				const vm = this;
				vm.sha1IconAddress = config.images.sha1;
				vm.sha256IconAddress = config.images.sha256;

				vm.getFileLink = (fileId, fileName?) => filesService.getFileLink(fileId, null, fileName);
			},
		],
	};
}
