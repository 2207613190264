export function encodeRoutePath(uri: string): string {
	// Just like encodeURIComponent, but encodes additional characters that have a special meaning in Angular
	// See https://github.com/angular/angular/issues/10280
	return encodeURIComponent(uri)
		.replace('(', '%28')
		.replace(')', '%29');
}

export function recursiveDecodeURIComponent(uriComponent: string): string {
	try {
		const decodedURIComponent: string = decodeURIComponent(uriComponent);

		if (decodedURIComponent == uriComponent) {
			return decodedURIComponent;
		}

		return recursiveDecodeURIComponent(decodedURIComponent);
	} catch (error) {
		return uriComponent;
	}
}
