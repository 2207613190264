import { SevilleModule } from '../../app/seville/seville.module';

SevilleModule.controller('seville.onboarding.preferences.geo', onboardingGeoController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding.geo', {
			url: '/geo',
			views: {
				onboardingStepView: {
					template: `
						<div class="onboarding-page">
                <div class="onboarding-body-title">
                    <span>Set up your preferences</span>
                </div>
                <div class="onboarding-body">
                    <div class="onboarding-body-position onboarding-geo">

                        <div class="page-header-title onboarding-title-margin" colspan="2">Select the data storage location</div>
                        <table>
                            <tr>
                                <td class="icon icon-lg icon-Warning onboarding-warning-padding"></td>
                                <td>
                                    This option cannot be changed without completely offboarding from Microsoft Defender for Endpoint and completing a new enrollment process.
                                    <br/>
                                    Select the location for data storage.
                                    For more information, see the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733763&amp;clcid=0x409" target="_blank">Data storage and privacy</a>
                                    section in the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409" target="_blank">Microsoft Defender for Endpoint guide</a>.
                                </td>
                            </tr>
                        </table>
                        <br/>
                        <div>
                            <label class="onboarding-radio">
                                <input type="radio" name="geo" value="Us" ng-model="geo.geo"/>
                                US
                            </label>
                        </div>
                        <br/>
                        <div>
                            <label class="onboarding-radio">
                                <input type="radio" name="geo" value="Europe" ng-model="geo.geo"/>
                                Europe
                            </label>
                        </div>
                        <br/>
                        <div>
                            <label class="onboarding-radio">
                                <input type="radio" name="geo" value="Uk" ng-model="geo.geo"/>
                                UK
                            </label>
                        </div>
                </div>
                <div class="onboarding-navigation">
                    <i class="icon icon-Sell rotate-90 icon-md onboarding-paging-icon onboarding-paging-icon-back-margin" ng-click="geo.MoveBack()"></i>
                    Back
                </div>
                <div class="onboarding-navigation onboarding-paging-icon-margin">
                    Next
                    <i class="icon icon-Sell rotate-270 icon-md onboarding-paging-icon onboarding-paging-icon-next-margin ng-class:{'onboarding-paging-icon': geo.geo, 'onboarding-paging-disabled': !geo.geo}" ng-click="geo.MoveNext()"></i>
                </div>
            </div>
						`,
					controller: 'seville.onboarding.preferences.geo',
					controllerAs: 'geo',
				},
			},
		});
	},
]);

onboardingGeoController.$inject = ['$scope', '$state'];

function onboardingGeoController($scope, $state) {
	if (!$scope.$parent.stepPermissionsVisited || $scope.$parent.stepProcessingVisited) {
		$state.go('onboarding.permissions');
	} else {
		var vm = this;
		$scope.$parent.resetStepsState(function() {
			$scope.$parent.stepPermissionsDone = true;
			$scope.$parent.stepWelcomeDone = true;
			$scope.$parent.stepPreferencesInProgress = true;
		});

		vm.geo = $scope.$parent.geo;
		vm.MoveNext = function() {
			if (!vm.geo) {
				return;
			}

			$state.go('onboarding.retention');
			$scope.$parent.geo = vm.geo;
		};

		vm.MoveBack = function() {
			$state.go('onboarding.welcome');
			$scope.$parent.geo = vm.geo;
		};
	}
}
