import { Input, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RelationshipRepository, ModelBase, Paris, Entity } from '@microsoft/paris';
import {
	Asset,
	TvmEndPoint,
	SecurityRecommendation,
	RecommendationContextType,
	NetworkDevice,
	RecommendationExposedNetworkDeviceRelationship,
} from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { EntityType } from '../../models/entity-type.interface';
import { SecurityRecommendationFieldsService } from '../../../@entities/@tvm/security-recommendations/services/security-recommendation.fields.service';
import { Observable } from 'rxjs';
import { RecommendationContextComponent } from '../../../@entities/@tvm/security-recommendations/components/recommendation-context.component';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TvmProductivityImpactService } from '../../../tvm/services/tvm-productivity-impact.service';
import { TvmTextsService } from '../../../tvm/services/tvm-texts.service';
import { DataviewField, DataViewConfig } from '@wcd/dataview';
import { RecommendationContextFieldsService } from '../../../@entities/@tvm/security-recommendations/services/recommendation-context.fields.service';
import { TvmRecommendationContextService } from '../../../tvm/services/tvm-recommendation-context.service';

@Component({
	selector: 'exposed-assets-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './exposed-assets.panel.html',
})
export class ExposedAssetsPanelComponent<T extends ModelBase> extends PanelContainer implements OnInit {
	entityType: EntityType<Asset>;
	isExportEnabled: boolean;
	isUserProductivityImpact = false;

	_repository: RelationshipRepository<T, Asset>;
	_sourceValue: T;
	_exposedAssetsTitle: string;

	fields: Array<DataviewField<Asset>>;
	dataViewConfig: DataViewConfig;

	networkDeviceRelationshipRepository: RelationshipRepository<SecurityRecommendation, NetworkDevice>;

	isRecommendationContextSupported: boolean;
	isRecommendationContextNeededInCSV: boolean;
	nestedComponent = {
		type: RecommendationContextComponent,
		getProps: asset => ({ asset: asset }),
	};
	// TODO: Review with Shai VSTS[25319843]
	getGroupItems(asset: Asset) {
		return new Observable<Array<Asset>>(observer => {
			const assets: Array<Asset> = [];
			observer.next(assets);
		});
	}

	@Input()
	set sourceValue(value: T) {
		this._sourceValue = value;
		if (
			value instanceof SecurityRecommendation &&
			this.productivityImpactService.isProductivityImpactExposed &&
			!value.oneAsset &&
			!!value.assetsStatistics.nonProductivityImpactedAssets
		) {
			// Adding the productivity impact column only to exposed assets with a context of only recommendations
			// which had populated the nonProductivityImpactedAssets -> on get exposed assets it will have the productivity impact values for them
			this.isUserProductivityImpact = true;
		}
	}

	get sourceValue(): T {
		return this._sourceValue;
	}

	@Input()
	set exposedAssetsTitle(value: string) {
		this._exposedAssetsTitle = value;
	}
	get exposedAssetsTitle(): string {
		return this._exposedAssetsTitle;
	}

	@Input()
	set repository(value: Function) {
		this._repository = this.paris.getRelationshipRepository(value);
	}

	@Input() isNetworkGear?: boolean;

	@Input() recommendationContextType?: RecommendationContextType;

	constructor(
		private i18nService: I18nService,
		private productivityImpactService: TvmProductivityImpactService,
		private tvmDownloadService: TvmDownloadService,
		private tvmTextsService: TvmTextsService,
		protected paris: Paris,
		private fieldsService: SecurityRecommendationFieldsService,
		private recommendationContextFieldsService: RecommendationContextFieldsService,
		private recommendationContextService: TvmRecommendationContextService,
		router: Router,
		private featuresService: FeaturesService,
		globalEntityTypesService: GlobalEntityTypesService
	) {
		super(router);
		this.entityType = globalEntityTypesService.getEntityType(Asset);
		this.isExportEnabled = featuresService.isEnabled(Feature.TvmExportCsvFromSidePanel);
		this.isRecommendationContextSupported = featuresService.isEnabled(Feature.TvmRecommendationContext);
	}

	ngOnInit() {
		this.isRecommendationContextNeededInCSV = this.recommendationContextService.isRecommendationContextNeededInCSV(
			this.recommendationContextType
		);

		const addRecommendationContextFields =
			this.isRecommendationContextSupported &&
			this.recommendationContextType &&
			this.recommendationContextType !== RecommendationContextType.Unknown;

		if (this.isUserProductivityImpact) {
			this.fields = this.fieldsService.exposedAssetsUserImpactFields;
		} else {
			this.fields = this.isNetworkGear
				? this.fieldsService.exposedDevicesFields
				: this.fieldsService.exposedAssetsFields;
		}
		const allFieldsIds = this.fields.map(field => field.id);
		this.dataViewConfig = {
			id: `exposedAssets(${allFieldsIds.join()})`, //must supply unique id for correct dataview caching purposes - unique id is based on the fields
		};
		if (addRecommendationContextFields) {
			this.fields = this.fields.filter(field => field.id !== 'recommendationContextCount');
			this.fields.push(
				this.recommendationContextFieldsService.getRecommendationContextCountField(
					this.recommendationContextType
				)
			);
			if (!this.fields.find(field => field.id === 'expand')) {
				this.fields.unshift(
					this.recommendationContextFieldsService.getRecommendationContextTableExpandField()
				);
			}
		}
		if (this.isNetworkGear) {
			this.networkDeviceRelationshipRepository = this.paris.getRelationshipRepository(
				RecommendationExposedNetworkDeviceRelationship
			);
			if (this._sourceValue instanceof SecurityRecommendation) {
				this.networkDeviceRelationshipRepository.sourceItem = this._sourceValue;
			}
		}
	}

	showNestedComponent(asset: Asset): boolean {
		return asset.recommendationContext.length > 0;
	}

	exportCveData() {
		if (this.isNetworkGear) {
			this.tvmDownloadService.downloadCsvFromRelationshipRepository(
				this.networkDeviceRelationshipRepository,
				TvmEndPoint.Analytics,
				`${this.exposedAssetsTitle} - ${this.i18nService.strings.tvm_common_exposedDevices}`,
				undefined
			);
		} else {
			this.tvmDownloadService.downloadCsvFromRelationshipRepository(
				this._repository,
				TvmEndPoint.Analytics,
				`${this.exposedAssetsTitle} - ${this.i18nService.strings.tvm_common_exposedDevices}`,
				{
					where: {
						includeProductivityColumn: this.isUserProductivityImpact,
					},
				},
				{ isRecommendationContextNeededInCSV: this.isRecommendationContextNeededInCSV }
			);
		}
	}
}
