import { Injectable } from '@angular/core';
import { LogonType } from '@wcd/domain';
import { KnownColorName } from '../../../shared/models/colors/known-colors.models';
import { ColorsService } from '../../../shared/services/colors.service';
import { KnownColorsService } from '../../../shared/services/known-colors.service';

const logonTypeColorName: Record<LogonType, KnownColorName> = {
	Batch: 'purpleDark',
	CachedInteractive: 'purple',
	CachedRemoteInteractive: 'purpleLight',
	CachedUnlock: 'blueMid',
	Local: 'tealLight',
	Interactive: 'blue',
	Network: 'blueLight',
	NetworkCleartext: 'magentaDark',
	NewCredentials: 'magenta',
	RemoteInteractive: 'magentaLight',
	Service: 'tealDark',
	Unlock: 'teal',
};

@Injectable()
export class LogonTypeColorsService extends ColorsService<LogonType> {
	constructor(colorsService: KnownColorsService) {
		super(colorsService, logonTypeColorName);
	}
}
