import { Injectable } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { AuthService } from '@wcd/auth';
import { Feature, FeaturesService } from '@wcd/config';
import { DataTableField } from '@wcd/datatable';
import {
	CustomTiIndicator,
	CustomTiIndicatorActionsType,
	CustomTiIndicatorActionsTypes,
	CustomTiIndicatorsType,
	CustomTiIndicatorsTypes,
	IndicatorAlertCategory,
	MachineGroup,
	CustomTiIndicatorSeverityType,
} from '@wcd/domain';
import { CheckboxComponent, ChecklistValue } from '@wcd/forms';
import { I18nService } from '@wcd/i18n';
import { lowerFirst, sortBy } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { MitreTechniqueDisplayPipe } from '../../../shared/pipes/mitre-technique-display.pipe';
import { CATEGORY_TO_MITRE_TECHNIQUES_MAPPING } from '@wcd/scc-common';
import { MachinesService } from '../../machines/services/machines.service';
import { RegExpService } from '@wcd/shared';

export enum ScopeTypesEnum {
	all = 0,
	specific = 1,
}

export const MAX_BYPASSDURATION = 999;

@Injectable()
export class CustomTiIndicatorComponentService {
	private _allMachineGroups: Array<MachineGroup>;
	private _valuesLabelTexts: Map<string, string> = new Map<string, string>();
	private _machineGroupsRepo: Repository<MachineGroup>;
	private _machineGroupSubscription: Subscription;
	private _breakTheGlassEnabled: boolean;
	private _tvmApplicationBlockEnabled: boolean;
	private _tvmApplicationControl: boolean;
	private _xplatIndicatorEnabled: boolean;
	private _exposedMachineGroups: Array<MachineGroup>;
	private _isAlertable: boolean;
	private _lastGenerateAlertState: boolean = false;
	private _isGenerateAlertDirty: boolean;

	selectableMachineGroupScopes: Array<ChecklistValue> = [
		{
			id: ScopeTypesEnum.all,
			name: this.i18nService.get(
				'customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.values.allmachines'
			),
		},
		{
			id: ScopeTypesEnum.specific,
			name: this.i18nService.get(
				'customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.values.selectedgroups'
			),
		},
	];

	actionOptions: Array<ChecklistValue> = [
		{
			id: CustomTiIndicatorActionsTypes.Allow,
			name: this.i18nService.strings.customTiIndicator_dataview_entity_fields_action_values_allow_title,
		},
		{
			id: CustomTiIndicatorActionsTypes.Alert,
			name: this.i18nService.strings.customTiIndicator_dataview_entity_fields_action_values_alert_title,
		},
		{
			id: CustomTiIndicatorActionsTypes.AlertAndBlock,
			name: this.i18nService.strings
				.customTiIndicator_dataview_entity_fields_action_values_alertandblock_title,
		},
	];

	readonly lookBackPeriodList: Array<number> = [30, 60, 90];

	allowSpecificMachineGroups: boolean = false;
	allowAllMachineGroups: boolean;
	currentMachineGroupScope: ChecklistValue = this.selectableMachineGroupScopes[ScopeTypesEnum.all];
	currentMachineGroups: Array<ChecklistValue> = [];
	loadingMachineGroups: boolean = true;
	availableMachineGroups: Array<ChecklistValue> = [];
	currentActionOption: ChecklistValue = this.actionOptions[CustomTiIndicatorActionsTypes.Allow];
	labelText: string;
	categoryList: Array<IndicatorAlertCategory>;
	selectableMitreTechniques: Array<DataTableField>;

	constructor(
		private paris: Paris,
		private authService: AuthService,
		private i18nService: I18nService,
		public machinesService: MachinesService,
		private featuresService: FeaturesService,
		private mitreTechniqueDisplayPipe: MitreTechniqueDisplayPipe
	) {
		this._machineGroupsRepo = paris.getRepository(MachineGroup);
		this._breakTheGlassEnabled = this.featuresService.isEnabled(Feature.BreakTheGlass);
		this._tvmApplicationBlockEnabled = this.featuresService.isEnabled(Feature.TVMApplicationBlock);
		this._tvmApplicationControl = this.featuresService.isEnabled(Feature.TvmApplicationControl);
		this._xplatIndicatorEnabled = this.featuresService.isEnabled(Feature.XplatIndicators);
	}

	init(customTiIndicator: CustomTiIndicator, customTiIndicatorType: CustomTiIndicatorsType) {
		if (this._tvmApplicationBlockEnabled) {
			this.actionOptions = [
				this.actionOptions[CustomTiIndicatorActionsTypes.Allow],
				{
					id: CustomTiIndicatorActionsTypes.Audit,
					name: this.i18nService.strings
						.customTiIndicator_dataview_entity_fields_action_values_audit_title,
				},
			];

			if (
				customTiIndicatorType.id !== CustomTiIndicatorsTypes.Files ||
				(customTiIndicatorType.id === CustomTiIndicatorsTypes.Files && this._tvmApplicationControl)
			) {
				this.actionOptions.splice(2, 0, {
					id: CustomTiIndicatorActionsTypes.Block,
					name: this.i18nService.strings
						.customTiIndicator_dataview_entity_fields_action_values_blockexecution_title,
					helpText:
						customTiIndicatorType.id === CustomTiIndicatorsTypes.Files &&
						this._xplatIndicatorEnabled
							? this.i18nService.strings
									.customTiIndicator_dataview_entity_fields_action_windows_only_description
							: null,
				});
			}

			if (customTiIndicatorType.id === CustomTiIndicatorsTypes.Files) {
				this.actionOptions.splice(this._tvmApplicationControl ? 3 : 2, 0, {
					id: CustomTiIndicatorActionsTypes.BlockAndRemediate,
					name: this.i18nService.strings
						.customTiIndicator_dataview_entity_fields_action_values_blockandremediate_title,
				});
			}
		}

		if (
			(this._breakTheGlassEnabled &&
				(customTiIndicatorType.id === CustomTiIndicatorsTypes.Ip ||
					customTiIndicatorType.id === CustomTiIndicatorsTypes.Url)) ||
			(this._tvmApplicationBlockEnabled &&
				this._tvmApplicationControl &&
				customTiIndicatorType.id === CustomTiIndicatorsTypes.Files)
		) {
			this.actionOptions.splice(2, 0, {
				id: CustomTiIndicatorActionsTypes.Warn,
				name: this.i18nService.strings
					.customTiIndicator_dataview_entity_fields_action_values_warn_title,
				helpText:
					customTiIndicatorType.id === CustomTiIndicatorsTypes.Files && this._xplatIndicatorEnabled
						? this.i18nService.strings
								.customTiIndicator_dataview_entity_fields_action_windows_only_description
						: null,
			});
		}

		if (customTiIndicatorType.id === CustomTiIndicatorsTypes.Certificate) {
			const certificateBlock = this._tvmApplicationBlockEnabled
				? {
						id: CustomTiIndicatorActionsTypes.BlockAndRemediate,
						name: this.i18nService.strings
							.customTiIndicator_dataview_entity_fields_action_values_blockandremediate_title,
				  }
				: {
						id: CustomTiIndicatorActionsTypes.Block,
						name: this.i18nService.strings
							.customTiIndicator_dataview_entity_fields_action_values_block_title,
				  };

			this.actionOptions = [this.actionOptions[CustomTiIndicatorActionsTypes.Allow], certificateBlock];
		}

		this.currentActionOption = this.actionOptions.find(
			(action) => action.id === customTiIndicator.action.id
		);

		this.categoryList = Object.keys(IndicatorAlertCategory)
			.filter((k) => typeof IndicatorAlertCategory[k as any] === 'number')
			.map((k) => IndicatorAlertCategory[k]);
		this.selectableMitreTechniques = this.getSelectableMitreTechniques(
			customTiIndicator.category || IndicatorAlertCategory.SuspiciousActivity
		);
	}

	destroy() {
		this._machineGroupSubscription && this._machineGroupSubscription.unsubscribe();
	}

	private _setAvailableScopes(customTiIndicator: CustomTiIndicator) {
		if (this._allMachineGroups.length && this._allMachineGroups.length > 0) {
			this.allowSpecificMachineGroups = true;
			if (this.authService.currentUser.isMdeAdmin) {
				this.allowAllMachineGroups = true;
				this.currentMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.all];

				if (
					!customTiIndicator.isNew &&
					customTiIndicator.rbacGroupIds &&
					customTiIndicator.rbacGroupIds.length > 0
				) {
					this.currentMachineGroupScope = this.selectableMachineGroupScopes[
						ScopeTypesEnum.specific
					];
				}
			} else {
				this.currentMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.specific];

				if (
					!customTiIndicator.isNew &&
					(!customTiIndicator.rbacGroupIds || customTiIndicator.rbacGroupIds.length <= 0)
				) {
					this.currentMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.all];
					this.allowSpecificMachineGroups = false;
					this.allowAllMachineGroups = true;
				}
			}
		} else {
			this.currentMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.all];
		}
	}

	private _setLabelText(values: Array<ChecklistValue>): string {
		if (values.length > 3) return `${values.length} values`;
		const _values = values.map((value) => value.name);
		if (_values.length) return _values.join(', ');
		return this.i18nService.get(
			`customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.selectValues`
		);
	}

	private _getMachineGroupCheckListValue(machineGroup: MachineGroup): ChecklistValue {
		return {
			id: machineGroup.id,
			name: machineGroup.isUnassignedMachineGroup
				? this.i18nService.get('machineGroup.unassignedGroup.name')
				: machineGroup.name,
		};
	}

	private _getUserExposedRbacGroups(): Observable<Array<MachineGroup>> {
		return this.machinesService.getFullUserExposedMachineGroups().pipe(
			tap((userExposedMachineGroups: Array<MachineGroup>) => {
				this._exposedMachineGroups = this._allMachineGroups.filter((machineGroup: MachineGroup) => {
					return (
						userExposedMachineGroups &&
						userExposedMachineGroups.some((group: MachineGroup) => group.id == machineGroup.id)
					);
				});

				this.availableMachineGroups = this._exposedMachineGroups.map((machineGroup: MachineGroup) =>
					this._getMachineGroupCheckListValue(machineGroup)
				);
			})
		);
	}

	get machineGroupsFieldId(): string {
		return 'custom-ti-machine-groups';
	}

	setCurrentMachineGroups(machineGroupIds: Array<number>) {
		this.currentMachineGroups = this._allMachineGroups
			.filter((machineGroup: MachineGroup) => machineGroupIds.includes(machineGroup.id))
			.map((machineGroup: MachineGroup) => this._getMachineGroupCheckListValue(machineGroup));

		this.setLabelText(this.machineGroupsFieldId, this.currentMachineGroups);
	}

	setLabelText(fieldId: string, values: Array<ChecklistValue>) {
		const labelText: string = this._setLabelText(values),
			currentValue: string = this._valuesLabelTexts.get(fieldId);

		if (!currentValue || currentValue !== labelText) this._valuesLabelTexts.set(fieldId, labelText);
		this.labelText =
			this._valuesLabelTexts.get(fieldId) ||
			this.i18nService.get(
				'customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.selectValues'
			);
	}

	setMachineGroups(customTiIndicator: CustomTiIndicator): Observable<Array<MachineGroup>> {
		return this._machineGroupsRepo.allItems$.pipe(
			tap((groups: Array<MachineGroup>) => {
				this.loadingMachineGroups = false;
				this._allMachineGroups = groups;
				this._setAvailableScopes(customTiIndicator);
			}),
			mergeMap(() => this._getUserExposedRbacGroups())
		);
	}

	setIsAlertable(customTiIndicator: CustomTiIndicator) {
		this._isAlertable =
			customTiIndicator.action.id === CustomTiIndicatorActionsTypes.Alert ||
			customTiIndicator.action.id === CustomTiIndicatorActionsTypes.AlertAndBlock ||
			customTiIndicator.generateAlert;
	}

	get isAlertable(): boolean {
		return !!this._isAlertable;
	}

	get isGenerateAlertDirty(): boolean {
		return !!this._isGenerateAlertDirty;
	}

	set isGenerateAlertDirty(value: boolean) {
		this._isGenerateAlertDirty = value;
	}

	get lastGenerateAlertState(): boolean {
		return this._lastGenerateAlertState;
	}

	set lastGenerateAlertState(value: boolean) {
		this._lastGenerateAlertState = value;
	}

	onActionOptionsChange(
		newOption: ChecklistValue,
		customTiIndicator: CustomTiIndicator,
		customTiIndicatorType: CustomTiIndicatorsType,
		generateAlertCheckbox: CheckboxComponent
	) {
		customTiIndicator.action = this.paris.getValue(
			CustomTiIndicatorActionsType,
			(action: CustomTiIndicatorActionsType) => action.id == newOption.id
		);

		if (
			this._tvmApplicationBlockEnabled &&
			customTiIndicatorType.id !== CustomTiIndicatorsTypes.Certificate
		) {
			switch (newOption.id) {
				case CustomTiIndicatorActionsTypes.Audit:
					customTiIndicator.generateAlert = true;
					break;
				case CustomTiIndicatorActionsTypes.Allow:
					customTiIndicator.generateAlert = false;
					break;
				case CustomTiIndicatorActionsTypes.BlockAndRemediate:
				case CustomTiIndicatorActionsTypes.Block:
					if (!this._isGenerateAlertDirty) {
						customTiIndicator.generateAlert = true;
					} else {
						customTiIndicator.generateAlert = this._lastGenerateAlertState;
					}
					break;
				default:
					if (!this._isGenerateAlertDirty) {
						customTiIndicator.generateAlert = false;
					} else {
						customTiIndicator.generateAlert = this._lastGenerateAlertState;
					}
			}

			generateAlertCheckbox.checked = customTiIndicator.generateAlert;
		}
		this.setIsAlertable(customTiIndicator);

		if (this._isAlertable) {
			customTiIndicator.category =
				customTiIndicator.category || IndicatorAlertCategory.SuspiciousActivity;
			customTiIndicator.historicalDetection = customTiIndicator.historicalDetection || false;
		}

		this.resetRedundantFields(customTiIndicator);
	}

	resetRedundantFields(customTiIndicator: CustomTiIndicator) {
		const actionType = customTiIndicator.action.id;

		if (!this._isAlertable) {
			customTiIndicator.severity = null;
			customTiIndicator.category = null;
			customTiIndicator.historicalDetection = null;
			customTiIndicator.lookBackPeriod = null;
			customTiIndicator.mitreTechniques = null;
		}

		if (actionType !== CustomTiIndicatorActionsTypes.Warn) {
			customTiIndicator.educateUrl = null;
			customTiIndicator.bypassDurationHours = null;
		}
	}

	setGenerateAlertChange = (shouldGenenerateAlert: boolean, customTiIndicator: CustomTiIndicator) => {
		customTiIndicator.generateAlert = shouldGenenerateAlert;
		this._isGenerateAlertDirty = true;
		this._lastGenerateAlertState = shouldGenenerateAlert;

		this.setIsAlertable(customTiIndicator);
		this.resetRedundantFields(customTiIndicator);
	};

	getFormatCategoryLabel(category: IndicatorAlertCategory): string {
		const categoryName = IndicatorAlertCategory[category];
		return this.i18nService.get(`reporting.alertsByCategory.${lowerFirst(categoryName)}`);
	}

	getFormatSeverityLabel(sevirityType: CustomTiIndicatorSeverityType): string {
		return this.i18nService.get(
			`customTiIndicator.dataview.entity.fields.alertSeverity.values.${sevirityType.name.toLowerCase()}.title`
		);
	}

	getSelectableMitreTechniques(alertCategory: IndicatorAlertCategory) {
		const categoryName = IndicatorAlertCategory[alertCategory];

		return (
			categoryName &&
			CATEGORY_TO_MITRE_TECHNIQUES_MAPPING[categoryName] &&
			sortBy(
				CATEGORY_TO_MITRE_TECHNIQUES_MAPPING[categoryName].map((technique) => ({
					id: technique,
					name: this.mitreTechniqueDisplayPipe.transform(technique, true),
				})),
				(techniqueField) => this.mitreTechniqueDisplayPipe.transform(techniqueField.id, false)
			)
		);
	}

	onCategoryChanged($event: IndicatorAlertCategory, customTiIndicator: CustomTiIndicator) {
		customTiIndicator.category = $event;

		customTiIndicator.mitreTechniques = null; // on category change, reset the MITRE techniques selection
		this.selectableMitreTechniques = this.getSelectableMitreTechniques($event);
	}

	getMitreTechniqueDropDownPlaceHolder(customTiIndicator: CustomTiIndicator): string {
		if (!customTiIndicator.mitreTechniques || !customTiIndicator.mitreTechniques.length) {
			return this.i18nService.get(
				'hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholder'
			);
		}

		return customTiIndicator.mitreTechniques.length > 1
			? this.i18nService.get(
					'hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholderWithSelection.plural',
					{ count: customTiIndicator.mitreTechniques.length }
			  )
			: this.i18nService.get(
					'hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholderWithSelection.singular'
			  );
	}

	isValidUrl(url: string): boolean {
		if (
			!url ||
			url === '' ||
			(!url.toLowerCase().startsWith('http://') && !url.toLowerCase().startsWith('https://'))
		) {
			return false;
		}
		const domain = this.extractDomainFromUrl(url);
		return RegExpService.ipOrDomain.test(domain);
	}

	private extractDomainFromUrl(url) {
		let domain;
		if (url.indexOf('://') > -1) {
			domain = url.split('/')[2];
		} else {
			domain = url.split('/')[0];
		}

		// find & remove port number
		domain = domain.split(':')[0];

		// find & remove "?"
		domain = domain.split('?')[0];

		return domain;
	}

	isWarnDetailsValid(customTiIndicator: CustomTiIndicator): boolean {
		if (customTiIndicator.action.id !== CustomTiIndicatorActionsTypes.Warn) {
			return true;
		}

		return (
			(!customTiIndicator.bypassDurationHours ||
				(customTiIndicator.bypassDurationHours > 0 &&
					customTiIndicator.bypassDurationHours <= MAX_BYPASSDURATION)) &&
			(!customTiIndicator.educateUrl || this.isValidUrl(customTiIndicator.educateUrl))
		);
	}
}
