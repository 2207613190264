
		<fab-hover-card
			*ngIf="!showEntityNameComponent"
			[type]="cardType"
			[plainCardOptions]="{renderPlainCard: card}"
		>
			<div class="wcd-impacted-entities-counter">
				<ul class="wcd-flex wcd-padding-none-all wcd-margin-none-all wcd-flex-horizontal">
					<li
						class="wcd-padding-small-right wcd-text-overflow-medium"
						*ngFor="let entity of entitiesList"
					>
						<fab-icon
							*ngIf="entity.icon"
							contentClass="field-value-icon"
							[iconName]="entity.icon"
						></fab-icon>
						<span>{{ entity.display }}</span>
					</li>
				</ul>
			</div>
		</fab-hover-card>
		<entity-name
			*ngIf="showEntityNameComponent"
			[entityTypeId]="entityTypeId"
			[entity]="entity"
			[entitySource]="entitySource"
			[showTooltip]="showTooltip"
		></entity-name>
		<ng-template #card>
			<div class="impacted-entities-linked-by-card-container" *ngIf="columns">
				<div class="card-title-height">Entities</div>
				<div class="card-body">
					<wcd-datatable
						[columns]="columns"
						[items]="items"
						[selectEnabled]="false"
						[fixedTable]="true"
						>
					</wcd-datatable>
				</div>
			</div>
		</ng-template>
	