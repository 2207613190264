import { SevilleModule } from '../seville.module';

SevilleModule.provider('hoverPaneService', hoverPaneService);

function hoverPaneService() {
	var provider = {
		$get: [
			'$rootScope',
			'$uibModal',
			function($rootScope, $modal) {
				var pane: any = {};

				function registerToStateChangeEvents() {
					$rootScope.$on('$stateChangeStart', function() {
						if (pane.currentInstance) {
							pane.currentInstance.close();
						}
					});
				}

				pane.open = function(paneOptions) {
					if (!paneOptions.title) {
						throw new Error('pane title is mandatory');
					}

					if (!paneOptions.size || (paneOptions.size != 'lg' && paneOptions.size != 'xl')) {
						throw new Error(
							'size attribute should be specified in the paneOptions (permitted values are: lg  xl)'
						);
					}

					var scope = $rootScope.$new(false);
					scope.title = paneOptions.title;
					scope.icon = paneOptions.icon;
					scope.showIcon = paneOptions.icon !== undefined;
					var windowSizeClass = 'hover-pane-window-' + paneOptions.size;
					pane.currentInstance = $modal.open({
						animation: true,
						template: paneOptions.template,
						controller: paneOptions.controller,
						controllerAs: paneOptions.controllerAs,
						bindToController: paneOptions.bindToController,
						windowTemplateUrl: 'seville.hoverPaneWindow.html',
						windowClass: 'hover-pane-window ' + windowSizeClass,
						backdropClass: 'hover-pane-backdrop',
						resolve: paneOptions.resolve,
						scope: scope,
					});
				};

				pane.close = function() {
					pane.currentInstance.close();
				};

				registerToStateChangeEvents();

				return pane;
			},
		],
	};

	return provider;
}
