export enum MachineRiskScoreType {
	None = 'None',
	Informational = 'Informational',
	Low = 'Low',
	Medium = 'Medium',
	High = 'High',
}

export interface IMachineRiskScore {
	readonly id: MachineRiskScoreType;
	name: string;
	nameI18nKey: string;
	priority: number;
}
