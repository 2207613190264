import { IMachineManagedBy, MachineManagedByType } from './machine-managed-by.models';
import { keyBy } from 'lodash-es';

export const machineManagedByValues: Array<IMachineManagedBy> = [
	{
		id: MachineManagedByType.Mdm,
		name: 'MDM',
		nameI18nKey: 'machineManagedBy_Mdm',
		priority: 1,
	},
	{
		id: MachineManagedByType.Sccm,
		name: 'SCCM',
		nameI18nKey: 'machineManagedBy_Sccm',
		priority: 2,
	},
	{
		id: MachineManagedByType.Mde,
		name: 'MDE',
		nameI18nKey: 'machineManagedBy_Mde',
		priority: 3,
	},
	{
		id: MachineManagedByType.Other,
		name: 'Other',
		nameI18nKey: 'machineManagedBy_Other',
		priority: 4,
	},
];

export const machineManagedByValuesMap = keyBy(machineManagedByValues, o => o.id);

