/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "@angular/common";
import * as i5 from "./user-icon.component";
var styles_UserIconComponent = [];
var RenderType_UserIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserIconComponent, data: {} });
export { RenderType_UserIconComponent as RenderType_UserIconComponent };
function View_UserIconComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "wcd-shared-icon", [["iconName", "users.user"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(1, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "]))], function (_ck, _v) { var currVal_0 = "users.user"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UserIconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 6, "span", [["class", "user-icon"]], [[4, "background-image", null]], null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserIconComponent_1)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(7, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "user-icon"; var currVal_2 = _co.className; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = (!_co.imageUrl && !_co.initials); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.imageUrl ? (("url(" + _co.imageUrl) + ")") : null); _ck(_v, 1, 0, currVal_0); var currVal_4 = (_co.imageUrl ? "" : _co.initials); _ck(_v, 7, 0, currVal_4); }); }
export function View_UserIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "user-icon", [], null, null, null, View_UserIconComponent_0, RenderType_UserIconComponent)), i0.ɵdid(1, 49152, null, 0, i5.UserIconComponent, [], null, null)], null, null); }
var UserIconComponentNgFactory = i0.ɵccf("user-icon", i5.UserIconComponent, View_UserIconComponent_Host_0, { userName: "userName", imageUrl: "imageUrl", className: "className" }, {}, []);
export { UserIconComponentNgFactory as UserIconComponentNgFactory };
