
		<ng-container *ngIf="(alertData$ | async) as alertData">
			<fab-shimmer [isDataLoaded]="alertData !== loadingSymbol">
				<ng-container *ngIf="alertData?.alertsSeveritySummary?.hasAlerts">
					<div class="wcd-padding-large-bottom">
						<alerts-severity-incident-summary
							[alertsSeveritySummary]="alertData.alertsSeveritySummary"
						>
						</alerts-severity-incident-summary>
					</div>
				</ng-container>
				<alerts-dataview
					*ngIf="alertData.alerts?.length"
					[dataViewId]="'mailbox-entity-alert-dataview'"
					[dataViewConfig]="{ data: alertData.alerts, id: 'mailbox-entity-alert-dataview' }"
					[showHeader]="false"
					[allowFilters]="false"
					[allowPaging]="false"
					[allowGrouping]="false"
					[allowTimeRangeSelect]="false"
					[hideControls]="true"
					[disableSelection]="true"
					[onlyFields]="['title', 'severity']"
					[sortDisabledFields]="['title', 'severity']"
					[maxItems]="5"
					[removePadding]="false"
				></alerts-dataview>
			</fab-shimmer>
		</ng-container>
	