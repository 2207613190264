var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, OnDestroy, EventEmitter, ChangeDetectorRef, } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { isCustomTimeRangeValue, } from '@wcd/date-time-picker';
import { DateRangeModel, TzDateService, LocaleConfigService } from '@wcd/localization';
import { Panel, PanelType } from '@wcd/panels';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { PreferencesService } from '@wcd/config';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { Positions } from '@wcd/forms';
import { sccHostService } from '@wcd/scc-interface';
var PANEL_SETTINGS = new Panel({
    type: PanelType.medium,
    headerText: '',
    showOverlay: false,
    isBlocking: false,
    isModal: true,
    isStatic: false,
    noBodyPadding: true,
});
var DataviewActionCustomRangeComponent = /** @class */ (function () {
    function DataviewActionCustomRangeComponent(i18nService, appInsightsService, preferencesService, tzDateService, dialogsService, localeConfigService, route, changeDetector) {
        var _this = this;
        this.i18nService = i18nService;
        this.appInsightsService = appInsightsService;
        this.preferencesService = preferencesService;
        this.tzDateService = tzDateService;
        this.dialogsService = dialogsService;
        this.localeConfigService = localeConfigService;
        this.route = route;
        this.changeDetector = changeDetector;
        this.smallScreenView = false;
        this.openMenuPosition = Positions.Default;
        this.toggleRangeSelectorState = new EventEmitter();
        this.toggleSelectorState = function (isOpen) {
            _this.toggleRangeSelectorState.emit(isOpen);
        };
        this.icon = FabricIconNames.Calendar;
        this.paddingTop = false;
        this.isDirty = false;
        this.createFormatLabel = function () {
            _this.formatLabel = function (value, isSelectionItem) {
                if (isSelectionItem === void 0) { isSelectionItem = false; }
                if (!isSelectionItem &&
                    _this.customDateRange &&
                    isCustomTimeRangeValue(_this.currentRange) &&
                    isCustomTimeRangeValue(value)) {
                    if (sccHostService.isSCC) {
                        var dateFormat = _this.customRangeActionConfig.dateFormat || 'longDate';
                        return _this.tzDateService.format(_this.customDateRange.from, dateFormat) + "-" + _this.tzDateService.format(_this.customDateRange.to, dateFormat);
                    }
                    _this.customRangeFromDisplay = new Intl.DateTimeFormat(_this.localeConfigService.selectedLocale).format(_this.customDateRange.from);
                    _this.customRangeToDisplay = new Intl.DateTimeFormat(_this.localeConfigService.selectedLocale).format(_this.customDateRange.to);
                    return _this.customRangeFromDisplay + "-" + _this.customRangeToDisplay;
                }
                return value.name;
            };
        };
        this.panelSettings = PANEL_SETTINGS;
        this.panelSettings.headerText = this.i18nService.get('common_daterange_panel_header');
    }
    DataviewActionCustomRangeComponent.prototype.togglePanel = function (panelOpen) {
        this.customRangePanelOpened = panelOpen;
    };
    DataviewActionCustomRangeComponent.prototype.onRangeTypeSelect = function (newRange) {
        this.currentRange = newRange;
        var isCustomRange = isCustomTimeRangeValue(this.currentRange);
        if (isCustomRange) {
            this.togglePanel(true);
        }
        if (!isCustomRange)
            this.preferencesService.setPreference(this.preferenceId, this.currentRange.id);
        if (!isCustomRange ||
            (this.originalCustomDateRange &&
                this.customDateRange.toString() !== this.originalCustomDateRange.toString())) {
            this.onRangeSelect();
        }
    };
    DataviewActionCustomRangeComponent.prototype.apply = function () {
        if (isCustomTimeRangeValue(this.currentRange)) {
            this.onNewRangeSelectedCallback(null, this.customDateRange);
        }
        else {
            this.onNewRangeSelectedCallback(this.currentRange);
        }
        this.togglePanel(false);
        this.isDirty = false;
        this.originalCurrentRange = cloneDeep(this.currentRange);
        this.originalCustomDateRange = cloneDeep(this.customDateRange);
    };
    DataviewActionCustomRangeComponent.prototype.cancel = function () {
        this.togglePanel(false);
        this.isDirty = false;
        this.customDateRange = cloneDeep(this.originalCustomDateRange);
        this.currentRange = cloneDeep(this.originalCurrentRange);
    };
    DataviewActionCustomRangeComponent.prototype.onRangeSelect = function () {
        this.isDirty = true;
        this.appInsightsService.trackEvent('UsageTrack', {
            ButtonType: isCustomTimeRangeValue(this.currentRange) ? 'CustomDateRange' : 'DateListPicker',
            Component: this.dataViewId,
        });
        if (isCustomTimeRangeValue(this.currentRange)) {
            var validatedRange = this.validateRange(this.customDateRange);
            if (validatedRange) {
                var textKey = "events.dateRange." + (validatedRange.to !== this.customDateRange.to
                    ? 'validatedLaterThanNow'
                    : 'validatedArchiveRange');
                this.dialogsService.showSnackbar({
                    text: this.i18nService.get(textKey, {
                        from: this.tzDateService.format(validatedRange.from, 'medium'),
                        to: this.tzDateService.format(validatedRange.to, 'medium'),
                    }),
                    icon: 'Info',
                });
                this.customDateRange = new DateRangeModel(this.tzDateService, new Date(validatedRange.from), new Date(validatedRange.to), false);
            }
        }
        else {
            this.customDateRange = DateRangeModel.fromDays(this.tzDateService, this.currentRange.value, undefined, undefined, false);
            this.apply();
        }
    };
    DataviewActionCustomRangeComponent.prototype.ngOnInit = function () {
        var _this = this;
        Object.assign(this, __assign({}, this.customRangeActionConfig));
        this.originalCurrentRange = cloneDeep(this.currentRange);
        this.createFormatLabel();
        this.routeSubscription = this.route.queryParams.subscribe(function () {
            _this.currentRange = _this.customRangeActionConfig.currentRange;
            _this.customDateRange = _this.customRangeActionConfig.customDateRange;
            _this.originalCurrentRange = cloneDeep(_this.currentRange);
            _this.createFormatLabel();
            _this.changeDetector.detectChanges();
        });
    };
    DataviewActionCustomRangeComponent.prototype.ngOnDestroy = function () {
        this.routeSubscription.unsubscribe();
    };
    return DataviewActionCustomRangeComponent;
}());
export { DataviewActionCustomRangeComponent };
