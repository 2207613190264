<div class="wcd-datatable wcd-flex-vertical"
	 [ngClass]="{'resizing': resizingColumn, 'wcd-full-height': fullHeight}"
	 role="table"
	 [attr.tabindex]="tabIndex !== false ? tabIndex : null"
	 [focusOn]="focusOnTable && !focusOnFirstCell"
	 [attr.aria-rowcount]="items?.length || '0'"
	 [attr.aria-colcount]="columns?.length || '0'"
	 [attr.aria-label]="label">
	<div class="datatable-table-wrapper datatable-table-header wcd-flex-none"
		 #listHeaderWrapper
		 *ngIf="showHeaders !== false"
		 role="presentation">
		<ul class="wcd-table datatable-header wcd-flex-horizontal" #listHeader role="row">
			<li class="checkbox-field wcd-datatable-header-check wcd-flex-none allow-focus-outline wcd-datatable-header-color"
				[focusOn]="focusOnFirstCell"
				*ngIf="showCheckboxField"
				(keydown.arrowDown)="onHeaderArrowKey($event,'down')"
				(keydown.arrowRight)="onHeaderArrowKey($event,'right')"
				(keydown.enter)="selectAllEnabled ? toggleAllSelection() : null"
				aria-colindex="1"
				role="columnheader"
				[wcdTooltip]="selectAllEnabled ? someSelected? unselectAllText : selectAllText : null"
				[attr.aria-label]="selectAllEnabled && selectAllRowFocused ? ('grid.columnHeader.selectAllRows' | i18n) : ('grid.columnHeader.selectRow' | i18n)"
				[attr.tabindex]="-1"
				(focus)="fixAllRowAndTabIndexElement($event,true)"
				(blur)="fixAllRowAndTabIndexElement($event,false)"
				aria-sort="none"
				>
				<fab-icon
					iconName="Accept"
					(click)="selectAllEnabled && toggleAllSelection()"
					[attr.aria-label]="someSelected? unselectAllText : selectAllText"
					tabindex="-1"
					[attr.disabled]="!selectAllEnabled"
					[class.disabled]="!selectAllEnabled"
					[attr.aria-disabled]="!selectAllEnabled"
					role="button">
				</fab-icon>
			</li>
			<li *ngFor="let field of columns; let colindex = index"
				[class.sort-by]="sortField === field"
				[class.resizable]="allowResize && field.allowResize !== false"
				[style.width.px]="getFieldWidth(field)"
				[ngClass]="[field.headerClass || '', wrapHeader? 'wrap-normal' : 'nowrap']"
				[attr.aria-sort]="getColumnAriaSort(field)"
				(keydown.arrowDown)="onHeaderArrowKey($event,'down')"
				(keydown.arrowRight)="onHeaderArrowKey($event,'right')"
				(keydown.arrowLeft)="onHeaderArrowKey($event,'left')"
				(focus)="fixTabIndexElement($event)"
				[attr.aria-colindex]="colindex + (showCheckboxField ? 2 : 1)"
				[attr.tabindex]="colindex == 0 && !showCheckboxField ? -1 : null"
				class="allow-focus-outline"
				role="columnheader">
				<ng-container
					*ngTemplateOutlet="dataTableHeaderCell; context:{ field: field }"></ng-container>
			</li>
			<li role="none"></li>
		</ul>
	</div>
	<div class="wcd-datatable__table-resize wcd-flex-horizontal" role="none" aria-hidden="true" *ngIf="allowResize" #tableResizeWrapper>
		<div class="wcd-flex-none checkbox-field" *ngIf="showCheckboxField"></div>
		<div *ngFor="let column of columns"
			 [style.width.px]="getFieldWidth(column)"
			 [attr.field-id]="column.id"
			 class="wcd-flex-none allow-focus-outline">
			<div class="wcd-datatable__column-resize"
				 *ngIf="column.allowResize !== false"
				 cdkDrag cdkDragLockAxis="x"
				 cdkDragBoundary=".wcd-datatable"
				 [class.wcd-datatable__column-resize--resizing]="resizingColumn === column"
				 (mousedown)="startResize(column, $event)"
				 (dblclick)="autoFitColumnSize(column)"
				 (cdkDragMoved)="resizeColumn($event.pointerPosition.x)"
				 (cdkDragEnded)="applyResize(column, $event)">
			</div>
		</div>
	</div>
	<div class="load-top-items wcd-flex-center-all wcd-padding-xsmall-vertical wcd-border-bottom-light"
		role="presentation"
		 [class.hidden]="!showLoadTopItemsButton">
			<fab-action-button
				#loadItemsOnTopElement
				[styles]="{ label: 'ms-fontColor-blue' }"
				(onClick)="loadItemsOnTop()"
				[disabled]="isLoadingTopItems$ | async"
				[text]="'grid.load.newer.results' | i18n"
				(keydown.arrowDown)="onItemOnTopArrowKey($event,'down')"
				(keydown.arrowUp)="onItemOnTopArrowKey($event,'up')"
				[tabIndex]="-1"
			></fab-action-button>
		<fab-spinner *ngIf="isLoadingTopItems$ | async"></fab-spinner>
	</div>
	<div #tableContainer role="presentation" class="wcd-scroll-all datatable-table-wrapper datatable-table-body wcd-flex-1">
		<table class="wcd-table data-table-table"
			   #table
			   role="presentation"
			   [class.data-table-table--resizable]="allowResize"
			   [class.data-table-table--fixed]="fixedTable"
			   [class.small-padding]="isSmallPadding"
			   >
			<thead *ngIf="showHeaders !== false" role="presentation" #tableHeader>
				<tr role="presentation" class="header-row">
					<th role="presentation" class="checkbox-field allow-focus-outline" *ngIf="showCheckboxField">
						<fab-icon iconName="Accept"></fab-icon>
					</th>
					<th *ngFor="let field of columns"
						class="allow-focus-outline"
						role="presentation"
						[style.minWidth.px]="getFieldWidth(field)"
						[style.maxWidth.px]="getFieldWidth(field)"
						[style.width]="fixedTable && fluidHeaderWidth && getFieldWidthPercent(field)"
						[ngClass]="{ 'sort-by': sortField && sortField.id === field.id }"
						[attr.data-test-id]="'datatable-header-' + field.id"
						[attr.aria-sort]="getColumnAriaSort(field)">
						<ng-container
							*ngTemplateOutlet="dataTableHeaderCell; context:{ field: field }"></ng-container>
					</th>
				</tr>
			</thead>
			<ng-container *ngIf="items">
				<ng-container *ngIf="allowGroupItems; else singleItems">
					<ng-container *ngFor="let item of items; let index = index">
						<ng-container *ngIf="isItemGroup && isItemGroup(item); else noGroup">
							<tbody role="rowgroup"
									class="datatable-group"
									[class.disabled]="expandedGroups.has(item) && !groupItems.has(item)"
									[class.expanded]="expandedGroups.has(item)">
							<ng-container
								*ngTemplateOutlet="datatableRows;context:{ items: [item], isHead: true, index: index }"></ng-container>
							<ng-container *ngIf="expandedGroups.has(item) && groupItems.has(item)">
								<ng-container
									*ngTemplateOutlet="datatableRows;context:{ items: groupItems.get(item), isHead: false, maxRows: 10, index: index }"></ng-container>
							</ng-container>
							</tbody>
						</ng-container>
						<ng-template #noGroup>
							<tbody role="rowgroup">
								<ng-container
									*ngTemplateOutlet="datatableRows;context:{ items: [item], isHead: false, index: index }"></ng-container>
							</tbody>
						</ng-template>
					</ng-container>
				</ng-container>
				<ng-template #singleItems>
					<tbody role="rowgroup" class="datatable-items">
						<ng-container
							*ngTemplateOutlet="datatableRows;context:{ items: items, isHead: false, index: 0 }"></ng-container>
					</tbody>
				</ng-template>
			</ng-container>
		</table>
	</div>
</div>
<ng-template #dataTableHeaderCell let-field="field">
	<span *ngIf="isColumnSortable(field); else fieldHeaderTextOnly"
			role="button"
			tabindex="-1"
			class="table-order-btn"
			[ngClass]="{ enabled: sortField && sortField.id === field.id, 'sort-descending': sortField && sortField.id === field.id && sortDescending }"
			(click)="onSortChanged(field)"
		  	(keydown.enter)="onSortChanged(field)"
		  	(keydown.space)="onSortChanged(field)"
		  	[attr.aria-label]="getSortableFieldAriaLabel(field)">
		<fab-icon *ngIf="field.headerIcon" [iconName]="field.headerIcon" contentClass="wcd-padding-right" [ariaLabel]="field.name ? null : 'dataview.header.' + field.id | i18n"></fab-icon>
		{{field.name}}
		<wcd-help
			*ngIf="field.description"
			[text]="field.description"
			[wcdTooltipAllowHtmlRendering]="field.descriptionTooltipAllowHtmlRendering" tabIndexValue="-1"></wcd-help>
		<fab-icon *ngIf="sortField && sortField.id === field.id"
				  contentStyle="display: inline-block"
				  contentClass="table-order-btn-icon"
				  [iconName]="'Sort' + (sortDescending ? 'Down' : 'Up')"></fab-icon>
	</span>
	<ng-template #fieldHeaderTextOnly>
		<span class="table-header-name-no-sort"
			[attr.aria-label]="field.name ? field.name : 'dataview.header.' + field.id | i18n">
			<fab-icon *ngIf="field.headerIcon" [iconName]="field.headerIcon"
					  contentClass="wcd-padding-right"></fab-icon>
			{{field.name}}
			<wcd-help *ngIf="field.description" [text]="field.description" tabIndexValue="-1"></wcd-help>
		</span>
	</ng-template>
</ng-template>
<ng-template #datatableRows let-rowindex="index" let-items="items" let-isHead="isHead" let-maxRows="maxRows">
	<ng-container *ngFor="let item of items; trackBy: boundTrackById; let innerRowindex = index ">
		<tr [ngClass]="{ selected: isSelectable(item) && ((allSelected && !allowParentSelectionWithoutSelectingNestedItems) || selectedItemsIndex.has(item)), unclickable: isItemClickable && !isItemClickable(item) }"
			[class.highlighted]="isItemHighlighted(item)"
			[attr.data-item-id]="item.id"
			(click)="toggleRow($event, item, isHead)"
			(keydown.enter)="toggleRow($event, item, isHead)"
			class="datatable-row"
			[attr.aria-rowindex]="innerRowindex + 1"
			[attr.aria-selected]="isItemSelected(item)"
			role="row">
			<td class="checkbox-field allow-focus-outline"
				*ngIf="showCheckboxField"
				role="gridcell"
				aria-colindex="1"
				[attr.tabindex]="(rowindex == 0 && innerRowindex == 0) ? 0 : null"
				[attr.aria-labelledby]="'cell_' + rowindex +'_' + innerRowindex + '_0_' + tableUniqueId"
				[attr.aria-selected]="isItemSelected(item)"
				(keydown.arrowDown)="onArrowKey($event,'down')"
				(keydown.arrowRight)="onArrowKey($event,'right')"
				(keydown.arrowUp)="onArrowKey($event,'up')"
				(keydown.arrowLeft)="onArrowKey($event,'left')"
				(keyDown.space)="keyboardToggleItemSelection($event, item)"
				(focus)="fixTabIndexElement($event)">
				<wcd-datatable-checkbox
					*ngIf="selectableItems.has(item)"
					(mouseDown)="onCheckboxClick($event)"
					[checked]="isItemSelected(item)"
					(change)="toggleItemSelection(item)"></wcd-datatable-checkbox>
			</td>
			<td *ngFor="let field of columns; let colindex = index"
				[ngClass]="[field.getFieldCssClass ? field.getFieldCssClass(item) || '' : '', field.className || '']"
				[class.nav-disabled]="field.id == 'expand' && expandedTabindex(field, item) != 0"
				[class.full-width-cell]="!allowResize && field.fluidWidth"
				[class.primary-field]="field.primaryField"
				[style.width]="!allowResize && field.fluidWidth ? field.fluidWidth * 100 + '%' : null"
				[style.overflow]="field.overflow"
				[style.minWidth.px]="getFieldWidth(field)"
				[style.maxWidth.px]="getFieldWidth(field)"
				(keydown.arrowDown)="onArrowKey($event,'down')"
				(keydown.arrowRight)="onArrowKey($event,'right')"
				(keydown.arrowUp)="onArrowKey($event,'up')"
				(keydown.arrowLeft)="onArrowKey($event,'left')"
				[attr.aria-colindex]="colindex + (showCheckboxField? 2 : 1)"
				[attr.id]="'cell_' + rowindex + '_' + innerRowindex + '_' + colindex + '_' + tableUniqueId"
				[attr.aria-selected]="!!isItemHighlighted(item)"
				[attr.tabindex]="(!showCheckboxField && rowindex == 0 && innerRowindex == 0  && colindex == 0) ? 0 : null"
				role="gridcell"
				class="allow-focus-outline"
				(focus)="fixTabIndexElement($event)"
			>
				<wcd-datatable-field-value
					[field]="field"
					[value]="item"
					[shortDisplay]="true"
					[refreshOn]="refreshOn"></wcd-datatable-field-value>
			</td>
		</tr>
		<ng-container *ngIf="nestedComponentType && expandedNestedContents.has(item)">
			<tr (keydown.arrowDown)="onArrowKey($event,'down')"
				(keydown.arrowUp)="onArrowKey($event,'up')">
				<td [attr.colspan]="columns?.length">
					<div #customNestedComponent [attr.id]="'nested_' + fixId(item[itemUniqueKey])"></div>
				</td>
			</tr>
		</ng-container>
	</ng-container>
</ng-template>
