import { DateValuePoint } from '@wcd/charts';
declare const moment: typeof import('moment');

const MAX_DAYS_OF_HISTORY = 30;

export function compareVersion(v1: string, v2: string): number {
	const v1Split = v1.split('.');
	const v2Split = v2.split('.');
	const k = Math.min(v1Split.length, v2Split.length);
	for (let i = 0; i < k; ++i) {
		const n1 = parseInt(v1Split[i], 10);
		const n2 = parseInt(v2Split[i], 10);
		if (n1 > n2) return 1;
		if (n1 < n2) return -1;
	}
	return v1Split.length == v2Split.length ? 0 : v1Split.length < v2Split.length ? -1 : 1;
}

// Function to be used for mapping value to a value and a rounded to a day date in a descending order
// e.g. v[i] -> { value: v[i], date: today.day - (i days) }
export function generateDatesForHistoryArray(values: number[], useLocalDate: boolean): DateValuePoint[] {
	return values.slice(0, MAX_DAYS_OF_HISTORY).map((v, index) => ({
		value: Math.floor(v),
		date: (useLocalDate ? moment() : moment.utc()) // Local date
			.subtract({ days: index })
			.startOf('day')
			.toDate(),
	}));
}
