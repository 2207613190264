var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TenantSettings } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
var ITEM_ID = 1;
export var RETENTION_PERIODS = [30, 60, 90, 120, 150, 180];
var GeneralSettingsService = /** @class */ (function () {
    function GeneralSettingsService(paris) {
        this.tenantSettingsRepository = paris.getRepository(TenantSettings);
    }
    GeneralSettingsService.prototype.getTenantSettings = function () {
        return this.tenantSettingsRepository.getItemById(ITEM_ID);
    };
    GeneralSettingsService.prototype.saveTenantSettings = function (settings) {
        return this.tenantSettingsRepository.save(__assign({}, settings, { id: ITEM_ID.toString() }));
    };
    return GeneralSettingsService;
}());
export { GeneralSettingsService };
