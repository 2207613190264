var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { AirsEntityStatus } from './airs-entity-status.entity';
import { InvestigatedMachine } from '../investigation/investigated-machine.entity';
import { RemediationActionType } from '../remediation/remediation-action-type.entity';
import { EvidenceDetectionSource } from '../evidence/evidence-detection-source.value-object';
import { AntivirusRemediationStatus } from './antivirus-remediation-status.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.automatedIr; }, ɵ1 = function (status) {
    // status can be 0, and that doesn't exist
    return status ? status : undefined;
};
var InfectedEntity = /** @class */ (function (_super) {
    __extends(InfectedEntity, _super);
    function InfectedEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], InfectedEntity.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'entity_status' }),
        __metadata("design:type", AirsEntityStatus)
    ], InfectedEntity.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'host' }),
        __metadata("design:type", InvestigatedMachine)
    ], InfectedEntity.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'action_type' }),
        __metadata("design:type", RemediationActionType)
    ], InfectedEntity.prototype, "remediationActionType", void 0);
    __decorate([
        EntityField({ data: 'threat_type' }),
        __metadata("design:type", String)
    ], InfectedEntity.prototype, "threatType", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], InfectedEntity.prototype, "path", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], InfectedEntity.prototype, "paths", void 0);
    __decorate([
        EntityField({
            data: 'av_remediation_status',
            parse: ɵ1,
        }),
        __metadata("design:type", AntivirusRemediationStatus)
    ], InfectedEntity.prototype, "avRemediationStatus", void 0);
    __decorate([
        EntityField({ data: 'entity_detection_src' }),
        __metadata("design:type", EvidenceDetectionSource)
    ], InfectedEntity.prototype, "detectionSource", void 0);
    InfectedEntity = __decorate([
        Entity({
            singularName: 'Infected Entity',
            pluralName: 'Infected Entities',
            readonly: true,
            endpoint: 'entities/infected',
            allItemsEndpointTrailingSlash: false,
            allItemsProperty: 'data',
            separateArrayParams: true,
            supportedEntityGetMethods: [EntityGetMethod.query],
            baseUrl: ɵ0,
        })
    ], InfectedEntity);
    return InfectedEntity;
}(EntityModelBase));
export { InfectedEntity };
export { ɵ0, ɵ1 };
