import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import {
	EvaluationLab,
	EvaluationPassword,
	EvaluationMachineOperatingSystem,	
	EvaluationMachineOs,
	MACHINE_OS_VALUES,
} from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { EvaluationService } from '../services/evaluation.service';
import { SpinnerSize, MessageBarType } from 'office-ui-fabric-react';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { ConfirmEvent } from '../../dialogs/confirm/confirm.event';
import { from, of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const DEFAULT_MACHINE_OS: EvaluationMachineOperatingSystem = EvaluationMachineOperatingSystem.Windows10;
@Component({
	selector: 'evaluation-add-machine-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './evaluation-add-machine-panel.component.html',
})
export class EvaluationAddMachinePanelComponent extends PanelContainer {
	@Input() lab: EvaluationLab;
	@Input() provisionedMachines: number;
	@Input() totalMachines: number;

	constructor(
		router: Router,
		private i18nService: I18nService,
		private evaluationService: EvaluationService,
		private changeDetectorRef: ChangeDetectorRef,
		private dialogsService: DialogsService
	) {
		super(router);
		this.boundCanDeactivate = this.canDeactivate.bind(this);
		this.getMachineOsLabel = this.getMachineOsLabel.bind(this);
	}

	SpinnerSize = SpinnerSize;
	MessageBarType = MessageBarType;

	machineOsValues: Array<EvaluationMachineOs> = MACHINE_OS_VALUES;
	machineOs: EvaluationMachineOs = MACHINE_OS_VALUES.find(os => os.id === DEFAULT_MACHINE_OS);
	evaluationMachine: EvaluationPassword;
	gettingConnectionDetails = false;
	machineUserName = this.evaluationService.getMachineUserName();
	boundCanDeactivate: () => Observable<boolean>;

	getDisclaimer() {
		return this.i18nService.get(
			`evaluation.dashboard.widgets.evaluationMachinesStatus.machinesLimitation`,
			{
				maxMachinesCount: this.lab.maxNumberOfMachines,
				machineExpirationInHours: this.lab.machineExpirationInHours,
			}
		);
	}

	getDescription() {
		return this.provisionedMachines + 1 === this.lab.maxNumberOfMachines
			? this.i18nService.get(
					`evaluation.dashboard.dataView.commandBar.addMachine.descriptionLastMachine`
			  )
			: this.i18nService.get(`evaluation.dashboard.dataView.commandBar.addMachine.description`, {
					provisionedMachinesCount: this.provisionedMachines,
					maxMachinesCount: this.lab.maxNumberOfMachines,
			  });
	}

	addEvaluationMachine() {
		this.gettingConnectionDetails = true;
		this.evaluationService.addEvaluationMachineByOs(this.machineOs.id, this.totalMachines).subscribe(
			(evaluationMachine: EvaluationPassword) => {
				this.evaluationMachine = evaluationMachine;
				this.changeDetectorRef.markForCheck();
			},
			error => {
				this.gettingConnectionDetails = false;
				this.changeDetectorRef.markForCheck();
				this.dialogsService.showError({
					title: this.i18nService.get('evaluation.dashboard.dataView.commandBar.addMachine.error'),
					data: error,
				});
			},
			() => (this.gettingConnectionDetails = false)
		);
	}

	canDeactivate(): Observable<boolean> {
		if (this.gettingConnectionDetails) {
			return from(this.confirmationDialog()).pipe(map((e: ConfirmEvent) => !e.confirmed));
		} else {
			return of(true);
		}
	}

	closeAddMachinePanel() {
		if (this.evaluationMachine) {
			return this.destroy();
		}

		if (this.gettingConnectionDetails) {
			return this.confirmationDialog().then((e: ConfirmEvent) => !e.confirmed && this.destroy());
		}
		return this.destroy();
	}

	getMachineOsLabel(machineOs: EvaluationMachineOs): string {
		return this.i18nService.get(machineOs.keyName);
	}

	getConnectionDetailsTitle() {
		return this.i18nService.get(
			'evaluation.dashboard.dataView.commandBar.addMachine.saveConnectionDetails'
		);
	}

	private confirmationDialog(): Promise<ConfirmEvent<Record<string, string>>> {
		return this.dialogsService.confirm({
			title: this.i18nService.get(
				'evaluation.dashboard.dataView.commandBar.addMachine.confirmCloseTitle'
			),
			text: this.i18nService.get(
				'evaluation.dashboard.dataView.commandBar.addMachine.confirmCloseText'
			),
			confirmText: this.i18nService.get(
				'evaluation.dashboard.dataView.commandBar.addMachine.confirmCloseOk'
			),
			cancelText: this.i18nService.get(
				'evaluation.dashboard.dataView.commandBar.addMachine.confirmCloseCancel'
			),
		});
	}
}
