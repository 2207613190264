import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../../shared/shared.module';
import { DataViewsModule } from '../../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import { TvmModule } from '../../../../tvm/tvm.module';
import { RecommendationExceptionEntityTypeService } from './services/recommendation-exception.entity-type.service';
import { RecommendationExceptionsFieldsService } from './services/recommendation-exceptions.fields.service';
import { RecommendationExceptionEntityPanelComponent } from './components/recommendation-exception.entity-panel.component';
import { RecommendationExceptionAggregatedEntityTypeService } from './services/recommendation-exception-aggregated.entity-type.service';
import { RecommendationExceptionAggregatedEntityPanelComponent } from './components/recommendation-exception-aggregated.entity-panel.component';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule, TvmModule],
	declarations: [
		RecommendationExceptionEntityPanelComponent,
		RecommendationExceptionAggregatedEntityPanelComponent,
	],
	exports: [
		RecommendationExceptionEntityPanelComponent,
		RecommendationExceptionAggregatedEntityPanelComponent,
	],
	entryComponents: [
		RecommendationExceptionEntityPanelComponent,
		RecommendationExceptionAggregatedEntityPanelComponent,
	],
	providers: [
		RecommendationExceptionsFieldsService,
		RecommendationExceptionEntityTypeService,
		RecommendationExceptionAggregatedEntityTypeService,
	],
})
export class RecommendationExceptionEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(RecommendationExceptionEntityTypeService);
		globalEntityTypesService.registerEntityType(RecommendationExceptionAggregatedEntityTypeService);
	}
}
