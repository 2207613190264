import { merge } from 'lodash-es';

const HIGH_PRIORITY = 3;

export class Notification {
	id: string;
	title: string;
	count: number;
	iconName: string;
	iconClassName: string;
	actions: Array<NotificationAction>;
	contents: Array<NotificationContentsItem>;
	link: Array<string>;
	group: string;
	text: string;
	priority: number = 10;
	actionsDisabled: boolean = false;
	timestamp: Date;

	private _isMinimized: boolean = false;
	private _isNew: boolean = true;

	constructor(config: NotificationConfig) {
		merge(this, config);
		this.timestamp = new Date();

		setTimeout(() => (this._isNew = false), 5000);
	}

	get isSelectable(): boolean {
		return !!this.link;
	}

	get isNew(): boolean {
		return this._isNew;
	}

	get isHighPriority(): boolean {
		return this.priority <= HIGH_PRIORITY;
	}

	get isMinimized(): boolean {
		return this._isMinimized;
	}

	clone(): Notification {
		return new Notification(this);
	}

	minimize() {
		this._isMinimized = true;
	}
}

export interface NotificationConfig {
	id?: string;
	title: string;
	iconName: string;
	iconClassName: string;
	count?: number;
	group?: string;
	link?: Array<string>;
	actions?: Array<NotificationAction>;
	text?: string;
	contents?: Array<NotificationContentsItem>;
	priority?: number;
}

interface NotificationContentsItem {
	text: string;
	iconName?: string;
}

export interface NotificationAction {
	name: string;
	iconName?: string;
	method: (notification?: Notification) => void;
}
