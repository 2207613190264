/* tslint:disable:template-accessibility-alt-text */
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { catchError, delayWhen, first, map, retryWhen, scan, switchMap, tap } from 'rxjs/operators';
import { MessageBarType } from 'office-ui-fabric-react';

import { WizardBaseStep } from '@wcd/wizard';

import { EvaluationSetupModel } from '../../models/evaluation-setup.model';
import { EvaluationService } from '../../services/evaluation.service';
import { SimulatorAdditionalSettingsData } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../dialogs/services/dialogs.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<h2 class="wcd-font-weight-bold">{{ 'evaluation.lab.setupPanel.summaryStep.title' | i18n }}</h2>
		<div class="wcd-margin-xLarge-top wcd-margin-mediumSmall-bottom">
			{{ 'evaluation.lab.setupPanel.summaryStep.allSet' | i18n }}
		</div>
		<hr class="wcd-margin-xxLarge-top" />
		<div class="wcd-margin-mediumSmall-bottom wcd-font-weight-semibold">
			{{ 'evaluation.lab.setupPanel.summaryStep.labConfiguration' | i18n }}
		</div>
		<lab-configuration [configuration]="data.labConfiguration"></lab-configuration>
		<hr class="wcd-margin-large-top" />
		<div class="wcd-margin-top wcd-margin-large-bottom wcd-font-weight-semibold">
			{{ 'evaluation.lab.setupPanel.summaryStep.activeVendors' | i18n: { vendorsCount: vendorsCount } }}
		</div>
		<div class="wcd-font-size-s wcd-margin-small-bottom" *ngIf="vendorsCount > 0">
			{{ 'evaluation.lab.setupPanel.summaryStep.vendorName' | i18n }}
		</div>
		<hr class="wcd-margin-none-all" *ngIf="data.withAiq" />
		<ng-container
			*ngIf="data.withAiq"
			[ngTemplateOutlet]="configuredVendor"
			[ngTemplateOutletContext]="{
				learnMore: 'evaluation.lab.setupPanel.summaryStep.attackIqLearnMore' | i18n,
				logo: '/assets/images/partners/aiq_logo.png',
				logoClassName: 'aiq-logo'
			}"
		></ng-container>
		<hr class="wcd-margin-none-all" *ngIf="data.withAiq || data.withSafeBreach" />
		<ng-container
			*ngIf="data.withSafeBreach"
			[ngTemplateOutlet]="configuredVendor"
			[ngTemplateOutletContext]="{
				learnMore: 'evaluation.lab.setupPanel.summaryStep.safeBreachLearnMore' | i18n,
				logo: '/assets/images/partners/safebreach_logo.png',
				logoClassName: 'safebearch-logo'
			}"
		></ng-container>
		<hr class="wcd-margin-none-all" *ngIf="data.withSafeBreach" />

		<ng-container *ngLet="(saving$ | async) as saving">
			<ng-container *ngLet="(error$ | async) as error">
				<fab-spinner *ngIf="saving" contentClass="wcd-margin-xLarge-top"></fab-spinner>
				<fab-message-bar
					*ngIf="error && !saving"
					[messageBarType]="MessageBarType.error"
					contentClass="wcd-margin-xLarge-top"
				>
					{{ error }}
				</fab-message-bar>
			</ng-container>
		</ng-container>

		<ng-template
			#configuredVendor
			let-learnMore="learnMore"
			let-logo="logo"
			let-logoClassName="logoClassName"
		>
			<div class="wcd-flex-horizontal wcd-margin-mediumSmall-vertical">
				<div class="wcd-flex-1 wcd-flex-center-vertical">
					<img [class]="logoClassName + ' wcd-margin-right'" [src]="logo | basePath" />
				</div>
				<markdown class="wcd-flex-1" [data]="learnMore"></markdown>
			</div>
		</ng-template>
	`,
	styles: [
		`
			.aiq-logo {
				width: 122px;
			}
			.safebearch-logo {
				height: 24px;
			}
		`,
	],
})
export class SummaryStepComponent extends WizardBaseStep<EvaluationSetupModel> implements OnInit {
	@Output() onLabCreated = new EventEmitter();
	vendorsCount = 0;
	saving$ = new Subject<boolean>();
	error$ = new ReplaySubject<string>(1);
	MessageBarType = MessageBarType;
	private labCreated$ = new ReplaySubject<boolean>(1);

	constructor(
		private evaluationService: EvaluationService,
		private i18nService: I18nService,
		private dialogsService: DialogsService
	) {
		super();
		this.labCreated$.next(false);
	}

	ngOnInit(): void {
		this.setOnNext(this.createLabAndUpdateSimulators);

		if (this.data.withAiq) {
			this.vendorsCount++;
		}
		if (this.data.withSafeBreach) {
			this.vendorsCount++;
		}
		this.setStepValidation(true);
	}

	createLabAndUpdateSimulators = () => {
		this.setBackButtonEnabled(false);
		this.setStepValidation(false);
		this.saving$.next(true);

		return this.labCreated$.pipe(
			first(),
			// First create the lab and then update the agents configurations
			// NOTE: Do not repeat the lab creation in case of error
			switchMap(labCreated => {
				if (labCreated) {
					return of(true);
				}
				return this.evaluationService.createEvaluationLab({
					id: 0,
					maxNumberOfMachines: this.data.labConfiguration.numberOfMachines,
					machineExpirationInHours: this.data.labConfiguration.hoursPerMachine,
				});
			}),
			tap(() => this.labCreated$.next(true)),
			switchMap(() => {
				const agentAdditionalSettings: SimulatorAdditionalSettingsData = {
					FirstName: this.data.firstName,
					LastName: this.data.lastName,
					EmailAddress: this.data.email,
				};

				return this.evaluationService.setSimulatorsConfigurations(
					agentAdditionalSettings,
					this.data.withSafeBreach,
					this.data.withAiq
				);
			}),
			tap(() => this.onLabCreated.emit()),
			map(() => true),
			retryWhen((errors: Observable<any>) => {
				return errors.pipe(
					scan((errorCount: number, err) => {
						if (errorCount > 2) throw err;
						return errorCount + 1;
					}, 0),
					delayWhen(() => timer(1000))
				);
			}),
			catchError(error => {
				return this.labCreated$;
			}),
			switchMap(labCreated => {
				if (labCreated) {
					this.dialogsService.showSnackbar({
						icon: 'ErrorBadge',
						text: this.i18nService.get('evaluation.lab.setupPanel.summaryStep.simulatorError'),
						className: 'color-box-failureBackground',
					});
					this.onLabCreated.emit();
				} else {
					this.error$.next(
						this.i18nService.get('evaluation.lab.setupPanel.summaryStep.createLabError')
					);
				}

				this.setBackButtonEnabled(true);
				this.setStepValidation(true);
				this.saving$.next(false);
				return of(false);
			})
		);
	};
}
