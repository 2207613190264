import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ElementRef,
	AfterViewInit,
	ViewChild,
	OnDestroy,
	ChangeDetectorRef,
} from '@angular/core';
import { Paris, ModelBase } from '@microsoft/paris';
import { of, Observable, Subscription, zip } from 'rxjs';
import { filter, map, mergeMap, tap, take } from 'rxjs/operators';
import { MachinesService } from '../../../../@entities/machines/services/machines.service';
import { EntityPanelsService } from '../../../../global_entities/services/entity-panels.service';
import { RegExpService } from '@wcd/shared';
import {
	AlertTimeline,
	Alert,
	AlertAlertTimelineRelationship,
	FileInstance,
	Process,
	ProcessActionDetails,
	FileActionDetails,
	AntivirusRemediationAction,
	LegacyUser,
	EntityContext,
	File,
	EntityWithContext,
} from '@wcd/domain';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { AlertsEtwService } from '../../services/alert-etw.service';
import { AlertProcessTreeHelperService } from '../../services/alert-process-tree-helper.service';
import { toObservable } from '../../../../utils/rxjs/utils';

@Component({
	selector: 'alert-process-tree',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<div *ngIf="error && !loading" class="wcd-padding-left">
				<wcd-shared-icon iconName="error"></wcd-shared-icon>
				{{ 'alert.tabs.tree.error' | i18n }}
			</div>
			<div class="wcd-padding-all" *ngIf="!loading && missingEventInfo">
				<div>
					{{
						'alert.tabs.tree.missingEvents.relatedEvents'
							| i18n: { events: missingEventInfo.events }
					}}
				</div>
				<div>
					{{
						'alert.tabs.tree.missingEvents.lastEventTime'
							| i18n: { time: missingEventInfo.spanningTo }
					}}
				</div>
				<div>
					{{ 'alert.tabs.tree.missingEvents.clickForTimelinePreLink' | i18n }}
					<a [href]="missingEventInfo.machineUrl">{{
						'alert.tabs.tree.missingEvents.clickForTimelineLink' | i18n
					}}</a>
					{{ 'alert.tabs.tree.missingEvents.clickForTimelinePostLink' | i18n }}
				</div>
			</div>
			<div #treeContainer id="processTree-container" class="wcd-flex-1 wcd-scroll-vertical">
				<div *ngIf="loading" class="wcd-full-height loading-overlay">
					<i class="large-loader-icon"></i>
				</div>
			</div>
		</div>
	`,
	providers: [AlertProcessTreeHelperService],
})
export class AlertProcessTreeComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() alert: Alert;

	@ViewChild('treeContainer', { static: true }) treeContainerEl: ElementRef<HTMLElement>;

	private subscriptions: Subscription[] = [];
	private alertTimeline$: Observable<AlertTimeline>;

	missingEventInfo: {
		events: String;
		spanningTo: Date;
		machineUrl: String;
	} = null;

	loading: boolean = true;

	error: Error = null;

	constructor(
		private readonly changeDetection: ChangeDetectorRef,
		private readonly genericEtwService: AlertsEtwService,
		private readonly machinesService: MachinesService,
		private readonly entityPanelsService: EntityPanelsService,
		private readonly paris: Paris,
		private readonly activatedEntity: ActivatedEntity,
		private readonly helper: AlertProcessTreeHelperService
	) {}

	getAlert(): Observable<Alert> {
		if (this.alert) {
			return of(this.alert);
		}
		return this.activatedEntity.currentEntity$.pipe(
			filter((entity: ModelBase) => entity instanceof Alert),
			take(1),
			tap((alert: Alert) => {
				this.alert = alert;
			})
		);
	}

	ngOnInit(): void {
		this.alertTimeline$ = this.getAlert().pipe(
			mergeMap((alert: Alert) => {
				return this.paris
					.getRelationshipRepository<Alert, AlertTimeline>(AlertAlertTimelineRelationship)
					.getRelatedItem(alert, {
						where: { flightingOverride: '', isAggregativeAlert: false },
					});
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	ngAfterViewInit(): void {
		const dataSubscription = this.alertTimeline$.pipe(take(1)).subscribe(
			({ raw: alertTimeline }) => {
				if (!alertTimeline) {
					this.error = new Error('No data received');
					return;
				}

				if (alertTimeline.MissingEventsSummary) {
					const events = this.getMissingEventsString(alertTimeline.MissingEventsSummary);
					if (events) {
						this.missingEventInfo = {
							events,
							spanningTo: alertTimeline.MissingEventsSummary.LastEventTime,
							machineUrl:
								this.alert.machine &&
								this.machinesService.getMachineLink(
									this.alert.machine.id,
									false,
									alertTimeline.MissingEventsSummary.LastEventTime
								),
						};
					}
				}
				if (!alertTimeline.RootElements || !alertTimeline.RootElements.length) {
					this.error = new Error('No elements');
				} else {
					const clickSubscription = this.helper
						.init(this.treeContainerEl, this.alert, alertTimeline)
						.subscribe(rawEvent => this.onNodeClick(rawEvent));

					this.subscriptions.push(clickSubscription);
				}

				this.changeDetection.markForCheck();
			},
			error => {
				this.error = error;
			},
			() => {
				this.loading = false;
				this.changeDetection.markForCheck();
			}
		);

		this.subscriptions.push(dataSubscription);
	}

	private getMissingEventsString(missingEventsData) {
		if (!missingEventsData) return '';

		// Translate dictionary<event type, counter> to dictionary<event type friendly name, counter>
		const missingEventsDictionary = {};
		for (const d in missingEventsData.EventsByType) {
			const eventCount = missingEventsData.EventsByType[d];
			const eventFriendlyName = this.helper.getEventTypeFriendlyName(d);

			if (eventFriendlyName in missingEventsDictionary) {
				// Multiple event types could be mapped to the same friendly description - so we should sum them together
				missingEventsDictionary[eventFriendlyName] =
					missingEventsDictionary[eventFriendlyName] + eventCount;
			} else {
				missingEventsDictionary[eventFriendlyName] = eventCount;
			}
		}

		const missingEventsDescriptions = [];
		for (const eventFriendlyName in missingEventsDictionary) {
			const eventCount = missingEventsDictionary[eventFriendlyName];
			const suffix = eventCount == 1 ? 'event' : 'events';
			missingEventsDescriptions.push(eventCount + ' ' + eventFriendlyName + ' ' + suffix);
		}

		return missingEventsDescriptions.join(', ');
	}

	onNodeClick(rawEvent: any) {
		rawEvent.SidePaneType = rawEvent.ActionType;

		if (rawEvent.ElementType === this.genericEtwService.genericEtwElementType) {
			rawEvent.EtwSidePaneDetails = this.genericEtwService.getEtwSidePaneDetails(rawEvent.SidePaneType);
			rawEvent.SidePaneType = this.genericEtwService.getEtwSidePaneType(rawEvent);
		}

		const file$: Observable<FileInstance> = this.paris.createItem(FileInstance, rawEvent);
		const process$: Observable<Process> = this.paris.createItem(Process, rawEvent);
		const targetProcess =
			rawEvent.EtwEventPropertiesAsJson && rawEvent.EtwEventPropertiesAsJson.TargetProcess;
		const targetProcessAction$: Observable<ProcessActionDetails> = toObservable(
			targetProcess &&
				this.paris.createItem(ProcessActionDetails, {
					...rawEvent,
					...targetProcess,
					CreationTime: targetProcess.CreationTimeUtc,
					ProcessCommandLine: targetProcess.CommandLine,
					IntegrityLevel: null, // Delete numeric value. TODO: change back when bug 21642853 is fixed
				})
		);
		const fileAction$: Observable<FileActionDetails> = this.paris.createItem(FileActionDetails, {
			...rawEvent,
			EtwEventPropertiesAsJson: rawEvent.EtwEventPropertiesAsJson && {
				...rawEvent.EtwEventPropertiesAsJson,
				Action:
					rawEvent.EtwEventPropertiesAsJson.Action &&
					AntivirusRemediationAction[rawEvent.EtwEventPropertiesAsJson.Action], // convert from numeric value to enum
			},
		});

		zip(file$, process$, targetProcessAction$, fileAction$)
			.pipe(
				take(1),
				map(
					([file, process, targetProcessAction, fileAction]: [
						FileInstance,
						Process,
						ProcessActionDetails,
						FileActionDetails
					]) => {
						// some tweaking of the received entities, as the alert process tree backend entities are somewhat different
						if (file && !RegExpService.sha1.test(file.sha1)) {
							file = new FileInstance({ ...file, sha1: null });
						}

						if (process) {
							const user: LegacyUser = new LegacyUser({
								id: rawEvent.ProcessAccount,
								accountName: rawEvent.ProcessAccount,
							});
							process = new Process({ ...process, file: file, user: user });
						}

						if (targetProcessAction && targetProcessAction.process) {
							targetProcessAction.process = new Process({
								...targetProcessAction.process,
								file: file,
							});
						}

						return [file, process, targetProcessAction, fileAction];
					}
				)
			)
			.subscribe(
				([file, process, targetProcessAction, fileAction]: [
					FileInstance,
					Process,
					ProcessActionDetails,
					FileActionDetails
				]) => {
					// create appropriate entity and entity context to display, based on the action type
					const mainEntity: any = file && {
						item: file,
						type: File,
					};
					let entityContext: EntityContext<ModelBase> = fileAction && {
						item: fileAction,
						type: FileActionDetails,
						nameKey: 'alerts.events.details.eventDetails',
					};

					switch (rawEvent.SidePaneType) {
						case 'WDAVDetection':
						case 'IRSpynetReport':
							if (entityContext) {
								entityContext.nameKey = 'alerts.events.details.blockDetails';
							}
							break;
						case 'CreateProcess':
						case 'FileSpecifiedInCommandline':
							entityContext = process && {
								item: process,
								type: Process,
								nameKey: 'alerts.events.details.executionDetails',
							};
							break;
						case 'ProcessInjection':
						case 'OpenProcess':
							entityContext = targetProcessAction && {
								item: targetProcessAction,
								type: ProcessActionDetails,
								nameKey: 'alerts.events.details.targetProcessDetails',
							};
							break;
						case 'CreateFile':
							entityContext = fileAction && {
								item: fileAction,
								type: FileActionDetails,
								nameKey: 'alerts.events.details.fileCreationDetails',
							};
							break;
						case 'LoadImage':
							entityContext = fileAction && {
								item: fileAction,
								type: FileActionDetails,
								nameKey: 'alerts.events.details.imageLoadDetails',
							};
							break;
					}

					const entityWithContext = new EntityWithContext({
						id: undefined,
						mainEntity: mainEntity,
						entityContext: entityContext,
					});

					this.entityPanelsService.showEntity(EntityWithContext, entityWithContext);
				}
			);
	}
}
