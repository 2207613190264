
		<wcd-panel (close)="destroy()" [settings]="settings">
			<header
				class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
			>
				<div class="wcd-flex-1">
					<h3 class="side-panel-title" id="email-download-panel-header" #panelHeader>
						{{ i18nService.strings.airsEntities_emailMessage_downloadEmail_title }}
					</h3>
				</div>
			</header>
			<div class="wcd-padding-medium-left">
				<form>
					<div class="wcd-padding-large-vertical">
						<fab-message-bar [messageBarType]="messageBarType.warning">
							<div>
								{{ i18nService.strings.airsEntities_emailMessage_downloadEmail_warning }}
							</div>
						</fab-message-bar>
					</div>
					<wcd-checkbox
						[id]="'agreeToTerms'"
						[name]="'agreeToTerms'"
						[(ngModel)]="agreedToTerms"
						(ngModelChange)="changeDetectorRef.detectChanges()"
						(change)="changeDetectorRef.detectChanges()"
						[label]="i18nService.strings.airsEntities_emailMessage_downloadEmail_agree"
					>
					</wcd-checkbox>
					<fab-primary-button
						contentClass="wcd-margin-large-vertical"
						[iconProps]="{ iconName: 'Download' }"
						[disabled]="!agreedToTerms || isDownloading"
						(onClick)="downloadEmail()"
						[text]="i18nService.strings.airsEntities_emailMessage_downloadEmail_button"
					>
					</fab-primary-button>
				</form>
			</div>
		</wcd-panel>
	