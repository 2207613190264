var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, ModelBase, EntityField, ApiCall, ApiCallModel } from '@microsoft/paris';
import { HuntingTableSchema } from './hunting-table-schema.entity';
import { HuntingFunctionSchema } from './hunting-function-schema.value-object';
var HuntingDatabaseSchema = /** @class */ (function (_super) {
    __extends(HuntingDatabaseSchema, _super);
    function HuntingDatabaseSchema() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Tables', arrayOf: HuntingTableSchema, defaultValue: [] }),
        __metadata("design:type", Array)
    ], HuntingDatabaseSchema.prototype, "tables", void 0);
    __decorate([
        EntityField({ data: 'Functions', arrayOf: HuntingFunctionSchema, defaultValue: [] }),
        __metadata("design:type", Array)
    ], HuntingDatabaseSchema.prototype, "functions", void 0);
    HuntingDatabaseSchema = __decorate([
        ValueObject({
            pluralName: 'Hunting database schemas',
            singularName: 'Hunting database schema',
            readonly: true,
        })
    ], HuntingDatabaseSchema);
    return HuntingDatabaseSchema;
}(ModelBase));
export { HuntingDatabaseSchema };
var ɵ0 = function (config) { return config.data.serviceUrls.huntingService; };
var HuntingDatabaseSchemaApiCall = /** @class */ (function (_super) {
    __extends(HuntingDatabaseSchemaApiCall, _super);
    function HuntingDatabaseSchemaApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingDatabaseSchemaApiCall = __decorate([
        ApiCall({
            name: 'Get hunting database schema',
            method: 'GET',
            baseUrl: ɵ0,
            endpoint: 'schema',
            type: HuntingDatabaseSchema,
        })
    ], HuntingDatabaseSchemaApiCall);
    return HuntingDatabaseSchemaApiCall;
}(ApiCallModel));
export { HuntingDatabaseSchemaApiCall };
export { ɵ0 };
