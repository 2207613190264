/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "../../../../../../projects/shared/src/lib/components/route-link.component.ngfactory";
import * as i4 from "../../../../../../projects/shared/src/lib/components/route-link.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../../projects/shared/src/lib/services/routes.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i9 from "../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./machine-name.component";
import * as i12 from "../../global_entities/services/global-entity-types.service";
var styles_MachineNameComponent = [];
var RenderType_MachineNameComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MachineNameComponent, data: {} });
export { RenderType_MachineNameComponent as RenderType_MachineNameComponent };
function View_MachineNameComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fab-icon", [], null, null, null, i1.View_FabIconComponent_0, i1.RenderType_FabIconComponent)), i0.ɵdid(1, 5816320, null, 0, i2.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconCssClass; var currVal_1 = _co.FabricIconNames.System; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MachineNameComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MachineNameComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "route-link", [], null, null, null, i3.View_RouteLinkComponent_0, i3.RenderType_RouteLinkComponent)), i0.ɵdid(1, 573440, null, 0, i4.RouteLinkComponent, [i5.UrlHandlingStrategy, i5.Router, i6.RoutesService], { routeLink: [0, "routeLink"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_MachineNameComponent_3)), i0.ɵdid(4, 540672, null, 0, i7.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.machineRouteLinkModel; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v.parent, 7); _ck(_v, 4, 0, currVal_1); }, null); }
function View_MachineNameComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "noprint na-on-empty"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 147456, null, 0, i8.TooltipDirective, [i0.ElementRef, i9.TooltipsService, i10.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i0.ɵted(2, null, ["\n\t\t\t\t", "\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.shortName !== _co.fullName) ? _co.fullName : null); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.preferFullName ? _co.fullName : _co.shortName); _ck(_v, 2, 0, currVal_1); }); }
function View_MachineNameComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MachineNameComponent_5)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "print-only na-on-empty"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasName; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.fullName; _ck(_v, 5, 0, currVal_1); }); }
export function View_MachineNameComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MachineNameComponent_1)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MachineNameComponent_2)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["machineName", 2]], null, 0, null, View_MachineNameComponent_4)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showIcon && _co.hasName); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.showLink && _co.machineId); var currVal_2 = i0.ɵnov(_v, 7); _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_MachineNameComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "machine-name", [], null, null, null, View_MachineNameComponent_0, RenderType_MachineNameComponent)), i0.ɵdid(1, 49152, null, 0, i11.MachineNameComponent, [i12.GlobalEntityTypesService], null, null)], null, null); }
var MachineNameComponentNgFactory = i0.ɵccf("machine-name", i11.MachineNameComponent, View_MachineNameComponent_Host_0, { showIcon: "showIcon", iconCssClass: "iconCssClass", showLink: "showLink", preferFullName: "preferFullName", machine: "machine" }, {}, []);
export { MachineNameComponentNgFactory as MachineNameComponentNgFactory };
