/* tslint:disable:template-accessibility-label-for */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { AuthService, MtpPermission } from '@wcd/auth';
import { AppContextService, Feature, FeaturesService, PreferencesService } from '@wcd/config';
import {
	AlertClassification,
	AlertDetermination,
	Incident,
	IncidentStatus, IncidentStatusValueIds, incidentStatusValues, IncidentsUpdateApiCall
} from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { PanelContainer } from '@wcd/panels';
import { breadcrumbsStateService } from '@wcd/shared';
import { TelemetryService, TrackingEventType } from '@wcd/telemetry';
import { AssigneePickerComponent } from 'app/forms/components/assignee-picker.component';
import { escapeRegExp } from 'lodash-es';
import {
	IBasePickerSuggestionsProps, IDropdownOption,
	IInputProps,
	IPersonaProps,
	ITag,
	MessageBarType
} from 'office-ui-fabric-react';
import { of, Subscription, zip } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { combinedClassifications, getCombinedClassificationMap } from '../../alerts/services/alerts.service';
import { IncidentsService } from '../services/incidents.service';

const RESOLVED_INCIDENT_STATUS_ID: number = IncidentStatusValueIds.IncidentResolvedStatusId;
const KEY_CODES = {
	13: 'ENTER',
	27: 'ESCAPE',
	38: 'UP',
	40: 'DOWN',
	9: 'TAB',
};
let lastId = 0;

@Component({
	selector: 'incidents-manage-entity-panel',
	templateUrl: './incident.manage-panel.component.html',
	styleUrls: ['./incident.manage-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentManagePanelComponent extends PanelContainer implements OnInit, OnDestroy {
	@Input() incident: Incident;

	id: string;
	readonly NOT_SET = '0';
	comment: string;
	incidentName: string;
	incidentClassification: AlertClassification;
	selectableAlertClassifications: Array<AlertClassification>;
	allAlertDeterminations: Array<AlertDetermination>;
	determination: AlertDetermination;
	assignedToMe: boolean;
	resolveIncident: boolean;
	isSaving = false;
	tags: ITag[];
	currentUserName: string;
	currentStatus: number;
	selectedStatus: string
	incidentUserDefinedTags: ITag[];
	assignedTo: IPersonaProps = {};
	assignedToMail: string;
	tagsPickerSuggestionsProps: IBasePickerSuggestionsProps;
	MessageBarType = MessageBarType;
	tagsSubscriptions: Subscription;
	tagPickerInputProps: IInputProps = {
		onKeyPress: e => {
			// do not allow !,@,-,+ in the start of the tag name
			if (/[!@\-+]/.test(e.key) && (<any>e.target).value === '' && !KEY_CODES[e.nativeEvent.keyCode]) {
				e.preventDefault();
			}
		},
		'aria-label': this.i18nService.strings.incident_commandBar_actions_manageIncident_tags,
	};
	isReadOnly: boolean = false;
	isAssignToSomeoneElseEnabled = false;
	isCombinedClassificationEnabled = false;
	isInProgressIncidentStatusEnabled: boolean;
	combinedClassificationMap: IDropdownOption[] = [];
	incidentStatusOptions: IDropdownOption[] = [];
	selectedCombinedClassification;

	constructor(
		private readonly i18nService: I18nService,
		router: Router,
		private route: ActivatedRoute,
		private paris: Paris,
		private incidentsService: IncidentsService,
		private readonly dialogsService: DialogsService,
		private readonly authService: AuthService,
		readonly telemetryService: TelemetryService,
		private changeDetection: ChangeDetectorRef,
		private rbacControlService: RbacControlService,
		private appContextService: AppContextService,
		private preferencesService: PreferencesService,
		private featuresService: FeaturesService,
	) {
		super(router);
		this.id = String(++lastId);
		this.currentUserName = authService.currentUser.username;
		this.isAssignToSomeoneElseEnabled = this.featuresService.isEnabled(Feature.AssignToSomeoneElse);
		this.isCombinedClassificationEnabled = this.featuresService.isEnabled(Feature.CombinedClassification);
		this.isInProgressIncidentStatusEnabled = this.featuresService.isEnabled(Feature.InProgressIncidentStatus);
		this.allAlertDeterminations = paris.getRepository(AlertDetermination).entity.values.filter(value => this.isCombinedClassificationEnabled || !value.combinedClassification);
		this.selectableAlertClassifications = paris.getRepository(AlertClassification).entity.values.filter(value => this.isCombinedClassificationEnabled || !value.combinedClassification);
		this.combinedClassificationMap = getCombinedClassificationMap(this.i18nService);
		this.incidentStatusOptions = incidentsService.getIncidentStatusDropdownValues(this.i18nService);
		this.selectedCombinedClassification = this.combinedClassificationMap[0].key;


		this.tagsPickerSuggestionsProps = {
			suggestionsHeaderText: this.i18nService.get(
				'incident.commandBar.actions.manageIncident.suggestedTagsHeaderText'
			),
			noResultsFoundText: this.i18nService.get(
				'incident.commandBar.actions.manageIncident.noResultsFoundText'
			),
		};
	}

	ngOnInit() {
		super.ngOnInit();
		if (!this.incident) {
			return;
		}
		this.incidentClassification = this.incident.classification;
		this.currentStatus = this.incident.status.id;
		this.selectedStatus = this.incident.status.type;
		this.determination = this.incident.determination;
		const foundClassification = this.incident.determination ?
			this.combinedClassificationMap.find(classification => classification.key === this.incident.determination.type)
			: this.combinedClassificationMap[0];
		this.selectedCombinedClassification = foundClassification && foundClassification.key;
		this.tags = this.incident.tags.map(tag => ({ name: tag, key: tag }));
		this.assignedToMe = this.incident.assignedTo === this.currentUserName;
		if(this.isAssignToSomeoneElseEnabled) {
			this.initAssignedTo();
		}
		else {
			this.assignedToMail = this.incident.assignedTo;
		}
		this.incidentName = this.incident.name;
		const rbacSettings = {
			mtpPermissions: [MtpPermission.SecurityData_Manage],
			mtpWorkloads: this.incident.mtpWorkloads,
			requireAllPermissions: true,
			ignoreNonActiveWorkloads: true,
		};
		this.isReadOnly = !this.incident.isFullyMachineRbacExposed || !this.rbacControlService.hasRequiredRbacPermissions(rbacSettings);

		this.tagsSubscriptions = this.incidentsService.getAllUserDefinedIncidentTags().subscribe(
			tags =>
				(this.incidentUserDefinedTags = tags.map(tag => ({
					name: tag.name,
					key: tag.name,
					id: tag.name,
				})))
		);

		this.resolveIncident = this.incident.status.id === RESOLVED_INCIDENT_STATUS_ID;
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.tagsSubscriptions.unsubscribe();
	}

	saveIncident() {
		this.isSaving = true;
		const previousAssignee = this.incident.assignedTo;
		const newAssignee = this.isAssignToSomeoneElseEnabled ? (previousAssignee === null && (!this.assignedTo || !this.assignedTo.secondaryText)) ? null : this.assignedTo && this.assignedTo.secondaryText
			: (previousAssignee === null && !this.assignedToMail) ? null : this.assignedToMail;

		zip(
			this.hasTagsChanged(this.incident.tags, this.tags)
				? this.incidentsService.updateIncidentTags([this.incident], <any>this.tags)
				: of(null),
			!!this.incidentName && this.incidentName !== this.incident.name
				? this.paris.apiCall( IncidentsUpdateApiCall, {
						title: this.incidentName,
						incidentIds: [this.incident.id],
				  })
				: of(null),
			this.paris
				.apiCall(IncidentsUpdateApiCall, {
					incidentIds: [this.incident.id],
					determination: this.determination,
					assignedTo: newAssignee,
					classification: this.incidentClassification,
					status:
						this.currentStatus !== this.incident.status.id
							? new IncidentStatus({
									id: this.currentStatus,
							  })
							: undefined,
					comment: this.comment,

				})
				.pipe(mergeMap(() => this.paris.getItemById(Incident, this.incident.id)))
		).subscribe(
			() => {
				this.trackResolveReactivate(this.currentStatus);
				this.dialogsService.showSuccessSnackbar({
					text: this.i18nService.get(
						'incident.commandBar.actions.manageIncident.incidentUpdateSuccess'
					),
					icon: 'tag',
				});
				this.incidentsService.clearCachedIncidents();
				this.incidentsService.clearAllUserDefinedIncidentTags();

				if (this.incidentName !== this.incident.name) {
					const lastBreadcrumb =
						breadcrumbsStateService.breadcrumbs$.value[
							breadcrumbsStateService.breadcrumbs$.value.length - 1
						];
					lastBreadcrumb.label = this.incidentName;
					breadcrumbsStateService.add(lastBreadcrumb);
					this.updateRouteSnapshot();
				}
				this.isAssignToSomeoneElseEnabled && AssigneePickerComponent.updateUserPreferredAssignees(this.assignedTo, this.preferencesService);

				this.destroy();
			},
			error => {
				this.isSaving = false;
				this.dialogsService.showError({
					title: this.i18nService.get(
						'incident.commandBar.actions.manageIncident.incidentUpdateError'
					),
					data: error,
				});
			}
		);
	}

	onNewClassificationDropdownChange(event) {
		this.selectedCombinedClassification = event.option.key;
		const { classification, determination } = combinedClassifications[event.option.key];
		this.incidentClassification = this.selectableAlertClassifications.find(alertClass => alertClass.id === classification)
		this.determination = this.allAlertDeterminations.find(alertDet => alertDet.id === determination);
		this.changeDetection.detectChanges();
	}


	incidentStatusChange(event) {
		this.currentStatus = incidentStatusValues.filter(status => status.type === event.option.key)[0].id;
		this.selectedStatus = incidentStatusValues.filter(status => status.type === event.option.key)[0].type;
		this.changeDetection.detectChanges();
	}

	onSelectAssignee = (assignee) => {
		this.assignedTo = assignee;
		this.changeDetection.detectChanges();
	}

	initAssignedTo(){
		this.assignedTo = this.incident.assignedTo ? {
			secondaryText: this.incident.assignedTo
		} : null
	}

	updateRouteSnapshot = (): void => {
		let route = this.route.snapshot;
		while (!!route) {
			const breadcrumbs = route.data['breadcrumbs'];
			if (!!breadcrumbs && breadcrumbs.length > 0) {
				route.data['breadcrumbs'] = breadcrumbsStateService.breadcrumbs$.value;
			}
			route = route.firstChild;
		}
	};


	onAssignToMeChanges(newVal: boolean) {
		this.assignedToMe = newVal;
		if (newVal) {
			this.assignedToMail = this.currentUserName;
		} else if (!newVal && this.incident.assignedTo === this.currentUserName) {
			this.assignedToMail = '';
		} else {
			this.assignedToMail = this.incident.assignedTo;
		}
		this.changeDetection.detectChanges();
	}

	resolveIncidentChanges(newVal: boolean) {
		this.resolveIncident = newVal;
		this.currentStatus = newVal ? 2 : 1;
		this.changeDetection.detectChanges();
	}

	incidentClassificationChanges(newVal: AlertClassification) {
		this.incidentClassification = newVal;
		if (newVal.id === this.NOT_SET) {
			this.resolveIncident = false;
			this.currentStatus = 1;
		}
		this.changeDetection.detectChanges();
	}

	determinationChanges(newVal: AlertDetermination) {
		this.determination = newVal;
		this.changeDetection.detectChanges();
	}

	hasTagsChanged(originalTags: Array<string>, newTags: ITag[]): boolean {
		if (originalTags.length === newTags.length) {
			const originalTagsMap = originalTags.reduce((r, t) => ({ ...r, [t.toLowerCase()]: 1 }), {});
			return newTags.reduce((r, tag) => r || !originalTagsMap[tag.name.toLowerCase()], false);
		}

		return true;
	}

	onTagsChange(ev) {
		this.tags = ev.items;
	}

	onFilterChanged = (filterText: string, tagList: ITag[]): ITag[] => {
		const termRegExp = new RegExp(escapeRegExp(filterText), 'i');
		const selectedTags = tagList.reduce((r, t) => ({ ...r, [t.name.toLowerCase()]: 1 }), {});

		const matchedUserDefinedTags = this.incidentUserDefinedTags
			.filter(tag => !selectedTags[tag.name.toLowerCase()] && termRegExp.test(tag.name))
			.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
			.slice(0, 10) || [];

		const exactMatchingTags = matchedUserDefinedTags.filter(tag => tag.name.toLowerCase() === filterText.toLowerCase())

		if (exactMatchingTags.length === 0 && !selectedTags[filterText.toLowerCase()])  {
			return [...matchedUserDefinedTags, <ITag>{ key: filterText, name: filterText, isNewItem: true }]
		}
		return matchedUserDefinedTags
	};

	trackResolveReactivate(currentStatus: number) {
		if (currentStatus !== this.incident.status.id) {
			this.telemetryService.trackEvent({
				type: TrackingEventType.Button,
				id: incidentStatusValues.filter(status => status.id === currentStatus)[0].type,
				component: 'incident-manage side panel',
				componentType: 'Side Panel',
			});
		}
	}
}
