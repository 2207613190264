import { Injectable } from '@angular/core';
import { mapValues } from 'lodash-es';
import { getTheme } from 'office-ui-fabric-react';

import { Lazy } from '@wcd/utils';
import { KnownColor, KnownColorName } from '../models/colors/known-colors.models';
import { m365ExtendedColors } from '../models/colors/m365-colors-palette.model';

function createMap(palette: any): any {
	return mapValues(palette, (value, key) => ({
		name: key as KnownColorName,
		raw: value as any,
		classes: {
			font: `ms-fontColor-${key}`,
			background: `ms-bgColor-${key}`,
			border: `ms-borderColor-${key}`,
		},
	}));
}

/**
 * Service for exposing colors (in various forms) to components and other consumers in an easy way.
 */
@Injectable({ providedIn: 'root' })
export class KnownColorsService {
	private readonly _theme = getTheme();

	private readonly _colorsMap = new Lazy<Record<KnownColorName, KnownColor>>(() => {
		return { ...createMap(this._theme.palette), ...createMap(m365ExtendedColors) };
	});

	private readonly _fontColorsClassMap = new Lazy<Record<KnownColorName, string>>(() =>
		this._calculateClassMap('font')
	);

	private readonly _backgroundColorsClassMap = new Lazy<Record<KnownColorName, string>>(() =>
		this._calculateClassMap('background')
	);

	private readonly _borderColorsClassMap = new Lazy<Record<KnownColorName, string>>(() =>
		this._calculateClassMap('border')
	);

	get knownColorsMap(): Record<KnownColorName, KnownColor> {
		return this._colorsMap.value;
	}

	get fontColorsClassMap(): Record<KnownColorName, string> {
		return this._fontColorsClassMap.value;
	}

	get backgroundColorsClassMap(): Record<KnownColorName, string> {
		return this._backgroundColorsClassMap.value;
	}

	get borderColorsClassMap(): Record<KnownColorName, string> {
		return this._borderColorsClassMap.value;
	}

	private _calculateClassMap(type: keyof KnownColor['classes']): Record<KnownColorName, string> {
		return mapValues(this.knownColorsMap, value => value.classes[type]) as any;
	}
}
