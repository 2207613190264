/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../../../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i4 from "../../../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/common";
import * as i7 from "./machines-at-risk.widget";
import * as i8 from "../../../../../shared-reports/services/reports.service";
import * as i9 from "../../../../../../../../../projects/app-config/src/lib/service-urls/services/service-urls.service";
import * as i10 from "../../../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i11 from "@microsoft/paris/dist/lib/paris";
import * as i12 from "../../../../../global_entities/services/entity-panels.service";
import * as i13 from "../../../../../../../../../projects/config/src/lib/services/features.service";
import * as i14 from "../../../../../global_entities/services/global-entity-types.service";
var styles_MachinesAtRiskWidget = [];
var RenderType_MachinesAtRiskWidget = i0.ɵcrt({ encapsulation: 2, styles: styles_MachinesAtRiskWidget, data: {} });
export { RenderType_MachinesAtRiskWidget as RenderType_MachinesAtRiskWidget };
export function View_MachinesAtRiskWidget_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "wcd-datatable", [["class", "table-with-legend"]], [[8, "hidden", 0]], [[null, "itemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemClick" === en)) {
        var pd_0 = (_co.showMachineSidePanel($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DataTableComponent_0, i3.RenderType_DataTableComponent)), i0.ɵdid(3, 13352960, null, 0, i4.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i5.LiveAnnouncer, i2.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], showHeaders: [3, "showHeaders"], label: [4, "label"] }, { itemClick: "itemClick" }), i0.ɵpid(131072, i6.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(5, 1), i0.ɵpid(131072, i6.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.data$)); var currVal_2 = false; var currVal_3 = _co.tableColumns; var currVal_4 = false; var currVal_5 = i0.ɵunv(_v, 3, 4, _ck(_v, 5, 0, i0.ɵnov(_v, 0), "dashboard.threatAnalytics.mitigationRecommendation.affectedMachinesColumn")); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 6).transform(_co.error$)); _ck(_v, 2, 0, currVal_0); }); }
export function View_MachinesAtRiskWidget_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "machines-at-risk-widget", [], null, null, null, View_MachinesAtRiskWidget_0, RenderType_MachinesAtRiskWidget)), i0.ɵdid(1, 245760, null, 0, i7.MachinesAtRiskWidget, [i8.ReportsService, i9.ServiceUrlsService, i10.AppConfigService, i11.Paris, i12.EntityPanelsService, i13.FeaturesService, i14.GlobalEntityTypesService, i2.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MachinesAtRiskWidgetNgFactory = i0.ɵccf("machines-at-risk-widget", i7.MachinesAtRiskWidget, View_MachinesAtRiskWidget_Host_0, {}, {}, []);
export { MachinesAtRiskWidgetNgFactory as MachinesAtRiskWidgetNgFactory };
