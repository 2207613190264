
		<wcd-select
			[(ngModel)]="currentDropdownSelection"
			[values]="dropdownValues"
			[formatLabel]="config?.dropdown?.formatLabel"
			[label]="config?.dropdown?.label"
			[isFullWidth]="true"
			[placeholder]="config?.dropdown?.placeholder"
			(ngModelChange)="onDropdownSelect()"
		></wcd-select>
		<div class="wcd-padding-top">
			<fab-spinner
				*ngIf="(isLoading$ | async); else checklist"
				[label]="loadingText$ | async"
				[size]="spinnerSize"
			></fab-spinner>
			<ng-template #checklist>
				<span *ngIf="error; else checklistContents">{{ error }}</span>
				<ng-template #checklistContents>
					<wcd-filter-values-checklist
						(filterValuesChange)="onChecklistSelectionChange($event)"
						[fieldId]="fieldId"
						[data]="currentChecklistValues"
						[config]="checklistConfig"
						[selectedValues]="selectedValues.checklist"
					></wcd-filter-values-checklist>
				</ng-template>
			</ng-template>
		</div>
	