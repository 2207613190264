var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { PrettyNumberService } from '@wcd/prettify';
import { map } from 'rxjs/operators';
import { SpinnerSize } from 'office-ui-fabric-react';
var DistributionComponent = /** @class */ (function () {
    function DistributionComponent(prettyNumberService) {
        this.prettyNumberService = prettyNumberService;
        this.SpinnerSize = SpinnerSize;
        this.onDistributionComplete = new EventEmitter();
        this.size = 'compact';
        this.sameWidth = false;
        this.clickable = false;
        this.onClick = new EventEmitter(false);
    }
    Object.defineProperty(DistributionComponent.prototype, "distributionData$", {
        set: function (value$) {
            var _this = this;
            if (!value$) {
                return;
            }
            this.processedDistributionData$ = value$ && value$.pipe(map(function (distributionDataArr) {
                return distributionDataArr.map(function (distributionData) {
                    var value = typeof distributionData.mainText === 'number'
                        ? _this.prettyNumberService.prettyNumber(distributionData.mainText)
                        : distributionData.mainText;
                    return __assign({}, distributionData, { mainText: value });
                });
            }));
            this.onDistributionComplete.emit();
        },
        enumerable: true,
        configurable: true
    });
    return DistributionComponent;
}());
export { DistributionComponent };
