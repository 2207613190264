import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { Process, File } from '@wcd/domain';
import { ProcessEntityPanelComponent } from '../components/process.entity-panel.component';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';

@Injectable()
export class ProcessEntityTypeService implements EntityTypeService<Process> {
	constructor(private globalEntityTypesService: GlobalEntityTypesService) {}
	readonly entityType: Readonly<EntityType<Process>> = {
		entity: Process,
		id: 'process',
		icon: 'entities.process',
		singleEntityPanelComponentType: ProcessEntityPanelComponent,
		getEntityName: process =>
			process
				? process.name ||
				  (process.file && process.file.fileName) ||
				  (process.id && `[PID ${process.id}]`)
				: 'Unknown process',
		getEntitiesLink: processes => {
			if (processes.length === 1) {
				const processId = processes[0].file ? processes[0].file.id : processes[0].id;
				return this.globalEntityTypesService.getEntityLink(File, <Partial<File>>{ id: processId });
			}
			return null;
		},
		getItemParams: (process: Process, options: {}) => {
			return Object.assign({}, options, { fileHash: process.file.id });
		},
	};
}
