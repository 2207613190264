import {
	ChangeDetectorRef,
	ComponentFactoryResolver,
	ComponentRef,
	Inject,
	Injectable,
	Injector,
	OnDestroy,
	Type,
	ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { PanelSettings } from '../models/panel-settings.model';
import { Panel } from '../models/panel.model';
import { PanelType } from '../models/panel-type.enum';
import { PanelContainer } from '../models/panel-container.model';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { merge } from 'lodash-es';
import { TelemetryService } from '@wcd/telemetry';
import { panelService } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';

const PANEL_OPEN_CLASS = 'panel-open';

// http://blog.brecht.io/Modals-in-angular2/

const PANEL_WIDTHS = {
	small: 320,
	medium: 480,
	large: 640,
	extraLarge: 1024,
};

/** @dynamic */
@Injectable()
export class PanelService implements OnDestroy {
	// here we hold our placeholder
	private vcRef: ViewContainerRef;
	// here we hold our injector
	private injector: Injector;
	// we can use this to determine z-index of multiple panels
	private eventsSub: Subscription;
	public activeInstances = 0;
	activeInstances$: Subject<number> = new Subject();
	activePanels$: BehaviorSubject<Array<Panel>> = new BehaviorSubject<Array<Panel>>([]);

	panelRef: ComponentRef<any>[] = [];
	private resolver: ComponentFactoryResolver;
	private _queuedPanel: { component: Type<any>; settings?: PanelSettings; parameters?: Object };
	private _lastComponentRef$: ReplaySubject<any>;

	constructor(
		router: Router,
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly telemetryService: TelemetryService
	) {
		this.eventsSub = router.events
			.pipe(filter((event: Event) => event instanceof NavigationEnd))
			.subscribe((event: Event) => {
				this.closeAllModalPanels(true);
			});
	}

	registerResolver(resolver: ComponentFactoryResolver) {
		this.resolver = resolver;

		if (this.injector && this.vcRef) this.createQueuedPanel();
	}

	registerViewContainerRef(vcRef: ViewContainerRef): void {
		this.vcRef = vcRef;

		if (this.injector && this.resolver) this.createQueuedPanel();
	}

	registerInjector(injector: Injector): void {
		this.injector = injector;

		if (this.vcRef && this.resolver) this.createQueuedPanel();
	}

	private createQueuedPanel() {
		if (!this._queuedPanel) return;

		this.create(this._queuedPanel.component, this._queuedPanel.settings, this._queuedPanel.parameters);
		this._queuedPanel = null;
		this._lastComponentRef$ = null;
	}

	create<t extends PanelContainer>(
		component: Type<t>,
		settings?: PanelSettings,
		parameters?: Object,
		componentFactoryResolver?: ComponentFactoryResolver
	): Observable<ComponentRef<t>> {
		const componentRef$ = this._lastComponentRef$ || new ReplaySubject();

		const panel: Panel = new Panel(settings);

		if (this.vcRef) {
			const currentFocusedElement = this.document.activeElement;
			const injector = Injector.create({ providers: [], parent: this.vcRef.parentInjector }),
				factory =
					(componentFactoryResolver &&
						componentFactoryResolver.resolveComponentFactory(component)) ||
					this.resolver.resolveComponentFactory(component),
				componentRef = factory.create(injector);

			this.vcRef.insert(componentRef.hostView);

			Object.assign(componentRef.instance, merge({}, parameters, { settings: panel })); // pass the @Input parameters to the instance

			this.activeInstances++;
			this.togglePanelOpenFlag();
			this.activeInstances$.next(this.activeInstances);

			setTimeout(() => {
				this.activePanels$.next(this.activePanels$.getValue().concat([panel]));
			}, 40);

			componentRef.instance['componentIndex'] = this.activeInstances;
			componentRef.instance['destroy'] = this.destroyPanel.bind(this, panel, componentRef);
			componentRef.instance['startClose'] = this.updateActivePanelsOnRemove.bind(this, panel);
			componentRef.onDestroy(() => {
				this.updateActivePanelsOnRemove(panel);
				if (currentFocusedElement && currentFocusedElement['focus']) {
					(<HTMLOrSVGElement>(<unknown>currentFocusedElement)).focus();
				}
			});

			this.panelRef.push(componentRef);

			componentRef$.next(componentRef);
			componentRef$.complete();
			try {
				this.vcRef.injector.get(ChangeDetectorRef).detectChanges();
			} catch (ex) {
				//it's important to catch errors here because otherwise minor errors that
				//occur inside a panel will break the panel service in general, and it
				//will not be able to close/open/navigate to other panels
				this.telemetryService.trackError({
					package: 'Panels',
					error: ex,
				});
			}
		} else {
			this._queuedPanel = {
				component: component,
				settings: settings,
				parameters: parameters,
			};
			this._lastComponentRef$ = componentRef$;
		}

		return <Observable<ComponentRef<t>>>componentRef$.asObservable();
	}

	private destroyPanel<T extends PanelContainer>(panel: Panel, componentRef: ComponentRef<T>) {
		this.activeInstances--;
		this.togglePanelOpenFlag();
		this.activeInstances = Math.max(this.activeInstances, 0);

		// remove panel instance from active instances array
		const idx = this.panelRef.indexOf(componentRef);
		if (idx > -1) {
			this.panelRef.splice(idx, 1);
		}

		this.activeInstances$.next(this.activeInstances);
		componentRef.destroy();
	}

	private updateActivePanelsOnRemove(panel: Panel) {
		const activePanels: Array<Panel> = this.activePanels$.getValue(),
			panelIndex: number = this.activePanels$.getValue().indexOf(panel);

		if (~panelIndex) {
			activePanels.splice(panelIndex, 1);
			this.activePanels$.next(activePanels);
		}
	}

	closePanel(panel: ComponentRef<any>) {
		panel.instance.destroy();
	}

	closeAllPanels() {
		this.closePanels(this.panelRef);
		sccHostService.isSCC && panelService.closeAllPanels(false);
	}

	closeAllModalPanels(leavePersistentPanels: boolean = false) {
		const modalPanels: Array<ComponentRef<PanelContainer>> = this.panelRef.filter(
			(componentRef: ComponentRef<PanelContainer>) =>
				componentRef.instance.settings.isModal &&
				(!leavePersistentPanels || !componentRef.instance.settings.persistOnNavigate)
		);

		this.closePanels(modalPanels);
		sccHostService.isSCC && panelService.closeAllPanels(false);
	}

	closePanelsBy<T>(callback: (panel: T) => boolean) {
		const panelsToClose: Array<ComponentRef<any>> = this.panelRef.filter(panelRef =>
			callback(panelRef.instance)
		);
		return this.closePanels(panelsToClose);
	}

	private closePanels(panels: Array<ComponentRef<any>>) {
		const panelsCopy = [...panels];
		panelsCopy.forEach(this.closePanel);
	}

	private togglePanelOpenFlag() {
		const bodyEl = this.document.getElementsByTagName('body')[0];

		if (this.activeInstances && bodyEl.classList.contains(PANEL_OPEN_CLASS))
			bodyEl.classList.add(PANEL_OPEN_CLASS);
		else bodyEl.classList.remove(PANEL_OPEN_CLASS);
	}

	static getPanelTypeWidth(panelType: PanelType): number {
		switch (panelType) {
			case PanelType.smallFluid:
			case PanelType.smallFixedFar:
				return PANEL_WIDTHS.small;
			case PanelType.medium:
				return PANEL_WIDTHS.medium;
			case PanelType.large:
				return PANEL_WIDTHS.large;
			case PanelType.extraLarge:
				return PANEL_WIDTHS.extraLarge;
			default:
				return 0;
		}
	}

	ngOnDestroy() {
		this.closeAllPanels();
		this.activePanels$.complete();
		this.activeInstances$.complete();
		this._lastComponentRef$ && this._lastComponentRef$.complete();
		this.eventsSub && this.eventsSub.unsubscribe();
	}
}
