import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { TvmOrgSettingsApiCallSharedConfigurations } from '../../orgSettingsEndPointUtils';
import { ConfigurationProfile } from './configuration-profile.value-object';

@ApiCall({
	...TvmOrgSettingsApiCallSharedConfigurations,
    name: 'Get configuration applicable profiles',
	endpoint: (_, query) => `baseline/profiles/configurationProfiles/${query.where['configurationId']}`,
    type: ConfigurationProfile,
})
export class ConfigurationProfilesApiCall extends ApiCallModel<ConfigurationProfile[], string> { }
