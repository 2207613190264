/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tvm-report-inaccuracy-dropdown.component.ngfactory";
import * as i2 from "./tvm-report-inaccuracy-dropdown.component";
import * as i3 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../../services/tvm-report-inaccuracy.service";
import * as i5 from "./tvm-reportable-base-field.component";
var styles_ReportableBaseFieldComponent = [];
var RenderType_ReportableBaseFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ReportableBaseFieldComponent, data: {} });
export { RenderType_ReportableBaseFieldComponent as RenderType_ReportableBaseFieldComponent };
export function View_ReportableBaseFieldComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "wcd-flex-center-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "wcd-flex-1 wcd-text-overflow-ellipsis"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["\n\t\t\t\t", "\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "tvm-report-inaccuracy-dropdown", [], null, null, null, i1.View_TvmReportInaccuracyDropdownComponent_0, i1.RenderType_TvmReportInaccuracyDropdownComponent)), i0.ɵdid(7, 49152, null, 0, i2.TvmReportInaccuracyDropdownComponent, [i3.I18nService, i4.TvmReportInaccuracyService], { contextObject: [0, "contextObject"], inaccuracyContext: [1, "inaccuracyContext"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.contextObject; var currVal_2 = _co.inaccuracyContext; _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); }); }
export function View_ReportableBaseFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "reportable-base-field", [], null, null, null, View_ReportableBaseFieldComponent_0, RenderType_ReportableBaseFieldComponent)), i0.ɵdid(1, 49152, null, 0, i5.ReportableBaseFieldComponent, [], null, null)], null, null); }
var ReportableBaseFieldComponentNgFactory = i0.ɵccf("reportable-base-field", i5.ReportableBaseFieldComponent, View_ReportableBaseFieldComponent_Host_0, { inaccuracyContext: "inaccuracyContext", contextObject: "contextObject", title: "title" }, {}, []);
export { ReportableBaseFieldComponentNgFactory as ReportableBaseFieldComponentNgFactory };
