import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Feature, FeaturesService } from '@wcd/config';
import { AadGroup } from "@wcd/domain";
import { find } from "lodash-es";
import { Repository, Paris, DataSet } from "@microsoft/paris";
import { AuthService } from "@wcd/auth";
import { UserRoleActionsService } from "../../services/user-role-actions.service";

export enum RbacComponentType {
	UserRole = "UserRole",
	DeviceGroup = "DeviceGroup"
}

@Component({
	selector: 'aad-groups-assignment-tab',
	templateUrl: 'aad-groups-assignment-tab.html',
})
export class AadGroupsAssignmentTab implements OnInit {

	@Input() assignedAadGroups: Array<AadGroup>;
	@Input() hostRbacComponentType: RbacComponentType;
	@Input() isGlobalAdmin: boolean;
	@Input() isDisabled = false;
	@Output() applySelectedAadGroups: EventEmitter<Array<AadGroup>> = new EventEmitter<Array<AadGroup>>();

	isUnifiedRbacEnabled = this.featureService.isEnabled(Feature.UnifiedRbac);

	get aadGroupsDescription() {
		return this.isUnifiedRbacEnabled && this.hostRbacComponentType === RbacComponentType.DeviceGroup
			? "machineGroup.edit.aadGroupsDescription"
			: "userRoles.aadGroupsWithRole";
	}

	get globalAdminAccessNote() {
		return this.isUnifiedRbacEnabled && this.hostRbacComponentType === RbacComponentType.DeviceGroup
			? "machineGroup.edit.globalAdminAccess"
			: "userRoles.globalAdminAccess";
	}

	get emptyAssignmentsNote() {
		return this.isUnifiedRbacEnabled && this.hostRbacComponentType === RbacComponentType.DeviceGroup
		? "machineGroup.edit.emptyAssignments"
		: "userRoles.emptyAssignments";
	}

	aadGroupsRepository: Repository<AadGroup>;
	availableAadGroups: Array<AadGroup> = [];
	selectedAadGroups: Array<AadGroup> = [];
	selectedAssignedAadGroups: Array<AadGroup> = [];

	isLoadingAadGroups: boolean = false;
	isAadGroupsError: boolean = false;

	isSaving: boolean = false;
	isError: boolean;

	private aadGroupsFilterResults: Array<AadGroup>;

	constructor(
		private paris: Paris,
		public authService: AuthService,
		public userRoleActionsService: UserRoleActionsService,
		private featureService: FeaturesService
	) {
		this.aadGroupsRepository = paris.getRepository(AadGroup);

	}

	ngOnInit() {
		this.setAvailableAadGroups();
	}

	setAvailableAadGroups(filter: string = '') {
		this.isLoadingAadGroups = true;
		this.isAadGroupsError = false;

		this.aadGroupsRepository.query({ where: { filter } }).subscribe(
			(aadGroups: DataSet<AadGroup>) => {
				this.aadGroupsFilterResults = aadGroups.items;
				this.setAvailableAadGroupsWithoutAssigned();
				this.isLoadingAadGroups = false;
			},
			error => {
				this.isLoadingAadGroups = false;
				this.isAadGroupsError = true;
			}
		);
	}

	addSelectedGroups() {
		this.assignedAadGroups = this.assignedAadGroups.concat(this.selectedAadGroups);
		this.setAvailableAadGroupsWithoutAssigned();
		this.selectedAadGroups = [];
		this.applySelectedAadGroups.emit(this.assignedAadGroups);
	}

	removeSelectedGroups() {
		this.assignedAadGroups = this.assignedAadGroups.filter(
			assignedAadGroup => !~this.selectedAssignedAadGroups.indexOf(assignedAadGroup)
		);
		this.selectedAssignedAadGroups = [];
		this.setAvailableAadGroupsWithoutAssigned();
		this.applySelectedAadGroups.emit(this.assignedAadGroups);
	}

	private setAvailableAadGroupsWithoutAssigned() {
		this.availableAadGroups = this.aadGroupsFilterResults.filter(
			aadGroup => !find(this.assignedAadGroups, assignedAadGroup => aadGroup.id === assignedAadGroup.id)
		);
	}
}
