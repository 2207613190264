import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	EventEmitter,
	Output,
	ChangeDetectorRef,
	OnDestroy,
} from '@angular/core';
import clipboardCopy from 'clipboard-copy';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SpinnerSize } from 'office-ui-fabric-react';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';

export type CopyDialogComponentOptions = {
	/**
	 * Text to copy. Will show loading spinner as long as the value is null.
	 */
	loadableCopyText$: Observable<string>;

	/**
	 * Title to show at the top of the copy-dialog
	 */
	title?: string;

	/**
	 * If provided, will appear above the copied text.
	 * Used to show indication over what was copied (for example 'Link copied')
	 */
	copiedText?: string;

	/**
	 * If provided, appears as an info message at the bottom of the copy dialog with info icon.
	 */
	infoMessage?: string;
};

@Component({
	selector: 'wcd-copy-dialog',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./copy-dialog.component.scss'],
	template: `
		<fab-dialog
			[hidden]="hidden"
			[minWidth]="340"
			[dialogContentProps]="{
				title: options.title
			}"
			(onDismiss)="closeCopyDialog()"
		>
			<div>
				<fab-spinner
					*ngIf="!copyText; else copyContent"
					[size]="SpinnerSize.large"
					contentClass="wcd-margin-xsmall-right wcd-margin-medium-top"
				>
				</fab-spinner>

				<ng-template #copyContent>
					<div class="wcd-flex-vertical wcd-flex-align-items-center wcd-margin-small-bottom">
						<div class="copy-check-mark-circle wcd-margin-small-bottom">
							<fab-icon iconName="checkMark"></fab-icon>
						</div>
						<span>
							{{ copyInfoText }}
						</span>
					</div>

					<div class="wcd-flex-horizontal">
						<div class="wcd-flex-auto">
							<fab-text-field [value]="copyText" [readOnly]="true"></fab-text-field>
						</div>
						<fab-primary-button
							(onClick)="copyValue(copyText)"
							data-track-type="Button"
							[text]="'copy' | i18n"
						>
						</fab-primary-button>
					</div>

					<div *ngIf="options.infoMessage" class="wcd-margin-medium-top wcd-flex-horizontal">
						<fab-icon iconName="info"></fab-icon>
						<span class="wcd-font-size-s wcd-padding-small-left">{{ options.infoMessage }}</span>
					</div>
				</ng-template>
			</div>
		</fab-dialog>
	`,
})
export class CopyDialogComponent implements OnInit, OnDestroy {
	readonly SpinnerSize: typeof SpinnerSize = SpinnerSize;

	@Input() options: CopyDialogComponentOptions;
	@Output() readonly onClosed = new EventEmitter<boolean>(false);

	copyInfoText: string;

	private _copyText: string = null;
	private _hidden: boolean = false;

	get copyText() {
		return this._copyText;
	}

	set copyText(value: string) {
		this._copyText = value;
		if (value) {
			this.changeDetectorRef.markForCheck();
		}
	}

	/**
	 * Gets the hidden state of the dialog.
	 *
	 * @internal
	 * @description used for hiding the dialog as soon as possible in React,
	 * until Angular destroys this component, to give the user a fluent experience.
	 */
	get hidden() {
		return this._hidden;
	}

	set hidden(value: boolean) {
		this._hidden = value;
		this.changeDetectorRef.markForCheck();
	}

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly i18nService: I18nService,
		private liveAnnouncer: LiveAnnouncer,
	) {}

	ngOnDestroy(): void {
		this.hidden = true;
	}

	async ngOnInit(): Promise<void> {
		this.options.loadableCopyText$
			.pipe(first(Boolean)) // take first value that isn't null. showing loader until then.
			.subscribe((value: string) => {
				this.copyText = value;
				this.copyValue(value);
			});

		this.copyInfoText = this.options.copiedText || this.i18nService.get('copy_dialog_copied_text');
	}

	closeCopyDialog() {
		this.onClosed.emit(true);
	}

	async copyValue(value: string): Promise<void> {
		await clipboardCopy(value);
		this.liveAnnouncer.announce(this.copyInfoText);

	}
}
