import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AssetsStatistics, Software } from '@wcd/domain';
import {
	ColorMap,
	EventModel,
	KindTextFormatter,
} from '../../../../../../../shared/components/events-summary-bar/events-summary-bar.component';
import { TvmColor, TvmColorService } from '../../../../../../../tvm/services/tvm-color.service';
import { ReportsService } from '../../../../../../../shared-reports/services/reports.service';
import { ReportWidgetComponent } from '../../../../../../../reports/components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../../../../reports/models/report-widget.model';
import { I18nService } from '@wcd/i18n';
import { ActivatedEntity } from '../../../../../../../global_entities/services/activated-entity.service';
import { map, filter, flatMap } from 'rxjs/operators';
import { ModelBase } from '@microsoft/paris';
import { Observable } from 'rxjs';
import { LegendItem, LineChartOptions, YAxisConfiguration, ChartColor } from '@wcd/charts';
import { TzDateService, LocaleConfigService } from '@wcd/localization';
import {
	TvmChartTooltipComponent,
	ASSETS_STATISTICS_EXPANDED_TOOLTIP_ID,
} from '../../../../../../../tvm/components/tooltips/events/tvm-chart-tooltip.component';
import { TvmTopChangeEventsService } from '../../../../../../../tvm/services/tvm-top-change-events.service';
import { generateDatesForHistoryArray } from '../../../../../../../tvm/tvm-utils';

@Component({
	selector: 'assets-statistics-expanded-widget',
	templateUrl: './assets-statistics-expanded.widget.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./assets-statistics-expanded.widget.scss'],
})
export class AssetsStatisticsExpandedWidget extends ReportWidgetComponent<AssetsStatistics> {
	private static exposedColor = TvmColor.HighRisk;
	private static installedColor = TvmColor.GraphSeriesLight;
	readonly textFormatter: KindTextFormatter<string>;

	totalAssetAtRisk: number;
	totalAsset: number;
	newChartOptions: LineChartOptions<TvmChartTooltipComponent>;

	events$: Observable<ReadonlyArray<EventModel<TvmColor>>> = this.data$.pipe(
		map(data => {
			if (!data) {
				return null;
			}

			this.totalAssetAtRisk = data.assetsAtRiskCount;
			this.totalAsset = data.totalAssetCount;

			return [
				{ kind: AssetsStatisticsExpandedWidget.exposedColor, count: data.assetsAtRiskCount },
				{
					kind: AssetsStatisticsExpandedWidget.installedColor,
					count: data.totalAssetCount - data.assetsAtRiskCount,
				},
			];
		})
	);
	_kindColorMap: ColorMap<TvmColor>;

	constructor(
		reportsService: ReportsService,
		private tvmColorService: TvmColorService,
		private i18nService: I18nService,
		private topChangeEventsService: TvmTopChangeEventsService,
		private tzDateService: TzDateService,
		private localeConfigService: LocaleConfigService,
		private readonly activatedEntity: ActivatedEntity
	) {
		super(reportsService);

		this._kindColorMap = {
			type: 'class',
			map: tvmColorService.backgroundColorsClassMap,
		};

		this.textFormatter = text => {
			if (text === AssetsStatisticsExpandedWidget.exposedColor) {
				return this.i18nService.get(
					'tvm.softwarePage.report.widgets.exposedDevices.title.data.exposedLegend'
				);
			}
			return this.i18nService.get(
				'tvm.softwarePage.report.widgets.exposedDevices.title.data.installedLegend'
			);
		};
	}

	get widgetConfig(): ReportWidgetConfig<AssetsStatistics> {
		return {
			id: 'misconfigurationsDistributionWidget',
			name: this.i18nService.get('tvm.softwarePage.report.widgets.exposedDevices.title.initial'),
			loadData: () => this.getAssetsStatisticsObservable(),
		};
	}

	legends: Array<LegendItem> = [
		{
			name: this.i18nService.get(
				'tvm.softwarePage.report.widgets.exposedDevices.title.data.exposedLegend'
			),
			iconColor: this.tvmColorService.colorsMap.get(AssetsStatisticsExpandedWidget.exposedColor).raw,
		},
		{
			name: this.i18nService.get(
				'tvm.softwarePage.report.widgets.exposedDevices.title.data.installedLegend'
			),
			iconColor: this.tvmColorService.colorsMap.get(AssetsStatisticsExpandedWidget.installedColor).raw,
		},
	];

	private getAssetsStatisticsObservable(): Observable<AssetsStatistics> {
		return this.activatedEntity.currentEntity$.pipe(
			filter((entity: ModelBase) => {
				return entity instanceof Software;
			}),
			flatMap((software: Software) => {
				const softwareChangeEvents$ = this.topChangeEventsService.getChangeEventsForSoftware$(
					software.id
				);
				const assetsStatistics = software.assetsStatistics;
				return softwareChangeEvents$.pipe(
					map(softwareChangeEvents => {
						this.setNewChartValues(assetsStatistics, softwareChangeEvents || new Map());
						return assetsStatistics;
					})
				);
			})
		);
	}

	private setNewChartValues(data: AssetsStatistics, vaEvents: Map<string, string[]>): void {
		if (!data) {
			this.newChartOptions = null;
			return;
		}

		const historyDataPoints = generateDatesForHistoryArray(
			data.assetsAtRiskHistory,
			this.localeConfigService.isLocalTimeZone
		);

		const maxYValue = Math.max(...data.assetsAtRiskHistory);
		const minYValue = Math.min(...data.assetsAtRiskHistory);

		this.newChartOptions = {
			data: historyDataPoints.map(point => ({
				...point,
				events: vaEvents.get(this.tzDateService.format(point.date, 'MM/dd')),
			})),
			legend: this.i18nService.strings.tvm_common_exposedDevices,
			yAxisConfiguration: this.getYAxisConfig(maxYValue, minYValue),
			yAxisTickValuesWidth: this.getYAxisTickValuesWidth(maxYValue),
			color: ChartColor.Red,
			height: 150,
			tooltipComponent: TvmChartTooltipComponent,
			tooltipId: ASSETS_STATISTICS_EXPANDED_TOOLTIP_ID,
		};
	}

	private getYAxisTickValuesWidth(maxYValue: number): number {
		const numOfDigits = maxYValue.toString().length;
		const baseWidthForThreeDigits = 35;
		const widthPerDigit = 5;
		return baseWidthForThreeDigits + Math.max(0, (numOfDigits - 3) * widthPerDigit);
	}

	/**
	 * Creates Y Axis based on max / min.
	 * We define max and min, closer to the trend, but not exactly on it, so it looks good
	 */
	private getYAxisConfig(maxYValue: number, minYValue: number): YAxisConfiguration {
		const maxValue = maxYValue + 1;

		// To make sure the graph looks good
		// We create Max and Min that the trend doesn't touch
		// Example Max value 1500 -> 100 *5
		// Then we take the 500 and found nearest for 1500 -> New Max = 2000
		// For min value 800 we found nearest 500 -> New min = 500
		const pow = maxValue.toString().length - 2;
		const closerRounding = Math.pow(10, pow > 0 ? pow : 0) * 5;

		const max = Math.ceil(maxValue / closerRounding) * closerRounding;
		const min = Math.floor(minYValue / closerRounding) * closerRounding;

		const ticks: number[] = [];
		for (let i = 0; i < 3; i++) {
			ticks.push(Math.round(min + ((max - min) * i) / 2));
		}

		return {
			max: max,
			min: min,
			ticks: {
				values: ticks,
			},
		};
	}
}
