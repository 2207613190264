var _a;
import { ElementRef, EventEmitter, OnDestroy, OnInit, QueryList, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Paris } from '@microsoft/paris';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { CustomTiIndicator, CustomTiIndicatorActionsType, CustomTiIndicatorActionsTypes, CustomTiIndicatorIdentifierType, CustomTiIndicatorIdentifierTypes, CustomTiIndicatorSeverityType, CustomTiIndicatorsType, CustomTiIndicatorsTypes, File, FileSearchCriterion, Ip, MachineGroup, Url, } from '@wcd/domain';
import { CheckboxComponent } from '@wcd/forms';
import { I18nService } from '@wcd/i18n';
import { MessageBarType } from 'office-ui-fabric-react';
import { combineLatest, of } from 'rxjs';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { RegExpService } from '@wcd/shared';
import { MachinesService } from '../../machines/services/machines.service';
import { CustomTiIndicatorComponentService, MAX_BYPASSDURATION, ScopeTypesEnum, } from '../services/custom-ti-indicator-component.service';
import { CustomTiIndicatorsService } from '../services/custom-ti-indicators.service';
import { AppFlavorConfig } from '@wcd/scc-common';
export var Tabs;
(function (Tabs) {
    Tabs["details"] = "details";
    Tabs["action"] = "action";
    Tabs["groups"] = "groups";
    Tabs["review"] = "review";
})(Tabs || (Tabs = {}));
var MAX_CERTIFICATE_FILE_SIZE_IN_BYTES = 10 * 1024 * 1024;
var TRUSTED_FILES_REASONS = [
    'FileCertificateMicrosoftRoot',
    'FileCertificateNonMsTrustedSigner',
];
var INDICATOR_DOC_URLS = (_a = {},
    _a[CustomTiIndicatorsTypes.Files] = 'https://go.microsoft.com/fwlink/?linkid=2132509',
    _a[CustomTiIndicatorsTypes.Ip] = 'https://go.microsoft.com/fwlink/?linkid=2132343',
    _a[CustomTiIndicatorsTypes.Url] = 'https://go.microsoft.com/fwlink/?linkid=2132343',
    _a[CustomTiIndicatorsTypes.Certificate] = 'https://go.microsoft.com/fwlink/?linkid=2132510',
    _a);
var CustomTiIndicatorNewComponent = /** @class */ (function () {
    function CustomTiIndicatorNewComponent(paris, i18nService, featuresService, machinesService, dialogsService, entityPanelsService, customTiIndicatorsService, advancedFeaturesService, indicatorMachineComponentService, flavorService) {
        var _this = this;
        this.paris = paris;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.machinesService = machinesService;
        this.dialogsService = dialogsService;
        this.entityPanelsService = entityPanelsService;
        this.customTiIndicatorsService = customTiIndicatorsService;
        this.advancedFeaturesService = advancedFeaturesService;
        this.indicatorMachineComponentService = indicatorMachineComponentService;
        this.flavorService = flavorService;
        this.MessageBarType = MessageBarType;
        this.RegExpService = RegExpService;
        this.MAX_BYPASSDURATION = MAX_BYPASSDURATION;
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
        this.Tabs = Tabs;
        this.CustomTiIndicatorsTypes = CustomTiIndicatorsTypes;
        this.ScopeTypesEnum = ScopeTypesEnum;
        this.CustomTiIndicatorActionsTypes = CustomTiIndicatorActionsTypes;
        this.isScopeVisible = this.flavorService.isEnabled(AppFlavorConfig.settings.indicators);
        this.tabs = [
            {
                id: Tabs.details,
                name: this.i18nService.get('customTiIndicator.detailsSidePane.tabs.details.title'),
            },
            {
                id: Tabs.action,
                name: this.i18nService.get('customTiIndicator.detailsSidePane.tabs.action.title'),
                disabled: true,
            }
        ].concat((this.isScopeVisible
            ? [
                {
                    id: Tabs.groups,
                    name: this.i18nService.get('customTiIndicator.detailsSidePane.tabs.scope.title'),
                    disabled: true,
                },
            ]
            : []), [
            {
                id: Tabs.review,
                name: this.i18nService.get('customTiIndicator.detailsSidePane.tabs.summary.title'),
                disabled: true,
            },
        ]).map(function (tab) { return new TabModel(tab); });
        this.expirationOptions = [
            {
                id: 0,
                name: this.i18nService.get('customTiIndicator.dataview.entity.fields.expiresOn.options.never'),
            },
            {
                id: 1,
                name: this.i18nService.get('customTiIndicator.dataview.entity.fields.expiresOn.options.custom'),
            },
        ];
        this.currentTabIndex = 0;
        this.currentTab = this.tabs[this.currentTabIndex];
        this.currentExpirationOption = this.expirationOptions[0];
        this.isSaving = false;
        this.asKeyValueList = true;
        this.loadingImpact = false;
        this.showNoImpactFoundMessage = false;
        this.showInvalidFileSizeMessage = false;
        this.showMd5IsNotRecommendedWarning = false;
        this.onExpirationOptionsChange = function (newOption) {
            var expirationDate = _this._getInitialExpirationDate();
            _this.customTiIndicator.expirationTime =
                newOption === _this.expirationOptions[1] ? expirationDate : null;
        };
        this.onMachineGroupChange = function (newOption) {
            _this.indicatorMachineComponentService.allowSpecificMachineGroups =
                newOption ===
                    _this.indicatorMachineComponentService.selectableMachineGroupScopes[ScopeTypesEnum.specific];
            if (!_this.indicatorMachineComponentService.allowSpecificMachineGroups) {
                _this.indicatorMachineComponentService.setCurrentMachineGroups((_this.customTiIndicator.rbacGroupIds = _this.indicatorMachineComponentService.currentMachineGroups = []));
            }
        };
        this.onActionOptionsChange = function (newOption) {
            _this.indicatorMachineComponentService.onActionOptionsChange(newOption, _this.customTiIndicator, _this.customTiIndicatorType, _this.generateAlertCheckbox);
        };
        this.onGenerateAlertChange = function (shouldGenenerateAlert) {
            _this.indicatorMachineComponentService.setGenerateAlertChange(shouldGenenerateAlert, _this.customTiIndicator);
        };
        this.onHistoricalDetectionChange = function (isHistoricalDetection) {
            _this.customTiIndicator.historicalDetection = isHistoricalDetection;
            _this.customTiIndicator.lookBackPeriod = 30;
        };
        this.getLookBackPeriodLabel = function (days) {
            return _this.i18nService.get("customTiIndicator.dataview.entity.fields.lookBackPeriod." + days);
        };
        this.onNavButtonPressed = function (isNext) {
            if (isNext) {
                _this.currentTabIndex++;
                while (!_this.tabs[_this.currentTabIndex].isVisible && _this.currentTabIndex < _this.tabs.length) {
                    _this.currentTabIndex++;
                }
                _this.tabs[_this.currentTabIndex].disabled = false;
            }
            else {
                _this.currentTab.disabled = true;
                _this.currentTabIndex--;
                while (!_this.tabs[_this.currentTabIndex].isVisible && _this.currentTabIndex >= 0) {
                    _this.currentTabIndex--;
                }
            }
            _this.currentTab = _this.tabs[_this.currentTabIndex];
            _this.focusOnFirstFocusable();
        };
        this.onTabChanged = function (newTab) {
            _this.currentTab.disabled = true;
            _this.currentTab = newTab;
            _this.currentTabIndex = _this.tabs.findIndex(function (tabModel) { return tabModel.id === _this.currentTab.id; });
        };
        this.showEntityPanel = function (entityConstructor, ob) {
            return ob.subscribe(function (indicator) {
                _this.loadingImpact = false;
                if (!indicator || !indicator.id) {
                    _this.showNoImpactFoundMessage = true;
                    return;
                }
                _this.entityPanelsService.showEntityById(entityConstructor, indicator.id);
            }, function (error) {
                _this.showNoImpactFoundMessage = true;
                _this.loadingImpact = false;
            });
        };
        this.getImpactPreview = function () {
            var _a;
            _this.loadingImpact = true;
            switch (_this.customTiIndicatorType.id) {
                case CustomTiIndicatorsTypes.Files: {
                    _this._impactSubscription = _this.showEntityPanel(File, _this._filesRepo.getItemById(_this.customTiIndicator.tiIndicator, undefined, (_a = {},
                        _a[File.fileSearchByParamName] = _this._fileSearchByParam,
                        _a['newFilesApi'] = _this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW),
                        _a)));
                    break;
                }
                case CustomTiIndicatorsTypes.Ip: {
                    _this._impactSubscription = _this.showEntityPanel(Ip, _this._ipRepo.getItemById(_this.customTiIndicator.tiIndicator));
                    break;
                }
                case CustomTiIndicatorsTypes.Url: {
                    _this._impactSubscription = _this.showEntityPanel(Url, _this._urlRepo.getItemById(_this.customTiIndicator.tiIndicator));
                    break;
                }
            }
        };
        this.onIndicatorChange = function () {
            _this.showNoImpactFoundMessage = false;
            _this.isCustomTiIndicatorValidData = _this.isCustomTiIndicatorValid();
        };
        this.saveRule = function () {
            _this.isSaving = true;
            _this.indicatorMachineComponentService.resetRedundantFields(_this.customTiIndicator);
            _this._saveCustomTiSubscription = _this._customTiIndicatorRepo.save(_this.customTiIndicator).subscribe(function () {
                _this.isSaving = false;
                // set timeout is used because the "add indicator" button gets focused
                // immediately after the panel is closed and message is not read by the narrator.
                setTimeout(function () {
                    _this.dialogsService.showSuccessSnackbar({
                        text: _this.i18nService.get('customTiIndicator.detailsSidePane.indicatorSaved'),
                    });
                });
                _this.save.emit(_this.customTiIndicator);
            }, function (error) {
                _this.isSaving = false;
                var errorMessageOptions = {
                    title: _this.i18nService.get('customTiIndicator.dataview.actions.add.errorMessages.generalError.title'),
                    data: error,
                };
                if (error.status === 403 && error.message && error.message.Message) {
                    var message = TRUSTED_FILES_REASONS.find(function (reason) { return reason === error.message.Message; });
                    if (message) {
                        var publisher = _this.i18nService.get("customTiIndicator.dataview.actions.add.errorMessages.trustedFiles.publishers." + (message === TRUSTED_FILES_REASONS[0] ? 'microsoft' : 'trusted'));
                        errorMessageOptions = {
                            title: _this.i18nService.get('customTiIndicator.dataview.actions.add.errorMessages.trustedFiles.title'),
                            data: _this.i18nService.get('customTiIndicator.dataview.actions.add.errorMessages.trustedFiles.description', { publisher: publisher }),
                        };
                    }
                }
                _this.dialogsService.showError(errorMessageOptions);
            });
        };
        this._machineGroupsRepo = paris.getRepository(MachineGroup);
        this._customTiIndicatorRepo = paris.getRepository(CustomTiIndicator);
        this._filesRepo = paris.getRepository(File);
        this._ipRepo = paris.getRepository(Ip);
        this._urlRepo = paris.getRepository(Url);
        this._rbacMachineGroupsEnabled = this.featuresService.isEnabled(Feature.RbacMachineGroups);
        this._blockIpAndDomainEnabled = this.featuresService.isEnabled(Feature.BlockIpAndDomain);
        this.tvmApplicationBlockEnabled = this.featuresService.isEnabled(Feature.TVMApplicationBlock);
        this.historicalDetectionEnabled = this.featuresService.isEnabled(Feature.HistoricalDetection);
        this.mitreTechniquesEnabled = this.featuresService.isEnabled(Feature.IndicatorMitreTechniques);
        this.allowBlockOnlyForUrlIndicator = this.featuresService.isEnabled(Feature.AllowBlockOnlyForUrlIndicator);
        this.xplatIndicatorEnabled = this.featuresService.isEnabled(Feature.XplatIndicators);
        this.indicatorMachineComponentService.getFormatSeverityLabel = this.indicatorMachineComponentService.getFormatSeverityLabel.bind(this); // this function is called from a child component and requires this component's context
        this.indicatorMachineComponentService.getFormatCategoryLabel = this.indicatorMachineComponentService.getFormatCategoryLabel.bind(this); // this function is called from a child component and requires this component's context
    }
    Object.defineProperty(CustomTiIndicatorNewComponent.prototype, "machineGroupsFieldId", {
        get: function () {
            return 'custom-ti-machine-groups';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicatorNewComponent.prototype, "showImpactSection", {
        get: function () {
            return (this.flavorService.isEnabled(AppFlavorConfig.settings.customTI) &&
                (this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Files ||
                    this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Ip ||
                    this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Url));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicatorNewComponent.prototype, "customTiIcon", {
        get: function () {
            return this.customTiIndicatorsService.getIcon(this.customTiIndicatorType.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicatorNewComponent.prototype, "localExpiration", {
        get: function () {
            if (this.lastTiExpiration != this.customTiIndicator.expirationTime) {
                var value = this.customTiIndicator.expirationTime;
                var utcDateAsLocal = moment([
                    value.getUTCFullYear(),
                    value.getUTCMonth(),
                    value.getUTCDate(),
                    value.getUTCHours(),
                    value.getUTCMinutes(),
                    value.getUTCSeconds(),
                ])
                    .utcOffset(new Date().getTimezoneOffset() / 60, true)
                    .toDate();
                this.lastTiExpiration = this.customTiIndicator.expirationTime;
                this.lastLocalExpiration = utcDateAsLocal;
            }
            return this.lastLocalExpiration;
        },
        set: function (d) {
            // Reinterpret full datetime from picker as UTC day (without time)
            this.customTiIndicator.expirationTime = moment([d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0])
                .utcOffset(0, true)
                .toDate();
        },
        enumerable: true,
        configurable: true
    });
    CustomTiIndicatorNewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isValidData = false;
        this.customTiIndicator = this._customTiIndicatorRepo.createNewItem();
        if (!!this.indicatorValue) {
            this.customTiIndicator.tiIndicator = this.indicatorValue;
        }
        this.isCustomTiIndicatorValidData = this.isCustomTiIndicatorValid();
        this.severityList = this.paris.getRepository(CustomTiIndicatorSeverityType).entity.values;
        this.setCustomTi();
        this._setEarliestDateAllowed();
        var advancedFeaturesObservable = this._blockIpAndDomainEnabled
            ? this.advancedFeaturesService.getAdvancedFeaturesSettings()
            : of(null);
        var machineGroupsObservable = this._rbacMachineGroupsEnabled
            ? this.indicatorMachineComponentService.setMachineGroups(this.customTiIndicator)
            : of(null);
        combineLatest(advancedFeaturesObservable, machineGroupsObservable).subscribe(function (_a) {
            var advancedFeatures = _a[0];
            _this.indicatorMachineComponentService.loadingMachineGroups = false;
            if (_this._rbacMachineGroupsEnabled) {
                _this.indicatorMachineComponentService.setCurrentMachineGroups(_this.customTiIndicator.rbacGroupIds);
            }
            else {
                _this._setTabVisibility(Tabs.groups, false);
            }
            _this._setActionInfoMessage(advancedFeatures);
            _this.indicatorMachineComponentService.loadingMachineGroups = false;
        }, function (error) {
            _this.indicatorMachineComponentService.loadingMachineGroups = false;
        });
        this.indicatorDocUrl = INDICATOR_DOC_URLS[this.customTiIndicatorType.id];
        this.indicatorMachineComponentService.init(this.customTiIndicator, this.customTiIndicatorType);
    };
    CustomTiIndicatorNewComponent.prototype.ngOnDestroy = function () {
        this._impactSubscription && this._impactSubscription.unsubscribe();
        this._onMachineGroupsChangedSubscription && this._onMachineGroupsChangedSubscription.unsubscribe();
        this._saveCustomTiSubscription && this._saveCustomTiSubscription.unsubscribe();
        this.indicatorMachineComponentService.destroy();
    };
    CustomTiIndicatorNewComponent.prototype.onCategoryChanged = function ($event) {
        this.indicatorMachineComponentService.onCategoryChanged($event, this.customTiIndicator);
    };
    CustomTiIndicatorNewComponent.prototype.getMitreTechniqueDropDownPlaceHolder = function () {
        return this.indicatorMachineComponentService.getMitreTechniqueDropDownPlaceHolder(this.customTiIndicator);
    };
    CustomTiIndicatorNewComponent.prototype.focusOnFirstFocusable = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.focusable.first instanceof ElementRef) {
                _this.focusable.first.nativeElement.focus();
            }
            else if (_this.focusable.first && _this.focusable.first.setFocus) {
                _this.focusable.first.setFocus();
            }
        }, 0);
    };
    CustomTiIndicatorNewComponent.prototype.setCustomTi = function () {
        this.customTiIndicator.isEnabled = true;
        this.indicatorMachineComponentService.currentMachineGroupScope = this.indicatorMachineComponentService.selectableMachineGroupScopes[ScopeTypesEnum.all];
        this.customTiIndicator.action = this.paris.getValue(CustomTiIndicatorActionsType, CustomTiIndicatorActionsTypes.Allow);
        this.customTiIndicator.generateAlert = false;
        this.setEntityPanelDetails();
    };
    CustomTiIndicatorNewComponent.prototype.onMachineGroupsChange = function (selectedMachineGroups) {
        var _this = this;
        if (!selectedMachineGroups || !selectedMachineGroups.length)
            this.indicatorMachineComponentService.setCurrentMachineGroups((this.customTiIndicator.rbacGroupIds = []));
        else {
            this._onMachineGroupsChangedSubscription = combineLatest(selectedMachineGroups.map(function (selectedGroup) {
                return _this._machineGroupsRepo.getItemById(selectedGroup.id);
            })).subscribe(function (machineGroups) {
                var machineGroupIds = machineGroups.map(function (group) { return group.id; });
                _this.indicatorMachineComponentService.setCurrentMachineGroups((_this.customTiIndicator.rbacGroupIds = machineGroupIds));
            });
        }
    };
    CustomTiIndicatorNewComponent.prototype.isValid = function () {
        switch (this.currentTab.id) {
            case Tabs.details: {
                this.isValidData = this.isCustomTiIndicatorValidData;
                break;
            }
            case Tabs.action: {
                this.isValidData = this.isAlertDetailsValid() && this.isWarnDetailsValid();
                break;
            }
            case Tabs.groups: {
                this.isValidData = this.isGroupsValid();
                break;
            }
            case Tabs.review: {
                this.isValidData =
                    this.isCustomTiIndicatorValidData &&
                        this.isAlertDetailsValid() &&
                        this.isWarnDetailsValid() &&
                        this.isGroupsValid();
                break;
            }
            default:
                this.isValidData = false;
        }
        return this.isValidData;
    };
    CustomTiIndicatorNewComponent.prototype.isCustomTiIndicatorValid = function () {
        if (this.customTiIndicatorType.id !== CustomTiIndicatorsTypes.Certificate &&
            !this.customTiIndicator.tiIndicator) {
            return false;
        }
        switch (this.customTiIndicatorType.id) {
            case CustomTiIndicatorsTypes.Files: {
                this.showMd5IsNotRecommendedWarning = false;
                if (RegExpService.sha256.test(this.customTiIndicator.tiIndicator)) {
                    this.customTiIndicator.tiIndicatorType = this.paris.getValue(CustomTiIndicatorIdentifierType, CustomTiIndicatorIdentifierTypes.FileSha256);
                    this._fileSearchByParam = FileSearchCriterion.Sha256;
                    return true;
                }
                else if (RegExpService.sha1.test(this.customTiIndicator.tiIndicator)) {
                    this.customTiIndicator.tiIndicatorType = this.paris.getValue(CustomTiIndicatorIdentifierType, CustomTiIndicatorIdentifierTypes.FileSha1);
                    this._fileSearchByParam = FileSearchCriterion.Sha1;
                    return true;
                }
                else if (RegExpService.md5.test(this.customTiIndicator.tiIndicator)) {
                    this.customTiIndicator.tiIndicatorType = this.paris.getValue(CustomTiIndicatorIdentifierType, CustomTiIndicatorIdentifierTypes.FileMd5);
                    this._fileSearchByParam = FileSearchCriterion.Md5;
                    this.showMd5IsNotRecommendedWarning = true;
                    return true;
                }
                this.customTiIndicatorForm.form.controls['custom-ti-indicator-value'].setErrors({
                    wrongformat: true,
                });
                return false;
            }
            case CustomTiIndicatorsTypes.Ip: {
                this.customTiIndicator.tiIndicatorType = this.paris.getValue(CustomTiIndicatorIdentifierType, CustomTiIndicatorIdentifierTypes.IpAddress);
                return RegExpService.ip.test(this.customTiIndicator.tiIndicator);
            }
            case CustomTiIndicatorsTypes.Url: {
                var isValidUrl = this.indicatorMachineComponentService.isValidUrl(this.customTiIndicator.tiIndicator);
                if (isValidUrl) {
                    this.customTiIndicator.tiIndicatorType = this.paris.getValue(CustomTiIndicatorIdentifierType, CustomTiIndicatorIdentifierTypes.Url);
                    return true;
                }
                if (RegExpService.domain.test(this.customTiIndicator.tiIndicator)) {
                    this.customTiIndicator.tiIndicatorType = this.paris.getValue(CustomTiIndicatorIdentifierType, CustomTiIndicatorIdentifierTypes.DomainName);
                    return true;
                }
                return false;
            }
            case CustomTiIndicatorsTypes.Certificate: {
                this.customTiIndicator.tiIndicatorType = this.paris.getValue(CustomTiIndicatorIdentifierType, CustomTiIndicatorIdentifierTypes.CertificateThumbprint);
                return !!this.customTiIndicator.file;
            }
        }
        return false;
    };
    CustomTiIndicatorNewComponent.prototype.isAlertDetailsValid = function () {
        if (this.isNullOrEmpty(this.customTiIndicator.description) ||
            this.isNullOrEmpty(this.customTiIndicator.title)) {
            return false;
        }
        if (this.indicatorMachineComponentService.currentActionOption &&
            this.indicatorMachineComponentService.currentActionOption.id ===
                CustomTiIndicatorActionsTypes.Allow) {
            return true;
        }
        if (this.tvmApplicationBlockEnabled) {
            return ((this.customTiIndicator.generateAlert && !!this.customTiIndicator.severity) ||
                !this.customTiIndicator.generateAlert);
        }
        else {
            return (this.indicatorMachineComponentService.currentActionOption &&
                (this.indicatorMachineComponentService.currentActionOption.id ===
                    CustomTiIndicatorActionsTypes.Warn ||
                    this.indicatorMachineComponentService.currentActionOption.id ===
                        CustomTiIndicatorActionsTypes.Block ||
                    !!this.customTiIndicator.severity));
        }
    };
    CustomTiIndicatorNewComponent.prototype.isWarnDetailsValid = function () {
        return this.indicatorMachineComponentService.isWarnDetailsValid(this.customTiIndicator);
    };
    CustomTiIndicatorNewComponent.prototype.isGroupsValid = function () {
        return ((this.indicatorMachineComponentService.currentMachineGroupScope &&
            this.indicatorMachineComponentService.currentMachineGroupScope.id === ScopeTypesEnum.all) ||
            !!this.customTiIndicator.rbacGroupIds.length);
    };
    CustomTiIndicatorNewComponent.prototype.onFileSelect = function (event, customTiIndicatorForm) {
        var files = event.srcElement.files;
        var file = files && files[0];
        if (!!file) {
            if (file.size > MAX_CERTIFICATE_FILE_SIZE_IN_BYTES) {
                this.showInvalidFileSizeMessage = true;
                this.certificateInput.nativeElement.value = null;
                this.customTiIndicator.file = null;
            }
            else {
                this.showInvalidFileSizeMessage = false;
                this.customTiIndicator.file = file;
                customTiIndicatorForm.form.markAsDirty();
            }
        }
        this.isCustomTiIndicatorValidData = this.isCustomTiIndicatorValid();
    };
    CustomTiIndicatorNewComponent.prototype.setEntityPanelDetails = function () {
        this.indicatorMachineComponentService.setIsAlertable(this.customTiIndicator);
        var entityName = '';
        switch (this.customTiIndicatorType.id) {
            case CustomTiIndicatorsTypes.Files: {
                entityName = this.i18nService
                    .get('customTiIndicator.dataview.entity.names.file.singularName')
                    .toLowerCase();
                break;
            }
            case CustomTiIndicatorsTypes.Ip: {
                entityName = this.i18nService.get('customTiIndicator.dataview.entity.names.ip.singularName');
                break;
            }
            case CustomTiIndicatorsTypes.Url: {
                entityName = this.i18nService.get('customTiIndicator.dataview.entity.names.url.singularName') + "/" + this.i18nService.get('customTiIndicator.dataview.entity.names.domain.singularName');
                break;
            }
            case CustomTiIndicatorsTypes.Certificate: {
                entityName = this.i18nService
                    .get('customTiIndicator.dataview.entity.names.certificate.singularName')
                    .toLowerCase();
                break;
            }
        }
        this.entitySingularName = entityName;
        this.entitySingularNameCapitalized =
            entityName.length > 0 ? entityName.charAt(0).toUpperCase() + entityName.slice(1) : entityName;
        this.title = this.i18nService.get('customTiIndicator.dataview.actions.add.title', {
            entityName: entityName,
        });
    };
    CustomTiIndicatorNewComponent.prototype.isNullOrEmpty = function (value) {
        return !value || value.trim() === '';
    };
    CustomTiIndicatorNewComponent.prototype._getInitialExpirationDate = function () {
        var expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        expirationDate.setHours(0, 0, 0, 0);
        return expirationDate;
    };
    CustomTiIndicatorNewComponent.prototype._setEarliestDateAllowed = function () {
        this.earliestDateAllowed = new Date();
        this.earliestDateAllowed.setDate(this.earliestDateAllowed.getDate() + 1);
        this.earliestDateAllowed.setHours(0, 0, 0, 0);
    };
    CustomTiIndicatorNewComponent.prototype._setTabVisibility = function (tabId, showTab) {
        var machineGroupTab = this.tabs.find(function (tab) { return tab.id == tabId; });
        machineGroupTab.show = function () { return showTab; };
    };
    Object.defineProperty(CustomTiIndicatorNewComponent.prototype, "actionsVisible", {
        get: function () {
            return (this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Files ||
                this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Ip ||
                this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Url);
        },
        enumerable: true,
        configurable: true
    });
    CustomTiIndicatorNewComponent.prototype._setActionInfoMessage = function (advancedFeatures) {
        switch (this.customTiIndicatorType.id) {
            case CustomTiIndicatorsTypes.Ip:
            case CustomTiIndicatorsTypes.Url: {
                if (!this._blockIpAndDomainEnabled) {
                    this.warningHelpKey =
                        'customTiIndicator.dataview.entity.fields.action.values.alertandblock.info.network.notExposed';
                }
                else if (!advancedFeatures || !advancedFeatures.allowWdavNetworkBlock) {
                    this.warningHelpKey =
                        'customTiIndicator.dataview.entity.fields.action.values.alertandblock.info.network.turnedOff';
                }
                else {
                    this.warningHelpKey = null;
                }
                break;
            }
            default: {
                this.warningHelpKey = null;
            }
        }
    };
    Object.defineProperty(CustomTiIndicatorNewComponent.prototype, "isGenerateAlertDisabled", {
        get: function () {
            return (this.indicatorMachineComponentService.currentActionOption.id ===
                CustomTiIndicatorActionsTypes.Audit ||
                this.indicatorMachineComponentService.currentActionOption.id ===
                    CustomTiIndicatorActionsTypes.Allow);
        },
        enumerable: true,
        configurable: true
    });
    return CustomTiIndicatorNewComponent;
}());
export { CustomTiIndicatorNewComponent };
