import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { ContentState } from '@wcd/contents-state';
import { PrettyNumberService } from '@wcd/prettify';
import { isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { finalize, shareReplay, takeUntil } from 'rxjs/operators';
import {
	AlertsSeveritySummary,
	File,
	FileActiveAlertsSummaryRelationship,
	FileFileStatsRelationship,
	FileStats,
	FileVirusTotalFileReportRelationship,
	VirusTotalFileReport,
} from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import {
	buildSeveritiesMap,
	SeveritiesCountMap,
} from '../../../../shared/components/severity-summary/severity-summary.component';
import { Feature, FeaturesService } from '@wcd/config';
import { ValueWithExternalLinkContext } from '../../../../shared/components/value-with-external-link.component';
import { VirusTotalFileReportService } from '../../services/virus-total-file-report.service';
import { I18nService } from '@wcd/i18n';

interface PrevalenceSectionData {
	readonly count: number;
	readonly firstSeen: Date;
	readonly lastSeen: Date;
}

@Component({
	selector: 'file-entity-panel',
	templateUrl: './file.entity-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileEntityPanelComponent extends EntityPanelComponentBase<File> implements OnDestroy {
	readonly dataAsset = this.i18nService.get('help_externalLoadError_data_asset');
	constructor(
		changeDetectorRef: ChangeDetectorRef,
		private readonly paris: Paris,
		private readonly prettyNumberService: PrettyNumberService,
		private readonly featuresService: FeaturesService,
		private readonly virusTotalFileReportService: VirusTotalFileReportService,
		private readonly i18nService: I18nService
	) {
		super(changeDetectorRef);
		this.isFileVerdictEnabled = this.featuresService.isEnabled(Feature.FileVerdict);
	}

	alertsSeveritiesCountMap: SeveritiesCountMap;
	worldwideFileStats: PrevalenceSectionData;
	organizationFileStats: PrevalenceSectionData;
	virusTotalFileReport: VirusTotalFileReport;
	isFileVerdictEnabled: boolean;

	alertsSeveritySummaryContentState = ContentState.Loading;
	fileWorldwideStatsContentState = ContentState.Loading;
	fileOrganizationStatsContentState = ContentState.Loading;
	virusTotalContentState = ContentState.Loading;

	private _destroy$ = new Subject<boolean>();

	get file(): File {
		return this.entity;
	}

	get windowsDefenderAvContext(): ValueWithExternalLinkContext | null {
		const { prevalentThreatName } = this.entity;
		if (!prevalentThreatName) {
			return null;
		}

		return {
			value: prevalentThreatName,
			icon: 'encyclopedia',
			verticalAlign: 'top',
			link: `http://go.microsoft.com/fwlink/?LinkID=142185&Name=${prevalentThreatName}`,
			title: this.i18nService.get(
				'files.entityDetails.sections.detections.fields.windowsDefenderAv.title'
			),
		};
	}

	get virusTotalContext(): ValueWithExternalLinkContext | null {
		if (!this.virusTotalFileReport) {
			return null;
		}
		return this.virusTotalFileReportService.toValueWithExternalLinkContext(this.virusTotalFileReport);
	}

	setEntity(entity: File, isExtendedData: boolean = false): void {
		super.setEntity(entity, isExtendedData);

		if (this.file.id) {
			this.setActiveAlertsSummary();
			this.setVirusTotal();
			this.setFileStats();
		}
	}

	ngOnDestroy() {
		this._destroy$.next(true);
		this._destroy$.unsubscribe();
	}

	setFileStats() {
		this.fileWorldwideStatsContentState = ContentState.Loading;
		this.fileOrganizationStatsContentState = ContentState.Loading;
		this.changeDetectorRef.markForCheck();

		const fileStats$ = this.paris
			.getRelatedItem<File, FileStats>(
				FileFileStatsRelationship,
				this.entity,
				{ where: { filesPrefix: this.featuresService.isEnabled('K8SMigration-EPSFilePrevalence-kw') } })
			.pipe(shareReplay(1));

		fileStats$
			.pipe(
				finalize(() => this.changeDetectorRef.markForCheck()),
				takeUntil(this._destroy$)
			)
			.subscribe(
				fileStats => {
					this.worldwideFileStats = {
						count: fileStats.worldwidePrevalence,
						firstSeen: fileStats.worldwideFirstSeen,
						lastSeen: fileStats.worldwideLastSeen,
					};
				},
				() => (this.fileWorldwideStatsContentState = ContentState.Error),
				() =>
					(this.fileWorldwideStatsContentState =
						this.worldwideFileStats &&
						(this.worldwideFileStats.firstSeen ||
						this.worldwideFileStats.lastSeen ||
						!isNil(this.worldwideFileStats.count)
							? ContentState.Complete
							: ContentState.Empty))
			);

		fileStats$
			.pipe(
				finalize(() => this.changeDetectorRef.markForCheck()),
				takeUntil(this._destroy$)
			)
			.subscribe(
				fileStats =>
					(this.organizationFileStats = {
						count: fileStats.organizationPrevalence,
						lastSeen: fileStats.organizationLastSeen,
						firstSeen: fileStats.organizationFirstSeen,
					}),
				() => (this.fileOrganizationStatsContentState = ContentState.Error),
				() =>
					(this.fileOrganizationStatsContentState =
						this.organizationFileStats &&
						(this.organizationFileStats.firstSeen ||
						this.organizationFileStats.lastSeen ||
						!isNil(this.organizationFileStats.count)
							? ContentState.Complete
							: ContentState.Empty))
			);
	}

	setVirusTotal() {
		this.virusTotalContentState = ContentState.Loading;
		this.changeDetectorRef.markForCheck();

		this.paris
			.getRelatedItem<File, VirusTotalFileReport>(FileVirusTotalFileReportRelationship, this.entity)
			.pipe(
				finalize(() => this.changeDetectorRef.markForCheck()),
				takeUntil(this._destroy$)
			)
			.subscribe(
				virusTotalFileReport => (this.virusTotalFileReport = virusTotalFileReport),
				() => (this.virusTotalContentState = ContentState.Error),
				() =>
					(this.virusTotalContentState = this.virusTotalFileReportService.hasVirusTotalData(
						this.virusTotalFileReport
					)
						? ContentState.Complete
						: ContentState.Empty)
			);
	}

	setActiveAlertsSummary() {
		this.alertsSeveritySummaryContentState = ContentState.Loading;
		this.changeDetectorRef.markForCheck();

		this.paris
			.getRelatedItem<File, AlertsSeveritySummary>(FileActiveAlertsSummaryRelationship, this.entity)
			.pipe(
				finalize(() => this.changeDetectorRef.markForCheck()),
				takeUntil(this._destroy$)
			)
			.subscribe(
				alertsSeveritySummary =>
					(this.alertsSeveritiesCountMap = buildSeveritiesMap(alertsSeveritySummary)),
				() => (this.alertsSeveritySummaryContentState = ContentState.Error),
				() =>
					(this.alertsSeveritySummaryContentState = this.alertsSeveritiesCountMap
						? ContentState.Complete
						: ContentState.Empty)
			);
	}
}
