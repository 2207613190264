import { catchError } from 'rxjs/operators';
import { toObservable } from './utils';
import { OperatorFunction, throwError } from 'rxjs';

export type HttpStatusCode = number;

export function catchHttpError<T, R>(
	statusCode: HttpStatusCode,
	defaultValue: R
): OperatorFunction<T, T | R> {
	return catchError(err => {
		return err && err.status === statusCode ? toObservable(defaultValue) : throwError(err);
	});
}
