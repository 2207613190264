import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { VulnerabilityNotificationRule, SeverityValue } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { VulnerabilityNotificationRulesService } from '../services/vulnerability-notification-rules.service';
import { ExternalRuleActions } from '../services/vulnerability-notification-rule-entity-type.service';
import { MachinesService } from '../../../machines/services/machines.service';
import { TvmNotificationsTextsService } from '../../../../tvm/services/tvm-notifications-texts.service';
import { AppConfigService } from '@wcd/app-config';

@Component({
	selector: 'vulnerability-notification-rule-panel',
	styleUrls: ['./vulnerability-notification-rule.panel.component.scss'],
	templateUrl: './vulnerability-notification-rule.panel.component.html',
})
export class VulnerabilityNotificationRulePanelComponent
	extends EntityPanelComponentBase<VulnerabilityNotificationRule>
	implements OnInit {
	notificationRule: VulnerabilityNotificationRule;
	lastModifiedDate: Date;
	lastModifiedBy: string;
	eventTypeTitle: string;
	severityThresholdTitle: string;
	recipientsTitle: string;
	isEnabled: boolean;
	isSaving: boolean = false;
	isUserAllowedActions: boolean;
	isUserCanEditRule: boolean;
	deviceGroupDisplay: string[];
	ruleScope: string;
	deviceGroupLabel: string;
	severityLevelForNewCve: SeverityValue;
	tenantHasMachineGroups: boolean;

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		private i18n: I18nService,
		private vulnerabilityNotificationRulesService: VulnerabilityNotificationRulesService,
		private machinesService: MachinesService,
		private notificationTextsService: TvmNotificationsTextsService,
		appConfigService: AppConfigService
	) {
		super(changeDetectorRef);
		this.isUserAllowedActions = vulnerabilityNotificationRulesService.isUserAllowedActions();
		this.tenantHasMachineGroups = appConfigService.hasMachineGroups;
	}

	ngOnInit() {
		this.notificationRule = this.entity;
		this.machinesService.getFullUserExposedMachineGroups().subscribe(groups => {
			this.isUserCanEditRule = this.vulnerabilityNotificationRulesService.isUserCanEditRule(
				this.notificationRule,
				groups
			);
		});
		this.isEnabled = this.notificationRule.isEnabled;
		this.lastModifiedDate = this.notificationRule.lastModifiedBy
			? this.notificationRule.lastModifiedOn
			: this.notificationRule.createdOn;
		this.lastModifiedBy = this.notificationRule.lastModifiedBy
			? this.notificationRule.lastModifiedBy.email
			: this.notificationRule.createdBy.email;
		this.recipientsTitle = this.i18n.get('email.notification.recipientsTable.title', {
			count: this.notificationRule.recipients.length,
		});
		this.eventTypeTitle = this.notificationRule.notificationRuleArgsCollection
			.map(args => this.notificationTextsService.getChangeEventTypeLabel(args.eventType))
			.join(', ');
		this.severityLevelForNewCve = this.vulnerabilityNotificationRulesService.getSeverityThreshold(
			this.notificationRule
		);
		this.severityThresholdTitle = this.severityLevelForNewCve
			? this.i18n.get(
					`tvm_notifications_vulnerability_${this.vulnerabilityNotificationRulesService.getSeverityThreshold(
						this.notificationRule
					)}`
			  )
			: undefined;
		if (!this.notificationRule.isGlobalNotification) {
			this.updateDeviceGroupsDisplay();
			this.deviceGroupLabel = this.i18n.get('email.notification.deviceGroupsTable.title', {
				count: this.deviceGroupDisplay.length,
			});
		}
		this.ruleScope = this.notificationTextsService.getRuleScope(this.notificationRule);
		this.changeDetectorRef.markForCheck();
	}

	updateDeviceGroupsDisplay() {
		this.deviceGroupDisplay = this.notificationRule.rbacGroupIds.map(machineGroup =>
			machineGroup && machineGroup.isUnassignedMachineGroup
				? this.i18n.strings.machineGroup_unassignedGroup_name
				: machineGroup.name
		);
	}

	updateStatus() {
		this.isSaving = true;
		super.runEntityAction(ExternalRuleActions.UPDATE_STATUS_ACTION);
	}
}
