
		<!-- entity context details -->
		<section *ngIf="entityContext" class="wcd-margin-vertical wcd-margin-large-horizontal">
			<entity-details-section-header
				*ngIf="entityContext.nameKey"
				[titleKey]="entityContext.nameKey"
			></entity-details-section-header>
			<global-entity-details
				[entity]="entityContext.item"
				[entityType]="entityContext.type"
				[showEntityPanelLink]="false"
			></global-entity-details>
		</section>

		<!-- main entity details -->
		<ng-container #mainEntityDetailsPlaceholder></ng-container>
	