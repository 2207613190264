import { ChangeDetectionStrategy, Component } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, filter, switchMap, take, tap } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import {
	CountVulnerabilitiesBySeverityApiCall,
	CountMisconfigurationsBySeverityApiCall,
	WeaknessesCountBySeverity,
	Software,
	EolState,
} from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ReportsService } from '../../../../../../../shared-reports/services/reports.service';
import { ReportWidgetConfig } from '../../../../../../../reports/models/report-widget.model';
import { ReportWidgetComponent } from '../../../../../../../reports/components/report-widget.component.base';
import { ActivatedEntity } from '../../../../../../../global_entities/services/activated-entity.service';
import { SeverityDistributionMap } from '../../../../../../../tvm/modules/tvm-severity-distribution/tvm-severity-distribution.component';
import { PrettyNumberService } from '@wcd/prettify';
import { SoftwareCommonService } from '../../../../services/software.common.service';
import { TvmTextsService, TextToken } from '../../../../../../../tvm/services/tvm-texts.service';

interface WeaknessesDistributionData {
	totalCount: number;
	vulnerabilities: {
		totalCount: number;
		severityDistribution$: Observable<SeverityDistributionMap>;
		isEolSoftware: boolean;
	};
	misconfigurations: {
		totalCount: number;
		severityDistribution$: Observable<SeverityDistributionMap>;
	};
}

@Component({
	selector: 'weaknesses-distribution-widget',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './weaknesses-distribution.widget.html',
})
export class WeaknessesDistributionWidget extends ReportWidgetComponent<WeaknessesDistributionData> {
	vulnerabilitiesCountDisplayText: string;
	misconfigurationsCountDisplayText: string;
	widgetEosTooltip: string;

	constructor(
		reportsService: ReportsService,
		private readonly paris: Paris,
		private readonly activatedEntity: ActivatedEntity,
		private readonly i18nService: I18nService,
		private readonly prettyNumberService: PrettyNumberService,
		private readonly softwareCommonService: SoftwareCommonService,
		private readonly tvmTextsService: TvmTextsService
	) {
		super(reportsService);
		this.widgetEosTooltip = this.i18nService.strings.tvm_securityRecommendation_eosTooltip;
	}

	get widgetConfig(): ReportWidgetConfig<WeaknessesDistributionData> {
		return {
			id: 'weaknessesDistributionWidget',
			name: this.i18nService.get('tvm.softwarePage.report.widgets.weaknesses.title.initial'),
			getName: data => {
				if (!data)
					return this.i18nService.get('tvm.softwarePage.report.widgets.weaknesses.title.initial');
				const weaknessesCount = data.totalCount;
				return weaknessesCount === 1
					? this.i18nService.get('tvm.softwarePage.report.widgets.weaknesses.title.data.singular')
					: this.i18nService.get('tvm.softwarePage.report.widgets.weaknesses.title.data.plural', {
							count: this.prettyNumberService.prettyNumber(weaknessesCount),
					  });
			},
			loadData: () =>
				this.activatedEntity.currentEntity$.pipe(
					filter(currentEntity => currentEntity instanceof Software),
					switchMap((software: Software) => {
						const productName = this.tvmTextsService.getText(
							TextToken.SoftwareNameFromId,
							software.id
						);
						const isEolSoftware = software.eolSoftwareState === EolState.AlreadyEOL;
						const vulnerabilitiesCountBySeverity$ = this.paris
							.apiCall<WeaknessesCountBySeverity[]>(CountVulnerabilitiesBySeverityApiCall, {
								product: this.softwareCommonService.getDecapitalizedString(productName),
								vendor: this.softwareCommonService.getDecapitalizedString(software.vendor),
							})
							.pipe(take(1));

						const misconfigurationsCountBySeverity$ = this.paris
							.apiCall<WeaknessesCountBySeverity[]>(CountMisconfigurationsBySeverityApiCall, {
								product: this.softwareCommonService.getDecapitalizedString(productName),
								vendor: this.softwareCommonService.getDecapitalizedString(software.vendor),
							})
							.pipe(take(1));

						return forkJoin(
							vulnerabilitiesCountBySeverity$,
							misconfigurationsCountBySeverity$
						).pipe(
							map(([vulnerabilitiesCountBySeverity, misconfigurationsCountBySeverity]) => {
								const vulnerabilitiesCount = vulnerabilitiesCountBySeverity.reduce(
									(acc, curr) => acc + curr.weaknesses,
									0
								);
								const misconfigurationsCount = misconfigurationsCountBySeverity.reduce(
									(acc, curr) => acc + curr.weaknesses,
									0
								);
								const weaknessesCount = vulnerabilitiesCount + misconfigurationsCount;
								return {
									totalCount: weaknessesCount,
									vulnerabilities: {
										totalCount: vulnerabilitiesCount,
										severityDistribution$: of(
											this.softwareCommonService.generateSeveritiesDistributionData(
												vulnerabilitiesCountBySeverity
											)
										),
										isEolSoftware: isEolSoftware,
									},
									misconfigurations: {
										totalCount: misconfigurationsCount,
										severityDistribution$: of(
											this.softwareCommonService.generateSeveritiesDistributionData(
												misconfigurationsCountBySeverity
											)
										),
									},
								};
							})
						);
					}),
					tap(weaknessesDistributionData => {
						this.vulnerabilitiesCountDisplayText = this.tvmTextsService.getText(
							TextToken.VulnerabilitiesCount,
							weaknessesDistributionData.vulnerabilities.totalCount
						);
						this.misconfigurationsCountDisplayText = this.tvmTextsService.getText(
							TextToken.MisconfigurationsCount,
							weaknessesDistributionData.misconfigurations.totalCount
						);
						return weaknessesDistributionData;
					})
				),
		};
	}
}
