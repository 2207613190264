<div *ngIf="softwareVersion" class="tvm wcd-scroll-vertical">
	<div *ngIf="showEolMessage" class="tvm-list__block_item">
		<fab-message-bar
			[messageBarType]="messageBarType"
			[isMultiline]="false"
			[truncated]="shouldShowLegalNote"
			[overflowButtonAriaLabel]="i18nService.strings.entityCommon_panel_impactedEntities_showMore"
			[styles]="getStyles.bind(this)">
			<div class="spaced">{{eolText}}</div>
			<div class="wcd-margin-top" *ngIf="messageBarState.showBody" >{{legalNote}}<ng-container *ngIf="showLegalNoteLink"><a [href]="legalNoteLink">{{legalNoteLinkText}} </a></ng-container></div>
		</fab-message-bar>
	</div>
	<entity-panel-collapsible-section [title]="'tvm.versionDetails.title' | i18n" [collapsed] = false>
			<div class="tvm-list__key_val_item">
				<dt>{{ i18nService.strings.tvm_versionDetails_installedOn }}</dt>
				<dd>{{installations}}</dd>
			</div>

			<div class="tvm-list__key_val_item" *ngIf="shouldShowOsDistribution">
				<dt>{{ i18nService.strings.tvm_common_osDistribution }}</dt>
				<dd>{{getOsDistributionString()}}</dd>
			</div>

			<div class="tvm-list__key_val_item">
				<dt>{{ i18nService.strings.tvm_versionDetails_discoveredVulnerabilities }}
					<wcd-help [text]="widgetEosTooltip" *ngIf="isEolVersion"></wcd-help>
				</dt>
				<dd>{{softwareVersion.discoveredVulnerabilities}}</dd>
			</div>

			<div class="tvm-list__key_val_item" *ngIf="showEolDate">
				<dt>{{ i18nService.strings.tvm_versionDetails_eolVersionSinceDate }}</dt>
				<dd>{{softwareVersion.eolVersionSinceDate | date:'shortDate'}}</dd>
			</div>

			<ng-template #blankEntry>
				<dd>-</dd>
			</ng-template>
	</entity-panel-collapsible-section>
	<ng-container *ngIf="isExposedDeviceFeatureEnabled">
		<installed-machines-details
			[installations$]="installations$"
			[title]="installedAssetsTitle"
			[loadingText]="installedAssetsLoadingText"
			[includeOperatingSystem]="isExposedOperatingSystem"
			(showMore)=openAllInstalledMachines()
			(export)="exportInstalledMachines()">
		</installed-machines-details>
	</ng-container>
</div>
