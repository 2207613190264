import { SecurityOperationsDashboardComponent } from './components/security-operations.dashboard.component';
import { Routes } from '@angular/router';
import { AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard } from '@wcd/auth';
import { BreadcrumbsResolver } from '../breadcrumbs/services/breadcrumbs-resolver.service';
import { MdeUserRoleActionEnum } from '@wcd/domain';
var ɵ0 = {
    breadcrumbsConfig: {
        itemConfig: {
            id: "securityOperationsDashboard",
            labelKey: 'securityOperations_title',
            icon: 'dashboard',
            url: '/dashboard',
        },
        resetListOnEnter: true,
        show: false,
    },
    pageTitle: 'Security operations dashboard',
    pageTitleIcon: 'dashboard',
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
};
var dashboardsRoutes = [
    {
        path: 'dashboard',
        canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
        component: SecurityOperationsDashboardComponent,
        resolve: {
            breadcrumbs: BreadcrumbsResolver,
        },
        data: ɵ0,
    },
];
var declarations = [SecurityOperationsDashboardComponent];
var DashboardsModule = /** @class */ (function () {
    function DashboardsModule() {
    }
    return DashboardsModule;
}());
export { DashboardsModule };
export { ɵ0 };
