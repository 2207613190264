import { Input, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RelationshipRepository, ModelBase, Paris, Entity } from '@microsoft/paris';
import {
	RecommendationContextKeyAndExposedMachinesCount,
	SecurityRecommendation,
	RecommendationContextType,
	TvmEndPoint,
	RecommendationContextKeyPanelType,
} from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { EntityType } from '../../models/entity-type.interface';
import { FeaturesService, Feature } from '@wcd/config';
import { TvmTextsService } from '../../../tvm/services/tvm-texts.service';
import { DataviewField, DataViewConfig } from '@wcd/dataview';
import { RecommendationContextFieldsService } from '../../../@entities/@tvm/security-recommendations/services/recommendation-context.fields.service';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';

@Component({
	selector: 'recommendation-context-by-key-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './recommendation-context-key.panel.html',
})
export class RecommendationContextKeyPanelComponent<T extends ModelBase> extends PanelContainer
	implements OnInit {
	private _recommendationContextType: RecommendationContextType;

	entityType: EntityType<any>;
	isExportEnabled: boolean;
	loadingMessageText: string;
	fields: Array<DataviewField<any>>;
	dataViewConfig: DataViewConfig;

	@Input() panelType: RecommendationContextKeyPanelType;
	@Input() repository: RelationshipRepository<T, any>;
	@Input() securityRecommendation: SecurityRecommendation;
	@Input() recommendationCtxKeyTitle: string;
	@Input() panelSubTitle: string;
	@Input()
	set recommendationContextType(recommendationContextType: RecommendationContextType) {
		this._recommendationContextType = recommendationContextType;
		this.loadingMessageText = this.tvmTextsService.recommendationContextToExposedSectionLoadingTitle[
			recommendationContextType
		];
	}

	constructor(
		private sidePanelService: SidePanelService,
		private tvmTextsService: TvmTextsService,
		private tvmDownloadService: TvmDownloadService,
		protected paris: Paris,
		private recommendationContextFieldsService: RecommendationContextFieldsService,
		router: Router,
		featuresService: FeaturesService,
		globalEntityTypesService: GlobalEntityTypesService
	) {
		super(router);
		this.entityType = globalEntityTypesService.getEntityType(
			RecommendationContextKeyAndExposedMachinesCount
		);
		this.isExportEnabled = featuresService.isEnabled(Feature.TvmExportCsvFromSidePanel);
	}

	ngOnInit() {
		this.fields =
			this.panelType === RecommendationContextKeyPanelType.EXPOSED_MACHINES_COUNT
				? this.recommendationContextFieldsService.getExposedMachinesCountPerRecommendationContextKeyFields(
						this._recommendationContextType
				  )
				: this.recommendationContextFieldsService.getAssetRecommendationContextFields(
						this._recommendationContextType
				  );

		const allFieldsIds = this.fields.map(field => field.id);
		this.dataViewConfig = {
			//must supply unique id for correct dataview caching purposes - unique id is based on the fields
			id: `recommendationContextDetails(${allFieldsIds.join()})`,
		};
	}

	openRecommendationContextItemPanel(contextKeyEntity: RecommendationContextKeyAndExposedMachinesCount) {
		this.sidePanelService.showRecommendationContextPropertiesPanel(
			this.securityRecommendation,
			contextKeyEntity.contextKey,
			this._recommendationContextType
		);
	}

	exportCveData() {
		this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.repository,
			TvmEndPoint.Analytics,
			`${this.recommendationCtxKeyTitle} - ${this.panelSubTitle}`
		);
	}
}
