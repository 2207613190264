import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { AssessmentJob } from '@wcd/domain';

@Component({
	selector: 'assessment-job-edit-panel',
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings" class="wcd-full-height">
			<assessment-job-edit [assessmentJob]="assessmentJob" [readOnly]="readOnly" (close)="closePanel()">
			</assessment-job-edit>
		</wcd-panel>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentJobEditPanelComponent extends PanelContainer {
	@Input() assessmentJob?: AssessmentJob;
	@Input() readOnly?: boolean;

	constructor(router: Router) {
		super(router);
	}

	closePanel() {
		this.startClose();
		setTimeout(() => this.destroy(), 200);
	}
}
