<wcd-panel (close)="destroy()" [settings]="settings"
	class="wcd-full-height wcd-flex-vertical wcd-flex-1"
	data-track-component="exposed-assets-panel"
	data-track-component-type="Side Panel">
	<div class="wcd-flex-horizontal wcd-padding-large-horizontal">
		<h4 class="wcd-flex-1 wcd-padding-bottom side-panel-title">
			{{ 'tvm.common.exposedDevices' | i18n }}
		</h4>
		<button
			class="tvm-show-more-csv-export-button"
			*ngIf="isExportEnabled"
			(click)="exportCveData()"
			[wcdTooltip]="exportTooltipText || 'Export data to CSV'">
			<wcd-shared-icon iconName="Download"> </wcd-shared-icon>
			<span>{{ 'export' | i18n }}</span>
		</button>
	</div>
	<dataview class="wcd-flex-1"
		*ngIf="sourceValue"
		[giveSearchBoxMoreSpace]="true"
		[entityType]="entityType"
		[dataViewConfig]="dataViewConfig"
		[repository]="_repository"
		[navigateOnChange]="false"
		[allowPaging]="true"
		[allowFilters]="false"
		[searchEnabled]="true"
		[ignoreQueryParams]="true"
		[allowColumnCustomization]="false"
		[fields]="fields"
		[disableSelection]="true"
		[nestedComponentType]="nestedComponent"
		[hasNestedContent]="showNestedComponent"
		[isItemGroup]="showNestedComponent"
		[allowGroupItems]="true"
		[getGroupItems]="getGroupItems">
	</dataview>
</wcd-panel>
