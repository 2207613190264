<!-- tslint:disable:template-click-events-have-key-events -->
<div class="element--border element clickable selectable--outer non-tree-element"
	 role="presentation"
	 [class.clickable]="user.id"
	 [class.clickable--with-constant-shadow]="user.id"
	 [class.clickable--with-radius]="user.id"
	 [class.selected]="alertPageService.selectedTreeElements[user.id]">
	<div class="tree-element--title--box selectable--inner element--centered"
		 [class.selected]="alertPageService.selectedTreeElements[user.id]"
		 (click)="alertPageService.updateDetailsTab(userDetailsTabType, user, user.id)"
		 (keydown.enter)="alertPageService.updateDetailsTab(userDetailsTabType, user, user.id)"
		 tabindex="0"
		 [attr.aria-label]="user.fullName + ('alert.accessibility.user.details.label' | i18n)"
		 role="listitem"
		 [attr.aria-current]="alertPageService.selectedTreeElements[user.id]"
		 data-track-type="Button"
		 data-track-id="user-tile"
		 [attr.data-track-value]="user.id">
		<div class="wcd-flex-vertical wcd-flex-1">
			<div class="wcd-flex-horizontal	element element--centered">
				<span class="wcd-flex-horizontal wcd-flex-1 wcd-padding-small-top">
					<!-- User title -->
					<fab-icon [iconName]="userIcon"></fab-icon>

					<span class="wcd-font-size-m
								wcd-text-overflow-ellipsis-nowrap
								wcd-text-overflow-ellipsis
								wcd-font-weight-bold
								wcd-flex-1
								wcd-padding-small-left"
						[wcdTooltip]="user.fullName">
						{{user.fullName}}
					</span>
				</span>

				<!-- User actions -->
				<entity-command-bar [entityType]="userType"
									[entity]="user"
									[entities]="[user]"
									[overFlowActionsView]="true"
									[entityCommandBarDisplayMode]="entityCommandBarDisplayMode.Overflow">
				</entity-command-bar>
			</div>

			<div class="wcd-padding-right alert-page-tags"
				 (click)="alertPageService.updateDetailsTab(userDetailsTabType, user, user.id)">
				<ng-container *ngIf="userProfile && userProfile.title">
					<tags-list [tags]="[userProfile.title]"></tags-list>
				</ng-container>
			</div>
		</div>
	</div>
</div>
