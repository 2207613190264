export enum RemediationTicketStateValue {
	Approved = 'Approved',
	Completed = 'Completed',
	PendingApproval = 'PendingApproval',
	Rejected = 'Rejected',
	Unknown = 'Unknown',
	CompletedTask = 'CompletedTask',
	Active = 'Active',
	Creating = 'Creating',
	FailedToCreate = 'FailedToCreate',
}
