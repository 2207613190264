import { FabButtonModule, FabSpinnerModule } from '@angular-react/fabric';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WcdIconsModule } from '@wcd/icons';
import { ConfirmModalContentComponent } from '../confirm/confirm-modal-content.component';
import { ModalPlaceholderComponent } from './components/modal-placeholder.component';
import { ModalComponent } from './components/modal.component';
import { ModalService } from './services/modal.service';
import { I18nModule } from '@wcd/i18n';
import { HexaditeFormsModule } from '../../forms/forms.module';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		CommonModule,
		I18nModule,
		WcdIconsModule,
		FabButtonModule,
		FabSpinnerModule,
		HexaditeFormsModule,
		A11yModule,
	],
	declarations: [ModalPlaceholderComponent, ModalComponent, ConfirmModalContentComponent],
	exports: [ModalPlaceholderComponent, ModalComponent, ConfirmModalContentComponent],
	providers: [ModalService],
	entryComponents: [ModalPlaceholderComponent, ConfirmModalContentComponent],
})
export class ModalsModule {}
