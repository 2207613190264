import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError, from, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { AuthBackendService } from '../services/auth.backend.service';
import { isUndefined } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';
import { APC_HEADER_KEY, tenantContextCache } from '@wcd/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private injector: Injector) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const authService = this.injector.get(AuthService); // can't get it in constructor because it creates circular dependency

		let authReq: HttpRequest<any> = req;
		if (isUndefined(authReq.withCredentials)) authReq = authReq.clone({ withCredentials: true });

		if (authService.token)
			authReq = authReq.clone({
				setHeaders: { authorization: `Bearer ${authService.token}` },
			});
		if (authService.tenantId)
			authReq = authReq.clone({ setHeaders: { 'Tenant-Id': authService.tenantId } });
		if (authService.apcHeader)
			authReq = authReq.clone({ setHeaders: { [APC_HEADER_KEY]: authService.apcHeader } });

		const intercept = !sccHostService.isSCC
			? of(authReq)
			: from(
					sccHostService.auth.getToken().then(token => {
						return authReq.clone({
							setHeaders: {
								authorization: `Bearer ${token}`,
							},
						})
					})
			  );

		return intercept.pipe(
			switchMap(modifiedReq => {
				const newReq = req.clone(modifiedReq);
				return next.handle(newReq);
			}),
			tap((res: HttpResponse<any>) => {
				const apc: string = res.headers && res.headers.get(APC_HEADER_KEY);
				if (apc) authService.apcHeader = apc;
			}),
			catchError((res: HttpResponse<any>) => {
				if (res.status === 401) {
					const authBackendService = this.injector.get(AuthBackendService),
						doNotCheckAuthUrls: Array<string> = [
							authBackendService.loginUrl,
							authBackendService.authValidationUrl,
						];
					authService.handleAuthError(
						doNotCheckAuthUrls.every(url => url.toLowerCase() !== authReq.url.toLowerCase())
					);
				}

				return throwError(res);
			})
		);
	}
}
