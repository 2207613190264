import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { HasExposureScore } from '../common/exposure-score/has-exposure-score.interface';

@ValueObject({
	singularName: 'Org exposure score',
	pluralName: 'Org exposure score',
})
export class OrgExposureScoreModel extends ModelBase implements HasExposureScore {
	@EntityField({
		required: true,
		data: 'risk',
	})
	exposureScore: number;

	@EntityField({
		required: true,
		data: 'riskHistory',
	})
	exposureScoreHistory: number[];
}
