
		<wcd-panel (close)="destroy()" [settings]="settings">
			<div
				class="wcd-full-height wcd-flex-justify-content-space-between wcd-flex-vertical wcd-padding-large-horizontal wcd-scroll-vertical"
			>
				<div>
					<label class="wcd-font-weight-semibold wcd-font-size-xxl wcd-margin-bottom">
						{{ 'evaluation.lab.setupPanel.simulatorAgentStep.' + this.consentTitle | i18n }}
					</label>
					<fab-message-bar [messageBarType]="MessageBarType.warning">
						<markdown
							[data]="
								'evaluation.lab.setupPanel.simulatorAgentStep.consentForm.notification' | i18n
							"
						>
						</markdown>
					</fab-message-bar>
					<div class="wcd-margin-largeMedium-vertical">
						<markdown
							[data]="
								'evaluation.lab.setupPanel.simulatorAgentStep.' + this.consentContent | i18n
							"
						>
						</markdown>
					</div>
				</div>
				<div class="wcd-padding-top wcd-flex-horizontal">
					<fab-primary-button
						contentClass="wcd-margin-small-horizontal wcd-margin-none-all"
						[text]="
							'evaluation_lab_setupPanel_simulatorAgentStep_consentForm_accept_button' | i18n
						"
						(onClick)="onConsentAccepted()"
					></fab-primary-button>
				</div>
			</div>
		</wcd-panel>
	