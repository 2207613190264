<!-- tslint:disable:template-accessibility-elements-content -->
<wcd-panel (close)="destroy()" [settings]="settings">
		<fab-message-bar *ngIf="userExposedToNewDataExportPage">
			<markdown>
				{{ 'endpointManagement.nonWindows.applications.dataExport.goToNewPageMarkdown' | i18n}}
			</markdown>
		</fab-message-bar>
		<div class="wcd-full-height large-form">
        <header class="wcd-flex-none wcd-padding-horizontal">
			<markdown class="wcd-margin-top-small" [data]="'endpointManagement.nonWindows.applications.dataExport.addNew.description' | i18n"></markdown>
		</header>
		<form #newDataExportForm="ngForm" (submit)="saveDataExport()" (keydown.enter)="$event.preventDefault()" class="wcd-full-height wcd-flex-vertical">
            <div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
                <h3 class="side-panel-title"></h3>
                <section class="wcd-padding-bottom form-group">
						<label for="data-export-name">{{ 'endpointManagement.nonWindows.applications.dataExport.addNew.exportName' | i18n }}</label>
						<input
							type="text"
							id="data-export-name"
							[(ngModel)]="name"
							name="data-export-name"
							class="form-control"
							maxlength="100"
							required
							[attr.aria-invalid]="(clickedSave && !name)"
						/>
						<div class="error-message"
							role="alert"
							*ngIf="(clickedSave && !name)"
							[attr.aria-label]="('endpointManagement.nonWindows.applications.dataExport.addNew.exportName' | i18n) + ': ' + ('endpointManagement.nonWindows.applications.dataExport.addNew.validations.invalidValue' | i18n)"
							>
							{{ 'endpointManagement.nonWindows.applications.dataExport.addNew.validations.invalidValue' | i18n }}

						</div>
                </section>
                <section class="form-group">
					<wcd-checkbox
						[(ngModel)]="sendToStorage"
						name="sendToStorage" class="wcd-margin-large-top">
						{{ 'endpointManagement.nonWindows.applications.dataExport.addNew.forwardToStorage' | i18n }}
					</wcd-checkbox>
					<ng-container *ngIf="sendToStorage">
						<div class="form-group">
							<label for="data-export-storageAccountId" class="wcd-margin-small-top">{{ 'endpointManagement.nonWindows.applications.dataExport.addNew.storageAccountId' | i18n }}</label>
							<input
								type="text"
								id="data-export-storageAccountId"
								[(ngModel)]="storageAccountId"
								name="data-export-storageAccountId"
								class="form-control wcd-margin-small-top"
								maxlength="1000"
								required
								[attr.aria-invalid]="(clickedSave && !storageAccountId)"
								aria-describedby="data-export-storageAccountId-hint"
							/>
							<span id="data-export-storageAccountId-hint" class="hint">/subscriptions/{{ "{" }}subscription id}/resourceGroups/{{ "{" }}resource group}/providers/Microsoft.Storage/storageaccounts/{{ "{" }}storage account}</span>
							<div class="error-message"
								role="alert"
								[attr.aria-label]="('endpointManagement.nonWindows.applications.dataExport.addNew.storageAccountId' | i18n) + ': ' + ('endpointManagement.nonWindows.applications.dataExport.addNew.validations.invalidValue' | i18n)"
								*ngIf="(clickedSave && !storageAccountId)">
								{{ 'endpointManagement.nonWindows.applications.dataExport.addNew.validations.invalidValue' | i18n }}
							</div>
						</div>
					</ng-container>
					<div class="wcd-margin-medium-top">
						<wcd-checkbox
							[(ngModel)]="sendToEventHub"
							class="wcd-margin-medium-top"
							name="sendToEventHub">
							{{ 'endpointManagement.nonWindows.applications.dataExport.addNew.forwardToEventHub' | i18n }}
						</wcd-checkbox>
					</div>
					<div class="wcd-margin-large-bottom">
						<ng-container *ngIf="sendToEventHub">
							<div class="form-group">
								<label for="data-export-eventHubAuthorizationRuleId" class="wcd-margin-small-top">{{ 'endpointManagement.nonWindows.applications.dataExport.addNew.eventHubRuleId' | i18n }}</label>
								<input
									type="text"
									id="data-export-eventHubAuthorizationRuleId"
									[(ngModel)]="eventHubResourceId"
									name="data-export-eventHubAuthorizationRuleId"
									class="form-control wcd-margin-small-top"
									maxlength="1000"
									required
									[attr.aria-invalid]="(clickedSave && !eventHubResourceId)"
									aria-describedby="data-export-eventHubAuthorizationRuleId-hint"
								/>
								<span id="data-export-eventHubAuthorizationRuleId-hint" class="hint">/subscriptions/{{ "{" }}subscription id}/resourceGroups/{{ "{" }}resource group}/providers/Microsoft.EventHub/namespaces/{{ "{" }}event hub namespace}</span>
								<div *ngIf="(clickedSave && !eventHubResourceId)"
									class="error-message"
									role="alert"
									[attr.aria-label]="('endpointManagement.nonWindows.applications.dataExport.addNew.eventHubRuleId' | i18n ) + ': ' + ( 'endpointManagement.nonWindows.applications.dataExport.addNew.validations.invalidValue' | i18n)"
								>
									{{ 'endpointManagement.nonWindows.applications.dataExport.addNew.validations.invalidValue' | i18n }}
								</div>
								<label for="data-export-eventHubAuthorizationRuleId-name" class="wcd-margin-medium-top">{{ 'endpointManagement.nonWindows.applications.dataExport.details.eventHubName' | i18n }}</label>
								<input
									type="text"
									id="data-export-eventHubAuthorizationRuleId-name"
									[(ngModel)]="eventHubName"
									name="data-export-eventHubAuthorizationRuleIdName"
									class="form-control wcd-margin-small-top"
									maxlength="100"
									required
									[attr.aria-invalid]="(clickedSave && !eventHubName)"
								/>
								<div class="error-message"
									*ngIf="(clickedSave && !eventHubName)"
									role="alert"
									[attr.aria-label]="('endpointManagement.nonWindows.applications.dataExport.details.eventHubName' | i18n) + ': ' + ('endpointManagement.nonWindows.applications.dataExport.addNew.validations.invalidValue' | i18n)"
								>
									{{ 'endpointManagement.nonWindows.applications.dataExport.addNew.validations.invalidValue' | i18n }}
								</div>
							</div>
						</ng-container>
						<div class="error-message"
							role="alert"
							*ngIf="(clickedSave && (!sendToEventHub && !sendToStorage))"
							[attr.aria-label]="'endpointManagement.nonWindows.applications.dataExport.addNew.validations.forwardType' | i18n">
							{{ 'endpointManagement.nonWindows.applications.dataExport.addNew.validations.forwardType' | i18n }}
						</div>
					</div>
                    <div class="wcd-margin-medium-top form-group">
						<div class="wcd-margin-small-bottom">{{ 'endpointManagement.nonWindows.applications.dataExport.details.eventTypesCombo' | i18n }}</div>
                        <wcd-checklist
                            id="logTypesFieldId"
                            name="data-export-log-types"
                            [selectById]="false"
                            [(ngModel)]="selectedLogTypes"
							[values]="availableLogTypes"
							[attr.aria-invalid]="(clickedSave && (selectedLogTypes.length === 0))"
							[ariaLabel]="'endpointManagement.nonWindows.applications.dataExport.details.eventTypesCombo' | i18n"
						>
						</wcd-checklist>

						<div class="error-message"
							*ngIf="(clickedSave && (selectedLogTypes.length === 0))"
							role="alert"
							[attr.aria-label]="( 'endpointManagement.nonWindows.applications.dataExport.details.eventTypesCombo' | i18n ) + ': ' + ( 'endpointManagement.nonWindows.applications.dataExport.addNew.validations.eventTypes' | i18n )"
							>
							{{ 'endpointManagement.nonWindows.applications.dataExport.addNew.validations.eventTypes' | i18n }}
						</div>
                    </div>
                </section>
            </div>
            <div class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
				<fab-primary-button
					className="wcd-width-xs-small wcd-margin-small-right"
					data-track-type="Button"
					data-track-id="SaveNewDataExport"
					[text]="'buttons.save' | i18n"
					[disabled]="isSaving"
					type="submit">
				</fab-primary-button>
				<fab-default-button
					(onClick)="cancel()"
					data-track-type="Button"
					data-track-id="CancelNewDataExport"
					[text]="'buttons.cancel' | i18n"
				></fab-default-button>
            </div>
	    </form>
    </div>
</wcd-panel>
