/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tvm-history-trend.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i3 from "@angular-react/fabric";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../projects/charts/src/lib/time-series/time-series-chart.component.ngfactory";
import * as i6 from "../../../../../../../projects/charts/src/lib/time-series/time-series-chart.component";
import * as i7 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i9 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i10 from "./tvm-history-trend.component";
import * as i11 from "../../services/tvm-color.service";
import * as i12 from "../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
var styles_TvmHistoryTrendComponent = [i0.styles];
var RenderType_TvmHistoryTrendComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TvmHistoryTrendComponent, data: {} });
export { RenderType_TvmHistoryTrendComponent as RenderType_TvmHistoryTrendComponent };
function View_TvmHistoryTrendComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "wcd-font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t", "\n\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._tvmHistoryTrendOptions.title; _ck(_v, 1, 0, currVal_0); }); }
function View_TvmHistoryTrendComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "advancement wcd-flex-baseline"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "fab-icon", [], null, null, null, i2.View_FabIconComponent_0, i2.RenderType_FabIconComponent)), i1.ɵdid(3, 5816320, null, 0, i3.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "ms-color-gray140 wcd-font-size-s"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["\n\t\t\t\t", "\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._advancement.contentClass; var currVal_1 = _co._advancement.iconName; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co._advancement.advancement; _ck(_v, 7, 0, currVal_2); }); }
function View_TvmHistoryTrendComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "numberOfDays ms-color-gray140 wcd-font-size-mi"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t", "\n\t\t"])), i1.ɵpod(2, { days: 0 }), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 0), "tvm.historyTrends.daysAnnotation", _ck(_v, 2, 0, _co._numberOfDays))); _ck(_v, 1, 0, currVal_0); }); }
function View_TvmHistoryTrendComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "trend__metadata wcd-flex-baseline"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TvmHistoryTrendComponent_3)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TvmHistoryTrendComponent_4)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TvmHistoryTrendComponent_5)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._tvmHistoryTrendOptions.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co._tvmHistoryTrendOptions.advancement.show && _co._advancement); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co._tvmHistoryTrendOptions.showNumberOfDays; _ck(_v, 9, 0, currVal_2); }, null); }
function View_TvmHistoryTrendComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "wcd-time-series-chart", [], null, null, null, i5.View_TimeSeriesChartComponent_0, i5.RenderType_TimeSeriesChartComponent)), i1.ɵdid(3, 770048, null, 0, i6.TimeSeriesChartComponent, [i1.ElementRef, i7.I18nService, i8.TzDateService], { settings: [0, "settings"], data: [1, "data"] }, null), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._trendSettings; var currVal_1 = _co._trend; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_TvmHistoryTrendComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TvmHistoryTrendComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TvmHistoryTrendComponent_6)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co._tvmHistoryTrendOptions.showAdvancement || _co._tvmHistoryTrendOptions.showNumberOfDays) || _co._tvmHistoryTrendOptions.title); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co._trendSettings && _co._trend); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_TvmHistoryTrendComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i9.I18nPipe, [i7.I18nService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TvmHistoryTrendComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co._trendSettings && _co._trend) && (_co._trend.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TvmHistoryTrendComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tvm-history-trend", [], null, null, null, View_TvmHistoryTrendComponent_0, RenderType_TvmHistoryTrendComponent)), i1.ɵdid(1, 49152, null, 0, i10.TvmHistoryTrendComponent, [i7.I18nService, i11.TvmColorService, i8.TzDateService, i12.PrettyNumberService], null, null)], null, null); }
var TvmHistoryTrendComponentNgFactory = i1.ɵccf("tvm-history-trend", i10.TvmHistoryTrendComponent, View_TvmHistoryTrendComponent_Host_0, { options: "options" }, {}, []);
export { TvmHistoryTrendComponentNgFactory as TvmHistoryTrendComponentNgFactory };
