<div class="wcd-full-height wcd-flex-vertical">
	<header class="page-header wcd-flex-none">
		<h2>{{(titleService.state$ | async).pageTitle}}</h2>
	</header>
	<pending-actions [pendingActionTypes]="pendingActionTypes"
					 class="wcd-flex-1"
					 [navClassName]="'wcd-padding-large-left'"
					 [isRemediationDisabled]="pendingActionsService.isRemediationDisabled$ | async"
					 [isLoading]="isLoading"
					 [error]="error"
					 [pollingError]="error"></pending-actions>
</div>
