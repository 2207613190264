var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/****************************************************************************************************
 * This is a copy of entity-icon-theme-mapping from InE package, used for Angular entities still defined
 * in this package.
 * IT ONLY CONTAINS ENTITIES THAT HAS ENTITY PAGES IN ANGULAR!!!! Please remove any migrated entity from here!
 * Please remove once all are migrated
 *****************************************************************************************************/
/**
 * Get a map of icon and icon colors per entity.
 * Based on this Figma:
 * https://www.figma.com/file/pqAvLnT1gPGrsxqKwHXk1j/Search?node-id=4207%3A408339
 * Design Owner: Michal Har Gev
 * @param theme
 */
export var getEntityIconThemeMapping = function (theme) {
    var evidenceTheme = {
        iconColor: theme.palette.neutralPrimary,
        iconBackgroundColor: theme.palette.neutralQuaternaryAlt
    };
    return {
        "file": __assign({ iconName: "Page" }, evidenceTheme),
        "ip": __assign({ iconName: "Streaming" }, evidenceTheme),
        "url": __assign({ iconName: "Link" }, evidenceTheme),
        "machine": {
            iconName: "System",
            iconColor: theme.palette.themeLighter,
            iconBackgroundColor: theme.palette.themeDarker
        },
        "incident": {
            iconName: "ShieldAlert",
            iconColor: '#610000',
            iconBackgroundColor: theme.semanticColors.errorBackground
        }
    };
};
