import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';

@Entity({
	singularName: 'Device Group Simple Rule Operator',
	pluralName: 'Device Group Simple Rule Operator',
	values: [
		{ id: 0, nameKey: 'machineGroup_rule_operator_startsWith' },
		{ id: 1, nameKey: 'machineGroup_rule_operator_endsWith' },
		{ id: 2, nameKey: 'machineGroup_rule_operator_equals' },
		{ id: 3, nameKey: 'machineGroup_rule_operator_contains' },
		{ id: 4, nameKey: 'machineGroup_rule_operator_in', valueIsList: true },
	],
	readonly: true,
})
export class MachineGroupSimpleRuleOperator extends EntityModelBase {
	@EntityField() nameKey: string;

	@EntityField() valueIsList: boolean;
}
