import { NgModule } from '@angular/core';
import { DomainEntityTypeService } from './services/domain.entity-type.service';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../shared/shared.module';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { DomainsService } from './services/domains.service';
import { CommentsModule } from '../../comments/comments.module';
import { FabSpinnerModule, FabIconModule } from '@angular-react/fabric';
import { DomainEntityPanelComponent } from './components/domain.entity-panel.component';
import { DomainUrlsComponent } from './components/domain-urls.dataview.component';
import { DomainExposedMachinesComponent } from './components/domain-exposed-machines.dataview.component';
import { DomainExposedMachinesFullComponent } from './components/domain-exposed-machines-full.dataview.component';
import { DomainUrlsFullComponent } from './components/domain-urls-full.dataview.component';
import { PanelsModule } from '@wcd/panels';

@NgModule({
	imports: [
		SharedModule,
		GlobalEntitiesModule,
		GlobalEntityPanelsModule,
		DataViewsModule,
		CommentsModule,
		FabSpinnerModule,
		FabIconModule,
		PanelsModule,
	],
	declarations: [
		DomainEntityPanelComponent,
		DomainUrlsComponent,
		DomainExposedMachinesComponent,
		DomainExposedMachinesFullComponent,
		DomainUrlsFullComponent,
	],
	exports: [
		DomainUrlsComponent,
		DomainExposedMachinesComponent,
		DomainExposedMachinesFullComponent,
		DomainUrlsFullComponent,
	],
	entryComponents: [
		DomainEntityPanelComponent,
		DomainExposedMachinesFullComponent,
		DomainUrlsFullComponent,
	],
	providers: [DomainEntityTypeService, DomainsService],
})
export class DomainsEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(DomainEntityTypeService);
	}
}
