import { InjectionToken } from '@angular/core';
import { HuntingSavedQuery, HuntingScheduledQuery, HuntingSharedQuery, HuntingUserQuery } from '@wcd/domain';

import { EntitiesDisplayMap } from './entity-display.model';
import { defaultEntitiesDisplayMap } from './defaults/entity-maps.defaults';

export const PACKAGE_NAME = 'WCD_HUNTING';

export const WCD_HUNTING_ENTITIES_DISPLAY_MAP = new InjectionToken<EntitiesDisplayMap>(
	`${PACKAGE_NAME}_ENTITIES_DISPLAY_MAP`,
	{
		factory: () => defaultEntitiesDisplayMap,
	}
);

export type SupportedQuery = HuntingUserQuery | HuntingSharedQuery | HuntingScheduledQuery | HuntingSavedQuery;
