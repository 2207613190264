import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataViewConfig } from '@wcd/dataview';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TitleService } from '../../../shared/services/title.service';
import { Domain } from '@wcd/domain';
import { DomainFields } from '../services/domain.fields';
import { Paris, Repository, DataQuery, DataSet } from '@microsoft/paris';
import { DataViewComponent } from '../../../dataviews/components/dataview.component';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { PreferencesService } from '@wcd/config';
import { Observable, of } from 'rxjs';
import { DomainsService } from '../services/domains.service';
import { AppConfigService } from '@wcd/app-config';
import { Lazy } from '../../../utils/lazy';
import { TimeRangeId, TimeRangeValue, isCustomTimeRangeValue } from '@wcd/date-time-picker';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { find } from 'lodash-es';
import { map, tap, mergeMap } from 'rxjs/operators';
export const TIME_RANGE_DEFAULT_PREFERENCE_ID = 'domains_time_range_default';
export const WEB_THREAT_CATEGORY_FILTERS_ID = 'domains_web_threat_category_filters';
export const DOMAIN_TIME_RANGES = [
	TimeRangeId.day,
	TimeRangeId.week,
	TimeRangeId.month,
	TimeRangeId['6months'],
];
export const DEFAULT_DOMAIN_TIME_RANGE = 30;
export const DEFAULT_WEB_THREAT_CATEGORY_FILTER = 'Any';
export const DEFAULT_WEB_THREAT_SEVERITY_FILTER = '0';
export const DEFAULT_WEB_THREAT_STATUS_FILTER = '0';

@Component({
	selector: 'domains-dataview',
	providers: [DomainFields],
	template: `
		<dataview
			class="wcd-full-height"
			[id]="dataViewId"
			[repository]="repository"
			[entityType]="entityType"
			[fields]="domainFields.fields"
			[allowPaging]="false"
			[allowFilters]="allowFilters"
			[fixedOptions]="dataviewFixedOptions"
			[dataViewConfig]="dataViewConfig"
			[disableSelection]="disableSelection"
			[label]="'reporting.webThreatReport.domainList.domainHeader' | i18n"
		>
			<div class="page-header" dataview-header *ngIf="showHeader">
				<h2>{{ (titleService.state$ | async).pageTitle }} {{ totalDomains$ | async }}</h2>
			</div>
			<ng-container dataview-controls>
				<fancy-select
					[(ngModel)]="currentTimeRange"
					*ngIf="allowTimeRangeSelect"
					buttonIcon="calendar"
					class="command-bar-item-dropdown"
					label="name"
					[values]="timeRanges"
					(ngModelChange)="onRangeTypeSelect()"
					[ariaLabel]="'dataview.timePeriod' | i18n"
				></fancy-select>
			</ng-container>
		</dataview>
	`,
})
export class DomainsDataviewComponent implements OnInit {
	dataViewId: string = 'domains';
	allowFilters: boolean = true;
	fixedOptions: { [index: string]: any };
	allowTimeRangeSelect: boolean = true;
	showHeader: boolean = true;
	repository: Repository<Domain>;
	disableSelection: boolean = true;

	entityType: EntityType<Domain>;

	dataviewFixedOptions: { [index: string]: any };
	dataViewConfig: DataViewConfig;
	currentTimeRange: TimeRangeValue;
	totalDomains$: Observable<string>;
	webThreatCategoryFilters$: { [key: string]: string };

	private readonly _timeRanges = new Lazy(() => {
		return this.timeRangesService.pick(DOMAIN_TIME_RANGES);
	});

	constructor(
		public paris: Paris,
		public domainFields: DomainFields,
		private domainService: DomainsService,
		public titleService: TitleService,
		globalEntityTypesService: GlobalEntityTypesService,
		public appConfigService: AppConfigService,
		private readonly timeRangesService: TimeRangesService,
		private preferencesService: PreferencesService,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.entityType = globalEntityTypesService.getEntityType(Domain);
		this.repository = this.paris.getRepository(Domain);
	}

	private parseQuery(queryString) {
		const query = {};
		const pairs: string[] = (queryString[0] === '?' ? queryString.substr(1) : queryString).split(',');

		Object.entries(pairs).forEach(([key, values]) => {
			const pair = values.split('=');
			query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '').replace(/\|/g, ',');
		});

		return query;
	}

	private getDataViewConfig(): DataViewConfig {
		return {
			getFiltersData: (): Observable<Record<string, any>> => this.domainService.getDomainsFilters(),
			disabledVisibleFieldIds: ['severity', 'status'],
		};
	}

	onRangeTypeSelect() {
		if (this.currentTimeRange)
			this.preferencesService.setPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID, this.currentTimeRange.id);

		this.router.navigate(['.'], {
			relativeTo: this.route,
			queryParams: {
				page: null,
				range: this.currentTimeRange.id,
			},
			queryParamsHandling: 'merge',
		});

		this.setFixedOptions();
	}

	private setFixedOptions() {
		this.dataviewFixedOptions = Object.assign(
			{
				lookBackInDays:
					this.allowTimeRangeSelect && !isCustomTimeRangeValue(this.currentTimeRange)
						? this.currentTimeRange.value
						: DEFAULT_DOMAIN_TIME_RANGE,
			},
			this.fixedOptions
		);
	}

	get timeRanges() {
		return this._timeRanges.value;
	}

	getTotalDomainCount(): Observable<string> {
		return this.repository
			.query(
				{
					where: {
						lookBackInDays:
							this.allowTimeRangeSelect && !isCustomTimeRangeValue(this.currentTimeRange)
								? this.currentTimeRange.value
								: DEFAULT_DOMAIN_TIME_RANGE,
						webThreatCategory:
							this.webThreatCategoryFilters$ &&
							this.webThreatCategoryFilters$['webThreatCategory']
								? this.webThreatCategoryFilters$['webThreatCategory']
								: DEFAULT_WEB_THREAT_CATEGORY_FILTER,
						status:
							this.webThreatCategoryFilters$ && this.webThreatCategoryFilters$['status']
								? this.webThreatCategoryFilters$['status']
								: DEFAULT_WEB_THREAT_STATUS_FILTER,
						severity:
							this.webThreatCategoryFilters$ && this.webThreatCategoryFilters$['severity']
								? this.webThreatCategoryFilters$['severity']
								: DEFAULT_WEB_THREAT_SEVERITY_FILTER,
					},
				},
				{ allowCache: true }
			)
			.pipe(
				mergeMap(items => {
					return of(`(${items.items.length})`);
				})
			);
	}

	ngOnInit() {
		this.currentTimeRange = this.appConfigService.isDemoTenant
			? this.timeRanges[this.timeRanges.length - 1]
			: this.timeRanges.find(
					range =>
						range.id === this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID)
			  ) || this.timeRanges[1];
		this.route.queryParams.subscribe((params: Params) => {
			const locationRange = params.range;
			var locationRangeChange = false;

			if (locationRange) {
				const foundRange: TimeRangeValue = find(this.timeRanges, range => range.id === locationRange);

				locationRangeChange = foundRange !== this.currentTimeRange;
				if (locationRangeChange && foundRange) this.currentTimeRange = foundRange;

				// The range in the url param overrides preference settings (need to change preference if url params specifically queries with specific time range)
				this.preferencesService.setPreference(
					TIME_RANGE_DEFAULT_PREFERENCE_ID,
					this.currentTimeRange.id
				);
			}

			if (locationRangeChange) {
				this.setFixedOptions();
				this.dataViewConfig = this.getDataViewConfig();
			}

			if (params.filters) {
				const webThreatCategoryFilters = this.parseQuery(params.filters);
				this.preferencesService.setPreference(
					WEB_THREAT_CATEGORY_FILTERS_ID,
					webThreatCategoryFilters
				);
				this.webThreatCategoryFilters$ = webThreatCategoryFilters;
			} else {
				this.preferencesService.setPreference(WEB_THREAT_CATEGORY_FILTERS_ID, null);
				this.webThreatCategoryFilters$ = null;
			}

			if (this.repository) {
				this.totalDomains$ = this.getTotalDomainCount();
			}
		});

		this.setFixedOptions();
		this.dataViewConfig = this.getDataViewConfig();
	}
}
