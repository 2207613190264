import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	Output,
	ViewChild,
	ElementRef,
	AfterViewInit,
} from '@angular/core';
import { ClipboardService } from '../services/clipboard.service';
import { DOCUMENT } from '@angular/common';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'copy-to-clipboard',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<span class="copy-to-clipboard" [ngClass]="settings.className">
			<button
				class="btn-link"
				[class.copied]="isCopied"
				[disabled]="!content"
				[attr.data-track-id]="'CopyToClipboard-' + ariaLabelFieldName"
				data-track-type="Button"
				[attr.aria-label]="'common.copyFieldToClipboardLabel' | i18n: { field: ariaLabelFieldName }"
				(click)="copyToClipboard()"
				[wcdTooltip]="tooltipText"
				#copyBtn
			>
				<wcd-shared-icon [iconName]="isCopied ? 'SkypeCircleCheck' : 'Copy'"> </wcd-shared-icon>
				{{ settings.copyText }}
			</button>
		</span>
	`,
})
export class CopyToClipboardComponent implements AfterViewInit {
	// This is the string you want to add to clipboard
	@Input() content: string;

	// This is the ID of the element to mark as copied after clicking the 'Copy' button
	@Input() originId: string;

	@Input()
	set settings(value) {
		if (value) {
			this._settings = Object.assign({}, this.DEFAULT_COPY_SETTINGS, value);
		}
	}
	@Input() ariaLabelFieldName: string = '';

	@Output() copyEvent: EventEmitter<string> = new EventEmitter();
	@Output() errorEvent: EventEmitter<string> = new EventEmitter();
	DEFAULT_COPY_SETTINGS: CopyToClipboardSettings;

	get settings(): CopyToClipboardSettings {
		if (!this._settings) {
			this._settings = this.DEFAULT_COPY_SETTINGS;
		}
		return this._settings;
	}

	@ViewChild('copyBtn', { static: true })
	set copyButton(button: ElementRef) {
		this.buttonEl = button.nativeElement;
	}

	buttonEl: HTMLElement;
	isCopied: boolean;
	tooltipText: string;
	private _settings: CopyToClipboardSettings;

	constructor(
		private clipboardService: ClipboardService,
		private changeDetectionRef: ChangeDetectorRef,
		private i18nService: I18nService,
		private liveAnnouncer: LiveAnnouncer,
		@Inject(DOCUMENT) private document: Document
	) {
		this.DEFAULT_COPY_SETTINGS = {
			copyText: this.i18nService.get('copy'),
		};
		this._settings = this.DEFAULT_COPY_SETTINGS;
	}

	ngAfterViewInit() {
		this.tooltipText = this.i18nService.get('common_copyFieldToClipboardLabel', {
			field: this.ariaLabelFieldName,
		});
	}

	public copyToClipboard(): void {
		this.selectElement();

		this.clipboardService.copy(this.content).subscribe(
			() => {
				this.isCopied = true;
				this.tooltipText = this.i18nService.get('common.copyToClipboard', {
					text: this.ariaLabelFieldName,
				});
				this.liveAnnouncer.announce(
					this.i18nService.get('common.copyToClipboard', { text: this.ariaLabelFieldName })
				);
				this.changeDetectionRef.detectChanges();

				setTimeout(() => {
					this.isCopied = false;
					this.tooltipText = this.i18nService.get('common_copyFieldToClipboardLabel', {
						field: this.ariaLabelFieldName,
					});
					this.changeDetectionRef.detectChanges();
				}, 1500);
				this.copyEvent.emit(this.content);
			},
			error => {
				this.changeDetectionRef.detectChanges();
				this.errorEvent.emit(error);
			},
			() => {
				this.buttonEl.focus();
			}
		);
	}

	selectElement() {
		if (this.originId) {
			const originElement: HTMLElement = this.document.getElementById(this.originId);
			if (
				originElement &&
				(originElement instanceof HTMLTextAreaElement || originElement instanceof HTMLInputElement)
			) {
				setTimeout(() => {
					(<HTMLInputElement>originElement).select();
					this.changeDetectionRef.markForCheck();
				});
			}
		}
	}
}

export interface CopyToClipboardSettings {
	copyText?: string;
	className?: string;
}
