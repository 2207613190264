/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./impacted-entities-datatables/impacted-entities-datatables.component.ngfactory";
import * as i5 from "./impacted-entities-datatables/impacted-entities-datatables.component";
import * as i6 from "@microsoft/paris/dist/lib/paris";
import * as i7 from "../../dialogs/services/dialogs.service";
import * as i8 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i9 from "../../../../../../projects/config/src/lib/services/features.service";
import * as i10 from "./impacted-entities-panel.component";
import * as i11 from "@angular/router";
var styles_ImpactedEntitiesPanelComponent = [];
var RenderType_ImpactedEntitiesPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImpactedEntitiesPanelComponent, data: {} });
export { RenderType_ImpactedEntitiesPanelComponent as RenderType_ImpactedEntitiesPanelComponent };
export function View_ImpactedEntitiesPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "wcd-panel", [], null, [[null, "close"], [null, "startClose"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } if (("startClose" === en)) {
        var pd_2 = (_co.startClose() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close", startClose: "startClose" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 2, "impacted-entities-datatables", [], null, null, null, i4.View_ImpactedEntitiesDatatablesComponent_0, i4.RenderType_ImpactedEntitiesDatatablesComponent)), i0.ɵdid(6, 638976, null, 0, i5.ImpactedEntitiesDatatablesComponent, [i6.Paris, i0.ChangeDetectorRef, i7.DialogsService, i8.I18nService, i9.FeaturesService], { entities: [0, "entities"], showTitle: [1, "showTitle"], showAllItems: [2, "showAllItems"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.entities; var currVal_2 = false; var currVal_3 = true; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ImpactedEntitiesPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "impacted-entities-panel", [], null, null, null, View_ImpactedEntitiesPanelComponent_0, RenderType_ImpactedEntitiesPanelComponent)), i0.ɵdid(1, 245760, null, 0, i10.ImpactedEntitiesPanelComponent, [i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImpactedEntitiesPanelComponentNgFactory = i0.ɵccf("impacted-entities-panel", i10.ImpactedEntitiesPanelComponent, View_ImpactedEntitiesPanelComponent_Host_0, { settings: "settings", entities: "entities" }, {}, []);
export { ImpactedEntitiesPanelComponentNgFactory as ImpactedEntitiesPanelComponentNgFactory };
