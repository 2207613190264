/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i2 from "../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i6 from "@angular/common";
import * as i7 from "./alert-impacted-assets.component";
import * as i8 from "../../machines/services/machines.fields";
import * as i9 from "../../users/services/legacy-users.fields.service";
import * as i10 from "../../../global_entities/services/entity-panels.service";
var styles_AlertImpactedAssetsComponent = [];
var RenderType_AlertImpactedAssetsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertImpactedAssetsComponent, data: {} });
export { RenderType_AlertImpactedAssetsComponent as RenderType_AlertImpactedAssetsComponent };
function View_AlertImpactedAssetsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "wcd-datatable", [["class", "table-with-legend"]], null, [[null, "itemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemClick" === en)) {
        var pd_0 = (_co.showMachineSidePanel($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DataTableComponent_0, i1.RenderType_DataTableComponent)), i0.ɵdid(3, 13352960, null, 0, i2.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.LiveAnnouncer, i4.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], showHeaders: [3, "showHeaders"], label: [4, "label"] }, { itemClick: "itemClick" }), i0.ɵpad(4, 1), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.machine); var currVal_1 = false; var currVal_2 = _co.machineFields; var currVal_3 = true; var currVal_4 = i0.ɵunv(_v, 3, 4, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "alerts.panel.impactedAssets.machines.table")); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_AlertImpactedAssetsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "wcd-datatable", [["class", "table-with-legend"]], null, [[null, "itemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemClick" === en)) {
        var pd_0 = (_co.showUserSidePanel($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DataTableComponent_0, i1.RenderType_DataTableComponent)), i0.ɵdid(3, 13352960, null, 0, i2.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.LiveAnnouncer, i4.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], showHeaders: [3, "showHeaders"], label: [4, "label"] }, { itemClick: "itemClick" }), i0.ɵpad(4, 1), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.user); var currVal_1 = false; var currVal_2 = _co.userFields; var currVal_3 = true; var currVal_4 = i0.ɵunv(_v, 3, 4, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "alerts.panel.impactedAssets.users.table")); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_AlertImpactedAssetsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertImpactedAssetsComponent_1)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertImpactedAssetsComponent_2)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.machine; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.user; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_AlertImpactedAssetsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alert-impacted-assets", [], null, null, null, View_AlertImpactedAssetsComponent_0, RenderType_AlertImpactedAssetsComponent)), i0.ɵdid(1, 114688, null, 0, i7.AlertImpactedAssetsComponent, [i4.I18nService, i8.MachinesFieldsService, i9.LegacyUsersFieldsService, i10.EntityPanelsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertImpactedAssetsComponentNgFactory = i0.ɵccf("alert-impacted-assets", i7.AlertImpactedAssetsComponent, View_AlertImpactedAssetsComponent_Host_0, { machine: "machine", user: "user" }, {}, []);
export { AlertImpactedAssetsComponentNgFactory as AlertImpactedAssetsComponentNgFactory };
