var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship } from '@microsoft/paris';
import { FileDownloadLink } from '../file.download-link.value-object';
import { File } from '../file.entity';
var ɵ0 = function (file) { return ({ Sha: file.id }); }, ɵ1 = function (config) { return config.data.serviceUrls.userRequests; };
var FileFileDownloadLinkRelationship = /** @class */ (function () {
    function FileFileDownloadLinkRelationship() {
    }
    FileFileDownloadLinkRelationship = __decorate([
        EntityRelationship({
            sourceEntity: File,
            dataEntity: FileDownloadLink,
            getRelationshipData: ɵ0,
            endpoint: 'sampledownloadrequest/download',
            baseUrl: ɵ1,
        })
    ], FileFileDownloadLinkRelationship);
    return FileFileDownloadLinkRelationship;
}());
export { FileFileDownloadLinkRelationship };
export { ɵ0, ɵ1 };
