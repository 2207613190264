/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../global_entities/components/entity-details/missing-kb/missing-kb.entity-details.component.ngfactory";
import * as i2 from "../../../../../global_entities/components/entity-details/missing-kb/missing-kb.entity-details.component";
import * as i3 from "../../../../../global_entities/services/activated-entity.service";
import * as i4 from "../../../common/side-panel.service";
import * as i5 from "@microsoft/paris/dist/lib/paris";
import * as i6 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../../../tvm/services/tvm-texts.service";
import * as i8 from "../../../../../tvm/services/tvm-download.service";
import * as i9 from "../../../../../../../../../projects/config/src/lib/services/features.service";
import * as i10 from "../../../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i11 from "@angular/router";
import * as i12 from "./missing-kb.entity-panel.component";
var styles_MissingKbEntityPanelComponent = [];
var RenderType_MissingKbEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MissingKbEntityPanelComponent, data: {} });
export { RenderType_MissingKbEntityPanelComponent as RenderType_MissingKbEntityPanelComponent };
export function View_MissingKbEntityPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 5, "section", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "missing-kb-entity-details", [], null, null, null, i1.View_MissingKbEntityDetailsComponent_0, i1.RenderType_MissingKbEntityDetailsComponent)), i0.ɵdid(4, 245760, null, 0, i2.MissingKbEntityDetailsComponent, [i3.ActivatedEntity, i4.SidePanelService, i5.Paris, i6.I18nService, i7.TvmTextsService, i0.Injector, i8.TvmDownloadService, i0.ChangeDetectorRef, i9.FeaturesService, i10.TzDateService, i11.Router], { machineDetails: [0, "machineDetails"], missingKb: [1, "missingKb"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.machineDetails; var currVal_1 = _co.missingKb; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_MissingKbEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "missing-kb-entity-panel", [], null, null, null, View_MissingKbEntityPanelComponent_0, RenderType_MissingKbEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i12.MissingKbEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MissingKbEntityPanelComponentNgFactory = i0.ɵccf("missing-kb-entity-panel", i12.MissingKbEntityPanelComponent, View_MissingKbEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { MissingKbEntityPanelComponentNgFactory as MissingKbEntityPanelComponentNgFactory };
