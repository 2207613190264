import { CsvService } from '../../../shared/services/csv.service';
import { ServiceUrlsService } from '@wcd/app-config';
var EXPORT_TO_CSV_TOKEN_URL = 'ti/authtoken';
var EXPORT_TO_CSV_URL = 'ti/csv';
var CustomTiIndicatorBackendService = /** @class */ (function () {
    function CustomTiIndicatorBackendService(serviceUrlsService, csvService) {
        this.serviceUrlsService = serviceUrlsService;
        this.csvService = csvService;
    }
    Object.defineProperty(CustomTiIndicatorBackendService.prototype, "apiUrl", {
        get: function () {
            return this.serviceUrlsService.userRequests;
        },
        enumerable: true,
        configurable: true
    });
    CustomTiIndicatorBackendService.prototype.downloadCsv = function (downloadParams) {
        var params = {
            type: downloadParams.type,
            downloadImportCsvSample: downloadParams.downloadImportCsvSample.toString(),
        };
        return this.csvService.downloadCsv({
            tokenUrl: this.apiUrl + "/" + EXPORT_TO_CSV_TOKEN_URL,
            apiUrl: this.apiUrl + "/" + EXPORT_TO_CSV_URL,
            params: params,
        });
    };
    return CustomTiIndicatorBackendService;
}());
export { CustomTiIndicatorBackendService };
