import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Table config',
	pluralName: '',
	readonly: true,
})
export class LiveResponseTableConfig extends ModelBase {
	@EntityField({ data: 'show_header', defaultValue: true })
	showHeader: boolean;
}
