import { RelationshipType, EntityRelationshipRepositoryType, EntityRelationship } from '@microsoft/paris';
import { AadUser } from './aad.user.entity';
import { LegacyUserProfile } from '../../legacy/user/legacy-user-profile.entity';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@EntityRelationship({
	sourceEntity: AadUser,
	dataEntity: LegacyUserProfile,
	getRelationshipData: (aadUser: AadUser) => ({
		sid: aadUser.id,
		userAccount: aadUser.accountName,
		userAccountDomain: aadUser.domainName,
	}),
	endpoint: 'UserCommunicationProfile',
	allowedTypes: [RelationshipType.OneToOne],
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class AadUserLegacyUserProfileRelationship
	implements EntityRelationshipRepositoryType<AadUser, LegacyUserProfile> {}
