export interface MitreTechniqueObject {
	id: string;
	nameWithId: string;
	nameWithoutId: string;
	link: string;
	fullDescription: string;
	shortDescription: string;
	categories: Array<MitreTechniqueCategoryObject>;
}

export interface MitreTechniqueCategoryObject {
	name: string;
	nameDisplay: string;
	link: string;
}

export interface CyberEventMitreTechniqueInfo {
	techniqueId: string;
	description?: string;
}

export const MITRE_TECHNIQUE_URL_PREFIX: string = 'https://attack.mitre.org/techniques/';
const MITRE_TECHNIQUE_SHORT_DESCRIPTION_LENGTH = 300;
const MITRE_TACTIC_URL_PREFIX: string = 'https://attack.mitre.org/tactics/';

export class MitreService {
	public static getFilteredMitreTechniquesObjects(
		mitreTechniques: Array<string>
	): Array<MitreTechniqueObject> {
		return this.filterInvalidMitreTechniques(mitreTechniques).map((mitreTechniqueId: string) => {
			return this.getMitreTechniqueObject(mitreTechniqueId);
		});
	}

	public static getFilteredMitreTechniquesObjectsFromMitreInfo(
		mitreTechniquesInfoObjects: Array<CyberEventMitreTechniqueInfo>
	): Array<MitreTechniqueObject> {
		if (!mitreTechniquesInfoObjects) {
			return null;
		}

		return mitreTechniquesInfoObjects
			.filter((mitreTechniqueInfoObject: CyberEventMitreTechniqueInfo) =>
				this.filterInvalidMitreTechnique(mitreTechniqueInfoObject.techniqueId)
			)
			.map((mitreTechniqueInfoObject: CyberEventMitreTechniqueInfo) => {
				return this.getMitreTechniqueObject(
					mitreTechniqueInfoObject.techniqueId,
					mitreTechniqueInfoObject.description
				);
			});
	}

	public static getMitreTechniqueObject(
		mitreTechniqueId: string,
		mitreTechniqueCustomDescription?: string
	) {
		const fullDescription: string = mitreTechniqueCustomDescription
			? mitreTechniqueCustomDescription
			: this.getMitreTechniqueFullDescription(mitreTechniqueId);
		return {
			id: mitreTechniqueId,
			nameWithId: this.getMitreTechniqueName(mitreTechniqueId),
			nameWithoutId: this.getMitreTechniqueName(mitreTechniqueId, false),
			fullDescription: fullDescription,
			link: this.getMitreTechniqueLink(mitreTechniqueId),
			shortDescription: this.getMitreTechniqueShortDescription(fullDescription),
			categories: this.getMitreTechniqueCategories(mitreTechniqueId),
		};
	}

	public static filterInvalidMitreTechniques(mitreTechniques: Array<string>): Array<string> {
		return mitreTechniques ? mitreTechniques.filter(this.filterInvalidMitreTechnique) : [];
	}

	public static filterInvalidMitreTechnique(mitreTechniqueId: string): boolean {
		return Boolean(MITRE_TECHNIQUES[mitreTechniqueId]) ? true : false;
	}

	public static getMitreTechniqueName(mitreTechniqueId: string, addIdPrefix: boolean = true) {
		if (!mitreTechniqueId) {
			return '';
		}

		const idPrefix = addIdPrefix != false ? `${mitreTechniqueId}` : '';
		const mitreTechniqueName = MITRE_TECHNIQUES[mitreTechniqueId]
			? MITRE_TECHNIQUES[mitreTechniqueId]['techniqueName']
			: null;

		return mitreTechniqueName
			? `${idPrefix}${idPrefix && mitreTechniqueName ? ': ' : ''}${mitreTechniqueName}`
			: mitreTechniqueId;
	}

	public static getMitreTechniqueLink(mitreTechniqueId: string): string {
		const [mitreTechnique = '', mitreSubTechnique = ''] = mitreTechniqueId
			? mitreTechniqueId.split('.')
			: [];
		return (
			MITRE_TECHNIQUE_URL_PREFIX + mitreTechnique + (mitreSubTechnique ? '/' + mitreSubTechnique : '')
		);
	}

	public static getMitreTechniqueCategories(mitreTechniqueId: string): Array<MitreTechniqueCategoryObject> {
		return MITRE_TECHNIQUES[mitreTechniqueId]['techniqueCategoriesDisplay']
			? MITRE_TECHNIQUES[mitreTechniqueId]['techniqueCategoriesDisplay'].map(
					(categoryNameDisplay: string) => {
						const categoryName: string = categoryNameDisplay.split(' ').join('');

						return {
							name: categoryName,
							nameDisplay: categoryNameDisplay,
							link: this.getMitreCategoryLink(categoryName),
						};
					}
			  )
			: null;
	}

	public static getMitreTechniqueFullDescription(mitreTechniqueId: string, i18nService?: any): string {
		var fullDescription: string = '';

		if (i18nService) {
			fullDescription = i18nService.get(
				'mitre_technique_description_' + mitreTechniqueId.split('.').join('_')
			);
		}

		if (fullDescription.includes('Not found')) {
			fullDescription = '';
		}

		return fullDescription;
	}

	public static getMitreTechniqueShortDescription(
		fullDescription: string,
		shortDescriptionLength: number = MITRE_TECHNIQUE_SHORT_DESCRIPTION_LENGTH
	): string {
		var shortDescription: string = '';

		if (fullDescription) {
			fullDescription.split('. ').forEach((sentence) => {
				if (shortDescription.length + sentence.length >= shortDescriptionLength) {
					return shortDescription;
				}

				shortDescription += sentence + '. ';
			});
		}

		return shortDescription ? shortDescription : null;
	}

	public static getMitreCategoryLink(mitreCategoryName: string) {
		var categoryId: string;

		switch (mitreCategoryName) {
			case 'InitialAccess':
				categoryId = 'TA0001';
				break;
			case 'Execution':
				categoryId = 'TA0002';
				break;
			case 'Persistence':
				categoryId = 'TA0003';
				break;
			case 'PrivilegeEscalation':
				categoryId = 'TA0004';
				break;
			case 'DefenseEvasion':
				categoryId = 'TA0005';
				break;
			case 'CredentialAccess':
				categoryId = 'TA0006';
				break;
			case 'Discovery':
				categoryId = 'TA0007';
				break;
			case 'LateralMovement':
				categoryId = 'TA0008';
				break;
			case 'Collection':
				categoryId = 'TA0009';
				break;
			case 'CommandAndControl':
				categoryId = 'TA0011';
				break;
			case 'Exfiltration':
				categoryId = 'TA0010';
				break;
			case 'Impact':
				categoryId = 'TA0040';
				break;
			default:
				categoryId = '';
				break;
		}

		return MITRE_TACTIC_URL_PREFIX + categoryId;
	}

	/**
	 *
	 * @param mitreTechniques A string of semicolon separated technique ids
	 * @example "T1065;T1102"
	 * @returns An array of strings or an empty array
	 */
	public static parseAndFilterMitreTechniqueIds(mitreTechniques: string) {
		return mitreTechniques
			? mitreTechniques
					.split(';')
					.map((t) => t.replace(/\s/g, ''))
					.filter(this.filterInvalidMitreTechnique)
			: [];
	}

	public static getMitreTechniqueIdAndName(mitreTechniqueId: string) {
		return {
			id: mitreTechniqueId,
			name: this.getMitreTechniqueName(mitreTechniqueId),
		};
	}
}

// Once awhile, update 'CATEGORY_TO_MITRE_TECHNIQUES_MAPPING' by running the script 'tools\wcd-scripts\mitre_techniques\mitre-techniques-generator.js' and copy his output manually.
export const CATEGORY_TO_MITRE_TECHNIQUES_MAPPING = {
	PrivilegeEscalation: [
		'T1548',
		'T1134',
		'T1546.008',
		'T1547.014',
		'T1546.009',
		'T1546.010',
		'T1546.011',
		'T1055.004',
		'T1053.001',
		'T1053.002',
		'T1547.002',
		'T1547',
		'T1037',
		'T1548.002',
		'T1574.012',
		'T1546.001',
		'T1078.004',
		'T1546.015',
		'T1053.007',
		'T1134.002',
		'T1543',
		'T1053.003',
		'T1574.001',
		'T1574.002',
		'T1078.001',
		'T1078.002',
		'T1484',
		'T1484.002',
		'T1574.004',
		'T1574.006',
		'T1055.001',
		'T1548.004',
		'T1546.014',
		'T1611',
		'T1546',
		'T1574.005',
		'T1068',
		'T1055.011',
		'T1484.001',
		'T1574',
		'T1546.012',
		'T1547.006',
		'T1546.006',
		'T1547.008',
		'T1543.001',
		'T1543.004',
		'T1053.004',
		'T1078.003',
		'T1547.015',
		'T1037.002',
		'T1037.001',
		'T1134.003',
		'T1546.007',
		'T1037.003',
		'T1134.004',
		'T1034',
		'T1574.007',
		'T1574.008',
		'T1574.009',
		'T1547.011',
		'T1547.010',
		'T1055.002',
		'T1546.013',
		'T1547.012',
		'T1055.009',
		'T1055.013',
		'T1055.012',
		'T1055',
		'T1055.008',
		'T1037.004',
		'T1547.007',
		'T1547.001',
		'T1134.005',
		'T1053.005',
		'T1053',
		'T1546.002',
		'T1547.005',
		'T1574.010',
		'T1574.011',
		'T1548.001',
		'T1547.009',
		'T1037.005',
		'T1548.003',
		'T1543.002',
		'T1053.006',
		'T1055.003',
		'T1055.005',
		'T1547.003',
		'T1134.001',
		'T1546.005',
		'T1546.004',
		'T1055.014',
		'T1078',
		'T1546.003',
		'T1543.003',
		'T1547.004',
		'T1547.013',
	],
	Persistence: [
		'T1546.008',
		'T1098',
		'T1547.014',
		'T1098.003',
		'T1137.006',
		'T1098.001',
		'T1546.009',
		'T1546.010',
		'T1546.011',
		'T1053.001',
		'T1053.002',
		'T1547.002',
		'T1197',
		'T1547',
		'T1037',
		'T1542.003',
		'T1176',
		'T1574.012',
		'T1546.001',
		'T1136.003',
		'T1078.004',
		'T1542.002',
		'T1546.015',
		'T1554',
		'T1053.007',
		'T1136',
		'T1543',
		'T1053.003',
		'T1574.001',
		'T1574.002',
		'T1078.001',
		'T1136.002',
		'T1078.002',
		'T1556.001',
		'T1574.004',
		'T1574.006',
		'T1546.014',
		'T1546',
		'T1098.002',
		'T1574.005',
		'T1133',
		'T1574',
		'T1062',
		'T1505.004',
		'T1546.012',
		'T1525',
		'T1547.006',
		'T1546.006',
		'T1547.008',
		'T1543.001',
		'T1543.004',
		'T1053.004',
		'T1136.001',
		'T1078.003',
		'T1547.015',
		'T1037.002',
		'T1037.001',
		'T1556',
		'T1546.007',
		'T1556.004',
		'T1037.003',
		'T1137',
		'T1137.001',
		'T1137.002',
		'T1137.003',
		'T1137.004',
		'T1137.005',
		'T1556.002',
		'T1034',
		'T1574.007',
		'T1574.008',
		'T1574.009',
		'T1547.011',
		'T1556.003',
		'T1205.001',
		'T1547.010',
		'T1546.013',
		'T1542',
		'T1547.012',
		'T1037.004',
		'T1542.004',
		'T1547.007',
		'T1108',
		'T1547.001',
		'T1505.001',
		'T1098.004',
		'T1053.005',
		'T1053',
		'T1546.002',
		'T1547.005',
		'T1505',
		'T1574.010',
		'T1574.011',
		'T1547.009',
		'T1037.005',
		'T1542.001',
		'T1543.002',
		'T1053.006',
		'T1542.005',
		'T1547.003',
		'T1205',
		'T1505.002',
		'T1546.005',
		'T1546.004',
		'T1078',
		'T1505.003',
		'T1546.003',
		'T1543.003',
		'T1547.004',
		'T1547.013',
	],
	CredentialAccess: [
		'T1003.008',
		'T1557.002',
		'T1558.004',
		'T1552.003',
		'T1110',
		'T1003.005',
		'T1552.005',
		'T1552.007',
		'T1056.004',
		'T1110.004',
		'T1552.001',
		'T1555',
		'T1555.003',
		'T1552.002',
		'T1003.006',
		'T1556.001',
		'T1212',
		'T1187',
		'T1606',
		'T1056.002',
		'T1558.001',
		'T1552.006',
		'T1056',
		'T1558.003',
		'T1555.001',
		'T1056.001',
		'T1557.001',
		'T1003.004',
		'T1003.001',
		'T1557',
		'T1556',
		'T1003.003',
		'T1556.004',
		'T1040',
		'T1003',
		'T1110.002',
		'T1556.002',
		'T1110.001',
		'T1555.005',
		'T1110.003',
		'T1556.003',
		'T1552.004',
		'T1003.007',
		'T1606.002',
		'T1003.002',
		'T1555.002',
		'T1558.002',
		'T1528',
		'T1539',
		'T1558',
		'T1111',
		'T1552',
		'T1606.001',
		'T1056.003',
		'T1555.004',
	],
	Collection: [
		'T1557.002',
		'T1560',
		'T1560.003',
		'T1560.002',
		'T1560.001',
		'T1123',
		'T1119',
		'T1115',
		'T1213.003',
		'T1213.001',
		'T1056.004',
		'T1074',
		'T1530',
		'T1602',
		'T1213',
		'T1005',
		'T1039',
		'T1025',
		'T1114',
		'T1114.003',
		'T1056.002',
		'T1056',
		'T1056.001',
		'T1557.001',
		'T1074.001',
		'T1114.001',
		'T1185',
		'T1557',
		'T1602.002',
		'T1074.002',
		'T1114.002',
		'T1602.001',
		'T1113',
		'T1213.002',
		'T1125',
		'T1056.003',
	],
	DefenseEvasion: [
		'T1548',
		'T1134',
		'T1550.001',
		'T1055.004',
		'T1197',
		'T1027.001',
		'T1542.003',
		'T1612',
		'T1548.002',
		'T1218.003',
		'T1574.012',
		'T1070.003',
		'T1070.002',
		'T1070.001',
		'T1078.004',
		'T1553.002',
		'T1553.006',
		'T1027.004',
		'T1218.001',
		'T1542.002',
		'T1218.002',
		'T1578.002',
		'T1134.002',
		'T1578.001',
		'T1574.001',
		'T1574.002',
		'T1078.001',
		'T1578.003',
		'T1140',
		'T1610',
		'T1006',
		'T1562.008',
		'T1600.002',
		'T1562.002',
		'T1562.007',
		'T1562.004',
		'T1562.001',
		'T1078.002',
		'T1556.001',
		'T1484',
		'T1484.002',
		'T1036.007',
		'T1562.010',
		'T1601.002',
		'T1574.004',
		'T1574.006',
		'T1055.001',
		'T1548.004',
		'T1564.008',
		'T1480.001',
		'T1574.005',
		'T1480',
		'T1211',
		'T1055.011',
		'T1070.004',
		'T1222',
		'T1553.001',
		'T1484.001',
		'T1027.006',
		'T1564.005',
		'T1564.001',
		'T1564.002',
		'T1564.003',
		'T1564',
		'T1574',
		'T1562.003',
		'T1562',
		'T1562.006',
		'T1027.005',
		'T1070',
		'T1202',
		'T1553.004',
		'T1218.004',
		'T1036.001',
		'T1149',
		'T1222.002',
		'T1078.003',
		'T1218.014',
		'T1127.001',
		'T1134.003',
		'T1553.005',
		'T1036.004',
		'T1036',
		'T1036.005',
		'T1218.013',
		'T1556',
		'T1578',
		'T1112',
		'T1601',
		'T1218.005',
		'T1218.007',
		'T1564.004',
		'T1599.001',
		'T1599',
		'T1556.004',
		'T1070.005',
		'T1027',
		'T1218.008',
		'T1134.004',
		'T1550.002',
		'T1550.003',
		'T1556.002',
		'T1601.001',
		'T1574.007',
		'T1574.008',
		'T1574.009',
		'T1556.003',
		'T1205.001',
		'T1055.002',
		'T1542',
		'T1055.009',
		'T1055.013',
		'T1055.012',
		'T1055',
		'T1055.008',
		'T1216.001',
		'T1542.004',
		'T1600.001',
		'T1108',
		'T1620',
		'T1218.009',
		'T1218.010',
		'T1036.003',
		'T1564.009',
		'T1578.004',
		'T1036.002',
		'T1207',
		'T1014',
		'T1564.006',
		'T1218.011',
		'T1134.005',
		'T1553.003',
		'T1562.009',
		'T1064',
		'T1574.010',
		'T1574.011',
		'T1548.001',
		'T1218',
		'T1216',
		'T1027.002',
		'T1036.006',
		'T1027.003',
		'T1553',
		'T1548.003',
		'T1497.001',
		'T1542.001',
		'T1542.005',
		'T1221',
		'T1055.003',
		'T1055.005',
		'T1497.003',
		'T1070.006',
		'T1134.001',
		'T1205',
		'T1127',
		'T1535',
		'T1550',
		'T1497.002',
		'T1564.007',
		'T1055.014',
		'T1078',
		'T1218.012',
		'T1497',
		'T1600',
		'T1550.004',
		'T1222.001',
		'T1220',
	],
	Impact: [
		'T1531',
		'T1499.003',
		'T1499.004',
		'T1485',
		'T1486',
		'T1565',
		'T1491',
		'T1498.001',
		'T1561.001',
		'T1561.002',
		'T1561',
		'T1499',
		'T1491.002',
		'T1495',
		'T1490',
		'T1491.001',
		'T1498',
		'T1499.001',
		'T1498.002',
		'T1496',
		'T1565.003',
		'T1499.002',
		'T1489',
		'T1565.001',
		'T1529',
		'T1565.002',
	],
	Discovery: [
		'T1087',
		'T1010',
		'T1217',
		'T1087.004',
		'T1069.003',
		'T1580',
		'T1538',
		'T1526',
		'T1619',
		'T1613',
		'T1087.002',
		'T1069.002',
		'T1482',
		'T1087.003',
		'T1083',
		'T1615',
		'T1016.001',
		'T1087.001',
		'T1069.001',
		'T1046',
		'T1135',
		'T1040',
		'T1201',
		'T1120',
		'T1069',
		'T1057',
		'T1012',
		'T1018',
		'T1518.001',
		'T1518',
		'T1497.001',
		'T1082',
		'T1614.001',
		'T1614',
		'T1016',
		'T1049',
		'T1033',
		'T1007',
		'T1124',
		'T1497.003',
		'T1497.002',
		'T1497',
	],
	ResourceDevelopment: [
		'T1583',
		'T1583.005',
		'T1584.005',
		'T1587.002',
		'T1588.003',
		'T1586',
		'T1584',
		'T1583.002',
		'T1584.002',
		'T1587',
		'T1587.003',
		'T1588.004',
		'T1583.001',
		'T1584.001',
		'T1608.004',
		'T1585.002',
		'T1586.002',
		'T1585',
		'T1587.004',
		'T1588.005',
		'T1608.003',
		'T1608.005',
		'T1587.001',
		'T1588.001',
		'T1588',
		'T1583.004',
		'T1584.004',
		'T1585.001',
		'T1586.001',
		'T1608',
		'T1588.002',
		'T1608.001',
		'T1608.002',
		'T1583.003',
		'T1584.003',
		'T1588.006',
		'T1583.006',
		'T1584.006',
	],
	Reconnaissance: [
		'T1595',
		'T1591.002',
		'T1596.004',
		'T1592.004',
		'T1589.001',
		'T1590.002',
		'T1596.001',
		'T1591.001',
		'T1596.003',
		'T1590.001',
		'T1589.002',
		'T1589.003',
		'T1592.003',
		'T1592',
		'T1589',
		'T1590',
		'T1591',
		'T1592.001',
		'T1590.005',
		'T1591.003',
		'T1591.004',
		'T1590.006',
		'T1590.004',
		'T1590.003',
		'T1598',
		'T1597.002',
		'T1596.005',
		'T1595.001',
		'T1597',
		'T1593.002',
		'T1596',
		'T1593',
		'T1594',
		'T1593.001',
		'T1592.002',
		'T1598.002',
		'T1598.003',
		'T1598.001',
		'T1597.001',
		'T1595.002',
		'T1596.002',
	],
	Execution: [
		'T1059.002',
		'T1053.001',
		'T1053.002',
		'T1059',
		'T1559.001',
		'T1175',
		'T1609',
		'T1053.007',
		'T1053.003',
		'T1610',
		'T1559.002',
		'T1203',
		'T1061',
		'T1559',
		'T1059.007',
		'T1569.001',
		'T1053.004',
		'T1204.002',
		'T1204.003',
		'T1204.001',
		'T1106',
		'T1059.008',
		'T1059.001',
		'T1059.006',
		'T1053.005',
		'T1053',
		'T1064',
		'T1569.002',
		'T1129',
		'T1072',
		'T1153',
		'T1569',
		'T1053.006',
		'T1059.004',
		'T1204',
		'T1059.005',
		'T1059.003',
		'T1047',
	],
	LateralMovement: [
		'T1550.001',
		'T1175',
		'T1021.003',
		'T1210',
		'T1534',
		'T1570',
		'T1550.002',
		'T1550.003',
		'T1563.002',
		'T1021.001',
		'T1563',
		'T1021',
		'T1091',
		'T1021.002',
		'T1021.004',
		'T1563.001',
		'T1051',
		'T1072',
		'T1080',
		'T1550',
		'T1021.005',
		'T1550.004',
		'T1021.006',
	],
	CommandAndControl: [
		'T1071',
		'T1573.002',
		'T1102.002',
		'T1043',
		'T1092',
		'T1071.004',
		'T1568.003',
		'T1132',
		'T1001',
		'T1102.001',
		'T1090.004',
		'T1568.002',
		'T1568',
		'T1573',
		'T1090.002',
		'T1008',
		'T1568.001',
		'T1071.002',
		'T1105',
		'T1090.001',
		'T1001.001',
		'T1071.003',
		'T1104',
		'T1090.003',
		'T1026',
		'T1095',
		'T1132.002',
		'T1571',
		'T1102.003',
		'T1205.001',
		'T1001.003',
		'T1572',
		'T1090',
		'T1219',
		'T1132.001',
		'T1001.002',
		'T1573.001',
		'T1205',
		'T1071.001',
		'T1102',
	],
	Exfiltration: [
		'T1020',
		'T1030',
		'T1048',
		'T1048.002',
		'T1011.001',
		'T1041',
		'T1011',
		'T1052',
		'T1048.001',
		'T1048.003',
		'T1567',
		'T1052.001',
		'T1567.002',
		'T1567.001',
		'T1029',
		'T1020.001',
		'T1537',
	],
	InitialAccess: [
		'T1078.004',
		'T1195.003',
		'T1195.001',
		'T1195.002',
		'T1078.001',
		'T1078.002',
		'T1189',
		'T1190',
		'T1133',
		'T1200',
		'T1078.003',
		'T1566',
		'T1091',
		'T1566.001',
		'T1566.002',
		'T1566.003',
		'T1195',
		'T1199',
		'T1078',
	],
};

/*
Once awhile, update 'MITRE_TECHNIQUES' by running the script 'tools\wcd-scripts\mitre_techniques\mitre-techniques-generator.js' and copy his output manually.
*/
export const MITRE_TECHNIQUES = {
	'T1003.008': {
		techniqueId: 'T1003.008',
		techniqueName: '/etc/passwd and /etc/shadow',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1557.002': {
		techniqueId: 'T1557.002',
		techniqueName: 'ARP Cache Poisoning',
		techniqueCategories: ['CredentialAccess', 'Collection'],
		techniqueCategoriesDisplay: ['Credential Access', 'Collection'],
	},
	'T1558.004': {
		techniqueId: 'T1558.004',
		techniqueName: 'AS-REP Roasting',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1548: {
		techniqueId: 'T1548',
		techniqueName: 'Abuse Elevation Control Mechanism',
		techniqueCategories: ['PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Defense Evasion'],
	},
	T1134: {
		techniqueId: 'T1134',
		techniqueName: 'Access Token Manipulation',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	T1015: {
		techniqueId: 'T1015',
		techniqueName: 'Accessibility Features',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.008': {
		techniqueId: 'T1546.008',
		techniqueName: 'Accessibility Features',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1531: {
		techniqueId: 'T1531',
		techniqueName: 'Account Access Removal',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1087: {
		techniqueId: 'T1087',
		techniqueName: 'Account Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1098: {
		techniqueId: 'T1098',
		techniqueName: 'Account Manipulation',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	T1583: {
		techniqueId: 'T1583',
		techniqueName: 'Acquire Infrastructure',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1595: {
		techniqueId: 'T1595',
		techniqueName: 'Active Scanning',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1547.014': {
		techniqueId: 'T1547.014',
		techniqueName: 'Active Setup',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1098.003': {
		techniqueId: 'T1098.003',
		techniqueName: 'Add Office 365 Global Administrator Role',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1137.006': {
		techniqueId: 'T1137.006',
		techniqueName: 'Add-ins',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1098.001': {
		techniqueId: 'T1098.001',
		techniqueName: 'Additional Cloud Credentials',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	T1182: {
		techniqueId: 'T1182',
		techniqueName: 'AppCert DLLs',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.009': {
		techniqueId: 'T1546.009',
		techniqueName: 'AppCert DLLs',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1103: {
		techniqueId: 'T1103',
		techniqueName: 'AppInit DLLs',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.010': {
		techniqueId: 'T1546.010',
		techniqueName: 'AppInit DLLs',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1155: {
		techniqueId: 'T1155',
		techniqueName: 'AppleScript',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1059.002': {
		techniqueId: 'T1059.002',
		techniqueName: 'AppleScript',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1527: {
		techniqueId: 'T1527',
		techniqueName: 'Application Access Token',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1550.001': {
		techniqueId: 'T1550.001',
		techniqueName: 'Application Access Token',
		techniqueCategories: ['DefenseEvasion', 'LateralMovement'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Lateral Movement'],
	},
	T1017: {
		techniqueId: 'T1017',
		techniqueName: 'Application Deployment Software',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1499.003': {
		techniqueId: 'T1499.003',
		techniqueName: 'Application Exhaustion Flood',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1071: {
		techniqueId: 'T1071',
		techniqueName: 'Application Layer Protocol',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1138: {
		techniqueId: 'T1138',
		techniqueName: 'Application Shimming',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.011': {
		techniqueId: 'T1546.011',
		techniqueName: 'Application Shimming',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1010: {
		techniqueId: 'T1010',
		techniqueName: 'Application Window Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1499.004': {
		techniqueId: 'T1499.004',
		techniqueName: 'Application or System Exploitation',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1560: {
		techniqueId: 'T1560',
		techniqueName: 'Archive Collected Data',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1560.003': {
		techniqueId: 'T1560.003',
		techniqueName: 'Archive via Custom Method',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1560.002': {
		techniqueId: 'T1560.002',
		techniqueName: 'Archive via Library',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1560.001': {
		techniqueId: 'T1560.001',
		techniqueName: 'Archive via Utility',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1573.002': {
		techniqueId: 'T1573.002',
		techniqueName: 'Asymmetric Cryptography',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1055.004': {
		techniqueId: 'T1055.004',
		techniqueName: 'Asynchronous Procedure Call',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1053.001': {
		techniqueId: 'T1053.001',
		techniqueName: 'At (Linux)',
		techniqueCategories: ['Execution', 'Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Execution', 'Persistence', 'Privilege Escalation'],
	},
	'T1053.002': {
		techniqueId: 'T1053.002',
		techniqueName: 'At (Windows)',
		techniqueCategories: ['Execution', 'Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Execution', 'Persistence', 'Privilege Escalation'],
	},
	T1123: {
		techniqueId: 'T1123',
		techniqueName: 'Audio Capture',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1131: {
		techniqueId: 'T1131',
		techniqueName: 'Authentication Package',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.002': {
		techniqueId: 'T1547.002',
		techniqueName: 'Authentication Package',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1119: {
		techniqueId: 'T1119',
		techniqueName: 'Automated Collection',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1020: {
		techniqueId: 'T1020',
		techniqueName: 'Automated Exfiltration',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	T1197: {
		techniqueId: 'T1197',
		techniqueName: 'BITS Jobs',
		techniqueCategories: ['DefenseEvasion', 'Persistence'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Persistence'],
	},
	T1139: {
		techniqueId: 'T1139',
		techniqueName: 'Bash History',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1552.003': {
		techniqueId: 'T1552.003',
		techniqueName: 'Bash History',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1102.002': {
		techniqueId: 'T1102.002',
		techniqueName: 'Bidirectional Communication',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1009: {
		techniqueId: 'T1009',
		techniqueName: 'Binary Padding',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1027.001': {
		techniqueId: 'T1027.001',
		techniqueName: 'Binary Padding',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1547: {
		techniqueId: 'T1547',
		techniqueName: 'Boot or Logon Autostart Execution',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1037: {
		techniqueId: 'T1037',
		techniqueName: 'Boot or Logon Initialization Scripts',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1067: {
		techniqueId: 'T1067',
		techniqueName: 'Bootkit',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1542.003': {
		techniqueId: 'T1542.003',
		techniqueName: 'Bootkit',
		techniqueCategories: ['Persistence', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Defense Evasion'],
	},
	'T1583.005': {
		techniqueId: 'T1583.005',
		techniqueName: 'Botnet',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1584.005': {
		techniqueId: 'T1584.005',
		techniqueName: 'Botnet',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1217: {
		techniqueId: 'T1217',
		techniqueName: 'Browser Bookmark Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1176: {
		techniqueId: 'T1176',
		techniqueName: 'Browser Extensions',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	T1110: {
		techniqueId: 'T1110',
		techniqueName: 'Brute Force',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1612: {
		techniqueId: 'T1612',
		techniqueName: 'Build Image on Host',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1591.002': {
		techniqueId: 'T1591.002',
		techniqueName: 'Business Relationships',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1088: {
		techniqueId: 'T1088',
		techniqueName: 'Bypass User Account Control',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1548.002': {
		techniqueId: 'T1548.002',
		techniqueName: 'Bypass User Account Control',
		techniqueCategories: ['PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Defense Evasion'],
	},
	'T1596.004': {
		techniqueId: 'T1596.004',
		techniqueName: 'CDNs',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1191: {
		techniqueId: 'T1191',
		techniqueName: 'CMSTP',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1218.003': {
		techniqueId: 'T1218.003',
		techniqueName: 'CMSTP',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1574.012': {
		techniqueId: 'T1574.012',
		techniqueName: 'COR_PROFILER',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	'T1003.005': {
		techniqueId: 'T1003.005',
		techniqueName: 'Cached Domain Credentials',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1042: {
		techniqueId: 'T1042',
		techniqueName: 'Change Default File Association',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.001': {
		techniqueId: 'T1546.001',
		techniqueName: 'Change Default File Association',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1146: {
		techniqueId: 'T1146',
		techniqueName: 'Clear Command History',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1070.003': {
		techniqueId: 'T1070.003',
		techniqueName: 'Clear Command History',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1070.002': {
		techniqueId: 'T1070.002',
		techniqueName: 'Clear Linux or Mac System Logs',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1070.001': {
		techniqueId: 'T1070.001',
		techniqueName: 'Clear Windows Event Logs',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1592.004': {
		techniqueId: 'T1592.004',
		techniqueName: 'Client Configurations',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1115: {
		techniqueId: 'T1115',
		techniqueName: 'Clipboard Data',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1136.003': {
		techniqueId: 'T1136.003',
		techniqueName: 'Cloud Account',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1087.004': {
		techniqueId: 'T1087.004',
		techniqueName: 'Cloud Account',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1078.004': {
		techniqueId: 'T1078.004',
		techniqueName: 'Cloud Accounts',
		techniqueCategories: ['DefenseEvasion', 'Persistence', 'PrivilegeEscalation', 'InitialAccess'],
		techniqueCategoriesDisplay: [
			'Defense Evasion',
			'Persistence',
			'Privilege Escalation',
			'Initial Access',
		],
	},
	'T1069.003': {
		techniqueId: 'T1069.003',
		techniqueName: 'Cloud Groups',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1580: {
		techniqueId: 'T1580',
		techniqueName: 'Cloud Infrastructure Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1522: {
		techniqueId: 'T1522',
		techniqueName: 'Cloud Instance Metadata API',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1552.005': {
		techniqueId: 'T1552.005',
		techniqueName: 'Cloud Instance Metadata API',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1538: {
		techniqueId: 'T1538',
		techniqueName: 'Cloud Service Dashboard',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1526: {
		techniqueId: 'T1526',
		techniqueName: 'Cloud Service Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1619: {
		techniqueId: 'T1619',
		techniqueName: 'Cloud Storage Object Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1213.003': {
		techniqueId: 'T1213.003',
		techniqueName: 'Code Repositories',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1116: {
		techniqueId: 'T1116',
		techniqueName: 'Code Signing',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1553.002': {
		techniqueId: 'T1553.002',
		techniqueName: 'Code Signing',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1587.002': {
		techniqueId: 'T1587.002',
		techniqueName: 'Code Signing Certificates',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1588.003': {
		techniqueId: 'T1588.003',
		techniqueName: 'Code Signing Certificates',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1553.006': {
		techniqueId: 'T1553.006',
		techniqueName: 'Code Signing Policy Modification',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1059: {
		techniqueId: 'T1059',
		techniqueName: 'Command and Scripting Interpreter',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1043: {
		techniqueId: 'T1043',
		techniqueName: 'Commonly Used Port',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1092: {
		techniqueId: 'T1092',
		techniqueName: 'Communication Through Removable Media',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1500: {
		techniqueId: 'T1500',
		techniqueName: 'Compile After Delivery',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1027.004': {
		techniqueId: 'T1027.004',
		techniqueName: 'Compile After Delivery',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1223: {
		techniqueId: 'T1223',
		techniqueName: 'Compiled HTML File',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1218.001': {
		techniqueId: 'T1218.001',
		techniqueName: 'Compiled HTML File',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1109: {
		techniqueId: 'T1109',
		techniqueName: 'Component Firmware',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1542.002': {
		techniqueId: 'T1542.002',
		techniqueName: 'Component Firmware',
		techniqueCategories: ['Persistence', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Defense Evasion'],
	},
	'T1559.001': {
		techniqueId: 'T1559.001',
		techniqueName: 'Component Object Model',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1122: {
		techniqueId: 'T1122',
		techniqueName: 'Component Object Model Hijacking',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.015': {
		techniqueId: 'T1546.015',
		techniqueName: 'Component Object Model Hijacking',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1175: {
		techniqueId: 'T1175',
		techniqueName: 'Component Object Model and Distributed COM',
		techniqueCategories: ['LateralMovement', 'Execution'],
		techniqueCategoriesDisplay: ['Lateral Movement', 'Execution'],
	},
	T1586: {
		techniqueId: 'T1586',
		techniqueName: 'Compromise Accounts',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1554: {
		techniqueId: 'T1554',
		techniqueName: 'Compromise Client Software Binary',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1195.003': {
		techniqueId: 'T1195.003',
		techniqueName: 'Compromise Hardware Supply Chain',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	T1584: {
		techniqueId: 'T1584',
		techniqueName: 'Compromise Infrastructure',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1195.001': {
		techniqueId: 'T1195.001',
		techniqueName: 'Compromise Software Dependencies and Development Tools',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	'T1195.002': {
		techniqueId: 'T1195.002',
		techniqueName: 'Compromise Software Supply Chain',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	'T1213.001': {
		techniqueId: 'T1213.001',
		techniqueName: 'Confluence',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1552.007': {
		techniqueId: 'T1552.007',
		techniqueName: 'Container API',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1609: {
		techniqueId: 'T1609',
		techniqueName: 'Container Administration Command',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1053.007': {
		techniqueId: 'T1053.007',
		techniqueName: 'Container Orchestration Job',
		techniqueCategories: ['Execution', 'Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Execution', 'Persistence', 'Privilege Escalation'],
	},
	T1613: {
		techniqueId: 'T1613',
		techniqueName: 'Container and Resource Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1218.002': {
		techniqueId: 'T1218.002',
		techniqueName: 'Control Panel',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1196: {
		techniqueId: 'T1196',
		techniqueName: 'Control Panel Items',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1136: {
		techniqueId: 'T1136',
		techniqueName: 'Create Account',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1578.002': {
		techniqueId: 'T1578.002',
		techniqueName: 'Create Cloud Instance',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1134.002': {
		techniqueId: 'T1134.002',
		techniqueName: 'Create Process with Token',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1578.001': {
		techniqueId: 'T1578.001',
		techniqueName: 'Create Snapshot',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1543: {
		techniqueId: 'T1543',
		techniqueName: 'Create or Modify System Process',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1056.004': {
		techniqueId: 'T1056.004',
		techniqueName: 'Credential API Hooking',
		techniqueCategories: ['Collection', 'CredentialAccess'],
		techniqueCategoriesDisplay: ['Collection', 'Credential Access'],
	},
	'T1110.004': {
		techniqueId: 'T1110.004',
		techniqueName: 'Credential Stuffing',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1589.001': {
		techniqueId: 'T1589.001',
		techniqueName: 'Credentials',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1552.001': {
		techniqueId: 'T1552.001',
		techniqueName: 'Credentials In Files',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1555: {
		techniqueId: 'T1555',
		techniqueName: 'Credentials from Password Stores',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1503: {
		techniqueId: 'T1503',
		techniqueName: 'Credentials from Web Browsers',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1555.003': {
		techniqueId: 'T1555.003',
		techniqueName: 'Credentials from Web Browsers',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1081: {
		techniqueId: 'T1081',
		techniqueName: 'Credentials in Files',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1214: {
		techniqueId: 'T1214',
		techniqueName: 'Credentials in Registry',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1552.002': {
		techniqueId: 'T1552.002',
		techniqueName: 'Credentials in Registry',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1053.003': {
		techniqueId: 'T1053.003',
		techniqueName: 'Cron',
		techniqueCategories: ['Execution', 'Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Execution', 'Persistence', 'Privilege Escalation'],
	},
	T1094: {
		techniqueId: 'T1094',
		techniqueName: 'Custom Command and Control Protocol',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1024: {
		techniqueId: 'T1024',
		techniqueName: 'Custom Cryptographic Protocol',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1003.006': {
		techniqueId: 'T1003.006',
		techniqueName: 'DCSync',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1038: {
		techniqueId: 'T1038',
		techniqueName: 'DLL Search Order Hijacking',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1574.001': {
		techniqueId: 'T1574.001',
		techniqueName: 'DLL Search Order Hijacking',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	T1073: {
		techniqueId: 'T1073',
		techniqueName: 'DLL Side-Loading',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1574.002': {
		techniqueId: 'T1574.002',
		techniqueName: 'DLL Side-Loading',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	'T1071.004': {
		techniqueId: 'T1071.004',
		techniqueName: 'DNS',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1590.002': {
		techniqueId: 'T1590.002',
		techniqueName: 'DNS',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1568.003': {
		techniqueId: 'T1568.003',
		techniqueName: 'DNS Calculation',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1583.002': {
		techniqueId: 'T1583.002',
		techniqueName: 'DNS Server',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1584.002': {
		techniqueId: 'T1584.002',
		techniqueName: 'DNS Server',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1596.001': {
		techniqueId: 'T1596.001',
		techniqueName: 'DNS/Passive DNS',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1002: {
		techniqueId: 'T1002',
		techniqueName: 'Data Compressed',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1485: {
		techniqueId: 'T1485',
		techniqueName: 'Data Destruction',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1132: {
		techniqueId: 'T1132',
		techniqueName: 'Data Encoding',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1022: {
		techniqueId: 'T1022',
		techniqueName: 'Data Encrypted',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1486: {
		techniqueId: 'T1486',
		techniqueName: 'Data Encrypted for Impact',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1565: {
		techniqueId: 'T1565',
		techniqueName: 'Data Manipulation',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1001: {
		techniqueId: 'T1001',
		techniqueName: 'Data Obfuscation',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1074: {
		techniqueId: 'T1074',
		techniqueName: 'Data Staged',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1030: {
		techniqueId: 'T1030',
		techniqueName: 'Data Transfer Size Limits',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	T1530: {
		techniqueId: 'T1530',
		techniqueName: 'Data from Cloud Storage Object',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1602: {
		techniqueId: 'T1602',
		techniqueName: 'Data from Configuration Repository',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1213: {
		techniqueId: 'T1213',
		techniqueName: 'Data from Information Repositories',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1005: {
		techniqueId: 'T1005',
		techniqueName: 'Data from Local System',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1039: {
		techniqueId: 'T1039',
		techniqueName: 'Data from Network Shared Drive',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1025: {
		techniqueId: 'T1025',
		techniqueName: 'Data from Removable Media',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1102.001': {
		techniqueId: 'T1102.001',
		techniqueName: 'Dead Drop Resolver',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1491: {
		techniqueId: 'T1491',
		techniqueName: 'Defacement',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	'T1078.001': {
		techniqueId: 'T1078.001',
		techniqueName: 'Default Accounts',
		techniqueCategories: ['DefenseEvasion', 'Persistence', 'PrivilegeEscalation', 'InitialAccess'],
		techniqueCategoriesDisplay: [
			'Defense Evasion',
			'Persistence',
			'Privilege Escalation',
			'Initial Access',
		],
	},
	'T1578.003': {
		techniqueId: 'T1578.003',
		techniqueName: 'Delete Cloud Instance',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1140: {
		techniqueId: 'T1140',
		techniqueName: 'Deobfuscate/Decode Files or Information',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1610: {
		techniqueId: 'T1610',
		techniqueName: 'Deploy Container',
		techniqueCategories: ['DefenseEvasion', 'Execution'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Execution'],
	},
	'T1591.001': {
		techniqueId: 'T1591.001',
		techniqueName: 'Determine Physical Locations',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1587: {
		techniqueId: 'T1587',
		techniqueName: 'Develop Capabilities',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1587.003': {
		techniqueId: 'T1587.003',
		techniqueName: 'Digital Certificates',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1588.004': {
		techniqueId: 'T1588.004',
		techniqueName: 'Digital Certificates',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1596.003': {
		techniqueId: 'T1596.003',
		techniqueName: 'Digital Certificates',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1498.001': {
		techniqueId: 'T1498.001',
		techniqueName: 'Direct Network Flood',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1006: {
		techniqueId: 'T1006',
		techniqueName: 'Direct Volume Access',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1562.008': {
		techniqueId: 'T1562.008',
		techniqueName: 'Disable Cloud Logs',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1600.002': {
		techniqueId: 'T1600.002',
		techniqueName: 'Disable Crypto Hardware',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1562.002': {
		techniqueId: 'T1562.002',
		techniqueName: 'Disable Windows Event Logging',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1562.007': {
		techniqueId: 'T1562.007',
		techniqueName: 'Disable or Modify Cloud Firewall',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1562.004': {
		techniqueId: 'T1562.004',
		techniqueName: 'Disable or Modify System Firewall',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1562.001': {
		techniqueId: 'T1562.001',
		techniqueName: 'Disable or Modify Tools',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1089: {
		techniqueId: 'T1089',
		techniqueName: 'Disabling Security Tools',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1488: {
		techniqueId: 'T1488',
		techniqueName: 'Disk Content Wipe',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1561.001': {
		techniqueId: 'T1561.001',
		techniqueName: 'Disk Content Wipe',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1487: {
		techniqueId: 'T1487',
		techniqueName: 'Disk Structure Wipe',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1561.002': {
		techniqueId: 'T1561.002',
		techniqueName: 'Disk Structure Wipe',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1561: {
		techniqueId: 'T1561',
		techniqueName: 'Disk Wipe',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	'T1021.003': {
		techniqueId: 'T1021.003',
		techniqueName: 'Distributed Component Object Model',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	'T1136.002': {
		techniqueId: 'T1136.002',
		techniqueName: 'Domain Account',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1087.002': {
		techniqueId: 'T1087.002',
		techniqueName: 'Domain Account',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1078.002': {
		techniqueId: 'T1078.002',
		techniqueName: 'Domain Accounts',
		techniqueCategories: ['DefenseEvasion', 'Persistence', 'PrivilegeEscalation', 'InitialAccess'],
		techniqueCategoriesDisplay: [
			'Defense Evasion',
			'Persistence',
			'Privilege Escalation',
			'Initial Access',
		],
	},
	'T1556.001': {
		techniqueId: 'T1556.001',
		techniqueName: 'Domain Controller Authentication',
		techniqueCategories: ['CredentialAccess', 'DefenseEvasion', 'Persistence'],
		techniqueCategoriesDisplay: ['Credential Access', 'Defense Evasion', 'Persistence'],
	},
	T1172: {
		techniqueId: 'T1172',
		techniqueName: 'Domain Fronting',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1090.004': {
		techniqueId: 'T1090.004',
		techniqueName: 'Domain Fronting',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1483: {
		techniqueId: 'T1483',
		techniqueName: 'Domain Generation Algorithms',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1568.002': {
		techniqueId: 'T1568.002',
		techniqueName: 'Domain Generation Algorithms',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1069.002': {
		techniqueId: 'T1069.002',
		techniqueName: 'Domain Groups',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1484: {
		techniqueId: 'T1484',
		techniqueName: 'Domain Policy Modification',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1590.001': {
		techniqueId: 'T1590.001',
		techniqueName: 'Domain Properties',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1482: {
		techniqueId: 'T1482',
		techniqueName: 'Domain Trust Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1484.002': {
		techniqueId: 'T1484.002',
		techniqueName: 'Domain Trust Modification',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1583.001': {
		techniqueId: 'T1583.001',
		techniqueName: 'Domains',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1584.001': {
		techniqueId: 'T1584.001',
		techniqueName: 'Domains',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1036.007': {
		techniqueId: 'T1036.007',
		techniqueName: 'Double File Extension',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1562.010': {
		techniqueId: 'T1562.010',
		techniqueName: 'Downgrade Attack',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1601.002': {
		techniqueId: 'T1601.002',
		techniqueName: 'Downgrade System Image',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1189: {
		techniqueId: 'T1189',
		techniqueName: 'Drive-by Compromise',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	'T1608.004': {
		techniqueId: 'T1608.004',
		techniqueName: 'Drive-by Target',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1157: {
		techniqueId: 'T1157',
		techniqueName: 'Dylib Hijacking',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1574.004': {
		techniqueId: 'T1574.004',
		techniqueName: 'Dylib Hijacking',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	T1173: {
		techniqueId: 'T1173',
		techniqueName: 'Dynamic Data Exchange',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1559.002': {
		techniqueId: 'T1559.002',
		techniqueName: 'Dynamic Data Exchange',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1574.006': {
		techniqueId: 'T1574.006',
		techniqueName: 'Dynamic Linker Hijacking',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	T1568: {
		techniqueId: 'T1568',
		techniqueName: 'Dynamic Resolution',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1055.001': {
		techniqueId: 'T1055.001',
		techniqueName: 'Dynamic-link Library Injection',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	T1514: {
		techniqueId: 'T1514',
		techniqueName: 'Elevated Execution with Prompt',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1548.004': {
		techniqueId: 'T1548.004',
		techniqueName: 'Elevated Execution with Prompt',
		techniqueCategories: ['PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Defense Evasion'],
	},
	'T1087.003': {
		techniqueId: 'T1087.003',
		techniqueName: 'Email Account',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1585.002': {
		techniqueId: 'T1585.002',
		techniqueName: 'Email Accounts',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1586.002': {
		techniqueId: 'T1586.002',
		techniqueName: 'Email Accounts',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1589.002': {
		techniqueId: 'T1589.002',
		techniqueName: 'Email Addresses',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1114: {
		techniqueId: 'T1114',
		techniqueName: 'Email Collection',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1114.003': {
		techniqueId: 'T1114.003',
		techniqueName: 'Email Forwarding Rule',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1564.008': {
		techniqueId: 'T1564.008',
		techniqueName: 'Email Hiding Rules',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1519: {
		techniqueId: 'T1519',
		techniqueName: 'Emond',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.014': {
		techniqueId: 'T1546.014',
		techniqueName: 'Emond',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	'T1589.003': {
		techniqueId: 'T1589.003',
		techniqueName: 'Employee Names',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1573: {
		techniqueId: 'T1573',
		techniqueName: 'Encrypted Channel',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1499: {
		techniqueId: 'T1499',
		techniqueName: 'Endpoint Denial of Service',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	'T1480.001': {
		techniqueId: 'T1480.001',
		techniqueName: 'Environmental Keying',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1611: {
		techniqueId: 'T1611',
		techniqueName: 'Escape to Host',
		techniqueCategories: ['PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Privilege Escalation'],
	},
	T1585: {
		techniqueId: 'T1585',
		techniqueName: 'Establish Accounts',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1546: {
		techniqueId: 'T1546',
		techniqueName: 'Event Triggered Execution',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	'T1098.002': {
		techniqueId: 'T1098.002',
		techniqueName: 'Exchange Email Delegate Permissions',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1574.005': {
		techniqueId: 'T1574.005',
		techniqueName: 'Executable Installer File Permissions Weakness',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	T1480: {
		techniqueId: 'T1480',
		techniqueName: 'Execution Guardrails',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1048: {
		techniqueId: 'T1048',
		techniqueName: 'Exfiltration Over Alternative Protocol',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	'T1048.002': {
		techniqueId: 'T1048.002',
		techniqueName: 'Exfiltration Over Asymmetric Encrypted Non-C2 Protocol',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	'T1011.001': {
		techniqueId: 'T1011.001',
		techniqueName: 'Exfiltration Over Bluetooth',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	T1041: {
		techniqueId: 'T1041',
		techniqueName: 'Exfiltration Over C2 Channel',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	T1011: {
		techniqueId: 'T1011',
		techniqueName: 'Exfiltration Over Other Network Medium',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	T1052: {
		techniqueId: 'T1052',
		techniqueName: 'Exfiltration Over Physical Medium',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	'T1048.001': {
		techniqueId: 'T1048.001',
		techniqueName: 'Exfiltration Over Symmetric Encrypted Non-C2 Protocol',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	'T1048.003': {
		techniqueId: 'T1048.003',
		techniqueName: 'Exfiltration Over Unencrypted/Obfuscated Non-C2 Protocol',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	T1567: {
		techniqueId: 'T1567',
		techniqueName: 'Exfiltration Over Web Service',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	'T1052.001': {
		techniqueId: 'T1052.001',
		techniqueName: 'Exfiltration over USB',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	'T1567.002': {
		techniqueId: 'T1567.002',
		techniqueName: 'Exfiltration to Cloud Storage',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	'T1567.001': {
		techniqueId: 'T1567.001',
		techniqueName: 'Exfiltration to Code Repository',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	T1190: {
		techniqueId: 'T1190',
		techniqueName: 'Exploit Public-Facing Application',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	T1203: {
		techniqueId: 'T1203',
		techniqueName: 'Exploitation for Client Execution',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1212: {
		techniqueId: 'T1212',
		techniqueName: 'Exploitation for Credential Access',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1211: {
		techniqueId: 'T1211',
		techniqueName: 'Exploitation for Defense Evasion',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1068: {
		techniqueId: 'T1068',
		techniqueName: 'Exploitation for Privilege Escalation',
		techniqueCategories: ['PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Privilege Escalation'],
	},
	T1210: {
		techniqueId: 'T1210',
		techniqueName: 'Exploitation of Remote Services',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	'T1587.004': {
		techniqueId: 'T1587.004',
		techniqueName: 'Exploits',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1588.005': {
		techniqueId: 'T1588.005',
		techniqueName: 'Exploits',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1491.002': {
		techniqueId: 'T1491.002',
		techniqueName: 'External Defacement',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	'T1090.002': {
		techniqueId: 'T1090.002',
		techniqueName: 'External Proxy',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1133: {
		techniqueId: 'T1133',
		techniqueName: 'External Remote Services',
		techniqueCategories: ['Persistence', 'InitialAccess'],
		techniqueCategoriesDisplay: ['Persistence', 'Initial Access'],
	},
	T1181: {
		techniqueId: 'T1181',
		techniqueName: 'Extra Window Memory Injection',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1055.011': {
		techniqueId: 'T1055.011',
		techniqueName: 'Extra Window Memory Injection',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	T1008: {
		techniqueId: 'T1008',
		techniqueName: 'Fallback Channels',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1568.001': {
		techniqueId: 'T1568.001',
		techniqueName: 'Fast Flux DNS',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1107: {
		techniqueId: 'T1107',
		techniqueName: 'File Deletion',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1070.004': {
		techniqueId: 'T1070.004',
		techniqueName: 'File Deletion',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1044: {
		techniqueId: 'T1044',
		techniqueName: 'File System Permissions Weakness',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1071.002': {
		techniqueId: 'T1071.002',
		techniqueName: 'File Transfer Protocols',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1083: {
		techniqueId: 'T1083',
		techniqueName: 'File and Directory Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1222: {
		techniqueId: 'T1222',
		techniqueName: 'File and Directory Permissions Modification',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1592.003': {
		techniqueId: 'T1592.003',
		techniqueName: 'Firmware',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1495: {
		techniqueId: 'T1495',
		techniqueName: 'Firmware Corruption',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1187: {
		techniqueId: 'T1187',
		techniqueName: 'Forced Authentication',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1606: {
		techniqueId: 'T1606',
		techniqueName: 'Forge Web Credentials',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1056.002': {
		techniqueId: 'T1056.002',
		techniqueName: 'GUI Input Capture',
		techniqueCategories: ['Collection', 'CredentialAccess'],
		techniqueCategoriesDisplay: ['Collection', 'Credential Access'],
	},
	T1144: {
		techniqueId: 'T1144',
		techniqueName: 'Gatekeeper Bypass',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1553.001': {
		techniqueId: 'T1553.001',
		techniqueName: 'Gatekeeper Bypass',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1592: {
		techniqueId: 'T1592',
		techniqueName: 'Gather Victim Host Information',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1589: {
		techniqueId: 'T1589',
		techniqueName: 'Gather Victim Identity Information',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1590: {
		techniqueId: 'T1590',
		techniqueName: 'Gather Victim Network Information',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1591: {
		techniqueId: 'T1591',
		techniqueName: 'Gather Victim Org Information',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1558.001': {
		techniqueId: 'T1558.001',
		techniqueName: 'Golden Ticket',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1061: {
		techniqueId: 'T1061',
		techniqueName: 'Graphical User Interface',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1615: {
		techniqueId: 'T1615',
		techniqueName: 'Group Policy Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1484.001': {
		techniqueId: 'T1484.001',
		techniqueName: 'Group Policy Modification',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1552.006': {
		techniqueId: 'T1552.006',
		techniqueName: 'Group Policy Preferences',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1148: {
		techniqueId: 'T1148',
		techniqueName: 'HISTCONTROL',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1027.006': {
		techniqueId: 'T1027.006',
		techniqueName: 'HTML Smuggling',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1592.001': {
		techniqueId: 'T1592.001',
		techniqueName: 'Hardware',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1200: {
		techniqueId: 'T1200',
		techniqueName: 'Hardware Additions',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	'T1564.005': {
		techniqueId: 'T1564.005',
		techniqueName: 'Hidden File System',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1158: {
		techniqueId: 'T1158',
		techniqueName: 'Hidden Files and Directories',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1564.001': {
		techniqueId: 'T1564.001',
		techniqueName: 'Hidden Files and Directories',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1147: {
		techniqueId: 'T1147',
		techniqueName: 'Hidden Users',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1564.002': {
		techniqueId: 'T1564.002',
		techniqueName: 'Hidden Users',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1143: {
		techniqueId: 'T1143',
		techniqueName: 'Hidden Window',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1564.003': {
		techniqueId: 'T1564.003',
		techniqueName: 'Hidden Window',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1564: {
		techniqueId: 'T1564',
		techniqueName: 'Hide Artifacts',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1574: {
		techniqueId: 'T1574',
		techniqueName: 'Hijack Execution Flow',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	T1179: {
		techniqueId: 'T1179',
		techniqueName: 'Hooking',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1062: {
		techniqueId: 'T1062',
		techniqueName: 'Hypervisor',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1505.004': {
		techniqueId: 'T1505.004',
		techniqueName: 'IIS Components',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1590.005': {
		techniqueId: 'T1590.005',
		techniqueName: 'IP Addresses',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1591.003': {
		techniqueId: 'T1591.003',
		techniqueName: 'Identify Business Tempo',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1591.004': {
		techniqueId: 'T1591.004',
		techniqueName: 'Identify Roles',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1183: {
		techniqueId: 'T1183',
		techniqueName: 'Image File Execution Options Injection',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.012': {
		techniqueId: 'T1546.012',
		techniqueName: 'Image File Execution Options Injection',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	'T1562.003': {
		techniqueId: 'T1562.003',
		techniqueName: 'Impair Command History Logging',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1562: {
		techniqueId: 'T1562',
		techniqueName: 'Impair Defenses',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1525: {
		techniqueId: 'T1525',
		techniqueName: 'Implant Internal Image',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	T1054: {
		techniqueId: 'T1054',
		techniqueName: 'Indicator Blocking',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1562.006': {
		techniqueId: 'T1562.006',
		techniqueName: 'Indicator Blocking',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1066: {
		techniqueId: 'T1066',
		techniqueName: 'Indicator Removal from Tools',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1027.005': {
		techniqueId: 'T1027.005',
		techniqueName: 'Indicator Removal from Tools',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1070: {
		techniqueId: 'T1070',
		techniqueName: 'Indicator Removal on Host',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1202: {
		techniqueId: 'T1202',
		techniqueName: 'Indirect Command Execution',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1105: {
		techniqueId: 'T1105',
		techniqueName: 'Ingress Tool Transfer',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1490: {
		techniqueId: 'T1490',
		techniqueName: 'Inhibit System Recovery',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1056: {
		techniqueId: 'T1056',
		techniqueName: 'Input Capture',
		techniqueCategories: ['Collection', 'CredentialAccess'],
		techniqueCategoriesDisplay: ['Collection', 'Credential Access'],
	},
	T1141: {
		techniqueId: 'T1141',
		techniqueName: 'Input Prompt',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1608.003': {
		techniqueId: 'T1608.003',
		techniqueName: 'Install Digital Certificate',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1130: {
		techniqueId: 'T1130',
		techniqueName: 'Install Root Certificate',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1553.004': {
		techniqueId: 'T1553.004',
		techniqueName: 'Install Root Certificate',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1118: {
		techniqueId: 'T1118',
		techniqueName: 'InstallUtil',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1218.004': {
		techniqueId: 'T1218.004',
		techniqueName: 'InstallUtil',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1559: {
		techniqueId: 'T1559',
		techniqueName: 'Inter-Process Communication',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1491.001': {
		techniqueId: 'T1491.001',
		techniqueName: 'Internal Defacement',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	'T1090.001': {
		techniqueId: 'T1090.001',
		techniqueName: 'Internal Proxy',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1534: {
		techniqueId: 'T1534',
		techniqueName: 'Internal Spearphishing',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	'T1016.001': {
		techniqueId: 'T1016.001',
		techniqueName: 'Internet Connection Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1036.001': {
		techniqueId: 'T1036.001',
		techniqueName: 'Invalid Code Signature',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1059.007': {
		techniqueId: 'T1059.007',
		techniqueName: 'JavaScript',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1001.001': {
		techniqueId: 'T1001.001',
		techniqueName: 'Junk Data',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1208: {
		techniqueId: 'T1208',
		techniqueName: 'Kerberoasting',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1558.003': {
		techniqueId: 'T1558.003',
		techniqueName: 'Kerberoasting',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1215: {
		techniqueId: 'T1215',
		techniqueName: 'Kernel Modules and Extensions',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.006': {
		techniqueId: 'T1547.006',
		techniqueName: 'Kernel Modules and Extensions',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1142: {
		techniqueId: 'T1142',
		techniqueName: 'Keychain',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1555.001': {
		techniqueId: 'T1555.001',
		techniqueName: 'Keychain',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1056.001': {
		techniqueId: 'T1056.001',
		techniqueName: 'Keylogging',
		techniqueCategories: ['Collection', 'CredentialAccess'],
		techniqueCategoriesDisplay: ['Collection', 'Credential Access'],
	},
	T1161: {
		techniqueId: 'T1161',
		techniqueName: 'LC_LOAD_DYLIB Addition',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.006': {
		techniqueId: 'T1546.006',
		techniqueName: 'LC_LOAD_DYLIB Addition',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1149: {
		techniqueId: 'T1149',
		techniqueName: 'LC_MAIN Hijacking',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1171: {
		techniqueId: 'T1171',
		techniqueName: 'LLMNR/NBT-NS Poisoning and Relay',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1557.001': {
		techniqueId: 'T1557.001',
		techniqueName: 'LLMNR/NBT-NS Poisoning and SMB Relay',
		techniqueCategories: ['CredentialAccess', 'Collection'],
		techniqueCategoriesDisplay: ['Credential Access', 'Collection'],
	},
	'T1003.004': {
		techniqueId: 'T1003.004',
		techniqueName: 'LSA Secrets',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1177: {
		techniqueId: 'T1177',
		techniqueName: 'LSASS Driver',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.008': {
		techniqueId: 'T1547.008',
		techniqueName: 'LSASS Driver',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1003.001': {
		techniqueId: 'T1003.001',
		techniqueName: 'LSASS Memory',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1570: {
		techniqueId: 'T1570',
		techniqueName: 'Lateral Tool Transfer',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	T1159: {
		techniqueId: 'T1159',
		techniqueName: 'Launch Agent',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1543.001': {
		techniqueId: 'T1543.001',
		techniqueName: 'Launch Agent',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1160: {
		techniqueId: 'T1160',
		techniqueName: 'Launch Daemon',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1543.004': {
		techniqueId: 'T1543.004',
		techniqueName: 'Launch Daemon',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1152: {
		techniqueId: 'T1152',
		techniqueName: 'Launchctl',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1569.001': {
		techniqueId: 'T1569.001',
		techniqueName: 'Launchctl',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1053.004': {
		techniqueId: 'T1053.004',
		techniqueName: 'Launchd',
		techniqueCategories: ['Execution', 'Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Execution', 'Persistence', 'Privilege Escalation'],
	},
	'T1608.005': {
		techniqueId: 'T1608.005',
		techniqueName: 'Link Target',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1222.002': {
		techniqueId: 'T1222.002',
		techniqueName: 'Linux and Mac File and Directory Permissions Modification',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1136.001': {
		techniqueId: 'T1136.001',
		techniqueName: 'Local Account',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1087.001': {
		techniqueId: 'T1087.001',
		techniqueName: 'Local Account',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1078.003': {
		techniqueId: 'T1078.003',
		techniqueName: 'Local Accounts',
		techniqueCategories: ['DefenseEvasion', 'Persistence', 'PrivilegeEscalation', 'InitialAccess'],
		techniqueCategoriesDisplay: [
			'Defense Evasion',
			'Persistence',
			'Privilege Escalation',
			'Initial Access',
		],
	},
	'T1074.001': {
		techniqueId: 'T1074.001',
		techniqueName: 'Local Data Staging',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1114.001': {
		techniqueId: 'T1114.001',
		techniqueName: 'Local Email Collection',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1069.001': {
		techniqueId: 'T1069.001',
		techniqueName: 'Local Groups',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1168: {
		techniqueId: 'T1168',
		techniqueName: 'Local Job Scheduling',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1162: {
		techniqueId: 'T1162',
		techniqueName: 'Login Item',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.015': {
		techniqueId: 'T1547.015',
		techniqueName: 'Login Items',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1037.002': {
		techniqueId: 'T1037.002',
		techniqueName: 'Logon Script (Mac)',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1037.001': {
		techniqueId: 'T1037.001',
		techniqueName: 'Logon Script (Windows)',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1218.014': {
		techniqueId: 'T1218.014',
		techniqueName: 'MMC',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1127.001': {
		techniqueId: 'T1127.001',
		techniqueName: 'MSBuild',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1071.003': {
		techniqueId: 'T1071.003',
		techniqueName: 'Mail Protocols',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1134.003': {
		techniqueId: 'T1134.003',
		techniqueName: 'Make and Impersonate Token',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1204.002': {
		techniqueId: 'T1204.002',
		techniqueName: 'Malicious File',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1204.003': {
		techniqueId: 'T1204.003',
		techniqueName: 'Malicious Image',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1204.001': {
		techniqueId: 'T1204.001',
		techniqueName: 'Malicious Link',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1156: {
		techniqueId: 'T1156',
		techniqueName: 'Malicious Shell Modification',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1587.001': {
		techniqueId: 'T1587.001',
		techniqueName: 'Malware',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1588.001': {
		techniqueId: 'T1588.001',
		techniqueName: 'Malware',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1185: {
		techniqueId: 'T1185',
		techniqueName: 'Browser Session Hijacking',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1557: {
		techniqueId: 'T1557',
		techniqueName: 'Adversary-in-the-Middle',
		techniqueCategories: ['CredentialAccess', 'Collection'],
		techniqueCategoriesDisplay: ['Credential Access', 'Collection'],
	},
	'T1553.005': {
		techniqueId: 'T1553.005',
		techniqueName: 'Mark-of-the-Web Bypass',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1036.004': {
		techniqueId: 'T1036.004',
		techniqueName: 'Masquerade Task or Service',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1036: {
		techniqueId: 'T1036',
		techniqueName: 'Masquerading',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1036.005': {
		techniqueId: 'T1036.005',
		techniqueName: 'Match Legitimate Name or Location',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1218.013': {
		techniqueId: 'T1218.013',
		techniqueName: 'Mavinject',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1556: {
		techniqueId: 'T1556',
		techniqueName: 'Modify Authentication Process',
		techniqueCategories: ['CredentialAccess', 'DefenseEvasion', 'Persistence'],
		techniqueCategoriesDisplay: ['Credential Access', 'Defense Evasion', 'Persistence'],
	},
	T1578: {
		techniqueId: 'T1578',
		techniqueName: 'Modify Cloud Compute Infrastructure',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1031: {
		techniqueId: 'T1031',
		techniqueName: 'Modify Existing Service',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1112: {
		techniqueId: 'T1112',
		techniqueName: 'Modify Registry',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1601: {
		techniqueId: 'T1601',
		techniqueName: 'Modify System Image',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1170: {
		techniqueId: 'T1170',
		techniqueName: 'Mshta',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1218.005': {
		techniqueId: 'T1218.005',
		techniqueName: 'Mshta',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1218.007': {
		techniqueId: 'T1218.007',
		techniqueName: 'Msiexec',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1104: {
		techniqueId: 'T1104',
		techniqueName: 'Multi-Stage Channels',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1188: {
		techniqueId: 'T1188',
		techniqueName: 'Multi-hop Proxy',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1090.003': {
		techniqueId: 'T1090.003',
		techniqueName: 'Multi-hop Proxy',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1026: {
		techniqueId: 'T1026',
		techniqueName: 'Multiband Communication',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1079: {
		techniqueId: 'T1079',
		techniqueName: 'Multilayer Encryption',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1003.003': {
		techniqueId: 'T1003.003',
		techniqueName: 'NTDS',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1096: {
		techniqueId: 'T1096',
		techniqueName: 'NTFS File Attributes',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1564.004': {
		techniqueId: 'T1564.004',
		techniqueName: 'NTFS File Attributes',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1106: {
		techniqueId: 'T1106',
		techniqueName: 'Native API',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1128: {
		techniqueId: 'T1128',
		techniqueName: 'Netsh Helper DLL',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.007': {
		techniqueId: 'T1546.007',
		techniqueName: 'Netsh Helper DLL',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	'T1599.001': {
		techniqueId: 'T1599.001',
		techniqueName: 'Network Address Translation Traversal',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1599: {
		techniqueId: 'T1599',
		techniqueName: 'Network Boundary Bridging',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1498: {
		techniqueId: 'T1498',
		techniqueName: 'Network Denial of Service',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	'T1556.004': {
		techniqueId: 'T1556.004',
		techniqueName: 'Network Device Authentication',
		techniqueCategories: ['CredentialAccess', 'DefenseEvasion', 'Persistence'],
		techniqueCategoriesDisplay: ['Credential Access', 'Defense Evasion', 'Persistence'],
	},
	'T1059.008': {
		techniqueId: 'T1059.008',
		techniqueName: 'Network Device CLI',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1602.002': {
		techniqueId: 'T1602.002',
		techniqueName: 'Network Device Configuration Dump',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1037.003': {
		techniqueId: 'T1037.003',
		techniqueName: 'Network Logon Script',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1590.006': {
		techniqueId: 'T1590.006',
		techniqueName: 'Network Security Appliances',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1046: {
		techniqueId: 'T1046',
		techniqueName: 'Network Service Scanning',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1126: {
		techniqueId: 'T1126',
		techniqueName: 'Network Share Connection Removal',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1070.005': {
		techniqueId: 'T1070.005',
		techniqueName: 'Network Share Connection Removal',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1135: {
		techniqueId: 'T1135',
		techniqueName: 'Network Share Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1040: {
		techniqueId: 'T1040',
		techniqueName: 'Network Sniffing',
		techniqueCategories: ['CredentialAccess', 'Discovery'],
		techniqueCategoriesDisplay: ['Credential Access', 'Discovery'],
	},
	'T1590.004': {
		techniqueId: 'T1590.004',
		techniqueName: 'Network Topology',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1590.003': {
		techniqueId: 'T1590.003',
		techniqueName: 'Network Trust Dependencies',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1050: {
		techniqueId: 'T1050',
		techniqueName: 'New Service',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1095: {
		techniqueId: 'T1095',
		techniqueName: 'Non-Application Layer Protocol',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1132.002': {
		techniqueId: 'T1132.002',
		techniqueName: 'Non-Standard Encoding',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1571: {
		techniqueId: 'T1571',
		techniqueName: 'Non-Standard Port',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1003: {
		techniqueId: 'T1003',
		techniqueName: 'OS Credential Dumping',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1499.001': {
		techniqueId: 'T1499.001',
		techniqueName: 'OS Exhaustion Flood',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1027: {
		techniqueId: 'T1027',
		techniqueName: 'Obfuscated Files or Information',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1588: {
		techniqueId: 'T1588',
		techniqueName: 'Obtain Capabilities',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1218.008': {
		techniqueId: 'T1218.008',
		techniqueName: 'Odbcconf',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1137: {
		techniqueId: 'T1137',
		techniqueName: 'Office Application Startup',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1137.001': {
		techniqueId: 'T1137.001',
		techniqueName: 'Office Template Macros',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1137.002': {
		techniqueId: 'T1137.002',
		techniqueName: 'Office Test',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1102.003': {
		techniqueId: 'T1102.003',
		techniqueName: 'One-Way Communication',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1137.003': {
		techniqueId: 'T1137.003',
		techniqueName: 'Outlook Forms',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1137.004': {
		techniqueId: 'T1137.004',
		techniqueName: 'Outlook Home Page',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1137.005': {
		techniqueId: 'T1137.005',
		techniqueName: 'Outlook Rules',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	T1502: {
		techniqueId: 'T1502',
		techniqueName: 'Parent PID Spoofing',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1134.004': {
		techniqueId: 'T1134.004',
		techniqueName: 'Parent PID Spoofing',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	T1075: {
		techniqueId: 'T1075',
		techniqueName: 'Pass the Hash',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1550.002': {
		techniqueId: 'T1550.002',
		techniqueName: 'Pass the Hash',
		techniqueCategories: ['DefenseEvasion', 'LateralMovement'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Lateral Movement'],
	},
	T1097: {
		techniqueId: 'T1097',
		techniqueName: 'Pass the Ticket',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1550.003': {
		techniqueId: 'T1550.003',
		techniqueName: 'Pass the Ticket',
		techniqueCategories: ['DefenseEvasion', 'LateralMovement'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Lateral Movement'],
	},
	'T1110.002': {
		techniqueId: 'T1110.002',
		techniqueName: 'Password Cracking',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1174: {
		techniqueId: 'T1174',
		techniqueName: 'Password Filter DLL',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1556.002': {
		techniqueId: 'T1556.002',
		techniqueName: 'Password Filter DLL',
		techniqueCategories: ['CredentialAccess', 'DefenseEvasion', 'Persistence'],
		techniqueCategoriesDisplay: ['Credential Access', 'Defense Evasion', 'Persistence'],
	},
	'T1110.001': {
		techniqueId: 'T1110.001',
		techniqueName: 'Password Guessing',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1555.005': {
		techniqueId: 'T1555.005',
		techniqueName: 'Password Managers',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1201: {
		techniqueId: 'T1201',
		techniqueName: 'Password Policy Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1110.003': {
		techniqueId: 'T1110.003',
		techniqueName: 'Password Spraying',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1601.001': {
		techniqueId: 'T1601.001',
		techniqueName: 'Patch System Image',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1034: {
		techniqueId: 'T1034',
		techniqueName: 'Path Interception',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1574.007': {
		techniqueId: 'T1574.007',
		techniqueName: 'Path Interception by PATH Environment Variable',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	'T1574.008': {
		techniqueId: 'T1574.008',
		techniqueName: 'Path Interception by Search Order Hijacking',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	'T1574.009': {
		techniqueId: 'T1574.009',
		techniqueName: 'Path Interception by Unquoted Path',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	T1120: {
		techniqueId: 'T1120',
		techniqueName: 'Peripheral Device Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1069: {
		techniqueId: 'T1069',
		techniqueName: 'Permission Groups Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1566: {
		techniqueId: 'T1566',
		techniqueName: 'Phishing',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	T1598: {
		techniqueId: 'T1598',
		techniqueName: 'Phishing for Information',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1150: {
		techniqueId: 'T1150',
		techniqueName: 'Plist Modification',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.011': {
		techniqueId: 'T1547.011',
		techniqueName: 'Plist Modification',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1556.003': {
		techniqueId: 'T1556.003',
		techniqueName: 'Pluggable Authentication Modules',
		techniqueCategories: ['CredentialAccess', 'DefenseEvasion', 'Persistence'],
		techniqueCategoriesDisplay: ['Credential Access', 'Defense Evasion', 'Persistence'],
	},
	'T1205.001': {
		techniqueId: 'T1205.001',
		techniqueName: 'Port Knocking',
		techniqueCategories: ['DefenseEvasion', 'Persistence', 'CommandAndControl'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Persistence', 'Command And Control'],
	},
	T1013: {
		techniqueId: 'T1013',
		techniqueName: 'Port Monitors',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.010': {
		techniqueId: 'T1547.010',
		techniqueName: 'Port Monitors',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1055.002': {
		techniqueId: 'T1055.002',
		techniqueName: 'Portable Executable Injection',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	T1086: {
		techniqueId: 'T1086',
		techniqueName: 'PowerShell',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1059.001': {
		techniqueId: 'T1059.001',
		techniqueName: 'PowerShell',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1504: {
		techniqueId: 'T1504',
		techniqueName: 'PowerShell Profile',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.013': {
		techniqueId: 'T1546.013',
		techniqueName: 'PowerShell Profile',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1542: {
		techniqueId: 'T1542',
		techniqueName: 'Pre-OS Boot',
		techniqueCategories: ['DefenseEvasion', 'Persistence'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Persistence'],
	},
	'T1547.012': {
		techniqueId: 'T1547.012',
		techniqueName: 'Print Processors',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1145: {
		techniqueId: 'T1145',
		techniqueName: 'Private Keys',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1552.004': {
		techniqueId: 'T1552.004',
		techniqueName: 'Private Keys',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1003.007': {
		techniqueId: 'T1003.007',
		techniqueName: 'Proc Filesystem',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1055.009': {
		techniqueId: 'T1055.009',
		techniqueName: 'Proc Memory',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	T1057: {
		techniqueId: 'T1057',
		techniqueName: 'Process Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1186: {
		techniqueId: 'T1186',
		techniqueName: 'Process Doppelgänging',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1055.013': {
		techniqueId: 'T1055.013',
		techniqueName: 'Process Doppelgänging',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	T1093: {
		techniqueId: 'T1093',
		techniqueName: 'Process Hollowing',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1055.012': {
		techniqueId: 'T1055.012',
		techniqueName: 'Process Hollowing',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	T1055: {
		techniqueId: 'T1055',
		techniqueName: 'Process Injection',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1001.003': {
		techniqueId: 'T1001.003',
		techniqueName: 'Protocol Impersonation',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1572: {
		techniqueId: 'T1572',
		techniqueName: 'Protocol Tunneling',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1090: {
		techniqueId: 'T1090',
		techniqueName: 'Proxy',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1055.008': {
		techniqueId: 'T1055.008',
		techniqueName: 'Ptrace System Calls',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1216.001': {
		techniqueId: 'T1216.001',
		techniqueName: 'PubPrn',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1597.002': {
		techniqueId: 'T1597.002',
		techniqueName: 'Purchase Technical Data',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1059.006': {
		techniqueId: 'T1059.006',
		techniqueName: 'Python',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1012: {
		techniqueId: 'T1012',
		techniqueName: 'Query Registry',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1037.004': {
		techniqueId: 'T1037.004',
		techniqueName: 'RC Scripts',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1563.002': {
		techniqueId: 'T1563.002',
		techniqueName: 'RDP Hijacking',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	'T1542.004': {
		techniqueId: 'T1542.004',
		techniqueName: 'ROMMONkit',
		techniqueCategories: ['DefenseEvasion', 'Persistence'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Persistence'],
	},
	T1163: {
		techniqueId: 'T1163',
		techniqueName: 'Rc.common',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1164: {
		techniqueId: 'T1164',
		techniqueName: 'Re-opened Applications',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.007': {
		techniqueId: 'T1547.007',
		techniqueName: 'Re-opened Applications',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1600.001': {
		techniqueId: 'T1600.001',
		techniqueName: 'Reduce Key Space',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1108: {
		techniqueId: 'T1108',
		techniqueName: 'Redundant Access',
		techniqueCategories: ['DefenseEvasion', 'Persistence'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Persistence'],
	},
	'T1498.002': {
		techniqueId: 'T1498.002',
		techniqueName: 'Reflection Amplification',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1620: {
		techniqueId: 'T1620',
		techniqueName: 'Reflective Code Loading',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1060: {
		techniqueId: 'T1060',
		techniqueName: 'Registry Run Keys / Startup Folder',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.001': {
		techniqueId: 'T1547.001',
		techniqueName: 'Registry Run Keys / Startup Folder',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1121: {
		techniqueId: 'T1121',
		techniqueName: 'Regsvcs/Regasm',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1218.009': {
		techniqueId: 'T1218.009',
		techniqueName: 'Regsvcs/Regasm',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1117: {
		techniqueId: 'T1117',
		techniqueName: 'Regsvr32',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1218.010': {
		techniqueId: 'T1218.010',
		techniqueName: 'Regsvr32',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1219: {
		techniqueId: 'T1219',
		techniqueName: 'Remote Access Software',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1074.002': {
		techniqueId: 'T1074.002',
		techniqueName: 'Remote Data Staging',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1076: {
		techniqueId: 'T1076',
		techniqueName: 'Remote Desktop Protocol',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1021.001': {
		techniqueId: 'T1021.001',
		techniqueName: 'Remote Desktop Protocol',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	'T1114.002': {
		techniqueId: 'T1114.002',
		techniqueName: 'Remote Email Collection',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1563: {
		techniqueId: 'T1563',
		techniqueName: 'Remote Service Session Hijacking',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	T1021: {
		techniqueId: 'T1021',
		techniqueName: 'Remote Services',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	T1018: {
		techniqueId: 'T1018',
		techniqueName: 'Remote System Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1036.003': {
		techniqueId: 'T1036.003',
		techniqueName: 'Rename System Utilities',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1091: {
		techniqueId: 'T1091',
		techniqueName: 'Replication Through Removable Media',
		techniqueCategories: ['LateralMovement', 'InitialAccess'],
		techniqueCategoriesDisplay: ['Lateral Movement', 'Initial Access'],
	},
	'T1564.009': {
		techniqueId: 'T1564.009',
		techniqueName: 'Resource Forking',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1496: {
		techniqueId: 'T1496',
		techniqueName: 'Resource Hijacking',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1536: {
		techniqueId: 'T1536',
		techniqueName: 'Revert Cloud Instance',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1578.004': {
		techniqueId: 'T1578.004',
		techniqueName: 'Revert Cloud Instance',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1036.002': {
		techniqueId: 'T1036.002',
		techniqueName: 'Right-to-Left Override',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1207: {
		techniqueId: 'T1207',
		techniqueName: 'Rogue Domain Controller',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1014: {
		techniqueId: 'T1014',
		techniqueName: 'Rootkit',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1564.006': {
		techniqueId: 'T1564.006',
		techniqueName: 'Run Virtual Instance',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1085: {
		techniqueId: 'T1085',
		techniqueName: 'Rundll32',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1218.011': {
		techniqueId: 'T1218.011',
		techniqueName: 'Rundll32',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1494: {
		techniqueId: 'T1494',
		techniqueName: 'Runtime Data Manipulation',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1565.003': {
		techniqueId: 'T1565.003',
		techniqueName: 'Runtime Data Manipulation',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	'T1606.002': {
		techniqueId: 'T1606.002',
		techniqueName: 'SAML Tokens',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1178: {
		techniqueId: 'T1178',
		techniqueName: 'SID-History Injection',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1134.005': {
		techniqueId: 'T1134.005',
		techniqueName: 'SID-History Injection',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	T1198: {
		techniqueId: 'T1198',
		techniqueName: 'SIP and Trust Provider Hijacking',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1553.003': {
		techniqueId: 'T1553.003',
		techniqueName: 'SIP and Trust Provider Hijacking',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1021.002': {
		techniqueId: 'T1021.002',
		techniqueName: 'SMB/Windows Admin Shares',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	'T1602.001': {
		techniqueId: 'T1602.001',
		techniqueName: 'SNMP (MIB Dump)',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1505.001': {
		techniqueId: 'T1505.001',
		techniqueName: 'SQL Stored Procedures',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	'T1021.004': {
		techniqueId: 'T1021.004',
		techniqueName: 'SSH',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	'T1098.004': {
		techniqueId: 'T1098.004',
		techniqueName: 'SSH Authorized Keys',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	T1184: {
		techniqueId: 'T1184',
		techniqueName: 'SSH Hijacking',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1563.001': {
		techniqueId: 'T1563.001',
		techniqueName: 'SSH Hijacking',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	'T1562.009': {
		techniqueId: 'T1562.009',
		techniqueName: 'Safe Mode Boot',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1596.005': {
		techniqueId: 'T1596.005',
		techniqueName: 'Scan Databases',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1595.001': {
		techniqueId: 'T1595.001',
		techniqueName: 'Scanning IP Blocks',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1053.005': {
		techniqueId: 'T1053.005',
		techniqueName: 'Scheduled Task',
		techniqueCategories: ['Execution', 'Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Execution', 'Persistence', 'Privilege Escalation'],
	},
	T1053: {
		techniqueId: 'T1053',
		techniqueName: 'Scheduled Task/Job',
		techniqueCategories: ['Execution', 'Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Execution', 'Persistence', 'Privilege Escalation'],
	},
	T1029: {
		techniqueId: 'T1029',
		techniqueName: 'Scheduled Transfer',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	T1113: {
		techniqueId: 'T1113',
		techniqueName: 'Screen Capture',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1180: {
		techniqueId: 'T1180',
		techniqueName: 'Screensaver',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.002': {
		techniqueId: 'T1546.002',
		techniqueName: 'Screensaver',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1064: {
		techniqueId: 'T1064',
		techniqueName: 'Scripting',
		techniqueCategories: ['DefenseEvasion', 'Execution'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Execution'],
	},
	T1597: {
		techniqueId: 'T1597',
		techniqueName: 'Search Closed Sources',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1593.002': {
		techniqueId: 'T1593.002',
		techniqueName: 'Search Engines',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1596: {
		techniqueId: 'T1596',
		techniqueName: 'Search Open Technical Databases',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1593: {
		techniqueId: 'T1593',
		techniqueName: 'Search Open Websites/Domains',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1594: {
		techniqueId: 'T1594',
		techniqueName: 'Search Victim-Owned Websites',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1003.002': {
		techniqueId: 'T1003.002',
		techniqueName: 'Security Account Manager',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1063: {
		techniqueId: 'T1063',
		techniqueName: 'Security Software Discovery',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1518.001': {
		techniqueId: 'T1518.001',
		techniqueName: 'Security Software Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1101: {
		techniqueId: 'T1101',
		techniqueName: 'Security Support Provider',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.005': {
		techniqueId: 'T1547.005',
		techniqueName: 'Security Support Provider',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1167: {
		techniqueId: 'T1167',
		techniqueName: 'Securityd Memory',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1555.002': {
		techniqueId: 'T1555.002',
		techniqueName: 'Securityd Memory',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1583.004': {
		techniqueId: 'T1583.004',
		techniqueName: 'Server',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1584.004': {
		techniqueId: 'T1584.004',
		techniqueName: 'Server',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1505: {
		techniqueId: 'T1505',
		techniqueName: 'Server Software Component',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	T1035: {
		techniqueId: 'T1035',
		techniqueName: 'Service Execution',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1569.002': {
		techniqueId: 'T1569.002',
		techniqueName: 'Service Execution',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1499.002': {
		techniqueId: 'T1499.002',
		techniqueName: 'Service Exhaustion Flood',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1058: {
		techniqueId: 'T1058',
		techniqueName: 'Service Registry Permissions Weakness',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1489: {
		techniqueId: 'T1489',
		techniqueName: 'Service Stop',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	'T1574.010': {
		techniqueId: 'T1574.010',
		techniqueName: 'Services File Permissions Weakness',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	'T1574.011': {
		techniqueId: 'T1574.011',
		techniqueName: 'Services Registry Permissions Weakness',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation', 'Defense Evasion'],
	},
	T1166: {
		techniqueId: 'T1166',
		techniqueName: 'Setuid and Setgid',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1548.001': {
		techniqueId: 'T1548.001',
		techniqueName: 'Setuid and Setgid',
		techniqueCategories: ['PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Defense Evasion'],
	},
	T1129: {
		techniqueId: 'T1129',
		techniqueName: 'Shared Modules',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1051: {
		techniqueId: 'T1051',
		techniqueName: 'Shared Webroot',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	'T1213.002': {
		techniqueId: 'T1213.002',
		techniqueName: 'Sharepoint',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	T1023: {
		techniqueId: 'T1023',
		techniqueName: 'Shortcut Modification',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.009': {
		techniqueId: 'T1547.009',
		techniqueName: 'Shortcut Modification',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1218: {
		techniqueId: 'T1218',
		techniqueName: 'Signed Binary Proxy Execution',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1216: {
		techniqueId: 'T1216',
		techniqueName: 'Signed Script Proxy Execution',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1558.002': {
		techniqueId: 'T1558.002',
		techniqueName: 'Silver Ticket',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1593.001': {
		techniqueId: 'T1593.001',
		techniqueName: 'Social Media',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1585.001': {
		techniqueId: 'T1585.001',
		techniqueName: 'Social Media Accounts',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1586.001': {
		techniqueId: 'T1586.001',
		techniqueName: 'Social Media Accounts',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1592.002': {
		techniqueId: 'T1592.002',
		techniqueName: 'Software',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1072: {
		techniqueId: 'T1072',
		techniqueName: 'Software Deployment Tools',
		techniqueCategories: ['Execution', 'LateralMovement'],
		techniqueCategoriesDisplay: ['Execution', 'Lateral Movement'],
	},
	T1518: {
		techniqueId: 'T1518',
		techniqueName: 'Software Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1045: {
		techniqueId: 'T1045',
		techniqueName: 'Software Packing',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1027.002': {
		techniqueId: 'T1027.002',
		techniqueName: 'Software Packing',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1153: {
		techniqueId: 'T1153',
		techniqueName: 'Source',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1151: {
		techniqueId: 'T1151',
		techniqueName: 'Space after Filename',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1036.006': {
		techniqueId: 'T1036.006',
		techniqueName: 'Space after Filename',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1193: {
		techniqueId: 'T1193',
		techniqueName: 'Spearphishing Attachment',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1566.001': {
		techniqueId: 'T1566.001',
		techniqueName: 'Spearphishing Attachment',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	'T1598.002': {
		techniqueId: 'T1598.002',
		techniqueName: 'Spearphishing Attachment',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1192: {
		techniqueId: 'T1192',
		techniqueName: 'Spearphishing Link',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1566.002': {
		techniqueId: 'T1566.002',
		techniqueName: 'Spearphishing Link',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	'T1598.003': {
		techniqueId: 'T1598.003',
		techniqueName: 'Spearphishing Link',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1598.001': {
		techniqueId: 'T1598.001',
		techniqueName: 'Spearphishing Service',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1194: {
		techniqueId: 'T1194',
		techniqueName: 'Spearphishing via Service',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1566.003': {
		techniqueId: 'T1566.003',
		techniqueName: 'Spearphishing via Service',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	T1608: {
		techniqueId: 'T1608',
		techniqueName: 'Stage Capabilities',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1032: {
		techniqueId: 'T1032',
		techniqueName: 'Standard Cryptographic Protocol',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1132.001': {
		techniqueId: 'T1132.001',
		techniqueName: 'Standard Encoding',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1165: {
		techniqueId: 'T1165',
		techniqueName: 'Startup Items',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1037.005': {
		techniqueId: 'T1037.005',
		techniqueName: 'Startup Items',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1528: {
		techniqueId: 'T1528',
		techniqueName: 'Steal Application Access Token',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1539: {
		techniqueId: 'T1539',
		techniqueName: 'Steal Web Session Cookie',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1558: {
		techniqueId: 'T1558',
		techniqueName: 'Steal or Forge Kerberos Tickets',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1027.003': {
		techniqueId: 'T1027.003',
		techniqueName: 'Steganography',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1001.002': {
		techniqueId: 'T1001.002',
		techniqueName: 'Steganography',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1492: {
		techniqueId: 'T1492',
		techniqueName: 'Stored Data Manipulation',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1565.001': {
		techniqueId: 'T1565.001',
		techniqueName: 'Stored Data Manipulation',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1553: {
		techniqueId: 'T1553',
		techniqueName: 'Subvert Trust Controls',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1169: {
		techniqueId: 'T1169',
		techniqueName: 'Sudo',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	T1206: {
		techniqueId: 'T1206',
		techniqueName: 'Sudo Caching',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1548.003': {
		techniqueId: 'T1548.003',
		techniqueName: 'Sudo and Sudo Caching',
		techniqueCategories: ['PrivilegeEscalation', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Defense Evasion'],
	},
	T1195: {
		techniqueId: 'T1195',
		techniqueName: 'Supply Chain Compromise',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	'T1573.001': {
		techniqueId: 'T1573.001',
		techniqueName: 'Symmetric Cryptography',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1497.001': {
		techniqueId: 'T1497.001',
		techniqueName: 'System Checks',
		techniqueCategories: ['DefenseEvasion', 'Discovery'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Discovery'],
	},
	T1019: {
		techniqueId: 'T1019',
		techniqueName: 'System Firmware',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1542.001': {
		techniqueId: 'T1542.001',
		techniqueName: 'System Firmware',
		techniqueCategories: ['Persistence', 'DefenseEvasion'],
		techniqueCategoriesDisplay: ['Persistence', 'Defense Evasion'],
	},
	T1082: {
		techniqueId: 'T1082',
		techniqueName: 'System Information Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	'T1614.001': {
		techniqueId: 'T1614.001',
		techniqueName: 'System Language Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1614: {
		techniqueId: 'T1614',
		techniqueName: 'System Location Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1016: {
		techniqueId: 'T1016',
		techniqueName: 'System Network Configuration Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1049: {
		techniqueId: 'T1049',
		techniqueName: 'System Network Connections Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1033: {
		techniqueId: 'T1033',
		techniqueName: 'System Owner/User Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1007: {
		techniqueId: 'T1007',
		techniqueName: 'System Service Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1569: {
		techniqueId: 'T1569',
		techniqueName: 'System Services',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1529: {
		techniqueId: 'T1529',
		techniqueName: 'System Shutdown/Reboot',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	T1124: {
		techniqueId: 'T1124',
		techniqueName: 'System Time Discovery',
		techniqueCategories: ['Discovery'],
		techniqueCategoriesDisplay: ['Discovery'],
	},
	T1501: {
		techniqueId: 'T1501',
		techniqueName: 'Systemd Service',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1543.002': {
		techniqueId: 'T1543.002',
		techniqueName: 'Systemd Service',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1053.006': {
		techniqueId: 'T1053.006',
		techniqueName: 'Systemd Timers',
		techniqueCategories: ['Execution', 'Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Execution', 'Persistence', 'Privilege Escalation'],
	},
	'T1542.005': {
		techniqueId: 'T1542.005',
		techniqueName: 'TFTP Boot',
		techniqueCategories: ['DefenseEvasion', 'Persistence'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Persistence'],
	},
	T1080: {
		techniqueId: 'T1080',
		techniqueName: 'Taint Shared Content',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	T1221: {
		techniqueId: 'T1221',
		techniqueName: 'Template Injection',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1055.003': {
		techniqueId: 'T1055.003',
		techniqueName: 'Thread Execution Hijacking',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1055.005': {
		techniqueId: 'T1055.005',
		techniqueName: 'Thread Local Storage',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1597.001': {
		techniqueId: 'T1597.001',
		techniqueName: 'Threat Intel Vendors',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1497.003': {
		techniqueId: 'T1497.003',
		techniqueName: 'Time Based Evasion',
		techniqueCategories: ['DefenseEvasion', 'Discovery'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Discovery'],
	},
	T1209: {
		techniqueId: 'T1209',
		techniqueName: 'Time Providers',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.003': {
		techniqueId: 'T1547.003',
		techniqueName: 'Time Providers',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1099: {
		techniqueId: 'T1099',
		techniqueName: 'Timestomp',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1070.006': {
		techniqueId: 'T1070.006',
		techniqueName: 'Timestomp',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1134.001': {
		techniqueId: 'T1134.001',
		techniqueName: 'Token Impersonation/Theft',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1588.002': {
		techniqueId: 'T1588.002',
		techniqueName: 'Tool',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1020.001': {
		techniqueId: 'T1020.001',
		techniqueName: 'Traffic Duplication',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	T1205: {
		techniqueId: 'T1205',
		techniqueName: 'Traffic Signaling',
		techniqueCategories: ['DefenseEvasion', 'Persistence', 'CommandAndControl'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Persistence', 'Command And Control'],
	},
	T1537: {
		techniqueId: 'T1537',
		techniqueName: 'Transfer Data to Cloud Account',
		techniqueCategories: ['Exfiltration'],
		techniqueCategoriesDisplay: ['Exfiltration'],
	},
	T1493: {
		techniqueId: 'T1493',
		techniqueName: 'Transmitted Data Manipulation',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1565.002': {
		techniqueId: 'T1565.002',
		techniqueName: 'Transmitted Data Manipulation',
		techniqueCategories: ['Impact'],
		techniqueCategoriesDisplay: ['Impact'],
	},
	'T1505.002': {
		techniqueId: 'T1505.002',
		techniqueName: 'Transport Agent',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	T1154: {
		techniqueId: 'T1154',
		techniqueName: 'Trap',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.005': {
		techniqueId: 'T1546.005',
		techniqueName: 'Trap',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1127: {
		techniqueId: 'T1127',
		techniqueName: 'Trusted Developer Utilities Proxy Execution',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1199: {
		techniqueId: 'T1199',
		techniqueName: 'Trusted Relationship',
		techniqueCategories: ['InitialAccess'],
		techniqueCategoriesDisplay: ['Initial Access'],
	},
	T1111: {
		techniqueId: 'T1111',
		techniqueName: 'Two-Factor Authentication Interception',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1065: {
		techniqueId: 'T1065',
		techniqueName: 'Uncommonly Used Port',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1059.004': {
		techniqueId: 'T1059.004',
		techniqueName: 'Unix Shell',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1546.004': {
		techniqueId: 'T1546.004',
		techniqueName: 'Unix Shell Configuration Modification',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1552: {
		techniqueId: 'T1552',
		techniqueName: 'Unsecured Credentials',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	T1535: {
		techniqueId: 'T1535',
		techniqueName: 'Unused/Unsupported Cloud Regions',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1608.001': {
		techniqueId: 'T1608.001',
		techniqueName: 'Upload Malware',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1608.002': {
		techniqueId: 'T1608.002',
		techniqueName: 'Upload Tool',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1550: {
		techniqueId: 'T1550',
		techniqueName: 'Use Alternate Authentication Material',
		techniqueCategories: ['DefenseEvasion', 'LateralMovement'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Lateral Movement'],
	},
	'T1497.002': {
		techniqueId: 'T1497.002',
		techniqueName: 'User Activity Based Checks',
		techniqueCategories: ['DefenseEvasion', 'Discovery'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Discovery'],
	},
	T1204: {
		techniqueId: 'T1204',
		techniqueName: 'User Execution',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1564.007': {
		techniqueId: 'T1564.007',
		techniqueName: 'VBA Stomping',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1055.014': {
		techniqueId: 'T1055.014',
		techniqueName: 'VDSO Hijacking',
		techniqueCategories: ['DefenseEvasion', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Privilege Escalation'],
	},
	'T1021.005': {
		techniqueId: 'T1021.005',
		techniqueName: 'VNC',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	T1078: {
		techniqueId: 'T1078',
		techniqueName: 'Valid Accounts',
		techniqueCategories: ['DefenseEvasion', 'Persistence', 'PrivilegeEscalation', 'InitialAccess'],
		techniqueCategoriesDisplay: [
			'Defense Evasion',
			'Persistence',
			'Privilege Escalation',
			'Initial Access',
		],
	},
	'T1218.012': {
		techniqueId: 'T1218.012',
		techniqueName: 'Verclsid',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1125: {
		techniqueId: 'T1125',
		techniqueName: 'Video Capture',
		techniqueCategories: ['Collection'],
		techniqueCategoriesDisplay: ['Collection'],
	},
	'T1583.003': {
		techniqueId: 'T1583.003',
		techniqueName: 'Virtual Private Server',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1584.003': {
		techniqueId: 'T1584.003',
		techniqueName: 'Virtual Private Server',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1497: {
		techniqueId: 'T1497',
		techniqueName: 'Virtualization/Sandbox Evasion',
		techniqueCategories: ['DefenseEvasion', 'Discovery'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Discovery'],
	},
	'T1059.005': {
		techniqueId: 'T1059.005',
		techniqueName: 'Visual Basic',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1588.006': {
		techniqueId: 'T1588.006',
		techniqueName: 'Vulnerabilities',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1595.002': {
		techniqueId: 'T1595.002',
		techniqueName: 'Vulnerability Scanning',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	'T1596.002': {
		techniqueId: 'T1596.002',
		techniqueName: 'WHOIS',
		techniqueCategories: ['Reconnaissance'],
		techniqueCategoriesDisplay: ['Reconnaissance'],
	},
	T1600: {
		techniqueId: 'T1600',
		techniqueName: 'Weaken Encryption',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	'T1606.001': {
		techniqueId: 'T1606.001',
		techniqueName: 'Web Cookies',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1056.003': {
		techniqueId: 'T1056.003',
		techniqueName: 'Web Portal Capture',
		techniqueCategories: ['Collection', 'CredentialAccess'],
		techniqueCategoriesDisplay: ['Collection', 'Credential Access'],
	},
	'T1071.001': {
		techniqueId: 'T1071.001',
		techniqueName: 'Web Protocols',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	T1102: {
		techniqueId: 'T1102',
		techniqueName: 'Web Service',
		techniqueCategories: ['CommandAndControl'],
		techniqueCategoriesDisplay: ['Command And Control'],
	},
	'T1583.006': {
		techniqueId: 'T1583.006',
		techniqueName: 'Web Services',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	'T1584.006': {
		techniqueId: 'T1584.006',
		techniqueName: 'Web Services',
		techniqueCategories: ['ResourceDevelopment'],
		techniqueCategoriesDisplay: ['Resource Development'],
	},
	T1506: {
		techniqueId: 'T1506',
		techniqueName: 'Web Session Cookie',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1550.004': {
		techniqueId: 'T1550.004',
		techniqueName: 'Web Session Cookie',
		techniqueCategories: ['DefenseEvasion', 'LateralMovement'],
		techniqueCategoriesDisplay: ['Defense Evasion', 'Lateral Movement'],
	},
	T1100: {
		techniqueId: 'T1100',
		techniqueName: 'Web Shell',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1505.003': {
		techniqueId: 'T1505.003',
		techniqueName: 'Web Shell',
		techniqueCategories: ['Persistence'],
		techniqueCategoriesDisplay: ['Persistence'],
	},
	T1077: {
		techniqueId: 'T1077',
		techniqueName: 'Windows Admin Shares',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1059.003': {
		techniqueId: 'T1059.003',
		techniqueName: 'Windows Command Shell',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	'T1555.004': {
		techniqueId: 'T1555.004',
		techniqueName: 'Windows Credential Manager',
		techniqueCategories: ['CredentialAccess'],
		techniqueCategoriesDisplay: ['Credential Access'],
	},
	'T1222.001': {
		techniqueId: 'T1222.001',
		techniqueName: 'Windows File and Directory Permissions Modification',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
	T1047: {
		techniqueId: 'T1047',
		techniqueName: 'Windows Management Instrumentation',
		techniqueCategories: ['Execution'],
		techniqueCategoriesDisplay: ['Execution'],
	},
	T1084: {
		techniqueId: 'T1084',
		techniqueName: 'Windows Management Instrumentation Event Subscription',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1546.003': {
		techniqueId: 'T1546.003',
		techniqueName: 'Windows Management Instrumentation Event Subscription',
		techniqueCategories: ['PrivilegeEscalation', 'Persistence'],
		techniqueCategoriesDisplay: ['Privilege Escalation', 'Persistence'],
	},
	T1028: {
		techniqueId: 'T1028',
		techniqueName: 'Windows Remote Management',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1021.006': {
		techniqueId: 'T1021.006',
		techniqueName: 'Windows Remote Management',
		techniqueCategories: ['LateralMovement'],
		techniqueCategoriesDisplay: ['Lateral Movement'],
	},
	'T1543.003': {
		techniqueId: 'T1543.003',
		techniqueName: 'Windows Service',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1004: {
		techniqueId: 'T1004',
		techniqueName: 'Winlogon Helper DLL',
		techniqueCategories: null,
		techniqueCategoriesDisplay: null,
	},
	'T1547.004': {
		techniqueId: 'T1547.004',
		techniqueName: 'Winlogon Helper DLL',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	'T1547.013': {
		techniqueId: 'T1547.013',
		techniqueName: 'XDG Autostart Entries',
		techniqueCategories: ['Persistence', 'PrivilegeEscalation'],
		techniqueCategoriesDisplay: ['Persistence', 'Privilege Escalation'],
	},
	T1220: {
		techniqueId: 'T1220',
		techniqueName: 'XSL Script Processing',
		techniqueCategories: ['DefenseEvasion'],
		techniqueCategoriesDisplay: ['Defense Evasion'],
	},
};
