<wcd-panel (close)="destroy()" [settings]="settings" data-track-component="MachineGroupsFilterPanel">

	<div class="wcd-full-height wcd-flex-vertical">

		<div class="wcd-padding-horizontal">
			<div class="wcd-flex-none wcd-flex-vertical wcd-margin-largeMedium-bottom">
				<h3 #panelHeader style="line-height: 1.4">
					{{('tvm.machineGroupsFilter.panel.header.title' | i18n)}}
				</h3>
			</div>

			<fab-message-bar
				[styles]="_messageBarStyles"
				[messageBarType]="_messageBarType.info">
				{{"tvm.machineGroupsFilter.panel.header.warning" | i18n}}
			</fab-message-bar>
		</div>

		<div class="wcd-flex-1 wcd-full-height wcd-flex-vertical wcd-margin-top wcd-scroll-vertical">
			<ng-container *ngIf="_wcdFiltersData[_filterId]">
				<wcd-filters
					class="wcd-flex-1"
					[fields]="_wcdFiltersFields"
					[data]="_wcdFiltersData"
					[selectedValues]="_wcdFiltersSelectedValues"
					(filtersChange)="onWcdFiltersChange($event)"
				></wcd-filters>
			</ng-container>
		</div>

	</div>

</wcd-panel>
