import { Type } from '@angular/core';
import { Resolve, Routes } from '@angular/router';
import { AuthenticatedGuard, OnboardGuard } from '@wcd/auth';
import { SearchPageComponent } from './components/search-page.component';
import { SearchResultsComponent } from './components/search-results.component';
import { SearchEntityTypeResolver } from './services/search-entity-type.resolver';
import { BreadcrumbsResolver } from '../breadcrumbs/services/breadcrumbs-resolver.service';
var ɵ0 = {
    breadcrumbsConfig: {
        addParentWhenEmpty: false,
        resetListOnEnter: true,
        show: false,
        itemConfig: {
            labelKey: 'main_search_title',
            url: '/endpoints/search',
        },
    },
}, ɵ1 = {
    breadcrumbsConfig: {
        addParentWhenEmpty: false,
        resetListOnEnter: true,
        itemConfig: {
            labelKey: 'main_searchResults_title',
            url: 'searchResults/:searchEntityTypeId/:term',
        },
    },
};
export var searchRoutes = [
    {
        path: '',
        canActivate: [AuthenticatedGuard, OnboardGuard],
        children: [
            {
                path: 'input',
                component: SearchPageComponent,
                data: ɵ0,
                children: [
                    {
                        path: ':searchEntityTypeId/:term',
                        component: SearchResultsComponent,
                        resolve: {
                            entityType: SearchEntityTypeResolver,
                            breadcrumbs: BreadcrumbsResolver,
                        },
                        canActivate: [AuthenticatedGuard, OnboardGuard],
                    },
                ],
            },
        ],
    },
    {
        path: 'searchResults/:searchEntityTypeId/:term',
        pathMatch: 'full',
        component: SearchResultsComponent,
        resolve: {
            entityType: SearchEntityTypeResolver,
            breadcrumbs: BreadcrumbsResolver,
        },
        canActivate: [AuthenticatedGuard, OnboardGuard],
        data: ɵ1,
    },
];
var SearchRoutingModule = /** @class */ (function () {
    function SearchRoutingModule() {
    }
    return SearchRoutingModule;
}());
export { SearchRoutingModule };
export { ɵ0, ɵ1 };
