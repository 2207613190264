import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { PreferencesService } from '@wcd/config';

const DISMISS_STORAGE_KEY = 'mtpPromotion-dismissed';

@Injectable({
	providedIn: 'root',
})
export class MtpPromotionService implements OnDestroy {
	private _mtpPromotionDismissed: boolean;
	private promotionVisibleSubject = new BehaviorSubject<boolean>(false);
	private _isMtpBannerFeatureEnabled = false;

	promoWidgetVisible$ = this.promotionVisibleSubject.asObservable();

	get mtpPromotionWidgetDismissed(): boolean {
		return this._mtpPromotionDismissed;
	}

	get isMtpBannerVisible(): boolean {
		return (
			this._isMtpBannerFeatureEnabled &&
			!this._mtpPromotionDismissed
		);
	}

	constructor(
		private appInsights: AppInsightsService,
		private preferenceService: PreferencesService,
		private featuresService: FeaturesService,
		private appContextService: AppContextService
	) {
		this._mtpPromotionDismissed = !!this.preferenceService.getPreference(DISMISS_STORAGE_KEY);

		this._isMtpBannerFeatureEnabled =
			!this.appContextService.isSCC && this.featuresService.isEnabled(Feature.ExperienceMtp);

		this.promotionVisibleSubject.next(
			this._isMtpBannerFeatureEnabled ? !this._mtpPromotionDismissed : false
		);
	}

	dismissMtpPromotion(trackEvent: boolean = true): void {
		this._mtpPromotionDismissed = true;
		this.preferenceService.setPreference(DISMISS_STORAGE_KEY, 'true');
		this.promotionVisibleSubject.next(false);
		if (trackEvent) {
			this.trackMtpPromotionWidgetEvent(true);
		}
	}

	trackMtpPromotionWidgetEvent(isDecline: boolean) {
		this.appInsights.trackEvent(`EndpointMtpPromotion-DashboardTile${isDecline ? 'Dismiss' : 'Button'}`, {
			Page: 'dashboard',
		});
	}

	ngOnDestroy() {
		this.promotionVisibleSubject.complete();
	}
}
