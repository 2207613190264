import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'asc-onboarding-link',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="wcd-flex-vertical wcd-margin-xLarge-left">
			<h5>{{ 'endpointManagement.agent.onboarding.asc.title' | i18n }}</h5>
			<p>{{ 'endpointManagement.agent.onboarding.asc.description' | i18n }}</p>
			<div class="wcd-flex-horizontal wcd-margin-top wcd-flex-center-vertical wcd-flex-margins">
				<a
					class="btn btn-primary"
					data-track-id="SecurityCenterIntegrationWdatp"
					data-track-type="ExternalLink"
					type="button"
					href="https://aka.ms/security-center-integration-wdatp"
					target="_blank"
				>
					{{ 'endpointManagement.agent.onboarding.asc.button' | i18n }}
				</a>
				<markdown>{{ 'endpointManagement.agent.onboarding.asc.learnMore' | i18n }}</markdown>
			</div>
		</div>
	`,
})
export class AscOnboardingComponent {}
