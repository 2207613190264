var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, QueryList, NgZone, ElementRef, AfterViewInit, } from '@angular/core';
import { isEqual, isNil, omitBy, sortBy } from 'lodash-es';
import { FiltersFieldComponent } from './filters-field.component';
import { of, combineLatest } from 'rxjs';
import { map, debounceTime, filter } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var LARGE_NUMBER = Math.pow(10, 6);
var FiltersComponent = /** @class */ (function () {
    function FiltersComponent(changeDetectorRef, ngZone, i18nService, liveAnnouncer) {
        this.changeDetectorRef = changeDetectorRef;
        this.ngZone = ngZone;
        this.i18nService = i18nService;
        this.liveAnnouncer = liveAnnouncer;
        this.filtersChange = new EventEmitter();
        this.clear = new EventEmitter();
        this.filtersCancel = new EventEmitter();
        this.fieldSelectedValues = new Map();
        this.allowFocusOnFirstElement = false;
        this.checkFocus = false;
        this._removedFilterFields = new Set();
    }
    Object.defineProperty(FiltersComponent.prototype, "data", {
        get: function () {
            return this._data || {};
        },
        set: function (data) {
            this._data = data;
            this.changeDetectorRef.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FiltersComponent.prototype, "selectedValues", {
        set: function (selectedValues) {
            this.fieldSelectedValues = new Map(selectedValues);
        },
        enumerable: true,
        configurable: true
    });
    /*
    resets the selected values, used by the dataview to change the values to
    default values giving to the dataview
     */
    FiltersComponent.prototype.resetSelectedValues = function (selectedValues) {
        var _this = this;
        setTimeout(function () {
            _this.fieldSelectedValues = new Map(Object.entries(selectedValues || {}));
            _this.changeDetectorRef.detectChanges();
        });
    };
    Object.defineProperty(FiltersComponent.prototype, "serializedFilters", {
        set: function (serializedFilters) {
            if (this._fieldComponents)
                this.selectSerializedFilters(serializedFilters);
            else
                this._serializedFilters = serializedFilters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FiltersComponent.prototype, "fieldComponents", {
        set: function (fieldComponents) {
            var _this = this;
            this._fieldComponents = fieldComponents;
            this._fieldComponents.changes
                .pipe(filter(function () { return !!_this._serializedFilters; }), debounceTime(50))
                .subscribe(function () {
                if (_this._serializedFilters && _this._fieldComponents.length) {
                    _this.selectSerializedFilters(_this._serializedFilters);
                    _this._serializedFilters = null;
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FiltersComponent.prototype, "fieldsWithData", {
        get: function () {
            var _this = this;
            return sortBy(this.fields.filter((function (field) { return (field.requiresData === false || (_this.data && _this.data[field.id])) && _this.isVisible(field); })), [function (field) { return (!isNaN(field.priority) ? field.priority : LARGE_NUMBER); }]);
        },
        enumerable: true,
        configurable: true
    });
    FiltersComponent.prototype.isVisible = function (field) {
        if (!field.isHidden)
            return true;
        // Either _serializedFilters or fieldSelectedValues is populated in a given time
        if (this._serializedFilters !== null)
            return this._serializedFilters[field.id] !== undefined;
        else
            return this.fieldSelectedValues.has(field.id);
    };
    Object.defineProperty(FiltersComponent.prototype, "isDirty", {
        get: function () {
            return !!((this._changedFields && this._changedFields.size) ||
                (this._removedFilterFields && this._removedFilterFields.size));
        },
        enumerable: true,
        configurable: true
    });
    FiltersComponent.prototype.ngAfterViewInit = function () {
        if (this.allowFocusOnFirstElement) {
            this.focusOnFirstFocusable();
        }
    };
    FiltersComponent.prototype.onFieldChange = function (fieldChange) {
        if (!this._changedFields)
            this._changedFields = new Map();
        var field = fieldChange.field, selectedValues = fieldChange.selectedValues;
        if (selectedValues && (!(selectedValues instanceof Array) || selectedValues.length)) {
            this._changedFields.set(field, selectedValues);
        }
        else {
            this._changedFields.delete(field);
            this._removedFilterFields.add(field);
        }
        if (isEqual(this.getNewSelectedValues(), this.fieldSelectedValues))
            this.cancel(false);
    };
    FiltersComponent.prototype.selectSerializedFilters = function (serializedFilters) {
        var _this = this;
        this.deserialize(serializedFilters).subscribe(function (deserialized) {
            _this.selectedValues = deserialized;
            _this.changeDetectorRef.markForCheck();
        });
    };
    FiltersComponent.prototype.clearAllFilters = function () {
        var _this = this;
        this.liveAnnouncer.announce(this.i18nService.strings.filters_cleared, 'assertive').then(function () {
            setTimeout(function () {
                _this.ngZone.run(function () {
                    _this.clearChanges();
                    _this._fieldComponents.forEach(function (component) { return (component.selectedValues = null); });
                    _this.fieldSelectedValues.clear();
                    _this.triggerChange();
                    _this.focusOnFirstFocusable();
                });
            }, 700);
        });
    };
    FiltersComponent.prototype.apply = function () {
        var _this = this;
        this.liveAnnouncer.announce(this.i18nService.strings.filters_applied, 'assertive').then(function () {
            setTimeout(function () {
                _this.ngZone.run(function () {
                    _this.fieldSelectedValues = _this.getNewSelectedValues();
                    _this.triggerChange();
                    _this.clearChanges();
                    _this.filtersCancel.emit();
                    _this.focusOnFirstFocusable();
                });
            }, 700);
        });
    };
    FiltersComponent.prototype.cancel = function (focusOnFirstElement) {
        var _this = this;
        if (focusOnFirstElement === void 0) { focusOnFirstElement = true; }
        this.liveAnnouncer.announce(this.i18nService.strings.filters_canceled, 'assertive').then(function () {
            setTimeout(function () {
                _this.ngZone.run(function () {
                    _this._changedFields.forEach(function (filterSelection, field) {
                        return _this.resetFieldSelectedValues(field);
                    });
                    _this._removedFilterFields.forEach(function (field) { return _this.resetFieldSelectedValues(field); });
                    if (focusOnFirstElement)
                        _this.focusOnFirstFocusable();
                    _this.clearChanges();
                    _this.filtersCancel.emit();
                });
            }, 700);
        });
    };
    FiltersComponent.prototype.resetFieldSelectedValues = function (field) {
        var fieldComponent = this._fieldComponents.find(function (component) { return component.field === field; });
        fieldComponent.selectedValues = this.fieldSelectedValues.get(field.id);
    };
    FiltersComponent.prototype.triggerChange = function () {
        this.filtersChange.emit(this.getChangeData());
    };
    FiltersComponent.prototype.getChangeData = function () {
        var selection = {};
        this.fieldSelectedValues.forEach(function (fieldSelection, fieldId) { return (selection[fieldId] = fieldSelection); });
        return {
            selection: selection,
            serialized: this.serialize(),
        };
    };
    FiltersComponent.prototype.clearChanges = function () {
        if (this._changedFields)
            this._changedFields.clear();
        this._removedFilterFields.clear();
    };
    FiltersComponent.prototype.serialize = function () {
        return omitBy(this._fieldComponents.reduce(function (allFieldValues, fieldComponent) { return (__assign({}, allFieldValues, fieldComponent.serialize())); }, {}), isNil);
    };
    FiltersComponent.prototype.deserialize = function (fieldSerializedSelections) {
        if (fieldSerializedSelections) {
            var fieldDeserializations$ = this._fieldComponents.map(function (fieldComponent) {
                return fieldComponent.deserialize(fieldSerializedSelections).pipe(map(function (deserializedFieldSelection) { return ({
                    field: fieldComponent.field,
                    selection: deserializedFieldSelection,
                }); }));
            });
            return combineLatest(fieldDeserializations$).pipe(map(function (fieldSelections) {
                var deserializedSelection = new Map();
                fieldSelections.forEach(function (fieldSelection) {
                    var selectedValues = fieldSelection.selection;
                    if (!isNil(selectedValues) &&
                        (!(selectedValues instanceof Array) || selectedValues.length))
                        deserializedSelection.set(fieldSelection.field.id, selectedValues);
                });
                return deserializedSelection;
            }));
        }
        return of(new Map());
    };
    /**
     * Returns a merge of the selected filter values with any new changes.
     */
    FiltersComponent.prototype.getNewSelectedValues = function () {
        var _this = this;
        var changedSelectedValues = new Map();
        this.fields.forEach(function (field) {
            var fieldNewSelectedValues = _this._changedFields.get(field);
            if (fieldNewSelectedValues !== undefined) {
                if (fieldNewSelectedValues !== null)
                    changedSelectedValues.set(field.id, fieldNewSelectedValues);
            }
            else if (!_this._removedFilterFields.has(field)) {
                var currentFieldSelectedValues = _this.fieldSelectedValues.get(field.id);
                if (!isNil(currentFieldSelectedValues))
                    changedSelectedValues.set(field.id, currentFieldSelectedValues);
            }
        });
        return changedSelectedValues;
    };
    FiltersComponent.prototype.focusOnFirstFocusable = function () {
        if (this.parentFocusable) {
            this.parentFocusable();
        }
        else {
            this._fieldComponents.first.setFocus();
        }
    };
    return FiltersComponent;
}());
export { FiltersComponent };
