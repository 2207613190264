import { EntityField, EntityModelBase, Entity } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { EvaluationSimulationStatus } from './evaluation-simulation-status.entity';
import { EvaluationSimulatorAgent } from './evaluation-model';

@Entity({
	singularName: 'Evaluation simulation',
	pluralName: 'Evaluation simulations',
	endpoint: 'evaluation/simulations',
	allItemsProperty: 'simulations',
	loadAll: false,
	parseSaveQuery: (value: EvaluationSimulation) => 'evaluation/simulations',
	saveMethod: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class EvaluationSimulation extends EntityModelBase<string> {
	@EntityField({ data: 'simulationName' })
	simulationName: string;

	@EntityField({ data: 'simulationId' })
	simulationId: number;

	@EntityField({ data: 'simulator' })
	simulator: EvaluationSimulatorAgent;

	@EntityField({ data: 'machineName' })
	machineName: string;

	@EntityField({ data: 'machineIndex' })
	machineIndex: number;

	@EntityField({ data: 'status' })
	status: EvaluationSimulationStatus;

	@EntityField({ data: 'startTime' })
	startTime: Date;

	@EntityField({ data: 'endTime' })
	endTime: Date;

	@EntityField({ data: 'userName' })
	userName: string;

	get uniqueKey(): string {
		return `${this.simulationId}#${this.endTime ? this.endTime.toISOString() : ''}${this.machineIndex}`;
	}
}
