import {
	DataQuery,
	EntityRelationship,
	EntityRelationshipRepositoryType,
	ParisConfig,
	RelationshipType,
} from '@microsoft/paris';
import { Alert } from '../../alert/alert.entity';
import { HuntingRule } from '../hunting-rule.entity';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { CustomActionRun } from '../actions/hunting-custom-action-result.value-object';
import { HuntingDataQuery } from '../hunting-dataQuery';

@EntityRelationship({
	sourceEntity: HuntingRule,
	dataEntity: Alert,
	endpoint: `AssociatedAlerts`,
	allItemsEndpointTrailingSlash: false,
	allItemsProperty: 'Items',
	allowedTypes: [RelationshipType.OneToMany],
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	getRelationshipData: (rule: HuntingRule) => {
		return {
			IoaDefinitionIds: rule.ioaDefinitionId,
		};
	},
})
export class ScheduledHuntingRuleAlertRelationship
	implements EntityRelationshipRepositoryType<HuntingRule, Alert> {}

const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
function getStartTimeOfRange(rangeInDays: number) {
	//add 10 seconds to the start time so that we don't accidentally go over the server's
	//date range limit and get an error
	return new Date(new Date().getTime() - rangeInDays * MILLISECONDS_PER_DAY + 10000);
}

@EntityRelationship({
	sourceEntity: HuntingRule,
	dataEntity: CustomActionRun,
	parseDataQuery: (query: DataQuery) => {
		const dataQuery: any = {
			startTime: getStartTimeOfRange((query.where as any).range).toISOString(),
			endTime: new Date().toISOString(),
			sortOrder: query.sortBy && query.sortBy[0].direction === 1 ? 'Descending' : 'Ascending',
			pageIndex: query.page || 1,
			pageSize: query.pageSize,
			lastRunOnly: !!(query.where as any).lastRunOnly,
		};
		if (query.sortBy && query.sortBy[0].field) {
			dataQuery.sortByField = query.sortBy[0].field;
		}
		return dataQuery;
	},
	endpoint: (config: ParisConfig, query: HuntingDataQuery) =>
		`rules/history/${(query.where as any).ruleId}/actions`,
	allItemsEndpointTrailingSlash: false,
	allowedTypes: [RelationshipType.OneToMany],
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.huntingService,

	getRelationshipData: (rule: HuntingRule) => {
		return {
			ruleId: rule.id,
		};
	},
})
export class ScheduledHuntingRuleActionRelationship
	implements EntityRelationshipRepositoryType<HuntingRule, CustomActionRun> {}
