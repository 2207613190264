import { SevilleModule } from '../../seville/seville.module';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';

SevilleModule.directive('sidePaneEvent', sidePaneEventIndicatorDirective);

sidePaneEventIndicatorDirective.$inject = ['sidePaneService'];

function sidePaneEventIndicatorDirective(sidePaneService) {
	return {
		restrict: 'E',
		bindToController: true,
		controllerAs: 'pi',
		scope: {
			rawEvent: '=',
			hideIndicator: '=',
		},
		template: `
		<span class="side-pane-indicator" tabindex="0" data-track-id="sidePaneButtonEvent" data-track-type="SidePaneToggleButton">
        <i class="icon icon-{{pi.icon}} pointer side-pane-indicator-icon" ng-click="toggle($event)" ng-if="!pi.hideIndicator"></i>
    </span>
		`,
		controller: [
			'$scope',
			'$http',
			'urlMapping',
			'$filter',
			'$log',
			'demoTenantResolverService',
			'executionDetailsSidePaneService',
			'appInsights',
			function(
				$scope,
				$http,
				urlMapping,
				$filter,
				$log,
				demoTenantResolverService,
				executionDetailsSidePaneService,
				appInsights: AppInsightsService
			) {
				var vm = this;
				vm.event = getEvent(vm.rawEvent);
				vm.key = vm.event.ElementId;

				function getEvent(rawEvent) {
					var roundedActionTime = $filter('date')(
						rawEvent.actionTime,
						'yyyy-MM-dd-HH-mm-ss',
						'UTC'
					);
					var actionType = getActionType(rawEvent.actionType, rawEvent.etwType);
					return {
						Machine: { MachineId: rawEvent.machineId },
						EventTime: rawEvent.eventTime,
						ActionTime: rawEvent.actionTime,
						ActionType: actionType,
						FolderPath: rawEvent.path,
						FileName: rawEvent.name,
						ProcessCommandLine: rawEvent.command || rawEvent.processCommand,
						Sha1: rawEvent.sha1,
						ProcessId: rawEvent.processId,
						ProcessAccount: rawEvent.accountName,
						ProcessTokenElevation: rawEvent.processTokenElevation,
						ProcessIntegrityLevel: rawEvent.processIntegrityLevel,
						IpAddress: rawEvent.ip,
						RegistryOperation: rawEvent.registryOperation,
						RegistryValueData: rawEvent.valueData,
						RegistryValueName: rawEvent.valueName,
						RegistryKey: rawEvent.key,
						RegistryPreviousValueData: rawEvent.previousValueData,
						RegistryPreviousValueName: rawEvent.previousValueName,
						RegistryPreviousKey: rawEvent.previousKey,
						ElementId:
							roundedActionTime +
							actionType +
							rawEvent.sha1 +
							rawEvent.processId +
							rawEvent.ip +
							rawEvent.registryOperation +
							rawEvent.valueData +
							rawEvent.valueName +
							rawEvent.key +
							rawEvent.previousValueData +
							rawEvent.previousValueName +
							rawEvent.previousKey,
						Protocol: rawEvent.protocol,
						Port: rawEvent.destinationPort,
						Domain: rawEvent.destinationDnsRecordName,
						EtwEventPropertiesAsJson: rawEvent.EventPropertiesAsJson,
						ElementType: rawEvent.etwType ? 'GenericEtw' : null,
					};
				}

				function getActionType(type, etwType) {
					switch (type) {
						case 'ProcessCreate':
							return 'CreateProcess';
						case 'EtwGenericEvent':
							return etwType;
						default:
							return type;
					}
				}

				this.togglePane = function() {
					$log.debug('Pane key: ' + vm.key);
					executionDetailsSidePaneService.open(vm.event);
				};
			},
		],
	};
}
