import { EventEmitter, OnInit } from "@angular/core";
import { Feature, FeaturesService } from '@wcd/config';
import { AadGroup } from "@wcd/domain";
import { find } from "lodash-es";
import { Paris } from "@microsoft/paris";
import { AuthService } from "@wcd/auth";
import { UserRoleActionsService } from "../../services/user-role-actions.service";
export var RbacComponentType;
(function (RbacComponentType) {
    RbacComponentType["UserRole"] = "UserRole";
    RbacComponentType["DeviceGroup"] = "DeviceGroup";
})(RbacComponentType || (RbacComponentType = {}));
var AadGroupsAssignmentTab = /** @class */ (function () {
    function AadGroupsAssignmentTab(paris, authService, userRoleActionsService, featureService) {
        this.paris = paris;
        this.authService = authService;
        this.userRoleActionsService = userRoleActionsService;
        this.featureService = featureService;
        this.isDisabled = false;
        this.applySelectedAadGroups = new EventEmitter();
        this.isUnifiedRbacEnabled = this.featureService.isEnabled(Feature.UnifiedRbac);
        this.availableAadGroups = [];
        this.selectedAadGroups = [];
        this.selectedAssignedAadGroups = [];
        this.isLoadingAadGroups = false;
        this.isAadGroupsError = false;
        this.isSaving = false;
        this.aadGroupsRepository = paris.getRepository(AadGroup);
    }
    Object.defineProperty(AadGroupsAssignmentTab.prototype, "aadGroupsDescription", {
        get: function () {
            return this.isUnifiedRbacEnabled && this.hostRbacComponentType === RbacComponentType.DeviceGroup
                ? "machineGroup.edit.aadGroupsDescription"
                : "userRoles.aadGroupsWithRole";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AadGroupsAssignmentTab.prototype, "globalAdminAccessNote", {
        get: function () {
            return this.isUnifiedRbacEnabled && this.hostRbacComponentType === RbacComponentType.DeviceGroup
                ? "machineGroup.edit.globalAdminAccess"
                : "userRoles.globalAdminAccess";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AadGroupsAssignmentTab.prototype, "emptyAssignmentsNote", {
        get: function () {
            return this.isUnifiedRbacEnabled && this.hostRbacComponentType === RbacComponentType.DeviceGroup
                ? "machineGroup.edit.emptyAssignments"
                : "userRoles.emptyAssignments";
        },
        enumerable: true,
        configurable: true
    });
    AadGroupsAssignmentTab.prototype.ngOnInit = function () {
        this.setAvailableAadGroups();
    };
    AadGroupsAssignmentTab.prototype.setAvailableAadGroups = function (filter) {
        var _this = this;
        if (filter === void 0) { filter = ''; }
        this.isLoadingAadGroups = true;
        this.isAadGroupsError = false;
        this.aadGroupsRepository.query({ where: { filter: filter } }).subscribe(function (aadGroups) {
            _this.aadGroupsFilterResults = aadGroups.items;
            _this.setAvailableAadGroupsWithoutAssigned();
            _this.isLoadingAadGroups = false;
        }, function (error) {
            _this.isLoadingAadGroups = false;
            _this.isAadGroupsError = true;
        });
    };
    AadGroupsAssignmentTab.prototype.addSelectedGroups = function () {
        this.assignedAadGroups = this.assignedAadGroups.concat(this.selectedAadGroups);
        this.setAvailableAadGroupsWithoutAssigned();
        this.selectedAadGroups = [];
        this.applySelectedAadGroups.emit(this.assignedAadGroups);
    };
    AadGroupsAssignmentTab.prototype.removeSelectedGroups = function () {
        var _this = this;
        this.assignedAadGroups = this.assignedAadGroups.filter(function (assignedAadGroup) { return !~_this.selectedAssignedAadGroups.indexOf(assignedAadGroup); });
        this.selectedAssignedAadGroups = [];
        this.setAvailableAadGroupsWithoutAssigned();
        this.applySelectedAadGroups.emit(this.assignedAadGroups);
    };
    AadGroupsAssignmentTab.prototype.setAvailableAadGroupsWithoutAssigned = function () {
        var _this = this;
        this.availableAadGroups = this.aadGroupsFilterResults.filter(function (aadGroup) { return !find(_this.assignedAadGroups, function (assignedAadGroup) { return aadGroup.id === assignedAadGroup.id; }); });
    };
    return AadGroupsAssignmentTab;
}());
export { AadGroupsAssignmentTab };
