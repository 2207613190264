<collapsible-items-preview
	[repository]="_repository"
	[title]="title"
	[loadingText]="'tvm.securityRecommendation.loadingRelatedCves' | i18n"
	[maxItems]="maxItems"
	[disableSort]="disableSort"
	[fields]="fields"
	defaultSortFieldId="'severity'"
	[initiallyCollapsed]="initiallyCollapsed"
	[dataviewLabel]="'tvm.common.relatedCVE.title' | i18n"
	(onItemClick)="openVulnerabilityPanel($event)"
	(showMore)="openAllVulnerabilities()"
	(export)="export.emit()">
</collapsible-items-preview>
