var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { LegacyUserNetworkInformation } from '../legacy-user-network-information.value-object';
import { LegacyUser } from '../legacy-user.entity';
var ɵ0 = function (user) { return ({
    userAccount: user.accountName,
    userAccountDomain: user.accountDomainName,
}); }, ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; };
var LegacyUserLegacyUserNetworkInformationRelationship = /** @class */ (function () {
    function LegacyUserLegacyUserNetworkInformationRelationship() {
    }
    LegacyUserLegacyUserNetworkInformationRelationship = __decorate([
        EntityRelationship({
            sourceEntity: LegacyUser,
            dataEntity: LegacyUserNetworkInformation,
            allowedTypes: [RelationshipType.OneToOne],
            getRelationshipData: ɵ0,
            endpoint: 'UserSidePaneDetails',
            baseUrl: ɵ1,
        })
    ], LegacyUserLegacyUserNetworkInformationRelationship);
    return LegacyUserLegacyUserNetworkInformationRelationship;
}());
export { LegacyUserLegacyUserNetworkInformationRelationship };
export { ɵ0, ɵ1 };
