import { Injectable, Inject } from '@angular/core';
import { SccRoutesService } from '../shared.module';
import { AppContextService, FeaturesService } from '@wcd/config';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { isUnifiedAlert } from '../shared-utils';

const MdatpToSCCUrlMap = {
	'localhost:4200': 'https://security.officeppe.com',
	'stg.localhost:4200': 'https://security.officeppe.com',
	'master.staging.securitycenter.microsoft.com': 'https://security.officeppe.com',
	'staging.securitycenter.microsoft.com': 'https://security.officeppe.com',
	'df.securitycenter.microsoft.com': 'https://df.security.microsoft.com',
	'df.securitycenter.windows.com': 'https://df.security.microsoft.com',
	'securitycenter.microsoft.com': 'https://security.microsoft.com',
	'securitycenter.windows.com': 'https://security.microsoft.com',
};
const SccToMdatpUrlMap = {
	'protection.office.com': 'https://df.securitycenter.microsoft.com',
	'security.officeppe.com': 'https://master.staging.securitycenter.microsoft.com',
	'dev.protection.office.com': 'https://df.securitycenter.microsoft.com',
	'df.security.microsoft.com': 'https://df.securitycenter.microsoft.com',
	'security.microsoft.com': 'https://securitycenter.microsoft.com',
};
const defaultMdatpUrlRoot = 'https://securitycenter.windows.com';
const devMdatpUrl = 'http://localhost:4200';
const InnerMdatpPageToInnerSccPagelMap = {
	'/users': '/mdatp-users',
	'/alertsQueue': '/endpoints/alertsQueue',
	'/searchResults': './endpoints/search/input',
	'/suppressionRule': '/preferences2/suppressionRule'
};

// path mapping for backend errors
export const ERROR_PAGE_MAPPING = {
	LIVE_RESPONSE_SESSION: '/live-response/',
	INVESTIGATION_PAGE: '/investigation/',
	DEVICE_PROFILE: '/machines/',
} as const;


// Routes defined in SCC app we wish to be able to navigate to
const ExternalSccRoutes = ['/securitysettings', '/preferences2/suppressionRule', '/user'];

export interface LinkConfig {
	url: string;
	isExternal: boolean;
}

@Injectable()
export class RoutesService {
	constructor(@Inject(SccRoutesService) private sccRoutes, private appContextService: AppContextService, private featuresService: FeaturesService) {
		this.sccRoutes = sccRoutes.concat(ExternalSccRoutes);
	}

	getMdatpFromSccUrl(innerMdatpPath: string): string {
		const sccLinkConfig = this.getMdatpFromSccLinkConfig(innerMdatpPath);
		return (sccLinkConfig && sccLinkConfig.url) || innerMdatpPath;
	}

	// This function converts the given inner MDATP url to an MDATP in SCC url.
	// The new url is used to navigate either out of SCC portal to an MDATP page or inside SCC portal to an MDATP page.
	getMdatpFromSccLinkConfig(innerMdatpPath: string): LinkConfig {
		if (this.appContextService.isSCC && innerMdatpPath) {
			if (innerMdatpPath.startsWith('.')) {
				return { url: innerMdatpPath, isExternal: false };
			}

			const pageNameIndexInPath = 0;
			const mdatpPage = RoutesService.getSegmentFromUrl(innerMdatpPath, pageNameIndexInPath);
			if (mdatpPage === "/alerts" && isUnifiedAlert(RoutesService.getSegmentFromUrl(innerMdatpPath, pageNameIndexInPath+1, false), this.featuresService)) {
				return {
					url: innerMdatpPath,
					isExternal: false
				}
			}
			// If we navigate to MDATP page inside SCC we might need to rename the page
			// for example: user page inside SCC should be renamed to mdatp-users to avoid confusion with MCAS user's page

			const mdatpInSccPage = InnerMdatpPageToInnerSccPagelMap[mdatpPage] || mdatpPage;
			const hasSccEq =
				mdatpInSccPage.startsWith('.') ||
				this.sccRoutes.some(sccRoutePage => mdatpInSccPage === sccRoutePage);
			if (hasSccEq) {
				// We navigate to the page inside SCC
				return {
					url: RoutesService.replaceSegmentInUrl(
						innerMdatpPath,
						mdatpInSccPage.replace(/^\./, ''),
						pageNameIndexInPath
					),
					isExternal: false,
				};
			}

			// We navigate out of SCC to MDATP portal
			return { url: RoutesService.getMdatpRootUrl() + innerMdatpPath, isExternal: true };
		}
		return null;
	}

	shouldOpenExternally(linkConfig: LinkConfig): boolean {
		return linkConfig && linkConfig.isExternal;
	}

	get unifiedUserPaneEnabled() {
		return sccHostService.inSccRole(SccRoles.hasUnifiedUserPane);
	}

	async getUnifiedUserPaneEnabledAsync() {
		return sccHostService.inSccRoleAsync(SccRoles.hasUnifiedUserPane);
	}

	static getMdatpRootUrl(): string {
		const sccRootUrl = window.location.host;
		let wdatpRootUrl: string;
		if (sccHostService.mock && sccHostService.mock.isMockMode) {
			wdatpRootUrl = devMdatpUrl;
		} else {
			wdatpRootUrl = SccToMdatpUrlMap[sccRootUrl] || defaultMdatpUrlRoot;
		}
		return wdatpRootUrl;
	}

	static getSCCRootUrl() {
		const rootUrl = window.location.host;
		return MdatpToSCCUrlMap[rootUrl]
	}

	static getSegmentFromUrl(url: string, segmentIndex: number, addSlashPrefix: boolean = true): string {
		if (url) {
			const separateOptionalParameters = RoutesService.splitUrlToOptionalAndRequiredParts(url);
			const urlSegments = RoutesService.splitUrlToSegments(separateOptionalParameters[0]);
			if (urlSegments.length > segmentIndex) {
				return addSlashPrefix? '/' + urlSegments[segmentIndex] :  urlSegments[segmentIndex];
			}
		}
		return '';
	}

	static replaceSegmentInUrl(url: string, newSegment: string, segmentIndex: number): string {
		if (!url) {
			return '';
		}

		const separateOptionalParameters = RoutesService.splitUrlToOptionalAndRequiredParts(url);
		const urlSegments = RoutesService.splitUrlToSegments(separateOptionalParameters[0]);
		urlSegments[0] = newSegment;

		separateOptionalParameters[0] = urlSegments.join('/');
		return separateOptionalParameters.join('?');
	}

	static splitUrlToOptionalAndRequiredParts(url: string) {
		return url.split('?');
	}

	static splitUrlToSegments(url: string) {
		const urlSections = url.split('/');
		urlSections.splice(0, 1); // Use splice to remove leading ''
		return urlSections;
	}
}
