import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Machine } from '../../machine/machine.entity';
import { LegacyUser } from './legacy-user.entity';

@ValueObject({
	singularName: 'User Observed Device',
	pluralName: 'User Observed Devices',
	readonly: true,
})
export class LegacyUserObservedMachine extends ModelBase {
	@EntityField({
		data: ['SenseMachineId', 'ComputerDnsName'],
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({
		data: '__self',
		parse: data => ({
			SenseMachineId: data.SenseMachineId,
			ComputerDnsName: data.ComputerDnsName,
		}),
	})
	readonly machine: Machine;

	@EntityField({ data: 'IsLocalAdminLeastPrevalent' })
	readonly isLocalAdminLeastPrevalent: boolean;

	@EntityField({ data: 'IsLocalAdminMostPrevalent' })
	readonly isLocalAdminMostPrevalent: boolean;

	@EntityField({ data: 'LastSeenOnMachine' })
	readonly lastSeenOnMachine: Date;

	@EntityField({ data: 'TotalObserevedUsers' })
	readonly totalObserevedUsers: number;

	@EntityField({
		data: '__self',
		parse: data => ({
			AccountDomainName: data.MostFrequentAccountDomainName,
			AccountName: data.MostFrequentAccountName,
		}),
	})
	readonly mostFrequentAccount: LegacyUser;

	@EntityField({
		data: '__self',
		parse: data => ({
			AccountDomainName: data.LeastFrequentAccountDomainName,
			AccountName: data.LeastFrequentAccountName,
		}),
	})
	readonly leastFrequentAccount: LegacyUser;
}
