import { ChangeDetectorRef, } from '@angular/core';
var TvmImpactScoreComponent = /** @class */ (function () {
    function TvmImpactScoreComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.hostInlineFlexCenterVertical = true;
    }
    Object.defineProperty(TvmImpactScoreComponent.prototype, "scoreData", {
        set: function (scoreData) {
            this._scoreData = scoreData;
            this.hasExposureScore = typeof scoreData.exposureScore === 'number';
            this.exposureScore = !this.hasExposureScore
                ? undefined
                : this._scoreData.exposureScore >= 0.01 || this._scoreData.exposureScore === 0
                    ? this._scoreData.exposureScore.toFixed(2)
                    : '<0.01';
            this.hasSecureScore = typeof scoreData.secureScore === 'number';
            this.showSeparator = this.hasExposureScore && this.hasSecureScore;
            this.changeDetectorRef.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    return TvmImpactScoreComponent;
}());
export { TvmImpactScoreComponent };
