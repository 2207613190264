var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Url } from '@wcd/domain';
import { Router } from '@angular/router';
import { EntityResolver } from '../../../global_entities/services/entity.resolver';
import { Paris } from '@microsoft/paris';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityIdRouteService } from '../../../global_entities/services/entity-id-route.service';
import { AppContextService } from '@wcd/config';
var UrlEntityResolver = /** @class */ (function (_super) {
    __extends(UrlEntityResolver, _super);
    function UrlEntityResolver(paris, activatedEntity, globalEntityTypesService, router, entityIdRouteService, appContext) {
        return _super.call(this, paris, activatedEntity, globalEntityTypesService, router, entityIdRouteService, appContext) || this;
    }
    UrlEntityResolver.prototype.loadEntity = function (entityConstructor, entityId, route, state, params) {
        // // The url in the route is encoded 2 times. To get the actual url we need to decode it 2 times
        return this.paris.createItem(Url, { Url: decodeURIComponent(decodeURIComponent(entityId)) });
    };
    return UrlEntityResolver;
}(EntityResolver));
export { UrlEntityResolver };
