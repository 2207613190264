import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@wcd/auth';

@Pipe({ name: 'urlWithToken' })
export class UrlWithTokenPipe implements PipeTransform {
	constructor(private authService: AuthService) {}

	transform(value: string): string {
		return this.authService.addTenantIdToUrl(value);
	}
}
