export enum MachineExploitLevelType {
	NoKnownExploit = 'NoKnownExploit',
	HasExploit = 'HasExploit',
	ExploitVerified = 'ExploitVerified',
	InExploitKit = 'InExploitKit'
}

export interface IMachineExploitLevel {
	readonly id: MachineExploitLevelType;
	name: string;
	nameI18nKey: string;
	priority: number;
}
