import { Component, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { SecurityRecommendation, Tag } from '@wcd/domain';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';

@Component({
	selector: 'security-recommendation-entity-panel',
	templateUrl: './security-recommendation.entity-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecurityRecommendationEntityPanelComponent
	extends EntityPanelComponentBase<SecurityRecommendation, SecurityRecommendationDetailsOptions>
	implements AfterViewInit {
	get securityRecommendation(): SecurityRecommendation {
		return this.entity;
	}

	get singleAssetRecommendationOptions(): SecurityRecommendationDetailsOptions {
		return this.options;
	}

	constructor(changeDetectorRef: ChangeDetectorRef, private appInsightsService: AppInsightsService) {
		super(changeDetectorRef);
	}

	ngAfterViewInit() {
		this.appInsightsService.trackEvent('security-recommendation-panel-load', {
			id: this.entity.id,
			onboardingStatus: this.entity.onboardingStatus,
		} )
	}

	initEntity(securityRecommendation: SecurityRecommendation, isExtendedData: boolean = false) {
		super.initEntity(securityRecommendation, isExtendedData);
		this.changeDetectorRef.markForCheck();
	}
}

export interface SecurityRecommendationDetailsOptions {
	singleAssetRecommendation: boolean;
	machineId?: string;
}
