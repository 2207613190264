var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
export var AuthenticationParamsSerializationType;
(function (AuthenticationParamsSerializationType) {
    AuthenticationParamsSerializationType["SnmpAuthParams"] = "SnmpAuthParams";
    AuthenticationParamsSerializationType["WindowsAuthParams"] = "WindowsAuthParams";
})(AuthenticationParamsSerializationType || (AuthenticationParamsSerializationType = {}));
var AuthenticationParams = /** @class */ (function (_super) {
    __extends(AuthenticationParams, _super);
    function AuthenticationParams(type, previous) {
        var _this = _super.call(this) || this;
        _this.$type = type;
        if (!!previous) {
            _this.isKeyVault = previous.isKeyVault;
            _this.keyVaultUri = previous.keyVaultUri;
            _this.keyVaultSecretName = previous.keyVaultSecretName;
        }
        return _this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AuthenticationParams.prototype, "$type", void 0);
    __decorate([
        EntityField({ serialize: false }),
        __metadata("design:type", Boolean)
    ], AuthenticationParams.prototype, "isKeyVault", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AuthenticationParams.prototype, "keyVaultUri", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AuthenticationParams.prototype, "keyVaultSecretName", void 0);
    AuthenticationParams = __decorate([
        ValueObject({
            singularName: 'Authentication parameters',
            pluralName: 'Authentication parameters',
        }),
        __metadata("design:paramtypes", [String, AuthenticationParams])
    ], AuthenticationParams);
    return AuthenticationParams;
}(ModelBase));
export { AuthenticationParams };
