
		<div
			*ngIf="wcdTooltipAllowHtmlRendering"
			style="display: none"
			[attr.id]="helpUniqueId"
			[innerHtml]="htmlTooltip"
		></div>
		<ng-container *ngIf="!!showIcon; else noIcon">
			<span class="wcd-help-icon">
				<ng-container *ngTemplateOutlet="content"></ng-container>
				<fab-icon
					iconName="Info"
					*ngIf="showIcon"
					[contentClass]="
						'wcd-help-icon__i' +
						(isLight ? ' wcd-help-icon__i--light' : isLarge ? ' wcd-help-icon__i--large' : '')
					"
					role="button"
					[ariaLabel]="wcdTooltipAllowHtmlRendering ? 'tooltip' : null"
					aria-roledescription="tooltip"
					[attr.tabindex]="tabIndexValue"
					[wcdTooltip]="helpHtml"
					[wcdTooltipAllowHtmlRendering]="wcdTooltipAllowHtmlRendering"
					[wcdTooltipSanitizeHtml]="wcdTooltipSanitizeHtml"
					[wcdTooltipEnableKeyboardEvents]="wcdTooltipEnableKeyboardEvents"
					[attr.aria-label]="wcdTooltipAllowHtmlRendering ? null : toolTipAriaLabel"
					[attr.aria-labelledby]="wcdTooltipAllowHtmlRendering ? helpUniqueId : null"
					(focus)="onFabFocus($event)"
					(blur)="onFabBlur($event)"
					(keyup.enter)="preventDefault($event)"
					(keydown.enter)="preventDefault($event)"
				></fab-icon>
			</span>
		</ng-container>
		<ng-template #noIcon>
			<span
				class="wcd-help-icon"
				role="button"
				aria-roledescription="tooltip"
				[attr.tabindex]="tabIndexValue"
				[wcdTooltip]="helpHtml"
				[wcdTooltipAllowHtmlRendering]="wcdTooltipAllowHtmlRendering"
				[wcdTooltipSanitizeHtml]="wcdTooltipSanitizeHtml"
				[attr.aria-label]="wcdTooltipAllowHtmlRendering ? null : toolTipAriaLabel"
				[attr.aria-labelledby]="wcdTooltipAllowHtmlRendering ? helpUniqueId : null"
			>
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</span>
		</ng-template>

		<ng-template #content>
			<ng-content></ng-content>
		</ng-template>
	