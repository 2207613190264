import { OnChanges, EventEmitter, OnInit, SecurityContext, } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { sccHostService } from '@wcd/scc-interface';
var lastId = 0;
var HelpComponent = /** @class */ (function () {
    function HelpComponent(domSanitizer) {
        this.domSanitizer = domSanitizer;
        this.showIcon = true;
        this.isLight = false;
        this.tabIndexValue = 0;
        this.isLarge = false;
        this.wcdTooltipEnableKeyboardEvents = false;
        this.onFocus = new EventEmitter();
        this.onBlur = new EventEmitter();
        this.helpUniqueId = "help-" + ++lastId;
        this.linkRegex = /\[(.*?)\]\((.*?)\)/gim;
    }
    HelpComponent.prototype.ngOnInit = function () {
        this.toolTipAriaLabel = this.ariaLabel ? this.ariaLabel : this.text;
    };
    HelpComponent.prototype.ngOnChanges = function () {
        this._helpHtml = null;
    };
    Object.defineProperty(HelpComponent.prototype, "htmlTooltip", {
        get: function () {
            if (sccHostService.isSCC) {
                return sccHostService.ui.sanitize(this.helpHtml);
            }
            return this.domSanitizer.sanitize(SecurityContext.HTML, this.helpHtml);
        },
        enumerable: true,
        configurable: true
    });
    HelpComponent.prototype.onFabFocus = function (event) {
        event.target.setAttribute('tabindex', '0');
        this.onFocus.emit();
    };
    HelpComponent.prototype.onFabBlur = function (event) {
        event.target.setAttribute('tabindex', this.tabIndexValue);
        this.onBlur.emit();
    };
    Object.defineProperty(HelpComponent.prototype, "helpHtml", {
        get: function () {
            if (!this._helpHtml) {
                this._helpHtml = (this.title ? this.title + ":\n" : '') + (this.text ? "" + this.text : '');
            }
            // Support markdown links
            this._helpHtml = this._helpHtml.replace(this.linkRegex, "<a href='$2'>$1</a>");
            return this._helpHtml.trim();
        },
        enumerable: true,
        configurable: true
    });
    HelpComponent.prototype.preventDefault = function (event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    };
    return HelpComponent;
}());
export { HelpComponent };
