import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { SuppressionRuleConditionIdType } from './suppression-rule-condition-id-type.enum';
import { SuppressionRuleConditionType } from './suppression-rule-condition-type.entity';

@ValueObject({
	singularName: 'Suppression Rule Condition Item',
	pluralName: 'Suppression Rule Condition Items',
})
export class SuppressionRuleCondition extends ModelBase {
	@EntityField({ data: 'Type' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;
	@EntityField({ data: 'Type', parse: (stringValue) => SuppressionRuleConditionIdType[stringValue] })
	type: SuppressionRuleConditionType;
	@EntityField({ data: 'Value' })
	value: string;
}
