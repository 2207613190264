/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./summery-bars-with-legend.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/prettify/src/lib/pipes/pretty-number.pipe";
import * as i3 from "../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i4 from "../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i5 from "@angular/common";
import * as i6 from "../events-summary-bar/events-summary-bar.component.ngfactory";
import * as i7 from "../events-summary-bar/events-summary-bar.component";
import * as i8 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i9 from "@angular-react/fabric";
import * as i10 from "./summery-bars-with-legend.component";
var styles_SummeryBarsWithLegendComponent = [i0.styles];
var RenderType_SummeryBarsWithLegendComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SummeryBarsWithLegendComponent, data: {} });
export { RenderType_SummeryBarsWithLegendComponent as RenderType_SummeryBarsWithLegendComponent };
function View_SummeryBarsWithLegendComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-flex-1 wcd-font-weight-semibold cursor-pointer"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemClick(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.onItemClick(_v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 3, 0, currVal_0); }); }
function View_SummeryBarsWithLegendComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "wcd-flex-justify-end-horizontal wcd-font-weight-semibold flex-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t\t", "\n\t\t\t\t"])), i1.ɵpid(131072, i2.PrettyNumberPipe, [i3.PrettyNumberService, [2, i4.LocaleConfigService], [2, i1.ChangeDetectorRef]]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "color-text-gray-400"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["\n\t\t\t\t\t/ ", "\n\t\t\t\t"])), i1.ɵpid(131072, i2.PrettyNumberPipe, [i3.PrettyNumberService, [2, i4.LocaleConfigService], [2, i1.ChangeDetectorRef]]), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.parent.context.$implicit.amount)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.parent.context.$implicit.total)); _ck(_v, 4, 0, currVal_1); }); }
function View_SummeryBarsWithLegendComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "wcd-margin-mediumSmall-top"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "wcd-flex-horizontal-spaced"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummeryBarsWithLegendComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummeryBarsWithLegendComponent_4)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "events-summary-bars", [["class", "events-summary-bars wcd-margin-xsmall-top"]], null, null, null, i6.View_EventsSummaryBarComponent_0, i6.RenderType_EventsSummaryBarComponent)), i1.ɵdid(12, 573440, null, 0, i7.EventsSummaryBarComponent, [], { events: [0, "events"], kindColorMap: [1, "kindColorMap"], displayTextFormatter: [2, "displayTextFormatter"], size: [3, "size"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.amount >= 0) && (_v.context.$implicit.total > 0)); _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.context.$implicit.events; var currVal_3 = _co.settingsArray.colorMap; var currVal_4 = _co.settingsArray.textFormatter; var currVal_5 = _co.settingsArray.size; _ck(_v, 12, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_SummeryBarsWithLegendComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummeryBarsWithLegendComponent_2)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settingsArray.events; _ck(_v, 3, 0, currVal_0); }, null); }
function View_SummeryBarsWithLegendComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "wcd-inline-block wcd-padding-small-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fab-icon", [["iconName", "SquareShapeSolid"]], null, null, null, i8.View_FabIconComponent_0, i8.RenderType_FabIconComponent)), i1.ɵdid(3, 5816320, null, 0, i9.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(4, null, ["\n\t\t", "\n\t"]))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "wcd-vertical-align-bottom ", _v.context.$implicit.iconClassName, ""); var currVal_1 = "SquareShapeSolid"; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); }); }
function View_SummeryBarsWithLegendComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-padding-mediumSmall-top"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummeryBarsWithLegendComponent_6)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.legendItems; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SummeryBarsWithLegendComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummeryBarsWithLegendComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummeryBarsWithLegendComponent_5)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settingsArray; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.showLegend && _co.legendItems); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_SummeryBarsWithLegendComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "summery-bars-with-legend", [], null, null, null, View_SummeryBarsWithLegendComponent_0, RenderType_SummeryBarsWithLegendComponent)), i1.ɵdid(1, 49152, null, 0, i10.SummeryBarsWithLegendComponent, [], null, null)], null, null); }
var SummeryBarsWithLegendComponentNgFactory = i1.ɵccf("summery-bars-with-legend", i10.SummeryBarsWithLegendComponent, View_SummeryBarsWithLegendComponent_Host_0, { settingsArray: "settingsArray", legendItems: "legendItems", showLegend: "showLegend" }, { onClick: "onClick" }, []);
export { SummeryBarsWithLegendComponentNgFactory as SummeryBarsWithLegendComponentNgFactory };
