import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { AppInsightsService } from '../../insights/services/app-insights.service';

@Injectable()
export class ClipboardService {
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private appInsightsService: AppInsightsService
	) {}

	public copy(value: string): Observable<string> {
		return new Observable(observer => {
			let textarea: HTMLTextAreaElement;

			try {
				textarea = this.document.createElement('textarea');
				textarea.style.height = textarea.style.width = '0px';
				textarea.style.left = textarea.style.top = '-100px';
				textarea.style.opacity = '0';
				textarea.style.position = 'fixed';
				this.document.body.appendChild(textarea);

				textarea.value = value;
				textarea.select();

				this.document.execCommand('copy');
				observer.next(value);
			} catch (err) {
				this.appInsightsService.trackException(err);
				observer.error(`failed to copy content to clipboard. ${err}`);
			} finally {
				if (textarea && textarea.parentNode) {
					textarea.parentNode.removeChild(textarea);
				}
				observer.complete();
			}
		});
	}
}
