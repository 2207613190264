import { Component, Input, ElementRef, ViewEncapsulation, AfterViewInit, HostListener } from '@angular/core';
import { select as d3Select, Selection as D3Selection, BaseType as D3BaseType } from 'd3-selection';
import { scaleTime, scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
import { extent, mouse, bisector, ScaleTime, ScaleLinear, area } from 'd3';
import { ChartColor, DateValuePoint } from '../line/public_api';

export interface AreaChartOptions {
	data: DateValuePoint[];
	color?: ChartColor;
	yMinValue?: number; // If not set will be 0
	yMaxValue?: number; // If not set will be 100
	width?: number; // If not set will be take from the width which occupied by the paren
	height?: number; // If not set will be taken from the height and divided by default ratio
	id?: string; // If not set will be areaChart
}

// TODO: evhvoste - Task 27434820: Refactor D3 Area and Line chart components into a mutual base
@Component({
	selector: 'wcd-area-chart',
	template: `
		<div [id]="chartId" class="wcd-area-chart"></div>
	`,
	styleUrls: ['./area-chart.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AreaChartComponent implements AfterViewInit {
	private readonly OPACITY_TRANSITION_MS = 150;

	private readonly MOUSE_OUT_TIMEOUT = 150;
	private readonly SHOW_TOOLTIP_TIMEOUT = 90;
	private readonly WIDTH_TO_HEIGHT_RATIO = 2.5;

	private readonly DEFAULT_AREA_CHART_ID = 'areaChart';

	private _mouseCoordinates: [number, number];
	private _insideTooltip: boolean;

	private _chartOptions: AreaChartOptions;
	private _chartData: DateValuePoint[];
	private _afterViewInit = false;

	private _chartColor: string;

	chartId: string;

	@Input() set options(chartOptions: AreaChartOptions) {
		if (!chartOptions) {
			return;
		}

		this._chartOptions = chartOptions;
		this._chartData = chartOptions.data;
		this._chartColor = chartOptions.color;
		this.chartId = chartOptions.id ? chartOptions.id : this.DEFAULT_AREA_CHART_ID;

		if (this._afterViewInit) {
			setTimeout(() => this.drawChart());
		}
	}

	private bisectDate = bisector((d: any, x: any) => x - d.date).left;

	private _rootEl: HTMLElement;

	private _rootD3El: D3Selection<HTMLElement, {}, null, undefined>;
	private _svgD3El: D3Selection<D3BaseType, {}, null, undefined>;
	private _focus: D3Selection<SVGGElement, {}, null, undefined>;
	private _tooltip: D3Selection<HTMLDivElement, {}, null, undefined>;

	private _xScale: ScaleTime<number, number>;
	private _yScale: ScaleLinear<number, number>;

	private _chartWidth: number;
	private _chartHeight: number;
	private _rootElementHeight: number;

	constructor(private elementRef: ElementRef) {}

	ngAfterViewInit() {
		const elementId = '#' + this.chartId;
		this._rootEl = this.elementRef.nativeElement.querySelector(elementId);
		this._rootD3El = d3Select(this._rootEl);

		setTimeout(() => {
			this.drawChart();
			this._rootElementHeight = this._rootEl.clientHeight;
			this._afterViewInit = true;
		}, 0);
	}

	@HostListener('window:resize', ['$event'])
	onResize(_) {
		this.drawChart();
	}

	private stopHoverExperience() {
		this.setDefaultTooltipOpacity(0);
		this._focus.style('display', 'none');
	}

	private drawChart() {
		if (!this._chartData) {
			return;
		}
		this._rootD3El.html('');
		this._chartWidth = this._chartOptions.width || this._rootEl.clientWidth;

		const height = this._chartOptions.height || Math.round(this._chartWidth / this.WIDTH_TO_HEIGHT_RATIO);
		this._chartHeight = height - 2; // To leave 2 px for the stroke from the top and from the bottom

		this._svgD3El = this._rootD3El
			.append('svg')
			.attr('width', this._chartWidth)
			.attr('height', height)
			.append('g')
			.attr('transform', 'translate(0,1)');

		this._xScale = scaleTime()
			.range([0, this._chartWidth])
			.domain(extent(this._chartData, d => d.date));

		this._yScale = scaleLinear()
			.range([this._chartHeight, 0])
			.domain([this._chartOptions.yMinValue || 0, this._chartOptions.yMaxValue || 100]);

		this.setHoverBehaviorInsideGraph();

		const valueLine = line()
			.x(d => this._xScale(d['date']))
			.y(d => this._yScale(+d['value']));

		const areaScale = area()
			.x(d => this._xScale(d['date']))
			.y0(this._chartHeight)
			.y1(d => this._yScale(+d['value']));

		this._svgD3El
			.append('path')
			.datum(this._chartData)
			.attr('d', <any>areaScale)
			.attr('class', 'chart-area')
			.style('fill', this._chartColor);

		this._svgD3El
			.append('path')
			.datum(this._chartData)
			.attr('d', <any>valueLine)
			.attr('class', 'chart-line')
			.style('stroke', this._chartColor);

		this._tooltip = this._rootD3El.append('div').attr('class', 'hover-tooltip');

		this._svgD3El
			.append('rect')
			.attr('transform', 'translate(0,1)')
			.attr('class', 'overlay')
			.attr('width', this._chartWidth)
			.attr('height', this._chartHeight)
			.on('mouseout', () =>
				// to wait for event when hovered into the tooltip
				setTimeout(() => {
					if (!this._insideTooltip) {
						// left the component (not inside the event tooltip)
						this.stopHoverExperience();
					}
				}, this.MOUSE_OUT_TIMEOUT)
			)
			.on('mousemove', (_, j, element) => {
				if (this._insideTooltip) {
					return;
				}
				this._mouseCoordinates = mouse(element[j]);
				const pointIndex = this.getHoveredPointIndex(this._mouseCoordinates[0]);
				const hoveredDataPoint = this._chartData[pointIndex];

				setTimeout(() => {
					if (!this._mouseCoordinates) {
						// To avoid race condition where hovered in event and left the area before timeout
						return;
					}
					this.onMouseMoveInEventRectangleHandler(hoveredDataPoint);
				}, this.SHOW_TOOLTIP_TIMEOUT);
			});
	}

	private getHoveredPointIndex(mouseXCoordinate: number): number {
		const hoveredDate = this._xScale.invert(mouseXCoordinate);
		const dateIndex = this.bisectDate(this._chartData, hoveredDate);

		if (dateIndex === this._chartData.length) {
			// overflow from the left
			return dateIndex - 1;
		} else if (dateIndex === 0) {
			// overflow from the right
			return 0;
		} else {
			const neighborIndex = dateIndex - 1;
			// d0 & d1 are the points closest to the hovered date -> the closest is set into d
			const d0 = this._chartData[dateIndex];
			const d1 = this._chartData[neighborIndex];

			return +hoveredDate - +d0.date > +d1.date - +hoveredDate ? neighborIndex : dateIndex;
		}
	}

	private setDefaultTooltipOpacity(opacity: number) {
		this._tooltip
			.transition()
			.duration(this.OPACITY_TRANSITION_MS)
			.style('opacity', opacity);
	}

	private onMouseMoveInEventRectangleHandler(dataPoint: DateValuePoint) {
		this.showHoveredFocusLine(dataPoint);

		const tooltipWidth = this._tooltip.node().getBoundingClientRect().width;
		const tooltipHeight = this._tooltip.node().getBoundingClientRect().height;

		const leftPosition = this.getLeftTooltipPosition(tooltipWidth);

		const bottomPositionCalc = this._rootElementHeight - this._yScale(dataPoint.value);

		const bottomPosition =
			bottomPositionCalc + tooltipHeight > this._rootElementHeight // Not enough px for the tooltip
				? this._rootElementHeight - tooltipHeight
				: bottomPositionCalc;

		this._tooltip
			.html(() => this.renderDefaultTooltipHtml(dataPoint))
			.style('left', leftPosition + 'px')
			.style('bottom', bottomPosition + 'px');

		this.setDefaultTooltipOpacity(1);
	}

	showHoveredFocusLine(d: DateValuePoint) {
		const hoverLineHeightBelow = this._chartHeight - this._yScale(d.value);

		this._focus
			.select('.hover-line')
			.attr('y2', hoverLineHeightBelow)
			.attr('y1', -this._chartHeight + hoverLineHeightBelow);

		this._focus
			.attr('transform', `translate(${this._xScale(d.date)},${this._yScale(d.value)})`)
			.style('display', null);
	}

	private getLeftTooltipPosition(tooltipWidth: number): number {
		const leftPosition = this._mouseCoordinates[0];

		return this._chartWidth - leftPosition < tooltipWidth
			? this._chartWidth - tooltipWidth
			: leftPosition;
	}

	private setHoverBehaviorInsideGraph() {
		this._focus = this._svgD3El.append('g').attr('class', 'focus');

		this._focus
			.append('line')
			.attr('class', 'hover-line')
			.attr('y1', 0)
			.attr('y2', -this._chartHeight);

		this._focus
			.append('circle')
			.attr('class', 'chart-hover-dot')
			.attr('r', 2)
			.style('fill', this._chartColor);
	}

	private renderDefaultTooltipHtml(hoveredPoint: DateValuePoint): string {
		return `<div style="padding: 2px 4px ; background: white;box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);">
		${hoveredPoint.value}</div>`;
	}
}
