import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Remediation error details',
	pluralName: 'Remediation errors details',
})
export class RemediationErrorDetails extends ModelBase {
	@EntityField({
		data: 'itsmStatusCode',
	})
	readonly itsmStatusCode?: number;

	@EntityField({
		data: 'externalServiceStatusCode',
	})
	readonly externalServiceStatusCode?: number;

	@EntityField({
		data: 'ticketServiceStatusCode',
	})
	readonly ticketServiceStatusCode: number;

	@EntityField({
		data: 'details',
	})
	readonly details?: string;
}
