/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../i18n/src/lib/services/i18n.service";
import * as i3 from "./pie-chart.component";
import * as i4 from "../../../../prettify/src/lib/services/pretty-number.service";
var styles_PieChartComponent = [".wcd-pie-align-center[_ngcontent-%COMP%] {\n\t\t\ttext-align: center;\n\t\t}"];
var RenderType_PieChartComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_PieChartComponent, data: {} });
export { RenderType_PieChartComponent as RenderType_PieChartComponent };
export function View_PieChartComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "pie-chart-wrapper"]], [[8, "id", 0], [2, "wcd-full-height", null], [1, "aria-label", 0], [2, "wcd-pie-align-center", null]], null, null, null, null)), i0.ɵppd(3, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "chart"]], [[2, "wcd-full-height", null], [1, "data-track-id", 0], [1, "data-track-type", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "pie-chart-content"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), i0.ɵncd(null, 0), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("pie-chart-" + _co.id); var currVal_1 = _co.isFullHeight; var currVal_2 = (_co.ariaLabel || i0.ɵunv(_v, 2, 2, _ck(_v, 3, 0, i0.ɵnov(_v, 0), "charts.pieChart"))); var currVal_3 = _co.alignCenter; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.isFullHeight; var currVal_5 = ((_co.settings.tracking == null) ? null : _co.settings.tracking.id); var currVal_6 = ((_co.settings.tracking == null) ? null : _co.settings.tracking.type); _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_PieChartComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-pie-chart", [], null, null, null, View_PieChartComponent_0, RenderType_PieChartComponent)), i0.ɵdid(1, 770048, null, 0, i3.PieChartComponent, [i0.ElementRef, i4.PrettyNumberService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PieChartComponentNgFactory = i0.ɵccf("wcd-pie-chart", i3.PieChartComponent, View_PieChartComponent_Host_0, { settings: "settings", data: "data", change: "change", enableRenderOnSettingsChanges: "enableRenderOnSettingsChanges", upperTitle: "upperTitle", labelsOutside: "labelsOutside", allowTitleClick: "allowTitleClick", isFullHeight: "isFullHeight", ariaLabel: "ariaLabel", role: "role", alignCenter: "alignCenter" }, { titleClick: "titleClick" }, ["*"]);
export { PieChartComponentNgFactory as PieChartComponentNgFactory };
