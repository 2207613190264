import { SevilleModule } from '../../seville/seville.module';
import { MdatpMitreService } from '@wcd/scc-common';

SevilleModule.filter('alertMitreTechniqueGetValid', function () {
	return function (mitreTechniquesString) {
		return MdatpMitreService.parseAndFilterMitreTechniqueIds(mitreTechniquesString);
	};
});

SevilleModule.filter('alertMitreTechniqueGetName', function () {
	return function (mitreTechniqueId) {
		return MdatpMitreService.getMitreTechniqueName(mitreTechniqueId);
	};
});
