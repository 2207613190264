import { Component, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { DataviewField } from '@wcd/dataview';
import { ModelBase, Paris } from '@microsoft/paris';
import { BaselineConfiguration, BaselineProfileDetails, BaselineProfileConfigurationIds, ConfigurationProfile, ConfigurationProfilesApiCall } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EntityPanelComponentBase } from '../../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { ActivatedEntity } from '../../../../../../global_entities/services/activated-entity.service';
import { TabModel } from '../../../../../../shared/components/tabs/tab.model';
import { Router } from '@angular/router';

enum TabIds {
	configurationDetails = 'configurationDetails',
	applicableProfiles = 'applicableProfiles',
	devices = 'devices'
}

@Component({
	selector: 'profile-configuration-entity-panel',
	templateUrl: './profile-configuration.entity-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileConfigurationEntityPanelComponent extends EntityPanelComponentBase<BaselineConfiguration> implements OnDestroy {
	private _subscription: Subscription;
	private _applicableProfilesSubscription: Subscription;
	profileConfigurationIds: BaselineProfileConfigurationIds;
	singleAssetPanel: boolean;
	profileName: string;
	profileId: string;

	tabs: Array<TabModel> = [];
	currentTab: TabModel;
	tabIds = TabIds;
	applicableProfiles: ConfigurationProfile[];
	applicableProfilesFields: Array<DataviewField<ConfigurationProfile>>;
	isProfileConfiguration: boolean;

	get configuration(): BaselineConfiguration {
		return this.entity;
	}

	constructor(
		readonly i18nService: I18nService,
		private activatedEntity: ActivatedEntity,
		changeDetectorRef: ChangeDetectorRef,
		private paris: Paris,
		private router: Router) {
		super(changeDetectorRef);
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
		this._applicableProfilesSubscription && this._applicableProfilesSubscription.unsubscribe();
	}

	initEntity(configuration: BaselineConfiguration, isExtendedData: boolean = false) {
		super.initEntity(configuration, isExtendedData);
		this.changeDetectorRef.markForCheck();

		this.tabs = this.getTabs();
		this.currentTab = this.tabs[0];

		if (this.configuration.isProfileConfiguration) {
			this._subscription = this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof BaselineProfileDetails))
				.subscribe((profile: BaselineProfileDetails) => {
					this.profileName = profile.name;
					this.profileId = profile.id;
				});
		}

		this.singleAssetPanel = this.configuration.applicableDevices === 1;
		this._applicableProfilesSubscription = this.paris.apiCall<ConfigurationProfile[]>(ConfigurationProfilesApiCall, {
			configurationId: this.configuration.id
		}).subscribe(profiles => this.applicableProfiles = profiles);

		this.applicableProfilesFields = DataviewField.fromList<ConfigurationProfile>([
			{
				id: 'name',
				name: this.i18nService.strings.tvm_common_name,
				onClick: (profile: ConfigurationProfile) => {
					this.activatedEntity.setNextEntity(profile);
					this.router.navigate([`/baseline-compliance_t/profiles/${encodeURIComponent(profile.id)}/configurations`]);
				},
			}]);

		this.isProfileConfiguration = this.configuration.isProfileConfiguration;
	}

	setTab(tab: TabModel) {
		this.currentTab = tab;
	}

	private getTabs(): Array<TabModel> {
		return [
			{
				id: this.tabIds.configurationDetails,
				name: this.i18nService.strings.tvm_baseline_profile_entity_configurationTab_sidePanel_configurationDetails_title,
			},
			!this.configuration.isProfileConfiguration &&
			{
				id: this.tabIds.applicableProfiles,
				name: this.i18nService.strings.tvm_baseline_device_widget_applicable_profiles_column,
			},
			{
				id: this.tabIds.devices,
				name: this.i18nService.strings.machines,
			}
		].filter(Boolean).map(tab => new TabModel(tab));
	}
}
