import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { FlavorService, TvmLicensesAngularService, Feature, FeaturesService } from '@wcd/config';

@Injectable()
export class TvmLicenseMigrationFlavorGuard implements CanActivate {
	constructor(
		private tvmLicensesService: TvmLicensesAngularService,
		private flavorService: FlavorService,
		private featuresService: FeaturesService
	) {}

	canActivate(route: ActivatedRouteSnapshot) {
		const data = route.routeConfig.data;
		const features =
			data && data.tvmLicenseAddedFeatureCheck
				? [Feature.TvmPremium, data.tvmLicenseAddedFeatureCheck]
				: [Feature.TvmPremium];
		const tvmLicensesEnabled = this.featuresService.isEnabled(features);
		const isEnabledByFlavor = data && this.flavorService.isEnabled(data.flavorConfig);
		const isEnabledByTvmLicenses = data && this.tvmLicensesService.isEnabled(data.tvmLicenseType);

		// elkamin: remove once the Nibiru code is in
		const shouldShowToSmb = data && data.shouldShowToSmb;
		const isEnabledBySmbFlavor = shouldShowToSmb && this.flavorService.isEnabled({ mdeFlavors: ['Smb'] });

		return tvmLicensesEnabled ? isEnabledByTvmLicenses || isEnabledBySmbFlavor : isEnabledByFlavor;
	}
}
