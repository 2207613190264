var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { ExtendedOutbreaksCalculation } from './extended-outbreaks-calculation.value-object';
var ɵ0 = function (config, query) {
    return query && query.where && query.where.isMtp ?
        query.where.isK8sMigration ? 'outbreaks/outbreaksEnrichedDataMtp' : 'v2/outbreaksEnrichedDataMtp'
        : query && query.where && query.where.isK8sMigration ? 'outbreaks/outbreaksEnrichedData' : 'outbreaksEnrichedData';
}, ɵ1 = function (config) { return config.data.serviceUrls.threatAnalytics; };
var ExtendedOutbreaksApiCall = /** @class */ (function (_super) {
    __extends(ExtendedOutbreaksApiCall, _super);
    function ExtendedOutbreaksApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExtendedOutbreaksApiCall = __decorate([
        ApiCall({
            name: 'Get extended outbreaks',
            // Once MDATP portal is deprecated the endpoint is always outbreaksEnrichedDataMtp
            endpoint: ɵ0,
            method: 'GET',
            type: ExtendedOutbreaksCalculation,
            baseUrl: ɵ1,
            cache: {
                time: 1000 * 60
            }
        })
    ], ExtendedOutbreaksApiCall);
    return ExtendedOutbreaksApiCall;
}(ApiCallModel));
export { ExtendedOutbreaksApiCall };
export { ɵ0, ɵ1 };
