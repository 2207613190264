import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { UserStatusTypes } from '../../AAD/user/aad.user-status';

export const stringToNumberParse = (value: string | number) =>
	typeof value === 'string' ? parseInt(value, 10) : value;

@Entity({
	...airsEntityConfig,
	singularName: 'Account',
	pluralName: 'Accounts',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.Account,
})
export class AirsAccount extends AirsEntity {
	@EntityField({ data: 'sid' })
	sid: string;

	@EntityField({ data: ['aad_user_id', 'aad_id'] })
	aadUserId: string;

	@EntityField({ data: ['display_name', 'name'] })
	userDisplayName: string;

	@EntityField({ data: 'upn' })
	upn: string;

	@EntityField({ data: 'email' })
	email: string;

	@EntityField({ data: 'dns_domain' })
	dnsDomain: string;

	@EntityField({ data: 'upn_suffix' })
	upnSuffix: string;

	@EntityField({ data: 'username' })
	username: string;

	@EntityField({ data: 'nt_domain' })
	ntDomain: string;

	@EntityField({ data: 'user_status' })
	userStatus: UserStatusTypes;

	@EntityField({
		data: 'investigation_priority',
		parse: priority => stringToNumberParse(priority),
	})
	investigationPriority: number;

	@EntityField({
		data: 'mcas_percentile',
		parse: percentile => stringToNumberParse(percentile),
	})
	percentile?: number;

	constructor(data) {
		super(data);
		this.entityName = this.userDisplayName || this.entityName;
		this.id = this.sid || this.aadUserId;
	}
}
