import { Injectable } from '@angular/core';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { getUserTimeZone } from '@wcd/localization';

const SYSTEM_EXCLUSIONS_API_ENDPOINT = '/acl';

@Injectable()
export class SystemExclusionsBackendService extends StoreBackendBase {
	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService
	) {
		super(http, downloadService);
	}

	get apiUrl() {
		return this.serviceUrlsService.automatedIr + SYSTEM_EXCLUSIONS_API_ENDPOINT;
	}

	remove(id: number, options: any): Observable<any> {
		return this.http.delete(`${this.apiUrl}/${id}?type=${options.type}`);
	}

	create(obj: any, options?: any): Observable<any> {
		return this.http.post(this.getUpdateUrl(options), SystemExclusionsBackendService.getItemData(obj));
	}

	update(id: number, data: any, options?: any): Observable<any> {
		return this.http.put(
			this.getUpdateUrl(options, id),
			SystemExclusionsBackendService.getItemData(data)
		);
	}

	getSystemExclusionTypeCounts(): Observable<any> {
		return this.http.get(`${this.apiUrl}/count`);
	}

	createMemoryContentsAcl(memoryContentsId: number, aclType: number): Observable<any> {
		return this.post('memory_content', {
			memory_content: memoryContentsId,
			acl_type: aclType,
		}).pipe(map(this.extractData));
	}

	removeMemoryContentsAcl(memoryContentsId: number): Observable<any> {
		return this.delete(`memory_content/${memoryContentsId}`);
	}

	private getUpdateUrl(options: any, id?) {
		const urlId = id || 'all';
		const url =
			options.type === 'certificate'
				? `${this.apiUrl}/certificate/upload`
				: `${this.apiUrl}/${urlId}?type=${options.type}`;
		return `${url}${/\?/.test(url) ? '&' : '?'}time_offset=${getUserTimeZone()}`;
	}

	private static getItemData(obj: any): any {
		let postData = obj;

		if (obj.file) {
			postData = new FormData();
			postData.append('file[]', obj.file);

			for (const p in obj) {
				if (p !== 'file') postData.append(p, obj[p]);
			}
		}

		return postData;
	}
}
