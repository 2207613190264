import { NgModule } from '@angular/core';
import { TzDatePipe } from './pipes/tz-date.pipe';

const pipes = [TzDatePipe];

@NgModule({
	declarations: [...pipes],
	exports: [...pipes],
})
export class LocalizationModule {}
