var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { AssessmentJob, AssessmentType, DeleteAuthenticatedScan, WindowsAuthType, } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { Feature, FeaturesService, TvmLicensesAngularService } from '@wcd/config';
import { TvmLicenseType } from '@wcd/scc-common';
import { AssessmentJobEncryptionService } from './assessment-job.encryption.service';
import { cloneDeep } from 'lodash-es';
var AssessmentJobService = /** @class */ (function () {
    function AssessmentJobService(paris, i18nService, dialogsService, featuresService, tvmLicensesService, encryptionService) {
        var _this = this;
        this.paris = paris;
        this.i18nService = i18nService;
        this.dialogsService = dialogsService;
        this.featuresService = featuresService;
        this.tvmLicensesService = tvmLicensesService;
        this.encryptionService = encryptionService;
        this.scanNames = new Set();
        this.maxAllowedIpAddressesForScan = 1024;
        this.repository = this.paris.getRepository(AssessmentJob);
        this._scanNamesSubscription = this.repository.allItems$.subscribe(function (scans) {
            scans.map(function (scan) { return _this.scanNames.add(scan.scanName); });
        });
    }
    AssessmentJobService.prototype.getRepository = function () {
        return this.repository;
    };
    AssessmentJobService.prototype.isScanNameInUse = function (scanName, itemBeforeChange) {
        if ((itemBeforeChange && itemBeforeChange.scanName === scanName) || !this.scanNames.has(scanName)) {
            return false;
        }
        return true;
    };
    AssessmentJobService.prototype.updateScanNames = function (scanName, scanNameBeforeChange) {
        if (scanNameBeforeChange) {
            this.scanNames.delete(scanNameBeforeChange);
        }
        this.scanNames.add(scanName);
    };
    AssessmentJobService.prototype.deleteItems = function (items) {
        var _this = this;
        var itemName = (items.length === 1
            ? this.repository.entity.singularName
            : this.repository.entity.pluralName).toLowerCase();
        return this.dialogsService
            .confirm({
            title: this.i18nService.get('deleteItem', { itemName: itemName }),
            text: this.i18nService.get('deleteConfirm', { itemPluralName: itemName }),
            confirmText: this.i18nService.get('delete'),
        })
            .then(function (e) {
            return e.confirmed && _this.paris.apiCall(DeleteAuthenticatedScan, items).toPromise();
        })
            .then(function () { return items.map(function (scan) { return _this.scanNames.delete(scan.scanName); }); });
    };
    AssessmentJobService.prototype.saveItem = function (item, next, errorHandling, itemBeforeChange) {
        return __awaiter(this, void 0, void 0, function () {
            var processedItem;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.preProcessAuthenticatedScanBeforeSendingToBackend(item)];
                    case 1:
                        processedItem = _a.sent();
                        this.repository
                            .save(processedItem)
                            .toPromise()
                            .then(function () {
                            next();
                            itemBeforeChange
                                ? _this.updateScanNames(processedItem.scanName, itemBeforeChange.scanName)
                                : _this.updateScanNames(processedItem.scanName);
                        })
                            .catch(function (error) { return errorHandling(error); });
                        return [2 /*return*/];
                }
            });
        });
    };
    AssessmentJobService.prototype.preProcessAuthenticatedScanBeforeSendingToBackend = function (assessmentJob) {
        return __awaiter(this, void 0, void 0, function () {
            var scanCopy, scanAuthAsSnmp, scanAuthAsWindows, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        scanCopy = cloneDeep(assessmentJob);
                        scanCopy.originalTargetRanges = this.preProcessIpAddresses(scanCopy.originalTargetRanges);
                        scanCopy.target = this.preProcessIpAddresses(scanCopy.target);
                        if (!scanCopy.auth) return [3 /*break*/, 2];
                        switch (scanCopy.scanType) {
                            case AssessmentType.NetworkAssessment: {
                                scanAuthAsSnmp = scanCopy.auth;
                                scanAuthAsSnmp.type = this.preProcessType(scanAuthAsSnmp.type);
                                break;
                            }
                            case AssessmentType.WindowsAssessment: {
                                scanAuthAsWindows = scanCopy.auth;
                                // TODO: remove ntlm handling after deprecation
                                scanAuthAsWindows.type =
                                    scanAuthAsWindows.type === WindowsAuthType.LocalNtlm ||
                                        scanAuthAsWindows.type === WindowsAuthType.DomainNtlm
                                        ? 'NTLM'
                                        : scanAuthAsWindows.type;
                                break;
                            }
                        }
                        if (!this.isE2EEncryptionFeatureEnabled()) return [3 /*break*/, 2];
                        _a = scanCopy;
                        return [4 /*yield*/, this.encryptionService.encrypt(JSON.stringify(scanCopy.auth))];
                    case 1:
                        _a.authEncrypted = _b.sent();
                        scanCopy.auth = null;
                        _b.label = 2;
                    case 2: return [2 /*return*/, scanCopy];
                }
            });
        });
    };
    AssessmentJobService.prototype.extractAuthTypeFromAssessment = function (assessmentJob) {
        switch (assessmentJob.scanType) {
            case AssessmentType.NetworkAssessment: {
                var scanAuthAsSnmp = assessmentJob.auth;
                return scanAuthAsSnmp.type;
            }
            case AssessmentType.WindowsAssessment: {
                var scanAuthAsWindows = assessmentJob.auth;
                return scanAuthAsWindows.type;
            }
        }
    };
    //Remove white spaces or newlines and replace with Comma and space separation(, )
    AssessmentJobService.prototype.preProcessIpAddresses = function (target) {
        return target ? target.replace(new RegExp(',\\s*|\\s+', 'g'), ', ') : target;
    };
    AssessmentJobService.prototype.countIpAddresses = function (target) {
        var listOfAddresses = this.preProcessIpAddresses(target).split(',');
        var deviceCount = 0;
        listOfAddresses.forEach(function (ip) {
            if (ip.includes('/')) {
                var mask = parseInt(ip.split('/')[1]);
                deviceCount += Math.pow(2, 32 - mask);
            }
            else {
                deviceCount++;
            }
        });
        return deviceCount;
    };
    //Remove white spaces or newlines and replace with Comma and space separation(, )
    AssessmentJobService.prototype.preProcessType = function (type) {
        return type ? type.replace(/ /g, '') : type;
    };
    AssessmentJobService.prototype.ngOnDestroy = function () {
        this._scanNamesSubscription && this._scanNamesSubscription.unsubscribe();
    };
    AssessmentJobService.prototype.isWindowsScanLicenseEnabled = function () {
        return this.tvmLicensesService.isEnabled(TvmLicenseType.TvmPremium);
    };
    AssessmentJobService.prototype.isWindowsScanFeatureEnabled = function () {
        return this.featuresService.isEnabled(Feature.NdrWindowsAuthScan);
    };
    AssessmentJobService.prototype.isWindowsScanNewAuthEnabled = function () {
        return this.featuresService.isEnabled(Feature.NdrWindowsAuthScanNewAuth);
    };
    AssessmentJobService.prototype.isE2EEncryptionFeatureEnabled = function () {
        return this.featuresService.isEnabled(Feature.PetraE2EEncryption);
    };
    return AssessmentJobService;
}());
export { AssessmentJobService };
