import { SevilleModule } from '../../seville/seville.module';
import { cloneDeep, assign } from 'lodash-es';

SevilleModule.service('exportToCsvService', exportToCsvService);

exportToCsvService.$inject = [
	'$log',
	'$http',
	'$httpParamSerializer',
	'$q',
	'$uibModal',
	'$timeout',
	'urlMapping',
];

function exportToCsvService($log, $http, $httpParamSerializer, $q, $uibModal, $timeout, urlMapping) {
	var service = {
		openDialog: openDialog,
		exportResults: exportResults,
	};

	return service;

	/*
	 * Opens the exports dialog. The options are:
	 * title:        the dialog title
	 * maxDays:      maximum days of data to exports
	 * maxDaysInColdStorage: maximum days of data in cold storage to exports. Optional, defaults to maxDays
	 * timelineDate: the date currently selected/ displayed. Optional, defaults to today
	 * httpRequestParams: the GET parameters for the exports request(s).
	 * downloadUrl:   The CSV stream download URL.
	 * checkResultsUrl: URL to check for existence of events. Optional.
	 * getAuthTokenUrl: URL to get an auth token for the download, if required. Optional.
	 */
	function openDialog(exportToCsvOptions) {
		if (!exportToCsvOptions.downloadUrl) {
			throw new Error('exportToCsvService.openDialog: downloadUrl is mandatory.');
		}

		exportToCsvOptions.model = exportToCsvOptions.httpRequestParams;

		// Set up the exports callback to use the specified URLs and date range
		exportToCsvOptions.exportCallback = function(model, fromDate, toDate, canceler) {
			var params = exportToCsvOptions.httpRequestParams
				? cloneDeep(exportToCsvOptions.httpRequestParams)
				: {};
			params.fromDate = momentToUtcIsoString(fromDate);
			params.toDate = momentToUtcIsoString(toDate);

			return exportResults({
				params: params,
				checkResultsUrl: exportToCsvOptions.checkResultsUrl,
				getAuthTokenUrl: exportToCsvOptions.getAuthTokenUrl,
				downloadUrl: exportToCsvOptions.downloadUrl,
				canceler: canceler,
			});
		};

		// Open the dialog modal, and pass the exportToCsvOptions to the controller
		return $uibModal.open({
			animation: false,
			template: `
			<div>
			<div class="row">
				<div class="col-md-12">
					<div class="export-to-csv-dialog-header">
						{{ ::exportdiag.title }}
					</div>
					<div class="export-to-csv-close-icon pull-right" ng-click="exportdiag.cancel()">
						<i class="icon icon-Cancel"></i>
					</div>
				</div>
			</div>

			<div class="modal-body" id="modal-body">
				<div>
					<button class="btn btn-link dropdown-toggle export-to-csv-dialog-button"
							ng-class="{'export-to-csv-disabled': exportdiag.exportInProgress}"
							ng-click="exportdiag.chooseTimelineDate()">
						<span ng-if="!exportdiag.currentDateChosen">
							<i class="icon icon-RadioBtnOff export-to-csv-dialog-button-off"></i>
						</span>
						<span ng-if="exportdiag.currentDateChosen">
							<i class="icon icon-CompletedSolid"></i>
						</span>
					</button>
					<span class="export-to-csv-row-text">
						Current date
						<span class="export-to-csv-date">
							({{exportdiag.timelineDate.toDate() | sevilleDate : 'date'}})
						</span>
					</span>
				</div>
				<div>
					<button class="btn btn-link dropdown-toggle export-to-csv-dialog-button"
							ng-class="{'export-to-csv-disabled': exportdiag.exportInProgress}"
							ng-click="exportdiag.chooseDates()">
						<span ng-if="exportdiag.currentDateChosen">
							<i class="icon icon-RadioBtnOff export-to-csv-dialog-button-off"></i>
						</span>
						<span ng-if="!exportdiag.currentDateChosen">
							<i class="icon icon-CompletedSolid"></i>
						</span>
					</button>
					<span class="export-to-csv-row-text">
						From:
					</span>
					<div class="export-to-csv-date-picker"
						 ng-class="{'export-to-csv-disabled': exportdiag.exportInProgress}"
						 ng-click="exportdiag.chooseDates()">
						<date-picker selected-date="exportdiag.fromDate"
									 date-format="'MMM DD YYYY | HH:mm'"
									 min-date="exportdiag.minFromDate"
									 max-date="exportdiag.maxFromDate"
									 input-aria-label="'from'" />
					</div>
					<span class="export-to-csv-totext">
						to:
					</span>
					<div class="export-to-csv-date-picker"
						 ng-class="{'export-to-csv-disabled': exportdiag.exportInProgress}"
						 ng-click="exportdiag.chooseDates()">
						<date-picker selected-date="exportdiag.toDate"
									 date-format="'MMM DD YYYY | HH:mm'"
									 min-date="exportdiag.fromDate"
									 max-date="exportdiag.maxToDate"
									 input-aria-label="'to'" />
					</div>
				</div>
				<div ng-if="exportdiag.isStoredInColdStorage()">
					<i class="icon icon-IncidentTriangle export-to-csv-warning"></i>
					<div class="export-to-csv-warning-text">
						Exporting data older than 30 days might result in a long wait time.
					</div>
				</div>
				<div class="export-to-csv-dialog-info">
					<i class="icon icon-Info"></i>
					Exported data will be in UTC.
					<span ng-if="!exportdiag.isStoredInColdStorage() || (exportdiag.maxDays && exportdiag.maxDays === exportdiag.maxDaysInColdStorage)">
							You can export up to {{ ::exportdiag.maxDays }} days of data.
					</span>
					<span ng-if="::exportdiag.maxDays && exportdiag.maxDaysInColdStorage && exportdiag.maxDays !== exportdiag.maxDaysInColdStorage">
							<br/> A limit of up to {{ ::exportdiag.maxDaysInColdStorage }} days is set for data older than 30 days.
					</span>
				</div>
			</div>
			<div class="row">
				<div class="text-right export-to-csv-buttons-row">
					<button class="btn btn-primary primary-button export-to-csv-export-button"
							type="button"
							ng-click="exportdiag.export()"
							ng-if="!exportdiag.exportInProgress">
						<span class="export-to-csv-export-button-text">
							Export to CSV
						</span>
					</button>
					<div class="export-to-csv-retrieving-box" ng-if="exportdiag.exportInProgress">
						<i class="loader-icon"></i>
						Retrieving data
					</div>
					<button class="btn btn-primary normal-button" type="button" ng-click="exportdiag.cancel()">
						<span class="export-to-csv-cancel-button-text">
							Cancel
						</span>
					</button>
					<div class="export-to-csv-warning-text">
						{{ exportdiag.failureMessage }}
					</div>
				</div>
			</div>
		</div>
			`,
			controller: 'seville.threatintel.exporttocsvdialog',
			controllerAs: 'exportdiag',
			size: 'md',
			windowClass: 'machine-actions-modal',
			backdrop: false,
			resolve: {
				exportToCsvOptions: function() {
					return exportToCsvOptions;
				},
			},
		});
	}

	/*
	 * A convenience method that helps with a common exports scenario. config options are:
	 * params: parameters of the GET request(s)
	 * checkResultsUrl: URL to check for existence of results. Optional
	 * getAuthTokenUrl: URL to get an auth token for the download, if required. Optional
	 * downloadUrl: the download URL.
	 * canceler: a deferred object that can be used to cancel the operation once it is resolved. Optional
	 */
	function exportResults(config) {
		var params = config.params ? cloneDeep(config.params) : {};
		var canceler = config.canceler || $q.defer();

		var promise = $q.resolve();
		if (config.checkResultsUrl) {
			promise = checkResults(config.checkResultsUrl, canceler, params);
		}
		if (config.getAuthTokenUrl) {
			promise = promise.then(function() {
				return getAuthToken(config.getAuthTokenUrl, canceler);
			});
		}

		promise = promise
			.then(function(authToken) {
				if (config.getAuthTokenUrl) {
					params = assign(params, authToken);
				}

				var downloadUrl =
					urlMapping.getThreatIntelUrl() + config.downloadUrl + '?' + $httpParamSerializer(params);
				// Download the file from URL.
				// This line works correctly because the server specified ContentDisposition = attachment
				window.location.href = downloadUrl;
				// Wait a few seconds for the download to start
				return $timeout(function() {}, 3000);
			})
			.catch(function(reason) {
				if (!reason.keepDialogOpen && (!reason.status || isHttpErrorCode(reason.status))) {
					$log.error('An error occurred while exporting to csv: ' + reason);
				}
				return $q.reject(reason);
			});

		return promise;
	}

	function checkResults(url, canceler, params) {
		url = urlMapping.getThreatIntelUrl() + url;

		return $http
			.get(url, {
				timeout: canceler.promise,
				params: params,
			})
			.then(function(response) {
				if (response.status !== 200) {
					var reason = isHttpErrorCode(response.status) ? response : noResultsFound();
					return $q.reject(reason);
				}
				return $q.resolve();
			});
	}

	function getAuthToken(url, canceler) {
		url = urlMapping.getThreatIntelUrl() + url;

		return $http
			.get(url, {
				timeout: canceler.promise,
			})
			.then(function(response) {
				if (response.status === 200) {
					return {
						ctx: response.data.ctx,
						token: response.data.token,
					};
				}
				return $q.reject(response);
			});
	}

	function momentToUtcIsoString(dateMoment) {
		var dateToUtc = dateMoment.clone();
		dateToUtc.utcOffset(0);
		dateToUtc.add(dateMoment.utcOffset() - dateToUtc.utcOffset(), 'minutes');
		var utcString = dateToUtc.toDate().toISOString();

		return utcString;
	}

	function noResultsFound() {
		// Signal the dialog controller not to close the dialog and to display the message
		return {
			keepDialogOpen: true,
			message: 'No events returned for selected time period',
		};
	}

	function isHttpErrorCode(status) {
		return status >= 400;
	}
}
