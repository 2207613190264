<entity-panel-collapsible-section
	data-track-id="collapseExpandRemediation"
	data-track-type="Toggle"
	*ngIf="!(remediationTasks?.length == 0)"
	[title]="'tvm.securityRecommendation.relatedRemediation.title' | i18n"
	[count]="remediationTasks?.length">
	<table class="wcd-table wcd-full-width table-grouped no-side-padding">
		<thead>
			<tr>
				<th>{{'tvm.securityRecommendation.relatedRemediation.status' | i18n}}</th>
				<th>{{'tvm.securityRecommendation.relatedRemediation.createdOn' | i18n}}</th>
			</tr>
		</thead>
		<tbody>
			<tr class="table-row table-group-header"
				*ngFor="let task of remediationTasks"
				data-track-id="RecommendationToRemediation"
				data-track-type="Navigation">
					<td class="table-link nowrap wcd-text-overflow-medium"
						(keydown.enter)="openRelatedRemediation(task)"
						(click)="openRelatedRemediation(task)">
							<span tabindex=0>{{getStatusText(task)}}</span>
					</td>
					<td class="nowrap wcd-text-overflow-medium wcd-font-weight-semibold">
						{{(task.createdOn | date:'shortDate') || ('notAvailable.short' | i18n)}}
					</td>
			</tr>
		</tbody>
	</table>
</entity-panel-collapsible-section>
