var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { RemediationCategory } from '../remediation/remediation-category.enum';
import { SoftwareArgs } from './software-args.value-object';
import { SecurityConfigurationArgs } from './security-configuration-args.value-object';
import { ProductivityImpactRemediationType } from '../../remediation-task/remediation-task-user-impact.enum';
var ɵ0 = function (data) { return data.category === RemediationCategory.Software; }, ɵ1 = function (data) { return data.category === RemediationCategory.SecurityConfiguration; };
var TaskArgs = /** @class */ (function (_super) {
    __extends(TaskArgs, _super);
    function TaskArgs() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], TaskArgs.prototype, "productivityImpactRemediationType", void 0);
    __decorate([
        EntityField({
            require: ɵ0,
            data: 'softwareArgs',
        }),
        __metadata("design:type", SoftwareArgs)
    ], TaskArgs.prototype, "softwareArgs", void 0);
    __decorate([
        EntityField({
            require: ɵ1,
            data: 'securityConfigurationArgs',
        }),
        __metadata("design:type", SecurityConfigurationArgs)
    ], TaskArgs.prototype, "securityConfigurationArgs", void 0);
    __decorate([
        EntityField({
            required: true,
            data: 'category',
        }),
        __metadata("design:type", String)
    ], TaskArgs.prototype, "category", void 0);
    __decorate([
        EntityField({
            data: 'areAssetsManaged',
        }),
        __metadata("design:type", Boolean)
    ], TaskArgs.prototype, "areAssetsManaged", void 0);
    TaskArgs = __decorate([
        ValueObject({
            singularName: 'Task arguments',
            pluralName: 'Tasks arguments',
        })
    ], TaskArgs);
    return TaskArgs;
}(ModelBase));
export { TaskArgs };
export { ɵ0, ɵ1 };
