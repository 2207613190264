import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
import { Software } from './software.entity';
import { SoftwareVersion } from '../software-version/software-version.entity';
import { SoftwareInstallation } from '../software-installation/software-installation.entity';
import { SecurityRecommendation } from '../security-recommendation/security-recommendation.entity';
import {
	TvmAnalyticsSharedEntityRelationshipConfigurations,
	ParseDataQuery,
} from '../analyticsEndPointUtils';
import { MissingKb } from '../missing-kb/missing-kb.entity';
import { SoftwareInstallationAgg } from './../software-installation/software-installation-agg-versions.entity';
import { ChangeEvent, ChangeEventTypesForMdatp } from '../change-event/change-event.entity';

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Software,
	dataEntity: SecurityRecommendation,
	endpoint: () => `products/product/recommendations`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (software: Software) => ({ productId: software.id }),
})
export class SoftwareSecurityRecommendationRelationship
	implements EntityRelationshipRepositoryType<Software, SecurityRecommendation> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Software,
	dataEntity: Vulnerability,
	endpoint: () => `products/product/vulnerabilities`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (software: Software) => ({ productId: software.id }),
})
export class SoftwareVulnerabilityRelationship
	implements EntityRelationshipRepositoryType<Software, Vulnerability> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Software,
	dataEntity: SoftwareVersion,
	endpoint: () => `products/product/productversions`,
	parseData: (data, _, query) =>
		Object.assign(
			{},
			data,
			data.results && {
				results: data.results.map(r => Object.assign({}, r, { productId: query.where['productId'] })),
			}
		),
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (software: Software) => ({ productId: software.id }),
})
export class SoftwareVersionRelationship
	implements EntityRelationshipRepositoryType<Software, SoftwareVersion> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Software,
	dataEntity: SoftwareInstallation,
	endpoint: () => `products/product/installations`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (software: Software) => ({ productId: software.id }),
})
export class SoftwareInstallationRelationship
	implements EntityRelationshipRepositoryType<Software, SoftwareInstallation> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Software,
	dataEntity: SoftwareInstallationAgg,
	endpoint: () => `products/product/installations/aggregate`,
	allowedTypes: [RelationshipType.OneToMany],
	allItemsEndpointTrailingSlash: false,
	getRelationshipData: (software: Software) => ({
		productId: software.id,
		isNetworkDeviceInstallation: software.category === 'NetworkGear',
	}),
	parseDataQuery: (dataQuery: DataQuery) => {
		const params = ParseDataQuery(dataQuery);
		params['addNetworkDeviceIpAddresses'] = dataQuery.where['isNetworkDeviceInstallation'];
		return params;
	},
})
export class SoftwareInstallationAggRelationship
	implements EntityRelationshipRepositoryType<Software, SoftwareInstallationAgg> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Software,
	dataEntity: MissingKb,
	endpoint: () => `products/product/missingkbs`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (software: Software) => ({ productId: software.id }),
	parseData: (data, _, query) => {
		data.results = Array.from(data.results).map((record: object) => ({
			...record,
			productId: query.where['productId'],
		}));
		return data;
	},
})
export class SoftwareMissingKbsRelationship
	implements EntityRelationshipRepositoryType<Software, MissingKb> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: Software,
	dataEntity: ChangeEvent,
	endpoint: () => `products/product/changeEvents`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (software: Software) => ({ productId: software.id }),
	parseDataQuery: dataQuery => {
		if (dataQuery.where && typeof dataQuery.where === 'object' && dataQuery.where['source'] === 'mdatp') {
			dataQuery.where['eventType'] = ChangeEventTypesForMdatp;
		}
		return ParseDataQuery(dataQuery);
	},
})
export class SoftwareChangeEventsRelationship
	implements EntityRelationshipRepositoryType<Software, ChangeEvent> {}
