import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { CmsActorSection } from '../../cms/cms-actor-section.value-object';

@Entity({
	singularName: 'Actor',
	pluralName: 'Actors',
	endpoint: 'ThreatIntel',
	baseUrl: config => config.data.serviceUrls.threatIntel,
	parseItemQuery: itemId => `/ThreatIntel?actor=${itemId}`,
})
export class Actor extends EntityModelBase {
	@EntityField({ data: ['Name'] })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: ['Name'] })
	name: string;

	@EntityField({ data: ['LinkToReport'] })
	linkToReport: string;

	@EntityField({ data: ['ReportType'] })
	reportType: string;

	@EntityField({ data: ['ThirdPartyProviderLink'] })
	thirdPartyProviderLink : string;

	@EntityField({ data: 'Sections', arrayOf: CmsActorSection })
	sections: Array<CmsActorSection>;
}
