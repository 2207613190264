import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { Url } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
var DomainUrlFields = /** @class */ (function () {
    function DomainUrlFields(i18nService, globalEntityTypesService) {
        this.i18nService = i18nService;
        this.globalEntityTypesService = globalEntityTypesService;
    }
    Object.defineProperty(DomainUrlFields.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'title',
                        name: this.i18nService.get('reporting.webThreatReport.domainUrlList.urlHeader'),
                        getLink: function (report) {
                            return _this.globalEntityTypesService.getEntityLink(Url, { id: report.url });
                        },
                        getTooltip: function (report) { return report.url; },
                        getDisplay: function (report) { return report.url; },
                        className: 'nowrap wcd-text-overflow-medium-large',
                    },
                    {
                        id: 'accessCount',
                        name: this.i18nService.get('reporting.webThreatReport.domainUrlList.accessCountHeader'),
                        className: 'nowrap',
                        sort: {
                            sortDescendingByDefault: true,
                            getLocalSortValue: 'accessCount',
                        },
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return DomainUrlFields;
}());
export { DomainUrlFields };
