import { Injectable, OnDestroy } from '@angular/core';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class HttpInterceptorService implements OnDestroy {
	private activePopup = new BehaviorSubject<boolean>(false);
	constructor(private dialogsService: DialogsService, private i18nService: I18nService) {}

	showHttp429Error = (): void => {
		if (!!this.activePopup.getValue()) {
			return;
		}
		this.activePopup.next(true);

		this.dialogsService
			.confirm({
				text: this.i18nService.get('errors.http429.text'),
				title: this.i18nService.get('errors.http429.title'),
				showConfirm: false,
				showOverlay: false,
				cancelText: this.i18nService.get('errors.http429.cancelText'),
			})
			.then(() => this.activePopup.next(false));
	};

	ngOnDestroy(): void {
		this.activePopup.complete();
	}
}
