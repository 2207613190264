
		<ng-container *ngIf="(latestAlertData$ | async)?.length; else noAlerts">
			<div class="wcd-flex-spaceBetween-horizontal wcd-flex-wrap-horizontal wcd-padding-bottom">
				<wcd-pie-chart
					[data]="newAlertsData$ | async"
					*ngIf="(newAlertsData$ | async)"
					[hidden]="error$ | async"
					[settings]="newAlertsPieSettings"
					[allowTitleClick]="true"
					[upperTitle]="newAlertsTitle$ | async"
					(titleClick)="onNewAlertsTitleClick()"
					[ariaLabel]="'alerts_active' | i18n"
				>
				</wcd-pie-chart>
				<wcd-pie-chart
					[data]="inProgressAlertsData$ | async"
					*ngIf="(inProgressAlertsData$ | async)"
					[hidden]="error$ | async"
					[settings]="inProgressAlertsPieSettings"
					[allowTitleClick]="true"
					[upperTitle]="inProgressAlertsTitle$ | async"
					(titleClick)="onInProgressAlertsTitleClick()"
					[ariaLabel]="'alerts_active' | i18n"
				>
				</wcd-pie-chart>
				<wcd-chart-legend [items]="legendData$ | async" [showValues]="true"></wcd-chart-legend>
			</div>
			<wcd-datatable
				[items]="latestAlertData$ | async"
				[columns]="tableColumns"
				[hidden]="error$ | async"
				[selectEnabled]="false"
				[showHeaders]="false"
				(itemClick)="showAlertSidePanel($event.item)"
				[label]="'machines.entityDetails.fields.activeAlerts.title' | i18n"
			></wcd-datatable>
		</ng-container>
		<ng-template #noAlerts>
			<div class="widget-nodata wcd-full-height wcd-flex-center-vertical">
				<div class="widget-nodata-message">
					<div class="widget-nodata-message-icon">
						<wcd-shared-icon [iconName]="'hide'"> </wcd-shared-icon>
					</div>
					<div>
						Wondering where to start?
						<a [routerLink]="'/preferences2/onboarding'">{{
							'onboarding.runDetectionTest.title' | i18n
						}}</a
						>.
					</div>
				</div>
			</div>
		</ng-template>
	