import { Component, Input } from '@angular/core';
import { EvidenceDetectionSource } from '@wcd/domain';

@Component({
	selector: 'evidence-detection-context-tooltip',
	template: `
		<div *ngFor="let detectionContext of detectionContexts; let last = last">
			<evidence-detection-context [detectionContext]="detectionContext" [showTooltip]="false">
			</evidence-detection-context>
			<ng-container *ngIf="!last">, </ng-container>
		</div>
	`,
})
export class EvidenceDetectionContextTooltipComponent {
	@Input() detectionContexts: Array<EvidenceDetectionSource>;
}
