import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconComponent } from '@wcd/icons';

@Component({
	selector: 'value-with-external-link',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="context">
			<span>{{ context.value }}</span>
			<a
				[href]="context.link"
				[attr.aria-label]="context.title"
				target="_blank"
				class="wcd-margin-xsmall-left"
				data-track-id="OpenLink"
				data-track-type="Navigation"
			>
				<wcd-shared-icon
					[iconName]="context.icon"
					[verticalAlign]="context.verticalAlign"
				></wcd-shared-icon>
			</a>
		</ng-container>
	`,
})
export class ValueWithExternalLinkComponent {
	@Input() context: ValueWithExternalLinkContext;
}

export interface ValueWithExternalLinkContext {
	readonly value: string;
	readonly link: string;
	readonly icon: IconComponent['iconName'];
	readonly verticalAlign?: IconComponent['verticalAlign'];
	readonly title?: string;
}
