var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ApiCall, ApiCallModel, EntityField, ValueObject, ModelBase } from '@microsoft/paris';
var WebThreatStatusSummary = /** @class */ (function (_super) {
    __extends(WebThreatStatusSummary, _super);
    function WebThreatStatusSummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Phishing' }),
        __metadata("design:type", Number)
    ], WebThreatStatusSummary.prototype, "phishing", void 0);
    __decorate([
        EntityField({ data: 'Malicious' }),
        __metadata("design:type", Number)
    ], WebThreatStatusSummary.prototype, "malicious", void 0);
    __decorate([
        EntityField({ data: 'CustomIndicator' }),
        __metadata("design:type", Number)
    ], WebThreatStatusSummary.prototype, "customIndicator", void 0);
    __decorate([
        EntityField({ data: 'TotalThreats' }),
        __metadata("design:type", Number)
    ], WebThreatStatusSummary.prototype, "totalThreats", void 0);
    __decorate([
        EntityField({ data: 'Unknown' }),
        __metadata("design:type", Number)
    ], WebThreatStatusSummary.prototype, "unknown", void 0);
    __decorate([
        EntityField({ data: 'UpdateTime' }),
        __metadata("design:type", Date)
    ], WebThreatStatusSummary.prototype, "updateTime", void 0);
    WebThreatStatusSummary = __decorate([
        ValueObject({
            singularName: 'Web threat protection',
            pluralName: 'Web threat protection',
            readonly: true,
        })
    ], WebThreatStatusSummary);
    return WebThreatStatusSummary;
}(ModelBase));
export { WebThreatStatusSummary };
var ɵ0 = function (config) { return config.data.serviceUrls.webThreatProtection; };
var WebThreatStatusSummaryApiCall = /** @class */ (function (_super) {
    __extends(WebThreatStatusSummaryApiCall, _super);
    function WebThreatStatusSummaryApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WebThreatStatusSummaryApiCall = __decorate([
        ApiCall({
            name: 'Get web threat health status summary',
            endpoint: 'webThreats/reports/webThreatSummary',
            method: 'GET',
            type: WebThreatStatusSummary,
            baseUrl: ɵ0,
        })
    ], WebThreatStatusSummaryApiCall);
    return WebThreatStatusSummaryApiCall;
}(ApiCallModel));
export { WebThreatStatusSummaryApiCall };
export { ɵ0 };
