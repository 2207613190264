<!-- tslint:disable:template-click-events-have-key-events -->
<div class="wcd-full-height wcd-flex-vertical" data-track-component="CyberEventEntityPanel">
	<ng-container *ngIf="!isAlertRow; else alertDetails">

		<wcd-collapsible-section *ngIf="mitreTechniquesObjects && mitreTechniquesObjects.length && mitreTechniquesObjects[0]; let mitreTechniqueObject" [label]="'events.mitre.technique.info.title' | i18n"
			[sectionId]="collapsibleID.MitreTechniquesInfo">
			<dl class="key-values" role="none">
				<dt>{{ 'events_mitre_technique_info_fields_id' | i18n }}</dt>
				<dd>
					<a href="{{ mitreTechniqueObject.link }}"
					   target="_blank"
					   (click)="onLinkClick('Mitre_technique_id_link')">
						{{ mitreTechniqueObject.nameWithId }}
					</a>
				</dd>

				<ng-container *ngIf="mitreTechniqueObject.categories && mitreTechniqueObject.categories.length">
					<dt>{{ 'events_mitre_technique_info_fields_tactic' | i18n }}</dt>
					<dd>
						<ng-container
							*ngFor="let mitreCategoryObject of mitreTechniqueObject.categories">
							<div>
								<a href="{{ mitreCategoryObject.link }}"
								   target="_blank"
								   (click)="onLinkClick('mitre_technique_tactic_link')">
								   {{ mitreCategoryObject.nameDisplay }}
							    </a>
							</div>
						</ng-container>
					</dd>
				</ng-container>

				<ng-container *ngIf="mitreTechniqueObject.fullDescription">
					<dt>{{ 'events_mitre_technique_info_fields_description' | i18n }}</dt>
					<dd [wcdTooltip]="mitreTechniqueObject.fullDescription">
						<markdown
						[data]="mitreTechniqueObject.shortDescription"
						class="wcd-block"></markdown>
						<div class="attribution wcd-font-weight-semibold">
							{{ 'events_mitre_technique_info_fields_description_copyrights' | i18n }}
						</div>
					</dd>
				</ng-container>
				<br>
			</dl>
		</wcd-collapsible-section>

		<wcd-collapsible-section [label]="'events.details.title' | i18n" [sectionId]="collapsibleID.Details">
			<cyber-event-entity-details [contextLog]="contextLog" [entity]="event" [mitreTechniquesObjects]="mitreTechniquesObjects" [showMachineLink]="this.options?.showMachineLink !== false">
			</cyber-event-entity-details>
			<ng-container *ngIf="eventEntities?.length">
				<dl class="key-values" role="none">
					<dt>{{ 'events.fields.entities.title' | i18n }}</dt>
					<dd>
						<ng-container *ngFor="let eventEntity of eventEntities; let lastValue = last">
							<wcd-shared-icon *ngIf="eventEntity.icon" [iconName]="eventEntity.icon"> </wcd-shared-icon>
							<ng-template *ngTemplateOutlet="entityTitle;context:{ eventEntity: eventEntity }">
							</ng-template>
							<span *ngIf="!lastValue"> > </span>
						</ng-container>
					</dd>
				</dl>
			</ng-container>
		</wcd-collapsible-section>

		<wcd-collapsible-section [sectionId]="collapsibleID.DetectedAlert" *ngIf="alerts?.length"
			[label]="'events.details.detectedAlert' | i18n">
			<div *ngFor="let alert of alerts; let last = last" class="wcd-padding-small-vertical wcd-border-top"
				[class.wcd-border-bottom]="last">
				<div class="wcd-flex-justify-end-horizontal wcd-padding-small-bottom wcd-font-size-s">
					<div class="wcd-flex-1">
						<span class="wcd-severity wcd-severity-{{alert.severity.type}}"></span>
						{{alert.severity.nameI18nKey | i18n}}
					</div>
					<div class="wcd-flex-0">{{alert.lastEventTime | date:'medium'}}</div>
				</div>
				<entity-link [linkLogContext]="contextLog" [entity]="alert" [linkText]="alert.nameI18nKey | i18n" [entityType]="alertType">
				</entity-link>
			</div>
		</wcd-collapsible-section>

		<wcd-collapsible-section [label]="'events.details.entities' | i18n" [sectionId]="collapsibleID.EntitiesDetails"
			*ngIf="eventEntities?.length && this.options?.showEntitiesGraph !== false">
			<cyber-event-entities-graph [event]="event" [collapseEntities]="'parents'"></cyber-event-entities-graph>
		</wcd-collapsible-section>
	</ng-container>

	<ng-template #alertDetails>
		<wcd-collapsible-section [label]="'events_alert_details' | i18n " [sectionId]="collapsibleID.AlertDetails">
			<alert-entity-details [entity]="event.relatedAlert" [showTitle]="false"></alert-entity-details>
			<a (click)="openAlertPanel(event.relatedAlert)" data-track-id="ManageAlertOpenAlertPanelLink"
				data-track-type="Navigation">
				{{'events.details.openAlertPanel' | i18n}}
			</a>
		</wcd-collapsible-section>

		<wcd-collapsible-section *ngIf="event.relatedAlert.description"
								 [label]="'alerts.alertDescription' | i18n"
								 [sectionId]="collapsibleID.AlertDescription">
			<alerted-description [description]="event.relatedAlert.description"></alerted-description>
		</wcd-collapsible-section>

		<wcd-collapsible-section *ngIf="event.relatedAlert.recommendedAction"
								 [label]="'alerts.alert.recommendedActions' | i18n"
								 [sectionId]="collapsibleID.AlertRecommendedAction">
			<alerted-description [recommendedAction]="event.relatedAlert.recommendedAction"></alerted-description>
		</wcd-collapsible-section>
	</ng-template>
</div>

<ng-template #entityTitle let-eventEntityModel="eventEntity">
	<entity-link *ngIf="isEntityClickable(eventEntityModel); else entityName" [entity]="eventEntityModel.item"
				 [linkLogContext]="contextLog" [linkText]="eventEntityModel.name" [entityType]="eventEntityModel.entityType"></entity-link>
	<ng-template #entityName>{{eventEntityModel.name}}</ng-template>
</ng-template>
