<ul class="wcd-checklist user-role-permissions wcd-padding-top" role="tree"  [attr.aria-label]="'settings_section_permissions' | i18n">
	<li *ngFor="let _parent of allowedActionsModel; let valueIndex = index" role="none" [class.wcd-padding-bottom]="_parent.children">
		<div [wcdTooltip]="userRoleAction.getHelpKey(_parent) | i18n" wcdTooltipAllowHtmlRendering="true">
			<wcd-checkbox
				[(ngModel)]="selectedValues[_parent.id]"
				(ngModelChange)="onValueChanged(_parent)"
				[isDisabled]="isPermissionDisabled(_parent)"
				[label]="_parent.displayNameKey | i18n"
				[isPartiallyChecked]="partiallySelectedCategories[_parent.id]"
				ariaRole="treeItem">
			</wcd-checkbox>
		</div>
		<ul class="wcd-checklist user-role-permissions wcd-padding-top" role="group" *ngIf="_parent.children" [attr.aria-label]="_parent.displayNameKey | i18n">
			<li *ngFor="let _child of _parent.children; let valueIndex = index" role="none" class="wcd-padding-horizontal">
				<div [wcdTooltip]="userRoleAction.getHelpKey(_child) | i18n" wcdTooltipAllowHtmlRendering="true">
					<wcd-checkbox
						[(ngModel)]="selectedValues[_child.id]"
						(ngModelChange)="onValueChanged(_child)"
						[isDisabled]="isPermissionDisabled(_child)"
						[label]="_child.displayNameKey | i18n"
						ariaRole="treeItem">
					</wcd-checkbox>
				</div>
			</li>
		</ul>
	</li>
</ul>
