import { Url, CustomTiIndicatorsTypes, MdeUserRoleActionEnum, ServiceSourceType } from '@wcd/domain';
import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { UrlEntityPanelComponent } from '../components/url.entity-panel.component';
import { UrlEntityComponent } from '../components/url.entity.component';
import { UrlEntityDetailsComponent } from '../../../global_entities/components/entity-details/url.entity-details.component';
import { UrlsService } from './urls.service';
import { Paris } from '@microsoft/paris';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { EntityCustomTiActionsConfigService } from '../../../@entities/common/services/entity.custom-ti-actionconfig.service';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { map } from 'rxjs/operators';
import { FabricIconNames } from '@wcd/scc-common';
import { EntityMinimizedDetailsComponentBase } from '../../../global_entities/components/entity-minimized/entity-minimized-details.component.base';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { of } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';

@Injectable()
export class UrlEntityTypeService implements EntityTypeService<Url> {
	constructor(
		private readonly urlsService: UrlsService,
		private readonly paris: Paris,
		private readonly entityCustomTiActionsConfigService: EntityCustomTiActionsConfigService,
		private i18nService: I18nService,
		private featuresService: FeaturesService
	) {}

	readonly entityType: Readonly<EntityType<Url>> = {
		entity: Url,
		id: 'url',
		icon: 'entities.url',
		getIcon: () => FabricIconNames.Website,
		getImage: () => '/assets/images/icons/entities/url.svg',
		entityContentsComponentType: UrlEntityComponent,
		entityDetailsComponentType: UrlEntityDetailsComponent,
		singleEntityPanelComponentType: UrlEntityPanelComponent,
		entityMinimizedComponentType: EntityMinimizedDetailsComponentBase,
		getEntityName: url => url.name,
		entityPluralNameKey: 'url_entityType_pluralName',
		entitySingularNameKey: 'url_entityType_singularName',
		getNavigationModel: (url: Url, serviceSource?: ServiceSourceType) =>
			(url.externalUrlPageUrl || url.id) && {
				externalLink: url.externalUrlPageUrl,
				routerLink: !url.externalUrlPageUrl
					? this.featuresService.isEnabled(Feature.PortedSccPages) ||
					  !serviceSource ||
					  serviceSource === ServiceSourceType.Wdatp
						? [this.urlsService.getUrlLink(url.id)]
						: null
					: null,
				route: "urls.child",
				queryParams: { path: url.id }
			},
		getEntitiesLink: (urls: Array<Url>) => {
			if (urls && urls.length === 1)
				if (urls[0].externalUrlPageUrl) {
					return urls[0].externalUrlPageUrl;
				} else if (urls[0].id) {
					return this.urlsService.getUrlLink(urls[0].id);
				} else {
					return null;
				}
		},
		getActions: (urls: Array<Url>, options: {}, entityViewType: EntityViewType) => {
			const [url] = urls;
			return entityViewType === EntityViewType.EntityPage
				? this.entityCustomTiActionsConfigService
						.getCommandBarActions<Url>(
							url.name,
							CustomTiIndicatorsTypes.Url,
							urlEntity => urlEntity.name
						)
						.pipe(map(config => [new ItemActionModel(config)]))
				: of(null);
		},
		loadFullEntityInPanel: false,
		searchOptions: {
			displayName: this.i18nService.strings.entity_type_display_name_url,
			resolveTypeFromSearchTerm: (searchTerm: string) => {
				return searchTerm.includes('://');
			},
			getSearchParams: (searchTerm: string) => {
				return {
					url: this.urlsService.getUrlLink(searchTerm),
				};
			},
			searchDropdownOrder: 0,
			requiredAllowedActions: MdeUserRoleActionEnum.viewData,
			flavorConfig: AppFlavorConfig.search.commonEntities,

		},
		entityPageViewMode: EntityPageViewMode.Asset,
		getEncodedEntityId: (entityId: string) => encodeURIComponent(encodeURIComponent(encodeURIComponent(entityId))),
		showMigrationToggle: () => this.featuresService.isEnabled(Feature.UnifiedUrlPage) && this.featuresService.isEnabled(Feature.ShowUnifiedUrlPageToggle),
		getMigrationToggleTitle: (isOn)=>this.i18nService.get( 'entity_new_page_toggle_title', {entityName : this.i18nService.strings.entity_type_display_name_url })
	};
}
