import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { WebThreatProtectionData } from './web-threat-protection-data.value-object';

@ValueObject({
	singularName: 'Web threat protection',
	pluralName: 'Web threat protection',
	readonly: true,
})
export class WebThreatProtection extends ModelBase {
	@EntityField({ arrayOf: WebThreatProtectionData })
	readonly data: Array<WebThreatProtectionData>;
}
