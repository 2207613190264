<div class="wcd-full-height wcd-flex-vertical">
	<section class="wcd-padding-vertical wcd-padding-large-horizontal wcd-scroll-vertical">
		<baseline-profile-entity-details [profile]="profile"></baseline-profile-entity-details>
		<dl class="tvm-list__key_val_item">
			<dt>
				{{ 'tvm_baseline_profiles_status_title' | i18n }}
			</dt>
			<dd>
				<wcd-checkbox
					[(ngModel)]="isEnabled"
					[isDisabled]="isSaving"
					name="profile-enable"
					[label]="'tvm_baseline_profile_enabled_label' | i18n">
				</wcd-checkbox>
			</dd>
		</dl>
		<dl class="tvm-list__block_item wcd-margin-large-top">
			<dt>
				{{ 'tvm_baseline_profiles_complianceStatus_sidePanel_widget' | i18n }}
			</dt>
			<dd class="wcd-margin-medium-top">
				<ng-container *ngIf="isLoading; else profileComplianceSummery">
					<fab-spinner
						[size]="SpinnerSize.medium"
						[label]="'common_loading' | i18n"
						labelPosition="right"
					></fab-spinner>
				</ng-container>
				<ng-template #profileComplianceSummery>
					<tvm-profiles-compliance-status-bars
						*ngIf="!noComplianceStatus; else noData"
						[profilesComplianceStatus]="profilesComplianceStatus"
						[showTitle]="false">
					</tvm-profiles-compliance-status-bars>
				</ng-template>
			</dd>
		</dl>
	</section>
	<footer class="wcd-padding-small-top flex-bottom wcd-border-top">
		<div class="wcd-padding-all wcd-padding-large-horizontal">
			<fab-primary-button
				(onClick)="updateStatus()"
				[disabled]="isEnabled == profile.status || isSaving">
				{{ 'buttons_save' | i18n }}
			</fab-primary-button>
		</div>
	</footer>
</div>

<ng-template #noData>
	<div>
		{{ 'common_noDataToShow' | i18n }}
	</div>
</ng-template>
