import { ValueObject, EntityField } from '@microsoft/paris';
import { AuthenticationParams, AuthenticationParamsSerializationType } from './authentication-params.value-object';

export enum SnmpAuthType {
	CommunityString = 'Community String',
	NoAuthNoPriv = 'NoAuthNoPriv',
	AuthNoPriv = 'AuthNoPriv',
	AuthPriv = 'AuthPriv',
}

export enum SnmpAuthProtocol {
	md5 = 'MD5',
	sha1 = 'SHA1',
}

export enum SnmpPrivProtocol {
	des = 'DES',
	threeDes = '3DES',
	aes = 'AES',
}

@ValueObject({
	singularName: 'Snmp authentication parameters',
	pluralName: 'Snmp authentication parameters',
})
export class SnmpAuthParams extends AuthenticationParams {
	@EntityField()
	type?: string;

	@EntityField()
	communityString?: string;

	@EntityField()
	username?: string;

	@EntityField()
	authProtocol?: string;

	@EntityField()
	authPassword?: string;

	@EntityField()
	privProtocol?: string;

	@EntityField()
	privPassword?: string;

	constructor(previous?: SnmpAuthParams) {
		super(AuthenticationParamsSerializationType.SnmpAuthParams, previous);
	}
}
