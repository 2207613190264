var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _a;
import { ColorsService } from '../../shared/services/colors.service';
import { KnownColorsService } from '../../shared/services/known-colors.service';
export var TvmColor;
(function (TvmColor) {
    TvmColor["CriticalRisk"] = "criticalRisk";
    TvmColor["HighRisk"] = "highRisk";
    TvmColor["MediumRisk"] = "mediumRisk";
    TvmColor["LowRisk"] = "lowRisk";
    TvmColor["GraphSeries"] = "graphSeries";
    TvmColor["Blue"] = "blue";
    TvmColor["Positive"] = "positive";
    TvmColor["Neutral"] = "neutralQuaternary";
    TvmColor["NeutralAlt"] = "GraphNatural";
    TvmColor["BlueDark"] = "blueDark";
    TvmColor["BlueMid"] = "blueMid";
    TvmColor["Green"] = "green";
    TvmColor["TealLight"] = "tealLight";
    TvmColor["PurpleLight"] = "purpleLight";
    TvmColor["GraphSeriesLight"] = "graphSeriesLight";
    TvmColor["PurpleDark"] = "purpleDark";
    TvmColor["NeutralTertiary"] = "neutralTertiary";
})(TvmColor || (TvmColor = {}));
var severityTypeColorName = (_a = {},
    _a[TvmColor.CriticalRisk] = 'darkBrown',
    _a[TvmColor.HighRisk] = 'highSeverity',
    _a[TvmColor.MediumRisk] = 'mediumSeverity',
    _a[TvmColor.LowRisk] = 'lowSeverity',
    _a[TvmColor.GraphSeries] = 'neutralSecondary',
    _a[TvmColor.GraphSeriesLight] = 'neutralSecondaryAlt',
    _a[TvmColor.Blue] = 'blue',
    _a[TvmColor.Positive] = 'greenLight',
    _a[TvmColor.Neutral] = 'neutralQuaternary',
    _a[TvmColor.NeutralAlt] = 'neutralQuaternaryAlt',
    _a[TvmColor.BlueDark] = 'blueDark',
    _a[TvmColor.BlueMid] = 'blueMid',
    _a[TvmColor.Green] = 'green',
    _a[TvmColor.TealLight] = 'tealLight',
    _a[TvmColor.PurpleLight] = 'purpleLight',
    _a[TvmColor.PurpleDark] = 'purpleDark',
    _a[TvmColor.NeutralTertiary] = 'neutralTertiary',
    _a);
var TvmColorService = /** @class */ (function (_super) {
    __extends(TvmColorService, _super);
    function TvmColorService(knownColorsService) {
        var _this = _super.call(this, knownColorsService, severityTypeColorName) || this;
        _this.getStatusIcon = function (value) { return "ms-fontColor-" + _this.colorsMap.get(value ? TvmColor.Green : TvmColor.NeutralTertiary).name + " field-value-font-size-mi"; };
        _this.getCompliantIcon = function (value) { return "ms-fontColor-" + _this.colorsMap.get(value ? TvmColor.Green : TvmColor.HighRisk).name + " field-value-font-size-mi"; };
        return _this;
    }
    return TvmColorService;
}(ColorsService));
export { TvmColorService };
