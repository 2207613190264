import { ModuleWithProviders, NgModule } from '@angular/core';
import { RemediationActionsService } from './services/remediation-actions.service';
import { PendingActionsService } from './services/pending-actions.service';
import { RemediationActionsBackendService } from './services/remediation-actions.backend.service';
import { PendingActionsPageComponent } from './components/pending-actions.page.component';
import { PendingActionsTypeComponent } from './components/pending-actions-type.component';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { QuarantineFilesRemediationDeclineModalComponent } from './components/quarantine-files-remediation-decline.modal';
import { SystemExclusionsModule } from '../system_exclusions/system-exclusions.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard, OnboardGuard, FeatureEnabledGuard, MdeAllowedActionsGuard } from '@wcd/auth';
import { SharedModule } from '../../shared/shared.module';
import { CommentsModule } from '../../comments/comments.module';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { I18nModule } from '@wcd/i18n';
import { PendingActionsComponent } from './components/pending-actions.component';
import { AirsEnabledGuard, Feature } from '@wcd/config';
import { RemediationActionEntityPanelComponent } from './components/remediation-action.entity-panel.component';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { RemediationActionEntityTypeService } from './services/remediation-action.entity-type.service';
import { AirsEntityTypesModule } from '../airs_entities/airs-entity-types.module';
import { AirsEntitiesModule } from '../airs_entities/airs-entities.module';
import { RemediationDeclineModalComponent } from './components/remediation-decline.modal';
import { RemediationDeclineReasonComponent } from './components/remediation-decline-reason.component';
import { MdeUserRoleActionEnum } from '@wcd/domain';
import { A11yModule } from '@angular/cdk/a11y';
import { AddFilesToAllowlistModal } from './components/add-files-to-allowlist.modal';
import { ExpanderModule } from '@wcd/expander';
import { FabSpinnerModule } from '@angular-react/fabric';
import { MailboxEntityTypeModule } from '../mailboxes/mailbox.entity-type.module';
import { MailboxDetailsFieldComponent } from '../mailboxes/components/mailbox-details-field.component';
import { RemediationActionTypeFieldsService } from './services/remediation-action-type.fields.service';

const pendingTitle: string = 'Pending actions';
const pendingActionsRoutes: Routes = [
	{
		path: 'pending',
		component: PendingActionsPageComponent,
		canActivate: [
			AuthenticatedGuard,
			OnboardGuard,
			FeatureEnabledGuard,
			AirsEnabledGuard,
			MdeAllowedActionsGuard,
		],
		data: {
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData,
			},
			pageTitle: pendingTitle,
			pageTitleIcon: 'users.user_attention',
			pageDescriptionKey: 'attentionRequired.description',
			enableAirsRoute: [
				'/enable_airs',
				{
					title: pendingTitle,
					messageClass: 'relative enable-auto-ir-investigation-message',
				},
			],
		},
	},
];

@NgModule({
	imports: [
		RouterModule.forChild(pendingActionsRoutes),
		SharedModule,
		AirsEntityTypesModule,
		DataViewsModule,
		SystemExclusionsModule,
		AirsEntitiesModule,
		CommentsModule,
		GlobalEntitiesModule,
		I18nModule,
		A11yModule,
		ExpanderModule,
		FabSpinnerModule,
		MailboxEntityTypeModule,
	],
	declarations: [
		PendingActionsPageComponent,
		PendingActionsTypeComponent,
		RemediationDeclineReasonComponent,
		QuarantineFilesRemediationDeclineModalComponent,
		AddFilesToAllowlistModal,
		RemediationDeclineModalComponent,
		PendingActionsComponent,
		RemediationActionEntityPanelComponent,
	],
	providers: [
		RemediationActionsService,
		RemediationActionsBackendService,
		RemediationActionEntityTypeService,
		RemediationActionTypeFieldsService,
	],
	exports: [
		PendingActionsPageComponent,
		RouterModule,
		PendingActionsComponent,
		RemediationActionEntityPanelComponent,
	],
	entryComponents: [
		PendingActionsPageComponent,
		RemediationDeclineReasonComponent,
		QuarantineFilesRemediationDeclineModalComponent,
		AddFilesToAllowlistModal,
		RemediationDeclineModalComponent,
		RemediationActionEntityPanelComponent,
		MailboxDetailsFieldComponent,
	],
})
export class RemediationModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(RemediationActionEntityTypeService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: RemediationModule,
			providers: [PendingActionsService],
		};
	}
}
