import { Injectable } from '@angular/core';
import { EntityType, EntityDataViewOptions } from '../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { AuthenticatedScanResult } from '@wcd/domain';

@Injectable()
export class AuthenticatedScanResultEntityTypeService implements EntityTypeService<AuthenticatedScanResult> {
	readonly entityType: EntityType<AuthenticatedScanResult> = {
		id: 'authenticated-scan-result',
		entity: AuthenticatedScanResult,
		getEntityName: (scanResult: AuthenticatedScanResult) => scanResult.ip,
		loadFullEntityInPanel: false,
		dataViewOptions: <EntityDataViewOptions<AuthenticatedScanResult, {}>>{
			defaultSortFieldId: 'ip',
		},
	};
}
