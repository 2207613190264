/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i3 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i6 from "@angular-react/fabric";
import * as i7 from "../../../../../../../projects/shared/src/lib/pipes/basePath.pipe";
import * as i8 from "../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i9 from "./scheduled-hunting-last-run-status.component";
import * as i10 from "@microsoft/paris/dist/lib/paris";
import * as i11 from "../../../../../../../projects/config/src/lib/services/polling.service";
import * as i12 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_ScheduledHuntingLastRunStatusComponent = [];
var RenderType_ScheduledHuntingLastRunStatusComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScheduledHuntingLastRunStatusComponent, data: {} });
export { RenderType_ScheduledHuntingLastRunStatusComponent as RenderType_ScheduledHuntingLastRunStatusComponent };
function View_ScheduledHuntingLastRunStatusComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ScheduledHuntingLastRunStatusComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScheduledHuntingLastRunStatusComponent_3)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "span", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(7, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(8, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v.parent, 7); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.status.isError && _co.status.className) ? ("color-text-" + _co.status.className) : ""); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getLastRunStatusText(true); _ck(_v, 8, 0, currVal_2); }); }
function View_ScheduledHuntingLastRunStatusComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ScheduledHuntingLastRunStatusComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 10, "span", [["class", "nowrap wcd-text-overflow-small"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 4).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 4).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(4, 147456, null, 0, i2.TooltipDirective, [i0.ElementRef, i3.TooltipsService, i4.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScheduledHuntingLastRunStatusComponent_5)), i0.ɵdid(7, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "nowrap wcd-text-overflow-small"; var currVal_1 = ((_co.status.isError && _co.status.className) ? ("color-text-" + _co.status.className) : ""); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.tooltip; _ck(_v, 4, 0, currVal_2); var currVal_3 = i0.ɵnov(_v.parent, 7); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.getLastRunStatusText(); _ck(_v, 10, 0, currVal_4); }); }
function View_ScheduledHuntingLastRunStatusComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "fab-icon", [], null, null, null, i5.View_FabIconComponent_0, i5.RenderType_FabIconComponent)), i0.ɵdid(1, 5816320, null, 0, i6.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.showInPage ? "wcd-margin-small-right" : "field-value-icon-base") + (_co.status.className ? (" color-text-" + _co.status.className) : "")); var currVal_1 = _co.status.iconName; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ScheduledHuntingLastRunStatusComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "img", [], [[1, "src", 4]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.showInPage ? "field-value-icon" : "field-value-icon-base"); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent.parent, 0), _co.status.image)); _ck(_v, 0, 0, currVal_0); }); }
function View_ScheduledHuntingLastRunStatusComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScheduledHuntingLastRunStatusComponent_7)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScheduledHuntingLastRunStatusComponent_8)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.status.iconName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.status.image; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ScheduledHuntingLastRunStatusComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScheduledHuntingLastRunStatusComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n  "])), (_l()(), i0.ɵand(0, [["showInTable", 2]], null, 0, null, View_ScheduledHuntingLastRunStatusComponent_4)), (_l()(), i0.ɵted(-1, null, ["\n\n  "])), (_l()(), i0.ɵand(0, [["contents", 2]], null, 0, null, View_ScheduledHuntingLastRunStatusComponent_6)), (_l()(), i0.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showInPage; var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_ScheduledHuntingLastRunStatusComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i7.BasePathPipe, [i8.AppContextService]), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScheduledHuntingLastRunStatusComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.status; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ScheduledHuntingLastRunStatusComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "scheduled-hunting-last-run-status", [], [[2, "wcd-flex-center-vertical", null]], null, null, View_ScheduledHuntingLastRunStatusComponent_0, RenderType_ScheduledHuntingLastRunStatusComponent)), i0.ɵdid(1, 180224, null, 0, i9.ScheduledHuntingLastRunStatusComponent, [i0.ChangeDetectorRef, i10.Paris, i11.PollingService, i12.I18nService], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).flexCenterVertical; _ck(_v, 0, 0, currVal_0); }); }
var ScheduledHuntingLastRunStatusComponentNgFactory = i0.ɵccf("scheduled-hunting-last-run-status", i9.ScheduledHuntingLastRunStatusComponent, View_ScheduledHuntingLastRunStatusComponent_Host_0, { rule: "rule", showInPage: "showInPage" }, {}, []);
export { ScheduledHuntingLastRunStatusComponentNgFactory as ScheduledHuntingLastRunStatusComponentNgFactory };
