import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { InvestigationGraphDataModel } from '../../../graph/airs-investigation/models/investigation-graph-data.model';
import { Investigation } from '@wcd/domain';

let lastId = 0;
@Component({
	selector: 'investigation-comments-edit-panel',
	template: `
		<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings" [attr.id]='panelId'>
			<investigation-comments
				[investigation]="investigation"
				[investigationGraphData]="investigationGraphData"
				[panelId]="panelId"
			></investigation-comments>
		</wcd-panel>
	`,
})
export class InvestigationCommentsPanelComponent extends PanelContainer {
	@Input() investigationGraphData: InvestigationGraphDataModel;
	@Input() investigation: Investigation;

	panelId = `investigation-comments-edit-panel-${lastId++}`

	constructor(protected router: Router) {
		super(router);
	}
}
