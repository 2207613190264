/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "@angular/common";
import * as i4 from "../../../global_entities/components/entity-name/entity-name.component.ngfactory";
import * as i5 from "../../../global_entities/components/entity-name/entity-name.component";
import * as i6 from "../../../global_entities/services/global-entity-types.service";
import * as i7 from "../../../global_entities/services/entity-panels.service";
import * as i8 from "../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i9 from "../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i12 from "./impacted-entities-linked-by.component";
var styles_ImpactedEntitiesLinkedByComponent = [];
var RenderType_ImpactedEntitiesLinkedByComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImpactedEntitiesLinkedByComponent, data: {} });
export { RenderType_ImpactedEntitiesLinkedByComponent as RenderType_ImpactedEntitiesLinkedByComponent };
function View_ImpactedEntitiesLinkedByComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fab-icon", [["contentClass", "field-value-icon"]], null, null, null, i1.View_FabIconComponent_0, i1.RenderType_FabIconComponent)), i0.ɵdid(1, 5816320, null, 0, i2.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null)], function (_ck, _v) { var currVal_0 = "field-value-icon"; var currVal_1 = _v.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ImpactedEntitiesLinkedByComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "li", [["class", "wcd-padding-small-right wcd-text-overflow-medium"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesLinkedByComponent_3)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.icon; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.display; _ck(_v, 6, 0, currVal_1); }); }
function View_ImpactedEntitiesLinkedByComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "fab-hover-card", [], null, null, null, i1.View_FabHoverCardComponent_0, i1.RenderType_FabHoverCardComponent)), i0.ɵdid(1, 5816320, null, 0, i2.FabHoverCardComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { type: [0, "type"], plainCardOptions: [1, "plainCardOptions"] }, null), i0.ɵpod(2, { renderPlainCard: 0 }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, 0, 7, "div", [["class", "wcd-impacted-entities-counter"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "ul", [["class", "wcd-flex wcd-padding-none-all wcd-margin-none-all wcd-flex-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesLinkedByComponent_2)), i0.ɵdid(9, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardType; var currVal_1 = _ck(_v, 2, 0, i0.ɵnov(_v.parent, 7)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.entitiesList; _ck(_v, 9, 0, currVal_2); }, null); }
function View_ImpactedEntitiesLinkedByComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "entity-name", [], null, null, null, i4.View_EntityNameComponent_0, i4.RenderType_EntityNameComponent)), i0.ɵdid(1, 573440, null, 0, i5.EntityNameComponent, [i6.GlobalEntityTypesService, i7.EntityPanelsService], { entity: [0, "entity"], entityTypeId: [1, "entityTypeId"], entitySource: [2, "entitySource"], showTooltip: [3, "showTooltip"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entity; var currVal_1 = _co.entityTypeId; var currVal_2 = _co.entitySource; var currVal_3 = _co.showTooltip; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ImpactedEntitiesLinkedByComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "impacted-entities-linked-by-card-container"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "card-title-height"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Entities"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "wcd-datatable", [], null, null, null, i8.View_DataTableComponent_0, i8.RenderType_DataTableComponent)), i0.ɵdid(8, 13352960, null, 0, i9.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i10.LiveAnnouncer, i11.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], fixedTable: [3, "fixedTable"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; var currVal_1 = false; var currVal_2 = _co.columns; var currVal_3 = true; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ImpactedEntitiesLinkedByComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesLinkedByComponent_6)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columns; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ImpactedEntitiesLinkedByComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesLinkedByComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesLinkedByComponent_4)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["card", 2]], null, 0, null, View_ImpactedEntitiesLinkedByComponent_5)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showEntityNameComponent; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showEntityNameComponent; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ImpactedEntitiesLinkedByComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "impacted-entities-linked-by", [], null, null, null, View_ImpactedEntitiesLinkedByComponent_0, RenderType_ImpactedEntitiesLinkedByComponent)), i0.ɵdid(1, 114688, null, 0, i12.ImpactedEntitiesLinkedByComponent, [i6.GlobalEntityTypesService, i11.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImpactedEntitiesLinkedByComponentNgFactory = i0.ɵccf("impacted-entities-linked-by", i12.ImpactedEntitiesLinkedByComponent, View_ImpactedEntitiesLinkedByComponent_Host_0, { entity: "entity", entityTypeId: "entityTypeId", entitySource: "entitySource", showTooltip: "showTooltip", targetEntities: "targetEntities" }, {}, []);
export { ImpactedEntitiesLinkedByComponentNgFactory as ImpactedEntitiesLinkedByComponentNgFactory };
