var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { SpinnerSize, MessageBarType } from 'office-ui-fabric-react';
import { EvaluationService } from '../services/evaluation.service';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { LAB_CONFIGURATION, DEFAULT_LAB_CONFIGURATION_ID } from '@wcd/domain';
var EvaluationLabSetupPanelComponent = /** @class */ (function (_super) {
    __extends(EvaluationLabSetupPanelComponent, _super);
    function EvaluationLabSetupPanelComponent(router, evaluationService, dialogsService, i18nService) {
        var _this = _super.call(this, router) || this;
        _this.evaluationService = evaluationService;
        _this.dialogsService = dialogsService;
        _this.i18nService = i18nService;
        _this.SpinnerSize = SpinnerSize;
        _this.MessageBarType = MessageBarType;
        _this.LAB_CONFIGURATION = LAB_CONFIGURATION;
        _this.selectedLabConfiguration = LAB_CONFIGURATION.find(function (c) { return c.id === DEFAULT_LAB_CONFIGURATION_ID; });
        return _this;
    }
    EvaluationLabSetupPanelComponent.prototype.createLab = function () {
        var _this = this;
        this.evaluationService
            .createEvaluationLab({
            id: 0,
            maxNumberOfMachines: this.selectedLabConfiguration.numberOfMachines,
            machineExpirationInHours: this.selectedLabConfiguration.hoursPerMachine,
        })
            .subscribe(function () {
            _this.evaluationService.evaluationLabCreated$.next(true);
            _this.destroy();
        }, function (error) {
            _this.dialogsService.showError({
                title: _this.i18nService.get('evaluation.lab.error'),
                data: error,
            });
        });
    };
    return EvaluationLabSetupPanelComponent;
}(PanelContainer));
export { EvaluationLabSetupPanelComponent };
