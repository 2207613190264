import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
import { NetworkDevice, SecurityRecommendation } from '@wcd/domain';

@EntityRelationship({
	sourceEntity: NetworkDevice,
	dataEntity: Vulnerability,
	endpoint: (_, query) => `assets/${encodeURIComponent(query.where['networkDeviceId'])}/vulnerabilities`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (networkDevice: NetworkDevice) => {
		return {
			networkDeviceId: networkDevice.id,
		};
	},
})
export class NetworkDeviceRelatedVulnerabilitiesRelationship
	implements EntityRelationshipRepositoryType<NetworkDevice, Vulnerability> {}

@EntityRelationship({
	sourceEntity: NetworkDevice,
	dataEntity: SecurityRecommendation,
	endpoint: (_, query) => `assets/${encodeURIComponent(query.where['networkDeviceId'])}/recommendations`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (networkDevice: NetworkDevice) => {
		return {
			networkDeviceId: networkDevice.id,
		};
	},
})
export class NetworkDeviceRelatedSecurityRecommendationsRelationship
	implements EntityRelationshipRepositoryType<NetworkDevice, SecurityRecommendation> {}
