var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PanelComponent, PanelContainer } from '@wcd/panels';
import { ProfileForCreationFormData } from '../../profiles-tab/models/profile-for-creation.model';
import { ProfileNameStepComponent } from './profile-name-step.component';
import { ProfileSettingsStepComponent } from './profile-settings-step.component';
import { ProfileConfigurationsAssignemntStepComponent } from './profile-configurations-assignment-step.component';
import { ProfileReviewStepComponent } from './profile-review-step.component';
var ProfileWizardComponent = /** @class */ (function (_super) {
    __extends(ProfileWizardComponent, _super);
    function ProfileWizardComponent(router) {
        var _this = _super.call(this, router) || this;
        _this.setSteps();
        return _this;
    }
    ProfileWizardComponent.prototype.ngOnInit = function () {
        this.title = this.isUpdateFlow
            ? 'baseline_profile_wizard_breadcrumbs_update'
            : 'baseline_profile_wizard_breadcrumbs_creation';
        this.formData = __assign({ isUpdateFlow: this.isUpdateFlow }, this.data);
        this.steps[3].nextButtonText = this.isUpdateFlow
            ? 'baseline_profile_wizard_update_profile'
            : 'baseline_profile_wizard_create_profile';
    };
    ProfileWizardComponent.prototype.onRuleCreated = function () {
        this.closePanel();
        this.onDone();
    };
    ProfileWizardComponent.prototype.onClosePanel = function () {
        this.closePanel();
        this.onClose();
    };
    ProfileWizardComponent.prototype.setSteps = function () {
        this.steps = [
            {
                id: 1,
                name: 'ProfileName',
                verticalStepKeyName: 'baseline_profile_wizard_profile_name_step_title',
                component: ProfileNameStepComponent,
            },
            {
                id: 2,
                name: 'ProfileSettings',
                verticalStepKeyName: 'baseline_profile_wizard_settings_step_title',
                component: ProfileSettingsStepComponent,
            },
            {
                id: 3,
                name: 'ConfigurationAssignment',
                verticalStepKeyName: 'baseline_profile_wizard_profile_configurations_assignment_label',
                component: ProfileConfigurationsAssignemntStepComponent,
                wideStep: true,
            },
            {
                id: 4,
                name: 'ReviewProfile',
                verticalStepKeyName: 'baseline_profile_wizard_review_profile_step_title',
                component: ProfileReviewStepComponent,
                onNext: true,
                nextButtonText: 'baseline_profile_wizard_create_profile',
            },
        ];
    };
    return ProfileWizardComponent;
}(PanelContainer));
export { ProfileWizardComponent };
