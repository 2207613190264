/**
 * Manages MTP SCC flows
 */
import { SccResourceService } from './resource.svc';

export class SccMTPService {
	constructor(private sccResource: SccResourceService) {}

	async bootMTP(checkTenantStatus?: boolean): Promise<any> {
		const { bootMTP } = await this.sccResource.load('bootMTP');
		return bootMTP(checkTenantStatus);
	}
}
