import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('errorcode', errorcodeFilter);

function errorcodeFilter() {
	return function(input) {
		if (input == '') {
			return input;
		}

		switch (input) {
			case 2147942722:
				return 'Device has no resources';
			case 2147942623:
				return 'File too large';
			case 2147943862:
				return 'Incorrect size';
			case 2149122552:
				return 'Gateway timeout';
			case 2149122456:
				return 'Request timeout';
			case 2149122551:
				return 'Service unavailable';
			case 32802:
				return 'Reboot required';
			case 32803:
				return 'File is signed by a trusted publisher.';
			case -2146992093:
				return 'File is signed by a trusted publisher.';
			case 2:
				return 'File not found. It may have been deleted or moved.';
			case -2147024894:
				return 'File not found. It may have been deleted or moved.';
			case 15301:
				return 'File has been modified.';
			case -2147009595:
				return 'File has been modified.';
			case -2147023890:
				return 'File is empty or invalid.';
			case 1006:
				return 'File is empty or invalid.';
			case -2145844840:
				return 'Device did not get the command';
			case 408:
				return 'Device did not get the command';
			case -2147024891:
				return 'Access denied. File is in use or read-only.';
			case 5:
				return 'Access denied. File is in use or read-only.';
			case -2147020579:
			case 4317:
				return 'AV Scan is already in progress.';
			case -2147416390:
				return 'Windows Defender Antivirus is disabled';
			case 0:
				return '';
			default:
				return 'Unknown error';
		}
	};
}
