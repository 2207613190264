import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { Software, Tag, FILTER_TAG, FilterDiscoveredVulnerabilities } from '@wcd/domain';
import { FieldsService } from '../../../../global_entities/models/entity-type.interface';
import { TvmImpactScoreComponent } from '../../../../tvm/components/tvm-impact-score/tvm-impact-score.component';
import { TvmThreatIconsComponent } from '../../../../tvm/components/tvm-threat-icons/tvm-threat-icons.component';
import { Router } from '@angular/router';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { FilterValuesChecklistComponent, ListFilterValueConfig } from '@wcd/ng-filters';
import { TvmIconBuilderService } from '../../../../tvm/services/tvm-icon-builder.service';
import { TvmAssetsStatisticsComponent } from '../../../../tvm/components/tvm-assets-statistics/tvm-assets-statistics.component';
import { FeaturesService, Feature } from '@wcd/config';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { ThreatInfoService } from './threat-info.service';
import { TaggedFieldComponent } from '../../../../tvm/components/tagged-field/tagged-field.component';
import { TvmTaggedFieldService } from '../../../../tvm/services/tagged-field.service';

@Injectable()
export class SoftwareFieldsService implements FieldsService<Software> {
	private _fields: Array<DataviewField<Software>>;
	isNewExploitExperienceEnabled: boolean;
	hasNetworkScanEnabled: boolean;
	private _topVulnerableSoftwareWidgetFields: Array<DataviewField<Software>>;

	constructor(
		private router: Router,
		private tvmTextsService: TvmTextsService,
		private activatedEntity: ActivatedEntity,
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		private tvmTagsService: TvmTagsService,
		private tvmIconBuilderService: TvmIconBuilderService,
		private tvmTaggedFieldService: TvmTaggedFieldService,
		private threatInfoService: ThreatInfoService
	) {
		this.isNewExploitExperienceEnabled = featuresService.isEnabled(Feature.TvmExploits);
		this.hasNetworkScanEnabled = this.featuresService.isEnabled(Feature.TvmNetworkScan);
	}
	get fields(): Array<DataviewField<Software>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<Software>([
				{
					id: 'name',
					name: this.i18nService.get('tvm.common.name'),
					component: {
						type: TaggedFieldComponent,
						getProps: (software: Software) => {
							const zeroDayProps = this.tvmTaggedFieldService.getZeroDayBaseProps(
								software.mostSevereVulnerabilityType,
								software.patchReleaseDate,
								software.name
							);

							return {
								...zeroDayProps,
								onClick: (software: Software) => {
									this.activatedEntity.setNextEntity(software);
									this.router.navigate([
										`/software-inventory/${encodeURIComponent(software.id)}`,
									]);
								},
								value: software,
							};
						},
					},
				},
				{
					id: 'osPlatform',
					name: this.i18nService.get('tvm_common_osPlatform'),
					sort: { enabled: false },
					getDisplay: (software: Software) =>
						software.osPlatform
							? this.i18nService.get(`tvm_common_osPlatform_${software.osPlatform}`)
							: this.i18nService.strings.notAvailable_short,
				},
				{
					id: 'vendor',
					name: this.i18nService.get('tvm.common.vendor'),
				},
				{
					id: 'discoveredVulnerabilities',
					name: this.i18nService.get('tvm.common.weaknesses'),
					getDisplay: (software: Software) =>
						software.productNeverMatched
							? this.i18nService.get('notAvailable.long')
							: `${software.discoveredVulnerabilities}`,
					filter: {
						component: {
							type: FilterValuesChecklistComponent,
							config: {
								mapFilterValue: (
									filterValue: any
								): ListFilterValueConfig<FilterDiscoveredVulnerabilities> => {
									const values = [
										{
											value: FilterDiscoveredVulnerabilities.hasWeaknesses,
											name: this.i18nService.get(
												'tvm.softwarePage.filters.discoveredVulnerabilities.hasWeaknesses'
											),
											priority: 1,
										},
										{
											value: FilterDiscoveredVulnerabilities.noWeaknesses,
											name: this.i18nService.get(
												'tvm.softwarePage.filters.discoveredVulnerabilities.noWeaknesses'
											),
											priority: 2,
										},
										{
											value: FilterDiscoveredVulnerabilities.notAvailable,
											name: this.i18nService.get('notAvailable.long'),
											priority: 3,
										},
									];

									const value = values.find(
										(val) => val.value === FilterDiscoveredVulnerabilities[filterValue]
									);
									return {
										id: value.value,
										rawValue: value.value,
										name: value.name,
										priority: value.priority,
									};
								},
							},
						},
					},
				},
				{
					id: 'threats',
					name: this.i18nService.get('tvm.common.threats'),
					sort: {
						enabled: false,
					},
					component: {
						type: TvmThreatIconsComponent,
						getProps: (software: Software) =>
							this.tvmIconBuilderService.configureIcons(
								software.threatInfo,
								software.threats,
								'software',
								false,
								null,
								true
							),
					},
					filter: {
						component: {
							type: FilterValuesChecklistComponent,
							config: {
								mapFilterValue: (filterValue: any): ListFilterValueConfig<any> => {
									const values = this.threatInfoService.getFiltersData();

									const value = values.find((val) => val.value === filterValue);

									return {
										id: value.value,
										rawValue: value.value,
										name: value.name,
										priority: value.priority,
									};
								},
							},
						},
					},
				},
				{
					id: 'exposedDevicesWithTrends',
					name: this.i18nService.get('tvm.common.exposedDevices'),
					sort: {
						enabled: false, // Re enable when we support nested field sorting
					},
					component: {
						type: TvmAssetsStatisticsComponent,
						getProps: (software: Software) => ({
							totalAssetCount: software.assetsStatistics.totalAssetCount,
							assetsCount: software.assetsStatistics.assetsAtRiskCount,
							assetsCountHistory: software.assetsStatistics.assetsAtRiskHistory,
							productNeverMatched: software.productNeverMatched,
						}),
					},
					allowResize: false,
					minWidth: 200,
				},
				{
					id: 'riskScore',
					name: this.i18nService.get('tvm.common.impact'),
					description: this.tvmTextsService.getText(TextToken.ScoreImpactInfo),
					sort: {
						sortDescendingByDefault: true,
					},
					component: {
						type: TvmImpactScoreComponent,
						getProps: (software: Software) => ({
							scoreData: {
								exposureScore: software.exposureScoreImprovement,
								exposureScoreTooltip: this.tvmTextsService.getText(
									TextToken.ExposureScoreTooltip
								),
							},
						}),
					},
				},
				{
					id: 'filterTags',
					name: this.i18nService.get('common.tags'),
					filterOnly: true,
					filter: {
						component: {
							type: FilterValuesChecklistComponent,
							config: {
								mapFilterValue: (filterValue: any): ListFilterValueConfig<FILTER_TAG> => {
									const values = this.tvmTagsService
										.getSoftwareTagsForFiltering()
										.map((tag) => {
											return {
												value: FILTER_TAG[tag],
												name: this.i18nService.get(
													`tvm_softwarePage_filters_tags_${tag}`
												),
												count: null,
											};
										});
									const v = values.find((val) => val.value === FILTER_TAG[filterValue]);
									return {
										id: v.value,
										rawValue: v.value,
										name: v.name,
									};
								},
							},
						},
					},
				},
				{
					id: 'tags',
					name: this.i18nService.get('common.tags'),
					component: {
						type: TagsFieldComponent,
						getProps: (software: Software) => ({
							tags: this.tvmTagsService.getSoftwareTags(software),
						}),
					},
					sort: { enabled: false },
				},
			]).filter(Boolean);
		}
		return this._fields;
	}

	get topVulnerableSoftwareWidgetFields(): Array<DataviewField<Software>> {
		if (!this._topVulnerableSoftwareWidgetFields) {
			this._topVulnerableSoftwareWidgetFields = DataviewField.fromList<Software>([
				{
					id: 'name',
					name: this.i18nService.get('tvm.common.software'),
					getTooltip: (software: Software) => software.name,
					component: {
						type: TaggedFieldComponent,
						getProps: (software: Software) => {
							const zeroDayProps = this.tvmTaggedFieldService.getZeroDayBaseProps(
								software.mostSevereVulnerabilityType,
								software.patchReleaseDate,
								software.name
							);

							return {
								...zeroDayProps,
								value: software,
							};
						},
					},
					//fluidWidth: 0.5,
				},
			]).concat(
				this.fields.filter(
					(field) =>
						field.id === 'osPlatform' ||
						field.id === 'discoveredVulnerabilities' ||
						field.id === 'threats' ||
						field.id === 'exposedDevicesWithTrends'
				)
			);
		}

		return this._topVulnerableSoftwareWidgetFields;
	}
}
