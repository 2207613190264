var _a;
import { Incident, TagType } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { compact } from 'lodash';
import { FilterValuesChecklistComponent, } from '@wcd/ng-filters';
import { CsvUtils } from '@wcd/shared';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
export var EntityTags = {
    RANSOMWARE: "ransomware_tag",
    CED: "ced_tag",
    MTE: "mte_tag",
    MTE_CLASSIC: "mte_classic_tag",
    DEX: "dex_tag",
    HVE: "hve_tag",
    SPOOFING: "spoofing_tag",
};
export var PredefinedTagKeys = Object.values(EntityTags);
var PredefinedTagPriorities = (_a = {},
    _a[EntityTags.RANSOMWARE] = 10,
    _a[EntityTags.SPOOFING] = 20,
    _a[EntityTags.HVE] = 30,
    _a[EntityTags.MTE_CLASSIC] = 38,
    _a[EntityTags.DEX] = 39,
    _a[EntityTags.MTE] = 40,
    _a[EntityTags.CED] = 50,
    _a);
var EntityTagsService = /** @class */ (function () {
    function EntityTagsService(appInsightService, paris, i18nService) {
        this.appInsightService = appInsightService;
        this.paris = paris;
        this.i18nService = i18nService;
        this._builtInTags = new Map();
        this._incidentsQueueLocalizedTags = new Map();
    }
    EntityTagsService.prototype.getEntityTagsFieldProps = function (entity) {
        return {
            tags: entity instanceof Incident ? this.getIncidentTags(entity) : this.getAlertTags(entity),
            autoOverflow: true,
        };
    };
    EntityTagsService.prototype.getIncidentTags = function (incident) {
        return incident.incidentTags ? this.getEntityTags(incident.incidentTags) : [];
    };
    EntityTagsService.prototype.getAlertTags = function (alert) {
        return alert.alertTags ? this.getEntityTags(alert.alertTags) : [];
    };
    EntityTagsService.prototype.getEntityTags = function (_a) {
        var _this = this;
        var _b = _a.incidentUserTags, incidentUserTags = _b === void 0 ? [] : _b, actorNames = _a.actorNames, deviceTags = _a.deviceTags, userTags = _a.userTags, systemTags = _a.systemTags;
        var actors = (Array.isArray(actorNames) ? actorNames : (actorNames && [actorNames]) || []).filter(function (a) { return a && a !== ""; });
        var hasDexTag = systemTags.includes(EntityTags.DEX);
        var hasMteClassicTag = systemTags.includes(EntityTags.MTE_CLASSIC);
        // The order of the tags matters
        return compact([
            systemTags.includes(EntityTags.RANSOMWARE) && this.getRansomwareTag(),
            systemTags.includes(EntityTags.SPOOFING) && this.getTelemetrySpoofingTag()
        ].concat(actors.map(function (tag) { return _this.createTagWithLabelAndType(tag, TagType.actor); }), [
            systemTags.includes(EntityTags.HVE) && this.getHveTag(),
            hasDexTag && this.getDexTag(),
            hasMteClassicTag && this.getMteClassicTag(),
            !hasDexTag && !hasMteClassicTag && systemTags.includes(EntityTags.MTE) && this.getMteTag(),
            systemTags.includes(EntityTags.CED) && this.getChainEventDetectionTag()
        ], incidentUserTags.map(function (tag) { return _this.createTagWithLabelAndType(tag, TagType.user); }), deviceTags.map(function (tag) { return _this.createTagWithLabelAndType(tag, TagType.system); }), userTags.map(function (tag) { return _this.createTagWithLabelAndType(tag, TagType.system); })));
    };
    EntityTagsService.prototype.getIncidentQueueTagsFilterConfig = function (priority) {
        var _this = this;
        return {
            priority: priority,
            component: {
                config: {
                    allowSingleValueDeselection: true,
                    allowUnknownValues: false,
                },
                type: FilterValuesChecklistComponent,
            },
            serializeFilterValues: function (filterSelection) {
                return filterSelection && filterSelection.length > 0
                    ? {
                        tag: filterSelection
                            .map(function (filterValue) {
                            if (_this._incidentsQueueLocalizedTags.get(filterValue) &&
                                _this._incidentsQueueLocalizedTags.get(filterValue) !== filterValue) {
                                // Add the localized value as well to the filter query
                                var localizedFilterValue = CsvUtils.encodeCsv(_this._incidentsQueueLocalizedTags.get(filterValue));
                                var additionalSearchTerms = (filterValue === EntityTags.MTE_CLASSIC || filterValue === EntityTags.DEX ? "," + EntityTags.MTE : "");
                                return localizedFilterValue + "," + CsvUtils.encodeCsv(filterValue) + additionalSearchTerms;
                            }
                            return CsvUtils.encodeCsv(filterValue);
                        })
                            .join(','),
                    }
                    : null;
            },
            deserializeFilterValues: function (serializedValues) {
                return serializedValues.tag && CsvUtils.decodeCsv(serializedValues.tag[0]);
            },
        };
    };
    EntityTagsService.prototype.getIncidentsQueueTagsFilterValues = function (tags) {
        var _this = this;
        var existingTags = new Set();
        var hasDexTag = tags.map(function (t) { return t.key; }).includes(EntityTags.DEX);
        return tags
            .filter(function (tag) { return !hasDexTag || (hasDexTag && tag.key !== EntityTags.MTE); })
            .map(function (tag) {
            if (PredefinedTagKeys.includes(tag.key)) {
                var tagLabel = _this.i18nService.strings["incident_tags_" + tag.key];
                existingTags.add(tagLabel);
                _this._incidentsQueueLocalizedTags.set(tag.key, tagLabel);
                return {
                    value: tag.key,
                    name: tagLabel,
                    priority: PredefinedTagPriorities[tag.key],
                    count: null,
                };
            }
            else if (!existingTags.has(tag.key)) {
                // could be a user defined a tag like "Bedrohungsexperten" === this.i18nService.strings[`incident_tags_mte_tag`], need to dedup
                existingTags.add(tag.key);
                _this._incidentsQueueLocalizedTags.set(tag.key, tag.key);
                return {
                    value: tag.key,
                    name: tag.key,
                    priority: 0,
                    count: null,
                };
            }
        })
            .filter(Boolean);
    };
    EntityTagsService.prototype.getIncidentSensitivityTagFieldProps = function (entity) {
        return {
            tags: entity instanceof Incident ? [entity.sensitivity] : [],
        };
    };
    EntityTagsService.prototype.createTagWithLabelAndType = function (label, tagType) {
        return {
            id: label,
            name: label,
            ariaLabel: label,
            type: tagType,
        };
    };
    EntityTagsService.prototype.getRansomwareTag = function () {
        if (this._builtInTags.has(EntityTags.RANSOMWARE))
            return this._builtInTags.get(EntityTags.RANSOMWARE);
        var name = this.i18nService.strings.incident_tags_ransomware_tag;
        var ransomwareTag = {
            id: EntityTags.RANSOMWARE,
            name: name,
            type: TagType.ransomware,
            ariaLabel: name,
        };
        this._builtInTags.set(EntityTags.RANSOMWARE, ransomwareTag);
        return ransomwareTag;
    };
    EntityTagsService.prototype.getMteTag = function () {
        if (this._builtInTags.has(EntityTags.MTE))
            return this._builtInTags.get(EntityTags.MTE);
        var name = this.i18nService.strings.incident_tags_mte_tag;
        var mteTag = {
            id: EntityTags.MTE,
            name: name,
            type: TagType.threatExpert,
            ariaLabel: name,
        };
        this._builtInTags.set(EntityTags.MTE, mteTag);
        return mteTag;
    };
    EntityTagsService.prototype.getDexTag = function () {
        if (this._builtInTags.has(EntityTags.DEX))
            return this._builtInTags.get(EntityTags.DEX);
        var name = this.i18nService.strings.incident_tags_mte_tag;
        var mteTag = {
            id: EntityTags.DEX,
            name: name,
            type: TagType.threatExpert,
            ariaLabel: name,
        };
        this._builtInTags.set(EntityTags.DEX, mteTag);
        return mteTag;
    };
    EntityTagsService.prototype.getMteClassicTag = function () {
        if (this._builtInTags.has(EntityTags.MTE_CLASSIC))
            return this._builtInTags.get(EntityTags.MTE_CLASSIC);
        var name = this.i18nService.strings.incident_tags_mte_classic_tag;
        var mteTag = {
            id: EntityTags.MTE_CLASSIC,
            name: name,
            type: TagType.threatExpert,
            ariaLabel: name,
        };
        this._builtInTags.set(EntityTags.MTE_CLASSIC, mteTag);
        return mteTag;
    };
    // AKA Priority Account
    EntityTagsService.prototype.getHveTag = function () {
        if (this._builtInTags.has(EntityTags.HVE))
            return this._builtInTags.get(EntityTags.HVE);
        var name = this.i18nService.strings.incident_tags_hve_tag;
        var hveTag = {
            id: EntityTags.HVE,
            name: name,
            type: TagType.highValueAsset,
            ariaLabel: name,
        };
        this._builtInTags.set(EntityTags.HVE, hveTag);
        return hveTag;
    };
    EntityTagsService.prototype.getTelemetrySpoofingTag = function () {
        if (this._builtInTags.has(EntityTags.SPOOFING))
            return this._builtInTags.get(EntityTags.SPOOFING);
        var name = this.i18nService.strings.incident_tags_spoofing_tag;
        var spoofingTag = {
            id: EntityTags.SPOOFING,
            name: name,
            type: TagType.telemetrySpoofing,
            ariaLabel: name,
        };
        this._builtInTags.set(EntityTags.SPOOFING, spoofingTag);
        return spoofingTag;
    };
    // AKA Alert to Event
    EntityTagsService.prototype.getChainEventDetectionTag = function () {
        if (this._builtInTags.has(EntityTags.CED))
            return this._builtInTags.get(EntityTags.CED);
        var name = this.i18nService.strings.incident_tags_ced_tag;
        var cedTag = {
            id: EntityTags.CED,
            name: name,
            type: TagType.alertToEvent,
            ariaLabel: name,
        };
        this._builtInTags.set(EntityTags.CED, cedTag);
        return cedTag;
    };
    return EntityTagsService;
}());
export { EntityTagsService };
