import { SevilleModule } from '../../../seville/seville.module';

SevilleModule.directive('prevalenceMailCube', eventsTimelineDirective);

eventsTimelineDirective.$inject = [];

function eventsTimelineDirective() {
	return {
		restrict: 'EA',
		scope: {
			sender: '=',
			prevalence: '=',
			firstSeen: '=',
			lastSeen: '=',
			loaded: '=',
			lookback: '=',
			numberOfSenders: '=',
			isDemoTenant: '=',
			sha256: '=',
			deepLinkToOffice: '=',
		},
		template: `
		<div class="inner-box border-default">
        <div class="prevalence-title">
            <span class="entity-meta-main-title">
                 Prevalence: email inboxes
            </span>
            <div class="prevalence-lookback"><br/>
            </div>
        </div>
        <div>
            <span class="prevalence-counter" ng-if="prevalenceMailCube.loaded && prevalenceMailCube.prevalence >= 0">{{prevalenceMailCube.prevalence}}</span>
            <span class="prevalence-counter" ng-if="prevalenceMailCube.loaded && prevalenceMailCube.prevalence < 0 ">-</span>
        </div>
        <div ng-if="prevalenceMailCube.prevalence > 0 && prevalenceMailCube.numberOfSenders == 1" ng-class="{'prevalence-counter-child': prevalenceMailCube.loaded}">
            Sender:<span> {{prevalenceMailCube.sender}}</span>
        </div>
        <div ng-if="prevalenceMailCube.prevalence > 0 && prevalenceMailCube.numberOfSenders > 1" ng-class="{'prevalence-counter-child': prevalenceMailCube.loaded}">
            Sender:<span> {{prevalenceMailCube.numberOfSenders}} senders</span>
        </div>
        <div ng-if="prevalenceMailCube.prevalence > 0 " >
            First seen: <span class=" prevalence-date" time-ago utc="{{prevalenceMailCube.firstSeen}}"> </span>
            <a ng-if="prevalenceMailCube.isDemoTenant" 
            	data-track-id="DemoPrevalenceMailCube"
				data-track-type="ExternalLink"
            	href="{{'https://go.microsoft.com/fwlink/?linkid=841531&amp;pc=' + prevalenceMailCube.sha256.substring(0,60) }}" target="_blank" style="float: right;">Open in Office365</a>
            <a ng-if="!prevalenceMailCube.isDemoTenant" 
            	data-track-id="PrevalenceMailCubeDeepLinkToOffice"
				data-track-type="ExternalLink"
            	href="{{prevalenceMailCube.deepLinkToOffice}}" target="_blank" style="float: right;">Open in Office365</a>
        </div>
    </div>
		`,
		controllerAs: 'prevalenceMailCube',
		bindToController: true,
		controller: [
			'appConfig',
			function(appConfig) {
				this.office365DemoLink = 'http://go.microsoft.com/fwlink/?LinkId=828004';
			},
		],
	};
}
