import {
	Component,
	ComponentFactoryResolver,
	Injector,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { PanelService } from '../services/panels.service';

@Component({
	selector: 'wcd-panel-placeholder',
	template: `
		<div #panelplaceholder></div>
	`,
})
export class WcdPanelPlaceholderComponent implements OnInit {
	@ViewChild('panelplaceholder', { read: ViewContainerRef, static: true })
	viewContainerRef;

	constructor(
		private panelService: PanelService,
		private injector: Injector,
		private _resolver: ComponentFactoryResolver
	) {}

	ngOnInit(): void {
		this.panelService.registerViewContainerRef(this.viewContainerRef);
		this.panelService.registerInjector(this.injector);
		this.panelService.registerResolver(this._resolver);
	}
}
