var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { pickBy } from 'lodash-es';
import { TelemetryService } from './telemetry.service';
import { TrackingAttributes } from './models/tracking-attributes.models';
import { TrackingEventType } from './models/telemetry-event.model';
var TRACK_SELECTOR = "[" + TrackingAttributes.Id + "]";
var TelemetryDelegateDirective = /** @class */ (function () {
    function TelemetryDelegateDirective(telemetryService) {
        this.telemetryService = telemetryService;
    }
    TelemetryDelegateDirective.prototype.onMouseDown = function (e) {
        var nonLeftMouseButtonPressed = (e.button != null && e.button !== 0) || e.which !== 1;
        var nonRelevantModifierPressed = e.altKey || e.shiftKey;
        if (nonLeftMouseButtonPressed || nonRelevantModifierPressed) {
            return;
        }
        var element = e.target.closest(TRACK_SELECTOR);
        if (!element) {
            return;
        }
        var eventTypeAttributeValue = element.getAttribute(TrackingAttributes.Type);
        var eventType = TrackingEventType[eventTypeAttributeValue];
        if (!eventType && eventTypeAttributeValue) {
            console.warn("Unknown tracking event type: '" + eventTypeAttributeValue + "', not tracking.");
            return;
        }
        var eventId = element.getAttribute(TrackingAttributes.Id);
        if (!eventType || !eventId) {
            return;
        }
        var telemetryEvent = __assign({ type: eventType, id: eventId }, pickBy({
            package: getClosestAttributeValue(element, TrackingAttributes.Package),
            component: getClosestAttributeValue(element, TrackingAttributes.Component),
            componentType: getClosestAttributeValue(element, TrackingAttributes.ComponentType),
            payload: element.getAttribute(TrackingAttributes.Value),
        }, function (property) { return property !== undefined; }));
        this.telemetryService.trackEvent(telemetryEvent);
    };
    return TelemetryDelegateDirective;
}());
export { TelemetryDelegateDirective };
function getClosestAttributeValue(element, attribute) {
    var closestElementWithAttribute = element.closest("[" + attribute + "]");
    return closestElementWithAttribute && closestElementWithAttribute.getAttribute(attribute);
}
