import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Cyber event MITRE technique info',
	pluralName: 'Cyber event MITRE techniques info',
	readonly: true,
})
export class CyberEventMitreTechniqueInfo extends ModelBase {
	@EntityField({ data: 'TechniqueId' })
	techniqueId: string;

	@EntityField({ data: 'Description' })
	description?: string;
}
