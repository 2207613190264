import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { ExceptionArgs } from './exception-args/exception-args.value-object';
import { ExceptionJustification } from './exception-justification.enum';
import {
	TvmRemediationSharedEntityConfigurations,
	ParseRemediationDataQuery,
} from '../remediationEndPointUtils';
import { UserInfo } from '../common/remediation/requester.value-object';
import { RecommendationExceptionStatusWithCount } from './recommendation-exception-status-with-count.value-object';
import { RecommendationExceptionStateValue } from './recommendation-exception-state-value.enum';

@Entity({
	...TvmRemediationSharedEntityConfigurations,
	singularName: 'Aggregated recommendation exception',
	pluralName: 'Aggregated recommendation exceptions',
	endpoint: 'aggregatedExceptions',
	parseItemQuery: id => {
		return `aggregatedExceptions/${id}`;
	},
	parseDataQuery: dataQuery => ParseRemediationDataQuery(dataQuery, 'aggregatedRecommendationException'),
})
export class RecommendationExceptionAggregated extends EntityModelBase<string> {
	@EntityField({
		data: 'title',
	})
	readonly title: string;

	@EntityField({
		data: 'isGlobalException',
	})
	readonly isGlobalException: boolean;

	@EntityField({
		data: 'rbacGroupIdsCount',
	})
	readonly rbacGroupIdsCount: number;

	@EntityField({
		data: 'distinctJustifications',
	})
	readonly distinctJustifications: ExceptionJustification[];

	@EntityField({
		data: 'justificationContext',
	})
	readonly justificationContext?: string;

	@EntityField({
		data: 'distinctStatusWithCount',
		arrayOf: RecommendationExceptionStatusWithCount,
	})
	readonly distinctStatusWithCount: RecommendationExceptionStatusWithCount[];

	@EntityField({
		data: 'primaryStatus',
	})
	readonly primaryStatus: RecommendationExceptionStateValue;

	@EntityField({
		data: 'expiringOn',
	})
	readonly expiringOn?: Date;

	@EntityField({
		data: 'expirationDatesCount',
	})
	readonly expirationDatesCount: number;

	@EntityField({
		data: 'createdOn',
	})
	readonly createdOn?: Date;

	@EntityField({
		data: 'creationDatesCount',
	})
	readonly creationDatesCount: number;

	@EntityField({
		data: 'exceptionArgs',
	})
	readonly exceptionArgs: ExceptionArgs;

	@EntityField({
		data: 'requester',
	})
	readonly requester?: UserInfo;

	@EntityField({
		data: 'requesterCount',
	})
	readonly requesterCount: number;
}
