import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Impact',
	pluralName: 'Impacts',
})
export class AssetsStatistics extends ModelBase {
	@EntityField({
		required: true,
	})
	readonly totalAssetCount: number;

	@EntityField({
		required: true,
	})
	readonly assetsAtRiskCount: number;

	@EntityField({
		required: true,
	})
	readonly assetsAtRiskHistory: number[];

	@EntityField()
	readonly assetsCountHistory: number[];

	@EntityField() readonly nonProductivityImpactedAssets?: number;

	@EntityField()
	readonly assetsAtRiskCountAfterApplyingExceptions: number;
}
