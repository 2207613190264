<!-- tslint:disable:template-click-events-have-key-events -->
<section aria-label="Top security recommendations" class="security-recommendations wcd-full-height wcd-flex-vertical wcd-padding-large-top wcd-padding-bottom ms-background-color-white" data-track-component="TvmSecurityRecommendations">
	<div class="wcd-flex-vertical wcd-padding-horizontal">
		<div id="tvm-top-security-recommendations-header" class="wcd-margin-small-bottom wcd-font-size-xl-plus" role="heading" aria-level="2">
			<span>{{ ('tvm.dashboard.topSecurityRecommendations' | i18n) }}</span>
			<span [attr.aria-label]="'showing top ' + visibleCards + ' recommendations out of ' + totalRecommendationsCount + ' available recommendations'" *ngIf="_cardsData && _cardsData.length">{{visibleCards}}/<span class="ms-fontColor-gray140">{{totalRecommendationsCount}}</span></span>
		</div>
		<p class="wcd-margin-largeMedium-bottom wcd-font-size-m">{{ ('tvm.securityRecommendation.basedImpact' | i18n) }}</p>
	</div>
	<div class="wcd-flex-1 wcd-flex-vertical">
		<ng-container *ngIf="_cardsData; else loader">
			<ng-container *ngIf="_cardsData.length > 0; else noData">
				<div class="cardsContainer wcd-flex-1 wcd-flex wcd-flex-wrap-vertical">
					<ng-container  *ngFor="let cardData of _cardsData">
						<tvm-security-recommendation-card class="wcd-margin-bottom wcd-full-width" *ngIf="cardData.showCard" [cardData]="cardData" wcdVisibility [refresh$]="refresh$" (onVisibilityChange)="onVisibilityChange($event)"></tvm-security-recommendation-card>
					</ng-container>
				</div>
				<div class="wcd-flex-horizontal">
					<a
						href="#"
						[attr.aria-label]="'tvm.dashboard.showMore.securityRecommendations' | i18n"
						tabindex="0"
						class="wcd-margin-right wcd-margin-left wcd-flex-0"
						data-track-id="OpenRecommendationsPage"
						data-track-type="Navigation"
						(click)="openRecommendationsPage()"
						>{{ ('tvm.common.showMore' | i18n) }}
					</a>
					<a
						href="#"
						[attr.aria-label]="'tvm.dashboard.showMore.exceptions' | i18n"
						tabindex="0"
						class="wcd-flex-1"
						data-track-id="OpenFilteredRecommendationsPage"
						data-track-type="Navigation"
						(click)="openFilteredRecommendationsPage()"
						>{{ ('tvm.common.showExceptions' | i18n) }}
					</a>
				</div>
			</ng-container>
		</ng-container>

		<ng-template #loader>
			<div class="wcd-flex-1 wcd-flex-center-all">
				<fab-spinner [size]="SpinnerSize.large" label="Loading recommendations..."></fab-spinner>
			</div>
		</ng-template>

		<ng-template #noData>
			<div class="widget-nodata wcd-padding-horizontal">
				<h2 class="wcd-margin-small-bottom wcd-font-weight-bold">
					{{'common.noDataToShow' | i18n}}
				</h2>
				<div>
					{{ (noRecommendationsMessage | async) }}
				</div>
			</div>
		</ng-template>

	</div>
</section>
