<section aria-label="Filters" class="wcd-filters wcd-full-height wcd-flex-vertical">
	<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-large-horizontal" [attr.aria-hidden]="checkFocus">
		<wcd-filters-field
			*ngFor="let field of fieldsWithData"
			[field]="field"
			[data]="data[field.id]"
			[fixedSelectedValues]="fixedSelectedValues"
			[selectedValues]="fieldSelectedValues?.get(field.id)"
			(filterChange)="onFieldChange($event)"
			#focusable></wcd-filters-field>
	</div>
	<div class="wcd-flex-none wcd-padding-horizontal wcd-padding-small-top wcd-padding-bottom wcd-border-top"
			*ngIf="isDirty || fieldSelectedValues?.size">
		<ng-container *ngIf="isDirty; else clearFilters">
			<fab-primary-button (onClick)="apply()" [text]="i18nService.strings.buttons_apply" className="wcd-margin-xsmall-right"></fab-primary-button>
			<fab-default-button (onClick)="cancel()" [text]="i18nService.strings.buttons_cancel"></fab-default-button>
		</ng-container>
		<ng-template #clearFilters>
			<fab-default-button (onClick)="clearAllFilters()"
				[text]="i18nService.strings.filters_clear"
				[iconProps]="{ iconName: 'ClearFilter' }"
				*ngIf="fieldSelectedValues?.size">
			</fab-default-button>
		</ng-template>
	</div>
</section>
