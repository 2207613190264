import { FabSpinnerModule } from '@angular-react/fabric';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PartnerRecommendationPanelComponent } from './components/partner.recommendation.panel.component';
import { PanelsModule } from '@wcd/panels';

@NgModule({
	imports: [SharedModule, FabSpinnerModule, PanelsModule],
	declarations: [PartnerRecommendationPanelComponent],
	entryComponents: [PartnerRecommendationPanelComponent],
})
export class PartnersEntityModule {}
