import { wcdGetWindow } from './dom/wcdGetWindow';
import { wcdSetFocusVisibility } from './setFocusVisibility/wcdSetFocusVisibility';
import { wcdIsDirectionalKeyCode } from './setFocusVisibility/wcdKeyboard';
export { WCDIsFocusVisibleClassName } from './setFocusVisibility/wcdSetFocusVisibility';

/**
 * Initializes the logic which:
 *
 * 1. Subscribes keydown and mousedown events. (It will only do it once per window,
 *    so it's safe to call this method multiple times.)
 * 2. When the user presses directional keyboard keys, adds the 'ms-Fabric--isFocusVisible' classname
 *    to the document body, removes the 'ms-Fabric-isFocusHidden' classname.
 * 3. When the user clicks a mouse button, adds the 'ms-Fabric-isFocusHidden' classname to the
 *    document body, removes the 'ms-Fabric--isFocusVisible' classname.
 *
 * This logic allows components on the page to conditionally render focus treatments based on
 * the existence of global classnames, which simplifies logic overall.
 *
 * @param window - the window used to add the event listeners
 */
export function wcdInitializeFocusRects(window?: Window): void {
	const win = (window || wcdGetWindow()) as Window & { __wcdHasInitializeFocusRects__: boolean };

	if (win && !win.__wcdHasInitializeFocusRects__) {
		win.__wcdHasInitializeFocusRects__ = true;
		win.addEventListener('mousedown', _onMouseDown, true);
		win.addEventListener('keydown', _onKeyDown as () => void, true);
	}
}

function _onMouseDown(ev: MouseEvent): void {
	// the check for the parameter detail comes from the need to distinguish between
	// a real mouse event and fake event occurs by other program in general
	// and specifically NVDA how known for swallowing space and enter keyboard events
	// and spit them as mouse event (https://github.com/nvaccess/nvda/issues/7898)
	//
	// the parameter ev.detail is read-only property, when non-zero, provides the current (or next, depending on the event) click count.
	// so in case of a click or a double click the param value will be higher the zero
	// When NVDA trigger the mouse event the ev.detail will be zero
	if (ev.detail) {
		wcdSetFocusVisibility(false, ev.target as Element);
	}
}

function _onKeyDown(ev: KeyboardEvent): void {
	wcdIsDirectionalKeyCode(ev.which) && wcdSetFocusVisibility(true, ev.target as Element);
}
