import { ElementRef, QueryList } from '@angular/core';
import { Positions } from '@wcd/forms';
import { DataViewModel } from '../../models/dataview.model';
import { DataviewActionFancySelectComponent } from './dataview-action-fancy-select.component';
import { DataviewActionTypes } from './dataview-actions.model';
var DataviewActionWrapperComponent = /** @class */ (function () {
    function DataviewActionWrapperComponent() {
        this.actionClass = '';
        this.position = Positions.Left;
        this.dataviewActionTypes = DataviewActionTypes;
    }
    Object.defineProperty(DataviewActionWrapperComponent.prototype, "dropdownButtonRef", {
        get: function () {
            if (!this._dropdownButtonRef) {
                this._dropdownButtonRef = new ElementRef(this.focusable.first.buttonElementPicker());
            }
            return this._dropdownButtonRef;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataviewActionWrapperComponent.prototype, "nativeElement", {
        get: function () {
            return this.dropdownButtonRef.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    return DataviewActionWrapperComponent;
}());
export { DataviewActionWrapperComponent };
