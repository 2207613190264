var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, EntityModelBase, Entity } from '@microsoft/paris';
import { parseEntityName, TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
var EvidenceDistribution = /** @class */ (function (_super) {
    __extends(EvidenceDistribution, _super);
    function EvidenceDistribution() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isInternalPathDistribution = false;
        _this.mainDiskPath = undefined;
        _this.mainDiskPathId = undefined;
        return _this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], EvidenceDistribution.prototype, "diskPath", void 0);
    __decorate([
        EntityField({ parse: parseEntityName }),
        __metadata("design:type", String)
    ], EvidenceDistribution.prototype, "productName", void 0);
    __decorate([
        EntityField({ parse: parseEntityName }),
        __metadata("design:type", String)
    ], EvidenceDistribution.prototype, "vendor", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], EvidenceDistribution.prototype, "productVersion", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], EvidenceDistribution.prototype, "assetCount", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], EvidenceDistribution.prototype, "assetsWithRunningFilesCount", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], EvidenceDistribution.prototype, "assetsWithOpenPortCount", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], EvidenceDistribution.prototype, "recommendationId", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], EvidenceDistribution.prototype, "vulnerabilities", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], EvidenceDistribution.prototype, "cveId", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], EvidenceDistribution.prototype, "hasInternalPaths", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], EvidenceDistribution.prototype, "hasOpenPort", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], EvidenceDistribution.prototype, "hasBeenUsedRecently", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], EvidenceDistribution.prototype, "lastUsedTimestamp", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], EvidenceDistribution.prototype, "pathLastSeenTimestamp", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], EvidenceDistribution.prototype, "pathLastUsedWithOpenPortTimestamp", void 0);
    EvidenceDistribution = __decorate([
        Entity(__assign({}, TvmAnalyticsSharedEntityConfigurations, { singularName: 'Evidence distribution', pluralName: 'Evidence distributions', endpoint: 'evidenceDistribution', readonly: true }))
    ], EvidenceDistribution);
    return EvidenceDistribution;
}(EntityModelBase));
export { EvidenceDistribution };
