var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase, DataQuerySortDirection } from '@microsoft/paris';
import { toPairs, mapKeys, omitBy, isUndefined } from 'lodash-es';
import { Machine } from '../../machine/machine.entity';
export var NO_SID_FALLBACK = 'nosid';
export var USER_LOOKBACK_IN_DAYS = 180;
var mapAccountKeys = function (obj) {
    var legacyIdentifierKeyMap = {
        accountName: 'userAccount',
        accountDomainName: 'userAccountDomain',
    };
    return mapKeys(obj, function (_, key) { return legacyIdentifierKeyMap[key] || key; });
};
var ɵ0 = mapAccountKeys;
var destructJsonIdentifier = function (userId) {
    if (userId[0] !== '{') {
        return null;
    }
    try {
        return mapAccountKeys(JSON.parse(userId));
    }
    catch (e) {
        return null;
    }
};
var ɵ1 = destructJsonIdentifier;
var destructIdentifier = function (userId) {
    var jsonIdentifier = destructJsonIdentifier(userId);
    if (jsonIdentifier) {
        return jsonIdentifier;
    }
    var userParts = userId.split('/');
    if (userParts.length === 1) {
        return null;
    }
    var userAccount = userParts[0], userAccountDomain = userParts[1], sid = userParts[2];
    return { userAccount: userAccount, userAccountDomain: userAccountDomain, sid: sid };
};
var ɵ2 = destructIdentifier;
var constructIdentifier = function (identifier) {
    return identifier.userAccount + "/" + identifier.userAccountDomain + "/" + identifier.sid;
};
var ɵ3 = constructIdentifier;
var ɵ4 = function (dataQuery) {
    var _a = dataQuery || {}, where = _a.where, sortBy = _a.sortBy, pageIndex = _a.page, pageSize = _a.pageSize;
    var keyMap = {
        searchTerm: 'userAccount',
    };
    var query = __assign({ pageIndex: pageIndex,
        pageSize: pageSize }, mapKeys(where, function (_, key) { return keyMap[key] || key; }), (sortBy && sortBy.length
        ? {
            sortByField: sortBy[0].field,
            sortOrder: sortBy[0].direction === DataQuerySortDirection.ascending
                ? 'Ascending'
                : 'Descending',
        }
        : undefined));
    return omitBy(query, isUndefined);
}, ɵ5 = function (userId) {
    var identifier = destructIdentifier(userId);
    // Not a structured identifier. Guessing userId is an SID
    if (!identifier) {
        return "user?sid=" + userId + "&lookingBackIndays=" + USER_LOOKBACK_IN_DAYS;
    }
    var queryParams = toPairs(identifier)
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        return key + "=" + value;
    })
        .join('&');
    return "user?" + queryParams + "&lookingBackIndays=" + USER_LOOKBACK_IN_DAYS;
}, ɵ6 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ7 = ['UserSid', 'AccountSid', 'Sid'], ɵ8 = ['AccountDomain', 'AccountDomainName', 'DomainName'], ɵ9 = ['AccountName', 'UserName'], ɵ10 = function (data) { return ({
    MachineId: data.MostPrevalentSenseMachineId,
    SenseMachineId: data.MostPrevalentSenseMachineId,
    ComputerDnsName: data.MostPrevalentComputerDnsName,
}); }, ɵ11 = function (data) { return ({
    MachineId: data.LeastPrevalentSenseMachineId,
    SenseMachineId: data.LeastPrevalentSenseMachineId,
    ComputerDnsName: data.LeastPrevalentComputerDnsName,
}); };
var LegacyUser = /** @class */ (function (_super) {
    __extends(LegacyUser, _super);
    function LegacyUser() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(LegacyUser.prototype, "id", {
        // override a property with a getter/setter pair isn't allowed
        // @ts-expect-error
        get: function () {
            if (!this._id) {
                this._id = constructIdentifier({
                    sid: this.sid,
                    userAccount: this.accountName,
                    userAccountDomain: this.accountDomainName,
                });
            }
            return this._id;
        },
        set: function (_) {
            /**
             * Ignore setting the ID property, since it doesn't have a single key in the raw data.
             * Paris calls this when constructing the entity, which we need to ignore since this is a computed property in this case.
             **/
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LegacyUser.prototype, "fullName", {
        get: function () {
            return "" + (this.accountDomainName ? this.accountDomainName + '\\' : '') + this.accountName;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: ɵ7 }),
        __metadata("design:type", String)
    ], LegacyUser.prototype, "sid", void 0);
    __decorate([
        EntityField({
            // NOTE: `AccountDomain` is the property name received from the `GET /user` API, `AccountDomainName` is used in some other, older APIs and should be aligned to `AccountDomain` with time.
            data: ɵ8,
        }),
        __metadata("design:type", String)
    ], LegacyUser.prototype, "accountDomainName", void 0);
    __decorate([
        EntityField({ data: ɵ9 }),
        __metadata("design:type", String)
    ], LegacyUser.prototype, "accountName", void 0);
    __decorate([
        EntityField({ data: 'FirstSeen' }),
        __metadata("design:type", Date)
    ], LegacyUser.prototype, "firstSeen", void 0);
    __decorate([
        EntityField({ data: 'LastSeen' }),
        __metadata("design:type", Date)
    ], LegacyUser.prototype, "lastSeen", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ10,
        }),
        __metadata("design:type", Machine)
    ], LegacyUser.prototype, "mostPrevalentMachine", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ11,
        }),
        __metadata("design:type", Machine)
    ], LegacyUser.prototype, "leastPrevalentMachine", void 0);
    __decorate([
        EntityField({ data: 'LoggedOnMachines' }),
        __metadata("design:type", Number)
    ], LegacyUser.prototype, "loggedOnMachinesCount", void 0);
    __decorate([
        EntityField({ data: 'IsDomainAdmin' }),
        __metadata("design:type", Boolean)
    ], LegacyUser.prototype, "isDomainAdmin", void 0);
    __decorate([
        EntityField({ data: 'IsLocalAdmin' }),
        __metadata("design:type", Boolean)
    ], LegacyUser.prototype, "isLocalAdmin", void 0);
    __decorate([
        EntityField({ data: 'IsLocalAdminMostPrevalent' }),
        __metadata("design:type", Boolean)
    ], LegacyUser.prototype, "isLocalAdminMostPrevalent", void 0);
    __decorate([
        EntityField({ data: 'IsLocalAdminLeastPrevalent' }),
        __metadata("design:type", Boolean)
    ], LegacyUser.prototype, "isLocalAdminLeastPrevalent", void 0);
    __decorate([
        EntityField({ data: 'IsOnlyNetworkUser' }),
        __metadata("design:type", Boolean)
    ], LegacyUser.prototype, "isOnlyNetworkUser", void 0);
    LegacyUser = __decorate([
        Entity({
            singularName: 'User',
            pluralName: 'Users',
            endpoint: 'SearchUserAccountName',
            allItemsEndpointTrailingSlash: false,
            parseDataQuery: ɵ4,
            parseItemQuery: ɵ5,
            baseUrl: ɵ6,
            cache: {
                time: 1000 * 60 * 5,
                max: 20,
            },
        })
    ], LegacyUser);
    return LegacyUser;
}(EntityModelBase));
export { LegacyUser };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
