import { FlavorConfig } from '@wcd/scc-common';

export interface ISimulationsAndTutorialsConfig {
	api: FlavorConfig;
	detection: FlavorConfig;
	simulations: FlavorConfig;
	onBoardingLink: FlavorConfig;
}

export const SimulationsAndTutorialsConfig: ISimulationsAndTutorialsConfig = {
	api: {
		mdeFlavors: ['P2'],
	},
	detection: {
		mdeFlavors: ['P2'],
	},
	simulations: {
		mdeFlavors: ['P2'],
	},
	onBoardingLink: {
		mdeFlavors: ['P2', 'Smb'],
	},
};
