import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
import { WebContentFilteringCategoryTypes } from '../../web-content-filtering/web-content-filtering-web-category-type';

@ValueObject({
	singularName: 'Device list report category',
	pluralName: 'Device list report categories',
	readonly: true,
})
export class WebContentFilteringCategory extends ModelBase {
	@EntityField({ data: 'CategoryId' })
	categoryId: WebContentFilteringCategoryTypes;

	@EntityField({ data: 'Count' })
	count: number;
}
