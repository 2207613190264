var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, EntityField, EntityModelBase } from '@microsoft/paris';
import { TreeElementTypeEnum } from './tree-element-relation-type-enum';
import { TreeElementAlertContext } from './tree-element-alert-context';
var TreeElement = /** @class */ (function (_super) {
    __extends(TreeElement, _super);
    function TreeElement() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isRoot = false;
        /* generic fields created by the frontend */
        _this.additionalGenericFields = new Array();
        // Ensure the sub trees components will be loaded only one time when first expanded.
        _this.subTreeNeverLoaded = true;
        // Ensure the details and associated items components will be loaded only one time when first expanded.
        _this.detailsNeverLoaded = true;
        return _this;
    }
    TreeElement_1 = TreeElement;
    Object.defineProperty(TreeElement.prototype, "allGenericFields", {
        get: function () {
            return this.additionalGenericFields.length > 0
                ? this.genericFields.concat(this.additionalGenericFields).filter(function (d) { return !d.isHidden; })
                : this.genericFields.filter(function (d) { return !d.isHidden; });
        },
        enumerable: true,
        configurable: true
    });
    var TreeElement_1;
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], TreeElement.prototype, "id", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], TreeElement.prototype, "cyberEntityId", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], TreeElement.prototype, "treeElementRelationType", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], TreeElement.prototype, "cyberEntityType", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], TreeElement.prototype, "isMinimized", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], TreeElement.prototype, "isSubTreeMinimized", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], TreeElement.prototype, "depth", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], TreeElement.prototype, "hight", void 0);
    __decorate([
        EntityField({ data: 'size' }),
        __metadata("design:type", Number)
    ], TreeElement.prototype, "subTreeSize", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], TreeElement.prototype, "icon", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], TreeElement.prototype, "nonBoldTitlePrefix", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], TreeElement.prototype, "boldTitle", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], TreeElement.prototype, "nonBoldTitleSuffix", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], TreeElement.prototype, "relationToPrecedingElement", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], TreeElement.prototype, "time", void 0);
    __decorate([
        EntityField({ data: 'typedDetails' }),
        __metadata("design:type", Array)
    ], TreeElement.prototype, "genericFields", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Object)
    ], TreeElement.prototype, "hiddenDetails", void 0);
    __decorate([
        EntityField({ arrayOf: TreeElement_1 }),
        __metadata("design:type", Array)
    ], TreeElement.prototype, "children", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", TreeElement)
    ], TreeElement.prototype, "lastChild", void 0);
    __decorate([
        EntityField({ arrayOf: TreeElementAlertContext }),
        __metadata("design:type", Array)
    ], TreeElement.prototype, "associatedAlerts", void 0);
    __decorate([
        EntityField({ arrayOf: TreeElement_1 }),
        __metadata("design:type", Array)
    ], TreeElement.prototype, "associatedItems", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], TreeElement.prototype, "isAssociatedItem", void 0);
    TreeElement = TreeElement_1 = __decorate([
        ValueObject({
            singularName: 'Tree element',
            pluralName: 'Tree elements',
        })
    ], TreeElement);
    return TreeElement;
}(EntityModelBase));
export { TreeElement };
