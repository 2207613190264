import { isEqual, isObject } from 'lodash-es';

/**
 * Testing equality in values, but without private members!
 * @param val1
 * @param val2
 */
export function valuesAreEqual(val1, val2): boolean {
	const equals: boolean = isEqual(val1, val2);
	// since lodash's isEqual compares the objects without inherited properties,
	// only make deep comparison in case it considers the objects equal
	// one exception: don't compare Paris's $parent property
	if (equals) {
		if (isObject(val1) && isObject(val2)) {
			for (const p in val1) {
				if (!val1.hasOwnProperty(p)) return !val2.hasOwnProperty(p);
				else {
					if (!val2.hasOwnProperty(p)) return false;
					if (/^[^_]/.test(p) && !/\$parent/.test(p)) {
						if (!valuesAreEqual(val1[p], val2[p])) return false;
					}
				}
			}
			return true;
		}
	}
	return equals;
}
