import { IStatus } from '../../../status.interface';

export type DomainCategoryId = 1 | 2 | 8 | 32;

export enum DomainCategoryType {
	Phishing = 'Phishing',
	Malicious = 'Malicious',
	CustomBlockList = 'CustomBlockList',
	Unknown = 'Unknown',
}

export interface IDomainCategory extends IStatus<DomainCategoryId, DomainCategoryType> {
	isOpen: boolean;
}
