import { SevilleModule } from '../seville.module';

declare let MscomCustomEvent;
declare let MscomSendPageView;

SevilleModule.factory('analyticsService', analyticsService);

analyticsService.$inject = ['$rootScope', '$location'];

function analyticsService($rootScope, $location) {
	var eventsCount = 0;
	var service = {
		sendEvent: function(name, value) {
			if (typeof MscomCustomEvent != 'undefined') {
				MscomCustomEvent('ms.InteractionType', '2', 'ms.' + name, value);
			}
		},
		sendPageViewEvent: function(route) {
			if (typeof MscomCustomEvent != 'undefined' && eventsCount > 1) {
				MscomSendPageView(0);
			}

			eventsCount++;
		},
	};

	$rootScope.$on('$stateChangeSuccess', function() {
		service.sendPageViewEvent($location.url());
	});

	return service;
}
