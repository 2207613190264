var TabModel = /** @class */ (function () {
    function TabModel(data) {
        this.disabled = false;
        Object.assign(this, data);
    }
    Object.defineProperty(TabModel.prototype, "isVisible", {
        get: function () {
            return this.show ? this.show.call(this) : true;
        },
        enumerable: true,
        configurable: true
    });
    return TabModel;
}());
export { TabModel };
