import { Entity, EntityField } from '@microsoft/paris';
import { airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { AirsFileBase } from './airs-entity.file-base';

@Entity({
	...airsEntityConfig,
	singularName: 'Appended File',
	pluralName: 'Appended Files',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsAppendedFile,
})
export class AirsAppendedFile extends AirsFileBase {
	@EntityField({ data: 'subject' })
	subject: string;
}
