import { Paris } from '@microsoft/paris';
import { Injectable } from '@angular/core';
import { DevicesWithTheSameIps } from '@wcd/domain';

@Injectable()
export class MagellanIsolationStatusService {
	constructor(private readonly paris: Paris) {}

	getDevicesWithSameIps(machineId: string): Promise<string[]> {
		return this.paris
			.getItemById(DevicesWithTheSameIps, machineId)
			.toPromise()
			.then(({ devicesWithSameLastIp }) => devicesWithSameLastIp);
	}
}
