import { Component, EventEmitter, Output } from '@angular/core';

import { NotificationsService } from '../services/notifications.service';

@Component({
	selector: 'notifications',
	templateUrl: './notifications.component.html',
})
export class NotificationsComponent {
	@Output() close: EventEmitter<void> = new EventEmitter<void>();

	constructor(public notificationsService: NotificationsService) {}
}
