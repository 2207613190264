import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
import { AadGroup } from './aad-group.entity';

@ValueObject({
	singularName: 'User Role Assignment',
	pluralName: 'User Role Assignments',
})
export class UserRoleAssignment extends ModelBase {
	@EntityField({ data: 'UserRoleAssignmentId' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({
		data: 'WcdAadGroup',
		serialize: (aadGroup: AadGroup) => {
			return { ObjectId: aadGroup.id, DisplayName: aadGroup.name };
		},
	})
	aadGroup: AadGroup;

	@EntityField({ data: 'LastUpdated' })
	lastUpdated: Date;
}
