import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Software, SoftwareInstallation } from '@wcd/domain';
import { Repository, Paris } from '@microsoft/paris';

@Component({
	selector: 'software-inventory-entity-panel',
	templateUrl: './software-inventory.entity-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftwareInventoryEntityPanelComponent extends EntityPanelComponentBase<Software> {
	softwareRepository: Repository<Software>;
	software: Software;
	softwareInstallation?: SoftwareInstallation;

	constructor(changeDetectorRef: ChangeDetectorRef, paris: Paris) {
		super(changeDetectorRef);
		this.softwareRepository = paris.getRepository(Software);
	}

	initEntity(software: Software, isExtendedData: boolean = false) {
		super.initEntity(software, isExtendedData);
		this.changeDetectorRef.markForCheck();

		if (this.entity instanceof Software) {
			this.software = this.entity;
		} else {
			this.fetchSoftwaredata(this.entity);
		}
	}

	fetchSoftwaredata(softwareInstallation: SoftwareInstallation): void {
		this.softwareInstallation = softwareInstallation;

		if (!softwareInstallation.isNormalized) {
			// If no cpe exist for this software Installation, we display only vendor name
			this.software = new Software({
				id: softwareInstallation.id,
				name: softwareInstallation.name,
				vendor: softwareInstallation.vendor,
				isNormalized: false,
				threatInfo: { hasExploit: false },
				exposureScoreImprovement: 0,
			});
		} else {
			const softwareId = softwareInstallation.getProductId();
			this.softwareRepository.getItemById(softwareId).subscribe(software => {
				this.software = software;
				this.changeDetectorRef.markForCheck();
			});
		}
	}
}
