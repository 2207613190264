export enum GenericFieldType {
	String = 'String',
	DateTime = 'DateTime',
	CopyPastableString = 'CopyPastableString',
	VirusTotalFileReport = 'VirusTotalFileReport',
	Signer = 'Signer',
	ContactDetails = 'ContactDetails',
	LinkableString = 'LinkableString',
	MitreTechniques = 'MitreTechniques',
	CyberEntity = 'CyberEntity',
	Script = 'Script'
}
