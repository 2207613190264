
		<ul *ngIf="contactDetails" class="unstyled wcd-margin-none-all wcd-padding-none-all">
			<li *ngIf="contactDetails.email as email">
				<fab-icon iconName="Mail"></fab-icon>
				<a
					data-track-id="ContactDetails__Email"
					data-track-type="ExternalLink"
					[href]="'mailto:' + email"
				>
					{{ email }}
				</a>
			</li>

			<li *ngIf="contactDetails.phone as phone">
				<fab-icon iconName="Phone"></fab-icon>
				<a
					data-track-id="ContactDetails__Phone"
					data-track-type="ExternalLink"
					[href]="'tel:' + phone"
				>
					{{ phone }}
				</a>
			</li>

			<li *ngIf="skypeUrl">
				<fab-icon iconName="SkypeForBusinessLogo"></fab-icon>
				<a data-track-id="ContactDetails__Skype" data-track-type="ExternalLink" [href]="skypeUrl">
					{{ skypeUrlText }}
				</a>
			</li>
		</ul>
	