var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { mapValues } from 'lodash-es';
import { filter, map } from 'rxjs/operators';
import { config } from '@wcd/shared';
import { ServiceUrlsService } from '@wcd/app-config';
import { LocaleConfigService, TzDateService } from '@wcd/localization';
import { Dictionary } from '@wcd/config';
import { Prettify } from '../../../../../utils/services/prettify.service';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { I18nService } from '@wcd/i18n';
var NAME_COLOR_MAPPING = {
    SmartScreens: {
        className: 'ms-border-tealLight',
        color: '#00A5B0',
        colorName: 'tealLight',
    },
    ExploitGuard: {
        className: 'ms-border-blueMid',
        color: '#00188F',
        colorName: 'blueMid',
    },
    Firewall: {
        className: 'ms-color-purpleLight',
        color: '#917EDB',
        colorName: 'purpleLight',
    },
    AntiVirus: {
        className: 'color-border-primary',
        color: '#0078d4',
        colorName: 'themePrimary',
    },
    DeviceGuard: {
        className: 'ms-border-magentaDark',
        color: '#4B003F',
        colorName: 'magentaDark',
    },
};
var SuspiciousActivitiesWidget = /** @class */ (function (_super) {
    __extends(SuspiciousActivitiesWidget, _super);
    function SuspiciousActivitiesWidget(reportsService, serviceUrlsService, tzDateService, prettyNumberService, localeConfigService, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.serviceUrlsService = serviceUrlsService;
        _this.tzDateService = tzDateService;
        _this.prettyNumberService = prettyNumberService;
        _this.localeConfigService = localeConfigService;
        _this.i18nService = i18nService;
        _this.Prettify = Prettify;
        _this.activitiesBySourceData$ = _this.data$.pipe(map(function (data) {
            return data && data.activitiesBySource;
        }));
        _this.activitiesBySourceDataLegendItems$ = _this.activitiesBySourceData$.pipe(filter(Boolean), map(function (data) {
            return data.map(function (chartItem) { return ({
                name: chartItem.displayName || chartItem.name,
                value: chartItem.value,
                iconColorName: chartItem.colorName,
            }); });
        }));
        _this.machinesOverTimeData$ = _this.data$.pipe(map(function (data) {
            return data && data.machinesOverTime;
        }));
        _this.activitiesBySourceTitle$ = _this.activitiesBySourceData$.pipe(map(function (data) {
            return _this.prettyNumberService.prettyNumber((data || []).reduce(function (res, item) { return res + (item.machineCount || 0); }, 0));
        }));
        _this.activitiesBySourceSettings = {
            options: {
                data: {
                    colors: mapValues(NAME_COLOR_MAPPING, function (value) { return value.color; }),
                },
                tooltip: {
                    contents: function (data) {
                        var item = data && data.length && _this.suspiciousActivityDataMapping.get(data[0].name);
                        return item
                            ? "<table class=\"c3-tooltip\">\n\t\t\t\t\t\t\t\t<tbody>\n\t\t\t\t\t\t\t\t\t<tr class=\"c3-tooltip-name--" + item.name + "\">\n\t\t\t\t\t\t\t\t\t\t<td class=\"name\">" + _this.prettyNumberService.prettyNumber(item.value || 0) + "\n\t\t\t\t\t\t\t\t\t\t\t" + item.displayName + " " + _this.i18nService.get('machine_entityType_pluralName') + "</br>\n\t\t\t\t\t\t\t\t\t\t\t" + _this.prettyNumberService.prettyNumber(item.machineCount || 0) + "\n\t\t\t\t\t\t\t\t\t\t\t" + _this.i18nService.get('machine_entityType_pluralName') + "\n\t\t\t\t\t\t\t\t\t\t</td>\n\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t</tbody>\n\t\t\t\t\t\t\t</table>"
                            : '';
                    },
                },
                donut: {
                    title: _this.i18nService.get('suspiciousActivities_widget_Activities_by_source'),
                    width: 16,
                },
                legend: {
                    show: false,
                },
                size: {
                    height: 170,
                    width: 200,
                },
            },
        };
        _this.machineOverTimeSettings = {
            useValues: false,
            setColors: true,
            series: [{ value: 'count', name: _this.i18nService.get('machine_entityType_pluralName'), color: config.color.primary }],
            options: {
                data: {},
                axis: {
                    x: {
                        tick: {
                            format: function (value) { return _this.tzDateService.format(value, 'MM/dd'); },
                            count: 4,
                        },
                    },
                    y: {
                        tick: {
                            format: function (value) { return _this.prettyNumberService.prettyNumber(value); },
                            count: 2,
                        },
                    },
                },
                tooltip: {
                    format: {
                        title: function (value) { return _this.tzDateService.format(value, 'MM/dd'); },
                    },
                },
                size: {
                    height: 170,
                    width: 350,
                },
                legend: {
                    show: false,
                },
                point: { show: false },
            },
        };
        return _this;
    }
    Object.defineProperty(SuspiciousActivitiesWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            var rangeInDays = 30;
            return {
                id: 'suspiciousActivities',
                name: this.i18nService.get('widget_title_suspiciousActivities'),
                rangeInDays: rangeInDays,
                api: {
                    url: function () { return _this.serviceUrlsService.threatIntel + "/Dashboard/suspicious_activities"; },
                    isExternal: true,
                },
                parseData: this.parseData.bind(this),
            };
        },
        enumerable: true,
        configurable: true
    });
    SuspiciousActivitiesWidget.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.activitiesBySourceData$.subscribe(function (data) {
            _this.suspiciousActivityDataMapping = Dictionary.fromList(data, 'name');
        });
    };
    SuspiciousActivitiesWidget.prototype.parseData = function (data) {
        return {
            activitiesBySource: data && data.sources
                ? data.sources.map(function (item) {
                    var itemColorData = NAME_COLOR_MAPPING[item.id];
                    return {
                        name: item.id,
                        value: item.activity_count,
                        machineCount: item.machine_count,
                        displayName: item.name,
                        className: itemColorData && itemColorData.className,
                        color: itemColorData && itemColorData.color,
                        colorName: itemColorData && itemColorData.colorName,
                    };
                })
                : [],
            machinesOverTime: data && data.machines_over_time
                ? ReportsService.parseTimeSeriesData(data.machines_over_time)
                : [],
        };
    };
    return SuspiciousActivitiesWidget;
}(ReportWidgetComponent));
export { SuspiciousActivitiesWidget };
