/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/shared/src/lib/components/info-box.component.ngfactory";
import * as i2 from "../../../../../../../projects/shared/src/lib/components/info-box.component";
import * as i3 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "./machine.adiot-up-sell-message.component";
import * as i7 from "../../../../../../../projects/config/src/lib/services/app-flavor.service";
import * as i8 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i9 from "../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../insights/services/app-insights.service";
import * as i12 from "../../../../../../../projects/auth/src/lib/services/auth.service";
var styles_AdIotUpSellMessageComponent = [];
var RenderType_AdIotUpSellMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdIotUpSellMessageComponent, data: {} });
export { RenderType_AdIotUpSellMessageComponent as RenderType_AdIotUpSellMessageComponent };
function View_AdIotUpSellMessageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "div", [["class", "wcd-margin-medium-bottom message-bar"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 13, "wcd-info-box", [], null, null, null, i1.View_InfoBoxComponent_0, i1.RenderType_InfoBoxComponent)), i0.ɵdid(5, 49152, null, 0, i2.InfoBoxComponent, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, 0, 9, "div", [["class", "wcd-font-size-s wcd-margin-none-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵppd(11, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAdIotUpsellSidePanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(14, null, ["", ""])), i0.ɵppd(15, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 0), "DevicePaneAdIotUpsellMessage")); _ck(_v, 10, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i0.ɵnov(_v.parent, 0), "DevicePaneAdIotUpsellMessageLinkText")); _ck(_v, 14, 0, currVal_1); }); }
export function View_AdIotUpSellMessageComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdIotUpSellMessageComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldDisplayAdIotUpsellMessage; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AdIotUpSellMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ad-iot-up-sell-message", [], null, null, null, View_AdIotUpSellMessageComponent_0, RenderType_AdIotUpSellMessageComponent)), i0.ɵdid(1, 573440, null, 0, i6.AdIotUpSellMessageComponent, [i7.FlavorService, i8.FeaturesService, i9.AppConfigService, i10.Router, i11.AppInsightsService, i12.AuthService], null, null)], null, null); }
var AdIotUpSellMessageComponentNgFactory = i0.ɵccf("ad-iot-up-sell-message", i6.AdIotUpSellMessageComponent, View_AdIotUpSellMessageComponent_Host_0, { device: "device" }, {}, []);
export { AdIotUpSellMessageComponentNgFactory as AdIotUpSellMessageComponentNgFactory };
