import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DomainUrl, Url } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';

@Injectable()
export class DomainUrlFields {
	private _fields: Array<DataviewField>;

	constructor(
		private i18nService: I18nService,
		private globalEntityTypesService: GlobalEntityTypesService
	) {}

	get fields(): Array<DataviewField> {
		if (!this._fields) {
			this._fields = DataviewField.fromList([
				{
					id: 'title',
					name: this.i18nService.get('reporting.webThreatReport.domainUrlList.urlHeader'),
					getLink: (report: DomainUrl) =>
						this.globalEntityTypesService.getEntityLink(Url, <Partial<Url>>{ id: report.url }),
					getTooltip: (report: DomainUrl) => report.url,
					getDisplay: (report: DomainUrl) => report.url,
					className: 'nowrap wcd-text-overflow-medium-large',
				},
				{
					id: 'accessCount',
					name: this.i18nService.get('reporting.webThreatReport.domainUrlList.accessCountHeader'),
					className: 'nowrap',
					sort: {
						sortDescendingByDefault: true,
						getLocalSortValue: 'accessCount',
					},
				},
			]);
		}

		return this._fields;
	}
}
