export const directoryTypes = [
	{
		id: 1,
		type: 'temporary',
		name: 'Temporary',
	},
	{
		id: 2,
		type: 'download',
		name: 'Download',
	},
	{
		id: 3,
		type: 'system',
		name: 'System',
	},
	{
		id: 4,
		type: 'applications',
		name: 'Applications',
	},
	{
		id: 5,
		type: 'users',
		name: 'Users',
	},
	{
		id: 6,
		type: 'miscellaneous',
		name: 'Miscellaneous',
	},
];
