import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TvmColorService, TvmColor } from '../../services/tvm-color.service';
import { ChartSettings } from '@wcd/charts';
import { TooltipOptions, YAxisConfiguration, Data } from 'c3';
import { TzDateService } from '@wcd/localization';
import { PrettyNumberService } from '@wcd/prettify';
import { I18nService } from '@wcd/i18n';

declare const moment: typeof import('moment');

export interface TvmHistoryTrendOptions {
	data: number[];

	// If null, no title will be shown
	title?: string;

	legend?: string;

	area?: boolean;
	graphColor: GraphColor;
	toolTipOptions?: TooltipOptions;
	xAxisValues: XAxisValues;
	yAxisValues: YAxisValues;
	showYGrid?: boolean;
	width?: number;
	height: number;
	advancement: Advancement;
	showNumberOfDays?: boolean;
}

export interface GraphColor {
	color?: TvmColor;
	byImprovement?: boolean;
}

export interface YAxisValues {
	show: boolean;
	max?: number;
	min?: number;
	autoDetect?: boolean;
	count?: number;
	tickValues?: number[];
}

export interface XAxisValues {
	show: boolean;
	count?: number;
}

export interface Advancement {
	show: boolean;
	byImprovement?: boolean;
}

@Component({
	selector: 'tvm-history-trend',
	templateUrl: './tvm-history-trend.component.html',
	styleUrls: ['./tvm-history-trend.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvmHistoryTrendComponent {
	@Input()
	set options(tvmHistoryTrendOptions: TvmHistoryTrendOptions) {
		// Include only up to 30 days of history data
		// TODO: evhvoste - temp solution for rebranding - will be removed with sca - events implementation - VSTS task 25356461
		this._isSecureScore =
			tvmHistoryTrendOptions.title ===
			this.i18nService.get('tvm.secureScoreWidget.secureScoreOverTime');
		this._historyData = tvmHistoryTrendOptions.data.slice(0, 30);

		this._tvmHistoryTrendOptions = tvmHistoryTrendOptions;

		this.setTrend();
		this.setTimeSeriesChartSettings();
		this.setAdvancement();
	}

	_historyData: number[];
	_trend: HistoryTrendPoint[];
	_trendSettings: ChartSettings;
	_tvmHistoryTrendOptions: TvmHistoryTrendOptions;
	_numberOfDays: number;
	_advancement: AdvancementMetadata;
	private _isSecureScore: boolean;

	constructor(
		private i18nService: I18nService,
		private tvmColorService: TvmColorService,
		private tzDateService: TzDateService,
		private prettyNumberService: PrettyNumberService
	) {}

	private setAdvancement(): void {
		if (!this._tvmHistoryTrendOptions.advancement.show) {
			return;
		}

		const advancement = this.getAdvancement();
		const graphColorClass = this.tvmColorService.fontColorsClassMap.get(
			this._tvmHistoryTrendOptions.graphColor.color
		);

		if (advancement < 0) {
			this._advancement = {
				advancement: this.prettyNumberService.prettyNumber(advancement * -1),
				iconName: 'StockDown',
				contentClass: this._tvmHistoryTrendOptions.advancement.byImprovement
					? this.tvmColorService.fontColorsClassMap.get(TvmColor.Positive)
					: graphColorClass,
			};
		} else {
			this._advancement = {
				advancement: this.prettyNumberService.prettyNumber(advancement),
				iconName: 'StockUp',
				contentClass: this._tvmHistoryTrendOptions.advancement.byImprovement
					? this.tvmColorService.fontColorsClassMap.get(TvmColor.HighRisk)
					: graphColorClass,
			};
		}
	}

	private setTrend(): void {
		if (!this._tvmHistoryTrendOptions) {
			this._trend = null;
			return;
		}

		this._trend = this._historyData.map(
			(value, index): HistoryTrendPoint => {
				return {
					value: value,
					date: moment() // Local date
						.utc()
						.subtract({ days: index })
						.startOf('day')
						.toDate(),
				};
			}
		);

		this._numberOfDays = this._trend.length;
	}

	private getAdvancement(): number {
		const historyDays = this._trend.length;
		return historyDays > 0 ? Math.round(this._trend[0].value - this._trend[historyDays - 1].value) : 0;
	}

	private getGraphColor(): string {
		if (!this._tvmHistoryTrendOptions.graphColor.byImprovement) {
			return this.tvmColorService.colorsMap.get(this._tvmHistoryTrendOptions.graphColor.color).raw;
		}

		const historyImprovement = this.getAdvancement();

		return historyImprovement > 0
			? this.tvmColorService.colorsMap.get(TvmColor.HighRisk).raw
			: this.tvmColorService.colorsMap.get(TvmColor.Positive).raw;
	}

	/**
	 * Creates Y Axis based on max / min and
	 * In case of undefined max/min
	 * 1. We defined max and min, closer to the trend, but not exactly on it, so it looks good
	 *
	 */
	private getYAxisConfig(): YAxisConfiguration {
		const yAxisValues = this._tvmHistoryTrendOptions.yAxisValues;

		if (!yAxisValues.show) {
			return { show: false };
		}

		let max: number;
		let min: number;
		if (yAxisValues.autoDetect) {
			const maxValue = Math.max(...this._historyData) + 1;
			const minValue = Math.min(...this._historyData);

			// To make sure the graph looks good
			// We create Max and Min that the trend doesn't touch
			// Example Max value 1500 -> 100 *5
			// Then we take the 500 and found nearest for 1500 -> New Max = 2000
			// For min value 800 we found nearest 500 -> New min = 500
			const pow = maxValue.toString().length - 2;
			const closerRounding = Math.pow(10, pow > 0 ? pow : 0) * 5;

			max = Math.ceil(maxValue / closerRounding) * closerRounding;
			min = Math.floor(minValue / closerRounding) * closerRounding;
		} else {
			(max = yAxisValues.max), (min = yAxisValues.min);
		}

		let yAxis: number[] = [];
		if (yAxisValues.tickValues) {
			yAxis = yAxisValues.tickValues;
		} else {
			for (let i = 0; i < yAxisValues.count; i++) {
				yAxis.push(min + ((max - min) * i) / (yAxisValues.count - 1));
			}
		}

		const axisValuesSuffix = this._isSecureScore ? '%' : '';

		return {
			max: max,
			min: min,
			show: true,
			tick: {
				format: value => Math.round(value).toString() + axisValuesSuffix,
				values: yAxis,
				count: yAxis.length,
			},
		};
	}

	private getToolTipOptions(): TooltipOptions {
		return (
			this._tvmHistoryTrendOptions.toolTipOptions || {
				format: {
					title: value => this.tzDateService.format(value, 'MM/dd'),
				},
			}
		);
	}

	private getDataConfig(): Data {
		if (this._tvmHistoryTrendOptions.area) {
			return {
				types: {
					value: 'area',
				},
				classes: {
					value: 'tvm-area-trend', // Set our own css for data line - c3-target-tvm (the prefix is added by the lib)
				},
			};
		}

		return {};
	}

	private setTimeSeriesChartSettings(): void {
		if (!this._trend || this._trend.length === 0) {
			this._trendSettings = null;
			return;
		}

		this._trendSettings = {
			useValues: false,
			xProperty: 'date',
			setColors: true,
			series: [
				{
					value: 'value',
					name: this._tvmHistoryTrendOptions.legend,
					color: this.getGraphColor(),
				},
			],
			options: {
				transition: {
					duration: 0,
				},
				data: this.getDataConfig(),
				axis: {
					x: {
						tick: {
							format: value => this.tzDateService.format(value, 'MM/dd'),
							count: this._tvmHistoryTrendOptions.xAxisValues.count,
						},
						show: this._tvmHistoryTrendOptions.xAxisValues.show,
					},
					y: this.getYAxisConfig(),
				},
				legend: {
					show: false,
				},
				size: {
					width: this._tvmHistoryTrendOptions.width,
					height: this._tvmHistoryTrendOptions.height,
				},
				grid: {
					y: {
						show: this._tvmHistoryTrendOptions.showYGrid,
					},
					x: {
						show: false,
					},
				},
				point: {
					show: false,
				},
				tooltip: this.getToolTipOptions(),
			},
		};
	}
}

interface HistoryTrendPoint {
	value: number;
	date: Date;
}

interface AdvancementMetadata {
	advancement: string;
	iconName: string;
	contentClass: string;
}
