import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { DataSensitivity } from '@wcd/domain';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { Tag } from '@wcd/domain';
import { TagsService } from '../../../tags/services/tags.service';
import { I18nService } from '@wcd/i18n';
import { lowerFirst } from 'lodash-es';

type TagInput = Tag | DataSensitivity | string;

@Component({
	selector: 'tag',
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnChanges<TagInput> {
	@Input() className?: string;
	@Input() tag?: TagInput;
	@Input() removable: boolean = false;
	@Output() readonly removeClick: EventEmitter<Tag> = new EventEmitter<Tag>();

	computedTag: Tag;
	constructor(private readonly tagsService: TagsService, private i18nService: I18nService) {}

	ngOnChanges(_changes: TypedChanges<TagInput>) {
		if (!this.tag) {
			this.computedTag = null;
			return;
		}
		if (typeof this.tag === 'string') {
			this.computedTag = {
				id: this.tag,
				name: this.tag,
				className: this.className || '',
			};
		} else {
			this.computedTag = this.convertToTag(this.tag);
		}
	}

	removeButtonClick() {
		this.removeClick.emit(this.computedTag);
	}

	getClass() {
		if (this.computedTag.className) {
			return this.computedTag.className;
		}

		const type = this.computedTag.type ? this.computedTag.type : 'default';

		return `tag-color-box-${type}`;
	}

	private convertToTag(tag: Tag | DataSensitivity): Tag {
		if (this.isTag(tag)) {
			return new Tag({
				...tag,
				name:
					tag.name || !tag.nameKey
						? tag.name
						: this.i18nService.get(`tags.localized.${lowerFirst(tag.nameKey)}`),
			});
		}

		return this.tagsService.getDataSensitivityTag(tag, tag.label, false);
	}

	private isTag(tag: Tag | DataSensitivity): tag is Tag {
		return Boolean((<Tag>tag).name || (<Tag>tag).nameKey);
	}
}
