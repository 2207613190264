import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {ConvergencePromotionBannerComponent} from "./components/convergence-promotion-banner.component";
import {ConvergencePromotionToolbarComponent} from "./components/convergence-promotion-toolbar.component";
import {ConvergencePromotionWidgetComponent} from "./components/convergence-promotion-widget.component";
import {ConvergencePromotionService} from "./services/convergence-promotion.service";

@NgModule({
	imports: [SharedModule] ,
	declarations: [ConvergencePromotionBannerComponent,ConvergencePromotionToolbarComponent,ConvergencePromotionWidgetComponent],
	exports: [ConvergencePromotionBannerComponent,ConvergencePromotionToolbarComponent,ConvergencePromotionWidgetComponent],
	providers: [ConvergencePromotionService],
})
export class ConvergencePromotionModule {}
