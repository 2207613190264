
		<div class="wcd-full-height wcd-scroll-vertical">
			<div class="wcd-padding-large-all wcd-border-bottom">
				<h3 class="wcd-margin-bottom">Language</h3>
				<p>
					You can override MDE pages' language on this browser. Select a language and refresh the
					page. Make sure that the 'I18n language change' flag is enabled.
				</p>
				<p>
					You're on {{ isScc ? 'SCC' : 'MDE' }} portal. Default is: '<strong>{{
						defaultPortalLanguage
					}}</strong
					>'
				</p>
				<p>
					<strong *ngIf="isScc"
						>Please use the Office-shell settings panel from the main header in order to imitate
						the user's language change.</strong
					>
				</p>
				<wcd-select
					[values]="languages"
					(ngModelChange)="onLanguageChange($event)"
					[(ngModel)]="selectedLanguage"
					[placeholder]="'Select...'"
					class="wcd-margin-top"
				></wcd-select>
				<div class="wcd-margin-top">
					<button (click)="refresh()" class="btn btn-primary">Refresh</button>
					<button
						(click)="resetLanguage()"
						class="btn btn-secondary wcd-margin-left"
						wcdTooltip="Resets language pick. After a refresh, the language will be taken from default or Office-shell settings panel."
					>
						Reset language selection
					</button>
				</div>
			</div>
			<div class="wcd-padding-large-all">
				<h3>{{ i18n.strings.flags_title }}</h3>
				<p class="page-subtitle wcd-margin-small-top">{{ 'flags_description' | i18n }}</p>
			</div>
			<div class="wcd-padding-large-horizontal wcd-margin-large-bottom">
				<input
					type="text"
					(keyup)="filter$.next($event.target.value)"
					placeholder="Filter flags..."
					class="wcd-width-medium search"
				/>
			</div>
			<div class="wcd-padding-large-horizontal wcd-padding-large-bottom">
				<table class="key-value-list with-light-borders">
					<tbody>
						<tr *ngFor="let flag of (filteredFlags$ | async)">
							<td class="key wcd-padding-small-top">
								<strong [id]="flag.id">{{ flag.name }}</strong>
								<markdown [data]="'flags.flag.' + flag.id | i18n: {}:true"></markdown>
							</td>
							<td class="value">
								<fancy-select
									[name]="'flag-' + flag.id"
									[(ngModel)]="flag.state"
									label="name"
									[formatLabel]="getFormatLabel(flag)"
									[isBordered]="true"
									[labelClass]="
										(flag.state.value
											? 'color-box-success'
											: flag.state.value === false
											? 'color-box-error'
											: '') + ' dropdown-width-small-medium'
									"
									(ngModelChange)="setFlag(flag, $event)"
									[values]="flagValues"
								></fancy-select>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	