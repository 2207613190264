var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, EntityModelBase, ValueObject } from '@microsoft/paris';
import { EvidenceDetectionSourceType } from './evidence-detection-source-type.entity';
import { InvestigationActionStatus } from '../investigation/actions/investigation-action-status.entity';
import { Severity } from '../severity/severity.entity';
var EvidenceDetectionSource = /** @class */ (function (_super) {
    __extends(EvidenceDetectionSource, _super);
    function EvidenceDetectionSource() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'detection_source_name_key' }),
        __metadata("design:type", String)
    ], EvidenceDetectionSource.prototype, "detectionNameI18nKey", void 0);
    __decorate([
        EntityField({ data: 'detection_source_name' }),
        __metadata("design:type", String)
    ], EvidenceDetectionSource.prototype, "detectionName", void 0);
    __decorate([
        EntityField({ data: 'detection_source_id' }),
        __metadata("design:type", String)
    ], EvidenceDetectionSource.prototype, "detectionId", void 0);
    __decorate([
        EntityField({ data: 'detection_type' }),
        __metadata("design:type", EvidenceDetectionSourceType)
    ], EvidenceDetectionSource.prototype, "detectionType", void 0);
    __decorate([
        EntityField({ data: 'additional_data.action.status' }),
        __metadata("design:type", InvestigationActionStatus)
    ], EvidenceDetectionSource.prototype, "actionStatus", void 0);
    __decorate([
        EntityField({ data: 'additional_data.alert.severity' }),
        __metadata("design:type", Severity)
    ], EvidenceDetectionSource.prototype, "alertSeverity", void 0);
    EvidenceDetectionSource = __decorate([
        ValueObject({
            singularName: 'Detection Source',
            pluralName: 'Detection Sources',
        })
    ], EvidenceDetectionSource);
    return EvidenceDetectionSource;
}(EntityModelBase));
export { EvidenceDetectionSource };
