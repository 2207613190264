import { SevilleModule } from '../../seville/seville.module';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { breadcrumbsStateService } from '@wcd/shared';
import { FilesService } from '../../../../@entities/files/services/files.service';

SevilleModule.controller('seville.threatintel.searchfiles', searchfilesController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('search.files', {
			url: '/files/:id',
			data: { results: null },
			title: 'Search results - Files',
			template: `
				<div ng-if="!searchfiles.UpgradeSearchFiles ">
					<div class="loading-layer search-loading" ng-if="searchfiles.loading">
						<img src="/assets/images/circle-loading.gif" />
						<div class="loading-from-archive" ng-if="searchfiles.inColdStorage">
							<div>Retrieving data from archive</div>
							<div>This action may take a few minutes</div>
							<div id="CancelSearch">
								<a ng-click="searchfiles.cancelSearch()"
									data-track-id="Cancel"
									data-track-type="Action"
									ng-if="searchfiles.bestMatchData || searchfiles.otherMatchData">Cancel</a>
							</div>
						</div>
					</div>
					<div class="row seville-page-header file-search-header">
						<div class="col-sm-12">
							<div ng-if="searchfiles.data.length < 100" class="row page-header-title">
								Showing {{searchfiles.data.length}} results for <b>"{{searchfiles.fileName}}"</b>
							</div>
							<div ng-if="searchfiles.data.length >= 100" class="row page-header-title">
								Showing top 100 results for <b>"{{searchfiles.fileName}}"</b>
							</div>
						</div>
					</div>
					<div ng-class=":: {'entity-toolbox entity-toolbar':searchfiles.exportToCsvEnabled, 'row seville-page-header alerts-filters text-right':!searchfiles.exportToCsvEnabled}"
						 data-track-component="FileSearchResults"
						ng-if="!searchfiles.loading">
						<div class="dropdown inline " uib-dropdown>
							<span ng-if="::searchfiles.exportToCsvEnabled">
								<b>Refine your results by:</b>
								<span class="machines-filter-padding">
									Time:
								</span>
							</span>
							<span ng-if="::!searchfiles.exportToCsvEnabled">Filter by: </span>
							<button class="btn btn-link dropdown-toggle alerts-btn" uib-dropdown-toggle type="button" data-toggle="dropdown" aria-expanded="true" ng-disabled="searchfiles.loading">
								<span>
										<span ng-if="searchfiles.telemetryLookback == 180">6 months</span>
										<span ng-if="searchfiles.telemetryLookback == 1">1 day</span>
										<span ng-if="searchfiles.telemetryLookback != 180 && searchfiles.telemetryLookback > 1">
											{{searchfiles.telemetryLookback}} days
										</span>
									</span>
								<span class="icon icon-sm icon-ChevronDown"></span>
							</button>
							<ul class="dropdown-menu dropdown-menu-shadow dropdown-margin pull-right">
								<li ng-class="{'dropdown-selected' : searchfiles.telemetryLookback == '1'}"><a href="#"
									data-track-id="TelemetryLookback1d"
									data-track-type="Selection"
									ng-model="searchfiles.telemetryLookback" uib-btn-radio="'1'">1 day</a></li>
								<li ng-class="{'dropdown-selected' : searchfiles.telemetryLookback == '3'}"><a href="#"
									data-track-id="TelemetryLookback3d"
									data-track-type="Selection"
									ng-model="searchfiles.telemetryLookback" uib-btn-radio="'3'">3 days</a></li>
								<li ng-class="{'dropdown-selected' : searchfiles.telemetryLookback == '7'}"><a href="#"
									data-track-id="TelemetryLookback7d"
									data-track-type="Selection"
									ng-model="searchfiles.telemetryLookback" uib-btn-radio="'7'">7 days</a></li>
								<li ng-class="{'dropdown-selected' : searchfiles.telemetryLookback == '30'}"><a href="#"
									data-track-id="TelemetryLookback30d"
									data-track-type="Selection"
									ng-model="searchfiles.telemetryLookback" uib-btn-radio="'30'">30 days</a></li>
								<li ng-class="{'dropdown-selected' : searchfiles.telemetryLookback == '180'}"><a href="#"
									data-track-id="TelemetryLookback180d"
									data-track-type="Selection"
									ng-model="searchfiles.telemetryLookback" uib-btn-radio="'180'">6 months</a></li>
							</ul>
						</div>
						<button ng-if="::searchfiles.exportToCsvEnabled" ng-click="searchfiles.exportResultsToCsv()" class="entity-toolbox-button entity-toolbar-button pull-right">
							<i class="icon icon-ExcelLogo export-to-csv-excel-logo"></i> Export
						</button>
					</div>

					<div class="row table-header event-timeline-columnsheader" ng-if="searchfiles.data && searchfiles.data.length>0">
						<div class="col-md-3">Filename</div>
						<div class="col-md-3">Last Seen <span class="icon machine-queue-header-icon icon-SortDown"></span></div>
						<div class="col-md-3">First Seen</div>
						<div class="col-md-3">Details</div>
					</div>

					<file-search-results results="searchfiles.bestMatchData" match-title="'Exact Matches'" loading="searchfiles.loading"></file-search-results>
					<file-search-results results="searchfiles.otherMatchData" match-title="'Other Matches'" loading="searchfiles.loading"></file-search-results>
				</div>
				`,
			controller: 'seville.threatintel.searchfiles',
			controllerAs: 'searchfiles',
		});
	},
]);

searchfilesController.$inject = [
	'$stateParams',
	'$state',
	'$http',
	'urlMapping',
	'investigationService',
	'$scope',
	'coldStorage',
	'$q',
	'demoTenantResolverService',
	'featuresService',
	'exportToCsvService',
	'appInsights',
	'ng2router',
	'filesService',
];

function searchfilesController(
	$stateParams,
	$state,
	$http,
	urlMapping,
	investigation,
	$scope,
	coldStorage,
	$q,
	demoTenantResolverService,
	featuresService,
	exportToCsvService,
	appInsights: AppInsightsService,
	ng2router,
	filesService: FilesService
) {
	var vm = this;
	if (!$stateParams.id) {
		ng2router.navigate(['/errors']);
		return;
	}

	vm.resolveLookback = function() {
		if (demoTenantResolverService.isDemoTenant()) return demoTenantResolverService.demoTenantLookback;
		else return 30;
	};

	vm.telemetryLookback = vm.resolveLookback();
	vm.fileName = $stateParams.id;
	vm.loading = false;
	vm.inColdStorage = false;
	vm.searchCanceler = null;
	vm.resultsLookback = vm.telemetryLookback;
	vm.searchCanceled = false;
	vm.exportToCsvEnabled = featuresService.isEnabled('ExportEntityTimelinesToCsv');
	vm.UpgradeSearchFiles = featuresService.isEnabled('UpgradeSearchFiles');

	$scope.$watch(
		function() {
			return vm.telemetryLookback;
		},
		function() {
			if (vm.loading || vm.searchCanceled) {
				vm.searchCanceled = false;
				return;
			}

			vm.inColdStorage = coldStorage.isLookbackDaysInColdStorage(vm.telemetryLookback);
			loadTopFileNames();
		}
	);

	vm.cancelSearch = function() {
		if (vm.searchCanceler != null) {
			vm.searchCanceler.resolve();
			vm.telemetryLookback = vm.resultsLookback;
			vm.searchCanceled = true;
			vm.loading = false;
		}
	};

	vm.exportResultsToCsv = function() {
		if (!vm.exportInProgress) {
			vm.exportInProgress = true;
			appInsights.trackEvent('UsageTrack', {
				ButtonType: 'ExportToCsv',
				Page: 'File',
				Component: 'FileSearchExportToCsv',
			});

			exportToCsvService
				.exportResults({
					params: {
						searchTerm: vm.fileName,
						lookingBackInDays: vm.telemetryLookback,
					},
					getAuthTokenUrl: '/GetAuthToken?exportUrl=DownloadFileSearchResults',
					downloadUrl: '/DownloadFileSearchResults',
				})
				.finally(function() {
					vm.exportInProgress = false;
				});
		}
	};

	function loadTopFileNames() {
		if (vm.UpgradeSearchFiles) {
			return;
		}

		vm.loading = true;
		vm.searchCanceled = false;
		var url = urlMapping.getThreatIntelUrl() + '/files';
		vm.searchCanceler = $q.defer();
		$http
			.get(url, {
				timeout: vm.searchCanceler.promise,
				params: {
					searchTerm: $stateParams.id,
					searchBy: 'TopFileNames',
					pageSize: 101,
					lookingBackIndays: vm.telemetryLookback,
				},
			})
			.then(
				function(response) {
					if (response.status == 404) {
						ng2router.navigate(['/errors/FileNotFound'], {
							queryParams: { itemId: $stateParams.id },
						});
						return;
					} else if (response.status == 500) {
						ng2router.navigate(['/errors']);

						return;
					} else if (response.status == 200) {
						if (response.data.length == 1) {
							const fileLink = filesService.getFileLink(
								response.data[0].Sha1 || response.data[0].Sha256
							);
							if (ng2router.shouldProcessUrl(fileLink)) {
								ng2router.navigateByUrl(fileLink);
							} else {
								$state.go(
									'file',
									{
										id: response.data[0].Sha1
											? response.data[0].Sha1
											: response.data[0].Sha256,
									},
									{ reload: true }
								);
							}
						}

						updateInvestigationFlow();
						vm.data = response.data;

						if (vm.data != null) {
							// filter data by the match type - exact match of other match
							vm.bestMatchData = vm.data.filter(function(item) {
								return item.MatchType === '1';
							});

							// show oher matches only if there are less than 100 results in the exact match - take the amount of missing results from other match.
							vm.otherMatchData =
								vm.bestMatchData && vm.bestMatchData.length < 100
									? vm.data.filter(function(item) {
											return item.MatchType === '0';
									  })
									: [];
						}

						vm.resultsLookback = vm.telemetryLookback;
						vm.loading = false;
					}
				},
				function(response) {
					ng2router.navigate(['/errors']);
				}
			);
	}

	function updateInvestigationFlow() {
		var data = {
			Id: $stateParams.id,
			icon: 'icon-Search',
			state: 'search.files',
			displaystate: 'Search results',
		};

		investigation.setActive(data);
		breadcrumbsStateService.set({
			id: `searchFiles`,
			label: 'Search results',
			url: $state.href($state.current),
			queryParams: {},
		});
	}
}
