
		<modal (close)="onClose()" [settings]="settings">
			<confirm-modal-content
				class="wcd-flex-1 ie11Patch ie11Flex"
				[confirmButtonOptions]="{
					disabled: isSaving || !declineReason
				}"
				[isSaving]="isSaving"
				(onCancel)="onCancelHandler()"
				(onConfirm)="onConfirmHandler()"
			>
				<div style="min-width: 400px">
					<remediation-decline-reason [(ngModel)]="declineReason"> </remediation-decline-reason>
				</div>
			</confirm-modal-content>
		</modal>
	