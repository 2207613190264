/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../i18n/src/lib/services/i18n.service";
import * as i3 from "./stacked-bars-chart.component";
var styles_StackedBarsChartComponent = [];
var RenderType_StackedBarsChartComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StackedBarsChartComponent, data: {} });
export { RenderType_StackedBarsChartComponent as RenderType_StackedBarsChartComponent };
export function View_StackedBarsChartComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "chart"]], [[1, "aria-label", 0]], null, null, null, null)), i0.ɵppd(3, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), "charts.stackedBarsChart")); _ck(_v, 2, 0, currVal_0); }); }
export function View_StackedBarsChartComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-stacked-bars-chart", [], null, null, null, View_StackedBarsChartComponent_0, RenderType_StackedBarsChartComponent)), i0.ɵdid(1, 770048, null, 0, i3.StackedBarsChartComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StackedBarsChartComponentNgFactory = i0.ɵccf("wcd-stacked-bars-chart", i3.StackedBarsChartComponent, View_StackedBarsChartComponent_Host_0, { settings: "settings", data: "data", change: "change", enableRenderOnSettingsChanges: "enableRenderOnSettingsChanges", allowXLabelClick: "allowXLabelClick" }, { xLabelClick: "xLabelClick" }, []);
export { StackedBarsChartComponentNgFactory as StackedBarsChartComponentNgFactory };
