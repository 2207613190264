import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Paris } from '@microsoft/paris';
import {
	GetAssetHardwareAndFirmwareApiCall,
	AssetHardwareAndFirmware,
	HardwareAndFirmwareTypes,
} from '@wcd/domain';
import { TvmTextsService } from '../../../../../tvm/services/tvm-texts.service';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ReactPanelsService } from '../../../../../shared/services/react-panels.service';

@Component({
	selector: 'collapsible-asset-hardware-and-firmware',
	templateUrl: './collapsible-asset-hardware-and-firmware-section.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleAssetHardwareAndFirmwareComponent implements OnInit {
	readonly SpinnerSize = SpinnerSize;

	@Input() machineId: string;

	loaded: boolean;

	assetHardwareAndFirmwareList: AssetHardwareAndFirmware[];
	systemData: AssetHardwareAndFirmware;
	biosData: AssetHardwareAndFirmware;
	processorData: AssetHardwareAndFirmware;

	systemText: string;
	biosText: string;
	processorText: string;

	constructor(
		private paris: Paris,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private tvmTextsService: TvmTextsService,
		private readonly reactPanelsService: ReactPanelsService
	) {}

	ngOnInit(): void {
		this.paris
			.apiCall(GetAssetHardwareAndFirmwareApiCall, { assetId: this.machineId })
			.subscribe(hardwareAndFirmware => {
				this.assetHardwareAndFirmwareList = hardwareAndFirmware;

				this.systemData = hardwareAndFirmware.find(a => a.type === HardwareAndFirmwareTypes.Hardware);
				this.biosData = hardwareAndFirmware.find(a => a.type === HardwareAndFirmwareTypes.Bios);
				this.processorData = hardwareAndFirmware.find(
					a => a.type === HardwareAndFirmwareTypes.Processor
				);

				this.systemText = this.systemData
					? `${this.getSummaryText(this.systemData)}, ${
							this.systemData.additionalFields.deviceFamily
					  }`
					: '-';
				this.processorText = this.processorData ? this.getSummaryText(this.processorData) : '-';
				this.biosText = this.biosData
					? `${this.getSummaryText(this.biosData)} ${this.getVersionText(this.biosData.version)}`
					: '-';

				this.loaded = true;
				this.changeDetectorRef.markForCheck();
			});
	}

	getSummaryText = (data: AssetHardwareAndFirmware) =>
		this.tvmTextsService.parseFullProductName(data.name, data.vendor);

	getVersionText = (version: string): string => version && version !== '-' && version;

	openSeeAllDetails(): void {
		this.reactPanelsService.renderComponent({
			componentName: 'AssetHardwareAndFirmwareDetailsPanelExternalWithBootstrap@wicd-tvm/main',
			props: {
				machineId: this.machineId,
			},
		});
	}
}
