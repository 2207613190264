import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { RemediatedThreatStatusEntities } from './remediated-threat-status-entities.value-object';

@ValueObject({
	singularName: 'Remediated Threat Statuses',
	pluralName: 'Remediated Threat Statuses',
	readonly: true,
})
export class RemediatedThreatsStatuses extends ModelBase {
	@EntityField({
		parse: entityTypes => {
			return { status: 'remediated', remediatedEntityTypes: entityTypes };
		},
	})
	remediated: RemediatedThreatStatusEntities;

	@EntityField({
		parse: entityTypes => {
			return { status: 'failed', remediatedEntityTypes: entityTypes };
		},
	})
	failed: RemediatedThreatStatusEntities;
}
