import { AlertsSummaryWidgetBase } from '../../../../global_entities/components/entity-widgets/alerts-summary/entity-alerts-summary.widget.base';
import { Url, UrlActiveAlertsSummaryRelationship } from '@wcd/domain';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { I18nService } from '@wcd/i18n';
import { ReportsService } from '../../../../shared-reports/services/reports.service';

@Component({
	templateUrl:
		'../../../../global_entities/components/entity-widgets/alerts-summary/entity-alerts-summary.widget.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UrlAlertsSummaryWidgetComponent extends AlertsSummaryWidgetBase<Url> {
	constructor(
		paris: Paris,
		activatedEntity: ActivatedEntity,
		i18nService: I18nService,
		reportsService: ReportsService
	) {
		super(paris, activatedEntity, reportsService, i18nService);
	}

	id = 'url-alerts-summary';

	entityType = Url;

	entityAlertsSummaryRelationship = UrlActiveAlertsSummaryRelationship;
}
