import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MdeUserRoleActionEnum } from '@wcd/domain';
import { AuthService } from '../services/auth.service';
import { AppContextService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';

interface MdeAllowedActionsGuardConfig {
	AllowedActions: MdeUserRoleActionEnum;
	NavigateToOnAuthorized: string;
	FallbackOnUnauthorized: string;
	AbsolutePath: boolean;
}

@Injectable()
export class MdeAllowedActionsGuard implements CanActivate {
	constructor(
		private appContext: AppContextService,
		private readonly authService: AuthService,
		private router: Router
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const config: MdeAllowedActionsGuardConfig = route.data['MdeAllowedActionsGuardConfig'];

		if (!this.authService.currentUser) {
			// No users signed in to check allowed actions
			return true;
		} else if (this.authService.currentUser.hasMdeAllowedUserRoleAction(config.AllowedActions)) {
			if (config.NavigateToOnAuthorized) {
				this.router.navigate([
					`${!config.AbsolutePath ? state.url : ''}${config.NavigateToOnAuthorized}`,
				]);
			}
			return true;
		} else {
			if (config.FallbackOnUnauthorized) {
				this.router.navigate([
					`${!config.AbsolutePath ? state.url : ''}${config.FallbackOnUnauthorized}`,
				]);
			} else {
				sccHostService.isSCC
					? sccHostService.state.go('Error.child', { path: 'UnauthorizedOnThisPage' })
					: this.router.navigate(['/Error/UnauthorizedOnThisPage'], {
							queryParams: { path: state.url },
					  });
			}
			return false;
		}
	}
}
