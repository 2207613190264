import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { dateStrToUtc } from '../../utils';

@Entity({
	...airsEntityConfig,
	singularName: 'Email',
	pluralName: 'Emails',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsEmailMessage,
})
export class AirsEmail extends AirsEntity {
	@EntityField({ data: 'sender_ip' })
	senderIp: string;

	@EntityField({ data: 'received_date', parse: dateStr => dateStrToUtc(dateStr) })
	receivedDate: Date;

	@EntityField()
	recipient: string;

	@EntityField()
	threats: string;

	@EntityField()
	sender: string;

	@EntityField()
	subject: string;

	@EntityField()
	urls: Array<string>;

	@EntityField({ data: 'delivery_status' })
	deliveryStatus: string;

	@EntityField({ data: 'network_message_id' })
	networkMessageId: string;

	@EntityField({
		data: 'attachments',
		parse: attachments => {
			/*
				There's a bug in the BE - attachments return as string and not as array (https://microsoft.visualstudio.com/OS/_workitems/edit/29226724)
				This is a temp solution so it won't break the UI
			 */
			return Array.isArray(attachments) ? attachments : null;
		},
	})
	attachments: Array<{
		fileName: string;
		SHA256: string;
		malwareFamily: string;
	}>;

	@EntityField({ data: 'internet_message_id' })
	internetMessageId: number;

	@EntityField({ data: 'delivery_location' })
	originalDeliveryLocation: string;

	@EntityField({ data: 'attachment_file_names' })
	attachmentFileNames: string;

	@EntityField({ data: 'attachment_file_hashes' })
	attachmentFileHashes: string;

	@EntityField({ data: 'email_headers' })
	emailHeaders: string;

	@EntityField({ data: 'email_download' })
	emailDownload: string;

	@EntityField({ data: 'delivery_action' })
	deliveryAction: string;

	@EntityField({ data: 'cluster_id' })
	clusterId: string;

	@EntityField({ data: 'sender_display_name' })
	senderDisplayName: string;

	@EntityField({ data: 'attachment_malware_family' })
	attachmentMalwareFamily: string;

	@EntityField({ data: 'language' })
	language: string;

	constructor(data) {
		super(data);
		this.entityName = this.subject;
	}
}
