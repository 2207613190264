<div class="wcd-filters-field"
	 [ngClass]="{'filter-field-uncollapsable': field.disableFilterSectionCollapse}"
	 [class.filter-field-filtered]="hasSelectedValues">
	<input type="checkbox" class="filters-list-checkbox-toggle"
		   [id]="'field_' + field.id + '_list_open'"
		   [(ngModel)]="isMinimized"
		   *ngIf="!field.disableFilterSectionCollapse"
	/>
	<button
		   class="btn filter-field-title full-width no-background list_open_button"
		   [attr.name]="'field_' + field.id + '_list_open_button'"
		   (keydown.space)="minimizedClicked($event)"
		   (keydown.enter)="minimizedClicked($event)"
		   (click)="minimizedClicked($event)"
		   [attr.aria-expanded]="!isMinimized"
		   [focusOn]="focus"
		   (blur)="resetFocus()">
		<div class="wcd-padding-right">
			{{field.name}}
		</div>
		<wcd-help [text]="field.helpText"
				  *ngIf="field.helpText"></wcd-help>
		<fab-icon iconName="ChevronUp" contentClass="wcd-filters-field__chevron" *ngIf="!field.disableFilterSectionCollapse"></fab-icon>
	</button>
	<div [hidden]="isMinimized" class="filters-field-values">
		<ng-container #filterValues></ng-container>
	</div>
</div>
