<!-- tslint:disable:template-accessibility-alt-text -->
<!-- tslint:disable:template-click-events-have-key-events -->
<div class="wcd-full-height main-contents wcd-scroll-all">
	<div class="error-page">
		<div class="error-content">
			 <wcd-shared-icon [iconName]="iconName" class="extra-large-icon pull-left"> </wcd-shared-icon >
			<div class="error-text">
				<h1>{{errorTitle}}</h1>
				<h2>
					<span>{{errorSubtitle}}</span>
					<span *ngIf="errorSubtitleHtml" [innerHtml]="errorSubtitleHtml"></span>
				</h2>
				<p>{{message}}</p>
				<img *ngIf="showLinearLoader" src="../../../assets/images/linear-loader.gif"/>
				<h2 *ngIf="showHelp !== false && isLoginError" class="error-footer">
					{{i18nService.strings.error_page_further_assistance}}
					<a href="https://support.microsoft.com/en-us/assistedsupportproducts"
					   data-track-id="AssistedSupportProductsDocs"
					   data-track-type="ExternalLink"
						target="_blank">{{i18nService.strings.error_page_contact_support}}</a>
				</h2>
				<h2 *ngIf="!authService.isLoggedIn" class="error-footer">
					<a (click)="goBack()"
					   data-track-id="Retry"
					   data-track-type="Action">
					   {{i18nService.strings.error_page_try_now}}
					</a>
				</h2>
			</div>
		</div>
	</div>
</div>
