import { EntityField, ParisConfig, ModelBase, ValueObject } from '@microsoft/paris';
import { AlertIocContextBase } from './alert-ioc-context-base.interface';

@ValueObject({
	singularName: 'Alert IP Context Item',
	pluralName: 'Alert IP Context Items',
})
export class AlertIPContext extends ModelBase implements AlertIocContextBase {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: 'IpAddress' })
	name: string;

	@EntityField({ data: 'FirstSeen' })
	firstSeen: Date;

	@EntityField({ data: 'LastSeen' })
	lastSeen: Date;

	@EntityField({
		data: 'Sha1',
		parse: data => (data && data.toLowerCase() !== 'null' ? data : null),
	})
	sha1: string;

	@EntityField({ data: 'Signer' })
	signer: string;

	@EntityField({ data: 'IpAddress' })
	ip: string;
}
