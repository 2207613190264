import { Injectable } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import {
	AccessTokenModificationDetails,
	CommandLine,
	CyberEvent,
	CyberEventActionType,
	CyberEventActionTypeName,
	CyberEventEntity,
	CyberEventEntityRelationTypeEnum,
	Email,
	File,
	LegacyUser,
	NetworkEndpoint,
	Process,
	Registry,
	RegistryModificationDetails,
	ScheduledTask,
	Script,
	Tag,
	TagType,
	UsbEvent,
} from '@wcd/domain';
import { BrowserFreeKnownProcessesService } from './browser-free-known-processes.service';
import { EventActionTypeConfig } from '../models/event-action-type-config.interface';
import { CyberEventsTagsService } from './cyber-events-tags.service';
import { LogonTypePipe } from '../../machines/pipes/logon-type.pipe';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { IconsService } from '@wcd/icons';
import { memoize } from 'lodash-es';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { Feature, FeaturesService } from '@wcd/config';
import { DataEntityType, EntityModelBase, Paris } from '@microsoft/paris';
import { tap } from 'rxjs/operators';
import { MdatpMitreService } from '@wcd/scc-common';

@Injectable()
export class CyberEventsActionTypesService {
	/**
	 * contains the list of available event action types and action groups configs.
	 * the keys should be action type name or action type group name
	 * each action type has different description, entities list, icon, etc.
	 * default is to get the details for an event by its action type. if action type is not found, get the details by its group type.
	 * @private
	 */
	private _eventActionTypes: { [index: string]: EventActionTypeConfig } = {
		alert: this.alertConfig(),
		allocateMemoryGroup: this.allocateMemoryGroupConfig(),
		amsiScriptDetection: this.amsiScriptDetectionConfig(),
		antivirusDetection: this.antivirusDetectionConfig(),
		antivirusEventGroup: this.antivirusEventGroupConfig(),
		appControlGroup: this.appControlGroupConfig(),
		appGuardContainerGroup: this.appGuardContainerGroupConfig(),
		appGuardLaunchedWithUrl: this.appGuardLaunchedWithUrlConfig(),
		browserLaunchedToOpenUrl: this.browserLaunchedToOpenUrlConfig(),
		connectionEventGroup: this.connectionEventGroupConfig(),
		exploitGuardAsrGroup: this.exploitGuardAsrGroupConfig(),
		exploitGuardGroup: this.exploitGuardGroupConfig(),
		exploitGuardNetworkProtectionGroup: this.exploitGuardNetworkProtectionGroupConfig(),
		fileCreated: this.fileCreatedConfig(),
		fileEventGroup: this.fileEventGroupConfig(),
		firewallConnectionBlockedGroup: this.firewallConnectionBlockedGroupConfig(),
		firewallInboundConnectionToAppBlocked: this.firewallInboundConnectionToAppBlockedConfig(),
		huntingRecordGroup: this.huntingRecordGroupConfig(),
		imageLoaded: this.imageLoadedConfig(),
		logonEventGroup: this.logonEventGroupConfig(),
		oneCyber: this.oneCyberConfig(),
		openProcessApiCall: this.openProcessApiCallConfig(),
		otherAlertRelatedActivity: this.otherAlertRelatedActivityConfig(),
		passwordChangeAttempt: this.passwordChangeAttemptConfig(),
		powerShellCommand: this.powerShellCommandConfig(),
		processCreatedGroup: this.processCreatedGroupConfig(),
		processInjectionGroup: this.processInjectionGroupConfig(),
		processPrimaryTokenModified: this.processPrimaryTokenModifiedConfig(),
		registryEventGroup: this.registryEventGroupConfig(),
		scheduledTaskGroup: this.scheduledTaskGroupConfig(),
		screenshotTaken: this.screenshotTakenConfig(),
		shellLinkCreateFileEvent: this.shellLinkCreateFileEventConfig(),
		smartScreenExploitWarning: this.smartScreenExploitWarningConfig(),
		smartScreenGroup: this.smartScreenGroupConfig(),
		usbDriveDriveLetterChanged: this.usbDriveDriveLetterChangedConfig(),
		usbDriveMount: this.usbDriveMountConfig(),
		usbDriveUnmount: this.usbDriveUnmountConfig(),
		usbDriveMounted: this.usbDriveMountedConfig(),
		usbDriveUnmounted: this.usbDriveUnmountedConfig(),
		usbEventGroup: this.usbEventGroupConfig(),
		userAccountCreated: this.userAccountCreatedConfig(),
		userToUserActivityGroup: this.userToUserActivityGroupConfig(),
	};

	constructor(
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly cyberEventsTagsService: CyberEventsTagsService,
		private readonly i18nService: I18nService,
		private readonly appInsightsService: AppInsightsService,
		private readonly logonTypePipe: LogonTypePipe,
		private iconsService: IconsService,
		private readonly entityPanelsService: EntityPanelsService,
		private readonly featuresService: FeaturesService,
		private readonly paris: Paris
	) {
		const methods = [
			this.getEventDescription,
			this.getSourceName,
			this.getTargetName,
			this.getEventEntities,
			this.getDefaultSourceEntities,
			this.getEventEntityRelationDescription,
			this.getEventIcon,
			this.getEventTags,
		];
		methods.map((method) => memoize(method));
	}

	isCyberEventSupportedByProcessTree = (cyberEvent: CyberEvent) =>
		Boolean(
			cyberEvent &&
				cyberEvent.actionType &&
				(this._eventActionTypes[cyberEvent.actionType.typeName] ||
					this._eventActionTypes[cyberEvent.actionType.groupTypeName])
		);

	isOneCyberEvent = (cyberEvent: CyberEvent) =>
		Boolean(
			cyberEvent &&
				cyberEvent.actionType &&
				cyberEvent.actionType.id === CyberEventActionTypeName.OneCyber
		);

	/**
	 * get action type of event.
	 * if the event is oneCyber event, take the action type from CyberEventEntity, otherwise return the actionType
	 * @param event
	 */
	getActionType(event: CyberEvent): string {
		if (event.actionType) {
			if (this.isOneCyberEvent(event) && event.cyberActionType) {
				// New flow - one cyber data with a new format
				return event.cyberActionType.typeName;
			}
			return event.actionType.id;
		}
		return event.rawActionType;
	}

	/**
	 * get text description of event.
	 * default: get text by event action type. if not found, get text by event group type. if not found, get string template for the event default entities.
	 * @param {CyberEvent} event
	 * @returns {string} event description text
	 */
	getEventDescription(event: CyberEvent): string {
		if (!event) throw new Error("Can't get event description. Event details are missing.");

		if (!event.actionType) {
			return this.handleMissingText(event);
		}

		if (
			this._eventActionTypes[event.actionType.typeName] &&
			this._eventActionTypes[event.actionType.typeName].getDescription
		)
			return this._eventActionTypes[event.actionType.typeName].getDescription(event);

		if (
			this._eventActionTypes[event.actionType.groupTypeName] &&
			this._eventActionTypes[event.actionType.groupTypeName].getDescription
		)
			return this._eventActionTypes[event.actionType.groupTypeName].getDescription(event);

		return (
			this.i18nService.get(
				`events.actionTypes.${event.actionType.typeName}.description`,
				{
					source: this.getSourceName(event),
					target: this.getTargetName(event),
				},
				true
			) || this.handleMissingText(event)
		);
	}

	/**
	 * gets default source Process entity name. if not found returns 'Unknown process' string.
	 * @param {CyberEvent} event
	 * @returns {string} initiating process name
	 */
	getSourceName(event: CyberEvent): string {
		if (!event) throw new Error("Can't get event source name. Event details are missing.");

		return event.initiatingProcess
			? this.globalEntityTypesService.getEntityType(Process).getEntityName(event.initiatingProcess)
			: this.i18nService.get('events.entities.unknownProcess');
	}

	/**
	 * gets default target Process\File entity name. if not found returns null.
	 * @param {CyberEvent} event
	 * @returns {string} target process\file name
	 */
	getTargetName(event: CyberEvent): string {
		if (!event) throw new Error("Can't get event target name. Event details are missing.");

		if (event.process)
			return this.globalEntityTypesService.getEntityType(Process).getEntityName(event.process);

		if (event.file) return this.globalEntityTypesService.getEntityType(File).getEntityName(event.file);

		return null;
	}

	/**
	 * gets event's entities array by action type or action group type.
	 * default: ["sourceParentParent", "sourceParent", "source", "target"].
	 * @param {CyberEvent} event
	 * @returns {Array<CyberEventEntityDisplay>} event entities with extended display details
	 */
	getEventEntities(event: CyberEvent): Array<CyberEventEntityDisplay> {
		return this.getEntities(event).map((eventEntity) => {
			const entityType = this.globalEntityTypesService.getEntityType(eventEntity.entityType);

			const icon = entityType
				? entityType.getIcon
					? entityType.getIcon([eventEntity.item])
					: entityType.icon
					? entityType.icon
					: null
				: null;

			const name = entityType
				? entityType.getEntityName
					? entityType.getEntityName(eventEntity.item)
					: eventEntity.item['name'] || entityType.entity.singularName
				: eventEntity.item['name'] || eventEntity.item.id || eventEntity.entityType.singularName;

			return {
				...eventEntity,
				icon,
				name,
			};
		});
	}

	/**
	 * gets initiating process and initiating process parent process if exists.
	 * @param {CyberEvent} event
	 * @returns {Array<CyberEventEntity>} event source parent and event source
	 */
	getDefaultSourceEntities(event: CyberEvent): Array<CyberEventEntity> {
		return [event.sourceParentParent, event.sourceParent, event.source].filter(Boolean);
	}

	/**
	 * gets a relation description text for an entity and its preceding entity in an event.
	 * @param {CyberEvent} event
	 * @param {CyberEventEntityDisplay} entity
	 * @returns {string} entity relation text (for example, "failed to connect to: ")
	 */
	getEventEntityRelationDescription(event: CyberEvent, entity: CyberEventEntityDisplay): string {
		if (!event || !entity) throw new Error("Can't get entity description. Details are missing.");

		if (!event.actionType) return '[Unknown action type]';

		if (
			this._eventActionTypes[event.actionType.typeName] &&
			this._eventActionTypes[event.actionType.typeName].getEntityRelationDescription
		)
			return this._eventActionTypes[event.actionType.typeName].getEntityRelationDescription(
				event,
				entity
			);

		if (
			this._eventActionTypes[event.actionType.groupTypeName] &&
			this._eventActionTypes[event.actionType.groupTypeName].getEntityRelationDescription
		)
			return this._eventActionTypes[event.actionType.groupTypeName].getEntityRelationDescription(
				event,
				entity
			);

		return null;
	}

	/**
	 * gets event's icon name by action type or action group type.
	 * @param {CyberEvent} event
	 * @returns {string} event icon name
	 */
	getEventIcon(event: CyberEvent): string {
		if (!event || !event.actionType) return null;

		let iconName: string = null;

		if (
			this._eventActionTypes[event.actionType.typeName] &&
			this._eventActionTypes[event.actionType.typeName].getIcon
		)
			iconName = this._eventActionTypes[event.actionType.typeName].getIcon(event);
		else if (
			this._eventActionTypes[event.actionType.groupTypeName] &&
			this._eventActionTypes[event.actionType.groupTypeName].getIcon
		)
			iconName = this._eventActionTypes[event.actionType.groupTypeName].getIcon(event);

		if (iconName) {
			const icon = this.iconsService.getIcon(iconName);
			if (icon) iconName = icon.name;
		}

		return iconName;
	}

	/**
	 * gets event's tags using backend tags and client side logic tags
	 * @param {CyberEvent} event
	 * @returns (Array<Tag>) event's tags
	 */
	getEventTags(event: CyberEvent): Array<Tag> {
		const tags: Array<Tag> = event.tags;
		tags.map((tag: Tag) => {
			if (tag.type && tag.type == TagType.mitre) {
				const mitreTagName: string = MdatpMitreService.getMitreTechniqueName(tag.id);
				tag.name = mitreTagName ? mitreTagName : tag.id;
			}

			return tag;
		});
		return [...this.cyberEventsTagsService.getEventAdditionslTags(event), ...tags];
	}

	/**
	 * gets actions available for the event
	 * @param {CyberEvent} event
	 * @returns {Array<ItemActionModel>} the available actions
	 */
	getEventActions(event: CyberEvent): ReadonlyArray<ItemActionModel> {
		if (!event || !event.actionType) return null;

		if (
			this._eventActionTypes[event.actionType.typeName] &&
			this._eventActionTypes[event.actionType.typeName].getActions
		) {
			return this._eventActionTypes[event.actionType.typeName].getActions(event);
		} else if (
			this._eventActionTypes[event.actionType.groupTypeName] &&
			this._eventActionTypes[event.actionType.groupTypeName].getActions
		) {
			return this._eventActionTypes[event.actionType.groupTypeName].getActions(event);
		}

		return null;
	}

	/**
	 * gets action name out of action type
	 * @param {CyberEventActionType} actionType
	 * @returns {string} action name ('audited' or 'blocked')
	 * @private
	 */
	private getAuditOrBlocked(actionType: CyberEventActionType): string {
		return this.i18nService.get(
			`events.actions.${actionType.id.includes('Audit') ? 'audited' : 'blocked'}`
		);
	}

	/**
	 * gets event's entities by action type or action group type
	 * @private
	 */
	private getEntities(event: CyberEvent): Array<CyberEventEntity> {
		if (!event || !event.actionType) return [];

		if (
			this._eventActionTypes[event.actionType.typeName] &&
			this._eventActionTypes[event.actionType.typeName].getEntities
		)
			return this._eventActionTypes[event.actionType.typeName].getEntities(event);

		if (
			this._eventActionTypes[event.actionType.groupTypeName] &&
			this._eventActionTypes[event.actionType.groupTypeName].getEntities
		)
			return this._eventActionTypes[event.actionType.groupTypeName].getEntities(event);
		else return [...this.getDefaultSourceEntities(event), event.target].filter(Boolean);
	}

	/**
	 * tracks missing action types and returns a default text
	 * @param {CyberEvent} event
	 * @returns {string}
	 */
	private handleMissingText(event: CyberEvent): string {
		return this.i18nService.get('events.entities.unknownActionType', { actionType: event.rawActionType });
	}

	// action types and groups configs

	private alertConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				return event.relatedAlert && event.relatedAlert.name;
			},
			getEntities: (event: CyberEvent) => [],
			getIcon: (event: CyberEvent) => 'alert',
		};
	}

	private allocateMemoryGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event);
				return this.i18nService.get('events.actionTypesGroups.allocateMemoryGroup.description', {
					source,
				});
			},
			getIcon: (event: CyberEvent) => 'memory',
		};
	}

	private amsiScriptDetectionConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event);
				return this.i18nService.get('events.actionTypes.amsiScriptDetection.description', { source });
			},
			getEntities: (event: CyberEvent) => this.getDefaultSourceEntities(event),
			getActions: (event: CyberEvent) =>
				this.featuresService.isEnabled(Feature.AmsiScriptDetection)
					? [
							new ItemActionModel({
								id: 'viewScript',
								nameKey: 'events.actionTypes.amsiScriptDetection.viewScript',
								refreshOnResolve: false,
								method: (events: Array<CyberEvent>) => {
									return this.paris
										.createItem(Script, {
											Sha1: event.file.sha1,
											Sha256: event.file.sha256,
										})
										.pipe(
											tap((script: Script) =>
												this.entityPanelsService.showEntity(Script, script, {
													actionTime: event.actionTime,
												})
											)
										)
										.toPromise();
								},
							}),
					  ]
					: [],
		};
	}

	private antivirusDetectionConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const target = this.getTargetName(event),
					threat =
						(event.additionalFields && event.additionalFields.threatName) ||
						this.i18nService.get('events.entities.unknownThreat').toLowerCase(),
					detectionSource =
						(event.additionalFields &&
							event.additionalFields.detectionSource &&
							this.i18nService.get(event.additionalFields.detectionSource.nameI18nKey)) ||
						this.i18nService.get('events.entities.defaultDetectionSource');
				if (target)
					return this.i18nService.get('events.actionTypes.antivirusDetection.description', {
						threat,
						target,
						detectionSource,
					});
				else
					return this.i18nService.get('events.actionTypes.antivirusDetection.descriptionNoFile', {
						threat,
						detectionSource,
					});
			},
		};
	}

	private antivirusEventGroupConfig(): EventActionTypeConfig {
		return {
			getIcon: (event: CyberEvent) => 'malwareOutline',
		};
	}

	private appControlGroupConfig(): EventActionTypeConfig {
		return {
			getIcon: (event: CyberEvent) => 'events.mixedSource',
		};
	}

	private appGuardContainerGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const containerId = event.containerId,
					action = this.i18nService.get(
						`events.actionTypesGroups.appGuardContainerGroup.actions.${event.actionType.typeName}`
					);

				return this.i18nService.get('events.actionTypesGroups.appGuardContainerGroup.description', {
					containerId,
					action,
				});
			},
			getIcon: (event: CyberEvent) => 'events.mixedSource',
		};
	}

	private appGuardLaunchedWithUrlConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const containerId = event.containerId;
				return this.i18nService.get('events.actionTypes.appGuardLaunchedWithUrl.description', {
					containerId,
				});
			},
			getIcon: (event: CyberEvent) => 'events.mixedSource',
		};
	}
	private browserLaunchedToOpenUrlConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				if (event.initiatingProcess) {
					if (event.initiatingProcess.name && BrowserFreeKnownProcessesService.isKnownProcess(event.initiatingProcess.name)) {
						// These known processes don't open links in the browser, so we know it was clicked by the user
						return this.i18nService.get(
							'events.actionTypes.browserLaunchedToOpenUrl.description'
						);
					}
					// Unknown process - we don't know if url was opened due to a user click or not, so use more vague terms
					else
						return this.i18nService.get(
							'events.actionTypes.browserLaunchedToOpenUrl.descriptionProcess',
							{
								source: this.getSourceName(event),
							}
						);
				}
				// With lack of process details, default to a user-friendly description, even if not 100% accurate.
				return this.i18nService.get('events.actionTypes.browserLaunchedToOpenUrl.description');
			},
			getEntities: (event: CyberEvent) => {
				const entities = this.getDefaultSourceEntities(event);
				return [
					...entities,
					...(event.remoteEndpoint
						? [
								{
									id: event.id || '',
									item: event.remoteEndpoint,
									entityType: NetworkEndpoint,
									relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
									depth: entities.length - 1,
								},
						  ]
						: []),
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				const isProcess =
					!event.initiatingProcess ||
					BrowserFreeKnownProcessesService.isKnownProcess(event.initiatingProcess.name);
				return this.i18nService.get(
					`events.actionTypes.browserLaunchedToOpenUrl.entityRelationText${
						isProcess ? 'Process' : ''
					}`
				);
			},
			getIcon: (event: CyberEvent) => 'link',
		};
	}

	private connectionEventGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event),
					remote = event.remoteEndpoint && event.remoteEndpoint.name;
				return this.i18nService.get(`events.actionTypes.${event.actionType.typeName}.description`, {
					source,
					remote,
				});
			},
			getEntities: (event: CyberEvent) => {
				const entities = this.getDefaultSourceEntities(event);
				return [
					...entities,
					...(event.remoteEndpoint
						? [
								{
									id: event.id || '',
									item: event.remoteEndpoint,
									entityType: NetworkEndpoint,
									relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
									depth: entities.length - 1,
								},
						  ]
						: []),
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				return this.i18nService.get(
					`events.actionTypes.${event.actionType.typeName}.entityRelationText`
				);
			},
			getIcon: (event: CyberEvent) => 'entities.ipaddress',
		};
	}

	private exploitGuardAsrGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event),
					target = this.getTargetName(event),
					action = this.getAuditOrBlocked(event.actionType);

				if (event.additionalFields && event.additionalFields.exploitGuardInnerRule) {
					let text = this.i18nService.get(
						`events.actionTypesGroups.exploitGuardAsrGroup.enforcedActions.${event.additionalFields.exploitGuardInnerRule.name}`,
						{ source, target, action },
						true
					);
					if (!text) {
						console.warn('Exploit guard ASR inner rule has no text description', event);
						const ruleId = event.additionalFields.exploitGuardInnerRule.id,
							item = target || source;
						text = this.i18nService.get(
							'events.actionTypesGroups.exploitGuardAsrGroup.description',
							{
								item,
								action,
								ruleId,
							}
						);
					}
					return text;
				} else throw new Error('Exploit guard ASR event is missing inner rule id');
			},
			getIcon: (event: CyberEvent) => 'events.mixedSource',
		};
	}

	private exploitGuardGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const target =
						this.getTargetName(event) || this.i18nService.get('events.entities.unknownProcess'),
					action = this.getAuditOrBlocked(event.actionType),
					enforcedAction = this.i18nService.get(
						`events.actionTypesGroups.exploitGuardGroup.enforcedActions.${event.actionType.subGroupTypeName}`
					);

				return this.i18nService.get('events.actionTypesGroups.exploitGuardGroup.description', {
					target,
					action,
					enforcedAction,
				});
			},
			getIcon: (event: CyberEvent) => 'events.mixedSource',
		};
	}

	private exploitGuardNetworkProtectionGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const remote = event.remoteEndpoint && event.remoteEndpoint.name,
					action = this.getAuditOrBlocked(event.actionType),
					category = event.additionalFields.responseCategory;

				return category
					? this.i18nService.get(
							'events.actionTypesGroups.exploitGuardNetworkProtectionGroup.descriptionWithCategory',
							{
								remote,
								action,
								category,
							}
					  )
					: this.i18nService.get(
							'events.actionTypesGroups.exploitGuardNetworkProtectionGroup.description',
							{
								remote,
								action,
							}
					  );
			},
			getIcon: (event: CyberEvent) => 'events.mixedSource',
		};
	}

	private fileCreatedConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event),
					target = this.getTargetName(event);

				if (event.email) {
					return this.i18nService.get(
						`events.actionTypes.fileCreated.outlookSavedAttachment.description`,
						{
							source,
						}
					);
				} else if (event.fileOrigin) {
					const downloadAddress = event.fileOrigin.name;
					return this.i18nService.get('events.actionTypes.fileCreated.descriptionDownloaded', {
						source,
						target,
						downloadAddress,
					});
				} else {
					return this.i18nService.get(
						`events.actionTypes.fileCreated.description${
							event.fileOriginReferer ? 'Extracted' : 'Created'
						}`,
						{ source, target }
					);
				}
			},
			getEntities: (event: CyberEvent) => {
				const entities = this.getDefaultSourceEntities(event);

				if (event.email) {
					return [
						...entities,
						...(event.email
							? [
									{
										id: event.id || '',
										item: event.email,
										entityType: Email,
										relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Child,
										depth: entities.length,
									},
							  ]
							: []),
						...(event.file
							? [
									{
										id: event.id || '',
										item: event.file,
										entityType: File,
										relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
										depth: entities.length,
									},
							  ]
							: []),
					];
				} else {
					return [
						...entities,
						...(event.file
							? [
									{
										id: event.id || '',
										item: event.file,
										entityType: File,
										relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
										depth: entities.length - 1,
									},
							  ]
							: []),
						...(event.fileOrigin
							? [
									{
										id: event.id || '',
										item: event.fileOrigin,
										entityType: NetworkEndpoint,
										relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
										depth: entities.length - 1,
									},
							  ]
							: []),
					];
				}
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				if (event.email) {
					if (event.file) {
						return this.i18nService.get(
							'events.actionTypes.fileCreated.outlookSavedAttachment.entityRelationText'
						);
					} else {
						return undefined;
					}
				} else {
					return this.i18nService.get(
						`events.actionTypes.fileCreated.entityRelationText${
							entity.entityType === File ? 'File' : 'Downloaded'
						}`
					);
				}
			},
			getIcon: (event: CyberEvent) => {
				if (event.email) {
					return 'email';
				} else {
					return 'events.fileCreated';
				}
			},
		};
	}
	private fileEventGroupConfig(): EventActionTypeConfig {
		return {
			getIcon: (event: CyberEvent) => 'entities.file',
		};
	}

	private firewallConnectionBlockedGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event);
				let remoteAddress =
					event.remoteEndpoint && event.remoteEndpoint.ip && event.remoteEndpoint.ip.id;
				if (remoteAddress) {
					const remotePort = event.remoteEndpoint && event.remoteEndpoint.port;
					if (remotePort) {
						remoteAddress += `:${remotePort}`;
					}
					return this.i18nService.get(
						'events.actionTypesGroups.firewallConnectionBlockedGroup.description',
						{
							source,
							remote: remoteAddress,
						}
					);
				} else
					return this.i18nService.get(
						'events.actionTypesGroups.firewallConnectionBlockedGroup.descriptionNoRemote',
						{
							source,
						}
					);
			},
			getIcon: (event: CyberEvent) => 'services.windowsfirewall',
		};
	}

	private firewallInboundConnectionToAppBlockedConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event);
				let profiles: string;
				let isMultiple: boolean;
				if (event.additionalFields && event.additionalFields.firewallProfiles) {
					profiles = event.additionalFields.firewallProfiles.join(', ');
					isMultiple = event.additionalFields.firewallProfiles.length > 1;
				}
				const key =
					'events.actionTypes.firewallInboundConnectionToAppBlocked.description' + isMultiple
						? '_multiple'
						: '';
				return this.i18nService.get(key, {
					source,
					profiles,
				});
			},
			getIcon: (event: CyberEvent) => 'services.windowsfirewall',
		};
	}

	private huntingRecordGroupConfig(): EventActionTypeConfig {
		return {};
	}

	private imageLoadedConfig(): EventActionTypeConfig {
		return {
			getEntities: (event: CyberEvent) => {
				const entities = this.getDefaultSourceEntities(event);

				return [
					...entities,
					...(event.file
						? [
								{
									id: event.id || '',
									item: event.file,
									entityType: File,
									relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
									depth: entities.length - 1,
								},
						  ]
						: []),
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				return this.i18nService.get('events.actionTypes.imageLoaded.entityRelationText');
			},
			getIcon: (event: CyberEvent) => 'entities.module',
		};
	}

	private logonEventGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const user = event.user && event.user.fullName,
					remote =
						(event.remoteMachine && event.remoteMachine.name) ||
						(event.remoteEndpoint && event.remoteEndpoint.name),
					logon = this.logonTypePipe.transform(event.logonType, true),
					status = this.i18nService.get(
						`events.actionTypesGroups.logonEventGroup.${
							event.actionType.id === CyberEventActionTypeName.LogonSuccess ? 'success' : 'fail'
						}`
					);

				if (user && remote)
					return this.i18nService.get(
						'events.actionTypesGroups.logonEventGroup.descriptionUserRemote',
						{
							logon,
							user,
							remote,
							status,
						}
					);
				else if (user)
					return this.i18nService.get('events.actionTypesGroups.logonEventGroup.descriptionUser', {
						logon,
						user,
						status,
					});
				else if (remote) {
					return this.i18nService.get(
						'events.actionTypesGroups.logonEventGroup.descriptionRemote',
						{
							logon,
							remote,
							status,
						}
					);
				} else
					return this.i18nService.get('events.actionTypesGroups.logonEventGroup.description', {
						logon,
						status,
					});
			},
			getEntities: (event: CyberEvent) => {
				return [
					...(event.user
						? [
								{
									id: event.id || '',
									item: event.user,
									entityType: LegacyUser,
									relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
									depth: 0,
								},
						  ]
						: []),
					...(event.remoteEndpoint
						? [
								{
									id: event.id || '',
									item: event.remoteEndpoint,
									entityType: NetworkEndpoint,
									relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
									depth: 0,
								},
						  ]
						: []),
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				return entity.entityType === LegacyUser
					? this.i18nService.get(
							`events.actionTypesGroups.logonEventGroup.entityRelationText${
								event.actionType.id === CyberEventActionTypeName.LogonSuccess
									? 'Success'
									: 'Fail'
							}`
					  )
					: this.i18nService.get(
							`events.actionTypesGroups.logonEventGroup.entityRelationTextRemote`
					  );
			},
			getIcon: (event: CyberEvent) => 'events.logon',
		};
	}

	private oneCyberConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => event.description,
			getEntities: (event: CyberEvent) => {
				const entities = new Array<CyberEventEntity>();
				for (const entity of event.entities) {
					const entityType: DataEntityType = this.globalEntityTypesService.getEntityType(
						entity.entityType
					).entity;
					if (entityType === Registry) {
						this.paris
							.createItem(RegistryModificationDetails, { Registry: entity.item })
							.subscribe((item) =>
								this.pushCyberEventItemToEntities(
									RegistryModificationDetails,
									entity,
									item,
									entities
								)
							);
					} else {
						this.paris
							.createItem(entityType, entity.item)
							.subscribe((item) =>
								this.pushCyberEventItemToEntities(entityType, entity, item, entities)
							);
					}
				}
				return entities;
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) =>
				entity.relationDescription,
			getIcon: (event: CyberEvent) => event.icon,
		};
	}

	private pushCyberEventItemToEntities(
		entityType: DataEntityType,
		entity: CyberEventEntity,
		parisItem: any,
		entities: Array<CyberEventEntity>
	): void {
		entities.push({
			id: parisItem.id || '',
			item: parisItem,
			entityType: entityType,
			relationToPrecedingEntity: entity.relationToPrecedingEntity,
			relationDescription: entity.relationDescription,
			depth: entity.depth,
			isCollapsed: entity.isCollapsed,
			genericFields: entity.genericFields,
		});
	}

	private openProcessApiCallConfig(): EventActionTypeConfig {
		return {
			getEntities: (event: CyberEvent) => {
				const entities = this.getDefaultSourceEntities(event);

				return [
					...(entities && entities.length
						? entities
						: [
								{
									id: '',
									item: null,
									entityType: Process,
									depth: 0,
								},
						  ]),
					...(event.process
						? [
								{
									id: event.id || '',
									item: event.process,
									entityType: Process,
									relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
									depth: entities && entities.length ? entities.length - 1 : 0,
								},
						  ]
						: []),
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				return this.i18nService.get('events.actionTypes.openProcessApiCall.entityRelationText');
			},
			getIcon: (event: CyberEvent) => 'provisioningPackage',
		};
	}

	private otherAlertRelatedActivityConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				return event.additionalFields && event.additionalFields.description;
			},
			getIcon: (event: CyberEvent) => 'events.alertRelatedEvent',
		};
	}

	private passwordChangeAttemptConfig(): EventActionTypeConfig {
		return {
			getIcon: (event: CyberEvent) => 'permissions',
		};
	}

	private powerShellCommandConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event),
					command = event.additionalFields && event.additionalFields.command.commandText;
				return this.i18nService.get('events.actionTypes.powerShellCommand.description', {
					source,
					command,
				});
			},
			getEntities: (event: CyberEvent) => {
				const entities = this.getDefaultSourceEntities(event);

				return [
					...entities,
					...(event.additionalFields && event.additionalFields.command
						? [
								{
									id: event.id || '',
									item: event.additionalFields.command,
									entityType: CommandLine,
									relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
									depth: entities.length - 1,
								},
						  ]
						: []),
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				return this.i18nService.get('events.actionTypes.powerShellCommand.entityRelationText');
			},
			getIcon: (event: CyberEvent) => 'entities.commandPrompt',
		};
	}

	private processCreatedGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event),
					target = this.getTargetName(event);

				if (event.actionType.id === CyberEventActionTypeName.ProcessCreatedUsingWmiQuery) {
					if (event.remoteMachine && event.remoteMachine.name) {
						const remoteMachine = event.remoteMachine.name;
						return this.i18nService.get(
							'events.actionTypesGroups.processCreatedGroup.descriptionLogicalWmiRemote',
							{
								target,
								remoteMachine,
							}
						);
					} else
						return this.i18nService.get(
							'events.actionTypesGroups.processCreatedGroup.descriptionLogicalWmi',
							{
								source,
								target,
							}
						);
				}
				return this.i18nService.get('events.actionTypesGroups.processCreatedGroup.description', {
					source,
					target,
				});
			},
			getIcon: (event: CyberEvent) => 'entities.process',
		};
	}

	private processInjectionGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event),
					target = this.getTargetName(event);

				return this.i18nService.get('events.actionTypesGroups.processInjectionGroup.description', {
					source,
					target,
				});
			},
			getEntities: (event: CyberEvent) => {
				const entities = this.getDefaultSourceEntities(event);

				return [
					...(entities && entities.length
						? entities
						: [
								{
									id: '',
									item: null,
									entityType: Process,
									depth: 0,
								},
						  ]),
					...(event.process
						? [
								{
									id: event.id || '',
									item: event.process,
									entityType: Process,
									relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
									depth: entities && entities.length ? entities.length - 1 : 0,
								},
						  ]
						: []),
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				return this.i18nService.get(
					'events.actionTypesGroups.processInjectionGroup.entityRelationText'
				);
			},
			getIcon: (event: CyberEvent) => 'syringe',
		};
	}

	private processPrimaryTokenModifiedConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event),
					isChangedToSystem =
						event.additionalFields &&
						event.additionalFields.accessTokenDetails &&
						event.additionalFields.accessTokenDetails.isChangedToSystemToken;
				return this.i18nService.get(
					`events.actionTypes.processPrimaryTokenModified.description${
						isChangedToSystem ? 'System' : ''
					}`,
					{ source }
				);
			},
			getEntities: (event: CyberEvent) => {
				const entities = this.getDefaultSourceEntities(event);

				return [
					...entities,
					...(event.additionalFields && event.additionalFields.accessTokenDetails
						? [
								{
									id: event.id || '',
									item: event.additionalFields.accessTokenDetails,
									entityType: AccessTokenModificationDetails,
									relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
									depth: entities.length - 1,
								},
						  ]
						: []),
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				const isChangedToSystem =
					event.additionalFields &&
					event.additionalFields.accessTokenDetails &&
					event.additionalFields.accessTokenDetails.isChangedToSystemToken;
				return this.i18nService.get(
					`events.actionTypes.processPrimaryTokenModified.entityRelationText${
						isChangedToSystem ? 'System' : ''
					}`
				);
			},
			getIcon: (event: CyberEvent) => 'permissions',
		};
	}

	private registryEventGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const source = this.getSourceName(event),
					registryKey =
						event.registryModificationDetails &&
						event.registryModificationDetails.current &&
						event.registryModificationDetails.current.key,
					previousKey =
						event.registryModificationDetails &&
						event.registryModificationDetails.previous &&
						event.registryModificationDetails.previous.key;
				return this.i18nService.get(`events.actionTypes.${event.actionType.typeName}.description`, {
					source,
					previousKey,
					registryKey,
				});
			},
			getEntities: (event: CyberEvent) => {
				const entities = this.getDefaultSourceEntities(event);

				return [
					...entities,
					...(event.registryModificationDetails
						? [
								{
									id: event.id || '',
									item: event.registryModificationDetails,
									entityType: RegistryModificationDetails,
									relationToPrecedingEntity: event.initiatingProcess
										? CyberEventEntityRelationTypeEnum.Outer
										: CyberEventEntityRelationTypeEnum.Empty,
									depth: entities.length - 1,
								},
						  ]
						: []),
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				return this.i18nService.get(
					`events.actionTypes.${event.actionType.typeName}.entityRelationText`
				);
			},
			getIcon: (event: CyberEvent) => 'entities.registry',
		};
	}

	private scheduledTaskGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const taskName =
						event.additionalFields &&
						event.additionalFields.scheduledTask &&
						event.additionalFields.scheduledTask.taskName,
					action = this.i18nService.get(
						`events.actionTypesGroups.scheduledTaskGroup.actions.${event.actionType.typeName}`
					);
				return this.i18nService.get(
					'events.actionTypesGroups.scheduledTaskGroup.' +
						(taskName ? 'description' : 'defaultDescription'),
					{
						taskName,
						action,
					}
				);
			},
			getEntities: (event: CyberEvent) => {
				return [
					...(event.initiatingUser
						? [
								{
									id: event.id || '',
									item: event.initiatingUser,
									entityType: LegacyUser,
									depth: 0,
								},
						  ]
						: []),
					{
						id: event.id || '',
						item: event.additionalFields.scheduledTask,
						entityType: ScheduledTask,
						depth: 0,
						relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
					},
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				return entity.entityType === ScheduledTask && event.initiatingUser
					? this.i18nService.get(
							`events.actionTypesGroups.scheduledTaskGroup.actions.${event.actionType.typeName}`
					  )
					: null;
			},
			getIcon: (event: CyberEvent) => 'entities.module',
		};
	}

	private screenshotTakenConfig() {
		return {
			getDescription: (event: CyberEvent) => {
				const username = (event.initiatingUser && event.initiatingUser.fullName) || 'User';
				return this.i18nService.get(`events.actionTypes.${event.actionType.typeName}.description`, {
					username,
				});
			},
			getEntities: (event: CyberEvent) => {
				return [
					...(event.initiatingUser
						? [
								{
									id: event.id || '',
									item: event.initiatingUser,
									entityType: LegacyUser,
									relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
									depth: 0,
								},
						  ]
						: []),
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				return this.i18nService.get(
					`events.actionTypes.${event.actionType.typeName}.entityRelationText`
				);
			},
			getIcon: (event: CyberEvent) => 'screenshot',
		};
	}

	private shellLinkCreateFileEventConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const process = this.getSourceName(event);
				let linkFileName: string;
				if (event.file) {
					linkFileName = event.file.fileName;
				} else {
					throw new Error("Can't get event file. Event details are missing.");
				}

				return this.i18nService.get('events.actionTypes.shellLinkCreateFileEvent.description', {
					process,
					linkFileName,
				});
			},
			getIcon: (event: CyberEvent) => 'events.fileCreated',
		};
	}

	private smartScreenExploitWarningConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const remote = event.remoteEndpoint && event.remoteEndpoint.name;
				return this.i18nService.get(
					`events.actionTypes.smartScreenExploitWarning.description${remote ? 'Remote' : ''}`,
					{ remote }
				);
			},
		};
	}

	private smartScreenGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				if (event.actionType.id === CyberEventActionTypeName.SmartScreenUserOverride) {
					// "user override event" is mostly empty.
					// On "GetItemById" on the event, backend will return the details
					// (which will be the details of some other smart screen event)
					return this.i18nService.get('events.actionTypes.smartScreenUserOverride.description');
				} else {
					const experience = event.additionalFields && event.additionalFields.experience;
					let target = this.getTargetName(event);

					if (event.actionType.id === CyberEventActionTypeName.SmartScreenUrlWarning)
						target = event.remoteEndpoint && event.remoteEndpoint.name;
					return this.i18nService.get(
						`events.actionTypesGroups.smartScreenGroup.description${
							experience ? 'Experience' : ''
						}`,
						{ target, experience }
					);
				}
			},
			getEntities: (event: CyberEvent) => {
				const entities = this.getDefaultSourceEntities(event);

				return [
					...entities,
					...(event.remoteEndpoint
						? [
								{
									id: event.id || '',
									item: event.remoteEndpoint,
									entityType: NetworkEndpoint,
									relationToPrecedingEntity: event.initiatingProcess
										? CyberEventEntityRelationTypeEnum.Outer
										: CyberEventEntityRelationTypeEnum.Empty,
									depth: entities.length - 1,
								},
						  ]
						: [
								...(event.target
									? [
											{
												...event.target,
												relationToPrecedingEntity: event.initiatingProcess
													? CyberEventEntityRelationTypeEnum.Outer
													: CyberEventEntityRelationTypeEnum.Empty,
												depth: entities.length - 1,
											},
									  ]
									: []),
						  ]),
				];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				const experience = event.additionalFields && event.additionalFields.experience;
				return this.i18nService.get(
					`events.actionTypesGroups.smartScreenGroup.entityRelationText${
						experience ? 'Experience' : ''
					}`,
					{ experience }
				);
			},
			getIcon: (event: CyberEvent) => 'shield',
		};
	}

	private usbDriveDriveLetterChangedConfig(): EventActionTypeConfig {
		return {
			getIcon: () => 'plug',
		};
	}

	private usbDriveMountConfig(): EventActionTypeConfig {
		return {
			getIcon: () => 'connection',
		};
	}

	private usbDriveUnmountConfig(): EventActionTypeConfig {
		return {
			getIcon: () => 'disconnect',
		};
	}

	private usbDriveMountedConfig(): EventActionTypeConfig {
		return {
			getIcon: () => 'connection',
		};
	}

	private usbDriveUnmountedConfig(): EventActionTypeConfig {
		return {
			getIcon: () => 'disconnect',
		};
	}

	private usbEventGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const { driveLetter = '', productName = '' } = event.additionalFields.usbEvent || {};
				return this.i18nService.get(`events.actionTypes.${event.actionType.typeName}.description`, {
					driveLetter,
					productName,
				});
			},
			getEntities: (event: CyberEvent) => [
				{
					id: event.id || '',
					item: <EntityModelBase<string>>event.additionalFields.usbEvent,
					entityType: UsbEvent,
					relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
					depth: 0,
				},
			],
		};
	}

	private userAccountCreatedConfig(): EventActionTypeConfig {
		return {
			getIcon: (event: CyberEvent) => 'users.user_add',
		};
	}

	private userToUserActivityGroupConfig(): EventActionTypeConfig {
		return {
			getDescription: (event: CyberEvent) => {
				const target = event.user && event.user.fullName,
					initiator = event.initiatingUser && event.initiatingUser.fullName;
				return this.i18nService.get(
					`events.actionTypesGroups.userToUserActivityGroup.${event.actionType.typeName}.description`,
					{
						target,
						initiator,
					}
				);
			},
			getEntities: (event: CyberEvent) => {
				return event.user
					? [
							...(event.initiatingUser
								? [
										{
											id: event.id || '',
											item: event.initiatingUser,
											entityType: LegacyUser,
											depth: 0,
										},
								  ]
								: []),
							{
								id: event.id || '',
								item: event.user,
								entityType: LegacyUser,
								depth: event.initiatingUser ? 1 : 0,
								relationToPrecedingEntity: event.initiatingUser
									? CyberEventEntityRelationTypeEnum.Outer
									: CyberEventEntityRelationTypeEnum.Empty,
							},
					  ]
					: [];
			},
			getEntityRelationDescription: (event: CyberEvent, entity: CyberEventEntityDisplay) => {
				return entity.relationToPrecedingEntity === CyberEventEntityRelationTypeEnum.Outer
					? this.i18nService.get(
							`events.actionTypesGroups.userToUserActivityGroup.${event.actionType.typeName}.entityRelationText`
					  )
					: null;
			},
			getIcon: (event: CyberEvent) => 'users.user',
		};
	}
}

export interface CyberEventEntityDisplay extends CyberEventEntity {
	icon: string;
	name: string;
}
