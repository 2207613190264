import { Entity, EntityField, EntityModelBase, DataQuery } from '@microsoft/paris';
import { InvestigatedMachine } from '../investigated-machine.entity';
import { RemediationActionType } from '../../remediation/remediation-action-type.entity';
import { InvestigationPendingType } from '../investigation-pending-type.entity';
import { InvestigationActionStatus } from './investigation-action-status.entity';
import { InvestigationActionCategory } from './investigation-category.entity';
import { InvestigationActionError } from './investigation-action-error.value-object';
import { InvestigationActionExtraData } from './investigation-action-extra-data.value-object';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { AirsEntity } from '../../airs_entity/airs-entity.entity';
import { OfficeUtils } from '../../utils/office-utils';
import { InvestigationActionStatusId } from './investigation-action-status.values';
import { getConvertedEntityTypeName } from '../../mtp-investigation/mtp-investigation.entity';
import { AirsEntityType } from '../../airs_entity/airs-entity-type.entity';
import { get } from 'lodash-es';
import { mapAlertV3EntityToAirsEntity } from '../../airs_entity/alertV3/converters/airs-entity.converter.utils';
import { AlertV3ActionTypeMap } from '../../action-history/alertV3-action-type-types';
import { remediationActionTypeValues } from '../../remediation/remediation-action-type.values';
import { getConvertedOfficeActionType } from './office-action.utils';
import { sccHostService } from '@wcd/scc-interface';

export function convertOfficeActionToInvestigationAction(rawData) {
	const actionEntities =
		rawData &&
		rawData.Entities &&
		rawData.Entities.reduce((res, entity) => {
			const convertedEntityType = getConvertedEntityTypeName(entity.Type);
			res.push({
				...mapAlertV3EntityToAirsEntity(entity),
				entity_type: AirsEntityType[convertedEntityType],
			});
			return res;
		}, []);

	const startDate = rawData.StartTimeUtc.endsWith('Z') ? rawData.StartTimeUtc : rawData.StartTimeUtc + 'Z';

	//TODO: currently, for office investigation action, there's a bug that there's no EndTimeUtc in the response (https://microsoft.visualstudio.com/OS/_workitems/edit/24180557)
	const endDate = rawData.EndTimeUtc
		? rawData.EndTimeUtc.endsWith('Z')
			? rawData.EndTimeUtc
			: rawData.EndTimeUtc + 'Z'
		: null;

	const durationInSeconds =
		endDate && startDate ? (new Date(endDate).valueOf() - new Date(startDate).valueOf()) / 1000 : null;

	const convertedOfficeActionType = getConvertedOfficeActionType(rawData);
	const investigationActionTypeType = AlertV3ActionTypeMap[convertedOfficeActionType];
	const investigationActionType = remediationActionTypeValues.find(
		v => v.type === investigationActionTypeType
	);
	return {
		id: rawData.ActionId,
		I18nNameKey: convertedOfficeActionType,
		remediation_action_type: investigationActionType ? investigationActionType.id : null,
		investigation: rawData.InvestigationId,
		action_status: InvestigationActionStatusId[rawData.ActionStatus],
		created: startDate,
		started: startDate,
		duration: durationInSeconds,
		entities: actionEntities,
		comment_count: rawData.ApproverComment ? 1 : 0,
		commentData: rawData.ApproverComment
			? {
					approverComment: rawData.ApproverComment,
					actionApprovedTime:
						rawData.ActionApprovedTime &&
						(rawData.ActionApprovedTime.endsWith('Z')
							? rawData.ActionApprovedTime
							: rawData.ActionApprovedTime + 'Z'),
					approvedBy: rawData.ApprovedBy,
			  }
			: null,
		category: 'Other',
		IsOfficeAction: true,
	};
}

@Entity({
	singularName: 'Investigation Action',
	pluralName: 'Investigation Actions',
	endpoint: (config, query) => {
		if (query && query.where && query.where['useOfficeApi']) {
			return `Find/MtpBatch?tenantid=${
				query.where['tenantId']
			}&PageSize=200&Filter=ModelType eq 1 and ContainerUrn eq '${query.where['investigation_urn']}'`;
		}
		return 'investigationactions';
	},
	parseItemQuery: (itemId, entity, config, params: { [index: string]: any }) => {
		if (params && params.useOfficeApi) {
			// There's bug in office that for PageSize=1 we don't get all the data, hence, PageSize=50
			return `Find/MtpBatch?tenantid=${
				params.tenantId
			}&PageSize=50&Filter=ModelType eq 1 and UrnProp eq '${itemId}'`;
		}

		return `investigationactions/${itemId}`;
	},
	allItemsProperty: 'results',
	separateArrayParams: true,
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) => {
		if (query && query.where && query.where['useOfficeApi']) {
			return sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>';
		}
		return config.data.serviceUrls.automatedIr;
	},
	parseData: (rawData, config, query) => {
		if (query && query.where && query.where['useOfficeApi']) {
			rawData = get(rawData, 'ResultData[0].InvestigationActionPayload');
			rawData = OfficeUtils.convertDataFromAlertV3(rawData);
			rawData =
				rawData &&
				(Array.isArray(rawData) ? rawData : [rawData]).map(a =>
					convertOfficeActionToInvestigationAction(a)
				);
			if (!query.where['isListView']) {
				return (rawData && rawData.length && rawData[0]) || null;
			}
			return {
				results: rawData,
				count: rawData ? rawData.length : 0,
			};
		}

		return rawData;
	},
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class InvestigationAction<T extends string | number = number> extends EntityModelBase<T> {
	@EntityField({
		parse: (name, itemData) => name || itemData['I18nNameKey'],
	})
	name: string;

	@EntityField({
		data: ['I18nNameKey', 'name'],
		parse: (actionType, rawData, query) => {
			if (actionType) {
				return actionType;
			}
		},
	})
	i18nNameKey: string;

	@EntityField() details: string;

	@EntityField({ data: 'investigation' })
	investigationId: T;

	@EntityField({ data: 'is_live_response' })
	isLiveResponse: boolean;

	@EntityField({ data: 'action_status' })
	status: InvestigationActionStatus;

	@EntityField() created: Date;

	@EntityField() started: Date;

	@EntityField() duration: number;

	@EntityField({ data: 'pending_duration' })
	pendingDuration: number;

	@EntityField({ data: 'queued_duration' })
	queuedDuration: number;

	@EntityField() category: InvestigationActionCategory;

	@EntityField({ data: 'host' })
	machine: InvestigatedMachine;

	@EntityField({ data: 'comment_count', defaultValue: 0 })
	commentCount: number;

	@EntityField()
	commentData?: {
		actionApprovedTime: string;
		approvedBy: string;
		approverComment: string;
	};

	@EntityField({ data: 'remediation_action_type' })
	remediationActionType: RemediationActionType;

	@EntityField({ data: 'pending_type' })
	pendingType: InvestigationPendingType;

	@EntityField({ data: 'is_remediation', defaultValue: false })
	isRemediation: boolean;

	@EntityField({
		parse: errors =>
			(errors || []).map(error => {
				if (!(error.details instanceof Array)) {
					const details = [];
					if (Object(error.details) === error.details) {
						for (const p in error.details) {
							let value = error.details[p];
							if (Object(value) === value) value = JSON.stringify(value);

							details.push(`${p}: ${value}`);
						}
					} else details.push(error.details && error.details.toString());

					error.details = details;
				}

				return error;
			}),
	})
	errors: Array<InvestigationActionError>;

	@EntityField({ data: 'extra_data', defaultValue: {} })
	extraData: InvestigationActionExtraData;

	@EntityField() recommendation: string;

	@EntityField() reason: string;

	@EntityField({ data: 'is_cleanup_applied', defaultValue: false })
	dataRetentionApplied: boolean;

	@EntityField({ arrayOf: AirsEntity })
	entities: Array<AirsEntity>;

	@EntityField({ data: 'undo_action_id' })
	undoActionId: number;

	@EntityField({ data: 'undone_action_id' })
	undoneActionId: number;

	@EntityField({
		data: 'IsOfficeAction',
		parse: (isOfficeAction, _, query) => {
			return !!(isOfficeAction || (query && query.where && query.where['useOfficeApi']));
		},
	})
	isOfficeAction: boolean;

	shortActionId: string | number;

	constructor(data) {
		super(data);
		this.shortActionId = OfficeUtils.getShortId(this.id);
	}

	get isActionUndone(): boolean {
		return !!this.undoneActionId;
	}

	get isActionUndoable(): boolean {
		return !!this.undoActionId;
	}

	get actionIds(): Array<T> {
		return [this.id];
	}

	get isPending(): boolean {
		return this.status.isPending || false;
	}

	get isRunning(): boolean {
		return this.status.isRunning || false;
	}

	get ended(): Date {
		if (!this.isRunning && this.duration != null)
			return new Date(this.started.valueOf() + this.duration * 1000);

		return null;
	}
}
