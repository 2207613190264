import { mapValues } from 'lodash-es';
import { Lazy } from '@wcd/utils';
var ColorsService = /** @class */ (function () {
    function ColorsService(knownColorsService, colorMapping) {
        var _this = this;
        this.knownColorsService = knownColorsService;
        this.colorMapping = colorMapping;
        this._colorsMap = new Lazy(function () {
            return new Map(Object.entries(mapValues(_this.colorMapping, function (colorName) { return _this.knownColorsService.knownColorsMap[colorName]; })));
        });
        this._fontColorsClassMap = new Lazy(function () {
            return new Map(Object.entries(mapValues(_this.colorMapping, function (colorName) { return _this.knownColorsService.fontColorsClassMap[colorName]; })));
        });
        this._backgroundColorsClassMap = new Lazy(function () {
            return new Map(Object.entries(mapValues(_this.colorMapping, function (colorName) { return _this.knownColorsService.backgroundColorsClassMap[colorName]; })));
        });
    }
    Object.defineProperty(ColorsService.prototype, "colorsMap", {
        get: function () {
            return this._colorsMap.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorsService.prototype, "fontColorsClassMap", {
        get: function () {
            return this._fontColorsClassMap.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorsService.prototype, "backgroundColorsClassMap", {
        get: function () {
            return this._backgroundColorsClassMap.value;
        },
        enumerable: true,
        configurable: true
    });
    return ColorsService;
}());
export { ColorsService };
