import { ValueObject, EntityField, EntityModelBase } from '@microsoft/paris';
import { Severity } from '../../severity/severity.entity';

@ValueObject({
	singularName: 'Tree element alert context',
	pluralName: 'Tree elements alert context',
})
export class TreeElementAlertContext extends EntityModelBase {
	@EntityField({ data: 'alertid' })
	alertId: string;

	@EntityField()
	severity: Severity;

	@EntityField()
	title: string;

	cyberEntity: EntityModelBase;
}
