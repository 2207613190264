import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'wcd-text-field',
	templateUrl: './text-field.component.html',
	styleUrls: ['./text-field.component.scss'],
})
export class TextFieldComponent {
	focus = false;
	@Input() text?: string = '';
	@Input() placeholder?: string = '';
	@Input() label?: string;
	@Input() className: string;
	@Input() required?: boolean;
	@Input() disabled?: boolean;
	@Input() autoFocus = true;
	@Output() textChange = new EventEmitter<string>();

	onValueChange(newValue: string) {
		this.textChange.emit(newValue);
	}

	ngAfterViewInit(): void {
		this.focus = this.autoFocus;
	}
}
