import { Component, OnInit } from '@angular/core';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { SuppressionRule } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { TitleService } from '../../../shared/services/title.service';
import { sccHostService } from '@wcd/scc-interface';

@Component({
	selector: 'suppression-rule-entity',
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<tabs
				*ngIf="tabs"
				[tabsData]="tabs"
				[currentRouteIsActive]="true"
				class="wcd-flex-0 wcd-padding-medium-all"
				[class.wcd-responsive-shell-padding-none-left-sm-screens]="isScc"
			></tabs>
			<div class="wcd-flex-1">
				<router-outlet></router-outlet>
			</div>
		</div>
	`,
})
export class SuppressionRuleEntityComponent extends EntityComponentBase<SuppressionRule> implements OnInit {
	tabs: Array<TabModel> = [];
	isScc = sccHostService.isSCC;

	constructor(
		private i18nService: I18nService,
		private readonly titleService: TitleService,
	) {
		super();
	}

	ngOnInit() {
		this.titleService.setState({
			pageTitle: this.i18nService.get('suppressionRules.page.title', { name: this.entity.name }),
		});

		this.tabs = [
			{
				id: 'associatedAlerts',
				name: this.i18nService.get('suppressionRules.tabs.alerts'),
				routerLink: './alerts',
			},
			{
				id: 'conditions',
				name: this.i18nService.get('suppressionRules.tabs.conditions'),
				routerLink: './conditions',
			},
		].map(tab => new TabModel(tab));
	}
}
