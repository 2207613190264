import { ModuleWithProviders } from '@angular/core';
import { Routes } from '@angular/router';
import { MdeAllowedActionsGuard, AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard } from '@wcd/auth';
import { AirsEnabledGuard } from '@wcd/config';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { InvestigationEntityTypeService } from './services/investigation.entity-type.service';
import { InvestigationsService } from './services/investigations.service';
import { InvestigationsBackendService } from './services/investigations.backend.service';
import { InvestigationsDataviewSelectorComponent } from './components/investigations-dataview-selector';
import { Investigation, MdeUserRoleActionEnum } from '@wcd/domain';
var investigationsPageTitle = 'Automated Investigations';
var ɵ0 = {
    entity: Investigation,
    breadcrumbsConfig: {
        resetListOnEnter: true,
        show: false,
    },
    pageTitle: investigationsPageTitle,
    pageTitleIcon: 'investigation',
    enableAirsRoute: [
        '/enable_airs',
        {
            title: investigationsPageTitle,
            messageClass: 'relative enable-auto-ir-investigation-message',
        },
    ],
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
};
var investigationsRoutes = [
    {
        path: 'investigations',
        canActivate: [
            AuthenticatedGuard,
            OnboardGuard,
            FeatureEnabledGuard,
            AirsEnabledGuard,
            MdeAllowedActionsGuard,
        ],
        component: InvestigationsDataviewSelectorComponent,
        data: ɵ0,
    },
];
var InvestigationsModule = /** @class */ (function () {
    function InvestigationsModule(globalEntityTypesService) {
        globalEntityTypesService.registerEntityType(InvestigationEntityTypeService);
    }
    InvestigationsModule.forRoot = function () {
        return {
            ngModule: InvestigationsModule,
            providers: [InvestigationsBackendService, InvestigationsService, InvestigationEntityTypeService],
        };
    };
    return InvestigationsModule;
}());
export { InvestigationsModule };
export { ɵ0 };
