import { Entity, EntityField, EntityModelBase, ParisConfig } from '@microsoft/paris';
import { ParamBackendConfig } from './param-backend-config.interface';
import { LiveResponseCommandOutput } from './live-response-command-output.value-object';
import { LiveResponseCommandProgress } from './live-response-command-progress.value-object';
import { isNil } from 'lodash-es';
import { LiveResponseCommandStatus } from './live-response-command-status.entity';

@Entity({
	singularName: 'Command',
	pluralName: 'Commands',
	baseUrl: (config, query) => query && query.where && query.where['useV3Api'] ? config.data.serviceUrls.k8s : config.data.serviceUrls.automatedIr,
	endpoint: (config, query) => {
		if(query && query.where && query.where['useV3Api']) {
			return 'cloud/live_response/commands'
		}

		return query && query.where && query.where['useV2Api']
			? 'v2/live_response/commands'
			: 'live_response/commands'
	},
	parseSaveQuery: item =>
		item.useV3Api ? 'cloud/live_response/create_command' : item.useV2Api ? 'v2/live_response/create_command' : 'live_response/create_command',
	serializeItem: (command: LiveResponseCommand) => ({
		session_id: command.sessionId,
		command_definition_id: command.commandTypeId,
		params:
			command.params &&
			command.params.map(p => ({
				param_id: p.paramId,
				value: p.value,
			})),
		flags: command.flags,
		raw_command_line: command.rawCommand,
		current_directory: command.currentDirectory,
		background_mode: command.runInBackground,
	}),
})
export class LiveResponseCommand extends EntityModelBase<number | string> {
	@EntityField({ data: 'command_id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number | string;

	@EntityField({ data: 'created_time' })
	startTime: Date;

	@EntityField({ data: 'completed_on' })
	endTime: Date;

	@EntityField({ data: 'duration_seconds' })
	private _duration: number;

	@EntityField({ data: 'entities_count' })
	entityCount: number;

	@EntityField({ data: 'session_id' })
	sessionId: number;

	@EntityField({ data: 'command_definition_id' })
	commandTypeId: string;

	@EntityField() status: LiveResponseCommandStatus;

	@EntityField() flags: Array<string>;

	@EntityField() params: Array<ParamBackendConfig>;

	@EntityField({ data: 'background_mode' })
	runInBackground: boolean;

	@EntityField({ data: 'raw_command_line' })
	rawCommand: string;

	@EntityField() errors: Array<string | { message: string; hresult: number }>;

	@EntityField({
		parse: (outputs, itemData) => {
			if (outputs) {
				return outputs;
			}

			const output = itemData['output'];
			if (output) {
				return [{ data_type: 'object', data: output }];
			}
		},
		arrayOf: LiveResponseCommandOutput,
	})
	outputs: Array<LiveResponseCommandOutput<any>>;

	@EntityField({
		parse: progress => (typeof progress === 'number' ? undefined : progress),
		arrayOf: LiveResponseCommandProgress,
	})
	progress?: Array<LiveResponseCommandProgress>;

	@EntityField({
		data: 'progress',
		parse: progress => (typeof progress === 'number' ? progress : undefined),
	})
	private _progressPercent?: number;

	@EntityField({ data: 'current_directory' })
	currentDirectory: string;

	@EntityField({ data: ['output.current_directory', 'context.current_directory'] })
	newCurrentDirectory: string;

	@EntityField({ data: ['output.download_token', 'context.download_token'] })
	downloadToken: string;

	@EntityField({ data: 'context.download_url' })
	downloadUrl: string;

	@EntityField({ data: ['output.download_file_name', 'context.download_file_name'] })
	downloadFileName: string;

	@EntityField({ data: 'powershell_transcript' })
	powershellTranscript: string;

	@EntityField({ data: 'session_id', parse: fieldData => String(fieldData).startsWith('LR') })
	useV2Api: boolean;

	@EntityField({ data: 'session_id', parse: fieldData => String(fieldData).startsWith('CLR') })
	useV3Api: boolean;

	get duration(): number {
		return this._duration || ((this.endTime || new Date()).valueOf() - this.startTime.valueOf()) / 1000;
	}

	get currentProgress(): LiveResponseCommandProgress {
		return (this.progress && this.progress.length && this.progress[this.progress.length - 1]) || null;
	}

	get progressPercent(): number {
		return !isNil(this._progressPercent)
			? this._progressPercent
			: this.currentProgress && !isNil(this.currentProgress.progressPercent)
			? this.currentProgress.progressPercent
			: null;
	}
}
