import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { LegacyUser } from '../legacy/user/legacy-user.entity';

@ValueObject({
	singularName: 'Device User Details',
	pluralName: 'Device User Details',
})
export class MachineUserDetails extends ModelBase {
	@EntityField({ data: 'LastSeenUser' })
	lastSeenUser: LegacyUser;

	@EntityField({ data: 'MostPrevalentUser' })
	mostPrevalentUser: LegacyUser;

	@EntityField({ data: 'LeastPrevalentUser' })
	leastPrevalentUser: LegacyUser;

	@EntityField({ data: 'LoggedOnUsersCount' })
	loggedOnUsersCount: number;

	@EntityField({ data: 'InteractiveUsersCount' })
	interactiveUsersCount: number;

	@EntityField({ data: 'RemoteUsersCount' })
	remoteUsersCount: number;

	@EntityField({ data: 'InteractiveOrRemoteUsersCount' })
	interactiveOrRemoteUsersCount: number;

	@EntityField({ data: 'OtherUsersCount' })
	otherUsersCount: number;

	get hasUsers(): boolean {
		return this.loggedOnUsersCount !== 0;
	}

	get isSingleUser(): boolean {
		return (
			this.mostPrevalentUser &&
			(!this.leastPrevalentUser ||
				(this.mostPrevalentUser.accountDomainName === this.leastPrevalentUser.accountDomainName &&
					this.mostPrevalentUser.accountName === this.leastPrevalentUser.accountName))
		);
	}
}
