import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
import { DetectionSource } from '../alert/sources/detection-source.entity';
import { ExploitGuardInnerRule } from './exploit_guard/exploit-guard-inner-rule.value-object';
import { exploitGuardInnerRules } from './exploit_guard/exploit-guard-inner-rule.values';
import { detectionSourceValues } from '../alert/sources/detection-source.entity.values';
import { CommandLine } from '../entity/command-line.value-object';
import { AccessTokenModificationDetails } from '../entity/access-token-modification-details.value-object';
import { ScheduledTask } from '../entity/scheduled-task.value-object';
import { UsbEvent } from '../entity/usb-event.value-object';

@ValueObject({
	singularName: 'Cyber event additional fields',
	pluralName: 'Cyber event additional fields',
	readonly: true,
})
export class CyberEventAdditionalFields extends ModelBase {
	@EntityField({
		data: 'TokenModificationProperties',
		parse: fieldData => {
			if (!fieldData) return null;
			let parsedData;
			try {
				parsedData = JSON.parse(fieldData);
			} catch (e) {
				console.warn(`Failed to parse TokenModificationProperties json. value: ${fieldData}`);
			}
			return parsedData;
		},
	})
	accessTokenDetails?: AccessTokenModificationDetails;

	@EntityField({
		data: '__self',
		parse: rawData => {
			return rawData.Command ? rawData : null;
		},
	})
	command?: CommandLine;

	@EntityField({
		data: '__self',
		require: rawData => rawData.TaskName,
	})
	scheduledTask?: ScheduledTask;

	@EntityField({ data: 'Description' })
	description?: string;

	@EntityField({
		data: 'ReportSource',
		parse: reportSource => {
			return detectionSourceValues.find(source => source.type === reportSource);
		},
	})
	detectionSource?: DetectionSource;

	@EntityField({ data: 'Experience' })
	experience?: string;

	@EntityField({
		data: 'RuleId',
		parse: ruleId => {
			return exploitGuardInnerRules.find(rule => rule.id === ruleId) || { id: ruleId };
		},
	})
	exploitGuardInnerRule?: ExploitGuardInnerRule;

	@EntityField({
		data: 'Profiles',
		parse: profiles => {
			return profiles ? (Array.isArray(profiles) ? profiles : profiles.split(',')) : null;
		},
	})
	firewallProfiles?: Array<string>;

	@EntityField({ data: 'RemoteAddress' })
	remoteAddress?: string;

	@EntityField({ data: 'RemotePort' })
	remotePort?: string;

	@EntityField({ data: 'ThreatName' })
	threatName?: string;

	@EntityField({ data: 'WasRemediated' })
	wasRemediated?: boolean;

	@EntityField({ data: 'WasExecutingWhileDetected' })
	wasExecutingWhileDetected?: boolean;

	@EntityField({ data: 'ResponseCategory' })
	responseCategory?: string;

	@EntityField({ data: '__self' })
	usbEvent?: UsbEvent;

	@EntityField({ data: 'Uri' })
	uri?: string;
}
