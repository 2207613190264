export var SccRoles;
(function (SccRoles) {
    SccRoles["searchAndPurge"] = "ucc:SearchAndPurge///UserPermissions:MDO:microsoft.xdr/secops/securitydata/emailadvancedactions/manage";
    SccRoles["enableSecurityGeneralSettings"] = "setting:PlatformClient$EnableSecurityGeneralSettings";
    SccRoles["hasMtpViewPermission"] = "mtprbac:MTP:UserViewPermissions";
    SccRoles["mtpEligible"] = "mtprbac:MTP:Eligible";
    SccRoles["hasMtpOnboardingPermission"] = "mtprbac:MTP:UserOnboardingPermissions";
    SccRoles["mdatpLicenseExpired"] = "mtprbac:Mdatp:LicenseExpired";
    SccRoles["hasUnifiedUserPage"] = "mtprbac:Feature:UnifiedUserPage";
    SccRoles["hasUnifiedUserPane"] = "mtprbac:Feature:UnifiedUserPane";
    SccRoles["mdatpWorkloadActive"] = "mtprbac:workload:Mdatp:Active";
    SccRoles["hasMtpUrbacReadPermissions"] = "mtpurbac:HasConfigurationReadPermissions";
    SccRoles["hasMtpUrbacManagePermissions"] = "mtpurbac:HasConfigurationManagePermissions";
    SccRoles["isAadAdmin"] = "IsAadCompanyAdmin,IsAadSecurityAdmin";
})(SccRoles || (SccRoles = {}));
/**
 * Manages SCC Roles
 */
var SccRolesService = /** @class */ (function () {
    function SccRolesService(sccAuth, sccResource) {
        var _this = this;
        this.sccAuth = sccAuth;
        this.sccResource = sccResource;
        this.rolesDictionary = {};
        this.roles = Object.keys(SccRoles).map(function (key) { return SccRoles[key]; });
        this.loadRoles();
        this.inRoleAsyncPromise = new Promise(function (resolve) {
            _this.promiseResolver = resolve;
        });
    }
    SccRolesService.prototype.loadRoles = function () {
        var _this = this;
        if (this.sccAuth) {
            this.sccAuth.isInRoles(this.roles).then(function (values) {
                _this.rolesDictionary = _this.roles.reduce(function (dic, name, index) {
                    dic[name] = values[index];
                    return dic;
                }, _this.rolesDictionary);
            });
        }
        if (this.sccResource) {
            this.sccResource.load('cas/config')
                .then(function (_a) {
                var userPageEnabledPromise = _a.userPageEnabledPromise, userProfilePaneEnabledPromise = _a.userProfilePaneEnabledPromise;
                userPageEnabledPromise.then(function (userPageEnabled) {
                    if (userPageEnabled) {
                        _this.rolesDictionary[SccRoles.hasUnifiedUserPage] = true;
                    }
                });
                userProfilePaneEnabledPromise.then(function (userProfileEnabled) {
                    if (userProfileEnabled) {
                        _this.rolesDictionary[SccRoles.hasUnifiedUserPane] = true;
                    }
                    _this.promiseResolver();
                });
            });
        }
    };
    SccRolesService.prototype.inRole = function (role) {
        return this.rolesDictionary && this.rolesDictionary[role];
    };
    ;
    //The inRole method is a synchronized function that depends on an async data, which is a bad design
    // adding this to solve a blocker bug, This needs to be refactored.
    SccRolesService.prototype.inRoleAsync = function (role) {
        var _this = this;
        return this.inRoleAsyncPromise.then(function () {
            return _this.inRole(role);
        });
    };
    return SccRolesService;
}());
export { SccRolesService };
