import { DataviewField } from '@wcd/dataview';
import { Lazy } from '../../../utils/lazy';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { FabricIconNames } from '@wcd/scc-common';
import { I18nService } from '@wcd/i18n';
var ApplicationFieldsService = /** @class */ (function () {
    function ApplicationFieldsService(i18nService) {
        var _this = this;
        this.i18nService = i18nService;
        this._fields = new Lazy(function () {
            var fields = [
                {
                    id: 'name',
                    name: _this.i18nService.strings.incident_apps_field_appName,
                    className: EntityNameComponent.entityNameDefaultCssClass,
                    icon: { fabricIcon: FabricIconNames.Cloud },
                    component: {
                        type: EntityNameComponent,
                        getProps: function (entity) { return ({
                            entity: entity,
                            entityTypeId: 'app',
                            entityName: entity.name,
                        }); },
                    },
                },
                {
                    id: 'id',
                    name: _this.i18nService.strings.incident_apps_field_applicationId,
                    className: EntityNameComponent.entityNameDefaultCssClass,
                    getDisplay: function (application) { return application.id; },
                    sort: { enabled: false },
                },
                {
                    id: 'applicationClientId',
                    name: _this.i18nService.strings.incident_apps_field_applicationClientId,
                    className: EntityNameComponent.entityNameDefaultCssClass,
                    getDisplay: function (application) { return application.applicationClientId; },
                    sort: { enabled: false },
                },
                {
                    id: 'risk',
                    name: _this.i18nService.strings.incident_apps_field_risk,
                    getDisplay: function (app) {
                        return app.riskLevel && _this.i18nService.get("incident.apps.field.risk." + app.riskLevel);
                    },
                    className: 'nowrap',
                    getCssClass: function (app) {
                        return app.riskLevel
                            ? "wcd-severity wcd-severity-" + app.riskLevel.toLocaleLowerCase()
                            : 'disabled';
                    },
                },
                {
                    id: 'activeAlertCount',
                    name: _this.i18nService.strings.incident_apps_field_incidentAlerts,
                    getDisplay: function (app) {
                        return app.totalAlerts ? app.activeAlerts + " / " + app.totalAlerts : null;
                    },
                    getTooltip: function (app) { return _this.i18nService.get('incident_apps_field_incidentAlerts_tooltip', app); },
                    useDefaultEmptyFieldComponent: true,
                },
                {
                    id: 'publisher',
                    name: _this.i18nService.strings.incident_apps_field_publisher,
                    className: EntityNameComponent.entityNameDefaultCssClass,
                    getDisplay: function (application) { return application.publisher; },
                    sort: { enabled: false },
                },
            ];
            return DataviewField.fromList(fields);
        });
    }
    Object.defineProperty(ApplicationFieldsService.prototype, "fields", {
        get: function () {
            return this._fields.value;
        },
        enumerable: true,
        configurable: true
    });
    return ApplicationFieldsService;
}());
export { ApplicationFieldsService };
