
		<label *ngIf="label" for="{{ id }}" [ngClass]="labelClass">{{ label }}</label>
		<input *ngIf="allowEdit; else textDisplay"
			type="text"
			[ngClass]="inputClass"
			id="{{ id }}"
			name="{{ id }}"
			[(ngModel)]="text"
			(ngModelChange)="onTextChange($event)"/>

		<ng-template #textDisplay>
			<p id="{{ id }}" [ngClass]="textClass">{{ text }}</p>
		</ng-template>