import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { SerializedFilters } from '@wcd/ng-filters';
import { Observable } from 'rxjs';
import { PanelType } from '@wcd/panels';
import {
	WebThreatProtection,
	WebThreatProtectionData,
	WebThreatHealthType,
	WebThreatDimensionKeyType,
	WebThreatProtectionHealthStatusApiCall,
	WebThreatStatusSummary,
	WebThreatStatusSummaryApiCall,
	WebContentFilteringLicenseStatus,
	WebContentFilteringLicenseStatusEntity,
} from '@wcd/domain';
import { TzDateService } from '@wcd/localization';
import { ChartSettings } from '@wcd/charts';
import { ReportsService } from '../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { mapValues, mapKeys, maxBy } from 'lodash-es';
import { KnownColor } from '../../shared/models/colors/known-colors.models';
import { I18nService } from '@wcd/i18n';
import { RbacService } from '../../rbac/services/rbac.service';
import { ChartType } from '../reporting.types';
import { DialogsService } from '../../dialogs/services/dialogs.service';

const MAX_LABELS = 4;

@Injectable()
export class WebThreatProtectionReportService {
	constructor(
		private paris: Paris,
		private tzDateService: TzDateService,
		private prettyNumberService: PrettyNumberService,
		private i18nService: I18nService,
		private rbacService: RbacService,
		private dialogsService: DialogsService
	) {}

	getWebThreatByHealthStatusData(lookBackInDays: string): Observable<WebThreatProtection> {
		const params = {
			lookBackInDays: lookBackInDays,
		};

		return this.paris.apiCall(WebThreatProtectionHealthStatusApiCall, params);
	}

	getLatestWebThreatStatusSummary(): Observable<WebThreatStatusSummary> {
		return this.paris.apiCall(WebThreatStatusSummaryApiCall);
	}

	// TODO: Look into making common functions since reports (machine and web-threat) both make this kind of call
	getSettingsOverTime<T extends WebThreatReportDimension>(
		dimensionType: WebThreatReportDimensionType,
		dimensionMapping: ReportMapping<T>,
		data?: WebThreatProtection
	): ChartSettings {
		const dimensionData = data && data.data && data.data.length && data.data;

		const maxWebThreatCount: number =
			dimensionData &&
			dimensionData.reduce(
				(maxWebThreatCount: number, data: WebThreatProtectionData) =>
					Math.max(maxBy(Object.values(data.values)), maxWebThreatCount),
				0
			);

		return {
			options: {
				data: {
					columns: this.getDimensionData(dimensionType, dimensionData, dimensionMapping),
					colors: mapValues(
						mapKeys(dimensionMapping, (value) => value.label),
						(value) => value.knownColorsMap.trend.raw
					),
				},
				axis: {
					y: {
						max: maxWebThreatCount ? maxWebThreatCount : 0,
						min: 0,
						tick: {
							values: dimensionData ? ReportsService.getYTicks(maxWebThreatCount) : [],
							format: (value) => this.prettyNumberService.prettyNumber(value),
						},
						padding: {
							bottom: 0,
						},
					},
					x: {
						type: 'category',
						categories: this.getDimensionDates(dimensionData),
						tick: {
							multiline: false,
							culling: {
								max: dimensionData
									? Math.min(dimensionData.length + 1, MAX_LABELS)
									: MAX_LABELS,
							},
						},
					},
				},
				point: {
					show: false,
				},
				grid: {
					y: {
						show: true,
					},
				},
				legend: {
					show: false,
				},
				size: {
					height: 200,
				},
			},
			disableCursor: true,
		};
	}

	getSettingsDaily<T extends WebThreatReportDimension>(
		type: ChartType,
		dimensionMapping: ReportMapping<T>,
		data?: WebThreatProtection,
		getDimensionFilterData?: (dimensionKey: WebThreatDimensionKeyType) => DimensionFilterData
	): ChartSettings {
		const dimensionData = data && data.data && data.data.length && data.data[0];
		const maxCount: number =
			dimensionData && dimensionData.values && maxBy(Object.values(dimensionData.values));
		const chartData = Object.entries<WebThreatReportDimensionMapping>(
			dimensionMapping
		).map(([key, value]) => this.getDimensionDataDaily(value.label, key, dimensionData));

		const colorsPattern = Object.entries<WebThreatReportDimensionMapping>(dimensionMapping).map(
			([key, value]) => value.knownColorsMap.daily.raw
		);

		return {
			options: {
				color: {
					pattern: colorsPattern,
				},
				data: {
					columns: [
						[
							this.i18nService.get('reporting.webThreatReport.dailyTooltipDescription'),
							...chartData.map((data) => data.value),
						],
					],

					groups: [
						Object.entries<WebThreatReportDimensionMapping>(dimensionMapping).map(
							([key, value]) => value.label
						),
					],
					type: 'bar',
					labels: {
						format: (value) => (maxCount > 0 ? this.prettyNumberService.prettyNumber(value) : ''), //c3 labels issue when all labels are zero, the labels are at a wrong position
					},

					color: (inColor, data) => {
						if (data.index !== undefined) {
							return colorsPattern[data.index];
						}
						return inColor;
					},
				},
				tooltip: {
					grouped: false,
					contents: (data) =>
						`<table class="c3-tooltip">
							<tbody>
								<tr>
									<td class="name">${data[0].value}</td>
								</tr>
							</tbody>
						</table>`,
				},
				bar: {
					width: {
						max: 8,
						ratio: 0.2,
					},
				},
				axis: {
					rotated: type === ChartType.Horizontal,
					x: {
						type: 'category',
						categories: chartData.map((data) => data.label),
						tick: {
							multiline: type === ChartType.Horizontal,
							culling: {
								max: 1,
							},
							rotate: 35,
						},
						padding: { right: 0.5 },
					},
					y: {
						show: true,
						max: maxCount ? maxCount : 0,
						min: 0,
						tick: {
							values: data ? ReportsService.getYTicks(maxCount) : [],
							format: (value) => this.prettyNumberService.prettyNumber(value),
						},
						padding: {
							bottom: 0,
						},
					},
				},
				padding: {
					left: type === ChartType.Horizontal ? 100 : 50,
				},
				grid: {
					y: {
						show: true,
					},
				},
				point: {
					show: false,
				},
				legend: { show: false },
				size: {
					height: 200,
				},
			},
		};
	}

	private getDimensionDates(dimensionData: Array<WebThreatProtectionData>): Array<string> {
		return dimensionData
			? dimensionData.map((data) => this.tzDateService.format(data.date, 'MM/dd'))
			: [];
	}

	private getDimensionData<T extends WebThreatReportDimension>(
		type: WebThreatReportDimensionType,
		dimensionData: Array<WebThreatProtectionData>,
		dimensionMapping: ReportMapping<T>
	): (string | number | boolean)[][] {
		return Object.keys(type).map((key) =>
			this.getDimensionDataOvertime(dimensionMapping[key].label, key, dimensionData)
		);
	}

	private getDimensionDataOvertime(
		label: string,
		dimensionType: string,
		dimensionData: Array<WebThreatProtectionData>
	) {
		return [
			label,
			...(dimensionData &&
				dimensionData.map((data) => (data.values[dimensionType] ? data.values[dimensionType] : 0))),
		];
	}

	private getDimensionDataDaily(
		label: string,
		dimensionType: string,
		dimensionData: WebThreatProtectionData
	) {
		return {
			label: label,
			value:
				dimensionData &&
				(dimensionData.values[dimensionType] ? dimensionData.values[dimensionType] : 0),
		};
	}
}

export interface WebThreatReportDimensionMapping {
	knownColorsMap: {
		daily: KnownColor;
		trend: KnownColor;
	};
	label: string;
	webThreatListFilter: string;
	priority?: number;
}

export declare type WebThreatReportDimension = WebThreatHealthType;
export declare type WebThreatReportDimensionType = typeof WebThreatHealthType;

export type ReportMapping<T extends WebThreatDimensionKeyType> = Record<T, WebThreatReportDimensionMapping>;

export interface WebThreatListFilters {
	webThreatTypes?: string;
}

export interface DimensionFilterData {
	currentFilters: SerializedFilters;
	type: string;
	dimensionFilter: string;
}

export enum WebThreatType {
	Phishing = 'Phishing',
	Malicious = 'Malicious',
	CustomIndicator = 'CustomBlockList',
	Unknown = 'Unknown',
}
