import {
	DataQuery,
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
} from '@microsoft/paris';
import { SecurityRecommendation } from './security-recommendation.entity';
import { Asset } from '../asset/asset.entity';
import { RecommendationContextKeyAndExposedMachinesCount } from '../recommendation-context/recommendation-context-key-and-exposed-machines-count.entity';
import { RecommendationContextProperties } from '../recommendation-context/recommendation-context-properties.entity';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
import {
	TvmAnalyticsSharedEntityRelationshipConfigurations,
	FilterByNameInsteadOfSearch,
} from '../analyticsEndPointUtils';
import { MitigatedVulnerability } from '../weakness/vulnerability/mitigated-vulnerability.entity';
import { SoftwareInstallationAgg } from '../software-installation/software-installation-agg-versions.entity';
import { NetworkDevice } from '../networks-devices/network-device.entity';
import { ExposedOperatingSystemsStatistics } from '../common/recommendation/exposed-operating-systems-statistics.value-object';
import { EvidenceDistribution } from '../evidence/evidence-distribution.entity';
import {
	AssetRecommendation,
	AssetRecommendationContext,
	TvmRemediationSharedEntityConfigurations,
	AddODataFilterQueryParam,
	ParseDataQuery,
	RecommendationExceptionByIdMap,
	RecommendationException,
	RemediationTask,
	RemediationTaskByIdMap,
} from '@wcd/domain';
import { SoftwareInstallationNetworkDevice } from '../software-installation/software-installation-network-device.entity';

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: SecurityRecommendation,
	dataEntity: ExposedOperatingSystemsStatistics,
	endpoint: () => `recommendations/recommendation/operatingSystems`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (securityRecommendation: SecurityRecommendation) => ({
		recommendationId: securityRecommendation.id,
	}),
	parseDataQuery: (dataQuery: DataQuery) => ParseDataQuery(dataQuery, undefined, true),
})
export class RecommendationExposedOperatingSystemsRelationship
	implements EntityRelationshipRepositoryType<SecurityRecommendation, ExposedOperatingSystemsStatistics> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: SecurityRecommendation,
	dataEntity: Asset,
	endpoint: (_, query) => {
		if (
			query.where['extraQueryData'] &&
			query.where['extraQueryData'].isRecommendationContextNeededInCSV
		) {
			return `recommendations/recommendation/assetsFlattenWithContextItem`;
		}
		return `recommendations/recommendation/assets`;
	},
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (securityRecommendation: SecurityRecommendation) => {
		return { recommendationId: securityRecommendation.id };
	},
	parseDataQuery: (dataQuery: DataQuery) => {
		// when searching for recommendation assets we want to use OData filters
		dataQuery = FilterByNameInsteadOfSearch(dataQuery);

		return ParseDataQuery(dataQuery);
	},
})
export class RecommendationExposedAssetsRelationship
	implements EntityRelationshipRepositoryType<SecurityRecommendation, Asset> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: SecurityRecommendation,
	dataEntity: EvidenceDistribution,
	endpoint: () => 'recommendations/recommendation/evidenceDistribution',
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (securityRecommendation: SecurityRecommendation) => {
		return { recommendationId: securityRecommendation.id };
	},
})
export class RecommendationEvidenceDistributionRelationship
	implements EntityRelationshipRepositoryType<SecurityRecommendation, EvidenceDistribution> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: SecurityRecommendation,
	dataEntity: RecommendationContextKeyAndExposedMachinesCount,
	endpoint: (_, query) => {
		return `configurations/${query.where['scId']}/context/assetsCountByKey`;
	},
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (securityRecommendation: SecurityRecommendation) => {
		return { scId: securityRecommendation.scId };
	},
})
export class RecommendationContextKeyAndExposedMachinesCountRelationship
	implements
		EntityRelationshipRepositoryType<
			SecurityRecommendation,
			RecommendationContextKeyAndExposedMachinesCount
		> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: RecommendationContextKeyAndExposedMachinesCount,
	dataEntity: RecommendationContextProperties,
	endpoint: (_, query) => {
		return `configurations/${query.where['scId']}/context/${query.where['contextKey']}/assets`;
	},
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (recommendationContextKey: RecommendationContextKeyAndExposedMachinesCount) => {
		return { scId: recommendationContextKey.scId, contextKey: recommendationContextKey.contextKey };
	},
})
export class RecommendationContextPropertiesRelationship
	implements
		EntityRelationshipRepositoryType<
			RecommendationContextKeyAndExposedMachinesCount,
			RecommendationContextProperties
		> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: AssetRecommendation,
	dataEntity: AssetRecommendationContext,
	endpoint: (_, query) => {
		return `configurations/${query.where['scId']}/assets/${query.where['assetId']}/context`;
	},
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (assetRecommendation: AssetRecommendation) => {
		return { scId: assetRecommendation.scId, assetId: assetRecommendation.assetId };
	},
})
export class AssetRecommendationContextRelationship
	implements EntityRelationshipRepositoryType<AssetRecommendation, AssetRecommendationContext> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: AssetRecommendation,
	dataEntity: EvidenceDistribution,
	endpoint: (_, query) => {
		return `recommendations/recommendation/assets/${query.where['assetId']}/evidenceDistribution`;
	},
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (ed: AssetRecommendation) => {
		return { recommendationId: ed.id, assetId: ed.assetId };
	},
})
export class AssetRecommendationEvidenceDistributionRelationship
	implements EntityRelationshipRepositoryType<AssetRecommendation, EvidenceDistribution> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: SecurityRecommendation,
	dataEntity: SoftwareInstallationAgg,
	endpoint: () => `products/product/installations/aggregate`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (securityRecommendation: SecurityRecommendation) => {
		return { productId: securityRecommendation.productId };
	},
})
export class RecommendationInstalledAssetsRelationship
	implements EntityRelationshipRepositoryType<SecurityRecommendation, SoftwareInstallationAgg> {}

@EntityRelationship({
	sourceEntity: SecurityRecommendation,
	dataEntity: Vulnerability,
	endpoint: (_, query) =>
		query.where['assetId']
			? `assets/${encodeURIComponent(query.where['assetId'])}/recommendations/vulnerabilities`
			: `products/product/vulnerabilities`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (recommendation: SecurityRecommendation | AssetRecommendation) => {
		return {
			productId: recommendation.productId,
			assetId: recommendation instanceof AssetRecommendation ? recommendation.assetId : undefined,
		};
	},
	parseDataQuery: (dataQuery: DataQuery) => ParseDataQuery(dataQuery),
})
export class RecommendationRelatedVulnerabilitiesRelationship
	implements
		EntityRelationshipRepositoryType<SecurityRecommendation | AssetRecommendation, Vulnerability> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: SecurityRecommendation,
	dataEntity: MitigatedVulnerability,
	endpoint: (_, query) => `configurations/${query.where['id']}/mitigatedVulnerabilities`,
	getRelationshipData: (securityRecommendation: SecurityRecommendation) => {
		return { id: securityRecommendation.scId };
	},
	allowedTypes: [RelationshipType.OneToMany],
})
export class RecommendatioMitigatedVulnerabilitiesRelationship
	implements EntityRelationshipRepositoryType<SecurityRecommendation, MitigatedVulnerability> {}

@EntityRelationship({
	...TvmRemediationSharedEntityConfigurations,
	sourceEntity: SecurityRecommendation,
	dataEntity: RecommendationException,
	endpoint: () => `recommendationExceptions`,
	parseDataQuery: dataQuery => {
		const queryParams = {};
		AddODataFilterQueryParam(dataQuery, queryParams, RecommendationExceptionByIdMap);
		return queryParams;
	},
	getRelationshipData: (securityRecommendation: SecurityRecommendation) => {
		return {
			id: securityRecommendation.scId
				? securityRecommendation.scId
				: `${securityRecommendation.productId}-_-${securityRecommendation.remediationType}`,
		};
	},
	allowedTypes: [RelationshipType.OneToMany],
})
export class RecommendationToExceptionsRelationship
	implements EntityRelationshipRepositoryType<SecurityRecommendation, RecommendationException> {}

@EntityRelationship({
	...TvmRemediationSharedEntityConfigurations,
	sourceEntity: SecurityRecommendation,
	dataEntity: RemediationTask,
	endpoint: (_, _query) => `remediationTasks`,
	parseDataQuery: dataQuery => {
		const queryParams = {};
		AddODataFilterQueryParam(dataQuery, queryParams, RemediationTaskByIdMap);
		return queryParams;
	},
	getRelationshipData: (securityRecommendation: SecurityRecommendation) => ({
		id: securityRecommendation.scId ? securityRecommendation.scId : securityRecommendation.productId,
	}),
	allowedTypes: [RelationshipType.OneToMany],
})
export class RecommendationToRemediationRelationship
	implements EntityRelationshipRepositoryType<SecurityRecommendation, RemediationTask> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: SecurityRecommendation,
	dataEntity: NetworkDevice,
	endpoint: () => `recommendations/recommendation/networkDevices`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (securityRecommendation: SecurityRecommendation) => ({
		recommendationId: securityRecommendation.id,
	}),
})
export class RecommendationExposedNetworkDeviceRelationship
	implements EntityRelationshipRepositoryType<SecurityRecommendation, NetworkDevice> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: SecurityRecommendation,
	dataEntity: SoftwareInstallationNetworkDevice,
	endpoint: () => `products/product/installations/networkDevices`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (securityRecommendation: SecurityRecommendation) => ({
		productId: securityRecommendation.productId,
	}),
})
export class RecommendationInstalledNetworkDevicesRelationship
	implements EntityRelationshipRepositoryType<SecurityRecommendation, SoftwareInstallationNetworkDevice> {}
