import { MailMessage } from '@wcd/domain';
import { Lazy } from '../../../utils/lazy';
import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { FabricIconNames } from '@wcd/scc-common';

@Injectable()
export class MailMessageEntityTypeService implements EntityTypeService<MailMessage> {
	private readonly _entityType = new Lazy<Readonly<EntityType<MailMessage>>>(() => ({
		entity: MailMessage,
		id: 'mailMessage',
		icon: FabricIconNames.Mail,
		getEntityName: MailMessage => MailMessage.name,
		getEntitiesLink: MailMessages => {
			if (!MailMessages || MailMessages.length !== 1) {
				return null;
			}

			const [mailMailMessage] = MailMessages;
			return mailMailMessage.mailMessagePageUrl;
		},
		getNavigationModel: (MailMessage: MailMessage) => ({
			externalLink: MailMessage.mailMessagePageUrl,
		}),
		getUseExternalRouting: (MailMessages: Array<MailMessage>) => true,
	}));

	get entityType(): Readonly<EntityType<MailMessage>> {
		return this._entityType.value;
	}
}
