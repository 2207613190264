<div class="tvm-impact-score wcd-flex-center-vertical wcd-font-size-m">
    <div
        *ngIf="hasExposureScore"
        class="wcd-flex-center-vertical"
        [wcdTooltip]="_scoreData.exposureScoreTooltip"
		[tooltipCssClass]="'tvm-impact-score-tooltip'"
    >
        <fab-icon
            iconName="CaretSolidDown"
            contentClass="wcd-font-size-mi impact-control-color"
        ></fab-icon>
        <div class="exposure-score wcd-margin-small-right">
            {{ exposureScore }}
        </div>
    </div>
    <div *ngIf="showSeparator" class="separator wcd-margin-small-right"></div>
    <div
        *ngIf="hasSecureScore"
        class="wcd-flex-center-vertical"
        [wcdTooltip]="_scoreData.secureScoreTooltip"
        [tooltipCssClass]="'tvm-impact-score-tooltip'"
    >
        <fab-icon
            iconName="CalculatorAddition"
            contentClass="wcd-font-size-mi impact-control-color"
        ></fab-icon>
        <div class="secure-score">
            {{ _scoreData?.secureScore.toFixed(2) }}
        </div>
    </div>
</div>
