import { Component, OnInit } from '@angular/core';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { HuntingRule } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { FeaturesService, Feature } from '@wcd/config';

@Component({
	selector: 'scheduled-hunting-entity',
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<tabs
				[tabsData]="tabs"
				[currentRouteIsActive]="true"
				class="wcd-flex-0 wcd-padding-medium-all"
			></tabs>
			<div class="wcd-flex-1">
				<router-outlet></router-outlet>
			</div>
		</div>
	`,
    styleUrls: ['./scheduled-hunting.entity.component.scss'],
})
export class ScheduledHuntingEntityComponent extends EntityComponentBase<HuntingRule> implements OnInit {
	tabs: Array<TabModel> = [];

	constructor(private i18nService: I18nService, private featuresService: FeaturesService) {
		super();
	}

	get rule(): HuntingRule {
		return this.entity;
	}

	ngOnInit() {
		this.tabs = this.getTabs(this.rule);
	}

	private getTabs(rule: HuntingRule): Array<TabModel> {
		return [
			{
				id: 'alerts',
				name: this.i18nService.get('scheduledHunting.tabs.alerts'),
				routerLink: './alerts',
			},
			{
				id: 'actions',
				name: this.i18nService.get('scheduledHunting.tabs.actions'),
				routerLink: './actions',
			},
		]
			.filter(
				tab => this.featuresService.isEnabled(Feature.HuntingCustomActions) || tab.id !== 'actions'
			)
			.map(tab => new TabModel(tab));
	}
}
