import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { EmailMetadata, GetEmailBodyApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { MessageBarType } from 'office-ui-fabric-react';
import { ErrorsDialogService } from '../../../dialogs/services/errors-dialog.service';
import { sccHostService } from '@wcd/scc-interface';

@Component({
	selector: 'email-download',
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<header
				class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
			>
				<div class="wcd-flex-1">
					<h3 class="side-panel-title" id="email-download-panel-header" #panelHeader>
						{{ i18nService.strings.airsEntities_emailMessage_downloadEmail_title }}
					</h3>
				</div>
			</header>
			<div class="wcd-padding-medium-left">
				<form>
					<div class="wcd-padding-large-vertical">
						<fab-message-bar [messageBarType]="messageBarType.warning">
							<div>
								{{ i18nService.strings.airsEntities_emailMessage_downloadEmail_warning }}
							</div>
						</fab-message-bar>
					</div>
					<wcd-checkbox
						[id]="'agreeToTerms'"
						[name]="'agreeToTerms'"
						[(ngModel)]="agreedToTerms"
						(ngModelChange)="changeDetectorRef.detectChanges()"
						(change)="changeDetectorRef.detectChanges()"
						[label]="i18nService.strings.airsEntities_emailMessage_downloadEmail_agree"
					>
					</wcd-checkbox>
					<fab-primary-button
						contentClass="wcd-margin-large-vertical"
						[iconProps]="{ iconName: 'Download' }"
						[disabled]="!agreedToTerms || isDownloading"
						(onClick)="downloadEmail()"
						[text]="i18nService.strings.airsEntities_emailMessage_downloadEmail_button"
					>
					</fab-primary-button>
				</form>
			</div>
		</wcd-panel>
	`,
})
export class DownloadEmailPanelComponent extends PanelContainer {
	@Input() emailMetadata: EmailMetadata;

	messageBarType = MessageBarType;
	isDownloading = false;
	agreedToTerms = false;

	constructor(
		router: Router,
		public i18nService: I18nService,
		private paris: Paris,
		private downloadService: DownloadService,
		private http: HttpClient,
		private errorsDialogService: ErrorsDialogService,
		public changeDetectorRef: ChangeDetectorRef
	) {
		super(router);
	}

	async downloadEmail() {
		this.isDownloading = true;
		this.changeDetectorRef.detectChanges();
		try {
			const recipient =
				this.emailMetadata &&
				this.emailMetadata.recipients &&
				this.emailMetadata.recipients.length &&
				this.emailMetadata.recipients[0];
			let EnableNewMailItemAPI = false;

			try {
				EnableNewMailItemAPI = await sccHostService.auth.isInRole(
					'setting:AntiSpamCHN$EnableNewMailItemAPI'
				);
			} catch (e) {}

			await this.paris
				.apiCall(GetEmailBodyApiCall, {
					internetMessageId: this.emailMetadata.internetMessageId,
					recipient: recipient,
					EnableNewMailItemAPI,
					// this field is always null in SCC
					// receivedTime: this.emailMetadata.receivedTime,
				})
				.toPromise();

			const fileName = `${this.emailMetadata.subject ||
				this.i18nService.strings.airsEntities_emailMessage_downloadEmail_noSubject}.eml`;

			await this.downloadService.download(
				// using Angular's http client because Paris doesn't pass the x-xsrf-token header
				this.http.post(
					'/api/Explorer/GetFullEmailMessage',
					{
						FileName: encodeURIComponent(fileName),
						InternetMessageId: this.emailMetadata.internetMessageId,
						Recepient: recipient,
						// this field is always null in SCC
						// ReceivedTime: this.emailMetadata.receivedTime,
					},
					{
						responseType: 'blob',
					}
				),
				fileName
			);
		} catch (e) {
			this.errorsDialogService.showError({
				title: this.i18nService.strings.common_error,
				data: this.i18nService.strings.airsEntities_emailMessage_downloadEmail_downloadError,
			});
			this.isDownloading = false;
			this.changeDetectorRef.detectChanges();
			return;
		}
		this.destroy();
		this.changeDetectorRef.detectChanges();
	}
}
