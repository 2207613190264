import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { isObservable, Observable, of, Subscription } from 'rxjs';
import { isPromise } from 'rxjs/internal-compatibility';
import { take } from 'rxjs/operators';
import { SpinnerSize } from 'office-ui-fabric-react';
import { NgClassInput } from '@wcd/angular-extensions';

import { ConfirmEvent } from './models/confirm-event.model';
import {
	ConfirmationDialogOptions,
	IConfirmationDialogContentComponent,
	IConfirmationDialogHandlers,
	TextOrTextWithOptions,
} from './models/confirmation-dialog-options.model';
import { isHandlersObject } from './utils/is-handlers-object';

@Component({
	selector: 'wcd-confirmation-dialog',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<fab-dialog
			[hidden]="hidden"
			[minWidth]="options.dimensions?.width || 400"
			[forceFocusInsideTrap]="false"
			[dialogContentProps]="{
				title: getText(options.title),
				styles: { title: getClassName(options.title) }
			}"
			[modalProps]="{
				isDarkOverlay: options.showOverlay,
				layerProps: { styles: { root: { zIndex: 9999 } } },
				isBlocking: options.isBlocking,
				styles: options.dimensions?.height && {
					main: { minHeight: options.dimensions.height }
				}
			}"
			(onDismiss)="cancelAndClose()"
		>
			<form (submit)="confirmAndClose($event)">
				<div
					*ngIf="getText(options.body) as bodyText"
					class="wcd-padding-bottom"
					[ngClass]="getClassName(options.body)"
				>
					<markdown [data]="bodyText"></markdown>
				</div>

				<ng-container #content></ng-container>

				<fab-dialog-footer>
					<div class="text-left">
						<fab-primary-button
							type="submit"
							[contentClass]="[getClassName(options.confirmButton), 'wcd-margin-small-right']"
							[disabled]="options.confirmDisabled || submitDisabled"
						>
							<fab-spinner
								*ngIf="submitInProgress"
								[size]="SpinnerSize.small"
								contentClass="wcd-margin-xsmall-right"
							>
							</fab-spinner>

							<div>
								<ng-container
									*ngIf="
										getText(options.confirmButton) as confirmText;
										else defaultConfirmText
									"
								>
									{{ confirmText }}
								</ng-container>

								<ng-template #defaultConfirmText>
								{{'buttons_confirm' | i18n}}
								</ng-template>
							</div>
						</fab-primary-button>

						<fab-default-button
							*ngIf="!hideCancelButton(options.cancelButton)"
							[text]="getText(options.cancelButton) || ('confirmation_dialog_cancel' | i18n)"
							[contentClass]="getClassName(options.cancelButton)"
							(onClick)="cancelAndClose()"
						>
						</fab-default-button>
					</div>
				</fab-dialog-footer>
			</form>
		</fab-dialog>
	`,
})
export class ConfirmationDialogComponent<TContentComponent extends IConfirmationDialogContentComponent, TData>
	implements AfterViewInit, OnDestroy {
	readonly SpinnerSize: typeof SpinnerSize = SpinnerSize;

	@Input() options: ConfirmationDialogOptions<TContentComponent, TData>;

	@Output() readonly onClosed = new EventEmitter<ConfirmEvent<TData>>(false);

	@ViewChild('content', { read: ViewContainerRef, static: false })
	readonly container: ViewContainerRef;

	get submitInProgress() {
		return this._submitInProgress;
	}

	set submitInProgress(value: boolean) {
		this._submitInProgress = value;
		this.changeDetectorRef.markForCheck();
	}

	get submitDisabled(): boolean {
		return this._submitDisabled;
	}

	set submitDisabled(value: boolean) {
		this._submitDisabled = value;
		this.changeDetectorRef.markForCheck();
	}

	/**
	 * Gets the hidden state of the dialog.
	 *
	 * @internal
	 * @description used for hiding the dialog as soon as possible in React,
	 * until Angular destroys this component, to give the user a fluent experience.
	 */
	get hidden() {
		return this._hidden;
	}

	set hidden(value: boolean) {
		this._hidden = value;
		this.changeDetectorRef.markForCheck();
	}

	private _submitDisabled: boolean;
	private _submitInProgress = false;
	private _hidden = false;
	private _currentContentComponentRef: ComponentRef<TContentComponent>;
	private _submitDisabledSubscription: Subscription;

	get contentComponent() {
		return this._currentContentComponentRef && this._currentContentComponentRef.instance;
	}

	private get handlers(): IConfirmationDialogHandlers<TContentComponent, TData> {
		if (this.options.handlers) {
			return this.options.handlers;
		}

		if (isHandlersObject<TContentComponent, TData>(this.contentComponent)) {
			return this.contentComponent;
		}

		return null;
	}

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly componentFactoryResolver: ComponentFactoryResolver
	) {}

	ngAfterViewInit() {
		if (this.options.contentComponent) {
			this._currentContentComponentRef = this.container.createComponent(
				this.componentFactoryResolver.resolveComponentFactory(this.options.contentComponent.type)
			);

			if (this.options.contentComponent.props) {
				Object.assign(this._currentContentComponentRef.instance, this.options.contentComponent.props);
			}

			const { isValid$ = of(true) } = this._currentContentComponentRef.instance;
			this._submitDisabledSubscription = isValid$.subscribe(isValid => {
				this.submitDisabled = !isValid;
			});

			this.changeDetectorRef.markForCheck();
		}
	}

	ngOnDestroy() {
		if (this._submitDisabledSubscription) {
			this._submitDisabledSubscription.unsubscribe();
		}
	}

	hideCancelButton(textOrOptions?: TextOrTextWithOptions): boolean {
		if (textOrOptions == null || typeof textOrOptions === 'string') {
			return false;
		}

		return textOrOptions.hide;
	}

	getText(textOrOptions?: TextOrTextWithOptions): string | null {
		if (textOrOptions == null) {
			return null;
		}

		return typeof textOrOptions === 'string' ? textOrOptions : textOrOptions.text;
	}

	getClassName(textOrOptions?: TextOrTextWithOptions): NgClassInput | null {
		if (textOrOptions == null || typeof textOrOptions !== 'object') {
			return null;
		}

		return textOrOptions.className;
	}

	async confirmAndClose(event: Event) {
		event.preventDefault();

		this.submitInProgress = true;

		try {
			const data = this.handlers && (await toPromise(this.handlers.onSubmit(this.contentComponent)));

			this.hidden = true;
			this.onClosed.emit({
				confirmed: true,
				data,
			});
		} catch (error) {
			this.submitInProgress = false;
		}
	}

	cancelAndClose() {
		this.hidden = true;
		this.onClosed.emit({
			confirmed: false,
		});
	}
}

export function toPromise<T>(value: T | PromiseLike<T> | Observable<T>): PromiseLike<T> {
	return isPromise(value)
		? value
		: isObservable(value)
		? value.pipe(take(1)).toPromise()
		: Promise.resolve(value);
}
