import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { LegendItem } from '@wcd/charts';
import {
	ColorMap,
	EventModel,
	EventsSummaryBarSize,
	KindTextFormatter,
} from '../events-summary-bar/events-summary-bar.component';

export class SummeryBarEvents<TKind extends string | number> {
	id?: string;
	events: ReadonlyArray<EventModel<TKind>>;
	title?: string;
	amount?: number;
	total?: number;
}
export class SummeryBarSetting<TKind extends string | number> {
	events: SummeryBarEvents<TKind>[];
	colorMap: ColorMap<TKind>;
	textFormatter?: KindTextFormatter<TKind>;
	size?: EventsSummaryBarSize;
}

@Component({
	selector: 'summery-bars-with-legend',
	templateUrl: './summery-bars-with-legend.component.html',
	styleUrls: ['./summery-bars-with-legend.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummeryBarsWithLegendComponent<TKind extends string | number> {
	@Input() settingsArray: SummeryBarSetting<TKind>;
	@Input() legendItems: LegendItem[];
	@Input() showLegend: boolean = true;
	@Output() onClick = new EventEmitter<any>();

	onItemClick(event: any) {
		this.onClick.emit(event);
	}
}
