import { ChangeDetectionStrategy, Component, Input, Injector, OnInit } from '@angular/core';
import {
	RecommendationContextKeyAndExposedMachinesCount,
	RecommendationContextProperties,
	RecommendationContextType,
} from '@wcd/domain';
import { RelationshipRepository } from '@microsoft/paris';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { SpinnerSize } from 'office-ui-fabric-react';
import { TvmContextOptions } from '../../../@entities/@tvm/vulnerabilities/components/vulnerability.entity-panel.component';
import { MessageBarType } from 'office-ui-fabric-react';
import { TvmTextsService } from '../../../tvm/services/tvm-texts.service';
import { FeaturesService, Feature } from '@wcd/config';
import { RecommendationContextFieldsService } from '../../../@entities/@tvm/security-recommendations/services/recommendation-context.fields.service';
import { DataviewField, DataViewConfig } from '@wcd/dataview';
import { Router } from '@angular/router';

@Component({
	selector: 'recommendation-context-properties-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './recommendation-context-properties.entity-details.component.html',
})
export class RecommendationContextPropertiesEntityDetailsComponent
	extends EntityDetailsComponentBase<RecommendationContextProperties>
	implements OnInit {
	private _recommendationContextType: RecommendationContextType;
	readonly MessageBarType = MessageBarType;
	loadingMessageText: string;
	fields: Array<DataviewField<RecommendationContextProperties>>;
	dataViewConfig: DataViewConfig;
	SpinnerSize = SpinnerSize;
	recommendationContextPropertiesRepository: RelationshipRepository<
		RecommendationContextKeyAndExposedMachinesCount,
		RecommendationContextProperties
	>;

	@Input() repository: RelationshipRepository<
		RecommendationContextKeyAndExposedMachinesCount,
		RecommendationContextProperties
	>;
	@Input() contextOptions: TvmContextOptions;
	@Input() recommendationContextKey: any;
	@Input()
	set recommendationContextType(value: RecommendationContextType) {
		this._recommendationContextType = value;
		this.fields = this.recommendationContextFieldsService.getRecommendationContextProperties(value);
		const allFieldsIds = this.fields.map(field => field.id);
		this.dataViewConfig = {
			//must supply unique id for correct dataview caching purposes - unique id is based on the fields
			id: `recommendationContextPropertiesDetails(${allFieldsIds.join()})`,
		};
	}

	constructor(
		private tvmTextsService: TvmTextsService,
		private featuresService: FeaturesService,
		private recommendationContextFieldsService: RecommendationContextFieldsService,
		injector: Injector,
		private router: Router
	) {
		super(injector);
	}

	ngOnInit() {
		this.loadingMessageText =
			this.tvmTextsService.recommendationContextToContextKeyPaneLoadingTitle[
				this._recommendationContextType
			] +
			' ' +
			this.recommendationContextKey;
	}

	openMachinePage(item: RecommendationContextProperties) {
		const link = this.featuresService.isEnabled(Feature.UpgradeMachinePage)
			? `/machines/${item.name}`
			: `/machine/${item.name}`;
		this.router.navigate([link]);
	}
}
