import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppConfigService } from '@wcd/app-config';
import { TvmLicensesAngularService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class MdatpOrTvmLicenseGuard implements CanActivate {
	constructor(
		private appConfigService: AppConfigService,
		private tvmLicensesService: TvmLicensesAngularService) {}

	canActivate(route: ActivatedRouteSnapshot) {
		const data = route.routeConfig.data;
		if (sccHostService.isSCC  &&  !this.appConfigService.isMdatpActive && (!data || !this.tvmLicensesService.isEnabled(data.tvmLicenseType)))
		{			
			return false;
		}
		return true;
	}	
}
