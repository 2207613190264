import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import {
	FilterValuesChecklistComponent,
	FilterValuesChecklistWithSearchComponent,
	FilterValuesDatePickerComponent,
	ListFilterValueConfig,
} from '@wcd/ng-filters';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
	CustomTiIndicator,
	CustomTiIndicatorActionsType,
	CustomTiIndicatorActionsTypes,
	CustomTiIndicatorsProperties,
	CustomTiIndicatorsTypes,
	MachineGroup,
	CustomTiIndicatorIdentifierTypes,
} from '@wcd/domain';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { MachinesService } from '../../machines/services/machines.service';
import { CustomTiIndicatorsService } from './custom-ti-indicators.service';
import { FabricIconNames } from '@wcd/scc-common';
import { AppFlavorConfig } from '@wcd/scc-common';

@Injectable()
export class CustomTiIndicatorsFields {
	private _fields: Array<DataviewField<CustomTiIndicator>>;
	private _machineRbacGroups: Array<MachineGroup>;
	private _rbacMachineGroupsEnabled: boolean;
	private _tvmApplicationBlockEnabled: boolean;

	constructor(
		private i18nService: I18nService,
		private featureService: FeaturesService,
		private flavorService: FlavorService,
		private machineService: MachinesService,
		private paris: Paris,
		private customTiService: CustomTiIndicatorsService
	) {
		this._rbacMachineGroupsEnabled = featureService.isEnabled(Feature.RbacMachineGroups);
		this._tvmApplicationBlockEnabled = featureService.isEnabled(Feature.TVMApplicationBlock);
	}

	setUserExposedMachineGroups(): Observable<Array<MachineGroup>> {
		if (this._rbacMachineGroupsEnabled && !this._machineRbacGroups) {
			return this.machineService.getFullUserExposedMachineGroups().pipe(
				tap((machineGroup: Array<MachineGroup>) => {
					this._machineRbacGroups = machineGroup;
				})
			);
		}

		return of(this._machineRbacGroups || []);
	}

	getFields(indicatorType: CustomTiIndicatorsTypes): Observable<Array<DataviewField<CustomTiIndicator>>> {
		return this.setUserExposedMachineGroups().pipe(
			map((machineGroup: Array<MachineGroup>) => {
				const now: Date = new Date();

				this._fields = DataviewField.fromList<CustomTiIndicator>(
					[
						{
							id: CustomTiIndicatorsProperties.Action,
							name: this.getEntityFieldsText('action.title'),
							getDisplay: (tiIndicator: CustomTiIndicator) => {
								let actionName = tiIndicator.action.name.toLowerCase();
								if (tiIndicator.action.id === CustomTiIndicatorActionsTypes.Block) {
									actionName =
										tiIndicator.tiIndicatorType.id ===
										CustomTiIndicatorIdentifierTypes.CertificateThumbprint
											? 'blockandremediate'
											: 'blockexecution';
								}

								return this.getEntityFieldsText(`action.values.${actionName}.title`);
							},
							className: 'nowrap',
							filter: {
								component: {
									type: FilterValuesChecklistComponent,
									config: {
										mapFilterValue: (actionTypeId: CustomTiIndicatorActionsTypes) => {
											const actionType: CustomTiIndicatorActionsType = this.paris.getValue<
												CustomTiIndicatorActionsType,
												CustomTiIndicatorActionsTypes
											>(CustomTiIndicatorActionsType, actionTypeId);

											if (actionType) {
												return {
													name: this.getEntityFieldsText(
														`action.values.${actionType.name.toLowerCase()}.title`
													),
													id: actionType.id,
												};
											}

											return null;
										},
									},
								},
							},
							sort: {
								enabled: true,
							},
						},
						{
							id: CustomTiIndicatorsProperties.Severity,
							name: this.getEntityFieldsText('alertSeverity.title'),
							getDisplay: (tiIndicator: CustomTiIndicator) => {
								if (
									tiIndicator.action.id === CustomTiIndicatorActionsTypes.Allow ||
									(this._tvmApplicationBlockEnabled && !tiIndicator.generateAlert)
								) {
									return this.i18nService.get(
										`customTiIndicator_dataview_entity_fields_alertSeverity_values_na_title`
									);
								}

								return this.i18nService.get(
									`customTiIndicator_dataview_entity_fields_alertSeverity_values_${tiIndicator.severity.type}_title`
								);
							},
							getCssClass: (tiIndicator: CustomTiIndicator) => {
								if (
									tiIndicator.action.id === CustomTiIndicatorActionsTypes.Allow ||
									(this._tvmApplicationBlockEnabled && !tiIndicator.generateAlert)
								) {
									return null;
								}

								return `wcd-severity wcd-severity-${tiIndicator.severity.type}`;
							},
							className: 'nowrap',
							sort: {
								enabled: true,
							},
						},
						...(this.flavorService.isEnabled(AppFlavorConfig.settings.indicators)
							? [
									{
										id: CustomTiIndicatorsProperties.RbacGroupIds,
										name: this.getEntityFieldsText('scope'),
										className: 'nowrap',
										getDisplay: (tiIndicator: CustomTiIndicator) => {
											if (
												!this._rbacMachineGroupsEnabled ||
												!tiIndicator.rbacGroupIds ||
												tiIndicator.rbacGroupIds.length === 0
											) {
												return this.i18nService.get(
													'customTiIndicator.detailsSidePane.sections.organizationalscope.all'
												);
											}
											if (tiIndicator.rbacGroupIds.length === 1) {
												const currentGroup: MachineGroup = this._machineRbacGroups.find(
													(group: MachineGroup) =>
														group.id === tiIndicator.rbacGroupIds[0]
												);
												return currentGroup
													? currentGroup.name
													: this.i18nService.get('common_error').toUpperCase();
											}

											return `${tiIndicator.rbacGroupIds.length} ${this.i18nService.get(
												'customTiIndicator.detailsSidePane.sections.organizationalscope.groups'
											)}`;
										},
										icon: {
											fabricIcon: (tiIndicator: CustomTiIndicator) =>
												!this.allGroupsValid(tiIndicator) &&
												FabricIconNames.IncidentTriangle,
											className: 'color-text-warning-dark',
										},
										getTooltip: (tiIndicator: CustomTiIndicator) => {
											if (!this.allGroupsValid(tiIndicator)) {
												return this.getEntityFieldsText(
													'invalid.device.group.tooltip'
												);
											}
											return null;
										},
										enabledByDefault: this._rbacMachineGroupsEnabled,
									} as DataviewFieldConfig,
							  ]
							: []),
						{
							id: CustomTiIndicatorsProperties.ExpirationTime,
							name: this.getEntityFieldsText('expiresOn.title'),
							icon: {
								fabricIcon: (tiIndicator: CustomTiIndicator) =>
									tiIndicator.expirationTime &&
									tiIndicator.expirationTime < now &&
									FabricIconNames.IncidentTriangle,
								className: 'color-text-warning-dark',
							},
							component: {
								type: TzDateComponent,
								getProps: (tiIndicator: CustomTiIndicator) => {
									const utcDateAsLocal = this.customTiService.reinterpretUtcAsLocalTime(
										tiIndicator.expirationTime
									);

									return {
										date: utcDateAsLocal,
										dateFormat: 'mediumDate',
									};
								},
							},
							filter: {
								component: {
									type: FilterValuesDatePickerComponent,
								},
							},
							sort: {
								enabled: true,
							},
							className: 'nowrap',
							getTooltip: (tiIndicator: CustomTiIndicator) => {
								if (!tiIndicator.expirationTime) {
									return this.getEntityFieldsText('expiresOn.values.never');
								}
								if (tiIndicator.expirationTime < now) {
									return this.getEntityFieldsText('expiresOn.values.expired');
								}
								return null;
							},
						},
						{
							id: CustomTiIndicatorsProperties.Title,
							name: this.getEntityFieldsText('tiTitle.title'),
							className: 'nowrap',
							getTooltip: (tiIndicator: CustomTiIndicator) => tiIndicator.title,
							sort: {
								enabled: true,
							},
						},
						{
							id: CustomTiIndicatorsProperties.CreatedByDisplayName,
							name: this.getEntityFieldsText('createdBy'),
							className: 'nowrap',
							filter: {
								component: {
									type: FilterValuesChecklistWithSearchComponent,
									config: this.customTiService.getFilterValuesConfig(
										CustomTiIndicatorsProperties.CreatedByDisplayName,
										indicatorType
									),
								},
							},
							sort: {
								enabled: true,
							},
						},
						{
							id: CustomTiIndicatorsProperties.CreationTime,
							name: this.getEntityFieldsText('createdOn'),
							className: 'nowrap',
							component: {
								type: TzDateComponent,
								getProps: (tiIndicator: CustomTiIndicator) => {
									const utcDateAsLocal = this.customTiService.reinterpretUtcAsLocalTime(
										tiIndicator.creationTime
									);
									return {
										date: utcDateAsLocal,
										dateFormat: 'medium',
									};
								},
							},
							filter: {
								component: {
									type: FilterValuesDatePickerComponent,
								},
							},
							sort: {
								enabled: true,
							},
						},
						{
							id: CustomTiIndicatorsProperties.GenerateAlert,
							name: this.getEntityFieldsText('generateAlert.title'),
							filterOnly: true,
							className: 'nowrap',
							filter: {
								component: {
									type: FilterValuesChecklistComponent,
									config: {
										mapFilterValue: (filterValue: any): ListFilterValueConfig<string> => {
											return {
												id: filterValue,
												name: this.i18nService.get(
													`common.${filterValue === true ? 'yes' : 'no'}`
												),
											};
										},
									},
								},
							},
							sort: {
								enabled: true,
							},
						},
					].map((field) =>
						!!field.sort && field.sort.enabled === true
							? field
							: {
									...field,
									sort: { enabled: false },
							  }
					)
				);

				return this._fields;
			})
		);
	}

	private getEntityFieldsText(suffix: string): string {
		return this.i18nService.get(`customTiIndicator.dataview.entity.fields.${suffix}`);
	}

	private allGroupsValid(tiIndicator: CustomTiIndicator): boolean {
		if (tiIndicator.rbacGroupIds && tiIndicator.rbacGroupIds.length > 0) {
			const isValidGroups = tiIndicator.rbacGroupIds.every((id: number) => {
				const foundMachineGroup = this._machineRbacGroups.find(
					(group: MachineGroup) => group.id === id
				);

				return !!foundMachineGroup;
			});

			return isValidGroups;
		}

		return true;
	}
}
