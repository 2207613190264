
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.upn">
				<dt role="none">{{ i18nService.strings.airsEntities_userActivity_fields_username }}</dt>
				<dd role="none">{{ entity.upn }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.fullName">
				<dt role="none">{{ i18nService.strings.airsEntities_general_fields_name }}</dt>
				<dd role="none">{{ entity.fullName }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.userObjectId">
				<dt role="none">{{ i18nService.strings.airsEntities_userActivity_fields_aadId }}</dt>
				<dd role="none">{{ entity.userObjectId }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.sid">
				<dt role="none">{{ i18nService.strings.airsEntities_userActivity_fields_sid }}</dt>
				<dd role="none">{{ entity.sid }}</dd>
			</ng-container>
		</dl>
	