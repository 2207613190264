var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createTheme } from '@uifabric/styling';
// The following are not available in office-ui-fabric-react's palette
export var m365ExtendedColors = {
    darkBrown: '#502006',
    highSeverity: '#990000',
    mediumSeverity: '#E60000',
    lowSeverity: '#F56A00',
    informationalSeverity: '#949494',
    criticalStatus: '#610000',
    dataVis6: '#881798',
    dataVis7: '#e43BA6',
    dataVis9: '#8764b8',
    themeDark: '#005BA1',
    themeDarkAlt: '#006CBE',
    themeSecondary: '#1A86D9',
    needHelpBackgroundPressed: '#006B73',
};
var m365Palette = {
    redDark: '#650000',
    red: '#a80000',
    orangeLighter: '#F2610C',
    greenLight: '#41A512',
    green: '#107C10',
    neutralQuaternary: '#e5e5e5',
    tealLight: '#00A2AD',
    magentaDark: '#4B003F',
    purpleLight: '#917EDB',
    blueDark: '#001D3F',
    greenDark: '#022F22',
    orangeLight: '#E86E58',
    neutralQuaternaryAlt: '#D9D9D9',
    neutralLighter: '#F5F5F5',
    neutralPrimary: '#323130',
    neutralPrimaryAlt: '#484644',
    neutralTertiary: '#959595',
    purpleDark: '#4B003F',
};
/**
 * M365 theme.
 * @note Anything not overridden gets the default (Fabric) values.
 */
export var m365Theme = createTheme({
    // cast to any since some keys are not valid M365 colors We do rely on them later in KnownColorsService though.
    palette: __assign({}, m365Palette, m365ExtendedColors),
});
