var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ICommandBarStyles, IContextualMenuItem } from 'office-ui-fabric-react';
import { ChangeDetectorRef } from '@angular/core';
import { filter } from 'rxjs/operators';
import { MainAppStateService } from '../../main/services/main-app-state.service';
import { config } from '@wcd/shared';
import { memoize } from 'lodash-es';
var CommandBarComponent = /** @class */ (function () {
    function CommandBarComponent(mainAppStateService, changeDetectorRef) {
        var _this = this;
        this.changeDetectorRef = changeDetectorRef;
        this.commandBarStyle = { primarySet: { justifyContent: 'flex-end' } };
        this.isNarrowLayout = false;
        this.overflowButtonAriaLabel = 'entityCommon.commandBar.moreOptions';
        // Adding this temporary input to disable the responsive behavior because its broken in some places
        // Needs to be removed when its fixed
        this.disableResponsiveBehavior = false;
        // Fix for bug https://microsoft.visualstudio.com/DefaultCollection/OS/_workitems/edit/25779016
        // Use Items instead of far Items to overcome Accessibility issue with empty items.
        // Empty Items add a default div that has role menubar with no children.
        // When this input is True, far items are used as items and the style changes to push the items to right.
        // Use it whenever the visible items might be empty.
        this.forceFarItemsOnEmptyItems = false;
        // Bellow this screen width, commands items will be rendered without labels - with icons only.
        this.onlyIconsScreenBreakpoint = config.msScreenSizeBreakpoints.small;
        this.setCurrentMenuButton = function (event) {
            _this.currentMenuButton = event.currentTarget;
        };
        this.hiddenMenuItemsFocusHandling = function (items) {
            return items
                ? items.map(function (item) {
                    if (item && item.onClick) {
                        var originalOnClick_1 = item.onClick;
                        item.onClick = function (ev, contextualMenuItem) {
                            _this.setFocusToCurrentMenuItem(ev.currentTarget);
                            return originalOnClick_1(ev, contextualMenuItem);
                        };
                    }
                    return item;
                })
                : null;
        };
        this.toIconOnlyItems = function (items) {
            return items.map(function (item) { return (__assign({}, item, { tooltipHostProps: {
                    content: item.title,
                }, ariaLabel: item.ariaLabel || item.title, title: '', iconOnly: true, cacheKey: item.key + "_icon_only" })); });
        };
        mainAppStateService.state$
            .pipe(filter(function (state) {
            return (_this.isNarrowLayout !==
                config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, _this.onlyIconsScreenBreakpoint));
        }))
            .subscribe(function (state) {
            _this.isNarrowLayout = config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, _this.onlyIconsScreenBreakpoint);
            _this.changeDetectorRef.markForCheck();
        });
        this.updateVisibleItems = memoize(this.updateVisibleItems);
        this.updateVisibleOverflowItems = memoize(this.updateVisibleOverflowItems);
        this.updateVisibleFarItems = memoize(this.updateVisibleFarItems);
    }
    Object.defineProperty(CommandBarComponent.prototype, "items", {
        get: function () {
            return this._items;
        },
        set: function (value) {
            this._items = this.hiddenMenuItemsFocusHandling(value);
            this.updateVisibleItems(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommandBarComponent.prototype, "overflowItems", {
        get: function () {
            return this._overflowItems;
        },
        set: function (value) {
            this._overflowItems = this.hiddenMenuItemsFocusHandling(value);
            this.updateVisibleOverflowItems(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommandBarComponent.prototype, "farItems", {
        get: function () {
            return this._farItems;
        },
        set: function (value) {
            this._farItems = this.hiddenMenuItemsFocusHandling(value);
            this.updateVisibleFarItems(value);
        },
        enumerable: true,
        configurable: true
    });
    CommandBarComponent.prototype.getCommandBarItems = function () {
        if (this.disableResponsiveBehavior) {
            return this._visibleItems;
        }
        if (this.forceFarItemsOnEmptyItems && (!this._visibleItems || !this._visibleItems.length)) {
            return this.isNarrowLayout ? this._visibleIconOnlyFarItems : this._visibleFarItems;
        }
        else {
            return this.isNarrowLayout ? this._visibleIconOnlyItems : this._visibleItems;
        }
    };
    CommandBarComponent.prototype.getCommandBarFarItems = function () {
        if (!this.forceFarItemsOnEmptyItems || !this._visibleItems || this._visibleItems.length) {
            return this.isNarrowLayout ? this._visibleIconOnlyFarItems : this._visibleFarItems;
        }
    };
    CommandBarComponent.prototype.updateVisibleItems = function (value) {
        if (value) {
            this._visibleItems = value.filter(function (item) { return !item.hidden; });
            this._visibleIconOnlyItems = this.toIconOnlyItems(this._visibleItems);
        }
        else {
            this._visibleItems = null;
            this._visibleIconOnlyItems = null;
        }
    };
    CommandBarComponent.prototype.updateVisibleOverflowItems = function (value) {
        if (value) {
            this._visibleOverflowItems = value.filter(function (item) { return !item.hidden; });
            this._visibleIconOnlyOverflowItems = this.toIconOnlyItems(this._visibleOverflowItems);
        }
        else {
            this._visibleOverflowItems = null;
            this._visibleIconOnlyOverflowItems = null;
        }
    };
    CommandBarComponent.prototype.updateVisibleFarItems = function (value) {
        if (value) {
            this._visibleFarItems = value.filter(function (item) { return !item.hidden; });
            this._visibleIconOnlyFarItems = this.toIconOnlyItems(this._visibleFarItems);
        }
        else {
            this._visibleFarItems = null;
            this._visibleIconOnlyFarItems = null;
        }
    };
    Object.defineProperty(CommandBarComponent.prototype, "visibleItems", {
        get: function () {
            if (!this.items)
                return undefined;
            return this.items.filter(function (item) { return !item.hidden; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommandBarComponent.prototype, "visibleOverflowItems", {
        get: function () {
            if (!this.overflowItems)
                return undefined;
            return this.overflowItems.filter(function (item) { return !item.hidden; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommandBarComponent.prototype, "visibleFarItems", {
        get: function () {
            if (!this.farItems)
                return undefined;
            return this.farItems.filter(function (item) { return !item.hidden; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommandBarComponent.prototype, "customStyles", {
        get: function () {
            if (this.forceFarItemsOnEmptyItems && (!this.visibleItems || this.visibleItems.length === 0)) {
                if (!this.styles) {
                    return this.commandBarStyle;
                }
                var customStyles = this.styles;
                customStyles.primarySet = Object.assign(customStyles.primarySet || {}, {
                    justifyContent: 'flex-end',
                });
                return customStyles;
            }
            return this.styles;
        },
        enumerable: true,
        configurable: true
    });
    CommandBarComponent.prototype.setFocusToCurrentMenuItem = function (element) {
        if (element && element.classList && element.classList.contains('ms-ContextualMenu-link')) {
            this.currentMenuButton.focus();
        }
    };
    CommandBarComponent.prototype.isAllOverflowItems = function () {
        if (!this._visibleItems || !this.visibleOverflowItems) {
            return false;
        }
        return this._visibleItems.length == this._visibleOverflowItems.length;
    };
    return CommandBarComponent;
}());
export { CommandBarComponent };
