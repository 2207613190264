import { ChangeDetectorRef, Component } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { CustomActionRun } from '@wcd/domain';

@Component({
	selector: 'scheduled-hunting-action-entity-panel',
	template: `
		<div class="wcd-padding-large-all">
			<dl class="wcd-padding-bottom">
				<dt>{{ 'hunting.customDetections.actionRuns.fields.status' | i18n }}</dt>
				<dd>
					<wcd-status [status]="action.status" [ariaLabel]="'hunting.customDetections.actionRuns.fields.status' | i18n"></wcd-status>
				</dd>
			</dl>

			<dl *ngIf="action.failureReason" class="wcd-padding-bottom">
				<dt>{{ 'hunting.customDetections.actionRuns.fields.failureReason' | i18n }}</dt>
				<dd>{{ action.failureReason }}</dd>
			</dl>

			<dl class="wcd-padding-bottom">
				<dt>{{ 'hunting.customDetections.actionRuns.fields.startTime' | i18n }}</dt>
				<dd><tz-date [date]="action.startTime"></tz-date></dd>
			</dl>

			<dl>
				<dt>{{ 'hunting.customDetections.actionRuns.fields.entity' | i18n }}</dt>
				<dd>
					<entity-name
						*ngIf="action.entities && action.entities.length && action.entities[0] as entity"
						[entityName]="entity.name"
						[entityTypeId]="entity.entityType.type"
						[entity]="{ senseMachineId: entity.id, id: entity.id }"
						[displayAriaLabelOnIcon]="false"
						[linkAriaLabel]="('hunting.customDetections.actionRuns.fields.entity' | i18n) + ', ' + entity.name"
					></entity-name>
				</dd>
			</dl>
		</div>
	`,
})
export class ScheduledHuntingActionEntityPanelComponent extends EntityPanelComponentBase<CustomActionRun> {
	get action(): CustomActionRun {
		return this.entity;
	}

	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}
}
