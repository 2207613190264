import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { PanelContainer } from '@wcd/panels';
import { WizardStepConfig } from '@wcd/wizard';
import { DEFAULT_LAB_CONFIGURATION_ID, LAB_CONFIGURATION } from '@wcd/domain';

import { EvaluationSetupModel } from '../../models/evaluation-setup.model';
import { LabConfigurationStepComponent } from './lab-configuration-step.component';
import { SimulatorAgentStepComponent } from './simulator-agent-step.component';
import { SummaryStepComponent } from './summary-step.component';
import { EvaluationService } from '../../services/evaluation.service';

@Component({
	selector: 'evaluation-setup-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel #panel (close)="destroy()" [settings]="settings">
			<wcd-wizard
				[steps]="steps"
				[data]="data"
				wizardTitle="evaluation.lab.setupPanel.title"
				wizardTrackingName="EvaluationWizard"
				(onClose)="panel.closePanel()"
			></wcd-wizard>
		</wcd-panel>
	`,
})
export class EvaluationSetupPanelComponent extends PanelContainer {
	data: EvaluationSetupModel = {
		labConfiguration: LAB_CONFIGURATION.find(c => c.id === DEFAULT_LAB_CONFIGURATION_ID),
		withAiq: false,
		withSafeBreach: false,
	};

	constructor(router: Router, private evaluationService: EvaluationService) {
		super(router);
	}

	onLabCreated = () => {
		this.evaluationService.notifyLabReady();
		this.closePanel();
	};

	steps: WizardStepConfig<EvaluationSetupModel>[] = [
		{
			id: 1,
			name: 'LabConfiguration',
			verticalStepKeyName: 'evaluation.lab.setupPanel.configurationStep.title',
			component: LabConfigurationStepComponent,
		},
		{
			id: 2,
			name: 'SimulatorAgent',
			verticalStepKeyName: 'evaluation.lab.setupPanel.simulatorAgentStep.title',
			component: SimulatorAgentStepComponent,
		},
		{
			id: 3,
			name: 'Summary',
			verticalStepKeyName: 'evaluation.lab.setupPanel.summaryStep.title',
			component: SummaryStepComponent,
			onNext: true,
			nextButtonText: 'evaluation.lab.createLab',
			componentOutputs: new Map<string, Function>([['onLabCreated', this.onLabCreated]]),
		},
	];
}
