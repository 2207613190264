import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InvestigatedMachine, Machine } from '@wcd/domain';
import { NavItemModel } from '@wcd/shared';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'investigated-machines-table',
	templateUrl: './investigated-machines-table.component.html',
})
export class InvestigatedMachinesTableComponent {
	constructor(
		private globalEntityTypesService: GlobalEntityTypesService,
		public i18nService: I18nService
	) {}

	@Input() investigatedMachines: Array<InvestigatedMachine>;

	getMachineRouteLinkModel(investigatedMachine: InvestigatedMachine): Partial<NavItemModel> {
		const machineId = investigatedMachine.machineId;
		return this.globalEntityTypesService.getRouteLinkModel(Machine, <Partial<Machine>>{
			senseMachineId: machineId,
			name: machineId,
		});
	}
}
