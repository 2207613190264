// tslint:disable:template-click-events-have-key-events
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ChangeDetectorRef,
	SecurityContext,
} from '@angular/core';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { combineLatest, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
	File,
	FileCurrentInstancesCount,
	FileCurrentInstancesRelationship,
	FileFileStatsRelationship,
	FileQuarantineApiCall,
	FileStats,
} from '@wcd/domain';
import { ModalContainer } from '../../../../dialogs/modals/models/modal-container.model';
import { CustomConfirmComponent } from '../../../../dialogs/models/custom-confirm-options';
import { DimensionsModel } from '../../../../dialogs/models/dimensions.model';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { FilesService } from '../../services/files.service';
import { FileActionsService } from '../../services/file.actions.service';
import { WicdSanitizerService } from '@wcd/shared';
import { FeaturesService } from '@wcd/config';

const MIN_ORG_PREVALENCE_TO_DISPLAY_WARNING = 20;
const MAX_FILE_NAMES_TO_DISPLAY = 4;

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<modal (close)="destroy()" [settings]="settings">
			<confirm-modal-content
				class="wcd-flex-vertical wcd-flex-1 ie11Patch ie11Flex"
				[confirmButtonOptions]="{
					disabled: !reason || isSaving || loading
				}"
				[isSaving]="isSaving"
				(onCancel)="onCancelHandler()"
				(onConfirm)="submit()"
			>
				<p>{{ 'file.quarantine.description' | i18n }}</p>

				<ng-container *ngIf="loading; else stats">
					<fab-spinner [label]="'file.quarantine.details.loading' | i18n"></fab-spinner>
				</ng-container>
				<ng-template #stats>
					<table class="response-confirmation-table wcd-margin-bottom">
						<tr>
							<th
								class="response-confirmation-table-header-text response-confirmation-table-header-applies"
							>
								{{ 'file.quarantine.details.applies.title' | i18n }}
							</th>
							<th class="response-confirmation-table-header-text">
								{{ 'file.quarantine.details.fileDetails.title' | i18n }}
							</th>
						</tr>
						<tr class="response-confirmation-details-row">
							<td class="response-confirmation-impact-section">
								<div>
									<fab-icon
										iconName="System"
										contentClass="response-confirmation-DeviceLaptopNoPic"
									></fab-icon>
								</div>
								<div>
									{{
										'file.quarantine.details.applies.machines'
											| i18n: { amount: fileInstanceCount.machineCount | prettyNumber }
									}}
								</div>
							</td>
							<td>
								<div class="response-confirmation-details-sha1-section">
									<span class="response-confirmation-details-sha1-text">{{
										'file.quarantine.details.fileDetails.sha1' | i18n
									}}</span>
									{{ file.sha1 }}
								</div>
								<div class="response-confirmation-details-prevalence-section">
									<table class="response-confirmation-table">
										<tr>
											<td class="response-confirmation-details-prevalence-cell">
												<span
													class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-worldwide"
													>{{
														'file.quarantine.details.fileDetails.prevalanceWorldwide'
															| i18n
													}}</span
												>{{ fileStats.worldwidePrevalence | prettyNumber }}
											</td>
											<td class="response-confirmation-details-prevalence-cell">
												<span
													class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-filenames"
													>{{
														'file.quarantine.details.fileDetails.fileNames' | i18n
													}}</span
												>{{ fileStats.topFileNames?.length | prettyNumber }}
												<fab-icon
													iconName="Info"
													contentClass="ms-color-blue wcd-padding-xsmall-bottom"
													[wcdTooltip]="topFileNamesHtmlTooltip"
												></fab-icon>
											</td>
										</tr>
										<tr>
											<td class="response-confirmation-details-prevalence-cell">
												<span
													class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-organizations"
													>{{
														'file.quarantine.details.fileDetails.prevalenceOrganization'
															| i18n
													}}</span
												>{{ fileStats.organizationPrevalence | prettyNumber }}
											</td>
											<td class="response-confirmation-details-prevalence-cell">
												<span
													class="response-confirmation-details-prevalence-title response-confirmation-details-prevalence-file-instances"
													>{{
														'file.quarantine.details.fileDetails.fileInstances'
															| i18n
													}}</span
												>{{ fileInstanceCount.fileCount | prettyNumber }}
											</td>
										</tr>
									</table>
								</div>
							</td>
						</tr>
						<tr
							*ngIf="showHighOrgPrevalenceWarning"
							class="response-confirmation-warning-message"
						>
							<td colspan="2">
								<fab-icon
									iconName="warningSolid"
									contentClass="color-text-warning-dark wcd-margin-small-all"
								></fab-icon>
								{{ 'file.quarantine.details.fileRelativelyPrevalent' | i18n }}
							</td>
						</tr>
					</table>
				</ng-template>
				<label for="block-file-reason" class="wcd-required"
					>{{ 'file.quarantine.reason' | i18n }}:</label
				>
				<textarea
					required
					rows="4"
					id="block-file-reason"
					[placeholder]="'file.quarantine.reasonPlaceholder' | i18n"
					class="form-control dialog-textarea wcd-full-width wcd-no-resize"
					[(ngModel)]="reason"
				></textarea>

				<p class="wcd-margin-top">
					<fab-icon iconName="Clock"></fab-icon>
					{{ 'file.quarantine.applies' | i18n }}
					<span
						class="response-confirmation-export-to-csv-open-dialog-button no-outline pointer"
						(click)="downloadMachineListCsv()"
					>
						<fab-icon iconName="Download"></fab-icon>
						{{ 'file.quarantine.details.exportMachineList' | i18n }}
					</span>
				</p>
			</confirm-modal-content>
		</modal>
	`,
	styleUrls: ['./quarantine-file-modal.component.scss'],
})
export class QuarantineFileModalComponent extends ModalContainer
	implements CustomConfirmComponent<{}>, OnInit, OnDestroy {
	@Input() file: File;
	@Input() isSaving = false;
	@Input() openActionCenterOnSubmit = false;

	@Output() readonly onConfirm = new EventEmitter<{}>();
	@Output() readonly onCancel = new EventEmitter<void>();

	private _fileStatsAndInstancesSubscription: Subscription;

	reason: string;
	fileInstanceCount: FileCurrentInstancesCount;
	fileStats: FileStats;
	loading: boolean = false;
	topFileNamesHtmlTooltip: string;
	showHighOrgPrevalenceWarning: boolean = false;

	constructor(
		router: Router,
		private paris: Paris,
		private readonly i18nService: I18nService,
		private readonly filesService: FilesService,
		private readonly fileActionsService: FileActionsService,
		private dialogsService: DialogsService,
		private changeDetectorRef: ChangeDetectorRef,
		private domSanitizer: WicdSanitizerService,
		private readonly featuresService: FeaturesService
	) {
		super(router);
	}

	ngOnInit() {
		this.settings = {
			...this.settings,
			className: 'wcd-flex-vertical',
			title: this.i18nService.get(`file.quarantine.title`),
			dimensions: new DimensionsModel(640, 540),
		};

		this.loading = true;

		const filesPrefix = this.featuresService.isEnabled('K8SMigration-EPSFilePrevalence-kw');

		const fileCurrentInstances$ = this.paris.getRelatedItem<File, FileCurrentInstancesCount>(
			FileCurrentInstancesRelationship,
			this.file,
			{ where: { filesPrefix } }
		);

		const fileStats$ = this.paris.getRelatedItem(
			FileFileStatsRelationship,
			this.file,
			{ where: { filesPrefix } });

		this._fileStatsAndInstancesSubscription = combineLatest(fileCurrentInstances$, fileStats$).subscribe(
			([instanceCount, fileStats]: [FileCurrentInstancesCount, FileStats]) => {
				this.setFileStatistics(instanceCount, fileStats);
				this.loading = false;
				this.changeDetectorRef.detectChanges();
			},
			error => {
				this.loading = false;
				this.dialogsService.showError({
					title: this.i18nService.get('file.quarantine.details.errors.failedLoading'),
					data: error,
				});
				this.changeDetectorRef.detectChanges();
			}
		);
	}

	ngOnDestroy() {
		this._fileStatsAndInstancesSubscription && this._fileStatsAndInstancesSubscription.unsubscribe();
	}

	onConfirmHandler() {
		this.onConfirm.emit({});
		this.onConfirm.complete();
	}

	onCancelHandler() {
		this.onCancel.emit();
		this.onCancel.complete();
	}

	submit() {
		this.isSaving = true;

		this.paris
			.apiCall(FileQuarantineApiCall, {
				file: this.file,
				reason: this.reason,
			})
			.pipe(
				finalize(() => {
					this.isSaving = false;
					this.onConfirmHandler();
				})
			)
			.subscribe(() => {
				if (this.openActionCenterOnSubmit) this.filesService.showFileActionCenter(this.file);
			});
	}

	downloadMachineListCsv() {
		this.fileActionsService.downloadFileObservedMachinesCsv(this.file.sha1);
	}

	private setFileStatistics(instanceCount: FileCurrentInstancesCount, fileStats: FileStats) {
		this.fileInstanceCount = instanceCount;
		this.fileStats = fileStats;
		this.showHighOrgPrevalenceWarning =
			instanceCount.machineCount > MIN_ORG_PREVALENCE_TO_DISPLAY_WARNING;
		this.setTopFileNamesHtmlForTooltip(fileStats.topFileNames);
	}

	private setTopFileNamesHtmlForTooltip(topFileNames: ReadonlyArray<string>) {
		const topNamesHtml: string = topFileNames
			.slice(0, Math.min(MAX_FILE_NAMES_TO_DISPLAY, topFileNames.length))
			.reduce((preValue, currValue) => {
				return preValue ? `${this.sanitize(preValue)}<br>${this.sanitize(currValue)}` : this.sanitize(currValue);
			});

		this.topFileNamesHtmlTooltip =
			topFileNames.length > MAX_FILE_NAMES_TO_DISPLAY
				? this.i18nService.get('file.quarantine.details.topFileNamesTooltip', {
						names: topNamesHtml,
						amount: topFileNames.length - MAX_FILE_NAMES_TO_DISPLAY,
				  })
				: topNamesHtml;
	}

	private sanitize = text => this.domSanitizer.sanitize(SecurityContext.HTML, text)
}
