var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AadUser, Alert, alertClassificationValues, alertDeterminationValues, AlertStatusTypeId, AlertV3StatusType, DetectionSourceType, detectionSourceValues, investigationStatusValues, Machine, Mailbox, serviceSourceValues, severityValues } from "@wcd/domain";
export var convertHostToMachine = function (host) {
    return new Machine(__assign({}, host, { senseMachineId: host.hostName || host.senseMachineId || host.HostName || host.SenseMachineId, id: host.senseMachineId || host.computerDnsName || host.id || host.SenseMachineId || host.ComputerDnsName || host.Id, name: host.hostName || host.IpAddress || host.HostName }));
};
export var convertV3MailboxToLegacy = function (mailbox) {
    return new Mailbox(__assign({}, mailbox, { type: 'mailbox', displayName: mailbox.displayName || mailbox.DisplayName, mailboxPrimaryAddress: mailbox.mailboxPrimaryAddress || mailbox.MailboxPrimaryAddress, upn: mailbox.upn || mailbox.Upn }));
};
export var convertAccountToUser = function (account) {
    return new AadUser(__assign({}, account, { id: account.sid || account.Sid, aadUserId: account.aadUserId || account.AadUserId, displayName: account.name || account.Name }));
};
export var convertImpactedEntitiesV3ToLegacy = function (impactedEntities, impactedEntitiesSummary) {
    if (!impactedEntities)
        return null;
    var impactedEntitiesObj = {
        machines: impactedEntities.filter(function (entity) { return entity.type === 'host' || entity.Type === 'host'; }).map(function (host) { return convertHostToMachine(host); }),
        mailboxes: impactedEntities.filter(function (entity) { return entity.type === 'mailbox' || entity.Type === 'mailbox'; }).map(function (mailbox) { return convertV3MailboxToLegacy(mailbox); }),
        users: impactedEntities.filter(function (entity) { return entity.type === 'account' || entity.Type === 'account'; }).map(function (account) { return convertAccountToUser(account); }),
        apps: null,
    };
    var impactedEntitiesCount = impactedEntitiesSummary ?
        {
            mailboxCount: impactedEntitiesSummary.mailbox,
            machineCount: impactedEntitiesSummary.host,
            userCount: impactedEntitiesSummary.account,
            appCount: null /* doesn't return from old associated alerts Api */
        } : null;
    return __assign({}, impactedEntitiesObj, impactedEntitiesCount);
};
export var convertAlertV3ToLegacyAlert = function (securityAlert) {
    return new Alert(__assign({}, securityAlert, { id: securityAlert.alertId, name: securityAlert.alertDisplayName, actor: securityAlert.actorName, groupedAlertsCount: securityAlert.alertCount, incidentLinkedBy: securityAlert.incidentLinkedBy || [], aggregatedIncidentLinkedBy: securityAlert.aggregatedLinkedByAlerts || [], detectionSource: detectionSourceValues.find(function (value) { return value.id === DetectionSourceType[securityAlert.detectionSource]; }), description: securityAlert.description, serviceSource: serviceSourceValues.find(function (value) { return (value.type === securityAlert.productSource); }), classification: alertClassificationValues.find(function (value) { return value.type === securityAlert.classification; }), determination: alertDeterminationValues.find(function (value) { return value.type === securityAlert.determination; }), status: AlertStatusTypeId[AlertV3StatusType[securityAlert.status]], categories: [securityAlert.category], severity: severityValues.find(function (value) { return value.name === securityAlert.severity; }), impactedEntities: convertImpactedEntitiesV3ToLegacy(securityAlert.impactedEntities, securityAlert.impactedEntitiesSummary), investigationId: securityAlert.investigationIds ? parseInt(securityAlert.investigationIds[0]) : null, firstEventTime: new Date(securityAlert.startTimeUtc), lastEventTime: new Date(securityAlert.endTimeUtc), firstSeen: new Date(securityAlert.timeGenerated), investigationStatus: investigationStatusValues.find(function (value) { return value.type === securityAlert.investigationState; }) }));
};
/* CAUTION!
Please use this util carefully when converting V3 security alert to legacy alert,
It's designed to work only in specific scenarios with specific BE response.
Will be used until angular deprecation.
Be aware! When using the 'new' keyword with ParisV1 entity - it doesn't pass parsing process!
 */
