import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { RecommendationType } from './recommendation-type.enum';

@ValueObject({
	singularName: 'Software recommendation arguments',
	pluralName: 'Software recommendations arguments',
})
export class VaRecommendationArgs extends ModelBase {
	@EntityField({
		data: 'productId',
	})
	readonly productId: string;

	@EntityField({
		data: 'recommendationType',
	})
	readonly recommendationType?: RecommendationType;
}
