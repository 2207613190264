var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { EvidenceDetectionSource } from './evidence-detection-source.value-object';
import { AirsEntityRemediationStatus } from '../airs_entity/airs-entity-remediation-status.entity';
import { AirsEntityTypeConfig } from '../airs_entity/airs-entity-type-config.entity';
import { AirsEntityVerdict } from '../airs_entity/airs-entity-verdict.entity';
import { EvidenceAsset } from './evidence-asset.value-object';
import { compact, sortBy } from 'lodash-es';
import { OfficeUtils } from '../utils/office-utils';
import { mapAlertV3EntityToAirsEntity } from '../airs_entity/alertV3/converters/airs-entity.converter.utils';
var ɵ0 = function (_, query) {
    if (query.where['EvidenceApiV2']) {
        return "evidenceapiservice/evidence/incident/" + query.where['incident_id'];
    }
    return "entities/evidence/" + query.where['incident_id'] + "/unified";
}, ɵ1 = function (data, _, query) {
    // since MDE investigation still not in AlertV3, we can't convert it from AlertV3.
    // the indication that it's not alertV3 is the structure of the data - in the regular model (not AlertV3)
    // the structure of 'data' is '{data: Array<>}' and in AlertV3 it's only 'Array<>'
    // Note that it's a temp solution, until MDE investigation will be returned in AlertV3 format.
    if (query && query.where['EvidenceApiV2'] && !data.data) {
        data = OfficeUtils.convertDataFromAlertV3(data);
        var convertedData = data && (Array.isArray(data) ? data : [data]).map(function (e) { return mapAlertV3EntityToAirsEntity(e); });
        return compact(convertedData); // we might have unknown types so we need to filter out the falsey values
    }
    return data;
}, ɵ2 = function (config) { return config.data.serviceUrls.automatedIr; }, ɵ3 = ['urn', 'id'], ɵ4 = function (_, rawData) {
    var intEntityId = parseInt(rawData.id);
    var isIdNegative = !isNaN(intEntityId) && intEntityId < 0;
    // negative 'id' means that it's not AirsEntity and we don't want to use AIRS BE
    return isIdNegative && rawData.urn ? rawData.urn : rawData.id;
}, ɵ5 = ['entity_type_id', 'type_id'], ɵ6 = function (detectionContexts, rawData) {
    if (detectionContexts && detectionContexts.length) {
        return sortBy(detectionContexts, ['detection_source_name']);
    }
    var detectionContext = rawData['entity_detection_src'];
    return (detectionContext && [detectionContext]) || null;
}, ɵ7 = function (investigation) { return (investigation && investigation.investigation_id) || null; }, ɵ8 = function (fieldData) { return (fieldData && fieldData.length > 0 ? fieldData.join(', ') : ''); };
var EvidenceEntity = /** @class */ (function (_super) {
    __extends(EvidenceEntity, _super);
    function EvidenceEntity(data) {
        var _this = _super.call(this, data) || this;
        _this.setAssets();
        //TODO: need to determine if it's office entity or not in case of evidence in incident
        _this.isOfficeEntity = _this._isOfficeEntity || !!_this.urn;
        return _this;
    }
    EvidenceEntity.prototype.setAssets = function () {
        this.assets = this.assets.filter(function (a) { return a.displayName; });
        if (this.assets && this.assets.length) {
            if (this.assets.length === 1) {
                this.assetsFieldName = this.assets[0].displayName;
            }
            else {
                this.assetsFieldName = this.assets.length + " assets";
            }
        }
    };
    __decorate([
        EntityField({
            data: ɵ3,
            parse: ɵ4,
        }),
        __metadata("design:type", Object)
    ], EvidenceEntity.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'merge_by_key' }),
        __metadata("design:type", String)
    ], EvidenceEntity.prototype, "mergeByKey", void 0);
    __decorate([
        EntityField({ data: 'urn' }),
        __metadata("design:type", String)
    ], EvidenceEntity.prototype, "urn", void 0);
    __decorate([
        EntityField({
            data: 'first_seen',
        }),
        __metadata("design:type", Date)
    ], EvidenceEntity.prototype, "firstSeen", void 0);
    __decorate([
        EntityField({ data: ɵ5 }),
        __metadata("design:type", AirsEntityTypeConfig)
    ], EvidenceEntity.prototype, "entityType", void 0);
    __decorate([
        EntityField({ data: 'display_name' }),
        __metadata("design:type", String)
    ], EvidenceEntity.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'impacted_assets', arrayOf: EvidenceAsset }),
        __metadata("design:type", Array)
    ], EvidenceEntity.prototype, "assets", void 0);
    __decorate([
        EntityField({
            data: 'entity_detection_src_list',
            arrayOf: EvidenceDetectionSource,
            parse: ɵ6,
        }),
        __metadata("design:type", Array)
    ], EvidenceEntity.prototype, "detectionContexts", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", AirsEntityVerdict)
    ], EvidenceEntity.prototype, "verdict", void 0);
    __decorate([
        EntityField({ data: 'remediation_status', require: 'remediation_status' }),
        __metadata("design:type", AirsEntityRemediationStatus)
    ], EvidenceEntity.prototype, "state", void 0);
    __decorate([
        EntityField({ data: 'status_details' }),
        __metadata("design:type", Array)
    ], EvidenceEntity.prototype, "statusDetails", void 0);
    __decorate([
        EntityField({
            data: 'investigation',
            parse: ɵ7,
        }),
        __metadata("design:type", Object)
    ], EvidenceEntity.prototype, "investigationId", void 0);
    __decorate([
        EntityField({
            data: 'threats',
            parse: ɵ8,
        }),
        __metadata("design:type", String)
    ], EvidenceEntity.prototype, "threatsDisplayName", void 0);
    __decorate([
        EntityField({ data: 'threatsKeys' }),
        __metadata("design:type", Array)
    ], EvidenceEntity.prototype, "threatsI18nKeys", void 0);
    __decorate([
        EntityField({
            data: 'remediation_actions',
        }),
        __metadata("design:type", Object)
    ], EvidenceEntity.prototype, "rawRemediationActions", void 0);
    __decorate([
        EntityField({
            data: 'all_actions',
        }),
        __metadata("design:type", Object)
    ], EvidenceEntity.prototype, "rawAllActions", void 0);
    __decorate([
        EntityField({ data: 'is_converged_platform' }),
        __metadata("design:type", Boolean)
    ], EvidenceEntity.prototype, "isConvergedPlatform", void 0);
    __decorate([
        EntityField({ data: 'is_office_entity' }),
        __metadata("design:type", Boolean)
    ], EvidenceEntity.prototype, "_isOfficeEntity", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Object)
    ], EvidenceEntity.prototype, "_rawData", void 0);
    EvidenceEntity = __decorate([
        Entity({
            singularName: 'Evidence',
            pluralName: 'Evidence',
            endpoint: ɵ0,
            parseData: ɵ1,
            allItemsProperty: 'data',
            separateArrayParams: true,
            baseUrl: ɵ2,
            timeout: 1000 * 60 * 5,
        }),
        __metadata("design:paramtypes", [Object])
    ], EvidenceEntity);
    return EvidenceEntity;
}(EntityModelBase));
export { EvidenceEntity };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
