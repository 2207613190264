import { EvaluationMachineOperatingSystem } from './evaluation-machine.entity';
import { Feature } from '@wcd/scc-common';
import { FlavorConfig } from '@wcd/scc-common';

export enum EvaluationStepType {
	Setup = 'setup',
	SetupInProgress = 'setupInProgress',
	Evaluation = 'evaluation',
	EvaluationConnectToMachine = 'evaluationConnectToMachine',
	EvaluationRunSimulations = 'evaluationRunSimulations',
	EvaluationInvestigations = 'evaluationInvestigations',
	EvaluationIncidents = 'evaluationIncidents',
	EvaluationHunt = 'evaluationHunt',
	EvaluationThreats = 'evaluationThreats',
	EvaluationEnd = 'evaluationEnd',
	EvaluationEndSummary = 'evaluationEndSummary',
	EvaluationEndFeedback = 'evaluationEndFeedback',
	EvaluationTvm = 'evaluationTvm',
	EvaluationCheckForDevices = 'evaluationCheckForDevices',
}

export enum EvaluationStepStatus {
	'Created' = 'Created',
	'InProgress' = 'InProgress',
	'Completed' = 'Completed',
}

export interface LabConfigurationOption {
	id: number;
	numberOfMachines: number;
	hoursPerMachine: number;
}

export const DEFAULT_LAB_CONFIGURATION_ID = 4;

// Supported evaluation machines configurations
export const LAB_CONFIGURATION: Array<LabConfigurationOption> = [
	{
		id: 3,
		numberOfMachines: 3,
		hoursPerMachine: 72,
	},
	{
		id: 4,
		numberOfMachines: 4,
		hoursPerMachine: 48,
	},
	{
		id: 8,
		numberOfMachines: 8,
		hoursPerMachine: 24,
	},
	{
		id: 16,
		numberOfMachines: 16,
		hoursPerMachine: 12,
	},
];

export interface EvaluationMachineOs {
	id: EvaluationMachineOperatingSystem;
	keyName: string;
}

export const MACHINE_OS_VALUES: Array<EvaluationMachineOs> = [
	{
		id: EvaluationMachineOperatingSystem.Windows10,
		keyName: 'evaluation.dashboard.dataView.commandBar.addMachine.machineOs.windows10',
	},
	{
		id: EvaluationMachineOperatingSystem.WindowsServer2019,
		keyName: 'evaluation.dashboard.dataView.commandBar.addMachine.machineOs.windowsServer2019',
	},
];

export enum EvaluationSimulatorAgent {
	All = 'All',
	AttackIQ = 'AttackIQ',
	SafeBreach = 'SafeBreach',
}

export enum DIYActionType {
	openInNewWindow = 'openInNewWindow',
	copyDataToClipBoard = 'copyDataToClipBoard',
	manual = 'manual',
	external = 'external',
}

export interface DIYScenarioConfig {
	name: string;
	simulationAction: DIYActionType;
	simulationFile?: string;
	simulationWalkThroughFile: string;
	data?: Object;
	feature?: Feature;
	flavor?: FlavorConfig;
}

export interface DIYFileConfiguration {
	fileName: string;
	url: string;
}
