import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { ExceptionType } from './exception-type.enum';
import { VaRecommendationArgs } from './va-recommendation-args.value-object';
import { ScaRecommendationArgs } from './sca-recommendation-args.value-object';

@ValueObject({
	singularName: 'Exception arguments',
	pluralName: 'Exceptions arguments',
})
export class ExceptionArgs extends ModelBase {
	@EntityField({
		require: data => data.type === ExceptionType.SoftwarePatch,
		data: 'vaRecommendationArgs',
	})
	readonly vaRecommendationArgs?: VaRecommendationArgs;

	@EntityField({
		require: data => data.type === ExceptionType.ConfigurationChange,
		data: 'scaRecommendationArgs',
	})
	readonly scaRecommendationArgs?: ScaRecommendationArgs;

	@EntityField({
		required: true,
		data: 'type',
	})
	readonly type: ExceptionType;
}
