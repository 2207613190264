import { ReportConfig, ReportModel, M365_DASHBOARD_WIDGET_CLASS } from '../../../models/report.model';
import { ActiveAlertsWidget } from './components/active-alerts.widget';
import { ActiveAutomatedInvestigationsWidget } from './components/active-automated-investigations.widget';
import { AutomatedInvestigationsStatisticsWidget } from './components/automated-investigations-statistics.widget';
import { SuspiciousActivitiesWidget } from './components/suspicious-activities.widget';
import { MachinesAtRiskWidget } from './components/machines-at-risk.widget';
import { UsersAtRiskWidget } from './components/users-at-risk.widget';
import { DailyMachineReportingWidget } from './components/daily-machine-reporting.widget';
import { SensorHealthWidget } from './components/sensor-health.widget';
import { OnboardingWidget } from './components/onboarding.widget';
import { PromotionBannerWidget } from './components/promotion-banner.widget';
import { ReportWidgetsLayoutRowConfig } from '../../../models/report-widgets-layout.row.model';
import { ActiveIncidentsWidgetComponent } from './components/active-incidents.widget';
import { Feature } from '@wcd/config';
import { ExperienceMtpWidgetComponent } from './components/experience-mtp.widget/experience-mtp.widget.component';
import { ThreatProtectionDetectionSourceDashboardWidget } from '../../../../shared-reports/threat-protection-detection-source-dashboard-widget';
import { MachineProtectionActiveStatusOvertimeWidget } from '../../../../shared-reports/machine-protection-active-status-overtime-widget';

const securityOperationsReportSettings: ReportConfig = {
	id: 'securityoperationsdashboard',
	name: 'Security operations dashboard',
	maxWidth: 1920,
	titleNameKey: 'securityOperations.title',
	allowExport: false,
	allowRangeSelect: false,
};

const messagesRow = {
	widgets: [ PromotionBannerWidget ]
}

const activeAlertsColumn = {
	widgets: [
		{
			type: OnboardingWidget,
			weight: 0
		},
		{
			type: ExperienceMtpWidgetComponent,
			custom: {
				features: [Feature.ExperienceMtp],
			},
		},
		{
			type: ActiveAlertsWidget,
			custom: {
				disableFeatures: [Feature.IncidentsPhase2],
			},
		},
	],
};

const activeAlertsColumnInScc = {
	widgets: [
		{
			type: OnboardingWidget,
			weight: 0,
		},
		{
			type: ExperienceMtpWidgetComponent,
			custom: {
				features: [Feature.ExperienceMtp],
			},
		},
		{
			type: ActiveIncidentsWidgetComponent,
			custom: {
				features: [Feature.IncidentsDashboardWidget],
			},
		},
		{
			type: ActiveAlertsWidget,
			custom: {
				disableFeatures: [Feature.IncidentsPhase2],
			},
		},
	],
};

const automatedIrColumn = {
	widgets: [ActiveAutomatedInvestigationsWidget, AutomatedInvestigationsStatisticsWidget],
};
const healthRow = {
	widgets: [SensorHealthWidget],
};
const atRiskRow = {
	columns: [{ widgets: [MachinesAtRiskWidget] }, { widgets: [UsersAtRiskWidget] }],
};
const atRiskColumn = {
	widgets: [MachinesAtRiskWidget, UsersAtRiskWidget],
};
const alertsAutoIrRow = {
	columns: [activeAlertsColumn, automatedIrColumn],
};
const alertsAndAtRiskRow = {
	columns: [activeAlertsColumnInScc, atRiskColumn],
};
const activeDevicesRow = {
	widgets: [
		{
			type: DailyMachineReportingWidget,
			custom: {
				disableFeatures: [Feature.ReplaceDailyMachineCountInDashboard],
			},
		},
		{
			type: MachineProtectionActiveStatusOvertimeWidget,
			custom: {
				features: [Feature.ReplaceDailyMachineCountInDashboard],
			},
		},
	],
};
const activeStatusOvertimeRow = {
	widgets: [
		{
			type: MachineProtectionActiveStatusOvertimeWidget,
			custom: {
				features: [Feature.ReplaceDailyMachineCountInDashboard],
			},
		},
	]
};
const SuspiciousActivitiesColumn = {
	widgets: [
		{
			type: SuspiciousActivitiesWidget,
			custom: {
				disableFeatures: [Feature.ReplaceSuspiciousActivitiesInDashboard],
			},
		},
		{
			type: ThreatProtectionDetectionSourceDashboardWidget,
			custom: {
				features: [Feature.ReplaceSuspiciousActivitiesInDashboard],
			},
		},
	],
};

export let SecurityOperationsDashboardReportInScc: ReportModel = new ReportModel({
	...securityOperationsReportSettings,
	rows: [
		messagesRow,
		alertsAndAtRiskRow,
		healthRow,
		activeStatusOvertimeRow,
	],
	className: M365_DASHBOARD_WIDGET_CLASS,
});

export const SecurityOperationsDashboardReportNoRbacRowsInScc: Array<ReportWidgetsLayoutRowConfig> = [
	messagesRow,
	alertsAndAtRiskRow,
	{
		columns: [
			healthRow,
			SuspiciousActivitiesColumn,
		],
	},
	activeDevicesRow,
];

export let SecurityOperationsDashboardReport: ReportModel = new ReportModel({
	...securityOperationsReportSettings,
	rows: [
		messagesRow,
		alertsAutoIrRow,
		atRiskRow,
		healthRow,
		activeStatusOvertimeRow
	],
	className: M365_DASHBOARD_WIDGET_CLASS,
});

export const SecurityOperationsDashboardReportNoRbacRows: Array<ReportWidgetsLayoutRowConfig> = [
	messagesRow,
	alertsAutoIrRow,
	atRiskRow,
	{
		columns: [
			{
				rows: [healthRow],
			},
			SuspiciousActivitiesColumn,
		],
	},
	activeDevicesRow
];
