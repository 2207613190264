var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Software } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
var SoftwareInventoryEntityPanelComponent = /** @class */ (function (_super) {
    __extends(SoftwareInventoryEntityPanelComponent, _super);
    function SoftwareInventoryEntityPanelComponent(changeDetectorRef, paris) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.softwareRepository = paris.getRepository(Software);
        return _this;
    }
    SoftwareInventoryEntityPanelComponent.prototype.initEntity = function (software, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, software, isExtendedData);
        this.changeDetectorRef.markForCheck();
        if (this.entity instanceof Software) {
            this.software = this.entity;
        }
        else {
            this.fetchSoftwaredata(this.entity);
        }
    };
    SoftwareInventoryEntityPanelComponent.prototype.fetchSoftwaredata = function (softwareInstallation) {
        var _this = this;
        this.softwareInstallation = softwareInstallation;
        if (!softwareInstallation.isNormalized) {
            // If no cpe exist for this software Installation, we display only vendor name
            this.software = new Software({
                id: softwareInstallation.id,
                name: softwareInstallation.name,
                vendor: softwareInstallation.vendor,
                isNormalized: false,
                threatInfo: { hasExploit: false },
                exposureScoreImprovement: 0,
            });
        }
        else {
            var softwareId = softwareInstallation.getProductId();
            this.softwareRepository.getItemById(softwareId).subscribe(function (software) {
                _this.software = software;
                _this.changeDetectorRef.markForCheck();
            });
        }
    };
    return SoftwareInventoryEntityPanelComponent;
}(EntityPanelComponentBase));
export { SoftwareInventoryEntityPanelComponent };
