import { ChangeDetectorRef, Component } from '@angular/core';
import { LegacyUser } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { EntityDetailsMode } from '../../../global_entities/models/entity-details-mode.enum';

@Component({
	selector: 'user-entity-panel',
	templateUrl: './user-entity-panel.component.html',
})
export class UserEntityPanelComponent extends EntityPanelComponentBase<LegacyUser> {
	readonly EntityDetailsMode = EntityDetailsMode;

	get user(): LegacyUser {
		return this.entity;
	}

	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}
}
