/* tslint:disable:template-accessibility-alt-text */
import { Component, Input, OnDestroy } from '@angular/core';
import { AgentEndpointsManagementState } from '@wcd/domain';
import {
	DetectionTestSettingsService,
	TestDetectionSettings,
} from '../../services/endpoint-management-detection-test-settings.service';
import { EndpointManagementService } from '../../services/endpoint-management.service';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'agent-onboarding',
	templateUrl: './agent-onboarding.component.html',
})
export class AgentOnboardingComponent implements OnDestroy {
	OsType: typeof OsType = OsType;

	loading: boolean = true;
	error: boolean = false;
	onboardingState: AgentEndpointsManagementState = new AgentEndpointsManagementState(null);
	detectionTestSettings: TestDetectionSettings = this.detectionTestSettingsService.all.agent;
	getOnboardingStateSubscription: Subscription;
	osTypeKey: OsType = OsType.client;

	@Input()
	set isServer(isServer: boolean) {
		this.osTypeKey = isServer ? OsType.server : OsType.client;
	}

	constructor(
		private paris: Paris,
		private endpointManagementService: EndpointManagementService,
		private detectionTestSettingsService: DetectionTestSettingsService,
		private dialogsService: DialogsService,
		private i18nService: I18nService
	) {
		this.getStateFromBackend();
	}

	ngOnDestroy() {
		this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
	}

	getStateFromBackend() {
		this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
		this.getOnboardingStateSubscription = this.endpointManagementService
			.getAgentOnboardingState()
			.subscribe(
				(state: AgentEndpointsManagementState) => {
					this.loading = false;
					this.setState(state);
				},
				error => {
					this.loading = false;
					this.error = true;
					this.dialogsService.showError({
						title: this.i18nService.get(
							'endpointManagement.agent.onboarding.getMachineStatus.failure'
						),
						data: error,
					});
				}
			);
	}

	setState(state: AgentEndpointsManagementState) {
		this.onboardingState = state;

		if (
			this.onboardingState.status &&
			this.onboardingState.status !==
				this.endpointManagementService.getAgentOnboardingStatusByType('inProgress')
		)
			this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
	}

	confirmAndTurnOnServerMonitoring() {
		this.dialogsService
			.confirm({
				title: this.i18nService.get(
					`endpointManagement.agent.onboarding.step1.${this.osTypeKey}.confirm.title`
				),
				text: this.i18nService.get(
					`endpointManagement.agent.onboarding.step1.${this.osTypeKey}.confirm.description`
				),
				confirmText: this.i18nService.get(
					`endpointManagement.agent.onboarding.step1.${this.osTypeKey}.confirm.yes`
				),
			})
			.then((e: ConfirmEvent) => e.confirmed && this.doTurnOn());
	}

	doTurnOn() {
		this.endpointManagementService
			.turnOnServerMonitoring()
			.pipe(map((data: any) => !!data))
			.subscribe(
				(success: boolean) => {
					this.getStateFromBackend();
				},
				error => {
					this.dialogsService.showError({
						title: this.i18nService.get(
							'endpointManagement.agent.onboarding.turnOnServer.failure'
						),
						data: error,
					});
				}
			);
	}
}

enum OsType {
	client = 'client',
	server = 'server',
}
