import {
	Component,
	Input,
	ViewChild,
	ViewContainerRef,
	ComponentRef,
	ComponentFactoryResolver,
	ViewEncapsulation,
	ChangeDetectorRef,
} from '@angular/core';
import { TypedChanges } from '@wcd/angular-extensions';
import { Tag } from '@wcd/domain';
import { Observable, BehaviorSubject, Subject, merge } from 'rxjs';
import { shareReplay, filter } from 'rxjs/operators';
import { toObservable } from '../../../utils/rxjs/utils';
import { ICommandBarItem } from '../../../shared/components/command-bar/models/command-bar-item.models';
import { EntityModelBase } from '@microsoft/paris';
import { ICommandBarStyles, IIconStyles, MessageBarType, getTheme } from 'office-ui-fabric-react';
import { breadcrumbsStateService, Breadcrumb } from '@wcd/shared';
import { MainAppState, MainAppStateService } from '../../../shared/main/services/main-app-state.service';
import { EntityType } from '../../models/entity-type.interface';
import { EntityHeaderBottomComponentBase } from '../entity-header-bottom/entity-header-bottom.component.base';
import { config } from '@wcd/shared';
import { EntityPageViewMode } from '../../models/entity-page-view-mode.enum';
import { memoize } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';
import {getEntityIconThemeMapping} from "../entity-icon-theme-mapping";

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'entity-upper-bar',
	templateUrl: './entity.upper-bar.component.html',
	styleUrls: ['./entity.upper-bar.component.scss'],
})
export class EntityUpperBarComponent<
	TEntity extends EntityModelBase<TId>,
	TId extends string | number = string
> {
	@Input() commandBarItems$: Observable<ReadonlyArray<ICommandBarItem>>;
	@Input() breadcrumbs$: BehaviorSubject<Array<Breadcrumb>> = breadcrumbsStateService.breadcrumbs$;
	@Input() entityType: EntityType<TEntity>;
	@Input() mainAppState: MainAppState;
	@Input() entity: TEntity;

	@ViewChild('bottomHeaderEntityViewPlaceHolder', {read: ViewContainerRef, static: true})
	bottomHeaderEntityViewPlaceHolder: ViewContainerRef;

	onlyIconsScreenBreakpoint = config.msScreenSizeBreakpoints.xl;
	verticalLayoutBreakpoint = config.msScreenSizeBreakpoints.medium;
	iconName: string;
	iconCssClass: string;
	title: string;
	tags$: Observable<ReadonlyArray<Tag>>;
	MessageBarType = MessageBarType;
	entityNotification: string
	entityAsyncBottomNotification$: Observable<string>;
	private _tagsSubject$: Subject<ReadonlyArray<Tag>> = new Subject<ReadonlyArray<Tag>>();

	entityIconStyle = getIconStyle(null);
	iconContainerBackground = null;
	isVerticalLayout: boolean = undefined;
	entityPageViewMode = EntityPageViewMode;
	commandBarStyles = getCommandBarStyles(this.isVerticalLayout);
	headerBottomComponentRef: ComponentRef<EntityHeaderBottomComponentBase<TEntity>>;
	isScc = sccHostService.isSCC;

	showMigrationToggle: boolean = false;
	isMigrationToggleOn: boolean = false;

	constructor(
		private readonly resolver: ComponentFactoryResolver,
		private mainAppStateService: MainAppStateService,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {
	}

	ngOnInit() {
		this.mainAppStateService.state$
			.pipe(
				filter((state: MainAppState) => {
					return (
						this.isVerticalLayout !==
						config.widthBreakpointSmallerThan(
							state.screenMaxWidthBreakpoint,
							this.verticalLayoutBreakpoint
						)
					);
				})
			)
			.subscribe((state: MainAppState) => {
				this.isVerticalLayout = config.widthBreakpointSmallerThan(
					state.screenMaxWidthBreakpoint,
					this.verticalLayoutBreakpoint
				);
				this.commandBarStyles = getCommandBarStyles(this.isVerticalLayout);
				this.changeDetectorRef.detectChanges();
			});

		this.showMigrationToggle = this.entityType.showMigrationToggle && this.entityType.showMigrationToggle();
		this.isMigrationToggleOn = this.entityType.isMigrationToggledOn && this.entityType.isMigrationToggledOn();
	}

	ngOnChanges(changes: TypedChanges<EntityUpperBarComponent<TEntity, TId>>) {
		if (changes.entity || changes.entityType) {
			this.setBottomHeaderComponent();
			this.iconName = this.entityType.getIcon
				? this.entityType.getIcon([this.entity])
				: this.entityType.icon;

			this.iconCssClass =
				this.entityType.getIconCssClass && this.entityType.getIconCssClass([this.entity]);
			if (this.entityType.getTags) {
				this.tags$ = merge(
					toObservable(this.entityType.getTags([this.entity])),
					this._tagsSubject$
				).pipe(shareReplay(1));
			}

			const iconData = getEntityIconThemeMapping(getTheme())[this.entityType.id];
			if (iconData) {
				this.iconName = iconData.iconName;
				this.entityIconStyle = getIconStyle(iconData.iconColor);
				this.iconContainerBackground =  iconData.iconBackgroundColor;
			}

			this.title = this.entityType.getEntityName(this.entity);
			this.entityNotification = this.entityType.getEntityNotification && this.entityType.getEntityNotification(this.entity);
			this.entityAsyncBottomNotification$ =
				this.entityType.getAsyncBottomEntityNotification &&
				this.entityType.getAsyncBottomEntityNotification(this.entity);
		}
	}

	private setBottomHeaderComponent() {
		if (
			!(this.headerBottomComponentRef && this.headerBottomComponentRef.instance) &&
			this.entityType.entityHeaderBottomComponentType
		) {
			const factory = this.resolver.resolveComponentFactory(
				this.entityType.entityHeaderBottomComponentType
			);
			this.headerBottomComponentRef = this.bottomHeaderEntityViewPlaceHolder.createComponent(factory);

			this.bottomHeaderEntityViewPlaceHolder.insert(this.headerBottomComponentRef.hostView);
			this.headerBottomComponentRef.instance.setEntity(this.entity);
		}
	}

	toggleMigrationFlag () {
		this.isMigrationToggleOn = !this.isMigrationToggleOn;

		sccHostService.log.trackEvent(`${this.entityType.id}-switch-to-new-page`, { isToggleOn: this.isMigrationToggleOn});
		localStorage.setItem(this.getMigrationLocalStorageKey(), this.isMigrationToggleOn.toString());

		const navigationModel = this.entityType.getNavigationModel(this.entity);
		if (navigationModel.route) {
			sccHostService.state.go(navigationModel.route, navigationModel.queryParams, {reload: true});
		} else {
			window.location.reload();
		}
	}

	getMigrationLocalStorageKey() {
		return this.entityType.getMigrationLocalStorageKey ? this.entityType.getMigrationLocalStorageKey() : `${this.entityType.id}-prefer-old-page`;
	}

	getIsMigrationToggleDisabled() {
		return this.entityType.isMigrationToggleDisabled ? this.entityType.isMigrationToggleDisabled() : this.isMigrationToggleOn;
	}

	getMigrationToggleTitle (isOn) {
		return this.entityType.getMigrationToggleTitle ? this.entityType.getMigrationToggleTitle(isOn) : ""; // This should be implemented if a migration toggle is on
	}
}

const getIconStyle = (iconColor) => {
	const entityIconStyle: IIconStyles = {
		root: {
			fontSize: '32px',
			lineHeight: '72px',
			width: '72px',
			textAlign: 'center',
			verticalAlign: 'super',
			marginLeft: '2px',
			color: iconColor
		},
	};
	return entityIconStyle;
}
const getCommandBarStyles = memoize((verticalLayout) => {
	const commandBarStyles: ICommandBarStyles = {
		root: {
			padding: 0,
			minWidth: buttonSize,
			height: buttonSize,
			backgroundColor: 'transparent',
			position: 'relative',
		},
		primarySet: {
			justifyContent: verticalLayout ? 'flex-start' : 'flex-end',
			paddingRight: '0px',
			paddingLeft: '0px',
		},
	};
	return commandBarStyles;
})

const buttonSize = 48;
