import { ChangeDetectionStrategy, Component, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { File } from '@wcd/domain';
import { MessageBarType } from 'office-ui-fabric-react';

@Component({
	selector: 'file-collect-sample-panel',
	template: `
		<wcd-panel (close)="destroy()" (save)="closePanel()" [settings]="settings">
			<form
				#sampleCollectForm="ngForm"
				(keydown.enter)="$event.preventDefault()"
				class="wcd-full-height wcd-flex-vertical"
			>
				<div class="wcd-flex-1">
					<div class="subtle wcd-margin-large-bottom">
						{{ file.name }}
					</div>
					<div class="wcd-margin-xsmall-bottom">
						{{ 'file.sample.collect.collectReasonHeader' | i18n }}
					</div>
					<textarea
						class="form-control comment-input wcd-full-width wcd-margin-large-bottom wcd-required wcd-no-resize"
						[placeholder]="'file.sample.collect.collectReasonPlaceholder' | i18n"
						maxlength="200"
						autofocus
						required
						rows="5"
						[(ngModel)]="sampleCollectionReason"
						name="reason"
						[attr.aria-label]="'file.sample.collect.collectReasonHeader' | i18n"
					></textarea>
				</div>
				<div class="wcd-flex-none wcd-padding-all">
					<fab-message-bar
						[messageBarType]="MessageBarType.info"
						[styles]="{ text: { marginBottom: 16 } }"
					>
						{{ 'file.sample.collect.collectionDurationInfo' | i18n }}
					</fab-message-bar>
				</div>
				<div class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
					<fab-primary-button
						[disabled]="!isValid()"
						(onClick)="collectSample($event)"
						(keyup.enter)="collectSample($event)"
						className="wcd-margin-small-right"
						data-track-type="Button"
						[text]="'file.sample.collect.buttons.submit' | i18n"
						type="submit"
					>
					</fab-primary-button>
					<fab-default-button
						(onClick)="closePanel()"
						(keyup.enter)="closePanel()"
						data-track-type="Button"
						[text]="'file.sample.collect.buttons.cancel' | i18n"
					>
					</fab-default-button>
				</div>
			</form>
		</wcd-panel>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileCollectSamplePanelComponent extends PanelContainer {
	readonly MessageBarType = MessageBarType;

	@Input() file: File;
	@Input() sampleCollectionReason: string;
	@Output() onCollectSample: EventEmitter<void> = new EventEmitter<void>();
	@Output() onDestroyPanel: EventEmitter<boolean> = new EventEmitter<boolean>();
	private collectPressed: boolean = false;

	constructor(protected router: Router) {
		super(router);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.onDestroyPanel.emit(this.collectPressed);
	}

	isValid(): boolean {
		return !!this.sampleCollectionReason;
	}

	collectSample(event: MouseEvent) {
		event.preventDefault();
		this.onCollectSample.emit();
		this.collectPressed = true;
		this.closePanel();
	}
}
