import { Entity } from '@microsoft/paris';
import { AuthenticatedScanResult, TvmAuthenticatedScanSharedEntityConfigurations } from '@wcd/domain';

//Adding this entity because we use in this case another endpoint for csv as we cover our results with {output: "", endOfFile:""}
// in the original one for the polling mechanism
@Entity({
	...TvmAuthenticatedScanSharedEntityConfigurations,
	singularName: 'Authenticated scan result as csv',
	pluralName: 'Authenticated scan results as csv',
	endpoint: 'commands/liveResultsCsv',
})
export class AuthenticatedScanResultCsv extends AuthenticatedScanResult {}
