/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./suppression-rule-comments.component.ngfactory";
import * as i5 from "./suppression-rule-comments.component";
import * as i6 from "@microsoft/paris/dist/lib/paris";
import * as i7 from "../services/suppression-rules.backend.service";
import * as i8 from "../../../dialogs/services/dialogs.service";
import * as i9 from "../../../insights/services/app-insights.service";
import * as i10 from "./suppression-rule-comments.panel.component";
import * as i11 from "@angular/router";
var styles_SuppressionRuleCommentsPanelComponent = [];
var RenderType_SuppressionRuleCommentsPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SuppressionRuleCommentsPanelComponent, data: {} });
export { RenderType_SuppressionRuleCommentsPanelComponent as RenderType_SuppressionRuleCommentsPanelComponent };
export function View_SuppressionRuleCommentsPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 6, "wcd-panel", [], null, [[null, "close"], [null, "startClose"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } if (("startClose" === en)) {
        var pd_2 = (_co.startClose() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close", startClose: "startClose" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 1, "suppression-rule-comments", [], null, null, null, i4.View_SuppressionRuleCommentsComponent_0, i4.RenderType_SuppressionRuleCommentsComponent)), i0.ɵdid(6, 114688, null, 0, i5.SuppressionRuleCommentsComponent, [i6.Paris, i7.SuppressionRulesBackendService, i8.DialogsService, i9.AppInsightsService], { rule: [0, "rule"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.rule; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_SuppressionRuleCommentsPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "notification-rule-edit-panel", [], null, null, null, View_SuppressionRuleCommentsPanelComponent_0, RenderType_SuppressionRuleCommentsPanelComponent)), i0.ɵdid(1, 245760, null, 0, i10.SuppressionRuleCommentsPanelComponent, [i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuppressionRuleCommentsPanelComponentNgFactory = i0.ɵccf("notification-rule-edit-panel", i10.SuppressionRuleCommentsPanelComponent, View_SuppressionRuleCommentsPanelComponent_Host_0, { settings: "settings", rule: "rule" }, {}, []);
export { SuppressionRuleCommentsPanelComponentNgFactory as SuppressionRuleCommentsPanelComponentNgFactory };
