import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { detectionSourceValues } from './detection-source.entity.values';

@Entity({
	singularName: 'Detection Source',
	pluralName: 'Detection Sources',
	values: detectionSourceValues,
})
export class DetectionSource extends EntityModelBase {
	@EntityField() name: string;
	@EntityField() nameI18nKey: string;
	@EntityField() type: string;
	@EntityField() logoUrl: string;
	@EntityField() link: string;
	@EntityField() isExternal: boolean;
	@EntityField() isAirs: boolean;
	@EntityField({ defaultValue: false })
	isSelectable: boolean;
	@EntityField({ defaultValue: false })
	isThirdParty: boolean;
	@EntityField() priority: number;
	@EntityField({ defaultValue: false })
	isGroupedThirdParty: boolean;
	@EntityField({ defaultValue: false })
	isExtended: boolean;
	@EntityField({ defaultValue: false })
	isMdeDetectionSource: boolean;
	@EntityField({ defaultValue: false })
	isSmbDetectionSource: boolean;

}
