import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
	ViewChildren,
	QueryList,
	NgZone,
	ElementRef,
	AfterViewInit,
} from '@angular/core';
import { FilterSelectedValues } from '../models/filter-selected-values.type';
import { FilterFieldChange } from '../models/filter-field-change.interface';
import { isEqual, isNil, omitBy, sortBy } from 'lodash-es';
import { FiltersField } from '../models/filters-field.interface';
import { FiltersFieldId } from '../models/filters-field-id.type';
import { FiltersFieldComponent } from './filters-field.component';
import { FiltersState } from '../models/filters-state.interface';
import { SerializedFilters } from '../models/serialized-filters.type';
import { Observable, of, combineLatest } from 'rxjs';
import { map, debounceTime, filter } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { Focusable } from '@wcd/forms';
import { LiveAnnouncer } from '@angular/cdk/a11y';

const LARGE_NUMBER = 10 ** 6;

@Component({
	selector: 'wcd-filters',
	templateUrl: './filters.component.html',
	styleUrls: ['./filters.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements AfterViewInit {
	@Input() fields: Array<FiltersField>;
	private _data: Record<FiltersFieldId, any>;

	@Input()
	set data(data: Record<FiltersFieldId, any>) {
		this._data = data;
		this.changeDetectorRef.markForCheck();
	}

	get data(): Record<FiltersFieldId, any> {
		return this._data || {};
	}

	@Input() parentFocusable?: () => void;

	@Output() filtersChange: EventEmitter<FiltersState> = new EventEmitter<FiltersState>();
	@Output() clear: EventEmitter<void> = new EventEmitter();
	@Output() filtersCancel: EventEmitter<void> = new EventEmitter();

	private _changedFields: Map<FiltersField, any>;

	fieldSelectedValues: Map<FiltersFieldId, any> = new Map();
	@Input()
	set selectedValues(selectedValues: Map<FiltersFieldId, any>) {
		this.fieldSelectedValues = new Map(selectedValues);
	}

	/*
	resets the selected values, used by the dataview to change the values to
	default values giving to the dataview
	 */
	resetSelectedValues(selectedValues: Record<FiltersFieldId, any>) {
		setTimeout(() => {
			this.fieldSelectedValues = new Map(Object.entries(selectedValues || {}));
			this.changeDetectorRef.detectChanges();
		})
	}

	@Input() fixedSelectedValues: SerializedFilters;

	private _serializedFilters: SerializedFilters;

	@Input()
	set serializedFilters(serializedFilters: SerializedFilters) {
		if (this._fieldComponents) this.selectSerializedFilters(serializedFilters);
		else this._serializedFilters = serializedFilters;
	}

	@Input() allowFocusOnFirstElement: boolean = false;
	@Input() checkFocus: boolean = false;

	@ViewChildren('focusable') focusable: QueryList<ElementRef | Focusable>;

	private _fieldComponents: QueryList<FiltersFieldComponent<any, any, any>>;

	@ViewChildren(FiltersFieldComponent)
	private set fieldComponents(fieldComponents: QueryList<FiltersFieldComponent<any, any, any>>) {
		this._fieldComponents = fieldComponents;
		this._fieldComponents.changes
			.pipe(
				filter(() => !!this._serializedFilters),
				debounceTime(50)
			)
			.subscribe(() => {
				if (this._serializedFilters && this._fieldComponents.length) {
					this.selectSerializedFilters(this._serializedFilters);
					this._serializedFilters = null;
				}
			});
	}

	get fieldsWithData(): Array<FiltersField> {
		return sortBy(
			this.fields.filter((field => (field.requiresData === false || (this.data && this.data[field.id])) && this.isVisible(field))),
			[(field: FiltersField) => (!isNaN(field.priority) ? field.priority : LARGE_NUMBER)]
		);
	}

	private isVisible(field: FiltersField): boolean{
		if(!field.isHidden)
			return true;

		// Either _serializedFilters or fieldSelectedValues is populated in a given time
		if(this._serializedFilters!==null)
		 	return this._serializedFilters[field.id]!==undefined;
		else
			return this.fieldSelectedValues.has(field.id)
	}

	private _removedFilterFields: Set<FiltersField> = new Set();

	get isDirty(): boolean {
		return !!(
			(this._changedFields && this._changedFields.size) ||
			(this._removedFilterFields && this._removedFilterFields.size)
		);
	}

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private ngZone: NgZone,
		public i18nService: I18nService,
		private liveAnnouncer: LiveAnnouncer
	) {}

	ngAfterViewInit() {
		if (this.allowFocusOnFirstElement) {
			this.focusOnFirstFocusable();
		}
	}

	onFieldChange<TSelection>(fieldChange: FilterFieldChange<TSelection>) {
		if (!this._changedFields) this._changedFields = new Map();
		const { field, selectedValues } = fieldChange;

		if (selectedValues && (!(selectedValues instanceof Array) || selectedValues.length)) {
			this._changedFields.set(field, selectedValues);
		} else {
			this._changedFields.delete(field);
			this._removedFilterFields.add(field);
		}

		if (isEqual(this.getNewSelectedValues(), this.fieldSelectedValues)) this.cancel(false);
	}

	private selectSerializedFilters(serializedFilters: SerializedFilters) {
		this.deserialize(serializedFilters).subscribe(deserialized => {
			this.selectedValues = deserialized;
			this.changeDetectorRef.markForCheck();
		});
	}

	clearAllFilters() {
		this.liveAnnouncer.announce(this.i18nService.strings.filters_cleared, 'assertive').then(() => {
			setTimeout(() => {
				this.ngZone.run(() => {
					this.clearChanges();
					this._fieldComponents.forEach(component => (component.selectedValues = null));
					this.fieldSelectedValues.clear();
					this.triggerChange();
					this.focusOnFirstFocusable();
				});
			}, 700);
		});
	}

	apply() {
		this.liveAnnouncer.announce(this.i18nService.strings.filters_applied, 'assertive').then(() => {
			setTimeout(() => {
				this.ngZone.run(() => {
					this.fieldSelectedValues = this.getNewSelectedValues();
					this.triggerChange();
					this.clearChanges();
					this.filtersCancel.emit();
					this.focusOnFirstFocusable();
				});
			},700);
		});
	}

	cancel(focusOnFirstElement = true) {
		this.liveAnnouncer.announce(this.i18nService.strings.filters_canceled, 'assertive').then(() => {
			setTimeout(() => {
				this.ngZone.run(() => {
					this._changedFields.forEach((filterSelection, field) =>
						this.resetFieldSelectedValues(field)
					);
					this._removedFilterFields.forEach(field => this.resetFieldSelectedValues(field));
					if (focusOnFirstElement) this.focusOnFirstFocusable();
					this.clearChanges();
					this.filtersCancel.emit();
				});
			}, 700);
		});
	}

	private resetFieldSelectedValues(field: FiltersField) {
		const fieldComponent = this._fieldComponents.find(component => component.field === field);
		fieldComponent.selectedValues = this.fieldSelectedValues.get(field.id);
	}

	private triggerChange() {
		this.filtersChange.emit(this.getChangeData());
	}

	private getChangeData(): FiltersState {
		const selection: Record<FiltersFieldId, any> = {};
		this.fieldSelectedValues.forEach((fieldSelection, fieldId) => (selection[fieldId] = fieldSelection));

		return {
			selection: selection,
			serialized: this.serialize(),
		};
	}

	clearChanges() {
		if (this._changedFields) this._changedFields.clear();
		this._removedFilterFields.clear();
	}

	serialize(): SerializedFilters {
		return omitBy(
			this._fieldComponents.reduce(
				(allFieldValues, fieldComponent) => ({
					...allFieldValues,
					...fieldComponent.serialize(),
				}),
				{}
			),
			isNil
		);
	}

	deserialize(fieldSerializedSelections: SerializedFilters): Observable<Map<FiltersFieldId, any>> {
		if (fieldSerializedSelections) {
			const fieldDeserializations$: Array<
				Observable<{ field: FiltersField; selection: any }>
			> = this._fieldComponents.map(fieldComponent =>
				fieldComponent.deserialize(fieldSerializedSelections).pipe(
					map(deserializedFieldSelection => ({
						field: fieldComponent.field,
						selection: deserializedFieldSelection,
					}))
				)
			);

			return combineLatest(fieldDeserializations$).pipe(
				map((fieldSelections: Array<{ field: FiltersField; selection: any }>) => {
					const deserializedSelection: Map<FiltersFieldId, any> = new Map();

					fieldSelections.forEach(fieldSelection => {
						const selectedValues = fieldSelection.selection;

						if (
							!isNil(selectedValues) &&
							(!(selectedValues instanceof Array) || selectedValues.length)
						)
							deserializedSelection.set(fieldSelection.field.id, selectedValues);
					});

					return deserializedSelection;
				})
			);
		}

		return of(new Map());
	}

	/**
	 * Returns a merge of the selected filter values with any new changes.
	 */
	private getNewSelectedValues(): Map<FiltersFieldId, any> {
		const changedSelectedValues: Map<FiltersFieldId, any> = new Map();

		this.fields.forEach(field => {
			const fieldNewSelectedValues = this._changedFields.get(field);
			if (fieldNewSelectedValues !== undefined) {
				if (fieldNewSelectedValues !== null)
					changedSelectedValues.set(field.id, fieldNewSelectedValues);
			} else if (!this._removedFilterFields.has(field)) {
				const currentFieldSelectedValues = this.fieldSelectedValues.get(field.id);
				if (!isNil(currentFieldSelectedValues))
					changedSelectedValues.set(field.id, currentFieldSelectedValues);
			}
		});

		return changedSelectedValues;
	}

	protected focusOnFirstFocusable() {
		if (this.parentFocusable){
			this.parentFocusable();
		}
		else{
			this._fieldComponents.first.setFocus();
		}

	}
}
