import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ViewChild,
} from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import {
	MachineGroup,
	MdeUserRoleActionEnum,
	WebContentFilteringCategoryType,
	WebContentFilteringCategoryTypes,
	WebContentFilteringPolicy,
} from '@wcd/domain';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { I18nService } from '@wcd/i18n';
import { MachinesService } from '../../machines/services/machines.service';
import { SpinnerSize } from 'office-ui-fabric-react';
import {
	ScopeTypesEnum,
	WebContentFilteringPolicyService,
} from '../services/web-content-filtering-policy.service';
import { ChecklistValue } from '@wcd/forms';
import { AuthService } from '@wcd/auth';
import { FilterValuesChecklistComponent, FilterValuesCheckListConfig } from '@wcd/ng-filters';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { AppFlavorConfig } from '@wcd/scc-common';

@Component({
	selector: 'web-content-filtering-policy-edit',
	templateUrl: './web-content-filtering-policy-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebContentFilteringPolicyEditComponent
	extends EntityPanelComponentBase<WebContentFilteringPolicy>
	implements AfterViewInit {
	readonly ScopeTypesEnum = ScopeTypesEnum;
	readonly SpinnerSize = SpinnerSize;

	isSaving: boolean = false;
	isDirty: boolean = false;
	isWebCategoryEnabled$: Observable<boolean>;
	allowEdit: boolean = true;

	// Category to block checklist
	@ViewChild(FilterValuesChecklistComponent, { static: false })
	categoryChecklist: FilterValuesChecklistComponent<number>;
	categoryChecklistConfig: FilterValuesCheckListConfig<string>;

	supportedCategoryIds: Array<number> = [];

	// Machine group
	loadingMachineGroups: boolean = true;
	allowSpecificMachineGroups: boolean = false;
	allowAllMachineGroups: boolean;
	selectableMachineGroupScopes: Array<ChecklistValue>;
	selectedMachineGroupScope: ChecklistValue;
	selectedMachineGroups: Array<ChecklistValue> = [];
	selectedMachineGroupsNames: Array<string> = [];
	availableMachineGroups: Array<ChecklistValue> = [];

	private _allMachineGroups: Array<MachineGroup>;
	private _rbacMachineGroupsEnabled: boolean;
	private _valuesLabelTexts: Map<string, string> = new Map<string, string>();

	private machineGroupSubscription: Subscription;
	private onMachineGroupsChangedSubscription: Subscription;
	private savePolicySubscription: Subscription;

	private machineGroupsRepo: Repository<MachineGroup>;
	private policyRepo: Repository<WebContentFilteringPolicy>;

	get machineGroupsFieldId(): string {
		return 'web-content-filtering-policy-machine-groups';
	}

	get policy(): WebContentFilteringPolicy {
		return this.entity;
	}

	get blockedCategories(): string[] {
		return this.policyService.getCategoryNames(this.policy);
	}

	constructor(
		private paris: Paris,
		private authService: AuthService,
		private dialogsService: DialogsService,
		changeDetectorRef: ChangeDetectorRef,
		private machinesService: MachinesService,
		private featuresService: FeaturesService,
		private flavorService: FlavorService,
		private entityPanelsService: EntityPanelsService,
		private policyService: WebContentFilteringPolicyService,
		private i18nService: I18nService,
		private readonly advancedFeaturesService: AdvancedFeaturesService
	) {
		super(changeDetectorRef);

		this.machineGroupsRepo = paris.getRepository(MachineGroup);
		this.policyRepo = paris.getRepository(WebContentFilteringPolicy);

		this._rbacMachineGroupsEnabled = this.featuresService.isEnabled(Feature.RbacMachineGroups);
		this.allowEdit = this.authService.currentUser.hasMdeAllowedUserRoleAction(
			MdeUserRoleActionEnum.securitySettings
		);

		this.supportedCategoryIds = this.paris
			.getRepository(WebContentFilteringCategoryType)
			.entity.values.filter((category) => this.policyService.filterCategory(category))
			.map((category) => category.id);
	}

	isScopeVisible = this.flavorService.isEnabled(AppFlavorConfig.settings.webContentFiltering);

	ngOnInit() {
		super.ngOnInit();
		this.selectableMachineGroupScopes = this.policyService.getSelectableMachineGroupScopes();

		const machineGroupsObservable: Observable<Array<MachineGroup>> = this._rbacMachineGroupsEnabled
			? this.setMachineGroups()
			: of(null);

		this.machineGroupSubscription = machineGroupsObservable.subscribe(
			() => {
				this.loadingMachineGroups = false;
				if (this._rbacMachineGroupsEnabled) {
					if (this.allowEdit) {
						this._setSelectedMachineGroups(this.policy.rbacGroupIds);
					} else {
						this._setSelectedMachineGroupsNames(this.policy.rbacGroupIds);
					}
				}
			},
			(error) => {
				this.loadingMachineGroups = false;
			}
		);

		this.isWebCategoryEnabled$ = this.advancedFeaturesService
			.getAdvancedFeaturesSettings()
			.pipe(switchMap((advancedFeatures) => of(advancedFeatures.webCategoriesEnabled)));

		if (this.policy.rbacGroupIds && this.policy.rbacGroupIds.length) {
			this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.Specific];
		} else {
			this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.All];
		}

		this.categoryChecklistConfig = this.policyService.getCategoryChecklistConfig();
	}

	ngOnDestroy() {
		this.machineGroupSubscription && this.machineGroupSubscription.unsubscribe();
		this.onMachineGroupsChangedSubscription && this.onMachineGroupsChangedSubscription.unsubscribe();
		this.savePolicySubscription && this.savePolicySubscription.unsubscribe();
	}

	ngAfterViewInit() {
		// Set category to block checklist initial values
		if (this.categoryChecklist) {
			this.categoryChecklist.setSelectedValues(this.policy.blockedCategoryIds);
		}
	}

	ngAfterViewChecked() {
		// make sure the selection gets updated if template view change
		if (this.categoryChecklist) {
			this.categoryChecklist.setSelectedValues(this.policy.blockedCategoryIds);
		}
	}

	setEntity(entity: WebContentFilteringPolicy) {
		const clonedEtity: WebContentFilteringPolicy = cloneDeep(entity);
		super.setEntity(clonedEtity);

		// Filter out unsupported categories
		this.policy.blockedCategoryIds = this.policy.blockedCategoryIds.filter((id) =>
			this.supportedCategoryIds.includes(id)
		);

		this.policy.auditCategoryIds = this.supportedCategoryIds.filter(
			(id) => !this.policy.blockedCategoryIds.includes(id)
		);
	}

	setMachineGroups(): Observable<Array<MachineGroup>> {
		return this.machineGroupsRepo.allItems$.pipe(
			tap((groups: Array<MachineGroup>) => {
				this._allMachineGroups = groups;
				this._setAvailableScopes();
			}),
			mergeMap(() => this._getUserExposedRbacGroups())
		);
	}

	onMachineGroupChange = (newOption: ChecklistValue) => {
		this.allowSpecificMachineGroups =
			newOption === this.selectableMachineGroupScopes[ScopeTypesEnum.Specific];

		if (!this.allowSpecificMachineGroups) {
			this._setSelectedMachineGroups((this.policy.rbacGroupIds = this.selectedMachineGroups = []));
			this.isDirty = true;
		}
	};

	onMachineGroupsChange(selectedMachineGroups: Array<ChecklistValue>) {
		if (!selectedMachineGroups || !selectedMachineGroups.length)
			this._setSelectedMachineGroups((this.policy.rbacGroupIds = []));
		else {
			this.onMachineGroupsChangedSubscription = combineLatest(
				selectedMachineGroups.map((selectedGroup: ChecklistValue) =>
					this.machineGroupsRepo.getItemById(selectedGroup.id)
				)
			).subscribe((machineGroups: Array<MachineGroup>) => {
				const machineGroupIds: Array<number> = machineGroups.map((group: MachineGroup) => group.id);
				this._setSelectedMachineGroups((this.policy.rbacGroupIds = machineGroupIds));
			});
		}

		this.isDirty = true;
	}

	onBlockedCategoriesChange(selectedIds: Array<number>) {
		this.policy.blockedCategoryIds = selectedIds;
		this.policy.auditCategoryIds = this.supportedCategoryIds.filter((id) => !selectedIds.includes(id));

		this.isDirty = true;
	}

	getLabelText(fieldId: string): string {
		return (
			this._valuesLabelTexts.get(fieldId) ||
			this.i18nService.get(
				'webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.selectValues'
			)
		);
	}

	setLabelText(fieldId: string, values: Array<ChecklistValue>) {
		const labelText: string = this._setLabelText(values),
			currentValue: string = this._valuesLabelTexts.get(fieldId);

		if (!currentValue || currentValue !== labelText) this._valuesLabelTexts.set(fieldId, labelText);
	}

	private _setSelectedMachineGroups(machineGroupIds: Array<number>) {
		this.selectedMachineGroups = this._allMachineGroups
			.filter((machineGroup: MachineGroup) => machineGroupIds.includes(machineGroup.id))
			.map((machineGroup: MachineGroup) => this._getMachineGroupCheckListValue(machineGroup));

		this.setLabelText(this.machineGroupsFieldId, this.selectedMachineGroups);
	}

	private _setSelectedMachineGroupsNames(machineGroupIds: Array<number>) {
		this.selectedMachineGroupsNames = this._allMachineGroups
			.filter((machineGroup: MachineGroup) => machineGroupIds.includes(machineGroup.id))
			.map((machineGroup: MachineGroup) => machineGroup.name);
	}

	private _setLabelText(values: Array<ChecklistValue>): string {
		if (values.length > 3) {
			return `${values.length} values`;
		}

		const _values = values.map((value) => value.name);
		if (_values.length) {
			return _values.join(', ');
		}

		return this.i18nService.get(
			`webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.selectValues`
		);
	}

	private _setAvailableScopes() {
		if (this._allMachineGroups.length) {
			this.allowSpecificMachineGroups = true;
			if (this.authService.currentUser.isMdeAdmin) {
				this.allowAllMachineGroups = true;
			} else {
				this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.Specific];
			}
		} else {
			this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.All];
		}
	}

	private _getUserExposedRbacGroups(): Observable<Array<MachineGroup>> {
		return this.machinesService.getFullUserExposedMachineGroups().pipe(
			tap((userExposedMachineGroups: Array<MachineGroup>) => {
				this.availableMachineGroups = this._allMachineGroups
					.filter((machineGroup: MachineGroup) => {
						return (
							userExposedMachineGroups &&
							userExposedMachineGroups.some(
								(group: MachineGroup) => group.id === machineGroup.id
							)
						);
					})
					.map((machineGroup: MachineGroup) => this._getMachineGroupCheckListValue(machineGroup));
			})
		);
	}

	private _getMachineGroupCheckListValue(machineGroup: MachineGroup): ChecklistValue {
		return {
			id: machineGroup.id,
			name: machineGroup.isUnassignedMachineGroup
				? this.i18nService.get('machineGroup.unassignedGroup.name')
				: machineGroup.name,
		};
	}

	get isValid(): boolean {
		if (
			this.loadingMachineGroups ||
			(this.selectedMachineGroupScope.id == ScopeTypesEnum.Specific &&
				(!this.policy.rbacGroupIds || !this.policy.rbacGroupIds.length))
		) {
			return false;
		}

		return true;
	}

	save() {
		this.isSaving = true;
		this.savePolicySubscription = this.policyRepo.save(this.policy).subscribe(
			() => {
				this.isSaving = false;
				this.entityPanelsService.closeEntityPanel(WebContentFilteringPolicy);
			},
			(error) => {
				this.isSaving = false;
				this.dialogsService
					.showError({
						title: this.i18nService.get(
							'webContentFilteringPolicy.dataview.actions.edit.errorMessage',
							{ itemName: this.policy.policyName }
						),
						data: error,
					})
					.then(() => this.changeDetectorRef.markForCheck());
			}
		);
	}
}
