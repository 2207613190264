/**
 * Manages SCC static resource:
 * Static resource path
 */
export class SccResourceService {
	constructor(private sccResource) {}

	getUrl(string): string {
		return this.sccResource.getUrl(string);
	}

	getStaticResourceUrl(packageName: string, resource: string): string {
		const baseUrl = this.sccResource.getUrl(`${packageName}-static`);
		return `${baseUrl}/${resource}`;
	}

	load(moduleName: string): Promise<any> {
		return this.sccResource.load(moduleName);
	}

	version(packageName: string): string {
		return this.sccResource.version(packageName);
	}
}
