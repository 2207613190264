import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RbacService } from './rbac.service';
import { catchError, tap, map } from 'rxjs/operators';
import { MainAppStateService } from '../../shared/main/services/main-app-state.service';
import { I18nService } from '@wcd/i18n';
import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { EntityType } from '../../global_entities/models/entity-type.interface';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class RbacGroupGuard implements CanActivate {
	constructor(
		private router: Router,
		private rbacService: RbacService,
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		private mainAppStateService: MainAppStateService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private appContextService: AppContextService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		if (!this.featuresService.isEnabled(Feature.RbacMachineGroups)) return true;

		const data = route.routeConfig.data;
		if (data.rbacGroupGuardParams || data.entity) {
			const entityType: EntityType = this.globalEntityTypesService.getEntityType(
				data.rbacGroupGuardParams ? data.rbacGroupGuardParams.type : data.entity
			);
			const entitySingularName: string = entityType.entity.singularName.toLowerCase();

			return this.rbacService.isUserExposedToEntity(entityType, route.params.id).pipe(
				tap(userExposureResult => {
					if (!userExposureResult.isExposed) {
						this.appContextService.isSCC
							? sccHostService.state.go('Error.child', { path: 'UnauthorizedOnThisPage' })
							: this.rbacService.showNoAccessModal(
									userExposureResult.reason ||
										this.i18nService.get(
											`rbac.accessDenied.description.${entitySingularName}`,
											null,
											true
										) ||
										this.i18nService.get('rbac.accessDenied.description.global')
							  );
					}
				}),
				map(userExposureResult => userExposureResult.isExposed),
				catchError(error => {
					sccHostService.isSCC
						? setTimeout(() => sccHostService.state.go('Error.child', { path: '404' }))
						: this.router.navigateByUrl('/Error/404');
					return of(null);
				})
			);
		}

		return true;
	}
}
