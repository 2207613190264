// French
import localeFr from '@angular/common/locales/fr';
// Hebrew
import localeHe from '@angular/common/locales/he';
// Japanese
import localeJa from '@angular/common/locales/ja';
// Italian
import localeIt from '@angular/common/locales/it';
// German
import localeDe from '@angular/common/locales/de';
// Chinese
import localeZh from '@angular/common/locales/zh';
// Chinese simplified
import localeZhH from '@angular/common/locales/zh-Hans';
// Chinese traditional
import localeZhT from '@angular/common/locales/zh-Hant';
// Chinese traditional HK
import localeZhHk from '@angular/common/locales/zh-Hant-HK';
// English GB
import localeEnGb from '@angular/common/locales/en-GB';
// Arabic
import localeAr from '@angular/common/locales/ar';
// Hindi
import localeHi from '@angular/common/locales/hi';
// Korean
import localeKo from '@angular/common/locales/ko';
// Polish
import localePo from '@angular/common/locales/pl';
// Spanish
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

export function registerLocales() {
	[
		localeFr,
		localeHe,
		localeJa,
		localeIt,
		localeDe,
		localeZh,
		localeZhT,
		localeZhHk,
		localeEnGb,
		localeAr,
		localeHi,
		localeKo,
		localePo,
		localeEs,
		localeZhH,
	].forEach(locale => {
		registerLocaleData(locale);
	});
}
