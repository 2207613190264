import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';

@Entity({
	...airsEntityConfig,
	singularName: 'Cloud Application',
	pluralName: 'Cloud Applications',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsCloudApplication,
})
export class AirsCloudApplication extends AirsEntity {
	@EntityField({ data: 'app_id' })
	appId: string;

	@EntityField({ data: 'risk' })
	risk: string;
}
