import { Component, OnInit, Input, ComponentRef } from '@angular/core';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import { Observable, defer } from 'rxjs';

import { DataTableField } from '@wcd/datatable';
import { File, FileFileVerdictsRelationship, FileVerdict, Alert } from '@wcd/domain';

import { I18nService } from '@wcd/i18n';
import { map, tap, mergeMap, take } from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { FileVerdictAlertService } from '../services/file-verdict-alert.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { MalwareAlertsComponent } from './malware-alerts.component';
import { PanelType } from '@wcd/panels';
import { catchHttpError } from '../../../utils/rxjs/rxjs-custom-operators';
import { Location } from '@angular/common';

@Component({
	selector: 'file-malware-detected',
	template: `
		<ng-container *ngIf="(tableItems$ | async) as tableItems; else loadingTemplate">
			<div
				*ngIf="showSummary"
				class="wcd-font-size-s wcd-padding-small-top"
				[ngSwitch]="tableItems.length"
			>
				<p *ngSwitchCase="0">
					{{ 'files.entityDetails.sections.detections.fields.malwareDetected.none' | i18n }}
				</p>
				<p *ngSwitchCase="1">{{ tableItems[0].threatName }}</p>
				<p *ngSwitchDefault>
					{{ 'files.entityDetails.sections.detections.fields.malwareDetected.multiple' | i18n }}
				</p>
			</div>
			<div *ngIf="tableItems.length" class="wcd-padding-small-top">
				<wcd-datatable
					[items]="tableItems"
					[columns]="tableFields"
					[selectEnabled]="false"
					[label]="'files.entityDetails.sections.detections.fields.malwareDetected.title' | i18n"
				></wcd-datatable>
			</div>
		</ng-container>

		<ng-template #loadingTemplate>
			<fab-spinner></fab-spinner>
		</ng-template>
	`,
})
export class FileMalwareDetectedComponent implements OnInit {
	@Input() file: File;

	@Input() showSummary = true;

	private _malwareAlertsPanel: ComponentRef<MalwareAlertsComponent>;
	private readonly _alertEntityTypeService: EntityType<Alert>;

	fileFileVerdictRepo: RelationshipRepository<File, FileVerdict>;
	tableItems$: Observable<ReadonlyArray<FileVerdict>>;
	readonly tableFields = DataTableField.fromList<FileVerdict>([
		{
			id: 'malware',
			name: this.i18nService.get(
				'files.entityDetails.sections.detections.fields.malwareDetected.malware'
			),
			getDisplay: item => item.threatName,
		},
		{
			id: 'source',
			name: this.i18nService.get(
				'files.entityDetails.sections.detections.fields.malwareDetected.source'
			),
			getDisplay: (item: FileVerdict) =>
				item.sources
					.map(src =>
						src === 0
							? this.i18nService.get(
									'files.entityDetails.sections.detections.fields.malwareDetected.sources.windowsDefenderAv'
							  )
							: this.i18nService.get(
									'files.entityDetails.sections.detections.fields.malwareDetected.sources.cloudService'
							  )
					)
					.join(', '),
		},
		{
			id: 'seeAlertsLink',
			name: '',
			getDisplay: (item: FileVerdict) =>
				this.i18nService.get(
					`files.entityDetails.sections.detections.fields.malwareDetected.${
						item.relatedAlertsCount === 0
							? 'noAlert'
							: item.relatedAlertsCount > 1
							? 'seeAlerts'
							: 'seeAlert'
					}`
				),
			onClick: async (item: FileVerdict) => {
				if (item.relatedAlertsCount === 0) {
					return;
				}

				if (item.relatedAlertsCount === 1) {
					const alertLink = await this.fileVerdictAlertService
						.getFileVerdictAlerts(this.file.sha1, item)
						.pipe(
							map(alerts => this._alertEntityTypeService.getEntitiesLink(alerts)),
							take(1)
						)
						.toPromise();

					this.location.go(alertLink);
					return null;
				}

				return this.showMalwareAlertsPanel(item)
					.pipe(take(1))
					.toPromise();
			},
			getCssClass: item => (item.relatedAlertsCount > 0 ? 'btn-link' : ''),
		},
	]);

	constructor(
		private readonly i18nService: I18nService,
		private readonly paris: Paris,
		private dialogsService: DialogsService,
		globalEntityTypesService: GlobalEntityTypesService,
		private readonly fileVerdictAlertService: FileVerdictAlertService,
		private readonly location: Location
	) {
		this._alertEntityTypeService = globalEntityTypesService.getEntityType(Alert);
	}

	ngOnInit() {
		this.fileFileVerdictRepo = this.paris.getRelationshipRepository(FileFileVerdictsRelationship);
		this.fileFileVerdictRepo.sourceItem = this.file;
		this.tableItems$ = this.fileFileVerdictRepo.query(null, { allowCache: true }).pipe(
			catchHttpError(404, { items: [] }),
			map(result => result.items)
		);
	}

	showMalwareAlertsPanel(fileVerdict: FileVerdict): Observable<void> {
		return this.dialogsService
			.showPanel(
				MalwareAlertsComponent,
				{
					id: 'malware-alert',
					isModal: true,
					headerText: this.i18nService.get(
						'files.entityDetails.sections.detections.fields.malwareDetected.malwareAlertPanel.title'
					),
					showOverlay: true,
					back: { onClick: () => this._malwareAlertsPanel.destroy() },
					type: PanelType.large,
				},
				{
					sha1: this.file.sha1,
					fileVerdict: fileVerdict,
				}
			)
			.pipe(
				tap(panel => {
					this._malwareAlertsPanel = panel;
					panel.onDestroy(() => {
						this._malwareAlertsPanel = null;
					});
				}),
				mergeMap(panel => defer(() => new Promise(resolve => panel.onDestroy(resolve)))),
				map(() => null)
			);
	}
}
