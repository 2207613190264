
		<div class="wcd-flex-vertical wcd-full-height">
			<ng-container *ngIf="(data$ | async) as data">
				<div class="wcd-flex-1 ie11Patch ie11Flex">
					<h4 class="wcd-margin-bottom">{{ 'alert.report.widgets.alertStatus.title' | i18n }}</h4>
					<div class="wcd-flex-horizontal wcd-margin-small-bottom">
						<span class="wcd-font-weight-bold wcd-flex-0 wcd-margin-small-right">
							{{ 'alert.report.widgets.alertStatus.stateTitle' | i18n }}:
						</span>
						{{ data?.status?.name }}
					</div>
					<div class="wcd-flex-horizontal wcd-margin-small-bottom">
						<span class="wcd-font-weight-bold wcd-flex-0 wcd-margin-small-right">
							{{ 'alert.report.widgets.alertStatus.ClassificationTitle' | i18n }}:
						</span>
						{{ data?.classification?.name || ('alert.report.widgets.alertStatus.notSet' | i18n) }}
					</div>
					<div class="wcd-flex-horizontal wcd-margin-small-bottom">
						<span class="wcd-font-weight-bold wcd-flex-0 wcd-margin-small-right">
							{{ 'alert.report.widgets.alertStatus.assignToTitle' | i18n }}:
						</span>
						{{ data?.assignedTo || ('alert.report.widgets.alertStatus.notAssigned' | i18n) }}
					</div>
				</div>
			</ng-container>
		</div>
	