import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Software, SoftwareInstallation } from '@wcd/domain';
import { HistorySafeRedirectComponent } from './history-safe-redirect.component';

@Component({
	selector: 'history-safe-redirect',
	template: ''
})
export class NonSupportedRedirectComponent extends HistorySafeRedirectComponent {
	constructor(
		activatedRoute: ActivatedRoute,
		router: Router
	) {
		super(activatedRoute, router);
		const entity = activatedRoute.snapshot.data.entity;
		if ((entity instanceof Software || entity instanceof SoftwareInstallation) && entity.productNeverMatched) {
			this.redirectTo = "installations";
		} 
	}

}
