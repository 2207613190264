import { IStatus } from '../../status.interface';

export type IncidentStatusId = 1 | 2 | 4;

export enum IncidentStatusType {
	Open = 'Open',
	Closed = 'Closed',
	InProgress = 'InProgress'
}

export interface IIncidentStatus extends IStatus<IncidentStatusId, IncidentStatusType> {
	isOpen: boolean;
}
