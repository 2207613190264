import { ApiCallModel, ApiCall } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { ReportingOptions } from './reporting-options.interface';
import { AlertsByDetectionSourceOverTime } from './alerts-by-detection-source.value-object';

@ApiCall({
	name: 'Alerts by detection source',
	endpoint: 'reporting/alertsByDetectionSource',
	method: 'GET',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (options: ReportingOptions) => {
		return { params: options };
	},
	type: AlertsByDetectionSourceOverTime,
	parseData: rawData => ({ items: rawData }),
	separateArrayParams: true,
})
export class AlertsByDetectionSourceApiCall extends ApiCallModel<
	AlertsByDetectionSourceOverTime,
	ReportingOptions
> {}
