import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'history-safe-redirect',
	template: ''
})
export class HistorySafeRedirectComponent {
	public redirectTo: string;

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router
	) {
		this.redirectTo = activatedRoute.snapshot.data.redirectTo;
	}

	public ngOnInit() {
		const tree = this.router.createUrlTree([this.redirectTo], {
			relativeTo: this.activatedRoute,
		});

		// calling this in the constructor would trigger an 'NavigationCancelled' event
		// that's why we call it here
		this.router.navigateByUrl(tree, {
			replaceUrl: true,
		});
	}
}
