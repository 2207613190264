
		<span
			wcdTooltip
			[tooltipInnerComponent]="alertLinkedByDetailsComponent"
			[innerComponentInput]="{
				alertId: alert?.id,
				linkedBy: alert?.aggregatedIncidentLinkedBy,
				aggregatedLinkedBy: aggregatedLinkedBy,
				showTooltip: false
			}"
			>{{ reasonsText }}</span
		>
	