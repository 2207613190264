import { ApiCall, ApiCallModel, HttpOptions, ParisConfig } from '@microsoft/paris';
import * as TroubleshootCreateModels from './troubleshoot-requests.models';

export type TroubleshootRawApiRequestBody = TroubleshootCreateModels.TroubleshootRawApiRequestBody;
export type TroubleshootRawApiResponse = TroubleshootCreateModels.TroubleshootRawApiResponse;

export type TroubleshootParameters = TroubleshootCreateModels.TroubleshootParameters;
export type TroubleshootResponse = TroubleshootCreateModels.TroubleshootResponse;

@ApiCall({
	name: 'Troubleshoot machine',
	endpoint: 'requests/create',
	method: 'POST',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({
		machine,
		comment,
		troubleshootStartDateTimeUtc,
		troubleshootExpirationDateTimeUtc,
		paramsJsonFormatVersion,
		troubleshootState
	}: TroubleshootParameters): HttpOptions<TroubleshootRawApiRequestBody> => ({
		data: {
			MachineId: machine.machineId,
			RequestorComment: comment,
			Type: 'TroubleshootRequest',
			TroubleshootState: troubleshootState,
			TroubleshootExpirationDateTimeUtc: troubleshootExpirationDateTimeUtc,
			TroubleshootStartDateTimeUtc: troubleshootStartDateTimeUtc,
			ParamsJsonFormatVersion: paramsJsonFormatVersion,
			RequestSource: 'Portal',
			OsPlatform: machine.os.platform.id,
			SenseClientVersion: machine.senseClientVersion,
		},
	}),
	parseData: (rawData: TroubleshootRawApiResponse): TroubleshootResponse => ({
		machineId: rawData.MachineId,
		requestGuid: rawData.Id,
	}),
})
export class TroubleshootApiCall extends ApiCallModel<TroubleshootResponse, TroubleshootParameters> {}
