/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tags-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tag/tag.component.ngfactory";
import * as i3 from "../tag/tag.component";
import * as i4 from "../../services/tags.service";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i8 from "./tags-list.component";
var styles_TagsListComponent = [i0.styles];
var RenderType_TagsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagsListComponent, data: {} });
export { RenderType_TagsListComponent as RenderType_TagsListComponent };
function View_TagsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "tag", [], null, [[null, "removeClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("removeClick" === en)) {
        var pd_0 = (_co.removeTag($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TagComponent_0, i2.RenderType_TagComponent)), i1.ɵdid(3, 573440, null, 0, i3.TagComponent, [i4.TagsService, i5.I18nService], { tag: [0, "tag"], removable: [1, "removable"] }, { removeClick: "removeClick" }), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.editable; _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.name || _v.context.$implicit.label); _ck(_v, 0, 0, currVal_0); }); }
function View_TagsListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "tags-more"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t\t", "\n\t\t\t"])), i1.ɵpod(2, { count: 0 }), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), "tags.moreTags", _ck(_v, 2, 0, (_co.localTags.length - _co.maxItems)))); _ck(_v, 1, 0, currVal_0); }); }
function View_TagsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "span", [], [[2, "wcd-flex-items-center-horizontal", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "ul", [["class", "tags-list"]], [[2, "multi-line", null], [2, "inline-block", null], [2, "wcd-flex-items-start-vertical", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TagsListComponent_2)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i6.SlicePipe, []), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsListComponent_3)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = ((_co.maxItems > 0) ? i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.localTags, 0, _co.maxItems)) : _co.localTags); _ck(_v, 5, 0, currVal_4); var currVal_5 = (_co.maxItems && (_co.localTags.length > _co.maxItems)); _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.orientation === _co.FlexDirection.Horizontal); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.multiline; var currVal_2 = (_co.orientation === _co.FlexDirection.None); var currVal_3 = (_co.orientation === _co.FlexDirection.Vertical); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
function View_TagsListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "subtle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t\t", "\n\t\t\t"])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.emptyMessageKey)); _ck(_v, 1, 0, currVal_0); }); }
function View_TagsListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsListComponent_5)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allowEmptyState; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TagsListComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i7.I18nPipe, [i5.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsListComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(0, [["empty", 2]], null, 0, null, View_TagsListComponent_4)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.localTags == null) ? null : _co.localTags.length); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_TagsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tags-list", [], null, null, null, View_TagsListComponent_0, RenderType_TagsListComponent)), i1.ɵdid(1, 573440, null, 0, i8.TagsListComponent, [], null, null)], null, null); }
var TagsListComponentNgFactory = i1.ɵccf("tags-list", i8.TagsListComponent, View_TagsListComponent_Host_0, { tags: "tags", editable: "editable", allowEmptyState: "allowEmptyState", maxItems: "maxItems", orientation: "orientation", emptyMessageKey: "emptyMessageKey", multiline: "multiline" }, { tagRemoved: "tagRemoved" }, []);
export { TagsListComponentNgFactory as TagsListComponentNgFactory };
