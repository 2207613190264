import { DataviewField } from '@wcd/dataview';
var MachineGroupsFieldsService = /** @class */ (function () {
    function MachineGroupsFieldsService() {
    }
    Object.defineProperty(MachineGroupsFieldsService.prototype, "fields", {
        get: function () {
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'name',
                        name: 'Name',
                        minWidth: 350,
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return MachineGroupsFieldsService;
}());
export { MachineGroupsFieldsService };
