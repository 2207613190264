import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'empty-field',
	template: `
		<span class="table-empty-object" i18n>
			{{ 'common_noDataAvailable' | i18n }}
		</span>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyFieldComponent {}
