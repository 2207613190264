import { ChangeDetectionStrategy, Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
import { SerializedFilters } from '../../../models/serialized-filters.type';
import { RangeValue } from '@wcd/forms';
import { isEqual } from 'lodash-es';

@Component({
	template: `
		<wcd-range-slider
			[max]="config?.max"
			[min]="config?.min"
			[valueFormat]="config?.valueFormat"
			[value]="selectedValues"
			[defaultValue]="config?.initialValue || selectedValues"
			(onChange)="onChange($event)"
		>
		</wcd-range-slider>
	`,
	selector: 'wcd-filter-values-slider',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterValuesRangeSliderComponent extends FilterValuesComponent<
	RangeValue,
	RangeValue,
	RangeValue,
	FilterValuesSliderConfig
> {
	constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {
		super();
	}

	get value(): RangeValue {
		return this.selectedValues;
	}

	setSelectedValues(selectedValues: RangeValue) {
		if (!this.config.initialValue) {
			this.config.initialValue = {
				from: this.config.min,
				to: this.config.max,
			};
		}

		if (!selectedValues) this.selectedValues = { ...this.config.initialValue };

		this.changeDetectorRef.markForCheck();
	}

	serialize(): SerializedFilters {
		return this.selectedValues && !isEqual(this.selectedValues, this.config && this.config.initialValue)
			? { [this.fieldId]: `${this.selectedValues.from}:${this.selectedValues.to}` }
			: null;
	}

	deserialize(serializedRange: SerializedFilters): RangeValue {
		let strRange = serializedRange[this.fieldId];
		if (strRange instanceof Array) strRange = strRange[0];
		if (!strRange) return null;
		const matched = strRange.match(/^(\d+):(\d+)$/);
		if (matched) {
			const from = Number(matched[1]);
			const to = Number(matched[2]);
			return { from: from, to: to };
		}
		return null;
	}

	onChange(range: RangeValue) {
		this.ngZone.run(() => {
			this.selectedValues = range;
			this.filterValuesChange.emit(range);
		});
	}
}

export interface FilterValuesSliderConfig {
	max?: number;
	min?: number;
	initialValue?: RangeValue;
	valueFormat?: (value: number) => string;
}
