import { ApiCall, ApiCallModel, HttpOptions } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Get authorized download link for linked report',
	endpoint: 'GetActorSasBlobLink',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (linkToReport: string) => {
		return { params: { reportBlobLink: linkToReport } };
	},
})
export class CmsLinkedReportDownloadUrlApiCall extends ApiCallModel<string, string> {}

export interface CmsLinkedReportUploadInput {
	file: File;
	actorName: string;
}

@ApiCall({
	name: 'Upload linked report',
	endpoint: (config, query) => {
		const actorName = query.where['_'];
		return `api/ThreatIntel/${actorName}`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.cms,
	parseQuery: ({ file, actorName }: CmsLinkedReportUploadInput): HttpOptions<FormData> => {
		const formData: FormData = new FormData();
		formData.append('Id', actorName);
		formData.append('file', file);
		return {
			data: formData,
			params: { _: actorName },
		};
	},
	method: 'PUT',
})
export class CmsLinkedReportUploadApiCall extends ApiCallModel<void, CmsLinkedReportUploadInput> {}
