
		<h2 class="wcd-font-weight-bold">
			{{ 'evaluation.lab.setupPanel.simulatorAgentStep.title' | i18n }}
		</h2>
		<div class="wcd-margin-xLarge-top wcd-margin-mediumSmall-bottom wcd-font-weight-semibold">
			{{ 'evaluation.lab.setupPanel.simulatorAgentStep.threatSimulatorDetails' | i18n }}
		</div>
		<markdown
			[data]="'evaluation.lab.setupPanel.simulatorAgentStep.agentSetupDescription' | i18n"
		></markdown>
		<div class="wcd-margin-xLarge-top wcd-margin-mediumSmall-bottom">
			<div class="wcd-margin-mediumSmall-bottom wcd-font-weight-semibold">
				{{ 'evaluation.lab.setupPanel.simulatorAgentStep.msConsentTitle' | i18n }}
			</div>
			<div>
				{{ 'evaluation.lab.setupPanel.simulatorAgentStep.msConsentTitleGuidance' | i18n }}
			</div>
		</div>
		<div class="wcd-margin-mediumSmall-bottom">
			<fab-checkbox
				(change)="onMsConsentStateChanged()"
				[disabled]="!agentsConfig.msTermsConsentRead || !!agentsConfig.msTermsConsentDisabled"
				[(checked)]="agentsConfig.msTermsConsentAccepted"
				[label]="'evaluation.lab.setupPanel.simulatorAgentStep.msConsentFirstDeclaration' | i18n"
			></fab-checkbox>
			<span (click)="showConsentForm(ConsentType.MsInformation)" (keyup.enter)="showConsentForm(ConsentType.MsInformation)" tabindex="0"
				><a class="wcd-margin-largeMedium-left">{{
					'evaluation.lab.setupPanel.simulatorAgentStep.msFirstConsentDescriptionLink' | i18n
				}}</a>
			</span>
		</div>
		<div class="wcd-margin-mediumSmall-bottom">
			<fab-checkbox
				(change)="onMsConsentStateChanged()"
				[disabled]="
					!agentsConfig.msInfoSharingConsentRead || !!agentsConfig.msInfoSharingConsentDisabled
				"
				[(checked)]="agentsConfig.msInfoSharingConsentAccepted"
				[label]="'evaluation.lab.setupPanel.simulatorAgentStep.msConsentSecondDeclaration' | i18n"
			></fab-checkbox>
			<span (click)="showConsentForm(ConsentType.MsInformationSharing)" (keyup.enter)="showConsentForm(ConsentType.MsInformationSharing)" tabindex="0"
				><a class="wcd-margin-largeMedium-left">{{
					'evaluation.lab.setupPanel.simulatorAgentStep.msSecondConsentDescriptionLink' | i18n
				}}</a>
			</span>
		</div>
		<div class="wcd-margin-large-top wcd-margin-mediumSmall-bottom wcd-font-weight-semibold">
			{{ 'evaluation.lab.setupPanel.simulatorAgentStep.selectVendors' | i18n }}
		</div>
		<div class="wcd-margin-mediumSmall-bottom">
			{{ 'evaluation.lab.setupPanel.simulatorAgentStep.selectVendorsRequiredConsentNote' | i18n }}
		</div>
		<fab-checkbox
			(change)="onProviderToggled.emit()"
			[disabled]="agentsConfig.aiqDisabled || !agentsConfig.attackIQConsentAccepted"
			[(checked)]="agentsConfig.withAiq"
			[label]="'evaluation.lab.setupPanel.simulatorAgentStep.attackIq' | i18n"
		></fab-checkbox>
		<div class="wcd-margin-mediumSmall-left">
			<div>
				[
				<span *ngIf="!agentsConfig.attackIQConsentAccepted">
					{{ 'evaluation.lab.setupPanel.simulatorAgentStep.attackIqReadConsentGuidance' | i18n }}
				</span>
				<span *ngIf="agentsConfig.attackIQConsentAccepted">
					<fab-icon iconName="CompletedSolid" contentClass="ms-color-greenLight"></fab-icon>
					{{ 'evaluation.lab.setupPanel.simulatorAgentStep.attackIqConsentAccepted' | i18n }}
				</span>
				<span (click)="showConsentForm(ConsentType.AttackIQ)" (keyup.enter)="showConsentForm(ConsentType.AttackIQ)" tabindex="0">
					<a class="wcd-margin-small-left">{{
						'evaluation.lab.setupPanel.simulatorAgentStep.attackIqReadConsent' | i18n
					}}</a>
				</span>
				]
			</div>
			<div>{{ 'evaluation.lab.setupPanel.simulatorAgentStep.attackIqDescription' | i18n }}</div>
		</div>
		<fab-checkbox
			(change)="onProviderToggled.emit()"
			contentClass="wcd-margin-medium-top"
			[(checked)]="agentsConfig.withSafeBreach"
			[label]="'evaluation.lab.setupPanel.simulatorAgentStep.safeBreach' | i18n"
			[disabled]="agentsConfig.safeBreachDisabled"
		></fab-checkbox>
		<div class="wcd-margin-largeMedium-left">
			<div>{{ 'evaluation.lab.setupPanel.simulatorAgentStep.safeBreachDescription' | i18n }}</div>
		</div>
		<div *ngIf="agentsConfig.withAiq || agentsConfig.withSafeBreach" class="wcd-margin-medium-top">
			<label for="aiq-email" class="wcd-margin-mediumSmall-top wcd-font-weight-semibold required">{{
				'evaluation.lab.setupPanel.simulatorAgentStep.emailAddress' | i18n
			}}</label>
			<input
				type="email"
				id="aiq-email"
				validateRegExp="email"
				name="aiqEmail"
				[required]="true"
				class="form-control wcd-margin-small-vertical wcd-flex-1"
				[(ngModel)]="agentsConfig.email"
				[readOnly]="agentsConfig.aiqDisabled || agentsConfig.safeBreachDisabled"
			/>
			<label for="aiq-first-name" class="wcd-font-weight-semibold required">{{
				'evaluation.lab.setupPanel.simulatorAgentStep.firstName' | i18n
			}}</label>
			<input
				type="text"
				id="aiq-first-name"
				validateRegExp="notEmpty"
				name="aiqFirstName"
				class="form-control wcd-margin-small-vertical wcd-flex-1"
				[required]="true"
				[(ngModel)]="agentsConfig.firstName"
				[readOnly]="agentsConfig.aiqDisabled || agentsConfig.safeBreachDisabled"
			/>
			<label for="aiq-last-name" class="wcd-font-weight-semibold required">{{
				'evaluation.lab.setupPanel.simulatorAgentStep.lastName' | i18n
			}}</label>
			<input
				type="text"
				id="aiq-last-name"
				validateRegExp="notEmpty"
				name="aiqLastName"
				class="form-control wcd-margin-small-vertical wcd-flex-1"
				[required]="true"
				[(ngModel)]="agentsConfig.lastName"
				[readOnly]="agentsConfig.aiqDisabled || agentsConfig.safeBreachDisabled"
			/>
		</div>
	