import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TabModel } from '../../../../../../shared/components/tabs/tab.model';
import { EntityComponentBase } from '../../../../../../global_entities/components/entity-contents.component.base';
import { BaselineProfileDetails } from '@wcd/domain';
import { Observable } from 'rxjs';
import { I18nService } from '@wcd/i18n';

export interface RelationshipCountProvider {
	itemCount$: Observable<number>;
	relationshipType: any;
}

@Component({
	selector: 'profile-entity',
	templateUrl: './profile.entity.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileEntityComponent extends EntityComponentBase<BaselineProfileDetails> {
	tabs: Array<TabModel> = [];

	constructor(private i18nService: I18nService) {
		super();
		this.tabs = this.getTabs();
	}

	private getTabs(): Array<TabModel> {
		return [
			{
				id: 'configurations',
				name: this.i18nService.strings.tvm_baseline_profile_entity_configurationTab_title,
				routerLink: './configurations',
			},
			{
				id: 'devices',
				name: this.i18nService.strings.tvm_baseline_profile_entity_devicesTab_title,
				routerLink: './devices',
			},
		].map(tab => new TabModel(tab));
	}
}
