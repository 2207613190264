import { Dictionary } from '@wcd/config';

const REPORTS = [
	{ id: 0, name: 'Phishing' },
	{ id: 1, name: 'Investigations' },
	{ id: 2, name: 'Endpoints' },
	{ id: 3, name: 'Threats' },
	{ id: 4, name: 'Dashboard' },
];

let reportsDictionary;

export class AirsReportTypes {
	static get ALL() {
		return REPORTS;
	}

	static get DEFAULT() {
		return REPORTS[0];
	}

	static getReportTypeById(reportId): { id: number; name: string } {
		if (!reportsDictionary)
			reportsDictionary = Dictionary.fromList<number, { id: number; name: string }>(REPORTS, 'id');

		return reportsDictionary.get(reportId);
	}
}
