import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { BilbaoLicense } from './bilbao-license.value-object';

@ApiCall({
	name: 'Get Bilbao license',
	endpoint: 'GetBilbaoLicense',
	method: 'GET',
	type: BilbaoLicense,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.mte,
})
export class BilbaoLicenseApiCall extends ApiCallModel<BilbaoLicense> {}
