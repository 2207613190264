export type TrackingAttributesRaw =
	| 'data-track-package'
	| 'data-track-component'
	| 'data-track-component-type'
	| 'data-track-type'
	| 'data-track-id'
	| 'data-track-value';

export enum TrackingAttributes {
	Package = 'data-track-package',
	Component = 'data-track-component',
	ComponentType = 'data-track-component-type',
	Type = 'data-track-type',
	Id = 'data-track-id',
	Value = 'data-track-value',
}
