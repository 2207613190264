import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { NgClassInput } from '@wcd/angular-extensions';

export type ExpanderArrowPosition = 'left' | 'right';

let lastId = 0;

@Component({
	selector: 'wcd-expander-button',
	styleUrls: ['./expander-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div
			#expenderButton
			class="expander-button wcd-flex wcd-flex-nowrap-horizontal"
			[class.expander-button--right]="arrowPosition === 'right'"
			tabindex="0"
			[attr.role]="ariaRole"
			[attr.aria-expanded]="isExpanded"
			(keyup.enter)="toggle()"
			(click)="toggle()"
			[attr.aria-controls]="regionId"
			[attr.aria-labelledby]="ariaLabel ? ariaLabel : expanderButtonLabelId"
			[attr.aria-label]="ariaLabel ? ariaLabel : null"
			[attr.id]="buttonId"
		>
			<ng-container *ngIf="!arrowPosition || arrowPosition === 'left'">
				<ng-container *ngTemplateOutlet="arrowIcon"></ng-container>
			</ng-container>
			<fab-icon *ngIf="iconName" [iconName]="iconName" role="presentation"></fab-icon>
			<label
				class="expander-button__label wcd-flex-1  wcd-padding-small-left"
				(click)="onLabelClicked($event)"
				role="presentation"
			>
				<span [ngClass]="labelClass" [attr.id]="expanderButtonLabelId">
					<ng-container *ngIf="label">
						{{ label }}
					</ng-container>
					<ng-content select="[wcd-expander-title]"></ng-content>
				</span>
				<span class="ms-fontColor-neutralTertiary expander-button__sub-label" *ngIf="subLabel">
					{{ subLabel }}
				</span>
			</label>

			<ng-container *ngIf="arrowPosition === 'right'">
				<ng-container *ngTemplateOutlet="arrowIcon"></ng-container>
			</ng-container>
		</div>

		<ng-template #arrowIcon>
			<span
				class="expander-button__icon"
				[ngClass]="{ 'expander-button__icon--expanded': isExpanded }"
				role="presentation"
			>
				<fab-icon [contentClass]="" iconName="ChevronDownMed"></fab-icon>
			</span>
		</ng-template>
	`,
})
export class ExpanderButtonComponent {
	@Input() label?: string;
	@Input() subLabel?: string;
	@Input() ariaLabel?: string;
	@Input() isExpanded = true;
	@Input() toggleOnTitleClick = true;
	@Input() labelClass?: NgClassInput = '';
	@Input() arrowPosition?: ExpanderArrowPosition = 'left';
	@Input() ariaRole?: string = 'button';
	@Input() iconName?: string;
	@Input() buttonId: string = null;
	@Input() regionId: string = null;

	@Output() readonly isExpandedChange = new EventEmitter<boolean>();
	@Output() readonly labelClicked = new EventEmitter<MouseEvent>();

	@ViewChild('expenderButton', { static: true }) readonly expenderButton: ElementRef<HTMLElement>;

	readonly expanderButtonLabelId = `expander-button-label-${lastId++}`;

	constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

	toggle() {
		this.isExpanded = !this.isExpanded;
		this.isExpandedChange.emit(this.isExpanded);

		this.changeDetectorRef.markForCheck();
	}

	onLabelClicked(event: MouseEvent) {
		event.stopPropagation();

		this.labelClicked.emit(event);
		if (this.toggleOnTitleClick) {
			this.toggle();
		}
	}

	focusExpenderButton(){
		this.expenderButton.nativeElement.focus()
	}
}
