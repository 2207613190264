var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MitreService } from './mitre.service';
import { i18nLoader } from '../../../../projects/i18n/src/lib/services/i18n.loader.svc';
var MdatpMitreService = /** @class */ (function (_super) {
    __extends(MdatpMitreService, _super);
    function MdatpMitreService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MdatpMitreService.getMitreTechniqueFullDescription = function (mitreTechniqueId) {
        return _super.getMitreTechniqueFullDescription.call(this, mitreTechniqueId, this.i18nService);
    };
    MdatpMitreService.i18nService = i18nLoader;
    return MdatpMitreService;
}(MitreService));
export { MdatpMitreService };
