<wcd-panel (close)="destroy()" [settings]="settings"
	class="wcd-full-height wcd-flex-vertical wcd-flex-1"
	data-track-component="installed-assets-panel"
	data-track-component-type="Side Panel">
	<div class="wcd-flex-horizontal wcd-padding-large-horizontal">
		<h4 class="wcd-flex-1 wcd-padding-bottom side-panel-title">
			{{ 'tvm.common.installedDevices' | i18n }}
		</h4>
		<button
			class="tvm-show-more-csv-export-button"
			*ngIf="isExportEnabled"
			(click)="exportCveData()"
			[wcdTooltip]="exportTooltipText || 'Export data to CSV'">
			<wcd-shared-icon iconName="Download"> </wcd-shared-icon>
			<span>{{ 'export' | i18n }}</span>
		</button>
	</div>
	<dataview class="wcd-flex-1"
		*ngIf="sourceValue"
		id="installed-assets-dataview"
		[entityType]="entityType"
		[dataViewConfig]="dataViewConfig"
		[giveSearchBoxMoreSpace]="true"
		[repository]="_repository"
		[navigateOnChange]="false"
		[allowPaging]="true"
		[allowFilters]="false"
		[searchEnabled]="true"
		[ignoreQueryParams]="true"
		[allowColumnCustomization]="false"
		[fields]="_fields"
		[disableSelection]="true">
	</dataview>
</wcd-panel>
