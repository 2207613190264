<div class="wcd-flex-horizontal wcd-flex-wrap-horizontal">
	<distribution-element
		class="EntityRelatedEvidence__bar"
		*ngFor="let entity of shownEntities"
		[barColor]="'neutralSecondary'"
		[title]="entity.name"
		[mainText]="entity.count"
	>
	</distribution-element>

	<div *ngIf="shownEntities.length === 0">
		{{ 'entityCommon.panel.relatedEvidence.noDataMessage' | i18n }}
	</div>
</div>
