/* tslint:disable:template-click-events-have-key-events */
import { SimpleChanges, OnChanges, SecurityContext, } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { UrlsService } from '../../../@entities/urls/services/urls.service';
import { WicdSanitizerService, RegExpService } from '@wcd/shared';
var ALERT_DESCRIPTION_MAX_LENGTH_SHOWN = 500;
var AlertedDescriptionComponent = /** @class */ (function () {
    function AlertedDescriptionComponent(i18nService, urlsService, domSanitizer) {
        this.i18nService = i18nService;
        this.urlsService = urlsService;
        this.domSanitizer = domSanitizer;
        this.linkToEncyclopedia = null;
        this.allowHtmlRendering = false;
        this.decodedLinkToEncyclopedia = null;
        this.descriptionExpanded = true;
    }
    AlertedDescriptionComponent.prototype.ngOnChanges = function (changes) {
        if (changes.linkToEncyclopedia && this.linkToEncyclopedia) {
            this.decodedLinkToEncyclopedia = this.urlsService.getDecodedUrl(this.linkToEncyclopedia);
        }
    };
    Object.defineProperty(AlertedDescriptionComponent.prototype, "expandableDescription", {
        get: function () {
            return ((this.description ? this.description.length : 0) +
                (this.recommendedAction ? this.recommendedAction.length : 0) >
                ALERT_DESCRIPTION_MAX_LENGTH_SHOWN);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertedDescriptionComponent.prototype, "descriptionText", {
        get: function () {
            var descriptionText = '';
            var cleanedDescription = this.showOauthUrl && this.description ? this.description.replace(RegExpService.anchorTag, "") : this.description;
            if (this.description && this.description.length && this.recommendedAction && this.recommendedAction.length) {
                descriptionText = descriptionText.concat(cleanedDescription);
                descriptionText = descriptionText.concat('<br><br>');
                descriptionText = descriptionText.concat('<h5>');
                descriptionText = descriptionText.concat(this.i18nService.get('alerts.alert.recommendedActions'));
                descriptionText = descriptionText.concat('</h5>');
                descriptionText = descriptionText.concat(this.recommendedAction);
            }
            else {
                if (this.description && this.description.length) {
                    descriptionText = cleanedDescription;
                }
                if (this.recommendedAction && this.recommendedAction.length) {
                    descriptionText = this.recommendedAction;
                }
            }
            descriptionText = descriptionText.slice(0, this.descriptionExpanded ? Infinity : ALERT_DESCRIPTION_MAX_LENGTH_SHOWN);
            return descriptionText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertedDescriptionComponent.prototype, "oauthUrlText", {
        get: function () {
            return this.domSanitizer.sanitize(SecurityContext.HTML, this.oAuthAlertPageLinkLabel);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertedDescriptionComponent.prototype, "showOauthUrl", {
        get: function () {
            return !!this.oAuthAlertPageLinkLabel;
        },
        enumerable: true,
        configurable: true
    });
    return AlertedDescriptionComponent;
}());
export { AlertedDescriptionComponent };
