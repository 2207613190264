
		<h2 class="wcd-font-weight-bold">{{ 'evaluation.lab.setupPanel.summaryStep.title' | i18n }}</h2>
		<div class="wcd-margin-xLarge-top wcd-margin-mediumSmall-bottom">
			{{ 'evaluation.lab.setupPanel.summaryStep.allSet' | i18n }}
		</div>
		<hr class="wcd-margin-xxLarge-top" />
		<div class="wcd-margin-mediumSmall-bottom wcd-font-weight-semibold">
			{{ 'evaluation.lab.setupPanel.summaryStep.labConfiguration' | i18n }}
		</div>
		<lab-configuration [configuration]="data.labConfiguration"></lab-configuration>
		<hr class="wcd-margin-large-top" />
		<div class="wcd-margin-top wcd-margin-large-bottom wcd-font-weight-semibold">
			{{ 'evaluation.lab.setupPanel.summaryStep.activeVendors' | i18n: { vendorsCount: vendorsCount } }}
		</div>
		<div class="wcd-font-size-s wcd-margin-small-bottom" *ngIf="vendorsCount > 0">
			{{ 'evaluation.lab.setupPanel.summaryStep.vendorName' | i18n }}
		</div>
		<hr class="wcd-margin-none-all" *ngIf="data.withAiq" />
		<ng-container
			*ngIf="data.withAiq"
			[ngTemplateOutlet]="configuredVendor"
			[ngTemplateOutletContext]="{
				learnMore: 'evaluation.lab.setupPanel.summaryStep.attackIqLearnMore' | i18n,
				logo: '/assets/images/partners/aiq_logo.png',
				logoClassName: 'aiq-logo'
			}"
		></ng-container>
		<hr class="wcd-margin-none-all" *ngIf="data.withAiq || data.withSafeBreach" />
		<ng-container
			*ngIf="data.withSafeBreach"
			[ngTemplateOutlet]="configuredVendor"
			[ngTemplateOutletContext]="{
				learnMore: 'evaluation.lab.setupPanel.summaryStep.safeBreachLearnMore' | i18n,
				logo: '/assets/images/partners/safebreach_logo.png',
				logoClassName: 'safebearch-logo'
			}"
		></ng-container>
		<hr class="wcd-margin-none-all" *ngIf="data.withSafeBreach" />

		<ng-container *ngLet="(saving$ | async) as saving">
			<ng-container *ngLet="(error$ | async) as error">
				<fab-spinner *ngIf="saving" contentClass="wcd-margin-xLarge-top"></fab-spinner>
				<fab-message-bar
					*ngIf="error && !saving"
					[messageBarType]="MessageBarType.error"
					contentClass="wcd-margin-xLarge-top"
				>
					{{ error }}
				</fab-message-bar>
			</ng-container>
		</ng-container>

		<ng-template
			#configuredVendor
			let-learnMore="learnMore"
			let-logo="logo"
			let-logoClassName="logoClassName"
		>
			<div class="wcd-flex-horizontal wcd-margin-mediumSmall-vertical">
				<div class="wcd-flex-1 wcd-flex-center-vertical">
					<img [class]="logoClassName + ' wcd-margin-right'" [src]="logo | basePath" />
				</div>
				<markdown class="wcd-flex-1" [data]="learnMore"></markdown>
			</div>
		</ng-template>
	