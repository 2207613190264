import { Entity, EntityModelBase, EntityField, DataQuery, DataQuerySortDirection } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@Entity({
	singularName: 'Domain link',
	pluralName: 'Domain links',
	endpoint: 'webThreats/domains/urls',
	loadAll: true,
	allItemsProperty: 'results',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.webThreatProtection,
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class DomainUrl extends EntityModelBase {
	@EntityField({ data: 'Url' })
	url: string;

	@EntityField({ data: 'AccessCount' })
	accessCount: number;
}
