import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { Omit } from '@wcd/types';
import assertNever from 'assert-never';
import { compact, cloneDeep } from 'lodash-es';
import { SpinnerSize } from 'office-ui-fabric-react';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import {
	MachineExtention,
	Machine,
	MachineCollectInvesigationPackageRequest,
	MachineIsolationRequest,
	MachineLogsCollectionRequest,
	MachineMachineRequestLatestRelationship,
	MachineRequest,
	MachineRequestLogsCollectionDownloadRelationship,
	MachineRequestLogsDownload,
	MachineRequestPackageDownload,
	MachineRequestPackageDownloadRelationship,
	MachineResponseType,
	MachineRestrictAppExecutionRequest,
	MachineRunAntivirusScanRequest,
	RequestStatus,
	TroubleshootModeRequest,
} from '@wcd/domain';
import { CommentModel } from '../../../../../comments/models/comment.model';
import { PanelContainer, PanelType } from '@wcd/panels';
import { ActivatedEntity } from '../../../../../global_entities/services/activated-entity.service';
import { I18nService } from '@wcd/i18n';
import { RbacMdeAllowedActions } from '../../../../../rbac/enums/mde-allowed-actions.enum';
import {
	RbacControlSettings,
	RbacControlState,
} from '../../../../../rbac/models/rbac-control-settings.model';
import { DownloadService } from '../../../../../shared/services/download.service';
import { MachineRequestErrorCodePipe } from '../../../pipes/machine-request-error-code.pipe';
import { MachinesActionsService } from '../../../services/machines-actions.service';
import { MachineRequestsPermissionsService } from '../../../services/machines-requests.permissions.service';
import { ActionCenterSectionComponent } from './action-center-section.component';
import { ActionCenterStatus } from './models/action-center-status.model';
import { LiveAnnouncer } from '@angular/cdk/a11y';

export interface Section<TResponseType extends MachineResponseType> {
	readonly labelKey: string;
	readonly type: TResponseType;
	readonly onCancelSubmission: (machine: Machine) => Promise<void | MachineRequest<TResponseType>>;
	readonly state$: Observable<Pick<ActionCenterSectionComponent, 'submission' | 'comments' | 'status'>>;
}

enum CollapsibleID {
	Details = 'action-center-details',
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [
		`
			:host {
				display: block;
			}

			.section:last-child {
				border-bottom: none;
			}
		`,
	],
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<div
				class="wcd-auto-height-responsive wcd-full-height wcd-flex-vertical"
				data-track-component="Machine action center Panel"
				data-track-component-type="Side Panel"
			>
				<header
					class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
				>
					<div class="page-header-icon wcd-flex-none">
						<wcd-shared-icon class="round-icon color-box-gray-200" iconName="F12DevTools">
						</wcd-shared-icon>
					</div>
					<div class="wcd-flex-1 wcd-flex-center-vertical">
						<h3 #panelHeader class="side-panel-title">
							{{ 'machines.entityDetails.actions.actionCenter.title' | i18n }}
						</h3>
					</div>
				</header>

				<div class="wcd-flex-1 wcd-flex-vertical wcd-padding-vertical wcd-padding-large-horizontal">
					<ng-container *ngIf="(currentMachine$ | async) as machine">
						<ng-container *ngIf="!isLoading; else loader">
							<main class="wcd-flex-auto wcd-scroll-vertical">
								<ng-container
									*ngFor="
										let section of sections;
										index as i;
										let first = first;
										let last = last
									"
								>
									<wcd-collapsible-section
										*ngIf="(section.state$ | async) as state"
										[label]="section.labelKey | i18n"
										[sectionId]="collapsibleID.Details + i"
										[showTopBorder]="!first"
									>
										<action-center-section
											[rbac]="investigationPackageCollectionRbac"
											[submission]="state.submission"
											[comments]="state.comments"
											[status]="state.status"
											(onCancelSubmissionActionClicked)="
												section.onCancelSubmission(machine)
											"
										></action-center-section>
									</wcd-collapsible-section>
								</ng-container>
							</main>

							<fab-message-bar *ngIf="sections" role="footer" className="wcd-margin-small-top">
								{{ 'machines.actionCenter.notice' | i18n }}
							</fab-message-bar>
						</ng-container>

						<ng-template #loader>
							<fab-spinner
								[size]="SpinnerSize.large"
								[label]="'machines.actionCenter.loading' | i18n"
							></fab-spinner>
						</ng-template>
					</ng-container>
				</div>
			</div>
		</wcd-panel>
	`,
})
export class MachineActionCenterPanelComponent extends PanelContainer implements OnInit, OnDestroy {
	readonly SpinnerSize = SpinnerSize;

	@Input() machine?: Machine;
	@Input() requestGuid?: string;

	collapsibleID = CollapsibleID;

	readonly investigationPackageCollectionRbac: RbacControlSettings = {
		state: RbacControlState.disabled,
		permissions: [RbacMdeAllowedActions.alertsInvestigation],
	};
	readonly requests$ = new BehaviorSubject<ReadonlyArray<MachineRequest>>([]);
	sections: ReadonlyArray<Section<MachineResponseType>>;
	currentMachine$: Observable<Machine>;
	isLoading: boolean = true;

	private _dataSetSubscription: Subscription;

	private _statusTextPrefixRequestTextKeysMap: Record<
		MachineResponseType,
		(request: MachineRequest) => string
	>;
	private _statusTextSuffixRequestTextKeysMap: Record<RequestStatus, (request: MachineRequest) => string>;

	private _actionTextPrefixRequestTextKeysMap: Record<
		MachineResponseType,
		(request: MachineRequest, kind: 'submit' | 'cancel') => string
	>;
	private cancelAnnounceKeysMap : Record<'Succeeded' | 'InProgress' | 'Failed' | 'Cancelled', string> = {
		Succeeded: 'machines_actionCenter_actions_cancellation_success',
		Failed: 'machines_actionCenter_actions_cancellation_failed',
		InProgress: 'machines_actionCenter_actions_cancellation_inProgress',
		Cancelled: 'machines_actionCenter_actions_cancellation_success'
	};

	constructor(
		router: Router,
		private readonly activatedEntity: ActivatedEntity,
		private readonly paris: Paris,
		private readonly i18nService: I18nService,
		private readonly machineRequestErrorCodePipe: MachineRequestErrorCodePipe,
		private readonly machinesPermissionsService: MachineRequestsPermissionsService,
		private readonly machinesActionsService: MachinesActionsService,
		private readonly downloadService: DownloadService,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly liveAnnouncer: LiveAnnouncer,
	) {
		super(router);

		this.buildActionTextsMaps();
	}

	ngOnInit() {
		this.settings = {
			...this.settings,
			showOverlay: true,
			hasCloseButton: true,
			type: PanelType.large,
			noBodyPadding: true,
			isBlocking: true,
			className: this.settings.className || '',
		};

		this.currentMachine$ = this.machine
			? of(this.machine)
			: this.activatedEntity.currentEntity$.pipe(
					filter(currentEntity => currentEntity instanceof Machine),
					map(machine => machine as Machine)
			  );

		const featuresStatus$ = this.currentMachine$.pipe(
			mergeMap(machine => this.machinesPermissionsService.getFeaturesStatus(machine))
		);

		const extendedMachine = new MachineExtention(this.machine, this.requestGuid);
		const machineRequests$ = this.currentMachine$.pipe(
			mergeMap(machine =>
				this.paris.queryForItem<Machine, MachineRequest>(
					MachineMachineRequestLatestRelationship,
					extendedMachine
				)
			),
			map(dataSet => dataSet.items)
		);

		this._dataSetSubscription = combineLatest(machineRequests$, featuresStatus$)
			.pipe(
				map(([requests, featuresStatus]) => requests.filter(request => featuresStatus[request.type]))
			)
			.subscribe(items => {
				this.requests$.next(items);
				this.isLoading = false;
				this.changeDetector.markForCheck();
			});

		this.sections = this.createSections();

		super.ngOnInit();
	}

	ngOnDestroy() {
		if (this._dataSetSubscription) {
			this._dataSetSubscription.unsubscribe();
		}
	}

	createState<TResponseType extends MachineResponseType>(type: TResponseType) {
		return this.requests$.pipe(
			map(items => items.find(item => item.type === type)),
			filter(requestItem => Boolean(requestItem)),
			map(requestItem => {
				const cancellationComment: CommentModel =
					requestItem.cancellationDateTimeUtc &&
					new CommentModel({
						id: 'cancellationComment',
						timestamp: requestItem.cancellationDateTimeUtc,
						action: this.calculateActionText(requestItem, 'cancel'),
						user: requestItem.cancellationRequestor,
						message: requestItem.cancellationComment,
					});

				const creationComment: CommentModel =
					requestItem.creationDateTimeUtc &&
					new CommentModel({
						id: 'creationComment',
						user: requestItem.requestor,
						timestamp: requestItem.creationDateTimeUtc,
						action: this.calculateActionText(requestItem, 'submit'),
						message: requestItem.requestorComment,
					});

				return {
					comments: compact([cancellationComment, creationComment]),
					submission: {
						dateTime: requestItem.creationDateTimeUtc,
						allowCancellation: requestItem.requestStatus === 'Submitted',
						status: requestItem.requestStatus,
					},
					status: this.getRequestStatus(requestItem),
				} as Omit<ActionCenterSectionComponent, 'onCancelSubmissionActionClicked'>;
			})
		);
	}

	private createSections(): ReadonlyArray<Section<MachineResponseType>> {
		return [
			{
				labelKey: 'machines.actionCenter.sections.investigationPackageCollection.label',
				type: 'ForensicsResponse',
				onCancelSubmission: async machine => {
					const forensicsResponseRequest = this.requests$.value.find(
						item => item.type === 'ForensicsResponse'
					) as MachineIsolationRequest;

					if (!forensicsResponseRequest) {
						return;
					}

					const result = await this.machinesActionsService.cancelCollectInvestigationPackage(
						forensicsResponseRequest.requestGuid
					);

					if (result.confirmed) {
						return result.data;
					}
				},
			} as Section<'ForensicsResponse'>,
			{
				labelKey: 'machines.actionCenter.sections.antivirusScan.label',
				type: 'ScanResponse',
				onCancelSubmission: async machine => {
					const scanResponseRequest = this.requests$.value.find(
						item => item.type === 'ScanResponse'
					) as MachineIsolationRequest;

					if (!scanResponseRequest) {
						return;
					}

					const result = await this.machinesActionsService.cancelAntivirusScan(
						scanResponseRequest.requestGuid
					);
					if (result.confirmed) {
						return result.data;
					}
				},
			} as Section<'ScanResponse'>,
			{
				labelKey: 'machines.actionCenter.sections.troubleshootMode.label',
				type: 'TroubleshootResponse',
			} as Section<'TroubleshootResponse'>,
			{
				labelKey: 'machines.actionCenter.sections.appRestriction.label',
				type: 'RestrictExecutionResponse',
				onCancelSubmission: async machine => {
					const restrictResponseRequest = this.requests$.value.find(
						item => item.type === 'RestrictExecutionResponse'
					) as MachineIsolationRequest;

					if (!restrictResponseRequest) {
						return;
					}

					const result = await this.machinesActionsService.cancelRestrictAppExecution(
						restrictResponseRequest.requestGuid
					);

					if (result.confirmed) {
						return result.data;
					}
				},
			} as Section<'RestrictExecutionResponse'>,
			{
				labelKey: 'machines.actionCenter.sections.machineIsolation.label',
				type: 'IsolationResponse',
				onCancelSubmission: async machine => {
					const isolationResponseRequest = this.requests$.value.find(
						item => item.type === 'IsolationResponse'
					) as MachineIsolationRequest;

					if (!isolationResponseRequest) {
						return;
					}

					const result = await this.machinesActionsService.cancelIsolation(
						isolationResponseRequest.action,
						isolationResponseRequest.requestGuid
					);
					if (result.confirmed) {
						return result.data;
					}
				},
			} as Section<'IsolationResponse'>,
			{
				labelKey: 'machines.actionCenter.sections.logsCollection.label',
				type: 'LogsCollectionResponse',
				onCancelSubmission: async machine => {
					const logsCollectionRequest = this.requests$.value.find(
						item => item.type === 'LogsCollectionResponse'
					) as MachineLogsCollectionRequest;

					if (!logsCollectionRequest) {
						return;
					}

					const result = await this.machinesActionsService.cancelLogsCollection(
						logsCollectionRequest.requestGuid
					);

					if (result.confirmed) {
						return result.data;
					}
				},
			} as Section<'LogsCollectionResponse'>,
		].map(partialSection => ({
			...partialSection,
			state$: this.createState(partialSection.type),
			onCancelSubmission: async machine => {
				const newRequest = await partialSection.onCancelSubmission(machine);
				if (!newRequest) return;

				this.announceCancelRequestStatus(newRequest);

				const newRequests = this.requests$.value.map(existingRequest =>
					existingRequest.type === newRequest.type ? newRequest : existingRequest
				);
				this.requests$.next(newRequests);
			},
		}));
	}

	private getRequestStatus(requestItem: MachineRequest): ActionCenterStatus {
		const isDownloadStatus =
			(requestItem.type === 'ForensicsResponse' || requestItem.type === 'LogsCollectionResponse') && requestItem.requestStatus === 'Succeeded';

		let machineRequest: MachineRequest = requestItem;

		if (isDownloadStatus) {
			machineRequest = cloneDeep(requestItem); // duplicate item to allow updating RO property
			machineRequest.packageIdentity = this.machine.name;
		}

		return {
			text: this.calculateStatusText(machineRequest),
			helpText: this.calculateHelpText(machineRequest),
			iconName: isDownloadStatus ? FabricIconNames.Download : null,
			onClick: isDownloadStatus
				? () => {


						const downloadUrl$ = (requestItem.type === 'ForensicsResponse' ? this.paris
							.getRelatedItem<MachineRequest, MachineRequestPackageDownload>(
								MachineRequestPackageDownloadRelationship,
								machineRequest
							) :
							this.paris
									.getRelatedItem<MachineRequest, MachineRequestLogsDownload>(
										MachineRequestLogsCollectionDownloadRelationship,
										machineRequest
							  )
						    )
							.pipe(map(({ url }) => url));

						this.downloadService.downloadFromUrl(downloadUrl$, {
							isAuthenticated: true,
						});
				  }
				: null,
		};
	}

	private buildActionTextsMaps() {
		this._statusTextPrefixRequestTextKeysMap = {
			IsolationResponse: (request: MachineIsolationRequest) => {
				if (request.action === 'Isolate')
					return 'machines.actionCenter.statuses.prefix.isolation.isolate';
				else if (request.action === 'Unisolate')
					return 'machines.actionCenter.statuses.prefix.isolation.unisolate';

				assertNever(request.action);
			},
			ForensicsResponse: (request: MachineCollectInvesigationPackageRequest) =>
				'machines.actionCenter.statuses.prefix.packageCollection',
			ScanResponse: (request: MachineRunAntivirusScanRequest) =>
				'machines.actionCenter.statuses.prefix.antivirusScan',
			RestrictExecutionResponse: (request: MachineRestrictAppExecutionRequest) => {
				if (request.policyType === 'Restrict')
					return 'machines.actionCenter.statuses.prefix.appRestriction.policyType.restrict';
				else if (request.policyType === 'Unrestrict')
					return 'machines.actionCenter.statuses.prefix.appRestriction.policyType.unrestrict';

				assertNever(request.policyType);
			},
			TroubleshootResponse: (request: TroubleshootModeRequest) => {
				return 'machines.actionCenter.statuses.prefix.troubleshootMode';
			},
			LogsCollectionResponse: (request: MachineLogsCollectionRequest) => 'machines.actionCenter.statuses.prefix.logsCollection'
		};

		this._statusTextSuffixRequestTextKeysMap = {
			Cancelled: request => 'machines.actionCenter.statuses.suffix.status.cancelled',
			Failed: request => 'machines.actionCenter.statuses.suffix.status.failed',
			InProgress: request => 'machines.actionCenter.statuses.suffix.status.inProgress',
			Submitted: request => 'machines.actionCenter.statuses.suffix.status.submitted',
			None: request => '',
			Succeeded: request => {
				switch (request.type) {
					case 'IsolationResponse':
						return request.action === 'Unisolate'
							? 'machines.actionCenter.statuses.suffix.status.succeeded.isolation.unisolate'
							: 'machines.actionCenter.statuses.suffix.status.succeeded.isolation.isolate';
					case 'ForensicsResponse':
						return 'machines.actionCenter.statuses.suffix.status.succeeded.packageCollection';
					case 'RestrictExecutionResponse':
						return request.policyType === 'Unrestrict'
							? 'machines.actionCenter.statuses.suffix.status.succeeded.appRestriction.policyType.unrestrict'
							: 'machines.actionCenter.statuses.suffix.status.succeeded.appRestriction.policyType.restrict';
					case 'ScanResponse':
						return 'machines.actionCenter.statuses.suffix.status.succeeded.antivirusScan';
					case 'TroubleshootResponse':
						return 'machines.actionCenter.statuses.suffix.status.succeeded.troubleshootMode';
					case 'LogsCollectionResponse':
						return 'machines.actionCenter.statuses.suffix.status.succeeded.logsCollection';
					default:
						assertNever(request);
				}
			},
		};

		this._actionTextPrefixRequestTextKeysMap = {
			ForensicsResponse: (request, kind) =>
				'machines.actionCenter.statusActions.prefix.packageCollection',
			ScanResponse: (request: MachineRunAntivirusScanRequest, kind) =>
				request.scanType === 'Quick'
					? 'machines.actionCenter.statusActions.prefix.antivirusScan.scanType.quick'
					: 'machines.actionCenter.statusActions.prefix.antivirusScan.scanType.full',
			RestrictExecutionResponse: (request, kind) =>
				'machines.actionCenter.statusActions.prefix.appRestriction',
			IsolationResponse: (request: MachineIsolationRequest, kind) => {
				if (request.action === 'Isolate') {
					return kind === 'submit' && request.isolationType === 'Selective'
						? 'machines.actionCenter.statusActions.prefix.isolation.selective'
						: 'machines.actionCenter.statusActions.prefix.isolation.full';
				} else if (request.action === 'Unisolate') {
					return 'machines.actionCenter.statusActions.prefix.isolation.unisolate';
				}

				assertNever(request.action);
			},
			TroubleshootResponse: (request, kind) =>
				'machines.actionCenter.statusActions.prefix.troubleshootMode',
			LogsCollectionResponse: (request, kind) =>
				'machines.actionCenter.statusActions.prefix.logsCollection',
		};
	}

	private calculateStatusText(request: MachineRequest): string {
		const prefixKey = this._statusTextPrefixRequestTextKeysMap[request.type](request);
		const suffixKey = this._statusTextSuffixRequestTextKeysMap[request.requestStatus](request);

		return `${this.i18nService.get(prefixKey)} ${this.i18nService.get(suffixKey)}`;
	}

	private calculateHelpText(request: MachineRequest): string | null {
		return (
			(request &&
				request.errorCode &&
				this.machineRequestErrorCodePipe.transform(request.errorCode.value)) ||
			null
		);
	}

	private calculateActionText(request: MachineRequest, kind: 'submit' | 'cancel'): string {
		const prefixKey = this._actionTextPrefixRequestTextKeysMap[request.type](request, kind);
		const suffixKey =
			kind === 'submit'
				? 'machines.actionCenter.statuses.suffix.kind.submit'
				: 'machines.actionCenter.statuses.suffix.kind.cancel';

		return `${this.i18nService.get(prefixKey)} ${this.i18nService.get(suffixKey)}`;
	}

	private announceCancelRequestStatus(request: MachineRequest){
		if(!request)
			return;
		const textSelector = this.cancelAnnounceKeysMap[request.requestStatus];
		if(!textSelector)
			return;
		this.liveAnnouncer.announce(this.i18nService.get(textSelector),'assertive',300);
	}
}
