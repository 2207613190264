import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { DataEntityType, EntityModelBase } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../services/global-entity-types.service';
import { EntityPanelsService } from '../services/entity-panels.service';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { TypedChanges, OnChanges } from '@wcd/angular-extensions';
import { EntityType } from '../models/entity-type.interface';
import { File, Ip, NetworkEndpoint, Process, Url } from '@wcd/domain';
import { RoutesService } from '@wcd/shared';
import { FeaturesService, Feature } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';

/**
 * Entity link component
 * Opens entity panel on click
 * Opens entity page on "right-click+open in new tab" or "ctrl+click" or "mouse-middle-click"
 * Implements special logic for "Process" (renders File entity links) and "NetworkEndpoint" (renders both Ip and Url links) links
 */
@Component({
	selector: 'entity-link',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="item && itemEntityType">
			<!-- Network endpoint links are rendederd as 2 links: Ip and Url -->
			<ng-container *ngIf="originalEntityType !== networkEndpointType; else ipAndUrlLinks">
				<ng-template *ngTemplateOutlet="linkContent"></ng-template>
			</ng-container>
		</ng-container>
		<ng-template #linkContent>
			<a [href]="link" (click)="openEntityPanel($event)"
				>{{ getLinkText() }}
				<wcd-shared-icon
					*ngIf="showExternalLinkIcon"
					iconName="popOut"
					class="field-value-icon field-additional-icon"
				></wcd-shared-icon>
			</a>
		</ng-template>
		<ng-template #ipAndUrlLinks>
			<entity-link *ngIf="item.ip" [entity]="item.ip" [entityType]="ipType"></entity-link>
			<ng-container *ngIf="item.ip && item.url; else urlLinkContent">
				(<ng-template *ngTemplateOutlet="urlLinkContent"></ng-template>)
			</ng-container>
		</ng-template>
		<ng-template #urlLinkContent>
			<entity-link *ngIf="item.url" [entity]="item.url" [entityType]="urlType"></entity-link>
		</ng-template>
	`,
})
export class EntityLinkComponent implements OnChanges<EntityLinkComponent> {
	readonly networkEndpointType = NetworkEndpoint;
	readonly ipType = Ip;
	readonly urlType = Url;
	private enableProcessSidePanel: boolean = false;
	link: string;
	showExternalLinkIcon: boolean;
	entityPanelsService: EntityPanelsService;
	item: EntityModelBase<string | number>;
	itemEntityType: EntityType<EntityModelBase<string | number>>;
	originalEntityType: DataEntityType<EntityModelBase<string | number>>;

	@Input() linkText: string | null;

	@Input() actionTime: Date;

	@Input() linkLogContext: string; // This string will be passed to entityPanelsService.showEntity and is used for logging

	@Input()
	set entityType(value: DataEntityType<EntityModelBase<string | number>>) {
		this.originalEntityType = value;
		const isProcessEntityType = value === Process;
		this.itemEntityType = this.globalEntityTypesService.getEntityType(!isProcessEntityType || this.enableProcessSidePanel ? value : File); // Process links are treated as File links
	}

	@Input()
	set entity(value: EntityModelBase<string | number>) {
		this.item = value;
	}

	constructor(
		private readonly injector: Injector,
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly appInsightsService: AppInsightsService,
		private readonly routesService: RoutesService,
		private readonly featuresService: FeaturesService
	) {
		this.entityPanelsService = this.injector.get(EntityPanelsService);
		this.enableProcessSidePanel = sccHostService.isSCC && this.featuresService.isEnabled(Feature.ImprovedEntitySidePanels)
			&& this.featuresService.isEnabled(Feature.ReactEntityPanelsInAngular);
	}

	ngOnChanges(changes: TypedChanges<EntityLinkComponent>) {
		if (!this.itemEntityType) {
			console.warn(`Entity link rendered without details.`, this.item, this.originalEntityType);
			return;
		}
		if (this.originalEntityType === Process && !this.enableProcessSidePanel){
			this.item = this.item['file']; // Process links are treated as File links
		}

		const link =
			this.itemEntityType &&
			this.itemEntityType.getEntitiesLink &&
			this.itemEntityType.getEntitiesLink([this.item]);

		const mdatpFromSccUrl = this.routesService.getMdatpFromSccLinkConfig(link);

		this.link = mdatpFromSccUrl ? mdatpFromSccUrl.url : link;
		if (this.routesService.shouldOpenExternally(mdatpFromSccUrl)) {
			this.showExternalLinkIcon = true;
		}
	}

	getLinkText(): string {
		return (
			this.linkText ||
			(this.itemEntityType &&
				this.itemEntityType.getEntityName &&
				this.itemEntityType.getEntityName(this.item)) ||
			this.item['name'] ||
			this.item.id
		);
	}

	openEntityPanel(event: MouseEvent) {
		if (event.ctrlKey) {
			this.trackNavigation(`EntityLinkOpenInNewTab_${this.itemEntityType.entity.singularName}`);
			return;
		} else {
			this.trackNavigation(`EntityLinkOpenEntityPanel_${this.itemEntityType.entity.singularName}`);
			event.preventDefault();
			event.stopPropagation();

			this.entityPanelsService.showEntity(
				this.itemEntityType.entity,
				this.item,
				{ actionTime: this.actionTime },
				{
					back: {
						onClick: () => this.entityPanelsService.closeEntityPanel(this.itemEntityType.entity),
					},
				},
				this.linkLogContext
			);
		}
	}

	trackNavigation(id: string) {
		this.appInsightsService.trackEvent('UI', {
			id: id,
			type: TrackingEventType.Navigation,
		});
	}
}
