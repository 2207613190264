import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Automated IR property',
	pluralName: 'Automated IR properties',
	endpoint: 'properties/',
	parseItemQuery: () => 'properties/',
	parseSaveQuery: () => 'properties/update',
	serializeItem: (item, serializedItem) => ({
		properties: serializedItem,
	}),
	saveMethod: 'PATCH',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class AutomatedIrProperties extends EntityModelBase {
	@EntityField({
		data: 'AutomatedIrPuaAsSuspicious',
		parse: feature => !feature,
		serialize: feature => !feature,
	})
	automatedIrRemediatePua?: boolean;
}
