import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { OnChanges, TypedChanges } from '../types/angular/on-changes';

@Directive({
	selector: '[fabButtonFocusOn]',
})
export class FabButtonFocusOnDirective implements OnChanges<FabButtonFocusOnDirective>, AfterViewInit, OnDestroy {
	@Input() fabButtonFocusOn: boolean;
	@Input() focusingDelay: number = 0;

	@Input() fabButtonBlurCallback?: ()=>void

	_button: HTMLButtonElement;
	get button(){
		if (!this._button){
			this._button = this.elemRef.nativeElement.querySelector('button')
		}
		return this._button;
	}
	focused = false;
	blurCallbackInit = false;

	constructor(private elemRef: ElementRef) {}

	ngOnChanges(changes: TypedChanges<FabButtonFocusOnDirective>) {
		this.focused = false;
		if (changes.fabButtonFocusOn && changes.fabButtonFocusOn.currentValue) {
			this.focusOnButton(this.focusingDelay)
		}
	}

	ngAfterViewInit() {
		if (this.fabButtonFocusOn) {
			this.focusOnButton(this.focusingDelay);
		}
	}

	ngOnDestroy(){
		this.fabButtonBlurCallback && !this.blurCallbackInit && this.button.removeEventListener('blur', this.onBlur);
	}

	onBlur = ()=>{
		this.focused = false;
		this.fabButtonBlurCallback();
	}

	focusOnButton(delay = 0){
		setTimeout(() => {
			if (this.elemRef && this.elemRef.nativeElement) {
				if (this.button && !this.focused) {
					this.focused = true;
					this.button.focus();
					if (this.fabButtonBlurCallback && !this.blurCallbackInit){
						this.blurCallbackInit = true;
						this.button.addEventListener('blur', this.onBlur)
					}
				}
			}
		}, delay);
	}
}
