import { fromPairs, zip, isNil, sum, without, isEmpty } from 'lodash-es';
import { OfficeDeliveryLocation } from '../investigation/actions/office-action.utils';

export class MailClusterUtils {
	static getQuery(clusteredBy: string, clusteredByValue: string): { [index: string]: Array<string> } {
		if (clusteredBy && clusteredByValue) {
			try {
				return fromPairs(
					zip(clusteredBy.split(';'), clusteredByValue.split(';').map(v => v.split(',')))
				);
			} catch (e) {}
		}
	}

	static getDisplayQuery(clusteredBy: string, clusteredByValue: string): string {
		const queryObj = MailClusterUtils.getQuery(clusteredBy, clusteredByValue);
		if (queryObj) {
			try {
				return Object.entries(queryObj)
					.map(([k, v]) => `${k}:(${v.map(_v => `"${_v}"`).join(',')})`)
					.join(' and ');
			} catch (e) {}
		}
	}
}

export function getMailClusterMailboxCount(
	countByDeliveryLocation: { [key in OfficeDeliveryLocation]: number }
): number {
	if (countByDeliveryLocation) {
		const unknown = countByDeliveryLocation[OfficeDeliveryLocation.Unknown];
		const inbox = countByDeliveryLocation[OfficeDeliveryLocation.Inbox];
		const junkFolder = countByDeliveryLocation[OfficeDeliveryLocation.JunkFolder];
		const cloudMailbox = countByDeliveryLocation[OfficeDeliveryLocation.CloudMailBox];
		const others = countByDeliveryLocation[OfficeDeliveryLocation.Others];

		// keep '0' values in the array
		const deliveryLocations = without(
			[unknown, inbox, junkFolder, cloudMailbox, others],
			null,
			undefined
		);

		return !isEmpty(deliveryLocations) ? sum(deliveryLocations) : null;
	}

	return null;
}

export function getMailClusterNotInMailboxCount(
	countByDeliveryLocation: { [key in OfficeDeliveryLocation]: number }
): number {
	if (countByDeliveryLocation) {
		const deletedFolder = countByDeliveryLocation[OfficeDeliveryLocation.DeletedFolder];
		const quarantine = countByDeliveryLocation[OfficeDeliveryLocation.Quarantine];
		const failed = countByDeliveryLocation[OfficeDeliveryLocation.Failed];
		const dropped = countByDeliveryLocation[OfficeDeliveryLocation.Dropped];
		const forwarded = countByDeliveryLocation[OfficeDeliveryLocation.Forwarded];

		// keep '0' values in the array
		const deliveryLocations = without(
			[deletedFolder, quarantine, failed, dropped, forwarded],
			null,
			undefined
		);

		return !isEmpty(deliveryLocations) ? sum(deliveryLocations) : null;
	}

	return null;
}

export function getMailClusterOnPremOrExternalCount(
	countByDeliveryLocation: { [key in OfficeDeliveryLocation]: number }
): number {
	return countByDeliveryLocation && !isNil(countByDeliveryLocation[OfficeDeliveryLocation.External])
		? countByDeliveryLocation[OfficeDeliveryLocation.External]
		: null;
}

// Forces int32 arithmetic (i.e. overflows) to simulate C/C++ int addition
function int32Add(arg1: number, arg2: number): number {
	return (arg1 + arg2) | 0;
}

// Adaptation of String.GetHashCode() from .NET 4.x
function CSharpStringGetHashCode(input: string) {
	let hash1 = 5381;
	let hash2 = hash1;

	let index = 0;
	let charUnicode;

	while (index < input.length) {
		charUnicode = input.charCodeAt(index);
		hash1 = int32Add(hash1 << 5, hash1) ^ charUnicode;

		if (index + 1 >= input.length) {
			break;
		}
		charUnicode = input.charCodeAt(index + 1);
		hash2 = int32Add(hash2 << 5, hash2) ^ charUnicode;
		index += 2;
	}

	return int32Add(hash1, Math.imul(hash2, 1566083941));
}

/* 	For admin mail cluster we need to get the internalId that generated from the urn.
	Note that this is a temporary solution and this logic should be in BE in the future.
* */
export function getMailClusterInternalIdFromUrns(airInvUrn: string, mailClusterUrn?: string): string {
	const urns = mailClusterUrn ? [airInvUrn, mailClusterUrn] : [airInvUrn];
	let hash = 0;
	// apply bitwise XOR to C# hash-codes of input urns to get a single hash value
	for (const urn of urns) {
		hash ^= CSharpStringGetHashCode(urn);
	}

	// convert Int32 value to array of bytes
	const arr = new ArrayBuffer(4);
	const view = new DataView(arr);
	view.setUint32(0, hash, false);

	// Pad the array to 16 bytes (filling zeroes), and convert to hex-string
	const bytes = Array.from(new Uint8Array(arr)).concat(Array(12).fill(0));
	const guid = bytes.reduce((output, elem) => output + ('0' + elem.toString(16)).slice(-2), '');

	// reformat to MS format
	return `${guid.slice(0, 8)}-${guid.slice(8, 12)}-${guid.slice(12, 16)}-${guid.slice(16, 20)}-${guid.slice(
		20
	)}`;
}
