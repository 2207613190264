/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../file.prevalence.component.ngfactory";
import * as i2 from "../../file.prevalence.component";
import * as i3 from "@microsoft/paris/dist/lib/paris";
import * as i4 from "../../../../../shared/services/time-ranges.service";
import * as i5 from "../../../../../admin/services/office-integration.service";
import * as i6 from "../../../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i7 from "../../../../../insights/services/app-insights.service";
import * as i8 from "../../../../../../../../../projects/config/src/lib/services/features.service";
import * as i9 from "@angular/common";
import * as i10 from "./file.prevalence.widget";
import * as i11 from "../../../../../shared-reports/services/reports.service";
import * as i12 from "../../../../../global_entities/services/activated-entity.service";
import * as i13 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_FilePrevalenceWidgetComponent = [];
var RenderType_FilePrevalenceWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilePrevalenceWidgetComponent, data: {} });
export { RenderType_FilePrevalenceWidgetComponent as RenderType_FilePrevalenceWidgetComponent };
export function View_FilePrevalenceWidgetComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "file-prevalence", [["supportNewLayout", "true"]], null, [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FilePrevalenceComponent_0, i1.RenderType_FilePrevalenceComponent)), i0.ɵdid(2, 114688, null, 0, i2.FilePrevalenceComponent, [i3.Paris, i4.TimeRangesService, i0.ChangeDetectorRef, i5.OfficeIntegrationService, i6.AppConfigService, i7.AppInsightsService, i8.FeaturesService], { supportNewLayout: [0, "supportNewLayout"], file: [1, "file"] }, { error: "error" }), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "true"; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 3).transform(_co.data$)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_FilePrevalenceWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_FilePrevalenceWidgetComponent_0, RenderType_FilePrevalenceWidgetComponent)), i0.ɵdid(1, 245760, null, 0, i10.FilePrevalenceWidgetComponent, [i11.ReportsService, i12.ActivatedEntity, i13.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilePrevalenceWidgetComponentNgFactory = i0.ɵccf("ng-component", i10.FilePrevalenceWidgetComponent, View_FilePrevalenceWidgetComponent_Host_0, {}, {}, []);
export { FilePrevalenceWidgetComponentNgFactory as FilePrevalenceWidgetComponentNgFactory };
