import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { RegistryValue } from '@wcd/domain';
import { RegistryEntityPanelComponent } from '../components/registry.entity-panel.component';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';

@Injectable()
export class RegistryValueEntityTypeService implements EntityTypeService<RegistryValue> {
	readonly entityType: Readonly<EntityType<RegistryValue>> = {
		entity: RegistryValue,
		id: 'registryValue',
		icon: 'entities.registry',
		singleEntityPanelComponentType: RegistryEntityPanelComponent,
		loadFullEntityInPanel: false,
		getEntityName: registryValue => registryValue.name,
		getNavigationModel: () => {
			return {
				routerLink: null
			}
		},
		entityPageViewMode: EntityPageViewMode.Default,
		entityPluralNameKey: 'registryValue_entityType_pluralName',
		entitySingularNameKey: 'registryValue_entityType_singularName',
	};
}
