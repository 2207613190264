var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
import { FileInstance } from '../file/file-instance.entity';
import { NetworkEndpoint } from './network-endpoint.value-object';
import { Url } from '../url/url.entity';
import { Ip } from '../ip/ip.entity';
import { AntivirusBlockDetails } from './antivirus-block-details.value-object';
var ɵ0 = function (rawData) {
    var url = rawData.FileMotwHostUrl, ip = rawData.FileMotwHostIp;
    return url || ip
        ? new NetworkEndpoint({
            id: url || ip,
            url: new Url({ id: url, name: url }),
            ip: new Ip({ id: ip, name: ip }),
        })
        : null;
}, ɵ1 = function (rawData) {
    return rawData
        ? new NetworkEndpoint({ id: rawData, url: new Url({ id: rawData, name: rawData }) })
        : null;
};
var FileActionDetails = /** @class */ (function (_super) {
    __extends(FileActionDetails, _super);
    function FileActionDetails() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'ActionTime' }),
        __metadata("design:type", Date)
    ], FileActionDetails.prototype, "actionTime", void 0);
    __decorate([
        EntityField({ data: 'ActionType' }),
        __metadata("design:type", String)
    ], FileActionDetails.prototype, "actionType", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ0,
        }),
        __metadata("design:type", NetworkEndpoint)
    ], FileActionDetails.prototype, "fileOrigin", void 0);
    __decorate([
        EntityField({
            data: 'FileMotwReferrerUrl',
            parse: ɵ1,
        }),
        __metadata("design:type", NetworkEndpoint)
    ], FileActionDetails.prototype, "fileOriginReferer", void 0);
    __decorate([
        EntityField({ data: '__self' }),
        __metadata("design:type", FileInstance)
    ], FileActionDetails.prototype, "file", void 0);
    __decorate([
        EntityField({ data: 'EtwEventPropertiesAsJson' }),
        __metadata("design:type", AntivirusBlockDetails)
    ], FileActionDetails.prototype, "blockDetails", void 0);
    FileActionDetails = __decorate([
        ValueObject({
            singularName: 'File action details',
            pluralName: 'File action details',
        })
    ], FileActionDetails);
    return FileActionDetails;
}(ModelBase));
export { FileActionDetails };
export { ɵ0, ɵ1 };
