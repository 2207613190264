import { Injectable } from '@angular/core';
import { ColorsService } from '../../shared/services/colors.service';
import { KnownColorsService } from '../../shared/services/known-colors.service';
import { KnownColorName } from '../../shared/models/colors/known-colors.models';

export enum TvmColor {
	CriticalRisk = 'criticalRisk',
	HighRisk = 'highRisk',
	MediumRisk = 'mediumRisk',
	LowRisk = 'lowRisk',
	GraphSeries = 'graphSeries',
	Blue = 'blue',
	Positive = 'positive',
	Neutral = 'neutralQuaternary',
	NeutralAlt = 'GraphNatural',
	BlueDark = 'blueDark',
	BlueMid = 'blueMid',
	Green = 'green',
	TealLight = 'tealLight',
	PurpleLight = 'purpleLight',
	GraphSeriesLight = 'graphSeriesLight',
	PurpleDark = 'purpleDark',
	NeutralTertiary = 'neutralTertiary',
}

const severityTypeColorName: Record<TvmColor, KnownColorName> = {
	[TvmColor.CriticalRisk]: 'darkBrown',
	[TvmColor.HighRisk]: 'highSeverity',
	[TvmColor.MediumRisk]: 'mediumSeverity',
	[TvmColor.LowRisk]: 'lowSeverity',
	[TvmColor.GraphSeries]: 'neutralSecondary',
	[TvmColor.GraphSeriesLight]: 'neutralSecondaryAlt',
	[TvmColor.Blue]: 'blue',
	[TvmColor.Positive]: 'greenLight',
	[TvmColor.Neutral]: 'neutralQuaternary',
	[TvmColor.NeutralAlt]: 'neutralQuaternaryAlt',
	[TvmColor.BlueDark]: 'blueDark',
	[TvmColor.BlueMid]: 'blueMid',
	[TvmColor.Green]: 'green',
	[TvmColor.TealLight]: 'tealLight',
	[TvmColor.PurpleLight]: 'purpleLight',
	[TvmColor.PurpleDark]: 'purpleDark',
	[TvmColor.NeutralTertiary]: 'neutralTertiary',
};

@Injectable()
export class TvmColorService extends ColorsService<TvmColor> {
	constructor(knownColorsService: KnownColorsService) {
		super(knownColorsService, severityTypeColorName);
	}

	getStatusIcon = (value) => `ms-fontColor-${
			this.colorsMap.get(
				value ? TvmColor.Green : TvmColor.NeutralTertiary
			).name
		} field-value-font-size-mi`;

	getCompliantIcon = (value) => `ms-fontColor-${
			this.colorsMap.get(
				value ? TvmColor.Green : TvmColor.HighRisk
			).name
		} field-value-font-size-mi`;
}
