<wcd-panel (close)="destroy()"
		   [settings]="settings"
		   [disableAutoFocus]="disableAutoFocus"
		   [disableTrapFocus]="disableTrapFocus">
	<div class="dialog-snackbar-panel wcd-margin-xsmall-top" *ngIf="isLoadingEntity || loadEntityRbacError">
		<div class="dialog-snackbar" *ngIf="isLoadingEntity; else loadEntityRbacErrorSnackbar">
			<accessible-spinner [loadingEntityName]="'entity_header_dialog' | i18n : {'header': getName()}" spinnerSize="large" labelPosition="right"></accessible-spinner>
		</div>
		<ng-template #loadEntityRbacErrorSnackbar>
			<div class="dialog-snackbar color-box-malware">
				 <wcd-shared-icon iconName="Warning"> </wcd-shared-icon >
				{{'entity_missing_permissions' | i18n}}
			</div>
		</ng-template>
	</div>
	<div class="wcd-full-height wcd-flex-vertical"
		 [attr.data-track-component]="entityType.entity.singularName + ' Panel'"
		 data-track-component-type="Side Panel">
		<header class="wcd-flex-none wcd-padding-small-vertical wcd-padding-large-horizontal wcd-flex-horizontal">
			<ng-container *ngLet="(entityImage$ | async) as entityImage">
				<div *ngIf="entityImage" class="page-header-icon wcd-flex-none">
					<img
							*ngIf="entityImage"
							alt="null"
							[src]="entityImage"
							[ngClass]="entityType.getImageCssClass ? entityType.getImageCssClass(entities) : null"/>
				</div>
			</ng-container>
			<div class="wcd-flex-1 wcd-flex-center-vertical">
				<div class="wcd-full-width">
					<ng-container *ngLet="(tagsWithPseudoTags$ | async) as tagsWithPseudoTags">
						<h1 #panelHeader class="wcd-text-overflow-ellipsis wcd-margin-small-bottom wcd-font-weight-bold header-height">{{entityName}}</h1>
						<div class="page-subtitle" *ngIf="entitySubtitle">{{entitySubtitle}}</div>
						<tags-list [multiline]="true" *ngIf="tagsWithPseudoTags?.length" [tags]="tagsWithPseudoTags"></tags-list>
						<div #entityStatusPlaceholder></div>
					</ng-container>
				</div>
			</div>
		</header>

		<ng-container *ngIf="lastSetActionsTime && entityType && (entity || entities) && !loadEntityRbacError && (isUserExposed$ | async)">
			<entity-command-bar	[entityType]="entityType"
								[entity]="entity"
								[entities]="entities"
								[options]="options"
								[isLoadingEntitySubject$]="isLoadingEntitySubject$"
								[lastSetActionsTime]="lastSetActionsTime"
								[detailsActionId]="detailsActionId"
								[lastDetailsActionTime]="lastDetailsActionTime"
								[isUserExposed$]="isUserExposed$"
								[loadEntityError]="loadEntityError"
								(commandBarAction)="onRunAction($event.action, $event.data, $event.tags)">
			</entity-command-bar>
		</ng-container>

		<div class="wcd-flex-1 wcd-scroll-vertical entity-panel-content" [ngClass]="contentClass">
			<div class="wcd-full-height wcd-flex-center-all"
				 *ngIf="!entities?.length && (isLoadingEntity || loadEntityError)">
				<div *ngIf="isLoadingEntity" class="center-text">
					<accessible-spinner [loadingEntityName]="entitySingularName" spinnerSize="large" labelPosition="right"></accessible-spinner>
				</div>
				<div class="error-message center-text" *ngIf="loadEntityError">
					 <wcd-shared-icon iconName="Warning"> </wcd-shared-icon >
						<strong>{{"entity.loading.error" | i18n : {itemType : entitySingularName} }}</strong>
				</div>
			</div>
			<div #entityDetailsPlaceholder></div>
		</div>
	</div>
</wcd-panel>
