var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { Machine } from '../../machine/machine.entity';
import { IncidentGraphNodeEntityType } from './incident-graph-node-entity-type.entity';
import { IncidentGraphCategory } from './incident-graph-category.entity';
import { NetworkEndpoint } from '../../entity/network-endpoint.value-object';
import { IncidentGraphNodeSource } from './incident-graph-node-source.entity';
import { IncidentGraphNodeEntityTypeId } from './incident-graph-node-entity-type.values';
import { File } from '../../file/file.entity';
import { LegacyUser } from '../../legacy/user/legacy-user.entity';
import { IncidentGraphGroupingLogics } from './incident-graph-grouping-logics.value-object';
import { Registry } from '../../entity/registry.value-object';
var IncidentGraphNode = /** @class */ (function (_super) {
    __extends(IncidentGraphNode, _super);
    function IncidentGraphNode() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IncidentGraphNode_1 = IncidentGraphNode;
    Object.defineProperty(IncidentGraphNode.prototype, "isGroup", {
        get: function () {
            return !!this.groupName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IncidentGraphNode.prototype, "name", {
        get: function () {
            if (this.groupName)
                return this.groupName;
            if (!this.entityType)
                return this.id;
            switch (this.entityType.id) {
                case IncidentGraphNodeEntityTypeId.NetworkEndpoint:
                    return this.networkEndpoint.name;
                case IncidentGraphNodeEntityTypeId.File:
                    return this.file.fileName || this.file.name;
                case IncidentGraphNodeEntityTypeId.Machine:
                    return this.machine.shortName || this.machine.name;
                case IncidentGraphNodeEntityTypeId.User:
                    return this.user['name'];
                case IncidentGraphNodeEntityTypeId.Registry:
                    var registryKeyLastToken = this.registry.key.split('\\').pop();
                    return this.registry.valueName
                        ? registryKeyLastToken + "->" + this.registry.valueName
                        : registryKeyLastToken;
                default:
                    return this.id;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IncidentGraphNode.prototype, "entity", {
        get: function () {
            return this.machine || this.file || this.networkEndpoint || this.registry;
        },
        enumerable: true,
        configurable: true
    });
    var IncidentGraphNode_1;
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", String)
    ], IncidentGraphNode.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'EntityType' }),
        __metadata("design:type", IncidentGraphNodeEntityType)
    ], IncidentGraphNode.prototype, "entityType", void 0);
    __decorate([
        EntityField({ data: 'Machine' }),
        __metadata("design:type", Machine)
    ], IncidentGraphNode.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'File' }),
        __metadata("design:type", File)
    ], IncidentGraphNode.prototype, "file", void 0);
    __decorate([
        EntityField({ data: 'NetworkEndpoint' }),
        __metadata("design:type", NetworkEndpoint)
    ], IncidentGraphNode.prototype, "networkEndpoint", void 0);
    __decorate([
        EntityField({ data: 'Registry' }),
        __metadata("design:type", Registry)
    ], IncidentGraphNode.prototype, "registry", void 0);
    __decorate([
        EntityField({ data: 'User' }),
        __metadata("design:type", LegacyUser)
    ], IncidentGraphNode.prototype, "user", void 0);
    __decorate([
        EntityField({ data: 'ChildNodes', arrayOf: IncidentGraphNode_1 }),
        __metadata("design:type", Array)
    ], IncidentGraphNode.prototype, "childNodes", void 0);
    __decorate([
        EntityField({ data: 'Category' }),
        __metadata("design:type", IncidentGraphCategory)
    ], IncidentGraphNode.prototype, "category", void 0);
    __decorate([
        EntityField({ data: 'GroupName' }),
        __metadata("design:type", String)
    ], IncidentGraphNode.prototype, "groupName", void 0);
    __decorate([
        EntityField({
            data: 'GroupingLogics',
            arrayOf: IncidentGraphGroupingLogics,
        }),
        __metadata("design:type", Array)
    ], IncidentGraphNode.prototype, "groupingLogics", void 0);
    __decorate([
        EntityField({ data: 'Source' }),
        __metadata("design:type", IncidentGraphNodeSource)
    ], IncidentGraphNode.prototype, "source", void 0);
    __decorate([
        EntityField({ data: 'RelatedResourcesNodeIds' }),
        __metadata("design:type", Array)
    ], IncidentGraphNode.prototype, "relatedResourcesNodeIds", void 0);
    IncidentGraphNode = IncidentGraphNode_1 = __decorate([
        ValueObject({
            singularName: 'Incident graph node',
            pluralName: 'Incident graph nodes',
        })
    ], IncidentGraphNode);
    return IncidentGraphNode;
}(ModelBase));
export { IncidentGraphNode };
