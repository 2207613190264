
		<div class="snackbars">
			<div
				*ngIf="snackBarsService.visibleSnackBar"
				class="snackbar"
				data-track-component="Snackbars"
				[ngClass]="snackBarsService.visibleSnackBar.className"
				[class.removing]="snackBarsService.isRemovingVisibleSnackbar"
			>
				<div class="snackbar-methods">
					<button
						type="button"
						data-track-id="ApplyMethod"
						data-track-type="Button"
						*ngIf="snackBarsService.visibleSnackBar.method"
						(click)="applyMethod()"
						[ngClass]="snackBarsService.visibleSnackBar.method.className"
					>
						<wcd-shared-icon
							[iconName]="snackBarsService.visibleSnackBar.method.icon"
							[ngClass]="snackBarsService.visibleSnackBar.iconClassName"
							*ngIf="snackBarsService.visibleSnackBar.method.icon"
						>
						</wcd-shared-icon>
						{{ snackBarsService.visibleSnackBar.method.text }}
					</button>
				</div>
				<wcd-shared-icon
					*ngIf="snackBarsService.visibleSnackBar.icon"
					[iconName]="snackBarsService.visibleSnackBar.icon"
					[ngClass]="snackBarsService.visibleSnackBar.iconClassName"
				>
				</wcd-shared-icon>
				<div
					class="snackbar-text"
					tabindex="-1"
					[attr.aria-label]="snackBarsService.visibleSnackBar.text"
					[announcerForceFocus]="
						!!snackBarsService.visibleSnackBar &&
						!snackBarsService.visibleSnackBar.disableForceFocus
					"
					[focusableQuery]="snackBarsService.visibleSnackBar.focusableQuery"
				>
					{{ snackBarsService.visibleSnackBar.text }}
				</div>
			</div>
		</div>
	