var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';
import { UrlsService } from '../services/urls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { urlReportModel } from './url.overview.component';
var UrlEntityComponent = /** @class */ (function (_super) {
    __extends(UrlEntityComponent, _super);
    function UrlEntityComponent(i18nService, featuresService, urlsService, router, route) {
        var _this = _super.call(this) || this;
        _this.i18nService = i18nService;
        _this.featuresService = featuresService;
        _this.urlsService = urlsService;
        _this.router = router;
        _this.route = route;
        _this.tabs = [];
        _this.featuresChangeSubscription = _this.featuresService.featureChanged$
            .pipe(filter(function (_a) {
            var featureId = _a.featureId;
            return featureId === Feature.UpgradeUrlPage;
        }))
            .subscribe(function () {
            if (!featuresService.isEnabled(Feature.UpgradeUrlPage)) {
                var params = _this.route.snapshot.queryParams;
                _this.router.navigateByUrl(_this.urlsService.getUrlLink(_this.url.id, new Date((params && params['to']) || Date.now())));
            }
        });
        _this.report = urlReportModel;
        return _this;
    }
    Object.defineProperty(UrlEntityComponent.prototype, "url", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    UrlEntityComponent.prototype.ngOnInit = function () {
        this.tabs = this.getTabs(this.url);
    };
    UrlEntityComponent.prototype.ngOnDestroy = function () {
        this.featuresChangeSubscription && this.featuresChangeSubscription.unsubscribe();
    };
    UrlEntityComponent.prototype.getTabs = function (url) {
        var tabs = [
            {
                id: 'alerts',
                name: this.i18nService.get('urls.tabs.alerts'),
                routerLink: './alerts',
            },
            {
                id: 'timeline',
                name: this.i18nService.get('urls.tabs.observed'),
                routerLink: './timeline',
            },
        ];
        tabs.unshift({
            id: 'overview',
            name: this.i18nService.get('urls.tabs.overview'),
            routerLink: './overview',
        });
        return tabs.map(function (tab) { return new TabModel(tab); });
    };
    return UrlEntityComponent;
}(EntityComponentBase));
export { UrlEntityComponent };
