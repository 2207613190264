<ng-container *ngIf="setKeyboardContainer; else noKeyboardContainer">
	<ul class="wcd-checklist wcd-filter-values-checklist"
		[keyboard-navigable-container]
		[elementVisible]="elementVisible"
		[stopPropagation]="['ArrowUp','ArrowDown']">
		<ng-container *ngTemplateOutlet="filterContent"></ng-container>
	</ul>
</ng-container>


<ng-template #noKeyboardContainer>
	<ul class="wcd-checklist wcd-filter-values-checklist" >
		<ng-container *ngTemplateOutlet="filterContent"></ng-container>
	</ul>
</ng-template>

<ng-template #filterContent>
	<li class="filter-field-all wcd-flex-horizontal wcd-flex-center-vertical"
		*ngIf="values.length > 1 && config?.disableSelectAll !== true && disableSelectAll !== true">
		<wcd-checkbox
			[id]="'filter_' + fieldId + '_toggle_all'"
			(ngModelChange)="toggleAll()"
			[ngModel]="isAll"
			[isPartiallyChecked]="isPartialSelected"
			setKeyboardSupport="true"
			tabIndex="-1"
			[attr.aria-label]="getCheckboxAriaLabel(-1, selectAllText,false)"
			[elementVisible]="elementVisible">
			{{selectAllText}}
		</wcd-checkbox>
	</li>
	<li *ngFor="let filterValue of values; let i = index">
		<div class="wcd-flex-horizontal wcd-flex-center-vertical">
			<div class="wcd-flex-1 wcd-flex-horizontal">
				<div class="wcd-flex-none wcd-filter-values-checklist--value" *ngIf="filterValue">
					<wcd-checkbox
						[ngModel]="isValueSelected(filterValue)"
						class="filter-field-value-checkbox"
						[isDisabled]="isValueDisabled(filterValue)"
						[isPartiallyChecked]="isPartiallySelected(filterValue)"
						(ngModelChange)="onToggleFieldValue(filterValue)"
						tabIndex="-1"
						setKeyboardSupport="true"
						(focusChange)="setChildrenTabIndex($event, filterValue.id, filterValue.hasChildren)"
						[elementVisible]="elementVisible"
						>
						<span [wcdTooltip]="filterValue.displayName"
							  [wcdTooltipShowOnOverflowOnly]="true"
								[class.filter-field-value-checkbox--with-children]="filterValue.hasChildren"
							  [ngClass]="filterValue.className">
							<img [attr.src]="filterValue.image | basePath" *ngIf="filterValue.image"
								 class="filter-field-value-image" role="presentation"/>
							<wcd-icon *ngIf="filterValue.icon?.wcdIconName"
									  [iconName]="filterValue.icon.wcdIconName"
									  [className]="filterValue.icon.className"></wcd-icon>
							<fab-icon *ngIf="filterValue.icon?.iconName"
									  [iconName]="filterValue.icon.iconName"
									  [contentClass]="filterValue.icon.className"></fab-icon>
							<span [ngClass]="filterValue.nameClass"
							[attr.aria-label]="getCheckboxAriaLabel(i, filterValue.displayName, filterValue.hasChildren)"
							>
								{{filterValue.displayName}}
							</span>
						</span>
					</wcd-checkbox>
				</div>

				<div class="wcd-flex-1" *ngIf="filterValue.hasChildren">

					<button
					class="wcd-filter-values-checklist--category--btn"
					[attr.name]="'field_' + fieldId + '_list_open'"
					(click)="toggleValue($event, filterValue)"
					(keydown.enter)="toggleValue($event, filterValue)"
					(keydown.space)="toggleValue($event, filterValue)"
					[attr.tabindex]="childrenTabIndex[filterValue.id]"
					[attr.aria-label]="filterValue.displayName"
					[attr.aria-expanded]="isExpanded(filterValue)"
					>

						<fab-icon
							aria-hidden="true"
							[iconName]="isExpanded(filterValue) ? 'ChevronUp' : 'ChevronDown'"
							contentClass="wcd-filter-values-checklist--category--btn--expand"
							>
						</fab-icon>
					</button>
				</div>
			</div>

			<span *ngIf="filterValue.count"
				class="wcd-flex-none filter-field-value-count">
				{{filterValue.count ? (filterValue.count | prettyNumber) : ''}}
			</span>
		</div>
		<div
			*ngIf="filterValue.childCategories"
			[class.wcd-filter-hidden]="!isExpanded(filterValue)">
			<section *ngFor="let category of filterValue.childCategories"
					 class="wcd-filter-values-checklist--category wcd-margin-bottom">
				<h5 *ngIf="category.name" class="wcd-filter-values-checklist--category--name">{{category.name}}</h5>
				<wcd-filter-values-checklist
					[fieldId]="fieldId + '_' + filterValue.id"
					[parentValue]="filterValue"
					[config]="config"
					[disableSelectAll]="true"
					(filterValuesChange)="onChildChecklistChange(filterValue)"
					[data]="getCategoryValues(category)"
					[selectedValues]="selectedValues"
					[setKeyboardContainer]="false"
					[elementVisible]="isExpanded(filterValue)"></wcd-filter-values-checklist>
			</section>
		</div>
	</li>
</ng-template>

