var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalContainer } from '../modals/models/modal-container.model';
var ConfirmModalComponent = /** @class */ (function (_super) {
    __extends(ConfirmModalComponent, _super);
    function ConfirmModalComponent(router) {
        var _this = _super.call(this, router) || this;
        _this.confirmText = '';
        _this.confirmOngoing = false;
        _this.focus = false;
        return _this;
    }
    ConfirmModalComponent.prototype.ngOnInit = function () {
        if (this.options.requireMessage && this.options.requireMessage.defaultValue)
            this.confirmText = this.options.requireMessage.defaultValue;
    };
    ConfirmModalComponent.prototype.ngAfterViewInit = function () {
        this.focus = true;
    };
    ConfirmModalComponent.prototype.confirmAndClose = function () {
        var _this = this;
        this.confirm(this.confirmText);
        if (!this.options.showLoaderAndCloseOnEvent) {
            this.destroy();
        }
        else {
            this.confirmOngoing = true;
            this.options.showLoaderAndCloseOnEvent.subscribe(function (e) {
                _this.destroy();
            });
        }
    };
    return ConfirmModalComponent;
}(ModalContainer));
export { ConfirmModalComponent };
