import { Component, OnDestroy, Input } from '@angular/core';
import {
	DetectionTestSettingsService,
	TestDetectionSettings,
} from '../../services/endpoint-management-detection-test-settings.service';
import { EndpointManagementSensePackagesService } from '../../services/endpoint-management-sense-packages.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { SenseEndpointsManagementState } from '@wcd/domain';
import { Subscription } from 'rxjs';
import { EndpointManagementService } from '../../services/endpoint-management.service';
import {
	MANAGEMENT_FLOWS,
	EndpointManagementProcessesService,
	PROCESS_TYPES,
} from '../../services/endpoint-management-processes.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EndpointManagementPackage } from '../../models/endpoint-management-process.model';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'manage-non-windows-first-party-onboarding',
	templateUrl: './manage-non-windows-first-party-onboarding.component.html',
})
export class ManageNonWindowsFirstPartyOnboardingComponent implements OnDestroy {
	@Input() handleErrorAsIncompleteStatus = false;

	macOsDetectionSettings: TestDetectionSettings;
	loading: boolean = true;
	error: boolean = false;
	onboardingState: SenseEndpointsManagementState = new SenseEndpointsManagementState(null);
	detectionTestSettings: TestDetectionSettings = this.detectionTestSettingsService.all.sense;
	getOnboardingStateSubscription: Subscription;
	packages: Array<EndpointManagementPackage> = this.packagesService.getEnabledPackages(
		MANAGEMENT_FLOWS.onboarding,
		this.endpointManagementProcessesService.all.find(
			p => p.processType === PROCESS_TYPES.nonWindows || p.processType === PROCESS_TYPES.macOS
		)
	);
	currentMethod: EndpointManagementPackage = this.packages && this.packages[0];

	constructor(
		private endpointManagementService: EndpointManagementService,
		private endpointManagementProcessesService: EndpointManagementProcessesService,
		public packagesService: EndpointManagementSensePackagesService,
		private detectionTestSettingsService: DetectionTestSettingsService,
		private dialogsService: DialogsService,
		private appInsights: AppInsightsService,
		private i18nService: I18nService
	) {
		this.macOsDetectionSettings = Object.assign({
			instructionsText: `
				${this.i18nService.get('endpointManagement_nonWindows_macOS_onboarding_firstParty_step2_description')}
				<ol type="a">
					<li class="wcd-margin-small-top">${this.i18nService.get(
						'endpointManagement.nonWindows.macOS.onboarding.firstParty.step2.description.a'
					)}</li>
					<li class="wcd-margin-small-top">${this.i18nService.get(
						'endpointManagement.nonWindows.macOS.onboarding.firstParty.step2.description.b'
					)}</li>
					<li class="wcd-margin-small-top">${this.i18nService.get(
						'endpointManagement.nonWindows.macOS.onboarding.firstParty.step2.description.c'
					)}</li>
				</ol>
				`,
			resultText: this.i18nService.get(
				'endpointManagement.nonWindows.macOS.onboarding.firstParty.step2.description.after'
			),
			command: 'curl -o ~/Downloads/eicar.com.txt https://www.eicar.org/download/eicar.com.txt',
			commandTextRows: 1,
		});

		this.getOnboardingStateSubscription = this.endpointManagementService
			.getSenseOnboardingState()
			.subscribe(
				(state: SenseEndpointsManagementState) => {
					this.loading = false;
					this.setState(state);
				},
				error => {
					this.loading = false;
					if (this.handleErrorAsIncompleteStatus) {
						this.onboardingState.ranTestAlert = false;
						this.onboardingState.machineOnboarded = false;
					} else {
						this.error = true;
					}
					this.appInsights.trackEvent('EndpointOnboarding-Status', {
						ResponseStatus: String(error.status),
						FailureType: 'API',
						RanTestAlert: String(this.onboardingState.ranTestAlert),
						MachineOnboarded: String(this.onboardingState.machineOnboarded),
					});
				}
			);

		this.getDeploymentMethodLabel = this.getDeploymentMethodLabel.bind(this);
	}

	ngOnDestroy() {
		this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
	}

	getDeploymentMethodLabel(managementPackage: EndpointManagementPackage) {
		return this.i18nService.get(
			'endpointManagement.deploymentMethods.' + managementPackage.name + '.title'
		);
	}

	downloadOnboardingPackage() {
		this.endpointManagementService.downloadSensePackage(true, this.currentMethod.id).subscribe(
			(res: string) => {
				this.appInsights.trackEvent('EndpointOnboarding-DownloadPackage', {
					ResponseStatus: String(200),
					RanTestAlert: String(this.onboardingState.ranTestAlert),
					MachineOnboarded: String(this.onboardingState.machineOnboarded),
				});
				window.location.href = res;
			},
			(error: HttpErrorResponse) => {
				this.dialogsService.showError({
					title: this.i18nService.get('endpointManagement.download.package.failed'),
					data: error,
				});
				this.appInsights.trackEvent('EndpointOnboarding-DownloadPackage', {
					ResponseStatus: String(error.status),
					FailureType: 'API',
					RanTestAlert: String(this.onboardingState.ranTestAlert),
					MachineOnboarded: String(this.onboardingState.machineOnboarded),
				});
			}
		);
	}

	downloadInstallationPackage() {
		window.location.href =
			'https://officecdn-microsoft-com.akamaized.net/pr/C1297A47-86C4-4C1F-97FA-950631F94777/MacAutoupdate/wdav.pkg';
	}

	setState(state: SenseEndpointsManagementState) {
		this.onboardingState = state;
		this.appInsights.trackEvent('EndpointOnboarding-Status', {
			RanTestAlert: String(this.onboardingState.ranTestAlert),
			MachineOnboarded: String(this.onboardingState.machineOnboarded),
		});

		if (this.onboardingState.machineOnboarded && this.onboardingState.ranTestAlert) {
			this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
		}
	}
}
