
import { BaselineConfigurationByCategory } from '@wcd/domain';

export class ProfileForCreationFormData {
    id: string;
    name: string;
    description: string;
    isEnabled: boolean;
    operatingSystem: string;
    operatingSystemVersion: string;
    benchmark: string;
    benchmarkVersion: string;
    complianceLevel: string;
    checks: string[];
    selectedSettings: Array<BaselineConfigurationByCategory>
	eTag: string;
	isUpdateFlow: boolean;

	constructor(
		profileForCreationFormData = {
			id: undefined,
			name: '',
            description: '',
			isEnabled: true,
            operatingSystem: '',
            operatingSystemVersion: '',
            benchmark: '',
            benchmarkVersion: '',
            complianceLevel: '',
			checks: [],
			selectedSettings: [],
			eTag: '',
			isUpdateFlow: false,
		}
	) {
        this.id = profileForCreationFormData.id
        this.name = profileForCreationFormData.name
        this.description = profileForCreationFormData.description
        this.isEnabled = profileForCreationFormData.isEnabled
        this.operatingSystem = profileForCreationFormData.operatingSystem
        this.operatingSystemVersion = profileForCreationFormData.operatingSystemVersion
        this.benchmark = profileForCreationFormData.benchmark
        this.benchmarkVersion = profileForCreationFormData.benchmarkVersion
        this.complianceLevel = profileForCreationFormData.complianceLevel
        this.checks = profileForCreationFormData.checks
        this.selectedSettings = profileForCreationFormData.selectedSettings
        this.eTag = profileForCreationFormData.eTag
        this.isUpdateFlow = profileForCreationFormData.isUpdateFlow
	}
}
