var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { panelService } from './panels.service';
import { sccHostService } from '@wcd/scc-interface';
/**
 * Entities that have a side panel in React, and can be opened with just a type + id.
 * Meaning - there's a BE endpoint which returns an entity data based on type + id.
 */
export var EntitiesWithIndependentPanelSupport = ['alert', 'machine', 'process', 'file', 'ip', 'url'];
export var openEntityPanel = function (props, onDismiss, navigationConfig) {
    var PanelComponent = sccHostService.ui.render('EntityPanelWithAppBootstrap@wicd-ine/main', props);
    panelService.openPanel(PanelComponent, onDismiss, navigationConfig);
};
export var openAdIotUpSellPanel = function () {
    var PanelComponent = sccHostService.ui.render('AdIotUpsellSidePanel@wicd-magellan/main');
    panelService.openPanel(PanelComponent);
};
export var openDeviceReportInaccuracyPanel = function (props) {
    var newProps = __assign({}, props, { onDismiss: function () { return panelService.closeAllPanels(); }, isFromDevicePage: true });
    var PanelComponent = sccHostService.ui.render('DeviceReportInaccuracyWithBootstrap@wicd-magellan/main', newProps);
    panelService.openPanel(PanelComponent);
};
export var openMcasUserPanel = function (props, onDismiss, navigationConfig) {
    var PanelComponent = sccHostService.ui.render('McasAccountDetailsPaneContent@wicd-ine/main', {
        entity: props,
    });
    /**
     * The property $$inheritors gets injected to the Object.prototype.constructor when in advanced hunting pages,
     * by a library they use (monaco-kusto - Bridge.NET). The issue happens When trying to open a MCAS user panel, they depend on
     * a library `object-hash` that collides with Bridge.NET and causes an error preventing the component from loading,
     * as a work around, deleting this prop from the constructor for the component object
     */
    // @ts-ignore
    PanelComponent.constructor.$$inheritors && delete PanelComponent.constructor.$$inheritors;
    panelService.openPanel(PanelComponent, onDismiss, navigationConfig);
};
//Can't import type PanelSettings from '@wcd/panels' here, so using 'any'
export var panelSettingsToNavigationConfig = function (panelSettings, upButtonLabel, downButtonLabel, dismissButtonLabel) {
    return panelSettings &&
        ((panelSettings.previous && panelSettings.previous.onClick) ||
            (panelSettings.next && panelSettings.next.onClick))
        ? {
            upButtonOnClick: panelSettings.previous ? panelSettings.previous.onClick : null,
            upButtonDisabled: panelSettings.previous ? panelSettings.previous.isDisabled : null,
            downButtonOnClick: panelSettings.next ? panelSettings.next.onClick : null,
            downButtonDisabled: panelSettings.next ? panelSettings.next.isDisabled : null,
            upButtonLabel: upButtonLabel,
            downButtonLabel: downButtonLabel,
            dismissButtonLabel: dismissButtonLabel,
        }
        : null;
};
