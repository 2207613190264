import {
	Component,
	Input,
	ElementRef,
	ViewEncapsulation,
	AfterViewInit,
	HostListener,
	ViewChild,
	ViewContainerRef,
	ComponentFactoryResolver,
	OnDestroy,
	ComponentRef,
	Type,
	EventEmitter,
} from '@angular/core';
import { TzDateService } from '@wcd/localization';
import { select as d3Select, Selection as D3Selection, BaseType as D3BaseType } from 'd3-selection';
import { scaleTime, scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
import { axisBottom, axisLeft, extent, timeFormat, mouse, bisector, ScaleTime, ScaleLinear } from 'd3';
import { Observable, ReplaySubject } from 'rxjs';

export enum ChartColor {
	Grey = '#605e5c', // Neutral secondary
	Blue = '#0078d4', // Secure score blue
	Red = '#990000', // High severity
	Green = '#00AA00', // Green light - positive
}

export interface YAxisConfiguration {
	min: number;
	max: number;
	ticks: {
		values: number[];
	};
}

export interface LineChartOptions<TComponent> {
	data: DateValuePoint[];
	legend: string;
	valueInPercent?: boolean;
	tooltipComponent?: Type<TComponent>;
	color?: ChartColor;
	height?: number;
	yAxisConfiguration?: YAxisConfiguration;
	yAxisTickValuesWidth?: number; // Used to create enough space to show the Y axis tick values
	id?: string;
	tooltipId: string;
}

export interface LineChartTooltip {
	legend: string;
	percentageDisplay: boolean;
	newDataPoint$: Observable<DateValuePoint>;

	id: string;
	left: number;
	bottom: number;
	opacity: number;

	onNewWidth: EventEmitter<number>;
	onEnterTooltip: EventEmitter<void>;
	onLeaveTooltip: EventEmitter<void>;
}

export interface DateValuePoint {
	value: number;
	date: Date;
	events?: string[];
}

export interface DatePercentagePoint extends DateValuePoint {
	nominator: number;
	denominator: number;
}

@Component({
	selector: 'wcd-line-chart',
	template: `
		<div style="position: relative;">
			<div [id]="divId" class="wcd-line-chart"></div>
			<template #hoveredEventTooltipContainer></template>
		</div>
	`,
	styleUrls: ['./line-chart.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class LineChartComponent<TComponent extends LineChartTooltip> implements AfterViewInit, OnDestroy {
	@ViewChild('hoveredEventTooltipContainer', { read: ViewContainerRef, static: true })
	tooltipPlaceholder: ViewContainerRef;

	private readonly DEFAULT_PADDING = 30;
	private readonly CHART_PADDING_TOP = 10;
	private readonly CHART_PADDING_LEFT = 35;
	private readonly Y_AXIS_PADDING = 5;
	private readonly X_AXIS_PADDING = 7;
	private readonly DATE_PADDING = 5;
	private readonly PIXELS_PER_DATE = 55;
	private readonly MOUSE_LEFT_PADDING = -15;
	private readonly MOUSE_BOTTOM_PADDING = 5;
	private readonly OPACITY_TRANSITION_MS = 150;

	private readonly WIDTH_TO_HEIGHT_RATIO = 2.5;

	private readonly HIDE_TOOLTIP_TIMEOUT = 100;
	private readonly MOUSE_OUT_TIMEOUT = 150;
	private readonly SHOW_TOOLTIP_TIMEOUT = 90;

	private readonly SEARCH_NEIGHBOR_POINTS = 4;
	private readonly DEFAULT_HIGHLIGHT_POINT_RADIUS = 3;
	private readonly HOVER_HIGHLIGHT_POINT_RADIUS = 7;
	private readonly SHADOW_HIGHLIGHT_POINT_OPACITY = 0.6;

	private readonly DEFAULT_CHART_COLOR = ChartColor.Grey;

	private readonly DEFAULT_Y_AXIS_CONFIGURATION: YAxisConfiguration = {
		min: 0,
		max: 100,
		ticks: {
			values: [0, 30, 70, 100],
		},
	};

	private _customTooltipComponentRef: ComponentRef<TComponent>;
	private _tooltipWidth: number;
	private _mouseCoordinates: [number, number];
	private _insideTooltip: boolean;
	private _totalWidth: number;

	private _chartOptions: LineChartOptions<TComponent>;
	private _chartData: DateValuePoint[];
	private _tooltipComponent: Type<TComponent>;
	private _useDefaultTooltip: boolean;
	private _usePercentageValue: boolean;
	private _highestNeighborPointValue: number;
	private _afterViewInit = false;
	private _yAxisConfiguration: YAxisConfiguration;
	private _yAxisTickValuesWidth: number;

	private onNewDataPoint$ = new ReplaySubject<DateValuePoint>();
	private _eventDotsShadow: D3Selection<SVGCircleElement, DateValuePoint, D3BaseType, {}>;
	private _currentShadow: D3Selection<SVGCircleElement, DateValuePoint, D3BaseType, {}>;

	private _chartColor: string;
	private _currentDataPoint: DateValuePoint;

	divId: string;

	@Input() set options(chartOptions: LineChartOptions<TComponent>) {
		if (!chartOptions) {
			return;
		}

		this._chartOptions = chartOptions;
		this._chartData = chartOptions.data;
		this._usePercentageValue = !!chartOptions.valueInPercent;
		this._tooltipComponent = chartOptions.tooltipComponent;
		this._chartColor = chartOptions.color || this.DEFAULT_CHART_COLOR;
		this._yAxisTickValuesWidth = chartOptions.yAxisTickValuesWidth || this.CHART_PADDING_LEFT;
		this._yAxisConfiguration = chartOptions.yAxisConfiguration || this.DEFAULT_Y_AXIS_CONFIGURATION;
		this.divId = chartOptions.id ? chartOptions.id : 'lineChart';
		if (this._afterViewInit) {
			setTimeout(() => this.drawChart());
		}
	}

	private bisectDate = bisector((d: any, x: any) => x - d.date).left;

	private _rootEl: HTMLElement;

	private _rootD3El: D3Selection<HTMLElement, {}, null, undefined>;
	private _svgD3El: D3Selection<D3BaseType, {}, null, undefined>;
	private _focus: D3Selection<SVGGElement, {}, null, undefined>;
	private _tooltip: D3Selection<HTMLDivElement, {}, null, undefined>;

	private _xScale: ScaleTime<number, number>;
	private _yScale: ScaleLinear<number, number>;

	private _chartWidth: number;
	private _chartHeight: number;

	constructor(
		private resolver: ComponentFactoryResolver,
		private tzDateService: TzDateService,
		private elementRef: ElementRef
	) {}

	ngAfterViewInit() {
		const elementId = this.divId ? '#' + this.divId : '#lineChart';
		this._rootEl = this.elementRef.nativeElement.querySelector(elementId);
		this._rootD3El = d3Select(this._rootEl);
		this.tooltipPlaceholder.clear();

		setTimeout(() => {
			this.handleTooltipViewInit();
			this.drawChart();
			this._afterViewInit = true;
		}, 0);
	}

	private handleTooltipViewInit() {
		if (!this._tooltipComponent) {
			this._useDefaultTooltip = true;
			return;
		}
		const factory = this.resolver.resolveComponentFactory(this._tooltipComponent);
		this._customTooltipComponentRef = this.tooltipPlaceholder.createComponent(factory);

		this._customTooltipComponentRef.instance.id = this._chartOptions.tooltipId;
		this._customTooltipComponentRef.instance.legend = this._chartOptions.legend;
		this._customTooltipComponentRef.instance.percentageDisplay = this._usePercentageValue;
		this._customTooltipComponentRef.instance.newDataPoint$ = this.onNewDataPoint$;

		this._customTooltipComponentRef.instance.onEnterTooltip.subscribe(() => {
			this._insideTooltip = true;
			this._focus.style('display', null);
		});
		this._customTooltipComponentRef.instance.onLeaveTooltip.subscribe(() => {
			this._insideTooltip = false;
			this.stopHoverExperience();

			this._mouseCoordinates = null; // to avoid race conditions where receiving new width events after leaving the tooltip
		});

		this._customTooltipComponentRef.instance.onNewWidth.subscribe((newWidth: number) => {
			if (!this._mouseCoordinates) {
				return;
			}
			this._insideTooltip = false; // set to false to avoid race conditions between resize and on enter events
			this._tooltipWidth = newWidth;

			const leftPosition = this.getLeftTooltipPosition(newWidth);
			const bottomPosition = this.getBottomTooltipPosition();
			this.setTooltipOpacityAndLocation(1, leftPosition, bottomPosition);
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize(_) {
		this.drawChart();
	}

	ngOnDestroy() {
		this._customTooltipComponentRef && this._customTooltipComponentRef.destroy();
	}

	private calculateXIndexesAccordingToDataLength(): number[] {
		const dataLength = this._chartData.length;
		const ticksModulo = Math.floor(dataLength / (this._chartWidth / this.PIXELS_PER_DATE));

		const thirdArray = Math.floor(dataLength / 3);
		const quarterArray = Math.floor(dataLength / 4);
		const fifthArray = Math.floor(dataLength / 5);
		const twoThirds = Math.floor((dataLength * 2) / 3);

		let sections = 0; // the sections is responsible for dividing the x axis into relatively equals sections
		if (ticksModulo > twoThirds) {
			sections = 2;
		} else if (ticksModulo > thirdArray) {
			sections = 3;
		} else if (ticksModulo > quarterArray) {
			sections = 4;
		} else if (ticksModulo > fifthArray) {
			sections = 5;
		} else {
			sections = 6;
		}

		const indexes = [];
		const fraction = (dataLength - 1) / (sections - 1);
		for (let i = 0; i < sections; i++) {
			indexes.push(Math.floor(fraction * i));
		}
		return indexes;
	}

	private stopHoverExperience() {
		this.hideTooltip();
		this._focus.style('display', 'none');
		if (this._currentShadow) {
			this._currentShadow.style('opacity', 0);
			this._currentShadow = null;
		}
	}

	private drawChart() {
		if (!this._chartData) {
			return;
		}
		this._rootD3El.html('');
		this._totalWidth = this._rootEl.clientWidth;

		const height = this._chartOptions.height || this._totalWidth / this.WIDTH_TO_HEIGHT_RATIO;

		this._chartHeight = height - this.DEFAULT_PADDING - this.DATE_PADDING;
		this._chartWidth = this._totalWidth - (this._yAxisTickValuesWidth + this.DEFAULT_PADDING);

		this._svgD3El = this._rootD3El
			.append('svg')
			.attr('width', this._totalWidth)
			.attr('height', height)
			.append('g')
			.attr('transform', `translate(${this._yAxisTickValuesWidth},${this.CHART_PADDING_TOP})`);

		this._xScale = scaleTime()
			.range([0, this._chartWidth])
			.domain(extent(this._chartData, d => d.date));

		this._yScale = scaleLinear()
			.range([this._chartHeight, 0])
			.domain([this._yAxisConfiguration.min, this._yAxisConfiguration.max]);

		this.setHoverBehaviorInsideGraph();
		this.setChartAxis(height);
		this.setHighlightedPointsEvent();

		const valueLine = line()
			.x(d => this._xScale(d['date']))
			.y(d => this._yScale(+d['value']));

		this._svgD3El
			.append('path')
			.datum(this._chartData)
			.attr('d', <any>valueLine)
			.attr('class', 'chart-line')
			.style('stroke', this._chartColor);

		this._tooltip = this._rootD3El
			.append('div')
			.attr('class', 'hover-tooltip')
			.style('opacity', 0);

		this._svgD3El
			.append('rect')
			.attr('transform', `translate(0,0)`)
			.attr('class', 'overlay')
			.attr('width', this._chartWidth)
			.attr('height', this._chartHeight)
			.on('mouseout', () =>
				// to wait for event when hovered into the tooltip
				setTimeout(() => {
					if (!this._insideTooltip) {
						// left the component (not inside the event tooltip)
						this.stopHoverExperience();
					}
				}, this.MOUSE_OUT_TIMEOUT)
			)
			.on('mousemove', (_, j, element) => {
				if (this._insideTooltip) {
					return;
				}
				this._mouseCoordinates = mouse(element[j]);
				const pointIndex = this.getHoveredPointIndex(this._mouseCoordinates[0]);
				const hoveredDataPoint = this._chartData[pointIndex];

				this.setHighestNeighborPointValue(pointIndex);
				setTimeout(() => {
					if (!this._mouseCoordinates) {
						// To avoid race condition where hovered in event and left the area before timeout
						return;
					}
					this.onMouseMoveInEventRectangleHandler(hoveredDataPoint);
				}, this.SHOW_TOOLTIP_TIMEOUT);
			});
	}

	private setHighlightedPointsEvent() {
		const highlightedPoints = this._chartData.filter(p => Array.isArray(p.events) && p.events.length > 0);
		this.addCirclesToChart(highlightedPoints, this.DEFAULT_HIGHLIGHT_POINT_RADIUS, 'static-events-dot');
		this.addCirclesToChart(highlightedPoints, this.HOVER_HIGHLIGHT_POINT_RADIUS, 'hovered-event-dot');
		this._eventDotsShadow = this._svgD3El.selectAll('.hovered-event-dot');
	}

	private addCirclesToChart(highlightedPoints: DateValuePoint[], radius: number, className: string) {
		this._svgD3El
			.selectAll(className)
			.data(highlightedPoints)
			.enter()
			.append('circle')
			.attr('class', className)
			.attr('r', radius)
			.attr('cx', d => this._xScale(d.date))
			.attr('cy', d => this._yScale(d.value))
			.style('fill', this._chartColor);
	}

	private setHighestNeighborPointValue(pointIndex: number): void {
		let point = this._chartData[pointIndex];
		for (let i = 0; i < this.SEARCH_NEIGHBOR_POINTS; i++) {
			let tmpIndex = pointIndex + i;
			if (tmpIndex < this._chartData.length && this._chartData[tmpIndex].value > point.value) {
				point = this._chartData[tmpIndex];
			}
			tmpIndex = pointIndex - i;
			if (tmpIndex >= 0 && this._chartData[tmpIndex].value > point.value) {
				point = this._chartData[tmpIndex];
			}
		}
		this._highestNeighborPointValue = point.value;
	}

	private hideTooltip() {
		if (this._useDefaultTooltip) {
			this.setDefaultTooltipOpacity(0);
		} else {
			this.setTooltipOpacityAndLocation(0, 0, 0); // hide the component
		}
	}

	private getHoveredPointIndex(mouseXCoordinate: number): number {
		const hoveredDate = this._xScale.invert(mouseXCoordinate);
		const dateIndex = this.bisectDate(this._chartData, hoveredDate);

		const neighborIndex = dateIndex - 1 >= 0 ? dateIndex - 1 : 0;
		// d0 & d1 are the points closest to the hovered date -> the closest is set into d
		const d0 = this._chartData[dateIndex];
		const d1 = this._chartData[neighborIndex];

		return +hoveredDate - +d0.date > +d1.date - +hoveredDate ? neighborIndex : dateIndex;
	}

	private setTooltipOpacityAndLocation(opacity: number, left: number, bottom: number) {
		this._customTooltipComponentRef.instance.opacity = opacity;
		this._customTooltipComponentRef.instance.left = left;
		this._customTooltipComponentRef.instance.bottom = bottom;

		this._customTooltipComponentRef.changeDetectorRef.markForCheck();
	}

	private setDefaultTooltipOpacity(opacity: number) {
		this._tooltip
			.transition()
			.duration(this.OPACITY_TRANSITION_MS)
			.style('opacity', opacity);
	}

	private onMouseMoveInEventRectangleHandler(dataPoint: DateValuePoint) {
		this.showHoveredFocusLine(dataPoint);

		const tooltipWidth = this._useDefaultTooltip
			? this._tooltip.node().getBoundingClientRect().width
			: this._tooltipWidth;

		const leftPosition = this.getLeftTooltipPosition(tooltipWidth);
		const bottomPosition = this.getBottomTooltipPosition();

		if (this._useDefaultTooltip) {
			this._rootD3El
				.selectAll('.hover-tooltip')
				.html(() => this.renderDefaultTooltipHtml(dataPoint))
				.style('left', leftPosition + 'px')
				.style('bottom', bottomPosition + 'px');

			this.setDefaultTooltipOpacity(1);
		} else {
			if (!this._currentDataPoint || dataPoint !== this._currentDataPoint) {
				this._currentDataPoint = dataPoint;
				this.onNewDataPoint$.next(dataPoint);
			}
			this.handlePointShadow(dataPoint);
			this.setTooltipOpacityAndLocation(1, leftPosition, bottomPosition);
		}
	}

	private handlePointShadow(dataPoint: DateValuePoint) {
		if (!this._currentShadow || this._currentShadow.data()[0].date !== dataPoint.date) {
			// either the first hovered point or the point is on an other date (new point)
			if (this._currentShadow) {
				this._currentShadow.style('opacity', 0); // removing the highlighted previous point
			}

			const newShadow = this._eventDotsShadow.filter(x => x.date === dataPoint.date);
			if (newShadow.empty()) {
				this._currentShadow = null; // For when hovering over points without events
			} else {
				this._currentShadow = newShadow;
				this._currentShadow.style('opacity', this.SHADOW_HIGHLIGHT_POINT_OPACITY);
			}
		}
	}

	showHoveredFocusLine(d: DateValuePoint) {
		const hoverLineHeightBelow = this._chartHeight - this._yScale(d.value);

		this._focus.attr('transform', `translate(${this._xScale(d.date)},${this._yScale(d.value)})`);

		this._focus
			.select('.x-hover-line')
			.attr('y2', hoverLineHeightBelow)
			.attr('y1', -this._chartHeight + hoverLineHeightBelow);

		this._focus.style('display', null);
	}

	private getBottomTooltipPosition(): number {
		return (
			this._rootEl.clientHeight -
			this._yScale(this._highestNeighborPointValue) +
			this.MOUSE_BOTTOM_PADDING
		);
	}

	private getLeftTooltipPosition(tooltipWidth: number): number {
		if (this._totalWidth < tooltipWidth) {
			// On narrow screen on large zoom start from parent 0 left
			return 0;
		}
		const xCoordinate = this._mouseCoordinates[0];
		const leftPosition =
			xCoordinate + this._yAxisTickValuesWidth + this.Y_AXIS_PADDING + this.MOUSE_LEFT_PADDING;

		const tooltipOverflow =
			this._totalWidth - xCoordinate - this._yAxisTickValuesWidth - this.Y_AXIS_PADDING - tooltipWidth <
			0;

		return tooltipOverflow ? this._totalWidth + this.MOUSE_LEFT_PADDING - tooltipWidth : leftPosition;
	}

	private setChartAxis(totalHeight: number) {
		const displayIndexes = this.calculateXIndexesAccordingToDataLength();

		const xAxis = axisBottom(this._xScale)
			.tickFormat(timeFormat('%m/%d'))
			.tickValues(this._chartData.map(d => d.date).filter((d, i) => displayIndexes.includes(i)));

		const yAxis = axisLeft(this._yScale)
			.tickValues(this._yAxisConfiguration.ticks.values)
			.tickSizeOuter(0)
			.tickPadding(this.Y_AXIS_PADDING)
			.tickSize(-this._chartWidth)
			.tickFormat(yValue => (this._usePercentageValue ? `${yValue}%` : `${yValue}`));

		this._svgD3El
			.append('g')
			.attr('class', 'y-axis')
			.call(yAxis)
			.call(g => g.select('.domain').remove());

		this._svgD3El
			.append('g')
			.attr('class', 'x-axis')
			.attr('transform', `translate(0,${totalHeight - this.DEFAULT_PADDING - this.DATE_PADDING})`)
			.call(xAxis);

		// Setting the padding for all x axis tick values
		this._svgD3El.selectAll('.x-axis .tick text').attr('transform', `translate(0, ${this.DATE_PADDING})`);

		// Setting the padding for the first and last x axis tick values
		this._svgD3El
			.selectAll('.x-axis .tick:first-of-type text')
			.attr('transform', `translate(${-this.X_AXIS_PADDING}, ${this.DATE_PADDING})`);

		this._svgD3El
			.selectAll('.x-axis .tick:last-of-type text')
			.attr('transform', `translate(${this.X_AXIS_PADDING}, ${this.DATE_PADDING})`);
	}

	private setHoverBehaviorInsideGraph() {
		this._focus = this._svgD3El
			.append('g')
			.attr('class', 'focus')
			.style('display', 'none');

		this._focus
			.append('line')
			.attr('class', 'x-hover-line hover-line')
			.attr('y1', 0)
			.attr('y2', -this._chartHeight);

		this._focus
			.append('circle')
			.attr('class', 'chart-hover-dot')
			.attr('r', 2)
			.style('fill', this._chartColor);
	}

	private renderDefaultTooltipHtml(hoveredPoint: DateValuePoint): string {
		return `<table class="c3-tooltip">
					<tbody>
						<tr>
							<th colspan="2">${this.tzDateService.format(hoveredPoint.date, 'MM/dd')}</th>
						</tr>
						<tr>
							<td class="name">
								<span class="tooltip-legend"></span>
								${this._chartOptions.legend}
							</td>
							<td class="value">${hoveredPoint.value}</td>
						</tr>
					</tbody>
				</table>`;
	}
}
