<!-- tslint:disable:template-accessibility-label-for -->
<div class="wcd-full-height large-form">
	<form #notificationRuleForm="ngForm" (submit)="saveRule()" class="wcd-full-height wcd-flex-vertical" data-track-component="NotificationRuleForm">
		<header class="wcd-flex-none wcd-padding-all">
			<div class="wcd-flex-center-vertical wcd-flex-horizontal">
				<div class="page-header-icon wcd-flex-none">
					 <wcd-shared-icon class="round-icon color-box-gray-200" iconName="email"> </wcd-shared-icon >
				</div>
				<div class="wcd-flex-1">
					<h3 class="side-panel-title">{{createRuleMode ? ('notificationRules.newRule' | i18n) :
						'notificationRules.editRule' | i18n:{ruleName: rule.name} }}</h3>
				</div>
			</div>
			<div class="wcd-margin-top">
				<tabs tabsKind="large"
					  [tabsData]="tabs"
					  [currentTab]="currentTab.id"
					  (select)="setCurrentTab($event.tab)"></tabs>
			</div>
		</header>
		<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
			<div *ngIf="currentTab.id === 'details'">
				<section class="form-section">
					<div *ngIf="editedRule.readonly || !isUserAllowedActions" class="wcd-margin-bottom">
						 <wcd-shared-icon [iconName]="'info'" class="color-text-primary ms-icon-top-2-fix"> </wcd-shared-icon >
						{{'notificationRules.readonly' | i18n}}
					</div>
					<div class="form-group">
						<label for="notification-rule-name">{{'notificationRules.ruleName.title' | i18n}}</label>
						<input type="text"
							   id="notification-rule-name"
							   [(ngModel)]="editedRule.name"
							   name="rule-name"
							   class="form-control"
							   [disabled]="editedRule.readonly || !isUserAllowedActions"
							   maxlength="100"
							   [focusOn]="!focusOnRecipient"
							   required/>
					</div>
					<div class="form-group">
						<wcd-checklist
							[values]="notificationFormats"
							[isDisabled]="editedRule.readonly || !isUserAllowedActions"
							[selectById]="false"
							[formatLabel]="getNotificationFormatLabel"
							helpProperty="helpKey"
							name="notification-rule-format"
							[(ngModel)]="editedRule.formatFlavor">
						</wcd-checklist>
					</div>
					<div class="form-group" *ngIf="isDeviceScopeEnabled">
						<label>{{'notificationRules.machineGroups.title' | i18n}}
							<wcd-help [text]="'notificationRules.machineGroups.description' | i18n"></wcd-help>
						</label>
						<div class="wcd-margin-small-bottom">
							<ng-container *ngIf="loadingMachineGroups; else machineGroupsFields">
								<div>
									<i class="loader-icon wcd-margin-right"></i>
									{{'notificationRules.machineGroups.loading' | i18n}}
								</div>
							</ng-container>
							<ng-template #machineGroupsFields>
								<ng-container *ngIf="allowSpecificMachineGroups; else allMachineGroupsLabel">
									<ng-container *ngIf="allowAllMachineGroups; else forceSpecificMachineGroupsSelect">
										<wcd-radiolist [values]="selectableMachineGroupScopes"
													[isDisabled]="editedRule.readonly || !isUserAllowedActions"
													name="notification-rule-machine-group-scope"
													(ngModelChange)="onMachineGroupScopeChange($event)"
													[(ngModel)]="currentMachineGroupScope"
													[ariaLabel]="'notificationRules.machineGroups.title' | i18n"></wcd-radiolist>
										<ng-container *ngIf="currentMachineGroupScope?.id === 'specific'">
											<ng-container *ngTemplateOutlet="specificMachineGroups"></ng-container>
										</ng-container>
									</ng-container>
									<ng-template #forceSpecificMachineGroupsSelect>
										<ng-container *ngTemplateOutlet="specificMachineGroups"></ng-container>
									</ng-template>
								</ng-container>
								<ng-template #allMachineGroupsLabel>
									{{'notificationRules.machineGroups.all' | i18n}}
								</ng-template>
								<ng-template #specificMachineGroups>
									<wcd-checklist-dropdown
										[id]="machineGroupsFieldId"
										[buttonText]="getLabelText(machineGroupsFieldId, 'machineGroups')"
										name="notification-rule-machine-groups"
										[buttonTooltip]="getLabelText(machineGroupsFieldId, 'machineGroups')"
										[isFullWidth]="true"
										[isDisabled]="editedRule.readonly || !isUserAllowedActions"
										[selectById]="false"
										[ngModel]="currentMachineGroups"
										(ngModelChange)="onMachineGroupsChange($event)"
										[values]="availableMachineGroups"></wcd-checklist-dropdown>
								</ng-template>
							</ng-template>
						</div>
					</div>
					<div>
						<label>{{'notificationRules.conditions.alertSeverity.title' | i18n}}
							<wcd-help [text]="'notificationRules.conditions.alertSeverity.description' | i18n"></wcd-help>
						</label>
						<ul class="form-list">
							<li *ngFor="let condition of editedRule.conditions; let conditionIndex = index">
								<div class="wcd-margin-small-bottom">
									<wcd-checklist-dropdown
										[buttonText]="getLabelText(condition.type.name, 'conditions.' + condition.type.typeName)"
										[name]="'condition-values-' + conditionIndex"
										[buttonTooltip]="getLabelText(condition.type.name, 'conditions.' + condition.type.typeName)"
										[isFullWidth]="true"
										[isDisabled]="editedRule.readonly || !isUserAllowedActions"
										[selectById]="false"
										[(ngModel)]="condition.values"
										(ngModelChange)="setLabelText(condition.type.name, $event, 'conditions.' + condition.type.typeName)"
										[values]="alertSeverities"
										[ariaLabel]="'notificationRules.conditions.alertSeverity.title' | i18n"
										[ariaRole]="'combobox'"></wcd-checklist-dropdown>
								</div>
							</li>
						</ul>
					</div>
				</section>
			</div>
			<div *ngIf="currentTab.id === 'recipients'">
				<section class="form-section">
					<div class="form-group">
						<label for="notification-rule-email">{{'notificationRules.ruleEmails.title' | i18n}}</label>
						<div class="wcd-flex-center-vertical">
							<input type="text"
								   id="notification-rule-email"
								   class="form-control with-action-after wcd-width-medium wcd-margin-small-right"
								   input-control
								   list-input
								   autofocus
								   name="email"
								   #email="ngModel"
								   #emailEl
								   validateRegExp="emailWithSpecialChars"
								   [disabled]="editedRule.readonly || !isUserAllowedActions"
								   [(ngModel)]="currentEmail"
								   [focusOn]="focusOnRecipient"
								   aria-describedby="notification-rule-email-error-message"/>
							<button type="button"
									[disabled]="editedRule.readonly || !isUserAllowedActions || !currentEmail || !email.valid"
									class="btn-link"
									data-track-id="AddEmail"
									data-track-type="Button"
									(click)="add(currentEmail)">
								 <wcd-shared-icon iconName="Add" class="small-icon" [ariaLabel]="''"> </wcd-shared-icon >
								{{'notificationRules.ruleEmails.add' | i18n}}
							</button>
						</div>
						<div id="notification-rule-email-error-message">
							<div class="error-message" *ngIf="currentEmail && !email.valid">
								{{'notificationRules.ruleEmails.error' | i18n}}
							</div>
						</div>
						<ul class="inline-list unstyled wcd-margin-top">
							<li *ngFor="let recipient of editedRule.recipients; let index = index">
								<div class="wcd-flex-horizontal wcd-flex-center-vertical">
									<div class="wcd-width-medium wcd-margin-small-right">
										<wcd-shared-icon [iconName]="'email'" class="small-icon wcd-margin-small-right"> </wcd-shared-icon >
										{{recipient}}
									</div>
									<button type="button"
											class="btn-link"
											data-track-id="RemoveEmail"
											data-track-type="Button"
											[attr.aria-label]="('notificationRules.ruleEmails.remove.title' | i18n) +' '+ recipient"
											[disabled]="editedRule.readonly || !isUserAllowedActions"
											(click)="removeEmail(recipient); notificationRuleForm.form.markAsDirty()"
										 wcdTooltip="{{'notificationRules.ruleEmails.remove.description' | i18n}}">
										 <wcd-shared-icon [iconName]="'close'" class="small-icon" [ariaLabel]="''"> </wcd-shared-icon >
										{{'notificationRules.ruleEmails.remove.title' | i18n}}
									</button>
								</div>
							</li>
						</ul>
						<fab-primary-button contentClass="wcd-margin-vertical"
								[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
								[disabled]="isSendTestEmailDisabled"
								data-track-id="SendTestNotificationRule"
								data-track-type="Button"
								type="button"
								(onClick)="sendTestEmail()">
								<span role="alert">
									<i *ngIf="sendTestStatus === SendStatuses.pending" class="loader-icon" [attr.aria-label]="'notificationRules.sendTestEmail.sending' | i18n" ></i>
								</span>
							{{'notificationRules.sendTestEmail.' + (sendTestStatus === SendStatuses.pending ? 'sending'
							: 'send') |
							i18n}}
						</fab-primary-button>
						<div
							role="alert"
							*ngIf="sendTestStatus !== SendStatuses.notStarted && sendTestStatus !== SendStatuses.pending"
							[class.error-message]="sendTestStatus === SendStatuses.error">
							 <wcd-shared-icon iconName="success"
								  [hidden]="sendTestStatus !== SendStatuses.success"
								  class="color-text-success-light ms-icon-top-2-fix"> </wcd-shared-icon >
							{{'notificationRules.sendTestEmail.' + SendStatuses[sendTestStatus] | i18n}}
						</div>
					</div>
				</section>
			</div>
		</div>
		<div class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal"
			 *ngIf="!editedRule.readonly && isUserAllowedActions">
			<div class="wcd-flex-1 wcd-margin-right">
				<fab-default-button contentClass="btn-secondary wcd-width-xs-small wcd-margin-small-right"
						(onClick)="setCurrentTab()">
					{{ 'buttons.' + ( currentTab === tabs[0] ? 'next' : 'previous') | i18n}}
				</fab-default-button>
				<fab-primary-button
						[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
						[disabled]="notificationRuleForm.form.pristine || !notificationRuleForm.form.valid || !isValid"
						data-track-id="SaveNotificationRule"
						data-track-type="Button"
						type="submit">
					<i class="loader-icon" [hidden]="!isSaving"></i>
					{{ 'buttons.' + (isSaving ? 'saving' : 'save') | i18n }}
				</fab-primary-button>
			</div>
			<div class="wcd-flex-none">
				<fab-default-button contentClass="btn-secondary" (onClick)="cancel.emit()">{{'buttons.cancel' | i18n}}</fab-default-button>
			</div>
		</div>
	</form>
</div>
