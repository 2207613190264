var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone } from '@angular/core';
import { Store } from '../../data/models/store.base';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { TagModel } from '../models/tags.model';
import { TagsBackendService } from './tags.backend.service';
import { I18nService } from '@wcd/i18n';
var TagsStore = /** @class */ (function (_super) {
    __extends(TagsStore, _super);
    function TagsStore(backendService, ngZone, dialogsService, i18nService) {
        var _this = _super.call(this, backendService, ngZone, TagModel, dialogsService, i18nService, {
            itemNameSingular: 'Tag',
            itemNamePlural: 'Tags',
            iconName: 'tag',
            showSnackbarOnUpdate: false,
        }) || this;
        _this.backendService = backendService;
        return _this;
    }
    TagsStore.prototype.addTagsToObjects = function (tag, objectType, objectsIds, options) {
        return this.backendService.addTagsToObjects(String(tag.id), objectType, objectsIds, options);
    };
    TagsStore.prototype.removeTagsFromObjects = function (tag, objectType, objectsIds, options) {
        return this.backendService.removeTagsFromObjects(String(tag.id), objectType, objectsIds, options);
    };
    return TagsStore;
}(Store));
export { TagsStore };
