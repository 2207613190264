
		<div
			*ngIf="
				config?.search &&
				(config?.search?.alwaysDisplay || allValues?.count > allValues?.values?.length)
			"
			class="wcd-margin-small-vertical"
		>
			<wcd-search
				[placeholder]="config.search.placeholder || i18nService.strings.filters_search_title"
				[settings]="config.search.settings"
				[fullWidth]="true"
				(select)="addSearchValue($event)"
				[searchFunction]="config.search.method"
				[ariaLabel]="fieldName"
				[closeResultsWhenSearchCleared]="true"
			></wcd-search>
		</div>
		<wcd-filter-values-checklist
			(filterValuesChange)="filterValuesChange.emit($event)"
			[fieldId]="fieldId"
			[data]="allValues"
			[config]="config"
			[selectedValues]="selectedValues"
		></wcd-filter-values-checklist>
	