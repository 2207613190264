var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { PanelContainer } from '@wcd/panels';
import { MessageBarType } from 'office-ui-fabric-react';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
var MachineGroupsFilterPanelComponent = /** @class */ (function (_super) {
    __extends(MachineGroupsFilterPanelComponent, _super);
    function MachineGroupsFilterPanelComponent(router, i18nService) {
        var _this = _super.call(this, router) || this;
        _this._messageBarType = MessageBarType;
        _this._messageBarStyles = {
            root: {
                background: '#FDF1D9',
            },
        };
        _this.onFiltersChange = new EventEmitter();
        _this._filterId = 'machineGroups';
        _this._wcdFiltersData = {};
        _this._wcdFiltersSelectedValues = new Map();
        _this._wcdFiltersFields = [
            {
                id: _this._filterId,
                name: '',
                requiresData: false,
                component: {
                    type: FilterValuesChecklistComponent,
                },
            },
        ];
        _this._wcdFiltersFields[0].name = i18nService.strings.tvm_machineGroupsFilter_panel_body_filterName;
        return _this;
    }
    MachineGroupsFilterPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this._subscription = this.machineGroupsFilter$.subscribe(function (machineGroupsFilterData) {
            if (!machineGroupsFilterData)
                return;
            var filtersAlternativeDataValues = [];
            var selectedValues = [];
            machineGroupsFilterData.machineGroups.forEach(function (data) {
                filtersAlternativeDataValues.push({
                    value: data.groupId,
                    name: data.groupName,
                });
                if (data.isSelected) {
                    selectedValues.push(data.groupId);
                }
            });
            if (selectedValues.length) {
                _this._wcdFiltersSelectedValues.set(_this._filterId, selectedValues);
            }
            _this._wcdFiltersData = {};
            _this._wcdFiltersData[_this._filterId] = {
                values: filtersAlternativeDataValues,
            };
        });
    };
    MachineGroupsFilterPanelComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this._subscription && this._subscription.unsubscribe();
    };
    MachineGroupsFilterPanelComponent.prototype.onWcdFiltersChange = function (e) {
        var selectedGroups = e.selection[this._filterId] ? e.selection[this._filterId].slice() : [];
        this.onFiltersChange.emit(selectedGroups);
    };
    return MachineGroupsFilterPanelComponent;
}(PanelContainer));
export { MachineGroupsFilterPanelComponent };
