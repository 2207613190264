import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { map, take } from 'rxjs/operators';
import {
	CategorySecureScore,
	CategorySecureScoreApiCall,
	TotalSecureScore,
	TotalSecureScoreApiCall,
	RecommendationException,
} from '@wcd/domain';
import { StretchedDonutBarItem, LineChartOptions, ChartColor } from '@wcd/charts';
import { TvmTextsService, TextToken } from '../../../services/tvm-texts.service';
import { TvmColorService, TvmColor } from '../../../services/tvm-color.service';
import { FabricIconNames } from '@wcd/scc-common';
import { zip } from 'rxjs';
import { LocaleConfigService, TzDateService } from '@wcd/localization';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';
import {
	TvmChartTooltipComponent,
	SECURE_SCORE_TOOLTIP_ID,
} from '../../../components/tooltips/events/tvm-chart-tooltip.component';
import { TvmScoreTrendOptions } from '../../../components/tvm-score-trend/tvm-score-trend.component';
import {
	TvmTopChangeEventsService,
	ChangeEventDomain,
} from '../../../services/tvm-top-change-events.service';

declare const moment: typeof import('moment');

const MAX_DAYS_OF_HISTORY = 30;

@Component({
	selector: 'secure-configuration-score-widget',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './secure-configuration-score.widget.html',
})
export class TvmSecureConfigurationScoreWidget extends ReportWidgetComponent<StretchedDonutBarItemsScoreMap> {
	currentDate: Date;
	widgetTooltip: string;
	widgetTooltipAriaLabel: string;
	isImpactedByExceptions: boolean;
	userHasSecureScoreAccess: boolean = false;
	newChartOptions: LineChartOptions<TvmChartTooltipComponent>;
	trendOptions: TvmScoreTrendOptions;

	private isMgSelected: boolean;
	private _osTitle: string;

	constructor(
		reportsService: ReportsService,
		private tvmTextsService: TvmTextsService,
		private router: Router,
		private topChangeEventService: TvmTopChangeEventsService,
		private paris: Paris,
		private tvmColorService: TvmColorService,
		private i18nService: I18nService,
		private tzDateService: TzDateService,
		public localeConfigService: LocaleConfigService,
		private machineGroupsFilterService: TvmMachineGroupsFilterService
	) {
		super(reportsService);
		this.currentDate = new Date();
		this.widgetTooltip = tvmTextsService.getText(TextToken.ConfigurationScoreWidgetInfo);
		this.widgetTooltipAriaLabel = this.i18nService.get('tvm.secureScoreWidget.info.AriaLabel');
		this._osTitle = this.i18nService.get('tvm.common.operatingSystem');
	}

	get widgetConfig() {
		return {
			id: 'secureScoreWidget',
			name: this.i18nService.get('tvm.secureScoreWidget.title'),
			noDataMessage: () => this.tvmTextsService.getText(TextToken.ScoreWidgetNoData, this.isMgSelected),
			noDataIcon: FabricIconNames.Trackers,
			NoIconLeftAlign: true,
			loadData: () => {
				const mg$ = this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1));
				const categories$ = this.paris.apiCall<CategorySecureScore[]>(CategorySecureScoreApiCall, {}); //TODO: bad practice ahead - the empty params is due to the fact that Paris fires the "parseQuery" CB only if arg is provided
				const totalScore$ = this.paris.apiCall<TotalSecureScore>(TotalSecureScoreApiCall, {}); //TODO: bad practice ahead - the empty params is due to the fact that Paris fires the "parseQuery" CB only if arg is provided
				const exceptions$ = this.paris
					.getRepository(RecommendationException)
					.query({ where: { status: ['Active'] } });
				const scaEvents$ = this.topChangeEventService.getChangeEvents$(ChangeEventDomain.SCA);

				return zip(mg$, categories$, totalScore$, exceptions$, scaEvents$).pipe(
					map(([mg, categories, totalScore, exceptions, scaEvents]) => {
						this.isMgSelected = mg.isFiltering;
						this.isImpactedByExceptions = exceptions.items.length > 0;
						if (
							!Array.isArray(categories) ||
							categories.length === 0 ||
							(totalScore.secureScore === 0 && totalScore.maxSecureScore === 0)
						) {
							return null; // To show the no data message
						}

						this.setNewChartValues(totalScore, scaEvents || new Map());
						return this.getStretchedDonutChartItems(categories, totalScore);
					})
				);
			},
			minHeight: '400px',
		};
	}

	getStretchedDonutChartItems(
		categories: CategorySecureScore[],
		totalScore: TotalSecureScore
	): StretchedDonutBarItemsScoreMap {
		return {
			donutItems: categories.map(
				categoryScore =>
					categoryScore && {
						id: categoryScore.id,
						title: categoryScore.category === 'OS' ? this._osTitle : categoryScore.category,
						valueColor: this.tvmColorService.colorsMap.get(TvmColor.Blue),
						totalColor: this.tvmColorService.colorsMap.get(TvmColor.GraphSeriesLight),
						value: categoryScore.secureScore,
						total: categoryScore.maxSecureScore,
						valuePrefix: this.i18nService.get('tvm_secureScoreWidget_category_bar_completed'),
						restPrefix: this.i18nService.get('tvm_secureScoreWidget_category_bar_uncompleted'),
						width: '100%',
						clickable: true,
						showValueAsPercentage: true,
					}
			),
			totalScore: totalScore,
		};
	}

	getScorePercentage(score: TotalSecureScore) {
		return Math.round((score.secureScore / score.maxSecureScore) * 100);
	}

	openSecureRecommendations(categoryTitle: string): void {
		if (categoryTitle === this._osTitle) {
			categoryTitle = 'OS';
		}
		this.router.navigate(['/security-recommendations'], { queryParams: { search: categoryTitle } });
	}

	private setNewChartValues(data: TotalSecureScore, scaEvents: Map<string, string[]>): void {
		if (!data) {
			this.trendOptions = null;
			this.newChartOptions = null;
			return;
		}

		const percentages = data.secureScoreHistoryPercentage.slice(0, MAX_DAYS_OF_HISTORY);
		const nominators = data.secureScoreHistory.slice(0, MAX_DAYS_OF_HISTORY);
		const denominators = data.maxSecureScoreHistory.slice(0, MAX_DAYS_OF_HISTORY);

		const dates = Array.from(Array(MAX_DAYS_OF_HISTORY)).map((_, i) =>
			moment()
				.utc()
				.subtract({ days: i })
				.startOf('day')
				.toDate()
		);

		const percentageScoreHistory = dates.map((date, i) => ({
			value: Math.floor(percentages[i]),
			nominator: Math.floor(nominators[i]),
			denominator: Math.floor(denominators[i]),
			date: date,
			events: scaEvents.get(this.tzDateService.format(date, 'MM/dd')),
		}));

		this.newChartOptions = {
			data: percentageScoreHistory,
			legend: this.i18nService.get('tvm.secureScoreWidget.secureScoreOverTimeLegend'),
			tooltipComponent: TvmChartTooltipComponent,
			valueInPercent: true,
			color: ChartColor.Blue,
			tooltipId: SECURE_SCORE_TOOLTIP_ID,
		};

		this.trendOptions = {
			title: this.i18nService.get('tvm.secureScoreWidget.secureScoreOverTime'),
			scoreHistory: percentages,
			iconColor: TvmColor.Blue,
		};
	}
}

interface StretchedDonutBarItemsScoreMap {
	donutItems: StretchedDonutBarItem[];
	totalScore: TotalSecureScore;
}
