import { Entity } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { RecommendationBase } from '../common/recommendation/recommendation.entity.base';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Security Recommendation',
	pluralName: 'Security Recommendations',
	endpoint: 'recommendations',
	readonly: true,
	parseItemQuery: id => `recommendations/recommendation?recommendationId=${id}`,
})
export class SecurityRecommendation extends RecommendationBase {}
