import { Pipe, PipeTransform } from '@angular/core';
import { padStart, padEnd, pad } from 'lodash-es';

@Pipe({ name: 'pad' })
export class PadPipe implements PipeTransform {
	transform(value: string, length: number, padWith?: string, direction?: 'start' | 'end'): string {
		const padFunc: (value: string, length: number, chars: string) => string =
			direction === 'start' ? padStart : direction === 'end' ? padEnd : pad;
		return padFunc(value, length, padWith);
	}
}
