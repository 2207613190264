import { NgModule } from '@angular/core';
import { PieChartComponent } from './pie-chart.component';
import { I18nModule } from '@wcd/i18n';

@NgModule({
	imports: [I18nModule],
	declarations: [PieChartComponent],
	exports: [PieChartComponent],
})
export class PieChartModule {}
