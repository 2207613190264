import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { HuntingCustomActionEntity } from './hunting-custom-action-result.value-object';
import { HuntingRuleEntityType } from '../hunting-rule-impacted-entity.value-object';

@ApiCall({
	name: 'Get names of machines by machine IDs',
	endpoint: 'machines/profiles',
	baseUrl: config => config.data.serviceUrls.threatIntel,
	method: 'POST',
	fixedData: { idType: 'SenseMachineId' },
	parseData: rawData =>
		rawData &&
		rawData.map(machine => ({
			EntityId: machine.SenseMachineId,
			EntityName: machine.ComputerDnsName,
			EntityType: HuntingRuleEntityType.Machine,
		})),
	type: HuntingCustomActionEntity,
})
export class HuntingCustomActionDeviceNames extends ApiCallModel<
	Array<HuntingCustomActionEntity>,
	Array<string>
> {}

@ApiCall({
	name: 'Get names of AAD users by user IDs',
	endpoint: 'users/aad',
	baseUrl: config => config.data.serviceUrls.threatIntel,
	method: 'POST',
	parseData: rawData =>
		rawData &&
		rawData.map(user => ({
			EntityId: user.ObjectId,
			EntityName: user.DisplayName,
			EntityType: HuntingRuleEntityType.User,
		})),
	type: HuntingCustomActionEntity,
})
export class HuntingCustomActionAadUserNames extends ApiCallModel<
	Array<HuntingCustomActionEntity>,
	Array<string>
> {}
