import { BehaviorSubject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { SessionStorageService } from '@wcd/shared';
import * as i0 from "@angular/core";
import * as i1 from "./session-storage.service";
var BROWSER_ERROR_KEY = 'X-Bypass-Browser';
var BrowserDetectionService = /** @class */ (function () {
    function BrowserDetectionService(sessionStorageService) {
        this.sessionStorageService = sessionStorageService;
        this.bypassed$ = new BehaviorSubject(null);
    }
    Object.defineProperty(BrowserDetectionService.prototype, "browserData", {
        get: function () {
            if (this._browserData === undefined)
                this._browserData = this.detectBrowser();
            return this._browserData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BrowserDetectionService.prototype, "supported", {
        get: function () {
            return this.browserData.chrome || this.browserData.hexaditeReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BrowserDetectionService.prototype, "bypassedByUser", {
        get: function () {
            if (this._isBypassedByUser === undefined)
                this._isBypassedByUser = !!this.sessionStorageService.getItem(BROWSER_ERROR_KEY);
            return this._isBypassedByUser;
        },
        enumerable: true,
        configurable: true
    });
    /*
     * set "bypassed by user" token on session storage and publish changes
     */
    BrowserDetectionService.prototype.setBypassedByUser = function () {
        this._isBypassedByUser = true;
        this.sessionStorageService.setItem(BROWSER_ERROR_KEY, 'true');
        this.bypassed$.next(true);
    };
    BrowserDetectionService.prototype.detectBrowser = function () {
        var browserData = {
            opera: (!!window.opr && !!window.opr.addons) ||
                !!window.opera ||
                navigator.userAgent.indexOf(' OPR/') >= 0,
            firefox: typeof InstallTrigger !== 'undefined',
            safari: Object.prototype.toString.call(HTMLElement).indexOf('Constructor') > 0,
            IE: /*@cc_on!@*/ false || !!document.documentMode,
            chrome: !!window.chrome && !!window.chrome.webstore,
            hexaditeReports: navigator.userAgent === 'Hexadite Reports',
            isEdge: false,
        };
        browserData.isEdge = !browserData.IE && !!window.StyleMedia;
        return browserData;
    };
    BrowserDetectionService.prototype.ngOnDestroy = function () {
        this.bypassed$.complete();
    };
    BrowserDetectionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrowserDetectionService_Factory() { return new BrowserDetectionService(i0.ɵɵinject(i1.SessionStorageService)); }, token: BrowserDetectionService, providedIn: "root" });
    return BrowserDetectionService;
}());
export { BrowserDetectionService };
