import { Component } from '@angular/core';
import { EntityMinimizedDetailsComponentBase } from './entity-minimized-details.component.base';
import { LegacyUser } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { toObservable } from '../../../utils/rxjs/utils';
import { map, share, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ServiceUrlsService } from '@wcd/app-config';
import { I18nService } from '@wcd/i18n';

@Component({
	template: `
		<ng-container *ngLet="(iconImageUrl$ | async) as iconImageUrl">
			<div class="wcd-flex-center-horizontal wcd-padding-mediumSmall-top">
				<fab-persona
					[imageUrl]="iconImageUrl"
					[size]="size24"
					[imageInitials]="nameInitials"
					[hidePersonaDetails]="true"
				></fab-persona>
			</div>
		</ng-container>
	`,
})
export class UserMinimizedDetailsComponent extends EntityMinimizedDetailsComponentBase<LegacyUser> {
	iconImageUrl$: Observable<string>;
	iconCssClass: string;
	name: string = null;
	nameInitials: string = null;
	size24 = 1;

	constructor(
		protected readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly serviceUrls: ServiceUrlsService,
		protected readonly i18nService: I18nService
	) {
		super(globalEntityTypesService, i18nService);
	}

	setEntity(entity) {
		super.setEntity(entity);
		this.name = this.entityType.getEntityName(entity);
		this.nameInitials = this.name
			? this.name
					.split(' ')
					.map(n => n[0])
					.join('')
					.toUpperCase()
			: null;

		this.iconCssClass = this.entityType.getIconCssClass && this.entityType.getIconCssClass([this.entity]);

		this.iconImageUrl$ = this.entityType.getImage
			? toObservable(this.entityType.getImage([this.entity])).pipe(
					map(imageUrl => `${this.serviceUrls.assetsBaseUrl}${imageUrl}`),
					take(1),
					share()
			  )
			: of(null);
	}
}
