import { Injectable } from '@angular/core';
import { EntityTypeService } from '../models/entity-type-service.interface';
import { EntityType } from '../models/entity-type.interface';
import { GlobalEntityTypesService } from './global-entity-types.service';
import { EntityWithContext, EntityWithType } from '@wcd/domain';
import { EntityWithContextPanelComponent } from '../components/entity-panels/entity-with-context.entity-panel.component';
import { DataEntityType, EntityModelBase, ModelBase, Paris } from '@microsoft/paris';
import { map, switchMap } from 'rxjs/operators';
import { of, Observable, defer } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { toObservable } from '../../utils/rxjs/utils';
import { ItemActionModel } from '../../dataviews/models/item-action.model';

/**
 * Provides support for displaying entities together with some context.
 * e.g. display a file (entity) that was blocked by an antivirus (context)
 * Most of the time, this service takes the properties from the main entity type
 * To use this service, create entities of type EntityWithContext, and display them using the global entities infra, as you do for other, concrete entities.
 */
@Injectable()
export class EntityWithContextEntityTypeService<
	TEntity extends EntityModelBase<string | number>,
	TContext extends ModelBase,
	TOptions
> implements EntityTypeService<EntityWithContext<TEntity, TContext>> {
	constructor(
		private globalEntityTypesService: GlobalEntityTypesService,
		private readonly paris: Paris,
		private readonly i18nService: I18nService
	) {}

	readonly entityType: Readonly<EntityType<EntityWithContext<TEntity, TContext>, TOptions>> = {
		id: 'entity-with-context',
		entity: EntityWithContext,
		singleEntityPanelComponentType: EntityWithContextPanelComponent,
		loadFullEntityInPanel: true,
		getEntityName: entityWithContext =>
			this.delegateToEntityType(
				[entityWithContext],
				(entityType, entity) => entityType.getEntityName && entityType.getEntityName(entity)
			),
		getIcon: entities =>
			this.delegateToEntityType(
				entities,
				(entityType, entity) =>
					entityType.icon || (entityType.getIcon && entityType.getIcon([entity]))
			),
		getImage: entities =>
			this.delegateToEntityType(
				entities,
				(entityType, entity) => entityType.getImage && entityType.getImage([entity])
			),
		getIconCssClass: entities =>
			this.delegateToEntityType(
				entities,
				(entityType, entity) => entityType.getIconCssClass && entityType.getIconCssClass([entity])
			),
		getItemParams: (entityWithContext, options) =>
			this.delegateToEntityType(
				[entityWithContext],
				(entityType, entity) => entityType.getItemParams && entityType.getItemParams(entity, options)
			),
		getItem: (
			entityWithContext: EntityWithContext<TEntity, TContext>,
			loadedEntity$: Observable<EntityWithContext<TEntity, TContext>>,
			options: TOptions,
			allowCache?: boolean
		) =>
			this.delegateToEntityType([entityWithContext], (entityType, entity) => {
				if (entityType.loadFullEntityInPanel === false) {
					return entityWithContext;
				}

				// load main entity, retain context
				const loadedMainEntity$ = defer(() => {
					const params$ = toObservable(
						entityType.getItemParams ? entityType.getItemParams(entity, options) : null
					);
					return params$.pipe(
						switchMap(params =>
							this.paris.getItemById(
								<DataEntityType<EntityModelBase<any>>>entityType.entity,
								<any>entity.id,
								{ allowCache: allowCache },
								params
							)
						)
					);
				});
				const mainEntity$ = entityType.getItem
					? toObservable(entityType.getItem(entity, loadedMainEntity$, options, allowCache))
					: loadedMainEntity$;

				return mainEntity$.pipe(
					map(
						(loadedEntity: TEntity) =>
							//@ts-ignore
							new EntityWithContext({
								...entityWithContext,
								mainEntity: {
									...entityWithContext.mainEntity,
									item: loadedEntity,
								},
							})
					)
				);
			}),
		getActions: (entities, options, entityViewType) =>
			this.delegateToEntityType(entities, (entityType, entity) => {
				const mainEntityActions =
						entityType.getActions && entityType.getActions([entity], options, entityViewType),
					mainEntityActions$ = mainEntityActions && toObservable(mainEntityActions);

				return (
					mainEntityActions$ &&
					mainEntityActions$.pipe(
						map(actions => actions.map(action => this.convertEntityAction(action)))
					)
				);
			}),
		getEntityClassName: entityWithContext =>
			this.delegateToEntityType(
				[entityWithContext],
				(entityType, entity) => entityType.getEntityClassName && entityType.getEntityClassName(entity)
			),
		getEntitiesLink: entities =>
			this.delegateToEntityType(
				entities,
				(entityType, entity) => entityType.getEntitiesLink && entityType.getEntitiesLink([entity])
			),
		getEntitiesLinkText: entities =>
			this.delegateToEntityType(
				entities,
				(entityType, entity) =>
					(entityType.getEntitiesLinkText && entityType.getEntitiesLinkText([entity])) ||
					this.i18nService.get('entityCommon.commandBar.openPage', {
						entity: entityType.entity.singularName,
					})
			),
		getSubtitle: entities =>
			this.delegateToEntityType(
				entities,
				(entityType, entity) => entityType.getSubtitle && entityType.getSubtitle([entity])
			),
		isUserExposedToEntity: entityWithContext =>
			this.delegateToEntityType(
				[<EntityWithContext<TEntity, TContext>>entityWithContext],
				(entityType, entity) =>
					entityType.isUserExposedToEntity
						? entityType.isUserExposedToEntity(entity)
						: entityType.isUserExposedToEntityById
						? entityType.isUserExposedToEntityById(entity.id.toString())
						: of(true)
			),
		getTags: entities =>
			this.delegateToEntityType(
				entities,
				(entityType, entity) => (entityType.getTags && entityType.getTags([entity])) || []
			),
	};

	private delegateToEntityType<R>(
		entitiesWithContext: Array<EntityWithContext<TEntity, TContext>>,
		fn: (entityType: EntityType, entity: TEntity) => R
	): R {
		const mainEntityWithType: EntityWithType<TEntity> =
				entitiesWithContext && entitiesWithContext.length === 1 && entitiesWithContext[0]
					? entitiesWithContext[0].mainEntity
					: null,
			mainEntity: TEntity = mainEntityWithType && mainEntityWithType.item,
			mainEntityType =
				mainEntityWithType && this.globalEntityTypesService.getEntityType(mainEntityWithType.type);

		return mainEntity && mainEntityType && fn(<any>mainEntityType, mainEntity);
	}

	private convertEntityAction(
		action: ItemActionModel<TEntity>
	): ItemActionModel<EntityWithContext<TEntity, TContext>> {
		// The UI infra expects actions performed on this entity type to work with entities of type EntityWithContext
		// the actions are delegated to the main entity type, which works with entities of type TEntity
		// redirect the action to the main entity
		return {
			...action,
			method: (
				entitiesWithContext: Array<EntityWithContext<TEntity, TContext>>,
				options,
				dataset,
				itemActionValue
			) =>
				action.method(
					this.convertToMainEntities(entitiesWithContext),
					options,
					dataset && { ...dataset, items: this.convertToMainEntities(dataset.items) },
					itemActionValue
				),
		};
	}

	private convertToMainEntities(
		entitiesWithContext: Array<EntityWithContext<TEntity, TContext>>
	): Array<TEntity> {
		return (
			entitiesWithContext &&
			entitiesWithContext.map((ec: EntityWithContext<TEntity, TContext>) => ec.mainEntity.item)
		);
	}
}
