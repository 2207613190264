import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcdIconsModule } from '@wcd/icons';
import { TooltipsModule } from '@wcd/dialogs';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@wcd/i18n';
import { PrettifyModule } from '@wcd/prettify';
import { StretchedDonutBarsComponent } from './stretched-donut-bars.component';
import { BarsChartComponent } from './bars-chart.component';
import { HorizontalBarsChartComponent } from './horizontal-bars-chart.component';
import { StackedBarsChartComponent } from './stacked-bars-chart.component';
import { BarComponent } from './bar.component';
import { WcdFormsModule } from '@wcd/forms';
import { FabIconModule } from '@angular-react/fabric';

@NgModule({
	imports: [
		CommonModule,
		WcdIconsModule,
		TooltipsModule,
		RouterModule,
		I18nModule,
		PrettifyModule,
		WcdFormsModule,
		FabIconModule,
	],
	declarations: [
		BarComponent,
		BarsChartComponent,
		HorizontalBarsChartComponent,
		StackedBarsChartComponent,
		StretchedDonutBarsComponent,
	],
	exports: [
		BarComponent,
		BarsChartComponent,
		HorizontalBarsChartComponent,
		StackedBarsChartComponent,
		StretchedDonutBarsComponent,
		RouterModule,
	],
})
export class BarsChartModule {}
