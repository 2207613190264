import { wcdGetWindow } from '../dom/wcdGetWindow';
export const WCDIsFocusVisibleClassName = 'wcd-isFocusVisible';
export const WCDIsFocusHiddenClassName = 'wcd-isFocusHidden';

/**
 * Sets the visibility of focus styling.
 * By default, focus styling (the box surrounding a focused Button, for example) only show up when navigational
 * keypresses occur (through Tab, arrows, PgUp/PgDn, Home and End), and are hidden when mouse interactions occur.
 * This API provides an imperative way to turn them on/off.
 * A use case might be when you have a keypress like ctrl-f6 navigate to a particular region on the page, and want focus to show up.
 *
 * @param enabled - whether to remove or add focus
 * @param target - optional target
 */
export function wcdSetFocusVisibility(enabled: boolean, target?: Element): void {
	const win = target ? wcdGetWindow(target) : wcdGetWindow();

	if (win) {
		const { classList } = win.document.body;
		classList.add(enabled ? WCDIsFocusVisibleClassName : WCDIsFocusHiddenClassName);
		classList.remove(enabled ? WCDIsFocusHiddenClassName : WCDIsFocusVisibleClassName);
	}
}
