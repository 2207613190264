import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { Machine, MachineSecurityAnalytics } from '@wcd/domain';

@Component({
	selector: 'app-machine-score',
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<machine-security-state-panel
				class="wcd-flex-1 wcd-flex-vertical wcd-full-height"
				[machine]="machine"
				[machineSecurityAnalytics]="machineSecurityAnalytics"
			>
			</machine-security-state-panel>
		</wcd-panel>
	`,
})
export class MachineSecurityStateComponent extends PanelContainer {
	@Input() machine: Machine;
	@Input() machineSecurityAnalytics: MachineSecurityAnalytics;
	constructor(router: Router) {
		super(router);
	}
}
