/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./file-malware-detected.component.ngfactory";
import * as i5 from "./file-malware-detected.component";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "@microsoft/paris/dist/lib/paris";
import * as i8 from "../../../dialogs/services/dialogs.service";
import * as i9 from "../../../global_entities/services/global-entity-types.service";
import * as i10 from "../services/file-verdict-alert.service";
import * as i11 from "@angular/common";
import * as i12 from "./file-detections-panel.component";
import * as i13 from "@angular/router";
var styles_FileDetectionsPanelComponent = [];
var RenderType_FileDetectionsPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileDetectionsPanelComponent, data: {} });
export { RenderType_FileDetectionsPanelComponent as RenderType_FileDetectionsPanelComponent };
export function View_FileDetectionsPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 6, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 1, "file-malware-detected", [], null, null, null, i4.View_FileMalwareDetectedComponent_0, i4.RenderType_FileMalwareDetectedComponent)), i0.ɵdid(6, 114688, null, 0, i5.FileMalwareDetectedComponent, [i6.I18nService, i7.Paris, i8.DialogsService, i9.GlobalEntityTypesService, i10.FileVerdictAlertService, i11.Location], { file: [0, "file"], showSummary: [1, "showSummary"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.file; var currVal_2 = false; _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_FileDetectionsPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "file-detections-panel", [], null, null, null, View_FileDetectionsPanelComponent_0, RenderType_FileDetectionsPanelComponent)), i0.ɵdid(1, 245760, null, 0, i12.FileDetectionsPanelComponent, [i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileDetectionsPanelComponentNgFactory = i0.ɵccf("file-detections-panel", i12.FileDetectionsPanelComponent, View_FileDetectionsPanelComponent_Host_0, { settings: "settings", file: "file" }, {}, []);
export { FileDetectionsPanelComponentNgFactory as FileDetectionsPanelComponentNgFactory };
