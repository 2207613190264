import { Injector } from '@angular/core';
import { EntityModelBase } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../services/global-entity-types.service';
import { EntityPanelsService } from '../services/entity-panels.service';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { File, Ip, NetworkEndpoint, Process, Url } from '@wcd/domain';
import { RoutesService } from '@wcd/shared';
import { FeaturesService, Feature } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
/**
 * Entity link component
 * Opens entity panel on click
 * Opens entity page on "right-click+open in new tab" or "ctrl+click" or "mouse-middle-click"
 * Implements special logic for "Process" (renders File entity links) and "NetworkEndpoint" (renders both Ip and Url links) links
 */
var EntityLinkComponent = /** @class */ (function () {
    function EntityLinkComponent(injector, globalEntityTypesService, appInsightsService, routesService, featuresService) {
        this.injector = injector;
        this.globalEntityTypesService = globalEntityTypesService;
        this.appInsightsService = appInsightsService;
        this.routesService = routesService;
        this.featuresService = featuresService;
        this.networkEndpointType = NetworkEndpoint;
        this.ipType = Ip;
        this.urlType = Url;
        this.enableProcessSidePanel = false;
        this.entityPanelsService = this.injector.get(EntityPanelsService);
        this.enableProcessSidePanel = sccHostService.isSCC && this.featuresService.isEnabled(Feature.ImprovedEntitySidePanels)
            && this.featuresService.isEnabled(Feature.ReactEntityPanelsInAngular);
    }
    Object.defineProperty(EntityLinkComponent.prototype, "entityType", {
        set: function (value) {
            this.originalEntityType = value;
            var isProcessEntityType = value === Process;
            this.itemEntityType = this.globalEntityTypesService.getEntityType(!isProcessEntityType || this.enableProcessSidePanel ? value : File); // Process links are treated as File links
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EntityLinkComponent.prototype, "entity", {
        set: function (value) {
            this.item = value;
        },
        enumerable: true,
        configurable: true
    });
    EntityLinkComponent.prototype.ngOnChanges = function (changes) {
        if (!this.itemEntityType) {
            console.warn("Entity link rendered without details.", this.item, this.originalEntityType);
            return;
        }
        if (this.originalEntityType === Process && !this.enableProcessSidePanel) {
            this.item = this.item['file']; // Process links are treated as File links
        }
        var link = this.itemEntityType &&
            this.itemEntityType.getEntitiesLink &&
            this.itemEntityType.getEntitiesLink([this.item]);
        var mdatpFromSccUrl = this.routesService.getMdatpFromSccLinkConfig(link);
        this.link = mdatpFromSccUrl ? mdatpFromSccUrl.url : link;
        if (this.routesService.shouldOpenExternally(mdatpFromSccUrl)) {
            this.showExternalLinkIcon = true;
        }
    };
    EntityLinkComponent.prototype.getLinkText = function () {
        return (this.linkText ||
            (this.itemEntityType &&
                this.itemEntityType.getEntityName &&
                this.itemEntityType.getEntityName(this.item)) ||
            this.item['name'] ||
            this.item.id);
    };
    EntityLinkComponent.prototype.openEntityPanel = function (event) {
        var _this = this;
        if (event.ctrlKey) {
            this.trackNavigation("EntityLinkOpenInNewTab_" + this.itemEntityType.entity.singularName);
            return;
        }
        else {
            this.trackNavigation("EntityLinkOpenEntityPanel_" + this.itemEntityType.entity.singularName);
            event.preventDefault();
            event.stopPropagation();
            this.entityPanelsService.showEntity(this.itemEntityType.entity, this.item, { actionTime: this.actionTime }, {
                back: {
                    onClick: function () { return _this.entityPanelsService.closeEntityPanel(_this.itemEntityType.entity); },
                },
            }, this.linkLogContext);
        }
    };
    EntityLinkComponent.prototype.trackNavigation = function (id) {
        this.appInsightsService.trackEvent('UI', {
            id: id,
            type: TrackingEventType.Navigation,
        });
    };
    return EntityLinkComponent;
}());
export { EntityLinkComponent };
