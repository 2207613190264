/* tslint:disable:template-accessibility-alt-text */
/* tslint:disable:template-accessibility-label-for */
/* tslint:disable:template-click-events-have-key-events */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	NgZone,
	Type,
	Output,
	EventEmitter,
} from '@angular/core';
import { from, Observable } from 'rxjs';
import { finalize, map, shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
import { Paris } from '@microsoft/paris';
import {
	ResearcherAssistanceApiCall,
	SendFeedbackApiCall,
	MdeUserRoleActionEnum,
	UserAssistanceCause,
	UserAssistanceData,
} from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { SupportCentralService } from '../../support-central/support-central.service';

declare const html2canvas: typeof import('html2canvas');

@Component({
	selector: 'wcd-feedback',
	templateUrl: './feedback.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent {
	@Input() readonly isEmailOptional: boolean;
	@Input() readonly i18nType: string;
	@Input() readonly submitApiCall: Type<SendFeedbackApiCall | ResearcherAssistanceApiCall>;
	@Input() readonly includeCause = false;
	@Input() readonly submitI18nKey = 'feedback.submit';

	@Output() done: EventEmitter<any> = new EventEmitter();

	message: string;
	readonly openSupportTicketI18nKey = 'feedback.openSupportTicket';
	includeScreenshot = true;
	includeEmail = true;
	email: string;
	readonly screenshot$: Observable<string>;
	screenshot: string;
	isSaving = false;
	isFeedbackEnabled: boolean;
	cause: UserAssistanceCause;
	readonly causes: Array<UserAssistanceCause> = [
		'alertInformation',
		'machineContext',
		'threatIntelligenceDetails',
		'msThreatExpertAlert',
		'other',
	].map(cause => this.i18nService.get(`researcherAssistance.causes.${cause}`));

	constructor(
		private ngZone: NgZone,
		private authService: AuthService,
		private paris: Paris,
		private i18nService: I18nService,
		public supportCentralService: SupportCentralService,
		private dialogsService: DialogsService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		this.setEmail();

		this.screenshot$ = this.getScreenshot().pipe(tap(screenshot => (this.screenshot = screenshot)));
		this.isFeedbackEnabled =
			this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.viewData) ||
			this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.tvmViewData);
	}

	setEmail() {
		this.email = this.includeEmail ? this.authService.currentUser.name : null;
		setTimeout(() => this.changeDetectorRef.markForCheck());
	}

	submit() {
		if (!this.isFeedbackEnabled) return;

		this.ngZone.run(() => {
			// Due to angular-react bug
			this.isSaving = true;

			const data: Partial<UserAssistanceData> = {
				email: this.includeEmail && this.email,
				description: this.message,
				screenshot:
					this.includeScreenshot && this.screenshot.replace(/^data:image\/(png|jpg);base64,/, ''),
			};

			if (this.includeCause) data.cause = this.cause;

			this.paris
				.apiCall(this.submitApiCall, data)
				.pipe(
					finalize(() => {
						this.isSaving = false;
					})
				)
				.subscribe(
					result => this.done.emit(result),
					error => {
						this.dialogsService.showError({
							title: this.i18nService.get(this.i18nType + 'error.title'),
							text: this.i18nService.get(this.i18nType + 'error.text'),
							data: error,
						});
					}
				);
		});
	}

	private getScreenshot(): Observable<string> {
		return from(html2canvas(document.querySelector('app'))).pipe(
			map((canvas: HTMLCanvasElement) => canvas.toDataURL()),
			shareReplay(1)
		);
	}
}
