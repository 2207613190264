import {
	LiveResponseCommandFlag,
	LiveResponseCommandParam,
	LiveResponseCommandType,
	ParamBackendConfig,
} from '@wcd/domain';

export type CommandModifiers = {
	params: Array<ParamBackendConfig>;
	flags: Array<string>;
	outputTo: string;
	runInBackground: boolean;
};

export class LiveResponseInputParserService {
	static buildCommandModifiers(commandType: LiveResponseCommandType, commandStr: string): CommandModifiers {
		let currentParamPosition = 0;
		let outputTo: string;
		let runInBackground = false;
		let commandParts: Array<string> = LiveResponseInputParserService.buildCommandParts(commandStr);
		const flags: Array<string> = [],
			explicitParams: Array<ParamBackendConfig> = [],
			positionalParams: Array<ParamBackendConfig> = [],
			handledIndices: Array<number> = [];
		if (commandParts.length >= 2) {
			const redirectionCharIndex = commandParts.length - 2;
			if (commandParts[redirectionCharIndex] === '>') {
				outputTo = commandParts[redirectionCharIndex + 1];
				// remove quotes
				outputTo = outputTo.replace(/^(["'])(.*)\1$/, '$2');
				commandParts = commandParts.slice(0, redirectionCharIndex);
			}
		}
		commandParts.forEach((part, index) => {
			if (handledIndices.includes(index)) {
				return;
			}
			// explicit modifier
			if (part.startsWith('-')) {
				const modId = part.replace(/^-/, ''),
					param =
						commandType.params &&
						commandType.params.find(
							(p: LiveResponseCommandParam) => p.paramId.toLowerCase() === modId.toLowerCase()
						);
				if (param) {
					// throw if param was already specified
					if (explicitParams.find(p => p.paramId === param.paramId)) {
						throw new Error(`Parameter '${param.paramId}' was specified multiple times`);
					}
					handledIndices.push(index);
					let value = commandParts[index + 1];
					if (value && value.startsWith('-')) {
						value = undefined;
					} else {
						handledIndices.push(index + 1);
					}
					explicitParams.push({
						paramId: param.paramId,
						value: value,
					});
				} else {
					const flag =
						commandType.flags &&
						commandType.flags.find(
							(f: LiveResponseCommandFlag) => f.flagId.toLowerCase() === modId.toLowerCase()
						);
					if (flag) {
						// throw if flag was already specified
						if (flags.includes(flag.flagId)) {
							throw new Error(`Flag '${flag.flagId}' was specified multiple times`);
						}
						flags.push(flag.flagId);
						handledIndices.push(index);
					}
				}
			} else if (part === '&' && index === commandParts.length - 1) {
				runInBackground = true;
				handledIndices.push(index);
			} else if (commandType.params) {
				// implicit modifier - positional matching
				if (currentParamPosition < commandType.params.length) {
					positionalParams.push({
						paramId: commandType.params[currentParamPosition++].paramId,
						value: part,
					});
					handledIndices.push(index);
				}
			}
		});

		const mergedParams: Array<ParamBackendConfig> = positionalParams.slice();
		explicitParams.forEach(p => {
			if (p.value === undefined) {
				throw new Error(`Parameter '${p.paramId}' was specified without a value`);
			}
			const existingParam = mergedParams.find(_p => _p.paramId === p.paramId);
			// implicit matching and explicit collision
			if (existingParam) {
				throw new Error(`Parameter '${p.paramId}' was specified multiple times`);
			} else {
				mergedParams.push(p);
			}
		});

		const unhandledParts: Array<string> = commandParts.filter(
			(_, index) => !handledIndices.includes(index)
		);
		// throw if there are command parts that weren't handledIndices
		if (unhandledParts.length) {
			throw new Error(
				`Parts of the command couldn't be recognized, including: ${unhandledParts
					.map(part => `'${part}'`)
					.join(', ')}`
			);
		}

		const mandatoryParams: Array<LiveResponseCommandParam> = commandType.params
			? commandType.params.filter(p => p.isOptional === false)
			: [];
		mandatoryParams.forEach(p => {
			// not all mandatory explicitParams are satisfied
			if (!mergedParams.find(_p => _p.paramId === p.paramId)) {
				throw new Error(`Required parameter '${p.paramId}' is missing`);
			}
		});

		return {
			params: mergedParams.map(p =>
				// remove quotes
				Object.assign(p, {
					value: p.value.replace(/^(["'])(.*)\1$/, '$2'),
				})
			),
			flags,
			outputTo,
			runInBackground,
		};
	}

	private static buildCommandParts(commandStr: string): Array<string> {
		const parts: Array<string> = this.splitCommand(commandStr).slice(1),
			handledIndices: Array<number> = [],
			parsedParts: Array<string> = [];

		parts.forEach((p, index) => {
			if (handledIndices.includes(index)) {
				return;
			}
			let part: string;
			const startsWithQuoteMatch = p.match(/^(['"])/);
			const quoteType = startsWithQuoteMatch && startsWithQuoteMatch[1];
			if (!quoteType) {
				part = p;
				handledIndices.push(index);
			} else {
				const nextParts = parts.slice(index);
				part = '';
				let i = 0;
				while (i < nextParts.length) {
					const _p = nextParts[i];
					part += (i === 0 ? '' : ' ') + _p;
					handledIndices.push(index + i);
					// ends with a " but not escaped
					if (_p.endsWith(quoteType)) {
						const escapesMatch = _p.match(new RegExp(`(\\\\*)${quoteType}$`)),
							escapes = escapesMatch && escapesMatch[1];
						if (!escapes || escapes.length % 2 === 0) {
							break;
						}
					}
					if (i === nextParts.length - 1) {
						throw new Error('Check command for unclosed quotes');
					}
					i++;
				}
				// remove escaped quotes
				part = part.replace(new RegExp('\\\\' + quoteType, 'g'), quoteType);
			}
			// don't add whitespaces
			if (part !== '') {
				parsedParts.push(part);
			}
		});
		return parsedParts;
	}

	private static splitCommand(commandStr: string) {
		const whitespace = /\s/;
		const quotedStr = /('[^']*'?')|("[^"]*"?")/;
		const redirected = /(>)\s*((?:"[^"]*")|(?:'[^']*')|(?:\S+))/;
		const ampersand = /(&)/;
		return (
			commandStr
				// remove leading and trailing whitespaces
				.replace(/^\s+|\s+$/g, '')
				.split(
					new RegExp(
						`${whitespace.source}|${quotedStr.source}|${redirected.source}|${ampersand.source}`,
						'g'
					)
				)
				.filter(Boolean)
		);
	}

	static getCommandDefIdFromRawString(commandStr: string): string {
		const commandParts = this.splitCommand(commandStr);
		return (commandParts && commandParts.length && commandParts[0]) || '';
	}
}
