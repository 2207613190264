import { DataQuery, DataQuerySortDirection, Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { DomainCategory } from './domain-category/domain-category.entity';
import { DomainUrl } from './domain-url.entity';

@Entity({
	singularName: 'Domain',
	pluralName: 'Domains',
	endpoint: 'webThreats/domains/allDomains',
	allItemsProperty: 'results',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.webThreatProtection,
	cache: {
		time: 1000 * 60,
		max: 10,
	},
	parseItemQuery: (itemId, entity, config, params: { [index: string]: any }) => {
		// Since the id of the domain will most likely have periods in it, send it as query parameter
		// itemId is domain name (ex. www.microsoft.com)
		return `domains/allDomains?id=${itemId}`;
	},
})
export class Domain extends EntityModelBase {
	@EntityField({ data: 'DomainId' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'Title' })
	title: string;

	@EntityField({ data: 'AccessCount' })
	accessCount: number;

	@EntityField({ data: 'Blocks' })
	blocks: number;

	@EntityField({ data: 'AccessTrend' })
	accessTrend: number;

	@EntityField({ data: 'WebThreatCategory' })
	webThreatCategory: DomainCategory;

	@EntityField({ data: 'MachinesDetectedOn' })
	machinesDetectedOn: number;

	@EntityField({ data: 'Urls' })
	urls: DomainUrl[];

	@EntityField({ data: 'Severity' })
	severity: string;

	@EntityField({ data: 'Status' })
	status: string;
}
