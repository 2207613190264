import { Injectable } from '@angular/core';
import { MtpWorkload, MdeUserRoleAction, MdeUserRoleActionEnum } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { AuthService, RbacPermission } from '@wcd/auth';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { Dictionary } from '@wcd/config';
import { RbacControlSettings } from '../models/rbac-control-settings.model';
import { AppConfigService } from '@wcd/app-config';

@Injectable()
export class RbacControlService {
	private readonly userRoleActionsMap: Dictionary<string, MdeUserRoleAction>;

	constructor(
		paris: Paris,
		private authService: AuthService,
		private appConfigService: AppConfigService,
		private appInsights: AppInsightsService
	) {
		const userRoleActionsRepository = paris.getRepository<MdeUserRoleAction>(MdeUserRoleAction);
		this.userRoleActionsMap = Dictionary.fromList(
			MdeUserRoleAction.flatten(userRoleActionsRepository.entity.values),
			'name'
		);
	}

	/*
	Checks RBAC permissions (MTP & Workload permissions or MDE only permissions)
	 */
	hasRequiredRbacPermissions(rbacSettings: RbacControlSettings): boolean {
		let hasRequiredPermissions: boolean;

		if (rbacSettings.customCheckCallback && !rbacSettings.customCheckCallback()) {
			return false;
		}

		if (rbacSettings.mtpPermissions || rbacSettings.unifiedPermissions) {
			//ToDo: Check if Urbac is enabled and if so take unifiedPermissions, otherwise take mtpPermissions
			const permissions = rbacSettings.mtpPermissions ? rbacSettings.mtpPermissions : rbacSettings.unifiedPermissions
			hasRequiredPermissions =
				rbacSettings.mtpWorkloads && rbacSettings.mtpWorkloads.length
					? rbacSettings.mtpWorkloads.every(workload => {
							if (
								rbacSettings.ignoreNonActiveWorkloads &&
								!this.appConfigService.hasActiveWorkload(workload)
							) {
								// allow if wl is suspended\opted out
								return true;
							}

							// allow if user has the permissions on this wl
							return this.hasRequiredMtpPermission(
								permissions,
								workload,
								rbacSettings.requireAllPermissions
							);
					  })
					: // no specific wl requested, allow if user has the permissions on any wl
					  this.hasRequiredMtpPermission(
							permissions,
							null,
							rbacSettings.requireAllPermissions
					  );
		} else {
			hasRequiredPermissions = this.hasRequiredMdePermission(
				// check legacy permissions (mdatp only scenarios)
				rbacSettings.permissions,
				rbacSettings.requireAllPermissions
			);
		}

		return hasRequiredPermissions;
	}

	/*
	MDE only permissions (based on MdeUserRoleActionEnum which is MDE custom roles).
	 */
	hasRequiredMdePermission(
		permissions: Array<keyof typeof MdeUserRoleActionEnum>,
		requireAll: boolean = false
	): boolean {
		if (!permissions) {
			return true;
		}

		const checkPermissions = permission => {
			const userRoleAction = this.userRoleActionsMap.get(permission);
			if (!userRoleAction) {
				this.appInsights.trackException(new Error(`Unknown user role action type: ${permission}.`));
				return false;
			}

			return this.authService.currentUser.hasMdeAllowedUserRoleAction(
				this.userRoleActionsMap.get(permission)
			);
		};

		return requireAll ? permissions.every(checkPermissions) : permissions.some(checkPermissions);
	}

	/*
	A helper method for checking MTP permissions (based on MtpPermission and MtpWorkload).
	ToDo: rename hasRequiredMtpPermission to hasRequiredPermission
	 */
	private hasRequiredMtpPermission(
		permissions: Array<RbacPermission>,
		mtpWorkload?: MtpWorkload,
		requireAll: boolean = false
	): boolean {
		if (!permissions) {
			return true;
		}

		const checkPermissions = permission => {
			if (mtpWorkload) {
				return this.authService.currentUser.hasRequiredMtpPermissionInWorkload(
					permission,
					mtpWorkload
				);
			}

			return this.authService.currentUser.hasRequiredMtpPermission(permission);
		};

		return requireAll ? permissions.every(checkPermissions) : permissions.some(checkPermissions);
	}
}
