import { groupBy as lodashGroupBy } from 'lodash-es';

export interface Group<K, T> {
	key: K;
	items: T[];
}

/**
 * Safer alternative to lodash's `groupBy`.
 * Allows grouping based on any key, not just ones that are `string`-able.
 *
 * @param items The items.
 * @param keySelector Selector for the key.
 * @returns An array of `Group`, where each group contains the key and the items in the group.
 */
export function groupBy<TKey, TItem>(
	items: ReadonlyArray<TItem>,
	keySelector: (item: TItem) => TKey
): Group<TKey, TItem>[] {
	const itemWrappers = items.map(item => ({ key: keySelector(item), item: item }));
	const itemWrappersGroups = lodashGroupBy(itemWrappers, keyedItem => keySelector(keyedItem.item));

	return Object.values(itemWrappersGroups).map<Group<TKey, TItem>>(itemWrappersGroup => ({
		key: itemWrappersGroup[0].key, // all the items in the group have the same key
		items: itemWrappersGroup.map(itemWrapper => itemWrapper.item),
	}));
}
