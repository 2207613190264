import { NgModule } from '@angular/core';
import { CommentsListComponent } from './components/comments-list.component';
import { CommentsStore } from './services/comments.store';
import { CommentsBackendService } from './services/comments.backend.service';
import { SharedModule } from '../shared/shared.module';
import { EditableCommentsListComponent } from './components/editable-comments-list/editable-comments-list.component';
import { FabIconModule, FabButtonModule } from '@angular-react/fabric';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [SharedModule, FabIconModule, FabButtonModule, A11yModule],
	declarations: [CommentsListComponent, EditableCommentsListComponent],
	exports: [CommentsListComponent, EditableCommentsListComponent],
	entryComponents: [CommentsListComponent, EditableCommentsListComponent],
	providers: [CommentsStore, CommentsBackendService],
})
export class CommentsModule {}
