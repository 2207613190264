import { Injectable } from '@angular/core';
import { FabricIconNames } from '@wcd/scc-common';
import { DataviewField } from '@wcd/dataview';
import { FilterValuesChecklistComponent, FilterValuesToggleComponent } from '@wcd/ng-filters';
import { WcdIconNames } from '@wcd/icons';
import { sortBy } from 'lodash-es';
import {
	CyberEvent,
	CyberEventActionFilterGroupTypeName,
	CyberEventActionTypeName,
	FileInstance,
	Machine,
	NetworkEndpoint,
	Process,
	Tag,
} from '@wcd/domain';
import { EntityType, FieldsService } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { TagsFieldComponent } from '../../../tags/components/tags-field/tags-field.component';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { CyberEventsActionTypesService } from './cyber-events-action-types.service';
import { FlexDirection } from '@wcd/shared';
import { MachineEventMarkComponent } from '../../machines/components/machine-event-mark.component';
import { FeaturesService, Feature } from '@wcd/config';
import { CyberEventDataTypeFilterTypeName } from '@wcd/domain';

@Injectable()
export class CyberEventsFieldsService implements FieldsService<CyberEvent> {
	private _fields: Array<DataviewField<CyberEvent>>;

	constructor(
		private readonly cyberEventsActionTypesService: CyberEventsActionTypesService,
		private readonly i18nService: I18nService,
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly featuresService: FeaturesService
	) {}

	get fields(): Array<DataviewField<CyberEvent>> {
		if (!this._fields) {
			const machineEntityType: EntityType<Machine> = this.globalEntityTypesService.getEntityType(
				Machine
			);

			this._fields = DataviewField.fromList<CyberEvent>([
				{
					id: 'actionTime',
					name: this.i18nService.get('events.fields.date.title'),
					component: {
						type: TzDateComponent,
						getProps: (event: CyberEvent) => ({
							date: event.actionTime,
							dateFormat: 'shortWithMillis',
						}),
					},
					className: 'nowrap wcd-text-overflow-medium',
					sort: { enabled: false },
				},
				{
					id: 'mark',
					name: '',
					component: {
						type: MachineEventMarkComponent,
						getProps: (event: CyberEvent) => ({
							cyberEvent: event,
						}),
					},
					fluidWidth: 0.1,
					minWidth: 40,
					sort: { enabled: false },
					headerIcon: FabricIconNames.Flag,
					headerClass: 'wcd-padding-small-left',
					alwaysDisplay: true,
				},
				{
					id: 'machine',
					name: this.i18nService.get('machine'),
					getDisplay: (event: CyberEvent) =>
						event.machine ? machineEntityType.getEntityName(event.machine) : null,
					icon: {
						fabricIcon: this.globalEntityTypesService.getEntityTypeIcon(Machine),
					},
					className: 'nowrap wcd-text-overflow-medium',
					sort: { enabled: false },
				},
				{
					id: 'description',
					name: this.i18nService.get('events.fields.description.title'),
					getDisplay: (event: CyberEvent) =>
						this.cyberEventsActionTypesService.getEventDescription(event),
					getTooltip: (event: CyberEvent) =>
						this.cyberEventsActionTypesService.getEventDescription(event),
					maxWidth: 500,
					icon: {
						fabricIcon: (event: CyberEvent) => {
							if (event.isNotAlert) {
								const iconName = this.cyberEventsActionTypesService.getEventIcon(event);
								if (!WcdIconNames[iconName]) return iconName;
							}
						},
						wcdIcon: (event: CyberEvent) => {
							if (event.isNotAlert) {
								const iconName = this.cyberEventsActionTypesService.getEventIcon(event);
								if (WcdIconNames[iconName]) return WcdIconNames[iconName];
							}
						},
						className: (event: CyberEvent) => {
							const className: Array<string> = [];
							if (event.IsBoldEvent) {
								className.push('ms-color-tealLight');
							}
							if (event.alertSeverity) {
								className.push('field-value-icon');
								className.push(`wcd-severity-icon-outline-${event.alertSeverity.type}`);
							}

							return className.join(' ');
						},
					},
					getCssClass: (event: CyberEvent) => {
						const className: Array<string> = [];
						if (event.IsBoldEvent) {
							className.push('wcd-font-weight-bold');
						}
						if (
							event.actionType &&
							event.actionType.id === CyberEventActionTypeName.Alert &&
							event.relatedAlert
						) {
							className.push(`wcd-severity wcd-severity-${event.relatedAlert.severity.type}`);
						}

						return className.join(' ');
					},
					sort: { enabled: false },
					fluidWidth: 1,
					minWidth: 400,
				},
				{
					id: 'details', // "Additional information" field
					name: this.i18nService.get('events.fields.details.title'),
					component: {
						type: TagsFieldComponent,
						getProps: (event: CyberEvent) => {
							const tags: Array<Tag> = this.cyberEventsActionTypesService.getEventTags(event);
							const orientation = FlexDirection.Vertical;
							return {
								tags,
								orientation,
							};
						},
					},
					fluidWidth: 0.4,
					minWidth: 150,
					sort: { enabled: false },
				},
				{
					id: 'user',
					name: this.i18nService.get('events.fields.user.title'),
					getDisplay: (event: CyberEvent) =>
						event.isNotAlert ? event.initiatingUser && event.initiatingUser.accountName : null,
					icon: {
						fabricIcon: (event: CyberEvent) =>
							event.isNotAlert && event.initiatingUser && FabricIconNames.Contact,
					},
					getTooltip: (event: CyberEvent) =>
						event.isNotAlert ? event.initiatingUser && event.initiatingUser.fullName : null,
					fluidWidth: 0.4,
					minWidth: 150,
					sort: { enabled: false },
				},
				{
					id: 'entities',
					name: this.i18nService.get('events.fields.entities.title'),
					getDisplay: (event: CyberEvent) =>
						event.isNotAlert ? this.getEntitiesList(event) : null,
					getTooltip: (event: CyberEvent) =>
						event.isNotAlert ? this.getEntitiesList(event) : null,
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 300,
				},
				{
					id: 'actionTypes',
					name: this.i18nService.get('events.fields.actionTypes.title'),
					getDisplay: (event: CyberEvent) => {
						return event.isUnknownOrNotAlert
							? this.cyberEventsActionTypesService.getActionType(event)
							: null;
					},
					sort: { enabled: false },
					custom: {
						allowFilterValueTracking: true,
					},
				},
				{
					id: 'dataTypes',
					name: this.i18nService.get('events.fields.eventsDataType.title'),
					filterOnly: true,
					filter: {
						priority: 3,
						requiresData: false,
						component: {
							type: FilterValuesChecklistComponent,
							config: {
								values: sortBy(
									Object.keys(CyberEventDataTypeFilterTypeName).map((type: string) => ({
										value: type,
										name: this.i18nService.get(`events.dataTypeFilter.${type}`),
									})),
									(value) => value.name
								),
								disableSelectAll: true,
							},
						},
					},
					custom: {
						allowFilterValueTracking: true,
					},
				},
				{
					id: 'eventsGroups',
					name: this.i18nService.get('events.fields.eventsGroups.title'),
					filterOnly: true,
					filter: {
						priority: 4,
						requiresData: false,
						component: {
							type: FilterValuesChecklistComponent,
							config: {
								values: sortBy(
									Object.keys(CyberEventActionFilterGroupTypeName).map((group: string) => ({
										value: group,
										name: this.i18nService.get(`events.actionTypesFilterGroups.${group}`),
									})),
									(value) => value.name
								),
							},
						},
					},
					custom: {
						allowFilterValueTracking: true,
					},
				},
				{
					id: 'initiatingProcessName',
					name: this.i18nService.get('events.fields.initiatingProcessName.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) => this.getInitiatingProcessName(event),
					getTooltip: (event: CyberEvent) => this.getInitiatingProcessName(event),
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 200,
				},
				{
					id: 'initiatingProcessId',
					name: this.i18nService.get('events.fields.initiatingProcessId.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.initiatingProcess
							? event.initiatingProcess.id
							: null,
					sort: { enabled: false },
				},
				{
					id: 'initiatingCommandLine',
					name: this.i18nService.get('events.fields.initiatingCommandLine.title'),
					enabledByDefault: false,
					maxWidth: 400,
					getDisplay: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.initiatingProcess
							? event.initiatingProcess.commandLine
							: null,
					getTooltip: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.initiatingProcess
							? event.initiatingProcess.commandLine
							: null,
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 200,
				},
				{
					id: 'initiatingSha1',
					name: this.i18nService.get('events.fields.initiatingSha1.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.initiatingProcess && event.initiatingProcess.file
							? event.initiatingProcess.file.sha1
							: null,
					getTooltip: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.initiatingProcess && event.initiatingProcess.file
							? event.initiatingProcess.file.sha1
							: null,
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 100,
				},
				{
					id: 'initiatingPath',
					name: this.i18nService.get('events.fields.initiatingPath.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.initiatingProcess && event.initiatingProcess.file
							? event.initiatingProcess.file.folderPath
							: null,
					getTooltip: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.initiatingProcess && event.initiatingProcess.file
							? event.initiatingProcess.file.folderPath
							: null,
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 200,
				},
				{
					id: 'initiatingUserDomain',
					name: this.i18nService.get('events.fields.initiatingUserDomain.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) =>
						event.isNotAlert
							? event.initiatingUser && event.initiatingUser.accountDomainName
							: null,
					getTooltip: (event: CyberEvent) =>
						event.isNotAlert
							? event.initiatingUser && event.initiatingUser.accountDomainName
							: null,
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 200,
				},
				{
					id: 'targetProcessFileName',
					name: this.i18nService.get('events.fields.targetProcessFileName.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) => this.getTargetProcessOrFileName(event),
					getTooltip: (event: CyberEvent) => this.getTargetProcessOrFileName(event),
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 200,
				},
				{
					id: 'targetProcessId',
					name: this.i18nService.get('events.fields.targetProcessId.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.process ? event.process.id : null,
					sort: { enabled: false },
				},
				{
					id: 'targetCommandLine',
					name: this.i18nService.get('events.fields.targetCommandLine.title'),
					enabledByDefault: false,
					maxWidth: 400,
					getDisplay: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.process ? event.process.commandLine : null,
					getTooltip: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.process ? event.process.commandLine : null,
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 200,
				},
				{
					id: 'targetEndpoint',
					name: this.i18nService.get('events.fields.targetEndpoint.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.remoteEndpoint
							? this.globalEntityTypesService
									.getEntityType<NetworkEndpoint>(NetworkEndpoint)
									.getEntityName(event.remoteEndpoint)
							: null,
					getTooltip: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.remoteEndpoint
							? this.globalEntityTypesService
									.getEntityType<NetworkEndpoint>(NetworkEndpoint)
									.getEntityName(event.remoteEndpoint)
							: null,
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 200,
				},
				{
					id: 'targetPort',
					name: this.i18nService.get('events.fields.targetPort.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.remoteEndpoint ? event.remoteEndpoint.port : null,
					sort: { enabled: false },
				},
				{
					id: 'targetProtocol',
					name: this.i18nService.get('events.fields.targetProtocol.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) =>
						event.isUnknownOrNotAlert && event.remoteEndpoint
							? event.remoteEndpoint.protocol
							: null,
					sort: { enabled: false },
				},
				{
					id: 'targetSha1',
					name: this.i18nService.get('events.fields.targetSha1.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) => this.getTargetProcessOrFileSha1(event),
					getTooltip: (event: CyberEvent) => this.getTargetProcessOrFileSha1(event),
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 100,
				},
				{
					id: 'targetPath',
					name: this.i18nService.get('events.fields.targetPath.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) =>
						event.isUnknownOrNotAlert
							? event.process && event.process.file
								? event.process.file.folderPath
								: event.file
								? event.file.folderPath
								: event.registryModificationDetails.current
								? event.registryModificationDetails.current.key
								: null
							: null,
					getTooltip: (event: CyberEvent) =>
						event.isUnknownOrNotAlert
							? event.process && event.process.file
								? event.process.file.folderPath
								: event.file
								? event.file.folderPath
								: event.registryModificationDetails.current
								? event.registryModificationDetails.current.key
								: null
							: null,
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 200,
				},
				{
					id: 'targetUser',
					name: this.i18nService.get('events.fields.targetUser.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) =>
						event.isNotAlert ? event.user && event.user.accountName : null,
					getTooltip: (event: CyberEvent) =>
						event.isNotAlert ? event.user && event.user.accountName : null,
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 150,
				},
				{
					id: 'targetUserDomain',
					name: this.i18nService.get('events.fields.targetUserDomain.title'),
					enabledByDefault: false,
					getDisplay: (event: CyberEvent) =>
						event.isNotAlert ? event.user && event.user.accountDomainName : null,
					getTooltip: (event: CyberEvent) =>
						event.isNotAlert ? event.user && event.user.accountDomainName : null,
					sort: { enabled: false },
					fluidWidth: 0.4,
					minWidth: 200,
				},
				{
					id: 'markedEventsOnly',
					name: '',
					filterOnly: true,
					filter: {
						priority: 1,
						requiresData: false,
						disableFilterSectionCollapse: true,
						component: {
							type: FilterValuesToggleComponent,
							config: {
								trueLabel: this.i18nService.get('events.markedEvents.filterText'),
								falseLabel: this.i18nService.get('events.markedEvents.filterText'),
							},
						},
					},
					custom: {
						allowFilterValueTracking: true,
					},
				},
			]);
		}

		return this._fields;
	}

	private getEntitiesList(event: CyberEvent): string | null {
		return this.cyberEventsActionTypesService
			.getEventEntities(event)
			.map((item) => item.name)
			.join(' > ');
	}

	private getInitiatingProcessName(event: CyberEvent): string | null {
		return event.isUnknownOrNotAlert && event.initiatingProcess
			? this.globalEntityTypesService
					.getEntityType<Process>(Process)
					.getEntityName(event.initiatingProcess)
			: null;
	}

	private getTargetProcessOrFileName(event: CyberEvent): string | null {
		return event.isUnknownOrNotAlert
			? event.process
				? this.globalEntityTypesService.getEntityType<Process>(Process).getEntityName(event.process)
				: event.file
				? this.globalEntityTypesService
						.getEntityType<FileInstance>(FileInstance)
						.getEntityName(event.file)
				: null
			: null;
	}

	private getTargetProcessOrFileSha1(event: CyberEvent): string | null {
		return event.isUnknownOrNotAlert
			? event.process && event.process.file
				? event.process.file.sha1
				: event.file
				? event.file.sha1
				: null
			: null;
	}
}
