import { Entity, EntityField } from '@microsoft/paris';
import { Process } from '..';

@Entity({
	singularName: 'Process alert evidence',
	pluralName: '',
	readonly: true,
	endpoint: `files`,
})
export class ProcessAlertEvidence extends Process {
	@EntityField() relatedAlertIds: string[];

	get uniqueEvidenceId(): string {
		if (this.uniqueEntityId && this.uniqueEntityId) {
			const relatedAlertsIdsString = this.relatedAlertIds.sort().toString();
			return this.uniqueEntityId.concat(relatedAlertsIdsString);
		}
		return null;
	}

	entityType = 'process';
}
