export enum CyberEventActionTypeName {
	Alert = 'Alert',
	AmsiScriptDetection = 'AmsiScriptDetection',
	AntivirusDetection = 'AntivirusDetection',
	AntivirusReport = 'AntivirusReport',
	AntivirusScanCancelled = 'AntivirusScanCancelled',
	AntivirusScanCompleted = 'AntivirusScanCompleted',
	AntivirusScanFailed = 'AntivirusScanFailed',
	AntivirusScanResponse = 'AntivirusScanResponse',
	AppControlAppInstallationBlocked = 'AppControlAppInstallationBlocked',
	AppControlCodeIntegrityDriverRevoked = 'AppControlCodeIntegrityDriverRevoked',
	AppControlCodeIntegrityImageAudited = 'AppControlCodeIntegrityImageAudited',
	AppControlCodeIntegrityImageRevoked = 'AppControlCodeIntegrityImageRevoked',
	AppControlCodeIntegrityPolicyAudited = 'AppControlCodeIntegrityPolicyAudited',
	AppControlCodeIntegrityPolicyBlocked = 'AppControlCodeIntegrityPolicyBlocked',
	AppControlExecutableAudited = 'AppControlExecutableAudited',
	AppControlExecutableBlocked = 'AppControlExecutableBlocked',
	AppControlPackagedAppAudited = 'AppControlPackagedAppAudited',
	AppControlPackagedAppBlocked = 'AppControlPackagedAppBlocked',
	AppControlScriptAudited = 'AppControlScriptAudited',
	AppControlScriptBlocked = 'AppControlScriptBlocked',
	AppGuardBrowseToUrl = 'AppGuardBrowseToUrl',
	AppGuardCreateContainer = 'AppGuardCreateContainer',
	AppGuardLaunchedWithUrl = 'AppGuardLaunchedWithUrl',
	AppGuardResumeContainer = 'AppGuardResumeContainer',
	AppGuardStopContainer = 'AppGuardStopContainer',
	AppGuardSuspendContainer = 'AppGuardSuspendContainer',
	BrowserLaunchedToOpenUrl = 'BrowserLaunchedToOpenUrl',
	CollectInvestigationPackageResponse = 'CollectInvestigationPackageResponse',
	ConnectionFailed = 'ConnectionFailed',
	InboundConnectionRejected = 'InboundConnectionRejected',
	ConnectionFound = 'ConnectionFound',
	InboundConnectionFound = 'InboundConnectionFound',
	ConnectionObserved = 'ConnectionObserved',
	ConnectionRequest = 'ConnectionRequest',
	ConnectionSuccess = 'ConnectionSuccess',
	ConnectionAttempt = 'ConnectionAttempt',
	InboundConnectionAttempt = 'InboundConnectionAttempt',
	ConnectionAcknowledged = 'ConnectionAcknowledged',
	InboundConnectionAcknowledged = 'InboundConnectionAcknowledged',
	CreateRemoteThreadApiCall = 'CreateRemoteThreadApiCall',
	ExploitGuardAcgAudited = 'ExploitGuardAcgAudited',
	ExploitGuardAcgEnforced = 'ExploitGuardAcgEnforced',
	ExploitGuardAsrAudited = 'ExploitGuardAsrAudited',
	ExploitGuardAsrBlocked = 'ExploitGuardAsrBlocked',
	ExploitGuardChildProcessAudited = 'ExploitGuardChildProcessAudited',
	ExploitGuardChildProcessBlocked = 'ExploitGuardChildProcessBlocked',
	ExploitGuardEafViolationAudited = 'ExploitGuardEafViolationAudited',
	ExploitGuardEafViolationBlocked = 'ExploitGuardEafViolationBlocked',
	ExploitGuardIafViolationAudited = 'ExploitGuardIafViolationAudited',
	ExploitGuardIafViolationBlocked = 'ExploitGuardIafViolationBlocked',
	ExploitGuardLowIntegrityImageAudited = 'ExploitGuardLowIntegrityImageAudited',
	ExploitGuardLowIntegrityImageBlocked = 'ExploitGuardLowIntegrityImageBlocked',
	ExploitGuardNetworkProtectionAudited = 'ExploitGuardNetworkProtectionAudited',
	ExploitGuardNetworkProtectionBlocked = 'ExploitGuardNetworkProtectionBlocked',
	ExploitGuardNonMicrosoftSignedAudited = 'ExploitGuardNonMicrosoftSignedAudited',
	ExploitGuardNonMicrosoftSignedBlocked = 'ExploitGuardNonMicrosoftSignedBlocked',
	ExploitGuardRopExploitAudited = 'ExploitGuardRopExploitAudited',
	ExploitGuardRopExploitBlocked = 'ExploitGuardRopExploitBlocked',
	ExploitGuardSharedBinaryAudited = 'ExploitGuardSharedBinaryAudited',
	ExploitGuardSharedBinaryBlocked = 'ExploitGuardSharedBinaryBlocked',
	ExploitGuardWin32SystemCallAudited = 'ExploitGuardWin32SystemCallAudited',
	ExploitGuardWin32SystemCallBlocked = 'ExploitGuardWin32SystemCallBlocked',
	FileCreated = 'FileCreated',
	FileModified = 'FileModified',
	FileRenamed = 'FileRenamed',
	FirewallInboundConnectionBlocked = 'FirewallInboundConnectionBlocked',
	FirewallInboundConnectionToAppBlocked = 'FirewallInboundConnectionToAppBlocked',
	FirewallOutboundConnectionBlocked = 'FirewallOutboundConnectionBlocked',
	FirewallServiceStopped = 'FirewallServiceStopped',
	HuntingRecord = 'HuntingRecord',
	ImageLoaded = 'ImageLoaded',
	InboundConnectionAccepted = 'InboundConnectionAccepted',
	IsolateResponse = 'IsolateResponse',
	LogonFailed = 'LogonFailed',
	LogonSuccess = 'LogonSuccess',
	NtAllocateVirtualMemoryApiCall = 'NtAllocateVirtualMemoryApiCall',
	NtAllocateVirtualMemoryRemoteApiCall = 'NtAllocateVirtualMemoryRemoteApiCall',
	NtMapViewOfSectionRemoteApiCall = 'NtMapViewOfSectionRemoteApiCall',
	NtProtectVirtualMemoryApiCall = 'NtProtectVirtualMemoryApiCall',
	OneCyber = 'OneCyber',
	OpenProcessApiCall = 'OpenProcessApiCall',
	OtherAlertRelatedActivity = 'OtherAlertRelatedActivity',
	PasswordChangeAttempt = 'PasswordChangeAttempt',
	PowerShellCommand = 'PowerShellCommand',
	ProcessCreated = 'ProcessCreated',
	ProcessCreatedUsingWmiQuery = 'ProcessCreatedUsingWmiQuery',
	ProcessPrimaryTokenModified = 'ProcessPrimaryTokenModified',
	QueueUserApcRemoteApiCall = 'QueueUserApcRemoteApiCall',
	RegistryKeyCreated = 'RegistryKeyCreated',
	RegistryKeyDeleted = 'RegistryKeyDeleted',
	RegistryKeyRenamed = 'RegistryKeyRenamed',
	RegistryValueDeleted = 'RegistryValueDeleted',
	RegistryValueSet = 'RegistryValueSet',
	RemediationResponse = 'RemediationResponse',
	ResponseAction = 'ResponseAction',
	RestrictAppExecutionResponse = 'RestrictAppExecutionResponse',
	ScheduledTaskCreated = 'ScheduledTaskCreated',
	ScheduledTaskDeleted = 'ScheduledTaskDeleted',
	ScheduledTaskDisabled = 'ScheduledTaskDisabled',
	ScheduledTaskEnabled = 'ScheduledTaskEnabled',
	ScreenshotTaken = 'ScreenshotTaken',
	SetThreadContextRemoteApiCall = 'SetThreadContextRemoteApiCall',
	ShellLinkCreateFileEvent = 'ShellLinkCreateFileEvent',
	SmartScreenAppWarning = 'SmartScreenAppWarning',
	SmartScreenExploitWarning = 'SmartScreenExploitWarning',
	SmartScreenUrlWarning = 'SmartScreenUrlWarning',
	SmartScreenUserOverride = 'SmartScreenUserOverride',
	UsbDriveDriveLetterChanged = 'UsbDriveDriveLetterChanged',
	UsbDriveMount = 'UsbDriveMount',
	UsbDriveUnmount = 'UsbDriveUnmount',
	UsbDriveMounted = 'UsbDriveMounted',
	UsbDriveUnmounted = 'UsbDriveUnmounted',
	UserAccountCreated = 'UserAccountCreated',
}
