import { ChangeDetectorRef, Component } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { HuntingRule } from '@wcd/domain';
import { DialogsService } from '../../../dialogs/services/dialogs.service';

export interface HuntingRuleEntityPanelOptions {
	/**
	 * When editing the query text, contains the edited text. This text will be saved by the "Edit rule" action.
	 * @default undefined
	 */
	editedQueryText?: string;

	/**
	 * A callback that will be called when a hunting rule is saved from the panel
	 * @default undefined
	 */
	huntingRuleSaved?: (rule: HuntingRule) => void;
}

@Component({
	selector: 'scheduled-hunting-entity-panel',
	template: `
		<div class="wcd-full-height">
			<section class="wcd-padding-vertical">
				<scheduled-hunting-entity-details [entity]="rule"></scheduled-hunting-entity-details>
			</section>
		</div>
	`,
})
export class ScheduledHuntingEntityPanelComponent extends EntityPanelComponentBase<
	HuntingRule,
	HuntingRuleEntityPanelOptions
> {
	get rule(): HuntingRule {
		return this.entity;
	}

	constructor(private dialogsService: DialogsService, changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}
}
