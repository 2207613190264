import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { tenantContextCache } from '@wcd/auth';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class SccNotOnboardedGuard implements CanActivate {
	constructor() {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (tenantContextCache.appConfig.IsOnboardingComplete && tenantContextCache.hasMtpConsent) {
			sccHostService.state.go('homepage');
			return false;
		}

		return true;
	}
}
