var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Alert } from '../alert.entity';
import { IncidentLinkedByEntity } from './incident-linked-by-entity.value-object';
import { convertImpactedEntitiesV3ToLegacy } from '../alert.utils';
import { detectionSourceValues } from '../sources/detection-source.entity.values';
import { DetectionSourceType } from '../sources/detection-source.enum';
import { severityValues } from '../../severity/severity.entity.values';
var ɵ0 = function (data) { return data.AlertDisplayName ? new Alert({
    id: data.AlertId,
    name: data.AlertDisplayName,
    actor: data.ActorName,
    detectionSource: detectionSourceValues.find(function (value) { return value.id === DetectionSourceType[data.DetectionSource]; }),
    severity: severityValues.find(function (value) { return value.name === data.Severity; }),
    impactedEntities: convertImpactedEntitiesV3ToLegacy(data.ImpactedEntities, data.ImpactedEntitiesSummary),
    firstEventTime: new Date(data.StartTimeUtc),
    lastEventTime: new Date(data.EndTimeUtc),
    firstSeen: new Date(data.TimeGenerated),
}) : data; };
var LinkedEntityAlert = /** @class */ (function (_super) {
    __extends(LinkedEntityAlert, _super);
    function LinkedEntityAlert() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'Alert',
            parse: ɵ0,
        }),
        __metadata("design:type", Alert)
    ], LinkedEntityAlert.prototype, "alert", void 0);
    __decorate([
        EntityField({
            data: 'Entity',
        }),
        __metadata("design:type", IncidentLinkedByEntity)
    ], LinkedEntityAlert.prototype, "entity", void 0);
    LinkedEntityAlert = __decorate([
        ValueObject({
            singularName: 'Linked entity alert',
            pluralName: 'Linked entity alerts',
            readonly: true,
        })
    ], LinkedEntityAlert);
    return LinkedEntityAlert;
}(ModelBase));
export { LinkedEntityAlert };
export { ɵ0 };
