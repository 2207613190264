export enum MitreCategories {
	InitialAccess = 'InitialAccess',
	Execution = 'Execution',
	Persistence = 'Persistence',
	PrivilegeEscalation = 'PrivilegeEscalation',
	DefenseEvasion = 'DefenseEvasion',
	CredentialAccess = 'CredentialAccess',
	Discovery = 'Discovery',
	LateralMovement = 'LateralMovement',
	Collection = 'Collection',
	CommandAndControl = 'CommandAndControl',
	Exfiltration = 'Exfiltration',
	Impact = 'Impact',
}

export enum OtherCategories {
	Backdoor = 'Backdoor',
	CredentialStealing = 'CredentialStealing',
	CredentialTheft = 'CredentialTheft',
	Delivery = 'Delivery',
	DocumentExploit = 'DocumentExploit',
	Exploit = 'Exploit',
	ExplorationEnumeration = 'ExplorationEnumeration',
	General = 'General',
	Installation = 'Installation',
	Malware = 'Malware',
	MalwareDownload = 'MalwareDownload',
	NetworkPropagation = 'NetworkPropagation',
	NotApplicable = 'NotApplicable',
	Ransomware = 'Ransomware',
	Reconnaissance = 'Reconnaissance',
	SocialEngineering = 'SocialEngineering',
	SuspiciousActivity = 'SuspiciousActivity',
	SuspiciousNetworkTraffic = 'SuspiciousNetworkTraffic',
	TrojanDownloader = 'TrojanDownloader',
	UnwantedSoftware = 'UnwantedSoftware',
	WebExploit = 'WebExploit',
}
