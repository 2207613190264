var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, EntityModelBase } from '@microsoft/paris';
import { GuidedHuntingQuery } from './guided-query/guided-hunting-query.value-object';
var ɵ0 = function (itemData) {
    return itemData ? { Filters: itemData.filters, FilteredViews: itemData.views } : null;
};
var HuntingQueryBase = /** @class */ (function (_super) {
    __extends(HuntingQueryBase, _super);
    function HuntingQueryBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ serialize: false }),
        __metadata("design:type", String)
    ], HuntingQueryBase.prototype, "kind", void 0);
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", Number)
    ], HuntingQueryBase.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], HuntingQueryBase.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'QueryText' }),
        __metadata("design:type", String)
    ], HuntingQueryBase.prototype, "text", void 0);
    __decorate([
        EntityField({ data: 'Path' }),
        __metadata("design:type", String)
    ], HuntingQueryBase.prototype, "path", void 0);
    __decorate([
        EntityField({ data: 'UserId', serialize: false }),
        __metadata("design:type", String)
    ], HuntingQueryBase.prototype, "userId", void 0);
    __decorate([
        EntityField({ data: 'IsReadOnly', defaultValue: false, serialize: false }),
        __metadata("design:type", Boolean)
    ], HuntingQueryBase.prototype, "readonly", void 0);
    __decorate([
        EntityField({ data: 'IsGuided', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], HuntingQueryBase.prototype, "isGuided", void 0);
    __decorate([
        EntityField({
            data: 'GuidedQuery',
            serialize: ɵ0,
        }),
        __metadata("design:type", GuidedHuntingQuery)
    ], HuntingQueryBase.prototype, "guidedQuery", void 0);
    __decorate([
        EntityField({ data: 'Shared' }),
        __metadata("design:type", Boolean)
    ], HuntingQueryBase.prototype, "shared", void 0);
    __decorate([
        EntityField({ data: 'IsScheduled' }),
        __metadata("design:type", Boolean)
    ], HuntingQueryBase.prototype, "isScheduled", void 0);
    return HuntingQueryBase;
}(EntityModelBase));
export { HuntingQueryBase };
export { ɵ0 };
