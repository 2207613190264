<!-- tslint:disable:template-click-events-have-key-events -->
<wcd-collapsible-section
	[label]="i18nService.strings.investigationActions_panel_summary"
	[sectionId]="'investigation-action-panel-action-Summary'"
>
	<dl class="key-values">
		<dt>{{ i18nService.strings.investigationActions_fields_status }}</dt>
		<dd>
			<div
				class="wcd-padding-small-left"
				[ngClass]="'color-border-' + investigationAction.status.className"
			>
				{{ investigationAction.status.name }} -
				{{ 'investigationActions.status.' + investigationAction.status.type + '.description' | i18n }}
			</div>
		</dd>
		<ng-container *ngIf="machine">
			<dt>{{ i18nService.strings.machine }}</dt>
			<dd>
				<entity-name [entityTypeId]="Machine" [entity]="machine"></entity-name>
			</dd>
		</ng-container>
		<ng-container *ngIf="investigationAction.machine?.ip">
			<dt>{{ i18nService.strings.investigationActions_panel_ip }}</dt>
			<dd>{{ investigationAction.machine.ip }}</dd>
		</ng-container>
		<dt>{{ i18nService.strings.investigationActions_panel_created }}</dt>
		<dd>{{ investigationAction.created | date: 'medium' }}</dd>
		<ng-container *ngIf="investigationAction.started">
			<dt>{{ i18nService.strings.investigationActions_panel_executionStart }}</dt>
			<dd>{{ investigationAction.started | date: 'medium' }}</dd>
		</ng-container>
		<ng-container *ngIf="investigationAction.duration">
			<dt>{{ i18nService.strings.investigationActions_panel_duration }}</dt>
			<dd>
				<stopwatch
					[start]="investigationAction.started"
					[end]="investigationAction.ended"
					[prettify]="true"
				></stopwatch>
			</dd>
		</ng-container>
		<ng-container *ngIf="investigationAction.pendingDuration">
			<dt>{{ i18nService.strings.investigationActions_panel_pendingDuration }}</dt>
			<dd>{{ investigationAction.pendingDuration | prettyTime }}</dd>
		</ng-container>
		<dt>{{ i18nService.strings.common_description }}</dt>
		<dd [class.subtle]="!actionDetails">
			{{ actionDetails || i18nService.strings.notAvailable_short }}
		</dd>
	</dl>
</wcd-collapsible-section>
<section
	class="wcd-padding-vertical wcd-padding-large-horizontal"
	*ngIf="investigationAction.dataRetentionApplied; else extraData"
>
	<div class="wcd-padding-small-all color-box-malware">
		<wcd-shared-icon [iconName]="'warning'"> </wcd-shared-icon>
		{{ i18nService.strings.investigationActions_dataRetention_message }}
		<wcd-help [text]="i18nService.strings.investigationActions_dataRetention_description"></wcd-help>
	</div>
</section>
<ng-template #extraData>
	<wcd-collapsible-section
		*ngIf="investigationAction.extraData.input || investigationAction.extraData.inputSummary"
		id="investigation-action-input-data-section"
		[sectionId]="'investigation-action-input-data-section'"
		[label]="i18nService.strings.investigationActions_panel_inputData"
	>
		<p *ngIf="investigationAction.extraData.inputSummary">
			{{ investigationAction.extraData.inputSummary }}
		</p>
		<pre
			*ngIf="investigationAction.extraData.input"
			class="code wcd-full-width">{{investigationAction.extraData.input}}<div
			*ngIf="investigationAction.extraData.inputIsPartial">{{i18nService.strings.common_ellipsis}}</div></pre>
		<div>
			<a
				*ngIf="investigationAction.extraData.inputIsPartial"
				data-track-id="OpenInput"
				data-track-type="Navigation"
				(click)="openInput()"
			>
				<wcd-shared-icon iconName="popOut" class="small-icon"> </wcd-shared-icon>
				{{ i18nService.strings.investigationActions_panel_viewInputData }}
			</a>
		</div>
	</wcd-collapsible-section>
	<wcd-collapsible-section
		*ngIf="investigationAction.extraData.output || investigationAction.extraData.outputSummary"
		id="investigation-action-output-data-section"
		[sectionId]="'investigation-action-output-data-section'"
		[label]="i18nService.strings.investigationActions_panel_outputData"
	>
		<p *ngIf="investigationAction.extraData.outputSummary">
			{{ investigationAction.extraData.outputSummary }}
		</p>
		<pre *ngIf="investigationAction.extraData.output" class="code wcd-full-width">{{
			investigationAction.extraData.output
		}}</pre>
		<div>
			<a
				*ngIf="investigationAction.extraData.outputIsPartial"
				data-track-id="OpenOutput"
				data-track-type="Navigation"
				(click)="openOutput()"
			>
				<wcd-shared-icon iconName="popOut" class="small-icon"> </wcd-shared-icon>
				{{ i18nService.strings.investigationActions_panel_viewOutputData }}
			</a>
		</div>
	</wcd-collapsible-section>
	<wcd-collapsible-section
		*ngIf="investigationAction.extraData.technicalInformation"
		id="investigation-action-technical-information-section"
		[sectionId]="'investigation-action-technical-information-section'"
		[label]="i18nService.strings.investigationActions_panel_technicalInfo"
	>
		<pre class="code wcd-full-width">{{ investigationAction.extraData.technicalInformation }}</pre>
	</wcd-collapsible-section>
</ng-template>
<wcd-collapsible-section
	*ngIf="investigationAction.errors.length"
	id="investigation-action-errors-section"
	[sectionId]="'investigation-action-errors-section'"
	[label]="i18nService.strings.common_errors"
>
	<ul class="inline-list with-border unstyled">
		<li *ngFor="let error of investigationAction.errors">
			<div class="wcd-margin-small-bottom bold" *ngIf="error.type">
				{{ error.type }}
			</div>
			<ul class="inline-list">
				<li *ngFor="let errorDetail of error.details">
					{{ errorDetail }}
				</li>
			</ul>
		</li>
	</ul>
</wcd-collapsible-section>
<wcd-collapsible-section
	*ngIf="investigationAction.commentCount"
	[sectionId]="'investigation-action-panel-comments'"
	[label]="i18nService.strings.entityCommon_panel_comments_header"
>
	<editable-comments-list
		*ngLet="getComments() as comments"
		[options]="comments ? null : { investigation_action: investigationAction.id }"
		[comments]="comments"
		defaultCommentIcon="comment"
		[readonly]="true"
	></editable-comments-list>
</wcd-collapsible-section>
