import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import {
	AadUser,
	Alert,
	AadUserAlertsRelationship,
	AadUserAadUserMcasDetailsRelationship,
	AadUserMcasDetails,
	EvidenceAlertsSummary,
} from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { EntityDetailsMode } from '../../../../global_entities/models/entity-details-mode.enum';
import { catchError, startWith, take } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import { EntityType } from '../../../../global_entities/models/entity-type.interface';
import { Observable, of } from 'rxjs';
import { AadUserEntityTypeOptions } from '../aad-user.entity-type.service';

const loadingSymbol: unique symbol = Symbol();
type Loadable<T> = T | typeof loadingSymbol;

@Component({
	selector: 'aad-user-entity-panel',
	templateUrl: './aad-user-entity-panel.component.html',
	styles: [
		`
			img.aaduser-image {
				width: 75px;
				height: 75px;
				max-height: 75px;
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
})
export class AadUserEntityPanelComponent extends EntityPanelComponentBase<AadUser, AadUserEntityTypeOptions> {
	readonly EntityDetailsMode = EntityDetailsMode;
	readonly MaxAlertItems = 5;
	readonly DefaultSortBy: string = 'severity';
	readonly DefaultMaxColumnWidth: number = 250;
	alertEntityType: EntityType<Alert>;
	alertData$: Observable<Loadable<EvidenceAlertsSummary>>;
	loadingSymbol = loadingSymbol;
	alerts: Alert[];
	error: boolean = false;

	get aadUser(): AadUser {
		return this.entity;
	}

	constructor(
		public changeDetectorRef: ChangeDetectorRef,
		globalEntityTypesService: GlobalEntityTypesService,
		private paris: Paris
	) {
		super(changeDetectorRef);
		this.alertEntityType = globalEntityTypesService.getEntityType(Alert);
	}

	ngOnInit() {
		this.alertData$ = this.paris
			.getRelatedItem<AadUser, EvidenceAlertsSummary>(AadUserAlertsRelationship, this.aadUser)
			.pipe(
				startWith(loadingSymbol),
				catchError(err => {
					this.error = true;
					return of(null);
				})
			);
		this.paris
			.getRelatedItem<AadUser, AadUserMcasDetails>(AadUserAadUserMcasDetailsRelationship, this.aadUser)
			.pipe(take(1))
			.subscribe((mcasDetails: AadUserMcasDetails) => {
				this.entity = { ...this.aadUser, ...mcasDetails };
				this.changeDetectorRef.detectChanges();
			});
	}
}
