import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';

@Component({
	selector: 'manage-non-windows-offboarding',
	templateUrl: './manage-non-windows-offboarding.component.html',
})
export class ManageNonWindowsOffboardingComponent {
	macOsOnboardingEnabled: boolean;

	constructor(private featuresService: FeaturesService) {
		this.macOsOnboardingEnabled = featuresService.isEnabled(Feature.MacOsOnboarding);
	}
}
