var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, ChangeDetectorRef, OnInit, } from '@angular/core';
import { VulnerabilityExposedAssetsRelationship, Vulnerability, WeaknessSeverity, TvmEndPoint, VulnerabilityType, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { SpinnerSize } from 'office-ui-fabric-react';
import { TvmWeaknessSeverityService } from '../../../tvm/services/tvm-vulnerability-severity.service';
import { MessageBarType } from 'office-ui-fabric-react';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { I18nService } from '@wcd/i18n';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { FeaturesService, Feature } from '@wcd/config';
import { TvmIconBuilderService } from '../../../tvm/services/tvm-icon-builder.service';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { MaxItemsInCollapseExpandTable } from '../../../tvm/tvm.constants';
var VulnerabilityEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(VulnerabilityEntityDetailsComponent, _super);
    function VulnerabilityEntityDetailsComponent(i18nService, paris, tvmWeaknessSeverityService, changeDetectorRef, sidePanelService, iconBuilderService, tvmTextsService, featuresService, injector, tvmDownloadService) {
        var _this = _super.call(this, injector) || this;
        _this.i18nService = i18nService;
        _this.paris = paris;
        _this.tvmWeaknessSeverityService = tvmWeaknessSeverityService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.sidePanelService = sidePanelService;
        _this.iconBuilderService = iconBuilderService;
        _this.tvmTextsService = tvmTextsService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.MessageBarType = MessageBarType;
        _this.messageBarState = {
            showBody: false,
        };
        _this.SpinnerSize = SpinnerSize;
        _this.isAssetsMinimized = true;
        _this.assetRelationshipRepository = _this.paris.getRelationshipRepository(VulnerabilityExposedAssetsRelationship);
        _this.isNewExploitExperienceEnabled = featuresService.isEnabled(Feature.TvmExploits);
        _this.isDetectionLogicEnabled = featuresService.isEnabled(Feature.TvmDetectionLogic);
        _this.includeOperatingSystem = featuresService.isEnabled(Feature.TvmExposedOperatingSystems);
        _this.zeroDayFeatureEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
        _this.legalNoticeText = i18nService.strings.tvm_vulnerability_legalNotice;
        return _this;
    }
    Object.defineProperty(VulnerabilityEntityDetailsComponent.prototype, "vulnerability", {
        get: function () {
            return this._vulnerability;
        },
        set: function (vulnerability) {
            this.loadNewAssets(vulnerability);
            this.vulnerabilityAge = this.tvmTextsService.getText(TextToken.VulnerabilityAge, vulnerability);
            this.hasOsFeature = vulnerability.osFeatures != null && vulnerability.osFeatures.length > 0;
            this.osFeatureNote = this.i18nService.get('tvm.vulnerability.osFeatureNote', {
                osFeatures: vulnerability.osFeatures.join(', '),
            });
            this.osFeature = this.tvmTextsService.getText(TextToken.VulnerabilityOsFeature, vulnerability);
            this.osFeatureTooltip = this.tvmTextsService.getText(TextToken.OsFeatureTooltip, vulnerability);
            this.relatedSoftware = this.tvmTextsService.getText(TextToken.RelatedSoftware, vulnerability);
            this.softwareTooltip = this.tvmTextsService.getText(TextToken.RelatedSoftwareTooltip, vulnerability);
            this.threatConfigs = this.iconBuilderService.configureIcons(vulnerability.threatInfo, null, 'vulnerability').config;
            this.showZeroDayMessage = this.shouldShowZeroDayMessage(vulnerability);
            this.showZeroDayFormerId = this.shouldShowZeroDayFormerId(vulnerability);
            this._vulnerability = vulnerability;
            this.productNeverMatched = !Number.isInteger(vulnerability.impactedAssetsCount);
            if (vulnerability.cveSources.some(function (sourceInfo) { return sourceInfo.source === 'RedHat'; })) {
                this.legalNoticeText = this.tvmTextsService.getText(TextToken.RedHatLegalNotice);
            }
        },
        enumerable: true,
        configurable: true
    });
    VulnerabilityEntityDetailsComponent.prototype.ngOnInit = function () {
        if (this.isNetworkGearCve || (this.contextOptions && this.contextOptions.isNetworkGear)) {
            this.configureNetworkGearCve();
        }
    };
    Object.defineProperty(VulnerabilityEntityDetailsComponent.prototype, "severityInfo", {
        get: function () {
            var severity = this._vulnerability.severity;
            return [
                WeaknessSeverity.Critical,
                WeaknessSeverity.High,
                WeaknessSeverity.Medium,
                WeaknessSeverity.Low,
            ].includes(severity)
                ? {
                    text: severity,
                    showIcon: true,
                    iconClass: this.tvmWeaknessSeverityService.getWeaknessColorClass(severity),
                }
                : {
                    text: this.i18nService.get('common.noDataAvailable'),
                    showIcon: false,
                    iconClass: null,
                };
        },
        enumerable: true,
        configurable: true
    });
    VulnerabilityEntityDetailsComponent.prototype.openAllExposedMachines = function () {
        this.sidePanelService.showAllExposedAssetsPanel(this._vulnerability, this.isNetworkGearCve);
    };
    VulnerabilityEntityDetailsComponent.prototype.loadNewAssets = function (vulnerability) {
        if (this.includeExposedMachinesComponent === false ||
            (this.contextOptions && this.contextOptions.isSingleAsset)) {
            return;
        }
        var queryOptions = {
            page: 1,
            pageSize: MaxItemsInCollapseExpandTable,
        };
        // TODO: evhvoste add the order by - when the BE will support it
        this.assetRelationshipRepository.sourceItem = vulnerability;
        this.assets$ = this.assetRelationshipRepository.query(queryOptions);
    };
    VulnerabilityEntityDetailsComponent.prototype.getStyles = function (messageBarProps) {
        if (this.messageBarState.showBody !== messageBarProps.expandSingleLine) {
            this.messageBarState.showBody = messageBarProps.expandSingleLine;
            this.changeDetectorRef.detectChanges();
        }
    };
    VulnerabilityEntityDetailsComponent.prototype.exportExposedMachines = function () {
        return this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.assetRelationshipRepository, TvmEndPoint.Analytics, this._vulnerability.id + " - " + this.i18nService.get('tvm.common.exposedDevices'), undefined);
    };
    VulnerabilityEntityDetailsComponent.prototype.configureNetworkGearCve = function () {
        this.exposedAssetsTitle = this.i18nService.get('tvm.common.exposedNetworkDevices');
        this.exposedAssetsLoadingText = this.i18nService.get('tvm.securityRecommendation.loadingExposedNetworkDevices');
        this.isNetworkDevice = true;
        this.includeLastSeenStatus = true;
        this.includeOperatingSystem = false;
    };
    VulnerabilityEntityDetailsComponent.prototype.shouldShowZeroDayMessage = function (vulnerability) {
        return (this.zeroDayFeatureEnabled &&
            vulnerability.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
            !vulnerability.patchReleaseDate);
    };
    VulnerabilityEntityDetailsComponent.prototype.shouldShowZeroDayFormerId = function (vulnerability) {
        return (this.zeroDayFeatureEnabled &&
            vulnerability.formalTemporaryId &&
            vulnerability.formalTemporaryId !== vulnerability.name);
    };
    return VulnerabilityEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { VulnerabilityEntityDetailsComponent };
