import { ChangeDetectorRef, ComponentRef, EventEmitter, OnChanges, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from '../../../shared/services/title.service';
import { Alert, MdeUserRoleActionEnum } from '@wcd/domain';
import { AlertsFields } from '../services/alerts.fields';
import { Paris, Repository } from '@microsoft/paris';
import { isCustomTimeRangeValue } from '@wcd/date-time-picker';
import { BehaviorSubject } from 'rxjs';
import { AlertsService } from '../services/alerts.service';
import { alertTypes, alertTypesArray } from '../models/alert-type.model';
import { Feature, FeaturesService, PreferencesService } from '@wcd/config';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { AuthService } from '@wcd/auth';
import { DataViewComponent } from '../../../dataviews/components/dataview.component';
import { cloneDeep, compact, find } from 'lodash-es';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { take } from 'rxjs/operators';
import { Lazy } from '@wcd/utils';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { AppConfigService } from '@wcd/app-config';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { AlertsDataviewPanelComponent } from './alerts.dataview-panel';
import { I18nService } from '@wcd/i18n';
import { DataviewActionTypes, } from '../../../dataviews/components/actions-components/dataview-actions.model';
import { FabricIconNames } from '@wcd/scc-common';
var TIME_RANGE_DEFAULT_PREFERENCE_ID = 'alerts_time_range_default';
var AlertsDataviewComponent = /** @class */ (function () {
    function AlertsDataviewComponent(paris, alertFields, alertsService, titleService, authService, router, preferencesService, route, globalEntityTypesService, timeRangesService, rbacService, appConfigService, dialogsService, changeDetectorRef, featuresService, i18nService) {
        var _this = this;
        this.paris = paris;
        this.alertFields = alertFields;
        this.alertsService = alertsService;
        this.titleService = titleService;
        this.authService = authService;
        this.router = router;
        this.preferencesService = preferencesService;
        this.route = route;
        this.timeRangesService = timeRangesService;
        this.rbacService = rbacService;
        this.appConfigService = appConfigService;
        this.dialogsService = dialogsService;
        this.changeDetectorRef = changeDetectorRef;
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.responsiveLayout = false; // see dataview.component.ts for usage
        this.allowPaging = true;
        this.allowFilters = true;
        this.allowGrouping = true;
        this.allowTimeRangeSelect = true;
        this.hideControls = false;
        this.disableSelection = false;
        this.showHeader = true;
        this.showDialogOnError = true;
        this.defaultSortId = 'lasteventtime';
        this.queueHeader = true;
        this.focusOnTable = true;
        this.responsiveActionBar = false;
        this.customActionsRight = false;
        this.removePadding = true;
        this.removePaddingRight = false;
        this.removePaddingLeftOnResize = false;
        this.fullHeight = true;
        this.onData = new EventEmitter();
        this.onTableRenderComplete = new EventEmitter();
        this.dataviewHorizontalPadding = true;
        this.alertTypes = alertTypesArray;
        this.commandBarRight = [];
        this.wasTableInit = false;
        this.isFirstElementBeenFocused = false;
        this.isFirstInitDone = false;
        this.promotionBannerFeatureEnabled = false;
        this._timeRanges = new Lazy(function () {
            return _this.timeRangesService.pick([
                "day" /* day */,
                "3days" /* '3days' */,
                "week" /* week */,
                "month" /* month */,
                "6months" /* '6months' */,
            ]);
        });
        this.microsoft365DefenderPromotionToIncidentsQueueLink = {
            id: 'Microsoft_365_Defender_Promotion_To_Incidents_Queue',
            buttonTextKey: 'alerts_queue_m365_incidents_queue_promotion_banner_link_title',
            navigateTo: 'https://security.microsoft.com/incidents'
        };
        this.entityType = globalEntityTypesService.getEntityType(Alert);
        this.boundGetContainedAlerts = this.getContainedAlerts.bind(this);
        this.boundSetItemActions = this.setItemActions.bind(this);
        this.boundFormatVal = this.formatVal.bind(this);
        this.promotionBannerFeatureEnabled = this.featuresService.isEnabled(Feature.ShowAlertsQueueBannerForM365Incidents);
    }
    Object.defineProperty(AlertsDataviewComponent.prototype, "timeRanges", {
        get: function () {
            return this._timeRanges.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertsDataviewComponent.prototype, "isUserAllowedActions", {
        get: function () {
            return this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.alertsInvestigation);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertsDataviewComponent.prototype, "dataViewConfig", {
        get: function () {
            return this._dataViewConfig;
        },
        set: function (value) {
            this._originalDataViewConfig = cloneDeep(value);
            this._dataViewConfig = this.getDataViewConfig();
        },
        enumerable: true,
        configurable: true
    });
    AlertsDataviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.repository)
            this.repository = this.paris.getRepository(Alert);
        this.alertTypePreferenceId = (this.dataViewId || 'alerts') + "_alertsListType";
        var currPreference = this.preferencesService.getPreference(this.alertTypePreferenceId);
        this.alertType =
            alertTypes[currPreference
                ? currPreference
                : this.allowGrouping
                    ? alertTypes.grouped.type
                    : alertTypes.flat.type];
        this.currentTimeRange = this.appConfigService.isDemoTenant
            ? this.timeRanges[this.timeRanges.length - 1]
            : this.timeRanges.find(function (timeRange) {
                return timeRange.id ===
                    _this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID);
            }) || this.timeRanges[3];
        this.currentRangeSubject = new BehaviorSubject(this.currentTimeRange);
        if (this.responsiveActionBar)
            this.setCommandBarRight();
        this.route.queryParams.subscribe(function (params) {
            var locationRange = params.range;
            var locationRangeChange = false;
            if (locationRange) {
                var foundRange = find(_this.timeRanges, function (range) { return range.id === locationRange; });
                locationRangeChange = foundRange !== _this.currentTimeRange;
                if (locationRangeChange && foundRange)
                    _this.currentTimeRange = foundRange;
            }
            if (locationRangeChange) {
                _this.setFixedOptions();
                _this._dataViewConfig = _this.getDataViewConfig();
            }
        });
        this.setFixedOptions();
        this._dataViewConfig = this.getDataViewConfig();
        // alert fields are affected by rbac status. Load them only when rbac status is known.
        this.rbacService.isFilteringNecessary$.pipe(take(1)).subscribe(function (val) { return _this.setFields(); });
    };
    AlertsDataviewComponent.prototype.ngOnChanges = function (changes) {
        if (changes.fixedOptions) {
            this.setFixedOptions();
        }
    };
    AlertsDataviewComponent.prototype.afterTableInit = function (loading) {
        this.wasTableInit = loading;
        this.checkIfFirstInitDone();
    };
    AlertsDataviewComponent.prototype.afterFirstElementFocused = function () {
        this.isFirstElementBeenFocused = true;
        this.checkIfFirstInitDone();
    };
    /**
     *  focusing on the first interactive element
     *  Fixing bug: 27220333 (https://dev.azure.com/microsoft/OS/_workitems/edit/27220333/)
     */
    AlertsDataviewComponent.prototype.checkIfFirstInitDone = function () {
        if (this.isFirstElementBeenFocused && this.wasTableInit && !this.isFirstInitDone) {
            this.isFirstInitDone = true;
        }
    };
    AlertsDataviewComponent.prototype.updateTableHeaders = function () {
        if (this.dataView) {
            this.dataView.updateHeaders();
        }
    };
    AlertsDataviewComponent.prototype.formatVal = function (value) {
        return this.i18nService.get("alert.types." + value.id);
    };
    AlertsDataviewComponent.prototype.setFields = function () {
        var _this = this;
        if (this.alertType.isGrouped) {
            this.fields = this.alertFields.fields;
        }
        else {
            this.fields = this.alertFields.fields.filter(function (field) { return field.id !== 'expand'; });
        }
        if (this.onlyFields) {
            this.fields = this.fields.filter(function (f) { return _this.onlyFields.includes(f.id); });
        }
        if (this.maxColumnWidth) {
            this.fields = this.fields.map(function (field) {
                return field.clone({ maxWidth: field.maxWidth || _this.maxColumnWidth });
            });
        }
        this.changeDetectorRef.markForCheck();
    };
    AlertsDataviewComponent.prototype.isAlertSelectable = function (alert) {
        return alert.id;
    };
    AlertsDataviewComponent.prototype.isGroup = function (alert) {
        return alert.isGroup;
    };
    AlertsDataviewComponent.prototype.onAlertTypeChange = function () {
        this.setFixedOptions();
        this.preferencesService.setPreference(this.alertTypePreferenceId, this.alertType.type);
        this.setFields();
    };
    AlertsDataviewComponent.prototype.onRangeTypeSelect = function (value) {
        this.currentTimeRange = value;
        if (this.currentTimeRange)
            this.preferencesService.setPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID, this.currentTimeRange.id);
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                page: null,
                range: this.currentTimeRange.id,
            },
            queryParamsHandling: 'merge',
        });
        this.setFixedOptions();
    };
    AlertsDataviewComponent.prototype.setFixedOptions = function () {
        this.dataviewFixedOptions = Object.assign({
            alertType: this.allowGrouping ? this.alertType.id : alertTypes.flat.id,
            lookBackInDays: this.allowTimeRangeSelect && !isCustomTimeRangeValue(this.currentTimeRange)
                ? this.currentTimeRange.value
                : 180,
        }, this.fixedOptions);
    };
    AlertsDataviewComponent.prototype.getContainedAlerts = function (alert, options) {
        return this.alertsService.getContainedAlerts(alert, options);
    };
    AlertsDataviewComponent.prototype.getDataViewConfig = function () {
        var _this = this;
        if (this._originalDataViewConfig) {
            var dataViewConfig = cloneDeep(this._originalDataViewConfig);
            return dataViewConfig;
        }
        else {
            return {
                getFiltersData: function () { return _this.alertsService.getAlertsFilters(); },
            };
        }
    };
    AlertsDataviewComponent.prototype.setItemActions = function (selectedAlerts) {
        var _this = this;
        if (!selectedAlerts || !selectedAlerts.length)
            return [];
        var allAlertsAreAssignedToCurrentUser = selectedAlerts.every(function (alert) { return alert.assignedTo === _this.authService.currentUser.username; });
        return compact([
            allAlertsAreAssignedToCurrentUser || !this.isUserAllowedActions
                ? null
                : {
                    id: 'assignToMe',
                    icon: 'users.userCheck',
                    name: 'Assign to me',
                    method: function (alerts) {
                        return _this.alertsService.assignAlertsToCurrentUser(alerts).toPromise();
                    },
                    tooltip: 'Assign the selected alerts to the logged-in user',
                    refreshOnResolve: true,
                },
        ]).map(function (itemAction) { return new ItemActionModel(itemAction); });
    };
    AlertsDataviewComponent.prototype.onDataIn = function (alertsData) {
        this.alertsData = alertsData.data;
        this.onData.emit(alertsData);
    };
    AlertsDataviewComponent.prototype.onShowMoreAlerts = function () {
        var _this = this;
        this.dialogsService
            .showPanel(AlertsDataviewPanelComponent, {
            id: 'alerts-dataview-panel-dataview',
            type: PanelType.large,
            isModal: true,
            noBodyPadding: true,
            scrollBody: false,
            noShadow: true,
            back: {
                onClick: function () { return _this.alertsSidePanel.destroy(); },
            },
        }, {
            alerts: this.alertsData.items,
            defaultSortBy: this.defaultSortId,
            onlyFields: this.onlyFields,
        })
            .subscribe(function (panel) {
            _this.alertsSidePanel = panel;
            panel.onDestroy(function () {
                _this.alertsSidePanel = null;
            });
        });
    };
    AlertsDataviewComponent.prototype.setCommandBarRight = function () {
        this.commandBarRight = [
            {
                onSelectionChanged: this.onRangeTypeSelect.bind(this),
                currentSelection: this.currentRangeSubject,
                selections: this.timeRanges,
                icon: FabricIconNames.Calendar,
                ariaLabel: this.i18nService.get('dataview.timePeriod'),
                dataTrackId: 'timeRange_IncidentsDataview',
                listId: 'timeRangeSelector',
                focus: this.afterFirstElementFocused.bind(this),
                focusOnInit: !this.isFirstInitDone,
                propertyIdForLabel: 'name',
                actionType: DataviewActionTypes.FancySelect,
            },
        ];
    };
    AlertsDataviewComponent.prototype.onTableRenderCompleteLoaded = function () {
        this.onTableRenderComplete.emit();
    };
    return AlertsDataviewComponent;
}());
export { AlertsDataviewComponent };
