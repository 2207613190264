import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { WebThreatDimensionKeyType } from './web-threat-protection.model';

@ValueObject({
	singularName: 'Web threat protection',
	pluralName: 'Web threat protection',
	readonly: true,
})
export class WebThreatProtectionData extends ModelBase {
	@EntityField() readonly date: Date;

	@EntityField() readonly values: Record<WebThreatDimensionKeyType, number>;
}
