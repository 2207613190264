var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { EntitiesPanelComponentBase } from './entities-panel.component.base';
var EntityPanelComponentBase = /** @class */ (function (_super) {
    __extends(EntityPanelComponentBase, _super);
    function EntityPanelComponentBase(changeDetectorRef) {
        var _this = _super.call(this) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.isNgOnInit = false;
        _this.hasExtendedData = false;
        return _this;
    }
    EntityPanelComponentBase.prototype.ngOnInit = function () {
        this.isNgOnInit = true;
        this.setEntity(this.entity, this.hasExtendedData);
    };
    EntityPanelComponentBase.prototype.setEntity = function (entity, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        var isDifferentEntity = !this.entity || entity.id !== this.entity.id;
        this.entity = entity;
        this.hasExtendedData = isExtendedData;
        if (!this.isNgOnInit)
            return;
        if (this.isInit && isDifferentEntity)
            this.resetExtraDataSubscriptions();
        if (!this.isInit || isDifferentEntity || isExtendedData) {
            this.initEntity(entity, isExtendedData);
            this.isInit = true;
        }
    };
    EntityPanelComponentBase.prototype.initEntity = function (entity, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
    };
    return EntityPanelComponentBase;
}(EntitiesPanelComponentBase));
export { EntityPanelComponentBase };
