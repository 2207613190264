import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { TzDateService } from '@wcd/localization';
import { map } from 'rxjs/operators';
import { FabricIconNames } from '@wcd/scc-common';
import { Feature, FeaturesService } from '@wcd/config';
import { BaselineCompliantBarComponent } from '../../../../../../tvm/components/baseline-compliant-bar/baseline-compliant-bar.component';
import { RbacService } from '../../../../../../rbac/services/rbac.service';
import { FilterValuesRangeSliderComponent } from '@wcd/ng-filters';
var ProfileDeviceFieldsService = /** @class */ (function () {
    function ProfileDeviceFieldsService(tzDateService, i18nService, featuresService, rbacService) {
        var _this = this;
        this.tzDateService = tzDateService;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.rbacGroupIdToNameMap = {};
        this.isDevicesAssessmentEnabled = this.featuresService.isEnabled(Feature.TvmBaselineDevicesAssessment);
        rbacService.userExposedRbacGroups$.pipe(map(function (rbacGroups) {
            var machineGroupNameById = rbacGroups.reduce(function (map, currMachineGroup) {
                map[currMachineGroup.id] = currMachineGroup.name;
                return map;
            }, {});
            return machineGroupNameById;
        })).subscribe(function (machineGroupNameById) {
            _this.rbacGroupIdToNameMap = machineGroupNameById;
        });
    }
    Object.defineProperty(ProfileDeviceFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'name',
                        name: this.i18nService.strings.tvm_common_name,
                        getTooltip: function (device) { return device.name; },
                        icon: {
                            fabricIcon: FabricIconNames.System,
                        },
                        maxWidth: 500,
                    },
                    {
                        id: 'domain',
                        name: this.i18nService.strings.tvm_baseline_profiles_devices_domain_title,
                        getTooltip: function (device) { return device.domain; },
                        maxWidth: 300,
                    },
                    {
                        id: 'compliantConfigurations',
                        name: this.i18nService.strings.tvm_baseline_profiles_devices_deviceCompliance_title,
                        component: {
                            type: BaselineCompliantBarComponent,
                            getProps: function (baselineProfileDevice) { return ({
                                showLegend: false,
                                compliantCount: baselineProfileDevice.compliantConfigurations,
                                notCompliantCount: baselineProfileDevice.applicableConfigurations -
                                    baselineProfileDevice.compliantConfigurations,
                                total: baselineProfileDevice.applicableConfigurations,
                                showPercentage: true,
                                showNumbers: true,
                            }); },
                        },
                        maxWidth: 300,
                        allowResize: false,
                        filter: {
                            component: {
                                type: FilterValuesRangeSliderComponent,
                                config: {
                                    min: 0,
                                    max: 100,
                                    valueFormat: function (value) { return value + "%"; },
                                },
                            },
                        },
                    },
                    {
                        id: 'lastSeen',
                        name: this.i18nService.strings.tvm_baseline_profiles_devices_lastSeen_title,
                        getDisplay: function (device) {
                            return _this.tzDateService.format(device.lastSeen, 'short');
                        },
                        sort: { enabled: false },
                    }
                ].concat(this.isDevicesAssessmentEnabled ? [{
                        id: 'deviceGroup',
                        name: this.i18nService.strings.tvm_baseline_profiles_devices_deviceGroup_title,
                        getDisplay: function (device) { return _this.rbacGroupIdToNameMap[device.rbacGroupId]; },
                        sort: { enabled: false },
                    }] : []));
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileDeviceFieldsService.prototype, "configurationsFields", {
        get: function () {
            var _this = this;
            if (!this._configurationsFields) {
                this._configurationsFields = DataviewField.fromList([
                    {
                        id: 'configurationId',
                        name: this.i18nService.strings.tvm_baseline_profiles_configurations_ID_title,
                        getDisplay: function (configuration) { return configuration.id; },
                        sort: { enabled: false },
                    },
                    {
                        id: 'name',
                        name: this.i18nService.strings.tvm_common_name,
                        getTooltip: function (configuration) { return configuration.name; },
                        sort: { enabled: false },
                        className: 'nowrap wcd-text-overflow-medium',
                    },
                    {
                        id: 'configurationCompliant',
                        name: this.i18nService.strings.tvm_baseline_configuration_compliant_column_name,
                        getDisplay: function (configuration) {
                            return configuration.compliantDevices === 1
                                ? _this.i18nService.strings.common_yes
                                : _this.i18nService.strings.common_no;
                        },
                    },
                ]);
            }
            return this._configurationsFields;
        },
        enumerable: true,
        configurable: true
    });
    return ProfileDeviceFieldsService;
}());
export { ProfileDeviceFieldsService };
