/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i4 from "../../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "../../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i6 from "../../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i10 from "./network-device-entity-status.component";
import * as i11 from "../../../../tvm/services/tvm-vulnerability-severity.service";
import * as i12 from "../../../../tvm/services/tvm-color.service";
var styles_NetworkDeviceEntityStatusComponent = [];
var RenderType_NetworkDeviceEntityStatusComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NetworkDeviceEntityStatusComponent, data: {} });
export { RenderType_NetworkDeviceEntityStatusComponent as RenderType_NetworkDeviceEntityStatusComponent };
function View_NetworkDeviceEntityStatusComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "wcd-shared-icon", [["class", "wcd-margin-xsmall-right"], ["iconName", "StatusCircleInner"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(3, 573440, null, 0, i3.IconComponent, [i4.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n            "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-margin-xsmall-right"; var currVal_1 = _co.getStatusColorClass(_co.networkDevice); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "StatusCircleInner"; _ck(_v, 3, 0, currVal_2); }, null); }
function View_NetworkDeviceEntityStatusComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "span", [["class", "wcd-padding-small-right"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 147456, null, 0, i5.TooltipDirective, [i0.ElementRef, i6.TooltipsService, i7.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i0.ɵppd(4, 1), (_l()(), i0.ɵted(5, null, ["\n                    ", "\n                "])), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(8, 0, null, null, 4, "wcd-shared-icon", [["class", "wcd-font-weight-semibold wcd-margin-xsmall-right"], ["iconName", "warning"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(11, 573440, null, 0, i3.IconComponent, [i4.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "span", [["class", "wcd-font-weight-semibold"]], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["\n                    ", "\n                "])), (_l()(), i0.ɵted(-1, null, ["\n            "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "tvm_network_devices_exposure_level")); _ck(_v, 3, 0, currVal_0); var currVal_2 = "wcd-font-weight-semibold wcd-margin-xsmall-right"; var currVal_3 = _co.getWeaknessColorClass(_co.networkDevice); _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_4 = "warning"; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 0), "tvm_network_devices_exposure_level")); _ck(_v, 5, 0, currVal_1); var currVal_5 = _co.networkDevice.exposureLevel; _ck(_v, 15, 0, currVal_5); }); }
export function View_NetworkDeviceEntityStatusComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i8.I18nPipe, [i9.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "wcd-flex-horizontal wcd-padding-small-top wcd-flex-horizontal-align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NetworkDeviceEntityStatusComponent_1)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "wcd-font-weight-semibold wcd-margin-large-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["\n                ", "\n            "])), (_l()(), i0.ɵted(-1, null, ["\n\n            "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NetworkDeviceEntityStatusComponent_2)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldShowStatusIcon(_co.networkDevice); _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.networkDevice.exposureLevel; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getStatusText(_co.networkDevice); _ck(_v, 8, 0, currVal_1); }); }
export function View_NetworkDeviceEntityStatusComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NetworkDeviceEntityStatusComponent_0, RenderType_NetworkDeviceEntityStatusComponent)), i0.ɵdid(1, 49152, null, 0, i10.NetworkDeviceEntityStatusComponent, [i11.TvmWeaknessSeverityService, i12.TvmColorService, i9.I18nService], null, null)], null, null); }
var NetworkDeviceEntityStatusComponentNgFactory = i0.ɵccf("ng-component", i10.NetworkDeviceEntityStatusComponent, View_NetworkDeviceEntityStatusComponent_Host_0, { entity: "entity" }, {}, []);
export { NetworkDeviceEntityStatusComponentNgFactory as NetworkDeviceEntityStatusComponentNgFactory };
