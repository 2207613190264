import { Entity, EntityField, ModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '@wcd/domain';
const UTC = 'Z';

@Entity({
	singularName: 'Magellan features enabling dates',
	pluralName: 'Magellan features enabling dates',
	endpoint: 'settings/MagellanFeaturesEnablingDates',
	parseItemQuery: () => 'settings/MagellanFeaturesEnablingDates',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
	readonly: true,
})
export class MagellanFeaturesEnablingDates extends ModelBase {
	@EntityField({ data: 'ProactiveDiscoveryEnablingDate', parse: date => date + UTC })
	proactiveDiscoveryEnablingDate: string;
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;
}
