
		<div class="widget-contents-header wcd-flex-horizontal widget-contents-header-top">
			<div class="wcd-flex-1 wcd-flex-center-vertical paddingRight20">
				<div>
					<header class="widget-header" *ngIf="name">
						<h3 [attr.aria-level]="headerLevel">
							<span [id]="headerId || widget.id + '-header'">{{ name }}</span>
							<wcd-help
								*ngIf="help"
								[text]="help"
								[wcdTooltipAllowHtmlRendering]="widget.helpTooltipAllowHtmlRendering"
								[wcdTooltipEnableKeyboardEvents]="true"
								class="wcd-padding-small-left"></wcd-help>
						</h3>
					</header>
				</div>
			</div>
			<div *ngIf="extraHtml" [innerHtml]="extraHtml" class="wcd-flex-1"></div>
			<div
				class="wcd-flex-1 wcd-flex-justify-end wcd-flex-center-vertical widget-timestamp"
				*ngIf="rangeInDays"
			>
				{{ rangeInDays }} {{ 'datetime.day.plural.value' | i18n }}
			</div>
			<div
				class="wcd-flex-1 wcd-flex-justify-end wcd-flex-center-vertical widget-timestamp"
				*ngIf="dateOfWidget"
			>
				{{ dateOfWidget }}
			</div>
			<div
				class="wcd-flex-1 wcd-flex-justify-end wcd-flex-center-vertical widget-timestamp"
				*ngIf="dateRangeOfWidget"
			>
				{{ dateRangeOfWidget }}
			</div>

			<fab-icon-button
				[menuIconProps]="{ iconName: 'MoreVertical' }"
				contentClass="wcd-flex-none noprint"
				data-track-id="WidgetMenuOpen"
				data-track-type="Button"
				*ngIf="widget.menu"
				[menuProps]="{ items: menuItems }"
				[styles]="dropdownMenuStyles"
			>
			</fab-icon-button>
		</div>
	