var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { SecurityRecommendation } from '@wcd/domain';
import { flatMap } from 'lodash-es';
var RecommendationWorkaroundsPanelComponent = /** @class */ (function (_super) {
    __extends(RecommendationWorkaroundsPanelComponent, _super);
    function RecommendationWorkaroundsPanelComponent(router) {
        return _super.call(this, router) || this;
    }
    RecommendationWorkaroundsPanelComponent.prototype.ngOnInit = function () {
        this.workarounds = this.securityRecommendation.workarounds.sort(function (w1, w2) {
            return w1.id.localeCompare(w2.id);
        });
        this.scids = flatMap(this.workarounds, function (workaround) { return workaround.scids; }).filter(function (scid) { return scid; });
        this.showWorkaround = this.securityRecommendation.workarounds.some(function (workaround) { return !!workaround.details; });
    };
    RecommendationWorkaroundsPanelComponent.prototype.openScaRecommendation = function (scid) {
        this.router.navigate(['/security-recommendations', "sca-_-" + scid], {
            queryParams: {
                search: scid,
            },
        });
    };
    return RecommendationWorkaroundsPanelComponent;
}(PanelContainer));
export { RecommendationWorkaroundsPanelComponent };
