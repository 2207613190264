import { OnDestroy, OnInit } from '@angular/core';
import { isEqual, isObject } from 'lodash-es';
import { BehaviorSubject, merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { DataError } from '../../shared/models/error.model';
import { ReportWidgetConfig, ReportWidgetModel } from '../models/report-widget.model';
import { ReportOptions, ReportsService } from '../../shared-reports/services/reports.service';

export abstract class ReportWidgetComponent<TData = any, TRawData = TData, TDataOptions = any>
	implements OnInit, OnDestroy {
	abstract get widgetConfig(): ReportWidgetConfig<TData, TRawData, TDataOptions>;

	widget: ReportWidgetModel;
	options: ReportOptions;
	params: { [index: string]: any };
	dataOptions: {};
	//using to pass the id of the element that contain the aria label for the report widget (wcd-stretched-donut-bars)
	//Fix for: https://microsoft.visualstudio.com/OS/_workitems/edit/25732042/
	ariaLabelledby: string = null;

	data$: BehaviorSubject<TData> = new BehaviorSubject(undefined);
	error$: Subject<DataError> = new Subject();
	isLoadingData$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	pollingError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	widgetConfig$: Subject<Partial<ReportWidgetModel<TData, TDataOptions>>> = new Subject();

	private _showLoader$: Subject<boolean> = new Subject<boolean>();
	private _dataSubscription: Subscription;
	private _stateSubscription: Subscription;
	private _lastError: DataError;

	showLoader$: Observable<boolean> = merge(
		this._showLoader$,
		this.isLoadingData$.pipe(filter((isLoading: boolean) => !isLoading))
	);

	constructor(protected reportsService: ReportsService) {}

	setWidget() {
		this.widget = new ReportWidgetModel<TData, TRawData, TDataOptions>(this.widgetConfig);
	}

	ngOnInit() {
		this._stateSubscription = this.reportsService.reportState$
			.pipe(debounceTime(20))
			.subscribe((options: ReportOptions) => {
				this.options = options;
				this.setDataObservable();
			});
	}

	ngOnDestroy() {
		this._dataSubscription && this._dataSubscription.unsubscribe();
		this._stateSubscription && this._stateSubscription.unsubscribe();
	}

	reload(dataOptions?: {}, showLoader?: boolean) {
		if (dataOptions) this.dataOptions = dataOptions;
		this.setDataObservable(showLoader);
	}

	userHasPermission(): boolean {
		return this.reportsService.userHasRequiredPermissions(this.widget);
	}

	private setDataObservable(showLoader?: boolean) {
		if (this._dataSubscription) this._dataSubscription.unsubscribe();

		if (this.widgetConfig.isDisabled) {
			this.reset();
		} else {
			this.isLoadingData$.next(true);

			if (showLoader) this._showLoader$.next(true);

			if (this.widget.shouldLoadData && !this.widget.shouldLoadData(this.dataOptions)) return;

			this._dataSubscription = this.reportsService
				.loadWidgetData(this.widget, this.dataOptions)
				.subscribe(
					data => {
						const hasData: boolean =
							data &&
							!!(
								(data instanceof Array && data.length) ||
								(isObject(data) && Object.keys(data).length)
							);
						const value = hasData ? data : null;
						this.pollingError$.next(false);
						this.isLoadingData$.next(false);

						if (!isEqual(this.data$.value, value)) {
							this.data$.next(value);
							if (this._lastError) {
								this._lastError = null;
								this.error$.next(null);
							}
						}
						this.isLoadingData$.next(false);
					},
					(error: DataError) => {
						if (this.data$.value) {
							this.pollingError$.next(true);
						} else {
							this.error$.next((this._lastError = error));
							this.data$.next(null);
						}
						this.isLoadingData$.next(false);
					},
					() => this.isLoadingData$.next(false)
				);
		}
	}

	reset() {
		this._dataSubscription && this._dataSubscription.unsubscribe();
		this.data$.next(undefined);
		this.error$.next(null);
		this.isLoadingData$.next(false);
		this.pollingError$.next(false);
	}
}
