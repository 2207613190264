import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { UserInfo } from '@wcd/domain';
import { TvmOrgSettingsSharedEntityConfigurations } from '../../orgSettingsEndPointUtils';

@Entity({
	...TvmOrgSettingsSharedEntityConfigurations,
	singularName: 'Baseline profile',
	pluralName: 'Baseline profiles',
	endpoint: 'baseline/profiles',
	parseItemQuery: id => `baseline/profiles/${id}`,
})
export class BaselineProfileForSaving extends EntityModelBase<string> {
	@EntityField()
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField()
	readonly name: string;

	@EntityField()
	readonly eTag: string;

	@EntityField()
	readonly description: string;

	@EntityField({
		serialize: false,
	})
	readonly createdOn?: Date;

	@EntityField({
		serialize: false,
	})
	readonly lastModifiedOn?: Date;

	@EntityField()
	readonly createdBy?: UserInfo;

	@EntityField()
	readonly lastModifiedBy?: UserInfo;

	@EntityField()
	readonly isEnabled: boolean;

	@EntityField()
	readonly operatingSystem: string;

	@EntityField()
	readonly operatingSystemVersion: string;

	@EntityField()
	readonly benchmark: string;

	@EntityField()
	readonly benchmarkVersion: string;

	@EntityField()
	readonly complianceLevel: string;

	@EntityField()
	readonly checks: Array<string>;

	@EntityField()
	readonly rbacGroupIds?: Array<number>;

	@EntityField()
	readonly deviceTags: Array<string>;
}
