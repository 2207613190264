import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService, Feature } from '@wcd/config';

@Injectable()
export class EvaluationEnabledGuard implements CanActivate {
	constructor(private appConfigService: AppConfigService, private featuresService: FeaturesService) {}

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		return (
			this.appConfigService.isExposedToAllMachineGroups &&
			(this.appConfigService.isEvaluationEnabled ||
				this.featuresService.isEnabled(Feature.WindowsDefenderAtpEvaluation))
		);
	}
}
