import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { IMachineManagedBy, MachineManagedByType } from './machine-managed-by.models';
import { machineManagedByValues } from './machine-managed-by.values';

@Entity({
	singularName: 'Device Managed By',
	pluralName: 'Device Managed By',
	values: machineManagedByValues,
})
export class MachineDeviceManager extends EntityModelBase<MachineManagedByType> implements IMachineManagedBy {
	@EntityField() name: string;
	@EntityField() nameI18nKey: string;

	@EntityField() priority: number;

	get type(): MachineManagedByType {
		return this.id;
	}
}
