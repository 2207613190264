import { ModuleWithProviders, NgModule } from '@angular/core';
import { InvestigationsDataviewComponent } from './components/investigations.dataview';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { AlertsModule } from '../alerts/alerts.module';
import { RouterModule, Routes } from '@angular/router';
import { MdeAllowedActionsGuard, AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard } from '@wcd/auth';
import { SharedModule } from '../../shared/shared.module';
import { AirsEnabledGuard, Feature } from '@wcd/config';
import { InvestigationActionsModule } from '../investigation_actions/investigation-actions.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { InvestigationEntityTypeService } from './services/investigation.entity-type.service';
import { InvestigationEntityPanelComponent } from './components/investigation.entity-panel.component';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { InvestigationsService } from './services/investigations.service';
import { InvestigationsBackendService } from './services/investigations.backend.service';
import { AirsEntitiesModule } from '../airs_entities/airs-entities.module';
import { InvestigationsDataviewSelectorComponent } from './components/investigations-dataview-selector';
import { SevilleInvestigationsDataviewComponent } from './components/seville-investigations.dataview';
import { Investigation, MdeUserRoleActionEnum } from '@wcd/domain';
import { PanelsModule } from '@wcd/panels';
import { WcdIconsModule } from '@wcd/icons';
import { InvestigationsCommonModule } from '../investigations_common/investigations-common.module';

const investigationsPageTitle: string = 'Automated Investigations';

const investigationsRoutes: Routes = [
	{
		path: 'investigations',
		canActivate: [
			AuthenticatedGuard,
			OnboardGuard,
			FeatureEnabledGuard,
			AirsEnabledGuard,
			MdeAllowedActionsGuard,
		],
		component: InvestigationsDataviewSelectorComponent,
		data: {
			entity: Investigation,
			breadcrumbsConfig: {
				resetListOnEnter: true,
				show: false,
			},
			pageTitle: investigationsPageTitle,
			pageTitleIcon: 'investigation',
			enableAirsRoute: [
				'/enable_airs',
				{
					title: investigationsPageTitle,
					messageClass: 'relative enable-auto-ir-investigation-message',
				},
			],
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData,
			},
		},
	},
];

@NgModule({
	imports: [
		RouterModule.forChild(investigationsRoutes),
		SharedModule,
		DataViewsModule,
		AirsEntitiesModule,
		AlertsModule,
		InvestigationActionsModule,
		GlobalEntitiesModule,
		PanelsModule,
		WcdIconsModule,
		InvestigationsCommonModule,
	],
	declarations: [
		InvestigationsDataviewComponent,
		InvestigationsDataviewSelectorComponent,
		SevilleInvestigationsDataviewComponent,
		InvestigationEntityPanelComponent,
	],
	exports: [
		InvestigationsDataviewComponent,
		InvestigationsDataviewSelectorComponent,
		SevilleInvestigationsDataviewComponent,
		RouterModule,
	],
	entryComponents: [InvestigationEntityPanelComponent],
})
export class InvestigationsModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(InvestigationEntityTypeService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: InvestigationsModule,
			providers: [InvestigationsBackendService, InvestigationsService, InvestigationEntityTypeService],
		};
	}
}
