import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, parseEntityName } from '../analyticsEndPointUtils';
import { EolState } from './eol-version-state.enum';
import { OsDistribution } from '../common/os-distribution.enum';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'version',
	pluralName: 'versions',
	endpoint: 'productversions',
	readonly: true,
})
export class SoftwareVersion extends EntityModelBase<string> {
	@EntityField({
		required: true,
		parse: parseEntityName,
	})
	readonly name: string;

	@EntityField({
		required: true,
		parse: parseEntityName,
	})
	readonly vendor: string;

	@EntityField({
		required: true,
	})
	readonly version: string;

	@EntityField({
		required: true,
	})
	readonly installations: number;

	@EntityField({
		required: true,
	})
	readonly discoveredVulnerabilities: number;

	@EntityField()
	readonly eolVersionState: EolState;

	@EntityField()
	readonly eolVersionSinceDate: Date;

	@EntityField()
	readonly productId?: string;

	@EntityField()
	readonly osDistribution?: OsDistribution;

	@EntityField()
	readonly assetsWithUsage?: number;
}
