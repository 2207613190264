import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('arrayToText', arrayToTextFilter);

function arrayToTextFilter() {
	return function(input) {
		if (!input) {
			return '';
		}

		var result = '';
		for (var i = 0; i < input.length; i++) {
			result += input[i] + ', ';
		}

		return result.substring(0, result.length - 2);
	};
}
