<!--// TODO(khaw): Remove as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'-->
<div *ngIf="device" class="wcd-full-height relative">
	<div class="tvm-list" [ngClass]="{ 'tvm-list--no-columns': mode === 'EntityPage' }">
		<div class="tvm-list__block_item" *ngIf=shouldShowMessage>
			<fab-message-bar [messageBarType]="MessageBarType.warning">
				<div>{{ 'tvm_network_devices_side_panel_warning_message' | i18n : { pastDays: diffDays, remainingDays: remainingDays } }}</div>
			</fab-message-bar>
		</div>

		<div class="tvm-list__header">{{ 'tvm_network_devices_side_panel_device_details' | i18n }}</div>

		<div class="tvm-list__key_val_item">
			<dt>{{ ('tvm_network_devices_name' | i18n) }}</dt>
			<dd>{{ device.deviceName || ('tvm_network_devices_unknown_device_name' | i18n) }}</dd>
		</div>

		<div class="tvm-list__key_val_item" *ngIf="device.deviceType">
			<dt>{{ ('tvm_network_devices_device_type' | i18n) }}</dt>
			<dd>{{ device.deviceType }}</dd>
		</div>

		<div class="tvm-list__key_val_item" *ngIf="device.vendor">
			<dt>{{ ('tvm_network_devices_vendor' | i18n) }}</dt>
			<dd>{{ device.vendor }}</dd>
		</div>

		<div class="tvm-list__key_val_item" *ngIf="device.os">
			<dt>{{ ('tvm_network_devices_os' | i18n) }}</dt>
			<dd>{{ device.os }}</dd>
		</div>

		<div class="tvm-list__key_val_item" *ngIf="device.osVersion">
			<dt>{{ ('tvm_network_devices_os_version' | i18n) }}</dt>
			<dd>{{ device.osVersion }}</dd>
		</div>

		<div class="tvm-list__key_val_item" *ngIf="device.firstSeen">
			<dt>{{ ('tvm_network_devices_first_seen' | i18n) }}</dt>
			<dd>{{ device.firstSeen | date:'medium' }}</dd>
		</div>

		<div class="tvm-list__key_val_item" *ngIf="device.lastScanned">
			<dt>{{ ('tvm_network_devices_last_scanned' | i18n) }}</dt>
			<dd>{{ device.lastScanned | date:'medium' }}</dd>
		</div>

		<ng-container *ngIf="recommendationTitle && recommendationDescription">
			<div class="tvm-list__header">{{ 'tvm_network_devices_side_panel_realated_security_recommendation' | i18n }}</div>
			<div class="tvm-list__block_item">
				<dt class="wcd-margin-small-top wcd-font-weight-semibold">{{ recommendationTitle }}</dt>
				<dd class="wcd-margin-xsmall-top wcd-inline-block">
					<span [innerHTML]="recommendationDescription"></span>
					<a href="#" role="link" (click)="navigateToRecommendation()">{{ 'tvm_network_devices_side_panel_realated_security_recommendation_link' | i18n }}</a>
				</dd>
			</div>
		</ng-container>
	</div>

	<!--Showing only if its a device with a known OS-->
	<related-cves-details *ngIf="device.os"
    	[repository]="vulnerabilitiesRelationshipRepository"
		[title]="'tvm.common.relatedCVE.title' | i18n"
		[maxItems]="3"
		[initiallyCollapsed]="false"
		(showMore)=openAllRelatedCves()
		(export)="exportCves()">
	</related-cves-details>
</div>
