/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-data-list-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i3 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i4 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i6 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i10 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i11 from "./simple-data-list-table.component";
import * as i12 from "../../../dialogs/services/dialogs.service";
var styles_SimpleDataListTableComponent = [i0.styles];
var RenderType_SimpleDataListTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SimpleDataListTableComponent, data: {} });
export { RenderType_SimpleDataListTableComponent as RenderType_SimpleDataListTableComponent };
function View_SimpleDataListTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "data-row-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t\t", "\n\t\t\t"])), i1.ɵpod(2, { count: 0 })], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18n.get(_co.titleKey, _ck(_v, 2, 0, _co.dataList.length)); _ck(_v, 1, 0, currVal_0); }); }
function View_SimpleDataListTableComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-shared-icon", [["class", "small-icon wcd-margin-small-right"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(1, 573440, null, 0, i3.IconComponent, [i4.IconsService, i1.ChangeDetectorRef], { iconName: [0, "iconName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SimpleDataListTableComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "btn-link"], ["data-track-id", "RemoveEmail"], ["data-track-type", "Button"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.onDeleteClicked(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i5.TooltipDirective, [i1.ElementRef, i6.TooltipsService, i7.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i1.ɵppd(2, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "wcd-shared-icon", [["class", "small-icon"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(5, 573440, null, 0, i3.IconComponent, [i4.IconsService, i1.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.i18n.strings.notificationRules_ruleEmails_remove_description, ""); _ck(_v, 1, 0, currVal_1); var currVal_2 = "delete"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = ((i1.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), "notificationRules.ruleEmails.remove.title")) + " ") + _v.parent.parent.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
function View_SimpleDataListTableComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "li", [["class", "data-row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "wcd-flex-horizontal wcd-flex-center-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "wcd-width-large wcd-margin-small-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDataListTableComponent_4)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(8, null, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDataListTableComponent_5)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldShowIcon; _ck(_v, 7, 0, currVal_0); var currVal_2 = _co.shouldShowDeleteButton; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 8, 0, currVal_1); }); }
function View_SimpleDataListTableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDataListTableComponent_3)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.openPanelForAllItems || (_v.context.index < 3)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SimpleDataListTableComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["data-track-id", "OpenAllDataListEntitiesPanel"], ["data-track-type", "SidePaneToggleButton"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPanel() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.openPanel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["\n\t\t\t\t", "\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18n.strings[_co.seeAllKey]; _ck(_v, 3, 0, currVal_0); }); }
export function View_SimpleDataListTableComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i9.I18nPipe, [i10.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 10, "ul", [], null, null, null, null, null)), i1.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "wcd-margin-large-top": 0, "wcd-margin-medium-horizontal": 1, "clear-list-style": 2 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDataListTableComponent_1)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDataListTableComponent_2)), i1.ɵdid(11, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDataListTableComponent_6)), i1.ɵdid(15, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, _co.shouldShowTitle, _co.fullPanelView, true); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.dataList.length > 0) && _co.shouldShowTitle); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.dataList; _ck(_v, 11, 0, currVal_2); var currVal_3 = (_co.openPanelForAllItems && (_co.dataList.length > 3)); _ck(_v, 15, 0, currVal_3); }, null); }
export function View_SimpleDataListTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "simple-data-list-table", [], null, null, null, View_SimpleDataListTableComponent_0, RenderType_SimpleDataListTableComponent)), i1.ɵdid(1, 49152, null, 0, i11.SimpleDataListTableComponent, [i10.I18nService, i12.DialogsService], null, null)], null, null); }
var SimpleDataListTableComponentNgFactory = i1.ɵccf("simple-data-list-table", i11.SimpleDataListTableComponent, View_SimpleDataListTableComponent_Host_0, { dataList: "dataList", shouldShowIcon: "shouldShowIcon", iconName: "iconName", shouldShowDeleteButton: "shouldShowDeleteButton", openPanelForAllItems: "openPanelForAllItems", shouldShowTitle: "shouldShowTitle", titleKey: "titleKey", fullPanelView: "fullPanelView", seeAllKey: "seeAllKey" }, { onDeleteIconClick: "onDeleteIconClick" }, []);
export { SimpleDataListTableComponentNgFactory as SimpleDataListTableComponentNgFactory };
