import { ComponentFactoryResolver, EventEmitter, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalContainer } from '../modals/models/modal-container.model';

// TODO: This should somehow be merged into ConfirmOptions (or vice-versa).

export function isCustomConfirmOptions<
	TPayload extends object,
	TComponent extends CustomConfirmComponent<TPayload>,
	TInputs extends Partial<TComponent> = Partial<TComponent>,
	TConfirmActionResult = never
>(x: any): x is CustomConfirmOptions<TPayload, TComponent, TInputs, TConfirmActionResult> {
	return (
		typeof x === 'object' &&
		typeof x.componentType === 'function' &&
		typeof x.inputs === 'object' &&
		(!x.onConfirm || (x.onConfirm && typeof x.onConfirm === 'function'))
	);
}

/**
 * Options for creating a custom confirm modal.
 *
 * @template TPayload The payload that the component returns on confirm.
 * @template TComponent The type of the modal component.
 * @template TInputs The inputs to pass to the components. **All should be `@Input`s in `TComponent`**.
 * @template TConfirmActionResult _Optional_ The result of the `onConfirm` callback.
 */
export interface CustomConfirmOptions<
	TPayload extends object,
	TComponent extends CustomConfirmComponent<TPayload>,
	TInputs extends Partial<TComponent> = Partial<TComponent>,
	TConfirmActionResult = never
> {
	readonly componentType: Type<TComponent>;
	readonly inputs: TInputs;
	readonly componentFactoryResolver?: ComponentFactoryResolver;

	/**
	 * If supplied, the modal will stay active until the action resolves successfully.
	 */
	readonly onConfirm?: (
		payload: TPayload
	) => TConfirmActionResult | Observable<TConfirmActionResult> | Promise<TConfirmActionResult>;
}

/**
 * An interface that a component should confirm to to be considered as a "custom confirm modal" (passable to `CustomConfirmOptions`).
 *
 * @template TPayload The payload that the component returns on confirm.
 */
export interface CustomConfirmComponent<TPayload extends object> extends ModalContainer {
	/**
	 * Is the modal currently in a saving state.
	 */
	isSaving?: boolean;

	/**
	 * Emitted when the confirm button is clicked.
	 * Usually wired up directly from `ConfirmModalContentComponent`'s `onConfirm`.
	 */
	readonly onConfirm: EventEmitter<TPayload>;

	/**
	 * Emitted when the cancel button is clicked.
	 * Usually wired up directly from `ConfirmModalContentComponent`'s `onCancel`.
	 */
	readonly onCancel: EventEmitter<void>;
}
