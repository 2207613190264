import { SevilleModule } from '../../seville/seville.module';

SevilleModule.factory('demoTenantResolverService', demoTenantResolverService);

demoTenantResolverService.$inject = ['$log', 'authenticationService'];

function demoTenantResolverService($log, authenticationService) {
	var demoTenantsDisplayNames = [
		'winatpcontoso',
		'winatptestlic11',
		'windowsdefenderatp',
		'winatptestlic12',
		'wdatpcontoso',
		'contoso-us',
		'winatpdemo',
		'wdatpcontosofld',
		'wdatpcontosomtc',
	];
	var defaultLookbackForStats = 30;

	var service = {
		isDemoTenant: function() {
			var loggedInUser = authenticationService.loggedInUser();
			if (typeof loggedInUser === 'undefined') {
				return false;
			}
			if (!loggedInUser) {
				return false;
			}
			var lowerCaseUser = loggedInUser.toLowerCase();
			var isMatch = false;
			demoTenantsDisplayNames.forEach(function(n) {
				if (lowerCaseUser.indexOf(n) >= 0) {
					$log.debug('Running as demo user ' + loggedInUser);
					isMatch = true;
				}
			});

			return isMatch;
		},
		demoTenantLookback: 180,
		demoCutOffForMachines: new Date('2016-06-30 00:00:00'),
		getStatsLookback: function() {
			if (this.isDemoTenant()) return this.demoTenantLookback;
			return defaultLookbackForStats;
		},
	};

	return service;
}
