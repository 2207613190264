import { SevilleModule } from '../../seville/seville.module';
import { IconsService } from '@wcd/icons';
import { InvestigationsService } from '../../../../@entities/investigations/services/investigations.service';
import { InvestigationStatus } from '@wcd/domain';
import { cloneDeep, extend, isEmpty, snakeCase } from 'lodash-es';

declare let angular: angular.IAngularStatic;

const options = {
	bindings: {
		data: '<',
		investigationId: '<',
		position: '@',
		flightingFeatureEnabled: '<',
		alertId: '<',
	},
	template: `
	<div class="investigation-status" id="investigation-status"
		data-track-component="SevilleInvestigtionStatus"
		ng-if="$ctrl.flightingFeatureEnabled && ($ctrl.data && $ctrl.data.State)"
		ng-class="::$ctrl.getContainerClasses();">
		<i ng-if="$ctrl.currentState.icon" class="{{ ::$ctrl.currentState.icon.library.name + ' ' + $ctrl.currentState.icon.library.useClass + $ctrl.currentState.icon.name }}" style="margin-right: 2px;" id="state-icon">
		</i>
		<img ng-if="$ctrl.currentState.image" ng-src="{{$ctrl.currentState.image}}" style="margin-right: .5rem; max-width: 24px" id="state-icon"/>
		<div class="text-container">
				{{$ctrl.defaults.mainText}}	{{$ctrl.currentState.displayName}}
				<span ng-if="$ctrl.currentState.InvestigationId && $ctrl.investigationLink">
					(<a router-link="['/investigation/' + $ctrl.currentState.InvestigationId]"
						data-track-id="GoToInvestigation"
	   					data-track-type="Navigation"
						href="{{'/investigation/' + $ctrl.currentState.InvestigationId}}">
						{{$ctrl.currentState.InvestigationId}}
					</a>)
				</span>
		<div class="tooltip-container" >
			<i class="icon icon-Info color-text-gray-900 shrink"
				tooltips
				tooltip-size="medium"
				tooltip-title="{{$ctrl.currentState.toolTipText}}"
				tooltip-side="bottom"></i>
		</div>
		</div>
	</div>
	`,
	controller: 'investigationStatusController',
};

SevilleModule.component('investigationStatus', options).controller(
	'investigationStatusController',
	investigationStatusController
);

investigationStatusController.$inject = [
	'$http',
	'$interval',
	'$log',
	'urlMapping',
	'investigationService',
	'iconsService',
	'investigationsService',
];

function investigationStatusController(
	$http,
	$interval,
	$log,
	urlMapping,
	investigationService,
	iconsService: IconsService,
	investigationsService: InvestigationsService
) {
	var vm = this;
	var investigationStateRedirectionIntervalCancelFunction, queuedRedirectionIntervalCancelFunction;

	angular.extend(vm, {
		$onInit: activate,
		$onChanges: onChanges,
		$onDestroy: onDestroy,
		setCurrentStateObj: setCurrentStateObj,
		getContainerClasses: getContainerClasses,
	});

	function activate() {
		if (vm.flightingFeatureEnabled) {
			vm.stateMap = urlMapping.investigationsStates;
			vm.defaultColors = {
				backgrounds: {
					failure: '#fde7e9',
					success: '#edf5db',
					waiting: '#f5f4ce',
					disabled: '#e6e6e6',
					running: '#e1effa',
				},
				icons: {
					failure: '#a80000',
					success: '#107c10',
					waiting: '#F2610C',
					disabled: '#737373',
					running: '#006CBE',
				},
			};

			vm.defaults = {
				mainText: 'Automated investigation ',
				position: 'top right',
				styles: {
					backgroundColors: {
						Queued: vm.defaultColors.backgrounds.waiting,
						PendingApproval: vm.defaultColors.backgrounds.waiting,
						PendingResource: vm.defaultColors.backgrounds.waiting,
						Failed: vm.defaultColors.backgrounds.failure,
						SuccessfullyRemediated: vm.defaultColors.backgrounds.success,
						PartiallyRemediated: vm.defaultColors.backgrounds.success,
						Benign: vm.defaultColors.backgrounds.success,
						Running: vm.defaultColors.backgrounds.running,
						Disabled: vm.defaultColors.backgrounds.disabled,
						Terminated: vm.defaultColors.backgrounds.disabled,
						TerminatedByUser: vm.defaultColors.backgrounds.disabled,
						TerminatedBySystem: vm.defaultColors.backgrounds.disabled,
						UnsupportedAlertType: vm.defaultColors.backgrounds.disabled,
						UnsupportedOs: vm.defaultColors.backgrounds.disabled,
						PreexistingAlert: vm.defaultColors.backgrounds.disabled,
						SuppressedAlert: vm.defaultColors.backgrounds.disabled,
					},
					iconColors: {
						Queued: vm.defaultColors.icons.waiting,
						PendingApproval: vm.defaultColors.icons.waiting,
						PendingResource: vm.defaultColors.icons.waiting,
						Failed: vm.defaultColors.icons.failure,
						SuccessfullyRemediated: vm.defaultColors.icons.success,
						Benign: vm.defaultColors.icons.success,
						PartiallyRemediated: vm.defaultColors.icons.success,
						Terminated: vm.defaultColors.icons.disabled,
						TerminatedByUser: vm.defaultColors.icons.disabled,
						TerminatedBySystem: vm.defaultColors.icons.disabled,
						Disabled: vm.defaultColors.icons.disabled,
						Running: vm.defaultColors.icons.running,
						UnsupportedAlertType: vm.defaultColors.icons.disabled,
						UnsupportedOs: vm.defaultColors.icons.disabled,
						PreexistingAlert: vm.defaultColors.icons.disabled,
						SuppressedAlert: vm.defaultColors.icons.disabled,
					},
				}, //needs default icons.
				toolTipTexts: {
					Terminated: 'Investigation was stopped before completion',
					TerminatedByUser: 'A user stopped the investigation before it could complete.',
					TerminatedBySystem: 'The investigation was stopped by the system.',
					Failed: 'A problem has interrupted the investigation, preventing it from completing.',
					Benign: 'No malicious entities found during the investigation.',
					SuccessfullyRemediated: 'Malicious entities found were successfully remediated.',
					PartiallyRemediated: 'A problem prevented the remediation of some malicious entities.',
					Running: 'Investigation ongoing. Malicious entities found will be remediated.',
					PendingApproval: 'Remediation actions require review and approval.',
					PendingResource:
						'Investigation paused. The investigation will resume as soon as the device is available.',
					Disabled: 'Automated investigations do not apply to this alert type.',
					Queued:
						'Investigation has been queued and will resume as soon as other remediation activities are completed.',
					UnsupportedAlertType: 'Automated investigation does not apply to this alert type.',
					UnsupportedOs:
						'Device is running an OS that is not supported by automated investigation.',
					PreexistingAlert:
						'Automated investigation does not apply to alerts that were generated before it was deployed.',
					SuppressedAlert: 'Automated investigation does not apply to suppressed alerts.',
					PartiallyInvestigated:
						'Entities directly related to the alert have been investigated. However, a problem stopped the investigation of collateral entities.',
					InnerFailure:
						'A triggering alert does not contain enough information for automated investigation to run.',
				},
				texts: {
					Benign: 'did not find any threats',
					Queued: 'is queued',
					Running: 'is running',
					UnsupportedAlertType: 'is not applicable to alert type',
					UnsupportedOs: 'does not support OS',
					PreexistingAlert: 'is unavailable for preexisting alerts',
					SuppressedAlert: 'is unavailable for suppressed alerts',
					PartiallyRemediated: 'partially remediated identified threats',
					PendingApproval: 'is waiting for user approval',
					PendingResource: 'is waiting for the device',
					SuccessfullyRemediated: 'remediated all identified threats',
					PartiallyInvestigated: 'was partially completed',
					InnerFailure: 'failed',
				},
			};

			if (vm.data) {
				if (vm.data.hasOwnProperty('State') && vm.data.State === vm.stateMap.Queued) {
					// alerts with queued state has investigation id -1 : track for investigation id changes
					trackInvestigationId();
				} else {
					trackInvestigationState();
				}

				vm.currentState = setCurrentStateObj(vm.data.State);
				if (vm.investigationId) {
					// set link to investigation if it is not disabled or queued
					vm.investigationLink =
						urlMapping.investigationsUrls.base +
						urlMapping.investigationsUrls.relatives.investigation +
						vm.currentState.InvestigationId;
				}
				setIconBackgroundColor();
			}
		} else {
			console.warn(
				'You are trying to use Notification Bar component, check flightingFeatureEnabled Property. flightingFeatureEnabled:',
				vm.flightingFeatureEnabled
			);
		}
	}

	function trackInvestigationId() {
		// track for queued investigation (investigation id change)
		queuedRedirectionIntervalCancelFunction = investigationService.refreshOnQueuedAutoInvestigationsStates(
			[vm.alertId],
			function(responseData, intervalCancelFunction) {
				if (
					responseData[0] &&
					responseData[0].AlertInvestigation &&
					responseData[0].AlertInvestigation.State !== vm.stateMap.Queued
				) {
					// auto investigation id updated - no longer in queued state
					vm.data.InvestigationId = responseData[0].AlertInvestigation.InvestigationId;

					vm.currentState = setCurrentStateObj(responseData[0].AlertInvestigation.State);
					setIconBackgroundColor();

					// stop interval over queued state
					intervalCancelFunction();

					// keep tracking investigation state if needed
					trackInvestigationState();
				}
			}
		);
	}

	function trackInvestigationState() {
		// check if investigation state needs to be tracked (pending or running)
		if (investigationService.isInvestigationStateTracked(vm.data.State)) {
			investigationStateRedirectionIntervalCancelFunction = investigationService.refreshOnAutoInvestigationsStates(
				[vm.data.InvestigationId],
				function(responseData, intervalCancelFunction) {
					if (
						responseData[0] &&
						responseData[0].State &&
						responseData[0].State !== vm.currentState.State
					) {
						vm.currentState = setCurrentStateObj(responseData[0].State);
						setIconBackgroundColor();
						if (!investigationService.isInvestigationStateTracked(vm.data.State)) {
							// if state is no longer pending / running - stop update requests
							intervalCancelFunction();
						}
					}
				}
			);
		}
	}

	function setCurrentStateObj(stateName) {
		try {
			var result: any = cloneDeep(
				extend(
					vm.data,
					{ name: stateName },
					{ iconName: snakeCase(vm.data.State) },
					{ displayName: toCamelCase(stateName) },
					{ toolTipText: vm.defaults.toolTipTexts[stateName] },
					{ State: stateName }
				)
			);

			if (vm.data && vm.data.State) {
				if (vm.defaults.texts[vm.data.State]) {
					result.displayName = vm.defaults.texts[vm.data.State];
				}
				// TODO: work with statuses ids when seville's backend can send them, and make sure all statuses are met

				if (vm.data.State === 'Running') {
					result.image = investigationsService.getInvestigationStatusByTypeName(
						vm.data.State.toLowerCase()
					).image;
					result.icon = null;
				} else {
					var statusName = (state => {
						switch (state) {
							case 'PendingApproval':
							case 'PendingResource':
								return 'pending';
							case 'Terminated':
								return 'terminatedByUser';
							case 'SuccessfullyRemediated':
								return 'fullyRemediated';
							default:
								return state;
						}
					})(vm.data.State);

					const investigationStatus: InvestigationStatus = investigationsService.getInvestigationStatusByTypeName(
						statusName
					);
					if (investigationStatus) {
						result.icon = iconsService.getIcon(investigationStatus.iconName);
					}
				}
			}

			return result;
		} catch (e) {
			console.error(e);
			return '';
		}
	}

	function setIconBackgroundColor() {
		if (vm.currentState.name) {
			$interval(
				function() {
					document.getElementById('investigation-status').style['background-color'] =
						vm.defaults.styles.backgroundColors[vm.currentState.name];

					var stateIcon = document.getElementById('state-icon');
					stateIcon.style['color'] = vm.defaults.styles.iconColors[vm.data.State];
				},
				0,
				1
			);
		}
	}

	function getContainerClasses() {
		var containerClass = vm.position || vm.defaults.position;
		return containerClass;
	}

	function onChanges(prev, curr) {
		//check values are initialized before running method
		if (prev && prev.data && !isEmpty(prev.data.previousValue)) {
			if (prev.data.previousValue.State !== prev.data.currentValue.State) {
				curr = prev.data.currentValue;
				curr.State && (vm.currentState = setCurrentStateObj(vm.data.State));
				setIconBackgroundColor();
			}
		}
	}

	function toCamelCase(str) {
		try {
			return str
				.replace(/([A-Z])/g, ' $1')
				.replace(/^./, function(str) {
					return str;
				})
				.toLowerCase();
		} catch (err) {
			console.error('toCamelCase: failed to parse ' + str, err);
			return str || ''; //if str is undefined - return empty string (needs to be changed to a fallback)
		}
	}

	function onDestroy() {
		// if investigation states update interval is running - stopping it.
		investigationStateRedirectionIntervalCancelFunction &&
			investigationStateRedirectionIntervalCancelFunction();
		investigationStateRedirectionIntervalCancelFunction = undefined;
		// if investigation queued update interval is running - stopping it.
		queuedRedirectionIntervalCancelFunction && queuedRedirectionIntervalCancelFunction();
		queuedRedirectionIntervalCancelFunction = undefined;
	}
}
