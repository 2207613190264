const wcdKeyCodes = {
	up: 38,
	down: 40,
	left: 37,
	right: 39,
	home: 36,
	end: 35,
	tab: 9,
	pageUp: 33,
	pageDown: 34,
};
const WCDDirectionalKeyCodes: { [key: number]: number } = {
	[wcdKeyCodes.up]: 1,
	[wcdKeyCodes.down]: 1,
	[wcdKeyCodes.left]: 1,
	[wcdKeyCodes.right]: 1,
	[wcdKeyCodes.home]: 1,
	[wcdKeyCodes.end]: 1,
	[wcdKeyCodes.tab]: 1,
	[wcdKeyCodes.pageUp]: 1,
	[wcdKeyCodes.pageDown]: 1,
};

/**
 * Returns true if the keycode is a directional keyboard key.
 */
export function wcdIsDirectionalKeyCode(which: number): boolean {
	return !!WCDDirectionalKeyCodes[which];
}
