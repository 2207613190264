import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';

@Entity({
	...airsEntityConfig,
	singularName: 'Registry Key',
	pluralName: 'Registry Keys',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsRegistryKey,
})
export class AirsRegistryKey extends AirsEntity {
	@EntityField({ data: 'key' })
	keyData: string;

	@EntityField()
	hive: string;

	constructor(data) {
		super(data);
		this.entityName = this.keyData;
	}
}
