import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { MachineDimensionType } from './machine-protection-dimension-types.values';
import { MachineReportDimensionKeyType } from './machine-protection.model';

@ValueObject({
	singularName: 'Device protection',
	pluralName: 'Device protection',
	readonly: true,
})
export class MachineProtectionData extends ModelBase {
	@EntityField() readonly date: Date;

	@EntityField() readonly values: Record<MachineReportDimensionKeyType, number>;
}
