var VERIFICATION_COOKIE = 'cookieVerifier';
var CookiesService = /** @class */ (function () {
    function CookiesService(document) {
        this.document = document;
    }
    Object.defineProperty(CookiesService.prototype, "cookiesEnabled", {
        // check if the browser has cookies enabled. This approach should work for most browsers:
        // https://stackoverflow.com/questions/4603289/how-to-detect-that-javascript-and-or-cookies-are-disabled
        get: function () {
            if (typeof this._cookiesEnabled === 'undefined') {
                this._cookiesEnabled = navigator.cookieEnabled;
                if (!this._cookiesEnabled) {
                    var oldCookie = this.document.cookie;
                    this.document.cookie = VERIFICATION_COOKIE;
                    this._cookiesEnabled = this.document.cookie.includes(VERIFICATION_COOKIE);
                    this.document.cookie = oldCookie;
                }
            }
            return this._cookiesEnabled;
        },
        enumerable: true,
        configurable: true
    });
    CookiesService.prototype.getAllCookies = function () {
        return new Map(this.document.cookie && this.document.cookie.split(';').map(function (c) { return c.trim().split('='); }));
    };
    CookiesService.prototype.getCookie = function (cookieName) {
        return this.getAllCookies().get(cookieName);
    };
    return CookiesService;
}());
export { CookiesService };
