var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentFactoryResolver, Injector, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { compact, merge, omit } from 'lodash-es';
import { FabricIconNames, isFabricIconName } from '@wcd/scc-common';
import { ItemActionType, } from '../../../dataviews/models/item-action.model';
import { I18nService } from '@wcd/i18n';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { TrackingEventsModelService, } from '../../../insights/services/tracking-events-model.service';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { IconsService } from '@wcd/icons';
import { CommandBarCustomIconComponent } from './command-bar-custom-icon.component';
import { sccHostService } from '@wcd/scc-interface';
var CommandBarItemService = /** @class */ (function () {
    function CommandBarItemService(rbacControlService, router, factoryResolver, iconsService, injector, trackingEventsModelService, i18nService) {
        this.rbacControlService = rbacControlService;
        this.router = router;
        this.factoryResolver = factoryResolver;
        this.iconsService = iconsService;
        this.injector = injector;
        this.trackingEventsModelService = trackingEventsModelService;
        this.i18nService = i18nService;
    }
    /**
     * Applies RBAC permissions to a `ICommandBarItem`, making it disabled/hidden.
     * Does not mutate the original object.
     */
    CommandBarItemService.prototype.withRbac = function (item, rbacSettings) {
        var state = rbacSettings.state, allowRbacTooltipOverride = rbacSettings.allowRbacTooltipOverride;
        var hasRequiredPermissions = this.rbacControlService.hasRequiredRbacPermissions(rbacSettings);
        var rbacDisabled = !hasRequiredPermissions && state !== RbacControlState.hidden;
        var rbacRespectingItem = Object.assign({}, item, {
            disabled: item.disabled || rbacDisabled,
            hidden: !hasRequiredPermissions && state === RbacControlState.hidden,
        }, allowRbacTooltipOverride && rbacDisabled
            ? { title: this.i18nService.get('common.permissions.noPermissionTooltip') }
            : null);
        return this.withData(rbacRespectingItem, 'rbacSettings', rbacSettings);
    };
    /**
     * Creates a date picker `ICommandBarItem` from the given template
     */
    CommandBarItemService.prototype.createDatePicker = function (_a) {
        var key = _a.key, templateRef = _a.templateRef;
        return this.withCustomRendering({ key: key }, { templateRef: templateRef });
    };
    /**
     * Takes an `ICommandBarItem` and a `TemplateRef` and changes it to render as the given template.
     */
    CommandBarItemService.prototype.withCustomRendering = function (item, options) {
        return Object.assign({}, item, {
            render: options.templateRef,
        });
    };
    /**
     * Takes an `ICommandBarItem` and options for routing and returns a new `ICommandBarItem` that when clicked will navigate using the given route options.
     */
    CommandBarItemService.prototype.withRouting = function (item, routingOptions) {
        var link = routingOptions.link, isExternal = routingOptions.isExternal, queryParams = routingOptions.queryParams;
        var path = Array.isArray(link) ? link.join('/') : String(link);
        var paramsStr = queryParams &&
            Object.keys(queryParams)
                .map(function (p) { return p + "=" + queryParams[p]; })
                .join('&');
        if (isExternal) {
            var iconProps = {
                iconName: FabricIconNames.OpenInNewWindow,
            };
            item.iconProps = iconProps;
            this.withCustomIcon(item);
        }
        return Object.assign({}, item, {
            href: "" + path + (paramsStr ? '?' + paramsStr : ''),
            // Adds target="_blank" so that the external link will be opened in a new tab
            target: isExternal == true ? '_blank' : '',
        });
    };
    /**
     * Adds support for rendering custom icons (as defined in `IconsService`).
     * Icon will be chosen based on the string in `iconProps.iconName`.
     */
    CommandBarItemService.prototype.withCustomIcon = function (item) {
        return Object.assign({}, item, {
            renderIcon: {
                componentType: CommandBarCustomIconComponent,
                factoryResolver: this.factoryResolver,
                injector: this.injector,
            },
        });
    };
    /**
     * Adds tracking (via the regular `data-track-*` attributes) to the given item.
     */
    CommandBarItemService.prototype.withTracking = function (item, trackingEventProperties) {
        var trackingAttributesMap = this.trackingEventsModelService.convertEventToAttribute(trackingEventProperties);
        return Object.assign({}, item, trackingAttributesMap);
    };
    CommandBarItemService.prototype.withHideUnderline = function (item) {
        return Object.assign({}, item, {
            buttonStyles: {
                rootHovered: { 'text-decoration': 'none' },
                rootFocused: { 'text-decoration': 'none' },
            },
        });
    };
    CommandBarItemService.prototype.fromItemActionModel = function (options) {
        var _this = this;
        var itemActionModel = options.itemActionModel, onClick = options.onClick, tagsTemplateRef = options.tagsTemplateRef;
        var getIconName = function (icon) {
            return typeof icon === 'string' ? icon : icon.iconName;
        };
        var createIconProps = function (itemIcon) {
            var iconName = itemIcon && getIconName(itemIcon);
            var icon = itemIcon && _this.iconsService.getIcon(iconName);
            if (!icon) {
                return {
                    iconProps: {},
                    isCustomIcon: false,
                };
            }
            // Although <icon> can handle Fabric icons (falls back to displaying <fab-icon>),
            // we can avoid this needless work by seeing if this is a Fabric icon first and adjusting only if it's not
            // The native Fabric icon also accepts more props, including class names, which can be specified on the `ItemActionModel`.
            if (isFabricIconName(icon.name)) {
                var iconProps_1 = {
                    iconName: icon.name,
                };
                if (typeof itemActionModel.icon === 'object') {
                    if (itemActionModel.icon.className) {
                        iconProps_1 = __assign({}, iconProps_1, { styles: { root: itemActionModel.icon.className } });
                    }
                    iconProps_1 = __assign({}, iconProps_1, omit(itemActionModel.icon, 'className', 'iconName'));
                }
                return { isCustomIcon: false, iconProps: iconProps_1 };
            }
            return {
                isCustomIcon: true,
                iconProps: {
                    iconName: iconName,
                },
            };
        };
        var _a = createIconProps(itemActionModel.icon), iconProps = _a.iconProps, isCustomIcon = _a.isCustomIcon;
        // Every item action gets assigned tracking by default (override-able)
        var item = this.withTracking({
            key: itemActionModel.id,
            text: itemActionModel.nameKey
                ? this.i18nService.get(itemActionModel.nameKey)
                : itemActionModel.name,
            iconProps: iconProps,
            ariaLabel: itemActionModel.ariaLabel,
            role: 'menuitem',
            href: itemActionModel.href,
            target: itemActionModel.href && itemActionModel.openLinkInNewTab ? '_blank' : null,
            disabled: itemActionModel.disabled || !onClick,
            title: itemActionModel.tooltip,
            onClick: function () {
                if (itemActionModel.href && !itemActionModel.openLinkInNewTab && sccHostService.isSCC) {
                    _this.router.navigateByUrl(itemActionModel.href);
                }
                if (itemActionModel.type === ItemActionType.Tags || !onClick) {
                    return;
                }
                onClick(itemActionModel);
            },
            data: {
                itemAction: itemActionModel,
            },
            buttonStyles: itemActionModel.styles ||
                {
                    // `.nowrap` is a workaround for office-ui-fabric-react issue #6168 (https://github.com/OfficeDev/office-ui-fabric-react/issues/6168)
                    root: compact(['nowrap', itemActionModel.buttonClass]),
                    rootDisabled: [
                        itemActionModel.buttonClass,
                        {
                            'pointer-events': 'inherit',
                        },
                    ],
                    rootPressed: itemActionModel.buttonClass,
                    rootHovered: itemActionModel.buttonClass,
                    rootFocused: itemActionModel.buttonClass,
                },
            subMenuProps: itemActionModel.values
                ? {
                    items: itemActionModel.values.map(function (itemActionValue) { return ({
                        key: itemActionValue.id,
                        text: itemActionValue.name,
                        iconProps: itemActionValue.icon && {
                            iconName: itemActionValue.icon,
                        },
                        data: {
                            itemActionValue: itemActionValue,
                        },
                        onClick: function () {
                            itemActionValue.method(null, null, null, itemActionValue);
                        },
                    }); }),
                }
                : undefined,
        }, {
            id: itemActionModel.id,
            type: TrackingEventType.Action,
        });
        if (itemActionModel.rbac || itemActionModel.rbacMtpPermission) {
            item = this.withRbac(item, {
                permissions: itemActionModel.rbac,
                mtpPermissions: itemActionModel.rbacMtpPermission,
                mtpWorkloads: itemActionModel.rbacMtpWorkloads,
                requireAllPermissions: itemActionModel.rbacRequireAllPermissions,
                ignoreNonActiveWorkloads: itemActionModel.rbacIgnoreNonActiveWorkloads,
                state: itemActionModel.rbacState,
                allowRbacTooltipOverride: itemActionModel.allowRbacTooltipOverride,
                customCheckCallback: itemActionModel.customCheckCallback
            });
        }
        if (isCustomIcon) {
            item = this.withCustomIcon(item);
        }
        // If the item specified any specific tracking, use that instead of the default ones
        if (itemActionModel.tracking) {
            item = this.withTracking(item, itemActionModel.tracking);
        }
        if (itemActionModel.type === ItemActionType.Tags && tagsTemplateRef) {
            item = this.withCustomRendering(item, {
                templateRef: tagsTemplateRef,
            });
        }
        if (itemActionModel.href && itemActionModel.href.startsWith('/')) {
            item = this.withHideUnderline(item);
        }
        // Needs casting due to bad typing of `data` in office-ui-react-fabric (typed as `any`, making it hard to override)
        return item;
    };
    CommandBarItemService.prototype.withData = function (item, key, data) {
        var _a;
        return merge({}, item, {
            data: (_a = {},
                _a[key] = data,
                _a),
        });
    };
    return CommandBarItemService;
}());
export { CommandBarItemService };
