var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalContainer } from '../../dialogs/modals/models/modal-container.model';
import { SendFeedbackApiCall } from '@wcd/domain';
import { Router } from '@angular/router';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
var FeedbackModalComponent = /** @class */ (function (_super) {
    __extends(FeedbackModalComponent, _super);
    function FeedbackModalComponent(router, dialogsService, i18nService) {
        var _this = _super.call(this, router) || this;
        _this.dialogsService = dialogsService;
        _this.i18nService = i18nService;
        _this.submitApiCall = SendFeedbackApiCall;
        return _this;
    }
    FeedbackModalComponent.prototype.onDone = function () {
        var _this = this;
        this.dialogsService
            .confirm({
            title: this.i18nService.get('feedback.confirm.title'),
            text: this.i18nService.get('feedback.confirm.text'),
            showConfirm: false,
            cancelText: this.i18nService.get('feedback.confirm.close'),
        })
            .then(function () {
            _this.closeModal();
        });
    };
    return FeedbackModalComponent;
}(ModalContainer));
export { FeedbackModalComponent };
