import {ComponentRef, EventEmitter, Injectable} from '@angular/core';
import {DataCache, Paris} from '@microsoft/paris';
import {AuthService} from '@wcd/auth';
import {
	AlertHistoryItem,
	Incident,
	IncidentHistoryItem,
	IncidentsAddCommentApiCall,
	IncidentsUpdateApiCall,
	IncidentsUpdateOptions,
	IncidentTimelineApiCall,
	IncidentTimelineResponse,
	Severity,
	Tag,
	TagType
} from '@wcd/domain';
import {I18nService} from '@wcd/i18n';
import {PanelType} from '@wcd/panels';
import {values} from 'lodash-es';
import {from, Observable} from 'rxjs';
import {map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {DialogsService} from '../../../dialogs/services/dialogs.service';
import {GlobalEntityTypesService} from '../../../global_entities/services/global-entity-types.service';
import {IncidentSetClassificationComponent} from '../components/incident-set-classification.component';
import {IncidentsBackendService, IncidentTagsBackendData} from './Incidents.backend.service';
import {IncidentsFiltersService} from './incidents.filters.service';
import {Feature, FeaturesService} from "@wcd/config";

export enum IncidentAssignOptions {
	AssignToMe = 0,
	Unassign = 1,
}

@Injectable()
export class IncidentsService {
	private _setClassificationPanel: ComponentRef<IncidentSetClassificationComponent>;
	private _allIncidentTagsCache: DataCache<Array<Tag>> = new DataCache({
		time: 1000 * 60 * 5,
		max: 1,
		getter: () =>
			this.backendService.getIncidentTags().pipe(
				tap(() => this.incidentFiltersService.clearCache()),
				map((data: IncidentTagsBackendData) =>
					values(data.UserDefinedTags).map(
						(tag: string) => new Tag({ id: tag, name: tag, type: TagType.user })
					)
				)
			),
	});

	constructor(
		private paris: Paris,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private authService: AuthService,
		private backendService: IncidentsBackendService,
		private incidentFiltersService: IncidentsFiltersService,
		private featuresService: FeaturesService,
	) {	}

	showSetClassificationPanel(incidents: Array<Incident>): Observable<AlertHistoryItem> {
		const panelHeaderText18nKey =
			incidents.length === 1 && incidents[0].classification
				? 'incident.setClassification.editClassification'
				: 'incident.setClassification.title';

		return this.dialogsService
			.showPanel(
				IncidentSetClassificationComponent,
				{
					id: 'incident-set-classification',
					isModal: false,
					headerText: this.i18nService.get(panelHeaderText18nKey),
					showOverlay: false,
					hasCloseButton: false,
					type: PanelType.large,
					back: { onClick: () => this._setClassificationPanel.destroy() },
				},
				{
					incidents: incidents,
				}
			)
			.pipe(
				tap((panel: ComponentRef<IncidentSetClassificationComponent>) => {
					this._setClassificationPanel = panel;
					panel.onDestroy(() => {
						this._setClassificationPanel = null;
					});
				}),
				switchMap((panel: ComponentRef<IncidentSetClassificationComponent>) => {
					return panel.instance.updateIncidentsEvent;
				}),
				switchMap((incidentsUpdateOptions: IncidentsUpdateOptions) => {
					return this.updateIncidents(incidentsUpdateOptions);
				}),
				tap(
					(_auditResult: AlertHistoryItem) => {
						incidents.forEach(this._setClassificationPanel.instance.updateIncidentsDetails);

						this.dialogsService.showSuccessSnackbar({
							text: this.i18nService.get(
								incidents.length === 1
									? 'incident.update.singleUpdateSuccess'
									: 'incident.update.multipleUpdateSuccess'
							),
						});
						this._setClassificationPanel.destroy();
					},
					error => {
						this.dialogsService.showError({
							title: this.i18nService.get(
								incidents.length === 1
									? 'incident.update.singleUpdateFailed'
									: 'incident.update.multipleUpdateFailed'
							),
							data: error,
						});
						this._setClassificationPanel.destroy();
					}
				)
			);
	}

	unassignIncidents(incidents: Array<Incident>): Observable<AlertHistoryItem> {
		return this.reassignIncidents(
			incidents,
			IncidentAssignOptions.Unassign,
			'[data-track-id=incidentAssignToMe]'
		);
	}

	assignIncidentsToMe(incidents: Array<Incident>): Observable<AlertHistoryItem> {
		return this.reassignIncidents(
			incidents,
			IncidentAssignOptions.AssignToMe,
			'[data-track-id=incidentUnAssign]'
		);
	}

	private reassignIncidents(
		incidents: Incident[],
		assignOption: IncidentAssignOptions,
		focusableQuery?: string
	): Observable<AlertHistoryItem> {
		const confirmDialogCloseEvent$: EventEmitter<boolean> = new EventEmitter();
		const isUnassign = assignOption === IncidentAssignOptions.Unassign;

		return from(
			// raise confirm dialog for assign / unassign incident.
			// returns a promise that with the confirm/cancel event
			this.dialogsService.confirm({
				text: this.i18nService.get(
					isUnassign
						? 'incident.assignmentDialog.unassign.confirmContentText'
						: 'incident.assignmentDialog.assignToMe.confirmContentText'
				),
				title: this.i18nService.get(
					isUnassign
						? 'incident.assignmentDialog.unassign.title'
						: 'incident.assignmentDialog.assignToMe.title'
				),
				confirmText: this.i18nService.get(
					isUnassign
						? 'incident.commandBar.actions.manageIncident.unassign'
						: 'incident.commandBar.actions.manageIncident.assignToMe'
				),
				showOverlay: false,
				showLoaderAndCloseOnEvent: confirmDialogCloseEvent$,
			})
		).pipe(
			mergeMap(confirmedEvent => {
				if (confirmedEvent.confirmed) {
					return this.updateIncidents({
						incidentIds: incidents.map(incident => incident.id),
						assignedTo: isUnassign ? null : this.authService.currentUser.username,
					});
				}
			}),
			tap(
				_auditHistoryItem => {
					// update incidents status
					incidents.forEach(incident => {
						incident.assignedTo = isUnassign ? null : this.authService.currentUser.username;
					});

					confirmDialogCloseEvent$.emit(true); // close confirm dialog

					this.dialogsService.showSuccessSnackbar({
						text: this.i18nService.get(
							incidents.length === 1
								? 'incident.update.singleUpdateSuccess'
								: 'incident.update.multipleUpdateSuccess'
						),
						focusableQuery: focusableQuery,
					});
				},
				(error: Error) => {
					confirmDialogCloseEvent$.emit(true); // close confirm dialog

					this.dialogsService.showError({
						title: this.i18nService.get(
							incidents.length === 1
								? 'incident.update.singleUpdateFailed'
								: 'incident.update.multipleUpdateFailed'
						),
						data: error,
					});
				}
			)
		);
	}


	getIncidentStatusDropdownValues(i18nService: I18nService) {
		return [
			{ key: 'Open', text: i18nService.get('incident_status_values_Active') },
			{ key: 'InProgress', text: i18nService.get('incident_status_values_InProgress') },
			{ key: 'Closed', text: i18nService.get('incident_status_values_Resolved') },
		];
	}

	updateIncidents(incidentsUpdateOptions: IncidentsUpdateOptions): Observable<AlertHistoryItem> {
		return this.paris
			.apiCall( IncidentsUpdateApiCall, incidentsUpdateOptions)
			.pipe(tap(() => this.paris.getRepository(Incident).clearCache()));
	}

	addComment(incidentIds: Array<Incident['id']>, comment: string): Observable<IncidentHistoryItem> {
		return  this.paris.apiCall(IncidentsAddCommentApiCall, {
			newCommentsApi: this.featuresService.isEnabled(Feature.VNextMigrationIncidentAddComments),
			Ids: incidentIds,
			Comment: comment,
		});
	}

	getIncidentsCommonColorClass(incidents: Array<Incident>): string | null {
		const commonSeverity: Severity = GlobalEntityTypesService.getCommonValue(
			incidents,
			incident => incident.severity
		);
		return commonSeverity ? `color-box-${commonSeverity.className}` : null;
	}

	getIncidentFilters(hideThreatsFilter: boolean = false): Observable<Record<string, any>> {
		return this.incidentFiltersService.getIncidentFilters(hideThreatsFilter);
	}

	getIncidentTimeline = (incident: Incident): Observable<IncidentTimelineResponse> =>
		this.paris.apiCall(IncidentTimelineApiCall, incident);

	getAllUserDefinedIncidentTags(): Observable<Array<Tag>> {
		return this._allIncidentTagsCache.get(true);
	}

	clearAllUserDefinedIncidentTags(): void {
		this.incidentFiltersService.clearCache();
		this._allIncidentTagsCache.clear();
	}

	clearCachedIncidents(): void {
		this.paris.getRepository(Incident).clearCache();
	}

	updateIncidentTags(incidents: ReadonlyArray<Incident>, tags: ReadonlyArray<Tag>): Observable<any> {
		this.clearAllUserDefinedIncidentTags();
		return this.backendService.updateIncidentTags(incidents.map(incident => incident.id), tags);
	}
}
