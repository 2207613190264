import { NgModule } from '@angular/core';

import { LetDirective } from './directives/let.directive';
import { FocusOnDirective } from './directives/focusOn.directive';
import { FabButtonFocusOnDirective } from './directives/fabButtonFocusOn.directive';
import { AnnouncerForceFocusDirective } from './directives/announcerForceFocus.directive';
import { CollapseDirective } from './directives/collapse.directive';
import { KeyboardNavigableElementDirective } from './directives/keyboard-navigable-element.directive';
import { KeyboardNavigableContainerDirective } from './directives/keyboard-navigable-container.directive';
import { LearnMoreLinkDirective } from "./directives/learn-more-link.directive";
import { AnnounceErrorDirective } from "./directives/announceError.directive"
import { TextHighlightDirective } from './directives/text-highlight.directive'
import { FocusOnFirstDirective } from './directives/focusOnFirst.directive';


const directives = [
	LetDirective,
	FocusOnDirective,
	FabButtonFocusOnDirective,
	LearnMoreLinkDirective,
	CollapseDirective,
	AnnouncerForceFocusDirective,
	KeyboardNavigableElementDirective,
	KeyboardNavigableContainerDirective,
	AnnounceErrorDirective,
	TextHighlightDirective,
	FocusOnFirstDirective
];

@NgModule({
	declarations: [...directives],
	exports: [...directives],
})
export class AngularExtensionsModule {}
