import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
	ViewChild,
} from '@angular/core';
import { isNil, isObject } from 'lodash-es';
import { from, ObservableInput } from 'rxjs';
import { SpinnerSize } from 'office-ui-fabric-react';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';

const MIN_SEARCH_LENGTH = 2;
const KEY_CODES = {
	ENTER: 13,
	ESCAPE: 27,
	UP: 38,
	DOWN: 40,
	TAB: 9,
};

const MARGIN = 20;
const TOP_CONTAINER_HEIGHT = 50;

let lastId = 0;

@Component({
	selector: 'wcd-search',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	encapsulation: ViewEncapsulation.None, // Due to icon styling
})
export class SearchComponent<TResult = any> implements OnInit {
	@Input() placeholder: string;
	@Input() settings: ISearchSettings<TResult>;
	@Input() fullWidth = false;
	@Input() searchFunction: (term: string) => ObservableInput<Array<TResult>>;
	@Input() ariaLabel?: string = null;
	@Input() forceAlphanumeric: boolean = false;
	@Input() setFocus: boolean = false;
	@Input() enableTopPosition: boolean = true;
	@Input() role: string = "combobox";
	@Input() closeResultsWhenSearchCleared: false;

	@Output() readonly select: EventEmitter<TResult> = new EventEmitter<TResult>();

	/**
	 * 	triggered when the user presses Esc or deletes the search term from the input box
	 */
	@Output() readonly searchTermCleared: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * 	triggered when the user presses Esc when search term is cleared and results are closed
	 */
	@Output() readonly escOnClosedResult: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('resultsEl', { static: false }) set resultsElement(resultsElement: ElementRef<HTMLUListElement>) {
		this.menuEl = resultsElement && resultsElement.nativeElement;
		if (this.menuEl) {
			/**
			 * Taking the menu out of the component, and into the body, for positioning.
			 */
		 	document.body.appendChild(this.menuEl);
		}
	}
	@ViewChild('noResultsEl', { static: false }) set noResultsElement(resultsElement: ElementRef<HTMLUListElement>) {
		this.noResultMenu = resultsElement && resultsElement.nativeElement;
		if (this.noResultMenu) {
			/**
			 * Taking the menu out of the component, and into the body, for positioning.
			 */
		 	document.body.appendChild(this.noResultMenu);
		}
	}

	@ViewChild('inputEl', { static: false }) inputElement: ElementRef<HTMLInputElement>;

	menuEl: HTMLElement;
	noResultMenu: HTMLElement;
	searchValue = '';
	results: ReadonlyArray<SearchResult> = [];
	rawResults: ReadonlyArray<TResult>;

	topPositionResults = false;
	highlightedResult: number;
	selectedResult: SearchResult;
	isLoading = false;
	minSearchSize: number = MIN_SEARCH_LENGTH;
	showResults = false;
	resultVisible = false;

	@Input() searchboxId = 'wcd-searchbox-' + lastId++;

	readonly SpinnerSize: typeof SpinnerSize = SpinnerSize;

	private _keyUpTimeout;
	private _selectTimeout;

	constructor(
		private readonly changeDetectionRef: ChangeDetectorRef,
		private liveAnnouncer: LiveAnnouncer,
		private i18nService: I18nService
		) {}

	ngOnInit() {
		if (this.settings && !isNaN(this.settings.minSearchSize))
			this.minSearchSize = this.settings.minSearchSize;
	}

	onKeyDown(e: KeyboardEvent) {
		clearTimeout(this._keyUpTimeout);
		if (e.keyCode === KEY_CODES.TAB) {
			this.closeResults();
		} else if (e.keyCode === KEY_CODES.ESCAPE) {
			if (this.searchValue !== '') {
				this.searchValue = '';
				if (this.closeResultsWhenSearchCleared){
					this.closeResults();
					this.searchTermCleared.emit(null);
				}
			} else {
				if(this.showResults){
					this.closeResults();
					this.searchTermCleared.emit(null);
				}
				else{
					this.escOnClosedResult.emit(null);
				}
			}
			e.stopPropagation();
		} else if (e.keyCode === KEY_CODES.ENTER) {
			this.onSelect();
		} else if (e.keyCode === KEY_CODES.UP || e.keyCode === KEY_CODES.DOWN) {

			if (e.keyCode === KEY_CODES.UP && this.highlightedResult >= 0) this.highlightedResult--;
			else if (e.keyCode === KEY_CODES.DOWN) {
				if (this.results && this.results.length && !this.showResults) {
					this.showResults = true;
					this.highlightedResult = 0;
				} else this.highlightedResult++;
			}
			this.highlightedResult = this.highlightedResult || 0;
			if (this.highlightedResult < 0) this.highlightedResult = this.results.length - 1;
			if (this.highlightedResult > this.results.length - 1) this.highlightedResult = 0;
			e.preventDefault();
			setTimeout(() => {
				const selectedElement = document.querySelector(".wcd-searchbox--result__highlighted");
				selectedElement && selectedElement.scrollIntoView();
			});


		} else if (/[!@\-+]/.test(e.key) && this.searchValue.length === 0 && this.forceAlphanumeric) {
			return false;
		} else {
			this._keyUpTimeout = setTimeout(this.loadSearchResults.bind(this), 400);
		}
		this.changeDetectionRef.detectChanges();
	}

	onBlur() {
		this.closeResults();
	}

	onMouseWheel = (e)=>{
		if (!e.target.closest('.wcd-searchbox--results')) this.closeResults();
	}

	onFocus() {
		if (this.settings && this.settings.showSuggestions) {
			clearTimeout(this._keyUpTimeout);
			this._keyUpTimeout = setTimeout(this.loadSearchResults.bind(this), 400);
		}
	}

	private closeResults(clearSearchValue = true) {
		this.topPositionResults = false;
		this.isLoading = false;
		this.showResults = false;
		this.resultVisible = false;
		if (clearSearchValue && (!this.settings || !this.settings.showValueOnSelect)) this.searchValue = '';
		window.removeEventListener('mousewheel', this.onMouseWheel);
		this.changeDetectionRef.detectChanges();
	}

	selectItem(itemIndex) {
		this.highlightedResult = itemIndex;
		this.selectedResult = this.results[itemIndex];
		if (this.settings && this.settings.showValueOnSelect) {
			this.searchValue = this.selectedResult.label || this.selectedResult.value;
		}
		this.emitSelect(this.selectedResult);
		this.closeResults();
	}

	private onSelect() {

		this.selectedResult = this.results ? this.results[this.highlightedResult || 0] : null;

		if (this.settings && this.settings.showValueOnSelect) this.searchValue = this.selectedResult.label || this.selectedResult.value;

		clearTimeout(this._selectTimeout);
		this._selectTimeout = setTimeout(() => {
			this.emitSelect(this.selectedResult);
		}, 40);

		this.closeResults();

		return false;
	}

	private emitSelect(selectedResult: SearchResult) {
		this.select.emit(this.rawResults[this.results.indexOf(selectedResult)]);
	}

	loadSearchResults() {
		const searchTerm = this.searchValue;
		if (!searchTerm) {
			this.searchTermCleared.emit(null);
		}

		if (!this.settings || !this.settings.showSuggestions) {
			if (!searchTerm || searchTerm.length < this.minSearchSize) return this.closeResults(false);
		}

		this.isLoading = true;
		this.changeDetectionRef.detectChanges();

		from(this.searchFunction(searchTerm)).subscribe(
			(results: Array<TResult>) => {
				this.results = this.parseResults(results);
				this.rawResults = results;
				this.showResults = true;

				setTimeout(this.setResultsAreBelowFold.bind(this), 10);
				this.isLoading = false;

				this.liveAnnouncer.announce(
					this.i18nService.get(this.results.length ? 'filters_search_suggestions_available':'filters_search_result_not_found'));

				window.addEventListener('mousewheel', this.onMouseWheel);
				this.changeDetectionRef.detectChanges();
			},
			() => this.closeResults()
		);
	}

	selectionChanged(e: KeyboardEvent) {
		// otherwise, it's bubbles up as the search.component's (select) event
		e.stopPropagation();
	}

	private parseResults(results: ReadonlyArray<TResult>): ReadonlyArray<SearchResult<TResult>> {
		if (!results) return [];

		if (this.settings && this.settings.parseResult)
			return results.map(result => this.settings.parseResult(result));

		if (isSearchResults(results)) return results;

		throw new Error('Failed to parse search results.');
	}

	private setResultsAreBelowFold() {
		const resultsBoundingRect =
				(this.menuEl && this.menuEl.getBoundingClientRect()) ||
				(this.noResultMenu && this.noResultMenu.getBoundingClientRect()),
			windowHeight = document.documentElement.clientHeight;
		this.topPositionResults =
			this.inputElement.nativeElement.getBoundingClientRect().bottom + resultsBoundingRect.height >
			windowHeight;

		this.changeDetectionRef.detectChanges();
		this.setMenuPositionAndShow()
	}

	setMenuPositionAndShow() {
		const inputRect = this.inputElement.nativeElement.getBoundingClientRect();

		const documentHeight = document.documentElement.clientHeight,
			aboveHight = inputRect.top - TOP_CONTAINER_HEIGHT - MARGIN,
			menuEl = this.results.length ? this.menuEl : this.noResultMenu,
			belowHight = documentHeight - inputRect.bottom - MARGIN;

		if (menuEl){
			if (belowHight >= aboveHight){

				menuEl.style.top = inputRect.bottom + 'px';
				menuEl.style.left = inputRect.left + 1 + 'px';
				menuEl.style.minWidth = inputRect.width - 1 + 'px';
				menuEl.style.maxWidth = inputRect.width + 1 + 'px';
				menuEl.style.maxHeight = belowHight + 'px'
			}
			else{

				const maxHeight = inputRect.top - TOP_CONTAINER_HEIGHT - MARGIN,
					menuRec = menuEl.getBoundingClientRect(),
					top = inputRect.top < menuRec.height ? maxHeight : inputRect.top - menuRec.height;

				menuEl.style.maxHeight = maxHeight + 'px'
				menuEl.style.left = inputRect.left + 1 + 'px';
				menuEl.style.minWidth = inputRect.width - 1 + 'px';
				menuEl.style.maxWidth = inputRect.width + 1 + 'px';
				menuEl.style.top = top + 'px';
			}

			this.resultVisible = true;
			this.changeDetectionRef.detectChanges();
		}
	}
}

function isSearchResults(searchResults: ReadonlyArray<any>): searchResults is ReadonlyArray<SearchResult> {
	return (
		Array.isArray(searchResults) &&
		searchResults.every(searchResult => {
			if (!isObject(searchResult)) return false;

			const maybeSearchResult = searchResult as SearchResult;
			if (isNil(maybeSearchResult.value)) return false;

			return isNil(maybeSearchResult.label) || typeof maybeSearchResult.label === 'string';
		})
	);
}

export interface SearchResult<TItem = any, TValue extends string | number = any> {
	label?: string;
	value: TValue;
	item?: TItem;
}

export interface ISearchSettings<TResult> {
	/**
	 * If `true`, after selecting a result, the label of the selected result will be the value of the search input.
	 */
	showValueOnSelect?: boolean;

	/**
	 * Search will be performed only if the length of the text in the search input is equal or larger than this number.
	 */
	minSearchSize?: number;

	/**
	 * If `true`, results will be requested even if there's no input from the user, in case the search input is focused on.
	 */
	showSuggestions?: boolean;

	/**
	 * If `true`, the searched term will be highlighted in the results
	 */
	highlightTermInResults?: boolean;

	/**
	 * Converts a result data item into a SearchResult
	 * @param result
	 */
	parseResult?: (result: TResult) => SearchResult;
}
