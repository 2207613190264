import { AlertHistoryItem } from './alert-history-item.entity';

const DEFAULT_ALERT_HISTORY_ITEM_ICON = 'clock';

export const alertHistoryItemTypes = [
	{
		id: 'Feedback',
		icon: 'comment',
	},
	{
		id: 'AssignedTo',
		icon: 'users.user',
	},
	{
		id: 'Unassign',
		icon: 'users.user',
	},
	{
		id: 'Suppressed',
		icon: DEFAULT_ALERT_HISTORY_ITEM_ICON,
	},
	{
		id: 'Status',
		icon: DEFAULT_ALERT_HISTORY_ITEM_ICON,
	},
	{
		id: 'Classification',
		icon: DEFAULT_ALERT_HISTORY_ITEM_ICON,
	},
	{
		id: 'Determination',
		icon: DEFAULT_ALERT_HISTORY_ITEM_ICON,
	},
	{
		id: 'Create',
		icon: 'alert',
	},
	{
		id: 'LinkAlertToIncident',
		icon: DEFAULT_ALERT_HISTORY_ITEM_ICON,
	},
	{
		id: 'UnlinkAlertFromIncident',
		icon: DEFAULT_ALERT_HISTORY_ITEM_ICON,
	},
	{
		id: 'Title',
		icon: DEFAULT_ALERT_HISTORY_ITEM_ICON,
	},
];
