import { TvmColorService, TvmColor } from '../../services/tvm-color.service';
import { TzDateService } from '@wcd/localization';
import { PrettyNumberService } from '@wcd/prettify';
import { I18nService } from '@wcd/i18n';
var TvmHistoryTrendComponent = /** @class */ (function () {
    function TvmHistoryTrendComponent(i18nService, tvmColorService, tzDateService, prettyNumberService) {
        this.i18nService = i18nService;
        this.tvmColorService = tvmColorService;
        this.tzDateService = tzDateService;
        this.prettyNumberService = prettyNumberService;
    }
    Object.defineProperty(TvmHistoryTrendComponent.prototype, "options", {
        set: function (tvmHistoryTrendOptions) {
            // Include only up to 30 days of history data
            // TODO: evhvoste - temp solution for rebranding - will be removed with sca - events implementation - VSTS task 25356461
            this._isSecureScore =
                tvmHistoryTrendOptions.title ===
                    this.i18nService.get('tvm.secureScoreWidget.secureScoreOverTime');
            this._historyData = tvmHistoryTrendOptions.data.slice(0, 30);
            this._tvmHistoryTrendOptions = tvmHistoryTrendOptions;
            this.setTrend();
            this.setTimeSeriesChartSettings();
            this.setAdvancement();
        },
        enumerable: true,
        configurable: true
    });
    TvmHistoryTrendComponent.prototype.setAdvancement = function () {
        if (!this._tvmHistoryTrendOptions.advancement.show) {
            return;
        }
        var advancement = this.getAdvancement();
        var graphColorClass = this.tvmColorService.fontColorsClassMap.get(this._tvmHistoryTrendOptions.graphColor.color);
        if (advancement < 0) {
            this._advancement = {
                advancement: this.prettyNumberService.prettyNumber(advancement * -1),
                iconName: 'StockDown',
                contentClass: this._tvmHistoryTrendOptions.advancement.byImprovement
                    ? this.tvmColorService.fontColorsClassMap.get(TvmColor.Positive)
                    : graphColorClass,
            };
        }
        else {
            this._advancement = {
                advancement: this.prettyNumberService.prettyNumber(advancement),
                iconName: 'StockUp',
                contentClass: this._tvmHistoryTrendOptions.advancement.byImprovement
                    ? this.tvmColorService.fontColorsClassMap.get(TvmColor.HighRisk)
                    : graphColorClass,
            };
        }
    };
    TvmHistoryTrendComponent.prototype.setTrend = function () {
        if (!this._tvmHistoryTrendOptions) {
            this._trend = null;
            return;
        }
        this._trend = this._historyData.map(function (value, index) {
            return {
                value: value,
                date: moment() // Local date
                    .utc()
                    .subtract({ days: index })
                    .startOf('day')
                    .toDate(),
            };
        });
        this._numberOfDays = this._trend.length;
    };
    TvmHistoryTrendComponent.prototype.getAdvancement = function () {
        var historyDays = this._trend.length;
        return historyDays > 0 ? Math.round(this._trend[0].value - this._trend[historyDays - 1].value) : 0;
    };
    TvmHistoryTrendComponent.prototype.getGraphColor = function () {
        if (!this._tvmHistoryTrendOptions.graphColor.byImprovement) {
            return this.tvmColorService.colorsMap.get(this._tvmHistoryTrendOptions.graphColor.color).raw;
        }
        var historyImprovement = this.getAdvancement();
        return historyImprovement > 0
            ? this.tvmColorService.colorsMap.get(TvmColor.HighRisk).raw
            : this.tvmColorService.colorsMap.get(TvmColor.Positive).raw;
    };
    /**
     * Creates Y Axis based on max / min and
     * In case of undefined max/min
     * 1. We defined max and min, closer to the trend, but not exactly on it, so it looks good
     *
     */
    TvmHistoryTrendComponent.prototype.getYAxisConfig = function () {
        var yAxisValues = this._tvmHistoryTrendOptions.yAxisValues;
        if (!yAxisValues.show) {
            return { show: false };
        }
        var max;
        var min;
        if (yAxisValues.autoDetect) {
            var maxValue = Math.max.apply(Math, this._historyData) + 1;
            var minValue = Math.min.apply(Math, this._historyData);
            // To make sure the graph looks good
            // We create Max and Min that the trend doesn't touch
            // Example Max value 1500 -> 100 *5
            // Then we take the 500 and found nearest for 1500 -> New Max = 2000
            // For min value 800 we found nearest 500 -> New min = 500
            var pow = maxValue.toString().length - 2;
            var closerRounding = Math.pow(10, pow > 0 ? pow : 0) * 5;
            max = Math.ceil(maxValue / closerRounding) * closerRounding;
            min = Math.floor(minValue / closerRounding) * closerRounding;
        }
        else {
            (max = yAxisValues.max), (min = yAxisValues.min);
        }
        var yAxis = [];
        if (yAxisValues.tickValues) {
            yAxis = yAxisValues.tickValues;
        }
        else {
            for (var i = 0; i < yAxisValues.count; i++) {
                yAxis.push(min + ((max - min) * i) / (yAxisValues.count - 1));
            }
        }
        var axisValuesSuffix = this._isSecureScore ? '%' : '';
        return {
            max: max,
            min: min,
            show: true,
            tick: {
                format: function (value) { return Math.round(value).toString() + axisValuesSuffix; },
                values: yAxis,
                count: yAxis.length,
            },
        };
    };
    TvmHistoryTrendComponent.prototype.getToolTipOptions = function () {
        var _this = this;
        return (this._tvmHistoryTrendOptions.toolTipOptions || {
            format: {
                title: function (value) { return _this.tzDateService.format(value, 'MM/dd'); },
            },
        });
    };
    TvmHistoryTrendComponent.prototype.getDataConfig = function () {
        if (this._tvmHistoryTrendOptions.area) {
            return {
                types: {
                    value: 'area',
                },
                classes: {
                    value: 'tvm-area-trend',
                },
            };
        }
        return {};
    };
    TvmHistoryTrendComponent.prototype.setTimeSeriesChartSettings = function () {
        var _this = this;
        if (!this._trend || this._trend.length === 0) {
            this._trendSettings = null;
            return;
        }
        this._trendSettings = {
            useValues: false,
            xProperty: 'date',
            setColors: true,
            series: [
                {
                    value: 'value',
                    name: this._tvmHistoryTrendOptions.legend,
                    color: this.getGraphColor(),
                },
            ],
            options: {
                transition: {
                    duration: 0,
                },
                data: this.getDataConfig(),
                axis: {
                    x: {
                        tick: {
                            format: function (value) { return _this.tzDateService.format(value, 'MM/dd'); },
                            count: this._tvmHistoryTrendOptions.xAxisValues.count,
                        },
                        show: this._tvmHistoryTrendOptions.xAxisValues.show,
                    },
                    y: this.getYAxisConfig(),
                },
                legend: {
                    show: false,
                },
                size: {
                    width: this._tvmHistoryTrendOptions.width,
                    height: this._tvmHistoryTrendOptions.height,
                },
                grid: {
                    y: {
                        show: this._tvmHistoryTrendOptions.showYGrid,
                    },
                    x: {
                        show: false,
                    },
                },
                point: {
                    show: false,
                },
                tooltip: this.getToolTipOptions(),
            },
        };
    };
    return TvmHistoryTrendComponent;
}());
export { TvmHistoryTrendComponent };
