<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="missingKb" class="tvm wcd-scroll-vertical" data-track-component="missingKb">
	<div *ngIf="isRedHatProductFix" class="tvm-list__block_item">
		<fab-message-bar
		[messageBarType]="LegalNoticeMessageBarType.info"
		[isMultiline]="false"
		[truncated]="true"
		[overflowButtonAriaLabel]="i18nService.strings.common_legalNotice + ', ' + i18nService.strings.entityCommon_panel_impactedEntities_showMore"
		[styles]="getLegalNoticeStyles.bind(this)"
		>
			<div>{{"common.legalNotice" | i18n}}</div><div class="wcd-margin-top" *ngIf="legalNoticeMessageBarState.showBody" [innerHTML]="legalNoticeText"></div>
		</fab-message-bar>
	</div>
	<div *ngIf="showEolMessage" class="tvm-list__block_item">
		<fab-message-bar
			[messageBarType]="messageBarType"
			[isMultiline]="false"
			[truncated]="shouldShowLegalNote"
			[overflowButtonAriaLabel]="i18nService.strings.entityCommon_panel_impactedEntities_showMore"
			[styles]="getStyles.bind(this)">
			<div class="spaced">{{eolText}}{{'tvm.securityRecommendation.goToSoftwarePagePrefix' | i18n}}<a (click)="openSoftwareVersions()">{{softwareVersionsLinkText}}</a> {{i18nService.strings.tvm_securityRecommendation_goToSoftwarePagePostfix}}</div>
			<div class="wcd-margin-top" *ngIf="messageBarState.showBody">{{legalNote}}<ng-container *ngIf="showLegalNoteLink"><a [href]="legalNoteLink">{{legalNoteLinkText}}</a></ng-container></div>
		</fab-message-bar>
	</div>
	<div class="wcd-scroll-vertical wcd-margin-large-top">
		<div class="tvm-list">
			<div class="tvm-list__key_val_item">
				<dt class="wcd-font-weight-semibold">{{ missingKbNameText }}</dt>
				<dd class="nowrap">
					<a *ngIf="missingKb.productFixUrl; else showNoAvailableShort" href="{{missingKb.productFixUrl}}" target="_blank">
						{{missingKb.productFixId || ('notAvailable.short' | i18n)}}</a>
				</dd>
			</div>
			<div class="tvm-list__key_val_item">
				<dt class="wcd-font-weight-semibold">{{ ('tvm.missingKb.osVersion' | i18n) }}</dt>
				<dd>
					{{missingKb.osVersion || ('notAvailable.short' | i18n)}}
				</dd>
			</div>
			<div class="tvm-list__key_val_item">
				<dt class="wcd-font-weight-semibold">{{ 'tvm_missingKb_machineMissedOn' | i18n }}</dt>
				<dd>
					{{missingKb.missingMachines | prettyNumber}}
				</dd>
			</div>
		</div>
	</div>
	<div *ngIf="!machineDetails.singleAssetMissingKb">
		<exposed-machines-details
			[assets$]="assets$"
			[includeOperatingSystem]=isExposedOperatingSystem
			(showMore)=openAllExposedMachines()
			(export)="exportExposedMachines()">
		</exposed-machines-details>
		<related-cves-details
			[repository]="missingKbRelatedCvesRepository"
			[title]="'tvm.missingKb.cvesAddressed.title' | i18n"
			[maxItems]="MaxItemsInCollapseExpandTable"
			[contextOptions]="contextOptions"
			[includeExposedMachinesComponent]=true
			(export)="exportCves()"
			(showMore)=openAllRelatedCves(false)>
		</related-cves-details>
	</div>
	<div *ngIf="machineDetails.singleAssetMissingKb">
		<related-cves-details
			[repository]="assetVulnerabilitiesRepository"
			[title]="'tvm.missingKb.cvesAddressed.title' | i18n"
			[maxItems]="MaxItemsInCollapseExpandTable"
			[contextOptions]="contextOptions"
			[includeExposedMachinesComponent]=false
			(export)="exportCves()"
			(showMore)=openAllRelatedCves(false)>
		</related-cves-details>
	</div>
</div>

<ng-template #showNoDataAvailable>
	{{'common.noDataAvailable' | i18n}}
</ng-template>

<ng-template #showNoAvailableShort>
	{{'notAvailable.short' | i18n}}
</ng-template>

