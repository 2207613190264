import { TvmColorService, TvmColor } from '../../services/tvm-color.service';
import { PrettyNumberService } from '@wcd/prettify';
var MAX_DAYS_OF_HISTORY = 30;
var TvmScoreTrendComponent = /** @class */ (function () {
    function TvmScoreTrendComponent(tvmColorService, prettyNumberService) {
        this.tvmColorService = tvmColorService;
        this.prettyNumberService = prettyNumberService;
    }
    Object.defineProperty(TvmScoreTrendComponent.prototype, "options", {
        set: function (trendOptions) {
            // Include only up to 30 days of history data
            var scoreHistory = trendOptions.scoreHistory.slice(0, MAX_DAYS_OF_HISTORY);
            this.historyDays = scoreHistory.length;
            var advancement = this.historyDays > 0 ? Math.round(scoreHistory[0] - scoreHistory[this.historyDays - 1]) : 0;
            var negativeTrend = (advancement < 0 && !trendOptions.lowerScoreBetter) ||
                (advancement > 0 && trendOptions.lowerScoreBetter);
            var defaultColoring = negativeTrend ? TvmColor.HighRisk : TvmColor.Positive;
            this.iconName = advancement < 0 ? 'StockDown' : 'StockUp';
            if (advancement < 0) {
                advancement = advancement * -1;
            }
            this.advancementScore = this.prettyNumberService.prettyNumber(advancement);
            this.iconColorClass = this.tvmColorService.fontColorsClassMap.get(trendOptions.iconColor ? trendOptions.iconColor : defaultColoring);
            this.title = trendOptions.title;
        },
        enumerable: true,
        configurable: true
    });
    return TvmScoreTrendComponent;
}());
export { TvmScoreTrendComponent };
