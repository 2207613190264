import { MailCluster } from '@wcd/domain';
import { Lazy } from '../../../utils/lazy';
import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { FabricIconNames } from '@wcd/scc-common';

@Injectable()
export class MailClusterEntityTypeService implements EntityTypeService<MailCluster> {
	private readonly _entityType = new Lazy<Readonly<EntityType<MailCluster>>>(() => ({
		entity: MailCluster,
		id: 'mailCluster',
		icon: FabricIconNames.CreateMailRule,
		getEntityName: mailCluster => mailCluster.name,
		getEntitiesLink: mailClusters => {
			if (!mailClusters || mailClusters.length !== 1) {
				return null;
			}

			const [mailmailCluster] = mailClusters;
			return mailmailCluster.mailClusterPageUrl;
		},
		getNavigationModel: (mailCluster: MailCluster) => ({
			externalLink: mailCluster.mailClusterPageUrl,
		}),
		getUseExternalRouting: (mailClusters: Array<MailCluster>) => true,
	}));

	get entityType(): Readonly<EntityType<MailCluster>> {
		return this._entityType.value;
	}
}
