/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./machine-itp-spns.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "./machine-itp-spns.component";
var styles_MachineSpnsComponent = [i0.styles];
var RenderType_MachineSpnsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MachineSpnsComponent, data: {} });
export { RenderType_MachineSpnsComponent as RenderType_MachineSpnsComponent };
function View_MachineSpnsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t\t", "\n\t\t\t"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_MachineSpnsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [["class", "wcd-padding-none-all wcd-machine-spns-list"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MachineSpnsComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spns; _ck(_v, 3, 0, currVal_0); }, null); }
function View_MachineSpnsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["\n\t\t\t", "\n\t\t"])), i1.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), "machines.entityDetails.fields.spns.noneFound")); _ck(_v, 0, 0, currVal_0); }); }
export function View_MachineSpnsComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MachineSpnsComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(0, [["noneFound", 2]], null, 0, null, View_MachineSpnsComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.spns == null) ? null : _co.spns.length); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_MachineSpnsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "machine-itp-spns", [], null, null, null, View_MachineSpnsComponent_0, RenderType_MachineSpnsComponent)), i1.ɵdid(1, 49152, null, 0, i5.MachineSpnsComponent, [], null, null)], null, null); }
var MachineSpnsComponentNgFactory = i1.ɵccf("machine-itp-spns", i5.MachineSpnsComponent, View_MachineSpnsComponent_Host_0, { spns: "spns" }, {}, []);
export { MachineSpnsComponentNgFactory as MachineSpnsComponentNgFactory };
