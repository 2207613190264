import { merge } from 'lodash-es';

export class SnackBarMethod {
	readonly text: string;
	readonly icon: string;
	readonly className: string;
	readonly method: (...args: any[]) => any;

	constructor(data: SnackBarMethodConfig) {
		merge(this, data);
	}
}

export interface SnackBarMethodConfig {
	readonly text: string;
	readonly method: (...args: any[]) => any;
	readonly icon?: string;
	readonly className?: string;
}
