import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Mail stats',
	pluralName: '',
	readonly: true,
})
export class MailStats extends ModelBase {
	@EntityField({ data: 'NumberOfInboxes' })
	readonly numberOfInboxes: number;

	@EntityField({ data: 'FirstSeen' })
	readonly firstSeen: Date;

	@EntityField({ data: 'LastSeen' })
	readonly lastSeen: Date;

	@EntityField({ data: 'Sender' })
	readonly sender: string;

	@EntityField({ data: 'NumberOfSenders' })
	readonly numberOfSenders: number;

	@EntityField({ data: 'Recepients' })
	readonly recipients: number;

	@EntityField({ data: 'DeepLinkToO365Portal' })
	readonly deepLinkToO365Portal: string;
}
