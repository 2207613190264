var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityRelationshipConfigurations } from '../analyticsEndPointUtils';
import { BaselineConfiguration } from './baseline-profile-configuration/baseline-configuration.entity';
import { BaselineProfileDevice } from './baseline-profile-device/baseline-profile-device.entity';
var ɵ0 = function (_, query) { return "baseline/allSettings/" + query.where['id'] + "/devices"; }, ɵ1 = function (configuration) { return ({ id: configuration.id }); };
var BaselineConfigurationBaselineProfileDeviceRelationship = /** @class */ (function () {
    function BaselineConfigurationBaselineProfileDeviceRelationship() {
    }
    BaselineConfigurationBaselineProfileDeviceRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: BaselineConfiguration, dataEntity: BaselineProfileDevice, endpoint: ɵ0, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ1 }))
    ], BaselineConfigurationBaselineProfileDeviceRelationship);
    return BaselineConfigurationBaselineProfileDeviceRelationship;
}());
export { BaselineConfigurationBaselineProfileDeviceRelationship };
export { ɵ0, ɵ1 };
