import { ChangeDetectorRef, Component } from '@angular/core';
import { LiveResponseCommand, LiveResponseCommandType, LiveResponseSession } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { LiveResponseService } from '../services/live-response.service';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { filter, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LiveResponseOutputParserService } from '../services/live-response-output-parser.service';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'live-response-command-entity-panel',
	template: `
		<div class="wcd-scroll-vertical">
			<!--details-->
			<section class="wcd-padding-large-horizontal wcd-padding-vertical">
				<div class="wcd-flex wcd-flex-wrap">
					<dl role="none">
						<!--Started-->
						<dt>{{ i18nService.strings.liveResponse_command_panel_startedOn }}</dt>
						<dd>{{ command.startTime | date: 'medium' }}</dd>

						<!--Entities-->
						<dt>{{ i18nService.strings.liveResponse_command_panel_entities }}</dt>
						<dd>{{ command.entityCount || 0 }}</dd>

						<!--Name-->
						<dt>{{ i18nService.strings.liveResponse_command_panel_commandName }}</dt>
						<dd>{{ (commandType$ | async)?.displayName }}</dd>
					</dl>
					<dl role="none">
						<!--Completed-->
						<dt>{{ i18nService.strings.liveResponse_command_panel_completedOn }}</dt>
						<dd>
							<ng-container *ngIf="command.endTime; else notAvailable">
								{{ command.endTime | date: 'medium' }}
							</ng-container>
							<ng-template #notAvailable>
								{{ i18nService.strings.notAvailable_short }}
							</ng-template>
						</dd>

						<!--Status-->
						<dt>{{ i18nService.strings.liveResponse_command_panel_status }}</dt>
						<dd>{{ command.status.name }}</dd>

						<!--Script name?-->
						<!--<dt>Script name</dt>-->
						<!--<dd></dd>-->
					</dl>
				</div>
			</section>
			<!--input-->
			<wcd-collapsible-section
				*ngIf="command.rawCommand"
				[label]="i18nService.strings.liveResponse_command_panel_section_input"
				[sectionId]="'live-response-command-panel-command-Input'"
			>
				<ng-container
					*ngTemplateOutlet="
						displayIO;
						context: {
							text: i18nService.strings.liveResponse_command_panel_section_inputLink,
							str: command.rawCommand
						}
					"
				>
				</ng-container>
			</wcd-collapsible-section>
			<!--output-->
			<wcd-collapsible-section
				*ngIf="commandOutput"
				[label]="i18nService.strings.liveResponse_command_panel_section_output"
				[sectionId]="'live-response-command-panel-command-Output'"
			>
				<ng-container
					*ngTemplateOutlet="
						displayIO;
						context: {
							text: i18nService.strings.liveResponse_command_panel_section_outputLink,
							str: commandOutput
						}
					"
				>
				</ng-container>
			</wcd-collapsible-section>
			<ng-template #displayIO let-text="text" let-str="str">
				<pre class="code wcd-full-width">{{ str }}</pre>
				<fab-action-button
					(onClick)="openIoInNewTab(str)"
					[text]="text"
					[iconProps]="{ iconName: 'OpenInNewWindow' }"
				>
				</fab-action-button>
			</ng-template>
		</div>
	`,
	styles: [
		`
			dl:first-child {
				margin-right: 7rem;
			}

			dt {
				font-weight: bold;
			}

			pre {
				max-height: 270px;
				overflow: hidden;
				margin-bottom: 0px;
			}
		`,
	],
})
export class LiveResponseCommandEntityPanelComponent extends EntityPanelComponentBase<LiveResponseCommand> {
	constructor(
		protected changeDetectorRef: ChangeDetectorRef,
		private liveResponseService: LiveResponseService,
		private activatedEntity: ActivatedEntity,
		public i18nService: I18nService
	) {
		super(changeDetectorRef);
	}

	commandType$: Observable<LiveResponseCommandType>;
	commandOutput: string;

	get command(): LiveResponseCommand {
		return this.entity;
	}

	setEntity(entity: LiveResponseCommand, isExtendedData: boolean = false): void {
		super.setEntity(entity, isExtendedData);
		this.commandType$ =
			this.command.commandTypeId &&
			this.activatedEntity.currentEntity$.pipe(
				filter(_entity => _entity instanceof LiveResponseSession),
				switchMap((session: LiveResponseSession) =>
					this.liveResponseService.findMatchingCommand(session, entity.commandTypeId)
				)
			);
		this.commandOutput = LiveResponseOutputParserService.getCommandOutput(entity);
	}

	openIoInNewTab(data: string) {
		const newTab: Window = window.open('', '_blank');
		newTab.document.open();
		newTab.document.write('<html><body><pre></pre></body></html>');
		const pre: HTMLElement = newTab.document.querySelector('pre');
		pre.innerText = data;
		newTab.document.close();
		newTab.focus();
	}
}
