import { AirsNotificationRuleTypeModel } from '../models/airs-notification-rule-type.model';
import { Dictionary } from '@wcd/config';

const NOTIFICATION_RULE_TYPES: Array<AirsNotificationRuleTypeModel> = [
	{ id: 0, name: 'Investigation Notifications', allowConditions: true },
	{ id: 1, name: 'AIRS Notifications', allowConditions: false },
	{ id: 2, name: 'Reports', allowConditions: false, requireSchedule: true },
].map(notificationRuleTypeConfig => new AirsNotificationRuleTypeModel(notificationRuleTypeConfig));

const NOTIFICATION_RULE_TYPES_DICTIONARY = Dictionary.fromList<number, AirsNotificationRuleTypeModel>(
	NOTIFICATION_RULE_TYPES,
	'id'
);

export class AirsNotificationRuleTypesService {
	static get allTypes(): Array<AirsNotificationRuleTypeModel> {
		return NOTIFICATION_RULE_TYPES;
	}

	static get default(): AirsNotificationRuleTypeModel {
		return NOTIFICATION_RULE_TYPES[0];
	}

	static getNotificationRuleTypeById(id: number): AirsNotificationRuleTypeModel {
		return NOTIFICATION_RULE_TYPES_DICTIONARY.get(id);
	}

	static get reportsNotificationRuleType() {
		return NOTIFICATION_RULE_TYPES[2];
	}
}
