/* tslint:disable:template-accessibility-label-for */
import { Component, Input, OnInit, ViewChild, forwardRef, OnChanges, Output, EventEmitter, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import {
	HuntingQuerySupportedAction,
	CustomActionMachineIsolationType,
	HuntingRuleCustomSupportedActionType,
	HuntingRuleEntityType,
	CustomActionAssetType,
	HuntingCustomAction,
	EmailActionType,
} from '@wcd/domain';
import { ChecklistValue } from '@wcd/forms';
import { HuntingRuleScope } from '../../@entities/scheduled_hunting/models/hunting-rule-scope.model';
import { map } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { RbacService } from '../../rbac/services/rbac.service';
import { Paris } from '@microsoft/paris';
import { AuthService } from '@wcd/auth';
import {
	NgForm,
	AbstractControl,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
	Validator,
	ValidationErrors,
	NG_VALIDATORS,
} from '@angular/forms';
import { lowerFirst, keyBy, groupBy, mapValues } from 'lodash-es';
import { Dictionary } from 'lodash';
import { ExpanderComponent } from '@wcd/expander';

//#region View models
class EntitySelection {
	entityType: HuntingRuleEntityType;
	selectableIds: string[];
	hideSelectionOnSingleOption?: boolean;

	private _selectedId?: string;
	set selectedId(value: string) {
		this._selectedId = value;
	}
	get selectedId(): string {
		if (
			!this._selectedId &&
			this.selectableIds &&
			this.selectableIds.length === 1 &&
			this.hideSelectionOnSingleOption
		) {
			this._selectedId = this.selectableIds[0];
		}
		return this._selectedId;
	}

	get showSelection(): boolean {
		return !(this.hideSelectionOnSingleOption && this.selectableIds && this.selectableIds.length === 1);
	}

	constructor(data: Partial<EntitySelection>) {
		Object.assign(this, data);
	}
}

class CustomActionSelection<TOptions extends string | number = any> {
	actionType: HuntingRuleCustomSupportedActionType;
	name: string;
	id: string;
	category: CustomActionAssetType;
	disabled: boolean;
	checked?: boolean;
	entities?: EntitySelection[];
	mutuallyExclusiveWith?: string[]

	// Optional - options that configure the action. Are shown as radio buttons.
	options?: ChecklistValue<TOptions>[];
	selectedOption?: ChecklistValue<TOptions>;

	constructor(config: Partial<CustomActionSelection>) {
		Object.assign(this, config);
	}

	fromCustomActions(actions: Array<HuntingCustomAction>) {
		const action = actions && actions.find(a => a.actionType === this.actionType);

		this.checked = action && !this.disabled;

		if (this.entities) {
			this.entities.forEach(entity => {
				const actionEntity =
					action &&
					action.entities &&
					action.entities.find(e => e.entityType === entity.entityType);
				entity.selectedId =
					actionEntity && entity.selectableIds.find(id => id === actionEntity.entityIdField);
			});
		}
	}

	toCustomAction(): HuntingCustomAction {
		if (this.checked && (this.entities || []).every(e => e.selectedId != null)) {
			return {
				actionType: this.actionType,
				entities:
					this.entities &&
					this.entities.map(entity => ({
						entityType: entity.entityType,
						entityIdField: entity.selectedId,
					})),
			};
		}
		return null;
	}
}
// Action are organized into categories (device actions, file actions, etc.)
// Represents UI state of an action category
interface ActionCategory {
	isExpanded?: boolean;
}

class IsolateMachineActionSelection extends CustomActionSelection<CustomActionMachineIsolationType> {
	fromCustomActions(actions: Array<HuntingCustomAction>) {
		super.fromCustomActions(actions);

		const action = actions && actions.find(a => a.actionType === this.actionType);
		const isolationType = action && action.additionalFields && action.additionalFields.isolationType;
		this.selectedOption =
			(isolationType != null && this.options.find(type => type.id === isolationType)) ||
			this.options[0];
	}

	toCustomAction(): HuntingCustomAction {
		if (this.selectedOption != null) {
			const action = super.toCustomAction();
			return (
				action && {
					...action,
					additionalFields: {
						isolationType: this.selectedOption.id,
					},
				}
			);
		}
		return null;
	}
}

class EmailActionSelection extends CustomActionSelection<EmailActionType> {
	fromCustomActions(actions: Array<HuntingCustomAction>) {
		super.fromCustomActions(actions);

		const action = actions && actions.find(a => a.actionType === this.actionType);
		const emailActionType = action && action.additionalFields && action.additionalFields.emailActionType;
		this.selectedOption =
			(emailActionType != null && this.options.find(type => type.id === emailActionType)) ||
			this.options[0];
	}

	toCustomAction(): HuntingCustomAction {
		if (this.selectedOption != null) {
			const action = super.toCustomAction();
			return (
				action && {
					...action,
					additionalFields: {
						emailActionType: this.selectedOption.id,
					},
				}
			);
		}
		return null;
	}
}

class AllowOrBlockFileActionSelection extends CustomActionSelection<HuntingRuleCustomSupportedActionType> {
	constructor(config: Partial<AllowOrBlockFileActionSelection>) {
		super(config);

		this.options = this.supportedActions.map(action => ({
			id: action.actionType,
			name: action.name,
		}));

		this.disabled = this.supportedActions[0].disabled;
		this.entities = this.supportedActions[0].entities;
	}

	rbacGroupIds?: number[];

	readonly supportedActions: CustomActionSelection[];

	selectableScopes: ChecklistValue[];

	private _selectedScope: ChecklistValue;

	private _selectedActionType: ChecklistValue<HuntingRuleCustomSupportedActionType>;

	get selectedOption() {
		return this._selectedActionType;
	}

	set selectedOption(value: ChecklistValue<HuntingRuleCustomSupportedActionType>) {
		this._selectedActionType = value;
		this.actionType = value.id;
	}

	get selectedScope() {
		return this._selectedScope;
	}

	set selectedScope(value: ChecklistValue) {
		if (value && value.id === HuntingRuleScope.all) {
			this.rbacGroupIds = null;
		}
		this._selectedScope = value;
	}

	get isAllMachinesScopeSelected() {
		return this.selectedScope && this.selectedScope.id === HuntingRuleScope.all;
	}

	fromCustomActions(actions: Array<HuntingCustomAction>) {
		const ids = this.options.map(t => t.id);
		const action = actions && actions.find(a => ids.includes(a.actionType));
		this.selectedOption =
			(action && this.options.find(({ id }) => action.actionType === id)) || this.options[0];

		super.fromCustomActions(actions);

		this.rbacGroupIds = action && action.additionalFields && action.additionalFields.rbacGroupIds;
		this._selectedScope =
			(this.rbacGroupIds &&
				this.rbacGroupIds.length &&
				this.selectableScopes.find(scope => scope.id === HuntingRuleScope.specific)) ||
			this.selectableScopes[0];
	}

	toCustomAction(): HuntingCustomAction {
		const customAction = super.toCustomAction();
		return (
			customAction && {
				...customAction,
				additionalFields: {
					rbacGroupIds: this.isAllMachinesScopeSelected ? null : this.rbacGroupIds,
				},
			}
		);
	}
}

//#endregion

type onChangeFn = (value: Array<HuntingCustomAction>) => void;

@Component({
	selector: 'hunting-custom-actions',
	templateUrl: './hunting-custom-actions.component.html',
	providers: [
		// Register the component as a validator for use by angular forms validation
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => HuntingCustomActionsComponent),
			multi: true,
		},
		// Register the component as a value accessor for use by ngModel
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => HuntingCustomActionsComponent),
			multi: true,
		},
	],
})
export class HuntingCustomActionsComponent implements OnInit, AfterViewInit, OnChanges, Validator, ControlValueAccessor {
	@Input() supportedActions: Array<HuntingQuerySupportedAction>;

	@Input() allowMultiSelect: boolean = true;

	@Output() readonly anyExpandedChange = new EventEmitter<boolean>();

	actions: Array<HuntingCustomAction>;

	onActionsChange: onChangeFn;

	userSelections: { [index: string]: CustomActionSelection };

	actionCategories: Dictionary<ActionCategory>;

	rbacGroups$: Observable<Array<ChecklistValue>>;

	CustomActionAssetType = CustomActionAssetType;

	@ViewChild('actionsForm', { static: true }) readonly actionsForm: NgForm;
	@ViewChildren(ExpanderComponent) readonly expenders: QueryList<ExpanderComponent>;

	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private rbacService: RbacService,
		private authService: AuthService
	) { }

	ngOnInit() {
		this.rbacGroups$ = this.rbacService.userExposedRbacGroups$.pipe(
			map(groups =>
				(groups || []).map(group => ({
					id: group.id,
					name: group.isUnassignedMachineGroup
						? this.i18nService.get('machineGroup.unassignedGroup.name')
						: group.name,
				}))
			)
		);
	}

	ngAfterViewInit(){
		this.expenders.first.focusExpender();
	}

	ngOnChanges() {
		this.initializeCustomActions();
	}

	//#region Angular forms integration: ngModel and validation
	writeValue(actions: Array<HuntingCustomAction>): void {
		this.actions = actions;
		this.applySelection(actions);
	}

	registerOnChange(fn: onChangeFn): void {
		this.onActionsChange = fn;
	}

	registerOnTouched(fn: any): void { }

	validate(control: AbstractControl): ValidationErrors {
		const valid = this.actionsForm && this.actionsForm.valid;
		return valid ? null : { error: 'invalid custom action selection' };
	}
	//#endregion

	private initializeCustomActions() {
		const allActions: { [index: string]: CustomActionSelection } = {
			isolateMachine: new IsolateMachineActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.IsolateMachine,
				category: CustomActionAssetType.Machine,
				options: Object.keys(CustomActionMachineIsolationType)
					.filter(key => parseInt(key) >= 0)
					.map(key => ({
						id: +key,
						name: this.i18nService.get(
							`hunting.scheduledMonitorSidePane.fields.customActions.machineIsolationTypes.${CustomActionMachineIsolationType[
								key
							].toLowerCase()}`
						),
					})),
			}),
			collectInvestigationPackage: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.CollectInvestigationPackage,
				category: CustomActionAssetType.Machine,
			}),
			runAntivirusScan: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.RunAntivirusScan,
				category: CustomActionAssetType.Machine,
			}),
			initiateInvestigation: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.InitiateInvestigation,
				category: CustomActionAssetType.Machine,
			}),
			restrictExecution: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.RestrictExecution,
				category: CustomActionAssetType.Machine,
			}),
			allowFile: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.AllowFile,
				category: CustomActionAssetType.File,
			}),
			blockFile: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.BlockFile,
				category: CustomActionAssetType.File,
			}),
			quarantineFile: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.QuarantineFile,
				category: CustomActionAssetType.File,
			}),
			markUserAsCompromised: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.MarkUserAsCompromised,
				category: CustomActionAssetType.User,
			}),
			disableUser: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.DisableUser,
				category: CustomActionAssetType.User,
			}),
			forceUserPasswordReset: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.ForceUserPasswordReset,
				category: CustomActionAssetType.User,
			}),
			moveEmailToFolder: new EmailActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.MoveEmailToFolder,
				category: CustomActionAssetType.Email,
				options: [
					EmailActionType.MoveToJunk,
					EmailActionType.MoveToInbox,
					EmailActionType.MoveToDeletedItems,
				].map(key => ({
					id: key,
					name: this.i18nService.get(
						`hunting_customActions_emailSubActionTypes_${lowerFirst(EmailActionType[key])}`
					),
				})),
				mutuallyExclusiveWith: ['deleteEmail']
			}),
			deleteEmail: new EmailActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.DeleteEmail,
				category: CustomActionAssetType.Email,
				options: [EmailActionType.Delete, EmailActionType.SoftDelete].map(key => ({
					id: key,
					name: this.i18nService.get(
						`hunting_customActions_emailSubActionTypes_${lowerFirst(EmailActionType[key])}`
					),
				})),
				mutuallyExclusiveWith: ['moveEmailToFolder']
			}),
			blockEmailSender: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.BlockEmailSender,
				category: CustomActionAssetType.Email,
			}),
			blockEmailUrl: new CustomActionSelection({
				actionType: HuntingRuleCustomSupportedActionType.BlockEmailUrl,
				category: CustomActionAssetType.Url,
			}),
		};

		const supportedActions = this.supportedActions || [];
		const entitiesWithSingleId = [
			HuntingRuleEntityType.Machine,
			HuntingRuleEntityType.MailMessage,
			HuntingRuleEntityType.Mailbox,
			HuntingRuleEntityType.Url,
		];
		// Only supported actions are shown
		const visibleActions: Array<CustomActionSelection> = supportedActions
			.map((supportedAction: HuntingQuerySupportedAction) => {
				// Get action ID, based on action type, for finding the relevant action. e.g. BlockFile => blockFile
				const actionId = lowerFirst(HuntingRuleCustomSupportedActionType[supportedAction.actionType]);
				const action = allActions[actionId];

				if (action) {
					action.id = actionId;
					action.disabled = supportedAction.isEnabled === false;
					action.name =
						action.name ||
						this.i18nService.get(`hunting.customDetections.actions.${action.actionType}`);
					action.entities =
						supportedAction.entities &&
						supportedAction.entities.map(
							e =>
								new EntitySelection({
									entityType: e.entityType,
									selectableIds: e.idFields,
									hideSelectionOnSingleOption: entitiesWithSingleId.includes(e.entityType),
								})
						);
					return action;
				}
			})
			.filter(Boolean);

		const actionsMap: { [index: string]: CustomActionSelection } = keyBy(visibleActions, 'id');

		if (actionsMap.allowFile && actionsMap.blockFile) {
			actionsMap.allowOrBlockFile = new AllowOrBlockFileActionSelection({
				name: this.i18nService.get(
					'hunting.scheduledMonitorSidePane.fields.customActions.allowOrBlock'
				),
				id: 'allowOrBlockFile',
				category: CustomActionAssetType.File,
				supportedActions: [actionsMap.allowFile, actionsMap.blockFile],
				selectableScopes: this.getSelectableMachineScopes(),
			});

			delete actionsMap.allowFile;
			delete actionsMap.blockFile;
		}

		this.userSelections = actionsMap;

		const actionsByCategory = groupBy(visibleActions, action => action.category);
		this.actionCategories = mapValues(actionsByCategory, actions => ({ isExpanded: false }));

		this.applySelection(this.actions);
	}

	private applySelection(selectedActions: Array<HuntingCustomAction>) {
		if (this.userSelections) {
			selectedActions = selectedActions || [];
			if (!this.allowMultiSelect) {
				selectedActions = selectedActions.slice(0, 1);
			}

			Object.values(this.userSelections).forEach(action => {
				action.fromCustomActions(selectedActions);

				if (action.mutuallyExclusiveWith &&
					action.mutuallyExclusiveWith.some(exclusiveActionId => this.userSelections[exclusiveActionId].checked)) {
					// another mutually exclusive action is selected
					action.checked = false;
				}

				if (action.checked) {
					this.actionCategories[action.category].isExpanded = true;
				}
			});

			this.onCustomActionChange();
		}
	}

	private getSelectableMachineScopes(): ChecklistValue[] {
		const scopes = this.authService.currentUser.isMdeAdmin
			? Object.keys(HuntingRuleScope)
			: Object.keys(HuntingRuleScope).filter(scope => scope != HuntingRuleScope.all);
		return scopes.map(scope => ({
			id: scope,
			name: this.i18nService.get(`hunting.scheduledMonitorSidePane.fields.machineGroups.${scope}`),
		}));
	}

	onSelectionChanged(action: CustomActionSelection) {
		if (action && action.checked) {
			if (!this.allowMultiSelect) {
				// deselect other selected actions
				Object.values(this.userSelections).forEach(a => (a.checked = a.actionType === action.actionType));
			}
			else if (action.mutuallyExclusiveWith) {
				// deselect actions that are mutually exclusive with the selected action
				for (const exclusiveActionId of action.mutuallyExclusiveWith) {
					this.userSelections[exclusiveActionId].checked = false;
				}
			}
		}

		this.onCustomActionChange();
	}

	onCustomActionChange() {
		const selectedActions = Object.values(this.userSelections)
			.filter(action => action.checked)
			.map(a => a.toCustomAction())
			.filter(Boolean);

		setTimeout(() => this.onActionsChange(selectedActions));
	}

	getGroupsDropdownPlaceholder(selectedGroupIds: number[]): string {
		const numSelectedGroups = selectedGroupIds ? selectedGroupIds.length : 0;
		return numSelectedGroups > 0
			? this.i18nService.get(
				`hunting.scheduledMonitorSidePane.fields.machineGroups.dropdown.placeholderWithInfo.${numSelectedGroups === 1 ? 'singular' : 'plural'
				}`,
				{
					numGroups: numSelectedGroups,
				}
			)
			: this.i18nService.get(
				'hunting.scheduledMonitorSidePane.fields.machineGroups.dropdown.placeholder'
			);
	}

	onAnyExpandedChange(state: boolean): void{
		this.anyExpandedChange.emit(state);
	}
}
