import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { Observable, fromEvent, zip } from 'rxjs';
import { map, debounceTime, take, tap } from 'rxjs/operators';
import { LocaleConfigService, TzDateService } from '@wcd/localization';
import { GaugeConfig, LegendItem, LineChartOptions } from '@wcd/charts';
import { Paris } from '@microsoft/paris';
import { OrgExposureScoreApiCall, OrgExposureScoreModel, RecommendationException } from '@wcd/domain';
import { TvmColor, TvmColorService } from '../../../services/tvm-color.service';
import { I18nService } from '@wcd/i18n';
import { TvmTextsService, TextToken } from '../../../services/tvm-texts.service';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';
import { TvmScoreTrendOptions } from '../../../components/tvm-score-trend/tvm-score-trend.component';
import {
	TvmChartTooltipComponent,
	EXPOSURE_SCORE_TOOLTIP_ID,
} from '../../../components/tooltips/events/tvm-chart-tooltip.component';
import {
	TvmTopChangeEventsService,
	ChangeEventDomain,
} from '../../../services/tvm-top-change-events.service';
import { generateDatesForHistoryArray } from '../../../tvm-utils';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';

declare const moment: typeof import('moment');

@Component({
	selector: 'tvm-org-exposure-score-widget',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './org-exposure-score.widget.html',
	styleUrls: ['./org-exposure-score.widget.scss'],
})
export class TvmOrgExposureScoreWidget extends ReportWidgetComponent<OrgExposureScoreModel> {
	widgetTooltip: string;
	widgetTooltipAriaLabel: string;
	isMgSelected: boolean;
	isImpactedByExceptions: boolean;

	accessibleGaugeExposureScore$: Observable<number>;
	newChartOptions: LineChartOptions<TvmChartTooltipComponent>;
	trendOptions: TvmScoreTrendOptions;

	constructor(
		reportsService: ReportsService,
		private tvmTextsService: TvmTextsService,
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		private tvmColorsService: TvmColorService,
		private paris: Paris,
		private topChangeEventService: TvmTopChangeEventsService,
		public localeConfigService: LocaleConfigService,
		public tzDateService: TzDateService,
		private i18nService: I18nService,
		private appInsightsService: AppInsightsService
	) {
		super(reportsService);
		this.widgetTooltip = tvmTextsService.getText(TextToken.ExposureScoreWidgetInfo);
		this.widgetTooltipAriaLabel = tvmTextsService.getText(TextToken.ExposureScoreWidgetInfoAriaLabel);

		this.accessibleGaugeExposureScore$ = this.data$.pipe(
			map(score => score && Math.floor(score.exposureScore))
		);
	}

	readonly forceGaugeReRender$ = fromEvent(window, 'resize').pipe(
		debounceTime(200),
		map(e => true)
	);

	get widgetConfig() {
		return {
			id: 'orgExposureScoreWidget',
			name: this.i18nService.get('tvm.exposureScoreWidget.title'),
			noDataMessage: () =>
				this.tvmTextsService.getText(TextToken.NoDataForWidget, {
					noDataKey: 'tvm.dashboard.noData.exposureScore',
					isGroupSelected: this.isMgSelected,
				}),
			noDataIcon: FabricIconNames.Trackers,
			NoIconLeftAlign: true,
			loadData: () => {
				const mg$ = this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1));
				const score$ = this.paris.apiCall<OrgExposureScoreModel>(OrgExposureScoreApiCall, {}); //TODO: bad practice ahead - the empty params is due to the fact that Paris fires the "parseQuery" CB only if arg is provided
				const exceptions$ = this.paris
					.getRepository(RecommendationException)
					.query({ where: { status: ['Active'] } });
				const vaEvents$ = this.topChangeEventService.getChangeEvents$(ChangeEventDomain.VA);
				return zip(mg$, score$, exceptions$, vaEvents$).pipe(
					map(([mg, score, exceptions, vaEvents]) => {
						this.isMgSelected = mg.isFiltering;
						this.isImpactedByExceptions = exceptions.items.length > 0;
						const history = score.exposureScoreHistory;
						if (score.exposureScore === 0 && Array.isArray(history) && history.length === 0) {
							return null;
						}
						this.setNewChartValues(score, vaEvents || new Map());

						return score;
					})
				);
			},
			minHeight: '400px',
		};
	}

	exposureScorePercentage$ = this.data$.pipe(
		map(data => (data ? Math.floor(data.exposureScore) / 100 : null))
	);

	gauge: { config: GaugeConfig } = {
		config: {
			sections: [
				{ percent: 0, label: '0' },
				{ percent: 0.3, label: '30' },
				{ percent: 0.7, label: '70' },
				{ percent: 1, label: '100' },
			],
			gaugeMargin: 35,
			labels: {
				show: true,
				needleLabelDistance: 8,
			},
			digital: {
				show: true,
				height: 30,
			},
			needleCircleRadius: 4,
		},
	};

	gaugeLegendItems: Array<LegendItem> = [
		{
			name: this.i18nService.get('tvm.gauge.legend.low'),
			iconColor: this.tvmColorsService.colorsMap.get(TvmColor.LowRisk).raw,
		},
		{
			name: this.i18nService.get('tvm.gauge.legend.medium'),
			iconColor: this.tvmColorsService.colorsMap.get(TvmColor.MediumRisk).raw,
		},
		{
			name: this.i18nService.get('tvm.gauge.legend.high'),
			iconColor: this.tvmColorsService.colorsMap.get(TvmColor.HighRisk).raw,
		},
	];

	private setNewChartValues(data: OrgExposureScoreModel, vaEvents: Map<string, string[]>): void {
		if (!data) {
			this.trendOptions = null;
			this.newChartOptions = null;
			return;
		}

		const historyDataPoints = generateDatesForHistoryArray(
			data.exposureScoreHistory,
			this.localeConfigService.isLocalTimeZone
		);

		this.newChartOptions = {
			data: historyDataPoints.map(point => ({
				...point,
				events: vaEvents.get(this.tzDateService.format(point.date, 'MM/dd')),
			})),
			legend: this.i18nService.get('tvm.exposureScoreWidget.exposureOverTimeLegend'),
			tooltipComponent: TvmChartTooltipComponent,
			id: 'orgExposureScoreHistoryLine',
			tooltipId: EXPOSURE_SCORE_TOOLTIP_ID,
		};

		this.trendOptions = {
			title: this.i18nService.get('tvm.exposureScoreWidget.exposureOverTime'),
			scoreHistory: historyDataPoints.map(point => point.value),
			lowerScoreBetter: true,
		};
	}
}
