import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';

@Directive({
	selector: '[textMask]',
})
export class TextMaskDirective implements OnDestroy {
	@Input() textMask: string;

	constructor(private el: ElementRef) {
		el.nativeElement.addEventListener('keypress', this.keyDownHandler)
	}

	ngOnDestroy() {
		this.el.nativeElement.removeEventListener('keypress', this.keyDownHandler)
	}

	keyDownHandler = (e) => {
		const re = new RegExp(this.textMask);
		if (!re.test(e.key)) {
			e.preventDefault()
		}
	}

}
