import { RecommendationExceptionService } from './../../remediation/recommendation-exceptions/services/recommendation-exception.service';
import { FeaturesService, Feature } from '@wcd/config';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	OnInit,
} from '@angular/core';
import { Subscription, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import {
	SecurityRecommendation,
	RemediationType,
	RecommendationException,
	ExceptionArgs,
	ExceptionType,
	RecommendationType,
	ExceptionJustification,
	MdeUserRoleActionEnum,
	MachineGroup,
	RecommendationToExceptionsRelationship,
	RecommendationExceptionStateValue,
	GlobalExceptionRbacGroupId,
} from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { CalculateRemainingDaysPipe } from '../../../../shared/pipes/calculate-remaining-days.pipe';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { AuthService } from '@wcd/auth';
import { MessageBarType, SpinnerSize } from 'office-ui-fabric-react';
import { AppConfigService } from '@wcd/app-config';
import { MessageBarStyles } from '../../common/styles';
import { RbacService } from '../../../../rbac/services/rbac.service';
import { ChecklistValue } from '@wcd/forms';
import { TzDateService } from '@wcd/localization';

declare const moment: typeof import('moment-timezone');

@Component({
	selector: 'recommendation-exception-creation',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './recommendation-exception-creation.component.html',
	styleUrls: ['./recommendation-exception-creation.component.scss'],
})
export class RecommendationExceptionCreationComponent extends PanelContainer implements OnInit {
	private _messageBarType = MessageBarType;

	SpinnerSize = SpinnerSize;
	messageBarStyles = MessageBarStyles;
	warningText: string;
	shouldLinkToException = false;
	recommendationExceptionTitle: string;
	shouldDisable: boolean;
	expiringOn: Date;
	minDate = moment()
		.add(1, 'days')
		.toDate();
	maxDate = moment()
		.add(1, 'year')
		.toDate();
	dateCustomFromNow = {
		id: 'custom',
		date: null,
	};
	date30FromNow = {
		id: '30',
		date: moment()
			.add(30, 'days')
			.toDate(),
	};
	date60FromNow = {
		id: '60',
		date: moment()
			.add(60, 'days')
			.toDate(),
	};
	date90FromNow = {
		id: '90',
		date: moment()
			.add(90, 'days')
			.toDate(),
	};

	datesList = [this.date30FromNow, this.date60FromNow, this.date90FromNow, this.dateCustomFromNow];
	exceptionJustification: ExceptionJustification;
	exceptionJustificationList: string[];
	error: string;
	isSaving: boolean;
	chosenDate = {
		id: '',
		date: null,
	};
	exceptionLink: string;
	isExceptionsPerRbacFeatureEnabled: boolean;
	machineGroups: Array<MachineGroup>;
	existingRecommendationExceptions: Array<RecommendationException>;
	userExposedRbacGroupIds: number[];
	isGlobalExceptionCreationAllowed: boolean;
	globalExceptionCreationType: ChecklistValue = {
		id: 'global',
		name: this.i18nService.get(
			'tvm_securityRecommendation_recommendationExceptionCreation_exceptionCreationType_global'
		),
	};
	byMachineGroupExceptionCreationType: ChecklistValue = {
		id: 'byMachineGroup',
		name: this.i18nService.get(
			'tvm_securityRecommendation_recommendationExceptionCreation_exceptionCreationType_byMachineGroup'
		),
	};
	exceptionCreationTypes: ChecklistValue[] = [
		this.globalExceptionCreationType,
		this.byMachineGroupExceptionCreationType,
	];

	_selectedExceptionCreationType: ChecklistValue;
	existingActiveGlobalException: RecommendationException;
	existingActiveExceptionsRbacGroupIds: number[] = [];
	allRbacGroupsHaveAnActiveException: boolean;
	recommendationExceptionCreationDescription: string;
	defaultWarningText: string;
	recommendationExceptionRouterLink = 'remediation/recommendation-exceptions';
	isUserAllowedToCreateExceptions: boolean;

	private _overrideSelectedExceptionCreationType: ChecklistValue;
	private _savingSubscription: Subscription;
	private _justificationContext: Map<ExceptionJustification, string>;

	exceptionDurationText: string;
	justificationNotificationText: any;
	justificationNote: string;

	get justificationContext(): string {
		return this._justificationContext.get(this.exceptionJustification);
	}

	set justificationContext(newJustificationContext: string) {
		this._justificationContext.set(this.exceptionJustification, newJustificationContext);
	}

	get shouldDisabledMachineGroupsDropdown(): boolean {
		return (
			this.shouldDisable ||
			this.allRbacGroupsHaveAnActiveException ||
			this.selectedExceptionCreationType !== this.byMachineGroupExceptionCreationType
		);
	}

	get selectedExceptionCreationType(): ChecklistValue {
		return this._overrideSelectedExceptionCreationType || this._selectedExceptionCreationType;
	}

	@Input() securityRecommendation: SecurityRecommendation;
	@Output() done = new EventEmitter<RecommendationException>();

	constructor(
		router: Router,
		featuresService: FeaturesService,
		private rbacService: RbacService,
		private paris: Paris,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private changeDetectionRef: ChangeDetectorRef,
		private calculateRemainingDaysPipe: CalculateRemainingDaysPipe,
		private tvmTextsService: TvmTextsService,
		private authService: AuthService,
		private appConfigService: AppConfigService,
		private recommendationExceptionService: RecommendationExceptionService,
		private tzDateService: TzDateService
	) {
		super(router);

		this.isUserAllowedToCreateExceptions = this.authService.currentUser.hasMdeAllowedUserRoleAction(
			MdeUserRoleActionEnum.tvmExceptionHandling
		);
		this.exceptionJustificationList = recommendationExceptionService.getCreationJustificationList();
		this.isExceptionsPerRbacFeatureEnabled =
			appConfigService.hasMachineGroups && featuresService.isEnabled(Feature.TvmExceptionsPerRbac);
		this.isGlobalExceptionCreationAllowed = this.authService.currentUser.isMdeAdmin;
		this._overrideSelectedExceptionCreationType = !this.isGlobalExceptionCreationAllowed
			? this.byMachineGroupExceptionCreationType
			: null;
		this.defaultWarningText =
			i18nService.strings.tvm_securityRecommendation_recommendationExceptionCreation_unknownError;

		this.justificationNotificationText = this.i18nService.strings.tvm_securityRecommendation_recommendationExceptionCreation_justificationScore;
		this.exceptionDurationText = this.i18nService.strings.tvm_securityRecommendation_recommendationExceptionCreation_expiringOn;
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.isExceptionsPerRbacFeatureEnabled) {
			this.recommendationExceptionCreationDescription = this.i18nService.strings.tvm_securityRecommendation_recommendationExceptionCreation_descriptionNew;
			this.setExceptionCreationParams();
		} else {
			this.recommendationExceptionCreationDescription = this.i18nService.strings.tvm_securityRecommendation_recommendationExceptionCreation_description;
			this.warningText = this.getWarningText();
			this.shouldDisable = this.shouldDisableForms();
		}

		this._justificationContext = new Map(
			this.exceptionJustificationList.map<Readonly<[ExceptionJustification, string]>>(key => [
				ExceptionJustification[key],
				'',
			])
		);

		this.recommendationExceptionTitle = this.tvmTextsService.getText(
			TextToken.SecurityRecommendationTitle,
			this.securityRecommendation
		);

		// These functions are called from a child component and requires this component's context
		this.getJustificationLabel = this.getJustificationLabel.bind(this);
		this.formatDate = this.formatDate.bind(this);
		this.getTimeLabel = this.getTimeLabel.bind(this);

		this.exceptionLink = `${this.recommendationExceptionRouterLink}/${
			this.securityRecommendation.remediationType === RemediationType.ConfigurationChange
				? this.securityRecommendation.scId
				: this.securityRecommendation.productId +
				  (this.isExceptionsPerRbacFeatureEnabled
						? `-_-${this.securityRecommendation.remediationType}`
						: '')
		}`;
	}

	setExceptionCreationParams() {
		const repository = this.paris.getRelationshipRepository<
			SecurityRecommendation,
			RecommendationException
		>(RecommendationToExceptionsRelationship);
		repository.sourceItem = this.securityRecommendation;
		zip(
			this.rbacService.userExposedRbacGroups$.pipe(
				map(res => res.map(machineGroup => machineGroup.id))
			),
			repository.query().pipe(map(res => res.items))
		).subscribe(([userExposedRbacGroupIds, existingRecommendationExceptions]) => {
			this.userExposedRbacGroupIds = userExposedRbacGroupIds;
			this.existingRecommendationExceptions = existingRecommendationExceptions;
			this.existingActiveGlobalException = this.existingRecommendationExceptions.filter(
				ex =>
					this.isExceptionMatchingRecommendation(ex) &&
					ex.status.value === RecommendationExceptionStateValue.Active &&
					this.isGlobalException(ex)
			)[0];

			this.existingActiveExceptionsRbacGroupIds = this.existingRecommendationExceptions
				.filter(
					exception =>
						this.isExceptionMatchingRecommendation(exception) &&
						exception.status.value === RecommendationExceptionStateValue.Active &&
						!this.isGlobalException(exception)
				)
				.map(exception => exception.rbacGroupId);

			const existingActiveExceptionsRbacGroupIdsSet = new Set(
				this.existingActiveExceptionsRbacGroupIds
			);

			this.allRbacGroupsHaveAnActiveException = this.userExposedRbacGroupIds.every(rbacGroupId =>
				existingActiveExceptionsRbacGroupIdsSet.has(rbacGroupId)
			);

			this.setDefaultCreationType();
			this.warningText = this.getWarningText();
			this.shouldDisable = this.shouldDisableForms();
			this.changeDetectionRef.markForCheck();
		});
	}

	getJustificationLabel(justification: ExceptionJustification): string {
		return this.recommendationExceptionService.getJustificationLabel(justification);
	}

	getTimeLabel(data): string {
		return this.i18nService.get(
			`tvm.securityRecommendation.recommendationExceptionCreation.times.${data.id}`
		);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this._savingSubscription && this._savingSubscription.unsubscribe();
	}

	machineGroupSelected(machineGroups: Array<MachineGroup>) {
		this.machineGroups = machineGroups;
	}

	submitRequest() {
		this.isSaving = true;
		const newExceptions = this.createRecommendationExceptions();

		const repository = this.paris.getRepository(RecommendationException);
		const saveObservable$ = Array.isArray(newExceptions)
			? repository.saveItems(newExceptions).pipe(map(exceptions => exceptions[0]))
			: repository.save(newExceptions);

		this._savingSubscription = saveObservable$.subscribe(
			recommendationException => {
				this.dialogsService.showSnackbar({
					text: this.i18nService.get(
						'tvm.securityRecommendation.recommendationExceptionCreation.pleaseWait'
					),
				});
				this.done.emit(recommendationException);
			},
			err => {
				if (err.status === 409) {
					this.dialogsService.showError({
						title: this.i18nService.get(
							'tvm.securityRecommendation.recommendationExceptionCreation.creationAlreadyExistFailTitle'
						),
						data: this.i18nService.get(
							'tvm.securityRecommendation.recommendationExceptionCreation.creationAlreadyExistFailMessage'
						),
					});
					this.done.emit();
					return;
				}
				this.dialogsService.showError({
					title: this.i18nService.get(
						'tvm.securityRecommendation.recommendationExceptionCreation.creationGeneralFailTitle'
					),
					data: this.i18nService.get(
						'tvm.securityRecommendation.recommendationExceptionCreation.creationGeneralFailMessage'
					),
				});
				this.isSaving = false;
				this.changeDetectionRef.markForCheck();
			}
		);
	}

	onSelectDate(date: Date) {
		if (date && date !== this.chosenDate.date) {
			this.chosenDate.date = date;
		}
	}

	formatDate(date?: Date): string {
		if (date) {
			return `${this.tzDateService.format(
				date,
				'shortDate'
			)} (${this.calculateRemainingDaysPipe.transform(date)} days)`;
		}
		return '';
	}

	setJustificationContext() {
		const context =
			this.justificationContext !== ''
				? this.justificationContext
				: this.i18nService.get(
						`tvm.securityRecommendation.recommendationExceptionCreation.justificationContext.${
							this.exceptionJustification
						}`
				  );
		this.justificationContext = context !== ' ' ? context : '';
		this.justificationNote = null; // to hide the info notification and show again to read the alert by the narrator on each selection
		this.changeDetectionRef.markForCheck();

		setTimeout(() => {
			this.justificationNote =
				this.exceptionJustification === ExceptionJustification.ThirdPartyControl ||
				this.exceptionJustification === ExceptionJustification.AlternateMitigation
					? this.justificationNotificationText
					: undefined;
			this.changeDetectionRef.markForCheck();
		});
	}

	shouldDisableForms(): boolean {
		if (!this.isExceptionsPerRbacFeatureEnabled) {
			return (
				this.securityRecommendation.status === 'Exception' ||
				!this.isUserAllowedToCreateExceptions ||
				!this.appConfigService.isExposedToAllMachineGroups
			);
		}

		if (this.selectedExceptionCreationType === this.globalExceptionCreationType) {
			return !(
				this.existingActiveGlobalException === null ||
				this.existingActiveGlobalException === undefined
			);
		} else if (this.selectedExceptionCreationType === this.byMachineGroupExceptionCreationType) {
			return this.allRbacGroupsHaveAnActiveException;
		} else {
			return !this.isUserAllowedToCreateExceptions;
		}
	}

	getWarningText(): string {
		const textArray: string[] = [];
		if (!this.isUserAllowedToCreateExceptions) {
			textArray.push(
				this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.notAllowed')
			);
		}

		if (!this.isExceptionsPerRbacFeatureEnabled && !this.appConfigService.isExposedToAllMachineGroups) {
			textArray.push(
				this.i18nService.get(
					'tvm.securityRecommendation.recommendationExceptionCreation.notExposedToAllRbac'
				)
			);
		}

		if (this.isExceptionsPerRbacFeatureEnabled) {
			if (this.existingActiveGlobalException) {
				textArray.push(
					this.i18nService.get(
						'tvm_securityRecommendation_recommendationExceptionCreation_alreadyUnderGlobalException',
						{
							expirationDate: this.tzDateService.format(
								this.existingActiveGlobalException.expiringOn,
								'shortDate'
							),
						}
					)
				);
				this.exceptionLink = `${this.recommendationExceptionRouterLink}/${
					this.existingActiveGlobalException.id
				}`;
				this.shouldLinkToException = true;
			}

			if (this.allRbacGroupsHaveAnActiveException) {
				textArray.push(
					this.i18nService.strings
						.tvm_securityRecommendation_recommendationExceptionCreation_alreadyUnderPerRbacException
				);
				this.shouldLinkToException = true;
			}
		} else {
			if (this.securityRecommendation.status === 'Exception') {
				textArray.push(
					this.i18nService.get(
						'tvm.securityRecommendation.recommendationExceptionCreation.alreadyUnderException'
					)
				);
				this.shouldLinkToException = true;
			}
		}

		return textArray.join('\n\n');
	}

	private createRecommendationExceptions(): RecommendationException | RecommendationException[] {
		if (!this.isExceptionsPerRbacFeatureEnabled) {
			return this.createExceptionWithRbacGroup(null);
		}

		if (this.selectedExceptionCreationType === this.globalExceptionCreationType) {
			return [this.createExceptionWithRbacGroup(GlobalExceptionRbacGroupId)];
		} else if (this.selectedExceptionCreationType === this.byMachineGroupExceptionCreationType) {
			return this.machineGroups.map(machineGroup => this.createExceptionWithRbacGroup(machineGroup.id));
		} else {
			throw new Error('No exception creation type selected');
		}
	}

	private createExceptionWithRbacGroup(rbacGroupId?: number): RecommendationException {
		return {
			id: null,
			title: this.recommendationExceptionTitle,
			exceptionJustification: this.exceptionJustification,
			justificationContext: this.justificationContext,
			expiringOn: this.chosenDate.date,
			exceptionArgs: this.createExceptionArgs(),
			requester: null,
			rbacGroupId: rbacGroupId,
		};
	}

	private createExceptionArgs(): ExceptionArgs {
		return this.securityRecommendation.scId
			? {
					type: ExceptionType.ConfigurationChange,
					scaRecommendationArgs: {
						scid: this.securityRecommendation.scId,
					},
			  }
			: {
					type: ExceptionType.SoftwarePatch,
					vaRecommendationArgs: {
						recommendationType:
							RecommendationType[RemediationType[this.securityRecommendation.remediationType]],
						productId: this.securityRecommendation.productId,
					},
			  };
	}

	private isGlobalException(exception: RecommendationException): boolean {
		return (
			exception.rbacGroupId === null ||
			exception.rbacGroupId === undefined ||
			exception.rbacGroupId === GlobalExceptionRbacGroupId
		);
	}

	private isExceptionMatchingRecommendation(exception: RecommendationException): boolean {
		switch (exception.exceptionArgs.type) {
			case ExceptionType.SoftwarePatch:
				return (
					exception.exceptionArgs.vaRecommendationArgs.productId ===
						this.securityRecommendation.productId &&
					(exception.exceptionArgs.vaRecommendationArgs.recommendationType as string) ===
						(this.securityRecommendation.remediationType as string)
				);
			case ExceptionType.ConfigurationChange:
				return (
					exception.exceptionArgs.scaRecommendationArgs.scid === this.securityRecommendation.scId
				);
		}
	}

	private setDefaultCreationType() {
		if (this.existingActiveGlobalException) {
			this.globalExceptionCreationType.disabled = true;
			this._selectedExceptionCreationType = this.byMachineGroupExceptionCreationType;
		}

		if (this.allRbacGroupsHaveAnActiveException) {
			this.byMachineGroupExceptionCreationType.disabled = true;

			// If both creation types are disabled, don't select any by default
			this._selectedExceptionCreationType = this.globalExceptionCreationType.disabled
				? null
				: this.globalExceptionCreationType;
		}
	}
}
