import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { SerializedFilters } from '@wcd/ng-filters';
import { combineLatest, Observable, of } from 'rxjs';
import {
	AlertStatusReportType,
	ThreatDimensionType,
	ThreatProtection,
	ThreatProtectionData,
	ThreatReportApiCall,
	ThreatReportDimensionKeyType,
	Alert,
} from '@wcd/domain';
import { TzDateService } from '@wcd/localization';
import { ChartSettings } from '@wcd/charts';
import { ReportsService } from './reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { Router } from '@angular/router';
import { mapKeys, mapValues, sum, values as lodashValues } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { TrackingEventId } from '../../insights/models/tracking-event-Id.enum';
import { ThreatProtectionStatusMapping } from '../threat-protection-status-mapping';
import { map, mergeMap } from 'rxjs/operators';
import {
	AlertsQueueFilters,
	DimensionFilterData,
	rbacGroupIdsFilter,
	ReportMapping,
	ThreatReportDimension,
	ThreatReportDimensionMapping,
	ThreatReportDimensionType,
} from '../../reporting/threat-protection2/threat-protection-report.model';
import { WidgetType } from '../../reports/components/report-with-filters.component';
import { ChartType } from '../../reporting/reporting.types';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';

const MAX_LABELS = 4;
const FIXED_STATUS_FILTERS = ['New', 'InProgress'];
const FIXED_AGE_FILTERS = 'day';
const SENSORS = 'Bitdefender,Ziften,SentinelOne,Lookout';
const SENSORS_DETECTION_SOURCE_FILTER = 'ThirdPartySensors';

@Injectable({
	providedIn: 'root',
})
export class ThreatProtectionReport2Service {
	constructor(
		private paris: Paris,
		private tzDateService: TzDateService,
		private readonly appInsightsService: AppInsightsService,
		private prettyNumberService: PrettyNumberService,
		private router: Router,
		private threatProtectionStatusMapping: ThreatProtectionStatusMapping,
		private i18nService: I18nService,
		private globalEntityTypesService: GlobalEntityTypesService
	) {}

	getAlertsByDimension(
		params: SerializedFilters,
		widgetType: WidgetType,
		dimensionType: string
	): Observable<ThreatProtection> {
		params.alignToPeriod = 'true';
		widgetType === WidgetType.Trend
			? (params.OpenedAge = FIXED_AGE_FILTERS)
			: (params.Status = FIXED_STATUS_FILTERS.join(','));

		return this.paris
			.apiCall(ThreatReportApiCall, params)
			.pipe(
				mergeMap((res: Array<ThreatProtection>) =>
					of(res.find((r) => r.dimensionType.toString() === dimensionType))
				)
			);
	}

	getAlertsByStatus(
		params: SerializedFilters,
		widgetType: WidgetType,
		dimensionType: string
	): Observable<ThreatProtection> {
		// Alerts by source overtime combined 2 API calls with different params.
		// One call with params.OpenAgeInDays  = 'day' that gives the data for New and InProgress alerts.
		// One call with params.ResolvedAgeInDays  = 'day' that gives the data for Resolved alerts.
		return combineLatest(
			this.getAlertsByDimension(Object.assign({}, params), widgetType, dimensionType),
			this.getResolvedData(Object.assign({}, params), dimensionType)
		).pipe(
			map(([sourceData, resolvedData]) => {
				const combinedData: Array<ThreatProtectionData> = sourceData.data.map(({ date, values }) => {
					const resolvedInDate = resolvedData.data.find((r) => r.date.getTime() === date.getTime());
					return {
						date,
						values: {
							...values,
							// For trend widget - New status is calculated as sum of new, in progress and resolved
							...(widgetType === WidgetType.Trend ? { New: sum(lodashValues(values)) } : {}),
							// Override the resolved count
							...(resolvedInDate
								? { Resolved: resolvedInDate.values[AlertStatusReportType.Resolved] }
								: {}),
						},
					};
				});

				return {
					dimensionType: sourceData.dimensionType,
					data: combinedData,
				};
			})
		);
	}

	getAlertsByClassificationAndDetermination(
		params: SerializedFilters,
		widgetType: WidgetType,
		dimensionType: string
	): Observable<ThreatProtection> {
		params.alignToPeriod = 'true';
		params.DeterminationAge = FIXED_AGE_FILTERS;

		// Merging 2 dimensions: classification & determination
		return this.paris.apiCall(ThreatReportApiCall, params).pipe(
			mergeMap((res: Array<ThreatProtection>) => {
				const classification: ThreatProtection = res.find(
					(r) => r.dimensionType.toString() === ThreatDimensionType.AlertClassificationType
				);
				const determination: ThreatProtection = res.find(
					(r) => r.dimensionType.toString() === ThreatDimensionType.AlertDeterminationType
				);
				const combinedData: Array<ThreatProtectionData> = [];

				classification.data.forEach((item, index) => {
					const p = { ...item.values, ...determination.data[index].values } as Record<
						ThreatReportDimensionKeyType,
						number
					>;
					combinedData.push({ date: item.date, values: p });
				});

				return of({
					dimensionType: classification.dimensionType,
					data: combinedData,
				});
			})
		);
	}

	getSettingsOverTime<T extends ThreatReportDimension>(
		dimensionType: ThreatReportDimensionType,
		dimensionMapping: ReportMapping<T>,
		data?: ThreatProtection,
		height: number = 200
	): ChartSettings {
		const dimensionData = data && data.data && data.data.length && data.data;

		const chartData = this.getDimensionData(dimensionType, dimensionData, dimensionMapping);
		const maxAlertsCount: number = chartData
			? Math.max(
					...chartData.map((d) => {
						const t = Math.max(...(d.slice(1) as number[]));
						return t;
					})
			  )
			: 0;

		return {
			options: {
				data: {
					columns: chartData,
					colors: mapValues(
						mapKeys(dimensionMapping, (value) => value.label),
						(value) => value.knownColorsMap.trend.raw
					),
				},
				axis: {
					y: {
						max: maxAlertsCount ? maxAlertsCount : 0,
						min: 0,
						tick: {
							values: dimensionData ? ReportsService.getYTicks(maxAlertsCount) : [],
							format: (value) => this.prettyNumberService.prettyNumber(value),
						},
						padding: {
							bottom: 0,
						},
					},
					x: {
						type: 'category',
						categories: this.getDimensionDates(dimensionData),
						tick: {
							multiline: false,
							culling: {
								max: dimensionData
									? Math.min(dimensionData.length + 1, MAX_LABELS)
									: MAX_LABELS,
							},
						},
					},
				},
				point: {
					show: false,
				},
				grid: {
					y: {
						show: true,
					},
				},
				legend: {
					show: false,
				},
				size: {
					height: height,
				},
				tooltip: {
					// Hide zeroes entries in tooltip
					format: {
						value: (value) => (value ? value : undefined),
					},
				},
			},
			disableCursor: true,
		};
	}

	getSettingsDaily<T extends ThreatReportDimension>(
		type: ChartType,
		dimensionMapping: ReportMapping<T>,
		data?: ThreatProtection,
		getDimensionFilterData?: (dimensionKey: ThreatReportDimensionKeyType) => DimensionFilterData,
		height: number = 250
	): ChartSettings {
		const dimensionData = data && data.data && data.data.length && data.data[0];
		const chartData = Object.entries<ThreatReportDimensionMapping>(dimensionMapping)
			.filter(([key, value]) => !value.hiddenOnDaily)
			.map(([key, value]) => this.getDimensionDataDaily(value.label, key, dimensionData));
		const maxCount: number = chartData
			? chartData.values && Math.max(...chartData.map((d) => d.value))
			: 0;

		const colorsPattern = Object.values<ThreatReportDimensionMapping>(dimensionMapping).map(
			(value) => value.knownColorsMap.daily.raw
		);

		return {
			options: {
				color: {
					pattern: colorsPattern,
				},
				data: {
					columns: [
						[
							this.i18nService.get('reporting.threatReport.dailyTooltipDescription'),
							...chartData.map((data) => data.value),
						],
					],

					groups: [
						Object.values<ThreatReportDimensionMapping>(dimensionMapping).map(
							(value) => value.label
						),
					],
					type: 'bar',
					labels: {
						format: (value) => (maxCount > 0 ? this.prettyNumberService.prettyNumber(value) : ''), //c3 labels issue when all labels are zero, the labels are at a wrong position
					},

					color: (inColor, data) => {
						if (data.index !== undefined) {
							return colorsPattern[data.index];
						}
						return inColor;
					},
					onclick: (e) => {
						this.onDimensionClick(Object.keys(dimensionMapping)[e.index], getDimensionFilterData);
					},
				},
				tooltip: {
					grouped: false,
					contents: (data) =>
						`<table class="c3-tooltip">
							<tbody>
								<tr>
									<td class="name">${data[0].value}</td>
								</tr>
							</tbody>
						</table>`,
				},
				bar: {
					width: {
						max: 8,
						ratio: 0.2,
					},
				},
				axis: {
					rotated: type === ChartType.Horizontal,
					x: {
						type: 'category',
						categories: chartData.map((data) => data.label),
						tick: {
							multiline: type === ChartType.Horizontal,
							culling: {
								max: 1,
							},
							rotate: 75,
						},
						padding: { right: 0.5 },
					},
					y: {
						show: true,
						max: maxCount ? maxCount : 0,
						min: 0,
						tick: {
							values: data ? ReportsService.getYTicks(maxCount) : [],
							format: (value) => this.prettyNumberService.prettyNumber(value),
						},
						padding: {
							bottom: 0,
						},
					},
				},
				padding: {
					left: type === ChartType.Horizontal ? 100 : 50,
				},
				grid: {
					y: {
						show: true,
					},
				},
				point: {
					show: false,
				},
				legend: { show: false },
				size: {
					height: height,
				},
			},
		};
	}

	onDimensionClick(
		dimensionKey: string,
		getDimensionFilterData?: (dimensionKey: ThreatReportDimensionKeyType) => DimensionFilterData
	) {
		try {
			if (!getDimensionFilterData) {
				return; // Dimension doesn't support drill down
			}

			const parsedFilters: AlertsQueueFilters = {};
			const dimensionFilterData = getDimensionFilterData(dimensionKey as ThreatReportDimensionKeyType);

			Object.entries(dimensionFilterData.currentFilters).forEach(([key, values]) => {
				if (key !== dimensionFilterData.type) {
					switch (key) {
						case 'severity': {
							const parsedValues = (<Array<string>>values).map((value) =>
								encodeURIComponent(
									this.threatProtectionStatusMapping.severityMapping[value].alertListFilter
								)
							);
							parsedFilters.severity = parsedValues.join('|');
							break;
						}
						case 'detectionSource': {
							const parsedValues = (<Array<string>>values).map((value) =>
								encodeURIComponent(
									this.threatProtectionStatusMapping.detectionSourceMapping[value]
										.alertListFilter
								)
							);
							parsedFilters.detectionSource = parsedValues.join('|');
							break;
						}
						case 'rbacGroupIds': {
							parsedFilters.rbacGroupIds = dimensionFilterData.currentFilters[
								rbacGroupIdsFilter
							]
								? (<Array<string>>(
										dimensionFilterData.currentFilters[rbacGroupIdsFilter]
								  )).join('|')
								: '';
							break;
						}
					}
				}
			});

			if (dimensionFilterData.dimensionFilter) {
				dimensionFilterData.dimensionFilter = dimensionFilterData.dimensionFilter.replace(
					'detectionSource=' + SENSORS_DETECTION_SOURCE_FILTER,
					'detectionSource=' + encodeURIComponent(encodeURIComponent(SENSORS))
				);
			}

			const drillDownFilters = [
				`status=${encodeURIComponent(FIXED_STATUS_FILTERS.join('|'))}`,
				dimensionFilterData.dimensionFilter ? dimensionFilterData.dimensionFilter : '',
				parsedFilters.severity ? `severity=${parsedFilters.severity}` : '',
				parsedFilters.detectionSource ? `detectionSource=${parsedFilters.detectionSource}` : '',
				parsedFilters.rbacGroupIds ? `rbacGroupIds=${parsedFilters.rbacGroupIds}` : '',
			].filter((value) => value !== '');

			this.trackNavigation(
				dimensionKey as ThreatReportDimensionKeyType,
				drillDownFilters,
				dimensionFilterData.type
			);

			const alertEntityType = this.globalEntityTypesService.getEntityType(Alert);
			this.router.navigate([alertEntityType.getEntityDataviewLink()], {
				queryParams: {
					filters: drillDownFilters.join(','),
					range: '6months',
				},
			});
		} catch (e) {
			this.appInsightsService.trackEvent('UI', {
				id: 'ThreatReport_OnClick',
				index: e.index,
			});
		}
	}

	private getResolvedData(params: SerializedFilters, dimensionType: string): Observable<ThreatProtection> {
		params.alignToPeriod = 'true';
		params.ResolvedAge = FIXED_AGE_FILTERS;
		return this.paris
			.apiCall(ThreatReportApiCall, params)
			.pipe(
				mergeMap((res: Array<ThreatProtection>) =>
					of(res.find((r) => r.dimensionType.toString() === dimensionType))
				)
			);
	}

	private trackNavigation(
		clickedValue: ThreatReportDimensionKeyType,
		drillDownFilters: Array<string>,
		dimension: string
	) {
		this.appInsightsService.trackEvent('UsageTrack', {
			type: TrackingEventType.Navigation,
			id: TrackingEventId.DrillDownToAlerts,
			dimension: dimension,
			column: clickedValue,
			filters: drillDownFilters,
		});
	}

	private getDimensionDates(dimensionData: Array<ThreatProtectionData>): Array<string> {
		return dimensionData
			? dimensionData.map((data) => this.tzDateService.format(data.date, 'MM/dd'))
			: [];
	}

	private getDimensionData<T extends ThreatReportDimension>(
		type: ThreatReportDimensionType,
		dimensionData: Array<ThreatProtectionData>,
		dimensionMapping: ReportMapping<T>
	): (string | number | boolean)[][] {
		return Object.entries<ThreatReportDimensionMapping>(dimensionMapping)
			.filter(([key, value]) => !value.hiddenOnTrend)
			.map(([key, value]) =>
				this.getDimensionDataOvertime(dimensionMapping[key].label, key, dimensionData)
			);
	}

	private getDimensionDataOvertime(
		label: string,
		dimensionType: string,
		dimensionData: Array<ThreatProtectionData>
	) {
		return [
			label,
			...(dimensionData &&
				dimensionData.map((data) => (data.values[dimensionType] ? data.values[dimensionType] : 0))),
		];
	}

	private getDimensionDataDaily(label: string, dimensionType: string, dimensionData: ThreatProtectionData) {
		return {
			label: label,
			value:
				dimensionData &&
				(dimensionData.values[dimensionType] ? dimensionData.values[dimensionType] : 0),
		};
	}
}
