export function mapRegistryValueEntityToAirsEntity(entityData) {
    return Object.entries(entityData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case "Name" /* Name */:
                    acc['name'] = val;
                    acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
                    break;
                case "Value" /* Value */:
                    acc['value_data'] = val;
                    acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
                    break;
                case "ValueType" /* ValueType */:
                    acc['value_type'] = val;
                    break;
                case "Key" /* Key */:
                    acc['key'] = val['Key'];
                    acc['hive'] = val['Hive'];
                    break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
}
