/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tooltip-placeholder.component";
import * as i2 from "./tooltips.service";
var styles_WcdTooltipPlaceholderComponent = [];
var RenderType_WcdTooltipPlaceholderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WcdTooltipPlaceholderComponent, data: {} });
export { RenderType_WcdTooltipPlaceholderComponent as RenderType_WcdTooltipPlaceholderComponent };
export function View_WcdTooltipPlaceholderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { tooltipPlaceholder: 0 }), i0.ɵqud(402653184, 2, { tooltipContainerDiv: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(3, 0, [[2, 0], ["containerDiv", 1]], null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 16777216, [[1, 3], ["tooltipPlaceholder", 1]], null, 0, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, null); }
export function View_WcdTooltipPlaceholderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-tooltip-placeholder", [], null, null, null, View_WcdTooltipPlaceholderComponent_0, RenderType_WcdTooltipPlaceholderComponent)), i0.ɵdid(1, 245760, null, 0, i1.WcdTooltipPlaceholderComponent, [i2.TooltipsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WcdTooltipPlaceholderComponentNgFactory = i0.ɵccf("wcd-tooltip-placeholder", i1.WcdTooltipPlaceholderComponent, View_WcdTooltipPlaceholderComponent_Host_0, {}, {}, []);
export { WcdTooltipPlaceholderComponentNgFactory as WcdTooltipPlaceholderComponentNgFactory };
