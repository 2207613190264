/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./machine-itp-uac-flags.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "./machine-itp-uac-flags.component";
var styles_MachineUacFlagsComponent = [i0.styles];
var RenderType_MachineUacFlagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MachineUacFlagsComponent, data: {} });
export { RenderType_MachineUacFlagsComponent as RenderType_MachineUacFlagsComponent };
function View_MachineUacFlagsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "wcd-machine-uac-flag-item"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "wcd-machine-uac-flag-item-enabled": 0 }), (_l()(), i1.ɵted(4, null, ["\n\t\t\t\t", "\n\t\t\t"])), i1.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-machine-uac-flag-item"; var currVal_1 = _ck(_v, 3, 0, !!_co.uacFlags[_v.context.$implicit]); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), ("machines.entityDetails.fields.uacFlags.flagsTexts." + _v.context.$implicit))); _ck(_v, 4, 0, currVal_2); }); }
export function View_MachineUacFlagsComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "ul", [["class", "wcd-machine-uac-flag-list"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MachineUacFlagsComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supportedUacFlagsKeys; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_MachineUacFlagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "machine-itp-uac-flags", [], null, null, null, View_MachineUacFlagsComponent_0, RenderType_MachineUacFlagsComponent)), i1.ɵdid(1, 114688, null, 0, i5.MachineUacFlagsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MachineUacFlagsComponentNgFactory = i1.ɵccf("machine-itp-uac-flags", i5.MachineUacFlagsComponent, View_MachineUacFlagsComponent_Host_0, { uacFlags: "uacFlags" }, {}, []);
export { MachineUacFlagsComponentNgFactory as MachineUacFlagsComponentNgFactory };
