var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { fromEvent, zip } from 'rxjs';
import { map, debounceTime, take } from 'rxjs/operators';
import { LocaleConfigService, TzDateService } from '@wcd/localization';
import { Paris } from '@microsoft/paris';
import { OrgExposureScoreApiCall, RecommendationException } from '@wcd/domain';
import { TvmColor, TvmColorService } from '../../../services/tvm-color.service';
import { I18nService } from '@wcd/i18n';
import { TvmTextsService, TextToken } from '../../../services/tvm-texts.service';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';
import { TvmChartTooltipComponent, EXPOSURE_SCORE_TOOLTIP_ID, } from '../../../components/tooltips/events/tvm-chart-tooltip.component';
import { TvmTopChangeEventsService, ChangeEventDomain, } from '../../../services/tvm-top-change-events.service';
import { generateDatesForHistoryArray } from '../../../tvm-utils';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
var TvmOrgExposureScoreWidget = /** @class */ (function (_super) {
    __extends(TvmOrgExposureScoreWidget, _super);
    function TvmOrgExposureScoreWidget(reportsService, tvmTextsService, machineGroupsFilterService, tvmColorsService, paris, topChangeEventService, localeConfigService, tzDateService, i18nService, appInsightsService) {
        var _this = _super.call(this, reportsService) || this;
        _this.tvmTextsService = tvmTextsService;
        _this.machineGroupsFilterService = machineGroupsFilterService;
        _this.tvmColorsService = tvmColorsService;
        _this.paris = paris;
        _this.topChangeEventService = topChangeEventService;
        _this.localeConfigService = localeConfigService;
        _this.tzDateService = tzDateService;
        _this.i18nService = i18nService;
        _this.appInsightsService = appInsightsService;
        _this.forceGaugeReRender$ = fromEvent(window, 'resize').pipe(debounceTime(200), map(function (e) { return true; }));
        _this.exposureScorePercentage$ = _this.data$.pipe(map(function (data) { return (data ? Math.floor(data.exposureScore) / 100 : null); }));
        _this.gauge = {
            config: {
                sections: [
                    { percent: 0, label: '0' },
                    { percent: 0.3, label: '30' },
                    { percent: 0.7, label: '70' },
                    { percent: 1, label: '100' },
                ],
                gaugeMargin: 35,
                labels: {
                    show: true,
                    needleLabelDistance: 8,
                },
                digital: {
                    show: true,
                    height: 30,
                },
                needleCircleRadius: 4,
            },
        };
        _this.gaugeLegendItems = [
            {
                name: _this.i18nService.get('tvm.gauge.legend.low'),
                iconColor: _this.tvmColorsService.colorsMap.get(TvmColor.LowRisk).raw,
            },
            {
                name: _this.i18nService.get('tvm.gauge.legend.medium'),
                iconColor: _this.tvmColorsService.colorsMap.get(TvmColor.MediumRisk).raw,
            },
            {
                name: _this.i18nService.get('tvm.gauge.legend.high'),
                iconColor: _this.tvmColorsService.colorsMap.get(TvmColor.HighRisk).raw,
            },
        ];
        _this.widgetTooltip = tvmTextsService.getText(TextToken.ExposureScoreWidgetInfo);
        _this.widgetTooltipAriaLabel = tvmTextsService.getText(TextToken.ExposureScoreWidgetInfoAriaLabel);
        _this.accessibleGaugeExposureScore$ = _this.data$.pipe(map(function (score) { return score && Math.floor(score.exposureScore); }));
        return _this;
    }
    Object.defineProperty(TvmOrgExposureScoreWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'orgExposureScoreWidget',
                name: this.i18nService.get('tvm.exposureScoreWidget.title'),
                noDataMessage: function () {
                    return _this.tvmTextsService.getText(TextToken.NoDataForWidget, {
                        noDataKey: 'tvm.dashboard.noData.exposureScore',
                        isGroupSelected: _this.isMgSelected,
                    });
                },
                noDataIcon: FabricIconNames.Trackers,
                NoIconLeftAlign: true,
                loadData: function () {
                    var mg$ = _this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1));
                    var score$ = _this.paris.apiCall(OrgExposureScoreApiCall, {}); //TODO: bad practice ahead - the empty params is due to the fact that Paris fires the "parseQuery" CB only if arg is provided
                    var exceptions$ = _this.paris
                        .getRepository(RecommendationException)
                        .query({ where: { status: ['Active'] } });
                    var vaEvents$ = _this.topChangeEventService.getChangeEvents$(ChangeEventDomain.VA);
                    return zip(mg$, score$, exceptions$, vaEvents$).pipe(map(function (_a) {
                        var mg = _a[0], score = _a[1], exceptions = _a[2], vaEvents = _a[3];
                        _this.isMgSelected = mg.isFiltering;
                        _this.isImpactedByExceptions = exceptions.items.length > 0;
                        var history = score.exposureScoreHistory;
                        if (score.exposureScore === 0 && Array.isArray(history) && history.length === 0) {
                            return null;
                        }
                        _this.setNewChartValues(score, vaEvents || new Map());
                        return score;
                    }));
                },
                minHeight: '400px',
            };
        },
        enumerable: true,
        configurable: true
    });
    TvmOrgExposureScoreWidget.prototype.setNewChartValues = function (data, vaEvents) {
        var _this = this;
        if (!data) {
            this.trendOptions = null;
            this.newChartOptions = null;
            return;
        }
        var historyDataPoints = generateDatesForHistoryArray(data.exposureScoreHistory, this.localeConfigService.isLocalTimeZone);
        this.newChartOptions = {
            data: historyDataPoints.map(function (point) { return (__assign({}, point, { events: vaEvents.get(_this.tzDateService.format(point.date, 'MM/dd')) })); }),
            legend: this.i18nService.get('tvm.exposureScoreWidget.exposureOverTimeLegend'),
            tooltipComponent: TvmChartTooltipComponent,
            id: 'orgExposureScoreHistoryLine',
            tooltipId: EXPOSURE_SCORE_TOOLTIP_ID,
        };
        this.trendOptions = {
            title: this.i18nService.get('tvm.exposureScoreWidget.exposureOverTime'),
            scoreHistory: historyDataPoints.map(function (point) { return point.value; }),
            lowerScoreBetter: true,
        };
    };
    return TvmOrgExposureScoreWidget;
}(ReportWidgetComponent));
export { TvmOrgExposureScoreWidget };
