import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { directoryTypes } from './directory-type.values';

@Entity({
	singularName: 'Directory type',
	pluralName: 'Directory types',
	values: directoryTypes,
})
export class DirectoryType extends EntityModelBase<number> {
	@EntityField() type: string;
	@EntityField() name: string;
}
