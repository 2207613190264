import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { ModalContainer } from '../../dialogs/modals/models/modal-container.model';
import { I18nService } from '@wcd/i18n';
import { EvaluationLab } from '@wcd/domain';
import { EvaluationService } from '../services/evaluation.service';

@Component({
	selector: 'evaluation-password-dialog',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<modal (close)="destroy()" [settings]="settings">
			<div class="wcd-padding-large-bottom wcd-padding-large-horizontal">
				<div class="wcd-padding-bottom" *ngIf="lab && provisionedMachines">
					{{ getDescription() }}
				</div>
				<markdown
					[data]="
						'evaluation.dashboard.dataView.commandBar.addMachine.saveConnectionDetails' | i18n
					"
				></markdown>
				<div class="wcd-margin-top">
					<span class="wcd-font-weight-bold">
						{{ 'evaluation.dashboard.dataView.commandBar.addMachine.machineName' | i18n }}
					</span>
					{{ machineName }}
				</div>
				<div>
					<span class="wcd-font-weight-bold">
						{{ 'evaluation.dashboard.dataView.commandBar.addMachine.userName' | i18n }}
					</span>
					{{ evaluationService.getMachineUserName() }}
				</div>
				<fab-text-field [value]="password" [readOnly]="true" [disabled]="true"></fab-text-field>
				<copy-to-clipboard
					[content]="password"
					[originId]="'evaluation-machine-password'"
					class="wcd-flex-items-end-vertical"
				>
				</copy-to-clipboard>
			</div>
		</modal>
	`,
})
export class EvaluationPasswordDialogComponent extends ModalContainer {
	@Input() password: string;
	@Input() machineName: string;
	@Input() lab?: EvaluationLab;
	@Input() provisionedMachines?: number;

	constructor(
		router: Router,
		private i18nService: I18nService,
		public evaluationService: EvaluationService
	) {
		super(router);
	}

	getDescription() {
		return this.provisionedMachines + 1 === this.lab.maxNumberOfMachines
			? this.i18nService.get(
					`evaluation.dashboard.dataView.commandBar.addMachine.descriptionLastMachine`
			  )
			: this.i18nService.get(`evaluation.dashboard.dataView.commandBar.addMachine.description`, {
					provisionedMachinesCount: this.provisionedMachines,
					maxMachinesCount: this.lab.maxNumberOfMachines,
			  });
	}
}
