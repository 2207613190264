<!-- tslint:disable:template-click-events-have-key-events -->
<div 	[id]="id"
		(mouseenter)="onEnterTooltip.emit()"
		(mouseleave)="onLeaveTooltip.emit()"
		#eventDiv
		[style.opacity]="opacity"
		[style.left.px]="left"
		[style.bottom.px]="bottom"
		[style.pointer-events]="opacity === 0 ? 'none' : null"
		[style.min-width]="minWidth + 'px'"
		class="events-hover-tooltip wcd-padding-mediumSmall-all wcd-flex-vertical">
	<div class="tooltip-date">
		{{ formattedDate }}
	</div>
	<div class="tooltip-legend">
		{{ legend }}
	</div>
	<div class="hovered-score">
		{{ percentageDisplay ? (score + '%') : (score | prettyNumber) }}
	</div>
	<div *ngIf="percentageDisplay" class="nominator-denominator">
		{{ percentNominator }}/{{ percentDenominator }}
	</div>
	<ng-container *ngIf="isDisplayingData">
		<div class="score-border wcd-border-top-modern"></div>
		<div *ngFor="let event of eventLines" class="wcd-flex-horizontal">
			<i style="padding-top:2px;" class="icon icon-ChevronRight ms-fontSize-10"></i>
			<div class="event-text wcd-padding-xsmall-left" [innerHTML]="event"></div>
		</div>
		<div class="wcd-padding-xsmall-top">
			<a [attr.data-track-id]="'TvmOpenEventsFromGraph_' + id" data-track-type="Navigation" (click)="openEventsPageOnDate()"
				>{{ linkText }}
			</a>
		</div>
	</ng-container>
</div>
