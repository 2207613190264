
		<ng-container *ngIf="statistics">
			<ng-container *ngIf="!isLoadingOrganizationPrevalence; else loading">
					<div>
						<h4>{{'file_prevalence_30d' | i18n}}</h4>
					</div>
					<ng-container *ngIf="showLowPrevMessage">
						<div class="wcd-padding-medium-bottom">
							<wcd-shared-icon [iconName]="'warning'"></wcd-shared-icon>
							<span>{{'file_Org_prevalence' | i18n}}</span>
						</div>
					</ng-container>
					<div class="wcd-padding-large-bottom">
						<distribution-element
							[title]="'file_mailStats_emailsSummary' | i18n"
							[mainText]="transformToPrettyNumber(mailStats && mailStats.numberOfInboxes || 0)"
						></distribution-element>
						<distribution-element
							[title]="'reports_widgets_prevalence_org_device' | i18n"
							[mainText]="transformToPrettyNumber(statistics.organizationPrevalence || 0)"
						></distribution-element>
						<distribution-element
							[title]="'reports_widgets_prevalence_worldwide_device' | i18n"
							[mainText]="transformToPrettyNumber(statistics.worldwidePrevalence || 0 )"
						></distribution-element>
					</div>
					<ng-container *ngIf="statistics.organizationLastSeen">
						<div class="wcd-width-x-large">
							<wcd-datatable [items]="orgPrevItems" [columns]="orgPrevFields" [selectEnabled]="false">
							</wcd-datatable>
						</div>
					</ng-container>
					<ng-container *ngIf="statistics.worldwideLastSeen">
						<div class="wcd-width-x-large">
							<wcd-datatable [items]="worldwidePrevItems" [columns]="worldwidePrevFields"
										[selectEnabled]="false">
							</wcd-datatable>
						</div>
					</ng-container>
				<div class="wcd-padding-small-bottom">
						<route-link [routeLink]="{ routerLink: fileLink }">
							{{ 'reports_widgets_prevalence_go_to_observed_in_org' | i18n }}
						</route-link>
					</div>
					<div>
						<a
								*ngIf="mailStats && mailStats.deepLinkToO365Portal"
								(click)='log365PortalClickEvent()'
								href="{{ mailStats.deepLinkToO365Portal }}"
								data-track-id="deepLinkToO365Portal"
								data-track-type="Navigation"
							>
								{{ 'file.mailStats.openInO365' | i18n }}
						</a>
					</div>
			</ng-container>
		</ng-container>
		<ng-template #loading>
			<div class="wcd-margin-small-bottom wcd-m365-typography">
				<div class="wcd-flex-horizontal">
					<div [ngClass]="{ 'wcd-flex-2': isLoadingOrganizationPrevalence }">
						<fab-shimmer
							[isDataLoaded]="!isLoadingOrganizationPrevalence"
							[styles]="shimmerStyles"
						>
							<h4 class="wcd-margin-none-bottom">
								{{
								'file.prevalence.machinesOrganization'
									| i18n
									: {
										organizationPrevalence: transformToPrettyNumber(
											statistics.organizationPrevalence
										)
									}
								}}
							</h4>
						</fab-shimmer>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #noData>
			<h4>{{ 'reports.widgets.prevalence.noData' | i18n }}</h4>
		</ng-template>
	