<ng-container *ngIf="userProfile">
	<dl [class.key-values]="asKeyValueList" role="none">
		<dt>{{ 'users.entityDetails.name' | i18n }}</dt>
		<dd>{{ userProfile.name }}</dd>
	</dl>
</ng-container>

<user-security-info-details
	[asKeyValueList]="asKeyValueList"
	[user]="user"
	[kind]="mode === EntityDetailsMode.EntityPage ? 'compact' : 'full'"
></user-security-info-details>

<ng-container [class.wcd-margin-large-top]="!!mode" *ngTemplateOutlet="entityDetails"></ng-container>

<user-network-activity-details
	*ngIf="showNetworkActivity"
	[asKeyValueList]="asKeyValueList"
	[user]="user"
></user-network-activity-details>

<user-contact-details *ngIf="showContactDetails" [user]="user"></user-contact-details>

<ng-template #entityDetails>
	<dl [class.key-values]="asKeyValueList" role="none">
		<dt>{{ 'users.entityDetails.sections.details.sam.title' | i18n }}</dt>
		<dd>
			<ng-container *ngIf="showEntityPanelLink && isClickable; else userName">
				<entity-link [entity]="user" [entityType]="userType" [linkText]="user.fullName"></entity-link>
			</ng-container>
			<ng-template #userName>{{ user.fullName }}</ng-template>
		</dd>

		<ng-container *ngIf="user.sid">
			<dt>{{ 'users.entityDetails.sections.details.sid.title' | i18n }}</dt>
			<dd>
				<copyable-input [ariaLabel]="'users.entityDetails.sections.details.sid.title' | i18n" [value]="user.sid" [readonly]="true"></copyable-input>
			</dd>
		</ng-container>

		<ng-container *ngIf="userProfile">
			<dt>{{ 'users.entityDetails.sections.details.department.title' | i18n }}</dt>
			<dd>{{ userProfile.department }}</dd>

			<dt>{{ 'users.entityDetails.sections.details.jobTitleUserProfile.title' | i18n }}</dt>
			<dd>{{ userProfile.title }}</dd>
		</ng-container>

		<dt>{{ 'machines.entityDetails.fields.azureAtpAlerts.title' | i18n }}</dt>
		<dd>
			<wcd-contents-state [contentState]="aatpDisplayDataContentState">
				<i loading class="loader-icon"></i>
				{{ 'machines.entityDetails.fields.azureAtpAlerts.loading' | i18n }}
				<ng-container complete *ngIf="aatpDisplayData">
					<a
						data-track-id="AatpAlertsDetails"
						data-track-type="ExternalLink"
						[href]="aatpDisplayData.url"
						target="_blank"
						>{{
							'machines.entityDetails.fields.azureAtpAlerts.details'
								| i18n: { count: aatpDisplayData.count }
						}}</a
					>
				</ng-container>
				<ng-container empty>
					<ng-container [ngTemplateOutlet]="notFoundOrEmpty"></ng-container>
				</ng-container>
				<ng-container error>
					<ng-container [ngTemplateOutlet]="notFoundOrEmpty"></ng-container>
				</ng-container>

				<ng-template #notFoundOrEmpty>{{
					'machines.entityDetails.fields.azureAtpAlerts.userNotFound' | i18n
				}}</ng-template>
			</wcd-contents-state>
		</dd>
	</dl>
</ng-template>
