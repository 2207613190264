import { AirsNotificationRuleConditionGroupModel } from '../models/airs-notification-rule-condition-group.model';
import { Injectable, NgZone } from '@angular/core';
import { AirsNotificationRuleConditionGroupsBackendService } from './airs-notification-rule-condition-groups.backend.service';
import { ReadOnlyStore } from '../../../data/models/readonly-store.base';

@Injectable()
export class AirsNotificationRuleConditionGroupsStore extends ReadOnlyStore<
	AirsNotificationRuleConditionGroupModel,
	string
> {
	constructor(backendService: AirsNotificationRuleConditionGroupsBackendService, ngZone: NgZone) {
		super(backendService, ngZone, AirsNotificationRuleConditionGroupModel, {
			itemNameSingular: 'Notification Rule Condition Group',
			itemNamePlural: 'Notification Rule Condition Groups',
		});
	}
}
