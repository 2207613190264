<dl [class.key-values]="asKeyValueList" role="none">
	<ng-container *ngFor="let fieldName of registryProperties">
		<ng-container *ngIf="(current && current[fieldName]) || (previous && previous[fieldName])">
			<ng-container
				*ngTemplateOutlet="fieldDetails;context:{ fieldName: fieldName, current: current, previous: previous }"></ng-container>
		</ng-container>
	</ng-container>

	<ng-template #fieldDetails let-fieldName="fieldName" let-current="current" let-previous="previous">
		<dt>{{ 'registry.fields.' + fieldName + '.title' | i18n }}</dt>
		<dd>
			<ng-container *ngIf="current; else onlyPreviousValue">
				<copyable-input [value]="current[fieldName]" showFullValue="true" [readonly]="true"
								[ariaLabel]="'registry.fields.' + fieldName + '.title' | i18n"></copyable-input>
				<ng-container *ngIf="previous && (previous[fieldName] !== current[fieldName])">
					<ng-container
						*ngTemplateOutlet="previousDetails;context:{  fieldName: fieldName, value: previous[fieldName] }"></ng-container>
				</ng-container>
			</ng-container>
			<ng-template #onlyPreviousValue>
				<ng-container
					*ngTemplateOutlet="onlyPreviousValueDisplay;context:{ fieldName: fieldName, previous: previous }"></ng-container>
			</ng-template>
		</dd>
	</ng-template>

	<ng-template #onlyPreviousValueDisplay let-fieldName="fieldName" let-previous="previous">
		<copyable-input *ngIf="previous" [value]="previous[fieldName]" showFullValue="true" [readonly]="true"
						[ariaLabel]="'registry.fields.' + fieldName + '.title' | i18n"></copyable-input>
	</ng-template>

	<ng-template #previousDetails let-value="value" let-fieldName="fieldName" >
		<span class="wcd-flex-horizontal wcd-flex-center-vertical wcd-padding-small-top">
			<span class="wcd-flex-none wcd-margin-small-right">{{ 'registry.fields.changedFrom' | i18n }}:</span>
			<copyable-input *ngIf="value; else empty" class="wcd-flex-1 subtle"
							[value]="value" showFullValue="true" [readonly]="true"
							[ariaLabel]="'registry.fields.' + fieldName + '.title' | i18n"></copyable-input>
		</span>
	</ng-template>

	<ng-template #empty><span class="subtle">[{{'registry.fields.empty' | i18n}}]</span></ng-template>
</dl>
