var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentRef } from '@angular/core';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { Incident, IncidentStatusValueIds, IncidentsUpdateApiCall, ServiceSourceType, Tag, TagType, } from '@wcd/domain';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { AuthService, MtpPermission } from '@wcd/auth';
import { Paris } from '@microsoft/paris';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { IncidentEntityPanelComponent } from '../components/incident.entity-panel.component';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { IncidentEntityDetailsComponent } from '../../../global_entities/components/entity-details/incident.entity-details.component';
import { IncidentEntityComponent } from '../components/incident.entity.component';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { PanelType } from '@wcd/panels';
import { IncidentCommentsComponent } from '../components/incident-comments.component';
import { IncidentsService } from './incidents.service';
import { mergeMap, take } from 'rxjs/operators';
import { IncidentsEntityPanelComponent } from '../components/incidents.entity-panel.component';
import { FabricIconNames } from '@wcd/scc-common';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { Feature, FeaturesService } from '@wcd/config';
import { AskThreatExpertService } from '../../../feedback/services/ask-threat-expert.service';
import { of } from 'rxjs';
import { countBy, flatMap, sortBy, uniq } from 'lodash-es';
import { IncidentManagePanelComponent } from '../components/incident.manage-panel.component';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { EntityTagsService } from '../../common/services/entity-tags.service';
import { sccHostService } from '@wcd/scc-interface';
import { panelService } from '@wcd/shared';
var IncidentEntityTypeService = /** @class */ (function () {
    function IncidentEntityTypeService(authService, paris, dialogsService, i18nService, incidentsService, featuresService, askThreatExpertService, rbacControlService, entityTagsService) {
        var _this = this;
        this.authService = authService;
        this.paris = paris;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.incidentsService = incidentsService;
        this.featuresService = featuresService;
        this.askThreatExpertService = askThreatExpertService;
        this.rbacControlService = rbacControlService;
        this.entityTagsService = entityTagsService;
        this.assignToMeButton = {
            id: 'incidentAssignToMe',
            nameKey: 'incident.commandBar.actions.manageIncident.assignToMe',
            icon: 'users.userCheck',
            refreshOnResolve: true,
            rbacState: RbacControlState.hidden,
            rbacMtpPermission: [MtpPermission.SecurityData_Manage],
            rbacRequireAllPermissions: true,
            rbacIgnoreNonActiveWorkloads: true,
            method: function (incidents) {
                return _this.incidentsService.assignIncidentsToMe(incidents).toPromise();
            },
        };
        this.unassignButton = {
            id: 'incidentUnAssign',
            nameKey: 'incident.commandBar.actions.manageIncident.unassign',
            icon: 'users.user_add',
            refreshOnResolve: true,
            rbacState: RbacControlState.hidden,
            rbacMtpPermission: [MtpPermission.SecurityData_Manage],
            rbacRequireAllPermissions: true,
            rbacIgnoreNonActiveWorkloads: true,
            method: function (incidents) {
                return _this.incidentsService.unassignIncidents(incidents).toPromise();
            },
        };
        this.resolveButton = {
            id: 'resolve',
            nameKey: 'incident.resolve.resolve',
            icon: 'check',
            refreshOnResolve: true,
            rbacState: RbacControlState.hidden,
            rbacMtpPermission: [MtpPermission.SecurityData_Manage],
            rbacRequireAllPermissions: true,
            rbacIgnoreNonActiveWorkloads: true,
            method: function (incidents) {
                return _this.incidentsService
                    .showSetClassificationPanel(incidents)
                    .pipe(take(1)) // takes one to make the observable complete - otherwise Promise.then() will never be called
                    .toPromise();
            },
        };
        this.reactivateButton = {
            id: 'reactivate',
            nameKey: 'incident.reactivate.reactivate',
            icon: 'undo',
            refreshOnResolve: true,
            rbacState: RbacControlState.hidden,
            rbacMtpPermission: [MtpPermission.SecurityData_Manage],
            rbacRequireAllPermissions: true,
            rbacIgnoreNonActiveWorkloads: true,
            method: function (incidents) {
                return _this.incidentsService
                    .showSetClassificationPanel(incidents)
                    .pipe(take(1)) // takes one to make the observable complete - otherwise Promise.then() will never be called
                    .toPromise();
            },
        };
        this.commentsButton = {
            id: 'comments',
            nameKey: 'incident.commandBar.commentsAndHistory.title',
            icon: 'comment',
            refreshOnResolve: false,
            method: function (incidents) {
                // TODO refactor explicit construction
                return new Promise(function (resolve, reject) {
                    if (_this._commentsPanel) {
                        // Comments panel is already open and button was clicked - closing it
                        _this._commentsPanel.instance.destroy();
                        _this._commentsPanel = null;
                        resolve();
                    }
                    else {
                        _this.dialogsService
                            .showPanel(IncidentCommentsComponent, {
                            id: 'incident-comments',
                            type: PanelType.medium,
                            isModal: true,
                            headerText: _this.i18nService.get('incident.commandBar.commentsAndHistory.title'),
                            showOverlay: false,
                            isBlocking: false,
                        }, {
                            incident: incidents.length === 1 ? incidents[0] : null,
                        })
                            .subscribe(function (panel) {
                            _this._commentsPanel = panel;
                            panel.onDestroy(function () {
                                _this._commentsPanel = null;
                                resolve();
                            });
                        }, function (error) {
                            reject(error);
                        });
                    }
                });
            },
        };
        this.setStatusAndClassificationButton = {
            id: 'incidentSetClassificationAndDetermination',
            nameKey: 'incident_ManageIncidents',
            icon: FabricIconNames.SetAction,
            refreshOnResolve: true,
            rbacState: RbacControlState.hidden,
            rbacMtpPermission: [MtpPermission.SecurityData_Manage],
            rbacRequireAllWorkloads: true,
            rbacIgnoreNonActiveWorkloads: true,
            method: function (incidents) {
                return _this.incidentsService
                    .showSetClassificationPanel(incidents)
                    .pipe(take(1))
                    .toPromise();
            },
        };
        this.manageIncidentButton = {
            id: 'manageIncident',
            nameKey: 'incident.commandBar.manageIncident.title',
            icon: FabricIconNames.Edit,
            refreshOnResolve: true,
            rbacState: RbacControlState.hidden,
            rbacMtpPermission: [MtpPermission.SecurityData_Manage],
            rbacRequireAllPermissions: true,
            rbacIgnoreNonActiveWorkloads: true,
            method: function (incidents) {
                // TODO fix explicit construction
                return new Promise(function (resolve, reject) {
                    if (sccHostService.isSCC) {
                        if (incidents.length && incidents[0]) {
                            var id_1 = '';
                            var PanelComponent = sccHostService.ui.render('ManageIncidentFlyoutWithAppBootstrap@wicd-ine/main', {
                                incident: incidents[0],
                                onDismiss: function (shouldReloadActions) {
                                    panelService.dismissPanel(id_1);
                                    !shouldReloadActions ? resolve() : reject();
                                },
                            });
                            id_1 = panelService.openPanel(PanelComponent);
                        }
                    }
                    else {
                        if (_this._incidentManagePanel) {
                            _this._incidentManagePanel.instance.destroy();
                            _this._incidentManagePanel = null;
                            resolve();
                        }
                        else {
                            _this.dialogsService
                                .showPanel(IncidentManagePanelComponent, {
                                id: 'incident-manage',
                                type: PanelType.large,
                                isModal: true,
                                headerText: _this.i18nService.get('incident.commandBar.manageIncident.title'),
                                showOverlay: false,
                                isBlocking: false,
                            }, {
                                incident: incidents.length === 1 ? incidents[0] : null,
                            })
                                .subscribe(function (panel) {
                                _this._incidentManagePanel = panel;
                                panel.onDestroy(function () {
                                    var wasSaving = _this._incidentManagePanel.instance.isSaving;
                                    _this._incidentManagePanel = null;
                                    if (wasSaving) {
                                        resolve();
                                    }
                                    else {
                                        reject();
                                    }
                                });
                            }, function (error) {
                                reject(error);
                            });
                        }
                    }
                });
            },
        };
        this.entityType = {
            id: 'incident',
            entity: Incident,
            icon: FabricIconNames.ATPLogo,
            getImage: function (incidents) {
                var commonSeverity = GlobalEntityTypesService.getCommonValue(incidents, function (incident) { return incident.severity; });
                return "/assets/images/icons/entities/incident_" + (commonSeverity ? commonSeverity.name.toLowerCase() : 'informational') + ".svg";
            },
            entityDetailsComponentType: IncidentEntityDetailsComponent,
            entityContentsComponentType: IncidentEntityComponent,
            singleEntityPanelComponentType: IncidentEntityPanelComponent,
            multipleEntitiesComponentType: IncidentsEntityPanelComponent,
            getEntityName: function (incident) { return incident.name; },
            entityPluralNameKey: 'incident_entityType_pluralName',
            entitySingularNameKey: 'incident_entityType_singularName',
            getEntityDataviewLink: function () { return '/incidents'; },
            getEntitiesLink: function (incidents) {
                return incidents.length === 1 ? "/incidents/" + incidents[0].id : null;
            },
            maxNameLength: 50,
            getRenameFunction: function (newName, incidentId) {
                return _this.paris
                    .apiCall(IncidentsUpdateApiCall, {
                    title: newName,
                    incidentIds: [incidentId],
                })
                    .pipe(mergeMap(function () { return _this.paris.getItemById(Incident, incidentId); }));
            },
            getEnableRename: function (incident) {
                var rbacSettings = {
                    mtpPermissions: [MtpPermission.SecurityData_Manage],
                    mtpWorkloads: incident.mtpWorkloads,
                    requireAllPermissions: true,
                    rbacIgnoreNonActiveWorkloads: true,
                };
                return incident.isFullyMachineRbacExposed && _this.rbacControlService.hasRequiredRbacPermissions(rbacSettings);
            },
            getTags: function (incidents) {
                if (incidents.length === 1) {
                    var incident = incidents[0];
                    return incident.incidentTags ? of(_this.entityTagsService.getIncidentTags(incident))
                        : of(incident.tags.map(function (tag) { return ({
                            id: tag,
                            name: tag,
                            type: TagType.user,
                        }); }).slice());
                }
                var incidentsTagCounts = countBy(flatMap(incidents, function (incident) { return incident.tags; }));
                var incidentsTags = Object.entries(incidentsTagCounts).map(function (_a) {
                    var tag = _a[0], count = _a[1];
                    return new Tag({ id: tag, name: tag, isPartial: count < incidents.length });
                });
                return of(sortBy(incidentsTags, function (tag) { return tag.name.toLowerCase(); }));
            },
            getNavigationModel: function (incident) {
                var link = _this.entityType.getEntitiesLink([incident]);
                return link ? { routerLink: [link] } : null;
            },
            getActions: function (incidents, options, entityViewType) {
                var actions = [];
                var allAlertsAssignedToCurrentUser = incidents.every(function (incident) {
                    return _this.authService.isCurrentUser(incident.assignedTo);
                });
                var isIncidentResolved = incidents.length === 1 &&
                    incidents[0] &&
                    incidents[0].status &&
                    incidents[0].status.id &&
                    incidents[0].status.id === IncidentStatusValueIds.IncidentResolvedStatusId;
                var userFullyExposed = incidents.every(function (incident) { return incident.isFullyMachineRbacExposed; });
                if (entityViewType === EntityViewType.EntityPage) {
                    actions = _this.getEntityPageViewTypeActionButtonsArray(incidents, userFullyExposed);
                }
                else {
                    // incident side panel actions
                    actions = _this.getEntitySidePanelActionButtonsArray(userFullyExposed, isIncidentResolved, allAlertsAssignedToCurrentUser, entityViewType, incidents);
                }
                return actions.map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); });
            },
            entityPageViewMode: EntityPageViewMode.Modern,
        };
        this._isUpdatedIncidentQueue = this.featuresService.isEnabled(Feature.UpdatedIncidentQueue);
    }
    IncidentEntityTypeService.prototype.getChildLinks = function (incident) {
        return {
            mailboxes: "/incidents/" + incident.id + "/mailboxes",
            users: "/incidents/" + incident.id + "/users",
            machines: "/incidents/" + incident.id + "/machines",
            evidence: "/incidents/" + incident.id + "/evidence",
            apps: "/incidents/" + incident.id + "/apps",
        };
    };
    IncidentEntityTypeService.prototype.incidentsHaveServiceSource = function (serviceSourceType, incidents) {
        incidents.forEach(function (incident) {
            if (incident.serviceSources.find(function (serviceSource) { return serviceSource.id === serviceSourceType; })) {
                return true;
            }
        });
        return false;
    };
    IncidentEntityTypeService.prototype.getEntityPageViewTypeActionButtonsArray = function (incidents, userFullyExposed) {
        var actions = [this.commentsButton];
        var workloads = uniq(flatMap(incidents.map(function (inc) { return inc.mtpWorkloads; })));
        // TODO: Remove this logic when management team support MAPG
        var extraRbacCheck = this.incidentsHaveServiceSource(ServiceSourceType.Mapg, incidents) ? this.authService.currentUser.hasDefaultManagePermissions : null;
        if (this.askThreatExpertService.shouldShowThreatExpertPanel()) {
            actions.unshift(this.askThreatExpertService.getAskTheExpertCommandConfig('incidentEntityCommandBar'));
        }
        if (userFullyExposed) {
            actions.unshift(__assign({}, this.manageIncidentButton, { rbacMtpWorkloads: workloads }));
        }
        return actions;
    };
    IncidentEntityTypeService.prototype.getEntitySidePanelActionButtonsArray = function (userFullyExposed, isIncidentResolved, allAlertsAssignedToCurrentUser, entityViewType, incidents) {
        var actions = [];
        if (!userFullyExposed) {
            return actions;
        }
        var workloads = uniq(flatMap(incidents.map(function (inc) { return inc.mtpWorkloads; })));
        if (incidents.length === 1) {
            actions.push(__assign({}, this.manageIncidentButton, { rbacMtpWorkloads: workloads }));
        }
        if (!allAlertsAssignedToCurrentUser && !this.featuresService.isEnabled(Feature.AssignToSomeoneElse)) {
            actions.push(__assign({}, this.assignToMeButton, { rbacMtpWorkloads: workloads }));
        }
        if (entityViewType === EntityViewType.MultipleEntitySidePanel) {
            actions.push(__assign({}, this.setStatusAndClassificationButton, { rbacMtpWorkloads: workloads }));
            return actions;
        }
        return actions;
    };
    return IncidentEntityTypeService;
}());
export { IncidentEntityTypeService };
