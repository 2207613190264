import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { TzDateService } from '@wcd/localization';
import {
	WebContentFilteringAccessTrend,
	WebContentFilteringCategoryReport,
	WebContentFilteringMachineListReport,
} from '@wcd/domain';
import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import { ChartSettings } from '@wcd/charts';
import { ReportsService } from '../../../shared-reports/services/reports.service';
import { config } from '@wcd/shared';

const MAX_LABELS = 8;

@Component({
	selector: 'web-content-filtering-access-trend-over-time-chart',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-time-series-chart
			[data]="entity.accessTrends"
			[settings]="chartSettings"
		></wcd-time-series-chart>
	`,
})
export class WebContentFilteringAccessTrendOvertimeChart implements OnInit {
	@Input() entity: WebContentFilteringCategoryReport | WebContentFilteringMachineListReport;

	chartSettings: ChartSettings;

	constructor(private tzDateService: TzDateService) {}

	ngOnInit() {
		this.chartSettings = this.getSettings(this.entity);
	}

	private getSettings(
		data?: WebContentFilteringCategoryReport | WebContentFilteringMachineListReport
	): ChartSettings {
		const maxAccessCount: number = data
			? data.accessTrends.reduce(
					(maxAccessCount: number, accessTrend: WebContentFilteringAccessTrend) => {
						return Math.max(maxAccessCount, accessTrend.count);
					},
					0
			  )
			: 1000;

		return {
			useValues: false,
			xProperty: 'date',
			setColors: true,
			series: [{ value: 'count', name: 'Access count', color: config.color.primary }],
			data: {
				order: 'desc',
			},
			options: {
				data: {},
				axis: {
					x: {
						tick: {
							format: value => this.tzDateService.format(value, 'MM/dd'),
							count: data ? data.accessTrends.length : MAX_LABELS,
						},
					},
					y: {
						min: 0,
						max: maxAccessCount,
						tick: {
							values: data ? ReportsService.getYTicks(maxAccessCount) : [],
						},
					},
				},
				size: {
					height: 300,
				},
				grid: {
					x: {
						show: false,
					},
					y: {
						show: true,
					},
				},
				point: {
					show: data && data.accessTrends.length === 1,
				},
				tooltip: {
					format: {
						value: (n: string) => format(',')(parseInt(n, 10)),
						title: timeFormat('%b %e, %Y'),
					},
				},
				legend: {
					show: false,
				},
			},
		};
	}
}
