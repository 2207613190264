/* tslint:disable:template-click-events-have-key-events */
import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Observable } from 'rxjs';
import { File, FileVerdict, FileFileVerdictsRelationship } from '@wcd/domain';
import { take, map } from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { FileDetectionsPanelComponent } from '../../../@entities/files/components/file-detections-panel.component';
import { PanelType } from '@wcd/panels';
import { I18nService } from '@wcd/i18n';
import { catchHttpError } from '../../../utils/rxjs/rxjs-custom-operators';

@Component({
	selector: 'file-detections-summary',
	template: `
		<div
			*ngIf="(fileVerdicts$ | async) as fileVerdicts"
			[ngClass]="{
				'wcd-flex-vertical wcd-flex-items-start': showLinkBelow,
				'wcd-flex-center-vertical wcd-flex-wrap': !showLinkBelow
			}"
		>
			<span class="wcd-padding-small-right">{{ getFileVerdictText(fileVerdicts) }}</span>
			<a
				*ngIf="fileVerdicts.length !== 0 && showFileVerdictPanelLink"
				class="wcd-font-weight-regular"
				(click)="showFileVerdictPanel(fileVerdicts)"
			>
				{{ 'files.entityDetails.sections.detections.fields.malwareDetected.seeDetails' | i18n }}
			</a>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDetectionsSummaryComponent implements OnInit {
	constructor(
		private readonly i18nService: I18nService,
		private readonly paris: Paris,
		private readonly dialogsService: DialogsService
	) {}

	@Input() file: File;

	@Input() showFileVerdictPanelLink: boolean = true;

	@Input() showLinkBelow: boolean = false;

	fileVerdicts$: Observable<ReadonlyArray<FileVerdict>>;

	getFileVerdictText(fileVerdicts: Array<FileVerdict>) {
		return !fileVerdicts || fileVerdicts.length === 0
			? this.i18nService.get('files.entityDetails.sections.detections.fields.malwareDetected.none')
			: fileVerdicts.length === 1
			? fileVerdicts[0].threatName
			: this.i18nService.get('files.entityDetails.sections.detections.fields.malwareDetected.multiple');
	}

	ngOnInit() {
		this.fileVerdicts$ = this.paris
			.queryForItem<File, FileVerdict>(FileFileVerdictsRelationship, this.file, null, {
				allowCache: true,
			})
			.pipe(
				take(1),
				catchHttpError(404, { items: [] }),
				map(result => result.items)
			);
	}

	showFileVerdictPanel(fileVerdicts: Array<FileVerdict>) {
		this.dialogsService.showPanel(
			FileDetectionsPanelComponent,
			{
				type: PanelType.large,
				headerText: this.getFileVerdictText(fileVerdicts),
			},
			{ file: this.file }
		);
	}
}
