import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core';
import { PrettyNumberService } from '@wcd/prettify';
import { cloneDeep, isNil, merge } from 'lodash-es';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const DEFAULT_SETTINGS: BarsChartSettings = {
	min: 0,
	max: null,
	isSelectable: true,
	valueProperty: 'value',
};

const GRADIENT_VENDOR_PREFIXES: Array<string> = ['', '-webkit-', '-moz-'];
const GRADIENT_TEST: string = 'linear-gradient(left, red, blue)';

@Component({
	selector: 'wcd-bars-chart',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="bars-chart" tabIndex="0" [attr.aria-label]="'charts.barsChart' | i18n">
			<ul [class.clickable]="settings.isSelectable" tabIndex="0">
				<li
					tabIndex="0"
					role="button"
					*ngFor="let item of items; trackBy: trackById"
					(click)="select.emit(item)"
					[class.with-icon]="item.image || item.icon"
				>
					<img *ngIf="item.image" [attr.src]="item.image" class="bars-chart-image" />
					<wcd-icon *ngIf="item.icon" [iconName]="item.icon" class="bars-chart-icon"></wcd-icon>
					<label
						[attr.data-object-type]="settings.objectType"
						[wcdTooltip]="item.itemNameTooltip"
						>{{ item.name }}</label
					>
					<div
						class="bars-chart-bar"
						[class.outer-value]="item.isOuterText || settings?.outerValues"
						[ngStyle]="{ width: item.relativeSize * 100 + '%', background: item.color }"
						[wcdTooltip]="item.tooltip"
					>
						<span class="bars-chart-value" tabIndex="0" [attr.aria-label]="item.valueDisplay">{{
							item.valueDisplay
						}}</span>
					</div>
				</li>
			</ul>
			<ul class="bars-chart-legend" *ngIf="settings.legend">
				<li *ngFor="let legendItem of settings.legend; trackBy: trackById">
					<wcd-icon [iconName]="'square'" [style.color]="legendItem.color"></wcd-icon>
					{{ legendItem.name }}
				</li>
			</ul>
		</div>
	`,
	styleUrls: ['./bars-chart.component.scss'],
})
export class BarsChartComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() settings: BarsChartSettings = DEFAULT_SETTINGS;
	@Input() data: Array<BarsChartItem>;

	@Output() select: EventEmitter<BarsChartItem> = new EventEmitter<BarsChartItem>();

	items: Array<BarsChartItem> = [];

	private _resizeSubscription: Subscription;
	private _gradientVendorPrefix: string;
	private _isViewInit: boolean = false;

	constructor(
		private _elementRef: ElementRef,
		private _changeDetectionRef: ChangeDetectorRef,
		private prettyNumberService: PrettyNumberService
	) {}

	ngOnInit() {
		this._resizeSubscription = fromEvent(window, 'resize')
			.pipe(debounceTime(100))
			.subscribe(this.setBarsOuterValue.bind(this));
	}

	ngOnChanges(changes) {
		this.settings = merge({}, DEFAULT_SETTINGS, this.settings);

		this.setItems();
	}

	ngAfterViewInit() {
		this._isViewInit = true;
		this.setBarsOuterValue();
	}

	trackById(index, item: BarsChartItem) {
		return item.id || item.name;
	}

	private setItems() {
		if (!this.data) {
			this.items = [];
			return;
		}

		this.items = cloneDeep(this.data).sort((a, b) => {
			if (a.value === b.value) return 0;

			return a.value < b.value ? 1 : -1;
		});

		const minValue: number = !isNil(this.settings.min)
				? this.settings.min
				: this.items[this.items.length - 1][this.settings.valueProperty],
			maxValue: number = !isNil(this.settings.max)
				? this.settings.max
				: this.items[0][this.settings.valueProperty],
			largestSize: number = maxValue - minValue;

		this.items.forEach((item: BarsChartItem, itemIndex: number) => {
			item.value = item[this.settings.valueProperty];
			item.relativeSize = (item.value - minValue) / largestSize;
			item.valueDisplay = this.settings.formatValue
				? this.settings.formatValue(item.value)
				: this.prettyNumberService.prettyNumber(item.value);

			if (this.settings.getTooltip) item.tooltip = this.settings.getTooltip(item);

			if (this.settings.getItemNameTooltip)
				item.itemNameTooltip = this.settings.getItemNameTooltip(item);

			if (this.settings.groups) item.color = this.getMultiValuesBackground(item);
			else if (this.settings.getColor) item.color = this.settings.getColor(item);
			else if (this.settings.colorPattern)
				item.color = this.settings.colorPattern[itemIndex % (this.items.length - 1)];
			else item.color = this.settings.color;
		});

		if (this._isViewInit) this.setBarsOuterValue();
	}

	private setBarsOuterValue() {
		setTimeout(() => {
			const listWidth: number = this._elementRef.nativeElement.querySelector('ul').clientWidth;
			let isChanged: boolean = false;

			this.items.forEach((item: BarsChartItem) => {
				const wasOuterText: boolean = !!item.isOuterText;

				item.isOuterText = listWidth * item.relativeSize < item.name.length * 9;
				if (wasOuterText !== item.isOuterText) isChanged = true;
			});

			if (isChanged) this._changeDetectionRef.markForCheck();
		});
	}

	/**
	 * Returns a linear-gradient value for the background CSS property, which displays the bar in multiple colors, according
	 * to the groups of the bars.
	 * @param item
	 * @returns string linear-gradient value for the 'background' CSS property
	 */
	private getMultiValuesBackground(item: BarsChartItem) {
		const gradientValues: Array<string> = [],
			valueSum: number = this.settings.groups.reduce(
				(sum: number, group: BarsChartGroup) => sum + item.values[group.property],
				0
			);

		let lastPosition: number = 0;

		this.settings.groups.forEach((group: BarsChartGroup, groupIndex: number) => {
			const itemValue: number = item.values[group.property];
			if (itemValue) {
				gradientValues.push(group.color + (lastPosition ? ` ${lastPosition}%` : ''));

				if (groupIndex < this.settings.groups.length - 1 && itemValue) {
					lastPosition = lastPosition + (100 * item.values[group.property]) / valueSum;
					gradientValues.push(`${group.color} ${lastPosition}%`);
				}
			}
		});

		if (gradientValues.length === 1) return gradientValues[0];
		else {
			gradientValues.unshift('left');
			return `${this.getGradientVendorPrefix()}linear-gradient(${gradientValues.join(', ')})`;
		}
	}

	/**
	 * Returns the CSS vendor prefix to use for the linear-gradient CSS property.
	 * @returns string -webkit- / -moz-/ (none)
	 */
	private getGradientVendorPrefix(): string {
		if (isNil(this._gradientVendorPrefix)) {
			const divEl: HTMLDivElement = document.createElement('div');

			for (let i = 0; isNil(this._gradientVendorPrefix) && i < GRADIENT_VENDOR_PREFIXES.length; i++) {
				divEl.style.setProperty('background', GRADIENT_VENDOR_PREFIXES[i] + GRADIENT_TEST);
				if (divEl.style.background) this._gradientVendorPrefix = GRADIENT_VENDOR_PREFIXES[i];
			}
		}

		return this._gradientVendorPrefix;
	}
}

export interface BarsChartItem {
	color?: string;
	icon?: string;
	id: string;
	image?: string;
	isOuterText?: boolean;
	name: string;
	relativeSize?: number;
	tooltip?: string;
	itemNameTooltip?: string;
	value: number;
	values?: any;
	valueDisplay?: string;
}

export interface BarsChartSettings {
	color?: string;
	colorPattern?: Array<string>;
	formatValue?: (value: number) => string;
	getColor?: (item: BarsChartItem) => string;
	getTooltip?: (item: BarsChartItem) => string;
	getItemNameTooltip?: (item: BarsChartItem) => string;
	groups?: Array<BarsChartGroup>;
	isSelectable?: boolean;
	legend?: Array<BarsChartGroup>;
	max?: number;
	min?: number;
	objectType?: string;
	outerValues?: boolean;
	valueProperty?: string;
}

export interface BarsChartGroup {
	color: string;
	name: string;
	property?: string;
}
