import { ChangeDetectorRef, Component } from '@angular/core';
import { Mailbox, Incident } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { EntityDetailsMode } from '../../../global_entities/models/entity-details-mode.enum';

export type MailboxPanelOptions = {
	/**
	 * Related incident, if panel is inside incident page.
	 */
	incident?: Incident;

	serviceContext?: string;
};

@Component({
	selector: 'mailbox-entity-panel',
	templateUrl: './mailbox.entity-panel.component.html',
})
export class MailboxEntityPanelComponent extends EntityPanelComponentBase<Mailbox, MailboxPanelOptions> {
	readonly EntityDetailsMode = EntityDetailsMode;
	get mailbox(): Mailbox {
		return this.entity;
	}

	constructor(public changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}
}
