import { ComponentFactoryResolver, ComponentRef, Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NotificationConfig } from '../../notifications/models/notification.model';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { toObservable } from '../../utils/rxjs/utils';
import { ConfirmEvent } from '../confirm/confirm.event';
import { ConfirmationService } from '../confirm/confirm.service';
import { ErrorMessageOptions } from '../errors/error-message-options.interface';
import { ModalContainer } from '../modals/models/modal-container.model';
import { ModalService } from '../modals/services/modal.service';
import { ConfirmOptions } from '../models/confirm-options';
import {
	CustomConfirmComponent,
	CustomConfirmOptions,
	isCustomConfirmOptions,
} from '../models/custom-confirm-options';
import { PopupSettings } from '../models/popup-settings';
import { PanelContainer, PanelSettings, PanelService } from '@wcd/panels';
import { SnackBarConfig } from '../snackbars/models/snackbar.model';
import { SnackBarsService } from '../snackbars/services/snackbars.service';
import { ErrorsDialogService } from './errors-dialog.service';

@Injectable()
export class DialogsService {
	constructor(
		private readonly notificationsService: NotificationsService,
		private readonly errorsService: ErrorsDialogService,
		private readonly snackbarsService: SnackBarsService,
		private readonly confirmService: ConfirmationService,
		private readonly modalsService: ModalService,
		private readonly panelsService: PanelService
	) {}

	/**
	 * Show a **simple** confirm dialog.
	 */
	confirm<TPayload extends Record<string, string> = Record<string, string>>(
		options: ConfirmOptions
	): Promise<ConfirmEvent<TPayload>>;
	/**
	 * Show an **advanced** confirm dialog (custom content).
	 *
	 * @template TPayload The payload that the component returns on confirm.
	 * @template TComponent The type of the modal component.
	 * @template TInputs The inputs to pass to the components. **All should be `@Input`s in `TComponent`**.
	 * @template TConfirmActionResult _Optional_ The result of the `onConfirm` callback.
	 */
	confirm<
		TPayload extends object,
		TComponent extends CustomConfirmComponent<TPayload>,
		TInputs extends Partial<TComponent> = Partial<TComponent>,
		TConfirmActionResult = never
	>(
		options: CustomConfirmOptions<TPayload, TComponent, TInputs, TConfirmActionResult>
	): Promise<ConfirmEvent<TConfirmActionResult>>;
	confirm<
		TPayload extends object | Record<string, string>,
		TComponent extends CustomConfirmComponent<TPayload>,
		TInputs extends Partial<TComponent> = Partial<TComponent>,
		TConfirmActionResult = never
	>(options: ConfirmOptions | CustomConfirmOptions<TPayload, TComponent, TInputs, TConfirmActionResult>) {
		if (isCustomConfirmOptions(options)) {
			return this.showCustomConfirmModal(options);
		}

		return this.confirmService.confirm(options as ConfirmOptions);
	}

	closeAllModals() {
		this.modalsService.closeAllModals();
	}

	showError(options: ErrorMessageOptions) {
		return this.errorsService.showError(options);
	}

	showSuccessSnackbar(snackbarConfig: SnackBarConfig) {
		const defaultConfig = {
			icon: 'checkCircle',
			iconClassName: 'color-text-success-dark',
			className: 'color-box-successBackground',
		};
		this.snackbarsService.add({
			...defaultConfig,
			...snackbarConfig,
		});
	}

	showSnackbar(snackbarConfig: SnackBarConfig) {
		this.snackbarsService.add(snackbarConfig);
	}

	setNotification(notificationId: string, notificationConfig: NotificationConfig) {
		this.notificationsService.setNotification(notificationId, notificationConfig);
	}

	showModal<TComponent extends ModalContainer>(
		component: Type<TComponent>,
		modalSettings?: PopupSettings,
		parameters?: Partial<TComponent>,
		componentFactoryResolver?: ComponentFactoryResolver
	): Observable<ComponentRef<TComponent>> {
		return this.modalsService.create(component, modalSettings, parameters, componentFactoryResolver);
	}

	showPanel<T extends PanelContainer>(
		component: Type<T>,
		panelSettings?: PanelSettings,
		inputs?: Object,
		componentFactoryResolver?: ComponentFactoryResolver
	): Observable<ComponentRef<T>> {
		return this.panelsService.create(component, panelSettings, inputs, componentFactoryResolver);
	}

	private async showCustomConfirmModal<
		TPayload extends object,
		TComponent extends CustomConfirmComponent<TPayload>,
		TInputs extends Partial<TComponent> = Partial<TComponent>,
		TConfirmActionResult = never
	>(
		options: CustomConfirmOptions<TPayload, TComponent, TInputs, TConfirmActionResult>
	): Promise<ConfirmEvent<TConfirmActionResult>> {
		const { instance: dialogInstance } = await this.showModal<TComponent>(
			options.componentType,
			{},
			options.inputs,
			options.componentFactoryResolver
		).toPromise();

		const closeResult = await Promise.race([
			dialogInstance.onCancel.toPromise(),
			dialogInstance.onConfirm.toPromise(),
		]);

		if (closeResult) {
			dialogInstance.isSaving = true;

			const confirmActionResult$ = toObservable(options.onConfirm(closeResult)).pipe(
				map<TConfirmActionResult, ConfirmEvent<TConfirmActionResult>>(actionResult => ({
					confirmed: true,
					data: actionResult,
				})),
				tap(
					() => {},
					() => {
						// On error - don't close the modal - just change the saving status back to false
						dialogInstance.isSaving = false;
					},
					() => {
						// On complete destroy the modal
						dialogInstance.destroy();
					}
				)
			);

			return confirmActionResult$.toPromise();
		}

		dialogInstance.destroy();

		return {
			confirmed: false,
		};
	}
}
