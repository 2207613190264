import { Input, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { MessageBarType } from 'office-ui-fabric-react';
import { I18nService} from '@wcd/i18n';
import { MtpPermission } from '@wcd/auth';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { MtpWorkload } from '@wcd/domain';
import { sccHostService } from '@wcd/scc-interface';

@Component({
	selector: 'incident-rbac-permission-message',
	template:`
		<div [class]="contentClass" *ngIf="showReadOnlyMessage">
			<fab-message-bar [messageBarType]="MessageBarType.info"
							 (onDismiss)="closeBar('showReadOnlyMessage')"
							 [dismissable]="true">
				{{'incident_rbac_permission_message_read_only' | i18n}}
			</fab-message-bar>
		</div>
		<div [class]="contentClass"
			*ngIf="showIncidentRbacMessage" >
			<fab-message-bar [messageBarType]="MessageBarType.info"
							 (onDismiss)="closeBar('showIncidentRbacMessage')"
							 [dismissable]="true">
				<div [innerHTML]="rbacMessage"></div>
			</fab-message-bar>
		</div>
		<div [class]="contentClass" *ngIf="showMTPAlertMessageBar">
			<fab-message-bar [messageBarType]="MessageBarType.info"
							 (onDismiss)="closeBar('showMTPAlertMessageBar')"
							 [dismissable]="true">
				<div [innerHTML]="i18nService.get('incident_MtpAlertsExistMessage',{ url: incidentUrl } )"></div>
			</fab-message-bar>
		</div>
	`
})
export class IncidentRbacPermissionMessageComponent implements OnInit {
	@Input() incident;
	@Input() contentClass: string;

	enableControllingRbacConstraint: boolean ;
	MessageBarType = MessageBarType;
	rbacMessage: string;
	showMTPAlertMessageBar: boolean = true;
	showReadOnlyMessage: boolean = false;
	showIncidentRbacMessage: boolean = false;
	incidentUrl: string;

	constructor(private featureService: FeaturesService,
				public i18nService: I18nService,
				private changeDetection: ChangeDetectorRef,
				private rbacControlService: RbacControlService,
				private appContextService: AppContextService,
				) {
		this.enableControllingRbacConstraint = featureService.isEnabled(Feature.EnableControllingRbacConstraint);
		this.rbacMessage = i18nService.get(this.enableControllingRbacConstraint ? 'incident_rbac_permission_message_with_controllingrbacconstraint' : 'incident_rbac_permission_message')
	}

	ngOnInit() {
		this.showMTPAlertMessageBar = this.incident && this.incident.mtpAlertsExist && !this.appContextService.isMtp;
		this.incidentUrl = `/incidents/${this.incident.id}`;

		const rbacSettings = {
			mtpPermissions: [MtpPermission.SecurityData_Manage],
			mtpWorkloads: this.incident.mtpWorkloads,
			requireAllPermissions: true,
			ignoreNonActiveWorkloads: true,
		};
		const hasManagePermission = this.rbacControlService.hasRequiredRbacPermissions(rbacSettings)
		const onlyMDTAPWorkload = this.incident.mtpWorkloads.length === 1 && this.incident.mtpWorkloads[0] === MtpWorkload.Mdatp

		if (this.incident.alertCount > 0) {
			if (sccHostService.isSCC) {
				this.showReadOnlyMessage = !hasManagePermission
				this.showIncidentRbacMessage = !this.incident.isFullyMachineRbacExposed
			} else {
				this.showIncidentRbacMessage = !this.incident.isFullyMachineRbacExposed &&
					((onlyMDTAPWorkload && hasManagePermission) || (!onlyMDTAPWorkload && !hasManagePermission))
			}
		}
	}

	closeBar(which) {
		this[which] = false;
		this.changeDetection.detectChanges();
	}

}
