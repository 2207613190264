export type KustoFieldType =
	| 'Byte'
	| 'Boolean'
	| 'DateTime'
	| 'Double'
	| 'Guid'
	| 'UInt16'
	| 'UInt32'
	| 'UInt64'
	| 'Single'
	| 'Decimal'
	| 'Dynamic'
	| 'Int16'
	| 'Int32'
	| 'Int64'
	| 'Object'
	| 'SByte'
	| 'String'
	| 'TimeSpan';

export type FieldName = string;

export type RawResultSetItem = Record<FieldName, ResultValueType>;

export type EntityTypeName =
	| 'Machine'
	| 'File'
	| 'User'
	| 'Ip'
	| 'Url'
	| 'Alert'
	| 'UserSid'
	| 'UserObjectId';

// TODO: Is 'boolean' really applicable here?
export type ResultValueType = string | number | boolean | null;

export type ResultContentType = 'Table' | 'Chart' | 'None';

export enum KustoChartType {
	None = 'None',
	Table = 'Table',
	PieChart = 'PieChart',
	DonutChart = 'DonutChart',
	BarChart = 'BarChart',
	ColumnChart = 'ColumnChart',
	StackedColumnChart = 'StackedColumnChart',
	LineChart = 'LineChart',
	TimeLineChart = 'TimeLineChart',
	TimeLineWithAnomalyChart = 'TimeLineWithAnomalyChart',
	TimeLadderChart = 'TimeLadderChart',
	PivotChart = 'PivotChart',
	ScatterChart = 'ScatterChart',
	AreaChart = 'AreaChart',
	StackedAreaChart = 'StackedAreaChart',
	TimePivot = 'TimePivot',
	ThreeDChart = 'ThreeDChart',
}
export enum EntityTypes {
	machine = 'Machine',
	file = 'File',
	user = 'User',
	ip = 'Ip',
	url = 'Url',
	alert = 'Alert',
	userSid = 'UserSid',
	userObjectId = 'UserObjectId',
}
