var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FeaturesService } from '@wcd/config';
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { breadcrumbsStateService } from '@wcd/shared';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../../../../projects/config/src/lib/services/features.service";
/**
 * A service with breadcrumbs helpers methods
 */
var BreadcrumbsService = /** @class */ (function () {
    function BreadcrumbsService(route, router, featuresService) {
        this.route = route;
        this.router = router;
        this.featuresService = featuresService;
        /**
         * Used by angularJs router in order to signal route change and showBreadcrumbs flag on given route.
         * Can be removed when angularJs router is removed. TODO: remove when angularJs router is removed
         * @type {Subject<boolean>}
         */
        this.legacyShow$ = new Subject();
    }
    /**
     * Gets the route (or route's child) that contains breadcrumbs config.
     * Returns null if no child route contains breadcrumbs config.
     * @param {ActivatedRouteSnapshot} route
     * @returns {ActivatedRouteSnapshot | null}
     */
    BreadcrumbsService.prototype.getRouteWithBreadcrumbsConfig = function (route) {
        var routeWithBreadcrumbsConfig = route;
        while (routeWithBreadcrumbsConfig && !routeWithBreadcrumbsConfig.data['breadcrumbsConfig']) {
            routeWithBreadcrumbsConfig = routeWithBreadcrumbsConfig.firstChild;
        }
        return routeWithBreadcrumbsConfig;
    };
    /**
     * Registers a listener on route events in order to update breadcrumbs list
     */
    BreadcrumbsService.prototype.register = function () {
        var _this = this;
        // listens to route change and updates breadcrumbs list
        this._routeSubscriptions = this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            var routeWithBreadcrumbsConfig = _this.getRouteWithBreadcrumbsConfig(_this.route.snapshot);
            if (!routeWithBreadcrumbsConfig)
                return;
            var breadcrumbs = routeWithBreadcrumbsConfig.data['breadcrumbs'];
            var breadcrumbsConfig = routeWithBreadcrumbsConfig.data['breadcrumbsConfig'];
            // reset breadcrumbs if not allowed by feature flighting
            if (_this.isBreadcrumbsAllowed(breadcrumbsConfig)) {
                breadcrumbsStateService.reset();
                return;
            }
            // reset list
            if (breadcrumbsConfig.resetListOnEnter)
                breadcrumbsStateService.reset();
            // add route 'parent' item
            if (breadcrumbsConfig.addParentWhenEmpty &&
                !breadcrumbsStateService.breadcrumbs$.value.length) {
                if (!breadcrumbs || breadcrumbs.length < 2)
                    console.warn('Add parent breadcrumb when list is empty is set, but breadcrumbs are missing.', breadcrumbs);
                else
                    breadcrumbsStateService.add(breadcrumbs[0]);
            }
            // add route breadcrumb item
            if (breadcrumbs && breadcrumbs.length) {
                var lastCrumb = breadcrumbs[breadcrumbs.length - 1];
                breadcrumbsStateService.add(__assign({}, lastCrumb, (lastCrumb.queryParams !== _this.route.snapshot.queryParams
                    ? { queryParams: _this.route.snapshot.queryParams }
                    : {})));
            }
        });
    };
    BreadcrumbsService.prototype.isBreadcrumbsAllowed = function (breadcrumbsConfig) {
        var _this = this;
        return (breadcrumbsConfig.features &&
            !breadcrumbsConfig.features.every(function (feature) { return _this.featuresService.isEnabled(feature); }));
    };
    BreadcrumbsService.prototype.ngOnDestroy = function () {
        this.legacyShow$.complete();
        this._routeSubscriptions && this._routeSubscriptions.unsubscribe();
    };
    BreadcrumbsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BreadcrumbsService_Factory() { return new BreadcrumbsService(i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.FeaturesService)); }, token: BreadcrumbsService, providedIn: "root" });
    return BreadcrumbsService;
}());
export { BreadcrumbsService };
