/* tslint:disable:template-click-events-have-key-events */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Notification } from '../models/notification.model';
import { NotificationsService } from '../services/notifications.service';

@Component({
	selector: 'notification',
	templateUrl: './notification.component.html',
})
export class NotificationComponent {
	@Input() notification: Notification;
	@Input() isMinimizable: boolean = false;
	@Output() select: EventEmitter<Notification> = new EventEmitter<Notification>();
	@Output() minimize: EventEmitter<Notification> = new EventEmitter<Notification>();
	@Output() snooze: EventEmitter<void> = new EventEmitter<void>();

	constructor(public notificationsService: NotificationsService) {}

	notificationClick($event) {
		if (!$event.target.closest('button') && this.notification.isSelectable)
			this.select.emit(this.notification);
	}

	minimizeNotification($event: MouseEvent) {
		$event.stopPropagation();

		this.notification.minimize();
		this.minimize.emit(this.notification);
	}

	snoozeAll() {
		this.snooze.emit();
	}
}
