var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RecommendationExceptionService } from './../../remediation/recommendation-exceptions/services/recommendation-exception.service';
import { FeaturesService, Feature } from '@wcd/config';
import { EventEmitter, ChangeDetectorRef, OnInit, } from '@angular/core';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { SecurityRecommendation, RemediationType, RecommendationException, ExceptionType, RecommendationType, ExceptionJustification, MdeUserRoleActionEnum, RecommendationToExceptionsRelationship, RecommendationExceptionStateValue, GlobalExceptionRbacGroupId, } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { CalculateRemainingDaysPipe } from '../../../../shared/pipes/calculate-remaining-days.pipe';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { AuthService } from '@wcd/auth';
import { MessageBarType, SpinnerSize } from 'office-ui-fabric-react';
import { AppConfigService } from '@wcd/app-config';
import { MessageBarStyles } from '../../common/styles';
import { RbacService } from '../../../../rbac/services/rbac.service';
import { TzDateService } from '@wcd/localization';
var RecommendationExceptionCreationComponent = /** @class */ (function (_super) {
    __extends(RecommendationExceptionCreationComponent, _super);
    function RecommendationExceptionCreationComponent(router, featuresService, rbacService, paris, i18nService, dialogsService, changeDetectionRef, calculateRemainingDaysPipe, tvmTextsService, authService, appConfigService, recommendationExceptionService, tzDateService) {
        var _this = _super.call(this, router) || this;
        _this.rbacService = rbacService;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.dialogsService = dialogsService;
        _this.changeDetectionRef = changeDetectionRef;
        _this.calculateRemainingDaysPipe = calculateRemainingDaysPipe;
        _this.tvmTextsService = tvmTextsService;
        _this.authService = authService;
        _this.appConfigService = appConfigService;
        _this.recommendationExceptionService = recommendationExceptionService;
        _this.tzDateService = tzDateService;
        _this._messageBarType = MessageBarType;
        _this.SpinnerSize = SpinnerSize;
        _this.messageBarStyles = MessageBarStyles;
        _this.shouldLinkToException = false;
        _this.minDate = moment()
            .add(1, 'days')
            .toDate();
        _this.maxDate = moment()
            .add(1, 'year')
            .toDate();
        _this.dateCustomFromNow = {
            id: 'custom',
            date: null,
        };
        _this.date30FromNow = {
            id: '30',
            date: moment()
                .add(30, 'days')
                .toDate(),
        };
        _this.date60FromNow = {
            id: '60',
            date: moment()
                .add(60, 'days')
                .toDate(),
        };
        _this.date90FromNow = {
            id: '90',
            date: moment()
                .add(90, 'days')
                .toDate(),
        };
        _this.datesList = [_this.date30FromNow, _this.date60FromNow, _this.date90FromNow, _this.dateCustomFromNow];
        _this.chosenDate = {
            id: '',
            date: null,
        };
        _this.globalExceptionCreationType = {
            id: 'global',
            name: _this.i18nService.get('tvm_securityRecommendation_recommendationExceptionCreation_exceptionCreationType_global'),
        };
        _this.byMachineGroupExceptionCreationType = {
            id: 'byMachineGroup',
            name: _this.i18nService.get('tvm_securityRecommendation_recommendationExceptionCreation_exceptionCreationType_byMachineGroup'),
        };
        _this.exceptionCreationTypes = [
            _this.globalExceptionCreationType,
            _this.byMachineGroupExceptionCreationType,
        ];
        _this.existingActiveExceptionsRbacGroupIds = [];
        _this.recommendationExceptionRouterLink = 'remediation/recommendation-exceptions';
        _this.done = new EventEmitter();
        _this.isUserAllowedToCreateExceptions = _this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.tvmExceptionHandling);
        _this.exceptionJustificationList = recommendationExceptionService.getCreationJustificationList();
        _this.isExceptionsPerRbacFeatureEnabled =
            appConfigService.hasMachineGroups && featuresService.isEnabled(Feature.TvmExceptionsPerRbac);
        _this.isGlobalExceptionCreationAllowed = _this.authService.currentUser.isMdeAdmin;
        _this._overrideSelectedExceptionCreationType = !_this.isGlobalExceptionCreationAllowed
            ? _this.byMachineGroupExceptionCreationType
            : null;
        _this.defaultWarningText =
            i18nService.strings.tvm_securityRecommendation_recommendationExceptionCreation_unknownError;
        _this.justificationNotificationText = _this.i18nService.strings.tvm_securityRecommendation_recommendationExceptionCreation_justificationScore;
        _this.exceptionDurationText = _this.i18nService.strings.tvm_securityRecommendation_recommendationExceptionCreation_expiringOn;
        return _this;
    }
    Object.defineProperty(RecommendationExceptionCreationComponent.prototype, "justificationContext", {
        get: function () {
            return this._justificationContext.get(this.exceptionJustification);
        },
        set: function (newJustificationContext) {
            this._justificationContext.set(this.exceptionJustification, newJustificationContext);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecommendationExceptionCreationComponent.prototype, "shouldDisabledMachineGroupsDropdown", {
        get: function () {
            return (this.shouldDisable ||
                this.allRbacGroupsHaveAnActiveException ||
                this.selectedExceptionCreationType !== this.byMachineGroupExceptionCreationType);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecommendationExceptionCreationComponent.prototype, "selectedExceptionCreationType", {
        get: function () {
            return this._overrideSelectedExceptionCreationType || this._selectedExceptionCreationType;
        },
        enumerable: true,
        configurable: true
    });
    RecommendationExceptionCreationComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.isExceptionsPerRbacFeatureEnabled) {
            this.recommendationExceptionCreationDescription = this.i18nService.strings.tvm_securityRecommendation_recommendationExceptionCreation_descriptionNew;
            this.setExceptionCreationParams();
        }
        else {
            this.recommendationExceptionCreationDescription = this.i18nService.strings.tvm_securityRecommendation_recommendationExceptionCreation_description;
            this.warningText = this.getWarningText();
            this.shouldDisable = this.shouldDisableForms();
        }
        this._justificationContext = new Map(this.exceptionJustificationList.map(function (key) { return [
            ExceptionJustification[key],
            '',
        ]; }));
        this.recommendationExceptionTitle = this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, this.securityRecommendation);
        // These functions are called from a child component and requires this component's context
        this.getJustificationLabel = this.getJustificationLabel.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.getTimeLabel = this.getTimeLabel.bind(this);
        this.exceptionLink = this.recommendationExceptionRouterLink + "/" + (this.securityRecommendation.remediationType === RemediationType.ConfigurationChange
            ? this.securityRecommendation.scId
            : this.securityRecommendation.productId +
                (this.isExceptionsPerRbacFeatureEnabled
                    ? "-_-" + this.securityRecommendation.remediationType
                    : ''));
    };
    RecommendationExceptionCreationComponent.prototype.setExceptionCreationParams = function () {
        var _this = this;
        var repository = this.paris.getRelationshipRepository(RecommendationToExceptionsRelationship);
        repository.sourceItem = this.securityRecommendation;
        zip(this.rbacService.userExposedRbacGroups$.pipe(map(function (res) { return res.map(function (machineGroup) { return machineGroup.id; }); })), repository.query().pipe(map(function (res) { return res.items; }))).subscribe(function (_a) {
            var userExposedRbacGroupIds = _a[0], existingRecommendationExceptions = _a[1];
            _this.userExposedRbacGroupIds = userExposedRbacGroupIds;
            _this.existingRecommendationExceptions = existingRecommendationExceptions;
            _this.existingActiveGlobalException = _this.existingRecommendationExceptions.filter(function (ex) {
                return _this.isExceptionMatchingRecommendation(ex) &&
                    ex.status.value === RecommendationExceptionStateValue.Active &&
                    _this.isGlobalException(ex);
            })[0];
            _this.existingActiveExceptionsRbacGroupIds = _this.existingRecommendationExceptions
                .filter(function (exception) {
                return _this.isExceptionMatchingRecommendation(exception) &&
                    exception.status.value === RecommendationExceptionStateValue.Active &&
                    !_this.isGlobalException(exception);
            })
                .map(function (exception) { return exception.rbacGroupId; });
            var existingActiveExceptionsRbacGroupIdsSet = new Set(_this.existingActiveExceptionsRbacGroupIds);
            _this.allRbacGroupsHaveAnActiveException = _this.userExposedRbacGroupIds.every(function (rbacGroupId) {
                return existingActiveExceptionsRbacGroupIdsSet.has(rbacGroupId);
            });
            _this.setDefaultCreationType();
            _this.warningText = _this.getWarningText();
            _this.shouldDisable = _this.shouldDisableForms();
            _this.changeDetectionRef.markForCheck();
        });
    };
    RecommendationExceptionCreationComponent.prototype.getJustificationLabel = function (justification) {
        return this.recommendationExceptionService.getJustificationLabel(justification);
    };
    RecommendationExceptionCreationComponent.prototype.getTimeLabel = function (data) {
        return this.i18nService.get("tvm.securityRecommendation.recommendationExceptionCreation.times." + data.id);
    };
    RecommendationExceptionCreationComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this._savingSubscription && this._savingSubscription.unsubscribe();
    };
    RecommendationExceptionCreationComponent.prototype.machineGroupSelected = function (machineGroups) {
        this.machineGroups = machineGroups;
    };
    RecommendationExceptionCreationComponent.prototype.submitRequest = function () {
        var _this = this;
        this.isSaving = true;
        var newExceptions = this.createRecommendationExceptions();
        var repository = this.paris.getRepository(RecommendationException);
        var saveObservable$ = Array.isArray(newExceptions)
            ? repository.saveItems(newExceptions).pipe(map(function (exceptions) { return exceptions[0]; }))
            : repository.save(newExceptions);
        this._savingSubscription = saveObservable$.subscribe(function (recommendationException) {
            _this.dialogsService.showSnackbar({
                text: _this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.pleaseWait'),
            });
            _this.done.emit(recommendationException);
        }, function (err) {
            if (err.status === 409) {
                _this.dialogsService.showError({
                    title: _this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.creationAlreadyExistFailTitle'),
                    data: _this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.creationAlreadyExistFailMessage'),
                });
                _this.done.emit();
                return;
            }
            _this.dialogsService.showError({
                title: _this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.creationGeneralFailTitle'),
                data: _this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.creationGeneralFailMessage'),
            });
            _this.isSaving = false;
            _this.changeDetectionRef.markForCheck();
        });
    };
    RecommendationExceptionCreationComponent.prototype.onSelectDate = function (date) {
        if (date && date !== this.chosenDate.date) {
            this.chosenDate.date = date;
        }
    };
    RecommendationExceptionCreationComponent.prototype.formatDate = function (date) {
        if (date) {
            return this.tzDateService.format(date, 'shortDate') + " (" + this.calculateRemainingDaysPipe.transform(date) + " days)";
        }
        return '';
    };
    RecommendationExceptionCreationComponent.prototype.setJustificationContext = function () {
        var _this = this;
        var context = this.justificationContext !== ''
            ? this.justificationContext
            : this.i18nService.get("tvm.securityRecommendation.recommendationExceptionCreation.justificationContext." + this.exceptionJustification);
        this.justificationContext = context !== ' ' ? context : '';
        this.justificationNote = null; // to hide the info notification and show again to read the alert by the narrator on each selection
        this.changeDetectionRef.markForCheck();
        setTimeout(function () {
            _this.justificationNote =
                _this.exceptionJustification === ExceptionJustification.ThirdPartyControl ||
                    _this.exceptionJustification === ExceptionJustification.AlternateMitigation
                    ? _this.justificationNotificationText
                    : undefined;
            _this.changeDetectionRef.markForCheck();
        });
    };
    RecommendationExceptionCreationComponent.prototype.shouldDisableForms = function () {
        if (!this.isExceptionsPerRbacFeatureEnabled) {
            return (this.securityRecommendation.status === 'Exception' ||
                !this.isUserAllowedToCreateExceptions ||
                !this.appConfigService.isExposedToAllMachineGroups);
        }
        if (this.selectedExceptionCreationType === this.globalExceptionCreationType) {
            return !(this.existingActiveGlobalException === null ||
                this.existingActiveGlobalException === undefined);
        }
        else if (this.selectedExceptionCreationType === this.byMachineGroupExceptionCreationType) {
            return this.allRbacGroupsHaveAnActiveException;
        }
        else {
            return !this.isUserAllowedToCreateExceptions;
        }
    };
    RecommendationExceptionCreationComponent.prototype.getWarningText = function () {
        var textArray = [];
        if (!this.isUserAllowedToCreateExceptions) {
            textArray.push(this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.notAllowed'));
        }
        if (!this.isExceptionsPerRbacFeatureEnabled && !this.appConfigService.isExposedToAllMachineGroups) {
            textArray.push(this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.notExposedToAllRbac'));
        }
        if (this.isExceptionsPerRbacFeatureEnabled) {
            if (this.existingActiveGlobalException) {
                textArray.push(this.i18nService.get('tvm_securityRecommendation_recommendationExceptionCreation_alreadyUnderGlobalException', {
                    expirationDate: this.tzDateService.format(this.existingActiveGlobalException.expiringOn, 'shortDate'),
                }));
                this.exceptionLink = this.recommendationExceptionRouterLink + "/" + this.existingActiveGlobalException.id;
                this.shouldLinkToException = true;
            }
            if (this.allRbacGroupsHaveAnActiveException) {
                textArray.push(this.i18nService.strings
                    .tvm_securityRecommendation_recommendationExceptionCreation_alreadyUnderPerRbacException);
                this.shouldLinkToException = true;
            }
        }
        else {
            if (this.securityRecommendation.status === 'Exception') {
                textArray.push(this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.alreadyUnderException'));
                this.shouldLinkToException = true;
            }
        }
        return textArray.join('\n\n');
    };
    RecommendationExceptionCreationComponent.prototype.createRecommendationExceptions = function () {
        var _this = this;
        if (!this.isExceptionsPerRbacFeatureEnabled) {
            return this.createExceptionWithRbacGroup(null);
        }
        if (this.selectedExceptionCreationType === this.globalExceptionCreationType) {
            return [this.createExceptionWithRbacGroup(GlobalExceptionRbacGroupId)];
        }
        else if (this.selectedExceptionCreationType === this.byMachineGroupExceptionCreationType) {
            return this.machineGroups.map(function (machineGroup) { return _this.createExceptionWithRbacGroup(machineGroup.id); });
        }
        else {
            throw new Error('No exception creation type selected');
        }
    };
    RecommendationExceptionCreationComponent.prototype.createExceptionWithRbacGroup = function (rbacGroupId) {
        return {
            id: null,
            title: this.recommendationExceptionTitle,
            exceptionJustification: this.exceptionJustification,
            justificationContext: this.justificationContext,
            expiringOn: this.chosenDate.date,
            exceptionArgs: this.createExceptionArgs(),
            requester: null,
            rbacGroupId: rbacGroupId,
        };
    };
    RecommendationExceptionCreationComponent.prototype.createExceptionArgs = function () {
        return this.securityRecommendation.scId
            ? {
                type: ExceptionType.ConfigurationChange,
                scaRecommendationArgs: {
                    scid: this.securityRecommendation.scId,
                },
            }
            : {
                type: ExceptionType.SoftwarePatch,
                vaRecommendationArgs: {
                    recommendationType: RecommendationType[RemediationType[this.securityRecommendation.remediationType]],
                    productId: this.securityRecommendation.productId,
                },
            };
    };
    RecommendationExceptionCreationComponent.prototype.isGlobalException = function (exception) {
        return (exception.rbacGroupId === null ||
            exception.rbacGroupId === undefined ||
            exception.rbacGroupId === GlobalExceptionRbacGroupId);
    };
    RecommendationExceptionCreationComponent.prototype.isExceptionMatchingRecommendation = function (exception) {
        switch (exception.exceptionArgs.type) {
            case ExceptionType.SoftwarePatch:
                return (exception.exceptionArgs.vaRecommendationArgs.productId ===
                    this.securityRecommendation.productId &&
                    exception.exceptionArgs.vaRecommendationArgs.recommendationType ===
                        this.securityRecommendation.remediationType);
            case ExceptionType.ConfigurationChange:
                return (exception.exceptionArgs.scaRecommendationArgs.scid === this.securityRecommendation.scId);
        }
    };
    RecommendationExceptionCreationComponent.prototype.setDefaultCreationType = function () {
        if (this.existingActiveGlobalException) {
            this.globalExceptionCreationType.disabled = true;
            this._selectedExceptionCreationType = this.byMachineGroupExceptionCreationType;
        }
        if (this.allRbacGroupsHaveAnActiveException) {
            this.byMachineGroupExceptionCreationType.disabled = true;
            // If both creation types are disabled, don't select any by default
            this._selectedExceptionCreationType = this.globalExceptionCreationType.disabled
                ? null
                : this.globalExceptionCreationType;
        }
    };
    return RecommendationExceptionCreationComponent;
}(PanelContainer));
export { RecommendationExceptionCreationComponent };
