import { EntityRelationship, EntityRelationshipRepositoryType } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { Alert } from '../alert.entity';
import { AlertTimeline } from './alert-timeline.value-object';

@EntityRelationship({
	sourceEntity: Alert,
	dataEntity: AlertTimeline,
	cache: {
		time: 1000 * 60, // 1 minute
		max: 10,
	},
	getRelationshipData: (alert: Alert) => ({
		id: alert.id,
	}),
	endpoint: 'AlertTimeline',
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class AlertAlertTimelineRelationship
	implements EntityRelationshipRepositoryType<Alert, AlertTimeline> {}
