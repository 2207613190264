var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/**
 * Service for opening React tabs from Angular.
 * Under the hood it works using React.Portals (https://reactjs.org/docs/portals.html) and react-reverse-portal lib (https://github.com/httptoolkit/react-reverse-portal).
 * For rendering implementation see react-portal-tab-wrapper.tsx.
 * For usage example see app-bootstrap.scc.tsx / incident-overview-tab.component.ts.
 */
var ReactTabsService = /** @class */ (function () {
    function ReactTabsService() {
        var _this = this;
        /**
        * Renders a react tab.
        * The callback "onTabChange" is responsible for passing the component details into the ReactPortalTabWrapper.
         */
        this.renderTab = function (tab) {
            return new Promise(function (resolve) {
                _this.onTabChange(tab, function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!tab) return [3 /*break*/, 2];
                                // Poll until we make sure that the OutPortal is populated, since setState and OutPortal's are logic are async.
                                return [4 /*yield*/, poll(isReactPortalNodePopulated)];
                            case 1:
                                // Poll until we make sure that the OutPortal is populated, since setState and OutPortal's are logic are async.
                                _a.sent();
                                _a.label = 2;
                            case 2:
                                resolve();
                                return [2 /*return*/];
                        }
                    });
                }); });
            });
        };
    }
    /**
     * Register a callback for changes in the currently open tab.
     * @param onTabChange callback to call when the currently open tab changes. See ReactPortalTabWrapper.
     */
    ReactTabsService.prototype.setOnTabChange = function (onTabChange) {
        this.onTabChange = onTabChange;
    };
    return ReactTabsService;
}());
export var reactTabsService = new ReactTabsService();
function poll(fn, retries, interval) {
    if (retries === void 0) { retries = 10; }
    if (interval === void 0) { interval = 200; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(!fn() && retries--)) return [3 /*break*/, 2];
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, interval); })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 0];
                case 2: return [2 /*return*/];
            }
        });
    });
}
export function isReactPortalNodePopulated() {
    return window.mdatp && window.mdatp.reactTabPortalNode && window.mdatp.reactTabPortalNode.props.node.element.children.length > 0;
}
