import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { getTheme, IPalette } from '@uifabric/styling';
import { PrettyNumberService } from '@wcd/prettify';
import { maxBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertsByDetectionSource, AlertsByDetectionSourceApiCall } from '@wcd/domain';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { WidgetType } from '../../../../components/report-with-filters.component';
import { SerializedFilters, FiltersState } from '@wcd/ng-filters';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { I18nService } from '@wcd/i18n';
import { AppInsightsService } from '../../../../../insights/services/app-insights.service';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { ChartSettings } from '@wcd/charts';
import { TrackingEventType } from '../../../../../insights/models/tracking-event-type.enum';
import { Paris } from '@microsoft/paris';
import { TimeRangeId } from '@wcd/date-time-picker';

@Component({
	template: `
		<wcd-stacked-bars-chart [data]="data$ | async" [settings]="chartSettings$ | async">
		</wcd-stacked-bars-chart>
	`,
})
export class AlertsByDetectionSourceWidget extends ReportWidgetComponent<AlertsByDetectionSource> {
	protected readonly widgetType = WidgetType.Daily;

	protected currentDataDate: Date;
	protected currentFilters: SerializedFilters;
	protected readonly today = new Date();
	protected readonly colorPalette: IPalette = getTheme().palette;

	protected readonly detectionSrcIndexToName: Map<number, string> = new Map([
		[0, 'MTP'],
		[1, 'WindowsDefenderAtp'],
		[2, 'WindowsDefenderAv'],
		[3, 'WindowsDefenderSmartScreen'],
		[4, 'CustomerTI'],
		[5, 'OfficeATP'],
		[6, 'AutomatedInvestigation'],
		[7, 'ThreatExperts'],
		[8, 'CustomDetection'],
		[9, 'ThirdPartySensors'],
	]);

	readonly titles = new Map(
		[
			'all',
			'mtp',
			'edr',
			'antivirus',
			'smartScreen',
			'customTi',
			'officeAtp',
			'automatedInvestigation',
			'threatExperts',
			'customDetection',
			'thirdPartySensors',
		].map(
			(title) =>
				[title, this.i18nService.get(`reporting.alertsByDetectionSource.${title}`)] as [
					string,
					string
				]
		)
	);

	constructor(
		reportsService: ReportsService,
		protected i18nService: I18nService,
		protected prettyNumberService: PrettyNumberService,
		protected router: Router,
		protected appInsightsService: AppInsightsService,
		protected paris: Paris
	) {
		super(reportsService);
	}

	get widgetConfig(): ReportWidgetConfig<AlertsByDetectionSource> {
		return this.getWidgetConfig();
	}

	readonly chartSettings$: Observable<ChartSettings> = this.data$.pipe(
		map((data) => data && this.getSettings(data))
	);

	loadData(params: {}) {
		return this.paris
			.apiCall(AlertsByDetectionSourceApiCall, params)
			.pipe(map((dataset) => dataset.items[0]));
	}

	navigateToAlertsPage = (detectionSrcIndex?: number) => {
		const parsedFilters =
			detectionSrcIndex + 1
				? `detectionSource=${this.detectionSrcIndexToName.get(detectionSrcIndex)}`
				: '';

		this.appInsightsService.trackEvent('UI', {
			type: TrackingEventType.Navigation,
			id: 'GoToAlertsQueue',
			component: 'Dashboard',
			widget: 'AlertsByDetectionSource',
			column: this.detectionSrcIndexToName.get(detectionSrcIndex),
		});
		this.router.navigate(['/alertsQueue'], {
			queryParams: { range: TimeRangeId['6months'], filters: parsedFilters },
		});
	};

	protected getWidgetConfig(): ReportWidgetConfig<AlertsByDetectionSource> {
		return {
			id: 'alertsByDetectionSourceDaily',
			name: this.i18nService.get('reporting.alertsByDetectionSource.dailyTitle'),
			getDateOfWidget: () => {
				return this.currentDataDate;
			},
			loadData: (filtersState: FiltersState) => {
				const serializedFilters: SerializedFilters = this.reportsService.fixDataIfNeeded(
					filtersState,
					this.widgetType
				);
				this.currentFilters = serializedFilters;

				this.currentDataDate = this.reportsService.calcCurrentDataDateForDaily(
					serializedFilters,
					this.today
				);

				return this.loadData(serializedFilters);
			},
		};
	}

	private getSettings(data?: AlertsByDetectionSource): ChartSettings {
		const maxCount: number = data && data.alertsCount && maxBy(Object.values(data.alertsCount));

		return {
			options: {
				data: {
					columns: [
						[
							this.titles.get('all'),
							data.alertsCount.mtp,
							data.alertsCount.edr,
							data.alertsCount.antivirus,
							data.alertsCount.smartScreen,
							data.alertsCount.customTi,
							data.alertsCount.officeAtp,
							data.alertsCount.automatedInvestigation,
							data.alertsCount.threatExperts,
							data.alertsCount.customDetection,
							data.alertsCount.thirdPartySensors,
						],
					],
					groups: [[this.titles.get('all')]],
					colors: {
						[this.titles.get('all')]: this.colorPalette.tealLight,
					},
					labels: {
						format: (value) => (maxCount > 0 ? this.prettyNumberService.prettyNumber(value) : ''), //c3 labels issue when all labels are zero, the labels are at a wrong position
					},
					onclick: (e) => this.navigateToAlertsPage(e.index),
				},
				tooltip: {
					grouped: false,
					contents: (data) =>
						`<table class="c3-tooltip">
							<tbody>
								<tr>
									<td class="name">${data[0].value}</td>
								</tr>
							</tbody>
						</table>`,
				},
				bar: {
					width: {
						max: 50,
						ratio: 0.1,
					},
				},
				axis: {
					y: {
						max: maxCount ? maxCount : 0,
						min: 0,
						tick: {
							values: data ? ReportsService.getYTicks(maxCount) : [],
							format: (value) => this.prettyNumberService.prettyNumber(value),
						},
						padding: {
							bottom: 0,
						},
					},
					x: {
						type: 'category',
						categories: [
							this.titles.get('mtp'),
							this.titles.get('edr'),
							this.titles.get('antivirus'),
							this.titles.get('smartScreen'),
							this.titles.get('customTi'),
							this.titles.get('officeAtp'),
							this.titles.get('automatedInvestigation'),
							this.titles.get('threatExperts'),
							this.titles.get('customDetection'),
							this.titles.get('thirdPartySensors'),
						],
						tick: {
							multiline: false,
							culling: {
								max: 1,
							},
							rotate: 20,
						},
						padding: { right: 0.7 },
						height: 80,
					},
				},
				grid: {
					y: {
						show: true,
					},
				},
				legend: {
					show: false,
				},
				size: {
					height: 200,
				},
			},
		};
	}
}
