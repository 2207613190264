
		<div class="dropdown-directive" [attr.aria-label]="ariaLabel">
			<button
				type="button"
				[attr.id]="buttonId"
				(click)="toggle()"
				[wcdTooltip]="buttonTooltip"
				class="dropdown-directive-button"
				[class.dropdown-directive-button-arrow]="!buttonIcon || showIconDropdown"
				[ngClass]="buttonClass"
				(focus)="focus.emit()"
				(blur)="blur.emit()"
				[attr.aria-label]="ariaLabel"
				[disabled]="disabled"
				[attr.role]="buttonRole ? buttonRole : null"
			>
				<img class="dropdown-directive-button-image" *ngIf="buttonImage" [attr.src]="buttonImage" />
				<wcd-shared-icon
					[iconName]="buttonIcon"
					*ngIf="buttonIcon"
					class="dropdown-directive-button-icon"
					[ngClass]="buttonIconClass"
				>
				</wcd-shared-icon>
				<span class="dropdown-directive-label">{{ buttonText }}</span>
				<wcd-shared-icon class="dropdown-icon" *ngIf="showIconDropdown" [iconName]="'carets.down'">
				</wcd-shared-icon>
				<ng-content select="[button-content]"></ng-content>
			</button>
			<span [ngClass]="'dropdown-directive-button-badge ' + buttonBadgeClass" *ngIf="buttonBadge">{{
				buttonBadge
			}}</span>
			<div
				class="dropdown-directive-menu"
				[ngClass]="menuClass"
				[cdkTrapFocus]="isOpen"
				tabindex="-1"
				(keydown.escape)="close()"
			>
				<ng-content></ng-content>
			</div>
		</div>
	