import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityTypeConfig } from './airs-entity-type-config.entity';
import { AirsEntityReport } from './airs-entity-report.value-object';
import { AirsEntityStatus } from './airs-entity-status.entity';

@ValueObject({
	singularName: 'Related Entity Report',
	pluralName: 'Related Entity Reports',
	readonly: true,
})
export class AirsRelatedEntityReport extends ModelBase {
	@EntityField({ data: 'entity_id' })
	entityId?: number;

	@EntityField({ data: 'name' })
	entityName: string;

	@EntityField({ data: 'type' })
	entityType: AirsEntityTypeConfig;

	@EntityField({ data: 'relation_name' })
	relationName?: string;

	@EntityField({ data: 'entity_status' })
	status?: AirsEntityStatus;

	@EntityField() report: AirsEntityReport;
}
