var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { WizardBaseStep } from '@wcd/wizard';
import { LAB_CONFIGURATION } from '@wcd/domain';
var LabConfigurationStepComponent = /** @class */ (function (_super) {
    __extends(LabConfigurationStepComponent, _super);
    function LabConfigurationStepComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.MessageBarType = MessageBarType;
        _this.LAB_CONFIGURATION = LAB_CONFIGURATION;
        return _this;
    }
    LabConfigurationStepComponent.prototype.ngOnInit = function () {
        this.setStepValidation(true);
    };
    return LabConfigurationStepComponent;
}(WizardBaseStep));
export { LabConfigurationStepComponent };
