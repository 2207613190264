import { FlavorConfig } from '@wcd/scc-common';

export interface IAlertsQueueConfig {
	extendedFilterAndSubFilterOptions: FlavorConfig;
}

export const AlertsQueueConfig: IAlertsQueueConfig = {
	extendedFilterAndSubFilterOptions: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
};
