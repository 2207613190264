// TODO(khaw): Remove as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'

import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { NetworkDevice } from '@wcd/domain';
import { Paris } from '@microsoft/paris';

@Component({
    selector: 'network-device-entity-panel',
    templateUrl: './network-device.entity-panel.components.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkDeviceEntityPanelComponent extends EntityPanelComponentBase<NetworkDevice> {
    get networkDevice(): NetworkDevice {
        return this.entity;
    }

    constructor(changeDetectorRef: ChangeDetectorRef, paris: Paris) {
        super(changeDetectorRef);
    }

    initEntity(device: NetworkDevice, isExtendedData: boolean = false) {
        super.initEntity(device, isExtendedData);
        this.changeDetectorRef.markForCheck();
    }
}