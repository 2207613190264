/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "@angular/common";
import * as i5 from "./generic-object.component";
import * as i6 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_GenericObjectComponent = [];
var RenderType_GenericObjectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GenericObjectComponent, data: {} });
export { RenderType_GenericObjectComponent as RenderType_GenericObjectComponent };
function View_GenericObjectComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "details-card"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), i0.ɵncd(null, 0), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, null); }
export function View_GenericObjectComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 8, "button", [["class", "btn-link wcd-margin-none-all wcd-padding-none-all"], ["data-track-id", "GenericObjectDetailsToggle"], ["data-track-type", "Toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.visible = !_co.visible) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "wcd-shared-icon", [["class", "small-icon"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(4, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["aria-live", "polite"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["\n\t\t\t\t", "\n\t\t\t\t", "\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericObjectComponent_1)), i0.ɵdid(12, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible ? "visibility.off" : "visibility.on"); _ck(_v, 4, 0, currVal_0); var currVal_3 = _co.visible; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.visible ? _co.i18nService.strings.generic_object_details_hide : _co.i18nService.strings.generic_object_details_show); var currVal_2 = _co.fieldName; _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_GenericObjectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "generic-object", [], null, null, null, View_GenericObjectComponent_0, RenderType_GenericObjectComponent)), i0.ɵdid(1, 49152, null, 0, i5.GenericObjectComponent, [i6.I18nService], null, null)], null, null); }
var GenericObjectComponentNgFactory = i0.ɵccf("generic-object", i5.GenericObjectComponent, View_GenericObjectComponent_Host_0, { visible: "visible", fieldName: "fieldName" }, {}, ["*"]);
export { GenericObjectComponentNgFactory as GenericObjectComponentNgFactory };
