import {NgModuleRef} from '@angular/core';
import * as React from 'react';
import {AppSCCModule} from '../app/app.scc.module';
import {ProgressIndicator} from 'office-ui-fabric-react';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {sccHostService} from "@wcd/scc-interface";
import { panelService, PanelConfig, reactTabsService, ReactTabConfig } from '@wcd/shared';
import { AngularWrapperPanels } from './angular-wrapper-panels';
import { ReactPortalWrapper } from './react-portal-tab-wrapper';

class WcdAppReactWrapper {
	private bootModuleRef: NgModuleRef<AppSCCModule> | null = null;
	private bootModuleRefPromise: Promise<void | NgModuleRef<AppSCCModule>> | null = null;


	bootSCC(): Promise<void | NgModuleRef<AppSCCModule>> {
		if (!this.bootModuleRefPromise) {
			this.bootModuleRefPromise = platformBrowserDynamic().bootstrapModule(AppSCCModule, {preserveWhitespaces: true})
				.then(m => {
					this.bootModuleRef = m;
					return m;
				})
				.catch(err => this.destroyComponent())
		}

		return this.bootModuleRefPromise;
	}

	destroyComponent() {
		if (this.bootModuleRef) {
			this.bootModuleRef.destroy();
			this.bootModuleRef = null;
		}
		this.bootModuleRefPromise = null;
	}
}

const wcdAppReactWrapper = new WcdAppReactWrapper();

interface IAngularWrapperProps {
    style?: React.CSSProperties;
    className?: string;
    input?: any;
}

interface IAngularWrapperState {
	loading: boolean;
	panels: PanelConfig[];
	tab?: ReactTabConfig;
}

class AngularWrapper extends React.Component<IAngularWrapperProps, IAngularWrapperState> {
    private element = React.createRef<HTMLDivElement>();
	state = {
		loading: true,
		panels: [],
		tab: null
	};

	constructor(props) {
		super(props);
		panelService.setOnPanelsChange((panels) => this.setState({panels}));
		reactTabsService.setOnTabChange((tab, onTabStateChange) => this.setState({tab}, () => { onTabStateChange(); }));
	}

	render() {
		return (
			<div ref={this.element} style={this.props.style} className={this.props.className}>
				{
					this.state.loading &&
					<div><ProgressIndicator label="Initializing page..."/></div>
				}
				{
					//@ts-ignore
					<wcd-root-app></wcd-root-app>
				}
				<AngularWrapperPanels panels={this.state.panels}/>
				{this.state.tab ? <ReactPortalWrapper {...this.state.tab}/> : null}
			</div>
		);
	}

    componentDidMount() {
		const perf = sccHostService.perf.createPerformanceSession(`ng-app-bootstrap@${sccHostService.state.current.name}`, 'ng-app-bootstrap', {
			isContainer: true,
			useParentEndAsStart: true,
			},
		);
		wcdAppReactWrapper.bootSCC()
			.then(() => {
				this.hideLoader();
				sccHostService.perf.endPerfSession(perf);
			})
			.catch(() => {
				this.hideLoader();
			})
    }

    componentWillUnmount() {
		wcdAppReactWrapper.destroyComponent();
    }

	private hideLoader() {
		this.setState({loading: false});
	}
}

export default function appBootstrapScc() {
    // Set webpack path to storage for lazy loading
    __webpack_public_path__ = sccHostService.getPackageBasePathByPkg() + '/';
    return (props: { input?: any }) => <AngularWrapper {...props} />;
}


