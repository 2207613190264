import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { Outbreak } from '@wcd/domain';

@Injectable()
export class OutbreakEntityTypeService implements EntityTypeService<Outbreak> {
	readonly entityType: EntityType<Outbreak> = {
		entity: Outbreak,
		id: 'outbreakId',
		getEntityName: (outbreak: Outbreak) => outbreak.displayName,
		getEntityDataviewLink: () => '/threatanalytics3',
	};
}
