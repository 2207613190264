import { Injectable } from '@angular/core';
import {
	ChangeEvent,
	ChangeEventCategory,
	VulnerabilityChangeEventType,
	ConfigurationChangeEventType,
	RecommendationCategory,
} from '@wcd/domain';
import { TvmTextsService } from './tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { PrettyNumberService } from '@wcd/prettify';

@Injectable()
export class ChangeEventTextService {
	private _noDataAvailableText: string;
	private _operatingSystemText: string;

	constructor(
		private tvmTextsService: TvmTextsService,
		private prettyNumberService: PrettyNumberService,
		private i18nService: I18nService
	) {
		this._noDataAvailableText = this.tvmTextsService.noDataAvailableToken;
		this._operatingSystemText = this.i18nService.get('tvm.common.operatingSystem');
	}

	public getRelatedComponent(event: ChangeEvent): string {
		if (!event) {
			return this._noDataAvailableText;
		}

		switch (event.category) {
			case ChangeEventCategory.Vulnerability:
				return this.tvmTextsService.parseFullProductName(event.productName, event.vendor);
			case ChangeEventCategory.Configuration:
				const category = event.configurationCategory;
				return category === RecommendationCategory.OS ? this._operatingSystemText : category;
			default:
				return this._noDataAvailableText;
		}
	}

	public getChangeEventText(changeEvent: ChangeEvent): string {
		if (!changeEvent) {
			return this._noDataAvailableText;
		}

		switch (changeEvent.category) {
			case ChangeEventCategory.Vulnerability:
				return this.handleVaChangeEventText(changeEvent);
			case ChangeEventCategory.Configuration:
				return this.handleScaChangeEventText(changeEvent);
			default:
				return this._noDataAvailableText;
		}
	}

	private handleVaChangeEventText(changeEvent: ChangeEvent): string {
		const isSingular = changeEvent.cvesList.length === 1;
		const wasValue = isSingular ? 'was' : 'were';
		const vulnerabilityValue = isSingular ? 'vulnerability' : 'vulnerabilities';
		const eventHasAssetsCount = changeEvent.assetsCount !== null && changeEvent.assetsCount !== undefined;
		const currentlyToken = eventHasAssetsCount ? '' : ' currently';
		const machineCount = this.prettyNumberService.prettyNumber(
			eventHasAssetsCount ? changeEvent.assetsCount : changeEvent.currentAssetsCount
		);
		const machine = changeEvent.assetsCount === 1 ? 'device' : 'devices';
		const cveNum = changeEvent.cvesList.length;
		const aValue = cveNum === 1 ? 'a' : cveNum.toString();
		const productName = this.tvmTextsService.parseFullProductName(
			changeEvent.productName,
			changeEvent.vendor
		);
		const exploit = isSingular ? 'An exploit' : 'Exploits';

		switch (changeEvent.eventType) {
			case VulnerabilityChangeEventType.NewCve:
				return `${productName} has ${aValue} new ${vulnerabilityValue},${currentlyToken} impacting ${machineCount} ${machine}`;
			case VulnerabilityChangeEventType.CveHasPubliclyDisclosedExploit:
				return `${cveNum} ${vulnerabilityValue} in ${productName} became exploitable,${currentlyToken} impacting ${machineCount} ${machine}`;
			case VulnerabilityChangeEventType.CveHasVerifiedExploit:
				return `${exploit} for ${cveNum} ${vulnerabilityValue} in ${productName} became verified,${currentlyToken} impacting ${machineCount} ${machine}`;
			case VulnerabilityChangeEventType.CveHasExploitInKit:
				return `${exploit} for ${cveNum} ${vulnerabilityValue} in ${productName} ${wasValue} added to an exploit kit,${currentlyToken} impacting ${machineCount} ${machine}`;
			default:
				return this._noDataAvailableText;
		}
	}

	private handleScaChangeEventText(changeEvent: ChangeEvent) {
		const isSingleDevice = changeEvent.assetsCount === 1;
		switch (changeEvent.eventType) {
			case ConfigurationChangeEventType.NewScid:
				const templateKey = isSingleDevice
					? 'tvm_changeEvent_configuration_newScid_single'
					: 'tvm_changeEvent_configuration_newScid_plural';
				return this.i18nService.get(templateKey, {
					scid: changeEvent.scid,
					configurationCategory: changeEvent.configurationCategory,
					devicesCount: changeEvent.assetsCount,
				});
			default:
				return this._noDataAvailableText;
		}
	}
}
