import { isNil } from 'lodash-es';
import { airsEntityStatusValues } from '../../airs-entity-status.entity';
import { AirsEntityType, AirsEntityTypeValue } from '../../airs-entity-type.entity';
import { VerdictStatusType } from '../../airs-entity-status-types.enum';
import {
	getThreatsNamesFromThreatIntelligence,
	getLastRemediationProvider,
	getRemediationStateId,
	getThreatsNames,
} from '../../../mtp-investigation/mtp-investigation.entity';
import { InvestigationActionStatusType } from '../../../investigation/actions/investigation-action-status.values';
import { mapOfficeActionToRemediationAction } from '../../../investigation/actions/office-action.utils';
import { mapEmailClusterEntityToAirsEntity } from './airs-entity.email-cluster.converter';
import { mapMailMessageEntityToAirsEntity } from './airs-entity.mail-message.converter';
import { mapMailboxEntityToAirsEntity } from './airs-entity.mailbox.converter';
import { mapMailboxConfigurationEntityToAirsEntity } from './airs-entity.mailbox-configuration.converter';
import { mapSubmissionMailEntityToAirsEntity } from './airs-entity.submission-mail.converter';
import { mapUrlEntityToAirsEntity } from './airs-entity.url.converter';
import { mapIpEntityToAirsEntity } from './airs-entity.ip.converter';
import { mapFileEntityToAirsEntity } from './airs-entity.file.converter';
import { mapProcessEntityToAirsEntity } from './airs-entity.process.converter';
import { mapSecurityGroupEntityToAirsEntity } from './airs-entity.security-group.converter';
import { mapRegistryKeyEntityToAirsEntity } from './airs-entity.registry-key.converter';
import { mapRegistryValueEntityToAirsEntity } from './airs-entity.registry-value.converter';
import { mapCloudApplicationEntityToAirsEntity } from './airs-entity.cloud-application.converter';
import { mapCloudLogonSessionEntityToAirsEntity } from './airs-entity.cloud-logon-session.converter';
import { mapOauthApplicationEntityToAirsEntity } from './airs-entity.oauth-application.converter';
import { mapActiveDirectoryDomainEntityToAirsEntity } from './airs-entity.active-directory-domain.converter';
import { EntityDisplayNameFunction } from '../entity-display-name.utils';
import { EvidenceDetectionSourceTypeEnum } from '../../../evidence/evidence-detection-source-type.enum';
import { AlertV3EntityTypes } from '../alertV3-entity-types.enum';
import { EvidenceAssetTypeType } from '../../../evidence/evidence-asset-type.entity';
import { BaseAirsEntityAlertV3Fields } from '../alertV3-base-fields.enum';

const ENTITY_TYPE_PROPS = {
	[AlertV3EntityTypes.MailCluster]: {
		id: AirsEntityType.MailCluster,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.MailCluster],
		entityMapper: mapEmailClusterEntityToAirsEntity,
	},
	[AlertV3EntityTypes.MailMessage]: {
		id: AirsEntityType.MailMessage,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.MailMessage],
		entityMapper: mapMailMessageEntityToAirsEntity,
	},
	[AlertV3EntityTypes.SubmissionMail]: {
		id: AirsEntityType.SubmissionMail,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.SubmissionMail],
		entityMapper: mapSubmissionMailEntityToAirsEntity,
	},
	[AlertV3EntityTypes.Mailbox]: {
		id: AirsEntityType.Mailbox,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.Mailbox],
		entityMapper: mapMailboxEntityToAirsEntity,
	},
	[AlertV3EntityTypes.MailboxConfiguration]: {
		id: AirsEntityType.MailboxConfiguration,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.MailboxConfiguration],
		entityMapper: mapMailboxConfigurationEntityToAirsEntity,
	},
	[AlertV3EntityTypes.URL]: {
		id: AirsEntityType.URL,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.URL],
		entityMapper: mapUrlEntityToAirsEntity,
	},
	[AlertV3EntityTypes.IP]: {
		id: AirsEntityType.IP,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.IP],
		entityMapper: mapIpEntityToAirsEntity,
	},
	[AlertV3EntityTypes.File]: {
		id: AirsEntityType.File,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.File],
		entityMapper: mapFileEntityToAirsEntity,
	},
	[AlertV3EntityTypes.Process]: {
		id: AirsEntityType.Process,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.Process],
		entityMapper: mapProcessEntityToAirsEntity,
	},
	[AlertV3EntityTypes.SecurityGroup]: {
		id: AirsEntityType.SecurityGroup,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.SecurityGroup],
		entityMapper: mapSecurityGroupEntityToAirsEntity,
	},
	[AlertV3EntityTypes.RegistryKey]: {
		id: AirsEntityType.RegistryKey,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.RegistryKey],
		entityMapper: mapRegistryKeyEntityToAirsEntity,
	},
	[AlertV3EntityTypes.RegistryValue]: {
		id: AirsEntityType.RegistryValue,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.RegistryValue],
		entityMapper: mapRegistryValueEntityToAirsEntity,
	},
	[AlertV3EntityTypes.CloudApplication]: {
		id: AirsEntityType.CloudApplication,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.CloudApplication],
		entityMapper: mapCloudApplicationEntityToAirsEntity,
	},
	[AlertV3EntityTypes.CloudLogonSession]: {
		id: AirsEntityType.CloudLogonSession,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.CloudLogonSession],
		entityMapper: mapCloudLogonSessionEntityToAirsEntity,
	},
	[AlertV3EntityTypes.OauthApplication]: {
		id: AirsEntityType.OauthApplication,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.OauthApplication],
		entityMapper: mapOauthApplicationEntityToAirsEntity,
	},
	[AlertV3EntityTypes.ActiveDirectoryDomain]: {
		id: AirsEntityType.ActiveDirectoryDomain,
		displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.ActiveDirectoryDomain],
		entityMapper: mapActiveDirectoryDomainEntityToAirsEntity,
	},
} as const;

export function getBaseAirsEntity(entityData, entityTypeId): any {
	const res = Object.entries(entityData).reduce(
		(acc, [key, val]) => {
			try {
				switch (key) {
					case BaseAirsEntityAlertV3Fields.ThreatIntelligence:
						acc['threats'] = getThreatsNamesFromThreatIntelligence(val);
						break;
					case BaseAirsEntityAlertV3Fields.ThreatAnalysisSummary:
						if(acc['threats']) break;
						const threats = getThreatsNames(val);
						acc['threats'] = threats.length ? threats.join(', ') : null;
						break;
					case BaseAirsEntityAlertV3Fields.CreatedTimeUtc:
						acc['created_on'] = val;
						break;
					case BaseAirsEntityAlertV3Fields.LastUpdatedTimeUtc:
						acc['updated_on'] = val;
						break;
					case BaseAirsEntityAlertV3Fields.FirstEvidenceTime:
					case BaseAirsEntityAlertV3Fields.FirstSeen:
						acc['first_seen'] = val;
						break;
					case BaseAirsEntityAlertV3Fields.Verdict:
					case BaseAirsEntityAlertV3Fields.LastVerdict:
						const verdictType = val && VerdictStatusType[<string>val];
						const verdict = airsEntityStatusValues.find(s => s.type === verdictType);
						acc['verdict'] = verdict != null ? verdict.id : null;
						acc['entity_status'] = verdict != null ? verdict.id : null;
						break;
					case BaseAirsEntityAlertV3Fields.Timestamp:
						acc['timestamp'] = val;
						break;
					case BaseAirsEntityAlertV3Fields.MergeByKey:
						acc['merge_by_key'] = val;
						break;
					case BaseAirsEntityAlertV3Fields.Urn:
						acc['urn'] = val;
						break;
					case BaseAirsEntityAlertV3Fields.LastRemediationState:
						const remediationStatusTypeId = getRemediationStateId(<string>val, {});
						acc['remediation_status'] = remediationStatusTypeId;
						break;
					case BaseAirsEntityAlertV3Fields.EntityPageUrl:
						acc['entity_page_url'] = val;
						break;
					case BaseAirsEntityAlertV3Fields.Alerts:
						const entityDetectionSourceList =
							val &&
							(<Array<{ [key: string]: any }>>val).reduce((res, alert) => {
								res.push({
									detection_type: EvidenceDetectionSourceTypeEnum.alert,
									detection_source_name: alert[BaseAirsEntityAlertV3Fields.AlertTitle],
									detection_source_id: alert[BaseAirsEntityAlertV3Fields.AlertId],
									additional_data: {
										alert: {
											severity: alert[BaseAirsEntityAlertV3Fields.AlertSeverity],
										},
									},
								});
								return res;
							}, []);

						acc['entity_detection_src_list'] = entityDetectionSourceList;
						// taking the most updated alert (Alerts array are sorted descending by 'LastUpdateTimeUtc')
						acc['entity_detection_src'] =
							entityDetectionSourceList &&
							entityDetectionSourceList.length &&
							entityDetectionSourceList[0];
						break;

					case BaseAirsEntityAlertV3Fields.Assets:
						acc['impacted_assets'] =
							val &&
							(<Array<{ [key: string]: any }>>val).reduce((res, asset) => {
								const assetType = asset.AssetType;
								const assetData = asset.Asset;
								res.push({
									asset_type: assetType,
									asset: Object.assign(
										{
											name: assetData.Name,
										},
										assetType === EvidenceAssetTypeType.Machine
											? { machine_id: assetData.MachineId }
											: null
									),
								});
								return res;
							}, []);
						break;
					case BaseAirsEntityAlertV3Fields.Host:
						if (val) {
							const machineData = <{ [key: string]: any }>val;
							acc['host'] = {
								machine_id: machineData.MachineId,
								name: machineData.HostName,
								rbac_group_id: machineData.RbacGroupId,
							};
						}
						break;
					case 'IsConvergedPlatform':
						// Note: 'IsConvergedPlatform' is not part of AlertV3 schema, hence, it's ot part of the enum
						acc['is_converged_platform'] = val;
						break;
					default:
						if (typeof key === 'string' && key.includes('.')) {
							const [k, v] = key.split('.');
							if (k in entityData) {
								entityData[k][v] = val;
							} else {
								entityData[k] = { [v]: val };
							}
						} else {
							acc[key] = val;
						}
				}
			} catch (e) {}
			return acc;
		},
		{ type_id: entityTypeId }
	);

	/*
		In AlertV3 schema 'RemediationProviders' data is an array.
		Not only office use AlertV3 schema, hence, it should support all cases
		(for example Azure has more than 1 provider but currently not supported).
		In case of office - there will be only 1 provider, hence, we are taking the last which is the only one.
	 */
	const lastRemediationProvider = getLastRemediationProvider(entityData.RemediationProviders);

	// set timestamp
	const startTimeUtc = lastRemediationProvider ? lastRemediationProvider.StartTimeUtc : null;
	if (!isNil(startTimeUtc) && !res.hasOwnProperty['timestamp']) {
		res['timestamp'] = startTimeUtc;
	}

	// set remediation status
	const remediationStatusTypeId = getRemediationStateId(
		entityData.LastRemediationState,
		lastRemediationProvider
	);

	if (remediationStatusTypeId) {
		res['remediation_status'] = remediationStatusTypeId;
	}

	if (
		lastRemediationProvider &&
		lastRemediationProvider.ActionStatus === InvestigationActionStatusType.Pending
	) {
		const remediationAction = mapOfficeActionToRemediationAction(lastRemediationProvider, entityData);
		res['remediation_actions'] = remediationAction ? [remediationAction] : null;
	}

	// set investigation id
	const investigationId = lastRemediationProvider && lastRemediationProvider.InvestigationId;
	res['investigation'] = investigationId ? { investigation_id: investigationId } : null;

	res['id'] = entityData.Urn || entityData.MergeByKey;
	return res;
}

export function mapAlertV3EntityToAirsEntity(entityData): any {
	const entityType = entityData && entityData.Type;
	const entityTypeProps = ENTITY_TYPE_PROPS[entityType];
	const typeId = entityTypeProps && entityTypeProps.id;

	if (!typeId) {
		return;
	}
	const baseAirsEntity = getBaseAirsEntity(entityData, typeId);
	const entityMapper = entityTypeProps && entityTypeProps.entityMapper;
	const specificEntity = entityMapper && entityMapper(entityData);

	// get the calculated display_name according to the current entity type
	const entityDisplayName =
		entityTypeProps && entityTypeProps.displayNameFunc
			? entityTypeProps.displayNameFunc(specificEntity)
			: '';

	const res = Object.assign(baseAirsEntity, specificEntity, { display_name: entityDisplayName });
	// keeping the '_rawData' cause we need it for the evidence dataview panels
	res['_rawData'] = res;
	return res;
}
