var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var _a;
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { ParseDataQuery, TvmAnalyticsBaseUrl } from '../analyticsEndPointUtils';
import { ChangeEventByDate } from './change-event-by-date.value-object';
import { ApiVersionHeaderName } from '../commonEndPointUtils';
export var CHANGE_EVENTS_DOMAIN_KEY = 'eventsDomain';
export var EVENTS_PER_DAY_KEY = 'eventsPerDay';
export var ChangeEventDomain;
(function (ChangeEventDomain) {
    ChangeEventDomain["VA"] = "va";
    ChangeEventDomain["SCA"] = "sca";
})(ChangeEventDomain || (ChangeEventDomain = {}));
var ChangeEventTopPerDayApiCall = /** @class */ (function (_super) {
    __extends(ChangeEventTopPerDayApiCall, _super);
    function ChangeEventTopPerDayApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChangeEventTopPerDayApiCall = __decorate([
        ApiCall({
            baseUrl: TvmAnalyticsBaseUrl,
            cache: null,
            customHeaders: (_a = {}, _a[ApiVersionHeaderName] = '2.0', _a),
            parseQuery: function (dataQuery) { return ({
                params: __assign({}, ParseDataQuery(dataQuery), { eventsDomain: dataQuery.where[CHANGE_EVENTS_DOMAIN_KEY], eventsPerDay: dataQuery.where[EVENTS_PER_DAY_KEY] || 2 }),
            }); },
            name: 'Top change events per day',
            endpoint: function (_, dataQuery) {
                var domain = dataQuery.where[CHANGE_EVENTS_DOMAIN_KEY];
                delete dataQuery.where[CHANGE_EVENTS_DOMAIN_KEY];
                return "changeEvents/" + domain + "/topPerDay";
            },
            type: ChangeEventByDate,
        })
    ], ChangeEventTopPerDayApiCall);
    return ChangeEventTopPerDayApiCall;
}(ApiCallModel));
export { ChangeEventTopPerDayApiCall };
