/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../common/collapsible-items-preview.component.ngfactory";
import * as i2 from "../common/collapsible-items-preview.component";
import * as i3 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "./related-exceptions.details.component";
import * as i7 from "@microsoft/paris/dist/lib/paris";
import * as i8 from "../../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exceptions.fields.service";
import * as i9 from "../../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exception.service";
import * as i10 from "../../../../@entities/@tvm/common/side-panel.service";
var styles_RelatedExceptionsDetailsComponent = [];
var RenderType_RelatedExceptionsDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RelatedExceptionsDetailsComponent, data: {} });
export { RenderType_RelatedExceptionsDetailsComponent as RenderType_RelatedExceptionsDetailsComponent };
function View_RelatedExceptionsDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "collapsible-items-preview", [["disableSort", "true"], ["maxItems", "3"]], null, [[null, "showMore"], [null, "onItemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showMore" === en)) {
        var pd_0 = (_co.openAllRelatedExceptions() !== false);
        ad = (pd_0 && ad);
    } if (("onItemClick" === en)) {
        var pd_1 = (_co.openRelatedExceptionPanel($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CollapsibleItemsPreviewComponent_0, i1.RenderType_CollapsibleItemsPreviewComponent)), i0.ɵdid(1, 114688, null, 0, i2.CollapsibleItemsPreviewComponent, [i0.ChangeDetectorRef], { repository: [0, "repository"], title: [1, "title"], loadingText: [2, "loadingText"], maxItems: [3, "maxItems"], disableSort: [4, "disableSort"], fields: [5, "fields"], initiallyCollapsed: [6, "initiallyCollapsed"], dataviewLabel: [7, "dataviewLabel"] }, { showMore: "showMore", onItemClick: "onItemClick" }), i0.ɵppd(2, 1), i0.ɵppd(3, 1), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.repository; var currVal_1 = i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "tvm.recommendationException.deviceGroupsExceptionDetails")); var currVal_2 = i0.ɵunv(_v, 1, 2, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), "common.loading")); var currVal_3 = "3"; var currVal_4 = "true"; var currVal_5 = _co.fields; var currVal_6 = false; var currVal_7 = i0.ɵunv(_v, 1, 7, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "tvm.recommendationException.deviceGroupsExceptionDetails")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_RelatedExceptionsDetailsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelatedExceptionsDetailsComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fields; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RelatedExceptionsDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "related-exceptions-details", [], null, null, null, View_RelatedExceptionsDetailsComponent_0, RenderType_RelatedExceptionsDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i6.RelatedExceptionsDetailsComponent, [i7.Paris, i8.RecommendationExceptionsFieldsService, i0.ChangeDetectorRef, i9.RecommendationExceptionService, i10.SidePanelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RelatedExceptionsDetailsComponentNgFactory = i0.ɵccf("related-exceptions-details", i6.RelatedExceptionsDetailsComponent, View_RelatedExceptionsDetailsComponent_Host_0, { sourceItem: "sourceItem" }, {}, []);
export { RelatedExceptionsDetailsComponentNgFactory as RelatedExceptionsDetailsComponentNgFactory };
