import { Injectable } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import {
	Tag,
	SecurityRecommendation,
	RemediationTask,
	Software,
	EolState,
	SoftwareVersion,
	SoftwareInstallation,
	MissingKb,
	FILTER_TAG,
	VA_RECOMMENDATION_FILTER_TAG,
	VERSION_DISTRIBUTION_FILTER_TAG,
	SCA_RECOMMENDATION_FILTER_TAG,
	RemediationType,
	VulnerabilityType,
	Vulnerability,
} from '@wcd/domain';
import { TvmProductivityImpactService } from './tvm-productivity-impact.service';
import { SOFTWARE_CATEGORY } from '../../@entities/@tvm/software-inventory/components/software-entity/software.entity.component';
import { TvmTagsServiceCommon } from './tvm-tag.service.common';
import { IFeaturesService } from '@wcd/scc-common';

const COVID_19_SCIDS = [
	'scid-24',
	'scid-28',
	'scid-30',
	'scid-41',
	'scid-42',
	'scid-44',
	'scid-55',
	'scid-64',
	'scid-63',
	'scid-68',
	'scid-87',
	'scid-88',
	'scid-94',
	'scid-95',
	'scid-2000',
	'scid-2001',
	'scid-2002',
	'scid-2010',
	'scid-2011',
	'scid-2012',
	'scid-2014',
	'scid-2016',
	'scid-3011',
];

const HUMAN_OPERATED_RANSOMWARE_SCIDS = [
	'scid-24',
	'scid-25',
	'scid-41',
	'scid-44',
	'scid-84',
	'scid-93',
	'scid-2003',
	'scid-2501',
	'scid-2509',
	'scid-2510',
	'scid-5006',
];

const IMPACT_ASSESSMENT_TAG_KEY = 'UserImpactAssessment';
const COVID_19_TAG_KEY = 'covid19';
const HUMAN_OPERATED_RANSOMWARE_KEY = 'HumanOperatedRansomware';

const portalToAnalyticsTagKeys = {
	[SCA_RECOMMENDATION_FILTER_TAG.covid19]: COVID_19_TAG_KEY,
};

@Injectable()
export class TvmTagsService {
	private readonly eolTag: Tag;
	private readonly upcomingEolTag: Tag;
	private readonly networkDeviceTag: Tag;
	private readonly eolVersionTag: Tag;
	private readonly upcomingEolVersionTag: Tag;
	private readonly hasEolVersionsTag: Tag;
	private readonly hasUpcomingEolVersionsTag: Tag;
	private readonly zeroDayTag: Tag;

	private hasNetworkScanEnabled: boolean;
	private _humanOperatedRansomwareTagEnabled: boolean;
	private _productivityImpactExposed: boolean;
	private zeroDayEnabled: boolean;

	private _scaSupportedFilterTags: string[] = [];
	private _scaTags: Map<string, Tag>;
	private tagServiceCommon: TvmTagsServiceCommon;

	constructor(
		private productivityImpactService: TvmProductivityImpactService,
		featuresService: FeaturesService,
		private i18nService: I18nService
	) {
		this._productivityImpactExposed = this.productivityImpactService.isProductivityImpactExposed;
		this._humanOperatedRansomwareTagEnabled = featuresService.isEnabled(Feature.TvmHumanOpRansomwareTags);

		this.hasNetworkScanEnabled = featuresService.isEnabled(Feature.TvmNetworkScan);

		this.zeroDayEnabled = featuresService.isEnabled(Feature.TvmZeroDay);

		this.eolTag = this.getEolTag();
		this.upcomingEolTag = this.getUpcomingEolTag();
		this.networkDeviceTag = this.getNetworkDeviceTag();
		this.eolVersionTag = this.getEolVersionTag();
		this.upcomingEolVersionTag = this.getUpcomingEolVersionTag();
		this.hasEolVersionsTag = this.getHasEolVersionsTag();
		this.hasUpcomingEolVersionsTag = this.getHasUpcomingEolVersionsTag();
		this.zeroDayTag = this.getZeroDayTagTag();

		this.tagServiceCommon = new TvmTagsServiceCommon(featuresService as IFeaturesService);

		this.setScaSupportedFilterTags();
	}

	getSoftwareInstallationVersionTags(installation: SoftwareInstallation): Array<Tag> {
		return this.tagServiceCommon.getSoftwareInstallationVersionTags(
			installation,
			this.i18nService.get('tvm.securityRecommendation.endOfLifeVersion'),
			this.i18nService.get('tvm.securityRecommendation.hasUpcomingEolVersions')
		);
	}

	getVulnerabilityTags(vulnerability: Vulnerability): Array<Tag> {
		return this.tagServiceCommon.getVulnerabilityTags(
			vulnerability,
			this.i18nService.strings.tvm_zeroDay_tag
		);
	}

	getRecommendationTags(recommendation: SecurityRecommendation): Array<Tag> {
		const tags = new Array<Tag>();

		if (recommendation.remediationType === RemediationType.ConfigurationChange && recommendation.scId) {
			recommendation.tags
				.filter((t) => this._scaTags.has(t))
				.forEach((t) => tags.push(this._scaTags.get(t)));
		}

		if (
			this.verifyZeroDayTag(recommendation.mostSevereVulnerabilityType, recommendation.patchReleaseDate)
		) {
			tags.push(this.zeroDayTag);
		}

		if (this.verifyAlreadyEolSoftwareTag(recommendation.eolSoftwareState)) {
			tags.push(this.eolTag);
		}

		if (this.verifyUpcomingEolSoftwareTag(recommendation.eolSoftwareState)) {
			tags.push(this.upcomingEolTag);
		}

		if (recommendation.hasEolVersions) {
			tags.push(this.hasEolVersionsTag);
		}

		if (recommendation.hasUpcomingEolVersions) {
			tags.push(this.hasUpcomingEolVersionsTag);
		}

		return tags;
	}

	getRemediationTags(remediationTask: RemediationTask): Array<Tag> {
		const tags = new Array<Tag>();
		if (!remediationTask || !remediationTask.taskArgs) {
			return tags;
		}

		const taskArgs = remediationTask.taskArgs;
		const softwareArgs = taskArgs.softwareArgs;
		const scaArgs = taskArgs.securityConfigurationArgs;

		if (softwareArgs) {
			if (softwareArgs.isEOL) {
				tags.push(this.eolTag);
			}
			if (softwareArgs.isUpcomingEol) {
				tags.push(this.upcomingEolTag);
			}
			if (softwareArgs.hasEolVersions) {
				tags.push(this.hasEolVersionsTag);
			}
			if (softwareArgs.hasUpcomingEolVersions) {
				tags.push(this.hasUpcomingEolVersionsTag);
			}
		} else if (scaArgs) {
			// TODO: evhvoste - remove this once remediation will also support tags
			// VSTS - Task 26790234: Add support for tags in remediation service created tasks
			if (this._productivityImpactExposed && taskArgs.productivityImpactRemediationType) {
				tags.push(this._scaTags.get(IMPACT_ASSESSMENT_TAG_KEY));
			}
			if (COVID_19_SCIDS.includes(scaArgs.scid)) {
				tags.push(this._scaTags.get(COVID_19_TAG_KEY));
			}
			if (
				this._humanOperatedRansomwareTagEnabled &&
				HUMAN_OPERATED_RANSOMWARE_SCIDS.includes(scaArgs.scid)
			) {
				tags.push(this._scaTags.get(HUMAN_OPERATED_RANSOMWARE_KEY));
			}
		}
		return tags;
	}

	getSoftwareTags(software: Software): Array<Tag> {
		const tags = new Array<Tag>();

		if (this.verifyZeroDayTag(software.mostSevereVulnerabilityType, software.patchReleaseDate)) {
			tags.push(this.zeroDayTag);
		}

		if (this.verifyAlreadyEolSoftwareTag(software.eolSoftwareState)) {
			tags.push(this.eolTag);
		}

		if (this.verifyUpcomingEolSoftwareTag(software.eolSoftwareState)) {
			tags.push(this.upcomingEolTag);
		}

		if (software.hasEolVersions) {
			tags.push(this.hasEolVersionsTag);
		}

		if (software.hasUpcomingEolVersions) {
			tags.push(this.hasUpcomingEolVersionsTag);
		}

		if (software.category === SOFTWARE_CATEGORY.NetworkGear) {
			tags.push(this.networkDeviceTag);
		}

		return tags;
	}

	getSoftwareVersionTags(version: SoftwareVersion): Array<Tag> {
		const tags = new Array<Tag>();
		if (this.verifyAlreadyEolVersionTag(version.eolVersionState)) {
			tags.push(this.eolVersionTag);
		}
		if (this.verifyUpcomingEolVersionTag(version.eolVersionState)) {
			tags.push(this.upcomingEolVersionTag);
		}
		return tags;
	}

	getSoftwareInstallationTags(softwareInstallation: SoftwareInstallation): Array<Tag> {
		const tags = new Array<Tag>();
		if (this.verifyAlreadyEolVersionTag(softwareInstallation.eolVersionState)) {
			tags.push(this.eolVersionTag);
		}
		if (this.verifyUpcomingEolVersionTag(softwareInstallation.eolVersionState)) {
			tags.push(this.upcomingEolVersionTag);
		}
		return tags;
	}

	getMissingKbTags(missingKb: MissingKb): Array<Tag> {
		const tags = new Array<Tag>();
		if (this.verifyAlreadyEolVersionTag(missingKb.eolVersionState)) {
			tags.push(this.eolVersionTag);
		}
		if (this.verifyUpcomingEolVersionTag(missingKb.eolVersionState)) {
			tags.push(this.upcomingEolVersionTag);
		}
		return tags;
	}

	getSoftwareTagsForFiltering(): string[] {
		return Object.keys(FILTER_TAG).filter((tag) => {
			switch (FILTER_TAG[tag]) {
				case FILTER_TAG.networkGear:
					return this.hasNetworkScanEnabled;
				case FILTER_TAG.zeroDay:
					return this.zeroDayEnabled;
				default:
					return true;
			}
		});
	}

	getSoftwareVersionTagsForFiltering(): string[] {
		return Object.keys(VERSION_DISTRIBUTION_FILTER_TAG);
	}

	getVaRecommendationsTagsForFiltering(): string[] {
		return Object.keys(VA_RECOMMENDATION_FILTER_TAG).filter((tag) => {
			switch (VA_RECOMMENDATION_FILTER_TAG[tag]) {
				case VA_RECOMMENDATION_FILTER_TAG.zeroDay:
					return this.zeroDayEnabled;
				default:
					return true;
			}
		});
	}

	get scaRecommendationsTagsForFiltering(): string[] {
		return this._scaSupportedFilterTags;
	}

	private setScaSupportedFilterTags(): void {
		// TODO: once each tag is GA - convert to [tag1, tag2].forEach like for SCA_RECOMMENDATION_FILTER_TAG.covid19
		this._scaTags = new Map<string, Tag>();

		if (this._productivityImpactExposed) {
			this._scaSupportedFilterTags.push(SCA_RECOMMENDATION_FILTER_TAG.userImpactAssessment);
			this._scaTags.set(IMPACT_ASSESSMENT_TAG_KEY, {
				id: IMPACT_ASSESSMENT_TAG_KEY,
				name: this.i18nService.get('tvm.securityRecommendation.tag.userImpactAssessment'),
				tooltip: this.i18nService.get(
					'tvm.securityRecommendation.userImpact.productivityImpactTagTooltip'
				),
			});
		}
		[SCA_RECOMMENDATION_FILTER_TAG.covid19].forEach((tag) => {
			const tagKey = portalToAnalyticsTagKeys[tag];
			this._scaSupportedFilterTags.push(tag);
			this._scaTags.set(tagKey, {
				id: tagKey,
				name: this.i18nService.strings[`tvm_securityRecommendation_tag_${tagKey}`],
				tooltip: this.i18nService.strings[`tvm_securityRecommendation_tag_${tagKey}Tooltip`],
			});
		});

		if (this._humanOperatedRansomwareTagEnabled) {
			this._scaSupportedFilterTags.push(SCA_RECOMMENDATION_FILTER_TAG.humanOperatedRansomware);
			this._scaTags.set(HUMAN_OPERATED_RANSOMWARE_KEY, {
				id: HUMAN_OPERATED_RANSOMWARE_KEY,
				name: this.i18nService.strings.tvm_securityRecommendation_tag_humanOperatedRansomware,
				tooltip: this.i18nService.strings
					.tvm_securityRecommendation_tag_humanOperatedRansomwareTooltip,
			});
		}
	}

	private getZeroDayTagTag(): Tag {
		return {
			id: 'zeroDayTag',
			name: this.i18nService.strings.tvm_zeroDay_tag,
			className: 'color-box-attention',
		};
	}

	private getEolTag(): Tag {
		return {
			id: 'endOfLifeTag',
			name: this.i18nService.get('tvm.securityRecommendation.endOfLife'),
			className: 'color-box-dark-medium-opacity',
		};
	}

	private getUpcomingEolTag(): Tag {
		return {
			id: 'upcomingEndOfLifeTag',
			name: this.i18nService.get('tvm.securityRecommendation.upcomingEndOfLife'),
			className: 'tag-color-box-default',
		};
	}

	private getNetworkDeviceTag(): Tag {
		if (!this.hasNetworkScanEnabled) {
			return undefined;
		}
		return {
			id: 'networkSeviceTag',
			name: this.i18nService.get('tvm.securityRecommendation.networkDevice'),
			className: 'tag-color-box-default',
		};
	}

	private getEolVersionTag(): Tag {
		return {
			id: 'endOfLifeVersionTag',
			name: this.i18nService.get('tvm.securityRecommendation.endOfLifeVersion'),
		};
	}

	private getUpcomingEolVersionTag(): Tag {
		return {
			id: 'upcomingEndOfLifeVersionTag',
			name: this.i18nService.get('tvm.securityRecommendation.upcomingEndOfLifeVersion'),
		};
	}

	private getHasEolVersionsTag(): Tag {
		return {
			id: 'hasEolVersionsTag',
			name: this.i18nService.get('tvm.securityRecommendation.hasEolVersions'),
		};
	}

	private getHasUpcomingEolVersionsTag(): Tag {
		return {
			id: 'hasUpcomingEolVersionsTag',
			name: this.i18nService.get('tvm.securityRecommendation.hasUpcomingEolVersions'),
		};
	}

	private verifyUpcomingEolVersionTag(eolVersionState): boolean {
		return eolVersionState == EolState.UpcomingEOL;
	}

	private verifyAlreadyEolVersionTag(eolVersionState): boolean {
		return eolVersionState == EolState.AlreadyEOL;
	}

	private verifyUpcomingEolSoftwareTag(eolSoftwareState: EolState): boolean {
		return eolSoftwareState == EolState.UpcomingEOL;
	}

	private verifyAlreadyEolSoftwareTag(eolSoftwareState: EolState): boolean {
		return eolSoftwareState == EolState.AlreadyEOL;
	}

	private verifyZeroDayTag(vulnerabilityType: VulnerabilityType, patchReleaseDate: Date): boolean {
		return this.zeroDayEnabled && vulnerabilityType === VulnerabilityType.ZeroDay && !patchReleaseDate;
	}
}
