/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "../../../comments/components/editable-comments-list/editable-comments-list.component.ngfactory";
import * as i5 from "../../../comments/components/editable-comments-list/editable-comments-list.component";
import * as i6 from "../../../comments/services/comments.store";
import * as i7 from "../../../insights/services/performance.scc.service";
import * as i8 from "../../../shared/interfaces/disableable.interface";
import * as i9 from "../../../rbac/directives/rbac-control.directive";
import * as i10 from "../../../rbac/services/rbac-control.service";
import * as i11 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i12 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i13 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i14 from "./incident-comments.component";
import * as i15 from "@angular/router";
import * as i16 from "@microsoft/paris/dist/lib/paris";
import * as i17 from "../../../global_entities/services/activated-entity.service";
import * as i18 from "../services/incidents.service";
import * as i19 from "../../alerts/services/alerts.service";
import * as i20 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i21 from "../../../dialogs/services/dialogs.service";
import * as i22 from "@angular/cdk/a11y";
var styles_IncidentCommentsComponent = [];
var RenderType_IncidentCommentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IncidentCommentsComponent, data: {} });
export { RenderType_IncidentCommentsComponent as RenderType_IncidentCommentsComponent };
export function View_IncidentCommentsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 8, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 16777216, null, 1, 3, "editable-comments-list", [], null, [[null, "save"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.saveComment($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_EditableCommentsListComponent_0, i4.RenderType_EditableCommentsListComponent)), i0.ɵdid(6, 573440, null, 0, i5.EditableCommentsListComponent, [i6.CommentsStore, i0.ChangeDetectorRef, i7.PerformanceSccService], { comments: [0, "comments"], isSaving: [1, "isSaving"], readonly: [2, "readonly"], isLoading: [3, "isLoading"] }, { save: "save" }), i0.ɵprd(2048, null, i8.DISABLEABLE_TOKEN, null, [i5.EditableCommentsListComponent]), i0.ɵdid(8, 4800512, null, 0, i9.RbacControlDirective, [i10.RbacControlService, i0.ElementRef, i0.ChangeDetectorRef, i11.WicdSanitizerService, [6, i8.DISABLEABLE_TOKEN], i12.TooltipsService, i0.ViewContainerRef, i13.I18nService], { rbac: [0, "rbac"], readonly: [1, "readonly"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.comments; var currVal_2 = _co.isSavingComment; var currVal_3 = !_co.incident.isFullyMachineRbacExposed; var currVal_4 = _co.isLoadingComments; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.rbacSettings; var currVal_6 = !_co.incident.isFullyMachineRbacExposed; _ck(_v, 8, 0, currVal_5, currVal_6); }, null); }
export function View_IncidentCommentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "incident-comments", [], null, null, null, View_IncidentCommentsComponent_0, RenderType_IncidentCommentsComponent)), i0.ɵdid(1, 245760, null, 0, i14.IncidentCommentsComponent, [i15.Router, i16.Paris, i17.ActivatedEntity, i18.IncidentsService, i19.AlertsService, i20.AuthService, i0.ChangeDetectorRef, i21.DialogsService, i13.I18nService, i22.LiveAnnouncer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IncidentCommentsComponentNgFactory = i0.ɵccf("incident-comments", i14.IncidentCommentsComponent, View_IncidentCommentsComponent_Host_0, { settings: "settings", incident: "incident" }, {}, []);
export { IncidentCommentsComponentNgFactory as IncidentCommentsComponentNgFactory };
