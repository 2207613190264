<!-- tslint:disable:template-accessibility-alt-text -->
<!-- tslint:disable:template-accessibility-label-for -->
<!-- tslint:disable:template-click-events-have-key-events -->
<form class="dialog-contents" #feedbackForm="ngForm" (submit)="submit()" data-track-component="FeedbackComponent">
	<div *ngIf="includeCause; else supportLinks" class="wcd-margin-bottom">
		<markdown class="wcd-flex-vertical wcd-flex-margins wcd-margin-bottom" [data]="'researcherAssistance.description' | i18n"></markdown>
		<label class="required">{{ 'researcherAssistance.cause' | i18n }}</label>
		<div style="width: 300px">
			<wcd-select
				[placeholder]="'researcherAssistance.causes.placeholder' | i18n"
				[values]="causes"
				[isFullWidth]="true"
				required
				name="cause"
				[(ngModel)]="cause"></wcd-select>
		</div>
	</div>
	<ng-template #supportLinks>
		<div class="wcd-margin-bottom wcd-font-weight-semibold">
			<div class="wcd-font-size-m wcd-margin-medium-top">{{'feedback.needSupport' | i18n}}</div>
			<div class="wcd-margin-top wcd-margin-mediumSmall-bottom">
				<a *ngIf="!supportCentralService.withSupportCentral; else SupportCentralLink" href="http://go.microsoft.com/fwlink/?LinkID=761093&clcid=0x409" target="_blank">
					{{openSupportTicketI18nKey | i18n}}
				</a>
				<ng-template #SupportCentralLink>
					<a (click)="supportCentralService.toggleWidgetIfPossible()">
						{{openSupportTicketI18nKey | i18n}}
					</a>
				</ng-template>
			</div>
			<div *ngIf="!supportCentralService.withSupportCentral"><markdown>{{'feedback.premierSupport' | i18n}}</markdown></div>
			<div class="wcd-font-size-m wcd-margin-largeMedium-top">{{'feedback.feedbackLabel' | i18n}}</div>
		</div>
	</ng-template>
	<div class="wcd-flex-horizontal">
		<div class="wcd-flex-1 wcd-padding-right">
				<textarea
					style="resize: none; min-height: 174px"
					class="wcd-full-width wcd-full-height form-control"
					rows="8"
					required
					autofocus
					name="feedbackMessage"
					[placeholder]="i18nType + 'placeholder' | i18n"
					[(ngModel)]="message"></textarea>
		</div>
		<div class="wcd-flex-none">
			<img
				*ngIf="screenshot$ | async as _screenshot; else loadingScreenshot"
				[src]="_screenshot"
				class="wcd-border-all"
				[class.disabled]="!includeScreenshot"
				style="max-width: 286px; max-height: 166px; border-color: #666"
				title="Screenshot"/>
			<ng-template #loadingScreenshot>
				<div style="width: 286px; height: 166px; background: #f4f4f4; border: solid 1px #999"
					 class="wcd-flex-center-all">
					<i class="large-loader-icon"></i>
				</div>
			</ng-template>
			<div class="wcd-margin-small-vertical">
				<wcd-checkbox
					name="feedbackIncludeScreenshot"
					[(ngModel)]="includeScreenshot"
					[label]="'feedback.includeScreenshot' | i18n"></wcd-checkbox>
			</div>
		</div>
	</div>
	<hr class="wcd-margin-bottom wcd-margin-largeMedium-top"/>
	<ng-container *ngIf="isEmailOptional; else emailLabel">
		<div class="wcd-margin-small-top">
			<wcd-checkbox
				[(ngModel)]="includeEmail"
				(ngModelChange)="setEmail()"
				name="feedbackIncludeEmail"
				[label]="'feedback.includeEmail' | i18n"></wcd-checkbox>
		</div>
	</ng-container>
	<ng-template #emailLabel>
		<label *ngIf="!isEmailOptional" for="feedback-email" class="required">{{'email' | i18n}}</label>
	</ng-template>
	<input type="email"
		id="feedback-email"
		validateRegExp="email"
		#emailControl="ngModel"
		name="feedbackEmail"
		[disabled]="!includeEmail"
		[required]="includeEmail || !isEmailOptional"
		class="form-control wcd-full-width wcd-margin-small-top"
		[placeholder]="(i18nType + 'emailPlaceholder') | i18n"
		[(ngModel)]="email"/>
	<div class="error-message"
		 [class.invisible]="!email || emailControl.valid">
		{{'feedback.invalidEmail' | i18n}}
	</div>
	<hr class="wcd-margin-none-all"/>
	<footer class="wcd-flex-horizontal wcd-margin-medium-top">
		<div class="wcd-flex-1">
			<fab-primary-button
				data-track-id="Submit"
				data-track-type="Button"
				*ngIf="isFeedbackEnabled; else disabledError"
				[text]="(isSaving ? 'feedback.submitting' : submitI18nKey) | i18n"
				(onClick)="submit()"
				[disabled]="!feedbackForm.form.valid || isSaving"></fab-primary-button>
			<ng-template #disabledError>
				<strong class="error-message" *ngIf="!isFeedbackEnabled">{{ 'feedback.disabled' | i18n }}</strong>
			</ng-template>
		</div>
		<div class="wcd-flex-none">
			<markdown [data]="'common.privacyStatement' | i18n"></markdown>
		</div>
	</footer>
</form>
