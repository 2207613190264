/* tslint:disable:template-click-events-have-key-events */
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	AlertPageService,
	detailsTabSupportedEntityTypes,
} from '../../services/alert-page.service';
import { TreeElementTypeEnum, TreeElement, GenericFieldType } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import _ from 'lodash';

const subTreeExpanderButtonStyle = {
	position: 'absolute',
	left: '-51px',
	top: '32px',
};

const subTreeRootExpanderButtonStyle = {
	...subTreeExpanderButtonStyle,
	left: '-25px',
};

@Component({
	selector: 'multiple-alerts-process-tree',
	templateUrl: './multiple-alerts-process-tree.component.html',
	styleUrls: ['./multiple-alerts-process-tree.component.scss'],
})
export class MultipleAlertsProcessTreeComponent implements OnInit, AfterViewInit {
	@Input() treeElements: Array<TreeElement>;
	@Output() doneRendering: EventEmitter<boolean> = new EventEmitter<boolean>();

	readonly TreeElementTypeEnum = TreeElementTypeEnum;
	readonly genericFieldType = GenericFieldType;
	readonly detailsTabSupportedEntityTypes = detailsTabSupportedEntityTypes;

	subTreeExpanderButtonStyle: any = subTreeExpanderButtonStyle;
	subTreeRootExpanderButtonStyle: any = subTreeRootExpanderButtonStyle;

	afterViewInitCalled = false;
	childrenToRender: number = 0;

	constructor(
		readonly alertPageService: AlertPageService,
		private readonly globalEntityTypesService: GlobalEntityTypesService
	) {}

	ngOnInit() {
		for (const treeElement of this.treeElements) {
			treeElement.subTreeNeverLoaded = treeElement.isSubTreeMinimized;
			treeElement.detailsNeverLoaded = treeElement.isMinimized;
			if (!treeElement.isSubTreeMinimized && treeElement.lastChild != null) {
				this.childrenToRender += treeElement.children.length + 1;
			}
			if (!treeElement.isMinimized && treeElement.associatedItems != null) {
				this.childrenToRender += treeElement.associatedItems.length;
			}
		}
	}

	ngAfterViewInit(): void {
		this.afterViewInitCalled = true;
		if (this.childrenToRender == 0) {
			this.doneRendering.emit();
		}
	}

	getEntityType(cyberEntityType: string) {
		if (detailsTabSupportedEntityTypes[cyberEntityType]) {
			return this.globalEntityTypesService.getEntityType(
				detailsTabSupportedEntityTypes[cyberEntityType]
			);
		}
	}

	hasTooltipValue(valueType: string): boolean {
		return (
			valueType == this.genericFieldType.String ||
			valueType == this.genericFieldType.DateTime ||
			valueType == this.genericFieldType.CopyPastableString ||
			valueType == this.genericFieldType.LinkableString
		);
	}

	OnDoneRendering() {
		if (this.childrenToRender > 0) {
			this.childrenToRender--;
			if (this.afterViewInitCalled && this.childrenToRender == 0) {
				this.doneRendering.emit();
			}
		}
	}

	onSubTreeMinimizedChange(treeElement: TreeElement) {
		treeElement.isSubTreeMinimized = !treeElement.isSubTreeMinimized;
		treeElement.subTreeNeverLoaded = false;
	}

	onTreeElementDetailsMinimizedChange(treeElement: TreeElement) {
		treeElement.isMinimized = !treeElement.isMinimized;
		treeElement.detailsNeverLoaded = false;
	}
}
