import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { File, SampleRequestInfo, SampleRequestStatus } from '@wcd/domain';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { FilesBackendService } from '../../services/files.backend.service';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'file-action-center-collection',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './file.action-center.collection.component.html',
	styleUrls: ['./file.action-center.panel.component.scss'],
})
export class FileActionCenterCollectionComponent implements OnInit {
	@Input() file: File;
	@Output() foundActions: EventEmitter<boolean> = new EventEmitter<boolean>();

	sampleRequestAction$: Observable<SampleRequestInfo | {}>;

	constructor(
		private readonly filesBackendService: FilesBackendService,
		private readonly appInsightService: AppInsightsService,
		private readonly i18nService: I18nService
	) {}

	statusToString(status: SampleRequestStatus): string {
		const statusDescriptionPrefix: string = 'file.sample.collect.actionCenter.requestStatus.';
		let keyName: string;

		switch (status) {
			case SampleRequestStatus.Active:
				keyName = 'active';
				break;
			case SampleRequestStatus.Completed:
				keyName = 'completed';
				break;
			default:
				keyName = 'failed';
		}

		return this.i18nService.get(statusDescriptionPrefix + keyName);
	}

	ngOnInit() {
		this.sampleRequestAction$ = this.filesBackendService.getSampleState(this.file.sha1, false).pipe(
			catchError(error => {
				this.appInsightService.trackException(error, 'getSampleMetadataForActionCenterPanel');
				return of(null);
			}), // in case of failure in the response, don't show sample history
			map(sampleState => {
				let history: Array<any> = null;
				if (!!sampleState) {
					history = sampleState.sampleRequestHistory.sort(
						(sr1, sr2) => sr2.creationTime.valueOf() - sr1.creationTime.valueOf()
					);
				}

				this.foundActions.emit(history && history.length > 0);
				return history && history.length > 0 ? history[0] : {};
			})
		);
	}
}
