export const downloadPackageTexts = {
	GroupPolicy: {
		title: 'Group Policy',
		description:
			"You can configure your devices using Group Policy.<br /> For more information on how to configure and monitor Microsoft Defender for Endpoint devices, see <a href='https://go.microsoft.com/fwlink/p/?linkid=822803' target='_blank'>Configure devices using Group Policy</a> section in the <a href='https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409' target='_blank'>Microsoft Defender for Endpoint guide</a>.",
	},
	SCCM1606: {
		title: 'System Center Configuration Manager (current branch) version 1606 and later',
		description:
			"Version 1606 and later of System Center Configuration Manager provides an integrated console to deploy Microsoft Defender for Endpoint policies and monitor the Microsoft Defender for Endpoint agent health status.<br /> For more information on how to configure and monitor Microsoft Defender for Endpoint devices, see <a href='https://go.microsoft.com/fwlink/p/?linkid=823682' target='_blank'>Microsoft Defender for Endpoint</a> section in the System Center Configuration Manager guide.",
	},
	SCCM: {
		title: 'System Center Configuration Manager 2012 / 2012 R2 / 1511 / 1602',
		description: `
			You can use System Center Configuration Manager’s default policy functionality to configure
			and monitor your devices. This is supported in the following versions:
					<ul class='onboarding-package-decription-ul'>
						<li>System Center 2012 Configuration Manager</li>
						<li>System Center 2012 R2 Configuration Manager</li>
						<li>System Center Configuration Manager (current branch) version 1511</li>
						<li>System Center Configuration Manager (current branch) version 1602</li>
					</ul>
					For more information on how to configure and monitor Microsoft Defender for Endpoint devices, see
					<a href='https://go.microsoft.com/fwlink/p/?linkid=822804' target='_blank'>
						Configure devices using System Center Configuration Manager
					</a> section in the
					<a href='https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409' target='_blank'>
						Microsoft Defender for Endpoint guide
					</a>.
		`,
	},
	MDM: {
		title: 'Mobile Device Management / Microsoft Intune',
		description: `
			You can use Mobile Device Management solutions, such as Microsoft Intune to configure and monitor your devices.<br />
			For more information on how to configure and monitor Microsoft Defender for Endpoint devices, see
			<a href='https://go.microsoft.com/fwlink/p/?linkid=822805' target='_blank'>
				Configure devices using Mobile Device Management tools
			</a> section in the
			<a href='https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409' target='_blank'>Microsoft Defender for Endpoint guide</a>.`,
	},
	LocalScript: {
		title: 'Local Script',
		description: `
			Run the script locally to configure a device for onboarding. This script has been optimized for use on up to 10 devices.
			To deploy at scale, consider other deployment options. For more information on how to use the script, see
			<a href='https://go.microsoft.com/fwlink/p/?linkid=822806' target='_blank'>Configure devices using a local script</a>.
		`,
	},
	LocalScriptDemo: {
		title: 'Local Script (for up to 10 devices)',
		description: `
			You can configure a single device by running a script locally. <br />
			<strong>Note: </strong>This script has been optimized for usage with a limited number of devices (1-10).
			To deploy at scale, please see other deployment options above. <br />
			For more information on how to configure and monitor Microsoft Defender for Endpoint devices, see
			<a href='https://go.microsoft.com/fwlink/p/?linkid=822806' target='_blank'>Configure devices using a local script</a>
			section in the <a href='https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409' target='_blank'>Microsoft Defender for Endpoint guide</a>.
		`,
	},
	VDI: {
		title: 'VDI onboarding scripts for non-persistent devices',
		description: `
			Use the VDI onboarding scripts to onboard non-persistent virtual desktop infrastructure (VDI) devices.
			These scripts help overcome typical challenges associated with these devices:
			<ul class='onboarding-package-decription-ul'>
				<li>Persistent device IDs for devices with the same device name</li>
				<li>Instantaneous onboarding on initial startup</li>
			</ul>
			For more information about onboarding non-persistent VDI devices, see
			<a href='https://go.microsoft.com/fwlink/?linkid=854375' target='_blank'>Onboard non-persistent VDI devices</a>.
		`,
	},
};
