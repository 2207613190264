var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { IncidentHistoryItem } from './incident-comments-item.entity';
var ɵ0 = function (config, query) {
    return query && query.where && query.where['newCommentsApi'] ? 'addComment' : 'incidents/addComment';
}, ɵ1 = function (config, query) {
    return query && query.where && query.where['newCommentsApi'] ? config.data.serviceUrls.incidents : config.data.serviceUrls.threatIntel;
}, ɵ2 = function (incidentAddCommentOptions) {
    return ({
        data: incidentAddCommentOptions,
        params: {
            newCommentsApi: incidentAddCommentOptions.newCommentsApi,
        }
    });
}, ɵ3 = function (data, _, query) {
    if (query && query.where && query.where['newCommentsApi']) {
        return __assign({ NewValue: data[0].Comment }, data[0]);
    }
    return data;
};
var IncidentsAddCommentApiCall = /** @class */ (function (_super) {
    __extends(IncidentsAddCommentApiCall, _super);
    function IncidentsAddCommentApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IncidentsAddCommentApiCall = __decorate([
        ApiCall({
            name: 'Add comment to incidents',
            endpoint: ɵ0,
            method: 'POST',
            type: IncidentHistoryItem,
            baseUrl: ɵ1,
            separateArrayParams: true,
            parseQuery: ɵ2,
            parseData: ɵ3,
        })
    ], IncidentsAddCommentApiCall);
    return IncidentsAddCommentApiCall;
}(ApiCallModel));
export { IncidentsAddCommentApiCall };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
