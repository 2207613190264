var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ComponentFactoryResolver, ComponentRef, Type } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { toObservable } from '../../utils/rxjs/utils';
import { ConfirmationService } from '../confirm/confirm.service';
import { ModalService } from '../modals/services/modal.service';
import { isCustomConfirmOptions, } from '../models/custom-confirm-options';
import { PanelService } from '@wcd/panels';
import { SnackBarsService } from '../snackbars/services/snackbars.service';
import { ErrorsDialogService } from './errors-dialog.service';
var DialogsService = /** @class */ (function () {
    function DialogsService(notificationsService, errorsService, snackbarsService, confirmService, modalsService, panelsService) {
        this.notificationsService = notificationsService;
        this.errorsService = errorsService;
        this.snackbarsService = snackbarsService;
        this.confirmService = confirmService;
        this.modalsService = modalsService;
        this.panelsService = panelsService;
    }
    DialogsService.prototype.confirm = function (options) {
        if (isCustomConfirmOptions(options)) {
            return this.showCustomConfirmModal(options);
        }
        return this.confirmService.confirm(options);
    };
    DialogsService.prototype.closeAllModals = function () {
        this.modalsService.closeAllModals();
    };
    DialogsService.prototype.showError = function (options) {
        return this.errorsService.showError(options);
    };
    DialogsService.prototype.showSuccessSnackbar = function (snackbarConfig) {
        var defaultConfig = {
            icon: 'checkCircle',
            iconClassName: 'color-text-success-dark',
            className: 'color-box-successBackground',
        };
        this.snackbarsService.add(__assign({}, defaultConfig, snackbarConfig));
    };
    DialogsService.prototype.showSnackbar = function (snackbarConfig) {
        this.snackbarsService.add(snackbarConfig);
    };
    DialogsService.prototype.setNotification = function (notificationId, notificationConfig) {
        this.notificationsService.setNotification(notificationId, notificationConfig);
    };
    DialogsService.prototype.showModal = function (component, modalSettings, parameters, componentFactoryResolver) {
        return this.modalsService.create(component, modalSettings, parameters, componentFactoryResolver);
    };
    DialogsService.prototype.showPanel = function (component, panelSettings, inputs, componentFactoryResolver) {
        return this.panelsService.create(component, panelSettings, inputs, componentFactoryResolver);
    };
    DialogsService.prototype.showCustomConfirmModal = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var dialogInstance, closeResult, confirmActionResult$;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.showModal(options.componentType, {}, options.inputs, options.componentFactoryResolver).toPromise()];
                    case 1:
                        dialogInstance = (_a.sent()).instance;
                        return [4 /*yield*/, Promise.race([
                                dialogInstance.onCancel.toPromise(),
                                dialogInstance.onConfirm.toPromise(),
                            ])];
                    case 2:
                        closeResult = _a.sent();
                        if (closeResult) {
                            dialogInstance.isSaving = true;
                            confirmActionResult$ = toObservable(options.onConfirm(closeResult)).pipe(map(function (actionResult) { return ({
                                confirmed: true,
                                data: actionResult,
                            }); }), tap(function () { }, function () {
                                // On error - don't close the modal - just change the saving status back to false
                                dialogInstance.isSaving = false;
                            }, function () {
                                // On complete destroy the modal
                                dialogInstance.destroy();
                            }));
                            return [2 /*return*/, confirmActionResult$.toPromise()];
                        }
                        dialogInstance.destroy();
                        return [2 /*return*/, {
                                confirmed: false,
                            }];
                }
            });
        });
    };
    return DialogsService;
}());
export { DialogsService };
