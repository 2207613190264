import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { ReportWidgetsLayoutColumnModel } from '../models/report-widgets-layout.column.model';
import { ReportRowComponent } from './report-row.component';
import { ReportWidgetDynamicComponent } from './report-widget.component';
import { ReportWidgetComponent } from './report-widget.component.base';
import { ReportWidgetsContainer } from '../models/report-widgets-container.interface';
import { flatMap } from 'lodash-es';
import { ReportWidgetsLayoutRowModel } from '../models/report-widgets-layout.row.model';

@Component({
	selector: 'report-column',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="reportColumn.rows">
			<report-row
				*ngFor="let reportRow of reportColumn.rows; trackBy: trackById"
				[reportRow]="reportRow"
				[params]="params"
				[dataOptions]="dataOptions"
				[headerLevel]="++headerLevel"
				#row
				class="dashboard-row {{ reportRow.className }}"
			>
			</report-row>
		</ng-container>
		<report-widget
			*ngFor="let widgetComponent of reportColumn.widgetComponents"
			[params]="params"
			[dataOptions]="dataOptions"
			[widgetComponentType]="widgetComponent.type"
			[ngClass]="'wcd-flex-' + widgetComponent.weight"
			[headerLevel]="headerLevel"
		></report-widget>
	`,
})
export class ReportColumnComponent implements ReportWidgetsContainer {
	@Input() reportColumn: ReportWidgetsLayoutColumnModel;
	@Input() params: { [index: string]: any };
	@Input() dataOptions: {};
	@Input() headerLevel: number = 2;

	@ViewChildren(ReportWidgetDynamicComponent) widgets: QueryList<ReportWidgetDynamicComponent>;
	@ViewChildren('row') rows: QueryList<ReportRowComponent>;

	get allWidgets(): Array<ReportWidgetComponent> {
		return this.widgets
			.toArray()
			.map((dynamicWidget: ReportWidgetDynamicComponent) => dynamicWidget.widgetComponent)
			.concat(<Array<ReportWidgetComponent>>(
				flatMap(this.rows.toArray().map((row: ReportWidgetsContainer) => row.allWidgets))
			));
	}

	trackById(index, item: ReportWidgetsLayoutRowModel) {
		return item.id;
	}
}
