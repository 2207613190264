/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "@angular/common";
import * as i5 from "./aad-user-status-display-name.component";
import * as i6 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_AadUserStatusDisplayNameComponent = [];
var RenderType_AadUserStatusDisplayNameComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AadUserStatusDisplayNameComponent, data: {} });
export { RenderType_AadUserStatusDisplayNameComponent as RenderType_AadUserStatusDisplayNameComponent };
function View_AadUserStatusDisplayNameComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "wcd-flex-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "wcd-shared-icon", [["class", "icon small-icon wcd-margin-small-right"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(3, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"], iconColor: [1, "iconColor"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(5, null, ["\n\t\t\t", "\n\t\t"]))], function (_ck, _v) { var currVal_0 = _v.context.ngIf.iconName; var currVal_1 = _v.context.ngIf.iconColor; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.i18nService.get(("aadUser_status_" + _v.context.ngIf.nameI18nKey), null, true) || _v.context.ngIf.nameI18nKey); _ck(_v, 5, 0, currVal_2); }); }
export function View_AadUserStatusDisplayNameComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AadUserStatusDisplayNameComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.userStatusType && _co.AadUserStatus[_co.userStatusType]); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AadUserStatusDisplayNameComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aad-user-status-display-name", [], null, null, null, View_AadUserStatusDisplayNameComponent_0, RenderType_AadUserStatusDisplayNameComponent)), i0.ɵdid(1, 49152, null, 0, i5.AadUserStatusDisplayNameComponent, [i6.I18nService], null, null)], null, null); }
var AadUserStatusDisplayNameComponentNgFactory = i0.ɵccf("aad-user-status-display-name", i5.AadUserStatusDisplayNameComponent, View_AadUserStatusDisplayNameComponent_Host_0, { userStatusType: "userStatusType" }, {}, []);
export { AadUserStatusDisplayNameComponentNgFactory as AadUserStatusDisplayNameComponentNgFactory };
