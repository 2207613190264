import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Control property',
	pluralName: 'Control properties',
	readonly: true,
})
export class MachineSecurityControlProperty extends ModelBase {
	@EntityField() propertyName: string;

	@EntityField() improvementScore: number;
}
