import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';
import { FeaturesService, Feature } from '@wcd/config';
import { Subscription } from 'rxjs';
import { SpinnerSize } from 'office-ui-fabric-react';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'tvm-machine-groups-filter-control',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: 'machine-groups-filter-control.component.html',
	styleUrls: ['./machine-groups-filter-control.component.scss'],
})
export class TvmMachineGroupsFilterControlComponent implements OnDestroy {
	readonly SpinnerSize = SpinnerSize;

	@Input() focusOnMachineGroup: boolean = false;

	_selectedCount: number;
	_totalCount: number;
	aria_label: string;

	private _subscription: Subscription;

	constructor(
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		private featuresService: FeaturesService,
		private changeDetgectionRef: ChangeDetectorRef,
		i18nService: I18nService
	) {
		this._subscription = machineGroupsFilterService.machineGroupsFilter$.subscribe(
			machineGroupsFilterData => {
				if (!machineGroupsFilterData) return;

				this._selectedCount = this._totalCount = machineGroupsFilterData.machineGroups.length;
				if (!machineGroupsFilterData.isFiltering) {
					this.aria_label = `${i18nService.get('tvm.machineGroupsFilter.control.header')}, ${
						this._totalCount
					} groups are available, filtering is off`;

					this.changeDetgectionRef.markForCheck();
					return;
				}

				this._selectedCount = machineGroupsFilterData.machineGroups.filter(
					mg => mg.isSelected
				).length;

				this.aria_label = `${i18nService.get('tvm.machineGroupsFilter.control.header')}, ${
					this._selectedCount
				} of ${this._totalCount} groups are selected`;

				this.changeDetgectionRef.markForCheck();
			}
		);
	}

	openMachineGroupsFilterPanel() {
		this.machineGroupsFilterService.openMachineGroupsFilterPanel();
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}
}
