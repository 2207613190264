import { Dictionary } from '@wcd/config';
import { DataEntityType } from '@microsoft/paris';
import { Severity, SeverityType } from '@wcd/domain';

const SEVERITIES = (Severity as DataEntityType<Severity>).entityConfig.values;

const SEVERITIES_BY_TYPE = Dictionary.fromList<SeverityType, Severity>(SEVERITIES, 'type');

export class SeverityTypesService {
	static getSeverityByType(severityType: SeverityType) {
		return SEVERITIES_BY_TYPE.get(severityType);
	}
}
