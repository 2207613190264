/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exposed-operating-systems.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../../projects/prettify/src/lib/pipes/pretty-number.pipe";
import * as i4 from "../../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i5 from "../../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i6 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "./exposed-operating-systems.component";
import * as i9 from "../../../services/tvm-texts.service";
import * as i10 from "@angular/router";
var styles_ExposedOperatingSystemsComponent = [i0.styles];
var RenderType_ExposedOperatingSystemsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExposedOperatingSystemsComponent, data: {} });
export { RenderType_ExposedOperatingSystemsComponent as RenderType_ExposedOperatingSystemsComponent };
function View_ExposedOperatingSystemsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t  "]))], null, null); }
function View_ExposedOperatingSystemsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToSoftwarePage(_v.parent.context.$implicit.osName) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["\n\t\t\t\t", "\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tvmTextsService.getOsPlatformLabel(_v.parent.context.$implicit.osName); _ck(_v, 2, 0, currVal_0); }); }
function View_ExposedOperatingSystemsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["\n\t\t\t\t", "\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tvmTextsService.getOsPlatformLabel(_v.parent.context.$implicit.osName); _ck(_v, 2, 0, currVal_0); }); }
function View_ExposedOperatingSystemsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExposedOperatingSystemsComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t  "])), (_l()(), i1.ɵand(0, [["softwareLink", 2]], null, 0, null, View_ExposedOperatingSystemsComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t  "])), (_l()(), i1.ɵand(0, [["softwareText", 2]], null, 0, null, View_ExposedOperatingSystemsComponent_4)), (_l()(), i1.ɵted(8, null, ["\n\n            (", " ", ")\n\t\t"])), i1.ɵpid(131072, i3.PrettyNumberPipe, [i4.PrettyNumberService, [2, i5.LocaleConfigService], [2, i1.ChangeDetectorRef]])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tvmTextsService.getOsProductName(_v.context.$implicit.osName); var currVal_1 = i1.ɵnov(_v, 5); var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_v.context.$implicit.exposedAssetsCount)); var currVal_4 = _co.buildExposedMachinesText(_v.context.$implicit.exposedAssetsCount); _ck(_v, 8, 0, currVal_3, currVal_4); }); }
function View_ExposedOperatingSystemsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t", "\n            (", " ", ")\n\t\t"])), i1.ɵppd(2, 1), i1.ɵpid(131072, i3.PrettyNumberPipe, [i4.PrettyNumberService, [2, i5.LocaleConfigService], [2, i1.ChangeDetectorRef]])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "tvm.recommendationException.justification.Other")); var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform(_co.unknownExposedOperatingSystemsCount)); var currVal_2 = _co.buildExposedMachinesText(_co.unknownExposedOperatingSystemsCount); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ExposedOperatingSystemsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.I18nPipe, [i7.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "wcd-font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["\n        ", "\n    "])), i1.ɵppd(6, 1), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExposedOperatingSystemsComponent_1)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExposedOperatingSystemsComponent_5)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.knownExposedOperatingSystemsStatistics; _ck(_v, 11, 0, currVal_1); var currVal_2 = (_co.unknownExposedOperatingSystemsCount > 0); _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), "tvm.securityRecommendation.exposedOperatingSystems.title")); _ck(_v, 5, 0, currVal_0); }); }
export function View_ExposedOperatingSystemsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "exposed-operating-systems", [], null, null, null, View_ExposedOperatingSystemsComponent_0, RenderType_ExposedOperatingSystemsComponent)), i1.ɵdid(1, 114688, null, 0, i8.ExposedOperatingSystemsComponent, [i9.TvmTextsService, i10.Router, i7.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExposedOperatingSystemsComponentNgFactory = i1.ɵccf("exposed-operating-systems", i8.ExposedOperatingSystemsComponent, View_ExposedOperatingSystemsComponent_Host_0, { exposedOperatingSystemsStatistics: "exposedOperatingSystemsStatistics" }, {}, []);
export { ExposedOperatingSystemsComponentNgFactory as ExposedOperatingSystemsComponentNgFactory };
