
		<modal (close)="cancel.emit()" [settings]="settings">
			<form
				class="wcd-full-height wcd-flex-vertical"
				data-track-component="DeclineQuarantineFiles"
				#declineForm="ngForm"
				(submit)="decline()"
			>
				<div class="wcd-flex-1 wcd-scroll-vertical dialog-contents">
					<div class="wcd-margin-bottom" style="min-width: 400px">
						<remediation-decline-reason
							[(ngModel)]="declineReason"
							name="quarantine-files-decline-reason"
						>
						</remediation-decline-reason>
					</div>
				</div>
				<footer class="dialog-footer wcd-flex-none right-text">
					<button
						type="submit"
						class="btn btn-primary"
						data-track-id="DeclineQuarantine"
						data-track-type="Button"
						[disabled]="declineForm.form.invalid || isSaving || !declineReason"
					>
						<i class="loader-icon" [hidden]="!isSaving"></i>
						{{
							isSaving
								? i18nService.strings.remediation_declineModal_pleaseWait
								: i18nService.strings.remediation_declineModal_decline
						}}
					</button>
					<button
						type="button"
						class="btn btn-cancel"
						data-track-id="Cancel"
						data-track-type="Button"
						(click)="cancel.emit()"
					>
						{{ i18nService.strings.buttons_cancel }}
					</button>
				</footer>
			</form>
		</modal>
	