<div class="wcd-full-height wcd-scroll-vertical wcd-padding-large-left wcd-padding-large-bottom"
	data-track-component="AirsNotificationRules">
	<div [hidden]="!notificationRulesStore.isLoading && !notificationRulesReportsStore.isLoading"
			 class="wcd-full-height loading-contents center-text">
		<div>
			<i class="large-loader-icon"></i>
			<div>Loading Notification Rules...</div>
		</div>
	</div>
	<div class="large-form" [hidden]="notificationRulesStore.isLoading || notificationRulesReportsStore.isLoading">
		<div class="wcd-margin-large-bottom">
			<markdown [data]="'airsNotificationRules.description' | i18n"></markdown>
		</div>
		<button class="btn btn-primary"
				data-track-id="AddRule"
				data-track-type="Button"
				[rbac]="{ permissions: ['securitySettings', 'systemSettings'], state: 'hidden' }"
				[disabled]="notificationRulesService.isEditingNotificationRule$ | async"
				(click)="notificationRulesService.showNotificationRuleEditPanel()"
				id="notification-rule-add-btn">
			 <wcd-shared-icon iconName="Add"> </wcd-shared-icon >
			New notification rule
		</button>
		<hr/>
		<div class="no-data-message wcd-flex-center-vertical"
				 [hidden]="allNotificationRules.length">
			<div class="widget-nodata-message">There are no notification rules</div>
		</div>
		<section class="form-section"
						 *ngFor="let notificationRule of allNotificationRules"
						 [ngClass]="{ disabled: notificationRule.deleting }">
			 <wcd-shared-icon [iconName]="'notificationRule'" class="form-section-icon"> </wcd-shared-icon >
			<div class="with-icon">
				<header class="form-section-header">
					<h4>
						{{notificationRule.name}}
					</h4>
				</header>
				<div>
					<div class="wcd-margin-bottom">
						<h4>Recipient Email Address{{notificationRule.emails.length > 1 ? 'es' : ''}}</h4>
						<ul class="form-list">
							<li *ngFor="let email of notificationRule.emails">
								 <wcd-shared-icon [iconName]="'email'"> </wcd-shared-icon >
								{{email}}
							</li>
						</ul>
					</div>
					<div class="wcd-margin-bottom">
						<div *ngIf="notificationRule.type.allowConditions" style="margin-top: 2rem;">
							<h4>Conditions</h4>
							<table class="wcd-table">
								<tr *ngFor="let condition of notificationRule.conditions; let isFirst = first">
									<td>
										{{isFirst ? '' : 'And'}}
										<strong>{{(condition.group$ | async)?.name}}</strong>
										is:
									</td>
									<td>
										<span
											*ngFor="let value of (condition.group$ | async)?.getValuesByIds(condition.values); let lastValue = last">
											<span
												[ngClass]="{ deleted: value.invalid }"
												class="chip color-box-neutral">{{value.name}}</span>
											<span class="chip-connector" *ngIf="!lastValue">or</span>
										</span>
									</td>
								</tr>
							</table>
						</div>
						<div *ngIf="notificationRule.type.requireSchedule" style="margin-top: 2rem;">
							<h4>Report Scheduling</h4>
							<table class="key-value-list">
								<tr>
									<td class="key">Report
										Type{{notificationRule.reportSchedule.reportTypes.length === 1 ? '' :
										's'}}
									</td>
									<td>
										<span
											*ngFor="let reportType of notificationRule.reportSchedule.reportTypeValues">
											<span class="chip color-box-primary">{{reportType.name}}</span>
										</span>
									</td>
								</tr>
								<tr>
									<td class="key">Data Period</td>
									<td class="value">Last {{notificationRule.reportSchedule.range.name}}</td>
								</tr>
								<tr>
									<td class="key">Repeats</td>
									<td class="value">{{notificationRule.reportSchedule.repeats.name}}</td>
								</tr>
								<tr *ngIf="notificationRule.reportSchedule.repeats.requireDays">
									<td class="key">Repeat On</td>
									<td>
										<span *ngFor="let day of notificationRule.reportSchedule.repeatDaysValues">
											<span class="chip color-box-primary">{{day.abbr}}</span>
										</span>
									</td>
								</tr>
								<tr>
									<td class="key">Time</td>
									<td class="value">{{notificationRule.reportSchedule.time}}</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<div class="buttons-row"
						 [rbac]="{ permissions: ['securitySettings', 'systemSettings'], state: 'hidden' }">
					<button class="btn btn-cancel"
							data-track-id="DeleteRule"
							data-track-type="Button"
							(click)="removeNotificationRule(notificationRule)"
							[attr.disabled]="notificationRule.deleting">
						 <wcd-shared-icon [iconName]="'delete'" [hidden]="notificationRule.deleting"> </wcd-shared-icon >
						<i class="loader-icon" [hidden]="!notificationRule.deleting"></i>
						{{notificationRule.deleting ? 'Deleting...' : 'Delete Rule'}}
					</button>
					<button class="btn btn-primary"
							data-track-id="EditRule"
							data-track-type="Button"
							(click)="notificationRulesService.showNotificationRuleEditPanel(notificationRule)"
							[disabled]="notificationRule.deleting || (notificationRulesService.isEditingNotificationRule$ | async)">
						 <wcd-shared-icon [iconName]="'edit'"> </wcd-shared-icon >
						Edit Rule
					</button>
				</div>
			</div>
		</section>
	</div>
</div>
