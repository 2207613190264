var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
var ɵ0 = function () { return 'compliance/alertSharing/status'; }, ɵ1 = function (item, modelData) {
    return item.complianceAlertsShareEnabled;
}, ɵ2 = function (config) { return config.data.serviceUrls.wdatpInternalApi; }, ɵ3 = function (value, data) { return data === true; };
var ComplianceAlertsShareStatus = /** @class */ (function (_super) {
    __extends(ComplianceAlertsShareStatus, _super);
    function ComplianceAlertsShareStatus() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.failed = false;
        return _this;
    }
    __decorate([
        EntityField({
            parse: ɵ3,
            defaultValue: false,
        }),
        __metadata("design:type", Boolean)
    ], ComplianceAlertsShareStatus.prototype, "complianceAlertsShareEnabled", void 0);
    ComplianceAlertsShareStatus = __decorate([
        Entity({
            singularName: 'Compliance alerts share status',
            pluralName: 'Compliance alerts share status',
            endpoint: 'compliance/alertSharing/status',
            parseItemQuery: ɵ0,
            saveMethod: 'POST',
            serializeItem: ɵ1,
            baseUrl: ɵ2,
            readonly: true,
            supportedEntityGetMethods: [EntityGetMethod.getItem],
        })
    ], ComplianceAlertsShareStatus);
    return ComplianceAlertsShareStatus;
}(EntityModelBase));
export { ComplianceAlertsShareStatus };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
