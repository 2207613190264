import { Injectable } from '@angular/core';
import { EntityModelBase } from '@microsoft/paris';
import { BehaviorSubject } from 'rxjs';
import { Alert, File, Ip, LegacyUser, Machine, Script, EntityWithContext, Url, Domain } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { TrackingEventPropertiesBase } from '../../../insights/models/tracking-event.interface';

export const detailsTabSupportedEntityTypes = {
	machine: Machine,
	alert: Alert,
	user: LegacyUser,
	process: EntityWithContext,
	file: File,
	script: Script,
	ip: Ip,
	url: Url,
	domain: Domain,
	defender: EntityWithContext
};

export interface DetailsTabDisplayEntity {
	entityType: EntityType<EntityModelBase<string>, {}, object>;
	entityTypeName: string;
	entity: EntityModelBase;
}

@Injectable()
export class AlertPageService {
	// This observable holds a map of the details tab updated entity properties, which determines the represented entity in the details tab.
	detailsTab$: BehaviorSubject<DetailsTabDisplayEntity>;
	selectedTreeElements: { [key: string]: boolean } = {};
	treeElementsUpdates$: BehaviorSubject<string>;
	mainAlertPageId$: BehaviorSubject<string>;
	processTreeJson$: BehaviorSubject<string>;
	enableTracking$: BehaviorSubject<boolean>;

	constructor(
		private globalEntityTypesService: GlobalEntityTypesService,
		private readonly appInsights: AppInsightsService
	) {
		this.detailsTab$ = new BehaviorSubject(null);
		this.mainAlertPageId$ = new BehaviorSubject('');
		this.treeElementsUpdates$ = new BehaviorSubject('');
		this.processTreeJson$ = new BehaviorSubject('');
		this.enableTracking$ = new BehaviorSubject(true);
	}

	updateMainAlertPageId(id: string) {
		this.mainAlertPageId$.next(id);
	}

	// Update the detailsTab$ so the details tab will render an updated entity.
	updateDetailsTab(
		detailsTabEntityConstructor: string,
		detailsTabEntity: EntityModelBase,
		selectedId: string = null
	) {
		if (
			detailsTabSupportedEntityTypes[detailsTabEntityConstructor] &&
			detailsTabEntity &&
			detailsTabEntity.id
		) {
			for (const key in this.selectedTreeElements) {
				this.selectedTreeElements[key] = false;
			}

			if (selectedId) {
				this.selectedTreeElements[selectedId] = true;
			}

			this.detailsTab$.next({
				entityType: this.globalEntityTypesService.getEntityType(
					detailsTabSupportedEntityTypes[detailsTabEntityConstructor]
				),
				entityTypeName: detailsTabEntityConstructor,
				entity: detailsTabEntity,
			});
		} else {
			// TODO: use app insight.
			console.log('Can not update entity in details tab.');
		}
	}

	updateTreeElement(treeElementsIds: Array<string>) {
		treeElementsIds.forEach(treeElementId => this.treeElementsUpdates$.next(treeElementId));
	}

	updateProcessTreeJson(processTreeJson: string) {
		this.processTreeJson$.next(processTreeJson);
	}

	updateTrackingStatus(enableTracking: boolean = true) {
		this.enableTracking$.next(enableTracking);
	}

	trackErrorInsight(alertId: string, correlationId: string, errorType: AlertInsightErrorType) {
		if (this.enableTracking$.value) {
			const properties = this.GetTrackProperties('Alert page error ' + errorType, alertId, correlationId, false);
			this.appInsights.track(properties);
		}
	}

	trackProcessTreeInsight(alertId: string, correlationId: string, success: boolean, measurements: { [key: string]: any }) {
		if (this.enableTracking$.value) {
			const properties = this.GetTrackProperties('Process tree elements', alertId, correlationId, success);
			this.appInsights.track(properties, measurements);
		}
	}

	trackPerformance(
		alertId: string,
		correlationId: string,
		performanceType: AlertInsightPerformanceType,
		success: boolean,
		additionalMeasurements: { [key: string]: any } = {}
	) {
		if (this.enableTracking$.value) {
			const measurements = {
				loadTime: this.getTotalLoadTime(performanceType),
				...additionalMeasurements,
			};
			const properties = this.GetTrackProperties('AlertPagePerformance' + performanceType, alertId, correlationId, success);
			this.appInsights.track(
				properties,
				measurements
			);
		}
	}

	private GetTrackProperties(id: string, alertId: string, correlationId: string, success: boolean, hasOfficeTenantPrefix: boolean = false) {
		const properties: TrackingAlertPageEventProperties = {
			component: 'AlertDetails',
			componentType: 'Page',
			id: id,
			type: TrackingEventType.Action,
			value: alertId,
			correlationId: correlationId,
			isSuccessful: success.toString()
		};
		return properties;
	}

	private getTotalLoadTime(performanceType: AlertInsightPerformanceType): number {
		const measurementName = performanceType + '-measurement';
		performance.measure(measurementName, performanceType);
		return performance.getEntriesByName(measurementName).pop().duration;
	}
}

class TrackingAlertPageEventProperties implements TrackingEventPropertiesBase {
	id: string;
	type: TrackingEventType;
	component: string;
	componentType: string;
	value: any;
	correlationId: string;
	isSuccessful: string;
}

export enum AlertInsightPerformanceType {
	InitiatingAlert = 'InitiatingAlertMs',
	RelatedMachine = 'RelatedMachineMs',
	RelatedUserFromProcessTree = 'RelatedUserFromProcessTreeMs',
	RelatedUserFromAlert = 'RelatedUserFromAlertMs',
	ProcessTree = 'ProcessTreeMs',
	ProcessTreeRendering = 'ProcessTreeRenderingMs',
	InitiatingOfficeTenantPrefix = 'InitiatingOfficeTenantPrefixMs',
}

export enum AlertInsightErrorType {
	NoRelatedAlert = 'noRelatedAlert',
	NoProcessTreeCreate = 'noProcessTreeCreated',
	CyberEntityNotFound = 'cyberEntityNotFound',
	OfficeIntegrationError = 'officeIntegrationError',
	Other = 'other',
}
