import { Routes } from '@angular/router';
import { AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard } from '@wcd/auth';
import { SuppressionRule, MdeUserRoleActionEnum } from '@wcd/domain';
import { BreadcrumbsResolver } from '../../breadcrumbs/services/breadcrumbs-resolver.service';
import { EntityComponent } from '../../global_entities/components/entity.component';
import { EntityResolver } from '../../global_entities/services/entity.resolver';
import { EntityPageViewMode } from '../../global_entities/models/entity-page-view-mode.enum';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SuppressionRulesEntityTypeService } from './services/suppression-rules.entity-type.service';
import { HistorySafeRedirectComponent } from '../../shared/components/history-safe-redirect.component';
import { SuppressionRuleAlertsComponent } from './components/suppression-rule.alerts.component';
import { SuppressionRuleConditionsComponent } from './components/suppression-rule.conditions.component';
var ɵ0 = {
    entity: SuppressionRule,
    breadcrumbsConfig: {
        addParentWhenEmpty: true,
        pageMode: EntityPageViewMode.Default,
        itemConfig: {
            icon: 'suppressionRule',
            idFieldName: 'suppressionRuleId',
        },
        parentItemConfig: {
            id: "suppressionRules",
            labelKey: 'suppressionRules_pageTitle',
            icon: 'suppressionRule',
            url: '/preferences2/alert_suppression',
        },
    },
    pageTitle: 'Suppression Rule',
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ1 = {
    pageTitle: 'Associated alerts',
    pageTitleKey: 'suppressionRules_tabs_alerts',
}, ɵ2 = {
    pageTitle: 'Rule conditions',
    pageTitleKey: 'suppressionRules_tabs_conditions',
}, ɵ3 = {
    redirectTo: 'alerts'
};
export var suppressionRulesRoutes = [
    {
        path: 'suppressionRule/:id',
        component: EntityComponent,
        canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
        resolve: {
            entity: EntityResolver,
            breadcrumbs: BreadcrumbsResolver,
        },
        data: ɵ0,
        children: [
            {
                path: 'alerts',
                component: SuppressionRuleAlertsComponent,
                data: ɵ1,
            },
            {
                path: 'conditions',
                component: SuppressionRuleConditionsComponent,
                data: ɵ2,
            },
            {
                path: '',
                component: HistorySafeRedirectComponent,
                data: ɵ3,
                pathMatch: 'full',
            },
        ],
    },
];
var SuppressionRulesModule = /** @class */ (function () {
    function SuppressionRulesModule(globalEntityTypesService) {
        globalEntityTypesService.registerEntityType(SuppressionRulesEntityTypeService);
    }
    return SuppressionRulesModule;
}());
export { SuppressionRulesModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
