import { SevilleModule } from '../../../seville/seville.module';
import { config } from '@wcd/shared';
import { FeaturesService, Feature } from '@wcd/config';
import { HybridRoutingService } from '../../../../../hybrid-routing.service';
import { IpsService } from '../../../../../@entities/ips/services/ips.service';
import { UrlsService } from '../../../../../@entities/urls/services/urls.service';
import { FilesService } from '../../../../../@entities/files/services/files.service';

SevilleModule.directive('processTree', processTreeDirective);

processTreeDirective.$inject = ['featuresService', 'ng2router', 'ipsService', 'urlsService', 'filesService'];

function processTreeDirective(
	featuresService: FeaturesService,
	ng2router: HybridRoutingService,
	ipsService: IpsService,
	urlsService: UrlsService,
	filesService: FilesService
) {
	return {
		restrict: 'EA',
		template: `
		<div ng-class="::{'col-md-offset-1' : processtree.showtime}">
        <div ng-repeat="leaf in processtree.graph.children">
            <div ng-include src="'/processtreelevel.html'"></div>
        </div>
    </div>

    <script type="text/ng-template" id="/processtreelevel.html">
        <div>
            <table class="timeline-graph-border">
                <tr class="event-timeline-section timeline-graph-row">
                    <td class="timeline-cube-cell">
                        <div class="overflow-all col-md-1 timeline-graph-cube">
                            <div ng-if="::leaf.multipleFiles" class="event-timeline-count">{{::leaf.filesAndSha1Count}}</div>
                            <div ng-if="::leaf.multipleIps" class="event-timeline-count">{{::leaf.destinationIpsCount}}</div>
                            <i class="timeline-graph-icon mdl2-icons icon" ng-class="::leaf.icon"></i>
                            <div>
                                <p class="timeline-cube-name white-space-word" data-ellipsis ng-if="::leaf.name" ellipsis-separator="" ellipsis-separator-reg="true" ng-bind="leaf.name"></p>
                                <p class="timeline-cube-name overflow-all" data-ellipsis ng-if="::leaf.ip" ellipsis-separator="" ellipsis-separator-reg="true" ng-bind="leaf.ip"></p>
                                <p class="timeline-cube-name overflow-all" data-ellipsis ng-if="::leaf.type == 'logon'" ellipsis-separator="" ellipsis-separator-reg="true" ng-bind="leaf.remoteIp || 'Unknown'"></p>
                            </div>
                            <!--draw lines between process tree nodes-->
                            <div ng-if="::leaf.notDirectChild">
                                <!--box with description for indirect child node-->
                                <div class="timeline-remote-thread-icon"></div>
                                <div class="timeline-remote-thread-line">{{::leaf.remoteText}}</div>
                                <div ng-if="::leaf.children.length == 1" class="timeline-graph-arrow-single-not-direct">
                                    <i class="icon icon-ChevronRight timeline-graph-arrow-ending-not-direct"></i>
                                </div>
                            </div>
                            <div ng-if="::leaf.children.length == 1 && !leaf.notDirectChild" class="timeline-graph-arrow-single">
                                <i class="icon icon-ChevronRight timeline-graph-arrow-ending"></i>
                            </div>
                        </div>
                    </td>
                    <td class="timeline-processtree-cell">
                        <div ng-if="!leaf.multipleFiles && leaf.type == 'file'" class="timeline-graph files-row">
                            <div ng-if="leaf.lastChild && leaf.reportIndexCount > 1"><b>Distinct Sha1 and Filename, may have multiple paths</b></div>
                            <div ng-if="::!leaf.processTreeNodeContentHeader && !leaf.processTreeNodeContent" class="timeline-graph-record">{{::leaf.name}}</div>
                            <div ng-if="leaf.processTreeNodeContentHeader" class="timeline-graph-record">
                                <i ng-if="leaf.processTreeNodeContentHeaderIcon" class="icon machine-timeline-icon" ng-class="::leaf.processTreeNodeContentHeaderIcon"></i>
                                <side-pane-event raw-event="::leaf" hide-indicator="::!leaf.actionTime" ng-if="::leaf.actionTime && leaf.EventPropertiesAsJson && leaf.EventPropertiesAsJson.TokenModificationProperties" />
                                <span>{{::leaf.processTreeNodeContentHeader}}</span>
                            </div>
                            <div ng-if="::!leaf.EtwEventShowNodeContentOnly">
                  <!--Sha-->
                                <div class="timeline-graph-record" ng-if="::leaf.sha1">
                    <img class="sha-svg" ng-src="{{processtree.sha1IconAddress}}" />
                                    <side-pane-event raw-event="::leaf" hide-indicator="::!leaf.actionTime" ng-if="::leaf.actionTime" />
                                    <side-pane-file sha1="::leaf.sha1" filename="::leaf.name" hide-indicator="::!leaf.valid" ng-if="::!leaf.actionTime" /> <!-- default to file side pane -->
                                    <span ng-if="::leaf.valid">
										<a href="{{::processtree.getFileLink(leaf.sha1)}}"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
                                           ng-click="processtree.preserveDateOnState($event, 'file', processtree.jumpToDate)">
                                            <span>{{::leaf.sha1}}</span>
                                        </a>
                                    </span>
                                    <span ng-if="::!leaf.valid">{{leaf.sha1}}</span>
                                </div>
                  <!--Signer-->
                  <div>
                                    <span ng-if="::leaf.isCertificateDataPopulated && leaf.isValidCertificate !== null && leaf.signer && leaf.valid">
                                        <i class="icon machine-timeline-icon icon-Certificate" ng-if="::leaf.isValidCertificate === true"></i>
                                        <i class="icon machine-timeline-icon-invalid icon-Certificate" ng-if="::leaf.isValidCertificate === false"></i>
                                        <span ng-if="::leaf.isCertificateDataPopulated && leaf.isValidCertificate !== null && leaf.signer && leaf.valid">Signer: {{ ::leaf.signer }}</span>
                                    </span>
                    <span ng-if="::leaf.isCertificateDataPopulated && (!leaf.signer || leaf.isValidCertificate == null)">
                                        <i class="icon machine-timeline-icon icon-BlockedSite"></i>
                                        <span>Unsigned file</span>
                                    </span>
                  </div>
                                <div class="timeline-graph-record" ng-if="::leaf.path">
                                    <span class="overflow-all">
                                        <i class="icon machine-timeline-icon flip-vertical icon-ExitRight"></i>
                                        {{::leaf.path}}
                                    </span>
                                </div>
                                <div class="timeline-graph-record" ng-if="::leaf.Uri">
                                    <i class="icon machine-timeline-icon icon-Link"></i>
                                    <side-pane-url url="::leaf.Uri" ng-if="::!leaf.EtwEventAvoidUrlSidePane"/>
                                    <side-pane-event raw-event="::leaf" ng-if="::leaf.actionTime && leaf.EtwEventAvoidUrlSidePane" />
                                    <a ng-if="::leaf.Uri.length > 60"
                                    		data-track-id="GoToUrl"
											data-track-type="Navigation"
										href="{{::processtree.getUrlLink(leaf.Uri)}}"
										ng-click="processtree.goToUrl(leaf.Uri, $event)"
                                        tooltips tooltip-side="left" tooltip-title="{{::leaf.Uri}}">{{::leaf.Uri | limitTo:60}}...</a>
                                    <a ng-if="::leaf.Uri.length <= 60"
                                    	data-track-id="GoToUrl"
										data-track-type="Navigation"
                                    	href="{{::processtree.getUrlLink(leaf.Uri)}}"
										ng-click="processtree.goToUrl(leaf.Uri, $event)">{{::leaf.Uri }}</a>
                                </div>
                            </div>
                            <div ng-repeat="contentRow in leaf.processTreeNodeContent" class="timeline-graph-record"
                                 ng-if="::leaf.processTreeNodeContent && contentRow.data !== null">
                                <i ng-if="::contentRow.icon" class="icon machine-timeline-icon" ng-class="::contentRow.icon"></i>
                                <side-pane-event raw-event="::leaf" hide-indicator="::!leaf.actionTime" ng-if="::leaf.actionTime && contentRow.sidePaneCondition" />
                                <span>{{::contentRow.data}}</span>
                                <span ng-if=":: contentRow.Ip">
                                    <side-pane-ip ip="::contentRow.Ip"/>
									<a href="{{::processtree.getIpLink(contentRow.Ip)}}"
										ng-click="processtree.goToIp(contentRow.Ip, $event)"
                                    	data-track-id="GoToIp"
										data-track-type="Navigation">{{::contentRow.Ip}}</a><span ng-if=":: contentRow.Port">:{{::contentRow.Port}}</span>
                                </span>
                            </div>
                            <div class="timeline-graph-record" ng-if="::leaf.threat">
                                <i class="icon machine-timeline-icon icon-Bug"></i>
                                {{::leaf.threat}}
                            </div>
                            <div class="timeline-graph-record" ng-if="::leaf.encyclopediaLink">
                                <i class="icon machine-timeline-icon icon-ReadingMode"></i>
                                <a target="_blank"
                                	data-track-id="MicrosoftEncyclopediaReadMore"
									data-track-type="ExternalLink"
                                	href="{{::leaf.encyclopediaLink}}">Read more on Microsoft Encyclopedia</a>
                            </div>
                            <div class="timeline-graph-record overflow-all" ng-if="::leaf.command">
                                <span class="overflow-all">
                                    <i class="icon machine-timeline-icon icon-CommandPrompt"></i>
                                    <span ng-if="::leaf.actionTime">{{ ::leaf.command | limitTo: 500 }} {{::leaf.command.length < 500 ? '' : '...'}}</span>
                                    <span ng-if="::!leaf.actionTime">{{ ::leaf.command }}</span>
                                </span>
                            </div>
                            <div class="timeline-graph-record" ng-if="::leaf.o365MailSender">
                                <span class="overflow-all"><b>Sender:</b> {{::leaf.o365MailSender}}</span>
                            </div>
                            <div class="timeline-graph-record" ng-if="::leaf.o365MailSubject">
                                <span class="overflow-all"><b>Subject:</b> {{::leaf.o365MailSubject}}</span>
                            </div>
                            <div class="timeline-graph-record" ng-if="::leaf.o365MailRecipients">
                                <span class="overflow-all"><b>Recipients:</b> {{::leaf.o365MailRecipients}}</span>
                            </div>
                            <div class="timeline-graph-record" ng-if="::leaf.o365MailReceived">
                                <span class="overflow-all"><b>Received:</b> <date src="::leaf.o365MailReceived"></date></span>
                            </div>
                            <a class="icon-container" ng-if="::leaf.o365MailDeepLink"
                            	data-track-id="O365MailDeepLink"
								data-track-type="ExternalLink"
                            	target="_blank" href="{{::leaf.o365MailDeepLink}}">
                                <i class="icon icon-Info" /> More details on this email on o365 <br />
                            </a>
                            <div>
								<a class="icon-container"
									href="{{::processtree.getUrlLink(leaf.o365MailIoAIdentifier)}}"
									ng-click="processtree.goToUrl(leaf.o365MailIoAIdentifier, $event)"
                                	data-track-id="GoToUrl"
									data-track-type="Navigation"
                                	ng-if="::leaf.o365MailIoAIdentifier && leaf.o365MailIoAType == 'Url'">
                                    <i class="icon icon-Info" /> {{::leaf.o365MailIoAIdentifier}}
                                </a>
                            </div>
                            <div>
                                <side-pane-file sha1="::leaf.o365MailIoAIdentifier" filename="::leaf.name" ng-if="::leaf.o365MailIoAIdentifier && leaf.o365MailIoAType == 'File'" />
								<a class="icon-container"
									href="{{::processtree.getFileLink(leaf.o365MailIoAIdentifier)}}"
                                	data-track-id="GoToFile"
									data-track-type="Navigation"
                                	ng-if="::leaf.o365MailIoAIdentifier && leaf.o365MailIoAType == 'File'">
                                    <i class="icon icon-Info" /> {{::leaf.o365MailIoAIdentifier}}
                                </a>
                            </div>
                        </div>

                        <div ng-if="::leaf.multipleFiles && leaf.type == 'file'" class="files-row">
                            <table class="event-timeline-files-container">
                                <tr>
                                    <td class="machine-timeline-expand-files machine-timeline-expand-files-title">
                                        <div class="processtree-files-title"><b>Distinct Sha1 and Filename, may have multiple paths</b></div>

                                        <span ng-repeat="fileAndSha1 in leaf.filesAndSha1 | objectLimitTo: leaf.morefilenames ? leaf.filesAndSha1.lenght : 15">
                                            <span ng-if="::alertProcessTree.isValidSha1(fileAndSha1.Sha1)">
                                                <side-pane-file sha1="::fileAndSha1.Sha1" filename="::fileAndSha1.FileName"/>
												<a href="{{::processtree.getFileLink(fileAndSha1.Sha1, fileAndSha1.FileName)}}"
                                                	data-track-id="GoToFile"
													data-track-type="Navigation"
                                                   ng-click="processtree.preserveDateOnState($event, 'file', processtree.jumpToDate)">{{ ::fileAndSha1.FileName }}</a>
                                                <span ng-if="!$last">,&nbsp;</span>
                                            </span>
                                            <span ng-if="::!alertProcessTree.isValidSha1(fileAndSha1.Sha1)">
                                                {{ ::fileAndSha1.FileName }}<span ng-if="!$last">, </span>
                                            </span>
                                        </span>
                                        <div class="timeline-graph-record" ng-if="::leaf.o365MailSender">
                                            <span class="overflow-all"><b>Sender:</b> {{::leaf.o365MailSender}}</span>
                                        </div>
                                        <div class="timeline-graph-record" ng-if="::leaf.o365MailSubject">
                                            <span class="overflow-all"><b>Subject:</b> {{::leaf.o365MailSubject}}</span>
                                        </div>
                                        <div class="timeline-graph-record" ng-if="::leaf.o365MailRecipients">
                                            <span class="overflow-all"><b>Recipients:</b> {{::leaf.o365MailRecipients}}</span>
                                        </div>
                                        <div class="timeline-graph-record" ng-if="::leaf.o365MailReceived">
                                            <span class="overflow-all"><b> Received:</b> <date src="::leaf.o365MailReceived"></date> </span>
                                        </div>
                                        <a class="icon-container" ng-if="::leaf.o365MailDeepLink"
                                        	data-track-id="O365MailDeepLink"
											data-track-type="ExternalLink"
                                        	target="_blank" href="{{::leaf.o365MailDeepLink}}">
                                            <i class="icon icon-Info" /> More details on this email on o365 <br />
                                        </a>
                                        <div>
											<a class="icon-container"
												href="{{::processtree.getUrlLink(leaf.o365MailIoAIdentifier)}}"
												ng-click="processtree.goToUrl(leaf.o365MailIoAIdentifier, $event)"
                                            	data-track-id="GoToUrl"
												data-track-type="Navigation"
                                            	ng-if="::leaf.o365MailIoAIdentifier && leaf.o365MailIoAType == 'Url'">
                                                <i class="icon icon-Info" /> {{::leaf.o365MailIoAIdentifier}}
                                            </a>
                                        </div>
                                        <div ng-if="::leaf.o365MailIoAIdentifier && leaf.o365MailIoAType == 'File'">
                                            <side-pane-file sha1="::leaf.o365MailIoAIdentifier" filename="::leaf.name"/>
                                            <a class="icon-container"
                                            	data-track-id="GoToFile"
												data-track-type="Navigation"
												href="{{::processtree.getFileLink(leaf.o365MailIoAIdentifier)}}">
                                                <i class="icon icon-Info" /> {{::leaf.o365MailIoAIdentifier}}
                                            </a>
                                        </div>
                                        <div>
                                            <button ng-if="::leaf.filesAndSha1Count > 15" class="btn btn-link dropdown-toggle timeline-processtree-button" data-toggle="dropdown" aria-expanded="true" type="button" ng-click="leaf.morefilenames = !leaf.morefilenames">
                                                <span>{{processtree.info(leaf.morefilenames, leaf.filesAndSha1Count, leaf.type)}}</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div ng-if="::leaf.multipleIps && leaf.type == 'ip'" class="files-row">
                            <table class="event-timeline-files-container">
                                <tr>
                                    <td class="machine-timeline-expand-files">
                                        <span ng-repeat="ip in leaf.destinationIps | objectLimitTo: leaf.moreIps ? destinationIpsCount : 15">
                                            <side-pane-ip ip="::ip" />
											<a href="{{::processtree.getIpLink(ip)}}"
                                            	data-track-id="GoToIp"
												data-track-type="Navigation"
                                            	ng-click="processtree.preserveDateOnState($event, 'ip', processtree.jumpToDate)">{{ ip }}</a><span ng-if="!$last">, </span>
                                        </span>
                                        <div>
                                            <button ng-if="::leaf.destinationIpsCount > 15" class="btn btn-link dropdown-toggle timeline-processtree-button" data-toggle="dropdown" aria-expanded="true" type="button" ng-click="leaf.moreIps = !leaf.moreIps">
                                                <span>{{processtree.info(leaf.moreIps, leaf.destinationIpsCount, leaf.type)}}</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div ng-if="::!leaf.multipleIps  && leaf.type == 'ip'" class="files-row">
                            <div class="timeline-graph-record">
                                <side-pane-event raw-event="::leaf" hide-indicator="::!leaf.actionTime" ng-if="::leaf.actionTime" />
                                <side-pane-ip ip="::leaf.ip" ng-if="::!leaf.actionTime" />
								<a href="{{::processtree.getIpLink(leaf.ip)}}"
                                	data-track-id="GoToIp"
									data-track-type="Navigation"
                                	ng-click="processtree.preserveDateOnState($event, 'ip', processtree.jumpToDate)">{{ leaf.ip }}</a><span ng-if="leaf.destinationPort">:{{::leaf.destinationPort}}</span>
                                <span ng-if="leaf.destinationDnsRecordName">({{::leaf.destinationDnsRecordName}})</span>
                            </div>
                        </div>

                        <div ng-if="::leaf.type == 'registry'" class="files-row">
                            <table>
                                <tr>
                                    <td class="timeline-processtree-cell">
                                        <div class="timeline-graph-record" ng-if="::leaf.key">
                                            <span class="overflow-all">
                                                <i class="icon machine-timeline-icon icon-Folder"></i>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="timeline-graph-record">
                                            <side-pane-event raw-event="leaf" hide-indicator="::!leaf.actionTime" />
                                            <span class="overflow-all">{{::leaf.key}}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="timeline-processtree-cell">
                                        <div class="timeline-graph-record" ng-if="::leaf.key">
                                            <span class="overflow-all">
                                                <i class="icon machine-timeline-icon icon-OEM"></i>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="timeline-graph-record overflow-all">Registry operation: {{::leaf.readableOperation}}</div>
                                    </td>
                                </tr>
                                <tr ng-if="::leaf.registryOperation=='RenameKey'">
                                    <td class="timeline-processtree-cell">
                                        <div class="timeline-graph-record" ng-if="::leaf.key">
                                            <span class="overflow-all">
                                                <i class="icon machine-timeline-icon icon-Permissions"></i>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="timeline-graph-record overflow-all">Previous key: {{::leaf.previousKey}}</div>
                                    </td>
                                </tr>
                                <tr ng-if="::(leaf.registryOperation=='SetValue' || leaf.registryOperation=='DeleteValue')">
                                    <td class="timeline-processtree-cell">
                                        <div class="timeline-graph-record" ng-if="::leaf.valueName">
                                            <span class="overflow-all">
                                                <i class="icon machine-timeline-icon icon-Permissions"></i>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="timeline-graph-record overflow-all">Value name: {{::leaf.valueName}}</div>
                                    </td>
                                </tr>
                                <tr ng-if="::((leaf.registryOperation=='SetValue' && leaf.valueType !== 'Binary') || leaf.registryOperation=='DeleteValue')">
                                    <td class="timeline-processtree-cell">
                                        <div class="timeline-graph-record" ng-if="::leaf.key">
                                            <span class="overflow-all">
                                                <i class="icon machine-timeline-icon icon-Permissions"></i>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="timeline-graph-record overflow-all">Value data: {{::leaf.valueData}}</div>
                                    </td>
                                </tr>
                                <tr ng-if="::(leaf.registryOperation=='SetValue' && leaf.valueType != 'Binary')">
                                    <td class="timeline-processtree-cell">
                                        <div class="timeline-graph-record" ng-if="::leaf.key">
                                            <span class="overflow-all">
                                                <i class="icon machine-timeline-icon icon-Permissions"></i>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="timeline-graph-record overflow-all">Previous value data: {{::leaf.previousValueData}}</div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div ng-if="::leaf.type == 'logon'" class="files-row">
                            <div ng-if="::leaf.remoteComputerName" class="timeline-graph-record">
                                <i class="icon machine-timeline-icon icon-DeviceLaptopNoPic"></i>
                                Source Device:
                                <span>{{:: leaf.remoteComputerName | parts : 1 }}</span>
                            </div>
                            <div ng-if="::leaf.remotePort" class="timeline-graph-record">
                                <span>&nbsp;</span>Logon Port: {{::leaf.remotePort}}
                            </div>
                        <div>
                    </td>
                </tr>
                <tr ng-if="::leaf.children && leaf.children.length > 0">
                    <td class="timeline-graph-cube timeline-graph-arrow-container">
                        <div ng-if="::leaf.children.length > 1" ng-repeat="child in leaf.children">
                            <div class="timeline-graph-arrow-multiple">
                                <i class="icon icon-ChevronRight timeline-graph-arrow-ending-multiple"></i>
                            </div>
                            <div ng-if="::!$last" class="timeline-graph-arrow-buffer"></div>
                        </div>
                    </td>
                    <td>
                        <div ng-repeat="leaf in leaf.children">
                            <div ng-include src="'/processtreelevel.html'"></div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </script>
		`,
		controllerAs: 'processtree',
		bindToController: true,
		scope: {
			graph: '=',
			showtime: '=',
			jumpToDate: '=',
		},
		controller: [
			'$scope',
			'$http',
			'$state',
			function($scope, $http, $state) {
				var vm = this;

				vm.sha1IconAddress = config.images.sha1;

				vm.preserveDateOnState = function($event, state, date) {
					$event.stopPropagation();
					if (!$state.get(state).data) {
						$state.get(state).data = {};
					}

					$state.get(state).data.slider = new Date(date);
				};

				vm.info = function(item, numOfItems, type) {
					var items = null;
					// calculate how many more items to show
					var additionalItems = numOfItems - 15;

					if (type == 'ip') {
						items = additionalItems == 1 ? 'ip' : 'ips';
					} else {
						items = additionalItems == 1 ? 'file' : 'files';
					}

					if (item) {
						return 'Click here to view top 15 ' + items;
					}
					return 'Show ' + additionalItems + ' more ' + items;
				};

				vm.getIpLink = ipId => {
					if (featuresService.isEnabled(Feature.UpgradeIpPage)) {
						return ipsService.getIpLink(ipId);
					} else {
						return $state.href('ip', {
							id: ipId,
						});
					}
				};

				vm.getFileLink = (fileId, fileName?) => filesService.getFileLink(fileId, null, fileName);

				vm.goToIp = (ipId, $event) => {
					$event.preventDefault();
					$event.stopPropagation();

					if (featuresService.isEnabled(Feature.UpgradeIpPage)) {
						ng2router.navigateByUrl(vm.getIpLink(ipId));
					} else {
						$state.go('ip', {
							id: ipId,
						});
					}
				};

				vm.getUrlLink = urlId => {
					if (featuresService.isEnabled(Feature.UpgradeUrlPage)) {
						return urlsService.getUrlLink(urlId);
					} else {
						return $state.href('url', {
							id: urlId,
						});
					}
				};

				vm.goToUrl = (urlId, $event) => {
					$event.preventDefault();
					$event.stopPropagation();

					if (featuresService.isEnabled(Feature.UpgradeUrlPage)) {
						ng2router.navigateByUrl(vm.getUrlLink(urlId));
					} else {
						$state.go('url', {
							id: urlId,
						});
					}
				};
			},
		],
	};
}
