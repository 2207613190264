import { ModuleWithProviders, NgModule } from '@angular/core';
import { RemediationModule } from '../remediation/remediation.module';
import { CommentsModule } from '../../comments/comments.module';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { SharedModule } from '../../shared/shared.module';
import { InvestigationActionsService } from './services/investigation-actions.service';
import { InvestigationActionEntityPanelComponent } from './components/investigation-action.entity-panel.component';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { InvestigationActionEntityTypeService } from './services/investigation-action.entity-type.service';
import { InvestigationActionCategories } from './services/investigation-action-categories.service';
import { PanelsModule } from '@wcd/panels';
import { ExpanderModule } from '@wcd/expander';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';

@NgModule({
	imports: [
		SharedModule,
		RemediationModule,
		CommentsModule,
		DataViewsModule,
		PanelsModule,
		ExpanderModule,
		GlobalEntitiesModule,
	],
	declarations: [InvestigationActionEntityPanelComponent],
	exports: [],
	entryComponents: [InvestigationActionEntityPanelComponent],
})
export class InvestigationActionsModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(InvestigationActionEntityTypeService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: InvestigationActionsModule,
			providers: [
				InvestigationActionsService,
				InvestigationActionEntityTypeService,
				InvestigationActionCategories,
			],
		};
	}
}
