import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityTypeConfig } from '../airs_entity/airs-entity-type-config.entity';
import { DirectoryType } from './directory-type.entity';
import { directoryTypes } from './directory-type.values';
import { keyBy } from 'lodash-es';
import { MailClusterUtils } from '../mailbox/mail-cluster.utils';
import { dateStrToUtc } from '../utils';

const dirTypesByType = keyBy(directoryTypes, 'type');

@ValueObject({
	singularName: 'Entity',
	pluralName: 'Entities',
	readonly: true,
})
export class ActionHistoryRelatedEntity extends ModelBase {
	@EntityField({ data: 'EntityType' })
	entityType: AirsEntityTypeConfig;

	@EntityField({ data: 'EntityId' })
	entityId: number | string;

	@EntityField({ data: 'SHA1' })
	sha1: string;

	@EntityField({ data: 'SHA256' })
	sha256: string;

	@EntityField({ data: 'FileName' })
	fileName: string;

	@EntityField({ data: 'FolderPath' })
	folderPath: string;

	@EntityField({
		data: 'FolderType',
		parse: folderType => {
			const folderTypeId = folderType && folderType.toLowerCase();
			return dirTypesByType[folderTypeId];
		},
	})
	folderType: DirectoryType;

	@EntityField({ data: 'CompanyName' })
	companyName: string;

	@EntityField({ data: 'FileSizeBytes' })
	fileSize: number;

	@EntityField({ data: 'IsSigned' })
	isSigned: boolean;

	@EntityField({ data: 'ProcessId' })
	processId: number;

	@EntityField({ data: 'ProcessCommandLine' })
	processCommandLine: string;

	@EntityField({ data: 'ProcessCreationTime', parse: dateStr => dateStrToUtc(dateStr) })
	processCreationTime: Date;

	@EntityField({ data: 'ServiceName' })
	serviceName: string;

	@EntityField({ data: 'ServiceDisplayName' })
	serviceDisplayName: string;

	@EntityField({ data: 'ServiceBinPath' })
	serviceBinPath: string;

	@EntityField({ data: 'DriverName' })
	driverName: string;

	@EntityField({ data: 'DriverDisplayName' })
	driverDisplayName: string;

	@EntityField({ data: 'DriverBinPath' })
	driverBinPath: string;

	@EntityField({ data: 'DriverType' })
	driverType: string;

	@EntityField({ data: 'MethodName' })
	methodName: string;

	@EntityField({ data: 'MethodCommandLine' })
	methodCommandLine: string;

	// user account actions

	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'NTDomain' })
	ntDomain: string;

	@EntityField({ data: 'DnsDomain' })
	dnsDomain: string;

	@EntityField({ data: 'UPNSuffix' })
	upnSuffix: string;

	@EntityField({ data: 'AadTenantId' })
	aadTenantId: string;

	@EntityField({ data: 'IsDomainJoined' })
	isDomainJoined: boolean;

	@EntityField({ data: 'PUID' })
	puid: string;

	@EntityField({ data: 'AadUserId' })
	aadUserId: string;

	@EntityField({ data: 'DisplayName' })
	userDisplayName: string;

	@EntityField({ data: 'FriendlyName' })
	friendlyName: string;

	@EntityField({ data: 'UniqueName' })
	uniqueName: string;

	@EntityField({ data: 'Email' })
	email: string;

	@EntityField({ data: 'Sid' })
	sid: string;

	// email actions
	@EntityField({ data: 'ReceivedDate' })
	receivedDate: Date;

	@EntityField({ data: 'DeliveryAction' })
	deliveryAction: string;

	@EntityField({ data: ['SenderFromMailAddress', 'Sender'] })
	senderFromMailAddress: string;

	@EntityField({ data: 'P1Sender' })
	p1SenderFromMailAddress: string;

	@EntityField({ data: ['RecipientEmailAddress', 'Recipient'] })
	recipientEmailAddress: string;

	@EntityField({ data: 'ClusterBy' })
	clusteredBy: string;

	@EntityField({ data: 'ClusterByValue' })
	clusteredByValue: string;

	@EntityField({ data: 'MailboxPrimaryAddress' })
	mailboxPrimaryAddress: string;

	@EntityField({ data: 'Url' })
	url: string;

	@EntityField({ data: 'NetworkMessageId' })
	networkMessageId: string;

	@EntityField({ data: 'Query' })
	clusterQueryString: string;

	@EntityField({ data: 'QueryStartTime' })
	clusterQueryStartTime: string;

	@EntityField({ data: 'QueryTime' })
	clusterQueryTime: string;

	displayQuery: string;

	constructor(props) {
		super(props);
		this.displayQuery = MailClusterUtils.getDisplayQuery(this.clusteredBy, this.clusteredByValue);
	}
}
