var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DataQuerySortDirection, EntityField, EntityModelBase } from '@microsoft/paris';
import { RemediationActionType } from '../remediation/remediation-action-type.entity';
import { Machine } from '../machine/machine.entity';
import { ActionHistoryRelatedEntity } from './action-history-related-entities.value-object';
import { InvestigationActionStatus } from '../investigation/actions/investigation-action-status.entity';
import { InvestigatedUser } from '../investigation/investigated-identity.value-object';
import { isEqual, omit, uniqBy } from 'lodash-es';
import { AirsEntityTypeConfig } from './../airs_entity/airs-entity-type-config.entity';
import { OfficeUtils } from '../utils/office-utils';
import { AirsEntityType } from '../airs_entity/airs-entity-type.entity';
import { dateStrToUtc } from '../utils';
var CRYPTO = window.crypto || window.msCrypto; // for IE 11
// data retention in office for investigation is 30 days
var OFFICE_INVESTIGATION_DATA_RETENTION = 30;
var ɵ0 = function (dateStr) { return dateStrToUtc(dateStr); }, ɵ1 = function (dateStr) { return dateStrToUtc(dateStr); }, ɵ2 = function (dateStr) { return dateStrToUtc(dateStr); }, ɵ3 = function (investigationId, rawData) {
    if (checkMcasOrMdiAction(rawData.ActionSource, rawData.Product)) {
        return null;
    }
    else if (rawData['AdditionalFields'] &&
        rawData['AdditionalFields']['add_liveresponse_prefix']) {
        return "LR" + investigationId;
    }
    return investigationId;
}, ɵ4 = function (additionalFields) { return additionalFields && additionalFields['add_liveresponse_prefix']; }, ɵ5 = function (investigationDeeplink, rawData) {
    return !checkMcasOrMdiAction(rawData.ActionSource, rawData.Product) && !!investigationDeeplink;
}, ɵ6 = function (investigationType) { return investigationType === 'Manual Investigation'; }, ɵ7 = function (machineData) {
    return machineData && {
        MachineId: machineData.MachineId,
        SenseMachineId: machineData.MachineId,
        ComputerDnsName: machineData.ComputerName,
    };
}, ɵ8 = function (relatedEntities, rawData) {
    // TODO: revert to old parsing once BE fix is deployed
    var additionalEntities = rawData['AdditionalFields'] && rawData['AdditionalFields']['additional_entities'];
    var objs;
    try {
        if (!(additionalEntities && Object.keys(additionalEntities).length)) {
            objs = relatedEntities;
        }
        else {
            objs = Object.entries(additionalEntities).reduce(function (acc, _a) {
                var entityId = _a[0], entity = _a[1];
                var entityType = entity['EntityType'];
                if (typeof entityType === 'string') {
                    entityType = ENTITY_TYPE_MAP[entityType.toLowerCase()];
                }
                if (relatedEntities.find(function (e) { return e['EntityType'] === entityType; })) {
                    var entityId_1 = entityType === AirsEntityType.Account
                        ? entity['Sid'] || entity['AadUserId']
                        : entity['EntityIdUrn'];
                    acc.push(Object.assign({ EntityId: entityId_1 }, entity, {
                        EntityType: entityType,
                    }));
                }
                return acc;
            }, []);
        }
    }
    catch (e) {
        objs = relatedEntities;
    }
    return uniqBy(objs, isEqual);
}, ɵ9 = function (actionSource) {
    if (actionSource === 'Advanced hunting') {
        return ActionSourceType.AdvancedHunting;
    }
    else if (actionSource === 'Custom detection') {
        return ActionSourceType.CustomDetection;
    }
    // otherwise, the enum value is already properly formatted and corresponds to specific ActionSourceType
    return actionSource;
};
var ActionBaseEntity = /** @class */ (function (_super) {
    __extends(ActionBaseEntity, _super);
    function ActionBaseEntity(data) {
        var _this = _super.call(this, data) || this;
        _this.shortInvestigationId = _this.isLRPrefixedInvestigationId
            ? _this.investigationId
            : OfficeUtils.getShortId(_this.investigationId);
        _this.shortApprovalId = _this.approvalId && _this.approvalId.substr(_this.approvalId.length - 6);
        _this._guid = ActionBaseEntity.uuidv4();
        _this.isMcasOrMdiAction = checkMcasOrMdiAction(_this.actionSourceI18nKey, _this.product);
        if (_this.isOfficeInvestigation) {
            var monthAgo = new Date();
            monthAgo.setDate(monthAgo.getDate() - OFFICE_INVESTIGATION_DATA_RETENTION);
            _this.doesInvestigationExist = (_this.endTime || _this.updateTime) >= monthAgo;
        }
        else {
            _this.doesInvestigationExist = true;
        }
        if (_this.entityType && _this.entityType.id === AirsEntityType.MailCluster) {
            // TODO: this is a patch for admin playbook scenario (there's dummy machine data in the response) and it should be removed soon
            _this.machine = null;
        }
        return _this;
    }
    ActionBaseEntity.convertActionCenterQueryToParams = function (dataQuery) {
        if (!dataQuery)
            return {};
        var pageSettings = {};
        if (dataQuery.page)
            pageSettings.pageIndex = dataQuery.page;
        if (dataQuery.pageSize)
            pageSettings.pageSize = dataQuery.pageSize;
        return Object.assign({}, omit(dataQuery.where, ['page', 'page_size', 'pagesize', 'ordering']), pageSettings, dataQuery.sortBy &&
            dataQuery.sortBy.length && {
            sortByField: dataQuery.sortBy[0].field,
            sortOrder: dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
                ? 'Ascending'
                : 'Descending',
        });
    };
    /*
    RFC4122 compliant uuidv4 (https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript)
     */
    ActionBaseEntity.uuidv4 = function () {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
            return (c ^ (CRYPTO.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16);
        });
    };
    __decorate([
        EntityField({ data: 'ActionId' }),
        __metadata("design:type", Object)
    ], ActionBaseEntity.prototype, "id", void 0);
    __decorate([
        EntityField({
            data: 'StartTime',
            parse: ɵ0,
        }),
        __metadata("design:type", Date)
    ], ActionBaseEntity.prototype, "startTime", void 0);
    __decorate([
        EntityField({
            data: 'EventTime',
            parse: ɵ1,
        }),
        __metadata("design:type", Date)
    ], ActionBaseEntity.prototype, "updateTime", void 0);
    __decorate([
        EntityField({
            data: 'EndTime',
            parse: ɵ2,
        }),
        __metadata("design:type", Date)
    ], ActionBaseEntity.prototype, "endTime", void 0);
    __decorate([
        EntityField({
            data: 'InvestigationId',
            parse: ɵ3,
        }),
        __metadata("design:type", Object)
    ], ActionBaseEntity.prototype, "investigationId", void 0);
    __decorate([
        EntityField({
            data: 'AdditionalFields',
            parse: ɵ4,
        }),
        __metadata("design:type", Boolean)
    ], ActionBaseEntity.prototype, "isLRPrefixedInvestigationId", void 0);
    __decorate([
        EntityField({ data: 'InvestigationDeeplink' }),
        __metadata("design:type", String)
    ], ActionBaseEntity.prototype, "investigationDeeplink", void 0);
    __decorate([
        EntityField({
            data: 'InvestigationDeeplink',
            parse: ɵ5,
        }),
        __metadata("design:type", Boolean)
    ], ActionBaseEntity.prototype, "isOfficeInvestigation", void 0);
    __decorate([
        EntityField({
            data: 'ActionAutomationType',
            // TODO: might need a type later
            parse: ɵ6,
        }),
        __metadata("design:type", Boolean)
    ], ActionBaseEntity.prototype, "isLiveResponse", void 0);
    __decorate([
        EntityField({ data: 'ActionType' }),
        __metadata("design:type", RemediationActionType)
    ], ActionBaseEntity.prototype, "actionType", void 0);
    __decorate([
        EntityField({
            data: 'Machine',
            parse: ɵ7,
        }),
        __metadata("design:type", Machine)
    ], ActionBaseEntity.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'ActionPendingDurationSeconds' }),
        __metadata("design:type", Number)
    ], ActionBaseEntity.prototype, "actionPendingDuration", void 0);
    __decorate([
        EntityField({ data: 'ResourcePendingDurationSeconds' }),
        __metadata("design:type", Number)
    ], ActionBaseEntity.prototype, "resourcePendingDurationSeconds", void 0);
    __decorate([
        EntityField({
            data: 'RelatedEntities',
            arrayOf: ActionHistoryRelatedEntity,
            parse: ɵ8,
        }),
        __metadata("design:type", Array)
    ], ActionBaseEntity.prototype, "relatedEntities", void 0);
    __decorate([
        EntityField({ data: 'EntityType' }),
        __metadata("design:type", AirsEntityTypeConfig)
    ], ActionBaseEntity.prototype, "entityType", void 0);
    __decorate([
        EntityField({ data: 'ActionStatus' }),
        __metadata("design:type", InvestigationActionStatus)
    ], ActionBaseEntity.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'ThreatType' }),
        __metadata("design:type", String)
    ], ActionBaseEntity.prototype, "threatType", void 0);
    __decorate([
        EntityField({ data: 'ExpandInvestigationFields.ExpandReason' }),
        __metadata("design:type", String)
    ], ActionBaseEntity.prototype, "expandReason", void 0);
    __decorate([
        EntityField({ data: 'ExpandInvestigationFields.ExpandSource' }),
        __metadata("design:type", String)
    ], ActionBaseEntity.prototype, "expandSource", void 0);
    __decorate([
        EntityField({ data: 'ExpandInvestigationFields.ExpandedHostsCount' }),
        __metadata("design:type", Number)
    ], ActionBaseEntity.prototype, "expandedHostsCount", void 0);
    __decorate([
        EntityField({
            data: 'AdditionalFields.user_activity',
        }),
        __metadata("design:type", InvestigatedUser)
    ], ActionBaseEntity.prototype, "investigatedUser", void 0);
    __decorate([
        EntityField({ data: 'BulkName' }),
        __metadata("design:type", String)
    ], ActionBaseEntity.prototype, "bulkName", void 0);
    __decorate([
        EntityField({ data: 'BulkId' }),
        __metadata("design:type", String)
    ], ActionBaseEntity.prototype, "approvalId", void 0);
    __decorate([
        EntityField({ data: 'Product' }),
        __metadata("design:type", String)
    ], ActionBaseEntity.prototype, "product", void 0);
    __decorate([
        EntityField({
            data: 'ActionSource',
            parse: ɵ9,
        }),
        __metadata("design:type", String)
    ], ActionBaseEntity.prototype, "actionSourceI18nKey", void 0);
    __decorate([
        EntityField({
            data: 'AdditionalFields.action_failure_key',
        }),
        __metadata("design:type", String)
    ], ActionBaseEntity.prototype, "actionFailureI18nKey", void 0);
    return ActionBaseEntity;
}(EntityModelBase));
export { ActionBaseEntity };
function checkMcasOrMdiAction(actionSource, actionProduct) {
    return (actionSource === ActionSourceType.MCASManualAction ||
        actionSource === ActionSourceType.MDIManualAction ||
        actionSource === ActionSourceType.MDIAutomaticAction ||
        actionProduct === ActionProduct.MDI);
}
var ENTITY_TYPE_MAP = {
    mailcluster: AirsEntityType.MailCluster,
    mailmessage: AirsEntityType.MailMessage,
    mailbox: AirsEntityType.Mailbox,
    submissionmail: AirsEntityType.SubmissionMail,
    mailboxconfiguration: AirsEntityType.MailboxConfiguration,
    url: AirsEntityType.URL,
    account: AirsEntityType.Account,
};
export var ActionSourceType;
(function (ActionSourceType) {
    ActionSourceType["Cloud"] = "Cloud";
    ActionSourceType["OatpManual"] = "OatpManual";
    ActionSourceType["AIRS"] = "AIRS";
    ActionSourceType["OfficeActionState"] = "OfficeActionState";
    ActionSourceType["MTP_BulkAction"] = "MTP_BulkAction";
    ActionSourceType["ThreatExplorer"] = "ThreatExplorer";
    ActionSourceType["AirsLiveResponse"] = "AirsLiveResponse";
    ActionSourceType["AirsCustomPlaybook"] = "AirsCustomPlaybook";
    ActionSourceType["MCASManualAction"] = "MCASManualAction";
    ActionSourceType["MDIManualAction"] = "MDIManualAction";
    ActionSourceType["MDIAutomaticAction"] = "MDIAutomaticAction";
    ActionSourceType["NDR"] = "NDR";
    ActionSourceType["AutomatedIRActionState"] = "AutomatedIRActionState";
    ActionSourceType["Portal"] = "Portal";
    ActionSourceType["PublicApi"] = "PublicApi";
    ActionSourceType["AdvancedHunting"] = "AdvancedHunting";
    ActionSourceType["CustomDetection"] = "CustomDetection";
    ActionSourceType["M365DManualAction"] = "M365DManualAction";
})(ActionSourceType || (ActionSourceType = {}));
export var ActionProduct;
(function (ActionProduct) {
    ActionProduct["MDO"] = "MDO";
    ActionProduct["MDE"] = "MDE";
    ActionProduct["MDI"] = "MDI";
})(ActionProduct || (ActionProduct = {}));
export var ActionFailureReason;
(function (ActionFailureReason) {
    // MDI action failure reasons
    ActionFailureReason["GmsaInsufficientPermissions"] = "GmsaInsufficientPermissions";
    ActionFailureReason["UserNotFound"] = "UserNotFound";
    ActionFailureReason["UserMultipleMatches"] = "UserMultipleMatches";
    ActionFailureReason["RequestedDomainNotFound"] = "RequestedDomainNotFound";
    ActionFailureReason["NoGmsaForRequestedDomain"] = "NoGmsaForRequestedDomain";
    ActionFailureReason["InternalError"] = "InternalError";
    ActionFailureReason["DeviceSharedIp"] = "DeviceSharedIp";
    ActionFailureReason["DeviceNoAutomatedIR"] = "DeviceNoAutomatedIR";
})(ActionFailureReason || (ActionFailureReason = {}));
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
