import { ComponentRef, Injectable } from '@angular/core';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { PanelType, PanelSettings } from '@wcd/panels';
import { Alert, SuppressionRule } from '@wcd/domain';
import { SuppressionRulePanelComponent } from '../components/suppression-rule.panel.component';
import { BehaviorSubject } from 'rxjs';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { DisableRuleConfirmModalComponent } from '../components/disable-rule-confirm.modal';
import { DimensionsModel } from '../../../dialogs/models/dimensions.model';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { I18nService } from '@wcd/i18n';
import { SuppressionRulesBackendService } from './suppression-rules.backend.service';
import { Paris, Repository } from '@microsoft/paris';
import { SuppressionRuleCommentsPanelComponent } from '../components/suppression-rule-comments.panel.component';
import { ReactPanelsService } from '../../../shared/services/react-panels.service';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';

// If changing this value, please also change SESSION_NEW_SUPPRESSION const in create-suppression-rule.store.ts
export const SESSION_NEW_SUPPRESSION = 'useNewSuppression';

@Injectable()
export class SuppressionRulesService {
	displayedRules$: BehaviorSubject<Array<SuppressionRule>> = new BehaviorSubject<Array<SuppressionRule>>(
		[]
	);
	changingRuleStatus$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
	refreshOn$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	private _rulesRepo: Repository<SuppressionRule>;
	private _ruleDetailsPanel: ComponentRef<SuppressionRulePanelComponent>;
	private _ruleEditPanelId: string;
	private _commentsPanel: ComponentRef<SuppressionRuleCommentsPanelComponent>;

	constructor(
		private paris: Paris,
		private dialogsService: DialogsService,
		private appInsightsService: AppInsightsService,
		private suppressionRulesBackendService: SuppressionRulesBackendService,
		private readonly reactPanelsService: ReactPanelsService,
		private appContextService: AppContextService,
		private featuresService: FeaturesService,
		private i18nService: I18nService
	) {
		this._rulesRepo = paris.getRepository(SuppressionRule);
	}

	/**
	 *
	 * @param alert : raw js object which represents alert
	 *
	 * This function is used for opening edit suppression rule panel from angular js code (alert page and old alerts queue page).
	 * This function is just a wrapper! The function takes the raw alert object we get from the angular js code, converts it to the Alert model (using paris),
	 * and call the opening side pane function.
	 */
	showRulePanelFromRawAlert(alert: any): Promise<SuppressionRule> {
		return new Promise(resolve => {
			this.paris
				.getRepository(Alert)
				.createItem(alert)
				.subscribe((a: Alert) => {
					resolve(this.showRulePanel(SuppressionRulePanelMode.create, null, a));
				});
		});
	}

	showRulePanel(
		method: SuppressionRulePanelMode,
		ruleId?: number,
		alert?: Alert
	): Promise<SuppressionRule> {
		if (ruleId) {
			return new Promise((resolve, reject) => {
				this._rulesRepo.getItemById(ruleId).subscribe((rule: SuppressionRule) => {
					this.doShowPanel(rule, method, alert).then(resolve, reject);
				}, reject);
			});
		} else return this.doShowPanel(null, method, alert);
	}

	showRuleCommentsPanel(rule: SuppressionRule): Promise<any> {
		const panelSettings: PanelSettings = {
			id: 'suppression-rule-comments-panel',
			type: PanelType.medium,
			isModal: true,
			showOverlay: false,
			isBlocking: true,
			noBodyPadding: true,
			scrollBody: false,
		};
		return new Promise((resolve, reject) => {
			if (this._commentsPanel) {
				// Comments panel is already open and button was clicked - closing it
				this._commentsPanel.instance.destroy();
				this._commentsPanel = null;
				resolve();
			} else {
				this.dialogsService
					.showPanel(SuppressionRuleCommentsPanelComponent, panelSettings, { rule: rule })
					.subscribe(
						(panel: ComponentRef<SuppressionRuleCommentsPanelComponent>) => {
							this._commentsPanel = panel;
							panel.onDestroy(() => {
								this._commentsPanel = null;
								resolve();
							});
						},
						error => {
							reject(error);
						},
					);
			}
		});
	}

	changeRuleStatus(rule: SuppressionRule): Promise<any> {
		if (rule.isEnabled) {
			// confirm with user decision and disable
			return new Promise((resolve, reject) => {
				let modal: ComponentRef<any>;
				this.dialogsService
					.showModal(
						DisableRuleConfirmModalComponent,
						{
							id: 'disable-rule-confirm-modal',
							dimensions: new DimensionsModel(450, 0),
							title: this.i18nService.get('suppressionRules.disable.title'),
						},
						{
							confirm: (data: { restoreAlerts: boolean; comment: string }) => {
								resolve(this.doChangeStatus(rule, data.comment, data.restoreAlerts));
								this.refreshOn$.next(new Date().valueOf());
								modal.destroy();
							},
							cancel: () => {
								resolve();
								modal.destroy();
							},
							isDeleteMode: false,
						}
					)
					.subscribe(_modal => {
						modal = _modal;
					});
			});
		} else {
			// simple confirm and enable
			return this.dialogsService
				.confirm({
					title: this.i18nService.get('suppressionRules.enable.title'),
					text: this.i18nService.get('suppressionRules.enable.description'),
					confirmText: 'Turn rule on',
					requireMessage: {
						placeholder: 'Please add a comment',
						property: 'comment',
					},
				})
				.then((e: ConfirmEvent) => {
					if (e.confirmed) {
						return this.doChangeStatus(rule, e.data.comment);
					}
				});
		}
	}

	private doChangeStatus(rule: SuppressionRule, comment: string, restoreAlerts?: boolean): Promise<any> {
		const statusForSave = this.getRuleStatusBackendData(rule, comment, restoreAlerts);

		return new Promise<void>((resolve, reject) => {
			this.changingRuleStatus$.next(true);
			this.suppressionRulesBackendService.changeRuleStatus(!rule.isEnabled, statusForSave).subscribe(
				() => {
					this.changingRuleStatus$.next(false);
					this.refreshOn$.next(new Date().valueOf());
					this.appInsightsService.track({
						type: TrackingEventType.EntitySave,
						id: this._rulesRepo.entity.singularName,
					});
					this.dialogsService.showSuccessSnackbar({
						text: `Suppression rule was ${!rule.isEnabled ? 'enabled' : 'disabled'}`,
					});
					resolve();
				},
				error => {
					this.changingRuleStatus$.next(false);
					reject();
				}
			);
		});
	}

	private getRuleStatusBackendData(
		rule: SuppressionRule,
		comment: string,
		restoreAlerts?: boolean,
		isDeleteMode: boolean = false
	): RuleToDeleteDetails {
		return isDeleteMode
			? {
				ruleId: rule.id,
				restoreAssociatedAlerts: restoreAlerts,
				comment: comment,
			}
			: {
				ruleId: rule.id,
				restoreAssociatedAlerts: !rule.isEnabled ? undefined : restoreAlerts,
				comment: comment,
			};
	}

	private doShowPanel(
		rule: SuppressionRule,
		method: SuppressionRulePanelMode,
		alert?: Alert
	): Promise<SuppressionRule> {
		const panelInputs = (resolve) => ({
			rule: rule,
			alert: alert,
			refreshOn$: this.refreshOn$,
			changingRuleStatus$: this.changingRuleStatus$,
			isEditRuleMode: method === SuppressionRulePanelMode.edit,
			onCancel: () => this._ruleDetailsPanel.destroy(),
			onDone: (savedSuppressionRule: SuppressionRule) => {
				resolve(savedSuppressionRule);
				this._ruleDetailsPanel.destroy();
				this.refreshOn$.next(new Date().valueOf());
			},
			onChangeStatus: (rule: SuppressionRule) => this.changeRuleStatus(rule),
		})

		if (this._ruleDetailsPanel && rule) {
			this.displayedRules$.next([rule]);
			return new Promise<SuppressionRule>((resolve) => {
				const instance = this._ruleDetailsPanel.instance;
				Object.assign(instance, panelInputs(resolve));
			});
		}
		return new Promise((resolve, reject) => {
			const shouldOpenNewSupressionForm = this.shouldOpenNewForm(rule);
			if (shouldOpenNewSupressionForm) {
				this.displayedRules$.next([rule]);
				if (this._ruleEditPanelId) {
					this.reactPanelsService.removeComponent(this._ruleEditPanelId);
				}
				this._ruleEditPanelId = this.reactPanelsService.renderComponent({
					componentName: 'CreateSuppressionRuleFlyoutWithAppBootstrap@wicd-ine/main',
					props: {
						rule,
						onDismiss: () => {
							this._ruleEditPanelId = null;
							this.displayedRules$.next([]);
							resolve();
						},
					},
				});
			} else {
				this.dialogsService.showPanel(SuppressionRulePanelComponent,
					{
						id: 'suppression-rule-details-panel',
						type: PanelType.large,
						isModal: true,
						disableOverlayClick: !rule,
						noBodyPadding: true,
						isBlocking: true,
					},
					panelInputs(resolve)
				).subscribe((panel: ComponentRef<SuppressionRulePanelComponent>) => {
					this._ruleDetailsPanel = panel;
					if (rule) this.displayedRules$.next([rule]);

					panel.onDestroy(() => {
						this._ruleDetailsPanel = null;
						this.displayedRules$.next([]);
						resolve();
					});
				}, reject);
			}
		});
	}

	deleteRule(rule: SuppressionRule): Promise<any> {
		return new Promise((resolve, reject) => {
			let modal: ComponentRef<any>;
			this.dialogsService
				.showModal(
					DisableRuleConfirmModalComponent,
					{
						id: 'delete-rule-confirm-modal',
						dimensions: new DimensionsModel(450, 0),
						title: this.i18nService.get('suppressionRules.delete.title'),
					},
					{
						confirm: (data: { restoreAlerts: boolean; comment: string }) => {
							this.doDeleteRule(rule, data.comment, data.restoreAlerts).then(data => {
								resolve({isConfirm: true})
							})
							modal.destroy();
						},
						cancel: () => {
							resolve({isCancel: true});
							modal.destroy();
						},
						isDeleteMode: true,
					}
				)
				.subscribe(_modal => {
					modal = _modal;
				});
		});
	}

	private doDeleteRule(
		rule: SuppressionRule,
		comment: string,
		restoreAlerts: boolean = false
	): Promise<void> {
		return new Promise((resolve, reject) => {
			this.changingRuleStatus$.next(true);

			const ruleToDelete = this.getRuleStatusBackendData(rule, comment, restoreAlerts, true);

			this._rulesRepo.removeItem(rule, { data: ruleToDelete }).subscribe(
				() => {
					this.changingRuleStatus$.next(false);
					this.refreshOn$.next(new Date().valueOf());
					this.appInsightsService.track({
						type: TrackingEventType.EntitySave,
						id: this._rulesRepo.entity.singularName,
					});
					this.dialogsService.showSuccessSnackbar({
						text: `Suppression rule was deleted`,
					});
					resolve();
				},
				error => {
					this.changingRuleStatus$.next(false);
					reject();
				}
			);
		});
	}

	private shouldOpenNewForm(rule : SuppressionRule) {
		return rule && this.featuresService.isEnabled(Feature.NewSuppressionRuleCreation) && this.appContextService.isSCC && localStorage.getItem(SESSION_NEW_SUPPRESSION) !== 'false';
	}
}

interface RuleToDeleteDetails {
	ruleId: number;
	restoreAssociatedAlerts: boolean;
	comment: string;
}

export enum SuppressionRulePanelMode {
	edit,
	create,
	show,
}
