import { EntityModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Stream',
	pluralName: 'Stream',
	readonly: true,
})
export class Stream extends EntityModelBase<string> {
	@EntityField({ data: 'id' })
	id: string;

	@EntityField({ data: 'name' })
	name: string;
}
