import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { FormComponent } from '../models/form.component';
import { ConfirmationService } from '../../dialogs/confirm/confirm.service';
import { ConfirmEvent } from '../../dialogs/confirm/confirm.event';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class CanDeactivateForm implements CanDeactivate<FormComponent> {
	constructor(private confirmationService: ConfirmationService, private i18nService: I18nService) {}

	canDeactivate(
		component: FormComponent,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState: RouterStateSnapshot
	): boolean | Promise<boolean> {
		if (component.isDirty) {
			return this.confirmationService
				.confirm({
					title: this.i18nService.get('can_dectivate_form_guard_title'),
					text: component.confirmDeactivateText || this.i18nService.get('can_dectivate_form_guard_message'),
				})
				.then((e: ConfirmEvent) => e.confirmed);
		} else return true;
	}
}
