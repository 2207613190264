import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { TzDateService } from '../services/tz-date.service';
import { LocaleConfigService } from '../services/locale-config.service';

/**
 * A pipe overriding Angular's `DatePipe`, which takes WCD's selected locale into account.
 * @note should hold the same interface as Angular's `DatePipe`. Note that this class does not `implement DatePipe` as it caused issues with AOT.
 */
@Pipe({
	name: 'date',
	pure: false,
})
export class TzDatePipe implements PipeTransform, OnDestroy {
	private tzSubscription: Subscription;
	private currentTransformedValue: string;
	private valueObj: string | number | Date;

	constructor(
		private readonly tzDateService: TzDateService,
		private readonly localeConfigService: LocaleConfigService,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {}

	transform(value: string | number | Date, format?: string, timezone?: string, locale?: string): string {
		if (!this.valueObj) {
			if (value) {
				this.valueObj = value;
				this.tzSubscription = this.localeConfigService.config$.subscribe(() => {
					this.currentTransformedValue = this.tzDateService.format(value, format, timezone, locale);
					this.changeDetectorRef.markForCheck();
				});
			}
			return this.currentTransformedValue;
		}
		// value changed, need to reset
		if (value !== this.valueObj) {
			this.dispose();
			return this.transform(value, format, locale);
		}
		return this.currentTransformedValue;
	}

	ngOnDestroy() {
		this.dispose();
	}

	private dispose() {
		if (this.tzSubscription) {
			this.tzSubscription.unsubscribe();
		}
		this.valueObj = null;
		this.currentTransformedValue = null;
	}
}
