import { Component, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ModelBase, Paris, Repository } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { BaselineProfileDetails, BaselineProfileDevice, BaselineProfileDeviceIds, Machine } from '@wcd/domain';
import { ActivatedEntity } from '../../../../../../global_entities/services/activated-entity.service';
import { EntityPanelComponentBase } from '../../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { TabModel } from '../../../../../../shared/components/tabs/tab.model';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

enum TabIds {
	deviceDetails = 'deviceDetails',
	configurations = 'configurations'
}

@Component({
	selector: 'profile-configuration-entity-panel',
	templateUrl: './profile-device.entity-panel.component.html',
	styleUrls: ['./profile-device.entity-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDeviceEntityPanelComponent extends EntityPanelComponentBase<BaselineProfileDevice> implements OnDestroy {
	private _subscription: Subscription;

	get baselineProfileDevice(): BaselineProfileDevice {
		return this.entity;
	}
	machineRepository: Repository<Machine>;
	machine: Machine;
	profileDeviceIds: BaselineProfileDeviceIds;
	profile: BaselineProfileDetails;

	tabs: Array<TabModel> = [];
	currentTab: TabModel;
	tabIds = TabIds;

	constructor(
		private activatedEntity: ActivatedEntity,
		readonly i18nService: I18nService,
		paris: Paris,
		changeDetectorRef: ChangeDetectorRef,
	) {
		super(changeDetectorRef);
		this.machineRepository = paris.getRepository(Machine);
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}


	initEntity(baselineProfileDevice: BaselineProfileDevice, isExtendedData: boolean = false) {
		super.initEntity(baselineProfileDevice, isExtendedData);
		this.machineRepository.getItemById(baselineProfileDevice.id).subscribe(m => {
			this.machine = m;
			this.changeDetectorRef.markForCheck();
		});

		this.tabs = this.getTabs();
		this.currentTab = this.tabs[0];
		this._subscription = this.activatedEntity.currentEntity$
			.pipe(filter((entity: ModelBase) => entity instanceof BaselineProfileDetails))
			.subscribe((profile: BaselineProfileDetails) => {
				this.profileDeviceIds = new BaselineProfileDeviceIds({
					id: this.baselineProfileDevice.id,
					deviceId: this.baselineProfileDevice.id,
					profileId: profile.id
				});
				this.profile = profile;
			});
	}

	setTab(tab: TabModel) {
		this.currentTab = tab;
	}

	private getTabs(): Array<TabModel> {
		return [
			{
				id: this.tabIds.deviceDetails,
				name: this.i18nService.strings.tvm_baseline_profile_entity_deviceTab_sidePanel_deviceDetails_title,
			},
			{
				id: this.tabIds.configurations,
				name: this.i18nService.strings.tvm_baseline_profile_entity_configurationTab_title,
			}
		].map(tab => new TabModel(tab));
	}
}
