var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var loadingSeverities = Object.freeze({
    high: null,
    medium: null,
    low: null,
    informational: null,
});
var defaultSeverities = Object.freeze({
    high: 0,
    medium: 0,
    low: 0,
    informational: 0,
});
export function buildSeveritiesMap(alertsSeveritySummary) {
    var extractCount = function (countWrapper) { return countWrapper.count; };
    return {
        high: extractCount(alertsSeveritySummary.high),
        medium: extractCount(alertsSeveritySummary.medium),
        low: extractCount(alertsSeveritySummary.low),
        informational: extractCount(alertsSeveritySummary.info),
    };
}
var SeveritySummaryComponent = /** @class */ (function () {
    function SeveritySummaryComponent() {
    }
    Object.defineProperty(SeveritySummaryComponent.prototype, "severitiesCountMap", {
        set: function (value) {
            var fullSeverities = !value
                ? loadingSeverities
                : __assign({}, defaultSeverities, value);
            this.severitiesCountArray = Object.entries(fullSeverities).map(function (_a) {
                var type = _a[0], count = _a[1];
                return ({
                    type: type,
                    count: count,
                });
            });
        },
        enumerable: true,
        configurable: true
    });
    return SeveritySummaryComponent;
}());
export { SeveritySummaryComponent };
