var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PipeTransform } from '@angular/core';
import { SlicePipe } from '@angular/common';
/**
 * Simple pipe to display `boolean` values.
 */
var SafeSlicePipe = /** @class */ (function (_super) {
    __extends(SafeSlicePipe, _super);
    function SafeSlicePipe() {
        return _super.call(this) || this;
    }
    /**
     * Augment native angular slice pipe, if and of the indexes is not a number(ie undifined also), return immediatly, avoid doing array slice when any index is not defined
     * @param value
     * @param start
     * @param end
     */
    SafeSlicePipe.prototype.transform = function (value, start, end) {
        if (isNaN(start) || isNaN(end)) {
            return value;
        }
        return _super.prototype.transform.call(this, value, start, end);
    };
    return SafeSlicePipe;
}(SlicePipe));
export { SafeSlicePipe };
