import { NgModule } from '@angular/core';
import { ButtonsBarWidgetComponent } from './buttons-bar.component';
import { WcdIconsModule } from '@wcd/icons';
import { TooltipsModule } from '@wcd/dialogs';
import { RouterModule } from '@angular/router';
import { WcdFormsModule } from '@wcd/forms';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@wcd/i18n';

@NgModule({
	imports: [CommonModule, I18nModule, WcdIconsModule, TooltipsModule, RouterModule, WcdFormsModule],
	declarations: [ButtonsBarWidgetComponent],
	exports: [ButtonsBarWidgetComponent],
})
export class ButtonsBarButtonModule {}
