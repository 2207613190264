import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { SuppressionRule } from '@wcd/domain';

@Component({
	selector: 'notification-rule-edit-panel',
	template: `
		<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings">
			<suppression-rule-comments [rule]="rule"></suppression-rule-comments>
		</wcd-panel>
	`,
})
export class SuppressionRuleCommentsPanelComponent extends PanelContainer {
	@Input() rule: SuppressionRule;

	constructor(protected router: Router) {
		super(router);
	}
}
