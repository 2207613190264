/* tslint:disable:template-click-events-have-key-events */
import {
	Component,
	ChangeDetectionStrategy,
	ElementRef,
	ChangeDetectorRef,
	OnDestroy,
	Input,
} from '@angular/core';
import { Paris, DataQuerySortDirection } from '@microsoft/paris';
import { Router } from '@angular/router';
import { SecurityRecommendation, RemediationType, Tag } from '@wcd/domain';
import { map, take, debounceTime } from 'rxjs/operators';
import { SecurityRecommendationCardData } from './security-recommendation-card/security-recommendation-card.component';
import { SpinnerSize } from 'office-ui-fabric-react';
import { TvmTextsService, TextToken } from '../../services/tvm-texts.service';
import { VisibilityChange } from '../../../shared/directives/visibility.directive';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TvmMachineGroupsFilterService } from '../../services/tvm-machine-groups-filter.service';
import { TvmTagsService } from '../../../tvm/services/tvm-tags.service';
import { Observable, Subscription, fromEvent } from 'rxjs';

const MAX_CARDS_TO_DISPLAY = 15;
//max number of pixels to switch to dashboard vertical mode, check tvm.dashboard.component
const VERTICAL_MODE_MAX_PIXELS = 800;

@Component({
	selector: 'tvm-security-recommendations',
	templateUrl: './security-recommendations.component.html',
	styleUrls: ['./security-recommendations.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvmSecurityRecommendationsComponent implements OnDestroy {
	@Input() refresh$: Observable<boolean>;

	readonly SpinnerSize = SpinnerSize;
	_cardsData: Array<SecurityRecommendationCardData>;

	readonly maxCardsToDisplay: number;
	totalRecommendationsCount: number;

	private readonly cardsVisibilityMap = new Map<ElementRef, boolean>();
	visibleCards: number;

	private _resizeSubscription: Subscription;
	private verticalMode: boolean;

	constructor(
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		private paris: Paris,
		private router: Router,
		private tvmTextsService: TvmTextsService,
		private featuresService: FeaturesService,
		private changeDetectorRef: ChangeDetectorRef,
		private i18nService: I18nService,
		private tvmTagsService: TvmTagsService
	) {
		this.verticalMode = window.innerWidth <= VERTICAL_MODE_MAX_PIXELS;
		this.maxCardsToDisplay = MAX_CARDS_TO_DISPLAY;
		this.refreshData();

		this._resizeSubscription = fromEvent(window, 'resize')
			.pipe(
				debounceTime(100),
				map(e => true)
			)
			.subscribe(() => {
				this.refreshCardsView();
			});
	}

	//for vertical mode we render 3 security recommendation cards
	public refreshCardsView() {
		this.checkDashboardDisplayMode();
		if (this.verticalMode) {
			this._cardsData.map((card, index) => {
				card.showCard = index < 3 ? true : false;
			});
			Array.from(this.cardsVisibilityMap.entries()).map((entry, index) => {
				this.cardsVisibilityMap.set(entry[0], index < 3 ? true : false);
			});
			this.updateVisibaleCards();
		} else {
			this._cardsData.map(card => (card.showCard = true));
		}
		this.changeDetectorRef.markForCheck();
	}

	public refreshData() {
		this._cardsData = null;
		this.changeDetectorRef.markForCheck();
		this.loadData();
	}

	private loadData() {
		this.paris
			.getRepository(SecurityRecommendation)
			.query({
				page: 1,
				pageSize: this.maxCardsToDisplay,
				where: { status: 'Active' },
				sortBy: [
					{
						field: 'exposureScoreImprovement',
						direction: DataQuerySortDirection.descending,
					},
					{
						field: 'secureScoreImprovement',
						direction: DataQuerySortDirection.descending,
					},
				],
			})
			.pipe(
				map(ds => {
					this.totalRecommendationsCount = ds.count;
					return ds.items
						.sort((a, b) => {
							if (a.exposureScoreImprovement === b.exposureScoreImprovement) {
								return (b.secureScoreImprovement || 0) - (a.secureScoreImprovement || 0);
							}
							return b.exposureScoreImprovement - a.exposureScoreImprovement;
						})
						.slice(0, this.maxCardsToDisplay)
						.map(
							(
								securityRecommendation: SecurityRecommendation
							): SecurityRecommendationCardData => {
								const tags = new Array<Tag>();

								const remediationTypeTag: Pick<Tag, 'id' | 'name'> = { id: 'remediationTag' };
								switch (securityRecommendation.remediationType) {
									case RemediationType.ConfigurationChange:
										remediationTypeTag.name = this.i18nService.get(
											'tvm.securityRecommendation.remediationType.configurationChange'
										);
										break;
									case RemediationType.Upgrade:
										remediationTypeTag.name = this.i18nService.get(
											'tvm.securityRecommendation.remediationType.softwareUpgrade'
										);
										break;
									case RemediationType.Update:
										remediationTypeTag.name = this.i18nService.get(
											'tvm.securityRecommendation.remediationType.softwarePatch'
										);
										break;
									case RemediationType.Uninstall:
										remediationTypeTag.name = this.i18nService.get(
											'tvm.securityRecommendation.remediationType.uninstallSoftware'
										);
										break;
								}
								tags.push(remediationTypeTag);

								this.tvmTagsService
									.getRecommendationTags(securityRecommendation)
									.forEach(t => tags.push(t));

								let insight = '';

								if (
									securityRecommendation.remediationType ===
									RemediationType.ConfigurationChange
								) {
									const properlyConfiguredInsight = this.tvmTextsService.getText(
										TextToken.SecurityRecommendationSCAProperlyConfiguredInsight,
										securityRecommendation
									);
									const supportedBenchmarksInsight = this.tvmTextsService.getText(
										TextToken.SecurityRecommendationSCARecommendedBenchmarksInsight,
										securityRecommendation
									);
									insight = `<div style="max-width:350px;"><div style="white-space: nowrap" class="wcd-tooltip__title">Recommendation insights</div><ul><li>${properlyConfiguredInsight}</li>`;
									if (supportedBenchmarksInsight) {
										insight += `<li>${supportedBenchmarksInsight}</li>`;
									}
									insight += `</ul></div>`;
								}

								return {
									recommendationId: securityRecommendation.id,
									isNew: false,
									title: this.tvmTextsService.getText(
										TextToken.SecurityRecommendationTitle,
										securityRecommendation
									),
									exposedMachinesCount:
										securityRecommendation.assetsStatistics.assetsAtRiskCount,
									scoreData: {
										exposureScore: securityRecommendation.exposureScoreImprovement,
										secureScore: securityRecommendation.secureScoreImprovement,
									},
									tags: tags,
									threatInfo: securityRecommendation.threatInfo,
									threats: securityRecommendation.threats,
									insight: insight,
								};
							}
						);
				})
			)
			.subscribe(cardsData => {
				this._cardsData = cardsData;
				this.refreshCardsView();
			});
	}

	get noRecommendationsMessage(): Observable<string> {
		return this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1)).pipe(
			map(mg =>
				this.tvmTextsService.getText(TextToken.NoDataForWidget, {
					noDataKey: 'tvm.dashboard.noData.topSecurityRecommendations',
					isGroupSelected: mg.isFiltering,
				})
			)
		);
	}

	onVisibilityChange(visibilityChange: VisibilityChange) {
		this.cardsVisibilityMap.set(visibilityChange.elementRef, visibilityChange.visible);
		this.updateVisibaleCards();
	}

	updateVisibaleCards() {
		this.visibleCards = Array.from(this.cardsVisibilityMap).reduce(
			(acc, curr) => acc + (curr[1] ? 1 : 0),
			0
		);
	}

	checkDashboardDisplayMode() {
		if (
			(!this.verticalMode && window.innerWidth <= VERTICAL_MODE_MAX_PIXELS) ||
			(this.verticalMode && window.innerWidth > VERTICAL_MODE_MAX_PIXELS)
		) {
			this.verticalMode = !this.verticalMode;
		}
	}

	openRecommendationsPage(): void {
		this.router.navigate(['/security-recommendations'], {
			queryParams: {
				filters: 'status=Active',
			},
		});
	}

	openFilteredRecommendationsPage() {
		this.router.navigate(['/security-recommendations'], {
			queryParams: {
				filters: 'status=Exception',
			},
		});
	}

	ngOnDestroy() {
		this._resizeSubscription && this._resizeSubscription.unsubscribe();
	}
}
