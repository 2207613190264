import { keyBy } from 'lodash-es';
import { MachineExclusionState } from './machine-exclusion-state.enum';
var ɵ0 = MachineExclusionState.Included, ɵ1 = MachineExclusionState.Excluded;
export var exclusionStates = [
    {
        id: ɵ0,
        i18nNameKey: 'machines_exclusionState_Included',
        priority: 1,
    },
    {
        id: ɵ1,
        i18nNameKey: 'machines_exclusionState_Excluded',
        priority: 2,
    },
];
var ɵ2 = function (o) { return o.id; };
export var machineExclusionStateMap = keyBy(exclusionStates, ɵ2);
export { ɵ0, ɵ1, ɵ2 };
