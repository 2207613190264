import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { Email } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';

@Component({
	selector: 'email-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './email.entity-details.component.html',
})
export class EmailEntityDetailsComponent extends EntityDetailsComponentBase<Email> {
	get email(): Email {
		return this.entity;
	}

	constructor(injector: Injector) {
		super(injector);
	}
}
