import { ApiCall, ApiCallModel, HttpOptions, ParisConfig } from '@microsoft/paris';
import {
	IsolateMachinePublicApiRequestBody,
	IsolationPublicApiParameters,
} from './isolation-requests.models';

@ApiCall({
	name: 'Isolate machine - public API',
	endpoint: (config, query) => {
		return `api/machines/${query.where['machineId']}/isolate`;
	},
	method: 'POST',
	baseUrl: (config: ParisConfig) => '<mdatpapi>',
	parseQuery: ({
		comment,
		title,
		isolationType,
		externalId,
		machineId,
	}: IsolationPublicApiParameters): HttpOptions<IsolateMachinePublicApiRequestBody> => ({
		data: {
			Comment: comment,
			Title: title,
			IsolationType: isolationType,
			ExternalId: externalId,
		},
		params: {
			machineId: machineId,
		},
	}),
})
export class MachineIsolatePublicApiApiCall extends ApiCallModel<void, IsolationPublicApiParameters> {}
