
		<div [class.assignee-selected]="!!selectedAssignees.length">
			<fab-people-picker
				[resolveDelay]="peoplePickerResolveDelay"
				[inputProps]="assignInputProps"
				[pickerSuggestionsOptions]="assigneesSuggestionsOptions"
				[selectedItems]="selectedAssignees"
				(onChange)="updateSelectedAssignees($event)"
				[resolveSuggestions]="peoplePickerInputChanged.bind(this)"
				className="people-picker"
				[renderItem]="peoplePickerItemRender"
				[emptyInputFocus]="onInputFocus"
				[disabled]="!!selectedAssignees.length || isDisabled"
			>
			</fab-people-picker>
		</div>

		<ng-template #peoplePickerItemRender let-item="item">
			<div class="wcd-flex">
				<span class="wcd-padding-small-left">{{item.secondaryText == currentUserName ? item.secondaryText : item.text }}</span>
				<div *ngIf="isLoading; else loaded"  class="wcd-padding-small-left">
					<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
				</div>
				<ng-template #loaded>
					<fab-action-button [disabled]="isDisabled" (click)="clearSelectedAssignees()" [iconProps]="iconProps" [styles]="buttonStyles">
					</fab-action-button>
				</ng-template>
			</div>
		</ng-template>
	