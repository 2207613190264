import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityRelationshipConfigurations } from '../../../analyticsEndPointUtils';
import { BaselineProfileConfiguration } from '../../baseline-profile-configuration/baseline-profile-configuration.entity';
import { BaselineProfileDeviceIds } from '../../baseline-profile-device/baseline-profile-device-ids.entity';

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: BaselineProfileDeviceIds,
	dataEntity: BaselineProfileConfiguration,
	endpoint: (_, query) => `baseline/profiles/${query.where['profileId']}/devices/${query.where['deviceId']}/settings`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (device: BaselineProfileDeviceIds) => ({
		profileId: device.profileId,
		deviceId: device.deviceId
	}),
})
export class BaselineProfileDeviceIdsBaselineConfigurationsRelationship
	implements EntityRelationshipRepositoryType<BaselineProfileDeviceIds, BaselineProfileConfiguration> {}
