var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnChanges } from '@angular/core';
import { SpinnerSize } from 'office-ui-fabric-react';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { EmailHeadersApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { HttpClient } from '@angular/common/http';
import { defer, of } from 'rxjs';
import { catchError, map, startWith, take } from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { sccHostService } from '@wcd/scc-interface';
var LOADING_SYMBOL = Symbol();
var EmailHeadersPanelComponent = /** @class */ (function (_super) {
    __extends(EmailHeadersPanelComponent, _super);
    function EmailHeadersPanelComponent(router, i18nService, paris, http, dialogsService) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.paris = paris;
        _this.http = http;
        _this.dialogsService = dialogsService;
        _this.LOADING_SYMBOL = LOADING_SYMBOL;
        _this.SpinnerSize = SpinnerSize;
        return _this;
    }
    EmailHeadersPanelComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.setHeaders();
    };
    EmailHeadersPanelComponent.prototype.ngOnChanges = function () {
        this.setHeaders();
    };
    EmailHeadersPanelComponent.prototype.setHeaders = function () {
        this.headers$ =
            this.headersObj &&
                (this.headersObj.email
                    ? this.getEmailHeaders()
                    : this.headersObj.quarantineEmail
                        ? this.getQuarantineEmailHeaders()
                        : null).pipe(startWith(LOADING_SYMBOL));
    };
    EmailHeadersPanelComponent.prototype.getEmailHeaders = function () {
        var _this = this;
        return defer(function () { return __awaiter(_this, void 0, void 0, function () {
            var EnableNewMailItemAPI, e_1, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        EnableNewMailItemAPI = false;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, sccHostService.auth.isInRole('setting:AntiSpamCHN$EnableNewMailItemAPI')];
                    case 2:
                        EnableNewMailItemAPI = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.paris
                                .apiCall(EmailHeadersApiCall, {
                                internetMessageId: this.headersObj.email.internetMessageId,
                                mailboxId: this.headersObj.email.mailboxId,
                                EnableNewMailItemAPI: EnableNewMailItemAPI,
                            })
                                .pipe(take(1))
                                .toPromise()];
                    case 5: return [2 /*return*/, _a.sent()];
                    case 6:
                        e_2 = _a.sent();
                        this.dialogsService.showError({
                            title: this.i18nService.strings.airsEntities_email_headers_panel_headerErrorTitle,
                            data: this.i18nService.strings.airsEntities_email_headers_panel_headerErrorMessage,
                        });
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); });
    };
    EmailHeadersPanelComponent.prototype.getQuarantineEmailHeaders = function () {
        // TODO: move to paris once we can test with examples in mtptestlab
        return this.http
            .get('/api/QuarantineMessageHeader', {
            params: { identity: this.headersObj.quarantineEmail.identity },
        })
            .pipe(take(1), map(function (data) {
            return (data &&
                data.header &&
                data.header
                    .split(/[\r\n]/g)
                    .map(function (s) { return s && s.trim(); })
                    .filter(Boolean));
        }), catchError(function (err) { return of(null); }));
    };
    return EmailHeadersPanelComponent;
}(PanelContainer));
export { EmailHeadersPanelComponent };
