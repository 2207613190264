var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _a;
import { ActionabilityType } from '@wcd/domain';
import { ColorsService } from '../../../shared/services/colors.service';
import { KnownColorsService } from '../../../shared/services/known-colors.service';
var actionabilityTypeColorName = (_a = {},
    _a[ActionabilityType.Actionable] = 'blue',
    _a[ActionabilityType.NonActionable] = 'neutralQuaternary',
    _a);
var ActionabilityTypeColorService = /** @class */ (function (_super) {
    __extends(ActionabilityTypeColorService, _super);
    function ActionabilityTypeColorService(knownColorsService) {
        return _super.call(this, knownColorsService, actionabilityTypeColorName) || this;
    }
    return ActionabilityTypeColorService;
}(ColorsService));
export { ActionabilityTypeColorService };
