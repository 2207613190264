import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { Asset, SoftwareInstallation } from '@wcd/domain';
import { SpinnerSize } from 'office-ui-fabric-react';
import { FeaturesService, Feature } from '@wcd/config';
import { Router } from '@angular/router';
import { TvmContextOptions } from '../../../../@entities/@tvm/vulnerabilities/components/vulnerability.entity-panel.component';
import { DataSet } from '@microsoft/paris';
import { Observable } from 'rxjs';
import { TvmTextsService } from '../../../../tvm/services/tvm-texts.service';

@Component({
	selector: 'installed-machines-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './installed-machines.details.component.html',
})
export class InstalledMachinesDetailsComponent {
	SpinnerSize = SpinnerSize;
	isListCollapsed = true;

	oneDayAgo: number;

	readonly oneDay = 60 * 60 * 24 * 1000;

	@Input() installations$: Observable<DataSet<SoftwareInstallation>>;
	@Input() contextOptions: TvmContextOptions;

	@Input() title?: string;
	@Input() isNetworkDevice?: boolean;
	@Input() loadingText?: string;
	@Input() includeLastSeenStatus?: boolean;

	@Input() includeOperatingSystem?: boolean;

	@Output() showMore: EventEmitter<void> = new EventEmitter<void>();
	@Output() export: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private featuresService: FeaturesService,
		public tvmTextsService: TvmTextsService,
		private router: Router
	) {
		this.oneDayAgo = new Date().getTime() - this.oneDay;
	}

	onItemClick(assetId: string) {
		const link = this.featuresService.isEnabled(Feature.UpgradeMachinePage)
			? `/machines/${assetId}`
			: `/machine/${assetId}`;
		this.router.navigate([link]);
	}

	openAllInstalledMachines() {
		this.showMore.emit();
	}

	getLastSeenDate(asset: Asset): string {
		return new Date(asset.lastSeen).toLocaleString();
	}
}
