import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';

@Entity({
	singularName: 'Suppression Rule Action',
	pluralName: 'Suppression Rule Actions',
	values: [
		{ id: 1, name: 'Hide alert', nameI18nKey: 'suppressionRuleAction_hide', type: 'hide' },
		{ id: 2, name: 'Resolve alert', nameI18nKey: 'suppressionRuleAction_autoResolve', type: 'autoResolve' },
	],
})
export class SuppressionRuleAction extends EntityModelBase<number> {
	@EntityField() name: string;
	@EntityField() nameI18nKey: string;
	@EntityField() type: string;

	get helpKey(): string {
		return `suppressionRules.action.${this.type}`;
	}
}
