var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { AlertsSeveritySummary } from '../../alert/alerts-severity-summary.value-object';
import { Url } from '../url.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.activeAlertsSummary; }, ɵ1 = function (url) { return ({
    Url: url.id,
}); }, ɵ2 = function (dataQuery) {
    return Object.assign({ lookingBackInDays: 180 }, dataQuery && dataQuery.where);
};
var UrlActiveAlertsSummaryRelationship = /** @class */ (function () {
    function UrlActiveAlertsSummaryRelationship() {
    }
    UrlActiveAlertsSummaryRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Url,
            dataEntity: AlertsSeveritySummary,
            allowedTypes: [RelationshipType.OneToOne],
            cache: {
                time: 1000 * 60,
                max: 10,
            },
            baseUrl: ɵ0,
            endpoint: 'activeAlertsSummary',
            getRelationshipData: ɵ1,
            parseDataQuery: ɵ2,
        })
    ], UrlActiveAlertsSummaryRelationship);
    return UrlActiveAlertsSummaryRelationship;
}());
export { UrlActiveAlertsSummaryRelationship };
export { ɵ0, ɵ1, ɵ2 };
