var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EvidenceEntity } from '@wcd/domain';
import { Observable, Subject } from 'rxjs';
import { finalize, shareReplay, takeUntil } from 'rxjs/operators';
import { isEqual, sortBy } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i2 from "../../../../../../../projects/config/src/lib/services/features.service";
var ɵ0 = function (acc, key) {
    acc[key] = key;
    return acc;
};
export var EVIDENCE_FIELD_IDS = Array.from(EvidenceEntity.entityConfig.fields.keys()).reduce(ɵ0, {});
var MAX_FILTER_VALUES = 5;
var EvidenceService = /** @class */ (function () {
    function EvidenceService(i18nService, featuresService) {
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.evidenceMap = {};
        this.destroy$ = new Subject();
    }
    EvidenceService.prototype.getEvidence = function (evidenceRepo) {
        var _this = this;
        var cachedKey = evidenceRepo.sourceEntityType.singularName + "_" + evidenceRepo.sourceItem.id;
        var isEvidenceApiV2Enabled = this.featuresService.isEnabled(Feature.EvidenceApiV2);
        var evidence$ = this.evidenceMap[cachedKey] ||
            new Observable(function (subscriber) {
                return evidenceRepo
                    .query({
                    where: Object.assign({ EvidenceApiV2: isEvidenceApiV2Enabled }, isEvidenceApiV2Enabled ? { pageSize: 5000 } : null),
                })
                    // ignore completion, only care about unsubscriptions
                    .subscribe(function (v) { return subscriber.next(v); }, function (err) { return subscriber.error(err); });
            }).pipe(takeUntil(this.destroy$), finalize(function () {
                delete _this.evidenceMap[cachedKey];
            }), shareReplay({ bufferSize: 1, refCount: true }));
        this.evidenceMap[cachedKey] = evidence$;
        return evidence$;
    };
    EvidenceService.prototype.destroyEvidence = function () {
        this.destroy$.next();
    };
    EvidenceService.prototype.sortByField = function (fieldId, evidence) {
        var _this = this;
        switch (fieldId) {
            case EVIDENCE_FIELD_IDS.name:
                return evidence.sort(function (a, b) {
                    var aName = (a.name || '').toLowerCase();
                    var bName = (b.name || '').toLowerCase();
                    if (aName !== bName) {
                        return aName > bName ? 1 : -1;
                    }
                    return a.entityType.id > b.entityType.id ? 1 : -1;
                });
            case EVIDENCE_FIELD_IDS.verdict:
            case EVIDENCE_FIELD_IDS.state:
                return evidence.sort(function (a, b) {
                    var aFieldId = a[fieldId] && a[fieldId].id;
                    var bFieldId = b[fieldId] && b[fieldId].id;
                    if (aFieldId !== bFieldId) {
                        if (aFieldId == null) {
                            return -1;
                        }
                        if (bFieldId == null) {
                            return 1;
                        }
                        return aFieldId > bFieldId ? 1 : -1;
                    }
                    return a.firstSeen > b.firstSeen ? 1 : -1;
                });
            case EVIDENCE_FIELD_IDS.detectionContexts:
                return evidence.sort(function (a, b) {
                    if (!isEqual(a.detectionContexts, b.detectionContexts)) {
                        var firstDetectionContextA = a.detectionContexts && a.detectionContexts.length && a.detectionContexts[0];
                        var firstDetectionContextB = b.detectionContexts && b.detectionContexts.length && b.detectionContexts[0];
                        if (!isEqual(firstDetectionContextA, firstDetectionContextB)) {
                            if (!firstDetectionContextA) {
                                return -1;
                            }
                            if (!firstDetectionContextB) {
                                return 1;
                            }
                            var aDetectionName = _this.getEvidenceDetectionContextDisplayName(firstDetectionContextA);
                            var bDetectionName = _this.getEvidenceDetectionContextDisplayName(firstDetectionContextB);
                            if (aDetectionName.toLowerCase() !== bDetectionName.toLowerCase()) {
                                return aDetectionName > bDetectionName ? 1 : -1;
                            }
                            if (firstDetectionContextA.detectionType.id !==
                                firstDetectionContextB.detectionType.id) {
                                return firstDetectionContextA.detectionType.id >
                                    firstDetectionContextB.detectionType.id
                                    ? 1
                                    : -1;
                            }
                            if (firstDetectionContextA.alertSeverity &&
                                firstDetectionContextB.alertSeverity &&
                                firstDetectionContextA.alertSeverity.id !==
                                    firstDetectionContextB.alertSeverity.id) {
                                return firstDetectionContextA.alertSeverity.id >
                                    firstDetectionContextB.alertSeverity.id
                                    ? 1
                                    : -1;
                            }
                            if (firstDetectionContextA.actionStatus &&
                                firstDetectionContextB.actionStatus &&
                                firstDetectionContextA.actionStatus.id !==
                                    firstDetectionContextB.actionStatus.id) {
                                return firstDetectionContextA.actionStatus.id >
                                    firstDetectionContextB.actionStatus.id
                                    ? 1
                                    : -1;
                            }
                        }
                        if (a.detectionContexts.length !== b.detectionContexts.length) {
                            return a.detectionContexts.length > b.detectionContexts.length ? 1 : -1;
                        }
                    }
                    return a.firstSeen > b.firstSeen ? 1 : -1;
                });
            case EVIDENCE_FIELD_IDS.assets:
                return evidence.sort(function (a, b) {
                    if (a.assets.length !== b.assets.length) {
                        return a.assets.length > b.assets.length ? 1 : -1;
                    }
                    if (a.assets.length === 1) {
                        var lowerNameA = a.assets[0].displayName.toLowerCase();
                        var lowerNameB = b.assets[0].displayName.toLowerCase();
                        if (lowerNameA !== lowerNameB) {
                            return lowerNameA > lowerNameB ? 1 : -1;
                        }
                    }
                    return a.firstSeen > b.firstSeen ? 1 : -1;
                });
            default:
                return sortBy(evidence, EVIDENCE_FIELD_IDS.firstSeen);
        }
    };
    EvidenceService.prototype.filterEvidence = function (evidence, key, values) {
        var _this = this;
        var lowerValues = values.map(function (v) { return v.toLowerCase(); });
        switch (key) {
            case EVIDENCE_FIELD_IDS.verdict:
            case EVIDENCE_FIELD_IDS.state:
                return evidence.filter(function (e) {
                    return values.includes(e[key] ? String(e[key].id) : '');
                });
            case EVIDENCE_FIELD_IDS.detectionContexts: {
                return evidence.filter(function (e) {
                    return (e.detectionContexts || []).some(function (detectionContext) {
                        return lowerValues.includes(_this.getEvidenceDetectionContextDisplayName(detectionContext).toLowerCase());
                    });
                });
            }
            case EVIDENCE_FIELD_IDS.assets: {
                return evidence.filter(function (e) {
                    return lowerValues.some(function (v) {
                        if (e.assets && e.assets.length) {
                            return e.assets.map(function (a) { return a.displayName.toLowerCase(); }).includes(v);
                        }
                        return v === '';
                    });
                });
            }
            default:
                return evidence.filter(function (e) { return values.includes(e[key]); });
        }
    };
    EvidenceService.prototype.getFiltersData = function (evidence, options) {
        var _this = this;
        var filteredEvidence = evidence.items;
        Object.entries(options).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            if (EVIDENCE_FIELD_IDS[k]) {
                filteredEvidence = _this.filterEvidence(filteredEvidence, k, v);
            }
        });
        var evidenceEntity = filteredEvidence.reduce(function (acc, ev) {
            Object.keys(EVIDENCE_FIELD_IDS).forEach(function (k) {
                var values = (acc[k] && acc[k].values) || [];
                var filterData;
                switch (k) {
                    case EVIDENCE_FIELD_IDS.name: {
                        filterData = [
                            {
                                id: ev.name,
                                name: ev.name,
                            },
                        ];
                        break;
                    }
                    case EVIDENCE_FIELD_IDS.verdict:
                    case EVIDENCE_FIELD_IDS.state: {
                        filterData = [
                            {
                                id: ev[k] ? String(ev[k].id) : '',
                                name: ev[k] ? String(ev[k].name) : '',
                            },
                        ];
                        break;
                    }
                    case EVIDENCE_FIELD_IDS.detectionContexts: {
                        var detectionNames = ev.detectionContexts &&
                            new Set(ev.detectionContexts.map(function (detectionContext) {
                                return _this.getEvidenceDetectionContextDisplayName(detectionContext);
                            }));
                        filterData =
                            detectionNames &&
                                Array.from(detectionNames).map(function (name) { return ({
                                    id: name,
                                    name: name,
                                }); });
                        break;
                    }
                    case EVIDENCE_FIELD_IDS.assets: {
                        filterData =
                            ev.assets && ev.assets.length
                                ? Array.from(new Set(ev.assets.map(function (a) { return a.displayName; }))).map(function (name) { return ({
                                    id: name,
                                    name: name,
                                }); })
                                : [{ id: '', name: '' }];
                        break;
                    }
                    default:
                        return;
                }
                var existingValues = values.filter(function (v) {
                    return Object.values(filterData)
                        .map(function (f) { return f.id; })
                        .includes(v.id);
                });
                if (existingValues.length) {
                    existingValues.forEach(function (v) {
                        v.count++;
                    });
                }
                else {
                    values = values.concat(filterData.map(function (f) { return ({ id: f.id, name: f.name, value: f.id, count: 1 }); }));
                }
                acc[k] = {
                    values: values,
                    count: values.length,
                };
            });
            return acc;
        }, {});
        return Object.entries(evidenceEntity).reduce(function (acc, _a) {
            var k = _a[0], val = _a[1];
            if (val.values.length > MAX_FILTER_VALUES) {
                acc[k] = __assign({}, val, { values: sortBy(val.values, '-count').slice(0, MAX_FILTER_VALUES) });
            }
            else {
                acc[k] = val;
            }
            return acc;
        }, {});
    };
    EvidenceService.prototype.getEvidenceDetectionContextDisplayName = function (detectionContext) {
        if (!detectionContext) {
            return '';
        }
        var detectionNameFromI18nKey = detectionContext.detectionNameI18nKey &&
            this.i18nService.get(detectionContext.detectionNameI18nKey, null, true);
        return detectionNameFromI18nKey || detectionContext.detectionName || '';
    };
    EvidenceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EvidenceService_Factory() { return new EvidenceService(i0.ɵɵinject(i1.I18nService), i0.ɵɵinject(i2.FeaturesService)); }, token: EvidenceService, providedIn: "root" });
    return EvidenceService;
}());
export { EvidenceService };
export { ɵ0 };
