import { Feature } from '@wcd/config';

export interface EndpointManagementPackage {
	id: number;
	name?: string;
	features?: Array<Feature>;
	disableFeatures?: Array<Feature>;
	disableFlow?: Array<EndpointManagementFlow>;
}

export class EndpointManagementProcess {
	id: number;
	name: string;
	processType: EndpointManagementProcessType;
	features: Array<Feature>;
	disableFeatures: Array<Feature>;
	isServer?: boolean;
	isDefault?: boolean;
	unsupportedPackageTypes?: Array<EndpointManagementPackage>;
	isDownlevel?: boolean;

	constructor(data: EndpointManagementProcessConfig) {
		Object.assign(this, data);
	}
}

export interface EndpointManagementProcessConfig {
	id: number;
	name: string;
	processType: EndpointManagementProcessType;
	features?: Array<Feature>;
	disableFeatures?: Array<Feature>;
	isServer?: boolean;
	isDefault?: boolean;
	unsupportedPackageTypes?: Array<EndpointManagementPackage>;
}

export interface EndpointManagementProcessType {
	id: string;
	features?: Array<Feature>;
}

export interface EndpointManagementFlow {
	id: string;
	enabled: boolean;
}
