import { FeaturesService, Feature } from '@wcd/config';

declare const moment: typeof import('moment');
import { SevilleModule } from '../../../seville/seville.module';
import { MachinesService } from '../../../../../@entities/machines/services/machines.service';
import { HybridRoutingService } from '../../../../../hybrid-routing.service';
import { IpsService } from '../../../../../@entities/ips/services/ips.service';
import { FilesService } from '../../../../../@entities/files/services/files.service';

declare let angular: angular.IAngularStatic;

SevilleModule.directive('timelineGrid', timelineGridDirective);
SevilleModule.controller('seville.threatintel.grid', gridController);

timelineGridDirective.$inject = [];

function timelineGridDirective() {
	return {
		restrict: 'EA',
		scope: {
			loading: '=',
			partialResultsLoaded: '=',
			events: '=',
			recordsPerPage: '=',
			maskResults: '=',
			inColdStorage: '=',
			loadTimeline: '&',
			entityname: '=?',
		},
		template: `
		<div>
        <div ng-repeat="event in timelinegrid.eventsWithDates" right-click="timelinegrid.printJsonToDebug(event)"
        	ng-class="{'mask-timeline-results': timelinegrid.maskResults }" ng-if="(!timelinegrid.loading || timelinegrid.partialResultsLoaded) && !timelinegrid.maskResults">

            <div ng-if="::event.dateGroup" class="event-timeline-group-title">
                <b>
                    <!-- Date -->
                    {{::event.dateGroup}}
                </b>
            </div>

            <div ng-if="::!event.dateGroup" class="event-timeline-event row hover-default side-pane-container">
                <div class="row-same-height pointer event-timeline-event-main nopadding" ng-click="timelinegrid.expandEvent(event)">
                    <div class="col-md-1 col-md-height col-middle">
                        <!-- Time -->
                        {{::event.filteredtime}}
                    </div>
                    <div class="col-md-height col-middle overflow-all event-timeline-desc col-md-5">
                        <span>
                            <i class="icon machine-timeline-icon icon-DeviceLaptopNoPic"></i>
                            <span ng-if="::!event.containerId">
                                <side-pane-machine machineid="event.machineId" computerdnsname="event.computerDnsName" />
                                <a href="{{::timelinegrid.getMachineLink(event.machineId, event.parsedEventISOTime)}}"
                                	data-track-id="GoToMachine"
									data-track-type="Navigation"
                                	ng-click="timelinegrid.goToMachine(event.machineId, event.parsedEventISOTime, $event)">
                                    {{event.accountDisplayName}}
                                </a>
                            </span>
                            <span ng-if="::event.containerId">
                                {{event.accountDisplayName}}
                            </span>
                        </span>
                    </div>
                    <div class="col-md-1 col-md-height col-middle">
                        <!-- event in container - appguard icon -->
                        <i ng-if="::event.containerId"
                           ng-style="::{'visibility': event.containerId ? 'visible':'hidden'}"
                           class="icon machine-timeline-icon icon-DeviceGuard machine-timeline-detection-active"
                           tooltips tooltip-size="large" tooltip-title="Contained in Appguard" tooltip-side="right" tooltip-speed="slow"></i>
                    </div>
                    <div class="col-md-height col-middle overflow machine-timeline-details-col col-md-5">
                        <table class="event-desc-table">
                            <tr>
                                <td class="overflow-all">
                                    <div class="overflow-all">
                                        <side-pane-file sha1="::event.sha1" filename="::event.filename" hide-indicator="::!event.filename || !event.valid"/>
										<a href="{{::timelinegrid.getFileLink(event.sha1, event.filename)}}"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
                                        	ng-if="::event.filename && event.valid">{{::event.filename}}</a>
                                        <span ng-if="::event.filename && !event.valid">{{::event.filename}}</span>
                                        <span ng-if="::!event.filename && !event.valid">Unknown process</span>
                                        <span ng-if="::event.source">
                                            <!-- url timeline -->
                                            {{:: event.source === 'Download'? 'downloaded from' : event.message}}
                                            <span tooltips tooltip-title="{{:: event.uri.length > 50 ? event.uri : ''}}" tooltip-side="left">{{:: event.uri | limitTo:50}}{{:: event.uri.length > 50 ? '...': ''}}</span>
                                        </span>
                                        <span ng-if="::!event.source && !event.desc">
                                            <!-- ip timeline -->
                                            {{event.message}} {{::timelinegrid.entityname}}<span ng-if="event.destinationPort">:{{::event.destinationPort}}</span>
                                            <span ng-if="event.destinationDnsRecordName">({{event.destinationDnsRecordName | limitTo:50}}{{event.destinationDnsRecordName > 50 ? '...' : ''}})</span>
                                        </span>
                                        <span ng-if="::!event.source && event.desc">{{::event.desc}}</span>
                                        <span ng-if="::!event.source && event.ips.length > 0">
                                            communicated with
                                            <span ng-if="::event.ips" ng-repeat="ip in event.ips | limitTo: 3">
												<a href="{{::timelinegrid.getIpLink(ip, event.parsedEventISOTime)}}"
													ng-click="timelinegrid.goToIp(ip, event.parsedEventISOTime, $event)"
                                                	data-track-id="GoToIp"
													data-track-type="Navigation">{{::ip}}</a><span ng-if="::!$last">, </span>
                                            </span>
                                            <span ng-if="::event.moreIPs || event.ips.length > 3">and more</span>
                                        </span>
                                        <span ng-if="::event.containerId">in AppGuard container</span>
                                    </div>
                                </td>
                                <td class="text-right machine-timeline-expand-icon-container">
                                    <i class="icon machine-timeline-expand-icon icon-AddTo" ng-if="!event.showdetails"></i>
                                    <i class="icon machine-timeline-expand-icon icon-RemoveFrom" ng-if="event.showdetails"></i>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <!-- Collapsed Zone -->
                <div ng-include src="'/timeline-collapsed.html'"
                     ng-if="event.showdetails">
                </div>
            </div>
        </div>
        <div class="loading-layer" ng-if="timelinegrid.loading || timelinegrid.partialResultsLoaded">
            <img src="/assets/images/circle-loading.gif" />
            <div class="loading-from-archive" ng-if="timelinegrid.inColdStorage">
                <div>Retrieving data from archive</div>
                <div>This action may take a few minutes</div>
            </div>
        </div>
        <div ng-if="!timelinegrid.loading && timelinegrid.maskResults" class="text-center user-select-results-title">
            <button class="btn btn-link timebar-select-button text-center" data-toggle="dropdown" aria-expanded="true" type="button" ng-click="timelinegrid.loadEntityTimelineResults()">
                <span> Selected time period has changed. Click to view results </span>
                <i class="shell-header-dropdown-label-icon icon icon-Sell rotate-270 user-select-results-button"></i>
            </button>
        </div>
        <div ng-if="!timelinegrid.loading && timelinegrid.eventsWithDates.length == 0 && !timelinegrid.maskResults"
             class="text-center events-timeline-norecords">
            <span>
                No items to show in the timeline.
                Check back again soon or change the search criteria.
            </span>
        </div>
        <div ng-if="!timelinegrid.loading && user.eventsWithDates.length == 0 && timelinegrid.error && !timelinegrid.maskResults" class="text-center events-timeline-norecords">
            <div class="entity-meta-main-title machine-timeline-error">
                Refresh the page
            </div>
            <div>
                The service couldn’t complete the search. Refresh the page and try again.
            </div>
            <div>
                If that doesn’t work, check back again soon or try changing the search criteria.
            </div>
        </div>
    </div>

    <!-- Collapsed Zone template for IP, File and Url -->
    <script type="text/ng-template" id="/timeline-collapsed.html">
        <div class="event-timeline-more event-timeline-more-animate" ng-if="event.showdetails">
            <div class="row event-timeline-section" ng-if="::event.files.TotalResults > 0" ng-repeat="file in event.files.Items">
                <div class="event-timeline-associated-record overflow-all col-md-6 col-md-offset-1">
                    <i ng-if="::file.Valid" class="icon machine-timeline-icon flip-vertical icon-ExitRight"></i>
                    <span ng-if="::file.Path">{{:: file.Path }}</span>
                    <span ng-if="::!file.Path && file.Name">{{:: file.Name }}</span>
                </div>
                <div class="col-md-5 overflow-all" ng-if="::file.Sha1">
					<a href="{{::timelinegrid.getFileLink(file.Sha1)}}"
                    	data-track-id="GoToFile"
						data-track-type="Navigation"
                    	ng-if="::file.Valid">
                        {{::file.Sha1}}
                    </a>
                    <span ng-if="::!file.Valid">{{::file.Sha1}}</span>
                </div>
            </div>
        </div>
    </script>
		`,
		bindToController: true,
		controllerAs: 'timelinegrid',
		controller: 'seville.threatintel.grid',
	};
}

gridController.$inject = [
	'$rootScope',
	'$scope',
	'$log',
	'coldStorage',
	'$filter',
	'$state',
	'featuresService',
	'ng2router',
	'machinesService',
	'ipsService',
	'filesService',
];

function gridController(
	$rootScope,
	$scope,
	$log,
	coldStorage,
	$filter,
	$state,
	featuresService: FeaturesService,
	ng2router: HybridRoutingService,
	machinesService: MachinesService,
	ipsService: IpsService,
	filesService: FilesService
) {
	var vm = this;
	var sevilleDateFilter = $filter('sevilleDate');
	var wcdMachineIdFilter = $filter('wcdMachineId');
	var partsFilter = $filter('parts');
	vm.dateNow = new Date();

	vm.expandEvent = function(event) {
		event.showdetails = !event.showdetails;
	};

	vm.loadEntityTimelineResults = function() {
		vm.maskResults = false;
		vm.loadTimeline();
	};

	vm.getFileLink = (fileId, fileName?) => filesService.getFileLink(fileId, null, fileName);

	function arrangeEventsByDate() {
		if (!vm.events) {
			vm.eventsWithDates = [];

			return;
		}

		vm.eventsWithDates = [];
		var newCollection = [];
		var lastDate = '';
		for (var i = 0; i < vm.recordsPerPage && i < vm.events.length; i++) {
			var event = vm.events[i];
			var date = sevilleDateFilter(event.time, 'date');

			if (date != lastDate) {
				lastDate = date;
				newCollection.push({ dateGroup: date });
			}

			event.dateGroupName = date;
			event.parsedEventISOTime = getEventRoundedTime(event); // required for the get request for the machine time line - so the event will be on the top

			applyFiltersOnEvent(event);
			newCollection.push(event);
		}

		vm.eventsWithDates = newCollection;
	}

	function applyFiltersOnEvent(event) {
		event.filteredtime = sevilleDateFilter(event.time, 'time');
		event.computerDnsNameFirstPart = partsFilter(event.computerDnsName, 1);
		event.wcdMachineId = wcdMachineIdFilter(event.wcdMachineId);
		event.accountDisplayName = getMachineNameToDisplay(
			event.computerDnsName,
			event.computerDnsNameFirstPart,
			event.wcdMachineId,
			event.containerId
		);
	}

	function getMachineNameToDisplay(computerDnsName, computerDnsNameFirstPart, wcdMachineId, containerId) {
		var containerSuffix = containerId ? '\\' + containerId : '';

		if (computerDnsName) {
			return computerDnsNameFirstPart + containerSuffix;
		}

		if (wcdMachineId) {
			return wcdMachineId + containerSuffix;
		}

		return '1 device' + (containerId ? ' in appguard ' + containerId : '');
	}

	vm.getMachineLink = (machineId, time) => {
		const eventTime = (time && new Date(time)) || vm.dateNow;
		if (featuresService.isEnabled(Feature.UpgradeMachinePage)) {
			return machinesService.getMachineLink(machineId, true, eventTime);
		} else {
			return $state.href('machine', {
				id: machineId,
				time: eventTime.toISOString(),
			});
		}
	};

	vm.goToMachine = (machineId, time, $event) => {
		$event.preventDefault();
		$event.stopPropagation();

		if (featuresService.isEnabled(Feature.UpgradeMachinePage)) {
			ng2router.navigateByUrl(vm.getMachineLink(machineId, time));
		} else {
			$state.go('machine', {
				id: machineId,
				time: time,
			});
		}
	};

	vm.getIpLink = (ipId, time) => {
		const eventTime = (time && new Date(time)) || vm.dateNow;
		if (featuresService.isEnabled(Feature.UpgradeIpPage)) {
			return ipsService.getIpLink(ipId, eventTime);
		} else {
			return $state.href('ip', {
				id: ipId,
				time: eventTime.toISOString(),
			});
		}
	};

	vm.goToIp = (ipId, time, $event) => {
		$event.preventDefault();
		$event.stopPropagation();

		if (featuresService.isEnabled(Feature.UpgradeIpPage)) {
			ng2router.navigateByUrl(vm.getIpLink(ipId, time));
		} else {
			$state.go('ip', {
				id: ipId,
				time: time,
			});
		}
	};

	vm.printJsonToDebug = function(eventRecord) {
		var item: any = { event: {}, json: {} };

		angular.copy(eventRecord, item.event);
		angular.copy(eventRecord.json, item.json);
		delete item.event.json;

		console.dir(item);
	};

	$scope.$watch(
		function() {
			return vm.events;
		},
		function(newValue, oldValue) {
			if (newValue && newValue !== oldValue) {
				vm.eventsWithDates = [];
				arrangeEventsByDate();
			}
		}
	);

	// returns an iso string of the event time increased by 1ms
	function getEventRoundedTime(event) {
		return moment(event.time)
			.add(1, 'ms')
			.toISOString();
	}
}
