import { Entity, EntityField } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { HuntingQueryBase } from './hunting-query.entity.base';
import { QueryKind } from './hunting-query.type';

/**
 * A hunting query entity that is configured to use the new hunting service for saving / loading queries.
 * @todo replace HuntingQueryBase, HuntingUserQuery, HuntingSharedQuery, and HuntingScheduledQuery with this one
 */
@Entity({
	singularName: 'Hunting query',
	pluralName: 'Hunting queries',
	endpoint: 'queries',
	saveMethod: ({ id }) => (id ? 'PATCH' : 'POST'),
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	serializeItem: (item, serializedItem) => ({
		...serializedItem,
		Shared: item.kind !== 'user',
	}),
})
export class HuntingSavedQuery extends HuntingQueryBase {
	@EntityField({
		parse: (_, rawData) => (rawData.IsScheduled ? 'scheduled' : rawData.UserId ? 'user' : 'shared'),
		serialize: false,
	})
	readonly kind: QueryKind;

	@EntityField({ data: 'IsScheduled' })
	readonly isScheduled: boolean;

	@EntityField({ data: 'UserId', parse: userId => !userId, serialize: false })
	readonly shared: boolean;
}
