import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ProcessActionDetails } from '@wcd/domain';

@Component({
	selector: 'process-action-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl [class.key-values]="asKeyValueList" role="none">
			<dt>{{ 'alerts.events.details.actionTime' | i18n }}</dt>
			<dd>{{ action.actionTime | date: 'medium' }}</dd>
		</dl>
		<ng-container *ngIf="action.process">
			<process-entity-details
				[entity]="action.process"
				[showEntityPanelLink]="false"
				[asKeyValueList]="asKeyValueList"
			></process-entity-details>
		</ng-container>
	`,
})
export class ProcessActionEntityDetailsComponent extends EntityDetailsComponentBase<ProcessActionDetails> {
	get action(): ProcessActionDetails {
		return this.entity;
	}

	constructor(injector: Injector) {
		super(injector);
	}
}
