
		<ng-container *ngIf="detectionContexts?.length" [ngPlural]="detectionContexts?.length">
			<ng-template ngPluralCase="=1">
				<ng-container *ngTemplateOutlet="firstDetectionContext"></ng-container>
			</ng-template>
			<ng-template ngPluralCase="other">
				<div class="wcd-flex-horizontal">
					<ng-container *ngTemplateOutlet="firstDetectionContext"></ng-container>
					<span
						wcdTooltip
						class="wcd-padding-left"
						[tooltipInnerComponent]="EvidenceDetectionContextTooltipComponent"
						[innerComponentInput]="{ detectionContexts: detectionContexts.slice(1) }"
					>
						{{
							'evidence_fields_detectionContext_extraAlerts'
								| i18n
									: {
											alertCount: detectionContexts.length - 1
									  }
						}}
					</span>
				</div>
			</ng-template>
		</ng-container>
		<ng-template #firstDetectionContext>
			<evidence-detection-context
				[detectionContext]="detectionContexts[0]"
				class="wcd-text-overflow-ellipsis"
			>
			</evidence-detection-context>
		</ng-template>
	