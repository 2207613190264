import { Component, Input, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { SpinnerSize } from 'office-ui-fabric-react';
import { A11yAnnouncerService } from '@wcd/shared';

const REPEAT_ANNOUNCEMENT_INTERVAL = 5000;
enum LoadingStatusKeys {
	loading = 'loading',
	stillLoading = 'stillLoading',
	loadingComplete = 'loadingComplete',
}

@Component({
	selector: 'accessible-spinner',
	template: `
		<fab-spinner
			[label]="message"
			[size]="SpinnerSize[spinnerSize]"
			[labelPosition]="labelPosition"
		></fab-spinner>
	`,
})
export class AccessibleSpinnerComponent implements AfterViewInit, OnDestroy {
	@Input() loadingEntityName?: string = '';
	@Input() customLabel?: string;
	@Input() customStillLoadingLabel?: string;
	@Input() longLoadingExpected?: boolean;
	@Input() politeness: 'assertive' | 'polite' = 'assertive';
	@Input() labelPosition: "top" | "right" | "bottom" | "left" = "bottom";
	@Input() spinnerSize: "xSmall" | "small" | "medium" | "large" = "large";

	private loadingTimer = null;
	private stillLoading: boolean = false;
	message: string;
	SpinnerSize = SpinnerSize;

	constructor(private i18nService: I18nService,
		private liveAnnouncer: A11yAnnouncerService, 
		private changeDetectionRef: ChangeDetectorRef) {}

	ngAfterViewInit(){
		this.announce(true);
	}

	private announce(stillLoading: boolean) {
		this.stillLoading = stillLoading;
		this.message = this.getMessage(LoadingStatusKeys.loading);
		this.changeDetectionRef.markForCheck();
		if (this.stillLoading) {
			this.stillLoadingRepeater();
		} else {
			clearTimeout(this.loadingTimer);
			this.message = this.getMessage(LoadingStatusKeys.loadingComplete);
			this.changeDetectionRef.markForCheck();
		}
		this.liveAnnouncer.announce(this.message, this.politeness);
	}

	private stillLoadingRepeater() {
		this.loadingTimer = setTimeout(() => {
			if (this.stillLoading) {
				this.message = this.getMessage(LoadingStatusKeys.stillLoading);
				this.changeDetectionRef.markForCheck();
				this.liveAnnouncer.announce(this.message);
				this.stillLoadingRepeater();
			}
		}, this.longLoadingExpected ? 2 * REPEAT_ANNOUNCEMENT_INTERVAL : REPEAT_ANNOUNCEMENT_INTERVAL);
	}

	private getMessage(status: LoadingStatusKeys) {
		if (status === LoadingStatusKeys.loading && this.customLabel) {
			return this.customLabel;
		} else if (status === LoadingStatusKeys.stillLoading && (this.customStillLoadingLabel || this.customLabel)) {
			return this.customStillLoadingLabel || this.customLabel;
		}
		const key = `accessibleSpinner.${status}`;
		return this.i18nService.get(key, {
			entityName: this.loadingEntityName,
		});
	}

	ngOnDestroy() {
		this.announce(false);
		clearTimeout(this.loadingTimer);
	}
}
