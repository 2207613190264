import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Dictionary } from '@wcd/config';
import { Notification, NotificationConfig } from '../models/notification.model';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';

import { IPreload } from '@wcd/shared';
import { Router } from '@angular/router';
import { isNil } from 'lodash-es';
import { share, filter } from 'rxjs/operators';
import { PreferencesService } from '@wcd/config';

const SNOOZE_PREFERENCE_ID = 'notificationsSnoozed';
const SNOOZE_TIME = 60 * 60 * 1000; // In milliseconds

@Injectable({
	providedIn: 'root',
})
export class NotificationsService implements IPreload, OnDestroy {
	notifications$: Observable<Array<Notification>>;
	highPriorityNotifications$: Observable<Array<Notification>>;
	notificationGroups$: Observable<Array<Notification>>;
	snoozed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	private _prefereceServiceSub: Subscription;
	private _updateNotificationsTimeout;
	private _notifications: Dictionary<string, Notification> = new Dictionary<string, Notification>();
	private _highPriorityNotifications: Dictionary<string, Notification> = new Dictionary<
		string,
		Notification
	>();
	private _notificationGroups: Dictionary<string, Notification> = new Dictionary<string, Notification>();
	private _notificationsBehaviorSubject: BehaviorSubject<Array<Notification>> = new BehaviorSubject([]);
	private _highPriorityNotificationsBehaviorSubject: BehaviorSubject<
		Array<Notification>
	> = new BehaviorSubject([]);
	private _notificationGroupsBehaviorSubject: BehaviorSubject<Array<Notification>> = new BehaviorSubject(
		[]
	);
	private _isSnoozed: boolean = false;
	private _snoozeTimeout;
	private hiddenHighPriorityNotificationIds: Set<string> = new Set();

	constructor(
		private ngZone: NgZone,
		private preferencesService: PreferencesService,
		private router: Router
	) {
		this.notifications$ = this._notificationsBehaviorSubject.asObservable().pipe(share());
		this.highPriorityNotifications$ = this._highPriorityNotificationsBehaviorSubject
			.asObservable()
			.pipe(share());
		this.notificationGroups$ = this._notificationGroupsBehaviorSubject.asObservable();
	}

	init(): Observable<null> {
		this._prefereceServiceSub = this.preferencesService.preferences$.subscribe(
			(preferences: Map<string, any> | Dictionary<string, any>) => {
				const snoozed: { from: number } = preferences && preferences.get(SNOOZE_PREFERENCE_ID);
				if (isNil(snoozed)) this.unSnoozeNotifications();
				else {
					const timeLeftForSnooze: number = snoozed.from + SNOOZE_TIME - new Date().valueOf();

					if (timeLeftForSnooze > 0) {
						this.snoozed$.next((this._isSnoozed = true));
						this.setSnoozeTimeout(timeLeftForSnooze);
					} else this.unSnoozeNotifications();
				}
			}
		);

		return of(null);
	}

	setNotification(notificationId: string, notificationConfig: NotificationConfig) {
		if (!notificationConfig) return this.removeNotification(notificationId);

		notificationConfig.id = notificationId;
		const notification = new Notification(notificationConfig);
		this._notifications.set(notificationId, notification);

		this.updateNotifications();
	}

	setNotifications(notifications: Array<NotificationConfig>) {
		notifications.forEach(notification => this.setNotification(notification.id, notification));
	}

	removeNotification(notificationId: string) {
		this._notifications.delete(notificationId);
		this.updateNotifications();
	}

	removeNotificationGroup(groupId: string) {
		if (!groupId) return;

		const groupNotificationIds = [];

		this._notifications.forEach(notification => {
			if (notification.group === groupId) groupNotificationIds.push(notification.id);
		});

		if (groupNotificationIds.length) {
			groupNotificationIds.forEach(notificationId => this._notifications.delete(notificationId));
			this.updateNotifications();
		}
	}

	setHiddenHighPriorityNotificationId(notificationId: string, filterOut: boolean = true) {
		if (filterOut) this.hiddenHighPriorityNotificationIds.add(notificationId);
		else this.hiddenHighPriorityNotificationIds.delete(notificationId);

		this.updateHighPriorityNotifications();
	}

	selectNotification(notification: Notification) {
		if (notification.link) this.router.navigate(notification.link);
	}

	updateHighPriorityNotifications() {
		this._highPriorityNotificationsBehaviorSubject.next(
			this._highPriorityNotifications
				.toArray()
				.filter(
					notification =>
						!notification.isMinimized &&
						!this.hiddenHighPriorityNotificationIds.has(notification.id)
				)
		);
	}

	snoozeNotifications(): void {
		this.setSnooze(true);
	}

	unSnoozeNotifications(): void {
		this.setSnooze(false);
	}

	/**
	 * Sets the snooze and saves the user preference (or removes it if snooze is off)
	 * @param isSnoozed Whether the snooze is turned on or off
	 */
	setSnooze(isSnoozed: boolean): void {
		if (isSnoozed === this._isSnoozed) return;

		this.snoozed$.next((this._isSnoozed = isSnoozed));

		if (isSnoozed) {
			const snoozeStart: Date = new Date();
			this.preferencesService.setPreference(SNOOZE_PREFERENCE_ID, {
				from: snoozeStart.valueOf(),
			});
			this.setSnoozeTimeout(SNOOZE_TIME);
			this._highPriorityNotifications.clear();
			this._highPriorityNotificationsBehaviorSubject.next([]);
		} else {
			clearTimeout(this._snoozeTimeout);
			this.preferencesService.removePreference(SNOOZE_PREFERENCE_ID);
			this.updateNotifications();
		}
	}

	private setSnoozeTimeout(time: number): void {
		clearTimeout(this._snoozeTimeout);
		this._snoozeTimeout = setTimeout(this.unSnoozeNotifications.bind(this), time);
	}

	private updateNotifications() {
		clearTimeout(this._updateNotificationsTimeout);

		this._updateNotificationsTimeout = setTimeout(() => {
			const notificationsArray = this._notifications.toArray().sort(this.sortNotifications);
			this._notificationsBehaviorSubject.next(notificationsArray);
			this.setNotificationGroups();
			this._notificationGroupsBehaviorSubject.next(this._notificationGroups.toArray());

			const highPriorityNotifications: Dictionary<string, Notification> = Dictionary.fromList<
					string,
					Notification
				>(notificationsArray.filter(notification => notification.isHighPriority), 'id'),
				highPriorityNotificationsChanged: boolean = !this._highPriorityNotifications.equalsWith(
					highPriorityNotifications,
					(currentNotification, newNotification) => {
						return (
							currentNotification &&
							newNotification &&
							(currentNotification === newNotification ||
								currentNotification.timestamp === newNotification.timestamp)
						);
					}
				);

			if (highPriorityNotificationsChanged) {
				this._highPriorityNotifications = highPriorityNotifications;
				this.updateHighPriorityNotifications();
			}
		}, 500);
	}

	private sortNotifications(a: Notification, b: Notification): number {
		if (a.priority === b.priority) return 0;

		return a.priority > b.priority ? 1 : -1;
	}

	private setNotificationGroups() {
		this._notificationGroups.clear();

		this._notifications
			.toArray()
			.sort(this.sortNotifications)
			.forEach(notification => {
				if (notification.group) {
					let existingGroup = this._notificationGroups.get(notification.group);
					if (!existingGroup) {
						existingGroup = notification.clone();
						existingGroup.count = 0;

						this._notificationGroups.set(notification.group, existingGroup);
					}

					existingGroup.count += notification.count || 1;
				} else {
					this._notificationGroups.set(notification.id, notification.clone());
				}
			});
	}

	ngOnDestroy() {
		this._prefereceServiceSub && this._prefereceServiceSub.unsubscribe();
		this.snoozed$.complete();
	}
}
