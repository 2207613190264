import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { TooltipsService } from './tooltips.service';

@Component({
	selector: 'wcd-tooltip-placeholder',
	template: `
		<div #containerDiv>
			<ng-container #tooltipPlaceholder></ng-container>
		</div>
	`,
})
export class WcdTooltipPlaceholderComponent implements OnInit, OnDestroy {
	@ViewChild('tooltipPlaceholder', { read: ViewContainerRef, static: true })
	tooltipPlaceholder;

	@ViewChild('containerDiv', { static: true })
	tooltipContainerDiv: ElementRef<HTMLElement>;

	constructor(private tooltipsService: TooltipsService) {}

	ngOnInit(): void {
		this.tooltipsService.registerViewContainerRef(this.tooltipPlaceholder);
		this.tooltipsService.registerTooltipContainerElement(this.tooltipContainerDiv);
	}

	ngOnDestroy(): void {
		this.tooltipsService.unregisterViewContainerRef();
		this.tooltipsService.unregisterTooltipContainerElement();
	}
}
