import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HuntingRule } from '@wcd/domain';

@Component({
	selector: 'scheduled-hunting-run-time',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="rule.intervalHours !== 0; else continuousRule">
			{{ runTime | date: 'medium' }}
		</ng-container>
		<ng-template #continuousRule>
			{{ 'hunting_scheduledMonitorSidePane_fields_huntingRuleFrequency_continuous' | i18n }}
		</ng-template>
	`,
})
export class ScheduledHuntingRunTimeComponent {
	@Input() rule: HuntingRule;

	@Input() runTime?: Date;
}
