import { HttpClient } from '@angular/common/http';
import { HttpOptions, IReadonlyRepository, ModelBase, Repository } from '@microsoft/paris';
import { Injectable } from '@angular/core';
import { ItemActionModel } from '../models/item-action.model';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { ConfirmEvent } from '../../dialogs/confirm/confirm.event';
import { DataviewField } from '@wcd/dataview';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterValuesChecklistValueData } from '@wcd/ng-filters';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class DataviewRepositoryService {
	constructor(
		private http: HttpClient,
		private dialogsService: DialogsService,
		private i18nService: I18nService
	) {}

	getFilterValuesForRepository(
		repository: IReadonlyRepository,
		fixedOptions?: { [index: string]: any }
	): (options?: { [index: string]: any }) => Observable<Record<string, any>> {
		return (options?: { [index: string]: any }) =>
			this.http.get<Record<string, any>>(`${repository.getEndpointUrl({ where: options })}/filters`, {
				params: Object.assign({}, options, fixedOptions),
			});
	}

	getSearchFilterValuesForRepository(
		repository: IReadonlyRepository,
		fixedOptions?: { [index: string]: any }
	): (
		field: DataviewField,
		term: string,
		options?: { [index: string]: any }
	) => Promise<Array<FilterValuesChecklistValueData>> {
		return (field: DataviewField, term: string, options?: { [index: string]: any }) => {
			const searchParams = Object.assign({}, options, { field: field.id, term: term }, fixedOptions);

			return this.http
				.get(`${repository.getEndpointUrl({ where: options })}/all`, {
					params: searchParams,
				})
				.pipe(map((result: any) => result.data))
				.toPromise();
		};
	}

	getRemoveItemAction<T extends ModelBase>(
		repository: Repository<T>,
		fixedOptions?: { [index: string]: any },
		separateRemoveForEachItem?: boolean
	): ItemActionModel {
		return new ItemActionModel({
			id: 'delete',
			name: 'Delete',
			icon: 'delete',
			refreshOnResolve: true,
			closeOnAction: true,
			method: (items: Array<T>) => {
				return new Promise((resolve, reject) => {
					const remove = () => {
						const options: HttpOptions = fixedOptions ? { params: fixedOptions } : null;
						const doRemove: () => Observable<Array<T>> = separateRemoveForEachItem
							? () => combineLatest(items.map((item) => repository.removeItem(item, options)))
							: () => repository.remove(items, options);

						doRemove()
							.toPromise()
							.then((items) => {
								resolve(items);

								this.dialogsService.showSnackbar({
									text: `Deleted ${(items.length === 1
										? repository.entity.singularName
										: items.length + ' ' + repository.entity.pluralName
									).toLowerCase()}`,
									icon: 'delete',
									iconClassName: 'color-text-error',
								});
							}, onError);
					};

					const onError = (error) => {
						this.dialogsService.showError({
							title: this.i18nService.get('common.error.failedDeleteItem', {
								itemName:
									items && items.length === 1
										? repository.entity.singularName
										: repository.entity.pluralName,
							}),
							data: error,
						});

						reject(error);
					};

					const itemName: string =
						items.length === 1
							? `this ${repository.entity.singularName}`
							: `these ${items.length} ${repository.entity.pluralName}`;

					return this.dialogsService
						.confirm({
							title: `Delete ${(items.length === 1
								? repository.entity.singularName
								: items.length + ' ' + repository.entity.pluralName
							).toLowerCase()}`,
							text: `Are you sure you wish to delete ${itemName.toLowerCase()}?`,
							confirmText: 'Delete',
						})
						.then((e: ConfirmEvent) => (e.confirmed ? remove() : resolve(false)));
				});
			},
			tooltip: 'Delete the selected ' + repository.entity.pluralName,
		});
	}
}
