import { EventEmitter, Injectable } from '@angular/core';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import {
	ActionHistory,
	ActionHistoryRelatedEntity,
	ActionSourceType,
	AdminActionSource,
	AdminActionType,
	AirsEntityType,
	Machine,
	MachineMachineRequestsStateRelationship,
	MachineRequestsState,
	MachineResponseType,
	ManualActionEmailData,
	ManualActionOnEmailApiCall,
	ManualAdminActionRequest,
	MdoManualAction,
	RemediationActionType,
	RemediationActionTypeTypes,
	SubActionTypeValue,
	UndoRemediationApiCall,
	ManualActionOnUserApiCall,
	ManualAdminUserActionRequest,
	ManualUserActionType,
	AlertV3EntityTypes,
} from '@wcd/domain';
import { ConfirmationService } from '../../../dialogs/confirm/confirm.service';
import {
	ItemActionIconName,
	ItemActionModel,
	ItemActionModelConfig,
} from '../../../dataviews/models/item-action.model';
import { ActionHistoryEntityPanelComponent } from '../components/action-history.entity-panel.component';
import { I18nService, Strings } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { Paris } from '@microsoft/paris';
import { combineLatest, defer, from, Observable, of } from 'rxjs';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { InvestigationsService } from '../../investigations/services/investigations.service';
import { MtpInvestigationsService } from '../../mtp_investigations/services/mtp-investigations.service';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { filter, map, take } from 'rxjs/operators';
import { compact, flatMap, uniqBy } from 'lodash-es';
import { ActionFinderService } from './pending-action-finder.service';
import { AddFilesToAllowlistModal } from '../../remediation/components/add-files-to-allowlist.modal';
import { ActionHistoryConverterService } from './action-history-converter.service';
import { MachineRequestsPermissionsService } from '../../machines/services/machines-requests.permissions.service';
import { MachinesActionsService } from '../../machines/services/machines-actions.service';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AppFlavorConfig } from '@wcd/scc-common';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { v4 as uuid4 } from 'uuid';
import { AuthService } from '@wcd/auth';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';

const ACTION_TYPE_TO_MACHINE_RESPONSE: Partial<
	Record<RemediationActionTypeTypes, MachineResponseType>
> = Object.freeze({
	[RemediationActionTypeTypes.restrict_app_execution_response]: 'RestrictExecutionResponse',
	[RemediationActionTypeTypes.isolate_response]: 'IsolationResponse',
});

const BASE_UNDO_CONFIG: ItemActionModelConfig<ActionHistory> = Object.freeze({
	id: 'undo',
	rbac: [RbacMdeAllowedActions.remediationActions],
	nameKey: 'actionsHistory_undo_action',
	icon: FabricIconNames.Undo,
	closeOnAction: true,
	allowRbacTooltipOverride: true,
});

@Injectable()
export class ActionHistoryEntityTypeService implements EntityTypeService<ActionHistory> {
	constructor(
		private confirmationService: ConfirmationService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private actionFinderService: ActionFinderService,
		private paris: Paris,
		private featuresService: FeaturesService,
		private machineRequestsPermissionsService: MachineRequestsPermissionsService,
		private machinesActionsService: MachinesActionsService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private flavorService: FlavorService,
		private authService: AuthService,
		private appContextService: AppContextService
	) {}

	readonly entityType: EntityType<ActionHistory> = {
		id: 'ActionHistory',
		entity: ActionHistory,
		icon: FabricIconNames.History,
		getIcon: (actions: Array<ActionHistory>) => {
			let actionType: RemediationActionType;
			if (actions && actions.length) {
				actionType = actions[0].actionType;
				if (actions.some(a => a.actionType !== actionType)) {
					actionType = null;
				}
			}
			return actionType ? actionType.icon : FabricIconNames.History;
		},
		singleEntityPanelComponentType: ActionHistoryEntityPanelComponent,
		getEntityName: (actionHistory: ActionHistory) =>
			actionHistory.actionType && actionHistory.actionType.name,
		loadFullEntityInPanel: false,
		getEntitiesLink: (actions: Array<ActionHistory>) => {
			const investigationId = GlobalEntityTypesService.getCommonValue(actions, a => a.investigationId);
			const machineId = GlobalEntityTypesService.getCommonValue(
				actions,
				a => a.machine && a.machine.id
			);
			if (!investigationId && !machineId) {
				return;
			}

			if (investigationId) {
				if (!this.flavorService.isEnabled(AppFlavorConfig.routes.investigation)) {
					return;
				}
				return (this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence)
					? MtpInvestigationsService
					: InvestigationsService
				).getInvestigationLink({
					isOfficeInvestigation: actions[0].isOfficeInvestigation,
					externalInvestigationPageUrl: actions[0].investigationDeeplink,
					isLiveResponse: actions[0].isLiveResponse,
					id: investigationId,
				});
			} else if (machineId) {
				return this.globalEntityTypesService.getEntityLink(Machine, actions[0].machine);
			}
		},
		getUseExternalRouting: (actions: Array<ActionHistory>) => {
			const investigationId = GlobalEntityTypesService.getCommonValue(actions, a => a.investigationId);
			return (
				investigationId &&
				!this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
				!!actions[0].investigationDeeplink
			);
		},
		getEntitiesLinkText: actions => {
			const investigationId = GlobalEntityTypesService.getCommonValue(actions, a => a.investigationId);
			return investigationId
				? this.i18nService.strings.actionHistory_panel_button_openInvestigationPage
				: this.i18nService.strings.actionHistory_panel_button_openDevicePage;
		},
		getEntitiesLinkTooltip: (actions: Array<ActionHistory>) => {
			const [action] = actions;
			// office investigation data retention is 30 days, hence, we need to add tooltip that explain it in this case
			return action.doesInvestigationExist
				? this.i18nService.strings.actionHistory_panel_button_openInvestigationPage
				: this.i18nService.strings
						.actionHistory_panel_button_openInvestigationPage_data_retention_tooltip;
		},
		getEntitiesLinkDisabled: (actions: Array<ActionHistory>) => {
			// office investigation data retention is 30 days, hence, we need to disable the button if investigation not exists
			const [action] = actions;
			return !action.doesInvestigationExist;
		},
		// @ts-ignore the type here is incorrect, TODO: fix me
		getActions: (actions: Array<ActionHistory>) => {
			// get the action with mail message or mail cluster entity (assuming there's only one that can be either cluster or message)
			const mailRelatedAction =
				actions &&
				actions.find(
					action =>
						action.entityType &&
						(action.entityType.id === AirsEntityType.MailMessage ||
							action.entityType.id === AirsEntityType.MailCluster)
				);

			if (
				actions &&
				actions.length === 1 &&
				this.appContextService.isSCC &&
				this.featuresService.isEnabled(Feature.MdoManualActions) &&
				mailRelatedAction
			) {
				const manualActionsRes: Array<Observable<ItemActionModelConfig>> = [];

				// Add MDO manual actions
				const moveToInbox$: Observable<ItemActionModelConfig> = this.getManualActionModelConfig(
					mailRelatedAction,
					MdoManualAction.MoveToInbox,
					SubActionTypeValue.MoveToInbox,
					FabricIconNames.Inbox
				);

				const softDelete$: Observable<ItemActionModelConfig> = this.getManualActionModelConfig(
					mailRelatedAction,
					MdoManualAction.SoftDelete,
					SubActionTypeValue.SoftDelete,
					FabricIconNames.Delete
				);

				const hardDelete$: Observable<ItemActionModelConfig> = this.getManualActionModelConfig(
					mailRelatedAction,
					MdoManualAction.Delete,
					SubActionTypeValue.Delete,
					FabricIconNames.Delete
				);

				const moveToDelete$: Observable<ItemActionModelConfig> = this.getManualActionModelConfig(
					mailRelatedAction,
					MdoManualAction.MoveToDeletedItems,
					SubActionTypeValue.MoveToDeletedItems,
					FabricIconNames.FabricMovetoFolder
				);

				const moveToJunk$: Observable<ItemActionModelConfig> = this.getManualActionModelConfig(
					mailRelatedAction,
					MdoManualAction.MoveToJunk,
					SubActionTypeValue.MoveToJunk,
					FabricIconNames.FabricMovetoFolder
				);

				manualActionsRes.push(moveToInbox$);
				manualActionsRes.push(softDelete$);
				manualActionsRes.push(hardDelete$);
				manualActionsRes.push(moveToDelete$);
				manualActionsRes.push(moveToJunk$);

				return combineLatest(manualActionsRes).pipe(
					map(_res => compact(_res).map(itemActionConfig => new ItemActionModel(itemActionConfig)))
				) as Observable<Array<ItemActionModel<ActionHistory>>>;
			}

			if (actions.every(action => action.isUndoable || action.isUndone)) {
				const undoConfig: ItemActionModelConfig<ActionHistory> = {
					...BASE_UNDO_CONFIG,
					method: async (_actions: Array<ActionHistory>) => {
						const allActions: Array<Array<ActionHistory>> = await Promise.all(
							_actions.map(
								async action =>
									await this.pendingActionToExtraActions([action])
										.pipe(
											map(allActions => uniqBy(allActions, 'id')),
											take(1)
										)
										.toPromise()
							)
						);

						const done$ = new EventEmitter<boolean>();

						const e = await this.confirmationService.confirm({
							title: this.i18nService.strings.actionsHistory_undo_confirm_title,
							text:
								this.i18nService.strings.actionsHistory_undo_confirm_text +
								(allActions.length === 1 && allActions[0].length > 1
									? '\n' +
									  this.i18nService.get('actionsHistory.undo.confirm.extraFiles', {
											fileCount: allActions[0].length - 1,
									  })
									: ''),
							showLoaderAndCloseOnEvent: done$,
						});
						const flatActions = Array.from(new Set(compact(flatMap(allActions))));
						if (e.confirmed) {
							try {
								await this.paris
									.apiCall(
										UndoRemediationApiCall,
										Array.from(new Set(flatActions.map(a => a.id)))
									)
									.toPromise();
								done$.next(true);
								done$.complete();
								if (
									flatActions.every(
										a => a.actionType.type === RemediationActionTypeTypes.file_quarantine
									)
								) {
									return new Promise(async (resolve, reject) => {
										const modal = await this.dialogsService
											.showModal(
												AddFilesToAllowlistModal,
												{
													id: 'add-file-to-allowlist-modal',
													title: this.i18nService.strings
														.actionsHistory_undo_addToWhitelist_title,
													titleIcon: FabricIconNames.Accept,
												},
												{
													message: this.i18nService.get(
														'actionsHistory_undo_addToWhitelist_message',
														{ fileCount: _actions.length }
													),
													hashes: _actions.map(a => {
														const action = ActionHistoryConverterService.getRemediationActionFromActionHistory(
															a
														);
														return action && action.file && action.file.sha1;
													}),
													onDone: () => {
														resolve({ confirmed: true, data: null });
														modal.destroy();
													},
												}
											)
											.pipe(take(1))
											.toPromise();
										modal.instance.cancel.subscribe(() => {
											resolve({ confirmed: true, data: null });
											modal.destroy();
										});
									});
								}
							} catch (error) {
								done$.next(true);
								done$.complete();
								await this.confirmationService.confirm({
									title: this.i18nService.strings.actionsHistory_undo_generalError_title,
									icon: FabricIconNames.Error,
									text: this.i18nService.strings.actionsHistory_undo_generalError,
									showConfirm: false,
									cancelText: this.i18nService.strings.buttons_done,
								});
							}
							return Promise.resolve({ confirmed: true, data: null });
						}

						return Promise.reject();
					},
				};
				const undoneActionsCount: number = actions.filter(action => action.isUndone).length;
				if (undoneActionsCount) {
					let tooltip: keyof Strings;
					if (undoneActionsCount === actions.length) {
						if (actions.length === 1) {
							tooltip = 'actionsHistory_undo_undone_single';
						} else {
							tooltip = 'actionsHistory_undo_undone_all';
						}
					} else {
						tooltip = 'actionsHistory_undo_undone_multiple';
					}
					undoConfig.tooltip = this.i18nService.strings[tooltip];
					undoConfig.disabled = true;
				}
				return [new ItemActionModel(undoConfig)];
			}

			if (actions && actions.length === 1 && actions[0].machine) {
				const [action] = actions;
				const cloudResponseType = ACTION_TYPE_TO_MACHINE_RESPONSE[action.actionType.type];
				if (cloudResponseType) {
					return defer(async () => {
						const machine = await this.paris.getItemById(Machine, action.machine.id).toPromise();

						const featuresStatus = await this.machineRequestsPermissionsService
							.getFeaturesStatus(machine)
							.toPromise();

						if (!(featuresStatus && featuresStatus[cloudResponseType])) {
							return;
						}

						const requestsState = await this.paris
							.getRelatedItem<Machine, MachineRequestsState>(
								MachineMachineRequestsStateRelationship,
								machine
							)
							.toPromise();

						let undoAction: () => Promise<ConfirmEvent>;
						if (
							cloudResponseType === 'IsolationResponse' &&
							requestsState.isolation === 'RequestCompleted'
						) {
							undoAction = () => this.machinesActionsService.releaseFromIsolation(machine);
						} else if (
							cloudResponseType === 'RestrictExecutionResponse' &&
							requestsState.restrictExecution === 'RequestCompleted'
						) {
							undoAction = () => this.machinesActionsService.removeAppRestrictions(machine);
						}

						if (!undoAction) {
							return;
						}
						return [
							new ItemActionModel({
								...BASE_UNDO_CONFIG,
								method: async () => {
									const { confirmed } = await undoAction();
									if (!confirmed) {
										return Promise.reject();
									}
								},
							}),
						];
					}).pipe(filter(Boolean));
				}
			}

			const actionType = actions && actions.length === 1 && actions[0].actionType;
			if (
				actionType &&
				(actionType.type === RemediationActionTypeTypes.enable_user ||
					actionType.type === RemediationActionTypeTypes.disable_user)
			) {
				const manualUserActionsRes: Array<Observable<ItemActionModelConfig>> = [];
				const enableUser$ = this.getManualUserActionModelConfig(
					actions[0],
					ManualUserActionType.EnableUser
				);
				const disableUser$ = this.getManualUserActionModelConfig(
					actions[0],
					ManualUserActionType.DisableUser
				);
				manualUserActionsRes.push(enableUser$);
				manualUserActionsRes.push(disableUser$);
				return combineLatest(manualUserActionsRes).pipe(
					map(_res => compact(_res).map(itemActionConfig => new ItemActionModel(itemActionConfig)))
				) as Observable<Array<ItemActionModel<ActionHistory>>>;
			}
		},
	};

	private getManualActionModelConfig(
		action: ActionHistory,
		manualAction: MdoManualAction,
		subActionType: SubActionTypeValue,
		icon: ItemActionIconName
	): Observable<ItemActionModelConfig> {
		const manualActionI18nKey = `actionsHistory_panel_manualAction_${manualAction}`;

		const isSecAdmin = this.authService.currentUser.isSecAdmin;
		const isAllowed = isSecAdmin || !action.isOfficeInvestigation;

		const ignoreRbac = this.appContextService.isSCC && action.isOfficeInvestigation;
		let isAllowed$: Observable<boolean>;

		if (!isAllowed && this.appContextService.isSCC) {
			isAllowed$ = from(<Promise<boolean>>sccHostService.auth.isInRole(SccRoles.searchAndPurge));
		} else {
			isAllowed$ = of(isAllowed);
		}

		return isAllowed$.pipe(
			map(hasPermission =>
				Object.assign(
					{
						id: `manualAction_${manualAction}`,
						nameKey: manualActionI18nKey,
						tooltip: hasPermission
							? this.i18nService.get(manualActionI18nKey)
							: this.i18nService.strings.common_permissions_noPermissionTooltip,
						method: async (_actions: Array<ActionHistory>) => {
							const done$ = new EventEmitter<boolean>();

							const isMailMessage = action.entityType.id === AirsEntityType.MailMessage;
							const entityI18nKey = isMailMessage ? 'mailMessage' : 'mailCluster';

							const confirmTextI18nKey = `actionsHistory_panel_${entityI18nKey}_manualAction_${manualAction}_confirmText`;
							const confirmEvent = await this.confirmationService.confirm({
								title: this.i18nService.get(manualActionI18nKey),
								text: this.i18nService.get(confirmTextI18nKey),
								showLoaderAndCloseOnEvent: done$,
							});

							if (confirmEvent.confirmed) {
								const requestBody = this.buildManualAdminActionRequest(action, subActionType);

								let title = '';
								let titleIcon: FabricIconNames = null;
								let message = '';

								try {
									if (!requestBody) {
										throw new Error('RequestBody is empty');
									}
									await this.paris
										.apiCall(ManualActionOnEmailApiCall, requestBody)
										.toPromise();
									title = this.i18nService.strings
										.actionsHistory_panel_manualAction_resultModal_success_title;
									titleIcon = FabricIconNames.Accept;
									message = this.i18nService.get(
										`actionsHistory_panel_${entityI18nKey}_manualAction_${manualAction}_resultModal_success_body`
									);
								} catch (error) {
									titleIcon = FabricIconNames.Error;
									if (error.response && error.response.status === 409) {
										title = this.i18nService.strings
											.actionsHistory_panel_manualAction_requestAlreadySubmitted_title;
										message = this.i18nService.get(
											`actionsHistory_panel_${entityI18nKey}_manualAction_${manualAction}_resultModal_requestAlreadySubmitted`
										);
									} else {
										title = this.i18nService.strings
											.actionsHistory_panel_manualAction_request_generalError_title;
										message = this.i18nService.strings
											.actionsHistory_panel_manualAction_request_generalError;
									}
								} finally {
									done$.next(true);
									done$.complete();
								}

								return new Promise(async (resolve, reject) => {
									await this.confirmationService.confirm({
										title: title,
										icon: titleIcon,
										text: message,
										showConfirm: false,
										cancelText: this.i18nService.strings.buttons_done,
									});
									resolve({ confirmed: true, data: null });
								});
							}

							return Promise.reject();
						},
						icon: icon,
						closeOnAction: false,
						allowRbacTooltipOverride: true,
						refreshOnResolve: false,
						refreshEntityPanelOnResolve: false,
						disabled: !hasPermission,
					},
					ignoreRbac
						? null
						: {
								rbac: [RbacMdeAllowedActions.remediationActions],
								rbacState: RbacControlState.disabled,
						  }
				)
			)
		);
	}

	private buildManualAdminActionRequest(
		actionHistory: ActionHistory,
		subActionType: SubActionTypeValue
	): ManualAdminActionRequest {
		const relatedEntities = actionHistory.relatedEntities;
		const entity: ActionHistoryRelatedEntity =
			relatedEntities && relatedEntities.length
				? relatedEntities[0]
				: ({} as ActionHistoryRelatedEntity);

		// the range for the request should be 30 days
		const today = new Date();
		const monthAgo = new Date();
		monthAgo.setDate(monthAgo.getDate() - 30);

		/*
			In admin playbook we have 'approvalId' for each action and for other MDO actions we might not have
			this value.
			If 'approvalId' value exists on the original action, this value should be used as 'bulkId' in the new action
			so we will be able to connect between those actions.
			In case of no 'approvalId', the fallback is to generate 'bulkId' from the entityId or just random guid
			if there's no entityId.
		*/
		const entityId = entity.entityId;
		const bulkId = actionHistory.approvalId
			? actionHistory.approvalId
			: typeof entityId === 'string'
			? this.createGuidFromEntityId(<string>entityId)
			: uuid4();

		const tenantId = sccHostService.isSCC ? sccHostService.loginUser.tenantId : null;

		const adminActionRequest = {
			AdminActionType: AdminActionType.MailAction,
			SubActionType: subActionType,
			StartTime:
				actionHistory.entityType.id === AirsEntityType.MailCluster
					? new Date(entity.clusterQueryStartTime)
					: monthAgo,
			EndTime:
				actionHistory.entityType.id === AirsEntityType.MailCluster
					? new Date(entity.clusterQueryTime)
					: today,
			TenantId: tenantId,
			Name: this.i18nService.strings.actionHistory_panel_manualAction_requestName_field_value,
			ApproverUpn: sccHostService.isSCC ? sccHostService.loginUser.upn : null,
			IsImmediateAction: true,
			BulkId: bulkId,
			AdminActionSource: AdminActionSource.MTP_BulkAction, //TODO: LIRAN - we need to change this value to MTP_ActionCenter when we have it in Substrate repo (client task: https://microsoft.visualstudio.com/OS/_workitems/edit/32054585)
		};

		if (actionHistory.entityType.id === AirsEntityType.MailMessage) {
			const emailList: Array<ManualActionEmailData> = [
				{ NetworkMessageId: entity.networkMessageId, Recipient: entity.recipientEmailAddress },
			];

			return Object.assign(adminActionRequest, { EmailList: emailList });
		} else if (actionHistory.entityType.id === AirsEntityType.MailCluster) {
			return Object.assign(adminActionRequest, {
				QueryString: entity.clusterQueryString,
			});
		}
	}

	private getManualUserActionModelConfig(
		action: ActionHistory,
		userActionType: ManualUserActionType
	): Observable<ItemActionModelConfig> {
		const userActionI18nKey = `actionsHistory_panel_Account_manualUserAction_${userActionType}`;
		const isAllowed$ = of(this.authService.currentUser.isMdeAdmin);
		return isAllowed$.pipe(
			map(hasPermission =>
				Object.assign(
					{
						id: `manualUserAction_${userActionType}`,
						nameKey: userActionI18nKey,
						tooltip: hasPermission
							? this.i18nService.get(userActionI18nKey)
							: this.i18nService.strings.common_permissions_noPermissionTooltip,
						method: async (_actions: Array<ActionHistory>) => {
							const done$ = new EventEmitter<boolean>();

							const confirmTextI18nKey = `actionsHistory_panel_Account_manualUserAction_${userActionType}_confirmText`;
							const confirmEvent = await this.confirmationService.confirm({
								title: this.i18nService.get(userActionI18nKey),
								text: this.i18nService.get(confirmTextI18nKey),
								showLoaderAndCloseOnEvent: done$,
							});

							if (confirmEvent.confirmed) {
								const requestBody = this.buildManualUserActionRequest(action, userActionType);

								let title = '';
								let titleIcon: FabricIconNames = null;
								let message = '';

								try {
									if (!requestBody) {
										throw new Error('RequestBody is empty');
									}
									await this.paris
										.apiCall(ManualActionOnUserApiCall, requestBody)
										.toPromise();
									title = this.i18nService.strings
										.actionsHistory_panel_Account_manualUserAction_resultModal_success_title;
									titleIcon = FabricIconNames.Accept;
									message = this.i18nService.get(
										`actionsHistory_panel_Account_manualUserAction_${userActionType}_resultModal_success_body`
									);
								} catch (error) {
									titleIcon = FabricIconNames.Error;
									title = this.i18nService.strings
										.actionsHistory_panel_manualAction_request_generalError_title;
									message = this.i18nService.strings
										.actionsHistory_panel_manualAction_request_generalError;
								} finally {
									done$.next(true);
									done$.complete();
								}

								return new Promise(async (resolve, reject) => {
									await this.confirmationService.confirm({
										title: title,
										icon: titleIcon,
										text: message,
										showConfirm: false,
										cancelText: this.i18nService.strings.buttons_done,
									});
									resolve({ confirmed: true, data: null });
								});
							}

							return Promise.reject();
						},
						icon: FabricIconNames.People,
						closeOnAction: false,
						allowRbacTooltipOverride: true,
						refreshOnResolve: false,
						refreshEntityPanelOnResolve: false,
						disabled: !hasPermission,
					},
					{
						rbac: [RbacMdeAllowedActions.admin],
						rbacState: RbacControlState.disabled,
					}
				)
			)
		);
	}

	private buildManualUserActionRequest(
		actionHistory: ActionHistory,
		userActionType: ManualUserActionType
	): ManualAdminUserActionRequest {
		const relatedEntities = actionHistory.relatedEntities;
		const entity: ActionHistoryRelatedEntity =
			relatedEntities && relatedEntities.length
				? relatedEntities[0]
				: ({} as ActionHistoryRelatedEntity);

		const currentUser = sccHostService.loginUser;
		const accountAlertV3Type = 'Account';
		const aadTenantId = currentUser.tenantId;

		return {
			InitiatedByAccount: {
				$type: accountAlertV3Type,
				AadUserId: currentUser.userId,
				DisplayName: currentUser.displayName && currentUser.displayName.split('@')[0],
				UPNSuffix: currentUser.upn && currentUser.upn.split('@')[1],
				AadTenantId: aadTenantId,
				Type: AlertV3EntityTypes.Account,
			},
			RemediationActions: [
				{
					ActionOrigin: 'Manual',
					ActionSource: ActionSourceType.MDIManualAction,
					ActionType: userActionType,
					Entities: [
						{
							$type: accountAlertV3Type,
							Name: entity.name,
							AadTenantId: entity.aadTenantId,
							AadUserId: entity.aadUserId,
							NTDomain: entity.ntDomain,
							DnsDomain: entity.dnsDomain,
							UPNSuffix: entity.upnSuffix,
							Sid: entity.sid,
							DisplayName: entity.userDisplayName,
							FriendlyName: entity.friendlyName,
							UniqueName: entity.uniqueName,
							Type: AlertV3EntityTypes.Account,
						},
					],
					ResourceIdentifiers: [
						{
							AadTenantId: aadTenantId,
							Type: 'AAD',
							$type: 'AADIdentifier',
						},
					],
					ApprovedBy: currentUser.displayName,
					$type: 'InvestigationAction',
				},
			],
		};
	}

	/**
	 * This function generates a guid from an entityId.
	 * MDO entityId is in the form of: 'urn:<entity type>:<32 chars>'.
	 * The output is a guid from the 32 chars of the entityId.
	 * For example:
	 * for entityId: 'urn:MailEntity:558cb4d9989d7fcb23a81762f0458b53'
	 * The output will be: 558cb4d9-989d-7fcb-23a8-1762f0458b53
	 *
	 * @param entityId
	 * @private
	 */
	private createGuidFromEntityId(entityId: string): string {
		if (!entityId) {
			return '';
		}

		// get the last part of the entityId.
		// for example: '558cb4d9989d7fcb23a81762f0458b53' from 'urn:MailEntity:558cb4d9989d7fcb23a81762f0458b53'
		const strippedId = entityId.split(':').pop();

		const allowedChars = '[a-f0-9]';

		const guid = strippedId.replace(
			new RegExp(
				`^(${allowedChars}{8})(${allowedChars}{4})(${allowedChars}{4})(${allowedChars}{4})(${allowedChars}{12})`
			),
			'$1-$2-$3-$4-$5'
		);
		return guid;
	}

	private pendingActionToExtraActions(
		pendingActions: Array<ActionHistory>
	): Observable<Array<ActionHistory>> {
		if (pendingActions.some(a => a.actionType.type !== RemediationActionTypeTypes.file_quarantine)) {
			return of(pendingActions);
		}
		return combineLatest(
			pendingActions.map(a => this.actionFinderService.getMoreActionsForFile(a).pipe(take(1)))
		).pipe(
			map(d => {
				const actions: Array<ActionHistory> = compact(flatMap(d));
				return actions.concat(pendingActions);
			})
		);
	}
}
