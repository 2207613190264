<!-- tslint:disable:template-click-events-have-key-events -->
<div class="element--border element clickable selectable--outer non-tree-element"
	 role="presentation"
	 [class.clickable]="machine.id"
	 [class.clickable--with-constant-shadow]="machine.id"
	 [class.clickable--with-radius]="machine.id"
	 [class.selected]="alertPageService.selectedTreeElements[machine.id]">
	<div class="tree-element--title--box selectable--inner element--centered"
		 [class.selected]="alertPageService.selectedTreeElements[machine.id]"
		 (click)="alertPageService.updateDetailsTab(machineDetailsTabType, machine, machine.id)"
		 (keydown.enter)="alertPageService.updateDetailsTab(machineDetailsTabType, machine, machine.id)"
		 tabindex="0"
		 [attr.aria-label]="machine.name + ('alert.accessibility.machine.details.label' | i18n)"
		 role="listitem"
		 [attr.aria-current]="alertPageService.selectedTreeElements[machine.id]"
		 data-track-type="Button"
		 data-track-id="machine-tile"
		 [attr.data-track-value]="machine.id">
		<div class="wcd-flex-vertical wcd-flex-1">
			<div class="wcd-flex-horizontal	element element--centered">
				<span class="wcd-flex-horizontal wcd-flex-1 wcd-padding-small-top">
					<!-- Machine title -->
					<fab-icon [iconName]="machineIcon"></fab-icon>

					<span class="wcd-font-size-m
								wcd-text-overflow-ellipsis-nowrap
								wcd-text-overflow-ellipsis
								wcd-font-weight-bold
								wcd-flex-1
								wcd-padding-small-left"
						[wcdTooltip]="machine.name">
						{{machine.name}}
					</span>

					<span *ngIf="machine.riskScore && machine.riskScore.type"
							class="wcd-flex-horizontal">
						<span class="wcd-padding-small-left
									wcd-padding-small-right
									wcd-font-size-s
									wcd-text-overflow-ellipsis-nowrap
									wcd-text-overflow-ellipsis">
							{{ 'machineRiskScore.title' | i18n }}
						</span>
						<span class="wcd-severity
									wcd-font-size-s
									wcd-text-overflow-ellipsis-nowrap"
							[ngClass]="'wcd-severity-' + machine.riskScore.id.toLocaleLowerCase()">
							{{ machine.riskScore.nameI18nKey | i18n }}
						</span>
					</span>
				</span>

				<!-- Machine actions -->
				<entity-command-bar [entityType]="machineType"
									[entity]="machine"
									[entities]="[machine]"
									[overFlowActionsView]="true"
									[entityCommandBarDisplayMode]="entityCommandBarDisplayMode.Overflow">
				</entity-command-bar>
			</div>

			<ng-container *ngLet="(tags$ | async) as tags">
				<div class="wcd-padding-right alert-page-tags"
					 (click)="alertPageService.updateDetailsTab(machineDetailsTabType, machine, machine.id)">
					<tags-list *ngIf="tags && tags.length" [tags]="tags"></tags-list>
				</div>
			</ng-container>
		</div>
	</div>
</div>
