
		<div class="wcd-flex-center-vertical">
			<div class="wcd-flex-1 wcd-text-overflow-ellipsis">
				{{ title }}
			</div>
			<tvm-report-inaccuracy-dropdown
				[inaccuracyContext]="inaccuracyContext"
				[contextObject]="contextObject"
			></tvm-report-inaccuracy-dropdown>
		</div>
	