import { Type } from '@angular/core';
import { HuntingCommunityQuery, HuntingSavedQuery, HuntingScheduledQuery, HuntingSharedQuery, HuntingUserQuery, } from '@wcd/domain';
import { Subject } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../i18n/src/lib/services/i18n.service";
import * as i2 from "../../../../../config/src/lib/services/features.service";
var HuntingQueryListService = /** @class */ (function () {
    function HuntingQueryListService(i18nService, featuresService) {
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.update$ = new Subject();
    }
    HuntingQueryListService.prototype.refreshQueriesLists = function () {
        this.update$.next(new Date().valueOf());
    };
    Object.defineProperty(HuntingQueryListService.prototype, "displayNames", {
        get: function () {
            return {
                user: this.i18nService.strings.hunting_savedQueries_types_user,
                shared: this.i18nService.strings.hunting_savedQueries_types_shared,
                scheduled: this.i18nService.strings.hunting_savedQueries_types_scheduled,
                community: this.i18nService.strings.hunting_savedQueries_types_community,
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HuntingQueryListService.prototype, "config", {
        get: function () {
            return {
                user: {
                    title: this.displayNames.user,
                    entityType: this.shouldMigrateToVNext ? HuntingSavedQuery : HuntingUserQuery,
                    queryKind: 'user',
                },
                shared: {
                    title: this.displayNames.shared,
                    entityType: this.shouldMigrateToVNext ? HuntingSavedQuery : HuntingSharedQuery,
                    queryKind: 'shared',
                },
                scheduled: {
                    title: this.displayNames.scheduled,
                    entityType: this.shouldMigrateToVNext ? HuntingSavedQuery : HuntingScheduledQuery,
                    queryKind: 'scheduled',
                    treeOptions: {
                        allowContextMenu: false,
                    },
                },
                community: {
                    title: this.displayNames.community,
                    entityType: HuntingCommunityQuery,
                    queryKind: 'community',
                    treeOptions: {
                        allowContextMenu: false,
                    },
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HuntingQueryListService.prototype, "shouldMigrateToVNext", {
        get: function () {
            return this.featuresService.isAnyEnabled([
                Feature.HuntingEndpointMigrationQueriesController,
                Feature.HuntingEndpointMigrationQueriesControllerMtp
            ]);
        },
        enumerable: true,
        configurable: true
    });
    HuntingQueryListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HuntingQueryListService_Factory() { return new HuntingQueryListService(i0.ɵɵinject(i1.I18nService), i0.ɵɵinject(i2.FeaturesService)); }, token: HuntingQueryListService, providedIn: "root" });
    return HuntingQueryListService;
}());
export { HuntingQueryListService };
