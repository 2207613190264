<div *ngIf="!singleAssetPanel; else singleAsset" class="wcd-full-height wcd-flex-vertical">
	<tabs
		class="wcd-padding-vertical wcd-padding-large-horizontal"
		[tabsData]="tabs"
		(select)="setTab($event.tab)">
	</tabs>
	<div [ngSwitch]="currentTab.id" class="wcd-flex-vertical">
		<div
			*ngSwitchCase="tabIds.configurationDetails"
			class="wcd-padding-vertical wcd-padding-large-horizontal">
			<baseline-configuration-entity-details [configuration]="configuration">
			</baseline-configuration-entity-details>
		</div>
		<wcd-datatable
			*ngSwitchCase="tabIds.applicableProfiles"
			class="wcd-full-height wcd-padding-large-horizontal wcd-margin-left"
			class=""
			[items]="applicableProfiles"
			[columns]="applicableProfilesFields"
			[selectEnabled]="false"
			[fluidHeaderWidth]="true">
		</wcd-datatable>
		<div *ngSwitchCase="tabIds.devices" class="wcd-full-height">
			<profile-configuration-devices
				*ngIf="isProfileConfiguration; else generalConfiguration"
				[profileName]="profileName"
				[profileId]="profileId"
				[configuration]="configuration">
			</profile-configuration-devices>
			<ng-template #generalConfiguration>
				<general-configuration-devices
					[configuration]="configuration">
				</general-configuration-devices>
			</ng-template>
		</div>
	</div>
</div>
<ng-template #singleAsset>
	<div class="wcd-padding-vertical wcd-padding-large-horizontal">
		<baseline-configuration-entity-details [configuration]="configuration">
		</baseline-configuration-entity-details>
	</div>
</ng-template>
