import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';


@ApiCall({
	name: 'Assigned users api call',
	method: 'GET',
	endpoint: () => 'incidents/GetLatestAssignedUsers',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.incidentQueue,
	parseData: data =>  data.map( user => ({text: user.DisplayName, secondaryText: user.Mail || user.UserPrincipalName})),
	parseQuery: (searchTerm: string) => searchTerm ? ({ params: {searchTerm} }) : null,
})
export class AssignedUsersApiCall extends ApiCallModel {}

