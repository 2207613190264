import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { RegistryEntityTypeService } from './services/registry.entity-type.service';
import { RegistryEntityPanelComponent } from './components/registry.entity-panel.component';
import { RegistryKeyEntityTypeService } from './services/registry-key.entity-type.service';
import { RegistryValueEntityTypeService } from './services/registry-value.entity-type.service';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule],
	providers: [RegistryEntityTypeService, RegistryKeyEntityTypeService, RegistryValueEntityTypeService],
	declarations: [RegistryEntityPanelComponent],
	entryComponents: [RegistryEntityPanelComponent],
})
export class RegistryEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(RegistryEntityTypeService);
		globalEntityTypesService.registerEntityType(RegistryKeyEntityTypeService);
		globalEntityTypesService.registerEntityType(RegistryValueEntityTypeService);
	}
}
