import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { AssetHardwareAndFirmwareAdditionalFields } from './asset-hardware-and-firmware-additional-fields.value-object';

export enum HardwareAndFirmwareTypes {
	Bios = 'Bios',
	Processor = 'Processor',
	Hardware = 'Hardware',
}

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'AssetHardwareAndFirmware',
	pluralName: 'AssetHardwareAndFirmware',
	endpoint: 'AssetHardwareAndFirmwareInfo',
	readonly: true,
})
export class AssetHardwareAndFirmware extends EntityModelBase<string> {
	@EntityField() readonly type: string;

	@EntityField() readonly vendor: string;

	@EntityField() readonly name: string;

	@EntityField() readonly family?: string;

	@EntityField() readonly version?: string;

	@EntityField() readonly additionalFields?: AssetHardwareAndFirmwareAdditionalFields;
}
