import { Injectable, OnDestroy } from '@angular/core';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { BehaviorSubject } from 'rxjs';
import {
	AirsAppendedFile,
	AirsDriver,
	AirsEntity,
	AirsEntityTypeResults,
	AirsFile,
	MemoryContents,
	RemediationStatusType,
	SystemExclusionListType,
} from '@wcd/domain';
import { SystemExclusionsService } from '../../system_exclusions/services/system-exclusions.service';
import { I18nService } from '@wcd/i18n';

export type EvidenceCountSummary = { totalRemediationStatusCount: number; totalEvidenceCount: number };

@Injectable()
export class AirsEntitiesService implements OnDestroy {
	currentlyDisplayedEntities$: BehaviorSubject<Array<AirsEntity>> = new BehaviorSubject<Array<AirsEntity>>(
		[]
	);

	constructor(
		private dialogsService: DialogsService,
		private systemExclusionsStore: SystemExclusionsService,
		private i18nService: I18nService
	) {}

	setMemoryContentsExclusionRule(
		memoryContents: MemoryContents,
		isRemove: boolean = false,
		aclType?: SystemExclusionListType
	): Promise<any> {
		if (isRemove) return this.systemExclusionsStore.removeMemoryContentsAcl(memoryContents).toPromise();
		else return this.systemExclusionsStore.createMemoryContentsAcl(memoryContents, aclType).toPromise();
	}

	public static countEvidenceAndRemediationStatus(
		airsEntityTypeResults: Array<AirsEntityTypeResults>
	): EvidenceCountSummary {
		return airsEntityTypeResults.reduce(
			(total: EvidenceCountSummary, result: AirsEntityTypeResults) => {
				total.totalEvidenceCount = total.totalEvidenceCount + result.totalEntityCount;
				total.totalRemediationStatusCount =
					total.totalRemediationStatusCount +
					Object.values(RemediationStatusType).reduce(
						(acc, status) => acc + result.getEntityCountByStatus(status),
						0
					);
				return total;
			},
			{ totalRemediationStatusCount: 0, totalEvidenceCount: 0 }
		);
	}

	public getEntityBooleanFields(entity: AirsEntity): Array<{ name: string; value: boolean }> {
		if (entity instanceof AirsFile) {
			return [
				{
					name: this.i18nService.strings.airsEntities_file_fields_compressed,
					value: entity.isCompressed,
				},
				{
					name: this.i18nService.strings.airsEntities_file_fields_readOnly,
					value: entity.isReadOnly,
				},
				{
					name: this.i18nService.strings.airsEntities_file_fields_downloaded,
					value: entity.isDownloaded,
				},
				{ name: this.i18nService.strings.airsEntities_file_fields_hidden, value: entity.isHidden },
			];
		}
		if (entity instanceof AirsAppendedFile) {
			return [
				{
					name: this.i18nService.strings.airsEntities_file_fields_compressed,
					value: entity.isCompressed,
				},
			];
		}
		if (entity instanceof AirsDriver) {
			return [
				{
					name: this.i18nService.strings.airsEntities_driver_fields_driverLoaded,
					value: entity.isLoaded,
				},
			];
		}
	}

	ngOnDestroy(): void {
		this.currentlyDisplayedEntities$.complete();
	}
}
