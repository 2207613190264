/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../../../../../../../projects/shared/src/lib/components/route-link.component.ngfactory";
import * as i4 from "../../../../../../../projects/shared/src/lib/components/route-link.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../../../projects/shared/src/lib/services/routes.service";
import * as i7 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i8 from "@angular-react/fabric";
import * as i9 from "./machine.timeline-link.component";
import * as i10 from "../../../@entities/machines/services/machines.service";
var styles_MachineTimelineLinkComponent = [];
var RenderType_MachineTimelineLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MachineTimelineLinkComponent, data: {} });
export { RenderType_MachineTimelineLinkComponent as RenderType_MachineTimelineLinkComponent };
export function View_MachineTimelineLinkComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 9, "route-link", [], null, null, null, i3.View_RouteLinkComponent_0, i3.RenderType_RouteLinkComponent)), i0.ɵdid(5, 573440, null, 0, i4.RouteLinkComponent, [i5.UrlHandlingStrategy, i5.Router, i6.RoutesService], { routeLink: [0, "routeLink"] }, null), i0.ɵpad(6, 1), i0.ɵpod(7, { lastEventTime: 0 }), i0.ɵpod(8, { routerLink: 0, queryParams: 1 }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(10, 0, null, 0, 1, "fab-icon", [["iconName", "TimelineProgress"]], null, null, null, i7.View_FabIconComponent_0, i7.RenderType_FabIconComponent)), i0.ɵdid(11, 5816320, null, 0, i8.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(12, 0, ["\n\t\t\t\t", "\n\t\t\t"])), i0.ɵppd(13, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 8, 0, _ck(_v, 6, 0, _co.machinesService.getMachineLink((_co.machineId || _co.alert.machine.machineId), false, _co.actionTime)), _co.machinesService.getMachineWithAlertParams((_co.alert || _ck(_v, 7, 0, _co.actionTime)))); _ck(_v, 5, 0, currVal_0); var currVal_1 = "TimelineProgress"; _ck(_v, 11, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 0), "machines.entityDetails.fields.name.linkText")); _ck(_v, 12, 0, currVal_2); }); }
export function View_MachineTimelineLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "machine-timeline-link", [], null, null, null, View_MachineTimelineLinkComponent_0, RenderType_MachineTimelineLinkComponent)), i0.ɵdid(1, 49152, null, 0, i9.MachineTimelineLinkComponent, [i10.MachinesService], null, null)], null, null); }
var MachineTimelineLinkComponentNgFactory = i0.ɵccf("machine-timeline-link", i9.MachineTimelineLinkComponent, View_MachineTimelineLinkComponent_Host_0, { machineId: "machineId", actionTime: "actionTime", alert: "alert" }, {}, []);
export { MachineTimelineLinkComponentNgFactory as MachineTimelineLinkComponentNgFactory };
