import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { MachinesService } from '../../../machines/services/machines.service';
import { UsersService } from '../../../users/services/users.service';
import { I18nService } from '@wcd/i18n';
import { Alert, Machine } from '@wcd/domain';
import { EntityType } from '../../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="wcd-flex-vertical wcd-full-height">
			<ng-container *ngIf="(data$ | async) as data">
				<div class="wcd-flex-1 ie11Patch ie11Flex">
					<h4 class="wcd-margin-bottom">{{ 'alert.report.widgets.alertContext.title' | i18n }}</h4>
					<!--[ First machine ]-->
					<div *ngIf="data.machine" class="wcd-flex-horizontal wcd-margin-small-bottom">
						<wcd-shared-icon
							[iconName]="
								(machineEntityTypeService.getIcon &&
									machineEntityTypeService.getIcon([data.machine])) ||
								machineEntityTypeService.icon
							"
						></wcd-shared-icon>
						&nbsp;
						<route-link
							[routeLink]="{
								routerLink: [machineEntityTypeService.getEntitiesLink([data.machine])]
							}"
							class="wcd-flex-1 wcd-text-overflow-ellipsis wcd-text-overflow-ellipsis-nowrap wcd-margin-small-right"
						>
							<span [wcdTooltip]="data.machine.name">{{ data.machine.name }}</span>
						</route-link>
					</div>

					<div
						class="wcd-flex-horizontal wcd-margin-small-bottom"
						*ngIf="getUserLink(data) as userLink"
					>
						<wcd-shared-icon iconName="Contact"></wcd-shared-icon>
						&nbsp;
						<route-link
							[routeLink]="{
								routerLink: userLink.commands,
								queryParams: userLink.queryParams
							}"
							class="wcd-flex-1 wcd-text-overflow-ellipsis wcd-text-overflow-ellipsis-nowrap wcd-margin-small-right"
						>
							<span [wcdTooltip]="data.user.fullName">{{ data.user.fullName }}</span>
						</route-link>
					</div>

					<div class="wcd-flex-horizontal wcd-margin-small-bottom">
						<span class="wcd-font-weight-bold wcd-flex-0 wcd-margin-small-right">
							{{ 'alerts.activity.first' | i18n }}:
						</span>
						{{ data.firstEventTime | date: 'medium' }}
					</div>

					<div class="wcd-flex-horizontal wcd-margin-small-bottom">
						<span class="wcd-font-weight-bold wcd-flex-0 wcd-margin-small-right">
							{{ 'alerts.activity.last' | i18n }}:
						</span>
						{{ data.lastEventTime | date: 'medium' }}
					</div>
				</div>
			</ng-container>
		</div>
	`,
})
export class AlertContextWidgetComponent extends ReportWidgetComponent<any> implements OnInit {
	readonly machineEntityTypeService: EntityType<Machine>;

	constructor(
		private readonly activatedEntity: ActivatedEntity,
		globalEntityTypesService: GlobalEntityTypesService,
		private readonly machinesService: MachinesService,
		private readonly userService: UsersService,
		private readonly i18nService: I18nService,
		reportsService: ReportsService
	) {
		super(reportsService);
		this.machineEntityTypeService = globalEntityTypesService.getEntityType(Machine);
	}

	get widgetConfig(): any {
		return {
			id: 'alert-context-widget',
			name: this.i18nService.get('alert.report.widgets.alertContext.title'),
			loadData: () => {
				return this.activatedEntity.currentEntity$;
			},
		};
	}

	getUserLink({ user, machine }: Alert) {
		if (!user) {
			return null;
		}
		const alertTime = machine && new Date(machine.lastEventTime || machine.lastSeen);
		return this.userService.getUserLink({
			accountName: user.name,
			accountDomainName: user.domain,
			sid: user.id,
			alertTime: alertTime,
		});
	}

	getMachineLink(alert: Alert) {
		const alertTime = new Date(alert.machine.lastEventTime || alert.machine.lastSeen);
		return this.machinesService.getMachineLink(alert.machine.id, false, alertTime, alert.id);
	}
}
