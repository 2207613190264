import { FlavorConfig } from '@wcd/scc-common';

export interface IReportsConfig {
	detectionSource: FlavorConfig;
}

export const ReportsConfig: IReportsConfig = {
	detectionSource: {
		mdeFlavors: ['P2'],
	},
};
