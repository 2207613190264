
		<div *ngIf="aadUser?.investigationPriority" class="wcd-flex wcd-flex-center-vertical">
			<span class="wcd-padding-small-right">{{
				'users.entityDetails.sections.details.investigation.priority.title' | i18n
			}}</span>
			<span>
				<aad-user-investigation-priority
					[percentile]="aadUser?.percentile"
					[priority]="aadUser?.investigationPriority"
				></aad-user-investigation-priority>
			</span>
		</div>
	