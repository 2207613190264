import { Paris } from '@microsoft/paris';
import { MachineHealthStatusApiCall, MachineAntivirusStatusApiCall, MachineOsPlatformApiCall, MachineOsVersionApiCall, AvStatus, } from '@wcd/domain';
import { TzDateService } from '@wcd/localization';
import { ReportsService } from '../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { Router } from '@angular/router';
import { mapValues, mapKeys, maxBy } from 'lodash-es';
import { MachineProtectionStatusMapping } from '../machine-protection-status-mapping';
import { I18nService } from '@wcd/i18n';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { TrackingEventId } from '../../insights/models/tracking-event-Id.enum';
import { ChartType } from '../../reporting/reporting.types';
import * as i0 from "@angular/core";
import * as i1 from "@microsoft/paris/dist/lib/paris";
import * as i2 from "../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i3 from "../../insights/services/app-insights.service";
import * as i4 from "../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i5 from "@angular/router";
import * as i6 from "../machine-protection-status-mapping";
import * as i7 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
var MAX_LABELS = 4;
var MachineProtectionReportService = /** @class */ (function () {
    function MachineProtectionReportService(paris, tzDateService, appInsightsService, prettyNumberService, router, machineProtectionStatusMapping, i18nService) {
        this.paris = paris;
        this.tzDateService = tzDateService;
        this.appInsightsService = appInsightsService;
        this.prettyNumberService = prettyNumberService;
        this.router = router;
        this.machineProtectionStatusMapping = machineProtectionStatusMapping;
        this.i18nService = i18nService;
    }
    MachineProtectionReportService.prototype.getMachineByHealthStatusData = function (params) {
        params.alignToPeriod = 'true';
        return this.paris.apiCall(MachineHealthStatusApiCall, params);
    };
    MachineProtectionReportService.prototype.getMachineByAntivirusStatusData = function (params) {
        params.alignToPeriod = 'true';
        var unknownEnumValue = 'Unknown';
        var unknownNewEnumValue = 'UnknownNew';
        if ('antivirusStatus' in params) {
            var antiVirusValues = params.antivirusStatus;
            if (antiVirusValues.includes(unknownEnumValue) &&
                !antiVirusValues.includes(unknownNewEnumValue)) {
                antiVirusValues.push(unknownNewEnumValue);
            }
            params.antivirusStatus = antiVirusValues;
        }
        return this.paris.apiCall(MachineAntivirusStatusApiCall, params);
    };
    MachineProtectionReportService.prototype.getMachineByOsPlatformData = function (params) {
        params.alignToPeriod = 'true';
        return this.paris.apiCall(MachineOsPlatformApiCall, params);
    };
    MachineProtectionReportService.prototype.getMachineByOsVersionData = function (params) {
        params.alignToPeriod = 'true';
        return this.paris.apiCall(MachineOsVersionApiCall, params);
    };
    MachineProtectionReportService.prototype.getSettingsOverTime = function (dimensionType, dimensionMapping, data, singleDimensionToDisplay) {
        var _this = this;
        var dimensionData = data && data.data && data.data.length && data.data;
        var maxMachineCount = dimensionData &&
            dimensionData.reduce(function (maxMachineCount, data) {
                return Math.max(maxBy(Object.values(data.values)), maxMachineCount);
            }, 0);
        return {
            options: {
                data: {
                    columns: this.getDimensionData(dimensionType, dimensionData, dimensionMapping, singleDimensionToDisplay),
                    colors: mapValues(mapKeys(dimensionMapping, function (value) { return value.label; }), function (value) { return value.knownColorsMap.trend.raw; }),
                },
                axis: {
                    y: {
                        max: maxMachineCount ? maxMachineCount : 0,
                        min: 0,
                        tick: {
                            values: dimensionData ? ReportsService.getYTicks(maxMachineCount) : [],
                            format: function (value) { return _this.prettyNumberService.prettyNumber(value); },
                        },
                        padding: {
                            bottom: 0,
                        },
                    },
                    x: {
                        type: 'category',
                        categories: this.getDimensionDates(dimensionData),
                        tick: {
                            multiline: false,
                            culling: {
                                max: dimensionData
                                    ? Math.min(dimensionData.length + 1, MAX_LABELS)
                                    : MAX_LABELS,
                            },
                        },
                    },
                },
                point: {
                    show: false,
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                legend: {
                    show: false,
                },
                size: {
                    height: 200,
                },
            },
            disableCursor: true,
        };
    };
    MachineProtectionReportService.prototype.getSettingsDaily = function (type, dimensionMapping, data, getDimensionFilterData) {
        var _this = this;
        var dimensionData = data && data.data && data.data.length && data.data[0];
        var maxCount = dimensionData && dimensionData.values && maxBy(Object.values(dimensionData.values));
        var chartData = Object.entries(dimensionMapping).map(function (_a) {
            var key = _a[0], value = _a[1];
            return _this.getDimensionDataDaily(value.label, key, dimensionData);
        });
        var colorsPattern = Object.values(dimensionMapping).map(function (value) { return value.knownColorsMap.daily.raw; });
        return {
            options: {
                color: {
                    pattern: colorsPattern,
                },
                data: {
                    columns: [
                        [
                            this.i18nService.get('reporting.machineReport.dailyTooltipDescription')
                        ].concat(chartData.map(function (data) { return data.value; })),
                    ],
                    groups: [
                        Object.values(dimensionMapping).map(function (value) { return value.label; }),
                    ],
                    type: 'bar',
                    labels: {
                        format: function (value) { return (maxCount > 0 ? _this.prettyNumberService.prettyNumber(value) : ''); },
                    },
                    color: function (inColor, data) {
                        if (data.index !== undefined) {
                            return colorsPattern[data.index];
                        }
                        return inColor;
                    },
                    onclick: function (e) {
                        var clickedValue = Object.keys(dimensionMapping)[e.index];
                        _this.onClick(getDimensionFilterData, clickedValue);
                    },
                },
                tooltip: {
                    grouped: false,
                    contents: function (data) {
                        return "<table class=\"c3-tooltip\">\n\t\t\t\t\t\t\t<tbody>\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td class=\"name\">" + data[0].value + "</td>\n\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t</tbody>\n\t\t\t\t\t\t</table>";
                    },
                },
                bar: {
                    width: {
                        max: 8,
                        ratio: 0.2,
                    },
                },
                axis: {
                    rotated: type === ChartType.Horizontal,
                    x: {
                        type: 'category',
                        categories: chartData.map(function (data) { return data.label; }),
                        tick: {
                            multiline: type === ChartType.Horizontal,
                            culling: {
                                max: 1,
                            },
                            rotate: 35,
                        },
                        padding: { right: 0.5 },
                    },
                    y: {
                        show: true,
                        max: maxCount ? maxCount : 0,
                        min: 0,
                        tick: {
                            values: data ? ReportsService.getYTicks(maxCount) : [],
                            format: function (value) { return _this.prettyNumberService.prettyNumber(value); },
                        },
                        padding: {
                            bottom: 0,
                        },
                    },
                },
                padding: {
                    left: type === ChartType.Horizontal ? 100 : 50,
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                point: {
                    show: false,
                },
                legend: { show: false },
                size: {
                    height: 200,
                },
            },
        };
    };
    MachineProtectionReportService.prototype.onClick = function (getDimensionFilterData, dimensionKey) {
        var _this = this;
        if (!getDimensionFilterData) {
            return; // Dimension doesn't support drill down
        }
        var parsedFilters = {};
        var dimensionFilterData = getDimensionFilterData(dimensionKey);
        Object.entries(dimensionFilterData.currentFilters).forEach(function (_a) {
            var key = _a[0], values = _a[1];
            if (key !== dimensionFilterData.type) {
                switch (key) {
                    case 'healthStatus': {
                        var parsedValues = values.map(function (value) {
                            return encodeURIComponent(_this.machineProtectionStatusMapping.healthStatusMapping[value]
                                .machineListFilter);
                        });
                        parsedFilters.healthStatuses = parsedValues.join('|');
                        break;
                    }
                    case 'antivirusStatus': {
                        var parsedValues = values
                            .map(function (value) {
                            return encodeURIComponent(_this.machineProtectionStatusMapping.avStatusMapping[value]
                                .machineListFilter);
                        })
                            .filter(function (value) { return value !== ''; }); // ignore filters that doesn't have corresponding filter on machines page
                        parsedFilters.securityPropertiesRequiringAttention = parsedValues.join('|');
                        break;
                    }
                    case 'osPlatform': {
                        var parsedValues = values.map(function (value) {
                            return encodeURIComponent(_this.machineProtectionStatusMapping.osPlatformMapping[value].machineListFilter);
                        });
                        parsedFilters.osPlatforms = parsedValues.join('|');
                        break;
                    }
                    case 'osVersion': {
                        var parsedValues = values.map(function (value) {
                            return encodeURIComponent(_this.machineProtectionStatusMapping.osVersionMapping[value].machineListFilter);
                        });
                        parsedFilters.osVersions = parsedValues.join('|');
                        break;
                    }
                    case 'rbacGroupIds': {
                        parsedFilters.rbacGroupIds = dimensionFilterData.currentFilters[rbacGroupIdsFilter]
                            ? dimensionFilterData.currentFilters[rbacGroupIdsFilter].join('|')
                            : '';
                        break;
                    }
                }
            }
        });
        var defaultOsPlatformsIfAv = dimensionFilterData.type === 'antivirusStatus' ? 'osPlatforms=Windows10' : '';
        var drillDownFilters = [
            dimensionFilterData.dimensionFilter ? dimensionFilterData.dimensionFilter : '',
            parsedFilters.securityPropertiesRequiringAttention
                ? "securityPropertiesRequiringAttention=" + parsedFilters.securityPropertiesRequiringAttention
                : '',
            parsedFilters.healthStatuses ? "healthStatuses=" + parsedFilters.healthStatuses : '',
            parsedFilters.osPlatforms ? "osPlatforms=" + parsedFilters.osPlatforms : defaultOsPlatformsIfAv,
            parsedFilters.osVersions ? "releaseVersion=" + parsedFilters.osVersions : '',
            parsedFilters.rbacGroupIds ? "rbacGroupIds=" + parsedFilters.rbacGroupIds : '',
        ].filter(function (value) { return value !== ''; });
        this.trackNavigation(dimensionKey, drillDownFilters, dimensionFilterData.type);
        // There is no drill down for updated antivirus
        dimensionKey !== AvStatus.Updated &&
            this.router.navigate(['/machines'], {
                queryParams: {
                    filters: drillDownFilters.join(','),
                    range: '180',
                },
            });
    };
    MachineProtectionReportService.prototype.trackNavigation = function (clickedValue, drillDownFilters, dimension) {
        this.appInsightsService.trackEvent('UsageTrack', {
            type: TrackingEventType.Navigation,
            id: TrackingEventId.DrillDownToMachines,
            dimension: dimension,
            column: clickedValue,
            filters: drillDownFilters,
        });
    };
    MachineProtectionReportService.prototype.getDimensionDates = function (dimensionData) {
        var _this = this;
        return dimensionData
            ? dimensionData.map(function (data) {
                return _this.tzDateService.format(Date.UTC(data.date.getFullYear(), data.date.getMonth(), data.date.getDate()), 'MM/dd');
            })
            : [];
    };
    MachineProtectionReportService.prototype.getDimensionData = function (type, dimensionData, dimensionMapping, singleDimensionToDisplay) {
        var _this = this;
        return Object.keys(type)
            .filter(function (key) { return (singleDimensionToDisplay ? key === singleDimensionToDisplay : true); })
            .map(function (key) { return _this.getDimensionDataOvertime(dimensionMapping[key].label, key, dimensionData); });
    };
    MachineProtectionReportService.prototype.getDimensionDataOvertime = function (label, dimensionType, dimensionData) {
        return [
            label
        ].concat((dimensionData &&
            dimensionData.map(function (data) { return (data.values[dimensionType] ? data.values[dimensionType] : 0); })));
    };
    MachineProtectionReportService.prototype.getDimensionDataDaily = function (label, dimensionType, dimensionData) {
        return {
            label: label,
            value: dimensionData &&
                (dimensionData.values[dimensionType] ? dimensionData.values[dimensionType] : 0),
        };
    };
    MachineProtectionReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MachineProtectionReportService_Factory() { return new MachineProtectionReportService(i0.ɵɵinject(i1.Paris), i0.ɵɵinject(i2.TzDateService), i0.ɵɵinject(i3.AppInsightsService), i0.ɵɵinject(i4.PrettyNumberService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i6.MachineProtectionStatusMapping), i0.ɵɵinject(i7.I18nService)); }, token: MachineProtectionReportService, providedIn: "root" });
    return MachineProtectionReportService;
}());
export { MachineProtectionReportService };
export var rbacGroupIdsFilter = 'rbacGroupIds';
