var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { TaskArgs } from '../common/taskArgs/task-args.value-object';
import { RemediationTaskStatus } from './remediation-task-status.value-object';
import { TvmRemediationSharedEntityConfigurations, ParseRemediationDataQuery, } from '../remediationEndPointUtils';
import { RbacGroups } from './rbac-groups.value-object';
import { UserInfo } from '../common/remediation/requester.value-object';
import { RemediationTaskPriority } from './remediation-task-priority.enum';
import { RemediationTaskCompletionMethod } from './remediation-task-completion-method.enum';
import { RemediationTicket } from './remediation-task-ticket.value-object';
var ɵ0 = function (id) {
    return "remediationTasks/" + id;
}, ɵ1 = function (dataQuery) { return ParseRemediationDataQuery(dataQuery); }, ɵ2 = function (priority) { return RemediationTaskPriority[priority]; };
var RemediationTask = /** @class */ (function (_super) {
    __extends(RemediationTask, _super);
    function RemediationTask() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'title',
        }),
        __metadata("design:type", String)
    ], RemediationTask.prototype, "name", void 0);
    __decorate([
        EntityField({
            data: 'description',
        }),
        __metadata("design:type", String)
    ], RemediationTask.prototype, "description", void 0);
    __decorate([
        EntityField({
            data: 'mitigationDescription',
        }),
        __metadata("design:type", String)
    ], RemediationTask.prototype, "mitigationDescription", void 0);
    __decorate([
        EntityField({
            data: 'relatedComponent',
        }),
        __metadata("design:type", String)
    ], RemediationTask.prototype, "relatedComponent", void 0);
    __decorate([
        EntityField({
            data: 'targetAssets',
        }),
        __metadata("design:type", Number)
    ], RemediationTask.prototype, "targetAssets", void 0);
    __decorate([
        EntityField({
            data: 'potentialRisk',
        }),
        __metadata("design:type", String)
    ], RemediationTask.prototype, "potentialRisk", void 0);
    __decorate([
        EntityField({
            data: 'recommendationDescription',
        }),
        __metadata("design:type", String)
    ], RemediationTask.prototype, "recommendationDescription", void 0);
    __decorate([
        EntityField({
            data: 'fixedAssets',
        }),
        __metadata("design:type", Number)
    ], RemediationTask.prototype, "fixedAssets", void 0);
    __decorate([
        EntityField({
            data: 'dueOn',
        }),
        __metadata("design:type", Date)
    ], RemediationTask.prototype, "dueDate", void 0);
    __decorate([
        EntityField({
            data: 'taskArgs',
        }),
        __metadata("design:type", TaskArgs)
    ], RemediationTask.prototype, "taskArgs", void 0);
    __decorate([
        EntityField({
            data: 'requesterNotes',
        }),
        __metadata("design:type", String)
    ], RemediationTask.prototype, "requesterNotes", void 0);
    __decorate([
        EntityField({
            data: 'createdOn',
            serialize: false,
        }),
        __metadata("design:type", Date)
    ], RemediationTask.prototype, "creationTime", void 0);
    __decorate([
        EntityField({
            data: 'status',
        }),
        __metadata("design:type", RemediationTaskStatus)
    ], RemediationTask.prototype, "status", void 0);
    __decorate([
        EntityField({
            data: 'ticket',
        }),
        __metadata("design:type", RemediationTicket)
    ], RemediationTask.prototype, "ticket", void 0);
    __decorate([
        EntityField({
            data: 'originalRbacGroupIds',
        }),
        __metadata("design:type", RbacGroups)
    ], RemediationTask.prototype, "originalRbacGroupIds", void 0);
    __decorate([
        EntityField({
            data: 'displayedRbacGroupIds',
        }),
        __metadata("design:type", RbacGroups)
    ], RemediationTask.prototype, "displayedRbacGroupIds", void 0);
    __decorate([
        EntityField({
            data: 'requester',
        }),
        __metadata("design:type", UserInfo)
    ], RemediationTask.prototype, "requester", void 0);
    __decorate([
        EntityField({
            data: 'completer',
        }),
        __metadata("design:type", UserInfo)
    ], RemediationTask.prototype, "completer", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RemediationTask.prototype, "completionMethod", void 0);
    __decorate([
        EntityField({
            data: 'priority',
            defaultValue: RemediationTaskPriority.None,
            parse: ɵ2,
        }),
        __metadata("design:type", Number)
    ], RemediationTask.prototype, "priority", void 0);
    RemediationTask = __decorate([
        Entity(__assign({}, TvmRemediationSharedEntityConfigurations, { singularName: 'Remediation activity', pluralName: 'Remediation activities', endpoint: 'remediationTasks', parseItemQuery: ɵ0, parseDataQuery: ɵ1 }))
    ], RemediationTask);
    return RemediationTask;
}(EntityModelBase));
export { RemediationTask };
export { ɵ0, ɵ1, ɵ2 };
