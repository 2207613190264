import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { RecommendationExceptionAggregated, RecommendationException } from '@wcd/domain';

@Component({
	selector: 'recommendation-exception-aggregated-entity-panel',
	templateUrl: './recommendation-exception-aggregated.entity-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationExceptionAggregatedEntityPanelComponent extends EntityPanelComponentBase<
	RecommendationExceptionAggregated
> {
	isGlobalException: boolean;

	get recommendationExceptionAggregated(): RecommendationExceptionAggregated {
		return this.entity;
	}

	get recommendationException(): RecommendationException {
		const recommendationExceptionAggregated = { ...this.recommendationExceptionAggregated };
		return {
			id: recommendationExceptionAggregated.id,
			title: recommendationExceptionAggregated.title,
			exceptionJustification: recommendationExceptionAggregated.distinctJustifications[0],
			justificationContext: recommendationExceptionAggregated.justificationContext,
			expiringOn: recommendationExceptionAggregated.expiringOn,
			creationTime: recommendationExceptionAggregated.createdOn,
			requester: recommendationExceptionAggregated.requester,
			status: {
				value: recommendationExceptionAggregated.primaryStatus,
			},
			exceptionArgs: recommendationExceptionAggregated.exceptionArgs,
		};
	}

	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}

	initEntity(
		recommendationExceptionAggregated: RecommendationExceptionAggregated,
		isExtendedData: boolean = false
	) {
		super.initEntity(recommendationExceptionAggregated, isExtendedData);
		this.isGlobalException = recommendationExceptionAggregated.isGlobalException;
		this.changeDetectorRef.markForCheck();
	}
}
