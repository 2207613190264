import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TzDateService } from "@wcd/localization";

@Component({
	selector: 'tz-date',
	template: `
		{{ getDateFormat() }}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TzDateComponent {
	@Input() date: Date;
	@Input() dateFormat = 'short';

	constructor(
		private tzDateService: TzDateService) {
	}

	getDateFormat() {
		return this.date ? this.tzDateService.format(this.date, this.dateFormat) : '';
	}
}
