var _a;
import { ComponentRef } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { FilterValuesChecklistComponent, FilterValuesChecklistWithSearchComponent, } from '@wcd/ng-filters';
import { CustomTiIndicatorIdentifierType, CustomTiIndicatorsProperties, CustomTiIndicatorsTypes, TiIndicatorsFilterSearchApiCall, } from '@wcd/domain';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { CustomTiIndicatorImportPanelComponent } from '../components/custom-ti-indicator-import.panel.component';
import { CustomTiIndicatorNewModalComponent } from '../components/custom-ti-indicator-new.modal.component';
import { CustomTiIndicatorBackendService, } from './custom-ti-indicator.backend.services';
var CUSTOM_TI_INDICATORS_ICONS = (_a = {},
    _a[CustomTiIndicatorsTypes.Files] = FabricIconNames.Page,
    _a[CustomTiIndicatorsTypes.Ip] = FabricIconNames.Streaming,
    _a[CustomTiIndicatorsTypes.Url] = FabricIconNames.Link,
    _a[CustomTiIndicatorsTypes.Certificate] = FabricIconNames.Certificate,
    _a[CustomTiIndicatorsTypes.MemoryContent] = FabricIconNames.Processing,
    _a);
var CustomTiIndicatorsService = /** @class */ (function () {
    function CustomTiIndicatorsService(dialogsService, backendService, i18nService, paris) {
        this.dialogsService = dialogsService;
        this.backendService = backendService;
        this.i18nService = i18nService;
        this.paris = paris;
        this.getIcon = function (indicatorType) {
            return CUSTOM_TI_INDICATORS_ICONS[indicatorType];
        };
    }
    CustomTiIndicatorsService.prototype.showCustomTiIndicatorNewDialog = function (customTiIndicatorType, indicatorValue) {
        return this.dialogsService.showPanel(CustomTiIndicatorNewModalComponent, {
            id: 'new-custom-ti-indicator',
            type: PanelType.large,
            noBodyPadding: true,
            hasCloseButton: true,
        }, {
            customTiIndicatorType: customTiIndicatorType,
            indicatorValue: indicatorValue,
        });
    };
    CustomTiIndicatorsService.prototype.showCustomTiIndicatorImportDialog = function (customTiIndicatorType) {
        var headerText = this.getCustomTiIndicatorImportTitle(customTiIndicatorType);
        return this.dialogsService.showPanel(CustomTiIndicatorImportPanelComponent, {
            id: 'import-custom-ti-indicator',
            type: PanelType.medium,
            noBodyPadding: true,
            headerIcon: 'BulkUpload',
            headerText: headerText,
            hasCloseButton: false,
        }, {
            customTiIndicatorType: customTiIndicatorType,
        });
    };
    CustomTiIndicatorsService.prototype.getCustomTiIndicatorImportTitle = function (customTiIndicatorType) {
        var entityName;
        switch (customTiIndicatorType.id) {
            case CustomTiIndicatorsTypes.Files: {
                entityName = this.i18nService
                    .get('customTiIndicator.dataview.entity.names.file.pluralName')
                    .toLowerCase();
                break;
            }
            case CustomTiIndicatorsTypes.Ip: {
                entityName = this.i18nService.get('customTiIndicator.dataview.entity.names.ip.pluralName');
                break;
            }
            case CustomTiIndicatorsTypes.Url: {
                entityName = this.i18nService.get('customTiIndicator.dataview.entity.names.url.pluralName') + "/" + this.i18nService.get('customTiIndicator.dataview.entity.names.domain.pluralName');
                break;
            }
            case CustomTiIndicatorsTypes.Certificate: {
                entityName = this.i18nService
                    .get('customTiIndicator.dataview.entity.names.certificate.pluralName')
                    .toLowerCase();
                break;
            }
        }
        return this.i18nService.get('customTiIndicator.dataview.actions.import.title') + " " + entityName;
    };
    CustomTiIndicatorsService.prototype.downloadCsv = function (downloadParams) {
        return this.backendService.downloadCsv(downloadParams);
    };
    CustomTiIndicatorsService.prototype.getCustomTiDisplay = function (item, property) {
        switch (property.id) {
            case CustomTiIndicatorsProperties.TiIndicatorType: {
                return this.i18nService.get("customTiIndicator.dataview.entity.fields.tiIndicatorType." + item.tiIndicatorType.name.toLocaleLowerCase() + ".title");
            }
            default: {
                if (property.itemProperty) {
                    return item[property.id][property.itemProperty];
                }
                else {
                    return item[property.id];
                }
            }
        }
    };
    CustomTiIndicatorsService.prototype.getCustomTiFilters = function (propertyId, type) {
        var _this = this;
        switch (propertyId) {
            case CustomTiIndicatorsProperties.TiIndicatorType: {
                return {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (actionTypeId) {
                                var identifierType = _this.paris.getValue(CustomTiIndicatorIdentifierType, actionTypeId);
                                if (identifierType) {
                                    return {
                                        name: _this.i18nService.get("customTiIndicator.dataview.entity.fields.tiIndicatorType." + identifierType.name.toLowerCase() + ".title"),
                                        id: identifierType.id,
                                    };
                                }
                                return null;
                            },
                        },
                    },
                };
            }
            case CustomTiIndicatorsProperties.TiIndicator:
            case CustomTiIndicatorsProperties.Application: {
                return {
                    component: {
                        type: FilterValuesChecklistWithSearchComponent,
                        config: this.getFilterValuesConfig(propertyId, type),
                    },
                };
            }
        }
        return null;
    };
    CustomTiIndicatorsService.prototype.getFilterValuesConfig = function (propertyId, type) {
        var _this = this;
        return {
            search: {
                method: function (term) {
                    return _this.paris.apiCall(TiIndicatorsFilterSearchApiCall, {
                        field: propertyId,
                        term: term.trim(),
                        type: type,
                    });
                },
                placeholder: this.i18nService.get('customTiIndicator_search_textbox_placeholder'),
            },
        };
    };
    // Since browser supports only local time dates, we need to move the date values back in time and then create a DateTime
    // The browser will display the time in local time zone, but when "fixing" the date (e.g. subtracting the delta beween local time and UTC)
    // the value that the browser will show will actually be the UTC that we wanted to display
    CustomTiIndicatorsService.prototype.reinterpretUtcAsLocalTime = function (date) {
        var utcDateAsLocal = undefined;
        if (!!date) {
            utcDateAsLocal = moment(date)
                .subtract(new Date().getTimezoneOffset() / 60, 'hours')
                .toDate();
        }
        return utcDateAsLocal;
    };
    return CustomTiIndicatorsService;
}());
export { CustomTiIndicatorsService };
