import { Component } from '@angular/core';
import { EntityHeaderBottomComponentBase } from './entity-header-bottom.component.base';
import { BaselineProfileDetails } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { TvmColorService, TvmColor } from '../../../tvm/services/tvm-color.service';

@Component({
	template: `
		<div>
			<fab-icon
				iconName="StatusCircleInner"
				contentClass="wcd-vertical-align-bottom {{ iconClass }}"
			></fab-icon>
			{{ display }}
		</div>
	`,
})
export class BaselineProfileHeaderBottomComponent extends EntityHeaderBottomComponentBase<
	BaselineProfileDetails
> {
	status: boolean;
	display: string;
	iconClass: string;

	constructor(
		protected readonly globalEntityTypesService: GlobalEntityTypesService,
		protected readonly i18nService: I18nService,
		private tvmColorService: TvmColorService
	) {
		super(globalEntityTypesService, i18nService);
	}

	setEntity(entity) {
		super.setEntity(entity);
		this.status = this.entity.status;
		this.display = this.status
			? this.i18nService.strings.tvm_baseline_profiles_enabled
			: this.i18nService.strings.tvm_baseline_profiles_disabled;

		const iconColorName = this.tvmColorService.colorsMap.get(
			this.status ? TvmColor.Green : TvmColor.NeutralTertiary
		).name;

		this.iconClass = `ms-fontColor-${iconColorName}`;
	}
}
