export enum TrackingEventType {
	Action = 'Action',
	Button = 'Button',
	Chart = 'Chart',
	Close = 'Close',
	EntitiesDelete = 'EntitiesDelete',
	EntitySave = 'EntitySave',
	Export = 'Export',
	ExternalLink = 'ExternalLink',
	Filter = 'Filter',
	Navigation = 'Navigation',
	InvestigationGraphPoint = 'InvestigationGraphPoint',
	Saving = 'Save',
	Search = 'Search',
	Selection = 'Selection',
	SettingsSave = 'SettingsSave',
	SidePaneToggleButton = 'SidePaneToggleButton',
	Toggle = 'Toggle',
	TagStart = 'TagStart',
}
