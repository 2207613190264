import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { SoftwareVersion } from '@wcd/domain';

@Component({
	selector: 'software-version-entity-panel',
	templateUrl: './software-version.entity-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftwareVersionEntityPanelComponent extends EntityPanelComponentBase<SoftwareVersion> {
	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}

	initEntity(version: SoftwareVersion, isExtendedData: boolean = false) {
		super.initEntity(version, isExtendedData);
		this.changeDetectorRef.markForCheck();
	}

	get version(): SoftwareVersion {
		return this.entity;
	}
}
