import { DataviewField } from '@wcd/dataview';
import { SoftwareVersion, VERSION_DISTRIBUTION_FILTER_TAG } from '@wcd/domain';
import { PrettyNumberService } from '@wcd/prettify';
import { FeaturesService } from '@wcd/config';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { I18nService } from '@wcd/i18n';
var SoftwareVersionFieldsService = /** @class */ (function () {
    function SoftwareVersionFieldsService(prettyNumberService, featuresService, tvmTagsService, i18nService) {
        var _this = this;
        this.prettyNumberService = prettyNumberService;
        this.featuresService = featuresService;
        this.tvmTagsService = tvmTagsService;
        this.i18nService = i18nService;
        this._fieldsConfig = [
            {
                id: 'version',
                name: this.i18nService.get('tvm.common.version'),
            },
            {
                id: 'installations',
                name: this.i18nService.get('tvm.versionDetails.installedOn'),
                sort: {
                    sortDescendingByDefault: true,
                },
                getDisplay: function (softwareVersion) {
                    return _this.prettyNumberService.prettyNumber(softwareVersion.installations);
                },
            },
            {
                id: 'discoveredVulnerabilities',
                name: this.i18nService.get('tvm.versionDetails.discoveredVulnerabilities'),
                sort: {
                    sortDescendingByDefault: true,
                },
            },
            {
                id: 'tags',
                name: this.i18nService.get('common.tags'),
                component: {
                    type: TagsFieldComponent,
                    getProps: function (version) { return ({
                        tags: _this.tvmTagsService.getSoftwareVersionTags(version),
                    }); },
                },
                sort: { enabled: false },
            },
            {
                id: 'versionDistributionTags',
                name: this.i18nService.get('common.tags'),
                filterOnly: true,
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (filterValue) {
                                var values = _this.tvmTagsService
                                    .getSoftwareVersionTagsForFiltering()
                                    .map(function (tag) {
                                    return {
                                        value: VERSION_DISTRIBUTION_FILTER_TAG[tag],
                                        name: _this.i18nService.get("tvm_softwarePage_versionDistribution_filters_tags_" + tag),
                                        count: null,
                                    };
                                });
                                var v = values.find(function (val) { return val.value === VERSION_DISTRIBUTION_FILTER_TAG[filterValue]; });
                                return {
                                    id: v.value,
                                    rawValue: v.value,
                                    name: v.name,
                                };
                            },
                        },
                    },
                },
            },
        ];
    }
    Object.defineProperty(SoftwareVersionFieldsService.prototype, "fields", {
        get: function () {
            if (!this._fields) {
                this._fields = DataviewField.fromList(this._fieldsConfig);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SoftwareVersionFieldsService.prototype, "linuxSoftwareVersionFields", {
        get: function () {
            var _this = this;
            if (!this._linuxSoftwareInstallationFields) {
                var linuxFields = this._fieldsConfig.slice(0, 1).concat([
                    {
                        id: 'osDistribution',
                        name: this.i18nService.get('tvm_common_osDistribution'),
                        sort: { enabled: false },
                        getDisplay: function (softwareVersion) {
                            return softwareVersion.osDistribution
                                ? _this.i18nService.get("tvm_common_osDistribution_" + softwareVersion.osDistribution)
                                : _this.i18nService.strings.notAvailable_short;
                        },
                    }
                ], this._fieldsConfig.slice(1));
                this._linuxSoftwareInstallationFields = DataviewField.fromList(linuxFields);
            }
            return this._linuxSoftwareInstallationFields;
        },
        enumerable: true,
        configurable: true
    });
    return SoftwareVersionFieldsService;
}());
export { SoftwareVersionFieldsService };
