export enum CyberEventActionFilterGroupTypeName {
	AlertsRelatedEvents = 'AlertsRelatedEvents',
	AntiVirus = 'AntiVirus',
	AppControl = 'AppControl',
	AppGuard = 'AppGuard',
	ExploitGuard = 'ExploitGuard',
	Files = 'Files',
	Firewall = 'Firewall',
	Network = 'Network',
	Other = 'Other',
	Processes = 'Processes',
	Registry = 'Registry',
	ResponseActions = 'ResponseActions',
	SmartScreen = 'SmartScreen',
	UserActivity = 'UserActivity',
	ScheduledTask = 'ScheduledTask',
}
