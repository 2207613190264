import { ApiCall, ApiCallModel, HttpOptions, ParisConfig } from '@microsoft/paris';
import { MachineActionParameters, MachineActionRequestBody } from '../machine-requests.models';

@ApiCall({
	name: 'Release device from isolation - public API',
	endpoint: (config, query) => {
		return `api/machines/${query.where['machineId']}/unisolate`;
	},
	method: 'POST',
	baseUrl: (config: ParisConfig) => '<mdatpapi>',
	parseQuery: ({
		comment,
		externalId,
		machineId,
	}: MachineActionParameters): HttpOptions<MachineActionRequestBody> => ({
		data: {
			Comment: comment,
			ExternalId: externalId,
		},
		params: {
			machineId: machineId,
		},
	}),
})
export class MachineReleaseIsolatePublicApiApiCall extends ApiCallModel<void, MachineActionParameters> {}
