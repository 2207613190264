import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'File current instances count',
	pluralName: '',
	readonly: true,
})
export class FileCurrentInstancesCount extends ModelBase {
	@EntityField() fileCount: number;

	@EntityField() machineCount: number;
}
