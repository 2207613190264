import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	OnDestroy,
	Injector,
	ChangeDetectorRef,
} from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import {
	SoftwareVersion,
	EolState,
	LegalNoteLinks,
	ShouldShowLegalNoteFor,
	SoftwareInstallationAgg,
	SoftwareVersionInstalledAssetsRelationship,
	TvmEndPoint,
	OsDistribution,
} from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { PrettyNumberService } from '@wcd/prettify';
import { MessageBarStyles } from '../../../@entities/@tvm/common/styles';
import { IMessageBarStyleProps, MessageBarType } from 'office-ui-fabric-react';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TextToken, TvmTextsService } from '../../../tvm/services/tvm-texts.service';
import { TzDateService } from '@wcd/localization';
import { Paris, DataSet, DataQuery, RelationshipRepository } from '@microsoft/paris';
import { MaxItemsInCollapseExpandTable } from '../../../tvm/tvm.constants';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';

@Component({
	selector: 'software-version-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './software-version.entity-details.component.html',
})
export class SoftwareVersionEntityDetailsComponent extends EntityDetailsComponentBase<SoftwareVersion>
	implements OnInit, OnDestroy {
	private _subscription: Subscription;
	@Input() softwareVersion?: SoftwareVersion;
	private installations: string;
	private showEolDate: boolean;
	messageBarState = {
		showBody: false,
	};
	_messageBarStyles = MessageBarStyles;
	showEolMessage: boolean;
	eolText: string;
	legalNote: string;
	private messageBarType: MessageBarType;
	readonly MessageBarType = MessageBarType;
	shouldShowLegalNote: boolean;
	legalNoteLinkText: string;
	legalNoteLink: string;
	showLegalNoteLink: boolean;
	widgetEosTooltip: string;
	isEolVersion: boolean;
	isExposedDeviceFeatureEnabled: boolean;
	isExposedOperatingSystem: boolean;
	shouldShowOsDistribution: boolean;

	readonly MaxItemsInCollapseExpandTable = MaxItemsInCollapseExpandTable;
	private _softwareVersion: SoftwareVersion;

	installationRelationshipRepository: RelationshipRepository<SoftwareVersion, SoftwareInstallationAgg>;
	installations$: Observable<DataSet<SoftwareInstallationAgg>>;
	installedAssetsTitle: string;
	installedAssetsLoadingText: string;

	constructor(
		paris: Paris,
		private changeDetectorRef: ChangeDetectorRef,
		private prettyNumberService: PrettyNumberService,
		private sidePanelService: SidePanelService,
		public i18nService: I18nService,
		private tvmTextsService: TvmTextsService,
		private tzDateService: TzDateService,
		private tvmDownloadService: TvmDownloadService,
		featuresService: FeaturesService,
		injector: Injector
	) {
		super(injector);
		this.installationRelationshipRepository = paris.getRelationshipRepository(
			SoftwareVersionInstalledAssetsRelationship
		);
		this.isExposedDeviceFeatureEnabled = featuresService.isEnabled(Feature.TvmCommonExposedDevices);
		this.isExposedOperatingSystem = featuresService.isEnabled(Feature.TvmExposedOperatingSystems);
	}

	ngOnInit() {
		this.isEolVersion = this.softwareVersion.eolVersionState === EolState.AlreadyEOL;
		this.shouldShowOsDistribution =
			this.softwareVersion.osDistribution && this.softwareVersion.osDistribution != OsDistribution.None;
		this.installations = this.getPrettyNumberOfInstallations();
		this.showEolMessage = this.shouldShowEolMessage();
		this.showEolDate = this.showEolDateDecider();
		this.eolText = this.getEolText();
		this.messageBarType = this.isEolVersion ? MessageBarType.warning : MessageBarType.info;
		this.legalNote = this.tvmTextsService.getText(
			TextToken.HasEolVersionsMicrosoftLegalNote,
			this.softwareVersion.productId
		);
		this.showLegalNoteLink = Object.keys(LegalNoteLinks).includes(this.softwareVersion.productId);
		this.legalNoteLink = LegalNoteLinks[this.softwareVersion.productId];
		this.legalNoteLinkText = this.tvmTextsService.getText(
			TextToken.LegalNoteLinkText,
			this.softwareVersion.productId
		);
		this.shouldShowLegalNote = ShouldShowLegalNoteFor.includes(this.softwareVersion.productId);
		this.widgetEosTooltip = this.i18nService.strings.tvm_softwarePage_versionDistribution_eosTooltip;
		this.installedAssetsTitle = this.i18nService.strings.tvm_common_installedDevices;
		this.installedAssetsLoadingText = this.i18nService.strings.tvm_softwareVersion_loadingInstalledDevices;
		if (this.softwareVersion != undefined && this.softwareVersion != null) {
			this.loadNewInstallations(this.softwareVersion);
		}
		this._softwareVersion = this.softwareVersion;
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	getPrettyNumberOfInstallations() {
		return this.prettyNumberService.prettyNumber(this.softwareVersion.installations);
	}

	showEolDateDecider() {
		return (
			this.softwareVersion.eolVersionSinceDate &&
			(this.isEolVersion || this.softwareVersion.eolVersionState === EolState.UpcomingEOL)
		);
	}

	getStyles(messageBarProps: IMessageBarStyleProps) {
		if (this.messageBarState.showBody !== messageBarProps.expandSingleLine) {
			this.messageBarState.showBody = messageBarProps.expandSingleLine;
			this.changeDetectorRef.detectChanges();
		}
		return this._messageBarStyles;
	}

	shouldShowEolMessage() {
		return this.softwareVersion.eolVersionState === EolState.UpcomingEOL || this.isEolVersion;
	}

	getOsDistributionString() {
		return this.softwareVersion.osDistribution
			? this.i18nService.get(`tvm_common_osDistribution_${this.softwareVersion.osDistribution}`)
			: this.i18nService.strings.notAvailable_short;
	}

	getEolText() {
		if (this.isEolVersion) {
			if (this.softwareVersion.eolVersionSinceDate) {
				return this.i18nService.get('tvm.softwarePage.versionDistribution.eolText', {
					date: this.tzDateService.format(this.softwareVersion.eolVersionSinceDate, 'shortDate'),
					product: this.softwareVersion.name,
				});
			} else {
				return this.i18nService.get('tvm.softwarePage.versionDistribution.eolTextWithoutDate');
			}
		}
		if (this.softwareVersion.eolVersionState === EolState.UpcomingEOL) {
			return this.i18nService.get('tvm.softwarePage.versionDistribution.upcomingEolText', {
				date: this.tzDateService.format(this.softwareVersion.eolVersionSinceDate, 'shortDate'),
			});
		}
	}

	loadNewInstallations(softwareVersion: SoftwareVersion) {
		const queryOptions: DataQuery = {
			page: 1,
			pageSize: MaxItemsInCollapseExpandTable,
		};
		this.installationRelationshipRepository.sourceItem = softwareVersion;
		this.installations$ = this.installationRelationshipRepository.query(queryOptions);
	}

	openAllInstalledMachines() {
		this.sidePanelService.showAllInstalledAssetsPanel(this._softwareVersion);
	}

	exportInstalledMachines() {
		return this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.installationRelationshipRepository,
			TvmEndPoint.Analytics,
			`${this.tvmTextsService.getText(
				TextToken.SoftwareVersionsVendorNameAndVersion,
				this.softwareVersion
			)} - ${this.i18nService.strings.tvm_common_installedDevices}`,
			undefined
		);
	}
}
