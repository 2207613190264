
		<dataview
			id="domain-urls"
			className="no-left-padding wcd-flex-1"
			[fields]="domainUrlFields.fields"
			[entityType]="entityType"
			[repository]="domainRepository"
			[allowFilters]="false"
			[hideControls]="hideControls"
			[navigateOnChange]="navigateOnChange"
			[allowPaging]="allowPaging"
			[disableSelection]="disableSelection"
			[hideCommandBarOnEmptyData]="hideCommandBarOnEmptyData"
			[fixedOptions]="dataviewFixedOptions"
			[searchEnabled]="searchEnabled"
			[searchRequestText]="'Search in urls'"
			[defaultSortFieldId]="defaultSortFieldId"
			[allowColumnCustomization]="false"
			[label]="'reporting.webThreatReport.domainList.urlTitle' | i18n"
		>
		</dataview>
		<a
			*ngIf="displayViewAllLink && !fullUrlList"
			(click)="showAllDomains()"
			class="color-text-gray-900 wcd-margin-left"
		>
			{{ 'reporting.webThreatReport.domainList.viewAllLink' | i18n }}
			<fab-icon iconName="CaretRight"></fab-icon>
		</a>
	