export class DataError {
	message: string;
	status: number;
	statusText: string;
	serverError: string;

	constructor(error: Response | any) {
		if (error instanceof Response) {
			error.json().then(res => {
				const body: { error?: string } = res || {};
				this.serverError = body.error || JSON.stringify(body);
				this.status = error.status;
				this.statusText = error.statusText || '';
			});
		} else this.serverError = error.message ? error.message : error.toString();
	}
}
