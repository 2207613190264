var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { LiveResponseSession } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { LiveResponseService } from '../services/live-response.service';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { filter, switchMap } from 'rxjs/operators';
import { LiveResponseOutputParserService } from '../services/live-response-output-parser.service';
import { I18nService } from '@wcd/i18n';
var LiveResponseCommandEntityPanelComponent = /** @class */ (function (_super) {
    __extends(LiveResponseCommandEntityPanelComponent, _super);
    function LiveResponseCommandEntityPanelComponent(changeDetectorRef, liveResponseService, activatedEntity, i18nService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.liveResponseService = liveResponseService;
        _this.activatedEntity = activatedEntity;
        _this.i18nService = i18nService;
        return _this;
    }
    Object.defineProperty(LiveResponseCommandEntityPanelComponent.prototype, "command", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    LiveResponseCommandEntityPanelComponent.prototype.setEntity = function (entity, isExtendedData) {
        var _this = this;
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.setEntity.call(this, entity, isExtendedData);
        this.commandType$ =
            this.command.commandTypeId &&
                this.activatedEntity.currentEntity$.pipe(filter(function (_entity) { return _entity instanceof LiveResponseSession; }), switchMap(function (session) {
                    return _this.liveResponseService.findMatchingCommand(session, entity.commandTypeId);
                }));
        this.commandOutput = LiveResponseOutputParserService.getCommandOutput(entity);
    };
    LiveResponseCommandEntityPanelComponent.prototype.openIoInNewTab = function (data) {
        var newTab = window.open('', '_blank');
        newTab.document.open();
        newTab.document.write('<html><body><pre></pre></body></html>');
        var pre = newTab.document.querySelector('pre');
        pre.innerText = data;
        newTab.document.close();
        newTab.focus();
    };
    return LiveResponseCommandEntityPanelComponent;
}(EntityPanelComponentBase));
export { LiveResponseCommandEntityPanelComponent };
