var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { WebContentFilteringPolicy } from './web-content-filtering-policy.entity';
import { WebContentFilteringCategoryReport } from '../reports/web-content-filtering/category-report.entity';
var ɵ0 = function (report) { return ({ IndicatorValue: report.id }); }, ɵ1 = function (config) { return config.data.serviceUrls.userRequests; };
var WebContentFilteringWebCategoryRelationship = /** @class */ (function () {
    function WebContentFilteringWebCategoryRelationship() {
    }
    WebContentFilteringWebCategoryRelationship = __decorate([
        EntityRelationship({
            sourceEntity: WebContentFilteringCategoryReport,
            dataEntity: WebContentFilteringPolicy,
            allowedTypes: [RelationshipType.OneToMany],
            getRelationshipData: ɵ0,
            allItemsEndpointTrailingSlash: false,
            baseUrl: ɵ1,
            endpoint: 'webcategory/policies',
        })
    ], WebContentFilteringWebCategoryRelationship);
    return WebContentFilteringWebCategoryRelationship;
}());
export { WebContentFilteringWebCategoryRelationship };
export { ɵ0, ɵ1 };
