export enum DeviceType {
	Unknown = 'Unknown',
	NetworkDevice = 'NetworkDevice',
	Printer = 'Printer',
	AudioAndVideo = 'AudioAndVideo',
	Surveillance = 'Surveillance',
	GamingConsole = 'GamingConsole',
	GenericIoT = 'GenericIoT',
	SmartAppliance = 'SmartAppliance',
	Communication = 'Communication',
	VoiceAndVideo = 'VoiceAndVideo',
	Desktop = 'Desktop',
	Workstation = 'Workstation',
	Server = 'Server',
	Mobile = 'Mobile',
	SmartFacility = 'SmartFacility',
	Miscellaneous = 'Miscellaneous',
	Unclassified = 'Unclassified',
}
