import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { isNil } from 'lodash-es';
import {
	BooleanOperator,
	GuidedHuntingFilterCategory,
	QueryOperatorType,
} from './hunting-guided-query.models';

@ValueObject({
	singularName: 'Guided hunting filter',
	pluralName: 'Guided hunting filters',
	modelWith: (rawData) => (!isNil(rawData.Filters) ? GroupFilter : SingleFilter),
})
export class GuidedQueryFilter extends ModelBase {
	@EntityField({ data: 'NextBooleanOperator' })
	nextBooleanOperator?: BooleanOperator;
}

@ValueObject({ singularName: 'Guided hunting single filter', pluralName: 'Guided hunting single filters' })
export class SingleFilter extends GuidedQueryFilter {
	@EntityField({ data: 'FieldCategory' })
	fieldCategory: GuidedHuntingFilterCategory;

	@EntityField({ data: 'FieldName' })
	fieldName: string;

	@EntityField({ data: 'Operator' })
	operator: QueryOperatorType;

	@EntityField({ data: 'Values' })
	values: string[];
}

@ValueObject({ singularName: 'Guided hunting group filter', pluralName: 'Guided hunting group filters' })
export class GroupFilter extends GuidedQueryFilter {
	@EntityField({ data: 'Filters', arrayOf: GuidedQueryFilter })
	filters: Array<GuidedQueryFilter>;
}
