/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./entity-related-evidence.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/modules/distribution/distribution-element/distribution-element.component.ngfactory";
import * as i3 from "../../../shared/modules/distribution/distribution-element/distribution-element.component";
import * as i4 from "../../../shared/services/known-colors.service";
import * as i5 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "@angular/common";
import * as i8 from "./entity-related-evidence.component";
var styles_EntityRelatedEvidenceComponent = [i0.styles];
var RenderType_EntityRelatedEvidenceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EntityRelatedEvidenceComponent, data: {} });
export { RenderType_EntityRelatedEvidenceComponent as RenderType_EntityRelatedEvidenceComponent };
function View_EntityRelatedEvidenceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "distribution-element", [["class", "EntityRelatedEvidence__bar"]], null, null, null, i2.View_DistributionElementComponent_0, i2.RenderType_DistributionElementComponent)), i1.ɵdid(1, 573440, null, 0, i3.DistributionElementComponent, [i4.KnownColorsService], { barColor: [0, "barColor"], title: [1, "title"], mainText: [2, "mainText"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = "neutralSecondary"; var currVal_1 = _v.context.$implicit.name; var currVal_2 = _v.context.$implicit.count; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_EntityRelatedEvidenceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t", "\n\t"])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "entityCommon.panel.relatedEvidence.noDataMessage")); _ck(_v, 1, 0, currVal_0); }); }
export function View_EntityRelatedEvidenceComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i5.I18nPipe, [i6.I18nService]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "wcd-flex-horizontal wcd-flex-wrap-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EntityRelatedEvidenceComponent_1)), i1.ɵdid(4, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EntityRelatedEvidenceComponent_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shownEntities; _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.shownEntities.length === 0); _ck(_v, 7, 0, currVal_1); }, null); }
export function View_EntityRelatedEvidenceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "related-evidence", [], null, null, null, View_EntityRelatedEvidenceComponent_0, RenderType_EntityRelatedEvidenceComponent)), i1.ɵdid(1, 573440, null, 0, i8.EntityRelatedEvidenceComponent, [i6.I18nService], null, null)], null, null); }
var EntityRelatedEvidenceComponentNgFactory = i1.ɵccf("related-evidence", i8.EntityRelatedEvidenceComponent, View_EntityRelatedEvidenceComponent_Host_0, { relatedEvidenceCount: "relatedEvidenceCount" }, {}, []);
export { EntityRelatedEvidenceComponentNgFactory as EntityRelatedEvidenceComponentNgFactory };
