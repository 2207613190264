
		<wcd-datatable
			[items]="data$ | async"
			[columns]="tableColumns"
			[hidden]="error$ | async"
			[selectEnabled]="false"
			[showHeaders]="false"
			class="table-with-legend"
			(itemClick)="showMachineSidePanel($event.item)"
			[label]="
				'dashboard.threatAnalytics.mitigationRecommendation.affectedMachinesColumn'
					| i18n
			"
		></wcd-datatable>
	