var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnInit, ElementRef, } from '@angular/core';
import { Router } from '@angular/router';
import { ModalContainer } from '../../../../dialogs/modals/models/modal-container.model';
import { I18nService } from '@wcd/i18n';
import { Machine, MachineValue, DeviceCategory } from '@wcd/domain';
var IsolateModalComponent = /** @class */ (function (_super) {
    __extends(IsolateModalComponent, _super);
    function IsolateModalComponent(router, i18nService, elementRef) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.elementRef = elementRef;
        _this.isSaving = false;
        _this.isMultipleSelection = false;
        _this.onConfirm = new EventEmitter();
        _this.onCancel = new EventEmitter();
        _this.MachineValue = MachineValue;
        _this.DeviceCategory = DeviceCategory;
        return _this;
    }
    IsolateModalComponent.prototype.ngOnInit = function () {
        this.dialogContentText = this.getDialogContentText();
        this.undoInfoText = this.getUndoInfoText();
        this.settings = __assign({}, this.settings, { className: 'wcd-flex-vertical', title: this.getTitle() });
    };
    Object.defineProperty(IsolateModalComponent.prototype, "isDisabled", {
        get: function () {
            return (this.isSaving ||
                !this.commentText ||
                (this.isNotManagedDevice && !this.isNotManagedDeviceWarningsReady));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IsolateModalComponent.prototype, "isNotManagedDevice", {
        get: function () {
            return this.device.isMdatp && !this.device.isManagedByMdatp && !this.isMultipleSelection;
        },
        enumerable: true,
        configurable: true
    });
    IsolateModalComponent.prototype.getTitle = function () {
        return this.isMultipleSelection
            ? this.i18nService.strings
                .machines_entityDetails_actions_multipleIsolateMachines_dialog_isolate_title
            : this.device.isManagedByMdatp
                ? this.i18nService.get('machines_entityDetails_actions_isolateMachine_dialog_isolate_title', {
                    machineName: this.machineName,
                })
                : this.i18nService.get('machines_entityDetails_actions_isolateNotManagedDevice_dialog_isolate_title', {
                    machineName: this.machineName,
                });
    };
    IsolateModalComponent.prototype.getDialogContentText = function () {
        return this.isMultipleSelection
            ? this.i18nService.strings
                .machines_entityDetails_actions_multiIsolateMachines_dialog_isolate_content
            : this.device.isManagedByMdatp
                ? this.i18nService.strings.machines_entityDetails_actions_isolateMachine_dialog_isolate_content
                : this.i18nService.strings.machines_entityDetails_actions_isolateNotManagedDevice_dialog_isolate_content;
    };
    IsolateModalComponent.prototype.getUndoInfoText = function () {
        return this.device.isManagedByMdatp
            ? this.i18nService.strings.machines_entityDetails_actions_isolateMachine_dialog_isolate_messages_undo
            : this.i18nService.strings.machines_entityDetails_actions_isolateNotManagedDevice_dialog_isolate_messages_undo;
    };
    IsolateModalComponent.prototype.onConfirmHandler = function () {
        this.onConfirm.emit(Object.assign({
            comment: this.commentText,
            allowOutlookAndSkype: this.isOutlookAndSkypeAllowed,
        }, this.isMultipleSelection ? { bulkName: this.bulkName } : null));
        this.onConfirm.complete();
    };
    IsolateModalComponent.prototype.onCancelHandler = function () {
        this.onCancel.emit();
        this.onCancel.complete();
    };
    IsolateModalComponent.prototype.enableNotManagedDeviceModal = function () {
        this.isNotManagedDeviceWarningsReady = true;
    };
    return IsolateModalComponent;
}(ModalContainer));
export { IsolateModalComponent };
