import {
	ApplicationRef,
	ComponentFactoryResolver,
	EmbeddedViewRef,
	Injectable,
	Injector,
	Inject,
	OnDestroy,
	ComponentRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { SnackbarComponent } from './snackbar.component';
import { AppInsightsService } from '../../../../apps/portal/src/app/insights/services/app-insights.service';

// @dynamic
@Injectable()
export class SnackbarMountingService implements OnDestroy {
	snackBarRef: ComponentRef<SnackbarComponent>;

	constructor(
		private readonly applicationRef: ApplicationRef,
		private readonly injector: Injector,
		@Inject(DOCUMENT) private readonly document: Document,
		private appInsights: AppInsightsService,
		private readonly componentFactoryResolver: ComponentFactoryResolver
	) {}

	get isMounted() {
		return this.document.body.querySelectorAll('wcd-snackbar').length > 0;
	}

	mount() {
		if (this.isMounted) {
			console.warn('wcd-snackbar already mounted.');
			return;
		}

		this.snackBarRef = this.componentFactoryResolver
			.resolveComponentFactory(SnackbarComponent)
			.create(this.injector);

		this.applicationRef.attachView(this.snackBarRef.hostView);

		this.document.body.appendChild((this.snackBarRef.hostView as EmbeddedViewRef<any>).rootNodes[0]);
	}

	ngOnDestroy(): void {
		try {
			this.snackBarRef && this.snackBarRef.destroy();
			this.document.body.querySelectorAll('wcd-snackbar').forEach(elem => {
				elem.parentNode.removeChild(elem);
			});
		} catch (e) {
			console.error(`Can't clear snackbar`);
			this.appInsights.trackException(e);
		}
	}
}
