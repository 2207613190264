/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef } from '@angular/core';
import { CyberEventsActionTypesService, } from '../services/cyber-events-action-types.service';
import { AccessTokenModificationDetails, CommandLine, CyberEvent, CyberEventEntityRelationTypeEnum, Process, File, FileInstance, LegacyUser, NetworkEndpoint, RegistryModificationDetails, ScheduledTask, Email, } from '@wcd/domain';
import { isNil } from 'lodash-es';
import { Paris } from '@microsoft/paris';
import { tap } from 'rxjs/operators';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { Feature, FeaturesService } from "@wcd/config";
var DEPTH_LEVEL_LEFT_PADDING = 24;
var supportedEntityTypes = {
    accessToken: AccessTokenModificationDetails,
    commandLine: CommandLine,
    file: File,
    fileInstance: FileInstance,
    networkEndpoint: NetworkEndpoint,
    process: Process,
    registryModification: RegistryModificationDetails,
    scheduledTask: ScheduledTask,
    user: LegacyUser,
    email: Email,
};
var CyberEventEntitiesGraphComponent = /** @class */ (function () {
    function CyberEventEntitiesGraphComponent(globalEntityTypesService, cyberEventsActionTypesService, paris, cd, featuresService) {
        this.globalEntityTypesService = globalEntityTypesService;
        this.cyberEventsActionTypesService = cyberEventsActionTypesService;
        this.paris = paris;
        this.cd = cd;
        this.featuresService = featuresService;
        this.CyberEventEntityRelationTypeEnum = CyberEventEntityRelationTypeEnum;
        this.DEPTH_LEVEL_LEFT_PADDING = DEPTH_LEVEL_LEFT_PADDING;
        this.minimizedSections = new Set();
        this.supportedEntityTypes = supportedEntityTypes;
    }
    CyberEventEntitiesGraphComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        this.buildEntitesGraphOnChanges(changes);
        // OneCyber events are not enriched from BE so a manual enrichment for specific entities is required
        this.enrichOneCyberEntities().then(function () {
            _this.buildEntitesGraphOnChanges(changes, true);
        });
    };
    CyberEventEntitiesGraphComponent.prototype.getEventEntityRelationText = function (entity) {
        return this.cyberEventsActionTypesService.getEventEntityRelationDescription(this.event, entity);
    };
    CyberEventEntitiesGraphComponent.prototype.toggleSections = function (entity) {
        if (this.minimizedSections.has(this.getEntityUniqueId(entity)))
            this.minimizedSections.delete(this.getEntityUniqueId(entity));
        else
            this.minimizedSections.add(this.getEntityUniqueId(entity));
    };
    CyberEventEntitiesGraphComponent.prototype.getEntityUniqueId = function (entity) {
        return this.event.id + "_" + (entity.entityType && entity.entityType.singularName) + "_" + (entity.item && entity.item.id);
    };
    CyberEventEntitiesGraphComponent.prototype.buildEntitesGraphOnChanges = function (changes, dataEnriched) {
        if (dataEnriched === void 0) { dataEnriched = false; }
        this.entitesGraphInitilized = false;
        this.entities = this.cyberEventsActionTypesService.getEventEntities(this.event);
        if (changes.event) {
            this.dataChanged = !changes.event.isFirstChange() || dataEnriched;
            this.collapseEntitiesNodes();
        }
        this.entitesGraphInitilized = true;
        this.cd.markForCheck();
    };
    CyberEventEntitiesGraphComponent.prototype.collapseEntitiesNodes = function () {
        var _this = this;
        if (this.cyberEventsActionTypesService.isOneCyberEvent(this.event)) {
            this.entities
                .filter(function (entity) { return entity.isCollapsed; })
                .map(function (entity) { return _this.minimizedSections.add(_this.getEntityUniqueId(entity)); });
        }
        else {
            this.entities
                .filter(function (entity) {
                return _this.collapseEntities === 'all' ||
                    (_this.collapseEntities === 'parents' &&
                        (entity.item === _this.event.initiatingProcessParent ||
                            entity.item === _this.event.initiatingProcessParentParent));
            })
                .map(function (entity) { return _this.minimizedSections.add(_this.getEntityUniqueId(entity)); });
        }
    };
    CyberEventEntitiesGraphComponent.prototype.enrichOneCyberEntities = function () {
        if (this.cyberEventsActionTypesService.isOneCyberEvent(this.event)) {
            var getFilePromises = [];
            var _loop_1 = function (entity) {
                var _a, _b;
                var entityType = this_1.globalEntityTypesService.getEntityType(entity.entityType).entity;
                if (entityType === Process) {
                    var sha1 = entity.item['ImageFile'] ? entity.item['ImageFile']['Sha1'] : null;
                    if (!isNil(sha1) && isNil(entity.item['ImageFile']['CertificateInfo'])) {
                        getFilePromises.push(this_1.paris.getItemById(File, sha1, undefined, (_a = {}, _a['newFilesApi'] = this_1.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW), _a)).pipe(tap(function (file) { return entity.item['ImageFile']['CertificateInfo'] = file.certificateInfo; })).toPromise().catch(function () { }));
                    }
                }
                else if (entityType === File || entityType === FileInstance) {
                    if (!isNil(entity.item['Sha1']) && isNil(entity.item['CertificateInfo'])) {
                        getFilePromises.push(this_1.paris.getItemById(File, entity.item['Sha1'], undefined, (_b = {}, _b['newFilesApi'] = this_1.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW), _b)).pipe(tap(function (file) { return entity.item['CertificateInfo'] = file.certificateInfo; })).toPromise().catch(function () { }));
                    }
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = this.event.entities; _i < _a.length; _i++) {
                var entity = _a[_i];
                _loop_1(entity);
            }
            return getFilePromises.length > 0 ? Promise.all(getFilePromises) : Promise.reject();
        }
        return Promise.reject();
    };
    return CyberEventEntitiesGraphComponent;
}());
export { CyberEventEntitiesGraphComponent };
