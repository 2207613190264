import { Component } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';

@Component({
	selector: 'investigations-dataview-selector',
	template: `
		<seville-investigations-dataview
			*ngIf="useSevilleData; else oldInvestigations"
		></seville-investigations-dataview>
		<ng-template #oldInvestigations>
			<investigations-dataview></investigations-dataview>
		</ng-template>
	`,
})
export class InvestigationsDataviewSelectorComponent {
	useSevilleData: boolean;
	constructor(private featuresService: FeaturesService) {
		this.useSevilleData = this.featuresService.isEnabled(Feature.AirsApiOffloading);
	}
}
