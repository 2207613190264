import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('status', statusFilter);

statusFilter.$inject = ['$log'];

function statusFilter($log) {
	return function(input, conclusion) {
		if (!input) {
			return '';
		}

		// take only the resolved conclusion, if there is none - the status isn't resolved.
		var index = input.toString().indexOf(':');
		var status = null;
		if (index < 0) {
			status = input.toString();
		} else {
			status = input.toString().slice(0, index);
		}

		// return the resolved conclusion
		if (conclusion && index > 0) {
			return input.toString().slice(index + 1);
		}
		if (conclusion && index < 0) {
			return null;
		}
		switch (status.toString()) {
			case 'Unspecified':
			case '1':
				return 'Unspecified';
			case 'New':
			case '2':
				return 'New';
			case 'InProgress':
			case '4':
				return 'In progress';
			case 'Resolved':
			case '8':
				return 'Resolved';
			case '16':
				return 'Hidden';
			case 'Resolved [Valid]':
				return status;
			case 'Resolved [Valid - Allowed]':
				return status;
			case 'Resolved [False Alarm]':
				return status;
			case 'Multiple':
				return 'Multiple';
			default:
				$log.error('Status filter - unknown status - ' + input);
				return 'Unknown';
		}
	};
}
