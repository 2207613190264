import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Bilbao license',
	pluralName: 'Bilbao license',
	readonly: true,
})
export class BilbaoLicense extends ModelBase {
	@EntityField({ data: 'Enabled' })
	readonly enabled: boolean;

	@EntityField({ data: 'DaysLeft' })
	readonly daysLeft: number;

	@EntityField({ data: 'InTrial' })
	readonly inTrial: boolean;
}
