<div *ngIf="baselineProfileDevice && machine; else loading" class="tvm wcd-scroll-vertical wcd-margin-medium-top">
	<div class="tvm-list" [ngClass]="{ 'tvm-list--no-columns': mode === 'EntityPage' }">
		<dl class="tvm-list__key_val_item">
			<dt>
				{{ 'tvm_baseline_profiles_devices_domain_title' | i18n }}
			</dt>
			<dd>
				{{baselineProfileDevice.domain || ('tvm_baseline_none' | i18n)}}
			</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>
				{{ 'tvm_baseline_profile_entity_deviceTab_sidePanel_externalIpAddress_title' | i18n }}
			</dt>
			<dd>
				{{machine.lastExternalIp || noDataAvailable}}
			</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>
				{{ 'tvm_baseline_profile_entity_deviceTab_sidePanel_firstSeen_title' | i18n }}
			</dt>
			<dd>
				{{(machine.firstSeen | date:'medium') || noDataAvailable}}
			</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>
				{{ 'tvm_baseline_profiles_devices_lastSeen_title' | i18n }}
			</dt>
			<dd>
				{{(baselineProfileDevice.lastSeen | date:'medium') || noDataAvailable}}
			</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>
				{{ 'tvm_baseline_profile_entity_deviceTab_sidePanel_dataSenstivity_title' | i18n }}
			</dt>
			<dd *ngLet="(dataSensitivity$ | async) as dataSensitivity">
				{{
					dataSensitivity
						? dataSensitivity.displayText
						: ('machines.entityDetails.fields.dataSensitivity.noData' | i18n)
				}}
			</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>
				{{ 'tvm_baseline_profile_entity_deviceTab_sidePanel_os_title' | i18n }}
			</dt>
			<dd>
				<ng-container *ngIf="machine.os?.platform || machine.itpOsVersion; else unknownOs">
					<ng-container *ngIf="machine.os?.platform; else itpOsVersion">
						{{ machine.os.platform.name }}
						{{ machine.os.processor || '' }}
					</ng-container>
					<ng-template #itpOsVersion>
						{{ machine.itpOsVersion }}
					</ng-template>
				</ng-container>
				<ng-template #unknownOs>
					{{ noDataAvailable }}
				</ng-template>
			</dd>
		</dl>
	</div>
	<div class="tvm-list">
		<dl class="tvm-list__block_item wcd-margin-large-top">
			<dt>
				{{ 'tvm_baseline_profiles_devices_deviceCompliance_title' | i18n }}
			</dt>
			<dd>
				<baseline-compliant-bar
					[compliantCount]="baselineProfileDevice.compliantConfigurations"
					[notCompliantCount]="baselineProfileDevice.applicableConfigurations - baselineProfileDevice.compliantConfigurations"
					[total]="baselineProfileDevice.applicableConfigurations"
					[showPercentage]="false"
					[showNumbers]="false"
				>
				</baseline-compliant-bar>
			</dd>
		</dl>
	</div>
</div>

<ng-template #loading>
	<fab-spinner
		[size]="SpinnerSize.large"
		[label]="'common_loading' | i18n"
		labelPosition="right"
	></fab-spinner>
</ng-template>
