import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { EncodeQueryTextApiCall, TimeRangeId } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { MessageBarType } from 'office-ui-fabric-react';
import { MagellanIsolationStatusService } from '../../services/magellan-isolation.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'isolation-modal-warnings',
	template: `
		<div>
			<!-- duplicate ips note -->
			<div class="wcd-margin-mediumSmall-bottom">
				<fab-message-bar
					*ngIf="devicesWithSameIp.length > 1"
					[messageBarType]="MessageBarType.warning"
				>
					<span>{{
						'MachineAction.IsolatDeviceWithDuplicateIps.warning'
							| i18n: { duplicatesCount: devicesWithSameIp.length - 1 }
					}}</span>
					<a href="{{ devicesWithSameIpLink }}" target="_blank" rel="noopener noreferrer">{{
						'MachineAction_IsolatDeviceMaybeHasDuplicateIps_Link' | i18n
					}}</a>
				</fab-message-bar>
			</div>

			<!-- unknown duplicate ips note -->
			<div class="wcd-margin-mediumSmall-bottom">
				<fab-message-bar
					*ngIf="errorOnDevicesWithSameIpRequest"
					[messageBarType]="MessageBarType.info"
				>
					<div>{{ 'MachineAction.IsolatDeviceMaybeHasDuplicateIps.info.first.line' | i18n }}</div>
					<div>
						{{ 'MachineAction.IsolatDeviceMaybeHasDuplicateIps.info.second.line' | i18n
						}}<a href="{{ devicesWithSameIpLink }}" target="_blank" rel="noopener noreferrer">{{
							'MachineAction.IsolatDeviceMaybeHasDuplicateIps.Link' | i18n
						}}</a>
					</div>
				</fab-message-bar>
			</div>

			<!-- isolating network device note -->
			<div class="wcd-margin-mediumSmall-bottom">
				<fab-message-bar *ngIf="isNetworkDevice" [messageBarType]="MessageBarType.warning">
					<span>{{ 'MachineAction.IsolatNetworkDevice.warning' | i18n }}</span>
				</fab-message-bar>
			</div>

			<!-- isolating high valued device note -->
			<div class="wcd-margin-mediumSmall-bottom">
				<fab-message-bar
					*ngIf="isHighValueAsset && !isNetworkDevice"
					[messageBarType]="MessageBarType.info"
				>
					<span>{{ 'MachineAction.IsolatHighValueDevice.info' | i18n }}</span>
				</fab-message-bar>
			</div>
		</div>
	`,
})
export class IsolationModalWarningsComponent implements OnInit {
	@Input() isHighValueAsset: boolean;
	@Input() isNetworkDevice: boolean;
	@Input() deviceId: string;
	@Output() readonly onReady = new EventEmitter<void>();

	readonly MessageBarType = MessageBarType;
	devicesWithSameIp: string[] = [];
	devicesWithSameIpLink = '/hunting';
	errorOnDevicesWithSameIpRequest: boolean;

	constructor(
		private paris: Paris,
		private changeDetector: ChangeDetectorRef,
		private magellanIsolationStatusProvider: MagellanIsolationStatusService
	) {}

	ngOnInit() {
		this.init();
	}

	async init() {
		try {
			this.devicesWithSameIp = await this.magellanIsolationStatusProvider.getDevicesWithSameIps(
				this.deviceId
			);
			this.devicesWithSameIpLink = await this.getQueryLink();
		} catch (e) {
			try {
				this.devicesWithSameIpLink = await this.getQueryLinkFromDeviceId();
			} finally {
				this.errorOnDevicesWithSameIpRequest = true;
			}
		} finally {
			this.changeDetector.detectChanges();
			this.onReady.emit();
		}
	}

	private getQueryLinkFromDeviceId() {
		const queryText = `let IsolatedDeviceId = "${this.deviceId}";
				let deviceIps = DeviceNetworkInfo
				| where DeviceId == IsolatedDeviceId
				| summarize arg_max(Timestamp, *) by DeviceId
				| join DeviceNetworkInfo on ReportId, DeviceId
				| project DeviceId, IPAddresses
				| mvexpand parse_json(IPAddresses )
				| project DeviceId, IPAddress = tostring(IPAddresses.IPAddress);
				let otherDevices = DeviceNetworkInfo
				| where DeviceId != IsolatedDeviceId
				| summarize arg_max(Timestamp, *) by DeviceId
				| join DeviceNetworkInfo on ReportId, DeviceId
				| project DeviceId, IPAddresses, Timestamp
				| mvexpand parse_json(IPAddresses )
				| project DeviceId, Timestamp, IPAddress = tostring(IPAddresses.IPAddress);
				// results
				deviceIps
				| join kind=rightsemi otherDevices on IPAddress
				| summarize arg_max(Timestamp, *) by DeviceId`;

		return this.getAdvancedHuntingLink(queryText);
	}

	private getQueryLink() {
		if (this.devicesWithSameIp.length <= 1) {
			return '';
		}

		const queryText = `DeviceInfo
		| where DeviceId in ("${this.devicesWithSameIp.join('", "')}")
		| summarize arg_max(Timestamp, *) by DeviceId`;
		return this.getAdvancedHuntingLink(queryText);
	}

	private async getAdvancedHuntingLink(queryText: string) {
		const encodedQuery = await this.paris
			.apiCall<string, string>(EncodeQueryTextApiCall, queryText)
			.toPromise();
		return `/hunting?query=${encodedQuery}&timeRangeId=${TimeRangeId.month}`;
	}
}
