import { Injectable } from '@angular/core';
import { formatDate, getLocaleId } from "@angular/common";
import { LocaleConfigService } from './locale-config.service';
import { getTzString } from '../locale.utils';
import { sccHostService } from '@wcd/scc-interface';
import {FeaturesService, Feature} from "@wcd/config";
declare const moment: typeof import('moment');

const MDATPtoSCCDateFormat = {
	'short': (date) => `${sccHostService.i18n.format(date, 'd')}, ${sccHostService.i18n.format(date, 't')}`,
	'shortWithMillis': (date) => `${sccHostService.i18n.format(date, 'd')}, ${sccHostService.i18n.format(date, 'T').replace(/ ([AP]M)/, `.${date.getMilliseconds().toString().padStart(3, '0')} $1`)}`,
	'medium': (date) => `${sccHostService.i18n.format(date, 'D')}, ${sccHostService.i18n.format(date, 'T')}`,
	'shortDate': (date) => `${sccHostService.i18n.format(date, 'd')}`,
	'mediumDate': (date) => `${sccHostService.i18n.format(date, 'D')}`,
	'longDate': (date) => `${sccHostService.i18n.format(date, 'D')}`,
	'shortTime': (date) => `${sccHostService.i18n.format(date, 't')}`,
	'mediumTime': (date) => `${sccHostService.i18n.format(date, 'T')}`,
	'monthDayOnly': (date) => `${sccHostService.i18n.format(date, 'm')}`,
	'MM/dd': (date) => `${sccHostService.i18n.format(date, 'm')}`,
	'MM/dd/yyyy': (date) => `${sccHostService.i18n.format(date, 'd')}`,
	'MM/dd/yyyy HH:mm:ss.SSS': (date) => `${sccHostService.i18n.format(date, 'd')} ${sccHostService.i18n.format(date, 'T')}.${date.getMilliseconds().toString().padStart(3, '0')} $1\`)}`,
	'h:mm:ss.SSS a': (date) =>`${sccHostService.i18n.format(date, 'T').replace(/ ([AP]M)/, `.${date.getMilliseconds().toString().padStart(3, '0')} $1`)}`,
	'MMM d, y, h:mm:ss.SSS a': (date) => `${sccHostService.i18n.format(date, 'D')} ${MDATPtoSCCDateFormat['h:mm:ss.SSS a'](date)}`,
	'longFullDateTime': (date) => `${sccHostService.i18n.format(date, 'F')}`,
	'fullDateTime': (date) => `${sccHostService.i18n.format(date, 'f')}`,
}

const DATE_TIME_FORMAT_OPTIONS = {
	year: "numeric",
	month: "numeric",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
	second: "numeric"
}

@Injectable({ providedIn: 'root' })
export class TzDateService {
	constructor(private readonly localeConfigService: LocaleConfigService,
				private readonly featuresService: FeaturesService) {}

	format(value: string | number | Date, format?: string, timezone?: string, locale?: string): string {
		format = format || 'mediumDate';
		locale = locale || this.localeConfigService.selectedLocale;

		let localeId: string;
		if (locale) {
			try {
				localeId = getLocaleId(locale);
			} catch (e) {
				// do nothing, will default to en-US;
			}
		}

		timezone =
			timezone ||
			(this.localeConfigService.isLocalTimeZone
				? null
				: createTzStringForPipe(this.localeConfigService.selectedTimezone));

		if (sccHostService.isSCC  &&
			this.featuresService.isEnabled(Feature.SccDateFormat) &&
			MDATPtoSCCDateFormat[format])  {
			return MDATPtoSCCDateFormat[format](value, format, localeId, timezone)
		}

		if (format !== 'shortWithMillis') {
			return formatDate(value, format, localeId, timezone);
		}

		const date = new Date(value);
		const options =  timezone ? { ...DATE_TIME_FORMAT_OPTIONS, timeZone: moment.tz(timezone).format("z") } : DATE_TIME_FORMAT_OPTIONS;
		return new Intl.DateTimeFormat(locale, options).format(date).replace(/( [AP]M)?$/, `.${date.getMilliseconds().toString().padStart(3, '0')}$1`);
	}
}

function createTzStringForPipe(tz: number): string {
	return getTzString(tz * 60);
}
