import { ValueObject, EntityField } from '@microsoft/paris';
import { FileActions } from './file.action-base.value-object';

@ValueObject({
	singularName: 'File quarantine actions',
	pluralName: 'File quarantine actions',
	readonly: true,
})
export class FileQuarantineActions extends FileActions {
	@EntityField() statuses: ReadonlyArray<{ id: string; count: number }>;
	@EntityField() isCancelable: boolean;
}
