
		<div
			*ngFor="let item of eventsDisplayItems"
			class="summary-bar"
			[wcdTooltip]="getTooltip(item.metadata.event)"
			(click)="select.emit(item.metadata.event)"
			[class.events-summary-bars--selectable]="isSelectable"
			[ngClass]="[item.colorClass, 'events-summary-bars--' + size]"
			[style.background-color]="item.color"
			[style.flex]="item.ratio"
			(keyup.enter)="select.emit(item.metadata.event)"
			[attr.tabindex]="isSelectable ? '0' : undefined"
			[attr.role]="isSelectable ? 'button' : 'img'"
			aria-roledescription="tooltip"
			[attr.aria-label]="getTooltip(item.metadata.event)"
		></div>
		<div *ngIf="(!eventsDisplayItems || eventsDisplayItems.length == 0) && emptyColorClass"
			 class="summary-bar"
			 [ngClass]="[emptyColorClass, 'events-summary-bars--' + size]"
			 [style.flex]="1"
		></div>
	