import { OnInit, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { MdeUserRoleAction, MdeUserRoleActionEnum } from '@wcd/domain';
var UserRolePermissionsComponent = /** @class */ (function () {
    function UserRolePermissionsComponent() {
        this.partiallySelectedCategories = {};
        this.selectedValues = {};
        this.disabledPermissions = {};
        this.mandatoryPermissions = {};
        this.relevantActions = [];
        this.liveResponseId = MdeUserRoleActionEnum.liveResponseBasic | MdeUserRoleActionEnum.liveResponseAdvanced;
        this.childToParentMap = {};
        // Enables to use the static function 'getHelpKey' in the html file
        this.userRoleAction = MdeUserRoleAction;
        this.onChange = function (_) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(UserRolePermissionsComponent.prototype, "isLiveResponseSelected", {
        set: function (isLiveResponseSelected) {
            var _this = this;
            this.selectedValues[this.liveResponseId] = isLiveResponseSelected;
            var liveResponseAction = this.relevantActions.find(function (action) { return action.id === _this.liveResponseId; });
            if (liveResponseAction) {
                this.onValueChanged(liveResponseAction);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserRolePermissionsComponent.prototype, "allowedActionsModel", {
        get: function () {
            return this._allowedActionsModel;
        },
        set: function (values) {
            this._allowedActionsModel = values;
            this.flattenedActions = MdeUserRoleAction.flatten(values);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserRolePermissionsComponent.prototype, "userAllowedActions", {
        get: function () {
            var _this = this;
            return this.flattenedActions.filter(function (value) { return _this.selectedValues[value.id]; });
        },
        set: function (userAllowedActions) {
            var _this = this;
            if (!userAllowedActions)
                return;
            userAllowedActions.forEach(function (allowedAction) {
                var setKey = (allowedAction.id & _this.liveResponseId) !== 0 ? _this.liveResponseId : allowedAction.id;
                _this.selectedValues[setKey] = true;
            });
        },
        enumerable: true,
        configurable: true
    });
    UserRolePermissionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.relevantActions = [
            {
                id: MdeUserRoleActionEnum.liveResponseBasic | MdeUserRoleActionEnum.liveResponseAdvanced,
                additionalImplicitAllowedActions: MdeUserRoleActionEnum.viewData,
            },
        ];
        this.relevantActions = this.relevantActions.concat(this.flattenedActions);
        this.relevantActions.forEach(function (permission) { return (_this.disabledPermissions[permission.id] = false); });
        this.disableMandatoryPermissions();
        this.updateChildrenStatuses();
        this.relevantActions
            .filter(function (action) { return _this.selectedValues[action.id]; })
            .forEach(function (action) { return _this.onValueChanged(action); });
    };
    UserRolePermissionsComponent.prototype.writeValue = function (values) {
        this.userAllowedActions = values;
    };
    UserRolePermissionsComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    UserRolePermissionsComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    UserRolePermissionsComponent.prototype.setDisabledState = function (isDisabled) {
        this.isDisabled = isDisabled;
    };
    UserRolePermissionsComponent.prototype.onValueChanged = function (action) {
        var _this = this;
        if (action.children) {
            action.children.forEach(function (child) {
                if (!_this.disabledPermissions[child.id]) {
                    _this.selectedValues[child.id] = _this.selectedValues[action.id];
                }
            });
        }
        this.updateActions(action);
        this.onChange(this.userAllowedActions);
    };
    UserRolePermissionsComponent.prototype.isPermissionDisabled = function (permission) {
        return this.disabledPermissions[permission.id] || this.isDisabled;
    };
    UserRolePermissionsComponent.prototype.disableMandatoryPermissions = function () {
        var _this = this;
        this.allowedActionsModel.forEach(function (action) {
            if (action.mandatory) {
                _this.mandatoryPermissions[action.id] = true;
                _this.disabledPermissions[action.id] = true;
                if (action.children) {
                    var someChildrenAreSelected = action.children.some(function (child) { return _this.selectedValues[child.id]; });
                    if (!someChildrenAreSelected) {
                        action.children.forEach(function (child) { return (_this.selectedValues[child.id] = true); });
                    }
                }
                else {
                    _this.selectedValues[action.id] = true;
                }
            }
            action.children &&
                action.children.forEach(function (child) {
                    if (child.mandatory) {
                        _this.mandatoryPermissions[child.id] = true;
                        _this.disabledPermissions[child.id] = true;
                        _this.selectedValues[child.id] = true;
                    }
                    _this.childToParentMap[child.id] = action;
                });
        });
    };
    UserRolePermissionsComponent.prototype.updateActions = function (action) {
        var _this = this;
        this.disableRequiredPermissions(action);
        if (action.children) {
            action.children.forEach(function (child) { return _this.disableRequiredPermissions(child); });
        }
        this.enableNotRequiredPermissions();
        this.disableLastCheckedChildForMandatoryParent(action);
        this.updateParentsStatuses();
    };
    // Verify that required permissions are disabled.
    UserRolePermissionsComponent.prototype.disableRequiredPermissions = function (action) {
        if (this.selectedValues[action.id]) {
            var additionalRequiredPermissions = action.additionalImplicitAllowedActions;
            for (var i in MdeUserRoleActionEnum) {
                if (Number(i) === MdeUserRoleActionEnum.admin) {
                    continue;
                }
                if ((additionalRequiredPermissions & Number(i)) !== 0) {
                    this.selectedValues[Number(i)] = true;
                    this.disabledPermissions[Number(i)] = true;
                }
            }
        }
    };
    // Verify that not required permissions are enabled.
    UserRolePermissionsComponent.prototype.enableNotRequiredPermissions = function () {
        var _this = this;
        this.relevantActions
            .filter(function (action) { return _this.disabledPermissions[action.id]; })
            .forEach(function (disabledPermission) {
            var isRequired = _this.relevantActions
                .filter(function (action) { return _this.selectedValues[action.id]; })
                .some(function (selectedPermission) {
                var additionalRequiredPermissions = selectedPermission.additionalImplicitAllowedActions;
                return (disabledPermission.id & additionalRequiredPermissions) !== 0;
            });
            if (!isRequired && !_this.mandatoryPermissions[disabledPermission.id]) {
                _this.disabledPermissions[disabledPermission.id] = false;
            }
        });
    };
    // Verify that mandatory parent has at least one checked child.
    UserRolePermissionsComponent.prototype.disableLastCheckedChildForMandatoryParent = function (action) {
        var _this = this;
        var parent = this.childToParentMap[action.id];
        if (parent && parent.mandatory) {
            var checkedChild_1 = parent.children.find(function (child) { return _this.selectedValues[child.id]; });
            var onlyOneChecked = parent.children
                .filter(function (child) { return child.id !== checkedChild_1.id; })
                .every(function (child) { return !_this.selectedValues[child.id]; });
            if (onlyOneChecked) {
                this.disabledPermissions[checkedChild_1.id] = true;
            }
        }
    };
    // Verify that if parent is enabled - all childs are enabled.
    UserRolePermissionsComponent.prototype.updateChildrenStatuses = function () {
        var _this = this;
        this.allowedActionsModel.forEach(function (action) {
            if (action.children && _this.selectedValues[action.id]) {
                action.children.forEach(function (child) { return (_this.selectedValues[child.id] = true); });
            }
        });
    };
    // Verify that parent is disabled only if all childs are disabled.
    // Verify that parent is selected if all childs are selected.
    // Verify that parent is partiallySelected if at least one child is selected.
    UserRolePermissionsComponent.prototype.updateParentsStatuses = function () {
        var _this = this;
        this.allowedActionsModel
            .filter(function (action) { return action.children; })
            .forEach(function (parent) {
            var allSelected = parent.children.every(function (child) { return _this.selectedValues[child.id]; });
            var noneSelected = parent.children.every(function (child) { return !_this.selectedValues[child.id]; });
            var allDisabled = parent.children.every(function (child) { return _this.disabledPermissions[child.id]; });
            _this.disabledPermissions[parent.id] = allDisabled;
            if (noneSelected) {
                _this.selectedValues[parent.id] = false;
                _this.partiallySelectedCategories[parent.id] = false;
            }
            if (allSelected) {
                _this.selectedValues[parent.id] = true;
                _this.partiallySelectedCategories[parent.id] = false;
                if (_this.mandatoryPermissions[parent.id]) {
                    _this.disabledPermissions[parent.id] = true;
                }
            }
            if (!allSelected && !noneSelected) {
                _this.selectedValues[parent.id] = false;
                _this.partiallySelectedCategories[parent.id] = true;
                if (!_this.mandatoryPermissions[parent.id]) {
                    _this.disabledPermissions[parent.id] = false;
                }
            }
        });
    };
    return UserRolePermissionsComponent;
}());
export { UserRolePermissionsComponent };
