/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "./confirm-modal-content.component";
var styles_ConfirmModalContentComponent = ["[_nghost-%COMP%] {\n\t\t\t\tdisplay: flex;\n\t\t\t}"];
var RenderType_ConfirmModalContentComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ConfirmModalContentComponent, data: {} });
export { RenderType_ConfirmModalContentComponent as RenderType_ConfirmModalContentComponent };
function View_ConfirmModalContentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fab-spinner", [["className", "wcd-margin-xsmall-right"]], null, null, null, i1.View_FabSpinnerComponent_0, i1.RenderType_FabSpinnerComponent)), i0.ɵdid(1, 5816320, null, 0, i2.FabSpinnerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { size: [0, "size"], className: [1, "className"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SpinnerSize.xSmall; var currVal_1 = "wcd-margin-xsmall-right"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ConfirmModalContentComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 27, "div", [["class", "wcd-flex-1 wcd-flex-vertical ie11Patch ie11Flex"], ["data-track-component", "ConfirmModalContent"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "main", [["class", "dialog-contents wcd-flex-1 ie11Patch ie11Flex wcd-scroll-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), i0.ɵncd(null, 0), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i0.ɵeld(9, 0, null, null, 19, "footer", [["class", "dialog-footer wcd-flex-none wcd-flex-justify-end-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(11, 0, null, null, 10, "fab-primary-button", [["className", "wcd-margin-small-right"], ["data-track-id", "Confirm"], ["data-track-type", "Button"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onConfirm.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabPrimaryButtonComponent_0, i1.RenderType_FabPrimaryButtonComponent)), i0.ɵdid(12, 6012928, null, 1, i2.FabPrimaryButtonComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { disabled: [0, "disabled"], className: [1, "className"] }, { onClick: "onClick" }), i0.ɵqud(603979776, 1, { menuItemsDirectives: 1 }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ConfirmModalContentComponent_1)), i0.ɵdid(16, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(18, 0, null, 0, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(19, null, ["", ""])), i0.ɵppd(20, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t\t"])), (_l()(), i0.ɵeld(23, 0, null, null, 4, "fab-default-button", [["data-track-id", "Cancel"], ["data-track-type", "Button"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onCancel.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabDefaultButtonComponent_0, i1.RenderType_FabDefaultButtonComponent)), i0.ɵdid(24, 6012928, null, 1, i2.FabDefaultButtonComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { disabled: [0, "disabled"], text: [1, "text"] }, { onClick: "onClick" }), i0.ɵqud(603979776, 2, { menuItemsDirectives: 1 }), i0.ɵppd(26, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.confirmButtonOptions == null) ? null : _co.confirmButtonOptions.disabled); var currVal_1 = "wcd-margin-small-right"; _ck(_v, 12, 0, currVal_0, currVal_1); var currVal_2 = _co.isSaving; _ck(_v, 16, 0, currVal_2); var currVal_4 = ((_co.cancelButtonOptions == null) ? null : _co.cancelButtonOptions.disabled); var currVal_5 = (((_co.cancelButtonOptions == null) ? null : _co.cancelButtonOptions.text) || i0.ɵunv(_v, 24, 1, _ck(_v, 26, 0, i0.ɵnov(_v, 0), "buttons.cancel"))); _ck(_v, 24, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = (((_co.confirmButtonOptions == null) ? null : _co.confirmButtonOptions.text) || i0.ɵunv(_v, 19, 0, _ck(_v, 20, 0, i0.ɵnov(_v, 0), "buttons.confirm"))); _ck(_v, 19, 0, currVal_3); }); }
export function View_ConfirmModalContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "confirm-modal-content", [], null, null, null, View_ConfirmModalContentComponent_0, RenderType_ConfirmModalContentComponent)), i0.ɵdid(1, 49152, null, 0, i6.ConfirmModalContentComponent, [], null, null)], null, null); }
var ConfirmModalContentComponentNgFactory = i0.ɵccf("confirm-modal-content", i6.ConfirmModalContentComponent, View_ConfirmModalContentComponent_Host_0, { confirmButtonOptions: "confirmButtonOptions", cancelButtonOptions: "cancelButtonOptions", isSaving: "isSaving" }, { onConfirm: "onConfirm", onCancel: "onCancel" }, ["*"]);
export { ConfirmModalContentComponentNgFactory as ConfirmModalContentComponentNgFactory };
