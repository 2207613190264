
		<div class="external-image">
			<div *ngIf="isImageAvailable">
				<img class="screenshot" [src]="src | safeUrl" (error)="onImageError()" />
			</div>
			<div class="alert alert-danger" *ngIf="!isImageAvailable">
				<wcd-shared-icon [iconName]="'error'" class="large-icon pull-left"> </wcd-shared-icon>
				<div class="with-large-icon">
					<div>{{ 'help.externalLoadError' | i18n: { asset: imageAsset } }}</div>
					<span [title]="src">{{ src | truncate: 50 }}</span>
				</div>
			</div>
		</div>
	