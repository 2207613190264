import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { notificationFormatValues } from './notification-rule-format.values';

@Entity({
	singularName: 'Notification Format',
	pluralName: 'Notification Formats',
	values: notificationFormatValues,
})
export class NotificationFormat extends EntityModelBase<number> {
	@EntityField() name: string;

	@EntityField({ defaultValue: false })
	defaultValue: boolean;

	@EntityField({ defaultValue: false })
	disabled: boolean;

	@EntityField({ defaultValue: false })
	hidden: boolean;

	@EntityField({ defaultValue: null })
	helpKey: string;
}
