import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
	MdeAllowedActionsGuard,
	AppConfigValueGuard,
	AuthenticatedGuard,
	EvaluationEnabledGuard,
	ExposedToAllMachineGroupsGuard,
	FeatureEnabledGuard,
	IsLiveResponseEnabled,
	NotOnboardGuard,
	OnboardGuard,
} from '@wcd/auth';
import { BreadcrumbsResolver } from './breadcrumbs/services/breadcrumbs-resolver.service';
import { EnableAutomatedIrComponent, Feature } from '@wcd/config';
import { HybridRoutingService } from './hybrid-routing.service';
import {
	DEPRECATED_HUNTING_ROUTE,
	DEPRECATED_HUNTING_ROUTE2,
	HUNTING_ROUTE,
	LogicalOperator,
} from '@wcd/shared';
import { MdeUserRoleActionEnum, FilterImpactedAssets } from '@wcd/domain';
import { FlagsComponent } from './flags/components/flags.component';
import { HistorySafeRedirectComponent } from './shared/components/history-safe-redirect.component';

export const FLAGS_URL = 'flags2';

export const appRoutes: Routes = [
	{
		path: '',
		resolve: {
			breadcrumbs: BreadcrumbsResolver,
		},
		children: [
			{
				path: '',
				component: HistorySafeRedirectComponent,
				data: {
					redirectTo: 'dashboards_junction',
				},
				pathMatch: 'full',
			},
			{
				path: 'dashboards_junction',
				children: [],
				data: {
					MdeAllowedActionsGuardConfig: {
						FallbackOnUnauthorized: '/tvm_dashboard',
						NavigateToOnAuthorized: '/dashboard',
						AllowedActions: MdeUserRoleActionEnum.viewData,
						AbsolutePath: true,
					},
				},
				canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
			},
			{
				path: 'enable_airs',
				component: EnableAutomatedIrComponent,
				data: {
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
				canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
			},
			{
				// This is a dummy route, it should not be used!
				// It is defined only to force localhost Angular compiler to detect the SccSettingsModule lazy module as local build goes through non scc module tree
				path: 'settings_scc_mtp',
				loadChildren: 'app/scc-settings/scc-settings.module#SccSettingsModule',
			},
			{
				// This is a dummy route, it should not be used!
				// It is defined only to force localhost Angular compiler to detect the scconboarding lazy module as local build goes through non scc module tree
				path: 'enable_scc_mtp',
				loadChildren:
					'app/scc-onboarding-package/scc-onboarding-package.module#SccOnboardingPackageModule',
			},
			{
				path: 'investigation',
				loadChildren:
					'app/graph/airs-investigation/investigation-graph.module#InvestigationGraphModule',
				data: {
					loadingComponentConfig: {
						entityTypeNameKey: 'investigation_entityType_singularName',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
				canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
			},
			{
				path: 'mtp-investigation',
				loadChildren:
					'app/graph/mtp-investigation/mtp-investigation-routing.module#MtpInvestigationRoutingModule',
				data: {
					loadingComponentConfig: {
						entityTypeNameKey: 'investigation_entityType_singularName',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
				canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
			},
			{
				path: 'live-response',
				loadChildren: 'app/@entities/live_response/live-response.module#LiveResponseModule',
				canActivate: [FeatureEnabledGuard, AppConfigValueGuard, MdeAllowedActionsGuard],
				data: {
					features: [Feature.LiveResponse],
					appConfigValidator: IsLiveResponseEnabled,
					loadingComponentConfig: {
						descriptionKey: 'loading_liveResponse',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
			},
			{
				path: 'incidents',
				loadChildren: 'app/@entities/incidents/incidents.module#IncidentsModule',
				canActivate: [FeatureEnabledGuard, MdeAllowedActionsGuard],
				data: {
					features: [Feature.Incidents],
					loadingComponentConfig: {
						entityTypeNameKey: 'incident_entityType_pluralName',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
			},
			{
				path: 'web-threat-domains',
				loadChildren: 'app/@entities/domains/domains.module#DomainsModule',
				canActivate: [FeatureEnabledGuard, MdeAllowedActionsGuard],
				data: {
					features: [Feature.WebThreatProtectionReport],
					loadingComponentConfig: {
						descriptionKey: 'loading_web_threat_domains',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
			},
			{
				path: 'baseline-compliance',
				canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				loadChildren:
					'app/@entities/@tvm/baseline-compliance/baseline-compliance.module#BaselineComplianceModule',
				data: {
					features: {
						enableFeatures: [Feature.TvmPages, Feature.TvmBaselineCompliance],
						logicalOperator: LogicalOperator.And,
					},
					loadingComponentConfig: {
						descriptionKey: 'tvm_baseline_loading',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.tvmViewData,
					},
				},
			},
			{
				path: 'baseline-compliance_t',
				canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				loadChildren:
					'app/@entities/@tvm/baseline-compliance/baseline-compliance.module#BaselineComplianceModule',
				data: {
					features: {
						enableFeatures: [Feature.TvmPages, Feature.TvmBaselineCompliance],
						logicalOperator: LogicalOperator.And,
					},
					loadingComponentConfig: {
						descriptionKey: 'tvm_baseline_loading',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.tvmViewData,
					},
				},
			},
			{
				path: 'tvm-event-insights',
				canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				loadChildren: 'app/@entities/@tvm/events/change-event.module#ChangeEventModule',
				data: {
					features: {
						enableFeatures: [Feature.TvmPages],
					},
					loadingComponentConfig: {
						descriptionKey: 'loading_events',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.tvmViewData,
					},
				},
			},
			{
				path: 'compare-kit',
				canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				loadChildren:
					'app/@entities/@tvm/comparison-tool/comparison-tool.module#ComparisonToolModule',
				data: {
					features: {
						enableFeatures: [Feature.TvmPages, Feature.TvmComparisonKit],
						logicalOperator: LogicalOperator.And,
					},
					loadingComponentConfig: {
						descriptionKey: 'loading_compare_kit',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.tvmViewData,
					},
				},
			},
			{
				path: 'vulnerabilities',
				canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				loadChildren:
					'app/@entities/@tvm/vulnerabilities/vulnerabilities.module#VulnerabilitiesModule',
				data: {
					features: [Feature.TvmPages],
					loadingComponentConfig: {
						entityTypeNameKey: 'vulnerability_entityType_pluralName',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.tvmViewData,
					},
					queryParams: {
						filters: `numOfImpactedAssets=${FilterImpactedAssets.hasEffect}`,
					},
				},
			},
			{
				path: 'software-inventory',
				canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				loadChildren:
					'app/@entities/@tvm/software-inventory/software-inventory.module#SoftwareInventoryModule',
				data: {
					features: [Feature.TvmPages],
					loadingComponentConfig: {
						descriptionKey: 'loading_software_inventory',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.tvmViewData,
					},
				},
			},
			{
				path: 'security-recommendations',
				canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				loadChildren:
					'app/@entities/@tvm/security-recommendations/security-recommendations.module#SecurityRecommendationsModule',
				data: {
					features: [Feature.TvmPages],
					loadingComponentConfig: {
						entityTypeNameKey: 'securityRecommendation_entityType_pluralName',
						transparent: true,
					},
					queryParams: {
						filters: 'status=Active',
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.tvmViewData,
					},
				},
			},
			{
				path: 'remediation',
				canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				loadChildren: 'app/@entities/@tvm/remediation/remediation.module#TvmRemediationModule',
				data: {
					features: [Feature.TvmPages],
					loadingComponentConfig: {
						descriptionKey: 'loading_remediation_page',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.tvmViewData,
					},
				},
			},
			{
				path: 'action-center',
				canActivate: [FeatureEnabledGuard, MdeAllowedActionsGuard],
				loadChildren: 'app/@entities/action_center/action-center.module#ActionCenterModule',
				data: {
					features: [Feature.ActionHistory],
					loadingComponentConfig: {
						descriptionKey: 'loading_action_center',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
			},
			{
				path: 'machines',
				loadChildren: 'app/@entities/machines/machines.module#MachinesModule',
				data: {
					loadingComponentConfig: {
						entityTypeNameKey: 'machine_entityType_pluralName',
						transparent: true,
					},
				},
			},
			{
				path: 'machine/:id',
				redirectTo: '/machines/:id/main',
				pathMatch: 'prefix',
			},
			{
				path: 'files',
				loadChildren: 'app/@entities/files/files.module#FilesModule',
				canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				data: {
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
			},
			{
				path: 'urls',
				loadChildren: 'app/@entities/urls/urls.module#UrlsModule',
				canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				data: {
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
			},
			{
				path: 'ips',
				loadChildren: 'app/@entities/ips/ips.module#IpsModule',
				canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				data: {
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
			},
			{
				path: 'users',
				loadChildren: 'app/@entities/users/users.module#UsersModule',
				canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				data: {
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
			},
			{
				path: HUNTING_ROUTE,
				loadChildren: 'app/hunting-package/hunting-package.module#HuntingPackageModule',
				canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				data: {
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData | MdeUserRoleActionEnum.tvmViewData,
					},
				},
			},
			{
				path: DEPRECATED_HUNTING_ROUTE,
				redirectTo: HUNTING_ROUTE,
				pathMatch: 'full',
			},
			{
				path: DEPRECATED_HUNTING_ROUTE2,
				redirectTo: HUNTING_ROUTE,
				pathMatch: 'full',
			},
			{
				path: 'custom_detection',
				loadChildren:
					'app/@entities/scheduled_hunting/scheduled-hunting-routing.module#ScheduledHuntingRoutingModule',
			},
			{
				path: 'scheduled_hunting',
				redirectTo: 'custom_detection',
				pathMatch: 'prefix',
			},
			{
				path: 'tutorials',
				loadChildren: 'app/tutorials/tutorials.module#TutorialsModule',
			},
			{
				path: 'threatanalytics2',
				redirectTo: 'threatanalytics3',
			},
			{
				path: 'threatanalytics3',
				canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard],
				data: {
					features: [Feature.ThreatAnalytics3],
				},
				loadChildren:
					'app/dashboards/threat-analytics3/threat-analytics.module#ThreatAnalyticsModule',
			},
			{
				path: 'evaluation',
				canActivate: [
					AuthenticatedGuard,
					OnboardGuard,
					ExposedToAllMachineGroupsGuard,
					EvaluationEnabledGuard,
					MdeAllowedActionsGuard,
				],
				data: {
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
				loadChildren: 'app/evaluation/evaluation.module#EvaluationModule',
			},
			{
				path: 'tenanthealth',
				canActivate: [AuthenticatedGuard, OnboardGuard],
				loadChildren: 'app/service-health/service-health.module#ServiceHealthModule',
			},
			{
				path: 'playbooks',
				loadChildren: 'app/@entities/playbooks/playbooks.module#PlaybooksModule',
				canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard, MdeAllowedActionsGuard],
				data: {
					pageTitle: 'Playbooks',
					features: [Feature.Playbooks],
					enableAirsRoute: [
						'/settings/enable_airs',
						{ messageClass: 'relative enable-auto-ir-investigation-message' },
					],
					loadingComponentConfig: {
						descriptionKey: 'loading_playbooks',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
			},
			{
				path: 'reports/webThreatProtection',
				loadChildren:
					'app/reporting/web-threat-protection/web-threat-protection-reporting.module#WebThreatProtectionReportingModule',
				canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard, MdeAllowedActionsGuard],
				data: {
					features: [Feature.WebThreatProtectionReport, Feature.WebContentFilteringPolicy],
					loadingComponentConfig: {
						descriptionKey: 'loading_web_protection_reports',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
				},
			},
			{
				path: 'reports',
				loadChildren: 'app/reporting/reporting.module#ReportingModule',
				canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard, MdeAllowedActionsGuard],
				data: {
					features: [Feature.Reports, Feature.ThreatReport],
					loadingComponentConfig: {
						descriptionKey: 'loading_report',
						transparent: true,
					},
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData | MdeUserRoleActionEnum.tvmViewData,
					},
				},
			},
			{
				path: 'interoperability',
				loadChildren: 'app/interoperability/interoperability.module#InteroperabilityModule',
				canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard],
				data: {
					features: [Feature.Partners],
					loadingComponentConfig: {
						descriptionKey: 'common_loading',
						transparent: true,
					},
				},
			},
			{
				path: 'onboarding2',
				canActivate: [AuthenticatedGuard, FeatureEnabledGuard, NotOnboardGuard],
				data: {
					features: [Feature.OnboardingWizardUpgrade],
				},
				loadChildren: 'app/onboarding-wizard/onboarding.wizard.module#OnboardingWizardModule',
			},
			{
				path: 'preferences2',
				canActivate: [AuthenticatedGuard, OnboardGuard, FeatureEnabledGuard],
				loadChildren: 'app/admin/admin.module#AdminModule',
			},
			{
				path: 'configuration-management',
				canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard],
				data: {
					features: [Feature.ConfigurationManagement],
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.configurationManagement,
					},
				},
				loadChildren:
					'app/configuration-management/configuration-management.module#ConfigurationManagementModule',
			},
			{
				path: 'web-content-filtering-report',
				canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard],
				loadChildren:
					'app/@entities/web_content_filtering/web-content-filtering-report.module#WebContentFilteringReportModule',
				data: {
					features: [Feature.WebContentFilteringPolicy],
					loadingComponentConfig: {
						descriptionKey: 'loading_category_reports',
						transparent: true,
					},
				},
			},
			{
				path: 'cms',
				canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
				data: {
					MdeAllowedActionsGuardConfig: {
						AllowedActions: MdeUserRoleActionEnum.viewData,
					},
					loadingComponentConfig: {
						descriptionKey: 'loading_cms',
						transparent: true,
					},
				},
				loadChildren: 'app/cms/cms.module#CmsModule',
			},
			{
				path: FLAGS_URL,
				component: FlagsComponent,
				canActivate: [AuthenticatedGuard, FeatureEnabledGuard],
				data: {
					pageTitle: 'Flags',
					features: [Feature.Flags],
				},
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	providers: [HybridRoutingService],
	exports: [RouterModule],
})
export class AppRoutingModule { }
