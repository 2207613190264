import { isEmpty } from 'lodash-es';
/* tslint:disable:template-click-events-have-key-events */
import {
	ChangeDetectorRef,
	Component,
	ComponentRef,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
	ViewEncapsulation,
} from '@angular/core';
import { DatasetBackendOptions, DataViewConfig, DataviewField, mergeDataViewConfig } from '@wcd/dataview';
import { SafeHtml } from '@angular/platform-browser';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { merge, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { IStore } from '../../data/models/store.interface';
import { PreferencesService, FeaturesService, AppContextService } from '@wcd/config';
import { Store } from '../../data/models/store.base';
import { DataTableColumnResizeEvent, DataTableComponent, DataTableFieldComponent } from '@wcd/datatable';
import { AVAILABLE_PAGE_SIZES, DataviewFieldId, DataViewModel } from '../models/dataview.model';
import { ExportDataSetModalComponent } from './export-dataset.modal';
import { DimensionsModel } from '../../dialogs/models/dimensions.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ItemActionModel, ItemActionValue } from '../models/item-action.model';
import { Panel, PanelService, PanelSettings } from '@wcd/panels';
import { DataviewRepositoryService } from '../services/dataview-repository.service';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { ErrorsDialogService } from '../../dialogs/services/errors-dialog.service';
import { EntityType } from '../../global_entities/models/entity-type.interface';
import {
	EntityPanelActionEvent,
	EntityPanelsService,
} from '../../global_entities/services/entity-panels.service';
import { clone, differenceBy, isEqualWith, isNil, omit } from 'lodash-es';
import { DataEntityType, DataSet, Paris, ReadonlyRepository } from '@microsoft/paris';
import { debounceTime, first, map, skip, startWith, take } from 'rxjs/operators';
import { FiltersComponent, FiltersFieldId, FiltersService, FiltersState } from '@wcd/ng-filters';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Focusable } from '@wcd/forms';
import { DataviewAction, DataviewActionTypes } from './actions-components/dataview-actions.model';
import { DataviewActionColumnsCustomizationConfig } from './actions-components/dataview-action-columns-customization.component';
import {
	DataviewActionFilterConfig,
	FILTERS_PANEL_SETTINGS,
} from './actions-components/dataview-action-filter.component';
import { DataviewActionPaginationConfig } from './actions-components/dataview-action-pagination.component';
import { DataviewActionSearchConfig } from './actions-components/dataview-action-search.component';
import { DataviewActionButtonConfig } from './actions-components/dataview-action-button.component';
import { DataviewActionFancySelectConfig } from './actions-components/dataview-action-fancy-select.component';
import { FabricIconNames } from '@wcd/scc-common';
import { HttpParams } from '@angular/common/http';
import { sccHostService } from '@wcd/scc-interface';
import { panelService as AngularWrapperPanelService, WicdSanitizerService } from '@wcd/shared';
import { isMachineExportResponse, MachineExportResponse } from "../../@entities/machines/services/machines.service";
import { ConfirmEvent } from "@wcd/dialogs";
import { ConfirmationService } from "../../dialogs/confirm/confirm.service";

const DEFAULT_EXPORT_FORMAT = 'csv';
const DEFAULT_SEARCH_PARAM_NAME = 'search';
const DEFAULT_UNIQUE_KEY = 'id';
const DEFAULT_PAGE_SIZE = 30;

let lastId = 0;
export interface DataChangedPayload<TData = any> {
	readonly data: DataSet<TData>;
	readonly dataOptions: DataViewComponent;
	readonly page: number;
}

@Component({
	selector: 'dataview',
	templateUrl: './dataview.component.html',
	styleUrls: ['./dataview.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DataViewComponent<
	TData extends { id?: TId } = any,
	TId extends string = any,
	TOptions extends DataViewConfig<TData> = DataViewConfig<TData>
> implements OnInit, OnDestroy {
	@Input() customActionsLeft: DataviewAction[] = [];
	@Input() customActionsRight: DataviewAction[] = [];
	@Input() allowAdd: boolean = false;
	/**
	 * Whether to have filters at all in the dataview
	 * @default true
	 * @type {boolean}
	 */
	@Input() allowFilters: boolean = true;

	/**
	 * If true, filters selected manually will be persisted to localStorage, and reloaded
	 * when the dataview is loaded without filters. Only relevant if allowFilters is also true.
	 */
	@Input() persistFilters: boolean = false;

	/**
	 * If true, page size selected manually will be persisted to user preferences, and reloaded
	 * when the dataview is loaded without page size..
	 */
	@Input() persistPageSize: boolean = true;

	/**
	 * Whether there are grouped items - items that can be clicked to retrieve nested items in the data table
	 * @default false
	 * @type {boolean}
	 */
	@Input() allowGroupItems: boolean = false;

	/**
	 * If allowGroupItems - allow group items parent to be selectable without auto selection of all the nested items
	 * @default false
	 * @type {boolean}
	 */
	@Input() allowParentSelectionWithoutSelectingNestedItems: boolean = false;

	/**
	 * Whether there's paging in the dataview. If set to false, there will be no paging controls and sorting will be done locally.
	 * @default true
	 * @type {boolean}
	 */
	@Input() allowPaging: boolean = true;
	@Input() nestedComponentType: DataTableFieldComponent<TData, any>;
	@Input() hasNestedContent: (item: TData) => boolean;
	@Input() className: string;
	@Input() commandBarClassName: string;
	@Input() customControls: Array<any> = [];
	@Input() customData: any;
	@Input() defaultSortFieldId: string;
	@Input() description: string;
	@Input() disabledFilters: Array<FiltersFieldId>;

	@Input()
	set disableSelection(value: boolean) {
		this.selectEnabled = !value;
	}

	@Input() measurePerformance = false;
	@Input() emptyDataMessage: string;
	@Input() emptyDataMarkdown: string;
	@Input() getGroupItems: (group: TData, options?: {}) => Promise<Array<TData>> | Observable<Array<TData>>;
	@Input() loadGroupItemsOnLoad: boolean = false;
	@Input() hideControls: boolean;
	@Input() showEmptyMessage: boolean = true;
	@Input() hideCommandBarOnEmptyData: boolean = false;
	@Input() navigateOnChange: boolean = true;
	@Input() highlightedItems: Array<TData>;
	@Input() permanentHighlightedItems: Array<TData>;
	@Input() selectAllItemsByDefault: boolean = false;
	@Input() selectOnItemClick: boolean = false;
	@Input() infiniteScrolling: boolean = false;
	@Input() forceGetDataFromApi: boolean = false;
	@Input() loadItemsOnTableTop: boolean = false;
	@Input() padLeft = true;
	// Remove command bar's horizontal padding.
	@Input() commandBarNoPadding = false;
	@Input() datatablePadTop = false;
	//toggle hide\display of Filters sidepane toggle button from command bar, by default the button is displayed
	@Input() hideFieldsFilter: boolean = false;
	//toggle hide\display of items limit selection dropdown from command bar, by default the button is displayed
	@Input() hideItemsLimitSelection: boolean = false;
	@Input() id: string;
	@Input() isItemClickable: (item: TData) => boolean;
	@Input() isItemGroup: (item: TData) => boolean;
	@Input() isSmallPadding = false;
	@Input() loadMoreOnEmpty = false;
	@Input() itemActions: Array<ItemActionModel>;
	@Input() itemSelectable: (item: TData) => boolean;
	@Input() searchEnabled: boolean;
	@Input() searchMinLength: number = 0;
	@Input() searchCommandTitle?: string;
	@Input() searchRequestText: string;
	@Input() loadingMessageText: string;
	@Input() stillLoadingMessageText: string;
	@Input() longLoadingExpected: boolean;
	@Input() exportTooltipText: string;
	@Input() setItemActions: (items: Array<TData>) => Array<ItemActionModel>;
	@Input() showHeader: boolean = true;
	@Input() hideHeaderOnEmptyData: boolean = false;
	@Input() withAddButton: boolean = false;
	@Input() localRefreshOn: any;
	@Input() allowMultipleSelection: boolean = true;
	@Input() itemUniqueKey: keyof TData = DEFAULT_UNIQUE_KEY;
	@Input() showDialogOnError: boolean = true;
	@Input() setFiltersOnDataRefresh: boolean = false;
	@Input() selectableFieldIds: Array<DataviewFieldId>;
	@Input() allowResize: boolean = true;
	@Input() hideTable: boolean = false;
	@Input() maxItems: number;
	@Input() focusOnTable: boolean = false;
	@Input() selectAllEnabled: boolean = false;
	@Input() entitySidePanelSettings: PanelSettings = {};
	// using false will be translated to null in the datatable which will eliminate the tabindex option
	@Input() tabIndex: 0 | -1 | false = -1;
	@Input() removePadding = false;
	@Input() removePaddingRight = false;
	@Input() removePaddingLeftOnResize = false;
	@Input() shouldShowPageNumbersOnPagination: boolean = false;
	@Input()
	set itemName(value: string) {
		this._itemName = value;
		this.evaluateEntityNames();
	}
	get itemName() {
		return this._itemName;
	}
	/**
	 * When displayed in a narrow area - hiding the 'Customize columns' button can free space for the search
	 * @default true
	 * @type {boolean}
	 */
	@Input() allowColumnCustomization: boolean = true;

	/**
	 * On narrow areas the order (from left) of search box, empty space and actions - can make the search box really short (1:1 ratio with the spacing)
	 * Setting the flag to true will increase the size of the search box in favor of the space in a 3:1 ratio
	 */
	@Input() giveSearchBoxMoreSpace: boolean = false;

	/**
	 * A filter which will be used to decide which items to be selected automatically: if the callback returns true, the item is added to the list of selected items, which controls auto opening of the side pane with such items.
	 */
	@Input() isItemSelected: (item: TData) => boolean;

	/**
	 * A filter which will be used to decide which groups are expanded on init.
	 */
	@Input() isGroupExpandedOnInit: (item: TData) => boolean = (item: TData) => false;

	/**
	 * Label to be announced by the narrator when reaching the table.
	 */
	@Input() label: string = '';

	//======== m365 designed error message with reload button (currently adopted bpany TVM)
	@Input() m365DataError: boolean = false;
	//========

	@Input() disableAddButton: boolean = false;
	/**
	 * Disable tooltip hover text for the dataview add button, by default would show generic text - "Add new {entity name}"
	 */
	@Input() disableAddTooltip: boolean = false;
	@Input() disabledAddTooltip: string;

	/**
	 * Disable panel auto focus when it's not needed, like in search-results page - search results should be focused.
	 */
	@Input() disableFilterPanelAutoFocus: boolean;

	/**
	 * Make the width of the table fixed, no matter the content size. overflow items will be hidden with ellipsis.
	 * Important: When set to true, allowResize option will be set to false (resize cannot work normally when fixedTable set to true)
	 */
	private _fixedTable: boolean = false;
	@Input()
	set fixedTable(fixedTable: boolean) {
		this._fixedTable = fixedTable;
		if (fixedTable) {
			this.allowResize = !fixedTable;
		}
	}
	get fixedTable(): boolean {
		return this._fixedTable;
	}

	/**
	 * changes the order of the command bar buttons when in asset page
	 */
	@Input() assetCommandBar: boolean = false;
	@Input() queueHeader: boolean = false;
	@Input() shouldShowEntityPanel: boolean = true;

	@Input() focusOnFirstMenuItem: boolean = false;
	//responsiveLayout indicates wither to apply responsive design to the component.
	// Set this to true when the dataview is used as the page main content (e.g. queue pages..)
	@Input() responsiveLayout: boolean = false;

	@Input() responsiveActionBar: boolean = false;
	@Input() defaultQueryFilters: Record<string, any>;
	@Input() fullHeight = true;
	@Input() focusOnFirstElement: boolean = false;

	@Output() onAction: EventEmitter<{ data: any }> = new EventEmitter<{ data: any }>();
	@Output() readonly onData = new EventEmitter<DataChangedPayload<TData>>();
	@Output() readonly onItemsAddedOnTop = new EventEmitter<DataSet<TData>>();
	@Output()
	onItemClick: EventEmitter<DataViewClickEvent<TData>> = new EventEmitter<DataViewClickEvent<TData>>();
	@Output() onNewItem: EventEmitter<void> = new EventEmitter<void>();
	@Output()
	select: EventEmitter<DataViewSelectEvent<TData>> = new EventEmitter<DataViewSelectEvent<TData>>();
	@Output()
	groupExpand: EventEmitter<{ group: TData; children: Array<TData> }> = new EventEmitter<{
		group: TData;
		children: Array<TData>;
	}>();
	@Output() dataLoadError: EventEmitter<Error> = new EventEmitter<Error>();
	@Output() filtersChange: EventEmitter<FiltersState> = new EventEmitter<FiltersState>();

	@Output() afterTableInit: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onSortChanged: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
	@Output() onTableRenderComplete: EventEmitter<void> = new EventEmitter<void>();
	@ViewChild('filterButton', { static: false }) filterButton: ElementRef;

	/**
	 * Returns all the items and grouped items in the data table
	 */
	get allItems(): Array<TData> {
		let groupedItems: Array<TData> = [];
		if (this.groupItems)
			this.groupItems.forEach((items: Array<TData>) => (groupedItems = groupedItems.concat(items)));

		return this.dataSet.items.concat(groupedItems);
	}

	boundIsItemSelectable: (item: TData) => boolean;
	dataViewModel: DataViewModel;
	dataSet: DataSet<TData>;
	error: any;
	showFilters: boolean = false;
	pageSizes = AVAILABLE_PAGE_SIZES;
	descriptionHtml: SafeHtml;
	searchTerm: string;
	selectEnabled: boolean = true;
	pageSize: number;
	selectedItems: Array<TData> = [];
	allItemsSelected: boolean = false;
	allPageItemsSelected: boolean = false;
	runningAction: boolean = false;
	itemNamePluralLowercase: string;
	itemNameSingular: string;
	itemNameLowercase: string;
	private _visibleFieldIds$: ReplaySubject<Array<DataviewFieldId>> = new ReplaySubject<
		Array<DataviewFieldId>
	>(1);
	visibleFieldIds$: Observable<Array<DataviewFieldId>> = this._visibleFieldIds$.asObservable();
	filtersPanelSettings = FILTERS_PANEL_SETTINGS;
	modalsWidth: number = 0;
	dataTableGetGroupedItems: (
		group: TData
	) => Array<TData> | Promise<Array<TData>> | Observable<Array<TData>>;
	columnWidths: Record<string, number>;

	commandBarLeft: DataviewAction[] = [];
	commandBarRight: DataviewAction[] = [];

	private _lockRefresh: boolean;
	private _setDataViewModelTimeout;
	private _isInit: boolean = false;
	private _locationChangeSubscription: Subscription;
	private _storeItemsSubscription: Subscription;
	private _onStoreChanges: Subscription;
	private _filterValues$: Subject<FiltersState> = new Subject<FiltersState>();
	private _reloadOnEntityPanelClose: boolean = false;
	private _onDataSubscription: Subscription;
	private _onErrorSubscription: Subscription;
	private _onVisibleFieldsChangeSubscription: Subscription;
	private _onSearchChangeSubscription: Subscription;
	private _onSettingsChangeSubscription: Subscription;
	private _visibleFieldsSubscription: Subscription;
	private _latestQueryParams: Params;
	private _queryParamsSubscription: Subscription;
	private _onChangeDisplayedItems: Subscription;
	private _onCloseEntityPanelSubscription: Subscription;
	private _onEntityTypeActionSubscription: Subscription;
	private _isLoadingSubscription: Subscription;
	private _dataViewOptionsToSet: DatasetBackendOptions;
	private _loadNextResultsUrl: string;
	private _loadPreviousResultsUrl: string;
	private _shouldKeepNextResultsUrl: boolean;
	private _shouldKeepPreviousResultsUrl: boolean;
	private _itemName: string;
	focusOnFirstCell: boolean = false;
	getBoundPageSizeLabel: (value: any) => string;
	private lastSearched: string;
	private isTableLoading: boolean = false;
	private groupItems: Map<TData, Array<TData>>;
	private savedUserPreferences: DataViewUserPreferences;
	private isTableExporting: boolean = false;

	readonly SearchSafePadding = 2;
	disableReset: boolean = false;

	showFilterButtonId = `dataview-show-filters-${lastId++}`;
	isScc = sccHostService.isSCC;

	@ViewChild(DataTableComponent, { static: false }) private _dataTableComponent: DataTableComponent;
	@ViewChild(FiltersComponent, { static: false }) private _filtersComponent: FiltersComponent;
	@ViewChildren('focusable') focusable: QueryList<ElementRef | Focusable>;

	constructor(
		private sanitizer: WicdSanitizerService,
		private dialogsService: DialogsService,
		private panelsService: PanelService,
		private preferencesService: PreferencesService,
		private route: ActivatedRoute,
		private dataviewRepositoryService: DataviewRepositoryService,
		private router: Router,
		private changeDetectionRef: ChangeDetectorRef,
		private appInsightsService: AppInsightsService,
		private errorsDialogService: ErrorsDialogService,
		private entityPanelsService: EntityPanelsService,
		private featuresService: FeaturesService,
		private appContext: AppContextService,
		private paris: Paris,
		private liveAnnouncer: LiveAnnouncer,
		private readonly confirmationService: ConfirmationService,
		public i18nService: I18nService
	) {
		this._queryParamsSubscription = route.queryParams
			// adding debounce since "hideTable" update is asynced, and we should make this flow asynced as well
			// (fix for bug https://dev.azure.com/microsoft/OS/_workitems/edit/23724353)
			// decreasing the debounce time from 50 to 1, because when it was defined as 50, there was problem with filters for dataviews with default filter (which is not 'any'):
			// selectedValues function in filters-field component was called twice: once with the right value
			// and second time with undefined
			// (fix for bug https://microsoft.visualstudio.com/OS/_workitems/edit/24096503)
			.pipe(debounceTime(1))
			.subscribe((params: Params) => {
				if (!this.hideTable) {
					this.setDataViewOptionsFromLocation(params);
					this.setUserPreferences();
				}
			});

		this.filtersPanelSettings.headerText = this.i18nService.strings.dataview_filter_panel_header_text;
		this.getBoundPageSizeLabel = this.getPageSizeLabel.bind(this);
	}

	private _ignoreQueryParams = false;

	get ignoreQueryParams() {
		return this._ignoreQueryParams;
	}

	/**
	 * When using multiple dataviews on the same url - they start to cause problems to each other
	 * fixing it by ignoring the query params for additional dataviews (except the main on the url)
	 * @default false
	 * @type {boolean}
	 */
	@Input()
	set ignoreQueryParams(value: boolean) {
		if (value === true) {
			if (this._queryParamsSubscription) this._queryParamsSubscription.unsubscribe();
			this._latestQueryParams = null;
		}
		this._ignoreQueryParams = value;
	}

	private _refreshOn: any;

	get refreshOn(): any {
		return this._refreshOn;
	}

	@Input('refreshOn')
	set refreshOn(value) {
		if (value instanceof Function) return;

		if (value && (!this._refreshOn || this._refreshOn !== value)) this.refreshData();
	}

	private _dataViewConfig: DataViewConfig<TData>;

	get dataViewConfig(): DataViewConfig<TData> {
		if (!this._dataViewConfig && !this._options) return null;

		return mergeDataViewConfig(this._dataViewConfig, this._options);
	}

	@Input('dataViewConfig')
	set dataViewConfig(value: DataViewConfig<TData>) {
		this._dataViewConfig = value;
		if (value) {
			this._dataViewConfig.searchParamName = value.searchParamName || DEFAULT_SEARCH_PARAM_NAME;
		}
		if (this._isInit) this.setDataViewModel();
	}

	private _itemNamePlural: string;

	get itemNamePlural(): string {
		return this._itemNamePlural;
	}

	@Input('itemNamePlural')
	set itemNamePlural(value: string) {
		this._itemNamePlural = value;
		this.evaluateEntityNames();
		if (this._isInit) this.setDataViewModel();
	}

	private _fields: Array<DataviewField<TData>>;

	get fields(): Array<DataviewField<TData>> {
		return this._fields;
	}

	@Input()
	set fields(value: Array<DataviewField<TData>>) {
		this._fields = value;
		if (this._isInit) this.setDataViewModel();
	}

	private _options: TOptions;

	get options(): TOptions {
		return this._options;
	}

	@Input('options')
	set options(value) {
		this._options = value;
		if (this._isInit) this.setDataViewModel();
	}

	private _store: IStore;

	get store(): IStore {
		return this._store;
	}

	@Input()
	set store(value: IStore) {
		if (value === this._store) return;

		if (this._onStoreChanges) this._onStoreChanges.unsubscribe();

		this._store = value;

		this.evaluateEntityNames();
		this.itemActions = (this.itemActions || []).concat(
			(this.store.options.getDataviewItemActions && this.store.options.getDataviewItemActions()) || []
		);
		this.setItemActions = this.store.getItemsActions.bind(this.store);
		this.searchEnabled =
			this.store.options.dataViewOptions && this.store.options.dataViewOptions.searchEnabled;
		this.itemSelectable =
			(this.store.options.dataViewOptions && this.store.options.dataViewOptions.isItemSelectable) ||
			this.itemSelectable;
		const storeFields: Array<DataviewField<TData>> =
			this.store.options.dataViewOptions && this.store.options.dataViewOptions.fields;

		if (storeFields) this._fields = storeFields;

		if (this._isInit) this.setDataViewModel();

		if (this._store instanceof Store)
			this._onStoreChanges = this._store.changes$.subscribe(() => this.refreshData());
	}

	private _repository: ReadonlyRepository<TData>;

	get repository(): ReadonlyRepository<TData> {
		return this._repository;
	}

	@Input()
	set repository(repository: ReadonlyRepository<TData>) {
		if (repository === this._repository) return;

		this._repository = repository;

		this.evaluateEntityNames();

		if (this._isInit) this.setDataViewModel();
	}

	private _entityType: EntityType;

	get entityType(): EntityType {
		return this._entityType;
	}

	@Input()
	set entityType(entityType: EntityType) {
		if (this._entityType === entityType) return;
		this._entityType = entityType;
		this.evaluateEntityNames();
		this._onChangeDisplayedItems && this._onChangeDisplayedItems.unsubscribe();

		this._onChangeDisplayedItems = this.entityPanelsService
			.getCurrentlyDisplayedItems(<DataEntityType<TData, any, TId>>entityType.entity)
			.subscribe((items) => {
				this.highlightedItems = items;
				this.changeDetectionRef.markForCheck();
			});

		this._onCloseEntityPanelSubscription = this.entityPanelsService.onCloseEntityPanel.subscribe(
			(entity: DataEntityType) => {
				//it's possible a panel was closed for a different entity, which is irrelevant here
				if (entity !== entityType.entity || AngularWrapperPanelService.hasOpenPanels()) return;

				this.unSelectAllItems();

				if (this._reloadOnEntityPanelClose) {
					this.refreshData();
					this._reloadOnEntityPanelClose = false;
				}
			}
		);
		this._onEntityTypeActionSubscription = this.entityPanelsService.onAction.subscribe(
			($event: EntityPanelActionEvent) => {
				if ($event.entityType === this.entityType) {
					if ($event.action.refreshOnResolve) this._reloadOnEntityPanelClose = true;
					else if ($event.action.localRefreshOnResolve) this.changeDetectionRef.markForCheck();
				}
			}
		);
	}

	private getExportActionConfig(): DataviewActionButtonConfig {
		return {
			localizedTooltip:
				this.exportTooltipText ||
				this.i18nService.get('dataview_export_to_csv', {
					entityName: this.itemNamePluralLowercase,
				}),
			onClickCallback: this.exportData.bind(this),
			icon: FabricIconNames.Download,
			localizedLabel: this.i18nService.get('dataview_export'),
			isBusyFn: () => this.isTableExporting,
			localizedBusyLabel: this.i18nService.get('dataview_exporting'),
			disabled: false,
			dataTrackId: 'export_' + this.dataViewModel.id,
			dataTrackType: 'Export',
			actionType: DataviewActionTypes.Button,
		};
	}

	private getAddActionConfig(): DataviewActionButtonConfig {
		return {
			localizedTooltip: this.disableAddTooltip
				? this.disabledAddTooltip
				: this.i18nService.get('dataview_add_new_item', {
						itemType: this.itemNameLowercase,
				  }),
			onClickCallback: () => this.onNewItem.emit(),
			icon: FabricIconNames.Add,
			localizedLabel: this.i18nService.get('dataview_add_item', {
				itemType: this.itemNameLowercase,
			}),
			disabled: this.disableAddButton,
			elementId: this.itemName + '-add-btn',
			dataTrackId: 'addNew_' + this.dataViewModel.id,
			actionType: DataviewActionTypes.Button,
		};
	}

	private getColumnsCustomizeActionConfig(): DataviewActionColumnsCustomizationConfig {
		return {
			selectableFieldIds: this.selectableFieldIds,
			onColumnsChange: this.onColumnsChange.bind(this),
			resetVisibleFields: this.resetVisibleFields.bind(this),
			actionType: DataviewActionTypes.ColumnsCustomization,
			visibleFields: this.visibleFieldIds$,
		};
	}

	private getFilterActionConfig(): DataviewActionFilterConfig {
		return {
			showText: true,
			disableFilterPanelAutoFocus: this.disableFilterPanelAutoFocus,
			datatablePadTop: this.datatablePadTop,
			onFiltersChangedCallback: this.onFiltersChanged.bind(this),
			dataviewId: this.showFilterButtonId,
			actionType: DataviewActionTypes.Filter,
			defaultQueryFilters: this.defaultQueryFilters,
		};
	}

	private getPageSizeCustomizationActionConfig(): DataviewActionFancySelectConfig {
		return {
			onSelectionChanged: this.onPageSizeChange.bind(this),
			currentSelection: this.dataViewModel.pageSize$,
			selections: this.pageSizes,
			icon: FabricIconNames.PageList,
			formatLabel: this.getBoundPageSizeLabel,
			ariaLabel: this.i18nService.get('dataview.itemsPerPage'),
			dataTrackId: 'dataview-pagesize',
			actionType: DataviewActionTypes.FancySelect,
		};
	}

	private getPaginationActionConfig(): DataviewActionPaginationConfig {
		return {
			onPageNumberChange: this.onPageNumberChange.bind(this),
			actionType: DataviewActionTypes.Pagination,
		};
	}

	private getSearchActionConfig(): DataviewActionSearchConfig {
		return {
			id: this.id,
			giveSearchBoxMoreSpace: this.giveSearchBoxMoreSpace,
			searchMinLength: this.searchMinLength,
			searchCallback: (): void => {
				this.focusOnTable = true;
			},
			entityTypeName: this.itemNamePluralLowercase,
			actionType: DataviewActionTypes.Search,
			allowMultipleWords: this.dataViewConfig && this.dataViewConfig.allowSearchMultipleWords,
			searchLocalized: this.searchCommandTitle || this.i18nService.strings.dataview_search,
		};
	}

	private buildLeftCommandBar() {
		let leftCommands: DataviewAction[] = [];
		if (this.assetCommandBar) {
			if (this.dataViewModel.exportEnabled) {
				leftCommands.push(this.getExportActionConfig());
			}
			if (this.searchEnabled) {
				leftCommands.push(this.getSearchActionConfig());
			}
		}
		leftCommands = leftCommands.concat(this.customActionsLeft);
		if (this.allowAdd) {
			leftCommands.push(this.getAddActionConfig());
		}
		if (!this.assetCommandBar && this.searchEnabled) {
			leftCommands.push(this.getSearchActionConfig());
		}

		return leftCommands;
	}

	private buildRightCommandBar() {
		let rightCommands: DataviewAction[] = [];
		if (!this.infiniteScrolling && this.dataViewModel.allowPaging) {
			rightCommands.push(this.getPaginationActionConfig());
		}
		rightCommands = rightCommands.concat(this.customActionsRight);
		this.customControls.forEach((control) => {
			rightCommands.push(<DataviewActionButtonConfig>{
				localizedTooltip: control.tooltip,
				onClickCallback: control.method,
				icon: control.icon,
			});
		});
		if (this.dataViewModel && this.allowColumnCustomization) {
			rightCommands.push(this.getColumnsCustomizeActionConfig());
		}
		if (!this.assetCommandBar && this.dataViewModel.exportEnabled) {
			rightCommands.push(this.getExportActionConfig());
		}
		if (!this.infiniteScrolling && this.dataViewModel.allowPaging) {
			rightCommands.push(this.getPageSizeCustomizationActionConfig());
		}
		rightCommands.push(this.getFilterActionConfig());

		return rightCommands;
	}

	private _fixedOptions: { [index: string]: any };

	get fixedOptions(): { [index: string]: any } {
		return this._fixedOptions;
	}

	@Input()
	set fixedOptions(value: { [index: string]: any }) {
		this._fixedOptions = value;
		if (this._isInit) this.setDataViewModel();
	}

	@Input() entityPanelOptions: { [index: string]: any };

	private _getFilterParams: DataViewConfig['getFilterQueryOptions'];

	@Input()
	set getFilterQueryOptions(getFilterQueryOptions: DataViewConfig['getFilterQueryOptions']) {
		this._getFilterParams = getFilterQueryOptions;
		if (this._isInit) this.setDataViewModel();
	}

	get getFilterQueryOptions(): DataViewConfig['getFilterQueryOptions'] {
		return this._getFilterParams;
	}

	private _disabledFields: Array<DataviewFieldId>;

	@Input()
	set disabledFields(value: Array<DataviewFieldId>) {
		this._disabledFields = value;
		if (this._isInit) this.setDataViewModel();
	}

	private _sortDisabledFields: Array<DataviewFieldId>;

	@Input()
	set sortDisabledFields(value: Array<DataviewFieldId>) {
		this._sortDisabledFields = value;
		if (this._isInit) this.setDataViewModel();
	}

	private _visibleFields: Array<DataviewFieldId>;

	get visibleFields(): Array<DataviewFieldId> {
		return this._visibleFields;
	}

	@Input()
	set visibleFields(value: Array<DataviewFieldId>) {
		this._visibleFields = value;

		if (this._isInit && this.dataViewModel) {
			this.dataViewModel.setVisibleFields(value);
		}
	}

	get userPreferencesId(): string {
		return `dataView_${DataViewModel.getDataViewId(
			this.store,
			this.repository,
			this.id ? { id: this.id } : this._dataViewConfig
		)}`;
	}

	get selectedItemsCount(): number {
		return this.allItemsSelected ? this.dataSet.count : this.selectedItems.length;
	}

	ngOnInit() {
		this.isItemSelected = this.isItemSelected ? this.isItemSelected : () => false;
		if (this.selectAllItemsByDefault) {
			this.isItemSelected = () => true;
		}

		if (this.description) this.descriptionHtml = this.sanitizer.bypassSecurityTrustHtml(this.description);

		this.evaluateEntityNames();

		const userPreferences = this.getUserPreferencesForDataView();
		this.columnWidths = userPreferences && userPreferences.columnWidths;

		const urlParams = this.getUrlParams();
		if (!urlParams.has('filters')) {
			this.setFiltersStateForEmptyUrl(urlParams.has('innerRouteFilterParams'));
		}

		this.setDataViewModel();
		this._isInit = true;

		this.boundIsItemSelectable = this.isItemSelectable.bind(this);
		this.getLoadNextResultsUrl = this.getLoadNextResultsUrl.bind(this);
		this.getLoadPreviousResultsUrl = this.getLoadPreviousResultsUrl.bind(this);

		this.panelsService.activePanels$.subscribe((panels) => this.setModalWidth(panels));
		this.visibleFieldIds$.subscribe(
			() => (this.disableReset = this.dataViewModel.allDefaultFieldsSelected)
		);

		this.groupItems = new Map();

		if (!this.dataViewModel) return;
	}

	ngOnDestroy() {
		this._locationChangeSubscription && this._locationChangeSubscription.unsubscribe();
		this._queryParamsSubscription && this._queryParamsSubscription.unsubscribe();
		this._onChangeDisplayedItems && this._onChangeDisplayedItems.unsubscribe();
		this._onCloseEntityPanelSubscription && this._onCloseEntityPanelSubscription.unsubscribe();
		this._onEntityTypeActionSubscription && this._onEntityTypeActionSubscription.unsubscribe();

		this.dataViewModel && this.dataViewModel.destroy();

		if (this._onStoreChanges) this._onStoreChanges.unsubscribe();
	}

	setDataViewModel() {
		if (!this.fields && !this.store) return;

		// First, debouncing, since setDataViewModel can be called a bunch of times together:
		clearTimeout(this._setDataViewModelTimeout);

		this._setDataViewModelTimeout = setTimeout(async () => {
			this.destroy();

			const dataViewConfig: DataViewConfig = mergeDataViewConfig(
				{
					pageSize: this.pageSize,
					searchParamName:
						(this.dataViewConfig && this.dataViewConfig.searchParamName) ||
						DEFAULT_SEARCH_PARAM_NAME,
					disabledVisibleFieldIds: this._disabledFields,
					disabledFilterFieldIds: this.disabledFilters,
					sortDisabledFieldIds: this._sortDisabledFields,
					fields: this.fields,
					defaultSortFieldId: this.defaultSortFieldId,
					fixedFilterValues: this.fixedOptions,
					fixedOptions: this.fixedOptions,
					allowFilters: this.allowFilters,
					allowPaging: this.allowPaging,
					visibleFields: this.visibleFields,
					infiniteScrolling: this.infiniteScrolling,
					forceGetDataFromApi: this.forceGetDataFromApi,
					loadItemsOnTableTop: this.loadItemsOnTableTop,
					getLoadNextResultsUrl: this.getLoadNextResultsUrl,
					getLoadPreviousResultsUrl: this.getLoadPreviousResultsUrl,
					getFilterQueryOptions: this.getFilterQueryOptions,
				},
				this.dataViewConfig,
				this.getUserPreferencesForDataView()
			);

			if (
				this.allowFilters &&
				!this.store &&
				this.repository &&
				dataViewConfig.requireFiltersData !== false &&
				!dataViewConfig.getFiltersData
			) {
				Object.assign(dataViewConfig, {
					getFiltersData: this.dataviewRepositoryService.getFilterValuesForRepository(
						this.repository,
						{ ...this.fixedOptions, ...dataViewConfig.fixedFilterValues }
					),
				});
			}

			this.dataViewModel = this.store
				? DataViewModel.fromStore(this.store, dataViewConfig)
				: this.repository
				? DataViewModel.fromRepository(
						this.repository,
						dataViewConfig,
						this.paris,
						this.featuresService,
						this.appContext
				  )
				: new DataViewModel(dataViewConfig);

			if (this._dataViewOptionsToSet) {
				this.dataViewModel.setOptions(this._dataViewOptionsToSet, false);
				this._dataViewOptionsToSet = null;
			}
			this._onVisibleFieldsChangeSubscription = this.dataViewModel.visibleFields$.subscribe(
				(visibleFields) => this.onFieldsChange(visibleFields)
			);
			this._onErrorSubscription = this.dataViewModel.error$.subscribe((error) =>
				this.onDataError(error)
			);
			this._onDataSubscription = this.dataViewModel.dataSet$.subscribe(this.onDataHandler.bind(this));
			this._isLoadingSubscription = this.dataViewModel.isLoading$.subscribe((isDataLoading) => {
				if (isDataLoading) {
					this.isTableLoading = true;
				}
				// isLoading starts as false, when data load starts, its turn in to true
				// when data load ends turn back to false
				// table done first init after is loading finished the cycle: false -> true -> false
				if (!isDataLoading && this.isTableLoading) {
					if (this.focusOnFirstMenuItem) {
						if (this.focusOnFirstMenuItem) {
							this.focusOnFirstFocusable();
							this.focusOnFirstMenuItem = false;
							setTimeout(() => {
								this.tabIndex = -1;
							});
						}

						this.focusOnFirstMenuItem = false;
					}
					this.afterTableInit.emit(true);
				}
			});

			// When the first event is fired (if the page is loaded with search term in url) sets the value
			this._onSearchChangeSubscription = this.dataViewModel.searchTerm$.pipe(take(1)).subscribe(() => {
				if (this.dataViewModel.currentSearchTerm) {
					this.onSearchBlur();
				}
			});

			// Skipping the first event, to avoid the initial dataViewModel settings. Debouncing for performance.
			this._onSettingsChangeSubscription = merge(
				this.dataViewModel.changeSettings$,
				this._filterValues$
			)
				.pipe(debounceTime(50), skip(1))
				.subscribe(this.onDataViewSettingsChange.bind(this));

			this._visibleFieldsSubscription = this.dataViewModel.visibleFields$
				.pipe(
					map((fields) => fields.map((field) => field.id)),
					startWith(undefined),
					debounceTime(1)
				)
				.subscribe((fieldIds) => {
					this._visibleFieldIds$.next(fieldIds);
				});

			if (!this.setDataViewOptionsFromLocation()) this.refreshData();

			this.unSelectAllItems();

			this.setViewModelOptions();
			await this.setDataTableGetGroupedItems();
			if (this.responsiveActionBar) {
				this.commandBarLeft = this.buildLeftCommandBar();
				this.commandBarRight = this.buildRightCommandBar();
			}
			this.changeDetectionRef.markForCheck();
		}, 1);
	}

	private focusOnFirstFocusable() {
		setTimeout(() => {
			if (this.focusable.first instanceof ElementRef) {
				this.focusable.first.nativeElement.focus();
			} else if (this.focusable.first && (this.focusable.first as Focusable).setFocus) {
				(this.focusable.first as Focusable).setFocus();
			}
		}, 0);
	}

	toggleFilters(isOpen?: boolean) {
		this.disableFilterPanelAutoFocus = false;
		this.showFilters = isNil(isOpen) ? !this.showFilters : isOpen;
		if (!this.showFilters) {
			setTimeout(() => this.filterButton.nativeElement.focus(), 0);
		}
	}

	setViewModelOptions() {
		if (!this.dataViewModel.isLocalData) {
			this.searchTerm = this.dataViewModel.currentSearchTerm;
			if (this._ignoreQueryParams) {
				// if the data isn't local it will be navigated on change and reloaded but with ignoring query params
				// it will not receive the events of the query param changes - which in this case is the search been pressed
				this.dataViewModel.reloadData();
			}
		}
	}

	destroy() {
		if (this.dataViewModel) {
			this.dataViewModel.destroy();
			this._onDataSubscription && this._onDataSubscription.unsubscribe();
			this._onErrorSubscription && this._onErrorSubscription.unsubscribe();
			this._onVisibleFieldsChangeSubscription && this._onVisibleFieldsChangeSubscription.unsubscribe();
			this._onSettingsChangeSubscription && this._onSettingsChangeSubscription.unsubscribe();
			this._onSearchChangeSubscription && this._onSearchChangeSubscription.unsubscribe();
			this._visibleFieldsSubscription && this._visibleFieldsSubscription.unsubscribe();
			this._isLoadingSubscription && this._isLoadingSubscription.unsubscribe();
		}

		if (this._storeItemsSubscription) this._storeItemsSubscription.unsubscribe();
	}

	onDataError(error) {
		if (!error) this.error = null;
		else if (error.status !== 401) {
			// In case of error, revert user preferences
			if (this.savedUserPreferences) {
				this.preferencesService.setPreference(this.userPreferencesId, this.savedUserPreferences);
				this.savedUserPreferences = null;
			}
			this.dataLoadError.emit(error);
			this.error = error;
			this.appInsightsService.trackException(error);

			if (this.showDialogOnError) {
				this.dialogsService.showError({
					title: this.i18nService.get('dataview_loading_error', {
						itemType: this.itemNamePluralLowercase,
					}),
					message: (error && error.message) || error,
					data: error,
				});
			}

			this.changeDetectionRef.markForCheck();
		}
	}

	refreshData() {
		if (this.dataViewModel && !this._lockRefresh) {
			this._lockRefresh = true;
			if (this.setFiltersOnDataRefresh) {
				this.dataViewModel.setFilters();
			}
			this.dataViewModel.reloadData();
		}
	}

	reloadData() {
		this.dataViewModel.setFilters();
		this.dataViewModel.reloadData();
	}

	private getUrlParams() {
		const fromString =
			window.location.search && window.location.search.includes('?')
				? window.location.search.split('?')[1]
				: window.location.search;
		return new HttpParams({ fromString });
	}

	private evaluateEntityNames() {
		const itemNamePlural = this.itemNamePlural
			? this.itemNamePlural
			: this.entityType && this.entityType.entityPluralNameKey
			? this.i18nService.get(this.entityType.entityPluralNameKey)
			: this.i18nService.get('dataview_items');

		this.itemNamePluralLowercase = itemNamePlural.toLowerCase();

		this.itemNameSingular = this.itemName
			? this.itemName
			: this.entityType && this.entityType.entitySingularNameKey
			? this.i18nService.get(this.entityType.entitySingularNameKey)
			: this.i18nService.get('dataview_item');

		this.itemNameLowercase = this.itemNameSingular.toLowerCase();
	}

	onFiltersChanged(filtersState: FiltersState) {
		// If we are in non responsive actions mode, then the filters are inlined in dataview template
		// So we handle the filters component state here
		if (
			(!filtersState.selection || Object.keys(filtersState.selection).length === 0) &&
			!this.responsiveActionBar
		) {
			this.resetFilters(filtersState);
		}

		this.dataViewModel.setFilters(filtersState);
		this._filterValues$.next(filtersState);
		this.filtersChange.emit(filtersState);

		if (this.dataViewModel.allowFilters) this.trackFilters(filtersState);
	}

	resetFilters(filtersState: FiltersState) {
		filtersState.selection = this.defaultQueryFilters;
		filtersState.serialized = this.defaultQueryFilters;
		this._filtersComponent.resetSelectedValues(this.defaultQueryFilters);
	}

	trackFilters(filtersState: FiltersState) {
		const filterTracking: Array<{
			field: string;
			values?: string | Array<string>;
			valueCount: number;
		}> = Object.keys(filtersState.serialized || {}).map((filterParam) => {
			const serializedFilterValues = filtersState.serialized[filterParam];

			const fieldTrackingData: {
				field: string;
				values?: string | Array<string>;
				valueCount: number;
			} = {
				field: filterParam,
				valueCount: serializedFilterValues instanceof Array ? serializedFilterValues.length : 1,
			};

			const field: DataviewField = this.dataViewModel.fields.find(
				(_field) => _field.id === filterParam
			);

			if (field && field.custom && field.custom.allowFilterValueTracking)
				fieldTrackingData.values = serializedFilterValues;

			return fieldTrackingData;
		});

		this.trackEvent('DataViewFilterChanged', TrackingEventType.Filter, filterTracking);
	}

	onSortChange(sortField: DataviewField<TData>, sortDescending?: boolean) {
		this.dataViewModel.setSortField(sortField, sortDescending, true);
		this.onSortChanged.emit({ sortField, sortDescending: this.dataViewModel.sortDescending });
	}

	/**
	 * Toggles the given item selection status.
	 * @param item Item to toggle the state of
	 * @param isExclusive If isExclusive parameter is true, so all other items are de-selected (if any).
	 * @param state Is state is specified, so set the item selection to the given state, otherwise it toggles it (negates it)
	 */
	toggleItemSelection(item: TData, isExclusive: boolean = false, state?: boolean): void {
		if (this._dataTableComponent) {
			if (isExclusive) {
				if (state === undefined) {
					state = !this._dataTableComponent.selectedItemsIndex.has(item);
				}
				this.selectItems(state ? [item] : []);
			} else {
				this._dataTableComponent.toggleItemSelection(item, state);
			}
		}
	}

	/**
	 * Select the given items (and de-select other items, if any).
	 * @param items The items that should be selected
	 */
	selectItems(items: Array<TData>): void {
		if (this.isItemSelectable) items = items.filter((item) => this.isItemSelectable(item));

		if (this._dataTableComponent) {
			this._dataTableComponent.selectItems(items);
		}
	}

	selectItemsByIds(itemIds: Array<TId>) {
		const currentDataSet = this.dataViewModel.dataSet$.value;

		if (currentDataSet) {
			const selectedItems = currentDataSet.items.filter((item) => itemIds.includes(item.id));
			this.selectItems(selectedItems);
		}
	}

	onSelectItems(selectedItems: Array<TData>, previous?: TData, next?: TData) {
		if (this.isSameItemsById(selectedItems, this.selectedItems)) return;

		this.selectedItems = selectedItems;
		this.allItemsSelected = null;

		const items = this.dataSet ? this.dataSet.items : [],
			count = this.dataSet ? this.dataSet.count : 0;

		this.allPageItemsSelected = this.dataSet
			? selectedItems.length === items.length && selectedItems.length < count
			: false;

		if (!this.allPageItemsSelected && this.itemSelectable && selectedItems.length < count)
			this.allPageItemsSelected = items.length === this.selectedItems.length;

		const selectEvent: DataViewSelectEvent = {
			items: selectedItems,
			previous,
			next,
		};

		this.select.emit(selectEvent);

		if (this.entityType && this.shouldShowEntityPanel) this.showEntityPanel(selectEvent);
		else if (this.setItemActions) this.itemActions = this.setItemActions(this.selectedItems) || [];

		this.changeDetectionRef.detectChanges();
	}

	selectAllItems(applySelection: boolean = true) {
		if (applySelection) {
			setTimeout(() => {
				this.selectItems(this.dataSet.items);
				this.changeDetectionRef.markForCheck();
			});
		}
	}

	unSelectAllItems() {
		this.allItemsSelected = false;
		this.allPageItemsSelected = false;
		this._dataTableComponent && this._dataTableComponent.selectNone();
		this.selectedItems = [];
		this.changeDetectionRef.markForCheck();
	}

	onFieldsChange(enabledFields) {
		const enabledFieldsIndex = {};
		enabledFields.forEach((field) => (enabledFieldsIndex[field.id] = true));
		this.changeDetectionRef.markForCheck();
	}

	runAction(action: ItemActionModel, actionValue?: ItemActionValue) {
		const onAction = (data?) => {
			if (action.refreshOnResolve && data !== false) this.refreshData();

			this.onAction.emit({ data: data });
		};

		this.runningAction = true;

		try {
			((actionValue && actionValue.method) || action.method)(
				this.allItemsSelected ? null : this.selectedItems,
				this._filtersComponent ? this._filtersComponent.serialize() : {},
				this.dataViewModel.dataSet$.value,
				actionValue
			).then(
				(data) => {
					if (data !== false) {
						this.unSelectAllItems();
						onAction(data);
					}

					this.runningAction = false;
				},
				(error) => {
					if (error !== false) {
						this.dialogsService.showError({
							title: this.i18nService.get('dataview_error_dialog_title'),
							errorTitle: this.i18nService.get('dataview_error_dialog_error_title', {
								actionName: action.name,
							}),
							data: error,
						});
					}

					this.runningAction = false;
				}
			);
		} catch (e) {
			console.error(e, e.stack);
			this.runningAction = false;
		}
	}

	onDataHandler(dataSet: DataSet<TData>): void {
		this.dataSet = dataSet;
		this._lockRefresh = false;
		this.error = null;

		if (dataSet) {
			this.savedUserPreferences = null;
			const resultsCount = (dataSet.items && dataSet.items.length) || 0;
			if (this.dataViewModel.currentPage === 1) {
				setTimeout(
					() =>
						this.liveAnnouncer.announce(
							!resultsCount
								? this.emptyDataMessage ||
										this.i18nService.get('dataview_empty_data_message', {
											itemType: this.itemNamePluralLowercase,
										})
								: this.i18nService.get('dataview.result.found', { count: resultsCount }),
							'assertive'
						),
					0
				);
			}
			this.onData.emit({ data: dataSet, dataOptions: this, page: this.dataViewModel.currentPage });

			setTimeout(() => {
				this.selectItems(this.allItems.filter(this.isItemSelected));
				this.changeDetectionRef.detectChanges();
			});

			if (!this._shouldKeepNextResultsUrl) {
				this.setLoadNextResultsUrl(dataSet.next);
			}
			if (!this._shouldKeepPreviousResultsUrl) {
				this.setLoadPreviousResultsUrl(dataSet.previous);
			}
		}
		this.changeDetectionRef.markForCheck();
	}

	search(term) {
		// if ignoring the query params and the data isn't local, the search term won't be updated because navigate on change
		// won't happen so the search term must be set inside the data view model as the third argument (setCurrentSearchTerm)
		this.lastSearched = term;
		const setCurrentSearchTerm: boolean = this._ignoreQueryParams;
		this.dataViewModel.setSearchTerm(term, true, setCurrentSearchTerm);
		this.onSearch.emit(term);
		this.trackEvent('DataviewSearchGo', TrackingEventType.Button);
		setTimeout(() => (this.focusOnTable = true));
	}

	cancelSearch() {
		this.search('');
		this.searchTerm = null;
		this.onSearch.emit('');
		this.trackEvent('DataviewSearchClear', TrackingEventType.Button);
	}

	onSearchBlur() {
		if (!this.searchTerm && this.dataViewModel.currentSearchTerm)
			this.searchTerm = this.dataViewModel.currentSearchTerm;
	}

	isItemSelectable(item) {
		return this.itemSelectable ? this.itemSelectable(item) : true;
	}

	exportData(format?: string, optionsValuesToOverride?: DatasetBackendOptions): void {
		if (this.dataViewConfig && this.dataViewConfig.showModalOnExport === false) {
			this.isTableExporting = this.dataViewConfig.showBusyOnExport && true;
			this.dataViewModel.exportData(format || DEFAULT_EXPORT_FORMAT, null, optionsValuesToOverride).then(
				(response: string | MachineExportResponse) => {
					this.isTableExporting = false;
					this.changeDetectionRef.markForCheck();

					if (isMachineExportResponse(response) && response.isPartial) {
						this.confirmationService
							.confirm({
								iconCssClass: 'color-text-error',
								title: this.i18nService.strings.dataview_export_data_partial_result_title,
								icon: 'Notification',
								showConfirm: true,
								cancelText: this.i18nService.strings.dataview_export_data_partial_result_close_button,
								text: this.i18nService.strings.dataview_export_data_partial_result_description,
							})
							.then((result: ConfirmEvent<any>) => {
								if (result.confirmed) {
									this.exportData(format, { toDate: response.minIncludedEventTime, isPartialExport: true });
								}
							});
					}
				},
				(err) => {
					this.isTableExporting = false;
					this.changeDetectionRef.markForCheck();
					this.appInsightsService.trackException(err);
					this.errorsDialogService.showError({
						title: this.i18nService.strings.dataview_export_data_failed,
						data: err,
					});
				}
			);
		} else {
			let modal: ComponentRef<any>;
			this.dialogsService
				.showModal(
					ExportDataSetModalComponent,
					{
						id: 'export-data-modal',
						title: this.i18nService.strings.dataview_export_data_dialog_title,
						dimensions: new DimensionsModel(280, 274),
					},
					{
						totalCount: this.dataSet && this.dataSet.count,
						setExportCount: (rowCount: number) =>
							Promise.resolve(
								setTimeout(
									() =>
										(this.isTableExporting = this.dataViewConfig.showBusyOnExport && true)
								)
							).then(() =>
								this.dataViewModel
									.exportData(format || DEFAULT_EXPORT_FORMAT, rowCount)
									.then(() => {
										this.isTableExporting = false;
										this.changeDetectionRef.markForCheck();
									})
							),
					}
				)
				.subscribe((_modal) => {
					modal = _modal;
				});
		}
	}

	setFields(fields: Array<DataviewField<TData>>, setDataViewModel: boolean = true): void {
		if (setDataViewModel) this.fields = fields;
		else {
			this._fields = fields;
			this.dataViewModel && this.dataViewModel.setFields(fields);
		}
	}

	public trackById(index, item: { id: TId }): TId {
		return item.id;
	}

	setUserPreferences() {
		const params = this.getUrlParams();
		const dataViewModelOptions: DatasetBackendOptions = this.dataViewModel
			? this.dataViewModel.getNonDefaultDataSetOptions()
			: {};
		const userPreferences: DataViewUserPreferences = {};

		if (this.columnWidths) userPreferences.columnWidths = this.columnWidths;

		if (this.persistPageSize) {
			const pageSizeParam = params.get('page_size');
			if (pageSizeParam || dataViewModelOptions.page_size) {
				userPreferences.pageSize = pageSizeParam
					? parseInt(pageSizeParam)
					: dataViewModelOptions.page_size;
			}
		}

		const fieldsToUpdate = params.get('fields') || dataViewModelOptions.fields;
		if (fieldsToUpdate) {
			userPreferences.visibleFields = fieldsToUpdate.split(',');
		}

		if (this.persistFilters) {
			let filtersToUpdate = FiltersService.filtersQueryParamToSerializedFilters(params.get('filters'));
			if (isEmpty(filtersToUpdate))
				filtersToUpdate =
					this.dataViewModel &&
					this.dataViewModel.filtersState &&
					this.dataViewModel.filtersState.serialized;
			if (filtersToUpdate) {
				userPreferences.filters = JSON.stringify(filtersToUpdate);
			}
		}

		this.savedUserPreferences = this.preferencesService.getPreference(this.userPreferencesId) || {};
		this.preferencesService.setPreference(this.userPreferencesId, userPreferences);
	}

	getLoadNextResultsUrl() {
		return this._loadNextResultsUrl;
	}

	setLoadNextResultsUrl(url: string) {
		this._loadNextResultsUrl = url;
	}

	getLoadPreviousResultsUrl() {
		return this._loadPreviousResultsUrl;
	}

	setLoadPreviousResultsUrl(url: string) {
		this._loadPreviousResultsUrl = url;
	}

	getPageSizeLabel(value: number): string {
		return this.i18nService.get('dataview_page_size_label', { count: value });
	}

	setModalWidth(panels: Array<Panel>) {
		if (panels) {
			this.modalsWidth = panels.reduce(
				(maxWidth: number, panel: Panel) =>
					Math.max(maxWidth, panel.isModal ? PanelService.getPanelTypeWidth(panel.type) : 0),
				0
			);
		} else this.modalsWidth = 0;
	}

	itemClick(item: TData, previous?: TData, next?: TData) {
		this.onItemClick.emit({
			item: item,
			previous: previous,
			next: next,
		});

		if (this.selectOnItemClick || this.entityType) this.selectItems([item]);
	}

	showEntityPanel($event: DataViewSelectEvent): void {
		const isSingleItem: boolean = $event.items.length === 1;

		const panelSettings: PanelSettings = isSingleItem
			? {
					previous: {
						onClick: () => this.selectItems([$event.previous]),
						isDisabled: !$event.previous,
					},
					next: {
						onClick: () => this.selectItems([$event.next]),
						isDisabled: !$event.next,
					},
					...this.entitySidePanelSettings,
			  }
			: null;

		const panelOptions = {
			// Older code used to pass panel options in `fixesOptions`. Add it for compatibility.
			...this.fixedOptions,
			...this.entityPanelOptions,
		};

		this.trackEvent(
			`OpenEntityPanel_${this.entityType.entity.singularName}`,
			TrackingEventType.SidePaneToggleButton
		);

		this.entityPanelsService.showEntities(
			this.entityType.entity,
			$event.items,
			panelOptions,
			panelSettings,
			this.id
		);
	}

	updateHeaders(): void {
		if (this._dataTableComponent) {
			this._dataTableComponent.updateHeaderCells();
		}
	}

	triggerItemClick(item: TData): void {
		if (this._dataTableComponent) {
			this._dataTableComponent.triggerItemClick(item);
		}
	}

	onGroupExpand($event: { group: TData; children: Array<TData> }) {
		if (this.selectOnItemClick)
			this.selectItems([...$event.children, ...(this.selectedItems || []), $event.group]);

		this.groupItems.set($event.group, $event.children);

		this.groupExpand.emit($event);

		this.trackEvent('ExpandItemGroupInResults', TrackingEventType.Toggle);
	}

	onPageSizeChange(pageSize: number) {
		this.dataViewModel.setPageSize(pageSize);
		this.trackEvent('DataviewPageSizeChanged', TrackingEventType.Button, pageSize);
	}

	onPageNumberChange(pageNumber: number) {
		this.dataViewModel.setPage(pageNumber);
		this.trackEvent('DataviewPageNumberChanged', TrackingEventType.Button, pageNumber);
	}

	onColumnsChange(fieldIds: Array<DataviewFieldId>) {
		this.dataViewModel.setVisibleFields(fieldIds);
		const resultsCount = (this.dataSet && this.dataSet.items && this.dataSet.items.length) || 0;
		setTimeout(
			() =>
				this.liveAnnouncer.announce(
					!resultsCount
						? this.emptyDataMessage ||
								this.i18nService.get('dataview_empty_data_message', {
									itemType: this.itemNamePluralLowercase,
								})
						: this.loadingMessageText
						? this.loadingMessageText
						: this.i18nService.get(`dataview_loadingComplete`, {
								entityName: this.itemNamePluralLowercase,
						  }),
					'polite'
				),
			0
		);
		this.trackEvent('DataviewVisibleColumnsChanged', TrackingEventType.Selection, fieldIds);
	}

	async loadNextResults() {
		// When loading next results, we need to keep the last "previous" url
		// (because new dataset returns with a new "previous" url that is related to the last batch)
		this.measurePerformance &&
			sccHostService.perf.createPerformanceSession('load-dataview-data', 'dataview-load-next-results');
		this._shouldKeepPreviousResultsUrl = true;
		await this.dataViewModel.loadNextData();

		this._shouldKeepPreviousResultsUrl = false;
		this.trackEvent('DataviewLoadNextResults', TrackingEventType.Action);
	}

	async loadPreviousResults() {
		// When loading previous results, we need to keep the last "next" url
		// (because new dataset returns with a new "next" url that is related to the last batch)
		this.measurePerformance &&
			sccHostService.perf.createPerformanceSession(
				'load-dataview-data',
				'dataview-load-previous-results'
			);
		this._shouldKeepNextResultsUrl = true;
		await this.dataViewModel.loadPreviousData();

		if (this._dataTableComponent) {
			this._dataTableComponent.onItemsAddedOnTop();
		}
		this._shouldKeepNextResultsUrl = false;
		this.onItemsAddedOnTop.emit(this.dataSet);
		this.trackEvent('DataviewLoadPreviousResults', TrackingEventType.Action);
	}

	onResizeColumn($event: DataTableColumnResizeEvent) {
		const { column, ...insightsEvent } = $event;
		this.appInsightsService.trackEvent('dataViewColumnResize', {
			...insightsEvent,
			column: column.id,
			dataviewId: this.id,
		});
		this.columnWidths = $event.columnsWidths;
		this.setUserPreferences();
	}

	private trackEvent(eventId: string, eventType: TrackingEventType, value?: any) {
		this.appInsightsService.track({
			id: eventId,
			component: `${this.id}_DataView`,
			type: eventType,
			componentType: 'DataView',
			value: value,
		});
	}

	private isSameItemsById(items1: Array<TData>, items2: Array<TData>): boolean {
		if (!items1 || !items2 || items1.length !== items2.length) return false;

		return !differenceBy(items1, items2, (item) => <any>item[this.itemUniqueKey]).length;
	}

	private setDataViewOptionsFromLocation(params?: Params): boolean {
		if (params) this._latestQueryParams = params;
		else params = this._latestQueryParams || {};

		const userPreferences: DataViewUserPreferences = this.getUserPreferencesForDataView() || {};

		const pagingOptions: { page_size?: number; page?: number } =
			this.allowPaging !== false
				? {
						page_size: params.page_size
							? parseInt(params.page_size, 10)
							: this.persistPageSize
							? userPreferences.pageSize
							: DEFAULT_PAGE_SIZE,

						page: params.page ? parseInt(params.page, 10) : 1,
				  }
				: {};

		const searchParamName =
			(this.dataViewConfig && this.dataViewConfig.searchParamName) || DEFAULT_SEARCH_PARAM_NAME;
		const searchParam = params[searchParamName];
		const dataViewOptions = {
			ordering: params.ordering,
			[searchParamName]: searchParam,
			filters: params.filters,
			fields:
				params.fields || (userPreferences.visibleFields && userPreferences.visibleFields.join(',')),
			...pagingOptions,
		};

		if (this.dataViewModel) return this.dataViewModel.setOptions(dataViewOptions);
		else {
			this._dataViewOptionsToSet = dataViewOptions;
			return true;
		}
	}

	private async onDataViewSettingsChange() {
		this.measurePerformance &&
			sccHostService.perf.createPerformanceSession('load-dataview-data', 'dataview-settings-changed');
		this.setViewModelOptions();
		await this.setUrlParams();

		this.changeDetectionRef.markForCheck();
		setTimeout(this.setDataTableGetGroupedItems.bind(this), 0);
	}

	private updateUrlParams(params: Params) {
		this.router.navigate([], { relativeTo: this.route, queryParams: params });
	}

	private async setUrlParams(): Promise<boolean> {
		const search: Params = this.dataViewModel.getNonDefaultDataSetOptions();
		const queryParams: Params = clone(this.route.snapshot.queryParams);
		Object.assign(queryParams, search);

		if (
			this.navigateOnChange &&
			!isEqualWith(search, this._latestQueryParams, (val1, val2) => val1 == val2)
		)
			return this.router.navigate([], { relativeTo: this.route, queryParams: queryParams });
	}

	private getUserPreferencesForDataView(): DataViewUserPreferences {
		return this.preferencesService.getPreference(this.userPreferencesId) || {};
	}

	private async setDataTableGetGroupedItems() {
		if (this.getGroupItems) {
			const options =
				this.dataViewModel &&
				omit(await this.dataViewModel.getDataSetOptions(), [
					'page_size',
					'page',
					'ordering',
					'fields',
				]);

			this.dataTableGetGroupedItems = (group: TData) => this.getGroupItems(group, options);
		} else {
			this.dataTableGetGroupedItems = null;
		}
	}

	private setFiltersStateForEmptyUrl(innerRouteFilterParams: boolean) {
		let filtersToSet;
		if (this.persistFilters) {
			const userPreferences = this.getUserPreferencesForDataView();
			filtersToSet =
				userPreferences &&
				userPreferences.filters &&
				FiltersService.getFiltersQueryParam(JSON.parse(userPreferences.filters));
		}

		filtersToSet = filtersToSet || FiltersService.getFiltersQueryParam(this.defaultQueryFilters);
		if (filtersToSet || innerRouteFilterParams) { // If filters were saved in preferences, or passed via input, or passed via url innerRoute
			this.route.queryParams.pipe(first()).subscribe((params) => {
				this.updateUrlParams({
					...params,
					...(filtersToSet ? { filters: filtersToSet } : {}),
				});
			});
		}
	}

	resetVisibleFields(announce = false) {
		if (announce)
			this.liveAnnouncer.announce(
				this.i18nService.get('common.resetColumnsComplete'),
				'assertive',
				300
			);
		this.dataViewModel.resetVisibleFields();
	}

	onDataTableRenderComplete() {
		this.onTableRenderComplete.emit();

		const perf =
			this.measurePerformance && sccHostService.perf.getPerformanceSessionById('load-dataview-data');
		perf && sccHostService.perf.endPerfSession(perf);
	}
}

interface DataViewUserPreferences {
	visibleFields?: Array<DataviewFieldId>;
	filters?: string;
	pageSize?: number;
	filtersHiddenByDefault?: boolean;
	columnWidths?: Record<string, number>;
}

export interface DataViewClickEvent<TData = any> {
	item: TData;
	previous: TData;
	next: TData;
}

export interface DataViewSelectEvent<TData = any> {
	items: Array<TData>;
	previous: TData;
	next: TData;
}
