import { EMPTY, Observable, of } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { EntityModelBase, Paris, DataEntityType } from '@microsoft/paris';
import { EntityType } from '../models/entity-type.interface';
import { GlobalEntityTypesService } from './global-entity-types.service';
import { ActivatedEntity } from './activated-entity.service';
import { EntityIdRouteService } from './entity-id-route.service';
import { AppContextService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { toObservable } from '../../utils/rxjs/utils';

@Injectable()
export class EntityResolver<TEntity extends EntityModelBase> implements Resolve<TEntity> {
	constructor(
		protected paris: Paris,
		protected activatedEntity: ActivatedEntity,
		protected globalEntityTypesService: GlobalEntityTypesService,
		protected router: Router,
		protected readonly entityIdRouteService: EntityIdRouteService,
		protected appContext: AppContextService
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, params?: {}): Observable<TEntity> {
		let entityType: EntityType<TEntity>;
		if (route.data.requireEntityType !== false) {
			entityType = this.globalEntityTypesService.getEntityType(route.data.entity);

			if (!entityType) throw new Error('Unknown entity type.');
		}

		if (
			this.activatedEntity.recentEntity &&
			this.activatedEntity.recentEntity.constructor === route.data.entity
		)
			return <Observable<TEntity>>of(this.activatedEntity.recentEntity);

		const routeId = this.entityIdRouteService.getRouteId({ route });

		const params$ = toObservable(
			params ||
				(entityType && entityType.getItemParams
					? entityType.getItemParams(route.data.entity, { routeId: routeId })
					: null)
		);
		return params$.pipe(
			switchMap(_params => this.loadEntity(route.data.entity, routeId, route, state, _params))
		);
	}

	protected loadEntity(
		entityConstructor: DataEntityType<TEntity>,
		entityId: string,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
		params: {}
	): Observable<TEntity> {
		const entityType: EntityType<TEntity> = this.globalEntityTypesService.getEntityType(
			route.data.entity
		);
		const encodedEntityId = entityType && entityType.getEncodedEntityId ? entityType.getEncodedEntityId(entityId) : entityId;
		return this.paris.getItemById(entityConstructor, encodedEntityId, null, params).pipe(
			catchError(error => {
				this.appContext.isSCC
					? setTimeout(() => sccHostService.state.go('Error.child', { path: '404' }))
					: this.router.navigateByUrl('/Error/404');
				return EMPTY;
			})
		);
	}
}
