/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tvm-impact-score.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i3 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i6 from "@angular-react/fabric";
import * as i7 from "@angular/common";
import * as i8 from "./tvm-impact-score.component";
var styles_TvmImpactScoreComponent = [i0.styles];
var RenderType_TvmImpactScoreComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TvmImpactScoreComponent, data: {} });
export { RenderType_TvmImpactScoreComponent as RenderType_TvmImpactScoreComponent };
function View_TvmImpactScoreComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "wcd-flex-center-vertical"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.TooltipDirective, [i1.ElementRef, i3.TooltipsService, i4.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], tooltipCssClass: [1, "tooltipCssClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fab-icon", [["contentClass", "wcd-font-size-mi impact-control-color"], ["iconName", "CaretSolidDown"]], null, null, null, i5.View_FabIconComponent_0, i5.RenderType_FabIconComponent)), i1.ɵdid(4, 5816320, null, 0, i6.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "exposure-score wcd-margin-small-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["\n            ", "\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._scoreData.exposureScoreTooltip; var currVal_1 = "tvm-impact-score-tooltip"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "wcd-font-size-mi impact-control-color"; var currVal_3 = "CaretSolidDown"; _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.exposureScore; _ck(_v, 7, 0, currVal_4); }); }
function View_TvmImpactScoreComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "separator wcd-margin-small-right"]], null, null, null, null, null))], null, null); }
function View_TvmImpactScoreComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "wcd-flex-center-vertical"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.TooltipDirective, [i1.ElementRef, i3.TooltipsService, i4.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], tooltipCssClass: [1, "tooltipCssClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fab-icon", [["contentClass", "wcd-font-size-mi impact-control-color"], ["iconName", "CalculatorAddition"]], null, null, null, i5.View_FabIconComponent_0, i5.RenderType_FabIconComponent)), i1.ɵdid(4, 5816320, null, 0, i6.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "secure-score"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["\n            ", "\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._scoreData.secureScoreTooltip; var currVal_1 = "tvm-impact-score-tooltip"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "wcd-font-size-mi impact-control-color"; var currVal_3 = "CalculatorAddition"; _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = ((_co._scoreData == null) ? null : _co._scoreData.secureScore.toFixed(2)); _ck(_v, 7, 0, currVal_4); }); }
export function View_TvmImpactScoreComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "tvm-impact-score wcd-flex-center-vertical wcd-font-size-m"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TvmImpactScoreComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TvmImpactScoreComponent_2)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TvmImpactScoreComponent_3)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasExposureScore; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showSeparator; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.hasSecureScore; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_TvmImpactScoreComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tvm-impact-score", [], [[2, "wcd-inline-flex-center-vertical", null]], null, null, View_TvmImpactScoreComponent_0, RenderType_TvmImpactScoreComponent)), i1.ɵdid(1, 49152, null, 0, i8.TvmImpactScoreComponent, [i1.ChangeDetectorRef], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hostInlineFlexCenterVertical; _ck(_v, 0, 0, currVal_0); }); }
var TvmImpactScoreComponentNgFactory = i1.ɵccf("tvm-impact-score", i8.TvmImpactScoreComponent, View_TvmImpactScoreComponent_Host_0, { scoreData: "scoreData" }, {}, []);
export { TvmImpactScoreComponentNgFactory as TvmImpactScoreComponentNgFactory };
