import { SevilleModule } from '../../../../../app/seville/seville.module';

SevilleModule.factory('seville.settings.clientsideutils', clientSideUtilsFactory);

clientSideUtilsFactory.$inject = ['$log', '$timeout'];

function clientSideUtilsFactory($log, $timeout) {
	function copyToClipboard(elementName, elementId, setCopyToClipboardIcon) {
		try {
			// Focus and select the text box
			var element: HTMLInputElement = <HTMLInputElement>document.getElementById(elementId);
			if (element == null) {
				$log.error(elementName + ' - could not find element by id: ' + elementId);
				return;
			}

			element.select();

			// Send the text to clipboard
			document.execCommand('copy');

			$log.debug('copied from ' + elementId);

			// set animated icon (just for 1.5 seconds)
			setCopyToClipboardIcon(
				elementId,
				'icon-CheckMarkZeroWidth settings-clientsideutils-copyToClipboard-icon-animation'
			);

			$timeout(function() {
				// restore the original icon
				setCopyToClipboardIcon(elementId, 'icon-Copy');
			}, 1500);
		} catch (e) {
			$log.debug(elementName + ' - could not copy from ' + elementId + ' ' + e);
		}
	}

	return {
		copyToClipboard: copyToClipboard,
	};
}
