import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityTypeConfig } from './airs-entity-type-config.entity';
import { InfectedEntity } from './infected-entity.entity';

@ValueObject({
	singularName: 'Infected Entity Type',
	pluralName: 'Infected Entity Types',
	readonly: true,
})
export class InfectedEntityType extends ModelBase {
	@EntityField({ data: 'entity_type' })
	type: AirsEntityTypeConfig;

	@EntityField({ arrayOf: InfectedEntity })
	entities: Array<InfectedEntity>;
}
