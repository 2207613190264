import { Injectable } from '@angular/core';
import { EntityType, EntityDataViewOptions } from '../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { Vulnerability, MdeUserRoleActionEnum, ResolveEntityURL, TvmEndPoint } from '@wcd/domain';
import {
	VulnerabilityEntityPanelComponent,
	TvmContextOptions,
} from '../components/vulnerability.entity-panel.component';
import { ItemActionModel } from '../../../../dataviews/models/item-action.model';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmReportInaccuracyService } from '../../../../tvm/services/tvm-report-inaccuracy.service';
import { InaccuracyContext } from '../../../../tvm/services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { VulnerabilityFieldsService } from './vulnerabilities.fields.service';
import { TvmDownloadService } from '../../../../tvm/services/tvm-download.service';
import { VulnerabilityService } from './vulnerability.service';
import { AppContextService } from '@wcd/config';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { AppFlavorConfig } from '@wcd/scc-common';

export interface VulnerabilitiesDataViewFixedOptions {}

@Injectable()
export class VulnerabilityEntityTypeService implements EntityTypeService<Vulnerability> {
	constructor(
		private reportInaccuracyService: TvmReportInaccuracyService,
		private i18nService: I18nService,
		private vulnerabilityFieldsService: VulnerabilityFieldsService,
		private tvmDownloadService: TvmDownloadService,
		private vulnerabilityService: VulnerabilityService,
		private tvmTagsService: TvmTagsService,
		private appContextService: AppContextService
	) {}

	readonly entityType: EntityType<Vulnerability> = {
		id: 'vulnerability',
		entity: Vulnerability,
		getTags: (vulnerability: Vulnerability[]) =>
			// Only sending the first vulnerability since we don't allow multiple selection
			this.tvmTagsService.getVulnerabilityTags(vulnerability[0]),
		searchOptions: {
			displayName: this.i18nService.strings.entity_type_display_name_vulnerability,
			getSearchParams: (searchTerm: string) => {
				const resultsUrl = this.appContextService.isSCC
					? `/searchResults/vulnerability/${searchTerm}`
					: `/vulnerabilities`;
				return { url: resultsUrl, filter: searchTerm };
			},
			searchDropdownOrder: 1,
			requiredAllowedActions: MdeUserRoleActionEnum.tvmViewData,
			flavorConfig: AppFlavorConfig.search.tvmEntities,

		},
		singleEntityPanelComponentType: VulnerabilityEntityPanelComponent,
		loadFullEntityInPanel: false,
		getEntityName: (vulnerability: Vulnerability) => vulnerability.name,
		entitySingularNameKey: 'tvm_common_vulnerability',
		entityPluralNameKey: 'tvm_common_vulnerabilities',
		getActions: (selectedVulnerabilities: Array<Vulnerability>, options: TvmContextOptions) => {
			if (selectedVulnerabilities.length !== 1) {
				return [];
			}
			return [
				new ItemActionModel({
					id: 'openReportInaccuracy',
					nameKey: 'tvm.reportInaccuracy.reportButton',
					icon: FabricIconNames.Feedback,
					closeOnAction: methodResolution => methodResolution,
					method: (vulnerabilities: Array<Vulnerability>) =>
						this.reportInaccuracyService.openReportInaccuracyPanel(
							options && options.isSingleAsset
								? InaccuracyContext.MachineVulnerabilities
								: InaccuracyContext.OrgVulnerabilities,
							vulnerabilities[0]
						),
				}),
			];
		},
		dataViewOptions: <EntityDataViewOptions<Vulnerability, VulnerabilitiesDataViewFixedOptions>>{
			fields: this.vulnerabilityFieldsService.fields,
			exportOptions: {
				showModalOnExport: false,
				exportResults: (options, format, searchTerm, dataQuery) => {
					const url = ResolveEntityURL({
						endPoint: TvmEndPoint.Analytics,
						entityModelBaseOrRelationship: Vulnerability,
					});

					return this.tvmDownloadService.downloadCsv({
						url: url,
						fileName: 'export-tvm-vulnerabilities',
						dataQuery: dataQuery,
					});
				},
			},
			dataViewConfig: {
				getFiltersData: () => this.vulnerabilityService.getFiltersData(),
			},
		},
	};
}
