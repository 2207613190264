import { I18nService } from '@wcd/i18n';
import { ChangeEventService } from './change-event.service';
import { DataviewField } from '@wcd/dataview';
import { TzDateService, getTzString } from '@wcd/localization';
import { isWcdIconName } from '@wcd/icons-font';
import { isFabricIconName } from '@wcd/scc-common';
import { FilterValuesRangeSliderComponent } from '@wcd/ng-filters';
import { ChangeEventTextService } from '../../../../tvm/services/tvm-change-events-texts.service';
var ChangeEventFieldsService = /** @class */ (function () {
    function ChangeEventFieldsService(tzDateService, changeEventTextService, i18nService, changeEventService) {
        this.tzDateService = tzDateService;
        this.changeEventTextService = changeEventTextService;
        this.i18nService = i18nService;
        this.changeEventService = changeEventService;
    }
    Object.defineProperty(ChangeEventFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'date',
                        name: this.i18nService.get('tvm.changeEvent.date'),
                        getDisplay: function (event) {
                            return _this.tzDateService.format(event.date, 'shortDate', getTzString(0));
                        },
                        sort: {
                            sortDescendingByDefault: true,
                        },
                    },
                    {
                        id: 'event',
                        name: this.i18nService.get('tvm.common.event'),
                        getDisplay: function (event) { return _this.changeEventTextService.getChangeEventText(event); },
                        icon: {
                            fabricIcon: function (event) {
                                var iconName = _this.changeEventService.getTitleIconName(event);
                                return isFabricIconName(iconName) ? iconName : null;
                            },
                            wcdIcon: function (event) {
                                var iconName = _this.changeEventService.getTitleIconName(event);
                                return isWcdIconName(iconName) ? iconName : null;
                            },
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'score',
                        name: this.i18nService.get('tvm.common.scoreTrend'),
                        description: this.i18nService.get('tvm.changeEvent.scoreTrendTooltip'),
                        getDisplay: this.changeEventService.getScoreText,
                        enabledByDefault: false,
                        icon: {
                            fabricIcon: this.changeEventService.getScoreTrendIconName,
                            className: this.changeEventService.getScoreTrendIconClass,
                        },
                        getTooltip: this.changeEventService.getToolTip,
                    },
                    {
                        id: 'relatedComponent',
                        name: this.i18nService.get('tvm.common.relatedComponent'),
                        getDisplay: function (event) {
                            return _this.changeEventTextService.getRelatedComponent(event);
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'assetsCount',
                        name: this.i18nService.strings.tvm_changeEvent_originallyImpactedMachines,
                        getDisplay: function (changeEvent) {
                            return _this.changeEventService.getAssetsCountString(changeEvent) ||
                                _this.i18nService.strings.notAvailable_long;
                        },
                        filter: {
                            component: {
                                type: FilterValuesRangeSliderComponent,
                                config: {
                                    min: 0,
                                    max: 100,
                                    valueFormat: function (value) { return value + "%"; },
                                },
                            },
                        },
                    },
                    {
                        id: 'currentAssetsCount',
                        name: this.i18nService.strings.tvm_changeEvent_currentlyImpactedMachines,
                        getDisplay: function (changeEvent) {
                            return _this.changeEventService.getCurrentAssetsCountString(changeEvent) ||
                                _this.i18nService.strings.notAvailable_short;
                        },
                    },
                    {
                        id: 'eventType',
                        name: this.i18nService.get('tvm.changeEvent.type'),
                        getDisplay: function (event) {
                            return _this.i18nService.get("tvm.changeEvent.eventType." + event.eventType);
                        },
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeEventFieldsService.prototype, "singleSoftwareFields", {
        get: function () {
            if (!this._singleSoftwareFields) {
                this._singleSoftwareFields = this.fields.filter(function (field) { return field.id !== 'relatedComponent'; });
            }
            return this._singleSoftwareFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeEventFieldsService.prototype, "topEventsWidgetFields", {
        get: function () {
            if (!this._topEventsWidgetFields) {
                this._topEventsWidgetFields = this.fields.filter(function (field) { return field.id === 'date' || field.id === 'event' || field.id === 'assetsCount'; });
            }
            return this._topEventsWidgetFields;
        },
        enumerable: true,
        configurable: true
    });
    return ChangeEventFieldsService;
}());
export { ChangeEventFieldsService };
