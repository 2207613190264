var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { AirsEntityType, File, FileIncidentAlertsRelationship, MailClusterUtils, } from '@wcd/domain';
import { AirsEntitiesService } from '../services/airs-entities.service';
import { GeoCoordinates } from '../../../maps/models/geo-coordinates.model';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { AuthService } from '@wcd/auth';
import { filter, shareReplay } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { GoHuntAirsEntityService } from '../services/go-hunt-airs-entity.service';
import { omit } from 'lodash-es';
import { AppContextService, FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
var TabIds;
(function (TabIds) {
    TabIds["relations"] = "relations";
    TabIds["behavior"] = "behavior";
    TabIds["summary"] = "summary";
    TabIds["evidenceView"] = "evidenceView";
})(TabIds || (TabIds = {}));
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["Details"] = "airs-entity-entity-panel-details";
})(CollapsibleID || (CollapsibleID = {}));
var AirsEntityEntityPanelComponent = /** @class */ (function (_super) {
    __extends(AirsEntityEntityPanelComponent, _super);
    function AirsEntityEntityPanelComponent(changeDetectorRef, airsEntitiesService, authService, paris, i18nService, goHuntAirsEntityService, appContextService, flavorService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.airsEntitiesService = airsEntitiesService;
        _this.authService = authService;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.goHuntAirsEntityService = goHuntAirsEntityService;
        _this.appContextService = appContextService;
        _this.flavorService = flavorService;
        _this.AirsEntityType = AirsEntityType;
        _this.showApproveReject = true;
        _this.flatView = false;
        _this.showGoHuntInDetails = false;
        _this.isMapAvailable = true;
        _this.isScreenshotAvailable = true;
        _this.isScreenshotError = false;
        _this.isLoadingDataFromCloud = false;
        _this.isSettingExclusionRule = false;
        _this.tabIds = TabIds;
        _this.collapsibleID = CollapsibleID;
        _this.defaultTabsInitialConfig = [
            {
                id: _this.tabIds.summary,
                name: _this.i18nService.strings.airsEntities_panel_tab_summary,
                value: null,
            },
        ];
        _this.fileAlertRepo = _this.paris.getRelationshipRepository(FileIncidentAlertsRelationship);
        return _this;
    }
    Object.defineProperty(AirsEntityEntityPanelComponent.prototype, "summaryView", {
        get: function () {
            return this.options && this.options.summaryView;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsEntityEntityPanelComponent.prototype, "huntingContext", {
        get: function () {
            return this.options && this.options.huntingContext;
        },
        enumerable: true,
        configurable: true
    });
    AirsEntityEntityPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (this.action$) {
            this.action$
                .pipe(filter(function (action) {
                return action.action.id === 'createEntityExclusionRule';
            }))
                .subscribe(function () {
                if (_this.entity.remediationActions) {
                    _this.entity.remediationActions.length = 0;
                }
            });
        }
    };
    AirsEntityEntityPanelComponent.prototype.setEntity = function (entity) {
        _super.prototype.setEntity.call(this, entity);
        if (!entity)
            return;
        this.setTabs();
        this.booleanProperties = this.getBooleanProperties();
        this.setLocationData();
        this.setFile();
        if (this.flavorService.isEnabled(AppFlavorConfig.routes.hunting)) {
            this.setGoHunt();
        }
        this.threatExplorerLink = this.getThreatExplorerLink();
    };
    AirsEntityEntityPanelComponent.prototype.setTabs = function () {
        var tabsConfig = this.defaultTabsInitialConfig.slice();
        if (this.summaryView) {
            // only one value
            tabsConfig = [{ id: TabIds.evidenceView }];
        }
        else {
            if (this.entity.relations && this.entity.relations.length) {
                tabsConfig.push({
                    id: TabIds.relations,
                    name: this.entity.relationCount === 1
                        ? this.i18nService.strings.airsEntities_panel_tab_relations_singular
                        : this.i18nService.strings.airsEntities_panel_tab_relations_plural,
                    value: this.entity.relationCount,
                });
            }
            if (this.entity.behavior && this.entity.behavior.length) {
                tabsConfig.push({
                    id: TabIds.behavior,
                    name: this.i18nService.strings.airsEntities_panel_tab_behavior,
                    value: null,
                });
            }
        }
        this.tabs = tabsConfig.map(function (tab) { return new TabModel(tab); });
        this.currentTab = this.tabs[0];
    };
    AirsEntityEntityPanelComponent.prototype.setLocationData = function () {
        if (this.entity.location) {
            this.mapCoordinates = new GeoCoordinates(this.entity.location);
        }
    };
    AirsEntityEntityPanelComponent.prototype.setTab = function (tab) {
        this.currentTab = tab;
    };
    AirsEntityEntityPanelComponent.prototype.onScreenshotError = function () {
        this.isScreenshotAvailable = false;
        this.isScreenshotError = true;
    };
    AirsEntityEntityPanelComponent.prototype.onMapError = function () {
        this.isMapAvailable = false;
    };
    /**
     * Returns an array of all the Entity type's possible boolean fields, each with the Entity's value.
     * @returns {{field:any, value:boolean}[]}
     */
    AirsEntityEntityPanelComponent.prototype.getBooleanProperties = function () {
        var booleanProperties = this.airsEntitiesService.getEntityBooleanFields(this.entity);
        return (booleanProperties &&
            booleanProperties.sort(function (a, b) {
                if ((a.value && b.value) || (!a.value && !b.value)) {
                    return 0;
                }
                return a.value ? -1 : 1;
            }));
    };
    AirsEntityEntityPanelComponent.prototype.setFile = function () {
        this.file = this.entity.sha1
            ? new File({
                id: this.entity.sha1,
                sha1: this.entity.sha1,
            })
            : null;
        this.changeDetectorRef.markForCheck();
    };
    AirsEntityEntityPanelComponent.prototype.setGoHunt = function () {
        this.goHuntActionConfig$ =
            this.showGoHuntInDetails || this.flavorService.isEnabled(AppFlavorConfig.routes.hunting)
                ? this.goHuntAirsEntityService
                    .getGoHuntAirsEntityObservable(this.entity, { huntingContext: this.huntingContext })
                    .pipe(shareReplay({ bufferSize: 1, refCount: true }))
                : null;
    };
    AirsEntityEntityPanelComponent.prototype.getThreatExplorerLink = function () {
        if (!this.appContextService.isSCC) {
            return null;
        }
        var endDate = new Date().toISOString();
        var currentDate = new Date();
        var monthAgo = new Date(currentDate.setDate(currentDate.getDate() - 30)).toISOString();
        var dateRangeStr = "startTime=" + monthAgo + "&endTime=" + endDate;
        if (this.entity.type &&
            (this.entity.type.id == AirsEntityType.MailMessage ||
                this.entity.type.id == AirsEntityType.SubmissionMail)) {
            var param = this.entity.networkMessageId;
            return ((param &&
                "/threatexplorer?dltarget=Explorer&dlstorage=Url&viewid=allemail&query-NetworkMessageId=" + param + "&" + dateRangeStr) ||
                null);
        }
        if (this.flavorService.isEnabled(AppFlavorConfig.incidents.urlExplorerLink) &&
            this.entity.type &&
            this.entity.type.id == AirsEntityType.URL) {
            var param = this.entity.address;
            return ((param &&
                "/threatexplorer?dltarget=Explorer&dlstorage=Url&viewid=allemail&query-CanonicalizedUrl=" + encodeURIComponent(param) + "&" + dateRangeStr) ||
                null);
        }
        if (this.entity.type && this.entity.type.id == AirsEntityType.MailCluster) {
            var clusteredBy = this.entity.clusteredBy;
            var clusteredByValue = this.entity.clusterByValue;
            var queryObj = MailClusterUtils.getQuery(clusteredBy, clusteredByValue);
            queryObj = omit(queryObj, 'ContentType');
            var param = Object.entries(queryObj)
                .map(function (_a) {
                var k = _a[0], v = _a[1];
                return "query-" + k + "=" + encodeURIComponent(v.join(','));
            })
                .join('&');
            return ((param &&
                "/threatexplorer?dltarget=Explorer&dlstorage=Url&viewid=allemail&" + param + "&" + dateRangeStr) ||
                null);
        }
    };
    return AirsEntityEntityPanelComponent;
}(EntityPanelComponentBase));
export { AirsEntityEntityPanelComponent };
