import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Investigation Action Summary',
	pluralName: 'Investigation Action Summaries',
	readonly: true,
})
export class InvestigationActionSummary extends ModelBase {
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() id: number;

	@EntityField() name: string;

	@EntityField() details: string;

	@EntityField({ data: 'is_remediation', defaultValue: false })
	isRemediation: boolean;
}
