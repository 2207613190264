/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../../../dialogs/modals/components/modal.component.ngfactory";
import * as i4 from "../../../dialogs/modals/components/modal.component";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./incident-update-status-dialog.component";
import * as i8 from "@angular/router";
var styles_IncidentUpdateStatusDialogComponent = [];
var RenderType_IncidentUpdateStatusDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IncidentUpdateStatusDialogComponent, data: {} });
export { RenderType_IncidentUpdateStatusDialogComponent as RenderType_IncidentUpdateStatusDialogComponent };
function View_IncidentUpdateStatusDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "loader-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"])), i0.ɵppd(4, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), (_co.isUpdateToResolve ? "incident.resolve.resolvingIncident" : "incident.reactivate.reactivatingIncident"))); _ck(_v, 3, 0, currVal_0); }); }
function View_IncidentUpdateStatusDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"])), i0.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), (_co.isUpdateToResolve ? "incident.resolve.resolveIncident" : "incident.reactivate.reactivateIncident"))); _ck(_v, 0, 0, currVal_0); }); }
export function View_IncidentUpdateStatusDialogComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 28, "modal", [], null, [[null, "close"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ModalComponent_0, i3.RenderType_ModalComponent)), i0.ɵdid(3, 180224, null, 0, i4.ModalComponent, [i0.ElementRef, i0.ChangeDetectorRef], { settings: [0, "settings"] }, { close: "close" }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 0, 24, "form", [["class", "wcd-full-height wcd-flex-vertical"], ["data-track-component", "IncidentUpdateStatusDialog"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("submit" === en)) {
        var pd_2 = (_co.submitResolve() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i5.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(7, 4210688, [["resolveIncident", 4]], 0, i5.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i5.ControlContainer, null, [i5.NgForm]), i0.ɵdid(9, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(11, 0, null, null, 2, "p", [["class", "dialog-contents wcd-flex-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i0.ɵppd(13, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(15, 0, null, null, 13, "footer", [["class", "dialog-footer wcd-flex-none wcd-flex-horizontal wcd-padding-medium-left wcd-padding-medium-right wcd-padding-small-top"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(17, 0, null, null, 6, "button", [["class", "btn btn-primary wcd-flex-1 wcd-margin-right"], ["data-track-type", "Button"], ["type", "submit"]], [[1, "data-track-id", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IncidentUpdateStatusDialogComponent_1)), i0.ɵdid(20, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(0, [["resolveIncident", 2]], null, 0, null, View_IncidentUpdateStatusDialogComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(25, 0, null, null, 2, "button", [["class", "btn wcd-flex-none"], ["data-track-id", "Cancel"], ["data-track-type", "Button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.destroy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(26, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"])), i0.ɵppd(27, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 3, 0, currVal_0); var currVal_10 = _co.resolving; var currVal_11 = i0.ɵnov(_v, 22); _ck(_v, 20, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 9).ngClassValid; var currVal_6 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 0), (_co.isUpdateToResolve ? "incident.resolve.resolveWarning" : "incident.reactivate.reactivateWarning"))); _ck(_v, 12, 0, currVal_8); var currVal_9 = (_co.isUpdateToResolve ? "ResolveIncident" : "ReactivateIncident"); _ck(_v, 17, 0, currVal_9); var currVal_12 = i0.ɵunv(_v, 26, 0, _ck(_v, 27, 0, i0.ɵnov(_v, 0), "incident.setClassification.cancel")); _ck(_v, 26, 0, currVal_12); }); }
export function View_IncidentUpdateStatusDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "incident-update-status-dialog", [], null, null, null, View_IncidentUpdateStatusDialogComponent_0, RenderType_IncidentUpdateStatusDialogComponent)), i0.ɵdid(1, 245760, null, 0, i7.IncidentUpdateStatusDialogComponent, [i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IncidentUpdateStatusDialogComponentNgFactory = i0.ɵccf("incident-update-status-dialog", i7.IncidentUpdateStatusDialogComponent, View_IncidentUpdateStatusDialogComponent_Host_0, { settings: "settings", isUpdateToResolve: "isUpdateToResolve" }, { resolveIncident: "resolveIncident" }, []);
export { IncidentUpdateStatusDialogComponentNgFactory as IncidentUpdateStatusDialogComponentNgFactory };
