var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiBaseModel } from '@wcd/data';
import { Observable } from 'rxjs';
import { merge } from 'lodash-es';
import { map } from 'rxjs/operators';
import { getUserTimeZone } from '@wcd/localization';
var StoreBackendBase = /** @class */ (function (_super) {
    __extends(StoreBackendBase, _super);
    function StoreBackendBase(http, downloadService) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        _this.downloadService = downloadService;
        _this.getAllResultsParams = {};
        _this.getAllResultsEndpoint = 'all';
        _this.commonParams = {};
        _this.updateMethod = 'patch';
        return _this;
    }
    /**
     * Performs a request with `get` http method.
     */
    StoreBackendBase.prototype.get = function (url, options) {
        return this.http.get(this.apiUrl + '/' + url, options);
    };
    /**
     * Performs a request with 'post' http method.
     */
    StoreBackendBase.prototype.post = function (url, body, options) {
        return this.http.post(this.apiUrl + '/' + url, body, options);
    };
    /**
     * Performs a request with 'put' http method.
     */
    StoreBackendBase.prototype.put = function (url, body, options) {
        return this.http.put(this.apiUrl + '/' + url, body, options);
    };
    /**
     * Performs a request with 'delete' http method.
     */
    StoreBackendBase.prototype.delete = function (url, options) {
        return this.http.delete(this.apiUrl + '/' + url, options);
    };
    /**
     * Performs a request with 'patch' http method.
     */
    StoreBackendBase.prototype.patch = function (url, body, options) {
        return this.http.patch(this.apiUrl + '/' + url, body, options);
    };
    StoreBackendBase.prototype.getAllResults = function (options) {
        var _this = this;
        var params = this.getUrlParams(merge({}, { page_size: 99999 }, this.commonParams, this.getAllResultsParams, options));
        return new Observable(function (observer) {
            _this.http
                .get(_this.apiUrl + "/" + _this.getAllResultsEndpoint, params)
                .pipe(map(function (res) {
                if (res.results)
                    return {
                        count: res.count,
                        items: res.results,
                        next: res.next,
                    };
                if (res['data'])
                    return { items: res['data'] };
                // TODO: this is WDATP object structure. we need to get real count from backend in order to allow paging
                if (res['Items'])
                    return { count: res['Items'].length, items: res['Items'] };
                // TODO: this is WDATP object structure. we need to get real count from backend in order to allow paging
                if (res instanceof Array)
                    return { count: res.length, items: res };
                return res;
            }))
                .subscribe(observer);
        });
    };
    StoreBackendBase.prototype.exportAllResults = function (options, format) {
        if (format === void 0) { format = 'csv'; }
        return this.downloadService.downloadFromUrl(this.apiUrl + "/all." + format, options);
    };
    StoreBackendBase.prototype.getFilterValues = function (options) {
        return this.http.get(this.apiUrl + "/filters", this.getUrlParams(merge({}, this.commonParams, options)));
    };
    StoreBackendBase.prototype.searchFilterValues = function (fieldId, term, options) {
        var searchParams = merge({}, options, { field: fieldId, term: term });
        return this.http
            .get(this.apiUrl + "/all", this.getUrlParams(merge({}, this.commonParams, searchParams)))
            .pipe(map(this.extractData));
    };
    StoreBackendBase.prototype.getItem = function (itemId, options) {
        return this.http.get((this.itemApiUrl || this.apiUrl) + "/" + itemId, this.getUrlParams(merge({}, this.commonParams, options)));
    };
    StoreBackendBase.prototype.remove = function (id, options) {
        return this.http.delete((this.itemApiUrl || this.apiUrl) + "/" + id, this.getUrlParams(this.commonParams));
    };
    StoreBackendBase.prototype.create = function (obj, options) {
        return this.http.post(this.apiUrl + "/all?time_offset=" + getUserTimeZone(), obj, this.getUrlParams(this.commonParams));
    };
    StoreBackendBase.prototype.update = function (id, data, options) {
        return this.http[this.updateMethod]((this.itemApiUrl || this.apiUrl) + "/" + id + "?time_offset=" + getUserTimeZone(), data, this.getUrlParams(this.commonParams));
    };
    StoreBackendBase.prototype.updateMultiple = function (data, itemIds, options) {
        return this.http.patch(this.apiUrl + "/all?time_offset=" + getUserTimeZone(), merge({}, data, itemIds ? { ids: itemIds } : null), this.getUrlParams(merge({}, this.commonParams, options)));
    };
    StoreBackendBase.prototype.export = function (itemIds, options) {
        var exportOptions = { time_offset: getUserTimeZone() };
        if (itemIds)
            exportOptions.ids = itemIds;
        return this.downloadService.downloadFromUrl(this.apiUrl + "/export", merge({}, options, exportOptions));
    };
    return StoreBackendBase;
}(ApiBaseModel));
export { StoreBackendBase };
