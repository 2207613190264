import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Live Response setting',
	pluralName: 'Live Response settings',
	endpoint: 'NOOP',
	parseItemQuery: (itemId, entity, config, params) => {
	    if(params && params['useV3Api']) {
	       return 'cloud/live_response/get_properties'
		}

		return params && params['useV2Api'] ? 'v2/live_response/get_properties' : 'live_response/get_properties'
	},
	parseSaveQuery: (item, entity, config, options) => {
		if(options.params['useV3Api']) {
			return 'cloud/live_response/update_properties'
		}

		return options.params['useV2Api'] ? 'v2/live_response/update_properties' : 'live_response/update_properties'
	},
	serializeItem: (item, serializedItem) => ({
		properties: serializedItem,
	}),
	saveMethod: 'PATCH',
	baseUrl: (config: WcdPortalParisConfig, query) => query && query.where && query.where['useV3Api'] ? config.data.serviceUrls.k8s : config.data.serviceUrls.automatedIr,
	cache: {
		max: 1,
		time: 1000 * 60 * 5,
	},
})
export class LiveResponseSettings extends EntityModelBase {
	@EntityField({ data: 'AutomatedIrLiveResponse' })
	liveResponseEnabled?: boolean;

	@EntityField({ data: 'AutomatedIrUnsignedScripts' })
	unsignedScriptsEnabled?: boolean;

	@EntityField({ data: 'LiveResponseForServers', defaultValue: false })
	liveResponseForServers?: boolean;
}
