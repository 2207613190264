import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('rightClick', rightClickDirective);

rightClickDirective.$inject = ['$parse'];

function rightClickDirective($parse) {
	return {
		restrict: 'A',
		controllerAs: 'vm',
		bindToController: true,
		link: function(scope, element, attrs) {
			var fn = $parse(attrs.rightClick);
			element.bind('contextmenu', function(event) {
				scope.$apply(function() {
					fn(scope, { $event: event });
				});
			});
		},
	};
}
