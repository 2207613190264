import { resetToUTC, shiftTime } from '../date-utils';
var DateRangeModel = /** @class */ (function () {
    function DateRangeModel(tzDateService, from, to, forceFullDays) {
        if (forceFullDays === void 0) { forceFullDays = true; }
        this.tzDateService = tzDateService;
        if (from && to && from > to) {
            var _to = to;
            to = from;
            from = _to;
        }
        this._isForcedFullDays = forceFullDays;
        this._from = from && (forceFullDays ? DateRangeModel.startOfDay(from) : new Date(from.valueOf()));
        this._to = to && (forceFullDays ? DateRangeModel.endOfDay(to) : new Date(to.valueOf()));
        this.checkRange();
    }
    Object.defineProperty(DateRangeModel.prototype, "from", {
        get: function () {
            return this._from;
        },
        set: function (value) {
            this._from = value;
            this.checkRange(true);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeModel.prototype, "to", {
        get: function () {
            return this._to;
        },
        set: function (value) {
            this._to = value;
            this.checkRange();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeModel.prototype, "hasValue", {
        get: function () {
            return !!(this.from || this.to);
        },
        enumerable: true,
        configurable: true
    });
    DateRangeModel.fromString = function (tzDateService, str, forceFullDays) {
        if (forceFullDays === void 0) { forceFullDays = true; }
        if (!str || typeof str !== 'string')
            return null;
        var rangeMatch = str.match(/^(\d+)?:(\d+)?$/);
        if (rangeMatch) {
            var _1 = rangeMatch[0], rawFrom = rangeMatch[1], rawTo = rangeMatch[2];
            return new DateRangeModel(tzDateService, rawFrom && new Date(parseInt(rawFrom, 10)), rawTo && new Date(parseInt(rawTo, 10)), forceFullDays);
        }
        return null;
    };
    DateRangeModel.startOfDay = function (date) {
        var newDate = new Date(date.valueOf());
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
        return newDate;
    };
    DateRangeModel.endOfDay = function (date) {
        var newDate = DateRangeModel.startOfDay(date);
        newDate.setDate(date.getDate() + 1);
        newDate.setMilliseconds(-1);
        return newDate;
    };
    DateRangeModel.fromDays = function (tzDateService, days, startDate, goBackwards, forceFullDays) {
        if (startDate === void 0) { startDate = new Date(); }
        if (goBackwards === void 0) { goBackwards = true; }
        if (forceFullDays === void 0) { forceFullDays = true; }
        var daysDelta = days * (goBackwards ? -1 : 1);
        var millisecondDelta = daysDelta * 24 * 60 * 60 * 1000;
        var otherDate = new Date(startDate.valueOf() + millisecondDelta);
        return new DateRangeModel(tzDateService, startDate, otherDate, forceFullDays);
    };
    DateRangeModel.prototype.clone = function () {
        return new DateRangeModel(this.tzDateService, this.from, this.to, this._isForcedFullDays);
    };
    DateRangeModel.prototype.toString = function () {
        if (!this.from && !this.to)
            return '';
        var fromStr = this.from ? this.from.valueOf() : '';
        var toStr = this.to ? this.to.valueOf() : '';
        return [fromStr, toStr].join(':');
    };
    DateRangeModel.prototype.format = function (format, locale) {
        if (!this._from && !this._to)
            return '';
        return this.tzDateService.format(this._from, format, locale) + " -\n\t\t\t\t" + this.tzDateService.format(this._to, format, locale);
    };
    DateRangeModel.prototype.shiftTime = function (milliseconds) {
        shiftTime(this.from, { type: 'milliseconds', amount: milliseconds });
        shiftTime(this.to, { type: 'milliseconds', amount: milliseconds });
    };
    DateRangeModel.prototype.shiftTimeByHours = function (hours) {
        shiftTime(this.from, { type: 'hours', amount: hours });
        shiftTime(this.to, { type: 'hours', amount: hours });
    };
    DateRangeModel.prototype.resetToUTC = function () {
        resetToUTC(this.from);
        resetToUTC(this.to);
    };
    DateRangeModel.prototype.checkRange = function (changeTo) {
        if (this._from && this._to && this._from >= this._to) {
            if (changeTo)
                this._to = DateRangeModel.endOfDay(this._from);
            else
                this._from = DateRangeModel.startOfDay(this._to);
        }
    };
    return DateRangeModel;
}());
export { DateRangeModel };
