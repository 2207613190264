import { ApiCallModel, ApiCall, DataQuery } from '@microsoft/paris';
import { omit } from 'lodash-es';
import { WcdPortalParisConfig } from '../paris-config.interface';

/**
 * Interface for asking Threat Expert a question.
 */
interface ThreatExpertRequest {
	questionArea: string;
	questionBody: string;
	sourceUrl: string;
	userEmail: string;
	language: string;
	requestId: string;
	useProvisioningStore: boolean;
}

interface ThreatExpertRequestDataQuery extends DataQuery {
	where: ThreatExpertRequest;
}

@ApiCall({
	name: 'Threat expert request',
	endpoint: (_config: WcdPortalParisConfig, query: ThreatExpertRequestDataQuery) => {
		if (query && query.where && query.where.useProvisioningStore) {
			return 'questions';
		}
		return 'PostMteRequest';
	},
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig, query: ThreatExpertRequestDataQuery) => {
		if (query && query.where && query.where.useProvisioningStore) {
			return `${config.data.serviceUrls.defenderExpertsService}/mte/experts-on-demand/v1.0`;
		}
		return config.data.serviceUrls.mte;
	},
	parseQuery: (params: ThreatExpertRequest) => {
		return {
			params: {
				useProvisioningStore: params.useProvisioningStore
			}, // passed as request query params, and also to endpoint & baseUrl key functions
			data: omit(params, 'useProvisioningStore'), // passed as request body
		}
	},
})
export class PostThreatExpertRequestApiCall extends ApiCallModel<void, ThreatExpertRequest> { }
