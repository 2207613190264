
		<modal (close)="destroy()" [settings]="settings">
			<form (submit)="submitShortcut()" class="dialog-contents wcd-padding-small" style="width:300px;">
				<wcd-shared-icon
					[iconName]="shortcut.iconName"
					class="pull-left large-medium-icon color-text-primary"
				>
				</wcd-shared-icon>
				<div class="with-large-medium-icon">
					<input
						type="text"
						[(ngModel)]="shortcutValue"
						class="form-control"
						name="shortcut-value"
						autofocus
						id="shortcut-value"
						[attr.placeholder]="shortcut.placeholder"
					/>
				</div>
			</form>
		</modal>
	