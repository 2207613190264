import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import {
	AirsEmail,
	AirsEmailSubmission,
	AirsEntityType,
	EmailMetadata,
	EmailMetadataApiCall,
} from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ThreatsDisplayNameService } from '../../../mtp_investigations/services/threats-display-name-field.service';
import { Paris } from '@microsoft/paris';
import { combineLatest, defer, from, Observable, of, Subject } from 'rxjs';
import {
	catchError,
	distinctUntilChanged,
	filter,
	map,
	share,
	shareReplay,
	startWith,
	switchMap,
	takeUntil,
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';
import { SpinnerSize } from 'office-ui-fabric-react';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { HttpClient } from '@angular/common/http';
import { DownloadEmailPanelComponent } from '../download-email.panel.component';
import { PanelType } from '@wcd/panels';
import { EmailHeadersPanelComponent } from '../email-headers.panel.component';
import { XOR } from '@wcd/types';
import { AppContextService, FeaturesService, Feature } from '@wcd/config';
import { EmailDetectionTechnologiesDisplayNameService } from '../../services/email-entity-detection-technologies-display-field.service';

interface EmailDetails {
	receivedTime: Date;
	networkMessageId: string;
	recipient: string;
}

interface QuarantineMessage {
	identity: string;
}

const LOADING_SYMBOL = Symbol();

@Component({
	selector: 'airs-email-message-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngLet="{ metadata: metadata$ | async, stateData: emailData$ | async } as data">
			<dl class="key-values clearfix" role="none">
				<ng-container *ngIf="entity.subject">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_subject }}</dt>
					<dd role="none">{{ entity.subject }}</dd>
				</ng-container>
				<ng-container *ngIf="!isEmailSubmission">
					<ng-container *ngIf="entity.receivedDate">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailMessage_fields_emailReceivedDate }}
						</dt>
						<dd role="none">{{ entity.receivedDate | date: 'short' }}</dd>
					</ng-container>
					<ng-container *ngIf="entity.deliveryStatus">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailMessage_fields_deliveryStatus }}
						</dt>
						<dd role="none">{{ entity.deliveryStatus }}</dd>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="entity.sender">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_sender }}</dt>
					<dd role="none">{{ entity.sender }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.recipient">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_recipient }}</dt>
					<dd role="none">{{ entity.recipient }}</dd>
				</ng-container>
				<ng-container *ngIf="isEmailSubmission">
					<ng-container *ngIf="emailSubmission.submitter">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailSubmission_fields_reportedBy }}
						</dt>
						<dd role="none">{{ emailSubmission.submitter }}</dd>
					</ng-container>
					<ng-container *ngIf="emailSubmission.timestamp">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailSubmission_fields_deliveredOn }}
						</dt>
						<dd role="none">{{ emailSubmission.timestamp | date: 'short' }}</dd>
					</ng-container>
					<ng-container *ngIf="emailSubmission.reportedOn">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailSubmission_fields_reportedOn }}
						</dt>
						<dd role="none">{{ emailSubmission.reportedOn | date: 'short' }}</dd>
					</ng-container>
					<ng-container *ngIf="emailSubmission.reportTypeI18nKey">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailSubmission_fields_reportType }}
						</dt>
						<dd role="none">
							{{
								i18nService.get(
									'airsEntities_emailSubmission_fields_reportType_' +
										emailSubmission.reportTypeI18nKey,
									null,
									true
								) || emailSubmission.reportTypeI18nKey
							}}
						</dd>
					</ng-container>
					<ng-container *ngIf="emailSubmission.submissionId">
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailSubmission_fields_submissionId }}
						</dt>
						<dd role="none">{{ emailSubmission.submissionId }}</dd>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="entity.senderIp">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_senderIp }}</dt>
					<dd role="none">{{ entity.senderIp }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.internetMessageId">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_internetMessageId }}
					</dt>
					<dd role="none">{{ entity.internetMessageId }}</dd>
				</ng-container>
				<dt role="none">
					{{ i18nService.strings.airsEntities_emailMessage_fields_attachments }}
				</dt>
				<dd role="none">
					<email-entity-attachments-field
						[attachments]="entity.attachments"
					></email-entity-attachments-field>
				</dd>
				<ng-container *ngIf="data?.metadata?.potentiallyHarmfulUrls">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_urls_and_threats }}
					</dt>
					<dd role="none">
						<email-entity-urls-field
							[urls]="entity.urls"
							[potentiallyHarmfulUrls]="data.metadata.potentiallyHarmfulUrls"
						></email-entity-urls-field>
					</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.originalDeliveryLocation">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_deliveryLocation }}
					</dt>
					<dd role="none">
						{{
							'airsEntities_emailMessage_deliveryLocation_' +
								data.metadata.originalDeliveryLocation | i18n
						}}
					</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.latestDeliveryLocation">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_latestDeliveryLocation }}
					</dt>
					<dd role="none">
						{{
							'airsEntities_emailMessage_deliveryLocation_' +
								data.metadata.latestDeliveryLocation | i18n
						}}
					</dd>
				</ng-container>
				<ng-container
					*ngIf="threatsDisplayNameService.getThreatsDisplayName(entity.threats) as threatsDisplay"
				>
					<dt role="none">{{ i18nService.strings.airsEntities_general_fields_threats }}</dt>
					<dd role="none">{{ threatsDisplay }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.attachmentFileNames">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_attachmentFileNames }}
					</dt>
					<dd role="none">{{ entity.attachmentFileNames }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.attachmentFileHashes">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_attachmentSha256 }}
					</dt>
					<dd role="none">{{ entity.attachmentFileHashes }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.emailHeaders">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_emailHeaders }}
					</dt>
					<dd role="none">{{ entity.emailHeaders }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.emailDownload">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_emailDownload }}
					</dt>
					<dd role="none">{{ entity.emailDownload }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.networkMessageId">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_networkMessageId }}
					</dt>
					<dd role="none">{{ entity.networkMessageId }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.deliveryAction">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_deliveryAction }}
					</dt>
					<dd role="none">{{ entity.deliveryAction }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.clusterId">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_clusterId }}</dt>
					<dd role="none">{{ entity.clusterId }}</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.senderDisplayName">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_senderDisplayName }}
					</dt>
					<dd role="none">{{ data.metadata.senderDisplayName }}</dd>
				</ng-container>
				<ng-container
					*ngIf="
						data?.metadata &&
						emailDetectionTechnologiesDisplayNameService.getEmailThreatDetectionMethodsDisplayName(
							data.metadata
						) as detectionMethodsDisplay
					"
				>
					<dt role="none">
						{{
							i18nService.strings.airsEntities_emailMessage_fields_threatsDetectionTechnologies
						}}
					</dt>
					<dd role="none">{{ detectionMethodsDisplay }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.attachmentMalwareFamily">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_attachmentMalwareFamily }}
					</dt>
					<dd role="none">{{ entity.attachmentMalwareFamily }}</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.language">
					<dt role="none">{{ i18nService.strings.airsEntities_emailMessage_fields_language }}</dt>
					<dd role="none">{{ data.metadata.language }}</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.returnPath">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_returnPath }}
					</dt>
					<dd role="none">{{ data.metadata.returnPath }}</dd>
				</ng-container>
				<ng-container *ngIf="data?.metadata?.directionalityI18nKey">
					<dt role="none">
						{{ i18nService.strings.airsEntities_emailMessage_fields_directionality }}
					</dt>
					<dd role="none">
						{{
							'airsEntities_emailMessage_antispamDirection_' +
								data.metadata.directionalityI18nKey | i18n
						}}
					</dd>
				</ng-container>
			</dl>
			<div *ngIf="emailDetailsLink" class="wcd-padding-vertical">
				<a [href]="emailDetailsLink" target="_blank">
					{{ i18nService.strings.airsEntities_emailMessage_emailDetailsLink }}
					<fab-icon iconName="OpenInNewWindow" className="small-icon"></fab-icon>
				</a>
			</div>
			<ng-container
				*ngIf="data?.metadata === LOADING_SYMBOL || data?.stateData === LOADING_SYMBOL"
				[ngTemplateOutlet]="loader"
			>
			</ng-container>
			<ng-container *ngIf="data?.stateData as stateData">
				<ng-container *ngIf="stateData?.quarantineMessage; else noQuarantineView">
					<div>
						<fab-primary-button
							(onClick)="openQuarantineEmailHeadersPanel(stateData.quarantineMessage)"
							[text]="i18nService.strings.airsEntities_email_headers_panel_button"
							[iconProps]="{ iconName: 'Header' }"
						>
						</fab-primary-button>
						<fab-primary-button
							*ngIf="stateData?.isQuarantineViewAllowed && data?.metadata?.internetMessageId"
							(onClick)="goToQuarantinePage(data?.metadata?.internetMessageId)"
							[iconProps]="{ iconName: 'OpenInNewWindow' }"
							[text]="i18nService.strings.airsEntities_emailMessage_viewQuarantinedEmail_text"
						>
						</fab-primary-button>
					</div>
				</ng-container>
				<ng-template #noQuarantineView>
					<div *ngIf="data.metadata?.deliveredToMailbox">
						<fab-primary-button
							(onClick)="openEmailHeadersPanel(data.metadata)"
							[text]="i18nService.strings.airsEntities_email_headers_panel_button"
							[iconProps]="{ iconName: 'Header' }"
						>
						</fab-primary-button>
						<fab-primary-button
							*ngIf="stateData?.isInPreview"
							(onClick)="openDownloadEmailPanel(data.metadata)"
							[iconProps]="{
								iconName: 'Download'
							}"
							[text]="i18nService.strings.airsEntities_emailMessage_downloadEmail_text"
						>
						</fab-primary-button>
					</div>
				</ng-template>
			</ng-container>
		</ng-container>
		<ng-template #loader>
			<div class="wcd-flex-center-all wcd-padding-all">
				<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
			</div>
		</ng-template>
	`,
})
export class AirsEmailMessageDetailsComponent implements OnChanges, AfterViewInit {
	LOADING_SYMBOL = LOADING_SYMBOL;
	SpinnerSize = SpinnerSize;

	emailDetailsLink: string;
	private destroy$ = new Subject<void>();
	private roles$ = defer(() =>
		from(sccHostService.auth.isInRoles(['ucc:Preview///UserPermissions:MDO:microsoft.xdr/secops/rawdata/emailcontent/read', 'QuarantineViewAdmin']))
	).pipe(
		map(([isInPreview, isQuarantineViewAllowed]) => ({ isInPreview, isQuarantineViewAllowed })),
		shareReplay(1),
		takeUntil(this.destroy$)
	);
	private currentEmailSubject$ = new Subject<EmailDetails>();
	private currentEmail$ = this.currentEmailSubject$.asObservable().pipe(
		share(),
		distinctUntilChanged((d1, d2) => isEqual(d1, d2))
	);
	isEmailSubmission: boolean = false;
	metadata$: Observable<EmailMetadata | typeof LOADING_SYMBOL> = this.currentEmail$.pipe(
		switchMap(currentEmail => {
			if (!(currentEmail && currentEmail.networkMessageId)) {
				return of(null);
			}
			return this.paris
				.apiCall(EmailMetadataApiCall, {
					tenantId: sccHostService.loginUser.tenantId,
					recipient: currentEmail.recipient,
					networkMessageId: currentEmail.networkMessageId,
					startTime: currentEmail.receivedTime,
					endTime: currentEmail.receivedTime,
				})
				.pipe(
					map(metadata => metadata && metadata.length && metadata[0]),
					startWith(LOADING_SYMBOL)
				);
		}),
		catchError(err => of(null)),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	// adapted from Nibiru (MTPEmailEntityPage.tsx)
	emailData$: Observable<
		| {
			quarantineMessage?: QuarantineMessage;
			isQuarantineViewAllowed: boolean;
			isInPreview: boolean;
		}
		| typeof LOADING_SYMBOL
	> = this.currentEmail$.pipe(
		switchMap(currentEmail => {
			if (!currentEmail) {
				return of(null);
			}
			return combineLatest([
				this.roles$,
				this.metadata$.pipe(filter(metadata => metadata !== LOADING_SYMBOL)) as Observable<
					EmailMetadata
				>,
			]).pipe(
				switchMap(([roles, metadata]) => {
					if (
						!metadata ||
						!metadata.internetMessageId ||
						!metadata.receivedTime ||
						metadata.isDelivered
					) {
						return of({ ...roles, quarantineMessage: null });
					}

					// using Angular's http client because Paris doesn't pass the x-xsrf-token header
					return this.http
						.patch('/api/QuarantineMessage/QueryMessage', {
							quarantineTypes: ['TransportRule', 'Bulk', 'Phish', 'Malware', 'Spam'],
							policyTypes: [
								'AntiMalwarePolicy',
								'SafeAttachmentPolicy',
								'AntiPhishPolicy',
								'HostedContentFilterPolicy',
								'TransportRule',
							],
							messageId: metadata.internetMessageId,
							receivedStartDateTime: new Date(
								metadata.receivedTime.valueOf() - 10 * 60 * 1000
							).toISOString(),
							receivedEndDateTime: new Date(
								metadata.receivedTime.valueOf() + 10 * 60 * 1000
							).toISOString(),
						})
						.pipe(
							catchError(err => of(null)),
							map(quarantined => ({
								...roles,
								quarantineMessage: quarantined && quarantined.length && quarantined[0],
							}))
						);
				}),
				startWith(LOADING_SYMBOL),
				catchError(err => of(null))
			);
		})
	);

	@Input() entity: AirsEmail;

	constructor(
		private paris: Paris,
		public i18nService: I18nService,
		private dialogsService: DialogsService,
		private http: HttpClient,
		private appContextService: AppContextService,
		private featuresService: FeaturesService,
		public threatsDisplayNameService: ThreatsDisplayNameService,
		public emailDetectionTechnologiesDisplayNameService: EmailDetectionTechnologiesDisplayNameService
	) {}

	get emailSubmission(): AirsEmailSubmission {
		return this.entity as AirsEmailSubmission;
	}

	ngAfterViewInit() {
		this.setEmail();
	}

	ngOnChanges(changes) {
		this.setEmail();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private setEmail() {
		this.isEmailSubmission =
			this.entity && this.entity.type && this.entity.type.id === AirsEntityType.SubmissionMail;
		const receivedTime = this.isEmailSubmission
			? (<AirsEmailSubmission>this.entity).timestamp
			: this.entity.receivedDate;
		this.currentEmailSubject$.next(
			this.entity && {
				networkMessageId: this.entity.networkMessageId,
				recipient: this.entity.recipient,
				receivedTime: receivedTime,
			}
		);

		if (
			this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
			this.appContextService.isSCC &&
			receivedTime
		) {
			const receivedTimeStr = receivedTime.toISOString();
			const pathNames = window.location.pathname.split('/');
			const page = pathNames.length > 2 ? pathNames[1] : 'mtp';
			this.emailDetailsLink = `/emailentity?f=${page}&startTime=${receivedTimeStr}&endTime=${receivedTimeStr}&id=${this.entity.networkMessageId
				}&recipient=${this.entity.recipient}`;
		} else {
			this.emailDetailsLink = null;
		}
	}

	openDownloadEmailPanel(emailMetadata: EmailMetadata) {
		this.dialogsService
			.showPanel(
				DownloadEmailPanelComponent,
				{
					id: 'email-download-panel',
					type: PanelType.large,
					isModal: true,
					showOverlay: false,
					isBlocking: true,
					scrollBody: true,
				},
				{ emailMetadata }
			)
			.toPromise();
	}

	goToQuarantinePage(internetMessageId: string) {
		window.open(`#/quarantine?messageParams=${internetMessageId}`, '_blank');
	}

	openQuarantineEmailHeadersPanel(quarantineMessage: QuarantineMessage) {
		this.openHeadersPanel({
			quarantineEmail: { identity: quarantineMessage && quarantineMessage.identity },
		});
	}

	openEmailHeadersPanel(metadata: EmailMetadata) {
		this.openHeadersPanel({
			email: {
				internetMessageId: metadata.internetMessageId,
				mailboxId: this.entity.recipient,
			},
		});
	}

	private openHeadersPanel(
		headersObj: XOR<
			{ email: { internetMessageId: string; mailboxId: string } },
			{ quarantineEmail: QuarantineMessage }
		>
	) {
		this.dialogsService
			.showPanel(
				EmailHeadersPanelComponent,
				{
					id: 'email-headers-panel',
					type: PanelType.large,
					isModal: true,
					showOverlay: false,
					isBlocking: true,
					scrollBody: false,
				},
				{ headersObj }
			)
			.toPromise();
	}
}
