import { EntityField, EntityModelBase } from '@microsoft/paris';
import { Machine } from '../machine/machine.entity';
import { StatusModel } from '../status.model';
import { AlertClassification } from './alert-classification.entity';
import { AlertDetermination } from './alert-determination.entity';
import { AlertUser } from './alert-user.value-object';
import { Severity } from '../severity/severity.entity';
import { MtpWorkload } from '../rbac/mtp-workload-enum';
import { InvestigationStatusesMapping } from './alert-investiation-status-mapping.enum';
import { InvestigationStatus } from '../investigation/investigation-status.entity';
import { isNil } from 'lodash-es';
import { OAuthAlertPageLinkDetails } from './alert-oAuth-alertPage-link-details.value-object';

export abstract class AlertBase extends EntityModelBase {
	@EntityField({ data: 'Title' })
	name: string;

	@EntityField({ data: 'Severity' })
	severity: Severity;

	@EntityField({ data: 'Classification', parse: (id) => (id === 0xff || isNil(id) ? null : String(id)) })
	classification: AlertClassification;

	@EntityField({ data: 'Classification', parse: (value) => value === 0xff })
	isMultipleClassifications: boolean;

	@EntityField({ data: 'FirstSeen' })
	firstSeen: Date;

	@EntityField({ data: 'FirstEventTime' })
	firstEventTime: Date;

	@EntityField({ data: 'LastSeen' })
	lastSeen: Date;

	@EntityField({ data: 'LastEventTime' })
	lastEventTime: Date;

	@EntityField({ data: 'Categories' })
	categories: Array<string>;

	@EntityField({
		data: 'Category',
		parse: (category) => category && category.replace(/([a-z])([A-Z])/g, '$1 $2'),
	})
	private _category: string;

	@EntityField({
		data: 'Determination',
		parse: (id) => (id === 0x7fffffff || id === 0 ? null : id && String(id)),
	})
	determination: AlertDetermination;

	@EntityField({ data: 'Determination', parse: (value) => value === 0x7fffffff })
	isMultipleDeterminations: boolean;

	abstract status: StatusModel<number>;

	@EntityField({
		data: 'Status',
		parse: (status: number) => {
			if (!status) return false;

			const statusLog = Math.log2(status);
			return statusLog !== Math.floor(statusLog);
		},
	})
	isMultipleStatuses: boolean;

	@EntityField({ data: 'AssignedTo' })
	assignedTo: string;

	@EntityField({ data: 'AssignedToCount' })
	assignedToCount: number;

	@EntityField({
		data: '__self',
		require: (data) => data.MachineId || data.SenseMachineId || data.ComputerDnsName,
	})
	machine?: Machine;

	@EntityField({ data: 'MachineCount' })
	machineCount: number;

	@EntityField({ data: '__self', require: (data) => data.UserSid || data.AccountSid })
	user: AlertUser;

	@EntityField({ data: 'OAuthAlertPageLinkDetails' })
	oAuthAlertPageLinkDetails: OAuthAlertPageLinkDetails;

	@EntityField({ data: 'UserCount' })
	userCount: number;

	@EntityField({ data: 'MailboxCount' })
	mailboxCount?: number;

	@EntityField({ data: 'MtpWorkloads' })
	mtpWorkloads: Array<MtpWorkload>;

	@EntityField({
		data: 'InvestigationState',
		parse: (state) => InvestigationStatusesMapping[state],
	})
	investigationStatus: InvestigationStatus;
}
