var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TabModel } from '../../../../../../shared/components/tabs/tab.model';
import { EntityComponentBase } from '../../../../../../global_entities/components/entity-contents.component.base';
import { I18nService } from '@wcd/i18n';
var ProfileEntityComponent = /** @class */ (function (_super) {
    __extends(ProfileEntityComponent, _super);
    function ProfileEntityComponent(i18nService) {
        var _this = _super.call(this) || this;
        _this.i18nService = i18nService;
        _this.tabs = [];
        _this.tabs = _this.getTabs();
        return _this;
    }
    ProfileEntityComponent.prototype.getTabs = function () {
        return [
            {
                id: 'configurations',
                name: this.i18nService.strings.tvm_baseline_profile_entity_configurationTab_title,
                routerLink: './configurations',
            },
            {
                id: 'devices',
                name: this.i18nService.strings.tvm_baseline_profile_entity_devicesTab_title,
                routerLink: './devices',
            },
        ].map(function (tab) { return new TabModel(tab); });
    };
    return ProfileEntityComponent;
}(EntityComponentBase));
export { ProfileEntityComponent };
