import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { UrlHandlingStrategy } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { initializeIcons, loadTheme } from 'office-ui-fabric-react';
import { AppComponent } from './app.component';
import { PreloadService } from './preloader/services/preload.service';
import { SevilleUpgradeModule } from './_seville/seville-upgrade.module';
import { Ng1Ng2UrlHandlingStrategy } from './_seville/url-handling-strategy';
import { registerLocales } from './app.locales';
import { SevilleDowngradeUtilsService } from './_seville/seville.downgrade-utils.service';
import { m365Theme } from './shared/models/colors/m365-colors-palette.model';
import { AppModuleCommons, imports, providers } from './app.module.commons';
import { SevilleAppDirective } from './_seville/app/seville/seville-app.component';
import { AppRoutingModule } from './app-routing.module';
import { MainNavConfigService } from './shared/main/services/main-nav-config.service';
import { setUpLocationSync } from '@angular/router/upgrade';
import { MainHeaderComponent } from './shared/main/components/main-header.component';
import { MainNavComponent } from './shared/main/components/main-nav.component';
import { MainNavEnabledPipe } from './shared/main/components/main-nav-enabled.pipe';
import { SearchModule } from './search/search.module';
import { DashboardsModule } from './dashboards/dashboards.module';
import { TvmModule } from './tvm/tvm.module';
import { BreadcrumbsModule } from './breadcrumbs/breadcrumbs.module';
import { OutbreakEntityTypeModule } from './@entities/outbreaks/outbreak.entity-type.module';
import { WcdSharedModule } from '@wcd/shared';
import { OutbreakService } from './dashboards/threat-analytics3/services/outbreak.service';
import { Paris } from '@microsoft/paris';
import { APC_HEADER_KEY, AuthService } from '@wcd/auth';
import { FeaturesService, PreferencesService } from '@wcd/config';
import { ServiceUrlsService } from '@wcd/app-config';
import { WcdPortalParisConfigData } from '@wcd/domain';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Http429Interceptor } from './shared/interceptors/http429.interceptor';
import { SupportCentralModule } from './support-central/support-central.module';
import { getPortalLanguage } from '@wcd/i18n';

export const appInit = (preloadService: PreloadService, injector: Injector) => () => {
	return preloadService.preload$.toPromise();
};

registerLocales();

@NgModule({
	imports: [
		...imports,
		AppModuleCommons,
		AppRoutingModule,
		UpgradeModule,
		SearchModule,
		DashboardsModule,
		TvmModule,
		BreadcrumbsModule,
		OutbreakEntityTypeModule,
		SupportCentralModule,
		WcdSharedModule.forRoot([]),
	],
	declarations: [
		AppComponent,
		SevilleAppDirective,
		MainHeaderComponent,
		MainNavComponent,
		MainNavEnabledPipe,
	],
	providers: [
		...providers,
		OutbreakService,
		PreloadService,
		{ provide: UrlHandlingStrategy, useClass: Ng1Ng2UrlHandlingStrategy },
		{
			provide: APP_INITIALIZER,
			useFactory: appInit,
			deps: [PreloadService, Injector],
			multi: true,
		},
		{
			provide: Paris,
			useFactory: (
				auth: AuthService,
				serviceUrlsService: ServiceUrlsService,
			) => {
				const headers = {
					get authorization() {
						return `Bearer ${auth.token}`;
					},
					get 'Tenant-Id'() {
						return auth.tenantId;
					},
					get [APC_HEADER_KEY]() {
						return auth.apcHeader;
					},
					get 'Accept-Language'() {
						return getPortalLanguage();
					},
				};

				return new Paris<WcdPortalParisConfigData>({
					apiRoot: '/api',
					data: {
						serviceUrls: serviceUrlsService,
					},
					http: {
						headers,
						withCredentials: true,
					},
				});
			},
			deps: [AuthService, ServiceUrlsService, FeaturesService],
		},
		{ provide: HTTP_INTERCEPTORS, useClass: Http429Interceptor, multi: true },
		SevilleDowngradeUtilsService,
		MainNavConfigService,
		{ provide: PreferencesService, useClass: PreferencesService },
	],
	entryComponents: [AppComponent],
})
export class AppModule {
	constructor(private upgrade: UpgradeModule) {
		initializeIcons('../assets/fonts/fabric-icons/');

		//@ts-ignore
		loadTheme(m365Theme);
	}

	ngDoBootstrap() {
		this.upgrade.bootstrap(document.body, [SevilleUpgradeModule.name], {
			strictDi: true,
		});
		setUpLocationSync(this.upgrade);

	}
}
