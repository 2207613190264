/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i2 from "../../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i6 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "./recommendation-context.component";
import * as i8 from "../../../../tvm/services/tvm-recommendation-context.service";
var styles_RecommendationContextComponent = [];
var RenderType_RecommendationContextComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RecommendationContextComponent, data: {} });
export { RenderType_RecommendationContextComponent as RenderType_RecommendationContextComponent };
function View_RecommendationContextComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), ("tvm.securityRecommendation.recommendationContext." + _v.context.$implicit))); _ck(_v, 1, 0, currVal_0); }); }
function View_RecommendationContextComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recommendationContextIcon; _ck(_v, 0, 0, currVal_0); }); }
function View_RecommendationContextComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "td", [], [[8, "className", 0]], [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 147456, null, 0, i1.TooltipDirective, [i0.ElementRef, i2.TooltipsService, i3.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecommendationContextComponent_4)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(5, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.parent.context.$implicit[_v.context.$implicit]; _ck(_v, 1, 0, currVal_1); var currVal_2 = (_v.context.first && (_co.recommendationContextIcon !== "")); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.last ? "wcd-border-bottom wcd-font-size-s" : "wcd-border-bottom full-width-cell wcd-font-size-s"); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.parent.context.$implicit[_v.context.$implicit]; _ck(_v, 5, 0, currVal_3); }); }
function View_RecommendationContextComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecommendationContextComponent_3)), i0.ɵdid(3, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contextTableHeaders; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_RecommendationContextComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.I18nPipe, [i6.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 10, "table", [["class", "wcd-margin-large-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecommendationContextComponent_1)), i0.ɵdid(7, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecommendationContextComponent_2)), i0.ɵdid(11, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contextTableHeaders; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co._asset.recommendationContext; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_RecommendationContextComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "asset-recommnedation-context", [], null, null, null, View_RecommendationContextComponent_0, RenderType_RecommendationContextComponent)), i0.ɵdid(1, 49152, null, 0, i7.RecommendationContextComponent, [i8.TvmRecommendationContextService], null, null)], null, null); }
var RecommendationContextComponentNgFactory = i0.ɵccf("asset-recommnedation-context", i7.RecommendationContextComponent, View_RecommendationContextComponent_Host_0, { asset: "asset" }, {}, []);
export { RecommendationContextComponentNgFactory as RecommendationContextComponentNgFactory };
