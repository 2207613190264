import { Injectable } from '@angular/core';
import { PanelSettings } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { InvestigationAction, RemediationActionType } from '@wcd/domain';
import { Dictionary } from '@wcd/config';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { RemediationActionsService } from '../../remediation/services/remediation-actions.service';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { I18nService } from '@wcd/i18n';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class InvestigationActionsService {
	constructor(
		private dialogsService: DialogsService,
		private remediationActionsService: RemediationActionsService,
		private entityPanelService: EntityPanelsService,
		private i18nService: I18nService
	) {}

	private _investigationActionsItemActions: { [actionId: string]: ItemActionModel } = {
		approve: new ItemActionModel({
			id: 'approve',
			name: 'Approve',
			buttonClass: 'btn color-box-success',
			method: this.approveActions.bind(this),
			tooltip: 'Approve the selected pending actions',
		}),
		decline: new ItemActionModel({
			id: 'decline',
			name: 'Decline',
			buttonClass: 'btn color-box-error',
			method: this.declineActions.bind(this),
			tooltip: 'Decline the selected pending actions',
		}),
		retry: new ItemActionModel({
			id: 'retry',
			name: 'Retry Now',
			buttonClass: 'btn color-box-success',
			method: this.retryActions.bind(this),
			tooltip: 'Retry the selected actions',
		}),
	};

	getInvestigationActionActions(item: InvestigationAction): Array<ItemActionModel> {
		const itemActions: Array<ItemActionModel> = [];

		if (item.status.isRetriable && item.pendingType.isUserPending)
			itemActions.push(this._investigationActionsItemActions.retry);

		if (item.status.isPending && !item.status.isRetriable)
			itemActions.push(
				this._investigationActionsItemActions.approve,
				this._investigationActionsItemActions.decline
			);

		return itemActions;
	}

	showInvestigationAction(
		investigationAction: number | string | InvestigationAction,
		panelSettings?: PanelSettings,
		isOfficeAction?: boolean
	) {
		if (!investigationAction) {
			return;
		}
		const isActionId: boolean =
			typeof investigationAction === 'number' || typeof investigationAction === 'string';
		if (isActionId) {
			this.entityPanelService.showEntityById(
				InvestigationAction,
				investigationAction as string,
				{
					useOfficeApi: isOfficeAction,
					tenantId: sccHostService.loginUser.tenantId,
				},
				panelSettings
			);
		} else {
			this.entityPanelService.showEntity(
				InvestigationAction,
				<InvestigationAction>investigationAction,
				{ useOfficeApi: isOfficeAction },
				panelSettings
			);
		}
	}

	approveActions(actions: Array<InvestigationAction>): Promise<void> {
		const actionsByRemediationActionType: Array<{
			remediationActionType: RemediationActionType;
			actions: Array<InvestigationAction>;
		}> = this.groupActionsByRemediationTypes(actions);

		const remediationPromises: Array<Promise<any>> = actionsByRemediationActionType.map(
			(actionsByType: {
				remediationActionType: RemediationActionType;
				actions: Array<InvestigationAction>;
			}) => {
				return this.remediationActionsService.approvePendingActions(actionsByType.actions, false);
			}
		);

		return Promise.all(remediationPromises).then(() => {
			this.dialogsService.showSnackbar({
				icon: 'users.user_attention',
				text:
					actions && actions.length === 1
						? actions[0].remediationActionType.notificationText(actions)
						: 'The selected items were approved',
			});
		});
	}

	declineActions(actions: Array<InvestigationAction>): Promise<any> {
		const actionsByRemediationActionType: Array<{
			remediationActionType: RemediationActionType;
			actions: Array<InvestigationAction>;
		}> = this.groupActionsByRemediationTypes(actions);

		const remediationPromises: Array<Promise<any>> = actionsByRemediationActionType.map(
			(actionsByType: {
				remediationActionType: RemediationActionType;
				actions: Array<InvestigationAction>;
			}) => {
				return this.remediationActionsService.confirmAndDismissPendingActionsByType(
					actionsByType.actions,
					null,
					false
				);
			}
		);

		return Promise.all(remediationPromises).then((confirmations: Array<any>) => {
			if (confirmations.some(confirmation => confirmation !== false)) {
				this.dialogsService.showSnackbar({
					icon: 'users.user_attention',
					text:
						actions && actions.length === 1
							? `Declined action '${actions[0].remediationActionType.simpleName}'`
							: 'The selected items were declined',
				});
			}
		});
	}

	retryActions(actions: Array<InvestigationAction>): Promise<Array<InvestigationAction>> {
		return new Promise((resolve, reject) => {
			this.remediationActionsService.retryPendingActions(actions.map(action => action.id)).subscribe(
				() => {
					this.dialogsService.showSnackbar({
						text:
							actions && actions.length === 1
								? `Action '${actions[0].name}' was retried`
								: `${actions ? actions.length : 'The selected'} Actions were retried`,
						icon: 'refresh',
					});
					resolve(actions);
				},
				error => {
					const errorMessage = this.i18nService.strings.investigation_error_FailedRetryAction;

					this.dialogsService.showError({
						title: errorMessage,
						data: error,
					});
					reject(errorMessage);
				}
			);
		});
	}

	private groupActionsByRemediationTypes(
		actions: Array<InvestigationAction>
	): Array<{
		remediationActionType: RemediationActionType;
		actions: Array<InvestigationAction>;
	}> {
		const actionsByRemediationTypes: Dictionary<
			number,
			{ remediationActionType: RemediationActionType; actions: Array<InvestigationAction> }
		> = new Dictionary<
			number,
			{ remediationActionType: RemediationActionType; actions: Array<InvestigationAction> }
		>();

		actions.forEach((action: InvestigationAction) => {
			let remediationActionsByType: {
				remediationActionType: RemediationActionType;
				actions: Array<InvestigationAction>;
			} = actionsByRemediationTypes.get(<number>action.remediationActionType.id);

			if (!remediationActionsByType) {
				remediationActionsByType = {
					remediationActionType: action.remediationActionType,
					actions: [action],
				};
				actionsByRemediationTypes.set(
					<number>action.remediationActionType.id,
					remediationActionsByType
				);
			} else remediationActionsByType.actions.push(action);
		});

		return actionsByRemediationTypes.toArray();
	}
}
