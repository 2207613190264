var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FeaturesService, Feature } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';
import { EvaluationEnabledGuard } from '@wcd/auth';
import { ReportWidgetComponent } from '../../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../../shared-reports/services/reports.service';
import { MtpPromotionService } from '../../../../../../mtp-promotion/services/mtp-promotion.service';
import { of } from 'rxjs';
import { SCC_BASE_URL } from '../../../../../../shared/main/services/main-nav-config.service';
import { TryItUrls } from '../../../../../../mtp-promotion/enums/try-it-urls';
import { I18nService } from '@wcd/i18n';
var ExperienceMtpWidgetComponent = /** @class */ (function (_super) {
    __extends(ExperienceMtpWidgetComponent, _super);
    function ExperienceMtpWidgetComponent(reportsService, featuresService, mtpPromotionService, appConfigService, evaluationEnabledGuard, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.featuresService = featuresService;
        _this.mtpPromotionService = mtpPromotionService;
        _this.appConfigService = appConfigService;
        _this.evaluationEnabledGuard = evaluationEnabledGuard;
        _this.i18nService = i18nService;
        _this.incidentsUrl = SCC_BASE_URL + "/" + TryItUrls.Incidents;
        _this.simulationAndTutorialsEnabled = _this.featuresService.isEnabled(Feature.SimulationsAndTutorials);
        _this.evaluationEnabled = _this.evaluationEnabledGuard.canActivate();
        _this.dismiss = function () {
            _this.mtpPromotionService.dismissMtpPromotion(true);
            _this.widgetConfig$.next(_this.widgetConfig);
        };
        return _this;
    }
    Object.defineProperty(ExperienceMtpWidgetComponent.prototype, "widgetConfig", {
        get: function () {
            return {
                id: 'mtpPromotion',
                showHeader: true,
                isDisabled: !this.appConfigService.isMtpEligible || this.mtpPromotionService.mtpPromotionWidgetDismissed,
                name: this.i18nService.get('mtpPromotion.header'),
                hideOnNoData: true,
                loadData: function () { return of({ noData: '' }); },
            };
        },
        enumerable: true,
        configurable: true
    });
    return ExperienceMtpWidgetComponent;
}(ReportWidgetComponent));
export { ExperienceMtpWidgetComponent };
