import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';

export enum ThreatExposureType {
	'High' = 'High',
	'Medium' = 'Medium',
	'Low' = 'Low',
	'None' = 'None'
}

export const threatExposureSeverityValues: ThreatExposureSeverity[] = [
	{
		id: 'High',
		name: 'high',
		className: 'redDark',
		type: ThreatExposureType.High,
	},
	{
		id: 'Medium',
		name: 'medium',
		className: 'red',
		type: ThreatExposureType.Medium,
	},
	{
		id: 'Low',
		name: 'low',
		className: 'orangeLight',
		type: ThreatExposureType.Low,
	},
	{
		id: 'None',
		name: 'None',
		type: ThreatExposureType.None,
	},
];

@Entity({
	singularName: 'Threat exposure severity',
	pluralName: 'Threat exposure severity',
	values: threatExposureSeverityValues,
})
export class ThreatExposureSeverity extends StatusModel<string, ThreatExposureType> {
	@EntityField() type: ThreatExposureType;
}
