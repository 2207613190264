import { NgModule } from '@angular/core';
import { SupportCentralComponent } from './support-central.component';
import { FabButtonModule } from '@angular-react/fabric';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@wcd/i18n';

@NgModule({
	imports: [FabButtonModule, CommonModule, I18nModule],
	declarations: [SupportCentralComponent],
	providers: [],
	exports: [SupportCentralComponent],
})
export class SupportCentralModule {}
