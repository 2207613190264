import { Directive, ElementRef, EventEmitter, Output, AfterViewInit, Input } from '@angular/core';
import { fromEvent, Subscription, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

export interface VisibilityChange {
	elementRef: ElementRef;
	visible: boolean;
}

@Directive({
	selector: '[wcdVisibility]',
})
export class VisibilityDirective implements AfterViewInit {
	private _subscription: Subscription;
	private _refreshSubscription: Subscription;

	private _lastState: boolean = null;

	@Output() onVisibilityChange: EventEmitter<VisibilityChange> = new EventEmitter<VisibilityChange>();

	@Input() refresh$: Observable<boolean> = new Observable<boolean>();

	constructor(private el: ElementRef) {
		this._subscription = fromEvent(window, 'resize')
			.pipe(
				debounceTime(100),
				map(e => true)
			)
			.subscribe(e => this.checkVisibility());
	}

	ngAfterViewInit(): void {
		setTimeout(() => this.checkVisibility(), 100);
		setTimeout(() => this.checkVisibility(), 500); // another test for safety
		this._refreshSubscription = this.refresh$.subscribe(e => this.checkVisibility());
	}

	private checkVisibility() {
		const visibility = this.isVisible(this.el.nativeElement);
		if (this._lastState === null || this._lastState !== visibility) {
			this._lastState = visibility;
			this.onVisibilityChange.emit({ elementRef: this.el, visible: visibility });
		}
	}

	private isVisible(elem: HTMLElement): boolean {
		const style = getComputedStyle(elem);

		if (style.display === 'none') return false;
		if (style.visibility !== 'visible') return false;
		if ((style.opacity as any) === 0) return false;

		if (
			elem.offsetWidth +
				elem.offsetHeight +
				elem.getBoundingClientRect().height +
				elem.getBoundingClientRect().width ===
			0
		)
			return false;

		// we will make sure that at least one of these 5 points are visible:
		const elementPoints = {
			center: {
				x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
				y: elem.getBoundingClientRect().top + elem.offsetHeight / 2,
			},
			topLeft: {
				x: elem.getBoundingClientRect().left,
				y: elem.getBoundingClientRect().top,
			},
			topRight: {
				x: elem.getBoundingClientRect().right,
				y: elem.getBoundingClientRect().top,
			},
			bottomLeft: {
				x: elem.getBoundingClientRect().left,
				y: elem.getBoundingClientRect().bottom,
			},
			bottomRight: {
				x: elem.getBoundingClientRect().right,
				y: elem.getBoundingClientRect().bottom,
			},
		};

		const docWidth = document.documentElement.clientWidth || window.innerWidth;
		const docHeight = document.documentElement.clientHeight || window.innerHeight;

		// checking cases where the el is entirely out of viewport:
		if (elementPoints.topLeft.x + 2 > docWidth) return false;
		if (elementPoints.topLeft.y + 2 > docHeight) return false;
		if (elementPoints.bottomRight.x - 2 < 0) return false;
		if (elementPoints.bottomRight.y - 2 < 0) return false;

		// for every point we check:
		/// we get the top most element in that point and if it is our element than it's visible.
		/// else, we iterate through the parents element. if one of them is our element, we can conclude it's visible.
		for (const index in elementPoints) {
			const point = elementPoints[index];
			let pointContainer = document.elementFromPoint(point.x, point.y);
			if (pointContainer !== null) {
				do {
					if (pointContainer === elem) return true; //returning in for..in... returns the control to the calling function with the returned value
				} while ((pointContainer = pointContainer.parentNode as any));
			}
		}
		return false;
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
		this._refreshSubscription && this._refreshSubscription.unsubscribe();
	}
}
