import {Entity, EntityField, EntityGetMethod, EntityModelBase} from '@microsoft/paris';
import {WcdPortalParisConfig} from '../../paris-config.interface';
import {AlertClassification} from '../alert-classification.entity';
import {AlertDetermination} from '../alert-determination.entity';
import {AlertStatus} from '../alert-status/alert-status.entity';
import {AlertHistoryItemType} from './alert-history-item-type.entity';

@Entity({
	singularName: 'Alert History Item',
	pluralName: 'Alert History Items',
	endpoint: 'AuditHistory',
	fixedData: { pageIndex: 1, pageSize: 100 },
	allItemsEndpointTrailingSlash: false,
	supportedEntityGetMethods: [EntityGetMethod.query],
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.auditHistory,
	parseData: (data) => ({
			items: data,
			count: data.length
		})
})
export class AlertHistoryItem extends EntityModelBase {
	@EntityField({ data: ['AuditId', 'auditId'] })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: ['Type', 'type'] })
	type: AlertHistoryItemType;

	@EntityField({ data: ['Timestamp', 'timestamp'] })
	timestamp: Date;

	@EntityField({ data: ['CreatedBy', 'createdBy'] })
	userName: string;

	@EntityField({ data: ['OldValue', 'oldValue'] })
	oldValue: any;

	@EntityField({ data: ['NewValue', 'newValue'] })
	newValue: any;

	@EntityField({
		data: ['OldValue', 'oldValue'],
		parse: (oldValue, data) => (data.Type === 'Status' ? parseInt(oldValue) : null),
	})
	oldStatus: AlertStatus;

	@EntityField({
		data: ['OldValue', 'oldValue'],
		parse: (oldValue, data) => (data.Type === 'Determination' ? oldValue : null),
	})
	oldDetermination: AlertDetermination;

	@EntityField({
		data: ['OldValue', 'oldValue'],
		parse: (oldValue, data) => (data.Type === 'Classification' ? oldValue : null),
	})
	oldClassification: AlertClassification;

	@EntityField({
		data: ['NewValue', 'newValue'],
		parse: (oldValue, data) => (data.Type === 'Status' ? parseInt(oldValue) : null),
	})
	newStatus: AlertStatus;

	@EntityField({
		data: ['NewValue', 'newValue'],
		parse: (oldValue, data) => (data.Type === 'Determination' ? oldValue : null),
	})
	newDetermination: AlertDetermination;

	@EntityField({
		data: ['NewValue', 'newValue'],
		parse: (oldValue, data) => (data.Type === 'Classification' ? oldValue : null),
	})
	newClassification: AlertClassification;

	@EntityField({ data: ['EntityType', 'entityType'] })
	entityType: string;
}
