import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const VERIFICATION_COOKIE = 'cookieVerifier';

export class CookiesService {
	private _cookiesEnabled: boolean;

	constructor(@Inject(DOCUMENT) private document) {}

	// check if the browser has cookies enabled. This approach should work for most browsers:
	// https://stackoverflow.com/questions/4603289/how-to-detect-that-javascript-and-or-cookies-are-disabled
	get cookiesEnabled(): boolean {
		if (typeof this._cookiesEnabled === 'undefined') {
			this._cookiesEnabled = navigator.cookieEnabled;
			if (!this._cookiesEnabled) {
				const oldCookie: string = this.document.cookie;
				this.document.cookie = VERIFICATION_COOKIE;
				this._cookiesEnabled = this.document.cookie.includes(VERIFICATION_COOKIE);
				this.document.cookie = oldCookie;
			}
		}

		return this._cookiesEnabled;
	}

	private getAllCookies(): Map<string, string> {
		return new Map(this.document.cookie && this.document.cookie.split(';').map(c => c.trim().split('=')));
	}

	getCookie(cookieName: string): string {
		return this.getAllCookies().get(cookieName);
	}
}
