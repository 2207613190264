import {
	EntityRelationship,
	RelationshipType,
	EntityRelationshipRepositoryType,
	DataQuery,
} from '@microsoft/paris';
import { Asset } from '../asset/asset.entity';
import { ParseDataQuery, FilterByNameInsteadOfSearch } from '../analyticsEndPointUtils';
import { MissingKb } from './missing-kb.entity';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';

@EntityRelationship({
	sourceEntity: MissingKb,
	dataEntity: Asset,
	endpoint: (_, query) =>
		query.where['osVersion']
			? `products/product/missingkbs/${query.where['productFixId']}/${query.where['osVersion']}/Assets`
			: `products/product/missingkbs/${query.where['productFixId']}/Assets`,
	allowedTypes: [RelationshipType.OneToMany],
	allItemsEndpointTrailingSlash: false,
	getRelationshipData: (missingKb: MissingKb) => {
		return {
			productId: missingKb.productId,
			productFixId: missingKb.productFixId,
			osVersion: missingKb.osVersion,
		};
	},
	parseDataQuery: (dataQuery: DataQuery) => {
		// when searching for missing kb assets we want to use OData filters
		dataQuery = FilterByNameInsteadOfSearch(dataQuery);

		return ParseDataQuery(dataQuery);
	},
})
export class MissingKbExposedAssetsRelationship
	implements EntityRelationshipRepositoryType<MissingKb, Asset> {}

@EntityRelationship({
	sourceEntity: MissingKb,
	dataEntity: Vulnerability,
	endpoint: (_, query) =>
		`products/product/missingkbs/${encodeURIComponent(query.where['productFixId'])}/Vulnerabilities`,
	allowedTypes: [RelationshipType.OneToMany],
	allItemsEndpointTrailingSlash: false,
	getRelationshipData: (missingKb: MissingKb) => {
		return {
			productId: missingKb.productId,
			productFixId: missingKb.productFixId,
		};
	},
	parseDataQuery: (dataQuery: DataQuery) => ParseDataQuery(dataQuery),
})
export class MissingKbRelatedVulnerabilitiesRelationship
	implements EntityRelationshipRepositoryType<MissingKb, Vulnerability> {}
