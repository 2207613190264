<dl class="wcd-padding-small-horizontal" role="none">
    <dt class="info-label semi-bold">{{'users.entityDetails.sections.details.jobTitle.title' | i18n}}</dt>
    <dd>{{aadUser?.jobTitle || unavailableTxt}}</dd>
    <dt class="info-label semi-bold">{{'users.entityDetails.sections.details.department.title' | i18n}}</dt>
    <dd>{{aadUser?.department || unavailableTxt}}</dd>
    <dt class="info-label semi-bold">{{'users.entityDetails.sections.details.email.title' | i18n}}</dt>
    <dd>{{aadUser?.mail || unavailableTxt }}</dd>
</dl>



