import { Pipe, PipeTransform } from '@angular/core';
import { camelCase } from 'lodash-es';
import { LogonType } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Pipe({
	name: 'logonType',
})
export class LogonTypePipe implements PipeTransform {
	constructor(private readonly i18nService: I18nService) {}

	transform(value: LogonType, elaborateDisplay: boolean = false): string | null {
		if (value in LogonType) {
			const key = camelCase(value);
			const display = elaborateDisplay ? 'elaborated' : 'summary';
			return this.i18nService.get(`logonType.${key}.${display}`);
		}

		return this.i18nService.get('logonType.default');
	}
}
