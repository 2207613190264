import { Component, Input } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { FabricIconName } from '@wcd/scc-common';

@Component({
	selector: 'data-list-panel',
	template: `
		<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings">
			<simple-data-list-table
				[dataList]="dataList"
				[openPanelForAllItems]="false"
				[shouldShowIcon]="shouldShowIcon"
				[iconName]="iconName"
				[fullPanelView]="true"
			></simple-data-list-table>
		</wcd-panel>
	`,
})
export class SimpleDataListPanelComponent extends PanelContainer {
	@Input() dataList: string[];
	@Input() shouldShowIcon: boolean = false;
	@Input() iconName: string | FabricIconName;

	constructor(protected router: Router) {
		super(router);
	}
}
