import { Injectable } from '@angular/core';
import { LocaleConfigService } from '@wcd/localization';
import { FormStyle, getLocaleDayNames, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { memoize } from 'lodash-es';
import { ICalendarStrings } from 'office-ui-fabric-react';
import { I18nService } from '@wcd/i18n';

export type LocaleDateStrings = Pick<
	ICalendarStrings,
	| 'months'
	| 'shortMonths'
	| 'days'
	| 'shortDays'
	| 'goToToday'
	| 'nextMonthAriaLabel'
	| 'prevMonthAriaLabel'
>;

/**
 * Helper service for calculating and getting date-related strings for the various components in this `NgModule`.
 *
 * TODO: If the values calculated here are needed outside of this module/package - move this to the `@wcd/localization`
 * package with it's own formatting (not `ICalendarStrings`) then proxy that through this service and massage the data
 * to fit `ICalendarStrings`.
 */
@Injectable()
export class DateTimeDisplayService {
	constructor(
		private readonly localeConfigService: LocaleConfigService,
		private readonly i18nService: I18nService
	) {
		this._getLocaleDateStrings = memoize(this._getLocaleDateStrings);
	}

	getDateStrings(locale?: string) {
		return this._getLocaleDateStrings(locale || this.localeConfigService.selectedLocale);
	}

	private _getLocaleDateStrings(locale: string): LocaleDateStrings {
		return {
			months: getLocaleMonthNames(locale, FormStyle.Format, TranslationWidth.Wide),
			shortMonths: getLocaleMonthNames(locale, FormStyle.Format, TranslationWidth.Abbreviated),
			days: getLocaleDayNames(locale, FormStyle.Format, TranslationWidth.Wide),
			shortDays: getLocaleDayNames(locale, FormStyle.Format, TranslationWidth.Narrow),
			goToToday: this.i18nService.get('datepicker.calendar.goToToday'),
			prevMonthAriaLabel: this.i18nService.get('datepicker.calendar.previousMonth'),
			nextMonthAriaLabel: this.i18nService.get('datepicker.calendar.nextMonth'),
		};
	}
}
