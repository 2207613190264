import { Mailbox, AirsEntityType } from '@wcd/domain';
import { Lazy } from '../../../utils/lazy';
import { MailboxEntityPanelComponent, } from '../components/mailbox.entity-panel.component';
import { MailboxEntityDetailsComponent } from '../../../global_entities/components/entity-details/mailbox-entity-details.component';
import { FabricIconNames } from '@wcd/scc-common';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { EMPTY, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { compact } from 'lodash-es';
import { GoHuntService } from '../../../hunting-go-hunt/services/go-hunt.service';
import { FeaturesService, Feature } from '@wcd/config';
var sccRedirectFunc = function (path) { return path ? "/threatexplorer?" + path.split('threatexplorer&')[1] : ''; };
var ɵ0 = sccRedirectFunc;
var MailboxEntityTypeService = /** @class */ (function () {
    function MailboxEntityTypeService(goHuntService, featuresService) {
        var _this = this;
        this.goHuntService = goHuntService;
        this.featuresService = featuresService;
        this._entityType = new Lazy(function () { return ({
            entity: Mailbox,
            id: 'mailbox',
            icon: FabricIconNames.Inbox,
            entityDetailsComponentType: MailboxEntityDetailsComponent,
            singleEntityPanelComponentType: MailboxEntityPanelComponent,
            getEntityName: function (mailbox) { return mailbox.id; },
            entityPluralNameKey: 'mailbox_entityType_pluralName',
            entitySingularNameKey: 'mailbox_entityType_singularName',
            getEntitiesLink: function (mailboxes) {
                if (!mailboxes || mailboxes.length !== 1) {
                    return null;
                }
                var mailbox = mailboxes[0];
                return _this.featuresService.isEnabled(Feature.PortedSccPages) && mailbox.mailboxPageUrl ? sccRedirectFunc(mailbox.mailboxPageUrl) : mailbox.mailboxPageUrl;
            },
            getNavigationModel: function (mailbox) { return ({
                externalLink: mailbox.mailboxPageUrl,
                sccInternalRedirectDefinition: _this.featuresService.isEnabled(Feature.PortedSccPages) && mailbox.mailboxPageUrl ? sccRedirectFunc : undefined,
            }); },
            getUseExternalRouting: function (mailboxes) { return _this.featuresService.isEnabled(Feature.PortedSccPages) ? false : true; },
            loadFullEntityInPanel: false,
            getActions: function (mailboxes, options) {
                if (mailboxes.length !== 1) {
                    return EMPTY;
                }
                var actions = [];
                var mailbox = mailboxes[0];
                actions.push(_this.goHuntService.getGoHuntActionObservable(mailbox, AirsEntityType.Mailbox, {
                    incident: options && options.incident,
                }));
                return combineLatest(actions).pipe(map(function (_actions) {
                    return compact(_actions).map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); });
                }));
            },
        }); });
    }
    Object.defineProperty(MailboxEntityTypeService.prototype, "entityType", {
        get: function () {
            return this._entityType.value;
        },
        enumerable: true,
        configurable: true
    });
    return MailboxEntityTypeService;
}());
export { MailboxEntityTypeService };
export { ɵ0 };
