var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AirsEntityHuntingQueryApiCall, AirsEntityType, } from '@wcd/domain';
import { EMPTY } from 'rxjs';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { HUNTING_ROUTE } from '@wcd/shared';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { DEVICE_ENTITY_TYPE_GO_HUNT_ID, GoHuntQueryBuilder, } from './go-hunt-query-builder';
var GoHuntService = /** @class */ (function () {
    function GoHuntService(featuresService, router, appContextService, paris, appInsightService) {
        this.featuresService = featuresService;
        this.router = router;
        this.appContextService = appContextService;
        this.paris = paris;
        this.appInsightService = appInsightService;
        this.queryBuilder = new GoHuntQueryBuilder();
    }
    GoHuntService.prototype.getGoHuntActionObservable = function (entity, evidenceTypeId, options) {
        var _this = this;
        if (!this.featuresService.isAnyEnabled([
            Feature.EvidenceGoHuntActionMtp,
            Feature.EvidenceGoHuntAction,
        ])) {
            return EMPTY;
        }
        var huntingQueryRequest = this.queryBuilder.getAirsEntityHuntingRequest(entity, evidenceTypeId, options);
        var entityTypeTrackId = evidenceTypeId == DEVICE_ENTITY_TYPE_GO_HUNT_ID
            ? 'Device'
            : AirsEntityType[evidenceTypeId];
        var shouldCallVNextApi = this.featuresService.isEnabled(Feature.HuntingMigrateGoHuntFE);
        return !huntingQueryRequest
            ? EMPTY
            : this.paris
                .apiCall(AirsEntityHuntingQueryApiCall, __assign({}, huntingQueryRequest, { migrateToVNext: shouldCallVNextApi }))
                .pipe(map(function (evidenceGoHuntResponse) {
                if (!evidenceGoHuntResponse || (!evidenceGoHuntResponse.encodedHuntingQuery && !evidenceGoHuntResponse.allActivity)) {
                    _this.trackGoHunt(entityTypeTrackId, false);
                    return null;
                }
                _this.trackGoHunt(entityTypeTrackId, true);
                var queryText = evidenceGoHuntResponse.encodedHuntingQuery === undefined ?
                    evidenceGoHuntResponse.allActivity :
                    evidenceGoHuntResponse.encodedHuntingQuery;
                var queryParams = {
                    timeRangeId: "month" /* month */,
                    query: queryText,
                    runQuery: true,
                    queryResultsTrackId: "GoHuntQueryResults_" + entityTypeTrackId,
                };
                var tid = _this.getTenantIdUrlParam();
                if (tid != null && tid != '') {
                    queryParams['tid'] = tid;
                }
                return {
                    id: 'evidenceGoHunt',
                    nameKey: 'entities_panel_action_goHunt_buttonTitle',
                    icon: 'hunting',
                    href: _this.router.serializeUrl(_this.router.createUrlTree(["/" + HUNTING_ROUTE], { queryParams: queryParams })),
                    openLinkInNewTab: true,
                };
            }), catchError(function (err) {
                _this.appInsightService.trackException(err, 'evidenceGoHunt');
                return EMPTY;
            }));
    };
    GoHuntService.prototype.trackGoHunt = function (evidenceTypeTrackId, succeededCreatingGoHuntQuery) {
        this.appInsightService.trackEvent('GoHuntUsage', {
            EntityType: evidenceTypeTrackId,
            BESucceededCreatingGoHuntQuery: succeededCreatingGoHuntQuery,
        });
    };
    GoHuntService.prototype.getTenantIdUrlParam = function () {
        var urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('tid');
    };
    ;
    return GoHuntService;
}());
export { GoHuntService };
