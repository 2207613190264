var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
import { TvmAnalyticsSharedEntityRelationshipConfigurations } from '../analyticsEndPointUtils';
import { AssetMissingKb } from './assetMissingKb.entity';
var ɵ0 = function (_, query) { return "assets/" + encodeURIComponent(query.where['id']) + "/vulnerabilities"; }, ɵ1 = function (assetMissingKb) {
    return { id: assetMissingKb.assetId, productFixId: assetMissingKb.productFixId };
}, ɵ2 = function (dataQuery) {
    var query = TvmAnalyticsSharedEntityRelationshipConfigurations.parseDataQuery(dataQuery);
    var productFixId = dataQuery.where['productFixId'];
    if (productFixId) {
        query['missingKb'] = productFixId;
    }
    return query;
};
var AssetMissingKbVulnerabilityRelationship = /** @class */ (function () {
    function AssetMissingKbVulnerabilityRelationship() {
    }
    AssetMissingKbVulnerabilityRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: AssetMissingKb, dataEntity: Vulnerability, endpoint: ɵ0, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ1, parseDataQuery: ɵ2 }))
    ], AssetMissingKbVulnerabilityRelationship);
    return AssetMissingKbVulnerabilityRelationship;
}());
export { AssetMissingKbVulnerabilityRelationship };
export { ɵ0, ɵ1, ɵ2 };
