var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { userRoleActionValues, deprecatedUserRoleActionValues } from './user-role-action.values';
var MdeUserRoleAction = /** @class */ (function (_super) {
    __extends(MdeUserRoleAction, _super);
    function MdeUserRoleAction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MdeUserRoleAction_1 = MdeUserRoleAction;
    MdeUserRoleAction.flatten = function (userRoleActions) {
        return userRoleActions.reduce(function (accumulator, currentValue) {
            return currentValue.children
                ? accumulator.concat(currentValue.children) : accumulator.concat([currentValue]);
        }, []);
    };
    Object.defineProperty(MdeUserRoleAction.prototype, "helpKey", {
        get: function () {
            return MdeUserRoleAction_1.getHelpKey(this);
        },
        set: function (value) { },
        enumerable: true,
        configurable: true
    });
    // Fix for bug: children roles are not recognized as MdeUserRoleAction and therefore calling child.helpKey returns empty string
    MdeUserRoleAction.getHelpKey = function (userRoleAction) {
        return "userRoles.permissions." + userRoleAction.name;
    };
    var MdeUserRoleAction_1;
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], MdeUserRoleAction.prototype, "displayNameKey", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], MdeUserRoleAction.prototype, "displayName", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], MdeUserRoleAction.prototype, "name", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], MdeUserRoleAction.prototype, "disabled", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], MdeUserRoleAction.prototype, "hidden", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], MdeUserRoleAction.prototype, "flavorRequired", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], MdeUserRoleAction.prototype, "isAdmin", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], MdeUserRoleAction.prototype, "isLiveResponseAction", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], MdeUserRoleAction.prototype, "mandatory", void 0);
    __decorate([
        EntityField({ defaultValue: null }),
        __metadata("design:type", Array)
    ], MdeUserRoleAction.prototype, "children", void 0);
    __decorate([
        EntityField({ defaultValue: 0 }),
        __metadata("design:type", Number)
    ], MdeUserRoleAction.prototype, "additionalImplicitAllowedActions", void 0);
    __decorate([
        EntityField({ defaultValue: null }),
        __metadata("design:type", Object)
    ], MdeUserRoleAction.prototype, "featureFlags", void 0);
    MdeUserRoleAction = MdeUserRoleAction_1 = __decorate([
        Entity({
            singularName: 'User Role Action',
            pluralName: 'User Role Actions',
            values: userRoleActionValues,
        })
    ], MdeUserRoleAction);
    return MdeUserRoleAction;
}(EntityModelBase));
export { MdeUserRoleAction };
// Pre-tvm roles permissions model repository
var DeprecatedUserRoleAction = /** @class */ (function (_super) {
    __extends(DeprecatedUserRoleAction, _super);
    function DeprecatedUserRoleAction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeprecatedUserRoleAction = __decorate([
        Entity({
            singularName: 'User Role Action',
            pluralName: 'User Role Actions',
            values: deprecatedUserRoleActionValues,
        })
    ], DeprecatedUserRoleAction);
    return DeprecatedUserRoleAction;
}(MdeUserRoleAction));
export { DeprecatedUserRoleAction };
