/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./command-bar-custom-icon.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i3 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i4 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "./command-bar-custom-icon.component";
var styles_CommandBarCustomIconComponent = [i0.styles];
var RenderType_CommandBarCustomIconComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CommandBarCustomIconComponent, data: {} });
export { RenderType_CommandBarCustomIconComponent as RenderType_CommandBarCustomIconComponent };
export function View_CommandBarCustomIconComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 2, "wcd-shared-icon", [["class", "CommandBarCustomIcon"]], [[4, "color", null], [2, "command-bar-custom-icon-disabled", null]], null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(2, 573440, null, 0, i3.IconComponent, [i4.IconsService, i1.ChangeDetectorRef], { iconName: [0, "iconName"], ariaLabel: [1, "ariaLabel"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.iconName; var currVal_3 = ""; _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconColor; var currVal_1 = _co.disabled; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_CommandBarCustomIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "command-bar-custom-icon", [], null, null, null, View_CommandBarCustomIconComponent_0, RenderType_CommandBarCustomIconComponent)), i1.ɵdid(1, 114688, null, 0, i5.CommandBarCustomIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommandBarCustomIconComponentNgFactory = i1.ɵccf("command-bar-custom-icon", i5.CommandBarCustomIconComponent, View_CommandBarCustomIconComponent_Host_0, { contextualMenuItem: "contextualMenuItem" }, {}, []);
export { CommandBarCustomIconComponentNgFactory as CommandBarCustomIconComponentNgFactory };
