import { Component, Input } from '@angular/core';
import { StretchedDonutBarItem } from '@wcd/charts';
import { KnownColorsService } from '../../../shared/services/known-colors.service';

@Component({
	selector: 'remediation-status-bar',
	templateUrl: 'remediation-task.status-bar.component.html',
})
export class RemediationTaskStatusBarComponent {
	/**
	 * the width of the bar in pixels
	 */
	@Input() width: number;

	/**
	 * option to display legend under the bar
	 * @default false
	 */
	@Input() showLegend = false;

	/**
	 * If value == null, bar will not be displayed. If showTitleIfNoBar == false, nothing will be displayed
	 * @default true
	 */
	@Input() showTitleIfNoBar = true;

	/**
	 * text for title which is displayed above the bar
	 */
	@Input() title: string;

	/**
	 * prefix to be displayed when hovering on the completed part of the bar, for example, 'completed' in: "completed: 30/50"
	 */
	@Input() valuePrefix?: string;

	/**
	 * prefix to be displayed when hovering on the incomplete part of the bar, for example, 'incomplete' in: "incomplete: 30/50"
	 */
	@Input() restPrefix?: string;

	/**
	 * A number which reflects the total part of the bar. For example, '50' in: "30/50"
	 */
	@Input()
	set total(total: number) {
		if (!this.currentTotal || this.currentTotal !== total) {
			this.currentTotal = total;
			this.updateStrechedDonutChartItem();
		}
	}

	/**
	 * A number which reflects the completed part of the bar. For example, '30' in: "30/50"
	 */
	@Input()
	set value(value: number) {
		if (!this.currentValue || this.currentValue !== value) {
			this.currentValue = value;
			this.updateStrechedDonutChartItem();
		}
	}

	/**
	 * Sometimes we don't want to show a bar at all. If RemediationType = AttentionRequired, show a "-"
	 */
	@Input() showHyphen?: boolean;

	/**
	 * If we want to show this in a table, we want to add some extra padding to make the column heights the same and visually appealing
	 */
	@Input() inDataTable?: boolean;

	private currentTotal: number;
	currentValue: number = null;
	strechedDonutChartItem: Array<StretchedDonutBarItem>;

	constructor(private knownColorsService: KnownColorsService) {}

	private updateStrechedDonutChartItem() {
		this.strechedDonutChartItem = [
			{
				id: '',
				title: this.title,
				valueColor: this.knownColorsService.knownColorsMap['blue'],
				totalColor: this.knownColorsService.knownColorsMap['neutralSecondaryAlt'],
				value: this.currentValue,
				total: this.currentTotal,
				width: this.width,
				disableClick: true,
				valuePrefix: this.valuePrefix,
				restPrefix: this.restPrefix,
				isDefaultFull: true,
				tabIndex: null,
			},
		];
	}
}
