/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./non-supported-redirect.component";
import * as i2 from "@angular/router";
var styles_NonSupportedRedirectComponent = [];
var RenderType_NonSupportedRedirectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NonSupportedRedirectComponent, data: {} });
export { RenderType_NonSupportedRedirectComponent as RenderType_NonSupportedRedirectComponent };
export function View_NonSupportedRedirectComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_NonSupportedRedirectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "history-safe-redirect", [], null, null, null, View_NonSupportedRedirectComponent_0, RenderType_NonSupportedRedirectComponent)), i0.ɵdid(1, 114688, null, 0, i1.NonSupportedRedirectComponent, [i2.ActivatedRoute, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NonSupportedRedirectComponentNgFactory = i0.ɵccf("history-safe-redirect", i1.NonSupportedRedirectComponent, View_NonSupportedRedirectComponent_Host_0, {}, {}, []);
export { NonSupportedRedirectComponentNgFactory as NonSupportedRedirectComponentNgFactory };
