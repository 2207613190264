import { ComponentRef, OnDestroy } from '@angular/core';
import { ModalService } from '../modals/services/modal.service';
import { DimensionsModel } from '../models/dimensions.model';
import { ConfirmModalComponent } from './confirm.modal';
var ConfirmationService = /** @class */ (function () {
    function ConfirmationService(modalsService) {
        this.modalsService = modalsService;
    }
    ConfirmationService.prototype.confirm = function (options) {
        var _this = this;
        return new Promise(function (resolve) {
            var modal;
            _this.modalSub = _this.modalsService
                .create(ConfirmModalComponent, {
                dimensions: new DimensionsModel(400, 0),
                id: 'confirm-dialog',
                showOverlay: options.showOverlay,
            }, {
                options: options,
                confirm: function (message) {
                    var _a;
                    var confirmData = options.requireMessage && options.requireMessage.property && message
                        ? (_a = {}, _a[options.requireMessage.property] = message, _a) : null;
                    resolve({ confirmed: true, data: confirmData });
                },
                cancel: function () {
                    resolve({ confirmed: false });
                },
            })
                .subscribe(function (_modal) {
                modal = _modal;
                _modal.onDestroy(function () {
                    resolve({ confirmed: false });
                });
            });
        });
    };
    ConfirmationService.prototype.ngOnDestroy = function () {
        this.modalSub && this.modalSub.unsubscribe();
    };
    return ConfirmationService;
}());
export { ConfirmationService };
