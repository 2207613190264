import { Feature, FeaturesService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { DialogsService } from '../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { AppInsightsService } from '../insights/services/app-insights.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../projects/config/src/lib/services/features.service";
import * as i2 from "../dialogs/services/dialogs.service";
import * as i3 from "../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../insights/services/app-insights.service";
var TOGGLE_SUPPORT_CENTRAL_SUCCESS = 'ToggleSupportCentralSuccess';
var TOGGLE_SUPPORT_CENTRAL_FAIL = 'ToggleSupportCentralFail';
var SupportCentralService = /** @class */ (function () {
    function SupportCentralService(featuresService, dialogsService, i18nService, appInsightsService) {
        this.featuresService = featuresService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.appInsightsService = appInsightsService;
    }
    Object.defineProperty(SupportCentralService.prototype, "withSupportCentral", {
        get: function () {
            return !sccHostService.isSCC && this.featuresService.isEnabled(Feature.SupportCentral);
        },
        enumerable: true,
        configurable: true
    });
    SupportCentralService.prototype.toggleWidgetIfPossible = function () {
        if (window['SupportCentral'] && window['SupportCentral'].ToggleWidget) {
            window['SupportCentral'].ToggleWidget();
            this.appInsightsService.trackEvent(TOGGLE_SUPPORT_CENTRAL_SUCCESS);
        }
        else {
            this.dialogsService.confirm({
                title: this.i18nService.get('support_notAvailableTitle'),
                text: this.i18nService.get('support_notAvailableText'),
            });
            this.appInsightsService.trackEvent(TOGGLE_SUPPORT_CENTRAL_FAIL);
        }
    };
    SupportCentralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SupportCentralService_Factory() { return new SupportCentralService(i0.ɵɵinject(i1.FeaturesService), i0.ɵɵinject(i2.DialogsService), i0.ɵɵinject(i3.I18nService), i0.ɵɵinject(i4.AppInsightsService)); }, token: SupportCentralService, providedIn: "root" });
    return SupportCentralService;
}());
export { SupportCentralService };
