import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[focusOnFirst]',
})
export class FocusOnFirstDirective implements AfterViewInit {

	constructor(private elemRef: ElementRef) {}
	@Input() focusOnFirst: boolean;
	@Input() elementSelector: string;
	ngAfterViewInit() {
		if(!this.focusOnFirst)
			return;
		setTimeout(()=>{
			const element = this.elementSelector && document.querySelector(this.elementSelector) || this.elemRef.nativeElement;
			const focusable = element.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
			if(focusable.length === 0)
				return;
			const firstFocusable = focusable[0];
			firstFocusable.focus();
		},0);
	}
}
