var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, Injector, OnChanges, SimpleChanges, } from '@angular/core';
import { of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { BaselineProfileDevice, Machine, MachineDataSensitivityRelationship, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { I18nService } from '@wcd/i18n';
import { SpinnerSize } from 'office-ui-fabric-react';
var BaselineProfileDeviceEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(BaselineProfileDeviceEntityDetailsComponent, _super);
    function BaselineProfileDeviceEntityDetailsComponent(paris, activatedEntity, i18nService, injector) {
        var _this = _super.call(this, injector) || this;
        _this.paris = paris;
        _this.activatedEntity = activatedEntity;
        _this.i18nService = i18nService;
        _this.SpinnerSize = SpinnerSize;
        _this.noDataAvailable = i18nService.strings.notAvailable_short;
        return _this;
    }
    BaselineProfileDeviceEntityDetailsComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.machine) {
            this.dataSensitivity$ = this.paris
                .getRelatedItem(MachineDataSensitivityRelationship, this.machine)
                .pipe(catchError(function (_) { return of(null); }));
        }
    };
    BaselineProfileDeviceEntityDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (!this.baselineProfileDevice) {
            this._subscription = this.activatedEntity.currentEntity$
                .pipe(filter(function (entity) { return entity instanceof BaselineProfileDevice; }))
                .subscribe(function (device) {
                _this.baselineProfileDevice = device;
            });
        }
    };
    BaselineProfileDeviceEntityDetailsComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    return BaselineProfileDeviceEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { BaselineProfileDeviceEntityDetailsComponent };
