var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, queryToHttpOptions, RelationshipType, } from '@microsoft/paris';
import { RemediationAction } from './remediation-action.entity';
import { RemediationActionType } from './remediation-action-type.entity';
var ɵ0 = function (config, query) {
    if (query.where['useSevilleApi']) {
        return config.data.serviceUrls.threatIntel;
    }
    return config.data.serviceUrls.automatedIr;
}, ɵ1 = function (config, query) {
    if (query.where['useSevilleApi']) {
        return 'investigations/pending_actions';
    }
    return query.where['api'];
}, ɵ2 = function (item) {
    return { action_type: item.id, api: item.api || "remediation_action/" + item.id + "/actions" };
}, ɵ3 = function (query) {
    if (query.where['useSevilleApi']) {
        var params = {};
        if (query.pageSize) {
            params.pageSize = query.pageSize;
        }
        if (query.page) {
            params.pageIndex = query.page;
        }
        if (query.where['investigation']) {
            params.investigationId = query.where['investigation'];
        }
        params.attentionRequiredActionType = query.where['action_type'];
        return params;
    }
    return queryToHttpOptions(query).params;
}, ɵ4 = function (rawData, _, query) {
    if (query && query.where && query.where['useSevilleApi']) {
        return {
            count: rawData.count,
            results: rawData.Actions &&
                rawData.Actions.map(function (action) { return Object.assign({}, action, action.entity_data); }),
        };
    }
    return rawData;
};
var RemediationActionTypeActionsRelationship = /** @class */ (function () {
    function RemediationActionTypeActionsRelationship() {
    }
    RemediationActionTypeActionsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: RemediationActionType,
            dataEntity: RemediationAction,
            baseUrl: ɵ0,
            endpoint: ɵ1,
            getRelationshipData: ɵ2,
            parseDataQuery: ɵ3,
            parseData: ɵ4,
            allowedTypes: [RelationshipType.OneToMany],
        })
    ], RemediationActionTypeActionsRelationship);
    return RemediationActionTypeActionsRelationship;
}());
export { RemediationActionTypeActionsRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
