import { Component, ElementRef, ViewChild } from '@angular/core';
import { ChartComponent } from '../chart.component.base';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'wcd-horizontal-bars-chart',
	template: `
		<div class="chart" [attr.aria-label]="'charts.horizontalBarsChart' | i18n"></div>
	`,
})
export class HorizontalBarsChartComponent extends ChartComponent {
	@ViewChild('horizontalBarsChart', { static: false }) public horizontalBarsChart: HTMLDivElement;

	protected chartSettings: any = {
		chartType: 'bar',
		options: {
			axis: {
				rotated: true,
				x: {},
				y: {
					show: false,
				},
			},
			grid: {
				y: {
					show: true,
				},
			},
			oninit: this.onInit.bind(this),
		},
		useValues: true,
		xProperty: 'label',
		setColors: true,
		data: {
			order: 'desc',
		},
	};

	constructor(elementRef: ElementRef, public i18nService: I18nService) {
		super(elementRef);
	}

	onInit() {
		setTimeout( () => {
			const options: c3.ChartConfiguration = this.getChartOptions();
			options.bindto = this.el;
			const horizontalBarsChart = this.el;

			d3.selectAll(horizontalBarsChart.querySelectorAll(`.c3-bar`))
				.each((dataPoint: any, index: number, nodesList: SVGElement[]) => {
					d3.select(nodesList[index]).attr(
						'aria-label',
						`${options.axis.x.categories[dataPoint.x]}, ${dataPoint.value}`
					);
				});
		}, 0);
	}
}
