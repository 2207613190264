/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./domain-urls.dataview.component.ngfactory";
import * as i5 from "../services/domain-urls.fields";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../global_entities/services/global-entity-types.service";
import * as i8 from "./domain-urls.dataview.component";
import * as i9 from "@microsoft/paris/dist/lib/paris";
import * as i10 from "../services/domains.service";
import * as i11 from "../../../shared/services/time-ranges.service";
import * as i12 from "../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i13 from "./domain-urls-full.dataview.component";
import * as i14 from "@angular/router";
var styles_DomainUrlsFullComponent = [];
var RenderType_DomainUrlsFullComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DomainUrlsFullComponent, data: {} });
export { RenderType_DomainUrlsFullComponent as RenderType_DomainUrlsFullComponent };
export function View_DomainUrlsFullComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 2, "domain-urls", [["class", "wcd-flex-1"]], null, null, null, i4.View_DomainUrlsComponent_0, i4.RenderType_DomainUrlsComponent)), i0.ɵprd(512, null, i5.DomainUrlFields, i5.DomainUrlFields, [i6.I18nService, i7.GlobalEntityTypesService]), i0.ɵdid(7, 114688, null, 0, i8.DomainUrlsComponent, [i9.Paris, i5.DomainUrlFields, i10.DomainsService, i7.GlobalEntityTypesService, i11.TimeRangesService, i12.PreferencesService], { hideControls: [0, "hideControls"], allowPaging: [1, "allowPaging"], navigateOnChange: [2, "navigateOnChange"], hideCommandBarOnEmptyData: [3, "hideCommandBarOnEmptyData"], domainId: [4, "domainId"], fullUrlList: [5, "fullUrlList"], searchEnabled: [6, "searchEnabled"], defaultSortFieldId: [7, "defaultSortFieldId"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = false; var currVal_2 = false; var currVal_3 = false; var currVal_4 = false; var currVal_5 = _co.domainIdSelected; var currVal_6 = true; var currVal_7 = true; var currVal_8 = "accessCount"; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_DomainUrlsFullComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "full-domain-url-list-panel", [], null, null, null, View_DomainUrlsFullComponent_0, RenderType_DomainUrlsFullComponent)), i0.ɵdid(1, 245760, null, 0, i13.DomainUrlsFullComponent, [i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DomainUrlsFullComponentNgFactory = i0.ɵccf("full-domain-url-list-panel", i13.DomainUrlsFullComponent, View_DomainUrlsFullComponent_Host_0, { settings: "settings" }, {}, []);
export { DomainUrlsFullComponentNgFactory as DomainUrlsFullComponentNgFactory };
