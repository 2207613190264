import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { TvmAnalyticsApiCallSharedConfigurations } from '../analyticsEndPointUtils';
import { ProfilesStatistics } from './profiles-statistics.interface';

@ApiCall({
	...TvmAnalyticsApiCallSharedConfigurations,
	name: 'device-compliance-statistics-api-call',
	method: 'GET',
	endpoint: (_config, query) =>
		 !query.where['profileId'] ?
		 'baseline/profilesStatistics' :
		 `baseline/profilesStatistics/${query.where['profileId']}`
	,
	cache: null
})
export class DeviceComplianceStatisticsApiCall extends ApiCallModel<ProfilesStatistics> {}
