import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { fileAppTypeValues } from './file-app-type.values';

@Entity({
	singularName: 'File app type',
	pluralName: 'File app types',
	values: fileAppTypeValues,
})
export class FileAppType extends EntityModelBase<string> {
	@EntityField() name: string;

	@EntityField() icon?: string;

	@EntityField() color: string;

	@EntityField() extensions: Array<string>;
}
