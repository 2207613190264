<ng-container *ngIf="rule">
	<wcd-collapsible-section
		[sectionId]="collapsibleID.AlertDetails"
		[label]="'scheduledHunting.details.alertDetails' | i18n">
		<dl [class.key-values]="asKeyValueList">
			<dt id="term-alertDetails">{{ "hunting.scheduledMonitorSidePane.fields.alertTitle.title" | i18n}}</dt>
			<dd aria-labelledby="term-alertDetails">
				{{rule.alertTitle}}
			</dd>
			<ng-container *ngIf="rule.alertSeverity">
				<dt id="term-alertSeverity">{{ "hunting.scheduledMonitorSidePane.fields.alertSeverity.title" | i18n}}</dt>
				<dd role="definition" aria-labelledby="term-alertSeverity" class="wcd-severity" [ngClass]="'wcd-severity-' + rule.alertSeverity.type">
					{{ 'customTiIndicator.dataview.entity.fields.alertSeverity.values.' + rule.alertSeverity.name.toLowerCase() + '.title'| i18n}}
				</dd>
			</ng-container>
			<ng-container *ngIf="rule.alertCategory">
				<dt id="term-alertCategory">{{ "hunting.scheduledMonitorSidePane.fields.alertCategory.title" | i18n}}</dt>
				<dd role="definition" aria-labelledby="term-alertCategory" >
					{{alertCategoryText}}
				</dd>
			</ng-container>
			<ng-container *ngIf="rule.mitreTechniques?.length">
				<dt id="term-mitreTechniques"> {{ "hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.title" | i18n}} </dt>
				<dd role="definition" aria-labelledby="term-mitreTechniques" >
					<div *ngFor="let mitreTechnique of rule.mitreTechniques">
						{{ mitreTechnique | mitreTechniqueDisplay }}
					</div>
				</dd>
			</ng-container>
			<ng-container *ngIf="showImpactedEntities && rule.impactedEntities?.length">
				<dt id="term-impactedEntities">{{ "hunting.scheduledMonitorSidePane.wizardSections.entities.title" | i18n}}</dt>
				<dd role="definition" aria-labelledby="term-impactedEntities" >
					<div *ngFor="let impactedEntity of rule.impactedEntities">
						{{ getImpactedEntityName(impactedEntity) }}
					</div>
				</dd>
			</ng-container>
			<ng-container *ngIf="customActionsFeatureEnabled && rule.actions?.length">
				<dt id="term-actions">{{ "hunting.scheduledMonitorSidePane.fields.appliedActions.title" | i18n}}</dt>
				<dd role="definition" aria-labelledby="term-actions" >
					<div *ngFor="let action of rule.actions; trackBy: trackByAction">
						{{ 'hunting.customDetections.actions.' + action.actionType | i18n }}
					</div>
				</dd>
			</ng-container>
			<ng-container *ngIf="threatAnalyticsCustomDetectionFeatureEnabled && rule.threatId && (getThreatAnalyticsReportText$ | async) as threat">
				<dt id="term-threatAnalyticsReport">{{ "hunting.scheduledMonitorSidePane.fields.threatAnalyticsReport.title" | i18n}}</dt>
				<dd role="definition" aria-labelledby="term-threatAnalyticsReport" >
					{{ threat.displayName }}
				</dd>
			</ng-container>
		</dl>
	</wcd-collapsible-section>
	<wcd-collapsible-section
		[sectionId]="collapsibleID.ExecutionDetails"
		[label]="'scheduledHunting.details.executionDetails' | i18n">
		<dl [class.key-values]="asKeyValueList">
			<dt id="term-executionDetails">{{ "scheduledHunting.fields.lastRunTime.title" | i18n}}</dt>
			<dd role="definition" aria-labelledby="term-executionDetails" >
				<scheduled-hunting-run-time [rule]="rule" [runTime]="rule.lastRunTime"></scheduled-hunting-run-time>
			</dd>
			<dt id="term-lastRunStatus">{{ "scheduledHunting.fields.lastRunStatus.title" | i18n}}</dt>
			<dd role="definition" aria-labelledby="term-lastRunStatus" >
				<scheduled-hunting-last-run-status [rule]="rule" showInPage="true" ></scheduled-hunting-last-run-status>
			</dd>
			<dt id="term-nextRunTime">{{ "scheduledHunting.fields.nextRunTime.title" | i18n}}</dt>
			<dd role="definition" aria-labelledby="term-nextRunTime" >
				<scheduled-hunting-run-time [rule]="rule" [runTime]="rule.nextRunTime"></scheduled-hunting-run-time>
			</dd>
		</dl>
	</wcd-collapsible-section>
	<wcd-collapsible-section
		[sectionId]="collapsibleID.LastRunActions"
		[label]="'hunting.scheduledMonitorSidePane.lastRunActions.sectionTitle' | i18n"
		(isExpandedChange)="$event && updateTable()"
		*ngIf="customActionsFeatureEnabled &&  lastRunActions.length">
		<wcd-datatable
			style="display: block; min-height:1px"
			[items]="lastRunActions"
			[columns]="fields"
			[fieldWidths]="{actionType: 150, entity: 150, status: 150}"
			[selectEnabled]="false"
			[showHeaders]="true"
		></wcd-datatable>
		<a *ngIf="moreLastRunActionsExist && !showingAllActions" (keydown.enter)="showAllActionRuns()" (click)="showAllActionRuns()">{{ "hunting.scheduledMonitorSidePane.lastRunActions.seeAll" | i18n }}</a>
	</wcd-collapsible-section>
	<wcd-collapsible-section
		[sectionId]="collapsibleID.MachineGroups"
		[label]="'hunting.scheduledMonitorSidePane.fields.machineGroups.sectionTitle' | i18n"
		*ngIf="rbacMachineGroupsFeatureEnabled">
		<dl>
			<dt id="term-machineGroups"> {{ "scheduledHunting.fields.machineGroups.selected" | i18n}}</dt>
			<dd *ngIf="ruleMachineGroups.length === 0; else selectedMachineGroups">{{ "scheduledHunting.fields.machineGroups.all" | i18n}}</dd>
			<ng-template #selectedMachineGroups>
				<dd *ngFor="let machineGroup of ruleMachineGroups" role="definition" class="wcd-margin-xsmall-bottom"
					aria-labelledby="term-alertSeverity">{{ machineGroup.name }}</dd>
			</ng-template>
		</dl>
	</wcd-collapsible-section>
</ng-container>
