var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { CategorySecureScore } from './category-secure-score.entity';
import { TvmAnalyticsApiCallSharedConfigurations } from '../analyticsEndPointUtils';
export var SupportedConfigurationScoreCategories;
(function (SupportedConfigurationScoreCategories) {
    SupportedConfigurationScoreCategories["Application"] = "Application";
    SupportedConfigurationScoreCategories["OS"] = "OS";
    SupportedConfigurationScoreCategories["Network"] = "Network";
    SupportedConfigurationScoreCategories["Accounts"] = "Accounts";
    SupportedConfigurationScoreCategories["SecurityControls"] = "Security controls";
})(SupportedConfigurationScoreCategories || (SupportedConfigurationScoreCategories = {}));
var ɵ0 = function (categoriesScores) {
    // Enforcing the acceptance of only supported categories as in the
    // SupportedConfigurationScoreCategories enum and enforcing their order in the display
    var tmpResults = {};
    categoriesScores.forEach(function (c) {
        if (Object.values(SupportedConfigurationScoreCategories).includes(c.category)) {
            tmpResults[c.category] = c;
        }
        // TODO: evhvoste - raise alert for wrong category
    });
    var sorted = [];
    for (var category in SupportedConfigurationScoreCategories) {
        if (tmpResults.hasOwnProperty(SupportedConfigurationScoreCategories[category])) {
            sorted.push(tmpResults[SupportedConfigurationScoreCategories[category]]);
        }
    }
    return sorted;
};
var CategorySecureScoreApiCall = /** @class */ (function (_super) {
    __extends(CategorySecureScoreApiCall, _super);
    function CategorySecureScoreApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CategorySecureScoreApiCall = __decorate([
        ApiCall(__assign({}, TvmAnalyticsApiCallSharedConfigurations, { name: 'Read secure score by categories', method: 'GET', endpoint: 'configurations/securescore/categories', cache: null, type: CategorySecureScore, parseData: ɵ0 }))
    ], CategorySecureScoreApiCall);
    return CategorySecureScoreApiCall;
}(ApiCallModel));
export { CategorySecureScoreApiCall };
export { ɵ0 };
