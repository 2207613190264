var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { SuppressionRule } from './suppression-rule.entity';
import { Alert } from '../alert/alert.entity';
var ɵ0 = function (config, query) {
    return query && query.where && query.where['useDetectionAssociatedAlertsVnextApi'] ? 'Alerts' : "AssociatedAlerts";
}, ɵ1 = function (config, query) {
    return query && query.where && query.where['useDetectionAssociatedAlertsVnextApi']
        ? config.data.serviceUrls.alertsApiService + "/Evidences/suppressionRule"
        : config.data.serviceUrls.threatIntel;
};
var SuppressionRuleAlertRelationship = /** @class */ (function () {
    function SuppressionRuleAlertRelationship() {
    }
    SuppressionRuleAlertRelationship = __decorate([
        EntityRelationship({
            sourceEntity: SuppressionRule,
            dataEntity: Alert,
            endpoint: ɵ0,
            allItemsEndpointTrailingSlash: false,
            foreignKey: 'suppressionRuleId',
            allItemsProperty: 'Items',
            allowedTypes: [RelationshipType.OneToMany],
            baseUrl: ɵ1,
        })
    ], SuppressionRuleAlertRelationship);
    return SuppressionRuleAlertRelationship;
}());
export { SuppressionRuleAlertRelationship };
export { ɵ0, ɵ1 };
