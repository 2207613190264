/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./custom-ti-indicator-new.component.ngfactory";
import * as i5 from "../services/custom-ti-indicator-component.service";
import * as i6 from "@microsoft/paris/dist/lib/paris";
import * as i7 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i8 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i9 from "../../machines/services/machines.service";
import * as i10 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i11 from "../../../shared/pipes/mitre-technique-display.pipe";
import * as i12 from "./custom-ti-indicator-new.component";
import * as i13 from "../../../dialogs/services/dialogs.service";
import * as i14 from "../../../global_entities/services/entity-panels.service";
import * as i15 from "../services/custom-ti-indicators.service";
import * as i16 from "../../../admin/integration-settings/advanced-features.service";
import * as i17 from "../../../../../../../projects/config/src/lib/services/app-flavor.service";
import * as i18 from "../../../shared/interfaces/disableable.interface";
import * as i19 from "../../../rbac/directives/rbac-control.directive";
import * as i20 from "../../../rbac/services/rbac-control.service";
import * as i21 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i22 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i23 from "./custom-ti-indicator-new.modal.component";
import * as i24 from "@angular/router";
var styles_CustomTiIndicatorNewModalComponent = [];
var RenderType_CustomTiIndicatorNewModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomTiIndicatorNewModalComponent, data: {} });
export { RenderType_CustomTiIndicatorNewModalComponent as RenderType_CustomTiIndicatorNewModalComponent };
export function View_CustomTiIndicatorNewModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 12, "wcd-panel", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { canDeactivate: [0, "canDeactivate"], settings: [1, "settings"] }, null), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 16777216, null, 1, 7, "custom-ti-indicator-new", [], null, [[null, "save"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_co.closePanel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_CustomTiIndicatorNewComponent_0, i4.RenderType_CustomTiIndicatorNewComponent)), i0.ɵprd(512, null, i5.CustomTiIndicatorComponentService, i5.CustomTiIndicatorComponentService, [i6.Paris, i7.AuthService, i8.I18nService, i9.MachinesService, i10.FeaturesService, i11.MitreTechniqueDisplayPipe]), i0.ɵdid(7, 245760, null, 0, i12.CustomTiIndicatorNewComponent, [i6.Paris, i8.I18nService, i10.FeaturesService, i9.MachinesService, i13.DialogsService, i14.EntityPanelsService, i15.CustomTiIndicatorsService, i16.AdvancedFeaturesService, i5.CustomTiIndicatorComponentService, i17.FlavorService], { customTiIndicatorType: [0, "customTiIndicatorType"], indicatorValue: [1, "indicatorValue"] }, { save: "save", cancel: "cancel" }), i0.ɵprd(2048, null, i18.DISABLEABLE_TOKEN, null, [i12.CustomTiIndicatorNewComponent]), i0.ɵdid(9, 4800512, null, 0, i19.RbacControlDirective, [i20.RbacControlService, i0.ElementRef, i0.ChangeDetectorRef, i21.WicdSanitizerService, [6, i18.DISABLEABLE_TOKEN], i22.TooltipsService, i0.ViewContainerRef, i8.I18nService], { rbac: [0, "rbac"] }, null), i0.ɵpad(10, 1), i0.ɵpod(11, { permissions: 0, state: 1 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boundCanDeactivate; var currVal_1 = _co.settings; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.customTiIndicatorType; var currVal_3 = _co.indicatorValue; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _ck(_v, 11, 0, _ck(_v, 10, 0, "securitySettings"), "disabled"); _ck(_v, 9, 0, currVal_4); }, null); }
export function View_CustomTiIndicatorNewModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "custom-ti-indicator-new-modal", [], null, null, null, View_CustomTiIndicatorNewModalComponent_0, RenderType_CustomTiIndicatorNewModalComponent)), i0.ɵdid(1, 245760, null, 0, i23.CustomTiIndicatorNewModalComponent, [i24.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomTiIndicatorNewModalComponentNgFactory = i0.ɵccf("custom-ti-indicator-new-modal", i23.CustomTiIndicatorNewModalComponent, View_CustomTiIndicatorNewModalComponent_Host_0, { settings: "settings", customTiIndicatorType: "customTiIndicatorType", indicatorValue: "indicatorValue" }, { save: "save", cancel: "cancel" }, []);
export { CustomTiIndicatorNewModalComponentNgFactory as CustomTiIndicatorNewModalComponentNgFactory };
