import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { IEntityConfigBase } from '@microsoft/paris/dist/lib/config/model-config';
import { ParisConfig } from '@microsoft/paris/dist/lib/config/paris-config';
import { HttpOptions } from '@microsoft/paris/dist/lib/data_access/http.service';

@Entity({
	singularName: 'Script',
	pluralName: 'Scripts',
	baseUrl: (config: WcdPortalParisConfig, query) => {
		if (query && query.where && query.where['useV3Api']) {
			return config.data.serviceUrls.k8s;
		}

		// We want to call the cluster url directly because SCC proxy doesn't allow for large requests,
		// and this request size can be up to 250 MB
		const baseUrl =
			(query && query.where && query.where['automatedIrBaseUrl']) ||
			config.data.serviceUrls.automatedIr;
		if (query && query.where && query.where['useV2Api']) {
			return `${baseUrl}/v2`;
		}
		return baseUrl;
	},
	endpoint: (config, query) => {
		if(query && query.where && query.where['useV3Api']) {
			return 'cloud/live_response/library/get_files';
		}

		return 	'live_response/library/get_files';
	},
	allItemsEndpointTrailingSlash: false,
	parseSaveQuery: (item, entity, config, options) =>
		options && options.params && options.params.useV3Api ? 'cloud/live_response/library/upload_file' : 'live_response/library/upload_file',
	parseRemoveQuery: items => {
		const [item] = items;
		return item.useV3Api ? 'cloud/live_response/library/delete_file' : 'live_response/library/delete_file';
	},
	serializeItem: (
		script: LiveResponseScript,
		serializedItem,
		entity,
		config,
		serializationData: { overwriteExisting?: boolean }
	) => {
		const fieldData = {
			description: script.description,
			has_parameters: script.hasParams,
			parameters_description: script.paramsDescription,
			override_if_exists: serializationData && serializationData.overwriteExisting,
		};

		for (const k in fieldData) {
			if (fieldData[k] === undefined || fieldData[k] === null) {
				delete fieldData[k];
			}
		}

		if (script.file) {
			const formData = new FormData();
			formData.append('file[]', script.file);
			if (Object.keys(fieldData).length) {
				formData.append('file_fields', JSON.stringify(fieldData));
			}
			return formData;
		}

		return {
			file_fields: fieldData,
		};
	},
	getRemoveData: (files: Array<LiveResponseScript>) => {
		if (files.length > 1) {
			throw new Error('Only one file can be removed at a time.');
		}
		const file = files[0];
		return {
			file_id: file.id,
			last_updated_time: file.updatedAt,
			file_name: file.fileName,
		};
	},
	// 5 minutes
	timeout: 1000 * 60 * 5,
})
export class LiveResponseScript extends EntityModelBase<number> {
	@EntityField({ data: 'file_id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: 'file_name' })
	fileName: string;

	@EntityField({ data: 'originating_user' })
	creatingUser: string;

	@EntityField({ data: 'creation_time' })
	creationTime: Date;

	@EntityField({ data: 'last_updated_user' })
	updatingUser: string;

	@EntityField({ data: 'last_updated_time' })
	updatedAt: string;

	@EntityField({ data: 'description' })
	description: string;

	@EntityField({ data: 'blob_text' })
	blobText: string;

	@EntityField({ data: 'sha256' })
	sha256: string;

	@EntityField({ data: 'has_parameters' })
	hasParams: boolean;

	@EntityField({ data: 'parameters_description' })
	paramsDescription: string;

	@EntityField() file: File;

	// Temp workaround for parseRemoveQuery will be removed after migration
	useV3Api: boolean;
}
