import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

export enum HuntingRuleEntityType {
	Machine = 'Machine',
	User = 'User',
	Mailbox = 'Mailbox',
	MailMessage = 'MailMessage',
	File = 'File',
	Url = 'Url',
}

export type HuntingRuleImpactedEntityType = HuntingRuleEntityType.Machine | HuntingRuleEntityType.User | HuntingRuleEntityType.Mailbox;

@ValueObject({
	singularName: 'Hunting rule impacted entity',
	pluralName: 'Hunting rule impacted entities',
	readonly: true,
})
export class HuntingRuleImpactedEntity extends ModelBase {
	@EntityField({ data: 'EntityType' })
	entityType: HuntingRuleEntityType;

	@EntityField({ data: 'EntityIdentifiers' })
	entityIdFields: Array<string>;
}
