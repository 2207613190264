import { of, isObservable } from 'rxjs';
import { groupBy, isNil, uniq } from 'lodash-es';
import { map } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
var EMPTY_FILTER_VALUE_KEY = 'list_filter_empty_value';
var ListFilterValue = /** @class */ (function () {
    function ListFilterValue(data) {
        this.count = 0;
        this.i18nService = new I18nService();
        if (data) {
            Object.assign(this, data);
            if (this.id === undefined) {
                if (this.rawValue !== undefined)
                    this.id = this.rawValue;
                else
                    throw new Error("Can't create ListFilterValue, no id or rawValue provided");
            }
            if (typeof data.icon === 'string')
                this.icon = { iconName: data.icon };
            if (data.children) {
                var children = data.children.map(function (child) { return new ListFilterValue(child); });
                this.setChildrenCategories(children);
            }
        }
    }
    Object.defineProperty(ListFilterValue.prototype, "hasChildren", {
        get: function () {
            return this.childCategories && !!this.childCategories.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListFilterValue.prototype, "displayName", {
        get: function () {
            if (!isNil(this.name))
                return this.name || this.i18nService.get(EMPTY_FILTER_VALUE_KEY);
            if (!isNil(this.id))
                return this.id.toString() || this.i18nService.get(EMPTY_FILTER_VALUE_KEY);
            return this.i18nService.get(EMPTY_FILTER_VALUE_KEY);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListFilterValue.prototype, "paramValue", {
        get: function () {
            return String(this.id);
        },
        enumerable: true,
        configurable: true
    });
    ListFilterValue.fromValue = function (value, config) {
        if (config === void 0) { config = {}; }
        if (value instanceof ListFilterValue)
            return of(value);
        var mapFilterValue = config.mapFilterValue;
        var valueObj = typeof value === 'object' ? value : { value: value };
        var filterConfig$ = (mapFilterValue && mapFilterValue(valueObj.value, valueObj)) ||
            ListFilterValue.valueToListValueConfig(valueObj);
        var filterConfigObservable$ = isObservable(filterConfig$)
            ? filterConfig$
            : of(filterConfig$);
        return filterConfigObservable$.pipe(map(function (filterConfig) {
            var filterValue = new ListFilterValue(filterConfig);
            filterValue.count = valueObj.count;
            return filterValue;
        }));
    };
    ListFilterValue.valueToListValueConfig = function (rawValue) {
        var _this = this;
        return {
            id: rawValue.value,
            rawValue: rawValue.value,
            priority: rawValue.priority,
            name: rawValue.name,
            category: rawValue.category,
            children: rawValue.children && rawValue.children.map(function (child) { return _this.valueToListValueConfig(child); }),
            data: rawValue.custom,
        };
    };
    ListFilterValue.prototype.setChildrenCategories = function (children) {
        var categories = groupBy(children, function (child) { return child.category || ''; });
        var orderedCategoryNames = uniq(children.map(function (child) { return child.category || ''; }));
        this.childCategories = orderedCategoryNames.map(function (name) { return ({ name: name, values: categories[name] }); });
    };
    return ListFilterValue;
}());
export { ListFilterValue };
