var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { RemediationActionType } from './remediation-action-type.entity';
import { InvestigatedMachine } from '../investigation/investigated-machine.entity';
import { RemediationActionEntity } from './remediation-action-entity.value-object';
import { RemediationActionRelatedEntity } from './remediation-action-related-entity.value-object';
import { InvestigatedUser } from '../investigation/investigated-identity.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.automatedIr; }, ɵ1 = function (actionIds, rawData, query) {
    if (actionIds) {
        return actionIds && Array.isArray(actionIds) ? actionIds.length && actionIds[0] : actionIds;
    }
    if (query && query.where && query.where['useSevilleApi']) {
        return rawData.action_id;
    }
}, ɵ2 = ['action_type', 'type'], ɵ3 = function (type, rawData, query) {
    return type !== undefined && type !== null ? type : query.where['action_type'];
}, ɵ4 = function (actionIds, rawData, query) {
    if (actionIds) {
        return actionIds;
    }
    if (query && query.where && query.where['useSevilleApi']) {
        return [rawData.action_id];
    }
}, ɵ5 = function (host, rawData, query) {
    if (host) {
        return host;
    }
    if (query && query.where && query.where['useSevilleApi'] && rawData.computer_dns_name) {
        return {
            id: rawData.sense_machine_id,
            machine_id: rawData.sense_machine_id,
            name: rawData.computer_dns_name,
        };
    }
};
var RemediationAction = /** @class */ (function (_super) {
    __extends(RemediationAction, _super);
    function RemediationAction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(RemediationAction.prototype, "isProcessing", {
        get: function () {
            return this.state.isApproving || this.state.isDeclining;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RemediationAction.prototype, "machines", {
        get: function () {
            return this._machines && this._machines.length ? this._machines : [this.machine];
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({
            data: 'action_ids',
            parse: ɵ1,
        }),
        __metadata("design:type", String)
    ], RemediationAction.prototype, "id", void 0);
    __decorate([
        EntityField({
            data: ɵ2,
            parse: ɵ3,
        }),
        __metadata("design:type", RemediationActionType)
    ], RemediationAction.prototype, "remediationActionType", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RemediationAction.prototype, "description", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Object)
    ], RemediationAction.prototype, "commentData", void 0);
    __decorate([
        EntityField({
            data: 'action_ids',
            parse: ɵ4,
        }),
        __metadata("design:type", Array)
    ], RemediationAction.prototype, "actionIds", void 0);
    __decorate([
        EntityField({ data: 'investigation_id' }),
        __metadata("design:type", Object)
    ], RemediationAction.prototype, "investigationId", void 0);
    __decorate([
        EntityField({
            data: 'host',
            parse: ɵ5,
        }),
        __metadata("design:type", InvestigatedMachine)
    ], RemediationAction.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'hosts', arrayOf: InvestigatedMachine }),
        __metadata("design:type", Array)
    ], RemediationAction.prototype, "_machines", void 0);
    __decorate([
        EntityField({ data: 'action' }),
        __metadata("design:type", Object)
    ], RemediationAction.prototype, "details", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "entity", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RemediationAction.prototype, "reason", void 0);
    __decorate([
        EntityField({ data: 'related_entities', arrayOf: RemediationActionRelatedEntity }),
        __metadata("design:type", Array)
    ], RemediationAction.prototype, "relatedEntities", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "file", void 0);
    __decorate([
        EntityField({ data: 'appended_file' }),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "appendedFile", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "process", void 0);
    __decorate([
        EntityField({ arrayOf: RemediationActionEntity }),
        __metadata("design:type", Array)
    ], RemediationAction.prototype, "processes", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "address", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "service", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "driver", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "user", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "mailCluster", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "mailMessage", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "mailbox", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "mailboxConfiguration", void 0);
    __decorate([
        EntityField({ data: 'persistence_method' }),
        __metadata("design:type", RemediationActionEntity)
    ], RemediationAction.prototype, "persistenceMethod", void 0);
    __decorate([
        EntityField({ data: 'user_activity' }),
        __metadata("design:type", InvestigatedUser)
    ], RemediationAction.prototype, "investigatedUser", void 0);
    __decorate([
        EntityField({ data: 'IsOfficeInvestigation' }),
        __metadata("design:type", Boolean)
    ], RemediationAction.prototype, "isOfficeInvestigation", void 0);
    RemediationAction = __decorate([
        Entity({
            singularName: 'Remediation Action',
            pluralName: 'Remediation Actions',
            endpoint: 'remediation_actions',
            allItemsProperty: 'results',
            separateArrayParams: true,
            baseUrl: ɵ0,
        })
    ], RemediationAction);
    return RemediationAction;
}(EntityModelBase));
export { RemediationAction };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
