var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ApiCall, ApiCallModel, EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { FilterItem } from './filter-item.value.object';
var IncidentQueueFilters = /** @class */ (function (_super) {
    __extends(IncidentQueueFilters, _super);
    function IncidentQueueFilters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'SystemTag' }),
        __metadata("design:type", FilterItem)
    ], IncidentQueueFilters.prototype, "systemTag", void 0);
    __decorate([
        EntityField({ data: 'CustomTag' }),
        __metadata("design:type", FilterItem)
    ], IncidentQueueFilters.prototype, "customTag", void 0);
    IncidentQueueFilters = __decorate([
        ValueObject({
            singularName: 'Incident Queue Filters',
            pluralName: '',
        })
    ], IncidentQueueFilters);
    return IncidentQueueFilters;
}(ModelBase));
export { IncidentQueueFilters };
var ɵ0 = function (config) { return config.data.serviceUrls.incidentQueue; }, ɵ1 = function (filters) { return ({ params: { name: filters } }); };
var GetIncidentQueueFiltersApiCall = /** @class */ (function (_super) {
    __extends(GetIncidentQueueFiltersApiCall, _super);
    function GetIncidentQueueFiltersApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GetIncidentQueueFiltersApiCall = __decorate([
        ApiCall({
            name: 'Get dynamic filters of the unified incidents queue',
            endpoint: 'incidents/filters',
            baseUrl: ɵ0,
            parseQuery: ɵ1,
            type: IncidentQueueFilters,
            cache: {
                time: 1000 * 60,
            },
        })
    ], GetIncidentQueueFiltersApiCall);
    return GetIncidentQueueFiltersApiCall;
}(ApiCallModel));
export { GetIncidentQueueFiltersApiCall };
export { ɵ0, ɵ1 };
