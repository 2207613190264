import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
	ActiveIncidents,
	ActiveIncidentsApiCall,
	ActiveIncidentsIncidentsPhase2ApiCall,
	Incident,
} from '@wcd/domain';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { EntityType } from '../../../../../global_entities/models/entity-type.interface';
import {IncidentFields, IncidentOrAlert} from '../../../../../@entities/incidents/services/incident.fields';
import { DataViewConfig, DataviewField } from '@wcd/dataview';
import { map, tap, take } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { SeverityTypeColorService } from '../../../../../shared/services/severity-type-color.service';
import { merge } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { Router } from '@angular/router';
import { FeaturesService, Feature} from '@wcd/config';


@Component({
	selector: 'active-alerts-widget',
	template: `
		<ng-container *ngIf="(data$ | async) as data">
			<p class="wcd-font-size-xxl wcd-margin-mediumSmall-bottom">
				{{ 'incident.activeIncidentsCount' | i18n: {count: data.activeIncidentCount | number} }}
				<span class="wcd-font-size-mi">{{ 'incident_relevant_for_30_days' | i18n }}</span>
			</p>
			<active-incidents-trends [data]="data"></active-incidents-trends>
			<dataview
				[id]="'active-incidents-dataview'"
				[disableSelection]="true"
				[entityType]="incidentEntityType"
				[fields]="fields"
				[hideControls]="true"
				[allowPaging]="false"
				[allowFilters]="false"
				[padLeft]="false"
				[dataViewConfig]="dataViewConfig"
				[label]="'incident.activeIncidents' | i18n"
				[fixedTable]="true"
				[ignoreQueryParams]="true"
				className="no-horizontal-padding"
			>
			</dataview>
			<fab-default-button contentClass="btn-secondary" (click)="viewAllIncidentClicked()">
				{{ 'incident.viewAll' | i18n }}
			</fab-default-button>
		</ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [IncidentFields],
})
export class ActiveIncidentsWidgetComponent extends ReportWidgetComponent<ActiveIncidents> implements OnInit {
	incidentEntityType: EntityType<Incident>;
	fields: Array<DataviewField<Incident>>;
	dataViewConfig: DataViewConfig<Incident>;
	data: ActiveIncidents;

	constructor(
		reportsService: ReportsService,
		private paris: Paris,
		private globalEntityTypesService: GlobalEntityTypesService,
		public incidentFields: IncidentFields,
		private severityTypeColorService: SeverityTypeColorService,
		private changeDetectorRef: ChangeDetectorRef,
		private i18nService: I18nService,
		private router: Router,
		private featuresService: FeaturesService
	) {
		super(reportsService);

	}

	ngOnInit() {
		super.ngOnInit();
		this.incidentEntityType = this.globalEntityTypesService.getEntityType(Incident);

		this.fields = this.incidentFields.dashboardWidgetFields.map(field => {
			const renameTitle = field.id === 'impactedEntities' ? this.i18nService.get('incident_scope') : field.name;
			const noSortField = merge({}, field, { name: renameTitle }, { sort: { enabled: false } });
			return new DataviewField<IncidentOrAlert>(noSortField)
		})

		this.dataViewConfig = {
			id: 'active-incidents-widget-dataview',
			loadResults: () =>
				this.data$.pipe(
					map((activeIncidents: ActiveIncidents) => ({
						items: activeIncidents.topIncidents,
						count: activeIncidents.topIncidents.length,
					}))
				),
		};

	}

	get widgetConfig(): ReportWidgetConfig<ActiveIncidents> {
		return {
			id: 'activeIncidents',
			name: this.i18nService.get('incidents_title'),

			loadData: () => {
				const incidentsDashboardWidget = this.featuresService.isEnabled(Feature.IncidentsDashboardWidget)
				return this.paris.apiCall(incidentsDashboardWidget ? ActiveIncidentsIncidentsPhase2ApiCall : ActiveIncidentsApiCall).pipe(
					tap((data: ActiveIncidents) => {
						this.data = data;
						this.changeDetectorRef.markForCheck();
					}),
					take(1)
				)
			},
		};
	}


	viewAllIncidentClicked() {
		this.router.navigate(['/incidents'])
	}

}
