import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { LiveResponseTableConfig } from './live-response-table-config.value-object';

@ValueObject({
	singularName: 'Command output',
	pluralName: 'Command outputs',
	readonly: true,
})
export class LiveResponseCommandOutput<T> extends ModelBase {
	@EntityField({ data: 'data_type' })
	outputType: 'table' | 'object' | 'string';

	@EntityField() data: string | T | Array<T>;

	@EntityField({ data: 'table_config' })
	tableConfig?: LiveResponseTableConfig;

	@EntityField() keys?: Array<{ id: keyof T; name?: string }>;
}
