import { AppConfigService } from '@wcd/app-config';
import { SevilleModule } from '../../../../../app/seville/seville.module';
import { AppInsightsService } from '../../../../../../insights/services/app-insights.service';
import { RbacMdeAllowedActions } from '../../../../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.controller('seville.settings.download.offboardingmodal', offboardingmodalController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('settings.offboardingmodal', {
			url: '/offboardingmodal',
			views: {
				onboardingStepView: {
					template: `
						<div class="offboarding-modal">
                <div class="settings-title">
                    Are you sure you want to download the device offboarding package?
                </div>
                <br />
                <div>
                    <table>
                        <tr>
                            <td class="icon icon-lg icon-Error offboarding-modal-warning-padding"></td>
                            <td>
                                By clicking download you'll create an offboarding package that expires on <date src="packageExpirationDate" type="date"></date>.
                                <br />
                                The offboarding package can be used to remove Microsoft Defender for Endpoint settings from devices. Offboarded devices will stop collecting and sending data.
                            </td>
                        </tr>
                    </table>
                </div>
                <br />
                <div class="row">
                    <div class="col-xs-1">
                        <button class="settings-button ng-class:{'settings-button-enabled':!isreadonlyuser, 'settings-button-disabled':isreadonlyuser}"
                                ng-disabled="isreadonlyuser"
                                ng-click="downloadoffboardingpackage()">
                        <span class="icon icon-Download"></span>
                        Download
                        </button>
                    </div>
                    <div class="col-xs-2 col-xs-offset-3 offboarding-modal-button-margin">
                        <button type="reset" class="normal-button" ng-click="cancel()">Cancel</button>
                    </div>
                </div>
                <br />
            </div>
						`,
					controller: 'seville.settings.download.offboardingmodal',
					controllerAs: 'offboardingmodal',
				},
			},
		});
	},
]);

offboardingmodalController.$inject = [
	'$scope',
	'$uibModalInstance',
	'appConfig',
	'$http',
	'$window',
	'packageType',
	'authenticationService',
	'appInsights',
];

function offboardingmodalController(
	$scope,
	$modalInstance,
	appConfig: AppConfigService,
	$http,
	$window,
	packageType,
	authenticationService,
	appInsights: AppInsightsService
) {
	var baseUrl =
		appConfig.serviceUrls && appConfig.isSuspended
			? appConfig.serviceUrls.authorization
			: appConfig.serviceUrls.packages;

	$scope.isreadonlyuser = !authenticationService.isUserActionAllowed([RbacMdeAllowedActions.securitySettings]);

	var date = new Date();
	$scope.packageExpirationDate = date.setDate(date.getUTCDate() + 30);

	$scope.downloadoffboardingpackage = function() {
		$http
			.get(`${baseUrl}/DownloadOffboardingPackage`, {
				params: { mgmtTool: packageType },
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200) {
						if (response.data === null) {
							console.log(response.data);
						}
						$window.location.href = response.data;
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'DownloadOffboardingPackage',
							Page: 'SettingsDownload',
							ResponseStatus: response.status,
						});
					} else {
						console.log('Download offboarding package failed');
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'DownloadOffboardingPackage',
							Page: 'SettingsDownload',
							ResponseStatus: response.status,
							FailureType: 'API',
						});
					}
				},
				function(response) {
					console.log('Download offboarding package call is failed');
					appInsights.trackEvent('ButtonClick', {
						ButtonType: 'DownloadOffboardingPackage',
						Page: 'SettingsDownload',
						ResponseStatus: response.status,
						FailureType: 'Request',
					});
				}
			);

		$modalInstance.dismiss();
	};

	$scope.cancel = function() {
		$modalInstance.dismiss();
		appInsights.trackEvent('ButtonClick', {
			ButtonType: 'DownloadOffboardingPackageCancel',
			Page: 'SettingsDownload',
		});
	};
}
