
		<modal (close)="destroy()" [settings]="settings" data-track-component="ExportModal">
			<form #exportForm="ngForm" class="wcd-full-height wcd-flex-vertical" (submit)="exportDataSet()">
				<div class="dialog-contents wcd-flex-1">
					<p>{{ i18nService.get('dataview_export_dialog_how_many', { totalCount: totalCount}) }}</p>
					<div class="large-form-control">
						<div class="form-control-input">
							<input
								type="number"
								min="1"
								[validateMinMax]="{ min: 1, max: maxRowCount }"
								required
								#rowCountInput="ngModel"
								name="rowCount"
								[attr.placeholder]="i18nService.strings.dataview_export_dialog_result_count"
								maxlength="4"
								minlength="1"
								[(ngModel)]="rowCount"
								[attr.max]="maxRowCount"
								[attr.aria-invalid]="!rowCountInput.valid"
								[attr.aria-label]="i18nService.strings.dataview_export_dialog_result_count"
							/>
							<div
								class="error-message"
								*ngIf="!rowCountInput.valid"
								role="alert"
								aria-live="polite">
								{{ 'errors.minMaxNumber' | i18n: { min: '1', max: maxRowCount.toString() } }}
							</div>
						</div>
					</div>
				</div>
				<footer class="dialog-footer wcd-flex-none wcd-flex-horizontal">
					<div>
						<button
							type="button"
							class="btn"
							data-track-id="Cancel"
							data-track-type="Button"
							[disabled]="isExporting"
							(click)="destroy()"
						>
							{{ 'buttons.cancel' | i18n }}
						</button>
					</div>
					<div>
						<button
							*ngIf="!isError"
							type="submit"
							class="btn btn-primary"
							data-track-id="Submit"
							data-track-type="Button"
							[disabled]="exportForm.form.invalid || isExporting"
						>
							<i class="loader-icon" [hidden]="!isExporting"></i>
							{{ isExporting ? i18nService.strings.dataview_export_dialog_please_wait : i18nService.strings.dataview_export_dialog_export }}
						</button>
						<strong
							class="error-message"
							*ngIf="isError"
							role="alert"
							aria-live="polite"
						>
							{{ i18nService.strings.dataview_export_dialog_error }}
						</strong>
					</div>
				</footer>
			</form>
		</modal>
	