/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/prettify/src/lib/pipes/pretty-number.pipe";
import * as i2 from "../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i3 from "../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i4 from "../../../../../../../projects/dialogs/src/lib/tooltips/help.component.ngfactory";
import * as i5 from "../../../../../../../projects/dialogs/src/lib/tooltips/help.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../../../../projects/charts/src/lib/area/area-chart.component.ngfactory";
import * as i8 from "../../../../../../../projects/charts/src/lib/area/area-chart.component";
import * as i9 from "@angular/common";
import * as i10 from "./tvm-assets-statistics.component";
import * as i11 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_TvmAssetsStatisticsComponent = [];
var RenderType_TvmAssetsStatisticsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TvmAssetsStatisticsComponent, data: {} });
export { RenderType_TvmAssetsStatisticsComponent as RenderType_TvmAssetsStatisticsComponent };
function View_TvmAssetsStatisticsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "wcd-flex-center-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t-\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 5, "span", [["class", "wcd-margin-xsmall-left color-text-neutralPrimaryAlt"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["\n\t\t\t\t/ ", "\n\t\t\t\t"])), i0.ɵpid(131072, i1.PrettyNumberPipe, [i2.PrettyNumberService, [2, i3.LocaleConfigService], [2, i0.ChangeDetectorRef]]), (_l()(), i0.ɵeld(7, 0, null, null, 1, "wcd-help", [], null, null, null, i4.View_HelpComponent_0, i4.RenderType_HelpComponent)), i0.ɵdid(8, 638976, null, 0, i5.HelpComponent, [i6.DomSanitizer], { text: [0, "text"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.productNeverMatchedTooltip; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.totalAssetCount)); _ck(_v, 5, 0, currVal_0); }); }
function View_TvmAssetsStatisticsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "wcd-area-chart", [["class", "wcd-margin-xsmall-top"], ["style", "margin-left: auto;"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_AreaChartComponent_0, i7.RenderType_AreaChartComponent)), i0.ɵdid(1, 4243456, null, 0, i8.AreaChartComponent, [i0.ElementRef], { options: [0, "options"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.newChartOptions; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TvmAssetsStatisticsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["\n\t\t", "\n\t\t"])), i0.ɵpid(131072, i1.PrettyNumberPipe, [i2.PrettyNumberService, [2, i3.LocaleConfigService], [2, i0.ChangeDetectorRef]]), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "wcd-margin-xsmall-left color-text-neutralPrimaryAlt"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t\t/ ", "\n\t\t"])), i0.ɵpid(131072, i1.PrettyNumberPipe, [i2.PrettyNumberService, [2, i3.LocaleConfigService], [2, i0.ChangeDetectorRef]]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TvmAssetsStatisticsComponent_3)), i0.ɵdid(7, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.newChartOptions; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform(_co.assetsCount)); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.totalAssetCount)); _ck(_v, 3, 0, currVal_1); }); }
export function View_TvmAssetsStatisticsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "wcd-flex wcd-flex-align-items-center wcd-font-weight-semibold"], ["style", "height: 33px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TvmAssetsStatisticsComponent_1)), i0.ɵdid(3, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(0, [["supported", 2]], null, 0, null, View_TvmAssetsStatisticsComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co._productNeverMatched && (_co.assetsCount === 0)); var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_TvmAssetsStatisticsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tvm-assets-statistics", [], null, null, null, View_TvmAssetsStatisticsComponent_0, RenderType_TvmAssetsStatisticsComponent)), i0.ɵdid(1, 245760, null, 0, i10.TvmAssetsStatisticsComponent, [i0.ChangeDetectorRef, i3.LocaleConfigService, i11.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TvmAssetsStatisticsComponentNgFactory = i0.ɵccf("tvm-assets-statistics", i10.TvmAssetsStatisticsComponent, View_TvmAssetsStatisticsComponent_Host_0, { isImprovementNegative: "isImprovementNegative", totalAssetCount: "totalAssetCount", assetsCount: "assetsCount", assetsCountHistory: "assetsCountHistory", productNeverMatched: "productNeverMatched" }, {}, []);
export { TvmAssetsStatisticsComponentNgFactory as TvmAssetsStatisticsComponentNgFactory };
