import { DeviceType } from './device-type.enum';
import { keyBy } from 'lodash-es';

const endpointDeviceTypes = [
	{
		id: DeviceType.Workstation,
		i18nNameKey: 'devices_page_device_type_value_wrokstation',
		priority: 9,
	},
	{
		id: DeviceType.Server,
		i18nNameKey: 'devices_page_device_type_value_server',
		priority: 10,
	},
	{
		id: DeviceType.Mobile,
		i18nNameKey: 'devices_page_device_type_value_mobile',
		priority: 11,
	},
];
export const iotDeviceTypes = [
	{
		id: DeviceType.Printer,
		i18nNameKey: 'devices_page_device_type_value_printer',
		priority: 2,
	},
	{
		id: DeviceType.AudioAndVideo,
		i18nNameKey: 'devices_page_device_type_value_audio_and_video',
		priority: 3,
	},
	{
		id: DeviceType.Surveillance,
		i18nNameKey: 'devices_page_device_type_value_surveillance',
		priority: 4,
	},
	{
		id: DeviceType.SmartAppliance,
		i18nNameKey: 'devices_page_device_type_value_smart_appliance',
		priority: 6,
	},
	{
		id: DeviceType.Communication,
		i18nNameKey: 'devices_page_device_type_value_communication',
		priority: 7,
	},
	{
		id: DeviceType.SmartFacility,
		i18nNameKey: 'devices_page_device_type_value_smart_facility',
		priority: 6,
	},
	{
		id: DeviceType.Miscellaneous,
		i18nNameKey: 'devices_page_device_type_value_miscellaneous',
		priority: 108,
	},
	{
		id: DeviceType.Unknown,
		i18nNameKey: 'devices_page_device_type_value_unknown',
		priority: 109,
	},
];

export const networkDeviceTypes = [
	{
		id: DeviceType.NetworkDevice,
		i18nNameKey: 'devices_page_device_type_value_network_device',
		priority: 1,
	},
];

// those deprecated types are used for translation and mapping of old types
const deprecatedEndpointTypes = [
	{
		id: DeviceType.Desktop,
		i18nNameKey: 'devices_page_device_type_value_wrokstation',
		priority: 9,
		isDeprecated: true,
		mappedInto: DeviceType.Workstation,
	},
];

const deprecatedIotTypes = [
	{
		id: DeviceType.GamingConsole,
		i18nNameKey: 'devices_page_device_type_value_gaming_console',
		priority: 3,
		isDeprecated: true,
		mappedInto: DeviceType.AudioAndVideo,
	},
	{
		id: DeviceType.GenericIoT,
		i18nNameKey: 'devices_page_device_type_value_generic_iot',
		priority: 107,
		isDeprecated: true,
		mappedInto: DeviceType.Miscellaneous,
	},
];

export const deviceTypes = [].concat(
	iotDeviceTypes,
	networkDeviceTypes,
	deprecatedIotTypes,
	endpointDeviceTypes,
	deprecatedEndpointTypes
);
export const deviceTypesMap = keyBy(deviceTypes, (t) => t.id);
