<!-- tslint:disable:template-accessibility-label-for -->
<wcd-panel (close)="destroy()" [settings]="settings">
	<div
		class="wcd-full-height wcd-flex-vertical wcd-padding-horizontal"
		data-track-component="Machine value Panel"
		data-track-component-type="Side Panel"
	>

		<div class="wcd-flex-1 wcd-flex-vertical wcd-scroll-vertical wcd-padding-medium-right">
			<!--The label tag was added in order to solve this bug: https://dev.azure.com/microsoft/OS/_workitems/edit/28137526/-->
			<!--Heading should be next to some meaningful content in order to be parsed correctly and recognized by screen readers.-->
			<label for="panel-header"></label>
			<h2 id="panel-header" class="wcd-font-size-xxl wcd-font-weight-bold">
				{{ 'machines.entityDetails.actions.machineValue.title' | i18n }}
			</h2>
			<div *ngIf="isSaving" class="wcd-margin-largeMedium-top">
				<fab-message-bar [messageBarType]="messageBarType">
					{{ messageBarText }}
				</fab-message-bar>
			</div>
			<div class="wcd-margin-largeMedium-top wcd-margin-small-bottom">
				<markdown>
					{{ description }}
				</markdown>
			</div>
			<div class="wcd-flex-1 wcd-margin-vertical">
				<dl class="asKeyValueList" role="none">
					<dt>{{ 'machines_entityDetails_actions_machineValue_title' | i18n}}</dt>
					<dd>
						<fancy-select
							class="command-bar-item-dropdown"
							[(ngModel)]="value"
							(ngModelChange)="onValueChange($event)"
							[isBordered]="true"
							[values]="values"
							[formatLabel]="formatLabel"
							[isFullWidth]="true"
							placeholder="(Multiple values)"
							[ariaLabel]="('machines_entityDetails_actions_machineValue_title' | i18n)"
							[dropdownAreaRole]="'combobox'"
							>
						</fancy-select>
					</dd>
				</dl>
			</div>
		</div>

		<div class="wcd-flex-none">
			<footer class="wcd-flex-horizontal wcd-margin-large-top">
				<fab-primary-button
					data-track-id="Submit"
					data-track-type="Button"
					[contentClass]="isSaving ? 'wcd-width-small' : ''"
					[text]="isSaving ? '' : ('buttons.submit' | i18n)"
					(onClick)="submitRequest()"
					[disabled]="(value === referenceValue) || isSaving">
					<fab-spinner *ngIf="isSaving" [size]="SpinnerSize.xSmall"></fab-spinner>
				</fab-primary-button>
			</footer>
		</div>

	</div>
</wcd-panel>
