<wcd-panel (close)="destroy()" [settings]="settings">
	<h3 class="side-panel-title wcd-margin-bottom wcd-margin-left">{{ 'incident.associatedIncidents.title' | i18n }}</h3>
	<wcd-datatable
		class="wcd-margin-bottom"
		*ngIf="(associatedIncidents$ | async) as associatedIncidents else loading"
		[columns]="associatedIncidentsFields.fields"
		[items]="associatedIncidents"
		[selectEnabled]="false"
		[fixedTable]="false"
	>
	</wcd-datatable>
	<ng-template #loading>
		<div class="wcd-full-height wcd-full-height wcd-flex wcd-flex-center-all">
			<accessible-spinner [customLabel]="'incident_associatedIncidents_loadingPanel' | i18n"></accessible-spinner>
		</div>
	</ng-template>
</wcd-panel>
