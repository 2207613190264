import { AlertStatusType, IAlertStatus, AlertStatusTypeId } from './alert-status.models';

export const alertStatusValues: IAlertStatus[] = [
	{
		id: 0,
		name: 'New',
		nameI18nKey: 'alertStatusType_New',
		type: AlertStatusType.Unspecified,
	},

	{
		id: AlertStatusTypeId.Unspecified,
		name: 'Unspecified',
		nameI18nKey: 'alertStatusType_Unspecified',
		type: AlertStatusType.Unspecified,
	},

	{
		id: AlertStatusTypeId.New,
		name: 'New',
		type: AlertStatusType.New,
		title: 'New alerts',
		nameI18nKey: 'alertStatusType_New',
		isSelectable: true,
		priority: 1,
		allowClassification: true,
	},
	{
		id: AlertStatusTypeId.InProgress,
		name: 'In progress',
		type: AlertStatusType.InProgress,
		nameI18nKey: 'alertStatusType_InProgress',
		title: 'Alerts in progress',
		isSelectable: true,
		priority: 2,
		allowClassification: true,
	},
	{
		id: AlertStatusTypeId.Resolved,
		name: 'Resolved',
		type: AlertStatusType.Resolved,
		title: 'Resolved Alerts',
		nameI18nKey: 'alertStatusType_Resolved',
		isSelectable: true,
		allowClassification: true,
		priority: 3,
	},
	{
		id: AlertStatusTypeId.Hidden,
		name: 'Hidden',
		type: AlertStatusType.Hidden,
		nameI18nKey: 'alertStatusType_Hidden',
		isHidden: true,
	},
	{
		id: AlertStatusTypeId.ResolvedValid,
		name: 'Resolved [valid]',
		type: AlertStatusType.ResolvedValid,
		nameI18nKey: 'alertStatusType_ResolvedValid',
	},

	{
		id: AlertStatusTypeId.ResolvedValidAllowed,
		name: 'Resolved [valid - allowed]',
		type: AlertStatusType.ResolvedValidAllowed,
		nameI18nKey: 'alertStatusType_ResolvedValidAllowed',
	},

	{
		id: AlertStatusTypeId.ResolvedFalseAlarm,
		name: 'Resolved [false alarm]',
		type: AlertStatusType.ResolvedFalseAlarm,
		nameI18nKey: 'alertStatusType_ResolvedFalseAlarm',
	},
];
