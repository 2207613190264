var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { AirsNotificationRuleModel } from '../models/airs-notification-rule.model';
import { PanelContainer } from '@wcd/panels';
var AirsNotificationRuleEditPanelComponent = /** @class */ (function (_super) {
    __extends(AirsNotificationRuleEditPanelComponent, _super);
    function AirsNotificationRuleEditPanelComponent(router) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        return _this;
    }
    AirsNotificationRuleEditPanelComponent.prototype.cancelEdit = function () {
        this.startClose();
        this.onCancel();
    };
    AirsNotificationRuleEditPanelComponent.prototype.save = function ($event) {
        this.onDone($event);
        this.startClose();
    };
    return AirsNotificationRuleEditPanelComponent;
}(PanelContainer));
export { AirsNotificationRuleEditPanelComponent };
