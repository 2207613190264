<h2 class="wcd-font-weight-bold">
	{{ 'email.notification.wizard.recipients.step.title' | i18n }}
</h2>
<div *ngIf="introductionText" class="wcd-margin-xLarge-top wcd-margin-mediumSmall-bottom">
	{{ introductionText }}
</div>
<div class="wcd-margin-large-top form-group">
	<label for="notification-rule-email" class="wcd-font-weight-semibold">{{ 'notificationRules.ruleEmails.title' | i18n }}</label>
	<div class="wcd-flex-center-vertical">
		<input
			type="text"
			id="notification-rule-email"
			class="form-control with-action-after wcd-width-medium wcd-margin-small-right"
			name="email"
			#email="ngModel"
			#emailEl
			validateRegExp="emailWithSpecialChars"
			[(ngModel)]="currentEmail"
			aria-describedby="notification-rule-email-error-message"
		/>
		<fab-primary-button
			[disabled]="!currentEmail || !email.valid"
			data-track-id="AddEmail"
			data-track-type="Button"
			type="button"
			(onClick)="addEmail(currentEmail)"
			ariaLabel="{{ 'notificationRules_ruleEmails_add_recipient' | i18n }}"
		>
			{{ 'notificationRules_ruleEmails_add' | i18n }}
		</fab-primary-button>
	</div>
	<div id="notification-rule-email-error-message">
		<div class="error-message" *ngIf="currentEmail && !email.valid">
			{{ 'notificationRules.ruleEmails.error' | i18n }}
		</div>
	</div>
	<simple-data-list-table
		[dataList]="data.recipients"
		[shouldShowDeleteButton]="true"
		(onDeleteIconClick)="removeEmail($event)"
		[shouldShowTitle]="true"
		[titleKey]="'email.notification.recipientsTable.title'"
		[shouldShowIcon]="true"
		[iconName]="'email'"
	></simple-data-list-table>
	<ng-container *ngIf="testMailEnabled">
		<button
			[disabled]="!isUserAllowedActions || !isValid || sendTestStatus == SendStatuses.pending"
			type="button"
			class="btn-link"
			data-track-id="SendTestNotificationRule"
			data-track-type="Button"
			(click)="sendTestEmail()"
		>
			<wcd-shared-icon iconName="email" class="small-icon"></wcd-shared-icon>
			<span role="alert"><i class="loader-icon" [attr.aria-label]="'notificationRules_sendTestEmail_sending' | i18n" [hidden]="sendTestStatus !== SendStatuses.pending"></i></span>
			{{
				'notificationRules_sendTestEmail_' +
					(sendTestStatus === SendStatuses.pending ? 'sending' : 'send') | i18n
			}}
		</button>
		<span role="alert">
			<span
				class="wcd-padding-small-left"
				*ngIf="sendTestStatus !== SendStatuses.notStarted && sendTestStatus !== SendStatuses.pending"
				[class.error-message]="sendTestStatus === SendStatuses.error"
			>
				<wcd-shared-icon
					iconName="success"
					[hidden]="sendTestStatus !== SendStatuses.success"
					class="color-text-success-light ms-icon-top-2-fix"
					[ariaLabel]="''"
				></wcd-shared-icon>
				{{ 'notificationRules.sendTestEmail.' + SendStatuses[sendTestStatus] | i18n }}
			</span>
		</span>
	</ng-container>
</div>
