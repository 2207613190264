import { EntityRelationship, RelationshipType, EntityRelationshipRepositoryType } from '@microsoft/paris';
import { AuthenticatedScanSession } from './authenticated-scan-session.entity';
import { AuthenticatedScanResult } from './authenticated-scan-result.entity';
import { TvmAuthenticatedScanSharedEntityConfigurations } from '@wcd/domain';
import { AuthenticatedScanResultCsv } from './authenticated-scan-result-csv.entity';

@EntityRelationship({
	...TvmAuthenticatedScanSharedEntityConfigurations,
	sourceEntity: AuthenticatedScanSession,
	dataEntity: AuthenticatedScanResult,
	endpoint: (_, query) =>
		`commands/liveResults?sessionId=${encodeURIComponent(query.where['id'])}&fromTimestamp=0`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (AuthenticatedScanSession: AuthenticatedScanSession) => {
		return { id: AuthenticatedScanSession.id };
	},
})
export class AuthenticatedScanSessionRelationship
	implements EntityRelationshipRepositoryType<AuthenticatedScanSession, AuthenticatedScanResult> {}

@EntityRelationship({
	...TvmAuthenticatedScanSharedEntityConfigurations,
	sourceEntity: AuthenticatedScanSession,
	dataEntity: AuthenticatedScanResultCsv,
	endpoint: (_, query) =>
		`commands/liveResultsCsv?sessionId=${encodeURIComponent(query.where['id'])}&fromTimestamp=0`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (AuthenticatedScanSession: AuthenticatedScanSession) => {
		return { id: AuthenticatedScanSession.id };
	},
})
export class AuthenticatedScanSessionCsvRelationship
	implements EntityRelationshipRepositoryType<AuthenticatedScanSession, AuthenticatedScanResultCsv> {}
