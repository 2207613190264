import { DataQuery, DataQuerySortDirection, EntityField, EntityModelBase } from '@microsoft/paris';
import { RemediationActionType } from '../remediation/remediation-action-type.entity';
import { Machine } from '../machine/machine.entity';
import { ActionHistoryRelatedEntity } from './action-history-related-entities.value-object';
import { InvestigationActionStatus } from '../investigation/actions/investigation-action-status.entity';
import { InvestigatedUser } from '../investigation/investigated-identity.value-object';
import { isEqual, omit, uniqBy } from 'lodash-es';
import { AirsEntityTypeConfig } from './../airs_entity/airs-entity-type-config.entity';
import { OfficeUtils } from '../utils/office-utils';
import { AirsEntityType } from '../airs_entity/airs-entity-type.entity';
import { dateStrToUtc } from '../utils';
const CRYPTO = window.crypto || (<any>window).msCrypto; // for IE 11

// data retention in office for investigation is 30 days
const OFFICE_INVESTIGATION_DATA_RETENTION = 30;

export class ActionBaseEntity extends EntityModelBase<number | string> {
	@EntityField({ data: 'ActionId' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number | string;

	@EntityField({
		data: 'StartTime',
		parse: dateStr => dateStrToUtc(dateStr),
	})
	startTime: Date;

	@EntityField({
		data: 'EventTime',
		parse: dateStr => dateStrToUtc(dateStr),
	})
	updateTime: Date;

	@EntityField({
		data: 'EndTime',
		parse: dateStr => dateStrToUtc(dateStr),
	})
	endTime: Date;

	@EntityField({
		data: 'InvestigationId',
		parse: (investigationId, rawData) => {
			if (checkMcasOrMdiAction(rawData.ActionSource, rawData.Product)) {
				return null;
			} else if (
				rawData['AdditionalFields'] &&
				rawData['AdditionalFields']['add_liveresponse_prefix']
			) {
				return `LR${investigationId}`;
			}
			return investigationId;
		},
	})
	investigationId: number | string;

	@EntityField({
		data: 'AdditionalFields',
		parse: additionalFields => additionalFields && additionalFields['add_liveresponse_prefix'],
	})
	isLRPrefixedInvestigationId: boolean;

	@EntityField({ data: 'InvestigationDeeplink' })
	investigationDeeplink: string;

	@EntityField({
		data: 'InvestigationDeeplink',
		parse: (investigationDeeplink, rawData) => {
			return !checkMcasOrMdiAction(rawData.ActionSource, rawData.Product) && !!investigationDeeplink;
		},
	})
	isOfficeInvestigation: boolean;

	@EntityField({
		data: 'ActionAutomationType',
		// TODO: might need a type later
		parse: investigationType => investigationType === 'Manual Investigation',
	})
	isLiveResponse: boolean;

	@EntityField({ data: 'ActionType' })
	actionType: RemediationActionType;

	@EntityField({
		data: 'Machine',
		parse: machineData =>
			machineData && {
				MachineId: machineData.MachineId,
				SenseMachineId: machineData.MachineId,
				ComputerDnsName: machineData.ComputerName,
			},
	})
	machine: Machine;

	@EntityField({ data: 'ActionPendingDurationSeconds' })
	actionPendingDuration: number;

	@EntityField({ data: 'ResourcePendingDurationSeconds' })
	resourcePendingDurationSeconds: number;

	@EntityField({
		data: 'RelatedEntities',
		arrayOf: ActionHistoryRelatedEntity,
		parse: (relatedEntities, rawData) => {
			// TODO: revert to old parsing once BE fix is deployed
			const additionalEntities =
				rawData['AdditionalFields'] && rawData['AdditionalFields']['additional_entities'];
			let objs: Array<object>;
			try {
				if (!(additionalEntities && Object.keys(additionalEntities).length)) {
					objs = relatedEntities;
				} else {
					objs = Object.entries(additionalEntities).reduce((acc, [entityId, entity]) => {
						let entityType: string | number = entity['EntityType'];
						if (typeof entityType === 'string') {
							entityType = ENTITY_TYPE_MAP[entityType.toLowerCase()];
						}
						if (relatedEntities.find(e => e['EntityType'] === entityType)) {
							const entityId =
								entityType === AirsEntityType.Account
									? entity['Sid'] || entity['AadUserId']
									: entity['EntityIdUrn'];
							acc.push(
								Object.assign({ EntityId: entityId }, entity, {
									EntityType: entityType,
								})
							);
						}
						return acc;
					}, []);
				}
			} catch (e) {
				objs = relatedEntities;
			}
			return uniqBy(objs, isEqual);
		},
	})
	relatedEntities: Array<ActionHistoryRelatedEntity>;

	@EntityField({ data: 'EntityType' })
	entityType: AirsEntityTypeConfig;

	@EntityField({ data: 'ActionStatus' })
	status: InvestigationActionStatus;

	@EntityField({ data: 'ThreatType' })
	threatType: string;

	@EntityField({ data: 'ExpandInvestigationFields.ExpandReason' })
	expandReason: string;

	@EntityField({ data: 'ExpandInvestigationFields.ExpandSource' })
	expandSource: string;

	@EntityField({ data: 'ExpandInvestigationFields.ExpandedHostsCount' })
	expandedHostsCount: number;

	@EntityField({
		data: 'AdditionalFields.user_activity',
	})
	investigatedUser: InvestigatedUser;

	@EntityField({ data: 'BulkName' })
	bulkName: string;

	@EntityField({ data: 'BulkId' })
	approvalId: string;

	@EntityField({ data: 'Product' })
	product: ActionProduct;

	@EntityField({
		data: 'ActionSource',
		parse: actionSource => {
			if (actionSource === 'Advanced hunting') {
				return ActionSourceType.AdvancedHunting;
			} else if (actionSource === 'Custom detection') {
				return ActionSourceType.CustomDetection;
			}

			// otherwise, the enum value is already properly formatted and corresponds to specific ActionSourceType
			return actionSource as keyof typeof ActionSourceType;
		},
	})
	actionSourceI18nKey: ActionSourceType;

	@EntityField({
		data: 'AdditionalFields.action_failure_key',
	})
	actionFailureI18nKey?: ActionFailureReason;

	shortInvestigationId: string | number;
	shortApprovalId: string;
	_guid: string;
	doesInvestigationExist: boolean;
	isMcasOrMdiAction: boolean;

	constructor(data) {
		super(data);
		this.shortInvestigationId = this.isLRPrefixedInvestigationId
			? this.investigationId
			: OfficeUtils.getShortId(this.investigationId);
		this.shortApprovalId = this.approvalId && this.approvalId.substr(this.approvalId.length - 6);
		this._guid = ActionBaseEntity.uuidv4();
		this.isMcasOrMdiAction = checkMcasOrMdiAction(this.actionSourceI18nKey, this.product);

		if (this.isOfficeInvestigation) {
			const monthAgo = new Date();
			monthAgo.setDate(monthAgo.getDate() - OFFICE_INVESTIGATION_DATA_RETENTION);
			this.doesInvestigationExist = (this.endTime || this.updateTime) >= monthAgo;
		} else {
			this.doesInvestigationExist = true;
		}

		if (this.entityType && this.entityType.id === AirsEntityType.MailCluster) {
			// TODO: this is a patch for admin playbook scenario (there's dummy machine data in the response) and it should be removed soon
			this.machine = null;
		}
	}

	static convertActionCenterQueryToParams(
		dataQuery: DataQuery
	): {
		[index: string]: any;
	} {
		if (!dataQuery) return {};

		const pageSettings: { pageIndex?: number; pageSize?: number } = {};
		if (dataQuery.page) pageSettings.pageIndex = dataQuery.page;

		if (dataQuery.pageSize) pageSettings.pageSize = dataQuery.pageSize;

		return Object.assign(
			{},
			omit(dataQuery.where as object, ['page', 'page_size', 'pagesize', 'ordering']),
			pageSettings,
			dataQuery.sortBy &&
				dataQuery.sortBy.length && {
					sortByField: dataQuery.sortBy[0].field,
					sortOrder:
						dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
							? 'Ascending'
							: 'Descending',
				}
		);
	}

	/*
	RFC4122 compliant uuidv4 (https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript)
	 */
	private static uuidv4(): string {
		return (<any>[1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
			(c ^ (CRYPTO.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
		);
	}
}

function checkMcasOrMdiAction(actionSource, actionProduct): boolean {
	return (
		actionSource === ActionSourceType.MCASManualAction ||
		actionSource === ActionSourceType.MDIManualAction ||
		actionSource === ActionSourceType.MDIAutomaticAction ||
		actionProduct === ActionProduct.MDI
	);
}

const ENTITY_TYPE_MAP = {
	mailcluster: AirsEntityType.MailCluster,
	mailmessage: AirsEntityType.MailMessage,
	mailbox: AirsEntityType.Mailbox,
	submissionmail: AirsEntityType.SubmissionMail,
	mailboxconfiguration: AirsEntityType.MailboxConfiguration,
	url: AirsEntityType.URL,
	account: AirsEntityType.Account,
};

export enum ActionSourceType {
	Cloud = 'Cloud',
	OatpManual = 'OatpManual',
	AIRS = 'AIRS',
	OfficeActionState = 'OfficeActionState',
	MTP_BulkAction = 'MTP_BulkAction', // deprecated - we will get 'AdvancedHunting' key instead (keeping it here for backward compatibility)
	ThreatExplorer = 'ThreatExplorer',
	AirsLiveResponse = 'AirsLiveResponse',
	AirsCustomPlaybook = 'AirsCustomPlaybook',
	MCASManualAction = 'MCASManualAction',
	MDIManualAction = 'MDIManualAction',
	MDIAutomaticAction = 'MDIAutomaticAction',
	NDR = 'NDR',
	AutomatedIRActionState = 'AutomatedIRActionState', //same as 'AIRS'
	Portal = 'Portal',
	PublicApi = 'PublicApi',
	AdvancedHunting = 'AdvancedHunting',
	CustomDetection = 'CustomDetection',
	M365DManualAction = 'M365DManualAction', //same as 'MCASManualAction'
}

export enum ActionProduct {
	MDO = 'MDO',
	MDE = 'MDE',
	MDI = 'MDI',
}

export enum ActionFailureReason {
	// MDI action failure reasons
	GmsaInsufficientPermissions = 'GmsaInsufficientPermissions',
	UserNotFound = 'UserNotFound',
	UserMultipleMatches = 'UserMultipleMatches',
	RequestedDomainNotFound = 'RequestedDomainNotFound',
	NoGmsaForRequestedDomain = 'NoGmsaForRequestedDomain',
	InternalError = 'InternalError',
	DeviceSharedIp = 'DeviceSharedIp',
	DeviceNoAutomatedIR = 'DeviceNoAutomatedIR',
}
