// the values in this enum are the entity types in alertV3 (Case sensitive)
export enum AlertV3EntityTypes {
	URL = 'url',
	Account = 'account',
	File = 'file',
	Host = 'host',
	IP = 'ip',
	Process = 'process',
	Mailbox = 'mailbox',
	MailCluster = 'mailCluster',
	MailMessage = 'mailMessage',
	SecurityGroup = 'security-group',
	RegistryKey = 'registry-key',
	RegistryValue = 'registry-value',
	CloudApplication = 'cloud-application',
	OauthApplication = 'oauth-application',
	ActiveDirectoryDomain = 'ActiveDirectoryDomain',
	CloudLogonSession = 'cloud-logon-session',
	MailboxConfiguration = 'MailboxConfiguration',
	SubmissionMail = 'SubmissionMail', //currently not supported in InE

	// not yet part of AlertV3 but should be in the future (task: https://microsoft.visualstudio.com/OS/_workitems/edit/37376681)
	Driver = 'driver',
	Service = 'service',
	AppendedFile = 'appended-file',
	EmailUrl = 'email-url',
	User = 'user',
	WebPage = 'web-page',
	PersistenceMethod = 'persistence-method',
	UserActivity = 'user-activity',
}
