import { HuntingQueryBase } from './hunting-query.entity.base';
import { Entity } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { QueryKind } from './hunting-query.type';

/**
 * @deprecated use HuntingSavedQuery instead
 */
@Entity({
	singularName: 'Shared query',
	pluralName: 'Shared queries',
	endpoint: 'hunting/queries',
	allItemsEndpoint: '/shared',
	saveMethod: (item: Partial<HuntingSharedQuery>) => (item.id ? 'PATCH' : 'POST'),
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class HuntingSharedQuery extends HuntingQueryBase {
	readonly kind: QueryKind = 'shared';
	readonly shared = true;
	readonly isScheduled = false;
}
