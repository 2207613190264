import { SevilleModule } from '../../seville/seville.module';
import { EntityPanelsService } from '../../../../global_entities/services/entity-panels.service';
import { Machine } from '@wcd/domain';
import { Subscription } from 'rxjs';

SevilleModule.component('sidePaneMachine', {
	controllerAs: 'vm',
	bindings: {
		computerdnsname: '=',
		machineid: '=',
		hideIndicator: '=',
	},
	template: `
		<span class="side-pane-indicator-wrapper"
			ng-class="{ selected: vm.selected }"
			data-track-id="sidePaneButtonMachine"
			data-track-type="SidePaneToggleButton"
			role="button"
			aria-label="Open machine side pane"
			tabindex="0"
			ng-click="vm.toggleSidePane()"
			ng-if="!vm.hideIndicator"
			>
			<i class="ms-Icon pointer side-pane-indicator-icon"></i>
		</span>
		`,
	controller: [
		'entityPanelsService',
		function(entityPanelsService: EntityPanelsService) {
			const displayPanelSubscription: Subscription = entityPanelsService
				.getCurrentlyDisplayedItems(Machine)
				.subscribe((displayedMachines: Array<Machine>) => {
					this.selected = displayedMachines.some(machine => machine.id === this.machineid);
				});

			this.toggleSidePane = () => {
				if (this.selected) entityPanelsService.closeEntityPanel(Machine);
				else
					entityPanelsService.showEntity(
						Machine,
						new Machine({
							id: this.machineid,
							name: this.computerdnsname,
							machineId: this.machineid,
							senseMachineId: this.machineid,
						})
					);
			};

			this.$onDestroy = function() {
				displayPanelSubscription && displayPanelSubscription.unsubscribe();
			};
		},
	],
});
