export enum RemediationActionTypeTypes {
	file_quarantine = 'file_quarantine',
	service_quarantine = 'service_quarantine',
	driver_quarantine = 'driver_quarantine',
	release_file = 'release_file',
	kill_process = 'kill_process',
	kill_processes = 'kill_processes',
	block_ip = 'block_ip',
	block_url = 'block_url',
	pending_resource = 'pending_resource',
	initiate_dlp = 'initiate_dlp',
	limit_user_privileges_info = 'limit_user_privileges_info',
	remove_user_from_local_group = 'remove_user_from_local_group',
	start_multiple_investigations = 'start_multiple_investigations',
	persistence_method_quarantine = 'persistence_method_quarantine',
	confirm_compromised_user = 'confirm_compromised_user',
	undo_clean_resource = 'undo_clean_resource',
	live_response_command = 'live_response_command',
	remove_file = 'remove_file',

	// Office actions
	generic = 'generic',
	quarantine_registry_key = 'quarantine_registry_key',
	block_sender = 'block_sender',
	block_sender_domain = 'block_sender_domain',
	block_spo_file = 'block_spo_file',
	email_remediation = 'email_remediation',
	ews_setting_change_remediation = 'ews_setting_change_remediation',
	mail_delegation_remediation = 'mail_delegation_remediation',
	mail_forwarding_rule_remediation = 'mail_forwarding_rule_remediation',
	owa_setting_change_remediation = 'owa_setting_change_remediation',
	user_inbox_rules_analyzer = 'user_inbox_rules_analyzer',
	file_hash_reputation_analyzer = 'file_hash_reputation_analyzer',
	user_admin_analyzer = 'user_admin_analyzer',
	user_inbox_rules_remediation = 'user_inbox_rules_remediation',
	url_reputation_analyzer = 'url_reputation_analyzer',
	interflow_ip_analyzer = 'interflow_ip_analyzer',
	interflow_domain_analyzer = 'interflow_domain_analyzer',
	email_zapper = 'email_zapper',
	block_url_remediation = 'block_url_remediation',
	force_password_reset_remediation = 'force_password_reset_remediation',
	move_to_inbox = 'move_to_inbox',
	move_to_junk = 'move_to_junk',
	move_to_deleted_items = 'move_to_deleted_items',
	hard_email_remediation = 'hard_email_remediation',
	delete_attachment = 'delete_attachment',
	submit_for_detonation = 'submit_for_detonation',
	office_block_ip = 'office_block_ip',

	// Cloud actions
	isolate_response = 'isolate_response',
	unisolate = 'unisolate',
	stop_and_quarantine = 'stop_and_quarantine',
	request_sample = 'request_sample',
	restrict_app_execution_response = 'restrict_app_execution_response',
	unrestrict_app_execution_response = 'unrestrict_app_execution_response',
	restrict_code_execution = 'restrict_code_execution',
	unrestrict_code_execution = 'unrestrict_code_execution',
	antivirus_scan_response = 'antivirus_scan_response',
	collect_investigation_package_response = 'collect_investigation_package_response',
	offboard_device = 'offboard_device',
	remediation_response = 'remediation_response',

	// Msec Identity actions
	confirm_user_compromised = 'confirm_user_compromised',
	suspend_user = 'suspend_user',
	invalidate_all_refresh_tokens_for_a_user = 'invalidate_all_refresh_tokens_for_a_user',

	// MDI actions
	disable_user = 'disable_user',
	enable_user = 'enable_user',

	// Magellan actions
	contain_device = 'contain_device',
	uncontain_device = 'uncontain_device',
}
