import { PendingActionDecisionTypes } from './pending-action-decision-types.enum';

export const pendingActionDecisionValues = [
	{
		id: PendingActionDecisionTypes.Declined,
		name: 'Declined',
		className: 'error',
		fabricIcon: 'Blocked',
	},
	{
		id: PendingActionDecisionTypes.Approved,
		name: 'Approved',
		className: 'success',
		fabricIcon: 'Completed',
	},
	{
		id: PendingActionDecisionTypes.Timeout,
		name: 'Timed Out',
		className: 'error',
		fabricIcon: 'BufferTimeAfter',
	},
	{
		id: PendingActionDecisionTypes.Skipped,
		name: 'Skipped',
		className: 'neutral',
		fabricIcon: 'Blocked',
	},
	{
		id: PendingActionDecisionTypes.Unknown,
		name: 'Unknown',
		className: 'neutral',
	},
];
