import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Disableable, DISABLEABLE_TOKEN } from '../models/disableable.interface';

const CHECKLIST_ITEM_HEIGHT = 26;

@Component({
	selector: 'wcd-checklist',
	templateUrl: './checklist.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ChecklistComponent),
			multi: true,
		},
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => ChecklistComponent),
		},
	],
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./checklist.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistComponent<TValue extends string | number = any>
	implements OnInit, ControlValueAccessor, Disableable {
	private _values: Array<ChecklistValue<TValue>>;

	@Input()
	set values(values: Array<ChecklistValue<TValue>>) {
		this._values = values;
		this.setSelectedValues();
	}

	get values(): Array<ChecklistValue<TValue>> {
		return this._values;
	}

	@Input() disableEmptySelection = false;
	@Input() name: string;
	@Input() isDisabled = false;
	@Input() trackComponent: string;
	@Input() selectById = true; // means that value selected and 'output'-ed is the object id
	@Input() label = 'name';
	@Input() formatLabel: (value: any) => string;
	@Input() navigateUsingArrowKeysOnly: boolean = false;
	@Input() ariaLabel?: string;
	@Input() wrapLabel: boolean = false;
	itemHeight: number = CHECKLIST_ITEM_HEIGHT; // item height must be determined explicitly when using virtual scroll
	@Input() withVirtualScroll: boolean = false;

	@Input() listRole: 'menu' | 'listbox' = 'menu';
	@Input() itemListRole: 'presentation' | 'option' = 'presentation';

	selectedValues: { [index: string]: boolean } = {};
	value: Array<TValue | ChecklistValue<TValue>>;
	isLastSelectedValue = false;
	disableReason: string;

	constructor(protected changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit() {
		if (this.value) this.setSelectedValues();
	}

	onChange = (_: Array<ChecklistValue<TValue> | TValue>) => {};
	onTouched = () => {};

	writeValue(value: Array<TValue | ChecklistValue<TValue>>): void {
		this.value =
			value &&
			value.map(_value => (this.isChecklistValue(_value) && this.selectById ? _value.id : _value));
		this.changeDetectorRef.markForCheck();

		if (this.values) {
			this.setSelectedValues();
		} else {
			this.selectedValues = {};
		}
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	getItemLabel(item: any): string {
		if (this.formatLabel) return this.formatLabel(item);

		return item[this.label];
	}

	isChecklistValue(value: any): value is ChecklistValue {
		return value && value.id && value.name;
	}

	setSelectedValues() {
		this.selectedValues = {};
		this.values.forEach(
			_value =>
				(this.selectedValues[_value.id] = this.value
					? this.value.some(
							val =>
								_value.id === (this.selectById ? val : this.isChecklistValue(val) && val.id)
					  )
					: false)
		);
		this.setIsLast();
		this.changeDetectorRef.detectChanges();
	}

	setValue() {
		this.onChange((this.value = this.getChecklistValue()));
	}

	selectAll() {
		this.setAll(true);
	}

	selectNone() {
		this.setAll(false);
	}

	setAll(isSelected: boolean) {
		this.values.forEach(value => !value.disabled && (this.selectedValues[value.id] = isSelected));

		if (!isSelected && this.disableEmptySelection) this.selectedValues[this.values[0].id] = true;

		this.setValue();
	}

	selectValues(selectedValues: Array<TValue>) {
		this.values.forEach(value => (this.selectedValues[value.id] = selectedValues.includes(value.id)));
		this.setValue();
	}

	isLastCheckedValue(): boolean {
		const selectedKeys = Object.keys(this.selectedValues).filter(key => this.selectedValues[key]);

		return selectedKeys.length === 1;
	}

	setIsLast() {
		this.isLastSelectedValue = this.isLastCheckedValue();
	}

	getChecklistValue(): Array<TValue | ChecklistValue<TValue>> {
		this.setIsLast();
		return this.values
			.filter(value => this.selectedValues[value.id])
			.map(value => (this.selectById ? value.id : value));
	}
}

export interface ChecklistValue<TValue extends string | number = any> {
	id: TValue;
	name: string;
	invalid?: boolean;
	disabled?: boolean;
	helpText?: string;
	description?: string;
	style?: any;
}
