/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../projects/charts/src/lib/time-series/time-series-chart.component.ngfactory";
import * as i2 from "../../../../../projects/charts/src/lib/time-series/time-series-chart.component";
import * as i3 from "../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i5 from "@angular/common";
import * as i6 from "./machine-protection-active-status-overtime-widget";
import * as i7 from "./services/reports.service";
import * as i8 from "./services/machine-protection-report.service";
import * as i9 from "./machine-protection-status-mapping";
var styles_MachineProtectionActiveStatusOvertimeWidget = [];
var RenderType_MachineProtectionActiveStatusOvertimeWidget = i0.ɵcrt({ encapsulation: 2, styles: styles_MachineProtectionActiveStatusOvertimeWidget, data: {} });
export { RenderType_MachineProtectionActiveStatusOvertimeWidget as RenderType_MachineProtectionActiveStatusOvertimeWidget };
export function View_MachineProtectionActiveStatusOvertimeWidget_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "wcd-time-series-chart", [], null, null, null, i1.View_TimeSeriesChartComponent_0, i1.RenderType_TimeSeriesChartComponent)), i0.ɵdid(2, 770048, null, 0, i2.TimeSeriesChartComponent, [i0.ElementRef, i3.I18nService, i4.TzDateService], { settings: [0, "settings"], data: [1, "data"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.chartSettings$)); var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 4).transform(_co.data$))) == null) ? null : tmp_1_0.data); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_MachineProtectionActiveStatusOvertimeWidget_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_MachineProtectionActiveStatusOvertimeWidget_0, RenderType_MachineProtectionActiveStatusOvertimeWidget)), i0.ɵdid(1, 245760, null, 0, i6.MachineProtectionActiveStatusOvertimeWidget, [i7.ReportsService, i3.I18nService, i8.MachineProtectionReportService, i9.MachineProtectionStatusMapping], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MachineProtectionActiveStatusOvertimeWidgetNgFactory = i0.ɵccf("ng-component", i6.MachineProtectionActiveStatusOvertimeWidget, View_MachineProtectionActiveStatusOvertimeWidget_Host_0, {}, {}, []);
export { MachineProtectionActiveStatusOvertimeWidgetNgFactory as MachineProtectionActiveStatusOvertimeWidgetNgFactory };
