import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<react-tab-wrapper componentName="configurationManagementPage@wicd-interoperability/main" componentId="MdeAttach"></react-tab-wrapper>
	`
})
export class SccMdeAttachSectionComponent  {
}
