import { Injectable } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { DataExportSettingsEntity } from '@wcd/domain';
import { Observable, of } from 'rxjs';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { DataExportSettingsDetailsComponent } from '../components/data-export-details.component';
import { I18nService } from '@wcd/i18n';
import { DataviewRepositoryService } from '../../../dataviews/services/dataview-repository.service';
import { AuthService } from '@wcd/auth';
import { PanelService } from '@wcd/panels';

const MAX_EXPORT_ITEMS = 5;

@Injectable()
export class DataExportEntityTypeService implements EntityTypeService<DataExportSettingsEntity> {
	readonly entityType: EntityType<DataExportSettingsEntity>;

	private dataExportRepository: Repository<DataExportSettingsEntity>;

	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private panelService: PanelService,
		private dataviewRepositoryService: DataviewRepositoryService,
		private authService: AuthService
	) {
		this.dataExportRepository = this.paris.getRepository(DataExportSettingsEntity);

		this.entityType = {
			id: 'data-export-settings-entity',
			entity: DataExportSettingsEntity,
			loadFullEntityInPanel: false,
			getEntityName: () =>
				this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.name'),
			singleEntityPanelComponentType: DataExportSettingsDetailsComponent,
			getItemParams: (data: DataExportSettingsEntity) => {
				return { id: data.id };
			},
			getActions: (
				dataExportSettings: Array<DataExportSettingsEntity>
			): Observable<ReadonlyArray<ItemActionModel>> => {
				const removeItemAction = this.dataviewRepositoryService.getRemoveItemAction(
					this.dataExportRepository,
					null,
					true
				);

				// workspace is an indication that rule was automatically generated and can't be edited from MTP UI
				const entitiesContainsWorkspace = !!dataExportSettings.find(entity => !!entity.workspaceProperties);

				if (!this.authService.isGlobalAdmin) {
					removeItemAction.disabled = true;
					removeItemAction.tooltip = this.i18nService.get(
						'endpointManagement.nonWindows.applications.dataExport.addNew.noPermissionsTooltip'
					);
				}

				if (entitiesContainsWorkspace) {
					removeItemAction.disabled = true;
					removeItemAction.tooltip = this.i18nService.get('endpointManagement_nonWindows_applications_dataExport_sentinalManaged_noPermissionsTooltip');
				}

				const actions: Array<ItemActionModelConfig> = [removeItemAction];

				return of(actions.map(itemActionConfig => new ItemActionModel(itemActionConfig)));
			},
		};
	}
}
