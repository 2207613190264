import { EntityModelBase, EntityField } from '@microsoft/paris';

export abstract class NetworkDeviceBase extends EntityModelBase<string> {
	@EntityField({
		required: true,
	})
	readonly ipAddress: string;

	@EntityField({
		required: false,
	})
	readonly type: string;

	@EntityField({
		required: false,
	})
	readonly os: string;

	@EntityField({
		required: false,
	})
	readonly hostName: string;

	@EntityField({
		required: false,
	})
	readonly lastSeen: Date;
}
