import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';
import { RemediationAction } from '@wcd/domain';
import { Router } from '@angular/router';
import { RemediationRejectData } from '../services/remediation-reject-reason.interface';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'quarantine-files-remediation-decline-modal',
	template: `
		<modal (close)="cancel.emit()" [settings]="settings">
			<form
				class="wcd-full-height wcd-flex-vertical"
				data-track-component="DeclineQuarantineFiles"
				#declineForm="ngForm"
				(submit)="decline()"
			>
				<div class="wcd-flex-1 wcd-scroll-vertical dialog-contents">
					<div class="wcd-margin-bottom" style="min-width: 400px">
						<remediation-decline-reason
							[(ngModel)]="declineReason"
							name="quarantine-files-decline-reason"
						>
						</remediation-decline-reason>
					</div>
				</div>
				<footer class="dialog-footer wcd-flex-none right-text">
					<button
						type="submit"
						class="btn btn-primary"
						data-track-id="DeclineQuarantine"
						data-track-type="Button"
						[disabled]="declineForm.form.invalid || isSaving || !declineReason"
					>
						<i class="loader-icon" [hidden]="!isSaving"></i>
						{{
							isSaving
								? i18nService.strings.remediation_declineModal_pleaseWait
								: i18nService.strings.remediation_declineModal_decline
						}}
					</button>
					<button
						type="button"
						class="btn btn-cancel"
						data-track-id="Cancel"
						data-track-type="Button"
						(click)="cancel.emit()"
					>
						{{ i18nService.strings.buttons_cancel }}
					</button>
				</footer>
			</form>
		</modal>
	`,
})
export class QuarantineFilesRemediationDeclineModalComponent<T> extends ModalContainer
	implements OnInit, OnDestroy {
	@Input() inputs: Array<T>;
	@Input() onDone: () => any;
	@Input()
	dismissPending: (
		inputs: Array<T>,
		declineReason: RemediationRejectData
	) => Promise<any>;

	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();

	declineReason: RemediationRejectData;
	isSaving: boolean = false;

	constructor(protected router: Router, public i18nService: I18nService) {
		super(router);
	}

	async decline() {
		this.isSaving = true;

		try {
			await this.dismissPending(this.inputs, this.declineReason);
			this.onDone && this.onDone();
			this.isSaving = false;
		} catch (e) {
			this.isSaving = false;
		}
	}
}
