var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, Injector, OnInit, OnDestroy, } from '@angular/core';
import { filter } from 'rxjs/operators';
import { RemediationTask, RemediationCategory, RemediationTicketStateValue, ItsmTool, RemediationType, RemediationTaskPriority, RemediationTaskStateValue, } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { RemediationTaskDisplayRemainingDaysPipe } from '../../../tvm/pipes/remediation-task-display-remaining-days.pipe';
import { CalculateRemainingDaysPipe } from '../../../shared/pipes/calculate-remaining-days.pipe';
import { Router } from '@angular/router';
import { Feature, FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { TvmProductivityImpactService } from '../../../tvm/services/tvm-productivity-impact.service';
import { MessageBarType } from 'office-ui-fabric-react';
import { CommonRemediationService } from '../../../@entities/@tvm/remediation/common-remediation-service/common-remediation.service';
import { AppConfigService } from '@wcd/app-config';
import { TvmMachineGroupsFilterService } from '../../../tvm/services/tvm-machine-groups-filter.service';
var RemediationTaskEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(RemediationTaskEntityDetailsComponent, _super);
    function RemediationTaskEntityDetailsComponent(activatedEntity, calculateRemainingDaysPipe, remediationTaskDisplayRemainingDaysPipe, router, featuresService, injector, i18nService, productivityImpactService, textsService, machineGroupsFilterService, commonRemediationService, appConfigService) {
        var _this = _super.call(this, injector) || this;
        _this.activatedEntity = activatedEntity;
        _this.calculateRemainingDaysPipe = calculateRemainingDaysPipe;
        _this.remediationTaskDisplayRemainingDaysPipe = remediationTaskDisplayRemainingDaysPipe;
        _this.router = router;
        _this.i18nService = i18nService;
        _this.productivityImpactService = productivityImpactService;
        _this.textsService = textsService;
        _this.machineGroupsFilterService = machineGroupsFilterService;
        _this.commonRemediationService = commonRemediationService;
        _this.appConfigService = appConfigService;
        _this.shouldShowMessageBar = false;
        _this.loggedOnUsersClick = new EventEmitter();
        _this.isDeviceGroupEnabled = featuresService.isEnabled(Feature.TvmRemediationTaskDeviceGroups);
        _this.isServiceNowEnabled = featuresService.isEnabled(Feature.TvmServiceNowIntegration);
        return _this;
    }
    Object.defineProperty(RemediationTaskEntityDetailsComponent.prototype, "remediationTask", {
        get: function () {
            return this._remediationTask;
        },
        set: function (task) {
            this.showRequestedForSection =
                this.productivityImpactService.isProductivityImpactExposed &&
                    !!task.taskArgs.productivityImpactRemediationType;
            if (this.showRequestedForSection) {
                this.appliedToProductivityImpactLabel = this.textsService.getSelectedProductivityRemediation(task.taskArgs.productivityImpactRemediationType);
            }
            if (task.taskArgs.softwareArgs &&
                task.taskArgs.softwareArgs.taskType == RemediationType.AttentionRequired) {
                this.showHyphenForRemediationTaskStatusBar = true;
            }
            this._remediationTask = task;
        },
        enumerable: true,
        configurable: true
    });
    RemediationTaskEntityDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.shouldShowDeviceScope =
            this.appConfigService.hasMachineGroups &&
                this.isDeviceGroupEnabled;
        if (this.shouldShowDeviceScope) {
            this.getMachineGroupsFilterTextHover(this._remediationTask.displayedRbacGroupIds);
        }
        if (!this.remediationTask) {
            this._subscription = this.activatedEntity.currentEntity$
                .pipe(filter(function (entity) { return entity instanceof RemediationTask; }))
                .subscribe(function (remediationTask) {
                _this.remediationTask = remediationTask;
                _this.initPropertiesFromRemediationTask();
            });
        }
        else {
            this.initPropertiesFromRemediationTask();
        }
    };
    RemediationTaskEntityDetailsComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    RemediationTaskEntityDetailsComponent.prototype.navigateToRecommendations = function () {
        this.router.navigate(['/security-recommendations', this._relatedRecommendationId], {
            queryParams: {
                search: "" + this.getRelatedRecommendationId(false),
            },
        });
    };
    RemediationTaskEntityDetailsComponent.prototype.getRelatedRecommendationId = function (forNav) {
        if (forNav === void 0) { forNav = true; }
        return this._remediationTask.taskArgs.category === RemediationCategory.SecurityConfiguration
            ? "" + (forNav ? 'sca-_-' : '') + this._remediationTask.taskArgs.securityConfigurationArgs.scid
            : "" + (forNav ? 'va-_-' : '') + this._remediationTask.taskArgs.softwareArgs.productId;
    };
    RemediationTaskEntityDetailsComponent.prototype.navigateToRelatedComponent = function () {
        this.router.navigate(['/software-inventory', this._remediationTask.taskArgs.softwareArgs.productId]);
    };
    RemediationTaskEntityDetailsComponent.prototype.hideMessage = function () {
        this.shouldShowMessageBar = false;
    };
    RemediationTaskEntityDetailsComponent.prototype.getMachineGroupsFilterTextHover = function (groups) {
        var _this = this;
        this.commonRemediationService.rbacGroupIdToNameMap$.subscribe(function (rbacDictionary) {
            _this.numberOfMachineGroupsText = _this.i18nService.get(groups.value.length > 1 ? "tvm.remediationTask.machineGroupsScopeText.plural" : "tvm.remediationTask.machineGroupsScopeText", { count: groups.value.length });
            var machineGroupNamesToShow = groups.value
                .map(function (rbacGroupId) { return rbacDictionary[rbacGroupId]; })
                .filter(Boolean);
            // Then show it in hover over, with a machine group per line
            _this.hoverMachineGroups = machineGroupNamesToShow.join('\n');
        });
    };
    RemediationTaskEntityDetailsComponent.prototype.setMessageBarProp = function () {
        if (this.isServiceNowEnabled &&
            this._remediationTask &&
            this._remediationTask.ticket &&
            this._remediationTask.ticket.itsmTool === ItsmTool.ServiceNow &&
            this._remediationTask.ticket.status === RemediationTicketStateValue.Unknown &&
            !this._remediationTask.ticket.rawStatus &&
            !this._remediationTask.ticket.ErrorDetails) {
            this.messageBarType = MessageBarType.warning;
            this.messageBarString = this.i18nService.strings.tvm_remediationTask_read_servicenow_errors_200_200;
            this.shouldShowMessageBar = true;
        }
    };
    RemediationTaskEntityDetailsComponent.prototype.getRemainingDays = function () {
        var remainingDays = this.calculateRemainingDaysPipe.transform(this._remediationTask.dueDate);
        return this.remediationTaskDisplayRemainingDaysPipe.transform(this._remediationTask, remainingDays);
    };
    RemediationTaskEntityDetailsComponent.prototype.shouldShowTable = function (ticket) {
        return (ticket &&
            ticket.status !== 'CompletedTask' &&
            (this.isServiceNowEnabled || ticket.itsmTool !== ItsmTool.ServiceNow));
    };
    RemediationTaskEntityDetailsComponent.prototype.getRemediationTicketStatus = function () {
        var ticket = this._remediationTask.ticket;
        if (!ticket)
            return null;
        return ticket.rawStatus
            ? ticket.rawStatus
            : this.i18nService.get("tvm.remediationTask.ticket.status." + ticket.status);
    };
    RemediationTaskEntityDetailsComponent.prototype.getRemediationStatusBarTitle = function () {
        return this.i18nService.get("tvm.remediationTask.status." + this.remediationTask.status.value);
    };
    RemediationTaskEntityDetailsComponent.prototype.getRemediationPriorityText = function () {
        return this.remediationTask.priority !== 0
            ? this.i18nService.get("tvm_remediationTask_priority_" + RemediationTaskPriority[this.remediationTask.priority])
            : this.i18nService.get('notAvailable.short');
    };
    RemediationTaskEntityDetailsComponent.prototype.initPropertiesFromRemediationTask = function () {
        this._relatedRecommendationId = this.getRelatedRecommendationId();
        this.remediationTicketStatus = this.getRemediationTicketStatus();
        this.remediationStatusBarTitle = this.getRemediationStatusBarTitle();
        this.relatedComponent = this.textsService.getText(TextToken.RemediationRelatedComponent, this.remediationTask);
        this.remainingDays = this.getRemainingDays();
        this.priorityText = this.getRemediationPriorityText();
        this.setMessageBarProp();
        this.completedBy = this.textsService.getText(TextToken.RemediationTaskCompletedBy, this.remediationTask);
        this.showTable = this.shouldShowTable(this.remediationTask.ticket);
        this.showDueDate = !(this.remediationTask.status.value === RemediationTaskStateValue.Completed ||
            (this.remediationTask.taskArgs.softwareArgs &&
                this.remediationTask.taskArgs.softwareArgs.taskType === RemediationType.AttentionRequired));
    };
    return RemediationTaskEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { RemediationTaskEntityDetailsComponent };
