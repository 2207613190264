var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmEndPoint, DefaultApiVersion } from '../..';
import { getEndpointForItsmTool } from '../remediation-task/remediation-task-ticket-itsm-tool.enum';
import { ServiceNowConfigDetails } from './servicenow-config-details.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.tvm + "/" + TvmEndPoint.ItsmProvisioning; }, ɵ1 = function (item) {
    return "services/" + getEndpointForItsmTool(item.itsmTool) + "/configurations";
}, ɵ2 = function (items) {
    return "services/" + getEndpointForItsmTool(items[0].itsmTool) + "/configurations";
};
var ItsmProvisioning = /** @class */ (function (_super) {
    __extends(ItsmProvisioning, _super);
    function ItsmProvisioning() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'endpoint',
        }),
        __metadata("design:type", String)
    ], ItsmProvisioning.prototype, "endpoint", void 0);
    __decorate([
        EntityField({
            data: 'clientId',
        }),
        __metadata("design:type", String)
    ], ItsmProvisioning.prototype, "clientId", void 0);
    __decorate([
        EntityField({
            data: 'clientSecret',
        }),
        __metadata("design:type", String)
    ], ItsmProvisioning.prototype, "clientSecret", void 0);
    __decorate([
        EntityField({
            data: 'redirectToWhenCompleted',
        }),
        __metadata("design:type", String)
    ], ItsmProvisioning.prototype, "redirectToWhenCompleted", void 0);
    __decorate([
        EntityField({
            data: 'redirect',
        }),
        __metadata("design:type", String)
    ], ItsmProvisioning.prototype, "redirect", void 0);
    __decorate([
        EntityField({
            data: 'isProvisioned',
        }),
        __metadata("design:type", Boolean)
    ], ItsmProvisioning.prototype, "isProvisioned", void 0);
    __decorate([
        EntityField({
            data: 'details',
        }),
        __metadata("design:type", ServiceNowConfigDetails)
    ], ItsmProvisioning.prototype, "serviceNowDetails", void 0);
    ItsmProvisioning = __decorate([
        Entity({
            singularName: 'Itsm Provisioning',
            pluralName: 'Itsm Provisioning',
            endpoint: 'services',
            baseUrl: ɵ0,
            saveMethod: 'PUT',
            customHeaders: __assign({}, DefaultApiVersion, { 'Cache-control': 'no-cache' }),
            parseSaveQuery: ɵ1,
            parseRemoveQuery: ɵ2,
        })
    ], ItsmProvisioning);
    return ItsmProvisioning;
}(EntityModelBase));
export { ItsmProvisioning };
export { ɵ0, ɵ1, ɵ2 };
