var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { ChangeDetectorRef, Injector, OnDestroy, OnInit, } from '@angular/core';
import { HuntingRuleCustomSupportedActionType, ScheduledHuntingRuleActionRelationship, Outbreak, } from '@wcd/domain';
import { EntityDetailsComponentBase } from '../../../global_entities/components/entity-details/entity-details.component.base';
import { orderBy, lowerFirst } from 'lodash-es';
import { Paris } from '@microsoft/paris';
import { ScheduledHuntingActionEntityTypeService } from '../services/scheduled-hunting-action.entity-type.service';
import { ScheduledHuntingActionsFields } from '../scheduled-hunting.actions.fields';
import { DataTableComponent } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { tenantContextCache } from '@wcd/auth';
import { AppFlavorConfig } from '@wcd/scc-common';
var MAX_LAST_RUN_ACTIONS = 5;
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["AlertDetails"] = "scheduled-hunting-entity-alert-details";
    CollapsibleID["ExecutionDetails"] = "scheduled-hunting-entity-execution-details";
    CollapsibleID["LastRunActions"] = "scheduled-hunting-entity-last-run-actions";
    CollapsibleID["MachineGroups"] = "scheduled-hunting-entity-machine-groups";
})(CollapsibleID || (CollapsibleID = {}));
var ScheduledHuntingEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(ScheduledHuntingEntityDetailsComponent, _super);
    function ScheduledHuntingEntityDetailsComponent(injector, appContextService, changeDetectorRef, featuresService, paris, _fields, scheduledHuntingActionEntityTypeService, i18nService, router) {
        var _this = _super.call(this, injector) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.featuresService = featuresService;
        _this.paris = paris;
        _this._fields = _fields;
        _this.scheduledHuntingActionEntityTypeService = scheduledHuntingActionEntityTypeService;
        _this.i18nService = i18nService;
        _this.router = router;
        _this.supportedCustomActionTypes = HuntingRuleCustomSupportedActionType;
        _this.actionTypes = [
            _this.supportedCustomActionTypes.IsolateMachine,
            _this.supportedCustomActionTypes.CollectInvestigationPackage,
            _this.supportedCustomActionTypes.RunAntivirusScan,
            _this.supportedCustomActionTypes.InitiateInvestigation,
            _this.supportedCustomActionTypes.AllowFile,
            _this.supportedCustomActionTypes.BlockFile,
            _this.supportedCustomActionTypes.QuarantineFile,
        ];
        _this.lastRunActions = [];
        _this.showingAllActions = false;
        _this.collapsibleID = CollapsibleID;
        var tenantContext = tenantContextCache.appConfig;
        _this.rbacMachineGroupsFeatureEnabled = featuresService.isEnabled(Feature.RbacMachineGroups);
        _this.customActionsFeatureEnabled = featuresService.isEnabled(Feature.HuntingCustomActions);
        _this.showImpactedEntities =
            appContextService.isMtp && featuresService.isEnabled(Feature.HuntingCustomDetectionUsingEvidence);
        _this.threatAnalyticsCustomDetectionFeatureEnabled =
            featuresService.isEnabled(Feature.ThreatAnalyticsCustomDetection) &&
                ((tenantContext.IsMdatpActive &&
                    AppFlavorConfig.threatAnalytics.mde.mdeFlavors.includes(tenantContext.MdeFlavor)) ||
                    tenantContext.IsOatpActive);
        _this.ruleActionsRepository = paris.getRelationshipRepository(ScheduledHuntingRuleActionRelationship);
        return _this;
    }
    Object.defineProperty(ScheduledHuntingEntityDetailsComponent.prototype, "rule", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledHuntingEntityDetailsComponent.prototype, "fields", {
        get: function () {
            return this._fields.dataTableFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledHuntingEntityDetailsComponent.prototype, "allActionRunsLink", {
        get: function () {
            return this.scheduledHuntingActionEntityTypeService.entityType.getEntitiesLink(this.lastRunActions);
        },
        enumerable: true,
        configurable: true
    });
    ScheduledHuntingEntityDetailsComponent.prototype.trackByAction = function (index, item) {
        return item.actionType;
    };
    Object.defineProperty(ScheduledHuntingEntityDetailsComponent.prototype, "ruleMachineGroups", {
        get: function () {
            if (!this._ruleMachineGroups) {
                //we have to remove undefined machine groups because of a server bug that
                //returns deleted machine group ids in the hunting rule
                this._ruleMachineGroups = orderBy(this.entity.machineGroups.filter(Boolean), [
                    function (machineGroup) { return machineGroup.name.toLowerCase(); },
                ]);
            }
            return this._ruleMachineGroups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledHuntingEntityDetailsComponent.prototype, "alertCategoryText", {
        get: function () {
            return this.i18nService.get('reporting.alertsByCategory.' + lowerFirst(this.rule.alertCategory));
        },
        enumerable: true,
        configurable: true
    });
    ScheduledHuntingEntityDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.routerSubscription = this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.showingAllActions = _this.setShowingAllAction(event.url);
            _this.changeDetectorRef.detectChanges();
        });
        this.showingAllActions = this.setShowingAllAction(this.router.url);
        this.changeDetectorRef.detectChanges();
        this.getThreatAnalyticsReportText$ = this.paris
            .getItemById(Outbreak, this.rule.threatId)
            .pipe(take(1));
    };
    ScheduledHuntingEntityDetailsComponent.prototype.ngOnDestroy = function () {
        if (this.routerSubscription)
            this.routerSubscription.unsubscribe();
    };
    ScheduledHuntingEntityDetailsComponent.prototype.setShowingAllAction = function (url) {
        return url.split('?')[0].split('/').pop() == 'actions';
    };
    ScheduledHuntingEntityDetailsComponent.prototype.updateTable = function () {
        if (this.dataTable) {
            this.dataTable.updateHeaderCells();
        }
    };
    ScheduledHuntingEntityDetailsComponent.prototype.setEntity = function (entity) {
        var _this = this;
        _super.prototype.setEntity.call(this, entity);
        this.changeDetectorRef.markForCheck();
        this.ruleActionsRepository.sourceItem = this.rule;
        this.ruleActionsRepository.query({ where: { range: 180, lastRunOnly: true } }).subscribe(function (data) {
            _this.lastRunActions = data.items.slice(0, MAX_LAST_RUN_ACTIONS);
            _this.moreLastRunActionsExist = data.items.length > MAX_LAST_RUN_ACTIONS;
            _this.changeDetectorRef.markForCheck();
        });
    };
    ScheduledHuntingEntityDetailsComponent.prototype.getImpactedEntityName = function (entity) {
        return this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.entities.' + lowerFirst(entity.entityType));
    };
    ScheduledHuntingEntityDetailsComponent.prototype.showAllActionRuns = function () {
        this.router.navigateByUrl(this.allActionRunsLink);
    };
    return ScheduledHuntingEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { ScheduledHuntingEntityDetailsComponent };
