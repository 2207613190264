import { File } from '@wcd/domain';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EntityResolver } from '../../../global_entities/services/entity.resolver';
import { Paris, DataEntityType } from '@microsoft/paris';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityIdRouteService } from '../../../global_entities/services/entity-id-route.service';
import { Observable, defer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { toObservable } from '../../../utils/rxjs/utils';
import { recursiveDecodeURIComponent } from '../../../utils/url-encoding/url-encoding-utils';
import { AppContextService } from '@wcd/config';

@Injectable()
export class FileEntityResolver extends EntityResolver<File> implements Resolve<File> {
	constructor(
		paris: Paris,
		activatedEntity: ActivatedEntity,
		globalEntityTypesService: GlobalEntityTypesService,
		router: Router,
		entityIdRouteService: EntityIdRouteService,
		appContext: AppContextService
	) {
		super(paris, activatedEntity, globalEntityTypesService, router, entityIdRouteService, appContext);
	}

	protected loadEntity(
		entityConstructor: DataEntityType<File>,
		entityId: string,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
		params: { [key: string]: any }
	): Observable<File> {
		const namelessFile$: Observable<File> = defer(() =>
			super.loadEntity(entityConstructor, entityId, route, state, params)
		);

		// by default, files have no name - they're identified by sha
		// in cases we reach the file page from a specific file context, the name is passed as a matrix parameter after the sha
		const fileNameParam = route.paramMap.get('name');
		if (fileNameParam) {
			const fileName = recursiveDecodeURIComponent(fileNameParam);
			const fileWithName$ = this.paris.createItem(File, { Id: entityId, FileName: fileName });
			const fileEntityType = this.globalEntityTypesService.getEntityType(File);

			return fileWithName$.pipe(
				switchMap(file => toObservable(fileEntityType.getItem(file, namelessFile$)))
			);
		}

		return namelessFile$;
	}
}
