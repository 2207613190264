import { AppInsightsService } from './../../../../insights/services/app-insights.service';
import { SevilleModule } from '../../seville/seville.module';
import { userAccountService } from '../../seville/services/seville.userAccounts';
import { RegExpService } from '@wcd/shared';
import { SevilleUtils } from '../../common/services/seville.utils';
import { AppConfigService } from '@wcd/app-config';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { cloneDeep } from 'lodash-es';
import { breadcrumbsStateService } from '@wcd/shared';
import { AlertsService } from '../../../../@entities/alerts/services/alerts.service';
import { HybridRoutingService } from '../../../../hybrid-routing.service';
import { MachinesService } from '../../../../@entities/machines/services/machines.service';
import { AskThreatExpertService } from '../../../../feedback/services/ask-threat-expert.service';
import { ServiceSourceType } from '@wcd/domain';

export const THREAT_EXPERT_DETECTION_SOURCE_ID = 2048;

SevilleModule.controller('seville.threatintel.alert', alertController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		$stateProvider
			.state('alert', {
				url: '/alert/:id/:flightingOverride',
				params: {
					flightingOverride: {
						value: '',
						squash: true,
					},
				},
				title: 'Alert',
				data: { entity: null },
				featureFlagToggleFeatureId: Feature.UpgradeAlertPage,
				showToggleFeatureId: Feature.ShowUpgradeAlertPageToggle,
				showBreadcrumbs: true,
				resolve: {
					IsUserExposedToAlert: isUserExposedToAlert,
				},
				views: {
					main: {
						template: ALERT_TEMPLATE,
						controller: 'seville.threatintel.alert',
						controllerAs: 'alert',
					},
				},
			})
			.state('aggregativealert', {
				url: '/aggregativealert/:id/:flightingOverride',
				params: {
					flightingOverride: {
						value: '',
						squash: true,
					},
				},
				title: 'Aggregative alert',
				data: { entity: null },
				views: {
					main: {
						template: ALERT_TEMPLATE,
						controller: 'seville.threatintel.alert',
						controllerAs: 'alert',
					},
				},
			});

		/**
		 * This method acts as a route guard - each time this route is trying to be accessed, this function will run,
		 * contacting the api to check if user is authorized to view the machine
		 * if the route is written manually - once the modal closes the user will be redirected into the dashboard
		 **/
		isUserExposedToAlert.$inject = [
			'$q',
			'$http',
			'$stateParams',
			'urlMapping',
			'featuresService',
			'appInsights',
			'rbacMachineGroupService',
			'authenticationService'
		];
		function isUserExposedToAlert(
			$q,
			$http,
			$stateParams,
			urlMapping,
			featuresService,
			appInsights: AppInsightsService,
			rbacMachineGroupService,
			authenticationService
		) {
			const dfd = $q.defer();
			// A simple hack to make sure that 'authenticationService' is initiated before alert controller.
			const apcHeader = authenticationService.getApcHeader();

			if (featuresService.isEnabled(Feature.RbacMachineGroups)) {
				//Flighting feature validation
				rbacMachineGroupService.isUserExposedToAlert($stateParams.id).then(
					response => {
						if (response.status === 200 && response.data === true) {
							dfd.resolve(true);
						} else {
							dfd.reject(false);
							appInsights.trackException(
								new Error(
									'Alert page: isUserAllowed: User is not authorized to view an alert, status code: ' +
										response.status +
										' alertID: ' +
										$stateParams.id
								)
							);
						}
					},
					function(e) {
						appInsights.trackException(
							new Error(
								'Something went wrong, Cant check if user is authorized to view alert: ' + e
							)
						);
					}
				);
			} else {
				dfd.resolve(true);
			}
			return dfd.promise;
		}
	},
]);

alertController.$inject = [
	'$log',
	'$filter',
	'$http',
	'$state',
	'$stateParams',
	'$scope',
	'urlMapping',
	'investigationService',
	'featuresService',
	'alertManagementService',
	'ng2router',
	'appConfig',
	'alertsService',
	'machinesService',
	'askTheExpertService',
	'appContextService',
	'appInsights',
];

function alertController(
	$log,
	$filter,
	$http,
	$state,
	$stateParams,
	$scope,
	urlMapping,
	investigation,
	featuresService: FeaturesService,
	alertManagementService,
	ng2router: HybridRoutingService,
	appConfig: AppConfigService,
	alertsService: AlertsService,
	machinesService: MachinesService,
	askTheExpertService: AskThreatExpertService,
	appContextService: AppContextService,
	appInsights: AppInsightsService
) {
	var vm = this;
	let missingAlertOsPlatformTraced = false;
	vm.events = [];
	vm.investigationStatesMap = cloneDeep(urlMapping.investigationsStates);
	vm.type = 'AlertTimeline';
	vm.stateName = $state.current.name;
	vm.isAggregativeAlert = vm.stateName === 'aggregativealert';
	vm.AlertId = $stateParams.id;
	vm.flightingOverride = $stateParams.flightingOverride;
	vm.loading = 1;
	vm.isPrintEnabled = featuresService.isEnabled('PrintAlert');
	vm.isAutoIRFeatureEnabled = appConfig.isAutomatedIrEnabled;
	vm.isIncidentEnabled = featuresService.isEnabled(Feature.Incidents);
	vm.huntingEnabled =
		featuresService.isEnabled('Hunting') && appConfig.isExposedToAllMachineGroups && false; // disabled for now;
	vm.showConsultThreatExpertMenuItem = askTheExpertService.shouldShowThreatExpertPanel();

	vm.showProductSource = function() {
		return Boolean(appContextService.isMtp && vm.main.Product);
	};

	vm.showDetectionSource = function() {
		return !vm.showProductSource() || vm.main.Product === ServiceSourceType.Wdatp;
	};

	if (!$stateParams.id) {
		ng2router.navigate(['/errors']);
		return;
	}

	goToNewAlertPage();
	return;

	vm.featuresChangeSubscription = featuresService.featureChanged$.subscribe(
		({ featureId, value: isEnabled }) => {
			if (featureId === Feature.UpgradeAlertPage && isEnabled) {
				goToNewAlertPage();
			}
		}
	);

	function goToNewAlertPage() {
		ng2router.navigate(['/alerts', $stateParams.id], { replaceUrl: true });
	}

	loadEntity();

	vm.showExpertPanel = function() {
		askTheExpertService.showResearcherAssistanceDialog();
	};

	if (vm.isAggregativeAlert) {
		$scope.$on('alertsMgmt:alertsUpdated', function(event, payload) {
			if (!payload.alerts) {
				return;
			}
			reloadHeader();
		});
	}

	function reloadHeader() {
		var url = urlMapping.getThreatIntelUrl() + '/alerts';
		if (vm.isAggregativeAlert) {
			url = urlMapping.getThreatIntelUrl() + '/aggregativealert';
		}

		$http
			.get(url, {
				params: {
					id: $stateParams.id,
				},
			})
			.then(
				function(response) {
					$log.debug('alert reloaded successfully');
					var data = response.data;
					vm.main = data;
				},
				function(response) {
					// keep alert header as is - failed to reload. Do not redirect to error page.
					$log.error('failed to reload alert header, response status code:' + response.status);
				}
			);
	}

	function loadEntity() {
		if ($state.current.data && $state.current.data.entity) {
			$log.debug('Alert is already loaded in memory');
			var data = $state.current.data.entity;
			updateInvestigationFlow(data);
			vm.main = cloneDeep(data);

			loadAlertTimelineEvents(data);
			loadAssociated();
			loadHistory();
			delete $state.current.data.entity;
		}

		if (
			!vm.main ||
			!vm.main.Id ||
			!vm.main.MonitorContextList ||
			vm.main.MonitorContextList.length === 0
		) {
			$log.debug('loading alert with id: ' + $stateParams.id);
			var url = urlMapping.getThreatIntelUrl() + '/alerts';
			if (vm.isAggregativeAlert) {
				url = urlMapping.getThreatIntelUrl() + '/aggregativealert';
			}

			$http
				.get(url, {
					params: {
						id: $stateParams.id,
					},
				})
				.then(
					function(response) {
						if (response.status == 200) {
							$log.debug('alert loaded successfully');
							var data = response.data;
							vm.main = cloneDeep(data);

							// alerts from container should show the container id instead of the domain in the user details of the alert
							vm.main.DomainOrContainerId = vm.main.ContainerId
								? vm.main.ContainerId
								: vm.main.DomainName;

							vm.main.isThreatExpertOriginated =
								vm.main.DetectionSource === THREAT_EXPERT_DETECTION_SOURCE_ID;
							loadAlertTimelineEvents(data);
							loadAssociated();
							updateInvestigationFlow(data);
							loadHistory();
							updateAlertCategoryLink(data);
						} else if (response.status != -1) {
							$log.debug('alert was not loaded, response status code:' + response.status);
							ng2router.navigate(['/errors']);
						}
					},
					function(response) {
						delete vm.main;
						$log.debug('alert loading failed');
					}
				);
		}
	}

	function loadAssociated() {
		if (!vm.main) {
			return;
		}

		if (vm.main.ActorName) {
			$http
				.get(urlMapping.getThreatIntelUrl() + '/ThreatIntel', {
					method: 'GET',
					params: {
						provider: vm.main.ThreatIntelProvider,
						actor: vm.main.ActorName ? vm.main.ActorName.trim() : null,
						additionalInfo: vm.main.ThreatIntelAdditionalInformation
							? vm.main.ThreatIntelAdditionalInformation.trim()
							: null,
					},
				})
				.then(function(response) {
					if (response.status == 200) {
						vm.main.threatIntel = response.data;
					}
				});
		}

		vm.machineesentity = [
			{
				Id: vm.main.MachineId,
				WcdMachineId: vm.main.WcdMachineId,
				ComputerDnsName: vm.main.ComputerDnsName,
				SenseMachineId: vm.main.SenseMachindId,
			},
		];

		if (vm.main.MonitorContextList && vm.main.MonitorContextList.length > 0) {
			for (var i = 0; i < vm.main.MonitorContextList.length; i++) {
				var mc = vm.main.MonitorContextList[i];
				if (mc.Sha1) {
					if (!vm.fileesentity) {
						vm.fileesentity = [];
					}

					// file
					vm.fileesentity.push({
						Sha1: mc.Sha1,
						Path: mc.Path || mc.FilePath,
						Name: mc.FileName,
					});
				}

				if (mc.Url) {
					// urls
					if (!vm.urls) {
						vm.urls = [];
					}

					vm.urls.push({
						Id: mc.Url,
					});
				}

				if (mc.IpAddress) {
					if (!vm.ips) {
						vm.ips = [];
					}

					// ip address
					vm.ips.push({
						Id: mc.IpAddress,
					});
				}
			}
		}
	}

	function updateInvestigationFlow(data) {
		if (!data || data.length <= 1) {
			data = {};
		}

		// TODO icon for aggregative alert
		data.icon = 'icon-LightningBolt';
		data.state = vm.stateName;
		data.displaystate = data.Title || 'Alert';
		if (!data.Id) {
			data.Id = vm.AlertId;
		}

		investigation.setActive(data);

		if (!breadcrumbsStateService.breadcrumbs$.value.length) {
			breadcrumbsStateService.add({
				id: `alertsQueue`,
				label: 'Alerts',
				url: '/alertsQueue',
				queryParams: {},
			});
		}
		breadcrumbsStateService.add({
			id: `alert_${vm.AlertId}`,
			label: data.displaystate,
			url: $state.href($state.current),
			queryParams: $state.params,
		});
	}

	function updateAlertCategoryLink(data) {
		if (!data || data.length <= 1 || data.Categories.length !== 1) {
			return;
		}

		const category = data.Categories[0];
		switch (category) {
			case 'InitialAccess':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0001/';
				break;
			case 'Execution':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0002/';
				break;
			case 'Persistence':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0003/';
				break;
			case 'PrivilegeEscalation':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0004/';
				break;
			case 'DefenseEvasion':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0005/';
				break;
			case 'CredentialAccess':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0006/';
				break;
			case 'Discovery':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0007/';
				break;
			case 'LateralMovement':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0008/';
				break;
			case 'Collection':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0009/';
				break;
			case 'CommandAndControl':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0011/';
				break;
			case 'Exfiltration':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0010/';
				break;
			case 'Impact':
				vm.categoryLink = 'https://attack.mitre.org/tactics/TA0040/';
				break;
		}
	}

	// load all associated events of the alert
	function loadAlertTimelineEvents(data) {
		var record;
		var item;

		if (data.IPMonitorContextList) {
			for (var i = 0; i < data.IPMonitorContextList.length; i++) {
				record = data.IPMonitorContextList[i];

				// update first seen and last seen of url
				var ipFirstSeen = record.FirstSeen;
				var ipLastSeen = record.LastSeen;

				if (ipFirstSeen == null) {
					ipFirstSeen = data.FirstSeen;
				}

				if (ipLastSeen == null) {
					ipLastSeen = data.LastSeen;
				}

				item = {
					time: ipLastSeen,
					sort: $filter('date')(ipLastSeen, 'yyyy-MM-dd-HH-mm-sss', 'UTC'),
					sha1: record.Sha1,
					signer: record.Signer,
					ip: record.IpAddress,
					type: 'AlertIp',
					firstSeen: ipFirstSeen,
				};

				vm.events.push(item);
			}
		}

		if (data.URLMonitorContextList) {
			for (var i = 0; i < data.URLMonitorContextList.length; i++) {
				record = data.URLMonitorContextList[i];

				// update first seen and last seen of url
				var urlFirstSeen = record.FirstSeen;
				var urlLastSeen = record.LastSeen;

				if (urlFirstSeen == null) {
					urlFirstSeen = data.FirstSeen;
				}

				if (urlLastSeen == null) {
					urlLastSeen = data.LastSeen;
				}

				// retrieve url domain
				var urlDomain;
				var url = record.Url;
				if (url.indexOf('://') > -1) {
					urlDomain = url.split('/')[2];
				} else {
					urlDomain = url.split('/')[0];
				}

				urlDomain = urlDomain.split(':')[0];

				item = {
					time: urlLastSeen,
					sort: $filter('date')(urlLastSeen, 'yyyy-MM-dd-HH-mm-sss', 'UTC'),
					sha1: record.Sha1,
					signer: record.Signer,
					url: record.Url,
					domain: urlDomain,
					type: 'AlertUrl',
					firstSeen: urlFirstSeen,
					json: record,
				};

				vm.events.push(item);
			}
		}

		if (data.FileMonitorContextList) {
			var results = SevilleUtils.groupBy(data.FileMonitorContextList, function(item) {
				return [item.Sha1 || item.Sha256 || item.FileName];
			});

			var groupedFiles = groupAlertFiles(results);

			for (var i = 0; i < groupedFiles.length; i++) {
				record = groupedFiles[i];

				// update first seen and last seen of url
				let fileFirstSeen = record.FirstSeen;
				var fileLastSeen = record.LastSeen;

				if (fileFirstSeen == null) {
					fileFirstSeen = data.FirstSeen;
				}

				if (fileLastSeen == null) {
					fileLastSeen = data.LastSeen;
				}

				if (
					!record.Sha ||
					(!RegExpService.sha1.test(record.Sha) && !RegExpService.sha256.test(record.Sha))
				) {
					record.Sha1 = 'Hash could not be retrieved';
					record.invalidHash = true;
				}

				item = {
					time: fileLastSeen,
					sort: $filter('date')(fileLastSeen, 'yyyy-MM-dd-HH-mm-sss', 'UTC'),
					filename: record.FileName,
					sha: record.Sha,
					realPaths: record.FilePaths,
					signer: record.Signer,
					type: 'AlertFile',
					valid: !record.invalidHash,
					firstSeen: fileFirstSeen,
				};

				item.files = {
					TotalResults: 1,
					Items: [
						{
							Sha1: record.Sha1,
							Sha: record.Sha,
							Paths: record.FilePaths,
							Name: record.FileName,
							Valid: !record.invalidHash,
							Signer: record.Signer,
						},
					],
				};
				vm.events.push(item);
			}
		}

		vm.events = $filter('orderBy')(vm.events, 'sort', true);

		vm.loading = 0;

		$(document).ready(function() {
			(<any>$('.pop'))
				.popover({
					html: true,
					trigger: 'focus',
					container: $(this).attr('id'),
					placement: 'right',
					template:
						'<div class="popover threatintel-popover"><div class="popover-inner"><div class="popover-content"></div></div></div>',
				})
				.on('mouseenter', function() {
					var _this = this;
					(<any>$(_this)).popover('show');
					(<any>$(_this)).siblings('.popover').on('mouseleave', function() {
						(<any>$(_this)).popover('hide');
					});
				})
				.on('mouseleave', function() {
					var _this = this;
					setTimeout(function() {
						if (!$('.popover:hover').length) {
							(<any>$(_this)).popover('hide');
						}
					}, 100);
				});
		});
	}

	function groupAlertFiles(groupedFiles) {
		var alertFiles = [];

		for (var i = 0; i < groupedFiles.length; i++) {
			var item = {};
			var aggFilePaths = [];
			var groupedFile = groupedFiles[i];
			if (groupedFile.length > 1) {
				var maxLastSeen = null;
				var minFirstSeen = null;
				var signer = null;
				var sha = groupedFile[0].Sha1 || groupedFile[0].Sha256;
				var fileName = groupedFile[0].FileName;
				for (var j = 0; j < groupedFile.length; j++) {
					if (maxLastSeen == null || maxLastSeen < groupedFile[j].LastSeen) {
						maxLastSeen = groupedFile[j].LastSeen;
						signer = groupedFile[j].Signer;
					}

					if (minFirstSeen == null || minFirstSeen > groupedFile[j].FirstSeen) {
						minFirstSeen = groupedFile[j].FirstSeen;
					}

					if (!fileName) {
						fileName = groupedFile[j].FileName;
					}

					if (!(aggFilePaths.indexOf(groupedFile[j].FilePath) > -1)) {
						if (groupedFile[j].FilePath != undefined && groupedFile[j].FilePath != '') {
							aggFilePaths.push(groupedFile[j].FilePath);
						}
					}
				}

				// if we removed all the empty paths and no paths remained, insert the following path
				if (aggFilePaths.length == 0) {
					aggFilePaths.push('File path could not be retrieved');
				}

				item = {
					FirstSeen: minFirstSeen,
					LastSeen: maxLastSeen,
					FileName: fileName,
					Sha: sha,
					FilePaths: aggFilePaths,
					Signer: signer,
				};

				alertFiles.push(item);
			} else {
				aggFilePaths.push(groupedFile[0].FilePath);
				item = {
					FirstSeen: groupedFile[0].FirstSeen,
					LastSeen: groupedFile[0].LastSeen,
					FileName: groupedFile[0].FileName,
					Sha: groupedFile[0].Sha1 || groupedFile[0].Sha256,
					FilePaths: aggFilePaths,
					Signer: groupedFile[0].Signer,
				};

				alertFiles.push(item);
			}
		}

		return alertFiles;
	}

	function loadHistory() {
		$scope.$on('alertsMgmt:alertsUpdated', function(e, args) {
			if (args.alerts && args.alerts.length == 1 && args.alerts[0].AlertId == vm.main.AlertId) {
				getAlertHistory(); // Reload history on alert update
			}
		});

		getAlertHistory();
	}

	function getAlertHistory() {
		alertManagementService.getAlertHistory(
			1,
			100,
			function(history) {
				if (history) {
					vm.history = history;
				} else {
					delete vm.history;
				}
			},
			vm.main.AlertId
		);
	}

	vm.getMachineLink = main => {
		if (featuresService.isEnabled(Feature.UpgradeMachinePage)) {
			const alertTime = new Date(vm.main.LastEventTime || vm.main.LastSeen),
				alertId = vm.main.AlertId;
			return machinesService.getMachineLink(main.MachineId, true, alertTime, alertId);
		} else {
			return $state.href('machine', {
				id: main.MachineId,
				time: main.LastEventTime || main.LastSeen,
			});
		}
	};

	vm.selectMachine = function(machine, $event) {
		$event.preventDefault();
		$event.stopPropagation();

		if ($event.ctrlKey) {
			window.open(vm.getMachineLink(vm.main));
			return;
		}

		if (
			!featuresService.isEnabled(Feature.UpgradeMachinePage) &&
			machine.MachineId &&
			machine.MachineId.length > 0
		) {
			var state = $state.get('machine');
			if (!state.data) {
				state.data = {};
			}

			// TODO AggregativeAlert fix alert highlighting when moving to machine page
			state.data.activeAlertId = machine.AlertId;
			state.data.activeAlertSeverity = machine.Severity;
			state.data.activeAlertTime = machine.LastEventTime || machine.LastSeen;
			state.data.slider = new Date(state.data.activeAlertTime);
		}

		goToMachine();
	};

	function goToMachine() {
		if (featuresService.isEnabled(Feature.UpgradeMachinePage)) {
			ng2router.navigateByUrl(vm.getMachineLink(vm.main));
		} else {
			$state.go('machine', {
				id: vm.main.MachineId,
				time: vm.main.LastEventTime || vm.main.LastSeen,
			});
		}
	}

	vm.manageAlert = function(alert) {
		alertsService.showAlertsSidePanelByRawData([alert], { hideOpenLink: true });
	};

	vm.isAllowedAccount = function(accountName, accountDomainName, containerId) {
		let isUserLinkAllowedForMachineOs = true;
		if (vm.main.OsPlatform) {
			isUserLinkAllowedForMachineOs =
				vm.main.OsPlatform.toLowerCase() !== 'windows7' &&
				vm.main.OsPlatform.toLowerCase() !== 'linux' &&
				vm.main.OsPlatform.toLowerCase() !== 'macos';
		} else {
			if (!missingAlertOsPlatformTraced) {
				appInsights.trackException(
					new Error(
						`Alert page: missing os platform in alert response. Alert id :${vm.main.AlertId}`
					)
				);
				missingAlertOsPlatformTraced = true; // avoid multiple traces over the same alert at the same page view
			}
		}

		return userAccountService.isAllowedAccount(
			accountName,
			accountDomainName,
			!containerId && isUserLinkAllowedForMachineOs
		);
	};

	vm.formatMultiLineText = function(text) {
		// Break multi line text with <br/> tags.
		// We can do it using css property white-space : pre-line, but it is not supported by Edge in print.
		return text ? text.replace(/\n/g, '<br/>') : '';
	};

	vm.openIncidentPage = (incidentId: number, $event) => {
		$event.preventDefault();
		$event.stopPropagation();

		const url = `/incidents/${incidentId}`;
		if ($event.ctrlKey) {
			window.open(url);
			return;
		}

		ng2router.navigate([url]);
	};

	vm.pageIsLoaded = function() {
		return vm.processTreeLoaded && (vm.scopeOfBreachLoaded || vm.isAggregativeAlert);
	};

	vm.printAlert = function() {
		if (vm.pageIsLoaded()) {
			window.print();
		}
	};

	// gets a link to private blob containing actor report.
	// address server to get link with access to the private blob and start download
	vm.downloadActorReport = function(linkToReport) {
		alertManagementService.downlodReportUsingSasToken(linkToReport);
	};

	// returns the class name according to the severity of the alert.
	// should NOT be done inside ng-class statement (interpolations problem in ng class is a known issue)
	vm.getAlertSeverityColorClassName = function() {
		return ('sev-' + vm.main.Severity).toLowerCase();
	};
}

const ALERT_TEMPLATE: string = `
<!-- Note: this page has a printable version. When making changes to this page, please make sure the printable version isn't broken.
It is important to test on all 3 browsers (Chrome, IE and Edge) as they differ significantly regarding to print. -->

<div ng-if="alert.main" class="print-hide-all-link-urls" data-track-component="SevilleAlertPage">
	<div class="row visible-print alert-header-title-container">
		<div class="col-xs-1">
			<i class="icon icon-LightningBolt alert-header-main-title-icon"></i>
		</div>
		<div class="col-xs-11 overflow overflow-all alert-header-title-print">
			<span ng-if="::alert.main.isThreatExpertOriginated" class="tag wcd-margin-small-right tag-color-box-threatExpert">
				{{::alert.main.DetectionSource | alertDetectionSource }}
			</span>
			<span ng-if="::alert.main.ActorName">
				<i class="icon icon-ReadingMode"></i>
				{{:: alert.main.ActorName }}
			</span>
			<span>{{:: alert.main.Title}}</span>
		</div>
	</div>

	<div class="entity clearfix" ng-if="alert.main">
		<div class="row alert-header-print-col">

		<div class="container" style="background-color: #e6e6e6; margin-bottom: 10px; padding: 5px">
			<p>We will be deprecating the legacy alert page on <b>April 2, 2021</b>. Once this happens you will only see the new alert page in both the Microsoft Defender Security Center and the Microsoft 365 security center. There will no longer be a toggle to switch between the old and new version of the page.
				<a target="_blank" href="https://techcommunity.microsoft.com/t5/microsoft-defender-for-endpoint/delivering-world-class-secops-experiences/ba-p/2170092">Read more</a>
			</p>
		</div>

		<!--Alert main info -->
		<div class="col-sm-12 col-md-12 col-lg-6 entity-cube alert-header-first-box alert-header-row">
			<investigation-status ng-if="alert.main.AlertInvestigation || alert.investigationStatesMap[alert.main.InvestigationState] != undefined"
								  flighting-feature-enabled="alert.isAutoIRFeatureEnabled "
								  data="{InvestigationId: alert.main.InvestigationId, State: alert.main.InvestigationState}"
								  investigation-id="alert.main.InvestigationId"
								  alert-id="alert.main.AlertId"></investigation-status>
				<div class="attack-sev" ng-class="alert.getAlertSeverityColorClassName()">
					<div class="border-default alert-header-inner-box">
						<div class="hidden-print alert-header-title-container">
							<table class="alert-header-table">
								<tr>
									<td rowspan="2">
										<i class="icon icon-LightningBolt alert-header-main-title-icon"></i>
									</td>
									<td>
										<div class="overflow overflow-all alert-header-title">
											<span ng-if="::alert.main.isThreatExpertOriginated" class="tag wcd-margin-small-right tag-color-box-threatExpert">
												{{::alert.main.DetectionSource | alertDetectionSource }}
											</span>
											<span ng-if="alert.main.ActorName">
												<button ng-click="alert.main.threatIntelExtended=!alert.main.threatIntelExtended"
													class="alert-header-actor-name">
													<i class="icon icon-ReadingMode"></i>
													{{:: alert.main.ActorName }}
												</button>
											</span>
										<!-- TODO AggregativeAlert add elipsis to the title -->
										<span>{{:: alert.main.Title}}</span>
										<label class="beta-label pop" ng-if="alert.isAggregativeAlert"
											   data-toggle="popover"
											   data-content="Aggregative alerts are in BETA mode. Your feedback is welcome.">
											BETA
										</label>
									</div>
								</td>
							</tr>
							<tr ng-if="::!alert.isIncidentEnabled && alert.main.ParentAlertId">
								<td>
									<b>
										Open <a
											data-track-id="GoToAggregativeAlert"
	   										data-track-type="Navigation"
	   										ui-sref="aggregativealert({id:alert.main.ParentAlertId})">aggregative view</a>
										with related alerts.
										<label class="beta-label pop"
											    class="pop"
												data-toggle="popover"
												data-content="Aggregative alerts are in BETA mode. Your feedback is welcome."
											   	popover-placement="bottom">
											BETA
										</label>
									</b>
									</td>
								</tr>
							<tr ng-if="::alert.main.IncidentId">
								<td>
									<b>
										This alert is part of incident <a
										data-track-id="GoToIncident"
	   									data-track-type="Navigation"
	   									href="{{'/incidents/' + alert.main.IncidentId}}"
	   									ng-click="alert.openIncidentPage(alert.main.IncidentId, $event)">({{alert.main.IncidentTitle}})</a>
									</b>
								</td>
							</tr>
							</table>
						</div>
						<div>
							<div ng-if="::!alert.isPrintEnabled">
								<div ng-if="::!alert.isAggregativeAlert">
									<button id="single-button" type="button" class="btn primary-button alert-header-button pop"
											ng-click="alert.manageAlert(alert.main)"
											data-toggle="popover"
											data-content="Set alert status and classification, assign alert, suppress alert, or add comments" href="#">
										Manage
									</button>
								</div>
								<div ng-if="::alert.isAggregativeAlert">
									<span class="tool-tip-disabled-button pop"
										    data-toggle="popover"
											data-content="Aggregative alerts are in BETA mode: managing them is not yet supported.">
										<button type="button" class="btn.disabled secondary-button-disabled" ng-disabled="true">
											Manage
										</button>
									</span>
								</div>
							</div>
							<div ng-if="::alert.isPrintEnabled" class="btn-group" uib-dropdown>
								<button type="button" id="actions-button" class="btn btn-primary dropdown-toggle secondary-button" uib-dropdown-toggle>
									Actions <span class="icon icon-ChevronDown machine-actions-icon"></span>
								</button>
								<ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="actions-button">
									<li>
										<a ng-if="::!alert.isAggregativeAlert"
											tabindex="0"
											href="#"
											data-track-id="ManageAlert"
											data-track-type="Navigation"
											class="pop"
											data-toggle="popover"
											data-content="Set alert status and classification, assign alert, suppress alert, or add comments"
											ng-click="alert.manageAlert(alert.main)"
											role="menuitem">
											Manage alert
										</a>
										<a ng-if="::alert.isAggregativeAlert"
											tabindex="0"
											data-track-id="ManageAlert"
	   										data-track-type="Navigation"
											class="disabled pop"
											href="#"
											data-toggle="popover"
											data-content="Aggregative alerts are in BETA mode: managing them is not yet supported."
											role="menuitem">
											Manage alert
										</a>
									</li>
									<li>
										<a ng-click="alert.selectMachine(alert.main, $event)"
												tabindex="0"
												href="#"
												data-track-id="GoToMachineTimeline"
											    data-track-type="Navigation"
											    class="pop"
											    data-toggle="popover"
												data-content="Check machine details and event timeline"
												role="menuitem">
										    View device timeline
										</a>
									</li>
									<li ng-if="::alert.isIncidentEnabled && alert.main.IncidentId">
										<a data-track-id="GoToIncidentFromMenu"
											tabindex="0"
											data-track-type="Navigation"
											href="{{'/incidents/' + alert.main.IncidentId}}"
											ng-click="alert.openIncidentPage(alert.main.IncidentId, $event)"
											role="menuitem">
											Open incident page
										</a>
									</li>
									<li>
										<a ng-class="{'disabled': !alert.pageIsLoaded()}" ng-click="alert.printAlert()"
											tabindex="0"
											href="#"
											data-track-id="PrintAlert"
										    data-track-type="Action"
										    class="pop"
										    data-toggle="popover"
											data-content="{{alert.pageIsLoaded()? 'Open print dialog' : 'The page is still loading'}}"
											role="menuitem">
											Print alert
										</a>
									</li>
									<li ng-if="alert.huntingEnabled">
										<a router-link="['/hunting']"
											tabindex="0"
											href="#"
											data-track-id="GoToHunting"
											data-track-type="Navigation"
											class="pop"
											data-toggle="popover"
											data-content="Query raw event data using Advanced hunting"
											role="menuitem">
											<span>Go to Advanced hunting</span>
										</a>
									</li>
									<!-- ./Hunting -->
									<li ng-if="alert.showConsultThreatExpertMenuItem">
										<a
											tabindex="0"
											data-track-id="consultThreatExpertMenuItem"
											data-track-type="Action"
											ng-click="alert.showExpertPanel()"
											role="menuitem">
											<span>Consult a threat expert</span>
										</a>
								  	</li>
								</ul>
							</div>

						<table class="alert-header-table alert-header-first-box-fields-table">
							<tr>
								<td>
									Severity:
								</td>
								<td>
									{{::alert.main.Severity | severity }}
								</td>
							</tr>
							<tr>
								<td>
									Category:
								</td>
								<td ng-if="!alert.categoryLink">
									{{::alert.main.Categories | arrayToText | splitCamelCase }}
								</td>
								<td ng-if="alert.main.Categories.length == 1 && alert.categoryLink">
									<a href="{{::alert.categoryLink}}"
										target="_blank">
										{{::alert.main.Categories[0] | splitCamelCase}}
									</a>
								</td>
							</tr>
							<tr ng-if="(alert.main.MitreTechniques | alertMitreTechniqueGetValid).length">
								<td>
									Technique:
								</td>
								<td>
									<span ng-repeat ="mt in (alert.main.MitreTechniques | alertMitreTechniqueGetValid)">
										<a href="https://attack.mitre.org/techniques/{{mt}}/" target="_blank">{{mt | alertMitreTechniqueGetName}}</a><span ng-if="!$last">, </span>
									</span>
								</td>
							</tr>
							<tr ng-if="alert.showProductSource()">
								<td>
									Service source:
								</td>
								<td>
									{{::alert.main.Product | alertProductSource }}
								</td>
							</tr>
							<tr ng-if="alert.showDetectionSource()">
								<td>
									Detection source:
								</td>
								<td>
									{{::alert.main.DetectionSource | alertDetectionSource }}
								</td>
							</tr>
							<tr ng-if="alert.main.DetectionTech != undefined && alert.main.DetectionTech != '' && (alert.main.DetectionTech | alertDetectionTech) != null">
								<td>
									Detection technology:
								</td>
								<td>
									{{::alert.main.DetectionTech | alertDetectionTech }}
								</td>
							</tr>
							<tr ng-if="alert.main.BlockingStatus != null && ((alert.main.BlockingStatus | alertBlockingStatus) != null)">
								<td>
									Detection status:
								</td>
								<td tooltips tooltip-title="{{::alert.main.BlockingStatus | alertBlockingStatusTooltip}}">
									{{::alert.main.BlockingStatus | alertBlockingStatus }}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>

			<!-- Alert context -->
			<div class="col-sm-6 col-md-6 col-lg-3 entity-cube alert-header-row">
				<div class="border-default alert-header-inner-box">
					<div class="entity-meta-main-title alert-header-box-title">
						<span>Alert context</span>
					</div>
					<div class="alert-header-box-parent">
						<table class="alert-header-table alert-header-table-single-field">
							<tr>
								<td>
									<div class="overflow-all ellipsis hover-no-background">
										<i class="icon icon-DeviceLaptopNoPic"></i>
										<side-pane-machine machineid="alert.main.MachineId" computerdnsname="alert.main.ComputerDnsName" hide-indicator="::!(alert.main.ComputerDnsName && alert.main.MachineId)" />
										<a ng-if="::alert.main.ComputerDnsName"
											data-track-id="GoToMachineFromAlertRow"
											data-track-type="Navigation"
											class="ellipsis attack-machine-name attack-machine-name-clickable"
											href="{{::alert.getMachineLink(alert.main)}}"
											ng-click="alert.selectMachine(alert.main, $event)" stop-propagation>
											<span ng-if="(alert.main.ComputerDnsName | parts:1 ).length < 30">{{ alert.main.ComputerDnsName | parts:1 }}</span>
											<span ng-if="( alert.main.ComputerDnsName | parts:1 ).length >= 30" tooltips tooltip-title="{{ alert.main.ComputerDnsName | parts:1 }}">{{ alert.main.ComputerDnsName | parts:1 }}</span>
										</a>
										<a ng-if="::!alert.main.ComputerDnsName"
											data-track-id="GoToMachineFromAlertHeader"
	   										data-track-type="Navigation"
											class="ellipsis attack-machine-name attack-machine-name-clickable"
											href="{{::alert.getMachineLink(alert.main)}}"
											ng-click="alert.selectMachine(alert.main, $event)" stop-propagation>1 device</a>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div ng-if="alert.main.UserName" class="overflow-all ellipsis hover-no-background">
										<i class="icon icon-Contact"></i>
										<side-pane-user accountname="::alert.main.UserName" accountdomainname="alert.main.DomainName" sid="alert.main.UserSid" hide-indicator="::!alert.isAllowedAccount(alert.main.UserName, alert.main.DomainName)"/>
										<span ng-if="::alert.isAllowedAccount(alert.main.UserName, alert.main.DomainName, alert.main.ContainerId)" class="overflow-all ellipsis hover-no-background">
											<a class="ellipsis attack-machine-name"
												data-track-id="GoToUser"
												data-track-type="Navigation"
												ui-sref="user(::{ userdomain: alert.main.DomainName, id: alert.main.UserName, sid: alert.main.UserSid })">
												<span ng-if="::((alert.main.DomainName + alert.main.UserName).length < 30)">{{::alert.main.DomainName | lowercase}}\\{{::alert.main.UserName | lowercase}}</span>
												<span ng-if="::((alert.main.DomainName + alert.main.UserName).length >= 30)" tooltips tooltip-title="{{::alert.main.DomainName | lowercase}}\\{{::alert.main.UserName | lowercase}}">{{::alert.main.DomainName | lowercase}}\\{{::alert.main.UserName | lowercase}}</span>
											</a>
										</span>
										<span ng-if="::!alert.isAllowedAccount(alert.main.UserName, alert.main.DomainName, alert.main.ContainerId)" class="overflow-all ellipsis alert-queue-user-context-disabled">
											<span ng-if="::((alert.main.DomainOrContainerId + alert.main.UserName).length < 30)">{{::alert.main.DomainOrContainerId | lowercase}}\\{{::alert.main.UserName | lowercase}}</span>
											<span ng-if="::((alert.main.DomainOrContainerId + alert.main.UserName).length >= 30)" tooltips tooltip-side="left" tooltip-title="{{::alert.main.DomainOrContainerId | lowercase}}\\{{::alert.main.UserName | lowercase}}">{{::alert.main.DomainOrContainerId | lowercase}}\\{{::alert.main.UserName | lowercase}}</span>
										</span>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div>
						<table class="alert-header-table">
							<tr>
								<td>
									First activity:
								</td>
								<td>
									<date src="alert.main.FirstEventTime"></date>
								</td>
							</tr>
							<tr>
								<td>
									Last activity:
								</td>
								<td>
									<date src="alert.main.LastEventTime"></date>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>

			<!-- Alert status -->
			<div class="col-sm-6 col-md-6 col-lg-3 entity-cube alert-header-row">
				<div class="border-default alert-header-inner-box">
					<div class="entity-meta-main-title alert-header-box-title">
						<span>Status</span>
					</div>
					<div class="alert-header-box-parent">
						<table class="alert-header-table alert-header-status-table">
							<tr>
								<td>
									State:
								</td>
								<td>
									{{alert.main.Status | status}}
								</td>
							</tr>
							<tr>
								<td>
									Classification:
								</td>
								<td>
									<span ng-if="alert.main.Classification">
										{{ alert.main.Classification | alertClassification }}
										<span ng-if="alert.main.Determination">- {{ alert.main.Determination | alertDetermination }}</span>
									</span>
									<span ng-if="!alert.main.Classification">
										Not set
									</span>
								</td>
							</tr>
						</table>
					</div>
					<div>
						<table class="alert-header-table alert-header-status-table">
							<tr>
								<td>
									Assigned to:
								</td>
								<td>
									{{ alert.main.AssignedTo || 'Not assigned'}}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>

		<!-- Comments & history - visible on print only -->
		<div class="col-xs-6 visible-print alert-header-print-col">
			<div class="entity-meta-main-title alert-header-box-title">
				<span>Comments and history</span>
			</div>
			<alert-history history="alert.history" first-seen="alert.main.FirstSeen"/>
		</div>
	</div>

	<!-- Actor report section -->
	<div class="row attack-threatIntel-section entity-meta-main border-default" ng-if="alert.main.ActorName" ng-show="alert.main.threatIntelExtended">
		<div class="col-sm-12">
			<div class="attack-threatIntel-title">
				<div class="row">
					<div class="col-md-6">{{:: alert.main.threatIntel.Name | uppercase }}</div>
					<div class="col-md-6 attack-threatIntel-credit" ng-if="alert.main.ThreatIntelProvider == 'iSight'">
						<a data-track-id="GoToThirdPartyProviderLinkOrDocs"
	   						data-track-type="ExternalLink"
	   						href="{{:: alert.main.threatIntel.ThirdPartyProviderLink || 'http://go.microsoft.com/fwlink/?LinkID=823681&clcid=0x409'}}" target="_blank">
							<img src="/assets/images/detection_systems/powered_by_fireeye.png" />
						</a>
					</div>
				</div>
			</div>
			<div ng-repeat="section in alert.main.threatIntel.Sections | orderBy:'Order'">
				<div class="attack-threatIntel-content-section-title">
					{{::section.Title}}
				</div>
				<div class="attack-threatIntel-content" ng-bind-html="section.Content">
				</div>
			</div>
			<div class="attack-threatIntel-actions hidden-print">
				<a href="javascript:void(0)"
					data-track-id="DownloadActorReport"
	   				data-track-type="Action"
					ng-click="alert.downloadActorReport(alert.main.threatIntel.LinkToReport)"
					ng-if="alert.main.threatIntel.LinkToReport">Download report</a>
				<a class="pull-right" href="javascript:void(0)"
					data-track-id="HideActivityGroupProfile"
	   				data-track-type="Toggle"
					ng-click="alert.main.threatIntelExtended=false">Hide activity group profile</a>
			</div>
		</div>
	</div>

		<!-- Alert info section -->
	<div class="row entity-meta-main border-default" ng-if="::!alert.isAggregativeAlert" >
		<dynamic-expandable-div height-limit-class-name="alert-info-section-collapsed" collapse-expand-div-class-name="alert-info-section-show-more col-sm-12">
			<div ng-class="::{'col-sm-6':alert.main.RecommendedAction, 'col-sm-12' : !alert.main.RecommendedAction}" class="break-words">
				<div class="entity-meta-title">
					<span>Description</span>
					<span class="alert-info-section-ti-banner" ng-if="alert.main.ThreatIntelProvider">
						<a href="{{::alert.threatIntel.ThirdPartyProviderLink || 'http://go.microsoft.com/fwlink/?LinkID=823681&clcid=0x409'}}" target="_blank"
						   data-track-id="ThirdPartyProviderLinkOrDocs"
	   						data-track-type="ExternalLink"
						   ng-if="::alert.main.ThreatIntelProvider == 'iSight'">
							<img src="/assets/images/detection_systems/powered_by_fireeye.png" />
						</a>
						<span class="alert-queue-external-ti alert-page-external-ti text-nowrap"
							  ng-if="::alert.main.ThreatIntelProvider.toLowerCase() == 'customer'">
							Custom TI
						</span>
					</span>
				</div>
				<div ng-bind-html=":: alert.formatMultiLineText(alert.main.PresentableUxDescription)"></div>
				<div class="white-space alert-ext-info" ng-if="::alert.main.AdditionalUxDescription">
					<div ng-repeat="line in (alert.main.AdditionalUxDescription).split('\n')" ng-bind=":: line"></div>
				</div>
				<div class="alert-ext-info hidden-print" ng-if="::alert.main.LinkToEncyclopedia">
					<br />
					<i class="icon machine-timeline-icon icon-ReadingMode"></i>
					<a ng-href="{{::alert.main.LinkToEncyclopedia | decode}}"
						data-track-id="MicrosoftEncyclopediaLink"
	   					data-track-type="ExternalLink"
						target="_blank">Read more on Microsoft Encyclopedia</a>
				</div>
			</div>
			<div class="col-sm-6 break-words" ng-if="::alert.main.RecommendedAction">
				<div class="entity-meta-title">
					<span>Recommended actions</span>
				</div>
				<div class="white-space-word alert-ext-info" ng-bind-html="::alert.formatMultiLineText(alert.main.RecommendedAction)"></div>
			</div>
		</dynamic-expandable-div>
	</div>

	<div class="row">
			<div class="col-sm-12">
				<!-- Associated Alerts -->
				<div class="avoid-page-breaks-inside">
					<associated-ioas
						aggregative-alert-id="alert.main.AlertId"
						hide-machine-names="true"
						entity="'aggregativealert'"
						refresh-investigation-states="true"
						ng-if="::alert.isAggregativeAlert" ></associated-ioas>
				</div>

				<div class="avoid-page-breaks-inside">
					<alert-process-tree alert="alert.main" is-aggregative-alert="alert.isAggregativeAlert" flighting-override="alert.flightingOverride" on-loaded="alert.processTreeLoaded = true"></alert-process-tree>
				</div>

				<div class="avoid-page-breaks-inside">
					<scope-of-breach alert="alert.main" flighting-override="alert.flightingOverride" on-loaded="alert.scopeOfBreachLoaded = true" ng-if="::!alert.isAggregativeAlert"></scope-of-breach>
				</div>

				<div class="avoid-page-breaks-inside">
					<events-timeline events="alert.events" loading="alert.loading" type="alert.type" ng-if="::!alert.isAggregativeAlert"></events-timeline>
				</div>

			</div>
		</div>
</div>
`;
