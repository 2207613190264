<!-- tslint:disable:template-click-events-have-key-events -->
<entity-panel-collapsible-section
	*ngIf="repository"
	[collapsed]="initiallyCollapsed"
	[loaded]="loaded"
	[loadingText]="recommendationContextExposedMachinesLoadingText"
	[title]="title"
	[count]="itemsCount"
	(export)="export.emit()"
	(onCollapseChange)="onCollapseChange($event)">
	<dataview
		[hideTable]="hideTable"
		[repository]="repository"
		[navigateOnChange]="false"
		[ignoreQueryParams]="true"
		[disableSelection]="true"
		[hideControls]="true"
		[allowFilters]="false"
		[fields]="fields"
		[padLeft]="false"
		[maxItems]="maxItems"
		[label]="title"
		[dataViewConfig]="dataViewConfig"
		(onData)="onData($event.data)"
		(onItemClick)="openRecommendationContextItemPanel($event.item)">
	</dataview>
	<a *ngIf="itemsCount >= maxItems" class="wcd-padding-top"
		tabindex="0"
		role="button"
		data-track-id="openAllRecommendationContextItems"
		data-track-type="Navigation"
		(click)="this.showMore.emit()">{{'tvm.common.showMore' | i18n}}</a>
</entity-panel-collapsible-section>
