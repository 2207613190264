import { Injectable, NgZone } from '@angular/core';
import { AirsNotificationRuleModel } from '../models/airs-notification-rule.model';
import { AirsNotificationRuleConditionGroupsStore } from './airs-notification-rule-condition-groups.store';
import { AirsNotificationRulesReportsBackendService } from './airs-notification-rules-reports.backend.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { Store } from '../../../data/models/store.base';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class AirsNotificationRulesReportsStore extends Store<AirsNotificationRuleModel, number> {
	constructor(
		dialogsService: DialogsService,
		notificationRuleConditionGroupsStore: AirsNotificationRuleConditionGroupsStore,
		backendService: AirsNotificationRulesReportsBackendService,
		ngZone: NgZone,
		i18nService: I18nService,
	) {
		super(backendService, ngZone, AirsNotificationRuleModel, dialogsService, i18nService, {
			itemParams: [notificationRuleConditionGroupsStore],
			itemNameSingular: 'Notification Rule',
			itemNamePlural: 'Notification Rules',
			iconName: 'notificationRule',
		});
	}
}
