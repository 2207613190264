import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { memoize } from 'lodash-es';
import { ColorClassNames } from '@uifabric/styling';
import { PrettyNumberService } from '@wcd/prettify';
import { FlexDirection } from '@wcd/shared';

export interface LegendItem {
	name?: string;
	nameHtml?: string;
	iconColor?: string;
	iconColorName?: keyof typeof ColorClassNames;
	iconClassName?: string;
	iconName?: string;

	/**
	 * An additional optional value to be associated with the item.
	 */
	value?: number;
	helpKey?: string;
	helpText?: string;
	tooltip?: string;
	selectable?: boolean;
}

export type ValueFormatter = (value: LegendItem['value']) => string | number;

const getLegendItemClass: (isLast: boolean, orientation: FlexDirection) => string = memoize(
	(isLast, orientation) => {
		const margin = orientation === FlexDirection.Horizontal && !isLast ? 'wcd-margin-small-right' : '';
		return `wcd-flex-spaceBetween-horizontal wcd-chart-legend ${margin}`;
	}
);

@Component({
	selector: 'wcd-chart-legend',
	templateUrl: './legend.component.html',
	styleUrls: ['./legend.component.scss'],
})
export class LegendComponent {
	@Input() items: Array<LegendItem>;
	@Input() showValues: boolean = false;
	@Input() valueFormatter?: ValueFormatter;
	@Input() legendKind: 'regular' | 'small' = 'regular';
	@Input() orientation: FlexDirection = FlexDirection.Vertical;
	@Input() wrap?: boolean = false;

	@Output() readonly select = new EventEmitter<LegendItem>();

	readonly itemRole = 'listitem';
	FlexDirection = FlexDirection;
	private readonly _defaultValueFormatter: ValueFormatter;

	/**
	 * To be used by the Angular template engine to apply class to the host component.
	 */
	@HostBinding('class.wcd-full-height')
	get hostFullHeight() {
		return this.orientation === FlexDirection.Vertical;
	}

	/**
	 * Computed value to allow the Angular template engine to apply a class based on a condition.
	 */
	@HostBinding('class.wcd-flex-horizontal')
	get hostFlexHorizontal() {
		return this.orientation === FlexDirection.Horizontal;
	}

	/**
	 * Computed value to allow the Angular template engine to apply a class based on a condition.
	 */
	@HostBinding('class.wcd-flex-wrap')
	get hostFlexWrap() {
		return this.wrap === true;
	}

	/**
	 * Computed value to allow the Angular template engine to apply a class based on a condition.
	 */
	@HostBinding('class.wcd-flex-vertical')
	get hostFlexVertical() {
		return this.orientation === FlexDirection.Vertical;
	}

	/**
	 * Computed value to allow the Angular template engine to apply a class based on a condition.
	 */
	@HostBinding('class.small')
	get hostSmall() {
		return this.legendKind === 'small';
	}

	get formatter(): ValueFormatter {
		return this.valueFormatter || this._defaultValueFormatter;
	}

	constructor(prettyNumberService: PrettyNumberService) {
		this._defaultValueFormatter = value => prettyNumberService.prettyNumber(value);
	}

	getItemClass = (isLast: boolean) => getLegendItemClass(isLast, this.orientation);
}
