<ng-container *ngIf="!selectedClassification || selectedClassification.name == 'Not set'">
	<ng-container *ngIf="selectedStatus?.allowClassification">
		<fab-message-bar *ngIf="!isCombinedClassificationEnabled"
						 [isMultiline]="isNarrowLayout"
						 [messageBarType]="MessageBarType.info"
						 [renderActions]="actions"
						 contentClass="wcd-padding-small-all wcd-margin-large-bottom"
						 [styles]="fixMessageBarButtonsOrder"
						 [attr.aria-label]="'alerts.panel.manageAlerts.classify.message' | i18n"
						 >
			<div class="wcd-text-overflow-ellipsis-nowrap wcd-text-overflow-ellipsis"
				 [innerHTML]="'alerts.panel.manageAlerts.classify.message' | i18n"
				 [wcdTooltip]="'alerts.panel.manageAlerts.classify.message' | i18n">
			</div>
		</fab-message-bar>

		<i class="loader-icon" *ngIf="isUpdatingClassification"></i>

		<ng-template #actions>
			<ng-container *ngFor="let selectableClassification of selectableAlertClassifications">
				<span *ngIf="selectableClassification.type != AlertClassificationType.Unknown"
					class="wcd-padding-mediumSmall-right">
					<fab-messagebar-button *ngIf="selectableClassification.isSelectable"
											[text]="selectableClassification.nameI18nKey | i18n"
											[rbac]="rbacSettings"
											[isDisabled]="readonly || isUpdatingAlert"
											(onClick)="onClassificationSelectWithValue(selectableClassification)"
					></fab-messagebar-button>
				</span>
			</ng-container>
		</ng-template>
	</ng-container>
</ng-container>

<table class="key-value-list" role="none">
	<tr role="none">
		<td class="key" role="none">{{ "alerts.panel.manageAlerts.status.title" | i18n }}</td>
		<td class="value" role="none">
			<button class="btn btn-secondary-primary"
					*ngIf="isSingleAlert && alert.status?.isHidden; else visibleAlert"
					(click)="restoreHiddenAlert()"
					[rbac]="rbacSettings"
					[disabled]="readonly || isUpdatingAlert"
					data-track-id="RestoreHiddenAlert"
					data-track-type="Button">{{ "alerts.panel.manageAlerts.status.restore.hidden.alert" | i18n }}
			</button>
			<ng-template #visibleAlert>
				<fancy-select
							[ariaLabel]="'Status'"
							[values]="selectableStatuses"
							[(ngModel)]="selectedStatus"
							[rbac]="rbacSettings"
							[isBordered]="true"
							labelClass="dropdown-width-small-medium"
							[class.cursor-progress]="isUpdatingAlert"
							[placeholder]="'alerts.panel.manageAlerts.selectedStatuses.placeholder' | i18n"
							name="alert-status"
							[isDisabled]="readonly || isUpdatingAlert"
							labelI18nKey="nameI18nKey"
							(ngModelChange)="onStatusSelect($event)">
				</fancy-select>
				<i class="loader-icon" *ngIf="isUpdatingStatus"></i>
			</ng-template>
		</td>
	</tr>
	<ng-container *ngIf="isCombinedClassificationEnabled; else oldClassification">
		<tr role="none">
			<td class="key" role="none">{{ "alerts.panel.manageAlerts.classify.title" | i18n }}</td>
			<td class="value" role="none">
				<fab-dropdown dropdownWidth="5"
							  [selectedKey]="selectedCombinedClassification"
							  [options]="combinedClassificationMap"
							  (onChange)="onNewClassificationDropdownChange($event)">
				</fab-dropdown>
				<i class="loader-icon" *ngIf="isUpdatingClassification"></i>
			</td>
		</tr>
	</ng-container>
	<ng-template #oldClassification>
		<tr *ngIf="selectedStatus?.allowClassification" role="none">
			<td class="key" role="none">{{ "alerts.panel.manageAlerts.classify.title" | i18n }}</td>
			<td class="value" role="none">
				<fancy-select [ariaLabel]="'Classification'"
							  [values]="selectableAlertClassifications"
							  [(ngModel)]="selectedClassification"
							  [rbac]="rbacSettings"
							  [isBordered]="true"
							  labelClass="dropdown-width-small-medium"
							  [class.cursor-progress]="isUpdatingAlert"
							  labelI18nKey="nameI18nKey"
							  [isDisabled]="readonly || isUpdatingAlert"
							  [placeholder]="'alerts.panel.manageAlerts.classify.placeholder' | i18n"
							  (ngModelChange)="onClassificationSelect($event)"
								name="alert-classification">
				</fancy-select>
				<i class="loader-icon" *ngIf="isUpdatingClassification"></i>
			</td>
		</tr>
		<tr *ngIf="selectedStatus?.allowClassification && selectedClassification?.allowDetermination" role="none">
			<td class="key" role="none">{{'alerts.panel.manageAlerts.determination' | i18n}}</td>
			<td class="value" role="none">
				<fancy-select [values]="allAlertDeterminations"
							[(ngModel)]="selectedDetermination"
							[rbac]="rbacSettings"
							[isBordered]="true"
							labelClass="dropdown-width-small-medium"
							[class.cursor-progress]="isUpdatingAlert"
							labelI18nKey="nameI18nKey"
							trackBy="id"
							[isDisabled]="readonly || isUpdatingAlert"
							[placeholder]="'alerts.panel.manageAlerts.determination.placeholder' | i18n"
							(ngModelChange)="onDeterminationSelect($event)">
				</fancy-select>
				<i class="loader-icon" *ngIf="isUpdatingDetermination"></i>
			</td>
		</tr>
	</ng-template>
	<tr role="none" *ngIf="isAssignToSomeoneElseEnabled">
		<td class="key" role="none">{{'incident_commandBar_manageIncident_assignTo' | i18n }}</td>
		<td class="value" role="none">
			<assignee-picker
				[assignedTo]="assignedTo"
				(onSelectAssignee)="onSelectAssignee($event)"
				[isDisabled]="readonly || isUpdatingAlert"
			></assignee-picker>
		</td>
	</tr>
</table>
