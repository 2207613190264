import { Entity } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { NetworkDeviceBase } from '../networks-devices/network-device.base.entity';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'SoftwareInstallationNetworkDevice',
	pluralName: 'SoftwareInstallationNetworkDevices',
	endpoint: 'installations/networkDevices',
	readonly: true,
})
export class SoftwareInstallationNetworkDevice extends NetworkDeviceBase {}
