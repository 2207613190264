import { DetectionSource } from '@wcd/domain';
import { AppContextService, FlavorService, FeaturesService, Feature } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';
import { I18nService } from '@wcd/i18n';
import { getDetectionSourceFilterValueByServiceSource, mdatpDetectionSources } from '@wcd/scc-common';
import { AppFlavorConfig } from '@wcd/scc-common';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i2 from "../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i3 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i5 from "../../../../../../../projects/config/src/lib/services/app-flavor.service";
var SourceFilterService = /** @class */ (function () {
    function SourceFilterService(appConfigService, appContextService, i18nService, featuresService, flavorService) {
        this.appConfigService = appConfigService;
        this.appContextService = appContextService;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.detectionSourceFiltersExtended = flavorService.isEnabled(AppFlavorConfig.alerts.detectionSourceExtended);
        this.isMtpFilterHidden = !flavorService.isEnabled(AppFlavorConfig.alertsQueue.extendedFilterAndSubFilterOptions);
        this.isThreatExpertEnabledForMdatp =
            flavorService.isEnabled(AppFlavorConfig.alerts.threatExpertSource) &&
                this.appConfigService.isBilbaoEnabled;
        this.isThreatExpertEnabledForMtp = this.appConfigService.isBilbaoEnabled;
        this.isSmbDetectionSourcesEnabled = flavorService.isEnabled(AppFlavorConfig.incidents.detectionSources);
        this.isManualAlertsEnabled = featuresService.isEnabled(Feature.ShowNewHuntingPageToggle);
    }
    SourceFilterService.prototype.getDetectionSourcesFilterValues = function () {
        var _this = this;
        return mdatpDetectionSources(this.appContextService.isMtp, this.isThreatExpertEnabledForMdatp, true, this.detectionSourceFiltersExtended, this.isSmbDetectionSourcesEnabled).concat(this.getThirdPartySourceValue()).map(function (detectionSource) { return ({
            value: detectionSource.id,
            name: _this.i18nService.get(detectionSource.nameI18nKey),
            count: null,
        }); });
    };
    SourceFilterService.prototype.getServiceSourceTreeFilterValues = function () {
        var _this = this;
        var filterValues = getDetectionSourceFilterValueByServiceSource(this.isThreatExpertEnabledForMtp, this.isThreatExpertEnabledForMdatp, this.appContextService.isMtp, !this.appConfigService.isOatpActive, !this.appConfigService.isItpActive, this.isMtpFilterHidden, this.detectionSourceFiltersExtended, this.featuresService.isEnabled(Feature.MapgAlerts) && this.appConfigService.isMapgActive, this.featuresService.isEnabled(Feature.AadIp2Alerts) && this.appConfigService.isAadIpActive, this.isSmbDetectionSourcesEnabled, this.isManualAlertsEnabled, this.featuresService.isEnabled(Feature.SupportDlpServiceSource) &&
            this.appConfigService.isOatpActive //TODO: switch to isDlpActive once BE is ready - VSO: 34891225)
        );
        //translate names
        return filterValues.map(function (serviceSource) { return ({
            value: serviceSource.value,
            name: _this.i18nService.get(serviceSource.nameI18nKey),
            children: serviceSource.children
                ? serviceSource.children.map(function (detectionSource) { return ({
                    name: _this.i18nService.get(detectionSource.nameI18nKey),
                    value: detectionSource.value,
                    priority: detectionSource.priority,
                }); })
                : null,
        }); });
    };
    SourceFilterService.prototype.getThirdPartySourceValue = function () {
        var _this = this;
        return DetectionSource.entityConfig.values.filter(function (source) { return source.isSelectable && source.isGroupedThirdParty === !_this.appContextService.isMtp; });
    };
    SourceFilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SourceFilterService_Factory() { return new SourceFilterService(i0.ɵɵinject(i1.AppConfigService), i0.ɵɵinject(i2.AppContextService), i0.ɵɵinject(i3.I18nService), i0.ɵɵinject(i4.FeaturesService), i0.ɵɵinject(i5.FlavorService)); }, token: SourceFilterService, providedIn: "root" });
    return SourceFilterService;
}());
export { SourceFilterService };
