var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AirsNotificationRuleConditionGroupModel } from '../models/airs-notification-rule-condition-group.model';
import { NgZone } from '@angular/core';
import { AirsNotificationRuleConditionGroupsBackendService } from './airs-notification-rule-condition-groups.backend.service';
import { ReadOnlyStore } from '../../../data/models/readonly-store.base';
var AirsNotificationRuleConditionGroupsStore = /** @class */ (function (_super) {
    __extends(AirsNotificationRuleConditionGroupsStore, _super);
    function AirsNotificationRuleConditionGroupsStore(backendService, ngZone) {
        return _super.call(this, backendService, ngZone, AirsNotificationRuleConditionGroupModel, {
            itemNameSingular: 'Notification Rule Condition Group',
            itemNamePlural: 'Notification Rule Condition Groups',
        }) || this;
    }
    return AirsNotificationRuleConditionGroupsStore;
}(ReadOnlyStore));
export { AirsNotificationRuleConditionGroupsStore };
