import { ComponentRef, Injectable, OnDestroy } from '@angular/core';
import { ModalService } from '../modals/services/modal.service';
import { ConfirmOptions } from '../models/confirm-options';
import { DimensionsModel } from '../models/dimensions.model';
import { ConfirmEvent } from './confirm.event';
import { ConfirmModalComponent } from './confirm.modal';
import { Subscription } from 'rxjs';

@Injectable()
export class ConfirmationService implements OnDestroy {
	private modalSub: Subscription;
	constructor(private modalsService: ModalService) {}

	confirm(options: ConfirmOptions): Promise<ConfirmEvent<any>> {
		return new Promise(resolve => {
			let modal: ComponentRef<ConfirmModalComponent>;

			this.modalSub = this.modalsService
				.create(
					ConfirmModalComponent,
					{
						dimensions: new DimensionsModel(400, 0),
						id: 'confirm-dialog',
						showOverlay: options.showOverlay,
					},
					{
						options: options,
						confirm: (message?: string) => {
							const confirmData =
								options.requireMessage && options.requireMessage.property && message
									? { [options.requireMessage.property]: message }
									: null;

							resolve({ confirmed: true, data: confirmData });
						},
						cancel: () => {
							resolve({ confirmed: false });
						},
					}
				)
				.subscribe((_modal: ComponentRef<ConfirmModalComponent>) => {
					modal = _modal;

					_modal.onDestroy(() => {
						resolve({ confirmed: false });
					});
				});
		});
	}

	ngOnDestroy(): void {
		this.modalSub && this.modalSub.unsubscribe();
	}
}
