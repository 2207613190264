<!-- tslint:disable:template-mouse-events-have-key-events -->
<nav class="main-side-nav side wcd-flex-vertical light-theme"
	 [class.expanded]="isExpanded"
	 [class.has-scrollbar]="hasScrollbar"
	 data-track-component="Main Nav"
	 *ngIf="showItems"
	 [attr.aria-label]="'Main navigation'">
	<div
		class="main-side-nav-scrollable"
		(scroll)="onScroll($event)"
		#navScrollable>
		<div id="nav-header" class="wcd-flex-none wcd-margin-small-bottom">
			<button
				type="button"
				class="btn btn-link"
				id="close-nav-btn"
				data-track-id="MainNavToggle"
				data-track-type="Button"
				(click)="toggleMainNav()"
				[attr.aria-expanded]="mainAppState.mainNavIsExpanded ? true : false"
				aria-live="polite"
			>
				<wcd-shared-icon [iconName]="'parallel'"></wcd-shared-icon>
			</button>
		</div>
		<div id="nav-items" class="wcd-flex-vertical" (keydown)="onNavItemsKeydown($event)">
			<div class="main-menu-section">
				<ul class="list-unstyled main-menu wcd-flex-1" role="menubar">
				<ng-template [ngTemplateOutlet]="menuTemplate"
							 [ngTemplateOutletContext]="{ section: section, sectionIndex: sectionIndex }"
							 *ngFor="let section of navSections; let first = first; index as sectionIndex;">
				</ng-template>
				</ul>
			</div>
		</div>
	</div>
</nav>

<ng-template #menuTemplate let-sectionModel="section" let-sectionIndex="sectionIndex">
		<li
			*ngFor="let navItem of sectionModel ? sectionModel.items : getAllNavItem() | mainNavEnabled; index as navItemIndex;"
			class="main-menu-item"
			(mouseover)="!isMobileSize && toggleFloatingSubNav($event, navItem, true)"
			(mouseout)="!isMobileSize && toggleFloatingSubNav($event, navItem, false)"
			[class.nav-with-submenu]="navItem.isFolder"
			[class.selected]="navItem.isExpanded && !mainAppState.mainNavIsExpanded"
			role="none"
		>
			<!-- root leaf: -->
			<route-link
				[linkId]="navItem.id"
				[routeLink]="navItem"
				[linkTabIndex]="sectionIndex === 0 && navItemIndex === 0 ? 0 : -1"
				[wcdTooltip]="!mainAppState.mainNavIsExpanded ? navItem.name : ''"
				*ngIf="!navItem.isFolder; else folder"
				[className]="isActive(navItem) ? 'active' : ''"
				[routerLinkActiveCssClass]="'active'"
				(onClick)="mainNavService.notifyNavClick(navItem)"
				(onArrowRight)="onNavItemArrowRight({$event: $event, navItem: navItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex})"
				(onArrowLeft)="onNavItemArrowLeft({$event: $event, navItem: navItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex})"
				(onArrowUp)="onNavItemArrowUp({$event: $event, navItem: navItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex})"
				(onArrowDown)="onNavItemArrowDown({$event: $event, navItem: navItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex})"
				role="none"
				linkAriaRole="menuitem"
				[linkAriaSelected]="isActive(navItem)"
				[linkAriaLabel]="navItem.name + (isActive(navItem) ? ' selected' : '') "
				(onFocus)="onNavFocus($event, navItem)"
			>
				<wcd-shared-icon *ngIf="navItem.icon" [iconName]="navItem.icon" aria-hidden="true"></wcd-shared-icon>
				<img *ngIf="navItem.getImage" [attr.src]="navItem.getImage()"
					 [ngClass]="navItem.getImageClass && navItem.getImageClass()" alt=""/>
				<span [hidden]="!mainAppState.mainNavIsExpanded" class="nav-name">{{navItem.name}}</span>
			</route-link>

			<!-- folder: -->
			<ng-template #folder>
				<a
					[id]="navItem.id"
					class="navigationButton"
					data-track-type="Navigation"
					[attr.data-track-id]="'MainNavTo_' + navItem.id"
					[class.active]="isActive(navItem)"
					[class.open]="subNavsState[navItem.name].visibility === 'shown'"
					[attr.href]="navItem.routerLink[0]"
					[attr.data-link-id]="navItem.id"
					[attr.tabindex]="sectionIndex === 0 && navItemIndex === 0 ? 0 : -1"
					[attr.aria-label]="navItem.name"
					(click)="!isMobileSize  ? onFolderClick(navItem, $event) : onFolderClickMobile(navItem, $event)"
					role="menuitem"
					(focus)="onNavFocus($event, navItem)"
					[attr.aria-expanded]="subNavsState[navItem.name].visibility === 'shown' ? 'true' : 'false'"
					(keydown.arrowright)="onNavItemArrowRight({$event: $event, navItem: navItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex})"
					(keydown.arrowleft)="onNavItemArrowLeft({$event: $event, navItem: navItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex})"
					(keydown.arrowup)="onNavItemArrowUp({$event: $event, navItem: navItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex})"
					(keydown.arrowdown)="onNavItemArrowDown({$event: $event, navItem: navItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex})"
				>
					<wcd-shared-icon [iconName]="navItem.icon" aria-hidden="true"></wcd-shared-icon>
					<img *ngIf="navItem.getImage" [attr.src]="navItem.getImage()"
						 [ngClass]="navItem.getImageClass && navItem.getImageClass()" alt=""/>
					<span [hidden]="!mainAppState.mainNavIsExpanded" class="nav-name">{{navItem.name}}</span>
					<wcd-shared-icon role="none" [hidden]="!mainAppState.mainNavIsExpanded" class="pull-right expand-sub-nav-btn"
									 [iconName]="navItem.isExpanded ? 'carets.up' : 'carets.down'"></wcd-shared-icon>
					<div
						class="main-menu-badge"
						*ngIf="mainAppState.mainNavItemBadges[navItem.id]"
						aria-hidden="true"
						role="none"
					>
						{{mainAppState.mainNavItemBadges[navItem.id]}}
					</div>
				</a>
				<div
					class="nav-submenu-wrapper"
					[@slideFromTop]="subNavsState[navItem.name].visibility"
					role="presentation"
					>
					<div class="nav-submenu-container"
						 [style.overflow]="!navItem.isExpanded? 'hidden' : 'visible'"
						 [attr.aria-label]="navItem.name"
						 role="presentation"
					>
						<ul
							class="nav-submenu"
							[class.floating]="!mainAppState.mainNavIsExpanded"
							[style.top.px]="submenuTop"
							role="menu"
						>

							<li [hidden]="mainAppState.mainNavIsExpanded" role="none">
								<a class="nav-submenu-header" tabindex="-1" role="menuitem">
									<span class="nav-name">{{navItem.name}}</span>
								</a>
							</li>
							<!-- folder leaf: -->
							<li
								*ngFor="let subNavItem of navItem.children | mainNavEnabled; index as subNavItemIndex;"
								role="none"
							>
								<div class="main-menu-badge sub-menu-badge"
									 *ngIf="mainAppState.mainNavItemBadges[subNavItem.id]" role="none" aria-hidden="true">
									{{mainAppState.mainNavItemBadges[subNavItem.id]}}
								</div>
								<route-link
									[linkId]="subNavItem.id"
									linkTabIndex="-1"
									[routeLink]="subNavItem"
									[trackId]="'MainNavTo_' + subNavItem.id"
									[routerLinkActiveCssClass]="subNavItem.isAlias ? '' : 'active'"
									[className]="isActive(subNavItem) ? 'active' : ''"
									linkAriaRole="menuitem"
									[linkAriaSelected]="isActive(subNavItem)"
									[linkAriaLabel]="subNavItem.name + (isActive(subNavItem) ? ' selected' : '')"
									role="none"
									(onFocus)="onNavFocus($event, subNavItem)"
									(onArrowRight)="onNavItemArrowRight({$event: $event, navItem: subNavItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex, parentNavItem: navItem, subNavItemIndex: subNavItemIndex})"
									(onArrowLeft)="onNavItemArrowLeft({$event: $event, navItem: subNavItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex, parentNavItem: navItem, subNavItemIndex: subNavItemIndex})"
									(onArrowUp)="onNavItemArrowUp({$event: $event, navItem: subNavItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex, parentNavItem: navItem, subNavItemIndex: subNavItemIndex})"
									(onArrowDown)="onNavItemArrowDown({$event: $event, navItem: subNavItem, sectionIndex: sectionIndex, navItemIndex: navItemIndex, parentNavItem: navItem, subNavItemIndex: subNavItemIndex})"
								>
									<wcd-shared-icon *ngIf="navItem.icon" [iconName]="subNavItem.icon"
													 aria-hidden="true"></wcd-shared-icon>
									<span aria-hidden="true" class="nav-name">{{subNavItem.name}}</span>
								</route-link>
							</li>
						</ul>
					</div>
				</div>
			</ng-template>
		</li>
</ng-template>
