<dl [class.key-values]="asKeyValueList" role="none">
	<ng-container *ngIf="endpoint.ip">
		<dt>{{ 'networkEndpoints.fields.ip.title' | i18n }}</dt>
		<dd>
			<ng-container *ngIf="showEntityPanelLink; else ipName">
				<entity-link [entity]="endpoint.ip"
							 [entityType]="ipType"
							 [linkText]="endpoint.ip.name"></entity-link>
			</ng-container>
			<ng-template #ipName>{{endpoint.ip.name}}</ng-template>
		</dd>
	</ng-container>
	<ng-container *ngIf="endpoint.url">
		<dt>{{ 'networkEndpoints.fields.url.title' | i18n }}</dt>
		<dd>
			<ng-container *ngIf="showEntityPanelLink; else urlName">
				<entity-link [entity]="endpoint.url"
							 [entityType]="urlType"
							 [linkText]="endpoint.url.name"></entity-link>
			</ng-container>
			<ng-template #urlName>{{endpoint.url.name}}</ng-template>
		</dd>
	</ng-container>
	<ng-container *ngIf="endpoint.port">
		<dt>{{ 'networkEndpoints.fields.port.title' | i18n }}</dt>
		<dd>
			{{endpoint.port}}
		</dd>
	</ng-container>
	<ng-container *ngIf="endpoint.protocol">
		<dt>{{ 'networkEndpoints.fields.protocol.title' | i18n }}</dt>
		<dd>
			{{endpoint.protocol}}
		</dd>
	</ng-container>
</dl>
