/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../i18n/src/lib/services/i18n.service";
import * as i3 from "@angular/common";
import * as i4 from "./time-series-chart.component";
import * as i5 from "../../../../localization/src/lib/services/tz-date.service";
var styles_TimeSeriesChartComponent = [];
var RenderType_TimeSeriesChartComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimeSeriesChartComponent, data: {} });
export { RenderType_TimeSeriesChartComponent as RenderType_TimeSeriesChartComponent };
export function View_TimeSeriesChartComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), i0.ɵqud(671088640, 1, { timeSeriesChart: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(3, 0, [[1, 0], ["timeSeriesChart", 1]], null, 3, "div", [["class", "chart"]], [[1, "aria-label", 0]], null, null, null, null)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(5, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "chart"; var currVal_2 = _co.className; _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), "charts.timeSeriesChart")); _ck(_v, 3, 0, currVal_0); }); }
export function View_TimeSeriesChartComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-time-series-chart", [], null, null, null, View_TimeSeriesChartComponent_0, RenderType_TimeSeriesChartComponent)), i0.ɵdid(1, 770048, null, 0, i4.TimeSeriesChartComponent, [i0.ElementRef, i2.I18nService, i5.TzDateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimeSeriesChartComponentNgFactory = i0.ɵccf("wcd-time-series-chart", i4.TimeSeriesChartComponent, View_TimeSeriesChartComponent_Host_0, { settings: "settings", data: "data", change: "change", enableRenderOnSettingsChanges: "enableRenderOnSettingsChanges", className: "className" }, {}, []);
export { TimeSeriesChartComponentNgFactory as TimeSeriesChartComponentNgFactory };
