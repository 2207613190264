/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vulnerability.entity-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i3 from "@angular-react/fabric";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i5 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../../global_entities/components/entity-details/vulnerability.entity-details.component.ngfactory";
import * as i7 from "../../../../global_entities/components/entity-details/vulnerability.entity-details.component";
import * as i8 from "@microsoft/paris/dist/lib/paris";
import * as i9 from "../../../../tvm/services/tvm-vulnerability-severity.service";
import * as i10 from "../../common/side-panel.service";
import * as i11 from "../../../../tvm/services/tvm-icon-builder.service";
import * as i12 from "../../../../tvm/services/tvm-texts.service";
import * as i13 from "../../../../../../../../projects/config/src/lib/services/features.service";
import * as i14 from "../../../../tvm/services/tvm-download.service";
import * as i15 from "@angular/common";
import * as i16 from "./vulnerability.entity-panel.component";
import * as i17 from "@angular/router";
var styles_VulnerabilityEntityPanelComponent = [i0.styles];
var RenderType_VulnerabilityEntityPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VulnerabilityEntityPanelComponent, data: {} });
export { RenderType_VulnerabilityEntityPanelComponent as RenderType_VulnerabilityEntityPanelComponent };
function View_VulnerabilityEntityPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "footer", [["class", "wcd-padding-small-top flex-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "wcd-flex-none wcd-padding-all wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "fab-primary-button", [["data-track-id", "navigateToRelatedSecurityRecommendation"], ["data-track-type", "Navigation"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.navigateToSecurityRecommendation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FabPrimaryButtonComponent_0, i2.RenderType_FabPrimaryButtonComponent)), i1.ɵdid(5, 6012928, null, 1, i3.FabPrimaryButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i1.NgZone], { text: [0, "text"] }, { onClick: "onClick" }), i1.ɵqud(603979776, 1, { menuItemsDirectives: 1 }), i1.ɵppd(7, 1), (_l()(), i1.ɵted(-1, 0, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), "tvm.changeEvent.goToRelatedRecommendations")); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_VulnerabilityEntityPanelComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i4.I18nPipe, [i5.I18nService]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "section", [["class", "wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-vertical wcd-scroll-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "vulnerability-entity-details", [], null, null, null, i6.View_VulnerabilityEntityDetailsComponent_0, i6.RenderType_VulnerabilityEntityDetailsComponent)), i1.ɵdid(6, 114688, null, 0, i7.VulnerabilityEntityDetailsComponent, [i5.I18nService, i8.Paris, i9.TvmWeaknessSeverityService, i1.ChangeDetectorRef, i10.SidePanelService, i11.TvmIconBuilderService, i12.TvmTextsService, i13.FeaturesService, i1.Injector, i14.TvmDownloadService], { contextOptions: [0, "contextOptions"], vulnerability: [1, "vulnerability"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VulnerabilityEntityPanelComponent_1)), i1.ɵdid(10, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contextOptions; var currVal_1 = _co.vulnerability; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = _co.entity.impactedAssetsCount; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_VulnerabilityEntityPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vulnerability-entity-panel", [], null, null, null, View_VulnerabilityEntityPanelComponent_0, RenderType_VulnerabilityEntityPanelComponent)), i1.ɵdid(1, 245760, null, 0, i16.VulnerabilityEntityPanelComponent, [i1.ChangeDetectorRef, i17.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VulnerabilityEntityPanelComponentNgFactory = i1.ɵccf("vulnerability-entity-panel", i16.VulnerabilityEntityPanelComponent, View_VulnerabilityEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { VulnerabilityEntityPanelComponentNgFactory as VulnerabilityEntityPanelComponentNgFactory };
