import { AirsEntity } from '../airs-entity.entity';

import { AirsAppendedFile } from './airs-entity.appended-file';
import { AirsDriver } from './airs-entity.driver';
import { AirsEmail } from './airs-entity.email';
import { AirsEmailSubmission } from './airs-entity.email-submission';
import { AirsEmailCluster } from './airs-entity.email-cluster';
import { AirsEmailLink } from './airs-entity.email-link';
import { AirsFile } from './airs-entity.file';
import { AirsFileBase } from './airs-entity.file-base';
import { AirsIp } from './airs-entity.ip';
import { AirsMailbox } from './airs-entity.mailbox';
import { AirsMailboxConfiguration } from './airs-entity.mailbox-configuration';
import { AirsPersistenceMethod } from './airs-entity.persistence-method';
import { AirsProcess } from './airs-entity.process';
import { AirsService } from './airs-entity.service';
import { AirsLinkBase } from './airs-entity.link-base';
import { AirsUrl } from './airs-entity.url';
import { AirsUser } from './airs-entity.user';
import { AirsUserActivity } from './airs-entity.user-activity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { AirsAccount } from './airs-entity.account';
import { AirsSecurityGroup } from './airs-entity.security-group';
import { AirsRegistryKey } from './airs-entity.registry-key';
import { AirsRegistryValue } from './airs-entity.registry-value';
import { AirsCloudApplication } from './airs-entity.cloud-application';
import { AirsCloudLogonSession } from './airs-entity.cloud-logon-session';
import { AirsOauthApplication } from './airs-entity.oauth-application';
import { AirsActiveDirectoryDomain } from './airs-entity.active-directory-domain';

// these classes aren't used directly in the Angular project.
// instead, they are used using paris' 'modelWith' feature.
// because of this, they are getting tree-shaked by the Angular compiler.
// this unused const is here to 'fool' the Angular compiler and avoid tree-shaking for these classes
const __airsEntitiesStub: Record<AirsEntityRefNames, typeof AirsEntity> = {
	[AirsEntityRefNames.AirsAppendedFile]: AirsAppendedFile,
	[AirsEntityRefNames.AirsDriver]: AirsDriver,
	[AirsEntityRefNames.AirsEmailMessage]: AirsEmail,
	[AirsEntityRefNames.AirsEmailMessageSubmission]: AirsEmailSubmission,
	[AirsEntityRefNames.AirsEmailCluster]: AirsEmailCluster,
	[AirsEntityRefNames.AirsEmailLink]: AirsEmailLink,
	[AirsEntityRefNames.AirsFile]: AirsFile,
	[AirsEntityRefNames.AirsIp]: AirsIp,
	[AirsEntityRefNames.AirsMailbox]: AirsMailbox,
	[AirsEntityRefNames.AirsMailboxConfiguration]: AirsMailboxConfiguration,
	[AirsEntityRefNames.AirsPersistenceMethod]: AirsPersistenceMethod,
	[AirsEntityRefNames.AirsProcess]: AirsProcess,
	[AirsEntityRefNames.AirsService]: AirsService,
	[AirsEntityRefNames.AirsUrl]: AirsUrl,
	[AirsEntityRefNames.AirsUser]: AirsUser,
	[AirsEntityRefNames.AirsUserActivity]: AirsUserActivity,
	[AirsEntityRefNames.Account]: AirsAccount,
	[AirsEntityRefNames.AirsSecurityGroup]: AirsSecurityGroup,
	[AirsEntityRefNames.AirsRegistryKey]: AirsRegistryKey,
	[AirsEntityRefNames.AirsRegistryValue]: AirsRegistryValue,
	[AirsEntityRefNames.AirsCloudApplication]: AirsCloudApplication,
	[AirsEntityRefNames.AirsCloudLogonSession]: AirsCloudLogonSession,
	[AirsEntityRefNames.AirsOauthApplication]: AirsOauthApplication,
	[AirsEntityRefNames.AirsActiveDirectoryDomain]: AirsActiveDirectoryDomain,
};

export {
	AirsAppendedFile,
	AirsDriver,
	AirsEmail,
	AirsEmailSubmission,
	AirsEmailCluster,
	AirsEmailLink,
	AirsFile,
	AirsFileBase,
	AirsIp,
	AirsMailbox,
	AirsMailboxConfiguration,
	AirsPersistenceMethod,
	AirsProcess,
	AirsService,
	AirsLinkBase,
	AirsUrl,
	AirsUser,
	AirsUserActivity,
	AirsAccount,
	AirsSecurityGroup,
	AirsRegistryKey,
	AirsRegistryValue,
	AirsCloudApplication,
	AirsCloudLogonSession,
	AirsOauthApplication,
	AirsActiveDirectoryDomain,
};
