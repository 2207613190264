var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SecurityContext } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { map, take } from 'rxjs/operators';
import { DecodeQueryTextApiCall, EncodeQueryTextApiCall, HuntingQueryApiCall, HuntingSavedQuery, HuntingScheduledQuery, HuntingSharedQuery, HuntingUserQuery, } from '@wcd/domain';
import { ConfirmationDialogService } from '@wcd/dialogs';
import { SaveQueryContentComponent } from '../components/save-query-content.component';
import { Router } from '@angular/router';
import { HUNTING_ROUTE, WicdSanitizerService } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';
import { ScheduledHuntingErrorParsingService, } from '../../queries/services/scheduled-hunting.error-parsing.service';
import { FeaturesService, Feature } from '@wcd/config';
var QueryService = /** @class */ (function () {
    function QueryService(paris, confirmationDialogService, scheduledQueriesErrorParsingService, router, i18nService, featuresService, domSanitizer) {
        this.paris = paris;
        this.confirmationDialogService = confirmationDialogService;
        this.scheduledQueriesErrorParsingService = scheduledQueriesErrorParsingService;
        this.router = router;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.domSanitizer = domSanitizer;
    }
    QueryService.prototype.query = function (queryText, dateRange) {
        return this.paris.apiCall(HuntingQueryApiCall, {
            queryText: queryText,
            startTime: dateRange.from,
            endTime: dateRange.to,
        });
    };
    QueryService.prototype.saveQuery = function (_a) {
        var query = _a.query;
        return __awaiter(this, void 0, void 0, function () {
            var showSaveDialog, error_1, errorMessage;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        showSaveDialog = function (errorMessage) { return __awaiter(_this, void 0, void 0, function () {
                            var result;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.confirmationDialogService.showDialog({
                                            title: this.i18nService.get('hunting.saveQueryDialog.title'),
                                            confirmButton: this.i18nService.get('hunting_saveQueryDialog_buttons_save'),
                                            dimensions: { width: 500, height: 290 },
                                            contentComponent: {
                                                type: SaveQueryContentComponent,
                                                props: {
                                                    query: query,
                                                    errorMessage: errorMessage,
                                                },
                                            },
                                            handlers: {
                                                onSubmit: function (contentComponent) { return __awaiter(_this, void 0, void 0, function () {
                                                    var _a, kind, folderPath, modifiedQuery, error_2;
                                                    return __generator(this, function (_b) {
                                                        switch (_b.label) {
                                                            case 0:
                                                                contentComponent.isSaving = true;
                                                                _a = contentComponent.selection, kind = _a.kind, folderPath = _a.folderPath;
                                                                return [4 /*yield*/, this.paris
                                                                        .createItem(kind === 'user' ? HuntingUserQuery : HuntingSharedQuery, {
                                                                        Name: query.name,
                                                                        QueryText: query.text,
                                                                        Path: folderPath,
                                                                        UserId: kind === 'user' ? query.userId : null,
                                                                        IsReadOnly: query.readonly,
                                                                    })
                                                                        .pipe(take(1))
                                                                        .toPromise()];
                                                            case 1:
                                                                modifiedQuery = _b.sent();
                                                                _b.label = 2;
                                                            case 2:
                                                                _b.trys.push([2, 4, 5, 6]);
                                                                return [4 /*yield*/, this.saveQueryWithoutConfirmation(modifiedQuery)];
                                                            case 3: return [2 /*return*/, _b.sent()];
                                                            case 4:
                                                                error_2 = _b.sent();
                                                                contentComponent.errorMessage = this.scheduledQueriesErrorParsingService.parseError(error_2, true).errorMessage;
                                                                throw error_2;
                                                            case 5:
                                                                contentComponent.isSaving = false;
                                                                return [7 /*endfinally*/];
                                                            case 6: return [2 /*return*/];
                                                        }
                                                    });
                                                }); },
                                            },
                                        })];
                                    case 1:
                                        result = _a.sent();
                                        if (!result.confirmed) {
                                            return [2 /*return*/];
                                        }
                                        return [2 /*return*/, result.data];
                                }
                            });
                        }); };
                        if (!(query.id && query.name && query.text)) return [3 /*break*/, 6];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 5]);
                        return [4 /*yield*/, this.saveQueryWithoutConfirmation(query)];
                    case 2: return [2 /*return*/, _b.sent()];
                    case 3:
                        error_1 = _b.sent();
                        errorMessage = this.scheduledQueriesErrorParsingService.parseError(error_1, true).errorMessage;
                        return [4 /*yield*/, showSaveDialog(errorMessage)];
                    case 4: return [2 /*return*/, _b.sent()];
                    case 5: return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, showSaveDialog()];
                    case 7: return [2 /*return*/, _b.sent()];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    QueryService.prototype.renameQuery = function (_a) {
        var query = _a.query, newName = _a.newName;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!newName) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.renameQueryWithoutConfirmation(query, newName)];
                    case 1: return [2 /*return*/, _b.sent()];
                    case 2: return [4 /*yield*/, this.confirmationDialogService.showTextInputDialog({
                            title: this.i18nService.get('hunting.renameQueryDialog.title'),
                            confirmButton: this.i18nService.get('hunting_saveQueryDialog_buttons_save'),
                            textInputProps: {
                                label: 'Name',
                                required: true,
                                placeholder: query.name,
                                text: query.name,
                            },
                            handlers: {
                                onSubmit: function (_a) {
                                    var text = _a.text;
                                    return _this.renameQueryWithoutConfirmation(query, text);
                                },
                            },
                            dimensions: { height: 200, width: 500 },
                        })];
                    case 3:
                        result = _b.sent();
                        if (!result.confirmed) {
                            return [2 /*return*/];
                        }
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    QueryService.prototype.deleteQuery = function (_a) {
        var query = _a.query, _b = _a.withUserConfirmation, withUserConfirmation = _b === void 0 ? true : _b;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!!withUserConfirmation) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.deleteQueryWithoutConfirmation(query)];
                    case 1: return [2 /*return*/, _c.sent()];
                    case 2: return [4 /*yield*/, this.confirmationDialogService.showDialog({
                            title: this.i18nService.get('hunting.deleteQueryDialog.title'),
                            body: this.i18nService.get('hunting.deleteQueryDialog.verifyDeletion', { queryName: query.name }),
                            confirmButton: this.i18nService.get('hunting_deleteQueryDialog_buttons_delete'),
                            handlers: {
                                onSubmit: function () { return _this.deleteQueryWithoutConfirmation(query); },
                            },
                        })];
                    case 3:
                        result = _c.sent();
                        if (!result.confirmed) {
                            return [2 /*return*/];
                        }
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    QueryService.prototype.loadQuery = function (partialQuery) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.getRepository(partialQuery)
                        .getItemById(partialQuery.id)
                        .pipe(take(1))
                        .toPromise()];
            });
        });
    };
    QueryService.prototype.getDeepLinkToQuery = function (queryOrText) {
        var _this = this;
        var query = typeof queryOrText === 'object'
            ? queryOrText
            : new HuntingUserQuery({ id: undefined, text: queryOrText });
        return this.paris
            .apiCall(EncodeQueryTextApiCall, query.text)
            .pipe(map(function (encodedQuery) {
            return _this.router.createUrlTree([HUNTING_ROUTE], { queryParams: { query: encodedQuery } });
        }));
    };
    QueryService.prototype.parseQueryFromDeepLink = function (encodedQuery) {
        return this.paris
            .apiCall(DecodeQueryTextApiCall, encodedQuery)
            .pipe(map(function (decodedQuery) { return new HuntingUserQuery({ id: undefined, text: decodedQuery }); }));
    };
    QueryService.prototype.saveQueryWithoutConfirmation = function (query) {
        var name = this.domSanitizer.sanitize(SecurityContext.HTML, query.name);
        return this.getRepository(query)
            .save(Object.assign({}, query, { name: name }))
            .toPromise();
    };
    QueryService.prototype.deleteQueryWithoutConfirmation = function (query) {
        return this.getRepository(query).removeItem(query).toPromise();
    };
    QueryService.prototype.renameQueryWithoutConfirmation = function (query, newName) {
        var name = this.domSanitizer.sanitize(SecurityContext.HTML, newName);
        return this.getRepository(query)
            .save(Object.assign({}, query, { name: name }))
            .toPromise();
    };
    QueryService.prototype.getRepository = function (partialQuery) {
        return this.paris.getRepository(this.getEntityType(partialQuery));
    };
    QueryService.prototype.getEntityType = function (partialQuery) {
        if (this.shouldMigrateToVNext()) {
            return HuntingSavedQuery;
        }
        if (partialQuery.kind === 'scheduled') {
            return HuntingScheduledQuery;
        }
        else if (partialQuery.kind === 'user') {
            return HuntingUserQuery;
        }
        else if (partialQuery.kind === 'shared') {
            return HuntingSharedQuery;
        }
    };
    QueryService.prototype.shouldMigrateToVNext = function () {
        return (this.featuresService.isEnabled(Feature.HuntingEndpointMigrationQueriesController) ||
            this.featuresService.isEnabled(Feature.HuntingEndpointMigrationQueriesControllerMtp));
    };
    return QueryService;
}());
export { QueryService };
