export enum FileActionPermissionResult {
	Disallowed = -1,
	Allowed = 0,
	AllowedWithWarning = 1,
}

export enum FileActionPermissionReason {
	NoReason = 0,
	FileHighPrevalence = 0x1,
	FileCertificateMicrosoftRoot = 0x2,
	FileCertificateNonMsTrustedSigner = 0x4,
	NonPeFile = 0x8,
	NotSupported = 0x10,
}
