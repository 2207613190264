import { RemediationActionTypeTypes } from '../remediation/remediation-action-type-types.enum';

// this is a temp solution - office send SubType for EmailRemediation instead of sending it in the ActionType
export enum EmailRemediationActionSubType {
	EmailRemediation = 'EmailRemediation',
	MoveToJunk = 'MoveToJunk',
	MoveToInbox = 'MoveToInbox',
	Delete = 'Delete',
	MoveToDeletedItems = 'MoveToDeletedItems',
	DeleteAttachment = 'DeleteAttachment',
	SubmitForDetonation = 'SubmitForDetonation',
}

// some of the types are currently not part of alertV3 but should be in the future
export const AlertV3ActionTypeMap = Object.freeze({
	QuarantineFile: RemediationActionTypeTypes.file_quarantine,
	QuarantineService: RemediationActionTypeTypes.service_quarantine,
	QuarantineDriver: RemediationActionTypeTypes.driver_quarantine,
	ReleaseFile: RemediationActionTypeTypes.release_file,
	KillProcess: RemediationActionTypeTypes.kill_process,
	KillProcesses: RemediationActionTypeTypes.kill_processes,
	BlockUrl: RemediationActionTypeTypes.block_url,
	PendingResource: RemediationActionTypeTypes.pending_resource,
	InitiateDlp: RemediationActionTypeTypes.initiate_dlp,
	LimitUserPrivileges: RemediationActionTypeTypes.limit_user_privileges_info,
	RemoveUserFromLocalGroup: RemediationActionTypeTypes.remove_user_from_local_group,
	StartMultipleInvestigations: RemediationActionTypeTypes.start_multiple_investigations,
	QuarantinePersistenceMethod: RemediationActionTypeTypes.persistence_method_quarantine,
	ConfirmUserCompromised: RemediationActionTypeTypes.confirm_compromised_user,
	UndoCleanResource: RemediationActionTypeTypes.undo_clean_resource,
	LiveResponseCommand: RemediationActionTypeTypes.live_response_command,
	RemoveFile: RemediationActionTypeTypes.remove_file,

	// office action types
	Generic: RemediationActionTypeTypes.generic,
	QuarantineRegistryKey: RemediationActionTypeTypes.quarantine_registry_key,
	BlockSender: RemediationActionTypeTypes.block_sender,
	BlockSenderDomain: RemediationActionTypeTypes.block_sender_domain,
	BlockSpoFile: RemediationActionTypeTypes.block_spo_file,
	EmailRemediation: RemediationActionTypeTypes.email_remediation,
	EwsSettingChangeRemediation: RemediationActionTypeTypes.ews_setting_change_remediation,
	MailDelegationRemediation: RemediationActionTypeTypes.mail_delegation_remediation,
	MailForwardingRuleRemediation: RemediationActionTypeTypes.mail_forwarding_rule_remediation,
	OwaSettingChangeRemediation: RemediationActionTypeTypes.owa_setting_change_remediation,
	UserInboxRulesAnalyzer: RemediationActionTypeTypes.user_inbox_rules_analyzer,
	FileHashReputationAnalyzer: RemediationActionTypeTypes.file_hash_reputation_analyzer,
	UserAdminAnalyzer: RemediationActionTypeTypes.user_admin_analyzer,
	UserInboxRulesRemediation: RemediationActionTypeTypes.user_inbox_rules_remediation,
	UrlReputationAnalyzer: RemediationActionTypeTypes.url_reputation_analyzer,
	InterflowIPAnalyzer: RemediationActionTypeTypes.interflow_ip_analyzer,
	InterflowDomainAnalyzer: RemediationActionTypeTypes.interflow_domain_analyzer,
	EmailZapper: RemediationActionTypeTypes.email_zapper,
	BlockURLRemediation: RemediationActionTypeTypes.block_url_remediation,
	ForcePasswordResetRemediation: RemediationActionTypeTypes.force_password_reset_remediation, // also used for 'Force password reset' action of MDI product
	MoveToInbox: RemediationActionTypeTypes.move_to_inbox,
	Delete: RemediationActionTypeTypes.hard_email_remediation,
	BlockIp: RemediationActionTypeTypes.office_block_ip,
	MoveToJunk: RemediationActionTypeTypes.move_to_junk,
	MoveToDeletedItems: RemediationActionTypeTypes.move_to_deleted_items,
	DeleteAttachment: RemediationActionTypeTypes.delete_attachment,
	SubmitForDetonation: RemediationActionTypeTypes.submit_for_detonation,

	// cloud action types
	IsolateResponse: RemediationActionTypeTypes.isolate_response,
	UnIsolateResponse: RemediationActionTypeTypes.unisolate,
	// StopAndQuarantine: RemediationActionTypeTypes.stop_and_quarantine, // 'stop_and_quarantine' is currently mapped to 'RemediationResponse'
	RestrictAppExecutionResponse: RemediationActionTypeTypes.restrict_app_execution_response,
	UnrestrictAppExecutionResponse: RemediationActionTypeTypes.unrestrict_app_execution_response,
	RestrictCodeExecution: RemediationActionTypeTypes.restrict_code_execution,
	UnrestrictCodeExecution: RemediationActionTypeTypes.unrestrict_code_execution,
	AntivirusScanResponse: RemediationActionTypeTypes.antivirus_scan_response,
	CollectInvestigationPackageResponse: RemediationActionTypeTypes.collect_investigation_package_response,
	OffboardDevice: RemediationActionTypeTypes.offboard_device,
	RemediationResponse: RemediationActionTypeTypes.stop_and_quarantine, //currently 'RemediationResponse' should be mapped to 'stop and quarantine' action
	CollectFile: RemediationActionTypeTypes.request_sample, // currently 'CollectFile' should be mapped to 'request sample' action

	// Msec Identity actions
	MSecIdentitiesConfirmUserCompromised: RemediationActionTypeTypes.confirm_user_compromised,
	MSecIdentitiesSuspendUser: RemediationActionTypeTypes.suspend_user,
	MSecIdentitiesInvalidateAllRefreshTokensForAUser:
		RemediationActionTypeTypes.invalidate_all_refresh_tokens_for_a_user,

	// MDI action types
	EnableUser: RemediationActionTypeTypes.enable_user,
	DisableUser: RemediationActionTypeTypes.disable_user,

	// Magellan types
	ContainDevice: RemediationActionTypeTypes.contain_device,
	UnContainDevice: RemediationActionTypeTypes.uncontain_device,
});
