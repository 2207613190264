import { Mailbox, MailboxAlertsRelationship } from '@wcd/domain';
import { of } from 'rxjs';
import { catchError, startWith } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { Feature, FeaturesService } from "@wcd/config";
var loadingSymbol = Symbol();
var MailboxAlertsComponent = /** @class */ (function () {
    function MailboxAlertsComponent(paris, featureService) {
        this.paris = paris;
        this.featureService = featureService;
        this.loadingSymbol = loadingSymbol;
        this.mailboxAlertRepo = this.paris.getRelationshipRepository(MailboxAlertsRelationship);
    }
    Object.defineProperty(MailboxAlertsComponent.prototype, "mailbox", {
        get: function () {
            return this._mailbox;
        },
        set: function (mailbox) {
            this._mailbox = mailbox;
            if (!this.mailbox) {
                this.alertData$ = null;
                return;
            }
            this.alertData$ = this.paris
                .getRelatedItem(MailboxAlertsRelationship, this.mailbox, { where: { useMailboxAlertsVnextApi: this.featureService.isEnabled(Feature.AssociatedAlertsByEntity) } })
                .pipe(startWith(loadingSymbol), catchError(function (err) { return of(null); }));
        },
        enumerable: true,
        configurable: true
    });
    return MailboxAlertsComponent;
}());
export { MailboxAlertsComponent };
