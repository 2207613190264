import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('sevilleFocus', focusDirective);

function focusDirective() {
	return {
		restrict: 'EA',
		scope: {},
		link: function(scope, element, attrs) {
			element[0].focus();
		},
	};
}
