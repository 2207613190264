import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { RemediatedThreatStatus } from './remediated-threat-status.entity';

@ValueObject({
	singularName: 'Remediated Threat Status Count',
	pluralName: '',
	readonly: true,
})
export class RemediatedThreatStatusCount extends ModelBase {
	@EntityField() status: RemediatedThreatStatus;

	@EntityField()
	entityCount: number;
}
