import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Paris } from "@microsoft/paris";
import { BaselineConfigurationBaselineProfileDeviceRelationship, ResolveEntityURL, TvmEndPoint } from "@wcd/domain";
import { ConfigurationDevicesBaseComponent } from "./configuration-devices.base/configuration-devices.base.component";
import { BaselineConfigurationFieldsService } from "../../services/baseline-configuration.fields.service";
import { TvmDownloadService } from "../../../../../../../tvm/services/tvm-download.service";
import { BaselineDetectedValuesService } from '../../../../../../../tvm/services/baseline-detected-value.service';

@Component({
	selector: 'general-configuration-devices',
	templateUrl: './configuration-devices.base/configuration-devices.base.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralConfigurationDevicesComponent extends ConfigurationDevicesBaseComponent implements OnInit {
	constructor(
		fieldsService: BaselineConfigurationFieldsService,
		tvmDownloadService: TvmDownloadService,
		detectedValuesService: BaselineDetectedValuesService,
		paris: Paris) {
		super(fieldsService, tvmDownloadService, detectedValuesService, paris);
	}

	ngOnInit(): void {
		this.dataviewId = `baselineConfigurationDevices_${this.configuration.id}`
		super.ngOnInit();

		this.repository = this.paris.getRelationshipRepository(BaselineConfigurationBaselineProfileDeviceRelationship);
		this.repository.sourceItem = this.configuration;

		this.exportUrl = ResolveEntityURL({
			endPoint: TvmEndPoint.Analytics,
			entityModelBaseOrRelationship: BaselineConfigurationBaselineProfileDeviceRelationship,
			sourceModel: this.configuration,
		});
		this.exportFileName = this.configuration.id;
	}
}
