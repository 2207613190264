import { ValueObject, EntityModelBase, EntityField } from '@microsoft/paris';
import { RegistryValueTypes } from './registry-value-type.enum';

@ValueObject({
	singularName: 'Registry',
	pluralName: 'Registry items',
	readonly: true,
})
export class Registry extends EntityModelBase<string> {
	@EntityField({ data: 'Key' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'Key' })
	key: string;

	@EntityField({ data: 'ValueName' })
	valueName: string;

	@EntityField({ data: 'ValueData' })
	valueData: string;

	@EntityField({ data: 'ValueType' })
	valueType: RegistryValueTypes;

	get name(): string {
		return this.key;
	}
}
