export const IncidentAssignmentFilterValues = {
	Unassigned: {
		key: 'Unassigned',
		nameI18nKey: 'incident.assignmentFilterValue.unassigned',
	},
	Anyone: {
		key: 'Anyone',
		nameI18nKey: 'incident.assignmentFilterValue.anyone',
	},
	Me: {
		key: 'Me', // BE is not familiar with this value, filter using AssignedTo: currentUser@email.com
		nameI18nKey: 'incident.assignmentFilterValue.me',
	},
}

const ALL_ASSIGNMENT_OPTIONS_COUNT = 3;

export interface IncidentAssignmentFilters {
	incidentAssignment?: Array<string> | string;
	AssignedTo?: string | Array<string>;
}


/**
 * Returns true if the assignment filter combo has any effect.
 * (for instance, unassigned + anyone = all incident, no filter effect).
 * @param filters the assignment filter selection
 */
export const serializeIncidentAssignmentFiltersForPost = (filters: IncidentAssignmentFilters | string, withScrubbing = false): void => {
	if ('string' === typeof filters) {
		return;
	}

	let { incidentAssignment } = filters;
	incidentAssignment = incidentAssignment || '';
	const incidentAssignmentArray = incidentAssignment instanceof Array
		? incidentAssignment.join(',')
		: incidentAssignment;
	const incidentAssignmentLength = incidentAssignmentArray.split(',').length;

	filters.incidentAssignment = incidentAssignmentArray;
	if (!incidentAssignmentArray || !incidentAssignmentLength || incidentAssignmentLength === ALL_ASSIGNMENT_OPTIONS_COUNT) {
		// incidentAssignment = [] or [Anyone, Me, Unassigned]. There's no filter effect
		delete filters.AssignedTo;
		delete filters.incidentAssignment;
		return;
	}

	if (incidentAssignmentArray.includes(IncidentAssignmentFilterValues.Me.key) || incidentAssignmentArray.includes('@')) {
		if (incidentAssignmentArray.includes(IncidentAssignmentFilterValues.Anyone.key)) {
			filters.incidentAssignment = IncidentAssignmentFilterValues.Anyone.key;
			delete filters.AssignedTo;
		} else if (incidentAssignmentArray.includes(IncidentAssignmentFilterValues.Unassigned.key)) {
			// incidentAssignment = [Unassigned, Me | username]. Keep the (AssignedTo = current user) filter
			filters.incidentAssignment = IncidentAssignmentFilterValues.Unassigned.key;
		} else {
			// incidentAssignment = [Me | username]. The (AssignedTo = current user) filter takes care of it, so no need to pass incidentAssignment filter
			if(!filters.AssignedTo) {
				filters.AssignedTo = [withScrubbing ? "emailAddress" : filters.incidentAssignment];
			}
			else if (withScrubbing && filters.AssignedTo.length) {
				filters.AssignedTo = ["emailAddress"];
			}
			delete filters.incidentAssignment;
		}
	} else {
		// incidentAssignment doesn't contain "Me, no need to filter by AssignedTo
		delete filters.AssignedTo;
		if (incidentAssignmentLength !== 1) {
			// incidentAssignment = [Anyone, Unassigned]. Since (Anyone + Unassigned = All), there's no filter effect
			delete filters.incidentAssignment;
		}
	}
}

/**
 * Returns true if the assignment filter combo has any effect.
 * (for instance, unassigned + anyone = all incident, no filter effect).
 * @param filters the assignment filter selection
 */
 export const serializeIncidentAssignmentFilters = (filters: IncidentAssignmentFilters | string): boolean => {
	if ('string' === typeof filters) return;

	const { incidentAssignment } = filters;

	if (!incidentAssignment || !incidentAssignment.length || incidentAssignment.length === ALL_ASSIGNMENT_OPTIONS_COUNT) {
		// incidentAssignment = [] or [Anyone, Me, Unassigned]. There's no filter effect
		delete filters.AssignedTo;
		delete filters.incidentAssignment;
		return false;
	}

	if(incidentAssignment.includes(IncidentAssignmentFilterValues.Me.key)) {
		if(incidentAssignment.includes(IncidentAssignmentFilterValues.Anyone.key)) {
			// incidentAssignment = [Anyone, Me]. Since (Anyone + Me = Anyone), no need to filter by AssignedTo
			filters.incidentAssignment = IncidentAssignmentFilterValues.Anyone.key;
			delete filters.AssignedTo;
		} else if (incidentAssignment.includes(IncidentAssignmentFilterValues.Unassigned.key)){
			// incidentAssignment = [Unassigned, Me]. Keep the (AssignedTo = current user) filter
			filters.incidentAssignment = IncidentAssignmentFilterValues.Unassigned.key;
		}else{
			// incidentAssignment = [Me]. The (AssignedTo = current user) filter takes care of it, so no need to pass incidentAssignment filter
			delete filters.incidentAssignment;
		}
	} else {
		// incidentAssignment doesn't contain "Me, no need to filter by AssignedTo
		delete filters.AssignedTo;
		if (incidentAssignment.length !== 1) {
			// incidentAssignment = [Anyone, Unassigned]. Since (Anyone + Unassigned = All), there's no filter effect
			delete filters.incidentAssignment;
			return false;
		}
	}
	return true;
}
