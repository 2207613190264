/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "../pipes/safe-url.pipe";
import * as i5 from "../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i6 from "../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "../pipes/truncate.pipe";
import * as i9 from "@angular/common";
import * as i10 from "./external-image.component";
var styles_ExternalImageComponent = [];
var RenderType_ExternalImageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExternalImageComponent, data: {} });
export { RenderType_ExternalImageComponent as RenderType_ExternalImageComponent };
function View_ExternalImageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "img", [["class", "screenshot"]], [[8, "src", 4]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onImageError() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(3, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.src)); _ck(_v, 2, 0, currVal_0); }); }
function View_ExternalImageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "wcd-shared-icon", [["class", "large-icon pull-left"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(3, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 10, "div", [["class", "with-large-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵpod(10, { asset: 0 }), i0.ɵppd(11, 2), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "span", [], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(14, null, ["", ""])), i0.ɵppd(15, 2), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = "error"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 1), "help.externalLoadError", _ck(_v, 10, 0, _co.imageAsset))); _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.src; _ck(_v, 13, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i0.ɵnov(_v.parent, 2), _co.src, 50)); _ck(_v, 14, 0, currVal_3); }); }
export function View_ExternalImageComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.SafeUrlPipe, [i5.WicdSanitizerService]), i0.ɵpid(0, i6.I18nPipe, [i7.I18nService]), i0.ɵpid(0, i8.TruncatePipe, []), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 7, "div", [["class", "external-image"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExternalImageComponent_1)), i0.ɵdid(7, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExternalImageComponent_2)), i0.ɵdid(10, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isImageAvailable; _ck(_v, 7, 0, currVal_0); var currVal_1 = !_co.isImageAvailable; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_ExternalImageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "external-image", [], null, null, null, View_ExternalImageComponent_0, RenderType_ExternalImageComponent)), i0.ɵdid(1, 49152, null, 0, i10.ExternalImageComponent, [i7.I18nService], null, null)], null, null); }
var ExternalImageComponentNgFactory = i0.ɵccf("external-image", i10.ExternalImageComponent, View_ExternalImageComponent_Host_0, { src: "src" }, { error: "error" }, []);
export { ExternalImageComponentNgFactory as ExternalImageComponentNgFactory };
