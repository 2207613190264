import { Injectable } from '@angular/core';
import {
	EntityDataViewOptions,
	EntityType,
} from '../../../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../../../global_entities/models/entity-type-service.interface';
import { BaselineConfiguration } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { BaselineConfigurationFieldsService } from './baseline-configuration.fields.service';
import { ProfileConfigurationEntityPanelComponent } from '../components/profile-configuration.entity-panel.component';
import { BaselineConfigurationEntityDetailsComponent } from '../../../../../../global_entities/components/entity-details/baseline-configuration.entity-details.component';
import { BaselineComplianceStatusComponent } from '../../../components/baseline-configurations/baseline-device-compliance-status.component';

export interface ProfileConfigurationsDataViewFixedOptions { }

@Injectable()
export class ProfileConfigurationEntityTypeService
	implements EntityTypeService<BaselineConfiguration> {
	constructor() { }

	readonly entityType: EntityType<BaselineConfiguration> = {
		id: 'profile-configuration',
		entity: BaselineConfiguration,
		icon: FabricIconNames.PageList,
		entityDetailsComponentType: BaselineConfigurationEntityDetailsComponent,
		entityPanelStatusComponentType: BaselineComplianceStatusComponent,
		singleEntityPanelComponentType: ProfileConfigurationEntityPanelComponent,
		getEntityName: configuration => configuration.name,
		entityPluralNameKey: 'configuration_entityType_pluralName',
		entitySingularNameKey: 'configuration_entityType_singularName',
		getEntityDataviewLink: () => '/baseline-compliance_t/profiles/:id/configurations',
		loadFullEntityInPanel: false,
		dataViewOptions: <
			EntityDataViewOptions<BaselineConfiguration, ProfileConfigurationsDataViewFixedOptions>
			>{
				fields: BaselineConfigurationFieldsService,
			},
	};
}
