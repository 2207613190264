
		<header class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal">
			<div class="wcd-flex-1">
				<h3 class="side-panel-title" id="machine-resources-panel-header" #panelHeader>
					{{ 'machines.entityDetails.fields.resources.title' | i18n }}
				</h3>
			</div>
		</header>
		<div class="wcd-padding-medium-left">
			<wcd-datatable
				[items]="formattedResources"
				[columns]="tableFields"
				[selectEnabled]="false"
				[fieldWidths]="{
					name: resourcesTableFieldsWidth,
					formattedLastSeen: resourcesTableFieldsWidth,
					domainName: resourcesTableFieldsWidth
				}"
			>
			</wcd-datatable>
		</div>
	