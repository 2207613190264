export const incidentGraphRelationTypeValues = [
	{
		id: 'ObservedOn',
		name: 'Observed on',
		isDirectional: false,
	},
	{
		id: 'CommunicatedWith',
		name: 'Communicated with',
		icon: '\uE95A',
		iconName: 'communications',
	},
	{
		id: 'UrlReferrer',
		name: 'URL Referrer',
		icon: '\uE71B',
		iconName: 'link',
	},
	{
		id: 'FileDownload',
		name: 'File download',
		icon: '\uEBD3',
		iconName: 'cloudDownload',
	},
	{
		id: 'EmailAttachment',
		name: 'Email attachment',
		icon: '\uE723',
		iconName: 'attachment',
	},
	{
		id: 'OpenedUrl',
		name: 'Opened URL',
		icon: '\uE7C3',
		iconName: 'file',
	},
	{
		id: 'PrecededInChain',
		name: 'Preceded in chain',
	},
	{
		id: 'FileExtractedFromArchive',
		name: 'Extracted file',
		icon: '\uF012',
		iconName: 'zip',
	},
];
