import { ChangeDetectorRef } from '@angular/core';
import { isWcdIconName } from '@wcd/icons-font';
var WcdIconComponent = /** @class */ (function () {
    function WcdIconComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
    }
    Object.defineProperty(WcdIconComponent.prototype, "iconName", {
        get: function () {
            return this._iconName;
        },
        set: function (value) {
            if (!isWcdIconName(String(value)))
                throw new Error("Unknown WCD icon name, '" + String(value) + "'.");
            this._iconName = value;
        },
        enumerable: true,
        configurable: true
    });
    return WcdIconComponent;
}());
export { WcdIconComponent };
