var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
export var ThreatExposureType;
(function (ThreatExposureType) {
    ThreatExposureType["High"] = "High";
    ThreatExposureType["Medium"] = "Medium";
    ThreatExposureType["Low"] = "Low";
    ThreatExposureType["None"] = "None";
})(ThreatExposureType || (ThreatExposureType = {}));
export var threatExposureSeverityValues = [
    {
        id: 'High',
        name: 'high',
        className: 'redDark',
        type: ThreatExposureType.High,
    },
    {
        id: 'Medium',
        name: 'medium',
        className: 'red',
        type: ThreatExposureType.Medium,
    },
    {
        id: 'Low',
        name: 'low',
        className: 'orangeLight',
        type: ThreatExposureType.Low,
    },
    {
        id: 'None',
        name: 'None',
        type: ThreatExposureType.None,
    },
];
var ThreatExposureSeverity = /** @class */ (function (_super) {
    __extends(ThreatExposureSeverity, _super);
    function ThreatExposureSeverity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ThreatExposureSeverity.prototype, "type", void 0);
    ThreatExposureSeverity = __decorate([
        Entity({
            singularName: 'Threat exposure severity',
            pluralName: 'Threat exposure severity',
            values: threatExposureSeverityValues,
        })
    ], ThreatExposureSeverity);
    return ThreatExposureSeverity;
}(StatusModel));
export { ThreatExposureSeverity };
