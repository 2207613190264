var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, EventEmitter, OnDestroy, ViewContainerRef, } from '@angular/core';
import { isObservable, of } from 'rxjs';
import { isPromise } from 'rxjs/internal-compatibility';
import { take } from 'rxjs/operators';
import { SpinnerSize } from 'office-ui-fabric-react';
import { isHandlersObject } from './utils/is-handlers-object';
var ConfirmationDialogComponent = /** @class */ (function () {
    function ConfirmationDialogComponent(changeDetectorRef, componentFactoryResolver) {
        this.changeDetectorRef = changeDetectorRef;
        this.componentFactoryResolver = componentFactoryResolver;
        this.SpinnerSize = SpinnerSize;
        this.onClosed = new EventEmitter(false);
        this._submitInProgress = false;
        this._hidden = false;
    }
    Object.defineProperty(ConfirmationDialogComponent.prototype, "submitInProgress", {
        get: function () {
            return this._submitInProgress;
        },
        set: function (value) {
            this._submitInProgress = value;
            this.changeDetectorRef.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationDialogComponent.prototype, "submitDisabled", {
        get: function () {
            return this._submitDisabled;
        },
        set: function (value) {
            this._submitDisabled = value;
            this.changeDetectorRef.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationDialogComponent.prototype, "hidden", {
        /**
         * Gets the hidden state of the dialog.
         *
         * @internal
         * @description used for hiding the dialog as soon as possible in React,
         * until Angular destroys this component, to give the user a fluent experience.
         */
        get: function () {
            return this._hidden;
        },
        set: function (value) {
            this._hidden = value;
            this.changeDetectorRef.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationDialogComponent.prototype, "contentComponent", {
        get: function () {
            return this._currentContentComponentRef && this._currentContentComponentRef.instance;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationDialogComponent.prototype, "handlers", {
        get: function () {
            if (this.options.handlers) {
                return this.options.handlers;
            }
            if (isHandlersObject(this.contentComponent)) {
                return this.contentComponent;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    ConfirmationDialogComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.options.contentComponent) {
            this._currentContentComponentRef = this.container.createComponent(this.componentFactoryResolver.resolveComponentFactory(this.options.contentComponent.type));
            if (this.options.contentComponent.props) {
                Object.assign(this._currentContentComponentRef.instance, this.options.contentComponent.props);
            }
            var _a = this._currentContentComponentRef.instance.isValid$, isValid$ = _a === void 0 ? of(true) : _a;
            this._submitDisabledSubscription = isValid$.subscribe(function (isValid) {
                _this.submitDisabled = !isValid;
            });
            this.changeDetectorRef.markForCheck();
        }
    };
    ConfirmationDialogComponent.prototype.ngOnDestroy = function () {
        if (this._submitDisabledSubscription) {
            this._submitDisabledSubscription.unsubscribe();
        }
    };
    ConfirmationDialogComponent.prototype.hideCancelButton = function (textOrOptions) {
        if (textOrOptions == null || typeof textOrOptions === 'string') {
            return false;
        }
        return textOrOptions.hide;
    };
    ConfirmationDialogComponent.prototype.getText = function (textOrOptions) {
        if (textOrOptions == null) {
            return null;
        }
        return typeof textOrOptions === 'string' ? textOrOptions : textOrOptions.text;
    };
    ConfirmationDialogComponent.prototype.getClassName = function (textOrOptions) {
        if (textOrOptions == null || typeof textOrOptions !== 'object') {
            return null;
        }
        return textOrOptions.className;
    };
    ConfirmationDialogComponent.prototype.confirmAndClose = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var data, _a, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        event.preventDefault();
                        this.submitInProgress = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        _a = this.handlers;
                        if (!_a) return [3 /*break*/, 3];
                        return [4 /*yield*/, toPromise(this.handlers.onSubmit(this.contentComponent))];
                    case 2:
                        _a = (_b.sent());
                        _b.label = 3;
                    case 3:
                        data = _a;
                        this.hidden = true;
                        this.onClosed.emit({
                            confirmed: true,
                            data: data,
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _b.sent();
                        this.submitInProgress = false;
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ConfirmationDialogComponent.prototype.cancelAndClose = function () {
        this.hidden = true;
        this.onClosed.emit({
            confirmed: false,
        });
    };
    return ConfirmationDialogComponent;
}());
export { ConfirmationDialogComponent };
export function toPromise(value) {
    return isPromise(value)
        ? value
        : isObservable(value)
            ? value.pipe(take(1)).toPromise()
            : Promise.resolve(value);
}
