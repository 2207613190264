import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserStatusTypes, AadUserStatus } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'aad-user-status-display-name',
	template: `
		<div
			class="wcd-flex-items-center"
			*ngIf="userStatusType && AadUserStatus[userStatusType] as userStatusData"
		>
			<wcd-shared-icon
				class="icon small-icon wcd-margin-small-right"
				[iconName]="userStatusData.iconName"
				[iconColor]="userStatusData.iconColor"
			>
			</wcd-shared-icon>
			{{
				i18nService.get('aadUser_status_' + userStatusData.nameI18nKey, null, true) ||
					userStatusData.nameI18nKey
			}}
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AadUserStatusDisplayNameComponent {
	AadUserStatus = AadUserStatus;

	@Input() userStatusType: UserStatusTypes;

	constructor(public i18nService: I18nService) {}
}
