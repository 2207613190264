import { DataQuery, DataQuerySortDirection, Entity, EntityField } from '@microsoft/paris';
import { DetectionSource } from './sources/detection-source.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { MachineGroup } from '../rbac/machine-group.entity';
import { SuppressionRuleConditionIocType } from '../suppression-rule/suppression-rule-condition-ioc-type.enum';
import { SuppressionRuleIoc } from '../suppression-rule/suppression-rule-ioc.interface';
import { AlertGroupType } from './alert-group-type.entity';
import { AlertStatus } from './alert-status/alert-status.entity';
import { AlertBase } from './alert.entity.base';
import { AlertFileContext } from './contexts/alert-file-context.value-object';
import { AlertIPContext } from './contexts/alert-ip-context.value-object';
import { AlertUrlContext } from './contexts/alert-url-context.value-object';
import { IncidentLinkedByCategory } from './linked-by/incident-linked-by-category.model';
import { IncidentLinkedByEntityType } from './linked-by/incident-linked-by-entity.value-object';
import { IncidentLinkedBy } from './linked-by/incident-linked-by.value-object';
import { ImpactedEntities } from '../impacted-entities/incident.impacted.entities';
import { RelatedEvidenceCount } from './related-evidence-count/related-evidence-count.value-object';
import { ServiceSource } from './sources/service-source.entity';
import { AggregatedIncidentLinkedBy } from './linked-by/aggregated-incident-linked-by.value-object';
import { EntityTags } from '../tag/entity-tags.value-object';

export const THREAT_EXPERT_DETECTION_SOURCE_TYPE = 'ThreatExperts';
export const MCAS_SOURCE_TYPE = 'MCAS';
export const CUSTOM_DETECTION_SOURCE_TYPE = 'CustomDetection';
export const MTP_SOURCE_TYPE = 'MTP';

@Entity({
	singularName: 'Alert',
	pluralName: 'Alerts',
	endpoint: 'alerts',
	parseItemQuery: (itemId: string) => {
		return `alerts?id=${itemId}`;
	},
	parseDataQuery: (dataQuery: DataQuery) => {
		if (!dataQuery) return {};

		const pageSettings: { pageIndex?: number; pageSize?: number } = {};
		if (dataQuery.page) pageSettings.pageIndex = dataQuery.page;

		if (dataQuery.pageSize) pageSettings.pageSize = dataQuery.pageSize;

		return Object.assign(
			{},
			dataQuery.where,
			pageSettings,
			dataQuery.sortBy && dataQuery.sortBy.length
				? {
						sortByField: dataQuery.sortBy[0].field,
						sortOrder:
							dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
								? 'Ascending'
								: 'Descending',
				  }
				: undefined
		);
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class Alert extends AlertBase {
	@EntityField({
		data: 'AlertId',
		parse: (alertId: string, rawData) =>
			alertId || (rawData.AlertCount > 1 && rawData.GroupId && `group_${rawData.GroupId}`),
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'ParentAlertId' })
	parentId: string;

	@EntityField({ data: 'FormattedUxDescription' })
	description: string;

	@EntityField({ data: 'AdditionalUxDescription' })
	extendedDescription: string;

	@EntityField({ data: 'ActorName' })
	actor: string;

	@EntityField({ data: 'DetectionSource' })
	detectionSource: DetectionSource;

	@EntityField({ data: 'DetectionTech' })
	detectionTechnology: string;

	@EntityField({ data: 'BlockingStatus' })
	detectionStatus: string;

	@EntityField({ data: ['Product', 'ProductSource'] })
	serviceSource: ServiceSource;

	@EntityField({
		data: 'Status',
		parse: status => {
			if (!status) return status;

			const statusLog = Math.log2(status);
			return statusLog === Math.floor(statusLog) ? status : null;
		},
	})
	status: AlertStatus;

	@EntityField({ data: 'RecommendedAction' })
	recommendedAction: string;

	@EntityField({ data: 'ThreatIntelProvider' })
	threatInterProvider: string;

	@EntityField({ data: 'ThreatFamilyName' })
	threatFamilyName: string;

	@EntityField({
		data: 'MitreTechniques',
		parse: mitreTechniques =>
			mitreTechniques && mitreTechniques.split(';').length ? mitreTechniques.split(';') : null,
	})
	mitreTechniques: Array<string>;

	@EntityField({ data: 'LinkToEncyclopedia' })
	encyclopediaUrl: string;

	@EntityField({ data: 'SuppressionRuleId' })
	suppressionRuleId: number;

	@EntityField({ data: 'SuppressionRuleTitle' })
	suppressionRuleTitle: string;

	@EntityField({
		data: 'InvestigationId',
		require: data => {
			const intInvId = parseInt(data.InvestigationId);
			// currently office investigationId can be string
			return isNaN(intInvId) || intInvId > 0;
		},
	})
	investigationId: number | string;

	@EntityField({
		data: 'InvestigationId',
		require: data => {
			const intInvId = parseInt(data.InvestigationId);
			// currently office investigationId can be string
			return isNaN(intInvId);
		},
	})
	isOfficeInvestigation: boolean;

	@EntityField({ data: 'InvestigationCount', defaultValue: 0 })
	investigationCount: number;

	@EntityField({ data: 'GroupId' })
	groupId: number;

	@EntityField({ data: 'GroupHash' })
	groupHash?: string;

	@EntityField({ data: 'GroupKey' })
	groupedBy: AlertGroupType;

	@EntityField({ data: 'AlertCount' })
	groupedAlertsCount: number;

	@EntityField({ data: 'IoaDefinitionId' })
	ioaDefinitionId: string;

	@EntityField({ data: 'FileMonitorContextList', arrayOf: AlertFileContext })
	fileContexts: Array<AlertFileContext>;

	@EntityField({ data: 'IPMonitorContextList', arrayOf: AlertIPContext })
	ipContexts: Array<AlertIPContext>;

	@EntityField({ data: 'URLMonitorContextList', arrayOf: AlertUrlContext })
	urlContexts: Array<AlertUrlContext>;

	@EntityField({ data: 'ImpactedEntities' })
	impactedEntities?: ImpactedEntities;

	@EntityField({ data: 'IsReadOnly' })
	isReadOnly?: boolean;

	get availableIocs(): Array<SuppressionRuleIoc> {
		return []
			.concat(
				this.fileContexts
					.filter(context => context.name || context.path || context.sha1)
					.map(context => {
						return { type: SuppressionRuleConditionIocType.File, context: context };
					})
			)
			.concat(
				this.urlContexts
					.filter(context => context.name)
					.map(context => {
						return { type: SuppressionRuleConditionIocType.Url, context: context };
					})
			)
			.concat(
				this.ipContexts
					.filter(context => context.name)
					.map(context => {
						return { type: SuppressionRuleConditionIocType.Ip, context: context };
					})
			);
	}

	@EntityField({
		data: 'IncidentLinkedBy',
		arrayOf: IncidentLinkedBy,
		parse: (
			rawValue: IncidentLinkedByDeprecated | Array<IncidentLinkedByRaw>
		): Array<IncidentLinkedByRaw> => {
			// New format - needs no parsing
			if (typeof rawValue !== 'string') {
				return rawValue;
			}

			// Old format - "Automation"
			if (rawValue === 'Automation') {
				return [
					{
						Category: rawValue,
					},
				];
			}

			// Old format - User UPN
			return [
				{
					Category: 'User',
					TargetAlert: {
						Id: null,
						SourceEntity: {
							Type: 'User',
							Id: null,
							Name: rawValue,
						},
					},
				},
			];
		},
	})
	incidentLinkedBy: Array<IncidentLinkedBy>;

	@EntityField({
		data: 'AggregatedIncidentLinkedBy',
		arrayOf: AggregatedIncidentLinkedBy,
		required: false,
	})
	aggregatedIncidentLinkedBy?: Array<AggregatedIncidentLinkedBy>;

	@EntityField({ data: 'IncidentId' })
	incidentId: string;

	@EntityField({ data: 'IncidentTitle' })
	incidentName: string;

	@EntityField({ data: 'IncidentCount' })
	incidentCount: number;

	@EntityField({ data: 'RbacGroupId' })
	machineGroup?: MachineGroup;

	@EntityField({ data: 'RbacGroupCount' })
	machineGroupCount?: number;

	@EntityField({ data: 'RelatedEvidenceCount' })
	relatedEvidenceCount?: RelatedEvidenceCount;

	@EntityField({ data: 'AlertPageUrl' })
	alertPageExternalUrl?: string;

	@EntityField({ data: 'MachineTags' })
	systemTags?: string[];

	@EntityField({ data: ['AlertTags', 'AggregatedAlertTags'], required: false  })
	alertTags?: EntityTags;

	@EntityField({ data: 'IncidentLinkedByCount', required: false  })
	incidentLinkedByCount?: number;

	get isGroup(): boolean {
		return this.groupedAlertsCount > 1;
	}

	get isThreatExpertOriginated(): boolean {
		return this.detectionSource && this.detectionSource.type === THREAT_EXPERT_DETECTION_SOURCE_TYPE;
	}

	get isMcasOriginated(): boolean {
		return this.detectionSource && this.detectionSource.type === MCAS_SOURCE_TYPE;
	}

	get isCustomDetection(): boolean {
		return this.detectionSource && this.detectionSource.type === CUSTOM_DETECTION_SOURCE_TYPE;
	}

	constructor(data) {
		super(data);
	}
}

/**
 * Raw value returned by the server.
 * Should be removed together with `IncidentLinkedByDeprecated`, when we no longer need parsing.
 */
interface IncidentLinkedByEntityRaw {
	readonly Id: string;
	readonly Name: string;
	readonly Type: IncidentLinkedByEntityType;
}

/**
 * Raw value returned by the server.
 * Should be removed together with `IncidentLinkedByDeprecated`, when we no longer need parsing.
 */
interface IncidentLinkedByAlertRaw {
	readonly Id: string;
	readonly SourceEntity: IncidentLinkedByEntityRaw;
	readonly TargetEntity?: IncidentLinkedByEntityRaw;
}

/**
 * Raw value returned by the server.
 * Should be removed together with `IncidentLinkedByDeprecated`, when we no longer need parsing.
 */
interface IncidentLinkedByRaw {
	readonly Category: IncidentLinkedByCategory;
	readonly TargetAlert?: IncidentLinkedByAlertRaw;
}
/**
 * Deprecated type of the `IncidentLinkedBy` property.
 * TODO: Should be removed when the BE stops returning these types of values.
 */
export type IncidentLinkedByDeprecated = 'Automation' | /* User UPN (string) */ string;
