import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { SampleDownloadResponse } from '../file/file.sample-download-response.value-object';
import { SampleDownloadRequest } from '../file/file.sample-download-request.value-object';

@ApiCall({
	name: 'Generate download Uri',
	endpoint: 'sampledownloadrequest/generatedownloaduri',
	type: SampleDownloadResponse,
	method: 'GET',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
})
export class GenerateDownloadUriApiCall extends ApiCallModel<SampleDownloadResponse, SampleDownloadRequest> {}
