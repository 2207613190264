import { IStatus } from '../status.interface';

export enum AlertDeterminationType {
	'Apt' = 'Apt',
	'Malware' = 'Malware',
	'SecurityPersonnel' = 'SecurityPersonnel',
	'SecurityTesting' = 'SecurityTesting',
	'UnwantedSoftware' = 'UnwantedSoftware',
	'Other' = 'Other',
}

export enum combinedAlertDeterminationType {
	'Apt' = 'Apt',
	'Malware' = 'Malware',
	'SecurityPersonnel' = 'SecurityPersonnel',
	'SecurityTesting' = 'SecurityTesting',
	'UnwantedSoftware' = 'UnwantedSoftware',
	'Other' = 'Other',
	'MultiStagedAttack' = 'MultiStagedAttack',
	'CompromisedUser' = 'CompromisedUser',
	'Phishing' = 'Phishing',
	'MaliciousUserActivity' ='MaliciousUserActivity',
	'Clean' = 'Clean',
	'InsufficientData' = 'InsufficientData',
	'ConfirmedUserActivity' = 'ConfirmedUserActivity',
	'LineOfBusinessApplication' = 'LineOfBusinessApplication',
}

export const CombinedAlertDeterminationId = {
	[combinedAlertDeterminationType.Apt]: '10',
	[combinedAlertDeterminationType.Malware]: '20',
	[combinedAlertDeterminationType.SecurityPersonnel]: '30',
	[combinedAlertDeterminationType.SecurityTesting]: '40',
	[combinedAlertDeterminationType.UnwantedSoftware]: '50',
	[combinedAlertDeterminationType.Other]: '60',
	[combinedAlertDeterminationType.MultiStagedAttack]: '70',
	[combinedAlertDeterminationType.CompromisedUser]: '80',
	[combinedAlertDeterminationType.Phishing]: '90',
	[combinedAlertDeterminationType.MaliciousUserActivity]: '100',
	[combinedAlertDeterminationType.Clean]: '110',
	[combinedAlertDeterminationType.InsufficientData]: '120',
	[combinedAlertDeterminationType.ConfirmedUserActivity]: '130',
	[combinedAlertDeterminationType.LineOfBusinessApplication]: '140',
}

export const alertDeterminationValues: Array<IStatus> = [
	// id: 0 -> unset determination
	{ 	id: '10',
		name: 'APT',
		nameI18nKey: 'alertDetermination_apt',
		type: AlertDeterminationType.Apt
	},
	{
		id: '20',
		name: 'Malware',
		nameI18nKey: 'alertDetermination_malware',
		type: AlertDeterminationType.Malware,
	},
	{
		id: '30',
		name: 'Security personnel',
		nameI18nKey: 'alertDetermination_security_personnel',
		type: AlertDeterminationType.SecurityPersonnel,
	},
	{
		id: '40',
		name: 'Security testing',
		nameI18nKey: 'alertDetermination_security_testing',
		type: AlertDeterminationType.SecurityTesting,
	},
	{
		id: '50',
		name: 'Unwanted software',
		nameI18nKey: 'alertDetermination_unwanted_software',
		type: AlertDeterminationType.UnwantedSoftware,
	},
	{ id: '60', name: 'Other', nameI18nKey: 'alertDetermination_other', type: AlertDeterminationType.Other },
	{
		id: '60',
		name: 'Other',
		nameI18nKey: 'alertDetermination_other',
		type: AlertDeterminationType.Other,
		combinedClassification: true
	},
	{
		id: '70',
		name: 'Multi staged attack',
		nameI18nKey: 'alertDetermination_MultiStagedAttack',
		type: combinedAlertDeterminationType.MultiStagedAttack,
		combinedClassification: true
	},
	{
		id: '80',
		name: 'Compromised User',
		nameI18nKey: 'alertDetermination_CompromisedUser',
		type: combinedAlertDeterminationType.CompromisedUser,
		combinedClassification: true
	},
	{
		id: '90',
		name: 'Phishing',
		nameI18nKey: 'alertDetermination_Phishing',
		type: combinedAlertDeterminationType.Phishing,
		combinedClassification: true
	},
	{
		id: '100',
		name: 'Malicious user activity',
		nameI18nKey: 'alertDetermination_MaliciousUserActivity',
		type: combinedAlertDeterminationType.MaliciousUserActivity,
		combinedClassification: true
	},
	{
		id: '110',
		name: 'Clean',
		nameI18nKey: 'alertDetermination_Clean',
		type: combinedAlertDeterminationType.Clean,
		combinedClassification: true
	},
	{
		id: '120',
		name: 'Insufficient data',
		nameI18nKey: 'alertDetermination_InsufficientData',
		type: combinedAlertDeterminationType.InsufficientData,
		combinedClassification: true
	},
	{
		id: '130',
		name: 'Confirmed user activity',
		nameI18nKey: 'alertDetermination_ConfirmedUserActivity',
		type: combinedAlertDeterminationType.ConfirmedUserActivity,
		combinedClassification: true
	},
	{
		id: '140',
		name: 'Line of business application',
		nameI18nKey: 'alertDetermination_LineOfBusinessApplication',
		type: combinedAlertDeterminationType.LineOfBusinessApplication,
		combinedClassification: true
	},
];
