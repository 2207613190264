import {
	ChangeDetectorRef,
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	Type,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';
import { DynamicContentsDirective } from '../../shared/directives/dynamic-contents.directive';
import { ReportWidgetComponent } from './report-widget.component.base';
import { ReportWidgetModel } from '../models/report-widget.model';
import { DataError } from '../../shared/models/error.model';

import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { share, skip, tap } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';

let lastId = 0;

@Component({
	selector: 'report-widget',
	templateUrl: './report-widget.component.html',
	styleUrls: ['./report-widget.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ReportWidgetDynamicComponent implements OnInit, OnDestroy {
	@Input() widgetComponentType: Type<ReportWidgetComponent>;
	@Input() params: { [index: string]: any };
	@Input() dataOptions: {};
	@Input() headerLevel: number = 3;

	widget: ReportWidgetModel;
	widgetComponent: ReportWidgetComponent;
	data$: Observable<any>;
	error$: Observable<DataError>;
	loading$: Observable<boolean>;

	reportWidgetHeaderId = `report-widget-header-${lastId++}`;

	private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private widgetConfigSubscription: Subscription;
	private dataSubscription: Subscription;

	@ViewChild(DynamicContentsDirective, { static: true })
	private contentsDirective: DynamicContentsDirective;

	constructor(
		private i18nService: I18nService,
		private _componentFactoryResolver: ComponentFactoryResolver,
		private changeDetectorRef: ChangeDetectorRef,
		private elementRef: ElementRef
	) {}

	get widgetNoDataMessage() {
		if (!this.widget || !this.widget.noDataMessage) {
			return null;
		}
		const noData = this.widget.noDataMessage;
		return noData instanceof Function ? noData() : noData;
	}

	ngOnInit() {
		if (this.widgetComponentType) this.setComponent();
	}

	ngOnDestroy() {
		this.widgetConfigSubscription && this.widgetConfigSubscription.unsubscribe();
		this.dataSubscription && this.dataSubscription.unsubscribe();
	}

	setComponent() {
		const componentFactory: ComponentFactory<
			ReportWidgetComponent
		> = this._componentFactoryResolver.resolveComponentFactory(this.widgetComponentType);

		const viewContainerRef: ViewContainerRef = this.contentsDirective.viewContainerRef;
		viewContainerRef.clear();

		const componentRef: ComponentRef<ReportWidgetComponent> = viewContainerRef.createComponent(
			componentFactory
		);
		this.widgetComponent = <ReportWidgetComponent>componentRef.instance;
		Object.assign(this.widgetComponent, {
			params: this.params,
			dataOptions: this.dataOptions,
			ariaLabelledby: this.reportWidgetHeaderId,
			headerLevel: this.headerLevel
		});

		this.widgetComponent.setWidget();

		this.widgetConfigSubscription = this.widgetComponent.widgetConfig$.subscribe(() => {
			this.widgetComponent.setWidget();
			this.widget = this.widgetComponent.widget;
			this.widgetComponent.reload();
		});

		this.widget = this.widgetComponent.widget;
		this.data$ = this.widgetComponent.data$.asObservable().pipe(skip(1));
		this.loading$ = merge(this._loading$, this.widgetComponent.showLoader$).pipe(
			tap(isLoading => this.changeDetectorRef.detectChanges())
		);
		this.error$ = this.widgetComponent.error$.pipe(
			tap(error => {
				this._loading$.next(false);
				this.changeDetectorRef.detectChanges();
			}),
			share()
		);

		this._loading$.next(true);

		this.dataSubscription = this.data$.subscribe(
			data => {
				if (this.widgetComponent.widgetConfig.hideOnNoData && !data)
					this.elementRef.nativeElement.classList.add('hidden');
				else this.elementRef.nativeElement.classList.remove('hidden');

				this._loading$.next(false);
			},
			error => this._loading$.next(false)
		);
	}
}
