import { DataviewField, DataViewConfig } from '@wcd/dataview';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import { PanelContainer } from '@wcd/panels';
import {
	Input,
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ChangeDetectorRef,
	OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import {
	RecommendationExceptionAggregated,
	RecommendationException,
	RecommendationExceptionAggregatedRelationship,
} from '@wcd/domain';
import { RecommendationExceptionsFieldsService } from '../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exceptions.fields.service';
import { RecommendationExceptionService } from '../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exception.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'related-exceptions-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './related-exceptions.panel.component.html',
})
export class RelatedExceptionsPanelComponent extends PanelContainer implements OnInit, OnDestroy {
	private _subscription: Subscription;

	dataViewConfig: DataViewConfig;
	repository: RelationshipRepository<RecommendationExceptionAggregated, RecommendationException>;
	fields: DataviewField<RecommendationException>[];
	sortDisabledFieldsIds: string[];

	@Input() sourceItem: RecommendationExceptionAggregated;

	constructor(
		router: Router,
		recommendationExceptionsFieldsService: RecommendationExceptionsFieldsService,
		paris: Paris,
		changeDetectorRef: ChangeDetectorRef,
		private recommendationExceptionService: RecommendationExceptionService
	) {
		super(router);
		this.repository = paris.getRelationshipRepository(RecommendationExceptionAggregatedRelationship);
		this.dataViewConfig = {
			id: 'related-exceptions-panel-dataview',
		};
		recommendationExceptionsFieldsService.relatedExceptionsFields.subscribe(fields => {
			this.fields = fields;
			this.sortDisabledFieldsIds = fields.map(f => f.id);
			changeDetectorRef.markForCheck();
		});
		this._subscription = this.recommendationExceptionService.exceptionCancelled$.subscribe(_ =>
			this.destroy()
		);
	}

	ngOnInit() {
		this.settings.scrollBody = false;
		this.repository.sourceItem = this.sourceItem;
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	openRelatedExceptionPanel(recommendationException: RecommendationException) {
		this.recommendationExceptionService.openRelatedExceptionsPanel(recommendationException);
	}
}
