var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { AppSCCModule } from '../app/app.scc.module';
import { ProgressIndicator } from 'office-ui-fabric-react';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { sccHostService } from "@wcd/scc-interface";
import { panelService, reactTabsService } from '@wcd/shared';
import { AngularWrapperPanels } from './angular-wrapper-panels';
import { ReactPortalWrapper } from './react-portal-tab-wrapper';
var WcdAppReactWrapper = /** @class */ (function () {
    function WcdAppReactWrapper() {
        this.bootModuleRef = null;
        this.bootModuleRefPromise = null;
    }
    WcdAppReactWrapper.prototype.bootSCC = function () {
        var _this = this;
        if (!this.bootModuleRefPromise) {
            this.bootModuleRefPromise = platformBrowserDynamic().bootstrapModule(AppSCCModule, { preserveWhitespaces: true })
                .then(function (m) {
                _this.bootModuleRef = m;
                return m;
            })
                .catch(function (err) { return _this.destroyComponent(); });
        }
        return this.bootModuleRefPromise;
    };
    WcdAppReactWrapper.prototype.destroyComponent = function () {
        if (this.bootModuleRef) {
            this.bootModuleRef.destroy();
            this.bootModuleRef = null;
        }
        this.bootModuleRefPromise = null;
    };
    return WcdAppReactWrapper;
}());
var wcdAppReactWrapper = new WcdAppReactWrapper();
var AngularWrapper = /** @class */ (function (_super) {
    __extends(AngularWrapper, _super);
    function AngularWrapper(props) {
        var _this = _super.call(this, props) || this;
        _this.element = React.createRef();
        _this.state = {
            loading: true,
            panels: [],
            tab: null
        };
        panelService.setOnPanelsChange(function (panels) { return _this.setState({ panels: panels }); });
        reactTabsService.setOnTabChange(function (tab, onTabStateChange) { return _this.setState({ tab: tab }, function () { onTabStateChange(); }); });
        return _this;
    }
    AngularWrapper.prototype.render = function () {
        return (React.createElement("div", { ref: this.element, style: this.props.style, className: this.props.className },
            this.state.loading &&
                React.createElement("div", null,
                    React.createElement(ProgressIndicator, { label: "Initializing page..." })),
            //@ts-ignore
            React.createElement("wcd-root-app", null),
            React.createElement(AngularWrapperPanels, { panels: this.state.panels }),
            this.state.tab ? React.createElement(ReactPortalWrapper, __assign({}, this.state.tab)) : null));
    };
    AngularWrapper.prototype.componentDidMount = function () {
        var _this = this;
        var perf = sccHostService.perf.createPerformanceSession("ng-app-bootstrap@" + sccHostService.state.current.name, 'ng-app-bootstrap', {
            isContainer: true,
            useParentEndAsStart: true,
        });
        wcdAppReactWrapper.bootSCC()
            .then(function () {
            _this.hideLoader();
            sccHostService.perf.endPerfSession(perf);
        })
            .catch(function () {
            _this.hideLoader();
        });
    };
    AngularWrapper.prototype.componentWillUnmount = function () {
        wcdAppReactWrapper.destroyComponent();
    };
    AngularWrapper.prototype.hideLoader = function () {
        this.setState({ loading: false });
    };
    return AngularWrapper;
}(React.Component));
export default function appBootstrapScc() {
    // Set webpack path to storage for lazy loading
    __webpack_public_path__ = sccHostService.getPackageBasePathByPkg() + '/';
    return function (props) { return React.createElement(AngularWrapper, __assign({}, props)); };
}
