export const TreeElementCyberEntityTypeEnum = {
	machine: 'machine',
	alert: 'alert',
	process: 'process',
	ip: 'ip',
	file: 'file',
	user: 'user',
	registry: 'registry',
	script: 'script',
	url: 'url',
	domain: 'domain',
	defender: 'defender',
	other: 'other',
};
