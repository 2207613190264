<dl class="key-values" role="none">
	<dt>{{'mailboxes.entityDetails.details.displayName' | i18n}}</dt>
	<dd>{{displayName}}</dd>
	<ng-container *ngIf="showLink && mailboxPageLink">
		<dt>{{'mailboxes.entityDetails.details.OfficeATPLink' | i18n}}</dt>
		<dd><a
			href="{{mailboxPageLink}}"
			target="{{shouldLinkTabOut? '_blank' : null}}"
		>{{mailboxPageLink}}

			<wcd-shared-icon *ngIf="shouldLinkTabOut"
				iconName="popOut"
				class="field-value-icon field-additional-icon"
			></wcd-shared-icon>
		</a></dd>
	</ng-container>
</dl>
