import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'File verdict',
	pluralName: 'File verdicts',
	readonly: true,
})
export class FileVerdict extends ModelBase {
	@EntityField({ data: 'Sources' })
	readonly sources: Array<number>;

	@EntityField({ data: 'ThreatName' })
	readonly threatName: string;

	@EntityField({ data: 'RelatedAlertsCount' })
	readonly relatedAlertsCount: number;
}
