import { Injectable } from '@angular/core';
import { RemediationTask, RemediationType, RemediationTaskStateValue } from '@wcd/domain';
import { TvmDownloadService } from '../../../../../tvm/services/tvm-download.service';
import { of, Observable } from 'rxjs';
import { I18nService } from '../../../../../../../../../projects/i18n/src/public_api';
import { FeaturesService } from '@wcd/config';
import _ from 'lodash';

@Injectable()
export class RemediationTaskService {
	private _remediationTaskFilters: Record<string, any>;
	private remediationTypesToIgnoreInFilter: RemediationType[];

	constructor(
		private tvmDownloadService: TvmDownloadService,
		private i18nService: I18nService,
		private featuresService: FeaturesService
	) {
		// TODO: update those values when supporting remediation of those types. VSTS 23421057
		this.remediationTypesToIgnoreInFilter = []
	}

	downloadRemediationTaskCsv(remediationTask: RemediationTask): Promise<void> {
		if (!remediationTask) {
			throw new Error(
				'Error in RemediationTaskService[downloadRemediationTaskCsv] - remediation activity cant be undefined'
			);
		}
		return this.tvmDownloadService.downloadCsv({
			url: `remediation-tasks/remediationTasks/${remediationTask.id}/remediation-task-details.csv`,
			fileName: remediationTask.name,
			dataQuery: null,
		});
	}

	getRemediationTaskFilters(): Observable<Record<string, any>> {
		if (!this._remediationTaskFilters) {
			this._remediationTaskFilters = {
				remediationType: {
					count: null,
					values: Object.keys(RemediationType)
						.filter(
							type => this.remediationTypesToIgnoreInFilter.indexOf(RemediationType[type]) < 0
						)
						.map(type => {
							return {
								value: type,
								name: this.i18nService.get(
									`tvm.remediationTask.type.${RemediationType[type]}`
								),
								count: null,
							};
						}),
				},
				status: {
					count: null,
					values: Object.keys(RemediationTaskStateValue).map((type: string) => {
						return {
							value: type,
							name: this.i18nService.get(
								`tvm.remediationTask.status.${RemediationTaskStateValue[type]}`
							),
							count: null,
						};
					}),
				},
			};
		}

		this._remediationTaskFilters = _.omitBy(this._remediationTaskFilters, _.isNull);
		return of(
			Object.keys(this._remediationTaskFilters).reduce(
				(res, key) => Object.assign(res, { [key]: this._remediationTaskFilters[key] }),
				{}
			)
		);
	}
}
