/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, Component } from '@angular/core';
import { ServiceUrlsService } from '@wcd/app-config';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { InvestigationAction, Machine } from '@wcd/domain';
import { NavItemModel } from '@wcd/shared';
import { InvestigationActionsService } from '../services/investigation-actions.service';
import { DownloadService } from '../../../shared/services/download.service';
import { ErrorsDialogService } from '../../../dialogs/services/errors-dialog.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { CommentModel } from '../../../comments/models/comment.model';

@Component({
	selector: 'investigation-action-entity-panel',
	templateUrl: './investigation-action.entity-panel.component.html',
})
export class InvestigationActionEntityPanelComponent extends EntityPanelComponentBase<InvestigationAction> {
	get investigationAction(): InvestigationAction {
		return this.entity;
	}

	constructor(
		private investigationActionsService: InvestigationActionsService,
		private downloadService: DownloadService,
		private errorsDialogService: ErrorsDialogService,
		private serviceUrlsService: ServiceUrlsService,
		private globalEntityTypesService: GlobalEntityTypesService,
		public i18nService: I18nService,
		changeDetectorRef: ChangeDetectorRef
	) {
		super(changeDetectorRef);
	}

	actionDetails: string;
	Machine = Machine;
	machine: Machine;

	protected initEntity(entity: InvestigationAction, isExtendedData: boolean = false): void {
		super.initEntity(entity, isExtendedData);
		this.actionDetails =
			(this.investigationAction.i18nNameKey &&
				this.i18nService.get(
					`investigationAction_field_details_${this.investigationAction.i18nNameKey}_description`,
					null,
					true
				)) ||
			this.investigationAction.details;

		this.machine = null;

		if (this.investigationAction.machine) {
			const machineId = this.investigationAction.machine.machineId;

			this.machine = new Machine({
				id: machineId,
				senseMachineId: machineId,
				name: this.investigationAction.machine.name,
			});
		}
	}

	openInput() {
		this.openActionIO(ActionIoType.Input);
	}

	openOutput() {
		this.openActionIO(ActionIoType.Output);
	}

	private openActionIO(type: ActionIoType) {
		const url: string = `${this.serviceUrlsService.automatedIr}/investigationaction/${
			this.investigationAction.id
		}/${type}.json`;
		this.downloadService.dumpJsonFromUrlToNewTab(url).subscribe(
			() => {},
			err =>
				this.errorsDialogService.showError({
					title: this.i18nService.get(`investigation_error_get_action_${type}`),
					message: err.message || err,
					data: err,
				})
		);
	}

	getComments(): Array<CommentModel> {
		return this.investigationAction.commentData
			? [
					new CommentModel({
						id: undefined,
						message: this.investigationAction.commentData.approverComment,
						timestamp: this.investigationAction.commentData.actionApprovedTime,
						user: this.investigationAction.commentData.approvedBy,
					}),
			  ]
			: null;
	}
}

enum ActionIoType {
	Input = 'input',
	Output = 'output',
}
