/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tz-date.component";
import * as i2 from "../../../../../../projects/localization/src/lib/services/tz-date.service";
var styles_TzDateComponent = [];
var RenderType_TzDateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TzDateComponent, data: {} });
export { RenderType_TzDateComponent as RenderType_TzDateComponent };
export function View_TzDateComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(0, null, ["\n\t\t", "\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDateFormat(); _ck(_v, 0, 0, currVal_0); }); }
export function View_TzDateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tz-date", [], null, null, null, View_TzDateComponent_0, RenderType_TzDateComponent)), i0.ɵdid(1, 49152, null, 0, i1.TzDateComponent, [i2.TzDateService], null, null)], null, null); }
var TzDateComponentNgFactory = i0.ɵccf("tz-date", i1.TzDateComponent, View_TzDateComponent_Host_0, { date: "date", dateFormat: "dateFormat" }, {}, []);
export { TzDateComponentNgFactory as TzDateComponentNgFactory };
