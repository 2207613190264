<div
	 class="wcd-dropdown"
	 [class.wcd-dropdown__bordered]="isBordered"
	 [class.wcd-dropdown__open]="isOpen"
	 [class.wcd-full-width]="isFullWidth">
	<label *ngIf="ariaLabel" hidden [attr.id]="buttonId + '_ariaLabel'">{{ ariaLabel }}:</label>
	<button
			type="button"
			#dropdownButton
			[attr.id]="buttonId"
			(click)="toggle()"
			(keydown.alt.arrowDown)="toggle()"
			[wcdTooltip]="buttonTooltip"
			class="wcd-dropdown--button"
			[class.wcd-dropdown--button--arrow]="!buttonIcon || showIconDropdown"
			[ngClass]="buttonClass"
			[class.disabled]="disabled"
			(focus)="focus.emit()"
			[autofocus]="!isOpen || focusOnInit"
			(blur)="blur.emit()"
			[disabled]="disabled"
			[attr.aria-expanded]="isOpen && !isCombobox"
			[attr.aria-labelledby]="
					(buttonText || ariaLabel)
						? (ariaLabel ? buttonId + '_ariaLabel ' : '') +
						  (buttonText ? buttonId + '_buttonText ' : '')
						: null
				"
			[attr.role]="ariaRole"
			[attr.aria-haspopup]="ariaHaspopup"
			[attr.aria-owns]="ariaOwns"
			[attr.required]="required">
		<img class="wcd-dropdown--button--image" *ngIf="buttonImage" [attr.src]="buttonImage" />
		<wcd-shared-icon
						 [iconName]="buttonIcon"
						 *ngIf="buttonIcon"
						 [ngClass]="'wcd-dropdown--button--icon ' + buttonIconClass"
						 [attr.aria-hidden]="!iconButtonOnly"
						 [ariaLabel]="iconButtonOnly ? buttonText : null"
						 [attr.role]="iconButtonOnly ? optionAriaRole : null"
						 ></wcd-shared-icon>
		<span
			  class="wcd-dropdown--label"
			  [attr.id]="buttonId + '_buttonText'"
			  *ngIf="buttonText && !iconButtonOnly"
			  [attr.aria-setsize]="isCombobox ? null : optionAriaSetSize"
			  [attr.aria-posinset]="isCombobox ? null : optionAriaPositionInSet || null"
			  [attr.role]="isCombobox ? 'textbox' : optionAriaRole"
			  [attr.aria-hidden]="isCombobox"
			  [attr.aria-label]="isCombobox ? buttonText : null">
			{{ buttonText }}
		</span>
		<wcd-shared-icon
						 class="wcd-dropdown--button--chevron"
						 *ngIf="showIconDropdown"
						 [iconName]="position === positions.Default? 'ChevronDown' : 'ChevronRight'"
						 aria-hidden="true"
						 [styles]="chevronIconStyle"></wcd-shared-icon>
		<ng-content select="[button-content]"></ng-content>
	</button>
	<section
		 class="wcd-dropdown--menu"
		 #menu
		 [class.wcd-dropdown--menu__open]="isOpen"
		 [class.wcd-dropdown--menu__visible]="isVisible"
		 [ngClass]="menuClass"
		 (keydown.arrowUp)="onKeydownUpDown($event, true)"
		 (keydown.arrowDown)="onKeydownUpDown($event)"
		 (keydown.enter)="onKeydownEnter($event)"
		 (keydown.escape)="onKeydownEsc($event)"
		 (keydown.tab)="onKeydownTab($event)"
		 (keydown.shift.tab)="onKeydownTab($event)"
		 [attr.tabindex]="allowFocusOnMenu"
		 [attr.aria-label]="(buttonText || ariaLabel) + ' ' + ('common_button_expanded' | i18n)"
		 [cdkTrapFocus]="isOpen">
		<ng-content></ng-content>
	</section>
</div>
