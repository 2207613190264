/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gauge.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./gauge.component";
var styles_GaugeComponent = [i0.styles];
var RenderType_GaugeComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GaugeComponent, data: {} });
export { RenderType_GaugeComponent as RenderType_GaugeComponent };
export function View_GaugeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "wcd-gauge"]], null, null, null, null, null))], null, null); }
export function View_GaugeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-gauge", [], null, null, null, View_GaugeComponent_0, RenderType_GaugeComponent)), i1.ɵdid(1, 4898816, null, 0, i2.GaugeComponent, [i1.ElementRef], null, null)], null, null); }
var GaugeComponentNgFactory = i1.ɵccf("wcd-gauge", i2.GaugeComponent, View_GaugeComponent_Host_0, { config: "config", percent: "percent", forceReRender$: "forceReRender$" }, {}, []);
export { GaugeComponentNgFactory as GaugeComponentNgFactory };
