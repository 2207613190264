/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../dialogs/modals/components/modal.component.ngfactory";
import * as i2 from "../../../dialogs/modals/components/modal.component";
import * as i3 from "../../../dialogs/confirm/confirm-modal-content.component.ngfactory";
import * as i4 from "../../../dialogs/confirm/confirm-modal-content.component";
import * as i5 from "./remediation-decline-reason.component.ngfactory";
import * as i6 from "./remediation-decline-reason.component";
import * as i7 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "@angular/forms";
import * as i9 from "./remediation-decline.modal";
import * as i10 from "@angular/router";
var styles_RemediationDeclineModalComponent = [];
var RenderType_RemediationDeclineModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RemediationDeclineModalComponent, data: {} });
export { RenderType_RemediationDeclineModalComponent as RenderType_RemediationDeclineModalComponent };
export function View_RemediationDeclineModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 18, "modal", [], null, [[null, "close"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.onClose() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ModalComponent_0, i1.RenderType_ModalComponent)), i0.ɵdid(2, 180224, null, 0, i2.ModalComponent, [i0.ElementRef, i0.ChangeDetectorRef], { settings: [0, "settings"] }, { close: "close" }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, 0, 14, "confirm-modal-content", [["class", "wcd-flex-1 ie11Patch ie11Flex"]], null, [[null, "onCancel"], [null, "onConfirm"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onCancel" === en)) {
        var pd_0 = (_co.onCancelHandler() !== false);
        ad = (pd_0 && ad);
    } if (("onConfirm" === en)) {
        var pd_1 = (_co.onConfirmHandler() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ConfirmModalContentComponent_0, i3.RenderType_ConfirmModalContentComponent)), i0.ɵdid(5, 49152, null, 0, i4.ConfirmModalContentComponent, [], { confirmButtonOptions: [0, "confirmButtonOptions"], isSaving: [1, "isSaving"] }, { onConfirm: "onConfirm", onCancel: "onCancel" }), i0.ɵpod(6, { disabled: 0 }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, 0, 9, "div", [["style", "min-width: 400px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(10, 0, null, null, 6, "remediation-decline-reason", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.declineReason = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_RemediationDeclineReasonComponent_0, i5.RenderType_RemediationDeclineReasonComponent)), i0.ɵdid(11, 49152, null, 0, i6.RemediationDeclineReasonComponent, [i7.I18nService], null, null), i0.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.RemediationDeclineReasonComponent]), i0.ɵdid(13, 671744, null, 0, i8.NgModel, [[8, null], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i0.ɵdid(15, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, (_co.isSaving || !_co.declineReason)); var currVal_2 = _co.isSaving; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_10 = _co.declineReason; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var currVal_3 = i0.ɵnov(_v, 15).ngClassUntouched; var currVal_4 = i0.ɵnov(_v, 15).ngClassTouched; var currVal_5 = i0.ɵnov(_v, 15).ngClassPristine; var currVal_6 = i0.ɵnov(_v, 15).ngClassDirty; var currVal_7 = i0.ɵnov(_v, 15).ngClassValid; var currVal_8 = i0.ɵnov(_v, 15).ngClassInvalid; var currVal_9 = i0.ɵnov(_v, 15).ngClassPending; _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_RemediationDeclineModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "remediation-decline-modal", [], null, null, null, View_RemediationDeclineModalComponent_0, RenderType_RemediationDeclineModalComponent)), i0.ɵdid(1, 245760, null, 0, i9.RemediationDeclineModalComponent, [i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RemediationDeclineModalComponentNgFactory = i0.ɵccf("remediation-decline-modal", i9.RemediationDeclineModalComponent, View_RemediationDeclineModalComponent_Host_0, { settings: "settings", title: "title" }, { onConfirm: "onConfirm", onCancel: "onCancel" }, []);
export { RemediationDeclineModalComponentNgFactory as RemediationDeclineModalComponentNgFactory };
