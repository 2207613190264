var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Observable } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { NetworkFiltersFieldsApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { map } from 'rxjs/operators';
var NetworkDevicesService = /** @class */ (function () {
    function NetworkDevicesService(i18nService, paris) {
        this.i18nService = i18nService;
        this.paris = paris;
        if (!this._networkDevicesFilter) {
            this._networkDevicesFilter = {
                status: {
                    values: [
                        {
                            value: 'Active',
                            name: this.i18nService.get('tvm_network_devices_filters_active'),
                        },
                        {
                            value: 'Inactive',
                            name: this.i18nService.get('tvm_network_devices_filters_not_active'),
                        },
                    ],
                },
                exposureLevel: {
                    values: [
                        {
                            value: 'High',
                            name: this.i18nService.get('tvm_network_devices_filters_high'),
                            priority: 1,
                        },
                        {
                            value: 'Medium',
                            name: this.i18nService.get('tvm_network_devices_filters_medium'),
                            priority: 2,
                        },
                        {
                            value: 'Low',
                            name: this.i18nService.get('tvm_network_devices_filters_low'),
                            priority: 3,
                        },
                    ],
                },
            };
        }
    }
    NetworkDevicesService.prototype.getFiltersData = function () {
        var _this = this;
        return this.paris
            .apiCall(NetworkFiltersFieldsApiCall)
            .pipe(map(function (filtersFields) {
            var dynamicFilters = {};
            filtersFields.forEach(function (filterField) {
                dynamicFilters[filterField.name] = {
                    values: Object.values(filterField.filters).map(function (filter) {
                        return {
                            value: filter.name,
                            name: filter.name,
                        };
                    }),
                };
            });
            return __assign({}, dynamicFilters, _this._networkDevicesFilter);
        }));
    };
    return NetworkDevicesService;
}());
export { NetworkDevicesService };
