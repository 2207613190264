import { AirsNotificationRuleTypeModel } from '../models/airs-notification-rule-type.model';
import { Dictionary } from '@wcd/config';
var ɵ0 = function (notificationRuleTypeConfig) { return new AirsNotificationRuleTypeModel(notificationRuleTypeConfig); };
var NOTIFICATION_RULE_TYPES = [
    { id: 0, name: 'Investigation Notifications', allowConditions: true },
    { id: 1, name: 'AIRS Notifications', allowConditions: false },
    { id: 2, name: 'Reports', allowConditions: false, requireSchedule: true },
].map(ɵ0);
var NOTIFICATION_RULE_TYPES_DICTIONARY = Dictionary.fromList(NOTIFICATION_RULE_TYPES, 'id');
var AirsNotificationRuleTypesService = /** @class */ (function () {
    function AirsNotificationRuleTypesService() {
    }
    Object.defineProperty(AirsNotificationRuleTypesService, "allTypes", {
        get: function () {
            return NOTIFICATION_RULE_TYPES;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsNotificationRuleTypesService, "default", {
        get: function () {
            return NOTIFICATION_RULE_TYPES[0];
        },
        enumerable: true,
        configurable: true
    });
    AirsNotificationRuleTypesService.getNotificationRuleTypeById = function (id) {
        return NOTIFICATION_RULE_TYPES_DICTIONARY.get(id);
    };
    Object.defineProperty(AirsNotificationRuleTypesService, "reportsNotificationRuleType", {
        get: function () {
            return NOTIFICATION_RULE_TYPES[2];
        },
        enumerable: true,
        configurable: true
    });
    return AirsNotificationRuleTypesService;
}());
export { AirsNotificationRuleTypesService };
export { ɵ0 };
