import {
	ChangeDetectorRef,
	Component, EventEmitter, Input, Output,
} from '@angular/core';
import { AssignedUsersApiCall,
} from '@wcd/domain';
import { IPersonaProps, SpinnerSize } from 'office-ui-fabric-react';
import * as _ from 'lodash-es';
import { Paris } from '@microsoft/paris';
import { IBasePickerSuggestionsOptions } from '@angular-react/fabric';
import { PreferencesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { AuthService } from '@wcd/auth';

const PREFERRED_ASSIGNEES = 'userPreferredAssignees';
const USER_PREFERRED_ASSIGNEES_LIMIT = 10;

@Component({
	selector: 'assignee-picker',
	template: `
		<div [class.assignee-selected]="!!selectedAssignees.length">
			<fab-people-picker
				[resolveDelay]="peoplePickerResolveDelay"
				[inputProps]="assignInputProps"
				[pickerSuggestionsOptions]="assigneesSuggestionsOptions"
				[selectedItems]="selectedAssignees"
				(onChange)="updateSelectedAssignees($event)"
				[resolveSuggestions]="peoplePickerInputChanged.bind(this)"
				className="people-picker"
				[renderItem]="peoplePickerItemRender"
				[emptyInputFocus]="onInputFocus"
				[disabled]="!!selectedAssignees.length || isDisabled"
			>
			</fab-people-picker>
		</div>

		<ng-template #peoplePickerItemRender let-item="item">
			<div class="wcd-flex">
				<span class="wcd-padding-small-left">{{item.secondaryText == currentUserName ? item.secondaryText : item.text }}</span>
				<div *ngIf="isLoading; else loaded"  class="wcd-padding-small-left">
					<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
				</div>
				<ng-template #loaded>
					<fab-action-button [disabled]="isDisabled" (click)="clearSelectedAssignees()" [iconProps]="iconProps" [styles]="buttonStyles">
					</fab-action-button>
				</ng-template>
			</div>
		</ng-template>
	`,
	styles: [
		`
			.assignee-selected ::ng-deep input {
					width: 0;
				}
		`
	],
})

export class AssigneePickerComponent {
	@Output() onSelectAssignee = new EventEmitter();
	@Input() isDisabled?: boolean = false;
	@Input() set assignedTo(value) {
		if(value && value.secondaryText) {
			if(this.getSelectedAssignees().secondaryText === value.secondaryText) {
				this._assignedTo = this.getSelectedAssignees()
			}
			else {
				this._assignedTo = value;
			}
			if (value && value.secondaryText === 'multipleAssignees') {
				this._assignedTo.secondaryText = this.i18nService.get('incident_commandBar_manageIncident_multipleAssignees');
			}
		}
		this.isLoading = false;
	}

	_assignedTo: IPersonaProps;
	selectedAssignees: IPersonaProps[] = [];
	pickerSuggestions: IPersonaProps[];
	assignInputProps = {placeholder: this.i18nService.get('assigned_unassigned')}
	assigneesSuggestionsOptions: IBasePickerSuggestionsOptions = {noResultsFoundText: this.i18nService.get('error_page_no_result_found_title'), loadingText: this.i18nService.get('loading')} as IBasePickerSuggestionsOptions
	initialList: Promise<any[]>;
	userPreferredList = [];
	isLoading = true;
	currentUserName = this.authService.currentUser.username;
	readonly buttonStyles = {root: {height: 'auto'}, icon: {'font-size': '10px'}};
	readonly iconProps = {iconName:'Clear'};
	readonly peoplePickerResolveDelay = 200;
	readonly SpinnerSize = SpinnerSize;
	constructor( private paris: Paris,
				 private changeDetectorRef: ChangeDetectorRef,
				 private preferencesService: PreferencesService,
				 private i18nService: I18nService,
				 private authService: AuthService,) {}

	ngOnInit(){
		this.userPreferredList = this.preferencesService.getPreference('preferredAssignees') || [];
		this.setAssignedTo();
		this.initialList = this.getInitialList();
	}

	ngOnChanges(){
		this.setAssignedTo();
		this.assignInputProps = this.selectedAssignees.length ?
			{placeholder: ''} : this.assignInputProps = {placeholder: this.isLoading ? this.i18nService.get('common_loading') : this.i18nService.get('assigned_unassigned')};
	}

	getInitialList() {
		let initialList = this.userPreferredList;
		const currentUser = {
			text: this.i18nService.get('incident_commandBar_manageIncident_assignTo_me'),
			secondaryText: this.currentUserName,
		};
		const currentUserIndex = this.userPreferredList.findIndex(item => item.secondaryText === currentUser.secondaryText);
		this.userPreferredList.splice(currentUserIndex, 1);
		this.userPreferredList = [currentUser].concat(initialList);

		return this.paris.apiCall(AssignedUsersApiCall, '').toPromise().then(data => {
			initialList = _.unionBy(this.userPreferredList, data, 'secondaryText');
			return initialList;
		});
	}

	setAssignedTo(){
		if(!this._assignedTo) return;
		this._assignedTo = this.userPreferredList.find(user => user.secondaryText === (this._assignedTo && this._assignedTo.secondaryText)) || this._assignedTo;
		if(!this._assignedTo.text){
			this._assignedTo.text = this._assignedTo.secondaryText;
		}
		this.selectedAssignees = [this._assignedTo];
		this.assignInputProps = {placeholder: ''};
		if(this._assignedTo.secondaryText === 'multipleAssignees') {
			this._assignedTo.secondaryText = this.i18nService.get('incident_commandBar_manageIncident_multipleAssignees');
		}
	}

	async getUsersToAssign(searchTerm?: string){
		const searchResultList = await this.paris.apiCall(AssignedUsersApiCall, searchTerm).toPromise();
		const filteredInitialList = this.filterPreferredList(searchTerm);
		const mergedLists = filteredInitialList.concat(searchResultList);
		return _.unionBy(filteredInitialList, mergedLists, 'secondaryText');
	}

	filterPreferredList(searchTerm: string) {
		if(!searchTerm) return [];
		return this.userPreferredList.filter(item => item && item.text && item.text.toLowerCase().startsWith(searchTerm.toLowerCase()));
	}

	peoplePickerInputChanged(searchTerm: string){
		return this.getUsersToAssign(searchTerm);
	}

	updateSelectedAssignees(items?: { items?: IPersonaProps[] }){
		this.selectedAssignees = items.items;
		this.assignInputProps = this.selectedAssignees.length ?
			{placeholder: ''} : this.assignInputProps = {placeholder: this.isLoading ? 'Loading...' : this.i18nService.get('assigned_unassigned')};
		this.changeDetectorRef.markForCheck();
		this.onSelectAssignee.emit(this.getSelectedAssignees());
	}

	getSelectedAssignees(){
		return this.selectedAssignees[0] || {secondaryText: ''};
	}

	clearSelectedAssignees(){
		this._assignedTo = null;
		this.updateSelectedAssignees({items:[]})
	}

	onInputFocus = () => this.initialList

	static updateUserPreferredAssignees = (assignee: IPersonaProps, preferencesService: PreferencesService) => {
		if(!assignee || !assignee.secondaryText) return;
		let userPreferredAssignees = preferencesService.getPreference(PREFERRED_ASSIGNEES);
		if (userPreferredAssignees && Array.isArray(userPreferredAssignees)) {
			userPreferredAssignees = userPreferredAssignees.filter(item => item.secondaryText !== assignee.secondaryText)
			userPreferredAssignees.unshift(assignee);
			userPreferredAssignees.length > USER_PREFERRED_ASSIGNEES_LIMIT && userPreferredAssignees.splice(-1, 1);
		}
		else {
			userPreferredAssignees = [assignee];
		}
		preferencesService.setPreference('preferredAssignees', userPreferredAssignees);
	}
}
