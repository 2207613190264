import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { investigationStatusValues } from './investigation-status.values';

@Entity({
	singularName: 'Investigation Status',
	pluralName: 'Investigation Statuses',
	values: investigationStatusValues,
})
export class InvestigationStatus extends StatusModel<number> {
	@EntityField({ defaultValue: false })
	isPending: boolean;
	@EntityField({ defaultValue: false })
	isError: boolean;
	@EntityField({ defaultValue: false })
	isRunning: boolean;
	@EntityField({ defaultValue: false })
	isTerminated: boolean;
	@EntityField({ defaultValue: false })
	isTerminatedByUser: boolean;
	@EntityField({ defaultValue: false })
	isDisabled: boolean;
	@EntityField({ defaultValue: false })
	hideErrors: boolean;
	@EntityField({ defaultValue: false })
	investigationQueued: boolean;
	@EntityField() sevilleStatusId: number;
	@EntityField() isTempOffloadingStatus: boolean;
	@EntityField() isPendingUser: boolean;
}
