var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { NotificationRuleSeverityConditionValues, } from './notification-rule-condition-value';
import { NotificationFormatEnum } from './notification-rule-format.values';
var ɵ0 = function (accumulator, value) {
    accumulator[value.id + "Severity"] = false;
    return accumulator;
};
var defaultValues = Object.assign(NotificationRuleSeverityConditionValues.reduce(ɵ0, {}), {
    MailEnabled: true,
});
var ɵ1 = function (config) { return config.data.serviceUrls.alertsEmailNotifications; }, ɵ2 = function (rule) {
    var payload = Object.assign({
        IncludeOrganization: rule.formatFlavor.some(function (f) { return f.id === NotificationFormatEnum.customerName; }),
        MailAddresses: rule.recipients,
    }, defaultValues);
    rule.conditions.forEach(function (condition) {
        if (condition.type.id === 1) {
            condition.values.forEach(function (value) {
                payload[value.id + "Severity"] = true;
            });
            return true;
        }
    });
    return {
        data: payload,
    };
};
var SendTestEmailApiCall = /** @class */ (function (_super) {
    __extends(SendTestEmailApiCall, _super);
    function SendTestEmailApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SendTestEmailApiCall = __decorate([
        ApiCall({
            name: 'Send test email for alert notification',
            endpoint: 'SendTestEmail',
            method: 'POST',
            baseUrl: ɵ1,
            parseQuery: ɵ2,
        })
    ], SendTestEmailApiCall);
    return SendTestEmailApiCall;
}(ApiCallModel));
export { SendTestEmailApiCall };
export { ɵ0, ɵ1, ɵ2 };
