import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import {
	EvaluationMachine,
	EvaluationSimulatorStatusType,
	EvaluationSimulationCatalog,
	EvaluationSimulatorAgent,
} from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { EvaluationService } from '../services/evaluation.service';
import { SpinnerSize, MessageBarType } from 'office-ui-fabric-react';
import { tap, finalize } from 'rxjs/operators';
import { DialogsService } from '../../dialogs/services/dialogs.service';

@Component({
	selector: 'evaluation-create-simulation-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './evaluation-create-simulation-panel.component.html',
})
export class EvaluationCreateSimulationPanelComponent extends PanelContainer implements OnInit {
	@Input() machinesWithSimulatorsReady: Array<EvaluationMachine>;
	@Input() simulationId?: number;

	simulationsCatalog?: Array<EvaluationSimulationCatalog> = [];
	canCreateSimulation = false;

	constructor(
		router: Router,
		public evaluationService: EvaluationService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super(router);
	}

	ngOnInit() {
		this.getSimulationsCatalog();
		this.evaluationService.evaluationMachines$
			.pipe(
				tap(() => {
					this.canCreateSimulation = !!this.evaluationService.getMachinesWithSimulatorsReady()
						.length;
					this.changeDetectorRef.markForCheck();
				})
			)
			.subscribe();
	}

	SpinnerSize = SpinnerSize;
	MessageBarType = MessageBarType;

	selectedSimulator: EvaluationSimulatorAgent;
	selectedSimulation: EvaluationSimulationCatalog;
	selectedMachine: EvaluationMachine;
	createInProgress: boolean = false;

	getSimulators(): Array<EvaluationSimulatorAgent> {
		return Object.values(EvaluationSimulatorAgent);
	}

	getSimulationsCatalog() {
		this.evaluationService
			.getSimulationsCatalog()
			.pipe(
				tap(simulationsCatalog => {
					if (this.simulationId) {
						const simulation = simulationsCatalog.find(s => s.simulationId === this.simulationId);
						this.selectedSimulator = simulation.simulator;
						this.selectedSimulation = simulation;
					}
					this.simulationsCatalog =
						this.selectedSimulator === EvaluationSimulatorAgent.All
							? simulationsCatalog
							: simulationsCatalog.filter(
									simulation =>
										EvaluationSimulatorAgent[simulation.simulator] ===
										this.selectedSimulator
							  );
				})
			)
			.subscribe();
	}

	onSimulatorSelect() {
		this.selectedSimulation = null;
		this.selectedMachine = null;
		this.getSimulationsCatalog();
	}

	getSimulatorRelatedMachines(): Array<EvaluationMachine> {
		if (
			!this.selectedSimulator ||
			(this.selectedSimulator === EvaluationSimulatorAgent.All &&
				(!this.selectedSimulation || !this.selectedSimulation.simulator))
		)
			return;
		return this.machinesWithSimulatorsReady.filter(m => {
			const simulatorsExist = Object.entries(m.simulatorsStatus)
				.filter(k => k.every(v => v))
				.filter(s => {
					return (
						(s[0].toLowerCase() === this.selectedSimulator.toLowerCase() ||
							s[0].toLowerCase() ===
								(this.selectedSimulation &&
									this.selectedSimulation.simulator.toLowerCase())) &&
						s[1].id === EvaluationSimulatorStatusType.Completed
					);
				});
			return simulatorsExist.length;
		});
	}

	getSimulationLabel = (simulationCatalog: EvaluationSimulationCatalog) => {
		return (
			simulationCatalog &&
			(this.selectedSimulator === EvaluationSimulatorAgent.All
				? `${simulationCatalog.simulator}: ${simulationCatalog.name}`
				: simulationCatalog.name)
		);
	};

	getMachineLabel = (machine: EvaluationMachine) => {
		return machine && machine.name;
	};

	createSimulation() {
		this.createInProgress = true;
		this.changeDetectorRef.detectChanges();
		return this.evaluationService
			.createSimulation(this.selectedSimulation.simulationId, this.selectedMachine.index)
			.pipe(finalize(() => (this.createInProgress = false)))
			.subscribe(
				() => {
					this.destroy();
				},
				(error) => {
					const isSimulationBlocked = error.status === 400 && error.response && error.response.errorCode === 'SimulationRunningOnSameMachine';
					const isDefault = error.status === 400 && error.response && error.response.errorCode === 'Default';
					this.changeDetectorRef.markForCheck();
					this.dialogsService.showError({
						title: this.i18nService.get(
							'evaluation.dashboard.simulationDataView.commandBar.createSimulation.error'
						),
						data: isSimulationBlocked ? this.i18nService.get('evaluation.dashboard.simulationDataView.commandBar.createSimulation.error.simulationRunningOnMachine') : isDefault ? '' : error,
					});
				}
			);
	}

	getTooltip() {
		return !this.selectedMachine || !this.selectedSimulation
			? this.i18nService.get(
					'evaluation.dashboard.simulationDataView.commandBar.createSimulation.missingChoose'
			  )
			: '';
	}
}
