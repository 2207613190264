import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FabIconModule } from '@angular-react/fabric';
import { ExpanderComponent } from './expander.component';
import { ExpanderButtonComponent } from './expander-button/expander-button.component';
import { AngularExtensionsModule } from '@wcd/angular-extensions';
import { CollapsibleSectionComponent } from './collapsible-section.component';
import {I18nModule} from "@wcd/i18n";

@NgModule({
	imports: [CommonModule, FabIconModule, AngularExtensionsModule, I18nModule],
	declarations: [ExpanderComponent, ExpanderButtonComponent, CollapsibleSectionComponent],
	exports: [ExpanderComponent, CollapsibleSectionComponent, ExpanderButtonComponent],
})
export class ExpanderModule {}
