import { DataEntityType, Paris } from '@microsoft/paris';
import {
	MachineGroup,
	MachineHealthStatus,
	MachineRiskScore,
	MachineTags,
	MachineTagsApiCall,
	OperatingSystemPlatform,
	OperatingSystemPlatformCategories,
	MachineExposureScore,
	ReleaseVersion,
	Tag,
	AntivirusStatus,
	iotDeviceTypes,
	networkDeviceTypes,
	DeviceType,
	MachineVulnerabilitySeverityLevel,
	MachineVulnerabilityAgeLevel,
	MachineExploitLevel,
	onboardingStatuses,
	firstSeenValues,
	exclusionStates,
	machineManagedByValues,
	MachineManagedByType,
} from '@wcd/domain';
import { combineLatest, Observable, of } from 'rxjs';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { Injectable } from '@angular/core';
import { RbacService } from '../../../rbac/services/rbac.service';
import { DataviewField } from '@wcd/dataview';
import { map, take, switchMap } from 'rxjs/operators';
import { clone, compact, keyBy, mapValues, merge, values, pick } from 'lodash-es';
import {
	FilterValuesChecklistValueData,
	SerializedFilters,
	FilterValuesChecklistSelection,
	ListFilterValue,
} from '@wcd/ng-filters';
import { I18nService } from '@wcd/i18n';
import { DeviceCategory } from '../models/machine.model';
import { AppFlavorConfig } from '@wcd/scc-common';
import { AppConfigService } from '@wcd/app-config';

export const enum MachinesFilterType {
	MachineValue,
}

const MacinesFilterConfig: Record<
	MachinesFilterType,
	{
		backendProperty: string;
	}
> = {
	[MachinesFilterType.MachineValue]: {
		backendProperty: 'assetValue',
	},
};

const MAX_TAGS_FOR_FILTER = 2000;

@Injectable()
export class MachinesFiltersService {
	machineHealthStatusCategoriesMap: { [index: string]: HealthStatusCategoryFilter };
	osPlatformCategoriesMap: { [index: string]: OsPlatformCategoriesFilter };
	deviceTypeCategoriesMap: { [index: string]: DeviceTypeCategoriesFilter };
	private _machineFilters: Record<string, any>;
	private defaultMachineFilters: Record<string, any>;
	private iotDeviceTypeFilters;

	constructor(
		private rbacService: RbacService,
		private featuresService: FeaturesService,
		private paris: Paris,
		private i18nService: I18nService,
		private flavorService: FlavorService,
		private appConfigService: AppConfigService
	) {
		this.machineHealthStatusCategoriesMap = (<DataEntityType>MachineHealthStatus).entityConfig.values
			.filter((status: MachineHealthStatus) => status.category)
			.reduce((res, status: MachineHealthStatus) => {
				const category = status.category;
				if (!res[category]) {
					res[category] = {
						id: category,
						name: category,
						values: [],
						children: [],
					};
				}

				if (status.childOf === category) res[category].children.push(status);
				else res[category].values.push(status);
				return res;
			}, {});

		const relevantIotDevicesTypes = iotDeviceTypes;
		if (!featuresService.isEnabled(Feature.PetraNdrConvergenceUx)) {
			// TODO(khaw): Remove this line and NetworkDevice from iotDeviceTypes as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'
			relevantIotDevicesTypes.unshift(networkDeviceTypes[0]);
		}

		this.deviceTypeCategoriesMap = relevantIotDevicesTypes.reduce(
			(res, { i18nNameKey, id, priority }) => {
				res[id] = {
					id,
					name: this.i18nService.get(i18nNameKey),
					priority,
				};
				return res;
			},
			{}
		);

		this.osPlatformCategoriesMap = (<DataEntityType>OperatingSystemPlatform).entityConfig.values.reduce(
			(res, osPlatform: OperatingSystemPlatform) => {
				let category = osPlatform.category;
				if (
					([
						OperatingSystemPlatformCategories.Linux,
						OperatingSystemPlatformCategories.macOS,
					].includes(category) &&
						!this.featuresService.isEnabled(Feature.XPlatform)) ||
					([
						OperatingSystemPlatformCategories.Windows11,
						OperatingSystemPlatformCategories.WindowsServer2022,
					].includes(category) &&
						!this.featuresService.isEnabled(Feature.NewWindowsVersions))
				) {
					category = OperatingSystemPlatformCategories.OtherPlatform;
				}

				if (!res[category] && osPlatform.isBackendSupportedFilter)
					res[category] = {
						id: category,
						name: OperatingSystemPlatformCategories.OtherPlatform,
						values: [],
						priority: Infinity,
					};
				if (category === osPlatform.id)
					merge(res[category], {
						name: osPlatform.categoryName,
						priority: osPlatform.priority,
					});
				if (osPlatform.isBackendSupportedFilter) {
					res[category].values.push(osPlatform);
				}
				return res;
			},
			{}
		);

		this.defaultMachineFilters = {
			osPlatforms: {
				count: null,
				values: values(this.osPlatformCategoriesMap).map((osPlatform: OsPlatformCategoriesFilter) => {
					return {
						value: osPlatform.id,
						count: null,
					};
				}),
			},
			healthStatuses: {
				count: null,
				values: values(this.machineHealthStatusCategoriesMap).map(
					(statusCategory: HealthStatusCategoryFilter): FilterValuesChecklistValueData => {
						return {
							value: statusCategory.id,
							count: null,
						};
					}
				),
			},
			securityPropertiesRequiringAttention: {
				count: null,
				values: (<DataEntityType>AntivirusStatus).entityConfig.values.map(
					(status: AntivirusStatus) => {
						if (
							this.featuresService.isEnabled(Feature.UseTvmMachinesAvStatus) &&
							status.id == 'AntiVirusNotReporting'
						) {
							return {
								value: status.id,
								name: this.i18nService.get(
									`reporting.machineReport.antivirusStatus.unknownNew`
								),
								count: null,
							};
						} else {
							return {
								value: status.id,
								name: status.name,
								count: null,
							};
						}
					}
				),
			},
		};

		this.defaultMachineFilters.firstseen = {
			count: null,
			values: firstSeenValues.map(({ i18nNameKey, id, priority }) => ({
				value: id,
				priority,
				name: this.i18nService.get(i18nNameKey),
				count: null,
			})),
		};

		if (
			(this.featuresService.isEnabled(Feature.EndpointConfigManagementFe) &&
				this.featuresService.isEnabled(Feature.EndpointConfigManagement)) ||
			this.flavorService.isEnabled(AppFlavorConfig.settings.mdeAttach)
		) {
			this.defaultMachineFilters.managedByList = {
				count: null,
				values: machineManagedByValues.map(({ nameI18nKey, id, priority }) => ({
					value: id,
					priority,
					name: this.i18nService.get(nameI18nKey),
					count: null,
				})).filter(managedBy =>
					managedBy.value !== MachineManagedByType.Sccm || this.featuresService.isEnabled(Feature.EndpointConfigManagementMdeWithSccm)
				),
			};
		}

		if (
			this.featuresService.isEnabled(Feature.DevicesListIotV1) ||
			this.featuresService.isEnabled(Feature.DeviceInventoeryDisplayNotManagedDevices)
		) {
			this.defaultMachineFilters.onBoardingStatuses = {
				count: null,
				values: onboardingStatuses.map(({ id, i18nNameKey }) => ({
					value: id,
					name: this.i18nService.get(i18nNameKey),
					count: null,
				})),
			};
		}

		if (this.featuresService.isEnabled(Feature.ExcludedDevices)) {
			this.defaultMachineFilters.exclusionStates = {
				count: null,
				values: exclusionStates.map(({ id, i18nNameKey }) => ({
					value: id,
					name: this.i18nService.get(i18nNameKey),
					count: null,
				})),
			};
		}

		this.iotDeviceTypeFilters = {
			count: null,
			values: relevantIotDevicesTypes.map(({ i18nNameKey, id, priority }) => ({
				value: id,
				priority,
				name: this.i18nService.get(i18nNameKey),
				count: null,
			})),
		};

		Object.assign(this.defaultMachineFilters, {
			riskScores: {
				count: null,
				values: (<DataEntityType>MachineRiskScore).entityConfig.values.map(
					(riskScore: MachineRiskScore) => {
						return {
							value: riskScore.id,
							count: null,
							priority: riskScore.priority,
						};
					}
				),
			},
		});

		Object.assign(this.defaultMachineFilters, {
			vulnerabilitySeverityLevels: {
				count: null,
				values: (<DataEntityType>MachineVulnerabilitySeverityLevel).entityConfig.values.map(
					(vulnerabilitySeverityLevel: MachineVulnerabilitySeverityLevel) => {
						return {
							value: vulnerabilitySeverityLevel.id,
							count: null,
							priority: vulnerabilitySeverityLevel.priority,
						};
					}
				),
			},
		});

		Object.assign(this.defaultMachineFilters, {
			vulnerabilityAgeLevels: {
				count: null,
				values: (<DataEntityType>MachineVulnerabilityAgeLevel).entityConfig.values.map(
					(vulnerabilityAgeLevel: MachineVulnerabilityAgeLevel) => {
						return {
							value: vulnerabilityAgeLevel.id,
							count: null,
							priority: vulnerabilityAgeLevel.priority,
						};
					}
				),
			},
		});

		Object.assign(this.defaultMachineFilters, {
			exploitLevels: {
				count: null,
				values: (<DataEntityType>MachineExploitLevel).entityConfig.values.map(
					(exploitLevel: MachineExploitLevel) => {
						return {
							value: exploitLevel.id,
							count: null,
							priority: exploitLevel.priority,
						};
					}
				),
			},
		});

		Object.assign(this.defaultMachineFilters, {
			exposureScores: {
				count: null,
				values: (<DataEntityType>MachineExposureScore).entityConfig.values.map(
					(exposureScore: MachineExposureScore) => {
						return {
							value: exposureScore.id,
							count: null,
							priority: exposureScore.priority,
						};
					}
				),
			},
		});

		Object.assign(this.defaultMachineFilters, {
			releaseVersion: {
				count: null,
				values: (<DataEntityType>ReleaseVersion).entityConfig.values.map(
					(releaseVersion: ReleaseVersion) => {
						return {
							value: releaseVersion.name,
							count: null,
							priority: releaseVersion.priority,
						};
					}
				),
			},
		});
	}

	clearMachineTagsApiCall() {
		this.paris.clearApiCallCache(MachineTagsApiCall);
	}

	getMachinesFiltersWithPseudoTags(
		options: SerializedFilters,
		pseudoTags: ReadonlyArray<Tag>
	): Observable<Record<string, any>> {
		return this.getMachinesFilters(options).pipe(
			switchMap((filters) => {
				const clonedFilters = clone(filters);
				const tagsFilter = clonedFilters['tags'];
				if (tagsFilter) {
					pseudoTags.forEach((pt) => {
						tagsFilter.count++;
						tagsFilter.values.splice(0, 0, {
							value: pt.name,
							name: pt.name,
							custom: {
								filterType: MachinesFilterType.MachineValue,
								filterData: pt,
							},
						});
					});
				}
				return of(clonedFilters);
			})
		);
	}

	getMachinesFilters(options: SerializedFilters): Observable<Record<string, any>> {
		return combineLatest(
			this.getUserExposedRbacGroups(),
			this.paris.apiCall(MachineTagsApiCall, {
				migrateToVNext: this.featuresService.isEnabled(
					Feature.MachinesControllerMigrationGetTags
				),
			}),
			(rbacGroups: Array<FilterValuesChecklistValueData>, machineTags: MachineTags) => {
				this._machineFilters = this.getDefaultMachineFilters(
					options,
					!this.appConfigService.magellanOptOut
				);

				this.addRbacGroupsFilter(this._machineFilters, rbacGroups);

				/*
				 * If there are "too many tags" we don't want to show tags filter as it hurts
				 * the entire filter panel rendering and it's not usable anyway
				 */
				const allMachineTags = machineTags ? machineTags.allTags : [];
				if (allMachineTags.length < MAX_TAGS_FOR_FILTER) {
					this.addTagsFilter(this._machineFilters, machineTags);
				}

				return this._machineFilters;
			}
		).pipe(take(1));
	}

	private addRbacGroupsFilter(filters: Record<string, any>, rbacGroups) {
		rbacGroups = rbacGroups ? compact(rbacGroups) : [];

		Object.assign(filters, {
			rbacGroupIds: {
				count: rbacGroups.length,
				values: rbacGroups,
			},
		});
	}

	private addTagsFilter(filters: Record<string, any>, machineTags: MachineTags) {
		const tagFilterValuesIndex: Record<
			string,
			FilterValuesChecklistValueData<string, { isUserDefined?: boolean; isBuiltIn?: boolean }>
		> = mapValues(
			keyBy(machineTags.userDefinedTags, (tag) => tag.toLowerCase()),
			(tag) => ({
				value: tag,
				name: tag,
				custom: { isUserDefined: true },
			})
		);

		machineTags.builtInTags.forEach((builtInTag) => {
			const tagKey = builtInTag.toLowerCase();

			const existingTag = tagFilterValuesIndex[tagKey];
			if (existingTag) existingTag.custom.isBuiltIn = true;
			else
				tagFilterValuesIndex[tagKey] = {
					value: builtInTag,
					name: builtInTag,
					custom: { isBuiltIn: true },
				};
		});

		const sortedAllTags: Array<FilterValuesChecklistValueData<
			string,
			{ isUserDefined?: boolean; isBuiltIn?: boolean }
		>> = values(tagFilterValuesIndex);

		filters['tags'] = {
			count: sortedAllTags.length + 1,
			values: [{ value: null, name: null, priority: 0 }, ...sortedAllTags],
		};
	}

	getDefaultMachineFilters(
		options: SerializedFilters,
		displayNotManagedDevicesFilters: boolean
	): Record<string, any> {
		const filters = this.isIotOrNetworkCategory(options)
			? this.getIotOrNetworkMachinesFilters(options)
			: this.defaultMachineFilters;
		const { managedByList, onBoardingStatuses, exclusionStates, ...fixedFilters } = filters;
		let defaultFilters = fixedFilters;

		const isEndpointManagementEnabled =
			this.featuresService.isEnabled(Feature.EndpointConfigManagementFe) &&
			this.featuresService.isEnabled(Feature.EndpointConfigManagement);
		const isSmbEnabled = this.flavorService.isEnabled(AppFlavorConfig.settings.mdeAttach);
		if ((isEndpointManagementEnabled || isSmbEnabled) && managedByList) {
			defaultFilters = { managedByList, ...defaultFilters };
		}
		if (displayNotManagedDevicesFilters && onBoardingStatuses) {
			defaultFilters = { onBoardingStatuses, ...defaultFilters };
		}
		if (exclusionStates) {
			defaultFilters = { exclusionStates, ...defaultFilters };
		}

		return defaultFilters;
	}

	getIotOrNetworkMachinesFilters(options: SerializedFilters) {
		const {
			riskScores,
			vulnerabilitySeverityLevels,
			vulnerabilityAgeLevels,
			exploitLevels,
			exposureScores,
			firstseen,
			exclusionStates,
		} = this.defaultMachineFilters;

		const iotFilters = {
			deviceTypes: this.isIot(options) ? this.iotDeviceTypeFilters : null,
			riskScores,
			vulnerabilitySeverityLevels,
			vulnerabilityAgeLevels,
			exploitLevels,
			exposureScores,
			firstseen,
			exclusionStates,
		};

		return iotFilters;
	}

	isIot(options: SerializedFilters): boolean {
		return options && options.mainCategory && options.mainCategory === DeviceCategory.IoT;
	}

	isIotOrNetworkCategory(options: SerializedFilters): boolean {
		return (
			options &&
			options.mainCategory &&
			(options.mainCategory === DeviceCategory.IoT ||
				options.mainCategory === DeviceCategory.NetworkDevice)
		);
	}

	searchFilterValues(
		field: DataviewField,
		term: string,
		options?: any
	): Promise<Array<FilterValuesChecklistValueData>> {
		if (field.id === 'rbacGroupIds') {
			return this.searchMachineGroupFilterValues(term, options);
		}
		return Promise.resolve([]);
	}

	searchMachineGroupFilterValues(
		term: string,
		options?: any
	): Promise<Array<FilterValuesChecklistValueData>> {
		term = term.toLowerCase();

		return this.getUserExposedRbacGroups()
			.pipe(
				take(1),
				map((filterValues: Array<FilterValuesChecklistValueData>) =>
					filterValues.filter((v) => v.name && v.name.toLowerCase().includes(term))
				)
			)
			.toPromise();
	}

	clearMachinesFilters() {
		this._machineFilters = null;
	}

	getUserExposedRbacGroups(): Observable<Array<FilterValuesChecklistValueData>> {
		return this.rbacService.userExposedRbacGroups$.pipe(
			map(
				(data: Array<MachineGroup>) =>
					data &&
					data
						.filter((item) => item.id && item.name)
						.map((item) => {
							return {
								value: item.id,
								name: item.name,
								count: null,
							};
						})
			)
		);
	}

	serializeFilterValues(
		filterSelection: FilterValuesChecklistSelection<string>,
		listValues: ReadonlyArray<
			ListFilterValue<
				string,
				{
					isUserDefined?: boolean;
					isBuiltIn?: boolean;
					filterType?: MachinesFilterType;
					filterData?: any;
				}
			>
		>
	): Record<string, string | string[]> {
		if (!filterSelection || !filterSelection.length) return null;

		const selectedValuesIndex = pick(
			keyBy(listValues, (listValue) => listValue.id),
			filterSelection
		);
		const selectedValues = compact(filterSelection).map((tag) => selectedValuesIndex[tag]);

		const machineGroups: Array<string> = [];
		const userTags: Array<string> = [];
		const machineValues: Array<string> = [];

		if (selectedValues.length) {
			selectedValues.forEach((value) => {
				if (value.data) {
					if (value.data.isBuiltIn) machineGroups.push(value.id);

					if (value.data.isUserDefined) userTags.push(value.id);

					if (value.data.filterType === MachinesFilterType.MachineValue) {
						machineValues.push(value.data.filterData.id);
					}
				}
			});
		}

		return {
			machineGroups: machineGroups.length ? machineGroups : null,
			machineTags: userTags.length ? userTags : null,
			noTags: filterSelection.includes(null) ? 'true' : null,
			[MacinesFilterConfig[MachinesFilterType.MachineValue].backendProperty]: machineValues.length
				? machineValues
				: null,
		};
	}

	deserializeFilterValues(serializedValues: SerializedFilters): string[] {
		let deserialized: Array<string> = [];
		if (serializedValues['noTags']) deserialized.push(null);

		const userTags = <string>serializedValues['machineTags'];
		const machineGroups = <string>serializedValues['machineGroups'];

		deserialized = deserialized.concat(
			userTags ? userTags : [],
			machineGroups ? machineGroups : [],
			this.deserializePseudoTags(serializedValues)
		);

		return deserialized;
	}

	private deserializePseudoTags(serializedValues: SerializedFilters): string[] {
		const machineValues = <string[]>(
			serializedValues[MacinesFilterConfig[MachinesFilterType.MachineValue].backendProperty]
		);
		return machineValues
			? machineValues.map(
					(mv) =>
						`${this.i18nService.get('machines_entityDetails_actions_machineValue_title')}: ${mv}`
			  )
			: [];
	}
}

interface FilterValuesMap<T> {
	id: string;
	name: string;
	priority?: number;
	values: Array<T>;
	children?: Array<T>;
}

export interface HealthStatusCategoryFilter extends FilterValuesMap<MachineHealthStatus> {}
export interface OsPlatformCategoriesFilter extends FilterValuesMap<OperatingSystemPlatform> {}
export interface DeviceTypeCategoriesFilter extends FilterValuesMap<DeviceType> {}
