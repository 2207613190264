/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./incident.name.component";
var styles_IncidentNameComponent = [];
var RenderType_IncidentNameComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IncidentNameComponent, data: {} });
export { RenderType_IncidentNameComponent as RenderType_IncidentNameComponent };
export function View_IncidentNameComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "field-value-link"], ["data-track-id", "IncidentNameLink"], ["data-track-type", "Navigation"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(3, 2), (_l()(), i0.ɵted(4, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, "/incidents", _co.incidentId); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.incidentName; _ck(_v, 4, 0, currVal_3); }); }
export function View_IncidentNameComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "incident-name", [], null, null, null, View_IncidentNameComponent_0, RenderType_IncidentNameComponent)), i0.ɵdid(1, 49152, null, 0, i3.IncidentNameComponent, [], null, null)], null, null); }
var IncidentNameComponentNgFactory = i0.ɵccf("incident-name", i3.IncidentNameComponent, View_IncidentNameComponent_Host_0, { incidentId: "incidentId", incidentName: "incidentName" }, {}, []);
export { IncidentNameComponentNgFactory as IncidentNameComponentNgFactory };
