import { SevilleModule } from '../../../seville/seville.module';

SevilleModule.directive('actionStatus', actionStatusDirective);

actionStatusDirective.$inject = [];

function actionStatusDirective() {
	actionStatusDirectiveController.$inject = ['$log', '$http', '$scope'];

	function actionStatusDirectiveController($log, $http, $scope) {
		var vm = this;
		vm.showActionResults = true;

		vm.hideResults = function() {
			vm.showActionResults = !vm.showActionResults;
		};
	}

	return {
		restrict: 'EA',
		scope: {
			results: '=',
			loading: '=',
			header: '=',
			summary: '=',
			actionDescription: '=',
			cancelActionDescription: '=',
			cancelButtonTitle: '=',
		},
		template: `	
    <table class="action-center-table action-center-action-header">
        <tr class="action-center-result-header">
            <td>
                <span class="action-center-header-text col-md-height">{{actionStatus.header}}</span>
            </td>
            <td>
                <button class="btn btn-link dropdown-toggle action-center-title-button" data-toggle="dropdown" aria-expanded="true" type="button" ng-click="actionStatus.hideResults()" ng-disabled="actionStatus.loading">
                    <span ng-if="actionStatus.showActionResults" class="icon action-center-title-order-icon icon-ChevronUp"></span>
                    <span ng-if="!actionStatus.showActionResults" class="icon action-center-title-order-icon icon-ChevronDown"></span>
                </button>
            </td>
        </tr>
    </table>
    <div class="side-pane-section-loading text-center" ng-if="actionStatus.loading">
        <img src="/assets/images/circle-loading.gif" />
    </div>
    <table class="action-center-table" 
    	data-track-component="SevilleActionCenter"
    	ng-if="actionStatus.showActionResults && !actionStatus.loading && actionStatus.summary">
        <tr>
            <th class="action-center-column-title">Submission time</th>
            <th class="action-center-column-title">Status</th>
            <th class="action-center-column-title" ng-if="actionStatus.results.DisplayCancelButton"></th>
        </tr>
        <tr>
            <td class="action-center-cell-time">
                <span ng-if="::actionStatus.results.CreationDateTimeUtc">
                    {{actionStatus.results.CreationDateTimeUtc | sevilleDate : 'date'}} | {{actionStatus.results.CreationDateTimeUtc | sevilleDate : 'time'}}
                </span>
            </td>
            <td class="action-center-cell-status">
                <div>
                    <a ng-if="actionStatus.results.packageUri" 
						data-track-id="RefreshUriAndDownloadPackage"
		   				data-track-type="Navigation"
                    	href="#" ng-click="actionStatus.results.refreshUriAndDownloadPackage(true)"><i class="icon machine-timeline-icon icon-Download"></i>{{actionStatus.results.ActionStatus}}</a>
                    <span ng-if="!actionStatus.results.packageUri">{{actionStatus.results.ActionStatus}}</span>
                    <i ng-if="actionStatus.results.IsolationType==1 && actionStatus.results.RequestStatus == 'Succeeded'" class="icon action-center-post-comment-icon icon-Error"></i>
                    <i ng-if="actionStatus.results.RequestStatus == 'Failed'" class="icon icon-Info action-center-cell-info-icon" tooltips tooltip-size="medium" tooltip-title="{{::actionStatus.results.ErrorHResult | errorcode}}" tooltip-side="left"></i>
                </div>
            </td>
            <td class="action-center-cell-result" ng-if="actionStatus.results.DisplayCancelButton">
                <span class="action-center-cancel-message">
                    <button type="button" ng-click="actionStatus.results.cancel()" class="btn btn-link action-center-cancel-message dropdown-toggle nopadding pointer">
                        <i class="icon action-center-cancel-icon icon-Cancel "></i>{{::actionStatus.cancelButtonTitle}}
                    </button>
                </span>
            </td>
        </tr>
    </table>
    
    <table class="action-center-table" ng-if="actionStatus.showActionResults && !actionStatus.loading && !actionStatus.summary">
        <tr>
            <th class="action-center-column-title">Submission time</th>
            <th class="action-center-column-title-status">Success</th>
            <th class="action-center-column-title-status">Failed</th>
            <th ng-if="!actionStatus.results.CancellationDateTimeUtc" class="action-center-column-title-status">Pending</th>
            <th ng-if="actionStatus.results.CancellationDateTimeUtc" class="action-center-column-title-status">Canceled</th>
            <th class="action-center-column-title-action"></th>
        </tr>
        <tr ng-if="::actionStatus.results.CreationDateTimeUtc">
            <td class="action-center-cell-time">
                {{actionStatus.results.CreationDateTimeUtc | sevilleDate : 'date'}} | {{actionStatus.results.CreationDateTimeUtc | sevilleDate : 'time'}}
            </td>
            <td class="action-center-cell-result">
                <i class="icon icon-DeviceLaptopNoPic"></i>
                {{actionStatus.results.successes}}
            </td>
            <td class="action-center-cell-result">
                <i class="icon icon-DeviceLaptopNoPic"></i>
                {{actionStatus.results.failures}}
            </td>
            <td class="action-center-cell-result">
                <i class="icon icon-DeviceLaptopNoPic"></i>
                <span ng-if="!actionStatus.results.CancellationDateTimeUtc">{{actionStatus.results.pending}}</span>
                <span ng-if="actionStatus.results.CancellationDateTimeUtc">{{actionStatus.results.canceled}}</span>
            </td>
            <td class="action-center-cell-result">
                <span ng-if="actionStatus.results.DisplayCancelButton" class="action-center-cancel-message">
                    <button tooltips tooltip-size="medium" tooltip-title="{{::actionStatus.cancelButtonTitle}}" tooltip-side="left" type="button" ng-click="actionStatus.results.cancel()" class="btn btn-link action-center-cancel-message action-center-cancel-message-forensic dropdown-toggle nopadding pointer">
                        <i class="icon action-center-cancel-icon icon-Cancel"></i>{{::actionStatus.cancelButtonTitle}}
                    </button>
                </span>
            </td>
        </tr>
    </table>
    <div ng-if="actionStatus.showActionResults && !actionStatus.loading && !actionStatus.summary && actionStatus.results.CreationDateTimeUtc">
        <span id="exportRemediateStatusToCsv" ng-if="actionStatus.showActionResults && !actionStatus.loading && !actionStatus.summary" ng-click="actionStatus.results.downloadStatusCsv()" class="action-center-export-to-csv-open-dialog-button no-outline pointer">
            <i class="icon icon-ExcelLogo export-to-csv-excel-logo"></i> Export
        </span>
    </div>
    <div ng-if="actionStatus.showActionResults && actionStatus.results.packageUri" class="action-center-comment-section">
        <div>
            <i class="icon action-center-pre-comment-icon icon-Clock"></i>
            {{actionStatus.results.LastUpdateTimeUtc | sevilleDate : 'date'}} | {{actionStatus.results.LastUpdateTimeUtc | sevilleDate : 'time'}} Package collection is ready
        </div>
    </div>
    <div ng-if="actionStatus.showActionResults && actionStatus.results.CancellationDateTimeUtc" class="action-center-comment-section">
        <div>
            <i class="icon action-center-pre-comment-icon icon-Clock"></i>
            {{actionStatus.results.CancellationDateTimeUtc | sevilleDate : 'date'}} | {{actionStatus.results.CancellationDateTimeUtc | sevilleDate : 'time'}} {{actionStatus.cancelActionDescription}}
        </div>
        <div>
            {{actionStatus.results.CancellationRequestor}}: {{actionStatus.results.CancellationComment}}
        </div>
    </div>
    <div ng-if="actionStatus.showActionResults && actionStatus.results.CreationDateTimeUtc" class="action-center-comment-section">
        <div>
            <i class="icon action-center-pre-comment-icon icon-Clock"></i>
            {{actionStatus.results.CreationDateTimeUtc | sevilleDate : 'date'}} | {{actionStatus.results.CreationDateTimeUtc | sevilleDate : 'time'}} {{actionStatus.actionDescription}}
        </div>
        <div>
            {{::actionStatus.results.Requestor}}: {{::actionStatus.results.RequestorComment}}
        </div>
    </div>
		`,
		controllerAs: 'actionStatus',
		bindToController: true,
		controller: actionStatusDirectiveController,
	};
}
