import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { ThreatDimensionType } from './threat-protection-dimension-types.values';
import { ThreatProtectionData } from './threat-protection-data.value-object';

@ValueObject({
	singularName: 'Threat protection',
	pluralName: 'Threat protection',
	readonly: true,
})
export class ThreatProtection extends ModelBase {
	@EntityField({ data: 'dimensionName' })
	readonly dimensionType: ThreatDimensionType;

	@EntityField({ arrayOf: ThreatProtectionData })
	readonly data: Array<ThreatProtectionData>;
}
