import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject, timer } from 'rxjs';
import { MessageBarType } from 'office-ui-fabric-react';
import { PanelContainer } from '@wcd/panels';

import { SimulatorAgentSetupModel } from '../../models/evaluation-setup.model';
import { EvaluationService } from '../../services/evaluation.service';
import { catchError, delayWhen, retryWhen, scan, tap } from 'rxjs/operators';
import { EvaluationLab, SimulatorAdditionalSettingsData } from '@wcd/domain';
import { AuthService } from '@wcd/auth';

@Component({
	selector: 'simulator-agent-setup-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<div class="wcd-flex-vertical wcd-full-height" *ngIf="agentsConfig">
				<div
					class="wcd-flex-1 wcd-padding-large-horizontal wcd-margin-small-vertical wcd-scroll-vertical"
				>
					<form #agentsForm="ngForm">
						<simulator-agent-form-content
							[agentsConfig]="agentsConfig"
							(onProviderToggled)="onProviderToggled()"
						></simulator-agent-form-content>
						<fab-spinner *ngIf="saving" contentClass="wcd-margin-xLarge-top"></fab-spinner>
						<fab-message-bar
							[messageBarType]="MessageBarType.error"
							contentClass="wcd-margin-xLarge-top"
							*ngIf="(error$ | async) && !saving"
						>
							{{ 'evaluation.lab.setupPanel.summaryStep.simulatorError' | i18n }}
						</fab-message-bar>
					</form>
				</div>
				<div
					class="wcd-flex-justify-end wcd-flex-none wcd-padding-horizontal wcd-padding-top wcd-flex-horizontal"
				>
					<fab-primary-button
						contentClass="wcd-margin-small-horizontal"
						[disabled]="!agentsForm.valid || saving || !canUpdate"
						[text]="'evaluation_lab_setupPanel_summaryStep_update' | i18n"
						(onClick)="onUpdate()"
					></fab-primary-button>
				</div>
			</div>
		</wcd-panel>
	`,
})
export class EditSimulatorAgentsPanelComponent extends PanelContainer implements OnInit {
	agentsConfig: SimulatorAgentSetupModel;
	saving = false;
	error$ = new Subject<boolean>();
	canUpdate = false;
	MessageBarType = MessageBarType;

	constructor(
		router: Router,
		private evaluationService: EvaluationService,
		private authService: AuthService
	) {
		super(router);
	}

	ngOnInit() {
		this.evaluationService
			.getEvaluationLab()
			.pipe(
				tap(lab => {
					this.agentsConfig = this.getInitialAgentsConfig(lab);
				})
			)
			.subscribe();
	}

	private getInitialAgentsConfig(lab: EvaluationLab): SimulatorAgentSetupModel {
		const initialConfig: SimulatorAgentSetupModel = {
			withSafeBreach: false,
			withAiq: false,
			email: this.authService.currentUser.name,
		};

		// if any of the simulators were enabled, it means MS consent (both terms) was read & accepted
		if (lab.simulatorsSettings.AttackIQ.isEnabled || lab.simulatorsSettings.SafeBreach.isEnabled) {
			initialConfig.msTermsConsentRead = true;
			initialConfig.msTermsConsentAccepted = true;
			initialConfig.msTermsConsentDisabled = true;
			initialConfig.msInfoSharingConsentRead = true;
			initialConfig.msInfoSharingConsentAccepted = true;
			initialConfig.msInfoSharingConsentDisabled = true;
		}

		if (lab.simulatorsSettings.AttackIQ.isEnabled) {
			initialConfig.attackIQConsentAccepted = true;
			initialConfig.withAiq = true;
			initialConfig.aiqDisabled = true;
			initialConfig.firstName = lab.simulatorsSettings.AttackIQ.additionalInfo.FirstName;
			initialConfig.lastName = lab.simulatorsSettings.AttackIQ.additionalInfo.LastName;
			initialConfig.email = lab.simulatorsSettings.AttackIQ.additionalInfo.EmailAddress;
		}

		if (lab.simulatorsSettings.SafeBreach.isEnabled) {
			initialConfig.withSafeBreach = true;
			initialConfig.safeBreachDisabled = true;
			initialConfig.firstName = lab.simulatorsSettings.SafeBreach.additionalInfo.FirstName;
			initialConfig.lastName = lab.simulatorsSettings.SafeBreach.additionalInfo.LastName;
			initialConfig.email = lab.simulatorsSettings.SafeBreach.additionalInfo.EmailAddress;
		}

		return initialConfig;
	}

	onUpdate() {
		this.saving = true;
		const agentAdditionalSettings: SimulatorAdditionalSettingsData = {
			FirstName: this.agentsConfig.firstName,
			LastName: this.agentsConfig.lastName,
			EmailAddress: this.agentsConfig.email,
		};

		this.evaluationService
			.setSimulatorsConfigurations(
				agentAdditionalSettings,
				this.agentsConfig.withSafeBreach,
				this.agentsConfig.withAiq
			)
			.pipe(
				tap(() => {
					this.closePanel();
				}),
				retryWhen((errors: Observable<any>) => {
					return errors.pipe(
						scan((errorCount: number, err) => {
							if (errorCount > 2) throw err;
							return errorCount + 1;
						}, 0),
						delayWhen(() => timer(1000))
					);
				}),
				catchError(error => {
					this.error$.next(true);
					this.saving = false;
					return of();
				})
			)
			.subscribe();
	}

	onProviderToggled() {
		this.canUpdate =
			(!this.agentsConfig.aiqDisabled && this.agentsConfig.withAiq) ||
			(!this.agentsConfig.safeBreachDisabled && this.agentsConfig.withSafeBreach);
	}
}
