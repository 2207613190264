import { EntityModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Scheduled task',
	pluralName: 'Scheduled tasks',
})
export class ScheduledTask extends EntityModelBase<string> {
	@EntityField({ data: 'TaskName' })
	taskName: string;

	// override a property with a getter/setter pair isn't allowed 
	// @ts-expect-error 
	get id(): string {
		return this.taskName;
	}

	set id(value: string) {
		this.taskName = value;
	}
}
