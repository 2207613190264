
		<div class="wcd-full-height wcd-flex-vertical">
			<div class="page-header wcd-flex-none queue-layout-header"
				  [class.wcd-padding-none-horizontal-important]="isScc">
				<h2>{{ 'endpointManagement.nonWindows.applications.dataExport.name' | i18n }}</h2>
			</div>
			<div
				*ngIf="capacityString"
				class="wcd-padding-medium-bottom"
				[class.wcd-padding-large-left]="!isScc">
				<span
					>{{ 'endpointManagement.nonWindows.applications.dataExport.availableCapacity' | i18n }}
				</span>
				<span class="wcd-margin-xsmall-left">
					{{ capacityString }}
				</span>
			</div>
			<dataview
				class="wcd-flex-1"
				[entityType]="globalEntityType"
				[allowFilters]="false"
				[fields]="fields"
				[refreshOn]="lastUpdate"
				id="dataviewexport"
				[allowPaging]="false"
				emptyDataMarkdown="No data export settings found.
[Learn more](https://go.microsoft.com/fwlink/?linkid=2095611) about how to forward Microsoft Defender for Endpoint events to Azure storage or Event-Hub."
				[repository]="dataExportRepository"
				[disableAddButton]="disableAddButton"
				allowMultipleSelection="false"
				[disabledAddTooltip]="disabledAddTooltip"
				[allowAdd]="true"
				[itemName]="'endpointManagement.nonWindows.applications.dataExport.name' | i18n"
				(onNewItem)="showDataExportNewDialog()"
				[queueHeader]="true"
				[assetCommandBar]="true"
				[padLeft]="false"
				[responsiveActionBar]="true"
				[removePadding]="isScc"
			>
			</dataview>
		</div>
	