var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
var ɵ0 = function () { return 'GetAdvancedFeaturesSetting'; }, ɵ1 = function () { return 'SaveAdvancedFeaturesSetting'; }, ɵ2 = function (config) { return config.data.serviceUrls.settings; };
var AdvancedFeatures = /** @class */ (function (_super) {
    __extends(AdvancedFeatures, _super);
    function AdvancedFeatures() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'AatpIntegrationEnabled', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "aatpIntegrationEnabled", void 0);
    __decorate([
        EntityField({ data: 'AatpWorkspaceExists', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "aatpWorkspaceExists", void 0);
    __decorate([
        EntityField({ data: 'EnableWdavAuditMode', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "enableWdavAuditMode", void 0);
    __decorate([
        EntityField({ data: 'EnableWdavPassiveModeRemediation', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "enableWdavPassiveModeRemediation", void 0);
    __decorate([
        EntityField({ data: 'BlockListEnabled', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "blockListEnabled", void 0);
    __decorate([
        EntityField({ data: 'LicenseEnabled', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "licenseEnabled", void 0);
    __decorate([
        EntityField({ data: 'O365ToAtpIntegrationEnabled', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "o365ToAtpIntegrationEnabled", void 0);
    __decorate([
        EntityField({ data: 'OfficeIntegrationEnabled', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "officeIntegrationEnabled", void 0);
    __decorate([
        EntityField({ data: 'OfficeLicenseEnabled', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "oOfficeLicenseEnabled", void 0);
    __decorate([
        EntityField({ data: 'ShowUserAadProfile', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "showUserAadProfile", void 0);
    __decorate([
        EntityField({ data: 'SkypeIntegrationEnabled', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "skypeIntegrationEnabled", void 0);
    __decorate([
        EntityField({ data: 'DartDataCollection', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "dartDataCollection", void 0);
    __decorate([
        EntityField({ data: 'AutoResolveInvestigatedAlerts', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "autoResolveInvestigatedAlerts", void 0);
    __decorate([
        EntityField({ data: 'EnableMcasIntegration', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "enableMcasIntegration", void 0);
    __decorate([
        EntityField({ data: 'EnableAipIntegration', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "enableAipIntegration", void 0);
    __decorate([
        EntityField({ data: 'EnableWdavAntiTampering', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "enableWdavAntiTampering", void 0);
    __decorate([
        EntityField({ data: 'AllowWdavNetworkBlock', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "allowWdavNetworkBlock", void 0);
    __decorate([
        EntityField({ data: 'EnableBuiltInProtection', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "enableBuiltInProtection", void 0);
    __decorate([
        EntityField({ data: 'EnableBuiltInProtectionDates', defaultValue: "" }),
        __metadata("design:type", String)
    ], AdvancedFeatures.prototype, "enableBuiltInProtectionDates", void 0);
    __decorate([
        EntityField({ data: 'WebCategoriesEnabled', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "webCategoriesEnabled", void 0);
    __decorate([
        EntityField({ data: 'EnableEndpointDlp', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "endpointDlpEnabled", void 0);
    __decorate([
        EntityField({ data: 'EnableAuditTrail', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "enableAuditTrail", void 0);
    __decorate([
        EntityField({ data: 'EnableQuarantinedFileDownload', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "enableQuarantinedFileDownload", void 0);
    __decorate([
        EntityField({ data: 'MagellanOptOut', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "magellanOptOut", void 0);
    __decorate([
        EntityField({ data: 'IsolateIncidentsWithDifferentDeviceGroups', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AdvancedFeatures.prototype, "isolateIncidentsWithDifferentDeviceGroups", void 0);
    AdvancedFeatures = __decorate([
        Entity({
            singularName: 'Advanced features',
            pluralName: 'Advanced features',
            endpoint: 'GetAdvancedFeaturesSetting',
            parseItemQuery: ɵ0,
            parseSaveQuery: ɵ1,
            saveMethod: 'POST',
            readonly: true,
            baseUrl: ɵ2,
        })
    ], AdvancedFeatures);
    return AdvancedFeatures;
}(EntityModelBase));
export { AdvancedFeatures };
export { ɵ0, ɵ1, ɵ2 };
