/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "../../../../dialogs/src/lib/tooltips/tooltip.directive";
import * as i3 from "../../../../dialogs/src/lib/tooltips/tooltips.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular-react/fabric";
import * as i6 from "../../../../prettify/src/lib/pipes/pretty-number.pipe";
import * as i7 from "../../../../prettify/src/lib/services/pretty-number.service";
import * as i8 from "../../../../localization/src/lib/services/locale-config.service";
import * as i9 from "@angular/common";
import * as i10 from "./bar.component";
import * as i11 from "../../../../shared/src/lib/services/known-colors.service";
var styles_BarComponent = [];
var RenderType_BarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BarComponent, data: {} });
export { RenderType_BarComponent as RenderType_BarComponent };
function View_BarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "fab-icon", [["contentClass", "wcd-vertical-align-bottom wcd-padding-small-horizontal inheriting-icon"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_FabIconComponent_0, i1.RenderType_FabIconComponent)), i0.ɵdid(1, 147456, null, 0, i2.TooltipDirective, [i0.ElementRef, i3.TooltipsService, i4.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i0.ɵdid(2, 5816320, null, 0, i5.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconTooltip; _ck(_v, 1, 0, currVal_0); var currVal_1 = "wcd-vertical-align-bottom wcd-padding-small-horizontal inheriting-icon"; var currVal_2 = _co.icon; _ck(_v, 2, 0, currVal_1, currVal_2); }, null); }
function View_BarComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "ms-fontColor-gray140"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t/\n\t\t\t\t"]))], null, null); }
function View_BarComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i0.ɵpid(131072, i6.PrettyNumberPipe, [i7.PrettyNumberService, [2, i8.LocaleConfigService], [2, i0.ChangeDetectorRef]]), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BarComponent_5)), i0.ɵdid(9, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(11, 0, null, null, 4, "span", [["class", "ms-fontColor-gray140"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["\n\t\t\t\t\t", "", "\n\n\t\t\t\t\t"])), i0.ɵpid(131072, i6.PrettyNumberPipe, [i7.PrettyNumberService, [2, i8.LocaleConfigService], [2, i0.ChangeDetectorRef]]), (_l()(), i0.ɵeld(14, 0, null, null, 0, "span", [], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.total >= 0); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.value)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.valueAriaLabel; _ck(_v, 6, 0, currVal_1); var currVal_3 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform(_co.total)); var currVal_4 = _co.unit; _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_5 = _co.totalAriaLabel; _ck(_v, 14, 0, currVal_5); }); }
function View_BarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-flex-justify-end-horizontal wcd-font-weight-semibold"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BarComponent_4)), i0.ɵdid(3, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showValueAsPercentage; var currVal_1 = i0.ɵnov(_v.parent.parent, 20); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_BarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "wcd-flex-horizontal-spaced"]], [[1, "tabindex", 0], [1, "role", 0]], [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick.emit() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.onClick.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "wcd-flex-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(5, null, ["\n\t\t\t\t", "\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BarComponent_2)), i0.ɵdid(8, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BarComponent_3)), i0.ɵdid(12, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.icon; _ck(_v, 8, 0, currVal_4); var currVal_5 = ((_co.value >= 0) && !_co.useCustomDisplay); var currVal_6 = i0.ɵnov(_v.parent, 22); _ck(_v, 12, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabIndex; var currVal_1 = (_co.tabIndex ? "link" : null); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.barTitleCssClass; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.title; _ck(_v, 5, 0, currVal_3); }); }
function View_BarComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["\n\t\t", "%\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calculatePercentage(); _ck(_v, 2, 0, currVal_0); }); }
function View_BarComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "ms-fontColor-gray140"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["\n\t\t", "\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noDataText; _ck(_v, 2, 0, currVal_0); }); }
export function View_BarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BarComponent_1)), i0.ɵdid(2, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 14, "div", [["class", "wcd-flex-horizontal wcd-margin-xsmall-top bars-wrapper"]], [[4, "width", null], [4, "height", null]], [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 5).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 5).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 147456, null, 0, i2.TooltipDirective, [i0.ElementRef, i3.TooltipsService, i4.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["aria-roledescription", "tooltip"], ["class", "filled-bar"], ["role", "button"]], [[4, "width", null], [1, "aria-label", 0]], [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 10).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 10).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 10).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i9.ɵNgClassImpl, i9.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(9, 278528, null, 0, i9.NgClass, [i9.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(10, 147456, null, 0, i2.TooltipDirective, [i0.ElementRef, i3.TooltipsService, i4.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(12, 0, null, null, 0, "div", [["role", "presentation"]], [[4, "width", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(14, 0, null, null, 3, "div", [["aria-roledescription", "tooltip"], ["role", "button"]], [[4, "width", null], [1, "aria-label", 0]], [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 17).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 17).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 17).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 17).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i9.ɵNgClassImpl, i9.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(16, 278528, null, 0, i9.NgClass, [i9.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(17, 147456, null, 0, i2.TooltipDirective, [i0.ElementRef, i3.TooltipsService, i4.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵand(0, [["showValuePercentage", 2]], null, 0, null, View_BarComponent_6)), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵand(0, [["showNoDataText", 2]], null, 0, null, View_BarComponent_7)), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.barTooltip; _ck(_v, 5, 0, currVal_3); var currVal_6 = "filled-bar"; var currVal_7 = _co.getBackgroundColor(_co.colorName); _ck(_v, 9, 0, currVal_6, currVal_7); var currVal_8 = _co.valueTooltip(_co.value, _co.total, _co.unit, _co.valuePrefix); _ck(_v, 10, 0, currVal_8); var currVal_12 = _co.getBackgroundColor(_co.backgroundColorName); _ck(_v, 16, 0, currVal_12); var currVal_13 = _co.totalTooltip(_co.value, _co.total, _co.unit, _co.restPrefix); _ck(_v, 17, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.actualWidth; var currVal_2 = _co.actualHeight; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = ((_co.fillRatio * 100) + "%"); var currVal_5 = (_co.valueTooltip(_co.value, _co.total, _co.unit, _co.valuePrefix) || "tooltip"); _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_9 = _co.spaceBetweenBars; _ck(_v, 12, 0, currVal_9); var currVal_10 = (((1 - _co.fillRatio) * 100) + "%"); var currVal_11 = (_co.totalTooltip(_co.value, _co.total, _co.unit, _co.restPrefix) || "tooltip"); _ck(_v, 14, 0, currVal_10, currVal_11); }); }
export function View_BarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-bar", [], null, null, null, View_BarComponent_0, RenderType_BarComponent)), i0.ɵdid(1, 49152, null, 0, i10.BarComponent, [i11.KnownColorsService], null, null)], null, null); }
var BarComponentNgFactory = i0.ɵccf("wcd-bar", i10.BarComponent, View_BarComponent_Host_0, { value: "value", showValueAsPercentage: "showValueAsPercentage", total: "total", unit: "unit", icon: "icon", iconTooltip: "iconTooltip", barTitleCssClass: "barTitleCssClass", title: "title", noDataText: "noDataText", useCustomDisplay: "useCustomDisplay", valuePrefix: "valuePrefix", restPrefix: "restPrefix", fillRatio: "fillRatio", colorName: "colorName", backgroundColorName: "backgroundColorName", width: "width", height: "height", spaceBetweenBars: "spaceBetweenBars", barTooltip: "barTooltip", tabIndex: "tabIndex", valueAriaLabel: "valueAriaLabel", totalAriaLabel: "totalAriaLabel" }, { onClick: "onClick" }, []);
export { BarComponentNgFactory as BarComponentNgFactory };
