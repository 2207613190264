import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { OnChanges, TypedChanges } from '../types/angular/on-changes';

const DEFAULT_WAIT_TIME = 2000;
const DELAY_BEFORE_FOCUS = 500;
const WAIT_PER_LETTER = 85;

/**
 * This directive will focus the HTML element it is assigned to for a minimum of 2sec or according to the
 * length of the message an aria-label is assigned to the HTML element.
 */
@Directive({
	selector: '[announcerForceFocus]',
})
export class AnnouncerForceFocusDirective implements OnChanges<AnnouncerForceFocusDirective>, AfterViewInit {
	@Input()
	announcerForceFocus: boolean;

	@Input() focusableQuery: string;

	constructor(private elemRef: ElementRef, liveAnnouncer: LiveAnnouncer) {}

	ngOnChanges(changes: TypedChanges<AnnouncerForceFocusDirective>) {
		if (changes.announcerForceFocus && changes.announcerForceFocus.currentValue) {
			setTimeout(() => {
				const activeElement =
					(this.focusableQuery && (document.querySelector(this.focusableQuery) as HTMLElement)) ||
					(document.activeElement as HTMLElement);
				const ariaLabel = this.elemRef.nativeElement.getAttribute('aria-label');
				this.elemRef.nativeElement.focus();
				setTimeout(
					() => {
						activeElement.focus();
					},
					ariaLabel ? ariaLabel.length * WAIT_PER_LETTER : DEFAULT_WAIT_TIME
				);
			}, DELAY_BEFORE_FOCUS);
		}
	}

	ngAfterViewInit() {
		if (this.announcerForceFocus) {
			this.elemRef.nativeElement.focus();
		}
	}
}
