import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { AgentOnboardingStatus } from './agent-onboarding-status.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Agent endpoints management state',
	pluralName: 'Agent endpoints management state',
	endpoint: 'GetOmsProvisioningStatus',
	parseItemQuery: () => 'GetOmsProvisioningStatus',
	readonly: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.serveronboarding,
	supportedEntityGetMethods: [EntityGetMethod.getItem],
})
export class AgentEndpointsManagementState extends EntityModelBase {
	@EntityField({ data: 'OmsStatus' })
	status: AgentOnboardingStatus;

	@EntityField({ data: 'OmsWorkspaceId' })
	workspaceId: string;

	@EntityField({ data: 'OmsWorkspaceKey' })
	workspaceKey: string;
}
