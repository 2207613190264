import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('separateEmailAlias', separateEmailAlias);

function separateEmailAlias() {
	return function(input, length) {
		if (!input || length <= 0) {
			return '';
		}

		var parts = input.split('@');
		if (parts.length === 1) {
			return input;
		}

		return parts[0];
	};
}
