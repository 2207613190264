
		<div class="bars-chart" tabIndex="0" [attr.aria-label]="'charts.barsChart' | i18n">
			<ul [class.clickable]="settings.isSelectable" tabIndex="0">
				<li
					tabIndex="0"
					role="button"
					*ngFor="let item of items; trackBy: trackById"
					(click)="select.emit(item)"
					[class.with-icon]="item.image || item.icon"
				>
					<img *ngIf="item.image" [attr.src]="item.image" class="bars-chart-image" />
					<wcd-icon *ngIf="item.icon" [iconName]="item.icon" class="bars-chart-icon"></wcd-icon>
					<label
						[attr.data-object-type]="settings.objectType"
						[wcdTooltip]="item.itemNameTooltip"
						>{{ item.name }}</label
					>
					<div
						class="bars-chart-bar"
						[class.outer-value]="item.isOuterText || settings?.outerValues"
						[ngStyle]="{ width: item.relativeSize * 100 + '%', background: item.color }"
						[wcdTooltip]="item.tooltip"
					>
						<span class="bars-chart-value" tabIndex="0" [attr.aria-label]="item.valueDisplay">{{
							item.valueDisplay
						}}</span>
					</div>
				</li>
			</ul>
			<ul class="bars-chart-legend" *ngIf="settings.legend">
				<li *ngFor="let legendItem of settings.legend; trackBy: trackById">
					<wcd-icon [iconName]="'square'" [style.color]="legendItem.color"></wcd-icon>
					{{ legendItem.name }}
				</li>
			</ul>
		</div>
	