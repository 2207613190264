var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
var WebContentFilteringAccessTrend = /** @class */ (function (_super) {
    __extends(WebContentFilteringAccessTrend, _super);
    function WebContentFilteringAccessTrend() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Date' }),
        __metadata("design:type", Date)
    ], WebContentFilteringAccessTrend.prototype, "date", void 0);
    __decorate([
        EntityField({ data: 'Count' }),
        __metadata("design:type", Number)
    ], WebContentFilteringAccessTrend.prototype, "count", void 0);
    WebContentFilteringAccessTrend = __decorate([
        ValueObject({
            singularName: 'Category report access trend',
            pluralName: 'category report access trends',
            readonly: true,
        })
    ], WebContentFilteringAccessTrend);
    return WebContentFilteringAccessTrend;
}(ModelBase));
export { WebContentFilteringAccessTrend };
var WebContentFilteringDevice = /** @class */ (function (_super) {
    __extends(WebContentFilteringDevice, _super);
    function WebContentFilteringDevice() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], WebContentFilteringDevice.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'DeviceId' }),
        __metadata("design:type", String)
    ], WebContentFilteringDevice.prototype, "deviceId", void 0);
    __decorate([
        EntityField({ data: 'Count' }),
        __metadata("design:type", Number)
    ], WebContentFilteringDevice.prototype, "count", void 0);
    WebContentFilteringDevice = __decorate([
        ValueObject({
            singularName: 'Category report device',
            pluralName: 'Category report devices',
            readonly: true,
        })
    ], WebContentFilteringDevice);
    return WebContentFilteringDevice;
}(ModelBase));
export { WebContentFilteringDevice };
var WebContentFilteringDomain = /** @class */ (function (_super) {
    __extends(WebContentFilteringDomain, _super);
    function WebContentFilteringDomain() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], WebContentFilteringDomain.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'Count' }),
        __metadata("design:type", Number)
    ], WebContentFilteringDomain.prototype, "count", void 0);
    WebContentFilteringDomain = __decorate([
        ValueObject({
            singularName: 'Category report domain',
            pluralName: 'Category report domains',
            readonly: true,
        })
    ], WebContentFilteringDomain);
    return WebContentFilteringDomain;
}(ModelBase));
export { WebContentFilteringDomain };
