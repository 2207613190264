import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('domain', domainFilter);

function domainFilter() {
	return function(input, machineName) {
		if (!input) {
			return '';
		}

		const index = input.indexOf('.');
		if (index < 0) {
			if (machineName) {
				return input;
			} else {
				return '';
			}
		} else {
			if (machineName) {
				return input.substring(0, index).toLowerCase();
			}
			return input.substring(index + 1, input.length);
		}
	};
}
