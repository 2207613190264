import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { compact } from 'lodash-es';
import { dateStrToUtc } from '../utils';
import { sccHostService } from '@wcd/scc-interface';

@ApiCall({
	name: 'Email metadata',
	endpoint: 'Find/MailMetaData',
	baseUrl: sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>',
	parseQuery: (params: MailMetadataParams) => {
		const filterStrs = compact([
			params.networkMessageId ? `NetworkMessageId eq '${params.networkMessageId}'` : null,
			params.recipient ? `Recipients.LowercaseAnalyzed eq '${params.recipient.toLowerCase()}'` : null,
			'ContentType eq 1',
			params.dlExpansionHistory ? `DLExpansionHistory.LowercaseAnalyzed eq '${params.dlExpansionHistory.toLowerCase()}'` : null,
		]).join(' and ');

		return {
			params: {
				Filter: filterStrs,
				tenantId: params.tenantId,
				startTime: params.startTime && params.startTime.toISOString(),
				endTime: params.endTime && params.endTime.toISOString(),
			},
		};
	},
	parse: (data, params) => {
		return ((data && data.ResultData) || [])
			.map(r => JSON.parse(r))
			.filter(
				m =>
					!params.recipient ||
					m.Recipients.some(r => r.toLowerCase() === params.recipient.toLowerCase())
			)
			.map(r => {
				let receivedTime = r.ReceivedTime || '';
				receivedTime = dateStrToUtc(receivedTime);
				receivedTime = receivedTime ? new Date(receivedTime) : null;
				const deliveryLocation = r && (r.CurrentDeliveryLocationCode || r.DeliveryLocationCode);
				const deliveredToMailbox =
					deliveryLocation &&
					[
						DeliveryLocation.Inbox,
						DeliveryLocation.JunkFolder,
						DeliveryLocation.DeletedFolder,
					].includes(+deliveryLocation);

				const detectionMethodsI18nKeys = r && r.ThreatDetectionMethods;

				// note that 'AntispamDirection' is a number
				const directionalityI18nKey =
					(r && r.AntispamDirection && AntispamMailDirection[r.AntispamDirection]) || '';

				const potentiallyHarmfulUrls = new Map<string, UrlThreatType>();
				r.XmiInfo.MalwareUrlsDetected &&
					r.XmiInfo.MalwareUrlsDetected.forEach(url =>
						potentiallyHarmfulUrls.set(url, UrlThreatType.Malware)
					);
				r.XmiInfo.PhishUrlsDetected &&
					r.XmiInfo.PhishUrlsDetected.forEach(url =>
						potentiallyHarmfulUrls.set(url, UrlThreatType.Phish)
					);
				r.XmiInfo.SpamUrlsDetected &&
					r.XmiInfo.SpamUrlsDetected.forEach(url =>
						potentiallyHarmfulUrls.set(url, UrlThreatType.Spam)
					);

				return {
					internetMessageId: r.InternetMessageId,
					receivedTime: receivedTime,
					currentDeliveryLocationCode: r.CurrentDeliveryLocationCode,
					latestDeliveryLocation: DeliveryLocation[r.CurrentDeliveryLocationCode],
					deliveryLocationCode: r.DeliveryLocationCode,
					originalDeliveryLocation: DeliveryLocation[r.DeliveryLocationCode],
					recipients: r.Recipients,
					subject: r.Subject,
					deliveredToMailbox: !!deliveredToMailbox,
					messageActionCode: r.MessageActionCode,
					isDelivered: !!(
						r.MessageActionCode && +r.MessageActionCode === ProtectionStatus.Delivered
					),
					detectionMethodsI18nKeys: detectionMethodsI18nKeys,
					directionalityI18nKey: directionalityI18nKey,
					returnPath: r.P1Sender,
					potentiallyHarmfulUrls: potentiallyHarmfulUrls,
					// This relates to the URL threat detection technologies
					threatFilterControls: {
						spam: r.XmiInfo.SpamFilterControl,
						malware: r.XmiInfo.MalwareFilterControl,
						phish: r.XmiInfo.PhishFilterControl,
					},
				};
			});
	},
	cache: {
		time: 1000 * 60 * 10,
		max: 10,
	},
})
export class EmailMetadataApiCall extends ApiCallModel<Array<EmailMetadata>, MailMetadataParams> {}

interface MailMetadataParams {
	tenantId: string;
	networkMessageId: string;
	recipient: string;
	startTime: Date;
	endTime: Date;
	dlExpansionHistory: string;
}

export interface EmailMetadata {
	internetMessageId: string;
	receivedTime: Date;
	currentDeliveryLocationCode: number;
	latestDeliveryLocation: string;
	deliveryLocationCode: number;
	originalDeliveryLocation: string;
	recipients: Array<string>;
	subject: string;
	messageActionCode: number;
	isDelivered: boolean;
	deliveredToMailbox: boolean;
	detectionMethodsI18nKeys: Array<string>;
	directionalityI18nKey: string;
	returnPath: string;
	potentiallyHarmfulUrls: Map<string, UrlThreatType>; // is mapping of url to threatType
	threatFilterControls: ThreatFilterControls;
}

export interface ThreatFilterControls {
	spam: string;
	malware: string;
	phish: string;
}

enum DeliveryLocation {
	Unknown = 0,
	Inbox = 1,
	JunkFolder = 2,
	DeletedFolder = 3,
	Quarantine = 4,
	External = 5,
	Failed = 6,
	Dropped = 7,
	Forwarded = 8,
}

enum ProtectionStatus {
	Unknown = 0,
	DeliveredAsSpam = 1,
	Delivered = 2,
	Blocked = 3,
	Replaced = 4,
	TimeTraveled = 99,
}

enum AntispamMailDirection {
	// Unknown enumeration value
	unknown = 0,

	// Inbound mail
	inbound = 1,

	// Outbound mail
	outbound = 2,

	// Internal mail
	intraorg = 3,
}

export enum UrlThreatType {
	Spam,
	Phish,
	Malware,
}
