import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { airsEntityDetectionSourceTypes } from './evidence-detection-source-type.values';

@Entity({
	singularName: 'Detection Source Type',
	pluralName: 'Detection Source Types',
	values: airsEntityDetectionSourceTypes,
})
export class EvidenceDetectionSourceType extends EntityModelBase {
	@EntityField() name: string;

	@EntityField() iconName: string;

	@EntityField() entityTypeId: string;
}
