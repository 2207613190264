import { Component, Input } from '@angular/core';

@Component({
	selector: 'evidence-status-details',
	template: `
		<ul
			class="field-value-list inline-list with-bullets"
			[ngClass]="{ unstyled: (items?.length || 0) <= 1 }"
		>
			<li *ngFor="let item of items">
				{{ item }}
			</li>
		</ul>
	`,
})
export class EvidenceStatusDetailsComponent {
	@Input() items: Array<string>;
}
