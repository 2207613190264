import { OnDestroy } from '@angular/core';
import { DetectionTestSettingsService, } from '../../services/endpoint-management-detection-test-settings.service';
import { EndpointManagementSensePackagesService } from '../../services/endpoint-management-sense-packages.service';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { SenseEndpointsManagementState } from '@wcd/domain';
import { EndpointManagementService } from '../../services/endpoint-management.service';
import { MANAGEMENT_FLOWS, EndpointManagementProcessesService, } from '../../services/endpoint-management-processes.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EndpointManagementProcess, } from '../../models/endpoint-management-process.model';
import { I18nService } from '@wcd/i18n';
var SenseOnboardingComponent = /** @class */ (function () {
    function SenseOnboardingComponent(endpointManagementService, endpointManagementProcessesService, packagesService, detectionTestSettingsService, dialogsService, appInsights, i18nService) {
        var _this = this;
        this.endpointManagementService = endpointManagementService;
        this.endpointManagementProcessesService = endpointManagementProcessesService;
        this.packagesService = packagesService;
        this.detectionTestSettingsService = detectionTestSettingsService;
        this.dialogsService = dialogsService;
        this.appInsights = appInsights;
        this.i18nService = i18nService;
        this.handleErrorAsIncompleteStatus = false;
        this.loading = true;
        this.error = false;
        this.onboardingState = new SenseEndpointsManagementState(null);
        this.detectionTestSettings = this.detectionTestSettingsService.all.sense;
        this.packages = this.packagesService.getEnabledPackages(MANAGEMENT_FLOWS.onboarding, this.endpointManagementProcessesService.default);
        this.currentMethod = this.packages && this.packages[0];
        this.isDownlevel = false;
        this.getOnboardingStateSubscription = this.endpointManagementService
            .getSenseOnboardingState()
            .subscribe(function (state) {
            _this.loading = false;
            _this.setState(state);
        }, function (error) {
            _this.loading = false;
            if (_this.handleErrorAsIncompleteStatus) {
                _this.onboardingState.ranTestAlert = false;
                _this.onboardingState.machineOnboarded = false;
            }
            else {
                _this.error = true;
            }
            _this.appInsights.trackEvent('EndpointOnboarding-Status', {
                ResponseStatus: String(error.status),
                FailureType: 'API',
                RanTestAlert: String(_this.onboardingState.ranTestAlert),
                MachineOnboarded: String(_this.onboardingState.machineOnboarded),
            });
        });
        this.getDeploymentMethodLabel = this.getDeploymentMethodLabel.bind(this);
    }
    Object.defineProperty(SenseOnboardingComponent.prototype, "osType", {
        set: function (process) {
            this.packages = this.packagesService.getEnabledPackages(MANAGEMENT_FLOWS.onboarding, process);
            this.currentMethod = this.packages[0];
            this.isDownlevel = process.isDownlevel;
        },
        enumerable: true,
        configurable: true
    });
    SenseOnboardingComponent.prototype.ngOnDestroy = function () {
        this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
    };
    SenseOnboardingComponent.prototype.getDeploymentMethodLabel = function (managementPackage) {
        return this.i18nService.get('endpointManagement.deploymentMethods.' + managementPackage.name + '.title');
    };
    SenseOnboardingComponent.prototype.downloadPackage = function () {
        var _this = this;
        this.endpointManagementService.downloadSensePackage(true, this.currentMethod.id).subscribe(function (res) {
            _this.appInsights.trackEvent('EndpointOnboarding-DownloadPackage', {
                ResponseStatus: String(200),
                RanTestAlert: String(_this.onboardingState.ranTestAlert),
                MachineOnboarded: String(_this.onboardingState.machineOnboarded),
            });
            window.location.href = res;
        }, function (error) {
            _this.dialogsService.showError({
                title: _this.i18nService.get('endpointManagement.download.package.failed'),
                data: error,
            });
            _this.appInsights.trackEvent('EndpointOnboarding-DownloadPackage', {
                ResponseStatus: String(error.status),
                FailureType: 'API',
                RanTestAlert: String(_this.onboardingState.ranTestAlert),
                MachineOnboarded: String(_this.onboardingState.machineOnboarded),
            });
        });
    };
    SenseOnboardingComponent.prototype.setState = function (state) {
        this.onboardingState = state;
        this.appInsights.trackEvent('EndpointOnboarding-Status', {
            RanTestAlert: String(this.onboardingState.ranTestAlert),
            MachineOnboarded: String(this.onboardingState.machineOnboarded),
        });
        if (this.onboardingState.machineOnboarded && this.onboardingState.ranTestAlert) {
            this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
        }
    };
    SenseOnboardingComponent.prototype.downloadInstallationPackage = function () {
        window.location.href =
            'https://go.microsoft.com/fwlink/?linkid=2168294';
    };
    return SenseOnboardingComponent;
}());
export { SenseOnboardingComponent };
