var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel, DataQuerySortDirection } from '@microsoft/paris';
import { convertOSPlatformParameter, InvestigationStatusToFilterQueryString, severityValues, } from '@wcd/domain';
import { flatMap, omit } from 'lodash-es';
var parseProp = function (obj, prop) { return obj[prop] ? obj[prop] = JSON.parse(obj[prop]) : null; };
var ɵ0 = parseProp;
var convertTag = function (options) {
    var tagValue = options.tag;
    if (tagValue && tagValue instanceof Array) {
        var newTag = tagValue[0].split(',');
        return __assign({}, options, { tag: newTag });
    }
    return options;
};
var ɵ1 = convertTag;
var convertInvestigationStatusParameter = function (options) {
    var investigationStatesValue = options.investigationStates;
    if (investigationStatesValue && typeof investigationStatesValue === 'string') {
        var values = investigationStatesValue.split(',');
        var parsedValues = values.map(function (val) { return parseInt(InvestigationStatusToFilterQueryString[val], 10); });
        var transformedValues = flatMap(parsedValues, function (val) {
            return val === InvestigationStatusToFilterQueryString.Pending
                ? [
                    InvestigationStatusToFilterQueryString.PendingApproval,
                    InvestigationStatusToFilterQueryString.PendingResource,
                ]
                : [val];
        });
        return __assign({}, options, { investigationStates: transformedValues });
    }
    return options;
};
var ɵ2 = convertInvestigationStatusParameter;
var convertToNumbers = function (options, prop) {
    var _a;
    var value = options[prop];
    if (value && value instanceof Array) {
        var array = value.map(function (x) { return parseInt(x); });
        return __assign({}, options, (_a = {}, _a[prop] = array, _a));
    }
    return options;
};
var ɵ3 = convertToNumbers;
var convertSeverityToNumbers = function (options, prop) {
    var _a;
    var value = options[prop];
    if (value && value instanceof Array) {
        var array = value.map(function (x) {
            var severityEnum = severityValues.find(function (severity) { return severity.name == x && severity.isSelectable; });
            return severityEnum && severityEnum.id || parseInt(x);
        });
        return __assign({}, options, (_a = {}, _a[prop] = array, _a));
    }
    return options;
};
var ɵ4 = convertSeverityToNumbers;
var convertPagingOrdering = function (options) {
    options.pageIndex = options.page;
    if (!options.sortBy) {
        return options;
    }
    var sort = options.sortBy[0];
    var sortOrder = sort.direction === DataQuerySortDirection.descending ? 'Descending' : 'Ascending';
    var sortByField = sort.field;
    var result = omit(__assign({}, options, { sortOrder: sortOrder, sortByField: sortByField }), ['ordering', 'sortBy']);
    return result;
};
var ɵ5 = convertPagingOrdering;
// This function and all related functions, handle type conversions to be aligned with the HTTP post API call.
var convertData = function (dataOptions) {
    var where = dataOptions.where;
    var options = __assign({}, dataOptions, where);
    convertOSPlatformParameter(options);
    parseProp(options, 'serviceSources');
    options = convertTag(options);
    options = convertToNumbers(options, 'rbacGroup');
    options = convertSeverityToNumbers(options, 'severity');
    options = convertPagingOrdering(options);
    options = convertInvestigationStatusParameter(options);
    return omit(options, ['page_size', 'page', 'where']);
};
var ɵ6 = convertData;
var ɵ7 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ8 = function (optionsData) { return ({
    data: convertData(optionsData)
}); }, ɵ9 = function (data) {
    return {
        dataset: data.Items,
        count: data.TotalResults,
    };
};
var RelatedAlertsApiCall = /** @class */ (function (_super) {
    __extends(RelatedAlertsApiCall, _super);
    function RelatedAlertsApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RelatedAlertsApiCall = __decorate([
        ApiCall({
            name: 'AssociatedAlerts',
            endpoint: 'AssociatedAlerts',
            method: 'POST',
            baseUrl: ɵ7,
            parseQuery: ɵ8,
            parse: ɵ9,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
        })
    ], RelatedAlertsApiCall);
    return RelatedAlertsApiCall;
}(ApiCallModel));
export { RelatedAlertsApiCall };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
