<div class="wcd-full-height wcd-padding-vertical wcd-padding-large-horizontal" data-track-component="IncidentGraphNode">
	<section class="wcd-margin-large-bottom">
		<h4>{{'incidents.graph.grouping.groupedBy' | i18n}}</h4>
		<ul class="inline-list wcd-margin-top">
			<li *ngFor="let grouping of incidentGraphNode.groupingLogics">
				<ng-container *ngIf="grouping.isSameValue; else similar">{{'incidents.graph.grouping.same' | i18n}}</ng-container>
				<ng-template #similar>{{'incidents.graph.grouping.similar' | i18n}}</ng-template>
				{{('incidents.graph.grouping.types.' + grouping.type) | i18n}}
			</li>
		</ul>
	</section>
	<section>
		<h4>Children ({{incidentGraphNode.childNodes.length}})</h4>
		<table class="wcd-table wcd-full-width">
			<thead>
				<tr>
					<th>Type</th>
					<th>Name</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let childNode of incidentGraphNode.childNodes">
					<td>
						 <wcd-shared-icon class="nudge-up" [iconName]="childNode.entityType.iconName"> </wcd-shared-icon >
						{{childNode.entityType.name}}
					</td>
					<td>
						<button
							data-track-id="ShowChildNode"
							data-track-type="Button"
							class="btn-link"
							(click)="showChildNode(childNode)">
							{{childNode.name}}
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</section>
</div>
