var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { AlertClassification } from '../alert-classification.entity';
import { AlertDetermination } from '../alert-determination.entity';
import { AlertStatus } from '../alert-status/alert-status.entity';
import { AlertHistoryItemType } from './alert-history-item-type.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.auditHistory; }, ɵ1 = function (data) { return ({
    items: data,
    count: data.length
}); }, ɵ2 = ['AuditId', 'auditId'], ɵ3 = ['Type', 'type'], ɵ4 = ['Timestamp', 'timestamp'], ɵ5 = ['CreatedBy', 'createdBy'], ɵ6 = ['OldValue', 'oldValue'], ɵ7 = ['NewValue', 'newValue'], ɵ8 = ['OldValue', 'oldValue'], ɵ9 = function (oldValue, data) { return (data.Type === 'Status' ? parseInt(oldValue) : null); }, ɵ10 = ['OldValue', 'oldValue'], ɵ11 = function (oldValue, data) { return (data.Type === 'Determination' ? oldValue : null); }, ɵ12 = ['OldValue', 'oldValue'], ɵ13 = function (oldValue, data) { return (data.Type === 'Classification' ? oldValue : null); }, ɵ14 = ['NewValue', 'newValue'], ɵ15 = function (oldValue, data) { return (data.Type === 'Status' ? parseInt(oldValue) : null); }, ɵ16 = ['NewValue', 'newValue'], ɵ17 = function (oldValue, data) { return (data.Type === 'Determination' ? oldValue : null); }, ɵ18 = ['NewValue', 'newValue'], ɵ19 = function (oldValue, data) { return (data.Type === 'Classification' ? oldValue : null); }, ɵ20 = ['EntityType', 'entityType'];
var AlertHistoryItem = /** @class */ (function (_super) {
    __extends(AlertHistoryItem, _super);
    function AlertHistoryItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: ɵ2 }),
        __metadata("design:type", String)
    ], AlertHistoryItem.prototype, "id", void 0);
    __decorate([
        EntityField({ data: ɵ3 }),
        __metadata("design:type", AlertHistoryItemType)
    ], AlertHistoryItem.prototype, "type", void 0);
    __decorate([
        EntityField({ data: ɵ4 }),
        __metadata("design:type", Date)
    ], AlertHistoryItem.prototype, "timestamp", void 0);
    __decorate([
        EntityField({ data: ɵ5 }),
        __metadata("design:type", String)
    ], AlertHistoryItem.prototype, "userName", void 0);
    __decorate([
        EntityField({ data: ɵ6 }),
        __metadata("design:type", Object)
    ], AlertHistoryItem.prototype, "oldValue", void 0);
    __decorate([
        EntityField({ data: ɵ7 }),
        __metadata("design:type", Object)
    ], AlertHistoryItem.prototype, "newValue", void 0);
    __decorate([
        EntityField({
            data: ɵ8,
            parse: ɵ9,
        }),
        __metadata("design:type", AlertStatus)
    ], AlertHistoryItem.prototype, "oldStatus", void 0);
    __decorate([
        EntityField({
            data: ɵ10,
            parse: ɵ11,
        }),
        __metadata("design:type", AlertDetermination)
    ], AlertHistoryItem.prototype, "oldDetermination", void 0);
    __decorate([
        EntityField({
            data: ɵ12,
            parse: ɵ13,
        }),
        __metadata("design:type", AlertClassification)
    ], AlertHistoryItem.prototype, "oldClassification", void 0);
    __decorate([
        EntityField({
            data: ɵ14,
            parse: ɵ15,
        }),
        __metadata("design:type", AlertStatus)
    ], AlertHistoryItem.prototype, "newStatus", void 0);
    __decorate([
        EntityField({
            data: ɵ16,
            parse: ɵ17,
        }),
        __metadata("design:type", AlertDetermination)
    ], AlertHistoryItem.prototype, "newDetermination", void 0);
    __decorate([
        EntityField({
            data: ɵ18,
            parse: ɵ19,
        }),
        __metadata("design:type", AlertClassification)
    ], AlertHistoryItem.prototype, "newClassification", void 0);
    __decorate([
        EntityField({ data: ɵ20 }),
        __metadata("design:type", String)
    ], AlertHistoryItem.prototype, "entityType", void 0);
    AlertHistoryItem = __decorate([
        Entity({
            singularName: 'Alert History Item',
            pluralName: 'Alert History Items',
            endpoint: 'AuditHistory',
            fixedData: { pageIndex: 1, pageSize: 100 },
            allItemsEndpointTrailingSlash: false,
            supportedEntityGetMethods: [EntityGetMethod.query],
            baseUrl: ɵ0,
            parseData: ɵ1
        })
    ], AlertHistoryItem);
    return AlertHistoryItem;
}(EntityModelBase));
export { AlertHistoryItem };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20 };
