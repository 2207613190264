var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { SecurityRecommendation } from './security-recommendation.entity';
import { Asset } from '../asset/asset.entity';
import { RecommendationContextKeyAndExposedMachinesCount } from '../recommendation-context/recommendation-context-key-and-exposed-machines-count.entity';
import { RecommendationContextProperties } from '../recommendation-context/recommendation-context-properties.entity';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
import { TvmAnalyticsSharedEntityRelationshipConfigurations, FilterByNameInsteadOfSearch, } from '../analyticsEndPointUtils';
import { MitigatedVulnerability } from '../weakness/vulnerability/mitigated-vulnerability.entity';
import { SoftwareInstallationAgg } from '../software-installation/software-installation-agg-versions.entity';
import { NetworkDevice } from '../networks-devices/network-device.entity';
import { ExposedOperatingSystemsStatistics } from '../common/recommendation/exposed-operating-systems-statistics.value-object';
import { EvidenceDistribution } from '../evidence/evidence-distribution.entity';
import { AssetRecommendation, AssetRecommendationContext, TvmRemediationSharedEntityConfigurations, AddODataFilterQueryParam, ParseDataQuery, RecommendationExceptionByIdMap, RecommendationException, RemediationTask, RemediationTaskByIdMap, } from '@wcd/domain';
import { SoftwareInstallationNetworkDevice } from '../software-installation/software-installation-network-device.entity';
var ɵ0 = function () { return "recommendations/recommendation/operatingSystems"; }, ɵ1 = function (securityRecommendation) { return ({
    recommendationId: securityRecommendation.id,
}); }, ɵ2 = function (dataQuery) { return ParseDataQuery(dataQuery, undefined, true); };
var RecommendationExposedOperatingSystemsRelationship = /** @class */ (function () {
    function RecommendationExposedOperatingSystemsRelationship() {
    }
    RecommendationExposedOperatingSystemsRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: SecurityRecommendation, dataEntity: ExposedOperatingSystemsStatistics, endpoint: ɵ0, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ1, parseDataQuery: ɵ2 }))
    ], RecommendationExposedOperatingSystemsRelationship);
    return RecommendationExposedOperatingSystemsRelationship;
}());
export { RecommendationExposedOperatingSystemsRelationship };
var ɵ3 = function (_, query) {
    if (query.where['extraQueryData'] &&
        query.where['extraQueryData'].isRecommendationContextNeededInCSV) {
        return "recommendations/recommendation/assetsFlattenWithContextItem";
    }
    return "recommendations/recommendation/assets";
}, ɵ4 = function (securityRecommendation) {
    return { recommendationId: securityRecommendation.id };
}, ɵ5 = function (dataQuery) {
    // when searching for recommendation assets we want to use OData filters
    dataQuery = FilterByNameInsteadOfSearch(dataQuery);
    return ParseDataQuery(dataQuery);
};
var RecommendationExposedAssetsRelationship = /** @class */ (function () {
    function RecommendationExposedAssetsRelationship() {
    }
    RecommendationExposedAssetsRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: SecurityRecommendation, dataEntity: Asset, endpoint: ɵ3, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ4, parseDataQuery: ɵ5 }))
    ], RecommendationExposedAssetsRelationship);
    return RecommendationExposedAssetsRelationship;
}());
export { RecommendationExposedAssetsRelationship };
var ɵ6 = function () { return 'recommendations/recommendation/evidenceDistribution'; }, ɵ7 = function (securityRecommendation) {
    return { recommendationId: securityRecommendation.id };
};
var RecommendationEvidenceDistributionRelationship = /** @class */ (function () {
    function RecommendationEvidenceDistributionRelationship() {
    }
    RecommendationEvidenceDistributionRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: SecurityRecommendation, dataEntity: EvidenceDistribution, endpoint: ɵ6, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ7 }))
    ], RecommendationEvidenceDistributionRelationship);
    return RecommendationEvidenceDistributionRelationship;
}());
export { RecommendationEvidenceDistributionRelationship };
var ɵ8 = function (_, query) {
    return "configurations/" + query.where['scId'] + "/context/assetsCountByKey";
}, ɵ9 = function (securityRecommendation) {
    return { scId: securityRecommendation.scId };
};
var RecommendationContextKeyAndExposedMachinesCountRelationship = /** @class */ (function () {
    function RecommendationContextKeyAndExposedMachinesCountRelationship() {
    }
    RecommendationContextKeyAndExposedMachinesCountRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: SecurityRecommendation, dataEntity: RecommendationContextKeyAndExposedMachinesCount, endpoint: ɵ8, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ9 }))
    ], RecommendationContextKeyAndExposedMachinesCountRelationship);
    return RecommendationContextKeyAndExposedMachinesCountRelationship;
}());
export { RecommendationContextKeyAndExposedMachinesCountRelationship };
var ɵ10 = function (_, query) {
    return "configurations/" + query.where['scId'] + "/context/" + query.where['contextKey'] + "/assets";
}, ɵ11 = function (recommendationContextKey) {
    return { scId: recommendationContextKey.scId, contextKey: recommendationContextKey.contextKey };
};
var RecommendationContextPropertiesRelationship = /** @class */ (function () {
    function RecommendationContextPropertiesRelationship() {
    }
    RecommendationContextPropertiesRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: RecommendationContextKeyAndExposedMachinesCount, dataEntity: RecommendationContextProperties, endpoint: ɵ10, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ11 }))
    ], RecommendationContextPropertiesRelationship);
    return RecommendationContextPropertiesRelationship;
}());
export { RecommendationContextPropertiesRelationship };
var ɵ12 = function (_, query) {
    return "configurations/" + query.where['scId'] + "/assets/" + query.where['assetId'] + "/context";
}, ɵ13 = function (assetRecommendation) {
    return { scId: assetRecommendation.scId, assetId: assetRecommendation.assetId };
};
var AssetRecommendationContextRelationship = /** @class */ (function () {
    function AssetRecommendationContextRelationship() {
    }
    AssetRecommendationContextRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: AssetRecommendation, dataEntity: AssetRecommendationContext, endpoint: ɵ12, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ13 }))
    ], AssetRecommendationContextRelationship);
    return AssetRecommendationContextRelationship;
}());
export { AssetRecommendationContextRelationship };
var ɵ14 = function (_, query) {
    return "recommendations/recommendation/assets/" + query.where['assetId'] + "/evidenceDistribution";
}, ɵ15 = function (ed) {
    return { recommendationId: ed.id, assetId: ed.assetId };
};
var AssetRecommendationEvidenceDistributionRelationship = /** @class */ (function () {
    function AssetRecommendationEvidenceDistributionRelationship() {
    }
    AssetRecommendationEvidenceDistributionRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: AssetRecommendation, dataEntity: EvidenceDistribution, endpoint: ɵ14, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ15 }))
    ], AssetRecommendationEvidenceDistributionRelationship);
    return AssetRecommendationEvidenceDistributionRelationship;
}());
export { AssetRecommendationEvidenceDistributionRelationship };
var ɵ16 = function () { return "products/product/installations/aggregate"; }, ɵ17 = function (securityRecommendation) {
    return { productId: securityRecommendation.productId };
};
var RecommendationInstalledAssetsRelationship = /** @class */ (function () {
    function RecommendationInstalledAssetsRelationship() {
    }
    RecommendationInstalledAssetsRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: SecurityRecommendation, dataEntity: SoftwareInstallationAgg, endpoint: ɵ16, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ17 }))
    ], RecommendationInstalledAssetsRelationship);
    return RecommendationInstalledAssetsRelationship;
}());
export { RecommendationInstalledAssetsRelationship };
var ɵ18 = function (_, query) {
    return query.where['assetId']
        ? "assets/" + encodeURIComponent(query.where['assetId']) + "/recommendations/vulnerabilities"
        : "products/product/vulnerabilities";
}, ɵ19 = function (recommendation) {
    return {
        productId: recommendation.productId,
        assetId: recommendation instanceof AssetRecommendation ? recommendation.assetId : undefined,
    };
}, ɵ20 = function (dataQuery) { return ParseDataQuery(dataQuery); };
var RecommendationRelatedVulnerabilitiesRelationship = /** @class */ (function () {
    function RecommendationRelatedVulnerabilitiesRelationship() {
    }
    RecommendationRelatedVulnerabilitiesRelationship = __decorate([
        EntityRelationship({
            sourceEntity: SecurityRecommendation,
            dataEntity: Vulnerability,
            endpoint: ɵ18,
            allowedTypes: [RelationshipType.OneToMany],
            getRelationshipData: ɵ19,
            parseDataQuery: ɵ20,
        })
    ], RecommendationRelatedVulnerabilitiesRelationship);
    return RecommendationRelatedVulnerabilitiesRelationship;
}());
export { RecommendationRelatedVulnerabilitiesRelationship };
var ɵ21 = function (_, query) { return "configurations/" + query.where['id'] + "/mitigatedVulnerabilities"; }, ɵ22 = function (securityRecommendation) {
    return { id: securityRecommendation.scId };
};
var RecommendatioMitigatedVulnerabilitiesRelationship = /** @class */ (function () {
    function RecommendatioMitigatedVulnerabilitiesRelationship() {
    }
    RecommendatioMitigatedVulnerabilitiesRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: SecurityRecommendation, dataEntity: MitigatedVulnerability, endpoint: ɵ21, getRelationshipData: ɵ22, allowedTypes: [RelationshipType.OneToMany] }))
    ], RecommendatioMitigatedVulnerabilitiesRelationship);
    return RecommendatioMitigatedVulnerabilitiesRelationship;
}());
export { RecommendatioMitigatedVulnerabilitiesRelationship };
var ɵ23 = function () { return "recommendationExceptions"; }, ɵ24 = function (dataQuery) {
    var queryParams = {};
    AddODataFilterQueryParam(dataQuery, queryParams, RecommendationExceptionByIdMap);
    return queryParams;
}, ɵ25 = function (securityRecommendation) {
    return {
        id: securityRecommendation.scId
            ? securityRecommendation.scId
            : securityRecommendation.productId + "-_-" + securityRecommendation.remediationType,
    };
};
var RecommendationToExceptionsRelationship = /** @class */ (function () {
    function RecommendationToExceptionsRelationship() {
    }
    RecommendationToExceptionsRelationship = __decorate([
        EntityRelationship(__assign({}, TvmRemediationSharedEntityConfigurations, { sourceEntity: SecurityRecommendation, dataEntity: RecommendationException, endpoint: ɵ23, parseDataQuery: ɵ24, getRelationshipData: ɵ25, allowedTypes: [RelationshipType.OneToMany] }))
    ], RecommendationToExceptionsRelationship);
    return RecommendationToExceptionsRelationship;
}());
export { RecommendationToExceptionsRelationship };
var ɵ26 = function (_, _query) { return "remediationTasks"; }, ɵ27 = function (dataQuery) {
    var queryParams = {};
    AddODataFilterQueryParam(dataQuery, queryParams, RemediationTaskByIdMap);
    return queryParams;
}, ɵ28 = function (securityRecommendation) { return ({
    id: securityRecommendation.scId ? securityRecommendation.scId : securityRecommendation.productId,
}); };
var RecommendationToRemediationRelationship = /** @class */ (function () {
    function RecommendationToRemediationRelationship() {
    }
    RecommendationToRemediationRelationship = __decorate([
        EntityRelationship(__assign({}, TvmRemediationSharedEntityConfigurations, { sourceEntity: SecurityRecommendation, dataEntity: RemediationTask, endpoint: ɵ26, parseDataQuery: ɵ27, getRelationshipData: ɵ28, allowedTypes: [RelationshipType.OneToMany] }))
    ], RecommendationToRemediationRelationship);
    return RecommendationToRemediationRelationship;
}());
export { RecommendationToRemediationRelationship };
var ɵ29 = function () { return "recommendations/recommendation/networkDevices"; }, ɵ30 = function (securityRecommendation) { return ({
    recommendationId: securityRecommendation.id,
}); };
var RecommendationExposedNetworkDeviceRelationship = /** @class */ (function () {
    function RecommendationExposedNetworkDeviceRelationship() {
    }
    RecommendationExposedNetworkDeviceRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: SecurityRecommendation, dataEntity: NetworkDevice, endpoint: ɵ29, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ30 }))
    ], RecommendationExposedNetworkDeviceRelationship);
    return RecommendationExposedNetworkDeviceRelationship;
}());
export { RecommendationExposedNetworkDeviceRelationship };
var ɵ31 = function () { return "products/product/installations/networkDevices"; }, ɵ32 = function (securityRecommendation) { return ({
    productId: securityRecommendation.productId,
}); };
var RecommendationInstalledNetworkDevicesRelationship = /** @class */ (function () {
    function RecommendationInstalledNetworkDevicesRelationship() {
    }
    RecommendationInstalledNetworkDevicesRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: SecurityRecommendation, dataEntity: SoftwareInstallationNetworkDevice, endpoint: ɵ31, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ32 }))
    ], RecommendationInstalledNetworkDevicesRelationship);
    return RecommendationInstalledNetworkDevicesRelationship;
}());
export { RecommendationInstalledNetworkDevicesRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32 };
