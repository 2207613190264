import { AirsEntityTypeValue } from '../airs-entity-type.entity';
import { MailClusterUtils } from '../../mailbox/mail-cluster.utils';
import { MailClusterEntityAlertV3Fields } from './converters/airs-entity.email-cluster.converter';
import { MailMessageEntityAlertV3Fields } from './converters/airs-entity.mail-message.converter';
import { MailboxEntityAlertV3Fields } from './converters/airs-entity.mailbox.converter';
import { UrlEntityAlertV3Fields } from './converters/airs-entity.url.converter';
import { IpEntityAlertV3Fields } from './converters/airs-entity.ip.converter';
import { FileEntityAlertV3Fields } from './converters/airs-entity.file.converter';
import { ProcessEntityAlertV3Fields } from './converters/airs-entity.process.converter';
import { SecurityGroupEntityAlertV3Fields } from './converters/airs-entity.security-group.converter';
import { RegistryKeyEntityAlertV3Fields } from './converters/airs-entity.registry-key.converter';
import { RegistryValueEntityAlertV3Fields } from './converters/airs-entity.registry-value.converter';
import { CloudApplicationEntityAlertV3Fields } from './converters/airs-entity.cloud-application.converter';
import { CloudLogonSessionEntityAlertV3Fields } from './converters/airs-entity.cloud-logon-session.converter';
import { OauthApplicationEntityAlertV3Fields } from './converters/airs-entity.oauth-application.converter';
import { ActiveDirectoryDomainEntityAlertV3Fields } from './converters/airs-entity.active-directory-domain.converter';

// In case of empty subject in mail message - show this text in Evidence table
const ENTITY_COLUMN_NO_SUBJECT = '(no subject)';

export const EntityDisplayNameFunction = Object.freeze({
	[AirsEntityTypeValue.MailCluster]: (entityData: { [index: string]: any }) => {
		return MailClusterUtils.getDisplayQuery(
			entityData[MailClusterEntityAlertV3Fields.ClusterBy],
			entityData[MailClusterEntityAlertV3Fields.ClusterByValue]
		);
	},
	[AirsEntityTypeValue.MailMessage]: (entityData: { [index: string]: any }) => {
		return entityData[MailMessageEntityAlertV3Fields.Subject] || ENTITY_COLUMN_NO_SUBJECT;
	},
	[AirsEntityTypeValue.SubmissionMail]: (entityData: { [index: string]: any }) => {
		return entityData[MailMessageEntityAlertV3Fields.Subject] || ENTITY_COLUMN_NO_SUBJECT;
	},
	[AirsEntityTypeValue.Mailbox]: (entityData: { [index: string]: any }) => {
		return entityData[MailboxEntityAlertV3Fields.MailboxPrimaryAddress] || '';
	},
	[AirsEntityTypeValue.MailboxConfiguration]: (entityData: { [index: string]: any }) => {
		return entityData[MailboxEntityAlertV3Fields.MailboxPrimaryAddress] || '';
	},
	[AirsEntityTypeValue.URL]: (entityData: { [index: string]: any }) => {
		return entityData[UrlEntityAlertV3Fields.Url] || '';
	},
	[AirsEntityTypeValue.IP]: (entityData: { [index: string]: any }) => {
		return entityData[IpEntityAlertV3Fields.Address] || '';
	},
	[AirsEntityTypeValue.File]: (entityData: { [index: string]: any }) => {
		return entityData[FileEntityAlertV3Fields.Name] || '';
	},
	[AirsEntityTypeValue.Process]: (entityData: { [index: string]: any }) => {
		const imageFile = entityData[ProcessEntityAlertV3Fields.ImageFile];
		const name = imageFile && imageFile.Name;
		const processId = entityData[ProcessEntityAlertV3Fields.ProcessId];

		return name && processId ? `${name} (${processId})` : name ? name : '';
	},
	[AirsEntityTypeValue.SecurityGroup]: (entityData: { [index: string]: any }) => {
		return entityData[SecurityGroupEntityAlertV3Fields.FriendlyName] || '';
	},
	[AirsEntityTypeValue.RegistryKey]: (entityData: { [index: string]: any }) => {
		return entityData[RegistryKeyEntityAlertV3Fields.Key] || '';
	},
	[AirsEntityTypeValue.RegistryValue]: (entityData: { [index: string]: any }) => {
		const valueData = entityData[RegistryValueEntityAlertV3Fields.Value];
		const valueName = entityData[RegistryValueEntityAlertV3Fields.Name];
		return valueName ? (valueData ? `${valueName}: ${valueData}` : valueName) : '';
	},
	[AirsEntityTypeValue.CloudApplication]: (entityData: { [index: string]: any }) => {
		return entityData[CloudApplicationEntityAlertV3Fields.Name] || '';
	},
	[AirsEntityTypeValue.CloudLogonSession]: (entityData: { [index: string]: any }) => {
		const accountData = entityData[CloudLogonSessionEntityAlertV3Fields.Account];
		return (accountData && accountData['DisplayName']) || '';
	},
	[AirsEntityTypeValue.OauthApplication]: (entityData: { [index: string]: any }) => {
		return entityData[OauthApplicationEntityAlertV3Fields.Name] || '';
	},
	[AirsEntityTypeValue.ActiveDirectoryDomain]: (entityData: { [index: string]: any }) => {
		return entityData[ActiveDirectoryDomainEntityAlertV3Fields.ActiveDirectoryDomainName] || '';
	},
});
