import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { OfficeIntegrationSettings, OfficeTenantUrlPrefixApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { catchError, map, mergeMap, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { AppConfigService } from '@wcd/app-config';
import { Feature, FeaturesService } from '@wcd/config';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { LocalStorageService } from '@wcd/shared';
import { tenantContextCache } from '@wcd/auth';

declare const moment: typeof import('moment');

const OFFICE_TENANT_PREFIX_LOCAL_STORAGE_KEY = 'officeTenantPrefix';

@Injectable()
export class OfficeIntegrationService implements OnDestroy {
	officeIntegrationSettings$: BehaviorSubject<OfficeIntegrationSettings> = new BehaviorSubject<
		OfficeIntegrationSettings
	>(null);
	officeIntegrationWarning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	officeIntegrationSettingsError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isIntegrationEnabled$: Observable<boolean>;
	private onDestroy$: Subject<any> = new Subject<any>();
	private officeTenantUrlPrefix: string;
	private officeIntSub: Subscription;
	constructor(
		private paris: Paris,
		private appConfigService: AppConfigService,
		private featuresService: FeaturesService,
		private appInsightsService: AppInsightsService,
		private localStorageService: LocalStorageService
	) {
		this.init();
		this.isIntegrationEnabled$ = this.isIntegrationEnabled().pipe(
			shareReplay(1),
			takeUntil(this.onDestroy$)
		);
	}

	private init() {
		if (
			!this.appConfigService.isOnboardingComplete ||
			this.appConfigService.isSuspended ||
			this.appConfigService.isDeleted ||
			!this.featuresService.isEnabled(Feature.OfficeAtpIntegration)
		) {
			return;
		}

		this.officeIntSub = (!tenantContextCache.hasMtpConsent
			? EMPTY
			: this.loadOfficeIntegrationSettings()
		).subscribe();
	}

	loadOfficeIntegrationSettings(): Observable<OfficeIntegrationSettings> {
		return this.getOfficeTenantUrlPrefix().pipe(
			mergeMap((officeTenantUrlPrefix: string) => {
				return this.paris
					.getItemById(OfficeIntegrationSettings, 1, null, {
						tenantUrlPrefix: officeTenantUrlPrefix,
					})
					.pipe(
						tap((officeIntegrationSettings: OfficeIntegrationSettings) => {
							this.officeIntegrationSettings$.next(officeIntegrationSettings);
							this.officeIntegrationSettingsError$.next(false);

							const integrationWarning: boolean = OfficeIntegrationService.isIntegrationWarning(
								officeIntegrationSettings
							);
							if (this.officeIntegrationWarning$.getValue() !== integrationWarning)
								this.officeIntegrationWarning$.next(integrationWarning);
						})
					);
			}),
			catchError(error => {
				this.officeIntegrationSettingsError$.next(true);
				return throwError(error);
			})
		) as Observable<OfficeIntegrationSettings>;
	}

	private static isIntegrationWarning(officeIntegrationSettings: OfficeIntegrationSettings): boolean {
		return (
			(officeIntegrationSettings.officeLicenseEnabled &&
				(!officeIntegrationSettings.enableOffice365Integration ||
					!officeIntegrationSettings.officeToAtpIntegrationEnabled)) ||
			(officeIntegrationSettings.enableOffice365Integration && !officeIntegrationSettings.isAuthorized)
		);
	}

	getOfficeTenantUrlPrefix(): Observable<string> {
		if (this.officeTenantUrlPrefix) {
			return of(this.officeTenantUrlPrefix);
		} else {
			const parsedOfficeTenantPrefix: {
				value: string;
				expired: Date;
			} = this.getOfficeTenantPrefixFromLocalStorage();
			if (parsedOfficeTenantPrefix && new Date(parsedOfficeTenantPrefix.expired) > new Date()) {
				this.officeTenantUrlPrefix = parsedOfficeTenantPrefix.value;
				return of(this.officeTenantUrlPrefix);
			} else {
				this.localStorageService.removeItem(OFFICE_TENANT_PREFIX_LOCAL_STORAGE_KEY);
				return this.paris.apiCall(OfficeTenantUrlPrefixApiCall).pipe(
					tap(
						tenantPrefix => {
							if (tenantPrefix) {
								this.officeTenantUrlPrefix = tenantPrefix;
								this.setOfficeTenantPrefixToLocalStorage(tenantPrefix);
							}
						},
						ex => {
							this.officeTenantUrlPrefix = '';
							this.appInsightsService.trackException(ex);
						}
					)
				);
			}
		}
	}

	private isIntegrationEnabled(): Observable<boolean> {
		if (!this.featuresService.isEnabled(Feature.OfficeAtpIntegration)) {
			return of(false);
		}

		if (this.appConfigService.isDemoTenant) {
			// integration is enabled for demo tenants
			return of(true);
		}

		return this.officeIntegrationSettings$.pipe(
			map((settings: OfficeIntegrationSettings) =>
				Boolean(settings && settings.isE2EIntegrationEnabled)
			)
		);
	}

	private getOfficeTenantPrefixFromLocalStorage() {
		const officeTenantPrefix = this.localStorageService.getItem(OFFICE_TENANT_PREFIX_LOCAL_STORAGE_KEY);
		return officeTenantPrefix && (JSON.parse(officeTenantPrefix) as { value: string; expired: Date });
	}

	private setOfficeTenantPrefixToLocalStorage(tenantPrefix: string) {
		this.localStorageService.setItem(
			OFFICE_TENANT_PREFIX_LOCAL_STORAGE_KEY,
			JSON.stringify({
				value: tenantPrefix,
				expired: moment()
					.add(12, 'hours')
					.toDate(),
			})
		);
	}

	ngOnDestroy() {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
		this.officeIntegrationSettings$.complete();
		this.officeIntegrationWarning$.complete();
		this.officeIntegrationSettingsError$.complete();
		this.officeIntSub && this.officeIntSub.unsubscribe();
	}
}
