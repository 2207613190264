import { SevilleModule } from '../../seville/seville.module';
import { SevilleUtils } from '../../common/services/seville.utils';

SevilleModule.factory('coldStorage', coldStorage);
SevilleModule.constant('coldStorageConsts', {
	MACHINE_TIMELINE_LOOKBACK: 30,
	FILE_TIMELINE_LOOKBACK: 30,
	IP_TIMELINE_LOOKBACK: 30,
	URL_TIMELINE_LOOKBACK: 30,
});

coldStorage.$inject = ['appConfig'];

function coldStorage(appConfig) {
	var isColdStorageEnabled = appConfig.hotStorageInDays > 0;
	var service = {
		overrideStartDateIfBeyondHotData: function(date, lookback) {
			// If in hot storage - query up to 30 days, but don't allow to query over cold storage at all
			// If in hot storage but a week before cold storage date cutoff - query 7 days only
			// In cold storage - query 7 days as well
			var newStartDate = new Date(date.getTime());
			newStartDate.setDate(date.getDate() - lookback);

			if (!isColdStorageEnabled) {
				return newStartDate;
			}

			var cutOffDate = this.getColdStorageStartDate();

			var weekBeforeColdStorage = new Date(cutOffDate.getTime());
			weekBeforeColdStorage.setDate(cutOffDate.getDate() + 7);

			// if the start date is before the cold storage cut off date + 7 days - query a week of data (even if still in hot storage - to start loading events from cold storage)
			if (date.getTime() <= weekBeforeColdStorage.getTime()) {
				lookback = 7;
				newStartDate = new Date(date.getTime());
				newStartDate.setDate(date.getDate() - lookback);
				return newStartDate;
			}

			// if the start date is in hot storage, (but not in cold storage cut off date + 7 days) - do not let start time get to cold storage time frame
			return new Date(Math.max(newStartDate.getTime(), cutOffDate.getTime()));
		},

		overrideEndDateIfBeyondHotData: function(date, lookForwardInDays) {
			var newStartDate = new Date(date.getTime());
			newStartDate.setDate(date.getDate() + lookForwardInDays);

			if (!isColdStorageEnabled) {
				return newStartDate;
			}

			var cutOffDate = this.getColdStorageStartDate();

			var weekIntoColdStorage = new Date(cutOffDate.getTime());
			weekIntoColdStorage.setDate(cutOffDate.getDate() - 7);

			if (date.getTime() <= weekIntoColdStorage.getTime()) {
				lookForwardInDays = 7;
				newStartDate = new Date(date.getTime());
				newStartDate.setDate(date.getDate() + lookForwardInDays);
				return newStartDate;
			}

			return newStartDate;
		},

		isLookbackDaysInColdStorage: function(lookback) {
			if (!isColdStorageEnabled) {
				return false;
			}

			return lookback > appConfig.hotStorageInDays;
		},

		isInColdStorage: function(dateToCheck) {
			if (!isColdStorageEnabled) {
				return false;
			}

			var date = new Date(dateToCheck.getTime());

			return date.getTime() < this.getColdStorageStartDate().getTime();
		},
		getColdStorageStartDate: function() {
			var d = new Date();
			SevilleUtils.date.endOfDay(d);
			d.setDate(d.getDate() - appConfig.hotStorageInDays);

			return d;
		},
	};

	return service;
}
