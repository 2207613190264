
		<ul class="nav-list">
			<li
				*ngFor="let navItem of navItems"
				[class.active]="navItem.id === currentNavItemId"
				[routerLinkActive]="currentRouteIsActive ? 'active' : ''"
			>
				<a
					(click)="!navItem.disabled && selectNavItem(navItem, $event)"
					*ngIf="navItem.routerLink; else noLink"
					[attr.data-track-id]="'NavlistLink_' + navItem.id"
					data-track-type="Navigation"
					[routerLink]="navItem.routerLink"
					[queryParams]="navItem.routerLinkQueryParams"
					[queryParamsHandling]="navItem.routerQueryParamsHandling"
					[ngClass]="getTabClassName(tab)"
				>
					<ng-container
						*ngTemplateOutlet="itemContents; context: { navItem: navItem }"
					></ng-container>
				</a>
				<ng-template #noLink>
					<a
						(click)="!navItem.disabled && selectNavItem(navItem, $event)"
						[attr.data-track-id]="'NavlistButton_' + navItem.id"
						data-track-type="Button"
						[ngClass]="getNavItemClassName(navItem)"
					>
						<ng-container
							*ngTemplateOutlet="itemContents; context: { navItem: navItem }"
						></ng-container>
					</a>
				</ng-template>
				<ng-template #itemContents let-navItem="navItem">
					<img *ngIf="navItem.image" [src]="navItem.image" />
					<wcd-shared-icon *ngIf="navItem.icon" [iconName]="navItem.icon"> </wcd-shared-icon>
					<span>{{ navItem.text || navItem.name }}</span>
					<span
						*ngIf="navItem.value !== undefined && navItem.value !== null"
						[ngClass]="navItem.valueClassName || 'navItem-value'"
						>({{ navItem.value | prettyNumber }})</span
					>
				</ng-template>
			</li>
		</ul>
	