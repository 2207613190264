var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* tslint:disable:template-click-events-have-key-events */
import { AfterViewInit, EventEmitter, OnInit } from '@angular/core';
import { AlertPageService, detailsTabSupportedEntityTypes, } from '../../services/alert-page.service';
import { TreeElementTypeEnum, GenericFieldType } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
var subTreeExpanderButtonStyle = {
    position: 'absolute',
    left: '-51px',
    top: '32px',
};
var subTreeRootExpanderButtonStyle = __assign({}, subTreeExpanderButtonStyle, { left: '-25px' });
var MultipleAlertsProcessTreeComponent = /** @class */ (function () {
    function MultipleAlertsProcessTreeComponent(alertPageService, globalEntityTypesService) {
        this.alertPageService = alertPageService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.doneRendering = new EventEmitter();
        this.TreeElementTypeEnum = TreeElementTypeEnum;
        this.genericFieldType = GenericFieldType;
        this.detailsTabSupportedEntityTypes = detailsTabSupportedEntityTypes;
        this.subTreeExpanderButtonStyle = subTreeExpanderButtonStyle;
        this.subTreeRootExpanderButtonStyle = subTreeRootExpanderButtonStyle;
        this.afterViewInitCalled = false;
        this.childrenToRender = 0;
    }
    MultipleAlertsProcessTreeComponent.prototype.ngOnInit = function () {
        for (var _i = 0, _a = this.treeElements; _i < _a.length; _i++) {
            var treeElement = _a[_i];
            treeElement.subTreeNeverLoaded = treeElement.isSubTreeMinimized;
            treeElement.detailsNeverLoaded = treeElement.isMinimized;
            if (!treeElement.isSubTreeMinimized && treeElement.lastChild != null) {
                this.childrenToRender += treeElement.children.length + 1;
            }
            if (!treeElement.isMinimized && treeElement.associatedItems != null) {
                this.childrenToRender += treeElement.associatedItems.length;
            }
        }
    };
    MultipleAlertsProcessTreeComponent.prototype.ngAfterViewInit = function () {
        this.afterViewInitCalled = true;
        if (this.childrenToRender == 0) {
            this.doneRendering.emit();
        }
    };
    MultipleAlertsProcessTreeComponent.prototype.getEntityType = function (cyberEntityType) {
        if (detailsTabSupportedEntityTypes[cyberEntityType]) {
            return this.globalEntityTypesService.getEntityType(detailsTabSupportedEntityTypes[cyberEntityType]);
        }
    };
    MultipleAlertsProcessTreeComponent.prototype.hasTooltipValue = function (valueType) {
        return (valueType == this.genericFieldType.String ||
            valueType == this.genericFieldType.DateTime ||
            valueType == this.genericFieldType.CopyPastableString ||
            valueType == this.genericFieldType.LinkableString);
    };
    MultipleAlertsProcessTreeComponent.prototype.OnDoneRendering = function () {
        if (this.childrenToRender > 0) {
            this.childrenToRender--;
            if (this.afterViewInitCalled && this.childrenToRender == 0) {
                this.doneRendering.emit();
            }
        }
    };
    MultipleAlertsProcessTreeComponent.prototype.onSubTreeMinimizedChange = function (treeElement) {
        treeElement.isSubTreeMinimized = !treeElement.isSubTreeMinimized;
        treeElement.subTreeNeverLoaded = false;
    };
    MultipleAlertsProcessTreeComponent.prototype.onTreeElementDetailsMinimizedChange = function (treeElement) {
        treeElement.isMinimized = !treeElement.isMinimized;
        treeElement.detailsNeverLoaded = false;
    };
    return MultipleAlertsProcessTreeComponent;
}());
export { MultipleAlertsProcessTreeComponent };
