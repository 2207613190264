import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { AlertsSeveritySummary } from '../../alert/alerts-severity-summary.value-object';
import { Ip } from '../ip.entity';

@EntityRelationship({
	sourceEntity: Ip,
	dataEntity: AlertsSeveritySummary,
	allowedTypes: [RelationshipType.OneToOne],
	cache: {
		time: 1000 * 60, // 1 minute
		max: 10,
	},
	endpoint: 'activeAlertsSummary',
	baseUrl: config => config.data.serviceUrls.activeAlertsSummary,
	getRelationshipData: (ip: Ip) => ({
		Ip: ip.id,
	}),
	parseDataQuery: (dataQuery: DataQuery) =>
		Object.assign({ lookingBackInDays: 180 }, dataQuery && dataQuery.where),
})
export class IpActiveAlertsSummaryRelationship
	implements EntityRelationshipRepositoryType<Ip, AlertsSeveritySummary> {}
