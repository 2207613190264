<div class="wcd-full-height wcd-full-height-from-width-800 wcd-flex-horizontal-large">
	<div
		*ngIf="isRemediationDisabled; else remediationActions"
		class="wcd-flex-1 loading-overlay wcd-full-height"
	>
		<div class="error-message">
			<wcd-shared-icon [iconName]="'error'"> </wcd-shared-icon>
			{{ pendingActionsService.remediationDisabledMessage || i18nService.strings.remediation_disabled }}
		</div>
	</div>
	<ng-template #remediationActions>
		<nav-list
			class="wcd-flex-none wcd-scroll-vertical"
			[ngClass]="navClassName"
			*ngIf="pendingActionTypes?.length"
			[navItems]="tabs"
			[currentNavItemId]="selectedRemediationActionTypeActions.remediationActionType.type"
			(select)="setTab($event.navItem)"
		></nav-list>
		<div class="wcd-flex-1 relative wcd-padding-large-left">
			<div class="wcd-flex-1 loading-overlay wcd-full-height" [hidden]="!isLoading">
				<i class="large-loader-icon"></i>
			</div>
			<div class="wcd-full-height" [hidden]="isLoading">
				<div class="wcd-flex-1 loading-overlay wcd-full-height" *ngIf="error; else noError">
					<div class="error-message" style="max-width: 50%;">
						<wcd-shared-icon [iconName]="'error'"> </wcd-shared-icon>
						Error loading pending actions. We'll try again in a few seconds.
						<p>{{ error.message }}</p>
					</div>
				</div>
				<ng-template #noError>
					<message-bar *ngIf="pollingError" [messageData]="'pending actions'"></message-bar>
					<div
						class="dashboard-nodata"
						[hidden]="
							(pendingActionTypes && pendingActionTypes.length) ||
							selectedRemediationActionTypeActions
						"
					>
						<div class="widget-nodata-message custom-icon">
							<div class="widget-nodata-message-icon">
								<wcd-shared-icon [iconName]="'check'" class="color-text-success">
								</wcd-shared-icon>
							</div>
							<span class="color-text-success">There are no pending actions! :)</span>
						</div>
					</div>
					<pending-actions-type
						*ngIf="selectedRemediationActionTypeActions"
						(refreshClick)="onRefresh()"
						[fixedOptions]="fixedOptions"
						[investigation]="investigation"
						(onAction)="pendingActionsService.resetPolling()"
						[checkNewData]="lastChange"
						[showInvestigationData]="showInvestigationData"
						[remediationActionTypeActions]="selectedRemediationActionTypeActions"
						[externalData]="selectedExternalDataActions"
						[allowStatusSplit]="allowStatusSplit"
						[huntingContext]="huntingContext"
					></pending-actions-type>
				</ng-template>
			</div>
		</div>
	</ng-template>
</div>
