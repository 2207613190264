var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LegacyUser, SeverityType } from '@wcd/domain';
import { AppConfigService, ServiceUrlsService } from '@wcd/app-config';
import { TrackingEventType } from '../../../../../insights/models/tracking-event-type.enum';
import { SeverityTypesService } from '../../../../../shared/services/severity-types.service';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { DataTableField } from '@wcd/datatable';
import { UsersService } from '../../../../../@entities/users/services/users.service';
import { I18nService } from '@wcd/i18n';
var UsersAtRiskWidget = /** @class */ (function (_super) {
    __extends(UsersAtRiskWidget, _super);
    function UsersAtRiskWidget(reportsService, serviceUrlsService, appConfigService, globalEntityTypesService, usersService, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.serviceUrlsService = serviceUrlsService;
        _this.appConfigService = appConfigService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.usersService = usersService;
        _this.i18nService = i18nService;
        _this.tableColumns = DataTableField.fromList([
            {
                id: 'userName',
                name: 'widget_title_usersAtRisk_user_name',
                icon: {
                    fabricIcon: _this.globalEntityTypesService.getEntityTypeIcon(LegacyUser),
                },
                getDisplay: function (item) {
                    return "" + (item.DomainName ? item.DomainName.toLowerCase() + '\\' : '') + item.UserName.toLowerCase();
                },
                className: 'nowrap',
                getLink: function (item) {
                    return _this.usersService.getUserUrl({
                        accountName: item.UserName,
                        accountDomainName: item.DomainName,
                        sid: item.UserSid,
                    });
                },
                getTooltip: function (item) {
                    return "" + (item.DomainName ? item.DomainName.toLowerCase() + '\\' : '') + item.UserName.toLowerCase();
                },
                fluidWidth: 1,
                custom: {
                    tracking: {
                        id: 'userLink',
                        type: TrackingEventType.Navigation,
                    },
                },
            },
            {
                id: 'highSeverityAlerts',
                name: 'widget_title_usersAtRisk_high_severity_alerts',
                getDisplay: function (item) { return item.HighSevAlerts; },
                getFieldCssClass: function () {
                    var severity = SeverityTypesService.getSeverityByType(SeverityType.High);
                    return 'color-border-' + severity.className;
                },
                className: 'nowrap legend-item-cell',
                tooltip: _this.i18nService.get('widget_title_high_severity_tooltip'),
            },
            {
                id: 'mediumSeverityAlerts',
                name: 'widget_title_usersAtRisk_medium_severity_alerts',
                getDisplay: function (item) { return item.MediumSevAlerts; },
                getFieldCssClass: function () {
                    var severity = SeverityTypesService.getSeverityByType(SeverityType.Medium);
                    return 'color-border-' + severity.className;
                },
                className: 'nowrap legend-item-cell',
                tooltip: _this.i18nService.get('widget_title_medium_severity_tooltip'),
            },
            {
                id: 'lowSeverityAlerts',
                name: 'widget_title_usersAtRisk_low_severity_alerts',
                getDisplay: function (item) { return item.LowSevAlerts; },
                getFieldCssClass: function () {
                    var severity = SeverityTypesService.getSeverityByType(SeverityType.Low);
                    return 'color-border-' + severity.className;
                },
                className: 'nowrap legend-item-cell',
                tooltip: _this.i18nService.get('widget_title_low_severity_tooltip'),
            },
            {
                id: 'infoSeverityAlerts',
                name: 'widget_title_usersAtRisk_information_severity_alerts',
                getDisplay: function (item) { return item.InfoSevAlerts; },
                getFieldCssClass: function () {
                    var severity = SeverityTypesService.getSeverityByType(SeverityType.Informational);
                    return 'color-border-' + severity.className;
                },
                className: 'nowrap legend-item-cell',
                tooltip: _this.i18nService.get('widget_title_information_severity_tooltip'),
            },
        ]);
        return _this;
    }
    Object.defineProperty(UsersAtRiskWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            var rangeInDays = this.appConfigService.widgetLookback;
            return {
                id: 'usersAtRisk',
                name: this.i18nService.get('widget_title_usersAtRisk'),
                noDataMessage: this.i18nService.get('widget_noData_usersAtRisk'),
                noDataIcon: 'users.user',
                rangeInDays: rangeInDays,
                api: {
                    url: function () { return _this.serviceUrlsService.threatIntel + "/Dashboard/GetTopAlertUsers"; },
                    isExternal: true,
                    params: { lookBackInDays: rangeInDays, readFromCache: String(true) },
                },
                parseData: this.parseData.bind(this),
            };
        },
        enumerable: true,
        configurable: true
    });
    UsersAtRiskWidget.prototype.parseData = function (data) {
        return data.Users;
    };
    return UsersAtRiskWidget;
}(ReportWidgetComponent));
export { UsersAtRiskWidget };
