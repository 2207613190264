import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService, AppConfigData } from '@wcd/app-config';
import { catchError, map } from 'rxjs/operators';
import { AppContextService } from '@wcd/config';
import { CookiesService } from '@wcd/shared';

export const LOGIN_API_URL = '/Authenticate/Login';
export const WDATP_LOGOUT_URL = '/Authenticate/LogOff';
export const WDATP_REDIRECT_TO_LOGIN_URL = '/Authenticate/RedirectToLoginPage';
export const WDATP_AUTH_VALIDATION_URL = '/api/AllowedActions';
const REFRESH_TOKEN_URL = '/Authenticate/RefreshToken';

const XSS_COOKIE = 'afc';

@Injectable()
export class AuthBackendService {
	constructor(
		private readonly http: HttpClient,
		private readonly serviceUrlsService: ServiceUrlsService,
		private readonly appContext: AppContextService,
		private readonly cookiesService: CookiesService
	) {}

	get loginUrl(): string {
		return LOGIN_API_URL;
	}

	get authValidationUrl(): string {
		return (this.serviceUrlsService.authorization || '') + WDATP_AUTH_VALIDATION_URL;
	}

	checkAuthStatus(): Observable<boolean> {
		return this.http.get(this.authValidationUrl).pipe(
			map(res => true),
			catchError(err => of(err.status !== 401))
		);
	}

	private callWithXssHeader(url: string): Observable<AppConfigData> {
		const xssToken = this.cookiesService.getCookie(XSS_COOKIE);
		return this.http.get<AppConfigData>(
			url,
			xssToken
				? {
						headers: { [XSS_COOKIE]: xssToken },
				  }
				: undefined
		);
	}

	login(): Observable<AppConfigData> {
		return this.callWithXssHeader(this.loginUrl);
	}

	refreshToken(tid: string): Observable<AppConfigData> {
		return this.callWithXssHeader(`${REFRESH_TOKEN_URL}?tid=${tid}`);
	}
}
