import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { MachineSecurityScanStatusValue } from './machine-security-scan-status-value.enum';
import { MachineSecurityScanAvModeValue } from './machine-security-scan-av-mode-value.enum';
import { MachineSecurityUpdateStatusValue } from './machine-security-update-status-value.enum';

@ValueObject({
	singularName: 'Machine Security Health Status',
	pluralName: 'Machine Security Health Statuses',
})
export class MachineSecurityHealthStatus extends ModelBase {
	@EntityField()
	osPlatform: string;
	
	@EntityField()
	avMode: MachineSecurityScanAvModeValue;

	@EntityField()
	dataRefreshTimestamp: Date;

	@EntityField()
	avSignatureVersion: string;

	@EntityField()
	avSignatureUpdateTime: Date;

	@EntityField()
	avPlatformVersion: string;

	@EntityField()
	avPlatformUpdateTime: Date;

	@EntityField()
	avEngineVersion: string;

	@EntityField()
	avEngineUpdateTime: Date;

	@EntityField()
	quickScanResult: MachineSecurityScanStatusValue;

	@EntityField()
	quickScanTime?: Date;

	@EntityField()
	fullScanResult: MachineSecurityScanStatusValue;

	@EntityField()
	fullScanTime?: Date;

	@EntityField()
	avIsSignatureUpToDate: MachineSecurityUpdateStatusValue;

	@EntityField()
	avIsEngineUpToDate: MachineSecurityUpdateStatusValue;
	
	@EntityField()
	avIsPlatformUpToDate: MachineSecurityUpdateStatusValue;
}
