
		<div
			#expenderButton
			class="expander-button wcd-flex wcd-flex-nowrap-horizontal"
			[class.expander-button--right]="arrowPosition === 'right'"
			tabindex="0"
			[attr.role]="ariaRole"
			[attr.aria-expanded]="isExpanded"
			(keyup.enter)="toggle()"
			(click)="toggle()"
			[attr.aria-controls]="regionId"
			[attr.aria-labelledby]="ariaLabel ? ariaLabel : expanderButtonLabelId"
			[attr.aria-label]="ariaLabel ? ariaLabel : null"
			[attr.id]="buttonId"
		>
			<ng-container *ngIf="!arrowPosition || arrowPosition === 'left'">
				<ng-container *ngTemplateOutlet="arrowIcon"></ng-container>
			</ng-container>
			<fab-icon *ngIf="iconName" [iconName]="iconName" role="presentation"></fab-icon>
			<label
				class="expander-button__label wcd-flex-1  wcd-padding-small-left"
				(click)="onLabelClicked($event)"
				role="presentation"
			>
				<span [ngClass]="labelClass" [attr.id]="expanderButtonLabelId">
					<ng-container *ngIf="label">
						{{ label }}
					</ng-container>
					<ng-content select="[wcd-expander-title]"></ng-content>
				</span>
				<span class="ms-fontColor-neutralTertiary expander-button__sub-label" *ngIf="subLabel">
					{{ subLabel }}
				</span>
			</label>

			<ng-container *ngIf="arrowPosition === 'right'">
				<ng-container *ngTemplateOutlet="arrowIcon"></ng-container>
			</ng-container>
		</div>

		<ng-template #arrowIcon>
			<span
				class="expander-button__icon"
				[ngClass]="{ 'expander-button__icon--expanded': isExpanded }"
				role="presentation"
			>
				<fab-icon [contentClass]="" iconName="ChevronDownMed"></fab-icon>
			</span>
		</ng-template>
	