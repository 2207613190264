import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { TvmAnalyticsApiCallSharedConfigurations } from '../../analyticsEndPointUtils';
import { ExposureScoreCategory } from '../../common/exposure-score/exposure-score-category.enum';

export enum AssetsByExposureCategoriesNonSupported {
	'OS_NOT_SUPPORTED' = 'OsNotSupported',
	'OTHER' = 'Other',
}

export type AssetsByExposureCategories = ExposureScoreCategory | AssetsByExposureCategoriesNonSupported;

export interface AssetsByCategory {
	category: AssetsByExposureCategories;
	assetCount: number;
}

enum Category {
	Supported = 'supported',
	NotSupported = 'notSupported',
}

enum Supported {
	ExposureLevel = 'exposureLevel',
	AssetCount = 'assetCount',
}

enum NotSupported {
	Reason = 'reason',
	AssetCount = 'assetCount',
}

type oldDataShape = {
	[Supported.ExposureLevel]: string;
	[Supported.AssetCount]: number;
}[];

type newDataShape = {
	[Category.Supported]: {
		[Supported.ExposureLevel]: string;
		[Supported.AssetCount]: number;
	}[];
	[Category.NotSupported]: {
		[NotSupported.Reason]: string;
		[NotSupported.AssetCount]: number;
	}[];
};

@ApiCall({
	...TvmAnalyticsApiCallSharedConfigurations,
	name: 'assets-by-exposure-category-api-call',
	method: 'GET',
	endpoint: 'assets/countByExposureLevel',
	cache: null,
	parse: (data: oldDataShape | newDataShape): AssetsByCategory[] => {
		//convert to new shape:
		if (Array.isArray(data)) {
			data = {
				[Category.Supported]: data,
				[Category.NotSupported]: [
					{
						[NotSupported.Reason]: AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED,
						[NotSupported.AssetCount]: 0,
					},
					{
						[NotSupported.Reason]: AssetsByExposureCategoriesNonSupported.OTHER,
						[NotSupported.AssetCount]: 0,
					},
				],
			};
		}

		return data[Category.Supported]
			.map(record => ({
				category: record[Supported.ExposureLevel] as AssetsByExposureCategories,
				assetCount: record[Supported.AssetCount],
			}))
			.concat(
				data[Category.NotSupported].map(record => ({
					category: record[NotSupported.Reason] as AssetsByExposureCategories,
					assetCount: record[NotSupported.AssetCount],
				}))
			);
	},
})
export class AssetsByExposureCategoryApiCall extends ApiCallModel<AssetsByCategory[]> {}
