import { IMachineRiskScore, MachineRiskScoreType } from './machine-risk-score.models';

export const machineRiskScoreValues: Array<IMachineRiskScore> = [
	{
		id: MachineRiskScoreType.None,
		name: 'No known risks',
		nameI18nKey: 'machineRiskScore_None',
		priority: 5,
	},
	{
		id: MachineRiskScoreType.Informational,
		name: 'Informational',
		nameI18nKey: 'machineRiskScore_Informational',
		priority: 4,
	},
	{
		id: MachineRiskScoreType.Low,
		name: 'Low',
		nameI18nKey: 'machineRiskScore_Low',
		priority: 3,
	},
	{
		id: MachineRiskScoreType.Medium,
		name: 'Medium',
		nameI18nKey: 'machineRiskScore_Medium',
		priority: 2,
	},
	{
		id: MachineRiskScoreType.High,
		name: 'High',
		nameI18nKey: 'machineRiskScore_High',
		priority: 1,
	},
];

export const riskScoreNumberToEntity = {
	[0]: machineRiskScoreValues[0],
	[5]: machineRiskScoreValues[1],
	[10]: machineRiskScoreValues[2],
	[20]: machineRiskScoreValues[3],
	[30]: machineRiskScoreValues[4],
}
