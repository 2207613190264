var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
import { TvmAnalyticsSharedEntityRelationshipConfigurations } from '../analyticsEndPointUtils';
import { ChangeEvent, ExposedOperatingSystemsStatistics } from '@wcd/domain';
import { SecurityRecommendation } from '../security-recommendation/security-recommendation.entity';
var ɵ0 = function (_, query) { return "recommendations?search=" + query.where['id']; }, ɵ1 = function (changeEvent) { return ({ id: changeEvent.scid }); }, ɵ2 = function (data, _, query) {
    return data.results.find(function (recommendation) { return recommendation.scId === query.where['id']; });
};
var ChangeEventRecommendationRelationship = /** @class */ (function () {
    function ChangeEventRecommendationRelationship() {
    }
    ChangeEventRecommendationRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: ChangeEvent, dataEntity: SecurityRecommendation, allItemsEndpointTrailingSlash: false, endpoint: ɵ0, allowedTypes: [RelationshipType.OneToOne], getRelationshipData: ɵ1, parseData: ɵ2 }))
    ], ChangeEventRecommendationRelationship);
    return ChangeEventRecommendationRelationship;
}());
export { ChangeEventRecommendationRelationship };
var ɵ3 = function (_, query) { return "changeEvents/" + encodeURIComponent(query.where['id']) + "/vulnerabilities"; }, ɵ4 = function (changeEvent) { return ({ id: changeEvent.id }); }, ɵ5 = function (dataQuery) {
    //TODO[omcheri]: parse query correctly once analytics endpoint is up: VSTS 24334392
    var query = TvmAnalyticsSharedEntityRelationshipConfigurations.parseDataQuery(dataQuery);
    return query;
};
var ChangeEventRelatedVulnerabilitiesRelationship = /** @class */ (function () {
    function ChangeEventRelatedVulnerabilitiesRelationship() {
    }
    ChangeEventRelatedVulnerabilitiesRelationship = __decorate([
        EntityRelationship({
            sourceEntity: ChangeEvent,
            dataEntity: Vulnerability,
            endpoint: ɵ3,
            allowedTypes: [RelationshipType.OneToMany],
            getRelationshipData: ɵ4,
            parseDataQuery: ɵ5,
        })
    ], ChangeEventRelatedVulnerabilitiesRelationship);
    return ChangeEventRelatedVulnerabilitiesRelationship;
}());
export { ChangeEventRelatedVulnerabilitiesRelationship };
var ɵ6 = function () { return "recommendations/recommendation/operatingSystems"; }, ɵ7 = function (changeEvent) { return ({ recommendationId: "sca-_-" + changeEvent.scid }); };
var ChangeEventExposedOsRelationship = /** @class */ (function () {
    function ChangeEventExposedOsRelationship() {
    }
    ChangeEventExposedOsRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: ChangeEvent, dataEntity: ExposedOperatingSystemsStatistics, endpoint: ɵ6, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ7 }))
    ], ChangeEventExposedOsRelationship);
    return ChangeEventExposedOsRelationship;
}());
export { ChangeEventExposedOsRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
