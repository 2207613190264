var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ApiCall, ApiCallModel, EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Alert } from '../alert/alert.entity';
var Alerts = /** @class */ (function (_super) {
    __extends(Alerts, _super);
    function Alerts() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ arrayOf: Alert }),
        __metadata("design:type", Array)
    ], Alerts.prototype, "alerts", void 0);
    Alerts = __decorate([
        ValueObject({
            singularName: 'Array of alerts',
            pluralName: '',
        })
    ], Alerts);
    return Alerts;
}(ModelBase));
export { Alerts };
var ɵ0 = function (alertsIds) {
    return {
        params: {
            alertsIds: alertsIds.map(function (alertId) { return 'alertsids=' + alertId; }).join('&')
        },
    };
}, ɵ1 = function (_config, query) {
    return "AlertsByIds?" + query.where['alertsIds'];
}, ɵ2 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ3 = function (rawData) { return ({ alerts: rawData }); };
// TODO: Delete this after vNext migration is complete (controlled by K8SMigrationLegacyAlertsByIds feature flag).
var GetAlertsByIdsApiCall = /** @class */ (function (_super) {
    __extends(GetAlertsByIdsApiCall, _super);
    function GetAlertsByIdsApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GetAlertsByIdsApiCall = __decorate([
        ApiCall({
            name: 'Get alerts by list of ids vnow',
            parseQuery: ɵ0,
            endpoint: ɵ1,
            baseUrl: ɵ2,
            parseData: ɵ3,
            type: Alerts,
        })
    ], GetAlertsByIdsApiCall);
    return GetAlertsByIdsApiCall;
}(ApiCallModel));
export { GetAlertsByIdsApiCall };
var ɵ4 = function (alertsIds) { return ({ data: { AlertsIds: alertsIds } }); }, ɵ5 = function (config) { return config.data.serviceUrls.alerts; }, ɵ6 = function (rawData) { return ({ alerts: rawData }); };
var GetAlertsByIdsVNextApiCall = /** @class */ (function (_super) {
    __extends(GetAlertsByIdsVNextApiCall, _super);
    function GetAlertsByIdsVNextApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GetAlertsByIdsVNextApiCall = __decorate([
        ApiCall({
            name: 'Get alerts by list of ids vnext',
            parseQuery: ɵ4,
            endpoint: 'LegacyAlertsByIds',
            method: 'POST',
            baseUrl: ɵ5,
            parseData: ɵ6,
            type: Alerts,
        })
    ], GetAlertsByIdsVNextApiCall);
    return GetAlertsByIdsVNextApiCall;
}(ApiCallModel));
export { GetAlertsByIdsVNextApiCall };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
