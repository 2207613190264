import { NgModule } from '@angular/core';
import { SecurityOperationsDashboardComponent } from './components/security-operations.dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard } from '@wcd/auth';
import { ReportsModule } from '../reports/reports.module';
import { BreadcrumbsResolver } from '../breadcrumbs/services/breadcrumbs-resolver.service';
import { SharedModule } from '../shared/shared.module';
import { MdeUserRoleActionEnum } from '@wcd/domain';
import { SharedReportsModule } from '../shared-reports/shared-reports.module';
import { ConvergencePromotionModule } from "../convergence-promotion/convergence-promotion.module"

const dashboardsRoutes: Routes = [
	{
		path: 'dashboard',
		canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
		component: SecurityOperationsDashboardComponent,
		resolve: {
			breadcrumbs: BreadcrumbsResolver,
		},
		data: {
			breadcrumbsConfig: {
				itemConfig: {
					id: `securityOperationsDashboard`,
					labelKey: 'securityOperations_title',
					icon: 'dashboard',
					url: '/dashboard',
				},
				resetListOnEnter: true,
				show: false,
			},
			pageTitle: 'Security operations dashboard',
			pageTitleIcon: 'dashboard',
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData,
			},
		},
	},
];

const declarations: any[] = [SecurityOperationsDashboardComponent];

@NgModule({
	imports: [RouterModule.forChild(dashboardsRoutes), SharedModule, ReportsModule, SharedReportsModule, ConvergencePromotionModule],
	declarations,
	exports: [RouterModule, ...declarations],
})
export class DashboardsModule {}
