import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class DetectionTestSettingsService {
	private _testsSettings: { [index: string]: TestDetectionSettings };
	SENSE_DEFAULT_TEST_SETTINGS: TestDetectionSettings;

	constructor(private i18nService: I18nService) {
		this.SENSE_DEFAULT_TEST_SETTINGS = {
			instructionsText: `
		${this.i18nService.get('endpointManagement_sense_onboarding_step2_description')}
		<ol type="a">
			<li class="wcd-margin-small-top">${this.i18nService.get(
				'endpointManagement_sense_onboarding_step2_description_a'
			)}</li>
			<li class="wcd-margin-small-top">${this.i18nService.get(
				'endpointManagement_sense_onboarding_step2_description_b'
			)}</li>
		</ol>
	`,
			resultText: this.i18nService.get('endpointManagement_sense_onboarding_step2_description_result'),
			command:
				'pow' +
				'ers' +
				'hell.exe -NoExit -Ex' +
				'ecutionPolicy Byp' +
				'ass -WindowStyle Hidden ' +
				"$ErrorActionPreference= 'silentlycontinue';" +
				'(New-Object System.Net.WebClient).Down' +
				'loa' +
				"dFile('http://127.0.0.1/1.exe', 'C:\\\\test-WDATP-test\\\\invoice.exe');Start-Process 'C:\\\\test-WDATP-test\\\\invoice.exe'",
			commandTextRows: 3,
		};
		// Without the weird string concatenation above, Sense actually detects this TypeScript file and it's deleted by our AV. :)
	}

	get all(): { [index: string]: TestDetectionSettings } {
		if (!this._testsSettings) this._setSettings();
		return this._testsSettings;
	}

	get default(): TestDetectionSettings {
		return this.all.sense;
	}

	private _setSettings() {
		this._testsSettings = Object.assign(
			{},
			{
				sense: this.SENSE_DEFAULT_TEST_SETTINGS,
				agent: Object.assign({}, this.SENSE_DEFAULT_TEST_SETTINGS, {
					resultText: this.i18nService.get(
						'endpointManagement.sense.onboarding.detection.test.result'
					),
				}),
			}
		);
	}
}

export interface TestDetectionSettings {
	instructionsText?: SafeHtml;
	resultText?: SafeHtml;
	command?: string;
	commandTitle?: string;
	commandClassName?: string;
	commandTextRows?: number;
}
