import { EntityField, EntityModelBase, ValueObject } from '@microsoft/paris';
import { EvidenceDetectionSourceType } from './evidence-detection-source-type.entity';
import { InvestigationActionStatus } from '../investigation/actions/investigation-action-status.entity';
import { Severity } from '../severity/severity.entity';

@ValueObject({
	singularName: 'Detection Source',
	pluralName: 'Detection Sources',
})
export class EvidenceDetectionSource extends EntityModelBase {
	@EntityField({ data: 'detection_source_name_key' })
	detectionNameI18nKey: string;

	@EntityField({ data: 'detection_source_name' })
	detectionName: string;

	@EntityField({ data: 'detection_source_id' })
	detectionId: string;

	@EntityField({ data: 'detection_type' })
	detectionType: EvidenceDetectionSourceType;

	@EntityField({ data: 'additional_data.action.status' })
	actionStatus?: InvestigationActionStatus;

	@EntityField({ data: 'additional_data.alert.severity' })
	alertSeverity?: Severity;
}
