import { Injectable } from '@angular/core';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { FieldsService } from '../../../global_entities/models/entity-type.interface';
import { Lazy } from '../../../utils/lazy';
import { Application } from '@wcd/domain';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { FabricIconNames } from '@wcd/scc-common';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class ApplicationFieldsService implements FieldsService<Application> {
	constructor(private i18nService: I18nService) {}

	private readonly _fields = new Lazy<ReadonlyArray<DataviewField<Application>>>(() => {
		const fields: Array<DataviewFieldConfig<Application>> = [
			{
				id: 'name',
				name: this.i18nService.strings.incident_apps_field_appName,
				className: EntityNameComponent.entityNameDefaultCssClass,
				icon: { fabricIcon: FabricIconNames.Cloud },
				component: {
					type: EntityNameComponent,
					getProps: (entity: Application) => ({
						entity,
						entityTypeId: 'app',
						entityName: entity.name,
					}),
				},
			},
			{
				id: 'id',
				name: this.i18nService.strings.incident_apps_field_applicationId,
				className: EntityNameComponent.entityNameDefaultCssClass,
				getDisplay: (application: Application) => application.id,
				sort: { enabled: false },
			},
			{
				id: 'applicationClientId',
				name: this.i18nService.strings.incident_apps_field_applicationClientId,
				className: EntityNameComponent.entityNameDefaultCssClass,
				getDisplay: (application: Application) => application.applicationClientId,
				sort: { enabled: false },
			},
			{
				id: 'risk',
				name: this.i18nService.strings.incident_apps_field_risk,
				getDisplay: (app: Application) =>
					app.riskLevel && this.i18nService.get(`incident.apps.field.risk.${app.riskLevel}`),
				className: 'nowrap',
				getCssClass: (app: Application) =>
					app.riskLevel
						? `wcd-severity wcd-severity-${app.riskLevel.toLocaleLowerCase()}`
						: 'disabled',
			},
			{
				id: 'activeAlertCount',
				name: this.i18nService.strings.incident_apps_field_incidentAlerts,
				getDisplay: (app: Application) =>
					app.totalAlerts ? `${app.activeAlerts} / ${app.totalAlerts}` : null,
				getTooltip: app => this.i18nService.get('incident_apps_field_incidentAlerts_tooltip', app),
				useDefaultEmptyFieldComponent: true,
			},
			{
				id: 'publisher',
				name: this.i18nService.strings.incident_apps_field_publisher,
				className: EntityNameComponent.entityNameDefaultCssClass,
				getDisplay: (application: Application) => application.publisher,
				sort: { enabled: false },
			},
		];

		return DataviewField.fromList<Application>(fields);
	});

	get fields(): ReadonlyArray<DataviewField<Application>> {
		return this._fields.value;
	}
}
