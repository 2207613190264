import { Injectable, InjectionToken, Injector } from '@angular/core';
import { defer, from, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Feature, FeaturesService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios-types';

// Required in order to use the original HttpClient, used in app.scc.module
export const LegacyHttpClient = new InjectionToken<HttpClient>('OriginalHttpClient');

const STARTS_WITH_BRACKETS_REGEXP = /^(<(\w+)>)[\/]+/i;

type Method = 'DELETE' | 'GET' | 'POST' | 'PUT' | 'PATCH';
type BaseHttpClientOptions<TObserve = 'body'> = {
	headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };
	observe?: TObserve;
	params?:
		| HttpParams
		| {
				[param: string]: string | string[];
		  };
	reportProgress?: boolean;
	withCredentials?: boolean;
};

type GetHttpClientOptions = BaseHttpClientOptions & { responseType?: 'json' };
type PostHttpClientOptions = BaseHttpClientOptions & { responseType?: 'json' };
type BlobPostHttpClientOptions = BaseHttpClientOptions & { responseType: 'blob' };

@Injectable({
	providedIn: 'root',
})
export class HttpClientProxy {
	// The following injections are to prevent circular dependency with Angular services
	private _http: HttpClient;
	private get http(): HttpClient {
		// since we replace the original HttpClient with this one, we can't inject HttpClient directly as
		// a dependency, and we need to go thru the injector, AFTER THE CTOR
		if (!this._http) {
			this._http = this.injector.get<HttpClient>(LegacyHttpClient);
		}

		return this._http;
	}

	private _featuresService: FeaturesService;
	private get featuresService(): FeaturesService {
		if (!this._featuresService) {
			this._featuresService = this.injector.get<FeaturesService>(FeaturesService);
		}

		return this._featuresService;
	}

	constructor(private readonly injector: Injector) {}

	get<TResponse = any>(url: string, options?: GetHttpClientOptions): Observable<TResponse> {
		if (this.shouldUseSccProxy(url)) {
			const config = HttpClientProxy.convertToRequestConfig(options);
			return HttpClientProxy.toDeferredObservableResult(() => sccHostService.ajax.get(url, config));
		}

		return this.http.get<TResponse>(url, options);
	}

	put<TResponse = any, TBody = any>(url: string, body?: TBody): Observable<TResponse> {
		if (this.shouldUseSccProxy(url)) {
			return HttpClientProxy.toDeferredObservableResult(() => sccHostService.ajax.put(url, body));
		}

		return this.http.put<TResponse>(url, body);
	}

	patch<TResponse = any, TBody = any>(url: string, body: TBody): Observable<TResponse> {
		if (this.shouldUseSccProxy(url)) {
			return HttpClientProxy.toDeferredObservableResult(() => sccHostService.ajax.patch(url, body));
		}

		return this.http.patch<TResponse>(url, body);
	}

	post<TBody = any>(url: string, body: TBody, options?: PostHttpClientOptions): Observable<Object>;
	post<TResponse = Blob, TBody = any>(
		url: string,
		body: TBody,
		options: BlobPostHttpClientOptions
	): Observable<TResponse>;
	post<TResponse = Object, TBody = any>(
		url: string,
		body: TBody,
		options?: PostHttpClientOptions | BlobPostHttpClientOptions
	): Observable<TResponse> {
		if (this.shouldUseSccProxy(url)) {
			const config = HttpClientProxy.convertToRequestConfig(options);

			return HttpClientProxy.toDeferredObservableResult(() =>
				sccHostService.ajax.post(url, body, config)
			);
		}

		return this.http.post(url, body, options) as Observable<TResponse>;
	}

	delete<TResponse = any, TBody = any>(
		url: string,
		options?: BaseHttpClientOptions
	): Observable<TResponse> {
		if (this.shouldUseSccProxy(url)) {
			const config = HttpClientProxy.convertToRequestConfig(options);
			return HttpClientProxy.toDeferredObservableResult(() => sccHostService.ajax.delete(url, config));
		}

		return this.http.delete<TResponse>(url, options);
	}

	request<TResponse = any, TData = any>(
		method: Method,
		url: string,
		options?: BaseHttpClientOptions
	): Observable<TResponse> {
		if (this.shouldUseSccProxy(url)) {
			const config = HttpClientProxy.convertToRequestConfig({ ...options, url, method });

			return HttpClientProxy.toDeferredObservableResult(() =>
				sccHostService.ajax.request(config as AxiosRequestConfig)
			);
		}

		return this.http.request<TResponse>(method, url, options);
	}

	private shouldUseSccProxy(url: string): boolean {
		return (
			this.featuresService.isEnabled(Feature.UseSccProxy) &&
			Boolean(url.match(STARTS_WITH_BRACKETS_REGEXP))
		);
	}

	private static toDeferredObservableResult<T>(promiseFactory: () => AxiosPromise<T>): Observable<T> {
		// "promiseFactory" concept is required in order not to execute the promise immediately, only when actually executing the code
		return defer(() => from(promiseFactory().then(res => HttpClientProxy.toResult<T>(res))));
	}

	private static toResult<T>(response: AxiosResponse<T>): T {
		if (!response || !response.data) {
			return null;
		}

		return response.data as T;
	}

	private static convertToRequestConfig(
		options: any & { url?: string; method?: Method }
	): Partial<AxiosRequestConfig> {
		if (!options) {
			return null;
		}

		const config: Partial<AxiosRequestConfig> = {};

		if (options.url) {
			config.url = options.url;
		}
		if (options.method) {
			config.method = options.method;
		}
		if (options.headers) {
			config.headers = options.headers;
		}
		if (options.responseType) {
			config.responseType = options.responseType;
		}
		if (options.params) {
			config.params = options.params;
			config.paramsSerializer = HttpClientProxy.mimicAngularHttpClientParamSerializer;
		}

		return config;
	}

	private static mimicAngularHttpClientParamSerializer(params: any): string {
		// copied from Angular source code here:
		// https://github.com/angular/angular/blob/10.1.3/packages/common/http/src/client.ts#L477
		const p = params instanceof HttpParams ? params : new HttpParams({ fromObject: params });
		return p.toString();
	}
}
