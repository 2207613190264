import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ChartComponent, ChartSettings } from '../chart.component.base';
import { I18nService } from '@wcd/i18n';

import { format } from 'd3-format';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { TzDateService } from '@wcd/localization';

@Component({
	selector: 'wcd-time-series-chart',
	template: `
		<div class="chart" #timeSeriesChart [attr.aria-label]="'charts.timeSeriesChart' | i18n"
			[ngClass]="className"></div>
	`,
})
export class TimeSeriesChartComponent extends ChartComponent {
	@ViewChild('timeSeriesChart', { static: false }) public timeSeriesChart: HTMLDivElement;
	@Input()
	className: string;

	constructor(
		elementRef: ElementRef,
		public i18nService: I18nService,
		private tzDateService: TzDateService
	) {
		super(elementRef);
	}

	protected chartSettings: ChartSettings = {
		useValues: true,
		xProperty: 'label',
		setColors: true,
		data: {
			order: 'desc',
		},
		options: {
			padding: {
				right: 20,
				top: 1,
			},
			data: {},
			axis: {
				x: {
					tick: {
						format: '%Y-%m-%d',
					},
					type: 'timeseries',
				},
				y: {
					tick: {
						format: format('d'),
					},
					min: 0,
					padding: {
						bottom: 0,
					},
				},
			},
			grid: {
				y: {
					show: true,
				},
			},
			oninit: this.onInit.bind(this),
		},
	};

	onInit() {
		setTimeout(() => {
			const options: c3.ChartConfiguration = this.getChartOptions();
			options.bindto = this.el;

			this.setNavigationAndTooltipKeyboardSupport();
			this.setScreenReaderSupport();
		}, 0);
	}

	private setScreenReaderSupport() {
		const options: c3.ChartConfiguration = this.getChartOptions();

		d3.select(this.el)
			.attr('role', 'document')
			.attr('aria-label', this.i18nService.get('charts.timeSeriesChart'));

		d3.selectAll(this.el.querySelectorAll(`circle.c3-circle`))
			.each((dataPoint: any, index: number, nodesList: SVGElement[]) => {
				d3.select(nodesList[index]).attr(
					'aria-label',
					`${dataPoint.id},
					${this.i18nService.get('charts_x_axis')} ${options.axis.x.categories[dataPoint.x]},
					${this.i18nService.get('charts_y_axis')} ${dataPoint.value}, `
				);
			});
	}

	private setNavigationAndTooltipKeyboardSupport() {
		const elements = this.el.querySelectorAll(`circle.c3-circle`)
		d3.selectAll(elements)
			.attr('tabindex', '-1')
			.attr('focusable', 'true')
			.on('keydown', (item: any) => {
				if (!d3.event)
					return;
				else if (d3.event.code === 'Space') {
					this.showTooltip(item);
					d3.event.preventDefault();
					d3.event.stopPropagation();
				}
				else if (d3.event.code === 'Escape') {
					this.hideTooltip();
				}
				else {
					this.onNavigation(item, d3.event);
				}

			})
			.on('focus', (item) => {
				this.showTooltip(item);
			})
			.on('blur', () => {
				this.hideTooltip();
			});

		d3.selectAll([elements[0]]).attr('tabindex', '0')
	}

	onNavigation(item: any, event: any) {
		if (event.code === 'ArrowRight') {
			event.preventDefault();
			event.stopImmediatePropagation();
			const target = event.target;
			let next = event.target.nextElementSibling;
			if (!next) {
				const line = target.parentElement.parentElement;
				next = line.querySelector(`circle.c3-circle`);
			}

			this.setTabindexAndFocus(target, next);
		}
		else if (event.code === 'ArrowLeft') {
			event.preventDefault();
			event.stopImmediatePropagation();
			const target = event.target;
			let next = target.previousElementSibling;
			if (!next) {
				const line = target.parentElement.parentElement;
				next = line.querySelector(`circle.c3-circle:last-child`);
			}

			this.setTabindexAndFocus(target, next);
		}
		else if (event.code === 'ArrowDown') {
			event.preventDefault();
			event.stopImmediatePropagation();
			const target = event.target;
			const nextLine = target.parentElement.parentElement.nextElementSibling;
			let next = nextLine && nextLine.querySelectorAll('circle.c3-circle')[item.index];
			if (!next) {
				const firstLine = target.parentElement.parentElement.parentElement.children[0];
				next = firstLine && firstLine.querySelectorAll('circle.c3-circle')[item.index];
			}

			this.setTabindexAndFocus(target, next);
		}
		else if (event.code === 'ArrowUp') {
			event.preventDefault();
			event.stopImmediatePropagation();
			const target = event.target;
			const prevLine = target.parentElement.parentElement.previousElementSibling;
			let next = prevLine && prevLine.querySelectorAll('circle.c3-circle')[item.index];
			if (!next) {
				const lines = target.parentElement.parentElement.parentElement.children;
				const lastLine = lines[lines.length - 1];
				next = lastLine && lastLine.querySelectorAll('circle.c3-circle')[item.index];
			}

			this.setTabindexAndFocus(target, next);

		}
	}

	setTabindexAndFocus(target, next) {
		if (!next) return;

		target.setAttribute('tabindex', '-1');
		next.setAttribute('tabindex', '0');
		next.focus();
	}
}
