import { Injectable, NgZone } from '@angular/core';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { Store } from '../../../data/models/store.base';
import { AdvancedSettingsBackendService } from './advanced-settings.backend.service';
import {
	ADVANCED_SETTINGS_ID,
	AdvancedSettingsBackendData,
	AdvancedSettingsModel,
} from '../models/advanced-settings.model';
import { Observable } from 'rxjs';

import { merge } from 'lodash-es';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class AdvancedSettingsStore extends Store<AdvancedSettingsModel, string> {
	constructor(
		protected backendService: AdvancedSettingsBackendService,
		protected dialogsService: DialogsService,
		protected i18nService: I18nService,
		ngZone: NgZone
	) {
		super(backendService, ngZone, AdvancedSettingsModel, dialogsService, i18nService, {
			itemNameSingular: 'Setting',
			itemNamePlural: 'Settings',
			iconName: 'settings',
		});
	}

	// gets advanced settings by combining "getAdvancedSettings"
	getAdvancedSettings(): Observable<AdvancedSettingsModel> {
		return this.getItemById(ADVANCED_SETTINGS_ID);
	}

	parseData(data: any): AdvancedSettingsBackendData {
		return merge({}, data.data || data); // backend returns an object with "items" root on GET requests and without items on PUT requests
	}
}
