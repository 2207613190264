import { NgModule } from '@angular/core';
import { FloatingNotificationsComponent } from './components/floating-notifications.component';
import { NotificationComponent } from './components/notification.component';
import { NotificationsComponent } from './components/notifications.component';
import { CommonModule } from '@angular/common';
import { WcdIconsModule } from '@wcd/icons';
import { TooltipsModule } from '@wcd/dialogs';
import { HexaditeFormsModule } from '../forms/forms.module';
import { I18nModule } from '@wcd/i18n';

@NgModule({
	imports: [WcdIconsModule, CommonModule, TooltipsModule, HexaditeFormsModule, I18nModule],
	declarations: [NotificationComponent, NotificationsComponent, FloatingNotificationsComponent],
	exports: [NotificationsComponent, FloatingNotificationsComponent],
})
export class NotificationsModule {}
