import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';

@Entity({
	singularName: 'Custom Ti Indicator Severity Type',
	pluralName: 'Custom Ti Indicator Severity Types',
	values: [
		{
			id: 0,
			type: 'informational',
			name: 'Informational',
			className: 'neutral',
		},
		{
			id: 1,
			type: 'low',
			name: 'Low',
			className: 'lowSeverity',
		},
		{
			id: 2,
			type: 'medium',
			name: 'Medium',
			className: 'mediumSeverity',
		},
		{
			id: 3,
			type: 'high',
			name: 'High',
			className: 'highSeverity',
		},
	],
})
export class CustomTiIndicatorSeverityType extends EntityModelBase<number> {
	@EntityField() name: string;

	@EntityField() type?: string;

	@EntityField() className?: string;
}
