import { Paris } from '@microsoft/paris';
import { NotificationRule, MdeUserRoleActionEnum } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { NotificationRulesFields } from '../services/notification-rules.fields';
import { NotificationRulesService } from '../services/notification-rules.service';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
var NotificationRulesDataviewComponent = /** @class */ (function () {
    function NotificationRulesDataviewComponent(paris, authService, dialogsService, i18nService, notificationRulesFields, notificationRulesService, performanceSccService) {
        this.paris = paris;
        this.authService = authService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.notificationRulesFields = notificationRulesFields;
        this.notificationRulesService = notificationRulesService;
        this.performanceSccService = performanceSccService;
        this.isScc = sccHostService.isSCC;
        this.repository = paris.getRepository(NotificationRule);
        this.boundSetItemActions = this.setItemActions.bind(this);
    }
    Object.defineProperty(NotificationRulesDataviewComponent.prototype, "isUserAllowedActions", {
        get: function () {
            return this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings);
        },
        enumerable: true,
        configurable: true
    });
    NotificationRulesDataviewComponent.prototype.openEditPanel = function (rule) {
        this.notificationRulesService.showRulePanel(rule);
    };
    NotificationRulesDataviewComponent.prototype.setItemActions = function (selectedRules) {
        var _this = this;
        if (!selectedRules ||
            !selectedRules.length ||
            selectedRules.length > 1 ||
            selectedRules[0].readonly ||
            !this.isUserAllowedActions)
            return [];
        return [
            new ItemActionModel({
                id: 'delete',
                name: this.i18nService.get('delete'),
                icon: 'delete',
                tooltip: this.i18nService.get('deleteSelectedItems', {
                    itemsName: this.repository.entity.pluralName,
                }),
                method: function (items) { return _this.deleteItem(items[0]); },
                refreshOnResolve: true,
            }),
        ];
    };
    NotificationRulesDataviewComponent.prototype.deleteItem = function (item) {
        var _this = this;
        return this.dialogsService
            .confirm({
            title: this.i18nService.get('deleteItem', { itemName: item.name }),
            text: this.i18nService.get('deleteConfirm', {
                itemPluralName: this.repository.entity.pluralName,
            }),
            confirmText: this.i18nService.get('delete'),
        })
            .then(function (e) {
            return (e.confirmed &&
                _this.repository
                    .removeItem(item, { data: _this.repository.serializeItem(item) })
                    .toPromise());
        });
    };
    NotificationRulesDataviewComponent.prototype.onEmailNotificationsDataViewComplete = function () {
        this.performanceSccService.endNgPageLoadPerfSession('settings-email-notifications');
    };
    return NotificationRulesDataviewComponent;
}());
export { NotificationRulesDataviewComponent };
