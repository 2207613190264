import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Secure Score Settings',
	pluralName: 'Secure Score Settings',
	endpoint: 'SecurityAnalyticsSettings',
	parseItemQuery: () => 'SecurityAnalyticsSettings',
	parseSaveQuery: () => 'SecurityAnalyticsSettings',
	saveMethod: 'POST',
	readonly: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.settings,
})
export class SecurityAnalyticsSettings extends EntityModelBase {
	@EntityField({ data: 'ExcludedControls', defaultValue: [] })
	excludedControls: Array<string>;
}
