import {
	DataQuery,
	DataQuerySortDirection,
	Entity,
	EntityField,
	EntityModelBase,
	ParisConfig,
} from '@microsoft/paris';
import { MachineGroup } from '../rbac/machine-group.entity';
import { ScheduledHuntingRunStatus } from './scheduled-hunting-run-status.entity';
import { omit } from 'lodash-es';
import { Severity } from '../severity/severity.entity';
import * as HuntingRuleTypes from './hunting-rule-custom-action.entity';
import { HuntingRuleImpactedEntity } from './hunting-rule-impacted-entity.value-object';
import { HuntingCustomAction } from './actions/hunting-custom-action.value-object';
import { MtpWorkload } from '../rbac/mtp-workload-enum';
import { ServiceSource } from '../alert/sources/service-source.entity';
import { RULE_STATUS_ID_DOES_NOT_APPLY } from './shceduled-hunting-run-status.values';
import { Outbreak } from '..';

type HuntingRuleCustomActions = HuntingRuleTypes.HuntingRuleCustomActions;

/**
 * Subset of the AlertCategoryType enum.
 * Contains only alert categories that are supported in the BE, see: https://microsoft.visualstudio.com/WDATP/_git/Detection.Platform?path=%2FSevilleIntegration%2FWcdDetectionEngines.BondEntities%2FWcdDetectionEnginesEnumerations.cs&_a=contents&version=GBmaster
 */
export enum HuntingRuleAlertCategory {
	'Collection' = 'Collection',
	'CommandAndControl' = 'CommandAndControl',
	'CredentialAccess' = 'CredentialAccess',
	'DefenseEvasion' = 'DefenseEvasion',
	'Discovery' = 'Discovery',
	'Execution' = 'Execution',
	'Exfiltration' = 'Exfiltration',
	'Exploit' = 'Exploit',
	'InitialAccess' = 'InitialAccess',
	'LateralMovement' = 'LateralMovement',
	'Malware' = 'Malware',
	'Persistence' = 'Persistence',
	'PrivilegeEscalation' = 'PrivilegeEscalation',
	'Ransomware' = 'Ransomware',
	'SuspiciousActivity' = 'SuspiciousActivity',
	'UnwantedSoftware' = 'UnwantedSoftware',
	'Impact' = 'Impact',
}

@Entity({
	singularName: 'Detection rule',
	pluralName: 'Detection rules',
	endpoint: 'rules',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.huntingService,
	loadAll: false,
	cache: {
		time: 1000 * 60,
		max: 10,
	},
	saveMethod: (item: any) => (item.id ? 'PATCH' : 'POST'),
	serializeItem: (item, serializedItem, entity, config, serializationData: { queryText: string }) => ({
		...serializedItem,
		QueryText: serializationData.queryText,
		AlertDescription: serializedItem.Description,
		AlertSeverity: serializedItem.Severity,
		AlertCategory: serializedItem.Category,
		AlertRecommendedAction: serializedItem.RecommendedAction,
	}),
	parseDataQuery: (dataQuery: DataQuery) => {
		if (!dataQuery) return {};

		return Object.assign(
			{},
			omit(dataQuery.where as object, ['page', 'page_size', 'ordering']),
			{
				pageIndex: dataQuery.page || 1,
				pageSize: dataQuery.pageSize,
			},
			dataQuery.sortBy && dataQuery.sortBy.length
				? {
						sortByField: dataQuery.sortBy[0].field,
						sortOrder:
							dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
								? 'Ascending'
								: 'Descending',
				  }
				: undefined
		);
	},
})
export class HuntingRule extends EntityModelBase<number> {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	// #region Read only fields

	@EntityField({ data: 'QueryId', serialize: false })
	queryId: number;

	@EntityField({ data: 'LastRunTime', serialize: false })
	lastRunTime: Date;

	@EntityField({
		data: '__self',
		parse: ({ LastRunStatus, IntervalHours }) =>
			IntervalHours === 0 ? RULE_STATUS_ID_DOES_NOT_APPLY : LastRunStatus,
		serialize: false,
	})
	lastRunStatus: ScheduledHuntingRunStatus;

	@EntityField({ data: 'LastRunFailureReason', serialize: false })
	lastRunFailureReason: string;

	@EntityField({ data: 'NextRunTime', serialize: false })
	nextRunTime: Date;

	@EntityField({ data: 'CreatedBy', serialize: false })
	createdBy: string;

	@EntityField({ data: 'CreationTime', serialize: false })
	createdOn: Date;

	@EntityField({ data: 'LastUpdatedBy', serialize: false })
	lastUpdatedBy: string;

	@EntityField({ data: 'LastUpdatedTime', serialize: false })
	lastUpdatedOn: Date;

	@EntityField({ data: 'IoaDefinitionId', serialize: false })
	ioaDefinitionId: string;

	@EntityField({
		data: 'MtpWorkloads',
		parse: (rawData: Array<string | number>) =>
			rawData &&
			rawData.map(workload => (typeof workload === 'string' ? MtpWorkload[workload] : workload)),
		serialize: false,
	})
	mtpWorkloads: Array<MtpWorkload>;

	@EntityField({ data: 'ServiceSources', required: false, arrayOf: ServiceSource, serialize: false })
	serviceSources?: ServiceSource[];

	// #endregion

	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'Title' })
	alertTitle: string;

	@EntityField({ data: ['Description', 'AlertDescription'] })
	alertDescription: string;

	@EntityField({ data: 'IntervalHours' })
	intervalHours: number;

	@EntityField({ data: ['Severity', 'AlertSeverity'] })
	alertSeverity: Severity;

	@EntityField({ data: ['Category', 'AlertCategory'] })
	alertCategory: HuntingRuleAlertCategory;

	@EntityField({ data: 'ThreatId' })
	threatId?: string;

	threat?: Outbreak;

	@EntityField({ data: 'MitreTechniques' })
	mitreTechniques: Array<string>;

	@EntityField({ data: ['RecommendedAction', 'AlertRecommendedAction'] })
	recommendedActions: string;

	@EntityField({
		data: 'RbacGroupIds',
		arrayOf: MachineGroup,
		parse: (machineGroupIdsAsCsv: string) => machineGroupIdsAsCsv && machineGroupIdsAsCsv.split(','),
		serialize: (items: Array<MachineGroup>) => {
			return items ? items.map(item => item.id).join(',') : null;
		},
	})
	machineGroups: Array<MachineGroup>;

	@EntityField({ data: 'IsEnabled' })
	isEnabled: boolean;

	/**
	 * @deprecated use 'actions' instead
	 */
	@EntityField({
		data: 'CustomizedActions',
		serialize: false,
		parse: HuntingRuleTypes.parseCustomActions,
		get defaultValue(): HuntingRuleCustomActions {
			return HuntingRuleTypes.huntingRuleCustomActionTypeValues.reduce((acc, item) => {
				acc[item.id] = {
					actionType: item.id,
					displayName: item.displayName,
					category: item.category,
					active: false,
				};
				return acc;
			}, {});
		},
	})
	customActions: HuntingRuleCustomActions;

	@EntityField({
		data: 'CustomActions',
		arrayOf: HuntingCustomAction,
		defaultValue: [],
	})
	actions?: Array<HuntingCustomAction>;

	@EntityField({ data: 'ImpactedEntities', arrayOf: HuntingRuleImpactedEntity, defaultValue: [] })
	impactedEntities: Array<HuntingRuleImpactedEntity>;

	@EntityField({ data: 'IsMdatp' })
	isMdatp: boolean;
}

export function isContinuousHuntingRule(rule: HuntingRule) {
	return rule.intervalHours === 0;
}
