const ALL_KNOWN_PERMISSIONS = [
	'audioCapture',
	'app.window.fullscreen.overrideEsc',
	'browsingData',
	'content_security_policy',
	'contentSettings',
	'copresence',
	'debugger',
	'declarativeNetRequest',
	'declarativeWebRequest',
	'downloads.open',
	'experimental',
	'hid',
	'history',
	'nativeMessaging',
	'pageCapture',
	'privacy',
	'proxy',
	'socket',
	'tabCapture',
	'tabs',
	'unsafe-eval',
	'usb',
	'usbDevices',
	'videoCapture',
	'vpnProvider',
	'web_accessible_resources',
	'webNavigation',
	'activeTab',
	'bookmarks',
	'clipboardRead',
	'clipboardWrite',
	'contextMenus',
	'cookies',
	'desktopCapture',
	'downloads',
	'fileSystem',
	'fileSystem.directory',
	'fileSystem.retainEntries',
	'fileSystem.write',
	'fileSystem.writeDirectory',
	'geolocation',
	'identity',
	'identity.email',
	'management',
	'processes',
	'sessions',
	'syncFileSystem',
	'system.storage',
	'topSites',
	'tts',
	'webRequest',
	'webRequestBlocking',
	'accessibilityFeatures.modify',
	'accessibilityFeatures.read',
	'alarms',
	'alwaysOnTopWindows',
	'app.window.alpha',
	'app.window.alwaysOnTop',
	'app.window.fullscreen',
	'app.window.shape',
	'certificateProvider',
	'declarativeContent',
	'documentScan',
	'enterprise.deviceAttributes',
	'enterprise.hardwarePlatform',
	'enterprise.platformKeys',
	'externally_connectable',
	'fileBrowserHandler',
	'fileSystemProvider',
	'fontSettings',
	'gcm',
	'homepage_url',
	'idle',
	'mediaGalleries',
	'networking.config',
	'notifications',
	'overrideEscFullscreen',
	'platformKeys',
	'power',
	'printerProvider',
	'signedInDevices',
	'storage',
	'system.memory',
	'system.cpu',
	'system.display',
	'ttsEngine',
	'unlimitedStorage',
	'wallpaper',
	'webview',
];

export enum PermissionRisk {
	None = 'None',
	Low = 'Low',
	Medium = 'Medium',
	High = 'High',
	VeryHigh = 'VeryHigh',
}

const SeverityMapping = {
	[PermissionRisk.None]: 'None',
	[PermissionRisk.Low]: 'Low',
	[PermissionRisk.Medium]: 'Medium',
	[PermissionRisk.High]: 'High',
	[PermissionRisk.VeryHigh]: 'Critical',
};

type BrowserExtensionPermissionType = 'api' | 'host';

export interface BrowserExtensionPermission {
	permissionName: string;
	permissionRisk: string;
	isOptional: boolean;
	type: BrowserExtensionPermissionType;
}

const filterPermissions = (p) =>
	p.type === 'api' || p.permissionName === 'all_urls' || p.permissionName.indexOf('/') >= 0;

export const parsePermissionsData = (
	permissions: { name: string; risk: number; isOptional: boolean }[]
): BrowserExtensionPermission[] =>
	(permissions || [])
		.map<BrowserExtensionPermission>((p) => {
			const isApiPermissions = ALL_KNOWN_PERMISSIONS.indexOf(p.name) >= 0;
			// if this is a known permission, replace permission name . (dot) and - (dash) to underscore to support locations keys in locstring file
			// otherwise this is a url, just remove '<' and '>' in case this is <all_urls>
			const renamedPermissions = isApiPermissions
				? p.name.replace(/[.-]/g, '_')
				: p.name.replace(/[<>]/g, '');

			return {
				permissionName: renamedPermissions,
				permissionRisk: permissionRiskAsNumberToSeverityLevel(p.risk),
				isOptional: p.isOptional,
				type: isApiPermissions ? 'api' : 'host',
			};
		})
		.filter(filterPermissions);

export const permissionRiskAsNumberToSeverityLevel = (risk: number): string =>
	SeverityMapping[Object.keys(PermissionRisk)[risk]];

export const permissionRiskAsStringToExposureScoreCategory = (risk: PermissionRisk): string => {
	return SeverityMapping[risk];
};
