import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { LiveResponseCommandType } from '../entities/live-response-command-type.value-object';

@ApiCall({
	name: 'Get Available Live Response Commands',
	endpoint: (config, query) => {
		if(query && query.where && query.where['useV3Api']) {
			return 'cloud/live_response/get_command_definitions'
		}

		return query.where['useV2Api']
			? 'v2/live_response/get_command_definitions'
			: 'live_response/get_command_definitions'
	},
	baseUrl: (config: WcdPortalParisConfig, query) => query && query.where && query.where['useV3Api'] ? config.data.serviceUrls.k8s : config.data.serviceUrls.automatedIr,
	parseQuery: ({ sessionId, useV2Api, useV3Api }) => {
		return {
			params: {
				session_id: sessionId,
				useV2Api,
				useV3Api
			},
		};
	},
})
export class GetCommandDefinitionsApiCall extends ApiCallModel<
	Array<LiveResponseCommandType>,
	{ sessionId: string | number; useV2Api: boolean, useV3Api: boolean }
> {}
