import { PanelAction, PanelMenuAction, PanelSettings } from './panel-settings.model';
import { PanelType } from './panel-type.enum';

export class Panel implements PanelSettings {
	/**
	 * Text to appear as the panel title (on top of the panel).
	 */
	headerText?: string;
	/**
	 * Icon to appear left to the panel title (on top of the panel).
	 */
	headerIcon?: string;
	headerDescription?: string;
	headerClassName?: string;
	id?: string;
	className?: string;
	showOverlay?: boolean;
	isBlocking? = true;
	isModal? = true;
	type?: PanelType = PanelType.smallFixedFar;
	width?: number;
	hasCloseButton?: boolean;
	/**
	 * Removes padding around the content of the panel.
	 */
	noPadding?: boolean;
	noBodyPadding?: boolean;
	/**
	 * Cancel the shadow effect at the panel left border.
	 */
	noShadow?: boolean;
	menuActions?: Array<PanelMenuAction>;
	isStatic?: boolean;
	scrollBody?: boolean;
	disableOverlayClick?: boolean;
	/**
	 * Add a back arrow button at the top of the panel, triggers the given action when pressed.
	 */
	back?: PanelAction;
	/**
	 * Add a next arrow button at the top of the panel, triggers the given action when pressed.
	 */
	next?: PanelAction;
	previous?: PanelAction;
	/**
	 * Id of the element that holds the panel title, for accessibility (when template ref #panelHeader doesn't find element)
	 */
	headerElementId?: string;
	/**
	*  Set role of panel body. To change the behaviour of accessability tools.
	*/
	role?: string;


	constructor(data: PanelSettings) {
		Object.assign(this, data);
	}
}
