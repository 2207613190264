var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BehaviorSubject } from 'rxjs';
import { Alert, File, Ip, LegacyUser, Machine, Script, EntityWithContext, Url, Domain } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
export var detailsTabSupportedEntityTypes = {
    machine: Machine,
    alert: Alert,
    user: LegacyUser,
    process: EntityWithContext,
    file: File,
    script: Script,
    ip: Ip,
    url: Url,
    domain: Domain,
    defender: EntityWithContext
};
var AlertPageService = /** @class */ (function () {
    function AlertPageService(globalEntityTypesService, appInsights) {
        this.globalEntityTypesService = globalEntityTypesService;
        this.appInsights = appInsights;
        this.selectedTreeElements = {};
        this.detailsTab$ = new BehaviorSubject(null);
        this.mainAlertPageId$ = new BehaviorSubject('');
        this.treeElementsUpdates$ = new BehaviorSubject('');
        this.processTreeJson$ = new BehaviorSubject('');
        this.enableTracking$ = new BehaviorSubject(true);
    }
    AlertPageService.prototype.updateMainAlertPageId = function (id) {
        this.mainAlertPageId$.next(id);
    };
    // Update the detailsTab$ so the details tab will render an updated entity.
    AlertPageService.prototype.updateDetailsTab = function (detailsTabEntityConstructor, detailsTabEntity, selectedId) {
        if (selectedId === void 0) { selectedId = null; }
        if (detailsTabSupportedEntityTypes[detailsTabEntityConstructor] &&
            detailsTabEntity &&
            detailsTabEntity.id) {
            for (var key in this.selectedTreeElements) {
                this.selectedTreeElements[key] = false;
            }
            if (selectedId) {
                this.selectedTreeElements[selectedId] = true;
            }
            this.detailsTab$.next({
                entityType: this.globalEntityTypesService.getEntityType(detailsTabSupportedEntityTypes[detailsTabEntityConstructor]),
                entityTypeName: detailsTabEntityConstructor,
                entity: detailsTabEntity,
            });
        }
        else {
            // TODO: use app insight.
            console.log('Can not update entity in details tab.');
        }
    };
    AlertPageService.prototype.updateTreeElement = function (treeElementsIds) {
        var _this = this;
        treeElementsIds.forEach(function (treeElementId) { return _this.treeElementsUpdates$.next(treeElementId); });
    };
    AlertPageService.prototype.updateProcessTreeJson = function (processTreeJson) {
        this.processTreeJson$.next(processTreeJson);
    };
    AlertPageService.prototype.updateTrackingStatus = function (enableTracking) {
        if (enableTracking === void 0) { enableTracking = true; }
        this.enableTracking$.next(enableTracking);
    };
    AlertPageService.prototype.trackErrorInsight = function (alertId, correlationId, errorType) {
        if (this.enableTracking$.value) {
            var properties = this.GetTrackProperties('Alert page error ' + errorType, alertId, correlationId, false);
            this.appInsights.track(properties);
        }
    };
    AlertPageService.prototype.trackProcessTreeInsight = function (alertId, correlationId, success, measurements) {
        if (this.enableTracking$.value) {
            var properties = this.GetTrackProperties('Process tree elements', alertId, correlationId, success);
            this.appInsights.track(properties, measurements);
        }
    };
    AlertPageService.prototype.trackPerformance = function (alertId, correlationId, performanceType, success, additionalMeasurements) {
        if (additionalMeasurements === void 0) { additionalMeasurements = {}; }
        if (this.enableTracking$.value) {
            var measurements = __assign({ loadTime: this.getTotalLoadTime(performanceType) }, additionalMeasurements);
            var properties = this.GetTrackProperties('AlertPagePerformance' + performanceType, alertId, correlationId, success);
            this.appInsights.track(properties, measurements);
        }
    };
    AlertPageService.prototype.GetTrackProperties = function (id, alertId, correlationId, success, hasOfficeTenantPrefix) {
        if (hasOfficeTenantPrefix === void 0) { hasOfficeTenantPrefix = false; }
        var properties = {
            component: 'AlertDetails',
            componentType: 'Page',
            id: id,
            type: TrackingEventType.Action,
            value: alertId,
            correlationId: correlationId,
            isSuccessful: success.toString()
        };
        return properties;
    };
    AlertPageService.prototype.getTotalLoadTime = function (performanceType) {
        var measurementName = performanceType + '-measurement';
        performance.measure(measurementName, performanceType);
        return performance.getEntriesByName(measurementName).pop().duration;
    };
    return AlertPageService;
}());
export { AlertPageService };
var TrackingAlertPageEventProperties = /** @class */ (function () {
    function TrackingAlertPageEventProperties() {
    }
    return TrackingAlertPageEventProperties;
}());
export var AlertInsightPerformanceType;
(function (AlertInsightPerformanceType) {
    AlertInsightPerformanceType["InitiatingAlert"] = "InitiatingAlertMs";
    AlertInsightPerformanceType["RelatedMachine"] = "RelatedMachineMs";
    AlertInsightPerformanceType["RelatedUserFromProcessTree"] = "RelatedUserFromProcessTreeMs";
    AlertInsightPerformanceType["RelatedUserFromAlert"] = "RelatedUserFromAlertMs";
    AlertInsightPerformanceType["ProcessTree"] = "ProcessTreeMs";
    AlertInsightPerformanceType["ProcessTreeRendering"] = "ProcessTreeRenderingMs";
    AlertInsightPerformanceType["InitiatingOfficeTenantPrefix"] = "InitiatingOfficeTenantPrefixMs";
})(AlertInsightPerformanceType || (AlertInsightPerformanceType = {}));
export var AlertInsightErrorType;
(function (AlertInsightErrorType) {
    AlertInsightErrorType["NoRelatedAlert"] = "noRelatedAlert";
    AlertInsightErrorType["NoProcessTreeCreate"] = "noProcessTreeCreated";
    AlertInsightErrorType["CyberEntityNotFound"] = "cyberEntityNotFound";
    AlertInsightErrorType["OfficeIntegrationError"] = "officeIntegrationError";
    AlertInsightErrorType["Other"] = "other";
})(AlertInsightErrorType || (AlertInsightErrorType = {}));
