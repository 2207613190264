import { Injectable } from '@angular/core';
import { tenantContextCache } from '@wcd/auth';
import { AppContextService } from '@wcd/config';
import { TvmLicensesService, TvmLicenseType } from '@wcd/scc-common';
import { FeaturesService } from './features.service';
import { Feature } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class TvmLicensesAngularService {
	constructor(
		private appContextService: AppContextService,
		private featuresService: FeaturesService,
		appConfigService: AppConfigService
	) {
		this.tvmLicensesService = sccHostService.isSCC
			? undefined
			: new TvmLicensesService(
					appConfigService.tvmLicenses,
					this.featuresService.isEnabled(Feature.TvmPremium)
			  );
	}

	private tvmLicensesService: TvmLicensesService;

	async init() {
		const tenantContext = await tenantContextCache.getTenantContext();
		this.tvmLicensesService = new TvmLicensesService(
			tenantContext.TvmLicenses,
			this.featuresService.isEnabled(Feature.TvmPremium)
		);
	}

	isEnabled(licenseType: TvmLicenseType): boolean {
		return this.tvmLicensesService && this.tvmLicensesService.isEnabled(licenseType);
	}
}
