import { Injectable, NgZone } from '@angular/core';
import { SnackBar, SnackBarConfig } from '../models/snackbar.model';
import { BehaviorSubject } from 'rxjs';
import { LiveAnnouncer } from '@angular/cdk/a11y';

const SNACKBAR_SHOW_DURATION = 5000;
const REMOVE_ANIMATION_DURATION = 500;
const TIME_BETWEEN_SNACKBARS = 100;

@Injectable()
export class SnackBarsService {
	visibleSnackBar: SnackBar;
	snackBarsQueue: Array<SnackBar> = [];
	isRemovingVisibleSnackbar = false;
	isVisibleSnackBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private ngZone: NgZone, private liveAnnouncer: LiveAnnouncer) {}

	private showNext() {
		if (this.snackBarsQueue.length) {
			this.visibleSnackBar = this.snackBarsQueue.shift();
			this.isVisibleSnackBar$.next(true);
			if (this.visibleSnackBar.disableForceFocus) {
				this.liveAnnouncer.announce(this.visibleSnackBar.text, 'assertive', 300);
			}
			setTimeout(() => {
				this.closeVisibleSnackbar(true);
			}, SNACKBAR_SHOW_DURATION);
		} else if (this.visibleSnackBar) {
			this.closeVisibleSnackbar();
		}
	}

	private closeVisibleSnackbar(showNext?: boolean) {
		this.ngZone.run(() => (this.isRemovingVisibleSnackbar = true));

		setTimeout(() => {
			this.isRemovingVisibleSnackbar = false;
			this.visibleSnackBar = null;

			if (showNext) {
				setTimeout(() => {
					this.showNext();
				}, TIME_BETWEEN_SNACKBARS);
			}

			this.isVisibleSnackBar$.next(false);
		}, REMOVE_ANIMATION_DURATION);
	}

	add(snackBarConfig: SnackBarConfig): void {
		if (!snackBarConfig || !snackBarConfig.text) {
			return;
		}

		const snackBar = new SnackBar(snackBarConfig);
		this.snackBarsQueue.push(snackBar);

		if (!this.visibleSnackBar) {
			this.showNext();
		}
	}

	callVisibleSnackbarMethod(): void {
		if (!this.visibleSnackBar || !this.visibleSnackBar.method) {
			return;
		}

		this.closeVisibleSnackbar(true);
		this.visibleSnackBar.method.method(this.visibleSnackBar.data);
	}
}
