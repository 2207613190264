
		<wcd-panel (close)="destroy()" [settings]="settings">
			<ng-container *ngIf="(tableItems$ | async) as tableItems; else loadingTemplate">
				<wcd-datatable
					[items]="tableItems"
					[columns]="tableFields"
					[selectEnabled]="false"
					[label]="
						'files.entityDetails.sections.detections.fields.malwareDetected.malwareAlertPanel.title'
							| i18n
					"
				></wcd-datatable>
			</ng-container>

			<ng-template #loadingTemplate>
				<fab-spinner [label]="'loading' | i18n"></fab-spinner>
			</ng-template>
		</wcd-panel>
	