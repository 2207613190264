var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { MachineUserDetailsAccount } from '../../../machine/machine-user-details-account.value-object';
import { LegacyUser } from '../legacy-user.entity';
import { LegacyUserProfile } from '../legacy-user-profile.entity';
import { omitBy, isNil } from 'lodash-es';
var ɵ0 = function (user) {
    var userAccount = user.accountName;
    var userAccountDomain = user.accountDomainName;
    if (user.$parent && user.$parent.constructor === MachineUserDetailsAccount) {
        var account = user.$parent;
        userAccount = account.name;
        userAccountDomain = account.domain;
    }
    return omitBy({ sid: user.sid, userAccount: userAccount, userAccountDomain: userAccountDomain }, isNil);
}, ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; };
var LegacyUserLegacyUserProfileRelationship = /** @class */ (function () {
    function LegacyUserLegacyUserProfileRelationship() {
    }
    LegacyUserLegacyUserProfileRelationship = __decorate([
        EntityRelationship({
            sourceEntity: LegacyUser,
            dataEntity: LegacyUserProfile,
            getRelationshipData: ɵ0,
            endpoint: 'UserCommunicationProfile',
            allowedTypes: [RelationshipType.OneToOne],
            allItemsEndpointTrailingSlash: false,
            baseUrl: ɵ1,
        })
    ], LegacyUserLegacyUserProfileRelationship);
    return LegacyUserLegacyUserProfileRelationship;
}());
export { LegacyUserLegacyUserProfileRelationship };
export { ɵ0, ɵ1 };
