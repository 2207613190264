import { Type } from '@angular/core';
import { DataEntityType, EntityRelationshipRepositoryType, Paris, ModelBase } from '@microsoft/paris';
import { AlertsSeveritySummary } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { ActivatedEntity } from '../../../services/activated-entity.service';
import { EntityRelatedWidgetBase, EntityRelatedData } from '../entity-related-widget.base';

export const ALERTS_SUMMARY_WIDGET_RANGE_IN_DAYS = 180;

export interface AlertsSummaryWidgetData {
	alertsSeveritySummary: AlertsSeveritySummary;
}

export abstract class AlertsSummaryWidgetBase<TEntity extends ModelBase> extends EntityRelatedWidgetBase<
	TEntity,
	AlertsSummaryWidgetData,
	AlertsSeveritySummary
> {
	/**
	 * The ID of the widget
	 */
	protected abstract id: string;

	/**
	 * The entity type, for which we show the alerts summary
	 */
	protected abstract entityType: DataEntityType<TEntity>;

	/**
	 * The relationship type, will be used to fetch alerts summary of the active entity using paris.
	 */
	protected abstract entityAlertsSummaryRelationship: Type<
		EntityRelationshipRepositoryType<TEntity, AlertsSeveritySummary>
	>;

	constructor(
		paris: Paris,
		activatedEntity: ActivatedEntity,
		reportsService: ReportsService,
		protected readonly i18nService: I18nService
	) {
		super(paris, activatedEntity, reportsService);
	}

	get entityRelatedWidgetConfig() {
		return {
			id: this.id,
			name: this.i18nService.get('reports.widgets.alertsSummary.title'),
			rangeInDays: ALERTS_SUMMARY_WIDGET_RANGE_IN_DAYS,
			entityType: this.entityType,
			entityRelationshipType: this.entityAlertsSummaryRelationship,
			getDataQuery: () => ({ where: { lookingBackInDays: ALERTS_SUMMARY_WIDGET_RANGE_IN_DAYS } }),
			parseData: (entityAlertsSummary: EntityRelatedData<TEntity, AlertsSeveritySummary>) => ({
				alertsSeveritySummary: entityAlertsSummary.relatedData,
			}),
		};
	}
}
