<!--
tab index was placed on the following div
and the click event to prevent NVDA from announcing the
tooltip as clickable when they are not visible.
see: https://microsoft.visualstudio.com/OS/_workitems/edit/25762696/
-->
	<div class="wcd-flex-horizontal-spaced" *ngIf="title"
		[attr.tabindex]="tabIndex"
		(click)="onClick.emit()"
		(keydown.enter)="onClick.emit()"
		[attr.role]="tabIndex ? 'link' : null"
		>
		<div class="wcd-flex-1">
			<span [class]="barTitleCssClass">
				{{title}}
			</span>
			<fab-icon *ngIf="icon" [iconName]="icon" [wcdTooltip]="iconTooltip" contentClass="wcd-vertical-align-bottom wcd-padding-small-horizontal inheriting-icon"></fab-icon>
		</div>
		<div *ngIf="value >= 0 && !useCustomDisplay ; else showNoDataText" class="wcd-flex-justify-end-horizontal wcd-font-weight-semibold">
			<ng-container *ngIf="!showValueAsPercentage; else showValuePercentage">
				<span>
					{{value | prettyNumber}}
				</span>

				<span [attr.aria-label]="valueAriaLabel"></span>

				<span class="ms-fontColor-gray140" *ngIf="total >= 0">
					/
				</span>
				<span class="ms-fontColor-gray140">
					{{total | prettyNumber}}{{unit}}

					<span [attr.aria-label]="totalAriaLabel"></span>
				</span>
			</ng-container>
		</div>
	</div>
	<div [wcdTooltip]="barTooltip"
	 class="wcd-flex-horizontal wcd-margin-xsmall-top bars-wrapper" [style.width]="actualWidth" [style.height]="actualHeight">
		<div
				[ngClass]="getBackgroundColor(colorName)"
				[style.width]="fillRatio * 100 + '%'"
				[wcdTooltip]="valueTooltip(value, total, unit, valuePrefix)"
				aria-roledescription="tooltip"
				role="button"
				class="filled-bar"
				[attr.aria-label]="valueTooltip(value, total, unit, valuePrefix) || 'tooltip'"
		></div>
		<div
				[style.width]="spaceBetweenBars"
				role="presentation"
		></div>
		<div
				[ngClass]="getBackgroundColor(backgroundColorName)"
				[style.width]="(1 - fillRatio) * 100 + '%'"
				[wcdTooltip]="totalTooltip(value, total, unit, restPrefix)"
				aria-roledescription="tooltip"
				role="button"
				[attr.aria-label]="totalTooltip(value, total, unit, restPrefix) || 'tooltip'"
		></div>
	</div>

<ng-template #showValuePercentage>
	<span>
		{{ calculatePercentage() }}%
	</span>
</ng-template>

<ng-template #showNoDataText>
	<span class="ms-fontColor-gray140">
		{{noDataText}}
	</span>
</ng-template>
