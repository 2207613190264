var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { File } from '@wcd/domain';
import { MessageBarType } from 'office-ui-fabric-react';
var FileActionCenterPanelComponent = /** @class */ (function (_super) {
    __extends(FileActionCenterPanelComponent, _super);
    function FileActionCenterPanelComponent(router) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.MessageBarType = MessageBarType;
        _this.foundSampleCollectionActions = null;
        _this.foundQuarantineActions = null;
        return _this;
    }
    return FileActionCenterPanelComponent;
}(PanelContainer));
export { FileActionCenterPanelComponent };
