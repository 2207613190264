import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Alert } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';

@Component({
	selector: 'alerts-dataview-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings" data-track-component="AlertsDataviewPanel">
			<alerts-dataview
				*ngIf="alerts?.length"
				dataViewId="alerts-dataview-panel-view"
				[dataViewConfig]="{ data: alerts }"
				[responsiveLayout]="true"
				[showHeader]="false"
				[allowFilters]="false"
				[allowPaging]="false"
				[allowGrouping]="false"
				[defaultSortId]="defaultSortBy"
				[allowTimeRangeSelect]="false"
				[hideControls]="true"
				[disableSelection]="true"
				[onlyFields]="onlyFields"
				[fixedTable]="true"
				[sortDisabledFields]="['title', 'severity', 'status', 'lasteventtime']"
				[removePadding]="false"
			></alerts-dataview>
		</wcd-panel>
	`,
})
export class AlertsDataviewPanelComponent extends PanelContainer implements OnInit {
	@Input() alerts: Alert[];
	@Input() defaultSortBy: string = 'severity';
	@Input() onlyFields: string[];

	constructor(protected router: Router) {
		super(router);
	}
}
