import { SecurityContext } from '@angular/core';
import { DomSanitizer, } from '@angular/platform-browser';
import { sccHostService } from '@wcd/scc-interface';
import { FeaturesService } from '@wcd/config';
import { Feature } from '@wcd/config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "../../../../config/src/lib/services/features.service";
/**
 * A wrapper to determine between NG's DOMSanitizer or SCC's DOMPurify services.
 */
var WicdSanitizerService = /** @class */ (function () {
    function WicdSanitizerService(domSanitizer, featuresService) {
        this.domSanitizer = domSanitizer;
        this.featuresService = featuresService;
    }
    WicdSanitizerService.prototype.sanitize = function (context, value) {
        if (sccHostService.isSCC && this.featuresService.isEnabled(Feature.UseSCCDomSanitizer)) {
            return sccHostService.ui.sanitize(value);
        }
        else
            return this.domSanitizer.sanitize(context, value);
    };
    ;
    WicdSanitizerService.prototype.bypassSecurityTrustHtml = function (value) {
        return this.domSanitizer.bypassSecurityTrustHtml(value);
    };
    ;
    WicdSanitizerService.prototype.bypassSecurityTrustStyle = function (value) {
        return this.domSanitizer.bypassSecurityTrustStyle(value);
    };
    WicdSanitizerService.prototype.bypassSecurityTrustScript = function (value) {
        return this.domSanitizer.bypassSecurityTrustScript(value);
    };
    WicdSanitizerService.prototype.bypassSecurityTrustUrl = function (value) {
        return this.domSanitizer.bypassSecurityTrustUrl(value);
    };
    WicdSanitizerService.prototype.bypassSecurityTrustResourceUrl = function (value) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
    };
    ;
    WicdSanitizerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WicdSanitizerService_Factory() { return new WicdSanitizerService(i0.ɵɵinject(i1.DomSanitizer), i0.ɵɵinject(i2.FeaturesService)); }, token: WicdSanitizerService, providedIn: "root" });
    return WicdSanitizerService;
}());
export { WicdSanitizerService };
