import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Related component filters',
	pluralName: 'Related component filters',
	readonly: true,
})
export class RelatedComponentFilter extends ModelBase {
	@EntityField() category: string;

	@EntityField() subCategories: Array<string>;
}
