import { map } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { Machine } from '@wcd/domain';
import { MachineEntityTypeService } from '../../services/machine.entity-type.service';
var MachinesDataviewTagsComponent = /** @class */ (function () {
    function MachinesDataviewTagsComponent(machineEntityTypeService, changeDetectionRef) {
        this.machineEntityTypeService = machineEntityTypeService;
        this.changeDetectionRef = changeDetectionRef;
    }
    Object.defineProperty(MachinesDataviewTagsComponent.prototype, "machine", {
        set: function (machine) {
            this._machine = machine;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    MachinesDataviewTagsComponent.prototype.update = function () {
        var _this = this;
        this.tags$ = this.machineEntityTypeService.entityType.pseudoTags.get([this._machine]).pipe(map(function (tags) {
            return tags.concat(_this._machine.tags.map(function (tagStr) { return ({ id: tagStr, name: tagStr }); }));
        }));
        this.changeDetectionRef.markForCheck();
    };
    return MachinesDataviewTagsComponent;
}());
export { MachinesDataviewTagsComponent };
