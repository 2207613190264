var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SoftwareChangeEventsRelationship } from './../../../../../../../../../packages/@wcd/domain/src/tvm/software/software.relationships';
import { OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TabModel } from '../../../../../shared/components/tabs/tab.model';
import { EntityComponentBase } from '../../../../../global_entities/components/entity-contents.component.base';
import { SoftwareSecurityRecommendationRelationship, SoftwareVersionRelationship, SoftwareMissingKbsRelationship, SoftwareInstallationAggRelationship, TvmSupportedOsPlatform, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { ActivatedEntity } from '../../../../../global_entities/services/activated-entity.service';
import { take, filter, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ActivatedRoute, Router } from '@angular/router';
import { FeaturesService, Feature } from '@wcd/config';
import { SoftwareEntityReport } from './software-entity.report';
import { I18nService } from '@wcd/i18n';
import { TabsComponent } from '../../../../../shared/components/tabs/tabs.component';
export var SOFTWARE_CATEGORY;
(function (SOFTWARE_CATEGORY) {
    SOFTWARE_CATEGORY["NetworkGear"] = "NetworkGear";
})(SOFTWARE_CATEGORY || (SOFTWARE_CATEGORY = {}));
var SoftwareEntityComponent = /** @class */ (function (_super) {
    __extends(SoftwareEntityComponent, _super);
    /*
    TODO: We're hiding the total weakness count feature for now. uncomment when required.
    @ViewChild(ReportComponent) report: ReportComponent;
    totalWeaknessesCount$: Observable<number>; <see comment below>
    */
    function SoftwareEntityComponent(activatedRoute, paris, activatedEntity, changeDetectorRef, router, i18nService, featuresService) {
        var _this = _super.call(this) || this;
        _this.activatedRoute = activatedRoute;
        _this.paris = paris;
        _this.activatedEntity = activatedEntity;
        _this.changeDetectorRef = changeDetectorRef;
        _this.router = router;
        _this.i18nService = i18nService;
        _this.SpinnerSize = SpinnerSize;
        _this._routeToTabIdMap = {
            'event-insights': 'changeEvents',
        };
        _this.tabs = [];
        _this.countData = {
            securityRecommendations: null,
            vulnerabilities: null,
            installations: null,
            versions: null,
            missingKbs: null,
            changeEvents: null,
        };
        _this.isMissingKbsEnabled = featuresService.isEnabled(Feature.TvmMissingKbs);
        _this._tabNameSubscription = _this.activatedEntity.currentEntity$.subscribe(function (software) {
            if (!software)
                return;
            _this.tabs = _this.getTabs(software);
        });
        _this.softwareEntityReport = SoftwareEntityReport();
        return _this;
    }
    SoftwareEntityComponent.prototype.onActivate = function (currentCountProvider) {
        var _this = this;
        /*
        Select the right tab, when navigating
        */
        if (this.tabsComponent && this.tabs) {
            var id_1 = this.router.url
                .split('?')[0]
                .split('/')
                .pop();
            this.tabsComponent.selectTab(this.tabs.find(function (x) { return x.id.toLowerCase() === id_1 || x.id === _this._routeToTabIdMap[id_1]; }));
        }
        if (this._countSubscription)
            this._countSubscription.unsubscribe();
        if (currentCountProvider.itemCount$) {
            this._countSubscription = currentCountProvider.itemCount$
                .pipe(filter(function (count) { return count !== null; }))
                .subscribe(function (itemCount) {
                _this.fetchCountData(currentCountProvider.relationshipType, itemCount);
            });
        }
    };
    SoftwareEntityComponent.prototype.fetchCountData = function (relationship, itemCount) {
        var _this = this;
        if (this._subscription) {
            return; // Already fetched
        }
        this._subscription = this.activatedEntity.currentEntity$.subscribe(function (software) {
            if (!software)
                return;
            _this.countData.vulnerabilities = software.discoveredVulnerabilities;
            var supportMissingKbs = software.isKbProduct && _this.isMissingKbsEnabled;
            var countObservablesArray = [
                SoftwareSecurityRecommendationRelationship,
                SoftwareVersionRelationship,
                SoftwareInstallationAggRelationship,
            ];
            if (supportMissingKbs) {
                countObservablesArray.push(SoftwareMissingKbsRelationship);
            }
            countObservablesArray.push(SoftwareChangeEventsRelationship);
            var countObservables = countObservablesArray.map(function (relationshipClass) {
                if (relationshipClass === relationship) {
                    return of(itemCount);
                }
                var repo = _this.paris.getRelationshipRepository(relationshipClass);
                repo.sourceItem = software;
                var countObservable = repo
                    .query({
                    page: 1,
                    pageSize: 30,
                })
                    .pipe(take(1), map(function (data) { return data.count; }));
                return countObservable;
            });
            forkJoin(countObservables).subscribe(function (data) {
                _this.countData.securityRecommendations = data[0];
                _this.countData.versions = data[1];
                _this.countData.installations = data[2];
                _this.countData.missingKbs = data[3];
                _this.countData.changeEvents = data[supportMissingKbs ? 4 : 3];
                _this.tabs = _this.getTabs(software);
                _this.changeDetectorRef.markForCheck();
            });
        });
    };
    SoftwareEntityComponent.prototype.getTabs = function (software) {
        var _this = this;
        var tabsArray = [
            {
                id: 'overview',
                name: this.i18nService.get('tvm.common.overview'),
                routerLink: './overview',
                disabled: software.productNeverMatched,
                shouldForceActive: function () {
                    return (_this.router.url
                        .split('?')[0]
                        .split('/')
                        .pop() === 'overview');
                },
            },
            {
                id: 'recommendations',
                name: this.i18nService.get('tvm.common.securityRecommendations'),
                value: this.countData.securityRecommendations,
                routerLink: './recommendations',
                routerLinkQueryParams: { filters: 'status=Active' },
                disabled: software.productNeverMatched && this.countData.securityRecommendations === 0,
            },
            {
                id: 'vulnerabilities',
                name: this.i18nService.get('tvm.common.discoveredVulnerabilities'),
                value: this.countData.vulnerabilities,
                routerLink: './vulnerabilities',
                disabled: software.productNeverMatched,
            },
            {
                id: 'installations',
                name: software.category === SOFTWARE_CATEGORY.NetworkGear
                    ? this.i18nService.get('tvm.common.installedNetworkDevices')
                    : this.i18nService.get('tvm.common.installedDevices'),
                value: this.countData.installations,
                routerLink: './installations',
            },
            {
                id: 'versions',
                name: this.i18nService.get('tvm.common.versionDistribution'),
                value: this.countData.versions,
                routerLink: './versions',
            },
            {
                id: 'missingKbs',
                name: software.osPlatform === TvmSupportedOsPlatform.Linux
                    ? this.i18nService.strings.tvm_common_securityBulletins
                    : this.i18nService.strings.tvm_common_missingKbs,
                value: this.countData.missingKbs,
                routerLink: './missingkbs',
                disabled: !(software.isKbProduct && this.isMissingKbsEnabled),
            },
            {
                id: 'changeEvents',
                name: this.i18nService.strings.tvm_common_eventTimeline,
                value: this.countData.changeEvents,
                routerLink: './event-insights',
                disabled: software.productNeverMatched,
            },
        ];
        return tabsArray.filter(function (tab) { return !tab.disabled; }).map(function (tab) { return new TabModel(tab); });
    };
    /*
    TODO: We're hiding the total weakness count feature for now. uncomment when required.
    ngAfterViewInit() {

        const observables = [];

        this.report.allWidgets
            .filter(
                widget =>
                    widget instanceof MisconfigurationsDistributionWidget ||
                    widget instanceof VulnerabilitiesDistributionWidget
            )
            .map(widget => observables.push(widget.data$));

        this.totalWeaknessesCount$ = zip(...observables).pipe(
            map((values: SeverityDistributionMap[]) =>
                values.reduce((prevCount, severityDistributionMap) => {
                    if (!severityDistributionMap) return null;
                    const currCount = Object.keys(severityDistributionMap).reduce(
                        (prev, curr) => (prev += severityDistributionMap[curr]),
                        0
                    );
                    return prevCount + currCount;
                }, 0)
            )
        );
    }
    */
    SoftwareEntityComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
        this._countSubscription && this._countSubscription.unsubscribe();
        this._tabNameSubscription && this._tabNameSubscription.unsubscribe();
    };
    return SoftwareEntityComponent;
}(EntityComponentBase));
export { SoftwareEntityComponent };
