import { ICommandBarItemOptionsRenderContext } from '@angular-react/fabric';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	ElementRef,
	Injector,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataEntityType, EntityModelBase, Paris } from '@microsoft/paris';
import {
	BehaviorSubject,
	combineLatest,
	merge,
	Observable,
	ReplaySubject,
	Subject,
	Subscription,
} from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
import { ItemActionModel } from '../../dataviews/models/item-action.model';
import { I18nService } from '@wcd/i18n';
import { CommandBarItemService } from '../../shared/components/command-bar/command-bar-item.service';
import { ICommandBarItem } from '../../shared/components/command-bar/models/command-bar-item.models';
import { toObservable } from '../../utils/rxjs/utils';
import { EntityType } from '../models/entity-type.interface';
import { EntityViewType } from '../models/entity-view-type.enum';
import { EntityPageViewMode } from '../models/entity-page-view-mode.enum';
import { ActivatedEntity } from '../services/activated-entity.service';
import { GlobalEntityTypesService } from '../services/global-entity-types.service';
import { EntityComponentBase } from './entity-contents.component.base';
import { Tag } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { MainAppState, MainAppStateService } from '../../shared/main/services/main-app-state.service';
import { IButtonStyles, ICommandBarStyles } from 'office-ui-fabric-react';
import { config } from '@wcd/shared';
import { Panel, PanelType } from '@wcd/panels';
import { sccHostService } from '@wcd/scc-interface';

const buttonSize = 48;

// Styles used for AngularReact component.
// Need to be an object (unlike object literal where it's needed), as the change
// detection between Angular and React is a bit quirky and causes the page to stuck.
const commandBarStyles: IButtonStyles = {
	root: {
		backgroundColor: 'transparent !important',
		alignItems: 'flex-end',
		flexDirection: 'column',
	},
	rootHovered: {
		backgroundColor: '#EDEBE9 !important',
	},
	rootDisabled: {
		pointerEvents: 'bounding-box',
		backgroundColor: 'transparent',
	},
};

const assetCommandBarStyles: ICommandBarStyles = {
	root: {
		padding: 0,
		minWidth: buttonSize,
		height: buttonSize,
		backgroundColor: 'transparent',
		position: 'relative',
		right: '10px',
	},
	primarySet: {
		justifyContent: 'flex-end',
		paddingRight: '30px',
	},
};

@Component({
	selector: 'entity',
	templateUrl: './entity.component.html',
	styleUrls: ['./entity.component.scss'],
})
export class EntityComponent<TEntity extends EntityModelBase<TId>, TId extends string | number = string>
	implements OnInit, OnDestroy, AfterViewInit {
	entityType: EntityType<TEntity>;
	entity: TEntity;
	entityTypeName: string;
	commandBarItems$: Observable<ReadonlyArray<ICommandBarItem>>;
	options: {};
	itemActions$: Observable<ReadonlyArray<ItemActionModel<TEntity>>>;
	maxNameLength: number;
	isRenameEnabled: boolean;
	tags$: Observable<ReadonlyArray<Tag>>;
	isFullScreen: boolean;
	modernEntityPage: boolean = false;
	assetPage: boolean;
	commandBarStyles: ICommandBarStyles = null;
	hideEntitySummaryPanelLink: boolean = false;
	EntityPageViewMode = EntityPageViewMode;
	entityPageViewMode = EntityPageViewMode.Default;

	@ViewChild('entityContentsPlaceholder', { read: ViewContainerRef, static: true })
	entityContentsPlaceholder: ViewContainerRef;
	contentsComponentRef: ComponentRef<EntityComponentBase<TEntity>>;

	@ViewChild('tagsItem', { read: TemplateRef, static: true })
	tagsItemTemplateRef: TemplateRef<ICommandBarItemOptionsRenderContext>;

	@ViewChild('renameButton', { static: false })
	renameButton: ElementRef<HTMLButtonElement>;

	private _routeDataSubscription: Subscription;
	private _fullScreenSubscription: Subscription;
	private _tagsSubject$: Subject<ReadonlyArray<Tag>> = new Subject<ReadonlyArray<Tag>>();
	private readonly afterViewInit$ = new ReplaySubject<void>(1);
	mainAppState: MainAppState;
	tagEditOpen: boolean = false;
	openSummaryPanelNarrowScreen = false;
	isNarrowLayout = false;
	detailsPanelSettings = DETAILS_PANEL_SETTINGS;
	isScc: boolean = false;

	constructor(
		private readonly i18nService: I18nService,
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly resolver: ComponentFactoryResolver,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly activatedEntity: ActivatedEntity,
		private readonly paris: Paris,
		private readonly commandBarItemService: CommandBarItemService,
		public readonly authService: AuthService,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly mainAppStateService: MainAppStateService
	) {
		mainAppStateService.state$.subscribe((mainAppState: MainAppState) => {
			this.mainAppState = mainAppState;
		});
		this.isScc = sccHostService.isSCC;
	}

	ngOnInit() {
		this._fullScreenSubscription = this.activatedEntity.fullScreenEntity$.subscribe((isFullScreen: boolean) => this.toggleFullScreen(isFullScreen));
		this._routeDataSubscription = this.route.data.subscribe(({ entity, options, hideEntitySummaryPanelLink}) => {
			this.entityType = this.globalEntityTypesService.getEntityType(entity.constructor);
			this.entityTypeName = this.entityType.entity.entityConfig.singularName;
			this.options = options;
			this.isFullScreen = false;
			this.entityPageViewMode = this.getEntityPageViewMode();
			this.setEntity(entity);
			this.activatedEntity.setCurrentEntity(entity);
			this.maxNameLength = this.entityType.maxNameLength || 50;
			this.isRenameEnabled = this.entityType.getEnableRename
				? this.entityType.getEnableRename(this.entity)
				: true;
			this.hideEntitySummaryPanelLink = hideEntitySummaryPanelLink;

			this.setupEntityUpdateListener();
		});

		this.mainAppStateService.state$
		.pipe(
			filter((state: MainAppState) => {
				return (
					this.isNarrowLayout !== config.widthBreakpointSmallerThan(
						state.screenMaxWidthBreakpoint,
						config.msScreenSizeBreakpoints.xl
					)
				);
			})
		)
		.subscribe((state: MainAppState) => {
			this.isNarrowLayout = config.widthBreakpointSmallerThan(
				state.screenMaxWidthBreakpoint,
				config.msScreenSizeBreakpoints.xl
			);

			this.openSummaryPanelNarrowScreen = false;
			this.changeDetectorRef.detectChanges();
		});
	}

	entityUpdateListener = (e: CustomEvent) => {
		const {newEntityId, innerRoute, params} = e.detail;
		if (newEntityId !== this.entity.id || innerRoute) { // If the entity changed or if the entity haven't changed but the inner route changed (tab for example)
		   const entityTypePath = this.route.parent.routeConfig.path;
			const commands = ['/' + entityTypePath, newEntityId, e.detail.entityData ? e.detail.entityData : {}];
			if (innerRoute) commands.push(innerRoute);
			this.router.navigate(
				commands,
				{
					queryParams: params,
					queryParamsHandling: "merge", // remove to replace all query params by provided
				});
		}
	 }

	setupEntityUpdateListener () {
		if (this.entityType && this.entityType.id) {
			this.clearEntityUpdateListener();
			document.addEventListener(`${this.entityType.id}EntityUpdated`, this.entityUpdateListener);
		}
	}

	clearEntityUpdateListener () {
		if (this.entityType && this.entityType.id) {
			document.removeEventListener(`${this.entityType.id}EntityUpdated`, this.entityUpdateListener);
		}
	}

	showSummaryPanel = () => !this.modernEntityPage &&
		(!this.isNarrowLayout || this.openSummaryPanelNarrowScreen);

	closeSummaryPaneButtonStyles: IButtonStyles = {
		root: {
			minWidth: buttonSize,
			height: buttonSize,
			backgroundColor: 'transparent',
			float: 'right'
		},
	};

	toggleSummaryPanelInNarrowScreen(shouldOpen: boolean) {
		this.openSummaryPanelNarrowScreen = shouldOpen;
	}

	ngOnDestroy() {
		if (this._routeDataSubscription) {
			this._routeDataSubscription.unsubscribe();
		}

		if (this._fullScreenSubscription) {
			this._fullScreenSubscription.unsubscribe();
		}

		this.clearEntityUpdateListener();

		this.activatedEntity.setCurrentEntity(null);
		this.afterViewInit$.complete();
		this._tagsSubject$.complete();
	}

	ngAfterViewInit() {
		this.afterViewInit$.next();
	}

	setEntity(entity: TEntity) {
		this.entity = entity;

		// modernEntityPage is required for setting the action items (in "setEntityDetails"), so it should be set prior to it
		this.modernEntityPage = this.entityType.entityPageViewMode === EntityPageViewMode.Modern;

		this.assetPage = this.entityType.entityPageViewMode === EntityPageViewMode.Asset;

		this.setStyleSettings();

		this.setEntityDetails();
		this.setActions();

		this.setDynamicComponents();
	}

	private setStyleSettings() {
		if (this.modernEntityPage) {
			this.commandBarStyles = commandBarStyles;
		}

		if (this.assetPage) {
			this.commandBarStyles = assetCommandBarStyles;
		}
	}

	private setEntityDetails() {
		if (this.entityType.getTags) {
			this.tags$ = merge(toObservable(this.entityType.getTags([this.entity])), this._tagsSubject$).pipe(
				shareReplay(1)
			);
		}
	}

	private setActions() {
		this.itemActions$ = toObservable(
			this.entityType.getActions
				? this.entityType.getActions([this.entity], this.options, EntityViewType.EntityPage)
				: []
		);

		this.commandBarItems$ = combineLatest([this.itemActions$, this.afterViewInit$]).pipe(
			map(([itemActions, _]) => itemActions.map(itemAction =>
				this.actionToCommandBarItem({
					...itemAction,
					tooltip: itemAction.tooltip || itemAction.name || this.i18nService.get(itemAction.nameKey),
				})))
		);
	}

	private actionToCommandBarItem(itemAction: ItemActionModel<TEntity>): ICommandBarItem<TEntity> {
		if ((this.modernEntityPage || this.assetPage) && !itemAction.styles) {
			itemAction.styles = commandBarStyles;
		}
		return this.commandBarItemService.fromItemActionModel({
			itemActionModel: itemAction,
			tagsTemplateRef: this.tagsItemTemplateRef,
			onClick: itemActionModel => {
				itemActionModel.method([this.entity]).then(() => {
					if (itemActionModel.refreshOnResolve) {
						const params$ = toObservable(
							this.entityType && this.entityType.getItemParams
								? this.entityType.getItemParams(this.entity)
								: null
						);
						params$
							.pipe(
								switchMap(params =>
									this.paris.getItemById(
										<DataEntityType<TEntity, any, TId>>this.entityType.entity,
										this.entity.id,
										null,
										params
									)
								)
							)
							.subscribe((entity: TEntity) => {
								this.setEntity(entity);
								this.activatedEntity.setCurrentEntity(entity);
							});
					}
					this.changeDetectorRef.detectChanges();
				});
			},
		});
	}

	private setDynamicComponents() {
		this.setEntityContentsComponent();
	}

	private setEntityContentsComponent() {
		const injector = Injector.create({
			providers: [],
			parent: this.entityContentsPlaceholder.parentInjector,
		});
		const factory = this.resolver.resolveComponentFactory<EntityComponentBase<TEntity>>(
			this.entityType.entityContentsComponentType
		);

		if (this.entityContentsPlaceholder.length > 0) {
			this.entityContentsPlaceholder.clear();
		}

		this.contentsComponentRef = this.entityContentsPlaceholder.createComponent(
			factory,
			this.entityContentsPlaceholder.length,
			injector
		);

		this.contentsComponentRef.instance.entityPageViewMode = this.entityPageViewMode;

		Object.assign(this.contentsComponentRef.instance, {
			entity: this.entity,
			isFullScreen: this.isFullScreen,
			entityPageViewMode: this.entityPageViewMode,
		});

		// run action requested from the entity component
		combineLatest([this.contentsComponentRef.instance.runAction$.asObservable(), this.itemActions$])
			.pipe(
				map(([event, itemActions]) => ({
					action: itemActions.find(action => action.id === event.actionId),
					event,
				})),
				filter(({ action }) => Boolean(action))
			)
			.subscribe(({ event, action }) => action.method([this.entity], event.data));
	}

	setTags(tags: ReadonlyArray<Tag>, setTagsCallback: Function) {
		this._tagsSubject$.next(tags);
		setTagsCallback(tags);
	}

	tagsEditorToggled(open){
		this.tagEditOpen = open;
	}

	fireResizeEvent() {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 100);
	}

	entityIconProps = {
		iconName: FabricIconNames.ChromeClose
	}

	toggleFullScreen(isFullScreen: boolean) {
		this.isFullScreen = isFullScreen;
		this.changeDetectorRef.markForCheck();
		this.fireResizeEvent();
	}

	private getEntityPageViewMode(): EntityPageViewMode {
		return this.entityType.entityPageViewMode === EntityPageViewMode.Modern ||
			this.entityType.entityPageViewMode === EntityPageViewMode.Asset
			? this.entityType.entityPageViewMode
			: EntityPageViewMode.Default;
	}
}

export const DETAILS_PANEL_SETTINGS: Panel = new Panel({
	type: PanelType.large,
	showOverlay: false,
	isBlocking: false,
	isModal: true,
	isStatic: false,
	hasCloseButton: true,
});
