import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { Ip } from '../ip.entity';
import { EntityStatistics } from '../../entity/entity-statistics.value-object';

@EntityRelationship({
	sourceEntity: Ip,
	dataEntity: EntityStatistics,
	allowedTypes: [RelationshipType.OneToOne],
	cache: {
		time: 1000 * 60 * 5, // 5 minutes
		max: 10,
	},
	baseUrl: config => config.data.serviceUrls.threatIntel,
	endpoint: 'IpStats',
	allItemsEndpointTrailingSlash: false,
	getRelationshipData: (ip: Ip) => ({
		ip: ip.id,
	}),
	parseDataQuery: (dataQuery: DataQuery) => {
		const where: Record<string, string> =
			dataQuery && dataQuery.where ? (dataQuery.where as Record<string, string>) : {};

		return {
			lookingBackInDays: 30,
			...where,
		};
	},
})
export class IpIpStatsRelationship implements EntityRelationshipRepositoryType<Ip, EntityStatistics> {}
