import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core';
import {
	Alert,
	AlertClassification,
	AlertDetermination,
	AlertStatus,
	AlertClassificationType,
	ServiceSourceType
} from '@wcd/domain';
import { AlertsService, getCombinedClassificationMap } from '../../../@entities/alerts/services/alerts.service';
import { Paris } from '@microsoft/paris';
import { AuthService, MtpPermission } from '@wcd/auth';
import { filter, finalize } from 'rxjs/operators';
import { find, flatMap, uniq } from 'lodash-es';
import { IDropdownOption, IPersonaProps, MessageBarType } from 'office-ui-fabric-react';
import { I18nService } from '@wcd/i18n';
import { AlertPageService } from '../../../@entities/alerts/services/alert-page.service';
import { BehaviorSubject } from 'rxjs';
import { RbacControlSettings, RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { config } from '@wcd/shared';
import { MainAppState, MainAppStateService } from '../../../shared/main/services/main-app-state.service';
import { AssigneePickerComponent } from '../../../forms/components/assignee-picker.component';
import { Feature, PreferencesService, FeaturesService } from '@wcd/config';
import { AppInsightsService } from "../../../insights/services/app-insights.service";

@Component({
	selector: 'alerts-manage',
	templateUrl: './alerts-manage.component.html',
})
export class AlertsManageComponent implements OnInit, OnChanges {
	@Input() alerts: Array<Alert>;
	@Input() readonly: boolean;

	@Output() restored: EventEmitter<void> = new EventEmitter<void>();
	@Output() statusChanged: EventEmitter<AlertStatus> = new EventEmitter<AlertStatus>();
	@Output() classificationChanged: EventEmitter<AlertClassification> = new EventEmitter<AlertClassification>();
	@Output() determinationChanged: EventEmitter<AlertDetermination> = new EventEmitter<AlertDetermination>();
	@Output() assigneeChanged: EventEmitter<string> = new EventEmitter<string>();

	treeElementsUpdates$: BehaviorSubject<string>;
	selectedStatus: AlertStatus;
	selectedClassification: AlertClassification;
	MessageBarType = MessageBarType;
	selectedDetermination: AlertDetermination;
	selectableStatuses: Array<AlertStatus>;
	selectableAlertClassifications: Array<AlertClassification>;
	allAlertDeterminations: Array<AlertDetermination>;
	rbacSettings: RbacControlSettings;
	assignedTo: IPersonaProps;
	//for the new classification control
	selectedCombinedClassification = 'NotSet';
	combinedClassificationMap: IDropdownOption[] = [];

	isNarrowLayout: boolean = false;
	isUpdatingAlert: boolean = false;
	isUpdatingStatus: boolean = false;
	isUpdatingClassification: boolean = false;
	isUpdatingDetermination: boolean = false;
	isUpdatingAssignee: boolean = false;
	isAssignToSomeoneElseEnabled = false;
	isCombinedClassificationEnabled = false;

	private _lastSelectedStatus: AlertStatus;
	readonly AlertClassificationType = AlertClassificationType;
	readonly fixMessageBarButtonsOrder = { actions: { flexDirection: 'row', justifyContent: 'flex-end' } };

	get isSingleAlert(): boolean {
		return this.alerts && this.alerts.length === 1;
	}

	get alert(): Alert {
		return this.alerts[0];
	}

	constructor(
		private alertsService: AlertsService,
		private authService: AuthService,
		private changeDetectorRef: ChangeDetectorRef,
		private paris: Paris,
		private i18nService: I18nService,
		readonly appInsightService: AppInsightsService,
		readonly alertPageService: AlertPageService,
		mainAppStateService: MainAppStateService,
		private preferencesService: PreferencesService,
		private featuresService: FeaturesService
	) {
		this.treeElementsUpdates$ = this.alertPageService.treeElementsUpdates$;
		this.isAssignToSomeoneElseEnabled = this.featuresService.isEnabled(Feature.AssignToSomeoneElse);
		this.isCombinedClassificationEnabled = this.featuresService.isEnabled(Feature.CombinedClassification);
		this.combinedClassificationMap = getCombinedClassificationMap(this.i18nService);

		this.selectableStatuses = paris
			.getRepository(AlertStatus)
			.entity.values.filter(status => status.isSelectable);

		this.selectableAlertClassifications = paris
			.getRepository(AlertClassification)
			.entity.values.filter(status => status.isSelectable && (this.isCombinedClassificationEnabled || !status.combinedClassification))
			.sort((statusA, statusB) => statusA.priority - statusB.priority);

		this.allAlertDeterminations = paris.getRepository(AlertDetermination).entity.values.filter(value => this.isCombinedClassificationEnabled || !value.combinedClassification);

		mainAppStateService.state$
			.pipe(filter((state: MainAppState) => {
				return this.isNarrowLayout !== config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.medium);
			}))
			.subscribe((state: MainAppState) => {
				this.isNarrowLayout = config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.medium);
				this.changeDetectorRef.markForCheck();
			});
	}

	ngOnInit() {
		this.setManageModels();
		this.assignedTo = this.getAssignedTo();
		this.selectedCombinedClassification = this.getAlertClassification();
	}

	ngOnChanges(changes) {
		if (changes.alerts) this.setManageModels();
	}

	getAlertClassification() {
		return this.alerts.length === 1 && this.alerts[0].classification ? (this.alerts[0].determination && this.alerts[0].determination.type)
			: 'NotSet';
	}

	onNewClassificationDropdownChange(event) {
		this.selectedCombinedClassification = event.option.key;
		this.isUpdatingAlert = this.isUpdatingClassification = true;
		this.alertsService
			.setAlertsClassificationAndDetermination(this.alerts, this.selectedCombinedClassification)
			.then(() => {
				this.onUpdatedAlert();
			})
		this.changeDetectorRef.markForCheck();
	}

	/**
	 * Sets the common status, classification and determination for the alerts
	 */
	setManageModels() {
		if (!this.alerts || !this.alerts.length)
			this.selectedStatus = this.selectedClassification = this.selectedDetermination = null;
		else {
			const isSameStatus: boolean = this.alerts.every(alert => alert.status === this.alerts[0].status),
				isSameClassification: boolean = this.alerts.every(
					alert => alert.classification === this.alerts[0].classification
				),
				isSameDetermination: boolean = this.alerts.every(
					alert => alert.determination === this.alerts[0].determination
				);

			this.selectedStatus = this._lastSelectedStatus =
				isSameStatus &&
				this.alerts[0].status &&
				find(this.selectableStatuses, status => status.name === this.alerts[0].status.name);
			this.selectedClassification = isSameClassification && this.alerts[0].classification;
			this.selectedDetermination = isSameDetermination && this.alerts[0].determination;

			const workloads = uniq(flatMap(this.alerts.map(alert => alert.mtpWorkloads)));

			this.rbacSettings = {
				mtpPermissions: [MtpPermission.SecurityData_Manage],
				mtpWorkloads: workloads,
				requireAllPermissions: true,
				state: RbacControlState.disabled,
			};
			this.assignedTo = this.getAssignedTo();
		}
	}

	onStatusSelect(alertStatus: AlertStatus) {
		this.isUpdatingAlert = this.isUpdatingStatus = true;
		this.alertsService
			.setAlertsStatus(this.alerts, alertStatus, true, '[name=alert-status] button')
			.subscribe((alertStatus: AlertStatus) => {
				if (alertStatus) {
					this.statusChanged.emit(alertStatus);
					this.selectedStatus = this._lastSelectedStatus = alertStatus;
					this.appInsightService.trackEvent("alert-status-change", { status: alertStatus, context: "manage-alert"})
				} else this.selectedStatus = this._lastSelectedStatus;

				this.onUpdatedAlert();
			});
	}

	onClassificationSelectWithValue(selectedClassification: AlertClassification) {
		this.selectedClassification = selectedClassification;
		this.onClassificationSelect();
	}

	private alertsHaveServiceSource(serviceSource: ServiceSourceType) {
		this.alerts.forEach((alert) => {
				if (alert.serviceSource.id === serviceSource) {
					return true
			}
		})
		return false;
	}

	onClassificationSelect() {
		this.isUpdatingAlert = this.isUpdatingClassification = true;
		this.alertsService
			.setAlertsClassification(
				this.alerts,
				this.selectedClassification,
				'[name=alert-classification] button'
			)
			.pipe(finalize(() => this.onUpdatedAlert()))
			.subscribe(() => {
				this.appInsightService.trackEvent("alert-classification-change", { classification: this.selectedClassification, context: "manage-alert"})
				this.classificationChanged.emit(this.selectedClassification);
			});
	}

	onDeterminationSelect() {
		this.isUpdatingAlert = this.isUpdatingDetermination = true;
		this.alertsService
			.setAlertsDetermination(this.alerts, this.selectedDetermination)
			.pipe(finalize(() => this.onUpdatedAlert()))
			.subscribe(() => {
				this.appInsightService.trackEvent("alert-determination-change", { determination: this.selectedDetermination, context: "manage-alert"})
				this.determinationChanged.emit(this.selectedDetermination);
			});
	}

	restoreHiddenAlert() {
		this.isUpdatingAlert = true;
		this.alertsService.restoreHiddenAlerts(this.alerts).then(
			() => {
				this.restored.emit();
				this.onUpdatedAlert();
			},
			() => this.onUpdatedAlert()
		);
	}

	onSelectAssignee(assignee) {
		this.isUpdatingAssignee = true;
		this.alertsService
			.assignAlertsToSomeoneElse(this.alerts, assignee && assignee.secondaryText)
			.pipe(finalize(() => this.onUpdatedAlert()))
			.subscribe(() => {
				this.assigneeChanged.emit(assignee ? assignee.secondaryText : null);
				this.alerts.forEach(alert => alert.assignedTo = assignee ? assignee.secondaryText : null);
				this.assignedTo = this.getAssignedTo();
				this.appInsightService.trackEvent("alert-assignedTo-change", { context: "manage-alert"})
				AssigneePickerComponent.updateUserPreferredAssignees(assignee, this.preferencesService);
			});
	}

	getAssignedTo() {
		const assignee = this.alerts.length === 1 ? this.alerts[0].assignedTo :
			this.alerts.every(alert => alert.assignedTo === this.alerts[0].assignedTo) ? this.alerts[0].assignedTo : 'multipleAssignees';

		return assignee && {
			secondaryText: assignee
		}
	}

	private onUpdatedAlert() {
		this.isUpdatingAlert = this.isUpdatingStatus = this.isUpdatingClassification = this.isUpdatingDetermination = this.isUpdatingAssignee =  false;
		this.paris.getRepository(Alert).clearCache();
		this.alertPageService.updateTreeElement(this.alerts.map((alert: Alert) => alert.id));
		this.changeDetectorRef.markForCheck();
	}

	i18nFormat = item => {
		return this.i18nService.strings[item.nameI18nKey] || item.name;
	};
}
