var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { TreeElement } from './tree-element';
import { TreeElementAlertContext } from './tree-element-alert-context';
import { AlertUser } from '../alert-user.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.cyberData; }, ɵ1 = function (itemId, entity, config, params) {
    return "AlertPageData?\n\t\t\t\t\talertId=" + itemId + "\n\t\t\t\t\t" + (params['correlationId'] ? '&correlationId=' + params['correlationId'] : '') + "\n\t\t\t\t\t" + (params['officeTenantPrefix'] ? '&officeTenantPrefix=' + params['officeTenantPrefix'] : '');
};
var ProcessTree = /** @class */ (function (_super) {
    __extends(ProcessTree, _super);
    function ProcessTree() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ arrayOf: TreeElement }),
        __metadata("design:type", Array)
    ], ProcessTree.prototype, "treeElements", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], ProcessTree.prototype, "totalSize", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], ProcessTree.prototype, "maxDepth", void 0);
    __decorate([
        EntityField({ arrayOf: TreeElementAlertContext }),
        __metadata("design:type", Array)
    ], ProcessTree.prototype, "associatedAlerts", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", AlertUser)
    ], ProcessTree.prototype, "mainUser", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ProcessTree.prototype, "mainAlertId", void 0);
    ProcessTree = __decorate([
        Entity({
            singularName: 'Process Tree',
            pluralName: 'Process Trees',
            endpoint: 'AlertPageData',
            baseUrl: ɵ0,
            parseItemQuery: ɵ1,
        })
    ], ProcessTree);
    return ProcessTree;
}(EntityModelBase));
export { ProcessTree };
export { ɵ0, ɵ1 };
