import { Injectable } from '@angular/core';
import { WeaknessesCountBySeverity, SeverityValue, WeaknessSeverity, OsDistribution } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class SoftwareCommonService {
	private isDebianFeatureEnabled: boolean;

	constructor(featuresService: FeaturesService, private i18nService: I18nService) {
		this.isDebianFeatureEnabled = featuresService.isEnabled(Feature.TvmDataCookingEnableDebian);
	}

	getDecapitalizedString(str: string): string {
		return str
			.split(' ')
			.map(s => s.charAt(0).toLowerCase() + s.substring(1))
			.join('_');
	}

	generateSeveritiesDistributionData(
		weaknessCountBySeverityArr: Array<WeaknessesCountBySeverity>
	): Record<SeverityValue, number> {
		const returnObj: Record<SeverityValue, number> = {
			[SeverityValue.Critical]: 0,
			[SeverityValue.High]: 0,
			[SeverityValue.Medium]: 0,
			[SeverityValue.Low]: 0,
		};
		weaknessCountBySeverityArr.forEach(countByWeaknessSeverity => {
			/*
				PM: include the unknown severities in the total count, and add them to the HIGH distribution:
			*/
			if (
				[WeaknessSeverity.High, WeaknessSeverity.None, WeaknessSeverity.Unknown].includes(
					countByWeaknessSeverity.severity
				)
			) {
				returnObj[SeverityValue.High] += countByWeaknessSeverity.weaknesses;
			} else {
				returnObj[countByWeaknessSeverity.severity] = countByWeaknessSeverity.weaknesses;
			}
		});
		return returnObj;
	}

	getOsDistributionFilterData = (): { osDistribution: { values: { value: string; name: string }[] } } => ({
		osDistribution: {
			values: Object.keys(OsDistribution)
				.filter(
					distribution =>
						distribution !== OsDistribution.None &&
						(this.isDebianFeatureEnabled || distribution !== OsDistribution.Debian))
				.map(osDistribution => ({
					value: osDistribution,
					name: this.i18nService.get(`tvm_common_osDistribution_${OsDistribution[osDistribution]}`),
				})),
		},
	});
}
