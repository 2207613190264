/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "@angular/common";
import * as i4 from "./email-entity-urls-field.component";
import * as i5 from "../../../dialogs/services/dialogs.service";
var styles_EmailEntityUrlsFieldComponent = [];
var RenderType_EmailEntityUrlsFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmailEntityUrlsFieldComponent, data: {} });
export { RenderType_EmailEntityUrlsFieldComponent as RenderType_EmailEntityUrlsFieldComponent };
function View_EmailEntityUrlsFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "a", [["class", "btn-inline btn-link no-padding btn-link-high-contrast"], ["data-track-id", "ShowEmailUrlsPanel"], ["data-track-type", "Button"], ["role", "link"], ["tabindex", "0"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMailMessageUrls() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.showMailMessageUrls() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t\t\t", "\n\t\t\t"])), i0.ɵpod(4, { count: 0 }), i0.ɵppd(5, 2), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "airsEntities_emailMessage_fields_urls_and_threats_seeAll", _ck(_v, 4, 0, _co.urls.length))); _ck(_v, 3, 0, currVal_0); }); }
function View_EmailEntityUrlsFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["\n\t\t\t", "\n\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.airsEntities_emailMessage_fields_urls_and_threats_noUrlsAndThreatsFound; _ck(_v, 0, 0, currVal_0); }); }
export function View_EmailEntityUrlsFieldComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailEntityUrlsFieldComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["noUrls", 2]], null, 0, null, View_EmailEntityUrlsFieldComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.urls == null) ? null : _co.urls.length); var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_EmailEntityUrlsFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "email-entity-urls-field", [], null, null, null, View_EmailEntityUrlsFieldComponent_0, RenderType_EmailEntityUrlsFieldComponent)), i0.ɵdid(1, 49152, null, 0, i4.EmailEntityUrlsFieldComponent, [i2.I18nService, i5.DialogsService], null, null)], null, null); }
var EmailEntityUrlsFieldComponentNgFactory = i0.ɵccf("email-entity-urls-field", i4.EmailEntityUrlsFieldComponent, View_EmailEntityUrlsFieldComponent_Host_0, { urls: "urls", potentiallyHarmfulUrls: "potentiallyHarmfulUrls" }, {}, []);
export { EmailEntityUrlsFieldComponentNgFactory as EmailEntityUrlsFieldComponentNgFactory };
