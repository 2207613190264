var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { BaselineProfileDetails, BaselineProfileDeviceIds, Machine } from '@wcd/domain';
import { ActivatedEntity } from '../../../../../../global_entities/services/activated-entity.service';
import { EntityPanelComponentBase } from '../../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { TabModel } from '../../../../../../shared/components/tabs/tab.model';
import { filter } from 'rxjs/operators';
var TabIds;
(function (TabIds) {
    TabIds["deviceDetails"] = "deviceDetails";
    TabIds["configurations"] = "configurations";
})(TabIds || (TabIds = {}));
var ProfileDeviceEntityPanelComponent = /** @class */ (function (_super) {
    __extends(ProfileDeviceEntityPanelComponent, _super);
    function ProfileDeviceEntityPanelComponent(activatedEntity, i18nService, paris, changeDetectorRef) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.activatedEntity = activatedEntity;
        _this.i18nService = i18nService;
        _this.tabs = [];
        _this.tabIds = TabIds;
        _this.machineRepository = paris.getRepository(Machine);
        return _this;
    }
    Object.defineProperty(ProfileDeviceEntityPanelComponent.prototype, "baselineProfileDevice", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    ProfileDeviceEntityPanelComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    ProfileDeviceEntityPanelComponent.prototype.initEntity = function (baselineProfileDevice, isExtendedData) {
        var _this = this;
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, baselineProfileDevice, isExtendedData);
        this.machineRepository.getItemById(baselineProfileDevice.id).subscribe(function (m) {
            _this.machine = m;
            _this.changeDetectorRef.markForCheck();
        });
        this.tabs = this.getTabs();
        this.currentTab = this.tabs[0];
        this._subscription = this.activatedEntity.currentEntity$
            .pipe(filter(function (entity) { return entity instanceof BaselineProfileDetails; }))
            .subscribe(function (profile) {
            _this.profileDeviceIds = new BaselineProfileDeviceIds({
                id: _this.baselineProfileDevice.id,
                deviceId: _this.baselineProfileDevice.id,
                profileId: profile.id
            });
            _this.profile = profile;
        });
    };
    ProfileDeviceEntityPanelComponent.prototype.setTab = function (tab) {
        this.currentTab = tab;
    };
    ProfileDeviceEntityPanelComponent.prototype.getTabs = function () {
        return [
            {
                id: this.tabIds.deviceDetails,
                name: this.i18nService.strings.tvm_baseline_profile_entity_deviceTab_sidePanel_deviceDetails_title,
            },
            {
                id: this.tabIds.configurations,
                name: this.i18nService.strings.tvm_baseline_profile_entity_configurationTab_title,
            }
        ].map(function (tab) { return new TabModel(tab); });
    };
    return ProfileDeviceEntityPanelComponent;
}(EntityPanelComponentBase));
export { ProfileDeviceEntityPanelComponent };
