import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './components/error-page.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ErrorTypesService } from './services/error-types.service';
import { ErrorsService } from '../shared/services/errors.service';

export const errorRoutes: Routes = [
	{
		path: 'errors',
		component: ErrorPageComponent,
		data: {
			pageTitle: 'Errors page',
		},
	},
	{
		path: 'errors/:type',
		component: ErrorPageComponent,
		data: {
			pageTitle: 'Errors page',
		},
	},
	{
		path: 'Error',
		component: ErrorPageComponent,
		data: {
			isLoginError: true,
			pageTitle: 'Error page',
		},
	},
	{
		path: 'Error/:type',
		component: ErrorPageComponent,
		data: {
			isLoginError: true,
			pageTitle: 'Error page',
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(errorRoutes), SharedModule],
	providers: [ErrorTypesService, ErrorsService],
	declarations: [ErrorPageComponent],
	exports: [RouterModule],
})
export class ErrorModule {}
