<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="investigation && investigation.status && isMessageBarVisible" class="wcd-margin-large-horizontal wcd-margin-vertical">
	<fab-message-bar
		[messageBarType]="MessageBarType.info"
		[isMultiline]="true"
		[styles]="investigationMessageBarStyle"
		contentClass="wcd-flex-horizontal wcd-padding-small-all"
	>
		<span class="investigation-message-bar">{{
			'alerts.panel.investigation.status.title.prefix' | i18n
		}}</span>
		<entity-name
			[entity]="investigation"
			[entityName]="shortInvestigationId"
			[entityTypeId]="'investigation'"
			[hideIcon]="true"
			[linkClass]="'wcd-padding-none'"
			[trackId]="'InvestigationNameLink'"
		></entity-name>
		<span class="investigation-message-bar">{{
			'alerts.panel.investigation.status.title.suffix' | i18n
		}}</span>
		<span>
			<wcd-shared-icon
				[iconName]="investigation.status.iconName"
				[ngClass]="'color-text-' + investigation.status.className"
				class="small-icon"
			>
			</wcd-shared-icon>
			{{ investigation.isPending ? investigation.pendingType.name : investigation.status.name }}
		</span>
	</fab-message-bar>
</div>

<wcd-collapsible-section
	data-track-component="AlertEntityPanelControls"
	[sectionId]="collapsibleID.AlertsManage"
	[label]="'alerts.panel.manageAlert' | i18n"
>
	<div [class.disabled]="(isUserExposed$ | async) !== true || alert.isReadOnly">
		<alerts-manage
			[alerts]="[alert]"
			[readonly]="(isUserExposed$ | async) !== true || alert.isReadOnly"
			(statusChanged)="onStatusChanged($event)"
			(classificationChanged)="onClassificationChanged($event)"
			(determinationChanged)="onDeterminationChanged($event)"
			(assigneeChanged)="onAssigneeChanged($event)"
			(restored)="requestEntitiesRefresh.emit()"
		></alerts-manage>
	</div>

	<ng-container *ngIf="suppressionRule">
		<dl class="wcd-margin-large-top" [class.key-values]="true">
			<dt role="none">{{ 'suppressionRules.title' | i18n }}</dt>
			<dd role="none">
				<a
					(click)="openSuppressionRulePanel()"
					data-track-id="SuppressionRuleNameLink"
					data-track-type="Navigation"
				>
					{{ suppressionRule.name }}
				</a>
			</dd>
		</dl>
	</ng-container>
</wcd-collapsible-section>

<wcd-collapsible-section [label]="'alerts.panel.details.title' | i18n" [sectionId]="collapsibleID.Details">
	<alert-entity-details [entity]="alert" [showTitle]="false" [mode]="EntityDetailsMode.SidePane">
	</alert-entity-details>
</wcd-collapsible-section>

<wcd-collapsible-section
	*ngIf="alert.description"
	[label]="'alerts.alertDescription' | i18n"
	[sectionId]="collapsibleID.Description"
>
	<alerted-description
		[description]="alert.description"
		[linkToEncyclopedia]="alert.encyclopediaUrl"
		[allowHtmlRendering]="alert.isThreatExpertOriginated"
		[oAuthAlertPageLinkLabel]="alert.oAuthAlertPageLinkDetails && alert.oAuthAlertPageLinkDetails.oAuthAppName"
		[oAuthAlertPageLink]="oAuthAlertPageLink"
	>
	</alerted-description>
</wcd-collapsible-section>

<wcd-collapsible-section
	*ngIf="alert.recommendedAction"
	[label]="'alerts.alert.recommendedActions' | i18n"
	[sectionId]="collapsibleID.RecommendedAction"
>
	<alerted-description
		[recommendedAction]="alert.recommendedAction"
		[linkToEncyclopedia]="alert.encyclopediaUrl"
		[allowHtmlRendering]="alert.isThreatExpertOriginated"
	>
	</alerted-description>
</wcd-collapsible-section>

<wcd-collapsible-section
	*ngIf="alert.isCustomDetection"
	[label]="'alerts_alertCustomDetectionRuleDetails' | i18n"
	[sectionId]="collapsibleID.CustomDetectionRule"
>
	<alert-custom-detection-rule [alert]="alert"> </alert-custom-detection-rule>
</wcd-collapsible-section>

<wcd-collapsible-section
	*ngIf="alert.actor"
	[label]="'alerts.alertActor' | i18n"
	[sectionId]="collapsibleID.Actor"
>
	<alerted-actor [actorName]="alert.actor"> </alerted-actor>
</wcd-collapsible-section>

<wcd-collapsible-section
	*ngIf="
		investigationEnabledByLicense && options.showInvestigation !== false &&
		(investigation ||
			isLoadingInvestigation ||
			(alert.investigationStatus && !alert.investigationStatus.isDisabled))
	"
	[label]="'entityCommon.panel.relatedInvestigation.header' | i18n"
	[sectionId]="collapsibleID.RelatedInvestigation"
>
	<span *ngIf="isLoadingInvestigation; else investigationDetails">
		<i class="loader-icon"></i> {{ 'entityCommon.panel.relatedInvestigation.loading' | i18n }}
	</span>
	<ng-template #investigationDetails>
		<investigation-entity-details
			*ngIf="investigation; else investigationStatus"
			[investigation]="investigation"
		></investigation-entity-details>
		<ng-template #investigationStatus>
			<wcd-shared-icon [iconName]="alert.investigationStatus.iconName"> </wcd-shared-icon>
			<wcd-help
				[text]="'investigations.status.' + alert.investigationStatus.type + '.description' | i18n"
			>
				{{
					i18nService.strings[
						'investigations_status_' + alert.investigationStatus.type + '_title'
					] || alert.investigationStatus.name
				}}
			</wcd-help>
		</ng-template>
	</ng-template>
</wcd-collapsible-section>

<wcd-collapsible-section
	*ngIf="incident && incident.alertCount"
	[sectionId]="collapsibleID.RelatedIncidentAlerts"
	[label]="'alerts.panel.incident.details.title' | i18n"
	[isExpanded]="false"
>
	<alert-incident-details [incident]="incident"></alert-incident-details>
</wcd-collapsible-section>

<wcd-collapsible-section
	*ngIf="!isMtpEnabled && (alert.machine || alert.user)"
	[sectionId]="collapsibleID.ImpactedAssets"
	[label]="'alerts.panel.impactedAssets' | i18n"
	[isExpanded]="false"
>
	<alert-impacted-assets [machine]="machine" [user]="alert.user"> </alert-impacted-assets>
</wcd-collapsible-section>

<wcd-collapsible-section
	*ngIf="alert.aggregatedIncidentLinkedBy?.length"
	[sectionId]="collapsibleID.LinkByIncident"
	[label]="'alerts.panel.linkedByIncidentDetails.header' | i18n"
	[isExpanded]="false"
	(isExpandedChange)="$event && updateLinkByTable()"
>
	<alert-linked-by-details [alertId]="alert.id" [aggregatedLinkedBy]="alert.aggregatedIncidentLinkedBy">
	</alert-linked-by-details>
</wcd-collapsible-section>

<wcd-collapsible-section
	*ngIf="isMtpEnabled && alert.impactedEntities"
	[sectionId]="collapsibleID.ImpactedEntities"
	[label]="'entityCommon.panel.impactedEntities.top' | i18n"
	[isExpanded]="false"
>
	<impacted-entities-datatables
		[entities]="alert.impactedEntities"
		[openPanelForAllItems]="true"
		[showTitle]="false"
	>
	</impacted-entities-datatables>
</wcd-collapsible-section>

<wcd-collapsible-section
	*ngIf="alert.relatedEvidenceCount"
	[sectionId]="collapsibleID.RelatedEvidence"
	[isExpanded]="false"
	label="{{ 'entityCommon.panel.relatedEvidence.header' | i18n }} ({{
		getAlertRelatedEvidenceCount(alert)
	}})"
>
	<related-evidence [relatedEvidenceCount]="alert.relatedEvidenceCount"></related-evidence>
</wcd-collapsible-section>

<wcd-collapsible-section
	*ngIf="options?.showHistory !== false && (history || (isUserExposed$ | async))"
	[label]="'entityCommon.panel.comments.header' | i18n"
	[sectionId]="collapsibleID.AlertComments"
	[isExpanded]="false"
>
	<alerts-comments
		[comments]="history"
		[readonly]="!(isUserExposed$ | async)"
		[alerts]="entities"
	></alerts-comments>
</wcd-collapsible-section>
