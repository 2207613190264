import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Resource',
	pluralName: 'Resources',
})
export class DeviceResource extends ModelBase {
	@EntityField({ data: ['Id', 'ResourceId'] })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id?: string;

	@EntityField({ data: ['DisplayName', 'ResourceDisplayName'] })
	name?: string;

	@EntityField({ data: ['LastSeen', 'LastAccessTime'] })
	lastSeen?: Date;

	@EntityField({ data: ['DomainName', 'ResourceDomainName'] })
	domainName?: string;
}
