var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
import { AirsEntityVerdict } from '../airs_entity/airs-entity-verdict.entity';
var IpDetails = /** @class */ (function (_super) {
    __extends(IpDetails, _super);
    function IpDetails() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", String)
    ], IpDetails.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", String)
    ], IpDetails.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'Country' }),
        __metadata("design:type", String)
    ], IpDetails.prototype, "country", void 0);
    __decorate([
        EntityField({ data: 'State' }),
        __metadata("design:type", String)
    ], IpDetails.prototype, "state", void 0);
    __decorate([
        EntityField({ data: 'City' }),
        __metadata("design:type", String)
    ], IpDetails.prototype, "city", void 0);
    __decorate([
        EntityField({ data: 'Organization' }),
        __metadata("design:type", String)
    ], IpDetails.prototype, "organization", void 0);
    __decorate([
        EntityField({ data: 'Asn' }),
        __metadata("design:type", String)
    ], IpDetails.prototype, "asn", void 0);
    __decorate([
        EntityField({ data: 'Verdict' }),
        __metadata("design:type", AirsEntityVerdict)
    ], IpDetails.prototype, "verdict", void 0);
    __decorate([
        EntityField({ data: 'ISP' }),
        __metadata("design:type", String)
    ], IpDetails.prototype, "isp", void 0);
    __decorate([
        EntityField({ data: 'Carrier' }),
        __metadata("design:type", String)
    ], IpDetails.prototype, "carrier", void 0);
    IpDetails = __decorate([
        ValueObject({
            singularName: 'Ip address details',
            pluralName: 'Ip addresses details',
        })
    ], IpDetails);
    return IpDetails;
}(ModelBase));
export { IpDetails };
