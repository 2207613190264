export enum OsDistribution {
	None = 'None',
	RedHat = 'RedHat',
	Ubuntu = 'Ubuntu',
	OracleLinux = 'OracleLinux',
	CentOs = 'CentOs',
	Debian = 'Debian',
	Sles = 'Sles',
	AmazonLinux = 'AmazonLinux'
}
