import { Entity } from '@microsoft/paris';
import { InvestigationActionStatus } from '../../investigation/actions/investigation-action-status.entity';
import { investigationActionStatusValues } from '../../investigation/actions/investigation-action-status.values';

@Entity({
	singularName: 'Investigation Action Status',
	pluralName: 'Investigation Action Statuses',
	values: investigationActionStatusValues.concat({
		id: 9,
		type: 'cancelled',
		name: 'Canceled',
		iconName: 'Cancel',
		className: 'partial',
	}),
})
export class LiveResponseCommandStatus extends InvestigationActionStatus {}
