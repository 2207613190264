import { ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit, } from '@angular/core';
import { MainSearchEntityTypesService } from '../services/main-search-entity-types.service';
import { SearchService } from '../services/search.service';
import { merge, Subject } from 'rxjs';
import { ShortcutEvent, ShortcutsService } from '../../dialogs/shortcuts/shortcuts.service';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { FancySelectComponent } from '../../forms/components/fancy-select.component';
import { MainAppStateService } from '../../shared/main/services/main-app-state.service';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter } from 'rxjs/operators';
import { PreferencesService } from '@wcd/config';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { omit } from 'lodash-es';
import { breadcrumbsStateService } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';
var MIN_SEARCH_LENGTH = 3;
var LAST_SEARCH_TYPE_PREFERENCE_ID = 'lastSearchType';
var SEARCH_RESULTS_URLS = Object.freeze([
    /^\/?search[\./]/,
    /^\/?file[\./]/,
    /^\/?ip[\./]/,
    /^\/?url[\./]/,
]);
var MainSearchComponent = /** @class */ (function () {
    function MainSearchComponent(searchService, changeDetectionRef, shortcutsService, mainAppStateService, appInsightsService, preferencesService, router, mainSearchEntityTypesService, liveAnnouncer, i18nService) {
        var _this = this;
        this.searchService = searchService;
        this.changeDetectionRef = changeDetectionRef;
        this.appInsightsService = appInsightsService;
        this.preferencesService = preferencesService;
        this.mainSearchEntityTypesService = mainSearchEntityTypesService;
        this.liveAnnouncer = liveAnnouncer;
        this.i18nService = i18nService;
        this.isHovered = false;
        this.close = new EventEmitter();
        this.isScc = false;
        this.searchDebouncer = new Subject();
        this.lengthError = false;
        this.persistSearchValue = false;
        var type;
        var lastTypeId = this.preferencesService.getPreference(LAST_SEARCH_TYPE_PREFERENCE_ID);
        this.searchEntityTypes = mainSearchEntityTypesService.all.map(function (searchEntityType) { return omit(searchEntityType, 'entityType'); });
        if (lastTypeId) {
            type = this.searchEntityTypes.find(function (type) { return type.id === lastTypeId; });
        }
        else {
            type = mainSearchEntityTypesService.default;
        }
        this.setEntityType(type);
        this._shortcutsSubscription = shortcutsService.events$
            .pipe(filter(function (event) { return event === ShortcutEvent.search; }))
            .subscribe(function () {
            if (!_this.isShownInFixedMode) {
                mainAppStateService.toggleMainSearch(true);
            }
            setTimeout(function () { return _this.focusOnSearchTerm(); }, 100);
        });
        this._headerSearchClicksSubscription = mainAppStateService.state$.subscribe(function (mainAppState) {
            if (mainAppState.mainSearchShown) {
                setTimeout(function () { return _this.focusOnSearchTerm(); }, 100);
            }
        });
        this._navigationSubscription = merge(router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })), mainAppStateService.navChange$).subscribe(function (event) {
            var url = event instanceof NavigationEnd ? event.url : event;
            if (!SEARCH_RESULTS_URLS.some(function (routeRegExp) { return routeRegExp.test(url); }) &&
                !_this.persistSearchValue) {
                _this.clearSearch();
            }
            _this.persistSearchValue = false;
        });
    }
    Object.defineProperty(MainSearchComponent.prototype, "active", {
        get: function () {
            return this.typeFocused || this.textFocused;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MainSearchComponent.prototype, "isShownInFixedMode", {
        get: function () {
            var headerSearchBtn = (document.getElementById('main-header-search-btn'));
            return headerSearchBtn && window.getComputedStyle(headerSearchBtn).display === 'none';
        },
        enumerable: true,
        configurable: true
    });
    MainSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchDebouncer.pipe(debounceTime(300)).subscribe(function () { return _this.resolveType(); });
    };
    MainSearchComponent.prototype.ngOnDestroy = function () {
        this._shortcutsSubscription && this._shortcutsSubscription.unsubscribe();
        this._headerSearchClicksSubscription && this._headerSearchClicksSubscription.unsubscribe();
        this._navigationSubscription && this._navigationSubscription.unsubscribe();
    };
    MainSearchComponent.prototype.resolveType = function () {
        var type = this.searchService.resolveType(this.searchTerm);
        if (type) {
            this.setEntityType(type);
        }
    };
    MainSearchComponent.prototype.search = function () {
        var _this = this;
        if (!this.searchTerm) {
            return;
        }
        else if (this.searchTerm.length && this.searchTerm.length < MIN_SEARCH_LENGTH) {
            this.lengthError = true;
            this.liveAnnouncer.announce(this.i18nService.get('main.search.minLengthError'), 'assertive');
            setTimeout(function () {
                _this.lengthError = false;
                _this.changeDetectionRef.markForCheck();
            }, 3000);
            return;
        }
        else {
            // Resetting the breadcrumbs fixes time the breadcrumbs would stay when automatically
            // redirected to the search result (i.e. when searching for an IP or a URL)
            if (!sccHostService.isSCC) {
                breadcrumbsStateService.reset();
            }
            this.close.emit(null);
            this.persistSearchValue = true;
            this.searchService.onSearch(this.searchTerm, this.currentSearchEntityType);
            this.changeDetectionRef.markForCheck();
            this.appInsightsService.track({
                type: TrackingEventType.Selection,
                id: "searchType_" + this.currentSearchEntityType.id,
                component: 'Main Search',
            });
        }
    };
    MainSearchComponent.prototype.typeBlurred = function () {
        var _this = this;
        setTimeout(function () {
            _this.typeFocused = false;
            _this.changeDetectionRef.markForCheck();
        }, 100);
    };
    MainSearchComponent.prototype.typeChanged = function () {
        this.setTypeToPreference();
        this.focusOnSearchTerm();
    };
    MainSearchComponent.prototype.cancelSearch = function () {
        this.clearSearch();
        this.close.emit(null);
        if (this.searchTypeInput.dropdown.isOpen) {
            this.searchTypeInput.dropdown.close();
        }
        this.searchInput.nativeElement.blur();
        this.typeFocused = false;
        this.changeDetectionRef.markForCheck();
    };
    MainSearchComponent.prototype.searchTermChanged = function () {
        this.searchTerm = this.searchTerm.trim();
        this.searchDebouncer.next();
    };
    MainSearchComponent.prototype.setEntityType = function (type) {
        var matchingSearchEntityType = type
            ? this.searchEntityTypes.find(function (searchEntityType) { return searchEntityType.id === type.id; })
            : null;
        this.currentSearchEntityType = matchingSearchEntityType || this.mainSearchEntityTypesService.default;
        this.setTypeToPreference();
        this.changeDetectionRef.markForCheck();
    };
    MainSearchComponent.prototype.focusOnSearchTerm = function () {
        this.searchInput.nativeElement.focus();
        this.changeDetectionRef.markForCheck();
    };
    MainSearchComponent.prototype.clearSearch = function () {
        this.searchTerm = null;
        this.persistSearchValue = false;
        this.changeDetectionRef.markForCheck();
    };
    MainSearchComponent.prototype.setTypeToPreference = function () {
        this.preferencesService.setPreference(LAST_SEARCH_TYPE_PREFERENCE_ID, this.currentSearchEntityType.id);
    };
    return MainSearchComponent;
}());
export { MainSearchComponent };
