var _a;
import { AirsEntityTypeValue } from '../airs-entity-type.entity';
import { MailClusterUtils } from '../../mailbox/mail-cluster.utils';
// In case of empty subject in mail message - show this text in Evidence table
var ENTITY_COLUMN_NO_SUBJECT = '(no subject)';
export var EntityDisplayNameFunction = Object.freeze((_a = {},
    _a[AirsEntityTypeValue.MailCluster] = function (entityData) {
        return MailClusterUtils.getDisplayQuery(entityData["ClusterBy" /* ClusterBy */], entityData["ClusterByValue" /* ClusterByValue */]);
    },
    _a[AirsEntityTypeValue.MailMessage] = function (entityData) {
        return entityData["Subject" /* Subject */] || ENTITY_COLUMN_NO_SUBJECT;
    },
    _a[AirsEntityTypeValue.SubmissionMail] = function (entityData) {
        return entityData["Subject" /* Subject */] || ENTITY_COLUMN_NO_SUBJECT;
    },
    _a[AirsEntityTypeValue.Mailbox] = function (entityData) {
        return entityData["MailboxPrimaryAddress" /* MailboxPrimaryAddress */] || '';
    },
    _a[AirsEntityTypeValue.MailboxConfiguration] = function (entityData) {
        return entityData["MailboxPrimaryAddress" /* MailboxPrimaryAddress */] || '';
    },
    _a[AirsEntityTypeValue.URL] = function (entityData) {
        return entityData["Url" /* Url */] || '';
    },
    _a[AirsEntityTypeValue.IP] = function (entityData) {
        return entityData["Address" /* Address */] || '';
    },
    _a[AirsEntityTypeValue.File] = function (entityData) {
        return entityData["Name" /* Name */] || '';
    },
    _a[AirsEntityTypeValue.Process] = function (entityData) {
        var imageFile = entityData["ImageFile" /* ImageFile */];
        var name = imageFile && imageFile.Name;
        var processId = entityData["ProcessId" /* ProcessId */];
        return name && processId ? name + " (" + processId + ")" : name ? name : '';
    },
    _a[AirsEntityTypeValue.SecurityGroup] = function (entityData) {
        return entityData["FriendlyName" /* FriendlyName */] || '';
    },
    _a[AirsEntityTypeValue.RegistryKey] = function (entityData) {
        return entityData["Key" /* Key */] || '';
    },
    _a[AirsEntityTypeValue.RegistryValue] = function (entityData) {
        var valueData = entityData["Value" /* Value */];
        var valueName = entityData["Name" /* Name */];
        return valueName ? (valueData ? valueName + ": " + valueData : valueName) : '';
    },
    _a[AirsEntityTypeValue.CloudApplication] = function (entityData) {
        return entityData["Name" /* Name */] || '';
    },
    _a[AirsEntityTypeValue.CloudLogonSession] = function (entityData) {
        var accountData = entityData["Account" /* Account */];
        return (accountData && accountData['DisplayName']) || '';
    },
    _a[AirsEntityTypeValue.OauthApplication] = function (entityData) {
        return entityData["Name" /* Name */] || '';
    },
    _a[AirsEntityTypeValue.ActiveDirectoryDomain] = function (entityData) {
        return entityData["ActiveDirectoryDomainName" /* ActiveDirectoryDomainName */] || '';
    },
    _a));
