var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, Injector } from '@angular/core';
import { Alert, CyberEvent, CyberEventActionTypeName } from '@wcd/domain';
import { EntitiesPanelComponentBase } from '../../../global_entities/components/entity-panels/entities-panel.component.base';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { Paris } from '@microsoft/paris';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { CyberEventsUtilsService } from '../services/cyber-events-utils.service';
import { FULL_DATE_WITH_MILLISECONDS_FORMAT } from '@wcd/localization';
import { CyberEventsActionTypesService } from '../services/cyber-events-action-types.service';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["EventDetails"] = "cyber-events-entity-panel-event-details";
    CollapsibleID["EventGraph"] = "cyber-events-entity-panel-event-graph";
    CollapsibleID["AlertDetails"] = "cyber-events-entity-panel-alert-details";
    CollapsibleID["AlertDescription"] = "cyber-event-entity-panel-alert-description";
    CollapsibleID["AlertRecommendedAction"] = "cyber-event-entity-panel-alert-recommended-action";
})(CollapsibleID || (CollapsibleID = {}));
var CyberEventsEntityPanelComponent = /** @class */ (function (_super) {
    __extends(CyberEventsEntityPanelComponent, _super);
    function CyberEventsEntityPanelComponent(injector, changeDetectorRef, paris, appInsightsService, cyberEventsUtilsService, cyberEventsActionTypesService) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        _this.changeDetectorRef = changeDetectorRef;
        _this.paris = paris;
        _this.appInsightsService = appInsightsService;
        _this.cyberEventsUtilsService = cyberEventsUtilsService;
        _this.cyberEventsActionTypesService = cyberEventsActionTypesService;
        _this.alertActionTypeId = CyberEventActionTypeName.Alert;
        _this.extraDataLoaded = new Set();
        _this.extraDataLoadRequestSent = new Set();
        _this.fullDateFormat = FULL_DATE_WITH_MILLISECONDS_FORMAT;
        _this.collapsibleID = CollapsibleID;
        _this.sortEventsByDateDesc = function (a, b) {
            return b.value.actionTime.valueOf() - a.value.actionTime.valueOf();
        };
        _this.entityPanelsService = _this.injector.get(EntityPanelsService);
        return _this;
    }
    CyberEventsEntityPanelComponent.prototype.setEntities = function (entities) {
        var _this = this;
        _super.prototype.setEntities.call(this, entities);
        this.appInsightsService.trackEvent('Multiple events panel', {
            entitiesCount: entities.length,
        });
        this.eventsMap = new Map(entities.map(function (entity) { return [entity.id, entity]; }));
        this.entities
            .filter(function (event) {
            return !_this.extraDataLoaded.has(event) && !_this.extraDataLoadRequestSent.has(event.id);
        })
            .forEach(function (event) {
            if (!(event.machine && event.machine.id)) {
                var err = new Error("Event graph cannot get extended data for event because machine id is missing: " + event);
                console.error(err);
                _this.appInsightsService.trackException(err);
                return;
            }
            if (_this.cyberEventsActionTypesService.isOneCyberEvent(event)) {
                return;
            }
            _this.extraDataLoadRequestSent.add(event.id);
            _this.addExtraDataSubscription(_this.paris
                .getItemById(CyberEvent, event.id, null, _this.cyberEventsUtilsService.createParamsForGetEvent(event, {
                machineId: event.machine.id,
                actionTime: event.actionTime,
                relatedAlert: event.relatedAlert,
                reportId: event.reportId,
            }))
                .subscribe(function (_event) {
                _this.eventsMap.set(event.id, _event);
                _this.extraDataLoaded.add(event);
                _this.extraDataLoadRequestSent.delete(event.id);
                _this.changeDetectorRef.markForCheck();
            }, function () {
                _this.extraDataLoadRequestSent.delete(event.id);
                _this.changeDetectorRef.markForCheck();
            }));
        });
        this.changeDetectorRef.markForCheck();
        this.cyberEventsUtilsService.trackCyberEventPanelEvent('CyberEventEntityPanelEvent', TrackingEventType.SidePaneToggleButton, this.entities);
    };
    CyberEventsEntityPanelComponent.prototype.openAlertPanel = function (alert) {
        var _this = this;
        this.entityPanelsService.showEntityById(Alert, alert.id, null, {
            back: {
                onClick: function () { return _this.entityPanelsService.closeEntityPanel(Alert); },
            },
        });
    };
    return CyberEventsEntityPanelComponent;
}(EntitiesPanelComponentBase));
export { CyberEventsEntityPanelComponent };
