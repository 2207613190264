import { isEqual } from 'lodash-es';
var ReadOnlyIdentifiable = /** @class */ (function () {
    function ReadOnlyIdentifiable(data, params) {
        this.preSetData.apply(this, [data].concat(params));
        if (data)
            this.setData.apply(this, [data].concat(params));
    }
    Object.defineProperty(ReadOnlyIdentifiable.prototype, "isNew", {
        get: function () {
            return this.id === undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReadOnlyIdentifiable.prototype, "__data", {
        get: function () {
            return this._data;
        },
        enumerable: true,
        configurable: true
    });
    ReadOnlyIdentifiable.prototype.preSetData = function (data) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
    };
    ReadOnlyIdentifiable.prototype.setData = function (data) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        this.id = data.id;
        if (data.name !== undefined)
            this.name = data.name;
        this._data = data;
    };
    ReadOnlyIdentifiable.prototype.compare = function (otherItem) {
        return isEqual(this._data, otherItem._data);
    };
    return ReadOnlyIdentifiable;
}());
export { ReadOnlyIdentifiable };
