import { Inject, Injectable } from '@angular/core';
import { merge } from 'lodash-es';
import { DeepReadonly } from '@wcd/types';
import { defaultEntitiesDisplayMap } from '../models/defaults/entity-maps.defaults';
import { EntitiesDisplayMap } from '../models/entity-display.model';
import { WCD_HUNTING_ENTITIES_DISPLAY_MAP } from '../models/hunting.models';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';

@Injectable()
export class HuntingSettingsService {
	readonly scheduledQueriesFeatureEnabled: boolean;

	get entitiesDisplayMap(): DeepReadonly<EntitiesDisplayMap> {
		return this._entitiesDisplayMap;
	}

	private readonly _entitiesDisplayMap: EntitiesDisplayMap;

	constructor(
		appContextService: AppContextService,
		featuresService: FeaturesService,
		@Inject(WCD_HUNTING_ENTITIES_DISPLAY_MAP) entitiesDisplayMap: EntitiesDisplayMap
	) {
		this.scheduledQueriesFeatureEnabled =
			!appContextService.isMtp || featuresService.isEnabled(Feature.ShowScheduledHuntingOnMTP);

		this._entitiesDisplayMap = merge({}, defaultEntitiesDisplayMap, entitiesDisplayMap);
	}
}
