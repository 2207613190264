/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../reports/components/alerts-severity-incident-summary.component.ngfactory";
import * as i2 from "../../../../reports/components/alerts-severity-incident-summary.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i5 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "./ip.alerts-summary.widget";
import * as i7 from "@microsoft/paris/dist/lib/paris";
import * as i8 from "../../../../global_entities/services/activated-entity.service";
import * as i9 from "../../../../shared-reports/services/reports.service";
var styles_IpAlertsSummaryWidgetComponent = [];
var RenderType_IpAlertsSummaryWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IpAlertsSummaryWidgetComponent, data: {} });
export { RenderType_IpAlertsSummaryWidgetComponent as RenderType_IpAlertsSummaryWidgetComponent };
function View_IpAlertsSummaryWidgetComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "alerts-severity-incident-summary", [["class", "wcd-full-height"]], null, null, null, i1.View_AlertsSeverityIncidentSummaryComponent_0, i1.RenderType_AlertsSeverityIncidentSummaryComponent)), i0.ɵdid(3, 49152, null, 0, i2.AlertsSeverityIncidentSummaryComponent, [], { alertsSeveritySummary: [0, "alertsSeveritySummary"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.alertsSeveritySummary; _ck(_v, 3, 0, currVal_0); }, null); }
function View_IpAlertsSummaryWidgetComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵppd(3, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), "reports.widgets.alertsSummary.noAlerts")); _ck(_v, 2, 0, currVal_0); }); }
function View_IpAlertsSummaryWidgetComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IpAlertsSummaryWidgetComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i0.ɵand(0, [["noAlerts", 2]], null, 0, null, View_IpAlertsSummaryWidgetComponent_3)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = (((_v.context.ngIf.alertsSeveritySummary == null) ? null : _v.context.ngIf.alertsSeveritySummary.alertsCount) > 0); var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_IpAlertsSummaryWidgetComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.I18nPipe, [i5.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "wcd-full-height"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_IpAlertsSummaryWidgetComponent_1)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.data$)); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_IpAlertsSummaryWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ip-alerts-summary-widget", [], null, null, null, View_IpAlertsSummaryWidgetComponent_0, RenderType_IpAlertsSummaryWidgetComponent)), i0.ɵdid(1, 245760, null, 0, i6.IpAlertsSummaryWidgetComponent, [i7.Paris, i8.ActivatedEntity, i5.I18nService, i9.ReportsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IpAlertsSummaryWidgetComponentNgFactory = i0.ɵccf("ip-alerts-summary-widget", i6.IpAlertsSummaryWidgetComponent, View_IpAlertsSummaryWidgetComponent_Host_0, {}, {}, []);
export { IpAlertsSummaryWidgetComponentNgFactory as IpAlertsSummaryWidgetComponentNgFactory };
