import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Injector,
	ChangeDetectorRef,
	OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
	VulnerabilityExposedAssetsRelationship,
	Vulnerability,
	WeaknessSeverity,
	Asset,
	TvmEndPoint,
	VulnerabilityType,
} from '@wcd/domain';
import { DataSet, DataQuery, Paris, RelationshipRepository } from '@microsoft/paris';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { SpinnerSize } from 'office-ui-fabric-react';
import { TvmWeaknessSeverityService } from '../../../tvm/services/tvm-vulnerability-severity.service';
import { TvmContextOptions } from '../../../@entities/@tvm/vulnerabilities/components/vulnerability.entity-panel.component';
import { MessageBarType, IMessageBarStyleProps } from 'office-ui-fabric-react';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { I18nService } from '@wcd/i18n';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { FeaturesService, Feature } from '@wcd/config';
import { TvmIconBuilderService, TvmIconsConfig } from '../../../tvm/services/tvm-icon-builder.service';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { MaxItemsInCollapseExpandTable } from '../../../tvm/tvm.constants';

@Component({
	selector: 'vulnerability-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './vulnerability.entity-details.component.html',
})
export class VulnerabilityEntityDetailsComponent extends EntityDetailsComponentBase<Vulnerability>
	implements OnInit {
	readonly MessageBarType = MessageBarType;

	private _vulnerability: Vulnerability;

	messageBarState = {
		showBody: false,
	};

	vulnerabilityAge: string;
	hasOsFeature: boolean;
	osFeatureNote: string;
	osFeature: string;
	osFeatureTooltip: string;
	relatedSoftware: string;
	softwareTooltip: string;
	threatConfigs: TvmIconsConfig;
	isNewExploitExperienceEnabled: boolean;
	isDetectionLogicEnabled: boolean;
	zeroDayFeatureEnabled: boolean;
	showZeroDayMessage: boolean;
	showZeroDayFormerId: boolean;

	exposedAssetsTitle: string;
	exposedAssetsLoadingText: string;
	isNetworkDevice: boolean;
	includeLastSeenStatus: boolean;
	includeOperatingSystem: boolean;
	productNeverMatched: boolean;
	legalNoticeText: string;

	@Input() contextOptions: TvmContextOptions;
	@Input() includeExposedMachinesComponent: boolean;
	@Input() isNetworkGearCve: boolean;

	@Input()
	set vulnerability(vulnerability: Vulnerability) {
		this.loadNewAssets(vulnerability);

		this.vulnerabilityAge = this.tvmTextsService.getText(TextToken.VulnerabilityAge, vulnerability);
		this.hasOsFeature = vulnerability.osFeatures != null && vulnerability.osFeatures.length > 0;
		this.osFeatureNote = this.i18nService.get('tvm.vulnerability.osFeatureNote', {
			osFeatures: vulnerability.osFeatures.join(', '),
		});
		this.osFeature = this.tvmTextsService.getText(TextToken.VulnerabilityOsFeature, vulnerability);
		this.osFeatureTooltip = this.tvmTextsService.getText(TextToken.OsFeatureTooltip, vulnerability);
		this.relatedSoftware = this.tvmTextsService.getText(TextToken.RelatedSoftware, vulnerability);
		this.softwareTooltip = this.tvmTextsService.getText(TextToken.RelatedSoftwareTooltip, vulnerability);
		this.threatConfigs = this.iconBuilderService.configureIcons(
			vulnerability.threatInfo,
			null,
			'vulnerability'
		).config;
		this.showZeroDayMessage = this.shouldShowZeroDayMessage(vulnerability);
		this.showZeroDayFormerId = this.shouldShowZeroDayFormerId(vulnerability);

		this._vulnerability = vulnerability;
		this.productNeverMatched = !Number.isInteger(vulnerability.impactedAssetsCount);
		if (vulnerability.cveSources.some(sourceInfo => sourceInfo.source === 'RedHat')) {
			this.legalNoticeText = this.tvmTextsService.getText(TextToken.RedHatLegalNotice);
		}
	}

	get vulnerability(): Vulnerability {
		return this._vulnerability;
	}

	SpinnerSize = SpinnerSize;
	isAssetsMinimized = true;

	assetRelationshipRepository: RelationshipRepository<Vulnerability, Asset>;

	assets$: Observable<DataSet<Asset>>;

	constructor(
		public i18nService: I18nService,
		private paris: Paris,
		private tvmWeaknessSeverityService: TvmWeaknessSeverityService,
		private changeDetectorRef: ChangeDetectorRef,
		private sidePanelService: SidePanelService,
		private iconBuilderService: TvmIconBuilderService,
		private tvmTextsService: TvmTextsService,
		featuresService: FeaturesService,
		injector: Injector,
		private tvmDownloadService: TvmDownloadService
	) {
		super(injector);

		this.assetRelationshipRepository = this.paris.getRelationshipRepository(
			VulnerabilityExposedAssetsRelationship
		);

		this.isNewExploitExperienceEnabled = featuresService.isEnabled(Feature.TvmExploits);
		this.isDetectionLogicEnabled = featuresService.isEnabled(Feature.TvmDetectionLogic);
		this.includeOperatingSystem = featuresService.isEnabled(Feature.TvmExposedOperatingSystems);
		this.zeroDayFeatureEnabled = featuresService.isEnabled(Feature.TvmZeroDay);

		this.legalNoticeText = i18nService.strings.tvm_vulnerability_legalNotice;
	}

	ngOnInit() {
		if (this.isNetworkGearCve || (this.contextOptions && this.contextOptions.isNetworkGear)) {
			this.configureNetworkGearCve();
		}
	}

	get severityInfo() {
		const severity = this._vulnerability.severity;
		return [
			WeaknessSeverity.Critical,
			WeaknessSeverity.High,
			WeaknessSeverity.Medium,
			WeaknessSeverity.Low,
		].includes(severity)
			? {
					text: severity,
					showIcon: true,
					iconClass: this.tvmWeaknessSeverityService.getWeaknessColorClass(severity),
			  }
			: {
					text: this.i18nService.get('common.noDataAvailable'),
					showIcon: false,
					iconClass: null,
			  };
	}

	openAllExposedMachines() {
		this.sidePanelService.showAllExposedAssetsPanel(this._vulnerability, this.isNetworkGearCve);
	}

	loadNewAssets(vulnerability: Vulnerability) {
		if (
			this.includeExposedMachinesComponent === false ||
			(this.contextOptions && this.contextOptions.isSingleAsset)
		) {
			return;
		}
		const queryOptions: DataQuery = {
			page: 1,
			pageSize: MaxItemsInCollapseExpandTable,
		};
		// TODO: evhvoste add the order by - when the BE will support it
		this.assetRelationshipRepository.sourceItem = vulnerability;
		this.assets$ = this.assetRelationshipRepository.query(queryOptions);
	}

	getStyles(messageBarProps: IMessageBarStyleProps) {
		if (this.messageBarState.showBody !== messageBarProps.expandSingleLine) {
			this.messageBarState.showBody = messageBarProps.expandSingleLine;
			this.changeDetectorRef.detectChanges();
		}
	}

	exportExposedMachines() {
		return this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.assetRelationshipRepository,
			TvmEndPoint.Analytics,
			`${this._vulnerability.id} - ${this.i18nService.get('tvm.common.exposedDevices')}`,
			undefined
		);
	}

	private configureNetworkGearCve() {
		this.exposedAssetsTitle = this.i18nService.get('tvm.common.exposedNetworkDevices');
		this.exposedAssetsLoadingText = this.i18nService.get(
			'tvm.securityRecommendation.loadingExposedNetworkDevices'
		);
		this.isNetworkDevice = true;
		this.includeLastSeenStatus = true;
		this.includeOperatingSystem = false;
	}

	private shouldShowZeroDayMessage(vulnerability: Vulnerability) {
		return (
			this.zeroDayFeatureEnabled &&
			vulnerability.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
			!vulnerability.patchReleaseDate
		);
	}

	private shouldShowZeroDayFormerId(vulnerability: Vulnerability) {
		return (
			this.zeroDayFeatureEnabled &&
			vulnerability.formalTemporaryId &&
			vulnerability.formalTemporaryId !== vulnerability.name
		);
	}
}
