import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { File } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';

@Component({
	selector: 'file-detections-panel',
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<file-malware-detected [file]="file" [showSummary]="false"></file-malware-detected>
		</wcd-panel>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDetectionsPanelComponent extends PanelContainer {
	constructor(router: Router) {
		super(router);
	}

	@Input() file: File;
}
