import {ApiCall, ApiCallModel} from '@microsoft/paris';
import {Incident} from './incident.entity';

@ApiCall({
	name: 'Search incidents',
	endpoint: (config) => {
		return config.data.serviceUrls.incidentSearch
	},
	baseUrl: '',
	method: 'GET',
	parse: (data: Array<IncidentsSearchResultBackendConfig>) => {
		return data.map(item => {
			return {
				incidentId: item.IncidentId,
				name: item.Title,
			};
		});
	},
})
export class IncidentsSearchApiCall extends ApiCallModel<Array<IncidentsSearchResult>, SearchConfig> {}

interface IncidentsSearchResultBackendConfig {
	IncidentId: Incident['id'];
	Title: string;
}

export interface IncidentsSearchResult {
	incidentId: Incident['id'];
	name: string;
}

export interface SearchConfig {
	term: string;
	pageSize: number;
}
