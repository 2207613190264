
		<div
			class="wcd-flex-horizontal-spaced"
			*ngIf="appConfigService.isAutomatedIrEnabled; else enableAutoIr"
		>
			<wcd-pie-chart
				[data]="data$ | async"
				[hidden]="error$ | async"
				[settings]="activeAutomatedInvestigationPieSettings"
				[allowTitleClick]="true"
				(titleClick)="goToInvestigationsPage()"
				[upperTitle]="pieChartTitle$ | async"
			>
			</wcd-pie-chart>
			<wcd-chart-legend
				[items]="legendItems$ | async"
				[showValues]="true"
				[hidden]="error$ | async"
			></wcd-chart-legend>
		</div>
		<ng-template #enableAutoIr>
			<enable-auto-ir></enable-auto-ir>
		</ng-template>
	