import { ApiCallModel, ApiCall } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { DomainCategoryLookupRequest } from './domain-category-lookup-request.value-object';

@ApiCall({
	name: 'Web Content Filtering Domain Category Lookup',
	endpoint: 'WebContentFiltering/Reports/CategoryLookup',
	method: 'POST',
	cache: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.webThreatProtection,
})
export class WebContentFilteringCategoryLookup extends ApiCallModel<
	number[],
	DomainCategoryLookupRequest
> {}
