var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ReportWidgetComponent } from '../../../reports/components/report-widget.component.base';
import { filter, mergeMap, map } from 'rxjs/operators';
/**
 * A base class for widgets that display data that is related to an entity
 * Extends the regular widget with the ability to query paris for related data
 */
var EntityRelatedWidgetBase = /** @class */ (function (_super) {
    __extends(EntityRelatedWidgetBase, _super);
    function EntityRelatedWidgetBase(paris, activatedEntity, reportsService) {
        var _this = _super.call(this, reportsService) || this;
        _this.paris = paris;
        _this.activatedEntity = activatedEntity;
        return _this;
    }
    Object.defineProperty(EntityRelatedWidgetBase.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            var entityRelatedConfig = this.entityRelatedWidgetConfig;
            return __assign({}, entityRelatedConfig, { loadData: function () {
                    return _this.getEntity(entityRelatedConfig).pipe(mergeMap(function (entity) {
                        return entityRelatedConfig.getRelatedData
                            ? entityRelatedConfig.getRelatedData(entity)
                            : _this.paris
                                .getRelatedItem(entityRelatedConfig.entityRelationshipType, entity, entityRelatedConfig.getDataQuery &&
                                entityRelatedConfig.getDataQuery(entity))
                                .pipe(map(function (relatedData) { return ({
                                entity: entity,
                                relatedData: relatedData,
                            }); }));
                    }));
                } });
        },
        enumerable: true,
        configurable: true
    });
    EntityRelatedWidgetBase.prototype.getEntity = function (entityRelatedConfig) {
        var activatedEntity$ = (this.activatedEntity.currentEntity$.pipe(filter(function (currentEntity) { return currentEntity instanceof entityRelatedConfig.entityType; })));
        return (entityRelatedConfig.getEntity && entityRelatedConfig.getEntity()) || activatedEntity$;
    };
    return EntityRelatedWidgetBase;
}(ReportWidgetComponent));
export { EntityRelatedWidgetBase };
