var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { map, take } from 'rxjs/operators';
import { CategorySecureScoreApiCall, TotalSecureScoreApiCall, RecommendationException, } from '@wcd/domain';
import { ChartColor } from '@wcd/charts';
import { TvmTextsService, TextToken } from '../../../services/tvm-texts.service';
import { TvmColorService, TvmColor } from '../../../services/tvm-color.service';
import { FabricIconNames } from '@wcd/scc-common';
import { zip } from 'rxjs';
import { LocaleConfigService, TzDateService } from '@wcd/localization';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';
import { TvmChartTooltipComponent, SECURE_SCORE_TOOLTIP_ID, } from '../../../components/tooltips/events/tvm-chart-tooltip.component';
import { TvmTopChangeEventsService, ChangeEventDomain, } from '../../../services/tvm-top-change-events.service';
var MAX_DAYS_OF_HISTORY = 30;
var TvmSecureConfigurationScoreWidget = /** @class */ (function (_super) {
    __extends(TvmSecureConfigurationScoreWidget, _super);
    function TvmSecureConfigurationScoreWidget(reportsService, tvmTextsService, router, topChangeEventService, paris, tvmColorService, i18nService, tzDateService, localeConfigService, machineGroupsFilterService) {
        var _this = _super.call(this, reportsService) || this;
        _this.tvmTextsService = tvmTextsService;
        _this.router = router;
        _this.topChangeEventService = topChangeEventService;
        _this.paris = paris;
        _this.tvmColorService = tvmColorService;
        _this.i18nService = i18nService;
        _this.tzDateService = tzDateService;
        _this.localeConfigService = localeConfigService;
        _this.machineGroupsFilterService = machineGroupsFilterService;
        _this.userHasSecureScoreAccess = false;
        _this.currentDate = new Date();
        _this.widgetTooltip = tvmTextsService.getText(TextToken.ConfigurationScoreWidgetInfo);
        _this.widgetTooltipAriaLabel = _this.i18nService.get('tvm.secureScoreWidget.info.AriaLabel');
        _this._osTitle = _this.i18nService.get('tvm.common.operatingSystem');
        return _this;
    }
    Object.defineProperty(TvmSecureConfigurationScoreWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'secureScoreWidget',
                name: this.i18nService.get('tvm.secureScoreWidget.title'),
                noDataMessage: function () { return _this.tvmTextsService.getText(TextToken.ScoreWidgetNoData, _this.isMgSelected); },
                noDataIcon: FabricIconNames.Trackers,
                NoIconLeftAlign: true,
                loadData: function () {
                    var mg$ = _this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1));
                    var categories$ = _this.paris.apiCall(CategorySecureScoreApiCall, {}); //TODO: bad practice ahead - the empty params is due to the fact that Paris fires the "parseQuery" CB only if arg is provided
                    var totalScore$ = _this.paris.apiCall(TotalSecureScoreApiCall, {}); //TODO: bad practice ahead - the empty params is due to the fact that Paris fires the "parseQuery" CB only if arg is provided
                    var exceptions$ = _this.paris
                        .getRepository(RecommendationException)
                        .query({ where: { status: ['Active'] } });
                    var scaEvents$ = _this.topChangeEventService.getChangeEvents$(ChangeEventDomain.SCA);
                    return zip(mg$, categories$, totalScore$, exceptions$, scaEvents$).pipe(map(function (_a) {
                        var mg = _a[0], categories = _a[1], totalScore = _a[2], exceptions = _a[3], scaEvents = _a[4];
                        _this.isMgSelected = mg.isFiltering;
                        _this.isImpactedByExceptions = exceptions.items.length > 0;
                        if (!Array.isArray(categories) ||
                            categories.length === 0 ||
                            (totalScore.secureScore === 0 && totalScore.maxSecureScore === 0)) {
                            return null; // To show the no data message
                        }
                        _this.setNewChartValues(totalScore, scaEvents || new Map());
                        return _this.getStretchedDonutChartItems(categories, totalScore);
                    }));
                },
                minHeight: '400px',
            };
        },
        enumerable: true,
        configurable: true
    });
    TvmSecureConfigurationScoreWidget.prototype.getStretchedDonutChartItems = function (categories, totalScore) {
        var _this = this;
        return {
            donutItems: categories.map(function (categoryScore) {
                return categoryScore && {
                    id: categoryScore.id,
                    title: categoryScore.category === 'OS' ? _this._osTitle : categoryScore.category,
                    valueColor: _this.tvmColorService.colorsMap.get(TvmColor.Blue),
                    totalColor: _this.tvmColorService.colorsMap.get(TvmColor.GraphSeriesLight),
                    value: categoryScore.secureScore,
                    total: categoryScore.maxSecureScore,
                    valuePrefix: _this.i18nService.get('tvm_secureScoreWidget_category_bar_completed'),
                    restPrefix: _this.i18nService.get('tvm_secureScoreWidget_category_bar_uncompleted'),
                    width: '100%',
                    clickable: true,
                    showValueAsPercentage: true,
                };
            }),
            totalScore: totalScore,
        };
    };
    TvmSecureConfigurationScoreWidget.prototype.getScorePercentage = function (score) {
        return Math.round((score.secureScore / score.maxSecureScore) * 100);
    };
    TvmSecureConfigurationScoreWidget.prototype.openSecureRecommendations = function (categoryTitle) {
        if (categoryTitle === this._osTitle) {
            categoryTitle = 'OS';
        }
        this.router.navigate(['/security-recommendations'], { queryParams: { search: categoryTitle } });
    };
    TvmSecureConfigurationScoreWidget.prototype.setNewChartValues = function (data, scaEvents) {
        var _this = this;
        if (!data) {
            this.trendOptions = null;
            this.newChartOptions = null;
            return;
        }
        var percentages = data.secureScoreHistoryPercentage.slice(0, MAX_DAYS_OF_HISTORY);
        var nominators = data.secureScoreHistory.slice(0, MAX_DAYS_OF_HISTORY);
        var denominators = data.maxSecureScoreHistory.slice(0, MAX_DAYS_OF_HISTORY);
        var dates = Array.from(Array(MAX_DAYS_OF_HISTORY)).map(function (_, i) {
            return moment()
                .utc()
                .subtract({ days: i })
                .startOf('day')
                .toDate();
        });
        var percentageScoreHistory = dates.map(function (date, i) { return ({
            value: Math.floor(percentages[i]),
            nominator: Math.floor(nominators[i]),
            denominator: Math.floor(denominators[i]),
            date: date,
            events: scaEvents.get(_this.tzDateService.format(date, 'MM/dd')),
        }); });
        this.newChartOptions = {
            data: percentageScoreHistory,
            legend: this.i18nService.get('tvm.secureScoreWidget.secureScoreOverTimeLegend'),
            tooltipComponent: TvmChartTooltipComponent,
            valueInPercent: true,
            color: ChartColor.Blue,
            tooltipId: SECURE_SCORE_TOOLTIP_ID,
        };
        this.trendOptions = {
            title: this.i18nService.get('tvm.secureScoreWidget.secureScoreOverTime'),
            scoreHistory: percentages,
            iconColor: TvmColor.Blue,
        };
    };
    return TvmSecureConfigurationScoreWidget;
}(ReportWidgetComponent));
export { TvmSecureConfigurationScoreWidget };
