/* tslint:disable:template-click-events-have-key-events */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core';
import { StepStatus, WizardStepModel } from './wizard-step.model';
import { filter } from 'rxjs/operators';
import { MainAppState, MainAppStateService } from '../../main/services/main-app-state.service';
import { config } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';


@Component({
	selector: 'wizard',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './wizard.component.html',
	styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnChanges {
	@Input() stepsData: Array<WizardStepModel>;
	@Input() currentStepId: number;
	@Input() currentRouteIsActive: boolean = false;
	@Input() showProgressIcons: boolean = false;
	@Input() containerClassName: string;
	@Input() isPanel: boolean = false;
	@Input() isCompleted: boolean;

	@Output()
	select: EventEmitter<{ step: WizardStepModel }> = new EventEmitter<{ step: WizardStepModel }>(false);

	_currentStepId: number;
	currentStepName: string;
	isNarrowLayout: boolean;
	wizardDropdownOpen: boolean = false;

	constructor(readonly i18nService: I18nService, private changeDetectorRef: ChangeDetectorRef, mainAppStateService: MainAppStateService) {
		mainAppStateService.state$
			.pipe(filter((state: MainAppState) => {
				return this.isNarrowLayout !== config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.medium);
			}))
			.subscribe((state: MainAppState) => {
				this.isNarrowLayout = config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.medium);
				this.changeDetectorRef.markForCheck();
			});
	}

	ngOnChanges(changes) {
		if (!this.currentRouteIsActive) {
			this._currentStepId = this.currentStepId || (this.stepsData.length && this.stepsData[0].id);
			this.currentStepName = this.getStepDisplayName(this.stepsData.find(value => value.id === this._currentStepId));
		}
		this.changeDetectorRef.detectChanges();
	}

	selectStep(step: WizardStepModel, $event?): boolean {
		if (step.disabled) {
			return true;
		}

		this._currentStepId = step.id;
		this.currentStepName = this.getStepDisplayName(step);
		this.select.emit({ step: step });

		if (step.routerLink) {
			return true;
		}

		$event && $event.preventDefault();
		this.changeDetectorRef.detectChanges();
		return false;
	}

	getStepClassName(step): string {
		let className = step.disabled ? 'disabled' : '';

		if (step.className) className += ` ${step.className}`;

		return className;
	}

	getStepStatus(step: WizardStepModel) {
		if (step.id > this._currentStepId) {
			return StepStatus.next;
		} else if (step.id === this._currentStepId) {
			return StepStatus.current;
		}
		return StepStatus.completed;
	}

	getIconForStatus(status: keyof typeof StepStatus): string {
		return statusToIcon[status];
	}

	toggleWizardDropdown() {
		this.wizardDropdownOpen = !this.wizardDropdownOpen;
	}

	private getStepDisplayName({ text, subText }: WizardStepModel) {
		return text && subText ? `${text}: ${subText}` : (text || subText);
	}
}

const statusToIcon = {
	[StepStatus.completed]: 'CompletedSolid',
	[StepStatus.current]: 'CircleFill',
	[StepStatus.next]: 'CircleRing',
};
