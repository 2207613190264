export const fileAppTypeValues = [
	{
		id: 'PowerPoint',
		name: 'PowerPoint',
		icon: '\uF1EB',
		color: '#d24726',
		extensions: ['ppt', 'pptx', 'pptm', 'potx', 'potm', 'pot', 'pps', 'ppam'],
	},
	{
		id: 'Word',
		name: 'Word',
		icon: '\uF1E3',
		extensions: ['doc', 'docx', 'dot', 'docm', 'dotx', 'dotm', 'docb'],
		color: '#2b579a',
	},
	{
		id: 'PDF',
		name: 'PDF',
		extensions: ['pdf'],
		color: '#2b579a',
	},
	{
		id: 'DLL',
		name: 'DLL',
		extensions: ['dll'],
		color: '#2b579a',
	},
	{
		id: 'Executable',
		name: 'Executable',
		extensions: ['exe'],
		color: '#2b579a',
	},
	{
		id: 'Excel',
		name: 'Excel',
		icon: '\uF1E5',
		extensions: [
			'xls',
			'xlt',
			'xlm',
			'xlsx',
			'xlsm',
			'xltx',
			'xltm',
			'xlsb',
			'xla',
			'xlam',
			'xll',
			'xlw',
		],
		color: '#217346',
	},
	{
		id: 'Archive',
		name: 'Archive',
		icon: '\uF012',
		extensions: ['zip', 'rar'],
	},
];

export const fileAppTypesIndex = fileAppTypeValues.reduce((index, appType) => {
	const appTypeExtensions = {};
	appType.extensions.forEach(extension => (appTypeExtensions[extension] = appType));
	return Object.assign(index, appTypeExtensions);
}, {});
