import {
	AadUser,
	Alert,
	alertClassificationValues,
	alertDeterminationValues,
	AlertStatusTypeId,
	AlertV3StatusType,
	DetectionSourceType,
	detectionSourceValues,
	ImpactedEntities,
	investigationStatusValues,
	Machine,
	Mailbox,
	serviceSourceValues,
	severityValues
} from "@wcd/domain";

export const convertHostToMachine = (host): Machine => {
	return new Machine({
		...host,
		senseMachineId: host.hostName || host.senseMachineId || host.HostName || host.SenseMachineId,
		id: host.senseMachineId || host.computerDnsName || host.id || host.SenseMachineId || host.ComputerDnsName || host.Id,
		name: host.hostName || host.IpAddress ||  host.HostName,
	});
};

export const convertV3MailboxToLegacy = (mailbox): Mailbox => {
	return new Mailbox({
		...mailbox,
		type: 'mailbox',
		displayName: mailbox.displayName || mailbox.DisplayName,
		mailboxPrimaryAddress: mailbox.mailboxPrimaryAddress || mailbox.MailboxPrimaryAddress,
		upn: mailbox.upn || mailbox.Upn,
	});
};

export const convertAccountToUser = (account): AadUser => {
	return new AadUser({
		...account,
		id: account.sid || account.Sid,
		aadUserId: account.aadUserId || account.AadUserId,
		displayName: account.name || account.Name,
	});
};

export const convertImpactedEntitiesV3ToLegacy= (impactedEntities, impactedEntitiesSummary): ImpactedEntities => {
	if(!impactedEntities) return null;
	const impactedEntitiesObj = {
		machines: impactedEntities.filter((entity) => entity.type === 'host' || entity.Type === 'host').map((host) => convertHostToMachine(host)),
		mailboxes: impactedEntities.filter((entity) => entity.type === 'mailbox' || entity.Type === 'mailbox').map((mailbox) => convertV3MailboxToLegacy(mailbox)),
		users: impactedEntities.filter((entity) => entity.type === 'account' || entity.Type === 'account').map((account) => convertAccountToUser(account)),
		apps: null, /* doesn't return from old associated alerts Api */
	}
	const impactedEntitiesCount = impactedEntitiesSummary ?
		{
			mailboxCount: impactedEntitiesSummary.mailbox,
			machineCount: impactedEntitiesSummary.host,
			userCount: impactedEntitiesSummary.account,
			appCount: null /* doesn't return from old associated alerts Api */
		} : null
	return {
		...impactedEntitiesObj,
		...impactedEntitiesCount
	};
};

export const convertAlertV3ToLegacyAlert = (securityAlert): Alert => {
	return new Alert({
		...securityAlert,
		id: securityAlert.alertId,
		name: securityAlert.alertDisplayName,
		actor: securityAlert.actorName,
		groupedAlertsCount: securityAlert.alertCount,
		incidentLinkedBy: securityAlert.incidentLinkedBy || [],
		aggregatedIncidentLinkedBy: securityAlert.aggregatedLinkedByAlerts || [],
		detectionSource: detectionSourceValues.find((value) => value.id === DetectionSourceType[securityAlert.detectionSource]),
		description: securityAlert.description,
		serviceSource: serviceSourceValues.find((value) => (value.type ===securityAlert.productSource)),
		classification: alertClassificationValues.find((value) => value.type === securityAlert.classification),
		determination: alertDeterminationValues.find((value) => value.type === securityAlert.determination),
		status: AlertStatusTypeId[AlertV3StatusType[securityAlert.status]],
		categories: [securityAlert.category],
		severity: severityValues.find((value) => value.name === securityAlert.severity),
		impactedEntities: convertImpactedEntitiesV3ToLegacy(securityAlert.impactedEntities, securityAlert.impactedEntitiesSummary),
		investigationId: securityAlert.investigationIds ? parseInt(securityAlert.investigationIds[0]) : null,
		firstEventTime: new Date(securityAlert.startTimeUtc),
		lastEventTime: new Date(securityAlert.endTimeUtc),
		firstSeen: new Date(securityAlert.timeGenerated),
		investigationStatus: investigationStatusValues.find((value) => value.type === securityAlert.investigationState),
	})
}

/* CAUTION!
Please use this util carefully when converting V3 security alert to legacy alert,
It's designed to work only in specific scenarios with specific BE response.
Will be used until angular deprecation.
Be aware! When using the 'new' keyword with ParisV1 entity - it doesn't pass parsing process!
 */
