import { TvmTopEventsWidget } from './widgets/top-events/top-events.widget';
import { ReportModel, M365_DASHBOARD_WIDGET_CLASS } from '../../reports/models/report.model';
import { TvmOrgExposureScoreWidget } from './widgets/org-exposure-score/org-exposure-score.widget';
import { TvmSecureConfigurationScoreWidget } from './widgets/secure-configuration-score/secure-configuration-score.widget';
import { TvmDevicesExposureDistributionWidget } from './widgets/devices-exposure-distribution/devices-exposure-distribution.widget';
import { TvmTopVulnerableSoftwareWidget } from './widgets/top-vulnerable-software/top-vulnerable-software.widget';
import { TvmTopExposedMachinesWidget } from './widgets/top-exposed-machines/top-exposed-machines.widget';
import { TvmActiveRemediationTasksWidget } from './widgets/active-remediation-tasks/tvm-active-remediation-tasks.widget';
import { TvmThreatCampaignsWidget } from './widgets/threat-campaigns/threat-campaigns.widget';
import { ReportWidgetsLayoutRowModel } from '../../reports/models/report-widgets-layout.row.model';
import { FeaturesService, Feature } from '@wcd/config';
import { TvmDashboardEmptyComponent } from './tvm.dashboard-empty.component';

let _tvmDashboardReport: ReportModel;

export function getTvmDashboardReport(featuresService: FeaturesService) {
	if (!_tvmDashboardReport) {
		const enableThreatCampaigns = featuresService.isEnabled(Feature.TvmThreatCampaigns);

		_tvmDashboardReport = new ReportModel({
			id: 'tvmDashboard',
			titleNameKey: 'tvm.dashboard.title',
			maxWidth: 1650,
			allowExport: false,
			allowRangeSelect: false,
			refreshRate: 86400000, //24 hours
			emptyDashboardComponent: TvmDashboardEmptyComponent,
			rows: [
				{
					columns: [
						{
							rows: [
								{
									columns: [
										{
											widgets: [TvmOrgExposureScoreWidget],
										},
										{
											widgets: [TvmSecureConfigurationScoreWidget],
										},
									],
								},
							],
							weight: 2,
						},
						{
							widgets: [TvmDevicesExposureDistributionWidget],
						},
					],
				},
				{
					columns: [
						{
							rows: [
								{
									columns: [
										{
											widgets: [TvmTopEventsWidget, TvmTopVulnerableSoftwareWidget],
											weight: 2,
										},
										{
											widgets: [TvmActiveRemediationTasksWidget],
										},
									],
								},
								{
									columns: [
										{
											widgets: [TvmTopExposedMachinesWidget],
											weight: 2,
										},
										{
											widgets: [],
										},
									],
								},
							],
						},
					],
				},
			],
			className: M365_DASHBOARD_WIDGET_CLASS,
		});

		if (enableThreatCampaigns) {
			_tvmDashboardReport.rows.push(
				new ReportWidgetsLayoutRowModel({
					columns: [
						{
							widgets: [TvmThreatCampaignsWidget],
						},
						{},
					],
				})
			);
		}
	}

	return _tvmDashboardReport;
}
