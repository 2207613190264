/* tslint:disable:template-accessibility-alt-text */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';
import { EvaluationEnabledGuard } from '@wcd/auth';
import { ReportWidgetComponent } from '../../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../../shared-reports/services/reports.service';
import { ReportWidgetConfig } from '../../../../../models/report-widget.model';
import { MtpPromotionService } from '../../../../../../mtp-promotion/services/mtp-promotion.service';
import { of } from 'rxjs';
import { SCC_BASE_URL } from '../../../../../../shared/main/services/main-nav-config.service';
import { TryItUrls } from '../../../../../../mtp-promotion/enums/try-it-urls';
import { I18nService } from '@wcd/i18n';

@Component({
	templateUrl: './experience-mtp.widget.component.html',
	styleUrls: ['./experience-mtp.widget.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceMtpWidgetComponent extends ReportWidgetComponent<void> {
	readonly incidentsUrl = `${SCC_BASE_URL}/${TryItUrls.Incidents}`;

	simulationAndTutorialsEnabled = this.featuresService.isEnabled(Feature.SimulationsAndTutorials);
	evaluationEnabled = this.evaluationEnabledGuard.canActivate();

	get widgetConfig(): ReportWidgetConfig {
		return {
			id: 'mtpPromotion',
			showHeader: true,
			isDisabled:
				!this.appConfigService.isMtpEligible || this.mtpPromotionService.mtpPromotionWidgetDismissed,
			name: this.i18nService.get('mtpPromotion.header'),
			hideOnNoData: true,
			loadData: () => of({ noData: '' }),
		};
	}

	constructor(
		reportsService: ReportsService,
		private featuresService: FeaturesService,
		private mtpPromotionService: MtpPromotionService,
		public appConfigService: AppConfigService,
		private evaluationEnabledGuard: EvaluationEnabledGuard,
		private i18nService: I18nService
	) {
		super(reportsService);
	}

	dismiss = (): void => {
		this.mtpPromotionService.dismissMtpPromotion(true);
		this.widgetConfig$.next(this.widgetConfig);
	};
}
