import { ChangeDetectorRef, Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Domain } from '@wcd/domain';
import { Subscription } from 'rxjs';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { DomainsService } from '../services/domains.service';

@Component({
	selector: 'domain-entity-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './domain.entity-panel.component.html',
})
export class DomainEntityPanelComponent extends EntityPanelComponentBase<Domain>
	implements OnDestroy, OnInit {
	get domain(): Domain {
		return this.entity;
	}

	private _onActionSubscription: Subscription;
	domainIdSelected: string;

	constructor(private domainsService: DomainsService, changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}

	ngOnInit() {
		super.ngOnInit();

		this._onActionSubscription = this.action$.subscribe(
			($event: { action: ItemActionModel; data: any }) => this.onAction($event.action, $event.data)
		);

		this.domainIdSelected = this.domain.id;
	}

	ngOnDestroy() {
		this._onActionSubscription && this._onActionSubscription.unsubscribe();
	}

	private onAction(action: ItemActionModel, data: any) {
		this.changeDetectorRef.markForCheck();
	}

	protected initEntity(entity: Domain, isExtendedData: boolean = false) {
		super.initEntity(entity);

		this.changeDetectorRef.markForCheck();
	}
}
