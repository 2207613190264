import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let lastId = 0;

@Component({
	selector: 'wcd-datatable-checkbox',
	template: `
		<span class="wcd-datatable-checkbox" >
			<input type="checkbox" [checked]="checked" (change)="_onInteractionEvent($event)" [id]="id" />
			<label
				[id]="id + '-label'"
				[attr.for]="id"
				class="wcd-datatable-checkbox__label"
				(mousedown)="onCheckboxClick($event)"
				[class.wcd-datatable-checkbox__label--checked]="checked"
				[attr.tabindex]="checked ? 0 : -1"
				[attr.aria-label]="'grid_columnHeader_selectRow' | i18n"
				role="checkbox"
			>
				<fab-icon iconName="CircleRing" contentClass="wcd-datatable-checkbox__label__ring"></fab-icon>
				<fab-icon
					iconName="StatusCircleCheckmark"
					contentClass="wcd-datatable-checkbox__label__check"
				></fab-icon>
			</label>
		</span>
	`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DatatableCheckboxComponent),
			multi: true,
		},
	],
	styleUrls: ['./datatable-checkbox.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatatableCheckboxComponent implements ControlValueAccessor {
	private _checked: boolean;

	id: string = `datatable-checkbox-${lastId++}`;

	@Input()
	set checked(checked: boolean) {
		const value = !!checked;
		if (value !== this.checked) this._checked = value;
	}

	get checked() {
		return this._checked;
	}

	@Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() mouseDown: EventEmitter<MouseEvent> = new EventEmitter();

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	onChange: (value: any) => void = value => {};
	onTouched = () => {};

	writeValue(checked: any): void {
		this.checked = !!checked;
		this.changeDetectorRef.markForCheck();
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	/**
	 * Toggles the `checked` value between true and false
	 */
	toggle() {
		this.checked = !this.checked;
	}

	/**
	 * Event handler for checkbox input element.
	 * Toggles checked state if element is not disabled.
	 * @param event
	 */
	_onInteractionEvent(event: Event) {
		event.stopPropagation();

		this.toggle();

		this.onChange(this.checked);
		this.change.emit(this.checked);
	}

	onCheckboxClick($event: MouseEvent) {
		// If shift is pressed, the (changed) event won't be fired, so doing it explicitly.
		if ($event.shiftKey) {
			$event.preventDefault();
		}

		this.mouseDown.emit($event);
	}
}
