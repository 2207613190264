import {
	AfterViewInit,
	Directive,
	ElementRef,
	Input,
	Renderer2
} from "@angular/core";


@Directive({
	selector: '[learn-more-link]',
})
export class LearnMoreLinkDirective implements AfterViewInit {
	@Input() url: string;
	@Input() ariaLabel: string;
	@Input() linkText: string;
	@Input() onFocus: boolean = false;
	constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

	ngAfterViewInit() {
		const link: HTMLAnchorElement = document.createElement('a');
		link.href = this.url;
		link.target = '_blank'
		link.innerText = this.linkText;
		if (this.ariaLabel) {
			link.setAttribute('aria-label', this.ariaLabel)
		}
		this.renderer.appendChild(this.elementRef.nativeElement, link);
		if (this.onFocus) {
			setTimeout(() => {
				link.focus();
			});
		}
	}
}
