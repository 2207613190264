import { Component } from '@angular/core';

@Component({
	selector: 'endpoint-settings-licenses-tab',
	template: `
		<div class="wcd-margin-xLarge-left wcd-margin-xLarge-right wcd-margin-bottom">
			<react-portal-tab-wrapper
				componentName="MixedLicensesTab@wicd-smb/main"
				componentId="settings-licenses-tab"
			>
			</react-portal-tab-wrapper>
		</div>
	`,
})
export class EndpointSettingsLicensesTabComponent  {
}

