/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../common/collapsible-items-preview.component.ngfactory";
import * as i4 from "../common/collapsible-items-preview.component";
import * as i5 from "./related-cves.details.component";
import * as i6 from "../../../../@entities/@tvm/vulnerabilities/services/vulnerability.service";
import * as i7 from "../../../../@entities/@tvm/vulnerabilities/services/vulnerabilities.fields.service";
var styles_RelatedCvesDetailsComponent = [];
var RenderType_RelatedCvesDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RelatedCvesDetailsComponent, data: {} });
export { RenderType_RelatedCvesDetailsComponent as RenderType_RelatedCvesDetailsComponent };
export function View_RelatedCvesDetailsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 4, "collapsible-items-preview", [["defaultSortFieldId", "'severity'"]], null, [[null, "onItemClick"], [null, "showMore"], [null, "export"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onItemClick" === en)) {
        var pd_0 = (_co.openVulnerabilityPanel($event) !== false);
        ad = (pd_0 && ad);
    } if (("showMore" === en)) {
        var pd_1 = (_co.openAllVulnerabilities() !== false);
        ad = (pd_1 && ad);
    } if (("export" === en)) {
        var pd_2 = (_co.export.emit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_CollapsibleItemsPreviewComponent_0, i3.RenderType_CollapsibleItemsPreviewComponent)), i0.ɵdid(2, 114688, null, 0, i4.CollapsibleItemsPreviewComponent, [i0.ChangeDetectorRef], { repository: [0, "repository"], title: [1, "title"], loadingText: [2, "loadingText"], maxItems: [3, "maxItems"], disableSort: [4, "disableSort"], fields: [5, "fields"], defaultSortFieldId: [6, "defaultSortFieldId"], initiallyCollapsed: [7, "initiallyCollapsed"], dataviewLabel: [8, "dataviewLabel"] }, { showMore: "showMore", onItemClick: "onItemClick", export: "export" }), i0.ɵppd(3, 1), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._repository; var currVal_1 = _co.title; var currVal_2 = i0.ɵunv(_v, 2, 2, _ck(_v, 3, 0, i0.ɵnov(_v, 0), "tvm.securityRecommendation.loadingRelatedCves")); var currVal_3 = _co.maxItems; var currVal_4 = _co.disableSort; var currVal_5 = _co.fields; var currVal_6 = "'severity'"; var currVal_7 = _co.initiallyCollapsed; var currVal_8 = i0.ɵunv(_v, 2, 8, _ck(_v, 4, 0, i0.ɵnov(_v, 0), "tvm.common.relatedCVE.title")); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_RelatedCvesDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "related-cves-details", [], null, null, null, View_RelatedCvesDetailsComponent_0, RenderType_RelatedCvesDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i5.RelatedCvesDetailsComponent, [i6.VulnerabilityService, i7.VulnerabilityFieldsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RelatedCvesDetailsComponentNgFactory = i0.ɵccf("related-cves-details", i5.RelatedCvesDetailsComponent, View_RelatedCvesDetailsComponent_Host_0, { repository: "repository", contextOptions: "contextOptions", includeExposedMachinesComponent: "includeExposedMachinesComponent", title: "title", maxItems: "maxItems", disableSort: "disableSort", customFields: "customFields", isNetworkGearCve: "isNetworkGearCve", initiallyCollapsed: "initiallyCollapsed" }, { showMore: "showMore", export: "export" }, []);
export { RelatedCvesDetailsComponentNgFactory as RelatedCvesDetailsComponentNgFactory };
