import { Injectable } from '@angular/core';
import { WebContentFilteringCategoryReport, WebContentFilteringCategoryType } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { I18nService } from '@wcd/i18n';
import { WebContentFilteringCategoryReportDetailsComponent } from '../components/web-content-filtering-category-details.component';

@Injectable()
export class WebContentFilteringCategoryEntityTypeService
	implements EntityTypeService<WebContentFilteringCategoryReport> {
	private _categoryIdNameMappings: { [key: number]: string } = {};

	constructor(private paris: Paris, private i18nService: I18nService) {
		const categoryList = this.paris.getRepository(WebContentFilteringCategoryType).entity.values;
		categoryList.forEach(
			(category: WebContentFilteringCategoryType) =>
				(this._categoryIdNameMappings[category.id] = this.i18nService.get(
					`webCategories.categories.${category.name}`
				))
		);
	}

	readonly entityType: EntityType<WebContentFilteringCategoryReport> = {
		id: 'webContentFilteringWebCategory',
		entity: WebContentFilteringCategoryReport,
		loadFullEntityInPanel: false,
		getEntityName: (report: WebContentFilteringCategoryReport) => this._categoryIdNameMappings[report.id],
		singleEntityPanelComponentType: WebContentFilteringCategoryReportDetailsComponent,
	};
}
