import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input} from '@angular/core';
import {PanelContainer} from '@wcd/panels';
import {ContentState} from '@wcd/contents-state';
import {Router} from '@angular/router';
import {Paris} from '@microsoft/paris';
import {HuntingDocTable} from '@wcd/domain';
import {take} from 'rxjs/operators';
import {HuntingDocumentationService} from '../services/hunting-documentation.service';
import {AppContextService, Feature, FeaturesService} from '@wcd/config';

@Component({
	selector: 'hunting-documentation-table-reference',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: 'hunting-documentation-table-reference.panel.component.html',
	styleUrls: ['./hunting-documentation-table-reference.panel.component.scss'],
})
export class HuntingDocumentationTableReferencePanelComponent extends PanelContainer {
	contentState: ContentState = ContentState.Loading;
	@Input() tableName: string;
	tableReference: HuntingDocTable;

	constructor(
		private readonly paris: Paris,
		private changeDetectorRef: ChangeDetectorRef,
		readonly huntingDocumentationService: HuntingDocumentationService,
		readonly appContextService: AppContextService,
		private readonly featuresService: FeaturesService,
		router: Router
	) {
		super(router);
	}

	ngOnInit() {
		this.paris
			.getItemById(HuntingDocTable, this.tableName, null,{migrateToVNext: this.featuresService.isEnabled(Feature.HuntingEndpointMigrationHuntingDocumentation)})
			.pipe(take(1))
			.subscribe(
				(huntingDocTable: HuntingDocTable) => {
					this.tableReference = huntingDocTable;
					this.contentState = !this.tableReference ? ContentState.Empty : ContentState.Complete;
					this.changeDetectorRef.markForCheck();
				},
				_ => {
					this.contentState = ContentState.Error;
					this.changeDetectorRef.markForCheck();
				}
			);
	}

	sampleQueryClick(queryText: string) {
		this.huntingDocumentationService.runSampleQueryClicked(queryText);
	}
}
