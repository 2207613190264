import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer, PanelSettings } from '@wcd/panels';
import { MachineGroup, MachineGroupRemediationLevelApiCall, ProtectionLevel } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { take } from 'rxjs/operators';
import { ChecklistValue, RadioListComponent } from '@wcd/forms';
import { FabricIconNames } from '@wcd/scc-common';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
@Component({
	selector: 'machine-group-remediation-level-edit-panel',
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<div class="large-form wcd-full-height">
				<form
					*ngIf="editedMachineGroup"
					#machineGroupForm="ngForm"
					(submit)="saveMachineGroup()"
					data-track-component="MachineGroupRemediationLevelEdit"
					class="wcd-full-height wcd-flex-vertical"
				>
					<dl *ngIf="editedMachineGroup.description" class="wcd-padding-all" role="none">
						<dt class="info-label semi-bold">
							{{ 'machineGroup.edit.descriptionTitle' | i18n }}
						</dt>
						<dd>
							{{ editedMachineGroup.description }}
						</dd>
					</dl>
					<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
						<section>
							<div class="form-group">
								<label for="protection-level" class="wcd-font-weight-bold">{{
									'machineGroup.edit.automationLevelTitle' | i18n
								}}</label>
								<div class="wcd-margin-top">
									<wcd-radiolist
										[values]="allProtectionLevels"
										[(ngModel)]="selectedProtectionLevel"
										[trackBy]="'id'"
										[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
										name="protection-level"
										required
										#automationLevelRadioList
										[ariaLabel]="'machineGroup.edit.automationLevelTitle' | i18n"
									>
									</wcd-radiolist>
								</div>
							</div>
						</section>
					</div>
					<span *ngIf="error" class="wcd-padding-all color-text-red">
						{{ error }}
					</span>
					<div class="wcd-flex-none wcd-flex-horizontal wcd-padding-all">
						<div class="wcd-flex-1">
						<fab-primary-button
								data-track-id="Save"
								data-track-type="Button"
								[disabled]="
									machineGroupForm.form.pristine || !machineGroupForm.form.valid || isSaving
								"
								type="submit"
							>
								<span role="alert">
									<i class="loader-icon" [hidden]="!isSaving" [attr.aria-label]="'buttons.saving' | i18n"></i>
								</span>
								{{ (isSaving ? 'buttons.saving' : 'buttons.save') | i18n }}
							</fab-primary-button>
						</div>
						<div class="wcd-flex-none">
							<fab-default-button
								className="btn-secondary"
								data-track-id="Cancel"
								data-track-type="Button"
								(onClick)="closePanel()"
							>
								{{ 'buttons.cancel' | i18n }}
							</fab-default-button>
						</div>
					</div>
				</form>
			</div>
		</wcd-panel>
	`,
})
export class MachineGroupRemediationLevelEditPanelComponent extends PanelContainer implements AfterViewInit {
	private _settings: PanelSettings;
	@Input() private onDone: Function;
	@Input() private set machineGroup(group: MachineGroup) {
		this.editedMachineGroup = new MachineGroup(group);
		if (this.editedMachineGroup.isUnassignedMachineGroup) {
			this.editedMachineGroup.name = this.i18nService.get('machineGroup.unassignedGroup.name');
			this.editedMachineGroup.description = this.i18nService.get(
				'machineGroup.unassignedGroup.description'
			);
		}

		this.selectedProtectionLevel = {
			...this.editedMachineGroup.protectionLevel,
			name: this.i18nService.get(this.editedMachineGroup.protectionLevel.nameKey),
			description: this.i18nService.get(this.editedMachineGroup.protectionLevel.descriptionKey),
		};
		this.selectedProtectionLevel = this.allProtectionLevels.find(protection=>protection.id==this.editedMachineGroup.protectionLevel.id);
		this._settings = this.parseSettings(this._settings);
	}

	@Input() set settings(obj: PanelSettings) {
		this._settings = this.parseSettings(obj);
	}

	get settings(): PanelSettings {
		return this._settings;
	}

	allProtectionLevels: Array<ChecklistValue>;
	editedMachineGroup: MachineGroup;
	selectedProtectionLevel: ChecklistValue;
	isSaving = false;
	error: Error;

	@ViewChild('automationLevelRadioList', { static: false }) automationLevelRadioList: RadioListComponent;

	constructor(protected router: Router, private i18nService: I18nService, private paris: Paris, private dialogsService: DialogsService) {
		super(router);
		this.allProtectionLevels = paris
			.getRepository(ProtectionLevel)
			.entity.values.slice()
			.sort((a, b) => (a.order > b.order ? 1 : -1))
			.map((protectionLevel: ProtectionLevel) => ({
				...protectionLevel,
				name: this.i18nService.get(protectionLevel.nameKey),
				description: this.i18nService.get(protectionLevel.descriptionKey),
			}));
	}

	ngAfterViewInit(){
		this.automationLevelRadioList.setFocus();
	}

	private parseSettings(settings: PanelSettings): PanelSettings {
		if (this.editedMachineGroup) {
			return { ...settings, headerText: this.editedMachineGroup.name };
		}
		return settings;
	}

	async saveMachineGroup() {
		this.editedMachineGroup.protectionLevel = {...this.editedMachineGroup.protectionLevel, ...this.selectedProtectionLevel};
		this.isSaving = true;
		this.error = null;
		try {
			await this.paris
				.apiCall(MachineGroupRemediationLevelApiCall, [this.editedMachineGroup])
				.pipe(take(1))
				.toPromise();
			this.dialogsService.showSuccessSnackbar({
				icon: FabricIconNames.Completed,
				text: this.i18nService.get('machineGroup.edit.automationLevel.saved'),
			});
			this.destroy();
			this.onDone();
		} catch (e) {
			this.isSaving = false;
			this.error = e;
		}
	}
}
