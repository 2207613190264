import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { RemediationActionType } from './remediation-action-type.entity';

@ValueObject({
	singularName: 'Remediation Action Type Action Count',
	pluralName: 'Remediation Action Type Action Counts',
})
export class RemediationActionTypeActionCount extends ModelBase {
	@EntityField({ defaultValue: 0, data: 'count' })
	actionCount: number;

	@EntityField({ data: 'action' })
	remediationActionType: RemediationActionType;
}
