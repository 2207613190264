<ng-container *ngIf="!contextOptions || !contextOptions.isSingleAsset">
	<ng-container *ngTemplateOutlet="assetsSection; context: {assets: (assetsSubject$ | async)}">
	</ng-container>
</ng-container>

<ng-template #assetsSection let-assets="assets">
	<entity-panel-collapsible-section
		[loaded]="assets"
		[loadingText]=" loadingText ? loadingText : ('tvm.securityRecommendation.loadingExposedDevices' | i18n)"
		[title]="title ? title : ('tvm.common.exposedDevices' | i18n)"
		[count]="assets?.count"
		(export)="export.emit()">
		<table class="wcd-table wcd-full-width table-grouped no-side-padding wcd-padding-bottom">
			<thead>
				<tr>
					<th *ngIf="showContextTable"></th>
					<th>{{'tvm.common.assetNameTitle' | i18n}}</th>
					<th *ngIf="includeOperatingSystem">{{'os.operatingSystem' | i18n}}</th>
					<th *ngIf="includeProductivityAssessment">
						{{'tvm.securityRecommendation.userImpact.productivityImpactFieldName' | i18n}}
					</th>
					<th *ngIf="includeLastSeenStatus">{{'tvm.networkGear.networkDeviceReachableTitle' | i18n}}</th>
					<th *ngIf="showContextTable"
						[attr.colspan]="machinesTableColSpan">
						{{ misconfiguredContextItemsCountHeaderTitle }}
					</th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let asset of assets?.items; index as i">
					<tr class="table-group-header" >
						<td *ngIf="showContextTable" class="col-xs-1 wcd-border-bottom">
							<fab-icon
								contentClass="pointer"
								(click)="toggleContextItemCollapseState(i);"
								[iconName]="getContextItemCollapseState(i)? 'ChevronRight' : 'ChevronDown'"
							></fab-icon>
						</td>
						<td class="wcd-border-bottom table-link pointer nowrap wcd-text-overflow-medium full-width-cell">
							<div role="link" tabindex="0" (keydown.enter)="onItemClick(asset.id)" (click)="onItemClick(asset.id)">
								<span *ngIf="isNetworkDevice"><network-device-icon></network-device-icon></span>
								<span *ngIf="!isNetworkDevice"><fab-icon contentClass='field-value-icon' iconName='System'></fab-icon></span>
								{{ asset.name }}
							</div>
						</td>
						<td *ngIf="includeOperatingSystem" class="col-xs-3 wcd-border-bottom nowrap wcd-text-overflow-medium">
							{{ tvmTextsService.getOsPlatformLabel(asset.osName) }}
						</td>
						<td *ngIf="includeProductivityAssessment" class="col-xs-3 wcd-border-bottom nowrap wcd-text-overflow-medium">
							<tvm-productivity-impact [isUserProductivityImpacted] = asset.isUserProductivityImpacted>
							</tvm-productivity-impact>
						</td>
						<td *ngIf="includeLastSeenStatus" class="col-xs-3 nowrap wcd-text-overflow-medium" title="Last seen: {{ getLastSeenDate(asset) }}">
							{{ (asset.lastSeen > oneDayAgo) ? 'Yes' : 'No' }}
						</td>
						<td *ngIf="showContextTable" class="col-xs-3 wcd-border-bottom nowrap wcd-text-overflow-medium" [attr.colspan]="machinesTableColSpan">{{ asset.recommendationContext.length }}</td>
					</tr>
					<tr *ngIf="showContextTable" [hidden]="getContextItemCollapseState(i)">
						<th></th>
						<th *ngFor="let header of contextTableHeaders; let last = last;" [attr.colspan]="last ? contextTableColSpan : 1">{{ 'tvm.securityRecommendation.recommendationContext.' + header | i18n }}</th>
					</tr>
					<ng-container *ngIf="showContextTable">
						<ng-container *ngFor="let contextDataItem of asset?.recommendationContext;">
							<tr [hidden]="getContextItemCollapseState(i)">
								<td class="wcd-border-bottom"></td>
								<td *ngFor="let header of contextTableHeaders; let first = first; let last = last;"
									class="wcd-border-bottom nowrap wcd-text-overflow-medium full-width-cell wcd-font-size-s"
									[attr.colspan]="last ? contextTableColSpan : 1"
									[wcdTooltip]="contextDataItem[header]">
									<span *ngIf="first && recommendationContextIcon!==''" [class]="recommendationContextIcon"></span>
									{{ contextDataItem[header] }}
								</td>
							</tr>
						</ng-container>
					</ng-container>
				</ng-container>
			</tbody>
		</table>
		<a *ngIf="assets?.count >= 3 && isShowMore"
			tabindex="0"
			role="button"
			data-track-id="OpenAllExposedMachines"
			data-track-type="Navigation"
			(keydown.enter)="openAllExposedMachines()"
			(click)="openAllExposedMachines()">{{'tvm.common.showMore' | i18n}}</a>
	</entity-panel-collapsible-section>
</ng-template>
