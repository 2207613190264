var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { Paris } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { compact, escapeRegExp } from 'lodash-es';
import { combineLatest, defer, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { DefenderRunningMode, InvestigateMachineApiCall, Machine, MachineAnyRequestsActiveRelationship, MachineExclusionState, MachineMachineRequestLatestRelationship, MachineMachineRequestsStateRelationship, MachineTagsCollectionRelationship, OperatingSystemPlatformCategories, TagType, } from '@wcd/domain';
import { Feature, FeaturesService, PollingService, FlavorService } from '@wcd/config';
import { ItemActionModel, ItemActionType, } from '../../../dataviews/models/item-action.model';
import { PanelService } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { ErrorsDialogService } from '../../../dialogs/services/errors-dialog.service';
import { I18nService } from '@wcd/i18n';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { MachinesActionsService } from './machines-actions.service';
import { MACHINE_RESPONSE_FEATURE_MAP, MachineRequestsPermissionsService, } from './machines-requests.permissions.service';
import { MachinesService } from './machines.service';
import { LiveResponseService } from '../../live_response/services/live-response.service';
import { Router } from '@angular/router';
import { AskThreatExpertService } from '../../../feedback/services/ask-threat-expert.service';
import { EntityCommandBarDisplayMode } from '../../../global_entities/models/entity-command-bar-display-mode.enum';
import { GoHuntService } from '../../../hunting-go-hunt/services/go-hunt.service';
import { AppFlavorConfig } from '@wcd/scc-common';
import { LiveResponsePermissionsService } from '../../live_response/services/live-response-permissions.service';
import { sccHostService } from '@wcd/scc-interface';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { ReactPanelsService } from '../../../shared/services/react-panels.service';
import { DEVICE_ENTITY_TYPE_GO_HUNT_ID } from '../../../hunting-go-hunt/services/go-hunt-query-builder';
import { AppConfigService } from '@wcd/app-config';
import { openDeviceReportInaccuracyPanel } from '@wcd/shared';
var machineTrackingComponent = 'Machine Actions';
var pollingIntervalMilliseconds = 2 * 60 * 1000; // 2 minutes
var actionOrderWeights = (_a = {
        machineTags: 10,
        evidenceGoHunt: 15,
        isolateMachine: 20,
        restrictAppExecution: 30,
        runAntivirusScan: 40,
        collectInvestigationPackage: 50,
        liveResponse: 60,
        investigateMachine: 70,
        consultThreatExpert: 80
    },
    _a["machineValue" /* MachineValue */] = 90,
    _a.troubleshootingMode = 100,
    _a.logsCollection = 110,
    _a.actionCenter = 120,
    _a.reportInaccuracy = 130,
    _a);
var MachineEntityTypeActionsService = /** @class */ (function () {
    function MachineEntityTypeActionsService(paris, appConfigService, featuresService, flavorService, machinesService, machinesPermissionsService, machinesActionsService, i18nService, dialogsService, errorsDialogService, panelsService, liveResponseService, router, askThreatExpertService, pollingService, goHuntService, rbacControlService, advancedFeaturesService, reactPanelsService, liveResponsePermissionsService) {
        this.paris = paris;
        this.appConfigService = appConfigService;
        this.featuresService = featuresService;
        this.flavorService = flavorService;
        this.machinesService = machinesService;
        this.machinesPermissionsService = machinesPermissionsService;
        this.machinesActionsService = machinesActionsService;
        this.i18nService = i18nService;
        this.dialogsService = dialogsService;
        this.errorsDialogService = errorsDialogService;
        this.panelsService = panelsService;
        this.liveResponseService = liveResponseService;
        this.router = router;
        this.askThreatExpertService = askThreatExpertService;
        this.pollingService = pollingService;
        this.goHuntService = goHuntService;
        this.rbacControlService = rbacControlService;
        this.advancedFeaturesService = advancedFeaturesService;
        this.reactPanelsService = reactPanelsService;
        this.liveResponsePermissionsService = liveResponsePermissionsService;
        this.machineIsolationAction$ = new Subject();
        this.hasMdeLicenseWorkloads = flavorService.isEnabled(AppFlavorConfig.settings.mdeWithWorkloads);
    }
    MachineEntityTypeActionsService.prototype.getEntityActions = function (machines, machineEntityTypeService, options, entityCommandBarDisplayMode) {
        var _this = this;
        if (entityCommandBarDisplayMode === void 0) { entityCommandBarDisplayMode = EntityCommandBarDisplayMode.Default; }
        var machine = machines[0];
        // used for refetch machine isolation status
        this.machine = machine;
        if (machines.some(function (machine) { return !machine.senseMachineId; })) {
            // return only actions that are relevant without sense machine id
            var machineActionConfigs = [];
            if (machines.length == 1 && this.flavorService.isEnabled(AppFlavorConfig.devices.goHunt)) {
                machineActionConfigs.push(this._createGoHuntFromIncidentAction(machine, options));
            }
            return combineLatest(machineActionConfigs).pipe(map(function (actionConfigs) { return compact(actionConfigs); }), map(function (actionConfigs) { return actionConfigs.map(function (actionConfig) { return new ItemActionModel(actionConfig); }); }));
        }
        var p1LicenseInMixedLicenseMode = this.appConfigService.mixedLicenseMode && machines.some(function (machine) { return machine.hasP1tag; });
        var commonActionsConfigs$ = combineLatest([
            entityCommandBarDisplayMode != EntityCommandBarDisplayMode.Overflow
                ? this._createTagsActionConfig(machines)
                : EMPTY
        ].concat((this.hasMdeLicenseWorkloads && this.flavorService.isEnabled(AppFlavorConfig.devices.autoIr)
            ? [this._createInvestigateActionConfig(machines)]
            : []), (this.hasMdeLicenseWorkloads &&
            this.flavorService.isEnabled(AppFlavorConfig.devices.liveResponseSession) && !p1LicenseInMixedLicenseMode
            ? [this._createLiveResponseActionConfig(machines)]
            : []), (this.flavorService.isEnabled(AppFlavorConfig.devices.machineValue) && !p1LicenseInMixedLicenseMode
            ? [this._createMachineValueActionConfig(machineEntityTypeService)]
            : []), (this.featuresService.isEnabled(Feature.MagellanReportInaccuracy)
            ? [this._createReportInaccuracyActionConfig()]
            : []), [this._createMachineExclusionStateActionConfig$(machines)])).pipe(map(function (actionConfigs) { return compact(actionConfigs); }));
        // multi select case
        if (!this.featuresService.isEnabled(Feature.UpgradeMachinePage) || machines.length > 1) {
            var multipleMachineActionsConfigs_1 = this.featuresService.isEnabled(Feature.BulkCloudActions) && sccHostService.isSCC
                ? [
                    this._createMultipleIsolateMachineActionConfig(),
                    this._createMultipleRestrictAppExecutionActionConfig(),
                    this._createMultipleReverseIsolateActionConfig(),
                    this._createMultipleRemoveAppRestrictionsActionConfig(),
                ]
                : [];
            return commonActionsConfigs$.pipe(map(function (actionsConfigs) {
                return actionsConfigs.concat(multipleMachineActionsConfigs_1).map(function (actionConfig) {
                    return new ItemActionModel(actionConfig);
                });
            }));
        }
        // single select case
        return combineLatest([
            commonActionsConfigs$.pipe(catchError(function (err) { return of([]); }), startWith([])),
            this.getMachineCloudActions(machine).pipe(catchError(function (err) { return of([]); }), startWith([])),
            this._createConsultThreatExpertConfig(machine).pipe(catchError(function (err) { return of([]); }), startWith([])),
            this._createActionCenterActionConfig(machine).pipe(catchError(function (err) { return of(null); }), startWith(null)),
            this._createGoHuntFromIncidentAction(machine, options).pipe(catchError(function (err) { return of(null); }), startWith(null)),
        ]).pipe(map(function (_a) {
            var commonActionsConfigs = _a[0], machineCloudActions = _a[1], consultThreatExpertConfig = _a[2], actionCenterAction = _a[3], goHuntAction = _a[4];
            var machineCloudAction = _this.hasMdeLicenseWorkloads ? machineCloudActions : [];
            var consultThreatExpertConfigAction = _this.hasMdeLicenseWorkloads && !p1LicenseInMixedLicenseMode
                ? consultThreatExpertConfig
                : [];
            var actionCenterActions = _this.hasMdeLicenseWorkloads ? actionCenterAction : [];
            var huntAction = _this.hasMdeLicenseWorkloads ? goHuntAction : [];
            var allActions = compact(commonActionsConfigs.concat(machineCloudAction, consultThreatExpertConfigAction, [
                actionCenterActions
            ], (_this.flavorService.isEnabled(AppFlavorConfig.devices.goHunt) ? huntAction : [])));
            var getWeight = function (id) { return actionOrderWeights[id] || 999; };
            return allActions.sort(function (a, b) { return getWeight(a.id) - getWeight(b.id); });
        }), map(function (actionsConfigs) { return actionsConfigs.map(function (actionConfig) { return new ItemActionModel(actionConfig); }); }));
    };
    MachineEntityTypeActionsService.prototype.getMachineCloudActions = function (machine) {
        var _this = this;
        var isResponseFeatureSupported$ = this.machinesPermissionsService.isResponseFeatureEnabled(machine);
        var featureSupportedStatus$ = this.machinesPermissionsService.getFeaturesStatus(machine);
        var machineActiveActionsCreateFuncs = [
            {
                type: 'ForensicsResponse',
                createAction: function (machine) { return _this._createCollectInvestigationPackageActionConfig(machine); },
            },
            {
                type: 'ScanResponse',
                createAction: function (machine) { return _this._createRunAntivirusActionConfig(machine); },
            },
            {
                type: 'RestrictExecutionResponse',
                createAction: function (machine) { return _this._createRestrictAppExecutionActionConfig(machine); },
            },
            {
                type: 'IsolationResponse',
                createAction: function (machine) { return _this._createIsolateMachineActionConfig(machine); },
            },
            {
                type: 'LogsCollectionResponse',
                createAction: function (machine) { return _this._createCollectSupportLogsActionConfig(machine); },
            },
            {
                type: 'TroubleshootResponse',
                createAction: function (machine) { return _this._createTroubleshootingModeConfig(machine); },
            },
        ];
        // Also listening to active panels to re-trigger creation of actions when Action Center opens or closes
        return this.pollingService.poll(0, pollingIntervalMilliseconds).pipe(switchMap(function () {
            return combineLatest([
                isResponseFeatureSupported$,
                featureSupportedStatus$,
                _this.panelsService.activePanels$,
            ]);
        }), switchMap(function (_a) {
            var enabled = _a[0], featureSupportedStatus = _a[1];
            var actions = machineActiveActionsCreateFuncs
                .map(function (_a) {
                var type = _a.type, createAction = _a.createAction;
                var isSupportedInCloud = (_this.featuresService.isEnabled(Feature.MagellanShieldsUp) &&
                    type === 'IsolationResponse' &&
                    machine.isMdatp &&
                    !machine.isManagedByMdatp) ||
                    (enabled && !!featureSupportedStatus[type]);
                if (isSupportedInCloud) {
                    return createAction(machine);
                }
                var feature = MACHINE_RESPONSE_FEATURE_MAP[type];
                if (!_this.featuresService.isEnabled(feature)) {
                    return null;
                }
                return defer(function () { return __awaiter(_this, void 0, void 0, function () {
                    var isSupportedInLiveResponse, action;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.liveResponsePermissionsService.isCloudActionSupported(machine, type)];
                            case 1:
                                isSupportedInLiveResponse = _a.sent();
                                return [4 /*yield*/, createAction(machine)
                                        .pipe(take(1))
                                        .toPromise()];
                            case 2:
                                action = _a.sent();
                                return [2 /*return*/, Object.assign({}, action, {
                                        disabled: true,
                                        tooltip: isSupportedInLiveResponse
                                            ? this.i18nService.strings
                                                .machines_entityDetails_actions_cloudActions_supportedInLiveResponse
                                            : this.i18nService.strings
                                                .machines_entityDetails_actions_cloudActions_unsupportedDevice,
                                    })];
                        }
                    });
                }); });
            })
                .filter(Boolean)
                .map(function (action$) { return action$.pipe(startWith(null)); });
            return combineLatest(actions);
        }));
    };
    MachineEntityTypeActionsService.prototype._createConsultThreatExpertConfig = function (machine) {
        return of(this.askThreatExpertService.shouldShowThreatExpertPanel()
            ? [this.askThreatExpertService.getAskTheExpertCommandConfig(machineTrackingComponent)]
            : []);
    };
    MachineEntityTypeActionsService.prototype._createGoHuntFromIncidentAction = function (machine, options) {
        return this.goHuntService.getGoHuntActionObservable(machine, DEVICE_ENTITY_TYPE_GO_HUNT_ID, {
            incident: options && options.incident,
        });
    };
    MachineEntityTypeActionsService.prototype._createTagsActionConfig = function (machines) {
        var _this = this;
        return of({
            id: 'machineTags',
            nameKey: 'tags.manage',
            type: ItemActionType.Tags,
            icon: FabricIconNames.Tag,
            rbac: [RbacMdeAllowedActions.alertsInvestigation],
            rbacState: RbacControlState.disabled,
            refreshOnResolve: true,
            tracking: {
                id: 'machineTagsAction',
                type: TrackingEventType.Action,
                component: machineTrackingComponent,
            },
            options: {
                tags: {
                    setTags: function (tags) {
                        return _this.machinesService.updateMachinesTags(machines, tags).pipe(tap(function () {
                            _this.machinesService.clearCachedMachines();
                            _this.paris
                                .getRelationshipRepository(MachineTagsCollectionRelationship)
                                .clearCache();
                            _this.machinesService.clearAllUserDefinedMachineTags();
                        }));
                    },
                    createNewTag: function (tag) {
                        return of({
                            id: tag.id,
                            name: tag.name,
                            type: TagType.user,
                        });
                    },
                    searchFunction: function (term) {
                        var termRegExp = new RegExp(escapeRegExp(term), 'i');
                        return _this.machinesService.getAllUserDefinedMachineTags().pipe(map(function (tags) {
                            return tags
                                .filter(function (tag) { return termRegExp.test(tag.name); })
                                .sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1); })
                                .map(function (tag) { return ({
                                item: tag,
                                label: tag.name,
                                value: tag.id,
                                type: tag.type,
                            }); })
                                .slice(0, 10);
                        }));
                    },
                    searchSettings: { showSuggestions: true },
                },
            },
        });
    };
    MachineEntityTypeActionsService.prototype._createCollectInvestigationPackageActionConfig = function (machine) {
        var _this = this;
        return this.paris
            .getRelatedItem(MachineMachineRequestsStateRelationship, machine)
            .pipe(map(function (requestsState) { return requestsState.forensics || 'RequestCompleted'; }), map(function (forensicsRequestState) { return ({
            id: 'collectInvestigationPackage',
            nameKey: 'machines.entityDetails.actions.collectInvestigationPackage.title',
            tooltip: _this.i18nService.get("" + (forensicsRequestState === 'RequestPending'
                ? 'machines.entityDetails.actions.$general.tooltip.requestPending'
                : 'machines.entityDetails.actions.collectInvestigationPackage.tooltip')),
            icon: FabricIconNames.FolderList,
            disabled: forensicsRequestState === 'RequestPending',
            rbac: [RbacMdeAllowedActions.alertsInvestigation],
            rbacState: RbacControlState.disabled,
            tracking: {
                id: 'machineCollectInvestigationPackageAction',
                type: TrackingEventType.Action,
                component: machineTrackingComponent,
            },
            refreshOnResolve: false,
            method: function (_a) {
                var machine = _a[0];
                return __awaiter(_this, void 0, void 0, function () {
                    var response;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, this.machinesActionsService.collectInvestigationPackage(machine)];
                            case 1:
                                response = _b.sent();
                                if (!response.confirmed) return [3 /*break*/, 3];
                                return [4 /*yield*/, this.machinesActionsService.openActionCenter(machine, response.data.requestGuid)];
                            case 2:
                                _b.sent();
                                _b.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            },
        }); }));
    };
    MachineEntityTypeActionsService.prototype._createCollectSupportLogsActionConfig = function (machine) {
        var _this = this;
        if (!this.featuresService.isEnabled(Feature.LogsCollection)) {
            return of(null);
        }
        return this.paris
            .getRelatedItem(MachineMachineRequestsStateRelationship, machine)
            .pipe(map(function (requestsState) {
            return requestsState.collectLogs || 'RequestCompleted';
        }), map(function (collectLogsRequestState) { return ({
            id: 'logsCollection',
            nameKey: 'machines.entityDetails.actions.logsCollection.title',
            tooltip: _this.i18nService.get("" + (collectLogsRequestState === 'RequestPending'
                ? 'machines.entityDetails.actions.$general.tooltip.requestPending'
                : 'machines.entityDetails.actions.logsCollection.tooltip')),
            icon: FabricIconNames.ProductList,
            disabled: collectLogsRequestState === 'RequestPending',
            rbac: [RbacMdeAllowedActions.alertsInvestigation],
            rbacState: RbacControlState.disabled,
            tracking: {
                id: 'machineCollectSupportLogsAction',
                type: TrackingEventType.Action,
                component: machineTrackingComponent,
            },
            refreshOnResolve: false,
            method: function (_a) {
                var machine = _a[0];
                return __awaiter(_this, void 0, void 0, function () {
                    var response;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, this.machinesActionsService.collectSupportLogs(machine)];
                            case 1:
                                response = _b.sent();
                                if (!response.confirmed) return [3 /*break*/, 3];
                                return [4 /*yield*/, this.machinesActionsService.openActionCenter(machine, response.data.requestGuid)];
                            case 2:
                                _b.sent();
                                _b.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            },
        }); }));
    };
    MachineEntityTypeActionsService.prototype._createRunAntivirusActionConfig = function (machine) {
        var _this = this;
        var requestStateConfigMap = {
            RequestCompleted: {
                tooltipKey: 'machines.entityDetails.actions.runAntivirusScan.tooltip.scan',
            },
            RequestPending: {
                tooltipKey: 'machines.entityDetails.actions.$general.tooltip.requestPending',
                disabled: true,
            },
            NotSupported: {
                tooltipKey: 'machines.entityDetails.actions.runAntivirusScan.tooltip.notSupported',
                disabled: true,
            },
            Unknown: {
                tooltipKey: 'machines.entityDetails.actions.$general.tooltip.error',
                disabled: true,
            },
        };
        return this.paris
            .getRelatedItem(MachineMachineRequestsStateRelationship, machine)
            .pipe(map(function (requestsState) {
            if (machine.os.platform.category !== OperatingSystemPlatformCategories.Linux &&
                machine.os.platform.category !== OperatingSystemPlatformCategories.macOS &&
                machine.os.platform.category !== OperatingSystemPlatformCategories.Android &&
                machine.os.platform.category !== OperatingSystemPlatformCategories.iOS &&
                (!machine.defenderRunningMode ||
                    machine.defenderRunningMode === DefenderRunningMode.NotRunning ||
                    machine.defenderRunningMode === DefenderRunningMode.FailedGettingStatus)) {
                return 'NotSupported';
            }
            return requestsState.scan || 'RequestCompleted';
        }), map(function (scanRequestState) {
            var _a = requestStateConfigMap[scanRequestState], tooltipKey = _a.tooltipKey, disabled = _a.disabled;
            return {
                id: 'runAntivirusScan',
                disabled: disabled,
                icon: FabricIconNames.Shield,
                nameKey: 'machines.entityDetails.actions.runAntivirusScan.title',
                tooltip: _this.i18nService.get(tooltipKey),
                rbac: [RbacMdeAllowedActions.alertsInvestigation],
                rbacState: RbacControlState.disabled,
                refreshOnResolve: false,
                tracking: {
                    id: 'machineAntivirusScanAction',
                    type: TrackingEventType.Action,
                    component: machineTrackingComponent,
                },
                method: function (_a) {
                    var machine = _a[0];
                    return __awaiter(_this, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, this.machinesActionsService.runAntivirusScan(machine)];
                                case 1:
                                    response = _b.sent();
                                    if (!response.confirmed) return [3 /*break*/, 3];
                                    return [4 /*yield*/, this.machinesActionsService.openActionCenter(machine, response.data.requestGuid)];
                                case 2:
                                    _b.sent();
                                    _b.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                },
            };
        }));
    };
    MachineEntityTypeActionsService.prototype._createRestrictAppExecutionActionConfig = function (machine) {
        var _this = this;
        var removeRestrictionPartialConfig = {
            refreshOnResolve: false,
            nameKey: 'machines.entityDetails.actions.restrictAppExecution.title.removeRestriction',
            trackId: 'machineRemoveAppRestrictionsAction',
        };
        var restrictPartialConfig = {
            nameKey: 'machines.entityDetails.actions.restrictAppExecution.title.restrict',
            trackId: 'machineAppRestrictionsAction',
            refreshOnResolve: false,
        };
        var requestStateConfigMap = {
            RequestCompleted: __assign({}, removeRestrictionPartialConfig, { tooltipKey: 'machines.entityDetails.actions.restrictAppExecution.tooltip.removeRestriction', action: function (machine) { return _this.machinesActionsService.removeAppRestrictions(machine); } }),
            ReverseRequestPending: __assign({}, removeRestrictionPartialConfig, { tooltipKey: 'machines.entityDetails.actions.$general.tooltip.requestPending', disabled: true }),
            RequestPending: __assign({}, removeRestrictionPartialConfig, { tooltipKey: 'machines.entityDetails.actions.$general.tooltip.requestPending', disabled: true }),
            NotSupported: __assign({}, restrictPartialConfig, { tooltipKey: 'machines.entityDetails.actions.restrictAppExecution.tooltip.notSupported', disabled: true }),
            ReverseRequestCompleted: __assign({}, restrictPartialConfig, { tooltipKey: 'machines.entityDetails.actions.restrictAppExecution.tooltip.restrict', action: function (machine) { return _this.machinesActionsService.restrictAppExecution(machine); } }),
            Unknown: __assign({}, restrictPartialConfig, { tooltipKey: 'machines.entityDetails.actions.$general.tooltip.error', disabled: true }),
        };
        return this.paris
            .getRelatedItem(MachineMachineRequestsStateRelationship, machine)
            .pipe(map(function (requestsState) {
            if ((!requestsState.restrictExecution ||
                requestsState.restrictExecution === 'ReverseRequestCompleted') &&
                machine.defenderRunningMode !== DefenderRunningMode.Normal) {
                return 'NotSupported';
            }
            return requestsState.restrictExecution || 'ReverseRequestCompleted';
        }), map(function (requestState) {
            var _a = requestStateConfigMap[requestState], nameKey = _a.nameKey, tooltipKey = _a.tooltipKey, action = _a.action, disabled = _a.disabled, trackId = _a.trackId;
            return {
                id: 'restrictAppExecution',
                icon: 'Admin',
                rbac: [RbacMdeAllowedActions.remediationActions],
                rbacState: RbacControlState.disabled,
                nameKey: nameKey,
                tooltip: _this.i18nService.get(tooltipKey),
                disabled: disabled,
                refreshOnResolve: false,
                tracking: {
                    id: trackId,
                    type: TrackingEventType.Action,
                    component: machineTrackingComponent,
                },
                method: function (_a) {
                    var machine = _a[0];
                    return __awaiter(_this, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, action(machine)];
                                case 1:
                                    response = _b.sent();
                                    if (!response.confirmed) return [3 /*break*/, 3];
                                    return [4 /*yield*/, this.machinesActionsService.openActionCenter(machine, response.data.requestGuid)];
                                case 2:
                                    _b.sent();
                                    _b.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                },
            };
        }));
    };
    MachineEntityTypeActionsService.prototype._createMultipleRestrictAppExecutionActionConfig = function () {
        var _this = this;
        // 'Restrict app execution' action
        return {
            id: 'multipleRestrictAppExecution',
            icon: FabricIconNames.Admin,
            rbac: [RbacMdeAllowedActions.remediationActions],
            rbacState: RbacControlState.disabled,
            name: this.i18nService.strings.machines_entityDetails_actions_restrictAppExecution_title_restrict,
            tooltip: this.i18nService.strings
                .machines_entityDetails_actions_restrictAppExecution_tooltip_restrict,
            refreshOnResolve: false,
            tracking: {
                id: 'multipleMachineAppRestrictionsAction',
                type: TrackingEventType.Action,
                component: machineTrackingComponent,
            },
            method: function (selectedMachines) {
                _this.machinesActionsService.multipleRestrictAppExecution(selectedMachines);
                return Promise.resolve();
            },
        };
    };
    MachineEntityTypeActionsService.prototype._createMultipleRemoveAppRestrictionsActionConfig = function () {
        var _this = this;
        // 'Remove app restriction' action
        return {
            id: 'multipleRemoveRestrictAppExecution',
            icon: FabricIconNames.Admin,
            rbac: [RbacMdeAllowedActions.remediationActions],
            rbacState: RbacControlState.disabled,
            name: this.i18nService.strings
                .machines_entityDetails_actions_restrictAppExecution_title_removeRestriction,
            tooltip: this.i18nService.strings
                .machines_entityDetails_actions_restrictAppExecution_tooltip_removeRestriction,
            refreshOnResolve: false,
            tracking: {
                id: 'multipleMachineAppRemoveRestrictionsAction',
                type: TrackingEventType.Action,
                component: machineTrackingComponent,
            },
            method: function (selectedMachines) {
                _this.machinesActionsService.multipleRemoveAppRestrictions(selectedMachines);
                return Promise.resolve();
            },
        };
    };
    MachineEntityTypeActionsService.prototype.getMachineIsolationState = function () {
        return this.paris
            .getRelatedItem(MachineMachineRequestsStateRelationship, this.machine)
            .toPromise();
    };
    MachineEntityTypeActionsService.prototype.requestMachineIsolationState = function () {
        return __awaiter(this, void 0, void 0, function () {
            var state, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.getMachineIsolationState()];
                    case 1:
                        state = _a.sent();
                        this.machineIsolationAction$.next(state);
                        return [2 /*return*/, state];
                    case 2:
                        error_1 = _a.sent();
                        this.machineIsolationAction$.thrownError(error_1);
                        throw error_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MachineEntityTypeActionsService.prototype._createIsolateMachineActionConfig = function (machine) {
        var _this = this;
        var removeIsolationPartialConfig = {
            nameKey: machine.isManagedByMdatp
                ? 'machines_entityDetails_actions_isolateMachine_title_removeIsolation'
                : 'machines_entityDetails_actions_isolateDevice_title_removeIsolation',
            trackId: 'machineReleaseFromIsolationAction',
            refreshOnResolve: false,
        };
        var isolatePartialConfig = {
            nameKey: machine.isManagedByMdatp
                ? 'machines_entityDetails_actions_isolateMachine_title_isolate'
                : 'machines_entityDetails_actions_isolateDevice_title_isolate',
            trackId: 'machineIsolateAction',
            refreshOnResolve: false,
        };
        var requestStateConfigMap = {
            RequestCompleted: __assign({}, removeIsolationPartialConfig, { tooltipKey: this.machine.isManagedByMdatp
                    ? 'machines.entityDetails.actions.isolateMachine.tooltip.removeIsolation'
                    : 'machines.entityDetails.actions.isolateNotManagedDevice.tooltip.removeIsolation', action: function (machine) {
                    return _this.machinesActionsService.releaseFromIsolation(machine, function () {
                        return _this.requestMachineIsolationState();
                    });
                } }),
            ReverseRequestPending: __assign({}, removeIsolationPartialConfig, { nameKey: 'machines.entityDetails.actions.isolateMachine.title.removeIsolation', tooltipKey: 'machines.entityDetails.actions.$general.tooltip.requestPending', disabled: true }),
            RequestPending: __assign({}, isolatePartialConfig, { tooltipKey: 'machines.entityDetails.actions.$general.tooltip.requestPending', disabled: true }),
            ReverseRequestCompleted: __assign({}, isolatePartialConfig, { tooltipKey: this.machine.isManagedByMdatp
                    ? 'machines.entityDetails.actions.isolateMachine.tooltip.isolate'
                    : 'machines.entityDetails.actions.isolateNotManagedDevice.tooltip.isolate', action: function (machine) {
                    return _this.machinesActionsService.isolate(machine, _this.machinesPermissionsService.isSelectiveIsolationSupported(machine), function () { return _this.requestMachineIsolationState(); });
                }, disabled: machine.isMdatp &&
                    !machine.isManagedByMdatp &&
                    !this.featuresService.isEnabled(Feature.MagellanShieldsUp) }),
            Unknown: __assign({}, isolatePartialConfig, { tooltipKey: 'machines.entityDetails.actions.$general.tooltip.error', disabled: true }),
        };
        this.requestMachineIsolationState();
        return this.machineIsolationAction$
            .pipe(map(function (requestsState) { return requestsState.isolation || 'ReverseRequestCompleted'; }))
            .pipe(map(function (requestState) {
            var _a = requestStateConfigMap[requestState], nameKey = _a.nameKey, tooltipKey = _a.tooltipKey, action = _a.action, disabled = _a.disabled, trackId = _a.trackId;
            return {
                id: 'isolateMachine',
                icon: FabricIconNames.Blocked,
                rbac: [RbacMdeAllowedActions.remediationActions],
                rbacState: RbacControlState.disabled,
                nameKey: nameKey,
                tooltip: _this.i18nService.get(tooltipKey),
                disabled: disabled,
                refreshOnResolve: false,
                tracking: {
                    id: trackId,
                    type: TrackingEventType.Action,
                    component: machineTrackingComponent,
                },
                method: function (_a) {
                    var machine = _a[0];
                    return __awaiter(_this, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, action(machine)];
                                case 1:
                                    response = _b.sent();
                                    if (!response.confirmed) {
                                        return [2 /*return*/];
                                    }
                                    if (!machine.isManagedByMdatp) return [3 /*break*/, 3];
                                    return [4 /*yield*/, this.machinesActionsService.openActionCenter(machine, response.data.requestGuid)];
                                case 2:
                                    _b.sent();
                                    _b.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                },
            };
        }));
    };
    MachineEntityTypeActionsService.prototype._createMultipleIsolateMachineActionConfig = function () {
        var _this = this;
        // 'Isolate machine' action
        return {
            id: 'multipleIsolateMachine',
            icon: FabricIconNames.Blocked,
            rbac: [RbacMdeAllowedActions.remediationActions],
            rbacState: RbacControlState.disabled,
            name: this.i18nService.strings
                .machines_entityDetails_actions_isolateMachine_title_multipleIsolate,
            tooltip: this.i18nService.strings
                .machines_entityDetails_actions_multiIsolateMachine_tooltip_isolate,
            refreshOnResolve: false,
            tracking: {
                id: 'multipleMachineIsolateAction',
                type: TrackingEventType.Action,
                component: machineTrackingComponent,
            },
            method: function (selectedMachines) {
                _this.machinesActionsService.multipleIsolate(selectedMachines, true);
                return Promise.resolve();
            },
        };
    };
    MachineEntityTypeActionsService.prototype._createMultipleReverseIsolateActionConfig = function () {
        var _this = this;
        // 'Release machine from isolation' action
        return {
            id: 'multipleReverseIsolateMachine',
            icon: FabricIconNames.Blocked,
            rbac: [RbacMdeAllowedActions.remediationActions],
            rbacState: RbacControlState.disabled,
            name: this.i18nService.strings
                .machines_entityDetails_actions_isolateMachine_title_removeIsolation,
            tooltip: this.i18nService.strings
                .machines_entityDetails_actions_multiIsolateMachine_tooltip_removeIsolation,
            refreshOnResolve: false,
            tracking: {
                id: 'multipleMachineReleaseFromIsolationAction',
                type: TrackingEventType.Action,
                component: machineTrackingComponent,
            },
            method: function (selectedMachines) {
                _this.machinesActionsService.multipleReleaseFromIsolation(selectedMachines);
                return Promise.resolve();
            },
        };
    };
    MachineEntityTypeActionsService.prototype._createInvestigateActionConfig = function (machines) {
        var _this = this;
        if (!this.featuresService.isEnabled(Feature.InvestigateMachine)) {
            return of(null);
        }
        return of({
            id: 'investigateMachine',
            icon: 'investigationOutline',
            rbac: [RbacMdeAllowedActions.alertsInvestigation],
            rbacState: RbacControlState.disabled,
            nameKey: 'machines.entityDetails.actions.investigateMachine.title',
            tooltip: this.i18nService.get('machines.entityDetails.actions.investigateMachine.tooltip'),
            refreshOnResolve: false,
            disabled: machines.some(function (machine) {
                var osCategory = machine && machine.os && machine.os.platform && machine.os.platform.category;
                return ((machine.isMdatp && !machine.isManagedByMdatp) ||
                    osCategory === OperatingSystemPlatformCategories.macOS ||
                    osCategory === OperatingSystemPlatformCategories.Linux);
            }),
            method: function (machines) {
                var calls = machines.map(function (machine) {
                    return _this.paris.apiCall(InvestigateMachineApiCall, machine);
                });
                return combineLatest(calls)
                    .pipe(take(1), tap(function () {
                    _this.dialogsService.showSuccessSnackbar({
                        text: _this.i18nService.get('machines.entityDetails.actions.investigateMachine.successMessage'),
                        disableForceFocus: true,
                    });
                }, function (err) {
                    _this.errorsDialogService.showError({
                        title: _this.i18nService.get('machines.entityDetails.actions.investigateMachine.failureMessage'),
                        data: err,
                    });
                }))
                    .toPromise();
            },
        });
    };
    MachineEntityTypeActionsService.prototype._createLiveResponseActionConfig = function (machines) {
        var _this = this;
        if (!this.featuresService.isEnabled(Feature.LiveResponse) || machines.length > 1) {
            return of(null);
        }
        var machine = machines[0];
        return defer(function () { return __awaiter(_this, void 0, void 0, function () {
            var supportData;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.liveResponsePermissionsService.isLiveResponseSupportedForMachine(machine)];
                    case 1:
                        supportData = _a.sent();
                        return [2 /*return*/, {
                                id: 'liveResponse',
                                icon: FabricIconNames.Play,
                                rbac: [RbacMdeAllowedActions.liveResponseBasic, RbacMdeAllowedActions.liveResponseAdvanced],
                                rbacState: RbacControlState.disabled,
                                name: this.i18nService.strings.machines_entityDetails_actions_createLiveResponse_title,
                                allowRbacTooltipOverride: false,
                                tooltip: (supportData.disabled && supportData.disableReason) ||
                                    this.i18nService.strings.machines_entityDetails_actions_createLiveResponse_tooltip,
                                refreshOnResolve: false,
                                disabled: supportData.disabled,
                                method: function (_machines) {
                                    return _this.liveResponseService
                                        .createSession(_machines[0])
                                        .then(function (session) {
                                        _this.router.navigate(['live-response', session.id]);
                                    });
                                },
                            }];
                }
            });
        }); });
    };
    MachineEntityTypeActionsService.prototype._createTroubleshootingModeConfig = function (machine) {
        var _this = this;
        if (!this.featuresService.isEnabled(Feature.TroubleshootingMachine)) {
            return of(null);
        }
        var isUserAllowedToEdit = this.rbacControlService.hasRequiredMdePermission([
            RbacMdeAllowedActions.securitySettings,
        ]);
        var troubleshootPartialConfig = {
            nameKey: 'machines.entityDetails.actions.turnOnTroubleshootingMode.title',
            trackId: 'machineTroubleshootAction',
        };
        // TODO: Get proper strings from PMs/writers when they are ready...
        var requestStateConfigMap = {
            RequestCompleted: __assign({}, troubleshootPartialConfig, { tooltipKey: 'machines.entityDetails.actions.troubleshoot.tooltip.troubleshootMode', disabled: false }),
            ReverseRequestPending: __assign({}, troubleshootPartialConfig, { nameKey: 'machines.entityDetails.actions.troubleshoot.title.pendingTroubleshoot', tooltipKey: 'machines.entityDetails.actions.troubleshoot.tooltip.pendingTroubleshoot', disabled: true }),
            RequestPending: __assign({}, troubleshootPartialConfig, { nameKey: 'machines.entityDetails.actions.troubleshoot.title.pendingTroubleshoot', tooltipKey: 'machines.entityDetails.actions.troubleshoot.tooltip.pendingTroubleshoot', disabled: true }),
            ReverseRequestCompleted: __assign({}, troubleshootPartialConfig, { tooltipKey: 'machines.entityDetails.actions.troubleshoot.tooltip.troubleshootMode', disabled: false }),
            NotSupported: __assign({}, troubleshootPartialConfig, { tooltipKey: 'machines_entityDetails_actions_troubleshoot_tooltip_notSupportedTroubleshootMode', disabled: true }),
            Unknown: __assign({}, troubleshootPartialConfig, { tooltipKey: 'machines.entityDetails.actions.troubleshoot.tooltip.troubleshootMode', disabled: false }),
        };
        var hasTroubleshootContent$ = this.paris
            .queryForItem(MachineMachineRequestLatestRelationship, machine)
            .pipe(map(function (dataSet) { return dataSet.items.filter(function (e) { return e.type === 'TroubleshootResponse'; }); }));
        var getLatestTroubleshootState$ = this.paris
            .getRelatedItem(MachineMachineRequestsStateRelationship, machine)
            .pipe(map(function (requestsState) { return requestsState.troubleshoot || 'ReverseRequestCompleted'; }));
        return combineLatest([hasTroubleshootContent$, getLatestTroubleshootState$]).pipe(map(function (_a) {
            var hasTsContent = _a[0], requestState = _a[1];
            var _b = requestStateConfigMap[requestState], nameKey = _b.nameKey, tooltipKey = _b.tooltipKey, disabled = _b.disabled, trackId = _b.trackId;
            var succeededRequests = hasTsContent.filter(function (r) { return r.requestStatus === 'Succeeded' && r.troubleshootState == 1; });
            var hasSucceededRequests = succeededRequests.length > 0;
            var expirationDate = hasSucceededRequests && succeededRequests[0].troubleshootExpirationDateTimeUtc;
            var menuTitleKey = nameKey;
            var tooltipMessage = isUserAllowedToEdit
                ? _this.i18nService.get(tooltipKey)
                : _this.i18nService.get('machines.entityDetails.actions.troubleshoot.tooltip.rbacError');
            var shouldDisableTSModeCommand = disabled;
            if (requestState == 'RequestCompleted' && hasSucceededRequests && isUserAllowedToEdit) {
                // 5 min in milliseconds. Time delta to allow some tolerance between the scheduled
                // expiration time and the current time, to make sure that TS mode is already
                // expired.
                var timeDelta = 1000 * 60 * 5;
                var timeNow = Date.now();
                if (expirationDate.getTime() + timeDelta > timeNow) {
                    shouldDisableTSModeCommand = true;
                    tooltipMessage = _this.i18nService.get('machines.entityDetails.actions.troubleshoot.tooltip.isOn');
                    menuTitleKey = 'machines.entityDetails.actions.troubleshoot.title.isOn';
                }
            }
            return {
                id: 'troubleshootMode',
                icon: FabricIconNames.DeveloperTools,
                nameKey: menuTitleKey,
                tooltip: tooltipMessage,
                disabled: shouldDisableTSModeCommand || !isUserAllowedToEdit,
                tracking: {
                    id: trackId,
                    type: TrackingEventType.Action,
                    component: machineTrackingComponent,
                },
                method: function (_a) {
                    var machine = _a[0];
                    return _this.machinesActionsService.openTroubleshootingModePanel(machine);
                },
            };
        }));
    };
    MachineEntityTypeActionsService.prototype._createActionCenterActionConfig = function (machine) {
        var _this = this;
        var hasActionCenterContent$ = this.paris
            .queryForItem(MachineMachineRequestLatestRelationship, machine)
            .pipe(map(function (dataSet) { return dataSet.items && dataSet.items.length > 0; }));
        var anyRequestActive$ = this.paris
            .getRelatedItem(MachineAnyRequestsActiveRelationship, machine)
            .pipe(map(function (anyRequestsActive) { return anyRequestsActive.isActive; }));
        return combineLatest([hasActionCenterContent$, anyRequestActive$]).pipe(map(function (_a) {
            var hasActionCenterContent = _a[0], anyRequestActive = _a[1];
            return ({
                id: 'actionCenter',
                nameKey: 'machines.entityDetails.actions.actionCenter.title',
                tooltip: _this.i18nService.get('machines.entityDetails.actions.actionCenter.tooltip'),
                refreshOnResolve: false,
                disabled: !anyRequestActive && !hasActionCenterContent,
                tracking: {
                    id: 'MachineOpenActionCenter',
                    type: TrackingEventType.Action,
                    component: machineTrackingComponent,
                },
                icon: {
                    iconName: FabricIconNames.F12DevTools,
                    'data-badged-blue': anyRequestActive ? '!' : null,
                },
                method: function (_a) {
                    var machine = _a[0];
                    return _this.machinesActionsService.openActionCenter(machine);
                },
            });
        }));
    };
    MachineEntityTypeActionsService.prototype._createMachineValueActionConfig = function (machineEntityTypeService) {
        var _this = this;
        if (!this.featuresService.isEnabled(Feature.TvmMachineValue)) {
            return of(null);
        }
        return of({
            id: "machineValue" /* MachineValue */,
            icon: FabricIconNames.Sort,
            nameKey: 'machines_entityDetails_actions_machineValue_title',
            tooltip: this.i18nService.get('machines_entityDetails_actions_machineValue_title'),
            refreshOnResolve: true,
            rbac: [RbacMdeAllowedActions.alertsInvestigation],
            refreshEntityPanelOnResolve: true,
            tracking: {
                id: "machineValue" /* MachineValue */ + 'CommandBar',
                type: TrackingEventType.Action,
                component: machineTrackingComponent,
            },
            method: function (machines) {
                return _this.getMachineValueActionMethod(machineEntityTypeService, machines);
            },
        });
    };
    MachineEntityTypeActionsService.prototype._createReportInaccuracyActionConfig = function () {
        var _this = this;
        if (!this.featuresService.isEnabled(Feature.MagellanReportInaccuracy)) {
            return of(null);
        }
        return of({
            id: 'reportInaccuracy',
            icon: FabricIconNames.Feedback,
            nameKey: 'machines_entityDetails_actions_reportInaccuracy_title',
            tooltip: this.i18nService.strings.machines_entityDetails_actions_reportInaccuracy_title,
            refreshOnResolve: true,
            refreshEntityPanelOnResolve: true,
            tracking: {
                id: 'reportInaccuracyCommandBar',
                type: TrackingEventType.Action,
                component: machineTrackingComponent,
            },
            method: function (machines) {
                return _this.getReportInaccuracyActionMethod(machines);
            },
        });
    };
    MachineEntityTypeActionsService.prototype.getMachineExclusionStateActionConfigProperties = function (isMultiselectMode, proposedExclusionState, isUserAllowedToEdit) {
        var nameKey = proposedExclusionState == MachineExclusionState.Excluded
            ? isMultiselectMode
                ? 'machines_entityDetails_actions_excludeDevices_title'
                : 'machines_entityDetails_actions_excludeDevice_title'
            : isMultiselectMode
                ? 'machines_entityDetails_actions_cancelExclusion_title'
                : 'machines_entityDetails_actions_exclusionDetailsDevice_title';
        var icon = !isMultiselectMode || proposedExclusionState == MachineExclusionState.Excluded
            ? FabricIconNames.DisconnectVirtualMachine
            : FabricIconNames.ConnectVirtualMachine;
        var isButtonDisabled = !isUserAllowedToEdit &&
            (proposedExclusionState === MachineExclusionState.Excluded ||
                (proposedExclusionState === MachineExclusionState.Included && isMultiselectMode));
        return { nameKey: nameKey, icon: icon, isButtonDisabled: isButtonDisabled };
    };
    MachineEntityTypeActionsService.prototype.createMachineExclusionStateActionConfig = function (machines) {
        var _this = this;
        var isMultiselectMode = machines.length != 1;
        var proposedExclusionState = machines.every(function (machine) { return machine.exclusionState === MachineExclusionState.Excluded; })
            ? MachineExclusionState.Included
            : MachineExclusionState.Excluded;
        var isUserAllowedToEdit = this.rbacControlService.hasRequiredMdePermission([
            RbacMdeAllowedActions.securitySettings,
        ]);
        var _a = this.getMachineExclusionStateActionConfigProperties(isMultiselectMode, proposedExclusionState, isUserAllowedToEdit), nameKey = _a.nameKey, icon = _a.icon, isButtonDisabled = _a.isButtonDisabled;
        return {
            id: 'excludeOrIncludeDevice',
            icon: icon,
            nameKey: nameKey,
            tooltip: this.i18nService.get(nameKey),
            refreshOnResolve: true,
            refreshEntityPanelOnResolve: true,
            tracking: {
                id: 'excludeOrIncludeDevice',
                type: TrackingEventType.Action,
                component: machineTrackingComponent,
            },
            disabled: isButtonDisabled,
            method: function (machines) { return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    this.reactPanelsService.renderComponent({
                        componentName: 'ManageMachineExclusionStateFlyoutWithAppBootstrap@wicd-ine/main',
                        props: {
                            machines: machines,
                            isUserAllowedToEdit: isUserAllowedToEdit,
                            proposedExclusionState: proposedExclusionState,
                            isMultiselectMode: isMultiselectMode,
                            shouldSelfDismissOnDone: true,
                            onDone: function () {
                                _this.machinesService.clearCachedMachines();
                                _this.router.navigate(['machines']);
                            },
                        },
                    });
                    return [2 /*return*/];
                });
            }); },
        };
    };
    MachineEntityTypeActionsService.prototype._createMachineExclusionStateActionConfig$ = function (machines) {
        if (!this.featuresService.isEnabled(Feature.ExcludedDevices) || !sccHostService.isSCC) {
            return of(null);
        }
        return of(this.createMachineExclusionStateActionConfig(machines));
    };
    MachineEntityTypeActionsService.prototype.getMachineValueActionMethod = function (machineEntityTypeService, machines) {
        if (machines[0] instanceof Machine) {
            return machineEntityTypeService.entityType.pseudoTags.set(machines, "machineValue" /* MachineValue */);
        }
        else {
            var machineRepository = this.paris.getRepository(Machine);
            machineRepository
                .getItemById(machines[0].assetId)
                .pipe(map(function (machine) {
                return machineEntityTypeService.entityType.pseudoTags.set([machine], "machineValue" /* MachineValue */);
            }))
                .toPromise();
        }
    };
    MachineEntityTypeActionsService.prototype.getReportInaccuracyActionMethod = function (machines) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, openDeviceReportInaccuracyPanel({ selectedItems: machines, entryPoint: 'DeviceInventoryFromDevicePage', isPanelOpen: true })];
            });
        });
    };
    return MachineEntityTypeActionsService;
}());
export { MachineEntityTypeActionsService };
