import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { investigationPendingTypeValues } from './investigation-pending-type.values';
import { InvestigationStatusEnum } from './investigation-status.enum';

@Entity({
	singularName: 'Investigation Pending Type',
	pluralName: 'Investigation Pending Types',
	values: investigationPendingTypeValues,
})
export class InvestigationPendingType extends EntityModelBase<number> {
	@EntityField() type: string;
	@EntityField() name: string;
	@EntityField({ defaultValue: false })
	isUserPending: boolean;
	@EntityField({ defaultValue: false })
	allowRetry: boolean;
	@EntityField({ defaultValue: false })
	isCoveragePending: boolean;
	@EntityField({ defaultValue: false })
	allowUnmanagedHost: boolean;
	@EntityField({ defaultValue: false })
	allowEnterCredentials: boolean;
	@EntityField()
	equivalentStatus: InvestigationStatusEnum;
}
