import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentionableTextComponent } from './components/mentionable-text.component';

@NgModule({
	imports: [CommonModule],
	declarations: [MentionableTextComponent],
	exports: [MentionableTextComponent],
})
export class MentionableTextModule {}
