<!-- tslint:disable:template-click-events-have-key-events -->
<fab-message-bar *ngIf="showMessageBar" [messageBarType]="MessageBarType.info" dismissable="true" [dismissButtonAriaLabel]="'dismiss' | i18n" (onDismiss)="hideMessage()">
	{{"tvm.securityRecommendation.exposeASR.message" | i18n}}
	<a [focusOn]="focus" tabindex="-1" role="link" class="allow-focus-outline" [routerLink]="" (click)="navigateToASRPosture()"> {{"tvm.securityRecommendation.exposeASR.navigateMessage" | i18n}}</a>
</fab-message-bar>
<convergence-promotion-banner sccPagePath='/tvm_dashboard'></convergence-promotion-banner>

<section aria-labelledby="tvm-dashboard-header" class="tvm-dashboard-container wcd-full-height wcd-flex-horizontal">
	<div class="wcd-flex-1">
		<report [report]="tvmDashboardReport" [showControls]="false" [titleDashboardId]="'tvm-dashboard-header'">
			<div class="custom-header wcd-flex-wrap-horizontal">
				<section class="wcd-flex-1 custom-header-section" [attr.aria-labelledby]="'tvm-dashboard-header'">
					<h3 id="tvm-dashboard-header" class="dashboard-title">{{'tvm.dashboard.title' | i18n}}</h3>
				</section>
				<tvm-machine-groups-filter-control [focusOnMachineGroup]="!showMessageBar"></tvm-machine-groups-filter-control>
			</div>
		</report>
	</div>
	<tvm-security-recommendations [refresh$]="refresh$"></tvm-security-recommendations>
</section>
