import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { severityValues } from './severity.entity.values';
import { SeverityId } from './severity-id.model';
import { SeverityType } from './severity-type.enum';
import { ISeverity } from './severity.interface';

@Entity({
	singularName: 'Severity',
	pluralName: 'Severities',
	values: severityValues,
})
export class Severity extends StatusModel<SeverityId, SeverityType> implements ISeverity {
	@EntityField({ defaultValue: false })
	isSelectable: boolean;
}
