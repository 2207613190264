/* tslint:disable:template-click-events-have-key-events */
import { Component, Input } from '@angular/core';
import { ExposedOperatingSystemsStatistics } from '@wcd/domain';
import { TvmTextsService } from '../../../services/tvm-texts.service';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { sumBy } from 'lodash-es';
import { OnInit } from '@angular/core';

@Component({
	selector: 'exposed-operating-systems',
	templateUrl: 'exposed-operating-systems.component.html',
	styleUrls: ['./exposed-operating-systems.component.scss'],
})
export class ExposedOperatingSystemsComponent implements OnInit {
	knownExposedOperatingSystemsStatistics: ExposedOperatingSystemsStatistics[];
	unknownExposedOperatingSystemsCount: number;
	constructor(
		public tvmTextsService: TvmTextsService,
		private router: Router,
		private i18nService: I18nService
	) {}

	@Input()
	exposedOperatingSystemsStatistics: Array<ExposedOperatingSystemsStatistics>;

	ngOnInit() {
		this.knownExposedOperatingSystemsStatistics = this.exposedOperatingSystemsStatistics.filter(
			({ osName }) => this.tvmTextsService.hasOsPlatformLabel(osName)
		);

		this.unknownExposedOperatingSystemsCount = sumBy(
			this.exposedOperatingSystemsStatistics.filter(
				({ osName }) => !this.tvmTextsService.hasOsPlatformLabel(osName)
			),
			({ exposedAssetsCount }) => exposedAssetsCount
		);
	}

	navigateToSoftwarePage(osName: string) {
		const product = this.tvmTextsService.getOsProductName(osName);
		this.router.navigate([`/software-inventory/${encodeURIComponent(product)}`]);
	}

	buildExposedMachinesText(exposedAssetsCount: number): string {
		return exposedAssetsCount === 1
			? this.i18nService.get(
					'tvm.securityRecommendation.exposedOperatingSystems.exposedMachineSingular'
			  )
			: this.i18nService.get('tvm.securityRecommendation.exposedOperatingSystems.exposedMachinePlural');
	}
}
