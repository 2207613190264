import { OnDestroy } from '@angular/core';
import { File, FileIncidentAlertsRelationship, FileVirusTotalFileReportRelationship, } from '@wcd/domain';
import { of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { PrettyNumberPipe } from '@wcd/prettify';
import { AlertsDataviewComponent } from '../../alerts/components/alerts.dataview';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["ActiveAlerts"] = "airs-entity-file-active-alerts";
    CollapsibleID["VirusTotal"] = "airs-entity-file-virus-total";
    CollapsibleID["FileStats"] = "airs-entity-file-stats";
    CollapsibleID["FilePrevalence"] = "airs-entity-file-prevalence";
})(CollapsibleID || (CollapsibleID = {}));
var loadingSymbol = Symbol();
var AirsEntityFileDetailsComponent = /** @class */ (function () {
    function AirsEntityFileDetailsComponent(paris, i18nService, prettyNumberPipe, flavorService, featuresService) {
        var _this = this;
        this.paris = paris;
        this.i18nService = i18nService;
        this.prettyNumberPipe = prettyNumberPipe;
        this.featuresService = featuresService;
        this.malwareFields = DataTableField.fromList([
            {
                id: 'malwareName',
                name: this.i18nService.strings
                    .files_entityDetails_sections_detections_fields_malwareDetected_malware,
                getDisplay: function (f) { return f.prevalentThreatName; },
                sort: { enabled: false },
            },
            {
                id: 'source',
                name: this.i18nService.strings
                    .files_entityDetails_sections_detections_fields_malwareDetected_source,
                getDisplay: function (f) {
                    return _this.i18nService.strings
                        .files_entityDetails_sections_detections_fields_malwareDetected_sources_windowsDefenderAv;
                },
                sort: { enabled: false },
            },
        ]);
        this.loadingSymbol = loadingSymbol;
        this.collapsibleID = CollapsibleID;
        this.fileAlertRepo = this.paris.getRelationshipRepository(FileIncidentAlertsRelationship);
        this.isFilePrevalenceApiEnabled = flavorService.isEnabled(AppFlavorConfig.incidents.filePrevalenceApi);
    }
    Object.defineProperty(AirsEntityFileDetailsComponent.prototype, "file", {
        get: function () {
            return this._file;
        },
        set: function (value) {
            this.setFileData(value);
            this._file = value;
        },
        enumerable: true,
        configurable: true
    });
    AirsEntityFileDetailsComponent.prototype.ngOnDestroy = function () {
        if (this.alertsDataSubscription) {
            this.alertsDataSubscription.unsubscribe();
        }
    };
    AirsEntityFileDetailsComponent.prototype.getAlertsTitle = function (alertsSeveritySummary) {
        if (!alertsSeveritySummary || !alertsSeveritySummary.hasAlerts) {
            return this.i18nService.strings.reports_widgets_alertsSummary_noActiveAlerts;
        }
        var activeAlertsQuantity = alertsSeveritySummary.alertsCount === 1
            ? this.i18nService.strings.reports_widgets_alertsSummary_activeAlerts_singular
            : this.i18nService.strings.reports_widgets_alertsSummary_activeAlerts_plural;
        var incidentQuantity = alertsSeveritySummary.incidentsCount === 1
            ? this.i18nService.strings.reports_widgets_alertsSummary_incidents_singular
            : this.i18nService.strings.reports_widgets_alertsSummary_incidents_plural;
        return this.i18nService.get('reports_widgets_alertsSummary_activeAlertsInIncidents', {
            alertCount: this.prettyNumberPipe.transform(alertsSeveritySummary.alertsCount),
            activeAlerts: activeAlertsQuantity,
            incidentCount: this.prettyNumberPipe.transform(alertsSeveritySummary.incidentsCount),
            incident: incidentQuantity,
        });
    };
    AirsEntityFileDetailsComponent.prototype.setFileData = function (file) {
        var _this = this;
        var _a;
        if (!(file && file.sha1)) {
            this.alertData$ = this.fileStats$ = this.vtData$ = null;
        }
        else if ((this.file && this.file.sha1) !== file.sha1) {
            this.alertData$ = this.fileAlertRepo.getRelatedItem(file).pipe(startWith(loadingSymbol), catchError(function (err) { return of(null); }));
            this.fileStats$ = this.paris
                .getRepository(File)
                .getItemById(file.sha1, undefined, (_a = {}, _a['newFilesApi'] = this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW), _a))
                .pipe(map(function (f) { return [f]; }), startWith(loadingSymbol), catchError(function (err) { return of(null); }));
            this.vtData$ = this.paris
                .getRelatedItem(FileVirusTotalFileReportRelationship, file)
                .pipe(startWith(loadingSymbol), catchError(function (err) { return of(null); }));
        }
        if (this.alertData$) {
            this.alertsDataSubscription = this.alertData$.subscribe(function (alertData) {
                if (alertData !== loadingSymbol) {
                    _this.activeAlertsTitle = _this.getAlertsTitle(alertData.alertsSeveritySummary);
                }
            });
        }
    };
    return AirsEntityFileDetailsComponent;
}());
export { AirsEntityFileDetailsComponent };
