import { Injectable } from '@angular/core';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
import { LogType } from '../enums/log-type.enum';
import { ChecklistValue } from '@wcd/forms';

const MS_TENANTS: Array<string> = [
	'f839b112-d9d7-4d27-9bf6-94542403f21c', // WcdTestPrd-Production
	'b3c1b5fc-828c-45fa-a1e1-10d74f6d6e9c', // WcdTestPrd-Staging
	'72f988bf-86f1-41af-91ab-2d7cd011db47', // Microsoft-Production
	'f686d426-8d16-42db-81b7-ab578e110ccd', // Microsoft-Staging
	'8ae383c5-8624-4ea3-9849-fc2bbd8fa79c', // mock tenant
];

const DEPRECATED_LOG_TYPES: Array<LogType> = [
];

@Injectable()
export class DataExportService {
	availableLogTypes: Array<ChecklistValue>;

	notDeprecatedAvailableLogTypes: Array<ChecklistValue>;

	constructor(private i18nService: I18nService, private authService: AuthService) {
		this.availableLogTypes = Object.keys(LogType)
			.filter(lt => LogType[lt] !== LogType.DynamicEventCollection || this.isMsTenant)
			.map(logType => this.logTypeToChecklistValue(logType));

		this.notDeprecatedAvailableLogTypes = Object.keys(LogType)
			.filter(
				lt =>
					(LogType[lt] !== LogType.DynamicEventCollection || this.isMsTenant) &&
					!this.isDeprecatedLogType(LogType[lt])
			)
			.map(logType => this.logTypeToChecklistValue(logType));
	}

	logTypeToChecklistValue(logTypeKey: string): ChecklistValue {
		return <ChecklistValue>{
			id: logTypeKey,
			name: this.i18nService.get(
				`endpointManagement.nonWindows.applications.dataExport.logTypes.${
					LogType[logTypeKey].replace('-', '') // converting the enum value (kebab-case) to our locstring format (snake_case or CamelCase)
				}`
			),
		};
	}

	isDeprecatedLogType(logTypeValue: string): boolean {
		return !!DEPRECATED_LOG_TYPES.find(lt => lt === logTypeValue);
	}

	private get isMsTenant(): boolean {
		const currTenant: string = this.authService.tenantId;

		return !!MS_TENANTS.find(tenant => tenant.toLowerCase() === currTenant.toLowerCase());
	}
}
