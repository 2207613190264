
		<ng-container [ngSwitch]="true">
			<div class="wcd-full-height wcd-flex-vertical" *ngSwitchCase="!!tabsData">
				<div class="with-tabs wcd-flex-none wcd-padding-bottom wcd-padding-horizontal">
					<tabs [tabsData]="tabsData" [currentTab]="currentTabId"></tabs>
				</div>
				<dataview
					class="wcd-flex-1"
					*ngIf="currentSystemExclusionType"
					[fields]="fields"
					[id]="'system-exclusions-' + currentSystemExclusionType.id"
					[repository]="systemExclusionsRepository"
					[itemActions]="itemActions"
					[disableSelection]="!isUserAllowedActions"
					[dataViewConfig]="dataViewConfig"
					[isItemClickable]="isItemClickable"
					[allowAdd]="!currentSystemExclusionType.readonly && isUserAllowedActions"
					(onTableRenderComplete)="onTableRenderComplete()"
					(onNewItem)="addNewSystemExclusion()"
					(onItemClick)="
						systemExclusionsService.showEditSystemExclusionDialog(
							currentSystemExclusionType,
							$event.item
						)
					"
					[queueHeader]="true"
					[padLeft]="false"
					responsiveActionBar="true"
					responsiveLayout="true"
					[removePaddingRight]="isScc"
				>
					<p dataview-header class="wcd-padding-top">
						{{ 'systemExclusions.typeDescriptions.' + currentSystemExclusionType.id | i18n }}
					</p>
				</dataview>
			</div>
			<div class="wcd-full-height loading-overlay error-message" *ngSwitchCase="!!error">
				<wcd-shared-icon [iconName]="'error'"> </wcd-shared-icon>
				Error loading Allowed/Blocked list
			</div>
			<div class="wcd-full-height loading-overlay" *ngSwitchDefault>
				<i class="loader-icon wcd-margin-right"></i>
				Loading Allowed/Blocked list
			</div>
		</ng-container>
	