import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostListener,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { VerticalStepModel } from './vertical-step.model';
import { StepStatus } from '../../models/wizard.model';
import { debounce } from 'lodash-es';
import { config } from '@wcd/shared';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'vertical-steps',
	templateUrl: './vertical-steps.component.html',
	styleUrls: ['./vertical-steps.component.scss'],
})
export class VerticalStepsComponent implements OnChanges, AfterViewInit {
	@Input() steps: VerticalStepModel[];
	@Input() isPanel: boolean = false;
	@Input() containerClassName: string;

	@HostListener('window:resize')
	windowResize() {
		this._onResizeDebounced();
	}

	StepStatus = StepStatus;
	currentStep: VerticalStepModel;
	isNarrowLayout: boolean = false;
	stepsDropdownOpen: boolean = false;
	private _onResizeDebounced: () => void;

	constructor(private changeDetectorRef: ChangeDetectorRef) {
		this._onResizeDebounced = debounce(this.onResize, 1000);
	}

	onResize() {
		const screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
		const isNarrowLayoutAfterResize = config.msScreenSizeBreakpoints.medium >= screenWidth;
		if (isNarrowLayoutAfterResize !== this.isNarrowLayout) {
			this.isNarrowLayout = isNarrowLayoutAfterResize;
			this.changeDetectorRef.detectChanges();
		}
	};

	ngAfterViewInit(): void {
		this.onResize();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.currentStep = this.steps.find(step => step.status === StepStatus.current);
		this.changeDetectorRef.detectChanges();
	}

	trackByFunction(index): number {
		return index;
	}

	toggleStepsDropdown() {
		this.stepsDropdownOpen = !this.stepsDropdownOpen;
	}

	getStepAriaLabelKey(stepStatus): string {
		return 'wizard_stepStatusAria_' + stepStatus;
	}

	getIconForStatus(status: string): string {
		return statusToIcon[status];
	}

}

const statusToIcon = {
	[StepStatus.completed]: 'CompletedSolid',
	[StepStatus.current]: 'CircleFill',
	[StepStatus.next]: 'CircleRing',
};

