import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	SecurityContext,
} from '@angular/core';
import { ModelBase } from '@microsoft/paris';
import { OnChanges, TypedChanges, wasChangedAndExists } from '@wcd/angular-extensions';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import {
	ImpactedEntities,
	AadUser,
	Machine,
	Mailbox,
	Application,
	ApplicationType,
	MachineGroup,
} from '@wcd/domain';
import { entityMap, EntityTypeMap } from '../impacted-entities.model';
import { find } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';
import { FabricIconNames } from '@wcd/scc-common';
import { WicdSanitizerService } from '@wcd/shared';

export interface EntityCounterItem {
	icon?: string;
	tooltip?: string;
	display: string;
}

type SupportedEntityType = 'machine' | 'machineGroup' | 'aaduser' | 'mailbox' | 'app';
type EntitiesCountType = { [key in SupportedEntityType]?: number };

@Component({
	selector: 'impacted-entities-counter',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	template: `
		<div class="wcd-impacted-entities-counter">
			<ul
				class="wcd-flex wcd-padding-none-all wcd-margin-none-all"
				[class.wcd-flex-horizontal]="layout === 'row'"
			>
				<li
					[wcdTooltip]="entity.tooltip"
					[wcdTooltipAllowHtmlRendering]="true"
					[wcdTooltipSanitizeHtml]="false"
					class="wcd-padding-small-right wcd-text-overflow-medium"
					*ngFor="let entity of entitiesList"
				>
					<fab-icon
						*ngIf="entity.icon"
						contentClass="field-value-icon"
						[iconName]="entity.icon"
					></fab-icon>
					<span>{{ entity.display }}</span>
				</li>
			</ul>
		</div>
	`,
	styleUrls: ['./impacted-entities-counter.component.scss'],
})
export class ImpactedEntitiesCounterComponent<TEntity extends ModelBase, TFixedDataViewOptions extends object>
	implements
		OnInit,
		OnChanges<ImpactedEntitiesCounterComponent<TEntity, TFixedDataViewOptions>>,
		OnDestroy {
	/*
	Entities map listing
	 */
	@Input() entities: ImpactedEntities;

	/*
	Entities count
	*/
	@Input() entitiesCount: EntitiesCountType;

	/*
	Layout of entities list, row || column
	 */
	@Input() layout: string = 'row';

	entitiesList: EntityCounterItem[];
	applicationAssetFeatureEnabled = this.featuresService.isEnabled(Feature.ConsiderApplicationAsAsset);

	constructor(
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly i18nService: I18nService,
		private domSanitizer: WicdSanitizerService,
		private featuresService: FeaturesService
	) {}

	ngOnInit(): void {}

	ngOnChanges(changes: TypedChanges<ImpactedEntitiesCounterComponent<TEntity, TFixedDataViewOptions>>) {
		if (!wasChangedAndExists(changes.entities) || !changes.entities.currentValue) {
			return;
		}
		this.parsEntitiesList(changes.entities.currentValue, changes.entitiesCount.currentValue);
	}

	private parsEntitiesList(list: ImpactedEntities, entitiesCount: EntitiesCountType) {
		this.entitiesList = Object.entries(list)
			.filter((entry) => entry[1] && entry[1].length)
			.filter((entry) => (entry[0] && entry[0] === 'apps' ? this.applicationAssetFeatureEnabled : true))
			.sort((a, b) => entityMap[a[0]].order - entityMap[b[0]].order)
			.map((entry) => {
				const [entityKey, entities] = entry;
				const typeMap = entityMap[entityKey];
				const entityType = this.globalEntityTypesService.getEntityType(typeMap.type);
				const icon =
					entityType.id === 'app'
						? (entities[0] as Application).type === ApplicationType.OAuthApplication
							? FabricIconNames.AppIconDefault
							: FabricIconNames.Cloud
						: entityType.icon;
				const singularName = entityType.entitySingularNameKey
					? this.i18nService.get(entityType.entitySingularNameKey)
					: entityType.entity.singularName;
				const pluralName = entityType.entityPluralNameKey
					? this.i18nService.get(entityType.entityPluralNameKey)
					: entityType.entity.pluralName;
				return {
					icon,
					tooltip: entities
						.map((entity) => this.getSingleEntityDisplay(entity, typeMap))
						.map((text) => this.domSanitizer.sanitize(SecurityContext.HTML, text))
						.join('</br>'),
					display:
						entityKey === 'machineGroup'
							? `${entitiesCount[entityType.id]} ${
									entities.length > 1 ? pluralName : singularName
							  }`
							: entities.length > 1
							? `${entitiesCount[entityType.id]} ${pluralName}`
							: this.getSingleEntityDisplay(entities[0], typeMap),
				};
			})
			.filter((item) => item.display);
	}

	/** Get the first display property that is populated for the given entity */
	private getSingleEntityDisplay(
		entity: Machine | Mailbox | AadUser | Application | MachineGroup,
		typeMap: EntityTypeMap
	) {
		const displayProperty = find(typeMap.displayProps, (displayProp) => !!entity[displayProp]);
		return (displayProperty && entity[displayProperty]) || null;
	}

	ngOnDestroy() {}
}
