/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i2 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i5 from "@angular-react/fabric";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../../../projects/filters/src/lib/components/filters.component.ngfactory";
import * as i8 from "../../../../../../../projects/filters/src/lib/components/filters.component";
import * as i9 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i12 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i13 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i14 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i15 from "./dataview-action-filter.component";
var styles_DataviewActionFilterComponent = [];
var RenderType_DataviewActionFilterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DataviewActionFilterComponent, data: {} });
export { RenderType_DataviewActionFilterComponent as RenderType_DataviewActionFilterComponent };
function View_DataviewActionFilterComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), "grid.commands.filters.text")); _ck(_v, 1, 0, currVal_0); }); }
function View_DataviewActionFilterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["filterButton", 1]], null, 9, "button", [["class", "hidden-action btn btn-link command-bar-item-button icon-only command-bar-filter"], ["data-track-id", "toggleFilters"], ["data-track-type", "Button"]], [[1, "id", 0], [2, "checked", null], [1, "aria-label", 0]], [[null, "click"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.toggleFilters() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 147456, null, 0, i1.TooltipDirective, [i0.ElementRef, i2.TooltipsService, i3.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i0.ɵppd(2, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "fab-icon", [], null, null, null, i4.View_FabIconComponent_0, i4.RenderType_FabIconComponent)), i0.ɵdid(5, 5816320, null, 0, i5.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DataviewActionFilterComponent_2)), i0.ɵdid(8, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.getLocalizedTooltip(); _ck(_v, 1, 0, currVal_3); var currVal_4 = _co.icon; _ck(_v, 5, 0, currVal_4); var currVal_5 = (_co.showText && !_co.smallScreenView); _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dataviewId + (_co.isInMoreMenu ? "-more" : "")); var currVal_1 = _co.filtersOpened; var currVal_2 = i0.ɵunv(_v, 0, 2, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "grid.commands.filters.text")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DataviewActionFilterComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-full-height wcd-flex-center-all"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.dataview_error_loading_filters; _ck(_v, 3, 0, currVal_0); }); }
function View_DataviewActionFilterComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "wcd-filters", [["class", "wcd-full-height"]], null, [[null, "filtersChange"], [null, "filtersCancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filtersChange" === en)) {
        var pd_0 = (_co.onFiltersChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("filtersCancel" === en)) {
        var pd_1 = ((_co.onFiltersCancelCallback ? _co.onFiltersCancelCallback() : null) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_FiltersComponent_0, i7.RenderType_FiltersComponent)), i0.ɵdid(3, 4243456, [[2, 4]], 0, i8.FiltersComponent, [i0.ChangeDetectorRef, i0.NgZone, i9.I18nService, i10.LiveAnnouncer], { fields: [0, "fields"], data: [1, "data"], parentFocusable: [2, "parentFocusable"], fixedSelectedValues: [3, "fixedSelectedValues"], serializedFilters: [4, "serializedFilters"] }, { filtersChange: "filtersChange", filtersCancel: "filtersCancel" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataViewModel.filterFields; var currVal_1 = _v.context.ngIf; var currVal_2 = i0.ɵnov(_v.parent.parent, 1).binnedFocusOnFirstfocusable; var currVal_3 = _co.dataViewModel.fixedFilterValues; var currVal_4 = ((_co.dataViewModel.filtersState == null) ? null : _co.dataViewModel.filtersState.serialized); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_DataviewActionFilterComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "wcd-full-height wcd-flex-center-all"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "fab-spinner", [], null, null, null, i4.View_FabSpinnerComponent_0, i4.RenderType_FabSpinnerComponent)), i0.ɵdid(4, 5816320, null, 0, i5.FabSpinnerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { label: [0, "label"] }, null), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent.parent, 0), "filters.loading")); _ck(_v, 4, 0, currVal_0); }, null); }
function View_DataviewActionFilterComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_DataviewActionFilterComponent_6)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i6.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(0, [["loadingFilters", 2]], null, 0, null, View_DataviewActionFilterComponent_7)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.dataViewModel.filtersData$)); var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_DataviewActionFilterComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "wcd-panel", [["class", "responsive-panel"]], [[2, "wcd-padding-large-top", null]], [[null, "close"], [null, "open"], [null, "keydown.arrowUp"], [null, "keydown.arrowDown"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.toggleFilters(false) !== false);
        ad = (pd_1 && ad);
    } if (("open" === en)) {
        var pd_2 = (_co.toggleFilters(true) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.arrowUp" === en)) {
        var pd_3 = (_co.onKeydown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.arrowDown" === en)) {
        var pd_4 = (_co.onKeydown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i11.View_PanelComponent_0, i11.RenderType_PanelComponent)), i0.ɵdid(1, 114688, [[3, 4], ["filterPanel", 4]], 1, i12.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i13.PanelService], { disableAutoFocus: [0, "disableAutoFocus"], settings: [1, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 4, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_DataviewActionFilterComponent_4)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵand(0, [["filters", 2]], 1, 0, null, View_DataviewActionFilterComponent_5)), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.disableFilterPanelAutoFocus; var currVal_2 = _co.filtersPanelSettings; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.dataViewModel.filtersError; var currVal_4 = i0.ɵnov(_v, 7); _ck(_v, 5, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datatablePadTop; _ck(_v, 0, 0, currVal_0); }); }
export function View_DataviewActionFilterComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i14.I18nPipe, [i9.I18nService]), i0.ɵqud(671088640, 1, { filterButton: 0 }), i0.ɵqud(671088640, 2, { _filtersComponent: 0 }), i0.ɵqud(671088640, 3, { filterPanel: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DataviewActionFilterComponent_1)), i0.ɵdid(6, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DataviewActionFilterComponent_3)), i0.ɵdid(9, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataViewModel.allowFilters; _ck(_v, 6, 0, currVal_0); var currVal_1 = (((_co.dataViewModel == null) ? null : _co.dataViewModel.allowFilters) && _co.filtersOpened); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_DataviewActionFilterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dataview-action-filter", [], null, null, null, View_DataviewActionFilterComponent_0, RenderType_DataviewActionFilterComponent)), i0.ɵdid(1, 114688, null, 0, i15.DataviewActionFilterComponent, [i9.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataviewActionFilterComponentNgFactory = i0.ɵccf("dataview-action-filter", i15.DataviewActionFilterComponent, View_DataviewActionFilterComponent_Host_0, { filterActionConfig: "filterActionConfig", dataViewModel: "dataViewModel", smallScreenView: "smallScreenView", isInMoreMenu: "isInMoreMenu" }, {}, []);
export { DataviewActionFilterComponentNgFactory as DataviewActionFilterComponentNgFactory };
