import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { Tag } from './tag.entity';
import { TagType } from './tag-type.enum';

@ValueObject({
	singularName: 'Tags Collection',
	pluralName: 'Tags Collections',
})
export class TagsCollection extends ModelBase {
	@EntityField({
		data: 'BuiltInTags',
		arrayOf: Tag,
		parse: (tags: Array<string>) =>
			tags.map(
				(tag: string) =>
					new Tag({
						id: tag,
						name: tag,
						type: TagType.system,
						isEditable: false,
					})
			),
	})
	builtInTags: Array<Tag>;

	@EntityField({
		data: 'UserDefinedTags',
		arrayOf: Tag,
		parse: (tags: Array<string>) =>
			tags.map((tag: string) => new Tag({ id: tag, name: tag, type: TagType.user })),
	})
	userDefinedTags: Array<Tag>;

	get allTags(): Array<Tag> {
		return this.builtInTags.concat(this.userDefinedTags);
	}
}
