import { ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TrackingEventPropertiesBase } from './tracking-event.interface';
import { merge, isEqual, get, isNil } from 'lodash-es';
import * as c3 from 'c3';
import * as d3 from 'd3';

const HORIZONTAL_BAR_HEIGHT = 25;
const HORIZONTAL_BAR_PADDING = 30;
const DISABLED_CURSOR_CLASS = 'disable-cursor';

export class ChartComponent implements OnInit, OnChanges, OnDestroy {
	@Input() settings: ChartSettings;
	@Input() data: any;
	@Input() change: any;
	@Input() enableRenderOnSettingsChanges: boolean = false;

	protected el: HTMLElement;
	private c3Chart: c3.ChartAPI;
	protected chartSettings: ChartSettings = {};
	private fullSettings: ChartSettings;

	constructor(protected elementRef: ElementRef) {}

	ngOnInit() {
		this.fullSettings = merge({}, this.chartSettings, this.settings);
		this.el = this.elementRef.nativeElement.querySelector('.chart');
		this.render(false);
	}

	ngOnChanges(changes) {
		if (this.fullSettings) {
			this.render(
				changes.data && !isEqual(changes.data.currentValue, changes.data.previousValue)
				|| this.enableRenderOnSettingsChanges && changes.settings
			);
		}
	}

	ngOnDestroy() {
		if (this.c3Chart) {
			// remove keyup listener
			d3.selectAll(this.el.querySelectorAll(`.c3-target-${CHART_CLICKABLE_MARKER_CLASS} path`)).on(
				'keyup',
				null
			);

			this.c3Chart.destroy();
		}
	}

	getEl(){
		return this.el;
	}
	private render(forceGenerate: boolean) {
		if (!this.data) {
			if (this.c3Chart) {
				this.c3Chart.unload();
				// without this, the chart breaks when loaded again
				this.c3Chart.load({ unload: true });
			}

			return;
		}

		let data: C3ChartData;
		const dataChanged = this.isDataChanged();

		if (dataChanged || forceGenerate) {
			this.fullSettings = merge({}, this.chartSettings, this.settings);
		}

		if (!this.c3Chart || dataChanged || forceGenerate) {
			this.generate();
		} else {
			const rawChartData: any = this.fullSettings.dataPath
				? get(this.data, this.fullSettings.dataPath)
				: this.data;

			if (rawChartData.length) {
				data = this.getChartData();

				if (
					!this.fullSettings.options ||
					!this.fullSettings.options.bar ||
					!this.fullSettings.options.bar.width
				) {
					const height: number = this.getChartHeight(data);

					if (height) {
						this.c3Chart.resize({
							height: height,
						});
					}
				}

				this.c3Chart.load(data);
			} else {
				this.c3Chart.unload();
			}
		}
	}

	protected showTooltip(item: any){
		if(!this.c3Chart || !this.c3Chart['tooltip'])
			return;
		this.c3Chart['tooltip'].show(item);
	}
	protected hideTooltip(){
		if(!this.c3Chart || !this.c3Chart['tooltip'])
			return;
		this.c3Chart['tooltip'].hide();
	}

	private isDataChanged(): boolean {
		// In case data has changed we want to marge the settings again to generate an updated chart
		return (
			this.fullSettings &&
			this.fullSettings.options &&
			this.settings.options &&
			!isEqual(this.fullSettings.options.data, this.settings.options.data)
		);
	}

	private generate() {
		const options: c3.ChartConfiguration = this.getChartOptions();
		options.bindto = this.el;
		/*
			This is a fix for accessibility bugs:
			https://dev.azure.com/microsoft/OS/_workitems/edit/21318448, https://dev.azure.com/microsoft/OS/_workitems/edit/24081505
			and it's a workaround until C3 will support accessibility: https://github.com/c3js/c3/issues/2632
			and should be removed (along with the definition and usage of "CHART_CLICKABLE_MARKER_CLASS"
			once C3 will support accessibility.
		*/
		if (options.data.onclick) {
			const self = this;
			const originalOnRendered = options.onrendered;
			options.onrendered = function() {
				if (originalOnRendered) {
					// c3 calls "onrendered.call($$)" ("$$" being c3) to pass its context
					originalOnRendered.call(this);
				}

				// add focusable elements to clickable elements
				d3.selectAll(self.el.querySelectorAll(`.c3-target-${CHART_CLICKABLE_MARKER_CLASS} path`))
					.attr('tabindex', '0')
					.attr('focusable', 'true')
					.attr('role', 'link')
					.each((dataPoint: any, index: number, nodesList: SVGElement[]) => {
						const element = <SVGElement>nodesList[index];
						if (
							options.axis &&
							options.axis.x &&
							options.axis.x.categories &&
							options.axis.x.categories[index]
						) {
							// used in bar charts
							element.setAttribute('aria-label', options.axis.x.categories[index]);
						} else if (dataPoint.data && dataPoint.data.id) {
							// used in pie charts
							if (self && self.data && self.data[index] && self.data[index].ariaLabel)
								element.setAttribute('aria-label', self.data[index].ariaLabel);
							else {
								d3.select(nodesList[index])
									.append('svg:title')
									.text(`${dataPoint.data.values[0].value + ' ' + dataPoint.data.id}`)
							}
						}
					})
					.on('keyup', (dataPoint: any) => {
						// d3.event holds the actual keyboard event
						if (d3.event.keyCode === 13) {
							options.data.onclick(dataPoint.data || dataPoint, d3.event.target);
						}
					});
			};
		}

		options.data.type = this.fullSettings.chartType;

		if (!options.bar || !options.bar.width) {
			const height: number = this.getChartHeight(options.data);
			if (height) options.size = { height: height };
		}

		if (this.fullSettings && this.fullSettings.disableCursor) {
			this.el.classList.add(DISABLED_CURSOR_CLASS);
		}

		setTimeout(() => {
			this.c3Chart = c3.generate(options);
		}, 1);
	}

	private getChartHeight(c3Data): number {
		if (
			this.fullSettings.chartType === 'bar' &&
			this.fullSettings.options &&
			this.fullSettings.options.axis &&
			this.fullSettings.options.axis.rotated
		) {
			const barCount: number = c3Data.columns[0].length - 1,
				barOptions: { width?: number | { ratio: number } } =
					this.fullSettings.options && this.fullSettings.options.bar,
				barWidth: number =
					barOptions && typeof barOptions.width === 'number'
						? barOptions.width
						: HORIZONTAL_BAR_HEIGHT,
				barPadding: number =
					(barOptions && typeof barOptions.width === 'number' && barOptions.width * 1.2) ||
					HORIZONTAL_BAR_PADDING;

			return barCount * (barWidth + barPadding) + barPadding;
		}

		return null;
	}

	/**
	 * Formats items for a chart according to settings
	 */
	protected getChartOptions(): c3.ChartConfiguration {
		const chartOptions: any = {
			data: this.getChartData(),
		};
		if (this.fullSettings.options) merge(chartOptions, this.fullSettings.options);

		if (this.fullSettings.series) {
			if (this.fullSettings.xProperty) {
				chartOptions.data.x = 'x';
				if (!chartOptions.axis || !chartOptions.axis.x || !chartOptions.axis.x.type) {
					merge(chartOptions, {
						axis: { x: { type: 'category' } },
					});
				}
			}
		}

		return chartOptions;
	}

	/**
	 * Get the items object of the chart - columns and names of the chart
	 */
	private getChartData(): C3ChartData {
		const chartData: C3ChartData = {};
		let data: Array<any> = this.data;

		if (this.fullSettings.dataPath) data = <Array<any>>get(this.data, this.fullSettings.dataPath);

		if (this.fullSettings.dataLimit) data = data.slice(0, this.fullSettings.dataLimit);

		if (this.fullSettings.series) {
			const groupIndex: { [index: string]: Array<string | number> } = {},
				groups: Array<string> = [],
				series: Array<ChartSettingsSeriesItem> =
					this.fullSettings.series instanceof Function
						? this.fullSettings.series(data, this.fullSettings)
						: this.fullSettings.series;

			chartData.names = {};
			chartData.columns = [];

			if (this.fullSettings.xProperty) {
				groupIndex.x = ['x'];
				chartData.columns.push(groupIndex.x);
			}

			if (this.fullSettings.setColors) chartData.colors = {};

			series.forEach((series: ChartSettingsSeriesItem) => {
				const group: Array<string | number> = [series.value];

				groupIndex[series.value] = group;
				chartData.columns.push(group);
				chartData.names[series.value] = series.name;

				if (this.fullSettings.setColors) chartData.colors[series.value] = series.color;

				groups.push(<string>series.value);
			});

			data.forEach((item: any) => {
				const values: { [index: string]: any } = this.fullSettings.useValues ? item.values : item;

				series.forEach((series: ChartSettingsSeriesItem) => {
					const value: number = values[series.value];
					groupIndex[series.value].push(typeof value === 'number' || isNil(value) ? value : 0);
				});

				if (this.fullSettings.xProperty)
					groupIndex.x.push(item[this.fullSettings.xProperty] || '(Unknown)');
			});

			if (this.fullSettings.stackGroups) chartData.groups = [groups];
		} else if (this.fullSettings.columnName && this.fullSettings.columnValue) {
			chartData.columns = data.map(item => {
				return [
					item[this.fullSettings.columnName],
					this.fullSettings.columnValue ? item[this.fullSettings.columnValue] : item,
				];
			});

			this.addClickableColumnClasses(chartData);
		} else if (this.fullSettings.options.data.columns) {
			chartData.columns = this.fullSettings.options.data.columns;
			this.addClickableColumnClasses(chartData);
		} else {
			chartData.json = data;
		}

		const dataNames = get(this.data, 'names');
		if (dataNames) {
			chartData.names = dataNames;
		}

		if (this.fullSettings.onclick) chartData.onclick = this.fullSettings.onclick;

		return chartData;
	}

	private addClickableColumnClasses(chartData) {
		const options = this.fullSettings && this.fullSettings.options;

		if (options && options.data && options.data.onclick) {
			chartData.classes = chartData.columns.reduce((acc, item) => {
				acc[item[0]] = CHART_CLICKABLE_MARKER_CLASS;

				return acc;
			}, {});
		}
	}
}

export const CHART_CLICKABLE_MARKER_CLASS = 'clickable';

export interface ChartSettings {
	chartType?: string;
	columnName?: string;
	columnValue?: string;
	data?: { order?: 'desc' | 'asc' };
	dataLimit?: number;
	dataPath?: string;
	onclick?: (item) => any;
	options?: c3.ChartConfiguration;
	series?:
		| ((data: any, settings: ChartSettings) => Array<ChartSettingsSeriesItem>)
		| Array<ChartSettingsSeriesItem>;
	setColors?: boolean;
	stackGroups?: boolean;
	useValues?: boolean;
	xProperty?: string;
	tracking?: TrackingEventPropertiesBase;
	disableCursor?: boolean;
}

export interface ChartSettingsSeriesItem {
	name: string;
	value: string | number;
	color?: string;
}

interface C3ChartData {
	url?: string;
	json?: {};
	keys?: { x?: string; value: string[] };
	rows?: c3.PrimitiveArray[];
	columns?: c3.PrimitiveArray[];
	classes?: { [key: string]: string };
	categories?: string[];
	axes?: { [key: string]: string };
	colors?: { [key: string]: string };
	names?: { [key: string]: string };
	type?: string;
	types?: { [key: string]: string };
	unload?: boolean | c3.ArrayOrString;

	groups?: Array<Array<string>>;
	onclick?: (item) => any;

	done?(): any;
}
