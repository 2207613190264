import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { camelCase } from 'lodash-es';
import { protectionLevelValues } from './protection-level.values';

@Entity({
	singularName: 'Protection Level',
	pluralName: 'Protection Levels',
	readonly: true,
	values: protectionLevelValues,
})
export class ProtectionLevel extends EntityModelBase {
	@EntityField() nameKey: string;

	@EntityField({ defaultValue: true })
	hostEdit: boolean;

	@EntityField({
		data: 'is_default',
	})
	isDefault: boolean;

	@EntityField() descriptionKey: string;

	readonly type: string;
	readonly order: number;

	constructor(data?) {
		super(data);

		if (data) {
			this.type = camelCase(data.name);
		}
	}
}
