import { WcdPortalParisConfig } from './../paris-config.interface';
import { ApiCall, ApiCallModel } from '@microsoft/paris';

@ApiCall({
	name: 'Platform provisioning api call',
	endpoint: 'mtp/additionalPlatformProvisioning',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class AdditionalPlatformProvisionApiCall extends ApiCallModel<void> {}
