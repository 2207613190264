var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { RemediationStatusId, RemediationStatusType, VerdictStatusType, } from './airs-entity-status-types.enum';
var ɵ0 = RemediationStatusId.Remediated, ɵ1 = RemediationStatusId.NotFound, ɵ2 = RemediationStatusId.Unremediated, ɵ3 = RemediationStatusId.PartiallyRemediated;
export var airsEntityStatusValues = [
    {
        id: ɵ0,
        name: 'Remediated',
        helpKey: null,
        type: RemediationStatusType.Remediated,
        className: 'success',
        paletteColor: 'green',
        priority: 2,
        iconName: 'shield',
        isImportant: true,
        isRemediated: true,
    },
    {
        id: 1,
        name: 'Malicious',
        helpKey: null,
        type: VerdictStatusType.Malicious,
        className: 'highSeverity',
        paletteColor: 'highSeverity',
        priority: 1,
        isImportant: true,
        iconName: 'malware',
        isMalicious: true,
        isVerdict: true,
    },
    {
        id: 2,
        name: 'Suspicious',
        helpKey: null,
        type: VerdictStatusType.Suspicious,
        className: 'mediumSeverity',
        paletteColor: 'mediumSeverity',
        priority: 3,
        isImportant: true,
        iconName: 'suspicious',
        isSuspicious: true,
        isVerdict: true,
    },
    {
        id: 3,
        name: 'Verified',
        helpKey: null,
        type: VerdictStatusType.Verified,
        className: 'benign',
        paletteColor: 'blueDark',
        priority: 4,
        iconName: 'check',
        isLegacy: true,
        isVerdict: true,
    },
    {
        id: 4,
        name: 'No threats found',
        helpKey: null,
        type: VerdictStatusType.Clean,
        className: 'benign',
        paletteColor: 'blueDark',
        priority: 4,
        iconName: 'safe',
        isVerdict: true,
    },
    {
        id: 5,
        name: 'Unknown',
        helpKey: null,
        type: VerdictStatusType.Unknown,
        priority: 5,
        iconName: 'help',
        isVerdict: true,
        className: 'neutralPrimaryAlt',
        paletteColor: 'neutralTertiaryAlt',
    },
    {
        id: ɵ1,
        name: 'Not found',
        helpKey: null,
        type: RemediationStatusType.NotFound,
        priority: 6,
        iconName: 'help',
        className: 'neutralPrimaryAlt',
        paletteColor: 'neutralTertiaryAlt',
    },
    {
        id: ɵ2,
        name: 'Unremediated',
        paletteColor: 'orangeLighter',
        helpKey: null,
        type: RemediationStatusType.Unremediated,
        priority: 7,
        isImportant: true,
        isVerdict: false,
    },
    {
        id: ɵ3,
        name: 'Partially Remediated',
        helpKey: null,
        type: RemediationStatusType.PartiallyRemediated,
        className: 'warning',
        paletteColor: 'yellow',
        priority: 0,
        isImportant: true,
        isVerdict: false,
    },
];
/**
 * @deprecated use AirsEntityVerdict/AirsEntityRemediationStatus instead
 */
var AirsEntityStatus = /** @class */ (function (_super) {
    __extends(AirsEntityStatus, _super);
    function AirsEntityStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AirsEntityStatus.prototype, "isRemediated", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AirsEntityStatus.prototype, "isImportant", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AirsEntityStatus.prototype, "isMalicious", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AirsEntityStatus.prototype, "isSuspicious", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AirsEntityStatus.prototype, "isLegacy", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AirsEntityStatus.prototype, "isVerdict", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AirsEntityStatus.prototype, "paletteColor", void 0);
    AirsEntityStatus = __decorate([
        Entity({
            singularName: 'Entity Status',
            pluralName: 'Entity Statuses',
            values: airsEntityStatusValues,
        })
    ], AirsEntityStatus);
    return AirsEntityStatus;
}(StatusModel));
export { AirsEntityStatus };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
