var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnChanges } from '@angular/core';
import { Mailbox } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { FeaturesService, Feature } from '@wcd/config';
var MailboxEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(MailboxEntityDetailsComponent, _super);
    function MailboxEntityDetailsComponent(injector, globalEntityTypesService, featuresService) {
        var _this = _super.call(this, injector) || this;
        _this.featuresService = featuresService;
        _this.mailboxEntity = globalEntityTypesService.getEntityType(Mailbox);
        return _this;
    }
    MailboxEntityDetailsComponent.prototype.ngOnChanges = function () {
        this.showLink = !this.featuresService.isEnabled(Feature.PortedSccPages);
        this.mailboxPageLink = this.mailboxEntity.getEntitiesLink([this.entity]);
        this.shouldLinkTabOut = this.mailboxEntity.getUseExternalRouting([this.entity]);
        this.displayName = this.mailboxEntity.getEntityName(this.entity);
    };
    return MailboxEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { MailboxEntityDetailsComponent };
