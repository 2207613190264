import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { ProfileForCreationFormData } from '../../profiles-tab/models/profile-for-creation.model';
import {Observable, Subscription} from 'rxjs';
import { TabModel } from '../../../../../shared/components/tabs/tab.model';
import { Paris, Repository } from '@microsoft/paris';
import { BaselineProfileSettings } from '@wcd/domain';
import { TvmTextsService } from '../../../../../tvm/services/tvm-texts.service';

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './profile-settings-step.component.html',
})
export class ProfileSettingsStepComponent extends WizardBaseStep<ProfileForCreationFormData>
	implements OnInit, OnDestroy {
	private _subscription: Subscription;

	profileSettingsRepo: Repository<BaselineProfileSettings>;
	settings$: Observable<BaselineProfileSettings[]>;
	operatingSystemsActions: TabModel[];
	benchmarksActions: TabModel[];
	complianceLevelsActions: TabModel[];
	selectedOperatingSystemAction: TabModel;
	selectedBenchmarkAction: TabModel;
	selectedCisComplianceLevelAction: TabModel;

	MessageBarType = MessageBarType;
	constructor(
		private tvmTextsService: TvmTextsService,
		public i18n: I18nService,
		private paris: Paris) {
		super();
	}
	ngOnDestroy(): void {
		this._subscription && this._subscription.unsubscribe();
	}

	ngOnInit(): void {
		this.setStepValidation(true);
		this.profileSettingsRepo = this.paris.getRepository(BaselineProfileSettings);
		this.settings$ = this.profileSettingsRepo.allItems$;
		this._subscription = this.settings$.subscribe(settings => {
			this.operatingSystemsActions = settings.map(setting => new TabModel({
					id: setting.operatingSystem + setting.operatingSystemVersion,
					name: `${setting.operatingSystem} ${setting.operatingSystemVersion}`,
					data: setting
				})
			);
			if(this.data.operatingSystem && this.data.operatingSystemVersion) {
				this.selectedOperatingSystemAction = this.operatingSystemsActions.find(action =>
					action.id === this.data.operatingSystem + this.data.operatingSystemVersion);
				this.setBenchmarksActions();
			}
		});
	}

	onOperatingSystemsDropdownChanged = newTab => {
		this.selectedOperatingSystemAction = newTab;
		this.selectedBenchmarkAction = null;
		this.selectedCisComplianceLevelAction = null;
		this.data.benchmark = null;
		this.data.benchmarkVersion = null;
		this.data.complianceLevel = null;
		this.data.selectedSettings = [];
		this.setBenchmarksActions();
		this.data.operatingSystem = this.selectedOperatingSystemAction.data.operatingSystem;
		this.data.operatingSystemVersion = this.selectedOperatingSystemAction.data.operatingSystemVersion;
	};

	onBenchmarkDropdownChanged = newTab => {
		this.data.selectedSettings = [];
		this.selectedBenchmarkAction = newTab;
		this.setCisComplianceLevelsActions();
		this.data.benchmark = this.selectedBenchmarkAction.data.benchmarkName;
		this.data.benchmarkVersion = this.selectedBenchmarkAction.data.benchmarkVersion;
	};

	onCisComplianceLevelDropdownChanged = newTab => {
		this.data.selectedSettings = [];
		this.selectedCisComplianceLevelAction = newTab;
		this.data.complianceLevel = this.selectedCisComplianceLevelAction.name;
	};

	setBenchmarksActions() {
		this.benchmarksActions = this.selectedOperatingSystemAction.data.benchmarks.map(benchmark => new TabModel({
			id: `${benchmark.benchmarkName} ${benchmark.benchmarkVersion}`,
			name: `${this.tvmTextsService.getBaselineBenchmarkLabel(benchmark.benchmarkName) || benchmark.benchmarkName} v${benchmark.benchmarkVersion}`,
			data: {
				benchmarkName: benchmark.benchmarkName,
				benchmarkVersion: benchmark.benchmarkVersion,
				complianceLevels: benchmark.complianceLevels
			}
		}))

		if(this.data.benchmark && this.data.benchmarkVersion) {
			this.selectedBenchmarkAction = this.benchmarksActions.find(action =>
				action.data.benchmarkName === this.data.benchmark && action.data.benchmarkVersion === this.data.benchmarkVersion);
			this.setCisComplianceLevelsActions();
		}
	}

	setCisComplianceLevelsActions() {
		const levels = this.selectedBenchmarkAction.data.complianceLevels;
		this.complianceLevelsActions = levels.map(level => new TabModel({
			id: levels.indexOf(level),
			name: level
		}))

		if(this.data.complianceLevel) {
			this.selectedCisComplianceLevelAction = this.complianceLevelsActions.find(action => action.name === this.data.complianceLevel)
		}
	}
}
