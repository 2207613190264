var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Paris } from '@microsoft/paris';
import { PanelContainer } from '@wcd/panels';
import { EventEmitter } from '@angular/core';
import { MachineGroup } from '@wcd/domain';
import { Router } from '@angular/router';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { MachineGroupsFieldsService } from '../../../tvm/services/tvm-machine-groups.fields.service';
import { remove } from 'lodash-es';
import { MessageBarType } from 'office-ui-fabric-react';
import { I18nService } from '@wcd/i18n';
var MachineGroupDataviewPanelComponent = /** @class */ (function (_super) {
    __extends(MachineGroupDataviewPanelComponent, _super);
    function MachineGroupDataviewPanelComponent(router, paris, globalEntityTypesService, machineGroupsFieldsService, i18nService) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.isBeforeOnInit = true;
        _this.preselectedMachineGroups = [];
        _this.manuallySelectedMachineGroups = [];
        _this.hideMachineGroups = [];
        _this.containsFilteredGroups = false;
        _this.onSaveGroups = new EventEmitter();
        _this._repository = paris.getRepository(MachineGroup);
        _this._entityType = globalEntityTypesService.getEntityType(MachineGroup);
        _this._dataViewConfig = {
            showModalOnExport: false,
            allowFilters: false,
            id: 'machine-groups-dataview',
            defaultSortFieldId: 'name'
        };
        _this._fields = machineGroupsFieldsService.fields;
        return _this;
    }
    MachineGroupDataviewPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        // TODO: (jomok) figure out a way to pre select existing rbac groups.
        //       This table (according to PMs) should only show selected RBACs. So when a user selects 6 groups in the tvm global filter,
        //		 there should only be 5 items in this table (with all of them selected). Probably a combination of ViewChild on the dataview, and onDataFetched
        // Note[omcheri]: currently "preselectedMachineGroups" will be removed from the list (instead of being selected and disabled)
        // Also, if user navigates in and out of this component, we need to remember the options they have selected previously
        // First convert array to number array and then select them as appropriate
        if (this.manuallySelectedMachineGroups && this.manuallySelectedMachineGroups.length > 0) {
            this.selectById = function (group) { return _this.manuallySelectedMachineGroups.includes(group.id); };
        }
        else if (this.isBeforeOnInit) {
            // We only want to select all when the panel first opens, and not on every subsequent search.
            this.selectById = function () { return true; };
            this.isBeforeOnInit = false;
        }
        this.manuallySelectedGroupsInfoText = this.i18nService.get('tvm.common.machineGroupFilter.dataview.numberOfMachineGroupsSelectedManually', {
            number: this.manuallySelectedMachineGroups ? this.manuallySelectedMachineGroups.length : 0
        });
    };
    MachineGroupDataviewPanelComponent.prototype.selectedMachineGroup = function (machineGroupEvent) {
        var _this = this;
        // this function will be called when a new search term is entered. Need to be a bit clever here.
        // If we get an empty array in machineGroupEvent.items, it is a new search term
        if (machineGroupEvent.items) {
            // If after searching and the currently selected items do not exist in the items that are currently being shown, don't remove them from the selected list!
            // First see what was selected, and see if it is in the currently visible items in the table
            // If an item was previously selected from before, then we do not want to overwrite the 'selectedMachineGroups'
            var distinctMachineGroups = this.selectedMachineGroups ? this.selectedMachineGroups.filter(function (group) { return !_this.currentlyVisibleMachineGroups.includes(group); }) : [];
            this.selectedMachineGroups = machineGroupEvent.items.concat(distinctMachineGroups);
        }
        this.manuallySelectedGroupsInfoText = this.i18nService.get('tvm.common.machineGroupFilter.dataview.numberOfMachineGroupsSelectedManually', {
            number: this.selectedMachineGroups ? this.selectedMachineGroups.length : 0
        });
    };
    MachineGroupDataviewPanelComponent.prototype.applyMachineGroups = function () {
        this.onSaveGroups.emit(this.selectedMachineGroups);
    };
    MachineGroupDataviewPanelComponent.prototype.onData = function (data) {
        var _this = this;
        if (this.preselectedMachineGroups && this.preselectedMachineGroups.length > 0) {
            // If in this condition, that means that user pre-selected some groups. Show how many groups are being filtered
            this.showSelectedGroupsMessage = true;
            this.selectedGroupsInfoText = this.getSelectedGroupsInfoText(this.preselectedMachineGroups.length, data.length);
            var preselectedMachineGroupsSet_1 = new Set(this.preselectedMachineGroups);
            remove(data, function (rbacGroup) { return !preselectedMachineGroupsSet_1.has(Number(rbacGroup.id)); });
        }
        // If the user has requested to hide some groups (ex. due to exceptions), filter them out here
        var hideMachineGroups = new Set(this.hideMachineGroups);
        remove(data, function (rbacGroup) { return hideMachineGroups.has(Number(rbacGroup.id)); });
        if (this.selectedMachineGroups) {
            this.selectById = function (group) { return _this.selectedMachineGroups.includes(group); };
        }
        else if (!this.isBeforeOnInit) {
            this.selectById = function () { return true; };
        }
        this.manuallySelectedGroupsInfoText = this.getNumberOfDevicesInfoText(this.selectedMachineGroups);
        this.currentlyVisibleMachineGroups = data;
    };
    MachineGroupDataviewPanelComponent.prototype.getNumberOfDevicesInfoText = function (machineGroups) {
        return this.i18nService.get('tvm.common.machineGroupFilter.dataview.numberOfMachineGroupsSelectedManually', {
            number: machineGroups ? machineGroups.length : 0
        });
    };
    MachineGroupDataviewPanelComponent.prototype.getSelectedGroupsInfoText = function (numOfGroupsFilteredByUser, numOfGroupsFilteredByUserTotal) {
        if (this.containsFilteredGroups && this.hideMachineGroups.length > 0) {
            this._messageBarType = MessageBarType.warning;
            return this.i18nService.get('tvm.common.machineGroupFilter.dataview.filterAndExceptionsDeviceGroupsInfoText', {
                numerator: numOfGroupsFilteredByUser,
                denominator: numOfGroupsFilteredByUserTotal,
            });
        }
        else if (this.containsFilteredGroups) {
            this._messageBarType = MessageBarType.info;
            return this.i18nService.get('tvm.common.machineGroupFilter.dataview.selectedGroupsInfoText', {
                numerator: numOfGroupsFilteredByUser,
                denominator: numOfGroupsFilteredByUserTotal,
            });
        }
        else {
            this._messageBarType = MessageBarType.warning;
            return this.i18nService.get('tvm.common.machineGroupFilter.dataview.exceptionsDeviceGroupsInfoText', {
                numerator: numOfGroupsFilteredByUser,
                denominator: numOfGroupsFilteredByUserTotal,
            });
        }
    };
    MachineGroupDataviewPanelComponent.prototype.closeMachineGroups = function () {
        this.destroy();
    };
    MachineGroupDataviewPanelComponent.prototype.shouldDisableSubmit = function () {
        // Need to check both since user may have some selected and de-selects them all. Can also force var to have default empty, both valid
        return this.selectedMachineGroups && this.selectedMachineGroups.length < 1;
    };
    return MachineGroupDataviewPanelComponent;
}(PanelContainer));
export { MachineGroupDataviewPanelComponent };
