var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BaselineProfileDetails } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { ItemActionModel, ItemActionType } from '../../../../../dataviews/models/item-action.model';
import { ProfileEntityPanelComponent } from '../components/profile.entity-panel.component';
import { ProfilesFieldsService } from './profiles.fields.service';
import { BaselineProfileEntityDetailsComponent } from '../components/baseline-profile.entity-details.component';
import { ProfileEntityComponent } from '../components/profile-entity/profile.entity.component';
import { EntityViewType } from '../../../../../global_entities/models/entity-view-type.enum';
import { EntityPageViewMode } from '../../../../../global_entities/models/entity-page-view-mode.enum';
import { BaselineProfileHeaderBottomComponent } from '../../../../../global_entities/components/entity-header-bottom/baseline-profile.header-bottom.component';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { ProfilesService } from './profiles.service';
import { Router } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { MdeUserRoleActionEnum } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
import { RbacService } from '../../../../../rbac/services/rbac.service';
import { map } from 'rxjs/operators';
export var ExternalProfileActions;
(function (ExternalProfileActions) {
    ExternalProfileActions["UPDATE_STATUS_ACTION"] = "updateStatus";
})(ExternalProfileActions || (ExternalProfileActions = {}));
var ProfileEntityTypeService = /** @class */ (function () {
    function ProfileEntityTypeService(profilesService, dialogsService, i18nService, router, authService, featuresService, rbacService) {
        var _this = this;
        this.profilesService = profilesService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.router = router;
        this.authService = authService;
        this.featuresService = featuresService;
        this.userExposedMachineGroupsIdsSet = new Set();
        this.entityType = {
            id: 'profile',
            entity: BaselineProfileDetails,
            icon: FabricIconNames.PageList,
            entityPageViewMode: EntityPageViewMode.Asset,
            entityContentsComponentType: ProfileEntityComponent,
            entityDetailsComponentType: BaselineProfileEntityDetailsComponent,
            singleEntityPanelComponentType: ProfileEntityPanelComponent,
            entityHeaderBottomComponentType: BaselineProfileHeaderBottomComponent,
            getEntityName: function (profile) { return profile.name; },
            entityPluralNameKey: 'profile_entityType_pluralName',
            entitySingularNameKey: 'profile_entityType_singularName',
            getEntityDataviewLink: function () { return '/baseline-compliance/profiles'; },
            getActions: function (profiles, options, entityViewType) {
                if (profiles.length !== 1) {
                    return [];
                }
                var IsUserAllowedManagingBaselineProfiles = function () {
                    if (!_this.featuresService.isEnabled(Feature.TvmBaselineAssessProfiles)) {
                        return true;
                    }
                    var isAdmin = _this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.admin);
                    var isUserAllowedToManageBaselineProfiles = _this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.tvmBaselineAssessProfiles);
                    return isAdmin || isUserAllowedToManageBaselineProfiles;
                };
                var IsUserAllowedToUpdatedProfile = function (baselineProfileDetails) {
                    var profileGroupIds = baselineProfileDetails.rbacGroupIds;
                    return profileGroupIds.every(function (gid) { return _this.userExposedMachineGroupsIdsSet.has(gid); });
                };
                var isDisabled = !(IsUserAllowedManagingBaselineProfiles() && IsUserAllowedToUpdatedProfile(profiles[0]));
                var tooltip;
                if (!IsUserAllowedManagingBaselineProfiles()) {
                    tooltip = _this.i18nService.strings.baselineProfiles_warning_NotAllowed;
                }
                else if (!IsUserAllowedToUpdatedProfile(profiles[0])) {
                    tooltip = _this.i18nService.strings.baselineProfiles_warning_NotAllowedRbacGroups;
                }
                var actions = [
                    entityViewType === EntityViewType.EntityPage ? null :
                        {
                            id: 'gotoProfile',
                            nameKey: 'tvm_baseline_profiles_sidepane_action_open',
                            icon: FabricIconNames.PageRight,
                            closeOnAction: function (methodResolution) { return methodResolution; },
                            method: function (profiles) {
                                return _this.profilesService.navigateToProfilePage(profiles[0]);
                            },
                        },
                    {
                        id: 'deleteProfile',
                        nameKey: 'tvm_baseline_profiles_sidepane_action_delete',
                        icon: FabricIconNames.Delete,
                        closeOnAction: function (methodResolution) { return methodResolution; },
                        disabled: isDisabled,
                        tooltip: tooltip,
                        method: function (profiles) {
                            var title = profiles[0].name;
                            return _this.dialogsService
                                .confirm({
                                title: _this.i18nService.get('deleteItem', { itemName: title }),
                                text: _this.i18nService.get('deleteConfirmSingular', {
                                    itemSingularName: _this.itemSingularName,
                                }),
                                confirmText: _this.i18nService.get('delete'),
                            })
                                .then(function (e) {
                                if (e.confirmed)
                                    return _this.profilesService
                                        .deleteProfile(profiles[0])
                                        .then(function (_) {
                                        if (entityViewType === EntityViewType.EntityPage)
                                            _this.router.navigate(['baseline-compliance/profiles']);
                                        return Promise.resolve(true);
                                    })
                                        .catch(function (error) {
                                        _this.dialogsService.showError({
                                            title: _this.i18nService.get('common_error_failedDeleteItem', {
                                                itemName: title,
                                            }),
                                            data: error,
                                        });
                                    });
                            });
                        },
                    },
                    {
                        id: ExternalProfileActions.UPDATE_STATUS_ACTION,
                        closeOnAction: true,
                        disabled: true,
                        method: function (profiles) {
                            var profileToUpdate = __assign({}, profiles[0], { status: !profiles[0].status });
                            return _this.profilesService.updateBaselineProfile(profileToUpdate);
                        },
                        type: ItemActionType.Hidden
                    },
                ];
                return actions.filter(Boolean).map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); });
            },
            loadFullEntityInPanel: false,
            dataViewOptions: {
                fields: ProfilesFieldsService,
            },
        };
        this.itemSingularName = this.i18nService.get('tvm_baseline_profile_entity_singular_name');
        this.rbacService = rbacService;
        this.rbacService.userExposedRbacGroups$.pipe(map(function (rbacGroups) { return rbacGroups.map(function (g) { return g.id; }); })).subscribe(function (rbacGroupIds) {
            _this.userExposedMachineGroupsIdsSet = new Set(rbacGroupIds);
            // TODO: Update once it's clear why there is a mismatch between the profile and user's rbac group ids regarding value 0
            _this.userExposedMachineGroupsIdsSet.add(0);
        });
    }
    return ProfileEntityTypeService;
}());
export { ProfileEntityTypeService };
