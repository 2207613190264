import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { IncidentGraphNode } from './incident-graph-node.value-object';
import { IncidentGraphRelation } from './incident-graph-relation.value-object';
import { IncidentGraphParsedRelation } from './incident-graph-parsed-relation';

@ValueObject({
	singularName: 'Incident graph',
	pluralName: 'Incident graph',
	readonly: true,
})
export class IncidentGraph extends ModelBase {
	@EntityField({
		arrayOf: IncidentGraphNode,
		data: 'Nodes',
	})
	nodes: Array<IncidentGraphNode>;

	@EntityField({
		arrayOf: IncidentGraphRelation,
		data: 'Relations',
	})
	private _relations: Array<IncidentGraphRelation>;

	private _state: {
		parsedRelations?: Array<IncidentGraphParsedRelation>;
	} = {};

	get relations(): Array<IncidentGraphParsedRelation> {
		if (!this._state.parsedRelations) this._state.parsedRelations = this.getParsedRelations();

		return this._state.parsedRelations;
	}

	private getParsedRelations(): Array<IncidentGraphParsedRelation> {
		const nodesIndex: {
			[nodeId: string]: IncidentGraphNode;
		} = this.nodes.reduce((nodesIndex, node) => Object.assign(nodesIndex, { [node.id]: node }), {});

		return this._relations.map(
			(relation: IncidentGraphRelation) =>
				new IncidentGraphParsedRelation({
					...relation,
					source: nodesIndex[relation.sourceNodeId],
					target: nodesIndex[relation.targetNodeId],
				})
		);
	}
}
