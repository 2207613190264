import { keyBy } from 'lodash-es';
import { OnboardingStatus } from './device-onboarding-status.enum';
var ɵ0 = OnboardingStatus.Onboarded, ɵ1 = OnboardingStatus.NotOnboarded, ɵ2 = OnboardingStatus.Unsupported, ɵ3 = OnboardingStatus.InsufficientInfo;
export var onboardingStatuses = [
    {
        id: ɵ0,
        i18nNameKey: 'devices_page_Onboarded',
        priority: 1,
    },
    {
        id: ɵ1,
        i18nNameKey: 'devices_page_NotOnboarded',
        priority: 2,
    },
    {
        id: ɵ2,
        i18nNameKey: 'devices_page_Unsupported',
        priority: 3,
    },
    {
        id: ɵ3,
        i18nNameKey: 'devices_page_InsufficientInfo',
        priority: 4,
    },
];
var ɵ4 = function (o) { return o.id; };
export var onboardingStatusesMap = keyBy(onboardingStatuses, ɵ4);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
