export class PositionModel {
	constructor(public top: number, public left: number) {}

	clone() {
		return new PositionModel(this.top, this.left);
	}

	setToElement(el: HTMLElement) {
		el.style.top = this.top + 'px';
		el.style.left = this.left + 'px';
	}
}
