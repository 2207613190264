var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, Injector, ChangeDetectorRef, } from '@angular/core';
import { SoftwareVersion, EolState, LegalNoteLinks, ShouldShowLegalNoteFor, SoftwareVersionInstalledAssetsRelationship, TvmEndPoint, OsDistribution, } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { PrettyNumberService } from '@wcd/prettify';
import { MessageBarStyles } from '../../../@entities/@tvm/common/styles';
import { MessageBarType } from 'office-ui-fabric-react';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TextToken, TvmTextsService } from '../../../tvm/services/tvm-texts.service';
import { TzDateService } from '@wcd/localization';
import { Paris } from '@microsoft/paris';
import { MaxItemsInCollapseExpandTable } from '../../../tvm/tvm.constants';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
var SoftwareVersionEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(SoftwareVersionEntityDetailsComponent, _super);
    function SoftwareVersionEntityDetailsComponent(paris, changeDetectorRef, prettyNumberService, sidePanelService, i18nService, tvmTextsService, tzDateService, tvmDownloadService, featuresService, injector) {
        var _this = _super.call(this, injector) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.prettyNumberService = prettyNumberService;
        _this.sidePanelService = sidePanelService;
        _this.i18nService = i18nService;
        _this.tvmTextsService = tvmTextsService;
        _this.tzDateService = tzDateService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.messageBarState = {
            showBody: false,
        };
        _this._messageBarStyles = MessageBarStyles;
        _this.MessageBarType = MessageBarType;
        _this.MaxItemsInCollapseExpandTable = MaxItemsInCollapseExpandTable;
        _this.installationRelationshipRepository = paris.getRelationshipRepository(SoftwareVersionInstalledAssetsRelationship);
        _this.isExposedDeviceFeatureEnabled = featuresService.isEnabled(Feature.TvmCommonExposedDevices);
        _this.isExposedOperatingSystem = featuresService.isEnabled(Feature.TvmExposedOperatingSystems);
        return _this;
    }
    SoftwareVersionEntityDetailsComponent.prototype.ngOnInit = function () {
        this.isEolVersion = this.softwareVersion.eolVersionState === EolState.AlreadyEOL;
        this.shouldShowOsDistribution =
            this.softwareVersion.osDistribution && this.softwareVersion.osDistribution != OsDistribution.None;
        this.installations = this.getPrettyNumberOfInstallations();
        this.showEolMessage = this.shouldShowEolMessage();
        this.showEolDate = this.showEolDateDecider();
        this.eolText = this.getEolText();
        this.messageBarType = this.isEolVersion ? MessageBarType.warning : MessageBarType.info;
        this.legalNote = this.tvmTextsService.getText(TextToken.HasEolVersionsMicrosoftLegalNote, this.softwareVersion.productId);
        this.showLegalNoteLink = Object.keys(LegalNoteLinks).includes(this.softwareVersion.productId);
        this.legalNoteLink = LegalNoteLinks[this.softwareVersion.productId];
        this.legalNoteLinkText = this.tvmTextsService.getText(TextToken.LegalNoteLinkText, this.softwareVersion.productId);
        this.shouldShowLegalNote = ShouldShowLegalNoteFor.includes(this.softwareVersion.productId);
        this.widgetEosTooltip = this.i18nService.strings.tvm_softwarePage_versionDistribution_eosTooltip;
        this.installedAssetsTitle = this.i18nService.strings.tvm_common_installedDevices;
        this.installedAssetsLoadingText = this.i18nService.strings.tvm_softwareVersion_loadingInstalledDevices;
        if (this.softwareVersion != undefined && this.softwareVersion != null) {
            this.loadNewInstallations(this.softwareVersion);
        }
        this._softwareVersion = this.softwareVersion;
    };
    SoftwareVersionEntityDetailsComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    SoftwareVersionEntityDetailsComponent.prototype.getPrettyNumberOfInstallations = function () {
        return this.prettyNumberService.prettyNumber(this.softwareVersion.installations);
    };
    SoftwareVersionEntityDetailsComponent.prototype.showEolDateDecider = function () {
        return (this.softwareVersion.eolVersionSinceDate &&
            (this.isEolVersion || this.softwareVersion.eolVersionState === EolState.UpcomingEOL));
    };
    SoftwareVersionEntityDetailsComponent.prototype.getStyles = function (messageBarProps) {
        if (this.messageBarState.showBody !== messageBarProps.expandSingleLine) {
            this.messageBarState.showBody = messageBarProps.expandSingleLine;
            this.changeDetectorRef.detectChanges();
        }
        return this._messageBarStyles;
    };
    SoftwareVersionEntityDetailsComponent.prototype.shouldShowEolMessage = function () {
        return this.softwareVersion.eolVersionState === EolState.UpcomingEOL || this.isEolVersion;
    };
    SoftwareVersionEntityDetailsComponent.prototype.getOsDistributionString = function () {
        return this.softwareVersion.osDistribution
            ? this.i18nService.get("tvm_common_osDistribution_" + this.softwareVersion.osDistribution)
            : this.i18nService.strings.notAvailable_short;
    };
    SoftwareVersionEntityDetailsComponent.prototype.getEolText = function () {
        if (this.isEolVersion) {
            if (this.softwareVersion.eolVersionSinceDate) {
                return this.i18nService.get('tvm.softwarePage.versionDistribution.eolText', {
                    date: this.tzDateService.format(this.softwareVersion.eolVersionSinceDate, 'shortDate'),
                    product: this.softwareVersion.name,
                });
            }
            else {
                return this.i18nService.get('tvm.softwarePage.versionDistribution.eolTextWithoutDate');
            }
        }
        if (this.softwareVersion.eolVersionState === EolState.UpcomingEOL) {
            return this.i18nService.get('tvm.softwarePage.versionDistribution.upcomingEolText', {
                date: this.tzDateService.format(this.softwareVersion.eolVersionSinceDate, 'shortDate'),
            });
        }
    };
    SoftwareVersionEntityDetailsComponent.prototype.loadNewInstallations = function (softwareVersion) {
        var queryOptions = {
            page: 1,
            pageSize: MaxItemsInCollapseExpandTable,
        };
        this.installationRelationshipRepository.sourceItem = softwareVersion;
        this.installations$ = this.installationRelationshipRepository.query(queryOptions);
    };
    SoftwareVersionEntityDetailsComponent.prototype.openAllInstalledMachines = function () {
        this.sidePanelService.showAllInstalledAssetsPanel(this._softwareVersion);
    };
    SoftwareVersionEntityDetailsComponent.prototype.exportInstalledMachines = function () {
        return this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.installationRelationshipRepository, TvmEndPoint.Analytics, this.tvmTextsService.getText(TextToken.SoftwareVersionsVendorNameAndVersion, this.softwareVersion) + " - " + this.i18nService.strings.tvm_common_installedDevices, undefined);
    };
    return SoftwareVersionEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { SoftwareVersionEntityDetailsComponent };
