/* tslint:disable:template-accessibility-label-for */
import { EventEmitter, OnInit } from '@angular/core';
import { AirsNotificationRulesStore } from '../services/airs-notification-rules.store';
import { AirsNotificationRuleConditionGroupsStore } from '../services/airs-notification-rule-condition-groups.store';
import { AirsNotificationRuleModel } from '../models/airs-notification-rule.model';
import { AirsReportTypes } from '../services/airs-report-types.service';
import { scheduleConfig } from '../services/airs-notification-rule-schedule-config.service';
import { AirsNotificationRulesReportsStore } from '../services/airs-notification-rules-reports.store';
import { NgForm } from '@angular/forms';
var AirsNotificationRuleEditComponent = /** @class */ (function () {
    function AirsNotificationRuleEditComponent(notificationRulesStore, notificationRulesReportsStore, notificationRuleConditionGroupsStore) {
        this.notificationRulesStore = notificationRulesStore;
        this.notificationRulesReportsStore = notificationRulesReportsStore;
        this.notificationRuleConditionGroupsStore = notificationRuleConditionGroupsStore;
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
        this.test = true;
        this.scheduleConfiguration = scheduleConfig;
    }
    Object.defineProperty(AirsNotificationRuleEditComponent.prototype, "unusedConditionGroups", {
        get: function () {
            var _this = this;
            if (!this.conditionGroups)
                return [];
            return this.conditionGroups.filter(function (conditionGroup) { return !_this.notificationRule.containsGroup(conditionGroup); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsNotificationRuleEditComponent.prototype, "isDirty", {
        get: function () {
            return this.notificationRuleForm.form.dirty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsNotificationRuleEditComponent.prototype, "confirmDeactivateText", {
        get: function () {
            return "Are you sure you wish to discard " + (this.notificationRule.isNew ? '' : 'changes to ') + "this Notification Rule?";
        },
        enumerable: true,
        configurable: true
    });
    AirsNotificationRuleEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.notificationRuleConditionGroupsStore.items$.subscribe(function (conditionGroups) {
            _this.conditionGroups = conditionGroups;
        });
    };
    AirsNotificationRuleEditComponent.prototype.getStoreForRule = function (notificationRule) {
        return notificationRule.type.requireSchedule
            ? this.notificationRulesReportsStore
            : this.notificationRulesStore;
    };
    AirsNotificationRuleEditComponent.prototype.onConditionGroupChange = function (condition, conditionGroup) {
        condition.group = conditionGroup;
        condition.clearValues();
    };
    AirsNotificationRuleEditComponent.prototype.getUnusedConditionGroups = function (condition) {
        var _this = this;
        return this.conditionGroups.filter(function (conditionGroup) {
            return !_this.notificationRule.containsGroup(conditionGroup) ||
                (condition.group && conditionGroup.id === condition.group.id);
        });
    };
    AirsNotificationRuleEditComponent.prototype.removeCondition = function (condition) {
        this.notificationRule.removeCondition(condition);
        this.notificationRuleForm.form.markAsDirty();
    };
    AirsNotificationRuleEditComponent.prototype.getConditionValuesText = function (condition) {
        if (condition.values.length > 3)
            return condition.values.length + " values";
        var values = condition.group.getValuesByIds(condition.values);
        if (values.length)
            return values.map(function (value) { return value.name; }).join(', ');
        return '(Please select values)';
    };
    AirsNotificationRuleEditComponent.prototype.getScheduleReportTypeValuesText = function () {
        if (!this.notificationRule.reportSchedule.reportTypes.length)
            return '(Please select reports)';
        if (this.notificationRule.reportSchedule.reportTypes.length === AirsReportTypes.ALL.length)
            return 'All';
        return this.notificationRule.reportSchedule.reportTypes
            .map(function (reportTypeId) { return AirsReportTypes.getReportTypeById(reportTypeId).name; })
            .join(', ');
    };
    AirsNotificationRuleEditComponent.prototype.getScheduleReportDaysValuesText = function () {
        if (!this.notificationRule.reportSchedule.repeatDays.length)
            return '(Please select days)';
        return this.notificationRule.reportSchedule.repeatDays
            .map(function (day, index) { return scheduleConfig.repeatOn[day].abbr; })
            .join(', ');
    };
    AirsNotificationRuleEditComponent.prototype.filterConditionGroups = function (group) {
        return !this.notificationRule.containsGroup(group);
    };
    AirsNotificationRuleEditComponent.prototype.allowAddCondition = function () {
        var _this = this;
        return this.conditionGroups.some(function (conditionGroup) { return !_this.notificationRule.containsGroup(conditionGroup); });
    };
    AirsNotificationRuleEditComponent.prototype.saveRule = function () {
        var _this = this;
        this.getStoreForRule(this.notificationRule)
            .save(this.notificationRule)
            .then(function () {
            _this.notificationRuleForm.form.markAsPristine();
            _this.save.emit(_this.notificationRule);
        });
    };
    return AirsNotificationRuleEditComponent;
}());
export { AirsNotificationRuleEditComponent };
