
		<dl [class.key-values]="asKeyValueList" role="none">
			<ng-container *ngIf="user.loggedOnMachinesCount">
				<dt>
					{{
						'users.entityDetails.sections.networkActivity.fields.loggedOnMachinesCount.title'
							| i18n
					}}
				</dt>
				<dd>{{ user.loggedOnMachinesCount }}</dd>
			</ng-container>

			<dt>{{ 'users.entityDetails.sections.networkActivity.fields.firstSeen.title' | i18n }}</dt>
			<dd>{{ user.firstSeen | date: 'medium' }}</dd>

			<dt>{{ 'users.entityDetails.sections.networkActivity.fields.lastSeen.title' | i18n }}</dt>
			<dd>{{ user.lastSeen | date: 'medium' }}</dd>

			<ng-template #machineDefinition let-machine="machine" let-titleKey="titleKey">
				<ng-container *ngIf="machine">
					<dt>{{ titleKey | i18n }}</dt>
					<dd class="wcd-flex-horizontal">
						<wcd-shared-icon
							[iconName]="
								(machineEntityTypeService.getIcon &&
									machineEntityTypeService.getIcon([machine])) ||
								machineEntityTypeService.icon
							"
						>
						</wcd-shared-icon>
						&nbsp;
						<entity-link
							class="wcd-break-words"
							[entity]="machine"
							[entityType]="machineType"
							[linkText]="machine.name"
						></entity-link>
					</dd>
				</ng-container>
			</ng-template>

			<ng-container *ngIf="(role$ | async) as role">
				<dt>{{ 'users.entityDetails.sections.details.role.title' | i18n }}</dt>
				<dd>{{ role }}</dd>
			</ng-container>

			<ng-container *ngIf="(logonType$ | async) as logonType">
				<dt>{{ 'users.entityDetails.sections.details.logonTypes.title' | i18n }}</dt>
				<dd>{{ logonType }}</dd></ng-container
			>
		</dl>
	