var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { AuthService, MtpPermission } from '@wcd/auth';
import { Paris } from '@microsoft/paris';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var SendTestStatuses;
(function (SendTestStatuses) {
    SendTestStatuses[SendTestStatuses["notStarted"] = 0] = "notStarted";
    SendTestStatuses[SendTestStatuses["pending"] = 1] = "pending";
    SendTestStatuses[SendTestStatuses["success"] = 2] = "success";
    SendTestStatuses[SendTestStatuses["error"] = 3] = "error";
})(SendTestStatuses || (SendTestStatuses = {}));
var EmailNotificationRecipientsStepComponent = /** @class */ (function (_super) {
    __extends(EmailNotificationRecipientsStepComponent, _super);
    function EmailNotificationRecipientsStepComponent(i18n, authService, paris, changeDetectorRef, liveAnnouncer) {
        var _this = _super.call(this) || this;
        _this.i18n = i18n;
        _this.authService = authService;
        _this.paris = paris;
        _this.changeDetectorRef = changeDetectorRef;
        _this.liveAnnouncer = liveAnnouncer;
        _this.sendTestStatus = SendTestStatuses.notStarted;
        /*
            Configurations for test mail.
            If testMailEnabled == true, the form data is translated to the relevant entity using getTestMailData,
            and the translated data is sent as ApiCall, whose type is determined by apiCallTypeForTestMail
        */
        _this.testMailEnabled = false;
        _this.isUserAllowedActions = _this.authService.currentUser.hasRequiredMtpPermission(MtpPermission.SecurityConfig_Manage);
        return _this;
    }
    Object.defineProperty(EmailNotificationRecipientsStepComponent.prototype, "SendStatuses", {
        get: function () {
            return SendTestStatuses;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmailNotificationRecipientsStepComponent.prototype, "isValid", {
        get: function () {
            return this.data.recipients.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    EmailNotificationRecipientsStepComponent.prototype.ngOnInit = function () {
        this.checkValidation();
    };
    EmailNotificationRecipientsStepComponent.prototype.addEmail = function (email) {
        this.updateEmail(email);
        this.emailEl.nativeElement.focus();
    };
    EmailNotificationRecipientsStepComponent.prototype.updateEmail = function (email) {
        var emailExists = ~this.data.recipients.indexOf(email);
        if (!emailExists) {
            this.data.recipients.splice(0, 0, email);
            this.announceEmailListUpdated();
        }
        this.currentEmail = '';
        this.checkValidation();
        this.clearSendTestStatus();
    };
    EmailNotificationRecipientsStepComponent.prototype.removeEmail = function (email) {
        var emailIndex = this.data.recipients.indexOf(email);
        if (~emailIndex) {
            this.data.recipients.splice(emailIndex, 1);
            this.announceEmailListUpdated();
        }
        this.checkValidation();
        this.clearSendTestStatus();
    };
    EmailNotificationRecipientsStepComponent.prototype.sendTestEmail = function () {
        var _this = this;
        this.sendTestStatus = SendTestStatuses.pending;
        var dataToSend = this.getTestMailData(this.data);
        this.paris.apiCall(this.apiCallTypeForTestMail, dataToSend).subscribe(function () {
            _this.sendTestStatus = SendTestStatuses.success;
            _this.changeDetectorRef.detectChanges();
        }, function (error) {
            _this.sendTestStatus = SendTestStatuses.error;
            _this.changeDetectorRef.detectChanges();
        });
        this.changeDetectorRef.detectChanges();
    };
    EmailNotificationRecipientsStepComponent.prototype.clearSendTestStatus = function () {
        this.sendTestStatus = SendTestStatuses.notStarted;
        this.changeDetectorRef.detectChanges();
    };
    EmailNotificationRecipientsStepComponent.prototype.checkValidation = function () {
        this.setStepValidation(this.data.recipients.length > 0);
    };
    EmailNotificationRecipientsStepComponent.prototype.announceEmailListUpdated = function () {
        this.liveAnnouncer.announce(this.i18n.get('notificationRules.ruleEmails.updated'), 'assertive', 300);
    };
    return EmailNotificationRecipientsStepComponent;
}(WizardBaseStep));
export { EmailNotificationRecipientsStepComponent };
