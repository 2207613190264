<wcd-panel
			(close)="destroy()"
			[settings]="settings"
			class="wcd-full-height wcd-flex-vertical wcd-flex-1"
		>
		<div class="wcd-full-height wcd-flex-vertical wcd-padding-vertical">
			<h2 #panelHeader class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal">
				<b>
					{{ 'tvm.common.machineGroupFilter.label' | i18n }}
				</b>
			</h2>
			<span *ngIf="dataviewTitle" class="wcd-flex-none wcd-padding-small-vertical wcd-padding-large-horizontal wcd-flex-horizontal">
				{{ dataviewTitle }}
			</span>
			<div *ngIf="showSelectedGroupsMessage" class="wcd-padding-large-horizontal tvm-list__block_item">
				<fab-message-bar [messageBarType]="_messageBarType.info" [styles]="messageBarStyles">
					<div class="spaced">{{
						selectedGroupsInfoText
					}}</div>
				</fab-message-bar>
			</div>
			<div class="wcd-padding-large-horizontal tvm-list__block_item text-right tvm-list__header_semi-bold">
				{{manuallySelectedGroupsInfoText}}
			</div>
			<dataview
				class="wcd-flex-1"
				id="machine-groups-dataview"
				[repository]="_repository"
				[entityType]="_entityType"
				[navigateOnChange]="false"
				[allowPaging]="false"
				[allowFilters]="false"
				[searchEnabled]="true"
				[ignoreQueryParams]="true"
				[defaultSortFieldId]="'name'"
				[allowColumnCustomization]="false"
				[fields]="_fields"
				[label]="'tvm.common.machineGroupFilter.dataview.machineGroups' | i18n"
				[fixedTable]="true"
				[selectAllEnabled]="true"
				[selectAllItemsByDefault]="false"
				[shouldShowEntityPanel]="false"
				[isItemSelected]="this.selectById"
				(select)="selectedMachineGroup($event)"
				(onData)="onData($event.data.items)"
			>
			</dataview>
			<footer class="wcd-flex-none wcd-flex-horizontal wcd-border-top wcd-padding-large-horizontal">
				<div class="wcd-flex-1 wcd-padding-small-top">
					<button [disabled]="shouldDisableSubmit()" class="btn btn-primary" (click)="applyMachineGroups()">
							{{ 'tvm.common.machineGroupFilter.apply' | i18n }}
					</button>
					<button class="btn" (click)="closeMachineGroups()">
							{{ 'tvm.common.machineGroupFilter.cancel' | i18n }}
					</button>
				</div>
			</footer>
		</div>
</wcd-panel>
