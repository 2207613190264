import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { ChangeEvent, ChangeEventCategory } from '@wcd/domain';
import { ChangeEventService } from './../services/change-event.service';
import { Router } from '@angular/router';

enum WindowsRelatedApps {
	Windows10 = 'microsoft-_-windows_10',
	InternetExplorer = 'microsoft-_-internet_explorer',
	Edge = 'microsoft-_-edge',
}

@Component({
	selector: 'change-event-entity-panel',
	styleUrls: ['./change-event.entity-panel.component.scss'],
	templateUrl: './change-event.entity-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeEventEntityPanelComponent extends EntityPanelComponentBase<ChangeEvent> {
	get changeEvent(): ChangeEvent {
		return this.entity;
	}

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		private router: Router,
		private changeEventService: ChangeEventService
	) {
		super(changeDetectorRef);
	}

	initEntity(changeEvent: ChangeEvent, isExtendedData: boolean = false) {
		super.initEntity(changeEvent, isExtendedData);
		this.changeDetectorRef.markForCheck();
	}

	navigateToRecommendation() {
		let prefix: string;
		let recommendationId: string;
		const _relatedProductId = this.changeEventService.getRelatedProductId(this.entity);

		if(this.entity.category === ChangeEventCategory.Vulnerability) {
			// handle Windows built-in apps
			prefix = 'va-_-';
			recommendationId =
				_relatedProductId === WindowsRelatedApps.InternetExplorer ||
				_relatedProductId === WindowsRelatedApps.Edge
					? WindowsRelatedApps.Windows10
					: _relatedProductId;
		} else {
			prefix = 'sca-_-';
			recommendationId = this.entity.scid;
		}

		this.router.navigate(['/security-recommendations', `${prefix}${recommendationId}`], {
			queryParams: {
				search: recommendationId,
			},
		});
	}
}