import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { BaselineProfileConfiguration, BaselineProfileDevice } from '@wcd/domain';
import { FieldsService } from '../../../../../../global_entities/models/entity-type.interface';
import { I18nService } from '@wcd/i18n';
import { TzDateService } from '@wcd/localization';
import { map } from 'rxjs/operators';
import { FabricIconNames } from '@wcd/scc-common';
import { Feature, FeaturesService } from '@wcd/config';
import { BaselineCompliantBarComponent } from '../../../../../../tvm/components/baseline-compliant-bar/baseline-compliant-bar.component';
import { RbacService } from '../../../../../../rbac/services/rbac.service';
import { FilterValuesRangeSliderComponent } from '@wcd/ng-filters';

@Injectable()
export class ProfileDeviceFieldsService implements FieldsService<BaselineProfileDevice> {
	isDevicesAssessmentEnabled: boolean;
	rbacGroupIdToNameMap: { [p: number]: string } = {};
	private _fields: Array<DataviewField<BaselineProfileDevice>>;
	private _configurationsFields: Array<DataviewField<BaselineProfileConfiguration>>;

	constructor(private tzDateService: TzDateService, private i18nService: I18nService, private featuresService: FeaturesService, rbacService: RbacService) {
		this.isDevicesAssessmentEnabled = this.featuresService.isEnabled(Feature.TvmBaselineDevicesAssessment);
		rbacService.userExposedRbacGroups$.pipe(
			map(rbacGroups => {
				const machineGroupNameById: { [id: number]: string } = rbacGroups.reduce(
					(map, currMachineGroup) => {
						map[currMachineGroup.id] = currMachineGroup.name;
						return map;
					},
					{}
				);
				return machineGroupNameById;
			})
		).subscribe((machineGroupNameById) => {
			this.rbacGroupIdToNameMap = machineGroupNameById;
		});
	}

	get fields(): Array<DataviewField<BaselineProfileDevice>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<BaselineProfileDevice>([
				{
					id: 'name',
					name: this.i18nService.strings.tvm_common_name,
					getTooltip: (device: BaselineProfileDevice) => device.name,
					icon: {
						fabricIcon: FabricIconNames.System,
					},
					maxWidth: 500,
				},
				{
					id: 'domain',
					name: this.i18nService.strings.tvm_baseline_profiles_devices_domain_title,
					getTooltip: (device: BaselineProfileDevice) => device.domain,
					maxWidth: 300,
				},
				{
					id: 'compliantConfigurations',
					name: this.i18nService.strings.tvm_baseline_profiles_devices_deviceCompliance_title,
					component: {
						type: BaselineCompliantBarComponent,
						getProps: (baselineProfileDevice: BaselineProfileDevice) => ({
							showLegend: false,
							compliantCount: baselineProfileDevice.compliantConfigurations,
							notCompliantCount:
								baselineProfileDevice.applicableConfigurations -
								baselineProfileDevice.compliantConfigurations,
							total: baselineProfileDevice.applicableConfigurations,
							showPercentage: true,
							showNumbers: true,
						}),
					},
					maxWidth: 300,
					allowResize: false,
					filter: {
						component: {
							type: FilterValuesRangeSliderComponent,
							config: {
								min: 0,
								max: 100,
								valueFormat: (value: number) => `${value}%`,
							},
						},
					},
				},
				{
					id: 'lastSeen',
					name: this.i18nService.strings.tvm_baseline_profiles_devices_lastSeen_title,
					getDisplay: (device: BaselineProfileDevice) =>
						this.tzDateService.format(device.lastSeen, 'short'),
					sort: { enabled: false },
				},
				...this.isDevicesAssessmentEnabled ? [{
					id: 'deviceGroup',
					name: this.i18nService.strings.tvm_baseline_profiles_devices_deviceGroup_title,
					getDisplay: (device: BaselineProfileDevice) => this.rbacGroupIdToNameMap[device.rbacGroupId],
					sort: { enabled: false },
				}] : [],
			]);
		}

		return this._fields;
	}

	get configurationsFields(): Array<DataviewField<BaselineProfileConfiguration>> {
		if (!this._configurationsFields) {
			this._configurationsFields = DataviewField.fromList<BaselineProfileConfiguration>([
				{
					id: 'configurationId',
					name: this.i18nService.strings.tvm_baseline_profiles_configurations_ID_title,
					getDisplay: (configuration: BaselineProfileConfiguration) => configuration.id,
					sort: { enabled: false },
				},
				{
					id: 'name',
					name: this.i18nService.strings.tvm_common_name,
					getTooltip: (configuration: BaselineProfileConfiguration) => configuration.name,
					sort: { enabled: false },
					className: 'nowrap wcd-text-overflow-medium',
				},
				{
					id: 'configurationCompliant',
					name: this.i18nService.strings.tvm_baseline_configuration_compliant_column_name,
					getDisplay: (configuration: BaselineProfileConfiguration) =>
						configuration.compliantDevices === 1
							? this.i18nService.strings.common_yes
							: this.i18nService.strings.common_no,
				},
			]);
		}

		return this._configurationsFields;
	}
}
