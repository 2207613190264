import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';

@Entity({
	...airsEntityConfig,
	singularName: 'Appended File',
	pluralName: 'Appended Files',
	modelWith: null,
})
export class AirsFileBase extends AirsEntity {
	@EntityField({ data: 'mime_type' })
	mimeType: string;

	@EntityField()
	size: number;

	@EntityField()
	md5: string;

	@EntityField()
	sha1: string;

	@EntityField()
	sha256: string;

	@EntityField({ data: 'digital_signature' })
	digitalSignature: {
		publisher: string;
		valid_from: string;
		valid_to: string;
	};

	@EntityField({ data: 'is_compressed' })
	isCompressed: boolean;

	@EntityField({ data: 'product_vendor' })
	productVendor: string;
}
