var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* tslint:disable:template-click-events-have-key-events */
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Feature, FeaturesService } from '@wcd/config';
import { NEVER, of, Subscription, EMPTY, throwError } from 'rxjs';
import { routeToLegacyAlertRoute } from '../services/alerts.service';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { I18nService } from '@wcd/i18n';
import { Alert, AntivirusBlockDetails, AntivirusRemediationAction, EntityWithContext, File, FileActionDetails, FileInstance, FileVirusTotalFileReportRelationship, LegacyUser, LegacyUserLegacyUserProfileRelationship, Machine, Process, ProcessTree, Script, TreeElementAlertContext, TreeElementCyberEntityTypeEnum, Url, AUTOMATION_DETECTION_SOURCE_TYPE, GenericFieldType, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { AlertInsightErrorType, AlertInsightPerformanceType, AlertPageService, detailsTabSupportedEntityTypes, } from '../services/alert-page.service';
import { SpinnerSize } from 'office-ui-fabric-react';
import { VirusTotalFileReportService } from '../../files/services/virus-total-file-report.service';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { MachinesService } from '../../machines/services/machines.service';
import { OfficeIntegrationService } from '../../../admin/services/office-integration.service';
import { v4 as uuid4 } from 'uuid';
import { GetAlertsByIdsApiCall } from '@wcd/domain';
export var ProcessTreeErrorType = {
    unavailableAlertStory: 'unavailableAlertStory',
    unSupportedAlertStory: 'unSupportedAlertStory',
    unSupportedJson: 'unSupportedJson',
};
var AlertPageComponent = /** @class */ (function () {
    function AlertPageComponent(featuresService, router, route, i18nService, paris, alertPageService, machinesService, virusTotalFileReportService, officeIntegrationService) {
        var _this = this;
        this.featuresService = featuresService;
        this.router = router;
        this.route = route;
        this.i18nService = i18nService;
        this.paris = paris;
        this.alertPageService = alertPageService;
        this.machinesService = machinesService;
        this.virusTotalFileReportService = virusTotalFileReportService;
        this.officeIntegrationService = officeIntegrationService;
        this.subscriptions = new Subscription();
        this.tabs = [];
        this.alertsIds = new Set();
        this.alertsEntities = new Map();
        this.processTreeLoading = true;
        this.processTreeError = false;
        this.processTreeJsonError = false;
        this.allCollapsed = false;
        this.correlationId = uuid4();
        this.SpinnerSize = SpinnerSize;
        this.genericFieldType = GenericFieldType;
        this.ProcessTreeErrorType = ProcessTreeErrorType;
        this.enableImportAlert = false;
        this.rootsToRender = 0;
        this.enableImportAlert = this.featuresService.isEnabled(Feature.ImportAlertProcessTree);
        this.subscriptions.add(this.featuresService.featureChanged$.subscribe(function (_a) {
            var featureId = _a.featureId, isEnabled = _a.value;
            if (featureId === Feature.UpgradeAlertPage && !isEnabled) {
                var urlTree = routeToLegacyAlertRoute(_this.route.snapshot);
                _this.router.navigate(urlTree, { replaceUrl: true });
            }
        }));
        this.setOfficeTenantPrefix();
        this.subscriptions.add(router.events.subscribe(function (val) {
            if (val instanceof NavigationEnd) {
                _this.init();
            }
        }));
    }
    AlertPageComponent.prototype.ngOnInit = function () {
        this.startLoadTime = Date.now();
        this.tabs = this.getTabs();
        if (this.enableImportAlert) {
            this.subscribeProcessTreeJson();
        }
        this.init();
    };
    AlertPageComponent.prototype.init = function () {
        var _this = this;
        performance.mark(AlertInsightPerformanceType.InitiatingAlert);
        // Use the EntityResolver of the parent component to get Alert with the url alert id.
        this.subscriptions.add(this.route.data.subscribe(function (_a) {
            var entity = _a.entity;
            _this.setAlert(entity);
            _this.alertPageService.trackPerformance(_this.alert.id, _this.correlationId, AlertInsightPerformanceType.InitiatingAlert, true);
            _this.setMachine();
            _this.setUser(_this.alert.user);
            _this.setProcessTree();
        }));
    };
    AlertPageComponent.prototype.setOfficeTenantPrefix = function () {
        var _this = this;
        performance.mark(AlertInsightPerformanceType.InitiatingOfficeTenantPrefix);
        this.subscriptions.add(this.officeIntegrationService.isIntegrationEnabled$
            .pipe(switchMap(function (isEnabled) {
            return isEnabled ? _this.officeIntegrationService.getOfficeTenantUrlPrefix() : of('');
        }), catchError(function (error) {
            _this.alertPageService.trackErrorInsight(_this.route.snapshot.params.id, _this.correlationId, AlertInsightErrorType.OfficeIntegrationError);
            _this.alertPageService.trackPerformance(_this.alert.id, _this.correlationId, AlertInsightPerformanceType.InitiatingOfficeTenantPrefix, false);
            return EMPTY;
        }))
            .subscribe(function (officeTenantPrefix) {
            _this.officeTenantPrefix = officeTenantPrefix ? officeTenantPrefix : '';
            _this.alertPageService.trackPerformance(_this.route.snapshot.params.id, _this.correlationId, AlertInsightPerformanceType.InitiatingOfficeTenantPrefix, true, {
                officeTenantPrefix: _this.officeTenantPrefix ? 1 : 0,
            });
        }));
    };
    AlertPageComponent.prototype.subscribeProcessTreeJson = function () {
        var _this = this;
        this.subscriptions.add(this.alertPageService.processTreeJson$
            .pipe(filter(function (processTreeJsonString) { return processTreeJsonString !== ''; }), tap(function () { return _this.alertPageService.updateTrackingStatus(false); }), switchMap(function (processTreeJsonString) {
            var processTreeJson;
            try {
                processTreeJson = JSON.parse(processTreeJsonString);
            }
            catch (error) {
                _this.onProcessTreeJsonError();
            }
            return processTreeJson ? of(processTreeJson) : NEVER;
        }), switchMap(function (processTreeJson) {
            return _this.paris.createItem(ProcessTree, processTreeJson).pipe(catchError(function (error) {
                _this.onProcessTreeJsonError();
                return NEVER;
            }));
        }), tap(function (processTree) {
            _this.processTree = processTree;
        }), switchMap(function (processTree) {
            return _this.paris.getItemById(Alert, _this.processTree.mainAlertId).pipe(catchError(function (error) {
                _this.onProcessTreeJsonError();
                return NEVER;
            }));
        }))
            .subscribe(function (alert) {
            _this.onAlertChangeThroughJSON(alert);
            _this.defaultAlertContext = null;
            _this.processTreeError = alert == null;
            _this.processTreeJsonError = alert == null;
        }));
    };
    AlertPageComponent.prototype.onProcessTreeJsonError = function () {
        this.processTreeError = true;
        this.processTreeErrorType = ProcessTreeErrorType.unSupportedJson;
    };
    AlertPageComponent.prototype.onAlertChangeThroughJSON = function (alert) {
        this.setAlert(alert);
        this.setMachine();
        this.setUser(this.alert.user);
        this.setTreeElements();
    };
    AlertPageComponent.prototype.setAlert = function (alert) {
        this.alert = alert;
        this.alertPageService.updateMainAlertPageId(this.alert.id);
        // By default the first entity to be represent in the details tab is the primary alert entity.
        this.alertPageService.updateDetailsTab(TreeElementCyberEntityTypeEnum.alert, this.alert, this.alert.id);
    };
    AlertPageComponent.prototype.setProcessTree = function () {
        var _this = this;
        performance.mark(AlertInsightPerformanceType.ProcessTree);
        var getStory = this.alert.detectionSource.type === AUTOMATION_DETECTION_SOURCE_TYPE
            ? throwError('Ignore autoIR alerts as story is not supported')
            : this.paris.getItemById(ProcessTree, this.alert.id, null, {
                correlationId: this.correlationId,
                officeTenantPrefix: this.officeTenantPrefix,
            });
        this.subscriptions.add(getStory
            .pipe(catchError(function (error) {
            _this.setDefaultProcessTree();
            _this.alertPageService.trackPerformance(_this.alert.id, _this.correlationId, AlertInsightPerformanceType.ProcessTree, false);
            if (_this.alert.detectionSource.type === AUTOMATION_DETECTION_SOURCE_TYPE) {
                _this.processTreeErrorType = ProcessTreeErrorType.unSupportedAlertStory;
            }
            else if (error.status === 404 || error.status === 500) {
                _this.processTreeErrorType = ProcessTreeErrorType.unavailableAlertStory;
            }
            _this.seeInTimelineLink = _this.machinesService.getMachineLink(_this.alert.machine.machineId, true, _this.alert.lastEventTime || _this.alert.lastSeen, _this.alert.id);
            _this.processTreeLoading = false;
            _this.processTreeError = true;
            return NEVER;
        }))
            .subscribe(function (processTree) {
            _this.processTree = processTree;
            performance.mark(AlertInsightPerformanceType.ProcessTreeRendering);
            _this.rootsToRender = processTree.treeElements.length ? 1 : 0;
            if (!_this.rootsToRender) {
                _this.OnDoneRendering();
            }
            _this.setTreeElements();
        }));
    };
    AlertPageComponent.prototype.setTreeElements = function () {
        var _this = this;
        this.treeElements = null;
        this.processTreeLoading = false;
        if (this.processTree.totalSize > 0) {
            this.treeElements = this.processTree.treeElements;
            this.alertPageService.trackProcessTreeInsight(this.alert.id, this.correlationId, true, {
                numberOfTreeElements: this.processTree.totalSize,
                treeDepth: this.processTree.maxDepth,
            });
            this.alertPageService.trackPerformance(this.alert.id, this.correlationId, AlertInsightPerformanceType.ProcessTree, true, {
                numberOfTreeElements: this.processTree.totalSize,
                treeDepth: this.processTree.maxDepth,
            });
            if (!this.isActualLogonUser(this.alert.user) &&
                this.isActualLogonUser(this.processTree.mainUser)) {
                this.setUser(this.processTree.mainUser, true);
            }
            this.collectAlertsIds(this.processTree);
            this.getAlertsEntities();
            this.treeElements.forEach(function (element) { return _this.setTreeElementCyberEntity(element, true); });
        }
        else {
            this.setDefaultProcessTree();
            this.alertPageService.trackPerformance(this.alert.id, this.correlationId, AlertInsightPerformanceType.ProcessTree, false);
        }
    };
    AlertPageComponent.prototype.collectAlertsIds = function (processTree) {
        var _this = this;
        if (processTree.associatedAlerts) {
            processTree.associatedAlerts.forEach(function (associatedAlert) {
                _this.alertsIds.add(associatedAlert.alertId);
            });
        }
    };
    AlertPageComponent.prototype.getAlertsEntities = function () {
        var _this = this;
        this.subscriptions.add(this.paris.apiCall(GetAlertsByIdsApiCall, Array.from(this.alertsIds)).subscribe(function (alertsList) {
            alertsList.alerts.forEach(function (alert) {
                _this.alertsEntities.set(alert.id, alert);
            });
            _this.treeElements.forEach(function (element) { return _this.setAlertsEntities(element); });
        }, function () { }));
    };
    AlertPageComponent.prototype.setAlertsEntities = function (element) {
        var _this = this;
        if (element.associatedAlerts) {
            element.associatedAlerts.forEach(function (associatedAlert) {
                if (_this.alertsEntities.has(associatedAlert.alertId)) {
                    associatedAlert.cyberEntity = _this.alertsEntities.get(associatedAlert.alertId);
                }
            });
        }
        if (element.children) {
            element.children.forEach(function (childElement) { return _this.setAlertsEntities(childElement); });
        }
        if (element.lastChild) {
            this.setAlertsEntities(element.lastChild);
        }
        if (element.associatedItems) {
            element.associatedItems.forEach(function (associatedItem) {
                _this.setAlertsEntities(associatedItem);
            });
        }
    };
    AlertPageComponent.prototype.setMachine = function () {
        var _this = this;
        this.machine = null;
        performance.mark(AlertInsightPerformanceType.RelatedMachine);
        this.subscriptions.add(this.paris.getItemById(Machine, this.alert.machine.machineId).subscribe(function (machine) {
            _this.machine = machine;
            _this.alertPageService.trackPerformance(_this.alert.id, _this.correlationId, AlertInsightPerformanceType.RelatedMachine, true);
        }, function (error) {
            _this.alertPageService.trackErrorInsight(_this.alert.id, _this.correlationId, AlertInsightErrorType.CyberEntityNotFound);
            _this.alertPageService.trackPerformance(_this.alert.id, _this.correlationId, AlertInsightPerformanceType.RelatedMachine, false);
        }));
    };
    AlertPageComponent.prototype.setUser = function (alertUser, fromProcessTree) {
        var _this = this;
        if (fromProcessTree === void 0) { fromProcessTree = false; }
        this.user = null;
        var performanceType = fromProcessTree
            ? AlertInsightPerformanceType.RelatedUserFromProcessTree
            : AlertInsightPerformanceType.RelatedUserFromAlert;
        performance.mark(performanceType);
        if (alertUser) {
            if (this.isActualLogonUser(alertUser)) {
                this.subscriptions.add(this.paris.getItemById(LegacyUser, this.getUserQueryString(alertUser)).subscribe(function (legacyUser) {
                    _this.user = legacyUser;
                    _this.alertPageService.trackPerformance(_this.alert.id, _this.correlationId, performanceType, true, { userFromParis: 1 });
                }, function (error) {
                    _this.alertPageService.trackErrorInsight(_this.alert.id, _this.correlationId, AlertInsightErrorType.CyberEntityNotFound);
                    _this.alertPageService.trackPerformance(_this.alert.id, _this.correlationId, performanceType, true, { userFromParis: 0 });
                    _this.user = new LegacyUser({
                        id: alertUser.sid,
                        sid: alertUser.sid,
                        accountDomainName: alertUser.accountDomainName,
                        accountName: alertUser.accountName,
                    });
                }));
            }
            else {
                this.alertPageService.trackPerformance(this.alert.id, this.correlationId, performanceType, true, { userFromParis: 0 });
                this.user = new LegacyUser({
                    id: alertUser.sid,
                    sid: alertUser.sid,
                    accountDomainName: alertUser.accountDomainName,
                    accountName: alertUser.accountName,
                });
            }
        }
        else {
            this.alertPageService.trackPerformance(this.alert.id, this.correlationId, performanceType, false);
        }
    };
    AlertPageComponent.prototype.setDefaultProcessTree = function () {
        if (!this.alert || !this.alert.id || !this.alert.name) {
            this.alertPageService.trackErrorInsight(this.alert ? this.alert.id : '', this.correlationId, AlertInsightErrorType.NoRelatedAlert);
            this.alertPageService.trackProcessTreeInsight('', this.correlationId, false, {
                loadTime: Date.now() - this.startLoadTime,
            });
        }
        else {
            this.defaultAlertContext = new TreeElementAlertContext(this.alert);
            this.defaultAlertContext.alertId = this.alert.id;
            this.defaultAlertContext.severity = this.alert.severity;
            this.defaultAlertContext.title = this.alert.name;
            this.defaultAlertContext.cyberEntity = this.alert;
            this.alertPageService.trackErrorInsight(this.alert.id, this.correlationId, AlertInsightErrorType.NoProcessTreeCreate);
            this.alertPageService.trackProcessTreeInsight(this.alert.id, this.correlationId, false, {
                loadTime: Date.now() - this.startLoadTime,
            });
        }
    };
    AlertPageComponent.prototype.setTreeElementCyberEntity = function (element, isRoot) {
        var _this = this;
        if (isRoot === void 0) { isRoot = false; }
        element.isRoot = isRoot;
        if (element.cyberEntityType && detailsTabSupportedEntityTypes[element.cyberEntityType]) {
            switch (element.cyberEntityType) {
                case TreeElementCyberEntityTypeEnum.script:
                    this.setScriptCyberEntity(element);
                    break;
                case TreeElementCyberEntityTypeEnum.process:
                    this.setProcessCyberEntity(element);
                    break;
                case TreeElementCyberEntityTypeEnum.file:
                    this.setFileCyberEntity(element);
                    break;
                case TreeElementCyberEntityTypeEnum.url:
                    this.setUrlCyberEntity(element);
                    break;
                case TreeElementCyberEntityTypeEnum.user:
                    this.setUserCyberEntity(element);
                    break;
                case TreeElementCyberEntityTypeEnum.defender:
                    this.setDefenderCyberEntity(element);
                    break;
                default:
                    this.setCyberEntityFromParis(element);
                    break;
            }
        }
        if (element.children) {
            element.children.forEach(function (childElement) { return _this.setTreeElementCyberEntity(childElement); });
        }
        if (element.lastChild) {
            this.setTreeElementCyberEntity(element.lastChild);
        }
        if (element.associatedItems) {
            element.associatedItems.forEach(function (associatedItem) {
                _this.setTreeElementCyberEntity(associatedItem, true);
            });
        }
    };
    // Special case for Script type - do not get it from paris but build it from the hidden details provided by the BE
    AlertPageComponent.prototype.setScriptCyberEntity = function (element) {
        if (!element.hiddenDetails) {
            return;
        }
        element.cyberEntity = new Script({
            sha1: element.hiddenDetails['SHA1'],
            sha256: element.hiddenDetails['SHA256'],
            id: null,
            content: element.hiddenDetails['Content'],
        });
    };
    AlertPageComponent.prototype.setProcessCyberEntity = function (element) {
        var _this = this;
        var _a;
        if (!element.cyberEntityId) {
            return;
        }
        this.subscriptions.add(this.paris
            .getItemById(File, element.cyberEntityId, undefined, (_a = {},
            _a['newFilesApi'] = this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW),
            _a))
            .subscribe(function (file) {
            var pid = Number(element.hiddenDetails['ProcessId']);
            var process = new Process({ id: pid });
            process.creationTime = new Date(element.hiddenDetails['CreationTime']);
            if (element.hiddenDetails['IntegrityLevel']) {
                process.integrityLevel = element.hiddenDetails['IntegrityLevel'];
            }
            if (element.hiddenDetails['TokenElevation']) {
                process.tokenElevation = element.hiddenDetails['TokenElevation'];
            }
            if (element.hiddenDetails['DecodedCommandLine']) {
                process.decodedCommandLine = element.hiddenDetails['DecodedCommandLine'];
            }
            process.commandLine = element.hiddenDetails['CommandLine'];
            process.account = element.boldTitle;
            var mainEntity = {
                item: new FileInstance(__assign({}, file, { fileName: element.hiddenDetails['FileLocalName'], fullPath: element.hiddenDetails['ImageFilePath'] })),
                type: FileInstance,
            };
            var entityContext = {
                item: process,
                type: Process,
                nameKey: 'alerts.events.details.executionDetails',
            };
            _this.setFileCyberEntityAdditionalTypedDetails(element, file);
            element.cyberEntity = new EntityWithContext({
                id: undefined,
                mainEntity: mainEntity,
                entityContext: entityContext,
            });
        }));
    };
    AlertPageComponent.prototype.setUrlCyberEntity = function (element) {
        if (!element.cyberEntityId) {
            return;
        }
        var decodeUrl = decodeURI(decodeURI(element.cyberEntityId));
        element.cyberEntity = new Url({ id: decodeUrl, name: decodeUrl });
    };
    AlertPageComponent.prototype.setFileCyberEntity = function (element) {
        var _this = this;
        var _a;
        if (!element.cyberEntityId) {
            return;
        }
        this.subscriptions.add(this.paris
            .getItemById(File, element.cyberEntityId, undefined, (_a = {},
            _a['newFilesApi'] = this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW),
            _a))
            .subscribe(function (file) {
            var fileEntity = new FileInstance(__assign({}, file, { fileName: element.hiddenDetails['FileLocalName'], fullPath: element.hiddenDetails['Path'] }));
            _this.setFileCyberEntityAdditionalTypedDetails(element, fileEntity);
            element.cyberEntity = fileEntity;
        }));
    };
    AlertPageComponent.prototype.setFileCyberEntityAdditionalTypedDetails = function (treeElement, fileEntity) {
        var _this = this;
        treeElement.additionalGenericFields.push({
            key: this.i18nService.get('files.entityDetails.sections.details.fields.signer.title'),
            value: fileEntity,
            valueType: this.genericFieldType.Signer,
        });
        this.subscriptions.add(this.paris
            .getRelatedItem(FileVirusTotalFileReportRelationship, fileEntity)
            .subscribe(function (virusTotalFileReport) {
            if (_this.hasVirusTotalFileReport(virusTotalFileReport)) {
                treeElement.additionalGenericFields.push({
                    key: _this.i18nService.get('files.entityDetails.sections.detections.fields.virusTotal.title'),
                    value: _this.virusTotalContext(virusTotalFileReport),
                    valueType: _this.genericFieldType.VirusTotalFileReport,
                });
            }
        }));
    };
    AlertPageComponent.prototype.hasVirusTotalFileReport = function (virusTotalFileReport) {
        return this.virusTotalFileReportService.hasVirusTotalData(virusTotalFileReport);
    };
    AlertPageComponent.prototype.virusTotalContext = function (virusTotalFileReport) {
        return this.virusTotalFileReportService.toValueWithExternalLinkContext(virusTotalFileReport);
    };
    AlertPageComponent.prototype.setUserCyberEntity = function (element) {
        var _this = this;
        if (!element.cyberEntityId ||
            this.isSystemSid(element.cyberEntityId) ||
            this.isSystemUserName(element.cyberEntityId)) {
            return;
        }
        this.subscriptions.add(this.paris
            .getItemById(LegacyUser, element.cyberEntityId)
            .pipe(switchMap(function (legacyUser) {
            element.cyberEntity = legacyUser;
            return _this.paris.getRelatedItem(LegacyUserLegacyUserProfileRelationship, legacyUser);
        }))
            .subscribe(function (profile) {
            _this.setUserCyberEntityAdditionalTypedDetails(element, profile);
        }));
    };
    AlertPageComponent.prototype.setUserCyberEntityAdditionalTypedDetails = function (treeElement, profile) {
        if (!profile) {
            return;
        }
        if (profile.department) {
            treeElement.additionalGenericFields.push({
                key: this.i18nService.get('users.entityDetails.sections.details.department.title'),
                value: profile.department,
                valueType: this.genericFieldType.String,
            });
        }
        if (profile.title) {
            treeElement.additionalGenericFields.push({
                key: this.i18nService.get('users.entityDetails.sections.details.jobTitleUserProfile.title'),
                value: profile.title,
                valueType: this.genericFieldType.String,
            });
        }
        if (profile.name) {
            treeElement.additionalGenericFields.push({
                key: this.i18nService.get('users.entityDetails.name'),
                value: profile.name,
                valueType: this.genericFieldType.String,
            });
        }
        treeElement.additionalGenericFields.push({
            key: this.i18nService.get('users.entityDetails.sections.contactDetails.title'),
            value: treeElement.cyberEntity,
            valueType: this.genericFieldType.ContactDetails,
        });
    };
    AlertPageComponent.prototype.setDefenderCyberEntity = function (element) {
        var _a;
        if (!element.cyberEntityId) {
            return;
        }
        this.subscriptions.add(this.paris
            .getItemById(File, element.cyberEntityId, undefined, (_a = {},
            _a['newFilesApi'] = this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW),
            _a))
            .subscribe(function (file) {
            var blockDetails = new AntivirusBlockDetails({ id: element.cyberEntityId });
            if (element.hiddenDetails['ThreatName']) {
                blockDetails.threatName = element.hiddenDetails['ThreatName'];
            }
            if (element.hiddenDetails['WasRemediated']) {
                blockDetails.wasRemediated = element.hiddenDetails['WasRemediated'] === 'True';
            }
            else {
                blockDetails.wasRemediated = null;
            }
            if (element.hiddenDetails['wasExecutingWhileDetected']) {
                blockDetails.wasExecutingWhileDetected =
                    element.hiddenDetails['wasExecutingWhileDetected'] === 'True';
            }
            else {
                blockDetails.wasExecutingWhileDetected = null;
            }
            if (element.hiddenDetails['Container']) {
                blockDetails.container = element.hiddenDetails['Container'];
            }
            if (element.hiddenDetails['RemediationAction']) {
                switch (element.hiddenDetails['RemediationAction']) {
                    case 'clean':
                        blockDetails.remediationAction = AntivirusRemediationAction.Clean;
                        break;
                    case 'quarantine':
                        blockDetails.remediationAction = AntivirusRemediationAction.Quarantine;
                        break;
                    case 'remove':
                        blockDetails.remediationAction = AntivirusRemediationAction.Remove;
                        break;
                    case 'block':
                        blockDetails.remediationAction = AntivirusRemediationAction.Block;
                        break;
                }
            }
            var fileInstance = {
                item: new FileInstance(__assign({}, file, { fileName: element.hiddenDetails['FileLocalName'], fullPath: element.hiddenDetails['FilePath'] })),
                type: FileInstance,
            };
            var fileAction = new FileActionDetails({
                id: element.cyberEntityId,
                actionTime: element.time,
                actionType: 'WDAVDetection',
                file: fileInstance.item,
                blockDetails: blockDetails,
            });
            var entityContext = {
                item: fileAction,
                type: FileActionDetails,
                nameKey: 'alerts.events.details.blockDetails',
            };
            element.cyberEntity = new EntityWithContext({
                id: undefined,
                mainEntity: fileInstance,
                entityContext: entityContext,
            });
        }));
    };
    AlertPageComponent.prototype.setCyberEntityFromParis = function (element) {
        var _this = this;
        if (!element.cyberEntityId) {
            return;
        }
        this.subscriptions.add(this.paris
            .getItemById(detailsTabSupportedEntityTypes[element.cyberEntityType], element.cyberEntityId)
            .subscribe(function (entity) {
            element.cyberEntity = entity;
        }, function (error) {
            _this.alertPageService.trackErrorInsight(_this.alert.id, _this.correlationId, AlertInsightErrorType.CyberEntityNotFound);
        }));
    };
    AlertPageComponent.prototype.getTabs = function () {
        var _this = this;
        var tabs = [
            {
                id: 'details',
            },
        ];
        if (this.enableImportAlert) {
            tabs.push({
                id: 'importAlert',
            });
        }
        return tabs.map(function (tab) {
            return new TabModel(__assign({ name: _this.i18nService.get("alert.tabs." + tab.id + ".title"), routerLink: "./" + tab.id }, tab));
        });
    };
    AlertPageComponent.prototype.setTreeMinimized = function (isMinimized) {
        var _this = this;
        this.allCollapsed = !this.allCollapsed;
        this.treeElements.forEach(function (element) { return _this.setTreeMinimizedRecursive(element, isMinimized); });
    };
    AlertPageComponent.prototype.setTreeMinimizedRecursive = function (element, isMinimized) {
        var _this = this;
        element.isMinimized = isMinimized;
        element.children.forEach(function (child) { return _this.setTreeMinimizedRecursive(child, isMinimized); });
        if (element.lastChild) {
            this.setTreeMinimizedRecursive(element.lastChild, isMinimized);
        }
    };
    AlertPageComponent.prototype.getUserQueryString = function (user) {
        if (user.accountDomainName && user.accountName) {
            return user.accountName + '/' + user.accountDomainName + '/' + user.sid;
        }
        else {
            return user.sid;
        }
    };
    AlertPageComponent.prototype.isActualLogonUser = function (alertUser) {
        if (!alertUser || (!alertUser.sid && (!alertUser.name || !alertUser.domain))) {
            return false;
        }
        return !this.isSystemUser(alertUser);
    };
    AlertPageComponent.prototype.isSystemUser = function (alertUser) {
        if (!alertUser) {
            return false;
        }
        if (alertUser.sid && alertUser.sid != '') {
            return this.isSystemSid(alertUser.sid);
        }
        if (alertUser.fullName && alertUser.fullName != '') {
            return this.isSystemUserName(alertUser.fullName);
        }
        return false;
    };
    AlertPageComponent.prototype.isSystemSid = function (sid) {
        if (!sid) {
            return false;
        }
        var minNumOfDashesInRealUserSid = 5; // actual users (not local system) sid has at least '-' in it
        return sid.match(/-/gi) == null || sid.match(/-/gi).length < minNumOfDashesInRealUserSid;
    };
    AlertPageComponent.prototype.isSystemUserName = function (userName) {
        var trivialUserNames = [
            'NT AUTHORITY\\NETWORK SERVICE',
            'NT AUTHORITY\\',
            'NETWORK SERVICE',
            'NT AUTHORITY\\SYSTEM',
            'SYSTEM',
            'NT AUTHORITY\\LOCAL SERVICE',
            'LOCAL SERVICE',
            '',
        ];
        return trivialUserNames.includes(userName.toUpperCase());
    };
    AlertPageComponent.prototype.OnDoneRendering = function () {
        var _this = this;
        setTimeout(function () {
            _this.alertPageService.trackPerformance(_this.alert.id, _this.correlationId, AlertInsightPerformanceType.ProcessTreeRendering, true);
        }, 0);
    };
    AlertPageComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return AlertPageComponent;
}());
export { AlertPageComponent };
