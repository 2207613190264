import { SevilleModule } from '../../../../../app/seville/seville.module';
import { AppConfigService } from '@wcd/app-config';
import { AppInsightsService } from '../../../../../../insights/services/app-insights.service';
import { RbacMdeAllowedActions } from '../../../../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.controller('seville.settings.download.omssetupmodal', omsSetupModalController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('settings.omssetupmodal', {
			url: '/offboardingmodal',
			views: {
				onboardingStepView: {
					template: OMS_SETUP_MODAL_TEMPLATE,
					controller: 'seville.settings.download.omssetupmodal',
					controllerAs: 'omssetupmodal',
				},
			},
		});
	},
]);

omsSetupModalController.$inject = [
	'$scope',
	'$uibModalInstance',
	'appConfig',
	'authenticationService',
	'appInsights',
];

function omsSetupModalController(
	$scope,
	$modalInstance,
	appConfig: AppConfigService,
	authenticationService,
	appInsights: AppInsightsService
) {
	$scope.settingsBaseUrl = appConfig.serviceUrls.management;
	$scope.isreadonlyuser = !authenticationService.isUserActionAllowed([RbacMdeAllowedActions.securitySettings]);

	$scope.ok = function() {
		$modalInstance.close();
	};

	$scope.cancel = function() {
		$modalInstance.dismiss();
		appInsights.trackEvent('ButtonClick', {
			ButtonType: 'StartOmsProvisioningCancel',
			Page: 'SettingsDownloadServer',
		});
	};
}

export const OMS_SETUP_MODAL_TEMPLATE: string = `
<div class="settings-download-oms-modal">
    <div class="settings-title">
        Turn on server device monitoring?
    </div>
    <div>
        <table>
            <tr>
                <td class="icon icon-lg icon-Info settings-download-oms-modal-padding"></td>
                <td>
                    Setup is about to prepare Microsoft Defender for Endpoint for server device monitoring. This might take a few minutes.
                </td>
            </tr>
        </table>
    </div>
    <br />
    <button class="settings-button ng-class:{'settings-button-enabled':!isreadonlyuser, 'settings-button-disabled':isreadonlyuser}"
            ng-disabled="isreadonlyuser"
            ng-click="ok()">
        Yes
    </button>
    <span>
        <button type="reset" class="normal-button settings-download-oms-modal-button-margin" ng-click="cancel()">Cancel</button>
    </span>
    <br />
</div>
`;
