import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { Incident } from '../incident/incident.entity';
import { DataSensitivity } from '../file/data-sensitivity.value-object';

@ApiCall({
	name: 'Get all device sensitivities related to a specific incident',
	parseQuery: (incident: Incident) => {
		return {
			params: {
				id: incident.id,
			},
		};
	},
	endpoint: (_config, query) => {
		return `incidents/${query.where['id']}/machineSensitivities`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.incidentMachineSensitivities,
	cache: {
		time: 1000 * 60,
	},
	type: DataSensitivity,
})
export class GetIncidentMachineSensitivitiesApiCall extends ApiCallModel<Array<DataSensitivity>, Incident> {}
