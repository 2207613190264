import { Pipe, PipeTransform } from '@angular/core';
import { AppContextService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';

@Pipe({ name: 'basePath' })
export class BasePathPipe implements PipeTransform {
	constructor(private appContext: AppContextService) {}
	transform(value: string): string {
		const _val = value[0] === '/' ? value : `/${value}`;
		return this.appContext.isSCC ? sccHostService.getPackageBasePathByPkg() + _val : value;
	}
}
