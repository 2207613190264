var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, Injector, OnInit } from '@angular/core';
import { FileFileProtectionInfoRelationship, FileInstance, FileQuarantineActionsRelationship, CustomTiIndicatorsType, CustomTiIndicatorsTypes, } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { Paris } from '@microsoft/paris';
import { shareReplay, take } from 'rxjs/operators';
import { TagsService } from '../../../tags/services/tags.service';
import { Feature, FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { merge, Subject } from 'rxjs';
import { toObservable } from '../../../utils/rxjs/utils';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { MessageBarType } from 'office-ui-fabric-react';
import { FileEntityTypeService } from '../../../@entities/files/services/file.entity-type.service';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["Tags"] = "file-entity-details-tags";
    CollapsibleID["Details"] = "file-entity-details-information";
    CollapsibleID["ProtectionInformation"] = "file-entity-details-protection-information";
    CollapsibleID["PeMetadata"] = "file-pe-metadata";
})(CollapsibleID || (CollapsibleID = {}));
var FileEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(FileEntityDetailsComponent, _super);
    function FileEntityDetailsComponent(injector, paris, changeDetector, tagsService, featuresService, i18nService, globalEntityTypesService, fileEntityTypeService) {
        var _this = _super.call(this, injector) || this;
        _this.paris = paris;
        _this.changeDetector = changeDetector;
        _this.tagsService = tagsService;
        _this.featuresService = featuresService;
        _this.i18nService = i18nService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.fileEntityTypeService = fileEntityTypeService;
        _this.fileInstanceType = FileInstance;
        _this.MessageBarType = MessageBarType;
        _this._tagsSubject$ = new Subject();
        _this.collapsibleID = CollapsibleID;
        _this.showFileInstanceDetails = true;
        return _this;
    }
    FileEntityDetailsComponent.prototype.ngOnInit = function () {
        this.setEntity(this.entity);
        this.isNewFilePageEnabled = this.featuresService.isEnabled(Feature.NewFilePage);
        this.getQuarantineActionStatus();
        this.getCustomIndicatorData();
    };
    Object.defineProperty(FileEntityDetailsComponent.prototype, "file", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileEntityDetailsComponent.prototype, "sensitivityTag", {
        get: function () {
            return this.file.sensitivity
                ? this.tagsService.getDataSensitivityTag(this.file.sensitivity, this.i18nService.get('files.sensitivity.tag.tooltip'), false)
                : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileEntityDetailsComponent.prototype, "isDataSensitivityEnabled", {
        get: function () {
            return this.featuresService.isEnabled(Feature.FileSensitivity);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileEntityDetailsComponent.prototype, "showFileVerdict", {
        get: function () {
            return (this.file &&
                this.file.sha1 && // File verdict doesn't support SHA256
                this.mode === EntityDetailsMode.EntityPage &&
                this.featuresService.isEnabled(Feature.FileVerdict));
        },
        enumerable: true,
        configurable: true
    });
    FileEntityDetailsComponent.prototype.isFileInstance = function (file) {
        return file instanceof FileInstance;
    };
    FileEntityDetailsComponent.prototype.setEntity = function (entity) {
        var _this = this;
        _super.prototype.setEntity.call(this, entity);
        this.entityType = this.globalEntityTypesService.getEntityType(entity.constructor) ||
            this.globalEntityTypesService.getEntityType(entity.entityType);
        if (this.isDataSensitivityEnabled) {
            if (this.isFileInstance(this.file)) {
                // file instance has these properties set
                this.fileInstance = this.file;
                this.isAzureInfoProtectionApplied = this.file.isAzureInfoProtectionApplied;
                this.isWindowsInfoProtectionApplied = this.file.isWindowsInfoProtectionApplied;
            }
            else {
                // fetch the latest status for the file
                this.isAzureInfoProtectionApplied = this.isWindowsInfoProtectionApplied = null;
                this.paris
                    .getRelatedItem(FileFileProtectionInfoRelationship, this.file)
                    .pipe(take(1))
                    .subscribe(function (info) {
                    //isWindowsInfoProtectionApplied in applicable only for a particular file instance
                    _this.isAzureInfoProtectionApplied = info.isAzureInfoProtectionApplied;
                    _this.changeDetector.markForCheck();
                });
            }
        }
        this.tags$ = merge(toObservable(this.entityType.getTags([this.entity])), this._tagsSubject$).pipe(shareReplay(1));
        this.changeDetector.markForCheck();
    };
    FileEntityDetailsComponent.prototype.getQuarantineActionStatus = function () {
        var _this = this;
        this.paris
            .getRelatedItem(FileQuarantineActionsRelationship, this.file)
            .subscribe(function (data) { return _this.quarantineActionStatus = data.request; });
    };
    FileEntityDetailsComponent.prototype.getCustomIndicatorData = function () {
        var _this = this;
        var indicatorType = this.paris.getValue(CustomTiIndicatorsType, CustomTiIndicatorsTypes.Files);
        this.fileEntityTypeService.getExistentCustomIndicators(this.file, indicatorType)
            .subscribe(function (data) {
            var indicatorFilters = [_this.file.sha1, _this.file.sha256, _this.file.md5];
            var href = "/preferences2/custom_ti_indicators/" + indicatorType.id + "?filters=" +
                encodeURI('tiIndicator=' + indicatorFilters.join('|'));
            _this.customIndicatorData = data[0] && __assign({ href: href }, data[0]);
        });
    };
    return FileEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { FileEntityDetailsComponent };
