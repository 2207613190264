import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('blur', blurDirective);

function blurDirective() {
	return {
		restrict: 'EA',
		scope: {},
		link: function(scope, element, attrs) {
			$(element[0]).click(function() {
				$(this).blur();
			});
		},
	};
}
