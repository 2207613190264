import { Component, Inject, OnInit } from '@angular/core';
import { catchError, first, map, tap } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { ContentState } from '@wcd/contents-state';
import { PlatformProvisionStatus } from '@wcd/domain';
import {
	OnboardingState,
	PANEL_SERVICE_INJECTION_TOKEN,
	SccOnboardingPanelService,
} from '../models/onboarding.models';
import { OnboardingService } from '../services/onboarding.service';
import { Observable, throwError } from 'rxjs';
import { tenantContextCache } from '@wcd/auth';
import { ActivatedRoute } from '@angular/router';
import { breadcrumbsStateService } from '@wcd/shared';

@Component({
	selector: 'scc-onboarding-welcome',
	templateUrl: './welcome.component.html',
})
export class SccOnboardingWelcomeComponent implements OnInit {
	contentState = ContentState.Loading;

	onboardingState: OnboardingState;
	hasAlreadyConsented: boolean;
	redirectTo: string;

	constructor(
		@Inject(PANEL_SERVICE_INJECTION_TOKEN) private readonly panelService: SccOnboardingPanelService,
		private i18n: I18nService,
		private onboardingService: OnboardingService,
		private route: ActivatedRoute
	) {
		this.hasAlreadyConsented = tenantContextCache.appConfig.MtpConsent;
	}

	ngOnInit() {
		breadcrumbsStateService.reset();

		this.route.queryParams.subscribe(params => {
			this.redirectTo = params['redirectTo'] || 'incidents';
		});

		this.initOnboarding()
			.subscribe();
	}

	private initOnboarding(): Observable<boolean> {
		return this.onboardingService.platformProvisionStatus$.pipe(
			first(),
			tap(provisionStatus => {
				this.contentState = ContentState.Complete;

				if (this.hasAlreadyConsented) {
					// Tenant has given consent - display onboarding view according to PlatformProvisionStatus
					if (provisionStatus === PlatformProvisionStatus.NotProvisioned) {
						this.onboardingState = OnboardingState.ConsentedNotOnboarded;
						return;
					} else if (provisionStatus === PlatformProvisionStatus.AdditionalPlatformProvisioned) {
						this.onboardingState = OnboardingState.PlatformProvisioningCompleted;
						return;
					} else {
						// Either 'PlatformProvisioned' or 'InProgress' are considered as during onboarding
						this.onboardingState = OnboardingState.ConsentedDuringOnboarding;
					}
				} else {
					// Tenant hasn't given consent - display toggle view regardless of specific PlatformProvisionStatus
					if (provisionStatus === PlatformProvisionStatus.NotProvisioned) {
						this.onboardingState = OnboardingState.NotConsentedNotOnboarded;
					} else if (
						(provisionStatus === PlatformProvisionStatus.AdditionalPlatformProvisioned) &&
						tenantContextCache.appConfig.IsOnboardingComplete === true
					) {
						// IsOnboardingComplete == true enforces that tenants will anyway grant their first consent through the onboarding view (not settings)
						this.onboardingState = OnboardingState.PlatformProvisioningCompleted;
					} else {
						this.onboardingState = OnboardingState.NotConsentedDuringOnboarding;
					}
				}
			}),
			catchError(error => {
				this.onboardingState = OnboardingState.EncounteredError;
				return throwError(error);
			}),
			// Converting observable to void so both initOnboarding v1/v2 will be of the same type.
			map(() => true)
		);
	}
}
