import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { MachineStatus } from '../machine/machine-status.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { MachineGroup } from '../rbac/machine-group.entity';

@Entity({
	singularName: 'Investigated Device',
	pluralName: 'Investigated Devices',
	endpoint: 'investigatedhost',
	allItemsProperty: 'results',
	readonly: true,
	separateArrayParams: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class InvestigatedMachine extends EntityModelBase {
	@EntityField({ data: ['id', 'machine_id', 'machineId', 'Id'] })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: ['name', 'Name'] }) name: string;

	@EntityField({ data: ['machine_id', 'machineId'] })
	machineId: string;

	@EntityField() group: MachineGroup;

	@EntityField({ data: 'rbac_group_id' })
	rbacGroupId: number;

	@EntityField({ data: ['logged_in_user', 'users', 'Users'] })
	users: Array<string>;

	@EntityField({ data: 'os', parse: os => (os && (typeof os === 'string' ? os : os.name)) || '' })
	osName?: string;

	@EntityField({ data: 'connectivity', defaultValue: false })
	isConnected: boolean;

	@EntityField({ data: 'is_unmanaged', defaultValue: false })
	isUnmanaged: boolean;

	@EntityField({ data: 'is_canceled_investigation', defaultValue: false })
	isCanceledInvestigation;

	@EntityField({ data: 'protection_level_name' })
	protectionLevelName: string;

	@EntityField({ data: 'protection_level_type' })
	protectionLevelId: number;

	@EntityField({ data: ['status', 'Status'] }) status: MachineStatus;

	@EntityField({ data: 'last_update' })
	lastUpdate: Date;

	@EntityField({ data: 'last_investigated' })
	lastInvestigated: Date;

	@EntityField({ data: 'investigation_count' })
	investigationCount: number;

	@EntityField({ data: 'alert_count' })
	alertCount: number;

	@EntityField({ data: 'entity_count' })
	entityCount: number;

	@EntityField({ data: 'reason' })
	unknownStatusReason: string;

	@EntityField({ data: ['ip', 'Ip'] }) ip: string;

	private _allowCancelInvestigation: boolean;

	get allowCancelInvestigation(): boolean {
		return this._allowCancelInvestigation !== null && this._allowCancelInvestigation !== undefined
			? this._allowCancelInvestigation
			: this.status.allowCancelInvestigation;
	}

	get usersLabel(): string {
		if (!this.users) return 'N/A';

		return this.users.join(', ');
	}
}
