import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { MissingKb } from '@wcd/domain';

@Component({
	selector: 'missing-kb-entity-panel',
	template: `
		<section class="wcd-padding-vertical wcd-padding-large-horizontal">
			<missing-kb-entity-details [missingKb]="missingKb" [machineDetails]="machineDetails">
			</missing-kb-entity-details>
		</section>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissingKbEntityPanelComponent extends EntityPanelComponentBase<
	MissingKb,
	MissingKbDetailsOptions
> {
	get missingKb(): MissingKb {
		return this.entity;
	}

	get machineDetails(): MissingKbDetailsOptions {
		return this.options;
	}

	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}

	initEntity(missingKb: MissingKb, isExtendedData: boolean = false) {
		super.initEntity(missingKb, isExtendedData);
		this.changeDetectorRef.markForCheck();
	}
}

export interface MissingKbDetailsOptions {
	singleAssetMissingKb: boolean;
	machineId?: string;
}
