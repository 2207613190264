
		<span
			class="wcd-toggle"
			[ngClass]="{ disabled: isDisabled }"
			tabindex="0"
			role="switch"
			[attr.aria-label]="ariaLabel ? ariaLabel : trueLabel"
			[attr.aria-checked]="!!checked"
			(click)="_onInteractionEvent($event)"
		>
			<input
				type="checkbox"
				[checked]="checked"
				(change)="_onInteractionEvent($event)"
				[disabled]="isDisabled"
				[name]="name"
				[id]="id"
			/>
			<label class="wcd-toggle-label" [attr.for]="id" [wcdTooltip]="isDisabled && disableReason">
				<span class="wcd-toggle-slider">
					<i class="wcd-toggle-ball"><em class="wcd-high-contrast-toggle-ball"></em></i>
				</span>
			</label>
			<label class="wcd-toggle-true" [attr.for]="id" *ngIf="checked; else unChecked">{{
				trueLabel
			}}</label>
			<wcd-help [text]="helpText" *ngIf="helpText"> </wcd-help>
			<ng-template #unChecked>
				<label class="wcd-toggle-false" [attr.for]="id">{{ falseLabel }}</label>
			</ng-template>
		</span>
	