import { ChangeDetectionStrategy, Component, Injector, Input, Type } from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ModelBase } from '@microsoft/paris';
import {
	AccessTokenModificationDetails,
	AntivirusBlockDetails,
	CommandLine,
	Email,
	File,
	FileActionDetails,
	FileInstance,
	LegacyUser,
	NetworkEndpoint,
	Process,
	ProcessActionDetails,
	RegistryModificationDetails,
	ScheduledTask,
	UsbEvent,
} from '@wcd/domain';

const supportedEntityTypes = {
	accessToken: AccessTokenModificationDetails,
	commandLine: CommandLine,
	file: File,
	fileInstance: FileInstance,
	networkEndpoint: NetworkEndpoint,
	process: Process,
	registryModification: RegistryModificationDetails,
	scheduledTask: ScheduledTask,
	user: LegacyUser,
	email: Email,
	antivirusBlock: AntivirusBlockDetails,
	fileAction: FileActionDetails,
	processAction: ProcessActionDetails,
	usbEvent: UsbEvent,
};

@Component({
	selector: 'global-entity-details',
	template: `
		<ng-container [ngSwitch]="entityType">
			<process-entity-details
				*ngSwitchCase="supportedEntityTypes.process"
				[entity]="entity"
				[showEntityPanelLink]="showEntityPanelLink"
				[dataEnriched]="dataEnriched"
			></process-entity-details>
			<file-entity-details
				*ngSwitchCase="supportedEntityTypes.file"
				[entity]="entity"
				[showEntityPanelLink]="showEntityPanelLink"
			></file-entity-details>
			<file-entity-details
				*ngSwitchCase="supportedEntityTypes.fileInstance"
				[entity]="entity"
				[showEntityPanelLink]="showEntityPanelLink"
			></file-entity-details>
			<network-endpoint-entity-details
				*ngSwitchCase="supportedEntityTypes.networkEndpoint"
				[entity]="entity"
				[showEntityPanelLink]="showEntityPanelLink"
			></network-endpoint-entity-details>
			<registry-modification-entity-details
				*ngSwitchCase="supportedEntityTypes.registryModification"
				[entity]="entity"
				[showEntityPanelLink]="showEntityPanelLink"
			></registry-modification-entity-details>
			<user-entity-details
				*ngSwitchCase="supportedEntityTypes.user"
				[entity]="entity"
				[showEntityPanelLink]="showEntityPanelLink"
			></user-entity-details>
			<command-line-entity-details
				*ngSwitchCase="supportedEntityTypes.commandLine"
				[entity]="entity"
			></command-line-entity-details>
			<access-token-entity-details
				*ngSwitchCase="supportedEntityTypes.accessToken"
				[entity]="entity"
			></access-token-entity-details>
			<scheduled-task-entity-details
				*ngSwitchCase="supportedEntityTypes.scheduledTask"
				[entity]="entity"
			></scheduled-task-entity-details>
			<email-entity-details
				*ngSwitchCase="supportedEntityTypes.email"
				[entity]="entity"
			></email-entity-details>
			<antivirus-block-entity-details
				*ngSwitchCase="supportedEntityTypes.antivirusBlock"
				[entity]="entity"
			></antivirus-block-entity-details>
			<file-action-entity-details
				*ngSwitchCase="supportedEntityTypes.fileAction"
				[entity]="entity"
			></file-action-entity-details>
			<process-action-entity-details
				*ngSwitchCase="supportedEntityTypes.processAction"
				[entity]="entity"
			></process-action-entity-details>
			<usb-event-entity-details
				*ngSwitchCase="supportedEntityTypes.usbEvent"
				[entity]="entity"
			></usb-event-entity-details>
			<div *ngSwitchDefault>
				{{ 'events.entities.notFound' | i18n: { type: entityType.name } }}
			</div>
		</ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalEntityDetailsComponent extends EntityDetailsComponentBase<ModelBase> {
	constructor(injector: Injector) {
		super(injector);
	}

	@Input() entityType: Type<ModelBase>;

	supportedEntityTypes = supportedEntityTypes;
}
