import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { FabricIconNames } from '@wcd/scc-common';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { AadUser } from '@wcd/domain';
import { NavItemModel } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';

const SELF_TARGET = '_self';

@Component({
	selector: 'user-display-name',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<fab-icon
			*ngIf="showIcon && displayName"
			[iconName]="FabricIconNames.Contact"
			[contentClass]="iconCssClass"
		></fab-icon>
		<route-link
			*ngIf="showLink && (sid || aadUserId); else userDisplayName"
			[routeLink]="userRouteLinkModel"
		>
			<ng-container *ngTemplateOutlet="userDisplayName"></ng-container>
		</route-link>
		<ng-template #userDisplayName>
			<span class="wcd-text-overflow-ellipsis" [wcdTooltip]="displayName">{{ displayName }}</span>
		</ng-template>
	`,
})
export class UserDisplayNameComponent implements OnChanges {
	FabricIconNames = FabricIconNames;

	constructor(private globalEntityTypesService: GlobalEntityTypesService) {}

	@Input() showIcon: boolean = false;
	@Input() iconCssClass: string = 'field-value-icon';
	@Input() showLink: boolean = false;
	@Input() displayName: string;
	@Input() sid?: string;
	@Input() aadUserId?: string;

	userRouteLinkModel: Partial<NavItemModel>;

	ngOnChanges() {
		const userPageUrl = this.sid
			? sccHostService.state.href('user', { sid: this.sid, path: '' })
			: this.aadUserId
			? sccHostService.state.href('user', { aad: this.aadUserId, path: '' })
			: '';

		if (userPageUrl) {
			this.userRouteLinkModel = this.globalEntityTypesService.getRouteLinkModel(AadUser, <
				Partial<AadUser>
			>{
				userPageUrl: userPageUrl,
			});

			// properties to open the user page on the same view and not in a different tab
			Object.assign(this.userRouteLinkModel, {
				showExternalLinkIcon: false,
				externalLinkTarget: SELF_TARGET,
			});
		}
	}
}
