/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/tabs/tabs.component.ngfactory";
import * as i2 from "../../../shared/components/tabs/tabs.component";
import * as i3 from "@angular/router";
import * as i4 from "./ip.entity.component";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i7 from "../services/ips.service";
var styles_IpEntityComponent = [];
var RenderType_IpEntityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IpEntityComponent, data: {} });
export { RenderType_IpEntityComponent as RenderType_IpEntityComponent };
export function View_IpEntityComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "tabs", [["class", "wcd-flex-none wcd-margin-xxLarge-horizontal wcd-flex-spaceBetween-horizontal\n\t\t\t\t\t   wcd-flex-center-vertical wcd-margin-large-bottom wcd-padding-none-left-sm-screens"]], null, null, null, i1.View_TabsComponent_0, i1.RenderType_TabsComponent)), i0.ɵdid(4, 4833280, null, 0, i2.TabsComponent, [i0.ChangeDetectorRef, i3.ActivatedRoute, i3.Router], { tabsData: [0, "tabsData"], currentRouteIsActive: [1, "currentRouteIsActive"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "wcd-flex-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(9, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; var currVal_1 = true; _ck(_v, 4, 0, currVal_0, currVal_1); _ck(_v, 9, 0); }, null); }
export function View_IpEntityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_IpEntityComponent_0, RenderType_IpEntityComponent)), i0.ɵdid(1, 245760, null, 0, i4.IpEntityComponent, [i5.I18nService, i6.FeaturesService, i7.IpsService, i3.Router, i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IpEntityComponentNgFactory = i0.ɵccf("ng-component", i4.IpEntityComponent, View_IpEntityComponent_Host_0, { entity: "entity", isFullScreen: "isFullScreen", action$: "action$", entityPageViewMode: "entityPageViewMode" }, { runAction$: "runAction$" }, []);
export { IpEntityComponentNgFactory as IpEntityComponentNgFactory };
