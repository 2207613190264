import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfigService } from '@wcd/app-config';
import { AuthService } from '../services/auth.service';
import { FeaturesService, Feature } from '@wcd/config';

@Injectable()
export class NotOnboardGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private appConfigService: AppConfigService,
		private router: Router,
		private featuresService: FeaturesService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (!this.appConfigService.isOnboardingComplete) return true;

		this.router.navigate(['/dashboard']);
		return false;
	}
}
