import { Injectable } from '@angular/core';
import { IncidentGraphNode } from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { IncidentGraphNodeEntityPanelComponent } from '../components/incident-graph/incident-graph-node.entity-panel.component';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class IncidentGraphNodeEntityTypeService implements EntityTypeService<IncidentGraphNode> {
	constructor(private i18nService: I18nService) {}

	readonly entityType: EntityType<IncidentGraphNode> = {
		id: 'incidentGraphNode',
		entity: IncidentGraphNode,
		getIcon: (incidents: Array<IncidentGraphNode>) => incidents[0].entityType.iconName,
		singleEntityPanelComponentType: IncidentGraphNodeEntityPanelComponent,
		loadFullEntityInPanel: false,
		getEntityName: (incident: IncidentGraphNode) => incident.name,
		getSubtitle: (incidents: Array<IncidentGraphNode>) =>
			incidents[0].isGroup
				? this.i18nService.get('incidents.graph.grouping.count', {
						count: incidents[0].childNodes.length,
				  })
				: null,
	};
}
