import { SevilleModule } from '../../seville/seville.module';
import { ServiceSource, ServiceSourceType, serviceSourceValues } from '@wcd/domain';

SevilleModule.filter('alertProductSource', alertServiceSourceFilter);

const sources: { [serviceSourceId: string]: ServiceSource } = serviceSourceValues.reduce(
	(acc, serviceSource: ServiceSource) => {
		acc[serviceSource.id] = serviceSource;
		return acc;
	},
	{}
);

function alertServiceSourceFilter() {
	return (input: ServiceSourceType) => sources[input].name;
}
