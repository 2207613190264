import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { IncidentSeverityCount } from './incident-severity-count.value-object';
import { Incident } from './incident.entity';
import {ActiveTrendItem } from './active-trend-item.value-object'
@ValueObject({
	singularName: 'Active incidents',
	pluralName: '',
	readonly: true,
})
export class ActiveIncidents extends ModelBase {
	@EntityField({ data: ['activeIncidentCount', 'ActiveIncidentCount']})
	activeIncidentCount: number;

	@EntityField({ arrayOf: IncidentSeverityCount })
	incidentsBySeverity: Array<IncidentSeverityCount>;

	@EntityField({ arrayOf: Incident , data: ['topIncidents', 'TopIncidents']})
	topIncidents: Array<Incident>;

	@EntityField()
	unassignedIncidentCount: number;

	@EntityField({arrayOf: ActiveTrendItem, data: ['incidentsTrend', 'IncidentsTrend']})
	incidentsTrend: Array<ActiveTrendItem>;

	@EntityField({arrayOf: ActiveTrendItem, data: ['alertsTrend', 'AlertsTrend']})
	alertsTrend: Array<ActiveTrendItem>;

}
