import { ElementRef, AfterViewInit } from '@angular/core';
import { select as d3Select } from 'd3-selection';
import { scaleTime, scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
import { extent, mouse, bisector, area } from 'd3';
// TODO: evhvoste - Task 27434820: Refactor D3 Area and Line chart components into a mutual base
var AreaChartComponent = /** @class */ (function () {
    function AreaChartComponent(elementRef) {
        this.elementRef = elementRef;
        this.OPACITY_TRANSITION_MS = 150;
        this.MOUSE_OUT_TIMEOUT = 150;
        this.SHOW_TOOLTIP_TIMEOUT = 90;
        this.WIDTH_TO_HEIGHT_RATIO = 2.5;
        this.DEFAULT_AREA_CHART_ID = 'areaChart';
        this._afterViewInit = false;
        this.bisectDate = bisector(function (d, x) { return x - d.date; }).left;
    }
    Object.defineProperty(AreaChartComponent.prototype, "options", {
        set: function (chartOptions) {
            var _this = this;
            if (!chartOptions) {
                return;
            }
            this._chartOptions = chartOptions;
            this._chartData = chartOptions.data;
            this._chartColor = chartOptions.color;
            this.chartId = chartOptions.id ? chartOptions.id : this.DEFAULT_AREA_CHART_ID;
            if (this._afterViewInit) {
                setTimeout(function () { return _this.drawChart(); });
            }
        },
        enumerable: true,
        configurable: true
    });
    AreaChartComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var elementId = '#' + this.chartId;
        this._rootEl = this.elementRef.nativeElement.querySelector(elementId);
        this._rootD3El = d3Select(this._rootEl);
        setTimeout(function () {
            _this.drawChart();
            _this._rootElementHeight = _this._rootEl.clientHeight;
            _this._afterViewInit = true;
        }, 0);
    };
    AreaChartComponent.prototype.onResize = function (_) {
        this.drawChart();
    };
    AreaChartComponent.prototype.stopHoverExperience = function () {
        this.setDefaultTooltipOpacity(0);
        this._focus.style('display', 'none');
    };
    AreaChartComponent.prototype.drawChart = function () {
        var _this = this;
        if (!this._chartData) {
            return;
        }
        this._rootD3El.html('');
        this._chartWidth = this._chartOptions.width || this._rootEl.clientWidth;
        var height = this._chartOptions.height || Math.round(this._chartWidth / this.WIDTH_TO_HEIGHT_RATIO);
        this._chartHeight = height - 2; // To leave 2 px for the stroke from the top and from the bottom
        this._svgD3El = this._rootD3El
            .append('svg')
            .attr('width', this._chartWidth)
            .attr('height', height)
            .append('g')
            .attr('transform', 'translate(0,1)');
        this._xScale = scaleTime()
            .range([0, this._chartWidth])
            .domain(extent(this._chartData, function (d) { return d.date; }));
        this._yScale = scaleLinear()
            .range([this._chartHeight, 0])
            .domain([this._chartOptions.yMinValue || 0, this._chartOptions.yMaxValue || 100]);
        this.setHoverBehaviorInsideGraph();
        var valueLine = line()
            .x(function (d) { return _this._xScale(d['date']); })
            .y(function (d) { return _this._yScale(+d['value']); });
        var areaScale = area()
            .x(function (d) { return _this._xScale(d['date']); })
            .y0(this._chartHeight)
            .y1(function (d) { return _this._yScale(+d['value']); });
        this._svgD3El
            .append('path')
            .datum(this._chartData)
            .attr('d', areaScale)
            .attr('class', 'chart-area')
            .style('fill', this._chartColor);
        this._svgD3El
            .append('path')
            .datum(this._chartData)
            .attr('d', valueLine)
            .attr('class', 'chart-line')
            .style('stroke', this._chartColor);
        this._tooltip = this._rootD3El.append('div').attr('class', 'hover-tooltip');
        this._svgD3El
            .append('rect')
            .attr('transform', 'translate(0,1)')
            .attr('class', 'overlay')
            .attr('width', this._chartWidth)
            .attr('height', this._chartHeight)
            .on('mouseout', function () {
            // to wait for event when hovered into the tooltip
            return setTimeout(function () {
                if (!_this._insideTooltip) {
                    // left the component (not inside the event tooltip)
                    _this.stopHoverExperience();
                }
            }, _this.MOUSE_OUT_TIMEOUT);
        })
            .on('mousemove', function (_, j, element) {
            if (_this._insideTooltip) {
                return;
            }
            _this._mouseCoordinates = mouse(element[j]);
            var pointIndex = _this.getHoveredPointIndex(_this._mouseCoordinates[0]);
            var hoveredDataPoint = _this._chartData[pointIndex];
            setTimeout(function () {
                if (!_this._mouseCoordinates) {
                    // To avoid race condition where hovered in event and left the area before timeout
                    return;
                }
                _this.onMouseMoveInEventRectangleHandler(hoveredDataPoint);
            }, _this.SHOW_TOOLTIP_TIMEOUT);
        });
    };
    AreaChartComponent.prototype.getHoveredPointIndex = function (mouseXCoordinate) {
        var hoveredDate = this._xScale.invert(mouseXCoordinate);
        var dateIndex = this.bisectDate(this._chartData, hoveredDate);
        if (dateIndex === this._chartData.length) {
            // overflow from the left
            return dateIndex - 1;
        }
        else if (dateIndex === 0) {
            // overflow from the right
            return 0;
        }
        else {
            var neighborIndex = dateIndex - 1;
            // d0 & d1 are the points closest to the hovered date -> the closest is set into d
            var d0 = this._chartData[dateIndex];
            var d1 = this._chartData[neighborIndex];
            return +hoveredDate - +d0.date > +d1.date - +hoveredDate ? neighborIndex : dateIndex;
        }
    };
    AreaChartComponent.prototype.setDefaultTooltipOpacity = function (opacity) {
        this._tooltip
            .transition()
            .duration(this.OPACITY_TRANSITION_MS)
            .style('opacity', opacity);
    };
    AreaChartComponent.prototype.onMouseMoveInEventRectangleHandler = function (dataPoint) {
        var _this = this;
        this.showHoveredFocusLine(dataPoint);
        var tooltipWidth = this._tooltip.node().getBoundingClientRect().width;
        var tooltipHeight = this._tooltip.node().getBoundingClientRect().height;
        var leftPosition = this.getLeftTooltipPosition(tooltipWidth);
        var bottomPositionCalc = this._rootElementHeight - this._yScale(dataPoint.value);
        var bottomPosition = bottomPositionCalc + tooltipHeight > this._rootElementHeight // Not enough px for the tooltip
            ? this._rootElementHeight - tooltipHeight
            : bottomPositionCalc;
        this._tooltip
            .html(function () { return _this.renderDefaultTooltipHtml(dataPoint); })
            .style('left', leftPosition + 'px')
            .style('bottom', bottomPosition + 'px');
        this.setDefaultTooltipOpacity(1);
    };
    AreaChartComponent.prototype.showHoveredFocusLine = function (d) {
        var hoverLineHeightBelow = this._chartHeight - this._yScale(d.value);
        this._focus
            .select('.hover-line')
            .attr('y2', hoverLineHeightBelow)
            .attr('y1', -this._chartHeight + hoverLineHeightBelow);
        this._focus
            .attr('transform', "translate(" + this._xScale(d.date) + "," + this._yScale(d.value) + ")")
            .style('display', null);
    };
    AreaChartComponent.prototype.getLeftTooltipPosition = function (tooltipWidth) {
        var leftPosition = this._mouseCoordinates[0];
        return this._chartWidth - leftPosition < tooltipWidth
            ? this._chartWidth - tooltipWidth
            : leftPosition;
    };
    AreaChartComponent.prototype.setHoverBehaviorInsideGraph = function () {
        this._focus = this._svgD3El.append('g').attr('class', 'focus');
        this._focus
            .append('line')
            .attr('class', 'hover-line')
            .attr('y1', 0)
            .attr('y2', -this._chartHeight);
        this._focus
            .append('circle')
            .attr('class', 'chart-hover-dot')
            .attr('r', 2)
            .style('fill', this._chartColor);
    };
    AreaChartComponent.prototype.renderDefaultTooltipHtml = function (hoveredPoint) {
        return "<div style=\"padding: 2px 4px ; background: white;box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);\">\n\t\t" + hoveredPoint.value + "</div>";
    };
    return AreaChartComponent;
}());
export { AreaChartComponent };
