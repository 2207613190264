import { ApiCall, ApiCallModel, HttpOptions, ParisConfig } from '@microsoft/paris';
import * as Requests from '../machine-requests.models';
import { MachineRunAntivirusScanRequest } from './machine-antivirus-scan-request.value-object';

export type CancelAntivirusScanRawApiResponse = Requests.CancelApiRequestBody<'ScanRequest'>;
export type CancelAntivirusScanParameters = Requests.CancelParameters;

@ApiCall({
	name: 'Cancel antivirus scan',
	endpoint: 'requests/cancelbyid',
	method: 'POST',
	type: MachineRunAntivirusScanRequest,
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({
		requestGuid,
		comment,
	}: CancelAntivirusScanParameters): HttpOptions<CancelAntivirusScanRawApiResponse> => ({
		params: {
			requestGuid: requestGuid,
			comment: comment,
		},
	}),
})
export class MachineCancelAntivirusScanApiCall extends ApiCallModel<
	MachineRunAntivirusScanRequest,
	CancelAntivirusScanParameters
> {}
