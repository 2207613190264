import { sccHostService } from '@wcd/scc-interface';

/**
 * SCC perf client facade
 */

const wicdPrefix = 'wicd-';
// dummy object to log issues with session end
export const dummyPerf = {
	end: () => {
		sccHostService.log.trackEvent(
			'performance session attempted to end although perf session is not valid'
		);
	},
} as IPerfSession;

export class SccPerfService {
	constructor(private perfSvc) {}
	/**
	 * Create a performance session, by-default indicating an action starts for a component/framework
	 *
	 * @param componentId - The identifer of the component
	 * @param componentType - The type of the component, e.g. Route / Workbench
	 * @param options - The creating options for a performance session
	 * @returns The created performance session
	 */
	createPerformanceSession(
		componentId: string,
		componentType: string,
		options?: IPerfSessionCreateOptions
	): IPerfSession {
		if (!sccHostService.isSCC) {
			return dummyPerf;
		}
		return this.perfSvc.createPerformanceSession(
			wicdPrefix + componentId,
			wicdPrefix + componentType,
			options
		);
	}

	/**
	 * Get a performance session by its componentId, if no corresponding session exists, returns undefined
	 *
	 * @param id - The performance session's Id looking for
	 * @returns The corresponding performance session / undefined if not found
	 */
	getPerformanceSessionById(id: string): IPerfSession | undefined {
		if (!sccHostService.isSCC) {
			return dummyPerf;
		}
		return this.perfSvc.getPerformanceSessionById(wicdPrefix + id);
	}

	cleanUpFinishedSessionFromCache() {
		if (!sccHostService.isSCC) {
			return;
		}
		this.perfSvc.cleanUpFinishedSessionFromCache();
	}

	endPerfSession(perfSession: IPerfSession, options?: IPerfSessionEndOptions) {
		if (perfSession && perfSession.end && typeof perfSession.end === 'function') {
			//@ts-ignore
			return perfSession.end(options);
		}
		sccHostService.log.trackEvent(
			'performance session attempted to end although perf session is not valid'
		);
	}
}

/**
 * The options interface for createPerformanceSession API
 */
export interface IPerfSessionCreateOptions extends IPerfSessionOptionsBase {
	/**
	 * Indicate if the component is a container and blocking user actions, mark as true e.g. Workbench / Dashboard
	 * Default value: false
	 */
	isContainer?: boolean;

	/**
	 * Indicate if the start session will be called separately, instead of starting it immediately
	 * If useParentEndAsStart is set to true delayed start is ignored.
	 * When true: The created perf session won't be started immediately, the session.start() needs to be called later
	 * When false: The created perf session will be started immediately, do not call the session.start() separately
	 * Default value: false
	 */
	delayStart?: boolean;

	/**
	 * Indicate if we want to use the Parent session end marker as start markert for the Current session
	 * Usefull In case of Sequential Blocking Events.
	 * When true: Parent Session End Marker is treated as a start marker for the Current Session, If True delayedStart will be ignored.
	 * When false: The created perf session will create it's unique start marker.
	 * Default value: false
	 */
	useParentEndAsStart?: boolean;

	/**
	 * Provide a customized actionName for a performance session, indicating an customized action for a component/framework
	 * Will be used in conjuction with componentId from Create Session to uniquely identify session Actions
	 * eg ListChartLoad, WizardSubmit
	 */
	action?: string;

	/**
	 * This is a special option for cases like workbench, that:
	 *  1. The component itself have 2 levels (Workbench 1:N Workbench views)
	 *  2. When user switches between sublevel components (Workbench views), the perf context switches along with it, stop/cancel all belonging perf sessions under the super level
	 */
	parentSessionId?: string;
}

/**
 * The base options interface for perf APIs
 */
export interface IPerfSessionOptionsBase {
	// Customized properties to mark, e.g. Cached: false, etc.
	customProps?: any;
}

/**
 * A performance session with a log entry
 */
export interface IPerfSession {
	/**
	 * Start a performance session, indicating an action starts for a component/framework
	 *
	 * @param options - The start options for a performance session
	 */
	start(options?: IPerfSessionStartOptions): void;

	/**
	 * Provide a customized action for a performance session, indicating an customized action for a component/framework
	 *
	 * @param actionName - The name of the custom action, e.g. startDownloadResource, gotResponse
	 * @param options - The options for a performance session customed action
	 */
	custom(actionName: string, options?: IPerfSessionCustomOptions): void;

	/**
	 * End the performance session
	 *
	 * @param options - The end options for a performance session
	 */
	end(options?: IPerfSessionEndOptions): void;

	/**
	 * Try to remove current perf session from perf context
	 * This will cause all belonging sessions be stopped or cancelled
	 */
	removeFromContext(): void;

	cancel(): void;
}

/**
 * The options interface for perfSession.start() API
 */
export interface IPerfSessionStartOptions extends IPerfSessionOptionsBase {}

/**
 * The options interface for perfSession.custom() API
 */
export interface IPerfSessionCustomOptions extends IPerfSessionOptionsBase {}

/**
 * The options interface for perfSession.end() API
 */
export interface IPerfSessionEndOptions extends IPerfSessionOptionsBase {
	/**
	 * If set to true, when this session end was called, also remove the logEntry from perf context
	 */
	removeFromContext?: boolean;
}
