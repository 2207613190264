<!-- tslint:disable:template-accessibility-label-for -->
<form
	name="systemExclusionForm"
	#systemExclusionForm="ngForm"
	novalidate="novalidate"
	data-track-component="SystemExclusionForm"
	class="wcd-flex-vertical"
>
	<div class="dialog-contents wcd-border-bottom wcd-margin-bottom">
		<div class="small-form dialog-controls">
			<div class="dialog-control form-group wcd-flex-horizontal">
				<label>{{ i18nService.strings.systemExclusions_exclusionType }}</label>
				<fancy-select
					[(ngModel)]="systemExclusion.listType"
					[isBordered]="true"
					labelClass="dropdown-width-medium"
					name="systemExclusionListType"
					[label]="'name'"
					[itemTestId]="'name'"
					[trackBy]="'id'"
					[isDisabled]="systemExclusionType.readonly || readonly"
					[wcdTooltip]="readonly && disableReason"
					[values]="systemExclusionListTypes"
				>
				</fancy-select>
			</div>
			<div class="dialog-control form-group wcd-flex-horizontal">
				<p class="dialog-control-description subtle" style="max-width:480px;">
					{{ systemExclusion.listType.helpKey | i18n }}
				</p>
			</div>
		</div>
	</div>
	<div class="dialog-contents wcd-border-bottom wcd-margin-bottom">
		<div class="small-form dialog-controls">
			<div *ngIf="systemExclusionType.file; else notFile" class="dialog-control form-group">
				<p>{{ systemExclusionType.file.placeholder }}</p>
				<input
					type="file"
					id="systemExclusion_file"
					name="systemExclusionFile"
					[disabled]="readonly"
					(change)="onFileSelect($event, systemExclusionForm)"
				/>
			</div>
			<ng-template #notFile>
				<div *ngFor="let property of editableProperties" class="form-group wcd-flex-horizontal">
					<label for="exclusion_{{ property.id }}">
						{{ property.name }}
						<span [hidden]="!property.isRequired"> *</span>
						<wcd-help
							*ngIf="property.descriptionKey"
							[text]="property.descriptionKey | i18n"
						></wcd-help>
					</label>
					<div class="form-control-wrapper" [wcdTooltip]="readonly && disableReason">
						<input
							id="exclusion_{{ property.id }}"
							class="form-control"
							type="text"
							name="exclusion_{{ property.id }}"
							#systemExclusionInput="ngModel"
							placeholder="{{ property.placeholder }}"
							[(ngModel)]="systemExclusion.properties[property.id]"
							[pattern]="RegExpService[property.pattern]"
							[disabled]="systemExclusionType.readonly || readonly"
							[required]="property.isRequired"
						/>
						<div
							class="form-error"
							*ngIf="systemExclusionInput.dirty && !systemExclusionInput.valid"
						>
							{{ property.patternError }}
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
	<div *ngIf="!(systemExclusionType.readonly || readonly)" class="wcd-flex-none dialog-footer buttons-bar">
		<button
			type="button"
			class="btn btn-primary"
			data-track-id="Save"
			data-track-type="Button"
			(click)="save()"
			[disabled]="
				(systemExclusionForm.form.pristine && !formIsDirtyOnInit) || systemExclusionForm.form.invalid
			"
		>
			<i class="loader-icon" [hidden]="!isSaving"></i>
			{{
				isSaving
					? i18nService.strings.systemExclusions_pleaseWait
					: systemExclusion.isNew
					? i18nService.strings.systemExclusions_editPanel_addRule
					: i18nService.strings.systemExclusions_editPanel_updateRule
			}}
		</button>
		<button
			type="button"
			class="btn btn-cancel"
			data-track-id="Cancel"
			data-track-type="Button"
			(click)="cancelEdit()"
		>
			{{ i18nService.strings.buttons_cancel }}
		</button>
	</div>
	<div *ngIf="error" class="error-message wcd-padding-small-top">
		{{ error }}
	</div>
</form>
