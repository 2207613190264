import { I18nService } from '@wcd/i18n';
import {
	AlertCategoryType,
	alertCategoryValues,
	AlertClassificationAndCombinedDeterminationKeyType,
	AlertClassificationAndDeterminationKeyType,
	AlertClassificationType,
	AlertDetectionSourceType,
	AlertDeterminationType,
	AlertSeverityType,
	AlertStatusReportType,	
	combinedAlertDeterminationType,	
	SeverityType,
} from '@wcd/domain';
import { Injectable } from '@angular/core';
import { ReportMapping } from '../reporting/threat-protection2/threat-protection-report.model';
import { SeverityTypeColorService } from '../shared/services/severity-type-color.service';
import { KnownColorsService } from '../shared/services/known-colors.service';
import { Feature, FeaturesService } from '@wcd/config';

@Injectable({
	providedIn: 'root',
})
export class ThreatProtectionStatusMapping {
	isCombinedClassificationEnabled: boolean = false;
	private readonly _severityMapping: ReportMapping<AlertSeverityType> = {
		[AlertSeverityType.High]: {
			knownColorsMap: {
				daily: this.severityTypeColorService.colorsMap.get(SeverityType.High),
				trend: this.severityTypeColorService.colorsMap.get(SeverityType.High),
			},
			label: this.i18nService.get(`alert.severity.high`),
			alertListFilter: 'High',
			priority: 1,
		},
		[AlertSeverityType.Medium]: {
			knownColorsMap: {
				daily: this.severityTypeColorService.colorsMap.get(SeverityType.Medium),
				trend: this.severityTypeColorService.colorsMap.get(SeverityType.Medium),
			},
			label: this.i18nService.get(`alert.severity.medium`),
			alertListFilter: 'Medium',
			priority: 2,
		},
		[AlertSeverityType.Low]: {
			knownColorsMap: {
				daily: this.severityTypeColorService.colorsMap.get(SeverityType.Low),
				trend: this.severityTypeColorService.colorsMap.get(SeverityType.Low),
			},
			label: this.i18nService.get(`alert.severity.low`),
			alertListFilter: 'Low',
			priority: 3,
		},
		[AlertSeverityType.Informational]: {
			knownColorsMap: {
				daily: this.severityTypeColorService.colorsMap.get(SeverityType.Informational),
				trend: this.severityTypeColorService.colorsMap.get(SeverityType.Informational),
			},
			label: this.i18nService.get(`alert.severity.informational`),
			alertListFilter: 'Informational',
			priority: 4,
		},
	};

	private readonly _detectionSourceMapping: ReportMapping<AlertDetectionSourceType> = {
		[AlertDetectionSourceType.EDR]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['magenta'],
			},
			label: this.i18nService.get(`reporting.alertsByDetectionSource.edr`),
			alertListFilter: 'WindowsDefenderAtp',
			priority: 1,
		},
		[AlertDetectionSourceType.Antivirus]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['tealLight'],
			},
			label: this.i18nService.get(`reporting.alertsByDetectionSource.antivirus`),
			alertListFilter: 'WindowsDefenderAv',
			priority: 2,
		},
		[AlertDetectionSourceType.SmartScreen]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['purpleLight'],
			},
			label: this.i18nService.get(`reporting.alertsByDetectionSource.smartScreen`),
			alertListFilter: 'WindowsDefenderSmartScreen',
			priority: 3,
		},
		[AlertDetectionSourceType.CustomTI]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['purple'],
			},
			label: this.i18nService.get(`reporting.alertsByDetectionSource.customTi`),
			alertListFilter: 'CustomerTI',
			priority: 5,
		},
		[AlertDetectionSourceType.OfficeATP]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['orangeLighter'],
			},
			label: this.i18nService.get(`reporting.alertsByDetectionSource.officeAtp`),
			alertListFilter: 'OfficeATP',
			priority: 6,
		},
		[AlertDetectionSourceType.AutomatedInvestigation]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['greenDark'],
			},
			label: this.i18nService.get(`reporting.alertsByDetectionSource.automatedInvestigation`),
			alertListFilter: 'AutomatedInvestigation',
			priority: 7,
		},
		[AlertDetectionSourceType.ThreatExperts]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['neutralSecondary'],
			},
			label: this.i18nService.get(`reporting.alertsByDetectionSource.threatExperts`),
			alertListFilter: 'ThreatExperts',
			priority: 8,
		},
		[AlertDetectionSourceType.CustomDetection]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['green'],
			},
			label: this.i18nService.get(`reporting.alertsByDetectionSource.customDetection`),
			alertListFilter: 'CustomDetection',
			priority: 9,
		},
		[AlertDetectionSourceType.ThirdPartySensors]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['magentaLight'],
			},
			label: this.i18nService.get(`reporting.alertsByDetectionSource.thirdPartySensors`),
			alertListFilter: 'ThirdPartySensors',
			priority: 12,
		},
	};

	private readonly _categoryMapping: ReportMapping<AlertCategoryType> = {
		[AlertCategoryType.CommandAndControl]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['magenta'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.CommandAndControl).type
				}`
			),
			alertListFilter: 'commandAndControl',
		},
		[AlertCategoryType.LateralMovement]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['tealLight'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.LateralMovement).type
				}`
			),
			alertListFilter: 'lateralMovement',
		},
		[AlertCategoryType.Malware]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['orangeLighter'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.Malware).type
				}`
			),
			alertListFilter: 'malware',
		},
		[AlertCategoryType.Persistence]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['neutralSecondary'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.Persistence).type
				}`
			),
			alertListFilter: 'persistence',
		},
		[AlertCategoryType.PrivilegeEscalation]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['green'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.PrivilegeEscalation).type
				}`
			),
			alertListFilter: 'privilegeEscalation',
		},
		[AlertCategoryType.Ransomware]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['orange'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.Ransomware).type
				}`
			),
			alertListFilter: 'ransomware',
		},
		[AlertCategoryType.SuspiciousActivity]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['orange'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.SuspiciousActivity).type
				}`
			),
			alertListFilter: 'suspiciousActivity',
		},
		[AlertCategoryType.UnwantedSoftware]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['magentaLight'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.UnwantedSoftware).type
				}`
			),
			alertListFilter: 'unwantedSoftware',
		},
		[AlertCategoryType.Exploit]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['magentaLight'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.Exploit).type
				}`
			),
			alertListFilter: 'exploit',
		},
		[AlertCategoryType.InitialAccess]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['orange'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.InitialAccess).type
				}`
			),
			alertListFilter: 'initialAccess',
		},
		[AlertCategoryType.Execution]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['teal'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.Execution).type
				}`
			),
			alertListFilter: 'execution',
		},
		[AlertCategoryType.Exfiltration]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['magentaLight'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.Exfiltration).type
				}`
			),
			alertListFilter: 'exfiltration',
		},
		[AlertCategoryType.Collection]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['orange'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.Collection).type
				}`
			),
			alertListFilter: 'collection',
		},
		[AlertCategoryType.CredentialAccess]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['teal'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.CredentialAccess).type
				}`
			),
			alertListFilter: 'credentialAccess',
		},
		[AlertCategoryType.DefenseEvasion]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['magentaLight'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.DefenseEvasion).type
				}`
			),
			alertListFilter: 'defenseEvasion',
		},
		[AlertCategoryType.Discovery]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['magentaLight'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.Discovery).type
				}`
			),
			alertListFilter: 'discovery',
		},
		[AlertCategoryType.Impact]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['blue'],
				trend: this.knownColorsService.knownColorsMap['magentaLight'],
			},
			label: this.i18nService.get(
				`reporting.alertsByCategory.${
					alertCategoryValues.find(a => a.name === AlertCategoryType.Impact).type
				}`
			),
			alertListFilter: 'impact',
		},
	};

	private readonly _statusMapping: ReportMapping<AlertStatusReportType> = {
		[AlertStatusReportType.New]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['purpleLight'],
				trend: this.knownColorsService.knownColorsMap['purpleLight'],
			},
			label: this.i18nService.get(`reporting.alertsByStatus.new`),
			alertListFilter: 'New',
			priority: 1,
		},
		[AlertStatusReportType.InProgress]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['orangeLighter'],
				trend: this.knownColorsService.knownColorsMap['orangeLighter'],
			},
			label: this.i18nService.get(`reporting.alertsByStatus.inProgress`),
			alertListFilter: 'InProgress',
			priority: 2,
			hiddenOnTrend: true,
		},
		[AlertStatusReportType.Resolved]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['greenLight'],
				trend: this.knownColorsService.knownColorsMap['greenLight'],
			},
			label: this.i18nService.get(`reporting.alertsByStatus.resolved`),
			alertListFilter: 'Resolved',
			priority: 3,
			hiddenOnDaily: true,
		},
	};

	private readonly _classificationAndDeterminationMapping: ReportMapping<
		AlertClassificationAndDeterminationKeyType
	> = {
		[AlertClassificationType.NotSet]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.unknown`),
			alertListFilter: '',
			priority: 1,
			hiddenOnDaily: true,
			hiddenOnTrend: true,
		},
		[AlertClassificationType.Unknown]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.unknown`),
			alertListFilter: '',
			priority: 1,
			hiddenOnDaily: true,
			hiddenOnTrend: true,
		},
		[AlertClassificationType.FalsePositive]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.falseAlert`),
			alertListFilter: 'FalsePositive',
			priority: 2,
		},
		[AlertClassificationType.TruePositive]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.trueAlert`),
			alertListFilter: 'TruePositive',
			priority: 3,
			hiddenOnDaily: true,
			hiddenOnTrend: true,
		},
		[AlertClassificationType.BenignPositive]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
			},
			label: this.i18nService.get(`alertsByClassification.BenignPositive`),
			alertListFilter: 'BenignPositive',
			priority: 4,
			hiddenOnDaily: true,
			hiddenOnTrend: true,
		},
		[AlertDeterminationType.Apt]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['blueMid'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.apt`),
			alertListFilter: 'atp',
			priority: 5,
		},
		[AlertDeterminationType.Malware]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['teal'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.malware`),
			alertListFilter: 'malware',
			priority: 6,
		},
		[AlertDeterminationType.Other]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['magentaLight'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.other`),
			alertListFilter: 'other',
			priority: 7,
		},
		[AlertDeterminationType.SecurityPersonnel]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['magentaDark'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.securityPersonnel`),
			alertListFilter: 'securityPersonnel',
			priority: 8,
		},
		[AlertDeterminationType.SecurityTesting]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['purpleLight'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.securityTesting`),
			alertListFilter: 'securityTesting',
			priority: 9,
		},
		[AlertDeterminationType.UnwantedSoftware]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['blueDark'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.unwantedSoftware`),
			alertListFilter: 'unwantedSoftware',
			priority: 10,
		},		
	};

	private readonly _classificationAndCombinedDeterminationMapping: ReportMapping<
		AlertClassificationAndCombinedDeterminationKeyType
	> = {
		[AlertClassificationType.NotSet]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.unknown`),
			alertListFilter: '',
			priority: 1,
			hiddenOnDaily: true,
			hiddenOnTrend: true,
		},
		[AlertClassificationType.Unknown]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.unknown`),
			alertListFilter: '',
			priority: 2,
			hiddenOnDaily: true,
			hiddenOnTrend: true,
		},
		[AlertClassificationType.FalsePositive]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.falseAlert`),
			alertListFilter: 'FalsePositive',
			priority: 3,
		},
		[AlertClassificationType.TruePositive]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.trueAlert`),
			alertListFilter: 'TruePositive',
			priority: 4,
			hiddenOnDaily: true,
			hiddenOnTrend: true,
		},
		[AlertClassificationType.BenignPositive]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
			},
			label: this.i18nService.get(`alertsByClassification.BenignPositive`),
			alertListFilter: 'BenignPositive',
			priority: 5,
			hiddenOnDaily: true,
			hiddenOnTrend: true,
		},
		[combinedAlertDeterminationType.MultiStagedAttack]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['lowSeverity'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.multiStagedAttack`),
			alertListFilter: 'multiStagedAttack',
			priority: 6,
		},
		[combinedAlertDeterminationType.Malware]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['mediumSeverity'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.malware`),
			alertListFilter: 'malware',
			priority: 7,
		},
		[combinedAlertDeterminationType.MaliciousUserActivity]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['highSeverity'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.maliciousUserActivity`),
			alertListFilter: 'maliciousUserActivity',
			priority: 8,
		},
		[combinedAlertDeterminationType.UnwantedSoftware]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['criticalStatus'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.unwantedSoftware`),
			alertListFilter: 'unwantedSoftware',
			priority: 9,
		},
		[combinedAlertDeterminationType.Phishing]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['dataVis9'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.phishing`),
			alertListFilter: 'phishing',
			priority: 10,
		},
		[combinedAlertDeterminationType.CompromisedUser]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['dataVis7'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.compromisedUser`),
			alertListFilter: 'compromisedUser',
			priority: 11,
		},		
		[combinedAlertDeterminationType.Apt]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['magentaDark'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.apt`),
			alertListFilter: 'apt',
			priority: 12,
		},
		[combinedAlertDeterminationType.Other]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['dataVis6'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.other`),
			alertListFilter: 'other',
			priority: 13,
		},
		[combinedAlertDeterminationType.SecurityTesting]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['themeDark'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.securityTesting`),
			alertListFilter: 'securityTesting',
			priority: 14,
		},
		[combinedAlertDeterminationType.ConfirmedUserActivity]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['themeDarkAlt'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.confirmedUserActivity`),
			alertListFilter: 'confirmedUserActivity',
			priority: 15,
		},
		[combinedAlertDeterminationType.LineOfBusinessApplication]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['themeSecondary'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.lineOfBusinessApplication`),
			alertListFilter: 'lineOfBusinessApplication',
			priority: 16,
		},
		[combinedAlertDeterminationType.SecurityPersonnel]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['blueMid'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.securityPersonnel`),
			alertListFilter: 'securityPersonnel',
			priority: 17,
		},
		[combinedAlertDeterminationType.Clean]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['green'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.clean`),
			alertListFilter: 'clean',
			priority: 18,
		},
		[combinedAlertDeterminationType.InsufficientData]: {
			knownColorsMap: {
				daily: this.knownColorsService.knownColorsMap['tealLight'],
				trend: this.knownColorsService.knownColorsMap['needHelpBackgroundPressed'],
			},
			label: this.i18nService.get(`reporting.alertsByClassification.insufficientData`),
			alertListFilter: 'insufficientData',
			priority: 19,
		},		
	};

	get severityMapping(): DeepReadonly<ReportMapping<AlertSeverityType>> {
		return this._severityMapping;
	}

	get detectionSourceMapping(): DeepReadonly<ReportMapping<AlertDetectionSourceType>> {
		return this._detectionSourceMapping;
	}

	get categoryMapping(): DeepReadonly<ReportMapping<AlertCategoryType>> {
		return this._categoryMapping;
	}

	get statusMapping(): DeepReadonly<ReportMapping<AlertStatusReportType>> {
		return this._statusMapping;
	}

	get classificationAndDeterminationMapping(): DeepReadonly<ReportMapping<AlertClassificationType>> {		
		return this.isCombinedClassificationEnabled ?
			this._classificationAndCombinedDeterminationMapping : this._classificationAndDeterminationMapping;			
	}

	constructor(
		private i18nService: I18nService,
		private knownColorsService: KnownColorsService,
		private severityTypeColorService: SeverityTypeColorService,
		private readonly featureService: FeaturesService
	) {
		this.isCombinedClassificationEnabled = this.featureService.isEnabled(Feature.CombinedClassification);
	}
}

type DeepReadonly<T> = { readonly [P in keyof T]: DeepReadonly<T[P]> };
