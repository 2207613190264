import { ApiCallModel, ApiCall } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { MachineProtection } from './machine-protection.value-object';
import { MachineProtectionReportOptions } from './machine-protection-options.interface';

@ApiCall({
	name: 'Machines health',
	endpoint: 'reports/machineHealth/healthStatus',
	method: 'GET',
	type: MachineProtection,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
	parseQuery: (options: MachineProtectionReportOptions) => {
		return { params: options };
	},
})
export class MachineHealthStatusApiCall extends ApiCallModel<
	MachineProtection,
	MachineProtectionReportOptions
> {}
