/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./collapsible-section.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./expander.component.ngfactory";
import * as i3 from "./expander.component";
import * as i4 from "../../../config/src/lib/services/preferences.service";
import * as i5 from "./collapsible-section.component";
var styles_CollapsibleSectionComponent = [i0.styles];
var RenderType_CollapsibleSectionComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CollapsibleSectionComponent, data: {} });
export { RenderType_CollapsibleSectionComponent as RenderType_CollapsibleSectionComponent };
export function View_CollapsibleSectionComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 7, "section", [["class", "collapsible-section"]], [[2, "no-side-margin", null], [2, "no-border", null], [2, "no-padding-bottom", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "wcd-expander", [["buttonRole", "button"]], null, [[null, "isExpandedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isExpandedChange" === en)) {
        var pd_0 = (_co.isExpandedChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ExpanderComponent_0, i2.RenderType_ExpanderComponent)), i1.ɵdid(4, 114688, null, 0, i3.ExpanderComponent, [i4.PreferencesService], { label: [0, "label"], isExpanded: [1, "isExpanded"], arrowPosition: [2, "arrowPosition"], buttonRole: [3, "buttonRole"], sectionId: [4, "sectionId"] }, { isExpandedChange: "isExpandedChange" }), (_l()(), i1.ɵted(-1, 1, ["\n\t\t\t\t"])), i1.ɵncd(1, 0), (_l()(), i1.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.label; var currVal_4 = _co.isExpanded; var currVal_5 = _co.arrowPosition; var currVal_6 = "button"; var currVal_7 = _co.sectionId; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noSideMargins; var currVal_1 = !_co.showTopBorder; var currVal_2 = _co.noBottomPadding; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_CollapsibleSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-collapsible-section", [], null, null, null, View_CollapsibleSectionComponent_0, RenderType_CollapsibleSectionComponent)), i1.ɵdid(1, 49152, null, 0, i5.CollapsibleSectionComponent, [], null, null)], null, null); }
var CollapsibleSectionComponentNgFactory = i1.ɵccf("wcd-collapsible-section", i5.CollapsibleSectionComponent, View_CollapsibleSectionComponent_Host_0, { label: "label", arrowPosition: "arrowPosition", isExpanded: "isExpanded", showTopBorder: "showTopBorder", sectionId: "sectionId", noSideMargins: "noSideMargins", noBottomPadding: "noBottomPadding" }, { isExpandedChange: "isExpandedChange" }, ["*"]);
export { CollapsibleSectionComponentNgFactory as CollapsibleSectionComponentNgFactory };
