import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { UserAssistanceData, userAssistanceParseQuery } from './user-assistance.api-call.base';

@ApiCall({
	name: 'Request researcher assistance',
	endpoint: 'ThreatExperts/CreateTicket',
	method: 'POST',
	timeout: 60000,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (data: UserAssistanceData) => ({
		data: userAssistanceParseQuery(data),
	}),
	parseData: (data: { Id: number }) => data.Id,
})
export class ResearcherAssistanceApiCall extends ApiCallModel<number, UserAssistanceData> {}
