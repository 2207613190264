var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { compact } from 'lodash-es';
import { dateStrToUtc } from '../utils';
import { sccHostService } from '@wcd/scc-interface';
var ɵ0 = function (params) {
    var filterStrs = compact([
        params.networkMessageId ? "NetworkMessageId eq '" + params.networkMessageId + "'" : null,
        params.recipient ? "Recipients.LowercaseAnalyzed eq '" + params.recipient.toLowerCase() + "'" : null,
        'ContentType eq 1',
        params.dlExpansionHistory ? "DLExpansionHistory.LowercaseAnalyzed eq '" + params.dlExpansionHistory.toLowerCase() + "'" : null,
    ]).join(' and ');
    return {
        params: {
            Filter: filterStrs,
            tenantId: params.tenantId,
            startTime: params.startTime && params.startTime.toISOString(),
            endTime: params.endTime && params.endTime.toISOString(),
        },
    };
}, ɵ1 = function (data, params) {
    return ((data && data.ResultData) || [])
        .map(function (r) { return JSON.parse(r); })
        .filter(function (m) {
        return !params.recipient ||
            m.Recipients.some(function (r) { return r.toLowerCase() === params.recipient.toLowerCase(); });
    })
        .map(function (r) {
        var receivedTime = r.ReceivedTime || '';
        receivedTime = dateStrToUtc(receivedTime);
        receivedTime = receivedTime ? new Date(receivedTime) : null;
        var deliveryLocation = r && (r.CurrentDeliveryLocationCode || r.DeliveryLocationCode);
        var deliveredToMailbox = deliveryLocation &&
            [
                DeliveryLocation.Inbox,
                DeliveryLocation.JunkFolder,
                DeliveryLocation.DeletedFolder,
            ].includes(+deliveryLocation);
        var detectionMethodsI18nKeys = r && r.ThreatDetectionMethods;
        // note that 'AntispamDirection' is a number
        var directionalityI18nKey = (r && r.AntispamDirection && AntispamMailDirection[r.AntispamDirection]) || '';
        var potentiallyHarmfulUrls = new Map();
        r.XmiInfo.MalwareUrlsDetected &&
            r.XmiInfo.MalwareUrlsDetected.forEach(function (url) {
                return potentiallyHarmfulUrls.set(url, UrlThreatType.Malware);
            });
        r.XmiInfo.PhishUrlsDetected &&
            r.XmiInfo.PhishUrlsDetected.forEach(function (url) {
                return potentiallyHarmfulUrls.set(url, UrlThreatType.Phish);
            });
        r.XmiInfo.SpamUrlsDetected &&
            r.XmiInfo.SpamUrlsDetected.forEach(function (url) {
                return potentiallyHarmfulUrls.set(url, UrlThreatType.Spam);
            });
        return {
            internetMessageId: r.InternetMessageId,
            receivedTime: receivedTime,
            currentDeliveryLocationCode: r.CurrentDeliveryLocationCode,
            latestDeliveryLocation: DeliveryLocation[r.CurrentDeliveryLocationCode],
            deliveryLocationCode: r.DeliveryLocationCode,
            originalDeliveryLocation: DeliveryLocation[r.DeliveryLocationCode],
            recipients: r.Recipients,
            subject: r.Subject,
            deliveredToMailbox: !!deliveredToMailbox,
            messageActionCode: r.MessageActionCode,
            isDelivered: !!(r.MessageActionCode && +r.MessageActionCode === ProtectionStatus.Delivered),
            detectionMethodsI18nKeys: detectionMethodsI18nKeys,
            directionalityI18nKey: directionalityI18nKey,
            returnPath: r.P1Sender,
            potentiallyHarmfulUrls: potentiallyHarmfulUrls,
            // This relates to the URL threat detection technologies
            threatFilterControls: {
                spam: r.XmiInfo.SpamFilterControl,
                malware: r.XmiInfo.MalwareFilterControl,
                phish: r.XmiInfo.PhishFilterControl,
            },
        };
    });
};
var EmailMetadataApiCall = /** @class */ (function (_super) {
    __extends(EmailMetadataApiCall, _super);
    function EmailMetadataApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EmailMetadataApiCall = __decorate([
        ApiCall({
            name: 'Email metadata',
            endpoint: 'Find/MailMetaData',
            baseUrl: sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>',
            parseQuery: ɵ0,
            parse: ɵ1,
            cache: {
                time: 1000 * 60 * 10,
                max: 10,
            },
        })
    ], EmailMetadataApiCall);
    return EmailMetadataApiCall;
}(ApiCallModel));
export { EmailMetadataApiCall };
var DeliveryLocation;
(function (DeliveryLocation) {
    DeliveryLocation[DeliveryLocation["Unknown"] = 0] = "Unknown";
    DeliveryLocation[DeliveryLocation["Inbox"] = 1] = "Inbox";
    DeliveryLocation[DeliveryLocation["JunkFolder"] = 2] = "JunkFolder";
    DeliveryLocation[DeliveryLocation["DeletedFolder"] = 3] = "DeletedFolder";
    DeliveryLocation[DeliveryLocation["Quarantine"] = 4] = "Quarantine";
    DeliveryLocation[DeliveryLocation["External"] = 5] = "External";
    DeliveryLocation[DeliveryLocation["Failed"] = 6] = "Failed";
    DeliveryLocation[DeliveryLocation["Dropped"] = 7] = "Dropped";
    DeliveryLocation[DeliveryLocation["Forwarded"] = 8] = "Forwarded";
})(DeliveryLocation || (DeliveryLocation = {}));
var ProtectionStatus;
(function (ProtectionStatus) {
    ProtectionStatus[ProtectionStatus["Unknown"] = 0] = "Unknown";
    ProtectionStatus[ProtectionStatus["DeliveredAsSpam"] = 1] = "DeliveredAsSpam";
    ProtectionStatus[ProtectionStatus["Delivered"] = 2] = "Delivered";
    ProtectionStatus[ProtectionStatus["Blocked"] = 3] = "Blocked";
    ProtectionStatus[ProtectionStatus["Replaced"] = 4] = "Replaced";
    ProtectionStatus[ProtectionStatus["TimeTraveled"] = 99] = "TimeTraveled";
})(ProtectionStatus || (ProtectionStatus = {}));
var AntispamMailDirection;
(function (AntispamMailDirection) {
    // Unknown enumeration value
    AntispamMailDirection[AntispamMailDirection["unknown"] = 0] = "unknown";
    // Inbound mail
    AntispamMailDirection[AntispamMailDirection["inbound"] = 1] = "inbound";
    // Outbound mail
    AntispamMailDirection[AntispamMailDirection["outbound"] = 2] = "outbound";
    // Internal mail
    AntispamMailDirection[AntispamMailDirection["intraorg"] = 3] = "intraorg";
})(AntispamMailDirection || (AntispamMailDirection = {}));
export var UrlThreatType;
(function (UrlThreatType) {
    UrlThreatType[UrlThreatType["Spam"] = 0] = "Spam";
    UrlThreatType[UrlThreatType["Phish"] = 1] = "Phish";
    UrlThreatType[UrlThreatType["Malware"] = 2] = "Malware";
})(UrlThreatType || (UrlThreatType = {}));
export { ɵ0, ɵ1 };
