import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('date', dateDirective).controller('dateController', dateController);

dateDirective.$inject = ['$filter', '$parse'];

function dateDirective($filter, $parse) {
	return {
		restrict: 'EA',
		scope: {
			dateValue: '=?',
			type: '=?',
		},
		link: function(scope, element, attrs) {
			// link function remained for backward compatibility. if no datevalue attribute set - define the element text by the src attribute
			if (!scope.dateValue) {
				var elem = $(element);
				var src = $parse(attrs.src)(scope.$parent);
				if (!src) {
					return;
				}

				var type = elem.attr('type');
				if (!type) {
					type = 'full';
				}

				elem.text($filter('sevilleDate')(src, type));
			}
		},
		template: '<div ng-if="$ctrl.parsedDateValue">{{$ctrl.parsedDateValue}}</div>',
		controllerAs: '$ctrl',
		controller: 'dateController',
	};
}

/*
	This directive was originaly created with no controller - causing that updating the 'src' attribute (if binded to a value that may change)
	caused no change in appearance of this directive - the text defined only when the link function run at the initialization of this directive.
	the controller and the 'dateValue' param was added later to support and present correctly date values that may change
*/
dateController.$inject = ['$filter', '$scope'];
function dateController($filter, $scope) {
	var vm = this;
	vm.dateValue = $scope.dateValue;
	vm.type = $scope.type || 'full';
	if (vm.dateValue) {
		vm.parsedDateValue = $filter('sevilleDate')(vm.dateValue, vm.type);
	}

	$scope.$watch(
		function() {
			return $scope.dateValue;
		},
		// follow input changes and present them with our filter
		function(newValue, oldValue) {
			if (newValue && oldValue !== newValue) {
				vm.parsedDateValue = $filter('sevilleDate')(newValue, vm.type);
			}
		}
	);
}
