var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { File } from '@wcd/domain';
var FileDownloadSamplePanelComponent = /** @class */ (function (_super) {
    __extends(FileDownloadSamplePanelComponent, _super);
    function FileDownloadSamplePanelComponent(router) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.onDownloadSample = new EventEmitter();
        _this.onDestroyPanel = new EventEmitter();
        _this.downloadPressed = false;
        return _this;
    }
    FileDownloadSamplePanelComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.onDestroyPanel.emit(this.downloadPressed);
    };
    FileDownloadSamplePanelComponent.prototype.isValid = function () {
        return this.zipPassword && this.sampleDownloadReason;
    };
    FileDownloadSamplePanelComponent.prototype.downloadSample = function (event) {
        event.preventDefault();
        this.onDownloadSample.emit({ downloadReason: this.sampleDownloadReason, password: this.zipPassword });
        this.downloadPressed = true;
        this.closePanel();
    };
    return FileDownloadSamplePanelComponent;
}(PanelContainer));
export { FileDownloadSamplePanelComponent };
