<!-- tslint:disable:template-accessibility-label-for -->
<wizard-panel
	[panelSettings]="settings"
	[currentStep]="currentStep"
	[stepsData]="stepsData"
	[wizardTitle]="'hunting.scheduledMonitorSidePane.title.' + (createMonitorMode ? 'new' : 'edit') | i18n"
	[onBackPressed]="previousStep.bind(this)"
	[onNextPressed]="nextStep.bind(this)"
	[onClosePressed]="onClosePanel.bind(this)"
	[isNextEnabled]="isNextButtonEnabled"
	[isLoading]="isSaving"
	class="wcd-input-placeholder wcd-textarea-placeholder"
>
	<form #alertDetailsStepForm="ngForm" *ngIf="currentStep.id === WizardStepId.AlertDetails" class="wcd-flex-1">
		<h3 class="wizard-section-title">{{ "hunting.scheduledMonitorSidePane.title.subTitle" | i18n}}</h3>
		<p class="wcd-margin-vertical">
			{{ "hunting.scheduledMonitorSidePane.title.description" | i18n}}
		</p>
		<div class="wcd-flex-1 wcd-padding-vertical">
			<div class="form-group">
				<label class="required">{{'hunting.scheduledMonitorSidePane.sections.detectionsName' | i18n}}</label>
				<input name="huntingRuleName"
					   [(ngModel)]="huntingRule.name"
					   class="form-control"
					   required
					   autofocus
					   placeholder="{{'hunting.scheduledMonitorSidePane.sections.detectionsName' | i18n}}"
					   [attr.aria-label]="'hunting.scheduledMonitorSidePane.sections.detectionsName' | i18n"
				/>
			</div>

			<div class="form-group">
				<div class="wcd-full-width">
					<label class="required">{{ "hunting.scheduledMonitorSidePane.fields.huntingRuleFrequency.title" | i18n}}</label>
					<wcd-help text="{{'hunting.scheduledMonitorSidePane.fields.huntingRuleFrequency.tooltip' | i18n}}"></wcd-help>
				</div>

				<fancy-select [(ngModel)]="selectedFrequency"
							  listId="rule-frequency"
							  name="ruleFrequency"
							  [isBordered]="true"
							  labelClass="dropdown-width-medium"
							  placeholder="{{ 'hunting.scheduledMonitorSidePane.fields.huntingRuleFrequency.placeholder' | i18n}}"
							  [ariaLabel]="'hunting.scheduledMonitorSidePane.fields.huntingRuleFrequency.title' | i18n"
							  label="name"
							  required
							  [values]="huntingRuleFrequencies"
							  [dropdownAreaRole]="'combobox'">
				</fancy-select>
			</div>

			<div class="form-group">
				<label class="required">{{ "hunting.scheduledMonitorSidePane.fields.alertTitle.title" | i18n}}</label>
				<input type="text"
					   id="alert-title"
					   name="alert-title"
					   class="form-control"
					   [(ngModel)]="huntingRule.alertTitle"
					   placeholder="{{ 'hunting.scheduledMonitorSidePane.fields.alertTitle.placeholder' | i18n}}"
					   [attr.aria-label]="('hunting.scheduledMonitorSidePane.fields.alertTitle.title' | i18n) + ', ' + ('hunting.scheduledMonitorSidePane.fields.alertTitle.placeholder' | i18n)"
					   required/>
			</div>

			<div class="form-group">
				<label class="required wcd-full-width">{{ "hunting.scheduledMonitorSidePane.fields.alertSeverity.title" | i18n}}</label>
				<fancy-select [(ngModel)]="huntingRule.alertSeverity"
							  listId="alert-severity"
							  name="alertSeverity"
							  [isBordered]="true"
							  labelClass="dropdown-width-medium"
							  placeholder="{{ 'hunting.scheduledMonitorSidePane.fields.alertSeverity.placeholder' | i18n}}"
							  [ariaLabel]="'hunting.scheduledMonitorSidePane.fields.alertSeverity.title' | i18n"
							  labelI18nKey="nameI18nKey"
							  required
							  [values]="availableAlertSeverities"
							  [dropdownAreaRole]="'combobox'">
				</fancy-select>
			</div>

			<div class="form-group">
				<label class="required wcd-full-width">{{ "hunting.scheduledMonitorSidePane.fields.alertCategory.title" | i18n}}</label>
				<fancy-select [(ngModel)]="alertCategory"
							  (ngModelChange)="onCategoryChanged($event)"
							  name="alertCategory"
							  [isBordered]="true"
							  labelClass="dropdown-width-medium"
							  placeholder="{{ 'hunting.scheduledMonitorSidePane.fields.alertCategory.placeholder' | i18n}}"
							  [ariaLabel]="'hunting.scheduledMonitorSidePane.fields.alertCategory.title' | i18n"
							  required
							  [values]="supportedAlertCategories"
							  [formatLabel]="getAlertCategoryLabel"
							  [dropdownAreaRole]="'combobox'">
				</fancy-select>
			</div>

			<div class="form-group" *ngIf="huntingCustomDetectionMitreDropDownEnabled && selectableMitreTechniques && selectableMitreTechniques.length">
				<label class="wcd-full-width">{{ "hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.title" | i18n}}</label>
				<wcd-checklist-dropdown
					[(ngModel)]="huntingRule.mitreTechniques"
					name="alertMitreTechnique"
					labelClass="dropdown-width-medium"
					[ariaLabel]="'hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.title' | i18n"
					[buttonText]="getMitreTechniqueDropDownPlaceHolder()"
					[wrapItemsLabel]="true"
					[values]="selectableMitreTechniques">
				</wcd-checklist-dropdown>
			</div>

			<div *ngIf="threatAnalyticsCustomDetectionFeatureEnabled" class="form-group">
				<label class="wcd-full-width">{{ "hunting.scheduledMonitorSidePane.fields.threatAnalyticsReport.title" | i18n}}</label>
				<fancy-select [(ngModel)]="threat"
							  (ngModelChange)="onThreatChanged($event)"
							  name="alertThreatAnalyticsReport"
							  [isBordered]="true"
							  labelClass="dropdown-width-medium"
							  placeholder="{{ 'hunting.scheduledMonitorSidePane.fields.threatAnalyticsReport.placeholder' | i18n}}"
							  [ariaLabel]="'hunting.scheduledMonitorSidePane.fields.threatAnalyticsReport.title' | i18n"
							  [values]="threats"
							  [formatLabel]="getThreatAnalyticsLabel"
							  [dropdownAreaRole]="'combobox'"
							  [isDisabled]="!shouldDropdownBeActive">
				</fancy-select>
			</div>

			<div class="form-group">
				<label class="required">{{ "hunting.scheduledMonitorSidePane.fields.alertDescription.title" | i18n}}</label>
				<textarea rows="4"
						  class="form-control"
						  name="description"
						  placeholder="{{ 'hunting.scheduledMonitorSidePane.fields.alertDescription.placeholder' | i18n}}"
						  [attr.aria-label]="'hunting.scheduledMonitorSidePane.fields.alertDescription.title' | i18n"
						  required
						  [(ngModel)]="huntingRule.alertDescription">
							</textarea>
			</div>

			<div class="form-group">
				<label>{{ "hunting.scheduledMonitorSidePane.fields.recommendedActions.title" | i18n}}</label>
				<textarea rows="4"
						  class="form-control"
						  name="recommendedActions"
						  placeholder="{{ 'hunting.scheduledMonitorSidePane.fields.recommendedActions.placeholder' | i18n}}"
						  [(ngModel)]="huntingRule.recommendedActions"
						  [attr.aria-label]="'hunting.scheduledMonitorSidePane.fields.recommendedActions.title' | i18n"
				>
							</textarea>
			</div>

		</div>
	</form>
	<form #entitiesStepForm="ngForm" *ngIf="currentStep.id === WizardStepId.ImpactedEntities" class="wcd-flex-1">
		<h3 class="wcd-margin-bottom wizard-section-title" >{{ "hunting.scheduledMonitorSidePane.wizardSections.entities.title" | i18n}}</h3>
		<p class="wcd-margin-vertical">
			{{ 'hunting.scheduledMonitorSidePane.wizardSections.entities.description' | i18n }}
			<br/><a href="{{'hunting_scheduledMonitorSidePane_wizardSections_entities_helpLink' | i18n}}" autofocus target="_blank"
					[attr.aria-label]="('common_learnMoreAbout' | i18n) +' '+ ('hunting.scheduledMonitorSidePane.wizardSections.entities.description' | i18n)"
			>{{'hunting_scheduledMonitorSidePane_wizardSections_entities_learnMore' | i18n}}</a>
		</p>
		<div class="wcd-flex-1 wcd-padding-vertical">
			<div *ngFor="let entityCategory of impactedEntityCategories" class="wcd-margin-bottom">
				<wcd-checkbox
					[name]="'impactedEntity-' + entityCategory.entityType + '-checkbox'"
					[label]="entityCategory.name"
					[isDisabled]="!(entityCategory.entities?.length)"
					[(ngModel)]="entityCategory.checked"
					(ngModelChange)="onImpactedEntitiesSelectionChange($event)">
				</wcd-checkbox>
				<div *ngIf="entityCategory.checked">
					<fancy-select
						[(ngModel)]="entityCategory.selectedEntity"
						(ngModelChange)="onImpactedEntitiesSelectionChange($event)"
						[values]="entityCategory.entities"
						[name]="'impactedEntity-' + entityCategory.entityType + 'dropdown'"
						[formatLabel]="getImpactedEntitiesLabel"
						[isBordered]="true"
						labelClass="dropdown-width-medium"
						placeholder="{{ 'hunting.scheduledMonitorSidePane.wizardSections.entities.choose' + entityCategory.entityType | i18n}}"
						[ariaLabel]="(entityCategory.name + ', ' ) + ('hunting.scheduledMonitorSidePane.wizardSections.entities.choose' + entityCategory.entityType | i18n)"
						required
						[dropdownAreaRole]="'combobox'">
					</fancy-select>
				</div>
			</div>
		</div>
	</form>
	<form #actionsStepForm="ngForm" *ngIf="currentStep.id === WizardStepId.CustomActions" class="wcd-flex-1">
		<h3 class="wcd-margin-bottom wizard-section-title" >{{ "hunting.scheduledMonitorSidePane.wizardSections.actions.title" | i18n}}</h3>
		<p class="wcd-margin-vertical">{{ 'hunting.scheduledMonitorSidePane.wizardSections.actions.description' | i18n }}</p>

		<hunting-custom-actions
			name="customActions"
			[supportedActions]="supportedCustomActions"
			[(ngModel)]="huntingRule.actions"
			(ngModelChange)="onCustomActionsChange($event)"
			(anyExpandedChange)="onAnyExpandedChange($event)">
		</hunting-custom-actions>

	</form>
	<form #scopeStepForm="ngForm" *ngIf="currentStep.id === WizardStepId.Scope" class="wcd-flex-1">
		<div
			class="wcd-flex-1">
			<div>
				<h3 class="wcd-margin-bottom wizard-section-title">{{"hunting.scheduledMonitorSidePane.wizardSections.scope.title" | i18n}}</h3>
				<div *ngIf="isLoadingMachinesScopes; else finishedLoading">
					<wcd-shared-icon class="loader-icon wcd-margin-small-right ms-icon-top-2-fix"></wcd-shared-icon>
					{{"entity.loadingOptions" | i18n}}
				</div>
				<ng-template #finishedLoading>
					<div class="form-group" *ngIf="selectableScopes.length > 1">
						<label>{{"hunting.scheduledMonitorSidePane.fields.machineGroups.title" | i18n}}</label>
						<wcd-radiolist [values]="selectableScopes"
									   name="huntingRuleScope"
									   (ngModelChange)="onRuleScopeChange($event)"
									   [focusOnInit]="true"
									   [(ngModel)]="huntingRuleScope"></wcd-radiolist>
					</div>
					<wcd-checklist-dropdown
						[(ngModel)]="currentSelectedMachineGroupsIds"
						(ngModelChange)="onMachineGroupsChange($event)"
						[isFullWidth]="true"
						placeholder="{{getGroupsDropdownPlaceholder(currentSelectedMachineGroupsIds)}}"
						name="select-groups-for-scope"
						[disableDropdown]="disableGroupsDropdown"
						[values]="machineGroupsChecklistValues$ | async"
						[required]="!disableGroupsDropdown">
					</wcd-checklist-dropdown>
				</ng-template>
			</div>
		</div>
	</form>
	<div *ngIf="currentStep.id === WizardStepId.Summary" class="wcd-flex-1" tabindex="0" autofocus >
		<h3 class="wcd-margin-bottom wizard-section-title">{{ "hunting.scheduledMonitorSidePane.wizardSections.summary.title" | i18n}}</h3>
		<h4>
			{{ "hunting.scheduledMonitorSidePane.wizardSections.alertDetails.title" | i18n}}
		</h4>

		<div class="wcd-padding-vertical">
			<dt role="none">{{ "hunting.scheduledMonitorSidePane.wizardSections.summary.ruleName" | i18n}}</dt>
			<dd role="none">{{huntingRule.name}}</dd>
		</div>

		<div class="wcd-padding-vertical">
			<dt role="none">{{ "hunting.scheduledMonitorSidePane.wizardSections.summary.frequency" | i18n}}</dt>
			<dd role="none">{{selectedFrequency?.name}}</dd>
		</div>

		<div class="wcd-padding-vertical">
			<dt role="none">{{ "hunting.scheduledMonitorSidePane.wizardSections.summary.alertTitle" | i18n}}</dt>
			<dd role="none">{{huntingRule.alertTitle}}</dd>
		</div>

		<div class="wcd-padding-vertical">
			<dt role="none">{{ "hunting.scheduledMonitorSidePane.wizardSections.summary.alertSeverity" | i18n}}</dt>
			<dd role="none">{{huntingRule.alertSeverity.nameI18nKey | i18n}}</dd>
		</div>

		<div class="wcd-padding-vertical">
			<dt role="none">{{ "hunting.scheduledMonitorSidePane.wizardSections.summary.alertCategory" | i18n}}</dt>
			<dd role="none">{{ huntingRule.alertCategory && getAlertCategoryLabel(huntingRule.alertCategory)}}</dd>
		</div>

		<div *ngIf="threatAnalyticsCustomDetectionFeatureEnabled" class="wcd-padding-vertical">
			<dt role="none">{{ "hunting.scheduledMonitorSidePane.wizardSections.summary.threatAnalyticsReport" | i18n}}</dt>
			<dd role="none">{{ huntingRule.threatId ? getThreatAnalyticsLabel(threat) : 'None'}}</dd>
		</div>

		<div class="wcd-padding-vertical" *ngIf="huntingRule.mitreTechniques && huntingRule.mitreTechniques.length">
			<dt role="none">{{ "hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.title" | i18n}}</dt>
			<dd role="none">
				<div *ngFor="let mitreTechnique of huntingRule.mitreTechniques">
					{{ mitreTechnique | mitreTechniqueDisplay}}
				</div>
			</dd>
		</div>

		<div class="wcd-padding-vertical" *ngIf="showImpactedEntities && huntingRule.impactedEntities?.length">
			<dt role="none">{{ "hunting.scheduledMonitorSidePane.wizardSections.entities.title" | i18n }}</dt>
			<dd role="none">
				<wcd-datatable
					[className]="'wizard'"
					[items]="huntingRule.impactedEntities"
					[columns]="impactedEntitiesColumns"
					[selectEnabled]="false">
				</wcd-datatable>
			</dd>
		</div>


		<ng-container *ngLet="summarizedActions as actions">
			<ng-container *ngIf="actions.length">
				<h4>{{ "hunting.scheduledMonitorSidePane.wizardSections.actions.title" | i18n}}</h4>
				<div>
					<wcd-datatable
						[className]="'wizard'"
						[items]="actions"
						[columns]="summarizedActionsColumns"
						[selectEnabled]="false">
					</wcd-datatable>
				</div>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="isRbacFilteringNecessary$.value">
			<h4>{{ "hunting.scheduledMonitorSidePane.wizardSections.scope.title" | i18n}}</h4>

			<div class="wcd-padding-vertical">
				<dt role="none">{{ "hunting.scheduledMonitorSidePane.wizardSections.summary.scope" | i18n}}</dt>
				<dd role="none">{{machineGroupsSummaryDisplay(currentSelectedMachineGroupsIds, this.huntingRuleScope.id === ALL_SCOPES)}}</dd>
			</div>
		</ng-container>
	</div>
	<div *ngIf="error" class="wcd-vertical-align-bottom error-message">
		{{ error }}
	</div>
</wizard-panel>
