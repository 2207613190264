import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppConfigService } from '@wcd/app-config';
import { UserAuthEnforcementMode } from '@wcd/auth';
import { MtpWorkload } from '@wcd/domain';
import { sccHostService } from '@wcd/scc-interface';
import { UnifiedRbacPermission } from '@wcd/auth';
import { AuthService } from '../services/auth.service';

interface UnifiedRbacPermissionGuardConfig {
	UnifiedRbacPermission: UnifiedRbacPermission;
	Workload: MtpWorkload;
}

@Injectable()
export class UnifiedRbacGuard implements CanActivate {
	constructor(private appConfigService: AppConfigService, private readonly authService: AuthService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
		if (
			!route.data ||
			!sccHostService.isSCC ||
			this.appConfigService.userAuthEnforcementMode != UserAuthEnforcementMode.UnifiedRbac
		) {
			return true;
		}

		const config: UnifiedRbacPermissionGuardConfig = route.data['UnifiedRbacPermissionGuardConfig'];

		if (!this.authService.currentUser) {
			// No users signed in to check permissions
			return true;
		} else if (
			this.authService.currentUser.hasRequiredMtpPermissionInWorkload(
				config.UnifiedRbacPermission,
				config.Workload
			)
		) {
			return true;
		} else {
			sccHostService.state.go('Error.child', { path: 'UnauthorizedOnThisPage' });
			return false;
		}
	}
}
