import { sccHostService } from '@wcd/scc-interface';
import { ServiceUrls } from '@wcd/domain';

const STARTS_WITH_BRACKETS_REGEXP = /^(<(\w+)>)[\/]+/i;

export class SccProxyUtils {
	private static shouldUseSccProxy(features: { [featureId: string]: boolean }): boolean {
		if (!sccHostService.isSCC) {
			return false;
		}

		const localFeatureStringValue = localStorage.getItem(`feature.UseSccProxy`);
		return localFeatureStringValue
			? localFeatureStringValue === String(true)
			: features && features.UseSccProxy;
	}

	static urlMatchSccProxyPattern(url: string): boolean {
		return Boolean(url.match(STARTS_WITH_BRACKETS_REGEXP));
	}

	static convertToSccProxyPattern(key: string): string {
		return `<mtp>/${key}`;
	}

	static getServiceUrls(
		originalServiceUrls: ServiceUrls,
		features: { [featureId: string]: boolean },
	): ServiceUrls {
		const useSccProxy = SccProxyUtils.shouldUseSccProxy(features);
		const useMockServer = sccHostService.mock.isMockMode;

		return Object.entries(originalServiceUrls || {})
			.filter(([key, value]) => value)
			.reduce(
				(acc, [key, value]) => {
					acc[key] =
						SccProxyUtils.urlMatchSccProxyPattern(value) || !useSccProxy || useMockServer
							? value
							: SccProxyUtils.convertToSccProxyPattern(key);
					return acc;
				},
				{} as ServiceUrls,
			);
	}
}
