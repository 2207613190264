import { merge } from 'lodash-es';
import { FiltersFieldConfig } from '@wcd/ng-filters';
import { DataTableField, DataTableFieldConfig } from '@wcd/datatable';

// @dynamic
export class DataviewField<TData = any, TComponent = any>
	extends DataTableField<TData, TComponent>
	implements DataviewFieldConfig<TData, TComponent> {
	constructor(dataViewFieldConfig: DataviewFieldConfig<TData, TComponent>) {
		super(dataViewFieldConfig);

		Object.assign(this, {
			enabledByDefault: dataViewFieldConfig.enabledByDefault !== false,
			enabled: dataViewFieldConfig.enabledByDefault !== false,
			alwaysDisplay: dataViewFieldConfig.alwaysDisplay === true,
		});

		Object.freeze(this);
	}

	readonly alwaysDisplay: boolean;
	readonly enabled: boolean;
	readonly enabledByDefault: boolean;
	readonly filter: FiltersFieldConfig<any, any, any, any>;
	readonly filterOnly: boolean;
	readonly filterName: string;

	/**
	 * Small helper to easily map an array of DataviewFieldConfig objects to DataviewFields.
	 */
	static fromList<TData>(fields: Array<DataviewFieldConfig<TData>>): Array<DataviewField<TData>> {
		return fields.filter(Boolean).map((field) => new DataviewField<TData>(field));
	}

	clone(data?: Partial<DataviewFieldConfig<TData, TComponent>>) {
		return new DataviewField(merge({}, this, data));
	}
}

export interface DataviewFieldConfig<TData = any, TComponent = any>
	extends DataTableFieldConfig<TData, TComponent> {
	/**
	 * Unique id for this field. This is the name of the field that's sent to the backend as sortField or when filtering.
	 */
	id: string;

	/**
	 * If true, the field will always display in the dataview and won't be available in the column selection
	 */
	alwaysDisplay?: boolean;

	/**
	 * Whether to display the field by default in the dataview. If false, the field isn't displayed, but will be available for adding to the dataview by the user.
	 */
	enabledByDefault?: boolean;

	/**
	 * Configuration for the field's filter.
	 */
	filter?: FiltersFieldConfig<any, any, any, any>;

	/**
	 * If true, the field will be available only in Filters, and not in the DataTable itself, or selectable by customizing columns.
	 */
	filterOnly?: boolean;

	/**
	 * The name to display in filters (if not provided name is used as default)
	 */
	filterName?: string;
}
