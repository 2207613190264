
		<modal (close)="destroy()" [settings]="settings">
			<confirm-modal-content
				class="wcd-flex-1 ie11Patch ie11Flex"
				[confirmButtonOptions]="{
					disabled: !reason || isSaving
				}"
				[isSaving]="isSaving"
				(onCancel)="onCancelHandler()"
				(onConfirm)="submit()"
			>
				<p>{{ 'file.' + action + '.description' | i18n }}</p>
				<label for="block-file-reason">{{ 'file.block.reason' | i18n }}:</label>
				<textarea
					required
					rows="4"
					id="block-file-reason"
					[placeholder]="'file.quarantine.reasonPlaceholder' | i18n"
					class="form-control dialog-textarea wcd-full-width wcd-no-resize-horizontal"
					[(ngModel)]="reason"
				></textarea>
				<ng-container *ngIf="action === 'block'">
					<div
						*ngIf="(currentInstancesCount$ | async) as currentInstancesCount"
						class="wcd-margin-vertical"
					>
						<markdown
							[data]="'file.block.instances' | i18n: { count: currentInstancesCount.fileCount }"
						></markdown>
						<div class="wcd-margin-top">
							<small>
								{{ 'file.quarantine.applies' | i18n }}
							</small>
						</div>
					</div>
				</ng-container>
			</confirm-modal-content>
		</modal>
	