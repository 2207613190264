var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Paris } from '@microsoft/paris';
import { combineLatest, of } from 'rxjs';
import { AlertStatusReportType, ThreatDimensionType, ThreatReportApiCall, Alert, } from '@wcd/domain';
import { TzDateService } from '@wcd/localization';
import { ReportsService } from './reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { Router } from '@angular/router';
import { mapKeys, mapValues, sum, values as lodashValues } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { TrackingEventId } from '../../insights/models/tracking-event-Id.enum';
import { ThreatProtectionStatusMapping } from '../threat-protection-status-mapping';
import { map, mergeMap } from 'rxjs/operators';
import { rbacGroupIdsFilter, } from '../../reporting/threat-protection2/threat-protection-report.model';
import { WidgetType } from '../../reports/components/report-with-filters.component';
import { ChartType } from '../../reporting/reporting.types';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import * as i0 from "@angular/core";
import * as i1 from "@microsoft/paris/dist/lib/paris";
import * as i2 from "../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i3 from "../../insights/services/app-insights.service";
import * as i4 from "../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i5 from "@angular/router";
import * as i6 from "../threat-protection-status-mapping";
import * as i7 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "../../global_entities/services/global-entity-types.service";
var MAX_LABELS = 4;
var FIXED_STATUS_FILTERS = ['New', 'InProgress'];
var FIXED_AGE_FILTERS = 'day';
var SENSORS = 'Bitdefender,Ziften,SentinelOne,Lookout';
var SENSORS_DETECTION_SOURCE_FILTER = 'ThirdPartySensors';
var ThreatProtectionReport2Service = /** @class */ (function () {
    function ThreatProtectionReport2Service(paris, tzDateService, appInsightsService, prettyNumberService, router, threatProtectionStatusMapping, i18nService, globalEntityTypesService) {
        this.paris = paris;
        this.tzDateService = tzDateService;
        this.appInsightsService = appInsightsService;
        this.prettyNumberService = prettyNumberService;
        this.router = router;
        this.threatProtectionStatusMapping = threatProtectionStatusMapping;
        this.i18nService = i18nService;
        this.globalEntityTypesService = globalEntityTypesService;
    }
    ThreatProtectionReport2Service.prototype.getAlertsByDimension = function (params, widgetType, dimensionType) {
        params.alignToPeriod = 'true';
        widgetType === WidgetType.Trend
            ? (params.OpenedAge = FIXED_AGE_FILTERS)
            : (params.Status = FIXED_STATUS_FILTERS.join(','));
        return this.paris
            .apiCall(ThreatReportApiCall, params)
            .pipe(mergeMap(function (res) {
            return of(res.find(function (r) { return r.dimensionType.toString() === dimensionType; }));
        }));
    };
    ThreatProtectionReport2Service.prototype.getAlertsByStatus = function (params, widgetType, dimensionType) {
        // Alerts by source overtime combined 2 API calls with different params.
        // One call with params.OpenAgeInDays  = 'day' that gives the data for New and InProgress alerts.
        // One call with params.ResolvedAgeInDays  = 'day' that gives the data for Resolved alerts.
        return combineLatest(this.getAlertsByDimension(Object.assign({}, params), widgetType, dimensionType), this.getResolvedData(Object.assign({}, params), dimensionType)).pipe(map(function (_a) {
            var sourceData = _a[0], resolvedData = _a[1];
            var combinedData = sourceData.data.map(function (_a) {
                var date = _a.date, values = _a.values;
                var resolvedInDate = resolvedData.data.find(function (r) { return r.date.getTime() === date.getTime(); });
                return {
                    date: date,
                    values: __assign({}, values, (widgetType === WidgetType.Trend ? { New: sum(lodashValues(values)) } : {}), (resolvedInDate
                        ? { Resolved: resolvedInDate.values[AlertStatusReportType.Resolved] }
                        : {})),
                };
            });
            return {
                dimensionType: sourceData.dimensionType,
                data: combinedData,
            };
        }));
    };
    ThreatProtectionReport2Service.prototype.getAlertsByClassificationAndDetermination = function (params, widgetType, dimensionType) {
        params.alignToPeriod = 'true';
        params.DeterminationAge = FIXED_AGE_FILTERS;
        // Merging 2 dimensions: classification & determination
        return this.paris.apiCall(ThreatReportApiCall, params).pipe(mergeMap(function (res) {
            var classification = res.find(function (r) { return r.dimensionType.toString() === ThreatDimensionType.AlertClassificationType; });
            var determination = res.find(function (r) { return r.dimensionType.toString() === ThreatDimensionType.AlertDeterminationType; });
            var combinedData = [];
            classification.data.forEach(function (item, index) {
                var p = __assign({}, item.values, determination.data[index].values);
                combinedData.push({ date: item.date, values: p });
            });
            return of({
                dimensionType: classification.dimensionType,
                data: combinedData,
            });
        }));
    };
    ThreatProtectionReport2Service.prototype.getSettingsOverTime = function (dimensionType, dimensionMapping, data, height) {
        var _this = this;
        if (height === void 0) { height = 200; }
        var dimensionData = data && data.data && data.data.length && data.data;
        var chartData = this.getDimensionData(dimensionType, dimensionData, dimensionMapping);
        var maxAlertsCount = chartData
            ? Math.max.apply(Math, chartData.map(function (d) {
                var t = Math.max.apply(Math, d.slice(1));
                return t;
            })) : 0;
        return {
            options: {
                data: {
                    columns: chartData,
                    colors: mapValues(mapKeys(dimensionMapping, function (value) { return value.label; }), function (value) { return value.knownColorsMap.trend.raw; }),
                },
                axis: {
                    y: {
                        max: maxAlertsCount ? maxAlertsCount : 0,
                        min: 0,
                        tick: {
                            values: dimensionData ? ReportsService.getYTicks(maxAlertsCount) : [],
                            format: function (value) { return _this.prettyNumberService.prettyNumber(value); },
                        },
                        padding: {
                            bottom: 0,
                        },
                    },
                    x: {
                        type: 'category',
                        categories: this.getDimensionDates(dimensionData),
                        tick: {
                            multiline: false,
                            culling: {
                                max: dimensionData
                                    ? Math.min(dimensionData.length + 1, MAX_LABELS)
                                    : MAX_LABELS,
                            },
                        },
                    },
                },
                point: {
                    show: false,
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                legend: {
                    show: false,
                },
                size: {
                    height: height,
                },
                tooltip: {
                    // Hide zeroes entries in tooltip
                    format: {
                        value: function (value) { return (value ? value : undefined); },
                    },
                },
            },
            disableCursor: true,
        };
    };
    ThreatProtectionReport2Service.prototype.getSettingsDaily = function (type, dimensionMapping, data, getDimensionFilterData, height) {
        var _this = this;
        if (height === void 0) { height = 250; }
        var dimensionData = data && data.data && data.data.length && data.data[0];
        var chartData = Object.entries(dimensionMapping)
            .filter(function (_a) {
            var key = _a[0], value = _a[1];
            return !value.hiddenOnDaily;
        })
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            return _this.getDimensionDataDaily(value.label, key, dimensionData);
        });
        var maxCount = chartData
            ? chartData.values && Math.max.apply(Math, chartData.map(function (d) { return d.value; }))
            : 0;
        var colorsPattern = Object.values(dimensionMapping).map(function (value) { return value.knownColorsMap.daily.raw; });
        return {
            options: {
                color: {
                    pattern: colorsPattern,
                },
                data: {
                    columns: [
                        [
                            this.i18nService.get('reporting.threatReport.dailyTooltipDescription')
                        ].concat(chartData.map(function (data) { return data.value; })),
                    ],
                    groups: [
                        Object.values(dimensionMapping).map(function (value) { return value.label; }),
                    ],
                    type: 'bar',
                    labels: {
                        format: function (value) { return (maxCount > 0 ? _this.prettyNumberService.prettyNumber(value) : ''); },
                    },
                    color: function (inColor, data) {
                        if (data.index !== undefined) {
                            return colorsPattern[data.index];
                        }
                        return inColor;
                    },
                    onclick: function (e) {
                        _this.onDimensionClick(Object.keys(dimensionMapping)[e.index], getDimensionFilterData);
                    },
                },
                tooltip: {
                    grouped: false,
                    contents: function (data) {
                        return "<table class=\"c3-tooltip\">\n\t\t\t\t\t\t\t<tbody>\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td class=\"name\">" + data[0].value + "</td>\n\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t</tbody>\n\t\t\t\t\t\t</table>";
                    },
                },
                bar: {
                    width: {
                        max: 8,
                        ratio: 0.2,
                    },
                },
                axis: {
                    rotated: type === ChartType.Horizontal,
                    x: {
                        type: 'category',
                        categories: chartData.map(function (data) { return data.label; }),
                        tick: {
                            multiline: type === ChartType.Horizontal,
                            culling: {
                                max: 1,
                            },
                            rotate: 75,
                        },
                        padding: { right: 0.5 },
                    },
                    y: {
                        show: true,
                        max: maxCount ? maxCount : 0,
                        min: 0,
                        tick: {
                            values: data ? ReportsService.getYTicks(maxCount) : [],
                            format: function (value) { return _this.prettyNumberService.prettyNumber(value); },
                        },
                        padding: {
                            bottom: 0,
                        },
                    },
                },
                padding: {
                    left: type === ChartType.Horizontal ? 100 : 50,
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                point: {
                    show: false,
                },
                legend: { show: false },
                size: {
                    height: height,
                },
            },
        };
    };
    ThreatProtectionReport2Service.prototype.onDimensionClick = function (dimensionKey, getDimensionFilterData) {
        var _this = this;
        try {
            if (!getDimensionFilterData) {
                return; // Dimension doesn't support drill down
            }
            var parsedFilters_1 = {};
            var dimensionFilterData_1 = getDimensionFilterData(dimensionKey);
            Object.entries(dimensionFilterData_1.currentFilters).forEach(function (_a) {
                var key = _a[0], values = _a[1];
                if (key !== dimensionFilterData_1.type) {
                    switch (key) {
                        case 'severity': {
                            var parsedValues = values.map(function (value) {
                                return encodeURIComponent(_this.threatProtectionStatusMapping.severityMapping[value].alertListFilter);
                            });
                            parsedFilters_1.severity = parsedValues.join('|');
                            break;
                        }
                        case 'detectionSource': {
                            var parsedValues = values.map(function (value) {
                                return encodeURIComponent(_this.threatProtectionStatusMapping.detectionSourceMapping[value]
                                    .alertListFilter);
                            });
                            parsedFilters_1.detectionSource = parsedValues.join('|');
                            break;
                        }
                        case 'rbacGroupIds': {
                            parsedFilters_1.rbacGroupIds = dimensionFilterData_1.currentFilters[rbacGroupIdsFilter]
                                ? (dimensionFilterData_1.currentFilters[rbacGroupIdsFilter]).join('|')
                                : '';
                            break;
                        }
                    }
                }
            });
            if (dimensionFilterData_1.dimensionFilter) {
                dimensionFilterData_1.dimensionFilter = dimensionFilterData_1.dimensionFilter.replace('detectionSource=' + SENSORS_DETECTION_SOURCE_FILTER, 'detectionSource=' + encodeURIComponent(encodeURIComponent(SENSORS)));
            }
            var drillDownFilters = [
                "status=" + encodeURIComponent(FIXED_STATUS_FILTERS.join('|')),
                dimensionFilterData_1.dimensionFilter ? dimensionFilterData_1.dimensionFilter : '',
                parsedFilters_1.severity ? "severity=" + parsedFilters_1.severity : '',
                parsedFilters_1.detectionSource ? "detectionSource=" + parsedFilters_1.detectionSource : '',
                parsedFilters_1.rbacGroupIds ? "rbacGroupIds=" + parsedFilters_1.rbacGroupIds : '',
            ].filter(function (value) { return value !== ''; });
            this.trackNavigation(dimensionKey, drillDownFilters, dimensionFilterData_1.type);
            var alertEntityType = this.globalEntityTypesService.getEntityType(Alert);
            this.router.navigate([alertEntityType.getEntityDataviewLink()], {
                queryParams: {
                    filters: drillDownFilters.join(','),
                    range: '6months',
                },
            });
        }
        catch (e) {
            this.appInsightsService.trackEvent('UI', {
                id: 'ThreatReport_OnClick',
                index: e.index,
            });
        }
    };
    ThreatProtectionReport2Service.prototype.getResolvedData = function (params, dimensionType) {
        params.alignToPeriod = 'true';
        params.ResolvedAge = FIXED_AGE_FILTERS;
        return this.paris
            .apiCall(ThreatReportApiCall, params)
            .pipe(mergeMap(function (res) {
            return of(res.find(function (r) { return r.dimensionType.toString() === dimensionType; }));
        }));
    };
    ThreatProtectionReport2Service.prototype.trackNavigation = function (clickedValue, drillDownFilters, dimension) {
        this.appInsightsService.trackEvent('UsageTrack', {
            type: TrackingEventType.Navigation,
            id: TrackingEventId.DrillDownToAlerts,
            dimension: dimension,
            column: clickedValue,
            filters: drillDownFilters,
        });
    };
    ThreatProtectionReport2Service.prototype.getDimensionDates = function (dimensionData) {
        var _this = this;
        return dimensionData
            ? dimensionData.map(function (data) { return _this.tzDateService.format(data.date, 'MM/dd'); })
            : [];
    };
    ThreatProtectionReport2Service.prototype.getDimensionData = function (type, dimensionData, dimensionMapping) {
        var _this = this;
        return Object.entries(dimensionMapping)
            .filter(function (_a) {
            var key = _a[0], value = _a[1];
            return !value.hiddenOnTrend;
        })
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            return _this.getDimensionDataOvertime(dimensionMapping[key].label, key, dimensionData);
        });
    };
    ThreatProtectionReport2Service.prototype.getDimensionDataOvertime = function (label, dimensionType, dimensionData) {
        return [
            label
        ].concat((dimensionData &&
            dimensionData.map(function (data) { return (data.values[dimensionType] ? data.values[dimensionType] : 0); })));
    };
    ThreatProtectionReport2Service.prototype.getDimensionDataDaily = function (label, dimensionType, dimensionData) {
        return {
            label: label,
            value: dimensionData &&
                (dimensionData.values[dimensionType] ? dimensionData.values[dimensionType] : 0),
        };
    };
    ThreatProtectionReport2Service.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThreatProtectionReport2Service_Factory() { return new ThreatProtectionReport2Service(i0.ɵɵinject(i1.Paris), i0.ɵɵinject(i2.TzDateService), i0.ɵɵinject(i3.AppInsightsService), i0.ɵɵinject(i4.PrettyNumberService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i6.ThreatProtectionStatusMapping), i0.ɵɵinject(i7.I18nService), i0.ɵɵinject(i8.GlobalEntityTypesService)); }, token: ThreatProtectionReport2Service, providedIn: "root" });
    return ThreatProtectionReport2Service;
}());
export { ThreatProtectionReport2Service };
