import {
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild,
	ElementRef,
	OnInit,
	ChangeDetectorRef,
	AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChecklistValue } from './checklist.component';
import { isObject } from 'lodash-es';
import { valuesAreEqual } from './form-component.shared';
import { Disableable, DISABLEABLE_TOKEN } from '../models/disableable.interface';
import { I18nService } from '@wcd/i18n';
import { Focusable } from '../models/focusable.interface';

const CUSTOM_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => RadioListComponent),
	multi: true,
};

const RADIO_ICONS = {
	checked: 'RadioBtnOn',
	unchecked: 'RadioBtnOff',
	disabled: 'CircleShapeSolid',
};

const TAB_STOP = 0;
const NO_TAB_STOP = -1;

@Component({
	selector: 'wcd-radiolist',
	providers: [
		CUSTOM_ACCESSOR,
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => RadioListComponent),
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ul
			class="wcd-radiolist"
			[ngClass]="{ 'wcd-flex-horizontal': isHorizontal }"
			role="radiogroup"
			#radiogroup
			[attr.aria-label]="ariaLabel"
		>
			<li
				*ngFor="let item of values; trackBy: trackItemById; let i = index"
				[attr.aria-label]="getItemLabel(item)"
				[class.checked]="isSelectedItem(item)"
				[class.disabled-completely]="isDisabled || item.disabled"
				[ngClass]="itemClassName"
				role="radio"
				[tabIndex]="getTabIndex(item)"
				(blur)="onBlur(item, i)"
				(focus)="onFocus(item)"
				(keydown.arrowDown)="keyboardNavigation(i + 1)"
				(keydown.arrowUp)="keyboardNavigation(i - 1)"
				(keydown.arrowRight)="keyboardNavigation(i + 1)"
				(keydown.arrowLeft)="keyboardNavigation(i - 1)"
				(keydown.enter)="selectValue(item)"
				(keydown.space)="selectValue(item)"
				[attr.aria-checked]="isSelectedItem(item)"
				(click)="selectValue(item)"
			>
				<label
					class="wcd-radiolist--label wcd-flex-horizontal-align-items-end"
					[attr.for]="'radio-' + name + '_' + item.id"
					[class.disabled-completely]="isDisabled || item.disabled"
				>
					<input
						type="radio"
						[name]="name"
						[id]="'radio-' + name + '_' + item.id"
						[attr.value]="item.id"
						(change)="selectValue(item)"
						[disabled]="isDisabled || item.disabled"
						[checked]="isSelectedItem(item)"
					/>
					<span class="wcd-radiolist--icon">
						<fab-icon [iconName]="getIconName(item)"></fab-icon>
					</span>
					<span class="wcd-radiolist--label--text">{{ getItemLabel(item) }}</span>
					<wcd-help
						(onFocus)="onHelpFocus()"
						(onBlur)="onHelpBlur()"
						*ngIf="item.helpText"
						[text]="item.helpText"
						[wcdTooltipAllowHtmlRendering]="wcdTooltipAllowHtmlRendering"
						[wcdTooltipSanitizeHtml]="wcdTooltipSanitizeHtml"
						[tabIndexValue]="this.focusedItem == item ? TAB_STOP : NO_TAB_STOP"
					></wcd-help>
					<wcd-help
						(onFocus)="onHelpFocus()"
						(onBlur)="onHelpBlur()"
						[attr.aria-hidden]="ariaHidden"
						*ngIf="!item.helpText && item.helpKey"
						[text]="getItemText(item.helpKey)"
						[wcdTooltipAllowHtmlRendering]="wcdTooltipAllowHtmlRendering"
						[wcdTooltipSanitizeHtml]="wcdTooltipSanitizeHtml"
						[tabIndexValue]="this.focusedItem == item ? TAB_STOP : NO_TAB_STOP"
					></wcd-help>
				</label>
				<div *ngIf="item.description" class="wcd-radiolist--description">
					{{ item.description }}
				</div>
			</li>
		</ul>
	`,
	styleUrls: ['./radiolist.component.scss'],
})
export class RadioListComponent<TValue extends string | number = any>
	implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges, Disableable, Focusable {
	@Input() values: Array<ChecklistValue<TValue>>;
	@Input() name: string;
	@Input() labelFieldName: string;
	@Input() trackBy: string;
	@Input() isDisabled = false;
	@Input() isHorizontal = false;
	@Input() itemClassName: string;
	@Input() defaultValue: ChecklistValue<TValue>;
	@Input() ariaLabel: string;
	@Input() wcdTooltipAllowHtmlRendering: boolean;
	@Input() wcdTooltipSanitizeHtml: boolean;
	@Input() focusOnInit: boolean = false;

	@ViewChild('radiogroup', { static: false }) radioGroupElement: ElementRef;

	value: ChecklistValue<TValue>;
	focusedItem: ChecklistValue<TValue>;
	ariaHidden = true;

	private _tempValue: ChecklistValue<TValue>;

	readonly TAB_STOP = TAB_STOP;
	readonly NO_TAB_STOP = NO_TAB_STOP;

	constructor(private i18nService: I18nService, private changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit() {
		if (this.defaultValue) {
			this.writeValue(this.defaultValue);
		}
	}

	ngAfterViewInit(){
		this.focusOnInit && this.setFocus();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.values) this.writeValue(this._tempValue || this.value);
	}

	isSelectedItem(item: ChecklistValue<TValue>): boolean {
		return item === this.value;
	}

	trackItemById(index, item: ChecklistValue<TValue>) {
		return item.id;
	}

	getItemLabel(item: ChecklistValue<TValue>) {
		return (this.labelFieldName && item[this.labelFieldName]) || item.name;
	}

	getIconName(item: ChecklistValue<TValue>): string {
		if (this.isDisabled || item.disabled) return RADIO_ICONS.disabled;

		return item === this.value ? RADIO_ICONS.checked : RADIO_ICONS.unchecked;
	}

	selectValue(value: ChecklistValue<TValue>) {
		this.value = value;
		this.onChange(this.value);
	}


	keyboardNavigation(index){
		this.focusItem(index);
		if (index >= 0 && index < this.values.length) {
			this.selectValue(this.values[index]);
		}
	}

	focusItem(index: number) {
		if (index >= 0 && index < this.values.length) {
			this.radioGroupElement.nativeElement.children[index].tabIndex = TAB_STOP;
			this.radioGroupElement.nativeElement.children[index].focus();
		}
	}

	onFocus(item) {
		this.focusedItem = item;
	}

	onBlur(item, index) {
		if (index >= 0 && index < this.values.length) {
			this.radioGroupElement.nativeElement.children[index].tabIndex = this.getTabIndex(item);
		}
	}

	getTabIndex(item: ChecklistValue) {
		if (!this.value) {
			return item === this.values[0] ? TAB_STOP : NO_TAB_STOP;
		}
		return item === this.value ? TAB_STOP : NO_TAB_STOP;
	}

	getItemText(key: string): string {
		return this.i18nService.get(key);
	}

	onChange = (_: ChecklistValue<TValue>) => {};
	onTouched = () => {};

	writeValue(value: ChecklistValue<TValue>): void {
		if (this.hasValue(value)) this.value = value;
		else if (!isObject(value) && this.trackBy && this.values)
			this.value = this.values.find(_value => _value[this.trackBy] === value);

		if (this.value) {
			if (!value) this.value = null;
		} else {
			if (!this.values) this._tempValue = value;
			else this.value = null;
		}

		this.changeDetectorRef.detectChanges();
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setFocus = () => {
		this.focusItem(0);
	};

	onHelpFocus() {
		this.ariaHidden = false;
	}

	onHelpBlur() {
		this.ariaHidden = true;
	}

	private hasValue(value: ChecklistValue<TValue>): boolean {
		if (!this.values || value === undefined || value === null) return false;

		return !!this.values.find(_value => {
			if (this.trackBy && isObject(value) && isObject(_value))
				return value[this.trackBy] === _value[this.trackBy];

			return valuesAreEqual(_value, value);
		});
	}
}
