
		<div
			class="wcd-flex-1 wcd-flex-vertical ie11Patch ie11Flex"
			data-track-component="ConfirmModalContent"
		>
			<main class="dialog-contents wcd-flex-1 ie11Patch ie11Flex wcd-scroll-vertical">
				<ng-content></ng-content>
			</main>

			<footer class="dialog-footer wcd-flex-none wcd-flex-justify-end-horizontal">
				<fab-primary-button
					className="wcd-margin-small-right"
					data-track-id="Confirm"
					data-track-type="Button"
					[disabled]="confirmButtonOptions?.disabled"
					(onClick)="onConfirm.emit()"
				>
					<fab-spinner
						*ngIf="isSaving"
						[size]="SpinnerSize.xSmall"
						className="wcd-margin-xsmall-right"
					></fab-spinner>
					<span>{{ confirmButtonOptions?.text || ('buttons.confirm' | i18n) }}</span>
				</fab-primary-button>

				<fab-default-button
					data-track-id="Cancel"
					data-track-type="Button"
					[disabled]="cancelButtonOptions?.disabled"
					[text]="cancelButtonOptions?.text || ('buttons.cancel' | i18n)"
					(onClick)="onCancel.emit()"
				>
				</fab-default-button>
			</footer>
		</div>
	