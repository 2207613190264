/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./icon-switch.component.ngfactory";
import * as i2 from "./icon-switch.component";
import * as i3 from "@angular/common";
import * as i4 from "./icon.component";
import * as i5 from "../services/icons.service";
var styles_IconComponent = [];
var RenderType_IconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconComponent, data: {} });
export { RenderType_IconComponent as RenderType_IconComponent };
function View_IconComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "icon-switch", [], null, null, null, i1.View_IconSwitchComponent_0, i1.RenderType_IconSwitchComponent)), i0.ɵdid(1, 573440, null, 0, i2.IconSwitchComponent, [i0.ComponentFactoryResolver], { iconName: [0, "iconName"], contentClass: [1, "contentClass"], ariaLabel: [2, "ariaLabel"], styles: [3, "styles"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.parsedIconName; var currVal_1 = _co.iconDynamicClass; var currVal_2 = _co.ariaLabel; var currVal_3 = _co.styles; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_IconComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "i", [], [[8, "className", 0], [8, "innerText", 0]], null, null, null, null)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.iconDynamicClass; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconTypeClass; var currVal_1 = _co.text; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_IconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["fallback", 2]], null, 0, null, View_IconComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFabricReactOrWcdIcon; var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_IconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-shared-icon", [], null, null, null, View_IconComponent_0, RenderType_IconComponent)), i0.ɵdid(1, 573440, null, 0, i4.IconComponent, [i5.IconsService, i0.ChangeDetectorRef], null, null)], null, null); }
var IconComponentNgFactory = i0.ɵccf("wcd-shared-icon", i4.IconComponent, View_IconComponent_Host_0, { iconName: "iconName", verticalAlign: "verticalAlign", iconColor: "iconColor", iconClass: "iconClass", styles: "styles", ariaLabel: "ariaLabel" }, {}, []);
export { IconComponentNgFactory as IconComponentNgFactory };
