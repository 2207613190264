<!-- tslint:disable:template-click-events-have-key-events -->
<div class="wcd-margin-bottom" *ngIf="showDescription">
	{{ 'airsEntities_details_report_prefix' | i18n: { entityType: entityType.singularName.toLowerCase() } }}
	<strong [ngClass]="'color-text-' + report.status.className">{{
		report.status.name.toLowerCase()
	}}</strong>
	{{
		(report.providers.length === 1
			? 'airsEntities_details_report_suffix_singular'
			: 'airsEntities_details_report_suffix_plural') | i18n: { providerCount: report.providers.length }
	}}
</div>
<div>
	<a
		(click)="viewProviders = !viewProviders"
		class="minimize-btn toggle-link"
		[ngClass]="{ minimized: !viewProviders }"
	>
		{{ i18nService.strings.airsEntities_report_details }}
		<wcd-shared-icon [iconName]="'carets.right'" class="minimize-btn-icon small-icon"> </wcd-shared-icon>
	</a>
</div>
<div class="details-card entity-report" *ngIf="viewProviders">
	<ul class="entity-report-providers unstyled">
		<li *ngFor="let provider of report.providers">
			<div class="entity-report-status">
				<span
					class="status-label"
					[ngClass]="'color-box-' + (provider.reputation.className || 'gray-200')"
					>{{ provider.reputation.name }}</span
				>
			</div>
			<div class="entity-report-provider">
				<h4
					[ngClass]="{
						minimized: !expandedProviders[provider.name],
						'minimize-btn': providerHasDetails(provider)
					}"
					(click)="providerHasDetails(provider) && toggleProvider(provider)"
				>
					{{ provider.name }}
					<wcd-shared-icon
						[iconName]="'carets.right'"
						class="minimize-btn-icon"
						*ngIf="providerHasDetails(provider)"
					>
					</wcd-shared-icon>
				</h4>
				<div *ngIf="expandedProviders[provider.name]" class="entity-report-details">
					<section *ngIf="provider.categories">
						<h5>{{ i18nService.strings.airsEntities_report_categorizedAs }}</h5>
						{{ provider.categories }}
					</section>
					<section *ngIf="provider.detectedBy">
						<h5>{{ i18nService.strings.airsEntities_report_detectedBy }}</h5>
						{{ provider.detectedBy }}
					</section>
					<section *ngIf="provider.scanTime">
						<h5>{{ i18nService.strings.airsEntities_report_analysisDate }}</h5>
						{{ provider.scanTime | date: 'medium' }}
					</section>
					<section *ngIf="provider.summary?.length">
						<h5>{{ i18nService.strings.airsEntities_report_summary }}</h5>
						<ul class="inline-list">
							<li *ngFor="let summaryItem of getProviderSummaryItems(provider)">
								{{ summaryItem }}
							</li>
						</ul>
						<button
							type="button"
							class="btn-inline"
							*ngIf="provider.summary.length > 5"
							(click)="toggleProviderSummary(provider)"
						>
							{{
								showAllProviderSummaryItems[provider.name]
									? i18nService.strings.airsEntities_report_summary_showLess
									: i18nService.strings.airsEntities_report_summary_showAll
							}}
						</button>
					</section>
					<a
						*ngIf="provider.link"
						[href]="
							provider.isInternal ? authService.addTenantIdToUrl(provider.link) : provider.link
						"
						target="_blank"
						class="noprint"
					>
						<wcd-shared-icon [iconName]="'popOut'" class="small-icon"> </wcd-shared-icon>
						{{ 'airsEntities_report_fullAnalysis' | i18n: { providerName: provider.name } }}
					</a>
				</div>
			</div>
		</li>
	</ul>
</div>
