/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/charts/src/lib/time-series/time-series-chart.component.ngfactory";
import * as i2 from "../../../../../../../projects/charts/src/lib/time-series/time-series-chart.component";
import * as i3 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i5 from "./web-content-filtering-access-trend-overtime-chart";
var styles_WebContentFilteringAccessTrendOvertimeChart = [];
var RenderType_WebContentFilteringAccessTrendOvertimeChart = i0.ɵcrt({ encapsulation: 2, styles: styles_WebContentFilteringAccessTrendOvertimeChart, data: {} });
export { RenderType_WebContentFilteringAccessTrendOvertimeChart as RenderType_WebContentFilteringAccessTrendOvertimeChart };
export function View_WebContentFilteringAccessTrendOvertimeChart_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "wcd-time-series-chart", [], null, null, null, i1.View_TimeSeriesChartComponent_0, i1.RenderType_TimeSeriesChartComponent)), i0.ɵdid(2, 770048, null, 0, i2.TimeSeriesChartComponent, [i0.ElementRef, i3.I18nService, i4.TzDateService], { settings: [0, "settings"], data: [1, "data"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartSettings; var currVal_1 = _co.entity.accessTrends; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_WebContentFilteringAccessTrendOvertimeChart_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "web-content-filtering-access-trend-over-time-chart", [], null, null, null, View_WebContentFilteringAccessTrendOvertimeChart_0, RenderType_WebContentFilteringAccessTrendOvertimeChart)), i0.ɵdid(1, 114688, null, 0, i5.WebContentFilteringAccessTrendOvertimeChart, [i4.TzDateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WebContentFilteringAccessTrendOvertimeChartNgFactory = i0.ɵccf("web-content-filtering-access-trend-over-time-chart", i5.WebContentFilteringAccessTrendOvertimeChart, View_WebContentFilteringAccessTrendOvertimeChart_Host_0, { entity: "entity" }, {}, []);
export { WebContentFilteringAccessTrendOvertimeChartNgFactory as WebContentFilteringAccessTrendOvertimeChartNgFactory };
