var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter } from '@angular/core';
import { Tag } from '@wcd/domain';
import { TagsService } from '../../../tags/services/tags.service';
import { I18nService } from '@wcd/i18n';
import { lowerFirst } from 'lodash-es';
var TagComponent = /** @class */ (function () {
    function TagComponent(tagsService, i18nService) {
        this.tagsService = tagsService;
        this.i18nService = i18nService;
        this.removable = false;
        this.removeClick = new EventEmitter();
    }
    TagComponent.prototype.ngOnChanges = function (_changes) {
        if (!this.tag) {
            this.computedTag = null;
            return;
        }
        if (typeof this.tag === 'string') {
            this.computedTag = {
                id: this.tag,
                name: this.tag,
                className: this.className || '',
            };
        }
        else {
            this.computedTag = this.convertToTag(this.tag);
        }
    };
    TagComponent.prototype.removeButtonClick = function () {
        this.removeClick.emit(this.computedTag);
    };
    TagComponent.prototype.getClass = function () {
        if (this.computedTag.className) {
            return this.computedTag.className;
        }
        var type = this.computedTag.type ? this.computedTag.type : 'default';
        return "tag-color-box-" + type;
    };
    TagComponent.prototype.convertToTag = function (tag) {
        if (this.isTag(tag)) {
            return new Tag(__assign({}, tag, { name: tag.name || !tag.nameKey
                    ? tag.name
                    : this.i18nService.get("tags.localized." + lowerFirst(tag.nameKey)) }));
        }
        return this.tagsService.getDataSensitivityTag(tag, tag.label, false);
    };
    TagComponent.prototype.isTag = function (tag) {
        return Boolean(tag.name || tag.nameKey);
    };
    return TagComponent;
}());
export { TagComponent };
