import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Entity } from '@microsoft/paris';
import { EntityModelBase } from '@microsoft/paris';

export const NotificationRuleSeverityConditionValues = [
	{ id: 'Informational', name: 'Informational', value: 32 },
	{ id: 'Low', name: 'Low', value: 64 },
	{ id: 'Medium', name: 'Medium', value: 128 },
	{ id: 'High', name: 'High', value: 256 },
];

@Entity({
	singularName: 'Notification Rule Severity Condition Value Item',
	pluralName: 'Notification Rule Severity Condition Value Items',
	values: NotificationRuleSeverityConditionValues,
})
export class NotificationRuleSeverityConditionValue extends EntityModelBase<string> {
	@EntityField({ data: '__self' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;
	@EntityField() name: string;
	@EntityField() value?: number;
}

export class NotificationRuleServiceSourceConditionValue extends EntityModelBase<string> {
	@EntityField({ data: '__self' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;
	@EntityField() name: string;
	@EntityField() value?: number;
	// Array of detection sources
	@EntityField() children?: Array<{'name': string, 'value': any, 'priority': number}>;
	@EntityField() disabled?: boolean;
	@EntityField() allDetectionSources?: boolean;
}

@ValueObject({
	singularName: 'serviceSourceSeverity',
	pluralName: 'serviceSourcesSeverities',
})
export class ServiceSourceSeverity extends ModelBase {
	constructor(alertSeverities: Array<NotificationRuleSeverityConditionValue>, serviceSources: Array<NotificationRuleServiceSourceConditionValue>) {
		super();
		this.severities = alertSeverities;
		this.serviceSources = serviceSources;
	}
	@EntityField()
	severities: Array<NotificationRuleSeverityConditionValue>;

	@EntityField()
	serviceSources: Array<NotificationRuleServiceSourceConditionValue>;
}
