/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "../../../../../../../projects/shared/src/lib/components/route-link.component.ngfactory";
import * as i5 from "../../../../../../../projects/shared/src/lib/components/route-link.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../../../../../projects/shared/src/lib/services/routes.service";
import * as i8 from "@angular/common";
import * as i9 from "./investigated-machines-table.component";
import * as i10 from "../../services/global-entity-types.service";
import * as i11 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_InvestigatedMachinesTableComponent = [];
var RenderType_InvestigatedMachinesTableComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InvestigatedMachinesTableComponent, data: {} });
export { RenderType_InvestigatedMachinesTableComponent as RenderType_InvestigatedMachinesTableComponent };
function View_InvestigatedMachinesTableComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 9, "td", [["class", "nowrap wcd-text-overflow-medium-large"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "wcd-shared-icon", [["class", "table-icon"], ["iconName", "System"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(5, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "route-link", [], null, null, null, i4.View_RouteLinkComponent_0, i4.RenderType_RouteLinkComponent)), i0.ɵdid(9, 573440, null, 0, i5.RouteLinkComponent, [i6.UrlHandlingStrategy, i6.Router, i7.RoutesService], { routeLink: [0, "routeLink"] }, null), (_l()(), i0.ɵted(10, 0, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(14, null, ["\n\t\t\t\t", "\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "System"; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.getMachineRouteLinkModel(_v.context.$implicit); _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_2); var currVal_3 = _v.context.$implicit.osName; _ck(_v, 14, 0, currVal_3); }); }
export function View_InvestigatedMachinesTableComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "table", [["class", "wcd-table wcd-full-width"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 10, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(14, 0, null, null, 4, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InvestigatedMachinesTableComponent_1)), i0.ɵdid(17, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.investigatedMachines; _ck(_v, 17, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.investigations_device_fields_deviceName; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.i18nService.strings.investigations_device_fields_operatingSystem_short; _ck(_v, 10, 0, currVal_1); }); }
export function View_InvestigatedMachinesTableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "investigated-machines-table", [], null, null, null, View_InvestigatedMachinesTableComponent_0, RenderType_InvestigatedMachinesTableComponent)), i0.ɵdid(1, 49152, null, 0, i9.InvestigatedMachinesTableComponent, [i10.GlobalEntityTypesService, i11.I18nService], null, null)], null, null); }
var InvestigatedMachinesTableComponentNgFactory = i0.ɵccf("investigated-machines-table", i9.InvestigatedMachinesTableComponent, View_InvestigatedMachinesTableComponent_Host_0, { investigatedMachines: "investigatedMachines" }, {}, []);
export { InvestigatedMachinesTableComponentNgFactory as InvestigatedMachinesTableComponentNgFactory };
