import { Component, Input, OnChanges } from '@angular/core';
import { SpinnerSize } from 'office-ui-fabric-react';

import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { EmailHeadersApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { HttpClient } from '@angular/common/http';
import { XOR } from '@wcd/types';
import { defer, Observable, of } from 'rxjs';
import { catchError, map, startWith, take } from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { sccHostService } from '@wcd/scc-interface';

const LOADING_SYMBOL = Symbol();

@Component({
	selector: 'email-headers',
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<header class="wcd-flex-none wcd-padding-bottom wcd-padding-large-right wcd-flex-horizontal">
				<div class="wcd-flex-1">
					<h3 class="side-panel-title" id="email-headers-panel-header" #panelHeader>
						{{ i18nService.strings.airsEntities_email_headers_panel_title }}
					</h3>
				</div>
			</header>
			<div>
				<markdown>
					{{ i18nService.strings.airsEntities_email_headers_panel_analyzerInfoLink }}
				</markdown>
			</div>
			<div class="wcd-padding-vertical">
				<span>
					<a target="_blank" href="https://go.microsoft.com/fwlink/?linkid=874390">
						{{ i18nService.strings.airsEntities_email_headers_panel_analyzer }}
						<fab-icon iconName="OpenInNewWindow" className="small-icon"></fab-icon>
					</a>
				</span>
			</div>
			<div
				*ngLet="(headers$ | async) as headers"
				class="wcd-padding-medium-left header-border wcd-scroll-vertical"
			>
				<ng-container
					*ngIf="headers === LOADING_SYMBOL; else checkHeaders"
					[ngTemplateOutlet]="loader"
				></ng-container>
				<ng-template #checkHeaders>
					<ng-container *ngIf="headers?.length; else noHeaders">
						<div class="wcd-padding-bottom">
							<copy-to-clipboard
								[content]="headers.join('\\n')"
								[settings]="{
									copyText:
										i18nService.strings.airsEntities_email_headers_panel_copyToClipboard
								}"
							></copy-to-clipboard>
						</div>
						<div *ngFor="let header of headers">
							<p class="break-all">{{ header }}</p>
						</div>
					</ng-container>
				</ng-template>
				<ng-template #noHeaders>
					<div>{{ i18nService.strings.airsEntities_email_headers_panel_noHeadersFound }}</div>
				</ng-template>
			</div>
		</wcd-panel>
		<ng-template #loader>
			<div class="wcd-flex-center-all wcd-padding-all">
				<fab-spinner [size]="SpinnerSize.large"></fab-spinner>
			</div>
		</ng-template>
	`,
	styles: [
		`
			.header-border {
				border: 1px gray solid;
				padding: 10px;
				height: 100%;
			}
		`,
	],
})
export class EmailHeadersPanelComponent extends PanelContainer implements OnChanges {
	LOADING_SYMBOL = LOADING_SYMBOL;
	SpinnerSize = SpinnerSize;

	@Input() headersObj: XOR<
		{ email: { internetMessageId: string; mailboxId: string } },
		{ quarantineEmail: { identity: string } }
	>;

	headers$: Observable<Array<string> | typeof LOADING_SYMBOL>;

	constructor(
		router: Router,
		public i18nService: I18nService,
		private paris: Paris,
		private http: HttpClient,
		private dialogsService: DialogsService
	) {
		super(router);
	}

	ngOnInit() {
		super.ngOnInit();
		this.setHeaders();
	}

	ngOnChanges() {
		this.setHeaders();
	}

	private setHeaders() {
		this.headers$ =
			this.headersObj &&
			(this.headersObj.email
				? this.getEmailHeaders()
				: this.headersObj.quarantineEmail
				? this.getQuarantineEmailHeaders()
				: null
			).pipe(startWith(LOADING_SYMBOL));
	}

	private getEmailHeaders(): Observable<Array<string>> {
		return defer(async () => {
			let EnableNewMailItemAPI = false;
			try {
				EnableNewMailItemAPI = await sccHostService.auth.isInRole(
					'setting:AntiSpamCHN$EnableNewMailItemAPI'
				);
			} catch (e) {}

			try {
				return await this.paris
					.apiCall(EmailHeadersApiCall, {
						internetMessageId: this.headersObj.email.internetMessageId,
						mailboxId: this.headersObj.email.mailboxId,
						EnableNewMailItemAPI,
					})
					.pipe(take(1))
					.toPromise();
			} catch (e) {
				this.dialogsService.showError({
					title: this.i18nService.strings.airsEntities_email_headers_panel_headerErrorTitle,
					data: this.i18nService.strings.airsEntities_email_headers_panel_headerErrorMessage,
				});
			}
		});
	}

	private getQuarantineEmailHeaders(): Observable<Array<string>> {
		// TODO: move to paris once we can test with examples in mtptestlab
		return this.http
			.get<{ header: string }>('/api/QuarantineMessageHeader', {
				params: { identity: this.headersObj.quarantineEmail.identity },
			})
			.pipe(
				take(1),
				map(data => {
					return (
						data &&
						data.header &&
						data.header
							.split(/[\r\n]/g)
							.map(s => s && s.trim())
							.filter(Boolean)
					);
				}),
				catchError(err => of(null))
			);
	}
}
