import { DOCUMENT } from '@angular/common';
import {
	ApplicationRef,
	ComponentFactory,
	ComponentFactoryResolver,
	EmbeddedViewRef,
	Inject,
	Injectable,
	Injector,
} from '@angular/core';
import { take } from 'rxjs/operators';

import { ConfirmationDialogComponent } from './confirmation-dialog.component';

import { ConfirmationDialogTextInputContentComponent } from './content-components/confirmation-dialog-text-input-content.component';
import { TextInputConfirmationDialogOptions } from './models/text-input-confirmation-dialog-options.model';
import {
	ConfirmationDialogOptions,
	IConfirmationDialogContentComponent,
} from './models/confirmation-dialog-options.model';
import { ConfirmEvent } from './models/confirm-event.model';

// @dynamic
@Injectable()
export class ConfirmationDialogService {
	private readonly _componentFactory: ComponentFactory<
		ConfirmationDialogComponent<IConfirmationDialogContentComponent, any>
	>;

	constructor(
		private readonly applicationRef: ApplicationRef,
		private readonly injector: Injector,
		@Inject(DOCUMENT) private readonly document: Document,
		componentFactoryResolver: ComponentFactoryResolver
	) {
		this._componentFactory = componentFactoryResolver.resolveComponentFactory(
			ConfirmationDialogComponent
		);
	}

	/**
	 * Shows a confirmation dialog.
	 * @param options Configurations for the dialog
	 */
	async showDialog<TContentComponent extends IConfirmationDialogContentComponent, TData>(
		options: ConfirmationDialogOptions<TContentComponent, TData>
	): Promise<ConfirmEvent<TData>> {
		const componentRef = this._componentFactory.create(this.injector);
		componentRef.instance.options = options;

		componentRef.changeDetectorRef.detectChanges();

		this.applicationRef.attachView(componentRef.hostView);
		this.document.body.appendChild((componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0]);

		const result = await componentRef.instance.onClosed.pipe(take(1)).toPromise<ConfirmEvent<TData>>();

		this.applicationRef.detachView(componentRef.hostView);
		componentRef.destroy();

		return result;
	}

	/**
	 * Shows a simple confirmation dialog with a text input.
	 * @param textInputProps The inputs to pass to the text input.
	 * @param options the options for the dialog
	 */
	async showTextInputDialog<TData = string>({
		textInputProps,
		...options
	}: TextInputConfirmationDialogOptions<TData>): Promise<ConfirmEvent<TData>> {
		return this.showDialog<ConfirmationDialogTextInputContentComponent, TData>({
			...options,
			contentComponent: {
				type: ConfirmationDialogTextInputContentComponent,
				props: textInputProps,
			},
		});
	}
}
