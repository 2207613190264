import { sccHostService } from '@wcd/scc-interface';
import { isNil } from 'lodash-es';
import { DEFAULT_LANGUAGE, getPortalLanguage } from './i18n.helpers';
var PARAMS_REGEXP = /\(\:(\w+)\)/g;
var I18nLoader = /** @class */ (function () {
    function I18nLoader() {
        var _this = this;
        this._dicPromise = this.loadI18nDictionary()
            .then(function (keys) { return (_this.strings = keys); })
            .catch(function (err) { return (_this._dicPromise = null); });
    }
    Object.defineProperty(I18nLoader.prototype, "i18nDictionaryPromise", {
        get: function () {
            return this._dicPromise;
        },
        enumerable: true,
        configurable: true
    });
    I18nLoader.prototype.get = function (keyPath, params, allowNotFound) {
        if (allowNotFound === void 0) { allowNotFound = false; }
        if (!keyPath)
            return '';
        if (!this.strings) {
            console.warn('keys not loaded: ', keyPath);
            return '';
        }
        var key = keyPath;
        if (keyPath.includes('.')) {
            // Allow dot notation for backwards compatibility.
            // Can be removed once no more dot notations keys exists on templates and services.
            key = keyPath.replace(/\./g, '_');
        }
        var value = this.strings[key];
        if (!value) {
            if (allowNotFound)
                return '';
            console.warn("i18n value not found: " + keyPath + ".");
            return '[Not found]';
        }
        if (params)
            return value.replace(PARAMS_REGEXP, function (match, propertyName) {
                return isNil(params[propertyName]) ? '' : params[propertyName];
            });
        return value;
    };
    I18nLoader.prototype.loadI18nDictionary = function () {
        var _this = this;
        var language = getPortalLanguage();
        return this.getLocaleStrings(language).catch(function (err) {
            console.error("Failed to get " + language + " language strings", err);
            return _this.getLocaleStrings(DEFAULT_LANGUAGE);
        });
    };
    I18nLoader.prototype.getLocaleStrings = function (locale) {
        var prefix = !sccHostService.isSCC ? '' : sccHostService.getPackageBasePathByPkg();
        var suffix = !sccHostService.isSCC ? "?v=" + window.config.buildVersion : '';
        return fetch(prefix + "/assets/i18n/" + locale + "/resources.json" + suffix).then(function (res) { return res.json(); });
    };
    return I18nLoader;
}());
export var i18nLoader = new I18nLoader();
