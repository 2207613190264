import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Paris } from "@microsoft/paris";
import { BaselineProfileConfigurationIds, BaselineProfileConfigurationsBaselineProfileDeviceRelationship, ResolveEntityURL, TvmEndPoint } from "@wcd/domain";
import { ConfigurationDevicesBaseComponent } from "./configuration-devices.base/configuration-devices.base.component";
import { BaselineConfigurationFieldsService } from "../../services/baseline-configuration.fields.service";
import { TvmDownloadService } from "../../../../../../../tvm/services/tvm-download.service";
import { BaselineDetectedValuesService } from '../../../../../../../tvm/services/baseline-detected-value.service';

@Component({
	selector: 'profile-configuration-devices',
	templateUrl: './configuration-devices.base/configuration-devices.base.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileConfigurationDevicesComponent extends ConfigurationDevicesBaseComponent implements OnInit {
	private sourceValue: BaselineProfileConfigurationIds;

	@Input()
	profileName: string;

	@Input()
	profileId: string;

	constructor(
		fieldsService: BaselineConfigurationFieldsService,
		tvmDownloadService: TvmDownloadService,
		detectedValuesService: BaselineDetectedValuesService,
		paris: Paris) {
		super(fieldsService, tvmDownloadService, detectedValuesService, paris);
	}

	ngOnInit(): void {
		this.dataviewId = `baselineConfigurationDevices_${this.profileId}_${this.configuration.id}`
		super.ngOnInit();

		this.sourceValue = new BaselineProfileConfigurationIds({
			id: this.configuration.id,
			configurationId: this.configuration.id,
			profileId: this.profileId
		});
		this.repository = this.paris.getRelationshipRepository(BaselineProfileConfigurationsBaselineProfileDeviceRelationship);
		this.repository.sourceItem = this.sourceValue;

		this.exportUrl = ResolveEntityURL({
			endPoint: TvmEndPoint.Analytics,
			entityModelBaseOrRelationship: BaselineProfileConfigurationsBaselineProfileDeviceRelationship,
			sourceModel: this.sourceValue,
		});
		this.exportFileName = `${this.profileName.split(' ').join('_')}_${this.configuration.id}`;
	}
}
