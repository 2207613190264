import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Package file',
	pluralName: 'Package file',
})
export class PackageFile extends ModelBase {
	@EntityField({ data: 'fileName' })
	fileName: string;
	@EntityField({ data: 'url' })
	url: string;
}
