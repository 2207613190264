import { Component, Input } from '@angular/core';
import { ModalContainer } from '../modals/models/modal-container.model';
import { Router } from '@angular/router';
import { IShortcut } from './shortcuts.service';

@Component({
	selector: 'shortcut',
	template: `
		<modal (close)="destroy()" [settings]="settings">
			<form (submit)="submitShortcut()" class="dialog-contents wcd-padding-small" style="width:300px;">
				<wcd-shared-icon
					[iconName]="shortcut.iconName"
					class="pull-left large-medium-icon color-text-primary"
				>
				</wcd-shared-icon>
				<div class="with-large-medium-icon">
					<input
						type="text"
						[(ngModel)]="shortcutValue"
						class="form-control"
						name="shortcut-value"
						autofocus
						id="shortcut-value"
						[attr.placeholder]="shortcut.placeholder"
					/>
				</div>
			</form>
		</modal>
	`,
})
export class ShortcutComponent extends ModalContainer {
	@Input() shortcut: Pick<IShortcut, 'name' | 'placeholder' | 'iconName'>;
	@Input() onEnterShortcut: (value: string) => void;
	shortcutValue: string;

	constructor(protected router: Router) {
		super(router);
	}

	submitShortcut() {
		if (this.shortcutValue) this.onEnterShortcut(this.shortcutValue);
	}
}
