var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Software } from '@wcd/domain';
import { TvmColor, TvmColorService } from '../../../../../../../tvm/services/tvm-color.service';
import { ReportsService } from '../../../../../../../shared-reports/services/reports.service';
import { ReportWidgetComponent } from '../../../../../../../reports/components/report-widget.component.base';
import { I18nService } from '@wcd/i18n';
import { ActivatedEntity } from '../../../../../../../global_entities/services/activated-entity.service';
import { map, filter, flatMap } from 'rxjs/operators';
import { ChartColor } from '@wcd/charts';
import { TzDateService, LocaleConfigService } from '@wcd/localization';
import { TvmChartTooltipComponent, ASSETS_STATISTICS_EXPANDED_TOOLTIP_ID, } from '../../../../../../../tvm/components/tooltips/events/tvm-chart-tooltip.component';
import { TvmTopChangeEventsService } from '../../../../../../../tvm/services/tvm-top-change-events.service';
import { generateDatesForHistoryArray } from '../../../../../../../tvm/tvm-utils';
var AssetsStatisticsExpandedWidget = /** @class */ (function (_super) {
    __extends(AssetsStatisticsExpandedWidget, _super);
    function AssetsStatisticsExpandedWidget(reportsService, tvmColorService, i18nService, topChangeEventsService, tzDateService, localeConfigService, activatedEntity) {
        var _this = _super.call(this, reportsService) || this;
        _this.tvmColorService = tvmColorService;
        _this.i18nService = i18nService;
        _this.topChangeEventsService = topChangeEventsService;
        _this.tzDateService = tzDateService;
        _this.localeConfigService = localeConfigService;
        _this.activatedEntity = activatedEntity;
        _this.events$ = _this.data$.pipe(map(function (data) {
            if (!data) {
                return null;
            }
            _this.totalAssetAtRisk = data.assetsAtRiskCount;
            _this.totalAsset = data.totalAssetCount;
            return [
                { kind: AssetsStatisticsExpandedWidget.exposedColor, count: data.assetsAtRiskCount },
                {
                    kind: AssetsStatisticsExpandedWidget.installedColor,
                    count: data.totalAssetCount - data.assetsAtRiskCount,
                },
            ];
        }));
        _this.legends = [
            {
                name: _this.i18nService.get('tvm.softwarePage.report.widgets.exposedDevices.title.data.exposedLegend'),
                iconColor: _this.tvmColorService.colorsMap.get(AssetsStatisticsExpandedWidget.exposedColor).raw,
            },
            {
                name: _this.i18nService.get('tvm.softwarePage.report.widgets.exposedDevices.title.data.installedLegend'),
                iconColor: _this.tvmColorService.colorsMap.get(AssetsStatisticsExpandedWidget.installedColor).raw,
            },
        ];
        _this._kindColorMap = {
            type: 'class',
            map: tvmColorService.backgroundColorsClassMap,
        };
        _this.textFormatter = function (text) {
            if (text === AssetsStatisticsExpandedWidget.exposedColor) {
                return _this.i18nService.get('tvm.softwarePage.report.widgets.exposedDevices.title.data.exposedLegend');
            }
            return _this.i18nService.get('tvm.softwarePage.report.widgets.exposedDevices.title.data.installedLegend');
        };
        return _this;
    }
    Object.defineProperty(AssetsStatisticsExpandedWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'misconfigurationsDistributionWidget',
                name: this.i18nService.get('tvm.softwarePage.report.widgets.exposedDevices.title.initial'),
                loadData: function () { return _this.getAssetsStatisticsObservable(); },
            };
        },
        enumerable: true,
        configurable: true
    });
    AssetsStatisticsExpandedWidget.prototype.getAssetsStatisticsObservable = function () {
        var _this = this;
        return this.activatedEntity.currentEntity$.pipe(filter(function (entity) {
            return entity instanceof Software;
        }), flatMap(function (software) {
            var softwareChangeEvents$ = _this.topChangeEventsService.getChangeEventsForSoftware$(software.id);
            var assetsStatistics = software.assetsStatistics;
            return softwareChangeEvents$.pipe(map(function (softwareChangeEvents) {
                _this.setNewChartValues(assetsStatistics, softwareChangeEvents || new Map());
                return assetsStatistics;
            }));
        }));
    };
    AssetsStatisticsExpandedWidget.prototype.setNewChartValues = function (data, vaEvents) {
        var _this = this;
        if (!data) {
            this.newChartOptions = null;
            return;
        }
        var historyDataPoints = generateDatesForHistoryArray(data.assetsAtRiskHistory, this.localeConfigService.isLocalTimeZone);
        var maxYValue = Math.max.apply(Math, data.assetsAtRiskHistory);
        var minYValue = Math.min.apply(Math, data.assetsAtRiskHistory);
        this.newChartOptions = {
            data: historyDataPoints.map(function (point) { return (__assign({}, point, { events: vaEvents.get(_this.tzDateService.format(point.date, 'MM/dd')) })); }),
            legend: this.i18nService.strings.tvm_common_exposedDevices,
            yAxisConfiguration: this.getYAxisConfig(maxYValue, minYValue),
            yAxisTickValuesWidth: this.getYAxisTickValuesWidth(maxYValue),
            color: ChartColor.Red,
            height: 150,
            tooltipComponent: TvmChartTooltipComponent,
            tooltipId: ASSETS_STATISTICS_EXPANDED_TOOLTIP_ID,
        };
    };
    AssetsStatisticsExpandedWidget.prototype.getYAxisTickValuesWidth = function (maxYValue) {
        var numOfDigits = maxYValue.toString().length;
        var baseWidthForThreeDigits = 35;
        var widthPerDigit = 5;
        return baseWidthForThreeDigits + Math.max(0, (numOfDigits - 3) * widthPerDigit);
    };
    /**
     * Creates Y Axis based on max / min.
     * We define max and min, closer to the trend, but not exactly on it, so it looks good
     */
    AssetsStatisticsExpandedWidget.prototype.getYAxisConfig = function (maxYValue, minYValue) {
        var maxValue = maxYValue + 1;
        // To make sure the graph looks good
        // We create Max and Min that the trend doesn't touch
        // Example Max value 1500 -> 100 *5
        // Then we take the 500 and found nearest for 1500 -> New Max = 2000
        // For min value 800 we found nearest 500 -> New min = 500
        var pow = maxValue.toString().length - 2;
        var closerRounding = Math.pow(10, pow > 0 ? pow : 0) * 5;
        var max = Math.ceil(maxValue / closerRounding) * closerRounding;
        var min = Math.floor(minYValue / closerRounding) * closerRounding;
        var ticks = [];
        for (var i = 0; i < 3; i++) {
            ticks.push(Math.round(min + ((max - min) * i) / 2));
        }
        return {
            max: max,
            min: min,
            ticks: {
                values: ticks,
            },
        };
    };
    AssetsStatisticsExpandedWidget.exposedColor = TvmColor.HighRisk;
    AssetsStatisticsExpandedWidget.installedColor = TvmColor.GraphSeriesLight;
    return AssetsStatisticsExpandedWidget;
}(ReportWidgetComponent));
export { AssetsStatisticsExpandedWidget };
