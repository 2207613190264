import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, parseEntityName } from '../analyticsEndPointUtils';
import { ThreatInfo } from '../common/threat-info.value-object';
import { AssetsStatistics } from '../common/assets-statistics.value-object';
import { ThreatDescriptor } from '../common/threat-descriptor.value-object';
import { EolState } from '../software-version/eol-version-state.enum';
import { VulnerabilityWorkaround } from '../common/vulnerability/vulnerability-workaround.value-object';
import { VulnerabilityType } from '../common/vulnerability/vulnerability-type.enum';
import { TvmSupportedOsPlatform } from '../common/tvm-supported-os-platform.enum';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Software',
	pluralName: 'Software',
	endpoint: 'products',
	parseItemQuery: id => `products/product?productId=${id}`,
	readonly: true,
})
export class Software extends EntityModelBase<string> {
	@EntityField({
		required: true,
		parse: parseEntityName,
	})
	readonly name: string;

	@EntityField({
		required: true,
		parse: parseEntityName,
	})
	readonly vendor: string;

	@EntityField() readonly latestVersion: string;

	@EntityField({
		required: true,
	})
	readonly discoveredVulnerabilities: number;

	@EntityField({
		required: true,
	})
	readonly lastUpdated: Date;

	@EntityField({
		required: true,
		data: 'riskScore',
	})
	readonly exposureScoreImprovement: number;

	@EntityField({
		required: true,
	})
	readonly threatInfo: ThreatInfo;

	@EntityField({
		required: true,
	})
	readonly assetsStatistics: AssetsStatistics;

	@EntityField()
	readonly threats: ThreatDescriptor[];

	@EntityField({
		required: false,
		defaultValue: true,
	})
	readonly isNormalized: boolean;

	@EntityField()
	readonly eolSoftwareState: EolState;

	@EntityField()
	readonly eolSoftwareSinceDate: Date;

	@EntityField()
	readonly hasEolVersions: boolean;

	@EntityField()
	readonly hasUpcomingEolVersions: boolean;

	@EntityField()
	readonly category: string;

	@EntityField()
	readonly isKbProduct: boolean;

	@EntityField({
		data: 'vulnerabilityWorkarounds',
	})
	readonly workarounds?: Array<VulnerabilityWorkaround>;

	@EntityField({
		data: 'mostSevereVulnerabilityType',
	})
	readonly mostSevereVulnerabilityType?: VulnerabilityType;

	@EntityField({
		data: 'zeroDayAssetsAtRisk',
	})
	readonly zeroDayAssetsAtRisk?: number;

	@EntityField({
		data: 'patchReleaseDate',
	})
	readonly patchReleaseDate?: Date;

	@EntityField({
		required: false,
		defaultValue: false,
	})
	readonly productNeverMatched: boolean;

	@EntityField()
	readonly osPlatform: TvmSupportedOsPlatform;
}
